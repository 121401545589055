import React, { useCallback } from 'react'
import { ASSESSMENT_REPORTS_REQUIRED_PERMISSIONS } from 'consts'
import {
    AssessmentReportSearchContract,
    AssessmentReportSearchResultContract,
    AssessmentReportsService,
    UserPermission,
} from 'core/api'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import { DictionaryPage, DictionaryPageActionsProps } from 'components/layouts'
import { LOCAL } from 'core/local'
import { getPageTitleFromRoute } from 'utils'
import { useDictionariesHelper } from 'hooks'
import { useLocation } from 'react-router-dom'

import { AssessmentReportsFilters, SelectReportForm } from './components'
import { getAssessmentReportsColumns } from './AssessmentReports.utils'

/** Страница реестра отчётов */
export const AssessmentReports = React.memo(() => {
    const locales = useDictionariesHelper(['assessmentTypeDictionary'])
    const location = useLocation()
    const title = getPageTitleFromRoute(location.pathname)

    /**
     * Рендер колонок таблицы
     * @param actions набор функций, берется из DictionaryPage
     */
    const renderTableColumns = useCallback(
        ({ reFetchDictionary, updateLoader }: DictionaryPageActionsProps) =>
            getAssessmentReportsColumns(
                {
                    reFetchDictionary,
                    updateLoader,
                },
                locales
            ),
        [locales]
    )

    return (
        <DictionaryPage<
            AssessmentReportSearchContract,
            AssessmentReportSearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.ASSESSMENT_REPORTS}
            contentTitle={title}
            tableColumnsRender={renderTableColumns}
            searchMethod={AssessmentReportsService.search}
            quickSearchMethod={AssessmentReportsService.searchText}
            createPermissions={[
                ...ASSESSMENT_REPORTS_REQUIRED_PERMISSIONS,
                UserPermission.AssessmentReportTemplate360Save,
                UserPermission.AssessmentReportTemplateManagerSave,
            ]}
            createRecordModal={{
                component: SelectReportForm,
                formId: FORM_IDS.SELECT_REPORT_FORM,
                buttonText: LOCAL.ACTIONS.SET,
                modalOptions: {
                    title: LOCAL.MESSAGES.CHOOSE_REPORT_TYPE,
                    okText: LOCAL.ACTIONS.CONTINUE,
                },
            }}
            filterComponent={AssessmentReportsFilters}
        />
    )
})
