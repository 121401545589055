import React, { useEffect, useMemo } from 'react'
import { Circle360ReportService } from 'core/api'
import { ControlGroup } from 'components/controls'
import { Spoiler, TableAdapter } from 'components/shared'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './AssessmentsRespondentsIndicators.module.scss'
import {
    createDataSource,
    customSpoilerTitleRender,
} from './AssessmentsRespondentsIndicators.utils'
import { getColumns } from './AssessmentsRespondentsIndicators.config'

/** Отчет по оценке 360. Оценки групп респондентов по индикаторам компетенций */
export const AssessmentsRespondentsIndicators: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(
        Circle360ReportService.getAssessmentsRespondentsIndicators
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    const columns = useMemo(() => data && getColumns(data.rolesToHide), [data])

    return (
        <div>
            {data?.respondentResults.map((el) => (
                <ControlGroup key={el.competence.id}>
                    <Spoiler
                        title={el.competence.name}
                        spoilerTitleRender={customSpoilerTitleRender(el)}
                        className={styles.spoiler}
                    >
                        <TableAdapter
                            rowClassName={(row, i) =>
                                i ? '' : styles.averageRow
                            }
                            className={styles.table}
                            columns={columns}
                            dataSource={createDataSource(el)}
                            rowKey={(row) => row.indicator.id}
                            pagination={false}
                        />
                    </Spoiler>
                </ControlGroup>
            ))}
        </div>
    )
})
