import { TrackStagePassingContract } from 'core/api'

/**
 * Остались ли попытки для прохождения мероприятия этапа
 * @param attemptsCount - текущее количество прохождений мероприятия
 * @param activityAttemptCount - максимально допустимое количество попыток прохождения мероприятия
 */
export const isEnoughAttemptCountForActivity = ({
    attemptsCount,
    activityAttemptCount,
}: TrackStagePassingContract) =>
    !activityAttemptCount || activityAttemptCount - attemptsCount
