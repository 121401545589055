export const INFO_ASSISTANT_EVENT = {
    STAGE_ACTIVITY_START: 'INFO_ASSISTANT_EVENT_STAGE_ACTIVITY_START',
    STAGE_ACTIVITY_OR_CONTENT_START:
        'INFO_ASSISTANT_EVENT_STAGE_ACTIVITY_CONTENT_START',
    STAGE_START: 'INFO_ASSISTANT_EVENT_STAGE_START',
    STAGE_END: 'INFO_ASSISTANT_EVENT_STAGE_END',
    TRACK_GREETING: 'INFO_ASSISTANT_EVENT_TRACK_GREETING',
    TRACK_START: 'INFO_ASSISTANT_EVENT_TRACK_START',
    TRACK_END: 'INFO_ASSISTANT_EVENT_TRACK_END',
    PREVIEW: 'INFO_ASSISTANT_EVENT_PREVIEW',
    HIDE: 'INFO_ASSISTANT_EVENT_HIDE',
}
