import React, { useContext, useEffect } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { CertificateContract, CertificatesService } from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { ContentTitle, PageContent } from 'components/shared'
import { ExternalCertificatesForm } from 'components/pages/Certificates/components/ExternalCertificatesForm'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UserContext } from 'contexts'
import { WithBlockUserRoutingComponentProps, withLoader } from 'HOCs'
import { getRouteMeta } from 'routing/routeNames.utils'
import { idToUrlParam, isFormModeView } from 'utils'
import { useFormInitialValues, useSaveOrPublishState } from 'hooks'
import { useParams } from 'react-router-dom'

import { CreateCertificatesUrlParamsProps } from './Certificates.types'
import { EXTERNAL_CERTIFICATES_FORM_ID } from './Certificates.consts'

export const ExternalCertificatesFormContainer: React.FC<
    ComponentCommonProps & WithBlockUserRoutingComponentProps
> = withLoader(
    React.memo(({ formMode, onBlockUserRouting, updateLoader, isLoading }) => {
        const { userId, certificateId } = useParams<
            CreateCertificatesUrlParamsProps
        >()

        const { userData } = useContext(UserContext)

        const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()

        const {
            initialValuesForEdit,
            fetchInitialFormValuesById,
        } = useFormInitialValues<CertificateContract>({
            requestMethod: CertificatesService.getById,
            updateLoader,
        })

        useEffect(() => {
            if (certificateId) {
                fetchInitialFormValuesById(+certificateId)
            }
        }, [certificateId, fetchInitialFormValuesById])

        return (
            <PageContent>
                <ContentTitle
                    title={getRouteMeta(
                        `${ROUTE_NAMES.EXTERNAL_CERTIFICATES}/${formMode}`
                    )}
                >
                    <ButtonsToolbar>
                        <Button
                            type="link"
                            href={`${
                                ROUTE_NAMES.EXTERNAL_CERTIFICATES
                            }${idToUrlParam(userId)}`}
                        >
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        <Button
                            form={EXTERNAL_CERTIFICATES_FORM_ID}
                            htmlType="submit"
                            type="primary"
                            onClick={handleSubmitForm(true)}
                            disabled={isFormModeView(formMode) || isLoading}
                        >
                            {LOCAL.ACTIONS.SAVE}
                        </Button>
                    </ButtonsToolbar>
                </ContentTitle>

                <ExternalCertificatesForm
                    updateLoader={updateLoader}
                    onBlockUserRouting={onBlockUserRouting}
                    initialValuesForEdit={initialValuesForEdit}
                    formMode={formMode}
                    isSaveDocument={isSaveDocument}
                    reFetchInitialFormValues={fetchInitialFormValuesById}
                    userId={Number(userId) || Number(userData?.id)}
                />
            </PageContent>
        )
    })
)
