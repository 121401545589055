import React, { useCallback, useEffect, useState } from 'react'
import { API_BASE_URL } from 'consts/env.consts'
import { AdaptationTrackReadyParams } from 'components/pages/TrackPreviewPassing/TrackPreviewPassing.types'
import { Button } from 'components/shared/Button'
import { LOCAL } from 'core/local'
import { ReactSVG } from 'react-svg'
import {
    TrackEvent,
    TrackPassingAssistantEventContract,
    TrackPassingsService,
    TrackPictureContract,
    TrackStageEvent,
} from 'core/api'
import {
    getBackgroundImageStyle,
    isStageModePreview,
    isTrackAssistantTypeTrack,
} from 'utils'
import { useParams } from 'react-router-dom'

import infoAssistantAvatar from './img/avatar.png'
import marker from './img/marker.svg'
import styles from './InfoAssistant.module.scss'
import { IconsAdapter } from '../IconsAdapter'
import {
    InfoAssistantConfigProps,
    InfoAssistantProps,
    InfoAssistantType,
} from './InfoAssistant.types'

/** Информационный помощник */
export const InfoAssistant: React.FC<InfoAssistantProps> = React.memo(
    ({
        type = InfoAssistantType.Track,
        visible,
        event,
        mode,
        stageNumber,
        trackPassingId,
        onCloseAssistant,
    }) => {
        const urlParams = useParams<AdaptationTrackReadyParams>()

        const [infoAssistantVisible, setInfoAssistantVisible] = useState<
            boolean
        >()
        const [config, setConfig] = useState<
            Partial<InfoAssistantConfigProps>
        >()

        const fetchInfoAssistantConfig = useCallback(async () => {
            if (!event) return

            if (isTrackAssistantTypeTrack(type)) {
                setConfig(
                    isStageModePreview(mode)
                        ? ({
                              event: TrackEvent.Greeting,
                              message: LOCAL.LABELS.INFORMATION_ASSISTANT,
                              illustration: {
                                  url: infoAssistantAvatar,
                              } as TrackPictureContract,
                          } as TrackPassingAssistantEventContract)
                        : await TrackPassingsService.getTrackAssistantMessage({
                              appointmentId: Number(urlParams.id),
                              trackEvent: event as TrackEvent,
                          })
                )

                return
            }

            if (!visible) return

            setConfig(
                await TrackPassingsService.getTrackStageAssistantMessage({
                    body: {
                        trackPassingId: trackPassingId!,
                        trackStageEvent: event as TrackStageEvent,
                        trackStageNumber: stageNumber!,
                    },
                })
            )
        }, [
            event,
            type,
            visible,
            trackPassingId,
            stageNumber,
            mode,
            urlParams.id,
        ])

        const handleCloseInfoAssistant = useCallback(() => {
            setInfoAssistantVisible(false)
            onCloseAssistant?.()
        }, [onCloseAssistant])

        useEffect(() => {
            fetchInfoAssistantConfig()
        }, [fetchInfoAssistantConfig])

        useEffect(() => {
            setInfoAssistantVisible(visible)
        }, [visible])

        return infoAssistantVisible && config?.message ? (
            <div className={styles.wrapper}>
                <div
                    className={styles.avatar}
                    style={getBackgroundImageStyle(config?.illustration?.url)}
                />

                <ReactSVG
                    src={marker}
                    wrapper="span"
                    className={styles.marker}
                />

                <div className={styles.message}>
                    {config.message}

                    {config?.greetingVideo && (
                        <a
                            href={`${API_BASE_URL}${config.greetingVideo.launchUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button className={styles.watchVideo}>
                                {LOCAL.ACTIONS.WATCH_VIDEO}
                            </Button>
                        </a>
                    )}

                    <IconsAdapter
                        iconType="CustomIconClose"
                        className={styles.close}
                        onClick={handleCloseInfoAssistant}
                    />
                </div>
            </div>
        ) : null
    }
)
