import {
    AppointmentTrackListenerContract,
    AppointmentTrackStatus,
    AppointmentType,
} from 'core/api'

import { PanelType } from './YourDevelopment.types'

/**
 * Получить две группы треков: завершенные и незавершенные
 * @tracks - все назначенные на пользователя треки
 */
export const splitAppointmentsByEndedStatus = (
    tracks: AppointmentTrackListenerContract[]
) => {
    const ended: AppointmentTrackListenerContract[] = []
    const notEnded: AppointmentTrackListenerContract[] = []

    tracks.forEach((appointment) => {
        switch (appointment.status) {
            case AppointmentTrackStatus.Passed:
            case AppointmentTrackStatus.NotPassed:
            case AppointmentTrackStatus.Canceled:
                ended.push(appointment)
                break
            default:
                notEnded.push(appointment)
                break
        }
    })

    return { ended, notEnded }
}

export const getRequestFilters = (panelType: PanelType) => {
    switch (panelType) {
        case PanelType.Tracks:
            return {
                showPassedOnly: false,
                appointmentType: AppointmentType.Track,
            }
        case PanelType.Activities:
            return {
                showPassedOnly: false,
                appointmentType: AppointmentType.Activity,
            }
        case PanelType.PassedTracksAndActivities:
            return {
                showPassedOnly: true,
            }
    }
}
