export const EDITOR_BODY_DARK_STYLES = {
    backgroundColor: '#0b1423',
    color: '#cccccc',
}

export const EDITOR_BODY_LIGHT_STYLES = {
    color: '#000000',
    backgroundColor: '#ffffff',
}

export const EDITOR_FULL_SCREEN_STATE = 1
