import { ActivityLessonPassingContract } from 'core/api'
import { LOCAL } from 'core/local'
import { isHomeworkOnChecking } from 'utils'
import { renderHomeworkStatus } from 'components/pages/UserPersonalArea/components/CheckHomework/CheckHomework.utils'

/**
 * Рендер статуса для домашнего задания
 * @param {ActivityLessonPassingContract} thematicBlock
 * @returns {JSX.Element | undefined}
 */
export const renderStatus = (thematicBlock: ActivityLessonPassingContract) => {
    if (!thematicBlock.homework) return

    const { status, tutorId } = thematicBlock.homework
    const tutor = tutorId ? `${LOCAL.LABELS.TUTOR} ${tutorId}: ` : ''

    const styledStatus = renderHomeworkStatus(
        status,
        isHomeworkOnChecking(status) ? `${tutor}${LOCAL.LABELS.DOCUMENT} ` : ''
    )

    return styledStatus
}
