import React, { useCallback, useMemo } from 'react'
import { AutoComplete, Form, Input } from 'antd'
import { CatalogItemSearchContract } from 'core/api'
import { FilterForm } from 'components/shared/FilterForm'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { createErrorNotification, isKeyEnter } from 'utils'
import { useHistory } from 'react-router-dom'
import { validateCardView } from 'components/pages/Main/MainPage.utils'

import styles from './CatalogSearchFilterByName.module.scss'
import { CatalogSearchFilterByNameProps } from './CatalogSearchFilterByName.types'
import { normalizeDataForSearch } from '../CatalogSearch.utils'

/**
 * Форма фильтрации элементов в каталоге по наименованию
 */
export const CatalogSearchFilterByName: React.FC<CatalogSearchFilterByNameProps<
    CatalogItemSearchContract
>> = React.memo(({ setVisibleSearchResults, catalogItems, ...props }) => {
    const history = useHistory()

    /**
     * Нормализуем данные для списка поиска по наименованию мероприятий
     */
    const autoCompleteOptions = useMemo(
        () => catalogItems?.map(normalizeDataForSearch),
        [catalogItems]
    )

    /**
     * Настройки фильтрации
     */
    const getFilterOptions = useCallback(
        (inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
            -1,
        []
    )

    /**
     * Переход на страницу курса
     */
    const goToCourseInformation = useCallback(
        async (_, inputValue) => {
            if (await validateCardView(inputValue.id)) {
                history.push(
                    `${ROUTE_NAMES.PRODUCT_CARD_VIEW}/${inputValue.id}`
                )

                return
            }

            createErrorNotification(LOCAL.MESSAGES.PRODUCT_CARD_ACCESS_DENIED)
        },
        [history]
    )

    /**
     * Вывод результата поиска по нажатию кнопки enter
     */
    const handleKeyDown = useCallback(
        (e) => {
            if (!isKeyEnter(e.keyCode)) {
                return
            }

            props.onSetFilters({ name: e.target.value })
            setVisibleSearchResults(true)
        },
        [props, setVisibleSearchResults]
    )

    return (
        <div className={styles.searchWrapper}>
            <FilterForm {...props} renderFooter={null}>
                <Form.Item name="name">
                    <AutoComplete
                        options={autoCompleteOptions}
                        filterOption={getFilterOptions}
                        onSelect={goToCourseInformation}
                        onKeyDown={handleKeyDown}
                    >
                        <Input
                            placeholder={LOCAL.PLACEHOLDERS.SEARCH_BY_CATALOGUE}
                            prefix={
                                <IconsAdapter
                                    iconType="SearchOutlined"
                                    className={styles.icon}
                                />
                            }
                        />
                    </AutoComplete>
                </Form.Item>
            </FilterForm>
        </div>
    )
})
