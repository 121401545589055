import React, { FC, useCallback } from 'react'
import {
    AppointmentTrackSearchContract,
    AppointmentTrackSearchResultContract,
    AppointmentTracksService,
    UserPermission,
} from 'core/api'
import { AppointmentTracksPassingService, getRowHandlers } from 'utils'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    DictionaryPageActionsProps,
    ORDER_RULES_WITH_CREATION_DATE,
} from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS } from 'consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'

import { TrackAssignmentsFilters } from './components'
import { getTrackAssignmentsTableColumns } from './TrackAssignments.config'

/** Реестр назначений треков */
export const TrackAssignments: FC = React.memo(() => {
    /**
     * Рендер колонок таблицы
     * @param actions - набор функций, берется из DictionaryPage
     */
    const renderTableColumns = useCallback(
        ({ reFetchDictionary, updateLoader }: DictionaryPageActionsProps) =>
            getTrackAssignmentsTableColumns(AppointmentTracksPassingService, {
                reFetchDictionary,
                updateLoader,
            }),
        []
    )

    return (
        <DictionaryPage<
            AppointmentTrackSearchContract,
            AppointmentTrackSearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.TRACK_ASSIGNMENTS}
            searchMethod={AppointmentTracksService.search}
            deleteMethod={AppointmentTracksService.setAsArchive}
            contentTitle={getRouteMeta(ROUTE_NAMES.TRACKS_ASSIGNMENTS)}
            tableColumnsRender={renderTableColumns}
            createRecordLink={{
                path: ROUTE_NAMES.TRACKS_ASSIGNMENTS_CREATE,
            }}
            filterComponent={TrackAssignmentsFilters}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES_WITH_CREATION_DATE,
                },
            }}
            createPermissions={[
                ...TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.TrackAppointmentSave,
            ]}
            quickSearchMethod={AppointmentTracksService.searchText}
            rowClassName={getRowClassNameArchive}
            onRow={getRowHandlers({ url: ROUTE_NAMES.TRACKS_ASSIGNMENTS_VIEW })}
        />
    )
})
