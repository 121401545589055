import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { FORM_IDS } from 'core/configs'
import { Form, Select } from 'antd'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { STRUCTURAL_UNIT_SEARCH_BODY } from 'consts'
import {
    StructuralUnitContract,
    StructuralUnitsSearchContract,
    StructuralUnitsService,
} from 'core/api'
import { getRowClassNameWithoutChild } from 'components/shared/TableAdapter'
import { isEmpty } from 'lodash'
import {
    isFormModeView,
    isStructuralUnitComplex,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { useElementVisible } from 'hooks'

import styles from './AddOrganizationControl.module.scss'
import { AddOrganizationControlProps } from './AddOrganizationControl.types'
import { ORGANIZATIONS_TABLE_COLUMNS } from './AddOrganizationControl.consts'
import { OrganizationsFilters } from '../SearchSelectControl/components/filters/OrganizationsFilters'
import { SearchTableShowButton } from '../SearchSelectControl/components/SearchTableShowButton'
import { ShouldUpdateChecker } from '../ShouldUpdateChecker'
import { SwitchControl } from '../SwitchControl'
import { checkboxRender } from './AddOrganizationControl.utils'
import { isRowSelectionTypeCheckbox } from '../SearchSelectControl'

/**
 * Поле - "Организации". Компонент предназначен для добавления выбранных организаций в форму.
 * Выбор организаций осуществляется в модальном окне, есть возможность поиска по наименованию.
 */
export const AddOrganizationControl: React.FC<AddOrganizationControlProps> = ({
    className,
    value,
    onChange,
    formMode,
    disabled,
    title,
    required,
    isExpandRowBySearch = true,
    withSwitch,
    rowSelectionType = 'checkbox',
}) => {
    const {
        updateElementVisible: updateLoader,
        elementVisible: isLoading,
    } = useElementVisible()
    const [selectedRows, setSelectedRows] = useState<LabeledValue[]>()

    const showAddButton = isRowSelectionTypeCheckbox(rowSelectionType) || !value

    /**
     * Обновить значение во внутреннем стейте компонента и внешней форме
     * @param changedValue новое значение для поля
     */
    const triggerChange = useCallback(
        (changedValue?: LabeledValue[]) => {
            setSelectedRows(changedValue)
            onChange?.(changedValue)
        },
        [onChange]
    )

    /** Запрос данных по ids (выбранные ряды таблицы) для селекта */
    const fetchEntitiesBySelectedRowsIds = useCallback(
        async (ids?: number[]) => {
            try {
                updateLoader(true)

                const response = await StructuralUnitsService.getByIds({
                    body: ids,
                })

                triggerChange(
                    response
                        .filter(({ type }) => !isStructuralUnitComplex(type))
                        .map(normalizeDataForSelectAndRadio)
                )
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        [triggerChange, updateLoader]
    )

    /** Обработчик выбора ряда, в таблице в модальном окне */
    const handleSelectTableRows = useCallback(
        (result?: number[]) => {
            if (result?.length) {
                fetchEntitiesBySelectedRowsIds(result)
            }
        },
        [fetchEntitiesBySelectedRowsIds]
    )

    /** Обработчик отмены выбора ряда (при нажатии на крестик тега, либо кнопку "очистить все") */
    const handleDeselectRows = useCallback(
        (result: number[]) => {
            const newValue = selectedRows?.filter((row) =>
                result?.includes(Number(row.value))
            )

            triggerChange(newValue?.length ? newValue : undefined)
        },
        [selectedRows, triggerChange]
    )

    /** ids выбранных рядов, для value в Select */
    const selectedRowsIds = useMemo(
        () => (value || selectedRows)?.map((el) => Number(el.value)),
        [selectedRows, value]
    )

    useEffect(() => {
        setSelectedRows(value)
    }, [value])

    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={cn(showAddButton && styles.control)}>
                {title && (
                    <span
                        className={cn(
                            styles.label,
                            required && 'required-control'
                        )}
                    >
                        {title}
                    </span>
                )}

                {showAddButton && (
                    <ShouldUpdateChecker fieldPath={['forAllOrganizations']}>
                        {({ getFieldValue }) => {
                            const forAllOrganizations = getFieldValue(
                                'forAllOrganizations'
                            )

                            return (
                                <SearchTableShowButton<
                                    StructuralUnitsSearchContract,
                                    StructuralUnitContract
                                >
                                    tableSearchOptions={{
                                        formId: FORM_IDS.SEARCH_ORGANIZATION,
                                        rowSelectionType,
                                        searchMethod:
                                            StructuralUnitsService.search,
                                        tableColumns: ORGANIZATIONS_TABLE_COLUMNS,
                                        childrenColumnName: 'units',
                                        filterComponent: OrganizationsFilters,
                                        rowClassName: getRowClassNameWithoutChild,
                                        onSelectTableItem: handleSelectTableRows,
                                        renderSelectCell: checkboxRender,
                                        tableFiltersOptions: {
                                            initialValues: STRUCTURAL_UNIT_SEARCH_BODY,
                                        },
                                        initialValues: selectedRowsIds,
                                        isExpandRowBySearch,
                                        expandable: {
                                            defaultExpandAllRows: true,
                                        },
                                    }}
                                    modalTitle={
                                        LOCAL.LABELS.ORGANIZATION_SELECTION
                                    }
                                >
                                    <AddButton
                                        buttonText={LOCAL.ACTIONS.ADD}
                                        disabled={
                                            isLoading ||
                                            disabled ||
                                            isFormModeView(formMode) ||
                                            forAllOrganizations
                                        }
                                        loading={isLoading}
                                    />
                                </SearchTableShowButton>
                            )
                        }}
                    </ShouldUpdateChecker>
                )}

                <Form.Item
                    className={styles.switch}
                    name="forAllOrganizations"
                    hidden={!withSwitch}
                >
                    <SwitchControl
                        addonAfter={
                            LOCAL.LABELS.AVAILABLE_FOR_ALL_ORGANIZATIONS
                        }
                        formMode={formMode}
                        disabled={
                            !isEmpty(value || selectedRowsIds) || disabled
                        }
                    />
                </Form.Item>
            </div>

            {!!(value || selectedRows)?.length && (
                <Select
                    className={styles.selected}
                    mode={
                        isRowSelectionTypeCheckbox(rowSelectionType)
                            ? 'multiple'
                            : undefined
                    }
                    allowClear
                    disabled={disabled || isFormModeView(formMode)}
                    showSearch={false}
                    dropdownStyle={{
                        display: 'none',
                    }}
                    value={selectedRowsIds}
                    options={value || selectedRows}
                    onChange={handleDeselectRows}
                    showArrow={false}
                />
            )}
        </div>
    )
}
