import { UploadChangeParam } from 'antd/lib/upload/interface'

/**
 * Чистим объект события от лишних файлов
 * @param event - объект события
 * @return массив добавленных файлов (если пользователь добавляет второй файл, то чистим
 *     массив от первого)
 */
export const getLastFileFromList = (event: UploadChangeParam) => {
    if (
        !event?.file.status ||
        event?.file.status === 'error' ||
        event?.file.status === 'removed'
    ) {
        return event?.fileList.slice(0, -1)
    }

    return [event.file]
}
