import React from 'react'
import { Col, Row } from 'antd'
import { ComponentCommonProps } from 'App.types'
import {
    ControlGroup,
    FormItemAdapter,
    InputNumberControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'

import styles from './ProductLearningSchedule.module.scss'

/** Блок полей формы создания карточки продукта - расписание обучения */
export const ProductLearningSchedule: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <ControlGroup
            type="bordered"
            title={LOCAL.LABELS.EDUCATION_TIME}
            className={styles.wrapper}
        >
            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <FormItemAdapter
                        label={LOCAL.LABELS.HOURS_DISTANT}
                        fieldName="distantHourCount"
                    >
                        <InputNumberControl
                            formMode={formMode}
                            className={styles.inputNumberControl}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        label={LOCAL.LABELS.HOURS_FACE_TO_FACE}
                        fieldName="intramuralHourCount"
                    >
                        <InputNumberControl
                            formMode={formMode}
                            className={styles.inputNumberControl}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={24}>
                    <FormItemAdapter
                        label={LOCAL.LABELS.DAYS}
                        fieldName="daysCount"
                    >
                        <InputNumberControl
                            formMode={formMode}
                            className={styles.inputNumberControl}
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </ControlGroup>
    )
)
