import React, { RefObject, useLayoutEffect, useRef, useState } from 'react'

export interface WithHeightResizeProps {
    /** вычисленная высота элемента до нижнего края браузера */
    componentHeight?: number
    /** ссылка для DOM-элемента, высоту которого надо считать **/
    componentRef?: RefObject<HTMLDivElement>
    /** использовать стиль height при назначении расчитанной высоты */
    useHeightStyleForResize?: boolean
}

interface AdditionalProps {
    useHeightStyleForResize?: boolean
}

/** HOC расчета высоты компонента до нижнего края браузера с учетом его ресайза */
export function withHeightResize<T = any>(
    Component: React.FC<T & WithHeightResizeProps>,
    options?: AdditionalProps
) {
    const WrappedComponent: React.FC<T & AdditionalProps> = (props) => {
        const componentRef = useRef<HTMLDivElement>(null)
        const [componentHeight, setComponentHeight] = useState<number>()

        useLayoutEffect(() => {
            const handleCalcComponentHeight = () => {
                const elem = componentRef.current
                const coordTop = elem
                    ? elem.getBoundingClientRect().top + window.pageYOffset
                    : 0

                setComponentHeight(window.innerHeight - coordTop)
            }

            handleCalcComponentHeight()

            window.addEventListener('resize', handleCalcComponentHeight)

            return () => {
                window.removeEventListener('resize', handleCalcComponentHeight)
            }
        }, [])

        return (
            <Component
                {...props}
                componentHeight={componentHeight}
                componentRef={componentRef}
                useHeightStyleForResize={
                    props?.useHeightStyleForResize ||
                    options?.useHeightStyleForResize
                }
            />
        )
    }

    return WrappedComponent
}
