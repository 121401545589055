import { LOCAL } from 'core/local'

/**
 * TODO: пока на бэке не реализовано АПИ для работы с мета-данными для вкладок
 * Получить массив объектов вкладок
 * @param {number} recordsCount количество записей
 * @return {object[]} массив объектов вкладок
 */
export const pageTabsRender = (recordsCount?: number) => [
    {
        text: LOCAL.LABELS.ALL,
        key: 'all',
        recordsCount,
    },
]

/**
 * Определяем активную вкладку
 * @param tabKey - ключ вкладки
 * @param activeTab - ключ активной вкладки
 */
export const getActiveTab = (tabKey: string, activeTab?: string) => () =>
    tabKey === activeTab
