import React from 'react'
import { Form } from 'antd'

import { AssessmentReportsContent } from '../AssessmentReportsContent'
import { FIELD_NAMES } from '../AssessmentReportsForm/AssessmentReportsForm.consts'

export const AssessmentReports360 = React.memo(() => (
    <Form.Item name={FIELD_NAMES.CIRCLE}>
        <AssessmentReportsContent namePath={FIELD_NAMES.CIRCLE} />
    </Form.Item>
))
