import React from 'react'
import lowerFirst from 'lodash/lowerFirst'
import {
    AssessmentReportTemplateSectionContract,
    AssessmentType,
} from 'core/api'
import { Store } from 'App.types'
import { isAssessmentTypeEstimate360, isAssessmentTypeManager } from 'utils'

import { AssessmentReports360 } from '../AssessmentReports360'
import { AssessmentReportsManager } from '../AssessmentReportsManager'
import { FIELD_NAMES } from './AssessmentReportsForm.consts'

export const getAssessmentReportByType = (type: AssessmentType) => {
    switch (type) {
        case AssessmentType.Estimate_360:
            return <AssessmentReports360 />

        case AssessmentType.EstimateByManager:
            return <AssessmentReportsManager />
    }
}

export const mapTemplatesForManagerReports = (
    templates: AssessmentReportTemplateSectionContract[]
) =>
    templates.reduce<Store<AssessmentReportTemplateSectionContract[]>>(
        (acc, template) => {
            const role = lowerFirst(template.assessmentRole)

            if (acc[role]) {
                acc[role].push(template)
            } else {
                acc[role] = [template]
            }

            return acc
        },
        {}
    )

export const getReportsTemplatesByAssessmentType = (
    templates: AssessmentReportTemplateSectionContract[],
    type: AssessmentType
) => {
    if (isAssessmentTypeEstimate360(type))
        return { [FIELD_NAMES.CIRCLE]: templates }

    if (isAssessmentTypeManager(type)) {
        return mapTemplatesForManagerReports(templates)
    }
}
