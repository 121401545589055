import { Resolutions } from 'core/configs'
import { TrackHeaderIconContract } from 'core/api'

export const getTrackHeaderIconsByResolution = (
    resolutionForTrack: Resolutions,
    trackHeaderIcons: TrackHeaderIconContract[]
) => {
    switch (resolutionForTrack) {
        case Resolutions.XS:
        case Resolutions.SM:
            return trackHeaderIcons?.slice(0, 1)
        case Resolutions.XXL:
            return trackHeaderIcons
        default:
            return trackHeaderIcons?.slice(0, 3)
    }
}
