import React from 'react'
import cn from 'classnames'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH, MODAL_Z_INDEX } from 'consts'
import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'

import styles from './Popup.module.scss'
import { HEIGHT_ERROR_FACTOR } from './Popup.consts'

export const Popup: React.FC<
    ModalProps & {
        formId?: string
        localLoaderState?: boolean
        bodyWithoutScroll?: boolean
    }
> = React.memo(
    ({
        className,
        formId,
        children,
        onCancel,
        visible,
        width,
        okText,
        footer,
        getContainer,
        okButtonProps,
        localLoaderState,
        maskClosable = false,
        bodyWithoutScroll,
        ...restModalProps
    }) => {
        const bodyStyle = {
            overflowY: bodyWithoutScroll
                ? ('unset' as 'unset')
                : ('auto' as 'auto'),
            maxHeight: window.innerHeight - HEIGHT_ERROR_FACTOR,
        }

        return (
            <Modal
                {...restModalProps}
                okButtonProps={{
                    ...okButtonProps,
                    htmlType: 'submit',
                    form: formId,
                    disabled: okButtonProps?.disabled || localLoaderState,
                }}
                cancelButtonProps={{
                    htmlType: 'button',
                    type: 'link',
                }}
                onCancel={onCancel}
                destroyOnClose
                visible={visible}
                zIndex={MODAL_Z_INDEX.DEFAULT}
                width={width || MODAL_WIDTH.SM}
                okText={okText || LOCAL.ACTIONS.ADD}
                getContainer={getContainer || getDropdownPopupContainerDefault}
                className={cn(
                    styles.wrapper,
                    footer === null && styles.withoutFooter,
                    className
                )}
                footer={footer}
                bodyStyle={bodyStyle}
                maskClosable={maskClosable}
            >
                {children}
            </Modal>
        )
    }
)
