import React from 'react'
import { Collapse } from 'antd'

import { CollapseSingleProps } from './CollapseSingle.types'
import { Title } from '../Title'

export const CollapseSingle: React.FC<CollapseSingleProps> = React.memo(
    ({ children, renderHeader, title, active, className, collapsible }) => (
        <Collapse ghost defaultActiveKey={active ? title : undefined}>
            <Collapse.Panel
                key={title}
                header={
                    renderHeader?.(title) || (
                        <Title bottomIndent="0" type="h5" text={title} />
                    )
                }
                collapsible={collapsible}
                className={className}
            >
                {children}
            </Collapse.Panel>
        </Collapse>
    )
)
