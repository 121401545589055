import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import {
    LOCAL_STORAGE_KEYS,
    LocalStorageHelper,
} from 'core/helpers/LocalStorage'
import {
    UrlParamsForEditProps,
    useFormInitialValues,
    useSaveOrPublishState,
} from 'hooks'
import { WithLoaderProps, withBlockUserRouting } from 'HOCs'
import { eventEmitter } from 'core/helpers/eventEmitter'
import {
    isDocumentStatusInWork,
    isDocumentStatusReadyForWork,
    isFormModeCopy,
    isFormModeView,
    isObjectEmpty,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useParams } from 'react-router-dom'

import { COURSOMETER_EVENT } from './components/TrackForm/components/shared/CoursometerRegistration/CoursometerRegistration.events'
import { InitialValuesBaseType, TrackProps } from './Track.types'
import { TRACK_COMPONENT_CONFIG } from './Track.consts'
import { TrackForm } from './components'
import { openTrackPreview } from './components/TrackForm/TrackForm.utils'

function Track<Response extends InitialValuesBaseType, Request>({
    onBlockUserRouting,
    formMode,
    trackType,
    getInitialValuesFn,
    saveFn,
    publishFn,
    createNewVersionFn,
    mapResponseToFormData,
    updateLoader,
    isLoading,
}: TrackProps<Response, Request> & WithLoaderProps) {
    const urlParams = useParams<UrlParamsForEditProps>()
    const [isPreviewReady, setPreviewReady] = useState(false)
    const [form] = Form.useForm()

    const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()

    const {
        initialValuesForEdit,
        fetchInitialFormValuesById,
    } = useFormInitialValues<Response>({
        requestMethod: getInitialValuesFn,
        updateLoader,
    })

    /**
     * Запрашиваем initial values для формы при наличии id
     */
    useEffect(() => {
        if (urlParams.id) fetchInitialFormValuesById(Number(urlParams.id))

        return () => {
            LocalStorageHelper.removeItem(LOCAL_STORAGE_KEYS.TRACK_PREVIEW)
        }
    }, [fetchInitialFormValuesById, urlParams.id])

    const isTrackSavable =
        isFormModeCopy(formMode) ||
        (!isDocumentStatusInWork(initialValuesForEdit?.status) &&
            !isDocumentStatusReadyForWork(initialValuesForEdit?.status))

    /**
     * Преобразуем полученные данные в валидные начальные значения для формы
     */
    const initialValues = useMemo(() => {
        if (!initialValuesForEdit || isObjectEmpty(initialValuesForEdit, true))
            return

        return mapResponseToFormData(
            initialValuesForEdit,
            isFormModeCopy(formMode)
        )
    }, [formMode, initialValuesForEdit, mapResponseToFormData])

    /**
     * Обработчик открытия окна просмотра
     */
    const handleOpenPreview = useCallback(() => {
        openTrackPreview(form.getFieldsValue())
    }, [form])

    useEffect(() => {
        const refetchForm = () => {
            if (urlParams.id) fetchInitialFormValuesById(Number(urlParams.id))
        }

        eventEmitter.on(COURSOMETER_EVENT, refetchForm)

        return () => {
            eventEmitter.off(COURSOMETER_EVENT, refetchForm)
        }
    }, [fetchInitialFormValuesById, urlParams.id])

    return (
        <PageContent>
            <ContentTitleWithBackLink
                status={initialValuesForEdit?.status}
                formMode={formMode}
            >
                <ButtonsToolbar>
                    <Button
                        type="link"
                        href={TRACK_COMPONENT_CONFIG[trackType].PARENT}
                    >
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    <HasPermissions
                        requiredPermissions={
                            TRACK_COMPONENT_CONFIG[trackType].PERMISSIONS.SAVE
                        }
                        accessDeniedRender={renderAccessDeniedButton({
                            text: LOCAL.ACTIONS.SAVE,
                            type: 'primary',
                        })}
                    >
                        <Button
                            form={FORM_IDS.TRACK_FORM}
                            htmlType="submit"
                            type="primary"
                            onClick={handleSubmitForm(true)}
                            disabled={
                                isFormModeView(formMode) ||
                                isLoading ||
                                !isTrackSavable
                            }
                        >
                            {LOCAL.ACTIONS.SAVE}
                        </Button>
                    </HasPermissions>

                    <HasPermissions
                        requiredPermissions={
                            TRACK_COMPONENT_CONFIG[trackType].PERMISSIONS
                                .PUBLISH
                        }
                        accessDeniedRender={renderAccessDeniedButton({
                            text: LOCAL.ACTIONS.PUBLISH,
                            type: 'primary',
                        })}
                    >
                        <Button
                            form={FORM_IDS.TRACK_FORM}
                            htmlType="submit"
                            type="primary"
                            onClick={handleSubmitForm(false)}
                            disabled={isFormModeView(formMode) || isLoading}
                        >
                            {LOCAL.ACTIONS.PUBLISH}
                        </Button>
                    </HasPermissions>

                    <Button
                        type="primary"
                        disabled={!isPreviewReady}
                        onClick={handleOpenPreview}
                    >
                        {LOCAL.ACTIONS.PREVIEW}
                    </Button>
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <TrackForm
                updateLoader={updateLoader}
                onBlockUserRouting={onBlockUserRouting}
                isSaveDocument={isSaveDocument}
                reFetchInitialFormValues={fetchInitialFormValuesById}
                initialValuesForEdit={initialValues}
                formMode={formMode}
                trackType={trackType}
                setPreviewReady={setPreviewReady}
                form={form}
                saveFn={saveFn}
                publishFn={publishFn}
                createNewVersionFn={createNewVersionFn}
            />
        </PageContent>
    )
}

export default React.memo(withBlockUserRouting(Track, false)) as typeof Track
