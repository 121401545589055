import React from 'react'
import { TrackPictureContract } from 'core/api'

interface ResolveCurrentPictureItemProps {
    picturesCollection?: TrackPictureContract[]
    selectedPictureId?: number
}

/**
 * Функция получения полного набора данных (id, url, name) по текущему выбранному элементу Select
 * @param picturesCollection - Коллекция всех доступных для выбора элементов select'а
 * @param selectedPictureId - id выбранного элемента select'а
 */
export const getPictureData = ({
    picturesCollection,
    selectedPictureId,
}: ResolveCurrentPictureItemProps) =>
    picturesCollection?.find((el) => el.id === selectedPictureId)

interface SelectAddonAfterPictureProps {
    selectedPictureItem?: TrackPictureContract
}

/**
 * Функция рендерит контент под select'ом
 * @param selectedPictureItem - объект, содержащий данные о картинке, которая должна быть отрендерена
 */
export const selectAddonAfterPicture = ({
    selectedPictureItem,
}: SelectAddonAfterPictureProps) => (
    <>
        {selectedPictureItem && (
            <div className="select-addon-after-picture">
                <img
                    className="select-addon-after-picture__img"
                    alt={selectedPictureItem.name}
                    src={selectedPictureItem.url}
                />
            </div>
        )}
    </>
)
