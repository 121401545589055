import React, { FC, memo } from 'react'
import { LOCAL } from 'core/local'
import { PassingStage } from 'components/shared/Passing/PassingStage'
import { getStyles } from 'utils'

import { QuestionnairesPassingGreetingsStageProps } from './QuestionnairesPassingGreetingsStage.types'
import { QuestionnairesPassingHeader } from '../QuestionnairesPassingHeader'
import { makeIconBlocksProps } from './QuestionnairesPassingGreetingsStage.utils'

/** Стадия приветствия прохождения */
export const QuestionnairesPassingGreetingsStage: FC<QuestionnairesPassingGreetingsStageProps> = memo(
    ({
        onStart,
        questionnaire,
        numberOfQuestions,
        attemptsLimited,
        attemptsLimit,
    }) => {
        const presentation =
            questionnaire.assessmentPortfolioPresentation ||
            questionnaire.quizPortfolioPresentation ||
            questionnaire.testPortfolioPresentation

        return (
            <PassingStage
                header={
                    <QuestionnairesPassingHeader
                        styles={getStyles(
                            presentation?.welcomeWindow?.titleTextSettings
                        )}
                        presentationSettings={presentation?.welcomeWindow}
                        {...questionnaire}
                    />
                }
                presentationSettings={presentation?.welcomeWindow}
                showGreeting={presentation?.welcomeWindow?.showGreeting}
                onButtonClick={onStart}
                buttonText={LOCAL.ACTIONS.BEGIN}
                iconBlocksProps={makeIconBlocksProps({
                    numberOfQuestions,
                    timeLimit: questionnaire.timeLimit,
                    finishedPassingCount: questionnaire.finishedPassingCount,
                    attemptsLimited,
                    attemptsLimit,
                    randomAnswerOrder: questionnaire.randomAnswerOrder,
                })}
            />
        )
    }
)
