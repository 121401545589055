import stylesDictionaryPage from 'components/layouts/DictionaryPage/DictionaryPage.module.scss'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { NotificationMailingInformationsService } from 'core/api'
import { TOOLBAR_CONTENT_SETTINGS } from 'components/layouts'
import { createConfirmPopup, createSuccessNotification, getItemId } from 'utils'
import { useToolbarGutterResponsive } from 'components/layouts/DictionaryPage/DictionaryPage.hooks'

import { DeliveryMessageStatistic } from '../DeliveryMessageStatistic'
import { DistributionMailingToolbarProps } from './DistributionMailingToolbar.types'

export const DistributionMailingToolbar: FC<DistributionMailingToolbarProps> = React.memo(
    ({
        titleComponent,
        clearSelection,
        filterSwitch,
        quickSearchComponent,
        selectedMailings,
        reFetchDictionary,
        queryParams,
    }) => {
        const [visibleStatistics, setVisibleStatistics] = useState(false)
        const gutterResponsive = useToolbarGutterResponsive()

        const selectedMailingIdsToResend = useMemo(
            () =>
                selectedMailings
                    .filter(
                        (item) => item.createdEmailCount > item.sendedEmailCount
                    )
                    .map(getItemId),
            [selectedMailings]
        )

        /**
         * Метод для переотправки рассылок с недоотправленными письмами
         */
        const resendTasksMailing = useCallback(async () => {
            try {
                await NotificationMailingInformationsService.resend({
                    body: { ids: selectedMailingIdsToResend },
                })
                createSuccessNotification(
                    LOCAL.MESSAGES.RESEND_TASKS_MAILING_SUCCESS_NOTIFICATION
                )

                await clearSelection?.()
                reFetchDictionary?.()
            } catch (error) {
                console.error(error)
            }
        }, [reFetchDictionary, clearSelection, selectedMailingIdsToResend])

        /**
         * Обработка нажатия по кнопке для повторения отправки рассылки
         */
        const handleClickResendButton = useCallback(() => {
            createConfirmPopup({
                title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                content: LOCAL.MESSAGES.RESEND_TASKS_MAILING,
                okText: LOCAL.ACTIONS.CONFIRM,
                onOk: resendTasksMailing,
            })
        }, [resendTasksMailing])

        const resendButton = useMemo(
            () => (
                <Button
                    disabled={!selectedMailingIdsToResend?.length}
                    onClick={handleClickResendButton}
                    title={
                        selectedMailingIdsToResend?.length
                            ? undefined
                            : LOCAL.TOOLTIPS.SELECT_MAILING_FOR_RESEND
                    }
                >
                    {LOCAL.ACTIONS.REPEAT_SEND}
                </Button>
            ),
            [selectedMailingIdsToResend, handleClickResendButton]
        )

        return (
            <Row {...TOOLBAR_CONTENT_SETTINGS.PROPS} className="pt-20 pb-20">
                <Col className={stylesDictionaryPage.toolbarTitle}>
                    {titleComponent}
                </Col>

                <Col>
                    <Row gutter={gutterResponsive} align="middle">
                        <Col
                            className={
                                stylesDictionaryPage.toolbarQuickSearchAndFilters
                            }
                        >
                            <Row gutter={gutterResponsive} align="middle">
                                <Col
                                    className={
                                        stylesDictionaryPage.toolbarQuickSearch
                                    }
                                >
                                    {quickSearchComponent}
                                </Col>

                                <Col
                                    className={
                                        stylesDictionaryPage.toolbarFilters
                                    }
                                >
                                    {filterSwitch}
                                </Col>
                            </Row>
                        </Col>

                        <Col className={stylesDictionaryPage.toolbarActions}>
                            <Row gutter={TOOLBAR_CONTENT_SETTINGS.GUTTER.SM}>
                                <Col>{resendButton}</Col>

                                <Col>
                                    <Button
                                        onClick={() =>
                                            setVisibleStatistics(true)
                                        }
                                        title={
                                            LOCAL.TOOLTIPS
                                                .ALL_REGISTRY_STATISTIC
                                        }
                                    >
                                        {LOCAL.LABELS.STATISTIC}
                                    </Button>
                                </Col>

                                <Col>
                                    <Button onClick={reFetchDictionary}>
                                        {LOCAL.ACTIONS.UPDATE_LIST}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>

                        {visibleStatistics && (
                            <DeliveryMessageStatistic
                                modalVisibility={visibleStatistics}
                                handleCloseModal={() =>
                                    setVisibleStatistics(false)
                                }
                                queryParams={queryParams}
                            />
                        )}
                    </Row>
                </Col>
            </Row>
        )
    }
)
