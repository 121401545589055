import React from 'react'
import { DEVELOPMENT_GOALS_REQUIRED_PERMISSIONS, MODAL_WIDTH } from 'consts'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import {
    DevelopmentGoalsSearchContract,
    DevelopmentGoalsSearchResultContract,
    DevelopmentGoalsService,
    UserPermission,
} from 'core/api'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRouteMeta } from 'routing/routeNames.utils'

import { DEVELOPMENT_GOALS_TABLE_COLUMNS } from './DevelopmentGoals.consts'
import { DevelopmentGoalsFilters, DevelopmentGoalsForm } from './components'

/** Справочник целей развития */
export const DevelopmentGoals: React.FC = React.memo(() => (
    <DictionaryPage<
        DevelopmentGoalsSearchContract,
        DevelopmentGoalsSearchResultContract
    >
        dictionaryKey={DICTIONARY_KEYS.DEVELOPMENT_GOALS}
        searchMethod={DevelopmentGoalsService.search}
        deleteMethod={DevelopmentGoalsService.setAsArchive}
        contentTitle={getRouteMeta(ROUTE_NAMES.DEVELOPMENT_GOALS)}
        tableColumns={DEVELOPMENT_GOALS_TABLE_COLUMNS}
        createPermissions={[
            ...DEVELOPMENT_GOALS_REQUIRED_PERMISSIONS,
            UserPermission.PurposeSave,
        ]}
        createRecordModal={{
            component: DevelopmentGoalsForm,
            formId: FORM_IDS.DEVELOPMENT_GOALS_FORM,
            modalOptions: {
                title: LOCAL.LABELS.DEVELOPMENT_GOAL_CREATION,
                okText: LOCAL.ACTIONS.SAVE,
                width: MODAL_WIDTH.MD,
            },
        }}
        filterComponent={DevelopmentGoalsFilters}
        tableFiltersOptions={{
            initialValues: {
                orderRules: ORDER_RULES_WITH_CHANGE_DATE,
            },
        }}
    />
))
