import { LOCAL } from 'core/local'
import {
    UserFioContract,
    UserProfileFavoriteRecommendationContract,
    UserProfileRecommendationContract,
    UserProfileRecommendationType,
} from 'core/api'
import { getShortFio } from 'utils'

/**
 * Формируем отображаемый текст имени пользователя, который менял цели у просматриваемого
 * пользователя
 * @param changeUserData имя пользователя, который менял элемент
 * @param changedByYou флаг, показывает, что сущность менялась текущим пользователем
 */
export const getChangeUserText = (
    changeUserData: UserFioContract,
    changedByYou: boolean
) =>
    changedByYou
        ? LOCAL.LABELS.CHANGED_BY_YOU.toLowerCase()
        : getShortFio(changeUserData)

/**
 * Проверка на тип рекомендации
 * @param type тип рекомендации
 */
const isRecommendationTypeActivity = (type: UserProfileRecommendationType) =>
    type === UserProfileRecommendationType.Activity
const isRecommendationTypeTrack = (type: UserProfileRecommendationType) =>
    type === UserProfileRecommendationType.DevelopmentTrack

/**
 * Проверка, является ли рекомендация выбранной пользователем
 * @param recommendation объект рекомендации для проверки
 * @param chosen массивы выбранных рекомендаций
 */
export const getChosen = (
    recommendation: UserProfileRecommendationContract,
    chosen?: UserProfileFavoriteRecommendationContract[]
): UserProfileFavoriteRecommendationContract | undefined =>
    chosen?.find(
        (item) =>
            (isRecommendationTypeActivity(item.type) &&
                item.activityId === recommendation.activityId) ||
            (isRecommendationTypeTrack(item.type) &&
                item.developmentTrackId === recommendation.developmentTrackId)
    )
