import React, { useCallback, useState } from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import { LOCAL } from 'core/local'
import {
    NotificationMailingInfoSearchContract,
    NotificationMailingInfoSearchResultContract,
    NotificationMailingInformationsService,
    SortDirection,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsCommonProps } from 'App.types'
import { getItemId } from 'utils'
import { useParams } from 'react-router-dom'

import styles from './DistributionMailing.module.scss'
import {
    DistributionMailingFilter,
    DistributionMailingToolbar,
} from './components'
import { getDistributionMailingColumns } from './DistributionMailing.config'

/** Страница реестра рассылок */
export const DistributionMailing = React.memo(() => {
    const { taskId } = useParams<UrlParamsCommonProps<'taskId'>>()

    const [selectedMailings, setSelectedMailings] = useState<
        NotificationMailingInfoSearchResultContract[]
    >([])

    const clearSelection = useCallback(() => {
        setSelectedMailings([])
    }, [])

    return (
        <DictionaryPage<
            NotificationMailingInfoSearchContract,
            NotificationMailingInfoSearchResultContract
        >
            contentTitle={LOCAL.CATALOGS.DISTRIBUTIONS}
            dictionaryKey={DICTIONARY_KEYS.DISTRIBUTION_MAILING}
            searchMethod={NotificationMailingInformationsService.search}
            quickSearchMethod={
                NotificationMailingInformationsService.searchText
            }
            filterComponent={DistributionMailingFilter}
            tableFiltersOptions={{
                initialValues: {
                    taskId: taskId ? Number(taskId) : undefined,
                    orderRules: [
                        { field: 'mailingDate', direction: SortDirection.Desc },
                    ],
                },
            }}
            createRecordLink={{
                path: ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_CREATE,
            }}
            tableColumnsRender={({ reFetchDictionary }) =>
                getDistributionMailingColumns({ reFetchDictionary })
            }
            toolbarContentRender={(props) => (
                <DistributionMailingToolbar
                    {...props}
                    selectedMailings={selectedMailings}
                    clearSelection={clearSelection}
                />
            )}
            columnOptionsClassName={styles.columnOptions}
            rowSelection={{
                type: 'checkbox',
                onChange: (_, data) => setSelectedMailings(data),
                selectedRowKeys: selectedMailings.map(getItemId),
                preserveSelectedRowKeys: true,
            }}
        />
    )
})
