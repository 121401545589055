import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { IconsAdapter } from 'components/shared'
import { isHomeworkOnChecking } from 'utils/conditions.utils'
import { useElementVisible } from 'hooks'

import styles from './ReferenceItem.module.scss'
import { ReferenceItemProps } from './ReferenceItem.types'

export const ReferenceItem: React.FC<ReferenceItemProps> = React.memo(
    ({ label, text, isTutorAnswer = true, form, fieldName = 0 }) => {
        const { toggleElementVisible, elementVisible } = useElementVisible()

        const textRef = React.useRef<HTMLDivElement>(null)

        const [showChevronBtn, setShowChevronBtn] = useState(false)

        const handleClickOnChat = useCallback(() => {
            const status = form?.getFieldValue([
                'taskPassings',
                fieldName,
                'status',
            ])

            if (isHomeworkOnChecking(status)) {
                form?.setFields([
                    {
                        name: ['taskPassings', fieldName, 'comment'],
                        value: text,
                    },
                ])
            }
        }, [form, fieldName, text])

        useEffect(() => {
            if (
                textRef.current &&
                textRef.current?.scrollWidth > textRef.current?.offsetWidth
            ) {
                setShowChevronBtn(true)
            }
        }, [])

        return (
            <div
                className={cn(
                    styles.wrapper,
                    isTutorAnswer && styles.tutorAnswer
                )}
            >
                {showChevronBtn && (
                    <span
                        className={cn(
                            styles.icon,
                            elementVisible && styles.iconOpened
                        )}
                        onClick={toggleElementVisible}
                    >
                        <IconsAdapter iconType="CustomIconChevron" />
                    </span>
                )}

                {isTutorAnswer && (
                    <span
                        className={styles.chatIcon}
                        onClick={handleClickOnChat}
                    >
                        <IconsAdapter iconType="CustomIconChat" />
                    </span>
                )}

                <span className={styles.label}>{`${label}:`}</span>

                <div
                    className={cn(
                        styles.text,
                        elementVisible && styles.textOpened
                    )}
                    ref={textRef}
                >
                    {text}
                </div>
            </div>
        )
    }
)
