import React from 'react'
import {
    ThematicBlockContent,
    ThematicBlockHeader,
} from 'components/pages/TrackPreviewPassing/components/ThematicBlockLayer/components'
import { isTrainingMaterialFormatTinCan } from 'utils'

import { BlockPassingLayerProps } from './BlockPassingLayer.types'

/** Слой прохождения тематического блока мероприятия */
export const BlockPassingLayer: React.FC<BlockPassingLayerProps> = React.memo(
    (props) => {
        const { thematicBlock, onViewMaterial, setProgressForSend } = props

        return (
            <>
                {!isTrainingMaterialFormatTinCan(
                    thematicBlock.course?.format
                ) && <ThematicBlockHeader {...props} />}

                <ThematicBlockContent
                    {...thematicBlock}
                    onViewMaterial={onViewMaterial}
                    setProgressForSend={setProgressForSend}
                />
            </>
        )
    }
)
