import React from 'react'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { PositionSearchContract } from 'core/api'

/**
 * Форма фильтрации
 */
export const PositionsFilters: React.FC<FiltersProps<
    PositionSearchContract
>> = React.memo((props) => (
    <FilterForm {...props}>
        <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
            <InputControl />
        </FormItemAdapter>
    </FilterForm>
))
