import {
    NotificationDestinationCategoryCode,
    NotificationMailingTaskStatus,
} from 'core/api'

/**
 * Проверка статуса рассылки
 * @param status - статус рассылки
 */
export const isMailingStatusDraft = (status?: NotificationMailingTaskStatus) =>
    status === NotificationMailingTaskStatus.Draft

export const isMailingStatusCanceled = (
    status?: NotificationMailingTaskStatus
) => status === NotificationMailingTaskStatus.Canceled

export const isMailingStatusExpectation = (
    status?: NotificationMailingTaskStatus
) => status === NotificationMailingTaskStatus.Expectation

export const isMailingStatusInProgress = (
    status?: NotificationMailingTaskStatus
) => status === NotificationMailingTaskStatus.InProgress

export const isMailingStatusSuspended = (
    status?: NotificationMailingTaskStatus
) => status === NotificationMailingTaskStatus.Suspended

export const isMailingStatusEnded = (status?: NotificationMailingTaskStatus) =>
    status === NotificationMailingTaskStatus.Ended

/**
 * Проверка категории назначения
 * @param category категория назначения
 */
export const isNotificationDestinationCategoryTrack = (
    category?: NotificationDestinationCategoryCode
) => category === NotificationDestinationCategoryCode.Track
