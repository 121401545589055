import React from 'react'
import cn from 'classnames'
import { Popover } from 'antd'

import styles from './StagesIndicator.module.scss'
import { StagesIndicatorProps } from './StagesIndicator.types'

/**
 * Шкала с индикацией статусов этапов определенного процесса
 */
export const StagesIndicator: React.FC<StagesIndicatorProps> = React.memo(
    ({ dataSource }) => (
        <div className={styles.wrapper}>
            {dataSource?.map(({ statusClassName, title, key }) => (
                <Popover
                    content={title || ''}
                    key={key}
                    overlayClassName={styles.popover}
                >
                    <span className={cn(styles.stage, statusClassName)} />
                </Popover>
            ))}
        </div>
    )
)
