import {
    DICTIONARIES_HELPER_EVENTS,
    DictionariesHelper,
    DictionaryKeys,
} from 'core/helpers/DictionariesHelper'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { useEffect, useMemo, useState } from 'react'

export type DictionariesStoreType = typeof DictionariesHelper.store

export const useDictionariesHelper = (dictionaryKeys: DictionaryKeys[]) => {
    const [dictionairesUpdated, setDictionairesUpdated] = useState(false)

    useEffect(() => {
        DictionariesHelper.init(dictionaryKeys)
    }, [dictionaryKeys])

    useEffect(() => {
        const handleDictionariesLoaded = () => setDictionairesUpdated(true)

        eventEmitter.once(
            DICTIONARIES_HELPER_EVENTS.ON_DICTIONARIES_LOADED,
            handleDictionariesLoaded
        )

        return () => {
            eventEmitter.removeListener(
                DICTIONARIES_HELPER_EVENTS.ON_DICTIONARIES_LOADED,
                handleDictionariesLoaded
            )
        }
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => DictionariesHelper.store, [dictionairesUpdated])
}
