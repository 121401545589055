import find from 'lodash/find'
import {
    PassingFormQuestionAnswerContract,
    QuestionPublicContract,
} from 'core/api'

export const isListCompleted = (
    list: QuestionPublicContract[],
    confirmedAnswers: PassingFormQuestionAnswerContract[]
) =>
    list.every((question) =>
        find(confirmedAnswers, ['questionId', question.id])
    )
