import React from 'react'

import { TableActionTrigger } from './components'
import { TriggerOptionsProps } from './TableActionBtn.types'

/** Получить компонент-триггер с определенным набором характеристик */
export const getTableActionTrigger = <T extends TriggerOptionsProps>(
    triggerOptions: TriggerOptionsProps
) => (props: T) => (
    <TableActionTrigger
        {...props}
        {...triggerOptions}
        title={props?.title || triggerOptions?.title}
    />
)
