import React from 'react'
import cn from 'classnames'
import { AttachmentContract } from 'core/api'
import { Button } from 'components/shared/Button'
import { LOCAL } from 'core/local'

import styles from './VideoAttachment.module.scss'
import { IconsAdapter } from '../IconsAdapter'

export const VideoAttachment = React.memo(
    ({ questionAttachment }: { questionAttachment: AttachmentContract }) => (
        <div className={styles.message}>
            {`${LOCAL.MESSAGES.QUESTION_CONTAINS_VIDEO_ATTACHMENT}.`}

            <div className={styles.video}>
                {questionAttachment.fileName}

                <Button
                    type="link"
                    className={cn('ant-btn-icon-only', styles.playButton)}
                    title={LOCAL.ACTIONS.PREVIEW}
                >
                    <a
                        target="_blank"
                        href={questionAttachment.url}
                        rel="noreferrer"
                    >
                        <IconsAdapter iconType="PlaySquareOutlined" />
                    </a>
                </Button>
            </div>
        </div>
    )
)
