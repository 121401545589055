import noAvatar from 'core/images/no-avatar.png'
import React, { useMemo } from 'react'
import { Button } from 'components/shared'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { formatDate } from 'utils'

import styles from './UserProfileInfo.module.scss'
import { AVATAR_SIZE } from './UserProfileInfo.consts'
import { UserProfileInfoProps } from './UserProfileInfo.types'

/**
 * Краткая информация о пользователе
 */
export const UserProfileInfo: React.FC<UserProfileInfoProps> = React.memo(
    ({ user }) => {
        const renderedTooltip = useMemo(
            () => (
                <div className={styles.tooltipWrapper}>
                    <h2>{LOCAL.LABELS.CONTACT_INFO}</h2>

                    <div className={styles.contactWrapper}>
                        <span className={styles.contactLabel}>
                            {LOCAL.LABELS.PHONE}
                        </span>

                        {user?.mobilePhone || LOCAL.LABELS.ABSENT}
                    </div>

                    <div className={styles.contactWrapper}>
                        <span className={styles.contactLabel}>
                            {LOCAL.LABELS.EMAIL}
                        </span>

                        {user?.email || LOCAL.LABELS.ABSENT}
                    </div>

                    {user?.oibAdditionalContacts?.map((el, index) => (
                        <div key={index} className={styles.contactWrapper}>
                            <span className={styles.contactLabel}>
                                {el.kindName}
                            </span>

                            {el.value}
                        </div>
                    ))}
                </div>
            ),
            [user]
        )

        return (
            <Row gutter={15} className={styles.wrapper}>
                <Col
                    xs={{
                        order: 2,
                        span: 24,
                    }}
                    md={{
                        order: 1,
                        span: 14,
                    }}
                >
                    <div className={styles.status}>
                        {`[${
                            !user?.isArchived
                                ? LOCAL.ACTIVE_EMPLOYEE
                                : LOCAL.NOT_ACTIVE_EMPLOYEE
                        }]`}
                    </div>

                    <div>
                        {`${LOCAL.LABELS.SNILS} - ${
                            user?.snils || LOCAL.LABELS.ABSENT
                        }`}
                    </div>

                    <div>
                        {`${LOCAL.LABELS.BIRTHDAY} - ${
                            formatDate(user?.birthday) || LOCAL.LABELS.UNKNOWN
                        }`}
                    </div>

                    <Button
                        type="link"
                        title={renderedTooltip}
                        tooltipOptions={{ trigger: 'click' }}
                    >
                        {LOCAL.LABELS.CONTACT_INFO}
                    </Button>
                </Col>

                <Col
                    xs={{
                        order: 1,
                        span: 24,
                    }}
                    md={{
                        order: 2,
                        span: 10,
                    }}
                    className={styles.avatar}
                >
                    <img
                        src={noAvatar}
                        alt={LOCAL.LABELS.USER_AVATAR}
                        width={AVATAR_SIZE}
                        height={AVATAR_SIZE}
                    />
                </Col>
            </Row>
        )
    }
)
