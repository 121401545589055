import { ChartDataset } from 'chart.js'
import { SpiderWebDiagramAssessment360Contract } from 'core/api'
import { getRoundedOneCharNumber } from 'utils'

import {
    AcceptedKeysProps,
    DatasetItemConfigProps,
} from './SpiderWebDiagramAssessment360.types'

/**
 * Получить значение полей объекта компетенции, которые содержат одну из оценок уровня данной
 * компетенции
 * @param scoreProperty поле объекта компетенции, содержащее оценку
 * @param competenciesCollection коллекция компетенций
 * @returns {number[]} массив оценок из всех компетенций в коллекции для текущего свойства
 */
export const getCompetenceCertainPropertyScore = (
    scoreProperty: AcceptedKeysProps,
    competenciesCollection: SpiderWebDiagramAssessment360Contract[]
): number[] =>
    competenciesCollection
        .reduce<number[]>((acc, el) => [...acc, el[scoreProperty]], [])
        .map((number) => Number(getRoundedOneCharNumber(number)))

/**
 * Функция создает элемент dataset'а диаграммы "радар" на основе объекта настроек и коллекции
 * компетенций
 * @param datasetItemConfig объект с настройками для создания элемента dataset'a
 * @param competenciesCollection коллекция компетенций для построения диаграммы
 * @returns {ChartDataSets} элемент dataset'a диаграммы
 */
export const getRadarChartDatasetItem = (
    datasetItemConfig: DatasetItemConfigProps,
    competenciesCollection: SpiderWebDiagramAssessment360Contract[]
): ChartDataset => ({
    data: getCompetenceCertainPropertyScore(
        datasetItemConfig.dataKey,
        competenciesCollection
    ),

    ...datasetItemConfig,
})
