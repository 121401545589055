import React, { useCallback, useEffect } from 'react'
import {
    COL_xl6_lg12_xs24,
    LEARNING_FORMATS_MAPPED,
    PRODUCT_TYPES_MAPPED,
    ROW_GUTTER,
    TREE_SELECT_NORMALIZE_TYPE,
} from 'consts'
import {
    CatalogItemSearchContract,
    CompetenciesService,
    ThemesService,
} from 'core/api'
import { Col, Row } from 'antd'
import { FilterForm } from 'components/shared'
import {
    FormItemAdapter,
    MultiSelectControl,
    SelectControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { useDictionaries } from 'hooks'

import {
    CatalogSearchFiltersExtendedProps,
    DictionariesStateProps,
} from './CatalogSearchFilterExtended.types'

/**
 * Расширенная форма фильтрации элементов каталога
 */
export const CatalogSearchFilterExtended: React.FC<CatalogSearchFiltersExtendedProps<
    CatalogItemSearchContract
>> = React.memo(({ onSetFilters, onResetFilters, setVisibleSearchResults }) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesStateProps
    >()

    const handleFinish = useCallback(
        (values: Partial<CatalogItemSearchContract>) => {
            setVisibleSearchResults(true)
            onSetFilters(values)
        },
        [onSetFilters, setVisibleSearchResults]
    )

    const handleClear = useCallback(() => {
        setVisibleSearchResults(false)
        onResetFilters?.()
    }, [onResetFilters, setVisibleSearchResults])

    useEffect(() => {
        handleFetchDictionaries({
            themes: [ThemesService.getAll(), TREE_SELECT_NORMALIZE_TYPE],
            competencies: [
                CompetenciesService.getAll(),
                TREE_SELECT_NORMALIZE_TYPE,
            ],
        })
    }, [handleFetchDictionaries])

    return (
        <FilterForm onSetFilters={handleFinish} onResetFilters={handleClear}>
            <Row gutter={ROW_GUTTER}>
                <Col {...COL_xl6_lg12_xs24}>
                    <FormItemAdapter
                        fieldName="themeIds"
                        label={LOCAL.LABELS.THEMATIC_SECTION}
                    >
                        <MultiSelectControl treeData={dictionaries?.themes} />
                    </FormItemAdapter>
                </Col>

                <Col {...COL_xl6_lg12_xs24}>
                    <FormItemAdapter
                        fieldName="studyingFormats"
                        label={LOCAL.LABELS.LEARNING_FORMAT}
                    >
                        <SelectControl
                            mode="multiple"
                            values={LEARNING_FORMATS_MAPPED}
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>

                <Col {...COL_xl6_lg12_xs24}>
                    <FormItemAdapter
                        fieldName="competenceIds"
                        label={LOCAL.LABELS.COMPETENCIES_DEVELOPS}
                    >
                        <MultiSelectControl
                            treeData={dictionaries?.competencies}
                        />
                    </FormItemAdapter>
                </Col>

                <Col {...COL_xl6_lg12_xs24}>
                    <FormItemAdapter
                        fieldName="types"
                        label={LOCAL.LABELS.SHAPE}
                    >
                        <SelectControl
                            mode="multiple"
                            values={PRODUCT_TYPES_MAPPED}
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    )
})
