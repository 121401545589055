import React from 'react'
import { PassingIconBlockProps } from 'components/shared/Passing/PassingIconBlock'
import { formatDate } from 'utils'

/** Создаём массив блоков с иконками и текстом для стадии */
export const makeIconBlocksProps = (
    date: Date,
    numberOfAnswers: number,
    numberOfQuestions: number
): PassingIconBlockProps[] => [
    {
        iconType: 'CalendarOutlined',
        content: (
            <>
                {'дата проведения:'}

                <br />

                {formatDate(date)}
            </>
        ),
    },
    {
        iconType: 'UnorderedListOutlined',
        content: (
            <>
                {'пройдено вопросов:'}

                <br />

                {`${numberOfAnswers} из ${numberOfQuestions}`}
            </>
        ),
    },
]
