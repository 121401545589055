import { Resolutions } from 'core/configs'
import { useMediaQuery } from 'react-responsive'

type DirectionType = 'max' | 'min'

export function useBreakpoint(
    breakpoint: keyof typeof Resolutions,
    direction: DirectionType = 'max'
) {
    const width =
        direction === 'max'
            ? +Resolutions[breakpoint] - 1
            : Resolutions[breakpoint]

    return useMediaQuery({
        query: `(${direction}-width: ${width}px)`,
    })
}
