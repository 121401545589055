import React from 'react'
import { FormDataStagesStylesProps } from 'components/pages/Track/components/TrackForm/TrackForm.types'
import { Resolutions } from 'core/configs'
import { TrackLogoList } from 'components/shared/TrackLogoList'
import { usePrepareLayer } from 'components/pages/TrackPassing/components/TrackLayerPassing/TrackLayerPassing.hook'

import styles from './TrackLayerPreview.module.scss'
import { TrackLayerProps } from './TrackLayerPreview.types'
import { TrackStagePreview } from '../TrackStagePreview'

export const TrackLayerPreview: React.FC<TrackLayerProps> = React.memo(
    ({ trackInfo }) => {
        const {
            resolution,
            resolutionForTrack,
            containerWithXScroll,
            trackCanvases,
            trackCanvasStyles,
            trackContainerStyles,
        } = usePrepareLayer({ trackInfo })

        return (
            <div
                ref={containerWithXScroll}
                className={styles.wrapper}
                style={trackContainerStyles}
            >
                {trackInfo?.trackHeaderIcons && (
                    <TrackLogoList
                        resolution={resolutionForTrack}
                        items={trackInfo.trackHeaderIcons}
                        className={styles.headerIconsWrapper}
                    />
                )}

                <div className={styles.canvas} style={trackCanvasStyles}>
                    {trackInfo &&
                        trackCanvases?.[
                            resolution
                        ]?.trackStageStyles?.map((stageStyle, index) => (
                            <TrackStagePreview
                                key={index}
                                index={index}
                                stageStyle={
                                    stageStyle as FormDataStagesStylesProps
                                }
                                stage={trackInfo.stages?.[index] as any}
                                canvasKey={Resolutions.XXL}
                            />
                        ))}
                </div>
            </div>
        )
    }
)
