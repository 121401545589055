import React, { useCallback } from 'react'
import { MODAL_WIDTH, MODAL_Z_INDEX } from 'consts'
import { Modal } from 'antd'

import styles from './QuestionnaireModal.module.scss'
import { QuestionnaireModalForm } from './QuestionnaireModalForm'
import { QuestionnaireModalProps } from './QuestionnaireModal.types'

/**
 * Визуализация прохождения опросного листа  и теста в модальном окне
 */
export const QuestionnaireModal: React.FC<QuestionnaireModalProps> = React.memo(
    ({
        initialValues,
        onClose,
        componentWrapper: Wrapper,
        onFinishThematicBlock,
        maskClosable = false,
    }) => {
        const handleRequestFinish = useCallback(() => {
            onFinishThematicBlock?.()
            onClose()
        }, [onFinishThematicBlock, onClose])

        return (
            <Modal
                maskClosable={maskClosable}
                visible
                width={MODAL_WIDTH.MD}
                footer={null}
                className={styles.wrapper}
                centered
                onCancel={onClose}
                keyboard={false}
                zIndex={MODAL_Z_INDEX.DEFAULT}
            >
                <Wrapper
                    onRequestFinish={handleRequestFinish}
                    initialValues={initialValues}
                    component={QuestionnaireModalForm}
                />
            </Modal>
        )
    }
)
