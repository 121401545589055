import {
    AppointmentUserContract,
    AssessmentProjectContract,
    AssessmentProjectPublishContract,
    AssessmentRole,
} from 'core/api'
import { DeepPartial, WithFormInstanceProps } from 'App.types'
import {
    getItemId,
    getUserShortInfo,
    isAssessmentTypeManager,
    normalizeDataForSelectAndRadio,
    validateDate,
} from 'utils'

import { AssesmentProjectCompetenceFormContract } from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.types'
import {
    AssessmentProjectFormValues,
    AssessmentProjectPortfolioFormValues,
    MapFormDataToRequestProps,
} from './AssessmentProjectsForm.types'
import {
    mapCompetenciesByGroup,
    normalizeInitialDataForValue,
} from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.utils'

/** Получить id компетенций
 * @param competencies - компетенции
 */
export const getCompetenciesIds = (
    competencies?: AssesmentProjectCompetenceFormContract[]
) => competencies?.map(({ competence }) => competence?.id)

/** Маппинг данных пользователя под значения формы
 * @param user - пользователь
 */
export const mapUserData = (user: AppointmentUserContract) => ({
    ...user,
    structuralUnitsAndPositions: [
        {
            structuralUnit: user.organization || {},
            position: user.position || {},
        },
    ],
})

/** Мапинг значений формы для отправки
 * @param values - значения формы
 */
export const mapFormDataToRequest = ({
    assessmentPortfolios,
    holdingDate,
    structuralUnitIds,
    managerParameters,
    ...restValues
}: MapFormDataToRequestProps) =>
    (({
        ...restValues,
        circleAppointments: assessmentPortfolios?.map((portfolio) => ({
            portfolioId: portfolio.id,
            appointmentAssessments: portfolio.assessmentObjects?.map(
                (assessmentObject) => ({
                    id: assessmentObject.appointmentId,
                    circleParameters: {
                        candidateId: assessmentObject.id,
                        respondents: assessmentObject.respondents?.map(
                            ({ id, role }) => ({
                                respondentId: id,
                                role,
                            })
                        ),
                    },
                })
            ),
        })),
        managerParameters: {
            assessmentId: managerParameters?.assessmentId,
            managerId: managerParameters?.managers?.[0]?.id,
            managerCompetenceIds: getCompetenciesIds(
                managerParameters?.managers?.[0]?.competencies
            ),
            candidateParameters: managerParameters?.coworkers?.map(
                (coworker, index) => ({
                    competences: coworker.competencies?.map(
                        ({ positionProfileId, competence }) => ({
                            competenceId: competence.id,
                            positionProfileId,
                        })
                    ),
                    candidateId: coworker.id,
                    selfAssessment: coworker.selfAssessment,
                    assessedPositionNumber: index + 1,
                })
            ),
            checkingPersonIds: managerParameters?.reviewers?.map(getItemId),
        },
        portfolioId: managerParameters?.portfolio?.id,
        structuralUnitIds: structuralUnitIds?.map((unit) => unit.value),
        dateFrom: holdingDate?.[0],
        dateTo: holdingDate?.[1],
    } as DeepPartial<
        AssessmentProjectPublishContract
    >) as AssessmentProjectPublishContract)

/** Мапинг ответа в форм дату
 * @param response - данные с бэкенда
 */
export const mapResponseToFormData = ({
    dateFrom,
    dateTo,
    circleAppointments,
    structuralUnits,
    managerParameters,
    purpose,
    ...restValues
}: AssessmentProjectContract) => ({
    purposeId: purpose?.id,
    holdingDate:
        validateDate(dateFrom) && validateDate(dateTo)
            ? [dateFrom, dateTo]
            : undefined,
    structuralUnitIds: structuralUnits.map(normalizeDataForSelectAndRadio),
    assessmentPortfolios: circleAppointments?.map(
        ({
            appointmentsAssessment,
            portfolioQuestionnaires,
            portfolioName,
            portfolioId,
        }) => ({
            name: portfolioName,
            id: portfolioId,
            circleParameters: {
                circlePortfolioQuestionnaires: portfolioQuestionnaires,
            },
            assessmentObjects: appointmentsAssessment.map(
                ({ circleParameters, id }) => ({
                    ...mapUserData(circleParameters.candidate),
                    appointmentId: id,
                    respondents: circleParameters.respondents.map(
                        ({ respondent, role }) => ({
                            ...mapUserData(respondent),
                            role,
                        })
                    ),
                })
            ),
        })
    ),
    managerParameters: {
        assessmentId: managerParameters?.assessmentId,
        managers: [
            {
                ...getUserShortInfo(managerParameters?.manager || {}),
                competencies: mapCompetenciesByGroup(
                    managerParameters?.managerCompetencies
                ),
            },
        ],
        coworkers: normalizeInitialDataForValue(
            managerParameters?.candidateParameters
        ),
        reviewers: managerParameters?.checkingPersons?.map(getUserShortInfo),
        portfolio: managerParameters?.portfolio,
    },
    ...restValues,
})

// TODO: Временное решение по запросам аналитиков, в будущем переделать реализацию подсветки компетенций по которым не сформированы опросные листы
/** Валидация компетенций для оценки руководителем по сформированности опросных листов
 * @param values - значения формы
 * @param form - сущность формы
 */
export const validateCompetenciesForManagerEstimate = (
    values: AssessmentProjectFormValues,
    form: WithFormInstanceProps['form']
) => {
    if (!isAssessmentTypeManager(values.assessmentType)) return

    function mapCompetenciesToCheckQuestionnaire(
        competencies?: AssesmentProjectCompetenceFormContract[]
    ) {
        return competencies?.map((competence) => ({
            ...competence,
            hasQuestionnaire: competence.hasQuestionnaire || false,
        }))
    }

    const manager = values.managerParameters?.managers?.[0]

    form?.setFields([
        {
            name: ['managerParameters', 'coworkers'],
            value: values.managerParameters?.coworkers?.map((coworker) => ({
                ...coworker,
                competencies: mapCompetenciesToCheckQuestionnaire(
                    coworker?.competencies
                ),
            })),
        },
        {
            name: ['managerParameters', 'managers', 0],
            value: {
                ...manager,
                competencies: mapCompetenciesToCheckQuestionnaire(
                    manager?.competencies
                ),
            },
        },
    ])
}

/**
 * Проверить требования относительно количества респондентов по ролям
 * @param assessmentPortfolios - портфели оценки
 */
export const validateRespondentsRoleCount = (
    assessmentPortfolios: AssessmentProjectPortfolioFormValues[]
) => {
    if (!assessmentPortfolios?.length) return false

    return assessmentPortfolios.every((portfolio) =>
        portfolio?.assessmentObjects?.every(({ respondents }) => {
            const respondentsCountByRoles = respondents?.reduce(
                (acc, item) => {
                    if (!item.role) return acc

                    if (item.role === AssessmentRole.Manager) {
                        acc.isManagerExist = true
                    }

                    return {
                        ...acc,
                        count: acc.count + 1,
                    }
                },
                {
                    count: 0,
                    isManagerExist: false,
                }
            )

            return (
                respondentsCountByRoles?.count &&
                respondentsCountByRoles.count >= 5 &&
                respondentsCountByRoles.isManagerExist
            )
        })
    )
}
