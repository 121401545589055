import { NotificationContract, NotificationsMetadataContract } from 'core/api'

export interface BaseNotificationProps {
    errorMessage?: string
}

/**
 * Оповещение из хаба
 * @param id - идентификатор
 * @param title - заголовок
 * @param text - сообщение
 * @param link - контекстная ссылка
 * @param meta - метаданные оповещений
 */
export interface NotificationProps
    extends Pick<NotificationContract, 'link' | 'id' | 'title'> {
    text: string
    meta: NotificationsMetadataContract
    modalType: 'Success' | 'Info' | 'Error' | 'Warning'
}

export interface TextDocumentTaskNotificationProps
    extends BaseNotificationProps {
    taskId: string
    documentId: string
    documentLdeId: string
    isSuccessful: boolean
}

export enum CourseUploadStateTypes {
    Create = 'Create',
    InProcess = 'InProcess',
    Finished = 'Finished',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
}

export enum NotificationModalTypes {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}

export interface CourseUploadStatusChangedProps extends BaseNotificationProps {
    fileObjectKey: string
    state: CourseUploadStateTypes
}

export interface ReportConvertedNotificationProps
    extends BaseNotificationProps {
    bucket: string
    fileName: string
    fileSize: string
    key: string
    url: string
}
