import { ComponentCommonProps } from 'App.types'
import {
    ExpertEvaluationListCompetenceContract,
    ExpertEvaluationListContract,
    ExpertEvaluationListIndicatorContract,
} from 'core/api'
import { WithBlockUserRoutingComponentProps } from 'HOCs'

export enum ActionForFormType {
    'Next' = 'Next',
    'Prev' = 'Prev',
    'Finish' = 'Finish',
}

export enum SideType {
    'Strong' = 'Strong',
    'Weak' = 'Weak',
}

export interface AssessmentEvaluationFormProps
    extends WithBlockUserRoutingComponentProps,
        ComponentCommonProps {
    currentQuestionnaireIndex: number
    setCurrentQuestionnaireIndex: React.Dispatch<React.SetStateAction<number>>
    evaluationListData?: ExpertEvaluationListContract
    actionForForm?: ActionForFormType
}

export interface MapFormDataToRequestProps
    extends Omit<ExpertEvaluationListCompetenceContract, 'indicatorResults'> {
    indicatorResults: IndicatorsEvaluationListProps[]
}

export interface IndicatorsEvaluationListProps
    extends Omit<ExpertEvaluationListIndicatorContract, 'isStrongSide'> {
    isStrongSide: SideType
}
