import {
    IRequestConfig,
    IRequestOptions,
    PictureContract,
    TrackStageStatus,
    axios,
    getConfigs,
} from '.'

/**
 * TODO: генератор АПИ не правильно генерирует сервис для загрузки иконок
 */
export class TrackCanvasIconsService {
    /**
     * Загрузить иконки холста.
     */
    static upload(
        params: {
            /**  */
            file: any
            trackStageStatus: TrackStageStatus
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-canvas-icons/upload/{trackStageStatus}'
            url = url.replace(
                '{trackStageStatus}',
                params['trackStageStatus'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }

    /**
     * Загрузить фон холста иконки
     */
    static uploadBackground(
        params: {
            /**  */
            file: any
            trackStageStatus: TrackStageStatus
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/track-canvas-icons/background/upload/{trackStageStatus}'
            url = url.replace(
                '{trackStageStatus}',
                params['trackStageStatus'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}
