import React from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import { LOCAL } from 'core/local'
import {
    NotificationMailingTaskSearchContract,
    NotificationMailingTaskSearchResultContract,
    NotificationMailingTasksService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRowClassNameArchive } from 'components/shared'
import { getRowHandlers } from 'utils'

import { MailingAssignmentFilters } from './components'
import { getDistributionMailingAssignmentColumns } from './DistributionMailingAssignment.utils'

/** Задания на рассылку */
export const DistributionMailingAssignment: React.FC = React.memo(() => (
    <DictionaryPage<
        NotificationMailingTaskSearchContract,
        NotificationMailingTaskSearchResultContract
    >
        dictionaryKey={DICTIONARY_KEYS.DISTRIBUTION_MAILING_ASSIGNMENT}
        contentTitle={LOCAL.CATALOGS.MAILING_ASSIGNMENT}
        searchMethod={NotificationMailingTasksService.search}
        deleteMethod={NotificationMailingTasksService.delete}
        quickSearchMethod={NotificationMailingTasksService.searchText}
        tableColumnsRender={getDistributionMailingAssignmentColumns}
        createRecordLink={{
            path: ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_CREATE,
        }}
        createPermissions={[
            UserPermission.NotificationMailingTaskRead,
            UserPermission.NotificationMailingTaskSave,
        ]}
        filterComponent={MailingAssignmentFilters}
        tableFiltersOptions={{
            initialValues: {
                orderRules: ORDER_RULES_WITH_CHANGE_DATE,
            },
        }}
        rowClassName={getRowClassNameArchive}
        onRow={getRowHandlers({
            url: ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_VIEW,
        })}
    />
))
