import React, { useEffect } from 'react'
import { DATE_FORMAT, MODAL_WIDTH } from 'consts'
import { LOCAL } from 'core/local'
import {
    NotificationDeliveryStatusStatisticsContract,
    NotificationMailingInformationsService,
} from 'core/api'
import { Popup } from 'components/shared'
import { Table } from 'antd'
import { getDateByFormat } from 'utils'
import { useHttp } from 'hooks'

import styles from './DeliveryMessageStatistic.module.scss'
import { DELIVERY_MESSAGE_STATISTIC_COLUMNS } from './DeliveryMessageStatistic.config'
import { DeliveryMessageStatisticProps } from './DeliveryMessageStatistic.types'

export const DeliveryMessageStatistic: React.FC<DeliveryMessageStatisticProps> = React.memo(
    ({ modalVisibility, handleCloseModal, queryParams }) => {
        const [getData, data] = useHttp<
            NotificationDeliveryStatusStatisticsContract
        >(NotificationMailingInformationsService.getStatistics)

        useEffect(() => {
            const { taskId, orderRules, ...restQueryParams } = queryParams?.body

            getData({
                body: restQueryParams,
            })
        }, [getData, queryParams])

        return (
            <Popup
                visible={modalVisibility}
                onCancel={handleCloseModal}
                destroyOnClose
                title={LOCAL.LABELS.DELIVERY_MESSAGE_STATISTIC}
                footer={null}
                width={MODAL_WIDTH.LG}
                centered
            >
                {data?.statisticsDate && (
                    <div className={styles.lastUpdate}>
                        {`${LOCAL.LABELS.LAST_UPDATE} - ${getDateByFormat(
                            data?.statisticsDate,
                            DATE_FORMAT.WITH_TIME
                        )}`}
                    </div>
                )}

                <Table
                    rowKey="name"
                    columns={DELIVERY_MESSAGE_STATISTIC_COLUMNS}
                    dataSource={data?.statistics}
                    pagination={false}
                />
            </Popup>
        )
    }
)
