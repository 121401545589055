import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
    CompetenceContract,
    DictionaryContract,
    UserPermission,
} from 'core/api'
import { ElementWithListPopover } from 'components/shared'
import { GetBtnOptionsType } from 'components/layouts/FormContainer/FormContainer.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { defaultSorter } from 'components/layouts'
import {
    extractNames,
    formatDate,
    isDocumentStatusReadyForWork,
    renderDocumentStatus,
    renderStringArrayWithSeparator,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { CompetenciesActions } from '../components'

/** настройка отображения колонок для справочника компетенций */
export const COMPETENCIES_TABLE_COLUMNS: ColumnsType<CompetenceContract> = [
    {
        title: LOCAL.LABELS.COMPETENCE_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.COMPETENCIES_GROUP,
        dataIndex: ['competenceGroup', 'name'],
        key: 'competenceGroupName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DEPARTMENT,
        dataIndex: ['department', 'name'],
        key: 'departmentName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ACTIVITY_TYPE,
        dataIndex: 'functionClass',
        key: 'functionClass',
        render: (functionClass: DictionaryContract[]) =>
            restrictBigText(
                renderStringArrayWithSeparator(
                    functionClass.map((el) => el.name)
                )
            ),
        width: '350px',
    },
    {
        title: LOCAL.LABELS.INDICATORS,
        dataIndex: 'indicatorsCount',
        key: 'indicatorsCount',
        render: (indicatorsCount, record) => (
            <ElementWithListPopover
                title={indicatorsCount}
                items={extractNames(record.indicators)}
                options={{
                    showPopoverAlways: true,
                }}
            />
        ),
    },
    {
        title: LOCAL.LABELS.VERSION,
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderDocumentStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => <CompetenciesActions tableRow={tableRow} />,
        align: 'right',
        fixed: 'right',
    },
]

export const CANCEL_BTN_OPTIONS = {
    visible: true,
    href: ROUTE_NAMES.COMPETENCIES,
}

export const getSaveBtnOptions: GetBtnOptionsType<CompetenceContract> = ({
    editValues,
}) => ({
    visible: true,
    disabled: isDocumentStatusReadyForWork(editValues?.status),
})

export const PUBLISH_BTN_OPTIONS = {
    visible: true,
    requiredPermissions: [
        UserPermission.CompetenceRead,
        UserPermission.CompetencePublish,
    ],
}
