import { AssessmentRole, AssessmentType } from 'core/api'

/**
 * Проверка роли оценки
 * @param role - роль
 */
export const isAssessmentRoleManager = (role?: AssessmentRole) =>
    role === AssessmentRole.Manager
export const isAssessmentRoleColleague = (role?: AssessmentRole) =>
    role === AssessmentRole.Colleague
export const isAssessmentRoleSubordinate = (role?: AssessmentRole) =>
    role === AssessmentRole.Subordinate
export const isAssessmentRoleExpert = (role?: AssessmentRole) =>
    role === AssessmentRole.Expert
export const isAssessmentRoleGroupExpert = (role?: AssessmentRole) =>
    role === AssessmentRole.GroupExpert
export const isAssessmentRoleSelfAssessment = (role?: AssessmentRole) =>
    role === AssessmentRole.SelfAssessment

/**
 * Проверка типа оценки
 * @param type - тип
 */
export const isAssessmentTypeExpert = (type?: AssessmentType) =>
    type === AssessmentType.EstimateByExpert
export const isAssessmentTypeManager = (type?: AssessmentType) =>
    type === AssessmentType.EstimateByManager
export const isAssessmentTypeEstimate360 = (type?: AssessmentType) =>
    type === AssessmentType.Estimate_360
export const isAssessmentTypeEstimate180 = (type?: AssessmentType) =>
    type === AssessmentType.Estimate_180
export const isAssessmentTypeEstimate270 = (type?: AssessmentType) =>
    type === AssessmentType.Estimate_270
export const isAssessmentTypeCircle = (type?: AssessmentType) =>
    isAssessmentTypeEstimate360(type) ||
    isAssessmentTypeEstimate180(type) ||
    isAssessmentTypeEstimate270(type)
