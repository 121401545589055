import React, { FC } from 'react'
import { ShouldUpdateChecker } from 'components/controls'
import { isActivityCanvasTypeFullScreen } from 'utils'

/**
 * Компонент условного рендеринга JSX для мероприятия типа - fullscreen
 */
export const FullScreenType: FC<{ inverse?: boolean }> = React.memo(
    ({ children, inverse }) => (
        <ShouldUpdateChecker fieldPath={['canvas', 'canvasDesignType']}>
            {({ getFieldValue }) => {
                const type = getFieldValue(['canvas', 'canvasDesignType'])

                const condition = inverse
                    ? !isActivityCanvasTypeFullScreen(type)
                    : isActivityCanvasTypeFullScreen(type)

                return condition && children
            }}
        </ShouldUpdateChecker>
    )
)
