import React from 'react'
import { ButtonsToolbar, TableActions } from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TEST_PORTFOLIOS_REQUIRED_PERMISSIONS } from 'consts'
import { TableActionsCommonProps } from 'App.types'
import { TestPortfolioSearchResultContract, UserPermission } from 'core/api'
import { getConfirmEditMessage } from 'core/local/messages.local.config'
import { isDocumentStatusInWork } from 'utils'
import { useConfirmRedirect } from 'hooks'

/** Компонент для создания колонки действий внутри таблицы реестра портфелей тестов */
export const TestPortfoliosTableActions: React.FC<TableActionsCommonProps<
    TestPortfolioSearchResultContract
>> = React.memo(({ tableRow = {} }) => {
    const { handleRedirect } = useConfirmRedirect({
        to: `${ROUTE_NAMES.TEST_PORTFOLIOS_EDIT}/${tableRow.id}`,
        needConfirm: isDocumentStatusInWork(tableRow.status),
        confirmText: getConfirmEditMessage('test'),
    })

    return (
        <ButtonsToolbar bottomIndent="0">
            <TableActions.View
                requiredPermissions={TEST_PORTFOLIOS_REQUIRED_PERMISSIONS}
                href={`${ROUTE_NAMES.TEST_PORTFOLIOS_VIEW}/${tableRow.id}`}
                disabled={!tableRow.active}
            />

            {tableRow.active && (
                <>
                    <TableActions.Edit
                        requiredPermissions={[
                            ...TEST_PORTFOLIOS_REQUIRED_PERMISSIONS,
                            UserPermission.TestPortfolioSave,
                        ]}
                        onClick={handleRedirect}
                    />

                    <TableActions.Copy
                        requiredPermissions={[
                            ...TEST_PORTFOLIOS_REQUIRED_PERMISSIONS,
                            UserPermission.TestPortfolioSave,
                        ]}
                        href={`${ROUTE_NAMES.TEST_PORTFOLIOS_COPY}/${tableRow.id}`}
                    />

                    {!isDocumentStatusInWork(tableRow.status) && (
                        <TableActions.Delete
                            tableRow={tableRow}
                            requiredPermissions={[
                                ...TEST_PORTFOLIOS_REQUIRED_PERMISSIONS,
                                UserPermission.TestPortfolioDelete,
                            ]}
                        />
                    )}
                </>
            )}
        </ButtonsToolbar>
    )
})
