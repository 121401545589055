import { ActivityCanvasCreateContract } from 'core/api'

export enum ThematicBlockStatuses {
    BeforePassing,
    AfterPassing,
    Current,
}

export interface BlocksProps extends ActivityCanvasCreateContract {
    thematicBlocksCount: number
}
export interface GetConnectionLineBackgroundStylesProps
    extends BlocksProps,
        GetConnectionLineBackgroundStylesParams {}

export interface GetConnectionLineBackgroundStylesParams {
    type: ThematicBlockStatuses
    currentElement?: number
}
