import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { FormItemAdapter, TextAreaControl } from 'components/controls'
import { LOCAL } from 'core/local'
import {
    NotificationDynamicFieldMarkersService,
    NotificationMailingInformationsService,
} from 'core/api'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { useForm } from 'antd/lib/form/Form'
import { withLoader } from 'HOCs'

import { Message } from './Message'
import { getMessage, normalizeData } from './EmailPreviewForm.utils'

/** Форма предпросмотра письма */
export const EmailPreviewForm: React.FC<PopupAdapterFormProps> = withLoader(
    React.memo(({ initialValues, updateLoader, id }) => {
        const [form] = useForm()
        const [message, setMessage] = useState<string>()

        useEffect(() => {
            const emailFetch = async () => {
                try {
                    updateLoader(true)

                    if (
                        initialValues?.notificationTheme ||
                        initialValues?.notificationBody
                    ) {
                        const markers = await NotificationDynamicFieldMarkersService.getForSelectAll()
                        const message = getMessage(
                            initialValues?.notificationBody || '',
                            markers
                        )
                        setMessage(message)

                        form.setFieldsValue(
                            normalizeData(initialValues, markers)
                        )
                    }

                    if (initialValues?.emailId) {
                        const data = await NotificationMailingInformationsService.getEmailContent(
                            {
                                id: initialValues.emailId,
                            }
                        )

                        setMessage(data.message)

                        form.setFieldsValue(data)
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }

            emailFetch()
        }, [initialValues, updateLoader, form])

        return (
            <Form id={id} form={form}>
                <FormItemAdapter
                    fieldName="subject"
                    label={LOCAL.LABELS.MAILING_THEME}
                >
                    <TextAreaControl
                        autoSize={{ minRows: 3 }}
                        disabled
                        placeholder=""
                    />
                </FormItemAdapter>

                <FormItemAdapter
                    fieldName="message"
                    label={LOCAL.LABELS.MESSAGE_BODY}
                >
                    <Message message={message} />
                </FormItemAdapter>
            </Form>
        )
    })
)
