import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Upload } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload/interface'
import { validateUploadImage } from 'utils'

import styles from './UploadDraggerImageControl.module.scss'
import { UploadDraggerImageControlProps } from './UploadDraggerImageControl.types'

/**
 * Декоратор для `Upload.Dragger` от `antd`
 */
export const UploadDraggerImageControl: React.FC<UploadDraggerImageControlProps> = React.memo(
    ({
        className,
        placeholder,
        onChange,
        fileList,
        disabled,
        ...restProps
    }) => {
        const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(
            null
        )

        /**
         * Обновление значения во внешней форме
         * @param value значение для формы
         */
        const triggerChange = useCallback(
            (value) => {
                onChange?.(value)
            },
            [onChange]
        )

        /**
         * Превью загружаемого изображения
         * @param info информация об изображении
         */
        const handleChange = useCallback(
            (info: UploadChangeParam) => {
                if (info.file?.originFileObj) {
                    const reader = new FileReader()

                    reader.addEventListener('load', () => {
                        setImageUrl(reader.result)
                    })
                    reader.readAsDataURL(info.file.originFileObj as Blob)

                    triggerChange(info)
                }
            },
            [triggerChange]
        )

        /**
         * Обработчик удаления картинки
         */
        const handleRemoveImg = useCallback(
            (e) => {
                e.stopPropagation()
                setImageUrl(null)
                triggerChange(undefined)
            },
            [triggerChange]
        )

        /** Если имеется сохраненное значение для поля, то устанавливаем его в состояние */
        useEffect(() => {
            const currentImgUrl = fileList?.[0]?.response?.url

            if (currentImgUrl) {
                setImageUrl(currentImgUrl)
            }
        }, [fileList])

        return (
            <div className={cn(styles.wrapper, className)}>
                <Upload.Dragger
                    beforeUpload={validateUploadImage}
                    accept="image/*"
                    showUploadList={false}
                    fileList={fileList}
                    onChange={handleChange}
                    disabled={disabled}
                    {...restProps}
                >
                    {imageUrl ? (
                        <>
                            {!disabled && (
                                <Button
                                    type="text"
                                    icon={
                                        <IconsAdapter iconType="CloseSquareFilled" />
                                    }
                                    onClick={handleRemoveImg}
                                />
                            )}

                            <img src={imageUrl as string} alt="Изображение" />
                        </>
                    ) : (
                        <>
                            <IconsAdapter
                                iconType="PictureOutlined"
                                className={styles.imgUpload}
                            />

                            <span>
                                {placeholder || LOCAL.PLACEHOLDERS.UPLOAD_IMAGE}
                            </span>
                        </>
                    )}
                </Upload.Dragger>
            </div>
        )
    }
)
