import React, { useCallback } from 'react'
import { APPOINTMENT_STATUS_MAPPED, ROW_GUTTER } from 'consts'
import {
    AssessmentProjectSearchContract,
    AssessmentType,
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    CoworkersFilters,
    FormItemAdapter,
    InputControl,
    SearchSelectControl,
    SelectControl,
    getCoworkersTableColumns,
    getUserByIdsMethod,
} from 'components/controls'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import {
    FiltersProps,
    getValue,
    normalizeDictionaryToControl,
    pickSelectOptions,
} from 'utils'
import { LOCAL } from 'core/local'
import { Store } from 'App.types'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionariesHelper } from 'hooks'

/** Форма фильтрации проектов оценки*/
export const AssessmentProjectsFilters: React.FC<FiltersProps<
    AssessmentProjectSearchContract
>> = React.memo((props) => {
    const { assessmentTypeDictionary } = useDictionariesHelper([
        'assessmentTypeDictionary',
    ])
    const mapFormDataToRequest = useCallback(
        (values: Store) => ({
            ...values,
            changeUserIds: values.changeUserIds?.map(getValue),
        }),
        []
    )

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                mapFormDataToRequest={mapFormDataToRequest}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={APPOINTMENT_STATUS_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="assessmentType"
                            label={LOCAL.LABELS.ASSESSMENT_METHOD}
                        >
                            <SelectControl
                                values={pickSelectOptions(
                                    normalizeDictionaryToControl(
                                        assessmentTypeDictionary
                                    ),
                                    [
                                        AssessmentType.Estimate_360,
                                        AssessmentType.EstimateByManager,
                                    ]
                                )}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="changeUserIds"
                            label={LOCAL.LABELS.RESPONSIBLE_PERSON}
                        >
                            <SearchSelectControl<
                                UserModalSearchContract,
                                UserModalContract
                            >
                                getByIdsMethod={getUserByIdsMethod}
                                selectMode="multiple"
                                tableSearchOptions={{
                                    searchMethod: UsersService.getForModal,
                                    tableColumns: getCoworkersTableColumns(),
                                    filterComponent: CoworkersFilters,
                                }}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="requestNumber"
                            label={LOCAL.LABELS.REQUEST_NUMBER}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_START,
                                fieldName: 'dateFrom',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_END,
                                fieldName: 'dateTo',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
