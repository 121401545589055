import { CompetenceContract } from 'core/api'

import { AssessmentPortfolioPublishFormContract } from '../AssessmentTemplateForm/AssessmentTemplateForm.types'
import { CompetenceFormContract } from './CircleParameters.types'

/** нормализация данных, полученных через `getEntitiesByIds`, для `value` и внутреннего стейта компонента */
export const normalizeFetchDataForValue = (
    newData: CompetenceContract[],
    oldData?: CompetenceFormContract[]
): CompetenceFormContract[] =>
    newData.map((el) => ({
        id: el.id,
        name: el.name,
        selfQuestionnaireId: oldData?.find((oldEl) => oldEl.id === el.id)
            ?.selfQuestionnaireId,
        respondentQuestionnaireId: oldData?.find((oldEl) => oldEl.id === el.id)
            ?.respondentQuestionnaireId,
    }))

/**
 * Считаем указанное кол-во респондентов
 * @param respondents объект с данными необходимых полей формы
 */
export const getRespondentsCount = (
    respondents: Omit<
        AssessmentPortfolioPublishFormContract['circleParameters'],
        'questionnaires'
    >
) => Object.values<number>(respondents).reduce((acc, el) => acc + el, 0)

/**
 * Проверка компетенций на отношение к управленческой группе
 * @param competencies проверяемые компетенции
 */
export const checkCompetenciesFromManagerGroup = (
    competencies?: CompetenceContract[]
) => competencies?.some((competence) => competence.competenceGroup?.isManager)
