import React from 'react'
import cn from 'classnames'
import { ActivityLessonPassingContract, ActivityPassingStatus } from 'core/api'
import { Button } from 'components/shared/Button'
import { FnActionProps } from 'App.types'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import {
    isActivityLessonCompletedSuccessfully,
    isActivityLessonInProgress,
    isThemeBlockTypeCourse,
    isThemeBlockTypeHomeworkWithoutEditor,
    isThemeBlockTypeInformation,
    isThemeBlockTypeWebConferenceOrFaceToFace,
} from 'utils'

import styles from './ThematicBlock.module.scss'

export const getActionsButton = (
    { status, type }: Partial<ActivityLessonPassingContract>,
    onThematicBlockPassing: FnActionProps,
    activityStatus: ActivityPassingStatus
) => {
    switch (status) {
        case ActivityPassingStatus.Waiting:
        case ActivityPassingStatus.InProgress:
            if (!isActivityLessonCompletedSuccessfully(activityStatus)) {
                return (
                    <Button
                        className={cn(styles.action, 'capitalize-text')}
                        type="primary"
                        onClick={onThematicBlockPassing}
                    >
                        {isActivityLessonInProgress(status)
                            ? LOCAL.ACTIONS.CONTINUE
                            : LOCAL.ACTIONS.PROCEED}
                    </Button>
                )
            }

            break

        case ActivityPassingStatus.CompletedSuccessfully:
        case ActivityPassingStatus.CompletedUnsuccessfully:
            if (
                isThemeBlockTypeWebConferenceOrFaceToFace(type) ||
                isThemeBlockTypeHomeworkWithoutEditor(type) ||
                isThemeBlockTypeCourse(type) ||
                isThemeBlockTypeInformation(type)
            ) {
                return (
                    <Button
                        className={cn(styles.action, 'capitalize-text')}
                        type="primary"
                        onClick={onThematicBlockPassing}
                    >
                        {LOCAL.ACTIONS.BROWSE}
                    </Button>
                )
            }

            break
    }
}

export const getStatus = (
    status: ActivityPassingStatus,
    orderNumber: number
) => {
    switch (status) {
        case ActivityPassingStatus.CompletedUnsuccessfully:
            return <IconsAdapter iconType="CloseOutlined" />
        case ActivityPassingStatus.CompletedSuccessfully:
            return <IconsAdapter iconType="CheckOutlined" />
        case ActivityPassingStatus.InProgress:
        case ActivityPassingStatus.Waiting:
            return orderNumber
        default:
            return <IconsAdapter iconType="LockOutlined" />
    }
}

export const getClasses = (status: ActivityPassingStatus) => {
    switch (status) {
        case ActivityPassingStatus.CompletedSuccessfully:
        case ActivityPassingStatus.CompletedUnsuccessfully:
            return styles.wrapperCompleted
        case ActivityPassingStatus.InProgress:
        case ActivityPassingStatus.Waiting:
            return styles.wrapperInProgress
        default:
            return styles.wrapperBlocked
    }
}
