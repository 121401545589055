import last from 'lodash/last'
import React, { useCallback } from 'react'
import { COL_lg12_xs24 } from 'consts'
import { Col, Form, Row } from 'antd'
import { FormItemAdapter, MultiSelectControl } from 'components/controls'
import { FormListOperation } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'
import { QuestionnairesService, UserPermission } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    isFormModeView,
    renderQuestionnairesDropdown,
    shouldFormFieldUpdate,
} from 'utils'
import { withLoader } from 'HOCs'

import { QuestionnaireSetProps } from './QuestionnaireSet.types'
import {
    QuestionnaireSetRow,
    QuestionnaireSetRowValueProps,
} from '../QuestionnaireSetRow'
import { composeQuestionnairesTreeData } from './QuestionnaireSet.utils'

/**
 * Список опросных листов для формы портфеля теста
 */
export const QuestionnaireSet: React.FC<QuestionnaireSetProps> = withLoader(
    React.memo(
        ({
            questionnairesTreeData = [],
            form,
            formMode,
            updateLoader,
            disabled,
        }) => {
            /**
             * Запрос информации о тесте
             */
            const questionnairesFetch = useCallback(
                async (id: number, add: FormListOperation['add']) => {
                    try {
                        updateLoader(true)
                        const questionnaireData = await QuestionnairesService.getShortInfoById(
                            { id }
                        )

                        add({
                            name: questionnaireData.name,
                            countOfRandomQuestions:
                                questionnaireData.questionsCount,
                            questionnaireId: questionnaireData.id,
                            totalQuestionCount:
                                questionnaireData.questionsCount,
                            totalAnswerLimit:
                                questionnaireData.totalAnswerLimit,
                        })
                    } catch (error) {
                        console.error(error)
                    } finally {
                        updateLoader(false)
                    }
                },
                [updateLoader]
            )

            /**
             * Обработчик выбора опросного листа
             */
            const handleChangeSelect = useCallback(
                (
                    add: FormListOperation['add'],
                    remove: FormListOperation['remove']
                ) => (ids?: number[]) => {
                    if (!ids) return

                    const selectedQuestionnairesIds =
                        form
                            ?.getFieldValue('questionnaires')
                            ?.map(
                                (el: QuestionnaireSetRowValueProps) =>
                                    el.questionnaireId
                            ) || []

                    if (selectedQuestionnairesIds?.length < ids.length) {
                        questionnairesFetch(last(ids)!, add)
                    } else {
                        remove(
                            selectedQuestionnairesIds?.findIndex(
                                (el: number) => !ids.includes(el)
                            )
                        )
                    }
                },
                [questionnairesFetch, form]
            )

            return (
                <Form.List name="questionnaires">
                    {(fields, { add, remove }) => (
                        <>
                            <Row>
                                <Col {...COL_lg12_xs24}>
                                    <FormItemAdapter
                                        label={
                                            LOCAL.CATALOGS
                                                .QUESTIONNAIRE_TEMPLATES
                                        }
                                        shouldUpdate={shouldFormFieldUpdate(
                                            'questionnaires'
                                        )}
                                        required
                                    >
                                        {({ getFieldValue }) => {
                                            const questionnaires: QuestionnaireSetRowValueProps[] = getFieldValue(
                                                'questionnaires'
                                            )

                                            return (
                                                <MultiSelectControl
                                                    treeData={composeQuestionnairesTreeData(
                                                        questionnaires,
                                                        questionnairesTreeData
                                                    )}
                                                    showSearch
                                                    value={questionnaires?.map(
                                                        (el) =>
                                                            el.questionnaireId
                                                    )}
                                                    onChange={handleChangeSelect(
                                                        add,
                                                        remove
                                                    )}
                                                    dropdownRender={renderQuestionnairesDropdown(
                                                        {
                                                            url:
                                                                ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_CREATE,
                                                            title:
                                                                LOCAL.LABELS
                                                                    .QUESTIONNAIRE_TEMPLATE_ADD,
                                                            permissions: [
                                                                UserPermission.QuestionnaireSave,
                                                            ],
                                                        }
                                                    )}
                                                    formMode={formMode}
                                                    notFoundContent={
                                                        LOCAL.LABELS.EMPTY_DATA
                                                    }
                                                    placeholder={
                                                        isFormModeView(formMode)
                                                            ? LOCAL.PLACEHOLDERS
                                                                  .INPUT_EMPTY
                                                            : LOCAL.PLACEHOLDERS
                                                                  .SEARCH_SELECT
                                                    }
                                                    disabled={disabled}
                                                />
                                            )
                                        }}
                                    </FormItemAdapter>
                                </Col>
                            </Row>

                            <Row>
                                <Col {...COL_lg12_xs24}>
                                    {fields.map((props) => (
                                        <Form.Item {...props}>
                                            <QuestionnaireSetRow
                                                removeRow={remove}
                                                rowIndex={props.name}
                                                formMode={formMode}
                                                disabled={disabled}
                                            />
                                        </Form.Item>
                                    ))}
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>
            )
        }
    )
)
