import React, { FC, useCallback } from 'react'
import { AppError } from 'core/api'
import { Form } from 'antd'
import { HiddenField } from 'components/controls'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'

/** Универсальная форма для удаления записи */
export const ConfirmDeleteForm: FC<PopupAdapterFormProps> = React.memo(
    ({
        onRequestFinish,
        deleteFormAction,
        initialValues,
        rowSelectionType,
        recordCopy,
        onCancelSubmit,
        messageText,
        updateButtonVisibility,
        onRefetch,
        onChangeModalOptions,
        ...props
    }) => {
        const handleFinish = useCallback(
            async (values: { id?: number }) => {
                try {
                    await deleteFormAction?.(values.id as number)

                    onRequestFinish?.()
                } catch (error) {
                    onRequestFinish?.(error as AppError)
                }
            },
            [deleteFormAction, onRequestFinish]
        )

        return (
            <Form
                onFinish={handleFinish}
                {...props}
                initialValues={initialValues}
            >
                {messageText || LOCAL.MESSAGES.DELETE_CONFIRM}

                <HiddenField fieldName="id" />
            </Form>
        )
    }
)
