import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { HomeworkStatusTag } from 'components/shared/HomeworkStatusTag'
import { HomeworkStatusType } from 'core/api'
import { HomeworkTaskNumberTitle } from 'components/shared'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker } from 'components/controls'
import { TextAreaControl } from 'components/controls/TextAreaControl'
import {
    isHomeworkOnChecking,
    isHomeworkOnRevision,
    isHomeworkPassed,
    isHomeworkWaitingForSolution,
    safelySetInnerHtml,
} from 'utils'
import { useSize } from 'hooks'
import { withLoader } from 'HOCs'

import styles from './SubtaskBlock.module.scss'
import { Footer } from '../Footer'
import { History } from '../History'
import { SubtaskBlockProps } from './SubtaskBlock.types'
import { finishHomework } from './SubtaskBlock.utils'

/** Блок подзадания для тьютора */
export const SubtaskBlock: React.FC<SubtaskBlockProps> = withLoader(
    React.memo(
        ({
            field,
            form,
            homeworkId,
            setSendingTaskIds,
            sendingTaskIds,
            isOverdue,
            isTasksTitlesVisible,
            setSubtaskBlocksHeight,
            homeworkHistory,
        }) => {
            const subtaskContainerRef = useRef<HTMLDivElement>(null)

            const containerSize = useSize(subtaskContainerRef)

            const fieldValues = form?.getFieldValue([
                'taskPassings',
                field.name,
            ])

            const {
                taskNumber,
                taskText,
                id: taskId,
                attachment,
                answerText,
            } = fieldValues

            const currentHistory = homeworkHistory?.find(
                (el) => el.taskNumber === taskNumber
            )

            /** Вызов метода зачёт/доработка на подзадание*/
            const finishSubtaskReview = useCallback(
                async (status?: HomeworkStatusType) => {
                    try {
                        const comment = form?.getFieldValue([
                            'taskPassings',
                            field.name,
                            'comment',
                        ])

                        const body = { taskId, comment, homeworkId }

                        if (isHomeworkOnRevision(status)) {
                            finishHomework({
                                body,
                                fieldName: field.name,
                                status: HomeworkStatusType.Revision,
                                form,
                            })

                            return
                        }

                        setSendingTaskIds((prev) => [...prev, taskId])

                        finishHomework({
                            body,
                            fieldName: field.name,
                            status: HomeworkStatusType.Passed,
                            form,
                        })
                    } catch (error) {
                        console.error(error)
                    } finally {
                        setSendingTaskIds((prev) =>
                            prev.filter((el) => el !== taskId)
                        )
                    }
                },
                [form, field.name, taskId, setSendingTaskIds, homeworkId]
            )

            const loading = useMemo(() => sendingTaskIds.includes(taskId), [
                taskId,
                sendingTaskIds,
            ])

            useEffect(() => {
                setSubtaskBlocksHeight((prev) => ({
                    ...prev,
                    [field.name]: JSON.stringify(containerSize?.height),
                }))
            }, [containerSize, field.name, setSubtaskBlocksHeight])

            return (
                <ShouldUpdateChecker
                    fieldPath={['taskPassings', field.name, 'status']}
                >
                    {({ getFieldValue }) => {
                        const status = getFieldValue([
                            'taskPassings',
                            field.name,
                            'status',
                        ])

                        const disabledCommentField =
                            isHomeworkPassed(status) ||
                            isHomeworkOnRevision(status) ||
                            isOverdue

                        return (
                            <div
                                className={cn(
                                    styles.wrapper,
                                    styles[`subtaskBLock${field.name}`]
                                )}
                                ref={subtaskContainerRef}
                            >
                                <div className={styles.header}>
                                    <HomeworkTaskNumberTitle
                                        isTasksTitlesVisible={
                                            isTasksTitlesVisible
                                        }
                                        taskNumber={taskNumber}
                                    />

                                    {!isHomeworkWaitingForSolution(status) &&
                                        !isHomeworkOnChecking(status) && (
                                            <HomeworkStatusTag
                                                currentStatus={status}
                                                type="tutor"
                                            />
                                        )}
                                </div>

                                <div
                                    className={styles.text}
                                    dangerouslySetInnerHTML={safelySetInnerHtml(
                                        taskText
                                    )}
                                />

                                <div className={styles.answer}>
                                    {answerText}
                                </div>

                                {!!currentHistory && (
                                    <History
                                        homeworkHistory={
                                            currentHistory.missionTaskPassings
                                        }
                                    />
                                )}

                                <FormItemAdapter
                                    fieldName={[field.name, 'comment']}
                                    label={LOCAL.LABELS.COMMENT_TO_USER}
                                    className={styles.comment}
                                >
                                    <TextAreaControl
                                        disabled={disabledCommentField}
                                    />
                                </FormItemAdapter>

                                <Footer
                                    attachment={attachment}
                                    currentStatus={status}
                                    finishSubtaskReview={finishSubtaskReview}
                                    loading={loading}
                                    isOverdue={isOverdue}
                                />
                            </div>
                        )
                    }}
                </ShouldUpdateChecker>
            )
        }
    )
)
