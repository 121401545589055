import {
    AppointmentStatus,
    HomeworkStatusType,
    QuizCheckStatus,
} from 'core/api'
import { excludeSelectOptions } from 'utils/form.utils'
import { normalizeDictionaryToControl } from 'utils/dictionaries.utils'

import {
    ACTIVITY_CANVAS_DESIGN_TYPE,
    ACTIVITY_PASSING_DATE_TYPE,
    ADDITIONAL_OPTIONS,
    APPOINTMENT_STATUS,
    APPOINTMENT_TYPES,
    ASSESSMENT_PROJECTS_EVALUATION_TYPES,
    ASSESSMENT_ROLES,
    CERTIFICATE_KIND,
    DOCUMENT_STATUSES,
    EMAIL_SEND_STATUSES,
    EVENT_DECORATION_TYPE,
    EVENT_SPECIES,
    EVENT_TYPES,
    FACE_TO_FACE_MEETING_STATUSES,
    FONT_NAMES,
    FONT_STYLES,
    HOMEWORK_STATUS_TYPE,
    LEARNING_FORMATS,
    MAILING_STATUSES,
    MAILING_TYPES,
    NOTIFICATION_DELIVERY_STATUSES,
    NOTIFICATION_DICTIONARY,
    NOTIFICATION_STATUSES,
    NOTIFICATION_TYPES,
    PRODUCT_TYPES,
    PROFILE_TYPES,
    PROGRAMME_LEVELS,
    QUESTIONNAIRE_TYPES,
    QUESTION_TYPES,
    QUIZ_CHECK_STATUSES,
    QUIZ_TYPES,
    TEMPLATE_STATUSES,
    TEST_SEQUENCE,
    TRACKS_APPOINTMENT_STATUSES,
    TRACKS_APPOINTMENT_TYPES,
    TRACK_STAGES_MATERIAL_TYPE,
    TRACK_STAGE_SETTINGS_STATUS,
    TRACK_TYPES,
} from './dictionaries.consts'
import { TEXT_ALIGN_OPTION } from './form.consts'

export const APPOINTMENT_STATUS_MAPPED = normalizeDictionaryToControl(
    APPOINTMENT_STATUS
)

export const APPOINTMENT_STATUS_WITHOUT_CHECKED_MAPPED = excludeSelectOptions(
    APPOINTMENT_STATUS_MAPPED,
    [AppointmentStatus.Checked, AppointmentStatus.Done]
)

export const TRACK_STAGES_MATERIAL_TYPE_MAPPED = normalizeDictionaryToControl(
    TRACK_STAGES_MATERIAL_TYPE
)

export const EVENT_TYPES_MAPPED = normalizeDictionaryToControl(EVENT_TYPES)

export const ACTIVITY_PASSING_DATE_TYPE_MAPPED = normalizeDictionaryToControl(
    ACTIVITY_PASSING_DATE_TYPE
)

export const PROFILE_TYPES_MAPPED = normalizeDictionaryToControl(PROFILE_TYPES)

export const QUESTIONNAIRE_TYPES_MAPPED = normalizeDictionaryToControl(
    QUESTIONNAIRE_TYPES
)

export const TEXT_ALIGN_OPTION_MAPPED = normalizeDictionaryToControl(
    TEXT_ALIGN_OPTION
)

export const ASSESSMENT_PROJECTS_EVALUATION_TYPES_MAPPED = normalizeDictionaryToControl(
    ASSESSMENT_PROJECTS_EVALUATION_TYPES
)

export const DOCUMENT_STATUSES_MAPPED = normalizeDictionaryToControl(
    DOCUMENT_STATUSES
)

export const DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED = excludeSelectOptions(
    DOCUMENT_STATUSES_MAPPED,
    ['Archive']
)

export const EVENT_SPECIES_MAPPED = normalizeDictionaryToControl(EVENT_SPECIES)

export const LEARNING_FORMATS_MAPPED = normalizeDictionaryToControl(
    LEARNING_FORMATS
)

export const NOTIFICATION_DICTIONARY_MAPPED = normalizeDictionaryToControl(
    NOTIFICATION_DICTIONARY
)

export const ASSESSMENT_ROLES_MAPPED = normalizeDictionaryToControl(
    ASSESSMENT_ROLES
)

export const NOTIFICATION_TYPES_MAPPED = normalizeDictionaryToControl(
    NOTIFICATION_TYPES
)

export const MAILING_STATUSES_MAPPED = normalizeDictionaryToControl(
    MAILING_STATUSES
)

export const CERTIFICATE_KIND_MAPPED = normalizeDictionaryToControl(
    CERTIFICATE_KIND
)

export const MAILING_TYPES_MAPPED = normalizeDictionaryToControl(MAILING_TYPES)

export const TRACK_TYPES_MAPPED = normalizeDictionaryToControl(TRACK_TYPES)

export const TRACKS_APPOINTMENT_STATUSES_MAPPED = normalizeDictionaryToControl(
    TRACKS_APPOINTMENT_STATUSES
)

export const EVENT_DECORATION_TYPE_MAPPED = normalizeDictionaryToControl(
    EVENT_DECORATION_TYPE
)

export const EMAIL_SEND_STATUSES_MAPPED = normalizeDictionaryToControl(
    EMAIL_SEND_STATUSES
)

export const NOTIFICATION_DELIVERY_STATUSES_MAPPED = normalizeDictionaryToControl(
    NOTIFICATION_DELIVERY_STATUSES
)

export const ACTIVITY_CANVAS_DESIGN_TYPE_MAPPED = normalizeDictionaryToControl(
    ACTIVITY_CANVAS_DESIGN_TYPE
)

export const ADDITIONAL_OPTIONS_MAPPED = normalizeDictionaryToControl(
    ADDITIONAL_OPTIONS
)

export const QUESTION_TYPES_MAPPED = normalizeDictionaryToControl(
    QUESTION_TYPES
)

export const TEMPLATE_STATUSES_MAPPED = normalizeDictionaryToControl(
    TEMPLATE_STATUSES
)

export const NOTIFICATION_STATUSES_MAPPED = normalizeDictionaryToControl(
    NOTIFICATION_STATUSES
)

export const TRACKS_APPOINTMENT_TYPES_MAPPED = normalizeDictionaryToControl(
    TRACKS_APPOINTMENT_TYPES
)

export const QUIZ_TYPES_MAPPED = normalizeDictionaryToControl(QUIZ_TYPES)

export const TEST_SEQUENCE_MAPPED = normalizeDictionaryToControl(TEST_SEQUENCE)

export const PRODUCT_TYPES_MAPPED = normalizeDictionaryToControl(PRODUCT_TYPES)

export const PROGRAMME_LEVELS_MAPPED = normalizeDictionaryToControl(
    PROGRAMME_LEVELS
)

export const TRACK_STAGE_SETTINGS_STATUS_MAPPED = normalizeDictionaryToControl(
    TRACK_STAGE_SETTINGS_STATUS
)

export const FONT_NAMES_MAPPED = normalizeDictionaryToControl(FONT_NAMES)

export const FONT_STYLES_MAPPED = normalizeDictionaryToControl(FONT_STYLES)

export const FACE_TO_FACE_MEETING_STATUSES_MAPPED = normalizeDictionaryToControl(
    FACE_TO_FACE_MEETING_STATUSES
)

export const QUIZ_CHECK_STATUSES_MAPPED = normalizeDictionaryToControl(
    QUIZ_CHECK_STATUSES
)

export const QUIZ_CHECK_TYPE_WITHOUT_IN_PROCESS_FOR_SOLUTION_MAPPED = excludeSelectOptions(
    QUIZ_CHECK_STATUSES_MAPPED,
    [QuizCheckStatus.InProcess]
)

export const HOMEWORK_STATUS_TYPE_MAPPED = normalizeDictionaryToControl(
    HOMEWORK_STATUS_TYPE
)

export const HOMEWORK_STATUS_TYPE_WITHOUT_WAITING_FOR_SOLUTION_MAPPED = excludeSelectOptions(
    HOMEWORK_STATUS_TYPE_MAPPED,
    [HomeworkStatusType.WaitingForSolution]
)

export const APPOINTMENT_TYPES_MAPPED = normalizeDictionaryToControl(
    APPOINTMENT_TYPES
)
