import React from 'react'
import cn from 'classnames'
import { Button } from 'components/shared/Button'
import {
    CatalogItemProductCardPreviewContract,
    ProductCardStudyingPeriodContract,
    ProductCardStudyingPeriodListenerInfoContract,
    ProductCardsService,
    ProductType,
} from 'core/api'
import { FnActionProps } from 'App.types'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { Modal } from 'antd'
import { createConfirmPopup, formatDate } from 'utils'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import {
    isProductTypeBook,
    isProductTypeContent,
} from 'utils/conditions.utils/product.utils'

import styles from './ProductCardLauncher.module.scss'
import {
    GetLaunchButtonProps,
    StudyingPeriodExpandedContract,
} from './ProductCardLauncher.types'

/** Обработчик подтверждения завершения записи на продукт*/
const handleConfirmSignUpFinish = (
    fetchPageInfo: FnActionProps
) => async () => {
    await fetchPageInfo()

    Modal.destroyAll()
}

/**
 * Завершение записи на период обучения/резерв продукта
 */
const onSignUpFinish = (fetchPageInfo: FnActionProps) => {
    Modal.destroyAll()

    createConfirmPopup({
        title: LOCAL.LABELS.THANKS,
        width: MODAL_WIDTH.SM,
        content: LOCAL.MESSAGES.YOU_HAVE_ENROLLED_SUCCEFULLY,
        icon: (
            <IconsAdapter
                iconType="CloseOutlined"
                onClick={handleConfirmSignUpFinish(fetchPageInfo)}
            />
        ),
        getContainer: getDropdownPopupContainerDefault,
        className: cn(styles.modal, styles.modalNotification),
        onCancel: handleConfirmSignUpFinish(fetchPageInfo),
        onOk: handleConfirmSignUpFinish(fetchPageInfo),
        okText: LOCAL.OK,
    })
}

/**
 * Обработчик записи в резерв обучения продукта
 */
const handleSignUpToReserve = (
    cardId: number,
    fetchPageInfo: FnActionProps
) => async () => {
    try {
        await ProductCardsService.enrollListenerInProductCardReserve({
            productCardId: cardId,
        })

        onSignUpFinish(fetchPageInfo)
    } catch (error) {
        console.error(error)
    }
}

/**
 * Обработчик записи на период обучения продукта
 */
const handleSignUp = (
    periodId: number,
    fetchPageInfo: FnActionProps
) => async () => {
    try {
        await ProductCardsService.enrollListenerInProductCardStudyingPeriod({
            productCardStudyingPeriodId: periodId,
        })

        onSignUpFinish(fetchPageInfo)
    } catch (error) {
        console.error(error)
    }
}

/**
 * Расширить информацию о периодах обучения продукта с информации о количестве записавшихся участниках
 */
const composePeriodsInfo = (
    periods: ProductCardStudyingPeriodContract[],
    periodsInfo: ProductCardStudyingPeriodListenerInfoContract[]
) =>
    periods.map((period) => ({
        ...(periodsInfo.find(
            (info) => info.productCardStudyingPeriodId === period.id
        ) as ProductCardStudyingPeriodListenerInfoContract),
        ...period,
    }))

/**
 * Рендер контента модального окна записи на обучение
 */
const renderSignUpModalContent = (
    periods: StudyingPeriodExpandedContract[],
    fetchPageInfo: FnActionProps
) =>
    periods.map((period) => (
        <div className={styles.record}>
            <span className={styles.date}>{`${formatDate(
                period.startDate
            )} - ${formatDate(period.endDate)}`}</span>

            <Button
                className={styles.signUp}
                onClick={handleSignUp(period.id, fetchPageInfo)}
                disabled={
                    period.enrolledListenersCount >= period.participantCount
                }
            >
                {LOCAL.ACTIONS.SEND_APPLICATION}
            </Button>
        </div>
    ))

/**
 * Обработчик открытия модального окна записи на период обучения
 */
const handleOpenSignUpModal = (
    card: CatalogItemProductCardPreviewContract,
    fetchInfo: FnActionProps
) => async () => {
    const periodsInfo = await ProductCardsService.getProductCardPeriodsListenerInfo(
        { productCardId: card.id }
    )

    const periods = composePeriodsInfo(card.studyingPeriods, periodsInfo)

    createConfirmPopup({
        width: MODAL_WIDTH.MD,
        icon: (
            <IconsAdapter iconType="CloseOutlined" onClick={Modal.destroyAll} />
        ),
        getContainer: getDropdownPopupContainerDefault,
        className: styles.modal,
        title: LOCAL.ACTIONS.CHOOSE_EDUCATION_PERIOD,
        onOk: handleSignUpToReserve(card.id, fetchInfo),
        okText: LOCAL.ACTIONS.SIGN_UP_TO_RESERVE,
        content: renderSignUpModalContent(periods, fetchInfo),
    })
}

/**
 * Обработчик нажатия на кнопку "приступить"
 */
const handleProceed = ({
    card,
    goToCourse,
    onSetAppointment,
}: Omit<GetLaunchButtonProps, 'isEnrolled' | 'fetchPageInfo'>) => () => {
    switch (card?.type) {
        case ProductType.Content:
            goToCourse()

            break

        case ProductType.Track:
        case ProductType.Activity:
            onSetAppointment()

            break
        default:
            break
    }
}

/**
 * Получить пропсы для кнопки запуска карточки продукта
 */
export const getLaunchButtonProps = ({
    card,
    isEnrolled,
    fetchPageInfo,
    onSetAppointment,
    goToCourse,
}: GetLaunchButtonProps) => {
    if (!card || isProductTypeBook(card.type) || isProductTypeBook(card.type))
        return

    if (card.availableToStartFromCatalog)
        return {
            children: LOCAL.ACTIONS.PROCEED,
            onClick: handleProceed({
                card,
                goToCourse,
                onSetAppointment,
            }),
        }

    if (card.bookingAvailable && !isProductTypeContent(card.type))
        return {
            children: isEnrolled
                ? LOCAL.LABELS.YOUR_APPLICATION_HAS_SEND
                : LOCAL.ACTIONS.SIGN_UP,
            onClick: handleOpenSignUpModal(card, fetchPageInfo),
            disabled: isEnrolled,
        }
}
