import React, { useCallback } from 'react'
import { ButtonsToolbar, TableActions } from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { START_PARAM } from 'consts'
import {
    StartType,
    TracksMguuSearchResultContract,
    UserPermission,
} from 'core/api'
import { TableActionsCommonProps } from 'App.types'
import { useHistory } from 'react-router-dom'

/** Набор прав доступа обязательных для совершения любых действий внутри страницы */
export const PAGE_ACTIONS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.MguuTracksRead,
    UserPermission.MguuTrackRead,
]

/** Компонент для создания колонки действий внутри таблицы реестра трека МГУУ */
export const TracksUniqueTableActions: React.FC<TableActionsCommonProps<
    TracksMguuSearchResultContract
>> = React.memo(({ tableRow }) => {
    const history = useHistory()

    const handleStartPassingTrack = useCallback(() => {
        history.push(
            `${ROUTE_NAMES.TRACKS_PASSING_UNIQUE}/${tableRow.tags}/${tableRow.id}/${StartType.ByTrack}/${START_PARAM}`
        )
    }, [history, tableRow.id, tableRow.tags])

    return (
        <ButtonsToolbar bottomIndent="0">
            <TableActions.Play
                onClick={handleStartPassingTrack}
                requiredPermissions={PAGE_ACTIONS_REQUIRED_PERMISSIONS}
            />

            <TableActions.Info
                requiredPermissions={PAGE_ACTIONS_REQUIRED_PERMISSIONS}
            />
        </ButtonsToolbar>
    )
})
