import React, { FC, useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Row, Upload, message } from 'antd'
import {
    CompetenceContract,
    CompetenciesSearchContract,
    CompetenciesService,
    CompetenciesUploadResultContract,
    UserPermission,
} from 'core/api'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
    TOOLBAR_CONTENT_SETTINGS,
    ToolbarContentRenderChildrenProps,
} from 'components/layouts'
import { FnActionProps } from 'App.types'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { RcCustomRequestOptions } from 'types'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'
import { getRowHandlers } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useElementVisible } from 'hooks'
import { useToolbarGutterResponsive } from 'components/layouts/DictionaryPage/DictionaryPage.hooks'

import styles from './Competencies.module.scss'
import { COMPETENCIES_TABLE_COLUMNS } from './consts/Competencies.config'
import { CompetenciesFilters } from './components'

/** Справочник компетенций */
export const Competencies: FC = React.memo(() => {
    const {
        updateElementVisible: updateLoader,
        elementVisible: isLoading,
    } = useElementVisible()

    const gutterResponsive = useToolbarGutterResponsive()

    /**
     * Отправка файла на сервер
     * @param request - тело запроса
     */
    const uploadFile = useCallback(
        (reFetchDictionary?: FnActionProps) => async (
            request: RcCustomRequestOptions
        ) => {
            try {
                updateLoader(true)

                const result = await CompetenciesService.parseFile({
                    file: request.file,
                })

                const showSuccessMessage = (
                    result: CompetenciesUploadResultContract
                ) => {
                    message.success(
                        <>
                            <p>{`${LOCAL.ADDED}:`}</p>

                            <ul className={styles.message}>
                                <li>
                                    {`${LOCAL.CATALOGS.COMPETENCIES_GROUPS}: ${result.groupsAdded}`}
                                </li>

                                <li>
                                    {`${LOCAL.LABELS.COMPETENCIES}: ${result.competenciesAdded}`}
                                </li>

                                <li>
                                    {`${LOCAL.LABELS.INDICATORS}: ${result.indicatorsAdded}`}
                                </li>
                            </ul>
                        </>,
                        10
                    )
                }

                reFetchDictionary?.()

                result && showSuccessMessage(result)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        [updateLoader]
    )

    const renderTitle = useCallback(
        (title: React.ReactNode) => (
            <>
                {title}

                <Row gutter={15} className={styles.links}>
                    <Col>
                        <Link to={ROUTE_NAMES.COMPETENCIES_GROUPS}>
                            {LOCAL.CATALOGS.COMPETENCIES_GROUPS}
                        </Link>
                    </Col>

                    <Col>
                        <Link to={ROUTE_NAMES.COMPETENCIES_INDICATORS}>
                            {LOCAL.CATALOGS.COMPETENCIES_INDICATORS}
                        </Link>
                    </Col>

                    <Col>
                        <HasPermissions
                            requiredPermissions={[
                                UserPermission.CompetenceRead,
                                UserPermission.CompetenceSave,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                type: 'link',
                                text: LOCAL.ACTIONS.SET_ORDER,
                            })}
                        >
                            <Link to={ROUTE_NAMES.COMPETENCIES_ORDER_OPTIONS}>
                                {LOCAL.ACTIONS.SET_ORDER}
                            </Link>
                        </HasPermissions>
                    </Col>
                </Row>
            </>
        ),
        []
    )

    const renderToolbarContent = useCallback(
        (props: ToolbarContentRenderChildrenProps) => (
            <Row {...TOOLBAR_CONTENT_SETTINGS.PROPS} className="pt-20 pb-20">
                <Col>{renderTitle(props.titleComponent)}</Col>

                <Col>
                    <Row gutter={gutterResponsive} align="middle">
                        <Col>
                            <Row gutter={gutterResponsive} align="middle">
                                <Col>{props.quickSearchComponent}</Col>

                                <Col>{props.filterSwitch}</Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row gutter={TOOLBAR_CONTENT_SETTINGS.GUTTER.SM}>
                                <Col>
                                    <HasPermissions
                                        requiredPermissions={[
                                            UserPermission.CompetenceRead,
                                            UserPermission.CompetenceImport,
                                        ]}
                                        accessDeniedRender={renderAccessDeniedButton(
                                            {
                                                type: 'primary',
                                                text: LOCAL.ACTIONS.IMPORT,
                                            }
                                        )}
                                    >
                                        <Upload
                                            showUploadList={false}
                                            customRequest={uploadFile(
                                                props.reFetchDictionary
                                            )}
                                        >
                                            <Button
                                                disabled={isLoading}
                                                loading={isLoading}
                                            >
                                                {LOCAL.ACTIONS.IMPORT}
                                            </Button>
                                        </Upload>
                                    </HasPermissions>
                                </Col>

                                <Col>
                                    <HasPermissions
                                        requiredPermissions={[
                                            UserPermission.CompetenceRead,
                                            UserPermission.CompetenceSave,
                                        ]}
                                        accessDeniedRender={renderAccessDeniedButton(
                                            {
                                                type: 'primary',
                                                text: LOCAL.ACTIONS.CREATE,
                                            }
                                        )}
                                    >
                                        {props.createComponent}
                                    </HasPermissions>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        ),
        [gutterResponsive, isLoading, renderTitle, uploadFile]
    )

    return (
        <DictionaryPage<CompetenciesSearchContract, CompetenceContract>
            dictionaryKey={DICTIONARY_KEYS.COMPETENCIES}
            searchMethod={CompetenciesService.search}
            deleteMethod={CompetenciesService.delete}
            contentTitle={getRouteMeta(ROUTE_NAMES.COMPETENCIES)}
            tableColumns={COMPETENCIES_TABLE_COLUMNS}
            createRecordLink={{
                path: ROUTE_NAMES.COMPETENCIES_CREATE,
            }}
            quickSearchMethod={CompetenciesService.searchText}
            toolbarContentRender={renderToolbarContent}
            filterComponent={CompetenciesFilters}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES_WITH_CHANGE_DATE,
                },
            }}
            className={styles.wrapper}
            rowClassName={getRowClassNameArchive}
            onRow={getRowHandlers({ url: ROUTE_NAMES.COMPETENCIES_VIEW })}
        />
    )
})
