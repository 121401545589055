import { ExpertEvaluationListCompetenceContract } from 'core/api'

import {
    ActionForFormType,
    MapFormDataToRequestProps,
    SideType,
} from './AssessmentEvaluationForm.types'

/** Проверка типа индикатора */
export const isSideTypeStrong = (sideType: SideType) =>
    sideType === SideType.Strong

/** Проверка типа действия для формы */
export const isActionFOrFormTypeNext = (actionType?: ActionForFormType) =>
    actionType === ActionForFormType.Next
export const isActionFOrFormTypePrev = (actionType?: ActionForFormType) =>
    actionType === ActionForFormType.Prev
export const isActionFOrFormTypeFinish = (actionType?: ActionForFormType) =>
    actionType === ActionForFormType.Finish

/** Значения для формы
 * @param values - объект сущности с компетенцией
 */
export const mapResponseToFormData = (
    values: ExpertEvaluationListCompetenceContract
) => ({
    ...values,
    indicatorResults: values.indicatorResults.map((el) => ({
        ...el,
        isStrongSide: el.isStrongSide ? SideType.Strong : SideType.Weak,
    })),
})

/** Значения для отправки информации по оценке компетенции
 * @param values - данные формы
 * @param appointmentId - id оценки компетенций
 */
export const mapFormDataToRequest = (
    values: MapFormDataToRequestProps,
    appointmentId: number
) => ({
    appointmentId,
    questionnaires: values.questionnaireResults.map((el) => ({
        questionnaireId: el.questionnaireId,
        score: el.score,
    })),
    indicators: values.indicatorResults.map((el) => ({
        indicatorId: el.indicatorId,
        isStrongSide: isSideTypeStrong(el.isStrongSide),
    })),
    competenceId: values.competenceId,
    score: values.score,
    comment: values.comment,
})
