import {
    AppointmentActivityService,
    AppointmentTracksService,
    AppointmentsService,
    NotificationMailingTasksService,
} from 'core/api'

/** УТИЛИТЫ ДЛЯ РАБОТЫ С ДЕКОРАТОРАМИ НАД СЕРВИСАМИ API */

type CommonAppointmentsServiceProps<T> = (
    appointmentId: number,
    action: T
) => Promise<void>

/** Варианты управления процессом прохождения назначения */
export enum AppointmentProcessTypes {
    // Приостановить
    Suspend = 'suspendAppointment',

    // Отменить
    Cancel = 'cancelAppointment',

    // Начать
    CancelSuspension = 'cancelSuspensionAppointment',
}

/** Интерфейс сервиса-декоратора для контроля процесса прохождения назначения */
export type AppointmentsPassingServiceProps = CommonAppointmentsServiceProps<
    AppointmentProcessTypes
>

/**
 * Сервис-декоратор для контроля над процессом прохождения назначения
 * @param id идентификатор записи
 * @param action тип экшена (запроса на бэк)
 */
export const AppointmentsPassingService: AppointmentsPassingServiceProps = async (
    id,
    action
) => {
    await AppointmentsService[action]({ id })
}

export enum AppointmentTracksProcessTypes {
    // Приостановить
    Suspend = 'suspend',

    // Отменить
    Cancel = 'cancel',

    // Начать
    Resume = 'resume',
}

/** Интерфейс сервиса-декоратора для контроля процесса прохождения назначения треков */
export type AppointmentTracksPassingServiceProps = CommonAppointmentsServiceProps<
    AppointmentTracksProcessTypes
>

/**
 * Сервис-декоратор для контроля над процессом прохождения назначения треков
 * @param appointmentTrackId идентификатор записи
 * @param action тип экшена (запроса на бэк)
 */
export const AppointmentTracksPassingService: AppointmentTracksPassingServiceProps = async (
    appointmentTrackId,
    action
) => {
    await AppointmentTracksService[action]({ appointmentTrackId })
}

export enum AppointmentActivitiesProcessTypes {
    // Приостановить
    Suspend = 'suspend',

    // Отменить
    Cancel = 'cancel',

    // Начать
    CancelSuspension = 'cancelSuspension',
}

/** Интерфейс сервиса-декоратора для контроля процесса прохождения назначения мероприятий */
export type AppointmentActivitiesPassingServiceProps = CommonAppointmentsServiceProps<
    AppointmentActivitiesProcessTypes
>

/**
 * Сервис-декоратор для контроля над процессом прохождения назначения мероприятий
 * @param id идентификатор записи
 * @param action тип экшена (запроса на бэк)
 */
export const AppointmentActivitiesPassingService: AppointmentActivitiesPassingServiceProps = async (
    id,
    action
) => {
    await AppointmentActivityService[action]({ id })
}

export enum MailingAssignmentProcessTypes {
    // Приостановить
    Suspend = 'suspend',

    // Отменить
    Cancel = 'cancel',

    // Начать
    Resume = 'resume',
}

/** Интерфейс сервиса-декоратора для контроля процесса прохождения назначения мероприятий */
export type MailingAssignmentServiceProps = CommonAppointmentsServiceProps<
    MailingAssignmentProcessTypes
>

/**
 * Сервис-декоратор для контроля над процессом прохождения назначения мероприятий
 * @param id идентификатор записи
 * @param action тип экшена (запроса на бэк)
 */
export const MailingAssignmentServiceService: MailingAssignmentServiceProps = async (
    id,
    action
) => {
    await NotificationMailingTasksService[action]({ id })
}
