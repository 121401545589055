import styles from './TrackStageDnD.module.scss'
import { IconStatusProps } from '../../../TrackForm.types'

export const TRACK_STAGE_CLASS_NOT_DROPPED = styles.wrapperNotDropped
export const TRACK_STAGE_CLASS_DRAGGING_NOW = styles.wrapperDraggingNow
export const TRACK_STAGE_STATUSES: IconStatusProps[] = [
    'beforeStart',
    'inProcess',
    'passing',
]

export const TRACK_STAGE_ICON_SIZE_DEFAULT = 64
export const MIN_PULSE_TIME = 1
export const MAX_PULSE_TIME = 60
