import cn from 'classnames'
import omit from 'lodash/omit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import {
    ColumnsOptions,
    DictionarySyncDate,
    FiltersToggleButton,
    IconsAdapter,
    PageBanner,
    PageContent,
    PageTabs,
    PopupWithForm,
    QuickSearch,
    ResetButton,
    TableAdapter,
    Title,
} from 'components/shared'
import { ConfirmDeleteForm } from 'components/forms/ConfirmDeleteForm'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { RecordProps } from 'components/shared/TableAdapter/TableAdapter.types'
import { RequestQueryProps } from 'hooks/useSearchQuery.types'
import { SearchTextContract } from 'core/api'
import {
    createSuccessNotification,
    getPageSize,
    getTableColumnsWithClass,
    showElem,
} from 'utils'
import { pageTabsRender } from 'components/shared/PageTabs/PageTabs.utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useFilter, usePageSettings } from 'hooks'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import styles from './DictionaryPage.module.scss'
import { DictionaryPageProps } from './DictionaryPage.types'
import { TOOLBAR_CONTENT_SETTINGS } from './DictionaryPage.consts'
import { useToolbarGutterResponsive } from './DictionaryPage.hooks'

/** Универсальный компонент для отображения страниц типа "Справочник" */
function DictionaryPagePlain<
    Request extends object,
    Response extends object | RecordProps,
    SearchTextRequest = SearchTextContract
>({
    dictionaryKey,
    tableColumns,
    tableColumnsRender,
    searchMethod,
    onRow,
    filterComponent: FilterComponent,
    tableFiltersOptions,
    className,
    contentTitle,
    contentTitleType = 'h3',
    deleteMethod,
    createRecordLink,
    createRecordModal,
    toolbarContentRender,
    pageTabsRenderCustom,
    pageContentAfterRender,
    handleReset,
    rowClassName,
    integrationSyncDate,
    rowKey = 'id',
    quickSearchMethod,
    visibleColumnsOptions = true,
    isNeedUpdateAfterReset = false,
    columnOptionsClassName,
    updateLoader,
    keysOfAlwaysVisibleColumns,
    rowSelection,
    backLink,
    createPermissions,
    withPageBanner = true,
    withPagination = true,
}: DictionaryPageProps<Request, Response, SearchTextRequest>) {
    const { columnsVisible, saveFilters } = usePageSettings(dictionaryKey)

    const [tableData, setTableData] = useState<Response[]>()
    const [recordsCount, setRecordsCount] = useState(0)
    const [visibleFilter, toggleFilterVisibility] = useFilter()

    const gutterResponsive = useToolbarGutterResponsive()

    const {
        paginationOptions,
        queryParams,
        handleSort,
        handleSetFilters,
        handleResetFilters,
    } = useSearchQuery({
        dictionaryKey,
        queryOptions: {
            body: tableFiltersOptions?.initialValues,
        },
    })

    const dictionaryFetch = useCallback(async () => {
        try {
            updateLoader?.(true)

            const dataSource = queryParams.body?.searchText
                ? await quickSearchMethod?.(
                      queryParams as Required<RequestQueryProps>
                  )
                : await searchMethod?.(
                      queryParams as Required<RequestQueryProps>
                  )

            setRecordsCount(dataSource?.total || 0)
            setTableData(dataSource?.pageItems)
        } catch (error) {
            console.error(error)
        } finally {
            updateLoader?.(false)
        }
    }, [updateLoader, queryParams, quickSearchMethod, searchMethod])

    const handleDelete = useCallback((id: number) => deleteMethod?.({ id }), [
        deleteMethod,
    ])

    const handleRequestFinish = useCallback(dictionaryFetch, [dictionaryFetch])

    const handleResetWithUpdate = useCallback(async () => {
        await handleReset!()
        isNeedUpdateAfterReset && dictionaryFetch()
    }, [dictionaryFetch, isNeedUpdateAfterReset, handleReset])

    useEffect(() => {
        if (searchMethod || quickSearchMethod) {
            dictionaryFetch()
        }
    }, [dictionaryFetch, searchMethod, quickSearchMethod])

    const quickSearchComponent = useMemo(
        () =>
            quickSearchMethod && (
                <QuickSearch
                    onSetFilters={handleSetFilters}
                    isReset={visibleFilter}
                />
            ),
        [handleSetFilters, quickSearchMethod, visibleFilter]
    )

    const backLinkComponent = useMemo(
        () => (
            <Link to={backLink?.path || '#s'}>
                <IconsAdapter iconType="ArrowLeftOutlined" />

                {backLink?.text || LOCAL.LINKS.BACK_TO_THE_CATALOG}
            </Link>
        ),
        [backLink]
    )

    const filterSwitch = useMemo(
        () =>
            FilterComponent && (
                <FiltersToggleButton
                    onToggleFilterVisibility={toggleFilterVisibility}
                    isActive={saveFilters}
                />
            ),
        [FilterComponent, saveFilters, toggleFilterVisibility]
    )

    const titleComponent = useMemo(
        () => (
            <Title
                type={contentTitleType}
                text={contentTitle}
                bottomIndent="0"
            />
        ),
        [contentTitle, contentTitleType]
    )

    const createComponent = useMemo(
        () =>
            (createRecordModal || createRecordLink) && (
                <>
                    {createRecordModal && !createRecordLink && (
                        <PopupWithForm
                            component={createRecordModal.component}
                            formId={createRecordModal.formId}
                            buttonText={
                                createRecordModal.buttonText ||
                                LOCAL.ACTIONS.CREATE
                            }
                            onRequestFinish={handleRequestFinish}
                            modalOptions={{
                                ...createRecordModal?.modalOptions,
                                title:
                                    createRecordModal?.modalOptions?.title ||
                                    LOCAL.ACTIONS.RECORD_ADD,
                                ...(omit(createRecordModal?.modalOptions, [
                                    'title',
                                ]) || {}),
                            }}
                            bodyWithoutScroll={
                                createRecordModal.bodyWithoutScroll
                            }
                        />
                    )}

                    {createRecordLink && !createRecordModal && (
                        <Button href={createRecordLink.path}>
                            {createRecordLink.text || LOCAL.ACTIONS.CREATE}
                        </Button>
                    )}
                </>
            ),
        [createRecordLink, createRecordModal, handleRequestFinish]
    )

    /**
     * Содержимое после основного контента стр.
     */
    const pageContentAfterRendered = useMemo(
        () =>
            pageContentAfterRender?.({
                onRequestFinish: handleRequestFinish,
            }),
        [handleRequestFinish, pageContentAfterRender]
    )

    const tableColumnsCalculated = useMemo(
        () =>
            getTableColumnsWithClass<Response>(
                columnsVisible,
                tableColumnsRender?.({
                    reFetchDictionary: dictionaryFetch,
                    updateLoader,
                }) || tableColumns
            ),
        [
            columnsVisible,
            tableColumnsRender,
            dictionaryFetch,
            updateLoader,
            tableColumns,
        ]
    )

    const pagination = useMemo(
        () => ({
            ...paginationOptions,
            total: recordsCount,
            current: queryParams.pageNumber,
            pageSize: getPageSize(queryParams?.pageSize, tableData?.length),
        }),
        [paginationOptions, queryParams, recordsCount, tableData]
    )

    useEffect(() => {
        if (saveFilters) {
            createSuccessNotification(LOCAL.MESSAGES.SAVE_FILTERS)
        }
    }, [saveFilters])

    return (
        <PageContent filled className={cn(styles.wrapper, className)}>
            {withPageBanner && (
                <PageBanner>
                    {pageTabsRenderCustom ? (
                        pageTabsRenderCustom(recordsCount)
                    ) : (
                        <PageTabs tabsData={pageTabsRender(recordsCount)} />
                    )}

                    {integrationSyncDate && (
                        <DictionarySyncDate
                            integrationSyncDate={integrationSyncDate}
                        />
                    )}

                    {handleReset && (
                        <ResetButton handleReset={handleResetWithUpdate} />
                    )}
                </PageBanner>
            )}

            {toolbarContentRender?.({
                titleComponent,
                createComponent,
                filterSwitch,
                quickSearchComponent,
                backLinkComponent,
                reFetchDictionary: dictionaryFetch,
                tableData,
                queryParams,
            }) || (
                <div className="pt-20 pb-20">
                    <Row {...TOOLBAR_CONTENT_SETTINGS.PROPS}>
                        <Col className={styles.toolbarTitle}>
                            {titleComponent}
                        </Col>

                        <Col>
                            <Row gutter={gutterResponsive} align="middle">
                                <Col
                                    className={
                                        styles.toolbarQuickSearchAndFilters
                                    }
                                >
                                    <Row
                                        gutter={gutterResponsive}
                                        align="middle"
                                    >
                                        <Col
                                            className={
                                                styles.toolbarQuickSearch
                                            }
                                        >
                                            {quickSearchComponent}
                                        </Col>

                                        <Col className={styles.toolbarFilters}>
                                            {filterSwitch}
                                        </Col>
                                    </Row>
                                </Col>

                                {createComponent && (
                                    <Col className={styles.toolbarActions}>
                                        {createPermissions ? (
                                            <HasPermissions
                                                requiredPermissions={
                                                    createPermissions
                                                }
                                                accessDeniedRender={renderAccessDeniedButton(
                                                    {
                                                        type: 'primary',
                                                        text:
                                                            LOCAL.ACTIONS
                                                                .CREATE,
                                                    }
                                                )}
                                            >
                                                {createComponent}
                                            </HasPermissions>
                                        ) : (
                                            createComponent
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>

                    {backLink && (
                        <Row>
                            <Col className={styles.toolbarBackLink} xs={24}>
                                {backLinkComponent}
                            </Col>
                        </Row>
                    )}
                </div>
            )}
            {FilterComponent && (
                <div className={cn(styles.filterForm, showElem(visibleFilter))}>
                    <FilterComponent
                        tableFiltersOptions={tableFiltersOptions}
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                        dictionaryKey={dictionaryKey}
                    />
                </div>
            )}
            {visibleColumnsOptions && (
                <ColumnsOptions<Response>
                    dictionaryKey={dictionaryKey}
                    tableColumns={tableColumnsCalculated}
                    className={columnOptionsClassName}
                    keysOfAlwaysVisibleColumns={keysOfAlwaysVisibleColumns}
                />
            )}
            <TableAdapter<Response>
                columns={tableColumnsCalculated}
                dataSource={tableData}
                locale={{ emptyText: LOCAL.LABELS.EMPTY_DATA }}
                className={styles.table}
                rowClassName={rowClassName}
                pagination={withPagination && pagination}
                onRow={onRow}
                rowKey={rowKey}
                onChange={handleSort}
                showSorterTooltip={false}
                rowSelection={rowSelection}
            />
            {deleteMethod && (
                <PopupWithForm
                    component={ConfirmDeleteForm}
                    formId={FORM_IDS.CONFIRM_DELETE_FORM}
                    onRequestFinish={handleRequestFinish}
                    haveButton={false}
                    deleteFormAction={handleDelete}
                />
            )}
            {pageContentAfterRendered}
        </PageContent>
    )
}

type DictionaryPlainType = typeof DictionaryPagePlain

export const DictionaryPage = withLoader(
    React.memo(DictionaryPagePlain) as DictionaryPlainType
) as DictionaryPlainType
