export const TOOLTIPS = {
    ADDITIONAL_REQUIREMENTS_OF_JOB_PROFILE:
        'Укажите дополнительные требования к данной должности',
    ALL_REGISTRY_STATISTIC: 'Статистика по всем рассылкам, выбранным в фильтре',
    AUTHORITY_OF_JOB_PROFILE:
        'Орган власти, в рамках которого разрабатывается профиль должности',
    BRANCH_OF_JOB_PROFILE:
        'В какой отрасли требуется наличие опыта работы для данной должности',
    BUDGET_IN_MANAGEMENT_OF_JOB_PROFILE:
        'Если управленческий стаж необходим, укажите, опыт работы с каким бюджетом в непосредственном управлении необходим для данной должности',
    COMPETENCIES_OF_JOB_PROFILE:
        'Выберите компетенции, требования по которым будут включены в профиль данной должности',
    COMPLEX_OF_JOB_PROFILE:
        'Комплекс, в рамках которого разрабатывается профиль должности',
    EDUCATION_LEVEL_OF_JOB_PROFILE:
        'Какой уровень образования минимально необходим для работы в данной должности',
    EDUCATION_PROFILE_OF_JOB_PROFILE:
        'Образование по какому профилю необходимо для работы в данной должности',
    EMAILS_LIST:
        'Используйте точку с запятой для перечисления нескольких адресов',
    EXECUTION_DATE_OF_JOB_PROFILE: 'Дата создания профиля должности',
    EXPERIENCE_OF_JOB_PROFILE:
        'Какой стаж работы (в годах) в указанной выше отрасли требуется для данной должности',
    EXPERIENCE_ON_GGS_OF_JOB_PROFILE:
        'Если требуется стаж работы на ГГС, укажите, какой стаж необходим',
    FUNCTIONAL_RESPONSIBILITIES_OF_JOB_PROFILE:
        'Укажите основные обязанности сотрудника на данной должности',
    INDUSTRY_FOR_WHICH_MANAGEMENT_EXPERIENCE_IS_REQUIRED:
        'В какой отрасли требуется наличие управленческого опыта работы для данной должности',
    MANAGERIAL_EXPERIENCE_OF_JOB_PROFILE:
        'Какой стаж работы на управленческих позиция необходим для этой должности',
    NAME_OF_NEW_JOB_PROFILE:
        'Укажите, как будет называться новый профиль должности',
    NOTE_OF_JOB_PROFILE:
        'Опишите дополнительные пожелания к опыту работы сотрудника в этой должности',
    ORGANIZATION_OF_JOB_PROFILE:
        'Организация, в рамках которой разрабатывается профиль должности',
    PAUSE_JOB_TO_EDIT: 'Приостановите выполнение задания, чтобы редактировать',
    PERFORMANCE_INDICATORS_DESCRIPTION:
        'Опишите, какие параметры служат для оценки эффективности работы сотрудника на профилируемой должности',
    POSITION_LEVEL_OF_JOB_PROFILE: 'Какого уровня профилируемая должность',
    REQUEST_DATE_OF_JOB_PROFILE:
        'Дата получения заявки на создание профиля должности',
    REQUEST_NUMBER_OF_JOB_PROFILE:
        'Номер заявки, на основе которой создан профиль должности',
    RESPONDENTS_MIN_REQUIRED:
        'Необходимое минимальное количество <%= count %> респондентов',
    SELECT_CATEGORY_DESTINATION_OBJECT: 'Выберите вид объекта назначения',
    SELECT_MAILING_FOR_RESEND:
        'Выберите рассылки, в которых имеются неотправленные сообщения',
    SET_RESPONDENTS: 'Задано <%= count %> респондентов',
    SHOW_TO_EMPLOYEE: 'Отобразить сотруднику',
    STRUCTURAL_UNIT_OF_JOB_PROFILE:
        'Подразделение, в рамках которого разрабатывается профиль должности',
    SUBORDINATES_COUNT_OF_JOB_PROFILE:
        'Если управленческий стаж необходим, укажите, опыт управления каким количеством подчинённых необходим для этой должности',
    SUBORDINATIONS_OF_JOB_PROFILE:
        'Должность руководителя сотрудника на этой должности',
    TIME_DECODING: 'часы:минуты:секунды',
    TITLE_OF_POSITION_OF_JOB_PROFILE:
        'Должность, для которой создаётся профиль',
}
