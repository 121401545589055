import { UserPermission } from 'core/api'
import { getRouteMeta } from 'routing/routeNames.utils'

interface RouteItemProps {
    name: string
    permissions: UserPermission[]
}

/**
 * Получить параметры пункта меню
 * @param routeItem название маршрута
 * @returns подготовленный объект для формирования ссылки внутри компонента AdminMenu
 */
export const getMenuLink = ({ name, permissions }: RouteItemProps) => ({
    title: getRouteMeta(name, 'menuTitle') || '',
    link: name,
    key: name,
    permissions,
})
