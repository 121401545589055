import React from 'react'
import cn from 'classnames'
import { Anchor } from 'antd'

import styles from './AnchorDecorator.module.scss'
import { AnchorDecoratorProps } from './AnchorDecorator.types'

/** Декоратор для `Anchor` от `antd`, принимает все теже `props` */
export const AnchorDecorator: React.FC<AnchorDecoratorProps> = React.memo(
    ({ children, className, ...restProps }) => (
        <Anchor {...restProps} className={cn(styles.wrapper, className)}>
            {children}
        </Anchor>
    )
)
