import React from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CREATION_DATE,
} from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    TestPortfolioSearchContract,
    TestPortfolioSearchResultContract,
    TestPortfoliosService,
    UserPermission,
} from 'core/api'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'
import { getRowHandlers } from 'utils'

import { TEST_PORTFOLIOS_TABLE_COLUMNS } from './TestPortfolios.config'
import { TestPortfoliosFilters } from './components'

/** Страница реестра портфелей тестов*/
export const TestPortfolios: React.FC = React.memo(() => (
    <DictionaryPage<
        TestPortfolioSearchContract,
        TestPortfolioSearchResultContract
    >
        dictionaryKey={DICTIONARY_KEYS.TEST_PORTFOLIOS}
        searchMethod={TestPortfoliosService.search}
        deleteMethod={TestPortfoliosService.delete}
        contentTitle={getRouteMeta(ROUTE_NAMES.TEST_PORTFOLIOS, 'pageTitle')}
        tableColumns={TEST_PORTFOLIOS_TABLE_COLUMNS}
        createRecordLink={{
            path: ROUTE_NAMES.TEST_PORTFOLIOS_CREATE,
        }}
        filterComponent={TestPortfoliosFilters}
        tableFiltersOptions={{
            initialValues: {
                orderRules: ORDER_RULES_WITH_CREATION_DATE,
            },
        }}
        quickSearchMethod={TestPortfoliosService.searchText}
        createPermissions={[
            UserPermission.TestPortfolioRead,
            UserPermission.TestPortfolioSave,
        ]}
        rowClassName={getRowClassNameArchive}
        onRow={getRowHandlers({ url: ROUTE_NAMES.TEST_PORTFOLIOS_VIEW })}
    />
))
