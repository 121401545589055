import { StaffFieldsStatusProps } from '../CircleParameters/CircleParameters.types'

export const MIN_STAFF_COUNT = 5

export const STAFF_STATUS_INITIAL_STATE = {
    managers: '',
    staff: '',
} as StaffFieldsStatusProps

export const FORM_FIELD_PATHS = {
    type: 'type',
    circleParameters: {
        questionnaires: ['circleParameters', 'questionnaires'],
    },
    managerParameters: 'managerParameters',
}
