import React, { useEffect } from 'react'
import {
    CheckboxControl,
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import { Col, Form, Row, Space } from 'antd'
import { DICTIONARY_KEYS } from 'core/configs'
import { DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED, ROW_GUTTER } from 'consts'
import { DictionariesCommonProps } from 'App.types'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import { RenderFooterProps } from 'components/shared/FilterForm/FilterForm.types'
import { StructuralUnitsService, TrackSearchContract } from 'core/api'
import { useDictionaries } from 'hooks'

/** Фильтры для реестра шаблонов трека адаптации */
export const TrackPageFilters: React.FC<FiltersProps<
    TrackSearchContract
>> = React.memo((props) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<'structuralUnits'>
    >()

    const isAdaptationTrackPage =
        props.dictionaryKey === DICTIONARY_KEYS.ADAPTATION_TRACKS

    const renderFooter = ({
        cleanBtn,
        submitBtn,
        saveFiltersSwitch,
        isWidthLessLG,
    }: RenderFooterProps) => (
        <Row
            gutter={10}
            align="middle"
            justify={isWidthLessLG ? 'center' : 'end'}
        >
            <Col>
                <Space>
                    {isAdaptationTrackPage && (
                        <Form.Item name="showAutomaticAppointmentOnly" noStyle>
                            <CheckboxControl>
                                {LOCAL.LABELS.SHOW_AUTO_ASSIGNMENT}
                            </CheckboxControl>
                        </Form.Item>
                    )}

                    <Form.Item name="showArchive" noStyle>
                        <CheckboxControl>
                            {LOCAL.LABELS.ARCHIVED_RECORDS}
                        </CheckboxControl>
                    </Form.Item>
                </Space>
            </Col>

            {saveFiltersSwitch && <Col>{saveFiltersSwitch}</Col>}

            <Col>{submitBtn}</Col>

            <Col>{cleanBtn}</Col>
        </Row>
    )

    useEffect(() => {
        handleFetchDictionaries({
            structuralUnits: [
                StructuralUnitsService.getAllStructuralUnits(),
                'treeSelectForStructuralUnit',
            ],
        })
    }, [handleFetchDictionaries])

    return (
        <ControlGroup type="light">
            <FilterForm {...props} renderFooter={renderFooter}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="title"
                            label={LOCAL.LABELS.TRACK_TITLE}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitIds"
                            label={LOCAL.LABELS.ORGANIZATIONS}
                        >
                            <TreeSelectControl
                                treeData={dictionaries.structuralUnits || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CREATE_FROM,
                                fieldName: 'creatingDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CREATE_TO,
                                fieldName: 'creatingDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
