import { Resolutions } from 'core/configs'

export const getInitialWidth = (canvasKey: Resolutions) => {
    const getBoundary = (canvasKey: Resolutions) => Number(canvasKey) - 1

    switch (canvasKey) {
        case Resolutions.XS:
            return getBoundary(Resolutions.SM)
        case Resolutions.SM:
            return getBoundary(Resolutions.MD)
        case Resolutions.MD:
            return getBoundary(Resolutions.LG)
        case Resolutions.LG:
            return getBoundary(Resolutions.XL)
        case Resolutions.XL:
            return getBoundary(Resolutions.XXL)
        default:
            return 1920
    }
}
