import NoImage from 'core/images/no-image.svg'
import Paragraph from 'antd/lib/typography/Paragraph'
import React, { useCallback } from 'react'
import {
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { ConfirmDeleteForm } from 'components/forms'
import { FORM_IDS } from 'core/configs'
import { GlossariesService, UserPermission } from 'core/api'
import { HasPermissions } from 'core/permissions'
import { KNOWLEDGE_BASE_REQUIRED_PERMISSIONS } from 'consts'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ReactSVG } from 'react-svg'
import { formatDate, getBackgroundImageStyle } from 'utils'

import styles from './KnowledgeBaseCard.module.scss'
import { KnowledgeBaseCardProps } from './KnowledgeBaseCard.types'

/**
 * Карточка с информацией о сущности
 */
export const KnowledgeBaseCard: React.FC<KnowledgeBaseCardProps> = React.memo(
    ({ article, onDictionaryFetch, updateLoader }) => {
        const { picture, changeDate, isDraft, id, name } = article

        const handleArticleCopy = useCallback(async () => {
            try {
                updateLoader(true)

                await GlossariesService.copyArticle({ articleId: id })

                onDictionaryFetch()
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader, onDictionaryFetch, id])

        const handleArticleDelete = useCallback(
            async (articleId: number) => {
                try {
                    updateLoader(true)

                    await GlossariesService.deleteArticle({ articleId })

                    onDictionaryFetch()
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader, onDictionaryFetch]
        )

        return (
            <div className={styles.wrapper}>
                <Link
                    style={getBackgroundImageStyle(picture.url)}
                    className={styles.image}
                    to={`${ROUTE_NAMES.KNOWLEDGE_BASE_VIEW}/${id}`}
                >
                    {!picture.url && (
                        <ReactSVG src={NoImage} className={styles.noImage} />
                    )}
                </Link>

                <div className={styles.content}>
                    <div>
                        <div className={styles.header}>
                            <div className={styles.date}>
                                {formatDate(changeDate)}
                            </div>

                            {isDraft && (
                                <div className={styles.label}>
                                    {LOCAL.LABELS.IN_DEVELOPMENT}
                                </div>
                            )}
                        </div>

                        <Link
                            to={`${ROUTE_NAMES.KNOWLEDGE_BASE_VIEW}/${id}`}
                            title={name}
                        >
                            <Paragraph
                                className={styles.title}
                                ellipsis={{ rows: 3 }}
                            >
                                {name}
                            </Paragraph>
                        </Link>
                    </div>

                    <ButtonsToolbar bottomIndent="0" align="flex-start">
                        <HasPermissions
                            requiredPermissions={[
                                ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                                UserPermission.KnowledgeBaseArticleSave,
                            ]}
                        >
                            <TableActions.Copy onClick={handleArticleCopy} />
                        </HasPermissions>

                        <HasPermissions
                            requiredPermissions={[
                                ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                                UserPermission.KnowledgeBaseArticleSave,
                            ]}
                        >
                            <TableActions.Edit
                                href={`${ROUTE_NAMES.KNOWLEDGE_BASE_EDIT}/${id}`}
                            />
                        </HasPermissions>

                        <HasPermissions
                            requiredPermissions={[
                                ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                                UserPermission.KnowledgeBaseArticleDelete,
                            ]}
                        >
                            <PopupWithForm
                                formId={`${FORM_IDS.CONFIRM_DELETE_FORM}${id}`}
                                component={ConfirmDeleteForm}
                                buttonText=""
                                buttonOption={{
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="DeleteOutlined" />
                                    ),
                                    title: LOCAL.ACTIONS.DELETE,
                                }}
                                formOptions={{
                                    initialValues: { id },
                                }}
                                modalOptions={{
                                    title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                                    okText: LOCAL.YES,
                                }}
                                deleteFormAction={handleArticleDelete}
                            />
                        </HasPermissions>
                    </ButtonsToolbar>
                </div>
            </div>
        )
    }
)
