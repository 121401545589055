import React from 'react'
import { Col, Form, Row } from 'antd'
import {
    ControlGroup,
    ShouldUpdateChecker,
    SwitchControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import {
    isFormModeView,
    isTrackTypeAdaptation,
    isTrackTypeDevelopment,
} from 'utils'

import styles from './OverallSettings.module.scss'
import { AutomaticTrackAssignment } from '../../adaptation'
import { DescriptionArea } from '../DescriptionArea'
import { DevelopmentGoals } from '../../development'
import { OverallSettingsProps } from './OverallSettings.types'
import { TrackVisualizationOptions } from '../TrackVisualizationOptions'
import { UploadLogo } from '../UploadLogo'

export const OverallSettings: React.FC<OverallSettingsProps> = React.memo(
    ({ formMode, disabled }) => (
        <Row gutter={ROW_GUTTER} className={styles.wrapper}>
            <Col xs={8}>
                <DescriptionArea formMode={formMode} />
            </Col>

            <ShouldUpdateChecker fieldPath={['trackType']}>
                {(form) => {
                    const trackType = form.getFieldValue('trackType')

                    return (
                        <Col xs={8}>
                            {isTrackTypeAdaptation(trackType) && (
                                <AutomaticTrackAssignment
                                    formMode={formMode}
                                    disabled={disabled}
                                />
                            )}

                            {isTrackTypeDevelopment(trackType) && (
                                <ControlGroup
                                    type="light"
                                    className={styles.card}
                                >
                                    <DevelopmentGoals
                                        formMode={formMode}
                                        disabled={disabled}
                                    />

                                    <Form.Item name="isCertification">
                                        <SwitchControl
                                            addonAfter={
                                                LOCAL.LABELS.CERTIFICATION
                                            }
                                            disabled={
                                                isFormModeView(formMode) ||
                                                disabled
                                            }
                                        />
                                    </Form.Item>

                                    <UploadLogo
                                        form={form}
                                        formMode={formMode}
                                        disabled={disabled}
                                    />
                                </ControlGroup>
                            )}
                        </Col>
                    )
                }}
            </ShouldUpdateChecker>

            <Col xs={8}>
                <TrackVisualizationOptions
                    formMode={formMode}
                    disabled={disabled}
                />
            </Col>
        </Row>
    )
)
