import React, { useEffect } from 'react'
import { AssessmentReportManagerService } from 'core/api'
import { LOCAL } from 'core/local'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import { ComplianceProfilePositionBlock } from '../ComplianceProfilePositionBlock'

/** Отчёт по оценке руководителя. Анализ соответствия результатов сотрудника профилю должности */
export const ComplianceProfilePosition: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(
        AssessmentReportManagerService.getCompliancePositionProfile
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return data ? (
        <>
            <ComplianceProfilePositionBlock
                title={LOCAL.LABELS.ASSESSMENT_SELF}
                {...data.selfEstimation}
            />

            <ComplianceProfilePositionBlock
                title={LOCAL.LABELS.MANAGER}
                {...data.managerEstimation}
            />
        </>
    ) : null
})
