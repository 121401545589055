import React from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
} from 'components/controls'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import { LdeReportSearchContract } from 'core/api'
import { ROW_GUTTER } from 'consts'

/** Фильтр для реестра отчетов */
export const ReportsFilters: React.FC<FiltersProps<
    LdeReportSearchContract
>> = React.memo((props) => (
    <ControlGroup type="light">
        <FilterForm {...props}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.DOCUMENT_NAME}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="author"
                        label={LOCAL.LABELS.OWNER_NAME}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FromToDatesFilter
                        from={{
                            label: LOCAL.LABELS.DATE_CREATE_FROM,
                            fieldName: 'creationDateStart',
                        }}
                        to={{
                            label: LOCAL.LABELS.DATE_CREATE_TO,
                            fieldName: 'creationDateEnd',
                        }}
                    />
                </Col>
            </Row>
        </FilterForm>
    </ControlGroup>
))
