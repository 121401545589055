import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LEARNING_FORMATS, PRODUCT_TYPES } from 'consts'
import { LOCAL } from 'core/local'
import {
    ProductCardSearchResultContract,
    ProductType,
    StudyingFormat,
} from 'core/api'
import { SpaceAdapter, WidthRestrictedElement } from 'components/shared'
import { Typography } from 'antd'
import { defaultSorter } from 'components/layouts'
import {
    formatDate,
    isFirstDateEarlier,
    renderDocumentStatus,
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { ProductCatalogTableActions } from './components'

const { Text } = Typography

/**
 * настройка отображения колонок для реестра треков
 */
export const PRODUCT_CATALOG_TABLE_COLUMNS: ColumnsType<ProductCardSearchResultContract> = [
    {
        title: LOCAL.LABELS.PRODUCT_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        render: (_, record) => (
            <SpaceAdapter>
                {record.showInRecommended && (
                    <IconsAdapter iconType="CustomIconRecommended" />
                )}

                {restrictBigText(record.name)}
            </SpaceAdapter>
        ),
        fixed: 'left',
        width: '350px',
    },
    {
        title: LOCAL.LABELS.THEMATIC_SECTION,
        dataIndex: 'themes',
        key: 'themes',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.PRODUCT_SHAPE,
        dataIndex: 'type',
        key: 'type',
        render: (type: ProductType) => (
            <WidthRestrictedElement title={PRODUCT_TYPES[type]} />
        ),
    },
    {
        title: LOCAL.LABELS.LEARNING_FORMAT,
        dataIndex: 'studyingFormat',
        key: 'studyingFormat',
        render: (studyingFormat: StudyingFormat) => (
            <WidthRestrictedElement title={LEARNING_FORMATS[studyingFormat]} />
        ),
    },
    {
        title: LOCAL.LABELS.AVAILABLE_TO_ORGANIZATIONS,
        dataIndex: 'organizations',
        key: 'organizations',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.YEAR,
        dataIndex: 'year',
        key: 'year',
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'startPlacementDate',
        key: 'startPlacementDate',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'endPlacementDate',
        key: 'endPlacementDate',
        render: (date: string) =>
            isFirstDateEarlier(new Date(date), new Date()) ? (
                <Text type="danger">{formatDate(date)}</Text>
            ) : (
                formatDate(date)
            ),
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderDocumentStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <ProductCatalogTableActions tableRow={tableRow} />
        ),
        align: 'right',
        fixed: 'right',
    },
]
