import React, { useContext } from 'react'

import { ActivityLayer } from '../ActivityLayer'
import { CourseLayer } from '../CourseLayer'
import { TrackPassingContext } from '../../TrackPassing.context'

export const ContentOrActivityLayer: React.FC = React.memo(() => {
    const { store } = useContext(TrackPassingContext)

    const { stageInfo } = store

    if (stageInfo?.activity) {
        return <ActivityLayer />
    }

    if (stageInfo?.content) {
        return <CourseLayer />
    }

    return null
})
