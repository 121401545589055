import React, { useCallback } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { Popup } from 'components/shared/Popup'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ShouldUpdateChecker } from 'components/controls'
import { UploadCourseForm } from 'components/pages/TrainingMaterials/components'
import { convertToShouldUpdateDep } from 'utils'
import { useElementVisible } from 'hooks'
import { useHistory } from 'react-router-dom'

import styles from './AddCourseVersion.module.scss'
import { DEPENDENT_FIELDS } from './AddCourseVersion.consts'

/** Добавить версию курса*/
export const AddCourseVersion: React.FC = React.memo(() => {
    const history = useHistory()

    const {
        elementVisible,
        handleHideElement,
        handleShowElement,
    } = useElementVisible()

    const handleRequestFinish = useCallback(() => {
        history.push(ROUTE_NAMES.TRAINING_MATERIALS)
    }, [history])

    return (
        <ShouldUpdateChecker
            fieldPath={convertToShouldUpdateDep(DEPENDENT_FIELDS)}
        >
            {({ getFieldsValue }) => {
                const {
                    structuralUnit,
                    name,
                    format,
                    rootCourseVersion,
                } = getFieldsValue(DEPENDENT_FIELDS)

                return (
                    <>
                        <AddButton
                            buttonText={LOCAL.LABELS.NEW_VERSION}
                            className={styles.addButton}
                            onClick={handleShowElement}
                        />

                        <Popup
                            visible={elementVisible}
                            footer={null}
                            width={MODAL_WIDTH.MD}
                            onCancel={handleHideElement}
                            title={LOCAL.ACTIONS.ADD_MATERIAL}
                            destroyOnClose
                        >
                            <UploadCourseForm
                                onRequestFinish={handleRequestFinish}
                                onCancelSubmit={handleHideElement}
                                newVersion
                                initialValues={{
                                    structuralUnitId: structuralUnit?.id,
                                    rootCourseVersionId: rootCourseVersion?.id,
                                    name,
                                    format,
                                }}
                            />
                        </Popup>
                    </>
                )
            }}
        </ShouldUpdateChecker>
    )
})
