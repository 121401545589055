import React from 'react'
import cn from 'classnames'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker } from 'components/controls'
import { TextAlignProperty } from 'App.types'
import {
    convertToShouldUpdateDep,
    getBackgroundImageStyle,
    getStyles,
} from 'utils'

import styles from './ModalCanvas.module.scss'
import { CANVAS_DEPENDENCIES } from '../EventFormVisualization/EventFormVisualization.consts'
import { MapFormDataToRequestProps } from '../EventForm/EventForm.types'
import { ModalBlocks } from '../ModalBlocks'

/**
 * Отображение для мероприятия типа - "modal"
 */
export const ModalCanvas: React.FC = React.memo(() => (
    <ShouldUpdateChecker
        fieldPath={convertToShouldUpdateDep(CANVAS_DEPENDENCIES)}
    >
        {({ getFieldsValue }) => {
            const {
                canvas,
                lessons,
            }: MapFormDataToRequestProps = getFieldsValue(CANVAS_DEPENDENCIES)

            return (
                <main
                    className={styles.wrapper}
                    style={{
                        justifyContent: canvas.windowAlign,
                    }}
                >
                    <div className={styles.body}>
                        <IconsAdapter
                            iconType="CloseOutlined"
                            className={styles.closeButton}
                            style={{ color: canvas.buttonColor }}
                        />

                        <div
                            className={styles.image}
                            style={getBackgroundImageStyle(
                                canvas.canvasBackgroundUrl
                            )}
                        />

                        <h1
                            className={styles.blockTitle}
                            style={getStyles(canvas.blockNameSettings)}
                        >
                            {lessons?.[0]?.name}
                        </h1>

                        <pre
                            className={styles.blockDescription}
                            style={{
                                ...getStyles(canvas.blockDescriptionSettings),
                                borderColor:
                                    canvas.thematicBlockColorWhilePassing,
                            }}
                        >
                            {lessons?.[0]?.lessonDescription}
                        </pre>

                        {!!lessons?.length && (
                            <div className={styles.blocks}>
                                <ModalBlocks
                                    thematicBlocksCount={lessons.length}
                                    {...canvas}
                                />
                            </div>
                        )}

                        <div
                            style={{
                                textAlign: canvas.buttonAlign as TextAlignProperty,
                            }}
                        >
                            <Button
                                className={cn(
                                    styles.startButton,
                                    'capitalize-text'
                                )}
                                style={{
                                    background: canvas.buttonColor,
                                    color: canvas.buttonTextColor,
                                    borderColor: canvas.buttonColor,
                                }}
                            >
                                {LOCAL.ACTIONS.PROCEED}
                            </Button>
                        </div>
                    </div>
                </main>
            )
        }}
    </ShouldUpdateChecker>
))
