import React, { memo } from 'react'

import styles from './StageProgress.module.scss'
import { StageProgressPropsModel } from './StageProgress.types'

/**
 * Компонент для отображения прогресса этапа трека
 */
export const StageProgress: React.FC<StageProgressPropsModel> = memo(
    ({ stageCount, currentStage }) => (
        <div className={styles.wrapper}>{`${currentStage}/${stageCount}`}</div>
    )
)
