import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { CourseAppointmentContract, CoursesService } from 'core/api'
import { LOCAL } from 'core/local'
import { TableAdapter } from 'components/shared'
import { withLoader } from 'HOCs'

import styles from './CourseActiveAppointments.module.scss'
import { COURSE_ACTIVE_APPOINTMENTS_TABLE_COLUMNS } from './CourseActiveAppointments.config'
import { CourseActiveAppointmentsProps } from './CourseActiveAppointments.types'

/** Вкладка активных назначений курса */
export const CourseActiveAppointments: React.FC<CourseActiveAppointmentsProps> = withLoader(
    React.memo(({ id, updateLoader }) => {
        const [courseAppointments, setCourseAppointments] = useState<
            CourseAppointmentContract[]
        >()

        useEffect(() => {
            const fetchCourseAppointment = async () => {
                try {
                    updateLoader(true)

                    const apointment = await CoursesService.getRelatedAppointments(
                        {
                            id,
                        }
                    )

                    setCourseAppointments(apointment)
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }

            fetchCourseAppointment()
        }, [id, updateLoader])

        return (
            <div className={styles.wrapper}>
                <h2>{LOCAL.LABELS.ACTIVE_APPOINTMENTS_INFO}</h2>

                {!!courseAppointments?.length ? (
                    <TableAdapter
                        dataSource={courseAppointments}
                        columns={COURSE_ACTIVE_APPOINTMENTS_TABLE_COLUMNS}
                        rowKey="appointmentId"
                    />
                ) : (
                    <p className={cn(styles.text, 'mt-40')}>
                        {LOCAL.LABELS.NO_ACTIVE_APPOINTMENTS_YET}
                    </p>
                )}
            </div>
        )
    })
)
