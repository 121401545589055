import React from 'react'
import { DATE_FORMAT } from 'consts'
import {
    DatePickerControl,
    InputControl,
    InputNumberControl,
    SelectControl,
} from 'components/controls'
import { FormMode } from 'App.types'
import { NotificationConditionParameterType } from 'core/api'
import { normalizeDataForSelectAndRadio } from 'utils'

import { NotificationFilteringParameterCustomContract } from './ConditionParamsControl.types'

export const isEnumeration = (
    parameterType: NotificationConditionParameterType
) => parameterType === NotificationConditionParameterType.Enumeration

export const isDateTime = (parameterType: NotificationConditionParameterType) =>
    parameterType === NotificationConditionParameterType.DateTime

export const isInteger = (parameterType: NotificationConditionParameterType) =>
    parameterType === NotificationConditionParameterType.Integer

/**
 * рендер параметра условия отправки в зависимости от типа
 * @param parameter
 * @param formMode
 */
export const renderParamControl = (
    parameter: NotificationFilteringParameterCustomContract,
    formMode?: FormMode
) => {
    switch (parameter.parameterType) {
        case NotificationConditionParameterType.DateTime:
            return (
                <DatePickerControl
                    showTime={{
                        format: DATE_FORMAT.HH_mm,
                    }}
                    format={DATE_FORMAT.LIST_WITH_TIME}
                    formMode={formMode}
                />
            )
        case NotificationConditionParameterType.Enumeration:
            return (
                <SelectControl
                    values={parameter.parameterValues.map(
                        normalizeDataForSelectAndRadio
                    )}
                    formMode={formMode}
                />
            )
        case NotificationConditionParameterType.Integer:
            return <InputNumberControl fullWidth formMode={formMode} />
        default:
            return <InputControl formMode={formMode} />
    }
}

/**
 * определяет относительную ширину блока параметра, последний нечетный параметр занимает полную ширину контейнера, все остальные половину
 * @param index индекс параметра в массиве
 * @param arrayLength длина массива параметров
 */
export const getParameterFieldWidth = (index: number, arrayLength: number) =>
    index === arrayLength - 1 && index % 2 === 0 ? 24 : 12

/**
 * подготавливает данные параметров для рендера
 * @param parameters справочные значения параметров, связанные с выбранные условием
 */
export const getRenderParametersData = (
    parameters: NotificationFilteringParameterCustomContract[] = []
): NotificationFilteringParameterCustomContract[] =>
    parameters.reduce(
        (acc: NotificationFilteringParameterCustomContract[], el) => [
            ...acc,
            {
                ...el,
                value: el.parameterValues?.find(
                    (parameterValue) => parameterValue.isDefault
                )?.id,
            },
        ],
        []
    )
