import React from 'react'
import { Diagram } from 'components/shared'
import { LOCAL } from 'core/local'

import styles from './SemanticCharts.module.scss'
import { ChartColor } from '../../QuizReport.consts'
import { RenderChartsProps } from './SemanticCharts.types'
import {
    SEMANTIC_BACKGROUNDS,
    getSemanticChartOptions,
} from './SemanticCharts.config'

const getChartHistogramBackgrounds = (value: number) =>
    value < 0
        ? SEMANTIC_BACKGROUNDS[ChartColor.Red]
        : SEMANTIC_BACKGROUNDS[ChartColor.Green]

export const getSemanticChartData = (value: number) => ({
    labels: [LOCAL.LABELS.AVERAGE_VALUE],
    responsive: true,
    datasets: [
        {
            data: [value],
            backgroundColor: getChartHistogramBackgrounds(value),
            barThickness: 40,
            borderRadius: 20,
        },
    ],
})

export const renderCharts = ({
    grafics,
    scaleFrom,
    scaleTo,
    theme,
}: RenderChartsProps) =>
    grafics.map((item) => (
        <div className={styles.wrapper} key={item.name}>
            <Diagram
                type="bar"
                data={getSemanticChartData(item.value)}
                options={getSemanticChartOptions({
                    chartTitle: item.name,
                    min: scaleFrom,
                    max: scaleTo,
                    count: item.value,
                    theme,
                })}
                chartDataLabelsPlugin
            />
        </div>
    ))
