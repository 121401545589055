import throttle from 'lodash/throttle'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { BackTop, ControlPanel, PageContent } from 'components/shared'
import { ColorThemeContext } from 'contexts'
import {
    CompetencesPublicQuestionsContract,
    PassingFormsService,
    QuestionType,
    StopPassingContract,
} from 'core/api'
import { ComponentCommonProps, UrlParamsCommonProps } from 'App.types'
import { Form } from 'antd'
import { HiddenField } from 'components/controls'
import { PassingStage } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { VALIDATE_MESSAGE_DEFAULT } from 'core/configs'
import {
    changeQuestionEndToEndNumber,
    getBannerBackgroundCssProp,
    getFlatCompetences,
    getFlatQuestions,
    getUniqueItemsArray,
    isAppointmentStatusEnded,
    isFormModePassing,
    isFormModeView,
} from 'utils'
import {
    isPassingStageDone,
    isPassingStageGreeting,
    isPassingStageInProgress,
} from 'utils/conditions.utils/passingStage.utils'
import { useHistory, useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './UserAssignmentPassing.module.scss'
import {
    DictionariesStateProps,
    PassingStateProps,
    PassingSubmitValues,
} from './UserAssignmentPassing.types'
import { PassingDoneStage } from '../PassingDoneStage'
import { PassingGreetingsStage } from '../PassingGreetingsStage'
import { PassingMenu } from '../PassingMenu'
import { THROTTLE_INTERVAL } from './UserAssignmentPassing.consts'
import { UserAssignmentPassingForm } from '../UserAssignmentPassingForm'
import { UserAssignmentPassingHeader } from '../UserAssignmentPassingHeader'
import {
    changeUnsavedAnswers,
    filterAnswers,
    filterAnswersForPassingState,
    getActiveCompetenceId,
    getCheckAnswers,
    getCompetencesStats,
    mapFormDataToRequest,
    mapResponseToFormData,
    setCompetencesStats,
} from './UserAssignmentPassing.utils'

/** Страница прохождения назначения пользователем */
export const UserAssignmentPassing: React.FC<ComponentCommonProps> = withLoader(
    React.memo(({ formMode, updateLoader }) => {
        const { theme } = useContext(ColorThemeContext)
        const [form] = Form.useForm()
        const { assignmentId, candidateId } = useParams<
            Required<UrlParamsCommonProps<'assignmentId' | 'candidateId'>>
        >()
        const { push } = useHistory()

        /** Справочники */
        const [{ questionnaire, competences }, setDictionaries] = useState<
            DictionariesStateProps
        >({})

        /** Состояние прохождения */
        const [
            {
                questions,
                savedAnswers,
                activeCompetenceContract,
                competencesStats,
                stage,
                passingResults,
                unsavedAnswers,
                picture,
            },
            setPassingState,
        ] = useState<PassingStateProps>({
            stage: PassingStage.GREETINGS,
            unsavedAnswers: {},
        })

        /** Есть ли изменения и ответы в форме */
        const isFormTouched = useMemo<boolean>(
            () => !!Object.keys(unsavedAnswers).length,
            [unsavedAnswers]
        )

        /** Запрос информации по опросу */
        const fetchQuestionnaire = useCallback(async () => {
            try {
                updateLoader(true)

                const body = {
                    appointmentId: +assignmentId,
                    candidateId: +candidateId,
                }

                const questionnaireData = changeQuestionEndToEndNumber(
                    await (isFormModeView(formMode)
                        ? PassingFormsService.getPassingInfo(body)
                        : PassingFormsService.startPassing({
                              body,
                          }))
                )

                // Мапим данные в массивы
                const newCompetences = getFlatCompetences(questionnaireData)
                const newAnswersIds = getUniqueItemsArray(
                    questionnaireData.answers.map((el) => el.questionId)
                )
                const newQuestions = getFlatQuestions(questionnaireData)
                const newSavedQuestions = mapResponseToFormData(
                    questionnaireData.answers
                )
                const newCompetenceStats = getCompetencesStats(
                    newCompetences,
                    newAnswersIds
                )
                const newActiveCompetenceContract =
                    questionnaireData?.groupedQuestions?.[0]
                        ?.competenceQuestions?.[0]

                setDictionaries((prev) => ({
                    ...prev,
                    questionnaire: questionnaireData,
                    competences: newCompetences,
                }))

                setPassingState((prev) => ({
                    ...prev,
                    questions: newQuestions,
                    savedAnswers: newSavedQuestions,
                    activeCompetenceContract: newActiveCompetenceContract,
                    competencesStats: newCompetenceStats,
                    picture:
                        questionnaireData.assessmentPortfolioPresentation
                            ?.passingPicture,
                }))

                // Если прохождение закончено, сразу кидаем на финальный экран
                if (
                    isAppointmentStatusEnded(questionnaireData?.passingStatus)
                ) {
                    const newPassingResults = {
                        endDate: questionnaireData?.endTime,
                        passingQuestionsCount: newAnswersIds.length,
                        totalQuestionsCount: newQuestions?.length || 0,
                    }

                    setPassingState((prev) => ({
                        ...prev,
                        stage: PassingStage.DONE,
                        passingResults: newPassingResults,
                        picture:
                            questionnaireData?.assessmentPortfolioPresentation
                                ?.finalWindow?.picture,
                    }))

                    return
                }

                if (isPassingStageGreeting(stage)) {
                    setPassingState((prev) => ({
                        ...prev,
                        picture:
                            questionnaireData.assessmentPortfolioPresentation
                                ?.welcomeWindow.picture,
                    }))
                }
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [assignmentId, candidateId, formMode, stage, updateLoader])

        /** Запрашиваем данные по опросу во время инициализации */
        useEffect(() => {
            if (isPassingStageDone(stage)) return
            fetchQuestionnaire()
        }, [fetchQuestionnaire, stage])

        /** Когда меняются сохраненные ответы, подставляем их в форму */
        useEffect(() => {
            form.setFieldsValue(savedAnswers)
        }, [savedAnswers, form])

        /** Вычисляем активную компетенцию по позиции скролла */
        const handleScroll = useCallback(() => {
            const activeId = getActiveCompetenceId()
            if (activeId && competences) {
                setPassingState((prev) => ({
                    ...prev,
                    activeCompetenceContract: competences.find(
                        (el) => el.competence.id === activeId
                    ),
                }))
            }
        }, [competences])

        /** Обрабатываем скролл, если отображение вопросов "по одному" */
        useEffect(() => {
            if (
                !questionnaire?.assessmentPortfolioPresentation
                    ?.showQuestionsByBlock
            ) {
                const throttleHandleScroll = throttle(
                    handleScroll,
                    THROTTLE_INTERVAL
                )
                document.addEventListener('scroll', throttleHandleScroll)

                return () => {
                    document.removeEventListener('scroll', throttleHandleScroll)
                }
            }
        }, [questionnaire, handleScroll])

        /** Обрабатываем изменения формы */
        const handleFormValuesChange = useCallback(
            (values: PassingSubmitValues, allValues: PassingSubmitValues) => {
                const [questionId, questionValue] = Object.entries(values)[0]
                const questionType = Object.keys(
                    questionValue
                )[0] as QuestionType

                setPassingState((prev) => {
                    const savedAnswers = Object.keys(
                        prev.savedAnswers || {}
                    ).reduce<PassingSubmitValues>(
                        (acc, el) =>
                            el === questionId
                                ? acc
                                : { ...acc, [el]: prev.savedAnswers![el] },
                        {}
                    )

                    return {
                        ...setCompetencesStats(prev, savedAnswers, competences),
                        unsavedAnswers: changeUnsavedAnswers(
                            prev.unsavedAnswers,
                            questionId,
                            questionType,
                            questionValue,
                            allValues
                        ),
                        savedAnswers,
                    }
                })
            },
            [competences]
        )

        /** Обрабатываем кнопку "подтвердить" */
        const handleFinish = useCallback(async () => {
            if (questionnaire) {
                try {
                    updateLoader(true)

                    const filteredAnswers = filterAnswers(unsavedAnswers)
                    const filteredAnswersForPassingState = filterAnswersForPassingState(
                        unsavedAnswers
                    )
                    const dataForRequest = mapFormDataToRequest(filteredAnswers)

                    await PassingFormsService.setAnswers({
                        id: +questionnaire?.id,
                        body: dataForRequest,
                    })

                    setPassingState((prev) => {
                        const savedAnswers = {
                            ...prev.savedAnswers,
                            ...filteredAnswersForPassingState,
                        }

                        return {
                            ...setCompetencesStats(
                                prev,
                                savedAnswers,
                                competences
                            ),
                            unsavedAnswers: {},
                            savedAnswers,
                        }
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }
        }, [questionnaire, unsavedAnswers, competences, updateLoader])

        /** Обрабатываем кнопку "завершить" */
        const handleComplete = useCallback(async () => {
            if (questionnaire) {
                try {
                    updateLoader(true)

                    const {
                        picture,
                        ...results
                    } = await PassingFormsService.done({
                        id: +questionnaire?.id,
                        body: {} as StopPassingContract,
                    })

                    setPassingState((prev) => ({
                        ...prev,
                        passingResults: results,
                        picture,
                        stage: PassingStage.DONE,
                    }))
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }
        }, [questionnaire, updateLoader])

        /** Колбэк для изменения активной компетенции */
        const setActiveCompetenceContract = useCallback(
            (competenceContract: CompetencesPublicQuestionsContract) => {
                setPassingState((prev) => ({
                    ...prev,
                    activeCompetenceContract: competenceContract,
                }))
            },
            []
        )

        /** Поменять стадию на "прохождение" */
        const setStagePassing = useCallback(() => {
            setPassingState((prev) => ({
                ...prev,
                stage: PassingStage.PASSING,
            }))
        }, [])

        /** Стадия приветствия */
        const greetingsStageContent = useMemo(() => {
            if (
                questionnaire &&
                competences &&
                questions &&
                isPassingStageGreeting(stage)
            ) {
                return (
                    <PassingGreetingsStage
                        questionnaire={questionnaire}
                        onStart={setStagePassing}
                        numberOfCompetences={competences.length}
                        numberOfQuestions={questions.length}
                    />
                )
            }
        }, [competences, questionnaire, questions, setStagePassing, stage])

        /** Стадия прохождения*/
        const passingStageContent = useMemo(() => {
            if (
                questionnaire &&
                questions &&
                activeCompetenceContract &&
                competences &&
                (isPassingStageInProgress(stage) ||
                    (isPassingStageDone(stage) && isFormModeView(formMode)))
            ) {
                return (
                    <>
                        <UserAssignmentPassingHeader
                            {...questionnaire}
                            numberOfQuestions={questions.length}
                            numberOfAnswers={
                                Object.keys(savedAnswers || {}).length
                            }
                            hasUnsavedChanges={isFormTouched}
                        />

                        {questionnaire.assessmentPortfolioPresentation
                            .showPassingProgress && (
                            <ControlPanel
                                passingData={{
                                    questionnaire,
                                    questions,
                                    id: Number(assignmentId),
                                }}
                                checkAnswers={getCheckAnswers({
                                    ...savedAnswers,
                                    ...unsavedAnswers,
                                })}
                            />
                        )}

                        <div className={styles.body}>
                            <UserAssignmentPassingForm
                                isFormTouched={isFormTouched}
                                canComplete={
                                    Object.keys(savedAnswers || {}).length ===
                                        questions.length && !isFormTouched
                                }
                                setActiveCompetenceContract={
                                    setActiveCompetenceContract
                                }
                                activeCompetenceContract={
                                    activeCompetenceContract
                                }
                                competences={competences}
                                onComplete={handleComplete}
                                questionnaire={questionnaire}
                            />

                            <PassingMenu
                                {...questionnaire}
                                activeCompetenceContract={
                                    activeCompetenceContract
                                }
                                setActiveCompetenceContract={
                                    setActiveCompetenceContract
                                }
                                competencesStats={competencesStats}
                            />
                        </div>
                    </>
                )
            }
        }, [
            unsavedAnswers,
            activeCompetenceContract,
            assignmentId,
            competences,
            competencesStats,
            formMode,
            handleComplete,
            isFormTouched,
            questionnaire,
            questions,
            savedAnswers,
            setActiveCompetenceContract,
            stage,
        ])

        /** Обработчик перехода обратно в реестр назначений, при нажатии на кнопку типа 'ok' в окне завершения прохождения назначения */
        const backToUserAssignmentsRegister = useCallback(() => {
            push(ROUTE_NAMES.USER_ASSIGNMENTS)
        }, [push])

        /** Стадия окончания */
        const doneStageContent = useMemo(() => {
            if (
                questionnaire &&
                questions &&
                passingResults &&
                isPassingStageDone(stage) &&
                isFormModePassing(formMode)
            ) {
                return (
                    <PassingDoneStage
                        questionnaire={questionnaire}
                        onOk={backToUserAssignmentsRegister}
                        numberOfAnswers={passingResults.passingQuestionsCount}
                        numberOfQuestions={passingResults.totalQuestionsCount}
                        date={passingResults.endDate}
                    />
                )
            }
        }, [
            questionnaire,
            questions,
            passingResults,
            stage,
            formMode,
            backToUserAssignmentsRegister,
        ])

        return (
            <PageContent
                className={styles.wrapper}
                style={getBannerBackgroundCssProp({
                    url: picture?.url,
                    theme,
                })}
            >
                <BackTop bottom={130} />

                {greetingsStageContent}

                <Form
                    form={form}
                    onValuesChange={handleFormValuesChange}
                    onFinish={handleFinish}
                    initialValues={savedAnswers}
                    validateMessages={{
                        required: VALIDATE_MESSAGE_DEFAULT,
                    }}
                >
                    <HiddenField fieldName="formMode" initialValue={formMode} />

                    {passingStageContent}
                </Form>

                {doneStageContent}
            </PageContent>
        )
    })
)
