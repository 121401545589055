import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { AppointmentType, ProductCardsService } from 'core/api'
import { Button } from 'components/shared/Button'
import { LOCAL } from 'core/local'
import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'
import { VideoContentModal } from 'components/shared'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { isTrainingMaterialFormatVideo } from 'utils'
import { useElementVisible } from 'hooks'
import { useHistory } from 'react-router-dom'

import styles from './ProductCardLauncher.module.scss'
import { LauncherProps } from './ProductCardLauncher.types'
import { PRODUCT_CARD_EVENTS } from '../../ProductCard.events'
import { getLaunchButtonProps } from './ProductCardLauncher.utils'

export const ProductCardLauncher: React.FC<LauncherProps> = React.memo(
    ({
        card,
        long,
        isListenerEnrolled,
        showMessage,
        isAppointed,
        fetchPageInfo,
    }) => {
        const history = useHistory()

        const {
            elementVisible,
            handleShowElement,
            handleHideElement,
        } = useElementVisible()

        const goToCourse = useCallback(() => {
            if (!card) return

            if (isTrainingMaterialFormatVideo(card.course.format)) {
                handleShowElement()

                return
            }

            history.push(
                `${ROUTE_NAMES.PRODUCT_CARD_VIEW}/${card.id}/${ROUTES_POSTFIXES.COURSE}`
            )
        }, [card, handleShowElement, history])

        const onSetAppointment = useCallback(async () => {
            try {
                const appointment = await ProductCardsService.autoAppointmentForCurrentUser(
                    {
                        productCardId: Number(card?.id),
                    }
                )

                eventEmitter.emit(PRODUCT_CARD_EVENTS.SET_APPOINTED)

                switch (appointment.appointmentType) {
                    case AppointmentType.Activity:
                        window.open(
                            `${ROUTE_NAMES.EVENTS_PASSING}/${appointment.activityId}/${appointment.appointmentId}`,
                            '_blank'
                        )
                        break

                    default:
                        window.open(
                            `${ROUTE_NAMES.TRACKS_PASSING}/${appointment.appointmentId}/start`,
                            '_blank'
                        )
                        break
                }
            } catch (err) {
                console.error(err)
            }
        }, [card])

        const props = useMemo(
            () =>
                getLaunchButtonProps({
                    onSetAppointment,
                    goToCourse,
                    fetchPageInfo,
                    isEnrolled: isListenerEnrolled,
                    card,
                }),
            [
                onSetAppointment,
                goToCourse,
                fetchPageInfo,
                isListenerEnrolled,
                card,
            ]
        )

        const videoPopupProps = useMemo(
            () => ({
                visible: elementVisible,
                onCancel: handleHideElement,
            }),
            [elementVisible, handleHideElement]
        )

        return props ? (
            <>
                <Button
                    {...props}
                    className={cn(
                        styles.wrapper,
                        (isAppointed || props.disabled) &&
                            styles.wrapperDisabled,
                        long && styles.wrapperLong
                    )}
                    disabled={isAppointed || props.disabled}
                />

                {isAppointed && showMessage && (
                    <p className={styles.message}>
                        {LOCAL.MESSAGES.PROGRAM_AVAILABLE_AT_YOUR_DEVELOPMENT}
                    </p>
                )}

                {card?.course?.launchUrl && (
                    <VideoContentModal
                        popupProps={videoPopupProps}
                        fileUrl={card.course.launchUrl}
                        autoPlay
                    />
                )}
            </>
        ) : null
    }
)
