import React, { useEffect } from 'react'
import { Circle360ReportService } from 'core/api'
import { REPORT_LEVELS_MAX } from 'consts'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { getRoundedOneCharNumber } from 'utils'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './CompetenceRating.module.scss'
import stylesReportView from '../ReportView/ReportView.module.scss'
import { calculatePercent } from './CompetenceRating.utils'
import { dividerBackground } from './CompetenceRating.config'
import { getConsistencyIcon } from '../ReportView/ReportView.utils'

/** Отчет по оценке 360. Рейтинг оценок по компетенциям */
export const CompetenceRating: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(
        Circle360ReportService.getCompetenceRating360
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return (
        <div className={styles.grid}>
            {data?.map((el) => {
                const value = getRoundedOneCharNumber(
                    el.averageScoreWithoutSelfEstim
                )
                const width = calculatePercent(+value, REPORT_LEVELS_MAX)

                return (
                    <>
                        <div className={styles.name}>
                            {el.competence.name}

                            {getConsistencyIcon(el.consistency)}
                        </div>

                        <div
                            className={stylesReportView.line}
                            style={{ width }}
                        >
                            {value}
                        </div>

                        <div
                            className={styles.divider}
                            style={{ background: dividerBackground }}
                        />
                    </>
                )
            })}
        </div>
    )
})
