import React from 'react'
import { Col, Row } from 'antd'
import { CompetenciesSearchContract } from 'core/api'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'

/**
 * Форма фильтрации компетенций
 */
export const CompetenciesFilters: React.FC<FiltersProps<
    CompetenciesSearchContract
>> = React.memo((props) => (
    <FilterForm {...props}>
        <Row gutter={ROW_GUTTER}>
            <Col xs={12}>
                <FormItemAdapter
                    fieldName="competenceGroupName"
                    label={LOCAL.LABELS.COMPETENCIES_GROUP_NAME}
                >
                    <InputControl />
                </FormItemAdapter>
            </Col>

            <Col xs={12}>
                <FormItemAdapter
                    fieldName="competenceName"
                    label={LOCAL.LABELS.COMPETENCE_NAME}
                >
                    <InputControl />
                </FormItemAdapter>
            </Col>
        </Row>
    </FilterForm>
))
