import React, { useCallback, useContext, useState } from 'react'
import {
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { FormMode, TableActionsCommonProps } from 'App.types'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { QUESTIONNAIRES_REQUIRED_PERMISSIONS } from 'consts'
import {
    QuestionnaireSearchResultContract,
    QuestionnairesService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { StoreContext } from 'components/pages/Questionnaires/Questionnaires.context'
import {
    isDocumentStatusDraft,
    isDocumentStatusReadyForWork,
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useHistory } from 'react-router-dom'

/** Компонент для создания колонки действий внутри таблицы реестра конструктора опросных листов */
export const QuestionnairesTableActions: React.FC<TableActionsCommonProps<
    QuestionnaireSearchResultContract
>> = React.memo(({ tableRow }) => {
    const history = useHistory()
    const { updateStore } = useContext(StoreContext)
    const [isLoading, setLoading] = useState(false)
    const [lastActionType, setLastActionType] = useState<FormMode>()

    const getRouteForAction = useCallback(
        (type: FormMode) => {
            const id = tableRow.id

            switch (type) {
                case 'edit':
                    return `${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_EDIT}/${id}`
                case 'view':
                    return `${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW}/${id}`
                case 'copy':
                    return `${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_COPY}/${id}`
            }
        },
        [tableRow.id]
    )

    const entityActionFn = useCallback(
        (id: number, type: FormMode) => async () => {
            try {
                setLoading(true)
                setLastActionType(type)

                updateStore({
                    currentQuestionnaire: isFormModeEdit(type)
                        ? await QuestionnairesService.getForEdit({ id })
                        : await QuestionnairesService.getActive({ id }),
                })

                const route = getRouteForAction(type)

                if (route) history.push(route)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
                setLastActionType(undefined)
            }
        },
        [getRouteForAction, history, updateStore]
    )

    return (
        <ButtonsToolbar bottomIndent="0">
            <TableActions.View
                requiredPermissions={QUESTIONNAIRES_REQUIRED_PERMISSIONS}
                disabled={!tableRow.active}
                onClick={entityActionFn(tableRow.id, 'view')}
                loading={isLoading && isFormModeView(lastActionType)}
            />

            {tableRow.active && (
                <>
                    {(isDocumentStatusDraft(tableRow.status) ||
                        isDocumentStatusReadyForWork(tableRow.status)) && (
                        <TableActions.Edit
                            requiredPermissions={[
                                ...QUESTIONNAIRES_REQUIRED_PERMISSIONS,
                                UserPermission.QuestionnaireSave,
                            ]}
                            onClick={entityActionFn(tableRow.id, 'edit')}
                            loading={
                                isLoading && isFormModeEdit(lastActionType)
                            }
                        />
                    )}

                    <TableActions.Copy
                        requiredPermissions={[
                            ...QUESTIONNAIRES_REQUIRED_PERMISSIONS,
                            UserPermission.QuestionnaireSave,
                        ]}
                        onClick={entityActionFn(tableRow.id, 'copy')}
                        loading={isLoading && isFormModeCopy(lastActionType)}
                    />

                    <HasPermissions
                        requiredPermissions={[
                            ...QUESTIONNAIRES_REQUIRED_PERMISSIONS,
                            UserPermission.QuestionnaireDelete,
                        ]}
                        accessDeniedRender={renderAccessDeniedButton({
                            type: 'link',
                            icon: <IconsAdapter iconType="DeleteOutlined" />,
                        })}
                    >
                        <PopupWithForm
                            formId={FORM_IDS.CONFIRM_DELETE_FORM}
                            buttonText=""
                            havePopup={false}
                            buttonOption={{
                                title: LOCAL.ACTIONS.DELETE,
                                type: 'link',
                                icon: (
                                    <IconsAdapter iconType="DeleteOutlined" />
                                ),
                            }}
                            formOptions={{
                                initialValues: tableRow,
                            }}
                            modalOptions={{
                                title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                                okText: LOCAL.YES,
                            }}
                        />
                    </HasPermissions>
                </>
            )}
        </ButtonsToolbar>
    )
})
