import React, { FC, useCallback, useMemo, useState } from 'react'
import { AppError, AttachmentsService } from 'core/api'
import {
    CheckboxControl,
    InputControl,
    InputNumberEditControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { Form } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import {
    PicturesWallControl,
    PicturesWallControlValueProps,
} from 'components/controls/PicturesWallControl'
import { RcCustomRequestOptions } from 'types'
import {
    isFormModeView,
    isQuestionnaireTypeQuiz,
    isQuestionnaireTypeTest,
} from 'utils'

import styles from './AnswerRow.module.scss'
import { AnswerRowProps } from './AnswerRow.types'
import { TEST_TASK_SCORING_DEFAULT } from './AnswerRow.const'
import { disablePoints } from './AnswerRow.utils'

/** Ряд параметров для компетенции */
export const AnswerRow: FC<AnswerRowProps> = React.memo(
    ({ value = {}, onChange, answerRowIndex, onRemove, disabled, type }) => {
        const [text, setText] = useState<string>()
        const [points, setPoints] = useState<number | undefined>(
            isQuestionnaireTypeTest(type)
                ? TEST_TASK_SCORING_DEFAULT
                : undefined
        )
        const [isRightAnswer, setIsRightAnswer] = useState<boolean>()
        const [attachment, setAttachment] = useState<
            PicturesWallControlValueProps
        >()

        /**
         * Обновить значение во внутреннем стейте компонента и внешней форме
         * @param changedValue новое значение для поля
         */
        const triggerChange = useCallback(
            (changedValue: AnswerRowProps['value']) => {
                onChange?.({
                    text,
                    points,
                    isRightAnswer,
                    attachment,
                    ...value,
                    ...changedValue,
                })
            },
            [attachment, isRightAnswer, onChange, points, text, value]
        )

        /**
         * Обработчик изменения поля {LOCAL.LABELS.TEXT}
         * @param event - объект события
         */
        const handleTextChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                setText(event.target.value)
                triggerChange({ text: event.target.value })
            },
            [triggerChange]
        )

        /**
         * Обработчик изменения поля "Количество баллов"
         * @param value - новое значение
         */
        const handlePointsChange = useCallback(
            (value?: number) => {
                setPoints(value)
                triggerChange({ points: value })
            },
            [triggerChange]
        )

        /**
         * Обработчик изменения поля "Правильный ответ"
         * @param event - объект события
         */
        const handleIsRightAnswerChange = useCallback(
            (checked?: boolean) => {
                setIsRightAnswer(checked)
                triggerChange({ isRightAnswer: checked })
            },
            [triggerChange]
        )

        /**
         * Обработчик изменения поля "Изображение"
         * @param value - новое значение
         */
        const handleAttachmentChange = useCallback(
            (value?: PicturesWallControlValueProps) => {
                setAttachment(value)
                triggerChange({ attachment: value })
            },
            [triggerChange]
        )

        /**
         * Отправка файла на сервер
         * @param request - тело запроса
         */
        const uploadFile = useCallback(
            async (request: RcCustomRequestOptions) => {
                try {
                    const result = await AttachmentsService.upload({
                        file: request.file,
                    })

                    request.onSuccess?.(result, {} as XMLHttpRequest)
                } catch (error) {
                    request.onError?.(error as AppError)
                }
            },
            []
        )

        /**
         * Рендер кнопки загрузки картинки
         */
        const uploadText = useMemo(
            () => (
                <div className="ant-upload-text">
                    <IconsAdapter
                        iconType="FileAddOutlined"
                        disabled={disabled}
                    />
                </div>
            ),
            [disabled]
        )

        return (
            <>
                {!answerRowIndex && (
                    <div className={styles.header}>
                        <div className={styles.text}>
                            {LOCAL.LABELS.DESCRIPTION}
                        </div>

                        <div className={styles.image} />

                        <div className={styles.points}>
                            {LOCAL.LABELS.POINTS_FOR_ANSWER}
                        </div>

                        {!isQuestionnaireTypeQuiz(type) && (
                            <div className={styles.rightVariant}>
                                {LOCAL.LABELS.RIGHT_ANSWER}
                            </div>
                        )}

                        <div className={styles.remove} />
                    </div>
                )}

                <ShouldUpdateChecker fieldPath="formMode">
                    {({ getFieldValue }) => {
                        const formMode = getFieldValue('formMode')

                        return (
                            <div className={styles.wrapper}>
                                <div className={styles.number}>{`${
                                    answerRowIndex + 1
                                }.`}</div>

                                <div className={styles.text}>
                                    <Form.Item>
                                        <InputControl
                                            onChange={handleTextChange}
                                            value={value.text || text}
                                            formMode={formMode}
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                </div>

                                <div className={styles.image}>
                                    <PicturesWallControl
                                        maxPicturesCount={1}
                                        uploadText={uploadText}
                                        customRequest={uploadFile}
                                        value={value.attachment || attachment}
                                        onChange={handleAttachmentChange}
                                        disabled={
                                            isFormModeView(formMode) || disabled
                                        }
                                        className={styles.picturesWallControl}
                                    />
                                </div>

                                <div className={styles.points}>
                                    <Form.Item shouldUpdate>
                                        {(form) => (
                                            <InputNumberEditControl
                                                onChange={handlePointsChange}
                                                initialValue={
                                                    value.points ?? points
                                                }
                                                {...disablePoints(
                                                    form.getFieldsValue()
                                                )}
                                                disabled={
                                                    isFormModeView(formMode) ||
                                                    disabled
                                                }
                                                min={0}
                                                className={
                                                    styles.inputNumberEditControl
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                </div>

                                {!isQuestionnaireTypeQuiz(type) && (
                                    <div className={styles.rightVariant}>
                                        <Form.Item>
                                            <CheckboxControl
                                                onChange={
                                                    handleIsRightAnswerChange
                                                }
                                                checked={
                                                    value.isRightAnswer ||
                                                    isRightAnswer
                                                }
                                                formMode={formMode}
                                                disabled={disabled}
                                            />
                                        </Form.Item>
                                    </div>
                                )}

                                <div className={styles.remove}>
                                    {Boolean(answerRowIndex) &&
                                        !isFormModeView(formMode) &&
                                        !disabled && (
                                            <IconsAdapter
                                                iconType="CloseOutlined"
                                                onClick={onRemove}
                                            />
                                        )}
                                </div>
                            </div>
                        )
                    }}
                </ShouldUpdateChecker>
            </>
        )
    }
)
