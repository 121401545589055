import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { ReactTextNullable } from 'App.types'
import { isFormModeView } from 'utils'

import styles from './InputNumberEditControl.module.scss'
import { InputNumberControl } from '../InputControl'
import { InputNumberEditControlProps } from './InputNumberEditControl.types'

/**
 * Кастомный инпут типа `number`, имеет два режима отображения,
 * в режиме редактирования это обычный инпут, в режиме просмотра это текст
 */
export const InputNumberEditControl: React.FC<InputNumberEditControlProps> = ({
    value,
    initialValue,
    onChange,
    min,
    max,
    disabled,
    placeholderDisabled,
    formMode,
    className,
}) => {
    /**
     * Стейт для управления режимом отображения
     */
    const [isEdit, setEdit] = useState<boolean>(false)

    /**
     * Стейт для хранения набранных значений
     */
    const [number, setNumber] = useState<number>()

    const getResultValue = useCallback(
        (value?: number) => {
            if (typeof max === 'number' && number && number >= max) return max
            if (typeof min === 'number' && number && number <= min) return min

            return value || number
        },
        [max, min, number]
    )

    /**
     * Обработчик устновки значения
     */
    const handleSetEdit = useCallback(() => {
        setEdit(true)
    }, [])

    /**
     * Обработчик отмены установки значения
     */
    const handleCancelEdit = useCallback(() => {
        setEdit(false)
        setNumber(value || initialValue)
    }, [initialValue, value])

    /**
     * Обработчик подтверждения установки набранного значения
     */
    const handleConfirmEdit = useCallback(() => {
        if (onChange) onChange(getResultValue())
        setNumber(getResultValue())
        setEdit(false)
    }, [getResultValue, onChange])

    /**
     * Обработчик изменения поля
     * @param value новое значение
     */
    const handleNumberChange = (value?: ReactTextNullable) => {
        setNumber(Number(value))
    }

    useEffect(() => {
        setNumber(value || initialValue)
    }, [initialValue, value])

    /**
     * Получить стили для отображения иконки
     */
    const getIconClassNames = useCallback(
        (reverse?: boolean) => {
            const isEditValue = reverse ? isEdit : !isEdit
            const isHidden = disabled || isFormModeView(formMode) || isEditValue

            return cn(styles.icon, isHidden && styles.iconHidden)
        },
        [disabled, formMode, isEdit]
    )

    return (
        <div className={cn('form-control', className)}>
            <div className={styles.value}>
                {isEdit ? (
                    <InputNumberControl
                        value={number}
                        onChange={handleNumberChange}
                        placeholder={min ? String(min) : '0'}
                        min={min}
                        max={max}
                    />
                ) : (
                    <div>{value || number || placeholderDisabled || '0'}</div>
                )}

                <IconsAdapter
                    iconType="CheckOutlined"
                    className={getIconClassNames()}
                    onClick={handleConfirmEdit}
                />

                <IconsAdapter
                    iconType="CloseOutlined"
                    className={getIconClassNames()}
                    onClick={handleCancelEdit}
                />

                <IconsAdapter
                    iconType="EditOutlined"
                    className={getIconClassNames(true)}
                    onClick={handleSetEdit}
                />
            </div>
        </div>
    )
}
