import React from 'react'
import uniq from 'lodash/uniq'
import { ColumnsType } from 'antd/lib/table'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { History } from 'history'
import { LOCAL } from 'core/local'
import { PROFILE_TYPES } from 'consts'
import {
    PositionProfileFromFileContract,
    PositionProfileImportState,
    PositionProfileSaveContract,
    PositionProfileSearchResultContract,
    StructuralUnitType,
} from 'core/api'
import {
    formatDate,
    getItemId,
    renderDocumentStatus,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import {
    JobProfileFromStateProps,
    JobProfileHistoryStateProps,
} from './JobProfiles.types'
import { JobProfilesTableActions } from './components'

export const isFromUserProfile = (
    history: History<JobProfileHistoryStateProps>
) => history.location.state?.from === JobProfileFromStateProps.UserProfile

interface StructuralUnitWithIdProps {
    supervisingComplexId?: number
    oivId?: number
    structuralUnitId?: number
    organization?: number
}

/**
 * Получить идентификатор структурной единицы в зависимости от ее типа
 * @param unitId идентификатор структурной единицы
 * @param unitType тип структурной единицы
 * @return {StructuralUnitWithIdProps} объект содержащий id одного из типов структурных единиц
 *     (комплекс/оив/организация)
 */
export const getStructuralUnitIdDependingOnType = (
    unitId: number,
    unitType?: StructuralUnitType
): StructuralUnitWithIdProps => {
    switch (unitType) {
        case StructuralUnitType.Complex:
            return { supervisingComplexId: unitId }
        case StructuralUnitType.Oiv:
            return { oivId: unitId }
        case StructuralUnitType.Unit:
            return { structuralUnitId: unitId }
        default:
            return { organization: unitId }
    }
}

/**
 * Мапим импортированные ПД для публикации
 * @param parsedData
 */
export const mapParsedPositionProfilesForSave = (
    parsedData: PositionProfileFromFileContract[]
): Partial<PositionProfileSaveContract>[] =>
    parsedData
        .filter(
            (item) =>
                item.state === PositionProfileImportState.New ||
                item.state === PositionProfileImportState.ExistsAndUpdatable
        )
        .map((item) => ({
            ...item,
            name: item.importId,
            educationLevelIds: item.educationLevels.map(getItemId),
            educationProfileIds: item.educationProfiles.map(getItemId),
            requiredExperienceIndustryIds: [item.requiredExperienceIndustryId],
            functionClassesIds: item.functionalClasses.map(getItemId),
            structuralUnitId:
                item.thirdOrderUnitId ||
                item.secondOrderUnitId ||
                item.firstOrderUnitId ||
                item.organizationId,
            competenceGroupsIds: uniq(
                item.competences.map((item) => item.competenceGroupId)
            ),
            competencies: item.competences.map((item) => ({
                ...item,
                id: item.competenceId,
                isAdjacent: false,
            })),
        }))

export const getJobProfileColumns = (
    props: DictionaryPageActionsProps
): ColumnsType<PositionProfileSearchResultContract> => [
    {
        title: LOCAL.LABELS.PROFILE_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'positionName',
        key: 'positionName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.COMPLEX,
        dataIndex: 'supervisingComplexName',
        key: 'supervisingComplexName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.OIV,
        dataIndex: 'oivName',
        key: 'oivName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizationName',
        key: 'organizationName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.STRUCTURAL_UNIT,
        dataIndex: 'structuralUnitName',
        key: 'structuralUnitName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.PROFILE,
        dataIndex: 'type',
        key: 'type',
        render: (_, record) => PROFILE_TYPES[record.type],
    },
    {
        title: LOCAL.LABELS.REQUEST_NUMBER,
        dataIndex: 'requestNumber',
        key: 'requestNumber',
    },
    {
        title: LOCAL.LABELS.REQUEST_DATE,
        dataIndex: 'requestDate',
        key: 'requestDate',
        render: formatDate,
    },
    {
        title: 'ФИО исполнителя',
        dataIndex: 'changeUserName',
        key: 'changeUserName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.VERSION,
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderDocumentStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <JobProfilesTableActions tableRow={tableRow} {...props} />
        ),
        align: 'right',
        fixed: 'right',
    },
]
