import React from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, IconsAdapter, PopupWithForm } from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'

import { UserNotifictionsHeaderActionsProps } from './UserNotificationsHeaderActions.types'

/** Рендер кнопок управления для хедера таблицы уведомлений */
export const UserNotificationsHeaderActions: React.FC<UserNotifictionsHeaderActionsProps> = React.memo(
    ({ onSetAsRead, tableRow }) => (
        <ButtonsToolbar bottomIndent="0">
            <Button
                title={LOCAL.MARK_AS_READ_SELECTED}
                type="link"
                icon={<IconsAdapter iconType="MailOutlined" />}
                onClick={onSetAsRead}
            />

            <PopupWithForm
                formId={FORM_IDS.CONFIRM_DELETE_FORM}
                buttonText=""
                havePopup={false}
                buttonOption={{
                    title: LOCAL.DELETE_SELECTED,
                    type: 'link',
                    icon: <IconsAdapter iconType="DeleteOutlined" />,
                }}
                formOptions={{
                    initialValues: tableRow,
                }}
                modalOptions={{
                    title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                    okText: LOCAL.YES,
                }}
            />
        </ButtonsToolbar>
    )
)
