import React from 'react'
import { COL_xl8_lg12_xs24, ROW_GUTTER } from 'consts'
import { Col, Row } from 'antd'
import { FormItemAdapter, InputControl } from 'components/controls'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import styles from './ThemeBlockAttachmentsRow.module.scss'
import { ThemeBlockAttachmentsRowProps } from './ThemeBlockAttachmentsRow.types'

export const ThemeBlockAttachmentsRow: React.FC<ThemeBlockAttachmentsRowProps> = React.memo(
    ({ field, remove, disabled, formMode }) => (
        <Row gutter={ROW_GUTTER} className={styles.row}>
            <Col {...COL_xl8_lg12_xs24} className={styles.fileName}>
                <FormItemAdapter fieldName={[field.name, 'fileName']}>
                    <InputControl bordered={false} readOnly />
                </FormItemAdapter>
            </Col>

            <Col {...COL_xl8_lg12_xs24}>
                <FormItemAdapter fieldName={[field.name, 'name']}>
                    <InputControl
                        placeholder={LOCAL.PLACEHOLDERS.INPUT}
                        disabled={disabled}
                        formMode={formMode}
                    />
                </FormItemAdapter>
            </Col>

            {!isFormModeView(formMode) && (
                <IconsAdapter
                    iconType="CloseOutlined"
                    className={styles.flexCenter}
                    onClick={() => remove(field.name)}
                    disabled={disabled}
                />
            )}
        </Row>
    )
)
