import { QuestionVariationValueProps } from '../QuestionVariation/QuestionVariation.types'

export const getFormatedQuestions = (
    questions: QuestionVariationValueProps[],
    fieldName: number
) =>
    questions.reduce(
        (
            acc: QuestionVariationValueProps[],
            current: QuestionVariationValueProps,
            index: number
        ) => {
            if (index === fieldName) {
                return [...acc, { ...current, attachments: [] }]
            }

            return [...acc, current]
        },
        []
    )
