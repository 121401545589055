import React, { FC } from 'react'
import { UserProfile } from 'components/pages/UserProfile'
import { useParams } from 'react-router-dom'

import { CoworkerUrlParamsProps } from './CoworkerRecord.types'

/** Справочник сотрудников: Карточка сотрудника */
export const CoworkerRecord: FC = React.memo(() => {
    const coworkerUrlParams = useParams<CoworkerUrlParamsProps>()

    return <UserProfile id={Number(coworkerUrlParams.id)} />
})
