import React from 'react'

import styles from './ModalSingleBlock.module.scss'

/** Получить линию соединяющую тематический блок с основной панелью*/
export const getChain = (backgroundColor?: string) => (
    <div
        className={styles.chain}
        style={{
            backgroundColor,
        }}
    />
)
