import React, { useCallback } from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import {
    RrwebStorageService,
    SessionInfoContract,
    SessionSearchContract,
} from 'core/api'
import { getPageTitleFromRoute } from 'utils'
import { useElementVisible, useRrweb } from 'hooks'
import { useLocation } from 'react-router-dom'
import { useToolbarGutterResponsive } from 'components/layouts/DictionaryPage/DictionaryPage.hooks'
import { withLoader } from 'HOCs'

import { MonitoringAgreement } from './MonitoringAgreement'
import { MonitoringFilters } from './MonitoringFilters'
import { getTableColumnsConfig } from './Monitoring.config.table'
import { renderToolbarContent } from './Monitoring.utils'

/** Личный кабинет пользователя: реестр мониторинга пользователей */
export const Monitoring: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const { elementVisible, toggleElementVisible } = useElementVisible()

        const gutterResponsive = useToolbarGutterResponsive()
        const location = useLocation()
        const {
            toggleMonitoringSettings,
            stopMonitoring,
            monitoringEnable,
        } = useRrweb()

        const toggleMonitoring = useCallback(
            (e) => {
                try {
                    updateLoader(true)

                    e ? toggleElementVisible() : stopMonitoring()
                } catch (err) {
                    console.error(err)
                } finally {
                    updateLoader(false)
                }
            },
            [stopMonitoring, toggleElementVisible, updateLoader]
        )

        const enableMonitoring = useCallback(() => {
            toggleMonitoringSettings(true)
            toggleElementVisible()
        }, [toggleMonitoringSettings, toggleElementVisible])

        return (
            <>
                <DictionaryPage<SessionSearchContract, SessionInfoContract>
                    dictionaryKey={DICTIONARY_KEYS.MONITORING}
                    searchMethod={RrwebStorageService.search}
                    contentTitle={getPageTitleFromRoute(location.pathname)}
                    contentTitleType="h5"
                    tableColumnsRender={getTableColumnsConfig}
                    filterComponent={MonitoringFilters}
                    toolbarContentRender={renderToolbarContent({
                        gutterResponsive,
                        toggleMonitoring,
                        monitoringEnable,
                    })}
                    withPageBanner={false}
                />

                <MonitoringAgreement
                    visible={elementVisible}
                    onCancel={toggleElementVisible}
                    onOk={enableMonitoring}
                />
            </>
        )
    })
)
