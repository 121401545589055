import { FormListFieldData } from 'antd/lib/form/FormList'
import { Store } from 'App.types'

interface StagesGroupsProps extends Store<FormListFieldData[]> {}

/**
 * Разбить этапы трека (Form.list) на группы для построения горизонтальных списков DnD
 * @param stages этапы трека
 * @returns {StagesGroupsProps} этапы трека разбитые по группам
 */
export const makeStagesGroups = (stages: FormListFieldData[]) => {
    let tempGroup: FormListFieldData[] = []
    let groupNumber = 0

    const groups = stages.reduce<StagesGroupsProps>(
        (group, currStage, index) => {
            tempGroup.push(currStage)

            if (tempGroup.length >= 3 || stages.length === index + 1) {
                group[`group-${groupNumber}`] = tempGroup
                groupNumber += 1
                tempGroup = []
            }

            return group
        },
        {}
    )

    return groups
}
