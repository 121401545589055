import React from 'react'
import {
    ACTIVITIES_TABLE_COLUMNS,
    ActivitiesFilters,
    GetByIdsMethodParamProps,
    InputControl,
    SearchSelectControl,
    TRACK_COLUMNS,
    TRAINING_MATERIALS_TABLE_COLUMNS,
    TracksFilters,
    TrainingMaterialsFilters,
} from 'components/controls'
import {
    ActivitiesService,
    ActivityModalContract,
    ActivityModalSearchContract,
    CourseForModalContract,
    CourseSearchContract,
    CoursesService,
    DevelopmentTrackContract,
    DevelopmentTracksService,
    ProductType,
    TrackModalContract,
    TrackModalSearchContract,
} from 'core/api'
import {
    EVENTS_REQUIRED_PERMISSIONS,
    TRACK_DEVELOPMENT_PERMISSIONS,
    TRAINING_MATERIALS_PERMISSIONS,
} from 'consts'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { RowSelectionType } from 'antd/lib/table/interface'

export const SEARCH_TABLE_COMMON_OPTIONS = {
    rowSelectionType: 'radio' as RowSelectionType,
}

export const PRODUCT_TYPES_VALUES = [
    {
        value: ProductType.Track,
        label: LOCAL.LABELS.TRACK,
    },
    {
        value: ProductType.Activity,
        label: LOCAL.LABELS.EVENTS_M,
    },
    {
        value: ProductType.Content,
        label: LOCAL.LABELS.CONTENT,
    },
    {
        value: ProductType.Book,
        label: LOCAL.LABELS.BOOK,
    },
    {
        value: ProductType.Podcast,
        label: LOCAL.LABELS.PODCAST,
    },
]

const PRODUCT_FORM_ITEM_CHILDREN_BY_TYPE = {
    [ProductType.Track]: (
        <SearchSelectControl<
            TrackModalSearchContract,
            TrackModalContract | DevelopmentTrackContract
        >
            {...SEARCH_TABLE_COMMON_OPTIONS}
            modalTitle={LOCAL.LABELS.TRACK_SELECTION}
            getByIdsMethod={({ body }: GetByIdsMethodParamProps) =>
                DevelopmentTracksService.getActive({ id: Number(body?.[0]) })
            }
            tableSearchOptions={{
                filterComponent: TracksFilters,
                tableColumns: TRACK_COLUMNS,
                searchMethod: DevelopmentTracksService.searchForModal,
            }}
        />
    ),
    [ProductType.Activity]: (
        <SearchSelectControl<ActivityModalSearchContract, ActivityModalContract>
            {...SEARCH_TABLE_COMMON_OPTIONS}
            getByIdsMethod={ActivitiesService.getByIds}
            modalTitle={LOCAL.LABELS.EVENT_M_SELECTION}
            tableSearchOptions={{
                filterComponent: ActivitiesFilters,
                tableColumns: ACTIVITIES_TABLE_COLUMNS,
                searchMethod: ActivitiesService.getForModal,
            }}
        />
    ),
    [ProductType.Content]: (
        <SearchSelectControl<CourseSearchContract, CourseForModalContract>
            {...SEARCH_TABLE_COMMON_OPTIONS}
            getByIdsMethod={CoursesService.getByIds}
            modalTitle={LOCAL.LABELS.CONTENT_SELECTION}
            tableSearchOptions={{
                filterComponent: TrainingMaterialsFilters,
                tableColumns: TRAINING_MATERIALS_TABLE_COLUMNS,
                searchMethod: CoursesService.getForModal,
            }}
        />
    ),
    [ProductType.Book]: <InputControl />,
    [ProductType.Podcast]: <InputControl />,
}

/** Объект, содержащий элемент формы продукта соответствующего типа */
export const PRODUCT_FORM_ITEM_BY_TYPE = {
    [ProductType.Track]: {
        name: 'track',
        label: LOCAL.LABELS.TRACK,
        children: PRODUCT_FORM_ITEM_CHILDREN_BY_TYPE[ProductType.Track],
        link: ROUTE_NAMES.TRACKS_DEVELOPMENT_VIEW,
        permissions: TRACK_DEVELOPMENT_PERMISSIONS,
    },
    [ProductType.Activity]: {
        name: 'activity',
        label: LOCAL.LABELS.EVENT_M,
        children: PRODUCT_FORM_ITEM_CHILDREN_BY_TYPE[ProductType.Activity],
        link: ROUTE_NAMES.EVENTS_VIEW,
        permissions: EVENTS_REQUIRED_PERMISSIONS,
    },
    [ProductType.Content]: {
        name: 'course',
        label: LOCAL.LABELS.CONTENT,
        children: PRODUCT_FORM_ITEM_CHILDREN_BY_TYPE[ProductType.Content],
        link: ROUTE_NAMES.TRAINING_MATERIALS,
        permissions: TRAINING_MATERIALS_PERMISSIONS,
    },
    [ProductType.Book]: {
        name: 'book',
        label: LOCAL.LABELS.BOOK,
        children: PRODUCT_FORM_ITEM_CHILDREN_BY_TYPE[ProductType.Book],
        link: '',
        permissions: [],
    },
    [ProductType.Podcast]: {
        name: 'podcast',
        label: LOCAL.LABELS.PODCAST,
        children: PRODUCT_FORM_ITEM_CHILDREN_BY_TYPE[ProductType.Podcast],
        link: '',
        permissions: [],
    },
}
