import React from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, TooltipAdapter } from 'components/shared'
import { COL_lg12_xs24, ROW_GUTTER } from 'consts'
import {
    CheckboxControl,
    ControlGroup,
    CoworkersFilters,
    FormItemAdapter,
    HiddenField,
    InputControl,
    SearchSelectControl,
    SelectControl,
    SelectWithAddButtonControl,
    ShouldUpdateChecker,
    TextAreaControl,
    getCoworkersTableColumns,
    getUserByIdsMethod,
} from 'components/controls'
import { Col, Row } from 'antd'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import {
    SubordinationsOtherService,
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import { isFormModeView } from 'utils'

import styles from './JobProfileFormFields.module.scss'
import { AdditionalRequirementsGroup } from '../AdditionalRequirementsGroup'
import { EducationGroup } from '../EducationGroup'
import { ExperienceGroup } from '../ExperienceGroup'
import { FunctionClassesGroup } from '../FunctionClassesGroup'
import { FunctionalResponsibilitiesGroup } from '../FunctionalResponsibilitiesGroup'
import { JobProfileCompetencies } from '../JobProfileCompetencies'
import { JobProfileFormFieldsProps } from './JobProfileFormFields.types'
import { MIN_SUBORDINATION_COUNT } from './JobProfileFormFields.consts'
import { ManageExperienceGroup } from '../ManageExperienceGroup'
import { OtherSubordinationForm } from './OtherSubordinationForm'
import { ProfileTypeControl } from '../ProfileTypeControl'
import { RequestGroup } from '../RequestGroup'
import { StaffUnitModal } from '../StaffUnitModal'

export const JobProfileFormFields: React.FC<JobProfileFormFieldsProps> = React.memo(
    ({
        formMode,
        dictionaries,
        initialValuesForEdit,
        onShowStaffUnitModal,
        elementVisible,
        handleHideElement,
        handleStuffUnitChange,
        staffModalInitialValues,
    }) => (
        <Row gutter={ROW_GUTTER}>
            <Col xs={24} xl={16}>
                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_lg12_xs24}>
                        <HiddenField fieldName="importId" />

                        <TooltipAdapter
                            title={LOCAL.TOOLTIPS.NAME_OF_NEW_JOB_PROFILE}
                        >
                            <FormItemAdapter
                                fieldName="name"
                                label={LOCAL.LABELS.PROFILE_NAME}
                                required
                            >
                                <InputControl formMode={formMode} />
                            </FormItemAdapter>
                        </TooltipAdapter>

                        <TooltipAdapter
                            title={
                                LOCAL.TOOLTIPS.TITLE_OF_POSITION_OF_JOB_PROFILE
                            }
                        >
                            <FormItemAdapter
                                fieldName="positionId"
                                label={LOCAL.LABELS.POSITION_NAME}
                                required
                            >
                                <SelectControl
                                    values={dictionaries?.positions}
                                    formMode={formMode}
                                    showSearch
                                />
                            </FormItemAdapter>
                        </TooltipAdapter>

                        <TooltipAdapter
                            title={LOCAL.TOOLTIPS.COMPLEX_OF_JOB_PROFILE}
                        >
                            <FormItemAdapter
                                fieldName="supervisingComplexId"
                                label={LOCAL.LABELS.COMPLEX}
                                required
                            >
                                <SelectControl
                                    values={dictionaries?.supervisingComplexes}
                                    formMode={formMode}
                                    showSearch
                                />
                            </FormItemAdapter>
                        </TooltipAdapter>

                        <TooltipAdapter
                            title={LOCAL.TOOLTIPS.AUTHORITY_OF_JOB_PROFILE}
                        >
                            <FormItemAdapter
                                fieldName="oivId"
                                label={LOCAL.LABELS.OIV}
                                required
                            >
                                <SelectControl
                                    values={dictionaries?.oiv}
                                    formMode={formMode}
                                    showSearch
                                />
                            </FormItemAdapter>
                        </TooltipAdapter>

                        <TooltipAdapter
                            title={LOCAL.TOOLTIPS.ORGANIZATION_OF_JOB_PROFILE}
                        >
                            <FormItemAdapter
                                fieldName="organization"
                                label={LOCAL.LABELS.ORGANIZATION}
                            >
                                <SelectControl
                                    values={dictionaries?.organization}
                                    formMode={formMode}
                                    showSearch
                                />
                            </FormItemAdapter>
                        </TooltipAdapter>

                        <TooltipAdapter
                            title={
                                LOCAL.TOOLTIPS.STRUCTURAL_UNIT_OF_JOB_PROFILE
                            }
                        >
                            <FormItemAdapter
                                fieldName="structuralUnitId"
                                label={LOCAL.LABELS.STRUCTURAL_UNIT}
                            >
                                <SelectControl
                                    values={dictionaries?.structuralUnits}
                                    formMode={formMode}
                                    showSearch
                                />
                            </FormItemAdapter>
                        </TooltipAdapter>

                        <Row gutter={ROW_GUTTER} align="middle">
                            <Col xs={18}>
                                <FormItemAdapter
                                    fieldName="staffUnitId"
                                    label={LOCAL.LABELS.STAFF_UNIT}
                                >
                                    <SelectControl
                                        className={styles.staffUnit}
                                        disabled
                                        values={dictionaries?.staffUnits}
                                    />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={6}>
                                <ButtonsToolbar bottomIndent="0">
                                    <Button
                                        onClick={onShowStaffUnitModal}
                                        className={styles.button}
                                        disabled={isFormModeView(formMode)}
                                    >
                                        {LOCAL.ACTIONS.SELECT}
                                    </Button>
                                </ButtonsToolbar>
                            </Col>
                        </Row>

                        {elementVisible && (
                            <StaffUnitModal
                                formMode={formMode}
                                isOpen={elementVisible}
                                handleClose={handleHideElement}
                                handleStuffUnitChange={handleStuffUnitChange}
                                initialValuesForAdd={staffModalInitialValues}
                            />
                        )}
                    </Col>

                    <Col {...COL_lg12_xs24}>
                        <Row
                            gutter={ROW_GUTTER}
                            justify="space-between"
                            align="bottom"
                        >
                            <Col>
                                <FormItemAdapter
                                    fieldName="type"
                                    label={LOCAL.LABELS.PROFILE}
                                >
                                    <ProfileTypeControl formMode={formMode} />
                                </FormItemAdapter>
                            </Col>

                            {initialValuesForEdit?.id && (
                                <Col className={styles.id}>
                                    <span className={styles.label}>
                                        {'ID:'}
                                    </span>

                                    <span className={styles.value}>
                                        {initialValuesForEdit.id}
                                    </span>
                                </Col>
                            )}
                        </Row>

                        <FormItemAdapter
                            fieldName="chiefId"
                            label={LOCAL.LABELS.RESPONSIBLE_MANAGER}
                            required
                        >
                            <SearchSelectControl<
                                UserModalSearchContract,
                                UserModalContract
                            >
                                getByIdsMethod={getUserByIdsMethod}
                                formMode={formMode}
                                modalTitle={LOCAL.LABELS.MANAGER_SELECTION}
                                tableSearchOptions={{
                                    tableColumns: getCoworkersTableColumns(),
                                    filterComponent: CoworkersFilters,
                                    searchMethod: UsersService.getForModal,
                                }}
                            />
                        </FormItemAdapter>

                        <TooltipAdapter
                            title={LOCAL.TOOLTIPS.SUBORDINATIONS_OF_JOB_PROFILE}
                        >
                            <FormItemAdapter
                                fieldName="subordinationId"
                                label={LOCAL.LABELS.TO_WHOM_IT_REPORTS}
                                required
                            >
                                <SelectControl
                                    values={dictionaries?.subordinations}
                                    formMode={formMode}
                                />
                            </FormItemAdapter>
                        </TooltipAdapter>

                        <ShouldUpdateChecker fieldPath={['subordinationId']}>
                            {({ getFieldValue }) =>
                                getFieldValue('subordinationId') ===
                                    MIN_SUBORDINATION_COUNT && (
                                    <FormItemAdapter
                                        fieldName="subordinationOtherId"
                                        label={LOCAL.LABELS.OTHER}
                                    >
                                        <SelectWithAddButtonControl
                                            formComponent={
                                                OtherSubordinationForm
                                            }
                                            formId={
                                                FORM_IDS.JOB_PROFILE_OTHER_SUBORDINATION_FORM
                                            }
                                            formMode={formMode}
                                            request={
                                                SubordinationsOtherService.getAll
                                            }
                                        />
                                    </FormItemAdapter>
                                )
                            }
                        </ShouldUpdateChecker>

                        <Row gutter={ROW_GUTTER}>
                            <Col xs={24} sm={12}>
                                <TooltipAdapter
                                    title={
                                        LOCAL.TOOLTIPS
                                            .POSITION_LEVEL_OF_JOB_PROFILE
                                    }
                                >
                                    <FormItemAdapter
                                        fieldName="levelId"
                                        label={LOCAL.LABELS.POSITION_LEVEL}
                                        required
                                    >
                                        <SelectControl
                                            values={dictionaries?.levels}
                                            formMode={formMode}
                                        />
                                    </FormItemAdapter>
                                </TooltipAdapter>

                                <FormItemAdapter
                                    fieldName="dismissalRiskId"
                                    label={LOCAL.LABELS.RISK_DISMISSAL}
                                    required
                                >
                                    <SelectControl
                                        values={dictionaries?.dismissalRisks}
                                        formMode={formMode}
                                    />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={24} sm={12}>
                                <FormItemAdapter
                                    fieldName="corruptionRiskId"
                                    label={LOCAL.LABELS.CORRUPTION_RISK}
                                    required
                                >
                                    <SelectControl
                                        values={dictionaries?.corruptionRisks}
                                        formMode={formMode}
                                    />
                                </FormItemAdapter>

                                <FormItemAdapter
                                    fieldName="accessFormId"
                                    label={LOCAL.LABELS.ADMISSION_FORM}
                                    required
                                >
                                    <SelectControl
                                        values={dictionaries?.accessForms}
                                        formMode={formMode}
                                    />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={24}>
                                <ShouldUpdateChecker
                                    fieldPath={['showPerfomanceIndicators']}
                                >
                                    {({ getFieldValue }) => {
                                        const showPerfomanceIndicators = getFieldValue(
                                            ['showPerfomanceIndicators']
                                        )

                                        return (
                                            <>
                                                <FormItemAdapter
                                                    fieldName="showPerfomanceIndicators"
                                                    className={
                                                        styles.showPerfomanceIndicators
                                                    }
                                                >
                                                    <CheckboxControl
                                                        formMode={formMode}
                                                    >
                                                        {showPerfomanceIndicators
                                                            ? LOCAL.LABELS
                                                                  .POST_PERFORMANCE_INDICATORS
                                                            : LOCAL.LABELS
                                                                  .SHOW_POST_PERFORMANCE_INDICATORS}
                                                    </CheckboxControl>
                                                </FormItemAdapter>

                                                {showPerfomanceIndicators && (
                                                    <TooltipAdapter
                                                        title={
                                                            LOCAL.TOOLTIPS
                                                                .PERFORMANCE_INDICATORS_DESCRIPTION
                                                        }
                                                    >
                                                        <FormItemAdapter fieldName="performanceIndicatorsDescription">
                                                            <TextAreaControl
                                                                autoSize={{
                                                                    minRows: 2,
                                                                }}
                                                                formMode={
                                                                    formMode
                                                                }
                                                            />
                                                        </FormItemAdapter>
                                                    </TooltipAdapter>
                                                )}
                                            </>
                                        )
                                    }}
                                </ShouldUpdateChecker>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24}>
                        <ControlGroup noPadding>
                            <JobProfileCompetencies
                                formMode={formMode}
                                initialValuesForEdit={initialValuesForEdit}
                            />
                        </ControlGroup>
                    </Col>
                </Row>
            </Col>

            <Col xs={24} xl={8}>
                <FunctionClassesGroup
                    formMode={formMode}
                    treeData={dictionaries?.functionClasses || []}
                />

                <FunctionalResponsibilitiesGroup formMode={formMode} />

                <ExperienceGroup
                    industries={dictionaries?.industries || []}
                    experiences={dictionaries?.experiences || []}
                    governmentExperiences={
                        dictionaries?.governmentExperiences || []
                    }
                    formMode={formMode}
                />

                <ManageExperienceGroup
                    industries={dictionaries?.industries || []}
                    manageExperiences={dictionaries?.manageExperiences || []}
                    subordinatesNumbers={
                        dictionaries?.subordinatesNumbers || []
                    }
                    formMode={formMode}
                />

                <EducationGroup
                    educationLevels={dictionaries?.educationLevels || []}
                    educationProfiles={dictionaries?.educationProfiles || []}
                    formMode={formMode}
                />

                <AdditionalRequirementsGroup formMode={formMode} />

                <RequestGroup formMode={formMode} />
            </Col>
        </Row>
    )
)
