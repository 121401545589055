import React from 'react'
import { ControlGroup } from 'components/controls'
import { safelySetInnerHtml } from 'utils'

import styles from './ArticleView.module.scss'
import { ArticleViewProps } from './ArticleView.types'

/** Содержание статьи */
export const ArticleView: React.FC<ArticleViewProps> = React.memo(
    ({ article }) => (
        <>
            <h2 className={styles.name}>{article?.name}</h2>

            <ControlGroup type="bordered" className={styles.chapters}>
                {article?.chapters.map((el) => (
                    <div key={el.id} id={`${el.id}`}>
                        <h2>{el.title}</h2>

                        <div
                            dangerouslySetInnerHTML={safelySetInnerHtml(
                                el.content
                            )}
                        />
                    </div>
                ))}
            </ControlGroup>
        </>
    )
)
