import cn from 'classnames'
import React, { useCallback, useContext, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, ContentTitle } from 'components/shared'
import { Col, Modal, Row } from 'antd'
import { EventPassingContext } from 'components/pages/Events/EventPassing/EventPassing.context'
import { HomeworksService } from 'core/api'
import { LOCAL } from 'core/local'
import { MODAL_Z_INDEX } from 'consts'
import { TrackPassingContext } from 'components/pages/TrackPreviewPassing/TrackPassing.context'
import {
    isHomeworkWaitingForSolution,
    isThematicBlockStatusCompleted,
    isThemeBlockTypeHomework,
    isTrainingMaterialFormatMedia,
} from 'utils'

import styles from './ThematicBlockHeader.module.scss'
import { ThematicBlockLayerProps } from '../../ThematicBlockLayer.types'
import { renderStatus } from './ThematicBlockHeader.utils'

/**
 * Шапка тематического блока, входящего в состав мероприятия
 */
export const ThematicBlockHeader: React.FC<ThematicBlockLayerProps> = React.memo(
    ({ thematicBlock, activity, onFinishThematicBlock, onViewMaterial }) => {
        const { store: trackStore } = useContext(TrackPassingContext)
        const { store: eventStore } = useContext(EventPassingContext)
        const updateLoader = trackStore.updateLoader || eventStore.updateLoader

        const isThematicBlockCompleted = isThematicBlockStatusCompleted(
            thematicBlock.status
        )
        const isHomeworkType = isThemeBlockTypeHomework(thematicBlock.type)

        /**
         * Отправить задание на проверку
         */
        const handleSendToCheck = useCallback(async () => {
            try {
                updateLoader(true)

                await HomeworksService.sendToCheck({
                    id: thematicBlock.homework?.id,
                    isHomeworkAutoCheck: activity?.isHomeworkAutoCheck,
                })

                activity?.isHomeworkAutoCheck
                    ? onFinishThematicBlock()
                    : Modal.success({
                          icon: '',
                          content: LOCAL.MESSAGES.HOMEWORK_ON_CHECK,
                          onOk: onFinishThematicBlock,
                          okText: LOCAL.ACTIONS.CLOSE,
                          zIndex: MODAL_Z_INDEX.DEFAULT,
                      })
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [
            updateLoader,
            thematicBlock.homework,
            activity,
            onFinishThematicBlock,
        ])

        const submitButtonProps = useMemo(
            () =>
                isHomeworkType
                    ? {
                          onClick: handleSendToCheck,
                          disabled: !isHomeworkWaitingForSolution(
                              thematicBlock.homework?.status
                          ),
                      }
                    : {
                          onClick: onViewMaterial,
                      },
            [
                onViewMaterial,
                handleSendToCheck,
                isHomeworkType,
                thematicBlock.homework,
            ]
        )

        return (
            <div className={cn(styles.wrapper)}>
                {!isTrainingMaterialFormatMedia(
                    thematicBlock.course?.format
                ) && <ContentTitle title={activity.name} />}

                <Row
                    justify="space-between"
                    align="middle"
                    className={styles.actions}
                >
                    <Col className={styles.name}>{thematicBlock.name}</Col>

                    <Col>
                        <ButtonsToolbar className={styles.buttonsToolbar}>
                            {isHomeworkType && renderStatus(thematicBlock)}

                            <Button
                                type="primary"
                                onClick={onFinishThematicBlock}
                            >
                                {LOCAL.ACTIONS.RETURN}
                            </Button>

                            {!isThematicBlockCompleted && (
                                <Button type="primary" {...submitButtonProps}>
                                    {isHomeworkType
                                        ? LOCAL.LABELS.ON_CHECK
                                        : LOCAL.LABELS.FURTHER}
                                </Button>
                            )}
                        </ButtonsToolbar>
                    </Col>
                </Row>
            </div>
        )
    }
)
