import cn from 'classnames'
import React, { useCallback } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { isFormModeView } from 'utils'

import styles from './CloseButton.module.scss'
import { CloseButtonProps } from './CloseButton.types'

/** Кнопка закрытия */
export const CloseButton: React.FC<CloseButtonProps> = React.memo(
    ({ onClose, className, formMode, disabled }) => {
        const isDisabled = disabled || isFormModeView(formMode)

        /** Обработчик нажатия на кнопку */
        const handleClose = useCallback(() => {
            if (isDisabled) return

            onClose()
        }, [isDisabled, onClose])

        return (
            <IconsAdapter
                iconType="CloseOutlined"
                onClick={handleClose}
                className={cn(styles.wrapper, [className])}
                disabled={isDisabled}
            />
        )
    }
)
