import React, { FC, useCallback } from 'react'
import {
    AppError,
    TrackCanvasTemplateCreateContract,
    TrackCanvasTemplateService,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { FormItemAdapter, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { mapFormDataToRequest } from 'components/pages/Track/components/TrackForm/TrackForm.utils'
import { withLoader } from 'HOCs'

/**
 * Форма сохранения шаблона визуализации трека
 */
export const SaveStageVisualisationTemplateForm: FC<PopupAdapterFormProps> = withLoader(
    ({
        onRequestFinish,
        deleteFormAction,
        initialValues,
        recordCopy,
        rowSelectionType,
        onCancelSubmit,
        updateLoader,
        ...props
    }) => {
        /**
         * Обработчик отправки формы
         */
        const handleFinish = useCallback(
            async (values: Partial<TrackCanvasTemplateCreateContract>) => {
                try {
                    updateLoader(true)

                    if (!initialValues) return

                    const { name, ...initialValuesWithoutName } = initialValues

                    const body = {
                        ...mapFormDataToRequest(initialValuesWithoutName),
                        ...values,
                    } as TrackCanvasTemplateCreateContract

                    const response = await TrackCanvasTemplateService.create({
                        body,
                    })

                    onRequestFinish?.(response)
                } catch (error) {
                    console.error(error as AppError)
                } finally {
                    updateLoader(false)
                }
            },
            [initialValues, onRequestFinish, updateLoader]
        )

        return (
            <>
                <Form onFinish={handleFinish} {...props}>
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.TEMPLATE_NAME}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Form>

                <ButtonsToolbar>
                    <Button
                        form={FORM_IDS.SAVE_STAGE_VISUALISATION_TEMPLATE_FORM}
                        type="primary"
                        htmlType="submit"
                    >
                        {LOCAL.ACTIONS.SAVE}
                    </Button>
                </ButtonsToolbar>
            </>
        )
    }
)
