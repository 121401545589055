import React, { FC, useCallback, useEffect, useReducer, useState } from 'react'
import { CKEditorAdapter } from 'components/shared'
import { CKEditorEventAction, stripPrefix } from 'ckeditor4-react'
import { Col, Form, Row } from 'antd'
import { DEFAULT_STYLES } from 'components/shared/CKEditorAdapter/CKEditorAdapter.config'
import {
    DynamicMarkerControl,
    FormItemAdapter,
    InputControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import {
    EDITOR_TEMPLATE_EXTRA_PLUGINS_LIST,
    EDITOR_TEMPLATE_TOOLBAR_OPTIONS,
} from 'consts'
import { FocusedElementProps } from 'App.types'
import { FormInstance } from 'rc-field-form/es/interface'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import {
    NotificationDestinationCategoriesService,
    NotificationDestinationCategoryContract,
} from 'core/api'
import { ROW_GUTTER } from 'consts/layout.consts'
import { addMarker, normalizeDataForSelectAndRadio } from 'utils'
import { editorReducer } from 'components/shared/CKEditorAdapter/CKEditorAdapter.utils'
import { isFormModeView } from 'utils/conditions.utils'
import { useHttp } from 'hooks'

import { DistributionTemplateMailProps } from './DistributionTemplateMail.types'
import { MAX_LENGTH_SUBJECT_FIELD } from './DistributionTemplateMail.consts'

/** Часть формы для создания шаблона письма */
export const DistributionTemplateMail: FC<DistributionTemplateMailProps> = React.memo(
    ({ form, formMode, initialValuesForEdit }) => {
        const [state, dispatch] = useReducer(editorReducer, {
            styles: DEFAULT_STYLES,
        })

        const [newNotificationBody, setNewNotificationBody] = useState('')

        const [focusedField, setFocusedField] = useState<FocusedElementProps>()

        const [fetchCategories, categories] = useHttp<
            NotificationDestinationCategoryContract[]
        >(NotificationDestinationCategoriesService.getForSelect)

        const { notificationBody } = initialValuesForEdit || {}

        const handleValidateEditor = useCallback(
            (getFieldError: FormInstance['getFieldError']) => (
                value?: string
            ) => !!getFieldError('notificationBody').length && !value,
            []
        )

        useEffect(() => {
            fetchCategories()
        }, [fetchCategories])

        useEffect(() => () => state?.editor?.destroy(), [state])

        useEffect(() => {
            if (notificationBody && state.editor) {
                setNewNotificationBody(notificationBody)
            }
        }, [notificationBody, state.editor])

        const handleAddMarker = useCallback(
            (marker: LabeledValue) => {
                if (focusedField) {
                    addMarker(form, focusedField)(marker)

                    return
                }

                dispatch({
                    type: stripPrefix(CKEditorEventAction.insertHtml),
                    payload: { html: `{${marker.label}}` },
                })
            },
            [focusedField, form]
        )

        return (
            <Row gutter={ROW_GUTTER}>
                <Col xs={16}>
                    <FormItemAdapter
                        fieldName="notificationTheme"
                        label={LOCAL.LABELS.TOPIC_MESSAGE}
                        rules={[
                            {
                                required: true,
                                message: LOCAL.ERRORS.EMPTY_MAIL_SUBJECT,
                            },
                            {
                                max: MAX_LENGTH_SUBJECT_FIELD,
                                message: LOCAL.ERRORS.MAX_LENGTH_MAIL_SUBJECT,
                            },
                        ]}
                    >
                        <InputControl
                            formMode={formMode}
                            onFocus={({ currentTarget }) =>
                                setFocusedField({
                                    name: 'notificationTheme',
                                    currentTarget,
                                })
                            }
                        />
                    </FormItemAdapter>

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldError }) => (
                            <FormItemAdapter
                                fieldName="notificationBody"
                                label={LOCAL.LABELS.MESSAGE_BODY}
                                rules={[
                                    {
                                        required: true,
                                        message: LOCAL.ERRORS.EMPTY_MAIL_BODY,
                                    },
                                ]}
                            >
                                <CKEditorAdapter
                                    dispatchEvent={dispatch}
                                    extraPlugins={
                                        EDITOR_TEMPLATE_EXTRA_PLUGINS_LIST
                                    }
                                    toolbar={EDITOR_TEMPLATE_TOOLBAR_OPTIONS}
                                    onFocus={() => setFocusedField(undefined)}
                                    newValue={newNotificationBody}
                                    readOnly={isFormModeView(formMode)}
                                    validateCallback={handleValidateEditor(
                                        getFieldError
                                    )}
                                />
                            </FormItemAdapter>
                        )}
                    </Form.Item>
                </Col>

                {!isFormModeView(formMode) && (
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="categoryId"
                            label={LOCAL.LABELS.TEMPLATE_CATEGORY_TYPE}
                        >
                            <SelectControl
                                formMode={formMode}
                                showSearch
                                allowClear
                                values={categories?.map(
                                    normalizeDataForSelectAndRadio
                                )}
                            />
                        </FormItemAdapter>

                        <ShouldUpdateChecker fieldPath={['categoryId']}>
                            {({ getFieldValue }) => (
                                <DynamicMarkerControl
                                    form={form}
                                    onInsertMarker={handleAddMarker}
                                    categoryId={getFieldValue('categoryId')}
                                />
                            )}
                        </ShouldUpdateChecker>
                    </Col>
                )}
            </Row>
        )
    }
)
