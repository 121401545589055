import React from 'react'
import { AddonAfterDefault } from 'components/shared'
import {
    FormItemAdapter,
    QUESTIONNAIRES_TABLE_COLUMNS,
    SearchSelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { QUESTIONNAIRES_REQUIRED_PERMISSIONS } from 'consts'
import {
    QuestionnaireContract,
    QuestionnaireForModalContract,
    QuestionnaireSearchContract,
    QuestionnairesService,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ThemeBlockQuestionnairesFilters } from 'components/controls/SearchSelectControl/components/filters/ThemeBlockQuestionnairesFilters'

import { QUESTIONNAIRES_SEARCH_METHOD_BODY } from './QuestionnaireFields.consts'
import { QuestionanireFieldsProps } from './QuestionnaireFields.types'

export const QuestionnaireFields: React.FC<QuestionanireFieldsProps> = React.memo(
    ({ field, formMode, disabled }) => (
        <ShouldUpdateChecker
            fieldPath={[['lessons', field.name, 'questionnaires']]}
        >
            {({ getFieldValue }) => {
                const questionnaireId = getFieldValue([
                    'lessons',
                    field.name,
                    'questionnaires',
                ])?.[0].value

                return (
                    <FormItemAdapter
                        label={LOCAL.ACTIONS.ADD_QUIZ_TEST}
                        fieldName={[field.name, 'questionnaires']}
                        required
                    >
                        <SearchSelectControl<
                            QuestionnaireSearchContract,
                            | QuestionnaireContract
                            | QuestionnaireForModalContract
                        >
                            getByIdsMethod={QuestionnairesService.getByIds}
                            modalTitle={LOCAL.LABELS.SELECTION_QUIZ_TEST}
                            formMode={formMode}
                            addonAfter={
                                <AddonAfterDefault
                                    url={`${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW}/${questionnaireId}`}
                                    disabled={!questionnaireId}
                                    permissionsRequired={
                                        QUESTIONNAIRES_REQUIRED_PERMISSIONS
                                    }
                                />
                            }
                            tableSearchOptions={{
                                searchMethod: QuestionnairesService.getForModal,
                                tableFiltersOptions: {
                                    initialValues: QUESTIONNAIRES_SEARCH_METHOD_BODY,
                                },
                                filterComponent: ThemeBlockQuestionnairesFilters,
                                tableColumns: QUESTIONNAIRES_TABLE_COLUMNS,
                            }}
                            disabled={disabled}
                        />
                    </FormItemAdapter>
                )
            }}
        </ShouldUpdateChecker>
    )
)
