import cn from 'classnames'
import React, { ReactText, useMemo } from 'react'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'

import styles from './ControlViewMode.module.scss'
import { ControlViewModeProps } from './ControlViewMode.types'

/**
 * Компонет предназначен для использования внутри других контролов, для отображения поля формы в режиме просмотра
 */
export const ControlViewMode: React.FC<ControlViewModeProps> = React.memo(
    ({ value, addonAfter, addonBefore, className }) => {
        const classes = cn(
            Boolean(addonAfter) && styles.wrapperAddonAfter,
            Boolean(addonBefore) && styles.wrapperAddonBefore,
            styles.wrapper,
            className
        )

        const mappedValue = useMemo(() => {
            if (!value) return value

            if (Array.isArray(value)) {
                const mapped = (value as Array<
                    ReactText | LabeledValue
                >).map((el) => (typeof el === 'object' ? String(el.label) : el))

                return mapped.join(', ')
            }

            if (typeof value === 'object') return String(value.label)

            return value
        }, [value])

        return (
            <div className={classes}>
                {addonBefore && (
                    <div className={styles.addonBeforeContent}>
                        {addonBefore}
                    </div>
                )}

                {mappedValue || LOCAL.PLACEHOLDERS.INPUT_EMPTY}

                {addonAfter && (
                    <div className={styles.addonAfterContent}>{addonAfter}</div>
                )}
            </div>
        )
    }
)
