import cn from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { Form, Radio } from 'antd'
import {
    InputNumberEditControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { getControlClass, isFormModeView } from 'utils'

import styles from './CalcForAnswer.module.scss'
import { CalcForAnswerProps } from './CalcForAnswer.types'

/**
 * Поля: Считать баллы за каждый ответ
 */
export const CalcForAnswer: FC<CalcForAnswerProps> = React.memo(
    ({ onChange, value, disabled }) => {
        const [calcForAnswer, setCalcForAnswer] = useState(
            value?.calcForAnswer ?? false
        )
        const [questionScore, setQuestionScore] = useState<number>()

        /**
         * Инициировать обновление состояние компонента, а также значения внешней формы
         */
        const triggerChanges = useCallback(
            (changedValue) => {
                if (onChange)
                    onChange({
                        calcForAnswer,
                        questionScore,
                        ...value,
                        ...changedValue,
                    })
            },
            [calcForAnswer, onChange, questionScore, value]
        )

        /**
         * Обработчик изменения поля "calcForAnswer"
         */
        const handleCalcForAnswerChange = useCallback(() => {
            setCalcForAnswer(!calcForAnswer)
            triggerChanges({ calcForAnswer: !calcForAnswer })
        }, [calcForAnswer, triggerChanges])

        /**
         * Обработчик изменения поля "questionScore"
         */
        const handleQuestionScoreChange = useCallback(
            (value?: number) => {
                setQuestionScore(value)
                triggerChanges({ questionScore: value })
            },
            [triggerChanges]
        )

        return (
            <ShouldUpdateChecker fieldPath="formMode">
                {({ getFieldValue }) => {
                    const formMode = getFieldValue('formMode')

                    return (
                        <div>
                            <Form.Item
                                className={cn(styles.task, styles.radioBtn)}
                            >
                                <Radio
                                    checked={!calcForAnswer}
                                    onChange={handleCalcForAnswerChange}
                                    disabled={
                                        isFormModeView(formMode) || disabled
                                    }
                                    className={getControlClass({
                                        formMode,
                                    })}
                                >
                                    <div
                                        className={cn({
                                            'required-control':
                                                !calcForAnswer && !disabled,
                                        })}
                                    >
                                        {LOCAL.LABELS.POINTS_FOR_ANSWER}
                                    </div>
                                </Radio>

                                {!calcForAnswer && (
                                    <InputNumberEditControl
                                        initialValue={
                                            value?.questionScore ||
                                            questionScore
                                        }
                                        onChange={handleQuestionScoreChange}
                                        disabled={
                                            isFormModeView(formMode) || disabled
                                        }
                                        min={0}
                                    />
                                )}
                            </Form.Item>

                            <Form.Item className={styles.radioBtn}>
                                <Radio
                                    checked={calcForAnswer}
                                    onChange={handleCalcForAnswerChange}
                                    disabled={
                                        isFormModeView(formMode) || disabled
                                    }
                                    className={getControlClass({
                                        formMode,
                                    })}
                                >
                                    <div
                                        className={cn({
                                            'required-control':
                                                calcForAnswer && !disabled,
                                        })}
                                    >
                                        {
                                            LOCAL.LABELS
                                                .POINTS_FOR_EACH_CORRECT_ANSWER
                                        }
                                    </div>
                                </Radio>
                            </Form.Item>
                        </div>
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
