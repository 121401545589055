import {
    ActivityContract,
    ActivityPublishContract,
    DocumentStatus,
    FaceToFaceSchedule,
    LessonCreateContract,
    WebConferenceSchedule,
} from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { FormProps } from 'components/forms/forms.types'
import { LabeledValue } from 'antd/lib/select'
import { UploadFile } from 'antd/lib/upload/interface'
import { WithLoaderProps } from 'HOCs'

export interface LessonValueFormProps
    extends Omit<
        LessonCreateContract,
        | 'tutorIds'
        | 'questionnaireIds'
        | 'schedule'
        | 'courseId'
        | 'textDocumentId'
    > {
    nestedActivity?: LabeledValue[]
    textDocuments?: LabeledValue[]
    tutors?: LabeledValue[]
    questionnaires?: LabeledValue[]
    course?: LabeledValue[]
    faceToFaceMeeting: FaceToFaceSchedule
    webConference: WebConferenceSchedule
    missions?: LabeledValue[]
}

export enum EventFormTabType {
    MainOptions = 'MainOptions',
    Structure = 'Structure',
    EventVisualization = 'EventVisualization',
}

export interface MapFormDataToRequestProps
    extends Omit<
        ActivityPublishContract,
        'competencesIds' | 'activityPictureId' | 'lessons' | 'canvas'
    > {
    canvas: ActivityPublishContract['canvas'] & { canvasBackgroundUrl: string }
    documentStatus?: DocumentStatus
    competencesIds?: LabeledValue[]
    activityPictureId?: UploadFile[]
    lessons?: LessonValueFormProps[]
}

export interface EventFormProps
    extends FormProps<ActivityContract>,
        ComponentCommonProps,
        Partial<WithLoaderProps> {}
