import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AddButton } from 'components/shared/AddButton/AddButton'
import {
    DictionaryContract,
    PositionSearchContract,
    PositionsService,
} from 'core/api'
import { FORM_IDS } from 'core/configs'
import { Form, Select } from 'antd'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { isEmpty } from 'lodash'
import { isFormModeView, normalizeDataForSelectAndRadio } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './AddPositionControl.module.scss'
import { AddPositionControlProps } from './AddPositionControl.types'
import { POSITIONS_TABLE_COLUMNS } from './AddPositionControl.consts'
import { PositionsFilters } from './components/PositionsFilters/PositionsFilters'
import { SearchTableShowButton } from '../SearchSelectControl/components/SearchTableShowButton'
import { ShouldUpdateChecker } from '../ShouldUpdateChecker'
import { SwitchControl } from '../SwitchControl'
import { isRowSelectionTypeCheckbox } from '../SearchSelectControl'

/**
 * Поле - "Должности". Компонент предназначен для добавления выбранных должностей в форму.
 * Выбор должностей осуществляется в модальном окне, есть возможность поиска по наименованию.
 */
export const AddPositionControl: React.FC<AddPositionControlProps> = ({
    className,
    value,
    onChange,
    formMode,
    disabled,
    title,
    form,
    onBeforeChange,
    withSwitch,
    rowSelectionType = 'checkbox',
}) => {
    const {
        updateElementVisible: updateLoader,
        elementVisible: isLoading,
    } = useElementVisible()
    const [selectedRows, setSelectedRows] = useState<LabeledValue[]>()

    /**
     * Обновить значение во внутреннем стейте компонента и внешней форме
     * @param changedValue новое значение для поля
     */
    const triggerChange = useCallback(
        (changedValue?: LabeledValue[]) => {
            setSelectedRows(changedValue)
            onChange?.(changedValue)
        },
        [onChange]
    )

    /** Запрос данных по ids (выбранные ряды таблицы) для селекта */
    const fetchEntitiesBySelectedRowsIds = useCallback(
        async (ids?: number[]) => {
            try {
                updateLoader(true)

                const response = await PositionsService.getByIds({
                    body: ids,
                })

                triggerChange(response.map(normalizeDataForSelectAndRadio))
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        [updateLoader, triggerChange]
    )

    /** Обработчик выбора ряда, в таблице в модальном окне */
    const handleSelectTableRows = useCallback(
        (result?: number[]) => {
            if (result?.length) {
                fetchEntitiesBySelectedRowsIds(result)
            }
        },
        [fetchEntitiesBySelectedRowsIds]
    )

    /** Обработчик отмены выбора ряда (при нажатии на крестик тега, либо кнопку "очистить все") */
    const handleDeselectRows = useCallback(
        (result: number[]) => {
            const newValue = selectedRows?.filter((row) =>
                result?.includes(Number(row.value))
            )

            triggerChange(newValue?.length ? newValue : undefined)
        },
        [selectedRows, triggerChange]
    )

    /** ids выбранных рядов, для value в Select */
    const selectedRowsIds = useMemo(
        () => (value || selectedRows)?.map((el) => Number(el.value)),
        [selectedRows, value]
    )

    const showAddButton = isRowSelectionTypeCheckbox(rowSelectionType) || !value

    useEffect(() => {
        setSelectedRows(value)
    }, [value])

    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={cn(showAddButton && styles.control)}>
                {title && <span className={styles.label}>{title}</span>}

                {showAddButton && (
                    <ShouldUpdateChecker fieldPath={['forAllPositions']}>
                        {({ getFieldValue }) => {
                            const forAllPositions = getFieldValue(
                                'forAllPositions'
                            )

                            return (
                                <SearchTableShowButton<
                                    PositionSearchContract,
                                    DictionaryContract
                                >
                                    form={form}
                                    onBeforeChange={onBeforeChange}
                                    tableSearchOptions={{
                                        formId: FORM_IDS.SEARCH_POSITION,
                                        rowSelectionType,
                                        searchMethod: PositionsService.search,
                                        tableColumns: POSITIONS_TABLE_COLUMNS,
                                        childrenColumnName: 'units',
                                        filterComponent: PositionsFilters,
                                        onSelectTableItem: handleSelectTableRows,
                                        initialValues: selectedRowsIds,
                                    }}
                                    modalTitle={LOCAL.LABELS.POSITION_SELECTION}
                                >
                                    <AddButton
                                        buttonText={LOCAL.ACTIONS.ADD}
                                        disabled={
                                            isLoading ||
                                            disabled ||
                                            isFormModeView(formMode) ||
                                            forAllPositions
                                        }
                                        loading={isLoading}
                                    />
                                </SearchTableShowButton>
                            )
                        }}
                    </ShouldUpdateChecker>
                )}

                <Form.Item
                    className={styles.switch}
                    name="forAllPositions"
                    hidden={!withSwitch}
                >
                    <SwitchControl
                        addonAfter={LOCAL.LABELS.AVAILABLE_FOR_ALL_POSITIONS}
                        formMode={formMode}
                        disabled={
                            !isEmpty(value || selectedRowsIds) || disabled
                        }
                    />
                </Form.Item>
            </div>

            {!!(value || selectedRows)?.length && (
                <Select
                    className={styles.selected}
                    mode={
                        isRowSelectionTypeCheckbox(rowSelectionType)
                            ? 'multiple'
                            : undefined
                    }
                    allowClear
                    disabled={disabled || isFormModeView(formMode)}
                    showSearch={false}
                    dropdownStyle={{
                        display: 'none',
                    }}
                    value={selectedRowsIds}
                    options={value || selectedRows}
                    onChange={handleDeselectRows}
                    showArrow={false}
                />
            )}
        </div>
    )
}
