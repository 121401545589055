import React from 'react'
import { UserStaffStatusTypeRegistryContract } from 'core/api'

import { getStaffStatusDateRange } from './CoworkersStaffStatusTypeColumn.utils'

export const CoworkersStaffStatusTypeColumn = (
    userStaffStatusType?: UserStaffStatusTypeRegistryContract
) =>
    userStaffStatusType?.name && (
        <div>
            <div>{userStaffStatusType.name}</div>
            <div>{getStaffStatusDateRange(userStaffStatusType)}</div>
        </div>
    )
