import { DataIndex } from 'rc-table/lib/interface.d'
import { Store } from 'App.types'

/**
 * Определить видимость столбца
 * @param columnVisibility - опции видимости столбцов
 * @param columnKey - ключ столбца
 */
export const calculateColumnVisibility = (
    columnVisibility?: Store<boolean>,
    columnKey?: DataIndex
) =>
    columnVisibility?.[
        Array.isArray(columnKey) ? columnKey?.[0] : (columnKey as string)
    ] === false
        ? 'column-hide'
        : ''
