import React from 'react'
import { AdaptationTrackContract, AdaptationTrackSaveContract } from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { withLoader } from 'HOCs'

import Track from './Track'
import { TRACK_COMPONENT_PROPS } from './Track.consts'
import { mapAdaptationTrackResponseToFormData } from './components/TrackForm/TrackForm.utils'

export const TrackAdaptationPreset: React.FC<ComponentCommonProps> = withLoader(
    ({ formMode, updateLoader, isLoading }) => (
        <Track<AdaptationTrackContract, AdaptationTrackSaveContract>
            isLoading={isLoading}
            updateLoader={updateLoader}
            formMode={formMode}
            {...TRACK_COMPONENT_PROPS.ADAPTATION}
            mapResponseToFormData={mapAdaptationTrackResponseToFormData}
        />
    )
)
