import React from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { Radio } from 'antd'
import { getBackgroundImageStyle } from 'utils'

import styles from './CanvasIconRadio.module.scss'
import { CanvasIconRadioProps } from './CanvasIconRadio.types'

/**
 * Компонент типа радио для SingleStageVisualisationOptionTab
 */
export const CanvasIconRadio: React.FC<CanvasIconRadioProps> = React.memo(
    ({ src, id, onRemoveImg }) => (
        <div className={styles.wrapper}>
            <Radio
                value={id}
                className={styles.control}
                style={getBackgroundImageStyle(src)}
            />

            {!!onRemoveImg && (
                <div className={styles.areaRemove}>
                    <IconsAdapter
                        iconType="CustomIconTrashAlt"
                        className={styles.iconRemove}
                        onClick={onRemoveImg}
                    />
                </div>
            )}
        </div>
    )
)
