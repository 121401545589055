import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
    ActivityCanvasDesignType,
    ActivityLessonPassingContract,
    ActivityLessonPassingService,
    HomeworkStatusType,
    HomeworksService,
    TrackEvent,
} from 'core/api'
import { InfoAssistant } from 'components/shared'
import { InformationPassingModal } from 'components/forms/InformationPassingModal'
import { LOCAL } from 'core/local'
import { PassingUnitProps } from 'components/pages/Events/EventPassing/EventPassing.types'
import { TaskPassingForm } from 'components/forms'
import {
    createWarningNotification,
    isThemeBlockTypeActivity,
    isThemeBlockTypeHomeworkWithoutEditor,
    isThemeBlockTypeInformation,
    isThemeBlockTypeQuestionnaire,
    isThemeBlockTypeVideoCourse,
    isThemeBlockTypeWebConferenceOrFaceToFace,
} from 'utils'

import styles from './ActivityLayer.module.scss'
import {
    ActiveBlockStateProps,
    ActivityLayerProps,
    CanvasProps,
} from './ActivityLayer.types'
import { ActivityInfoProps } from '../../TrackPreviewPassing.types'
import { FullScreenCanvas, ListCanvas, ModalCanvas } from './components'
import { InfoAssistantChecker } from '../InfoAssistantChecker'
import { ThematicBlockLayer } from '../ThematicBlockLayer'
import { TrackPassingContext } from '../../TrackPassing.context'
import {
    getInitialThematicBlockForPassing,
    getThematicBLockColor,
} from './ActivityLayer.utils'
import { isAppointmentPassedView } from '../../TrackPreviewPassing.utils'

/**
 * Слой мероприятия, выбранного для определенного этапа трека адаптации
 */
export const ActivityLayer: React.FC<ActivityLayerProps> = React.memo(
    ({
        data,
        trackPassingId,
        updateInfoAboutPassing,
        refetchTrackInfo,
        parentLessonIdForPassing,
        isTrackWithVisualisation,
        isShowInfoAssistant,
        stageInfoAssistantProps,
        onCloseNestedActivity,
        onRefetchParentLessonInfo,
        themeBlockByContentId,
        onThemeBlockByContentId,
        appointmentStatus,
        updateTrackInfo,
        switchViewToNextStage,
        onUpdateStageAssistantVisible,
        updateProgress,
        currentStagesId,
        currentStageNumber,
    }) => {
        const { store } = useContext(TrackPassingContext)
        const [thematicBlockInProgress, setThematicBlockInProgress] = useState<
            ActivityLessonPassingContract
        >()
        const [isThematicBlockStarted, setThematicBlockStarted] = useState(
            false
        )
        const [nestedActivity, setNestedActivity] = useState<PassingUnitProps>()
        const [
            infoAssistantTrackVisible,
            setInfoAssistantTrackVisible,
        ] = useState(isShowInfoAssistant)

        const [activeBlock, setActiveBlock] = useState<ActiveBlockStateProps>({
            index: 0,
        })
        const [parentLessonIdForInfo, setParentLesson] = useState<number>()

        const { homework } = thematicBlockInProgress || {}

        const isShowThematicLayer =
            isThematicBlockStarted &&
            thematicBlockInProgress &&
            !nestedActivity &&
            !isThemeBlockTypeWebConferenceOrFaceToFace(
                thematicBlockInProgress.type
            ) &&
            !isThemeBlockTypeHomeworkWithoutEditor(
                thematicBlockInProgress?.type,
                thematicBlockInProgress?.homework?.isEditorUsed
            ) &&
            !isThemeBlockTypeVideoCourse(thematicBlockInProgress) &&
            !isThemeBlockTypeInformation(thematicBlockInProgress?.type)

        const handleSetActiveBlock = useCallback(
            (state: ActiveBlockStateProps) => {
                setActiveBlock(state)
            },
            []
        )

        const handleCloseNestedActivity = useCallback(async () => {
            onUpdateStageAssistantVisible(false)
            setThematicBlockStarted(false)
            setNestedActivity(undefined)
            refetchTrackInfo()
        }, [refetchTrackInfo, onUpdateStageAssistantVisible])

        const handleStartOrInfoLesson = useCallback(
            async (lessonId: number, isRefetch?: boolean) => {
                try {
                    store.updateLoader(true)
                    onThemeBlockByContentId?.(undefined)

                    if (!data.id) return

                    const requestMethod =
                        isRefetch || isAppointmentPassedView(appointmentStatus)
                            ? ActivityLessonPassingService.info
                            : ActivityLessonPassingService.start

                    const thematicBlock = await requestMethod({
                        body: {
                            activityPassingId: data.id,
                            lessonId,
                        },
                    })

                    if (!thematicBlock) return

                    setInfoAssistantTrackVisible(false)
                    onUpdateStageAssistantVisible(false)

                    if (
                        isThemeBlockTypeHomeworkWithoutEditor(
                            thematicBlock.type,
                            thematicBlock?.homework?.isEditorUsed
                        ) &&
                        thematicBlock?.homework.status ===
                            HomeworkStatusType.OnChecking
                    ) {
                        createWarningNotification(
                            LOCAL.MESSAGES.ATTENTION,
                            LOCAL.MESSAGES.HOMEWORK_ON_CHECKING
                        )

                        return
                    }

                    if (isThemeBlockTypeActivity(thematicBlock.type)) {
                        setNestedActivity({
                            ...thematicBlock.nestedActivityPassing,
                            isHomeworkAutoCheck: data.isHomeworkAutoCheck,
                        })
                    }

                    if (!isThemeBlockTypeQuestionnaire(thematicBlock.type)) {
                        setThematicBlockStarted(true)
                    }

                    setParentLesson(lessonId)
                    setThematicBlockInProgress(thematicBlock)

                    return thematicBlock
                } catch (error) {
                    console.error(error)
                } finally {
                    store.updateLoader(false)
                }
            },
            [
                store,
                onThemeBlockByContentId,
                data.id,
                appointmentStatus,
                onUpdateStageAssistantVisible,
                data.isHomeworkAutoCheck,
            ]
        )

        const handleRefetchParentLessonInfo = useCallback(() => {
            parentLessonIdForInfo &&
                handleStartOrInfoLesson(parentLessonIdForInfo, true)
        }, [handleStartOrInfoLesson, parentLessonIdForInfo])

        const goBackToTrackLayer = useCallback(
            ({ updateAfterFinishEvent = false }) => {
                updateInfoAboutPassing(undefined)

                if (!isTrackWithVisualisation || updateAfterFinishEvent) {
                    updateTrackInfo?.()
                }
            },
            [updateInfoAboutPassing, isTrackWithVisualisation, updateTrackInfo]
        )

        const handleFinishThematicBlock = useCallback(() => {
            setThematicBlockStarted(false)
            updateProgress?.()

            if (parentLessonIdForPassing) {
                onCloseNestedActivity?.()
                onRefetchParentLessonInfo?.()
            } else {
                refetchTrackInfo(true)
            }
        }, [
            onCloseNestedActivity,
            onRefetchParentLessonInfo,
            parentLessonIdForPassing,
            refetchTrackInfo,
            updateProgress,
        ])

        const handleExitThematicBlock = useCallback(
            async (progress?: number) => {
                try {
                    store.updateLoader(true)

                    if (!thematicBlockInProgress) return

                    await ActivityLessonPassingService.exit({
                        activityLessonPassingId: thematicBlockInProgress.id,
                        body: {
                            progress: progress || 0,
                        },
                    })
                    handleFinishThematicBlock()
                } catch (error) {
                    console.error(error)
                } finally {
                    store.updateLoader(false)
                }
            },
            [store, thematicBlockInProgress, handleFinishThematicBlock]
        )

        useEffect(() => {
            const index = getInitialThematicBlockForPassing(
                data.activityLessonPassings,
                themeBlockByContentId
            )

            const activityLesson = data.activityLessonPassings[index]

            setActiveBlock({
                index,
                borderColor: getThematicBLockColor(
                    activityLesson.status,
                    data.canvas
                ),
            })
        }, [data, themeBlockByContentId])

        const closeHomeworkModal = useCallback(async () => {
            try {
                setThematicBlockInProgress(undefined)
                await HomeworksService.onExit({
                    id: Number(homework?.id),
                })
                updateProgress?.()
                refetchTrackInfo?.()
            } catch (err) {
                console.error(err)
            }
        }, [refetchTrackInfo, homework, updateProgress])

        /** Получить канвас для слоя мероприятия в зависимости от его типа */
        const getActivityCanvas = () => {
            const options: CanvasProps = {
                activity: data,
                activeBlock,
                thematicBlockInProgress,
                parentLessonIdForPassing,
                isTrackWithVisualisation,
                isThematicBlockStarted,
                refetchTrackInfo,
                onCloseNestedActivity,
                goBackToTrackLayer,
                onSetActiveBlock: handleSetActiveBlock,
                onStartThematicBlock: handleStartOrInfoLesson,
                onFinishThematicBlock: handleFinishThematicBlock,
                onExitThematicBlock: handleExitThematicBlock,
                themeBlockByContentId,
                switchViewToNextStage,
                currentStagesId,
                currentStageNumber,
                trackPassingId,
            }

            switch (data.canvas?.canvasDesignType) {
                case ActivityCanvasDesignType.FullScreen:
                    return <FullScreenCanvas {...options} />
                case ActivityCanvasDesignType.ModalWindow:
                    return <ModalCanvas {...options} />
                default:
                    return <ListCanvas {...options} />
            }
        }

        const handleFinishInfoBlock = useCallback(async () => {
            if (thematicBlockInProgress) {
                await ActivityLessonPassingService.finish({
                    activityLessonPassingId: thematicBlockInProgress.id,
                })
                handleFinishThematicBlock()
                setThematicBlockInProgress(undefined)
            }
        }, [thematicBlockInProgress, handleFinishThematicBlock])

        return (
            <>
                <div className={styles.wrapper}>{getActivityCanvas()}</div>

                {isShowThematicLayer && (
                    <ThematicBlockLayer
                        thematicBlock={thematicBlockInProgress!}
                        activity={data}
                        onFinishThematicBlock={handleFinishThematicBlock}
                    />
                )}

                {isThematicBlockStarted && nestedActivity && (
                    <ActivityLayer
                        data={
                            {
                                ...nestedActivity,
                                id:
                                    thematicBlockInProgress?.mainActivityPassingId,
                            } as ActivityInfoProps
                        }
                        isTrackWithVisualisation={isTrackWithVisualisation}
                        updateInfoAboutPassing={updateInfoAboutPassing}
                        onUpdateStageAssistantVisible={
                            onUpdateStageAssistantVisible
                        }
                        refetchTrackInfo={refetchTrackInfo}
                        onCloseNestedActivity={handleCloseNestedActivity}
                        onRefetchParentLessonInfo={
                            handleRefetchParentLessonInfo
                        }
                        parentLessonIdForPassing={thematicBlockInProgress?.id}
                        appointmentStatus={appointmentStatus}
                        updateProgress={updateProgress}
                        currentStageNumber={currentStageNumber}
                    />
                )}

                {isThemeBlockTypeHomeworkWithoutEditor(
                    thematicBlockInProgress?.type,
                    thematicBlockInProgress?.homework?.isEditorUsed
                ) && (
                    <TaskPassingForm
                        homework={homework!}
                        popupProps={{
                            visible: !!thematicBlockInProgress,
                            onCancel: closeHomeworkModal,
                        }}
                        isHomeworkAutoCheck={data?.isHomeworkAutoCheck}
                    />
                )}

                {isThemeBlockTypeInformation(thematicBlockInProgress?.type) && (
                    <InformationPassingModal
                        popupProps={{
                            visible: !!thematicBlockInProgress,
                            onCancel: () =>
                                setThematicBlockInProgress(undefined),
                            onOk: handleFinishInfoBlock,
                        }}
                        selectedThematicBlock={thematicBlockInProgress}
                    />
                )}

                <InfoAssistantChecker>
                    {isTrackWithVisualisation && (
                        <InfoAssistant
                            {...stageInfoAssistantProps}
                            visible={stageInfoAssistantProps?.visible}
                        />
                    )}

                    <InfoAssistant
                        mode="passing"
                        event={TrackEvent.Start}
                        visible={
                            !isThematicBlockStarted && infoAssistantTrackVisible
                        }
                    />
                </InfoAssistantChecker>
            </>
        )
    }
)
