import {
    AppointmentStatus,
    AppointmentUserSearchContract,
    AssessmentType,
    QuestionnaireType,
} from 'core/api'
import { getConditionalArray } from 'utils'

import { USER_ASSIGNMENTS_INITIAL_VALUES } from '../../UserAssignmentsPage.consts'
import { UserAssignmentsFiltersSubmitValues } from './UserAssignmentsFilters.types'

/**
 * Маппер значений формы для запроса на бэк
 * @param value значения формы
 */
export const mapFormDataToRequest = ({
    showArchived,
    EstimateByManager,
    Estimate_360,
    Test,
}: Partial<UserAssignmentsFiltersSubmitValues>): Partial<
    AppointmentUserSearchContract
> => ({
    ...USER_ASSIGNMENTS_INITIAL_VALUES,
    types: [
        ...getConditionalArray(
            EstimateByManager || Estimate_360,
            QuestionnaireType.Assessment
        ),
        ...getConditionalArray(Test, QuestionnaireType.Test),
    ],
    statuses: [
        ...USER_ASSIGNMENTS_INITIAL_VALUES.statuses,
        ...getConditionalArray(!!showArchived, AppointmentStatus.Ended),
    ],
    assessmentTypes: [
        ...getConditionalArray(Estimate_360, AssessmentType.Estimate_360),
        ...getConditionalArray(
            EstimateByManager,
            AssessmentType.EstimateByManager
        ),
    ],
})

/**
 * Маппер значений фильтра (запроса) для подстановки в форму
 * @param value значения фильтра
 */
export const mapRequestToFormData = ({
    statuses,
    assessmentTypes,
    types,
}: Partial<AppointmentUserSearchContract>): Partial<
    UserAssignmentsFiltersSubmitValues
> => ({
    showArchived: statuses?.includes(AppointmentStatus.Ended),
    [AssessmentType.Estimate_360]: assessmentTypes?.includes(
        AssessmentType.Estimate_360
    ),
    [AssessmentType.EstimateByManager]: assessmentTypes?.includes(
        AssessmentType.EstimateByManager
    ),
    [QuestionnaireType.Test]: types?.includes(QuestionnaireType.Test),
})
