import React, { FC, useMemo, useState } from 'react'

import { COMPARING_OPTIONS_DEFAULT } from './UserProfileContextProvider.consts'
import { ComparingOptions } from './UserProfileContext.types'
import { UserProfileContext } from './UserProfileContext'

export const UserProfileContextProvider: FC = React.memo(({ children }) => {
    const [resultsComparingOptions, setResultsComparingOptions] = useState<
        ComparingOptions[]
    >(COMPARING_OPTIONS_DEFAULT)

    const [showAssessments, showProfilePosition] = useMemo(
        () => [
            resultsComparingOptions?.includes(ComparingOptions.Assessments),
            resultsComparingOptions?.includes(ComparingOptions.ProfilePosition),
        ],
        [resultsComparingOptions]
    )

    return (
        <UserProfileContext.Provider
            value={{
                resultsComparingOptions,
                setResultsComparingOptions,
                showAssessments,
                showProfilePosition,
            }}
        >
            {children}
        </UserProfileContext.Provider>
    )
})
