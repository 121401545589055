import isEmpty from 'lodash/isEmpty'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Col, Form, Row } from 'antd'
import {
    ControlGroup,
    HiddenField,
    ShouldUpdateChecker,
} from 'components/controls'
import { EmailPreviewForm } from 'components/forms'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH, ROW_GUTTER } from 'consts'
import {
    NotificationNewsletterTemplateContract,
    NotificationNewsletterTemplatesService,
} from 'core/api'
import { PopupWithForm } from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { createConfirmPopup } from 'utils/notification.utils'
import {
    getInitialValuesForCopy,
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { useHistory } from 'react-router-dom'

import styles from './DistributionTemplateForm.module.scss'
import { DistributionTemplateFormProps } from './DistributionTemplateForm.types'
import { DistributionTemplateInfo } from '../DistributionTemplateInfo'
import { DistributionTemplateMail } from '../DistributionTemplateMail'
import { DistributionTemplatesCommonInfo } from '../DistributionTemplatesCommonInfo'
import { mapResponseToFormData } from './DistributionTemplateForm.utils'

/** Форма для создания, редактирования шаблона рассылки */
export const DistributionTemplateForm: FC<DistributionTemplateFormProps> = React.memo(
    ({
        formMode,
        initialValuesForEdit,
        isSaveDocument,
        updateLoader,
        onBlockUserRouting,
    }) => {
        const [form] = Form.useForm()
        const history = useHistory()

        const [isFormTouched, setFormTouched] = useState(false)

        useEffect(() => {
            if (isEmpty(initialValuesForEdit)) return

            form.setFieldsValue(
                mapResponseToFormData(
                    getInitialValuesForCopy<
                        NotificationNewsletterTemplateContract
                    >(initialValuesForEdit!, formMode)
                )
            )
        }, [form, initialValuesForEdit, formMode, onBlockUserRouting])

        const handleFinish = useCallback(
            async (body) => {
                try {
                    updateLoader?.(true)
                    let document: NotificationNewsletterTemplateContract
                    if (isSaveDocument) {
                        document = await NotificationNewsletterTemplatesService.save(
                            {
                                body,
                            }
                        )
                    } else {
                        document = await NotificationNewsletterTemplatesService.publish(
                            {
                                body,
                            }
                        )
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: isSaveDocument
                            ? LOCAL.MESSAGES.TEMPLATE_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES.TEMPLATE_PUBLISH_SUCCESS_MESSAGE,
                        content: `${LOCAL.MESSAGES.GO_TO_PAGE} "${LOCAL.LABELS.TEMPLATES}"?`,
                        okText: LOCAL.ACTIONS.CONFIRM,
                        onOk: () => {
                            history.push(ROUTE_NAMES.DISTRIBUTION_TEMPLATES)
                        },
                        onCancel: () => {
                            history.push(
                                `${ROUTE_NAMES.DISTRIBUTION_TEMPLATES_VIEW}/${document.id}`
                            )
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [isSaveDocument, history, updateLoader, onBlockUserRouting]
        )

        const showInfo = isFormModeEdit(formMode) || isFormModeView(formMode)

        /**
         * Обработчик изменений формы
         */
        const handleValuesChange = useCallback(
            (changedValue) => {
                const notificationBodyMatchResult = initialValuesForEdit?.notificationBody?.match(
                    changedValue.notificationBody
                )

                if (
                    !changedValue.notificationBody ||
                    notificationBodyMatchResult === null
                ) {
                    setFormTouched(true)
                }
            },
            [initialValuesForEdit]
        )

        useEffect(() => {
            if (isFormTouched && !isFormModeView(formMode)) {
                onBlockUserRouting?.()
            }
        }, [formMode, isFormTouched, onBlockUserRouting])

        return (
            <Form
                form={form}
                id={FORM_IDS.TEMPLATES_FORM}
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                className={styles.wrapper}
            >
                {!isFormModeCopy(formMode) && <HiddenField fieldName="id" />}

                <Row gutter={ROW_GUTTER} className={styles.row}>
                    <Col xs={16}>
                        <DistributionTemplatesCommonInfo formMode={formMode} />
                    </Col>

                    <Col xs={8}>
                        {showInfo && (
                            <DistributionTemplateInfo
                                template={initialValuesForEdit!}
                            />
                        )}
                    </Col>
                </Row>

                <ControlGroup
                    title={LOCAL.LABELS.MAIL}
                    type="bordered"
                    className={styles.block}
                >
                    <ShouldUpdateChecker
                        fieldPath={[
                            ['notificationTheme'],
                            ['notificationBody'],
                        ]}
                    >
                        {({ getFieldsValue }) => (
                            <PopupWithForm
                                formId={FORM_IDS.EMAIL_PREVIEW_FORM}
                                buttonText={LOCAL.ACTIONS.PREVIEW}
                                buttonOption={{
                                    className: 'preview-button',
                                }}
                                modalOptions={{
                                    footer: null,
                                    width: MODAL_WIDTH.XL,
                                    title: LOCAL.ACTIONS.PREVIEW,
                                }}
                                component={EmailPreviewForm}
                                formOptions={{
                                    initialValues: getFieldsValue([
                                        'notificationTheme',
                                        'notificationBody',
                                    ]),
                                }}
                            />
                        )}
                    </ShouldUpdateChecker>

                    <DistributionTemplateMail
                        form={form}
                        formMode={formMode}
                        initialValuesForEdit={initialValuesForEdit}
                    />
                </ControlGroup>
            </Form>
        )
    }
)
