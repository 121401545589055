import sortBy from 'lodash/sortBy'
import React, { useCallback, useEffect, useState } from 'react'
import { AddOrganizationControl } from 'components/controls/AddOrganizationControl'
import { AddPositionControl } from 'components/controls/AddPositionControl'
import { AddonAfterDefault } from 'components/shared'
import {
    COMPETENCIES_REQUIRED_PERMISSIONS,
    EXCLUDE_DRAFT_STATUS_SEARCH_BODY,
    QUESTIONNAIRE_TYPES_MAPPED,
    QUIZ_TYPES,
    ROW_GUTTER,
    TEST_SEQUENCE_MAPPED,
} from 'consts'
import {
    COMPETENCIES_TABLE_COLUMNS,
    CompetenciesFilters,
    FormItemAdapter,
    HiddenField,
    InputControl,
    NotEditableTextField,
    RadioButtonControl,
    SearchSelectControl,
    SelectControl,
    ShouldUpdateChecker,
    TextAreaControl,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import {
    CompetenceForModalContract,
    CompetenciesSearchContract,
    CompetenciesService,
    QuestionnaireContract,
    QuestionnaireDictionariesService,
    QuestionnaireSaveContract,
    QuestionnaireType,
    QuestionnairesService,
    QuizType,
} from 'core/api'
import { DEPENDENCIES_MESSAGES } from 'core/local/messages.local.config'
import { DictionariesCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WithLoaderProps } from 'HOCs'
import {
    createConfirmPopup,
    getFormPopupTitle,
    getInitialValuesForCopy,
    getOnBeforeChangeHandler,
    isDocumentStatusReadyForWork,
    isFormModeView,
    isObjectEmpty,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeQuiz,
    isQuestionnaireTypeTest,
    setParentIdForCopy,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useDictionaries, useQuery } from 'hooks'
import { useHistory } from 'react-router-dom'

import styles from './QuestionnairesConstructorForm.module.scss'
import { AssessmentQuestionnaireOptions } from '../AssessmentQuestionnaireOptions'
import {
    INITIAL_FORM_VALUES,
    fieldsShouldUpdate,
    resetQuestionsValueViaDependency,
} from './QuestionnairesConstructorForm.consts'
import { MIN_LIMIT_ANSWERS_COUNT } from '../QuestionForm/QuestionForm.consts'
import { QuestionVariationValueProps } from '../QuestionVariation/QuestionVariation.types'
import { QuestionnairesConstructorFormSubmitValues } from './QuestionnairesConstructorForm.types'
import { QuestionsList } from '../QuestionsList'
import { ResultAssessment } from '../ResultAssessment'
import { calculateMaxScore } from '../AnswerRow/AnswerRow.utils'
import {
    calculateValuesByRules,
    changeQuestionFieldsByIndex,
    isResultAssessmentVisible,
    mapFormDataToRequest,
    mapResponseToFormData,
} from './QuestionnairesConstructorForm.utils'

/**
 * Форма создания шаблона опросного листа
 */
export const QuestionnairesConstructorForm: React.FC<
    FormProps<QuestionnaireContract> & WithLoaderProps
> = ({
    isSaveDocument,
    onBlockUserRouting,
    reFetchInitialFormValues,
    updateLoader,
    isLoading,
    initialValuesForEdit,
    formMode,
}) => {
    const history = useHistory()
    const query = useQuery()
    const [form] = Form.useForm()
    const [initialValuesForCreate, setInitialValuesForCreate] = useState<
        Partial<QuestionnairesConstructorFormSubmitValues>
    >(INITIAL_FORM_VALUES)
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<
            'resultCalculatingMethods' | 'respondentTypes' | 'testTypes'
        >
    >({ updateLoader })

    /**
     * Обработчик отправки формы
     */
    const handleFinish = useCallback(
        async (values: Partial<QuestionnairesConstructorFormSubmitValues>) => {
            try {
                updateLoader(true)

                const body = setParentIdForCopy(
                    mapFormDataToRequest(values),
                    formMode
                ) as QuestionnaireSaveContract

                let dataSource: QuestionnaireContract
                if (isSaveDocument) {
                    dataSource = await QuestionnairesService.save({ body })
                } else {
                    dataSource = await QuestionnairesService.publish({ body })
                }

                onBlockUserRouting?.(false)

                createConfirmPopup({
                    title: getFormPopupTitle(
                        getRouteMeta(ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES)
                    ),
                    content: isSaveDocument
                        ? LOCAL.MESSAGES.TEMPLATE_SAVE_SUCCESS_MESSAGE
                        : LOCAL.MESSAGES.TEMPLATE_PUBLISH_SUCCESS_MESSAGE,
                    onOk: () => {
                        history.push(ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES)
                    },
                    onCancel: () => {
                        if (!values?.id) {
                            form?.resetFields()
                            form?.setFieldsValue(INITIAL_FORM_VALUES)
                        } else reFetchInitialFormValues?.(dataSource?.id)
                    },
                })
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        [
            updateLoader,
            formMode,
            isSaveDocument,
            onBlockUserRouting,
            history,
            reFetchInitialFormValues,
            form,
        ]
    )

    /**
     * Обработчик изменения значений полей формы
     * @param changedValue значение поля на котором сработало событие
     * @param allValues все значения, включая `changedValue`
     */
    const handleValuesChange = useCallback(
        (
            changedValue,
            allValues: QuestionnairesConstructorFormSubmitValues
        ) => {
            const [changedValueKey] = Object.keys(changedValue)

            if (fieldsShouldUpdate.includes(changedValueKey)) {
                const calculateValues = {
                    ...calculateValuesByRules(allValues),
                    ...resetQuestionsValueViaDependency(changedValueKey),
                }

                if (
                    changedValueKey === 'type' &&
                    isQuestionnaireTypeAssessment(
                        changedValue[changedValueKey]
                    ) &&
                    !allValues.assessmentType
                ) {
                    form?.setFieldsValue({
                        assessmentType: INITIAL_FORM_VALUES.assessmentType,
                        ...calculateValues,
                    })
                } else {
                    form?.setFieldsValue(calculateValues)
                }
            }

            if (changedValueKey === 'competenceId') {
                form?.setFieldsValue({
                    questions: allValues.questions?.map(
                        (question: QuestionVariationValueProps) => ({
                            ...question,
                            indicatorId: null,
                            title: undefined,
                        })
                    ),
                })
            }

            if (
                changedValueKey === 'questions' &&
                changedValue[changedValueKey].length
            ) {
                const allQuestions = allValues.questions?.filter(
                    (el) => !isObjectEmpty(el, true)
                )
                const questionsList = changedValue[changedValueKey]
                const questionIndex = questionsList.length - 1
                const questionInfo = questionsList[questionIndex]

                if (
                    questionInfo?.answers?.length >= MIN_LIMIT_ANSWERS_COUNT &&
                    questionInfo?.answers?.length <
                        allQuestions?.[questionIndex]?.limitAnswersCount
                ) {
                    form?.setFieldsValue(
                        changeQuestionFieldsByIndex(
                            questionIndex,
                            {
                                limitAnswersCount: questionInfo.answers.length,
                            },
                            allQuestions
                        )
                    )
                }

                if (questionInfo?.indicatorId) {
                    form?.setFieldsValue(
                        changeQuestionFieldsByIndex(
                            questionIndex,
                            {
                                title: questionInfo?.indicatorId.label,
                            },
                            allQuestions
                        )
                    )
                }

                if (
                    questionInfo?.isActive === false &&
                    allQuestions?.[questionIndex]?.blockedByQuestionNumber
                ) {
                    form?.setFieldsValue(
                        changeQuestionFieldsByIndex(
                            questionIndex,
                            {
                                blockedByQuestionNumber: undefined,
                            },
                            allQuestions
                        )
                    )
                }
            }

            if (
                isQuestionnaireTypeTest(allValues.type) &&
                changedValueKey === 'questions'
            ) {
                const maxPoints = calculateMaxScore(allValues.questions)
                const resetResultAssessmentValues =
                    maxPoints === allValues.maxPoints
                        ? {}
                        : {
                              resultAssessmentType:
                                  INITIAL_FORM_VALUES.resultAssessmentType,
                          }

                form?.setFieldsValue({
                    maxPoints,
                    ...resetResultAssessmentValues,
                })
            }

            if (form?.isFieldsTouched() && !isFormModeView(formMode)) {
                onBlockUserRouting?.()
            }
        },
        [form, formMode, onBlockUserRouting]
    )

    /**
     * Запрос справочников
     */
    useEffect(() => {
        handleFetchDictionaries({
            resultCalculatingMethods: QuestionnaireDictionariesService.getResultCalculatingMethods(),
            respondentTypes: QuestionnaireDictionariesService.getRespondentTypes(),
            testTypes: QuestionnaireDictionariesService.getTestTypes(),
        })
    }, [handleFetchDictionaries])

    useEffect(() => {
        const typeFromQuery = query.get('type')
        const competenceIdFromQuery = query.get('competenceId')

        if (competenceIdFromQuery && typeFromQuery) {
            const competenceFetch = async () =>
                await CompetenciesService.getByIds({
                    body: [competenceIdFromQuery],
                })

            competenceFetch().then((res) => {
                setInitialValuesForCreate((prevState) => ({
                    ...prevState,
                    type: typeFromQuery as QuestionnaireType,
                    competenceId: [
                        {
                            value: res[0].id,
                            key: String(res[0].id),
                            label: res[0].name,
                        },
                    ],
                }))
            })
        }
    }, [query])

    useEffect(() => {
        const initialValues = initialValuesForEdit?.id
            ? mapResponseToFormData?.(
                  getInitialValuesForCopy(initialValuesForEdit, formMode)
              )
            : initialValuesForCreate

        form.setFieldsValue(initialValues)
    }, [form, formMode, initialValuesForEdit, initialValuesForCreate])

    return (
        <Form
            id={FORM_IDS.QUESTIONNAIRES_CONSTRUCTOR_FORM}
            onFinish={handleFinish}
            onValuesChange={handleValuesChange}
            form={form}
            className={styles.wrapper}
        >
            <HiddenField fieldName="id" />

            <HiddenField fieldName="formMode" initialValue={formMode} />

            <ShouldUpdateChecker fieldPath={['type']}>
                {(form) => {
                    const type = form.getFieldValue('type')

                    const status = form.getFieldValue('status')

                    const isTypeTest = isQuestionnaireTypeTest(type)

                    const isCompetenceRequired =
                        isQuestionnaireTypeAssessment(type) || isTypeTest

                    return (
                        <>
                            <Row
                                gutter={ROW_GUTTER}
                                className={styles.mainFields}
                            >
                                <Col xs={12}>
                                    <FormItemAdapter
                                        fieldName="name"
                                        label={LOCAL.LABELS.TEMPLATE_NAME}
                                        required
                                    >
                                        <InputControl formMode={formMode} />
                                    </FormItemAdapter>

                                    <FormItemAdapter
                                        fieldName="type"
                                        label={LOCAL.LABELS.DOCUMENT_TYPE}
                                    >
                                        <RadioButtonControl
                                            values={sortBy(
                                                QUESTIONNAIRE_TYPES_MAPPED,
                                                'label'
                                            )}
                                            buttonStyle="solid"
                                            form={form}
                                            onBeforeChange={getOnBeforeChangeHandler(
                                                {
                                                    dependencies: [
                                                        ['questions'],
                                                    ],
                                                    confirmTitle:
                                                        DEPENDENCIES_MESSAGES.QUESTIONS,
                                                    form,
                                                }
                                            )}
                                            formMode={formMode}
                                            disabled={isDocumentStatusReadyForWork(
                                                status
                                            )}
                                        />
                                    </FormItemAdapter>

                                    {isQuestionnaireTypeQuiz(type) && (
                                        <NotEditableTextField
                                            fieldName="quizType"
                                            label={LOCAL.LABELS.QUIZ_TYPE}
                                            normalizeValueFn={(
                                                type: QuizType
                                            ) => QUIZ_TYPES[type]}
                                        />
                                    )}

                                    <FormItemAdapter
                                        fieldName="description"
                                        label={
                                            LOCAL.LABELS.TEMPLATE_DESCRIPTION
                                        }
                                    >
                                        <TextAreaControl
                                            autoSize={{
                                                minRows: 3,
                                                maxRows: 3,
                                            }}
                                            formMode={formMode}
                                        />
                                    </FormItemAdapter>

                                    <FormItemAdapter
                                        fieldName="positionIds"
                                        label={LOCAL.LABELS.POSITION}
                                    >
                                        <AddPositionControl
                                            formMode={formMode}
                                            withSwitch
                                        />
                                    </FormItemAdapter>

                                    <FormItemAdapter
                                        fieldName="structuralUnitIds"
                                        label={LOCAL.LABELS.ORGANIZATIONS}
                                    >
                                        <AddOrganizationControl
                                            formMode={formMode}
                                            isExpandRowBySearch
                                            withSwitch
                                        />
                                    </FormItemAdapter>

                                    {!isQuestionnaireTypeQuiz(type) && (
                                        <ShouldUpdateChecker fieldPath="competenceId">
                                            {({ getFieldValue }) => {
                                                const competenceId = getFieldValue(
                                                    'competenceId'
                                                )?.[0].value

                                                return (
                                                    <FormItemAdapter
                                                        fieldName="competenceId"
                                                        label={
                                                            LOCAL.LABELS
                                                                .COMPETENCE
                                                        }
                                                        required={
                                                            isCompetenceRequired
                                                        }
                                                    >
                                                        <SearchSelectControl<
                                                            CompetenciesSearchContract,
                                                            CompetenceForModalContract
                                                        >
                                                            getByIdsMethod={
                                                                CompetenciesService.getByIds
                                                            }
                                                            formMode={formMode}
                                                            modalTitle={
                                                                LOCAL.LABELS
                                                                    .COMPETENCE_SELECTION
                                                            }
                                                            addonAfter={
                                                                <AddonAfterDefault
                                                                    url={`${ROUTE_NAMES.COMPETENCIES_VIEW}/${competenceId}`}
                                                                    disabled={
                                                                        !competenceId
                                                                    }
                                                                    permissionsRequired={
                                                                        COMPETENCIES_REQUIRED_PERMISSIONS
                                                                    }
                                                                />
                                                            }
                                                            tableSearchOptions={{
                                                                searchMethod:
                                                                    CompetenciesService.getForModal,
                                                                tableFiltersOptions: {
                                                                    initialValues: EXCLUDE_DRAFT_STATUS_SEARCH_BODY,
                                                                },
                                                                filterComponent: CompetenciesFilters,
                                                                tableColumns: COMPETENCIES_TABLE_COLUMNS,
                                                            }}
                                                        />
                                                    </FormItemAdapter>
                                                )
                                            }}
                                        </ShouldUpdateChecker>
                                    )}

                                    {isTypeTest && (
                                        <FormItemAdapter
                                            fieldName="testTypeId"
                                            label={LOCAL.LABELS.TEST_KIND}
                                            required
                                        >
                                            <SelectControl
                                                values={dictionaries?.testTypes}
                                                formMode={formMode}
                                            />
                                        </FormItemAdapter>
                                    )}

                                    <FormItemAdapter
                                        fieldName="sequence"
                                        label={LOCAL.LABELS.ANSWERS_SEQUENCE}
                                        hidden={isTypeTest}
                                        noStyle={isTypeTest}
                                    >
                                        <RadioButtonControl
                                            values={TEST_SEQUENCE_MAPPED}
                                            buttonStyle="solid"
                                            formMode={formMode}
                                        />
                                    </FormItemAdapter>
                                </Col>

                                <Col xs={12}>
                                    {isQuestionnaireTypeAssessment(type) && (
                                        <AssessmentQuestionnaireOptions
                                            resultCalculatingMethods={
                                                dictionaries?.resultCalculatingMethods ||
                                                []
                                            }
                                            respondentTypes={
                                                dictionaries?.respondentTypes ||
                                                []
                                            }
                                            status={status}
                                        />
                                    )}

                                    {isResultAssessmentVisible(
                                        form.getFieldsValue()
                                    ) && <ResultAssessment />}
                                </Col>
                            </Row>

                            <ShouldUpdateChecker fieldPath="competenceId">
                                {(form) => {
                                    const competenceId = form?.getFieldValue(
                                        'competenceId'
                                    )
                                    const id = competenceId?.[0].value

                                    return (
                                        <QuestionsList
                                            updateLoader={updateLoader}
                                            isLoading={isLoading}
                                            competenceId={id}
                                        />
                                    )
                                }}
                            </ShouldUpdateChecker>
                        </>
                    )
                }}
            </ShouldUpdateChecker>
        </Form>
    )
}
