import React from 'react'
import isNumber from 'lodash/isNumber'
import { CheckboxControl } from 'components/controls'
import { ColumnsType } from 'antd/lib/table'
import { FnActionProps } from 'App.types'
import { LOCAL } from 'core/local'
import {
    UserNotificationSettingContract,
    UserNotificationSettingSaveContract,
} from 'core/api'

import {
    FetchNotificationsStatusProps,
    MapFormDataToRequestProps,
    NotificationsKindProps,
    NotificationsSettingsTableDataChildProps,
    NotificationsSettingsTableDataProps,
    RenderColumnTitleProps,
} from './NotificationsSettings.types'

/**
 * Заголовок столбца таблицы
 */
const renderColumnTitle = ({
    title,
    onFetchNotificationsStatus,
    propName,
    settingsData,
}: RenderColumnTitleProps) => {
    const isChecked = settingsData?.every((el) => el[propName])
    const isIndeterminate = settingsData?.some((el) =>
        el.children.some((child) => child[propName])
    )

    return (
        <>
            <div>{title}</div>

            <CheckboxControl
                onChange={onFetchNotificationsStatus({
                    notificationKind: propName,
                })}
                checked={isChecked}
                indeterminate={!isChecked && isIndeterminate}
            >
                {LOCAL.ACTIONS.SELECT_ALL}
            </CheckboxControl>
        </>
    )
}

/**
 * Отображение колонок для страницы настройки уведомлений
 * @param onFetchNotificationsStatus обработчик запроса на сервер для сохранения изменений
 * @param settingsData данные с сервера с настройками оповещений
 */
export const getNotificationsSettingsTableColumns = (
    onFetchNotificationsStatus: (
        values: FetchNotificationsStatusProps
    ) => FnActionProps<boolean>,
    settingsData?: NotificationsSettingsTableDataProps[]
): ColumnsType<NotificationsSettingsTableDataProps> => [
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: LOCAL.LABELS.NOTIFICATIONS_THEME,
        dataIndex: 'theme',
        key: 'theme',
        width: '50%',
    },
    {
        title: renderColumnTitle({
            title: LOCAL.LABELS.IN_PERSONAL_ACCOUNT,
            onFetchNotificationsStatus,
            settingsData,
            propName: 'isPersonalArea',
        }),
        dataIndex: 'isPersonalArea',
        key: 'isPersonalArea',
        render: (isChecked, { id, notificationType, children }) => (
            <CheckboxControl
                checked={isChecked}
                indeterminate={
                    !isChecked && children?.some((el) => el.isPersonalArea)
                }
                onChange={onFetchNotificationsStatus({
                    notificationKind: 'isPersonalArea',
                    id,
                    notificationType,
                })}
            />
        ),
    },
    {
        title: renderColumnTitle({
            title: LOCAL.LABELS.BY_POST,
            onFetchNotificationsStatus,
            settingsData,
            propName: 'isEmail',
        }),
        dataIndex: 'isEmail',
        key: 'isEmail',
        render: (isChecked, { id, notificationType, children }) => (
            <CheckboxControl
                checked={isChecked}
                indeterminate={!isChecked && children?.some((el) => el.isEmail)}
                onChange={onFetchNotificationsStatus({
                    notificationKind: 'isEmail',
                    id,
                    notificationType,
                })}
            />
        ),
    },
]

/**
 * Создает дочерний объект для таблицы настроек оповещения
 * @param themeData
 */
const createTableDataChildObject = ({
    themeId,
    type,
    ...restThemeData
}: UserNotificationSettingContract): NotificationsSettingsTableDataChildProps => ({
    id: themeId,
    ...restThemeData,
})

/**
 * Создает родительский объект для таблицы настроек оповещения
 * @param themeData
 */
const createTableDataParentObject = (
    themeData: UserNotificationSettingContract
): NotificationsSettingsTableDataProps => ({
    id: themeData.notificationType,
    type: themeData.type,
    notificationType: themeData.notificationType,
    isPersonalArea: false,
    isEmail: false,
    children: [createTableDataChildObject(themeData)],
})

/**
 * Нормалайзер данных настроек уведомлений для таблицы
 * @param values данные настроек уведомлений
 */
export const normalizedNotificationsSettingsData = (
    values: UserNotificationSettingContract[]
): NotificationsSettingsTableDataProps[] => {
    const tableData = values.reduce<NotificationsSettingsTableDataProps[]>(
        (columnData, themeData: UserNotificationSettingContract) => {
            const parentIndex = columnData.findIndex(
                (el) => el.notificationType === themeData.notificationType
            )

            if (parentIndex === -1) {
                return [...columnData, createTableDataParentObject(themeData)]
            }

            const parentElement = columnData[parentIndex]
            const { themeId, type, ...restChildThemeDta } = themeData

            parentElement.children = [
                ...parentElement.children,
                { id: themeId, ...restChildThemeDta },
            ]

            return columnData
        },
        []
    )

    return tableData.map((el) => ({
        ...el,
        isEmail: el.children.every((theme) => theme.isEmail),
        isPersonalArea: el.children.every((theme) => theme.isPersonalArea),
    }))
}

/**
 * Создает объект с типом уведомления для отправки на север
 * @param themeId идентификатор темы
 * @param notificationKind вид уведомления
 * @param isChecked состояние оповещения
 */
export const createRequestTypeObject = (
    themeId: number,
    notificationKind: NotificationsKindProps,
    isChecked?: boolean
): Partial<UserNotificationSettingSaveContract> => ({
    themeId,
    [notificationKind]: isChecked,
})

/**
 * Готовим данные формы для отправки на бек
 * @param value значения для подготовки итогового массива
 */
export const mapFormDataToRequest = ({
    id,
    notificationType,
    settingsData,
    notificationKind,
    isChecked,
}: MapFormDataToRequestProps): Partial<
    UserNotificationSettingSaveContract
>[] => {
    if (!id && !notificationType) {
        return settingsData
            .map((el) =>
                el.children.map((theme) =>
                    createRequestTypeObject(
                        theme.id,
                        notificationKind,
                        isChecked
                    )
                )
            )
            .flat()
    }

    if (isNumber(id)) {
        return [createRequestTypeObject(id, notificationKind, isChecked)]
    }

    return settingsData
        .find((el) => el.notificationType === notificationType)!
        .children.map((el) =>
            createRequestTypeObject(el.id, notificationKind, isChecked)
        )
}
