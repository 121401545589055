import React, { FC, memo, useMemo } from 'react'
import { LABELS } from 'core/local/labels.local.config'
import { PassingHeader } from 'components/shared/Passing/PassingHeader'
import { QUIZ_TYPES, TEST_TYPES } from 'consts'

import { QuestionnairesPassingHeaderProps } from './QuestionnairesPassingHeader.types'

/** Заголовок приветствия/финального окна */
export const QuestionnairesPassingHeader: FC<QuestionnairesPassingHeaderProps> = memo(
    ({
        presentationSettings,
        numberOfAnswers,
        numberOfQuestions,
        hasUnsavedChanges,
        styles,
        quizType,
        testType,
    }) => {
        // eslint-disable-next-line no-nested-ternary
        const type = quizType
            ? QUIZ_TYPES[quizType]
            : testType
            ? `${TEST_TYPES[testType]} ${LABELS.TEST}`
            : ''

        const title = useMemo(
            () => (
                <div style={styles}>{presentationSettings?.title || type}</div>
            ),
            [styles, type, presentationSettings]
        )

        return (
            <PassingHeader
                styles={styles}
                title={title}
                numberOfAnswers={numberOfAnswers}
                numberOfQuestions={numberOfQuestions}
                hasUnsavedChanges={hasUnsavedChanges}
            />
        )
    }
)
