import {
    CompetenceGroupsPublicQuestionsContract,
    DictionaryContract,
} from 'core/api'
import { getItemId } from 'utils'

/** Определяем активная ли компетенция в меню */
export const getIsActive = (
    competence: DictionaryContract,
    activeCompetence?: DictionaryContract
) => {
    if (activeCompetence) {
        return competence.id === activeCompetence.id
    }
}

/** Получить все айди всех групп компетенций */
export const getAllIds = (groups: CompetenceGroupsPublicQuestionsContract[]) =>
    groups.map((group) => getItemId(group.competenceGroup))

/** Скролл до переданной позиции */
export const scrollTo = (position: number) => window.scrollTo({ top: position })
