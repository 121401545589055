import { AppError } from 'core/api'
import { FnActionAsyncRequiredProps, FnActionRequiredProps } from 'App.types'
import { ReactText, useCallback, useEffect, useState } from 'react'

export interface UrlParamsForEditProps {
    id?: string
}

export type RequestMethodProps = {
    id: number
}

const initialValues = {}

export interface UseFormInitialValuesProps<T> {
    requestMethod: FnActionAsyncRequiredProps<RequestMethodProps, T>
    id?: ReactText
    onRequestError?: FnActionRequiredProps<AppError>
    onRequestSuccess?: FnActionRequiredProps<T>
    updateLoader?: FnActionRequiredProps<boolean>
}

/**
 * Хук useFormInitialValues используется для запроса изначальных значений формы после ее инициализации в компоненте.
 * @param params метод запроса данных для формы, который предоставляет api
 */
export function useFormInitialValues<T>(
    params: UseFormInitialValuesProps<T>
): {
    fetchInitialFormValuesById: FnActionRequiredProps<number>
    initialValuesForEdit?: T
} {
    const {
        requestMethod,
        id,
        onRequestError,
        onRequestSuccess,
        updateLoader,
    } = params

    const [initialValuesForEdit, setInitialValuesForEdit] = useState(
        initialValues as T
    )

    /**
     * функция запроса initial values по `id`
     */
    const fetchInitialFormValuesById = useCallback(
        async (id: number) => {
            try {
                updateLoader?.(true)
                const response = await requestMethod({ id })
                setInitialValuesForEdit(response)
                onRequestSuccess?.(response)
            } catch (error) {
                console.error(error)
                onRequestError?.(error as AppError)
            } finally {
                updateLoader?.(false)
            }
        },
        [updateLoader, requestMethod, onRequestError, onRequestSuccess]
    )

    useEffect(() => {
        if (id) fetchInitialFormValuesById(Number(id))
    }, [fetchInitialFormValuesById, id])

    return {
        initialValuesForEdit,
        fetchInitialFormValuesById,
    }
}
