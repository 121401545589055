import React from 'react'
import { AssessmentProjectContract, AssessmentProjectService } from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { FormContainer } from 'components/layouts'

import { AssessmentProjectsForm } from './components/AssessmentProjectsForm'
import {
    CANCEL_BTN_OPTIONS,
    PUBLISH_BTN_OPTIONS,
    getSaveBtnOptions,
} from './AssessmentProjects.config'

export const AssessmentProjectsFormContainer: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <FormContainer<AssessmentProjectContract>
            formId={FORM_IDS.ASSESSMENT_PROJECT_FORM}
            formMode={formMode}
            formComponent={AssessmentProjectsForm}
            requestMethod={AssessmentProjectService.getById}
            cancelBtnOptions={CANCEL_BTN_OPTIONS}
            saveBtnOptions={getSaveBtnOptions}
            publishBtnOptions={PUBLISH_BTN_OPTIONS}
        />
    )
)
