import React, { useCallback, useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import styles from './PageTabs.module.scss'
import { PageTabsProps } from './PageTabs.types'
import { getActiveTab } from './PageTabs.utils'

/** Стилизованные вкладки-табы для страниц реестров, обычно размещаются внутри компонента PageBanner */
export const PageTabs: React.FC<PageTabsProps> = React.memo(
    ({ className, tabsData, showCount = true, onChange, defaultTabKey }) => {
        const [activeTab, setActiveTab] = useState(defaultTabKey)
        const location = useLocation()

        /**
         * Обработчик передачи значения текущего таба во вне компонента
         */
        const triggerChange = useCallback(
            (tabKey) => () => {
                onChange?.(tabKey)
                setActiveTab(tabKey)
            },
            [onChange]
        )

        /**
         * Вкладки
         */
        const tabs = useMemo(
            () =>
                tabsData?.map((tab) => (
                    <NavLink
                        isActive={
                            defaultTabKey && activeTab
                                ? getActiveTab(tab.key, activeTab)
                                : undefined
                        }
                        key={tab.key}
                        className={styles.item}
                        activeClassName={styles.itemActive}
                        to={tab.path || location}
                        onClick={triggerChange(tab.key)}
                    >
                        {tab.text}

                        {showCount && <span>{tab.recordsCount}</span>}
                    </NavLink>
                )),
            [
                showCount,
                tabsData,
                triggerChange,
                activeTab,
                defaultTabKey,
                location,
            ]
        )

        return (
            <div className={className}>
                <div className={styles.list}>{tabs}</div>
            </div>
        )
    }
)
