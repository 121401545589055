export const ACTIONS = {
    ACCEPT: 'Принять',
    ADD: 'Добавить',
    ADD_ARTICLE: 'Добавить статью',
    ADD_CHOSEN_EMPLOYEES: 'Добавить выбранных сотрудников',
    ADD_COMMENT_TO_AGREEMENT: 'Добавить комментарий к согласованию',
    ADD_COMPETENCIES_FROM_PP: 'Добавить компетенции из ПД',
    ADD_FIELD: 'Добавить поле',
    ADD_FROM_CATALOG: 'Добавить из справочника',
    ADD_FROM_REGISTER: 'Добавить из справочника',
    ADD_GROUP: 'Добавить группу',
    ADD_KNOWLEDGE_IN_RELATED_INDUSTRY: 'Добавить знания в смежных областях',
    ADD_LEVEL: 'Добавить уровень',
    ADD_MATERIAL: 'Добавить материал',
    ADD_PARTICIPANTS: 'Добавить участников',
    ADD_PERIOD: 'Добавить период',
    ADD_PROFILE: 'Добавить профиль',
    ADD_QUESTION: 'Добавить вопрос',
    ADD_QUIZ_TEST: 'Добавить тест/опрос',
    ADD_REVIEWER: 'Добавить проверяющего',
    ADD_SECTION: 'Добавить раздел',
    ADD_STAGE: 'Добавить этап',
    ADD_SUBSECTION: 'Добавить подраздел',
    ADD_TARGET_COMPETENCE: 'Добавить целевую компетенцию',
    ADD_TASK: 'Добавить задание',
    ADD_TEACHER: 'Добавить преподавателя',
    ADD_TITLE: 'Добавить заголовок',
    ADD_TUTOR: 'Добавить тьютора',
    ADD_VALUE: 'Добавить значение',
    ADD_VARIANT: 'Добавить вариант',
    ANSWER: 'Ответить',
    APPROVAL_COMPLETE: 'Завершить согласование',
    APPROVAL_SEND: 'Отправить на согласование',
    APPROVE: 'Согласовать',
    ASSIGN: 'Назначить',
    ATTACH_FILE: 'Прикрепить файл',
    BEGIN: 'Начать',
    BROWSE: 'Просмотр',
    CALCULATE: 'Рассчитать',
    CANCEL: 'Отмена',
    CHANGE_THEME: 'Сменить тему',
    CHECK: 'Проверить',
    CHOOSE_EDUCATION_PERIOD: 'Выберите сроки обучения',
    CHOOSE_EVENTS: 'Подобрать мероприятия',
    CHOOSE_POSITION_PROFILE: 'Выбрать профиль должности',
    CLOSE: 'Закрыть',
    COLLAPSE: 'Свернуть',
    COMPLETE: 'Завершить',
    COMPLETE_SURVEY: 'Завершить опрос',
    CONFIRM: 'Подтвердить',
    CONFIRM_LIST: 'Подвердить список',
    CONTINUE: 'Продолжить',
    COPY: 'Копировать',
    CREATE: 'Создать',
    CREATE_ARTICLE: 'Создать статью',
    CREATE_NEW_SECTION: 'Создать новый раздел',
    CREATE_TASK_FOR_MAILING: 'Создать задание на рассылку',
    CREATE_TEMPLATE: 'Создать шаблон',
    DELETE: 'Удалить',
    DELETE_FILE: 'Удалить файл',
    DELETE_SECTION: 'Удалить раздел',
    DESELECT: 'Снять выделение',
    DISPLAY_DATA: 'Отображать данные',
    DOCUMENT_CREATE: 'Создать документ',
    DOCUMENT_EDITOR_START: 'Запуск редактора',
    DOWNLOAD_FILE: 'Загрузить файл',
    DOWNLOAD_LOGO: 'Загрузить логотип',
    EDIT: 'Редактировать',
    EDIT_SECTION: 'Редактировать раздел',
    ESTABLISH: 'Сформировать',
    ESTABLISH_APPOINTMENT: 'Сформировать назначение',
    EXPORT: 'Экспорт',
    FAIL: 'Незачет',
    FINISH_PASSING: 'Завершить прохождение',
    FOR_REVISION: 'На доработку',
    FURTHER: 'Далее',
    GENERATE_REPORT: 'Выгрузить отчет',
    GO_TO: 'Перейти',
    GO_TO_FILE_CARD: 'Перейти в карточку файла',
    GO_TO_NEXT_COMPETENCE: 'Перейти к следующей компетенции',
    HIDE: 'Скрыть',
    HIDE_ANSWERS_HISTORY: 'Скрыть историю ответов',
    HIDE_TASK: 'Скрыть задание',
    IMPORT: 'Импорт',
    IMPORT_DICTIONARIES: 'Импорт справочников',
    IMPORT_POSITION_PROFILES: 'Импорт ПД',
    INSERT_MARKER: 'Вставить маркер',
    LAUNCH: 'Запуск',
    LIMIT_TIME: 'Ограничить время',
    MAILING_BY_ASSIGNMENT: 'Рассылки на основе задания',
    MAKE_MAIN: 'Сделать основной',
    MODAL_START: 'Запуск модального окна',
    PASSED: 'Зачет',
    PAUSE: 'Приостановить',
    PLAY: 'Воспроизвести',
    PREVIEW: 'Предпросмотр',
    PROCEED: 'Приступить',
    PUBLISH: 'Опубликовать',
    RECORD_ADD: 'Добавить запись',
    RECORD_COPY: 'Копировать запись',
    RECORD_DELETE: 'Удалить запись',
    RECORD_EDIT: 'Редактировать запись',
    RECORD_TO_ARCHIVE: 'Отправить запись в архив',
    REGISTER_IN_COURSOMETER: 'Зарегистрировать в Курсометре',
    REMIND: 'Напомнить',
    RENAME: 'Переименовать',
    REPASS_ONCE_MORE: 'Пройти ещё раз',
    REPEAT: 'Повторить',
    REPEAT_SEND: 'Повторить отправку',
    RETURN: 'Назад',
    SAVE: 'Сохранить',
    SAVE_AS_DRAFT: 'Сохранить как черновик',
    SAVE_TEMPLATE: 'Сохранить шаблон',
    SCHEDULE: 'Запланировать',
    SELECT: 'Выбрать',
    SELECT_ALL: 'Выбрать все',
    SELECT_ANOTHER_FILE: 'Выбрать другой файл',
    SELECT_FILE: 'Выбрать файл',
    SELECT_STAFF_UNIT: 'Выбрать штатную единицу',
    SEND: 'Отправить',
    SEND_APPLICATION: 'Отправить заявку',
    SEND_TO_ARCHIVE: 'Отправить в архив',
    SEND_TO_WORK: 'Отправить в работу',
    SET: 'Настроить',
    SET_ORDER: 'Настроить порядок',
    SHOW: 'Показать',
    SHOW_ALL: 'Показать все',
    SHOW_ALL_TASK: 'Просмотреть всё задание',
    SHOW_ANSWERS_HISTORY: 'Показать историю ответов',
    SHOW_MORE: 'Показать еще',
    SIGN_UP: 'Записаться',
    SIGN_UP_TO_RESERVE: 'Записаться в резерв',
    START: 'Запустить',
    STOP: 'Остановить',
    TAKE_TO_WORK: 'Взять в работу',
    TO_ARCHIVE: 'В архив',
    TO_NEXT_QUESTIONNAIRE_LIST: 'К следующему опросному листу',
    UPDATE: 'Обновить',
    UPDATE_COURSE_VERSION: 'Обновление версии курса',
    UPDATE_LIST: 'Обновить список',
    UPLOAD: 'Загрузить',
    UPLOAD_ALBUM: 'Загрузить альбом',
    UPLOAD_REPORT: 'Выгрузить отчет',
    UPLOAD_TEMPLATE: 'Загрузить шаблон',
    VIEW: 'Посмотреть',
    VIEW_REPORT: 'Посмотреть отчёт',
    WATCH_ALL: 'Смотреть все',
    WATCH_VIDEO: 'Смотреть видео',
}
