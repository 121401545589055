import React, { useEffect } from 'react'
import { Circle360ReportService } from 'core/api'
import { LOCAL } from 'core/local'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import stylesReportView from '../ReportView/ReportView.module.scss'
import { makeList } from '../ReportView/ReportView.utils'

/** Отчет по оценке 360. Комментарии респондентов */
export const CommentsOfRespondents: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(
        Circle360ReportService.getCommentsOfRespondents
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return (
        <>
            {data?.length ? (
                data.map((el) => (
                    <>
                        <h3>{el.name}</h3>

                        {makeList(el.comments)}
                    </>
                ))
            ) : (
                <p className={stylesReportView.text}>
                    {LOCAL.LABELS.COMMENTS_MISSING}
                </p>
            )}
        </>
    )
})
