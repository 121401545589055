import React from 'react'
import cn from 'classnames'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { TextAlignProperty } from 'App.types'
import { getBackgroundImageStyle, getStyles } from 'utils'

import styles from './FullScreenCanvas.module.scss'
import { CanvasProps } from '../../ActivityLayer.types'
import { FullScreenBlocks } from '../FullScreenBlocks'
import { getPassButtonText } from '../../ActivityLayer.utils'
import { useCanvas } from '../../ActivityLayer.hooks'

/**
 * Отображение для мероприятия типа - "fullscreen"
 */
export const FullScreenCanvas: React.FC<CanvasProps> = React.memo((props) => {
    const {
        activity,
        activeBlock,
        parentLessonIdForPassing,
        onSetActiveBlock,
        goBackToTrackLayer,
        onCloseNestedActivity,
    } = props

    const {
        questionnaireForm,
        activeThematicBlock,
        isThematicBlockLock,
        isThematicBlockCompletedAndNotAvailableForRepassing,
        meetingModal,
        handleStartThematicBlock,
        videoModal,
        showFurtherButton,
        finishEventModal,
        handleFurtherBtnClick,
        showReturnButton,
    } = useCanvas(props)

    const { name, announcement, canvas, activityLessonPassings } = activity

    const buttonStyles = {
        background: canvas.buttonColor,
        color: canvas.buttonTextColor,
        borderColor: canvas.buttonColor,
    }

    return (
        <div className={styles.wrapper}>
            <div
                style={getBackgroundImageStyle(canvas?.canvasBackground?.url)}
                className={styles.body}
            >
                <div>
                    {showReturnButton && (
                        <Button
                            type="link"
                            onClick={
                                parentLessonIdForPassing
                                    ? onCloseNestedActivity
                                    : goBackToTrackLayer
                            }
                            className={styles.returnButton}
                        >
                            <IconsAdapter iconType="CustomIconArrow" />
                        </Button>
                    )}

                    <h1
                        className={styles.eventTitle}
                        style={getStyles(canvas.titleSettings)}
                    >
                        {name}
                    </h1>

                    <pre
                        className={styles.eventDescription}
                        style={getStyles(canvas.descriptionSettings)}
                    >
                        {announcement}
                    </pre>
                </div>

                <h3
                    className={styles.blockName}
                    style={getStyles(canvas.blockNameSettings)}
                >
                    {activeThematicBlock?.name}
                </h3>

                <div
                    style={{
                        textAlign: canvas.buttonAlign as TextAlignProperty,
                    }}
                    className={styles.nav}
                >
                    {!isThematicBlockCompletedAndNotAvailableForRepassing &&
                        !isThematicBlockLock && (
                            <Button
                                style={buttonStyles}
                                onClick={handleStartThematicBlock}
                                className={cn(
                                    styles.startButton,
                                    'capitalize-text'
                                )}
                            >
                                {getPassButtonText(
                                    activeThematicBlock,
                                    activity.status
                                )}
                            </Button>
                        )}

                    {showFurtherButton && (
                        <Button
                            style={buttonStyles}
                            onClick={handleFurtherBtnClick}
                            className={cn(styles.nextButton, 'capitalize-text')}
                        >
                            {LOCAL.ACTIONS.FURTHER}
                        </Button>
                    )}
                </div>

                <div className={styles.blocks}>
                    <FullScreenBlocks
                        canvas={canvas}
                        blocksList={activityLessonPassings}
                        activeBlock={activeBlock}
                        onSetActiveBlock={onSetActiveBlock}
                    />
                </div>
            </div>

            {questionnaireForm}

            {meetingModal}

            {videoModal}

            {finishEventModal}
        </div>
    )
})
