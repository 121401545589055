export enum ReportShortCodes360 {
    ObjectInformationAssessment_360 = 'object_information_assessment_360',
    GroupsOfRespondents = 'groups_of_respondents',
    SpiderWebDiagramAssessment360 = 'spider_web_diagram_assessment_360',
    CompetenceRating = 'competence_rating',
    CompetenciesLowHighLevel = 'competencies_low_high_level',
    AssessmentsRespondentsIndicators = 'assessments_respondents_indicators',
    AreasStrengthsDevelopment = 'areas_strengths_development',
    HiddenOpportunitiesProblems = 'hidden_opportunities_problems',
    CommentsOfRespondents = 'comments_of_respondents',
    Percentile = 'percentile',
}

export enum ReportShortCodesManager {
    ObjectInformationManagerAssessment = 'object_information_manager_assessment',
    SpiderWebDiagramManagerAssessment = 'spider_web_diagram_manager_assessment',
    TablesCompetenciesIndicators = 'tables_competencies_indicators',
    ComplianceProfilePosition = 'compliance_profile_position',
    TableOfDevelopmentWithProfilePosition = 'table_of_development_with_profile_position',
    TableOfDevelopmentWithoutProfilePosition = 'table_of_development_without_profile_position',
}

export const ReportShortCodes = {
    ...ReportShortCodes360,
    ...ReportShortCodesManager,
}
export type ReportShortCodes = ReportShortCodes360 & ReportShortCodesManager
