import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { Form, Table } from 'antd'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { ProductCardsService } from 'core/api'
import { ShouldUpdateChecker } from 'components/controls'
import { withLoader } from 'HOCs'

import styles from './ProductListenersForm.module.scss'
import { FORM_INITIAL_VALUES } from './ProductListenersForm.consts'
import { ProductCardStudyingGroupContract } from './ProductListenersForm.types'
import { ProductListenersGroup } from '../ProductListenersGroup'
import {
    addReserveToStudyingGroups,
    getProductListenersColumns,
    isProductPeriodCompleted,
    isProductPeriodConfirmed,
    mapListenersToUpdate,
} from './ProductListenersForm.utils'

/** Форма слушателей записанных на продукт */
export const ProductListenersForm: React.FC<PopupAdapterFormProps> = withLoader(
    React.memo(({ initialValues, onRequestFinish, updateLoader }) => {
        const [form] = Form.useForm()

        const [currentGroupIndex, setCurrentGroupIndex] = useState(0)

        const fetchStudyingGroups = useCallback(async () => {
            try {
                updateLoader(true)

                const reserve = await ProductCardsService.getProductCardReserveEnrollments(
                    { productCardId: Number(initialValues?.id) }
                )

                const periods = await ProductCardsService.getProductCardListeners(
                    { productCardId: Number(initialValues?.id) }
                )

                const groups = addReserveToStudyingGroups(periods, reserve)

                form.setFieldsValue({ groups })
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [initialValues, form, updateLoader])

        const handleSelectCurrentGroup = useCallback(
            (index: number) => () => {
                setCurrentGroupIndex(index)
            },
            []
        )

        const handleConfirmListenersList = useCallback(
            (group: ProductCardStudyingGroupContract) => async () => {
                try {
                    updateLoader(true)

                    await ProductCardsService.confirmListenersInProductStudyingPeriod(
                        { productCardStudyingPeriodId: group.id }
                    )

                    fetchStudyingGroups()
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [fetchStudyingGroups, updateLoader]
        )

        const handleUpdateListeners = useCallback(
            (group: ProductCardStudyingGroupContract) => async () => {
                try {
                    updateLoader(true)

                    await ProductCardsService.updateProductCardStudyingPeriodListeners(
                        {
                            productCardStudyingPeriodId: group.id,
                            body: mapListenersToUpdate(group.listeners),
                        }
                    )

                    fetchStudyingGroups()
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [fetchStudyingGroups, updateLoader]
        )

        useEffect(() => {
            fetchStudyingGroups()
        }, [initialValues, fetchStudyingGroups])

        return (
            <Form
                onFinish={onRequestFinish}
                form={form}
                initialValues={FORM_INITIAL_VALUES}
            >
                <ShouldUpdateChecker fieldPath={['groups']}>
                    {({ getFieldValue }) => {
                        const studyingGroups:
                            | ProductCardStudyingGroupContract[]
                            | undefined = getFieldValue('groups')

                        const currentGroup = studyingGroups?.[currentGroupIndex]
                        const isConfirmed = isProductPeriodConfirmed(
                            currentGroup?.status
                        )

                        return (
                            <div>
                                <div className={styles.groups}>
                                    {studyingGroups?.map((group, index) => (
                                        <ProductListenersGroup
                                            key={index}
                                            currentGroupIndex={
                                                currentGroupIndex
                                            }
                                            group={group}
                                            index={index}
                                            onClick={handleSelectCurrentGroup(
                                                index
                                            )}
                                        />
                                    ))}
                                </div>

                                {!!currentGroup?.listeners.length && (
                                    <Table
                                        dataSource={currentGroup.listeners}
                                        className={styles.table}
                                        columns={getProductListenersColumns(
                                            currentGroupIndex,
                                            isConfirmed
                                        )}
                                        pagination={false}
                                        rowKey="id"
                                    />
                                )}

                                {currentGroup && !currentGroup?.isReserved && (
                                    <ButtonsToolbar align="flex-start">
                                        <Button
                                            onClick={handleUpdateListeners(
                                                currentGroup
                                            )}
                                            disabled={isConfirmed}
                                        >
                                            {LOCAL.ACTIONS.SAVE}
                                        </Button>

                                        <Button
                                            onClick={handleConfirmListenersList(
                                                currentGroup
                                            )}
                                            disabled={
                                                !isProductPeriodCompleted(
                                                    currentGroup?.status
                                                ) || isConfirmed
                                            }
                                        >
                                            {LOCAL.ACTIONS.CONFIRM_LIST}
                                        </Button>

                                        <Button
                                            // Реализация в рамках другой задачи
                                            onClick={() => onRequestFinish?.()}
                                            type="link"
                                            disabled={!isConfirmed}
                                        >
                                            {
                                                LOCAL.ACTIONS
                                                    .ESTABLISH_APPOINTMENT
                                            }
                                        </Button>
                                    </ButtonsToolbar>
                                )}
                            </div>
                        )
                    }}
                </ShouldUpdateChecker>
            </Form>
        )
    }),
    { onTopOfEverything: false }
)
