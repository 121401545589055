import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    CircleReportSmallContract,
    PositionProfileContract,
    StructuralUnitsService,
    UserProfilesService,
    UserStructuralUnitPositionContract,
} from 'core/api'
import { PageContent } from 'components/shared'
import { getFullFio, isStructuralUnitTypeOrganization } from 'utils'
import { withLoader } from 'HOCs'

import styles from './UserProfile.module.scss'
import {
    ComparisonData,
    UserProfileProps,
    UserProfileStateProps,
} from './UserProfile.types'
import {
    UserProfileBody,
    UserProfileCertificates,
    UserProfileFooter,
    UserProfileHeader,
    UserProfileReportForm,
} from './components'
import { UserProfileContextProvider } from './context'

/**
 * Профиль пользователя
 */
export const UserProfile: React.FC<UserProfileProps> = withLoader(
    React.memo(({ id, updateLoader }) => {
        /**
         * Стейт для хранения данных профиля пользователя
         */
        const [userProfile, setUserProfile] = useState<UserProfileStateProps>()
        const [comparisonData, setComparisonData] = useState<ComparisonData>()
        const [chosenReports, setChosenReports] = useState<number[]>([])
        const [isAvailableForCandidate, setAvailableForCandidate] = useState<
            boolean
        >()
        const [reports, setReports] = useState<CircleReportSmallContract[]>()

        /**
         * Объект, содержащий идентификатор связи пользователя с подразделениями и должностями
         */
        const [
            currentUserStructuralUnitPosition,
            setCurrentUserStructuralUnitPosition,
        ] = useState<UserStructuralUnitPositionContract>()

        /**
         * ID пользователя
         */
        const userId = useMemo(() => userProfile?.user?.id, [userProfile])

        /**
         * Обработчик смены профиля должности
         * @param positionProfile - новый профиль должности
         */
        const handleChangePositionProfile = useCallback(
            (
                userStructuralUnitPosition: UserStructuralUnitPositionContract,
                positionProfile?: PositionProfileContract
            ) => {
                setUserProfile((prevState) => ({
                    ...prevState,
                    positionProfile,
                }))
                setCurrentUserStructuralUnitPosition(userStructuralUnitPosition)
            },
            []
        )

        /**
         * Обработчик изменения поля выбора оценки (каждой оценке соответствует свое назначение)
         * @param ids идентификаторы выбранных назначений
         */
        const handleChangeChosenReports = useCallback(
            (ids: number[]) => {
                setChosenReports(ids)

                if (ids.length === 1) {
                    const [reportId] = ids
                    const report = reports?.find(
                        (report) => report.id === reportId
                    )

                    setAvailableForCandidate(
                        report?.competenciesReportAvailableForCandidate
                    )
                }
            },
            [reports]
        )

        /**
         * Обработчик загрузки отчетов
         * @param reports массив отчетов
         */
        const handleLoadReports = useCallback(
            (reports: CircleReportSmallContract[]) => {
                setReports(reports)
            },
            []
        )

        /**
         * Страницу просматривает владелец профиля
         */
        const isOwnProfile = !id

        /**
         * Запрос сотрудника
         */
        useEffect(() => {
            const coworkerFetch = async () => {
                try {
                    updateLoader(true)

                    const sourceData = await (id
                        ? UserProfilesService.getUserById({ id })
                        : UserProfilesService.getCurrentUserProfile())
                    const unitId =
                        sourceData.user.structuralUnitsPositions[0]
                            ?.structuralUnit?.id
                    const userOrganizationData = unitId
                        ? await StructuralUnitsService.getAllParentElemntsByStructuralUnitId(
                              { unitId }
                          )
                        : []

                    setCurrentUserStructuralUnitPosition(
                        sourceData?.user?.structuralUnitsPositions.find(
                            (el) => el.isMain
                        )
                    )
                    setUserProfile({
                        ...sourceData,
                        userOrganization: userOrganizationData.find(
                            (el) =>
                                isStructuralUnitTypeOrganization(el.type) &&
                                el.id !== unitId
                        ),
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }

            coworkerFetch()
        }, [updateLoader, id])

        return (
            <UserProfileContextProvider>
                <PageContent className={styles.wrapper}>
                    <UserProfileHeader
                        fullFio={getFullFio(userProfile?.user)}
                        userProfile={userProfile}
                        hideBackLink={isOwnProfile}
                    />

                    <UserProfileCertificates userId={userId} />

                    <UserProfileReportForm
                        userId={userId}
                        setComparisonData={setComparisonData}
                        onChangeChosenReports={handleChangeChosenReports}
                        onLoadReports={handleLoadReports}
                        isOwnProfile={isOwnProfile}
                    />

                    <UserProfileBody
                        chosenReports={chosenReports}
                        handleChangePositionProfile={
                            handleChangePositionProfile
                        }
                        isOwnProfile={isOwnProfile}
                        setAvailableForCandidate={setAvailableForCandidate}
                        currentUserId={userId}
                        comparisonData={comparisonData}
                        currentUserStructuralUnitPosition={
                            currentUserStructuralUnitPosition
                        }
                        isAvailableForCandidate={isAvailableForCandidate}
                        userProfile={userProfile}
                    />

                    <UserProfileFooter userProfile={userProfile} />
                </PageContent>
            </UserProfileContextProvider>
        )
    })
)
