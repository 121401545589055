import React from 'react'
import cn from 'classnames'

import styles from './ReportsGroup.module.scss'
import { IconsAdapter } from '../IconsAdapter'
import { ReportsGroupProps } from './ReportsGroup.types'
import { Title } from '../Title'
import { TooltipAdapter } from '../TooltipAdapter'

/**
 * Группировка для отчетов
 */
export const ReportsGroup: React.FC<ReportsGroupProps> = React.memo(
    ({
        children,
        className,
        title,
        haveInfoIcon,
        infoText,
        groupHeaderNoPadding,
    }) => (
        <div className={cn(styles.wrapper, className)}>
            {title && (
                <Title
                    type="h5"
                    text={
                        <>
                            {title}

                            {haveInfoIcon && (
                                <TooltipAdapter title={infoText}>
                                    <IconsAdapter iconType="QuestionCircleOutlined" />
                                </TooltipAdapter>
                            )}
                        </>
                    }
                    className={cn(
                        styles.title,
                        groupHeaderNoPadding && styles.titleNoPadding
                    )}
                />
            )}

            {children}
        </div>
    )
)
