import React from 'react'
import {
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS } from 'consts'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { MissionSearchResultContract, UserPermission } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TableActionsCommonProps } from 'App.types'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

/** Компонент для создания колонки действий внутри таблицы реестра заданий */
export const MaterialTasksTableActions: React.FC<TableActionsCommonProps<
    MissionSearchResultContract
>> = React.memo(({ tableRow }) => (
    <ButtonsToolbar bottomIndent="0">
        <TableActions.Edit
            requiredPermissions={[
                ...DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS,
                UserPermission.TextDocumentSave,
            ]}
            href={`${ROUTE_NAMES.TASKS_EDIT}/${tableRow.id}`}
        />

        <HasPermissions
            requiredPermissions={[
                ...DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS,
                UserPermission.TextDocumentDelete,
            ]}
            accessDeniedRender={renderAccessDeniedButton({
                type: 'link',
                icon: <IconsAdapter iconType="DeleteOutlined" />,
            })}
        >
            <PopupWithForm
                formId={FORM_IDS.CONFIRM_DELETE_FORM}
                buttonText=""
                havePopup={false}
                buttonOption={{
                    title: LOCAL.ACTIONS.DELETE,
                    type: 'link',
                    icon: <IconsAdapter iconType="DeleteOutlined" />,
                }}
                formOptions={{
                    initialValues: tableRow,
                }}
                modalOptions={{
                    title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                    okText: LOCAL.YES,
                }}
            />
        </HasPermissions>
    </ButtonsToolbar>
))
