import { FnActionRequiredProps } from 'App.types'
import { eventEmitter } from 'core/helpers/eventEmitter'

import { MapFormDataToRequestProps } from './TrackForm.types'
import { TRACK_STAGE_DND_EVENTS } from './components/shared/StagesDndArea/TrackStageDndHelper'

export const UPDATE_STATE_OF_PREVIEW_BTN_EVENT = {
    UPDATE_START: 'UPDATE_START',
}

export const registerUpdateStateOfPreviewBtn = (
    handleUpdateStateOfPreviewBtn: FnActionRequiredProps<
        MapFormDataToRequestProps
    >
) => {
    eventEmitter.on(
        TRACK_STAGE_DND_EVENTS.REMOVE_STAGE,
        handleUpdateStateOfPreviewBtn
    )
    eventEmitter.on(
        TRACK_STAGE_DND_EVENTS.DRAG_FINISH,
        handleUpdateStateOfPreviewBtn
    )
}

export const unRegisterUpdateStateOfPreviewBtn = (
    handleUpdateStateOfPreviewBtn: FnActionRequiredProps<
        MapFormDataToRequestProps
    >
) => {
    eventEmitter.off(
        TRACK_STAGE_DND_EVENTS.REMOVE_STAGE,
        handleUpdateStateOfPreviewBtn
    )
    eventEmitter.off(
        TRACK_STAGE_DND_EVENTS.DRAG_FINISH,
        handleUpdateStateOfPreviewBtn
    )
}
