import React, { FC } from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    SupervisingComplexContract,
    SupervisingComplexSearchContract,
    SupervisingComplexesService,
} from 'core/api'
import { getRouteMeta } from 'routing/routeNames.utils'

import { SUPERVISING_COMPLEXES_TABLE_COLUMNS } from './SupervisingComplexes.consts'
import { SupervisingComplexesFilter } from './components'

/** Компонент отображения справочника курирующих комплексов */
export const SupervisingComplexes: FC = React.memo(() => (
    <DictionaryPage<
        SupervisingComplexSearchContract,
        SupervisingComplexContract
    >
        dictionaryKey={DICTIONARY_KEYS.SUPERVISING_COMPLEXES}
        searchMethod={SupervisingComplexesService.search}
        contentTitle={getRouteMeta(ROUTE_NAMES.SUPERVISING_COMPLEXES)}
        tableColumns={SUPERVISING_COMPLEXES_TABLE_COLUMNS}
        filterComponent={SupervisingComplexesFilter}
    />
))
