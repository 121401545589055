import uniqueId from 'lodash/uniqueId'
import { AttachmentContract } from 'core/api'

export const generatePictureFromResponse = (picture: AttachmentContract) => {
    if (!picture) return null

    const imageType = picture.fileName.indexOf('png') !== -1 ? 'png' : 'jpeg'

    return [
        {
            response: picture,
            name: picture.fileName,
            type: `image/${imageType}`,
            uid: uniqueId(),
        },
    ]
}
