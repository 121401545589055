import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DATE_FORMAT, MAILING_STATUSES, MAILING_TYPES } from 'consts'
import { DateProps, getDateByFormat, restrictBigText } from 'utils'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { LOCAL } from 'core/local'
import {
    NotificationMailingInfoSearchResultContract,
    NotificationMailingInfoStatus,
} from 'core/api'
import { WidthRestrictedElement } from 'components/shared'

import styles from './DistributionMailing.module.scss'
import { DistributionMailingTableActions } from './components'

export const getDistributionMailingColumns = ({
    reFetchDictionary,
}: DictionaryPageActionsProps): ColumnsType<
    NotificationMailingInfoSearchResultContract
> => [
    {
        title: LOCAL.LABELS.NAME_MAILING_INFORMATION,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: (
            <div className={styles.columnTitle}>
                {LOCAL.LABELS.MAILING_STATISTICS}
            </div>
        ),
        key: 'counter',
        render: ({
            recipientCount,
            createdEmailCount,
            sendedEmailCount,
        }: NotificationMailingInfoSearchResultContract) =>
            `${recipientCount} / ${createdEmailCount} / ${sendedEmailCount}`,
    },
    {
        title: LOCAL.LABELS.MAILING_TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (value, record) => (
            <WidthRestrictedElement title={MAILING_TYPES[record.type]} />
        ),
    },
    {
        title: LOCAL.LABELS.SPECIFIED_DATE_TIME,
        dataIndex: 'mailingDate',
        key: 'mailingDate',
        sorter: defaultSorter,
        defaultSortOrder: 'descend',
        render: (date: DateProps) =>
            getDateByFormat(date, DATE_FORMAT.WITH_TIME),
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status: NotificationMailingInfoStatus) =>
            MAILING_STATUSES[status],
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.CHANGE_DATE_TIME,
        dataIndex: 'changeDate',
        key: 'changeDate',
        sorter: defaultSorter,
        render: (date: DateProps) =>
            getDateByFormat(date, DATE_FORMAT.WITH_TIME),
        fixed: 'right',
    },
    {
        render: (tableRow: NotificationMailingInfoSearchResultContract) => (
            <DistributionMailingTableActions
                tableRow={tableRow}
                reFetchDictionary={reFetchDictionary}
            />
        ),
        fixed: 'right',
    },
]
