import React, { ReactText, useCallback } from 'react'
import { ProductType } from 'core/api'
import { RadioButtonControl } from 'components/controls'
import { isFormModeView } from 'utils'

import styles from '../ProductCatalogForm/ProductCatalogForm.module.scss'
import { PRODUCT_TYPES_VALUES } from './ProductTypeControl.consts'
import { ProductTypeControlProps } from './ProductTypeControl.types'

/** Контрол для выбора типа продукта внутри формы создания/редактирования карточки продукта */
export const ProductTypeControl: React.FC<ProductTypeControlProps> = React.memo(
    ({ value, onChange, formMode }) => {
        /**
         * Изменяем значение типа для поля данного контрола в форме
         * @param type - текущий выбранный пользователем тип для контрола
         */
        const handleChangeCurrentType = useCallback(
            (type?: ReactText) => {
                if (!type) return

                onChange?.(type as ProductType)
            },
            [onChange]
        )

        return (
            <section className={styles.productType}>
                <RadioButtonControl
                    values={PRODUCT_TYPES_VALUES}
                    buttonStyle="solid"
                    value={value}
                    onChange={handleChangeCurrentType}
                    disabled={isFormModeView(formMode)}
                />
            </section>
        )
    }
)
