import React from 'react'

import { AnswerFieldProps } from '../QuestionnairePassingAnswer/QuestionnairePassingAnswer.types'
import { getQuestionTextForPassing } from './PasteTextPassing.utils'

/**
 * Секция вопроса типа "Вставить пропущенный текст"
 */
export const PasteTextPassing: React.FC<AnswerFieldProps> = React.memo(
    ({ text, fieldName }) => <>{getQuestionTextForPassing(text, fieldName)}</>
)
