import React, { useCallback, useEffect } from 'react'
import { Col, Form, Row } from 'antd'
import {
    FormItemAdapter,
    HiddenField,
    RangePickerControl,
} from 'components/controls'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import {
    ProductCardContract,
    ProductCardPublishContract,
    ProductCardsService,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ROW_GUTTER } from 'consts'
import { WithLoaderProps } from 'HOCs'
import {
    createConfirmPopup,
    disableDate,
    getFormPopupTitle,
    getInitialValuesForCopy,
    isFormModeCopy,
    isFormModeView,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'

import styles from './ProductCatalogForm.module.scss'
import {
    PRODUCT_CATALOG_FORM_ID,
    PRODUCT_CATALOG_FORM_INITIAL_VALUES,
} from './ProductCatalogForm.consts'
import { ProductAssignments } from '../ProductAssignments'
import { ProductAvailability } from '../ProductAvailability'
import { ProductContent } from '../ProductContent'
import { ProductDescription } from '../ProductDescription'
import { ProductImage } from '../ProductImage'
import { ProductLearning } from '../ProductLearning'
import { ProductLearningSchedule } from '../ProductLearningSchedule'
import { ProductProgram } from '../ProductProgram'
import { ProductTeachers } from '../ProductTeachers'
import { ProductTypeControl } from '../ProductTypeControl'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
} from './ProductCatalogForm.utils'

/** Компонент формы для создания/редактирования карточки продукта в каталоге */
export const ProductCatalogForm: React.FC<
    FormProps<ProductCardContract> & WithLoaderProps
> = React.memo(
    ({
        onBlockUserRouting,
        initialValuesForEdit,
        isSaveDocument,
        reFetchInitialFormValues,
        formMode,
        updateLoader,
    }) => {
        const history = useHistory()
        const [form] = Form.useForm()

        const afterSubmitFn = useCallback(
            (productId?: number) => {
                onBlockUserRouting?.(false)

                createConfirmPopup({
                    title: getFormPopupTitle(
                        getRouteMeta(ROUTE_NAMES.PRODUCT_CATALOG, 'pageTitle')
                    ),
                    content: isSaveDocument
                        ? LOCAL.MESSAGES.PRODUCT_CARD_SAVE_SUCCESS_MESSAGE
                        : LOCAL.MESSAGES.PRODUCT_CARD_PUBLISH_SUCCESS_MESSAGE,
                    onOk: () => {
                        history.push(ROUTE_NAMES.PRODUCT_CATALOG)
                    },
                    onCancel: () => {
                        if (!initialValuesForEdit?.id) {
                            form.resetFields()

                            return
                        }

                        if (isFormModeCopy(formMode)) {
                            history.push(
                                `${ROUTE_NAMES.PRODUCT_CATALOG_EDIT}/${productId}`
                            )
                        } else {
                            reFetchInitialFormValues?.(initialValuesForEdit?.id)
                        }
                    },
                })
            },
            [
                form,
                history,
                initialValuesForEdit,
                isSaveDocument,
                onBlockUserRouting,
                reFetchInitialFormValues,
                formMode,
            ]
        )

        const confirmPublish = useCallback(
            async (body: ProductCardPublishContract) => {
                const result = await ProductCardsService.publish({
                    body,
                })

                afterSubmitFn(result.id)
            },
            [afterSubmitFn]
        )

        const publishProductCard = useCallback(
            async (body: ProductCardPublishContract) => {
                const hasProduct =
                    body.activityId || body.courseId || body.trackId

                if (hasProduct) {
                    const validate = await ProductCardsService.validate({
                        body,
                    })

                    if (!validate.result) {
                        createConfirmPopup({
                            content: LOCAL.MESSAGES.PRODUCT_IS_ALREADY_EXIST,
                            onOk: () => confirmPublish(body),
                        })

                        return
                    }
                }

                await confirmPublish(body)
            },
            [confirmPublish]
        )

        const handleTypeChange = useCallback(() => {
            form.resetFields(['studyingPeriods'])
        }, [form])

        /**
         * Отправка формы на сервер
         * @param values значения формы
         */
        const handleFinish = useCallback(
            async ({ id, ...restValues }) => {
                try {
                    updateLoader(true)

                    const body = {
                        ...mapFormDataToRequest(restValues),
                        id: !isFormModeCopy(formMode) ? id : undefined,
                    } as ProductCardPublishContract

                    if (isSaveDocument) {
                        const result = await ProductCardsService.save({
                            body,
                        })

                        afterSubmitFn(result.id)
                    } else {
                        await publishProductCard(body)
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [
                updateLoader,
                formMode,
                isSaveDocument,
                afterSubmitFn,
                publishProductCard,
            ]
        )

        /**
         * Обработчик изменений формы
         */
        const handleValuesChange = useCallback(() => {
            if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                onBlockUserRouting?.()
            }
        }, [form, formMode, onBlockUserRouting])

        useEffect(() => {
            if (initialValuesForEdit?.id) {
                form.setFieldsValue(
                    mapResponseToFormData(
                        getInitialValuesForCopy(initialValuesForEdit, formMode)
                    )
                )
            }
        }, [initialValuesForEdit, form, formMode])

        return (
            <Form
                id={PRODUCT_CATALOG_FORM_ID}
                form={form}
                onValuesChange={handleValuesChange}
                onFinish={handleFinish}
                initialValues={PRODUCT_CATALOG_FORM_INITIAL_VALUES}
            >
                <HiddenField fieldName="id" />

                <Row gutter={ROW_GUTTER}>
                    <Col xs={16}>
                        <Row align="middle">
                            <Col xs={16}>
                                <FormItemAdapter
                                    fieldName="type"
                                    label={LOCAL.LABELS.PRODUCT_SHAPE}
                                >
                                    <ProductTypeControl
                                        formMode={formMode}
                                        onChange={handleTypeChange}
                                    />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={8}>
                                <FormItemAdapter
                                    fieldName="placementDate"
                                    label={
                                        LOCAL.LABELS.PLACEMENT_PERIOD_IN_CATALOG
                                    }
                                    required
                                >
                                    <RangePickerControl
                                        formMode={formMode}
                                        disabledDate={disableDate()}
                                    />
                                </FormItemAdapter>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={ROW_GUTTER}>
                    <Col md={24} xl={16}>
                        <ProductDescription formMode={formMode} />

                        <ProductContent formMode={formMode} />

                        <ProductTeachers formMode={formMode} />

                        <ProductAvailability
                            formMode={formMode}
                            updateLoader={updateLoader}
                        />

                        <ProductLearning formMode={formMode} />
                    </Col>

                    <Col md={24} xl={8}>
                        <Row gutter={ROW_GUTTER} className={styles.rightCol}>
                            <Col xs={24}>
                                <ProductImage formMode={formMode} />

                                <ProductProgram formMode={formMode} />

                                <ProductLearningSchedule formMode={formMode} />

                                {initialValuesForEdit?.autoAppointment && (
                                    <ProductAssignments
                                        autoAppointment={
                                            initialValuesForEdit.autoAppointment
                                        }
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        )
    }
)
