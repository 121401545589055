import {
    REPORT_LEVELS_MAX,
    REPORT_LEVELS_TABLES_OPTIONS,
    ReportLevels,
} from 'consts'

// Консты для читаемости
const OPTIONS_LOW = REPORT_LEVELS_TABLES_OPTIONS[ReportLevels.Low]
const OPTIONS_MEDIUM = REPORT_LEVELS_TABLES_OPTIONS[ReportLevels.Medium]
const OPTIONS_HIGH = REPORT_LEVELS_TABLES_OPTIONS[ReportLevels.High]

// Проценты уровней, относительно половины линии
const lowPercentage = (OPTIONS_LOW.SCORE[1] / REPORT_LEVELS_MAX) * 50
const mediumPercentage =
    ((OPTIONS_MEDIUM.SCORE[1] - OPTIONS_LOW.SCORE[1]) / REPORT_LEVELS_MAX) * 50
const highPercentage =
    ((OPTIONS_HIGH.SCORE[1] - OPTIONS_MEDIUM.SCORE[1]) / REPORT_LEVELS_MAX) * 50

// Рисуем градиент на основе таблицы оценок по баллам
export const dividerBackground = `linear-gradient(
to right,
var(--report_360_competence_rating_divider) 0% 50%,
${OPTIONS_LOW.COLOR} 50% ${50 + lowPercentage}%,
${OPTIONS_MEDIUM.COLOR} ${50 + lowPercentage}% ${
    50 + lowPercentage + mediumPercentage
}%,
${OPTIONS_HIGH.COLOR} ${50 + lowPercentage + mediumPercentage}% ${
    50 + lowPercentage + mediumPercentage + highPercentage
}%
)`
