import { YourDevelopmentPageItemContractPageContract } from 'core/api'

export enum PanelType {
    Tracks = 'Tracks',
    Activities = 'Activities',
    PassedTracksAndActivities = 'PassedTracksAndActivities',
}

export type PaginationOptionsType = {
    pageSize: number
    pageNumber: number
}

export type PaginationType = {
    [keyof in PanelType]: PaginationOptionsType
}

export type AppointmentsStateType = {
    [keyof in PanelType]?: YourDevelopmentPageItemContractPageContract
}

export interface FetchAppointmentsProps {
    panelType: PanelType
    isInit?: boolean
}
