import { CatalogService } from 'core/api'
import { LOCAL } from 'core/local'
import { showMessageWithDelayAfter } from 'utils'

interface ToggleFavoriteStatusProps {
    itemId: number
    isFavorite: boolean
}
/**
 * Обработчик добавления/удаления мероприятия в избранное
 * @param itemId - id курса
 * @param isFavorite - флаг присутствия в "избранном"
 */
export const toggleFavoriteStatus = async ({
    itemId,
    isFavorite,
}: ToggleFavoriteStatusProps) => {
    try {
        await CatalogService.toggleProductCardFavoriteAsync({
            productCardId: itemId,
        })

        showMessageWithDelayAfter({
            messageText: isFavorite
                ? LOCAL.MESSAGES.REMOVE_FROM_FAVORITES
                : LOCAL.MESSAGES.ADD_TO_FAVORITES,
        })
    } catch (error) {
        console.error(error)
        showMessageWithDelayAfter({
            messageText: LOCAL.MESSAGES.FAVORITES_REQUEST_ERROR,
            messageType: 'error',
            delay: 1000,
        })
    }
}
