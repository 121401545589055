import DynamicTableAdapter from 'components/shared/DynamicTableAdapter/DynamicTableAdapter'
import React from 'react'
import { AddButton } from 'components/shared'
import { CoworkersFilters, getCoworkersTableColumns } from 'components/controls'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import {
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'

import styles from '../PortfolioAssessmentObjects/PortfolioAssessmentObjects.module.scss'
import {
    PortfolioAssessmentObjectsRespondentsProps,
    UserContractWithAssessmentRole,
} from './PortfolioAssessmentObjectsRespondents.types'
import {
    normalizeRespondentsData,
    renderAssessmentObjectRespondentsColumns,
    renderRespondentsControlTitle,
} from './PortfolioAssessmentObjectsRespondents.utils'

/** Компонент слушателей объекта оценки */
export const PortfolioAssessmentObjectsRespondents: React.FC<PortfolioAssessmentObjectsRespondentsProps> = React.memo(
    ({ field, formMode }) => (
        <Form.Item name={[field.name, 'respondents']}>
            <DynamicTableAdapter<
                UserContractWithAssessmentRole,
                UserContractWithAssessmentRole,
                UserModalSearchContract,
                UserModalContract
            >
                formMode={formMode}
                getEntitiesByIds={UsersService.getByIds}
                searchMethod={UsersService.getForModal}
                modalFilterComponent={CoworkersFilters}
                columnsForSearchModal={getCoworkersTableColumns()}
                tableOptions={{
                    pagination: false,
                }}
                renderTableColumns={renderAssessmentObjectRespondentsColumns({
                    formMode,
                })}
                modalTitle={LOCAL.LABELS.RESPONDENTS_ADD}
                className={styles.respondents}
                normalizeFn={normalizeRespondentsData}
                customAddButtonRender={({
                    isLoading,
                    disabled,
                    isModeView,
                }) => (
                    <AddButton
                        buttonText=""
                        className={styles.respondentsAddBtn}
                        disabled={disabled || isModeView || isLoading}
                        loading={isLoading}
                    />
                )}
                renderTitle={renderRespondentsControlTitle}
            />
        </Form.Item>
    )
)
