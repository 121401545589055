import cn from 'classnames'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Popup } from 'components/shared/Popup'
import { QuestionnaireModal } from 'components/shared/QuestionnaireModal'
import { TrackPassingsService, TrackQuestionnaireContract } from 'core/api'
import { getMaterialLaunchUrl, isAppointmentTrackStatusPassed } from 'utils'
import { useElementVisible } from 'hooks'
import { useParams } from 'react-router-dom'

import styles from './TrackInfoMenu.module.scss'
import { MenuItemWithSpoiler } from '../MenuSpoiler'
import { QuizForm } from './QuizForm'
import { TrackContentMenu } from '../TrackContentMenu'
import { TrackInfoMenuVideo } from './TrackInfoMenuVideo'
import { TrackPassingContext } from '../../TrackPassing.context'
import { TrackPassingUrlProps } from '../../TrackPassing.types'

/** Компонент дополнительной информации о треке адаптации */
export const TrackInfoMenu: React.FC = React.memo(() => {
    const urlParams = useParams<TrackPassingUrlProps>()
    const appointmentId = Number(urlParams.id)
    const [questions, setQuestions] = useState<TrackQuestionnaireContract>()
    const { store } = useContext(TrackPassingContext)
    const { trackInfo } = store

    const {
        elementVisible: contentVisible,
        toggleElementVisible: toggleContent,
        handleHideElement: hideContent,
    } = useElementVisible()

    const {
        elementVisible: menuVisible,
        toggleElementVisible: toggleMenu,
    } = useElementVisible(true)

    const {
        handleHideElement: hideFeedbackForm,
        handleShowElement: showFeedbackForm,
        elementVisible: feedbackFromVisible,
    } = useElementVisible()

    const onShowFeedbackForm = useCallback(async () => {
        try {
            setQuestions(
                await TrackPassingsService.getQuestions({
                    appointmentId,
                })
            )

            showFeedbackForm()
        } catch (error) {
            console.error(error)
        }
    }, [appointmentId, showFeedbackForm])

    const trackMenuVisualization = trackInfo?.trackMenuVisualization

    const textStyle = {
        color: trackMenuVisualization?.menuTextSettings?.textColor,
        fontName: trackMenuVisualization?.menuTextSettings?.fontName,
    }

    const trackMenuBackgroundStyle = {
        background: trackMenuVisualization?.backgroundColor,
    }

    useEffect(() => {
        const isAllStagesPassed = trackInfo?.stages.every((el) =>
            isAppointmentTrackStatusPassed(el.status)
        )

        if (
            isAllStagesPassed &&
            !trackInfo?.isQuestionnairePassed &&
            trackInfo?.trackAdditionalInfo?.questionnaire
        )
            onShowFeedbackForm()
    }, [trackInfo, onShowFeedbackForm])

    return (
        <div
            className={cn(styles.wrapper, menuVisible && styles.wrapperHidden)}
        >
            <div
                onClick={toggleMenu}
                className={styles.toggle}
                style={{
                    ...trackMenuBackgroundStyle,
                    ...textStyle,
                }}
            >
                <span hidden={!menuVisible}>{LOCAL.LABELS.MENU}</span>

                <IconsAdapter hidden={menuVisible} iconType="CloseOutlined" />
            </div>

            <ul className={styles.list} style={trackMenuBackgroundStyle}>
                <li className={styles.listItem} style={textStyle}>
                    {LOCAL.LABELS.MENU}
                </li>

                {trackMenuVisualization?.isContentsVisible && (
                    <li
                        onClick={toggleContent}
                        className={cn(styles.listItem, 'pointer')}
                        style={textStyle}
                    >
                        {LOCAL.LABELS.SUBSTANCE}
                    </li>
                )}

                <MenuItemWithSpoiler
                    title={LOCAL.LABELS.MATERIALS}
                    textStyle={textStyle}
                    itemCount={
                        trackInfo?.trackAdditionalInfo?.documents?.length
                    }
                >
                    <div className={styles.listItemContent} style={textStyle}>
                        {trackInfo?.trackAdditionalInfo?.documents?.map(
                            (el) => (
                                <a
                                    className={styles.materialLink}
                                    href={getMaterialLaunchUrl(el.launchUrl)}
                                    key={el.id}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={textStyle}
                                >
                                    <span>{el.name}</span>

                                    <IconsAdapter iconType="CustomIconPdfFile" />
                                </a>
                            )
                        )}
                    </div>
                </MenuItemWithSpoiler>

                <MenuItemWithSpoiler
                    title={LOCAL.LABELS.VIDEO}
                    textStyle={textStyle}
                    itemCount={trackInfo?.trackAdditionalInfo?.videos?.length}
                >
                    <div className={styles.listItemContent} style={textStyle}>
                        {trackInfo?.trackAdditionalInfo?.videos?.map((el) => (
                            <TrackInfoMenuVideo
                                launchUrl={el.launchUrl}
                                key={el.id}
                            >
                                {el.name}
                            </TrackInfoMenuVideo>
                        ))}
                    </div>
                </MenuItemWithSpoiler>

                <MenuItemWithSpoiler
                    title={LOCAL.LABELS.QUIZ}
                    textStyle={textStyle}
                    itemCount={
                        trackInfo?.trackAdditionalInfo?.questionnaire
                            ? 1
                            : undefined
                    }
                >
                    {trackInfo?.trackAdditionalInfo?.questionnaire && (
                        <div
                            className={cn(
                                styles.listItemContent,
                                styles.materialLink
                            )}
                            style={textStyle}
                            onClick={onShowFeedbackForm}
                        >
                            <span className={styles.itemName}>
                                {
                                    trackInfo?.trackAdditionalInfo
                                        ?.questionnaire.name
                                }
                            </span>

                            <IconsAdapter iconType="BarsOutlined" />
                        </div>
                    )}
                </MenuItemWithSpoiler>

                {!!trackMenuVisualization?.isHomeWorksVisible && (
                    <MenuItemWithSpoiler
                        title={LOCAL.LABELS.HOMEWORK}
                        textStyle={textStyle}
                    />
                )}
            </ul>

            {feedbackFromVisible &&
                trackInfo?.trackAdditionalInfo?.questionnaire?.name && (
                    <QuestionnaireModal
                        initialValues={{
                            id: appointmentId,
                            name:
                                trackInfo?.trackAdditionalInfo.questionnaire
                                    .name,
                            questions: questions?.questions,
                            currentQuestionNumber:
                                questions?.currentQuestionNumber,
                        }}
                        onClose={hideFeedbackForm}
                        componentWrapper={QuizForm}
                    />
                )}

            <Popup
                visible={contentVisible}
                footer={null}
                title={LOCAL.LABELS.SUBSTANCE}
                onCancel={hideContent}
            >
                <TrackContentMenu onHideContent={hideContent} />
            </Popup>
        </div>
    )
})
