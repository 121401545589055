import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import {
    DATE_FORMAT,
    MAILING_STATUSES_MAPPED,
    MAILING_TYPES_MAPPED,
    ROW_GUTTER,
} from 'consts'
import {
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { NotificationMailingTasksService } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { createConfirmPopup } from 'utils'

import styles from './DistributionMailingViewFields.module.scss'
import { DistributionMailingViewFieldsProps } from './DistributionMailingViewFields.types'

/** Поля с данными рассылки */
export const DistributionMailingViewFields: React.FC<DistributionMailingViewFieldsProps> = React.memo(
    ({ formMode }) => {
        const handleView = useCallback((id: number) => {
            const windowOpen = () => {
                window.open(
                    `${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_VIEW}/${id}`,
                    '_blank'
                )
            }

            NotificationMailingTasksService.checkIsChangedTask({ id }).then(
                (res) => {
                    if (res?.value) {
                        createConfirmPopup({
                            onOk: () => {
                                windowOpen()
                            },
                            title: LOCAL.MESSAGES.ATTENTION,
                            okText: LOCAL.YES,
                            cancelText: LOCAL.NO,
                            content:
                                LOCAL.MESSAGES.VIEW_JOB_FOR_MAILING_MESSAGE,
                        })
                    } else {
                        windowOpen()
                    }
                }
            )
        }, [])

        return (
            <Row gutter={ROW_GUTTER}>
                <Col xs={5}>
                    <FormItemAdapter
                        fieldName="status"
                        label={LOCAL.LABELS.MAILING_STATUS}
                    >
                        <SelectControl
                            values={MAILING_STATUSES_MAPPED}
                            formMode={formMode}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={7}>
                    <FormItemAdapter
                        fieldName="changeDate"
                        label={LOCAL.LABELS.STATUS_CHANGE_TIME}
                    >
                        <DatePickerControl
                            formMode={formMode}
                            format={[DATE_FORMAT.WITH_TIME]}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="emailsStatistics"
                        label={LOCAL.LABELS.MAILING_INFORMATION}
                    >
                        <InputControl formMode={formMode} />
                    </FormItemAdapter>
                </Col>

                <Col xs={5}>
                    <FormItemAdapter
                        fieldName="type"
                        label={LOCAL.LABELS.MAILING_TYPE}
                    >
                        <SelectControl
                            values={MAILING_TYPES_MAPPED}
                            formMode={formMode}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={7}>
                    <FormItemAdapter
                        fieldName="mailingDate"
                        label={LOCAL.LABELS.DISPATCH_TIME_PLANNED}
                    >
                        <DatePickerControl
                            formMode={formMode}
                            format={[DATE_FORMAT.WITH_TIME]}
                        />
                    </FormItemAdapter>
                </Col>

                <ShouldUpdateChecker fieldPath={['task', 'id']}>
                    {({ getFieldValue }) => {
                        const handleClick = () => {
                            handleView(getFieldValue(['task', 'id']))
                        }

                        return (
                            <Col xs={12}>
                                <FormItemAdapter
                                    fieldName={['task', 'name']}
                                    label={LOCAL.LABELS.MAILING_ASSIGNMENT}
                                >
                                    <InputControl
                                        className={styles.name}
                                        formMode={formMode}
                                        addonBefore={
                                            <Button
                                                icon={
                                                    <IconsAdapter iconType="EyeOutlined" />
                                                }
                                                type="link"
                                                onClick={handleClick}
                                            />
                                        }
                                    />
                                </FormItemAdapter>
                            </Col>
                        )
                    }}
                </ShouldUpdateChecker>
            </Row>
        )
    }
)
