import React, { FC, memo, useContext } from 'react'
import { Button } from 'components/shared/Button'
import { LOCAL } from 'core/local'
import { UserContext } from 'contexts'
import { getStyles } from 'utils'

import styles from './PassingStage.module.scss'
import { PassingIconBlock } from '../PassingIconBlock'
import { PassingStageProps } from './PassingStage.types'

/** Компонент стадии для прохождения назначения */
export const PassingStage: FC<PassingStageProps> = memo(
    ({
        showGreeting,
        buttonText,
        onButtonClick,
        iconBlocksProps,
        presentationSettings,
        header,
    }) => {
        const { userData } = useContext(UserContext)

        return (
            <div className={styles.wrapper}>
                {header}

                <div className={styles.body}>
                    <div
                        style={getStyles(
                            presentationSettings?.messageTextSettings
                        )}
                        className={styles.messageText}
                    >
                        {showGreeting && (
                            <div>{`${LOCAL.RESPECTED} ${userData?.firstName}!`}</div>
                        )}

                        {presentationSettings?.message ??
                            LOCAL.MESSAGES.NEED_TO_PASS_ALL_ANSWERS}
                    </div>

                    <div className={styles.icons}>
                        {iconBlocksProps?.map((props, index) => (
                            <PassingIconBlock key={index} {...props} />
                        ))}
                    </div>

                    <Button onClick={onButtonClick} className={styles.button}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        )
    }
)
