import React from 'react'
import { Anchor } from 'antd'
import { AnchorDecorator } from 'components/shared'
import { ControlGroup } from 'components/controls'

import styles from './ReportContents.module.scss'
import { ANCHOR_OFFSET } from './ReportContents.consts'
import { ReportContentsProps } from './ReportContents.types'

/** Отчет по оценки. Содержание */
export const ReportContents: React.FC<ReportContentsProps> = React.memo(
    ({ template }) => (
        <AnchorDecorator offsetTop={ANCHOR_OFFSET}>
            <ControlGroup type="light" className={styles.controlGroup}>
                {template?.map((el) => (
                    <Anchor.Link
                        href={`#${el.id}`}
                        title={el.name}
                        key={el.id}
                        className={styles.name}
                    />
                ))}
            </ControlGroup>
        </AnchorDecorator>
    )
)
