import React from 'react'
import {
    PassingFormQuestionAnswerContract,
    QuestionPublicContract,
} from 'core/api'
import { SpaceAdapter, Title } from 'components/shared'

import styles from './PassingNavigation.module.scss'

export const renderListTItle = (
    list: QuestionPublicContract[],
    confirmedAnswers: PassingFormQuestionAnswerContract[]
) => {
    const confirmedQuantity = list.filter((question) =>
        confirmedAnswers.find((el) => el.questionId === question.id)
    ).length

    return (
        <SpaceAdapter fullWidth justifyContent="space-between" align="center">
            <Title
                bottomIndent="0"
                type="h5"
                text={list[0].questionnaire.name}
            />

            <p
                className={styles.text}
            >{`${confirmedQuantity}/${list.length}`}</p>
        </SpaceAdapter>
    )
}
