import React, { ReactNode } from 'react'

import { SpoilerTitleRenderProps } from './Spoiler.types'

/**
 * Рендер для заголовка спойлера
 * @param text текст заголовка
 * @param icon иконка заголовка
 * @param iconPosition
 * @param toggleSpoilerVisible
 * @returns {ReactNode} содержимое заголовка спойлера
 */
export const spoilerTitleRenderDefault = ({
    text,
    icon,
    iconPosition,
    toggleSpoilerVisible,
}: SpoilerTitleRenderProps): ReactNode => (
    <a href="#s" onClick={toggleSpoilerVisible}>
        {iconPosition === 'left' && icon}

        {text}

        {iconPosition === 'right' && icon}
    </a>
)
