import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { NotificationMailingTaskAppointmentSearchResultContract } from 'core/api'
import { formatDate } from 'utils'

/** Настройка отображения колонок для таблицы назначений*/
export const APPOINTMENTS_TABLE_COLUMNS: ColumnsType<NotificationMailingTaskAppointmentSearchResultContract> = [
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'dateStart',
        key: 'dateStart',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'dateEnd',
        key: 'dateEnd',
        render: formatDate,
    },
]
