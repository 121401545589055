import React from 'react'
import { LOCAL } from 'core/local'
import { isNumber } from 'lodash'
import { secondCountToTimeString } from 'utils'

import styles from './CommonStatsAvgTime.module.scss'
import { CommonStatsAvgTimeProps } from './CommonStatsAvgTime.types'

/** общая статистика отображение среднего времени прохождения теста */
export const CommonStatsAvgTime: React.FC<CommonStatsAvgTimeProps> = React.memo(
    ({ avgTime }) => (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                {LOCAL.MESSAGES.TEST_PASSING_AVG_TIME}
            </div>

            {isNumber(avgTime) ? secondCountToTimeString(avgTime) : <br />}
        </div>
    )
)
