import React from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import { LOCAL } from 'core/local'
import {
    NotificationNewsletterTemplateSearchContract,
    NotificationNewsletterTemplateSearchResultContract,
    NotificationNewsletterTemplatesService,
    SortDirection,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRowClassNameArchive } from 'components/shared'

import { DISTRIBUTION_TEMPLATES_COLUMNS } from './DistributionTemplates.config'
import { DistributionTemplatesFilters } from './components'

/** Страница реестра шаблонов */
export const DistributionTemplates = React.memo(() => (
    <DictionaryPage<
        NotificationNewsletterTemplateSearchContract,
        NotificationNewsletterTemplateSearchResultContract
    >
        contentTitle={LOCAL.CATALOGS.TEMPLATES}
        dictionaryKey={DICTIONARY_KEYS.DISTRIBUTION_TEMPLATES}
        searchMethod={NotificationNewsletterTemplatesService.search}
        quickSearchMethod={NotificationNewsletterTemplatesService.searchText}
        deleteMethod={NotificationNewsletterTemplatesService.delete}
        createRecordLink={{
            path: ROUTE_NAMES.DISTRIBUTION_TEMPLATES_CREATE,
        }}
        tableColumns={DISTRIBUTION_TEMPLATES_COLUMNS}
        filterComponent={DistributionTemplatesFilters}
        tableFiltersOptions={{
            initialValues: {
                orderRules: [
                    { field: 'changeDate', direction: SortDirection.Desc },
                    { field: 'name', direction: SortDirection.Asc },
                ],
            },
        }}
        createPermissions={[
            UserPermission.NotificationNewsletterTemplateRead,
            UserPermission.NotificationNewsletterTemplateSave,
        ]}
        rowClassName={getRowClassNameArchive}
    />
))
