import React, { FC, useCallback, useEffect } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { DictionaryContract, NotificationTagsService } from 'core/api'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { LOCAL } from 'core/local'
import { ROW_GUTTER, TEMPLATE_STATUSES_MAPPED } from 'consts'
import { getValue, normalizeDataForSelectAndRadio } from 'utils'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useHttp } from 'hooks'
import { withLoader } from 'HOCs'

import {
    DistributionTemplatesFiltersProps,
    DistributionTemplatesFiltersSubmitValues,
} from './DistributionTemplatesFilters.types'

export const DistributionTemplatesFilters: FC<DistributionTemplatesFiltersProps> = withLoader(
    React.memo(({ updateLoader, isLoading, ...props }) => {
        const [getMarkers, markers] = useHttp<DictionaryContract[]>(
            NotificationTagsService.getForSelect,
            updateLoader
        )

        useEffect(() => {
            getMarkers()
        }, [getMarkers])

        const mapFormDataToRequest = useCallback(
            ({
                tags,
                ...rest
            }: Partial<DistributionTemplatesFiltersSubmitValues>) => ({
                ...rest,
                tagIds: tags?.map(getValue),
            }),
            []
        )

        return (
            <ControlGroup type="light">
                <FilterForm
                    {...props}
                    mapFormDataToRequest={mapFormDataToRequest}
                    renderFooter={renderFooterWithShowArchiveField}
                >
                    <Row gutter={ROW_GUTTER}>
                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="name"
                                label={LOCAL.LABELS.NAME}
                            >
                                <InputControl />
                            </FormItemAdapter>
                        </Col>

                        <Col xs={8}>
                            <FromToDatesFilter
                                from={{
                                    label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                    fieldName: 'changeDateStart',
                                }}
                                to={{
                                    label: LOCAL.LABELS.DATE_CHANGE_TO,
                                    fieldName: 'changeDateEnd',
                                }}
                            />
                        </Col>

                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="statuses"
                                label={LOCAL.LABELS.STATUS}
                            >
                                <SelectControl
                                    mode="multiple"
                                    values={TEMPLATE_STATUSES_MAPPED}
                                    showSearch
                                />
                            </FormItemAdapter>
                        </Col>
                    </Row>

                    <Row gutter={ROW_GUTTER}>
                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="tags"
                                label={LOCAL.CATALOGS.TAGS}
                            >
                                <SelectControl
                                    mode="multiple"
                                    showSearch
                                    allowClear
                                    labelInValue
                                    values={
                                        markers?.map(
                                            normalizeDataForSelectAndRadio
                                        ) || []
                                    }
                                />
                            </FormItemAdapter>
                        </Col>
                    </Row>
                </FilterForm>
            </ControlGroup>
        )
    })
)
