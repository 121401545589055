import React, { FC, useEffect, useMemo, useState } from 'react'
import {
    AgreementListDetailContract,
    PositionProfileAgreementService,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, PageContent } from 'components/shared'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsCommonProps } from 'App.types'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useParams } from 'react-router-dom'

import styles from './ApproveView.module.scss'
import { ApproveStages } from './components'

/** Страница просмотра листа согласования */
export const ApproveView: FC = React.memo(() => {
    const [approveData, setApproveData] = useState<
        AgreementListDetailContract
    >()

    /** id листа согласования */
    const { id: approveId } = useParams<UrlParamsCommonProps<'id'>>()

    /**
     * Наименование профиля должности
     */
    const jobProfilesName = useMemo(
        () =>
            approveData?.matchingObjects.map((el) => (
                <span key={el.id}>{` — ${el.name}`}</span>
            )),
        [approveData]
    )

    /**
     * Запрос листа согласования
     */
    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                if (!approveId) return

                const dataSource = await PositionProfileAgreementService.getAgreementList(
                    { agreementListId: approveId }
                )

                setApproveData(dataSource)
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [approveId])

    return (
        <PageContent>
            <div className={styles.header}>
                <h1>
                    {getRouteMeta(ROUTE_NAMES.APPROVE_VIEW)}

                    {jobProfilesName}
                </h1>

                <ButtonsToolbar>
                    <Button type="link" href={ROUTE_NAMES.POSITION_PROFILES}>
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    <Button type="primary" disabled>
                        {LOCAL.ACTIONS.APPROVAL_COMPLETE}
                    </Button>
                </ButtonsToolbar>
            </div>

            <div className={styles.info}>
                <h2>{LOCAL.LABELS.ROUTE}</h2>

                <p className={styles.infoName}>
                    <span>{`${LOCAL.LABELS.AUTHOR}: `}</span>

                    {approveData?.creatorFullName}

                    {approveData?.comment && ':'}
                </p>

                {approveData?.comment && (
                    <p className={styles.infoComment}>{approveData?.comment}</p>
                )}
            </div>

            <ApproveStages approveStages={approveData?.stages} />
        </PageContent>
    )
})
