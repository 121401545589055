import cn from 'classnames'
import styles from 'components/pages/Track/components/TrackForm/components/shared/TrackStageDnD/TrackStageDnD.module.scss'
import React, { useCallback, useContext, useLayoutEffect, useRef } from 'react'
import { ColorThemeContext } from 'contexts'
import { IconsAdapter, StageProgress, TooltipAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { PassageProcessInfoProps } from 'components/pages/TrackPreviewPassing/TrackPreviewPassing.types'
import { STAGE_STYLE_OPTIONS_INITIAL } from 'components/pages/Track/components/TrackForm/components/shared/StagesDndArea/StagesDndArea.consts'
import { TrackPassingContext } from 'components/pages/TrackPreviewPassing/TrackPassing.context'
import { TrackPassingsService } from 'core/api'
import { UrlParamsCommonProps } from 'App.types'
import { composeStageIconStyles } from 'components/pages/Track/components/TrackForm/components/shared/TrackStageDnD/TrackStageDnD.utils'
import {
    currentTrackStageByStatus,
    formatDate,
    isAppointmentTrackPassedOrNotPassed,
    isAppointmentTrackStatusNotPassed,
    isAppointmentTrackStatusPassed,
    isNotAvailableStatus,
    isPassingTypeConsistently,
} from 'utils'
import { isAppointmentPassedView } from 'components/pages/TrackPreviewPassing/TrackPreviewPassing.utils'
import { useParams } from 'react-router-dom'

import { TrackStagePassingProps } from './TrackStagePassing.types'
import { isEnoughAttemptCountForActivity } from './TrackStagePassing.utils'

export const TrackStagePassing: React.FC<TrackStagePassingProps> = React.memo(
    ({
        stageOptions,
        stageStyle,
        index,
        droppableElem,
        onUpdatePassageProcessInfo,
        startStagePassing,
        passingType,
        appointmentStatus,
    }) => {
        const { store } = useContext(TrackPassingContext)
        const urlParams = useParams<UrlParamsCommonProps<'id'>>()
        const { theme } = useContext(ColorThemeContext)
        const isPassingConsistently = isPassingTypeConsistently(passingType)
        const stageDraggablePart = useRef<HTMLDivElement>(null)

        const stageTooltip = `${
            LOCAL.MESSAGES.AVAILABILITY_STAGE_FOR_PASSING
        } ${
            isNotAvailableStatus(stageOptions?.availability?.availableStatus)
                ? formatDate(stageOptions?.availability?.periodTo)
                : formatDate(stageOptions?.availability?.periodFrom)
        }`

        /**
         * Получить информацию о прохождении мероприятия
         */
        const getStagePassingInfo = useCallback(async () => {
            const body = {
                appointmentId: Number(urlParams.id),
                stageNumber: stageOptions.stageNumber,
            }

            const passingEvent = await TrackPassingsService.getStagePassingInfo(
                body
            )

            await TrackPassingsService.setCurrentStage({ body })

            const updateInfo = passingEvent.activityPassing
                ? {
                      activity: passingEvent.activityPassing,
                  }
                : {
                      content: {
                          trackStagePassingId: stageOptions.id,
                          course: passingEvent.course,
                          stageTitle: stageOptions.title,
                      },
                  }

            onUpdatePassageProcessInfo?.(updateInfo as PassageProcessInfoProps)
        }, [
            stageOptions.stageNumber,
            urlParams.id,
            onUpdatePassageProcessInfo,
            stageOptions.id,
            stageOptions.title,
        ])

        const onStartOrViewStagePassing = useCallback(async () => {
            try {
                store.updateLoader(true)

                if (
                    isAppointmentPassedView(appointmentStatus) ||
                    (isAppointmentTrackPassedOrNotPassed(stageOptions.status) &&
                        isEnoughAttemptCountForActivity(stageOptions))
                ) {
                    getStagePassingInfo()

                    return
                }

                await startStagePassing?.({
                    currentStage: stageOptions,
                })
            } catch (error) {
                console.error(error)
            } finally {
                store.updateLoader(false)
            }
        }, [
            store,
            appointmentStatus,
            stageOptions,
            getStagePassingInfo,
            startStagePassing,
        ])

        const composeStagePositionStyles = () => {
            const styles = stageStyle?.positionStyles

            if (!styles) return

            const parseStyles = JSON.parse(styles)

            if (!parseStyles) return

            return {
                ...parseStyles,
                ...STAGE_STYLE_OPTIONS_INITIAL,
            }
        }

        /**
         * Прогресс нужно скрывать, когда успешно пройдены все этапы.
         * Для этого используется сравнение полей passingThematicBlocksCount и totalThematicBlocksCount,
         * потому что этап может быть со статусом passing, даже если один из этапов провален
         */
        const isShowProgress =
            stageOptions?.showProgress &&
            stageOptions?.passingThematicBlocksCount !==
                stageOptions?.totalThematicBlocksCount

        useLayoutEffect(() => {
            const injectedStyle = composeStageIconStyles(
                {
                    stageStyle,
                    stageStatus: currentTrackStageByStatus(
                        stageOptions.status,
                        stageOptions.availability && isPassingConsistently
                    ),
                    stageIndex: String(index),
                },
                theme
            )

            const removeInjectedStyle = () => {
                injectedStyle?.remove()
            }

            if (!stageDraggablePart.current) return removeInjectedStyle
            droppableElem?.appendChild(stageDraggablePart.current)

            return removeInjectedStyle
        }, [
            index,
            stageStyle,
            theme,
            stageDraggablePart,
            droppableElem,
            stageOptions.status,
            stageOptions,
            isPassingConsistently,
        ])

        const stage = (
            <div
                className={cn(styles.wrapper, styles.wrapperOriginal)}
                data-id={index}
                style={composeStagePositionStyles()}
                ref={stageDraggablePart}
                onClick={onStartOrViewStagePassing}
            >
                <div className={styles.img}>
                    {isAppointmentTrackStatusNotPassed(stageOptions.status) && (
                        <IconsAdapter
                            iconType="CustomIconClose"
                            className={styles.notPassed}
                        />
                    )}

                    {!stageOptions.availability &&
                        !isAppointmentTrackStatusPassed(stageOptions.status) &&
                        !isPassingConsistently && (
                            <IconsAdapter
                                iconType="CustomIconLock"
                                className={styles.notAvailable}
                            />
                        )}
                </div>

                {isShowProgress && (
                    <StageProgress
                        stageCount={stageOptions.totalThematicBlocksCount}
                        currentStage={stageOptions.passingThematicBlocksCount}
                    />
                )}
            </div>
        )

        return stageOptions?.availability?.periodFrom ? (
            <TooltipAdapter title={stageTooltip}>{stage}</TooltipAdapter>
        ) : (
            stage
        )
    }
)
