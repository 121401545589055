import React, { useCallback, useMemo } from 'react'
import { ANSWER_RESULT_TYPES } from 'consts'
import { Checkbox, Radio } from 'antd'
import { SpaceAdapter } from 'components/shared/SpaceAdapter'
import { isArrayEmpty } from 'utils'

import styles from './ChoosingTypeAnswer.module.scss'
import { ANSWER_RESULT_CLASSNAME } from './ChoosingTypeAnswer.consts'
import { ChoosingTypeAnswerProps } from './ChoosingTypeAnswer.types'
import { VerifyIcon } from '../VerifyIcon'

/** Тип ответа на вопрос с вариантами */
export const ChoosingTypeAnswer: React.FC<ChoosingTypeAnswerProps> = React.memo(
    ({
        value,
        onChange,
        chooseVariant: {
            limitAnswersCount,
            isAnswersCountLimited,
            answers,
            multiChoose,
        },
        disabled,
        columnsCount,
        answerVerify,
    }) => {
        const isVerified = !isArrayEmpty(answerVerify?.correctAnswerIds)

        /**
         * Обработчик изменения формы
         */
        const triggerChange = useCallback(
            (value) => {
                onChange?.(multiChoose ? value : [value.target.value])
            },
            [multiChoose, onChange]
        )

        /**
         * Варианты для ответа
         */
        const chooseItems = useMemo(
            () => (
                <div
                    className={styles.group}
                    style={{
                        gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
                    }}
                >
                    {answers.map((el) => {
                        const imgUrl = el.attachment?.url
                        const isDisableVariant =
                            isAnswersCountLimited &&
                            value?.length === limitAnswersCount &&
                            !value?.includes(el.id)

                        return (
                            <div key={el.id}>
                                <SpaceAdapter align="center">
                                    {isVerified && (
                                        <VerifyIcon
                                            isCorrect={answerVerify!.correctAnswerIds!.includes(
                                                el.id
                                            )}
                                        />
                                    )}

                                    {multiChoose ? (
                                        <Checkbox
                                            value={el.id}
                                            disabled={isDisableVariant}
                                        >
                                            {el.text}
                                        </Checkbox>
                                    ) : (
                                        <Radio value={el.id}>{el.text}</Radio>
                                    )}
                                </SpaceAdapter>

                                <div>
                                    {imgUrl && <img src={imgUrl} alt="img" />}
                                </div>
                            </div>
                        )
                    })}
                </div>
            ),
            [
                columnsCount,
                answers,
                isAnswersCountLimited,
                value,
                limitAnswersCount,
                multiChoose,
                answerVerify,
                isVerified,
            ]
        )

        return (
            <div className={styles.wrapper}>
                {multiChoose ? (
                    <Checkbox.Group onChange={triggerChange} value={value}>
                        {chooseItems}
                    </Checkbox.Group>
                ) : (
                    <Radio.Group
                        onChange={triggerChange}
                        value={value?.[0]}
                        disabled={disabled}
                    >
                        {chooseItems}
                    </Radio.Group>
                )}

                {!!answerVerify?.result && (
                    <div
                        className={
                            ANSWER_RESULT_CLASSNAME[
                                answerVerify.result as keyof typeof ANSWER_RESULT_CLASSNAME
                            ]
                        }
                    >
                        {
                            ANSWER_RESULT_TYPES[
                                answerVerify.result as keyof typeof ANSWER_RESULT_TYPES
                            ]
                        }
                    </div>
                )}
            </div>
        )
    }
)
