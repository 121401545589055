import {
    AppointmentAssessmentManagerParametersCandidateContract,
    AppointmentAssessmentRespondentContract,
    AppointmentContract,
    AppointmentUserContract,
    AssessmentPortfolioAssessedPositionContract,
    AssessmentPortfolioCircleParametersContract,
    AssessmentRole,
} from 'core/api'
import { UserFormContract } from 'components/controls/AddUsersTableControl/AddUsersTableControl.types'
import { getFullFio, getItemId } from 'utils'

import {
    AppointmentPublishFormAssessedPositionProps,
    AppointmentPublishFormContract,
} from './AssessmentAssignmentForm.types'

/**
 * Получить значения для отправки на бэк
 * @param values текущие значения формы
 * @param id идентификатор назначения
 */
export const mapFormDataToRequest = (
    { type, assessment, ...restValues }: AppointmentPublishFormContract,
    id?: number
) => ({
    ...restValues,
    id,
    type,
    assessment: {
        ...assessment,
        circleParameters: {
            ...assessment?.circleParameters,
            candidateId: assessment?.circleParameters?.candidateId?.[0]?.id,
            respondents: assessment?.circleParameters?.respondents
                ?.filter((respondent) => respondent.userId)
                ?.map((respondent) => ({
                    role: respondent.role,
                    respondentId: respondent.userId,
                })),
            checkingPersonsIds: assessment?.circleParameters?.checkingPersons?.map(
                getItemId
            ),
        },
        managerParameters: {
            candidates: assessment?.managerParameters?.candidates?.map(
                (el) => ({
                    assessedPositionId: el.assessedPosition.id,
                    candidateIds: el.candidateIds?.map(getItemId),
                })
            ),
            supervisorId: assessment?.managerParameters?.supervisor?.[0]?.id,
            checkingPersonsIds: assessment?.managerParameters?.checkingPersons?.map(
                getItemId
            ),
        },
    },
})

/** нормализация данных, начальных значений формы (при редактировании, просмотре), для `value` при оценке руководителем */
export const normalizeInitialDataForValue = (
    value: AppointmentUserContract
): UserFormContract => ({
    id: value.id,
    name: getFullFio(value),
    positions: value.position?.name,
    organizations: value.organization?.name,
})

/** нормализация данных, начальных значений формы (при редактировании, просмотре), для `value` при оценке 360 */
export const normalizeInitialDataForRoles = (
    data: AppointmentAssessmentRespondentContract[]
): UserFormContract[] =>
    data?.map((el) => ({
        role: el.role,
        id: el.respondent?.id,
        userId: el.respondent?.id,
        name: getFullFio(el.respondent),
        positions: el.respondent?.position?.name,
        organizations: el.respondent?.organization?.name,
    }))

/** нормализация данных для поля candidates */
export const normalizeCandidatesData = (
    value: AppointmentAssessmentManagerParametersCandidateContract
) => ({
    candidateIds: value.candidates.map((el) => ({
        ...el,
        structuralUnitsAndPositions: [
            {
                position: el.position,
                structuralUnit: el.organization,
            },
        ],
    })),
    assessedPosition: value.assessedPosition,
})

/**
 * Получить начальные значения для формы назначения
 * @param assignment опросный лист
 */
export const mapResponseToFormData = ({
    assessment,
    ...restValues
}: AppointmentContract) => ({
    ...restValues,
    assessment: {
        ...assessment,
        portfolioId: assessment?.portfolio?.id,
        circleParameters: {
            candidateId: assessment?.circleParameters?.candidate
                ? [
                      normalizeInitialDataForValue(
                          assessment?.circleParameters?.candidate
                      ),
                  ]
                : undefined,
            respondents: normalizeInitialDataForRoles(
                assessment?.circleParameters?.respondents
            ),
            checkingPersons: assessment?.circleParameters?.checkingPersons?.map(
                normalizeInitialDataForValue
            ),
        },
        managerParameters: {
            supervisor: assessment?.managerParameters?.supervisor && [
                normalizeInitialDataForValue(
                    assessment?.managerParameters?.supervisor
                ),
            ],
            checkingPersons: assessment?.managerParameters?.checkingPersons?.map(
                normalizeInitialDataForValue
            ),
            candidates: assessment?.managerParameters?.candidates?.map(
                normalizeCandidatesData
            ),
        },
    },
})

/**
 * Создать значения формы на основе настроек портфеля оценки 360 (при его выборе)
 * @param circleParameters настройки
 * @param structuralUnitsIds идентификаторы организаций, выбранных в портфеле оценки
 */
export const createRespondentsRoles = (
    {
        managersCount,
        colleaguesCount,
        colleagueFromRelatedDepartmentCount,
        subordinatesCount,
    }: AssessmentPortfolioCircleParametersContract,
    structuralUnitsIds: number[]
) => {
    const respondents = [
        ...Array(managersCount).fill({
            role: AssessmentRole.Manager,
        }),
        ...Array(colleaguesCount).fill({
            role: AssessmentRole.Colleague,
        }),
        ...Array(colleagueFromRelatedDepartmentCount).fill({
            role: AssessmentRole.ColleagueFromRelatedDepartment,
        }),
        ...Array(subordinatesCount).fill({
            role: AssessmentRole.Subordinate,
        }),
    ].map((el, i) => ({ ...el, id: -i }))

    return {
        assessment: {
            managerParameters: undefined,
            circleParameters: {
                respondents,
                structuralUnitsIds,
            },
        },
    }
}

/**
 * Маппинг начальных значений формы при выборе портфеля оценки руководителем
 */
export const setManagerParametersValues = (
    managerParameters: AssessmentPortfolioAssessedPositionContract[],
    candidates?: AppointmentPublishFormAssessedPositionProps[]
) => ({
    assessment: {
        circleParameters: undefined,
        managerParameters: {
            candidates: managerParameters.map((el) => ({
                assessedPosition: el,
                candidateIds: candidates?.find(
                    (candidate) => candidate.assessedPosition.id === el.id
                )?.candidateIds,
            })),
        },
    },
})

/**
 * Проверяем, был очищен селект с выбором портфеля оценки
 * @param assessment
 */
export const isRemovePortfolio = (
    assessment: AppointmentPublishFormContract['assessment']
) =>
    Object.keys(assessment || {})?.includes('portfolioId') &&
    !assessment.portfolioId

/**
 * Чистим данные при удалении портфеля из селекта
 * @param allValues все данные формы
 */
export const clearAssessment = (allValues: AppointmentPublishFormContract) => ({
    ...allValues,
    assessment: {
        ...allValues.assessment,
        circleParameters: undefined,
        managerParameters: {
            ...allValues.assessment.managerParameters,
            candidateIds: undefined,
            assessedPosition: undefined,
        },
    },
})

/**
 * Чистим данные при изменении типа оценки
 * @param allValues все данные формы
 */
export const clearData = (allValues: AppointmentPublishFormContract) => ({
    ...allValues,
    assessment: {
        ...allValues.assessment,
        portfolioId: undefined,
        candidateId: undefined,
        circleParameters: undefined,
        managerParameters: undefined,
    },
})
