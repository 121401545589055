import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import styles from './BackTop.module.scss'
import { BackTopProps, PositionProps } from './BackTop.types'
import { IconsAdapter } from '../IconsAdapter'

export const BackTop: React.FC<BackTopProps> = React.memo(
    ({
        bottom = 50,
        right = 50,
        zIndex = 5001,
        visibilityHeight = 600,
        className,
        getTarget,
    }) => {
        const [visible, setVisible] = useState(false)
        const [position, setPosition] = useState<PositionProps>({})

        const goTop = () => {
            const elementWithScroll = getTarget?.() || window

            elementWithScroll.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        }

        useEffect(() => {
            const target = getTarget?.()
            const elementWithScroll = target ? target : window

            const onScroll = () => {
                const btnSize = 44
                let scroll: number

                if (target) {
                    scroll = (elementWithScroll as HTMLElement).scrollTop

                    const coordinates = (elementWithScroll as HTMLElement).getBoundingClientRect()

                    const posX = coordinates.x + coordinates.width
                    const posY = coordinates.y + coordinates.height

                    setPosition({
                        top: posY - bottom - btnSize,
                        left: posX - right - btnSize,
                    })
                } else {
                    scroll = (elementWithScroll as Window).scrollY

                    setPosition({ bottom, right })
                }

                if (scroll >= visibilityHeight) setVisible(true)
                else setVisible(false)
            }

            elementWithScroll?.addEventListener('scroll', onScroll)

            return () => {
                elementWithScroll?.removeEventListener('scroll', onScroll)
            }
        }, [bottom, getTarget, right, visibilityHeight])

        return (
            <span
                onClick={goTop}
                className={cn(
                    className,
                    styles.wrapper,
                    visible && styles.wrapperActive
                )}
                style={{
                    ...position,
                    zIndex,
                }}
            >
                <IconsAdapter iconType="ArrowUpOutlined" />
            </span>
        )
    }
)
