import React from 'react'
import { AppointmentStatus } from 'core/api'
import { Button } from 'components/shared/Button'
import { CustomTableActionsProps } from 'components/shared/DynamicTableAdapter/DynamicTableAdapter.types'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import {
    createConfirmPopup,
    isAppointmentStatusCanceled,
    isAppointmentStatusEnded,
    isFormModeView,
} from 'utils'

/**
 * Рендер кнопок действия для таблиц
 */
export const renderTableActions = <T extends { id: React.ReactText }>(
    isEditForm: boolean
) => ({
    record,
    disabled,
    formMode,
    onRemoveRow,
}: CustomTableActionsProps<T>) => {
    const handleRemoveRow = () => {
        if (isEditForm) {
            createConfirmPopup({
                content:
                    LOCAL.MESSAGES.ASSESSMENTS_ASSIGNMENTS_REMOVE_PARTICIPANT,
                okText: LOCAL.YES,
                cancelText: LOCAL.NO,
                onOk: onRemoveRow(record.id),
            })
        } else {
            onRemoveRow(record.id)()
        }
    }

    return (
        <Button
            type="link"
            title={LOCAL.ACTIONS.DELETE}
            icon={<IconsAdapter iconType="DeleteOutlined" />}
            onClick={handleRemoveRow}
            disabled={disabled || isFormModeView(formMode)}
        />
    )
}

export const isDisabledPersons = (status?: AppointmentStatus) =>
    isAppointmentStatusEnded(status) || isAppointmentStatusCanceled(status)
