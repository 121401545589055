import CertificateLogo from 'core/images/certificate.svg'
import React, { useCallback } from 'react'
import { CERTIFICATE_TYPE } from 'consts'
import { CertificatesService } from 'core/api'
import { Logo } from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    getDateByFormat,
    isCertificateTypeInternal,
    openPdfFileInNewTab,
} from 'utils'
import { useHistory } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './CertificateCard.module.scss'
import { CertificateCardProps } from './CertificateCard.types'

/** Карточка сертификата для профайла пользователя */
export const CertificateCard: React.FC<CertificateCardProps> = withLoader(
    React.memo(
        ({ name, type, issueDate, number, updateLoader, id, userId }) => {
            const history = useHistory()
            const isInternalType = isCertificateTypeInternal(type)

            const handleViewCertificate = useCallback(async () => {
                try {
                    updateLoader(true)

                    if (isInternalType) {
                        openPdfFileInNewTab(
                            await CertificatesService.getFile(
                                { id },
                                { responseType: 'blob' }
                            )
                        )
                    } else {
                        history.push(
                            `${ROUTE_NAMES.EXTERNAL_CERTIFICATES_VIEW}/${userId}/${id}`
                        )
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }, [updateLoader, history, id, userId, isInternalType])

            return (
                <div className={styles.wrapper} onClick={handleViewCertificate}>
                    <div>
                        <div className={styles.header}>
                            <div>{CERTIFICATE_TYPE[type]}</div>

                            {isInternalType && (
                                <Logo isLink={false} className={styles.logo} />
                            )}
                        </div>

                        <div className={styles.name}>{name}</div>
                    </div>

                    <div className={styles.footer}>
                        <div>
                            <div>{number}</div>

                            <div className={styles.date}>
                                {getDateByFormat(issueDate, 'DD MMMM YYYY')}
                            </div>
                        </div>

                        <img
                            src={CertificateLogo}
                            className={styles.cert}
                            alt="cert"
                        />
                    </div>
                </div>
            )
        }
    ),
    { onTopOfEverything: false }
)
