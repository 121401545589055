import { FormInstance } from 'rc-field-form'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { LessonType } from 'core/api'

/**
 * Узнаем тип тематического блока
 * @param getFieldValue функция получения значения поля формы по имени
 * @param field объект с метаданными тематического блока
 * @return тип тематического блока
 */
export const getThemeBlockType = (
    getFieldValue: FormInstance['getFieldValue'],
    field: FormListFieldData
): LessonType => getFieldValue('lessons')[field.name].type
