import {
    FnActionProps,
    FnActionRequiredProps,
    WithFormInstanceProps,
    WithFormModeProps,
} from 'App.types'
import { ROUTES_POSTFIXES } from 'routing/routeNames.consts'
import {
    createConfirmPopup,
    getFormPopupTitle,
    setParentIdForCopy,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

interface UsePublishProps<
    CreateRequestContract,
    CreateResponseContract,
    FormContract
> extends WithFormInstanceProps, WithFormModeProps {
    mapFormDataToRequest: FnActionRequiredProps<Partial<FormContract>, any>
    saveFn: FnActionRequiredProps<
        { body: CreateRequestContract },
        Promise<CreateResponseContract>
    >
    publishFn: FnActionRequiredProps<
        { body: CreateRequestContract },
        Promise<CreateResponseContract>
    >
    redirectRoute: string
    publishMessage: string
    saveMessage: string
    initialValuesForEdit?: CreateResponseContract
    onBlockUserRouting?: FnActionProps
    isSaveDocument?: boolean
    formInitialValues?: any
    updateLoader?: FnActionRequiredProps<boolean>
}

export const usePublish = <
    CreateRequestContract,
    CreateResponseContract extends { id: number },
    FormContract extends { id: number }
>({
    updateLoader,
    onBlockUserRouting,
    mapFormDataToRequest,
    initialValuesForEdit,
    saveFn,
    publishFn,
    publishMessage,
    saveMessage,
    redirectRoute,
    isSaveDocument,
    formMode,
    form,
    formInitialValues,
}: UsePublishProps<
    CreateRequestContract,
    CreateResponseContract,
    FormContract
>) => {
    const history = useHistory()

    const createEntityFn = useCallback(
        async (values: Partial<FormContract>) => {
            try {
                updateLoader?.(true)

                const body = setParentIdForCopy(
                    mapFormDataToRequest({
                        ...values,
                        id: initialValuesForEdit?.id,
                    }),
                    formMode
                ) as CreateRequestContract

                let dataSource: CreateResponseContract
                if (isSaveDocument) {
                    dataSource = await saveFn({
                        body,
                    })
                } else {
                    dataSource = await publishFn({
                        body,
                    })
                }

                onBlockUserRouting?.(false)

                createConfirmPopup({
                    title: getFormPopupTitle(
                        getRouteMeta(redirectRoute, 'pageTitle')
                    ),
                    content: isSaveDocument ? saveMessage : publishMessage,
                    onOk: () => {
                        history.push(redirectRoute)
                    },
                    onCancel: () => {
                        if (!initialValuesForEdit?.id) {
                            form?.resetFields()

                            if (formInitialValues) {
                                form?.setFieldsValue(formInitialValues)
                            }
                        } else {
                            history.push(
                                `${redirectRoute}/${ROUTES_POSTFIXES.EDIT}/${dataSource.id}`
                            )
                        }
                    },
                })
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader?.(false)
            }
        },
        [
            formInitialValues,
            form,
            formMode,
            history,
            initialValuesForEdit,
            isSaveDocument,
            mapFormDataToRequest,
            onBlockUserRouting,
            publishFn,
            publishMessage,
            redirectRoute,
            saveFn,
            saveMessage,
            updateLoader,
        ]
    )

    return { createEntityFn }
}
