import { QuestionnaireType, ResultCalculatingType } from 'core/api'

/**
 * Проверка типа анкеты
 * @param type - тип
 */
export const isQuestionnaireTypeTest = (type?: QuestionnaireType) =>
    type === QuestionnaireType.Test
export const isQuestionnaireTypeQuiz = (type?: QuestionnaireType) =>
    type === QuestionnaireType.Quiz
export const isQuestionnaireTypeAssessment = (type?: QuestionnaireType) =>
    type === QuestionnaireType.Assessment

/**
 * Проверка типа оценки результата прохождения опросного листа
 * @param type тип
 */
export const isResultCalculatingTypeTotalPoints = (
    type?: ResultCalculatingType
) => type === ResultCalculatingType.TotalPoints
export const isResultCalculatingTypePassingPoints = (
    type?: ResultCalculatingType
) => type === ResultCalculatingType.PassingPoints
