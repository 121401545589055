import React, { useCallback, useEffect, useState } from 'react'
import { AppError, TextDocumentsTemplatesService } from 'core/api'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, FormAdapter } from 'components/shared'
import {
    CheckboxControl,
    FormItemAdapter,
    InputControl,
    TextAreaControl,
} from 'components/controls'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    onTextDocumentStateUpdated,
    textDocumentStateUpdatedOff,
} from 'core/helpers/signalr'
import { useForm } from 'antd/lib/form/Form'
import { useHistory } from 'react-router-dom'
import { uuidv4 } from 'utils'
import { withLoader } from 'HOCs'

import { DOCUMENT_EDITOR_FORM_FIELDS_MAX_LENGTH } from './DocumentEditorCreateForm.consts'

/** Форма создания документа редактора */
export const DocumentEditorCreateForm: React.FC<PopupAdapterFormProps> = withLoader(
    React.memo(
        ({
            onRequestFinish,
            onCancelSubmit,
            updateLoader,
            onChangeModalOptions,
        }) => {
            const [form] = useForm()
            const history = useHistory()
            const [isNeedToOpenDocument, setNeedToOpenDocument] = useState(true)
            const [documentLdeId, setDocumentLdeId] = useState<string>()

            /**
             *  Отправка формы
             *  @param values - данные формы
             */
            const handleFinish = useCallback(
                async (values) => {
                    try {
                        updateLoader(true)

                        const {
                            name,
                            description,
                            isDisableSpellCheck,
                            ...task
                        } = values

                        await TextDocumentsTemplatesService.addHomework({
                            body: {
                                id: uuidv4(),
                                name,
                                description,
                                tasks: [task],
                                isDisableSpellCheck,
                            },
                        })
                    } catch (error) {
                        onRequestFinish?.(error as AppError)
                        updateLoader(false)
                    }
                },
                [updateLoader, onRequestFinish]
            )

            const handleChecking = useCallback((checked) => {
                setNeedToOpenDocument(checked)
            }, [])

            useEffect(() => {
                onTextDocumentStateUpdated((response) => {
                    updateLoader(false)

                    if (response?.isSuccessful)
                        setDocumentLdeId(response.documentLdeId)

                    if (response?.errorMessage)
                        onRequestFinish?.({
                            data: { messages: [response.errorMessage] },
                        } as AppError)
                })

                return () => {
                    textDocumentStateUpdatedOff()
                }
            }, [onRequestFinish, updateLoader])

            useEffect(() => {
                if (!documentLdeId) return

                if (isNeedToOpenDocument) {
                    onChangeModalOptions?.({ visible: false })
                    history.push(
                        `${ROUTE_NAMES.DOCUMENTS_EDITOR_EDIT}/${documentLdeId}`
                    )

                    return
                }

                onRequestFinish?.()
            }, [
                documentLdeId,
                onChangeModalOptions,
                isNeedToOpenDocument,
                history,
                onRequestFinish,
            ])

            return (
                <FormAdapter
                    id={FORM_IDS.DOCUMENT_CREATE_FORM}
                    form={form}
                    onFinish={handleFinish}
                >
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.DOCUMENT_NAME}
                    >
                        <InputControl
                            maxLength={
                                DOCUMENT_EDITOR_FORM_FIELDS_MAX_LENGTH.NAME
                            }
                        />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName="title"
                        label={LOCAL.LABELS.TASK_TITLE}
                    >
                        <InputControl
                            maxLength={
                                DOCUMENT_EDITOR_FORM_FIELDS_MAX_LENGTH.TITLE
                            }
                        />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName="text"
                        label={LOCAL.LABELS.TASK_TEXT}
                    >
                        <TextAreaControl
                            maxLength={
                                DOCUMENT_EDITOR_FORM_FIELDS_MAX_LENGTH.TEXT
                            }
                        />
                    </FormItemAdapter>

                    <FormItemAdapter fieldName="isDisableSpellCheck">
                        <CheckboxControl>
                            {LOCAL.LABELS.TURN_OFF_SPELLING_CHECK.toLowerCase()}
                        </CheckboxControl>
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName="description"
                        label={LOCAL.LABELS.DOCUMENT_DESCRIPTION}
                    >
                        <TextAreaControl
                            maxLength={
                                DOCUMENT_EDITOR_FORM_FIELDS_MAX_LENGTH.TEXT
                            }
                        />
                    </FormItemAdapter>

                    <CheckboxControl
                        checked={isNeedToOpenDocument}
                        onChange={handleChecking}
                    >
                        {LOCAL.LABELS.OPEN_DOCUMENT_AFTER_SAVE.toLowerCase()}
                    </CheckboxControl>

                    <ButtonsToolbar bottomIndent="0">
                        <Button type="link" onClick={onCancelSubmit}>
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        <Button type="primary" htmlType="submit">
                            {LOCAL.ACTIONS.DOCUMENT_CREATE}
                        </Button>
                    </ButtonsToolbar>
                </FormAdapter>
            )
        }
    )
)
