import {
    NotificationDynamicFieldMarkerSelectDto,
    NotificationEmailInfoContentContract,
} from 'core/api'
import { Store } from 'App.types'

export const getMarkersDict = (
    markers?: NotificationDynamicFieldMarkerSelectDto[]
) =>
    Object.fromEntries(
        markers?.map((marker, i) => [marker.name, marker.previewValue]) || []
    )

export function getMessage(
    str: string,
    markers?: NotificationDynamicFieldMarkerSelectDto[]
): string {
    const markersDict = getMarkersDict(markers)
    const reg = /{([^}]+)}/

    while (str.match(reg)) {
        const execArr = reg.exec(str)!

        str = str.replace(execArr[0], markersDict[execArr[1] as string] || '_')
    }

    return str
}

export function normalizeData(
    initialValues?: Store,
    markers?: NotificationDynamicFieldMarkerSelectDto[]
): NotificationEmailInfoContentContract {
    return {
        subject: getMessage(initialValues?.notificationTheme || '', markers),
        message: initialValues?.notificationBody,
    }
}
