import Slider from 'react-slick'
import React, { useEffect, useMemo } from 'react'
import {
    CertificateSearchResultContractPageContract,
    CertificatesService,
} from 'core/api'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { useHttp } from 'hooks'

import styles from './UserProfileCertificates.module.scss'
import {
    CAROUSEL_SETTINGS,
    getCertificatesSearchParams,
} from './UserProfileCertificates.config'
import { CertificateCard } from '../CertificateCard'
import { UserProfileCertificatesProps } from './UserProfileCertificates.types'

/** Список сертификатов в профиле пользователя */
export const UserProfileCertificates: React.FC<UserProfileCertificatesProps> = React.memo(
    ({ userId }) => {
        const [getData, data] = useHttp<
            CertificateSearchResultContractPageContract
        >(CertificatesService.search)

        const certificatesList = useMemo(
            () =>
                data?.pageItems.map((el) => (
                    <div className={styles.carouselItem} key={el.id}>
                        <CertificateCard {...el} />
                    </div>
                )),
            [data]
        )

        useEffect(() => {
            getData(getCertificatesSearchParams(userId))
        }, [getData, userId])

        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h2 className={styles.title}>
                        {LOCAL.CATALOGS.CERTIFICATES}
                    </h2>

                    <Link to={ROUTE_NAMES.INTERNAL_CERTIFICATES}>
                        {LOCAL.ACTIONS.WATCH_ALL}
                    </Link>
                </div>

                {certificatesList?.length ? (
                    <div className={styles.list}>
                        <Slider
                            {...CAROUSEL_SETTINGS}
                            infinite={certificatesList?.length > 3}
                        >
                            {certificatesList}
                        </Slider>
                    </div>
                ) : (
                    <div className={styles.noCerts}>
                        {LOCAL.LABELS.NO_CERTIFICATES}
                    </div>
                )}
            </div>
        )
    }
)
