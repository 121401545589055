import { AttachmentContract } from 'core/api'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'

export const validateUploadFile = (
    event: UploadChangeParam<UploadFile<AttachmentContract>>
) => {
    if (!event.file.status || event.file.status === 'error') {
        return event.fileList.slice(0, -1)
    }

    return event.fileList
}
