import { DictionaryContract } from 'core/api'

const prefix = 'competence_'

/** Создаём id для div'а компетенции */
export const getCompetenceBlockId = ({ id }: DictionaryContract) =>
    `${prefix}${id}`

/** Находим id компетенции по ее id div'а */
export const getCompetenceIdByBlock = (divId: string) =>
    +divId.replace(prefix, '')
