import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Form, Row } from 'antd'
import { LOCAL } from 'core/local'
import { Store } from 'App.types'
import { SwitchControl } from 'components/controls/SwitchControl'
import { isNull } from 'lodash'
import { useBreakpoint, usePageSettings } from 'hooks'

import styles from './FilterForm.module.scss'
import { Button } from '../Button'
import { FilterFormProps } from './FilterForm.types'

/**
 * Форма фильтрации
 */
export const FilterForm: React.FC<FilterFormProps> = React.memo(
    ({
        onSetFilters,
        onResetFilters,
        children,
        renderFooter,
        dictionaryKey,
        tableFiltersOptions,
        mapFormDataToRequest,
        withoutSavedFilters,
        ...props
    }) => {
        const { filters } = usePageSettings(dictionaryKey)

        const [form] = Form.useForm()

        const formInstance = props.form || form

        const isWidthLessLG = useBreakpoint('LG')

        const [initialFormState] = useState(
            filters?.formValues?.saveFilters && !withoutSavedFilters
                ? filters.formValues
                : tableFiltersOptions?.initialValues
        )

        const handleClear = useCallback(() => {
            onResetFilters?.()
            formInstance.resetFields()
            formInstance.setFieldsValue(tableFiltersOptions?.initialValues)
        }, [formInstance, onResetFilters, tableFiltersOptions])

        const handleFinish = useCallback(
            (values: Store) => {
                onSetFilters(values, {
                    mapFormDataToRequest,
                })
            },
            [mapFormDataToRequest, onSetFilters]
        )

        const saveFiltersSwitch = useMemo(
            () =>
                dictionaryKey && (
                    <Form.Item name="saveFilters" noStyle>
                        <SwitchControl
                            className={styles.saveFilterSwitch}
                            addonAfter={LOCAL.LABELS.SAVE_FILTERS}
                        />
                    </Form.Item>
                ),
            [dictionaryKey]
        )

        const submitBtn = useMemo(
            () => (
                <Button htmlType="submit" type="primary">
                    {LOCAL.LABELS.SEARCH}
                </Button>
            ),
            []
        )

        const cleanBtn = useMemo(
            () => <Button onClick={handleClear}>{LOCAL.LABELS.CLEAR}</Button>,
            [handleClear]
        )

        const footer = isNull(renderFooter)
            ? undefined
            : renderFooter?.({
                  cleanBtn,
                  submitBtn,
                  saveFiltersSwitch,
                  isWidthLessLG,
              }) || (
                  <Row
                      gutter={10}
                      align="middle"
                      justify={isWidthLessLG ? 'center' : 'end'}
                  >
                      <Col>{saveFiltersSwitch}</Col>

                      <Col>{submitBtn}</Col>

                      <Col>{cleanBtn}</Col>
                  </Row>
              )

        useEffect(() => {
            formInstance.setFieldsValue(initialFormState)
        }, [formInstance, initialFormState])

        return (
            <Form
                {...props}
                form={formInstance}
                onFinish={handleFinish}
                autoComplete="off"
            >
                {children}

                {footer}
            </Form>
        )
    }
)
