import React from 'react'
import { FormDataStagesStylesProps } from 'components/pages/Track/components/TrackForm/TrackForm.types'
import { TrackLogoList } from 'components/shared/TrackLogoList'

import styles from './TrackLayerPassing.module.scss'
import { TrackLayerProps } from './TrackLayerPassing.types'
import { TrackStagePassing } from '../TrackStagePassing'
import { usePrepareLayer } from './TrackLayerPassing.hook'

export const TrackLayerPassing: React.FC<TrackLayerProps> = React.memo(
    ({ trackInfo }) => {
        const {
            resolution,
            resolutionForTrack,
            containerWithXScroll,
            trackCanvases,
            trackCanvasStyles,
            trackContainerStyles,
        } = usePrepareLayer({ trackInfo })

        return (
            <div
                ref={containerWithXScroll}
                className={styles.wrapper}
                style={trackContainerStyles}
            >
                {trackInfo?.trackHeaderIcons && (
                    <TrackLogoList
                        resolution={resolutionForTrack}
                        items={trackInfo.trackHeaderIcons}
                        className={styles.headerIconsWrapper}
                    />
                )}

                <div className={styles.canvas} style={trackCanvasStyles}>
                    {trackInfo &&
                        trackCanvases?.[
                            resolution
                        ]?.trackStageStyles?.map((stageStyle, index) => (
                            <TrackStagePassing
                                key={index}
                                index={index}
                                stageStyle={
                                    stageStyle as FormDataStagesStylesProps
                                }
                                stage={trackInfo.stages[index]}
                                passingType={trackInfo?.passingType}
                            />
                        ))}
                </div>
            </div>
        )
    }
)
