import React, { useCallback, useEffect, useState } from 'react'
import {
    AppError,
    TrackCanvasIconsService,
    TrackStageIconBackgroundAlbumContract,
    TrackStageStatus,
} from 'core/api'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { PopupWithForm, VisualizationOptionsGroup } from 'components/shared'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import { withLoader } from 'HOCs'

import styles from './SingleStageBackgroundImages.module.scss'
import stylesSingleStageVisualisationOptions from '../SingleStageVisualisationOptions/SingleStageVisualisationOptions.module.scss'
import { SingleStageBackgroundImagesProps } from './SingleStageBackgroundImages.types'
import { StageBackgroundImagesAlbums } from '../StageBackgroundImagesAlbums'
import { UploadAlbumForm } from '../UploadAlbumForm'

/** Компонент фоновых картинок холста этапа трека */
export const SingleStageBackgroundImages: React.FC<SingleStageBackgroundImagesProps> = withLoader(
    React.memo(({ stageStatus, stageIndex, updateLoader, canvasKey }) => {
        const [backgroundImagesAlbums, setBackgroundImagesAlbums] = useState<
            TrackStageIconBackgroundAlbumContract[]
        >()

        /** Функция для запросов альбомов фоновых картинок */
        const fetchBackgroundImagesAlbums = useCallback(async () => {
            try {
                const albums = await TrackCanvasIconsService.searchAlbum({
                    body: {
                        trackStageStatus: stageStatus as TrackStageStatus,
                    },
                })

                setBackgroundImagesAlbums(albums)
            } catch (error) {
                console.error(error)
            }
        }, [stageStatus])

        /** Callback после завершения отправки данных формы */
        const onRequestFinish = useCallback(
            async (err?: AppError) => {
                if (!err) {
                    try {
                        await fetchBackgroundImagesAlbums()
                    } catch (error) {
                        console.error(error)
                    } finally {
                        updateLoader(false)
                    }
                }
            },
            [fetchBackgroundImagesAlbums, updateLoader]
        )

        useEffect(() => {
            fetchBackgroundImagesAlbums()
        }, [fetchBackgroundImagesAlbums])

        return (
            <VisualizationOptionsGroup
                title={LOCAL.LABELS.ICON_CANVAS_BACKGROUND}
                className={styles.wrapper}
            >
                {backgroundImagesAlbums && (
                    <StageBackgroundImagesAlbums
                        albums={backgroundImagesAlbums}
                        stageIndex={stageIndex}
                        stageStatus={stageStatus}
                        canvasKey={canvasKey}
                    />
                )}

                <PopupWithForm
                    component={UploadAlbumForm}
                    formOptions={{
                        initialValues: { stageStatus },
                    }}
                    buttonText={LOCAL.ACTIONS.UPLOAD_ALBUM}
                    formId={FORM_IDS.UPLOAD_ALBUM_FORM + stageStatus}
                    onRequestFinish={onRequestFinish}
                    modalOptions={{
                        title: LOCAL.ACTIONS.UPLOAD_ALBUM,
                        width: MODAL_WIDTH.MD,
                        okText: LOCAL.ACTIONS.SAVE,
                        centered: true,
                        getContainer: getDropdownPopupContainerDefault(
                            stylesSingleStageVisualisationOptions.body
                        ),
                    }}
                />
            </VisualizationOptionsGroup>
        )
    })
)
