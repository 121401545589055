import React from 'react'
import { ButtonsToolbar, IconsAdapter, PopupWithForm } from 'components/shared'
import { DeleteTableAction } from 'components/shared/TableActions/components/DeleteTableAction'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { TableActionsCommonProps } from 'App.types'
import { ThemeContract, UserPermission } from 'core/api'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

import { getDeleteMessageText } from './ThemesTableActions.utils'

/** Компонент для создания колонки действий внутри таблицы реестра тематик */
export const ThemesTableActions: React.FC<TableActionsCommonProps<
    ThemeContract
>> = React.memo(({ tableRow }) => (
    <ButtonsToolbar bottomIndent="0">
        <HasPermissions
            requiredPermissions={[UserPermission.ThemeUpdate]}
            accessDeniedRender={renderAccessDeniedButton({
                type: 'link',
                icon: <IconsAdapter iconType="EditOutlined" />,
            })}
        >
            <PopupWithForm
                formId={FORM_IDS.THEME_FORM}
                buttonText=""
                havePopup={false}
                buttonOption={{
                    title: LOCAL.ACTIONS.EDIT,
                    type: 'link',
                    icon: <IconsAdapter iconType="EditOutlined" />,
                }}
                formOptions={{
                    initialValues: tableRow,
                }}
                modalOptions={{
                    title: LOCAL.LABELS.THEMATIC_SECTIONS_EDITING,
                    okText: LOCAL.ACTIONS.SAVE,
                    width: MODAL_WIDTH.MD,
                }}
            />
        </HasPermissions>

        <DeleteTableAction
            requiredPermissions={[UserPermission.ThemeDelete]}
            accessDeniedRender={renderAccessDeniedButton({
                type: 'link',
                icon: <IconsAdapter iconType="DeleteOutlined" />,
            })}
            tableRow={tableRow}
            messageText={getDeleteMessageText(tableRow)}
            modalStyles={{ whiteSpace: 'pre-wrap' }}
        />
    </ButtonsToolbar>
))
