import template from 'lodash/template'
import React, { useCallback, useContext, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Popup } from 'components/shared/Popup'
import { eventEmitter } from 'core/helpers/eventEmitter'

import styles from './FinishActivityModal.module.scss'
import { FinishActivityModalProps } from './FinishActivityModal.types'
import { TRACK_PASSING_EVENTS } from '../../TrackPassing.events'
import { TrackPassingContext } from '../../TrackPassing.context'

export const FinishActivityModal: React.FC<FinishActivityModalProps> = React.memo(
    ({ visible }) => {
        const { store } = useContext(TrackPassingContext)

        const { stageInfo, lessonInfo } = store

        const activity = useMemo(
            () =>
                lessonInfo?.mainActivityPassingId &&
                lessonInfo?.nestedActivityPassing
                    ? {
                          ...lessonInfo?.nestedActivityPassing,
                          id: lessonInfo.mainActivityPassingId,
                          isHomeworkAutoCheck: !!stageInfo?.activity
                              ?.activityPassing.isHomeworkAutoCheck,
                      }
                    : stageInfo?.activity?.activityPassing,
            [lessonInfo, stageInfo]
        )

        const finishActivityLesson = useCallback(() => {
            eventEmitter.emit(TRACK_PASSING_EVENTS.FINISH_LESSON)
        }, [])

        const hideFinishActivityModal = useCallback(() => {
            eventEmitter.emit(TRACK_PASSING_EVENTS.HIDE_FINISH_ACTIVITY_MODAL)
        }, [])

        const modalFooter = useMemo(
            () => (
                <div>
                    <Button onClick={hideFinishActivityModal}>
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    <Button onClick={finishActivityLesson}>
                        {LOCAL.ACTIONS.CONFIRM}
                    </Button>
                </div>
            ),
            [finishActivityLesson, hideFinishActivityModal]
        )

        return (
            <Popup
                className={styles.wrapper}
                visible={visible}
                footer={modalFooter}
                closable={false}
            >
                <div className={styles.flex}>
                    <IconsAdapter
                        iconType="ExclamationCircleOutlined"
                        className={styles.icon}
                    />

                    <div>
                        <div className={styles.title}>
                            {LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT}
                        </div>

                        <div className={styles.message}>
                            {template(
                                LOCAL.MESSAGES
                                    .ALL_MANDATORY_BLOCKS_FINISH_CONFIRM_MESSAGE
                            )({
                                eventName: activity?.name,
                            })}
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
)
