import noop from 'lodash/noop'
import React, { useCallback, useEffect, useState } from 'react'
import {
    ActivityLessonPassingService,
    QuestionAnswerCreateContract,
} from 'core/api'
import { Form, Modal } from 'antd'
import { LOCAL } from 'core/local'
import { MODAL_Z_INDEX } from 'consts'
import { composeFormRequestBody } from 'components/shared/QuestionnaireModal/QuestionnaireModal.utils'
import { isQuestionnaireTypeQuiz } from 'utils'

import { QuestionnaireFormProps } from './QuestionnaireForm.types'
import { globalLoaderEventEmit } from '../GlobalLoader/GlobalLoader.events'

/**
 * Компонент обертка для прохождения опросного листа и теста в модальном окне
 */
export const QuestionnaireForm: React.FC<QuestionnaireFormProps> = React.memo(
    ({ initialValues, onRequestFinish = noop, component: Component }) => {
        const [currentIndex, setCurrentIndex] = useState(0)
        const [form] = Form.useForm()

        /**
         * Количество вопросов
         */
        const questionsCount = initialValues?.questions?.length

        /**
         * Обрабатываем кнопку "ответить"
         */
        const handleQuestionAnswer = useCallback(
            async (values: QuestionAnswerCreateContract) => {
                try {
                    if (!initialValues?.questions || !initialValues?.id) return

                    globalLoaderEventEmit('SHOW')

                    const activityLessonPassingId = initialValues.id

                    const currentQuestion =
                        initialValues.questions?.[currentIndex]
                    const body = (composeFormRequestBody(
                        currentQuestion?.type,
                        values
                    ) || {}) as QuestionAnswerCreateContract

                    await ActivityLessonPassingService.setAnswer({
                        activityLessonPassingId,
                        body: {
                            ...body,
                            questionId:
                                initialValues.questions[currentIndex].id,
                        },
                    })

                    if (currentIndex + 1 !== questionsCount) {
                        setCurrentIndex((prevIndex) => prevIndex + 1)
                    } else {
                        Modal.info({
                            title: initialValues.name,
                            content: isQuestionnaireTypeQuiz(initialValues.type)
                                ? LOCAL.MESSAGES.THANKS_FOR_FEEDBACK
                                : LOCAL.MESSAGES.TEST_IS_PASSING,
                            okText: LOCAL.ACTIONS.CLOSE,
                            keyboard: false,
                            zIndex: MODAL_Z_INDEX.CONFIRM,
                            onOk: onRequestFinish,
                        })
                    }

                    form.resetFields()
                } catch (error) {
                    console.error(error)
                } finally {
                    globalLoaderEventEmit('HIDE')
                }
            },
            [initialValues, currentIndex, questionsCount, form, onRequestFinish]
        )

        useEffect(() => {
            if (initialValues?.currentQuestionNumber) {
                setCurrentIndex(initialValues?.currentQuestionNumber - 1)
            }
        }, [initialValues])

        return (
            <Component
                form={form}
                currentIndex={currentIndex}
                handleQuestionAnswer={handleQuestionAnswer}
                initialValues={initialValues}
            />
        )
    }
)
