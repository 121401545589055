import React, { useCallback } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import {
    NOTIFICATION_STATUSES_MAPPED,
    NOTIFICATION_TYPES_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { NotificationSearchContract } from 'core/api'
import { Store } from 'App.types'

/**
 * Форма фильтрации уведомлений
 */
export const UserNotificationsFilters: React.FC<FiltersProps<
    NotificationSearchContract
>> = React.memo((props) => {
    const mapFormDataToRequest = useCallback(
        (values: Store) => ({
            ...values,
            unread: values.unread ? values.unread === 'unread' : undefined,
        }),
        []
    )

    return (
        <ControlGroup type="light">
            <FilterForm {...props} mapFormDataToRequest={mapFormDataToRequest}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="type"
                            label={LOCAL.LABELS.TYPE}
                        >
                            <SelectControl values={NOTIFICATION_TYPES_MAPPED} />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="title"
                            label={LOCAL.LABELS.THEME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="text"
                            label={LOCAL.LABELS.TEXT}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="unread"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                values={NOTIFICATION_STATUSES_MAPPED}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="creationDateStart"
                            label={LOCAL.LABELS.DATE_FROM}
                        >
                            <DatePickerControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="creationDateEnd"
                            label={LOCAL.LABELS.DATE_TO}
                        >
                            <DatePickerControl />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
