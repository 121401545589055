import React from 'react'
import { ButtonsToolbar, IconsAdapter, PopupWithForm } from 'components/shared'
import { CompetenceGroupContract, UserPermission } from 'core/api'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { TableActionsCommonProps } from 'App.types'
import { isDocumentStatusInWork, isDocumentStatusReadyForWork } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

/** Компонент для создания колонки действий внутри таблицы реестра групп компетенций */
export const CompetenciesGroupsActions: React.FC<TableActionsCommonProps<
    CompetenceGroupContract
>> = React.memo(({ tableRow }) => {
    const isDeletable =
        !isDocumentStatusReadyForWork(tableRow.status) &&
        !isDocumentStatusInWork(tableRow.status)

    return (
        <ButtonsToolbar bottomIndent="0">
            <HasPermissions
                requiredPermissions={[
                    UserPermission.CompetenceGroupRead,
                    UserPermission.CompetenceGroupSave,
                ]}
                accessDeniedRender={renderAccessDeniedButton({
                    type: 'link',
                    icon: <IconsAdapter iconType="EditOutlined" />,
                })}
            >
                <PopupWithForm
                    formId={FORM_IDS.COMPETENCIES_GROUP_FORM}
                    buttonText=""
                    havePopup={false}
                    buttonOption={{
                        title: LOCAL.ACTIONS.EDIT,
                        type: 'link',
                        icon: <IconsAdapter iconType="EditOutlined" />,
                    }}
                    formOptions={{
                        initialValues: tableRow,
                    }}
                    modalOptions={{
                        title: LOCAL.ACTIONS.RECORD_EDIT,
                        width: MODAL_WIDTH.MD,
                        footer: null,
                    }}
                />
            </HasPermissions>

            {isDocumentStatusReadyForWork(tableRow.status) && (
                <HasPermissions
                    requiredPermissions={[
                        UserPermission.CompetenceGroupRead,
                        UserPermission.CompetenceGroupDelete,
                    ]}
                    accessDeniedRender={renderAccessDeniedButton({
                        type: 'link',
                        icon: <IconsAdapter iconType="FileZipOutlined" />,
                    })}
                >
                    <PopupWithForm
                        formId={FORM_IDS.CONFIRM_DELETE_FORM}
                        buttonText=""
                        havePopup={false}
                        buttonOption={{
                            title: LOCAL.ACTIONS.RECORD_TO_ARCHIVE,
                            type: 'link',
                            icon: <IconsAdapter iconType="FileZipOutlined" />,
                            disabled: Boolean(tableRow.competenceCount),
                        }}
                        formOptions={{
                            initialValues: tableRow,
                        }}
                        modalOptions={{
                            title: LOCAL.ACTIONS.RECORD_TO_ARCHIVE,
                            okText: LOCAL.YES,
                        }}
                        messageText={LOCAL.MESSAGES.RECORD_TO_ARCHIVE_CONFIRM}
                    />
                </HasPermissions>
            )}

            {isDeletable && (
                <HasPermissions
                    requiredPermissions={[
                        UserPermission.CompetenceGroupRead,
                        UserPermission.CompetenceGroupDelete,
                    ]}
                    accessDeniedRender={renderAccessDeniedButton({
                        type: 'link',
                        icon: <IconsAdapter iconType="DeleteOutlined" />,
                    })}
                >
                    <PopupWithForm
                        formId={FORM_IDS.CONFIRM_DELETE_FORM}
                        buttonText=""
                        havePopup={false}
                        buttonOption={{
                            title: LOCAL.ACTIONS.DELETE,
                            type: 'link',
                            icon: <IconsAdapter iconType="DeleteOutlined" />,
                        }}
                        formOptions={{
                            initialValues: tableRow,
                        }}
                        modalOptions={{
                            title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                            okText: LOCAL.YES,
                        }}
                    />
                </HasPermissions>
            )}
        </ButtonsToolbar>
    )
})
