import React, { FC, useCallback, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import {
    DictionaryPage,
    DictionaryPageActionsProps,
    ORDER_RULES_WITH_CHANGE_DATE,
    PageContentAfterRenderChildrenProps,
    TOOLBAR_CONTENT_SETTINGS,
    ToolbarContentRenderChildrenProps,
} from 'components/layouts'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { PopupWithForm, getRowClassNameArchive } from 'components/shared'
import {
    PositionProfileSearchResultContract,
    PositionProfilesSearchContract,
    PositionProfilesService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowHandlers, queryStrToObj, wrapToArrayIfTruthy } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useHistory } from 'react-router-dom'
import { useToolbarGutterResponsive } from 'components/layouts/DictionaryPage/DictionaryPage.hooks'

import { JobProfileInWorkForm, JobProfilesFilters } from './components'
import { JobProfileQueryObjProps } from './JobProfiles.types'
import { getJobProfileColumns } from './JobProfiles.utils'

/** Компонент отображения справочника профилей должности */
export const JobProfiles: FC = React.memo(() => {
    const gutterResponsive = useToolbarGutterResponsive()

    const externalFilters = useMemo(
        () => queryStrToObj<JobProfileQueryObjProps>(window.location.search),
        []
    )

    const { push } = useHistory()

    const renderPageContentAfter = useCallback(
        ({ onRequestFinish }: PageContentAfterRenderChildrenProps) => (
            <PopupWithForm
                component={JobProfileInWorkForm}
                formId={FORM_IDS.JOB_PROFILE_IN_WORK_FORM}
                onRequestFinish={onRequestFinish}
                haveButton={false}
            />
        ),
        []
    )

    const renderTableColumns = useCallback(
        (props: DictionaryPageActionsProps) => getJobProfileColumns(props),
        []
    )

    const onImportButtonClick = useCallback(() => {
        push(ROUTE_NAMES.POSITION_PROFILES_IMPORT)
    }, [push])

    const renderToolbarContent = useCallback(
        (props: ToolbarContentRenderChildrenProps) => (
            <Row {...TOOLBAR_CONTENT_SETTINGS.PROPS} className="pt-20 pb-20">
                <Col>{props.titleComponent}</Col>

                <Col>
                    <Row gutter={gutterResponsive} align="middle">
                        <Col>
                            <Row gutter={gutterResponsive} align="middle">
                                <Col>{props.quickSearchComponent}</Col>

                                <Col>{props.filterSwitch}</Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row gutter={TOOLBAR_CONTENT_SETTINGS.GUTTER.SM}>
                                <Col>
                                    <Button onClick={onImportButtonClick}>
                                        {LOCAL.ACTIONS.IMPORT}
                                    </Button>
                                </Col>

                                <Col>
                                    <HasPermissions
                                        requiredPermissions={[
                                            UserPermission.PositionProfileSave,
                                        ]}
                                        accessDeniedRender={renderAccessDeniedButton(
                                            {
                                                type: 'primary',
                                                text: LOCAL.ACTIONS.CREATE,
                                            }
                                        )}
                                    >
                                        {props.createComponent}
                                    </HasPermissions>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        ),
        [gutterResponsive, onImportButtonClick]
    )

    const tableFiltersOptions = useMemo(
        () => ({
            initialValues: {
                ...(externalFilters?.register === 'staffUnits'
                    ? {
                          staffUnitId: externalFilters?.unit,
                      }
                    : {}),
                ...(externalFilters?.register === 'organizations'
                    ? {
                          supervisingComplexIds: wrapToArrayIfTruthy(
                              externalFilters?.complex
                          ),
                          oivIds: wrapToArrayIfTruthy(externalFilters?.oiv),
                          structuralOrganizationIds: wrapToArrayIfTruthy(
                              externalFilters?.organization
                          ),
                          structuralUnitsIds: wrapToArrayIfTruthy(
                              externalFilters?.unit
                          ),
                      }
                    : {}),
                positionsIds: externalFilters?.position
                    ? [+externalFilters.position]
                    : undefined,
                orderRules: ORDER_RULES_WITH_CHANGE_DATE,
            },
        }),
        [externalFilters]
    )

    const createRecordLink = useMemo(
        () => ({
            path: ROUTE_NAMES.POSITION_PROFILES_CREATE,
        }),
        []
    )

    const onRow = useMemo(
        () =>
            getRowHandlers({
                url: ROUTE_NAMES.POSITION_PROFILES_VIEW,
                allowArchive: true,
            }),
        []
    )

    return (
        <DictionaryPage<
            PositionProfilesSearchContract,
            PositionProfileSearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.JOB_PROFILES}
            searchMethod={PositionProfilesService.search}
            deleteMethod={PositionProfilesService.delete}
            contentTitle={getRouteMeta(ROUTE_NAMES.POSITION_PROFILES)}
            tableColumnsRender={renderTableColumns}
            filterComponent={JobProfilesFilters}
            tableFiltersOptions={tableFiltersOptions}
            createRecordLink={createRecordLink}
            quickSearchMethod={PositionProfilesService.searchText}
            pageContentAfterRender={renderPageContentAfter}
            rowClassName={getRowClassNameArchive}
            onRow={onRow}
            toolbarContentRender={renderToolbarContent}
        />
    )
})
