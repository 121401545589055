import React, { useCallback } from 'react'
import { AddButton } from 'components/shared'
import { AssessmentProjectService } from 'core/api'
import { Form, notification } from 'antd'
import { LOCAL } from 'core/local'
import { WithFormInstanceProps, WithFormModeProps } from 'App.types'
import { isAppointmentStatusDraft, isFormModeEdit, isFormModeView } from 'utils'
import { mapFormDataToRequest } from 'components/pages/AssessmentProjects/components/AssessmentProjectsForm/AssessmentProjectsForm.utils'
import { useElementVisible } from 'hooks'

import styles from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.module.scss'

/** Контроллер для генерации опросных листов оценки руководителем */
export const GenerateQuestionnaires: React.FC<WithFormModeProps> = React.memo(
    ({ formMode }) => {
        const {
            updateElementVisible: updateLoader,
            elementVisible: isLoading,
        } = useElementVisible()

        const handleGenerateQuestionnaires = useCallback(
            (form?: WithFormInstanceProps['form']) => async () => {
                try {
                    updateLoader(true)

                    const values = form?.getFieldsValue()

                    const response = await AssessmentProjectService.generateQuestionnaires(
                        {
                            body: mapFormDataToRequest(values),
                        }
                    )

                    form?.setFieldsValue({
                        id: response.projectId,
                        managerParameters: {
                            ...values.managerParameters,
                            assessmentId: response.assessmentId,
                        },
                    })

                    notification.success({
                        message: LOCAL.MESSAGES.QUESTIONNAIRES_GENERATE_SUCCESS,
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader]
        )

        return (
            <div className={styles.generateQuestionnaires}>
                <h3>{LOCAL.CATALOGS.QUESTIONNAIRE_TEMPLATES}</h3>

                <Form.Item noStyle shouldUpdate>
                    {(form) => (
                        <AddButton
                            onClick={handleGenerateQuestionnaires(form)}
                            disabled={
                                isFormModeView(formMode) ||
                                (isFormModeEdit(formMode) &&
                                    !isAppointmentStatusDraft(
                                        form.getFieldValue('status')
                                    )) ||
                                isLoading
                            }
                            loading={isLoading}
                            buttonText={LOCAL.ACTIONS.ESTABLISH}
                        />
                    )}
                </Form.Item>
            </div>
        )
    }
)
