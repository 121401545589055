import isNil from 'lodash/isNil'
import React, { useCallback, useEffect, useReducer } from 'react'
import {
    AppError,
    ArticleCreateContract,
    ArticleUpdateContract,
    AttachmentsService,
    GlossariesService,
} from 'core/api'
import { CKEditorAdapter, TooltipAdapter } from 'components/shared'
import {
    CheckboxControl,
    FormItemAdapter,
    HiddenField,
    InputControl,
    SelectWithAddButtonControl,
    ShouldUpdateChecker,
    UploadDraggerImageControl,
    getLastFileFromList,
} from 'components/controls'
import { Col, Divider, Form, Row } from 'antd'
import {
    DEFAULT_STYLES,
    EXTRA_PLUGINS_LIST_EXTENDED,
    TOOLBAR_OPTIONS_EXTENDED,
} from 'components/shared/CKEditorAdapter/CKEditorAdapter.config'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { MEDIA_DOCUMENT_TYPE } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { RcCustomRequestOptions } from 'types'
import { editorReducer } from 'components/shared/CKEditorAdapter/CKEditorAdapter.utils'
import {
    getStringWithoutDots,
    isFormModeEdit,
    isFormModeView,
    validateUploadImage,
} from 'utils'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'hooks'

import styles from './KnowledgeBaseForm.module.scss'
import {
    KnowledgeBaseFormProps,
    KnowledgeBaseFormSubmitValues,
} from './KnowledgeBaseForm.types'
import { KnowledgeBaseFormSubstance } from '../KnowledgeBaseFormSubstance'
import { KnowledgeBaseSectionForm } from '../KnowledgeBaseSectionForm'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
} from './KnowledgeBaseForm.utils'

/**
 * Конструктор статьи Базы знаний
 */
export const KnowledgeBaseForm: React.FC<KnowledgeBaseFormProps> = React.memo(
    ({ onBlockUserRouting, formMode, updateLoader, initialValuesForEdit }) => {
        const [form] = Form.useForm<KnowledgeBaseFormSubmitValues>()
        const history = useHistory()
        const sectionId = useQuery().get('section')
        const [, dispatch] = useReducer(editorReducer, {
            styles: DEFAULT_STYLES,
        })

        const handleValuesChange = useCallback(() => {
            if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                onBlockUserRouting?.()
            }
        }, [form, formMode, onBlockUserRouting])

        /**
         * Обработчик добавления файла
         */
        const uploadImage = useCallback(
            async ({ file, onError, onSuccess }: RcCustomRequestOptions) => {
                try {
                    updateLoader(true)

                    const result = await AttachmentsService.upload({
                        file,
                    })

                    onSuccess?.(result)
                } catch (error) {
                    onError?.(error as AppError)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader]
        )

        /**
         * Обработчик отправки формы
         */
        const handleFinish = useCallback(
            async (body) => {
                try {
                    updateLoader(true)

                    if (isFormModeEdit(formMode))
                        await GlossariesService.updateArticle({
                            body: mapFormDataToRequest(
                                body
                            ) as ArticleUpdateContract,
                        })
                    else
                        await GlossariesService.createArticle({
                            body: mapFormDataToRequest(
                                body
                            ) as ArticleCreateContract,
                        })

                    onBlockUserRouting?.(false)
                    history.push(ROUTE_NAMES.KNOWLEDGE_BASE)
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader, formMode, history, onBlockUserRouting]
        )

        useEffect(() => {
            form.setFieldsValue(
                initialValuesForEdit?.id
                    ? mapResponseToFormData(initialValuesForEdit)
                    : {
                          sectionId: sectionId ? Number(sectionId) : undefined,
                      }
            )
        }, [form, initialValuesForEdit, sectionId])

        return (
            <Form
                className={styles.wrapper}
                id={FORM_IDS.KNOWLEDGE_BASE_FORM}
                form={form}
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
            >
                <HiddenField fieldName="id" />

                <HiddenField fieldName="selectedIndex" />

                <HiddenField fieldName="chapters" />

                <Row>
                    <Col xs={6}>
                        <div className={styles.leftColumnContent}>
                            <TooltipAdapter
                                title={getStringWithoutDots(
                                    MEDIA_DOCUMENT_TYPE
                                )}
                            >
                                <FormItemAdapter
                                    fieldName="picture"
                                    label={LOCAL.LABELS.ARTICLE_CARD_IMAGE}
                                    valuePropName="fileList"
                                    required
                                    getValueFromEvent={getLastFileFromList}
                                >
                                    <UploadDraggerImageControl
                                        accept={MEDIA_DOCUMENT_TYPE}
                                        customRequest={uploadImage}
                                        beforeUpload={validateUploadImage}
                                        className={styles.uploadImage}
                                        disabled={isFormModeView(formMode)}
                                    />
                                </FormItemAdapter>
                            </TooltipAdapter>

                            <FormItemAdapter
                                fieldName="name"
                                label={LOCAL.LABELS.ARTICLE_NAME}
                                required
                            >
                                <InputControl />
                            </FormItemAdapter>

                            <FormItemAdapter
                                fieldName="sectionId"
                                label={LOCAL.LABELS.SECTION}
                                required
                            >
                                <SelectWithAddButtonControl
                                    formComponent={KnowledgeBaseSectionForm}
                                    formId={FORM_IDS.KNOWLEDGE_ADD_SECTION_FORM}
                                    formMode={formMode}
                                    request={GlossariesService.getSections}
                                    addButtonText={LOCAL.ACTIONS.ADD_SECTION}
                                    modalOptions={{
                                        title: LOCAL.ACTIONS.CREATE_NEW_SECTION,
                                        okText: LOCAL.ACTIONS.CREATE,
                                    }}
                                />
                            </FormItemAdapter>

                            <Divider />

                            <KnowledgeBaseFormSubstance form={form} />

                            <FormItemAdapter
                                fieldName="isDraft"
                                className={styles.checkbox}
                            >
                                <CheckboxControl>
                                    {LOCAL.LABELS.ARTICLE_IN_DEVELOPMENT}
                                </CheckboxControl>
                            </FormItemAdapter>

                            {isFormModeEdit(formMode) && (
                                <>
                                    <Divider />

                                    <FormItemAdapter
                                        className={styles.lastEditPerson}
                                        fieldName="changeUser"
                                        label={LOCAL.LABELS.LAST_CHANGES}
                                    >
                                        <InputControl formMode="view" />
                                    </FormItemAdapter>
                                </>
                            )}
                        </div>
                    </Col>

                    <Col xs={18}>
                        <ShouldUpdateChecker fieldPath={['selectedIndex']}>
                            {({ getFieldValue }) => {
                                const selectedIndex = getFieldValue(
                                    'selectedIndex'
                                )

                                return (
                                    !isNil(selectedIndex) && (
                                        <div className={styles.editorWrapper}>
                                            <Form.Item
                                                noStyle
                                                name={[
                                                    'chapters',
                                                    selectedIndex,
                                                    'content',
                                                ]}
                                            >
                                                <CKEditorAdapter
                                                    dispatchEvent={dispatch}
                                                    toolbar={
                                                        TOOLBAR_OPTIONS_EXTENDED
                                                    }
                                                    extraPlugins={
                                                        EXTRA_PLUGINS_LIST_EXTENDED
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                    )
                                )
                            }}
                        </ShouldUpdateChecker>
                    </Col>
                </Row>
            </Form>
        )
    }
)
