import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { CanvasImagesWithInfiniteScroll } from 'components/shared'
import { Form } from 'antd'
import { ImgsUploadFnResponseProps } from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.types'
import { LOCAL } from 'core/local'
import { Resolutions } from 'core/configs'
import { ShouldUpdateChecker } from 'components/controls'
import { TrackCanvasBackgroundsService } from 'core/api'
import { TrackLogoList } from 'components/shared/TrackLogoList'
import { canvasBgImgMapFn } from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.utils'
import { isDocumentStatusInWork } from 'utils'

import styles from './TrackStagesVisualization.module.scss'
import { CanvasSizes } from '../CanvasSizes'
import { DownloadSaveTemplate } from '../DownloadSaveTemplate'
import { StagesDndArea } from '../StagesDndArea'
import { TrackStagesVisualizationProps } from './TrackStagesVisualization.types'
import {
    canvasBgImgsFetchFn,
    canvasBgImgsUploadFn,
} from './TrackStagesVisualization.utils'

// TODO: после влития SDO-6053 убрать лишние признаки и код, ориентерированные на предпросмотр, прохождение
/**
 * Компонент настройки визуализации трека
 */
export const TrackStagesVisualization: React.FC<TrackStagesVisualizationProps> = React.memo(
    ({ form, onChange, canvasKey, currentCanvasKey }) => {
        /**
         * Ссылка на элемент `aside`
         */
        const asideElem = useRef<HTMLElement>(null)

        /**
         * Ссылка на элемент `main`
         */
        const mainElem = useRef<HTMLElement>(null)

        /**
         * Ссылка на элемент `droppable`
         */
        const droppableElem = useRef<HTMLDivElement>(null)

        /**
         * Обработчик выбора фона полотна
         */
        const handleCanvasBgChoose = useCallback(
            (value?: ImgsUploadFnResponseProps) => {
                form?.setFields([
                    {
                        name: [
                            'trackCanvases',
                            canvasKey,
                            'meta',
                            'currentBackgroundUrl',
                        ],
                        value: value?.url,
                    },
                ])
            },
            [form, canvasKey]
        )

        /**
         * Вычисление высоты `aside`
         */
        useLayoutEffect(() => {
            function setAsideHeight() {
                const tabs = document.querySelector('.ant-tabs-nav')
                const tabsCoordinates = tabs?.getBoundingClientRect()

                if (!tabsCoordinates) return

                const asideHeight =
                    window.innerHeight -
                    (tabsCoordinates?.top + tabsCoordinates?.height)
                const heightProps: [string, string] = [
                    'style',
                    `height: ${asideHeight}px`,
                ]

                asideElem.current?.setAttribute(...heightProps)
                mainElem.current?.setAttribute(...heightProps)
            }

            setAsideHeight()

            window.addEventListener('resize', setAsideHeight)

            return () => {
                window.removeEventListener('resize', setAsideHeight)
            }
        }, [])

        return (
            <div className={cn(styles.wrapper, `canvas-${canvasKey}`)}>
                <Form.Item noStyle shouldUpdate>
                    {(form) => {
                        const { status } = form.getFieldsValue()

                        const isInWorkStatus = isDocumentStatusInWork(status)

                        return (
                            <aside ref={asideElem} className={styles.aside}>
                                <DownloadSaveTemplate
                                    form={form}
                                    disabled={isInWorkStatus}
                                />

                                <CanvasSizes
                                    disabled={isInWorkStatus}
                                    canvasKey={canvasKey}
                                />

                                <CanvasImagesWithInfiniteScroll
                                    fileListTitle={LOCAL.LABELS.CANVAS_BG}
                                    imgMapFn={canvasBgImgMapFn}
                                    imgsFetchFn={canvasBgImgsFetchFn}
                                    imgsUploadFn={canvasBgImgsUploadFn}
                                    controlName={[
                                        'trackCanvases',
                                        canvasKey,
                                        'trackCanvasBackgroundId',
                                    ]}
                                    controlMetaName={[
                                        'trackCanvases',
                                        canvasKey,
                                        'meta',
                                        'currentBackgroundUrl',
                                    ]}
                                    downloadBtnText={
                                        LOCAL.LABELS.DOWNLOAD_IMAGE
                                    }
                                    onImgChoose={handleCanvasBgChoose}
                                    disabled={isInWorkStatus}
                                    deleteMethod={
                                        TrackCanvasBackgroundsService.delete
                                    }
                                    required
                                />

                                <StagesDndArea
                                    form={form}
                                    droppableElem={droppableElem.current}
                                    status={status}
                                    mode="create"
                                    onChangeStagesStyles={onChange}
                                    canvasKey={canvasKey}
                                    currentCanvasKey={currentCanvasKey}
                                />
                            </aside>
                        )
                    }}
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                        !isEqual(
                            prevValues?.trackCanvases?.[canvasKey],
                            curValues?.trackCanvases?.[canvasKey]
                        )
                    }
                >
                    {(form) => {
                        const values = form.getFieldsValue()
                        const bgUrl =
                            values?.trackCanvases?.[canvasKey]?.meta
                                ?.currentBackgroundUrl

                        return (
                            <ShouldUpdateChecker
                                fieldPath={['trackHeaderIcons']}
                            >
                                {({ getFieldsValue }) => {
                                    const trackHeaderIcons = getFieldsValue()
                                        ?.trackHeaderIcons

                                    return (
                                        <main
                                            ref={mainElem}
                                            className={styles.main}
                                        >
                                            <div
                                                ref={droppableElem}
                                                className={cn(
                                                    styles.canvas,
                                                    `droppable-${canvasKey}`
                                                )}
                                                style={{
                                                    width:
                                                        values?.trackCanvases?.[
                                                            canvasKey
                                                        ]?.width || 'auto',
                                                    height:
                                                        values?.trackCanvases?.[
                                                            canvasKey
                                                        ]?.height || '100%',
                                                }}
                                            >
                                                {bgUrl && (
                                                    <img
                                                        src={bgUrl}
                                                        alt=""
                                                        className={styles.bg}
                                                    />
                                                )}

                                                {trackHeaderIcons && (
                                                    <TrackLogoList
                                                        resolution={
                                                            currentCanvasKey as Resolutions
                                                        }
                                                        items={trackHeaderIcons}
                                                        className={
                                                            styles.headerIconsWrapper
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </main>
                                    )
                                }}
                            </ShouldUpdateChecker>
                        )
                    }}
                </Form.Item>
            </div>
        )
    }
)
