import React, { useEffect, useState } from 'react'
import {
    FormItemAdapter,
    InputControl,
    SelectPictureControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { TRACK_EVENTS } from 'consts'
import {
    TrackAssistantIllustrationContact,
    TrackAssistantIllustrationsService,
} from 'core/api'

import { InfoAssistantOptions } from '../InfoAssistantOptions'
import { InfoAssistantProps } from './InfoAssistant.types'
import { SEARCH_ASSISTANTS_PICTURES_PARAMS } from './InfoAssistant.consts'

/** Настройки помощника */
export const InfoAssistant: React.FC<InfoAssistantProps> = React.memo(
    ({ disabled, formMode }) => {
        const [assistantPictures, setAssistantPicture] = useState<
            TrackAssistantIllustrationContact[]
        >()

        useEffect(() => {
            const fetchAssistantsPictures = async () => {
                try {
                    const items = await TrackAssistantIllustrationsService.search(
                        SEARCH_ASSISTANTS_PICTURES_PARAMS
                    )
                    setAssistantPicture(items.pageItems)
                } catch (error) {
                    console.error(error)
                }
            }

            fetchAssistantsPictures()
        }, [])

        return (
            <>
                <FormItemAdapter
                    fieldName={['informationAssistant', 'name']}
                    label={LOCAL.LABELS.INFORMATION_ASSISTANT_NAME}
                >
                    <InputControl disabled={disabled} formMode={formMode} />
                </FormItemAdapter>

                <FormItemAdapter
                    fieldName={['informationAssistant', 'illustrationId']}
                    label={LOCAL.LABELS.INFORMATION_ASSISTANT_ILLUSTRATION}
                >
                    <SelectPictureControl
                        selectValues={assistantPictures}
                        disabled={disabled}
                        formMode={formMode}
                    />
                </FormItemAdapter>

                <InfoAssistantOptions
                    eventTypes={TRACK_EVENTS}
                    formListName={['informationAssistant', 'events']}
                    disabled={disabled}
                    formMode={formMode}
                />
            </>
        )
    }
)
