import React, { ReactText, useCallback, useMemo, useState } from 'react'
import { InputNumberControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { ReactTextNullable } from 'App.types'

import { MIN_QUESTIONS_COUNT } from './QuestionnaireQuestionsCount.consts'
import { QuestionnaireQuestionsCountProps } from './QuestionnaireQuestionsCount.types'

/** Количество случайных вопросов из опросного листа */
export const QuestionnaireQuestionsCount: React.FC<QuestionnaireQuestionsCountProps> = React.memo(
    ({
        isRandomQuestions,
        totalQuestionCount,
        value,
        onChange,
        disabled,
        formMode,
    }) => {
        const [questionCount, setQuestionCount] = useState<number>()

        const triggerChanges = useCallback(
            (value?: ReactText) => {
                if (!value) return

                onChange?.(Number(value))
                setQuestionCount(Number(value))
            },
            [onChange]
        )

        const handleQuestionCount = useCallback(
            (value?: ReactTextNullable) => {
                triggerChanges(Number(value))
            },
            [triggerChanges]
        )

        const questionCountResult = useMemo(() => {
            if (!isRandomQuestions) return totalQuestionCount

            if (!value && !questionCount) return

            const resultCount = value || questionCount

            return Number(resultCount)
        }, [isRandomQuestions, questionCount, totalQuestionCount, value])

        return (
            <>
                <InputNumberControl
                    type="number"
                    defaultValue={value ? Number(value) : undefined}
                    min={MIN_QUESTIONS_COUNT}
                    value={questionCountResult}
                    max={totalQuestionCount}
                    disabled={!isRandomQuestions || disabled}
                    onChange={handleQuestionCount}
                    formMode={formMode}
                />

                <span>{LOCAL.FROM}</span>

                {totalQuestionCount}
            </>
        )
    }
)
