import React from 'react'
import { ButtonsToolbar, IconsAdapter, PopupWithForm } from 'components/shared'
import { DEVELOPMENT_GOALS_REQUIRED_PERMISSIONS, MODAL_WIDTH } from 'consts'
import { DevelopmentGoalsSearchResultContract, UserPermission } from 'core/api'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { TableActionsCommonProps } from 'App.types'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

/** Кнопки для колонки действий внутри таблицы реестра целей развития */
export const DevelopmentGoalsTableActions: React.FC<TableActionsCommonProps<
    DevelopmentGoalsSearchResultContract
>> = React.memo(({ tableRow }) => (
    <ButtonsToolbar bottomIndent="0">
        <HasPermissions
            requiredPermissions={[
                ...DEVELOPMENT_GOALS_REQUIRED_PERMISSIONS,
                UserPermission.PurposeSave,
            ]}
            accessDeniedRender={renderAccessDeniedButton({
                type: 'link',
                icon: <IconsAdapter iconType="EditOutlined" />,
            })}
        >
            <PopupWithForm
                formId={FORM_IDS.DEVELOPMENT_GOALS_FORM}
                buttonText=""
                havePopup={false}
                buttonOption={{
                    title: LOCAL.ACTIONS.EDIT,
                    type: 'link',
                    icon: <IconsAdapter iconType="EditOutlined" />,
                }}
                formOptions={{
                    initialValues: tableRow,
                }}
                modalOptions={{
                    title: LOCAL.LABELS.DEVELOPMENT_GOAL_EDITING,
                    okText: LOCAL.ACTIONS.SAVE,
                    width: MODAL_WIDTH.MD,
                }}
            />
        </HasPermissions>

        <HasPermissions
            requiredPermissions={[
                ...DEVELOPMENT_GOALS_REQUIRED_PERMISSIONS,
                UserPermission.PurposeDelete,
            ]}
            accessDeniedRender={renderAccessDeniedButton({
                type: 'link',
                icon: <IconsAdapter iconType="DeleteOutlined" />,
            })}
        >
            <PopupWithForm
                formId={FORM_IDS.CONFIRM_DELETE_FORM}
                buttonText=""
                havePopup={false}
                buttonOption={{
                    title: LOCAL.ACTIONS.DELETE,
                    type: 'link',
                    icon: <IconsAdapter iconType="DeleteOutlined" />,
                }}
                formOptions={{
                    initialValues: tableRow,
                }}
                modalOptions={{
                    title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                    okText: LOCAL.YES,
                }}
            />
        </HasPermissions>
    </ButtonsToolbar>
))
