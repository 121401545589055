import React, { FC } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { ControlGroup } from 'components/controls'
import { Form, Space } from 'antd'
import { FormListOperation } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import { ManagerParametersProps } from './ManagerParameters.types'
import { ManagerParametersRow } from '../ManagerParametersRow'

/** Группа полей - оценка руководителем */
export const ManagerParameters: FC<ManagerParametersProps> = React.memo(
    ({ formMode, disabled }) => {
        /**
         * Рендер заголовка группы полей
         */
        const getGroupTitle = (add: FormListOperation['add']) => (
            <Space>
                {LOCAL.LABELS.ASSESSED_POSITIONS}

                <AddButton
                    onClick={() => add()}
                    disabled={isFormModeView(formMode) || disabled}
                />
            </Space>
        )

        return (
            <div>
                <Form.List name="managerParameters">
                    {(fields, { add, remove }) => (
                        <ControlGroup
                            title={getGroupTitle(add)}
                            noPadding
                            type="ghost"
                            required
                        >
                            {fields.map((field) => (
                                <Form.Item {...field}>
                                    <ManagerParametersRow
                                        formMode={formMode}
                                        key={field.key}
                                        field={field}
                                        onRemoveRow={remove}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            ))}
                        </ControlGroup>
                    )}
                </Form.List>
            </div>
        )
    }
)
