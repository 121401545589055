import { useBreakpoint } from 'hooks'

import { TOOLBAR_CONTENT_SETTINGS } from './DictionaryPage.consts'

export function useToolbarGutterResponsive() {
    const isLessXL = useBreakpoint('XL')

    const gutterResponsive = isLessXL
        ? TOOLBAR_CONTENT_SETTINGS.GUTTER.SM
        : TOOLBAR_CONTENT_SETTINGS.GUTTER.XL

    return gutterResponsive
}
