import React from 'react'
import cn from 'classnames'
import {
    AddonAfterDefault,
    ButtonsToolbar,
    CloseButton,
} from 'components/shared'
import { isFormModeView } from 'utils'

import styles from './DynamicListControlItem.module.scss'
import { DynamicListControlItemProps } from './DynamicListControlItem.types'

/** Элемент списка контрола динамических полей */
export const DynamicListControlItem: React.FC<DynamicListControlItemProps> = ({
    value,
    onRemove,
    formMode,
    viewOptions,
    className,
}) => (
    <div className={cn(styles.wrapper, className)}>
        <p className={styles.text}>{value?.name}</p>

        <ButtonsToolbar bottomIndent="0">
            {!isFormModeView(formMode) && (
                <CloseButton onClose={onRemove} formMode={formMode} />
            )}

            {viewOptions && value?.id && (
                <AddonAfterDefault
                    url={`${viewOptions.url}/${value.id}`}
                    permissionsRequired={viewOptions.permissionsRequired}
                />
            )}
        </ButtonsToolbar>
    </div>
)
