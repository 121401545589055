import React from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import {
    QuizPortfolioSearchContract,
    QuizPortfolioSearchResultContract,
    QuizPortfoliosService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'
import { getRowHandlers } from 'utils'

import { QUIZ_PORTFOLIOS_TABLE_COLUMNS } from './QuizPortfolios.config'
import { QuizPortfoliosFilters } from './components'

/** Реестр портфелей опросов */
export const QuizPortfolios: React.FC = React.memo(() => (
    <DictionaryPage<
        QuizPortfolioSearchContract,
        QuizPortfolioSearchResultContract
    >
        dictionaryKey={DICTIONARY_KEYS.QUIZ_PORTFOLIOS}
        searchMethod={QuizPortfoliosService.search}
        deleteMethod={QuizPortfoliosService.delete}
        contentTitle={getRouteMeta(ROUTE_NAMES.QUIZ_PORTFOLIOS, 'pageTitle')}
        tableColumns={QUIZ_PORTFOLIOS_TABLE_COLUMNS}
        createRecordLink={{
            path: ROUTE_NAMES.QUIZ_PORTFOLIOS_CREATE,
        }}
        filterComponent={QuizPortfoliosFilters}
        tableFiltersOptions={{
            initialValues: {
                orderRules: ORDER_RULES_WITH_CHANGE_DATE,
            },
        }}
        quickSearchMethod={QuizPortfoliosService.searchText}
        createPermissions={[
            UserPermission.QuizPortfoliosRead,
            UserPermission.QuizPortfoliosSave,
        ]}
        rowClassName={getRowClassNameArchive}
        onRow={getRowHandlers({ url: ROUTE_NAMES.QUIZ_PORTFOLIOS_VIEW })}
    />
))
