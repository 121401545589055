import React from 'react'
import { API_BASE_URL } from 'consts/env.consts'
import { Button } from 'components/shared/Button'
import { ColumnsType } from 'antd/lib/table'
import { CourseContract } from 'core/api'
import { CustomTableActionsProps } from 'components/shared/DynamicTableAdapter/DynamicTableAdapter.types'
import { EditableTextControl, SwitchControl } from 'components/controls'
import { FnActionRequiredProps, WithFormModeProps } from 'App.types'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import styles from '../AdditionalMaterials.module.scss'
import { FormDataDocumentsProps } from '../../../../TrackForm.types'

/** Настройка отображения колонок для документов */
export const getRenderColumnFn = (
    formMode: WithFormModeProps['formMode'],
    disabledForEdit?: boolean
) => (
    onChangeRow: FnActionRequiredProps<FormDataDocumentsProps>
): ColumnsType<FormDataDocumentsProps> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        width: '40%',
        render: (name, record) => (
            <EditableTextControl
                editable={{
                    onChange: (value) =>
                        onChangeRow({ ...record, name: value }),
                    tooltip: false,
                    autoSize: { maxRows: 1 },
                    editing: !isFormModeView(formMode),
                }}
                text={name}
                formMode={formMode}
                disabled={disabledForEdit}
            />
        ),
    },
    {
        title: LOCAL.LABELS.FORMAT,
        dataIndex: 'format',
        key: 'format',
    },
    {
        title: LOCAL.LABELS.AVAILABILITY,
        dataIndex: 'availability',
        key: 'availability',
        render: (isAvailability, record) => (
            <SwitchControl
                checked={isAvailability}
                onChange={(value) => {
                    onChangeRow({ ...record, availability: value })
                }}
                formMode={formMode}
                addonAfter={
                    <div className={styles.control}>
                        {isAvailability
                            ? LOCAL.LABELS.ALWAYS
                            : LOCAL.LABELS.AFTER_PASSING}
                    </div>
                }
                disabled={disabledForEdit}
            />
        ),
    },
]

/**
 * Нормалайзер для вывода сущности в таблице после выбора в модальном окне
 * @param newData новые данные
 * @param oldData старые данные
 */
export const normalizeFn = (
    newData: CourseContract[],
    oldData?: FormDataDocumentsProps[]
) =>
    newData.map((el) => {
        const oldItem = oldData?.find((oldEl) => oldEl.id === el.id)

        return {
            id: el.id,
            additionalMaterialId: oldItem?.additionalMaterialId,
            name: oldItem?.name || el.name,
            availability: oldItem?.availability ?? true,
            format: el.format,
            structuralUnitId: el.structuralUnit.id,
            launchUrl: el.launchUrl,
        }
    })

/**
 * Рендер кнопок действия для таблицы выбора видео
 * @param rowInfo данные строки таблицы
 */
export const renderTableActions = ({
    record,
    disabled,
    formMode,
    onRemoveRow,
}: CustomTableActionsProps<FormDataDocumentsProps>) => (
    <>
        <Button
            type="link"
            href={`${API_BASE_URL}/${record.launchUrl}`}
            icon={<IconsAdapter iconType="EyeOutlined" />}
            targetBlank
        />

        {!isFormModeView(formMode) && (
            <Button
                type="link"
                icon={<IconsAdapter iconType="DeleteOutlined" />}
                onClick={onRemoveRow(record.id)}
                disabled={disabled}
            />
        )}
    </>
)
