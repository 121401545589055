import cn from 'classnames'
import React, { FC, memo, useCallback } from 'react'
import { ContentTitle } from 'components/shared/ContentTitle'
import { LOCAL } from 'core/local'

import styles from './PassingHeader.module.scss'
import { PassingHeaderProps } from './PassingHeader.types'

/** Заголовок прохождения */
export const PassingHeader: FC<PassingHeaderProps> = memo(
    ({
        numberOfAnswers,
        numberOfQuestions,
        hasUnsavedChanges,
        styles: externalStyles,
        title,
    }) => {
        const renderTitle = useCallback((title) => <div>{title}</div>, [])

        return (
            <ContentTitle
                className={cn(
                    styles.wrapper,
                    !externalStyles && styles.wrapperDefault
                )}
                renderTitle={externalStyles ? renderTitle : undefined}
                title={title}
                disableBoxShadow
            >
                {hasUnsavedChanges && (
                    <div className={styles.changes}>
                        {LOCAL.LABELS.HAS_UNSAVED_CHANGES}
                    </div>
                )}

                {numberOfQuestions && (
                    <div className={styles.counter}>
                        {`${numberOfAnswers} / ${numberOfQuestions}`}
                    </div>
                )}
            </ContentTitle>
        )
    }
)
