import {
    AppointmentAssessmentExpertListContract,
    DictionaryContract,
} from 'core/api'
import { ColumnsType } from 'antd/lib/table/interface'
import { DictionariesStoreProps } from 'core/helpers/DictionariesHelper'
import { LOCAL } from 'core/local'
import { defaultSorter } from 'components/layouts'
import { formatDate, renderAppointmentStatus } from 'utils'

import { renderExpertListActions } from './AssessmentAssignedExpert.utils'

/** настройка отображения колонок таблицы назначенных оценок эксперта */
export const getColumns = ({
    assessmentTypeDictionary,
}: DictionariesStoreProps): ColumnsType<
    AppointmentAssessmentExpertListContract
> => [
    {
        title: LOCAL.LABELS.ASSESSMENT_TITLE,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.ASSESSMENT_TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type, record) => assessmentTypeDictionary[record.type],
    },
    {
        title: LOCAL.LABELS.ASSESSMENT_GOAL,
        dataIndex: 'purpose',
        key: 'purpose',
        render: (purpose: DictionaryContract) => purpose?.name,
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'evaluationPeriodFrom',
        key: 'evaluationPeriodFrom',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'evaluationPeriodTo',
        key: 'evaluationPeriodTo',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderAppointmentStatus,
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.ACTIONS,
        width: 150,
        render: renderExpertListActions,
        align: 'right',
    },
]
