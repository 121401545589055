import find from 'lodash/find'
import React, { useEffect, useState } from 'react'
import { CompetencesPublicQuestionsContract } from 'core/api'
import { ControlGroup } from 'components/controls'
import { Form } from 'antd'

import styles from './CompetenceGroupQuestions.module.scss'
import {
    CompetenceGroupQuestionsProps,
    QuestionsListProps,
} from './CompetenceGroupQuestions.types'
import { QuestionnairePassingAnswer } from '../QuestionnairePassingAnswer'

/** Страница прохождения опроса слушателем */
export const CompetenceGroupQuestions: React.FC<CompetenceGroupQuestionsProps> = React.memo(
    ({
        competenceGroup,
        competenceQuestions,
        confirmedAnswers,
        isAssessment,
        showQuestionsByBlock,
        getBlockedByQuestionEndToEndNumber,
        onConfirmAnswers,
        setIsLast,
        disabledQuestionsTimer,
    }) => {
        const [{ questionsList, currentNumber }, setQuestionsList] = useState<
            QuestionsListProps
        >({ questionsList: competenceQuestions[0], currentNumber: 0 })

        /**
         * Вопросы группы компетенций
         */
        const getQuestions = (group: CompetencesPublicQuestionsContract) =>
            group.questions.map((question) => (
                <Form.Item
                    name={['answers', String(question.id)]}
                    key={question.id}
                >
                    <QuestionnairePassingAnswer
                        question={question}
                        confirmedAnswer={find(confirmedAnswers, [
                            'questionId',
                            question.id,
                        ])}
                        getBlockedByQuestionEndToEndNumber={
                            getBlockedByQuestionEndToEndNumber
                        }
                        onConfirmAnswers={onConfirmAnswers}
                        disabledQuestionsTimer={disabledQuestionsTimer}
                    />
                </Form.Item>
            ))

        const renderQuestionsCompetenceGroup = () => {
            if (showQuestionsByBlock) {
                const allQuestionsAreAnswered = questionsList?.questions.every(
                    ({ id }) =>
                        confirmedAnswers.find((item) => item.questionId === id)
                )

                if (
                    allQuestionsAreAnswered &&
                    currentNumber < competenceQuestions.length - 1
                ) {
                    setQuestionsList((prev) => ({
                        currentNumber: prev.currentNumber + 1,
                        questionsList:
                            competenceQuestions[prev.currentNumber + 1],
                    }))
                }

                return questionsList && getQuestions(questionsList)
            }

            return competenceQuestions.map((competenceQuestions) =>
                getQuestions(competenceQuestions)
            )
        }

        useEffect(() => {
            if (currentNumber === competenceQuestions.length - 1) {
                setIsLast()
            }
        }, [competenceQuestions.length, currentNumber, setIsLast])

        return (
            <ControlGroup
                className={styles.wrapper}
                noPadding={!isAssessment}
                type={isAssessment ? 'bordered' : undefined}
            >
                {isAssessment && competenceGroup && (
                    <h2 className={styles.groupName}>{competenceGroup.name}</h2>
                )}

                {renderQuestionsCompetenceGroup()}
            </ControlGroup>
        )
    }
)
