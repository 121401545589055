import React, { useCallback } from 'react'
import { APPOINTMENT_STATUS_MAPPED, ROW_GUTTER } from 'consts'
import { AppointmentSearchContract } from 'core/api'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'

/**
 * Форма фильтрации назначенных оценок эксперта
 */
export const AssessmentAssignedExpertFilters: React.FC<FiltersProps<
    AppointmentSearchContract
>> = React.memo(({ onSetFilters, ...props }) => {
    const handleFinish = useCallback(
        ({ purposeName, ...restValues }) => {
            onSetFilters({
                ...restValues,
                appointmentAssessmentSearch: {
                    purposeName,
                },
            } as AppointmentSearchContract)
        },
        [onSetFilters]
    )

    return (
        <ControlGroup type="light">
            <FilterForm {...props} onSetFilters={handleFinish}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="purposeName"
                            label={LOCAL.LABELS.ASSESSMENT_GOAL}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={APPOINTMENT_STATUS_MAPPED}
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
