import template from 'lodash/template'
import React, { useCallback, useEffect, useMemo } from 'react'
import { ActivityPassingInfoContract, ActivityPassingService } from 'core/api'
import { Button } from 'components/shared/Button'
import { INFO_ASSISTANT_EVENT } from 'components/pages/TrackPreviewPassing/TrackPassing.events'
import { LOCAL } from 'core/local'
import { allBlocksAreRequired, checkAllRequiredBlocksPassed } from 'utils'
import { eventEmitter } from 'core/helpers/eventEmitter'

import styles from './FinishActivityModal.module.scss'
import { FinishActivityModalProps } from './FinishActivityModal.types'
import { IconsAdapter } from '../IconsAdapter'
import { Popup } from '../Popup'

export const FinishActivityModal: React.FC<FinishActivityModalProps> = React.memo(
    ({
        visible,
        openModal,
        activityId,
        appointmentActivityId,
        trackStagePassingId,
        closeModal,
        finishActivity,
        isNestedActivity,
        trackPassingId,
        currentStageNumber,
        eventName,
        activityLessons,
    }) => {
        const fetchFinishEvent = useCallback(async () => {
            if (trackStagePassingId && !isNestedActivity) {
                await ActivityPassingService.finish({
                    body: {
                        activityId,
                        trackStagePassingId,
                    } as ActivityPassingInfoContract,
                })

                finishActivity({ updateAfterFinishEvent: true })

                eventEmitter.emit(INFO_ASSISTANT_EVENT.STAGE_END, {
                    trackPassingId,
                    stageNumber: currentStageNumber,
                })

                eventEmitter.emit(INFO_ASSISTANT_EVENT.TRACK_END)
            }

            if (appointmentActivityId && !isNestedActivity) {
                await ActivityPassingService.finish({
                    body: {
                        activityId,
                        appointmentActivityId: +appointmentActivityId,
                    } as ActivityPassingInfoContract,
                })

                finishActivity()
            }

            if (isNestedActivity) {
                finishActivity()
            }
        }, [
            appointmentActivityId,
            trackStagePassingId,
            isNestedActivity,
            activityId,
            finishActivity,
            trackPassingId,
            currentStageNumber,
        ])

        const finishEvent = useCallback(async () => {
            try {
                await fetchFinishEvent()

                closeModal()
            } catch (error) {
                console.error(error)
            }
        }, [closeModal, fetchFinishEvent])

        const modalFooter = useMemo(
            () => (
                <div>
                    <Button onClick={closeModal}>{LOCAL.ACTIONS.CANCEL}</Button>

                    <Button onClick={finishEvent}>
                        {LOCAL.ACTIONS.CONFIRM}
                    </Button>
                </div>
            ),
            [closeModal, finishEvent]
        )

        useEffect(() => {
            if (!checkAllRequiredBlocksPassed(activityLessons)) return

            if (allBlocksAreRequired(activityLessons)) {
                fetchFinishEvent()

                return
            }

            openModal()
        }, [openModal, activityLessons, fetchFinishEvent])

        return (
            <Popup
                className={styles.wrapper}
                visible={visible}
                footer={modalFooter}
                closable={false}
            >
                <div className={styles.flex}>
                    <IconsAdapter
                        iconType="ExclamationCircleOutlined"
                        className={styles.icon}
                    />

                    <div>
                        <div className={styles.title}>
                            {LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT}
                        </div>

                        <div className={styles.message}>
                            {template(
                                LOCAL.MESSAGES
                                    .ALL_MANDATORY_BLOCKS_FINISH_CONFIRM_MESSAGE
                            )({
                                eventName,
                            })}
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
)
