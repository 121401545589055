import React from 'react'
import cn from 'classnames'
import { Button } from 'components/shared/Button'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker } from 'components/controls'
import {
    convertToShouldUpdateDep,
    getBackgroundImageStyle,
    getStyles,
} from 'utils'

import styles from './FullScreenCanvas.module.scss'
import { CANVAS_DEPENDENCIES } from '../EventFormVisualization/EventFormVisualization.consts'
import { FullScreenBlocks } from '../FullScreenBlocks'

/**
 * Отображение для мероприятия типа - "fullscreen"
 */
export const FullScreenCanvas: React.FC = React.memo(() => (
    <main className={styles.wrapper}>
        <ShouldUpdateChecker
            fieldPath={convertToShouldUpdateDep(CANVAS_DEPENDENCIES)}
        >
            {({ getFieldsValue }) => {
                const { name, announcement, canvas, lessons } = getFieldsValue(
                    CANVAS_DEPENDENCIES
                )

                return (
                    <div
                        className={styles.body}
                        style={getBackgroundImageStyle(
                            canvas.canvasBackgroundUrl
                        )}
                    >
                        <h2
                            className={styles.eventTitle}
                            style={getStyles(canvas.titleSettings)}
                        >
                            {name}
                        </h2>

                        <pre
                            className={styles.eventDescription}
                            style={getStyles(canvas.descriptionSettings)}
                        >
                            {announcement}
                        </pre>

                        <h3
                            className={styles.blockTitle}
                            style={getStyles(canvas.blockNameSettings)}
                        >
                            {lessons?.[0]?.name}
                        </h3>

                        <div
                            style={{
                                textAlign: canvas.buttonAlign,
                            }}
                        >
                            <Button
                                className={cn(
                                    styles.startButton,
                                    'capitalize-text'
                                )}
                                style={{
                                    background: canvas.buttonColor,
                                    color: canvas.buttonTextColor,
                                    borderColor: canvas.buttonColor,
                                }}
                            >
                                {LOCAL.ACTIONS.PROCEED}
                            </Button>
                        </div>

                        {!!lessons?.length && (
                            <FullScreenBlocks
                                thematicBlocksCount={lessons.length}
                                {...canvas}
                            />
                        )}
                    </div>
                )
            }}
        </ShouldUpdateChecker>
    </main>
))
