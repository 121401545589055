import React, { useCallback } from 'react'
import { Col, Form, Row } from 'antd'
import {
    ColorPickerControl,
    FormItemAdapter,
    RadioButtonControl,
    SelectControl,
} from 'components/controls'
import {
    FONT_NAMES_MAPPED,
    FONT_SIZES,
    LINE_HEIGHTS,
    ROW_GUTTER,
    TEXT_ALIGN_OPTION_MAPPED,
} from 'consts'
import { LOCAL } from 'core/local'
import { SpaceAdapter } from 'components/shared/SpaceAdapter'
import { normalizeDictionaryToControl } from 'utils'

import styles from './WindowTextSettings.module.scss'
import { FONT_STYLE_OPTIONS } from './WindowTextSettings.consts'
import { PRESENTATION_FORM_FIELD_NAME } from '../../PortfolioPresentation.consts'
import { WindowTextSettingsProps } from './WindowTextSettings.types'

/** Компонент настроек текста окон оформления */
export const WindowTextSettings: React.FC<WindowTextSettingsProps> = React.memo(
    ({ type, windowFormFieldName, formMode, disabled }) => {
        /** Получить имя элемента формы окна оформления
         * @param name имя Form.Item без вложенной структуры
         */
        const getPresentationWindowFieldName = useCallback(
            (name: string) => [
                PRESENTATION_FORM_FIELD_NAME,
                windowFormFieldName,
                type,
                name,
            ],
            [windowFormFieldName, type]
        )

        return (
            <Row gutter={ROW_GUTTER}>
                <Col xs={8 * 2}>
                    <FormItemAdapter
                        fieldName={getPresentationWindowFieldName('fontName')}
                        label={LOCAL.LABELS.FONT}
                    >
                        <SelectControl
                            formMode={formMode}
                            values={FONT_NAMES_MAPPED}
                            disabled={disabled}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName={getPresentationWindowFieldName('fontSize')}
                        label={LOCAL.LABELS.SIZE}
                    >
                        <SelectControl
                            formMode={formMode}
                            values={normalizeDictionaryToControl(
                                FONT_SIZES,
                                true
                            )}
                            disabled={disabled}
                        />
                    </FormItemAdapter>
                </Col>

                <div className={styles.flex}>
                    <Col xs={8 * 2}>
                        <SpaceAdapter
                            justifyContent="space-between"
                            align="end"
                            fullWidth
                        >
                            <Form.Item
                                name={getPresentationWindowFieldName(
                                    'textAlign'
                                )}
                            >
                                <RadioButtonControl
                                    formMode={formMode}
                                    className={styles.radio}
                                    values={TEXT_ALIGN_OPTION_MAPPED}
                                    buttonStyle="solid"
                                    disabled={disabled}
                                />
                            </Form.Item>

                            <Form.Item
                                name={getPresentationWindowFieldName(
                                    'fontStyle'
                                )}
                            >
                                <RadioButtonControl
                                    formMode={formMode}
                                    className={styles.radio}
                                    values={normalizeDictionaryToControl(
                                        FONT_STYLE_OPTIONS
                                    )}
                                    buttonStyle="solid"
                                    disabled={disabled}
                                />
                            </Form.Item>

                            <FormItemAdapter
                                fieldName={getPresentationWindowFieldName(
                                    'textColor'
                                )}
                                label={LOCAL.LABELS.COLOR}
                            >
                                <ColorPickerControl
                                    className={styles.colorPicker}
                                    formMode={formMode}
                                    disabled={disabled}
                                />
                            </FormItemAdapter>
                        </SpaceAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName={getPresentationWindowFieldName(
                                'lineHeight'
                            )}
                            label={LOCAL.LABELS.LINE_HEIGHT}
                        >
                            <SelectControl
                                formMode={formMode}
                                values={normalizeDictionaryToControl(
                                    LINE_HEIGHTS,
                                    true
                                )}
                                disabled={disabled}
                            />
                        </FormItemAdapter>
                    </Col>
                </div>
            </Row>
        )
    }
)
