import {
    AppointmentContract,
    AppointmentPublishContract,
    AppointmentTestCreateContract,
    AppointmentTestParticipantsGroupCreateContract,
} from 'core/api'
import {
    getItemId,
    getValue,
    isObjectEmpty,
    normalizeDataForSelectAndRadio,
} from 'utils'

import { MIN_DAYS_TO_NOTIFICATION } from './TestAssignmentForm.consts'
import { TestAssignmentFormSubmitValues } from './TestAssignmentForm.types'

/**
 * Получить значения для отправки на бэк
 * @param values текущие значения формы
 * @param id идентификатор назначения
 * @returns {Partial<AppointmentPublishContract>} объект запроса на сервер
 */
export const mapFormDataToRequest = (
    {
        portfolioId,
        attemptsLimited,
        attemptsLimit,
        participantsIds,
        reviewerIds,
        organizationsIds,
        ...restValues
    }: Partial<TestAssignmentFormSubmitValues>,
    id?: number
): Partial<AppointmentPublishContract> => {
    const defaultGroup: Partial<AppointmentTestParticipantsGroupCreateContract> = {
        attemptsLimited,
        attemptsLimit,
        participantsIds,
    }

    const testParameters = {
        portfolioId,
        groups:
            !isObjectEmpty(defaultGroup, true) && participantsIds?.length
                ? [{ ...defaultGroup, number: 1 }]
                : undefined,
        reviewerIds,
    } as AppointmentTestCreateContract

    return {
        ...restValues,
        id,
        organizationsIds: organizationsIds?.map(getValue) as number[],
        test: !isObjectEmpty(testParameters, true) ? testParameters : undefined,
    }
}

/**
 * Получить начальные значения для формы назначения
 * @param assignment объект назначения
 */
export const mapResponseToFormData = (assignment: AppointmentContract) => ({
    name: assignment.name,
    evaluationPeriodTo: assignment.evaluationPeriodTo,
    evaluationPeriodFrom: assignment.evaluationPeriodFrom,
    daysToStartNotification: assignment.daysToStartNotification,
    daysToEndNotification: assignment.daysToEndNotification,
    portfolioId: assignment.test?.portfolio?.id,
    reviewerIds: assignment.test?.reviewers?.map(getItemId),
    attemptsLimited: assignment.test?.groups[0]?.attemptsLimited,
    attemptsLimit: assignment.test?.groups[0]?.attemptsLimit,
    participantsIds: assignment.test?.groups[0]?.participants.map(getItemId),
    organizationsIds: assignment.organizations?.map(
        normalizeDataForSelectAndRadio
    ),
    forAllOrganizations: assignment.forAllOrganizations,
})

export const getValueFromEventNotificationField = (value?: number) =>
    value == null || value < MIN_DAYS_TO_NOTIFICATION
        ? MIN_DAYS_TO_NOTIFICATION
        : value
