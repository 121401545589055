import React, { useReducer } from 'react'
import { CKEditorAdapter } from 'components/shared'
import { editorReducer } from 'components/shared/CKEditorAdapter/CKEditorAdapter.utils'
import { isUndefined } from 'lodash'

import styles from './Message.module.scss'
import { MessageProps } from './Message.types'
import { PREVIEW_STYLES } from '../EmailPreviewForm.consts'

/** Обертка для редактора. Нужна для корректного вывода сообщения при многократном открытии модалки-родителя */
export const Message: React.FC<MessageProps> = React.memo(
    ({ value, onChange, message }) => {
        const [, dispatch] = useReducer(editorReducer, {
            styles: PREVIEW_STYLES,
        })

        return (
            <>
                {(!isUndefined(message) || !isUndefined(value)) && (
                    <CKEditorAdapter
                        dispatchEvent={dispatch}
                        newValue={message}
                        readOnly
                        value={value}
                        onChange={onChange}
                        height="500px"
                        className={styles.message}
                    />
                )}
            </>
        )
    }
)
