import React, { useCallback } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Col, Form, Row } from 'antd'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import {
    FormItemAdapter,
    RadioButtonControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { FormListOperation } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'
import { PassingType } from 'core/api'
import { ROW_GUTTER, TEST_SEQUENCE_MAPPED } from 'consts'
import { isFormModeView, isPassingTypeConsistently } from 'utils'

import styles from './TrackStages.module.scss'
import { GetStagesMappedProps, TrackStagesProps } from './TrackStages.types'
import { INITIAL_STAGE_VALUES_DEFAULT } from './TrackStages.consts'
import { StagesGroup } from '../StagesGroup'
import { USE_INFORMATION_ASSISTANT_FIELD_NAME } from '../../../TrackForm.consts'
import { makeStagesGroups } from './TrackStages.utils'

/**
 * Компонент отображения этапов трека
 */
export const TrackStages: React.FC<TrackStagesProps> = React.memo(
    ({ form, formMode, disabled }) => {
        /**
         * Обработчик окончания переноса DnD
         * @param result результирующий объект
         */
        const handleReorderStages = useCallback(
            (result: DropResult) => {
                const stages = form?.getFieldValue('stages')

                if (!result.destination) {
                    return
                }

                const [sourceStage] = stages.splice(result.source.index, 1)

                stages.splice(result.destination.index, 0, sourceStage)

                form?.setFieldsValue({
                    stages,
                })
            },
            [form]
        )

        const handleDragStart = useCallback(() => {
            /** Снятие фокуса с активного элемента при перемещении элемента */
            const focusedElement = document.activeElement as HTMLInputElement
            focusedElement.blur()
        }, [])

        /**
         * Маппинг для этапов трека
         * @param stages этапы трека
         * @param removeStage функция для удаления этапа
         */
        const getStagesMapped = useCallback(
            ({ stages, removeStage, isDisabled }: GetStagesMappedProps) =>
                Object.entries(
                    makeStagesGroups(stages)
                ).map(([groupKey, groupStages]) => (
                    <StagesGroup
                        key={groupKey}
                        groupKey={groupKey}
                        groupStages={groupStages}
                        removeStage={removeStage}
                        isDisabled={isDisabled}
                        formMode={formMode}
                    />
                )),
            [formMode]
        )

        /**
         * Обработчик добавления этапов
         * @param {FormListOperation} объект с возможными вариантами операций (добавление, удаление)
         */
        const handleAddStage = useCallback(
            (
                { add }: Partial<FormListOperation>,
                passingType?: PassingType,
                useInformationAssistant?: boolean
            ) => () => {
                add?.({
                    ...INITIAL_STAGE_VALUES_DEFAULT,
                    isPassingRequired: isPassingTypeConsistently(passingType),
                    assistantEvents: useInformationAssistant ? [undefined] : [],
                })
            },
            []
        )

        return (
            <>
                <Row gutter={ROW_GUTTER} className={styles.wrapper}>
                    <Col xs={24}>
                        <ShouldUpdateChecker
                            fieldPath={['isTrackWithVisualisation']}
                        >
                            {({ getFieldValue }) => {
                                const isTrackWithVisualisation = getFieldValue(
                                    'isTrackWithVisualisation'
                                )

                                return (
                                    <FormItemAdapter
                                        fieldName="passingType"
                                        label={
                                            LOCAL.LABELS.STAGES_PASSING_ORDER
                                        }
                                    >
                                        <RadioButtonControl
                                            values={TEST_SEQUENCE_MAPPED}
                                            buttonStyle="solid"
                                            disabled={
                                                disabled ||
                                                !isTrackWithVisualisation
                                            }
                                            formMode={formMode}
                                            className={styles.radio}
                                        />
                                    </FormItemAdapter>
                                )
                            }}
                        </ShouldUpdateChecker>
                    </Col>
                </Row>

                <DragDropContext
                    onDragEnd={handleReorderStages}
                    onDragStart={handleDragStart}
                >
                    <Form.List name="stages">
                        {(stages, { add, remove }) => (
                            <>
                                <div className={styles.stages}>
                                    {getStagesMapped({
                                        stages,
                                        removeStage: remove,
                                        isDisabled: disabled,
                                    })}
                                </div>

                                <AddButton
                                    className={styles.add}
                                    onClick={handleAddStage(
                                        { add },
                                        form?.getFieldValue('passingType'),
                                        form?.getFieldValue(
                                            USE_INFORMATION_ASSISTANT_FIELD_NAME
                                        )
                                    )}
                                    disabled={
                                        disabled || isFormModeView(formMode)
                                    }
                                    buttonText={LOCAL.ACTIONS.ADD_STAGE}
                                />
                            </>
                        )}
                    </Form.List>
                </DragDropContext>
            </>
        )
    }
)
