import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { CourseSearchResultContract } from 'core/api'
import { LOCAL } from 'core/local'
import { defaultSorter } from 'components/layouts'
import { formatDate, getCourseNameWithVersion, restrictBigText } from 'utils'

import { TrainingMaterialsTableActions } from './components'

/** Настройка отображения колонок для реестра обучающих материалов */
export const TRAINING_MATERIALS_TABLE_COLUMNS: ColumnsType<CourseSearchResultContract> = [
    {
        dataIndex: 'name',
        key: 'name',
        title: LOCAL.LABELS.NAME,
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        render: (_, row) => restrictBigText(getCourseNameWithVersion(row)),
        fixed: 'left',
        width: '350px',
    },
    {
        title: LOCAL.LABELS.FORMAT,
        dataIndex: 'formatDescription',
        key: 'formatDescription',
    },
    {
        title: LOCAL.LABELS.OWNER,
        dataIndex: ['structuralUnit', 'name'],
        key: 'structuralUnit',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.VERSION,
        dataIndex: 'courseVersion',
        width: 200,
        key: 'courseVersion',
    },
    {
        title: LOCAL.LABELS.UPLOAD_DATE,
        dataIndex: 'uploadDate',
        key: 'uploadDate',
        render: formatDate,
        sorter: defaultSorter,
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        align: 'right',
        render: (tableRow) => (
            <TrainingMaterialsTableActions tableRow={tableRow} />
        ),
        fixed: 'right',
    },
]
