import React, { useCallback } from 'react'
import {
    CanvasImagesWithInfiniteScroll,
    VisualizationOptionsGroup,
} from 'components/shared'
import {
    ColorPickerControl,
    InputNumberControl,
    RadioWithSpoilerControl,
    ShadowPickerControl,
} from 'components/controls'
import { Form } from 'antd'
import { ImgsUploadFnResponseProps } from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.types'
import { LOCAL } from 'core/local'
import { TrackCanvasIconsService } from 'core/api'
import { canvasIconMapFn } from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.utils'
import {
    trackStageOptionsDuration,
    trackStageOptionsRepeat,
    trackStageOptionsStart,
} from 'components/pages/Track/components/TrackForm/TrackForm.consts'

import styles from '../SingleStageVisualisationOptions/SingleStageVisualisationOptions.module.scss'
import {
    MAX_PULSE_TIME,
    MIN_PULSE_TIME,
} from '../TrackStageDnD/TrackStageDnD.consts'
import { SingleStageBackgroundImages } from '../SingleStageBackgroundImages'
import { SingleStageVisualisationIconOption } from '../SingleStageVisualisationIconOption'
import { SingleStageVisualisationOptionTabProps } from './SingleStageVisualisationOptionTab.types'
import {
    canvasIconsFetchFn,
    canvasIconsUploadFn,
    getPopupContainerForIconRemove,
} from '../SingleStageVisualisationOptions/SingleStageVisualisationOptions.utils'

/**
 * Компонент настройки визуализации: Tab
 */
export const SingleStageVisualisationOptionTab: React.FC<SingleStageVisualisationOptionTabProps> = React.memo(
    ({
        stageIndex,
        stageStatus,
        hideBackgroundImagePicker,
        form,
        canvasKey,
    }) => {
        /**
         * Обработчик выбора фона полотна
         */
        const handleIconBgChoose = useCallback(
            (value?: ImgsUploadFnResponseProps) => {
                form?.setFields([
                    {
                        name: [
                            'trackCanvases',
                            canvasKey,
                            'trackStageStyles',
                            stageIndex,
                            'icons',
                            stageStatus,
                            'meta',
                            'currentIconUrl',
                        ],
                        value: value?.url,
                    },
                ])
            },
            [form, stageIndex, stageStatus, canvasKey]
        )

        return (
            <>
                <CanvasImagesWithInfiniteScroll
                    fileListTitle={LOCAL.LABELS.ICONS_SET}
                    imgMapFn={canvasIconMapFn}
                    imgsFetchFn={canvasIconsFetchFn(stageStatus)}
                    imgsUploadFn={canvasIconsUploadFn(stageStatus)}
                    controlName={[
                        'trackCanvases',
                        canvasKey,
                        'trackStageStyles',
                        stageIndex,
                        'icons',
                        stageStatus,
                        'iconId',
                    ]}
                    controlMetaName={[
                        'trackCanvases',
                        canvasKey,
                        'trackStageStyles',
                        stageIndex,
                        'icons',
                        stageStatus,
                        'meta',
                        'currentIconUrl',
                    ]}
                    downloadBtnText={LOCAL.LABELS.ICON_DOWNLOAD}
                    className={styles.icons}
                    onImgChoose={handleIconBgChoose}
                    getContainerForPopup={getPopupContainerForIconRemove}
                    imgsFetchCount={12}
                    deleteMethod={TrackCanvasIconsService.delete}
                />

                <SingleStageVisualisationIconOption
                    stageIndex={stageIndex}
                    stageStatus={stageStatus}
                    canvasKey={canvasKey}
                />

                {!hideBackgroundImagePicker && (
                    <SingleStageBackgroundImages
                        stageIndex={stageIndex}
                        stageStatus={stageStatus}
                        canvasKey={canvasKey}
                    />
                )}

                <VisualizationOptionsGroup
                    title={LOCAL.LABELS.STAGE_EFFECTS}
                    className={styles.effects}
                >
                    <section className={styles.subGroup}>
                        <h3 className={styles.subGroupTitle}>
                            {LOCAL.LABELS.SHADOW_LIGHT}
                        </h3>

                        <Form.Item
                            name={[
                                'trackCanvases',
                                canvasKey,
                                'trackStageStyles',
                                stageIndex,
                                'icons',
                                stageStatus,
                                'haveShadow',
                            ]}
                        >
                            <ShadowPickerControl
                                label={LOCAL.LABELS.TEMPLATES}
                            />
                        </Form.Item>

                        <Form.Item
                            name={[
                                'trackCanvases',
                                canvasKey,
                                'trackStageStyles',
                                stageIndex,
                                'icons',
                                stageStatus,
                                'shadowColor',
                            ]}
                        >
                            <ColorPickerControl label={LOCAL.LABELS.COLOR} />
                        </Form.Item>
                    </section>

                    <section className={styles.subGroup}>
                        <h3 className={styles.subGroupTitle}>
                            {LOCAL.LABELS.PULSE}
                        </h3>

                        <Form.Item
                            name={[
                                'trackCanvases',
                                canvasKey,
                                'trackStageStyles',
                                stageIndex,
                                'icons',
                                stageStatus,
                                'effectTime',
                            ]}
                        >
                            <InputNumberControl
                                min={MIN_PULSE_TIME}
                                max={MAX_PULSE_TIME}
                                placeholder={String(MIN_PULSE_TIME)}
                                addonAfter={LOCAL.LABELS.PULSE_TIME}
                            />
                        </Form.Item>

                        <RadioWithSpoilerControl
                            label={LOCAL.LABELS.START}
                            values={trackStageOptionsStart}
                            name={[
                                'trackCanvases',
                                canvasKey,
                                'trackStageStyles',
                                stageIndex,
                                'icons',
                                stageStatus,
                                'effectStart',
                            ]}
                        />

                        <RadioWithSpoilerControl
                            label={LOCAL.LABELS.DURATION}
                            values={trackStageOptionsDuration}
                            name={[
                                'trackCanvases',
                                canvasKey,
                                'trackStageStyles',
                                stageIndex,
                                'icons',
                                stageStatus,
                                'effectDuration',
                            ]}
                        />

                        <RadioWithSpoilerControl
                            label={LOCAL.LABELS.REPEAT}
                            values={trackStageOptionsRepeat}
                            name={[
                                'trackCanvases',
                                canvasKey,
                                'trackStageStyles',
                                stageIndex,
                                'icons',
                                stageStatus,
                                'effectRepeat',
                            ]}
                        />
                    </section>
                </VisualizationOptionsGroup>
            </>
        )
    }
)
