import React from 'react'
import classNames from 'classnames'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'

import styles from './FiltersToggleButton.module.scss'
import { FiltersToggleButtonProps } from './FiltersToggleButton.types'

/**
 * Кнопка переключения видимости фильтров
 */
export const FiltersToggleButton: React.FC<FiltersToggleButtonProps> = React.memo(
    ({
        onToggleFilterVisibility,
        buttonText = LOCAL.LABELS.FILTERS,
        className,
        hideText,
        icon,
        isActive,
    }) => {
        const classes = classNames(
            styles.wrapper,
            isActive && styles.wrapperActive,
            className
        )

        return (
            <Button
                onClick={onToggleFilterVisibility}
                type="text"
                icon={icon || <IconsAdapter iconType="CustomIconFilter" />}
                className={classes}
            >
                {!hideText && buttonText}
            </Button>
        )
    }
)
