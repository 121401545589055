import { DictionaryContract, UserRegistryContract } from 'core/api'

export const isStructuralUnitChanged = (
    structuralUnits: DictionaryContract[],
    user: UserRegistryContract
) => {
    if (!user.lastArchiveVersion) return false

    return !user.lastArchiveVersion.userStructuralUnitPositions.find((el) =>
        el.structuralUnits.find((lastUnit) =>
            structuralUnits.find((unit) => unit.id === lastUnit.id)
        )
    )
}

export const isPositionChanged = (
    position: DictionaryContract,
    user: UserRegistryContract
) => {
    if (!user.lastArchiveVersion) return false

    return !user.lastArchiveVersion?.userStructuralUnitPositions.find(
        (el) => el.position.id === position.id
    )
}
