import React, { useEffect } from 'react'
import { AssessmentReportManagerService } from 'core/api'
import {
    COMPETENCIES_PRIORITIES_WITHOUT_PP,
    COMPETENCIES_PRIORITIES_WITH_PP,
} from 'consts'
import { LOCAL } from 'core/local'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './TableOfDevelopment.module.scss'
import stylesReportView from '../ReportView/ReportView.module.scss'
import { TableOfDevelopmentProps } from './TableOfDevelopment.types'

/** Отчёт по оценке руководителя. Уровни развития компетенций */
export const TableOfDevelopment: React.FC<TableOfDevelopmentProps> = React.memo(
    ({ withProfilePosition }) => {
        const { reportId } = useParams<UrlParamsProps>()
        const [getData, data] = useHttp(
            withProfilePosition
                ? AssessmentReportManagerService.getTablefDevelopmentWithProfilePosition
                : AssessmentReportManagerService.getTablefDevelopmentWithoutProfilePosition
        )

        const dictionary = withProfilePosition
            ? COMPETENCIES_PRIORITIES_WITH_PP
            : COMPETENCIES_PRIORITIES_WITHOUT_PP

        useEffect(() => {
            if (reportId) {
                getData({ id: Number(reportId) })
            }
        }, [getData, reportId])

        return data?.length ? (
            <div className={styles.wrapper}>
                {data.map((item) => (
                    <React.Fragment key={item.priority}>
                        <h3 className={styles.priority}>
                            {dictionary[item.priority]}
                        </h3>

                        {item.competencies.map((competence) => (
                            <div
                                key={competence.id}
                                className={styles.competence}
                            >
                                {competence.name}
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        ) : (
            <p className={stylesReportView.text}>
                {LOCAL.LABELS.INSUFFICIENT_DATA}
            </p>
        )
    }
)

export const TableOfDevelopmentWithProfilePosition: React.FC = () => (
    <TableOfDevelopment withProfilePosition />
)
