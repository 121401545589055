import React from 'react'
import cn from 'classnames'
import { Result } from 'antd'

import styles from './ResultControl.module.scss'
import { ResultControlProps } from './ResultControl.types'

export const ResultControl: React.FC<ResultControlProps> = React.memo(
    ({ className, ...restProps }) => (
        <Result {...restProps} className={cn(styles.wrapper, className)} />
    )
)
