import React, { FC } from 'react'
import {
    Certificates,
    ErrorPage,
    MainPage,
    NotificationsSettings,
    PlaceholderPage,
    QuestionnairesListener,
    UserAssignments,
    UserNotifications,
    UserPersonalArea,
    UserProfile,
    YourDevelopment,
} from 'components/pages'
import { ProtectedRoute } from 'core/permissions'
import { Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'
import { YourDevelopmentV2 } from 'components/pages/YourDevelopmentV2'
import { useDocumentEditor } from 'hooks'

import {
    AdministrationRouting,
    ApproveRouting,
    AssessmentRouting,
    DistributionRouting,
    EventRouting,
    KnowledgeBaseRouting,
    MaterialsRouting,
    ProductCardsRouting,
    ProductCatalogRouting,
    ProfilingRouting,
    QuizRouting,
    TestRouting,
    TracksRouting,
} from './components'
import { ROUTE_NAMES } from './routeNames.consts'

export const Routing: FC = React.memo(() => {
    useDocumentEditor()

    return (
        <Switch>
            <Route path={ROUTE_NAMES.MAIN} exact>
                <MainPage />
            </Route>

            <Route path={ROUTE_NAMES.USER_PROFILE}>
                <UserProfile />
            </Route>

            <ProtectedRoute
                requiredPermissions={[UserPermission.Lk]}
                path={ROUTE_NAMES.USER_PERSONAL_AREA}
            >
                <UserPersonalArea />
            </ProtectedRoute>

            <Route path={ROUTE_NAMES.NOTIFICATIONS_SETTINGS}>
                <NotificationsSettings />
            </Route>

            <Route path={ROUTE_NAMES.CERTIFICATES}>
                <Certificates />
            </Route>

            <Route path={ROUTE_NAMES.USER_NOTIFICATIONS}>
                <UserNotifications />
            </Route>

            <Route path={ROUTE_NAMES.TRACKS}>
                <TracksRouting />
            </Route>

            <Route path={ROUTE_NAMES.QUESTIONNAIRES_LISTENER}>
                <QuestionnairesListener />
            </Route>

            <Route path={`${ROUTE_NAMES.PRODUCT_CARDS}`}>
                <ProductCardsRouting />
            </Route>

            <Route path={ROUTE_NAMES.ASSESSMENT}>
                <AssessmentRouting />
            </Route>

            <Route path={ROUTE_NAMES.QUIZ}>
                <QuizRouting />
            </Route>

            <Route path={ROUTE_NAMES.EVENTS}>
                <EventRouting />
            </Route>

            <Route path={ROUTE_NAMES.TEST}>
                <TestRouting />
            </Route>

            <Route path={ROUTE_NAMES.MATERIALS}>
                <MaterialsRouting />
            </Route>

            <Route path={ROUTE_NAMES.ADMINISTRATION}>
                <AdministrationRouting />
            </Route>

            <Route path={ROUTE_NAMES.PROFILING}>
                <ProfilingRouting />
            </Route>

            <Route path={ROUTE_NAMES.APPROVE}>
                <ApproveRouting />
            </Route>

            <Route path={ROUTE_NAMES.PRODUCT_CATALOG}>
                <ProductCatalogRouting />
            </Route>

            <Route path={ROUTE_NAMES.YOUR_DEVELOPMENT}>
                <YourDevelopment />
            </Route>

            <Route path={ROUTE_NAMES.YOUR_DEVELOPMENT_v2}>
                <YourDevelopmentV2 />
            </Route>

            <Route path={ROUTE_NAMES.USER_ASSIGNMENTS}>
                <UserAssignments />
            </Route>

            <Route path={ROUTE_NAMES.KNOWLEDGE_BASE}>
                <KnowledgeBaseRouting />
            </Route>

            <Route path={ROUTE_NAMES.DISTRIBUTION}>
                <DistributionRouting />
            </Route>

            <Route path={ROUTE_NAMES.WORK_IN_PROGRESS}>
                <PlaceholderPage />
            </Route>

            <Route>
                <ErrorPage errorCode="404" />
            </Route>
        </Switch>
    )
})
