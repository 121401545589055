import React, { useCallback, useEffect } from 'react'
import { Button } from 'components/shared/Button'
import { Checkbox, Form, Modal } from 'antd'
import { FormItemAdapter, ShouldUpdateChecker } from 'components/controls'
import { LOCAL } from 'core/local'
import { MODAL_Z_INDEX } from 'consts'
import { RESOLUTION_RELATIONS, Resolutions } from 'core/configs'
import { SpaceAdapter } from 'components/shared'

import styles from './ResponsiveModal.module.scss'
import { FIELD_RESPONSIVE_NAME } from './ResponsiveModal.config'
import { FormFieldsProps, ResponsiveModalProps } from './ResponsiveModal.types'
import { resolutions } from './ResponsiveModal.utils'

export const ResponsiveModal: React.FC<ResponsiveModalProps> = React.memo(
    ({
        isVisible,
        onCancel,
        onSelectResolution,
        initialValues,
        maskClosable = false,
    }) => {
        const [form] = Form.useForm<FormFieldsProps>()

        const handleFinish = useCallback(
            ({ resolution }: FormFieldsProps) => {
                onSelectResolution(resolution)
            },
            [onSelectResolution]
        )

        useEffect(() => {
            if (!initialValues) return

            form.setFieldsValue(initialValues)
        }, [form, initialValues])

        return (
            <Modal
                title={LOCAL.LABELS.SELECT_RESOLUTION}
                visible={isVisible}
                onCancel={onCancel}
                className={styles.wrapper}
                zIndex={MODAL_Z_INDEX.DEFAULT}
                footer={null}
                maskClosable={maskClosable}
            >
                <Form form={form} onFinish={handleFinish}>
                    <FormItemAdapter fieldName={FIELD_RESPONSIVE_NAME}>
                        <Checkbox.Group>
                            {resolutions}

                            <Checkbox value={Resolutions.XXL} disabled>
                                {RESOLUTION_RELATIONS[Resolutions.XXL]}
                            </Checkbox>
                        </Checkbox.Group>
                    </FormItemAdapter>

                    <SpaceAdapter justifyContent="flex-end" fullWidth>
                        <Button onClick={onCancel}>
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        <ShouldUpdateChecker fieldPath={FIELD_RESPONSIVE_NAME}>
                            {({ getFieldsValue }) => {
                                const {
                                    resolution,
                                }: FormFieldsProps = getFieldsValue()

                                const isDisabled =
                                    !resolution?.length ||
                                    (resolution?.length === 1 &&
                                        resolution.includes(Resolutions.XXL))

                                return (
                                    <Button
                                        htmlType="submit"
                                        disabled={isDisabled}
                                    >
                                        {LOCAL.ACTIONS.ADD}
                                    </Button>
                                )
                            }}
                        </ShouldUpdateChecker>
                    </SpaceAdapter>
                </Form>
            </Modal>
        )
    }
)
