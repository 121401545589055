import React from 'react'
import { FeedbackQuizReportGraficTabContract } from 'core/api'
import { NoChartData, Title } from 'components/shared'

import styles from './ChartsHistogramGroup.module.scss'
import { renderChart } from './ChartsHistogramGroup.utils'

/** Вкладка "Гистограмма со средними значениями" в назначениях опросов  */
export const ChartsHistogramGroup: React.FC<FeedbackQuizReportGraficTabContract> = React.memo(
    ({ head, questions, scaleTo, scaleFrom }) => {
        const minScore = scaleFrom === 0 ? scaleFrom : scaleFrom - 1
        const scaleDifference = scaleTo - minScore

        return (
            <div className={styles.wrapper}>
                <Title type="h5" text={head} />

                {questions.length ? (
                    questions.map((el) =>
                        renderChart({ question: el, scaleDifference, minScore })
                    )
                ) : (
                    <NoChartData />
                )}
            </div>
        )
    }
)
