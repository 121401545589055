import React from 'react'
import cn from 'classnames'
import last from 'lodash/last'
import lowerFirst from 'lodash/lowerFirst'
import toLower from 'lodash/toLower'
import { Col, Row } from 'antd'
import {
    ContentTitle,
    FiltersToggleButton,
    SpaceAdapter,
} from 'components/shared'
import { FnActionProps } from 'App.types'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { isStatusActive, objToQueryStr, restrictBigText } from 'utils'

import styles from './Organizations.module.scss'
import { NormalizeStructuralUnitPositionsResultProps } from './Organizations.types'

export const renderOrganizationStructureColumn = (
    name: string,
    record: NormalizeStructuralUnitPositionsResultProps
) => {
    const organizationStructureNameBlock = (
        <div
            className={cn(
                styles.name,
                !record.linkedProfile &&
                    !record.unitType &&
                    styles.withoutPosProfile
            )}
        >
            {restrictBigText(name)}
        </div>
    )

    return record.isUnitChanged || record.isChanged ? (
        <div
            className={cn(
                styles.rowChanged,
                !record.children && styles.rowChangedWithoutChildren
            )}
        >
            <IconsAdapter iconType="ExclamationCircleOutlined" />

            {organizationStructureNameBlock}
        </div>
    ) : (
        organizationStructureNameBlock
    )
}

export const renderUsernameColumn = (
    username: string,
    record: NormalizeStructuralUnitPositionsResultProps
) => {
    if (record.unitType) return

    return (
        <div className={cn(record.isChanged && styles.usernamesChanged)}>
            <SpaceAdapter>
                <span>{restrictBigText(username)}</span>

                {!record.linkedProfile && (
                    <span className={styles.usernamesFlag}>
                        {username
                            ? ` (${lowerFirst(
                                  LOCAL.LABELS.NO_POSITION_PROFILE
                              )})`
                            : lowerFirst(LOCAL.LABELS.VACANCY)}
                    </span>
                )}
            </SpaceAdapter>
        </div>
    )
}

export const renderIsActiveColumn = (
    active: boolean,
    record: NormalizeStructuralUnitPositionsResultProps
) => (
    <div className={cn(record.isChanged && styles.rowChanged)}>
        <p>{isStatusActive(active)}</p>
    </div>
)

export const renderPositionProfileColumn = (
    isPositionProfile: boolean,
    record: NormalizeStructuralUnitPositionsResultProps
) => {
    if (record.unitType)
        return (
            <div className={styles.positionProfileRelations}>
                <span>{record?.withPositionProfileCount}</span>

                {'/'}

                <b>{record?.withoutPositionProfileCount}</b>
            </div>
        )

    const recordId = record.positionId || record.id

    const nearestUnit = last(record.positionUnits)

    const unitQueryString = objToQueryStr({
        ...record.positionUnits?.reduce(
            (acc, { type, id }) => ({ ...acc, [toLower(type)]: id }),
            {}
        ),
        position: recordId,
        register: 'organizations',
    })

    const registrLink = `${ROUTE_NAMES.POSITION_PROFILES}${unitQueryString}`
    const createLink = `${ROUTE_NAMES.POSITION_PROFILES}/${ROUTES_POSTFIXES.CREATE}/${nearestUnit?.id}/${nearestUnit?.type}/${recordId}`

    return isPositionProfile ? (
        <Link to={registrLink} className={styles.showProfiles}>
            <IconsAdapter iconType="CustomIconTree" />

            {LOCAL.TO_REGISTER}
        </Link>
    ) : (
        <Link
            to={createLink}
            className={cn(
                styles.createProfile,
                !record.active && styles.createProfileDisabled
            )}
        >
            <IconsAdapter iconType="CustomIconPlus" />

            {LOCAL.ACTIONS.ADD_PROFILE}
        </Link>
    )
}

interface ContentTitleRenderProps {
    triggerFilterVisibility: FnActionProps
    route: string
    justify?: 'end'
    withPositionProfileCount?: number
    withoutPositionProfileCount?: number
    isActive?: boolean
}

export const contentTitleRender = ({
    triggerFilterVisibility,
    route,
    justify,
    withPositionProfileCount,
    withoutPositionProfileCount,
    isActive,
}: ContentTitleRenderProps) => (
    <ContentTitle title={getRouteMeta(route)}>
        <Row gutter={15} justify={justify} align="middle">
            <Col>
                <FiltersToggleButton
                    onToggleFilterVisibility={triggerFilterVisibility}
                    isActive={isActive}
                />
            </Col>

            <Col>
                <div className={styles.positionProfileRelations}>
                    {'Привязано к ПД, да/нет:\u00A0'}

                    <span>{withPositionProfileCount}</span>

                    {'/'}

                    <b>{withoutPositionProfileCount}</b>
                </div>
            </Col>
        </Row>
    </ContentTitle>
)
