import React, { FC, useCallback } from 'react'
import {
    AppointmentSearchContract,
    AppointmentSearchResultContract,
    AppointmentSearchTextContract,
    AppointmentsService,
    UserPermission,
} from 'core/api'
import {
    AppointmentsPassingService,
    getPageTitleFromRoute,
    getRowHandlers,
} from 'utils'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage, DictionaryPageActionsProps } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRowClassNameArchive } from 'components/shared'
import { useLocation } from 'react-router-dom'

import { AssignmentsFilters, TEST_FILTERS_INITIAL_VALUES } from './components'
import { getTestAssignmentsTableColumns } from './TestAssignments.config'

/**
 * Список назначений тестов
 */
export const TestAssignments: FC = React.memo(() => {
    const location = useLocation()
    const title = getPageTitleFromRoute(location.pathname)

    /**
     * Рендер колонок таблицы
     * @param actions - набор функций, берется из DictionaryPage
     */
    const renderTableColumns = useCallback(
        ({ reFetchDictionary, updateLoader }: DictionaryPageActionsProps) =>
            getTestAssignmentsTableColumns(AppointmentsPassingService, {
                reFetchDictionary,
                updateLoader,
            }),
        []
    )

    return (
        <DictionaryPage<
            AppointmentSearchContract,
            AppointmentSearchResultContract,
            AppointmentSearchTextContract
        >
            dictionaryKey={DICTIONARY_KEYS.TEST_ASSIGNMENTS}
            searchMethod={AppointmentsService.searchAppointment}
            deleteMethod={AppointmentsService.deleteAppointment}
            contentTitle={title}
            tableColumnsRender={renderTableColumns}
            createRecordLink={{
                path: ROUTE_NAMES.TEST_ASSIGNMENTS_CREATE,
            }}
            filterComponent={AssignmentsFilters}
            tableFiltersOptions={{
                initialValues: TEST_FILTERS_INITIAL_VALUES,
            }}
            quickSearchMethod={AppointmentsService.searchAppointmentText}
            createPermissions={[
                UserPermission.TestAppointmentRead,
                UserPermission.TestAppointmentSave,
            ]}
            rowClassName={getRowClassNameArchive}
            onRow={getRowHandlers({
                url: ROUTE_NAMES.TEST_ASSIGNMENTS_VIEW,
            })}
        />
    )
})
