import React from 'react'
import {
    ControlGroup,
    FormItemAdapter,
    MultiSelectControl,
    SelectControl,
    TextAreaControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { TooltipAdapter } from 'components/shared/TooltipAdapter'

import { ExperienceGroupProps } from './ExperienceGroup.types'

export const ExperienceGroup: React.FC<ExperienceGroupProps> = React.memo(
    ({ industries, experiences, governmentExperiences, formMode }) => (
        <ControlGroup type="dark" title={LOCAL.LABELS.REQUIRED_EXPERIENCE}>
            <TooltipAdapter title={LOCAL.TOOLTIPS.BRANCH_OF_JOB_PROFILE}>
                <FormItemAdapter
                    fieldName="requiredExperienceIndustryIds"
                    label={LOCAL.LABELS.BRANCH}
                >
                    <MultiSelectControl
                        treeData={industries}
                        formMode={formMode}
                        selectAll
                        allowClear
                    />
                </FormItemAdapter>
            </TooltipAdapter>

            <TooltipAdapter title={LOCAL.TOOLTIPS.EXPERIENCE_OF_JOB_PROFILE}>
                <FormItemAdapter
                    fieldName="requiredExperienceId"
                    label={LOCAL.LABELS.EXPERIENCE}
                    required
                >
                    <SelectControl values={experiences} formMode={formMode} />
                </FormItemAdapter>
            </TooltipAdapter>

            <TooltipAdapter
                title={LOCAL.TOOLTIPS.EXPERIENCE_ON_GGS_OF_JOB_PROFILE}
            >
                <FormItemAdapter
                    fieldName="governmentExperienceId"
                    label={LOCAL.LABELS.EXPERIENCE_ON_GGS}
                    required
                >
                    <SelectControl
                        values={governmentExperiences}
                        formMode={formMode}
                    />
                </FormItemAdapter>
            </TooltipAdapter>

            <TooltipAdapter title={LOCAL.TOOLTIPS.NOTE_OF_JOB_PROFILE}>
                <FormItemAdapter
                    fieldName="requiredExperienceDescription"
                    label={LOCAL.LABELS.NOTE}
                >
                    <TextAreaControl formMode={formMode} />
                </FormItemAdapter>
            </TooltipAdapter>
        </ControlGroup>
    )
)
