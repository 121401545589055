import React from 'react'
import cn from 'classnames'
import find from 'lodash/find'

import styles from './NavigationList.module.scss'
import { NavigationListProps } from './NavigationList.types'
import { scrollToQuestion } from './NavigationList.utils'

export const NavigationList: React.FC<NavigationListProps> = React.memo(
    ({ listIndex, list, confirmedAnswers, isExpanded, toggleExpanded }) => (
        <div className={styles.list}>
            {list.map((question, index) => {
                const questionNumber = index + 1
                const notExpandedElemsLimitCount = 10

                if (questionNumber > notExpandedElemsLimitCount && !isExpanded)
                    return null

                return questionNumber === notExpandedElemsLimitCount ? (
                    <div
                        key={question.id}
                        className={cn(styles.question, styles.dots)}
                        onClick={toggleExpanded(listIndex)}
                    >
                        {'...'}
                    </div>
                ) : (
                    <div
                        className={cn(styles.question, {
                            [styles.questionCompleted]: find(confirmedAnswers, [
                                'questionId',
                                question.id,
                            ]),
                        })}
                        onClick={scrollToQuestion(question.id)}
                    >
                        {questionNumber}
                    </div>
                )
            })}
        </div>
    )
)
