import React from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { LOCAL } from 'core/local'

import { ButtonsControlProps } from './ButtonsControl.types'
import { useButtonsControl } from './ButtonsControl.hook'

/** Кнопки завершения/подтверждения опроса/теста */
export const ButtonsControl: React.FC<ButtonsControlProps> = React.memo(
    (props) => {
        const {
            isBtnFinishDisabled,
            handleSubmit,
            unsavedAnswers,
            handleConfirmAnswers,
        } = useButtonsControl(props)

        return (
            <ButtonsToolbar>
                <Button
                    htmlType="submit"
                    type="primary"
                    disabled={isBtnFinishDisabled}
                    onClick={handleSubmit}
                >
                    {LOCAL.ACTIONS.COMPLETE}
                </Button>

                <Button
                    onClick={handleConfirmAnswers}
                    disabled={
                        !unsavedAnswers.length ||
                        (props.passingData.questionnaire.showCorrectAnswer &&
                            props.passingData.questionnaire
                                .allRequiredUserAnswersExists)
                    }
                >
                    {LOCAL.ACTIONS.CONFIRM}
                </Button>
            </ButtonsToolbar>
        )
    }
)
