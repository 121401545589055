import { QuestionType } from 'core/api'
import { Store } from 'App.types'

export const isQuestionTypeChooseVariant = (type?: QuestionType) =>
    type === QuestionType.ChooseVariant
export const isQuestionTypeCriteriaScale = (type?: QuestionType) =>
    type === QuestionType.CriteriaScale
export const isQuestionTypeFillEmptyText = (type?: QuestionType) =>
    type === QuestionType.FillEmptyText

export const isEmptyField = (type: QuestionType, values: Store<string>) =>
    isQuestionTypeChooseVariant(type) && values.customAnswer && !values.text
