import { LOCAL } from 'core/local'

export const JOB_PROFILE_FILTER_TABS = {
    ORGANIZATIONS: {
        tab: 'Орг. структура',
        key: 'orgStructure',
    },
    STAFF_UNITS: {
        tab: LOCAL.LABELS.STAFF_UNIT,
        key: 'staffUnits',
    },
}
