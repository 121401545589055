import { LOCAL } from 'core/local'

export const VISUALIZATION_COLOR_OPTIONS_META = {
    thematicBlocksOptions: {
        title: LOCAL.LABELS.THEMATIC_BLOCKS,
        colorPickerData: {
            thematicBlockColorBeforePassing:
                LOCAL.LABELS.BLOCK_COLOR_BEFORE_PASSING,
            thematicBlockColorWhilePassing: LOCAL.LABELS.CURRENT_BLOCK_COLOR,
            thematicBlockColorAfterPassing:
                LOCAL.LABELS.BLOCK_COLOR_AFTER_PASSING,
            thematicBlockIconColor: LOCAL.LABELS.BLOCK_ICON_COLOR,
        },
    },
    buttonsOptions: {
        title: LOCAL.LABELS.BLOCK_START_BUTTON,
        colorPickerData: {
            buttonColor: LOCAL.LABELS.BUTTON_COLOR,
            buttonTextColor: LOCAL.LABELS.BUTTON_TEXT_COLOR,
        },
    },
}
