import React, { useReducer } from 'react'
import { CKEditorAdapter } from 'components/shared'
import {
    DEFAULT_STYLES,
    EXTRA_PLUGINS_LIST_EXTENDED,
    TOOLBAR_OPTIONS_EXTENDED,
} from 'components/shared/CKEditorAdapter/CKEditorAdapter.config'
import { Form, Tabs } from 'antd'
import { editorReducer } from 'components/shared/CKEditorAdapter/CKEditorAdapter.utils'

import styles from './AssessmentReportsContent.module.scss'
import { AssessmentReportsContentProps } from './AssessmentReportsContent.types'
import { ShortCodesForm } from '../ShortCodesForm'

export const AssessmentReportsContent: React.FC<AssessmentReportsContentProps> = React.memo(
    ({ value: templates, namePath }) => {
        const [, dispatch] = useReducer(editorReducer, {
            styles: DEFAULT_STYLES,
        })

        return (
            <Tabs
                tabBarExtraContent={<ShortCodesForm dispatch={dispatch} />}
                className={styles.tabs}
                defaultActiveKey={String(templates?.[0]?.id)}
                tabPosition="left"
                destroyInactiveTabPane
            >
                {templates?.map((template, index) => (
                    <Tabs.TabPane
                        key={template.id}
                        tab={template.name}
                        tabKey={String(template.id)}
                    >
                        <Form.Item noStyle name={[namePath, index, 'content']}>
                            <CKEditorAdapter
                                dispatchEvent={dispatch}
                                toolbar={TOOLBAR_OPTIONS_EXTENDED}
                                extraPlugins={EXTRA_PLUGINS_LIST_EXTENDED}
                            />
                        </Form.Item>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        )
    }
)
