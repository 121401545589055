import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { FnActionProps, FnActionRequiredProps } from 'App.types'

export interface UseSaveOrPublishState {
    isSaveDocument: boolean
    setSaveDocument: Dispatch<SetStateAction<boolean>>
    handleSubmitForm: FnActionRequiredProps<boolean, FnActionProps>
}

/**
 * Хук useSaveOrPublishControls используется для получения набора состояний и хендлеров к
 * ним, которые помогут в реализации методов публикации или сохранения данных внутри форм
 */
export const useSaveOrPublishState = (): UseSaveOrPublishState => {
    /**
     * Стейт для управления типом отправки формы (сохранение/публикация)
     */
    const [isSaveDocument, setSaveDocument] = useState(false)

    /**
     * Обработчик сохранения/публикации шаблона
     */
    const handleSubmitForm = useCallback(
        (isSave: boolean) => () => setSaveDocument(isSave),
        []
    )

    return {
        isSaveDocument,
        setSaveDocument,
        handleSubmitForm,
    }
}
