import React from 'react'
import { Col, Form, Row } from 'antd'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import {
    ControlGroup,
    ShouldUpdateChecker,
    SwitchControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { isFormModeView } from 'utils'

import styles from './InformationAssistantSettings.module.scss'
import { InfoAssistant } from '../InfoAssistant'
import { USE_INFORMATION_ASSISTANT_FIELD_NAME } from '../../../TrackForm.consts'

/** Настройки информационного помощника */
export const InformationAssistantSettings: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => {
    /**
     * Заголовок для компонента ControlGroup
     */
    const getControlGroupTitle = (disabled?: boolean) => (
        <Form.Item name={USE_INFORMATION_ASSISTANT_FIELD_NAME}>
            <SwitchControl
                addonAfter={LOCAL.LABELS.INFORMATION_ASSISTANT}
                disabled={disabled || isFormModeView(formMode)}
            />
        </Form.Item>
    )

    return (
        <Row gutter={ROW_GUTTER}>
            <Col xs={8}>
                <ControlGroup
                    title={getControlGroupTitle(disabled)}
                    className={styles.card}
                >
                    <ShouldUpdateChecker
                        fieldPath={USE_INFORMATION_ASSISTANT_FIELD_NAME}
                    >
                        {({ getFieldValue }) =>
                            getFieldValue(
                                USE_INFORMATION_ASSISTANT_FIELD_NAME
                            ) && (
                                <InfoAssistant
                                    disabled={disabled}
                                    formMode={formMode}
                                />
                            )
                        }
                    </ShouldUpdateChecker>
                </ControlGroup>
            </Col>
        </Row>
    )
})
