import React, { FC } from 'react'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Switch } from 'react-router-dom'
import { TRACK_ADAPTATION_PERMISSIONS } from 'consts'
import {
    TrackAdaptationPreset,
    TrackPreview,
    TracksAdaptation,
} from 'components/pages'
import { TrackPreview as TrackPreviewV2 } from 'components/pages/TrackPreview'
import { UserPermission } from 'core/api'

export const AdaptationTrackRouting: FC = React.memo(() => (
    <Switch>
        <ProtectedRoute
            requiredPermissions={[
                ...TRACK_ADAPTATION_PERMISSIONS,
                UserPermission.AdaptationTrackSave,
            ]}
            path={`${ROUTE_NAMES.TRACKS_ADAPTATION_CREATE}/:id`}
        >
            <TrackAdaptationPreset formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={TRACK_ADAPTATION_PERMISSIONS}
            path={`${ROUTE_NAMES.TRACKS_ADAPTATION_VIEW}/:id`}
        >
            <TrackAdaptationPreset formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TRACK_ADAPTATION_PERMISSIONS,
                UserPermission.AdaptationTrackSave,
            ]}
            path={`${ROUTE_NAMES.TRACKS_ADAPTATION_EDIT}/:id`}
        >
            <TrackAdaptationPreset formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TRACK_ADAPTATION_PERMISSIONS,
                UserPermission.AdaptationTrackSave,
            ]}
            path={ROUTE_NAMES.TRACKS_ADAPTATION_CREATE}
        >
            <TrackAdaptationPreset formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={TRACK_ADAPTATION_PERMISSIONS}
            path={ROUTE_NAMES.TRACKS_ADAPTATION_PREVIEW}
        >
            <TrackPreview />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={TRACK_ADAPTATION_PERMISSIONS}
            path={ROUTE_NAMES.TRACKS_ADAPTATION_PREVIEW_v2}
        >
            <TrackPreviewV2 />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.AdaptationTracksRead,
            ]}
            path={ROUTE_NAMES.TRACKS_ADAPTATION}
        >
            <TracksAdaptation />
        </ProtectedRoute>
    </Switch>
))
