import './CKEditorAdapter.scss'

import cn from 'classnames'
import React, { useCallback } from 'react'
import { CKEditorEventPayload } from 'ckeditor4-react'
import { FONT_NAMES } from 'consts'

import styles from './CKEditorAdapter.module.scss'
import { CKEditorAdapterProps } from './CKEditorAdapter.types'
import {
    EDITOR_HEIGHT,
    EXTRA_PLUGINS_LIST,
    FONT_NAMES_STRING,
    TAGS_SETTINGS,
    TEXT_FORMAT_TAGS,
    TOOLBAR_OPTIONS,
} from './CKEditorAdapter.config'
import { Editor } from './Editor'
import { getTagsSettings } from './CKEditorAdapter.utils'

/** Текстовый редактор */
export const CKEditorAdapter: React.FC<CKEditorAdapterProps> = React.memo(
    ({
        dispatchEvent,
        onChange,
        value,
        toolbar = TOOLBAR_OPTIONS,
        extraPlugins = EXTRA_PLUGINS_LIST,
        height = EDITOR_HEIGHT,
        onFocus,
        newValue,
        readOnly = false,
        validateCallback,
        className,
    }) => {
        const handleChange = useCallback(
            (e: CKEditorEventPayload<string>) => {
                onChange?.(e.editor.getData())
            },
            [onChange]
        )

        return (
            <div
                className={cn(
                    className,
                    validateCallback?.(value) && styles.validate
                )}
            >
                <Editor
                    dispatchEvent={dispatchEvent}
                    initData={value}
                    onChange={handleChange}
                    onFocus={onFocus}
                    newValue={newValue}
                    type="classic"
                    isReadOnly={readOnly}
                    config={{
                        toolbar,
                        extraPlugins,
                        format_tags: TEXT_FORMAT_TAGS,
                        font_defaultLabel: FONT_NAMES.Roboto,
                        font_names: FONT_NAMES_STRING,
                        allowedContent: true,
                        height,
                        ...getTagsSettings(TAGS_SETTINGS),
                    }}
                />
            </div>
        )
    }
)
