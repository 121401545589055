import React from 'react'
import { ButtonsToolbar, TableActions } from 'components/shared'
import {
    QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS,
    QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
} from 'consts'
import { QuizPortfolioContract, UserPermission } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TableActionsCommonProps } from 'App.types'
import { getConfirmEditMessage } from 'core/local/messages.local.config'
import { isDocumentStatusInWork } from 'utils'
import { useConfirmRedirect } from 'hooks'

/** Компонент для создания колонки действий внутри таблицы реестра портфелей опросов */
export const QuizPortfoliosTableActions: React.FC<TableActionsCommonProps<
    QuizPortfolioContract
>> = React.memo(({ tableRow }) => {
    const { handleRedirect } = useConfirmRedirect({
        to: `${ROUTE_NAMES.QUIZ_PORTFOLIOS_EDIT}/${tableRow.id}`,
        needConfirm: isDocumentStatusInWork(tableRow.status),
        confirmText: getConfirmEditMessage('quiz'),
    })

    return (
        <ButtonsToolbar bottomIndent="0">
            <TableActions.View
                requiredPermissions={QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS}
                conditionalPermissions={QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS}
                href={`${ROUTE_NAMES.QUIZ_PORTFOLIOS_VIEW}/${tableRow.id}`}
                disabled={!tableRow.active}
            />

            {tableRow.active && (
                <>
                    <TableActions.Edit
                        requiredPermissions={[
                            ...QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
                            UserPermission.QuizPortfoliosSave,
                        ]}
                        conditionalPermissions={
                            QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS
                        }
                        onClick={handleRedirect}
                    />

                    <TableActions.Copy
                        requiredPermissions={[
                            ...QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
                            UserPermission.QuizPortfoliosSave,
                        ]}
                        conditionalPermissions={
                            QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS
                        }
                        href={`${ROUTE_NAMES.QUIZ_PORTFOLIOS_COPY}/${tableRow.id}`}
                    />

                    {!isDocumentStatusInWork(tableRow.status) && (
                        <TableActions.Delete
                            tableRow={tableRow}
                            requiredPermissions={[
                                ...QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
                                UserPermission.QuizPortfoliosDelete,
                            ]}
                            conditionalPermissions={
                                QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS
                            }
                        />
                    )}
                </>
            )}
        </ButtonsToolbar>
    )
})
