import React, { useCallback } from 'react'
import {
    CertificateSearchContract,
    CertificateSearchResultContract,
    CertificateType,
    CertificatesFilesService,
    CertificatesService,
} from 'core/api'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage, DictionaryPageActionsProps } from 'components/layouts'
import { LOCAL } from 'core/local'
import { PageTabs } from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { RcCustomRequestOptions } from 'types'
import { getRouteMeta } from 'routing/routeNames.utils'
import { message, notification } from 'antd'
import { useElementVisible } from 'hooks'
import { useParams } from 'react-router-dom'
import { useToolbarGutterResponsive } from 'components/layouts/DictionaryPage/DictionaryPage.hooks'

import { CertificatesFilters } from './components'
import { CertificatesPageUrlParamsProps } from './Certificates.types'
import { TabsKeys } from './Certificates.consts'
import {
    getCertificatesPageTableColumns,
    getTabsData,
    renderInternalCertificatesPageToolbar,
} from './Certificates.utils'

/** Реестр внутренних сертификатов */
export const InternalCertificatesPage = React.memo(() => {
    const { userId } = useParams<CertificatesPageUrlParamsProps>()
    const {
        updateElementVisible: updateLoader,
        elementVisible: isLoading,
    } = useElementVisible()

    const gutterResponsive = useToolbarGutterResponsive()

    const renderTableColumns = useCallback(
        ({ updateLoader }: DictionaryPageActionsProps) =>
            getCertificatesPageTableColumns(TabsKeys.INTERNAL_CERTIFICATES, {
                updateLoader,
            }),
        []
    )

    const handleUploadTemplateFile = useCallback(
        async (request: RcCustomRequestOptions) => {
            try {
                updateLoader(true)

                await CertificatesFilesService.upload({ file: request.file })

                message.success(
                    LOCAL.MESSAGES.INTERNAL_CERTIFICATE_TEMPLATE_UPLOAD_SUCCESS
                )
            } catch (error) {
                notification.error({
                    message:
                        LOCAL.MESSAGES.ERROR_UPLOAD_CERTIFICATES_TEMPLATE_FILE,
                })
            } finally {
                updateLoader(false)
            }
        },
        [updateLoader]
    )

    const renderTabs = useCallback(
        () => <PageTabs tabsData={getTabsData(userId)} />,
        [userId]
    )

    return (
        <DictionaryPage<
            CertificateSearchContract,
            CertificateSearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.INTERNAL_CERTIFICATES}
            searchMethod={CertificatesService.search}
            deleteMethod={CertificatesService.delete}
            contentTitle={getRouteMeta(ROUTE_NAMES.CERTIFICATES)}
            tableColumnsRender={renderTableColumns}
            tableFiltersOptions={{
                initialValues: {
                    type: CertificateType.Internal,
                    userId: Number(userId),
                },
            }}
            filterComponent={CertificatesFilters}
            pageTabsRenderCustom={renderTabs}
            toolbarContentRender={renderInternalCertificatesPageToolbar(
                handleUploadTemplateFile,
                isLoading,
                gutterResponsive
            )}
        />
    )
})
