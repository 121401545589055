import React from 'react'
import { COL_xl6_lg12_xs24, LEARNING_FORMATS } from 'consts'
import { CatalogItemProductCardPreviewContract, StudyingFormat } from 'core/api'
import { Col } from 'antd'
import { LOCAL } from 'core/local'
import { Title } from 'components/shared'

/** Маппинг информации о расписании продукта*/
export const mapLearningScheduleInfo = (
    card?: CatalogItemProductCardPreviewContract
) =>
    [
        {
            title: `${LOCAL.LABELS.STUDYING_DAYS_PLURAL}:`,
            text: card?.daysCount,
        },
        {
            title: `${
                LOCAL.LABELS.STUDYING_ADJECTIVE
            } ${LOCAL.LABELS.HOURS_FACE_TO_FACE.toLowerCase()}:`,
            text: card?.intramuralHourCount,
        },
        {
            title: `${
                LOCAL.LABELS.STUDYING_ADJECTIVE
            } ${LOCAL.LABELS.HOURS_DISTANT.toLowerCase().slice(0, -6)}.):`,
            text: card?.distantHourCount,
        },

        {
            title: `${LOCAL.LABELS.LEARNING_FORMAT}:`,
            text: LEARNING_FORMATS[card?.studyingFormat as StudyingFormat],
        },
    ].map(({ title, text }, index) => (
        <Col {...COL_xl6_lg12_xs24} key={index}>
            <Title type="h6" text={title} />

            <Title type="h4" text={text || '-'} />
        </Col>
    ))
