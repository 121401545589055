import React from 'react'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import {
    NotificationTagSearchContract,
    NotificationTagSearchResultContract,
    NotificationTagsService,
    SortDirection,
    UserPermission,
} from 'core/api'

import { DistributionTagForm } from './components'
import { getDistributionTagsColumns } from './DistributionTags.utils'

/** Список тегов рассылки */
export const DistributionTags = React.memo(() => (
    <DictionaryPage<
        NotificationTagSearchContract,
        NotificationTagSearchResultContract
    >
        dictionaryKey={DICTIONARY_KEYS.DISTRIBUTION_TAGS}
        contentTitle={LOCAL.CATALOGS.TAGS}
        searchMethod={NotificationTagsService.search}
        deleteMethod={NotificationTagsService.delete}
        quickSearchMethod={NotificationTagsService.searchText}
        tableColumnsRender={getDistributionTagsColumns}
        tableFiltersOptions={{
            initialValues: {
                orderRules: [{ field: 'name', direction: SortDirection.Asc }],
            },
        }}
        createPermissions={[
            UserPermission.NotificationTagRead,
            UserPermission.NotificationTagSave,
        ]}
        createRecordModal={{
            component: DistributionTagForm,
            formId: FORM_IDS.DISTRIBUTION_TAGS_CREATE_FORM,
            modalOptions: {
                title: LOCAL.LABELS.DISTRIBUTION_TAG_CREATE,
                okText: LOCAL.ACTIONS.SAVE,
                width: MODAL_WIDTH.MD,
            },
        }}
    />
))
