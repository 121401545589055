import React, { FC } from 'react'
import { ErrorPage, ProductCard, ProductCardCourse } from 'components/pages'
import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'

export const ProductCardsRouting: FC = React.memo(() => (
    <Switch>
        <Route path={`${ROUTE_NAMES.PRODUCT_CARD_VIEW}/:id`} exact>
            <ProductCard />
        </Route>

        <Route
            path={`${ROUTE_NAMES.PRODUCT_CARD_VIEW}/:id/${ROUTES_POSTFIXES.COURSE}`}
        >
            <ProductCardCourse />
        </Route>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
