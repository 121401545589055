import React from 'react'
import { ControlGroup } from 'components/controls'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { isAppointmentTypeActivity } from 'utils'

import styles from './ProductAssignments.module.scss'
import { ProductAssignmentsProps } from './ProductAssignments.types'

export const ProductAssignments: React.FC<ProductAssignmentsProps> = React.memo(
    ({ autoAppointment }) => {
        const link = isAppointmentTypeActivity(autoAppointment.appointmentType)
            ? `${ROUTE_NAMES.EVENTS_ASSIGNMENTS_VIEW}/${autoAppointment.activityId}`
            : `${ROUTE_NAMES.TRACKS_ASSIGNMENTS_VIEW}/${autoAppointment.appointmentId}`

        return (
            <ControlGroup
                type="bordered"
                title={LOCAL.LABELS.APPOINTMENTS}
                className={styles.wrapper}
            >
                <Link to={link} target="_blank">
                    {`${process.env.REACT_APP_URL}${link}`}
                </Link>
            </ControlGroup>
        )
    }
)
