import cn from 'classnames'
import React, { useCallback, useEffect } from 'react'
import {
    ActivityLessonPassingContentContract,
    TrackPassingsService,
    TrackStageContentContract,
    TrackStagePassingContract,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import { ROW_GUTTER } from 'consts'
import { Spoiler } from 'components/shared/Spoiler'
import { UrlParamsCommonProps } from 'App.types'
import { getBackgroundImageStyle } from 'utils'
import { useHttp } from 'hooks'
import { useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './TrackContentMenu.module.scss'
import { TrackContentMenuProps } from './TrackContentMenu.types'
import {
    customSpoilerTitleRender,
    getActivityClassName,
    getActivityTitle,
    getBlockClassName,
    getBlockTitle,
} from './TrackContentMenu.utils'

/** Окно с содержанием трека */
export const TrackContentMenu: React.FC<TrackContentMenuProps> = withLoader(
    React.memo(
        ({
            startStagePassing,
            form,
            onThemeBlockByContentId,
            onHideContent,
            infoAboutPassing,
            updateLoader,
        }) => {
            const { id } = useParams<UrlParamsCommonProps<'id'>>()
            const [getData, data] = useHttp(
                TrackPassingsService.getTrackContent,
                updateLoader
            )
            const stages: TrackStagePassingContract[] = form?.getFieldValue(
                'stages'
            )

            /**
             * Обработчик нажатия на заголовок этапа
             * @param stageId идентификатор этапа
             * @param stageStatus статус этапа
             * @param isAvailable доступность этапа
             */
            const handleStartStagePassing = useCallback(
                ({
                    isAvailable,
                    stageStatus,
                    activityPassingId,
                    id,
                }: TrackStageContentContract) => () => {
                    if (
                        !isAvailable ||
                        infoAboutPassing?.activity?.id === activityPassingId
                    )
                        return

                    startStagePassing?.(
                        stages?.find((el) => el.id === id)?.stageNumber
                    )
                    onHideContent()
                },
                [startStagePassing, stages, infoAboutPassing, onHideContent]
            )

            /**
             * Обработчик нажатия на заголовок тематического блока
             * @param themeBlockId индекс тематического блока
             */
            const handleStartThemeBlockPassing = useCallback(
                (
                    themeBlock: ActivityLessonPassingContentContract,
                    stage: TrackStageContentContract
                ) => () => {
                    if (!themeBlock.isAvailable) return

                    handleStartStagePassing(stage)()
                    onThemeBlockByContentId(themeBlock.lessonId)
                    onHideContent()
                },
                [
                    onThemeBlockByContentId,
                    handleStartStagePassing,
                    onHideContent,
                ]
            )

            useEffect(() => {
                if (!id) return

                getData({ appointmentId: Number(id) })
            }, [getData, id])

            return (
                <>
                    {data?.map((el) => (
                        <Row gutter={ROW_GUTTER} key={el.id}>
                            <Col xs={3}>
                                {el.contentIcon?.url && (
                                    <div
                                        className={styles.icon}
                                        style={getBackgroundImageStyle(
                                            el.contentIcon.url
                                        )}
                                    />
                                )}
                            </Col>

                            <Col xs={21}>
                                <Spoiler
                                    title={getBlockTitle(
                                        el.title,
                                        el.stageNumber
                                    )}
                                    className={cn(
                                        styles.spoiler,
                                        getBlockClassName(
                                            el.stageStatus,
                                            el.isAvailable
                                        )
                                    )}
                                    spoilerTitleRender={customSpoilerTitleRender(
                                        handleStartStagePassing(el)
                                    )}
                                >
                                    {el.lessons.map((lesson, lessonIndex) => (
                                        <div
                                            key={lesson.lessonId}
                                            className={cn(
                                                styles.activity,
                                                getActivityClassName(
                                                    lesson.passingStatus
                                                )
                                            )}
                                        >
                                            <Button
                                                type="link"
                                                className={styles.activityLink}
                                                onClick={handleStartThemeBlockPassing(
                                                    lesson,
                                                    el
                                                )}
                                            >
                                                {getActivityTitle(
                                                    lesson.name,
                                                    lessonIndex
                                                )}
                                            </Button>
                                        </div>
                                    ))}
                                </Spoiler>
                            </Col>
                        </Row>
                    ))}
                </>
            )
        }
    )
)
