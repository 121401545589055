import React from 'react'
import { LOCAL } from 'core/local'

import styles from './TableActions.module.scss'
import { DeleteTableAction } from './components/DeleteTableAction'
import { TableActionsProps } from './TableActionBtn.types'
import { getTableActionTrigger } from './TableActions.utils'

/** Компонент-хаб, через который осуществляется доступ до компонентов-триггеров */
const TableActions: TableActionsProps = ({ children }): JSX.Element => (
    <>{children}</>
)
TableActions.Edit = getTableActionTrigger({
    iconType: 'EditOutlined',
    title: LOCAL.ACTIONS.EDIT,
})

TableActions.Copy = getTableActionTrigger({
    iconType: 'CopyOutlined',
    title: LOCAL.ACTIONS.COPY,
})

TableActions.Play = getTableActionTrigger({
    iconType: 'PlaySquareOutlined',
    title: LOCAL.ACTIONS.START,
})

TableActions.Pause = getTableActionTrigger({
    iconType: 'PauseCircleOutlined',
    title: LOCAL.ACTIONS.PAUSE,
})

TableActions.Stop = getTableActionTrigger({
    iconType: 'StopOutlined',
    title: LOCAL.ACTIONS.CANCEL,
})

TableActions.Report = getTableActionTrigger({
    iconType: 'CustomIconAssessment',
    title: LOCAL.ACTIONS.VIEW_REPORT,
    iconClassName: styles.customIconAssessment,
})

TableActions.View = getTableActionTrigger({
    iconType: 'EyeOutlined',
    title: LOCAL.ACTIONS.VIEW,
})

TableActions.Progress = getTableActionTrigger({
    iconType: 'InfoCircleOutlined',
    title: LOCAL.LABELS.PROGRESS,
})

TableActions.Info = getTableActionTrigger({
    iconType: 'InfoCircleOutlined',
    title: LOCAL.LABELS.INFORMATION,
})

TableActions.Export = getTableActionTrigger({
    iconType: 'DownCircleOutlined',
    title: LOCAL.ACTIONS.EXPORT,
})

TableActions.GoToListByRow = getTableActionTrigger({
    iconType: 'CustomIconCloud',
    title: LOCAL.ACTIONS.MAILING_BY_ASSIGNMENT,
})

TableActions.Delete = (props) => <DeleteTableAction {...props} />

export default TableActions as typeof TableActions
