import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { CompetenceGroupsService } from 'core/api'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { MultiSelectControl, TagsType } from 'components/controls'
import { Popup } from 'components/shared/Popup'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import { isFormModeView } from 'utils'
import { useDictionaries, useElementVisible } from 'hooks'

import styles from '../JobProfileCompetencies/JobProfileCompetencies.module.scss'
import {
    CompetenceGroupsControlProps,
    DictionariesStateProps,
} from './CompetenceGroupsIdsControl.types'

/** Компонент кастомного контрола для работы с группами компетенций внутри формы профиля должности */
export const CompetenceGroupsIdsControl: React.FC<CompetenceGroupsControlProps> = React.memo(
    ({ value, onChange, formMode }) => {
        const [competenceGroupIdsState, setCompetenceGroupIdsState] = useState<
            number[]
        >()

        const { dictionaries, handleFetchDictionaries } = useDictionaries<
            DictionariesStateProps
        >()

        const {
            elementVisible,
            handleHideElement,
            handleShowElement,
        } = useElementVisible()

        const dictionaryFetch = useCallback(() => {
            handleFetchDictionaries({
                competenceGroups: [
                    CompetenceGroupsService.getForModal(),
                    'treeSelect',
                ],
            })
        }, [handleFetchDictionaries])

        const triggerChanges = useCallback(
            (value?: number[]) => {
                setCompetenceGroupIdsState(value)
                onChange?.(value)
            },
            [onChange, setCompetenceGroupIdsState]
        )

        useEffect(() => {
            if (!value || isEqual(value, competenceGroupIdsState)) return

            setCompetenceGroupIdsState(value)
        }, [value, competenceGroupIdsState])

        useEffect(() => {
            dictionaryFetch()
        }, [dictionaryFetch])

        return (
            <>
                <AddButton
                    onClick={handleShowElement}
                    disabled={isFormModeView(formMode)}
                    buttonText={LOCAL.ACTIONS.ADD_GROUP}
                />

                <Popup
                    visible={elementVisible}
                    onCancel={handleHideElement}
                    cancelText={LOCAL.ACTIONS.CLOSE}
                    destroyOnClose
                    centered
                    title={LOCAL.LABELS.SELECT_COMPETENCE_GROUP}
                    width={MODAL_WIDTH.LG}
                    okButtonProps={{ hidden: true }}
                    getContainer={getDropdownPopupContainerDefault}
                    className={styles.competenceGroupsPopup}
                    bodyWithoutScroll
                >
                    <MultiSelectControl
                        treeData={dictionaries?.competenceGroups || []}
                        value={competenceGroupIdsState}
                        onChange={triggerChanges}
                        tagsType={TagsType.Cloud}
                    />
                </Popup>
            </>
        )
    }
)
