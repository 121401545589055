import React from 'react'
import { CloseButton } from 'components/shared/CloseButton'
import { Col, Row } from 'antd'
import {
    CoworkersFilters,
    FormItemAdapter,
    InputNumberControl,
    RangePickerControl,
    SearchSelectControl,
    getCoworkersTableColumns,
    getUserByIdsMethod,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import {
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import { disableDate, isFormModeView } from 'utils'

import styles from './ProductPeriod.module.scss'
import { ProductPeriodProps } from './ProductPeriod.types'

/** Блок полей формы создания карточки продукта - период обучения */
export const ProductPeriod: React.FC<ProductPeriodProps> = React.memo(
    ({ field, index, formMode, remove }) => (
        <div className={styles.wrapper} key={field.key}>
            <div className={styles.number}>{index + 1}</div>

            <Row gutter={ROW_GUTTER} className={styles.row} align="bottom">
                <Col xs={8}>
                    <FormItemAdapter
                        {...field}
                        fieldName={[field.name, 'date']}
                        label={LOCAL.LABELS.DATE_OF_LEARNING_HOLDING}
                    >
                        <RangePickerControl
                            disabledDate={disableDate()}
                            formMode={formMode}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        {...field}
                        fieldName={[field.name, 'participantCount']}
                    >
                        <InputNumberControl
                            addonBefore={LOCAL.LABELS.NUMBER_OF_PARTICIPANTS}
                            formMode={formMode}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        {...field}
                        fieldName={[field.name, 'responsiblePerson']}
                        label={LOCAL.LABELS.RESPONSIBLE}
                    >
                        <SearchSelectControl<
                            UserModalSearchContract,
                            UserModalContract
                        >
                            getByIdsMethod={getUserByIdsMethod}
                            formMode={formMode}
                            tableSearchOptions={{
                                searchMethod: UsersService.getForModal,
                                tableColumns: getCoworkersTableColumns(),
                                filterComponent: CoworkersFilters,
                            }}
                        />
                    </FormItemAdapter>
                </Col>
            </Row>

            {!isFormModeView(formMode) && (
                <CloseButton
                    onClose={() => remove?.(field.name)}
                    className={styles.closeIcon}
                    formMode={formMode}
                />
            )}
        </div>
    )
)
