import * as React from 'react'
import {
    DictionaryContract,
    NotificationFilteringConditionContract,
    NotificationMailingTaskContract,
    NotificationMailingTaskCreateOrUpdateContract,
} from 'core/api'
import { LOCAL } from 'core/local/local.config'
import { WithFormInstanceProps } from 'App.types'
import { getItemId, normalizeAttachmentObject } from 'utils'
import { notification } from 'antd'

import {
    DistributionMailingFormCheckAnalogsValues,
    DistributionMailingFormSubmitValues,
} from './DistributionMailingAssignmentForm.types'
import {
    mapConditionsToFormData,
    mapConditionsToRequest,
} from '../Conditions/Conditions.utils'

/**
 * Функция для подготовки данных задания на рассылку
 * @param {} - информация о задании на рассылку
 * @param conditions справочник условий отправки
 */
export const mapResponseToFormData = (
    { category, subCategory, ...task }: NotificationMailingTaskContract,
    conditions: NotificationFilteringConditionContract[]
) => ({
    ...task,
    sendOptionId: task.sendOption?.id,
    eventId: task.event?.id,
    category: category
        ? { label: category?.name, value: category.id, key: category.code }
        : undefined,
    tagIds: task.tags?.map(getItemId),
    subCategory: subCategory
        ? {
              label: subCategory?.name,
              value: subCategory.id,
              key: subCategory.code,
          }
        : undefined,
    attachmentIds: task.attachments?.map((attachment) =>
        normalizeAttachmentObject(attachment)
    ),
    conditions: mapConditionsToFormData(task.conditions, conditions),
})

/**
 * Получить значения для отправки на бэк
 * @param values - текущие значения полей формы
 */
export const mapFormDataToRequest = (
    values: Partial<DistributionMailingFormSubmitValues>
) =>
    ({
        ...values,
        categoryId: values.category?.value,
        subCategoryId: values.subCategory?.value,
        attachmentIds: values.attachmentIds?.map(
            ({ response }) => response?.id
        ),
        appointmentIds: values.appointments?.map(getItemId),
        appointmentObjectIds: values.appointmentObjects?.map(getItemId),
        conditions: mapConditionsToRequest(values.conditions),
    } as NotificationMailingTaskCreateOrUpdateContract)

/**
 * Проверить заполненность полей тема и тело сообщение и отобразить алерт
 * @param form - сущность формы
 * @param name - имя категории
 */
export const checkMailFieldsAndAlert = (
    form: WithFormInstanceProps['form'],
    name: React.ReactNode
) => {
    const { notificationTheme, notificationBody } = form?.getFieldsValue([
        'notificationTheme',
        'notificationBody',
    ])

    if (notificationTheme && notificationBody) {
        notification.success({
            message: name
                ? `${LOCAL.MESSAGES.CATEGORY_CHANGED_TO} "${name}"`
                : LOCAL.MESSAGES.CATEGORY_CHANGED,
        })
    }
}

export const mapFormDataToCheckRequest = (
    values: DistributionMailingFormCheckAnalogsValues
) => ({
    eventId: values.eventId,
    startDate: values.mailingStartDate || null,
    endDate: values.mailingEndDate || null,
    objectIds: values.appointmentObjects?.map(getItemId) || [],
    subCategoryId: values.subCategory && Number(values.subCategory.value),
})

export const normalizeRatioByCode = (
    item: DictionaryContract & { code: string }
) => ({
    label: item.name,
    value: item.id,
    key: item.code,
})
