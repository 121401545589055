import React, { useContext } from 'react'
import {
    AddOrganizationControl,
    AddPositionControl,
    CheckboxControl,
    FormItemAdapter,
    InputControl,
    MultiSelectControl,
    NotEditableTextField,
    TagsType,
    TextAreaControl,
    TimePickerControl,
    UploadAttachments,
} from 'components/controls'
import {
    COL_xl16_lg12_xs24,
    QUIZ_TYPES,
    ROW_GUTTER,
    VIDEO_DOCUMENT_TYPE,
} from 'consts'
import { Col, Form, Row } from 'antd'
import { LOCAL } from 'core/local'
import { PortfolioFormCommonTabsContext } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import { QuizPortfolioContract, QuizType, UserPermission } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { injectDataByDefault } from 'components/controls/MultiSelectControl/MultiSelectControl.utils'
import { normalizeDataForTreeSelect, renderQuestionnairesDropdown } from 'utils'

import { QuizPortfolioCommonSettingsProps } from './QuizPortfolioCommonSettings.types'

/** Вкладка общих настроек формы создания портфеля опроса */
export const QuizPortfolioCommonSettings: React.FC<QuizPortfolioCommonSettingsProps> = React.memo(
    ({ quizzes }) => {
        const { store } = useContext(PortfolioFormCommonTabsContext)

        const { formMode, form, disabledByStatus } = store

        const initialValuesForEdit = store.initialValuesForEdit as QuizPortfolioContract

        return (
            <>
                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl16_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                            required
                        >
                            <InputControl formMode={formMode} />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="description"
                            label={LOCAL.LABELS.DESCRIPTION}
                        >
                            <TextAreaControl
                                autoSize={{ minRows: 4, maxRows: 5 }}
                                formMode={formMode}
                            />
                        </FormItemAdapter>

                        <UploadAttachments
                            name="attachmentIds"
                            form={form}
                            formMode={formMode}
                            documentType={VIDEO_DOCUMENT_TYPE}
                            disabled={disabledByStatus}
                            overallFilesMaxSize={50}
                        />
                    </Col>

                    <Col {...COL_xl16_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="organizationsIds"
                            label={LOCAL.LABELS.ORGANIZATION}
                            required
                        >
                            <AddOrganizationControl
                                formMode={formMode}
                                withSwitch
                                disabled={disabledByStatus}
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="positionIds"
                            label={LOCAL.LABELS.POSITION}
                            required
                        >
                            <AddPositionControl
                                formMode={formMode}
                                withSwitch
                                disabled={disabledByStatus}
                            />
                        </FormItemAdapter>

                        <NotEditableTextField
                            fieldName="quizType"
                            label={LOCAL.LABELS.QUIZ_TYPE}
                            normalizeValueFn={(type: QuizType) =>
                                QUIZ_TYPES[type]
                            }
                        />
                    </Col>
                </Row>

                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl16_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="questionnaireIds"
                            label={LOCAL.LABELS.QUESTIONNAIRE_TEMPLATE}
                            required
                        >
                            <MultiSelectControl
                                treeData={injectDataByDefault({
                                    injectableData: initialValuesForEdit?.questionnaires?.map(
                                        normalizeDataForTreeSelect
                                    ),
                                    treeData: quizzes?.map(
                                        normalizeDataForTreeSelect
                                    ),
                                })}
                                showSearch
                                allowClear
                                tagsType={TagsType.ListView}
                                dropdownRender={renderQuestionnairesDropdown({
                                    url:
                                        ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_CREATE,
                                    title:
                                        LOCAL.LABELS.QUESTIONNAIRE_TEMPLATE_ADD,
                                    permissions: [
                                        UserPermission.QuestionnaireSave,
                                    ],
                                })}
                                formMode={formMode}
                                notFoundContent={LOCAL.LABELS.EMPTY_DATA}
                                disabled={disabledByStatus}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_xl16_lg12_xs24}>
                        <Row>
                            <Form.Item name="timeIsLimited">
                                <CheckboxControl
                                    formMode={formMode}
                                    disabled={disabledByStatus}
                                >
                                    {LOCAL.LABELS.PASSING_TIME_LIMIT_FOR_QUIZ}
                                </CheckboxControl>
                            </Form.Item>

                            <Form.Item name="timeLimit">
                                <TimePickerControl
                                    disabled={disabledByStatus}
                                    formMode={formMode}
                                />
                            </Form.Item>
                        </Row>

                        <Form.Item name="depersonalizeRespondents">
                            <CheckboxControl
                                formMode={formMode}
                                disabled={disabledByStatus}
                            >
                                {LOCAL.LABELS.DEPERSONALIZE_RESULT_FOR_QUIZ}
                            </CheckboxControl>
                        </Form.Item>
                    </Col>
                </Row>
            </>
        )
    }
)
