import React, { useCallback, useEffect, useState } from 'react'
import {
    AppError,
    AppointmentDictionariesService,
    AppointmentPublishContract,
    AppointmentsService,
    AssessmentPortfolioSelectSearchContract,
    AssessmentPortfoliosService,
    AssessmentType,
    EntityEditSessionService,
    EntityType,
    UsersService,
} from 'core/api'
import { DictionariesCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { Form, notification } from 'antd'
import { HiddenField, ShouldUpdateChecker } from 'components/controls'
import { LOCAL } from 'core/local'
import { ORDER_RULES_WITH_CREATION_DATE } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { checkChangeEvaluationPeriodFrom } from 'utils/conditions.utils'
import { checkCompetenciesFromManagerGroup } from 'components/pages/AssessmentPortfolios/components/CircleParameters/CircleParameters.utils'
import {
    createConfirmPopup,
    getAssignmentFormPopupTitle,
    getFullFio,
    isAppointmentStatusDraft,
    isAssessmentTypeEstimate360,
    isAssessmentTypeManager,
    isCompetitiveAccessException,
    isFormModeEdit,
    isFormModeView,
    normalizeDataForSelectAndRadio,
    normalizeDictionaryToControl,
    renderOrganizationName,
    renderPositionName,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { template } from 'lodash'
import { useDictionaries, useDictionariesHelper } from 'hooks'
import { useHistory } from 'react-router-dom'

import {
    ASSESSMENT_ASSIGNMENT_FORM_INITIAL_VALUES,
    shouldRolesAndAssessmentPortfoliosReFetch,
    structuralUnitsIdsFieldNamePath,
} from './AssessmentAssignmentForm.consts'
import {
    AppointmentPublishFormContract,
    AssessmentAssignmentFormProps,
} from './AssessmentAssignmentForm.types'
import { AssessmentCircleSettings } from '../AssessmentCircleSettings'
import { AssessmentManagerSettings } from '../AssessmentManagerSettings'
import { GeneralSettings } from '../GeneralSettings'
import { RespondentFormContract } from '../AddRespondentsTableControl/AddRespondentsTableControl.types'
import {
    clearAssessment,
    clearData,
    createRespondentsRoles,
    isRemovePortfolio,
    mapFormDataToRequest,
    mapResponseToFormData,
    setManagerParametersValues,
} from './AssessmentAssignmentForm.utils'

/**
 * Форма создания назначения оценки
 */
export const AssessmentAssignmentForm: React.FC<AssessmentAssignmentFormProps> = React.memo(
    ({
        onBlockUserRouting,
        isSaveDocument,
        initialValuesForEdit,
        reFetchInitialFormValues,
        formMode,
        updateLoader,
    }) => {
        const { assessmentTypeDictionary } = useDictionariesHelper([
            'assessmentTypeDictionary',
        ])
        const history = useHistory()
        const [form] = Form.useForm()
        const [
            visibleAssessmentObjects,
            setVisibleAssessmentObjects,
        ] = useState(false)

        const [
            formHasManagerCompetencies,
            setFormHasManagerCompetencies,
        ] = useState<boolean>()

        const { dictionaries, handleChangeDictionaries } = useDictionaries<
            DictionariesCommonProps<'assessmentRoles' | 'assessmentPortfolios'>
        >({ updateLoader })

        const disabledByStatus =
            isFormModeEdit(formMode) &&
            !isAppointmentStatusDraft(initialValuesForEdit?.status)

        /**
         * Запрос ролей
         */
        const assessmentRolesFetch = useCallback(
            async (currentValues?: Partial<AppointmentPublishFormContract>) => {
                try {
                    const assessmentType = currentValues?.assessment?.type

                    if (!assessmentType) return

                    const assessmentRoles = await AppointmentDictionariesService.getAssessmentRoles(
                        { type: assessmentType }
                    )

                    handleChangeDictionaries({
                        assessmentRoles: assessmentRoles.map(
                            normalizeDataForSelectAndRadio
                        ),
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            [handleChangeDictionaries]
        )

        /**
         * Запрос портфелей оценки
         */
        const assessmentPortfoliosFetch = useCallback(
            async (currentValues?: Partial<AppointmentPublishFormContract>) => {
                try {
                    const type = currentValues?.assessment?.type

                    if (!type) return

                    const assessmentPortfolios = await AssessmentPortfoliosService.getForSelect(
                        {
                            body: {
                                type,
                                orderRules: ORDER_RULES_WITH_CREATION_DATE,
                            } as AssessmentPortfolioSelectSearchContract,
                        }
                    )

                    handleChangeDictionaries({
                        assessmentPortfolios: assessmentPortfolios.map(
                            normalizeDataForSelectAndRadio
                        ),
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            [handleChangeDictionaries]
        )

        const initAssessedPosition = useCallback(
            async (portfolioId?: number) => {
                try {
                    updateLoader?.(true)

                    const assessment = form.getFieldValue('assessment')

                    if (!portfolioId || !assessment.type) return

                    const {
                        circleParameters,
                        managerParameters,
                        structuralUnitsIds,
                    } = await AssessmentPortfoliosService.getActiveNoPermissions(
                        {
                            id: portfolioId,
                        }
                    )

                    const formHasManagerCompetencies = checkCompetenciesFromManagerGroup(
                        circleParameters?.circlePortfolioQuestionnaires.map(
                            (el) => el.competence
                        )
                    )

                    setFormHasManagerCompetencies(formHasManagerCompetencies)

                    switch (assessment.type) {
                        case AssessmentType.Estimate_360:
                            form.setFieldsValue(
                                createRespondentsRoles(
                                    circleParameters,
                                    structuralUnitsIds
                                )
                            )

                            break
                        case AssessmentType.EstimateByManager:
                            setVisibleAssessmentObjects(
                                !!managerParameters.length
                            )

                            form.setFieldsValue(
                                setManagerParametersValues(
                                    managerParameters,
                                    assessment.managerParameters?.candidates
                                )
                            )

                            break
                    }
                } catch (error) {
                    console.error(error)
                    if (isCompetitiveAccessException(error as AppError))
                        form.setFieldsValue({
                            assessment: {
                                portfolioId: undefined,
                                circleParameters: undefined,
                                managerParameters: undefined,
                            },
                        })
                } finally {
                    updateLoader?.(false)
                }
            },
            [form, updateLoader]
        )

        /**
         * Обработчик изменения значений полей формы
         * @param changedValue значение поля на котором сработало событие
         * @param allValues все значения, включая `changedValue`
         */
        const handleValuesChange = useCallback(
            async (changedValue, allValues) => {
                if (changedValue?.assessment?.type) {
                    assessmentRolesFetch(allValues)
                    assessmentPortfoliosFetch(allValues)
                    form.setFieldsValue(clearData(allValues))
                }

                if (changedValue?.assessment?.portfolioId) {
                    const dataSource = await EntityEditSessionService.checkAvailability(
                        {
                            id: changedValue.assessment.portfolioId,
                            entity: EntityType.AssessmentPortfolio,
                        }
                    )
                    if (!dataSource.available) {
                        notification.error({
                            message: template(LOCAL.MESSAGES.PORTFOLIO_ON_EDIT)(
                                {
                                    portfolio: LOCAL.LABELS.ASSESSMENT_PORTFOLIO.toLowerCase(),
                                }
                            ),
                        })

                        form.setFieldsValue({
                            assessment: { portfolioId: undefined },
                        })
                    } else {
                        initAssessedPosition(
                            changedValue.assessment.portfolioId
                        )
                    }
                }

                if (changedValue.evaluationPeriodFrom) {
                    checkChangeEvaluationPeriodFrom({
                        evaluationPeriodFrom: changedValue.evaluationPeriodFrom,
                        evaluationPeriodTo: allValues.evaluationPeriodTo,
                        form,
                    })
                }

                if (isRemovePortfolio(changedValue.assessment)) {
                    setVisibleAssessmentObjects(false)
                    form.setFieldsValue(clearAssessment(allValues))
                }

                if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }
            },
            [
                initAssessedPosition,
                form,
                formMode,
                assessmentRolesFetch,
                assessmentPortfoliosFetch,
                onBlockUserRouting,
            ]
        )

        /**
         * Обработчик отправки формы
         */
        const handleFinish = useCallback(
            async (values: Partial<AppointmentPublishFormContract>) => {
                try {
                    updateLoader?.(true)
                    const body = (mapFormDataToRequest(
                        values as AppointmentPublishFormContract,
                        initialValuesForEdit?.id
                    ) as unknown) as AppointmentPublishContract

                    if (isSaveDocument) {
                        await AppointmentsService.saveAppointment({
                            body,
                        })
                    } else {
                        await AppointmentsService.publishAppointment({
                            body,
                        })
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: isSaveDocument
                            ? LOCAL.MESSAGES.ASSIGNMENT_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES.ASSIGNMENT_PUBLISH_SUCCESS_MESSAGE,
                        content: getAssignmentFormPopupTitle(
                            getRouteMeta(
                                ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS,
                                'pageTitle'
                            )
                        ),
                        onOk: () => {
                            history.push(ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS)
                        },
                        onCancel: () => {
                            if (!initialValuesForEdit?.id) {
                                form.resetFields()
                            } else
                                reFetchInitialFormValues?.(
                                    initialValuesForEdit?.id
                                )
                        },
                        okText: LOCAL.YES,
                        cancelText: LOCAL.NO,
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                updateLoader,
                initialValuesForEdit,
                isSaveDocument,
                onBlockUserRouting,
                history,
                reFetchInitialFormValues,
                form,
            ]
        )

        /**
         * Обработать выбор респондента в таблице
         */
        const handleRespondentSelect = useCallback(
            (record: RespondentFormContract) => async (result?: number[]) => {
                try {
                    updateLoader?.(true)

                    const currentRespondents: RespondentFormContract[] = form.getFieldValue(
                        ['assessment', 'circleParameters', 'respondents']
                    )

                    if (!result?.[0]) return

                    if (
                        currentRespondents?.find(
                            (el) => el.userId === result[0]
                        )
                    ) {
                        return notification.error({
                            message:
                                LOCAL.MESSAGES
                                    .THIS_RESPONDENT_IS_ALREADY_CHOSEN,
                        })
                    }

                    const user = await UsersService.getUserById({
                        id: result[0],
                    })

                    if (!user) return

                    const respondent: RespondentFormContract = {
                        ...record,
                        userId: user.id,
                        positions: renderPositionName(user),
                        organizations: renderOrganizationName(user),
                        name: getFullFio(user),
                    }

                    const respondents = currentRespondents.map((el) =>
                        el.id === respondent.id ? respondent : el
                    )

                    form.setFieldsValue({
                        assessment: {
                            circleParameters: { respondents },
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [form, updateLoader]
        )

        useEffect(() => {
            if (initialValuesForEdit?.id) {
                const initialValues = mapResponseToFormData(
                    initialValuesForEdit
                )

                setVisibleAssessmentObjects(
                    !!initialValues.assessment?.portfolio ||
                        !!initialValues.assessment?.managerParameters
                            ?.candidates?.length
                )

                form.setFieldsValue(initialValues)

                if (
                    isAssessmentTypeManager(initialValues.assessment.type) &&
                    !initialValues.assessment?.managerParameters?.candidates
                        ?.length
                ) {
                    initAssessedPosition(initialValues.assessment?.portfolioId)
                }
            }

            const currenValues = form.getFieldsValue()

            assessmentPortfoliosFetch(currenValues)
            assessmentRolesFetch(currenValues)
        }, [
            form,
            initialValuesForEdit,
            assessmentPortfoliosFetch,
            assessmentRolesFetch,
            initAssessedPosition,
        ])

        return (
            <Form
                id={FORM_IDS.ASSESSMENT_ASSIGNMENT_FORM}
                onFinish={handleFinish}
                initialValues={ASSESSMENT_ASSIGNMENT_FORM_INITIAL_VALUES}
                onValuesChange={handleValuesChange}
                form={form}
            >
                <HiddenField fieldName="type" />

                <HiddenField fieldName={structuralUnitsIdsFieldNamePath} />

                <GeneralSettings
                    formMode={formMode}
                    form={form}
                    initialValuesForEdit={initialValuesForEdit}
                    assessmentPortfolios={dictionaries?.assessmentPortfolios}
                    assessmentTypes={normalizeDictionaryToControl(
                        assessmentTypeDictionary
                    )}
                    disabled={disabledByStatus}
                />

                <ShouldUpdateChecker
                    fieldPath={shouldRolesAndAssessmentPortfoliosReFetch}
                >
                    {({ getFieldValue }) => {
                        const assessmentType = getFieldValue(
                            shouldRolesAndAssessmentPortfoliosReFetch
                        )

                        return (
                            <>
                                {isAssessmentTypeEstimate360(
                                    assessmentType
                                ) && (
                                    <AssessmentCircleSettings
                                        formMode={formMode}
                                        assessmentRoles={
                                            dictionaries?.assessmentRoles
                                        }
                                        initialValuesForEdit={
                                            initialValuesForEdit
                                        }
                                        onRespondentSelect={
                                            handleRespondentSelect
                                        }
                                        disabled={disabledByStatus}
                                        formHasManagerCompetencies={
                                            formHasManagerCompetencies
                                        }
                                    />
                                )}

                                {isAssessmentTypeManager(assessmentType) && (
                                    <AssessmentManagerSettings
                                        formMode={formMode}
                                        visibleAssessmentObjects={
                                            visibleAssessmentObjects
                                        }
                                        initialValuesForEdit={
                                            initialValuesForEdit
                                        }
                                        disabled={disabledByStatus}
                                    />
                                )}
                            </>
                        )
                    }}
                </ShouldUpdateChecker>
            </Form>
        )
    }
)
