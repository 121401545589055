import React from 'react'
import { LOCAL } from 'core/local'
import { ReportsGroup } from 'components/shared'
import { Table } from 'antd'

import { CIRCLE_REPORT_PROGRESS_COLUMNS } from './CircleReportProgress.consts'
import { CircleAssessmentReportsParticleProps } from '../../CircleAssessmentReports.types'

/**
 * Сводный отчет по оценке 360/270/180: Блок 2 (Роли респондентов)
 */
export const CircleReportProgress: React.FC<CircleAssessmentReportsParticleProps> = React.memo(
    ({ reportsData }) => (
        <ReportsGroup
            title={LOCAL.LABELS.RESPONDENT_ROLES}
            groupHeaderNoPadding
        >
            <Table
                rowKey="name"
                columns={CIRCLE_REPORT_PROGRESS_COLUMNS}
                dataSource={reportsData?.circleReportProgress}
                pagination={false}
            />
        </ReportsGroup>
    )
)
