import React from 'react'
import { Store } from 'App.types'
import {
    composeRouteWithoutParams,
    getBackLinkTitleFromRoute,
    getPageTitleFromRoute,
} from 'utils'
import { useLocation, useParams } from 'react-router-dom'

import styles from './ContentTitleWithBackLink.module.scss'
import { ContentTitle } from '../ContentTitle'
import { ContentTitleWithBackLinkProps } from './ContentTitleWithBackLink.types'

export const ContentTitleWithBackLink: React.FC<ContentTitleWithBackLinkProps> = React.memo(
    ({
        children,
        status,
        title,
        backLinkText,
        renderTitle,
        onBack,
        isArchive,
        formMode,
    }) => {
        const { pathname } = useLocation()
        const urlParams = useParams<Store>()

        return (
            <ContentTitle
                title={title || getPageTitleFromRoute(pathname, urlParams)}
                renderTitle={renderTitle}
                backLink={{
                    path: composeRouteWithoutParams(pathname, urlParams, true),
                    text:
                        backLinkText ||
                        getBackLinkTitleFromRoute(pathname, urlParams),
                    onClick: onBack,
                }}
                status={status}
                className={styles.wrapper}
                isArchive={isArchive}
                flex
                formMode={formMode}
            >
                {children}
            </ContentTitle>
        )
    }
)
