import React from 'react'
import { NotImplementedError } from 'core/helpers/NotImplementedError'

import { UserContextProps } from './UserContext.types'

export const UserContext = React.createContext<UserContextProps>({
    userData: null,
    setUserData: () => {
        throw new NotImplementedError()
    },
    userSettingsData: null,
    setUserSettingsData: () => {
        throw new NotImplementedError()
    },
    updatePageSettings: () => {
        throw new NotImplementedError()
    },
})
