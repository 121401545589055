import cn from 'classnames'
import isNumber from 'lodash/isNumber'
import React, { useCallback } from 'react'
import { ArticleChapterCreateContract } from 'core/api'
import { Button } from 'antd'
import { FORM_IDS } from 'core/configs'
import { IconsAdapter, PopupWithForm } from 'components/shared'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { ShouldUpdateChecker } from 'components/controls'
import { cloneDeepViaJson, createConfirmPopup } from 'utils'

import styles from './KnowledgeBaseFormSubstance.module.scss'
import { KnowledgeBaseFormSubstanceProps } from './KnowledgeBaseFormSubstance.types'
import { TitleForm } from '../TitleForm'
import { TitleFormSubmitValues } from '../TitleForm/TitleForm.types'

/**
 * "Содержание" конструктора статей Базы знаний
 */
export const KnowledgeBaseFormSubstance: React.FC<KnowledgeBaseFormSubstanceProps> = React.memo(
    ({ form }) => {
        const handleTitleEdit = useCallback(
            (formValues?: TitleFormSubmitValues) => {
                if (formValues?.name && isNumber(formValues?.index)) {
                    const chapters = cloneDeepViaJson(
                        form?.getFieldValue('chapters') || []
                    )

                    chapters[formValues.index].title = formValues.name

                    form?.setFieldsValue({
                        chapters,
                    })
                }
            },
            [form]
        )

        const handleTitleAdd = useCallback(
            (formValues?: TitleFormSubmitValues) => {
                if (formValues?.name) {
                    const chapters = cloneDeepViaJson(
                        form?.getFieldValue('chapters') || []
                    )

                    chapters.push({ title: formValues.name })

                    form?.setFieldsValue({
                        chapters,
                        selectedIndex: chapters.length - 1,
                    })
                }
            },
            [form]
        )

        const handleTitleDelete = useCallback(
            (indexToDelete) => () => {
                createConfirmPopup({
                    content: LOCAL.MESSAGES.DELETE_CONFIRM,
                    onOk: () => {
                        form?.setFieldsValue({
                            chapters: form
                                ?.getFieldValue('chapters')
                                .filter(
                                    (
                                        item: ArticleChapterCreateContract,
                                        i: number
                                    ) => i !== indexToDelete
                                ),
                            selectedIndex: undefined,
                        })
                    },
                })
            },
            [form]
        )

        const renderTitle = useCallback(
            (
                { title }: ArticleChapterCreateContract,
                index: number,
                selectedIndex: number
            ) => {
                const isActive = selectedIndex === index

                return (
                    <div className={styles.titleItem} key={index}>
                        <Button
                            type="link"
                            onClick={() =>
                                form?.setFieldsValue({ selectedIndex: index })
                            }
                            block
                            className={cn(
                                styles.link,
                                isActive && styles.linkActive
                            )}
                        >
                            {title}
                        </Button>

                        <div
                            className={cn(
                                styles.buttons,
                                !isActive && styles.buttonsHidden
                            )}
                        >
                            <PopupWithForm
                                formId={`${FORM_IDS.KNOWLEDGE_TITLE_FORM}_${index}`}
                                buttonText=""
                                buttonOption={{
                                    title: LOCAL.ACTIONS.EDIT,
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="EditOutlined" />
                                    ),
                                    disabled: !isActive,
                                }}
                                component={TitleForm}
                                onRequestFinish={handleTitleEdit}
                                formOptions={{
                                    initialValues: {
                                        name: title,
                                        index,
                                    },
                                }}
                                modalOptions={{
                                    title: LOCAL.LABELS.TITLE_EDIT,
                                    width: MODAL_WIDTH.MD,
                                }}
                            />

                            <Button
                                type="link"
                                title={LOCAL.ACTIONS.DELETE}
                                icon={
                                    <IconsAdapter iconType="DeleteOutlined" />
                                }
                                onClick={handleTitleDelete(index)}
                                disabled={!isActive}
                            />
                        </div>
                    </div>
                )
            },
            [form, handleTitleDelete, handleTitleEdit]
        )

        return (
            <>
                <h2>{LOCAL.LABELS.SUBSTANCE}</h2>

                <ShouldUpdateChecker
                    fieldPath={[['chapters'], ['selectedIndex']]}
                >
                    {({ getFieldsValue }) => {
                        const { chapters, selectedIndex } = getFieldsValue([
                            'chapters',
                            'selectedIndex',
                        ])

                        return chapters?.map(
                            (
                                chapter: ArticleChapterCreateContract,
                                i: number
                            ) => renderTitle(chapter, i, selectedIndex)
                        )
                    }}
                </ShouldUpdateChecker>

                <PopupWithForm
                    formId={FORM_IDS.KNOWLEDGE_TITLE_FORM}
                    buttonText={LOCAL.ACTIONS.ADD_TITLE}
                    component={TitleForm}
                    onRequestFinish={handleTitleAdd}
                    modalOptions={{
                        title: LOCAL.ACTIONS.ADD_TITLE,
                        width: MODAL_WIDTH.MD,
                    }}
                />
            </>
        )
    }
)
