import React, {
    FC,
    Key,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { AppointmentsService, RespondentProgressContract } from 'core/api'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { ControlGroup } from 'components/controls'
import { LOCAL } from 'core/local'
import { Table } from 'antd'
import {
    createConfirmPopup,
    isAssessmentRoleSelfAssessment,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeQuiz,
    showMessageWithDelayAfter,
} from 'utils'
import { isNumber } from 'lodash'
import { withLoader } from 'HOCs'

import styles from './AssignmentsLaunchedTable.module.scss'
import { APPOINTMENT_LAUNCHED_TABLE_COLUMNS } from './AssignmentsLaunchedTable.consts'
import { AssignmentsLaunchedTableProps } from './AssignmentsLaunchedTable.types'
import {
    calculateRowClass,
    calculateRowKey,
    checkboxPlaceholderRender,
    checkboxRender,
    getModalTitle,
} from './AssignmentsLaunchedTable.utils'

/**
 * Форма удаления/редактирования/замены участников в запущенном назначении
 */
export const AssignmentsLaunchedTable: FC<AssignmentsLaunchedTableProps> = withLoader(
    React.memo(
        ({
            initialValues,
            hideProgress,
            updateLoader,
            isLoading,
            reFetchDictionary,
        }) => {
            const [respondents, setRespondents] = useState<
                RespondentProgressContract[]
            >()
            const [candidates, setCandidates] = useState<
                RespondentProgressContract[]
            >()
            const [overallProgress, setOverallProgress] = useState<number>()
            const [selectedKeys, setSelectedKeys] = useState<Key[]>()

            /**
             * Обработчик выбора записей для удаления в таблице формы
             */
            const handleSelectKeys = useCallback(setSelectedKeys, [
                setSelectedKeys,
            ])

            /**
             * Обработчик отправки формы
             */
            const handleFinish = useCallback(async () => {
                try {
                    updateLoader(true)

                    createConfirmPopup({
                        content: LOCAL.MESSAGES.EXCLUDE_USERS_CONFIRM_MESSAGE,
                        onOk: async () => {
                            await AppointmentsService.updateRespondentsAppointment(
                                {
                                    id: initialValues?.id,
                                    body: selectedKeys,
                                }
                            )

                            reFetchDictionary?.()

                            showMessageWithDelayAfter({
                                messageText:
                                    LOCAL.MESSAGES
                                        .ASSIGNMENT_SAVE_SUCCESS_MESSAGE,
                            }).then(hideProgress)
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }, [
                initialValues,
                reFetchDictionary,
                hideProgress,
                selectedKeys,
                updateLoader,
            ])

            const progress = useMemo(
                () =>
                    isNumber(overallProgress) && (
                        <p className={styles.overallProgress}>
                            {`${LOCAL.LABELS.OVERALL_PROGRESS_OF_PASSAGE}: ${overallProgress}%`}
                        </p>
                    ),
                [overallProgress]
            )

            useEffect(() => {
                const appointmentFetch = async () => {
                    try {
                        updateLoader(true)

                        const {
                            results,
                            passingPercent,
                        } = await AppointmentsService.getProgressAppointment({
                            id: initialValues?.id,
                        })

                        const candidate = results?.find((el) =>
                            isAssessmentRoleSelfAssessment(el.respondent.role)
                        )

                        const respondents = results?.filter(
                            (el) =>
                                !isAssessmentRoleSelfAssessment(
                                    el.respondent.role
                                )
                        )

                        setCandidates(candidate ? [candidate] : [])
                        setRespondents(respondents)
                        setOverallProgress(passingPercent)
                    } catch (error) {
                        console.error(error)
                    } finally {
                        updateLoader(false)
                    }
                }

                appointmentFetch()
            }, [updateLoader, initialValues])

            return (
                <>
                    {!!candidates?.length && (
                        <>
                            {isQuestionnaireTypeAssessment(
                                initialValues?.type
                            ) && progress}

                            <ControlGroup
                                title={LOCAL.LABELS.ASSESSMENT_OBJECT}
                                noPadding
                                className={styles.controlGroup}
                            >
                                <Table<RespondentProgressContract>
                                    rowKey={calculateRowKey}
                                    columns={APPOINTMENT_LAUNCHED_TABLE_COLUMNS}
                                    rowSelection={{
                                        hideSelectAll: true,
                                        renderCell: checkboxPlaceholderRender,
                                    }}
                                    dataSource={candidates}
                                    pagination={false}
                                    rowClassName={calculateRowClass}
                                />
                            </ControlGroup>
                        </>
                    )}

                    {!isQuestionnaireTypeQuiz(initialValues?.type) && (
                        <ControlGroup
                            title={getModalTitle(initialValues)}
                            noPadding
                            className={styles.controlGroup}
                        >
                            {!candidates?.length && progress}

                            <Table<RespondentProgressContract>
                                rowKey={calculateRowKey}
                                columns={APPOINTMENT_LAUNCHED_TABLE_COLUMNS}
                                rowSelection={{
                                    hideSelectAll: true,
                                    preserveSelectedRowKeys: true,
                                    onChange: handleSelectKeys,
                                    renderCell: checkboxRender,
                                }}
                                dataSource={respondents || []}
                                pagination={false}
                                rowClassName={calculateRowClass}
                            />
                        </ControlGroup>
                    )}

                    <ButtonsToolbar align="space-between">
                        <Button
                            onClick={handleFinish}
                            disabled={isLoading || !selectedKeys?.length}
                            loading={isLoading}
                        >
                            {LOCAL.ACTIONS.DELETE}
                        </Button>

                        <Button type="link" onClick={hideProgress}>
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>
                    </ButtonsToolbar>
                </>
            )
        }
    )
)
