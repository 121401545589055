import { ColorThemes } from './ColorThemeProps'

/**
 * Проверка цветовой темы
 * @param themeColor тип цветовой темы
 */
export const isThemeColorLight = (themeColor: ColorThemes) =>
    themeColor === ColorThemes.Light
export const isThemeColorDark = (themeColor: ColorThemes) =>
    themeColor === ColorThemes.Dark

interface MakeThemeDependentVarProps<T> {
    theme: ColorThemes
    dark: T
    light: T
}

/**
 * Сделать переменную, зависимую от цветовой темы
 */
export const makeThemeDependentVar = <T>({
    theme,
    light,
    dark,
}: MakeThemeDependentVarProps<T>) => (isThemeColorLight(theme) ? light : dark)
