import { LOCAL } from 'core/local'

import styles from './CompetenciesPanel.module.scss'

export const COMPETENCE_GROUP_LEGEND_ITEMS = [
    {
        label: LOCAL.LABELS.COMPETENCE_LEVEL_NOT_IMPORTANT,
        title: LOCAL.LABELS.COMPETENCE_LEVEL_NOT_IMPORTANT_HINT,
        modifierCn: styles.notImportant,
    },
    {
        label: LOCAL.LABELS.COMPETENCE_LEVEL_BEGINNER,
        title: LOCAL.LABELS.COMPETENCE_LEVEL_BEGINNER_HINT,
        modifierCn: styles.beginner,
    },
    {
        label: LOCAL.LABELS.COMPETENCE_LEVEL_BASIC,
        title: LOCAL.LABELS.COMPETENCE_LEVEL_BASIC_HINT,
        modifierCn: styles.basic,
    },
    {
        label: LOCAL.LABELS.COMPETENCE_LEVEL_EXPERIENCE,
        title: LOCAL.LABELS.COMPETENCE_LEVEL_EXPERIENCE_HINT,
        modifierCn: styles.experience,
    },
    {
        label: LOCAL.LABELS.COMPETENCE_LEVEL_MASTER,
        title: LOCAL.LABELS.COMPETENCE_LEVEL_MASTER_HINT,
        modifierCn: styles.master,
    },
    {
        label: LOCAL.LABELS.COMPETENCE_LEVEL_LEADER,
        title: LOCAL.LABELS.COMPETENCE_LEVEL_LEADER_HINT,
        modifierCn: styles.leader,
    },
]
