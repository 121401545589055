import { ActivityPassingDateType } from 'core/api'

export const PASSING_DATE_TYPE_FIELD = 'passingDateType'
export const ACTIVITY_FIELD = 'activity'
export const ORGANIZATIONS_FIELD = 'organizationIds'

export const EVENTS_ASSIGNMENT_FORM_INITIAL_VALUES = {
    passingDateType: ActivityPassingDateType.ByActivity,
    participants: [undefined],
}
