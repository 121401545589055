import React from 'react'
import { ErrorPage } from 'components/pages/utilityPages'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'

import { ExternalCertificatesFormContainer } from './ExternalCertificatesFormContainer'
import { ExternalCertificatesPage } from './ExternalCertificatesPage'
import { InternalCertificatesPage } from './InternalCertificatesPage'

export const Certificates = React.memo(() => (
    <Switch>
        <Route path={`${ROUTE_NAMES.INTERNAL_CERTIFICATES}/:userId?`}>
            <InternalCertificatesPage />
        </Route>

        <Route
            path={`${ROUTE_NAMES.EXTERNAL_CERTIFICATES_CREATE}/:userId?`}
            exact
        >
            <ExternalCertificatesFormContainer formMode="create" />
        </Route>

        <Route path={`${ROUTE_NAMES.EXTERNAL_CERTIFICATES}/:userId?`} exact>
            <ExternalCertificatesPage />
        </Route>

        <Route
            path={`${ROUTE_NAMES.EXTERNAL_CERTIFICATES_EDIT}/:userId/:certificateId`}
        >
            <ExternalCertificatesFormContainer formMode="edit" />
        </Route>

        <Route
            path={`${ROUTE_NAMES.EXTERNAL_CERTIFICATES_VIEW}/:userId/:certificateId`}
        >
            <ExternalCertificatesFormContainer formMode="view" />
        </Route>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
