import React from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { ShouldUpdateChecker } from 'components/controls'
import {
    convertToShouldUpdateDep,
    getBackgroundImageStyle,
    getStyles,
} from 'utils'

import styles from './ListCanvas.module.scss'
import { CANVAS_DEPENDENCIES } from '../EventFormVisualization/EventFormVisualization.consts'
import { MapFormDataToRequestProps } from '../EventForm/EventForm.types'

/**
 * Отображение для мероприятия типа - "List"
 */
export const ListCanvas: React.FC = React.memo(() => {
    const getBlockColor = (
        canvas: MapFormDataToRequestProps['canvas'],
        index: number
    ) => {
        if (index < 1) return canvas.thematicBlockColorAfterPassing
        else if (index < 2) return canvas.thematicBlockColorWhilePassing

        return canvas.thematicBlockColorBeforePassing
    }

    return (
        <main className={styles.wrapper}>
            <ShouldUpdateChecker
                fieldPath={convertToShouldUpdateDep(CANVAS_DEPENDENCIES)}
            >
                {({ getFieldsValue }) => {
                    const {
                        name,
                        announcement,
                        canvas,
                        lessons,
                    }: MapFormDataToRequestProps = getFieldsValue(
                        CANVAS_DEPENDENCIES
                    )

                    return (
                        <div
                            className={styles.body}
                            style={getBackgroundImageStyle(
                                canvas.canvasBackgroundUrl
                            )}
                        >
                            <h2
                                className={styles.eventTitle}
                                style={getStyles(canvas.titleSettings)}
                            >
                                {name}
                            </h2>

                            <pre
                                className={styles.eventDescription}
                                style={getStyles(canvas.descriptionSettings)}
                            >
                                {announcement}
                            </pre>

                            <div
                                className={styles.blocks}
                                style={getStyles(canvas.listSettings)}
                            >
                                {lessons?.map((lesson, index) => (
                                    <div
                                        className={styles.blockTitle}
                                        style={{
                                            color: getBlockColor(canvas, index),
                                            textDecoration:
                                                canvas.listSettings?.fontStyle,
                                        }}
                                        key={lesson.id}
                                    >
                                        {index < 1 && (
                                            <IconsAdapter
                                                className={styles.icon}
                                                iconType="CheckOutlined"
                                                style={{
                                                    color: getBlockColor(
                                                        canvas,
                                                        index
                                                    ),
                                                }}
                                            />
                                        )}

                                        {index >= 1 && (
                                            <span
                                                className={
                                                    styles.iconPlaceholder
                                                }
                                            />
                                        )}

                                        <span className={styles.lessonName}>
                                            {lesson.name}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                }}
            </ShouldUpdateChecker>
        </main>
    )
})
