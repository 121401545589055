import { HomeworkStatusType } from 'core/api'
import { LOCAL } from 'core/local'

export const getTutorStatusText = (status: HomeworkStatusType) => {
    switch (status) {
        case HomeworkStatusType.Passed:
            return LOCAL.STATUSES.CREDIT_RECEIVED
        case HomeworkStatusType.Revision:
            return LOCAL.STATUSES.CREDIT_LOST
        default:
            return
    }
}

export const getStatusColor = (status: HomeworkStatusType) => {
    switch (status) {
        case HomeworkStatusType.OnChecking:
            return '#cccccc'
        case HomeworkStatusType.Passed:
            return 'green'
        case HomeworkStatusType.Revision:
            return 'red'
    }
}

export const getUserStatusText = (status: HomeworkStatusType) => {
    switch (status) {
        case HomeworkStatusType.OnChecking:
            return LOCAL.STATUSES.SENT
        case HomeworkStatusType.Passed:
            return LOCAL.STATUSES.CREDIT_RECEIVED
        case HomeworkStatusType.Revision:
            return LOCAL.STATUSES.REVISE
        default:
            return
    }
}
