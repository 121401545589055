import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DOCUMENT_STATUSES, MAILING_STATUSES, MAILING_TYPES } from 'consts'
import { DictionaryPageActionsProps } from 'components/layouts'
import { LOCAL } from 'core/local'
import { NotificationMailingTaskSearchResultContract } from 'core/api'
import { WidthRestrictedElement } from 'components/shared'
import { defaultSorter } from 'components/layouts/DictionaryPage/DictionaryPage.consts'
import {
    formatDate,
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { MailingAssignmentTableActions } from './components'

export const getDistributionMailingAssignmentColumns = ({
    reFetchDictionary,
    updateLoader,
}: DictionaryPageActionsProps): ColumnsType<
    NotificationMailingTaskSearchResultContract
> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DESTINATION_OBJECT,
        dataIndex: 'appointmentObjects',
        key: 'appointmentObjects',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DESTINATION,
        dataIndex: 'appointments',
        key: 'appointments',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.TAGS,
        dataIndex: 'tags',
        key: 'tags',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.MAILING_TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (value, record) => (
            <WidthRestrictedElement title={MAILING_TYPES[record.type]} />
        ),
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'businessStatus',
        key: 'businessStatus',
        render: (status, record) => (
            <WidthRestrictedElement
                title={
                    record.active
                        ? MAILING_STATUSES[record.businessStatus]
                        : DOCUMENT_STATUSES.Archive
                }
            />
        ),
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        render: (tableRow) => (
            <MailingAssignmentTableActions
                tableRow={tableRow}
                reFetchDictionary={reFetchDictionary}
                updateLoader={updateLoader}
            />
        ),
        fixed: 'right',
    },
]
