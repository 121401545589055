import React from 'react'
import { LOCAL } from 'core/local'
import { useElementVisible } from 'hooks'

import styles from './EyeBlock.module.scss'
import { EyeBlockProps } from './EyeBlock.types'
import { IconsAdapter } from '../IconsAdapter'
import { Popup } from '../Popup'

/** Блок иконки для страницы отчета по тесту */
export const EyeBlock: React.FC<EyeBlockProps> = React.memo(
    ({ title, modalContent = LOCAL.LABELS.CONTENT_EMPTY }) => {
        const {
            elementVisible,
            handleHideElement,
            handleShowElement,
        } = useElementVisible()

        return (
            <>
                <figure onClick={handleShowElement} className={styles.wrapper}>
                    <IconsAdapter
                        className={styles.icon}
                        iconType="EyeOutlined"
                    />

                    <figcaption className={styles.title}>{title}</figcaption>
                </figure>

                <Popup
                    className={styles.modal}
                    visible={elementVisible}
                    onCancel={handleHideElement}
                    title={title}
                    closable
                    destroyOnClose
                    keyboard
                    centered
                    footer={null}
                >
                    <span className={styles.content}>{modalContent}</span>
                </Popup>
            </>
        )
    }
)
