import React from 'react'
import { Col, Form, Row } from 'antd'
import { ComponentCommonProps } from 'App.types'
import { HiddenField } from 'components/controls'

import { AssessmentProjectsCoworkers } from '../AssessmentProjectsCoworkers'
import { AssessmentProjectsManager } from '../AssessmentProjectsManager'
import { AssessmentProjectsReviewers } from '../AssessmentProjectsReviewers'
import { GenerateQuestionnaires } from '../GenerateQuestionnaires'

/** Компонент формы оценочного проекта для типа оценки руководителем */
export const AssessmentProjectsManagerEstimate: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <Row>
            <Col xs={24}>
                <GenerateQuestionnaires formMode={formMode} />
            </Col>

            <Col xs={24}>
                <AssessmentProjectsCoworkers formMode={formMode} />
            </Col>

            <Col xs={24}>
                <Form.Item name={['managerParameters', 'managers', 0]}>
                    <AssessmentProjectsManager formMode={formMode} />
                </Form.Item>
            </Col>

            <Col xs={24}>
                <AssessmentProjectsReviewers formMode={formMode} />
            </Col>

            <HiddenField fieldName={['managerParameters', 'portfolio']} />
        </Row>
    )
)
