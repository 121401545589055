import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import { ANIMATION_TIME_OUT_DEFAULT } from 'consts'
import { Button } from 'components/shared/Button'
import { CSSTransition } from 'react-transition-group'
import { Draggable } from 'react-beautiful-dnd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { backwardFn } from 'utils'

import styles from './ChildrenNodesWithSpoiler.module.scss'
import stylesCompetenciesOrderOptions from '../CompetenciesOrderOptions.module.scss'
import { CompetenciesOrderOptionsParticleProps } from '../CompetenciesOrderOptions.types'

export const ChildrenNodesWithSpoiler: React.FC<CompetenciesOrderOptionsParticleProps> = React.memo(
    ({ draggableId, index, name, dndKey, children, visible }) => {
        /**
         * Управление состоянием видимости
         */
        const [isVisible, setVisible] = useState(visible)

        /**
         * Переключатель видимости содержимого
         */
        const toggleSpoilerVisible = useCallback(
            (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault()

                setVisible(backwardFn)
            },
            []
        )

        return (
            <Draggable key={dndKey} draggableId={draggableId} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={provided.draggableProps.style}
                    >
                        <div
                            className={cn(
                                stylesCompetenciesOrderOptions.item,
                                snapshot.isDragging &&
                                    stylesCompetenciesOrderOptions.itemDragging
                            )}
                        >
                            <span
                                {...provided.dragHandleProps}
                                className={styles.drag}
                            >
                                <IconsAdapter iconType="CustomIconDnD" />
                            </span>

                            {children ? (
                                <Button
                                    className={cn(
                                        styles.toggleVisible,
                                        isVisible && styles.toggleVisibleActive
                                    )}
                                    onClick={toggleSpoilerVisible}
                                    type="link"
                                    icon={
                                        <IconsAdapter iconType="DownOutlined" />
                                    }
                                />
                            ) : (
                                <span
                                    className={cn(
                                        styles.toggleVisible,
                                        styles.toggleVisiblePlaceholder
                                    )}
                                />
                            )}

                            <span>{name}</span>
                        </div>

                        {children && (
                            <CSSTransition
                                in={isVisible}
                                timeout={ANIMATION_TIME_OUT_DEFAULT}
                                classNames="animation-fade-unmount"
                                unmountOnExit
                            >
                                <div>{children}</div>
                            </CSSTransition>
                        )}
                    </div>
                )}
            </Draggable>
        )
    }
)
