import React from 'react'
import { COL_xl8_lg12_xs24, ROW_GUTTER } from 'consts'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'

import styles from './ThemeBlockAttachmentsHeader.module.scss'

export const ThemeBlockAttachmentsHeader: React.FC = React.memo(() => (
    <Row gutter={ROW_GUTTER} className={styles.header}>
        <Col {...COL_xl8_lg12_xs24}>
            <span>{LOCAL.LABELS.FILE_NAME}</span>
        </Col>

        <Col {...COL_xl8_lg12_xs24}>
            <span>{LOCAL.LABELS.ATTACHMENT_TITLE}</span>
        </Col>
    </Row>
))
