import React, { FC, memo, useMemo } from 'react'
import {
    FormItemAdapter,
    RadioControl,
    ShouldUpdateChecker,
    TextAreaControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { addNameToNamePath, normalizeScaleValues } from 'utils'

import styles from './CriteriaScale.module.scss'
import { QuestionComponentProps } from '../PassingCompetenceQuestion/PassingCompetenceQuestion.types'

/** Компонент для отображения вопроса типа "шкала критериев" */
export const CriteriaScale: FC<QuestionComponentProps> = memo(
    ({ criteriaScale, name }) => {
        // Варианты ответа
        const scaleList = useMemo(
            () => normalizeScaleValues(criteriaScale.scale.scaleValues),
            [criteriaScale]
        )

        return (
            <ShouldUpdateChecker fieldPath={['formMode']}>
                {({ getFieldValue }) => {
                    const formMode = getFieldValue('formMode')

                    return (
                        <>
                            <FormItemAdapter
                                fieldName={addNameToNamePath(
                                    name,
                                    'scaleValueId'
                                )}
                                className={styles.wrapper}
                            >
                                <RadioControl
                                    values={scaleList}
                                    formMode={formMode}
                                />
                            </FormItemAdapter>

                            <FormItemAdapter
                                fieldName={addNameToNamePath(name, 'comment')}
                                label={LOCAL.LABELS.COMMENT}
                            >
                                <TextAreaControl formMode={formMode} />
                            </FormItemAdapter>
                        </>
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
