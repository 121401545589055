import template from 'lodash/template'
import { LOCAL } from 'core/local'
import { QuizType } from 'core/api'

export const FORM_INITIAL_VALUES = {
    quizType: QuizType.Feedback,
    presentation: {
        welcomeWindow: {
            message: LOCAL.MESSAGES.QUIZ_PORTFOLIO_GREETING_TEXT,
        },
        finalWindow: {
            message: template(LOCAL.MESSAGES.PORTFOLIO_FINAL_MESSAGE)({
                portfolioType: LOCAL.LABELS.QUIZ,
            }),
        },
    },
}

/**
 * Массив зависимых полей, при изменении которых происходит обновление состояния формы
 */
export const fieldsShouldUpdate = [
    'questionnaireId',
    'positionIds',
    'quizType',
    'forAllPositions',
    'forAllOrganizations',
]

/**
 * Массив зависимых полей, при изменении которых происходит сброс значения для поля `questionnaireId`
 */
export const questionnaireIdDependencyFields = [
    'positionIds',
    'quizType',
    'forAllPositions',
]
