import React from 'react'
import cn from 'classnames'
import { ConsistencyResultType } from 'core/api'
import { IconType } from 'components/shared/IconsAdapter/IconsAdapter.types'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { REPORT_LEVELS_TABLES_OPTIONS } from 'consts'
import { getItemName, getRoundedOneCharNumber } from 'utils'

import styles from './ReportView.module.scss'
import { ObjectWithNameField } from './ReportView.types'

/**
 * Проверить находится ли значение в диапазоне кортежа
 * @param value значение
 * @param range кортеж-диапазон
 */
const isInRange = (value: number, range: [number, number]) =>
    value >= range[0] && value <= range[1]

/**
 * Определить цвет по значению
 * @param value значение
 */
export const defineColorByValue = (value: number) => {
    let color

    Object.values(REPORT_LEVELS_TABLES_OPTIONS).forEach((opt) => {
        if (isInRange(value, opt.SCORE)) {
            color = opt.COLOR
        }
    })

    return color
}

/**
 * Рендер клетки со результатом оценки
 * @param value значение
 * @param enableColor опциональная настройка цвета
 */
export const renderScore = (value?: number, enableColor?: any) => {
    const roundedValue = getRoundedOneCharNumber(value)

    if (!roundedValue) return LOCAL.LABELS.NO_DATA_SHORT

    const disableColor =
        enableColor !== undefined &&
        (enableColor === false || enableColor === null)

    return (
        <span
            style={{
                color: !disableColor
                    ? defineColorByValue(+roundedValue)
                    : undefined,
            }}
        >
            {roundedValue}
        </span>
    )
}

/**
 * Создать маркированный список
 */
export const makeList = <T extends ObjectWithNameField | string>(
    items?: T[],
    className?: string
) => (
    <ul className={cn(styles.text, className)}>
        {items?.map((el, i) => (
            <li key={i}>
                {typeof el === 'string'
                    ? el
                    : getItemName(el as ObjectWithNameField)}
            </li>
        ))}
    </ul>
)

/**
 * Получить иконку, зависимую от согласованности
 */
export const getConsistencyIcon = (consistency?: ConsistencyResultType) => {
    let iconType: IconType

    switch (consistency) {
        case ConsistencyResultType.LowConsistency:
            iconType = 'CustomIconConsistencyLow'
            break
        case ConsistencyResultType.MediumConsistency:
            iconType = 'CustomIconConsistencyMedium'
            break
        case ConsistencyResultType.HighConsistency:
            iconType = 'CustomIconConsistencyHigh'
            break
        default:
            return
    }

    return <IconsAdapter iconType={iconType} />
}
