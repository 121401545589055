import { LOCAL } from 'core/local'

import { ComparingOptions } from './UserProfileContext.types'

export const COMPARING_OPTIONS = [
    {
        label: LOCAL.LABELS.PROFILE_DISPLAY_DATA_TYPE_POSITION,
        value: ComparingOptions.ProfilePosition,
    },
    {
        label: LOCAL.LABELS.PROFILE_DISPLAY_DATA_TYPE_ASSESSMENT,
        value: ComparingOptions.Assessments,
    },
]

export const COMPARING_OPTIONS_DEFAULT = [ComparingOptions.ProfilePosition]
