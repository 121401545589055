import React from 'react'
import { ComponentCommonProps } from 'App.types'
import { ControlGroup } from 'components/controls'
import { DynamicListControl } from 'components/controls/DynamicListControl'
import { LOCAL } from 'core/local'

import styles from './ProductProgram.module.scss'

/** Блок полей формы создания карточки продукта - программа продукта */
export const ProductProgram: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <div className={styles.wrapper}>
            <ControlGroup
                title={`${LOCAL.LABELS.TASKS_THAT_PROGRAM_SOLVES}:`}
                type="bordered"
                className={styles.card}
            >
                <DynamicListControl
                    listFieldName="challenges"
                    label={LOCAL.LABELS.OBJECTIVE}
                    formMode={formMode}
                />
            </ControlGroup>

            <ControlGroup
                title={`${LOCAL.LABELS.YOU_WILL_LEARN}:`}
                type="bordered"
                className={styles.card}
            >
                <DynamicListControl
                    listFieldName="outcomes"
                    label={LOCAL.LABELS.RESULT_OF_THE_EDUCATION}
                    formMode={formMode}
                />
            </ControlGroup>
        </div>
    )
)
