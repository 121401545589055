import React from 'react'
import { AssessmentRole, IndicatorResultsReport360Contract } from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { getItemName } from 'utils'

import styles from './AssessmentsRespondentsIndicators.module.scss'
import { getConsistencyIcon, renderScore } from '../ReportView/ReportView.utils'

const ROLES_COLUMNS_KEYS = {
    [AssessmentRole.SelfAssessment]: 'averageScoreSelf',
    [AssessmentRole.Manager]: 'averageScoreManager',
    [AssessmentRole.Subordinate]: 'averageScoreSubordinate',
    [AssessmentRole.Colleague]: 'averageScoreCollegue',
}

/**
 * Получить колонки для таблицы с учетом спрятанных ролей
 */
export const getColumns = (rolesToHide: AssessmentRole[]) => {
    const columns: ColumnsType<IndicatorResultsReport360Contract> = [
        {
            dataIndex: 'indicator',
            key: 'indicator',
            render: (el, row) => (
                <div className={styles.indicatorCell}>
                    {getItemName(el)}

                    {getConsistencyIcon(row.consistency)}
                </div>
            ),
        },
        {
            title: LOCAL.LABELS.ASSESSMENT_SELF,
            dataIndex: ROLES_COLUMNS_KEYS[AssessmentRole.SelfAssessment],
            key: AssessmentRole.SelfAssessment,
            align: 'center',
            render: (value: number) => renderScore(value),
        },
        {
            title: LOCAL.LABELS.MANAGER,
            dataIndex: ROLES_COLUMNS_KEYS[AssessmentRole.Manager],
            key: AssessmentRole.Manager,
            align: 'center',
            render: (value: number, row) =>
                renderScore(value, row.opinionFormedManager),
        },
        {
            title: LOCAL.LABELS.SUBORDINATES,
            dataIndex: ROLES_COLUMNS_KEYS[AssessmentRole.Subordinate],
            key: AssessmentRole.Subordinate,
            align: 'center',
            render: (value: number, row) =>
                renderScore(value, row.opinionFormedSubordinate),
        },
        {
            title: LOCAL.LABELS.COLLEAGUES,
            dataIndex: ROLES_COLUMNS_KEYS[AssessmentRole.Colleague],
            key: AssessmentRole.Colleague,
            align: 'center',
            render: (value: number, row) =>
                renderScore(value, row.opinionFormedCollegue),
        },
        {
            title: LOCAL.LABELS.ALL_RESPONDENTS,
            dataIndex: 'averageScoreWithoutSelfEstim',
            key: 'averageScoreWithoutSelfEstim',
            align: 'center',
            render: (value: number, row) =>
                renderScore(value, row.opinionFormedAllGroups),
        },
    ]

    return columns.filter(
        ({ key }) => !(rolesToHide as string[]).includes(key as string)
    )
}
