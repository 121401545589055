import React, { useContext, useEffect, useState } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { Link } from 'react-router-dom'
import { NotificationCounterContext } from 'contexts'
import { NotificationsService } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { onReceiveUnreadMessageCount } from 'core/helpers/signalr'
import { useHttp } from 'hooks'

import styles from './NotificationIndicator.module.scss'

/** Значок уведомлений с количеством непрочитанных */
export const NotificationIndicator: React.FC = React.memo(() => {
    const [getData, data] = useHttp(NotificationsService.getMeta)

    const { notificationUnreadCount } = useContext(NotificationCounterContext)

    const [unreadCount, setUnreadCount] = useState<number | undefined>(0)

    useEffect(() => {
        setUnreadCount(notificationUnreadCount)
    }, [notificationUnreadCount])

    useEffect(() => {
        getData()
    }, [getData])

    useEffect(() => {
        setUnreadCount(data?.unreadCount)
    }, [data])

    useEffect(() => {
        onReceiveUnreadMessageCount((response: number) => {
            setUnreadCount(response)
        })
    }, [])

    return (
        <Link to={ROUTE_NAMES.USER_NOTIFICATIONS} className={styles.wrapper}>
            <IconsAdapter
                iconType="CustomIconNotification"
                className={styles.icon}
            />

            <span className={styles.title}>{unreadCount || 0}</span>
        </Link>
    )
})
