import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'

import React, { useEffect, useState } from 'react'
import Viewer, { PageChangeEvent, Worker } from '@phuocng/react-pdf-viewer'

import { PDF_INITIAL_PAGE, WORKER_URL } from './PlayerPdf.consts'
import { PlayerPdfProps } from './PlayerPdf.types'

/** Плеер для PDF */
export const PlayerPdf: React.FC<PlayerPdfProps> = React.memo(
    ({
        fileUrl,
        viewerOptions,
        progress = PDF_INITIAL_PAGE,
        setProgressForSend,
    }) => {
        const [documentData, setDocumentData] = useState<PageChangeEvent>()
        const [breakpoint, setBreakpoint] = useState<number>()

        useEffect(() => {
            if (!documentData) return

            setBreakpoint(documentData.doc.numPages * progress)
            setProgressForSend?.(
                documentData.currentPage / documentData.doc.numPages
            )
        }, [documentData, progress, setProgressForSend])

        if (!fileUrl) return null

        return (
            <Worker workerUrl={WORKER_URL}>
                <Viewer
                    fileUrl={fileUrl}
                    {...viewerOptions}
                    initialPage={breakpoint}
                    onPageChange={setDocumentData}
                />
            </Worker>
        )
    }
)
