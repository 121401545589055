import React from 'react'
import cn from 'classnames'
import isNull from 'lodash/isNull'
import { Tooltip } from 'antd'
import { getPopupContainer as getPopupContainerDefault } from 'utils/common.utils'

import styles from './TooltipAdapter.module.scss'
import { TooltipAdapterProps } from './TooltipAdapter.types'

/**
 * Адаптер для `Tooltip` от `antd`
 */
export const TooltipAdapter: React.FC<TooltipAdapterProps> = React.memo(
    ({
        placement = 'topLeft',
        getPopupContainer,
        children,
        className,
        ...restProps
    }) => {
        const getContainer = isNull(getPopupContainer)
            ? undefined
            : getPopupContainer || getPopupContainerDefault

        return (
            <Tooltip
                {...restProps}
                placement={placement}
                overlayClassName={cn(className, styles.wrapper)}
                getPopupContainer={getContainer}
            >
                {children}
            </Tooltip>
        )
    }
)
