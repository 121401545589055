import { Moment } from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import { getDayStartWithoutTime } from 'utils'

import { PickerModeType } from './RangePickerControl.types'

export const mappingRangePickerDate = (
    dates: RangeValue<Moment>
): [string, string] | null =>
    dates && [
        getDayStartWithoutTime(dates[0]),
        getDayStartWithoutTime(dates[1]),
    ]

export const isPickerYearType = (picker: PickerModeType) => picker === 'year'
