import React, { useContext, useEffect } from 'react'
import {
    CKEditorEventAction,
    registerEditorEventHandler,
    stripPrefix,
    useCKEditor,
} from 'ckeditor4-react'
import { ColorThemeContext } from 'contexts'
import { LOCAL } from 'core/local'
import { isThemeColorLight } from 'contexts/ColorTheme/ColorTheme.utils'
import { message } from 'antd'
import { template } from 'lodash'

import {
    EDITOR_BODY_DARK_STYLES,
    EDITOR_BODY_LIGHT_STYLES,
} from './Editor.consts'
import { EditorProps } from './Editor.types'
import {
    isEditorFullScreenState,
    isEditorStatusReady,
    setEditorStyles,
} from './Editor.utils'

export const Editor: React.FC<EditorProps> = ({
    dispatchEvent,
    initData,
    onChange,
    onFocus,
    config,
    newValue,
    isReadOnly,
    ...restProps
}) => {
    const [element, setElement] = React.useState<HTMLDivElement | null>(null)

    const { theme } = useContext(ColorThemeContext)

    /** TODO: https://jira.it2g.ru/browse/SDO-5931 Заменить на 5ую CKEditor'a версию, когда выйдет типизация */
    const { editor, status } = useCKEditor({
        element,
        dispatchEvent,
        initContent: initData,
        editorUrl: '/static-api/ckeditor/ckeditor.js',
        config: {
            ...config,
            removeButtons: '',
            // TODO: CKEditor easyimage удален, чтобы не было ошибок, функциональность требует наличия эндпоинта, который будет отдавать токен, сейчас не реализовано. Убрать если понадобиться реализация загрузки файла
            removePlugins: 'easyimage, cloudservices',
        },
        ...restProps,
    })

    useEffect(() => {
        if (!editor) return

        const cleanupBeforeDestroy = registerEditorEventHandler({
            editor,
            evtName: stripPrefix(CKEditorEventAction.beforeDestroy),
            handler: (event) => {
                if (isEditorFullScreenState(event.editor)) {
                    event.editor.execCommand('maximize')
                }
            },
        })

        const cleanupChange = registerEditorEventHandler({
            editor,
            evtName: stripPrefix(CKEditorEventAction.change),
            handler: onChange,
        })

        return () => {
            cleanupBeforeDestroy()
            cleanupChange()
        }
    }, [editor, onChange])

    useEffect(() => {
        if (newValue) {
            editor?.setData(newValue)
        }
    }, [newValue, editor])

    useEffect(() => {
        if (isEditorStatusReady(status)) {
            editor?.setReadOnly(isReadOnly)
        }
    }, [editor, status, isReadOnly])

    useEffect(() => {
        if (isEditorStatusReady(status)) {
            editor?.document
                .getBody()
                .setStyles(
                    isThemeColorLight(theme)
                        ? EDITOR_BODY_LIGHT_STYLES
                        : EDITOR_BODY_DARK_STYLES
                )

            setEditorStyles('.cke_top', theme)
            setEditorStyles('.cke_bottom', theme)
        }
    }, [theme, editor, status])

    useEffect(() => {
        if (editor && onFocus) {
            const cleanup = registerEditorEventHandler({
                editor,
                evtName: stripPrefix(CKEditorEventAction.focus),
                handler: onFocus,
            })

            return cleanup
        }
    }, [editor, onFocus])

    useEffect(() => {
        editor?.on('notificationShow', (event: any) => {
            if (
                event.data.notification.message.includes(
                    'This file format is not supported'
                )
            ) {
                message.warning(
                    template(
                        LOCAL.MESSAGES.YOU_CAN_ONLY_UPLOAD_FORMATS_TEMPLATE
                    )({
                        documentType: 'JPEG/PNG',
                    })
                )

                event.cancel()
            }
        })
    }, [editor])

    return <div ref={setElement}>{initData}</div>
}
