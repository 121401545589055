import cn from 'classnames'
import React, { FC } from 'react'

import styles from './ChartRows.module.scss'
import { ChartRowsProps } from './ChartRows.types'
import { getBarBackgrounds } from './ChartRows.utils'

export const ChartRows: FC<ChartRowsProps> = React.memo(
    ({ grafics, scaleDifference, scaleFrom, isScale }) => (
        <>
            {grafics.map((el) => {
                const percent = ((el.value - scaleFrom) * 100) / scaleDifference

                return (
                    <div
                        className={styles.wrapper}
                        key={`${el.name}${el.value}`}
                    >
                        <div
                            className={cn(
                                styles.name,
                                isScale && styles.scaleName
                            )}
                        >
                            {el.name}
                        </div>

                        <div className={styles.chartBarBlock}>
                            <div
                                className={cn(
                                    styles.chartBar,
                                    styles[
                                        `chartBar${getBarBackgrounds(percent)}`
                                    ]
                                )}
                                style={{ width: `${percent}%` }}
                            >
                                <div
                                    className={cn(styles.chartBarValue, {
                                        [styles.zeroScore]: el.value === 0,
                                    })}
                                >
                                    {el.value}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
)
