import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'
import noop from 'lodash/noop'
import stylesCanvasInfiniteScrollBlock from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.module.scss'
import React, { useCallback } from 'react'
import { CollapseSingle, Title } from 'components/shared'
import { Form, Radio } from 'antd'
import { LOCAL } from 'core/local'

import styles from './StageBackgroundImagesAlbums.module.scss'
import { StageBackgroundImagesAlbumsProps } from './StageBackgroundImagesAlbums.types'
import { canvasBgImgMapFn } from './StageBackgroundImagesAlbums.utils'

/** Компонент альбомов фоновых картинок холста этапа трека */
export const StageBackgroundImagesAlbums: React.FC<StageBackgroundImagesAlbumsProps> = React.memo(
    ({ albums, disabled, stageIndex, stageStatus, canvasKey }) => {
        const renderHeader = useCallback(
            (title) => <Title bottomIndent="0" type="h6" text={title} />,
            []
        )

        return (
            <div>
                {albums.map((album) => (
                    <CollapseSingle
                        title={album.name || LOCAL.ACTIONS.SHOW}
                        renderHeader={renderHeader}
                        className={styles.wrapper}
                        key={album.id}
                    >
                        {!!album.iconBackgrounds?.length && (
                            <div>
                                <Form.Item
                                    name={[
                                        'trackCanvases',
                                        canvasKey,
                                        'trackStageStyles',
                                        stageIndex,
                                        'icons',
                                        stageStatus,
                                        'iconBackgroundId',
                                    ]}
                                >
                                    <Radio.Group
                                        disabled={disabled}
                                        className={
                                            stylesCanvasInfiniteScrollBlock.radioGroup
                                        }
                                    >
                                        <InfiniteScroll
                                            hasMore={false}
                                            loader={null}
                                            next={noop}
                                            dataLength={
                                                album.iconBackgrounds?.length
                                            }
                                            className={cn(
                                                stylesCanvasInfiniteScrollBlock.fields,
                                                disabled &&
                                                    stylesCanvasInfiniteScrollBlock.fieldsDisabled,
                                                styles.scroll
                                            )}
                                        >
                                            {album.iconBackgrounds.map(
                                                canvasBgImgMapFn
                                            )}
                                        </InfiniteScroll>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        )}
                    </CollapseSingle>
                ))}
            </div>
        )
    }
)
