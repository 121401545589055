import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import {
    CourseSearchContract,
    CoursesService,
    StructuralUnitsSearchContract,
    StructuralUnitsService,
} from 'core/api'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps, normalizeDataForSelectAndRadio } from 'utils'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { ROW_GUTTER, STRUCTURAL_UNIT_SEARCH_BODY } from 'consts'

/**
 * Форма фильтрации курсов
 */
export const TrainingMaterialsFilters: React.FC<FiltersProps<
    CourseSearchContract
>> = React.memo((props) => {
    const [courseTypes, setCourseTypes] = useState<LabeledValue[]>([])

    const [owners, setOwners] = useState<LabeledValue[]>()

    useEffect(() => {
        const fetchOwners = async () => {
            try {
                const organizations = await StructuralUnitsService.flatSearch({
                    body: STRUCTURAL_UNIT_SEARCH_BODY as StructuralUnitsSearchContract,
                    pageSize: 999,
                    pageNumber: 1,
                })

                setOwners(
                    organizations?.pageItems.map(normalizeDataForSelectAndRadio)
                )
            } catch (error) {
                console.error(error)
            }
        }

        fetchOwners()
    }, [])

    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                const courseTypes = await CoursesService.getCourseTypes()

                setCourseTypes(courseTypes.map(normalizeDataForSelectAndRadio))
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [])

    return (
        <ControlGroup type="light">
            <FilterForm {...props}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="format"
                            label={LOCAL.LABELS.FORMAT}
                        >
                            <SelectControl values={courseTypes} showSearch />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="owners"
                            label={LOCAL.LABELS.OWNER}
                        >
                            <SelectControl
                                mode="multiple"
                                values={owners}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: `${LOCAL.LABELS.DATE_DOWNLOAD} (${LOCAL.SINCE})`,
                                fieldName: 'uploadDateStart',
                            }}
                            to={{
                                label: `${LOCAL.LABELS.DATE_DOWNLOAD} (${LOCAL.TO})`,
                                fieldName: 'uploadDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
