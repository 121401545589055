import { FnActionProps } from 'App.types'
import { RecordEntityType } from 'core/api'
import { Rrweb } from 'core/helpers/rrweb'
import { UserContext } from 'contexts'
import { updateMonitoringSettings } from 'contexts/User/UserContextProvider.utils'
import { useCallback, useContext } from 'react'

interface UseRrwebResultProps {
    runMonitoring: FnActionProps
    stopMonitoring: FnActionProps
    pauseMonitoring: FnActionProps
    resumeMonitoring: FnActionProps
    toggleMonitoringSettings: FnActionProps<boolean>
    monitoringEnable?: boolean
}

/**
 * Хук для мониторинга
 */
export const useRrweb = (): UseRrwebResultProps => {
    const { userSettingsData, setUserSettingsData } = useContext(UserContext)

    const toggleMonitoringSettings = useCallback(
        async (isOn?: boolean) => {
            const updateResult = await updateMonitoringSettings(
                !!isOn,
                userSettingsData
            )

            setUserSettingsData(updateResult || null)
        },
        [setUserSettingsData, userSettingsData]
    )

    const runMonitoring = useCallback(
        async (entity: RecordEntityType) => {
            if (Rrweb.isMonitoringRun) return

            const sessionId = await Rrweb.run(entity)

            toggleMonitoringSettings(!!sessionId)
        },
        [toggleMonitoringSettings]
    )

    const resumeMonitoring = useCallback((entity: RecordEntityType) => {
        if (Rrweb.isMonitoringRun) return

        Rrweb.run(entity)
    }, [])

    const pauseMonitoring = useCallback(() => {
        if (!Rrweb.isMonitoringRun) return

        Rrweb.stop()
    }, [])

    const stopMonitoring = useCallback(async () => {
        if (Rrweb.isMonitoringRun) pauseMonitoring()

        toggleMonitoringSettings(false)
    }, [pauseMonitoring, toggleMonitoringSettings])

    return {
        resumeMonitoring,
        runMonitoring,
        pauseMonitoring,
        stopMonitoring,
        toggleMonitoringSettings,
        monitoringEnable: userSettingsData?.monitoringEnable,
    }
}
