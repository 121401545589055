import React from 'react'
import { FiltersProps } from 'utils'
import { PositionProfilesSearchContract } from 'core/api'
import { Tabs } from 'antd'

import styles from './JobProfilesFilters.module.scss'
import { JOB_PROFILE_FILTER_TABS } from './JobProfilesFilters.consts'
import { JobProfileOrgStructureFilters } from '../JobProfileOrgStructureFilters'
import { JobProfileStaffUnitsFilters } from '../JobProfileStaffUnitsFilters'

/**
 * Форма фильтрации сотрудников
 */
export const JobProfilesFilters: React.FC<FiltersProps<
    PositionProfilesSearchContract
>> = React.memo((props) => (
    <Tabs className={styles.wrapper}>
        <Tabs.TabPane {...JOB_PROFILE_FILTER_TABS.ORGANIZATIONS}>
            <JobProfileOrgStructureFilters {...props} />
        </Tabs.TabPane>

        <Tabs.TabPane {...JOB_PROFILE_FILTER_TABS.STAFF_UNITS}>
            <JobProfileStaffUnitsFilters {...props} />
        </Tabs.TabPane>
    </Tabs>
))
