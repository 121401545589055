import isEqual from 'lodash/isEqual'
import unionWith from 'lodash/unionWith'
import React, { ReactNode } from 'react'
import { CloudTag } from 'components/shared/CloudTag'
import { DataNode } from 'rc-tree-select/lib/interface'
import { LOCAL } from 'core/local'
import { ListTag } from 'components/shared/ListTag'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WithFormModeProps } from 'App.types'
import { isFormModeView } from 'utils'

import styles from './MultiSelectControl.module.scss'
import {
    GetMultiSelectTagsProps,
    GetTagLabelProps,
    GettingTagsUtilsProps,
    TagsType,
} from './MultiSelectControl.types'

/**
 * Функция возвращает label
 * @param itemId - значение по которому необходимо найти label
 * @param treeDataCollection - опции выбора для мультиселекта
 */
const getTagLabel = ({ itemId, treeDataCollection }: GetTagLabelProps) => {
    const searchLabel = (el: DataNode): boolean | undefined | DataNode =>
        el.value === itemId || el.children?.find(searchLabel)

    return treeDataCollection?.find(searchLabel)?.title
}

/**
 * Получить пропс ссылки
 * @param itemId - идентификатор элемента для генерации ссылки
 * @param isViewSubtype - если true, то вернуть пропс ссылки для компонента тега
 */
const getTagLink = (itemId: number, isViewSubtype?: boolean) =>
    isViewSubtype
        ? { link: `${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW}/${itemId}` }
        : {}

/**
 * Рендер списка выбранных пунктов меню для типа "Cloud"
 */
const getTagCloudType = ({
    itemId,
    label,
    onDeleteTag,
    isTagsDisabled,
}: GettingTagsUtilsProps) => (
    <CloudTag
        key={itemId}
        showCloseIcon={true}
        onDelete={onDeleteTag?.(itemId)}
        disabled={isTagsDisabled}
    >
        {label}
    </CloudTag>
)

/**
 * Рендер списка выбранных пунктов меню для типа "List|ListView"
 */
const getTagListType = (
    { itemId, onDeleteTag, isTagsDisabled, label }: GettingTagsUtilsProps,
    isViewSubtype?: boolean
) => (
    <ListTag
        key={itemId}
        onDelete={onDeleteTag?.(itemId)}
        disabled={isTagsDisabled}
        className={styles.listTag}
        {...getTagLink(itemId, isViewSubtype)}
    >
        {label}
    </ListTag>
)

/** Функция получения тегов для мультиселекта по заданным типам */
export const getMultiSelectTags = ({
    tagsType,
    treeDataCollection,
    onDeleteTag,
    selectedItemsIds = [],
    isTagsDisabled,
}: GetMultiSelectTagsProps): ReactNode | false => {
    const getCommonTagOptions = (itemId: number): GettingTagsUtilsProps => ({
        itemId,
        onDeleteTag,
        label: getTagLabel({ itemId, treeDataCollection }),
        isTagsDisabled,
    })

    switch (tagsType) {
        case TagsType.Cloud:
            const tagsCloud = selectedItemsIds.map((id) =>
                getTagCloudType(getCommonTagOptions(id))
            )

            return <div className={styles.tagsCloud}>{tagsCloud}</div>

        case TagsType.List:
            const tagsList = selectedItemsIds.map((id) =>
                getTagListType(getCommonTagOptions(id))
            )

            return <div className={styles.tagsList}>{tagsList}</div>

        case TagsType.ListView:
            const tagsListView = selectedItemsIds.map((id) =>
                getTagListType(getCommonTagOptions(id), true)
            )

            return <div>{tagsListView}</div>

        default:
            console.error(
                new TypeError('Incorrect tag type in getMultiSelectTags()')
            )

            return false
    }
}

/**
 * Получение placeholder для MultiSelectControl
 * @param placeholder - placeholder
 * @param formMode - режим формы
 * @param tagsType - тип вывода выбранных пунктов меню
 * @returns placeholder для MultiSelectControl
 */
export const getPlaceholder = (
    placeholder: React.ReactNode,
    formMode?: WithFormModeProps['formMode'],
    tagsType?: TagsType
): React.ReactNode => {
    if (isFormModeView(formMode)) return LOCAL.PLACEHOLDERS.INPUT_EMPTY

    if (isFormModeView(formMode) && tagsType) return ''

    if (!placeholder && !tagsType) return LOCAL.PLACEHOLDERS.SELECT

    return placeholder
}

export interface InjectDataByDefaultProps {
    injectableData?: DataNode[]
    treeData?: DataNode[]
}

export const injectDataByDefault = ({
    injectableData,
    treeData,
}: InjectDataByDefaultProps) => {
    if (injectableData) return unionWith(treeData, injectableData, isEqual)

    return treeData
}
