import React from 'react'
import { ShouldUpdateChecker } from 'components/controls'

export const InfoAssistantChecker: React.FC = React.memo(({ children }) => (
    <ShouldUpdateChecker fieldPath={['useInformationAssistant']}>
        {({ getFieldValue }) =>
            !!getFieldValue('useInformationAssistant') && children
        }
    </ShouldUpdateChecker>
))
