import cn from 'classnames'
import React, { useMemo } from 'react'
import { Col, Row, Tag } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROW_GUTTER } from 'consts'

import styles from './ContentTitle.module.scss'
import { ContentTitleProps } from './ContentTitle.types'
import { Title } from '../Title'
import { getTag } from './ContentTitle.utils'

/** Типовой заголовок для страниц приложения */
export const ContentTitle: React.FC<ContentTitleProps> = React.memo(
    ({
        children,
        className,
        title,
        renderTitle,
        backLink,
        status,
        disableBoxShadow,
        transparent,
        isArchive,
        flex,
        cols,
        formMode,
    }) => {
        const titleText = useMemo(
            () => (
                <>
                    {title}

                    {status && (
                        <Tag color="blue" className={styles.tag}>
                            {getTag({ isArchive, status, formMode })}
                        </Tag>
                    )}
                </>
            ),
            [isArchive, status, title, formMode]
        )

        return (
            <div
                className={cn(
                    {
                        [styles.disableBoxShadow]: disableBoxShadow,
                        [styles.transparent]: transparent,
                    },
                    styles.wrapper,
                    className
                )}
            >
                <Row gutter={ROW_GUTTER} align="middle" justify="space-between">
                    <Col {...cols?.title}>
                        <>
                            {renderTitle?.(title) || (
                                <Title
                                    type="h3"
                                    text={titleText}
                                    bottomIndent="0"
                                    flex={flex}
                                />
                            )}
                        </>
                    </Col>

                    {children && (
                        <Col {...cols?.children} className={styles.children}>
                            {children}
                        </Col>
                    )}

                    {backLink && (
                        <Col className={styles.backLink} xs={24}>
                            <Link
                                to={backLink.path || '#s'}
                                onClick={backLink.onClick}
                            >
                                <IconsAdapter iconType="ArrowLeftOutlined" />

                                {backLink.text ||
                                    LOCAL.LINKS.BACK_TO_THE_CATALOG}
                            </Link>
                        </Col>
                    )}
                </Row>
            </div>
        )
    }
)
