import React, { useEffect } from 'react'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import { DevelopmentGoalsService, DictionaryContract } from 'core/api'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import {
    MultiSelectControl,
    ShouldUpdateChecker,
    SwitchControl,
    TagsType,
} from 'components/controls'
import { isFormModeView, normalizeDataForTreeSelect } from 'utils'
import { useHttp } from 'hooks'

export const DevelopmentGoals: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => {
    const [getGoals, goals] = useHttp<DictionaryContract[]>(
        DevelopmentGoalsService.getAllForSelect
    )

    useEffect(() => {
        getGoals()
    }, [getGoals])

    return (
        <>
            <Form.Item name="isDevelopmentGoals">
                <SwitchControl
                    addonAfter={LOCAL.CATALOGS.DEVELOPMENT_GOALS}
                    disabled={isFormModeView(formMode) || disabled}
                />
            </Form.Item>

            <ShouldUpdateChecker fieldPath={['isDevelopmentGoals']}>
                {({ getFieldValue }) =>
                    getFieldValue('isDevelopmentGoals') && (
                        <Form.Item name="goalIds">
                            <MultiSelectControl
                                tagsType={TagsType.Cloud}
                                treeData={goals?.map(
                                    normalizeDataForTreeSelect
                                )}
                                formMode={formMode}
                                disabled={disabled}
                            />
                        </Form.Item>
                    )
                }
            </ShouldUpdateChecker>
        </>
    )
})
