import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Form, Modal, Row } from 'antd'
import {
    DictionaryContract,
    NotificationFilteringConditionsService,
    NotificationMailingTaskContract,
    NotificationMailingTasksService,
} from 'core/api'
import { FORM_IDS } from 'core/configs'
import { HiddenField } from 'components/controls'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH, MODAL_Z_INDEX, ROW_GUTTER } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsCommonProps } from 'App.types'
import { createConfirmPopup } from 'utils/notification.utils'
import { eventEmitter } from 'core/helpers/eventEmitter'
import {
    getInitialValuesForCopy,
    isFormModeCopy,
    isFormModeCreate,
    isFormModeEdit,
    isFormModeView,
    isNotificationMailingTaskTypeAtSpecifiedTime,
} from 'utils'
import { useHistory, useParams } from 'react-router-dom'

import styles from './DistributionMailingAssignmentForm.module.scss'
import { DistributionMailingAssignmentFormProps } from './DistributionMailingAssignmentForm.types'
import { DistributionMailingGeneralnfo } from '../DistributionMailingGeneralnfo'
import { DistributionMailingInfo } from '../DistributionMailingInfo'
import { DistributionMailingMail } from '../DistributionMailingMail'
import { DistributionMailingParams } from '../DistributionMailingParams'
import { ON_MAP_DISTRIBUTION_RESPONSE_DATA } from './DistributionMailingAssignmentForm.events'
import {
    mapFormDataToCheckRequest,
    mapFormDataToRequest,
    mapResponseToFormData,
} from './DistributionMailingAssignmentForm.utils'

/** Форма для создания, редактирования задания на рассылку */
export const DistributionMailingAssignmentForm: FC<DistributionMailingAssignmentFormProps> = React.memo(
    ({
        form,
        formMode,
        initialValuesForEdit,
        isSaveDocument,
        updateLoader,
        onBlockUserRouting,
        eventType,
    }) => {
        const history = useHistory()
        const { templateId } = useParams<UrlParamsCommonProps<'templateId'>>()

        const [modal, contextHolder] = Modal.useModal()

        const initialValues = useMemo(
            () => (templateId ? { templateId: +templateId } : {}),
            [templateId]
        )

        const isDisabledMailingInfo =
            !isFormModeCreate(formMode) && !isFormModeCopy(formMode)

        useEffect(() => {
            const onMapResponseToFormData = async () => {
                if (!initialValuesForEdit?.id) return

                form.setFieldsValue(
                    mapResponseToFormData(
                        getInitialValuesForCopy(initialValuesForEdit, formMode),
                        (await NotificationFilteringConditionsService.getForSelect()) ||
                            []
                    )
                )

                eventEmitter.emit(ON_MAP_DISTRIBUTION_RESPONSE_DATA)
            }

            onMapResponseToFormData()
        }, [form, initialValuesForEdit, formMode])

        const getTitle = useCallback(() => {
            if (isSaveDocument) {
                return LOCAL.MESSAGES.MAILING_SAVE_SUCCESS_MESSAGE
            }
            if (!isNotificationMailingTaskTypeAtSpecifiedTime(eventType)) {
                return LOCAL.MESSAGES.MAILING_SCHEDULE_SUCCESS_MESSAGE
            }

            return LOCAL.MESSAGES.MAILING_SEND_SUCCESS_MESSAGE
        }, [eventType, isSaveDocument])

        /**
         * Функция отправки формы для сохранения или создания
         * задания на рассылку
         */
        const submitForm = useCallback(
            async (values) => {
                const body = mapFormDataToRequest(values)

                try {
                    let document: NotificationMailingTaskContract

                    if (isSaveDocument) {
                        updateLoader(true)

                        document = await NotificationMailingTasksService.save({
                            body,
                        })
                    } else {
                        document = await NotificationMailingTasksService.schedule(
                            {
                                body,
                            }
                        )
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: getTitle(),

                        content: `${LOCAL.MESSAGES.GO_TO_PAGE} "${LOCAL.LABELS.MAILING}"?`,
                        okText: LOCAL.ACTIONS.CONFIRM,
                        onOk: () => {
                            history.push(
                                ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT
                            )
                        },
                        onCancel: () => {
                            history.push(
                                `${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_VIEW}/${document.id}`
                            )
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [
                isSaveDocument,
                onBlockUserRouting,
                getTitle,
                updateLoader,
                history,
            ]
        )

        const getLinksMailingAssignmentsList = useCallback(
            (data: DictionaryContract[]) => (
                <>
                    <p>{LOCAL.MESSAGES.MAILING_ASSIGNMENT_HAS_DUPLICATES}</p>

                    <p>{LOCAL.MESSAGES.ANALOG_TASKS}</p>

                    <div className={styles.links}>
                        {data.map(({ id, name }) => (
                            <Button
                                key={id}
                                type="link"
                                targetBlank
                                href={`${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_VIEW}/${id}`}
                                icon={<IconsAdapter iconType="EyeOutlined" />}
                                className={styles.link}
                            >
                                {name}
                            </Button>
                        ))}
                    </div>

                    <p>
                        {
                            LOCAL.MESSAGES
                                .MAILING_ASSIGNMENT_HAS_DUPLICATES_CONFIRM_TEXT
                        }
                    </p>
                </>
            ),
            []
        )

        /**
         * Функция обработки отправки формы
         */
        const handleFinish = useCallback(
            async (values) => {
                try {
                    updateLoader(true)

                    if (values.eventId && !isSaveDocument) {
                        const body = mapFormDataToCheckRequest(values)
                        const data = await NotificationMailingTasksService.searchByTask(
                            { body }
                        )

                        updateLoader(false)

                        if (!!data.length) {
                            modal.confirm({
                                zIndex: MODAL_Z_INDEX.CONFIRM,
                                width: MODAL_WIDTH.SM,
                                title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                                content: getLinksMailingAssignmentsList(data),
                                cancelText: LOCAL.NO,
                                okText: LOCAL.YES,
                                icon: <IconsAdapter iconType="WarnOutlined" />,
                                onOk: () => {
                                    submitForm(values)
                                },
                            })

                            return
                        }
                    }

                    await submitForm(values)
                } catch (error) {
                    console.log(error)
                } finally {
                    updateLoader(false)
                }
            },
            [
                submitForm,
                getLinksMailingAssignmentsList,
                updateLoader,
                modal,
                isSaveDocument,
            ]
        )

        const handleValuesChange = useCallback(
            (changedValue, allValues) => {
                if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }

                const [changedValueKey] = Object.keys(changedValue)

                if (changedValueKey === 'type') {
                    form?.setFieldsValue({
                        appointments: [],
                        appointmentObjects: [],
                        eventId: undefined,
                        category: undefined,
                        subCategory: undefined,
                        conditions: [],
                    })
                }

                if (changedValueKey === 'category')
                    form?.setFieldsValue({
                        appointments: [],
                    })

                if (changedValueKey === 'subCategory')
                    form?.setFieldsValue({
                        appointmentObjects: [],
                    })

                if (changedValueKey === 'eventId')
                    form?.setFieldsValue({
                        conditions: [],
                    })

                if (changedValueKey === 'appointmentObjects') {
                    form?.setFieldsValue({
                        appointmentObjects: allValues.appointmentObjects,
                    })
                }
            },
            [form, formMode, onBlockUserRouting]
        )

        if (
            (isFormModeCopy(formMode) ||
                isFormModeView(formMode) ||
                isFormModeEdit(formMode)) &&
            !initialValuesForEdit?.id
        ) {
            return null
        }

        return (
            <Form
                form={form}
                id={FORM_IDS.DISTRIBUTION_MAILING_FORM}
                onValuesChange={handleValuesChange}
                onFinish={handleFinish}
                initialValues={initialValues}
            >
                {!isFormModeCopy(formMode) && <HiddenField fieldName="id" />}

                {contextHolder}

                <Row gutter={ROW_GUTTER} className={styles.row}>
                    <Col xs={16}>
                        <DistributionMailingGeneralnfo
                            form={form}
                            formMode={formMode}
                        />
                    </Col>

                    <Col xs={8}>
                        {isDisabledMailingInfo && (
                            <DistributionMailingInfo
                                task={initialValuesForEdit!}
                            />
                        )}
                    </Col>
                </Row>

                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <DistributionMailingParams
                            form={form}
                            formMode={formMode}
                            initialValues={initialValuesForEdit}
                        />
                    </Col>

                    <Col xs={16}>
                        <DistributionMailingMail
                            form={form}
                            formMode={formMode}
                            initialValuesForEdit={initialValuesForEdit}
                        />
                    </Col>
                </Row>
            </Form>
        )
    }
)
