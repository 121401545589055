import cn from 'classnames'
import { IconsAdapterProps } from 'components/shared/IconsAdapter/IconsAdapter.types'

import styles from './VerifyIcon.module.scss'

export const getIconProps = (isCorrectly: boolean): IconsAdapterProps => ({
    iconType: isCorrectly ? 'CheckCircleFilled' : 'CloseCircleFilled',
    className: cn(
        styles.wrapper,
        isCorrectly ? styles.wrapperSuccess : styles.wrapperFail
    ),
})
