import {
    ImgsFetchFnResponseProps,
    ImgsUploadFnResponseProps,
} from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.types'
import { RcCustomRequestOptions } from 'types'
import { TrackCanvasBackgroundsService } from 'core/api'

/**
 * Запрос фоновых изображений
 * @param pageSize количество записей на стр.
 * @param pageNumber номер стр.
 * @returns {Promise<ImgsFetchFnResponseProps>} объект с массивом изображений
 */
export const canvasBgImgsFetchFn = (
    pageSize: number,
    pageNumber: number
): Promise<ImgsFetchFnResponseProps> =>
    TrackCanvasBackgroundsService.search({
        pageSize,
        pageNumber,
    })

/**
 * Загрузка нового фонового изображеня
 * @param request объект запроса, содержащего изображение
 * @returns {Promise<ImgsUploadFnResponseProps>} объект с массивом изображений
 */
export const canvasBgImgsUploadFn = (
    request: RcCustomRequestOptions
): Promise<ImgsUploadFnResponseProps> =>
    TrackCanvasBackgroundsService.upload({
        file: request.file,
    })
