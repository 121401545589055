import { MLSEC_IN_SECOND } from 'consts'

import { TimerStatuses } from './QuestionTimer.types'

/**
 * Функция normalizeTime предназначена для приведения времени из количества секунд к виду mm:ss
 * @param timeInSeconds - количество времени в секундах
 */
export const normalizeTime = (timeInSeconds: number) => {
    const date = new Date(timeInSeconds * MLSEC_IN_SECOND)
    const minutes = date.getUTCMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    return `${minutes}:${seconds}`
}

/**
 * Проверки статуса таймера на соответствие одному из определенных значений
 * @param timerStatus - статус таймера подлежащий сравнению с заданным внутри функции
 */
export const isTimerStatusRunning = (timerStatus?: TimerStatuses) =>
    timerStatus === TimerStatuses.Running

export const isTimerStatusStopped = (timerStatus?: TimerStatuses) =>
    timerStatus === TimerStatuses.Stopped

export const isTimerStatusPause = (timerStatus?: TimerStatuses) =>
    timerStatus === TimerStatuses.Pause
