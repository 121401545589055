import React from 'react'
import { FreeAnswerTabType } from 'core/api'
import { LOCAL } from 'core/local'

import { TabContent } from '../TabContent'

export const getTabsOptions = (activeKey: string) => [
    {
        tab: LOCAL.LABELS.TEXT_ANSWER,
        key: FreeAnswerTabType.Text,
        content: (
            <TabContent
                tabType={FreeAnswerTabType.Text}
                activeKey={activeKey}
            />
        ),
        visible: true,
    },
    {
        tab: LOCAL.LABELS.USERS_COMMENTS_TO_ANSWERS,
        key: FreeAnswerTabType.Comments,
        content: (
            <TabContent
                tabType={FreeAnswerTabType.Comments}
                activeKey={activeKey}
            />
        ),
        visible: true,
    },
    {
        tab: LOCAL.LABELS.FREE_ANSWERS,
        key: FreeAnswerTabType.FreeAnswer,
        content: (
            <TabContent
                tabType={FreeAnswerTabType.FreeAnswer}
                activeKey={activeKey}
            />
        ),
        visible: true,
    },
]
