import { CompetenceGroupWithCompetenciesContract } from 'core/api'
import { DropResult } from 'react-beautiful-dnd'
import { getNumFromStr } from 'utils'

/**
 * Получить направления передвигаемой сущности
 * @param result -  результат передвижения
 * @param competenciesData - массив групп компетенций
 */
export const getDndDestination = (
    result: DropResult,
    competenciesData?: CompetenceGroupWithCompetenciesContract[]
) => {
    const positionFrom = result.source.index
    const positionTo = result.destination?.index || 0
    const beforePositionTo =
        positionFrom > positionTo ? positionTo - 1 : positionTo

    /**
     * Бэку необходимо отдавать `0` если направление передвигаемого элемента - первая позиция
     */
    if (result.type.includes('group')) {
        return beforePositionTo < 0
            ? { id: 0 }
            : competenciesData?.[beforePositionTo]
    }

    if (result.type.includes('competence')) {
        const groupIndex = getNumFromStr(result.type)

        return competenciesData?.[+groupIndex]?.competencies?.[beforePositionTo]
    }

    const [groupIndex, competenceIndex] = result.type.split('-')

    return competenciesData?.[+groupIndex]?.competencies?.[+competenceIndex]
        ?.indicators?.[beforePositionTo]
}
