import React, { FC } from 'react'
import { AddButton, ButtonsToolbar, IconsAdapter } from 'components/shared'
import { Form } from 'antd'
import { FormItemAdapter, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import styles from './PastTextAnswerVariantsControl.module.scss'
import { PastTextAnswerVariantsControlProps } from './PastTextAnswerVariantsControl.types'

/** Список вариантов правильного ответа на вопрос типа "Вставить пропущенный текст" */
export const PastTextAnswerVariantsControl: FC<PastTextAnswerVariantsControlProps> = React.memo(
    (props) => {
        const { fieldName, formMode, disabled } = props

        return (
            <Form.List name={fieldName}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <div key={field.key} className={styles.item}>
                                <FormItemAdapter
                                    className={styles.value}
                                    fieldName={[field.name]}
                                >
                                    <InputControl
                                        formMode={formMode}
                                        disabled={disabled}
                                    />
                                </FormItemAdapter>

                                <div className={styles.icon}>
                                    {!!index &&
                                        !disabled &&
                                        !isFormModeView(formMode) && (
                                            <IconsAdapter
                                                iconType="CloseOutlined"
                                                onClick={() => remove(index)}
                                            />
                                        )}
                                </div>
                            </div>
                        ))}

                        <ButtonsToolbar
                            className={styles.toolbar}
                            bottomIndent="0"
                        >
                            <AddButton
                                buttonText={LOCAL.ACTIONS.ADD_VARIANT}
                                onClick={() => add('')}
                                disabled={disabled}
                                formMode={formMode}
                            />
                        </ButtonsToolbar>
                    </>
                )}
            </Form.List>
        )
    }
)
