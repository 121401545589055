import React from 'react'
import { HasPermissions } from 'core/permissions'
import { HasPermissionsProps } from 'core/permissions/HasPermissions/HasPermissions.types'

export interface WithPermissionProps extends Partial<HasPermissionsProps> {}

export function withPermission<P extends WithPermissionProps>(
    WrappedComponent: React.FC<P>
) {
    return (props: P) => {
        const { accessDeniedRender, requiredPermissions } = props

        return (
            <>
                {requiredPermissions ? (
                    <HasPermissions
                        requiredPermissions={requiredPermissions}
                        accessDeniedRender={accessDeniedRender}
                    >
                        <WrappedComponent {...props} />
                    </HasPermissions>
                ) : (
                    <WrappedComponent {...props} />
                )}
            </>
        )
    }
}
