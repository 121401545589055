import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { ElementWithListPopover } from 'components/shared'
import { LOCAL } from 'core/local'
import { TrackSearchResultContract } from 'core/api'
import { defaultSorter } from 'components/layouts'
import {
    extractNames,
    formatDate,
    renderDocumentStatus,
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { TracksDevelopmentActions } from './components'

/**
 * настройка отображения колонок для реестра треков
 */
export const DEVELOPMENT_TRACKS_TABLE_COLUMNS: ColumnsType<TrackSearchResultContract> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.TRACK_TITLE,
        dataIndex: 'title',
        key: 'title',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizations',
        key: 'organizations',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.STAGES_COUNT,
        dataIndex: 'stageCount',
        key: 'stageCount',
        render: (stageCount, record) => (
            <ElementWithListPopover
                title={stageCount}
                items={extractNames(record.stages)}
                options={{
                    showPopoverAlways: true,
                }}
            />
        ),
    },
    {
        title: LOCAL.LABELS.VERSION,
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderDocumentStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CREATION,
        dataIndex: 'creationDate',
        key: 'creationDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => <TracksDevelopmentActions tableRow={tableRow} />,
        align: 'right',
        fixed: 'right',
    },
]
