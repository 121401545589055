import React, { useCallback, useEffect } from 'react'
import { CertificatesService } from 'core/api'
import { Col, Form, Row } from 'antd'
import { ComponentCommonProps } from 'App.types'
import {
    DatePickerControl,
    FormItemAdapter,
    InputControl,
} from 'components/controls'
import { EXTERNAL_CERTIFICATES_FORM_ID } from 'components/pages/Certificates/Certificates.consts'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ROW_GUTTER } from 'consts'
import { WithBlockUserRoutingComponentProps, WithLoaderProps } from 'HOCs'
import {
    createConfirmPopup,
    disableDate,
    getControlClass,
    getFormPopupTitle,
    idToUrlParam,
    isFormModeView,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'

import {
    ExternalCertificatesFormProps,
    ExternalCertificatesSubmitValues,
} from './ExternalCertificatesForm.types'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
} from './ExternalCertificatesForm.utils'

export const ExternalCertificatesForm: React.FC<
    ComponentCommonProps &
        WithBlockUserRoutingComponentProps &
        ExternalCertificatesFormProps &
        WithLoaderProps
> = React.memo(
    ({
        initialValuesForEdit,
        formMode,
        isSaveDocument,
        onBlockUserRouting,
        reFetchInitialFormValues,
        userId,
        updateLoader,
    }) => {
        const [form] = Form.useForm()

        const history = useHistory()

        const handleFinish = useCallback(
            async (values: ExternalCertificatesSubmitValues) => {
                try {
                    updateLoader(true)

                    const body = mapFormDataToRequest({
                        values,
                        userId,
                    })

                    if (initialValuesForEdit?.id) {
                        await CertificatesService.update({
                            body,
                            id: initialValuesForEdit.id,
                        })
                    } else {
                        await CertificatesService.create({
                            body,
                        })
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(
                                ROUTE_NAMES.EXTERNAL_CERTIFICATES,
                                'pageTitle'
                            )
                        ),
                        content: isSaveDocument
                            ? LOCAL.MESSAGES
                                  .EXTERNAL_CERTIFICATE_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES
                                  .EXTERNAL_CERTIFICATE_PUBLISH_SUCCESS_MESSAGE,
                        onOk: () => {
                            history.push(
                                `${
                                    ROUTE_NAMES.EXTERNAL_CERTIFICATES
                                }${idToUrlParam(userId)}`
                            )
                        },
                        onCancel: () => {
                            if (!initialValuesForEdit?.id) {
                                form.resetFields()
                            } else
                                reFetchInitialFormValues?.(
                                    initialValuesForEdit?.id
                                )
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [
                updateLoader,
                userId,
                initialValuesForEdit,
                onBlockUserRouting,
                isSaveDocument,
                history,
                reFetchInitialFormValues,
                form,
            ]
        )

        useEffect(() => {
            if (initialValuesForEdit?.id) {
                form.setFieldsValue(mapResponseToFormData(initialValuesForEdit))
            }
        }, [initialValuesForEdit, form])

        return (
            <Form
                id={EXTERNAL_CERTIFICATES_FORM_ID}
                onFinish={handleFinish}
                form={form}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={12}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.CERTIFICATE_NAME}
                        >
                            <InputControl
                                disabled={isFormModeView(formMode)}
                                className={getControlClass({
                                    formMode,
                                })}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={12}>
                        <FormItemAdapter
                            fieldName="number"
                            label={LOCAL.LABELS.CERTIFICATE_NUMBER}
                        >
                            <InputControl
                                disabled={isFormModeView(formMode)}
                                className={getControlClass({
                                    formMode,
                                })}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={12}>
                        <FormItemAdapter
                            fieldName="issueDate"
                            label={LOCAL.LABELS.CERTIFICATE_DATE}
                        >
                            <DatePickerControl
                                disabled={isFormModeView(formMode)}
                                className={getControlClass({
                                    formMode,
                                })}
                                disabledDate={disableDate({ isMore: true })}
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </Form>
        )
    }
)
