import React, { useCallback } from 'react'
import {
    AdaptationTracksService,
    AppError,
    DevelopmentTracksService,
    TrackType,
} from 'core/api'
import { Col } from 'antd'
import {
    FormItemAdapter,
    ShouldUpdateChecker,
    UploadDraggerImageControl,
    getLastFileFromList,
} from 'components/controls'
import { RcCustomRequestOptions } from 'types'
import { TooltipAdapter } from 'components/shared'
import {
    getStringWithoutDots,
    isTrackTypeAdaptation,
    validateUploadImage,
} from 'utils'

import { ACCEPT_FORMATS, VALIDATE_FILE_FORMATS } from './TrackBackground.consts'

export const TrackBackground: React.FC = React.memo(() => {
    /**
     * Отправка файла на сервер
     * @param request - тело запроса
     */
    const uploadImage = useCallback(
        (trackType: TrackType) => async (request: RcCustomRequestOptions) => {
            try {
                const uploadRequest = isTrackTypeAdaptation(trackType)
                    ? AdaptationTracksService.upload
                    : DevelopmentTracksService.upload
                const result = await uploadRequest({
                    file: request.file,
                })

                request.onSuccess?.(result, {} as XMLHttpRequest)
            } catch (error) {
                request.onError?.(error as AppError)
            }
        },
        []
    )

    return (
        <Col xs={6}>
            <ShouldUpdateChecker fieldPath={['trackType']}>
                {({ getFieldValue }) => (
                    <TooltipAdapter
                        title={getStringWithoutDots(ACCEPT_FORMATS)}
                    >
                        <FormItemAdapter
                            fieldName="trackBackgroundPictureId"
                            valuePropName="fileList"
                            getValueFromEvent={getLastFileFromList}
                        >
                            <UploadDraggerImageControl
                                accept={ACCEPT_FORMATS}
                                customRequest={uploadImage(
                                    getFieldValue('trackType')
                                )}
                                beforeUpload={(file, fileList) =>
                                    validateUploadImage(
                                        file,
                                        fileList,
                                        VALIDATE_FILE_FORMATS
                                    )
                                }
                            />
                        </FormItemAdapter>
                    </TooltipAdapter>
                )}
            </ShouldUpdateChecker>
        </Col>
    )
})
