import React from 'react'
import { AddonAfterDefault } from 'components/shared'
import {
    CourseForModalContract,
    CourseSearchContract,
    CoursesService,
} from 'core/api'
import {
    FormItemAdapter,
    SearchSelectControl,
    ShouldUpdateChecker,
    TRAINING_MATERIALS_TABLE_COLUMNS,
    TrainingMaterialsFilters,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TRAINING_MATERIALS_PERMISSIONS } from 'consts'

import { CourseFieldsProps } from './CourseFields.types'

export const CourseFields: React.FC<CourseFieldsProps> = React.memo(
    ({ formMode, field, disabled }) => (
        <ShouldUpdateChecker fieldPath={[['lessons', field.name, 'course']]}>
            {({ getFieldValue }) => {
                const courseId = getFieldValue([
                    'lessons',
                    field.name,
                    'course',
                ])?.[0]?.value

                return (
                    <FormItemAdapter
                        label={LOCAL.LABELS.EDUCATION_MATERIALS}
                        fieldName={[field.name, 'course']}
                        required
                    >
                        <SearchSelectControl<
                            CourseSearchContract,
                            CourseForModalContract
                        >
                            tableSearchOptions={{
                                searchMethod: CoursesService.getForModal,
                                filterComponent: TrainingMaterialsFilters,
                                tableColumns: TRAINING_MATERIALS_TABLE_COLUMNS,
                            }}
                            addonAfter={
                                <AddonAfterDefault
                                    url={`${ROUTE_NAMES.TRAINING_MATERIALS}/${courseId}`}
                                    disabled={!courseId}
                                    permissionsRequired={
                                        TRAINING_MATERIALS_PERMISSIONS
                                    }
                                />
                            }
                            getByIdsMethod={CoursesService.getByIds}
                            allowClearSelect
                            formMode={formMode}
                            modalTitle={
                                LOCAL.LABELS.EDUCATION_MATERIALS_SELECTION
                            }
                            disabled={disabled}
                        />
                    </FormItemAdapter>
                )
            }}
        </ShouldUpdateChecker>
    )
)
