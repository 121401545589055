import React from 'react'
import { Col, Row } from 'antd'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { ROW_GUTTER } from 'consts'

import { ConditionParamsFormProps } from './ConditionParamsControl.types'
import {
    getParameterFieldWidth,
    renderParamControl,
} from './ConditionParamsControl.utils'

/** Компонент для работы с параметрами условия отправки на форме "Задание на рассылку" */
export const ConditionParamsControl: React.FC<ConditionParamsFormProps> = React.memo(
    (props) => {
        const { formMode, value: parameters, fieldName } = props

        return (
            <Row wrap gutter={ROW_GUTTER}>
                {parameters?.map((parameter, index, array) => (
                    <Col
                        key={parameter.id}
                        xs={getParameterFieldWidth(index, array.length)}
                    >
                        <FormItemAdapter
                            label={parameter.name}
                            fieldName={[
                                fieldName,
                                'parameters',
                                index,
                                'value',
                            ]}
                        >
                            {renderParamControl(parameter, formMode)}
                        </FormItemAdapter>
                    </Col>
                ))}
            </Row>
        )
    }
)
