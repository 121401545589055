import React from 'react'
import { AddButton, CloseButton } from 'components/shared'
import { Form } from 'antd'
import { FormItemAdapter, TextAreaControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import styles from './ProductContent.module.scss'
import { MapProductContentSectionsProps } from './ProductContent.types'

/** Маппинг подразделов раздела */
export const mapProductContentSubsections = ({
    fields,
    formMode,
    remove,
}: MapProductContentSectionsProps) =>
    fields.map((field) => (
        <div className={styles.subsection} key={field.key}>
            <div className={styles.subsectionControl}>
                <FormItemAdapter
                    {...field}
                    fieldName={[field.name, 'name']}
                    label={LOCAL.LABELS.SUBSECTION}
                >
                    <TextAreaControl autoSize formMode={formMode} />
                </FormItemAdapter>
            </div>

            {!isFormModeView(formMode) && (
                <CloseButton
                    onClose={() => remove?.(field.name)}
                    className={styles.close}
                    formMode={formMode}
                />
            )}
        </div>
    ))

/** Маппинг разделов блока содержания продукта */
export const mapProductContentSections = ({
    fields,
    remove,
    formMode,
}: MapProductContentSectionsProps) =>
    fields.map((field) => (
        <div key={field.key}>
            <div className={styles.section}>
                <FormItemAdapter
                    {...field}
                    fieldName={[field.name, 'name']}
                    label={LOCAL.LABELS.SECTION}
                >
                    <TextAreaControl autoSize formMode={formMode} />
                </FormItemAdapter>

                {!isFormModeView(formMode) && (
                    <CloseButton
                        onClose={() => remove?.(field.name)}
                        className={styles.close}
                        formMode={formMode}
                    />
                )}
            </div>

            <Form.List name={[field.name, 'subSections']}>
                {(subsections, { add, remove }) => (
                    <div>
                        <div className={styles.subsections}>
                            {mapProductContentSubsections({
                                fields: subsections,
                                remove,
                                formMode,
                            })}
                        </div>

                        <AddButton
                            className={styles.addSubsection}
                            buttonText={LOCAL.ACTIONS.ADD_SUBSECTION}
                            onClick={() => add()}
                            formMode={formMode}
                        />
                    </div>
                )}
            </Form.List>
        </div>
    ))
