import unionWith from 'lodash/unionWith'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Resolutions } from 'core/configs'
import { Tabs } from 'antd'
import { cloneDeepViaJson, createConfirmDeletePopup } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './ResponsiveTabs.module.scss'
import { FIELD_RESPONSIVE_NAME } from '../ResponsiveModal/ResponsiveModal.config'
import { INITIAL_TABS, RESOLUTION_INITIAL_TAB } from './ResponsiveTabs.config'
import {
    PaneActionType,
    PaneStateProps,
    ResponsiveTabsProps,
} from './ResponsiveTabs.types'
import { ResponsiveModal } from '../ResponsiveModal'
import {
    composeTabOptions,
    updateTabsDependingOnExternalCanvases,
} from './ResponsiveTabs.utils'
import { synchronizeStagesAndCanvases } from '../../../TrackForm.utils'

export const ResponsiveTabs: React.FC<ResponsiveTabsProps> = React.memo(
    ({ form, externalCanvases }) => {
        const {
            elementVisible,
            handleHideElement,
            handleShowElement,
        } = useElementVisible()

        const [state, setState] = useState<PaneStateProps>({
            activeKey: RESOLUTION_INITIAL_TAB.key,
            tabs: INITIAL_TABS,
        })

        const handleSelectResolution = useCallback(
            (keys?: string[]) => {
                if (!keys?.length) {
                    handleHideElement()

                    return
                }

                const newTabs = keys?.map(composeTabOptions)

                setState((prev) => {
                    const tabs = unionWith(
                        prev.tabs,
                        newTabs,
                        (first, second) => first.key === second.key
                    )

                    tabs.sort(
                        (prev, next) => Number(prev.key) - Number(next.key)
                    )

                    return {
                        tabs,
                        activeKey: String(tabs[tabs.length - 1].key),
                    }
                })

                handleHideElement()
            },
            [handleHideElement]
        )

        const addTab = useCallback(() => {
            handleShowElement()
        }, [handleShowElement])

        const removeTab = useCallback(
            (targetKey: React.MouseEvent | React.KeyboardEvent | string) => {
                createConfirmDeletePopup({
                    onOk: () => {
                        setState((prev) => {
                            const { tabs, activeKey } = prev

                            let newActiveKey = activeKey
                            let lastIndex

                            tabs?.forEach((tab, index) => {
                                if (tab.key === targetKey) {
                                    lastIndex = index - 1
                                }
                            })

                            const newPanes = tabs?.filter(
                                (tab) => tab.key !== targetKey
                            )

                            if (
                                newPanes?.length &&
                                newActiveKey === targetKey
                            ) {
                                if (lastIndex !== undefined && lastIndex >= 0) {
                                    newActiveKey = newPanes[lastIndex].key
                                } else {
                                    newActiveKey = newPanes[0].key
                                }
                            }

                            return {
                                tabs: newPanes,
                                activeKey: newActiveKey,
                            }
                        })

                        const trackCanvasesCopy = cloneDeepViaJson(
                            form?.getFieldValue('trackCanvases')
                        )

                        delete trackCanvasesCopy[targetKey as string]
                        form?.setFields([
                            {
                                name: 'trackCanvases',
                                value: trackCanvasesCopy,
                            },
                        ])
                    },
                })
            },
            [form]
        )

        const onChange = useCallback((activeKey: string) => {
            setState((prev) => ({ ...prev, activeKey }))
        }, [])

        const onEdit = useCallback(
            (
                targetKey: React.MouseEvent | React.KeyboardEvent | string,
                action: PaneActionType
            ) => {
                switch (action) {
                    case 'add':
                        addTab()

                        break
                    case 'remove':
                        removeTab(targetKey)

                        break
                }
            },
            [addTab, removeTab]
        )

        const initialValues = useMemo(
            () => ({
                [FIELD_RESPONSIVE_NAME]: state.tabs?.map((el) => el.key),
            }),
            [state.tabs]
        )

        useEffect(() => {
            const tabs = updateTabsDependingOnExternalCanvases(externalCanvases)

            setState((prev) => ({
                ...prev,
                tabs,
            }))
        }, [externalCanvases])

        useEffect(() => {
            synchronizeStagesAndCanvases(form)
        }, [state.tabs, form])

        return (
            <div className={styles.wrapper}>
                <Tabs
                    type="editable-card"
                    onChange={onChange}
                    activeKey={state.activeKey}
                    onEdit={onEdit}
                >
                    {state.tabs?.map(({ content: Component, ...tab }) => (
                        <Tabs.TabPane
                            tab={tab.title}
                            key={tab.key}
                            closable={tab.closable}
                            forceRender
                        >
                            <Component
                                canvasKey={tab.key as Resolutions}
                                currentCanvasKey={state.activeKey}
                                form={form}
                            />
                        </Tabs.TabPane>
                    ))}
                </Tabs>

                <ResponsiveModal
                    isVisible={elementVisible}
                    onCancel={handleHideElement}
                    onSelectResolution={handleSelectResolution}
                    initialValues={initialValues}
                />
            </div>
        )
    }
)
