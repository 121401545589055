import React, { FC, useCallback, useEffect, useState } from 'react'
import {
    ActivityPassingLessonAttachmentContract,
    ActivityPassingService,
} from 'core/api'
import { IconsAdapter } from 'components/shared'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { downloadFileViaBlob } from 'utils'
import { withLoader } from 'HOCs'

import styles from './DocumentUploadForm.module.scss'

/**
 * Форма загрузки документов
 */
export const DocumentUploadForm: FC<PopupAdapterFormProps> = withLoader(
    React.memo(({ initialValues, updateLoader }) => {
        const [attachments, setAttachments] = useState<
            ActivityPassingLessonAttachmentContract[]
        >([])

        useEffect(() => {
            const dictionaryFetch = async () => {
                try {
                    updateLoader(true)

                    setAttachments(
                        await ActivityPassingService.getAttachments({
                            lessonId: initialValues?.lessonId,
                        })
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }

            dictionaryFetch()
        }, [initialValues, updateLoader])

        const downloadDocument = useCallback(
            ({
                id,
                name,
                contentType,
            }: ActivityPassingLessonAttachmentContract) => async () => {
                try {
                    const data = await ActivityPassingService.downloadAttachment(
                        { attachmentId: id },
                        {
                            responseType: 'arraybuffer',
                        }
                    )

                    downloadFileViaBlob([data], contentType, name)
                } catch (error) {
                    console.error(error)
                }
            },
            []
        )

        return (
            <>
                {attachments?.map((attachment) => (
                    <div className={styles.wrapper} key={attachment.id}>
                        <p>{attachment.name}</p>

                        <IconsAdapter
                            iconType="VerticalAlignBottomOutlined"
                            onClick={downloadDocument(attachment)}
                        />
                    </div>
                ))}
            </>
        )
    })
)
