import React from 'react'
import cn from 'classnames'
import moment from 'moment'
import { APPOINTMENT_STATUS, QUESTIONNAIRE_TYPES, QUIZ_TYPES } from 'consts'
import { AppointmentUserSearchResultContract, QuizType } from 'core/api'
import {
    InclusivityOptions,
    formatPeriod,
    isAppointmentStatusEnded,
    isDateInBetween,
    isFirstDateEarlier,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeQuiz,
} from 'utils'
import { LOCAL } from 'core/local'
import { Typography } from 'antd'
import { useDictionariesHelper } from 'hooks'

import styles from './AppointmentCard.module.scss'
import { AppointmentCardResponsiblePeople } from './AppointmentCardResponsiblePeople'
import { AppointmentCardWrapper } from './AppointmentCardWrapper'
import { CloudTag } from '../CloudTag'
import { IconsAdapter } from '../IconsAdapter'

const { Paragraph } = Typography

/**
 * Карточка назначения опроса/теста/оценки
 */
export const AppointmentCard: React.FC<AppointmentUserSearchResultContract> = React.memo(
    ({
        id,
        name,
        type,
        quizType,
        assessmentType,
        evaluationPeriodFrom,
        evaluationPeriodTo,
        passingPercent,
        status,
        reviewers,
        candidate,
        owner,
        alarmDate,
    }) => {
        const { assessmentTypeDictionary } = useDictionariesHelper([
            'assessmentTypeDictionary',
        ])
        const ended = isAppointmentStatusEnded(status)
        const expired = !ended && isFirstDateEarlier(evaluationPeriodTo)
        const needToPass =
            !ended &&
            isDateInBetween(
                moment().startOf('d'),
                evaluationPeriodFrom,
                evaluationPeriodTo
            )
        const showAlarm =
            !ended &&
            isDateInBetween(
                moment(),
                alarmDate,
                evaluationPeriodFrom,
                InclusivityOptions.IncludedEnd
            )

        const formattedPercent = `${passingPercent}%`

        return (
            <AppointmentCardWrapper
                className={cn(
                    styles.wrapper,
                    !expired && !ended && styles.wrapperActive,
                    expired && styles.wrapperExpired,
                    ended && styles.wrapperEnded
                )}
                type={type}
                candidate={candidate}
                id={id}
                ended={ended}
                expired={expired}
                needToPass={needToPass}
            >
                <div>
                    <div className={styles.typeContainer}>
                        <span className={styles.type}>
                            {QUESTIONNAIRE_TYPES[type]}
                        </span>

                        {isQuestionnaireTypeQuiz(type) && (
                            <CloudTag className={styles.cloudTag}>
                                {quizType
                                    ? QUIZ_TYPES[quizType]
                                    : QUIZ_TYPES[QuizType.Feedback]}
                            </CloudTag>
                        )}

                        {isQuestionnaireTypeAssessment(type) &&
                            assessmentType && (
                                <CloudTag className={styles.cloudTag}>
                                    {assessmentTypeDictionary[assessmentType]}
                                </CloudTag>
                            )}

                        {showAlarm && (
                            <IconsAdapter
                                iconType="CustomIconAlarmClock"
                                className={styles.alarmIcon}
                            />
                        )}
                    </div>

                    <Paragraph
                        ellipsis={{ rows: 2, tooltip: name }}
                        className={styles.title}
                    >
                        {isQuestionnaireTypeAssessment(type)
                            ? candidate?.name
                            : name}
                    </Paragraph>

                    {isQuestionnaireTypeAssessment(type) && (
                        <Paragraph
                            ellipsis={{ rows: 2, tooltip: name }}
                            className={cn(styles.subTitle, styles.weakText)}
                        >
                            {name}
                        </Paragraph>
                    )}
                </div>

                <div className={styles.bottomPart}>
                    <div
                        className={cn(
                            styles.responsiblePeople,
                            styles.weakText
                        )}
                    >
                        <AppointmentCardResponsiblePeople
                            expired={expired}
                            owner={owner}
                            type={type}
                            reviewers={reviewers}
                        />
                    </div>

                    <div className={styles.progressLineContainer}>
                        <div
                            className={styles.progressLine}
                            style={{ width: formattedPercent }}
                        />
                    </div>

                    <div className={styles.underlineContent}>
                        <div className={cn(styles.period, styles.weakText)}>
                            {`${
                                expired
                                    ? LOCAL.LABELS.EXPIRED
                                    : APPOINTMENT_STATUS[status]
                            }: `}

                            {formatPeriod(
                                evaluationPeriodFrom,
                                evaluationPeriodTo
                            )}
                        </div>

                        <div className={styles.passingPercent}>
                            {formattedPercent}
                        </div>
                    </div>
                </div>
            </AppointmentCardWrapper>
        )
    }
)
