import isNil from 'lodash/isNil'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { ShouldUpdateChecker } from 'components/controls'
import { Table } from 'antd'

import styles from './PastTextAnswersTable.module.scss'
import {
    PastTextAnswersTableProps,
    PastTextAnswersTableValueProps,
} from './PastTextAnswersTable.types'
import { getPastTextAnswersTableColumns } from './PastTextAnswersTable.consts'

/**
 * Поля: Ответы по пропущенному тексту
 */
export const PastTextAnswersTable: FC<PastTextAnswersTableProps> = React.memo(
    ({ onChange, value, field, formState, disabled }) => {
        const [pastTextAnswersData, setPastTextAnswersData] = useState<
            PastTextAnswersTableValueProps[]
        >()

        /**
         * Обработчик изменения поля `score` в `answerRow`
         * @param id - идентификатор ряда `answerRow`
         * @param value - объект события
         */
        const handleScoreChange = useCallback(
            (id?: string) => (value?: number) => {
                if (isNil(value)) return

                const answers = pastTextAnswersData?.map((answerRow) => {
                    if (answerRow.id === id)
                        return {
                            ...answerRow,
                            score: value,
                        }

                    return answerRow
                })

                setPastTextAnswersData(answers)
                onChange?.(answers)
            },
            [onChange, pastTextAnswersData]
        )

        useEffect(() => {
            setPastTextAnswersData(value)
        }, [value])

        return (
            <ShouldUpdateChecker fieldPath="formMode">
                {({ getFieldValue }) => {
                    const formMode = getFieldValue('formMode')

                    return (
                        <Table
                            columns={getPastTextAnswersTableColumns({
                                onScoreChange: handleScoreChange,
                                maxPoints: formState?.maxPoints,
                                calcForAnswer:
                                    formState?.questions[field.name]
                                        ?.calcAndScore?.calcForAnswer,
                                pastTextAnswersData,
                                formMode,
                                disabled,
                                field,
                            })}
                            dataSource={value || pastTextAnswersData}
                            rowKey="id"
                            pagination={false}
                            className={styles.pastTextAnswersTable}
                        />
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
