import React from 'react'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { FormItemAdapter, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'

/**
 * Форма фильтрации слушателей
 */
export const ActivityAssignmentsProgressFilters: React.FC<FiltersProps<
    any
>> = React.memo((props) => (
    <FilterForm {...props}>
        <FormItemAdapter
            fieldName="name"
            label={LOCAL.LABELS.SEARCH_BY_FULL_NAME}
        >
            <InputControl />
        </FormItemAdapter>
    </FilterForm>
))
