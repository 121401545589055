import { FocusedElementProps, WithFormInstanceProps } from 'App.types'
import { LabeledValue } from 'antd/lib/select'

export const getTextWithMarker = (
    text: string,
    marker: string,
    cursorPosition: number
) =>
    `${text.slice(0, cursorPosition)}{${marker}}${text.slice(
        cursorPosition,
        text.length
    )}`

export const addMarker = (
    form: WithFormInstanceProps['form'],
    focusedField?: FocusedElementProps
) => (marker: LabeledValue) => {
    if (!focusedField) return

    const { name, currentTarget } = focusedField

    currentTarget.focus()

    const textWithMarker = getTextWithMarker(
        currentTarget.value,
        String(marker.label),
        currentTarget.selectionStart!
    )

    form?.setFieldsValue({
        [name]: textWithMarker,
    })
}
