import React from 'react'
import cn from 'classnames'

import styles from './HomeworkStatusTag.module.scss'
import { HomeworkStatusTagProps } from './HomeworkStatusTag.types'
import {
    getTutorStatusText,
    getUserStatusText,
} from './HomeworkStatusTag.utils'

export const HomeworkStatusTag: React.FC<HomeworkStatusTagProps> = React.memo(
    ({ currentStatus, type, isTasksTitlesVisible }) => (
        <div
            className={cn(
                styles.status,
                styles[`status${currentStatus}`],
                isTasksTitlesVisible && styles.statusWithTitle
            )}
        >
            {type === 'user'
                ? getUserStatusText(currentStatus)
                : getTutorStatusText(currentStatus)}
        </div>
    )
)
