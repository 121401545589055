import cn from 'classnames'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
    CompetenceGroupWithCompetenciesContract,
    CompetenceGroupsService,
    CompetenciesService,
    IndicatorsService,
    SetPositionArgsContract,
} from 'core/api'
import { ContentTitle, PageBanner, PageContent } from 'components/shared'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    cloneDeepViaJson,
    getBackLinkTitleFromRoute,
    getNumFromStr,
    reorderDnDRow,
} from 'utils'
import { withLoader } from 'HOCs'

import styles from './CompetenciesOrderOptions.module.scss'
import { ChildrenNodesWithSpoiler, Competencies } from './components'
import { getDndDestination } from './CompetenciesOrderOptions.utils'

/** Компонент настройки порядка отображения компетенций\групп компетенций */
export const CompetenciesOrderOptions: FC = withLoader(
    React.memo(({ updateLoader }) => {
        const [competenciesData, setCompetenciesData] = useState<
            CompetenceGroupWithCompetenciesContract[]
        >()

        /**
         * Поменять порядок группы компетенций\компетенции
         */
        const moveEntityOrder = useCallback(
            async (result: DropResult, position: SetPositionArgsContract) => {
                try {
                    const type = result.type
                    const request = { body: position }

                    if (type.includes('group')) {
                        await CompetenceGroupsService.move(request)
                    } else if (type.includes('competence')) {
                        await CompetenciesService.move(request)
                    } else {
                        if (!result.destination?.droppableId) return

                        const competenceId = getNumFromStr(
                            result.destination?.droppableId
                        )

                        await IndicatorsService.move({
                            ...request,
                            body: { ...request.body, parentId: competenceId },
                        })
                    }
                } catch (error) {
                    console.error(error)
                }
            },
            []
        )

        const reorderDnDRowByRules = useCallback(
            (result: DropResult) => {
                const competenciesGroups = cloneDeepViaJson(competenciesData)

                if (result.type.includes('group')) {
                    return reorderDnDRow(
                        result.source.index,
                        result.destination?.index,
                        competenciesData
                    )
                }

                if (result.type.includes('competence')) {
                    const groupIndex = getNumFromStr(result.type)

                    const competencies = reorderDnDRow(
                        result.source.index,
                        result.destination?.index,
                        competenciesData?.[groupIndex].competencies
                    )

                    competenciesGroups[groupIndex].competencies = competencies

                    return competenciesGroups
                }

                const [groupIndex, competenceIndex] = result.type.split('-')

                const indicators = reorderDnDRow(
                    result.source.index,
                    result.destination?.index,
                    competenciesData?.[+groupIndex].competencies[
                        +competenceIndex
                    ].indicators
                )

                competenciesGroups[+groupIndex].competencies[
                    +competenceIndex
                ].indicators = indicators

                return competenciesGroups
            },
            [competenciesData]
        )

        const handleDragEnd = useCallback(
            (result: DropResult) => {
                if (!result.destination) {
                    return
                }

                const draggableId = getNumFromStr(result.draggableId)
                const destination = getDndDestination(result, competenciesData)

                if (draggableId === destination?.id) return

                moveEntityOrder(result, {
                    moveUnderId: destination?.id || 0,
                    toMoveId: draggableId,
                } as SetPositionArgsContract)

                setCompetenciesData(reorderDnDRowByRules(result))
            },
            [competenciesData, moveEntityOrder, reorderDnDRowByRules]
        )

        const competenciesGroups = useMemo(
            () =>
                competenciesData?.map((group, index) => {
                    const key = `group-${group.id}`

                    return (
                        <ChildrenNodesWithSpoiler
                            key={key}
                            dndKey={key}
                            draggableId={key}
                            index={index}
                            name={`${group.name}`}
                        >
                            {!!group.competencies?.length && (
                                <Competencies
                                    groupIndex={index}
                                    groupData={group}
                                    className={styles.competencies}
                                />
                            )}
                        </ChildrenNodesWithSpoiler>
                    )
                }),
            [competenciesData]
        )

        /**
         * Запрос данных справочника
         */
        useEffect(() => {
            const dictionaryFetch = async () => {
                try {
                    updateLoader(true)

                    setCompetenciesData(
                        await CompetenceGroupsService.getAllHierarchy()
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }

            dictionaryFetch()
        }, [updateLoader])

        return (
            <PageContent>
                <PageBanner />

                <ContentTitle
                    backLink={{
                        path: ROUTE_NAMES.COMPETENCIES,
                        text: getBackLinkTitleFromRoute(
                            ROUTE_NAMES.COMPETENCIES
                        ),
                    }}
                    title={LOCAL.LABELS.COMPETENCIES_ORDER_SETTINGS}
                />

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable" type="group">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                className={cn(
                                    styles.groups,
                                    snapshot.isDraggingOver && 'dragging-over'
                                )}
                            >
                                {competenciesGroups}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </PageContent>
        )
    })
)
