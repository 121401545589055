import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { CoursometerService } from 'core/api'
import { CustomTrackType } from 'components/pages/Track/Track.types'
import { HiddenField, ShouldUpdateChecker } from 'components/controls'
import { LOCAL } from 'core/local'
import { WithFormModeProps } from 'App.types'
import {
    convertToShouldUpdateDep,
    isDocumentStatusReadyForWork,
    isFormModeView,
} from 'utils'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { useElementVisible } from 'hooks'

import { COURSOMETER_DEPENDENCIES } from './CoursometerRegistration.consts'
import { COURSOMETER_EVENT } from './CoursometerRegistration.events'

export const CoursometerRegistration: React.FC<WithFormModeProps> = React.memo(
    ({ formMode }) => {
        const {
            updateElementVisible: updateLoader,
            elementVisible: isLoading,
        } = useElementVisible()

        const registerInCoursometer = useCallback(
            (trackId: number, trackType: CustomTrackType) => async () => {
                try {
                    updateLoader(true)

                    const payload = { id: trackId }

                    switch (trackType) {
                        case 'Adaptation':
                            await CoursometerService.registerAdaptationTrack(
                                payload
                            )

                            break
                        case 'Development':
                            await CoursometerService.registerDevelopmentTrack(
                                payload
                            )

                            break
                    }

                    eventEmitter.emit(COURSOMETER_EVENT)
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader]
        )

        return (
            <>
                <HiddenField fieldName="coursometerStructureRegistered" />

                <ShouldUpdateChecker
                    fieldPath={convertToShouldUpdateDep(
                        COURSOMETER_DEPENDENCIES
                    )}
                >
                    {({ getFieldsValue }) => {
                        const {
                            coursometerStructureRegistered,
                            status,
                            trackType,
                            id,
                        } = getFieldsValue(COURSOMETER_DEPENDENCIES)

                        const isDisabled =
                            !isDocumentStatusReadyForWork(status) ||
                            (isDocumentStatusReadyForWork(status) &&
                                coursometerStructureRegistered) ||
                            isLoading ||
                            isFormModeView(formMode)

                        return (
                            <Button
                                disabled={isDisabled}
                                htmlType="button"
                                onClick={registerInCoursometer(id, trackType)}
                            >
                                {LOCAL.ACTIONS.REGISTER_IN_COURSOMETER}
                            </Button>
                        )
                    }}
                </ShouldUpdateChecker>
            </>
        )
    }
)
