import React from 'react'
import { FormItemAdapter, TextAreaControl } from 'components/controls'
import { isHomeworkOnChecking, isHomeworkPassed } from 'utils'

import { AnswerFieldProps } from './AnswerField.types'

export const AnswerField: React.FC<AnswerFieldProps> = React.memo(
    ({ fieldName, currentStatus }) => {
        const disabledAnswerField =
            isHomeworkOnChecking(currentStatus) ||
            isHomeworkPassed(currentStatus)

        return (
            <FormItemAdapter fieldName={[fieldName, 'answerText']}>
                <TextAreaControl
                    autoSize={{ minRows: 2 }}
                    disabled={disabledAnswerField}
                />
            </FormItemAdapter>
        )
    }
)
