import { StructuralUnitContract } from 'core/api'
import { isStructuralUnitComplex, normalizeDataForTreeSelect } from 'utils'

/** Нормалайз данных дерева организаций без возможности выбора комплексов */
export const normalizeDataForOrganizationsTreeSelect = (
    unit: StructuralUnitContract
) => ({
    ...normalizeDataForTreeSelect(unit),
    selectable: !isStructuralUnitComplex(unit.type),
})
