import React, { useCallback, useEffect } from 'react'
import { APPOINTMENT_STATUS_WITHOUT_CHECKED_MAPPED, ROW_GUTTER } from 'consts'
import {
    AppointmentSearchContract,
    CompetenceForModalContract,
    CompetenciesSearchContract,
    CompetenciesService,
    StructuralUnitsService,
} from 'core/api'
import {
    COMPETENCIES_TABLE_COLUMNS,
    CompetenciesFilters,
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SearchSelectControl,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import { Col, Row } from 'antd'
import { DictionariesCommonProps } from 'App.types'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps, getValue } from 'utils'
import { LOCAL } from 'core/local'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionaries } from 'hooks'

import { AssignmentsFiltersSubmitValues } from './TestAssignmentsFilters.types'
import { TEST_FILTERS_INITIAL_VALUES } from './TestAssignmentsFilters.consts'

/**
 * Форма фильтрации назначений
 */
export const AssignmentsFilters: React.FC<FiltersProps<
    AppointmentSearchContract
>> = React.memo((props) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<'structuralUnits'>
    >()

    const mapFormDataToRequest = useCallback(
        (values: Partial<AssignmentsFiltersSubmitValues>) => ({
            ...values,
            appointmentTestSearch: {
                competenceIds: values.competenceIds?.map(getValue),
            },
        }),
        []
    )

    useEffect(() => {
        handleFetchDictionaries({
            structuralUnits: [
                StructuralUnitsService.getAllStructuralUnits(),
                'treeSelectForStructuralUnit',
            ],
        })
    }, [handleFetchDictionaries])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                mapFormDataToRequest={mapFormDataToRequest}
                tableFiltersOptions={{
                    initialValues: TEST_FILTERS_INITIAL_VALUES,
                }}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.APPOINTMENT_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitIds"
                            label={LOCAL.LABELS.ORGANIZATIONS}
                        >
                            <TreeSelectControl
                                treeData={dictionaries.structuralUnits || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="competenceIds"
                            label={LOCAL.LABELS.ASSESSED_COMPETENCIES}
                        >
                            <SearchSelectControl<
                                CompetenciesSearchContract,
                                CompetenceForModalContract
                            >
                                getByIdsMethod={CompetenciesService.getByIds}
                                selectMode="multiple"
                                tableSearchOptions={{
                                    tableColumns: COMPETENCIES_TABLE_COLUMNS,
                                    filterComponent: CompetenciesFilters,
                                    searchMethod:
                                        CompetenciesService.getForModal,
                                }}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    APPOINTMENT_STATUS_WITHOUT_CHECKED_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_START,
                                fieldName: 'evaluationPeriodFrom',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_END,
                                fieldName: 'evaluationPeriodTo',
                            }}
                        />
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changeDateFrom',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changeDateTo',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
