import { LOCAL } from 'core/local'
import { PassingType } from 'core/api'

import { MapFormDataToRequestProps, StageOptionsProps } from './TrackForm.types'

/** Начальные значения для формы при условии отсутствия значений полученных с сервера */
export const INITIAL_FORM_VALUES_DEFAULT = ({
    passingType: PassingType.Consistently,
    stages: [] as StageOptionsProps,
    useInformationAssistant: false,
    informationAssistant: {
        events: [undefined],
    },
    isTrackWithVisualisation: true,
} as unknown) as MapFormDataToRequestProps

/**
 * Опции визуализации трека, эффекты иконок: запускать эффект
 */
export const trackStageOptionsStart = [
    {
        label: LOCAL.LABELS.ON_OPENING,
        value: 'onOpening',
    },
    {
        label: LOCAL.LABELS.ON_MOUSE_OVER,
        value: 'onMouseOver',
    },
]

/**
 * Опции визуализации трека, эффекты иконок: скорость
 */
export const trackStageOptionsDuration = [
    {
        label: LOCAL.SLOW,
        value: 'slow',
    },
    {
        label: LOCAL.MIDDLE,
        value: 'middle',
    },
    {
        label: LOCAL.FAST,
        value: 'fast',
    },
]

/**
 * Опции визуализации трека, эффекты иконок: повтор
 */
export const trackStageOptionsRepeat = [
    {
        label: LOCAL.YES,
        value: true,
    },
    {
        label: LOCAL.NO,
        value: false,
    },
]

export const USE_INFORMATION_ASSISTANT_FIELD_NAME = 'useInformationAssistant'
