import moment from 'moment'
import { UserStructuralUnitPositionForUserProfileContract } from 'core/api'

/**
 * Формируем строку с информацией о текущем подразделении и организации пользователя
 * @param structuralUnit - название подразделения
 * @param organization - название организации
 * @return текущее подразделение и организация пользователя
 */
export const getStructuralUnitAndOrganizationText = (
    structuralUnit: string = '',
    organization: string = ''
): string => {
    const separatorString = structuralUnit && organization && ', '

    return `${structuralUnit}${separatorString}${organization}`
}

export const getLastWorkPlace = (
    structuralUnitPoints?: UserStructuralUnitPositionForUserProfileContract[]
) =>
    structuralUnitPoints
        ?.filter((el) => !el.fireDate)
        .sort(
            (
                first: UserStructuralUnitPositionForUserProfileContract,
                second: UserStructuralUnitPositionForUserProfileContract
            ) => moment(second.hireDate).diff(moment(first.hireDate))
        )[0]
