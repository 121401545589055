import React, { useCallback, useEffect, useState } from 'react'
import {
    FeedbackQuizReportTitleContract,
    FeedbackQuizReportsService,
} from 'core/api'
import { PageBanner, PageContent, PageTabs, Title } from 'components/shared'
import { Store } from 'antd/lib/form/interface'
import { UrlParamsForEditProps } from 'hooks'
import { useLocation, useParams } from 'react-router-dom'

import { Charts, QuizeReportFreeAnswer, SpentTime } from './components'
import { QUIZ_REPORT_TABS } from './QuizReport.consts'
import { QuizReportType } from './QuizReport.types'
import { getCommonStatisticsInfo, getPageTitle } from './QuizReport.utils'

/** Отчет по опросу типа "Обратная связь" */
export const QuizReport: React.FC = React.memo(() => {
    const { id: appointmentId } = useParams<UrlParamsForEditProps>()
    const location = useLocation()
    const urlParams = useParams<Store>()
    const [commonStatistics, setCommonStatistics] = useState<
        FeedbackQuizReportTitleContract
    >()
    const [currentTab, setCurrentTab] = useState<QuizReportType>('charts')

    /**
     * Запрос данных отчета
     */
    const reportDataFetch = useCallback(async (appointmentId) => {
        try {
            setCommonStatistics(
                await FeedbackQuizReportsService.getReportHeader({
                    appointmentId,
                })
            )
        } catch (error) {
            console.error(error)
        }
    }, [])

    /**
     * Выводим компонент, в зависимости от выбранной вкладки
     */
    const getContentForTab = () => {
        switch (currentTab) {
            case 'charts':
                return <Charts appointmentId={Number(appointmentId)} />
            case 'freeAnswers':
                return <QuizeReportFreeAnswer />
            case 'spentTime':
                return <SpentTime appointmentId={Number(appointmentId)} />
        }
    }

    /**
     * Обработчик выбора текущего таба
     * @param currentTabKey - ключ выбранного таба
     */
    const handleChangeTab = useCallback((currentTabKey: string) => {
        setCurrentTab(currentTabKey as QuizReportType)
    }, [])

    /**
     * Заголовок страницы
     */
    const pageTitle = getPageTitle(currentTab, urlParams, location.pathname)

    useEffect(() => {
        if (appointmentId) reportDataFetch(Number(appointmentId))
    }, [reportDataFetch, appointmentId])

    return (
        <PageContent>
            {appointmentId && (
                <>
                    <PageBanner>
                        <PageTabs
                            tabsData={QUIZ_REPORT_TABS}
                            onChange={handleChangeTab}
                            defaultTabKey="charts"
                        />
                    </PageBanner>

                    <div className="pt-20 pb-20">
                        <Title type="h3" text={pageTitle} bottomIndent="10" />

                        {getCommonStatisticsInfo(commonStatistics)}
                    </div>

                    {getContentForTab()}
                </>
            )}
        </PageContent>
    )
})
