import cn from 'classnames'
import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { ElementWithListPopover } from 'components/shared'
import { LOCAL } from 'core/local'

import styles from './ButtonsListControl.module.scss'
import { ButtonsListControlProps } from './ButtonsListControl.types'
import { useButtonsControl } from '../ButtonsControl/ButtonsControl.hook'

/** Кнопки для прохождения тестов блоками */
export const ButtonsListControl: React.FC<ButtonsListControlProps> = React.memo(
    ({
        selectedListIndex,
        isLastQuestionsList,
        passingData,
        setSelectedListIndex,
        isQuestionsListCompleted,
        ...restProps
    }) => {
        const {
            handleConfirmAnswers,
            handleSubmit,
            isBtnFinishDisabled,
            unsavedAnswers,
        } = useButtonsControl({ ...restProps, passingData })

        const handleGoToNextQuestionsList = useCallback(() => {
            setSelectedListIndex((prev) => prev + 1)
        }, [setSelectedListIndex])

        return (
            <ButtonsToolbar className={styles.toolbar}>
                <Button
                    onClick={handleConfirmAnswers}
                    className={styles.button}
                    disabled={
                        !unsavedAnswers.length ||
                        (passingData.questionnaire.showCorrectAnswer &&
                            passingData.questionnaire
                                .allRequiredUserAnswersExists)
                    }
                >
                    {LOCAL.ACTIONS.CONFIRM}
                </Button>

                {isLastQuestionsList ? (
                    <Button
                        className={styles.button}
                        htmlType="submit"
                        type="primary"
                        disabled={isBtnFinishDisabled}
                        onClick={handleSubmit}
                    >
                        {LOCAL.ACTIONS.COMPLETE}
                    </Button>
                ) : (
                    <div className={styles.container}>
                        <Button
                            className={cn(styles.button, styles.nextBtn)}
                            disabled={
                                !isQuestionsListCompleted(selectedListIndex)
                            }
                            type="primary"
                            onClick={handleGoToNextQuestionsList}
                        >
                            {LOCAL.ACTIONS.TO_NEXT_QUESTIONNAIRE_LIST}
                        </Button>

                        <ElementWithListPopover
                            className={styles.popover}
                            items={[
                                LOCAL.MESSAGES
                                    .ALL_MANDATORY_QUESTIONS_NEED_TO_BE_ANSWERED,
                            ]}
                            options={{
                                showPopoverAlways: true,
                                showWithoutTitle: true,
                                placement: 'top',
                            }}
                        />
                    </div>
                )}
            </ButtonsToolbar>
        )
    }
)
