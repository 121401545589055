import { FnActionRequiredProps, Store } from 'App.types'
import { PAGE_NUMBER_DEFAULT, PAGE_SIZE_DEFAULT } from 'consts'
import { SortDirection } from 'core/api'
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { sortByDefaultRule } from 'utils'
import { useCallback, useState } from 'react'

import { QueryParamsProps, UsePaginationProps } from './useSearchQuery.types'
import { usePageSettings } from './usePageSettings'

export interface MappingProps {
    mapFormDataToRequest?: FnActionRequiredProps<Store, Store>
}

export const useSearchQuery = ({
    dictionaryKey,
    queryOptions,
}: UsePaginationProps) => {
    const { pagination, filters, updatePageSettings } = usePageSettings(
        dictionaryKey
    )

    const [isShowMore, setShowMore] = useState(false)

    const [initialState] = useState(queryOptions?.body)

    const [queryParams, setQueryParams] = useState<QueryParamsProps>({
        pageSize:
            queryOptions?.pageSize || pagination?.size || PAGE_SIZE_DEFAULT,
        pageNumber: queryOptions?.pageNumber || PAGE_NUMBER_DEFAULT,
        body: {
            ...queryOptions?.body,
            ...filters?.searchQuery,
        },
    })

    const handleSetFilters = (formValues: Store, mapping?: MappingProps) => {
        const { saveFilters, ...values } = formValues
        const searchQuery = mapping?.mapFormDataToRequest?.(values) || values

        if (dictionaryKey) {
            updatePageSettings(dictionaryKey, {
                filters: saveFilters
                    ? {
                          searchQuery,
                          formValues,
                      }
                    : {
                          formValues: { saveFilters },
                      },
            })
        }

        setShowMore(false)

        setQueryParams((prevQuery) => ({
            ...prevQuery,
            pageNumber: PAGE_NUMBER_DEFAULT,
            body: {
                ...initialState,
                ...searchQuery,
            },
        }))
    }

    const handleResetFilters = useCallback(() => {
        if (dictionaryKey) {
            updatePageSettings(dictionaryKey, {
                filters: {},
            })
        }

        setShowMore(false)

        setQueryParams((prevQuery) => ({
            ...prevQuery,
            pageNumber: PAGE_NUMBER_DEFAULT,
            body: initialState || {},
        }))
    }, [dictionaryKey, initialState, updatePageSettings])

    const handlePageChange = useCallback((pageNumber: number) => {
        setShowMore(false)

        setQueryParams((prevQuery) => ({
            ...prevQuery,
            pageNumber,
        }))
    }, [])

    const handleShowMore = useCallback(
        (loadedEntitiesCount?: number) => {
            setShowMore(true)

            const pageNumber =
                (loadedEntitiesCount || 0) /
                    (queryOptions?.pageSize || PAGE_SIZE_DEFAULT) +
                (queryOptions?.pageNumber || PAGE_NUMBER_DEFAULT)

            setQueryParams((prevQuery) => ({
                ...prevQuery,
                pageNumber,
            }))
        },
        [queryOptions]
    )

    const handlePageSizeChange = useCallback(
        (_current: number, pageSize: number) => {
            if (dictionaryKey) {
                updatePageSettings(dictionaryKey, {
                    pagination: {
                        size: pageSize,
                    },
                })
            }

            setShowMore(false)

            setQueryParams((prevQuery) => ({
                ...prevQuery,
                pageNumber: PAGE_NUMBER_DEFAULT,
                pageSize,
            }))
        },
        [dictionaryKey, updatePageSettings]
    )

    const getSorterMultiple = (el: SorterResult<any>) => {
        const defaultPriority = 1

        const multiple =
            typeof el.column?.sorter === 'object' &&
            'multiple' in el.column.sorter &&
            el.column.sorter.multiple

        return multiple || defaultPriority
    }

    const determineOrderRules = useCallback(
        <T>(sorter: SorterResult<T> | SorterResult<T>[]) => {
            const sortOptions =
                'length' in sorter
                    ? (sorter as SorterResult<T>[])
                    : [sorter as SorterResult<T>]

            sortOptions.sort((a, b) =>
                sortByDefaultRule(getSorterMultiple(b), getSorterMultiple(a))
            )

            return sortOptions.reduce<SorterResult<T>[]>((acc, curr) => {
                if (curr.order)
                    return [
                        ...acc,
                        {
                            field: curr.field,
                            direction:
                                curr.order === 'ascend'
                                    ? SortDirection.Asc
                                    : SortDirection.Desc,
                        },
                    ]

                return acc
            }, [])
        },
        []
    )

    const handleSort = useCallback(
        (
            _pagination: TablePaginationConfig,
            _filters: Store,
            sorter: Store,
            _extra: Store
        ) => {
            setShowMore(false)

            setQueryParams((prevQuery) => ({
                ...prevQuery,
                body: {
                    ...prevQuery?.body,
                    orderRules: determineOrderRules(sorter),
                },
            }))
        },
        [determineOrderRules]
    )

    const paginationOptions = {
        onChange: handlePageChange,
        onShowSizeChange: handlePageSizeChange,
        showSizeChanger: true,
    }

    return {
        paginationOptions,
        queryParams,
        isShowMore,
        handleShowMore,
        handleSetFilters,
        handleResetFilters,
        handleSort,
    }
}
