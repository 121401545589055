import {
    DepartmentsService,
    OrganizationInfoContract,
    OrganizationsService,
    StaffUnitStructureContract,
    StructuralUnitType,
} from 'core/api'
import { normalizeDataForSelectAndRadio } from 'utils'

import { STAFF_MODAL_INITIAL_FORM_VALUES } from './StaffUnitModal.consts'
import { StaffUnitModalFormValues } from './StaffUnitModal.types'

export const mapOrganizationsToFormData = (
    organizations: OrganizationInfoContract[] = []
) =>
    Object.fromEntries(
        organizations.map((org) => [`${org.type.toLowerCase()}Id`, org.id])
    )

export const getDictionaryName = (type: StructuralUnitType) => {
    let dictionaryName = ''

    switch (type) {
        case StructuralUnitType.Complex:
            dictionaryName = StructuralUnitType.Oiv
            break
        case StructuralUnitType.Oiv:
            dictionaryName = StructuralUnitType.Organization
            break
        case StructuralUnitType.Organization:
            dictionaryName = 'department'
    }

    return dictionaryName.toLowerCase()
}

export const mapResponseToFormData = (
    response?: Partial<StaffUnitStructureContract>
) => {
    if (!response) return STAFF_MODAL_INITIAL_FORM_VALUES

    const data: Partial<StaffUnitModalFormValues> = {
        staffUnitId: response.staffUnit?.id,
        ...mapOrganizationsToFormData(response.organizations),
    }

    if (data.organizationId) {
        data.departmentId = response.department?.id
    }

    return data
}

export const getNormalizedUnits = async (
    changedValueKey: string,
    changedValue: string | number
) => {
    if (changedValueKey.includes('department')) return []

    const structuralUnits = changedValueKey.includes('organization')
        ? await DepartmentsService.getByOrganizationsIds({
              body: [+changedValue],
          })
        : await OrganizationsService.getAllOfChildTypeElemntsByParentId({
              parentId: +changedValue,
          })

    return structuralUnits.map(normalizeDataForSelectAndRadio)
}
