import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Connect } from 'core/helpers/signalr'
import {
    LOCAL_STORAGE_KEYS,
    LocalStorageHelper,
} from 'core/helpers/LocalStorage'
import { UserContract, UsersService } from 'core/api'
import { globalLoaderEventEmit } from 'components/shared/GlobalLoader/GlobalLoader.events'

import {
    PageSettingsProps,
    UserSettingsParsedProps,
    UserSettingsType,
} from './UserContext.types'
import { UserContext } from './UserContext'
import {
    parseUserSettings,
    updateMonitoringSettings,
    updateUserSettings,
} from './UserContextProvider.utils'

export const UserContextProvider: FC = React.memo(({ children }) => {
    const { userSettingsData: userSettings } = useContext(UserContext)
    const [userData, setUserData] = useState<UserContract | null>(null)
    const isAuth = useRef(false)
    const [userSettingsData, setUserSettingsData] = useState<UserSettingsType>(
        LocalStorageHelper.getItem(
            LOCAL_STORAGE_KEYS.USER_PAGE_SETTINGS,
            true
        ) || JSON.stringify(userSettings)
    )

    /**
     * Обработчик установки данных пользователя
     * @param userData - данные пользователя
     */
    const onSetUserData = useCallback((userData: UserContract | null) => {
        setUserData(userData)
    }, [])

    const checkAuth = useCallback(async () => {
        try {
            globalLoaderEventEmit('SHOW')

            const user = await UsersService.getCurrent()
            const userSettings = await UsersService.getCurrentUserSettings({
                key: LOCAL_STORAGE_KEYS.USER_PAGE_SETTINGS,
            })

            Connect()
            setUserData(user)

            if (!userSettings?.value) return

            const settings = parseUserSettings(userSettings.value)

            if (settings?.monitoringEnable) {
                userSettings.value =
                    (await updateMonitoringSettings(true, settings)) || ''
            }

            setUserSettingsData(userSettings.value)
            isAuth.current = true
        } catch (error) {
            console.error(error)
        } finally {
            globalLoaderEventEmit('HIDE')
        }
    }, [])

    const updatePageSettings = useCallback(
        (pageKey: string, newSettings: PageSettingsProps) => {
            const userSettings = parseUserSettings(userSettingsData)

            const settings: UserSettingsParsedProps = {
                ...userSettings,
                pages: {
                    ...(userSettings?.pages || {}),
                    [pageKey]: {
                        ...(userSettings?.pages?.[pageKey] || {}),
                        ...newSettings,
                    },
                },
            }

            const settingsJson = JSON.stringify(settings)

            updateUserSettings({
                key: LOCAL_STORAGE_KEYS.USER_PAGE_SETTINGS,
                value: settingsJson,
            })
            setUserSettingsData(settingsJson)
        },
        [setUserSettingsData, userSettingsData]
    )

    const userSettingsParsed = useMemo(
        () => parseUserSettings(userSettingsData),
        [userSettingsData]
    )

    useEffect(() => {
        if (!isAuth.current) {
            checkAuth()
        }
    }, [checkAuth])

    useEffect(() => {
        LocalStorageHelper.setItem(
            LOCAL_STORAGE_KEYS.USER_PAGE_SETTINGS,
            userSettingsData
        )
    }, [userSettingsData])

    return (
        <UserContext.Provider
            value={{
                userData,
                setUserData: onSetUserData,
                userSettingsData: userSettingsParsed,
                setUserSettingsData,
                updatePageSettings,
            }}
        >
            {userData && children}
        </UserContext.Provider>
    )
})
