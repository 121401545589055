import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { TreeSelect } from 'antd'
import { getPopupContainer as getPopupContainerDefault } from 'utils/common.utils'
import { isFormModeView } from 'utils/conditions.utils'

import styles from './MultiSelectControl.module.scss'
import { CheckboxControl } from '../CheckboxControl'
import { MultiSelectControlProps } from './MultiSelectControl.types'
import { getMultiSelectTags, getPlaceholder } from './MultiSelectControl.utils'

/**
 * Декоратор для `TreeSelect` от `antd`
 * Использовать в случае, если нужен множественный выбор.
 */
export const MultiSelectControl: React.FC<MultiSelectControlProps> = React.memo(
    (props) => {
        const {
            onChange,
            value,
            treeData = [],
            tagsType,
            placeholder,
            selectAll,
            addonAfter,
            onBeforeChange,
            disabled,
            getPopupContainer = getPopupContainerDefault,
            formMode,
            ...additionalProps
        } = props

        /**
         * Вычисленное значение для `TreeSelect`
         */
        const calculatedValue = useMemo(
            () => (value !== null ? value : undefined),
            [value]
        )

        /**
         * Обновить значение в стейте и внешней форме
         * @param value - значение поля для обновления внешних состояний
         */
        const triggerChanges = useCallback(
            (value?: number[]) => onChange?.(value),
            [onChange]
        )

        /**
         * Обработчик изменения значения TreeSelect
         * @param selectedItemsIds - массив идентификаторов выбранных элементов поля
         */
        const handleSelectChange = useCallback(
            (selectedItemsIds: number[]) => {
                const startTrigger = () => {
                    triggerChanges(selectedItemsIds)
                }

                if (onBeforeChange) {
                    onBeforeChange({
                        onChange: startTrigger,
                    })
                } else {
                    startTrigger()
                }
            },
            [onBeforeChange, triggerChanges]
        )

        /**
         * Обработчик пункта "Выбрать все/снять выделение" в TreeSelect
         */
        const handleSelectAllChange = useCallback(() => {
            calculatedValue?.length
                ? handleSelectChange([])
                : handleSelectChange(
                      treeData?.map((el) => Number(el.value)).filter(Boolean)
                  )
        }, [calculatedValue, handleSelectChange, treeData])

        /**
         * Обработчик для снятия выбора с определенного элемента поля multiselect
         * @param id - id удаляемого пункта меню
         */
        const handleDeselectOption = useCallback(
            (id: number) => () => {
                if (calculatedValue) {
                    triggerChanges(calculatedValue.filter((el) => el !== id))
                }
            },
            [calculatedValue, triggerChanges]
        )

        /**
         * Рендер пункта "Выбрать все/снять выделение"
         */
        const renderDropdownItemSelectAll = useMemo(
            () => (
                <CheckboxControl
                    className={styles.selectAllOption}
                    checked={!!calculatedValue?.length}
                    onChange={handleSelectAllChange}
                >
                    {calculatedValue?.length
                        ? LOCAL.ACTIONS.DESELECT
                        : LOCAL.ACTIONS.SELECT_ALL}
                </CheckboxControl>
            ),
            [handleSelectAllChange, calculatedValue]
        )

        /**
         * Возвращает дерево данных для селекта в зависимости от флага selectAll
         * @param selectAll - определяет наличие пункта "Выбрать все" в дереве данных
         * @returns массив данных для генерации опций выбора
         */
        const getTreeData = useCallback(
            (selectAll?: boolean) => {
                if (selectAll)
                    return [
                        {
                            title: renderDropdownItemSelectAll,
                            disableCheckbox: true,
                            disabled: true,
                            key: 'all',
                            value: 'all',
                        },
                        ...treeData,
                    ]

                return treeData
            },
            [renderDropdownItemSelectAll, treeData]
        )

        /** Рендер тегов */
        const tagsNodeRender = useMemo(
            () =>
                tagsType &&
                getMultiSelectTags({
                    tagsType,
                    isTagsDisabled: disabled || isFormModeView(formMode),
                    treeDataCollection: treeData,
                    selectedItemsIds: calculatedValue,
                    onDeleteTag: handleDeselectOption,
                }),
            [
                formMode,
                tagsType,
                treeData,
                calculatedValue,
                handleDeselectOption,
                disabled,
            ]
        )

        const selectClasses = cn(
            isFormModeView(formMode) && 'view-mode',
            Boolean(tagsType) && styles.wrapperHasTagsCloud,
            'form-control',
            styles.wrapper
        )

        const dropdownClasses = cn(
            styles.dropdown,
            selectAll && styles.dropdownSelectAll
        )

        return (
            <div className={selectClasses}>
                <div className={addonAfter ? 'multi-select-addon-after' : ''}>
                    <TreeSelect
                        dropdownClassName={dropdownClasses}
                        treeNodeFilterProp="title"
                        treeData={getTreeData(selectAll)}
                        showArrow={true}
                        showSearch={false}
                        disabled={disabled || isFormModeView(formMode)}
                        onChange={handleSelectChange}
                        value={calculatedValue}
                        placeholder={getPlaceholder(
                            placeholder,
                            formMode,
                            tagsType
                        )}
                        suffixIcon={
                            <IconsAdapter iconType={'CustomIconTriangle'} />
                        }
                        treeCheckable={true}
                        showCheckedStrategy="SHOW_CHILD"
                        getPopupContainer={getPopupContainer}
                        {...additionalProps}
                    />

                    {addonAfter && (
                        <div className="addon-after-content">{addonAfter}</div>
                    )}
                </div>

                {tagsNodeRender}
            </div>
        )
    }
)
