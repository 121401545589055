import React from 'react'
import { Col, Row } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { SliderSingleControl } from 'components/controls'
import { TooltipAdapter } from 'components/shared'

import styles from './CompetenceIndicators.module.scss'
import { CompetenceIndicatorsProps } from './CompetenceIndicators.types'

export const CompetenceIndicators: React.FC<CompetenceIndicatorsProps> = React.memo(
    ({ indicators, competenceLevel }) => (
        <Row className={styles.wrapper}>
            {indicators?.map(({ id, name, description }) => (
                <Row className={styles.row} key={id}>
                    <Col xs={12} className={styles.level}>
                        <SliderSingleControl
                            min={0}
                            max={5}
                            value={competenceLevel}
                            className={styles.sliderSingleControl}
                        />
                    </Col>

                    <Col xs={12} className={styles.info}>
                        <Row className={styles.row}>
                            <Col xs={22}>
                                <p className={styles.levelNumber}>
                                    {competenceLevel}
                                </p>

                                <p className={styles.name}>{name}</p>
                            </Col>

                            {description && (
                                <Col className={styles.description}>
                                    <TooltipAdapter
                                        placement="top"
                                        trigger="click"
                                        title={description}
                                    >
                                        <IconsAdapter iconType="InfoCircleFilled" />
                                    </TooltipAdapter>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            ))}
        </Row>
    )
)
