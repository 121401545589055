import React from 'react'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import {
    ControlGroup,
    FormItemAdapter,
    InputNumberControl,
    SearchSelectControl,
    ShouldUpdateChecker,
    SwitchControl,
} from 'components/controls'
import {
    DictionaryContract,
    PositionSearchContract,
    PositionsService,
} from 'core/api'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { POSITIONS_TABLE_COLUMNS } from 'components/controls/AddPositionControl/AddPositionControl.consts'
import { PositionsFilters } from 'components/controls/AddPositionControl/components'

import stylesOverallSettings from '../../shared/OverallSettings/OverallSettings.module.scss'
import { AUTOMATIC_APPOINTMENT_FIELDS } from './AutomaticTrackAssignment.consts'
import { UploadLogo } from '../../shared/UploadLogo'

/** Компонент - форма автоматического назначения трека */
export const AutomaticTrackAssignment: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => (
    <ShouldUpdateChecker fieldPath={AUTOMATIC_APPOINTMENT_FIELDS.AVAILABLE}>
        {(form) => {
            const isAutomaticAppointmentAvailable = form.getFieldValue(
                AUTOMATIC_APPOINTMENT_FIELDS.AVAILABLE
            )

            return (
                <ControlGroup
                    type="light"
                    className={stylesOverallSettings.card}
                >
                    <Form.Item name={AUTOMATIC_APPOINTMENT_FIELDS.AVAILABLE}>
                        <SwitchControl
                            addonAfter={LOCAL.LABELS.AUTOMATIC_TRACK_ASSIGNMENT}
                            formMode={formMode}
                            disabled={disabled}
                        />
                    </Form.Item>

                    <FormItemAdapter
                        fieldName={AUTOMATIC_APPOINTMENT_FIELDS.DURATION}
                        label={LOCAL.LABELS.TRACK_ACCESSIBILITY}
                    >
                        <InputNumberControl
                            min={0}
                            placeholder={LOCAL.PLACEHOLDERS.INPUT_NUMBER}
                            addonAfter={LOCAL.LABELS.DAYS_FROM_ASSIGNMENT_DATE}
                            disabled={
                                !isAutomaticAppointmentAvailable || disabled
                            }
                            formMode={formMode}
                        />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName={AUTOMATIC_APPOINTMENT_FIELDS.POSITION_IDS}
                        label={LOCAL.LABELS.POSITION}
                    >
                        <SearchSelectControl<
                            PositionSearchContract,
                            DictionaryContract
                        >
                            getByIdsMethod={PositionsService.getByIds}
                            selectMode="multiple"
                            disabled={
                                !isAutomaticAppointmentAvailable || disabled
                            }
                            formMode={formMode}
                            modalTitle={LOCAL.LABELS.POSITION_SELECTION}
                            tableSearchOptions={{
                                searchMethod: PositionsService.search,
                                tableColumns: POSITIONS_TABLE_COLUMNS,
                                filterComponent: PositionsFilters,
                            }}
                        />
                    </FormItemAdapter>

                    <UploadLogo
                        form={form}
                        formMode={formMode}
                        disabled={disabled}
                    />
                </ControlGroup>
            )
        }}
    </ShouldUpdateChecker>
))
