import { LOCAL } from 'core/local'

/**
 * Опции визуализации трека, эффекты иконок: угол тени
 */
export const OPTIONS_SHADOW_ANGLE = [
    {
        label: LOCAL.LABELS.WITHOUT_SHADOW,
        value: 'none',
    },
    {
        label: LOCAL.LABELS.RIGHT,
        value: 'right',
    },
    {
        label: LOCAL.LABELS.CENTER,
        value: 'center',
    },
    {
        label: LOCAL.LABELS.LEFT,
        value: 'left',
    },
]
