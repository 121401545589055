import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { AnswerFieldProps } from 'components/pages/QuestionnairesListener/components/QuestionnairePassingAnswer/QuestionnairePassingAnswer.types'
import { Form } from 'antd'
import { TextAreaControl } from 'components/controls'
import { getFieldsPath } from 'utils'
import { usePreventPast } from 'hooks'

import { CLASS_NAME, COUNT_ROWS, MAX_LENGTH } from './TextAnswerPassing.consts'

/**
 * Секция ответа типа "Текстовый ответ"
 */
export const TextAnswerPassing: React.FC<AnswerFieldProps> = React.memo(
    ({ id, fieldName }) => {
        usePreventPast(`.${CLASS_NAME}`)

        return (
            <Form.Item name={getFieldsPath('text', fieldName)}>
                <TextAreaControl
                    className={CLASS_NAME}
                    id={String(id) || uniqueId('textAnswerPassing')}
                    autoSize={{ minRows: COUNT_ROWS, maxRows: COUNT_ROWS }}
                    maxLength={MAX_LENGTH}
                />
            </Form.Item>
        )
    }
)
