import React from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import {
    FaceToFaceMeetingJournalRegistryContract,
    FaceToFaceMeetingJournalSearchContract,
    FaceToFaceMeetingStatus,
    FaceToFaceMeetingsJournalsService,
} from 'core/api'
import { getPageTitleFromRoute } from 'utils'
import { getRowClassNameExpired } from 'components/shared/TableAdapter/TableAdapter.utils'
import { useLocation } from 'react-router-dom'

import { CheckHomeworkFilters } from './components/ResultsOfMeetingsFilters'
import { getTableColumnsConfig } from './ResultsOfMeetings.config'

/**
 * Личный кабинет пользователя: результаты очных встреч
 */
export const ResultsOfMeetings: React.FC = React.memo(() => {
    const location = useLocation()

    return (
        <DictionaryPage<
            FaceToFaceMeetingJournalSearchContract,
            FaceToFaceMeetingJournalRegistryContract
        >
            dictionaryKey={DICTIONARY_KEYS.RESULTS_OF_MEETINGS}
            searchMethod={FaceToFaceMeetingsJournalsService.search}
            contentTitle={getPageTitleFromRoute(location.pathname)}
            contentTitleType="h5"
            tableColumnsRender={getTableColumnsConfig}
            quickSearchMethod={FaceToFaceMeetingsJournalsService.searchText}
            filterComponent={CheckHomeworkFilters}
            rowClassName={getRowClassNameExpired}
            withPageBanner={false}
            tableFiltersOptions={{
                initialValues: {
                    statuses: [FaceToFaceMeetingStatus.WaitForSolution],
                },
            }}
        />
    )
})
