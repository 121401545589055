import React from 'react'
import cn from 'classnames'
import { ColumnsType } from 'antd/lib/table'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { PassingFormQuestionnaireResultContract } from 'core/api'
import { Popover } from 'antd'
import { isResultCalculatingTypeTotalPoints, renderOrderNumber } from 'utils'

import styles from './QuestionnairePassing.module.scss'

const getStatusClass = (
    questionnaireResult: PassingFormQuestionnaireResultContract
) => {
    if (
        !isResultCalculatingTypeTotalPoints(
            questionnaireResult.resultCalculatingType
        )
    ) {
        return questionnaireResult.isPassed
            ? styles.statusPassed
            : styles.statusNotPassed
    }

    return styles.statusLevel
}

const getStatusText = (isPassed: boolean) =>
    isPassed ? LOCAL.STATUSES.PASSED_M : LOCAL.STATUSES.NOT_PASSED_M

export const repassingColumns: ColumnsType<PassingFormQuestionnaireResultContract> = [
    {
        title: LOCAL.LABELS.NUMBER_SHORT,
        render: renderOrderNumber,
    },
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'questionnaireName',
    },
    {
        title: LOCAL.LABELS.POINTS_OF,
        render: (_, row) => (
            <>
                {`${row.overallScore}/${row.maxScore}`}

                {!isResultCalculatingTypeTotalPoints(
                    row.resultCalculatingType
                ) &&
                    !row.isPassed && (
                        <Popover
                            content={`${LOCAL.LABELS.REQUIRED_MIN_SCORE} ${row.requiredMinScore}`}
                            placement="topLeft"
                        >
                            <IconsAdapter
                                iconType="InfoCircleOutlined"
                                className={styles.info}
                            />
                        </Popover>
                    )}
            </>
        ),
    },
    {
        title: LOCAL.LABELS.STATUS,
        render: (_, row) => {
            const showLevel = isResultCalculatingTypeTotalPoints(
                row.resultCalculatingType
            )

            return (
                <>
                    <span className={cn(styles.status, getStatusClass(row))}>
                        {showLevel
                            ? `${LOCAL.LABELS.LEVEL} ${row.levelNumber}`
                            : getStatusText(row.isPassed)}
                    </span>

                    {showLevel && (
                        <Popover content={row.levelName} placement="topLeft">
                            <IconsAdapter
                                iconType="InfoCircleOutlined"
                                className={styles.info}
                            />
                        </Popover>
                    )}
                </>
            )
        },
    },
]
