import { FnActionProps, FnActionRequiredProps } from 'App.types'
import { eventEmitter } from 'core/helpers/eventEmitter'

import { AnswerFormValuesContract } from './QuestionnairePassing.types'

export const QUESTIONNAIRE_PASSING_EVENTS = {
    CONFIRM: 'CONFIRM',
    FINISH: 'FINISH',
    UPDATE_ANSWER: 'UPDATE_ANSWER',
}

interface RegisterUpdateAnswerTimeLeftProps {
    onUpdateAnswerTimeLeftValues: FnActionRequiredProps<
        AnswerFormValuesContract[]
    >
}

interface RegisterQuestionnairePassingEvents {
    onFinish: FnActionProps
    onConfirmRepassing: FnActionProps<boolean>
}

export const registerUpdateAnswerTimeLeft = ({
    onUpdateAnswerTimeLeftValues,
}: RegisterUpdateAnswerTimeLeftProps) => {
    eventEmitter.on(
        QUESTIONNAIRE_PASSING_EVENTS.UPDATE_ANSWER,
        onUpdateAnswerTimeLeftValues
    )
}

export const unregisterUpdateAnswerTimeLeft = ({
    onUpdateAnswerTimeLeftValues,
}: RegisterUpdateAnswerTimeLeftProps) => {
    eventEmitter.off(
        QUESTIONNAIRE_PASSING_EVENTS.UPDATE_ANSWER,
        onUpdateAnswerTimeLeftValues
    )
}

export const registerQuestionnairePassingEvents = ({
    onFinish,
    onConfirmRepassing,
}: RegisterQuestionnairePassingEvents) => {
    eventEmitter.on(QUESTIONNAIRE_PASSING_EVENTS.FINISH, onFinish)
    eventEmitter.on(QUESTIONNAIRE_PASSING_EVENTS.CONFIRM, onConfirmRepassing)
}

export const unregisterQuestionnairePassingEvents = ({
    onFinish,
    onConfirmRepassing,
}: RegisterQuestionnairePassingEvents) => {
    eventEmitter.off(QUESTIONNAIRE_PASSING_EVENTS.FINISH, onFinish)
    eventEmitter.off(QUESTIONNAIRE_PASSING_EVENTS.CONFIRM, onConfirmRepassing)
}
