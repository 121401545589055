import React from 'react'
import { NotImplementedError } from 'core/helpers/NotImplementedError'

import { ColorThemeContextProps, ColorThemes } from './ColorThemeProps'

export const ColorThemeContext = React.createContext<ColorThemeContextProps>({
    theme: ColorThemes.Light,
    updateTheme: () => {
        throw new NotImplementedError()
    },
    changeTheme: () => {
        throw new NotImplementedError()
    },
})
