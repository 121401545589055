import React, { useCallback, useMemo } from 'react'
import { ContentTitle, PageContent } from 'components/shared'
import { LOCAL } from 'core/local'
import { LdeEditorRoles } from 'App.types'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsForEditProps, useDocumentEditor } from 'hooks'
import {
    WithHeightResizeProps,
    WithLoaderProps,
    withHeightResize,
    withLoader,
} from 'HOCs'
import { useHistory, useParams } from 'react-router-dom'

import styles from './DocumentEditorEdit.module.scss'
import { DOCUMENT_EDITOR_CONTAINER_ID } from './DocumentEditorEdit.consts'

/** Редактор документов: редактирование */
const DocumentEditorEdit: React.FC<
    WithHeightResizeProps & Partial<WithLoaderProps>
> = ({ componentRef, componentHeight, updateLoader }) => {
    const urlParams = useParams<Required<UrlParamsForEditProps>>()
    const history = useHistory()

    const config = useMemo(
        () => ({
            documentId: urlParams.id,
            spelling: true,
            roles: [LdeEditorRoles.Tutor],
        }),
        [urlParams.id]
    )

    const { handleCloseLde } = useDocumentEditor({
        editorContainerId: DOCUMENT_EDITOR_CONTAINER_ID,
        config,
        updateLoader,
    })

    const handleCloseEditor = useCallback(() => {
        handleCloseLde()
        history.push(ROUTE_NAMES.DOCUMENTS_EDITOR)
    }, [handleCloseLde, history])

    return (
        <PageContent>
            <ContentTitle
                backLink={{
                    text: LOCAL.LINKS.TO_REGISTER,
                    onClick: handleCloseEditor,
                }}
                className={styles.title}
            />

            <div
                id={DOCUMENT_EDITOR_CONTAINER_ID}
                className={styles.container}
                ref={componentRef}
                style={{ height: componentHeight }}
            />
        </PageContent>
    )
}

export default withLoader(
    React.memo(withHeightResize(DocumentEditorEdit))
) as typeof DocumentEditorEdit
