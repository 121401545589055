import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    AssessmentPortfolioContract,
    AssessmentPortfolioPublishContract,
    AssessmentPortfoliosService,
    QuestionnaireType,
} from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { FormProps } from 'components/forms/forms.types'
import { HiddenField } from 'components/controls'
import { LOCAL } from 'core/local'
import { PortfolioFormCommonTabs } from 'components/shared'
import { PortfolioFormCommonTabsContextProvider } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WithLoaderProps } from 'HOCs'
import {
    createConfirmPopup,
    getFormPopupTitle,
    getInitialValuesForCopy,
    isAssessmentTypeCircle,
    isAssessmentTypeEstimate360,
    isAssessmentTypeManager,
    isDocumentStatusInWork,
    isFormModeEdit,
    isFormModeView,
    setParentIdForCopy,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory, useParams } from 'react-router-dom'

import { AssessmentPortfolioPublishFormContract } from './AssessmentTemplateForm.types'
import { AssessmentPortfolioSettings } from '../AssessmentPortfolioSettings'
import {
    FORM_FIELD_PATHS,
    MIN_STAFF_COUNT,
    STAFF_STATUS_INITIAL_STATE,
} from './AssessmentTemplateForm.config'
import { StaffFieldsStatusProps } from '../CircleParameters/CircleParameters.types'
import { UrlProps } from '../../AssessmentPortfolioFormContainer.types'
import {
    getInitialFormValues,
    mapFormDataToRequest,
    mapResponseToFormData,
    triggerLocalStoragePortfolioEvent,
} from './AssessmentTemplateForm.utils'

/** Форма создания/редактирования портфеля оценки */
export const AssessmentTemplateForm: React.FC<
    FormProps<AssessmentPortfolioContract> &
        ComponentCommonProps &
        Partial<WithLoaderProps>
> = React.memo(
    ({
        onBlockUserRouting,
        isSaveDocument,
        initialValuesForEdit,
        reFetchInitialFormValues,
        formMode,
        updateLoader,
    }) => {
        const { assessmentType } = useParams<UrlProps>()
        const initialValues = useMemo(
            () => getInitialFormValues(assessmentType),
            [assessmentType]
        )
        const history = useHistory()
        const [form] = Form.useForm()
        const [staffFieldsStatus, setStaffFieldsStatus] = useState<
            StaffFieldsStatusProps
        >(STAFF_STATUS_INITIAL_STATE)

        /**
         * Обработчик отправки формы
         */
        const handleFinish = useCallback(
            async (values: Partial<AssessmentPortfolioPublishFormContract>) => {
                try {
                    updateLoader?.(true)

                    const body = setParentIdForCopy(
                        mapFormDataToRequest(values),
                        formMode
                    ) as AssessmentPortfolioPublishContract

                    let dataSource: number

                    if (isSaveDocument) {
                        dataSource = await AssessmentPortfoliosService.save({
                            body,
                        })
                    } else {
                        dataSource = await AssessmentPortfoliosService.publish({
                            body,
                        })

                        triggerLocalStoragePortfolioEvent()
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(
                                ROUTE_NAMES.ASSESSMENT_PORTFOLIOS,
                                'pageTitle'
                            )
                        ),
                        content: isSaveDocument
                            ? LOCAL.MESSAGES
                                  .ASSESSMENT_TEMPLATE_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES
                                  .ASSESSMENT_TEMPLATE_CREATE_SUCCESS_MESSAGE,
                        onOk: () => {
                            history.push(ROUTE_NAMES.ASSESSMENT_PORTFOLIOS)
                        },
                        onCancel: () => {
                            if (!values?.id) {
                                form.resetFields()
                                form.setFieldsValue(initialValues)
                            } else reFetchInitialFormValues?.(dataSource)
                        },
                    })
                } catch (error) {
                    if (
                        isAssessmentTypeCircle(values.type) &&
                        values.circleParameters
                    ) {
                        const {
                            managersCount,
                            colleagueFromRelatedDepartmentCount,
                            colleaguesCount,
                            subordinatesCount,
                        } = values.circleParameters

                        const staffCount =
                            colleagueFromRelatedDepartmentCount +
                            colleaguesCount +
                            subordinatesCount

                        setStaffFieldsStatus({
                            managers: !managersCount ? 'error' : '',
                            staff: staffCount < MIN_STAFF_COUNT ? 'error' : '',
                        })
                    }
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                updateLoader,
                formMode,
                isSaveDocument,
                onBlockUserRouting,
                history,
                form,
                initialValues,
                reFetchInitialFormValues,
            ]
        )

        useEffect(() => {
            if (initialValuesForEdit?.id) {
                form.setFieldsValue(
                    mapResponseToFormData(
                        getInitialValuesForCopy(initialValuesForEdit, formMode)
                    )
                )
            }
        }, [form, formMode, initialValuesForEdit])

        /**
         * Обработчик изменения значений полей формы
         */
        const handleValuesChange = useCallback(
            (changedValues) => {
                if (
                    changedValues.type &&
                    isAssessmentTypeManager(changedValues.type)
                ) {
                    form.setFields([
                        {
                            name:
                                FORM_FIELD_PATHS.circleParameters
                                    .questionnaires,
                            value: undefined,
                        },
                    ])
                }

                if (
                    changedValues.type &&
                    isAssessmentTypeEstimate360(changedValues.type)
                ) {
                    form.setFields([
                        {
                            name: FORM_FIELD_PATHS.managerParameters,
                            value: undefined,
                        },
                    ])
                }

                if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }
            },
            [form, formMode, onBlockUserRouting]
        )

        if (
            (isFormModeEdit(formMode) || isFormModeView(formMode)) &&
            !initialValuesForEdit?.id
        )
            return null

        return (
            <Form
                id={FORM_IDS.ASSESSMENT_TEMPLATE_FORM}
                onFinish={handleFinish}
                initialValues={initialValues}
                onValuesChange={handleValuesChange}
                form={form}
            >
                <PortfolioFormCommonTabsContextProvider
                    form={form}
                    initialValuesForEdit={initialValuesForEdit}
                    formMode={formMode}
                    portfolioFormType={QuestionnaireType.Assessment}
                    disabledByStatus={
                        isFormModeEdit(formMode) &&
                        isDocumentStatusInWork(
                            initialValuesForEdit?.documentStatus
                        )
                    }
                >
                    <PortfolioFormCommonTabs>
                        <HiddenField fieldName="id" />

                        <AssessmentPortfolioSettings
                            assessmentType={assessmentType}
                            staffFieldsStatus={staffFieldsStatus}
                            updateLoader={updateLoader}
                        />
                    </PortfolioFormCommonTabs>
                </PortfolioFormCommonTabsContextProvider>
            </Form>
        )
    }
)
