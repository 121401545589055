import CertificateLogo from 'core/images/certificate.svg'
import React from 'react'
import { Col, Row } from 'antd'
import { ENDING_DOCUMENT_TYPES, ROW_GUTTER } from 'consts'
import { LOCAL } from 'core/local'
import { ReactSVG } from 'react-svg'
import { Title } from 'components/shared'
import { insertCharBeforeWord } from 'utils'

import styles from './ProductCardCertificate.module.scss'
import { ProductCardCertificateProps } from './ProductCardCertificate.types'

/** Карточка продукта - сертификат */
export const ProductCardCertificate: React.FC<ProductCardCertificateProps> = React.memo(
    ({ graduationDocumentType }) => (
        <Row className={styles.wrapper} gutter={ROW_GUTTER}>
            <Col>
                <div className={styles.picture}>
                    <ReactSVG src={CertificateLogo} className={styles.logo} />
                </div>
            </Col>

            <Col>
                <h3 className={styles.titleSecondary}>
                    {LOCAL.LABELS.ENDING_DOCUMENT}
                </h3>

                <Title
                    type="h4"
                    text={insertCharBeforeWord(
                        ENDING_DOCUMENT_TYPES[graduationDocumentType],
                        2,
                        '\n'
                    )}
                />
            </Col>
        </Row>
    )
)
