import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'
import { RecordEntityType } from 'core/api'

export const MATCH_CONFIG_FOR_HEADER_SLIDE = {
    path: [
        ROUTE_NAMES.TRACKS_ADAPTATION_PREVIEW,
        `${ROUTE_NAMES.TRACKS_ADAPTATION_PREVIEW}_v2`,
        `${ROUTE_NAMES.TRACKS_PASSING}/:id/:start?`,
        `${ROUTE_NAMES.TRACKS_PASSING}_v2/:id/:start?`,
        `${ROUTE_NAMES.EVENTS_PASSING}/:activityId/:appointmentActivityId?`,
        `${ROUTE_NAMES.TRAINING_MATERIALS}/:id`,
    ],
    strict: true,
}

export const MATCH_CONFIG_FOR_HEADER_NONE = {
    path: [ROUTE_NAMES.TRACKS_PASSING_UNIQUE],
}

export const URL_PATH_TO_ENTITY_TYPE = {
    [ROUTE_NAMES.TRACKS_ADAPTATION]: RecordEntityType.AdaptationTrack,
    [ROUTE_NAMES.ASSESSMENT_PORTFOLIOS]: RecordEntityType.AssessmentPortfolio,
    [ROUTE_NAMES.TEST_PORTFOLIOS]: RecordEntityType.TestPortfolio,
    [ROUTE_NAMES.TRACKS_DEVELOPMENT]: RecordEntityType.DevelopmentTrack,
    [ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS]:
        RecordEntityType.AssessmentAppointment,
    [ROUTE_NAMES.TEST_ASSIGNMENTS]: RecordEntityType.TestAppointment,
    [ROUTE_NAMES.TRACKS_ASSIGNMENTS]: RecordEntityType.TrackAppointment,
    [ROUTE_NAMES.QUIZ_ASSIGNMENTS]: RecordEntityType.QuizAppointment,
    [ROUTE_NAMES.EVENTS_ASSIGNMENTS]: RecordEntityType.ActivityAppointment,
    [`${ROUTE_NAMES.EXTERNAL_CERTIFICATES}/:id?`]: RecordEntityType.Certificate,
    [`${ROUTE_NAMES.EXTERNAL_CERTIFICATES_EDIT}/:userId/:certificateId`]: RecordEntityType.Certificate,
    [`${ROUTE_NAMES.EXTERNAL_CERTIFICATES_VIEW}/:userId/:certificateId`]: RecordEntityType.Certificate,
    [`${ROUTE_NAMES.INTERNAL_CERTIFICATES}/:id?`]: RecordEntityType.Certificate,
    [ROUTE_NAMES.PRODUCT_CARDS]: RecordEntityType.ProductCards,
    [ROUTE_NAMES.EVENTS]: RecordEntityType.Activity,
    [ROUTE_NAMES.POSITION_PROFILES]: RecordEntityType.PositionProfile,
    [ROUTE_NAMES.QUIZ_PORTFOLIOS]: RecordEntityType.QuizAppointment,
    [ROUTE_NAMES.YOUR_DEVELOPMENT]: RecordEntityType.UserDevelopment,
    [ROUTE_NAMES.KNOWLEDGE_BASE]: RecordEntityType.Article,
    [ROUTE_NAMES.QUESTIONNAIRES_LISTENER]: RecordEntityType.UserQuizAndTest,
    [ROUTE_NAMES.QUESTIONNAIRE_PASSING]: RecordEntityType.UserQuizAndTest,
    [ROUTE_NAMES.USER_ASSIGNMENTS]: RecordEntityType.UserAssessment,
    [ROUTE_NAMES.USER_ASSIGNMENTS_PASSING]: RecordEntityType.UserAssessment,
    [ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT]:
        RecordEntityType.NotificationMailingTask,
    [ROUTE_NAMES.PRODUCT_CATALOG]: RecordEntityType.ProductCards,
}

export const MATCH_CONFIG_FOR_MONITORING_PATH = Object.keys(
    URL_PATH_TO_ENTITY_TYPE
)

export const MATCH_CONFIG_FOR_MONITORING_PATH_WITH_INTERNALS = MATCH_CONFIG_FOR_MONITORING_PATH.reduce<
    string[]
>((acc, route) => {
    acc.push(
        route,
        `${route}/${ROUTES_POSTFIXES.CREATE}/:id?`,
        `${route}/${ROUTES_POSTFIXES.COPY}/:id`,
        `${route}/${ROUTES_POSTFIXES.EDIT}/:id`,
        `${route}/${ROUTES_POSTFIXES.VIEW}/:id`
    )

    return acc
}, [])

export const MATCH_CONFIG_FOR_MONITORING = {
    path: MATCH_CONFIG_FOR_MONITORING_PATH_WITH_INTERNALS,
    exact: true,
}
