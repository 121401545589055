import React, { FC, useCallback } from 'react'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import { PopupWithForm, getRowClassNameArchive } from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    ScaleContract,
    ScaleSearchContract,
    ScalesService,
    UserPermission,
} from 'core/api'
import { getRouteMeta } from 'routing/routeNames.utils'

import { CriteriaScaleView, CriteriaScalesFilters } from './components'
import { SCALES_TABLE_COLUMNS } from './CriteriaScales.config'

/** Шкалы критериев */
export const CriteriaScales: FC = React.memo(() => {
    /**
     * Рендер контента после основного содержимого страницы
     */
    const renderPageContentAfter = useCallback(
        () => (
            <PopupWithForm
                component={CriteriaScaleView}
                formId={FORM_IDS.SCALE_INFO_FORM}
                haveButton={false}
                modalOptions={{ footer: null }}
            />
        ),
        []
    )

    return (
        <DictionaryPage<ScaleSearchContract, ScaleContract>
            dictionaryKey={DICTIONARY_KEYS.CRITERIA_SCALES}
            searchMethod={ScalesService.search}
            deleteMethod={ScalesService.delete}
            contentTitle={getRouteMeta(ROUTE_NAMES.CRITERIA_SCALES)}
            tableColumns={SCALES_TABLE_COLUMNS}
            pageContentAfterRender={renderPageContentAfter}
            createRecordLink={{
                path: ROUTE_NAMES.CRITERIA_SCALES_CREATE,
            }}
            filterComponent={CriteriaScalesFilters}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES_WITH_CHANGE_DATE,
                },
            }}
            createPermissions={[UserPermission.ScaleSave]}
            quickSearchMethod={ScalesService.searchText}
            rowClassName={getRowClassNameArchive}
        />
    )
})
