import React, { useCallback, useEffect } from 'react'
import {
    DocumentStatus,
    QuestionnaireSelectSearchContract,
    QuestionnaireType,
    QuestionnairesService,
    QuizPortfolioContract,
    QuizPortfoliosService,
    QuizType,
} from 'core/api'
import { FORM_IDS, VALIDATE_MESSAGE_DEFAULT } from 'core/configs'
import { Form } from 'antd'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import { ORDER_RULES_WITH_CREATION_DATE } from 'components/layouts'
import { PortfolioFormCommonTabs } from 'components/shared'
import { PortfolioFormCommonTabsContextProvider } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WithLoaderProps } from 'HOCs'
import {
    getInitialValuesForCopy,
    isDocumentStatusInWork,
    isFormModeEdit,
    isFormModeView,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { useHttp, usePublish } from 'hooks'

import {
    FORM_INITIAL_VALUES,
    fieldsShouldUpdate,
    questionnaireIdDependencyFields,
} from './QuizPortfolioForm.consts'
import { QuizPortfolioCommonSettings } from '../QuizPortfolioCommonSettings'
import { QuizPortfolioFormSubmitValues } from './QuizPortfolioForm.types'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
} from './QuizPortfolioForm.utils'

/**
 * Форма создания, редактирования и просмотра портфеля опроса
 */
export const QuizPortfolioForm: React.FC<
    FormProps<QuizPortfolioContract> & Partial<WithLoaderProps>
> = React.memo(
    ({
        onBlockUserRouting,
        isSaveDocument,
        initialValuesForEdit,
        formMode,
        updateLoader,
    }) => {
        const [form] = Form.useForm()
        const { createEntityFn } = usePublish({
            updateLoader,
            formMode,
            mapFormDataToRequest,
            initialValuesForEdit,
            saveFn: QuizPortfoliosService.save,
            publishFn: QuizPortfoliosService.publish,
            redirectRoute: ROUTE_NAMES.QUIZ_PORTFOLIOS,
            saveMessage: LOCAL.MESSAGES.QUIZ_PORTFOLIO_SAVE_SUCCESS_MESSAGE,
            publishMessage:
                LOCAL.MESSAGES.QUIZ_PORTFOLIO_PUBLISH_SUCCESS_MESSAGE,
            onBlockUserRouting,
            isSaveDocument,
            form,
            formInitialValues: FORM_INITIAL_VALUES,
        })

        const [fetchQuizzes, quizzes, , updateData] = useHttp(
            QuestionnairesService.getForSelect
        )

        /**
         * Отправка формы
         */
        const handleFinish = useCallback(
            async (values: Partial<QuizPortfolioFormSubmitValues>) => {
                await createEntityFn(values)
            },
            [createEntityFn]
        )

        /**
         * Запрос справочника шаблонов опросов
         */
        const dictionaryQuizTemplateFetch = useCallback(
            (values?: Partial<QuizPortfolioFormSubmitValues>) => {
                const positionIds = values?.positionIds?.map((el) =>
                    Number(el.value)
                )

                if (
                    positionIds ||
                    values?.forAllPositions ||
                    values?.forAllOrganizations
                ) {
                    fetchQuizzes({
                        body: {
                            questionnaireType: QuestionnaireType.Quiz,
                            positionIds,
                            excludeStatuses: [DocumentStatus.Draft],
                            quizType:
                                values?.quizType ||
                                initialValuesForEdit?.type ||
                                QuizType.Feedback,
                            forAllPositions: values?.forAllPositions,
                            forAllOrganizations: values?.forAllOrganizations,
                            orderRules: ORDER_RULES_WITH_CREATION_DATE,
                        } as QuestionnaireSelectSearchContract,
                    })

                    return
                }

                updateData([])
            },
            [updateData, fetchQuizzes, initialValuesForEdit]
        )

        /**
         * Обработчик изменения полей формы
         */
        const handleValuesChange = useCallback(
            (changedValue, allValues) => {
                const changedValueKey = Object.keys(changedValue)[0]

                if (fieldsShouldUpdate.includes(changedValueKey)) {
                    dictionaryQuizTemplateFetch(allValues)
                }
                if (questionnaireIdDependencyFields.includes(changedValueKey)) {
                    form.setFieldsValue({ questionnaireIds: undefined })
                }
                if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }
            },
            [dictionaryQuizTemplateFetch, form, formMode, onBlockUserRouting]
        )

        useEffect(() => {
            dictionaryQuizTemplateFetch({
                quizType: initialValuesForEdit?.type,
                positionIds: initialValuesForEdit?.positions?.map(
                    normalizeDataForSelectAndRadio
                ),
                forAllPositions: initialValuesForEdit?.forAllPositions,
                forAllOrganizations: initialValuesForEdit?.forAllOrganizations,
            })
        }, [dictionaryQuizTemplateFetch, initialValuesForEdit])

        useEffect(() => {
            if (initialValuesForEdit?.id)
                form.setFieldsValue(
                    mapResponseToFormData(
                        getInitialValuesForCopy(initialValuesForEdit, formMode)
                    )
                )
        }, [form, formMode, initialValuesForEdit])

        if (
            (isFormModeEdit(formMode) || isFormModeView(formMode)) &&
            !initialValuesForEdit?.id
        )
            return null

        return (
            <Form
                id={FORM_IDS.QUIZ_PORTFOLIO_FORM}
                form={form}
                initialValues={FORM_INITIAL_VALUES}
                validateMessages={{ required: VALIDATE_MESSAGE_DEFAULT }}
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                autoComplete="off"
            >
                <PortfolioFormCommonTabsContextProvider
                    form={form}
                    initialValuesForEdit={initialValuesForEdit}
                    formMode={formMode}
                    portfolioFormType={QuestionnaireType.Quiz}
                    disabledByStatus={
                        isFormModeEdit(formMode) &&
                        isDocumentStatusInWork(initialValuesForEdit?.status)
                    }
                >
                    <PortfolioFormCommonTabs>
                        <QuizPortfolioCommonSettings quizzes={quizzes} />
                    </PortfolioFormCommonTabs>
                </PortfolioFormCommonTabsContextProvider>
            </Form>
        )
    }
)
