import { LOCAL } from 'core/local'
import { Resolutions } from 'core/configs'

export const ICON_STATE_NAMES = [
    { label: LOCAL.LABELS.PASSING_BEFORE, value: 'beforeStart' },
    { label: LOCAL.LABELS.IN_PROCESS, value: 'inProcess' },
    { label: LOCAL.LABELS.PASSING, value: 'passing' },
]

export const RESOLUTIONS_FOR_LOGO = [
    Resolutions.XS,
    Resolutions.SM,
    Resolutions.MD,
    Resolutions.LG,
    Resolutions.XL,
    Resolutions.XXL,
]
