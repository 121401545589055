export const staffUnitsShouldFetch = [
    'complexId',
    'oivId',
    'organizationId',
    'departmentId',
]

export const STAFF_MODAL_INITIAL_FORM_VALUES = {
    oivId: null,
    organizationId: null,
    departmentId: null,
    staffUnitId: null,
}
