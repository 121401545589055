import { LessonForUserContract } from 'core/api'

import { getStatusLessonIndicator } from '../StagesIndicator/StagesIndicator.utils'

export const getLessonsData = (lessons?: LessonForUserContract[]) =>
    lessons?.map(({ status, id, name }) => ({
        key: String(id),
        title: name,
        statusClassName: getStatusLessonIndicator(status),
    }))
