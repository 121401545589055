import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import {
    CompetenciesService,
    StructuralUnitsService,
    TestPortfolioSearchContract,
} from 'core/api'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import { DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED, ROW_GUTTER } from 'consts'
import { DictionariesCommonProps } from 'App.types'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionaries } from 'hooks'

/**
 * Форма фильтрации для реестра портфелей тестов
 */
export const TestPortfoliosFilters: React.FC<FiltersProps<
    TestPortfolioSearchContract
>> = React.memo((props) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<'structuralUnits' | 'competenciesData'>
    >()

    useEffect(() => {
        handleFetchDictionaries({
            structuralUnits: [
                StructuralUnitsService.getAllStructuralUnits(),
                'treeSelectForStructuralUnit',
            ],
            competenciesData: CompetenciesService.getAll(),
        })
    }, [handleFetchDictionaries])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitIds"
                            label={LOCAL.LABELS.ORGANIZATIONS}
                        >
                            <TreeSelectControl
                                treeData={dictionaries.structuralUnits || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="competenceIds"
                            label={LOCAL.LABELS.ASSESSED_COMPETENCIES}
                        >
                            <SelectControl
                                values={dictionaries.competenciesData}
                                mode="multiple"
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CREATE_FROM,
                                fieldName: 'creationDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CREATE_TO,
                                fieldName: 'creationDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
