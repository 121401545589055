import React from 'react'
import template from 'lodash/template'
import { DictionaryContract } from 'core/api'
import { LOCAL } from 'core/local'
import { REPORT_LEVELS_MESSAGES_TO, ReportLevels } from 'consts'

import styles from './CompetenciesLowHighLevel.module.scss'
import { makeList } from '../ReportView/ReportView.utils'

export const makeCompetenceLevelsBlock = (
    level: ReportLevels,
    data?: DictionaryContract[]
) =>
    !!data?.length && (
        <>
            <div className={styles.header}>
                {template(LOCAL.MESSAGES.COMPETENCE_REPORT_LEVEL)({
                    level: REPORT_LEVELS_MESSAGES_TO[level],
                })}
            </div>

            {makeList(data, styles.list)}
        </>
    )
