import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'core/helpers/ErrorBoundary'
import { FORM_IDS, Resolutions } from 'core/configs'
import { FallbackSimple } from 'core/helpers/ErrorBoundary/components'
import { Form } from 'antd'
import { HiddenField } from 'components/controls'
import { InfoAssistant, Popup } from 'components/shared'
import {
    InfoAssistantProps,
    InfoAssistantType,
} from 'components/shared/InfoAssistant/InfoAssistant.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    TrackEvent,
    TrackPassingContract,
    TrackPassingsService,
    TrackStageEvent,
} from 'core/api'
import {
    isAppointmentTrackStatusAvailableToPassing,
    isAppointmentTrackStatusDraft,
    isAppointmentTrackStatusInWork,
    isAppointmentTrackStatusPassed,
    isMaterialTypeActivity,
    isMaterialTypeContent,
    isStageModePassing,
} from 'utils'
import { useElementVisible, useHttp, usePrevious } from 'hooks'
import { useHistory, useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import {
    ActivityLayer,
    ContentLayer,
    InfoAssistantChecker,
    TrackContentMenu,
    TrackInfoMenu,
    TrackLayer,
    TrackProgress,
} from './components'
import {
    AdaptationTrackReadyParams,
    PassageProcessInfoProps,
    StartStagePassingFnProps,
} from './TrackPreviewPassing.types'
import { InfoAssistantEventsProps } from './TrackPassing.types'
import { TrackPassingContextProvider } from './TrackPassing.context'
import {
    getCurrentStage,
    getFirstNotPassedStage,
    getFirstPassedStage,
    isTrackNeedToBeStarted,
    onSetInfoAssitantVisible,
    scrollToFirstAvailableStage,
} from './TrackPreviewPassing.utils'
import { mapTrackPassingData } from '../Track/components/TrackForm/TrackForm.utils'
import {
    registerInfoAssistantEvents,
    unRegisterInfoAssistantEvents,
} from './TrackPassing.events'

export const TrackPassing: React.FC = withLoader(({ updateLoader }) => {
    const mode = 'passing'
    const history = useHistory()
    const urlParams = useParams<AdaptationTrackReadyParams>()
    const [currentStageForViewIndex, setCurrentStageForViewIndex] = useState(0)

    const appointmentId = Number(urlParams.id)

    const [form] = Form.useForm()

    const [trackAutoStart, setTrackAutoStart] = useState(true)
    const [trackInfo, setTrackInfo] = useState<TrackPassingContract>()
    const [infoAboutPassing, setInfoAboutPassing] = useState<
        PassageProcessInfoProps
    >()

    const [infoAssistantTrackConfig, setInfoAssistantTrackConfig] = useState<
        InfoAssistantProps
    >()
    const [infoAssistantStageConfig, setInfoAssistantStageConfig] = useState<
        InfoAssistantProps
    >()
    const [
        infoAssisstantStageFinishConfig,
        setInfoAssistantStageFinishConfig,
    ] = useState<InfoAssistantProps>()

    const [themeBlockByContentId, setThemeBlockByContentId] = useState<number>()
    const [getProgress, progress] = useHttp(TrackPassingsService.getProgress)

    const {
        elementVisible: openContent,
        toggleElementVisible: handleToggleContent,
        handleHideElement: handleHideContent,
    } = useElementVisible()

    const {
        elementVisible: showProgress,
        handleShowElement: handleShowProgress,
        elementRef,
        toggleElementVisibleRef,
    } = useElementVisible()

    const { isTrackWithVisualisation, currentStageNumber, id: trackPassingId } =
        trackInfo || {}
    const isVisibleProgress =
        trackInfo?.trackProgressVisualization?.isProgressVisible

    const switchViewToNextStage = useCallback(() => {
        if (!trackInfo?.stages) return

        setCurrentStageForViewIndex((prev) => {
            if (prev < trackInfo.stages.length - 1) {
                return prev + 1
            }

            return 0
        })
    }, [trackInfo])

    const updateInfoAboutPassing = useCallback(
        (state?: PassageProcessInfoProps) => {
            setInfoAboutPassing(state)
        },
        []
    )

    const updateInfoAssistantTrackConfig = useCallback(
        (config: InfoAssistantProps) => {
            setInfoAssistantTrackConfig((prev) => ({ ...prev, ...config }))
        },
        []
    )

    const updateStageAssisstantVisible = useCallback((visible: boolean) => {
        setInfoAssistantStageConfig((prev) =>
            onSetInfoAssitantVisible(prev, visible)
        )
    }, [])

    const startStagePassing: StartStagePassingFnProps = useCallback(
        async ({ currentStage, isRestart }) => {
            try {
                if (!currentStage) return

                const {
                    stageNumber,
                    materialType,
                    title: stageTitle,
                } = currentStage

                updateLoader(true)

                if (isMaterialTypeActivity(materialType)) {
                    const payload = {
                        appointmentId,
                        stageNumber,
                    }
                    const activity =
                        isAppointmentTrackStatusInWork(currentStage.status) ||
                        isAppointmentTrackStatusPassed(trackInfo?.status)
                            ? await TrackPassingsService.getStagePassingInfo(
                                  payload
                              )
                            : await TrackPassingsService.startStagePassingWithActivity(
                                  {
                                      ...payload,
                                      body: {
                                          isRestart: Boolean(isRestart),
                                      },
                                  }
                              )

                    await TrackPassingsService.setCurrentStage({
                        body: payload,
                    })

                    updateInfoAboutPassing({
                        activity: activity.activityPassing,
                    })

                    if (
                        isAppointmentTrackStatusAvailableToPassing(
                            currentStage.status
                        ) ||
                        isAppointmentTrackStatusDraft(currentStage.status)
                    ) {
                        setInfoAssistantStageConfig({
                            type: InfoAssistantType.Stage,
                            event: TrackStageEvent.Start,
                            visible: true,
                            stageNumber: currentStage.stageNumber,
                            trackPassingId,
                        })
                    }
                }

                if (isMaterialTypeContent(materialType)) {
                    const content = await TrackPassingsService.startStagePassingWithContent(
                        {
                            appointmentId,
                            stageNumber,
                        }
                    )

                    updateInfoAboutPassing({
                        content: {
                            ...content,
                            stageTitle,
                        },
                    })
                }

                if (urlParams.action !== 'view') {
                    setTrackInfo((prevState) => {
                        if (!prevState) return

                        return {
                            ...prevState,
                            currentStageNumber: stageNumber,
                        }
                    })
                }

                setInfoAssistantTrackConfig(onSetInfoAssitantVisible)
                setInfoAssistantStageFinishConfig(onSetInfoAssitantVisible)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        [
            updateLoader,
            urlParams.action,
            appointmentId,
            trackInfo?.status,
            updateInfoAboutPassing,
            trackPassingId,
        ]
    )

    const getStagePassingInfo = useCallback(
        async (stageNumber?: number) => {
            if (!stageNumber) return

            const body = {
                appointmentId,
                stageNumber,
            }

            const eventData = await TrackPassingsService.getStagePassingInfo(
                body
            )

            await TrackPassingsService.setCurrentStage({ body })

            updateInfoAboutPassing({
                activity: eventData.activityPassing,
            })
        },
        [appointmentId, updateInfoAboutPassing]
    )

    const fetchTrackInfo = useCallback(
        async () => {
            try {
                updateLoader(true)

                // Необходимо в рамках SDO-2698, возможность прохождения трека по ссылке, передавая url-параметр start
                if (isTrackNeedToBeStarted(urlParams.action)) {
                    await TrackPassingsService.startPassing({
                        appointmentId,
                    })

                    history.replace(
                        `${ROUTE_NAMES.TRACKS_PASSING}/${appointmentId}`
                    )

                    updateInfoAssistantTrackConfig({
                        mode,
                        visible: true,
                        event: TrackEvent.Greeting,
                    })

                    return
                }

                const track = await TrackPassingsService.getInfo({
                    appointmentId,
                })
                setTrackInfo(track)

                const currentStage = getCurrentStage(
                    track.currentStageNumber,
                    track.stages
                )

                if (
                    track.currentStageNumber &&
                    isMaterialTypeActivity(currentStage?.materialType)
                ) {
                    getStagePassingInfo(track.currentStageNumber)
                }

                if (
                    !track.currentStageNumber &&
                    track.isTrackWithVisualisation
                ) {
                    updateInfoAboutPassing(undefined)
                }
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        // TODO: https://jira.it2g.ru/browse/SDO-4532
        // необходимо для корректной работы прохождения треков с визуализацией, для избежания цикличности (trackInfo?.currentStageNumber)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            updateLoader,
            urlParams.action,
            appointmentId,
            trackPassingId,
            history,
            updateInfoAssistantTrackConfig,
            updateInfoAboutPassing,
            currentStageNumber,
        ]
    )

    /** Обновление информации о прохождении трека
     * используется только в треках без визуализации,
     * обновляет инфу о треке по кнопке "Далее" в мероприятиях
     */
    const updateTrackInfo = useCallback(async () => {
        // устанавливаем текущий этап прохождения в 0, чтобы осуществился переход к следующему этапу
        await TrackPassingsService.setCurrentStage({
            body: {
                appointmentId,
                stageNumber: 0,
            },
        })
        setTrackInfo(
            await TrackPassingsService.getInfo({
                appointmentId,
            })
        )
    }, [appointmentId])

    const updateProgress = useCallback(() => {
        isVisibleProgress && getProgress({ appointmentId })
    }, [isVisibleProgress, getProgress, appointmentId])

    /** получение первичной информации о треке */
    useEffect(() => {
        if (urlParams.id) fetchTrackInfo()
    }, [fetchTrackInfo, urlParams.id])

    useEffect(() => {
        if (!trackInfo) return

        const values = mapTrackPassingData(trackInfo)

        form.setFieldsValue(values)
    }, [form, mode, trackInfo])

    const previousTrackInfo = usePrevious(trackInfo)

    useEffect(() => {
        if (!isEqual(previousTrackInfo?.stages, trackInfo?.stages)) {
            scrollToFirstAvailableStage(trackInfo?.stages)
        }
    }, [previousTrackInfo, trackInfo])

    useEffect(() => {
        updateProgress()
    }, [updateProgress])

    useEffect(() => {
        if (!trackInfo) return

        const hasQuestionnaire = trackInfo?.trackAdditionalInfo?.questionnaire
        const isQuestionnairePassed = trackInfo?.isQuestionnairePassed
        const hasPassedQuestionnaire = hasQuestionnaire && isQuestionnairePassed

        const isTrackWithoutVisualisation = !trackInfo.isTrackWithVisualisation
        const isTrackWithoutVisualisationPassed =
            isTrackWithoutVisualisation &&
            isAppointmentTrackStatusPassed(trackInfo.status)

        const isTrackWithoutVisualisationView =
            isTrackWithoutVisualisationPassed && urlParams.action === 'view'

        if (
            isTrackWithoutVisualisationPassed &&
            urlParams.action !== 'view' &&
            (!hasQuestionnaire || hasPassedQuestionnaire)
        ) {
            history.push(ROUTE_NAMES.YOUR_DEVELOPMENT)

            return
        }

        /** Первый не пройденный этап трека, все этапы могут быть пройдены */
        const firstNotPassedStage = getFirstNotPassedStage(trackInfo.stages)

        /** Первый пройденный этап трека */
        const firstPassedStage = getFirstPassedStage(trackInfo.stages)

        /** Текущий этап трека, может быть 0 */
        const currentStage = getCurrentStage(
            trackInfo.currentStageNumber,
            trackInfo.stages
        )

        const stage = isTrackWithoutVisualisationView
            ? trackInfo.stages[currentStageForViewIndex]
            : currentStage || firstNotPassedStage

        const isNeedToStartNextStage =
            !currentStage && isTrackWithoutVisualisation && !!firstPassedStage

        /** Для треков с визуализацией автостарт возможен только для текущего этапа */
        if (trackAutoStart || isNeedToStartNextStage) {
            startStagePassing({
                currentStage: isTrackWithoutVisualisation
                    ? stage
                    : currentStage,
            }).then(() => {
                setTrackAutoStart(false)
            })
        }
    }, [
        history,
        trackInfo,
        mode,
        startStagePassing,
        trackAutoStart,
        urlParams.id,
        fetchTrackInfo,
        urlParams.action,
        currentStageForViewIndex,
    ])

    const contentOrEventLayer = useMemo(() => {
        if (!infoAboutPassing) return

        const { content, activity } = infoAboutPassing

        const isShowInfoAssistant = trackInfo?.stages.every(
            (stage) =>
                isAppointmentTrackStatusAvailableToPassing(stage.status) ||
                isAppointmentTrackStatusDraft(stage.status)
        )

        const currentStage = trackInfo
            ? getCurrentStage(trackInfo.currentStageNumber, trackInfo.stages)
            : null

        if (activity) {
            return (
                <ErrorBoundary
                    fallbackComponent={
                        <FallbackSimple
                            {...LOCAL.ERRORS
                                .ADAPTATION_TRACK_EVENT_PASSING_VISUALIZATION}
                        />
                    }
                >
                    <ActivityLayer
                        data={activity}
                        updateInfoAboutPassing={updateInfoAboutPassing}
                        refetchTrackInfo={fetchTrackInfo}
                        onUpdateStageAssistantVisible={
                            updateStageAssisstantVisible
                        }
                        isTrackWithVisualisation={isTrackWithVisualisation}
                        themeBlockByContentId={themeBlockByContentId}
                        onThemeBlockByContentId={setThemeBlockByContentId}
                        appointmentStatus={trackInfo?.status}
                        isShowInfoAssistant={isShowInfoAssistant}
                        updateTrackInfo={updateTrackInfo}
                        switchViewToNextStage={switchViewToNextStage}
                        stageInfoAssistantProps={infoAssistantStageConfig}
                        updateProgress={updateProgress}
                        currentStagesId={currentStage?.id}
                        currentStageNumber={currentStageNumber}
                        trackPassingId={trackInfo?.id}
                    />
                </ErrorBoundary>
            )
        }

        if (content) {
            return (
                <ContentLayer
                    data={content}
                    updateInfoAboutPassing={updateInfoAboutPassing}
                    refetchTrackInfo={fetchTrackInfo}
                    isTrackWithVisualisation={isTrackWithVisualisation}
                    onThemeBlockByContentId={setThemeBlockByContentId}
                    updateTrackInfo={updateTrackInfo}
                    isShowInfoAssistant={isShowInfoAssistant}
                    updateProgress={updateProgress}
                />
            )
        }
    }, [
        infoAboutPassing,
        trackInfo,
        updateInfoAboutPassing,
        fetchTrackInfo,
        updateStageAssisstantVisible,
        isTrackWithVisualisation,
        themeBlockByContentId,
        updateTrackInfo,
        switchViewToNextStage,
        infoAssistantStageConfig,
        updateProgress,
        currentStageNumber,
    ])

    const handleFinishTrack = useCallback(() => {
        // TODO: https://jira.it2g.ru/browse/SDO-5024
        if (
            trackInfo &&
            trackInfo.stages.filter(
                (stage) => !isAppointmentTrackStatusPassed(stage.status)
            ).length <= 1
        ) {
            updateInfoAssistantTrackConfig({
                mode,
                event: TrackEvent.End,
                visible: true,
            })
        }
    }, [trackInfo, updateInfoAssistantTrackConfig])

    const handleFinishStage = useCallback<
        InfoAssistantEventsProps['onFinishStage']
    >(({ stageNumber, trackPassingId }) => {
        setInfoAssistantStageFinishConfig({
            stageNumber,
            trackPassingId,
            event: TrackStageEvent.End,
            type: InfoAssistantType.Stage,
            visible: true,
        })
    }, [])

    useEffect(() => {
        const events = {
            onFinishStage: handleFinishStage,
            onFinishTrack: handleFinishTrack,
        }

        registerInfoAssistantEvents(events)

        return () => {
            unRegisterInfoAssistantEvents(events)
        }
    }, [handleFinishStage, handleFinishTrack])

    return (
        <TrackPassingContextProvider updateLoader={updateLoader}>
            <Form id={FORM_IDS.TRACK_FORM} form={form}>
                <HiddenField fieldName="title" />

                <HiddenField fieldName="useInformationAssistant" />

                <HiddenField
                    fieldName={['trackCanvases', Resolutions.XXL, 'width']}
                />

                <HiddenField
                    fieldName={['trackCanvases', Resolutions.XXL, 'height']}
                />

                <HiddenField
                    fieldName={[
                        'trackCanvases',
                        Resolutions.XXL,
                        'trackCanvasBackgroundId',
                    ]}
                />

                <HiddenField
                    fieldName={[
                        'trackCanvases',
                        Resolutions.XXL,
                        'meta',
                        'currentBackgroundUrl',
                    ]}
                />

                <HiddenField fieldName={['trackCanvases']} />

                <HiddenField fieldName="stages" />

                <HiddenField fieldName="trackPassingId" />

                <HiddenField fieldName="trackBackgroundPicture" />

                {isTrackWithVisualisation && (
                    <TrackLayer
                        mode={mode}
                        trackInfo={trackInfo}
                        onUpdatePassageProcessInfo={updateInfoAboutPassing}
                        startStagePassing={startStagePassing}
                        passingType={trackInfo?.passingType}
                    />
                )}

                {infoAboutPassing && contentOrEventLayer}

                <InfoAssistantChecker>
                    <InfoAssistant {...infoAssistantTrackConfig} />

                    {isTrackWithVisualisation && (
                        <InfoAssistant {...infoAssisstantStageFinishConfig} />
                    )}
                </InfoAssistantChecker>

                {isStageModePassing(mode) && (
                    <div ref={elementRef}>
                        {trackInfo?.trackProgressVisualization
                            ?.isProgressVisible &&
                            progress && (
                                <TrackProgress
                                    data={progress}
                                    toggleRef={toggleElementVisibleRef}
                                    trackProgressVisualization={
                                        trackInfo?.trackProgressVisualization
                                    }
                                    appointmentId={appointmentId}
                                    showProgress={showProgress}
                                    onShowProgress={handleShowProgress}
                                />
                            )}

                        {trackInfo?.trackMenuVisualization?.isMenuVisible && (
                            <>
                                <TrackInfoMenu
                                    appointmentId={appointmentId}
                                    trackInfo={trackInfo}
                                    onToggleContent={handleToggleContent}
                                />

                                <Popup
                                    visible={openContent}
                                    footer={null}
                                    title={LOCAL.LABELS.SUBSTANCE}
                                    onCancel={handleHideContent}
                                >
                                    <TrackContentMenu
                                        startStagePassing={getStagePassingInfo}
                                        form={form}
                                        onThemeBlockByContentId={
                                            setThemeBlockByContentId
                                        }
                                        onHideContent={handleHideContent}
                                        infoAboutPassing={infoAboutPassing}
                                    />
                                </Popup>
                            </>
                        )}
                    </div>
                )}
            </Form>
        </TrackPassingContextProvider>
    )
})
