import React from 'react'
import { AppointmentContract, AppointmentsService } from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { FormContainer } from 'components/layouts'

import { AssessmentAssignmentForm } from './components'
import {
    CANCEL_BTN_OPTIONS,
    getPublishBtnOptions,
    getSaveBtnOptions,
} from './AssessmentAssignments.config'

/**
 * Создание назначения оценки
 */
export const AssessmentAssignmentFormContainer: React.FC<ComponentCommonProps> = ({
    formMode,
}) => (
    <FormContainer<AppointmentContract>
        formId={FORM_IDS.ASSESSMENT_ASSIGNMENT_FORM}
        formMode={formMode}
        formComponent={AssessmentAssignmentForm}
        requestMethod={AppointmentsService.getAppointment}
        cancelBtnOptions={CANCEL_BTN_OPTIONS}
        saveBtnOptions={getSaveBtnOptions}
        publishBtnOptions={getPublishBtnOptions}
    />
)
