import cn from 'classnames'
import React, { useCallback, useContext, useEffect } from 'react'
import {
    ActivityLessonPassingContentContract,
    TrackPassingsService,
    TrackStageContentContract,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import { ROW_GUTTER } from 'consts'
import { Spoiler } from 'components/shared/Spoiler'
import { UrlParamsCommonProps } from 'App.types'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { getBackgroundImageStyle } from 'utils'
import { useHttp } from 'hooks'
import { useParams } from 'react-router-dom'

import styles from './TrackContentMenu.module.scss'
import { TRACK_PASSING_EVENTS } from '../../TrackPassing.events'
import { TrackContentMenuProps } from './TrackContentMenu.types'
import { TrackPassingContext } from '../../TrackPassing.context'
import {
    customSpoilerTitleRender,
    getBlockTitle,
} from './TrackContentMenu.render'
import {
    getActivityClassName,
    getActivityTitle,
    getBlockClassName,
} from './TrackContentMenu.utils'

/** Окно с содержанием трека */
export const TrackContentMenu: React.FC<TrackContentMenuProps> = React.memo(
    ({ onHideContent }) => {
        const { store, updateStore } = useContext(TrackPassingContext)

        const { trackInfo } = store

        const { id } = useParams<UrlParamsCommonProps<'id'>>()
        const [getStages, stages] = useHttp(
            TrackPassingsService.getTrackContent
        )

        const startStage = useCallback(
            (stage: TrackStageContentContract) => () => {
                if (
                    !stage.isAvailable ||
                    trackInfo?.currentStageNumber === stage.stageNumber
                )
                    return

                eventEmitter.emit(
                    TRACK_PASSING_EVENTS.START_STAGE,
                    stage.stageNumber
                )

                onHideContent()
            },
            [onHideContent, trackInfo]
        )

        const startLesson = useCallback(
            (
                lesson: ActivityLessonPassingContentContract,
                stage: TrackStageContentContract,
                lessonIndex: number
            ) => () => {
                updateStore({
                    activeLessonIndex: lessonIndex,
                })

                onHideContent()

                eventEmitter.emit(
                    TRACK_PASSING_EVENTS.START_LESSON_FROM_CONTENT,
                    stage,
                    lesson
                )
            },
            [onHideContent, updateStore]
        )

        useEffect(() => {
            if (!id) return

            getStages({ appointmentId: Number(id) })
        }, [getStages, id])

        return (
            <>
                {stages?.map((stage) => (
                    <Row gutter={ROW_GUTTER} key={stage.id}>
                        <Col xs={3}>
                            {stage.contentIcon?.url && (
                                <div
                                    className={styles.icon}
                                    style={getBackgroundImageStyle(
                                        stage.contentIcon.url
                                    )}
                                />
                            )}
                        </Col>

                        <Col xs={21}>
                            <Spoiler
                                title={getBlockTitle(
                                    stage.title,
                                    stage.stageNumber
                                )}
                                className={cn(
                                    styles.spoiler,
                                    getBlockClassName(
                                        stage.stageStatus,
                                        stage.isAvailable
                                    )
                                )}
                                spoilerTitleRender={customSpoilerTitleRender(
                                    startStage(stage)
                                )}
                            >
                                {stage.lessons.map((lesson, index) => (
                                    <div
                                        key={lesson.lessonId}
                                        className={cn(
                                            styles.activity,
                                            getActivityClassName(
                                                lesson.passingStatus
                                            )
                                        )}
                                    >
                                        <Button
                                            type="link"
                                            className={styles.activityLink}
                                            onClick={startLesson(
                                                lesson,
                                                stage,
                                                index
                                            )}
                                        >
                                            {getActivityTitle(
                                                lesson.name,
                                                index
                                            )}
                                        </Button>
                                    </div>
                                ))}
                            </Spoiler>
                        </Col>
                    </Row>
                ))}
            </>
        )
    }
)
