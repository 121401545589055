import escape from 'lodash/escape'
import unescape from 'lodash/unescape'
import {
    AssessmentType,
    AttachmentContract,
    QuestionnaireContract,
    QuizType,
} from 'core/api'
import { Store } from 'antd/lib/form/interface'
import { TAG_VALUE_ATTRIBUTE } from 'components/controls/TextRedactor/TextRedactor.consts'
import {
    createFieldsRow,
    getValue,
    isAssessmentTypeExpert,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeTest,
    isResultCalculatingTypePassingPoints,
    normalizeAttachmentObject,
    normalizeDataForSelectAndRadio,
} from 'utils'

import { MIN_LIMIT_ANSWERS_COUNT } from '../QuestionForm/QuestionForm.consts'
import { QuestionVariationValueProps } from '../QuestionVariation/QuestionVariation.types'
import { QuestionnairesConstructorFormSubmitValues } from './QuestionnairesConstructorForm.types'
import {
    RespondentTypeIdDictionary,
    ResultCalculatingMethodIdDictionary,
    ResultEstimateScaleTypesDictionary,
} from './QuestionnairesConstructorForm.consts'

export const isRespondentTypeExpert = (
    respondentTypeId?: RespondentTypeIdDictionary
) => respondentTypeId === RespondentTypeIdDictionary.Expert

export const isRespondentTypeEstimated = (
    respondentTypeId?: RespondentTypeIdDictionary
) => respondentTypeId === RespondentTypeIdDictionary.Estimated

export const isCalculatingMethodMiddle = (
    assessmentResultCalculatingMethodId?: ResultCalculatingMethodIdDictionary
) =>
    assessmentResultCalculatingMethodId ===
    ResultCalculatingMethodIdDictionary.CompetencyAverage

export const calculateValuesByRules = (currentValues: Store) => {
    const { assessmentType } = currentValues

    if (
        isAssessmentTypeExpert(assessmentType) &&
        isRespondentTypeExpert(currentValues.respondentTypeId) &&
        isCalculatingMethodMiddle(
            currentValues.assessmentResultCalculatingMethodId
        )
    ) {
        return {
            ...currentValues,
            assessmentEstimateScaleTypeId:
                ResultEstimateScaleTypesDictionary.GradeScale05,
        }
    }

    if (
        isAssessmentTypeExpert(assessmentType) &&
        isRespondentTypeEstimated(currentValues.respondentTypeId)
    ) {
        return {
            ...currentValues,
            assessmentEstimateScaleTypeId:
                ResultEstimateScaleTypesDictionary.GradeScale05,
            assessmentResultCalculatingMethodId: null,
        }
    }

    if (isAssessmentTypeExpert(assessmentType)) {
        return {
            ...currentValues,
            respondentTypeId: RespondentTypeIdDictionary.Expert,
        }
    }

    if (!isAssessmentTypeExpert(assessmentType)) {
        return {
            ...currentValues,
            assessmentResultCalculatingMethodId:
                ResultCalculatingMethodIdDictionary.CompetencyAverage,
            respondentTypeId: RespondentTypeIdDictionary.NotSet,
            assessmentEstimateScaleTypeId:
                ResultEstimateScaleTypesDictionary.GradeScale05,
        }
    }
}

const getChooseVariantAttachment = (attachment?: AttachmentContract) => {
    if (attachment)
        return {
            previewVisible: false,
            previewTitle: '',
            previewImage: '',
            fileList: [
                {
                    ...normalizeAttachmentObject(attachment),
                    url: attachment.url,
                },
            ],
        }
}

/**
 * Получить начальные значения для конструктора опросных листов, для конкретного опросного листа
 * @param questionnaire опросный лист
 */
export const mapResponseToFormData = (
    questionnaire: QuestionnaireContract
) => ({
    structuralUnitIds: questionnaire.structuralUnits.map(
        normalizeDataForSelectAndRadio
    ),
    positionIds: questionnaire.positions?.map(normalizeDataForSelectAndRadio),
    status: questionnaire.status,
    name: questionnaire.name,
    description: questionnaire.description,
    id: questionnaire.id,
    type: questionnaire.type,
    sequence: questionnaire.sequence,
    randomQuestionsOrder: questionnaire.randomQuestionsOrder,
    competenceId: questionnaire.competence && [
        {
            value: questionnaire.competence?.id,
            key: String(questionnaire.competence?.id),
            label: questionnaire.competence?.name,
        },
    ],
    assessmentType:
        questionnaire.assessmentParameters?.assessmentType ||
        AssessmentType.Estimate_360,
    quizType: questionnaire.quizParameters?.quizType || QuizType.Feedback,
    testTypeId: questionnaire.testParameters?.testType?.id,
    respondentTypeId: questionnaire.assessmentParameters?.respondentType?.id,
    pollResultCalculatingMethodId:
        questionnaire.quizParameters?.resultCalculatingMethod?.id,
    assessmentResultCalculatingMethodId:
        questionnaire.assessmentParameters?.resultCalculatingMethod?.id,
    assessmentEstimateScaleTypeId:
        questionnaire.assessmentParameters?.scale?.id,
    maxPoints: questionnaire.resultEstimationParameters?.maxPoints,
    passingPoints: questionnaire.resultEstimationParameters?.passingPoints,
    resultAssessmentType: {
        resultCalculatingType:
            questionnaire.resultEstimationParameters?.resultCalculatingType,
        resultLevels: (questionnaire.resultEstimationParameters?.resultLevels
            .length &&
            questionnaire.resultEstimationParameters?.resultLevels?.map(
                (el) => ({
                    ...el,
                    ...createFieldsRow(),
                })
            )) || [createFieldsRow()],
    },
    forAllOrganizations: questionnaire.forAllOrganizations,
    forAllPositions: questionnaire.forAllPositions,
    questions: questionnaire.questions?.map((question) => ({
        id: question.id,
        pollEstimateScaleTypeId: question.criteriaScale?.scaleId,
        isActive: !question.isInactive,
        number: question.number,
        blockedByQuestionNumber: question.blockedByQuestionNumber,
        type: question.type,
        mandatoryQuestion: question.mandatoryQuestion,
        limitedAnswerTime: question.limitedAnswerTime,
        answerLimit: question.answerLimit,
        title: question.title,
        text: question.text,
        indicatorId:
            question.indicator &&
            normalizeDataForSelectAndRadio(question.indicator),
        attachments: question.attachments?.map((attachment) =>
            normalizeAttachmentObject(attachment)
        ),
        multiChoose: question.chooseVariant?.multiChoose,
        randomAnswerOrder: question.chooseVariant?.randomAnswerOrder,
        customAnswer: question.chooseVariant?.customAnswer,
        isAnswersCountLimited: question.chooseVariant?.isAnswersCountLimited,
        limitAnswersCount:
            question.chooseVariant?.limitAnswersCount ||
            MIN_LIMIT_ANSWERS_COUNT,
        answers: question.chooseVariant?.answers?.map((answer) => ({
            points: answer.points,
            isRightAnswer: answer.isRightAnswer,
            text: answer.text,
            attachment: getChooseVariantAttachment(answer.attachment),
            ...createFieldsRow(),
        })),
        fillEmptyTextAnswers: question.fillEmptyText?.answers.map((answer) => {
            const tempHTML = unescape(question.text)

            const regexp = new RegExp(
                `id="(.{9,12})".+?${TAG_VALUE_ATTRIBUTE}="(\\d*)"`,
                'g'
            )
            const matchAll = tempHTML.matchAll(regexp)

            const answersRelations = Array.from(matchAll).map((el) => ({
                id: el[1],
                number: el[2],
            }))

            return {
                ...answer,
                id: answersRelations.find(
                    (el) => Number(el.number) === answer.number
                )?.id,
            }
        }),
        calcAndScore: {
            calcForAnswer: question.fillEmptyText?.calcForAnswer,
            questionScore: question.fillEmptyText?.questionScore,
        },
        isInverseQuestion: question.criteriaScale?.isInverseQuestion,
        linkedQuestionNumber: question.criteriaScale?.linkedQuestionNumber,
    })),
})

/**
 * Добавление уровней к отправке, в зависимости от типа оценки
 * @param resultAssessmentType - тип оценки
 */
const getResultLevels = (
    resultAssessmentType?: QuestionnairesConstructorFormSubmitValues['resultAssessmentType']
) => {
    if (
        isResultCalculatingTypePassingPoints(
            resultAssessmentType?.resultCalculatingType
        )
    )
        return

    return resultAssessmentType?.resultLevels?.map((el, index) => ({
        name: el.name,
        level: index + 1,
        pointsFrom: el.pointsFrom,
        pointsTo: el.pointsTo,
    }))
}

/**
 * Получить значения для отправки на бэк
 * @param values текущие значения полей формы
 */
export const mapFormDataToRequest = (
    values: Partial<QuestionnairesConstructorFormSubmitValues>
) => {
    const {
        assessmentType,
        respondentTypeId,
        assessmentResultCalculatingMethodId,
        assessmentEstimateScaleTypeId,
        quizType,
        pollResultCalculatingMethodId,
        testTypeId,
        maxPoints,
        passingPoints,
        resultAssessmentType,
        competenceId,
        questions,
        structuralUnitIds,
        positionIds,
        ...restValues
    } = values

    return {
        ...restValues,
        competenceId: competenceId?.[0].value,
        structuralUnitIds: structuralUnitIds?.map(getValue),
        positionIds: positionIds?.map(getValue),
        assessmentParameters: {
            assessmentType,
            respondentTypeId,
            resultCalculatingMethodId: assessmentResultCalculatingMethodId,
            scaleId: assessmentEstimateScaleTypeId,
        },
        quizParameters: {
            quizType,
            resultCalculatingMethodId: pollResultCalculatingMethodId,
        },
        testParameters: {
            testTypeId,
        },
        resultEstimationParameters: {
            maxPoints,
            passingPoints,
            resultCalculatingType: resultAssessmentType?.resultCalculatingType,
            resultLevels: getResultLevels(resultAssessmentType),
        },
        questions: questions?.map(
            (
                {
                    attachments,
                    multiChoose,
                    randomAnswerOrder,
                    customAnswer,
                    answers,
                    isInverseQuestion,
                    linkedQuestionNumber,
                    calcAndScore,
                    fillEmptyTextAnswers,
                    text,
                    isAnswersCountLimited,
                    pollEstimateScaleTypeId,
                    limitAnswersCount,
                    indicatorId,
                    isActive,
                    blockedByQuestionNumber,
                    ...restProps
                },
                index
            ) => ({
                ...restProps,
                blockedByQuestionNumber:
                    blockedByQuestionNumber === 0
                        ? undefined
                        : blockedByQuestionNumber,
                isInactive: !isActive,
                indicatorId: indicatorId?.value,
                text: fillEmptyTextAnswers ? escape(text) : text,
                number: index + 1,
                attachmentIds: attachments?.map(
                    (el) => el.response?.id || el.uid
                ),
                chooseVariant: {
                    multiChoose,
                    randomAnswerOrder,
                    customAnswer,
                    answers: answers?.map(
                        ({ attachment, id, ...rest }, index) => ({
                            ...rest,
                            number: index + 1,
                            attachment: {
                                id: attachment?.fileList?.[0]?.response?.id,
                                fileName:
                                    attachment?.fileList?.[0]?.response
                                        ?.fileName,
                                url: attachment?.fileList?.[0]?.response?.url,
                            },
                        })
                    ),
                    isAnswersCountLimited,
                    limitAnswersCount: isAnswersCountLimited
                        ? limitAnswersCount
                        : undefined,
                },
                criteriaScale: {
                    scaleId:
                        pollEstimateScaleTypeId ||
                        assessmentEstimateScaleTypeId,
                    isInverseQuestion,
                    linkedQuestionNumber,
                },
                fillEmptyText: {
                    calcForAnswer: calcAndScore?.calcForAnswer ?? false,
                    questionScore: calcAndScore?.questionScore,
                    answers: fillEmptyTextAnswers?.map((el, index) => ({
                        number: index + 1,
                        correctAnswers: el.correctAnswers?.filter(
                            (item) => !!item
                        ),
                        score: el.score,
                    })),
                },
            })
        ),
    }
}

export const isResultAssessmentVisible = (formState?: Store) =>
    isQuestionnaireTypeTest(formState?.type) ||
    (isQuestionnaireTypeAssessment(formState?.type) &&
        isRespondentTypeEstimated(formState?.respondentTypeId) &&
        isAssessmentTypeExpert(formState?.assessmentType))

export const isAssessmentResultCalculatingMethodIdDisabled = (
    formState?: Store
) =>
    !isAssessmentTypeExpert(formState?.assessmentType) ||
    (isAssessmentTypeExpert(formState?.assessmentType) &&
        isRespondentTypeEstimated(formState?.respondentTypeId))

export const isAssessmentEstimateScaleTypeIdDisabled = (formState?: Store) =>
    !isAssessmentTypeExpert(formState?.assessmentType) ||
    (isRespondentTypeExpert(formState?.respondentTypeId) &&
        isCalculatingMethodMiddle(
            formState?.assessmentResultCalculatingMethodId
        )) ||
    isRespondentTypeEstimated(formState?.respondentTypeId)

/**
 * Функция изменения полей вопроса по индексу
 * @param questionIndex индекс изменяемого вопроса
 * @param newFieldData объект с новыми данными
 * @param allQuestions массив текущих данных вопросов
 */
export const changeQuestionFieldsByIndex = (
    questionIndex: number,
    newFieldData: Partial<QuestionVariationValueProps>,
    allQuestions?: QuestionVariationValueProps[]
) => ({
    questions: allQuestions?.map(
        (question: QuestionVariationValueProps, i: number) =>
            i === questionIndex
                ? {
                      ...question,
                      ...newFieldData,
                  }
                : question
    ),
})
