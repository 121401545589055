import stylesCanvasInfiniteScrollBlock from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.module.scss'
import React, { ReactNode } from 'react'
import { CanvasBackgroundRadio } from 'components/shared/CanvasImagesWithInfiniteScroll/components'
import { ImageCommonContract } from 'components/forms/forms.types'

/**
 * Маппинг изображений альбома фоновых картинок иконки трека
 * @param file объект изображения
 * @returns {ReactNode} контрол типа радио для выбора изображения
 */
export const canvasBgImgMapFn = (file: ImageCommonContract): ReactNode => (
    <div key={file.id} className={stylesCanvasInfiniteScrollBlock.col12}>
        <CanvasBackgroundRadio id={file.id} src={file.url} />
    </div>
)
