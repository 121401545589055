import React from 'react'
import cn from 'classnames'
import { ColumnsType } from 'antd/lib/table'
import { InputNumberEditControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import styles from './PastTextAnswersTable.module.scss'
import { PastTextAnswerVariantsControl } from './components/PastTextAnswerVariantsControl'
import {
    PastTextAnswersTableColumnsProps,
    PastTextAnswersTableValueProps,
} from './PastTextAnswersTable.types'

/**
 * Настройка отображения колонок таблицы
 */
export const getPastTextAnswersTableColumns = ({
    onScoreChange,
    formMode,
    calcForAnswer,
    disabled,
    field,
}: PastTextAnswersTableColumnsProps): ColumnsType<
    PastTextAnswersTableValueProps
> => [
    {
        title: LOCAL.LABELS.FIELD_NUMBER,
        dataIndex: 'number',
        key: 'number',
        align: 'center',
        render: (number, record, index) => index + 1,
    },
    {
        title: (
            <div className={cn({ 'required-control': !disabled })}>
                {LOCAL.LABELS.RIGHT_ANSWER}
            </div>
        ),
        dataIndex: 'answers',
        key: 'answers',
        render: (answers, record, index) => (
            <PastTextAnswerVariantsControl
                fieldName={[
                    field.name,
                    'fillEmptyTextAnswers',
                    index,
                    'correctAnswers',
                ]}
                formMode={formMode}
                disabled={disabled}
            />
        ),
    },
    {
        title: (
            <div
                className={cn({
                    'required-control': calcForAnswer && !disabled,
                })}
            >
                {LOCAL.LABELS.POINTS_COUNT}
            </div>
        ),
        dataIndex: 'score',
        key: 'score',
        align: 'center',
        render: (score, record) => (
            <InputNumberEditControl
                onChange={onScoreChange(record.id)}
                initialValue={calcForAnswer ? score : null}
                disabled={
                    !calcForAnswer || isFormModeView(formMode) || disabled
                }
                placeholderDisabled={!calcForAnswer ? LOCAL.NO : '0'}
                min={0}
                className={styles.inputNumberEditControl}
            />
        ),
    },
]
