import EventEmitter from 'eventemitter3'
import { Store } from 'App.types'

/** Утилита для тех случаев, когда не требуется хранить никакое состояние, для того чтобы навесить слушателей на определенное событие */
export const eventEmitter = new EventEmitter()

export const registerEvents = (
    eventsWithhandlers: Store<(...args: any[]) => void>
) => {
    Object.keys(eventsWithhandlers).forEach((event) => {
        eventEmitter.on(event, eventsWithhandlers[event])
    })
}

export const registerEventsOnce = (
    eventsWithhandlers: Store<(...args: any[]) => void>
) => {
    Object.keys(eventsWithhandlers).forEach((event) => {
        eventEmitter.once(event, eventsWithhandlers[event])
    })
}

export const unRegisterEvents = (
    eventsWithhandlers: Store<(...args: any[]) => void>
) => {
    Object.keys(eventsWithhandlers).forEach((event) => {
        eventEmitter.off(event, eventsWithhandlers[event])
    })
}
