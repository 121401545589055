import { LOCAL } from 'core/local'

export const CIRCLE_REPORT_PROGRESS_COLUMNS = [
    {
        title: LOCAL.LABELS.ROLE,
        dataIndex: 'name',
    },
    {
        title: LOCAL.LABELS.PLANNED_QUANTITY,
        dataIndex: 'countPlanned',
    },
    {
        title: LOCAL.LABELS.ACTUAL_QUANTITY,
        dataIndex: 'countFact',
    },
    {
        title: '% исп.',
        dataIndex: 'donePercentage',
    },
]
