import React from 'react'
import { LOCAL } from 'core/local'

import styles from './QuestionnaireFinalWindowContent.module.scss'
import { QuestionnaireFinalWindowContentProps } from './QuestionnaireFinalWindowContent.types'

export const QuestionnaireFinalWindowContent: React.FC<QuestionnaireFinalWindowContentProps> = React.memo(
    ({ answeredQuestions }) => (
        <>
            {answeredQuestions?.map((item, index) => (
                <div className={styles.wrapper} key={`${item.name}${index}`}>
                    <div className={styles.title}>
                        <div className={styles.circle} />

                        {`${LOCAL.LABELS.QUESTION} ${index + 1}`}
                    </div>

                    <div className={styles.question}>{item.name}</div>

                    <div>
                        <span className={styles.label}>
                            {LOCAL.LABELS.USER_ANSWER}
                        </span>

                        {Array.isArray(item.answer)
                            ? item.answer.join(', ')
                            : item.answer}
                    </div>
                </div>
            ))}
        </>
    )
)
