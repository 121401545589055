import React, { ReactNode } from 'react'
import { FnActionProps } from 'App.types'
import { ImageCommonContract } from 'components/forms/forms.types'

import styles from './CanvasImagesWithInfiniteScroll.module.scss'
import stylesCanvasBackgroundRadio from './components/CanvasBackgroundRadio/CanvasBackgroundRadio.module.scss'
import { CanvasBackgroundRadio, CanvasIconRadio } from './components'

/**
 * Коллбэк для fileList.map внутри `CanvasImagesWithInfiniteScroll`
 * @param file объект изображения
 * @param handleRemoveImg
 * @returns {ReactNode} контрол типа радио для выбора изображения
 */
export const canvasBgImgMapFn = (
    file: ImageCommonContract,
    handleRemoveImg?: FnActionProps
): ReactNode => (
    <div key={file.id} className={styles.col8}>
        <CanvasBackgroundRadio
            id={file.id}
            src={file.previewUrl || file.url}
            onRemoveImg={handleRemoveImg}
            className={stylesCanvasBackgroundRadio.canvasBackgroundRadio}
        />
    </div>
)

/**
 * Коллбэк для fileList.map внутри `CanvasImagesWithInfiniteScroll`
 * @param file объект изображения
 * @param handleRemoveImg
 * @returns {ReactNode} контрол типа радио для выбора изображения
 */
export const canvasIconMapFn = (
    file: ImageCommonContract,
    handleRemoveImg?: FnActionProps
): ReactNode => (
    <div key={file.id} className={styles.col6}>
        <CanvasIconRadio
            id={file.id}
            src={file.url}
            onRemoveImg={handleRemoveImg}
        />
    </div>
)
