import React from 'react'

import styles from './FallbackSimple.module.scss'
import { FallbackSimpleProps } from './FallbackSimple.types'

export const FallbackSimple: React.FC<FallbackSimpleProps> = React.memo(
    ({ title, description }) => (
        <div className={styles.wrapper}>
            {title && <h3>{title}</h3>}

            {description && <p>{description}</p>}
        </div>
    )
)
