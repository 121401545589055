import React, { useReducer } from 'react'
import { CKEditorAdapter } from 'components/shared'
import {
    DEFAULT_STYLES,
    TOOLBAR_OPTIONS,
} from 'components/shared/CKEditorAdapter/CKEditorAdapter.config'
import { FormItemAdapter } from 'components/controls'
import { LOCAL } from 'core/local'
import { editorReducer } from 'components/shared/CKEditorAdapter/CKEditorAdapter.utils'
import { isFormModeView } from 'utils'

import { InformationBlockProps } from './InformationBlock.types'

export const InformationBlock: React.FC<InformationBlockProps> = React.memo(
    ({ fieldName, disabled, formMode }) => {
        const [, dispatch] = useReducer(editorReducer, {
            styles: DEFAULT_STYLES,
        })

        return (
            <FormItemAdapter
                fieldName={[fieldName, 'informationBlockText']}
                label={LOCAL.LABELS.MAIN_INFO}
                required
            >
                <CKEditorAdapter
                    dispatchEvent={dispatch}
                    toolbar={TOOLBAR_OPTIONS}
                    height="30vh"
                    readOnly={disabled || isFormModeView(formMode)}
                />
            </FormItemAdapter>
        )
    }
)
