import React from 'react'
import { CustomControlCommonProps } from 'App.types'
import { TEXT_ALIGN_OPTION_MAPPED } from 'consts'

import styles from './TextAlignControl.module.scss'
import { RadioButtonControl } from '../RadioControl'

/**
 * Контрол для выбора типа выравнения текста
 */
export const TextAlignControl: React.FC<CustomControlCommonProps<
    any
>> = React.memo((props) => (
    <RadioButtonControl
        {...props}
        className={styles.wrapper}
        values={TEXT_ALIGN_OPTION_MAPPED}
        buttonStyle="solid"
    />
))
