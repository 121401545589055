import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CompetenciesService } from 'core/api'
import {
    GroupedCompetenciesGroupValueProps,
    getGroupedCompetencies,
} from 'utils'
import { LOCAL } from 'core/local'
import { usePrevious } from 'hooks'

import styles from './CompetenceItemsControl.module.scss'
import {
    CompetenceItemsControlProps,
    PositionProfileCompetenceOptionalContract,
} from './CompetenceItemsControl.types'
import { CompetenciesPanel } from '../CompetenciesPanel'
import { PositionProfileCompetenceCustomContract } from '../JobProfileForm/JobProfileForm.types'
import { checkCompetenceItemsToCache } from './CompetenceitemsControl.utils'

/** Компонент кастомного контрола для работы с единицами компетенций внутри формы профиля должности */
export const CompetenceItemsControl: React.FC<CompetenceItemsControlProps> = React.memo(
    ({
        competenceGroupsIds,
        onChange,
        formMode,
        initialCompetencies,
        adjacentCompetencies,
        form,
    }) => {
        /** Состояние со всеми данными по компетенциям, которые были отрендерены когда либо */
        const [cachedCompetencies, setCachedCompetencies] = useState<
            PositionProfileCompetenceCustomContract[]
        >()

        /** Ids текущих компетенций для показа пользователю */
        const [currentCompetenceIds, setCurrentCompetenceIds] = useState<
            number[]
        >()

        const previousCompetenceGroupsIdsState = usePrevious(
            competenceGroupsIds
        )

        const previousAdjacentCompetenciesState = usePrevious(
            adjacentCompetencies
        )

        /**
         *  Функция инициализации изменения состояния компонента и состояния формы для текущего
         * поля
         *  @param value - значение обновленного состояния
         *  @param currentCompetenceIds - коллекция ids компетенций, которые должны быть показаны
         *     пользователю
         *  @param isReset флаг сброса уровня компетенции
         */
        const triggerChanges = useCallback(
            (
                value: PositionProfileCompetenceCustomContract[],
                currentCompetenceIds: number[],
                isReset?: boolean
            ) => {
                setCachedCompetencies((prev) =>
                    checkCompetenceItemsToCache(prev, value, isReset)
                )

                const competenceIdsToSave = [
                    ...currentCompetenceIds,
                    ...adjacentCompetencies.map((el) => el.id),
                ]

                onChange?.(
                    cachedCompetencies
                        ?.map((el) => ({
                            id: el.id,
                            competenceLevel: el.competenceLevel,
                            isAdjacent: el.isAdjacent,
                        }))
                        .filter((competence) =>
                            competenceIdsToSave?.includes(competence.id)
                        )
                )
            },
            [adjacentCompetencies, cachedCompetencies, onChange]
        )

        /**
         * Обработчик изменения уровня компетенции
         * @param value значение уровня
         * @param id идентификатор компетенции
         * @param isReset флаг сброса уровня компетенции
         */
        const handleCompetenciesLevelChange = useCallback(
            (value?: number, id?: string, isReset?: boolean) => {
                if (!id) return

                const updatedCacheData = cachedCompetencies?.reduce(
                    (acc: PositionProfileCompetenceCustomContract[], el) => {
                        if (el.id === Number(id)) {
                            return [
                                ...acc,
                                {
                                    ...el,
                                    competenceLevel: value,
                                },
                            ]
                        }

                        return acc
                    },
                    []
                )

                if (updatedCacheData)
                    triggerChanges(
                        updatedCacheData,
                        currentCompetenceIds || [],
                        isReset
                    )
            },
            [cachedCompetencies, triggerChanges, currentCompetenceIds]
        )

        /**
         * Рендер единиц компетенций вместе с контролом для изменения их уровня
         * @param competencies - коллекция компетенций для рендера
         */
        const getCompetenciesRender = useCallback(
            (
                competencies?: GroupedCompetenciesGroupValueProps<
                    PositionProfileCompetenceCustomContract
                >[]
            ) => {
                if (!competencies) return

                return competencies.map((group) => (
                    <CompetenciesPanel
                        key={group.groupId}
                        groupData={group}
                        onCompetenciesLevelChange={
                            handleCompetenciesLevelChange
                        }
                        competenceGroupsIds={competenceGroupsIds}
                        formMode={formMode}
                        form={form}
                    />
                ))
            },
            [competenceGroupsIds, form, formMode, handleCompetenciesLevelChange]
        )

        /**
         * Запрос компетенций
         * @param competenceGroupsIds - идентификаторы групп компетенций
         * @param adjacentCompetenciesToMerge - коллекция смежных компетенций для слияния
         */
        const fetchCompetenceItems = useCallback(
            async (
                competenceGroupIds: number[],
                adjacentCompetenciesToMerge: PositionProfileCompetenceOptionalContract[]
            ) => {
                try {
                    const fetchedCompetencies = await CompetenciesService.getByCompetenceGroupIds(
                        {
                            body: competenceGroupIds,
                        }
                    )
                    const mergedCompetencies = [
                        ...fetchedCompetencies,
                        ...adjacentCompetenciesToMerge,
                    ]

                    const competenceIds = mergedCompetencies.map((el) => el.id)

                    setCurrentCompetenceIds(competenceIds)

                    triggerChanges(mergedCompetencies, competenceIds)
                } catch (error) {
                    console.error(error)
                }
            },
            [triggerChanges]
        )

        /** Подготовленные данные по компетенциям, на основе которых будет осуществлен рендер */
        const competenciesRenderData = useMemo(
            () =>
                cachedCompetencies?.filter((el) =>
                    currentCompetenceIds?.includes(el.id)
                ),
            [cachedCompetencies, currentCompetenceIds]
        )

        const competenceGroups = useMemo(() => {
            const groups = getGroupedCompetencies(competenciesRenderData)

            const sortedGroups = orderBy(groups, ['order', 'groupName']).map(
                (group) => ({
                    ...group,
                    children: orderBy(group.children, ['order', 'name']),
                })
            )

            return sortedGroups
        }, [competenciesRenderData])

        /** Запрос данных по компетенциям со слиянием смежных компетенций при изменении выбранных групп компетенций */
        useEffect(() => {
            if (
                isEqual(
                    competenceGroupsIds,
                    previousCompetenceGroupsIdsState
                ) &&
                isEqual(adjacentCompetencies, previousAdjacentCompetenciesState)
            )
                return

            if (!competenceGroupsIds?.length && !adjacentCompetencies?.length) {
                setCurrentCompetenceIds([])

                return
            }

            fetchCompetenceItems(
                competenceGroupsIds || [],
                adjacentCompetencies
            )
        }, [
            previousAdjacentCompetenciesState,
            competenceGroupsIds,
            fetchCompetenceItems,
            previousCompetenceGroupsIdsState,
            adjacentCompetencies,
        ])

        /** Установка начальных значений в состояние кешированных компетенций */
        useEffect(() => {
            if (!initialCompetencies) return

            setCachedCompetencies((prevState) =>
                checkCompetenceItemsToCache(prevState, initialCompetencies)
            )
        }, [setCachedCompetencies, initialCompetencies])

        return (
            <div>
                {!!currentCompetenceIds?.length ? (
                    getCompetenciesRender(competenceGroups)
                ) : (
                    <div className={styles.empty}>
                        {`${LOCAL.LABELS.COMPETENCIES_LIST_EMPTY}...`}
                    </div>
                )}
            </div>
        )
    }
)
