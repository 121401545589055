import React, { FC } from 'react'
import {
    DevelopmentGoals,
    ErrorPage,
    TrackAssignments,
    TrackPassing,
} from 'components/pages'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS } from 'consts'
import { TrackAssignmentFormContainer } from 'components/pages/TrackAssignments/TrackAssignmentFormContainer'
import { TrackPassingContextProvider } from 'components/pages/TrackPassing/TrackPassing.context'
import { TrackPassing as TrackPassingV2 } from 'components/pages/TrackPassing'
import { TracksUniqueRouting } from 'components/pages/TracksUnique'
import { UserPermission } from 'core/api'

import { AdaptationTrackRouting } from './TrackAdaptationRouting'
import { TrackDevelopmentRouting } from './TrackDevelopmentRouting'

export const TracksRouting: FC = React.memo(() => (
    <Switch>
        <Route path={ROUTE_NAMES.TRACKS_UNIQUE}>
            <TracksUniqueRouting />
        </Route>

        <Route path={`${ROUTE_NAMES.TRACKS_PASSING}/:id/:action?`} exact>
            <TrackPassing />
        </Route>

        <Route path={`${ROUTE_NAMES.TRACKS_PASSING_v2}/:id/:action?`} exact>
            <TrackPassingContextProvider>
                <TrackPassingV2 />
            </TrackPassingContextProvider>
        </Route>

        <ProtectedRoute
            requiredPermissions={TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.TRACKS_ASSIGNMENTS_VIEW}/:id`}
        >
            <TrackAssignmentFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.TrackAppointmentSave,
            ]}
            path={`${ROUTE_NAMES.TRACKS_ASSIGNMENTS_COPY}/:id`}
        >
            <TrackAssignmentFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.TrackAppointmentSave,
            ]}
            path={`${ROUTE_NAMES.TRACKS_ASSIGNMENTS_EDIT}/:id`}
        >
            <TrackAssignmentFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.TrackAppointmentSave,
            ]}
            path={ROUTE_NAMES.TRACKS_ASSIGNMENTS_CREATE}
        >
            <TrackAssignmentFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.TrackAppointmentsRead,
            ]}
            path={ROUTE_NAMES.TRACKS_ASSIGNMENTS}
        >
            <TrackAssignments />
        </ProtectedRoute>

        <Route path={ROUTE_NAMES.TRACKS_ADAPTATION}>
            <AdaptationTrackRouting />
        </Route>

        <Route path={ROUTE_NAMES.TRACKS_DEVELOPMENT}>
            <TrackDevelopmentRouting />
        </Route>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.PurposesRead,
            ]}
            path={ROUTE_NAMES.DEVELOPMENT_GOALS}
        >
            <DevelopmentGoals />
        </ProtectedRoute>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
