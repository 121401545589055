import { SetPictureFileProps } from './PicturesWallControl.types'

export const getBase64 = (file: File | Blob) =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            if (typeof reader.result === 'string') resolve(reader.result)
            else reject(new Error())
        }
        reader.onerror = (error) => reject(error)
    })

export const setPictureFile = ({
    setPicturesWallData,
    onChange,
    result,
}: SetPictureFileProps) => {
    setPicturesWallData(result)

    onChange?.(result)
}
