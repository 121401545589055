import cn from 'classnames'
import last from 'lodash/last'
import React, {
    SyntheticEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { API_BASE_URL } from 'consts/env.consts'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ReactSVG } from 'react-svg'
import {
    TrackEvent,
    TrackPassingsService,
    TrackPictureContract,
    TrackStageEvent,
} from 'core/api'
import {
    eventEmitter,
    registerEvents,
    registerEventsOnce,
    unRegisterEvents,
} from 'core/helpers/eventEmitter'
import { getBackgroundImageStyle, showElem } from 'utils'

import infoAssistantAvatar from './img/avatar.png'
import marker from './img/marker.svg'
import styles from './InfoAssistant.module.scss'
import { AssistantsState, InfoAssistantProps } from './InfoAssistant.types'
import { INFO_ASSISTANT_EVENT } from './InfoAssistant.events'

export const InfoAssistant: React.FC<InfoAssistantProps> = React.memo(
    ({ active }) => {
        const [assistants, setAssistants] = useState<AssistantsState[]>()

        // TODO: временная мера, после доработки бэка будет отдельный метод для упроавления активностью событий
        const isTrackStarted = useRef(false)

        const lastAssistant = last(assistants)

        const handleClick = useCallback(
            (event: SyntheticEvent) => {
                const value = event?.currentTarget.getAttribute('value')

                if (!value) return

                const assistantOptions = assistants?.find(
                    (el) => el.event === value
                )

                eventEmitter.emit(
                    INFO_ASSISTANT_EVENT.HIDE,
                    assistantOptions?.event
                )
            },
            [assistants]
        )

        const hideAssistant = useCallback((event?: string) => {
            setAssistants((prev) => prev?.filter((el) => el.event !== event))
        }, [])

        useEffect(() => {
            const getTrackAssistant = async (
                appointmentId: number,
                trackEvent: TrackEvent
            ) => {
                try {
                    const assistant = await TrackPassingsService.getTrackAssistantMessage(
                        {
                            appointmentId,
                            trackEvent,
                        }
                    )

                    setAssistants((prev) => [
                        ...(prev || []),
                        { ...assistant, event: `track${assistant.event}` },
                    ])
                } catch (error) {
                    console.error(error)
                }
            }

            const getStageAssistant = async (
                trackPassingId: number,
                trackStageNumber: number,
                trackStageEvent: TrackStageEvent
            ) => {
                try {
                    const assistant = await TrackPassingsService.getTrackStageAssistantMessage(
                        {
                            body: {
                                trackPassingId,
                                trackStageEvent,
                                trackStageNumber,
                            },
                        }
                    )

                    setAssistants((prev) => [
                        ...(prev || []),
                        { ...assistant, event: `stage${assistant.event}` },
                    ])
                } catch (error) {
                    console.error(error)
                }
            }

            const getMockAssistant = async () => {
                setAssistants([
                    {
                        message: LOCAL.LABELS.INFORMATION_ASSISTANT,
                        illustration: {
                            url: infoAssistantAvatar,
                        } as TrackPictureContract,
                    },
                ])
            }

            // TODO: обработчик будет удален, после доработки бэка, аргумент appointmentId, а также вызов внутри функции getTrackAssistant,
            // являются временным решением, пока бэк не отдает всю инфу по помощнику сразу
            // нам нужна 100% гарантия, что стейт будет заполняться в нужной последовательности
            // в связи с асинхронностью
            const handleStageActivityStart = async (
                trackPassingId: number,
                trackStageNumber: number,
                appointmentId: number
            ) => {
                hideAssistant('trackGreeting')

                eventEmitter.emit(
                    INFO_ASSISTANT_EVENT.STAGE_ACTIVITY_OR_CONTENT_START,
                    trackPassingId,
                    trackStageNumber,
                    appointmentId
                )
            }

            // TODO:  обработчик будет удален, после доработки бэка, аргумент appointmentId, а также вызов внутри функции getTrackAssistant,
            // являются временным решением, пока бэк не отдает всю инфу по помощнику сразу
            // нам нужна 100% гарантия, что стейт будет заполняться в нужной последовательности
            // в связи с асинхронностью
            const handleStageActivityOrContentStart = async (
                trackPassingId: number,
                trackStageNumber: number,
                appointmentId: number
            ) => {
                await getTrackAssistant(appointmentId, TrackEvent.Start)

                if (!isTrackStarted.current) {
                    await getStageAssistant(
                        trackPassingId,
                        trackStageNumber,
                        TrackStageEvent.Start
                    )

                    isTrackStarted.current = true
                }
            }

            const handleStageStart = (
                appointmentId: number,
                trackStageNumber: number
            ) => {
                getStageAssistant(
                    appointmentId,
                    trackStageNumber,
                    TrackStageEvent.Start
                )
            }

            const handleStageEnd = (
                appointmentId: number,
                trackStageNumber: number
            ) => {
                getStageAssistant(
                    appointmentId,
                    trackStageNumber,
                    TrackStageEvent.End
                )
            }

            const handleTrackGreeting = (appointmentId: number) => {
                getTrackAssistant(appointmentId, TrackEvent.Greeting)
            }

            const handleTrackStart = (appointmentId: number) => {
                getTrackAssistant(appointmentId, TrackEvent.Start)
            }

            const handleTrackEnd = (appointmentId: number) => {
                getTrackAssistant(appointmentId, TrackEvent.End)
            }

            const events = {
                [INFO_ASSISTANT_EVENT.PREVIEW]: getMockAssistant,
                [INFO_ASSISTANT_EVENT.HIDE]: hideAssistant,
                [INFO_ASSISTANT_EVENT.STAGE_START]: handleStageStart,
                [INFO_ASSISTANT_EVENT.STAGE_END]: handleStageEnd,
                [INFO_ASSISTANT_EVENT.STAGE_ACTIVITY_START]: handleStageActivityStart,
                [INFO_ASSISTANT_EVENT.STAGE_ACTIVITY_OR_CONTENT_START]: handleStageActivityOrContentStart,
            }

            registerEventsOnce({
                [INFO_ASSISTANT_EVENT.TRACK_GREETING]: handleTrackGreeting,
                [INFO_ASSISTANT_EVENT.TRACK_START]: handleTrackStart,
                [INFO_ASSISTANT_EVENT.TRACK_END]: handleTrackEnd,
            })

            registerEvents(events)

            return () => {
                unRegisterEvents(events)
            }
        }, [hideAssistant])

        return (
            <div
                className={cn(
                    styles.wrapper,
                    showElem(active && !!assistants?.length)
                )}
            >
                <div
                    className={styles.avatar}
                    style={getBackgroundImageStyle(
                        lastAssistant?.illustration?.url
                    )}
                />

                <ReactSVG
                    src={marker}
                    wrapper="span"
                    className={styles.marker}
                />

                <div className={styles.messages}>
                    {assistants?.map((assistant) => (
                        <div
                            className={styles.messageItem}
                            key={assistant.event}
                        >
                            {assistant.message}

                            {assistant.greetingVideo && (
                                <a
                                    href={`${API_BASE_URL}${assistant.greetingVideo.launchUrl}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button className={styles.watchVideo}>
                                        {LOCAL.ACTIONS.WATCH_VIDEO}
                                    </Button>
                                </a>
                            )}

                            <IconsAdapter
                                iconType="CustomIconClose"
                                className={styles.close}
                                onClick={handleClick}
                                value={assistant.event}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    }
)
