import React from 'react'
import { Button } from 'components/shared/Button'
import { CERTIFICATE_KIND, CERTIFICATE_PERMISSIONS } from 'consts'
import { CertificateSearchResultContract } from 'core/api'
import { Col, Row, Upload } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import {
    DictionaryPageActionsProps,
    TOOLBAR_CONTENT_SETTINGS,
    ToolbarContentRenderChildrenProps,
    defaultSorter,
} from 'components/layouts'
import { FnActionRequiredProps } from 'App.types'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { RcCustomRequestOptions } from 'types'
import { formatDate, idToUrlParam } from 'utils'

import {
    ExternalCertificatesTableActions,
    InternalCertificatesTableActions,
} from './components'
import { TabsDataProps } from './Certificates.types'
import { TabsKeys } from './Certificates.consts'

/** Получить данные табов реестров сертификатов */
export const getTabsData = (userId?: string): TabsDataProps[] => [
    {
        text: LOCAL.LABELS.INNER,
        key: TabsKeys.INTERNAL_CERTIFICATES,
        path: `${ROUTE_NAMES.INTERNAL_CERTIFICATES}${idToUrlParam(userId)}`,
    },
    {
        text: LOCAL.LABELS.OUTER,
        key: TabsKeys.EXTERNAL_CERTIFICATES,
        path: `${ROUTE_NAMES.EXTERNAL_CERTIFICATES}${idToUrlParam(userId)}`,
    },
]

/** Колонки реестра таблицы для внутренних сертификатов */
export const INTERNAL_CERTIFICATES_PAGE_TABLE_COLUMNS = (
    updateLoader?: FnActionRequiredProps<boolean>
): ColumnsType<CertificateSearchResultContract> => [
    {
        title: LOCAL.LABELS.KIND,
        dataIndex: 'kind',
        key: 'kind',
        render: (_, record) => CERTIFICATE_KIND[record.kind],
    },
    {
        title: LOCAL.LABELS.DATE,
        dataIndex: 'issueDate',
        key: 'issueDate',
        render: formatDate,
        sorter: defaultSorter,
    },
    {
        key: 'actions',
        width: 150,
        render: (tableRow) => (
            <InternalCertificatesTableActions
                updateLoader={updateLoader}
                tableRow={tableRow}
            />
        ),
    },
]

/** Колонки реестра таблицы для внешних сертификатов */
export const EXTERNAL_CERTIFICATES_PAGE_TABLE_COLUMNS: ColumnsType<CertificateSearchResultContract> = [
    {
        title: LOCAL.LABELS.DATE,
        dataIndex: 'issueDate',
        key: 'issueDate',
        render: formatDate,
        sorter: defaultSorter,
    },
    {
        key: 'actions',
        width: 150,
        render: (tableRow) => (
            <ExternalCertificatesTableActions tableRow={tableRow} />
        ),
    },
]

/** Получить колонки реестра таблицы для внутренних или внешних сертификатов */
export const getCertificatesPageTableColumns = (
    key: TabsKeys,
    { updateLoader }: DictionaryPageActionsProps
): ColumnsType<CertificateSearchResultContract> => [
    {
        title: LOCAL.LABELS.CERTIFICATE_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.CERTIFICATE_NUMBER,
        dataIndex: 'number',
        key: 'number',
        sorter: defaultSorter,
    },
    ...(key === TabsKeys.INTERNAL_CERTIFICATES
        ? INTERNAL_CERTIFICATES_PAGE_TABLE_COLUMNS(updateLoader)
        : EXTERNAL_CERTIFICATES_PAGE_TABLE_COLUMNS),
]

/** Функция рендера тулбара для страницы внутренних сертификатов */
export const renderInternalCertificatesPageToolbar = (
    onUploadFile: (request: RcCustomRequestOptions) => void,
    isLoading: boolean,
    gutterResponsive: number
) => ({ titleComponent, filterSwitch }: ToolbarContentRenderChildrenProps) => (
    <Row {...TOOLBAR_CONTENT_SETTINGS.PROPS} className="pt-20 pb-20">
        <Col>{titleComponent}</Col>

        <Col>
            <Row gutter={gutterResponsive} align="middle">
                <Col>{filterSwitch}</Col>

                <Col>
                    <HasPermissions
                        requiredPermissions={CERTIFICATE_PERMISSIONS}
                    >
                        <Upload
                            showUploadList={false}
                            customRequest={onUploadFile}
                        >
                            <Button disabled={isLoading} loading={isLoading}>
                                {LOCAL.ACTIONS.UPLOAD_TEMPLATE}
                            </Button>
                        </Upload>
                    </HasPermissions>
                </Col>
            </Row>
        </Col>
    </Row>
)
