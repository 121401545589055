import React from 'react'

import styles from './InfoBlock.module.scss'

export const renderTaskCounter = (count: number, text: string) => (
    <div>
        <div className={styles.counterLabel}>{`${text}:`}</div>
        <div className={styles.count}>{count}</div>
    </div>
)
