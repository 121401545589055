import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Chart } from 'chart.js'
import { CheckboxControl } from 'components/controls'
import { Circle360ReportService } from 'core/api'
import { Diagram, NoChartData } from 'components/shared'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './SpiderWebDiagramAssessment360.module.scss'
import { AcceptedKeysProps } from './SpiderWebDiagramAssessment360.types'
import {
    DATASET_CONFIG,
    LABELS_CHECKED,
    radarChartOptions,
} from './SpiderWebDiagramAssessment360.config'
import { getRadarChartDatasetItem } from './SpiderWebDiagramAssessment360.utils'

// TODO https://jira.it2g.ru/browse/SDO-3563
/** Отчет по оценке 360. Паутинка */
export const SpiderWebDiagramAssessment360: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(
        Circle360ReportService.getSpiderWebDiagramAssessment360
    )
    const [chart, setChart] = useState<Chart>()

    const [labelsChecked, setLabelsChecked] = useState<{
        [key: string]: boolean
    }>(LABELS_CHECKED)

    const displayDiagram = data && data.length >= 3

    /** Набор данных для инициализации компонента диаграммы */
    const radarChartData = useMemo(() => {
        if (data) {
            return {
                labels: data?.map((el) => el.competence.name),
                datasets: DATASET_CONFIG.map((el) =>
                    getRadarChartDatasetItem(el, data)
                ),
            }
        }
    }, [data])

    /**
     * Обработчик отображения данных диаграммы
     * @param key ключ набора данных, которые надо скрыть\показать
     */
    const handleChangeHiddenLabel = useCallback(
        (key: AcceptedKeysProps) => (checked?: boolean) => {
            chart?.config.data?.datasets?.forEach((data: any) => {
                if (data.dataKey === key) {
                    data.hidden = !checked

                    setLabelsChecked((prev) => ({ ...prev, [key]: !!checked }))
                }
                chart.update()
            })
        },
        [chart]
    )

    const labelsList = useMemo(
        () =>
            DATASET_CONFIG.map((el) => (
                <CheckboxControl
                    key={el.dataKey}
                    className={el.className}
                    defaultChecked
                    onChange={handleChangeHiddenLabel(el.dataKey)}
                    checked={labelsChecked[el.dataKey]}
                >
                    {el.label}
                </CheckboxControl>
            )),
        [handleChangeHiddenLabel, labelsChecked]
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return displayDiagram ? (
        <div className={styles.wrapper}>
            <div className={styles.legends}>{labelsList}</div>

            <div className={styles.diagram}>
                <Diagram
                    type="radar"
                    data={radarChartData}
                    options={radarChartOptions}
                    getChartInstance={setChart}
                />
            </div>
        </div>
    ) : (
        <NoChartData />
    )
})
