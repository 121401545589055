import React, { useEffect } from 'react'
import { Circle360ReportService } from 'core/api'
import { Title } from 'components/shared'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './ObjectInformationAssessment360.module.scss'

/** Отчет по оценке 360. Общая информация */
export const ObjectInformationAssessment360: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(
        Circle360ReportService.getObjectInformationAssessment360
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return (
        <div className={styles.wrapper}>
            <div className={styles.position}>{data?.position?.name}</div>

            <Title type="h5" text={data?.candidateFullName} bottomIndent="20" />

            <div className={styles.organization}>{data?.organizationName}</div>

            {data?.unitName && (
                <div className={styles.unit}>{data.unitName}</div>
            )}
        </div>
    )
})
