import React from 'react'
import { Col, Form, Row } from 'antd'
import { HiddenField, InputNumberControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROW_GUTTER } from 'consts'

import styles from './QuestionnairesEvaluationList.module.scss'
import {
    INPUT_NUMBER_MAX_VALUE,
    INPUT_NUMBER_MIN_VALUE,
    INPUT_NUMBER_PLACEHOLDER,
} from '../../AssessmentEvaluation.consts'
import { QuestionnairesEvaluationListProps } from './QuestionnairesEvaluationList.types'

/** Оценка опросных листов */
export const QuestionnairesEvaluationList: React.FC<QuestionnairesEvaluationListProps> = React.memo(
    ({ field, evaluationListData, fieldIndex }) => (
        <Row key={field.key} gutter={ROW_GUTTER}>
            <HiddenField fieldName={[field.name, 'questionnaireId']} />

            <Col xs={8}>
                <Row gutter={5} className={styles.questionnaireName}>
                    <Col>{`${fieldIndex + 1}.`}</Col>

                    <Col>
                        <div>
                            {
                                evaluationListData?.questionnaireResults[
                                    fieldIndex
                                ].questionnaireName
                            }
                        </div>

                        <div className={styles.competence}>
                            {`${LOCAL.LABELS.COMPETENCE}: `}

                            {evaluationListData?.competenceName}
                        </div>
                    </Col>
                </Row>
            </Col>

            <Col xs={8}>
                <Row justify="space-between" align="middle">
                    <Col>
                        {/* TODO: SDO-188*/}

                        <Link to={'/#s'}>{LOCAL.LABELS.ASSESSMENT_VIEW}</Link>
                    </Col>

                    <Col>
                        <Form.Item name={[field.name, 'score']}>
                            <InputNumberControl
                                min={INPUT_NUMBER_MIN_VALUE}
                                max={INPUT_NUMBER_MAX_VALUE}
                                placeholder={INPUT_NUMBER_PLACEHOLDER}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
)
