import CryptoJS from 'crypto-js'
import {
    CourseValidationCaseContract,
    StructuralUnitType,
    StructuralUnitsSearchContract,
    StructuralUnitsService,
} from 'core/api'
import { FnActionProps } from 'App.types'
import { normalizeDataForSelectAndRadio } from 'utils'

/**
 * Считаем хэш файла
 * @param file выбранный файл
 */
export const getFileHash = (file: File | Blob): Promise<string> =>
    file.arrayBuffer().then((res) => {
        let sha256 = CryptoJS.algo.SHA256.create()
        let sliceStart = 0
        const SLICE_SIZE = 10485760 // 10MB

        while (sliceStart < res.byteLength) {
            /** Создаем типизированный массив из среза файла */
            const typedArraySlice = new Int8Array(
                res.slice(sliceStart, sliceStart + SLICE_SIZE)
            )

            /** Конвертим типизированный массив в массив слов для библиотеки CryptoJS */
            const wordArraySlice = CryptoJS.lib.WordArray.create(
                Array.from(typedArraySlice)
            )
            sha256 = sha256.update(wordArraySlice)
            sliceStart += SLICE_SIZE
        }

        /** Подсчитываем общий хэш и превращаем в строку */
        return sha256.finalize().toString()
    })

/**
 * Проверка типа валидации при загрузке файла курса
 * @param caseType тип валидации
 */
export const isCourseValidationTypeNameNotUnique = (
    caseType?: CourseValidationCaseContract
) => caseType === CourseValidationCaseContract.NameNotUnique

export const isCourseValidationTypeAlreadyExists = (
    caseType?: CourseValidationCaseContract
) => caseType === CourseValidationCaseContract.AlreadyExists

export const isCourseValidationTypeAlreadyExistsAndUsingAppointment = (
    caseType?: CourseValidationCaseContract
) => caseType === CourseValidationCaseContract.AlreadyExistsAndUsingAppointment

export const composeAcceptFormatString = (format?: string) => {
    if (!format) return

    switch (format) {
        case 'Scorm 1.2':
        case 'Scorm 1.3':
        case 'TinCan':
            return '.zip'
        default:
            return `.${format.toLowerCase()}`
    }
}

export const ownerFetch = async ({
    updateElementVisible,
    setOwners,
}: {
    updateElementVisible: FnActionProps
    setOwners: FnActionProps
}) => {
    try {
        updateElementVisible(true)
        const ownersData = await StructuralUnitsService.flatSearch({
            body: {
                types: [
                    StructuralUnitType.Organization,
                    StructuralUnitType.Oiv,
                    StructuralUnitType.Unit,
                ],
            } as StructuralUnitsSearchContract,
            pageSize: 999,
            pageNumber: 1,
        })

        setOwners(ownersData?.pageItems.map(normalizeDataForSelectAndRadio))
    } catch (error) {
        console.error(error)
    } finally {
        updateElementVisible(false)
    }
}
