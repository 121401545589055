import { AssessmentRole } from 'core/api'
import { LOCAL } from 'core/local'

export const TABS_PANE_OPTIONS = [
    {
        tab: LOCAL.LABELS.MANAGER,
        key: AssessmentRole.Manager,
    },
    {
        tab: LOCAL.LABELS.EMPLOYEE,
        key: AssessmentRole.SelfAssessment,
    },
]

export const DEFAULT_TAB_KEY = TABS_PANE_OPTIONS[0].key
