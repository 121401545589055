import React from 'react'
import { Col } from 'antd'
import { LOCAL } from 'core/local'
import { Title } from 'components/shared'

import { ProductCardCarousel } from '../ProductCardCarousel'
import { ProductCardTeacher } from '../ProductCardTeacher'
import { ProductCardTeachersProps } from './ProductCardTeachers.types'
import { SLIDES_TO_SHOW } from './ProductCardTeachers.consts'

/** Карточка продукта - преподаватели */
export const ProductCardTeachers: React.FC<ProductCardTeachersProps> = React.memo(
    ({ teachers }) => (
        <Col xs={18}>
            <Title type="h6" text={`${LOCAL.LABELS.TEACHERS}:`} />

            <ProductCardCarousel
                itemsCount={teachers.length}
                slidesToShow={SLIDES_TO_SHOW}
            >
                {teachers.map((teacher) => (
                    <div key={teacher.id}>
                        <ProductCardTeacher {...teacher} />
                    </div>
                ))}
            </ProductCardCarousel>
        </Col>
    )
)
