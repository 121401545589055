import React from 'react'
import { COL_xl8_lg12_xs24, ROW_GUTTER } from 'consts'
import { Col, Row } from 'antd'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { TrackModalSearchContract } from 'core/api'

/**
 * Сокращенная форма фильтрации треков
 */
export const TracksFilters: React.FC<FiltersProps<
    TrackModalSearchContract
>> = React.memo((props) => (
    <FilterForm {...props}>
        <Row gutter={ROW_GUTTER}>
            <Col {...COL_xl8_lg12_xs24}>
                <FormItemAdapter
                    fieldName="name"
                    label={LOCAL.LABELS.TEMPLATE_NAME}
                >
                    <InputControl />
                </FormItemAdapter>
            </Col>

            <Col {...COL_xl8_lg12_xs24}>
                <FormItemAdapter
                    fieldName="title"
                    label={LOCAL.LABELS.TEMPLATE_TITLE}
                >
                    <InputControl />
                </FormItemAdapter>
            </Col>

            <Col {...COL_xl8_lg12_xs24}>
                <FormItemAdapter
                    fieldName="ownerFio"
                    label={LOCAL.LABELS.AUTHOR_FIO}
                >
                    <InputControl />
                </FormItemAdapter>
            </Col>
        </Row>
    </FilterForm>
))
