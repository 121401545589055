import React from 'react'
import { TableAdapter } from 'components/shared'

import styles from './CommonStatsTable.module.scss'
import { CommonStatsTableProps } from './CommonStatsTable.types'
import { getTableColumns } from './CommonStatsTable.config'

/** общая статистика таблица */
export const CommonStatsTable: React.FC<CommonStatsTableProps> = React.memo(
    ({ data, questionsColumns }) => (
        <TableAdapter
            wrapperClassName={styles.wrapper}
            columns={getTableColumns(questionsColumns)}
            dataSource={data}
            expandable={{ defaultExpandAllRows: true }}
            scroll={{
                y: 395,
            }}
            pagination={false}
        />
    )
)
