import React from 'react'
import { APPOINTMENT_STATUS, DOCUMENT_STATUSES } from 'consts'
import {
    AppointmentContract,
    AppointmentSearchResultContract,
    UserPermission,
} from 'core/api'
import {
    AppointmentsPassingServiceProps,
    formatDate,
    isAppointmentStatusDraft,
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'
import { ColumnsType } from 'antd/lib/table'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { GetBtnOptionsType } from 'components/layouts/FormContainer/FormContainer.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WidthRestrictedElement } from 'components/shared'

import { TestAssignmentsTableActions } from './components'

/**
 * Получить настройки отображения колонок для реестра назначений тестов
 * @param appointmentProcessService сервис для контроля процесса прохождения назначения
 * @param actions вспомогательные функции/экшены, берутся из DictionaryPage
 * @returns настройки для отображения колонок
 */
export const getTestAssignmentsTableColumns = (
    appointmentProcessService: AppointmentsPassingServiceProps,
    { reFetchDictionary, updateLoader }: DictionaryPageActionsProps
): ColumnsType<AppointmentSearchResultContract> => [
    {
        title: LOCAL.LABELS.APPOINTMENT_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizations',
        key: 'organizations',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ASSESSED_COMPETENCIES,
        dataIndex: 'competencies',
        key: 'competencies',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'evaluationPeriodFrom',
        key: 'evaluationPeriodFrom',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'evaluationPeriodTo',
        key: 'evaluationPeriodTo',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
            <WidthRestrictedElement
                title={
                    record.active
                        ? APPOINTMENT_STATUS[record.status]
                        : DOCUMENT_STATUSES.Archive
                }
            />
        ),
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <TestAssignmentsTableActions
                updateLoader={updateLoader}
                tableRow={tableRow}
                appointmentProcessService={appointmentProcessService}
                reFetchDictionary={reFetchDictionary}
            />
        ),
        fixed: 'right',
    },
]

export const CANCEL_BTN_OPTIONS = {
    visible: true,
    href: ROUTE_NAMES.TEST_ASSIGNMENTS,
}

export const getSaveBtnOptions: GetBtnOptionsType<AppointmentContract> = ({
    editValues,
}) => ({
    visible:
        isAppointmentStatusDraft(editValues?.status) ||
        editValues?.status == null,
})

export const PUBLISH_BTN_OPTIONS = {
    visible: true,
    requiredPermissions: [UserPermission.TestAppointmentPublish],
    text: LOCAL.ACTIONS.ASSIGN,
}
