import TextArea from 'antd/lib/input/TextArea'
import React, { useCallback, useEffect } from 'react'
import {
    CheckboxControl,
    FormItemAdapter,
    InputControl,
} from 'components/controls'
import { DictionariesHelper } from 'core/helpers/DictionariesHelper'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ScaleContract, ScaleCreateContract, ScalesService } from 'core/api'
import { WithLoaderProps } from 'HOCs'
import {
    createConfirmPopup,
    getFormPopupTitle,
    getInitialValuesForCopy,
    isFormModeCopy,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'

import styles from './CriteriaScalesForm.module.scss'
import { ScaleValuesList } from '../ScaleValuesList'
import { mapFormDataToResponse } from './CriteriaScalesForm.utils'

/**
 * Форма создания шкалы опроса
 */
export const CriteriaScalesForm: React.FC<
    FormProps<ScaleContract> & Partial<WithLoaderProps>
> = React.memo(
    ({
        isSaveDocument,
        initialValuesForEdit,
        reFetchInitialFormValues,
        formMode,
        updateLoader,
    }) => {
        const history = useHistory()
        const [form] = Form.useForm()

        /**
         * Обработчик отправки формы
         * @param values значения формы
         */
        const handleFinish = useCallback(
            async (values) => {
                try {
                    updateLoader?.(true)

                    const body = {
                        id: !isFormModeCopy(formMode)
                            ? initialValuesForEdit?.id
                            : undefined,
                        ...mapFormDataToResponse(values),
                    }

                    if (isSaveDocument) {
                        await ScalesService.save({
                            body: body as ScaleCreateContract,
                        })
                    } else {
                        await ScalesService.publish({
                            body: body as ScaleCreateContract,
                        })
                    }

                    DictionariesHelper.update([
                        'resultEstimateScaleTypesDictionary',
                    ])

                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(
                                ROUTE_NAMES.CRITERIA_SCALES,
                                'pageTitle'
                            )
                        ),
                        content: isSaveDocument
                            ? LOCAL.MESSAGES.SCALE_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES.SCALE_PUBLISH_SUCCESS_MESSAGE,
                        onOk: () => {
                            history.push(ROUTE_NAMES.CRITERIA_SCALES)
                        },
                        onCancel: () => {
                            if (!initialValuesForEdit?.id) {
                                form.resetFields()
                            } else
                                reFetchInitialFormValues?.(
                                    initialValuesForEdit?.id
                                )
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                updateLoader,
                formMode,
                initialValuesForEdit,
                isSaveDocument,
                history,
                reFetchInitialFormValues,
                form,
            ]
        )

        useEffect(() => {
            if (initialValuesForEdit?.id) {
                form.setFieldsValue(
                    getInitialValuesForCopy(initialValuesForEdit, formMode)
                )
            }
        }, [initialValuesForEdit, form, formMode])

        return (
            <Form
                id={FORM_IDS.POLL_SCALE_FORM}
                onFinish={handleFinish}
                form={form}
                className={styles.wrapper}
            >
                <FormItemAdapter
                    fieldName="name"
                    label={LOCAL.LABELS.SCALE_NAME}
                    required
                >
                    <InputControl />
                </FormItemAdapter>

                <FormItemAdapter
                    fieldName="isSemantic"
                    label={LOCAL.LABELS.SCALE_SIGN}
                >
                    <CheckboxControl>{LOCAL.LABELS.SEMANTIC}</CheckboxControl>
                </FormItemAdapter>

                <ScaleValuesList />

                <FormItemAdapter
                    fieldName="description"
                    label={LOCAL.LABELS.DESCRIPTION}
                >
                    <TextArea />
                </FormItemAdapter>
            </Form>
        )
    }
)
