import { LOCAL } from 'core/local'

import { QuizReportTabProps } from './QuizReport.types'

export const QUIZ_REPORT_TABS: QuizReportTabProps[] = [
    {
        text: LOCAL.LABELS.CHARTS,
        key: 'charts',
    },
    {
        text: LOCAL.LABELS.FREE_ANSWER,
        key: 'freeAnswers',
    },
    {
        text: LOCAL.LABELS.SPENT_TIME,
        key: 'spentTime',
    },
]

export enum ChartColor {
    'Red' = 'Red',
    'Orange' = 'Orange',
    'Green' = 'Green',
}
