import React from 'react'
import cn from 'classnames'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { TreeSelect } from 'antd'

import styles from './TreeSelectControl.module.scss'
import { TreeSelectControlProps } from './TreeSelectControl.types'

/**
 * Декоратор для `TreeSelect` от `antd`, принимает все теже `props`
 */
export const TreeSelectControl: React.FC<TreeSelectControlProps<
    number[]
>> = React.memo(({ addonAfter, ...restProps }) => (
    <>
        <div className={cn('form-control', styles.wrapper)}>
            <TreeSelect
                placeholder={LOCAL.PLACEHOLDERS.SELECT}
                dropdownClassName={styles.dropdown}
                treeNodeFilterProp="title"
                switcherIcon={<IconsAdapter iconType="CustomIconChevron" />}
                showArrow
                suffixIcon={<IconsAdapter iconType="CustomIconTriangle" />}
                {...restProps}
            />
        </div>

        {addonAfter}
    </>
))
