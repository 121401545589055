import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import styles from './WidthRestrictedElement.module.scss'
import { IconsAdapter } from '../IconsAdapter'
import { TooltipAdapter } from '../TooltipAdapter'
import { WidthRestrictedElementProps } from './WidthRestrictedElement.types'

export const WidthRestrictedElement: React.FC<WidthRestrictedElementProps> = React.memo(
    ({
        width,
        title,
        tooltip,
        showDots,
        titlePlaceholder = <IconsAdapter iconType="MinusOutlined" />,
        className,
        showTooltipAlways,
        maxWidth,
        getPopupContainer,
    }) => {
        const ref = React.useRef<HTMLDivElement>(null)
        const [withTooltip, setWithTooltip] = useState(false)

        const element = (
            <div
                className={cn(styles.wrapper, className)}
                style={{
                    width,
                    maxWidth,
                    textOverflow:
                        showDots &&
                        ref.current &&
                        ref.current.scrollWidth > ref.current.offsetWidth
                            ? 'ellipsis'
                            : 'unset',
                }}
                ref={ref}
                data-testid="widthRestrictedElement"
            >
                {title || titlePlaceholder}
            </div>
        )

        useEffect(() => {
            if (showTooltipAlways) {
                setWithTooltip(true)

                return
            }

            if (
                !ref.current ||
                ref.current.scrollWidth <= ref.current.offsetWidth
            ) {
                setWithTooltip(false)

                return
            }

            setWithTooltip(true)
        }, [showTooltipAlways, title])

        return withTooltip && tooltip ? (
            <TooltipAdapter
                {...tooltip}
                getPopupContainer={getPopupContainer}
                title={tooltip?.title || title}
                placement="top"
            >
                {element}
            </TooltipAdapter>
        ) : (
            element
        )
    }
)
