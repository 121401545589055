import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Spin, Upload } from 'antd'
import { LOCAL } from 'core/local'
import { MEDIA_DOCUMENT_TYPE } from 'consts'
import { RcCustomRequestOptions } from 'types'
import { RcFile } from 'antd/lib/upload/interface'
import { getLastFileFromList } from 'components/controls'
import { restrictBigText } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './UploadFileWrapper.module.scss'
import { AddButton } from '../AddButton'
import { IconsAdapter } from '../IconsAdapter'
import { TooltipAdapter } from '../TooltipAdapter'
import { UploadFileWrapperProps } from './UploadFileWrapper.types'

export const UploadFileWrapper: React.FC<UploadFileWrapperProps> = React.memo(
    ({
        name,
        acceptedTypes = MEDIA_DOCUMENT_TYPE,
        disabled,
        handleFileRemove,
        validateFiles,
        uploadMethod,
        children,
        label,
        updateLoader,
        isLoading,
        initialFile,
        className,
        hideDownloadBtn,
        viewFilesComponent,
    }) => {
        const { updateElementVisible, elementVisible } = useElementVisible()

        const [uploadedFile, setUploadedFile] = useState<
            UploadFileWrapperProps['initialFile']
        >()

        const isDisabled = disabled || isLoading

        const uploadFile = useCallback(
            async (request: RcCustomRequestOptions) => {
                try {
                    updateLoader?.(true)

                    updateElementVisible(true)

                    const rcFile = request.file as RcFile

                    const result = await uploadMethod({
                        file: request.file,
                    })

                    setUploadedFile({ name: rcFile.name, id: result.id })

                    request.onSuccess?.(result)
                } catch (err) {
                    request.onError?.(err as Error)

                    setUploadedFile(undefined)
                } finally {
                    updateLoader?.(false)
                    updateElementVisible(false)
                }
            },
            [updateLoader, uploadMethod, updateElementVisible]
        )

        const handleDeleteAttachment = useCallback(async () => {
            if (!isDisabled) {
                try {
                    await handleFileRemove?.(uploadedFile?.id)

                    setUploadedFile(undefined)
                } catch (error) {
                    console.log(error)
                }
            }
        }, [handleFileRemove, uploadedFile, isDisabled])

        const renderViewFileComponent = useMemo(
            () =>
                viewFilesComponent?.({
                    disabled,
                    handleDeleteAttachment,
                    fileName: uploadedFile?.name,
                }) || (
                    <div className={styles.uploadFile}>
                        {elementVisible ? (
                            <Spin size="small" />
                        ) : (
                            <IconsAdapter
                                iconType="PaperClipOutlined"
                                className={styles.paperClipIcon}
                            />
                        )}

                        <div className={styles.fileName}>
                            {restrictBigText(uploadedFile?.name)}
                        </div>

                        <TooltipAdapter title={LOCAL.ACTIONS.DELETE_FILE}>
                            <IconsAdapter
                                iconType="CustomIconTrashAltBlue"
                                className={styles.trashIcon}
                                disabled={isDisabled}
                                onClick={handleDeleteAttachment}
                            />
                        </TooltipAdapter>
                    </div>
                ),
            [
                disabled,
                elementVisible,
                handleDeleteAttachment,
                isDisabled,
                uploadedFile,
                viewFilesComponent,
            ]
        )

        useEffect(() => {
            if (initialFile?.id || initialFile?.name)
                setUploadedFile(initialFile)
        }, [initialFile])

        return (
            <div className={cn(className, styles.wrapper)}>
                {(!hideDownloadBtn ||
                    (hideDownloadBtn && !uploadedFile?.name)) && (
                    <Form.Item
                        name={name}
                        valuePropName="fileList"
                        getValueFromEvent={getLastFileFromList}
                        label={label}
                    >
                        <Upload
                            disabled={isDisabled}
                            accept={acceptedTypes}
                            customRequest={uploadFile}
                            beforeUpload={validateFiles}
                            showUploadList={false}
                        >
                            {children || (
                                <AddButton
                                    buttonText={LOCAL.LABELS.DOWNLOAD_IMAGE}
                                    disabled={disabled}
                                />
                            )}
                        </Upload>
                    </Form.Item>
                )}

                {uploadedFile?.name && renderViewFileComponent}
            </div>
        )
    }
)
