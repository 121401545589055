import React from 'react'
import cn from 'classnames'
import { Typography } from 'antd'
import { isFormModeView } from 'utils'

import styles from './EditableTextControl.module.scss'
import { EditableTextControlProps } from './EditableTextControl.types'

const { Text } = Typography

/**
 * Декоратор для `Paragraph` от `antd`
 */
export const EditableTextControl: React.FC<EditableTextControlProps> = React.memo(
    ({ text, className, disabledControl, formMode, ...restProps }) => (
        <div
            className={cn(styles.wrapper, className, {
                [styles.disabled]: disabledControl || isFormModeView(formMode),
            })}
        >
            <Text {...restProps}>{text}</Text>
        </div>
    )
)
