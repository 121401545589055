import { ImgsUploadFnResponseProps } from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.types'
import { TrackHeaderIconsService } from 'core/api'

export const canvasLogoImgsFetchFn = (
    pageSize: number,
    pageNumber: number
): Promise<any> =>
    TrackHeaderIconsService.search({
        pageSize,
        pageNumber,
    })

export const canvasLogoImgsUploadFn = (
    request: any
): Promise<ImgsUploadFnResponseProps> =>
    TrackHeaderIconsService.upload({
        file: request.file,
    })
