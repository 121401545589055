import React from 'react'
import cn from 'classnames'
import { LOCAL } from 'core/local'

import styles from './ApproveStages.module.scss'
import { ApproveStagesProps } from './ApproveStages.types'
import { Stage } from './Stage'

/** Этапы листа согласования */
export const ApproveStages: React.FC<ApproveStagesProps> = React.memo(
    ({ approveStages }) => (
        <div>
            <div className={cn(styles.row, styles.rowHeader)}>
                <div className={styles.participant}>
                    {LOCAL.LABELS.APPROVAL_RESPONDENT}
                </div>

                <div className={styles.considerationPeriod}>
                    {LOCAL.LABELS.APPROVAL_TIME}
                </div>

                <div className={styles.statusIcon} />

                <div className={styles.status}>{LOCAL.LABELS.RESULT}</div>

                <div className={styles.comment}>{LOCAL.LABELS.COMMENT}</div>
            </div>

            {approveStages?.map((stage) => (
                <div
                    key={stage.id}
                    className={cn(
                        styles.block,
                        stage.items.length > 1 ? styles.group : styles.single
                    )}
                >
                    {stage.items.map((item) => (
                        <Stage key={item.id} {...item} />
                    ))}
                </div>
            ))}
        </div>
    )
)
