import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { AddonAfterDefault } from 'components/shared'
import { Col, Row } from 'antd'
import { DEPENDENCIES_MESSAGES } from 'core/local/messages.local.config'
import { DEPRECATED_ASSESSMENT_TYPES, ROW_GUTTER } from 'consts'
import {
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    InputNumberControl,
    RadioButtonControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UserContext } from 'contexts'
import { UserPermission } from 'core/api'
import {
    disableDateWhenSelectedDate,
    excludeSelectOptions,
    getOnBeforeChangeHandler,
    isAppointmentStatusEnded,
    isAvailableToWatch,
    isFormModeEdit,
    renderQuestionnairesDropdown,
} from 'utils'

import {
    CHECK_FIELDS,
    MIN_DAYS_TO_NOTIFICATION,
} from './GeneralSettings.consts'
import { GeneralSettingsProps } from './GeneralSettings.types'
import { isRenderAddonAfterAllowed } from './GeneralSettings.utils'
import {
    portfolioIdFieldNamePath,
    shouldRolesAndAssessmentPortfoliosReFetch,
} from '../AssessmentAssignmentForm/AssessmentAssignmentForm.consts'

/** Общие настройки формы назначения оценки */
export const GeneralSettings: React.FC<GeneralSettingsProps> = React.memo(
    ({
        formMode,
        form,
        initialValuesForEdit,
        assessmentTypes,
        assessmentPortfolios,
        disabled,
    }) => {
        const { userData } = useContext(UserContext)

        const disablePeriodFromByStatus =
            isAppointmentStatusEnded(initialValuesForEdit?.status) &&
            isFormModeEdit(formMode)

        return (
            <>
                <FormItemAdapter
                    fieldName={shouldRolesAndAssessmentPortfoliosReFetch}
                    label={LOCAL.LABELS.ASSESSMENT_TYPE}
                    required
                >
                    <RadioButtonControl
                        values={excludeSelectOptions(
                            assessmentTypes,
                            DEPRECATED_ASSESSMENT_TYPES
                        )}
                        buttonStyle="solid"
                        formMode={formMode}
                        onBeforeChange={getOnBeforeChangeHandler({
                            dependencies: CHECK_FIELDS,
                            confirmTitle: DEPENDENCIES_MESSAGES.ASSESSMENT,
                            form,
                        })}
                        disabled={disabled}
                    />
                </FormItemAdapter>

                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                            required
                        >
                            <InputControl formMode={formMode} />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName={['assessment', 'requestNumber']}
                            label={LOCAL.LABELS.REQUEST_NUMBER}
                            required
                        >
                            <InputControl
                                formMode={formMode}
                                disabled={disabled}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <ShouldUpdateChecker
                            fieldPath={[
                                portfolioIdFieldNamePath,
                                shouldRolesAndAssessmentPortfoliosReFetch,
                            ]}
                        >
                            {({ getFieldValue }) => {
                                const { portfolioId, type } = getFieldValue(
                                    'assessment'
                                )

                                return (
                                    <FormItemAdapter
                                        fieldName={portfolioIdFieldNamePath}
                                        label={
                                            LOCAL.LABELS.ASSESSMENT_PORTFOLIO
                                        }
                                        required
                                    >
                                        <SelectControl
                                            allowClear
                                            values={assessmentPortfolios}
                                            addonAfter={
                                                <AddonAfterDefault
                                                    url={`${ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_VIEW}/${portfolioId}`}
                                                    disabled={
                                                        !portfolioId ||
                                                        !isRenderAddonAfterAllowed(
                                                            userData?.permissions,
                                                            type
                                                        )
                                                    }
                                                />
                                            }
                                            dropdownRender={renderQuestionnairesDropdown(
                                                {
                                                    url:
                                                        ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_CREATE,
                                                    permissions: [
                                                        UserPermission.AssessmentAppointmentsRead,
                                                    ],
                                                }
                                            )}
                                            notFoundContent={
                                                LOCAL.LABELS.EMPTY_DATA
                                            }
                                            disabled={
                                                !!initialValuesForEdit?.id &&
                                                isAvailableToWatch(
                                                    initialValuesForEdit?.status
                                                )
                                            }
                                            formMode={formMode}
                                            showSearch
                                        />
                                    </FormItemAdapter>
                                )
                            }}
                        </ShouldUpdateChecker>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="evaluationPeriodFrom"
                            label={LOCAL.LABELS.DATE_START}
                            required
                        >
                            <DatePickerControl
                                formMode={formMode}
                                disabledDate={disableDateWhenSelectedDate(
                                    initialValuesForEdit?.evaluationPeriodFrom ||
                                        moment()
                                )}
                                disabled={disablePeriodFromByStatus}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <ShouldUpdateChecker fieldPath="evaluationPeriodFrom">
                            {({ getFieldsValue }) => {
                                const {
                                    evaluationPeriodFrom,
                                } = getFieldsValue(['evaluationPeriodFrom'])

                                return (
                                    <FormItemAdapter
                                        fieldName="evaluationPeriodTo"
                                        label={LOCAL.LABELS.DATE_END}
                                        required
                                    >
                                        <DatePickerControl
                                            formMode={formMode}
                                            disabledDate={disableDateWhenSelectedDate(
                                                evaluationPeriodFrom || moment()
                                            )}
                                            disabled={disablePeriodFromByStatus}
                                        />
                                    </FormItemAdapter>
                                )
                            }}
                        </ShouldUpdateChecker>
                    </Col>
                </Row>

                <FormItemAdapter
                    label={LOCAL.LABELS.NOTIFICATIONS_TIME_SET}
                    required
                >
                    <FormItemAdapter fieldName="daysToStartNotification">
                        <InputNumberControl
                            min={MIN_DAYS_TO_NOTIFICATION}
                            placeholder={String(MIN_DAYS_TO_NOTIFICATION)}
                            formMode={formMode}
                            addonBefore={
                                LOCAL.LABELS.NOTIFICATIONS_TIME_SET_BEFORE
                            }
                            addonAfter={
                                LOCAL.LABELS
                                    .NOTIFICATIONS_TIME_SET_DAYS_TO_START
                            }
                            disabled={disabled}
                        />
                    </FormItemAdapter>

                    <FormItemAdapter fieldName="daysToEndNotification">
                        <InputNumberControl
                            min={MIN_DAYS_TO_NOTIFICATION}
                            placeholder={String(MIN_DAYS_TO_NOTIFICATION)}
                            formMode={formMode}
                            addonBefore={
                                LOCAL.LABELS.NOTIFICATIONS_TIME_SET_BEFORE
                            }
                            addonAfter={
                                LOCAL.LABELS.NOTIFICATIONS_TIME_SET_DAYS_TO_END
                            }
                            disabled={disabled}
                        />
                    </FormItemAdapter>
                </FormItemAdapter>
            </>
        )
    }
)
