import React, { FC, useCallback, useEffect, useState } from 'react'
import { ApplicationSettings, SettingsService } from 'core/api'

import { AppSettingsContext } from './AppSettings'

export const AppSettingsContextProvider: FC = React.memo(({ children }) => {
    const [
        appSettingsData,
        setAppSettingsData,
    ] = useState<ApplicationSettings | null>(null)

    /**
     * Обработчик установки настроек приложения
     * @param appSettings - настройки приложения
     */
    const onSetAppSettings = useCallback(
        (appSettings: ApplicationSettings | null) => {
            setAppSettingsData(appSettings)
        },
        []
    )

    const fetchAppSettings = useCallback(async () => {
        if (appSettingsData) {
            return
        }

        try {
            const settings = await SettingsService.getApplicationSettings()

            setAppSettingsData(settings)
        } catch (error) {
            console.error(error)
        }
    }, [appSettingsData])

    useEffect(() => {
        fetchAppSettings()
    }, [fetchAppSettings])

    return (
        <AppSettingsContext.Provider
            value={{
                appSettings: appSettingsData,
                setAppSettings: onSetAppSettings,
            }}
        >
            {children}
        </AppSettingsContext.Provider>
    )
})
