import { CertificateContract, CertificateCreateContract } from 'core/api'

import { MapFormDataToRequestProps } from './ExternalCertificatesForm.types'

/** Маппинг отправляемых данных */
export const mapFormDataToRequest = ({
    values,
    userId,
}: MapFormDataToRequestProps) =>
    ({
        ...values,
        userId,
    } as CertificateCreateContract)

/** Маппинг получаемых данных*/
export const mapResponseToFormData = ({
    user,
    id,
    type,
    ...restValues
}: CertificateContract) => restValues
