import React, { useCallback, useEffect, useState } from 'react'
import { CourseContract, CoursesService } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsCommonProps } from 'App.types'
import { WithLoaderProps, withLoader } from 'HOCs'
import { useHistory, useParams } from 'react-router-dom'

import styles from './TrainingMaterialView.module.scss'
import { PlayerView } from '../TrackPreviewPassing/components/ThematicBlockLayer/components/ThematicBlockContent/components'

export const TrainingMaterialView: React.FC<Partial<
    WithLoaderProps
>> = withLoader(
    React.memo(({ updateLoader }) => {
        const { id: courseId } = useParams<UrlParamsCommonProps<'id'>>()

        const history = useHistory()

        const [course, setCourse] = useState<CourseContract>()

        const handleBackToTrainingMaterials = useCallback(() => {
            history.push(ROUTE_NAMES.TRAINING_MATERIALS)
        }, [history])

        useEffect(() => {
            const fetchCourse = async () => {
                try {
                    updateLoader(true)

                    const courses = await CoursesService.getByIds({
                        body: [Number(courseId)],
                    })

                    setCourse(courses[0])
                } catch (err) {
                    console.error(err)
                } finally {
                    updateLoader(false)
                }
            }

            fetchCourse()
        }, [courseId, updateLoader])

        return (
            <div className={styles.wrapper}>
                {course && (
                    <PlayerView
                        courseData={course}
                        onViewMaterial={handleBackToTrainingMaterials}
                    />
                )}
            </div>
        )
    })
)
