import { Store } from 'antd/lib/form/interface'
import { WithFormModeProps } from 'App.types'

/**
 * Проверка формы на пустоту
 * @param fields - поля формы
 * @deprecated
 */
export const isFormNotEmpty = (fields: Store) =>
    Object.values(fields).some(Boolean)

/** Проверка режима работы с формой */
export const isFormModeView = (mode?: WithFormModeProps['formMode']) =>
    mode === 'view'
export const isFormModeCopy = (mode?: WithFormModeProps['formMode']) =>
    mode === 'copy'
export const isFormModeEdit = (mode?: WithFormModeProps['formMode']) =>
    mode === 'edit'
export const isFormModeCreate = (mode?: WithFormModeProps['formMode']) =>
    mode === 'create'
export const isFormModePassing = (mode?: WithFormModeProps['formMode']) =>
    mode === 'passing'

/**
 * Определить является ли value null, если да, возвращает null, иначе возвращает value || state
 * @param value значение контрола
 * @param state состояние контрола
 */
export const determineNullValue = <T>(value: T, state: T) =>
    value === null ? null : value || state
