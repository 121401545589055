export const PLACEHOLDERS = {
    CHOOSE_CHART: 'Выберите график',
    DATA_PICKER: 'Выберите дату',
    INPUT: 'Введите значение',
    INPUT_EMAIL: 'Введите e-mail',
    INPUT_EMPTY: 'Не указано',
    INPUT_NUMBER: '0',
    QUICK_SEARCH: 'Быстрый поиск',
    SEARCH_BY_CATALOGUE: 'Поиск по каталогу',
    SEARCH_BY_NAME: 'Искать по наименованию',
    SEARCH_SELECT: 'Введите или выберите',
    SELECT: 'Выберите вариант',
    TIME_PICKER: 'Выберите время',
    UPLOAD_IMAGE: 'Добавить изображение',
}
