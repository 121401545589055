import { ScaleContract, ScaleValueContract } from 'core/api'

export const mapFormDataToResponse = (values: Partial<ScaleContract>) => {
    const { scaleValues, ...otherFields } = values

    return {
        ...otherFields,
        values: scaleValues?.map((item: ScaleValueContract, index: number) => ({
            ...item,
            id: index,
        })),
    }
}
