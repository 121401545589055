import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { SupervisingComplexContract } from 'core/api'
import { defaultSorter } from 'components/layouts'
import { formatDate, restrictBigText } from 'utils'

/** настройка отображения колонок таблицы */
export const SUPERVISING_COMPLEXES_TABLE_COLUMNS: ColumnsType<SupervisingComplexContract> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.CURATOR,
        dataIndex: 'curatorName',
        key: 'curatorName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        align: 'left',
        sorter: defaultSorter,
        fixed: 'right',
    },
]
