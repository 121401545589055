import React from 'react'

import { ContactType } from './AboutSystem.types'

export const getContactElement = (
    contactValue: string,
    contactType: ContactType
) => (
    <li>
        <a href={`${contactType}:${contactValue}`}>{contactValue}</a>
    </li>
)
