import cn from 'classnames'
import isNil from 'lodash/isNil'
import React, { ReactText, useCallback, useEffect, useState } from 'react'
import { Radio, Space } from 'antd'
import { isFormModeView } from 'utils'

import styles from './RadioControl.module.scss'
import { RadioControlProps } from './RadioControl.types'

/**
 * Декоратор для `RadioGroup` от `antd`, принимает все теже `props`
 */
export const RadioControl: React.FC<RadioControlProps> = React.memo((props) => {
    const {
        values = [],
        value,
        onChange,
        onBeforeChange,
        direction = 'horizontal',
        disabled,
        formMode,
        className,
        ...restProps
    } = props

    const [valueState, setValueState] = useState<ReactText>()

    /**
     * Обновить значение в стейте и внешней форме
     */
    const triggerChanges = useCallback(
        (value: ReactText) => {
            setValueState(value)
            onChange?.(value)
        },
        [onChange]
    )

    useEffect(() => {
        setValueState(value)
    }, [value])

    /**
     * Обработчик изменения значения RadioGroup
     */
    const handleRadioGroupChange = useCallback(
        (event) => {
            const startTrigger = () => {
                if (!isNil(event?.target?.value)) {
                    triggerChanges(event.target.value)
                }
            }

            if (onBeforeChange) {
                onBeforeChange({
                    onChange: startTrigger,
                })
            } else {
                startTrigger()
            }
        },
        [onBeforeChange, triggerChanges]
    )

    const classes = cn(
        isFormModeView(formMode) && 'view-mode',
        'form-control',
        className,
        styles.wrapper
    )

    return (
        <div className={classes}>
            <Radio.Group
                onChange={handleRadioGroupChange}
                value={valueState}
                disabled={disabled || isFormModeView(formMode)}
                {...restProps}
            >
                <Space direction={direction}>
                    {values.map((el) => (
                        <Radio key={el.key} value={el.value}>
                            {el.label}
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </div>
    )
})
