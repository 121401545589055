import last from 'lodash/last'
import React, { useCallback, useEffect, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Form, Radio, Table } from 'antd'
import { InputNumberControl, ShouldUpdateChecker } from 'components/controls'
import { LOCAL } from 'core/local'
import { RadioChangeEvent } from 'antd/lib/radio'
import { ResultCalculatingType } from 'core/api'
import {
    getControlClass,
    isFormModeView,
    isResultCalculatingTypeTotalPoints,
} from 'utils'

import styles from './ResultAssessmentType.module.scss'
import { ResultAssessmentTypeLevelsProps } from '../ResultAssessment/ResultAssessment.types'
import { ResultAssessmentTypeProps } from './ResultAssessmentType.types'
import { createNewRow, getColumns } from './ResultAssessmentType.utils'

export const ResultAssessmentType: React.FC<ResultAssessmentTypeProps> = React.memo(
    ({ onChange, value }) => {
        const [
            resultAssessmentTypeLevels,
            setResultAssessmentTypeLevels,
        ] = useState<ResultAssessmentTypeLevelsProps[]>([])

        /**
         * state для поля `resultAssessmentType`, отправляется на бэк
         */
        const [resultAssessmentType, setResultAssessmentType] = useState<
            ResultCalculatingType
        >()

        /**
         * Обработчик изменения `values`, для antd `Form`
         */
        const triggerChange = useCallback(
            (changedValue: ResultAssessmentTypeProps['value']) => {
                if (onChange) {
                    onChange({
                        resultCalculatingType: resultAssessmentType,
                        resultLevels: [],
                        ...value,
                        ...changedValue,
                    })
                }
            },
            [onChange, resultAssessmentType, value]
        )

        /**
         * Обработчик изменения поля `resultAssessmentType`
         */
        const handleResultAssessmentTypeChange = useCallback(
            (e: RadioChangeEvent) => {
                setResultAssessmentType(e.target.value)
                triggerChange({ resultCalculatingType: e.target.value })
            },
            [triggerChange]
        )

        /**
         * Обработчик изменения полей уровня таблицы
         */
        const handleLevelFieldsEdit = useCallback(
            (fieldKey: string, record: ResultAssessmentTypeLevelsProps) => (
                event?: React.ChangeEvent<HTMLInputElement> | number
            ) => {
                const resultLevels = resultAssessmentTypeLevels.map(
                    (el: ResultAssessmentTypeLevelsProps) => {
                        if (el.id === record.id) {
                            return {
                                ...el,
                                [fieldKey]:
                                    typeof event === 'number'
                                        ? event
                                        : event?.target.value,
                            }
                        }

                        return el
                    }
                )

                setResultAssessmentTypeLevels(resultLevels)
                triggerChange({ resultLevels })
            },
            [
                resultAssessmentTypeLevels,
                setResultAssessmentTypeLevels,
                triggerChange,
            ]
        )

        /**
         * Обработчик добавления новой строки (уровня) таблицы
         */
        const handleLevelAdd = useCallback(
            (maxPoints?: number) => () => {
                const resultLevels = [
                    ...resultAssessmentTypeLevels,
                    createNewRow(maxPoints, resultAssessmentTypeLevels),
                ]

                setResultAssessmentTypeLevels(resultLevels)
                triggerChange({ resultLevels })
            },
            [resultAssessmentTypeLevels, triggerChange]
        )

        /**
         * Обработчик удаления строки (уровня) таблицы
         */
        const handleDeleteLevel = useCallback(
            (id: string) => () => {
                const rowForDelete = {} as { index: number; pointsFrom: number }

                const filteredResultAssessmentTypeLevels = resultAssessmentTypeLevels.filter(
                    (el, index) => {
                        if (el.id === id) {
                            rowForDelete.index = index
                            rowForDelete.pointsFrom = el.pointsFrom
                        }

                        return el.id !== id
                    }
                )

                const finallyResultAssessmentTypeLevels = filteredResultAssessmentTypeLevels.map(
                    (el, index) => {
                        if (index === rowForDelete.index) {
                            return {
                                ...el,
                                pointsFrom: rowForDelete.pointsFrom,
                            }
                        }

                        return el
                    }
                )

                setResultAssessmentTypeLevels(finallyResultAssessmentTypeLevels)
                triggerChange({
                    resultLevels: finallyResultAssessmentTypeLevels,
                })
            },
            [
                resultAssessmentTypeLevels,
                setResultAssessmentTypeLevels,
                triggerChange,
            ]
        )

        const isDisabled = useCallback(
            (maxPoints: number) => {
                const pointsTo = last(resultAssessmentTypeLevels)?.pointsTo

                return !pointsTo || pointsTo >= maxPoints
            },
            [resultAssessmentTypeLevels]
        )

        const isResultAssessmentTypeTotal =
            isResultCalculatingTypeTotalPoints(resultAssessmentType) ||
            isResultCalculatingTypeTotalPoints(value?.resultCalculatingType)

        useEffect(() => {
            setResultAssessmentTypeLevels(
                value?.resultLevels || [createNewRow()]
            )
        }, [value])

        return (
            <ShouldUpdateChecker fieldPath={[['formMode'], ['maxPoints']]}>
                {({ getFieldsValue }) => {
                    const { formMode, maxPoints } = getFieldsValue()

                    return (
                        <Radio.Group
                            value={
                                value?.resultCalculatingType ||
                                resultAssessmentType
                            }
                            onChange={handleResultAssessmentTypeChange}
                            className={styles.wrapper}
                        >
                            <Radio
                                key="1"
                                value={ResultCalculatingType.TotalPoints}
                                disabled={isFormModeView(formMode)}
                                className={getControlClass({
                                    formMode,
                                })}
                            >
                                {LOCAL.LABELS.BY_COMMON_POINT}
                            </Radio>

                            <div className={styles.totalScore}>
                                {isResultAssessmentTypeTotal && (
                                    <>
                                        <div className={styles.addLevel}>
                                            <h4>
                                                {
                                                    LOCAL.LABELS
                                                        .PASSING_RESULT_LEVELS
                                                }
                                            </h4>

                                            <AddButton
                                                onClick={handleLevelAdd(
                                                    maxPoints
                                                )}
                                                disabled={
                                                    isDisabled(maxPoints) ||
                                                    isFormModeView(formMode)
                                                }
                                                buttonText={
                                                    LOCAL.ACTIONS.ADD_LEVEL
                                                }
                                            />
                                        </div>

                                        <Table
                                            columns={getColumns({
                                                onDelete: handleDeleteLevel,
                                                onLevelNameEdit: handleLevelFieldsEdit,
                                                onLevelScoreEdit: handleLevelFieldsEdit,
                                                tableData: resultAssessmentTypeLevels,
                                                maxPoints,
                                                formMode,
                                            })}
                                            dataSource={
                                                value?.resultLevels ||
                                                resultAssessmentTypeLevels
                                            }
                                            pagination={false}
                                            rowKey="id"
                                        />
                                    </>
                                )}
                            </div>

                            <div className={styles.passingScore}>
                                <Radio
                                    key="2"
                                    value={ResultCalculatingType.PassingPoints}
                                    disabled={isFormModeView(formMode)}
                                    className={getControlClass({
                                        formMode,
                                    })}
                                >
                                    {LOCAL.LABELS.BY_PASSING_POINT}
                                </Radio>

                                {!isResultAssessmentTypeTotal && (
                                    <Form.Item
                                        name="passingPoints"
                                        labelAlign="left"
                                    >
                                        <InputNumberControl
                                            min={0}
                                            max={maxPoints}
                                            placeholder="0"
                                            formMode={formMode}
                                        />
                                    </Form.Item>
                                )}
                            </div>
                        </Radio.Group>
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
