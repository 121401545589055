import React from 'react'
import cn from 'classnames'
import { APPROVE_STATUSES } from 'consts'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { StageItemContract } from 'core/api'
import { formatDate } from 'utils'

import styles from './ApproveStages.module.scss'
import { renderStatusIcon } from './ApproveStages.utils'

export const Stage: React.FC<StageItemContract> = React.memo((props) => {
    const isConsiderationPeriodOverdue =
        new Date(props.considerationPeriod).getTime() < new Date().getTime()

    return (
        <div className={cn(styles.row, styles[`row${props.status}`])}>
            <div className={styles.participant}>
                {props.participant.fullName}

                {props.participant.departmentName && ', '}

                <span>
                    {props.participant.departmentName}

                    {props.participant.positionName && ', '}

                    {props.participant.positionName}
                </span>
            </div>

            <div
                className={cn(
                    styles.considerationPeriod,
                    isConsiderationPeriodOverdue &&
                        styles.considerationPeriodOverdue
                )}
            >
                {formatDate(props.considerationPeriod)}
            </div>

            <div className={styles.statusIcon}>
                <IconsAdapter iconType={renderStatusIcon(props.status)} />

                <hr className={styles.statusLine} />
            </div>

            <div className={styles.status}>
                {APPROVE_STATUSES[props.status]}
            </div>

            <div className={styles.comment}>{' - '}</div>
        </div>
    )
})
