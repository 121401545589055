import React, { useCallback, useState } from 'react'
import { ActivityLessonPassingService, HomeworkStatusType } from 'core/api'
import { Button, HomeworkTaskNumberTitle } from 'components/shared'
import { HomeworkStatusTag } from 'components/shared/HomeworkStatusTag'
import { LOCAL } from 'core/local'
import {
    isHomeworkOnRevision,
    isHomeworkWaitingForSolution,
    safelySetInnerHtml,
} from 'utils'
import { withLoader } from 'HOCs'

import styles from './UserSubtaskBlock.module.scss'
import { AnswerField } from '../AnswerField'
import { UserFields } from '../UserFields'
import { UserSubtaskBlockProps } from './UserSubtaskBlock.types'
import { UserUploadFile } from '../UserUploadFile'

/** Блок подзадания для пользователя*/
export const UserSubtaskBlock: React.FC<UserSubtaskBlockProps> = withLoader(
    React.memo(
        ({
            field,
            form,
            homeworkId,
            setSendingTaskIds,
            sendingTaskIds,
            isHomeworkAutoCheck,
            updateLoader,
            isLoading,
            isTasksTitlesVisible,
        }) => {
            const fieldValues = form?.getFieldValue([
                'taskPassings',
                field.name,
            ])
            const {
                status,
                taskNumber,
                taskText,
                comment,
                id: taskId,
                attachment,
            } = fieldValues

            /** currentStatus нужен для обновления статуса на ui после отправки ответа*/
            const [currentStatus, setCurrentStatus] = useState<
                HomeworkStatusType
            >(status)

            /** Вызов метода сохранения ответа на один вопрос */
            const saveAnswer = useCallback(async () => {
                try {
                    const answer = form?.getFieldValue([
                        'taskPassings',
                        field.name,
                        'answerText',
                    ])

                    const attachments = form?.getFieldValue([
                        'taskPassings',
                        field.name,
                        'attachments',
                    ])

                    setSendingTaskIds((prev) => [...prev, taskId])

                    await ActivityLessonPassingService.setTaskAnswer({
                        body: {
                            taskId,
                            answer,
                            homeworkId,
                            isHomeworkAutoCheck,
                            attachmentId:
                                attachments?.[0]?.response.id || attachment?.id,
                        },
                    })

                    setCurrentStatus(
                        isHomeworkAutoCheck
                            ? HomeworkStatusType.Passed
                            : HomeworkStatusType.OnChecking
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    setSendingTaskIds((prev) =>
                        prev.filter((el) => el !== taskId)
                    )
                }
            }, [
                form,
                field.name,
                taskId,
                homeworkId,
                isHomeworkAutoCheck,
                setSendingTaskIds,
                attachment,
            ])

            return (
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <HomeworkTaskNumberTitle
                            isTasksTitlesVisible={isTasksTitlesVisible}
                            taskNumber={taskNumber}
                        />
                        {!isHomeworkWaitingForSolution(currentStatus) && (
                            <HomeworkStatusTag
                                currentStatus={currentStatus}
                                type="user"
                                isTasksTitlesVisible
                            />
                        )}
                    </div>

                    <div
                        className={styles.text}
                        dangerouslySetInnerHTML={safelySetInnerHtml(taskText)}
                    />

                    <UserFields
                        currentStatus={currentStatus}
                        comment={comment}
                        isHomeworkAutoCheck={isHomeworkAutoCheck}
                    />

                    <>
                        <AnswerField
                            fieldName={field.name}
                            currentStatus={currentStatus}
                        />

                        <div className={styles.footer}>
                            <UserUploadFile
                                updateLoader={updateLoader}
                                loading={!!isLoading}
                                status={currentStatus}
                                fieldName={field.name}
                                uploadFile={attachment}
                                form={form}
                            />

                            {(isHomeworkWaitingForSolution(currentStatus) ||
                                isHomeworkOnRevision(currentStatus)) && (
                                <Button
                                    onClick={saveAnswer}
                                    loading={
                                        isHomeworkWaitingForSolution(
                                            currentStatus
                                        ) && sendingTaskIds.includes(taskId)
                                    }
                                >
                                    {LOCAL.ACTIONS.SEND}
                                </Button>
                            )}
                        </div>
                    </>
                </div>
            )
        }
    )
)
