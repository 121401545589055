import { StructuralUnitType } from 'core/api'

/**
 * Проверка типа структурной единицы
 * @param type - тип
 */
export const isStructuralUnitTypeOrganization = (type?: StructuralUnitType) =>
    type === StructuralUnitType.Organization
export const isStructuralUnitUnit = (type?: StructuralUnitType) =>
    type === StructuralUnitType.Unit
export const isStructuralUnitComplex = (type?: StructuralUnitType) =>
    type === StructuralUnitType.Complex
export const isStructuralUnitTypeOiv = (type?: StructuralUnitType) =>
    type === StructuralUnitType.Oiv
