import React from 'react'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import { ControlGroup } from 'components/controls'
import { DynamicFormRows } from 'components/shared'
import { Form } from 'antd'
import { LOCAL } from 'core/local'

/** Область "слушатели" для формы назначения мероприятия */
export const ParticipantsArea: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => (
    <ControlGroup
        type="bordered"
        titleOutside={LOCAL.LABELS.LISTENERS}
        required
    >
        <Form.Item noStyle name="participantIds">
            <DynamicFormRows
                formMode={formMode}
                addBtnText={LOCAL.ACTIONS.ADD_PARTICIPANTS}
                disabled={disabled}
            />
        </Form.Item>
    </ControlGroup>
))
