export const EDITOR_TEMPLATE_EXTRA_PLUGINS_LIST = [
    'font',
    'colorbutton',
    'copyformatting',
    'justify',
]

export const EDITOR_TEMPLATE_TOOLBAR_OPTIONS = [
    {
        name: 'basicstyles',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'Strike',
            '-',
            'CopyFormatting',
            'RemoveFormat',
        ],
    },
    {
        name: 'actions',
        items: ['Undo', 'Redo'],
    },
    {
        name: 'paragraph',
        items: ['NumberedList', 'BulletedList', '-', 'Blockquote', 'Link'],
    },
    {
        name: 'insert',
        items: ['Table', 'HorizontalRule'],
    },
    {
        name: 'alignment',
        items: ['JustifyLeft', 'JustifyRight', 'JustifyBlock', 'JustifyCenter'],
    },
    '/',
    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
    { name: 'colors', items: ['TextColor', 'BGColor'] },
    { name: 'tools', items: ['Maximize', 'Source'] },
]
