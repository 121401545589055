import { useCallback, useEffect } from 'react'

export interface UsePreventCopyPasteProps {
    element?: HTMLElement
    manual?: boolean
}

export function usePreventCopyPaste({
    element,
    manual,
}: UsePreventCopyPasteProps = {}) {
    const target = element || document
    const preventAllActions = (event: any) => event.preventDefault()

    const subscribeOnActions = useCallback(() => {
        target.addEventListener('contextmenu', preventAllActions)
        target.addEventListener('selectstart', preventAllActions)
        target.addEventListener('copy', preventAllActions)
        target.addEventListener('cut', preventAllActions)
        target.addEventListener('paste', preventAllActions)
    }, [target])

    const unSubscribeOnActions = useCallback(() => {
        target.removeEventListener('contextmenu', preventAllActions)
        target.removeEventListener('selectstart', preventAllActions)
        target.removeEventListener('copy', preventAllActions)
        target.removeEventListener('cut', preventAllActions)
        target.removeEventListener('paste', preventAllActions)
    }, [target])

    useEffect(() => {
        if (manual) return

        subscribeOnActions()

        return unSubscribeOnActions
    }, [manual, subscribeOnActions, unSubscribeOnActions])

    return { subscribeOnActions, unSubscribeOnActions }
}
