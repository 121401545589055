import React from 'react'
import { LOCAL } from 'core/local'
import { Spoiler } from 'components/shared/Spoiler'
import { Title } from 'components/shared'

import styles from './ProductCardContent.module.scss'
import { ProductCardContentProps } from './ProductCardContent.types'
import { renderSpoilerTitle } from './ProductCardContent.utils'

/** Карточка продукта - отображение разделов продукта  */
export const ProductCardContent: React.FC<ProductCardContentProps> = React.memo(
    ({ sections }) => (
        <div className={styles.wrapper}>
            <Title type="h1" text={LOCAL.LABELS.SUBSTANCE} />

            {sections.map((section) => (
                <Spoiler
                    className={styles.partition}
                    spoilerTitleRender={renderSpoilerTitle(
                        section.name,
                        !!section.subSections.length
                    )}
                    key={section.id}
                >
                    {section.subSections.map((subSection) => (
                        <p className={styles.subpartition} key={subSection.id}>
                            {subSection.name}
                        </p>
                    ))}
                </Spoiler>
            ))}
        </div>
    )
)
