import React from 'react'
import { DictionaryContract, TextDocumentSearchContract } from 'core/api'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'

/**
 * Сокращенная форма фильтрации текстовых документов
 */
export const TextDocumentsFilters: React.FC<FiltersProps<
    TextDocumentSearchContract | DictionaryContract
>> = React.memo((props) => (
    <FilterForm {...props}>
        <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
            <InputControl />
        </FormItemAdapter>
    </FilterForm>
))
