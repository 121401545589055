import { LOCAL } from 'core/local'

export const POSITION_PROFILES = [
    {
        key: 'yes',
        value: 1,
        label: LOCAL.LABELS.POSITION_PROFILE_ATTACHMENT,
    },
    {
        key: 'no',
        value: 0,
        label: LOCAL.LABELS.POSITION_PROFILE_NOT_ATTACHMENT,
    },
]
