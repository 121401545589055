import { ColumnsType } from 'antd/lib/table'
import { CourseAppointmentContract } from 'core/api'
import { LOCAL } from 'core/local'
import { formatDate, getCourseNameWithVersion } from 'utils'

export const COURSE_ACTIVE_APPOINTMENTS_TABLE_COLUMNS: ColumnsType<CourseAppointmentContract> = [
    {
        title: LOCAL.LABELS.APPOINTMENT_NAME,
        dataIndex: 'appointmentName',
        key: 'appointmentName',
    },
    {
        title: LOCAL.LABELS.ACTIVITY_OR_TRACK_NAME,
        dataIndex: 'relatedEntityName',
        key: 'relatedEntityName',
    },
    {
        title: LOCAL.LABELS.APPOINTMENT_START_DATE,
        dataIndex: 'startDate',
        key: 'startDate',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.CONTENT_VERSION,
        dataIndex: 'course',
        key: 'course',
        render: getCourseNameWithVersion,
    },
]
