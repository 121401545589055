import React, { FC } from 'react'
import {
    ErrorPage,
    QuestionnaireTemplates,
    QuestionnairesConstructor,
} from 'components/pages'
import { ProtectedRoute } from 'core/permissions'
import { QUESTIONNAIRES_REQUIRED_PERMISSIONS } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { StoreContextProvider } from 'components/pages/Questionnaires/Questionnaires.context'
import { UserPermission } from 'core/api'

export const QuestionnairesRouting: FC = React.memo(() => (
    <StoreContextProvider>
        <Switch>
            <ProtectedRoute
                requiredPermissions={[
                    ...QUESTIONNAIRES_REQUIRED_PERMISSIONS,
                    UserPermission.QuestionnaireSave,
                ]}
                path={`${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_COPY}/:id`}
            >
                <QuestionnairesConstructor formMode="copy" />
            </ProtectedRoute>

            <ProtectedRoute
                requiredPermissions={QUESTIONNAIRES_REQUIRED_PERMISSIONS}
                path={`${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW}/:id`}
            >
                <QuestionnairesConstructor formMode="view" />
            </ProtectedRoute>

            <ProtectedRoute
                requiredPermissions={[
                    ...QUESTIONNAIRES_REQUIRED_PERMISSIONS,
                    UserPermission.QuestionnaireSave,
                ]}
                path={`${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_EDIT}/:id`}
            >
                <QuestionnairesConstructor formMode="edit" />
            </ProtectedRoute>

            <ProtectedRoute
                requiredPermissions={[
                    ...QUESTIONNAIRES_REQUIRED_PERMISSIONS,
                    UserPermission.QuestionnaireSave,
                ]}
                path={ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_CREATE}
            >
                <QuestionnairesConstructor formMode="create" />
            </ProtectedRoute>

            <ProtectedRoute
                requiredPermissions={QUESTIONNAIRES_REQUIRED_PERMISSIONS}
                path={ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES}
            >
                <QuestionnaireTemplates />
            </ProtectedRoute>

            <Route>
                <ErrorPage errorCode="404" />
            </Route>
        </Switch>
    </StoreContextProvider>
))
