import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import { BlockPicker } from 'react-color'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { isFormModeView } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './ColorPickerControl.module.scss'
import { BLOCK_PICKER_COLORS } from './ColorPickerControl.consts'
import { ColorPickerControlProp } from './ColorPickerControl.types'

/**
 * Контрол для выбора цвета
 */
export const ColorPickerControl: React.FC<ColorPickerControlProp> = React.memo(
    ({ label, value, onChange, disabled, formMode, className }) => {
        const [color, setColor] = useState('#000000')

        const handleChangeColor = useCallback(
            (color) => {
                setColor(color.hex)
                onChange?.(color.hex)
            },
            [onChange]
        )

        const {
            elementVisible,
            toggleElementVisible,
            toggleElementVisibleRef,
            elementRef,
        } = useElementVisible()

        return (
            <div className={cn(styles.wrapper, 'form-control', className)}>
                <Button
                    className={styles.toggle}
                    type="link"
                    onClick={toggleElementVisible}
                    ref={toggleElementVisibleRef}
                    disabled={isFormModeView(formMode) || disabled}
                >
                    <div className={styles.icon}>
                        <i
                            style={{
                                backgroundColor: value || color,
                            }}
                        />

                        <IconsAdapter iconType="CaretDownOutlined" />
                    </div>

                    {label && <div className={styles.text}>{label}</div>}
                </Button>

                {elementVisible && (
                    <div className={styles.dropdown} ref={elementRef}>
                        <BlockPicker
                            color={value || color}
                            onChangeComplete={handleChangeColor}
                            colors={Object.values(BLOCK_PICKER_COLORS)}
                        />
                    </div>
                )}
            </div>
        )
    }
)
