import { LOCAL } from 'core/local'

export const ADJACENT_COMPETENCIES_TABLE_COLUMNS = [
    {
        title: `${LOCAL.LABELS.OIV}/${LOCAL.LABELS.COMPETENCIES}`,
        dataIndex: 'label',
        key: 'label',
        width: '100%',
    },
]
