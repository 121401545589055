import React, { useCallback } from 'react'
import {
    ACTIVITIES_TABLE_COLUMNS,
    ActivitiesFilters,
    CheckboxControl,
    ControlGroup,
    FormItemAdapter,
    InputControl,
    InputNumberControl,
    RadioControl,
    SearchSelectControl,
    ShouldUpdateChecker,
    SwitchControl,
    TRAINING_MATERIALS_TABLE_COLUMNS,
    TrainingMaterialsFilters,
} from 'components/controls'
import {
    ActivitiesService,
    ActivityDecorationType,
    ActivityModalContract,
    ActivityModalSearchContract,
    CourseForModalContract,
    CourseSearchContract,
    CoursesService,
    DocumentStatus,
} from 'core/api'
import { AddonAfterDefault, UploadAvatar } from 'components/shared'
import {
    FiltersProps,
    isFormModeView,
    isMaterialTypeActivity,
    isMaterialTypeContent,
    normalizeFile,
} from 'utils'
import { Form, Space } from 'antd'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    TRACK_STAGES_MATERIAL_TYPE_MAPPED,
    TRACK_STAGE_EVENTS,
    TRAINING_MATERIALS_PERMISSIONS,
} from 'consts'
import { USE_INFORMATION_ASSISTANT_FIELD_NAME } from 'components/pages/Track/components/TrackForm/TrackForm.consts'

import styles from './TrackStageFields.module.scss'
import { AddonAfterActions } from '../AddonAfterActions'
import { COURSES_FORMATS_TO_EXCLUDE } from './TrackStageFields.consts'
import { InfoAssistantOptions } from '../InfoAssistantOptions'
import { TrackStageFieldsProps } from './TrackStageFields.types'
import { handleChangeMaterial } from '../AddonAfterActions/AddonAfterActions.utils'

/**
 * Поля для конкретного этапа трека
 */
export const TrackStageFields: React.FC<TrackStageFieldsProps> = React.memo(
    ({ stage, formMode, disabled }) => {
        const FilterComponent = useCallback(
            (props: FiltersProps<CourseSearchContract>) => (
                <TrainingMaterialsFilters
                    {...props}
                    excludeCourseFormats={COURSES_FORMATS_TO_EXCLUDE}
                />
            ),
            []
        )

        return (
            <div>
                <FormItemAdapter
                    fieldName={[stage.name, 'title']}
                    label={LOCAL.LABELS.STAGE_NAME}
                >
                    <InputControl formMode={formMode} />
                </FormItemAdapter>

                <FormItemAdapter fieldName={[stage.name, 'materialType']}>
                    <RadioControl
                        values={TRACK_STAGES_MATERIAL_TYPE_MAPPED}
                        direction="vertical"
                        disabled={disabled}
                        formMode={formMode}
                    />
                </FormItemAdapter>

                <ShouldUpdateChecker
                    fieldPath={[
                        ['stages', stage.name, 'materialType'],
                        ['goalIds'],
                    ]}
                >
                    {(form) => {
                        const {
                            stages,
                            status,
                            goalIds,
                        } = form.getFieldsValue(['stages', 'status', 'goalIds'])

                        const getByIdsMethod = async (params: {
                            body?: any | null[]
                        }) => {
                            const response = await ActivitiesService.getByIds(
                                params
                            )

                            form.setFields([
                                {
                                    name: [
                                        'stages',
                                        stage.name,
                                        'totalThematicBlocksCount',
                                    ],
                                    value: response[0].lessons.length,
                                },
                            ])

                            return response
                        }

                        const {
                            materialType,
                            activityId: activityData,
                            courseId: courseData,
                        } = stages?.[stage.name] || {}

                        const courseId = courseData?.[0].value

                        return (
                            <>
                                {isMaterialTypeActivity(materialType) && (
                                    <FormItemAdapter
                                        fieldName={[stage.name, 'activityId']}
                                        label={LOCAL.LABELS.EVENT_M}
                                    >
                                        <SearchSelectControl<
                                            ActivityModalSearchContract,
                                            ActivityModalContract
                                        >
                                            getByIdsMethod={getByIdsMethod}
                                            disabled={disabled}
                                            addonAfter={
                                                <AddonAfterActions
                                                    materialId={
                                                        activityData?.[0].value
                                                    }
                                                    trackStatus={status}
                                                    formMode={formMode}
                                                    onConfirm={handleChangeMaterial(
                                                        stage,
                                                        form
                                                    )}
                                                    getByIdsMethod={
                                                        ActivitiesService.getByIds
                                                    }
                                                />
                                            }
                                            formMode={formMode}
                                            modalTitle={
                                                LOCAL.LABELS.EVENT_M_SELECTION
                                            }
                                            tableSearchOptions={{
                                                tableFiltersOptions: {
                                                    initialValues: {
                                                        statuses: [
                                                            DocumentStatus.InWork,
                                                            DocumentStatus.ReadyForWork,
                                                        ],
                                                        decorationType:
                                                            ActivityDecorationType.Visualization,
                                                        developmentGoalIds: goalIds,
                                                    },
                                                },
                                                filterComponent: ActivitiesFilters,
                                                tableColumns: ACTIVITIES_TABLE_COLUMNS,
                                                searchMethod:
                                                    ActivitiesService.getForModal,
                                            }}
                                        />
                                    </FormItemAdapter>
                                )}

                                {isMaterialTypeContent(materialType) && (
                                    <FormItemAdapter
                                        fieldName={[stage.name, 'courseId']}
                                        label={LOCAL.LABELS.CONTENT}
                                    >
                                        <SearchSelectControl<
                                            CourseSearchContract,
                                            CourseForModalContract
                                        >
                                            getByIdsMethod={
                                                CoursesService.getByIds
                                            }
                                            disabled={disabled}
                                            formMode={formMode}
                                            modalTitle={
                                                LOCAL.LABELS.CONTENT_SELECTION
                                            }
                                            addonAfter={
                                                <AddonAfterDefault
                                                    url={`${ROUTE_NAMES.TRAINING_MATERIALS}/${courseId}`}
                                                    disabled={!courseId}
                                                    permissionsRequired={
                                                        TRAINING_MATERIALS_PERMISSIONS
                                                    }
                                                />
                                            }
                                            tableSearchOptions={{
                                                filterComponent: FilterComponent,
                                                tableColumns: TRAINING_MATERIALS_TABLE_COLUMNS,
                                                searchMethod:
                                                    CoursesService.getForModal,
                                                tableFiltersOptions: {
                                                    initialValues: {
                                                        excludeFormats: COURSES_FORMATS_TO_EXCLUDE,
                                                    },
                                                },
                                            }}
                                        />
                                    </FormItemAdapter>
                                )}
                            </>
                        )
                    }}
                </ShouldUpdateChecker>

                <FormItemAdapter
                    label={LOCAL.LABELS.NUMBER_OF_ATTEMPTS_TO_PASS}
                >
                    <Space align="center">
                        <Form.Item
                            noStyle
                            dependencies={[
                                stage.name,
                                'isActivityAttemptCountLimited',
                            ]}
                        >
                            {({ getFieldValue }) => {
                                const stages = getFieldValue('stages')
                                const { isActivityAttemptCountLimited } =
                                    stages?.[stage.name] || {}

                                return (
                                    <Form.Item
                                        name={[
                                            stage.name,
                                            'activityAttemptCount',
                                        ]}
                                        noStyle
                                    >
                                        <InputNumberControl
                                            min={0}
                                            max={100}
                                            disabled={
                                                isActivityAttemptCountLimited ||
                                                disabled
                                            }
                                            formMode={formMode}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>

                        <Form.Item
                            noStyle
                            dependencies={[stage.name, 'activityAttemptCount']}
                        >
                            {({ getFieldValue }) => {
                                const stages = getFieldValue('stages')
                                const { activityAttemptCount } =
                                    stages?.[stage.name] || {}

                                return (
                                    <Form.Item
                                        name={[
                                            stage.name,
                                            'isActivityAttemptCountLimited',
                                        ]}
                                        noStyle
                                    >
                                        <SwitchControl
                                            addonAfter={
                                                LOCAL.STATUSES.NO_RESTRICTED
                                            }
                                            disabled={
                                                Boolean(activityAttemptCount) ||
                                                disabled ||
                                                isFormModeView(formMode)
                                            }
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Space>
                </FormItemAdapter>

                <Form.Item name={[stage.name, 'showContentPicture']} noStyle>
                    <CheckboxControl
                        className={styles.attachIcon}
                        formMode={formMode}
                    >
                        {LOCAL.LABELS.ATTACH_ICON_IN_CONTENT}
                    </CheckboxControl>
                </Form.Item>

                <ShouldUpdateChecker
                    fieldPath={['stages', stage.name, 'showContentPicture']}
                >
                    {({ getFieldValue }) =>
                        getFieldValue([
                            'stages',
                            stage.name,
                            'showContentPicture',
                        ]) && (
                            <Form.Item
                                name={[stage.name, 'contentIcon']}
                                getValueFromEvent={normalizeFile}
                                noStyle
                            >
                                <UploadAvatar formMode={formMode} />
                            </Form.Item>
                        )
                    }
                </ShouldUpdateChecker>

                <Form.Item
                    noStyle
                    dependencies={[USE_INFORMATION_ASSISTANT_FIELD_NAME]}
                >
                    {({ getFieldValue }) => {
                        const useInformationAssistant = getFieldValue(
                            USE_INFORMATION_ASSISTANT_FIELD_NAME
                        )

                        return (
                            useInformationAssistant && (
                                <ControlGroup
                                    title={
                                        LOCAL.LABELS.INFORMATION_ASSISTANT_SET
                                    }
                                    noPadding
                                >
                                    <InfoAssistantOptions
                                        eventTypes={TRACK_STAGE_EVENTS}
                                        formListName={[
                                            stage.name,
                                            'assistantEvents',
                                        ]}
                                        disabled={disabled}
                                        formMode={formMode}
                                    />
                                </ControlGroup>
                            )
                        )
                    }}
                </Form.Item>
            </div>
        )
    }
)
