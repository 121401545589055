import toArray from 'lodash/toArray'
import React, { useCallback, useEffect } from 'react'
import { Col, Row } from 'antd'
import { DATE_FORMAT, ROW_GUTTER } from 'consts'
import {
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import {
    DictionaryContract,
    NotificationDestinationSubCategoriesService,
    NotificationDestinationSubCategoryContract,
    NotificationEventsService,
    NotificationFilteringConditionContract,
    NotificationFilteringConditionsService,
    NotificationMailingTaskAppointmentObjectSearchContract,
    NotificationMailingTaskAppointmentObjectSearchResultContract,
    NotificationMailingTasksService,
} from 'core/api'
import { LOCAL } from 'core/local'
import { TooltipAdapter } from 'components/shared'
import {
    disableDate,
    getDayWithTime,
    isFormModeEdit,
    isMailingStatusSuspended,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { useHttp } from 'hooks'

import { AppointmentObjectsSelectionFilter } from '../AppointmentObjectsSelectionFilter'
import { Appointments } from '../Appointments'
import { Conditions } from '../Conditions'
import { DISTRIBUTION_APPOINTMENT_OBJECT_DEPENDENCIES } from './DistributionMailingParamsByEvent.consts'
import { DistributionMailingParamsProps } from '../DistributionMailingParams/DistributionMailingParams.types'
import { ON_MAP_DISTRIBUTION_RESPONSE_DATA } from '../DistributionMailingAssignmentForm/DistributionMailingAssignmentForm.events'
import {
    checkMailFieldsAndAlert,
    normalizeRatioByCode,
} from '../DistributionMailingAssignmentForm/DistributionMailingAssignmentForm.utils'
import { getTableObjectsColumnsByCategoryCode } from '../DistributionMailingParams/DistributionMailingParams.utils'

export const DistributionMailingParamsByEvent: React.FC<DistributionMailingParamsProps> = React.memo(
    ({ formMode, form, initialValues }) => {
        const [fetchEvents, events] = useHttp<DictionaryContract[]>(
            NotificationEventsService.getForSelect
        )

        const [fetchSubCategoriesByCategoryId, subCategories] = useHttp<
            NotificationDestinationSubCategoryContract[]
        >(NotificationDestinationSubCategoriesService.getForSelectByCategory)
        const [fetchConditions, conditions] = useHttp<
            NotificationFilteringConditionContract[]
        >(NotificationFilteringConditionsService.getForSelect)

        useEffect(() => {
            fetchEvents()
        }, [fetchEvents])

        const handleChange = useCallback(
            async (id) => {
                if (!id) return

                const event = await NotificationEventsService.getActive({
                    id,
                })

                const prevCategoryId = form?.getFieldValue('category')?.value
                const category = event.category

                if (category) {
                    if (prevCategoryId && prevCategoryId !== category.id) {
                        checkMailFieldsAndAlert(form, category.name)

                        form?.setFieldsValue({
                            appointmentObjects: [],
                        })
                    }

                    fetchSubCategoriesByCategoryId({
                        categoryId: category.id,
                    })
                }
                form?.setFieldsValue({
                    category: normalizeRatioByCode(category),
                })

                fetchConditions({ eventId: id })
            },
            [form, fetchSubCategoriesByCategoryId, fetchConditions]
        )

        const onCategoryChange = useCallback(() => {
            handleChange(initialValues?.event?.id)
        }, [handleChange, initialValues])

        useEffect(() => {
            eventEmitter.once(
                ON_MAP_DISTRIBUTION_RESPONSE_DATA,
                onCategoryChange
            )

            return () => {
                eventEmitter.off(
                    ON_MAP_DISTRIBUTION_RESPONSE_DATA,
                    onCategoryChange
                )
            }
        }, [onCategoryChange])

        return (
            <>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={12}>
                        <FormItemAdapter
                            fieldName="mailingStartDate"
                            label={LOCAL.LABELS.MAILING_START}
                            initialValue={getDayWithTime()}
                            required
                        >
                            <DatePickerControl
                                formMode={formMode}
                                showTime={{
                                    format: DATE_FORMAT.HH_mm,
                                }}
                                format={DATE_FORMAT.LIST_WITH_TIME}
                                disabledDate={disableDate({ withTime: true })}
                                disabled={
                                    isFormModeEdit(formMode) &&
                                    isMailingStatusSuspended(
                                        initialValues?.status
                                    )
                                }
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={12}>
                        <FormItemAdapter
                            fieldName="mailingEndDate"
                            label={LOCAL.LABELS.MAILING_END}
                        >
                            <DatePickerControl
                                formMode={formMode}
                                showTime={{
                                    format: DATE_FORMAT.HH_mm,
                                }}
                                format={DATE_FORMAT.LIST_WITH_TIME}
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>

                <TooltipAdapter title={LOCAL.TOOLTIPS.EMAILS_LIST}>
                    <FormItemAdapter
                        fieldName="randomRecipientEmails"
                        label={LOCAL.LABELS.MAILING_EMAILS}
                    >
                        <InputControl
                            formMode={formMode}
                            placeholder={LOCAL.PLACEHOLDERS.INPUT_EMAIL}
                        />
                    </FormItemAdapter>
                </TooltipAdapter>

                <FormItemAdapter
                    fieldName="eventId"
                    label={LOCAL.LABELS.EVENT_C}
                    required
                >
                    <SelectControl
                        formMode={formMode}
                        showSearch
                        values={
                            events?.map(normalizeDataForSelectAndRadio) || []
                        }
                        onChange={handleChange}
                    />
                </FormItemAdapter>

                <ShouldUpdateChecker
                    fieldPath={DISTRIBUTION_APPOINTMENT_OBJECT_DEPENDENCIES.map(
                        toArray
                    )}
                >
                    {({ getFieldsValue }) => {
                        const {
                            category,
                            subCategory,
                            eventId,
                            appointmentObjects,
                        } = getFieldsValue(
                            DISTRIBUTION_APPOINTMENT_OBJECT_DEPENDENCIES
                        )

                        return (
                            category &&
                            eventId && (
                                <>
                                    {!!subCategories?.length && (
                                        <FormItemAdapter
                                            fieldName="subCategory"
                                            label={
                                                LOCAL.LABELS
                                                    .MAILING_CATEGORY_SUBTYPE
                                            }
                                        >
                                            <SelectControl
                                                formMode={formMode}
                                                allowClear
                                                values={subCategories?.map(
                                                    normalizeRatioByCode
                                                )}
                                                labelInValue
                                            />
                                        </FormItemAdapter>
                                    )}

                                    <Appointments<
                                        NotificationMailingTaskAppointmentObjectSearchContract,
                                        NotificationMailingTaskAppointmentObjectSearchResultContract
                                    >
                                        form={form}
                                        formMode={formMode}
                                        listFieldName="appointmentObjects"
                                        controlTitle={
                                            LOCAL.LABELS.APPOINTMENT_OBJECTS
                                        }
                                        modalTitle={
                                            LOCAL.LABELS
                                                .APPOINTMENT_OBJECTS_SELECTION
                                        }
                                        initialValues={{
                                            appointmentObjects,
                                            category,
                                            subCategory,
                                            eventId,
                                        }}
                                        getByIdsMethod={
                                            NotificationMailingTasksService.getAppointmentObjectsByEventAndIds
                                        }
                                        tableSearchOptions={{
                                            filterComponent: AppointmentObjectsSelectionFilter,
                                            searchMethod:
                                                NotificationMailingTasksService.searchAppointmentObjects,
                                            tableColumns: getTableObjectsColumnsByCategoryCode(
                                                category.key
                                            ),
                                            tableFiltersOptions: {
                                                initialValues: {
                                                    categoryId: category.value,
                                                    subCategoryId:
                                                        subCategory?.value,
                                                },
                                            },
                                        }}
                                    />
                                </>
                            )
                        )
                    }}
                </ShouldUpdateChecker>

                <ShouldUpdateChecker fieldPath={['eventId']}>
                    {({ getFieldValue }) =>
                        getFieldValue('eventId') &&
                        !!conditions?.length && (
                            <Conditions
                                form={form}
                                formMode={formMode}
                                conditions={conditions}
                            />
                        )
                    }
                </ShouldUpdateChecker>
            </>
        )
    }
)
