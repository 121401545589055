import React, { useCallback, useMemo, useRef, useState } from 'react'
import { BackTop } from 'components/shared'
import { Button } from 'components/shared/Button'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { Popup } from 'components/shared/Popup'
import { safelySetInnerHtml } from 'utils'
import { useForm } from 'antd/lib/form/Form'

import styles from './TaskPassingForm.module.scss'
import { TaskPassingFormProps } from './TaskPassingForm.types'
import { UserSubtaskBlock } from './components'

/** Форма выполнения ДЗ без использования редактора */
export const TaskPassingForm: React.FC<TaskPassingFormProps> = React.memo(
    ({ homework, popupProps, isHomeworkAutoCheck, isOverdue }) => {
        const [form] = useForm()
        const ref = useRef<HTMLDivElement>(null)
        const [sendingTaskIds, setSendingTaskIds] = useState<number[]>([])
        const {
            taskPassings,
            content,
            id: homeworkId,
            isTasksTitlesVisible,
        } = homework

        const isModalDisabled = useMemo(() => !!sendingTaskIds.length, [
            sendingTaskIds,
        ])

        const modalFooter = useMemo(
            () => (
                <Button
                    onClick={popupProps.onCancel}
                    disabled={isModalDisabled}
                >
                    {LOCAL.ACTIONS.RETURN}
                </Button>
            ),
            [popupProps.onCancel, isModalDisabled]
        )

        const getTarget = useCallback(
            () => ref.current?.closest('.ant-modal-body'),
            []
        )

        return (
            <Popup
                footer={modalFooter}
                className={styles.wrapper}
                width={MODAL_WIDTH.XL}
                closable={!isModalDisabled}
                centered
                {...popupProps}
            >
                <div ref={ref}>
                    <BackTop getTarget={getTarget} />

                    <Form
                        form={form}
                        id={FORM_IDS.TASK_PASSING_FORM}
                        initialValues={{ taskPassings }}
                    >
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={safelySetInnerHtml(
                                content
                            )}
                        />

                        <Form.List name="taskPassings">
                            {(fields) =>
                                fields.map((field) => (
                                    <UserSubtaskBlock
                                        field={field}
                                        form={form}
                                        key={field.key}
                                        homeworkId={homeworkId}
                                        setSendingTaskIds={setSendingTaskIds}
                                        sendingTaskIds={sendingTaskIds}
                                        isHomeworkAutoCheck={
                                            !!isHomeworkAutoCheck
                                        }
                                        isOverdue={isOverdue}
                                        isTasksTitlesVisible={
                                            isTasksTitlesVisible
                                        }
                                    />
                                ))
                            }
                        </Form.List>
                    </Form>
                </div>
            </Popup>
        )
    }
)
