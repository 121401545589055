import React from 'react'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'

import { DocumentsArea } from './DocumentsArea'
import { VideoFilesArea } from './VideoFilesArea'

/**
 * Компонент отображения дополнительных материалов
 */
export const AdditionalMaterials: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => (
    <>
        <DocumentsArea formMode={formMode} disabled={disabled} />

        <VideoFilesArea formMode={formMode} disabled={disabled} />
    </>
))
