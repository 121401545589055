import React, { useCallback, useMemo } from 'react'
import {
    AddButton,
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
} from 'components/shared'
import { CompetenciesIndicatorForm } from 'components/forms/CompetenciesIndicatorForm'
import { CustomControlCommonProps } from 'App.types'
import {
    DictionaryContract,
    IndicatorContract,
    IndicatorsSearchContract,
    IndicatorsService,
} from 'core/api'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { FORM_IDS } from 'core/configs'
import {
    INDICATORS_TABLE_COLUMNS,
    IndicatorsFilters,
    SearchTableShowButton,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { isFormModeView, reorderDnDRow } from 'utils'

import styles from './IndicatorsControl.module.scss'
import { INDICATORS_SEARCH_METHOD_BODY } from './IndicatorsControl.consts'
import { IndicatorItem } from './components'

export const IndicatorsControl: React.FC<CustomControlCommonProps<
    DictionaryContract[]
>> = React.memo(({ onChange, formMode, value = [] }) => {
    /**
     * Запрос выбранных индикаторов
     * @param ids - ids индикаторов для запроса
     */
    const fetchSelectedIndicators = useCallback(
        async (ids?: number[]) => {
            try {
                const fetchedIndicators = await IndicatorsService.getByIds({
                    body: ids,
                })

                onChange?.(
                    fetchedIndicators.map((el) => ({
                        id: el.id,
                        name: el.name,
                    }))
                )
            } catch (error) {
                console.error(error)
            }
        },
        [onChange]
    )

    /**
     * Обработчик добавления выбранных записей
     */
    const handleSelectIndicators = useCallback(
        (result?: number[]) => {
            if (result?.length) {
                fetchSelectedIndicators(result)
            }
        },
        [fetchSelectedIndicators]
    )

    /**
     * Обработчик удаления индикатора
     * @param id - id индикатора
     */
    const handleIndicatorDelete = useCallback(
        (id: number) => () => {
            if (!value || !onChange) return

            onChange(value.filter((el) => el.id !== id))
        },
        [onChange, value]
    )

    /**
     * Обработчик окончания dnd
     * @param result - объект данных с реальзатами dnd
     */
    const handleDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) return

            onChange?.(
                reorderDnDRow(
                    result?.source?.index,
                    result?.destination?.index,
                    value
                ) || []
            )
        },
        [value, onChange]
    )

    const handleRequestFinish = useCallback(
        (result?: IndicatorContract) => {
            if (result)
                onChange?.([
                    ...(value || []),
                    { id: result.id, name: result.name },
                ])
        },
        [onChange, value]
    )

    /**
     * Начальные значения (уже выбранные записи)
     */
    const initialValues = useMemo(() => value?.map((el) => el.id), [value])

    return (
        <section className={styles.wrapper}>
            <header className={styles.header}>
                <h2 className={styles.title}>{LOCAL.LABELS.INDICATORS}</h2>

                <ButtonsToolbar bottomIndent="0" className={styles.toolbar}>
                    <SearchTableShowButton<
                        IndicatorsSearchContract,
                        IndicatorContract
                    >
                        tableSearchOptions={{
                            formId: FORM_IDS.SEARCH_INDICATORS,
                            rowSelectionType: 'checkbox',
                            onSelectTableItem: handleSelectIndicators,
                            searchMethod: IndicatorsService.search,
                            tableColumns: INDICATORS_TABLE_COLUMNS,
                            filterComponent: IndicatorsFilters,
                            initialValues,
                            tableFiltersOptions: {
                                initialValues: INDICATORS_SEARCH_METHOD_BODY,
                            },
                        }}
                        modalTitle={LOCAL.LABELS.INDICATOR_SELECTION}
                    >
                        <AddButton disabled={isFormModeView(formMode)} />
                    </SearchTableShowButton>

                    <PopupWithForm
                        component={CompetenciesIndicatorForm}
                        formId={FORM_IDS.COMPETENCIES_INDICATOR_FORM}
                        buttonText={LOCAL.ACTIONS.CREATE}
                        onRequestFinish={handleRequestFinish}
                        modalOptions={{
                            title: LOCAL.LABELS.INDICATOR_CREATE,
                            footer: null,
                        }}
                        formOptions={{
                            initialValues: {
                                fromCompetence: true,
                            },
                        }}
                        buttonOption={{
                            type: 'link',
                            icon: (
                                <IconsAdapter iconType="CustomIconLayerPlus" />
                            ),
                            disabled: isFormModeView(formMode),
                            className: styles.createButton,
                        }}
                        bodyWithoutScroll
                    />
                </ButtonsToolbar>
            </header>

            {!!value.length && (
                <div className={styles.content}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable
                            droppableId="droppable"
                            isDropDisabled={isFormModeView(formMode)}
                        >
                            {(provided) => (
                                <>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {value.map((item, index) => (
                                            <IndicatorItem
                                                key={item.id}
                                                index={index}
                                                id={item.id}
                                                name={item.name}
                                                onDelete={handleIndicatorDelete(
                                                    item.id
                                                )}
                                                formMode={formMode}
                                            />
                                        ))}
                                    </div>

                                    {provided.placeholder}
                                </>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )}
        </section>
    )
})
