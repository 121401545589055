import React from 'react'
import { Button } from 'components/shared/Button'

import { RenderAccessDeniedButton } from './HasPermissions.types'

/**
 * Рендер кнопки для страницы, если для взаимодействия с ней у пользователя не хватает прав доступа
 */
export const renderAccessDeniedButton = ({
    text,
    ...otherProps
}: RenderAccessDeniedButton) => (
    <Button disabled {...otherProps}>
        {text}
    </Button>
)
