import React, { FC, useCallback } from 'react'
import { Form } from 'antd'
import { HiddenField, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { NotificationTagsService } from 'core/api'
import { isFormModeEdit } from 'utils'
import { withLoader } from 'HOCs'

import { DistributionTagFormProps } from './DistributionTagForm.types'

/** Форма редактирования тега рассылки */
export const DistributionTagForm: FC<DistributionTagFormProps> = withLoader(
    React.memo(
        ({
            initialValues,
            id,
            onRequestFinish,
            formMode,
            updateLoader,
            updateButtonVisibility,
        }) => {
            /**
             * Обработчик отправки формы
             * @param value значение формы
             */
            const handleFinish = useCallback(
                async (body) => {
                    try {
                        updateLoader(true)
                        updateButtonVisibility?.(true)

                        await (isFormModeEdit(formMode)
                            ? NotificationTagsService.update
                            : NotificationTagsService.create)({
                            body,
                        })

                        onRequestFinish?.()
                    } catch (error) {
                        console.error(error)
                    } finally {
                        updateLoader(false)
                        updateButtonVisibility?.(false)
                    }
                },
                [
                    updateLoader,
                    updateButtonVisibility,
                    formMode,
                    onRequestFinish,
                ]
            )

            return (
                <Form
                    id={id}
                    onFinish={handleFinish}
                    initialValues={initialValues}
                >
                    <HiddenField fieldName="id" />

                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: LOCAL.ERRORS.EMPTY_TAG_NAME,
                            },
                        ]}
                    >
                        <InputControl />
                    </Form.Item>
                </Form>
            )
        }
    )
)
