import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { formatDate } from 'utils'

import { NormalizeStructuralUnitPositionsResultProps } from './Organizations.types'
import {
    renderIsActiveColumn,
    renderOrganizationStructureColumn,
    renderPositionProfileColumn,
    renderUsernameColumn,
} from './Organizations.render'

export const ORGANIZATIONS_TABLE_COLUMNS: ColumnsType<NormalizeStructuralUnitPositionsResultProps> = [
    {
        title: LOCAL.LABELS.ORG_STRUCTURE,
        dataIndex: 'name',
        key: 'name',
        render: renderOrganizationStructureColumn,
    },
    {
        dataIndex: 'username',
        key: 'username',
        render: renderUsernameColumn,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'active',
        key: 'active',
        render: renderIsActiveColumn,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.POSITION_PROFILE,
        dataIndex: 'positionProfile',
        key: 'positionProfile',
        render: renderPositionProfileColumn,
        fixed: 'right',
    },
]
