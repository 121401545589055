export const MESSAGES = {
    ABORT_FILE_DOWNLOAD: 'Вы уверены, что хотите прервать загрузку файла?',
    ACCESS_DENIED: 'В доступе отказано',
    ACCESS_ERROR: 'Ошибка доступа',
    ADD_MIN_LESSON_COUNT_MESSAGE: 'Добавьте минимум 1 тематический блок',
    ADD_TO_FAVORITES: 'Продукт успешно добавлен в избранное',
    ALL_MANDATORY_BLOCKS_FINISH_CONFIRM_MESSAGE:
        'Вы завершили прохождение обязательных блоков в "<%= eventName %>". \nХотите завершить?',
    ALL_MANDATORY_QUESTIONS_FINISH_CONFIRM_MESSAGE:
        'Вы ответили на все обязательные вопросы. \nЖелаете завершить работу?',
    ALL_MANDATORY_QUESTIONS_NEED_TO_BE_ANSWERED:
        'Необходимо ответить на все обязательные вопросы',
    ALL_QUESTIONS_FINISH_CONFIRM_MESSAGE:
        'Вы ответили на все вопросы. \nЖелаете завершить работу?',
    ANALOG_TASKS: 'Аналогичные задания на рассылку:',
    APPOINTMENT_RESULTS: 'Результаты прохождения',
    APPROVE_SEND_SUCCESS_MESSAGE: 'Лист успешно отправлен на согласование!',
    ASSESSMENTS_ASSIGNMENTS_REMOVE_PARTICIPANT:
        'Данный участник будет удален из назначения. Продолжить?',
    ASSESSMENTS_PROJECTS_RESPONDENTS_VALIDATION_ERROR:
        'Невозможно опубликовать - общее количество респондентов во всех группах должно быть не менее пяти и как минимум 1 руководитель',
    ASSESSMENT_EVALUATION_SAVE_SUCCESS_MESSAGE:
        'Результаты оценки успешно сохранены!',
    ASSESSMENT_PARAMETERS_ROW_DELETE_ERROR_MESSAGE:
        'Должен быть как минимум один участник',
    ASSESSMENT_PROJECTS_CREATE_SUCCESS_MESSAGE:
        'Проект оценки успешно добавлен!',
    ASSESSMENT_PROJECTS_SAVE_SUCCESS_MESSAGE: 'Проект оценки успешно сохранен!',
    ASSESSMENT_REPORT_UPDATE_SUCCEESS_MESSAGE:
        'Шаблон отчета успешно обновлен!',
    ASSESSMENT_TEMPLATE_CREATE_SUCCESS_MESSAGE:
        'Портфель оценки успешно добавлен!',
    ASSESSMENT_TEMPLATE_SAVE_SUCCESS_MESSAGE:
        'Портфель оценки успешно сохранен!',
    ASSIGNMENT_PUBLISH_SUCCESS_MESSAGE: 'Назначение успешно создано',
    ASSIGNMENT_SAVE_SUCCESS_MESSAGE: 'Назначение успешно сохранено',
    ATTACH_FILE:
        'Форматы загрузки: <%= attachFormats %> \n Максимальный размер: 20 Mb',
    ATTENTION: 'Внимание!',
    ATTENTION_CONFIRM_DELETE:
        'Внимание! Выбранный элемент будет удален. \nХотите продолжить?',
    AVAILABILITY_STAGE_FOR_PASSING: 'Этап станет доступен для прохождения',
    BEFORE_CHANGE_QUESTIONS_ORDER:
        'При включении чек-бокса все внесенные настройки не сохранятся.',
    BROWSER_DO_NOT_SUPPORT_VIDEO_FORMAT:
        'Простите, к сожалению ваш браузер не поддерживает MP4/AVI видео формат',
    CANCEL_MAILING: 'Уверены что хотите отменить рассылку',
    CANCEL_MAILING_ASSIGNMENT:
        'Будут отменены все рассылки, запланированные по данному заданию. Уверены, что хотите отменить задание на рассылку?',
    CATEGORY_CHANGED: 'Вид объекта назначения изменен',
    CATEGORY_CHANGED_TO: 'Вид объекта назначения изменен на',
    CHANGES_SAVED: 'Изменения сохранены',
    CHANGES_UNSAVED: 'Возможно, внесенные изменения не сохранятся',
    CHECK_HOMEWORK_FROM_CATALOG:
        'Проверка домашних заданий пользователя при запуске из Каталога будет осуществляться автоматически',
    CHOOSE_ORGANIZATION_BEFORE:
        'Выберите организацию, у которой есть подразделения',
    CHOOSE_REPORT_TYPE: 'Выберите тип отчета для настройки',
    COMPARE_ANSWERS_WITH_CHECKED_TASK:
        'Сравните ответы с проверяемым заданием, для того чтобы убедиться в правильности ответа пользователя и одним нажатием скопировать понравившийся комментарий другого тьютор',
    COMPARISON_MESSAGE_MAX_ASSESSMENT_RESULTS:
        'Одновременно не более 3 результатов для сравнения',
    COMPETENCE_PUBLISH_SUCCESS_MESSAGE: 'Компетенция успешно опубликована!',
    COMPETENCE_REPORT_LEVEL:
        'Компетенции, которые были отнесены к <%= level %> уровню развития',
    COMPETENCE_SAVE_SUCCESS_MESSAGE: 'Компетенция успешно сохранена!',
    CONFIRM_TITLE_DEFAULT: 'Подтвердите действие!',
    COURSE_UPLOAD_ERROR: 'Ошибка обработки курса',
    COURSE_VERSION_UPDATE_SUCCESS: 'Версия курса успешно обновлена',
    CREATE_NEW_VERSION_CONFIRM_MESSAGE:
        'Будет создана новая версия. Вы уверены?',
    CREATE_RECORD_WITH_NEW_RESULT: 'Запустить с сохранением новых результатов?',
    CURRENT_PAGE_IN_DEVELOPMENT:
        'На данный момент текущая страница находится в разработке',
    DEFAULT_ERROR: 'Что-то пошло не так.',
    DELETE_CONFIRM: 'Уверены, что хотите удалить?',
    DICTIONARIES_UPDATED: 'Справочники успешно обновлены!',
    DOWNLOAD_CANCELED: 'Загрузка отменена',
    DRAG_OR_CLICK_TO_UPLOAD_FILE:
        'Нажмите или перетащите в эту область файлы для загрузки',
    EDITING_SECTION: 'Редактирование раздела',
    EDIT_DISTRIBUTION_TAG: 'Редактирование тега',
    EMPLOYEES_TOOK_PART: 'Приняло участие <%= count %>% сотрудников',
    ERROR_FETCH_CERTIFICATES: 'Произошла ошибка при получении сертификатов',
    ERROR_SEND_CERTIFICATES_TO_EMAIL:
        'Ошибка при отправке сертификата на почтовый адрес',
    ERROR_UPLOAD_CERTIFICATES_TEMPLATE_FILE:
        'Ошибка загрузки шаблона для формирования внутренних сертификатов',
    ERROR_VIEW_CERTIFICATE: 'Произошла ошибка при просмотре сертификата',
    ESTABLISHED_PASSING_TIME_RESTRICTION:
        'Установлено ограничение на время прохождения',
    EVALUATION_FINISHED: 'Оценка завершена! Благодарим за потраченное время',
    EVENT_FORM_POPUP_INFO_MESSAGE: 'Заполните вид и тип мероприятия',
    EVENT_IN_WORK_CONFIRM_EDIT:
        'Внимание! Мероприятие находится в статусе "В работе", не все поля доступны для редактирования. После редактирования мероприятия необходимо повторно прикрепить его к этапу трека. Форма мероприятия откроется в новой вкладке.',
    EVENT_M_CONFIRM_EDIT:
        'Внимание! После редактирования мероприятия необходимо повторно прикрепить его к этапу трека. Форма мероприятия откроется в новой вкладке.',
    EVENT_ON_EDIT: 'Извините, данное мероприятие находится на редактировании',
    EVENT_PUBLISH_SUCCESS_MESSAGE: 'Мероприятие успешно опубликовано!',
    EVENT_SAVE_SUCCESS_MESSAGE: 'Мероприятие успешно сохранено!',
    EVENT_SUCCESSFUL_COMPLETE: 'Мероприятие завершено успешно',
    EXCLUDE_USERS_CONFIRM_MESSAGE:
        'Внимание! Выбранные пользователи будут исключены из назначения. Хотите продолжить?',
    EXTERNAL_CERTIFICATE_PUBLISH_SUCCESS_MESSAGE:
        'Внешний сертификат успешно опубликован!',
    EXTERNAL_CERTIFICATE_SAVE_SUCCESS_MESSAGE:
        'Внешний сертификат успешно сохранен!',
    FAVORITES_REQUEST_ERROR: 'При обработке продукта произошла ошибка',
    FILE_UPLOADED_SUCCESSFULLY: 'Файл успешно загружен',
    FILE_USED_IN_ACTIVE_IN_APPOINTMENT:
        'Данный файл используется в активных назначениях',
    GO_BACK_TO_PREV_PAGE_MESSAGE: 'Вернуться на предыдущую страницу?',
    GO_TO_PAGE: 'Перейти на страницу',
    GO_TO_REGISTER: 'Перейти в реестр',
    HOMEWORK_AUTOCHECK_PASSED_MESSAGE:
        'Дорогой участник! Проверка задания не может быть осуществлена. \nОбразовательный контент доступен бессрочно, но уже без участия тьютора.',
    HOMEWORK_ON_CHECK: 'Документ отправлен на проверку тьютору.',
    HOMEWORK_ON_CHECKING:
        'Задание на проверке у тьютора. Когда проверка завершится, вам придёт уведомление',
    HOMEWORK_ON_REVISION: 'Задание на доработке у пользователя',
    HOMEWORK_PASSED: 'Задание зачтено.',
    HOMEWORK_SENT_FOR_REVISION: 'Задание отправлено на доработку пользователю.',
    IMAGE_FILE_SIZE_VALIDATION_ERROR_MESSAGE:
        'Размер изображение должен быть не более <%= fileSize %>МБ!',
    IMAGE_FILE_TYPE_VALIDATION_ERROR_MESSAGE:
        'Вы можете загрузить только JPG/PNG файлы!',
    IMPORT_SUCCESS_MESSAGE: 'Импорт завершен!',
    INTERNAL_CERTIFICATE_SEND_TO_MAIL_SUCCESS:
        'Сертификат успешно отправлен на почту',
    INTERNAL_CERTIFICATE_TEMPLATE_UPLOAD_SUCCESS:
        'Шаблон для создания внутренних сертификатов был успешно загружен',
    INVITE_TO_METTING: 'Приглашаем Вас на встречу, которая будет проходить',
    INVITE_TO_WEBINAR: 'Приглашаем Вас на вебинар, который будет проходить',
    IS_WANTED_TO_PASS_AGAIN:
        'Желаете пройти <%= type %> еще раз? В случае повторного прохождения предыдущий результат засчитан не будет',
    JOB_PROFILE_PUBLISH_SUCCESS_MESSAGE: 'Профиль успешно опубликован!',
    JOB_PROFILE_SAVE_SUCCESS_MESSAGE: 'Профиль успешно сохранен!',
    MAILING_ASSIGNMENT_HAS_DUPLICATES:
        'Задание на рассылку полностью или частично дублирует другие активные задания. Это может привести к отправке нескольких уведомлений одним и тем же пользователям.',
    MAILING_ASSIGNMENT_HAS_DUPLICATES_CONFIRM_TEXT:
        'Уверены, что хотите запланировать рассылки?',
    MAILING_PUBLISH_SUCCESS_MESSAGE:
        'Задание на рассылку успешно опубликовано!',
    MAILING_SAVE_SUCCESS_MESSAGE: 'Задание на рассылку успешно сохранено!',
    MAILING_SCHEDULE_SUCCESS_MESSAGE: 'Рассылка успешно запланирована!',
    MAILING_SEND_SUCCESS_MESSAGE:
        'Рассылка успешно поставлена в очередь на отправку!',
    MEETING_CONFIRMED: 'Встреча подтверждена',
    NEED_TO_PASS_ALL_ANSWERS:
        'Вам нужно ответить на все вопросы. Проявите внимательность при выборе ответа',
    NOTIFICATIONS_DISABLED:
        'Получение сообщений отключено пользователем в личном кабинете',
    NOT_ALL_QUESTIONS_ANSWERED_CONFIRM_MESSAGE:
        'Вы ответили не на все вопросы. \nЖелаете завершить работу?',
    NOT_FOUND: 'Не найдено',
    NO_ACTIVE_APPOINTMENTS: 'Активных назначений пока что нет',
    NO_COMMENT: 'Нет комментария',
    NO_QUESTION_ANSWERED_CONFIRM_MESSAGE:
        'Вы не ответили ни на один вопрос. \nЖелаете завершить работу?',
    NO_RESULTS_FOUND_FOR_SEARCH: 'По Вашему запросу ничего не найдено',
    NUMBER_PARTICIPANTS_FROM_APPOINTED:
        'Количество участников от числа назначенных',
    OVERALL_FILE_SIZE_ARE_BIGGER_THAN:
        'Загрузка не выполнена. Суммарный размер файлов превышает (<%= fileSize %>) МБ',
    PORTFOLIO_FINAL_MESSAGE:
        '<%= portfolioType %> завершен! Благодарим за потраченное время',
    PORTFOLIO_ON_EDIT:
        'Извините, данный <%= portfolio %> находится на редактировании',
    POSITION_PROFILE_MISSING: 'Профиль должности отсутствует.',
    PRODUCT_CARD_ACCESS_DENIED: 'Карточка в процессе обновления',
    PRODUCT_CARD_PUBLISH_SUCCESS_MESSAGE:
        'Карточка продукта успешно опубликована!',
    PRODUCT_CARD_SAVE_SUCCESS_MESSAGE: 'Карточка продукта успешно сохранена!',
    PRODUCT_IS_ALREADY_EXIST:
        'В каталоге уже размещена карточка с выбранным продуктом!',
    PROFILE_CANNOT_BE_DELETED:
        'Профиль не может быть удален и будет перенесен в архив!',
    PROGRAM_AVAILABLE_AT_YOUR_DEVELOPMENT:
        'Данная программа доступна в разделе "Ваше развитие"',
    PUBLISH_SUCCESS_MESSAGE_FEMALE: '<%= entity %> успешно опубликована!',
    PUBLISH_SUCCESS_MESSAGE_MALE: '<%= entity %> успешно опубликован!',
    PUT_CURSOR_TO_PASTE_CHART:
        'Поставьте курсор в редакторе там, куда следует разместить график',
    QUESTIONNAIRES_GENERATE_SUCCESS: 'Опросные листы успешно сформированы',
    QUESTION_CONTAINS_VIDEO_ATTACHMENT: 'Вопрос содержит вложенный видеофайл',
    QUESTION_TIME_LIMIT_ENDED: 'Отведенное для ответа время истекло',
    QUIZ_COMPLETE: 'Опрос подтвержден',
    QUIZ_PASSED_EMPLOYEES_COUNT:
        'Опрос прошло <%= finishedCount %> сотрудников из <%= respondentsCount %>.',
    QUIZ_PASSING_AVG_TIME: 'Среднее время прохождения опроса',
    QUIZ_PORTFOLIO_GREETING_TEXT:
        'Вам нужно ответить на вопросы. Проявите внимательность при выборе ответа',
    QUIZ_PORTFOLIO_PUBLISH_SUCCESS_MESSAGE: 'Портфель опроса опубликован!',
    QUIZ_PORTFOLIO_SAVE_SUCCESS_MESSAGE: 'Портфель опроса сохранен!',
    RECORD_TO_ARCHIVE_CONFIRM: 'Уверены, что хотите отправить запись в архив?',
    REMOVE_FROM_FAVORITES: 'Продукт удален из избранного',
    REPORT_HIDDEN_OPPORTUNITIES_AND_PROBLEMS_MESSAGE:
        'В этом разделе мы обращаем Ваше внимание на те индикаторы, по которым Ваша самооценка значимо отличается от оценки других людей. Данная информация поможет раскрыть неочевидные для Вас, но существующие, возможности и проблемы.',
    RESEND_TASKS_MAILING:
        'Будет выполнена повторная попытка отправки всех неотправленных сообщений в рассылках, выбранных в реестре. Уверены, что хотите повторить отправку?',
    RESEND_TASKS_MAILING_SUCCESS_NOTIFICATION:
        'Выполняется повторная отправка сообщений по выбранным рассылкам',
    SAVE_FILTERS: 'Внимание! Применены фильтры.',
    SAVE_SUCCESS_MESSAGE_FEMALE: '<%= entity %> успешно сохранена!',
    SAVE_SUCCESS_MESSAGE_MALE: '<%= entity %> успешно сохранен!',
    SCALE_PUBLISH_SUCCESS_MESSAGE: 'Шкала успешно создана!',
    SCALE_SAVE_SUCCESS_MESSAGE: 'Шкала успешно сохранена!',
    SECTION_HAS_ACTIVE_ARTICLES: 'Данный раздел содержит активные статьи.',
    SELECTED_FILE_FOR_OWNER_ALREADY_EXISTS:
        'Выбранный файл для данного владельца уже есть в системе',
    SELECTED_FILE_FOR_OWNER_IS_PROGRESS:
        'Выбранный файл для данного владельца уже загружается автором',
    SHOW_CORRECT_ANSWER_TO_QUESTION: 'Показывать правильный ответ на вопрос',
    SHOW_OVERALL_TEST_SCORE: 'Показывать общий результат прохождения теста',
    SWITCH_CONTROLS_QUESTION_ACTIVITY:
        'Это переключатель управляет активностью вопроса. Неактивные вопросы не будут использованы в опросном листе',
    TAB_REVIEW_AFTER_INSTRUCTION:
        'После завершения оценки ответов нажмите на кнопку «рассчитать».',
    TAB_REVIEW_INSTRUCTION:
        'Для проверки письменных ответов нажмите на наименование Организации и проставьте оценку каждому ответу в соответствующей ячейке;',
    TASKS_CREATE_SUCCESS_MESSAGE: 'Задания успешно добавлены',
    TASKS_UPDATE_SUCCESS_MESSAGE: 'Задания успешно обновлены',
    TEMPLATE_CHANGED:
        'Тема и тело сообщения будут обновлены по выбранному шаблону. Применить шаблон',
    TEMPLATE_PUBLISH_SUCCESS_MESSAGE: 'Шаблон успешно опубликован!',
    TEMPLATE_SAVE_SUCCESS_MESSAGE: 'Шаблон успешно сохранен!',
    TEST_IS_PASSING: 'Тест пройден!',
    TEST_PASSING_AVG_TIME: 'Среднее время прохождения теста',
    TEST_PORTFOLIO_PUBLISH_SUCCESS_MESSAGE: 'Портфель теста опубликован!',
    TEST_PORTFOLIO_SAVE_SUCCESS_MESSAGE: 'Портфель теста сохранен!',
    TEST_REVIEW_SAVE_SUCCESS_MESSAGE: 'Результаты проверки успешно сохранены!',
    THANKS_FOR_FEEDBACK: 'Спасибо за обратную связь!',
    THEME_DELETE_CONTAIN_PRODUCT_CARDS_MESSAGE:
        'Уверены, что хотите удалить? Тематический раздел содержит <%= productCardCount %> карточек продукта. \nКарточки продукта не будут отображаться в Каталоге.',
    THIS_RESPONDENT_IS_ALREADY_CHOSEN: 'Данный респондент уже выбран',
    TIME_IS_UP: 'Время истекло',
    TIME_IS_UP_INFO_MESSAGE:
        'К сожалению, установленное для прохождения время истекло. \nБлагодарим за уделенное время.',
    TIME_IS_UP_TEST_PASSING_MESSAGE:
        'К сожалению, установленное для прохождения время - ',
    TRACK_ADAPTATION_FORM_PUBLISH_SUCCESS_MESSAGE:
        'Трек адаптации успешно опубликован!',
    TRACK_ADAPTATION_FORM_SAVE_SUCCESS_MESSAGE:
        'Трек адаптации успешно сохранен!',
    TRACK_DEVELOPMENT_FORM_PUBLISH_SUCCESS_MESSAGE:
        'Трек развития успешно опубликован!',
    TRACK_DEVELOPMENT_FORM_SAVE_SUCCESS_MESSAGE:
        'Трек развития успешно сохранен!',
    TRACK_FEEDBACK_FORM: 'Анкета обратной связи по треку',
    TRACK_STAGE_EVENT_REPASSING:
        'Повторное прохождение мероприятия этапа трека',
    TRAINING_MATERIAL_IS_NOT_FINISHED:
        'Обучающий материал пройден не полностью. \nХотите выйти без сохранения прогресса?',
    UNKNOWN_SERVER_ERROR: 'Неизвестная ошибка сервера',
    UPLOAD_FILE_SIZE_LIMIT_MESSAGE:
        'Размер файла не должен превышать <%= maxSize %>МБ',
    VERSION_CHOOSE_AS_MAIN: 'Версия выбрана в качестве основной',
    VERSION_DELETED: 'Версия удалена',
    VIEW_JOB_FOR_MAILING_MESSAGE:
        'С момента отправки сообщений данной рассылки задание на рассылку было изменено. Будет отображено текущее задание. Продолжить?',
    VIEW_UPLOADED_ATTACHMENT:
        'Просмотр файла будет доступен после загрузки. Загрузить файл?',
    WRONG_FORMAT: 'Данный формат файла не поддерживается. Файл не сохранен',
    WRONG_SIZE_LIMIT:
        'Превышен максимальный размер загружаемого файла. Файл не сохранен.',
    YOU_CAN_ANSWER_QUESTIONS_IN_ANY_ORDER:
        'Вы можете отвечать \nна вопросы в любом порядке',
    YOU_CAN_ANSWER_QUESTIONS_IN_SEQUENTIAL_ORDER:
        'Вы можете отвечать \nна вопросы в последовательном порядке',
    YOU_CAN_GO_TO: 'Вы можете перейти на',
    YOU_CAN_ONLY_UPLOAD_FORMATS_TEMPLATE:
        'Вы можете загружать только <%= documentType %> файлы',
    YOU_HAVE_COMPLETED_MEETING: 'Встреча успешно завершена',
    YOU_HAVE_ENROLLED_SUCCEFULLY: `Ваша заявка отправлена. \t
                                                                Ждите подтверждения на электронную почту`,
    YOU_HAVE_LEFT_AMOUNT_OF_PASSING_ATTEMPTS:
        'У вас осталось <%= amount %> попыток прохождения',
    YOU_HAVE_MISSED_MEETING: 'Вы пропустили встречу',
    YOU_HAVE_USED_LAST_PASSING_ATTEMPT:
        'Вы использовали последнюю попытку прохождения. Текущий результат прохождения будет засчитан как окончательный',
    YOU_SCORED_POINTS: 'Вы набрали <%= accrued %> баллов из <%= total %>',
}

export const DEPENDENCIES_MESSAGES = {
    ASSESSMENT:
        'При изменении данного поля, портфель и объекты оценки придется заполнять заново...',
    QUESTIONNAIRES:
        'При изменении данного поля, список опросных листов придется заполнять заново...',
    QUESTIONS:
        'При изменении данного поля, список вопросов придется заполнять заново...',
}

export const getRightsRestrictionsMessage = (missingAccessRights?: string) =>
    `У вас недостаточно следующих прав для просмотра данной страницы: ${missingAccessRights}. Обратитесь в службу технической поддержки`

export const MAKE_ORDER_UNIT_LABEL = (orderNumber: number) =>
    `Подразделение ${orderNumber} порядка`

export const MAKE_UPLOAD_FILE_LIMIT_MESSAGE = (maxCount: number) =>
    `Нельзя загрузить файл, максимальное количество загруженных - ${maxCount}`

export const getConfirmEditMessage = (
    type: 'assessment' | 'event' | 'quiz' | 'test' | 'track',
    isAssignment?: boolean
) => {
    const names = {
        assessment: 'Данная оценка',
        event: 'Данное мероприятие',
        quiz: 'Данный опрос',
        test: 'Данный тест',
        track: 'Данный трек',
    }

    return `Внимание! ${names[type]} уже используется в ${
        isAssignment ? 'назначении' : 'работе'
    }. Хотите продолжить редактирование?`
}
