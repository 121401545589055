import {
    AssessmentType,
    QuestionnaireType,
    QuizType,
    ResultCalculatingType,
    TestSequence,
} from 'core/api'
import { createFieldsRow } from 'utils/form.utils'

import { QuestionnairesConstructorFormSubmitValues } from './QuestionnairesConstructorForm.types'

export enum RespondentTypeIdDictionary {
    // Эксперт
    'Expert' = 1,

    // Оцениваемый
    'Estimated' = 2,

    // Не задано
    'NotSet' = 3,
}

export enum ResultCalculatingMethodIdDictionary {
    // Среднее значение по компетенциям
    'CompetencyAverage' = 1,

    // Экспертная оценка
    'ExpertReview' = 2,

    // Оценка по результатам вовлеченности
    'ImpactAssessment' = 3,

    // Другое (значения пока не определены)
    'Other' = 4,
}

export enum ResultEstimateScaleTypesDictionary {
    // Бальная шкала оценки (0-5)
    'GradeScale05' = 1,

    // Шкала оценки сильных и слабых сторон
    'StrengthWeaknessRatingScale' = 2,

    // Шкала согласия/несогласия (1-3)
    'ConsentDisagreeScale13' = 3,

    // Шкала согласия/несогласия (1-5)
    'ConsentDisagreeScale15' = 4,
}

export const INITIAL_FORM_VALUES = ({
    type: QuestionnaireType.Test,
    timeLimit: 30,
    sequence: TestSequence.Consistently,
    respondentTypeId: RespondentTypeIdDictionary.NotSet,
    assessmentEstimateScaleTypeId:
        ResultEstimateScaleTypesDictionary.GradeScale05,
    assessmentResultCalculatingMethodId:
        ResultCalculatingMethodIdDictionary.CompetencyAverage,
    assessmentType: AssessmentType.Estimate_360,
    quizType: QuizType.Feedback,
    maxPoints: 0,
    resultAssessmentType: {
        resultCalculatingType: ResultCalculatingType.TotalPoints,
        resultLevels: [
            {
                name: undefined,
                pointsFrom: 0,
                pointsTo: undefined,
                ...createFieldsRow(),
            },
        ],
    },
    competenceId: null,
    randomQuestionsOrder: true,
    questions: undefined,
} as unknown) as Partial<QuestionnairesConstructorFormSubmitValues>

/**
 * Массив зависимых полей, при изменении которых происходит обновление состояния формы
 */
export const fieldsShouldUpdate = [
    'competenceId',
    'type',
    'assessmentType',
    'assessmentEstimateScaleTypeId',
    'respondentTypeId',
    'assessmentResultCalculatingMethodId',
    'quizType',
    'maxPoints',
]

/**
 * Массив зависимых полей, при изменении которых происходит сброс значения для поля `questions`
 */
export const questionsDependencyFields = [
    'type',
    'respondentTypeId',
    'assessmentType',
]

/**
 * Сброс значения для поля `questions`
 * @param key - ключ поля, которое было изменено
 */
export const resetQuestionsValueViaDependency = (key: string) => {
    if (questionsDependencyFields.includes(key)) {
        return { questions: undefined }
    }

    return {}
}
