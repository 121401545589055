import React from 'react'
import { FnActionProps } from 'App.types'
import { Menu } from 'antd'
import { NavLink } from 'react-router-dom'

import styles from './MobileNavigation.module.scss'
import { ADMIN_MENU_ITEMS } from '../AdminMenu/AdminMenu.consts'
import {
    AdminGroupMenuProps,
    AdminMenuChildProps,
} from '../AdminMenu/AdminMenu.types'
import { MAIN_MENU_ITEMS } from '../MainMenu/MainMenu.consts'
import { PROFILE_MENU_ITEMS } from '../ProfileMenu/ProfileMenuList/ProfileMenuList.consts'

const { SubMenu } = Menu

export const getMainMenu = (collapseMenu: FnActionProps) =>
    MAIN_MENU_ITEMS.map((menuItem) => (
        <Menu.Item key={menuItem.title} title="">
            <NavLink to={menuItem.link} onClick={collapseMenu}>
                {menuItem.title}
            </NavLink>
        </Menu.Item>
    ))

export const getProfileMenu = (collapseMenu: FnActionProps) =>
    PROFILE_MENU_ITEMS.map((menuItem) => (
        <Menu.Item key={menuItem.title} title="">
            <NavLink to={menuItem.link} onClick={collapseMenu}>
                {menuItem.title}
            </NavLink>
        </Menu.Item>
    ))

export const getAdminMenu = (collapseMenu: FnActionProps) =>
    ADMIN_MENU_ITEMS.map((item: AdminGroupMenuProps, index) => (
        <SubMenu key={index} title={item.title}>
            {item.children?.map((menuItem: AdminMenuChildProps) => (
                <Menu.Item key={menuItem.key} title="" className={styles.item}>
                    <li>
                        <NavLink to={menuItem.link} onClick={collapseMenu}>
                            {menuItem.title}
                        </NavLink>
                    </li>
                </Menu.Item>
            ))}
        </SubMenu>
    ))
