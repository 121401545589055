import React, { FC, memo } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { QuestionPublicContract } from 'core/api'
import {
    QuestionnairePictureAttachment,
    VideoAttachment,
} from 'components/shared'
import { checkVideoAttachment } from 'utils'

import styles from './PassingCompetenceQuestion.module.scss'
import {
    getQuestionComponent,
    getQuestionName,
} from './PassingCompetenceQuestion.utils'

/** Вопрос прохождения */
export const PassingCompetenceQuestion: FC<QuestionPublicContract> = memo(
    (question) => {
        const {
            number,
            title: titleProp,
            mandatoryQuestion,
            type,
            text,
        } = question
        const QuestionComponent = getQuestionComponent(type)
        const title = titleProp ? (
            <span className={styles.titleText}>{` — ${titleProp}`}</span>
        ) : (
            ''
        )
        const file = question.attachments[0]
        const hasVideoAttachment = checkVideoAttachment(file?.fileName)

        return (
            <div className={styles.wrapper}>
                <h3 className={styles.title}>
                    {`${LOCAL.LABELS.QUESTION} ${number}`}

                    {title}

                    {mandatoryQuestion && (
                        <IconsAdapter
                            iconType="ExclamationCircleOutlined"
                            className={styles.icon}
                        />
                    )}
                </h3>

                {text && <p className={styles.text}>{text}</p>}

                {hasVideoAttachment && (
                    <VideoAttachment questionAttachment={file} />
                )}

                {QuestionComponent && (
                    <QuestionComponent
                        {...question}
                        name={getQuestionName(question)}
                    />
                )}

                {!hasVideoAttachment && (
                    <QuestionnairePictureAttachment file={file} />
                )}
            </div>
        )
    }
)
