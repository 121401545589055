import React, { FC } from 'react'
import { AssessmentType } from 'core/api'
import { ContentTitle, PageContent } from 'components/shared'
import { REPORTS } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getBackLinkTitleFromRoute } from 'utils'
import { useParams } from 'react-router'
import { withLoader } from 'HOCs'

import styles from './AssessmentReport.module.scss'
import { AssessmentReportContent } from './AssessmentReportContent'
import { UrlParamsProps } from './AssessmentReport.types'

/** Отчет по оценке */
export const AssessmentReport: FC = withLoader(
    React.memo(({ updateLoader }) => {
        const { type } = useParams<UrlParamsProps>()

        /**
         * Заголовок отчета
         */
        const getTitle = () => {
            switch (type) {
                case AssessmentType.Estimate_360:
                    return REPORTS[AssessmentType.Estimate_360]
                case AssessmentType.EstimateByManager:
                    return REPORTS[AssessmentType.EstimateByManager]
                default:
                    return ''
            }
        }

        return (
            <PageContent className={styles.page}>
                <ContentTitle
                    transparent
                    disableBoxShadow
                    title={getTitle()}
                    backLink={{
                        path: ROUTE_NAMES.ASSESSMENT_REPORTS,
                        text: getBackLinkTitleFromRoute(
                            ROUTE_NAMES.ASSESSMENT_REPORTS
                        ),
                    }}
                />

                <AssessmentReportContent
                    updateLoader={updateLoader}
                    assessmentType={type}
                />
            </PageContent>
        )
    })
)
