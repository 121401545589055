export const ERRORS = {
    ADAPTATION_TRACK_EVENT_PASSING_VISUALIZATION: {
        description: 'У мероприятия не заполнены настройки визуализации.',
        title: 'Ошибка визуализации',
    },
    EMPTY_MAIL_BODY: 'Не указано тело письма',
    EMPTY_MAIL_SUBJECT: 'Не указана тема письма',
    EMPTY_TAG_NAME: 'Не указано наименование тега',
    EMPTY_TEMPLATE_NAME: 'Не указано наименование шаблона',
    INCORRECT_TYPE: 'Формат файла не поддерживается',
    MAX_LENGTH_MAIL_SUBJECT: 'Тема письма должна быть не более 255 символов',
    MAX_LENGTH_TEMPLATE_NAME:
        'Наименование шаблона должно быть не более 100 символов',
    UPLOAD_COURSE:
        'Заполните обязательные поля: Владелец, Выбрать файл, Наименование',
}
