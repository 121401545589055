import React, { useMemo, useState } from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import {
    QuizCheckRegistryContract,
    QuizCheckRegistrySearchContract,
    QuizCheckStatus,
    QuizCheckingService,
} from 'core/api'
import { TableRowSelection } from 'antd/lib/table/interface'
import { getPageTitleFromRoute } from 'utils'
import { getRowClassNameExpired } from 'components/shared/TableAdapter/TableAdapter.utils'
import { useHttp } from 'hooks'
import { useLocation } from 'react-router-dom'

import { QuizCheckingFilters } from './components/QuizCheckingFilters'
import { getTableColumns } from './QuizChecking.config'
import { renderPageContent } from './QuizChecking.utils'

/**
 * Личный кабинет пользователя: проверка опросов
 */
export const QuizChecking: React.FC = React.memo(() => {
    const [selectedRows, setSelectedRows] = useState<number[]>([])

    const [confirmQuiz] = useHttp(QuizCheckingService.confirmMany)

    const location = useLocation()

    const rowSelection: TableRowSelection<QuizCheckRegistryContract> = useMemo(
        () => ({
            type: 'checkbox',
            onChange: (selectedRowKeys: React.Key[]) => {
                setSelectedRows(selectedRowKeys as number[])
            },
        }),
        [setSelectedRows]
    )

    return (
        <DictionaryPage<
            QuizCheckRegistrySearchContract,
            QuizCheckRegistryContract
        >
            dictionaryKey={DICTIONARY_KEYS.QUIZ_CHECKING}
            searchMethod={QuizCheckingService.search}
            contentTitle={getPageTitleFromRoute(location.pathname)}
            contentTitleType="h5"
            tableColumnsRender={getTableColumns}
            quickSearchMethod={QuizCheckingService.searchText}
            filterComponent={QuizCheckingFilters}
            withPageBanner={false}
            rowSelection={rowSelection}
            rowClassName={getRowClassNameExpired}
            pageContentAfterRender={renderPageContent({
                confirmQuiz,
                selectedRows,
            })}
            tableFiltersOptions={{
                initialValues: {
                    statuses: [QuizCheckStatus.WaitForSolution],
                },
            }}
        />
    )
})
