import React, { useCallback, useState } from 'react'
import { ActivityContract, UserPermission } from 'core/api'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { EVENTS_REQUIRED_PERMISSIONS } from 'consts'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    createConfirmPopup,
    isDocumentStatusInWork,
    isFormModeView,
} from 'utils'
import { useCheckPermissions } from 'hooks'

import { AddonAfterActionsProps } from './AddonAfterActions.types'

/** Кнопки действий после SearchSelectControl в этапе формы трека */
export const AddonAfterActions: React.FC<AddonAfterActionsProps> = React.memo(
    ({ materialId, trackStatus, formMode, onConfirm, getByIdsMethod }) => {
        const { hasPermissionsRequired } = useCheckPermissions()
        const [isLoading, updateLoader] = useState(false)

        const handleOpenMaterialForm = useCallback(async () => {
            try {
                if (!materialId) return

                updateLoader(true)

                const response = (await getByIdsMethod({
                    body: [materialId],
                })) as ActivityContract[]

                updateLoader(false)

                createConfirmPopup({
                    onOk: () => {
                        onConfirm?.()
                        window.open(`${ROUTE_NAMES.EVENTS_EDIT}/${materialId}`)
                    },
                    title: LOCAL.CATALOGS.EVENTS_EDIT,
                    okText: LOCAL.ACTIONS.CONFIRM,
                    cancelText: LOCAL.ACTIONS.CANCEL,
                    content: isDocumentStatusInWork(response?.[0].status)
                        ? LOCAL.MESSAGES.EVENT_IN_WORK_CONFIRM_EDIT
                        : LOCAL.MESSAGES.EVENT_M_CONFIRM_EDIT,
                })
            } catch (error) {
                console.error(error)
            }
        }, [materialId, getByIdsMethod, onConfirm])

        if (!materialId)
            return (
                <IconsAdapter
                    iconType="EyeInvisibleOutlined"
                    data-testid="EyeInvisibleOutlined"
                />
            )

        return (
            <ButtonsToolbar bottomIndent="0">
                <Button
                    type="link"
                    href={`${ROUTE_NAMES.EVENTS_VIEW}/${materialId}`}
                    rel="noopener noreferrer"
                    icon={<IconsAdapter iconType="EyeOutlined" />}
                    disabled={
                        isLoading ||
                        !hasPermissionsRequired(EVENTS_REQUIRED_PERMISSIONS)
                    }
                    targetBlank
                />

                {!(
                    isDocumentStatusInWork(trackStatus) ||
                    isFormModeView(formMode)
                ) && (
                    <Button
                        type="link"
                        onClick={handleOpenMaterialForm}
                        icon={<IconsAdapter iconType="EditOutlined" />}
                        disabled={
                            isLoading ||
                            !hasPermissionsRequired([
                                ...EVENTS_REQUIRED_PERMISSIONS,
                                UserPermission.EventSave,
                            ])
                        }
                        loading={isLoading}
                    />
                )}
            </ButtonsToolbar>
        )
    }
)
