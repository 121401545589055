import {
    APPOINTMENT_STATUS,
    TRACKS_APPOINTMENT_STATUSES,
    TRACK_TYPES,
} from 'consts'
import {
    AppointmentStatus,
    AppointmentTrackStatus,
    NotificationDestinationCategoryCode,
    NotificationDestinationSubCategoryCode,
    NotificationMailingTaskAppointmentSearchResultContract,
} from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'

import { APPOINTMENTS_TABLE_COLUMNS } from './DistributionMailingParamsByTime.consts'

const getColumnsByStatus = (
    categoryCode: NotificationDestinationCategoryCode
) => {
    switch (categoryCode) {
        case NotificationDestinationCategoryCode.Track:
            return [
                {
                    title: LOCAL.LABELS.TYPE,
                    dataIndex: 'type',
                    key: 'type',
                    render: (val: NotificationDestinationSubCategoryCode) =>
                        val && TRACK_TYPES[val],
                },
                {
                    title: LOCAL.LABELS.STATUS,
                    dataIndex: 'trackStatus',
                    key: 'trackStatus',
                    render: (val: AppointmentTrackStatus) =>
                        val && TRACKS_APPOINTMENT_STATUSES[val],
                },
            ]
        case NotificationDestinationCategoryCode.Activity:
            return [
                {
                    title: LOCAL.LABELS.STATUS,
                    dataIndex: 'activityStatus',
                    key: 'activityStatus',
                    render: (val: AppointmentTrackStatus) =>
                        val && TRACKS_APPOINTMENT_STATUSES[val],
                },
            ]
        default:
            return [
                {
                    title: LOCAL.LABELS.STATUS,
                    dataIndex: 'status',
                    key: 'status',
                    render: (val: AppointmentStatus) =>
                        val && APPOINTMENT_STATUS[val],
                },
            ]
    }
}

export const getTableColumnsByCategoryCode = (
    categoryCode: NotificationDestinationCategoryCode
): ColumnsType<NotificationMailingTaskAppointmentSearchResultContract> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
    },
    ...getColumnsByStatus(categoryCode),
    ...APPOINTMENTS_TABLE_COLUMNS,
]
