import React from 'react'
import { HomeworkStatusType } from 'core/api'
import { WidthRestrictedElement } from 'components/shared'

import { getStatusMeta } from '../CheckHomework/CheckHomework.utils'

export const renderHomeworkStatus = (status?: HomeworkStatusType) => {
    const { title, styleModification } = getStatusMeta(status)

    return (
        <WidthRestrictedElement className={styleModification} title={title} />
    )
}
