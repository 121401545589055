import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DictionaryContract } from 'core/api'
import { FnActionProps } from 'App.types'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'

/** настройка отображения колонок для справочника шаблонов трека */
export const getTrackTemplateColumns = (
    handlePreviewClick: (row: DictionaryContract) => FnActionProps
): ColumnsType<DictionaryContract> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: LOCAL.LABELS.ACTIONS,
        width: 150,
        render: (_, row) => (
            <IconsAdapter
                iconType="EyeOutlined"
                onClick={handlePreviewClick(row)}
            />
        ),
        align: 'center',
    },
]
