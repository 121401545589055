import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BackTop, PageContent } from 'components/shared'
import {
    CatalogItemProductCardPreviewContract,
    CatalogService,
    ProductCardsService,
} from 'core/api'
import { ColorThemeContext } from 'contexts'
import { UrlParamsForEditProps } from 'hooks'
import { getBannerBackgroundCssProp } from 'utils'
import { useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './ProductCard.module.scss'
import {
    ProductCardFooter,
    ProductCardHeader,
    ProductCardOutcomes,
    ProductCardSchedule,
    ProductCardSecondary,
    ProductCardTasks,
} from './components'
import {
    registerProductCardEvents,
    unregisterProductCardEvents,
} from './ProductCard.events'

/** Компонент страницы карточки продукта */
export const ProductCard: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const { theme } = useContext(ColorThemeContext)
        const urlParams = useParams<UrlParamsForEditProps>()
        const [isListenerEnrolled, setListenerEnrolled] = useState(false)
        const [isAppointed, setIsAppointed] = useState(false)
        const [pageInfo, setPageInfo] = useState<
            CatalogItemProductCardPreviewContract
        >()

        /** Запросить данные о карточке продукта */
        const fetchPageInfo = useCallback(async () => {
            try {
                updateLoader(true)
                const dataSource = await CatalogService.getCatalogProductCardPreviewAsync(
                    {
                        productCardId: Number(urlParams.id),
                    }
                )

                const isEnrolled = await ProductCardsService.isCurrentUserEnrolledAsListenerInProduct(
                    { productCardId: dataSource.id }
                )

                const isAppointed = await ProductCardsService.productCardHasAutoAppointmentForCurrentUser(
                    { productCardId: dataSource.id }
                )

                setListenerEnrolled(isEnrolled)
                setPageInfo(dataSource)
                setIsAppointed(isAppointed)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader, urlParams.id])

        const onSetAppointed = useCallback(() => {
            setIsAppointed(true)
        }, [])

        /** Запрашиваем данные для отображения страницы */
        useEffect(() => {
            if (!urlParams.id && !Number(urlParams.id)) return

            fetchPageInfo()
        }, [fetchPageInfo, urlParams.id])

        useEffect(() => {
            registerProductCardEvents({ onSetAppointed })

            return () => unregisterProductCardEvents({ onSetAppointed })
        }, [onSetAppointed])

        return (
            <PageContent className={styles.wrapper}>
                <BackTop />

                <section
                    style={getBannerBackgroundCssProp({
                        url: pageInfo?.picture.url,
                        theme,
                    })}
                >
                    <ProductCardHeader
                        pageInfo={pageInfo}
                        fetchPageInfo={fetchPageInfo}
                        isListenerEnrolled={isListenerEnrolled}
                        isAppointed={isAppointed}
                    />

                    {!!pageInfo?.outcomes.length && (
                        <ProductCardOutcomes outcomes={pageInfo?.outcomes} />
                    )}

                    <ProductCardSecondary pageInfo={pageInfo} />
                </section>

                <section>
                    <ProductCardSchedule
                        fetchPageInfo={fetchPageInfo}
                        pageInfo={pageInfo}
                        isListenerEnrolled={isListenerEnrolled}
                        isAppointed={isAppointed}
                    />

                    {!!pageInfo?.challenges.length && (
                        <ProductCardTasks tasks={pageInfo.challenges} />
                    )}

                    <ProductCardFooter pageInfo={pageInfo} />
                </section>
            </PageContent>
        )
    })
)
