import { LOCAL } from 'core/local'
import { UserStructuralUnitPositionContract } from 'core/api'
import { formatDate } from 'utils'

/**
 * Получить период работы
 */
export const getWorkDate = (el: UserStructuralUnitPositionContract) => {
    if (el.hireDate && el.fireDate)
        return `${formatDate(el.hireDate)} - ${formatDate(el.fireDate)}`
    if (el.hireDate)
        return `${formatDate(
            el.hireDate
        )} - ${LOCAL.LABELS.UNTIL_NOW.toLowerCase()}`
}

/**
 * Информация о работе в организации
 * @param workData данные о работе на должности
 * @return инфо о периоде работы и признаке должности
 */
export const getWorkInfo = (workData: UserStructuralUnitPositionContract) => {
    const date = getWorkDate(workData)
    const signText = workData.isMain
        ? LOCAL.LABELS.MAIN_PLACE_OF_WORK
        : LOCAL.LABELS.PART_TIME

    const dateText = date ? `${date}, ` : ''

    return dateText + signText
}
