import React, { FC } from 'react'
import {
    CoworkerRecord,
    Coworkers,
    ErrorPage,
    Organizations,
    StaffUnits,
    SupervisingComplexes,
} from 'components/pages'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

export const AdministrationRouting: FC = React.memo(() => (
    <Switch>
        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.StructuralUnitsRead,
            ]}
            path={ROUTE_NAMES.ORGANIZATIONS}
        >
            <Organizations />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.StaffUnitsRead,
            ]}
            path={ROUTE_NAMES.STAFF_UNITS}
        >
            <StaffUnits />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.UsersRead,
                UserPermission.UsersProfileRead,
            ]}
            path={`${ROUTE_NAMES.COWORKERS}/:id`}
        >
            <CoworkerRecord />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.UsersRead,
            ]}
            path={ROUTE_NAMES.COWORKERS}
        >
            <Coworkers />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.ComplexesRead,
            ]}
            path={ROUTE_NAMES.SUPERVISING_COMPLEXES}
        >
            <SupervisingComplexes />
        </ProtectedRoute>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
