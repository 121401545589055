import React, { useEffect } from 'react'
import { ASSESSMENT_ROLES_SHORT } from 'consts'
import { Circle360ReportService } from 'core/api'
import { LOCAL } from 'core/local'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './GroupsOfRespondents.module.scss'

/** Отчет по оценке 360. Группы респондентов */
export const GroupsOfRespondents: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(
        Circle360ReportService.getGroupsOfRespondents
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return (
        <div className={styles.grid}>
            {data?.respondents.map((respondent) => (
                <>
                    <div>{ASSESSMENT_ROLES_SHORT[respondent.key]}</div>

                    <div>{respondent.value}</div>
                </>
            ))}

            <div className={styles.divider} />

            <div>{LOCAL.LABELS.TOTAL}</div>

            <div>{data?.countAll}</div>
        </div>
    )
})
