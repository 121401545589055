import React, { useCallback } from 'react'
import { AddButton } from 'components/shared'
import {
    AssessmentPortfolioForModalContract,
    AssessmentPortfolioSearchForModalContract,
    AssessmentPortfoliosService,
    DocumentStatus,
} from 'core/api'
import {
    AssessmentProjectFormValues,
    AssessmentProjectPortfolioFormValues,
} from 'components/pages/AssessmentProjects/components/AssessmentProjectsForm/AssessmentProjectsForm.types'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { SearchTableShowButton, ShouldUpdateChecker } from 'components/controls'
import { fetchSelectedItemsByIds, getItemId, isFormModeView } from 'utils'
import { useElementVisible } from 'hooks'

import styles from '../AssessmentProjectsCommonSettings/AssessmentProjectsCommonSettings.module.scss'
import { ASSESSMENT_PORTFOLIOS_MODAL_TABLE_COLUMNS } from './AddAssessmentProjectsPortfolios.consts'
import { AddAssessmentProjectsPortfoliosProps } from './AddAssessmentProjectsPortfolios.types'
import { AssessmentPortfoliosFilter } from '../AssessmentPortfoliosFilter'

/** Компонент добавления назначений портфелей оценки в форме создания оценочных проектов*/
export const AddAssessmentProjectsPortfolios: React.FC<AddAssessmentProjectsPortfoliosProps> = React.memo(
    ({ formMode, form }) => {
        const {
            updateElementVisible: updateLoader,
            elementVisible: isLoading,
        } = useElementVisible()

        /**
         * Обработчик выбора портфелей опроса
         */
        const handleSelectItems = useCallback(
            (
                selectedPortfoliosIds: number[] = [],
                selectedPortfolios: AssessmentProjectPortfolioFormValues[] = []
            ) => async (result?: number[]) => {
                try {
                    updateLoader(true)

                    if (result?.length) {
                        const assessmentPortfolios = await fetchSelectedItemsByIds(
                            {
                                selectedItemsIds: selectedPortfoliosIds,
                                selectedItems: selectedPortfolios,
                                getByIdsMethod:
                                    AssessmentPortfoliosService.getByIds,
                                result,
                            }
                        )
                        form?.setFieldsValue({ assessmentPortfolios })
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [form, updateLoader]
        )

        return (
            <ShouldUpdateChecker
                fieldPath={[['assessmentType'], ['assessmentPortfolios']]}
            >
                {({ getFieldsValue }) => {
                    const {
                        assessmentType,
                        assessmentPortfolios,
                    }: Partial<AssessmentProjectFormValues> = getFieldsValue([
                        'assessmentType',
                        'assessmentPortfolios',
                    ])

                    const selectedPortfoliosIds = assessmentPortfolios?.map(
                        getItemId
                    )

                    return (
                        <div className={styles.addPortfolio}>
                            <h3 className="required-control">
                                {LOCAL.LABELS.ASSESSMENT_PORTFOLIOS}
                            </h3>

                            <SearchTableShowButton<
                                AssessmentPortfolioSearchForModalContract,
                                AssessmentPortfolioForModalContract
                            >
                                tableSearchOptions={{
                                    searchMethod:
                                        AssessmentPortfoliosService.getForModal,
                                    disableSearch: !assessmentType,
                                    rowSelectionType: 'checkbox',
                                    tableColumns: ASSESSMENT_PORTFOLIOS_MODAL_TABLE_COLUMNS,
                                    formId: FORM_IDS.ASSESSMENT_PORTFOLIO,
                                    filterComponent: AssessmentPortfoliosFilter,
                                    onSelectTableItem: handleSelectItems(
                                        selectedPortfoliosIds,
                                        assessmentPortfolios
                                    ),
                                    tableFiltersOptions: {
                                        initialValues: {
                                            type: assessmentType,
                                            statuses: [
                                                DocumentStatus.ReadyForWork,
                                            ],
                                        },
                                    },
                                    initialValues: selectedPortfoliosIds,
                                }}
                                modalTitle={LOCAL.LABELS.ASSESSMENT_PORTFOLIOS}
                            >
                                <AddButton
                                    disabled={
                                        isFormModeView(formMode) || isLoading
                                    }
                                    buttonText={LOCAL.ACTIONS.ADD}
                                    loading={isLoading}
                                />
                            </SearchTableShowButton>
                        </div>
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
