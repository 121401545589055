import React, { useCallback, useEffect } from 'react'
import {
    ActivitiesSearchContract,
    StructuralUnitContract,
    StructuralUnitsService,
} from 'core/api'
import {
    CheckboxControl,
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import {
    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED,
    EVENT_DECORATION_TYPE_MAPPED,
    EVENT_SPECIES_MAPPED,
    EVENT_TYPES_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps, normalizeStructuralUnitsWithoutComplexes } from 'utils'
import { LOCAL } from 'core/local'
import { RenderFooterProps } from 'components/shared/FilterForm/FilterForm.types'
import { useHttp } from 'hooks'

/**
 * Форма фильтрации мероприятий
 */
export const EventsFilters: React.FC<FiltersProps<
    ActivitiesSearchContract
>> = React.memo((props) => {
    const [getOrganizations, organizations] = useHttp<StructuralUnitContract[]>(
        StructuralUnitsService.getAllStructuralUnits
    )

    const renderFooter = useCallback(
        ({
            cleanBtn,
            submitBtn,
            saveFiltersSwitch,
            isWidthLessLG,
        }: RenderFooterProps) => (
            <Row
                gutter={10}
                align="middle"
                justify={isWidthLessLG ? 'center' : 'end'}
            >
                <Col>
                    <Form.Item name="showArchiveActivities" noStyle>
                        <CheckboxControl>
                            {LOCAL.LABELS.ARCHIVED_RECORDS}
                        </CheckboxControl>
                    </Form.Item>
                </Col>

                {saveFiltersSwitch && <Col>{saveFiltersSwitch}</Col>}

                <Col>{submitBtn}</Col>

                <Col>{cleanBtn}</Col>
            </Row>
        ),
        []
    )

    useEffect(() => {
        getOrganizations()
    }, [getOrganizations])

    return (
        <ControlGroup type="light">
            <FilterForm {...props} renderFooter={renderFooter}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="type"
                            label={LOCAL.LABELS.TYPE}
                        >
                            <SelectControl
                                values={EVENT_TYPES_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="kind"
                            label={LOCAL.LABELS.KIND}
                        >
                            <SelectControl
                                values={EVENT_SPECIES_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="decorationType"
                            label={LOCAL.LABELS.DECORATION}
                        >
                            <SelectControl
                                values={EVENT_DECORATION_TYPE_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="ownerOrganizationIds"
                            label={LOCAL.LABELS.OWNER}
                        >
                            <TreeSelectControl
                                showSearch
                                treeData={normalizeStructuralUnitsWithoutComplexes(
                                    organizations
                                )}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changeDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changeDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
