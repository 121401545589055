import React from 'react'
import {
    COMPETENCIES_TABLE_COLUMNS,
    CheckboxControl,
    CompetenciesFilters,
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    SearchSelectControl,
    SelectControl,
    ShouldUpdateChecker,
    TextAreaControl,
} from 'components/controls'
import { Col, Row } from 'antd'
import {
    CompetenceForModalContract,
    CompetenciesSearchContract,
    CompetenciesService,
} from 'core/api'
import { ComponentCommonProps } from 'App.types'
import {
    DATE_FORMAT,
    ENDING_DOCUMENT_TYPES,
    EXCLUDE_DRAFT_STATUS_SEARCH_BODY,
    LEARNING_FORMATS_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { LOCAL } from 'core/local'
import {
    disableDate,
    isProductTypeContent,
    normalizeDictionaryToControl,
} from 'utils'
import {
    isProductTypeActivity,
    isProductTypeBook,
    isProductTypePodcast,
    isProductTypeTrack,
} from 'utils/conditions.utils'

import styles from '../ProductCatalogForm/ProductCatalogForm.module.scss'
import { getProductFieldByType } from '../ProductTypeControl/ProductTypeControl.utils'
import { prepareSearchOptions } from './ProductDescription.utils'

/** Блок полей формы создания карточки продукта - описание продукта */
export const ProductDescription: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <div className={styles.description}>
            <h3>{LOCAL.LABELS.DESCRIPTION}</h3>

            <ShouldUpdateChecker fieldPath={['type']}>
                {({ getFieldValue }) => {
                    const type = getFieldValue('type')

                    const isTypeActivityOrTrack =
                        isProductTypeActivity(type) || isProductTypeTrack(type)

                    return (
                        <Row gutter={ROW_GUTTER}>
                            <Col xs={20}>
                                <FormItemAdapter
                                    fieldName="name"
                                    label={LOCAL.LABELS.PRODUCT_NAME}
                                    required
                                >
                                    <InputControl formMode={formMode} />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={4}>
                                <FormItemAdapter
                                    fieldName="year"
                                    label={LOCAL.LABELS.YEAR}
                                    required
                                >
                                    <DatePickerControl
                                        formMode={formMode}
                                        disabledDate={disableDate()}
                                        picker="year"
                                        format={DATE_FORMAT.YEAR}
                                    />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={12}>
                                <FormItemAdapter fieldName="showInRecommended">
                                    <CheckboxControl formMode={formMode}>
                                        {LOCAL.LABELS.DISPLAY_IN_RECOMMENDED}
                                    </CheckboxControl>
                                </FormItemAdapter>
                            </Col>

                            <Col xs={12}>
                                {(isProductTypeActivity(type) ||
                                    isProductTypeTrack(type)) && (
                                    <FormItemAdapter
                                        fieldName="graduationDocumentType"
                                        label={LOCAL.LABELS.ENDING_DOCUMENT}
                                    >
                                        <SelectControl
                                            values={normalizeDictionaryToControl(
                                                ENDING_DOCUMENT_TYPES
                                            )}
                                            formMode={formMode}
                                        />
                                    </FormItemAdapter>
                                )}
                            </Col>

                            <Col xs={12}>
                                <FormItemAdapter
                                    fieldName="studyingFormat"
                                    label={LOCAL.LABELS.LEARNING_FORMAT}
                                    required
                                >
                                    <SelectControl
                                        values={LEARNING_FORMATS_MAPPED}
                                        formMode={formMode}
                                    />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={12}>
                                <FormItemAdapter
                                    fieldName="competences"
                                    label={LOCAL.LABELS.COMPETENCIES_DEVELOPS}
                                    required
                                >
                                    <SearchSelectControl<
                                        CompetenciesSearchContract,
                                        CompetenceForModalContract
                                    >
                                        getByIdsMethod={
                                            CompetenciesService.getByIds
                                        }
                                        selectMode="multiple"
                                        formMode={formMode}
                                        modalTitle={
                                            LOCAL.LABELS.COMPETENCES_SELECTION
                                        }
                                        tableSearchOptions={{
                                            tableFiltersOptions: {
                                                initialValues: EXCLUDE_DRAFT_STATUS_SEARCH_BODY,
                                            },
                                            searchMethod:
                                                CompetenciesService.getForModal,
                                            tableColumns: COMPETENCIES_TABLE_COLUMNS,
                                            filterComponent: CompetenciesFilters,
                                        }}
                                    />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={24}>
                                <FormItemAdapter
                                    fieldName="announcement"
                                    label={LOCAL.LABELS.PRODUCT_ANNOUNCEMENT}
                                >
                                    <TextAreaControl
                                        autoSize={{ minRows: 3 }}
                                        formMode={formMode}
                                    />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={24}>
                                <FormItemAdapter
                                    fieldName="targetAudience"
                                    label={LOCAL.LABELS.FOR_WHO}
                                >
                                    <InputControl formMode={formMode} />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={24}>
                                <ShouldUpdateChecker
                                    fieldPath={[
                                        ['forAllPositions'],
                                        ['forAllOrganizations'],
                                        ['organizations'],
                                        ['positionIds'],
                                    ]}
                                >
                                    {({ getFieldsValue }) => {
                                        const formValues = getFieldsValue([
                                            'forAllPositions',
                                            'forAllOrganizations',
                                            'organizations',
                                            'positionIds',
                                        ])
                                        const initialValues = isProductTypeTrack(
                                            type
                                        )
                                            ? prepareSearchOptions(formValues)
                                            : undefined

                                        return getProductFieldByType(
                                            type,
                                            formMode,
                                            initialValues
                                        )
                                    }}
                                </ShouldUpdateChecker>
                            </Col>

                            {isTypeActivityOrTrack && (
                                <ShouldUpdateChecker
                                    fieldPath={[['activity'], ['track']]}
                                >
                                    {({ getFieldValue }) => {
                                        const product = getFieldValue([
                                            type?.toLowerCase(),
                                        ])

                                        return (
                                            product && (
                                                <div
                                                    className={
                                                        styles.checkHomeworkMessage
                                                    }
                                                >
                                                    {
                                                        LOCAL.MESSAGES
                                                            .CHECK_HOMEWORK_FROM_CATALOG
                                                    }
                                                </div>
                                            )
                                        )
                                    }}
                                </ShouldUpdateChecker>
                            )}

                            {!isProductTypeBook(type) &&
                                !isProductTypePodcast(type) && (
                                    <ShouldUpdateChecker
                                        fieldPath={[
                                            ['activity'],
                                            ['track'],
                                            ['course'],
                                        ]}
                                    >
                                        {({ getFieldValue }) => {
                                            const product = isProductTypeContent(
                                                type
                                            )
                                                ? getFieldValue('course')
                                                : getFieldValue([
                                                      type?.toLowerCase(),
                                                  ])

                                            return (
                                                product && (
                                                    <Col xs={24}>
                                                        <FormItemAdapter fieldName="availableToStartFromCatalog">
                                                            <CheckboxControl
                                                                formMode={
                                                                    formMode
                                                                }
                                                            >
                                                                {
                                                                    LOCAL.LABELS
                                                                        .AVAILABILITY_FROM_CATALOG
                                                                }
                                                            </CheckboxControl>
                                                        </FormItemAdapter>
                                                    </Col>
                                                )
                                            )
                                        }}
                                    </ShouldUpdateChecker>
                                )}
                        </Row>
                    )
                }}
            </ShouldUpdateChecker>
        </div>
    )
)
