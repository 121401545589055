import React from 'react'
import cn from 'classnames'

import styles from './ProgressLine.module.scss'
import stylesEventPassingInfoPanel from '../../../EventPassingInfoPanel/EventPassingInfoPanel.module.scss'
import { ProgressLineProps } from './ProgressLine.types'

/**
 * Прогресс прохождения мероприятия
 */
export const ProgressLine: React.FC<ProgressLineProps> = React.memo(
    ({ activityLessonPassings, lessonCompletedCount }) => {
        const steps = activityLessonPassings?.map((el, index) => (
            <li
                key={el.lessonId}
                className={cn(
                    styles.division,
                    lessonCompletedCount &&
                        index < lessonCompletedCount &&
                        styles.divisionDone
                )}
            />
        ))

        return (
            <div className={styles.wrapper}>
                <ul className={styles.steps}>{steps}</ul>

                <span
                    className={cn(
                        styles.meta,
                        stylesEventPassingInfoPanel.meta
                    )}
                >
                    {`${lessonCompletedCount}/${activityLessonPassings?.length}`}
                </span>
            </div>
        )
    }
)
