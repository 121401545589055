import React from 'react'
import cn from 'classnames'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import {
    isStageModeCreate,
    isStageModePassing,
    isStageModePreview,
} from 'utils'

import styles from './StageActions.module.scss'
import { StageActionsProps } from './StageActions.types'

/** Действия для иконки этапа на полотне */
const StageActionsComponent = React.forwardRef<
    HTMLDivElement,
    StageActionsProps
>(
    (
        {
            onToggleMenu,
            onRemoveStageFromCanvas,
            onChangeStageState,
            mode,
            isInWorkStatus,
            className,
        },
        ref
    ) =>
        !isStageModePassing(mode) && !isInWorkStatus ? (
            <div
                className={cn(
                    styles.wrapper,
                    className,
                    styles[`wrapper_${mode}`]
                )}
            >
                {isStageModeCreate(mode) && (
                    <>
                        <IconsAdapter
                            iconType="CustomIconArrows"
                            className={cn(
                                styles.option,
                                styles.optionDrag,
                                'draggable'
                            )}
                        />

                        <div
                            ref={ref}
                            onClick={onToggleMenu}
                            className={cn(styles.option, styles.optionEdit)}
                        >
                            <IconsAdapter iconType="CustomIconPaintBrush" />
                        </div>

                        <IconsAdapter
                            onClick={onRemoveStageFromCanvas}
                            iconType="CustomIconTrashAlt"
                            className={cn(styles.option, styles.optionDelete)}
                        />
                    </>
                )}

                {isStageModePreview(mode) && (
                    <IconsAdapter
                        onClick={onChangeStageState}
                        iconType="CustomIconPencil"
                        className={cn(styles.option, styles.optionChangeState)}
                    />
                )}
            </div>
        ) : (
            <></>
        )
)

export const StageActions = React.memo(StageActionsComponent)
