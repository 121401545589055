import { AssessmentType, QuestionnaireType } from 'core/api'

/**
 * NamePath зависимых полей, при изменении которых происходит запрос портфелей оценки, ролей
 */
export const shouldRolesAndAssessmentPortfoliosReFetch = ['assessment', 'type']

export const portfolioIdFieldNamePath = ['assessment', 'portfolioId']

export const structuralUnitsIdsFieldNamePath = [
    'assessment',
    'circleParameters',
    'structuralUnitsIds',
]

/**
 * Начальные значения для формы
 */
export const ASSESSMENT_ASSIGNMENT_FORM_INITIAL_VALUES = {
    type: QuestionnaireType.Assessment,
    assessment: {
        type: AssessmentType.Estimate_360,
        circleParameters: {
            depersonalizeRespondents: true,
        },
    },
    daysToStartNotification: 3,
    daysToEndNotification: 3,
}
