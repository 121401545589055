import { UserPermission } from 'core/api'
import { renderStringArrayWithSeparator } from 'utils/common.utils'

import { PERMISSIONS, ROLE_BASED_PERMISSIONS } from './ProtectedRoute.consts'

/**
 * Функция проверки прав доступа на основе role-based модели. Используется в качестве декоратора
 * для аргумента userPermissions внутри более низкоуровневых проверок
 * @param userPermissions - массив имеющихся у пользователя прав доступа
 * @returns {UserPermission[]} - вернет склеенный массив из имеющихся у пользователя прав доступа и
 *     прав доступных из role-based модели
 * @description пока role-based права включаются вперемешку с обыкновенным набором прав, поэтому их
 *     нужно вычленить из массива, получить по ним список доступных прав и склеить обратно
 */
export const checkRoleBasedPermissions = (
    userPermissions?: UserPermission[]
) => {
    if (!userPermissions) return []

    const roles = Object.values(userPermissions).filter((permissionItem) =>
        Object.keys(ROLE_BASED_PERMISSIONS).includes(permissionItem)
    )

    return Array.from(
        new Set([
            ...userPermissions,
            ...roles.reduce<string[]>(
                (acc, el) => [...acc, ...ROLE_BASED_PERMISSIONS[el]],
                []
            ),
        ])
    ) as UserPermission[]
}

/**
 * Формирование сообщения после проверки на отсутствие обязательных прав для отображения элемента
 * @param requiredPermissions - массив прав доступа, которые требуются для отображения элемента
 * @param conditionalPermissions - массив дополнительных прав доступа, которые требуются для отображения элемента
 * @param userPermissions - массив имеющихся у пользователя прав доступа
 * @returns {string} - сообщение о недостающих правах
 */
export const getPermissionsMessages = (
    requiredPermissions: UserPermission[],
    userPermissions: UserPermission[],
    conditionalPermissions?: UserPermission[]
) => {
    const missingPermissions = requiredPermissions.filter(
        (i) => !userPermissions.includes(i)
    )

    const permissionsMessages = renderStringArrayWithSeparator(
        missingPermissions.map((item) => PERMISSIONS[item])
    )

    const conditionalPermissionsMessages = renderStringArrayWithSeparator(
        conditionalPermissions?.map((item) => PERMISSIONS[item]),
        ' или '
    )

    const additionalRights = conditionalPermissions?.some((item) =>
        userPermissions.includes(item)
    )

    if (permissionsMessages && additionalRights) return permissionsMessages

    if (permissionsMessages && conditionalPermissionsMessages)
        return `${permissionsMessages}, ${conditionalPermissionsMessages}`

    return conditionalPermissionsMessages ?? permissionsMessages
}
