import React, { FC, useCallback, useEffect, useState } from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    SettingsService,
    UserRegistryContract,
    UsersSearchContract,
    UsersService,
} from 'core/api'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'

import styles from './Coworkers.module.scss'
import { COWORKERS_TABLE_COLUMNS } from './Coworkers.consts'
import { CoworkersFilters } from './components'

/** Компонент отображения справочника сотрудников */
export const Coworkers: FC = React.memo(() => {
    const history = useHistory()

    const [integrationSyncDate, setIntegrationSyncDate] = useState<Date>()

    /**
     * Обработчик одиночного нажатия на строку таблицы
     * @param record - запись в таблице на которой произошел клик
     */
    const handleRowClick = useCallback(
        (record: UserRegistryContract) => async () => {
            /** сброс флага "Сущность изменена" при переходе в профиль, если isChanged = true */
            record.isChanged &&
                (await UsersService.resetIsChanged({
                    userId: record.id,
                }))
            history.push(`${ROUTE_NAMES.COWORKERS}/${record.id}`)
        },
        [history]
    )

    /**
     * Обработчики для `row` таблицы
     */
    const rowHandlers = useCallback(
        (record) => ({
            onClick: handleRowClick(record),
        }),
        [handleRowClick]
    )

    /**
     * Запрос данных справочника
     */
    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                setIntegrationSyncDate(
                    (await SettingsService.getOibV2IntegrationSyncDate())
                        .oibV2UsersLastUpdate
                )
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [])

    return (
        <DictionaryPage<UsersSearchContract, UserRegistryContract>
            dictionaryKey={DICTIONARY_KEYS.COWORKERS}
            searchMethod={UsersService.search}
            contentTitle={getRouteMeta(ROUTE_NAMES.COWORKERS)}
            integrationSyncDate={integrationSyncDate}
            tableColumns={COWORKERS_TABLE_COLUMNS}
            keysOfAlwaysVisibleColumns={['fullName']}
            filterComponent={CoworkersFilters}
            onRow={rowHandlers}
            className={styles.wrapper}
            handleReset={UsersService.resetIsChanged}
            isNeedUpdateAfterReset
        />
    )
})
