import {
    NotificationMailingInfoStatus,
    NotificationMailingTaskType,
} from 'core/api'

export const isNotificationMailingTaskTypeAtSpecifiedTime = (
    taskType?: NotificationMailingTaskType
) => taskType === NotificationMailingTaskType.AtSpecifiedTime
export const isNotificationMailingTaskTypeByEvent = (
    taskType?: NotificationMailingTaskType
) => taskType === NotificationMailingTaskType.ByEvent

export const isNotificationMailingInfoTypeExpectation = (
    status?: NotificationMailingInfoStatus
) => status === NotificationMailingInfoStatus.Expectation
