import {
    PassingFormQuestionAnswerMissingTextContract,
    QuestionAnswerCreateContract,
    QuestionType,
} from 'core/api'

export const composeFormRequestBody = (
    type?: QuestionType,
    values?: QuestionAnswerCreateContract
) => {
    if (!values) return

    switch (type) {
        case QuestionType.ChooseVariant:
            return {
                chooseAnswerParameters: values,
            }
        case QuestionType.CriteriaScale:
            return {
                scaleAnswerParameters: values,
            }
        case QuestionType.FillEmptyText:
            const missingTextAnswerParameters = (values as unknown) as PassingFormQuestionAnswerMissingTextContract

            return {
                missingTextAnswerParameters: {
                    missingTextBlocks: missingTextAnswerParameters.missingTextBlocks.filter(
                        (el) => el !== undefined
                    ),
                },
            }

        default:
            return {
                textAnswerParameters: values,
            }
    }
}
