import React from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import {
    HomeworkGetContract,
    HomeworkRegistryItemContract,
    HomeworkStatusType,
    HomeworksService,
} from 'core/api'
import { getPageTitleFromRoute } from 'utils'
import { getRowClassNameExpired } from 'components/shared/TableAdapter/TableAdapter.utils'
import { useLocation } from 'react-router-dom'

import { HomeworksFilters } from './components/HomeworksFilters'
import { getTableColumnsConfig } from './Homeworks.config'

export const Homeworks: React.FC = React.memo(() => {
    const location = useLocation()

    return (
        <DictionaryPage<HomeworkGetContract, HomeworkRegistryItemContract>
            dictionaryKey={DICTIONARY_KEYS.HOMEWORKS}
            searchMethod={HomeworksService.myHomeworkSearch}
            contentTitle={getPageTitleFromRoute(location.pathname)}
            contentTitleType="h5"
            tableColumnsRender={getTableColumnsConfig}
            quickSearchMethod={HomeworksService.myHomeworksSearchText}
            filterComponent={HomeworksFilters}
            withPageBanner={false}
            rowClassName={getRowClassNameExpired}
            tableFiltersOptions={{
                initialValues: {
                    statuses: [
                        HomeworkStatusType.WaitingForSolution,
                        HomeworkStatusType.Revision,
                    ],
                },
            }}
        />
    )
})
