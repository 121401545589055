import React from 'react'
import { Col, Row } from 'antd'
import { ROW_GUTTER } from 'consts'

import { AssessmentReportGridProps } from './AssessmentReportGrid.types'
import {
    COL_REPORT_CONTENTS,
    COL_REPORT_VIEW,
} from './AssessmentReportGrid.config'
import { ReportContents } from '../ReportContents'
import { ReportView } from '../ReportView'

export const AssessmentReportGrid: React.FC<AssessmentReportGridProps> = React.memo(
    ({ template }) => (
        <Row gutter={ROW_GUTTER}>
            <Col xs={COL_REPORT_CONTENTS}>
                <ReportContents template={template} />
            </Col>

            <Col xs={COL_REPORT_VIEW}>
                <ReportView template={template} />
            </Col>
        </Row>
    )
)
