import { LOCAL } from 'core/local'
import { RESOLUTION_RELATIONS, Resolutions } from 'core/configs'

import { TabeProps } from './ResponsiveTabs.types'
import { TrackBackground } from '../TrackBackground'
import { TrackStagesVisualization } from '../TrackStagesVisualization'

export const RESOLUTION_INITIAL_TAB: TabeProps = {
    title: RESOLUTION_RELATIONS[Resolutions.XXL],
    content: TrackStagesVisualization,
    key: Resolutions.XXL,
    closable: false,
}

export const BACKGROUND_TAB: TabeProps = {
    title: LOCAL.LABELS.CONTAINER_BACKGROUND,
    content: TrackBackground,
    key: 'backgroundTrack',
    closable: false,
}

export const INITIAL_TABS = [BACKGROUND_TAB, RESOLUTION_INITIAL_TAB]
