export const FORM_IDS = {
    ASSESSMENT_ASSIGNMENT_FORM: 'ASSESSMENT_ASSIGNMENT_FORM',
    ASSESSMENT_CANDIDATE: 'ASSESSMENT_CANDIDATE',
    ASSESSMENT_EVALUATION: 'ASSESSMENT_EVALUATION',
    ASSESSMENT_PORTFOLIO: 'ASSESSMENT_PORTFOLIO',
    ASSESSMENT_PROJECT_FORM: 'ASSESSMENT_PROJECT_FORM',
    ASSESSMENT_TEMPLATE_FORM: 'ASSESSMENT_TEMPLATE_FORM',
    CHECK_HOMEWORK_INFO_FORM: 'CHECK_HOMEWORK_INFO_FORM',
    COLUMNS_OPTIONS_FORM: 'COLUMNS_OPTIONS_FORM',
    COMPETENCE_FORM: 'COMPETENCE_FORM',
    COMPETENCIES_INDICATOR_FORM: 'COMPETENCIES_INDICATOR_FORM',
    COMPETENCIES_GROUP_FORM: 'COMPETENCIES_GROUP_FORM',
    CONFIRM_DELETE_FORM: 'CONFIRM_DELETE_FORM',
    CONFIRM_DELETE_ARTICLE_FORM: 'CONFIRM_DELETE_ARTICLE_FORM',
    COURSE_INFO_FORM: 'COURSE_INFO_FORM',
    COWORKERS_PROFILES_FORM: 'COWORKERS_PROFILES_FORM',
    DEVELOPMENT_GOALS_FORM: 'DEVELOPMENT_GOALS_FORM',
    DISTRIBUTION_MAILING_FORM: 'DISTRIBUTION_MAILING_FORM',
    DISTRIBUTION_MAILING_VIEW_FORM: 'DISTRIBUTION_MAILING_VIEW_FORM',
    DISTRIBUTION_TAGS_CREATE_FORM: 'DISTRIBUTION_TAGS_CREATE_FORM',
    DOCUMENT_CREATE_FORM: 'DOCUMENT_CREATE_FORM',
    DOCUMENT_UPLOAD_FORM: 'DOCUMENT_UPLOAD_FORM',
    EDIT_TAG_FORM: 'EDIT_TAG_FORM',
    EMAIL_PREVIEW_FORM: 'EMAIL_PREVIEW_FORM',
    EVENT_FORM: 'EVENT_FORM',
    EVENTS_ASSIGNMENTS_FORM: 'EVENTS_ASSIGNMENTS_FORM',
    GOALS_FORM: 'GOALS_FORM',
    JOB_PROFILE_IN_WORK_FORM: 'JOB_PROFILE_IN_WORK_FORM',
    JOB_PROFILE_OTHER_SUBORDINATION_FORM:
        'JOB_PROFILE_OTHER_SUBORDINATION_FORM',
    KNOWLEDGE_BASE_FORM: 'KNOWLEDGE_BASE_FORM',
    KNOWLEDGE_TITLE_FORM: 'KNOWLEDGE_TITLE_FORM',
    KNOWLEDGE_ADD_SECTION_FORM: 'KNOWLEDGE_ADD_SECTION_FORM',
    MATERIALS_TASK_FORM: 'MATERIALS_TASK_FORM',
    OTHER_SUBORDINATION_FORM: 'OTHER_SUBORDINATION_FORM',
    PRODUCT_LISTENERS_FORM: 'PRODUCT_LISTENERS_FORM',
    POLL_SCALE_FORM: 'POLL_SCALE_FORM',
    QUESTIONNAIRES_CONSTRUCTOR_FORM: 'QUESTIONNAIRES_CONSTRUCTOR_FORM',
    QUESTIONNAIRE_PASSING_FORM: 'QUESTIONNAIRE_PASSING_FORM',
    QUIZ_ASSIGNMENTS_FORM: 'QUIZ_ASSIGNMENTS_FORM',
    QUIZ_PORTFOLIO_FORM: 'QUIZ_PORTFOLIO_FORM',
    REPORT_CREATE_FORM: 'REPORT_CREATE_FORM',
    SAVE_STAGE_VISUALISATION_TEMPLATE_FORM:
        'SAVE_STAGE_VISUALISATION_TEMPLATE_FORM',
    SCALE_INFO_FORM: 'SCALE_INFO_FORM',
    SEARCH_ACTIVITIES: 'SEARCH_ACTIVITIES',
    SEARCH_CANDIDATES: 'SEARCH_CANDIDATES',
    SEARCH_COURSES: 'SEARCH_COURSES',
    SEARCH_COMPETENCE: 'SEARCH_COMPETENCE',
    SEARCH_INDICATORS: 'SEARCH_INDICATORS',
    SEARCH_MISSIONS: 'SEARCH_MISSIONS',
    SEARCH_QUESTIONNAIRE: 'SEARCH_QUESTIONNAIRE',
    SEARCH_OBJECTS: 'SEARCH_OBJECTS',
    SEARCH_ORGANIZATION: 'SEARCH_ORGANIZATION',
    SEARCH_POSITION: 'SEARCH_POSITION',
    SEARCH_QUIZ: 'SEARCH_QUIZ',
    SEARCH_RESPONSIBLE_MANAGER: 'SEARCH_RESPONSIBLE_MANAGER',
    SEARCH_TEXT_DOCUMENTS: 'SEARCH_TEXT_DOCUMENTS',
    SEARCH_TRACK_TEMPLATE: 'SEARCH_TRACK_TEMPLATE',
    SEARCH_TRACKS: 'SEARCH_TRACKS',
    SEARCH_TRACK: 'SEARCH_TRACK',
    SEARCH_TRAINING_MATERIALS: 'SEARCH_TRAINING_MATERIALS',
    SEARCH_RESPONDENTS: 'SEARCH_RESPONDENTS',
    SELECT_REPORT_FORM: 'SELECT_REPORT_FORM',
    SELECT_RESPONDENT: 'SELECT_RESPONDENT',
    TASK_PASSING_FORM: 'TASK_PASSING_FORM',
    TEMPLATES_FORM: 'TEMPLATES_FORM',
    TEST_PORTFOLIO_FORM: 'TEST_PORTFOLIO_FORM',
    THEME_FORM: 'THEME_FORM',
    TRACK_ASSIGNMENT_FORM: 'TRACK_ASSIGNMENT_FORM',
    TRACK_FORM: 'TRACK_FORM',
    UPLOAD_ALBUM_FORM: 'UPLOAD_ALBUM_FORM',
    UPLOAD_COURSE_FORM: 'UPLOAD_COURSE_FORM',
    USER_PROFILE_REPORT_FORM: 'USER_PROFILE_REPORT_FORM',
}
