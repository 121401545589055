/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios'

export interface IRequestOptions {
    headers?: any
    baseURL?: string
    responseType?: string
}

export interface IRequestConfig {
    method?: any
    headers?: any
    url?: any
    data?: any
    params?: any
}

// Add options interface
export interface ServiceOptions {
    axios?: AxiosInstance
}

// Add default options
export const serviceOptions: ServiceOptions = {}

// Instance selector
export function axios(
    configs: IRequestConfig,
    resolve: (p: any) => void,
    reject: (p: any) => void
): Promise<any> {
    if (serviceOptions.axios) {
        return serviceOptions.axios
            .request(configs)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    } else {
        throw new Error('please inject yourself instance like axios  ')
    }
}

export function getConfigs(
    method: string,
    contentType: string,
    url: string,
    options: any
): IRequestConfig {
    const configs: IRequestConfig = { ...options, method, url }
    configs.headers = {
        ...options.headers,
        'Content-Type': contentType,
    }
    return configs
}

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
    [key: string]: TValue
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
    items?: T[]
}

export class ListResultDto<T> implements IListResult<T> {
    items?: T[]
}

export interface IPagedResult<T> extends IListResult<T> {
    totalCount: number
}

export class PagedResultDto<T> implements IPagedResult<T> {
    totalCount!: number
}

// customer definition
// empty

export class ActivitiesService {
    /**
     * Получить список мероприятия для модального окна
     */
    static getForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: ActivityModalSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityModalContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список мероприятий с учетом поиска
     */
    static search(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: ActivitiesSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivitySearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivitySearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить активное мероприятие по идентификатору
     */
    static getActive(
        params: {
            /** Идентификатор мероприятия */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить мероприятие
     */
    static setAsArchive(
        params: {
            /** Идентификатор мероприятия */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить активное мероприятие для редактирвания
     */
    static getForEdit(
        params: {
            /** Идентификатор мероприятия */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/{id}/edit'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список мероприятий по идентификаторам
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать мероприятие.
     */
    static publish(
        params: {
            /** requestBody */
            body?: ActivityPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список картинок для фона холста визуализации мероприятия с пагинацией
     */
    static getActivityPictureWithPagination(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/activities/canvas-pictures/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить черновик мероприятия.
     */
    static save(
        params: {
            /** requestBody */
            body?: ActivitySaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать копию мероприятия.
     */
    static createNewVersion(
        params: {
            /** requestBody */
            body?: ActivityPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/createNewVersion'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить изображение мероприятия.
     */
    static uploadCanvasPicture(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/canvas-pictures'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить изображение мероприятия.
     */
    static upload(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/pictures'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить вложение для урока
     */
    static uploadLessonAttachment(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AttachmentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activities/lessons/attachments'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class ActivityLessonPassingService {
    /**
     * Запуск и продолжение прохождения тематичекого блока
     */
    static start(
        params: {
            /** requestBody */
            body?: ActivityLessonPassingSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityLessonPassingContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-lesson-passing/start'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Ответить на задание
     */
    static setTaskAnswer(
        params: {
            /** requestBody */
            body?: HomeworkTaskAnswerContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-lesson-passing/homework/task/answer'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить информацию по прохождению тематического блока
     */
    static info(
        params: {
            /** requestBody */
            body?: ActivityLessonPassingSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityLessonPassingContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-lesson-passing/info'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Выход из прохождения
     */
    static exit(
        params: {
            /** Идентификатор прохождения блока */
            activityLessonPassingId: number
            /** requestBody */
            body?: ActivityLessonPassingExitContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/activity-lesson-passing/{activityLessonPassingId}/exit'
            url = url.replace(
                '{activityLessonPassingId}',
                params['activityLessonPassingId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Установить ответ на вопрос из анкеты
     */
    static setAnswer(
        params: {
            /**  */
            activityLessonPassingId: number
            /** requestBody */
            body?: QuestionAnswerCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/activity-lesson-passing/{activityLessonPassingId}/quiz/answer'
            url = url.replace(
                '{activityLessonPassingId}',
                params['activityLessonPassingId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Завершить выполнение
     */
    static finish(
        params: {
            /**  */
            activityLessonPassingId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/activity-lesson-passing/{activityLessonPassingId}/finish'
            url = url.replace(
                '{activityLessonPassingId}',
                params['activityLessonPassingId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class ActivityPassingService {
    /**
     * Начать/продолжить тестовое прохождение мероприятия
     */
    static start(
        params: {
            /** requestBody */
            body?: ActivityPassingInfoContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-passing/start'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить информацию по прохождению мероприятия
     */
    static getInfo(
        params: {
            /** requestBody */
            body?: ActivityPassingInfoContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityPassingContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-passing/info'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Проверить существует ли форма прохождения мероприятия
     */
    static checkForPassingFormExist(
        params: {
            /** requestBody */
            body?: ActivityPassingInfoContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityPassingExistContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-passing/check-form-exist'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Завершение прохождения мероприятия
     */
    static finish(
        params: {
            /** requestBody */
            body?: ActivityPassingInfoContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-passing/finish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Перепройти мероприятие
     */
    static restart(
        params: {
            /** requestBody */
            body?: ActivityPassingInfoContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-passing/restart'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Начать/продолжить основное прохождение мероприятия
     */
    static startMain(
        params: {
            /** requestBody */
            body?: ActivityPassingInfoContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-passing/start-main'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получение списка вложений урока
     */
    static getAttachments(
        params: {
            /**  */
            lessonId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ActivityPassingLessonAttachmentContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/activity-passing/lesson-attachments/{lessonId}'
            url = url.replace('{lessonId}', params['lessonId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Скачать вложение урока
     */
    static downloadAttachment(
        params: {
            /**  */
            attachmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/activity-passing/lesson-attachments/{attachmentId}/download'
            url = url.replace('{attachmentId}', params['attachmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AdaptationTracksService {
    /**
     * Получить активный трек адаптации
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AdaptationTrackContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Установить статус архивной записи
     */
    static setAsArchive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить активный трек адаптации для формы назначения трека
     */
    static getActiveNoPermissions(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackShortInfoContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/get-active-no-permissions/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список треков адаптации с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TrackSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackSearchResultDtoPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/adaptation-tracks/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список треков для модального окна
     */
    static searchForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TrackModalSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackModalContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить треки адаптации по идентификаторам
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AdaptationTrackContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить трек адаптации
     */
    static save(
        params: {
            /** requestBody */
            body?: AdaptationTrackSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AdaptationTrackContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать трек адаптации
     */
    static publish(
        params: {
            /** requestBody */
            body?: AdaptationTrackPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AdaptationTrackContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать новую версию трека адаптации
     */
    static createNewVersion(
        params: {
            /** requestBody */
            body?: AdaptationTrackPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AdaptationTrackContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/createNewVersion'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить изображение мероприятия.
     */
    static upload(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/adaptation-tracks/pictures'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AppointmentActivityService {
    /**
     * Получить активное назначение мероприятия
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentActivityContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-activities/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить назначения мероприятия
     */
    static setAsArchive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-activities/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить назначения оценки с пагинацией с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AppointmentActivitySearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentActivitySearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointment-activities/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentActivitySearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointment-activities/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить
     */
    static save(
        params: {
            /** requestBody */
            body?: AppointmentActivityCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentActivityContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-activities/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать
     */
    static publish(
        params: {
            /** requestBody */
            body?: AppointmentActivityCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentActivityContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-activities/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить прогресс назначения
     */
    static getProgress(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AppointmentActivityRespondentSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<
        AppointmentActivityRespondentProgressContractAppointmentProgressPageContract
    > {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointment-activities/progress/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Приостановить назначение
     */
    static suspend(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-activities/{id}/suspend'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отменить назначение
     */
    static cancel(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-activities/{id}/cancel'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Возобновить назначение
     */
    static cancelSuspension(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-activities/{id}/cancel-suspension'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить слушателей из назначения
     */
    static deactivateParticipants(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: AppointmentActivityParticipantsDeleteContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-activities/{id}/participants'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AppointmentDictionariesService {
    /**
     * Получить возможные роли респондентов
     */
    static getAssessmentRoles(
        params: {
            /**  */
            type?: AssessmentType
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<EnumContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/dictionaries/assessment-roles'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { type: params['type'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить возможные статусы назначения оценки
     */
    static getAppointmentStatuses(
        options: IRequestOptions = {}
    ): Promise<EnumContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/dictionaries/appointment-statuses'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AppointmentsService {
    /**
     * Получить активное назначение
     */
    static getAppointment(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить назначение
     */
    static deleteAppointment(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить назначение
     */
    static saveAppointment(
        params: {
            /** requestBody */
            body?: AppointmentSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать назначение
     */
    static publishAppointment(
        params: {
            /** requestBody */
            body?: AppointmentPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список назначений с учетом поиска
     */
    static searchAppointment(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: AppointmentSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchAppointmentText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AppointmentSearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить респондентов назначения
     */
    static getParticipants(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentRespondentsContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/{id}/participants'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Деактивировать респондентов в назначении по идентификатору
     */
    static updateRespondentsAppointment(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/{id}/participants'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список назначений с учетом поиска
     */
    static searchAppointmentsForUser(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AppointmentUserSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentUserSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointments/search-for-user/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список назначений оценок для проверки эксертом
     */
    static searchForExpertChecking(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: AppointmentSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentAssessmentExpertListContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointments/search/expert-estimating/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список назначений оценок для проверки эксертом группы
     */
    static searchForGroupExpertChecking(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: AppointmentSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentAssessmentExpertListContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointments/search/group-expert-estimating/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchTextForGroupExpert(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentAssessmentExpertListContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointments/search-text/group-expert-estimating/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchTextForExpert(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentAssessmentExpertListContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointments/search-text/expert-estimating/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Приостановить назначение
     */
    static suspendAppointment(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/{id}/suspend'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отменить назначение
     */
    static cancelAppointment(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/{id}/cancel'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Возобновить назначение
     */
    static cancelSuspensionAppointment(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/{id}/cancel-suspension'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить прогресс прохождения для назначения
     */
    static getProgressAppointment(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentPassingProgressContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/{id}/progress'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить прогресс прохождения опроса
     */
    static getQuizProgress(
        params: {
            /** Идентификатор назначения опроса */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentQuizPassingProgressContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointments/quiz/{id}/progress'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AppointmentTestReviewsService {
    /**
     * Получить значения для оценивания назначения тестирования
     */
    static getAppointmentTestForReview(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentTestReviewContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-test-reviews/review/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить проверку назначения тестирования
     */
    static saveAppointmentTestReview(
        params: {
            /** requestBody */
            body?: AppointmentTestReviewCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentTestReviewContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-test-reviews/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить статистику по прохождению теста для вкладки "Общая статистика"
     */
    static getAppointmentTestStatistic(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentTestStatisticCommonContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-test-reviews/statistic/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AppointmentTracksService {
    /**
     * Получить активное назначение трека
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentTrackContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить назначение
     */
    static setAsArchive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить назначение
     */
    static save(
        params: {
            /** requestBody */
            body?: AppointmentTrackSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать назначение оценки
     */
    static publish(
        params: {
            /** requestBody */
            body?: AppointmentTrackPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить респондентов из назначении трека
     */
    static deactivateRespondents(
        params: {
            /**  */
            appointmentTrackId: number
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointment-tracks/{appointmentTrackId}/participants'
            url = url.replace(
                '{appointmentTrackId}',
                params['appointmentTrackId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отмена назначения
     */
    static cancel(
        params: {
            /**  */
            appointmentTrackId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/{appointmentTrackId}/cancel'
            url = url.replace(
                '{appointmentTrackId}',
                params['appointmentTrackId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentTrackSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointment-tracks/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список назначений с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AppointmentTrackSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentTrackSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список участников в назначении трека
     */
    static getParticipantList(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AppointmentTrackRespondentSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<
        AppointmentTrackRespondentProgressContractAppointmentProgressPageContract
    > {
        return new Promise((resolve, reject) => {
            let url =
                '/api/appointment-tracks/participant-list/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Возобновить назначение
     */
    static resume(
        params: {
            /**  */
            appointmentTrackId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/{appointmentTrackId}/resume'
            url = url.replace(
                '{appointmentTrackId}',
                params['appointmentTrackId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Приостановка назначения
     */
    static suspend(
        params: {
            /**  */
            appointmentTrackId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/{appointmentTrackId}/suspend'
            url = url.replace(
                '{appointmentTrackId}',
                params['appointmentTrackId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Импорт пользователей из файла
     */
    static importRespondents(
        params: {
            /**  */
            trackType: string
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentTrackRespondentContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/import-respondents'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['trackType']) {
                data.append('TrackType', params['trackType'] as any)
            }

            if (params['file']) {
                data.append('File', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить оценки пользователя
     */
    static getScores(
        params: {
            /** Идентификатор назначения */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AppointmentTrackRespondentScoreContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/appointment-tracks/{appointmentId}/scores'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AssessmentPortfolioDictionariesService {
    /**
     * Получить возможные статусы документа
     */
    static getDocumentStatusTypes(
        options: IRequestOptions = {}
    ): Promise<EnumContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessments-portfolios/dictionaries/document-statuses'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить возможные типы оценки
     */
    static getAssessmentTypes(
        options: IRequestOptions = {}
    ): Promise<EnumContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessments-portfolios/dictionaries/assessment-types'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить возможные виды оценки
     */
    static getAssessmentForms(
        options: IRequestOptions = {}
    ): Promise<EnumContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessments-portfolios/dictionaries/assessment-kinds'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список целей оценки
     */
    static getAll(
        options: IRequestOptions = {}
    ): Promise<AssessmentPurposeContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessments-portfolios/dictionaries/purposes'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AssessmentPortfoliosService {
    /**
     * Получить список портфелей оценки для селектов
     */
    static getForSelect(
        params: {
            /** requestBody */
            body?: AssessmentPortfolioSelectSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-portfolios/for-select'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить портфель оценки по идентификатору
     */
    static getActive(
        params: {
            /** Идентификатор портфеля оценки */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-portfolios/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить портфель оценки
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-portfolios/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить портфель оценки для редактирования
     */
    static getForEdit(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-portfolios/{id}/edit'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
 * Получить портфель оценки по идентификатору
для формы создания назначения
 */
    static getActiveNoPermissions(
        params: {
            /** Идентификатор портфеля оценки */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentPortfolioForDropdownContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-portfolios/get-active-no-permissions/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить портфель оценки
     */
    static save(
        params: {
            /** requestBody */
            body?: AssessmentPortfolioSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<number> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-portfolios/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать портфель оценки
     */
    static publish(
        params: {
            /** requestBody */
            body?: AssessmentPortfolioPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<number> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-portfolios/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список портфелей оценок с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AssessmentPortfolioSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentPortfolioSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-portfolios/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentPortfolioSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-portfolios/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить набор ролей портфеля оценки
     */
    static getRoles(
        params: {
            /** Идентификатор порфтеля оценки */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentPortfolioRolesContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-portfolios/{id}/roles'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить портфели оценок по идентификаторам
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentPortfolioByIdsResultContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-portfolios/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список портфелей оценки для модального окна
     */
    static getForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AssessmentPortfolioSearchForModalContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentPortfolioForModalContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-portfolios/for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AssessmentProjectService {
    /**
     * Поиск проектов оценок
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AssessmentProjectSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentProjectSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-projects/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск проектов оценок
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentProjectSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-projects/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить проект оценки по идентификатору
     */
    static getById(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentProjectContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-projects/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить проект оценки
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-projects/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать проект оценки
     */
    static publish(
        params: {
            /** requestBody */
            body?: AssessmentProjectPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-projects/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить проект оценки
     */
    static save(
        params: {
            /** requestBody */
            body?: AssessmentProjectSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-projects/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать портфель оценки с опросными листами
     */
    static generateQuestionnaires(
        params: {
            /** requestBody */
            body?: AssessmentProjectGenerateQuestionnairesContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentProjectGenerateQuestionnairesResultContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-projects/generate-questionnaires'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить компетенции профиля должности
     */
    static getPositionProfileCompetences(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentProjectPositionProfileContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-projects/position-profile-competences'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AssessmentReportManagerService {
    /**
     * Получить общую информацию
     */
    static getObjectInformation(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ManagerReportObjectInformationContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/manager/{id}/object-information-manager-assessment'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить общие результаты оценки компетенций сотрудника
     */
    static getSpiderWebDiagram(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ManagerReportSpiderWebContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/manager/{id}/spider-web-diagram-manager-assessment'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить результаты оценки сотрудника по компетенциям и индикаторам
     */
    static getCompetenceIndicatorResults(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ManagerReportCompetenceResultContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/manager/{id}/tables-competencies-indicators'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить расчёт соответствия профилю должности
     */
    static getCompliancePositionProfile(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ManagerReportComplianceProfileContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/manager/{id}/compliance-profile-position'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Уровни развития компетенций в соответствии с профилем должности
     */
    static getTablefDevelopmentWithProfilePosition(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ManagerReportCompetenciesByPriorityContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/manager/{id}/table-of-development-with-profile-position'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Уровни развития компетенций отсутствующих в профиле должности
     */
    static getTablefDevelopmentWithoutProfilePosition(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ManagerReportCompetenciesByPriorityContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/manager/{id}/table-of-development-without-profile-position'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AssessmentReportsService {
    /**
     * Поиск отчётов оценок
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: AssessmentReportSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentReportSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Быстрый поиск отчётов оценок
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentReportSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Включить/выключить чекбокс "Доступен для сотрудника"
     */
    static toggleAvailability(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/{id}/toggle-availability'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить отчёт по назначению
     */
    static getAssessmentReportByAppointmentId(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentReportContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AssessmentReportTemplatesService {
    /**
     * Получить шаблон по типу оценки
     */
    static getByType(
        params: {
            /**  */
            type: AssessmentType
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentReportTemplateSectionContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/templates/{type}'
            url = url.replace('{type}', params['type'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Обновить шаблон оценки
     */
    static save(
        params: {
            /** requestBody */
            body?: AssessmentReportTemplateSectionContract[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentReportTemplateSectionContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/templates'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class AttachmentsService {
    /**
     * Загрузить файл
     */
    static upload(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AttachmentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/attachments/upload'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Уделение приложенного файла
     */
    static delete(
        params: {
            /** Идентификатор приложенного файла */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/attachments/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class CatalogService {
    /**
     * Получить список тематических разделов.
     */
    static getCatalogItemsAsync(
        options: IRequestOptions = {}
    ): Promise<CatalogItemContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/catalog'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск продуктов в каталоге.
     */
    static searchAsync(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: CatalogItemSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CatalogItemProductCardContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/catalog/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список продуктов добавленных в избранное.
     */
    static getProductCardFavoritesAsync(
        params: {
            /**  */
            getAll?: boolean
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CatalogItemProductCardContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/catalog/product-favorites'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { getAll: params['getAll'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Добавить/удалить продукт в избранное.
     */
    static toggleProductCardFavoriteAsync(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CatalogItemProductCardFavoriteResult> {
        return new Promise((resolve, reject) => {
            let url = '/api/catalog/product-favorites/{productCardId}'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить анонс продукта.
     */
    static getProductCardAnnouncementAsync(
        params: {
            /**  */
            productId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CatalogItemProductCardAnnouncementContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/catalog/products/{productId}/announcement'
            url = url.replace('{productId}', params['productId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить превью продукта для каталога.
     */
    static getCatalogProductCardPreviewAsync(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CatalogItemProductCardPreviewContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/catalog/products/{productCardId}/preview'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Проверка доступности просмотра карточки продукта
     */
    static checkAvailability(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CatalogItemCheckAvailabilityResultContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/catalog/products/{productCardId}/check'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить названия продуктов для автосаджеста.
     */
    static autosuggestAsync(
        params: {
            /**  */
            query: string
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/catalog/products/autosuggest/{query}'
            url = url.replace('{query}', params['query'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class CertificatesService {
    /**
     * Получить сертификат по идентификатору
     */
    static getById(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CertificateContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/certificates/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить сертификат
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/certificates/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить сертификаты с учетом поиска и пагинации
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: CertificateSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CertificateSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/certificates/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать внешний сертификат
     */
    static create(
        params: {
            /** requestBody */
            body?: CertificateCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CertificateContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/certificates/external'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Обновить внешний сертификат
     */
    static update(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: CertificateCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CertificateContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/certificates/external/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить файл сертификата
     */
    static getFile(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/certificates/{id}/file'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class CertificatesFilesService {
    /**
     * Загрузить шаблон сертификатов.
     */
    static upload(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NoContentResult> {
        return new Promise((resolve, reject) => {
            let url = '/api/certificates/files/pattern'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отправить сертификат на email
     */
    static sendCertificateToEmail(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/certificates/files/send-as-email/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class Circle360ReportService {
    /**
     * Общая информация
     */
    static getObjectInformationAssessment360(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ObjectInformationAssessment360Contract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/circle/{id}/object-information-assessment360'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Группы респондентов, принимавших участие в оценке Ваших компетенций
     */
    static getGroupsOfRespondents(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<GroupsOfRespondentsContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/circle/{id}/groups-of-respondents'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Оценки компетенций с разбивкой по группам респондентов
     */
    static getSpiderWebDiagramAssessment360(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<SpiderWebDiagramAssessment360Contract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/circle/{id}/spider-web-diagram-assessment360'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Рейтинг оценок по компетенциям
     */
    static getCompetenceRating360(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceRating360Contract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/circle/{id}/competence-rating'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Компетенции низкого и высокого уровня развития
     */
    static getCompetenciesLowHighLevel(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenciesLowHighLevel360Contract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/circle/{id}/competencies-low-high-level'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Оценки групп респондентов по индикаторам компетенций
     */
    static getAssessmentsRespondentsIndicators(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AssessmentsRespondentsIndicatorsReport360Contract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/circle/{id}/assessments-respondents-indicators'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сильные стороны и зоны развития
     */
    static getAreasStrengthsDevelopment(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<StrongSidesAndZonesOfDevelopmentReport360Contract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/circle/{id}/areas-strengths-development'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Скрытые возможности
     */
    static getHiddenPossibilities(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<HiddenPossibilitiesReport360Contract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/circle/{id}/hidden-possibilities'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Скрытые проблемы
     */
    static getHiddenProblems(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<HiddenProblemsReport360Contract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/circle/{id}/hidden-problems'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Комментарии респондентов
     */
    static getCommentsOfRespondents(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<RespondentGroupCommentsContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/assessment-reports/circle/{id}/comments-of-respondents'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Рассчитать процентиль
     */
    static getPercentiles(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PercentileReport360Contract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/circle/{id}/percentile'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Подготовка отчета по оценке для выгрузки
     */
    static startBuildReport(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<string> {
        return new Promise((resolve, reject) => {
            let url = '/api/assessment-reports/circle/{id}/start-build-report'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class CircleReportsService {
    /**
     * Получить сводный отчет по ид назначения
     */
    static getConsolidatedReportByAppointmentId(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ConsolidatedCircleReportContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/circle-reports/{appointmentId}/consolidated'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить отчет по сильным/слабым сторонам по ид назначения
     */
    static getSidesReportByAppointmentId(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<SidesCircleReportContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/circle-reports/{appointmentId}/sides'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить отчеты для пользователя по ид пользователя
     */
    static getByUserId(
        params: {
            /** requestBody */
            body?: ReportsSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CircleReportSmallContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/circle-reports/for-user'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить отчеты для для профиля пользователя
     */
    static getByOwnerId(
        params: {
            /** requestBody */
            body?: ReportsSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CircleReportSmallContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/circle-reports/for-owner'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить результаты оценок по компетенциям по ид назначений
     */
    static getCompetenceResultsForComparison(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceGroupComparingContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/circle-reports/competence-results'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Добавить/удалить доступ пользователя к отчетам.
     */
    static toggleReportAccess(
        params: {
            /** requestBody */
            body?: CircleReportsAccessContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let url = '/api/circle-reports/report-accesses'

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class CompetenceGroupsService {
    /**
     * Получить список всех групп компетенций
     */
    static getAll(
        options: IRequestOptions = {}
    ): Promise<CompetenceGroupContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies-groups'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список компетенций для модалок
     */
    static getForModal(
        options: IRequestOptions = {}
    ): Promise<CompetenceGroupContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies-groups/for-modal'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceGroupContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies-groups/search-text'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список всех групп компетенций, комптенций и индикторов в виде иерархии
     */
    static getAllHierarchy(
        options: IRequestOptions = {}
    ): Promise<CompetenceGroupWithCompetenciesContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies-groups/hierarchy'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить группу компетенций по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceGroupContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies-groups/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить группу компетенций
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies-groups/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить группу компетенций
     */
    static save(
        params: {
            /** requestBody */
            body?: CompetenceGroupCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceGroupContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies-groups/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать группу компетенций
     */
    static publish(
        params: {
            /** requestBody */
            body?: CompetenceGroupCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceGroupContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies-groups/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Передвинуть объект в списке
     */
    static move(
        params: {
            /** requestBody */
            body?: SetPositionArgsContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies-groups/move'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class CompetenciesService {
    /**
     * Парсинг файла с компетенциями (XLSX, CSV)
     */
    static parseFile(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenciesUploadResultContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/parse-file'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список компетенций для выпадающего списка
     */
    static getAll(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список всех компетенций с учетом поиска
     */
    static search(
        params: {
            /** Количество компетенций отображаемых на странице */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: CompetenciesSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Возвращает список компетенций для модального окна
     */
    static getForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: CompetenciesSearchForModalContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceForModalContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить компетенцию
     */
    static save(
        params: {
            /** requestBody */
            body?: CompetenceCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать компетенцию
     */
    static publish(
        params: {
            /** requestBody */
            body?: CompetenceCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Передвинуть объект в списке
     */
    static move(
        params: {
            /** requestBody */
            body?: SetPositionArgsContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/move'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить компетенцию
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NoContentResult> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить компетенцию по идентификатору
     */
    static getById(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить компетенции по массиву идентификаторов
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CompetenceContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить компетенции по массиву ид групп компетенций
     */
    static getByCompetenceGroupIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileCompetenceContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/by-competence-group-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить компетенции по ид функциональных классов
     */
    static getByFunctionClassesIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileCompetenceContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/by-function-class-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить компетенции по ид департамента
     */
    static getByDepartmentIds(
        params: {
            /**  */
            id: string
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileCompetenceContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/{id}/by-department-ids'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Возвращает список групп компетенции по уровню должности
     */
    static getAllActiveByLevelId(
        params: {
            /**  */
            levelId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileCompetenceContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/competencies/by-level-id/{levelId}'
            url = url.replace('{levelId}', params['levelId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class CoursesService {
    /**
     * Получить курс по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CourseFullContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/by-id/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить курс по ключу
     */
    static getByKey(
        params: {
            /**  */
            key: string
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CourseFullContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/by-key/{key}'
            url = url.replace('{key}', params['key'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск курсов
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: CourseSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CourseSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CourseSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список курса для модального окна
     */
    static getForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: CourseSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CourseForModalContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Обновить информацию о курсе
     */
    static updateInfo(
        params: {
            /** requestBody */
            body?: CourseInfoSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CourseContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/update-info'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить возможные форматы курсов
     */
    static getCourseTypes(
        options: IRequestOptions = {}
    ): Promise<EnumContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/formats'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить курсы по массиву идентификаторов
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CourseContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузка курса (версия с раздельной загрузкой)
     */
    static registerUpload(
        params: {
            /** requestBody */
            body?: CourseAsyncUploadContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CourseFileInfoContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/upload/register'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Обработка курса
     */
    static processUpload(
        params: {
            /** requestBody */
            body?: CourseUploadIdContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/upload/process'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отмена обработки курса
     */
    static cancelUpload(
        params: {
            /** requestBody */
            body?: CourseUploadIdContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/upload/cancel'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить файл курса
     */
    static getFile(
        params: {
            /** Идентификатор курса */
            id: number
            /** Путь к файлу */
            path: string
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/{id}/files/{path}'
            url = url.replace('{id}', params['id'] + '')
            url = url.replace('{path}', params['path'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить курс
     */
    static setAsArchive(
        params: {
            /** Идентификатор курса */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить связанные с курсом назначения
     */
    static getRelatedAppointments(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CourseAppointmentContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/{id}/appointments'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сделать версию курса основной
     */
    static setCourseAsMainScopeVersion(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/set-main/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Пройден ли тинкан пользователем
     */
    static tincanIsPassed(
        params: {
            /**  */
            courseId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let url = '/api/courses/{courseId}/tincan/is-passed'
            url = url.replace('{courseId}', params['courseId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class CoursometerService {
    /**
     * Зарегистрировать трек адаптации как "курс" в курсометре
     */
    static registerAdaptationTrack(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/coursometer/adaptation-tracks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Зарегистрировать трек адаптации как "курс" в курсометре
     */
    static registerDevelopmentTrack(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/coursometer/development-tracks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class CurrentUserSettingsService {
    /**
     * Получить список настроек оповещений текущего пользователя
     */
    static getNotificationsSettings(
        options: IRequestOptions = {}
    ): Promise<UserNotificationSettingContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/current/settings/notifications'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить настройку оповещения по типу и теме
     */
    static getNotificationSetting(
        params: {
            /**  */
            type: NotificationType
            /**  */
            theme: NotificationThemeType
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserNotificationSettingContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/current/settings/notifications/{type}/{theme}'
            url = url.replace('{type}', params['type'] + '')
            url = url.replace('{theme}', params['theme'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Изменить способ отправки оповещений текущему пользователю
     */
    static saveNotificationSettings(
        params: {
            /** requestBody */
            body?: UserNotificationSettingSaveContract[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/current/settings/notifications/save-settings'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class DepartmentsService {
    /**
     * Получить список подразделений по идентификаторам организаций
     */
    static getByOrganizationsIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/departments/by-organizations-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class DevelopmentGoalsService {
    /**
     * Создать цель развития
     */
    static create(
        params: {
            /** requestBody */
            body?: DevelopmentGoalCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DevelopmentGoalContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-goals'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить активную цель развития
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DevelopmentGoalContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-goals/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Изменить цель развития
     */
    static update(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: DevelopmentGoalCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DevelopmentGoalContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-goals/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить цель развития
     */
    static setAsArchive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-goals/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск целей развития
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: DevelopmentGoalsSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DevelopmentGoalsSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-goals/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список целей оценки
     */
    static getAllForSelect(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-goals/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class DevelopmentTracksService {
    /**
     * Сохранить трек развития
     */
    static save(
        params: {
            /** requestBody */
            body?: DevelopmentTrackSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DevelopmentTrackContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-tracks/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать трек развития
     */
    static publish(
        params: {
            /** requestBody */
            body?: DevelopmentTrackPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DevelopmentTrackContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-tracks/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать новую версию трека развития
     */
    static createNewVersion(
        params: {
            /** requestBody */
            body?: DevelopmentTrackPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DevelopmentTrackContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-tracks/createNewVersion'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить активный трек развития
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DevelopmentTrackContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-tracks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Установить статус архивной записи
     */
    static setAsArchive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-tracks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить активный трек развития для формы назначения трека
     */
    static getActiveNoPermissions(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackShortInfoContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-tracks/get-active-no-permissions/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список треков развтия поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TrackSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-tracks/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/development-tracks/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список треков для модального окна
     */
    static searchForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TrackModalSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackModalContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/development-tracks/for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список треков развития по идентификаторам
     */
    static getDevelopmentTracksByids(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackSearchResultContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-tracks/get-by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить изображение мероприятия.
     */
    static upload(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/development-tracks/pictures'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class EntityEditSessionService {
    /**
     * Проверка доступности портфеля опросов
     */
    static checkAvailability(
        params: {
            /**  */
            id: number
            /**  */
            entity: EntityType
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<EntityCheckAvailabilityResultContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/edit-sessions/{entity}/{id}/check'
            url = url.replace('{id}', params['id'] + '')
            url = url.replace('{entity}', params['entity'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отменить сессию редактирования
     */
    static editSessionCancel(
        params: {
            /**  */
            id: number
            /**  */
            entity: EntityType
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/edit-sessions/{entity}/{id}/cancel'
            url = url.replace('{id}', params['id'] + '')
            url = url.replace('{entity}', params['entity'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class EnumTableDictionariesService {
    /**
     * Получить список типов оценки из базы
     */
    static getEntityAssessmentTypes(
        options: IRequestOptions = {}
    ): Promise<EnumTableContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/enum-table/dictionaries/assessment-types'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class ExpertEvaluationListService {
    /**
     * Получить оценочный лист по ид назначения
     */
    static getExpertEvaluationList(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ExpertEvaluationListContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/expert-evaluation-lists/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Завершить заполнение оценочного листа
     */
    static finish(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/expert-evaluation-lists/{appointmentId}/finish'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить страницу оценочного листа по ид компетенции и ид назначения
     */
    static getEvaluationListPage(
        params: {
            /**  */
            appointmentId: number
            /**  */
            competenceId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ExpertEvaluationListCompetenceContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/expert-evaluation-lists/{appointmentId}/page/{competenceId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')
            url = url.replace('{competenceId}', params['competenceId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить страницу оценочного листа
     */
    static saveEvaluationListPage(
        params: {
            /** requestBody */
            body?: ExpertEvaluationListCompetenceSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/expert-evaluation-lists/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class FaceToFaceMeetingsJournalsService {
    /**
     * Получить список журналов общих встреч
     */
    static search(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: FaceToFaceMeetingJournalSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<FaceToFaceMeetingJournalRegistryContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/face-to-face-meetings/journals/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список журналов общих встреч
     */
    static searchText(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<FaceToFaceMeetingJournalRegistryContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/face-to-face-meetings/journals/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить журнал очной встречи
     */
    static get(
        params: {
            /** Идентификатор личной встречи */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<FaceToFaceMeetingJournalContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/face-to-face-meetings/journals/{id}/listeners'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить данные журнала
     */
    static save(
        params: {
            /** requestBody */
            body?: FaceToFaceMeetingJournalSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/face-to-face-meetings/journals/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Подтвердить журнал
     */
    static publish(
        params: {
            /** requestBody */
            body?: FaceToFaceMeetingJournalSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/face-to-face-meetings/journals/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class FeedbackQuizReportsService {
    /**
     * Получить заголовок для отчета по опросу типа обратная связь
     */
    static getReportHeader(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<FeedbackQuizReportTitleContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/feedback-quiz-reports/title/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получение вкладки "статистика" для отчета по опросу типа обратная связь
     */
    static getStatisticsTab(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<FeedbackQuizReportStatisticsContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/feedback-quiz-reports/statistics/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получение вкладки "Графики - Гистограмма со средними значениями" для отчета по опросу типа обратная связь
     */
    static getGraficsTab(
        params: {
            /**  */
            appointmentId: number
            /**  */
            isSemantic?: boolean
            /**  */
            pageSize?: number
            /**  */
            pageNumber?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<FeedbackQuizReportGraficTabContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/feedback-quiz-reports/grafics/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = {
                isSemantic: params['isSemantic'],
                pageSize: params['pageSize'],
                pageNumber: params['pageNumber'],
            }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получение вкладки "Свободный ответ" для отчета по опросу типа обратная связь
     */
    static getFreeAnswersTab(
        params: {
            /**  */
            appointmentId: number
            /**  */
            feeAnswerTabType?: FreeAnswerTabType
            /**  */
            pageSize?: number
            /**  */
            pageNumber?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<FeedbackQuizReportFreeAnswerContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/feedback-quiz-reports/free-answers/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = {
                feeAnswerTabType: params['feeAnswerTabType'],
                pageSize: params['pageSize'],
                pageNumber: params['pageNumber'],
            }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получение вкладки "Затраченное время" для отчета по опросу типа обратная связь
     */
    static getElapsedTimeTab(
        params: {
            /** Идентификатор назначения */
            appointmentId: number
            /** Размер страницы */
            pageSize?: number
            /** Номер страницы */
            pageNumber?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<FeedbackQuizReportsElapsedTimeContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/feedback-quiz-reports/elapsed-time/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = {
                pageSize: params['pageSize'],
                pageNumber: params['pageNumber'],
            }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class GlossariesService {
    /**
     * Получить глоссарий с учетом поиска
     */
    static search(
        params: {
            /** requestBody */
            body?: GlossariesSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<GlossarySectionContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/search'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить глоссарий с учетом строки быстрого поиска
     */
    static searchText(
        params: {
            /** requestBody */
            body?: GlossariesSearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<GlossarySectionContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/search-text'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить статьи из раздела
     */
    static searchBySection(
        params: {
            /** requestBody */
            body?: GlossariesSearchBySectionContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<GlossaryArticleContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/search/articles'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список разделов
     */
    static getSections(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/sections'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать новый раздел
     */
    static createSection(
        params: {
            /** requestBody */
            body?: SectionCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/sections'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Редактировать раздел
     */
    static updateSection(
        params: {
            /** requestBody */
            body?: SectionUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/sections'

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить раздел
     */
    static deleteSection(
        params: {
            /** Идентификатор раздела */
            sectionId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/sections/{sectionId}'
            url = url.replace('{sectionId}', params['sectionId'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить статью базы знаний
     */
    static getArticle(
        params: {
            /** Идентификатор статьи */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ArticleContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/articles/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать новую статью
     */
    static createArticle(
        params: {
            /** requestBody */
            body?: ArticleCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/articles'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Обновить статью
     */
    static updateArticle(
        params: {
            /** requestBody */
            body?: ArticleUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/articles'

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Копировать карточку статьи
     */
    static copyArticle(
        params: {
            /** Идентификатор карточки */
            articleId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/articles/{articleId}/copy'
            url = url.replace('{articleId}', params['articleId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить карточку статьи
     */
    static deleteArticle(
        params: {
            /** Идентификатор карточки статьи */
            articleId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/glossaries/articles/{articleId}'
            url = url.replace('{articleId}', params['articleId'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class HomeworksService {
    /**
     * Получить список домашних работ
     */
    static homeworksSearch(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: HomeworkCheckSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<HomeworkRegistryItemContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в списке своих домашних работ при помощи строки поиска
     */
    static homeworksSearchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<HomeworkRegistryItemContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     *
     */
    static uploadTaskAttachment(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AttachmentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/task/attachment'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список своих домашних работ
     */
    static myHomeworkSearch(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: HomeworkGetContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<HomeworkRegistryItemContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/my/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в списке своих домашних работ при помощи строки поиска
     */
    static myHomeworksSearchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<HomeworkRegistryItemContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/my/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить данные по домашней работе
     */
    static info(
        params: {
            /** Идентификатор домашней работы */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<HomeworkInfoContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/{id}/info'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Обновить комментарий
     */
    static updateComment(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: HomeworkUpdateCommentContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/{id}/info'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поставить зачет на задание
     */
    static tutorCheck(
        params: {
            /** requestBody */
            body?: HomeworkTaskTutorCheckContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/task/tutor-check'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Запустить проверку домашнего задания
     */
    static startCheck(
        params: {
            /** Идентификатор прохождения домашнего задания */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<HomeworkDocumentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/{id}/start-check'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Взять в работу последнее доступное дз
     */
    static startCheckLast(
        options: IRequestOptions = {}
    ): Promise<HomeworkDocumentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/start-check-last'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Завершить проверку домашнего задания
     */
    static finishCheck(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: HomeworkCheckResultContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/{id}/finish-check'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отправить задание на проверку
     */
    static sendToCheck(
        params: {
            /** Идентификатор задания */
            id: number
            /** флаг автопроверки тьютором */
            isHomeworkAutoCheck?: boolean
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/{id}/send-to-check'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )
            configs.params = {
                isHomeworkAutoCheck: params['isHomeworkAutoCheck'],
            }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Обработать событие о закрытии модалки с прохождением ДЗ (не редактор)
     */
    static onExit(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/{id}/on-exit'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список задач на прохождение с ответами и комментариями для заполнения блока "Историчность"
     */
    static getHistoryTaskPassings(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<MissionTaskPassingsAsHistoricalByTaskNumberContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/{id}/history-task-passings'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список "референсов" с ответами и комментариями для заполнения блока "Референсы"
     */
    static getTaskPassingsReferences(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<MissionTaskPassingReferencesByTaskNumberContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/homeworks/{id}/task-passing-references'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class IndicatorsService {
    /**
     * Получить справочник индикаторов с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: IndicatorsSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<IndicatorContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicators/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<IndicatorContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicators/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить индикатор
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicators/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить индикатор по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<IndicatorContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicators/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить индикатор
     */
    static save(
        params: {
            /** requestBody */
            body?: IndicatorCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<IndicatorContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicators/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать индикатор
     */
    static publish(
        params: {
            /** requestBody */
            body?: IndicatorCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<IndicatorContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicators/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить индикаторы по идентификатору компетенций
     */
    static getIndicatorsByCompetenceId(
        params: {
            /**  */
            competenceId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<IndicatorInfoContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicators/by-competence-id/{competenceId}'
            url = url.replace('{competenceId}', params['competenceId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить индикаторы по массиву идентификаторов
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<IndicatorContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicators/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Передвинуть индикатор в списке
     */
    static move(
        params: {
            /** requestBody */
            body?: SetPositionArgsContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicators/move'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class IndicatorTypesService {
    /**
     * Получить справочник типов индикаторов
     */
    static getAllIndicatorsType(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/indicator-types'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class LdeReportsService {
    /**
     * Добавить новый отчёт LDE
     */
    static createLdeReport(
        params: {
            /** requestBody */
            body?: LdeReportAddContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<LdeReportContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/lde-reports'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск по отчётам LDE
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: LdeReportSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<LdeReportContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/lde-reports/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре отчётов LDE при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<LdeReportContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/lde-reports/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить отчёт LDE
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/lde-reports/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class LrsService {
    /**
     * Получить состояние прохождения для курса по ключевому слову
     */
    static getCourseStateByKey(
        params: {
            /** Ключевое слово */
            key: string
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<LrsActivityStateContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/lrs/courses/{key}'
            url = url.replace('{key}', params['key'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class MissionsService {
    /**
     * Создать или обновить задание
     */
    static saveMission(
        params: {
            /** requestBody */
            body?: MissionSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<MissionContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/missions/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить задание по id
     */
    static getById(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<MissionContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/missions/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить задание
     */
    static deleteMission(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/missions/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск по заданиям
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: MissionSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<MissionSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/missions/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить задание по id для модального окна
     */
    static getByIdForModal(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/missions/{id}/for-modal'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск по заданиям для модального окна
     */
    static searchForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: MissionSearchForModalContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/missions/search-for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить задания по идентификатору задачи
     */
    static getMissionTasks(
        params: {
            /**  */
            missionId?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<MissionTaskContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/missions'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { missionId: params['missionId'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Быстрый поиск
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<MissionSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/missions/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationDestinationCategoriesService {
    /**
     * Получить категории объектов назначений для сeлекта
     */
    static getForSelect(
        options: IRequestOptions = {}
    ): Promise<NotificationDestinationCategoryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-destination-categories/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationDestinationSubCategoriesService {
    /**
     * Получить подкатегории объектов назначений для селекта
     */
    static getForSelect(
        options: IRequestOptions = {}
    ): Promise<NotificationDestinationSubCategoryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-destination-subcategories/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить подкатегории объектов назначений по идентификатору категории для селекта
     */
    static getForSelectByCategory(
        params: {
            /**  */
            categoryId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationDestinationSubCategoryContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-destination-subcategories/for-select/{categoryId}'
            url = url.replace('{categoryId}', params['categoryId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationDynamicFieldMarkersService {
    /**
     * Получить список маркеров динамических полей оповещений с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: NotificationDynamicFieldMarkerSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationDynamicFieldMarkerSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-markers/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationDynamicFieldMarkerSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-markers/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить маркеры динамических полей оповещений для селекта с возможной фильтрацией по категории
     */
    static getForSelectAll(
        options: IRequestOptions = {}
    ): Promise<NotificationDynamicFieldMarkerSelectDto[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-markers/for-select/all'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить маркеры динамических полей оповещений для селекта с возможной фильтрацией по категории
     */
    static getForSelectByCategory(
        params: {
            /**  */
            categoryId?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationDynamicFieldMarkerSelectDto[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-markers/for-select/by-category'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { categoryId: params['categoryId'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить маркеры динамических полей оповещений для селекта с возможной фильтрацией по категории
     */
    static getForSelectWithoutCategory(
        options: IRequestOptions = {}
    ): Promise<NotificationDynamicFieldMarkerSelectDto[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-markers/for-select/without-category'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать маркер динамического поля оповещения
     */
    static create(
        params: {
            /** requestBody */
            body?: NotificationDynamicFieldMarkerCreateOrUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationDynamicFieldMarkerContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-markers/create'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Редактировать маркер динамического поля оповещения
     */
    static update(
        params: {
            /** requestBody */
            body?: NotificationDynamicFieldMarkerCreateOrUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationDynamicFieldMarkerContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-markers/update'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить маркер динамического поля оповещения
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-markers/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationEventsService {
    /**
     * Получить события оповещений для селекта
     */
    static getForSelect(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-events/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить событие оповещений
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationEventContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-events/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationFilteringConditionsService {
    /**
     * Получение списка усоловмий для модального окна
     */
    static getForSelect(
        params: {
            /** Идентификатор события */
            eventId?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationFilteringConditionContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-filtering/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { eventId: params['eventId'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationMailingInformationsService {
    /**
     * Получить список сведений о рассылках с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: NotificationMailingInfoSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationMailingInfoSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-informations/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationMailingInfoSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-informations/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отмена рассылки
     */
    static cancel(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-informations/{id}/cancel'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить электронные почты пользователей для селекта из отправленных
     */
    static getMailsForSelect(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-informations/recipients/mails/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отправить повторно неотправленные сообщения для списка сведений о рассылках
     */
    static resend(
        params: {
            /** requestBody */
            body?: NotificationMailingInfoResendDto
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-informations/resend'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить сведение о рассылке по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationMailingInfoContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-informations/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список получателей сформированных сообщений с учетом поиска для сведения о рассылке
     */
    static recipientsSearch(
        params: {
            /**  */
            id: number
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: NotificationRecipientEmailInfoSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationRecipientEmailInfoSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-informations/{id}/recipients/search/{pageSize}/{pageNumber}'
            url = url.replace('{id}', params['id'] + '')
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Быстрый поиск по списку получателей сформированных сообщений
     */
    static recipientsSearchByText(
        params: {
            /**  */
            id: number
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationRecipientEmailInfoSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-informations/{id}/recipients/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{id}', params['id'] + '')
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить содержимое письма
     */
    static getEmailContent(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationEmailInfoContentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-informations/emails/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить статистику рассылки
     */
    static getStatistics(
        params: {
            /** requestBody */
            body?: NotificationMailingInfoSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationDeliveryStatusStatisticsContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-informations/statistics'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationMailingTasksService {
    /**
     * Получить задание на рассылку по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationMailingTaskContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-tasks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить задание на рассылку
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-tasks/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить черновик задания на рассылку
     */
    static save(
        params: {
            /** requestBody */
            body?: NotificationMailingTaskCreateOrUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationMailingTaskContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-tasks/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Запланировать (отправить) задание на рассылку
     */
    static schedule(
        params: {
            /** requestBody */
            body?: NotificationMailingTaskCreateOrUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationMailingTaskContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-tasks/schedule'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список заданий на рассылку с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: NotificationMailingTaskSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationMailingTaskSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-tasks/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationMailingTaskSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-tasks/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список объектов назначений с пагинацией для модального окна
     */
    static searchAppointmentObjects(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: NotificationMailingTaskAppointmentObjectSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<
        NotificationMailingTaskAppointmentObjectSearchResultContractPageContract
    > {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-tasks/for-modal/objects/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список назначений с пагинацией для модального окна
     */
    static searchAppointments(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: NotificationMailingTaskAppointmentSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<
        NotificationMailingTaskAppointmentSearchResultContractPageContract
    > {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-tasks/for-modal/appointments/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск других пересекающихся заданий по событию для проверки при сохранение/планирование
     */
    static searchByTask(
        params: {
            /** requestBody */
            body?: NotificationMailingTaskByEventSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-tasks/search-by-task/event'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить файл к заданию на рассылку
     */
    static upload(
        params: {
            /**  */
            ids: []
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AttachmentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-tasks/upload'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['ids']) {
                data.append('Ids', params['ids'] as any)
            }

            if (params['file']) {
                data.append('File', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отменить задание на рассылку
     */
    static cancel(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-tasks/{id}/cancel'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список объектов назначений по идентификаторам, используя событие
     */
    static getAppointmentObjectsByEventAndIds(
        params: {
            /**  */
            eventId: number
            /**  */
            subCategoryId?: number
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-tasks/objects/by-event/{eventId}/by-ids'
            url = url.replace('{eventId}', params['eventId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )
            configs.params = { subCategoryId: params['subCategoryId'] }
            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список объектов назначений по идентификаторам, используя категорию
     */
    static getAppointmentObjectsByCategoryAndIds(
        params: {
            /**  */
            categoryId: number
            /**  */
            subCategoryId?: number
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-tasks/objects/by-category/{categoryId}/by-ids'
            url = url.replace('{categoryId}', params['categoryId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )
            configs.params = { subCategoryId: params['subCategoryId'] }
            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список назначений по идентификаторам
     */
    static getAppointmentsByIds(
        params: {
            /**  */
            categoryId: number
            /**  */
            subCategoryId?: number
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-tasks/appointments/{categoryId}/by-ids'
            url = url.replace('{categoryId}', params['categoryId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )
            configs.params = { subCategoryId: params['subCategoryId'] }
            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Проверить изменилось ли задание на рассылку после фактических даты и времени начала рассылки
     */
    static checkIsChangedTask(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<BooleanContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-tasks/{id}/is-changed-after-mailing'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Приостановить задание на рассылку
     */
    static suspend(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-tasks/{id}/suspend'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Восстановить задание на рассылку
     */
    static resume(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-mailing-tasks/{id}/resume'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationMailingTaskTestingService {
    /**
     * Съимитировать событие и сформировать оповещения по заданиями на рассылку
     */
    static simulate(
        params: {
            /**  */
            category: NotificationDestinationCategoryCode
            /** requestBody */
            body?: NotificationSimulateEventContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-task-testing/{category}/simulate-event'
            url = url.replace('{category}', params['category'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Съимитировать событие без категории (только по пользователям) и сформировать оповещения по заданиями на рассылку
     */
    static simulateWithoutCategory(
        params: {
            /** requestBody */
            body?: NotificationSimulateEventContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-task-testing/without-category/simulate-event'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить расчёт прохождения мероприятия
     */
    static getPassingProgressAtActivity(
        params: {
            /** Идентификатор назначения мероприятия */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PassingProgressDto> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-task-testing/passing-progress/activities/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить расчёт прохождения трека
     */
    static getPassingProgressAtTrack(
        params: {
            /** Идентификатор назначения трека */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PassingProgressDto> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-mailing-task-testing/passing-progress/tracks/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationNewsletterTemplatesService {
    /**
     * Получить список шаблонов оповещений на рассылку с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: NotificationNewsletterTemplateSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationNewsletterTemplateSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-newsletter-templates/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationNewsletterTemplateSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-newsletter-templates/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить шаблон оповещения по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationNewsletterTemplateContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-newsletter-templates/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить шаблон оповещения
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-newsletter-templates/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить черновик шаблона оповещения на рассылку
     */
    static save(
        params: {
            /** requestBody */
            body?: NotificationNewsletterTemplateCreateOrUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationNewsletterTemplateContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-newsletter-templates/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать шаблон оповещения на рассылку
     */
    static publish(
        params: {
            /** requestBody */
            body?: NotificationNewsletterTemplateCreateOrUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationNewsletterTemplateContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-newsletter-templates/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить шаблоны оповещений для селекта в статусе "Опубликован"
     */
    static getForSelect(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-newsletter-templates/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationsService {
    /**
     * Получить список уведомлений для пользователя
     */
    static search(
        params: {
            /**  */
            pageSize?: number
            /**  */
            pageNumber?: number
            /** requestBody */
            body?: NotificationSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notifications/search'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )
            configs.params = {
                pageSize: params['pageSize'],
                pageNumber: params['pageNumber'],
            }
            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить метаданные уведомлений для пользователя
     */
    static getMeta(
        options: IRequestOptions = {}
    ): Promise<NotificationsMetadataContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notifications'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить оповещения
     */
    static delete(
        params: {
            /** requestBody */
            body?: NotificationBatchOperationContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationsMetadataContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notifications'

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отметить сообщения прочитанными
     */
    static markAsRead(
        params: {
            /** requestBody */
            body?: NotificationBatchOperationContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationsMetadataContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notifications/read'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationSendingOptionsService {
    /**
     * Получить варианты отправки оповещений для селекта
     */
    static getForSelect(
        options: IRequestOptions = {}
    ): Promise<NotificationSendingOptionSelectContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-sending-options/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationsTestService {
    /**
     * Уведомление о создании нового пользователя.
     */
    static userCreated(
        params: {
            /** requestBody */
            body?: TestRequest
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/tests/notifications/user-created'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Приближение контрольного срока прохождения оценки.
     */
    static approximationAssessmentDeadline(
        params: {
            /** requestBody */
            body?: TestRequest
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/tests/notifications/assessment-deadline-approach'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отмена назначенной оценки.
     */
    static cancelAssignedAssessment(
        params: {
            /** requestBody */
            body?: TestRequest
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/tests/notifications/cancel-assigned-assessment'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Эксперт проверил результаты слушателя.
     */
    static expertCheckedResultsListener(
        params: {
            /** requestBody */
            body?: TestRequest
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/tests/notifications/expert-checked-results-listener'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Слушатель прошел оценку.
     */
    static listenerPassedAssessment(
        params: {
            /** requestBody */
            body?: TestRequest
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/tests/notifications/listener-passed-assessment'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Уведомление о назначении оценки.
     */
    static ratingAssigned(
        params: {
            /** requestBody */
            body?: TestRequest
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/tests/notifications/rating-assigned'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Нарушение контрольного срока прохождения оценки.
     */
    static violationAssessmentDeadline(
        params: {
            /** requestBody */
            body?: TestRequest
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/tests/notifications/violation-assessment-deadline'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Приостановление назначенной оценки.
     */
    static suspendAppointment(
        params: {
            /** requestBody */
            body?: TestRequest
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/tests/notifications/suspend-appointment'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Возобновление назначенной оценки.
     */
    static resumeAppointment(
        params: {
            /** requestBody */
            body?: TestRequest
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/tests/notifications/resume-appointment'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class NotificationTagsService {
    /**
     * Получить список тегов оповещений с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: NotificationTagSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationTagSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-tags/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationTagSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/notification-tags/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить теги оповещений для селекта
     */
    static getForSelect(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-tags/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать тег оповещения
     */
    static create(
        params: {
            /** requestBody */
            body?: NotificationTagCreateOrUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationTagContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-tags/create'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Редактировать тег оповещения
     */
    static update(
        params: {
            /** requestBody */
            body?: NotificationTagCreateOrUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NotificationTagContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-tags/update'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить тег оповещения
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/notification-tags/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class OrganizationsService {
    /**
     * Получить дерево организаций
     */
    static getTreeOrganizations(options: IRequestOptions = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/organizations/organizations-tree'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список организаций
     */
    static getAll(
        options: IRequestOptions = {}
    ): Promise<OrganizationInfoContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/organizations'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список организаций без иерархии по типу структурной единицы (для выпадающего списка)
     */
    static getAllByUnitType(
        params: {
            /**  */
            type: StructuralUnitType
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/organizations/by-unit-type'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { type: params['type'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список элементов c нижестоящим типом по ID родителя
     */
    static getAllOfChildTypeElemntsByParentId(
        params: {
            /**  */
            parentId?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/organizations/organizations-by-parent-type'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { parentId: params['parentId'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class PassingFormsService {
    /**
     * Начать или продолжить прохождение
     */
    static startPassing(
        params: {
            /** requestBody */
            body?: StartPassingContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PassingFormContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/passing-forms/start-passing'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Установить текущее время для поля "Время начала опроса" формы прохождения опросного листа
     */
    static setPassingStartTime(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/passing-forms/appointment/{appointmentId}/set-start-time'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Пройти тест заного
     */
    static retry(
        params: {
            /** requestBody */
            body?: StartPassingContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PassingFormContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/passing-forms/retry'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить инфо о прохождении
     */
    static getPassingInfo(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PassingFormContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/passing-forms/appointment/{appointmentId}/info'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Установить ответ на вопрос
     */
    static setAnswer(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: PassingFormQuestionAnswerCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/passing-forms/{id}/answer'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Установить ответ на несколько вопрсов
     */
    static setAnswers(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: PassingFormQuestionAnswerCreateContract[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/passing-forms/{id}/answers'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Прервать прохождение
     */
    static abort(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: AbortPassingContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/passing-forms/{id}/abort'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Завершить прохождение
     */
    static done(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: StopPassingContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PassingFormFinalWindowInfoContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/passing-forms/{id}/done'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить комментарий
     */
    static saveComment(
        params: {
            /** requestBody */
            body?: SavePassingFormCommentContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/passing-forms/save-comment'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class PositionProfileAgreementService {
    /**
     * Получить лист согласования
     */
    static getAgreementList(
        params: {
            /** ИД листа согласования */
            agreementListId: string
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AgreementListDetailContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/agreements/{agreementListId}'
            url = url.replace(
                '{agreementListId}',
                params['agreementListId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать лист согласования
     */
    static create(
        params: {
            /** requestBody */
            body?: CreateAgreementListContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CreateAgreementListResultContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/agreements'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class PositionProfileAgreementTemplateService {
    /**
     * Получить шаблон листа согласования
     */
    static getTemplateDetail(
        params: {
            /**  */
            templateId: string
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TemplateDetailContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/agreements/templates/{templateId}'
            url = url.replace('{templateId}', params['templateId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список шаблонов листа согласования
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TemplateSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TemplateContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/position-profiles/agreements/templates/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать шаблон листа согласования
     */
    static createTemplate(
        params: {
            /** requestBody */
            body?: CreateTemplateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<CreateTemplateResultContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/agreements/templates'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class PositionProfileArchivesService {
    /**
     * Получить профиль по идентификатору
     */
    static get(
        params: {
            /**  */
            id: number
            /**  */
            version: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/{id}/archives/{version}'
            url = url.replace('{id}', params['id'] + '')
            url = url.replace('{version}', params['version'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список версии профиля должности по Id
     */
    static getVersions(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileVersionContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/{id}/versions'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class PositionProfileDictionariesService {
    /**
     * Парсинг файла xls с профилями должностей
     */
    static parseFile(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/parse-file'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список подчиненности
     */
    static getAllSubordinations(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/subordinations'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список уровней
     */
    static getAllLevels(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/levels'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список коррупционных рисков
     */
    static getAllCorruptionRisks(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/corruption-risks'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список рисков увольнения
     */
    static getAllDismissalRisks(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/dismissal-risks'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список форм допуска
     */
    static getAllAccessForms(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/access-forms'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список отраслей
     */
    static getAllIndustries(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/industries'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список стажей
     */
    static getAllExperiences(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/experiences'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список управленческих стажей
     */
    static getAllManageExperiences(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/manage-experiences'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить варинаты для количества подчиненных
     */
    static getAllSubordinatesNumbers(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/subordinates-numbers'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список уровней образования
     */
    static getAllEducationLevels(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/education-levels'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список профилей образования
     */
    static getAllEducationProfiles(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/education-profiles'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список стажей ГГС
     */
    static getAllGovernmentExperiences(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/position-profiles/dictionaries/government-experiences'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список классов функций
     */
    static getAllFunctionClasses(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/dictionaries/function-classes'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список компетенций по умолчанию
     */
    static getDefaultCompetenceGroups(
        options: IRequestOptions = {}
    ): Promise<PositionProfileCompetenceContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/position-profiles/dictionaries/default-competence-groups'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class PositionProfilesService {
    /**
     * Получить список профилей с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: PositionProfilesSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список профилей для модального окна с учетом поиска
     */
    static searchForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: PositionProfilesSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileSearchForModalResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/position-profiles/search-for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/position-profiles/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить профиль по идентификатору
     */
    static get(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить профиль должности
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить профиль по идентификатору должности и структурной единицы
     */
    static getByUnitPosition(
        params: {
            /**  */
            unitId: number
            /**  */
            positionId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/{unitId}/{positionId}'
            url = url.replace('{unitId}', params['unitId'] + '')
            url = url.replace('{positionId}', params['positionId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить профиль по идентификатору для кнопки компетенций из ПД
     */
    static getByUnitPositionNoPermissions(
        params: {
            /**  */
            unitId: number
            /**  */
            positionId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileForDropdownContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/position-profiles/get-by-position-no-permissions/{unitId}/{positionId}'
            url = url.replace('{unitId}', params['unitId'] + '')
            url = url.replace('{positionId}', params['positionId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранение профиля
     */
    static save(
        params: {
            /** requestBody */
            body?: PositionProfileSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Публикация профиля
     */
    static publish(
        params: {
            /** requestBody */
            body?: PositionProfilePublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Публикация профилей при импорте
     */
    static saveMany(
        params: {
            /** requestBody */
            body?: PositionProfileSaveContract[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/save-many'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Пустить в работу
     */
    static inWork(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/in-work/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Парсинг файла xls с профилями должностей
     */
    static parseFile(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PositionProfileFromFileContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/parse-file'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class PositionsService {
    /**
     * Получить список должностей
     */
    static getAll(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/positions'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск должностей
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: PositionSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/positions/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить должности по идентификаторам
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/positions/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class ProductCardsService {
    /**
     * Получить список карточек с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: ProductCardSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить активную карточку продукта по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить карточку продукта
     */
    static setAsArchive(
        params: {
            /** Идентификатор карточки продукта */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить активную карточку продукта для редактирования
     */
    static getForEdit(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/{id}/edit'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Провести валидацию
     */
    static validate(
        params: {
            /** requestBody */
            body?: ProductCardPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardValidationResultContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/validate'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать карточку продукта
     */
    static publish(
        params: {
            /** requestBody */
            body?: ProductCardPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить карточку продукта
     */
    static save(
        params: {
            /** requestBody */
            body?: ProductCardSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить изображение
     */
    static upload(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/pictures'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить информацию о количестве слушателей записанных на период обучения, записывался ли слушатель ранее на период по идентификатору карточки продукта
     */
    static getProductCardPeriodsListenerInfo(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardStudyingPeriodListenerInfoContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/product-cards/{productCardId}/studying-periods-listener-info'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список слушателей записавшихся на период по идентификатору карточки продукта
     */
    static getProductCardListeners(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardStudyingPeriodContract[]> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/product-cards/{productCardId}/studying-periods-listeners'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список слушателей записавшихся в резерв по идентификатору карточки продукта
     */
    static getProductCardReserveEnrollments(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardReserveEnrollmentListenerContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/{productCardId}/reserve-enrollments'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Записаться на период обучения по идентификатору периода карточки продукта
     */
    static enrollListenerInProductCardStudyingPeriod(
        params: {
            /**  */
            productCardStudyingPeriodId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardStudyingPeriodListenerInfoContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/product-cards/studying-periods/{productCardStudyingPeriodId}/enroll'
            url = url.replace(
                '{productCardStudyingPeriodId}',
                params['productCardStudyingPeriodId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Записаться в резерв на период обучения по идентификатору карточки продукта
     */
    static enrollListenerInProductCardReserve(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/{productCardId}/enroll-reserve'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Обновить список слушателей записавшихся на период
     */
    static updateProductCardStudyingPeriodListeners(
        params: {
            /**  */
            productCardStudyingPeriodId: number
            /** requestBody */
            body?: ProductCardListenerUpdateContract[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/product-cards/studying-periods/{productCardStudyingPeriodId}/update-listeners'
            url = url.replace(
                '{productCardStudyingPeriodId}',
                params['productCardStudyingPeriodId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Проверить записан ли текущий пользователь как слушатель на продукт
     */
    static isCurrentUserEnrolledAsListenerInProduct(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/product-cards/{productCardId}/is-current-user-enrolled'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Подтвердить список слушателей потока
     */
    static confirmListenersInProductStudyingPeriod(
        params: {
            /**  */
            productCardStudyingPeriodId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/product-cards/studying-periods/{productCardStudyingPeriodId}/confirm-listeners'
            url = url.replace(
                '{productCardStudyingPeriodId}',
                params['productCardStudyingPeriodId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отменить сессию редактирования карточки продукта
     */
    static editSessionCancel(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/product-cards/{id}/edit-session-cancel'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Проверяет в карточке продукта имеется назначение для текущего пользователя
     */
    static productCardHasAutoAppointmentForCurrentUser(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/product-cards/{productCardId}/has-auto-appointment-for-current-user'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Назначить трек/мероприятие для текущего пользователя из карточки продукта
     */
    static autoAppointmentForCurrentUser(
        params: {
            /**  */
            productCardId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ProductCardAutoAppointmentContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/product-cards/{productCardId}/auto-appointment-for-current-user'
            url = url.replace('{productCardId}', params['productCardId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class QuestionnaireDictionariesService {
    /**
     * Получить список типов тестов
     */
    static getTestTypes(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/dictionaries/test-types'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список типов респондентов
     */
    static getRespondentTypes(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/dictionaries/respondent-types'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список способов расчета результата
     */
    static getResultCalculatingMethods(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/dictionaries/calculating-methods'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class QuestionnairesService {
    /**
     * Получить список опросных листов с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: QuestionnaireSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить прикрепленный к опросному листу файл
     */
    static deleteAttachment(
        params: {
            /**  */
            questionnaireId: number
            /**  */
            attachmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/questionnaires/{questionnaireId}/attachment/{attachmentId}'
            url = url.replace(
                '{questionnaireId}',
                params['questionnaireId'] + ''
            )
            url = url.replace('{attachmentId}', params['attachmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список опросных листов в зависимости от типа
     */
    static getForSelect(
        params: {
            /** requestBody */
            body?: QuestionnaireSelectSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/for-select'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список опросных листов для модального окна
     */
    static getForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: QuestionnaireSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireForModalContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список опросных листов по идентификаторам
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить опросный лист по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить опросный лист
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить информацию для опросного лист по идентификатору
     */
    static getShortInfoById(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireShortInfoContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/short-info/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создание разрешения на редактирования записи для текущего пользователя
     */
    static getForEdit(
        params: {
            /** Идентификатор опросного листа */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/{id}/edit'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список опросных листов по идентификатору компетенции
     */
    static getByCompetenceId(
        params: {
            /** Идентификатор компетенции */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/competence/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить опросный лист
     */
    static save(
        params: {
            /** requestBody */
            body?: QuestionnaireSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать опросный лист
     */
    static publish(
        params: {
            /** requestBody */
            body?: QuestionnairePublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuestionnaireContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить приложение вопроса
     */
    static uploadPicture(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<AttachmentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/questions/attachments'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отменить сессию редактирования опросного листа
     */
    static editSessionCancel(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/questionnaires/{id}/edit-session-cancel'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class QuizCheckingService {
    /**
     * Получить список опросов на проверку
     */
    static search(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: QuizCheckRegistrySearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuizCheckRegistryContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz/checking/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список опросов на проверку
     */
    static searchText(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuizCheckRegistryContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz/checking/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить опрос на проверку
     */
    static check(
        params: {
            /** Идентификатор прохождения опроса */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuizCheckContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz/checking/{id}/check'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить состояние комментария
     */
    static save(
        params: {
            /** requestBody */
            body?: QuizCheckSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz/checking/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Подтвердить опрос
     */
    static confirm(
        params: {
            /** Идентификатор прохождения опроса */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz/checking/{id}/confirm'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Подтвердить список опросов
     */
    static confirmMany(
        params: {
            /** requestBody */
            body?: QuizCheckConfirmContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz/checking/confirm'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class QuizPortfoliosService {
    /**
     * Получить список портфелей опросов с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: QuizPortfolioSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuizPortfolioSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-portfolios/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuizPortfolioSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-portfolios/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить портфель опросов по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuizPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-portfolios/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить портфель опросов
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-portfolios/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить портфель опросов для редактирования
     */
    static getForEdit(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuizPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-portfolios/{id}/edit'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить портфели опросов для селекта с возможностью фильтрации по типу
     */
    static getForSelect(
        params: {
            /** requestBody */
            body?: QuizPortfolioSelectSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-portfolios/for-select'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить черновик портфеля опросов
     */
    static save(
        params: {
            /** requestBody */
            body?: QuizPortfolioSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuizPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-portfolios/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать портфель опросов
     */
    static publish(
        params: {
            /** requestBody */
            body?: QuizPortfolioPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<QuizPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-portfolios/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class QuizReportsService {
    /**
     * Получить отчёт по опросу в формате xlsx
     */
    static getXlsxReport(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-reports/appointment/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить отчёт по опросу по назначению трека в формате xlsx
     */
    static getXlsxReportByTrack(
        params: {
            /**  */
            appointmentTrackId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/quiz-reports/appointmentTrack/{appointmentTrackId}'
            url = url.replace(
                '{appointmentTrackId}',
                params['appointmentTrackId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class RecommendationsService {
    /**
     * Получить список рекомендаций.
     */
    static getAsync(
        options: IRequestOptions = {}
    ): Promise<RecommendationContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/recommendations'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class RrwebStorageService {
    /**
     * Инициализировать сессию
     */
    static initSession(
        params: {
            /**  */
            recordEntityType: RecordEntityType
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<SessionInfoContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/rrweb-storages/init/{recordEntityType}'
            url = url.replace(
                '{recordEntityType}',
                params['recordEntityType'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить события сессии
     */
    static saveEvent(
        params: {
            /**  */
            sessionId: string
            /** requestBody */
            body?: RrwebEventCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/rrweb-storages/{sessionId}'
            url = url.replace('{sessionId}', params['sessionId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить все события сессии
     */
    static getSessionEvents(
        params: {
            /**  */
            sessionId: string
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<string[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/rrweb-storages/{sessionId}'
            url = url.replace('{sessionId}', params['sessionId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи фильтров
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SessionSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<SessionInfoContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/rrweb-storages/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поисчка
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<SessionInfoContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/rrweb-storages/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class ScalesService {
    /**
     * Получить список всех шкал критериев
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: ScaleSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ScaleContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/scales/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ScaleContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/scales/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список всех шкал критериев для селекта
     */
    static getForSelect(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/scales/for-select'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить шкалы со значениями по ид
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ScaleByIdsContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/scales/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить активную шкалу по идентификатору
     */
    static getActive(
        params: {
            /** Идентификатор шкалы */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ScaleContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/scales/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить шкалу
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/scales/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить шкалы как словарь
     */
    static getDictionaryByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/scales/dictionary-by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранение шкалы
     */
    static save(
        params: {
            /** requestBody */
            body?: ScaleCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ScaleContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/scales/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Публикация шкалы
     */
    static publish(
        params: {
            /** requestBody */
            body?: ScaleCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ScaleContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/scales/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class ScormService {
    /**
     * Инициализация курса
     */
    static initialize(
        params: {
            /** requestBody */
            body?: ScormOperationBaseContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/scorm/initialize'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Выход из курса
     */
    static terminate(
        params: {
            /** requestBody */
            body?: ScormOperationBaseContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/scorm/terminate'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Установить значение в модели данных SCORM
     */
    static setValue(
        params: {
            /** requestBody */
            body?: ScormSetValueContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/scorm/set-value'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить значение из модели SCORM
     */
    static getValue(
        params: {
            /** requestBody */
            body?: ScormGetValueContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ScormValueContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/scorm/get-value'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class SettingsService {
    /**
     * Вернуть дату для синхронизации с ОИБ второй версии
     */
    static getOibV2IntegrationSyncDate(
        options: IRequestOptions = {}
    ): Promise<OibV2SettingsContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/settings/oib_v2_sync_date'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить настройки приложения
     */
    static getApplicationSettings(
        options: IRequestOptions = {}
    ): Promise<ApplicationSettings> {
        return new Promise((resolve, reject) => {
            let url = '/api/settings/application'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class StaffUnitsService {
    /**
     * Поиск штатных едениц
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: StaffUnitSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<StaffUnitPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/staff-units/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск штатных единиц
     */
    static getByFilter(
        params: {
            /** requestBody */
            body?: StaffUnitFilterContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/staff-units/get-by-filter'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получение структуры штатной единицы для предзаполнения формы профиля должности
     */
    static getStaffUnitStructure(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<StaffUnitStructureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/staff-units/{id}/structure'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class StructuralUnitPositionsService {
    /**
     * Поиск связки подразделения и должности
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: StructuralUnitPositionSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<StructuralUnitPositionPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/structural-unit-positions/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сбросить флаг "Сущность изменена"
     */
    static resetIsChanged(options: IRequestOptions = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-unit-positions/reset-is-changed'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class StructuralUnitsService {
    /**
     * Получить список структурных единиц
     */
    static getAllStructuralUnits(
        options: IRequestOptions = {}
    ): Promise<StructuralUnitContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-units'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список организаций по типам структурных единиц
     */
    static getStructuralUnitsByTypes(
        params: {
            /** requestBody */
            body?: StructuralUnitType[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<StructuralUnitContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-units/by-types'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить идентификаторы ОИВов с привязанными компетенциями
     */
    static getOivIdsWithExistingCompetence(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-units/oiv-with-competencies-ids'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск структурных единиц
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: StructuralUnitsSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<StructuralUnitContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-units/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск структурных единиц
     */
    static flatSearch(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: StructuralUnitsSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/structural-units/search-flat/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить справочник должностей структурных единиц
     */
    static searchUnitsAndPositions(
        params: {
            /** Количество записей на странице */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: StructuralUnitPositionsSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<StructuralUnitPositionContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/structural-units/positions/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список организаций без иерархии по типу структурной единицы (для выпадающего списка)
     */
    static getOrganizationsByUnitType(
        params: {
            /**  */
            type: StructuralUnitType
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-units/organizations'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { type: params['type'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список элементов c нижестоящим типом по ID родителя
     */
    static getAllOfChildTypeElemntsByParentId(
        params: {
            /**  */
            parentId?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-units/organizations-by-parent-type'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { parentId: params['parentId'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список вышестоящих элементов по ID структурной единицы
     */
    static getAllParentElemntsByStructuralUnitId(
        params: {
            /**  */
            unitId?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<StructuralUnitContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-units/parent-elements-by-unit-id'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )
            configs.params = { unitId: params['unitId'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список подразделений в организациях без иерархии (для выпадающего списка)
     */
    static getOrganizationsUnitsFlat(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-units/units-by-organizations-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить плоский список организаций без дочерних организаций по ид
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<StructuralUnitContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/structural-units/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class SubordinationsOtherService {
    /**
     * Получить список подчиненности Другое
     */
    static getAll(
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/subordinations-other'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить подчиненность Другое по идентификатору
     */
    static getById(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/subordinations-other/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Добавить новую подчиненность Другое
     */
    static create(
        params: {
            /** requestBody */
            body?: DictionaryCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/position-profiles/subordinations-other/create'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class SupervisingComplexesService {
    /**
     * Вернет коллекцию всех курирующих комплексов
     */
    static getAll(
        options: IRequestOptions = {}
    ): Promise<SupervisingComplexContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/supervising-complexes'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск курирующих комплексов по параметрам
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SupervisingComplexSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<SupervisingComplexContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/supervising-complexes/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class SystemInformationService {
    /**
     * Информация о системе
     */
    static getVersion(
        options: IRequestOptions = {}
    ): Promise<SystemInformationContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/system-information'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TestPortfoliosService {
    /**
     * Получить портфели тестирования для селектов
     */
    static getAllForSelect(
        params: {
            /** requestBody */
            body?: TestPortfolioSearchForSelectContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/test-portfolios/for-select'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить портфель тестов по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TestPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/test-portfolios/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить портфель тестов
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/test-portfolios/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить портфель тестов по идентификатору
     */
    static getForEdit(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TestPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/test-portfolios/{id}/edit'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список портфелей с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TestPortfolioSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TestPortfolioSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/test-portfolios/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TestPortfolioSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/test-portfolios/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить черновик портфеля тестов
     */
    static save(
        params: {
            /** requestBody */
            body?: TestPortfolioSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TestPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/test-portfolios/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Опубликовать портфель тестов
     */
    static publish(
        params: {
            /** requestBody */
            body?: TestPortfolioPublishContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TestPortfolioContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/test-portfolios/publish'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TestReportsService {
    /**
     * Получить отчёт по тесту в формате xlsx
     */
    static getXlsxReport(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/test-reports/appointments/{appointmentId}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TextDocumentsService {
    /**
     * Добавить новый документ
     */
    static createDocument(
        params: {
            /** requestBody */
            body?: TextDocumentAddContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TextDocumentContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/text-documents'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить количество записей для вкладки документы и задания
     */
    static getDocumentPageEntitiesCount(
        options: IRequestOptions = {}
    ): Promise<DocumentsPageEntitiesCountContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/text-documents'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить документ по идентификаторам
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TextDocumentContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/text-documents/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск по документам
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TextDocumentSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TextDocumentContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/text-documents/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TextDocumentContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/text-documents/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить текстовые документы для модального окна
     */
    static getForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TextDocumentSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TextDocumentForModalContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/text-documents/for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить документ
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/text-documents/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TextDocumentsTemplatesService {
    /**
     * Добавить документ по шаблону "Домашнее задание"
     */
    static addHomework(
        params: {
            /** requestBody */
            body?: TextDocumentHomeworkTemplateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/text-documents/templates/homework'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class ThemesService {
    /**
     * Получить список всех тематик
     */
    static getAll(options: IRequestOptions = {}): Promise<ThemeContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/themes'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать новую тематику
     */
    static create(
        params: {
            /** requestBody */
            body?: ThemeCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ThemeContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/themes'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить тематику по идентификатору
     */
    static getActive(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ThemeContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/themes/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Обновить существующую тематику
     */
    static update(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: ThemeCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ThemeContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/themes/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'put',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить тематику
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/themes/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ThemeContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/themes/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск тем
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: ThemesSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<ThemeContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/themes/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TrackAssistantIllustrationsService {
    /**
     * Получить список иллюстраций
     */
    static search(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
            /** requestBody */
            body?: TrackAssistantIllustrationSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackAssistantIllustrationContactPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/track-assistant-illustrations/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TrackCanvasBackgroundsService {
    /**
     * Получить список фонов холста
     */
    static search(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackCanvasBackgroundContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/track-canvas-backgrounds/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить фон холста трека
     */
    static delete(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: TrackCanvasBackgroundDeleteContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-canvas-backgrounds/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить фон холста.
     */
    static upload(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackCanvasBackgroundContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-canvas-backgrounds/pictures'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TrackCanvasIconsService {
    /**
     * Получить список иконок холста
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TrackCanvasIconSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-canvas-icons/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список альбомов фонов холста иконки
     */
    static searchAlbum(
        params: {
            /** requestBody */
            body?: TrackCanvasIconSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackStageIconBackgroundAlbumContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-canvas-icons/background/album/search'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать альбом фона холста иконки
     */
    static createIconBackgroundAlbum(
        params: {
            /** requestBody */
            body?: TrackStageIconBackgroundAlbumCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-canvas-icons/background/album/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить фон холста иконки
     */
    static uploadBackground(
        params: {
            /**  */
            trackStageStatus: TrackStageStatus
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/track-canvas-icons/background/upload/{trackStageStatus}'
            url = url.replace(
                '{trackStageStatus}',
                params['trackStageStatus'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить иконку трека
     */
    static delete(
        params: {
            /**  */
            id: number
            /** requestBody */
            body?: TrackCanvasIconDeleteContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-canvas-icons/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить иконку холста
     */
    static uploadInProcess(
        params: {
            /**  */
            trackStageStatus: TrackStageStatus
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<PictureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-canvas-icons/upload/{trackStageStatus}'
            url = url.replace(
                '{trackStageStatus}',
                params['trackStageStatus'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TrackCanvasTemplateService {
    /**
     * Получить список шаблонов холста для трека адаптации
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TrackCanvasTemplateSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<DictionaryContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/tracks/canvas/templates/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать шаблон холста
     */
    static create(
        params: {
            /** requestBody */
            body?: TrackCanvasTemplateCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackCanvasTemplateContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/tracks/canvas/templates'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить шаблон холста по ИД
     */
    static getById(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackCanvasTemplateContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/tracks/canvas/templates/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TrackDictionariesService {
    /**
     * Получить список видов трека
     */
    static getTypes(options: IRequestOptions = {}): Promise<EnumContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/tracks/dictionaries/kinds'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TrackDisplayPicturesService {
    /**
     * Загрузить картинку для отображения трека
     */
    static upload(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackDisplayPictureContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-display-pictures/upload'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TrackHeaderIconsService {
    /**
     * Получить список иконок заголовка трэка
     */
    static search(
        params: {
            /** Размер страницы */
            pageSize: number
            /** Номер страницы */
            pageNumber: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackHeaderIconContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-header-icons/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Загрузить иконку заголока трэка
     */
    static upload(
        params: {
            /**  */
            file: any
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackHeaderIconContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-header-icons/pictures'

            const configs: IRequestConfig = getConfigs(
                'post',
                'multipart/form-data',
                url,
                options
            )

            let data = null
            data = new FormData()
            if (params['file']) {
                data.append('file', params['file'] as any)
            }

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить иконку заголока трэка
     */
    static delete(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-header-icons/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TrackPassingsService {
    /**
     * Начать прохождение трека
     */
    static startPassing(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/{appointmentId}/start'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Перезапустить трек
     */
    static restartPassing(
        params: {
            /** Идентификатор назначения */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/{appointmentId}/restart'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить содержание трека
     */
    static getTrackContent(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackStageContentContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/{appointmentId}/content'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить форму прохождения по идентификатору назначения
     */
    static getInfo(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackPassingContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/{appointmentId}/info'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Установить текущую стадию
     */
    static setCurrentStage(
        params: {
            /** requestBody */
            body?: TrackPassingCurrentStageContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/set-current-stage'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить вопросы для прохождения опроса
     */
    static getQuestions(
        params: {
            /**  */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackQuestionnaireContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/{appointmentId}/questions'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Ответить на вопрос опроса
     */
    static setAnswer(
        params: {
            /**  */
            appointmentId: number
            /** requestBody */
            body?: QuestionAnswerCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/{appointmentId}/quiz/answer'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить информацию о этапе прохождения
     */
    static getStagePassingInfo(
        params: {
            /**  */
            appointmentId: number
            /**  */
            stageNumber: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackStageActivityPassingContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/{appointmentId}/stages/{stageNumber}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')
            url = url.replace('{stageNumber}', params['stageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Начать прохождение этапа с мероприятием
     */
    static startStagePassingWithActivity(
        params: {
            /**  */
            appointmentId: number
            /**  */
            stageNumber: number
            /** requestBody */
            body?: TrackStageStartContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackStageActivityPassingContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/track-passings/{appointmentId}/stages/{stageNumber}/start-activity'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')
            url = url.replace('{stageNumber}', params['stageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Запустить этап трека с контентом
     */
    static startStagePassingWithContent(
        params: {
            /**  */
            appointmentId: number
            /**  */
            stageNumber: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackStageContentPassingContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/track-passings/{appointmentId}/stages/{stageNumber}/start-content'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')
            url = url.replace('{stageNumber}', params['stageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Завершить этап трека с контентом (обучающим материалом)
     */
    static finishTrackStageWithContent(
        params: {
            /**  */
            trackStagePassingId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/track-passings/stages/{trackStagePassingId}/finish-content'
            url = url.replace(
                '{trackStagePassingId}',
                params['trackStagePassingId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Завершить этап трека с контентом (обучающим материалом)
     */
    static exitTrackStageWithContent(
        params: {
            /**  */
            trackStagePassingId: number
            /** requestBody */
            body?: TrackStageExitContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/track-passings/stages/{trackStagePassingId}/exit-content'
            url = url.replace(
                '{trackStagePassingId}',
                params['trackStagePassingId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить сообщение инфопомощника трека адаптации/развития в процессе прохождения
     */
    static getTrackAssistantMessage(
        params: {
            /**  */
            appointmentId: number
            /**  */
            trackEvent: TrackEvent
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackPassingAssistantEventContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/track-passings/{appointmentId}/info-assistant/{trackEvent}'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')
            url = url.replace('{trackEvent}', params['trackEvent'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить сообщение инфопомощника этапа трека адаптации/развития в процессе прохождения
     */
    static getTrackStageAssistantMessage(
        params: {
            /** requestBody */
            body?: TrackStageAssistantMessageSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackStagePassingAssistantMessageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/stage-info-assistant'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить прогресс по прохождению трека
     */
    static getProgress(
        params: {
            /** Идентификатор назначения */
            appointmentId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TrackPassingProgressContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/{appointmentId}/progress'
            url = url.replace('{appointmentId}', params['appointmentId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TrackPassingsMguuService {
    /**
     * Создать новое прохождение трека МГУУ
     */
    static start(
        params: {
            /** requestBody */
            body?: MguuTrackPassingStartContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/mguu/start'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить форму прохождения трека
     */
    static info(
        params: {
            /** requestBody */
            body?: MguuTrackPassingSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<MguuTrackPassingContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/mguu/info'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Старт прохождения блока формы прохождения
     */
    static startBlock(
        params: {
            /** Идентификатор блока формы прохождения */
            blockId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/mguu/blocks/start/{blockId}'
            url = url.replace('{blockId}', params['blockId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Добавить попытку
     */
    static addAttempt(
        params: {
            /** Идентификатор блока формы прохождения */
            blockId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/mguu/blocks/add-attempt/{blockId}'
            url = url.replace('{blockId}', params['blockId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Отметить дочерние задания как пройденные, кроме последнего
     */
    static skipToLast(
        params: {
            /** Идентификатор блока */
            blockId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/mguu/blocks/skip/{blockId}'
            url = url.replace('{blockId}', params['blockId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Завершение прохождения блока формы прохождения
     */
    static finishBlock(
        params: {
            /** Идентификатор блока формы прохождения */
            blockId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/mguu/blocks/finish/{blockId}'
            url = url.replace('{blockId}', params['blockId'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить ответ на задание для блока
     */
    static setAnswer(
        params: {
            /** requestBody */
            body?: MguuTrackPassingAnswerWriteContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/mguu/blocks/answer'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить ответ на задание
     */
    static getAnswer(
        params: {
            /** Идентификатор блока */
            blockId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<MguuTrackPassingAnswerReadContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/track-passings/mguu/blocks/{blockId}/answer'
            url = url.replace('{blockId}', params['blockId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class TracksMguuService {
    /**
     * Поиск по реестру
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: TracksMguuSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TracksMguuSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/tracks/mguu/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Поиск в реестре при помощи строки поиска
     */
    static searchText(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: SearchTextContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<TracksMguuSearchResultContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/tracks/mguu/search-text/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class UserProfilesService {
    /**
     * Получить профиль текущего пользователя
     */
    static getCurrentUserProfile(
        options: IRequestOptions = {}
    ): Promise<UserProfileContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/user-profiles/current'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить профиль пользователя по идентификатору
     */
    static getUserById(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserProfileContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/user-profiles/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить цели пользователя
     */
    static getUserDevelopmentGoals(
        params: {
            /**  */
            userStructuralUnitPositionId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserDevelopmentGoalContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/user-profiles/goals/{userStructuralUnitPositionId}'
            url = url.replace(
                '{userStructuralUnitPositionId}',
                params['userStructuralUnitPositionId'] + ''
            )

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить цели пользователя
     */
    static saveUserDevelopmentGoals(
        params: {
            /** requestBody */
            body?: UserDevelopmentGoalSaveContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserDevelopmentGoalContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/user-profiles/goals/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить цели пользователя
     */
    static deleteUserDevelopmentGoal(
        params: {
            /**  */
            userId: number
            /**  */
            goalId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/user-profiles/{userId}/goals/{goalId}'
            url = url.replace('{userId}', params['userId'] + '')
            url = url.replace('{goalId}', params['goalId'] + '')

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить рекомендации пользователя
     */
    static getRecommendations(
        params: {
            /**  */
            userId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserProfileRecommendationContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/user-profiles/{userId}/recommendations'
            url = url.replace('{userId}', params['userId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить избранные рекомендации пользователя
     */
    static getFavoriteRecommendations(
        params: {
            /**  */
            userId: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserProfileFavoriteRecommendationContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/user-profiles/{userId}/favorite-recommendations'
            url = url.replace('{userId}', params['userId'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сохранить избранные рекомендации пользователя
     */
    static saveFavoriteRecommendations(
        params: {
            /** requestBody */
            body?: UserProfileFavoriteRecommentaionUpdateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserProfileFavoriteRecommendationContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/user-profiles/favorite-recommendations/save'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Удалить избранные рекомендации пользователя
     */
    static deleteFavoriteRecommendations(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/user-profiles/favorite-recommendations/delete'

            const configs: IRequestConfig = getConfigs(
                'delete',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class UsersService {
    /**
     * Получение текущего авторизованного пользователя
     */
    static getCurrent(options: IRequestOptions = {}): Promise<UserContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/current'

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список пользователей с пагинацией с учетом поиска
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: UsersSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserRegistryContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/search/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить список пользователей с пагинацией для модального окна
     */
    static getForModal(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: UserModalSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserModalContractPageContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/for-modal/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить информацию о пользователе по идентификатору
     */
    static getUserById(
        params: {
            /**  */
            id: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/{id}'
            url = url.replace('{id}', params['id'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Вернет коллекцию пользователей по их ид
     */
    static getByIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/by-ids'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить пользователей с подгруженной организацией
     */
    static getBySelectedInModalIds(
        params: {
            /** requestBody */
            body?: any | null[]
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserSelectedInModalContract[]> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/get-selected-in-modal'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Создать или обновить настройки пользователя
     */
    static createOrUpdateCurrentUserSettings(
        params: {
            /** requestBody */
            body?: UserSettingsCreateContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<NoContentResult> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/current/settings'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Получить настройки пользователя по ключу
     */
    static getCurrentUserSettings(
        params: {
            /**  */
            key: string
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<UserSettingsContract> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/current/settings/{key}'
            url = url.replace('{key}', params['key'] + '')

            const configs: IRequestConfig = getConfigs(
                'get',
                'application/json',
                url,
                options
            )

            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
    /**
     * Сбросить флаг "Сущность изменена"
     */
    static resetIsChanged(
        params: {
            /**  */
            userId?: number
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let url = '/api/users/reset-is-changed'

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )
            configs.params = { userId: params['userId'] }
            let data = null

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export class YourDevelopmentService {
    /**
     * Получить список назначений
     */
    static search(
        params: {
            /**  */
            pageSize: number
            /**  */
            pageNumber: number
            /** requestBody */
            body?: YourDevelopmentSearchContract
        } = {} as any,
        options: IRequestOptions = {}
    ): Promise<YourDevelopmentPageItemContractPageContract> {
        return new Promise((resolve, reject) => {
            let url =
                '/api/your-development/appointments/{pageSize}/{pageNumber}'
            url = url.replace('{pageSize}', params['pageSize'] + '')
            url = url.replace('{pageNumber}', params['pageNumber'] + '')

            const configs: IRequestConfig = getConfigs(
                'post',
                'application/json',
                url,
                options
            )

            let data = params.body

            configs.data = data
            axios(configs, resolve, reject)
        })
    }
}

export interface OrderRuleContract {
    /** Имя поля сортировки */
    field: string

    /**  */
    direction: SortDirection
}

export interface ActivityModalSearchContract {
    /** Наименование */
    name: string

    /** Идентификаторы тематик */
    themeIds: number[]

    /**  */
    type: ActivityType

    /**  */
    kind: ActivityKind

    /** Идентификаторы организаций */
    structuralUnitIds: number[]

    /** Статусы мероприятия */
    statuses: DocumentStatus[]

    /** Идентификаторы целей развития */
    developmentGoalIds: number[]

    /**  */
    decorationType: ActivityDecorationType

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface ExceptionContract {
    /** Тип ошибки */
    type: string

    /** Сообщения об ошибках */
    messages: string[]
}

export interface ProblemDetails {
    /**  */
    type: string

    /**  */
    title: string

    /**  */
    status: number

    /**  */
    detail: string

    /**  */
    instance: string
}

export interface DictionaryContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string
}

export interface ActivityModalContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Тематики */
    themes: DictionaryContract[]

    /**  */
    type: ActivityType

    /**  */
    kind: ActivityKind

    /**  */
    decorationType: ActivityDecorationType
}

export interface ActivityModalContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: ActivityModalContract[]
}

export interface ActivitiesSearchContract {
    /** Наименование мероприятия */
    name: string

    /** Тематики */
    themeIds: number[]

    /** Дата начала */
    fromDate: string

    /** Дата окончания */
    toDate: string

    /**  */
    decorationType: ActivityDecorationType

    /**  */
    type: ActivityType

    /**  */
    kind: ActivityKind

    /** Типы документа */
    statuses: DocumentStatus[]

    /** Идентификаторы организаций которые владеют мероприятием */
    ownerOrganizationIds: number[]

    /** Дата изменения, начало диапазона */
    changeDateStart: string

    /** Дата изменения, конец диапазона */
    changeDateEnd: string

    /** Показать архивные записи */
    showArchiveActivities: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface ActivitySearchResultContract {
    /** Идентификатор мероприятия */
    id: number

    /** Наименование мероприятия */
    name: string

    /**  */
    type: ActivityType

    /**  */
    kind: ActivityKind

    /**  */
    decorationType: ActivityDecorationType

    /**  */
    ownerOrganization: DictionaryContract

    /** Версия записи */
    version: number

    /** Дата изменения */
    changeDate: Date

    /**  */
    status: DocumentStatus

    /** Состояние записи */
    active: boolean
}

export interface ActivitySearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: ActivitySearchResultContract[]
}

export interface SearchTextContract {
    /** Строка поиска */
    searchText: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface CompetenceGroupBasicContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Порядок */
    order: number

    /** Управленческая группа */
    isManager: boolean
}

export interface CompetenceIndicatorContract {
    /** Идентификатора индикатора */
    id: number

    /** Наименование индикатора */
    name: string

    /** Порядок сортировки */
    order: number
}

export interface CompetenceContract {
    /** Идентификатор компетенции */
    id: number

    /** Наименование компетенции */
    name: string

    /**  */
    competenceGroup: CompetenceGroupBasicContract

    /** Индикаторы */
    indicators: CompetenceIndicatorContract[]

    /** Дата изменения */
    changeDate: Date

    /** Флаг активности записи */
    active: boolean

    /** Номер версии */
    version: number

    /** Количество индикаторов компетенции */
    indicatorsCount: number

    /**  */
    department: DictionaryContract

    /** Тип деятельности */
    functionClass: DictionaryContract[]

    /** Описание */
    description: string

    /**  */
    status: DocumentStatus

    /** Порядок */
    order: number
}

export interface UserStructuralUnitPositionContract {
    /** Идентификатор связи пользователя с подразделениями и должностями */
    id: number

    /**  */
    structuralUnit: DictionaryContract

    /**  */
    position: DictionaryContract

    /** Дата вступления в должность */
    hireDate: Date

    /** Дата увольнения */
    fireDate: Date

    /** Признак основного места работы */
    isMain: boolean
}

export interface UserContract {
    /** Флаг, архивный ли пользователь */
    isArchived: boolean

    /** Роли пользователя */
    roles: DictionaryContract[]

    /** Должности пользователя в подразделениях */
    structuralUnitsAndPositions: UserStructuralUnitPositionContract[]

    /** Дата изменения */
    changeDate: Date

    /** Мобильный телефон */
    mobilePhone: string

    /** Дата рождения */
    birthday: Date

    /** СНИЛС */
    snils: string

    /** Права доступа пользователя */
    permissions: UserPermission[]

    /** Изменялась ли сущность */
    isChanged: boolean

    /** Идентификатор пользователя */
    id: number

    /** Имя */
    firstName: string

    /** Фамилия */
    lastName: string

    /** Отчество */
    patronymic: string

    /** Email */
    email: string
}

export interface DayTime {
    /**  */
    day: DayOfWeek

    /**  */
    hours: number

    /**  */
    minutes: number
}

export interface FaceToFaceSchedule {
    /**  */
    date: Date

    /**  */
    durationHours: number

    /**  */
    durationMinutes: number

    /**  */
    place: string

    /**  */
    additionalInformation: string
}

export interface WebConferenceSchedule {
    /**  */
    date: Date

    /**  */
    durationHours: number

    /**  */
    durationMinutes: number

    /**  */
    link: string
}

export interface LessonScheduleContract {
    /** Расписание по датам. */
    dates: string[]

    /** Расписание по дням. */
    days: DayTime[]

    /**  */
    faceToFaceMeeting: FaceToFaceSchedule

    /**  */
    webConference: WebConferenceSchedule
}

export interface LessonAttachmentContract {
    /** Заголовок */
    name: string

    /** Идентификатор */
    id: number

    /** Имя файла вложения */
    fileName: string

    /** Url файла вложения */
    url: string

    /** Размер файла */
    fileSize: number
}

export interface LessonContract {
    /** Идентификатора урока */
    id: number

    /** Название урока */
    name: string

    /** Баллы за прохождение */
    pointForPassing: number

    /** Добавлять результат урока */
    addLessonResult: boolean

    /** Текст блока информационный */
    informationBlockText: string

    /** Обязательность */
    isRequired: boolean

    /** Номер по порядку в мероприятии */
    orderNumber: number

    /** Описание урока */
    lessonDescription: string

    /** Опросные листы */
    questionnaires: DictionaryContract[]

    /**  */
    textDocument: DictionaryContract

    /** Список Тьюторов */
    tutors: UserContract[]

    /** Список организаторов (только для очных мероприятий) */
    organisers: UserContract[]

    /** Длительность урока */
    courseDuration: number

    /** Условие использовать редактор */
    isEditorUsed: boolean

    /**  */
    mission: DictionaryContract

    /**  */
    type: LessonType

    /** Длительность курса минуты */
    courseDurationMinutes: number

    /** Количество попыток прохождения */
    attemptNumber: number

    /** Место проведения */
    location: string

    /**  */
    schedule: LessonScheduleContract

    /**  */
    course: DictionaryContract

    /**  */
    nestedActivity: DictionaryContract

    /** Вложения урока */
    attachments: LessonAttachmentContract[]
}

export interface PictureContract {
    /** ИД изображения */
    id: number

    /** Url файла */
    url: string
}

export interface TextSettingsBaseContract {
    /** Шрифт заголовка */
    fontName: string

    /** Стиль шрифта */
    fontStyle: string

    /** Размер шрифта */
    fontSize: number

    /** Выравнивание текста */
    textAlign: string

    /** Цвет текста */
    textColor: string

    /** Расстояния между строчками */
    lineHeight: number
}

export interface ActivityCanvasContract {
    /**  */
    canvasDesignType: ActivityCanvasDesignType

    /**  */
    canvasBackground: PictureContract

    /**  */
    titleSettings: TextSettingsBaseContract

    /**  */
    descriptionSettings: TextSettingsBaseContract

    /**  */
    blockNameSettings: TextSettingsBaseContract

    /** Расположение окна */
    windowAlign: string

    /**  */
    blockDescriptionSettings: TextSettingsBaseContract

    /**  */
    listSettings: TextSettingsBaseContract

    /** Цвет блоков до прохождения */
    thematicBlockColorBeforePassing: string

    /** Цвет блоков после прохождения */
    thematicBlockColorAfterPassing: string

    /** Цвет иконки блока */
    thematicBlockIconColor: string

    /** Цвет блока в момент прохождения */
    thematicBlockColorWhilePassing: string

    /** Отображать линии */
    showLines: boolean

    /** Отображать прогресс */
    showProgress: boolean

    /** Расположение кнопки */
    buttonAlign: string

    /** Цвет кнопок */
    buttonColor: string

    /** Цвет текста кнопок */
    buttonTextColor: string
}

export interface ActivityContract {
    /** Идентификатор мероприятия */
    id: number

    /** Наименование мероприятия */
    name: string

    /** Флаг активности мероприятия */
    active: boolean

    /** Код мероприятия */
    code: string

    /** Возможность повторного прохождения */
    isRepeatPassingPossible: boolean

    /** Доступно с */
    availableFrom: Date

    /** Доступно до */
    availableTo: Date

    /**  */
    decorationType: ActivityDecorationType

    /**  */
    ownerOrganization: DictionaryContract

    /** Сертификация */
    isCertification: boolean

    /** Рейтинговое мероприятие */
    isRating: boolean

    /** Версия записи */
    version: number

    /** Анонс мероприятия */
    announcement: string

    /** Отображать в хэдлайнерах */
    displayInHeadliners: boolean

    /** Отображать в рекомендованных */
    displayInRecommended: boolean

    /** Срок размещения в каталоге с какой то даты */
    catalogPlacementFromDate: Date

    /** Срок размещения в каталоге до какой то даты */
    catalogPlacementToDate: Date

    /**  */
    type: ActivityType

    /**  */
    kind: ActivityKind

    /** Список компетенции */
    competences: CompetenceContract[]

    /** Список тематик */
    themes: DictionaryContract[]

    /** Список уроков */
    lessons: LessonContract[]

    /**  */
    lessonAccessType: LessonAccessType

    /**  */
    status: DocumentStatus

    /** Дата изменения */
    changeDate: Date

    /**  */
    activityPicture: PictureContract

    /**  */
    canvas: ActivityCanvasContract
}

export interface LessonCreateContract {
    /** Идентификатор урока */
    id: number

    /** Наименование урока */
    name: string

    /** Баллы за прохождение */
    pointForPassing: number

    /** Обязательность */
    isRequired: boolean

    /** Текст блока информационный */
    informationBlockText: string

    /** Добавлять результат урока */
    addLessonResult: boolean

    /** Номер по порядку в мероприятии */
    orderNumber: number

    /** Описание урока */
    lessonDescription: string

    /** Идентификаторы опросных листов */
    questionnaireIds: number[]

    /** Идентификаторы тьюторов */
    tutorIds: number[]

    /** Идентификаторы ответственных лиц */
    organiserIds: number[]

    /** Идентификаторы текстовых документов */
    textDocumentId: number

    /** Идентификаторы задания */
    missionId: number

    /** Условие использовать редактор */
    isEditorUsed: boolean

    /**  */
    type: LessonType

    /** Длительность курса минуты */
    courseDurationMinutes: number

    /** Длительность урока */
    courseDuration: number

    /** Количество попыток прохождения */
    attemptNumber: number

    /** Место проведения */
    location: string

    /**  */
    schedule: LessonScheduleContract

    /** ИД электронного курса. */
    courseId: number

    /** Идентификатор вложенного мероприятия */
    nestedActivityId: number

    /** Содержит документы для скачивания */
    isContainsAttachments: boolean

    /** Вложения */
    attachments: DictionaryContract[]
}

export interface ActivityCanvasCreateContract {
    /**  */
    canvasDesignType: ActivityCanvasDesignType

    /** Идентификатор холста для мероприятия */
    canvasBackgroundId: number

    /**  */
    titleSettings: TextSettingsBaseContract

    /**  */
    descriptionSettings: TextSettingsBaseContract

    /**  */
    blockNameSettings: TextSettingsBaseContract

    /** Расположение окна */
    windowAlign: string

    /**  */
    blockDescriptionSettings: TextSettingsBaseContract

    /**  */
    listSettings: TextSettingsBaseContract

    /** Цвет блоков до прохождения */
    thematicBlockColorBeforePassing: string

    /** Цвет блоков после прохождения */
    thematicBlockColorAfterPassing: string

    /** Цвет иконки блока */
    thematicBlockIconColor: string

    /** Цвет блока в момент прохождения */
    thematicBlockColorWhilePassing: string

    /** Отображать линии */
    showLines: boolean

    /** Отображать прогресс */
    showProgress: boolean

    /** Расположение кнопки */
    buttonAlign: string

    /** Цвет кнопок */
    buttonColor: string

    /** Цвет текста кнопок */
    buttonTextColor: string
}

export interface ActivityPublishContract {
    /**  */
    type: ActivityType

    /**  */
    kind: ActivityKind

    /** Список компетенции */
    competencesIds: number[]

    /** Список уроков */
    lessons: LessonCreateContract[]

    /** ИД редактируемого мероприятия. Default если идет создание. */
    id: number

    /** Наименование мероприятия */
    name: string

    /** Код мероприятия */
    code: string

    /** Доступно до */
    availableTo: string

    /** Доступно c */
    availableFrom: string

    /** Возможность повторного прохождения */
    isRepeatPassingPossible: boolean

    /**  */
    decorationType: ActivityDecorationType

    /** Идентификатор организации которая владеет мероприятием */
    ownerOrganizationId: number

    /** Сертификация */
    isCertification: boolean

    /** Рейтинговое мероприятие */
    isRating: boolean

    /** Анонс мероприятия */
    announcement: string

    /** Отображать в хэдлайнерах */
    displayInHeadliners: boolean

    /** Отображать в рекомендованных */
    displayInRecommended: boolean

    /** Срок размещения в каталоге с какой то даты */
    catalogPlacementFromDate: string

    /** Срок размещения в каталоге до какой то даты */
    catalogPlacementToDate: string

    /** Список тематик */
    themeIds: number[]

    /**  */
    lessonAccessType: LessonAccessType

    /** ИД изображения мероприятия. */
    activityPictureId: number

    /**  */
    canvas: ActivityCanvasCreateContract
}

export interface PictureContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: PictureContract[]
}

export interface ActivitySaveContract {
    /** ИД редактируемого мероприятия. Default если идет создание. */
    id: number

    /** Наименование мероприятия */
    name: string

    /** Код мероприятия */
    code: string

    /** Доступно до */
    availableTo: string

    /** Доступно c */
    availableFrom: string

    /** Возможность повторного прохождения */
    isRepeatPassingPossible: boolean

    /**  */
    decorationType: ActivityDecorationType

    /** Идентификатор организации которая владеет мероприятием */
    ownerOrganizationId: number

    /** Сертификация */
    isCertification: boolean

    /** Рейтинговое мероприятие */
    isRating: boolean

    /** Анонс мероприятия */
    announcement: string

    /** Отображать в хэдлайнерах */
    displayInHeadliners: boolean

    /** Отображать в рекомендованных */
    displayInRecommended: boolean

    /** Срок размещения в каталоге с какой то даты */
    catalogPlacementFromDate: string

    /** Срок размещения в каталоге до какой то даты */
    catalogPlacementToDate: string

    /**  */
    type: ActivityType

    /**  */
    kind: ActivityKind

    /** Список компетенции */
    competencesIds: number[]

    /** Список тематик */
    themeIds: number[]

    /** Список уроков */
    lessons: LessonCreateContract[]

    /**  */
    lessonAccessType: LessonAccessType

    /** ИД изображения мероприятия. */
    activityPictureId: number

    /**  */
    canvas: ActivityCanvasCreateContract
}

export interface AttachmentContract {
    /** Идентификатор */
    id: number

    /** Имя файла вложения */
    fileName: string

    /** Url файла вложения */
    url: string

    /** Размер файла */
    fileSize: number
}

export interface ActivityLessonPassingSearchContract {
    /** Ид урока */
    lessonId: number

    /** Ид прохождения мероприятия */
    activityPassingId: number
}

export interface CourseContract {
    /** Идентификатор курса */
    id: number

    /** Наименование курса */
    name: string

    /** Наименование файла курса */
    fileName: string

    /**  */
    format: CourseFormat

    /** Описание формата курса */
    formatDescription: string

    /** Дата загрузки */
    uploadDate: Date

    /** URL запуска курса */
    launchUrl: string

    /** Флаг активности записи */
    active: boolean

    /** Ключ */
    key: string

    /** Комментарий */
    comment: string

    /**  */
    status: DocumentStatus

    /** Версия записи */
    version: number

    /**  */
    structuralUnit: DictionaryContract

    /**  */
    author: DictionaryContract

    /** Организация */
    organization: string

    /** Электронный адрес */
    email: string

    /** Номер телефона */
    phoneNumber: string
}

export interface QuestionChooseVariantAnswerPublicContract {
    /** Ид варианта ответа */
    id: number

    /** Порядковый номер ответа */
    number: number

    /** Текст ответа */
    text: string

    /**  */
    attachment: AttachmentContract
}

export interface QuestionChooseVariantPublicContract {
    /** Список ответов */
    answers: QuestionChooseVariantAnswerPublicContract[]

    /** Установить ограничение на количество вариантов ответа */
    isAnswersCountLimited: boolean

    /** Максимальное количество вариантов ответа */
    limitAnswersCount: number

    /** Доступное количество ответов (один\/несколько) */
    multiChoose: boolean

    /** Отображать варианты ответов в произвольном порядке */
    randomAnswerOrder: boolean

    /** Предоставить пользователю возможность добавить свой вариант ответа */
    customAnswer: boolean
}

export interface IndicatorContract {
    /** Идентификатора индикатора */
    id: number

    /** Наименование индикатора */
    name: string

    /**  */
    indicatorType: DictionaryContract

    /** Классы функций */
    functionClasses: DictionaryContract[]

    /** Версия записи */
    version: number

    /** Описание индикатора */
    description: string

    /**  */
    status: DocumentStatus

    /** Дата изменения */
    changeDate: Date

    /** Флаг активности записи */
    active: boolean
}

export interface ScaleValueContract {
    /** Ид */
    id: number

    /** Отображаемое значение (0, 1, 2) */
    displayValue: number

    /** Текстовое описание */
    description: string
}

export interface ScaleContract {
    /** Ид */
    id: number

    /** Название шкалы критериев */
    name: string

    /** Описание шкалы критериев */
    description: string

    /** Флаг Семантический */
    isSemantic: boolean

    /**  */
    status: DocumentStatus

    /** Значения шкалы */
    scaleValues: ScaleValueContract[]

    /** Дата изменения */
    changeDate: Date

    /** Активность шкалы */
    active: boolean
}

export interface QuestionCriteriaScaleContract {
    /** Ид шкалы ответов */
    scaleId: number

    /** Этот вопрос инверсионный */
    isInverseQuestion: boolean

    /** Номер парного прямого вопроса */
    linkedQuestionNumber: number

    /**  */
    scale: ScaleContract
}

export interface QuestionPublicContract {
    /**  */
    chooseVariant: QuestionChooseVariantPublicContract

    /**  */
    questionnaire: DictionaryContract

    /** Порядок опросного листа в портфеле теста */
    testPortfolioQuestionnaireOrder: number

    /** Ид вопроса */
    id: number

    /** Порядковый номер вопроса */
    number: number

    /** Порядковый номер блокирующего вопроса */
    blockedByQuestionNumber: number

    /** Идентификатор блокирующего вопроса */
    blockedByQuestionId: number

    /**  */
    type: QuestionType

    /** Обязательный вопрос */
    mandatoryQuestion: boolean

    /** Установлено ограничение на ответ */
    limitedAnswerTime: boolean

    /** Время на ответ в минутах */
    answerLimit: number

    /** Заголовок вопроса */
    title: string

    /** Текст вопроса */
    text: string

    /**  */
    indicator: IndicatorContract

    /** Коллекция присоединенных файлов */
    attachments: AttachmentContract[]

    /**  */
    criteriaScale: QuestionCriteriaScaleContract
}

export interface NestedActivityPassingContract {
    /** Название мероприятия */
    name: string

    /** Ид связанной активности */
    activityId: number

    /** Анонс */
    announcement: string

    /** Время прохождения всех тематических блоков */
    allCoursesDuration: number

    /**  */
    type: ActivityType

    /**  */
    kind: ActivityKind

    /**  */
    lessonAccessType: LessonAccessType

    /**  */
    activityPicture: PictureContract

    /**  */
    status: ActivityPassingStatus

    /** Ид пользователя */
    userId: number

    /** Прохождения тематическаих блоков */
    activityLessonPassings: ActivityLessonPassingContract[]

    /**  */
    canvas: ActivityCanvasContract
}

export interface MissionTaskPassingContract {
    /** Идентификатор */
    id: number

    /** Номер задачи */
    taskNumber: number

    /** Описание задачи */
    taskText: string

    /** Комментарий тьютора */
    comment: string

    /** Ответ на задачу */
    answerText: string

    /**  */
    status: HomeworkStatusType

    /**  */
    attachment: AttachmentContract

    /** Дата комментария тьютора */
    commentDate: Date

    /** Дата ответа на задачу */
    answerDate: Date
}

export interface ActivityLessonPassingHomeworkContract {
    /** Идентификатор прохождения домашнего задания */
    id: number

    /** Идентификатор рабочей копии документа */
    ldeId: string

    /** Идентификатор юзера для тьютора */
    tutorId: number

    /** Наименование задания */
    name: string

    /** Описание задания */
    content: string

    /**  */
    status: HomeworkStatusType

    /** Задачи для задания */
    taskPassings: MissionTaskPassingContract[]

    /** Идентификатор задания */
    missionId: number

    /** Условие использования редактора */
    isEditorUsed: boolean

    /** Признак, отключена ли проверка орфографии */
    isDisableSpellCheck: boolean

    /** Видны ли заголовки подзадач */
    isTasksTitlesVisible: boolean
}

export interface ActivityLessonPassingContract {
    /** Ид прохождения тематического блока мероприятия */
    id: number

    /** Ид тематического блока */
    lessonId: number

    /** Название тематического блока */
    name: string

    /** Обязательность */
    isRequired: boolean

    /** Текст блока информационный */
    informationBlockText: string

    /** Описание урока */
    lessonDescription: string

    /**  */
    status: ActivityPassingStatus

    /** Номер по порядку в мероприятии */
    orderNumber: number

    /**  */
    quizType: QuizType

    /**  */
    questionnaireType: QuestionnaireType

    /** Наименование шаблона опросного листа */
    questionnaireName: string

    /** Длительность урока часы */
    courseDuration: number

    /** Длительность курса минуты */
    courseDurationMinutes: number

    /**  */
    course: CourseContract

    /**  */
    type: LessonType

    /** Коллекция вопросов */
    questions: QuestionPublicContract[]

    /** Количество попыток прохождения */
    attemptNumber: number

    /** Количество сделанных попыток прохождения */
    attemptCount: number

    /**  */
    nestedActivityPassing: NestedActivityPassingContract

    /** Ид основного прохождения мероприятия - мероприятие с которого началось прохождение */
    mainActivityPassingId: number

    /**  */
    homework: ActivityLessonPassingHomeworkContract

    /** Номер текущего вопроса */
    currentQuestionNumber: number

    /** Прогресс прохождения (доля от 0 до 1.0 для PDF, в секундах для видео) */
    progress: number

    /**  */
    schedule: LessonScheduleContract

    /** Содержит документы для скачивания */
    isContainsAttachments: boolean
}

export interface HomeworkTaskAnswerContract {
    /** Идентификатор домашнего задания */
    homeworkId: number

    /** Идентификатор задания */
    taskId: number

    /** Ответ */
    answer: string

    /** Автоматическая проверка дз без тьютора */
    isHomeworkAutoCheck: boolean

    /** Идентификатор прикрепленного файла */
    attachmentId: number
}

export interface ActivityLessonPassingExitContract {
    /** Прогресс выполнения (доля от 0 до 1.0 для PDF, в секундах для видео) */
    progress: number
}

export interface PassingFormQuestionAnswerChooseVariantContract {
    /** Номера выбранных вариантов ответов */
    selectedAnswerIds: number[]

    /** Свой вариант ответа */
    customAnswer: boolean

    /** Текст своего ответа */
    text: string

    /** Комментарий */
    comment: string

    /** Идентификаторы правильных ответов */
    correctAnswerIds: number[]

    /**  */
    result: AnswerConclusion
}

export interface PassingFormQuestionAnswerScaleContract {
    /** Идентификатор шкалы критериев */
    scaleValueId: number

    /** Комментарий */
    comment: string
}

export interface PassingFormQuestionAnswerTextContract {
    /** Текстовый ответ */
    text: string
}

export interface PassingFormQuestionAnswerMissingTextBlockContract {
    /** Номер блока по порядку */
    blockNumber: number

    /** Текст блока */
    text: string

    /** Правильный ли ответ */
    isCorrect: boolean
}

export interface PassingFormQuestionAnswerMissingTextContract {
    /** Пропущенные слова */
    missingTextBlocks: PassingFormQuestionAnswerMissingTextBlockContract[]
}

export interface QuestionAnswerCreateContract {
    /** Идентификатор вопроса */
    questionId: number

    /** Остаток времени прохождения */
    timeLeft: number

    /**  */
    chooseAnswerParameters: PassingFormQuestionAnswerChooseVariantContract

    /**  */
    scaleAnswerParameters: PassingFormQuestionAnswerScaleContract

    /**  */
    textAnswerParameters: PassingFormQuestionAnswerTextContract

    /**  */
    missingTextAnswerParameters: PassingFormQuestionAnswerMissingTextContract
}

export interface ActivityPassingInfoContract {
    /** Ид мероприятия */
    activityId: number

    /** Идентификатор назначения */
    appointmentActivityId: number

    /** Ид прохождения этапа трека */
    trackStagePassingId: number
}

export interface RatingInfoContract {
    /** Общее колличество баллов */
    totalPoints: number

    /** Баллы за пройденные тематические блоки */
    accuredPoints: number
}

export interface ActivityPassingContract {
    /** Ид прохождения мероприятия */
    id: number

    /** Название мероприятия */
    name: string

    /** Ид связанной активности */
    activityId: number

    /** Анонс */
    announcement: string

    /** Время прохождения всех тематических блоков */
    allCoursesDuration: number

    /** Автоматическая проверка дз без тьютора */
    isHomeworkAutoCheck: boolean

    /**  */
    type: ActivityType

    /**  */
    kind: ActivityKind

    /**  */
    lessonAccessType: LessonAccessType

    /**  */
    activityPicture: PictureContract

    /**  */
    status: ActivityPassingStatus

    /** Ид пользователя */
    userId: number

    /** Прохождения тематическаих блоков */
    activityLessonPassings: ActivityLessonPassingContract[]

    /**  */
    canvas: ActivityCanvasContract

    /**  */
    ratingInfo: RatingInfoContract
}

export interface ActivityPassingExistContract {
    /** Существует ли форма прохождения мероприятия */
    isExist: boolean
}

export interface ActivityPassingLessonAttachmentContract {
    /** Идентификатор вложения */
    id: number

    /** Заголовок вложения */
    name: string

    /** Тип контент */
    contentType: string
}

export interface TrackStageAssistantEventContract {
    /**  */
    event: TrackStageEvent

    /** Текст сообщения */
    message: string
}

export interface AdaptationTrackStageContract {
    /** Идентификатор этапа */
    id: number

    /** Заголовок этапа */
    title: string

    /** Номер этапа трека адаптации */
    stageNumber: number

    /** Обязательность прохождения */
    isPassingRequired: boolean

    /** Отображать прогресс прохождения этапа */
    showProgress: boolean

    /** События помощника */
    assistantEvents: TrackStageAssistantEventContract[]

    /** Отображать иконку в содержании */
    showContentPicture: boolean

    /**  */
    contentIcon: AttachmentContract

    /**  */
    course: DictionaryContract

    /** Ограничение количество попыток прохождения мероприятия */
    activityAttemptCount: number

    /** Ограничено ли количество попыток прохождения мероприятия */
    isActivityAttemptCountLimited: boolean

    /**  */
    activity: DictionaryContract

    /** Общее количество тематических блоков */
    totalThematicBlocksCount: number

    /**  */
    materialType: TrackStageMaterialType
}

export interface TrackPictureContract {
    /** ИД изображения мероприятия. */
    id: number

    /** Наименование */
    name: string

    /** url к файлу вложения */
    url: string
}

export interface TrackAssistantEventContract {
    /**  */
    event: TrackEvent

    /** Текст сообщения */
    message: string
}

export interface TrackAssistantContract {
    /** Наименование */
    name: string

    /**  */
    illustration: TrackPictureContract

    /** События */
    events: TrackAssistantEventContract[]
}

export interface CourseAdditionalsContract {
    /** Идентификатор курса */
    id: number

    /**  */
    format: CourseFormat

    /** Ссылка на просмотр */
    launchUrl: string
}

export interface TrackAdditionalContract {
    /** Идентификатор дополнительных материалов */
    id: number

    /** Наименование доп. материала */
    name: string

    /**  */
    course: CourseAdditionalsContract

    /**  */
    structuralUnit: DictionaryContract

    /**  */
    additionalsType: AdditionalsType

    /**  */
    availability: AdditionalsAvailability
}

export interface TrackCanvasBackgroundContract {
    /** ИД изображения. */
    id: number

    /** Url файла вложения. */
    url: string

    /** Url превью файла вложения. */
    previewUrl: string
}

export interface TrackStageIconContract {
    /** Тень */
    haveShadow: string

    /** Цвет тени */
    shadowColor: string

    /** Время пульсации */
    effectTime: number

    /** Пульсация запуск */
    effectStart: string

    /** Пульсация повторение */
    effectRepeat: boolean

    /** Пульсация продолжительность */
    effectDuration: string

    /** Ширина иконки */
    width: number

    /** Высота иконки */
    height: number

    /** Скругление иконки */
    iconRounding: number

    /** ИД иконки */
    iconId: number

    /** Url иконки */
    url: string

    /**  */
    iconBackground: AttachmentContract
}

export interface TrackStageIconsContract {
    /**  */
    beforeStart: TrackStageIconContract

    /**  */
    inProcess: TrackStageIconContract

    /**  */
    passing: TrackStageIconContract
}

export interface TrackStageStylesContract {
    /** Номер этапа трека адаптации */
    stageNumber: number

    /** Стили позиции */
    positionStyles: string

    /**  */
    icons: TrackStageIconsContract
}

export interface TrackCanvasContract {
    /** ИД */
    id: number

    /** Разрешение для адаптива */
    screenResolution: number

    /**  */
    trackCanvasBackground: TrackCanvasBackgroundContract

    /** Высота */
    height: number

    /** Ширина */
    width: number

    /** Стили */
    trackStageStyles: TrackStageStylesContract[]
}

export interface TrackProgressVisualizationContract {
    /** Переключатель отображения меню Прогресса */
    isProgressVisible: boolean

    /** Переключатель отображения пройденых тематических блоков */
    isLessonsVisible: boolean

    /** Переключатель отображения пройденых домашних заданий */
    isHomeworksVisible: boolean

    /** Переключатель отображения пройденых вебинаров */
    isWebinarsVisible: boolean

    /** Переключатель отображения рейтинга */
    isRatingVisible: boolean

    /**  */
    progressTextSettings: TextSettingsBaseContract

    /** Цвет фона */
    backgroundColor: string
}

export interface TrackMenuVisualizationContract {
    /** Переключатель отображения меню */
    isMenuVisible: boolean

    /** Переключатель отображения содержания */
    isContentsVisible: boolean

    /** Переключатель отображения дополнительных материалов */
    isAdditionalMaterialsVisible: boolean

    /** Переключатель отображения домашних заданий */
    isHomeWorksVisible: boolean

    /**  */
    menuTextSettings: TextSettingsBaseContract

    /** Цвет фона */
    backgroundColor: string
}

export interface TrackHeaderIconContract {
    /** ИД изображения */
    id: number

    /** Url файла вложения */
    url: string

    /** Ссылка для перехода */
    link: string

    /** Порядок отображения */
    order: number
}

export interface AdaptationTrackContract {
    /** Этапы трека */
    stages: AdaptationTrackStageContract[]

    /** Автоматическое назначение трека */
    automaticAppointmentAvailable: boolean

    /** Доступность трека (дней) при автоматическом назначении */
    automaticAppointmentDuration: number

    /** Автоматическое назначение трека */
    automaticAppointmentPositions: DictionaryContract[]

    /** Идентификатор трека адаптации */
    id: number

    /** Наименование трека */
    name: string

    /** Использует ли трек визуализацию */
    isTrackWithVisualisation: boolean

    /** Заголовок */
    title: string

    /** Описание */
    description: string

    /**  */
    questionnaire: DictionaryContract

    /**  */
    passingType: PassingType

    /** Организации */
    organizations: DictionaryContract[]

    /** Должности */
    positions: DictionaryContract[]

    /** Использовать информационного помощника */
    useInformationAssistant: boolean

    /**  */
    informationAssistant: TrackAssistantContract

    /**  */
    status: DocumentStatus

    /** Дополнительные материалы */
    additionals: TrackAdditionalContract[]

    /** Холсты */
    trackCanvases: TrackCanvasContract[]

    /**  */
    trackProgressVisualization: TrackProgressVisualizationContract

    /**  */
    trackMenuVisualization: TrackMenuVisualizationContract

    /** Иконки заголовка трека */
    trackHeaderIcons: TrackHeaderIconContract[]

    /** Структура трека зарегистрирована в курсометре */
    coursometerStructureRegistered: boolean

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean

    /**  */
    trackBackgroundPicture: PictureContract

    /**  */
    trackYourDevelopmentDisplay: PictureContract
}

export interface TrackShortInfoContract {
    /** Идентификатор трека */
    id: number

    /** Наименование трека */
    name: string

    /** Количество этапов трека */
    countStages: number
}

export interface TrackSearchContract {
    /** Статусы трека адаптации */
    statuses: DocumentStatus[]

    /** Наименование */
    name: string

    /** Заголовок */
    title: string

    /** ФИО автора */
    ownerFio: string

    /** Показывать архивные */
    showArchive: boolean

    /** Организации */
    structuralUnitIds: number[]

    /** должности */
    positionIds: number[]

    /** Дата создания трека, начало диапазона */
    creatingDateStart: string

    /** Дата создания трека, конец диапазона */
    creatingDateEnd: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]

    /** Отобразить автоназначение */
    showAutomaticAppointmentOnly: boolean
}

export interface TrackSearchResultContract {
    /** Идентификатор трека адаптации */
    id: number

    /** Наименование трека */
    name: string

    /** Дата создания трека адаптации */
    creationDate: Date

    /** Дата изменения */
    changeDate: Date

    /** Заголовок */
    title: string

    /** Имя автора */
    ownerFirstName: string

    /** Фамилия автора */
    ownerLastName: string

    /** Отчество автора */
    ownerPatronymic: string

    /**  */
    status: DocumentStatus

    /** Флаг активности записи */
    active: boolean

    /** Организации */
    organizations: DictionaryContract[]

    /** Название треков */
    stages: DictionaryContract[]

    /** Версия записи */
    version: number

    /** Количество этапов */
    stageCount: number
}

export interface TrackSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TrackSearchResultContract[]
}

export interface DictionaryDto {
    /**  */
    id: number

    /**  */
    name: string
}

export interface TrackSearchResultDto {
    /**  */
    id: number

    /**  */
    name: string

    /**  */
    creationDate: Date

    /**  */
    changeDate: Date

    /**  */
    title: string

    /**  */
    ownerFirstName: string

    /**  */
    ownerLastName: string

    /**  */
    ownerPatronymic: string

    /**  */
    status: DocumentStatus

    /**  */
    active: boolean

    /**  */
    organizations: DictionaryDto[]

    /**  */
    stages: DictionaryDto[]

    /**  */
    version: number

    /**  */
    stageCount: number
}

export interface TrackSearchResultDtoPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TrackSearchResultDto[]
}

export interface TrackModalSearchContract {
    /** Статусы треков */
    statuses: DocumentStatus[]

    /** Наименование */
    name: string

    /** Заголовок */
    title: string

    /** ФИО автора */
    ownerFio: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean

    /** Идентификаторы организаций */
    structuralUnitIds: number[]

    /** Идентификаторы должностей */
    positionIds: number[]
}

export interface TrackModalContract {
    /** Идентификатор трека */
    id: number

    /** Наименование трека */
    name: string

    /**  */
    status: DocumentStatus

    /** Заголовок */
    title: string

    /** Имя автора */
    ownerFullName: string
}

export interface TrackModalContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TrackModalContract[]
}

export interface AdaptationTrackStageCreateContract {
    /** Идентификатор этапа */
    id: number

    /** Номер этапа трека адаптации */
    stageNumber: number

    /** Отображать прогресс прохождения этапа */
    showProgress: boolean

    /** Отображать иконку в содержании */
    showContentPicture: boolean

    /** Идентификатор иконки в содержании */
    contentIconId: number

    /** Заголовок этапа */
    title: string

    /** Обязательность прохождения */
    isPassingRequired: boolean

    /** События помощника */
    assistantEvents: TrackStageAssistantEventContract[]

    /** Мероприятие */
    activityId: number

    /** Обучающие материалы */
    courseId: number

    /** Ограничение количество попыток прохождения мероприятия */
    activityAttemptCount: number

    /** Ограничено ли количество попыток прохождения мероприятия */
    isActivityAttemptCountLimited: boolean

    /**  */
    materialType: TrackStageMaterialType
}

export interface TrackAssistantCreateContract {
    /** Наименование */
    name: string

    /** ИД иллюстрации */
    illustrationId: number

    /** События */
    events: TrackAssistantEventContract[]
}

export interface TrackAdditionalsCreateContract {
    /** Идентификатор дополнительных материалов */
    id: number

    /** Наименование доп материала */
    name: string

    /** Идентификатор обучающего материала */
    courseId: number

    /** Идентификатор организации */
    structuralUnitId: number

    /**  */
    additionalsType: AdditionalsType

    /**  */
    availability: AdditionalsAvailability
}

export interface TrackStageIconCreateContract {
    /** Тень */
    haveShadow: string

    /** Цвет тени */
    shadowColor: string

    /** Время пульсации */
    effectTime: number

    /** Пульсация запуск */
    effectStart: string

    /** Пульсация повторение */
    effectRepeat: boolean

    /** Пульсация продолжительность */
    effectDuration: string

    /** Ширина иконки */
    width: number

    /** Высота иконки */
    height: number

    /** Скругление иконки */
    iconRounding: number

    /** Идентификатор фона холста иконки */
    iconBackgroundId: number

    /** ИД иконки */
    iconId: number
}

export interface TrackStageIconsCreateContract {
    /**  */
    beforeStart: TrackStageIconCreateContract

    /**  */
    inProcess: TrackStageIconCreateContract

    /**  */
    passing: TrackStageIconCreateContract
}

export interface TrackStageStylesCreateContract {
    /** Номер этапа трека адаптации */
    stageNumber: number

    /** Стили позиции */
    positionStyles: string

    /**  */
    icons: TrackStageIconsCreateContract
}

export interface TrackCanvasCreateContract {
    /** Разрешение для адаптива */
    screenResolution: number

    /** ИД фона холста */
    trackCanvasBackgroundId: number

    /** Высота */
    height: number

    /** Ширина */
    width: number

    /** Стили */
    trackStageStyles: TrackStageStylesCreateContract[]
}

export interface TrackProgressVisualizationCreateContract {
    /** Переключатель отображения меню Прогресса */
    isProgressVisible: boolean

    /** Переключатель отображения пройденых тематических блоков */
    isLessonsVisible: boolean

    /** Переключатель отображения пройденых домашних заданий */
    isHomeworksVisible: boolean

    /** Переключатель отображения пройденых вебинаров */
    isWebinarsVisible: boolean

    /** Переключатель отображения рейтинга */
    isRatingVisible: boolean

    /**  */
    progressTextSettings: TextSettingsBaseContract

    /** Цвет фона */
    backgroundColor: string
}

export interface TrackMenuVisualizationCreateContract {
    /** Переключатель отображения меню */
    isMenuVisible: boolean

    /** Переключатель отображения содержания */
    isContentsVisible: boolean

    /** Переключатель отображения дополнительных материалов */
    isAdditionalMaterialsVisible: boolean

    /** Переключатель отображения домашних заданий */
    isHomeWorksVisible: boolean

    /**  */
    menuTextSettings: TextSettingsBaseContract

    /** Цвет фона */
    backgroundColor: string
}

export interface TrackHeaderIconCreateContract {
    /** Идентификатор иконки заголовка трека */
    id: number

    /** Ссылка для перехода */
    link: string

    /** Порядок отображения */
    order: number
}

export interface AdaptationTrackSaveContract {
    /** Этапы трека адаптации */
    stages: AdaptationTrackStageCreateContract[]

    /** Доступно автоматическое назначение трека */
    automaticAppointmentAvailable: boolean

    /** Количество дней на прохождение при автоматическом назначении */
    automaticAppointmentDuration: number

    /** Идентификаторы должностей, которым необходимо автоматически назначать данный трек */
    automaticAppointmentPositionIds: number[]

    /** Идентификатор родительской сущности */
    parentId: number

    /** Идентификатор трека */
    id: number

    /** Наименование трека */
    name: string

    /** Заголовок */
    title: string

    /** Описание */
    description: string

    /** Идентификатор опросного листа */
    questionnaireId: number

    /** Использует ли трек визуализацию */
    isTrackWithVisualisation: boolean

    /** Организации */
    organizationIds: number[]

    /** должности */
    positionIds: number[]

    /**  */
    passingType: PassingType

    /** Использовать информационного помощника */
    useInformationAssistant: boolean

    /**  */
    informationAssistant: TrackAssistantCreateContract

    /** Дополнительные материалы */
    additionals: TrackAdditionalsCreateContract[]

    /** Холсты */
    trackCanvases: TrackCanvasCreateContract[]

    /**  */
    trackProgressVisualization: TrackProgressVisualizationCreateContract

    /**  */
    trackMenuVisualization: TrackMenuVisualizationCreateContract

    /** Иконки заголовка трека */
    trackHeaderIcons: TrackHeaderIconCreateContract[]

    /** Структура трека зарегистрирована в курсометре */
    coursometerStructureRegistered: boolean

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean

    /** ИД изображение фона трека */
    trackBackgroundPictureId: number

    /** Идентификатор отображения трека на вкладке "Ваше развитие" */
    trackYourDevelopmentDisplayId: number
}

export interface AdaptationTrackPublishContract {
    /** Этапы трека адаптации */
    stages: AdaptationTrackStageCreateContract[]

    /** Доступно автоматическое назначение трека */
    automaticAppointmentAvailable: boolean

    /** Количество дней на прохождение при автоматическом назначении */
    automaticAppointmentDuration: number

    /** Идентификаторы должностей, которым необходимо автоматически назначать данный трек */
    automaticAppointmentPositionIds: number[]

    /** Идентификатор родительской сущности */
    parentId: number

    /** Идентификатор трека */
    id: number

    /** Наименование трека */
    name: string

    /** Заголовок */
    title: string

    /** Описание */
    description: string

    /** Идентификатор опросного листа */
    questionnaireId: number

    /** Использует ли трек визуализацию */
    isTrackWithVisualisation: boolean

    /** Организации */
    organizationIds: number[]

    /** должности */
    positionIds: number[]

    /**  */
    passingType: PassingType

    /** Использовать информационного помощника */
    useInformationAssistant: boolean

    /**  */
    informationAssistant: TrackAssistantCreateContract

    /** Дополнительные материалы */
    additionals: TrackAdditionalsCreateContract[]

    /** Холсты */
    trackCanvases: TrackCanvasCreateContract[]

    /**  */
    trackProgressVisualization: TrackProgressVisualizationCreateContract

    /**  */
    trackMenuVisualization: TrackMenuVisualizationCreateContract

    /** Иконки заголовка трека */
    trackHeaderIcons: TrackHeaderIconCreateContract[]

    /** Структура трека зарегистрирована в курсометре */
    coursometerStructureRegistered: boolean

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean

    /** ИД изображение фона трека */
    trackBackgroundPictureId: number

    /** Идентификатор отображения трека на вкладке "Ваше развитие" */
    trackYourDevelopmentDisplayId: number
}

export interface AppointmentUserContract {
    /** Идентификатор пользователя */
    id: number

    /** Имя */
    firstName: string

    /** Фамилия */
    lastName: string

    /** Отчество */
    patronymic: string

    /**  */
    position: DictionaryContract

    /**  */
    organization: DictionaryContract
}

export interface AppointmentActivityContract {
    /** Идентификатор назначения мероприятия */
    id: number

    /** Наименование */
    name: string

    /** Организации */
    organizations: DictionaryContract[]

    /**  */
    activity: DictionaryContract

    /** Автоматическая проверка дз без тьютора */
    isHomeworkAutoCheck: boolean

    /** Дата запуска автозачета */
    homeworkAutoCheckDate: Date

    /**  */
    status: AppointmentTrackStatus

    /** Идентификатор мероприятия */
    activityId: number

    /** Слушатели */
    participants: AppointmentUserContract[]

    /** Тьюторы */
    tutors: AppointmentUserContract[]

    /** Дата начала */
    startDate: Date

    /** Дата окончания */
    endDate: Date

    /**  */
    passingDateType: ActivityPassingDateType
}

export interface AppointmentActivitySearchContract {
    /** Наименование */
    name: string

    /** Наименование мероприятия */
    activityName: string

    /** Статусы назначения мероприятия */
    statuses: AppointmentTrackStatus[]

    /** Идентификаторы организаций */
    structuralUnitIds: number[]

    /** Показывать архивные записи */
    showArchive: boolean

    /** Дата начала */
    startDate: string

    /** Дата окончания */
    endDate: string

    /**  */
    isAutomaticallyCreated: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface AppointmentActivitySearchResultContract {
    /** Идентификатор назначения мероприятия */
    id: number

    /** Наименование */
    name: string

    /** Наименование мероприятия */
    activityName: string

    /** Организации */
    organizations: DictionaryContract[]

    /** Дата начала */
    startDate: Date

    /** Флаг активности записи */
    active: boolean

    /** Дата окончания */
    endDate: Date

    /**  */
    status: AppointmentTrackStatus

    /** Дата изменения */
    changeDate: Date
}

export interface AppointmentActivitySearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AppointmentActivitySearchResultContract[]
}

export interface AppointmentActivityCreateContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Организации */
    organizationIds: number[]

    /** Идентификатор мероприятия */
    activityId: number

    /** Автоматическая проверка дз без тьютора */
    isHomeworkAutoCheck: boolean

    /** Дата запуска автозачета */
    homeworkAutoCheckDate: string

    /** Слушатели */
    participantIds: number[]

    /** Тьюторы */
    tutorsIds: number[]

    /** Дата начала */
    startDate: string

    /** Дата окончания */
    endDate: string

    /**  */
    passingDateType: ActivityPassingDateType
}

export interface AppointmentActivityRespondentSearchContract {
    /** Идентификатор назначения мероприятия */
    appointmentId: number

    /** ФИО */
    name: string
}

export interface AppointmentActivityRespondentProgressContract {
    /**  */
    status: ActivityPassingStatus

    /** Ид пользователя */
    userId: number

    /** ФИО слушателя */
    fullName: string

    /** Прогресс прохождения слушателя */
    progress: number

    /** Активность участника */
    active: boolean
}

export interface AppointmentActivityRespondentProgressContractAppointmentProgressPageContract {
    /** Общий прогресс участников */
    commonProgress: number

    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AppointmentActivityRespondentProgressContract[]
}

export interface AppointmentActivityParticipantsDeleteContract {
    /** Идентификаторы пользователей, которых надо удалить */
    userIds: number[]
}

export interface EnumContract {
    /** Представление значения */
    name: string

    /** Значение перечисления */
    value: string
}

export interface AppointmentAssessmentRespondentContract {
    /** Флаг активности респондента */
    active: boolean

    /**  */
    respondent: AppointmentUserContract

    /**  */
    role: AssessmentRole
}

export interface AppointmentAssessmentCircleParametersContract {
    /**  */
    candidate: AppointmentUserContract

    /** Список респондентов */
    respondents: AppointmentAssessmentRespondentContract[]

    /** Обезличить респондентов */
    depersonalizeRespondents: boolean

    /** Список респондентов */
    checkingPersons: AppointmentUserContract[]
}

export interface AssessmentPortfolioRespondentContract {
    /** Идентификатор эксперта */
    id: number

    /** Номер эксперта */
    number: number
}

export interface AppointmentExpertAssessmentRespondentContract {
    /**  */
    portfolioExpert: AssessmentPortfolioRespondentContract

    /** Флаг активности респондента */
    active: boolean

    /**  */
    respondent: AppointmentUserContract

    /**  */
    role: AssessmentRole
}

export interface AppointmentAssessmentExpertParametersContract {
    /** Список респондентов */
    respondents: AppointmentExpertAssessmentRespondentContract[]
}

export interface AssessmentProjectManagerQuestionnaireContract {
    /**  */
    competence: DictionaryContract

    /**  */
    competenceGroup: DictionaryContract

    /**  */
    selfQuestionnaire: DictionaryContract

    /**  */
    managerQuestionnaire: DictionaryContract

    /** Доступен ли данный опросный лист для самооценки */
    unavailableForSelfQuestionnaire: boolean

    /** Идентификатор профиля должности */
    positionProfileId: number
}

export interface AssessmentProjectManagerPortfolioQuestionnairesContract {
    /** Идентификатор оцениваемой должности оценки руководителя для портфеля проекта оценки */
    id: number

    /**  */
    position: DictionaryContract

    /**  */
    structuralUnit: DictionaryContract

    /** Номер оцениваемой должности */
    assessedPositionNumber: number

    /** Содержит вопросники для самооценки */
    hasSelfQuestionnaires: boolean

    /** Контракт для создания связи портфеля оценки с опросными листами для самооценки и руководителя */
    questionnaires: AssessmentProjectManagerQuestionnaireContract[]
}

export interface AppointmentAssessmentManagerParametersCandidateContract {
    /** Объект оценки */
    candidates: AppointmentUserContract[]

    /**  */
    assessedPosition: AssessmentProjectManagerPortfolioQuestionnairesContract
}

export interface AppointmentAssessmentManagerParametersContract {
    /** Список объектов оценки */
    candidates: AppointmentAssessmentManagerParametersCandidateContract[]

    /**  */
    supervisor: AppointmentUserContract

    /** Идентификаторы проверяющих */
    checkingPersons: AppointmentUserContract[]
}

export interface AppointmentAssessmentContract {
    /** Номер заявки */
    requestNumber: string

    /**  */
    type: AssessmentType

    /**  */
    portfolio: DictionaryContract

    /**  */
    circleParameters: AppointmentAssessmentCircleParametersContract

    /**  */
    expertParameters: AppointmentAssessmentExpertParametersContract

    /**  */
    managerParameters: AppointmentAssessmentManagerParametersContract
}

export interface AppointmentQuizContract {
    /**  */
    type: QuizType

    /**  */
    portfolio: DictionaryContract

    /** Ограничивать количество попыток */
    attemptsLimited: boolean

    /** Ограничение количества попыток */
    attemptsLimit: number

    /** Проверяющие */
    reviewers: AppointmentUserContract[]

    /** Идентификаторы участников опроса */
    participants: AppointmentUserContract[]
}

export interface AppointmentTestParticipantsGroupContract {
    /** Номер группы по порядку */
    number: number

    /** Дополнительно ограничивать время для группы */
    groupTimeLimited: boolean

    /** Дополнительное ограничение времени для группы */
    groupTimeLimit: number

    /** Ограничивать количество попыток */
    attemptsLimited: boolean

    /** Ограничение количества попыток */
    attemptsLimit: number

    /** Участники группы тестирования */
    participants: AppointmentUserContract[]
}

export interface AppointmentTestContract {
    /**  */
    portfolio: DictionaryContract

    /** Группы участников тестирования */
    groups: AppointmentTestParticipantsGroupContract[]

    /** Проверяющие */
    reviewers: DictionaryContract[]
}

export interface AppointmentContract {
    /** Идентификатор назначения */
    id: number

    /** Наименование назначения оценки */
    name: string

    /**  */
    type: QuestionnaireType

    /**  */
    status: AppointmentStatus

    /** Период проведения с */
    evaluationPeriodFrom: Date

    /** Перод проведение до */
    evaluationPeriodTo: Date

    /** Дней до начала уведомлений */
    daysToStartNotification: number

    /** Дней до окончания уведомлений */
    daysToEndNotification: number

    /**  */
    assessment: AppointmentAssessmentContract

    /**  */
    quiz: AppointmentQuizContract

    /**  */
    test: AppointmentTestContract

    /** Организации */
    organizations: DictionaryContract[]

    /** Флаг активности записи */
    active: boolean

    /** Для всех организаций */
    forAllOrganizations: boolean
}

export interface AppointmentQuizCreateContract {
    /**  */
    type: QuizType

    /** Идентификатор портфеля опроса */
    portfolioId: number

    /** Ограничивать количество попыток */
    attemptsLimited: boolean

    /** Ограничение количества попыток */
    attemptsLimit: number

    /** Идентификаторы проверяющих */
    reviewerIds: number[]

    /** Идентификаторы участников опроса */
    participantsIds: number[]
}

export interface AppointmentAssessmentRespondentCreateContract {
    /** Идентификатор респондента */
    respondentId: number

    /**  */
    role: AssessmentRole
}

export interface AppointmentAssessmentCircleParametersCreateContract {
    /** Идентификатор оцениваемого */
    candidateId: number

    /** Обезличить респондентов */
    depersonalizeRespondents: boolean

    /** Список респондентов */
    respondents: AppointmentAssessmentRespondentCreateContract[]

    /** Список идентификаторов проверяющих */
    checkingPersonsIds: number[]
}

export interface AppointmentExpertOrManagerAssessmentRespondentCreateContract {
    /** Идентификатор эксперта или руководителя */
    expertOrManagerId: number

    /** Идентификатор респондента */
    respondentId: number

    /**  */
    role: AssessmentRole
}

export interface AppointmentAssessmentExpertParametersCreateContract {
    /** Список респондентов */
    respondents: AppointmentExpertOrManagerAssessmentRespondentCreateContract[]
}

export interface AppointmentAssessmentManagerParametersCandidateCreateContract {
    /** Идентификатор объекта оценки */
    candidateIds: number[]

    /** Идентификатор оцениваемой должности для портфеля оценки руководителем */
    assessedPositionId: number
}

export interface AppointmentAssessmentManagerParametersCreateContract {
    /** Идентификатор руководителя */
    supervisorId: number

    /** Список идентификаторов проверяющих */
    checkingPersonsIds: number[]

    /** Список объектов оценки */
    candidates: AppointmentAssessmentManagerParametersCandidateCreateContract[]
}

export interface AppointmentAssessmentCreateContract {
    /**  */
    type: AssessmentType

    /** Номер заявки */
    requestNumber: string

    /** Идентификатор портфеля оценки */
    portfolioId: number

    /**  */
    circleParameters: AppointmentAssessmentCircleParametersCreateContract

    /**  */
    expertParameters: AppointmentAssessmentExpertParametersCreateContract

    /**  */
    managerParameters: AppointmentAssessmentManagerParametersCreateContract
}

export interface AppointmentTestParticipantsGroupCreateContract {
    /** Номер группы по порядку */
    number: number

    /** Дополнительно ограничивать время для группы */
    groupTimeLimited: boolean

    /** Дополнительное ограничение времени для группы */
    groupTimeLimit: number

    /** Ограничивать количество попыток */
    attemptsLimited: boolean

    /** Ограничение количества попыток */
    attemptsLimit: number

    /** Идентификаторы участников тестирования */
    participantsIds: number[]
}

export interface AppointmentTestCreateContract {
    /** Идентификатор портфеля тестирования */
    portfolioId: number

    /** Группы участников тестирования */
    groups: AppointmentTestParticipantsGroupCreateContract[]

    /** Идентификаторы проверяющих */
    reviewerIds: number[]
}

export interface AppointmentSaveContract {
    /** Дней до начала уведомлений */
    daysToStartNotification: number

    /** Дней до окончания уведомлений */
    daysToEndNotification: number

    /** Идентификатор обновляемой сущности */
    id: number

    /** Наименование назначения оценки */
    name: string

    /**  */
    type: QuestionnaireType

    /** Период проведения оценки с */
    evaluationPeriodFrom: string

    /** Перод проведение оценки до */
    evaluationPeriodTo: string

    /**  */
    quiz: AppointmentQuizCreateContract

    /**  */
    assessment: AppointmentAssessmentCreateContract

    /**  */
    test: AppointmentTestCreateContract

    /** Организации */
    organizationsIds: number[]

    /** Для всех организаций */
    forAllOrganizations: boolean
}

export interface AppointmentPublishContract {
    /** Период проведения оценки с */
    evaluationPeriodFrom: string

    /** Перод проведение оценки до */
    evaluationPeriodTo: string

    /** Дней до начала уведомлений */
    daysToStartNotification: number

    /** Дней до окончания уведомлений */
    daysToEndNotification: number

    /** Идентификатор обновляемой сущности */
    id: number

    /** Наименование назначения оценки */
    name: string

    /**  */
    type: QuestionnaireType

    /**  */
    quiz: AppointmentQuizCreateContract

    /**  */
    assessment: AppointmentAssessmentCreateContract

    /**  */
    test: AppointmentTestCreateContract

    /** Организации */
    organizationsIds: number[]

    /** Для всех организаций */
    forAllOrganizations: boolean
}

export interface AppointmentAssessmentSearchContract {
    /** Наименование цели */
    purposeName: string

    /**  */
    type: AssessmentType

    /** Объекты оценки */
    candidates: number[]

    /** Номер заявки */
    requestNumber: string
}

export interface AppointmentQuizSearchContract {
    /**  */
    type: QuizType
}

export interface AppointmentTestSearchContract {
    /** Идентификаторы оцениваемых компетенций */
    competenceIds: number[]
}

export interface AppointmentSearchContract {
    /** Наименование назначения */
    name: string

    /**  */
    appointmentAssessmentSearch: AppointmentAssessmentSearchContract

    /**  */
    appointmentQuizSearch: AppointmentQuizSearchContract

    /**  */
    appointmentTestSearch: AppointmentTestSearchContract

    /**  */
    type: QuestionnaireType

    /** ФИО пользователя */
    fio: string

    /** Статус назначения */
    statuses: AppointmentStatus[]

    /** Показать архивные записи */
    showArchive: boolean

    /** Показать завершенные */
    showComplete: boolean

    /** Организации\/подразделения */
    structuralUnitIds: number[]

    /** Период проведения с */
    evaluationPeriodFrom: string

    /** Перод проведение до */
    evaluationPeriodTo: string

    /** дата создания, начало диапазона */
    changeDateFrom: string

    /** дата создания, конец диапазона */
    changeDateTo: string

    /** Дата создания с */
    creationDateFrom: string

    /** Дата создания по */
    creationDateTo: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]

    /** Правила сортировки для вкладки Опрос */
    orderRulesByUser: OrderRuleContract[]
}

export interface AppointmentSearchResultContract {
    /** Идентификатор назначения */
    id: number

    /** Флаг активности записи */
    active: boolean

    /**  */
    status: AppointmentStatus

    /**  */
    type: QuestionnaireType

    /** Наименование назначения оценки */
    name: string

    /** Период проведения с */
    evaluationPeriodFrom: Date

    /** Перод проведение до */
    evaluationPeriodTo: Date

    /** Дней до начала уведомлений */
    daysToStartNotification: number

    /** Дней до окончания уведомлений */
    daysToEndNotification: number

    /** Имя ответственного лица */
    ownerFirstName: string

    /** Фамилия ответственного лица */
    ownerLastName: string

    /** Отчество ответственного лица */
    ownerPatronymic: string

    /**  */
    assessmentType: AssessmentType

    /**  */
    quizType: QuizType

    /** Наименование цели оценки */
    purposeName: string

    /** Оцениваемые компетенции */
    competencies: DictionaryContract[]

    /** Дата создания */
    creationDate: Date

    /** Дата изменения */
    changeDate: Date

    /** Организации */
    organizations: DictionaryContract[]

    /** Номер заявки */
    requestNumber: string

    /** Наименование проекта */
    assessmentProjectName: string

    /** Процент прохождения */
    passingPercent: number

    /** ФИО объектов оценки */
    candidateFullNames: string[]

    /** Идентификатор отчёта оценки */
    assessmentReportId: number

    /** Показать отчёт или нет */
    isShowReport: boolean
}

export interface AppointmentSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AppointmentSearchResultContract[]
}

export interface AppointmentSearchTextContract {
    /**  */
    type: QuestionnaireType

    /** Строка поиска */
    searchText: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface ParticipantContract {
    /** Идентификатор респондента */
    id: number

    /** ФИО пользователя */
    userFio: string

    /** Наименование должности */
    positionNames: string[]
}

export interface RespondentContract {
    /**  */
    role: AssessmentRole

    /** Статус активности респондента */
    active: boolean

    /** Идентификатор респондента */
    id: number

    /** ФИО пользователя */
    userFio: string

    /** Наименование должности */
    positionNames: string[]
}

export interface AppointmentRespondentsContract {
    /**  */
    candidate: ParticipantContract

    /** Респонденты */
    respondents: RespondentContract[]
}

export interface AppointmentUserSearchContract {
    /** Наименование назначения */
    name: string

    /** Период с */
    periodFrom: Date

    /** Период по */
    periodTo: Date

    /** Типы назначений */
    types: QuestionnaireType[]

    /** Типы оценок */
    assessmentTypes: AssessmentType[]

    /** Статусы */
    statuses: AppointmentStatus[]
}

export interface UserBaseContract {
    /** Идентификатор пользователя */
    id: number

    /** Имя */
    firstName: string

    /** Фамилия */
    lastName: string

    /** Отчество */
    patronymic: string

    /** Email */
    email: string
}

export interface AppointmentUserSearchResultContract {
    /** Идентификатор назначения */
    id: number

    /** Флаг активности записи */
    active: boolean

    /**  */
    status: AppointmentStatus

    /**  */
    type: QuestionnaireType

    /** Наименование назначения оценки */
    name: string

    /** Период проведения с */
    evaluationPeriodFrom: Date

    /** Перод проведение до */
    evaluationPeriodTo: Date

    /** Дата отображения будильника */
    alarmDate: Date

    /**  */
    assessmentType: AssessmentType

    /**  */
    quizType: QuizType

    /**  */
    owner: UserBaseContract

    /** Проверяющие */
    reviewers: UserBaseContract[]

    /**  */
    candidate: DictionaryContract

    /** Процент прохождения */
    passingPercent: number

    /** Дата изменения */
    changeDate: Date
}

export interface AppointmentUserSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AppointmentUserSearchResultContract[]
}

export interface AppointmentAssessmentExpertListContract {
    /** Идентификатор  назначения */
    id: number

    /** Наименование назначения */
    name: string

    /**  */
    type: AssessmentType

    /**  */
    purpose: DictionaryContract

    /** Период проведения с */
    evaluationPeriodFrom: Date

    /** Перод проведение до */
    evaluationPeriodTo: Date

    /**  */
    status: AppointmentStatus

    /** Фио кандидата */
    fio: string
}

export interface AppointmentAssessmentExpertListContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AppointmentAssessmentExpertListContract[]
}

export interface PassingRespondentContract {
    /**  */
    role: AssessmentRole

    /**  */
    user: UserBaseContract

    /** Активность респондента */
    active: boolean
}

export interface RespondentProgressContract {
    /**  */
    status: AppointmentStatus

    /** Процент прохождения */
    passingPercent: number

    /**  */
    respondent: PassingRespondentContract
}

export interface AppointmentPassingProgressContract {
    /** Идентификатор назначения */
    id: number

    /** Наименование назначения */
    name: string

    /** Общий прогресс прохождения */
    passingPercent: number

    /** Результаты прохождения */
    results: RespondentProgressContract[]
}

export interface StructuralUnitShortContract {
    /** Идентификатор структурной единицы */
    id: number

    /** Название структурной единицы */
    name: string

    /**  */
    type: StructuralUnitType
}

export interface AppointmentQuizUserProgressContract {
    /** Флаг активности записи */
    active: boolean

    /** Имя пользователя */
    userName: string

    /** Идентификатор пользователя */
    userId: number

    /**  */
    status: AppointmentStatus

    /** Процент прохождения */
    passingPercent: number
}

export interface AppointmentQuizStructuralUnitProgressContract {
    /**  */
    structuralUnit: StructuralUnitShortContract

    /** Процент прохождения */
    passingPercent: number

    /** Пользователи */
    users: AppointmentQuizUserProgressContract[]

    /** Дочерние организации */
    units: AppointmentQuizStructuralUnitProgressContract[]
}

export interface AppointmentQuizPassingProgressContract {
    /** Идентификатор назначения */
    id: number

    /** Наименование назначения */
    name: string

    /** Общий прогресс прохождения */
    passingPercent: number

    /** Организации */
    units: AppointmentQuizStructuralUnitProgressContract[]
}

export interface AppointmentTestReviewQuestionAnswerContract {
    /** Идентификатор */
    id: number

    /**  */
    answer: DictionaryContract

    /**  */
    question: DictionaryContract

    /** Номер вопроса */
    questionNumber: number

    /** Оценка проверяющего */
    reviewerEvaluation: number

    /** Дата и время проверки */
    revieweredDate: Date

    /** ФИО пользователя, проверяющего */
    reviewerFullname: string
}

export interface CommentContract {
    /** Текст комментария */
    text: string

    /** ФИО пользователя внесшего комментарий */
    userFullName: string

    /** Дата и время внесения комментария */
    commentedDate: Date
}

export interface AppointmentTestReviewParticipantsContract {
    /** Идентификатор */
    id: number

    /**  */
    user: DictionaryContract

    /** Инфомрация о вопросе и ответе на него */
    questionAnswer: AppointmentTestReviewQuestionAnswerContract[]

    /** Итоговая оценка */
    finalEvaluation: number

    /**  */
    comment: CommentContract
}

export interface AppointmentTestReviewContract {
    /** Идентификатор формы проверки */
    id: number

    /**  */
    structuralUnit: DictionaryContract

    /** Участники */
    participants: AppointmentTestReviewParticipantsContract[]
}

export interface AppointmentTestReviewQuestionAnswerCreateContract {
    /** Идентификатор */
    id: number

    /** Идентификатор ответа */
    questionAnswerId: number

    /** Оценка проверяющего */
    reviewerEvaluation: number
}

export interface AppointmentTestReviewParticipantsCreateContract {
    /** Идентификатор */
    id: number

    /** Идентификатор пользователя */
    participantId: number

    /** Инфомрация о проверке назначения тестирования, а именно идентификатор вопрос и оценка проверяющего на этот ответ */
    questionAnswer: AppointmentTestReviewQuestionAnswerCreateContract[]

    /** Итоговая оценка */
    finalEvaluation: number

    /** Комментарий по прохождению */
    comment: string
}

export interface AppointmentTestReviewCreateContract {
    /** Идентификатор проверки */
    id: number

    /** Идентификатор назначения */
    appointmentId: number

    /** Оценка участников */
    participants: AppointmentTestReviewParticipantsCreateContract[]
}

export interface AppointmentTestStatisticScoreContract {
    /** Балл */
    score: number

    /**  */
    color: ColorResult
}

export interface AppointmentTestStatisticResultQuestionnaireContract {
    /** Баллы */
    scores: AppointmentTestStatisticScoreContract[]

    /** Результат прохождения опросного листа */
    result: string

    /**  */
    colorResult: ColorResult
}

export interface AppointmentTestStatisticParticipantContract {
    /** Идентификатор формы прохождения */
    passingFormId: number

    /** ФИО */
    fio: string

    /** Баллы в опросных листах */
    resultQuestionnaire: AppointmentTestStatisticResultQuestionnaireContract[]

    /** Время прохождения */
    timePassing: number

    /** Дата завершения */
    dateEnd: Date

    /**  */
    comment: CommentContract
}

export interface AppointmentTestStatisticByOrganizationContract {
    /** Идентификатор Организации\/Подразделения */
    id: number

    /** Название Организации\/Подразделения */
    name: string

    /** Дочерние Организации\/Подразделения */
    children: AppointmentTestStatisticByOrganizationContract[]

    /** Статистика участников */
    participantsStatistic: AppointmentTestStatisticParticipantContract[]
}

export interface AppointmentTestStatisticCommonContract {
    /** Наименование теста */
    name: string

    /** Дата начала теста */
    dateStart: Date

    /** Дата завершения теста */
    dateEnd: Date

    /** Среднее затраченное на прохождение время в секундах */
    averagePassingTime: number

    /** Количество завершивших тест участников */
    numberCompletedParticipants: number

    /** Количество назначенных участников */
    numberAppointedParticipants: number

    /** Названия опросных листов */
    questionnaireNames: string[]

    /** Статистика по организациям */
    statistic: AppointmentTestStatisticByOrganizationContract[]
}

export interface AppointmentTrackRespondentScoreContract {
    /** Оценка орфографии */
    spelling: number

    /** Оценка пунктуации */
    punctuation: number

    /** Оценка культуры речи */
    culture: number
}

export interface AppointmentTrackRespondentContract {
    /**  */
    score: AppointmentTrackRespondentScoreContract

    /** Идентификатор пользователя */
    id: number

    /** Имя */
    firstName: string

    /** Фамилия */
    lastName: string

    /** Отчество */
    patronymic: string

    /**  */
    position: DictionaryContract

    /**  */
    organization: DictionaryContract
}

export interface AppointmentTrackStageSettingsContract {
    /**  */
    status: TrackStageAvailableStatus

    /** Начало периода действия статуса */
    periodFrom: Date

    /** Окончания периода действия статуса */
    periodTo: Date

    /** Номер этапа */
    stageNumber: number
}

export interface AppointmentTrackContract {
    /** Идентификатор назначения */
    id: number

    /** Наименование назначения */
    name: string

    /** Количество попыток (Если Null, то неограничено\/если НЕ null то ограничено) */
    attemptCount: number

    /** Включено ли уведомление */
    isNotificationEnable: boolean

    /** Неограничено ли количество прохождения трека */
    isTrackAttemptCountNotLimited: boolean

    /** Автоматическая проверка дз без тьютора */
    isHomeworkAutoCheck: boolean

    /** Дата запуска автозачета */
    homeworkAutoCheckDate: Date

    /**  */
    trackType: TrackType

    /**  */
    adaptationTrack: DictionaryContract

    /**  */
    developmentTrack: DictionaryContract

    /**  */
    mguuTrack: DictionaryContract

    /** Длительность \/ Период */
    isDuration: boolean

    /** Дата запуска трека */
    periodFrom: Date

    /** Доступность трека */
    duration: number

    /** Дата завершения трека */
    periodTo: Date

    /** Тьюторы */
    tutors: AppointmentUserContract[]

    /** Респонденты */
    respondents: AppointmentTrackRespondentContract[]

    /** Проверяющие */
    checkingPersons: AppointmentUserContract[]

    /**  */
    status: AppointmentTrackStatus

    /**  */
    appointmentType: TrackAppointmentType

    /** Настройка этапов треков */
    stageSettings: AppointmentTrackStageSettingsContract[]

    /** Срок уведомления о начале трека */
    daysToStartNotification: number

    /** Срок уведомления о конце трека */
    daysToEndNotification: number
}

export interface AppointmentTrackRespondentCreateContract {
    /** Идентификатор пользователя */
    userId: number

    /**  */
    score: AppointmentTrackRespondentScoreContract
}

export interface AppointmentTrackStageSettingsCreateContract {
    /** Номер этапа */
    stageNumber: number

    /**  */
    status: TrackStageAvailableStatus

    /** Начало периода действия статуса */
    periodFrom: string

    /** Окончания периода действия статуса */
    periodTo: string
}

export interface AppointmentTrackSaveContract {
    /** Идентификатор обновляемой сущности */
    id: number

    /** Количество попыток (Если Null, то неограничено\/если НЕ null то ограничено) */
    attemptCount: number

    /** Неограничено ли количество прохождения трека */
    isTrackAttemptCountNotLimited: boolean

    /** Автоматическая проверка дз без тьютора */
    isHomeworkAutoCheck: boolean

    /** Дата запуска автозачета */
    homeworkAutoCheckDate: string

    /** Наименование */
    name: string

    /**  */
    trackType: TrackType

    /** ИД шаблона трека адаптации */
    adaptationTrackId: number

    /** Включено ли уведомление */
    isNotificationEnable: boolean

    /** ИД шаблона трека развития */
    developmentTrackId: number

    /** Идентификатор предустановленного трека */
    mguuTrackId: number

    /** Длительность \/ Период */
    isDuration: boolean

    /** Дата запуска трека */
    periodFrom: string

    /** Доступность трека */
    duration: number

    /** Дата завершения трека */
    periodTo: string

    /** Идентификаторы тьюторов */
    tutorIds: number[]

    /** Респонденты */
    respondents: AppointmentTrackRespondentCreateContract[]

    /** Идентификаторы проверяющих */
    checkingPersonsIds: number[]

    /**  */
    appointmentType: TrackAppointmentType

    /** Настройка этапов треков */
    stageSettings: AppointmentTrackStageSettingsCreateContract[]

    /** Срок уведомления о начале трека */
    daysToStartNotification: number

    /** Срок уведомления о конце трека */
    daysToEndNotification: number

    /** Автоматически назначен трек или нет */
    isAutomaticallyCreated: boolean
}

export interface AppointmentTrackPublishContract {
    /** Дата запуска трека */
    periodFrom: string

    /** Дата завершения трека */
    periodTo: string

    /** Идентификатор обновляемой сущности */
    id: number

    /** Количество попыток (Если Null, то неограничено\/если НЕ null то ограничено) */
    attemptCount: number

    /** Неограничено ли количество прохождения трека */
    isTrackAttemptCountNotLimited: boolean

    /** Автоматическая проверка дз без тьютора */
    isHomeworkAutoCheck: boolean

    /** Дата запуска автозачета */
    homeworkAutoCheckDate: string

    /** Наименование */
    name: string

    /**  */
    trackType: TrackType

    /** ИД шаблона трека адаптации */
    adaptationTrackId: number

    /** Включено ли уведомление */
    isNotificationEnable: boolean

    /** ИД шаблона трека развития */
    developmentTrackId: number

    /** Идентификатор предустановленного трека */
    mguuTrackId: number

    /** Длительность \/ Период */
    isDuration: boolean

    /** Доступность трека */
    duration: number

    /** Идентификаторы тьюторов */
    tutorIds: number[]

    /** Респонденты */
    respondents: AppointmentTrackRespondentCreateContract[]

    /** Идентификаторы проверяющих */
    checkingPersonsIds: number[]

    /**  */
    appointmentType: TrackAppointmentType

    /** Настройка этапов треков */
    stageSettings: AppointmentTrackStageSettingsCreateContract[]

    /** Срок уведомления о начале трека */
    daysToStartNotification: number

    /** Срок уведомления о конце трека */
    daysToEndNotification: number

    /** Автоматически назначен трек или нет */
    isAutomaticallyCreated: boolean
}

export interface AppointmentTrackSearchResultContract {
    /** Идентификатор назначения */
    id: number

    /** Наименование назначения */
    name: string

    /** Наименование трека */
    trackName: string

    /**  */
    trackType: TrackType

    /** Дата запуска трека */
    periodFrom: Date

    /** Дата завершения трека */
    periodTo: Date

    /**  */
    status: AppointmentTrackStatus

    /** Флаг активности записи */
    active: boolean

    /** Дата создания */
    creationDate: Date

    /** Признак наличия пройденного опроса с типом обратная связь */
    hasFeedbackQuizPassed: boolean
}

export interface AppointmentTrackSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AppointmentTrackSearchResultContract[]
}

export interface AppointmentTrackSearchContract {
    /** Наименование назначения */
    name: string

    /**  */
    trackType: TrackType

    /** Статусы назначения */
    statuses: AppointmentTrackStatus[]

    /** Показать архивные записи */
    showArchive: boolean

    /** Дата запуска трека */
    periodFrom: string

    /** Дата завершения трека */
    periodTo: string

    /** Автоматически назначен трек или нет */
    isAutomaticallyCreated: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface AppointmentTrackRespondentSearchContract {
    /** Ид назначения трека */
    appointmentTrackId: number

    /** ПОиск по фио */
    name: string
}

export interface AppointmentTrackRespondentProgressContract {
    /**  */
    status: AppointmentTrackStatus

    /** Ид пользователя */
    userId: number

    /** ФИО слушателя */
    fullName: string

    /** Прогресс прохождения слушателя */
    progress: number

    /** Активность участника */
    active: boolean
}

export interface AppointmentTrackRespondentProgressContractAppointmentProgressPageContract {
    /** Общий прогресс участников */
    commonProgress: number

    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AppointmentTrackRespondentProgressContract[]
}

export interface AssessmentPurposeContract {
    /** Идентификатор цели оценки */
    id: number

    /** Наименование цели оценки */
    name: string
}

export interface AssessmentPortfolioSelectSearchContract {
    /** Организации */
    structuralUnitsIds: number[]

    /**  */
    type: AssessmentType

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface StructuralUnitContract {
    /** Идентификатор структурной единицы */
    id: number

    /** Название структурной единицы */
    name: string

    /**  */
    type: StructuralUnitType

    /** Ссылка на родительскую сущность для иерархии */
    units: StructuralUnitContract[]

    /** Флаг, архивная ли структурная единица */
    isArchived: boolean

    /** Дата изменения */
    changeDate: Date
}

export interface QuestionChooseVariantAnswerContract {
    /** Порядковый номер ответа */
    number: number

    /** Текст ответа */
    text: string

    /**  */
    attachment: AttachmentContract

    /** Балы */
    points: number

    /** Правильный ответ */
    isRightAnswer: boolean
}

export interface QuestionChooseVariantContract {
    /** Установить ограничение на количество вариантов ответа */
    isAnswersCountLimited: boolean

    /** Максимальное количество вариантов ответа */
    limitAnswersCount: number

    /** Доступное количество ответов (один\/несколько) */
    multiChoose: boolean

    /** Отображать варианты ответов в произвольном порядке */
    randomAnswerOrder: boolean

    /** Предоставить пользователю возможность добавить свой вариант ответа */
    customAnswer: boolean

    /** Список ответов */
    answers: QuestionChooseVariantAnswerContract[]
}

export interface QuestionFillEmptyTextAnswerContract {
    /** Номер вопроса */
    number: number

    /** Правильные ответы */
    correctAnswers: string[]

    /** Количество очков за ответ */
    score: number
}

export interface QuestionFillEmptyTextContract {
    /** Считать баллы за каждый ответ */
    calcForAnswer: boolean

    /** Количество баллов за вопрос */
    questionScore: number

    /** Ответы по пропущенному тексту */
    answers: QuestionFillEmptyTextAnswerContract[]
}

export interface QuestionContract {
    /** Ид вопроса */
    id: number

    /** Порядковый номер вопроса */
    number: number

    /** Порядковый номер блокирующего вопроса */
    blockedByQuestionNumber: number

    /**  */
    type: QuestionType

    /** Обязательный вопрос */
    mandatoryQuestion: boolean

    /** Вопрос неактивен */
    isInactive: boolean

    /** Установлено ограничение на ответ */
    limitedAnswerTime: boolean

    /** Время на ответ в минутах */
    answerLimit: number

    /** Заголовок вопроса */
    title: string

    /** Текст вопроса */
    text: string

    /**  */
    indicator: IndicatorContract

    /** Коллекция присоединенных файлов */
    attachments: AttachmentContract[]

    /**  */
    chooseVariant: QuestionChooseVariantContract

    /**  */
    criteriaScale: QuestionCriteriaScaleContract

    /**  */
    fillEmptyText: QuestionFillEmptyTextContract
}

export interface QuestionnaireAssessmentParametersContract {
    /**  */
    assessmentType: AssessmentType

    /**  */
    respondentType: DictionaryContract

    /**  */
    resultCalculatingMethod: DictionaryContract

    /**  */
    scale: ScaleContract
}

export interface QuestionnaireTestParametersContract {
    /**  */
    testType: DictionaryContract
}

export interface QuestionnaireQuizParametersContract {
    /**  */
    quizType: QuizType

    /**  */
    resultCalculatingMethod: DictionaryContract

    /**  */
    scale: DictionaryContract
}

export interface ResultLevelContract {
    /** Наименование */
    name: string

    /** Уровень */
    level: number

    /** Баллы от */
    pointsFrom: number

    /** Баллы до */
    pointsTo: number
}

export interface QuestionnaireResultEstimationParametersContract {
    /**  */
    resultCalculatingType: ResultCalculatingType

    /** Максимальное количество баллов */
    maxPoints: number

    /** Количество проходных баллов */
    passingPoints: number

    /** Уровни результатов теста */
    resultLevels: ResultLevelContract[]
}

export interface QuestionnaireContract {
    /**  */
    status: DocumentStatus

    /**  */
    type: QuestionnaireType

    /** Наименование */
    name: string

    /** Идентификатор */
    id: number

    /** Список должностей */
    positions: DictionaryContract[]

    /** Вопросы */
    questions: QuestionContract[]

    /**  */
    competence: DictionaryContract

    /** Описание шаблона */
    description: string

    /**  */
    sequence: TestSequence

    /**  */
    assessmentParameters: QuestionnaireAssessmentParametersContract

    /**  */
    testParameters: QuestionnaireTestParametersContract

    /**  */
    quizParameters: QuestionnaireQuizParametersContract

    /**  */
    resultEstimationParameters: QuestionnaireResultEstimationParametersContract

    /** Отображать вопросы в произвольном порядке */
    randomQuestionsOrder: boolean

    /** Дата изменения */
    changeDate: Date

    /** Структурные подразделения */
    structuralUnits: DictionaryContract[]

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface CircleAssessmentPortfolioQuestionnaireContract {
    /**  */
    selfQuestionnaire: QuestionnaireContract

    /**  */
    respondentQuestionnaire: QuestionnaireContract

    /**  */
    competence: CompetenceContract
}

export interface AssessmentPortfolioCircleParametersContract {
    /** Количество руководителей */
    managersCount: number

    /** Количество коллег */
    colleaguesCount: number

    /** Количество подчиненных */
    subordinatesCount: number

    /** Количество коллег из смежного отдела */
    colleagueFromRelatedDepartmentCount: number

    /** Лимит времени для прохождения самооценки */
    selfTimeLimit: number

    /** Лимит времени для руководителей */
    managersTimeLimit: number

    /** Лимит времени для подчиненных */
    subordinatesTimeLimit: number

    /** Лимит времени для коллег */
    colleaguesTimeLimit: number

    /** Список опросных листов */
    circlePortfolioQuestionnaires: CircleAssessmentPortfolioQuestionnaireContract[]
}

export interface AssessmentQuestionnaireContract {
    /**  */
    questionnaire: QuestionnaireContract

    /**  */
    competence: CompetenceContract
}

export interface AssessmentPortfolioExpertContract {
    /** Идентификатор эксперта */
    id: number

    /** Номер эксперта */
    expertNumber: number

    /** Оценки экспертом */
    expertQuestionnaires: AssessmentQuestionnaireContract[]
}

export interface AssessmentPortfolioExpertParametersContract {
    /**  */
    kind: AssessmentKind

    /** Самоооценки */
    selfQuestionnaires: AssessmentQuestionnaireContract[]

    /** Эксперты */
    experts: AssessmentPortfolioExpertContract[]

    /** Оценки эксперта группы */
    groupExpertQuestionnaires: AssessmentQuestionnaireContract[]
}

export interface ManagerAssessmentAssessedPositionQuestionnaireContract {
    /**  */
    selfQuestionnaire: QuestionnaireContract

    /**  */
    managerQuestionnaire: QuestionnaireContract

    /**  */
    competence: CompetenceContract
}

export interface AssessmentPortfolioAssessedPositionContract {
    /** Идентификатор оцениваемой должности */
    id: number

    /**  */
    position: DictionaryContract

    /**  */
    structuralUnit: DictionaryContract

    /** Номер оцениваемой должности */
    assessedPositionNumber: number

    /** Содержит вопросники для самооценки */
    hasSelfQuestionnaires: boolean

    /** Контракт для создания связи портфеля оценки с опросными листами для самооценки и руководителя */
    questionnaires: ManagerAssessmentAssessedPositionQuestionnaireContract[]
}

export interface AssessmentPortfolioPresentationWelcomeWindowParametersContract {
    /** Показывать обращение к пользователю */
    showGreeting: boolean

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст приветствия */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /**  */
    picture: AttachmentContract
}

export interface AssessmentPortfolioPresentationFinalWindowParametersContract {
    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст финального обращения */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /**  */
    picture: AttachmentContract
}

export interface AssessmentPortfolioPresentationContract {
    /**  */
    welcomeWindow: AssessmentPortfolioPresentationWelcomeWindowParametersContract

    /**  */
    finalWindow: AssessmentPortfolioPresentationFinalWindowParametersContract

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Отображать прогресс прохождения */
    showPassingProgress: boolean

    /** Показывать вопросы по блокам */
    showQuestionsByBlock: boolean

    /**  */
    passingPicture: AttachmentContract
}

export interface AssessmentPortfolioContract {
    /** Идентификатор портфеля оценки */
    id: number

    /** Наименование портфеля оценки */
    name: string

    /** Описание портфеля оценки */
    description: string

    /**  */
    purpose: DictionaryContract

    /**  */
    documentStatus: DocumentStatus

    /** Должности */
    positions: DictionaryContract[]

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Организация */
    structuralUnits: StructuralUnitContract[]

    /** Дата изменения */
    changeDate: Date

    /**  */
    type: AssessmentType

    /**  */
    circleParameters: AssessmentPortfolioCircleParametersContract

    /**  */
    expertParameters: AssessmentPortfolioExpertParametersContract

    /** Параметры для портфеля оценки с типом - оценка руководителем */
    managerParameters: AssessmentPortfolioAssessedPositionContract[]

    /** Флаг активности записи */
    active: boolean

    /** Прикрепленный файл */
    attachments: AttachmentContract[]

    /**  */
    presentation: AssessmentPortfolioPresentationContract
}

export interface AssessmentPortfolioForDropdownContract {
    /** Идентификатор портфеля оценки */
    id: number

    /**  */
    type: AssessmentType

    /**  */
    circleParameters: AssessmentPortfolioCircleParametersContract

    /** Параметры для портфеля оценки с типом - оценка руководителем */
    managerParameters: AssessmentPortfolioAssessedPositionContract[]

    /** Идентификаторы структурных подразделений */
    structuralUnitsIds: number[]

    /** Для всех организаций */
    forAllOrganizations: boolean
}

export interface CircleAssessmentPortfolioQuestionnaireCreateContract {
    /** Идентификатор опросного листа самооценки */
    selfQuestionnaireId: number

    /** Идентификатор опросного листа респондента */
    respondentQuestionnaireId: number

    /** Идентификатор компетенции */
    competenceId: number
}

export interface AssessmentPortfolioCircleParametersCreateContract {
    /** Количество руководителей */
    managersCount: number

    /** Количество коллег */
    colleaguesCount: number

    /** Количество подчиненных */
    subordinatesCount: number

    /** Количество коллег из смежного отдела */
    colleagueFromRelatedDepartmentCount: number

    /** Лимит времени для прохождения самооценки */
    selfTimeLimit: number

    /** Лимит времени для руководителей */
    managersTimeLimit: number

    /** Лимит времени для подчиненных */
    subordinatesTimeLimit: number

    /** Лимит времени для коллег */
    colleaguesTimeLimit: number

    /** Связи портфеля оценки и опросного листа */
    questionnaires: CircleAssessmentPortfolioQuestionnaireCreateContract[]
}

export interface AssessmentQuestionnaireCreateContract {
    /** Идентификатор компетенции */
    competenceId: number

    /** Идентификатор опросного листа */
    questionnaireId: number
}

export interface ExpertAssessmentExpertCreateContract {
    /** Эксперты */
    expertQuestionnaires: AssessmentQuestionnaireCreateContract[]

    /** Номер эксперта */
    expertNumber: number
}

export interface AssessmentPortfolioExpertParametersCreateContract {
    /**  */
    kind: AssessmentKind

    /** Самооценки */
    selfQuestionnaires: AssessmentQuestionnaireCreateContract[]

    /** Эксперты */
    experts: ExpertAssessmentExpertCreateContract[]

    /** Эксперты группы */
    groupExpertQuestionnaires: AssessmentQuestionnaireCreateContract[]
}

export interface ManagerAssessmentAssessedPositionQuestionnaireCreateContract {
    /** Идентификатор опросного листа самооценки */
    selfQuestionnaireId: number

    /** Идентификатор опросного листа респондента */
    managerQuestionnaireId: number

    /** Идентификатор компетенции */
    competenceId: number

    /** Идентификатор профиля должности */
    positionProfileId: number
}

export interface ManagerAssessmentAssessedPositionCreateContract {
    /** Идентификатор должности */
    positionId: number

    /** Идентификатор организации */
    structuralUnitId: number

    /** Номер оцениваемой должности */
    assessedPositionNumber: number

    /** Содержит вопросники для самооценки */
    hasSelfQuestionnaires: boolean

    /** Контракт для создания связи портфеля оценки с опросными листами для самооценки и руководителя */
    questionnaires: ManagerAssessmentAssessedPositionQuestionnaireCreateContract[]
}

export interface AssessmentPortfolioPresentationWelcomeWindowParametersCreateContract {
    /** Показывать обращение к пользователю */
    showGreeting: boolean

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст приветствия */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /** Изображение приветственного окна */
    pictureId: number
}

export interface AssessmentPortfolioPresentationFinalWindowParametersCreateContract {
    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст финального обращения */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /** Изображение финального окна */
    pictureId: number
}

export interface AssessmentPortfolioPresentationCreateContract {
    /**  */
    welcomeWindow: AssessmentPortfolioPresentationWelcomeWindowParametersCreateContract

    /**  */
    finalWindow: AssessmentPortfolioPresentationFinalWindowParametersCreateContract

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Отображать прогресс прохождения */
    showPassingProgress: boolean

    /** Показывать вопросы по блокам */
    showQuestionsByBlock: boolean

    /** Изображение прохождения */
    passingPictureId: number
}

export interface AssessmentPortfolioSaveContract {
    /**  */
    documentStatus: DocumentStatus

    /** Идентификатор обновляемой сущности */
    id: number

    /** Наименование портфеля оценки */
    name: string

    /** Описание портфеля оценки */
    description: string

    /** Идентификатор цели оценки */
    purposeId: number

    /** Должности */
    positionIds: number[]

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Организация */
    structuralUnitIds: number[]

    /**  */
    type: AssessmentType

    /** Идентификатор прикрепленного файла */
    attachmentIds: number[]

    /**  */
    circleParameters: AssessmentPortfolioCircleParametersCreateContract

    /**  */
    expertParameters: AssessmentPortfolioExpertParametersCreateContract

    /** Параметры для создания портфеля оценки с типом - оценка руководителем */
    managerParameters: ManagerAssessmentAssessedPositionCreateContract[]

    /**  */
    presentation: AssessmentPortfolioPresentationCreateContract

    /** Идентификатор родительской сущности */
    parentId: number
}

export interface AssessmentPortfolioPublishContract {
    /**  */
    documentStatus: DocumentStatus

    /** Идентификатор обновляемой сущности */
    id: number

    /** Наименование портфеля оценки */
    name: string

    /** Описание портфеля оценки */
    description: string

    /** Идентификатор цели оценки */
    purposeId: number

    /** Должности */
    positionIds: number[]

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Организация */
    structuralUnitIds: number[]

    /**  */
    type: AssessmentType

    /** Идентификатор прикрепленного файла */
    attachmentIds: number[]

    /**  */
    circleParameters: AssessmentPortfolioCircleParametersCreateContract

    /**  */
    expertParameters: AssessmentPortfolioExpertParametersCreateContract

    /** Параметры для создания портфеля оценки с типом - оценка руководителем */
    managerParameters: ManagerAssessmentAssessedPositionCreateContract[]

    /**  */
    presentation: AssessmentPortfolioPresentationCreateContract

    /** Идентификатор родительской сущности */
    parentId: number
}

export interface AssessmentPortfolioSearchContract {
    /** Наименование портфеля оценки */
    name: string

    /**  */
    type: AssessmentType

    /** Статусы документа */
    statuses: DocumentStatus[]

    /** Наименование цели оценки */
    purposeId: number

    /** Показывать только архивные записи */
    showArchive: boolean

    /** Дата изменения, начало диапазона */
    changeDateStart: string

    /** Дата изменения, конец диапазона */
    changeDateEnd: string

    /** Идентификаторы организаций */
    structuralUnitIds: number[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface AssessmentPortfolioSearchResultContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Организации */
    organizations: DictionaryContract[]

    /** Дата изменения */
    changeDate: Date

    /**  */
    type: AssessmentType

    /**  */
    documentStatus: DocumentStatus

    /** Статус активности записи */
    active: boolean

    /** Наименование цели оценки */
    purposeName: string

    /** Управленческие компетенции */
    managerCompetencies: DictionaryContract[]

    /** Наименования активных назначений */
    appointmentNames: string[]
}

export interface AssessmentPortfolioSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AssessmentPortfolioSearchResultContract[]
}

export interface AssessmentPortfolioRolesContract {
    /** Количество определенной роли */
    rolesCount: number

    /**  */
    role: AssessmentRole
}

export interface AssessmentPortfolioByIdsResultContract {
    /** Идентификатор портфеля оценки */
    id: number

    /** Наименование портфеля оценки */
    name: string

    /**  */
    circleParameters: AssessmentPortfolioCircleParametersContract
}

export interface AssessmentPortfolioSearchForModalContract {
    /** Наименование */
    name: string

    /**  */
    type: AssessmentType

    /** Статусы документа */
    statuses: DocumentStatus[]
}

export interface AssessmentPortfolioForModalContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string
}

export interface AssessmentPortfolioForModalContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AssessmentPortfolioForModalContract[]
}

export interface AssessmentProjectSearchContract {
    /** Наименование проекта оценки */
    name: string

    /** Статусы */
    statuses: AppointmentStatus[]

    /**  */
    assessmentType: AssessmentType

    /** Отвественное лицо */
    changeUserIds: number[]

    /** Дата начала */
    dateFrom: Date

    /** Дата окончания */
    dateTo: Date

    /** Номер оценки */
    requestNumber: string

    /** Показывать архивные записи */
    showArchive: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface AssessmentProjectSearchResultContract {
    /** Идентификатор проекта оценки */
    id: number

    /** Наименование проекта оценки */
    name: string

    /** Ответственное лицо */
    changeUser: string

    /**  */
    assessmentType: AssessmentType

    /** Номер заявки */
    requestNumber: string

    /** Дата начала */
    dateFrom: Date

    /** Дата окончания */
    dateTo: Date

    /**  */
    status: AppointmentStatus

    /** Организации */
    structuralUnits: DictionaryContract[]

    /** Активна\/удалена запись */
    active: boolean
}

export interface AssessmentProjectSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AssessmentProjectSearchResultContract[]
}

export interface AssessmentProjectPortfolioQuestionnariesContract {
    /**  */
    selfQuestionnaire: DictionaryContract

    /**  */
    respondentQuestionnaire: DictionaryContract

    /**  */
    competence: DictionaryContract
}

export interface AssessmentProjectCircleAppointmentAssessmentContract {
    /** Идентификатор назначения оценки */
    id: number

    /**  */
    circleParameters: AppointmentAssessmentCircleParametersContract
}

export interface AssessmentProjectCircleAppointmentsContract {
    /** Идентификатор портфеля оценки */
    portfolioId: number

    /** Наименование портфеля оценки */
    portfolioName: string

    /** Связь с опросными листами */
    portfolioQuestionnaires: AssessmentProjectPortfolioQuestionnariesContract[]

    /** Назначения оценки портфеля оценки */
    appointmentsAssessment: AssessmentProjectCircleAppointmentAssessmentContract[]
}

export interface AssessmentProjectCompetenceContract {
    /**  */
    competenceGroup: DictionaryContract

    /**  */
    competence: DictionaryContract

    /** Профиль должности */
    positionProfileId: number

    /** Опросный лист сформирован */
    hasQuestionnaire: boolean
}

export interface AssessmentProjectManagerAppointmentAssessmentContract {
    /**  */
    candidate: AppointmentUserContract

    /** Наименование должности из профиля должности */
    positionName: string

    /** Компетенции объекта оценки */
    candidateCompetencies: AssessmentProjectCompetenceContract[]

    /** Самооценка */
    selfAssessment: boolean

    /** Номер оцениваемой должности */
    assessedPositionNumber: number
}

export interface AssessmentProjectManagerAppointmentsContract {
    /** Идентификатор назначения оценки */
    assessmentId: number

    /**  */
    portfolio: DictionaryContract

    /**  */
    manager: AppointmentUserContract

    /** Компетенции руководителя */
    managerCompetencies: AssessmentProjectCompetenceContract[]

    /** Проверяющие */
    checkingPersons: AppointmentUserContract[]

    /** Назначения оценки */
    candidateParameters: AssessmentProjectManagerAppointmentAssessmentContract[]
}

export interface AssessmentProjectContract {
    /** Идентификатор проекта оценки */
    id: number

    /** Наименование проекта */
    name: string

    /** Номер заявки */
    requestNumber: string

    /**  */
    assessmentType: AssessmentType

    /**  */
    status: AppointmentStatus

    /** Дата начала проекта */
    dateFrom: Date

    /** Дата окончания проекта */
    dateTo: Date

    /** Организации */
    structuralUnits: DictionaryContract[]

    /** Периодичность напоминаний */
    periodicalNotification: boolean

    /**  */
    notificationIntervals: NotificationIntervals

    /** Дней до начала уведомлений */
    daysToStartNotification: number

    /** Дней до окончания уведомлений */
    daysToEndNotification: number

    /**  */
    purpose: DictionaryContract

    /** Назначения оценки типа 360 */
    circleAppointments: AssessmentProjectCircleAppointmentsContract[]

    /**  */
    managerParameters: AssessmentProjectManagerAppointmentsContract
}

export interface AssessmentProjectCircleParametersPublishContract {
    /** Идентификатор назначения оценки */
    assessmentId: number

    /** Идентификатор оцениваемого */
    candidateId: number

    /**  */
    circleParameters: AppointmentAssessmentCircleParametersCreateContract
}

export interface AssessmentProjectCircleAppointmentPublishContract {
    /** Идентификатор портфеля оценки */
    portfolioId: number

    /** Назначения оценок */
    appointmentAssessments: AssessmentProjectCircleParametersPublishContract[]
}

export interface AssessmentProjectCompetencePublishContract {
    /** Идентификатор компетенции */
    competenceId: number

    /** Идентификатор профиля должности */
    positionProfileId: number
}

export interface AssessmentProjectManagerParameterCandidatePublishContract {
    /** Идентификатор сотрудника */
    candidateId: number

    /** Идентификаторы компетенций */
    competences: AssessmentProjectCompetencePublishContract[]

    /** Необходима ли самооценка */
    selfAssessment: boolean

    /** Номер оцениваемой должности */
    assessedPositionNumber: number
}

export interface AssessmentProjectManagerParametersPublishContract {
    /** Идентификатор назначения оценки */
    assessmentId: number

    /** Идентификатор руководителя */
    managerId: number

    /** Идентификаторы компетенций руководителя */
    managerCompetenceIds: number[]

    /** Идентификаторы проверяющих */
    checkingPersonIds: number[]

    /** Сотрудники */
    candidateParameters: AssessmentProjectManagerParameterCandidatePublishContract[]
}

export interface AssessmentProjectPublishContract {
    /** Идентификатор проекта оценки */
    id: number

    /** Наименование проекта */
    name: string

    /** Номер заявки */
    requestNumber: string

    /**  */
    assessmentType: AssessmentType

    /** Дата начала проекта */
    dateFrom: Date

    /** Дата окончания проекта */
    dateTo: Date

    /** Организации */
    structuralUnitIds: number[]

    /** Периодичность напоминаний */
    periodicalNotification: boolean

    /**  */
    notificationIntervals: NotificationIntervals

    /** Дней до начала уведомлений */
    daysToStartNotification: number

    /** Дней до окончания уведомлений */
    daysToEndNotification: number

    /** Идентификатор портфеля оценки */
    portfolioId: number

    /** Идентификатор цели оценки */
    purposeId: number

    /** Портфели оценок */
    circleAppointments: AssessmentProjectCircleAppointmentPublishContract[]

    /**  */
    managerParameters: AssessmentProjectManagerParametersPublishContract
}

export interface AssessmentProjectAppointmentSaveContract {
    /** Идентификатор назначения оценки */
    assessmentId: number

    /** Идентификатор оцениваемого */
    candidateId: number

    /**  */
    circleParameters: AppointmentAssessmentCircleParametersCreateContract
}

export interface AssessmentProjectCircleAppointmentSaveContract {
    /** Идентификатор портфеля оценки */
    portfolioId: number

    /** Назначения оценок */
    appointmentAssessments: AssessmentProjectAppointmentSaveContract[]
}

export interface AssessmentProjectManagerParametersSaveContract {
    /** Идентификатор назначения оценки */
    assessmentId: number

    /** Идентификатор руководителя */
    managerId: number

    /** Идентификаторы компетенций руководителя */
    managerCompetenceIds: number[]

    /** Идентификаторы проверяющих */
    checkingPersonIds: number[]

    /** Сотрудники */
    candidateParameters: AssessmentProjectManagerParameterCandidatePublishContract[]
}

export interface AssessmentProjectSaveContract {
    /** Идентификатор */
    id: number

    /** Наименование проекта */
    name: string

    /** Номер заявки */
    requestNumber: string

    /**  */
    assessmentType: AssessmentType

    /** Дата начала проекта */
    dateFrom: Date

    /** Дата окончания проекта */
    dateTo: Date

    /** Организации */
    structuralUnitIds: number[]

    /** Периодичность напоминаний */
    periodicalNotification: boolean

    /**  */
    notificationIntervals: NotificationIntervals

    /** Дней до начала уведомлений */
    daysToStartNotification: number

    /** Дней до окончания уведомлений */
    daysToEndNotification: number

    /** Идентификатор портфеля оценки */
    portfolioId: number

    /** Идентификатор цели оценки */
    purposeId: number

    /** Портфели оценок */
    circleAppointments: AssessmentProjectCircleAppointmentSaveContract[]

    /**  */
    managerParameters: AssessmentProjectManagerParametersSaveContract
}

export interface AssessmentProjectGenerateQuestionnairesContract {
    /** Идентификатор */
    id: number

    /** Наименование проекта */
    name: string

    /** Номер заявки */
    requestNumber: string

    /**  */
    assessmentType: AssessmentType

    /** Дата начала проекта */
    dateFrom: Date

    /** Дата окончания проекта */
    dateTo: Date

    /** Организации */
    structuralUnitIds: number[]

    /** Периодичность напоминаний */
    periodicalNotification: boolean

    /**  */
    notificationIntervals: NotificationIntervals

    /** Дней до начала уведомлений */
    daysToStartNotification: number

    /** Дней до окончания уведомлений */
    daysToEndNotification: number

    /** Идентификатор портфеля оценки */
    portfolioId: number

    /** Идентификатор цели оценки */
    purposeId: number

    /**  */
    managerParameters: AssessmentProjectManagerParametersSaveContract
}

export interface AssessmentProjectGenerateQuestionnairesResultContract {
    /** Идентификатор проекта оценки */
    projectId: number

    /** Идентификатор оценки */
    assessmentId: number
}

export interface AssessmentProjectPositionProfileContract {
    /**  */
    candidate: AppointmentUserContract

    /** Наименование должности из профиля должности */
    positionName: string

    /** Компетенции объекта оценки */
    candidateCompetencies: AssessmentProjectCompetenceContract[]
}

export interface ManagerReportObjectInformationContract {
    /**  */
    candidate: DictionaryContract

    /**  */
    organization: DictionaryContract

    /**  */
    unit: DictionaryContract

    /**  */
    position: DictionaryContract
}

export interface ManagerReportSpiderWebContract {
    /** Компетенция */
    competenceId: number

    /** Наименование компетенции */
    competenceName: string

    /** Результатат оценки руководителя */
    managerResult: number

    /** Результат самооценки */
    selfAssessmentResult: number

    /** Значение из профиля должности */
    positionProfileLevel: number
}

export interface ManagerReportIndicatorResultContract {
    /** Идентификатор индикатора */
    indicatorId: number

    /** Наименование индикатора */
    indicatorName: string

    /** Результатат оценки руководителя */
    managerResult: number

    /** Результат самооценки */
    selfAssessmentResult: number

    /** Значение из профиля должности */
    positionProfileLevel: number
}

export interface ManagerReportCompetenceResultContract {
    /** Компетенция */
    competenceId: number

    /** Наименование компетенции */
    competenceName: string

    /** Результатат оценки руководителя */
    managerResult: number

    /** Результат самооценки */
    selfAssessmentResult: number

    /** Значение из профиля должности */
    positionProfileLevel: number

    /** Результаты расчётов по индикаторам */
    indicatorResults: ManagerReportIndicatorResultContract[]
}

export interface CompliancePositionProfileContract {
    /** Соответствие профилю должности */
    compliance: number

    /** Превышение профиля  должности */
    exceedingPositionProfile: number

    /**  */
    result: CompliancePositionProfile
}

export interface ManagerReportComplianceProfileContract {
    /**  */
    selfEstimation: CompliancePositionProfileContract

    /**  */
    managerEstimation: CompliancePositionProfileContract
}

export interface ManagerReportCompetenciesByPriorityContract {
    /**  */
    priority: StrongSidesAndZonesOfDevelopmentPriority

    /** Компетенции */
    competencies: DictionaryContract[]
}

export interface AssessmentReportSearchContract {
    /** Идентификатор организации\/подразделения */
    structuralUnitIds: number[]

    /** Идентификатор сотрудника */
    candidateIds: number[]

    /** Идентификатор долности */
    positionIds: number[]

    /**  */
    type: AssessmentType

    /** Дата завершения */
    endDate: Date

    /** Наименование назначения оценки */
    appointmentName: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface AssessmentReportSearchResultContract {
    /** Идентификатор отчёта */
    id: number

    /**  */
    type: AssessmentType

    /** Дата создания */
    creationDate: Date

    /** Доступен сотруднику */
    availableForCandidate: boolean

    /** Организация */
    organizationName: string

    /** Подразделение */
    unitName: string

    /** Должность */
    positionName: string

    /** ФИО сотрудника */
    candidateFio: string

    /**  */
    appointmentAssessment: DictionaryContract

    /** Дата завершения назначения оценки */
    endDate: Date
}

export interface AssessmentReportSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: AssessmentReportSearchResultContract[]
}

export interface AssessmentReportContract {
    /** Идентификатор отчёта */
    id: number

    /**  */
    type: AssessmentType

    /** Дата создания */
    creationDate: Date

    /** Доступен сотруднику */
    availableForCandidate: boolean
}

export interface AssessmentReportTemplateSectionContract {
    /** Идентификатор радела */
    id: number

    /** Наименование раздела */
    name: string

    /** Контент раздела */
    content: string

    /**  */
    assessmentType: AssessmentType

    /**  */
    assessmentRole: AssessmentRole

    /** Порядковый номер */
    order: number
}

export interface CatalogItemProductCardContract {
    /** ИД карточки продукта */
    id: number

    /** Наименование карточки продукта */
    name: string

    /**  */
    type: ProductType

    /** Избранное */
    favorites: boolean

    /** Количество тематических блоков в карточке продукта */
    countThemes: number

    /** Срок размещения в каталоге до какой то даты */
    endPlacementDate: Date

    /**  */
    studyingFormat: StudyingFormat

    /** Имеет индикатор, что запись на период обучения по карточке продукта закрыта */
    hasIndicatorBookingClosed: boolean
}

export interface CatalogItemContract {
    /** ИД темы. */
    themeId: number

    /** Название темы. */
    name: string

    /** Список карточек продуктов. */
    productCards: CatalogItemProductCardContract[]

    /** Количество карточек продуктов. */
    countProductCards: number
}

export interface CatalogItemSearchContract {
    /** Наименование продукта */
    name: string

    /** Тематики */
    themeIds: number[]

    /** Типы продукта (форматы продукта) */
    types: ProductType[]

    /** Форматы обучения */
    studyingFormats: StudyingFormat[]

    /** Идентификаторы компетенций */
    competenceIds: number[]
}

export interface CatalogItemProductCardContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: CatalogItemProductCardContract[]
}

export interface CatalogItemProductCardFavoriteResult {
    /** Добален ли продукт в избранное. */
    favorites: boolean
}

export interface CatalogItemProductCardAnnouncementContract {
    /** Идентификатор продукта */
    id: number

    /** Анонс продукта */
    announcement: string
}

export interface ProductCardCourseContract {
    /** Идентификатор курса */
    id: number

    /** Наименование курса */
    name: string

    /** URL запуска курса */
    launchUrl: string

    /**  */
    format: CourseFormat
}

export interface ProductCardTeacherContract {
    /** Идентификатор преподавателя из карточки продукта */
    id: number

    /** ФИО преподавателя */
    fullName: string

    /** Дожность преподавателя */
    position: string

    /** Идентификатор приложения */
    attachmentId: number

    /** Адрес фото преподавателя */
    url: string
}

export interface ProductCardSubSectionContract {
    /** Идентификатор раздела */
    id: number

    /** Наименование раздела */
    name: string

    /** Порядок */
    order: number
}

export interface ProductCardSectionContract {
    /** Идентификатор раздела */
    id: number

    /** Наименование раздела */
    name: string

    /** Порядок */
    order: number

    /** Подразделы */
    subSections: ProductCardSubSectionContract[]
}

export interface ProductCardListenerContract {
    /** Идентификатор */
    id: number

    /**  */
    listener: UserBaseContract

    /**  */
    structuralUnit: DictionaryContract

    /**  */
    parentStructuralUnit: DictionaryContract

    /**  */
    position: DictionaryContract

    /** Идентификатор периода обучения из карточки продукта */
    productCardStudyingPeriodId: number

    /** Слушатель записан на курс */
    isEnrolled: boolean
}

export interface ProductCardStudyingPeriodContract {
    /** Идентификатор периода обучения */
    id: number

    /** Порядок */
    order: number

    /** Дата начала проведения */
    startDate: Date

    /** Дата конца проведения */
    endDate: Date

    /** Количество участников */
    participantCount: number

    /**  */
    responsiblePerson: DictionaryContract

    /**  */
    status: ProductCardEnrollmentStatus

    /** Слушатели записанные на обучение на продукт */
    listeners: ProductCardListenerContract[]
}

export interface CatalogItemProductCardPreviewContract {
    /** Идентификатор */
    id: number

    /**  */
    status: DocumentStatus

    /**  */
    type: ProductType

    /** Наименование */
    name: string

    /** Анонс продукта */
    announcement: string

    /** Доступен запуск продукта из каталога */
    availableToStartFromCatalog: boolean

    /** Доступна запись на обучение */
    bookingAvailable: boolean

    /**  */
    developmentTrack: DictionaryContract

    /**  */
    activity: DictionaryContract

    /**  */
    course: ProductCardCourseContract

    /** Целевая аудитория */
    targetAudience: string

    /** Преподователи */
    teachers: ProductCardTeacherContract[]

    /** Часов(очно) на прохождение */
    intramuralHourCount: number

    /** Часов(дистанционно) на прохождение */
    distantHourCount: number

    /** Дней на прохождение */
    daysCount: number

    /** Задачи, которые решает программа */
    challenges: DictionaryContract[]

    /** Содержание */
    sections: ProductCardSectionContract[]

    /**  */
    graduationDocumentType: GraduationDocumentType

    /**  */
    picture: PictureContract

    /** Добавлена ли в избранное */
    favorites: boolean

    /** Список тематик */
    themes: DictionaryContract[]

    /**  */
    studyingFormat: StudyingFormat

    /** Результаты обучения */
    outcomes: DictionaryContract[]

    /** Доступные периоды обучения */
    studyingPeriods: ProductCardStudyingPeriodContract[]
}

export interface CatalogItemCheckAvailabilityResultContract {
    /** Результат провреки */
    result: boolean
}

export interface CertificateContract {
    /** Идентификатор сущности */
    id: number

    /**  */
    type: CertificateType

    /** Наименование */
    name: string

    /** Номер для отображения */
    number: string

    /** Дата выпуска */
    issueDate: Date

    /**  */
    user: DictionaryContract
}

export interface CertificateSearchContract {
    /**  */
    type: CertificateType

    /** Номер сертификата */
    number: string

    /** Наименование */
    name: string

    /** Дата выдачи */
    issueDate: Date

    /** ИД пользователя */
    userId: number

    /**  */
    kind: CertificateKind

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface CertificateSearchResultContract {
    /** Идентификатор сущности */
    id: number

    /** Идентификатор пользователя */
    userId: number

    /**  */
    type: CertificateType

    /** Номер сертификата */
    number: string

    /** Наименование */
    name: string

    /** Дата выдачи */
    issueDate: Date

    /**  */
    kind: CertificateKind
}

export interface CertificateSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: CertificateSearchResultContract[]
}

export interface CertificateCreateContract {
    /** Наименование */
    name: string

    /** Идентификатор пользователя */
    userId: number

    /** Внешний номер */
    number: string

    /** Дата выпуска */
    issueDate: string
}

export interface CertificateContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: CertificateContract[]
}

export interface NoContentResult {
    /**  */
    statusCode: number
}

export interface ObjectInformationAssessment360Contract {
    /** ФИО объекта оценки */
    candidateFullName: string

    /** Организация */
    organizationName: string

    /** Подразделение */
    unitName: string

    /**  */
    position: DictionaryDto
}

export interface AssessmentRoleInt32KeyValuePair {
    /**  */
    key: AssessmentRole

    /**  */
    value: number
}

export interface GroupsOfRespondentsContract {
    /** Количество респондентов в группах */
    respondents: AssessmentRoleInt32KeyValuePair[]

    /** Всего */
    countAll: number
}

export interface SpiderWebDiagramAssessment360Contract {
    /**  */
    competence: DictionaryContract

    /** Средняя оценка */
    averageScore: number

    /** Средняя оценка по менеджеру */
    averageScoreManager: number

    /** Средняя оценка по коллегам */
    averageScoreCollegue: number

    /** Средняя оценка по подчинённым */
    averageScoreSubordinate: number

    /** Средняя оценка по экспертам */
    averageScoreExpert: number

    /** Средняя оценка по экспертам группы */
    averageScoreGroupExpert: number

    /** Средняя оценка по самооценке */
    averageScoreSelf: number

    /** Средняя оценка по всем ролям без самооценки */
    averageScoreWithoutSelfEstim: number
}

export interface CompetenceRating360Contract {
    /**  */
    competence: DictionaryContract

    /** Сформированность мнений по компетенции */
    opinionFormed: boolean

    /**  */
    consistency: ConsistencyResultType

    /** Средняя оценка */
    averageScore: number

    /** Средняя оценка по менеджеру */
    averageScoreManager: number

    /** Средняя оценка по коллегам */
    averageScoreCollegue: number

    /** Средняя оценка по подчинённым */
    averageScoreSubordinate: number

    /** Средняя оценка по экспертам */
    averageScoreExpert: number

    /** Средняя оценка по экспертам группы */
    averageScoreGroupExpert: number

    /** Средняя оценка по самооценке */
    averageScoreSelf: number

    /** Средняя оценка по всем ролям без самооценки */
    averageScoreWithoutSelfEstim: number
}

export interface CompetenciesLowHighLevel360Contract {
    /** Компетенции высокого уровня */
    highLevelCompetences: DictionaryContract[]

    /** Компетенции низкого уровня */
    lowLevelCompetences: DictionaryContract[]
}

export interface IndicatorResultsReport360Contract {
    /**  */
    indicator: DictionaryContract

    /**  */
    consistencyManager: ConsistencyResultType

    /**  */
    consistencyCollegue: ConsistencyResultType

    /**  */
    consistencySubordinate: ConsistencyResultType

    /**  */
    consistencyExpert: ConsistencyResultType

    /**  */
    consistencyGroupExpert: ConsistencyResultType

    /**  */
    consistencyAllGroups: ConsistencyResultType

    /**  */
    consistency: ConsistencyResultType

    /** Сформированность мнений по менеджеру */
    opinionFormedManager: boolean

    /** Сформированность мнений по коллегам */
    opinionFormedCollegue: boolean

    /** Сформированность мнений по подчинённым */
    opinionFormedSubordinate: boolean

    /** Сформированность мнений по экспертам */
    opinionFormedExpert: boolean

    /** Сформированность мнений по экспертам группы */
    opinionFormedGroupExpert: boolean

    /** Сформированность мнений по всем ролям без самооценки */
    opinionFormedAllGroups: boolean

    /** Средняя оценка */
    averageScore: number

    /** Средняя оценка по менеджеру */
    averageScoreManager: number

    /** Средняя оценка по коллегам */
    averageScoreCollegue: number

    /** Средняя оценка по подчинённым */
    averageScoreSubordinate: number

    /** Средняя оценка по экспертам */
    averageScoreExpert: number

    /** Средняя оценка по экспертам группы */
    averageScoreGroupExpert: number

    /** Средняя оценка по самооценке */
    averageScoreSelf: number

    /** Средняя оценка по всем ролям без самооценки */
    averageScoreWithoutSelfEstim: number
}

export interface RespondentResultsReport360Contract {
    /**  */
    competence: DictionaryContract

    /** Сформированность мнений по компетенции */
    opinionFormed: boolean

    /**  */
    consistency: ConsistencyResultType

    /** Данные по индикаторам */
    indicatorResults: IndicatorResultsReport360Contract[]

    /** Средняя оценка */
    averageScore: number

    /** Средняя оценка по менеджеру */
    averageScoreManager: number

    /** Средняя оценка по коллегам */
    averageScoreCollegue: number

    /** Средняя оценка по подчинённым */
    averageScoreSubordinate: number

    /** Средняя оценка по экспертам */
    averageScoreExpert: number

    /** Средняя оценка по экспертам группы */
    averageScoreGroupExpert: number

    /** Средняя оценка по самооценке */
    averageScoreSelf: number

    /** Средняя оценка по всем ролям без самооценки */
    averageScoreWithoutSelfEstim: number
}

export interface AssessmentsRespondentsIndicatorsReport360Contract {
    /** Роли респондентов, которые надо скрыть для показа */
    rolesToHide: AssessmentRole[]

    /** Результаты оценки групп респондентов по индикаторам компетенций */
    respondentResults: RespondentResultsReport360Contract[]
}

export interface IndicatorValuePairContract {
    /** Имя */
    name: string

    /** Средний бал */
    averageScore: number
}

export interface StrongSidesAndZonesOfDevelopmentReport360Contract {
    /** Сильные стороны */
    strongSides: IndicatorValuePairContract[]

    /** Зоны развития */
    zonesOfDevelopment: IndicatorValuePairContract[]
}

export interface HiddenPossibilitiesReport360Contract {
    /**  */
    competence: DictionaryContract

    /** Индикаторы */
    indicators: IndicatorResultsReport360Contract[]
}

export interface HiddenProblemsReport360Contract {
    /**  */
    competence: DictionaryContract

    /** Индикаторы */
    indicators: IndicatorResultsReport360Contract[]
}

export interface RespondentGroupCommentsContract {
    /** Название группы */
    name: string

    /** Комментарии */
    comments: string[]
}

export interface PercentileReport360Contract {
    /**  */
    competence: DictionaryContract

    /** Средняя оценка по всем ролям без самооценки */
    averageScoreWithoutSelfEstim: number

    /** Процентиль */
    percentile: number
}

export interface IndicatorResultContract {
    /** Ид индкатора */
    indicatorId: number

    /** Имя индикатора */
    indicatorName: string

    /** Ид результатов оценки по компетенции */
    competenceResultId: number

    /**  */
    consistencyManager: ConsistencyResultType

    /**  */
    consistencyCollegue: ConsistencyResultType

    /**  */
    consistencySubordinate: ConsistencyResultType

    /**  */
    consistencyExpert: ConsistencyResultType

    /**  */
    consistencyGroupExpert: ConsistencyResultType

    /**  */
    consistencyAllGroups: ConsistencyResultType

    /** Сформированность мнений по менеджеру */
    opinionFormedManager: boolean

    /** Сформированность мнений по коллегам */
    opinionFormedCollegue: boolean

    /** Сформированность мнений по подчинённым */
    opinionFormedSubordinate: boolean

    /** Сформированность мнений по экспертам */
    opinionFormedExpert: boolean

    /** Сформированность мнений по экспертам группы */
    opinionFormedGroupExpert: boolean

    /** Сформированность мнений по всем ролям без самооценки */
    opinionFormedAllGroups: boolean

    /** Средняя оценка */
    averageScore: number

    /** Средняя оценка по менеджеру */
    averageScoreManager: number

    /** Средняя оценка по коллегам */
    averageScoreCollegue: number

    /** Средняя оценка по подчинённым */
    averageScoreSubordinate: number

    /** Средняя оценка по экспертам */
    averageScoreExpert: number

    /** Средняя оценка по экспертам группы */
    averageScoreGroupExpert: number

    /** Средняя оценка по самооценке */
    averageScoreSelf: number

    /** Средняя оценка по всем ролям без самооценки */
    averageScoreWithoutSelfEstim: number
}

export interface CompetenceResultContract {
    /** Имя компетенции */
    competenceName: string

    /** Ид компетенции */
    competenceId: number

    /** Ид назначения */
    appointmentId: number

    /**  */
    competenceGroup: DictionaryContract

    /** Сформированность мнений по компетенции */
    opinionFormed: boolean

    /** Данные по индикаторам */
    indicatorResults: IndicatorResultContract[]

    /** Средняя оценка */
    averageScore: number

    /** Средняя оценка по менеджеру */
    averageScoreManager: number

    /** Средняя оценка по коллегам */
    averageScoreCollegue: number

    /** Средняя оценка по подчинённым */
    averageScoreSubordinate: number

    /** Средняя оценка по экспертам */
    averageScoreExpert: number

    /** Средняя оценка по экспертам группы */
    averageScoreGroupExpert: number

    /** Средняя оценка по самооценке */
    averageScoreSelf: number

    /** Средняя оценка по всем ролям без самооценки */
    averageScoreWithoutSelfEstim: number
}

export interface CircleReportProgressContract {
    /** Имя группы респондентов */
    name: string

    /** Количeство запланированных опросных листов */
    countPlanned: number

    /** Количeство фактически пройденных опросных листов */
    countFact: number

    /** Сколько процентов пройдено */
    donePercentage: number
}

export interface ConsolidatedCircleReportContract {
    /** Ид (Равно ид назначения) */
    id: number

    /** Имя (Равно имени оценки) */
    name: string

    /** Список результатов по компетенциям */
    competenceResults: CompetenceResultContract[]

    /** Прогресс прохождения опросных листов */
    circleReportProgress: CircleReportProgressContract[]

    /** Отчет доступен объекту оценки */
    availableForCandidate: boolean

    /** Идентификатор отчёта оценки */
    assessmentReportId: number
}

export interface SidesCircleReportContract {
    /** Сильные стороны */
    strongSides: IndicatorValuePairContract[]

    /** Зоны развития */
    zonesOfDevelopment: IndicatorValuePairContract[]

    /** Скрытые возмоности */
    hiddenPossibilities: IndicatorValuePairContract[]

    /** Скрытые проблемы */
    hiddenProblems: IndicatorValuePairContract[]

    /** Комментарии респонденетов */
    comments: RespondentGroupCommentsContract[]

    /** Компетенции для развития */
    competencesForDevelopment: DictionaryContract[]

    /** Отчет доступен объекту оценки */
    availableForCandidate: boolean

    /** Идентификатор отчёта оценки */
    assessmentReportId: number
}

export interface ReportsSearchContract {
    /** Идентификатор пользователя */
    userId: number
}

export interface CircleReportSmallContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /**  */
    assessmentType: AssessmentType

    /** Отчет по компетенциям доступен кандидату */
    competenciesReportAvailableForCandidate: boolean

    /** Сводный отчет доступен кандидату */
    consolidatedReportAvailableForCandidate: boolean

    /** Отчет по слабым\/сильным сторонам доступен кандидату */
    sydesReportAvailableForCandidate: boolean

    /** Идентификатор отчёта оценки */
    assessmentReportId: number

    /** Доступен кандидату */
    isAvailableForCandidate: boolean
}

export interface CompareScoreContract {
    /** Оценка */
    score: number

    /**  */
    assessmentType: AssessmentType

    /** Ид связанного назначения */
    appointmentId: number
}

export interface IndicatorComparingContract {
    /** Идентификатор индикатора */
    id: number

    /** Наименование индикатора */
    name: string

    /** Оценки */
    scores: CompareScoreContract[]
}

export interface CompetenceComparingContract {
    /** Ид компетенции */
    id: number

    /** Наименование компетенции */
    name: string

    /** Индикаторы */
    indicators: IndicatorComparingContract[]

    /** Оценки */
    scores: CompareScoreContract[]

    /** Порядок */
    order: number
}

export interface CompetenceGroupComparingContract {
    /** Ид группы */
    id: number

    /** Наименование группы */
    name: string

    /** Дочерние элементы */
    competences: CompetenceComparingContract[]

    /** Порядок */
    order: number
}

export interface CircleReportsAccessContract {
    /** Идентификатор назначения */
    appointmentId: number

    /**  */
    type: CircleReportType
}

export interface CompetenceGroupContract {
    /** Количество компетенций, входящих в группу */
    competenceCount: number

    /** Описание группы компетенций */
    description: string

    /**  */
    status: DocumentStatus

    /** Группа компетенций по умолчанию */
    defaultGroup: boolean

    /** Идентификатор уровня должности */
    levels: DictionaryContract[]

    /** Список компетенций */
    competencies: DictionaryContract[]

    /** Управленческая группа */
    isManager: boolean

    /** Идентификатор */
    id: number

    /** Наименование */
    name: string
}

export interface CompetenceSmallContract {
    /** Ид */
    id: number

    /** Имя */
    name: string

    /** Индикаторы */
    indicators: DictionaryContract[]
}

export interface CompetenceGroupWithCompetenciesContract {
    /** Ид */
    id: number

    /** Имя */
    name: string

    /** Компетенции */
    competencies: CompetenceSmallContract[]
}

export interface CompetenceGroupCreateContract {
    /** Идентификатор */
    id: number

    /** Наименование группы */
    name: string

    /** Описание группы компетенций */
    description: string

    /** Группа по умолчанию */
    defaultGroup: boolean

    /** Идентификатор уровня должности */
    levelIds: number[]

    /** Управленческая группа */
    isManager: boolean
}

export interface SetPositionArgsContract {
    /** Идентификатор родителя объекта */
    parentId: number

    /** Ид объекта под который следует переместить объект */
    moveUnderId: number

    /** Ид объекта который следует переметить */
    toMoveId: number
}

export interface CompetenciesUploadResultContract {
    /** Добавлено групп */
    groupsAdded: number

    /** Добавлено компетенций */
    competenciesAdded: number

    /** Добавлено индикаторов */
    indicatorsAdded: number
}

export interface CompetenciesSearchContract {
    /** Наименование компетенции */
    competenceName: string

    /** Наименование группы компетенции */
    competenceGroupName: string

    /** Дата изменения с */
    changingDateFrom: string

    /** Дата изменения по */
    changingDateTo: string

    /** Показывать архивные записи */
    showArchive: boolean

    /** Статусы компетении */
    statuses: DocumentStatus[]

    /** Исключить статусы */
    excludeStatuses: DocumentStatus[]

    /** Идентификаторы департаментов */
    departmentIds: number[]

    /** Идентификаторы функциональный классов(типов деятельности) */
    functionClassIds: number[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface CompetenceContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: CompetenceContract[]
}

export interface CompetenciesSearchForModalContract {
    /** Наименование компетенции */
    competenceName: string

    /** Наименование группы компетенции */
    competenceGroupName: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface CompetenceForModalContract {
    /** Идентификатор компетенции */
    id: number

    /** Наименование компетенции */
    name: string

    /**  */
    competenceGroup: DictionaryContract
}

export interface CompetenceForModalContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: CompetenceForModalContract[]
}

export interface CompetenceIndicatorCreateContract {
    /** Порядок сортировки */
    order: number

    /** Идентификатор индикатора */
    indicatorId: number
}

export interface CompetenceCreateContract {
    /** Идентификатор компетенции */
    id: number

    /** Наименование компетенции */
    name: string

    /** Идентификатор группы компетенций */
    competenceGroupId: number

    /** Идентификаторы индикаторов */
    indicators: CompetenceIndicatorCreateContract[]

    /** Департамент */
    departmentId: number

    /** Идентификаторы типов деятельности */
    functionClassIds: number[]

    /** Описание */
    description: string
}

export interface PositionProfileCompetenceGroupContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Порядок */
    order: number
}

export interface PositionProfileCompetenceContract {
    /** Числовое значение уровня компетенции */
    competenceLevel: number

    /** Ид департамента */
    departmentId: number

    /**  */
    competenceGroup: PositionProfileCompetenceGroupContract

    /** Описание */
    description: string

    /** Смежная компетенция */
    isAdjacent: boolean

    /** Порядок */
    order: number

    /** Идентификатор */
    id: number

    /** Наименование */
    name: string
}

export interface CourseVersionContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Номер версии курса */
    courseVersion: number

    /** Дата загрузки */
    uploadDate: Date

    /** Основная версия */
    isMain: boolean

    /** Флаг активности записи */
    active: boolean
}

export interface CourseFullContract {
    /** Идентификатор курса */
    id: number

    /** Наименование курса */
    name: string

    /** URL запуска курса */
    launchUrl: string

    /**  */
    status: DocumentStatus

    /** Дата загрузки */
    uploadDate: Date

    /** Версия курса */
    courseVersion: number

    /** Основная версия */
    isMain: boolean

    /**  */
    structuralUnit: DictionaryContract

    /** Формат курса */
    format: string

    /** Размер файла в мегабайтах */
    fileSize: number

    /**  */
    author: DictionaryContract

    /** Ключ */
    key: string

    /** Комментарий */
    comment: string

    /** Версии курса */
    relatedCourseVersions: CourseVersionContract[]

    /**  */
    rootCourseVersion: DictionaryContract

    /** Организация */
    organization: string

    /** Электронный адрес */
    email: string

    /** Номер телефона */
    phoneNumber: string

    /** Наименование файла курса */
    fileName: string

    /** Хэш файла */
    fileHash: string

    /** Идентификатор файла */
    identifier: string

    /** Структура курса */
    topics: DictionaryContract[]
}

export interface CourseSearchContract {
    /** Наименование */
    name: string

    /**  */
    format: CourseFormat

    /** Дата загрузки, начало диапазона */
    uploadDateStart: string

    /** Дата загрузки, конец диапазона */
    uploadDateEnd: string

    /** Дата загрузки, для поиска в ForModal */
    uploadDate: string

    /** Статусы */
    statuses: DocumentStatus[]

    /** Ид владельцев (StructuralUnit) */
    owners: number[]

    /** Типы курса */
    types: CourseFormat[]

    /** Исключить форматы из поиска */
    excludeFormats: CourseFormat[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface CourseSearchResultContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /**  */
    format: CourseFormat

    /** Описание формата курса */
    formatDescription: string

    /**  */
    status: DocumentStatus

    /**  */
    structuralUnit: DictionaryContract

    /** Дата загрузки текущей версии */
    uploadDate: Date

    /** Версия */
    courseVersion: number

    /** URL запуска курса */
    launchUrl: string

    /** Флаг активности записи */
    active: boolean
}

export interface CourseSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: CourseSearchResultContract[]
}

export interface CourseForModalContract {
    /** Идентификатор курса */
    id: number

    /** Наименование курса */
    name: string

    /**  */
    format: CourseFormat

    /** Описание формата курса */
    formatDescription: string

    /** Дата загрузки */
    uploadDate: Date
}

export interface CourseForModalContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: CourseForModalContract[]
}

export interface CourseInfoSaveContract {
    /** Ид курса */
    id: number

    /** Наименование */
    name: string

    /** Комментарий */
    comment: string

    /** Ид структурного подразделения (Владельца) */
    structuralUnitId: number

    /** Организация */
    organization: string

    /** Электронный адрес */
    email: string

    /** Ключ */
    key: string

    /** Номер телефона */
    phoneNumber: string
}

export interface CourseContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: CourseContract[]
}

export interface CourseAsyncUploadContract {
    /** Хеш файла */
    fileHash: string

    /** Наименование файла */
    fileName: string

    /** Наименование */
    name: string

    /** Ид владельца */
    structuralUnitId: number

    /** Ключ */
    key: string

    /** Комментарий */
    comment: string

    /** Идентификатор первой версии курса */
    rootCourseVersionId: number
}

export interface CourseValidationContract {
    /**  */
    case: CourseValidationCaseContract

    /** Идентификатор загруженного курса */
    courseId: number

    /**  */
    processingUser: UserBaseContract
}

export interface CourseFileInfoContract {
    /** Ключ объекта в хранилище */
    objectKey: string

    /** Ссылка на объект для загрузки в хранилище */
    putUrl: string

    /** Тип контента */
    contentType: string
}

export interface CourseUploadIdContract {
    /** Ключ файла */
    fileObjectKey: string
}

export interface CourseAppointmentContract {
    /** Идентификатор */
    appointmentId: number

    /** Наименование */
    appointmentName: string

    /** Наименование трека\/мероприятия */
    relatedEntityName: string

    /** Дата назначения */
    startDate: Date

    /**  */
    course: CourseVersionContract
}

export interface UserNotificationSettingContract {
    /**  */
    notificationType: NotificationType

    /** тип оповещения */
    type: string

    /** идентификатор темы оповещения */
    themeId: number

    /**  */
    notificationThemeType: NotificationThemeType

    /** тема оповещения */
    theme: string

    /** Оповещения приходят в личный кабинет */
    isPersonalArea: boolean

    /** Оповещения приходят на почту */
    isEmail: boolean
}

export interface UserNotificationSettingSaveContract {
    /** идентификатор темы оповещения */
    themeId: number

    /** Оповещения приходят в личный кабинет */
    isPersonalArea: boolean

    /** Оповещения приходят на почту */
    isEmail: boolean
}

export interface DevelopmentGoalCreateContract {
    /** Наименование цели развития */
    name: string

    /** Идентификаторы компетенций */
    competenceIds: number[]
}

export interface DevelopmentGoalContract {
    /** Идентификатор цели развития */
    id: number

    /** Наименование цели развития */
    name: string

    /** Дата изменения */
    changeDate: Date

    /** Компетенции */
    competences: DictionaryContract[]
}

export interface DevelopmentGoalsSearchContract {
    /** Наименование цели развития */
    name: string

    /** Идентификаторы компетенций */
    competenceIds: number[]

    /** Дата изменения, начало диапазона */
    changeDateStart: string

    /** Дата изменения, конец диапазона */
    changeDateEnd: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface DevelopmentGoalsSearchResultContract {
    /** Идентификатор цели развития */
    id: number

    /** Наименование цели развития */
    name: string

    /** Наименования компетенций */
    competences: DictionaryContract[]

    /** Дата изменения */
    changeDate: Date
}

export interface DevelopmentGoalsSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: DevelopmentGoalsSearchResultContract[]
}

export interface DevelopmentTrackStageCreateContract {
    /** ИД заданий */
    homeworksIds: number[]

    /** Идентификатор этапа */
    id: number

    /** Номер этапа трека адаптации */
    stageNumber: number

    /** Отображать прогресс прохождения этапа */
    showProgress: boolean

    /** Отображать иконку в содержании */
    showContentPicture: boolean

    /** Идентификатор иконки в содержании */
    contentIconId: number

    /** Заголовок этапа */
    title: string

    /** Обязательность прохождения */
    isPassingRequired: boolean

    /** События помощника */
    assistantEvents: TrackStageAssistantEventContract[]

    /** Мероприятие */
    activityId: number

    /** Обучающие материалы */
    courseId: number

    /** Ограничение количество попыток прохождения мероприятия */
    activityAttemptCount: number

    /** Ограничено ли количество попыток прохождения мероприятия */
    isActivityAttemptCountLimited: boolean

    /**  */
    materialType: TrackStageMaterialType
}

export interface DevelopmentTrackSaveContract {
    /** Этапы трека адаптации */
    stages: DevelopmentTrackStageCreateContract[]

    /** ИД целей */
    goalIds: number[]

    /** Сертификация */
    isCertification: boolean

    /** Идентификатор родительской сущности */
    parentId: number

    /** Идентификатор трека */
    id: number

    /** Наименование трека */
    name: string

    /** Заголовок */
    title: string

    /** Описание */
    description: string

    /** Идентификатор опросного листа */
    questionnaireId: number

    /** Использует ли трек визуализацию */
    isTrackWithVisualisation: boolean

    /** Организации */
    organizationIds: number[]

    /** должности */
    positionIds: number[]

    /**  */
    passingType: PassingType

    /** Использовать информационного помощника */
    useInformationAssistant: boolean

    /**  */
    informationAssistant: TrackAssistantCreateContract

    /** Дополнительные материалы */
    additionals: TrackAdditionalsCreateContract[]

    /** Холсты */
    trackCanvases: TrackCanvasCreateContract[]

    /**  */
    trackProgressVisualization: TrackProgressVisualizationCreateContract

    /**  */
    trackMenuVisualization: TrackMenuVisualizationCreateContract

    /** Иконки заголовка трека */
    trackHeaderIcons: TrackHeaderIconCreateContract[]

    /** Структура трека зарегистрирована в курсометре */
    coursometerStructureRegistered: boolean

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean

    /** ИД изображение фона трека */
    trackBackgroundPictureId: number

    /** Идентификатор отображения трека на вкладке "Ваше развитие" */
    trackYourDevelopmentDisplayId: number
}

export interface DevelopmentTrackStageContract {
    /** Задания */
    works: DictionaryContract[]

    /** Идентификатор этапа */
    id: number

    /** Заголовок этапа */
    title: string

    /** Номер этапа трека адаптации */
    stageNumber: number

    /** Обязательность прохождения */
    isPassingRequired: boolean

    /** Отображать прогресс прохождения этапа */
    showProgress: boolean

    /** События помощника */
    assistantEvents: TrackStageAssistantEventContract[]

    /** Отображать иконку в содержании */
    showContentPicture: boolean

    /**  */
    contentIcon: AttachmentContract

    /**  */
    course: DictionaryContract

    /** Ограничение количество попыток прохождения мероприятия */
    activityAttemptCount: number

    /** Ограничено ли количество попыток прохождения мероприятия */
    isActivityAttemptCountLimited: boolean

    /**  */
    activity: DictionaryContract

    /** Общее количество тематических блоков */
    totalThematicBlocksCount: number

    /**  */
    materialType: TrackStageMaterialType
}

export interface DevelopmentTrackContract {
    /** Список целей */
    goals: DictionaryContract[]

    /** Этапы трека */
    stages: DevelopmentTrackStageContract[]

    /** Сертификация */
    isCertification: boolean

    /** Идентификатор трека адаптации */
    id: number

    /** Наименование трека */
    name: string

    /** Использует ли трек визуализацию */
    isTrackWithVisualisation: boolean

    /** Заголовок */
    title: string

    /** Описание */
    description: string

    /**  */
    questionnaire: DictionaryContract

    /**  */
    passingType: PassingType

    /** Организации */
    organizations: DictionaryContract[]

    /** Должности */
    positions: DictionaryContract[]

    /** Использовать информационного помощника */
    useInformationAssistant: boolean

    /**  */
    informationAssistant: TrackAssistantContract

    /**  */
    status: DocumentStatus

    /** Дополнительные материалы */
    additionals: TrackAdditionalContract[]

    /** Холсты */
    trackCanvases: TrackCanvasContract[]

    /**  */
    trackProgressVisualization: TrackProgressVisualizationContract

    /**  */
    trackMenuVisualization: TrackMenuVisualizationContract

    /** Иконки заголовка трека */
    trackHeaderIcons: TrackHeaderIconContract[]

    /** Структура трека зарегистрирована в курсометре */
    coursometerStructureRegistered: boolean

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean

    /**  */
    trackBackgroundPicture: PictureContract

    /**  */
    trackYourDevelopmentDisplay: PictureContract
}

export interface DevelopmentTrackPublishContract {
    /** Этапы трека адаптации */
    stages: DevelopmentTrackStageCreateContract[]

    /** ИД целей */
    goalIds: number[]

    /** Сертификация */
    isCertification: boolean

    /** Идентификатор родительской сущности */
    parentId: number

    /** Идентификатор трека */
    id: number

    /** Наименование трека */
    name: string

    /** Заголовок */
    title: string

    /** Описание */
    description: string

    /** Идентификатор опросного листа */
    questionnaireId: number

    /** Использует ли трек визуализацию */
    isTrackWithVisualisation: boolean

    /** Организации */
    organizationIds: number[]

    /** должности */
    positionIds: number[]

    /**  */
    passingType: PassingType

    /** Использовать информационного помощника */
    useInformationAssistant: boolean

    /**  */
    informationAssistant: TrackAssistantCreateContract

    /** Дополнительные материалы */
    additionals: TrackAdditionalsCreateContract[]

    /** Холсты */
    trackCanvases: TrackCanvasCreateContract[]

    /**  */
    trackProgressVisualization: TrackProgressVisualizationCreateContract

    /**  */
    trackMenuVisualization: TrackMenuVisualizationCreateContract

    /** Иконки заголовка трека */
    trackHeaderIcons: TrackHeaderIconCreateContract[]

    /** Структура трека зарегистрирована в курсометре */
    coursometerStructureRegistered: boolean

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean

    /** ИД изображение фона трека */
    trackBackgroundPictureId: number

    /** Идентификатор отображения трека на вкладке "Ваше развитие" */
    trackYourDevelopmentDisplayId: number
}

export interface EntityCheckAvailabilityResultContract {
    /** Результат провреки */
    available: boolean
}

export interface EnumTableContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Код перечисления */
    code: string
}

export interface ExpertEvaluationListContract {
    /** Ид назначения */
    appointmentId: number

    /** Фио эксперта */
    expertName: string

    /** Фио кандидата */
    candidateName: string

    /** Название должности */
    positionName: string

    /** Ид компетенций в оценочном листе */
    competenceIds: number[]
}

export interface ExpertEvaluationListQuestionnaireContract {
    /** Ид */
    questionnaireId: number

    /** Название опросного листа */
    questionnaireName: string

    /** Баллы */
    score: number
}

export interface ExpertEvaluationListIndicatorContract {
    /** Ид индикатора */
    indicatorId: number

    /** Название индикатора */
    indicatorName: string

    /** Сильная\/Слабая стороно */
    isStrongSide: boolean
}

export interface ExpertEvaluationListCompetenceContract {
    /** Ид компетенции */
    competenceId: number

    /** Название компетенции */
    competenceName: string

    /** Итоговый бал по компетенции */
    score: number

    /** Комментарий */
    comment: string

    /** Оценки по опросным листам */
    questionnaireResults: ExpertEvaluationListQuestionnaireContract[]

    /** Оценки по индикаторам */
    indicatorResults: ExpertEvaluationListIndicatorContract[]
}

export interface ExpertEvaluationListQuestionnaireSaveContract {
    /** Ид опросного листа */
    questionnaireId: number

    /** Оценка */
    score: number
}

export interface ExpertEvaluationListIndicatorSaveContract {
    /** Ид индикатора */
    indicatorId: number

    /** Сильная\/Слабая сторона */
    isStrongSide: boolean
}

export interface ExpertEvaluationListCompetenceSaveContract {
    /** Ид назначения */
    appointmentId: number

    /** Ид компетенции */
    competenceId: number

    /** Оценка */
    score: number

    /** Комментарий */
    comment: string

    /** Опросные листы */
    questionnaires: ExpertEvaluationListQuestionnaireSaveContract[]

    /** Индикаторы */
    indicators: ExpertEvaluationListIndicatorSaveContract[]
}

export interface FaceToFaceMeetingJournalSearchContract {
    /** Назначение */
    appointment: string

    /** Встреча */
    meeting: string

    /**  */
    type: AppointmentType

    /** Статусы */
    statuses: FaceToFaceMeetingStatus[]

    /** Показать просроченные */
    isOverdue: boolean

    /** Дата начала С */
    startDateFrom: Date

    /** Дата начала По */
    startDateTo: Date

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface FaceToFaceMeetingJournalRegistryContract {
    /** Идентификатор журнала */
    id: number

    /** Наименование назначения */
    appointmentName: string

    /**  */
    appointmentType: AppointmentType

    /** Наименование встречи */
    meetingName: string

    /** Количество слушателей */
    listenersCount: number

    /** Дата начала */
    beginDate: Date

    /**  */
    status: FaceToFaceMeetingStatus

    /** Признак просроченного назначения */
    isOverdue: boolean
}

export interface FaceToFaceMeetingJournalRegistryContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: FaceToFaceMeetingJournalRegistryContract[]
}

export interface FaceToFaceMeetingListenerContract {
    /** Идентификатор записи слушателя в журнале */
    id: number

    /**  */
    listener: UserBaseContract

    /** Текущая должность пользователя */
    position: string

    /** Зачет\/Незачет */
    isPassed: boolean
}

export interface FaceToFaceMeetingJournalContract {
    /** Идентификатор журнала */
    id: number

    /** Наименование встречи */
    meetingName: string

    /** Слушатели */
    listeners: FaceToFaceMeetingListenerContract[]
}

export interface FaceToFaceMeetingListenerSaveContract {
    /** Идентификатор записи слушателя в журнале */
    id: number

    /** Зачет\/Незачет */
    isPassed: boolean

    /**  */
    listener: UserBaseContract
}

export interface FaceToFaceMeetingJournalSaveContract {
    /** Идентификатор журнала */
    id: number

    /** Слушатели */
    listeners: FaceToFaceMeetingListenerSaveContract[]
}

export interface FeedbackQuizReportTitleContract {
    /** Название опроса */
    title: string

    /** Количество респондентов */
    respondentsCount: number

    /** Количество респондентов завершивших прохождение */
    respondentsFinishedCount: number

    /** Количество респондентов завершивших прохождение (В процентах) */
    respondentsFinishedPercentage: number

    /** Среднее время прохождения в секундах */
    averagePassingTime: number
}

export interface StringStringKeyValuePair {
    /**  */
    key: string

    /**  */
    value: string
}

export interface StringInt32KeyValuePair {
    /**  */
    key: string

    /**  */
    value: number
}

export interface FeedbackQuizReportStatisticsContract {
    /** Процентное соотношение должностей сотрудников участвовавших в прохождении по уровню */
    respondentsPositionLevelPercentage: StringStringKeyValuePair[]

    /** Процентное отношение сотрудников участвовавших в прохождении по стажу */
    respondentsExperiencePercentage: StringStringKeyValuePair[]

    /** Процентное отношение сотрудников участвовавших в прохождении по возрасту */
    respondentsAgePercentage: StringStringKeyValuePair[]

    /** Количество прошедших опрос людей по организациям */
    respondentsGroupedByDepartmentsCount: StringInt32KeyValuePair[]
}

export interface FeedbackQuizReportGraficContract {
    /** Название графика */
    name: string

    /** Значение графика */
    value: number
}

export interface FeedbackQuizReportGraficWithHeadContract {
    /** Заголовок с вопросом (если есть) */
    subHead: string

    /** Графики */
    grafics: FeedbackQuizReportGraficContract[]
}

export interface FeedbackQuizReportGraficTabContract {
    /** Заголовок Среднее значение */
    head: string

    /** Шкала От */
    scaleFrom: number

    /** Шкала До */
    scaleTo: number

    /** Вопросы, ответы с количеством ответов */
    questions: FeedbackQuizReportGraficWithHeadContract[]
}

export interface FeedbackQuizReportQuestionsTableContract {
    /** Организация */
    organization: string

    /** Строки c ФИО и ответами */
    rows: string[]
}

export interface FeedbackQuizReportQuestionsTableContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: FeedbackQuizReportQuestionsTableContract[]
}

export interface FeedbackQuizReportFreeAnswerContract {
    /** Название колонок (вопросы) */
    columns: string[]

    /**  */
    questionsTable: FeedbackQuizReportQuestionsTableContractPageContract
}

export interface FeedbackQuizReportUserElapsedTimeContract {
    /** Идентификатор пользователя */
    userId: number

    /** Имя пользователя */
    userName: string

    /** Затраченное время в секундах */
    elapsedTime: number
}

export interface FeedbackQuizReportElapsedTimeNodeContract {
    /**  */
    organization: StructuralUnitShortContract

    /** Результаты пользователей */
    userResults: FeedbackQuizReportUserElapsedTimeContract[]

    /** Результаты вложенных подразделений */
    children: FeedbackQuizReportElapsedTimeNodeContract[]
}

export interface FeedbackQuizReportsElapsedTimeContract {
    /** Всего пользователей */
    totalUsers: number

    /** Таблица затраченного времени */
    elapsedTimeTable: FeedbackQuizReportElapsedTimeNodeContract[]
}

export interface GlossariesSearchContract {
    /** Наименование статьи */
    name: string

    /** Дата создания, начало диапазона */
    creatingDateStart: string

    /** Дата создания, конец диапазона */
    creatingDateEnd: string

    /** Размер страницы */
    pageSize: number
}

export interface GlossaryArticleContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /**  */
    picture: AttachmentContract

    /** Дата последнего изменения */
    changeDate: Date

    /** Дата создания */
    creationDate: Date

    /** Статья в разработке */
    isDraft: boolean
}

export interface GlossaryArticleContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: GlossaryArticleContract[]
}

export interface GlossarySectionContract {
    /** Идентификатор раздела */
    id: number

    /** Наименование раздела */
    name: string

    /**  */
    articles: GlossaryArticleContractPageContract
}

export interface GlossariesSearchTextContract {
    /** Строка поиска */
    searchText: string

    /** Размер страницы */
    pageSize: number
}

export interface GlossariesSearchBySectionContract {
    /** Идентификатор раздела */
    sectionId: number

    /** Номер страницы */
    pageNumber: number

    /** Наименование статьи */
    name: string

    /** Дата создания, начало диапазона */
    creatingDateStart: string

    /** Дата создания, конец диапазона */
    creatingDateEnd: string

    /** Размер страницы */
    pageSize: number
}

export interface SectionCreateContract {
    /** Наименование раздела */
    name: string
}

export interface SectionUpdateContract {
    /** Идентификатор раздела */
    id: number

    /** Наименование раздела */
    name: string
}

export interface ArticleChapterContract {
    /** Идентификатор блока */
    id: number

    /** Заголовок */
    title: string

    /** Содержание */
    content: string
}

export interface ArticleContract {
    /** Идентификатор статьи */
    id: number

    /** Наименование */
    name: string

    /**  */
    section: DictionaryContract

    /**  */
    picture: AttachmentContract

    /** Содержание */
    chapters: ArticleChapterContract[]

    /**  */
    changeUser: UserBaseContract

    /** Статья в разработке */
    isDraft: boolean
}

export interface ArticleChapterCreateContract {
    /** Заголовок */
    title: string

    /** Содержание */
    content: string
}

export interface ArticleCreateContract {
    /** Блоки статьи */
    chapters: ArticleChapterCreateContract[]

    /** Наименование статьи */
    name: string

    /** Идентификатор раздела */
    sectionId: number

    /** Идентификатор изображения карточки статьи */
    pictureId: number

    /** Статья в разработке */
    isDraft: boolean
}

export interface ArticleChapterUpdateContract {
    /** Идентификатор блока статьи */
    id: number

    /**  */
    title: string

    /**  */
    content: string
}

export interface ArticleUpdateContract {
    /** Идентификатор статьи */
    id: number

    /** Блоки статьи */
    chapters: ArticleChapterUpdateContract[]

    /** Наименование статьи */
    name: string

    /** Идентификатор раздела */
    sectionId: number

    /** Идентификатор изображения карточки статьи */
    pictureId: number

    /** Статья в разработке */
    isDraft: boolean
}

export interface HomeworkCheckSearchContract {
    /** Название трека\/мероприятия */
    name: string

    /** Назначениие */
    appointmentName: string

    /** Дорожный дневник */
    lessonName: string

    /** Проверяющий */
    tutor: string

    /** Идентификатор пользователя */
    userId: number

    /** Дата выполнения С */
    onCheckingDateStart: Date

    /** Дата выполнения По */
    onCheckingDateEnd: Date

    /** Дата начала проверки C */
    checkingStartDateStart: Date

    /** Дата начала проверки По */
    checkingStartDateEnd: Date

    /**  */
    type: AppointmentType

    /** Статусы */
    statuses: HomeworkStatusType[]

    /** Показать просроченные */
    isOverdue: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface HomeworkRegistryItemContract {
    /** Идентификатор домашней работы */
    id: number

    /** Наименование трека\/мероприятия */
    name: string

    /**  */
    type: AppointmentType

    /** Наименование назначения */
    appointmentName: string

    /** Наименование тематического блока */
    lessonName: string

    /** Условие использования редактора */
    isEditorUsed: boolean

    /**  */
    tutor: UserBaseContract

    /**  */
    status: HomeworkStatusType

    /** Идентификатор пользователя */
    userId: number

    /** Дата создания */
    creationDate: Date

    /** Дата перехода задания на проверку */
    onCheckingDate: Date

    /** Дата старта проверки */
    checkingStartDate: Date

    /** Признак просроченного назначения */
    isOverdue: boolean
}

export interface HomeworkRegistryItemContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: HomeworkRegistryItemContract[]
}

export interface HomeworkGetContract {
    /** Название трека\/мероприятия */
    name: string

    /** Назначениие */
    appointmentName: string

    /** Дорожный дневник */
    lessonName: string

    /** Проверяющий */
    tutorId: number

    /** Дата выполнения С */
    onCheckingDateStart: string

    /** Дата выполнения По */
    onCheckingDateEnd: string

    /** Дата начала проверки C */
    checkingStartDateStart: string

    /** Дата начала проверки По */
    checkingStartDateEnd: string

    /**  */
    type: AppointmentType

    /** Показать просроченные */
    isOverdue: boolean

    /** Статусы */
    statuses: HomeworkStatusType[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface HomeworkInfoContract {
    /** Идентификатор домашней работы */
    id: number

    /** Комментарий */
    comment: string

    /** Используется ли lde редактор */
    isEditorUsed: boolean

    /** Дата последнего изменения */
    changeDate: Date

    /** Дата начала прохождения тематического блока */
    lessonStartDate: Date

    /** Дата перехода домашнего задания на проверку */
    onCheckingDate: Date

    /**  */
    status: HomeworkStatusType
}

export interface HomeworkUpdateCommentContract {
    /** Комментарий */
    comment: string
}

export interface HomeworkTaskTutorCheckContract {
    /** Идентификатор домашнего задания */
    homeworkId: number

    /** Идентификатор задания */
    taskId: number

    /** Ответ */
    comment: string

    /**  */
    homeworkStatusType: HomeworkStatusType
}

export interface HomeworkDocumentContract {
    /** Идентификатор домашней работы */
    id: number

    /** Идентификатор документа LDE */
    ldeId: string

    /** Наименование */
    name: string

    /** Контент */
    content: string

    /** Задачи для задания */
    taskPassings: MissionTaskPassingContract[]

    /** Видны ли заголовки подзадач */
    isTasksTitlesVisible: boolean

    /** Идентификатор пользователя */
    userId: number

    /** Признак просроченного назначения */
    isOverdue: boolean

    /** Наименование тематического блока */
    lessonName: string
}

export interface HomeworkCheckResultContract {
    /** Поставлен зачет */
    isPassed: boolean
}

export interface MissionTaskPassingsAsHistoricalByTaskNumberContract {
    /** Номер задачи */
    taskNumber: number

    /** Список "исторических" элементов */
    missionTaskPassings: MissionTaskPassingContract[]
}

export interface UserBaseDto {
    /**  */
    id: number

    /**  */
    firstName: string

    /**  */
    lastName: string

    /**  */
    patronymic: string

    /**  */
    email: string
}

export interface MissionTaskPassingReferenceContract {
    /** Идентификатор */
    id: number

    /** Номер задачи */
    taskNumber: number

    /** Комментарий тьютора */
    comment: string

    /** Ответ на задачу */
    answerText: string

    /** Идентификатор пользователя */
    userId: number

    /**  */
    tutor: UserBaseDto

    /**  */
    status: HomeworkStatusType
}

export interface MissionTaskPassingReferencesByTaskNumberContract {
    /** Номер задачи */
    taskNumber: number

    /** Список "референсов" */
    missionTaskPassingReferences: MissionTaskPassingReferenceContract[]
}

export interface IndicatorsSearchContract {
    /** Наименование индикатора */
    name: string

    /**  */
    status: DocumentStatus

    /** Тип индикатора */
    indicatorTypeId: number

    /** Отображать архивные записи */
    showArchive: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface IndicatorContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: IndicatorContract[]
}

export interface IndicatorCreateContract {
    /** Идентификатор индикатора */
    id: number

    /** Наименование индикатора */
    name: string

    /** Идентификатор типа индикатора */
    indicatorTypeId: number

    /** Описание индикатора */
    description: string
}

export interface IndicatorInfoContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Описание */
    description: string

    /** Порядок */
    order: number
}

export interface LdeReportAddContract {
    /** Наименование документа */
    name: string
}

export interface LdeReportContract {
    /** Идентификатор документа */
    id: number

    /** Идентификатор документа в LDE */
    ldeId: string

    /** Наименование документа */
    name: string

    /** Автор документа */
    author: string

    /** Дата создания */
    creationDate: Date
}

export interface LdeReportSearchContract {
    /** Наименование документа */
    name: string

    /** ФИО автора документа */
    author: string

    /** Дата создания, старт диапазона */
    creationDateStart: string

    /** Дата создания финиш, диапазона */
    creationDateEnd: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface LdeReportContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: LdeReportContract[]
}

export interface LrsActivityStateContract {
    /** Активность пройдена */
    isPassed: boolean
}

export interface MissionTaskSaveContract {
    /** Идентификатор задачи */
    id: number

    /** Номер задачи */
    taskNumber: number

    /** Текст задачи */
    taskText: string
}

export interface MissionSaveContract {
    /** Id задания на обновление */
    id: number

    /** Наименование задания */
    name: string

    /** Описание задания */
    content: string

    /** Задачи для задания */
    missionTasks: MissionTaskSaveContract[]

    /** Видны ли заголовки подзадач */
    isTasksTitlesVisible: boolean
}

export interface MissionTaskContract {
    /** Идентификатор задачи */
    id: number

    /** Номер задачи */
    taskNumber: number

    /** Текст задачи */
    taskText: string
}

export interface MissionContract {
    /** Id миссии на обновление */
    id: number

    /** Наименование задания */
    name: string

    /** Описание задания */
    content: string

    /** Задачи для задания */
    missionTasks: MissionTaskContract[]

    /** Видны ли заголовки подзадач */
    isTasksTitlesVisible: boolean
}

export interface MissionSearchContract {
    /** Наименование задания */
    name: string

    /** ФИО владельца */
    fio: string

    /** Дата создания с */
    dateFrom: Date

    /** Дата создания по */
    dateTo: Date

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface MissionSearchResultContract {
    /** ИД */
    id: number

    /** Наименование задания */
    name: string

    /** ФИФ Автора */
    fullName: string

    /** Дата создания */
    creationDate: Date
}

export interface MissionSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: MissionSearchResultContract[]
}

export interface MissionSearchForModalContract {
    /** Наименование задания */
    name: string
}

export interface DictionaryContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: DictionaryContract[]
}

export interface NotificationDestinationCategoryContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /**  */
    code: NotificationDestinationCategoryCode
}

export interface NotificationDestinationSubCategoryContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /**  */
    code: NotificationDestinationSubCategoryCode
}

export interface NotificationDynamicFieldMarkerSearchContract {
    /** Наименование маркера динамического поля оповещения */
    name: string

    /** Значение для предпросмотра маркера динамического поля оповещения */
    previewValue: string

    /** Показывать архивные записи */
    showArchive: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface NotificationDynamicFieldMarkerSearchResultContract {
    /** Идентификатор маркера динамического поля оповещения */
    id: number

    /** Наименование маркера динамического поля оповещения */
    name: string

    /** Описание маркера динамического поля оповещения */
    description: string

    /** Значение для предпросмотра маркера динамического поля оповещения */
    previewValue: string

    /** Активна\/удалена запись */
    active: boolean
}

export interface NotificationDynamicFieldMarkerSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: NotificationDynamicFieldMarkerSearchResultContract[]
}

export interface NotificationDynamicFieldMarkerSelectDto {
    /**  */
    id: number

    /**  */
    name: string

    /**  */
    previewValue: string
}

export interface NotificationDynamicFieldMarkerCreateOrUpdateContract {
    /** Идентификатор обновляемой сущности */
    id: number

    /** Наименование маркера динамического поля оповещения */
    name: string

    /** Описание маркера динамического поля оповещения */
    description: string

    /** Значение для предпросмотра маркера динамического поля оповещения */
    previewValue: string
}

export interface NotificationDynamicFieldMarkerContract {
    /** Идентификатор маркера динамического поля оповещения */
    id: number

    /** Наименование маркера динамического поля оповещения */
    name: string

    /** Описание маркера динамического поля оповещения */
    description: string

    /** Значение для предпросмотра маркера динамического поля оповещения */
    previewValue: string

    /** Флаг активности записи */
    active: boolean
}

export interface NotificationEventContract {
    /** Идентификатор события оповещения */
    id: number

    /** Наименование события оповещения */
    name: string

    /**  */
    category: NotificationDestinationCategoryContract

    /**  */
    type: DictionaryContract

    /** Флаг активности записи */
    active: boolean
}

export interface NotificationFilteringParameterValueContract {
    /** Идентфикатор */
    id: number

    /** Наименование */
    name: string

    /** Признак "по-умлолчанию" */
    isDefault: boolean
}

export interface NotificationFilteringParameterContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Порядок */
    order: number

    /**  */
    parameterType: NotificationConditionParameterType

    /** Значение параметра */
    parameterValues: NotificationFilteringParameterValueContract[]
}

export interface NotificationFilteringConditionContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Настраиваемое */
    isCustomizable: boolean

    /** Параметры */
    parameters: NotificationFilteringParameterContract[]
}

export interface NotificationMailingInfoSearchContract {
    /** Наименование задания на рассылку */
    name: string

    /** Идентификатор задания на рассылку */
    taskId: number

    /** Статусы сведений на рассылку */
    statuses: NotificationMailingInfoStatus[]

    /** Типы заданий на рассылку */
    types: NotificationMailingTaskType[]

    /** Планируемая дата отправки, начало диапазона */
    mailingDateStart: Date

    /** Планируемая дата отправки, конец диапазона */
    mailingDateEnd: Date

    /** Дата изменения, начало диапазона */
    changeDateStart: Date

    /** Дата изменения, конец диапазона */
    changeDateEnd: Date

    /** Идентификаторы получателей */
    recipientIds: number[]

    /** Электронные почты получателей */
    recipientMails: string[]

    /** Показывать сведения о рассылке имеющие не отправленные письма */
    onlyWithErrors: boolean

    /** Показывать архивные записи */
    showArchive: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface NotificationMailingInfoSearchResultContract {
    /** Идентификатор сведения о рассылке */
    id: number

    /** Наименование задания на рассылку */
    name: string

    /** Количество адресатов в рассылке */
    recipientCount: number

    /** Количество созданных сообщений */
    createdEmailCount: number

    /** Количество отправленных сообщений */
    sendedEmailCount: number

    /** Статистика отправки */
    emailsStatistics: string

    /**  */
    type: NotificationMailingTaskType

    /** Дата отправки планируемая */
    mailingDate: Date

    /** Дата последнего изменения */
    changeDate: Date

    /**  */
    status: NotificationMailingInfoStatus

    /** Активна\/удалена запись */
    active: boolean
}

export interface NotificationMailingInfoSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: NotificationMailingInfoSearchResultContract[]
}

export interface NotificationMailingInfoResendDto {
    /**  */
    ids: number[]
}

export interface NotificationMailingInfoContract {
    /** Идентификатор сведения о рассылке */
    id: number

    /**  */
    task: DictionaryContract

    /** Количество адресатов в рассылке */
    recipientCount: number

    /** Количество созданных сообщений */
    createdEmailCount: number

    /** Количество отправленных сообщений */
    sendedEmailCount: number

    /** Статистика отправки */
    emailsStatistics: string

    /**  */
    type: NotificationMailingTaskType

    /**  */
    status: NotificationMailingInfoStatus

    /** Дата отправки планируемая */
    mailingDate: Date

    /** Дата изменения */
    changeDate: Date

    /**  */
    changeUser: UserBaseDto
}

export interface NotificationRecipientEmailInfoSearchContract {
    /** ФИО получателя */
    recipientFio: string

    /** Электронная почта получателя */
    recipientMail: string

    /** Статусы сформированных сообщений */
    statuses: EmailState[]

    /** Признак пользователю отключены уведомления */
    recipientNotificationsDisabled: boolean

    /** Статусы доставки внутри ИС ПК */
    deliveryStatuses: NotificationDeliveryStatus[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface NotificationRecipientEmailInfoSearchResultContract {
    /**  */
    recipient: UserBaseContract

    /** Дата отправки */
    sendDate: Date

    /** Количество попыток отправки */
    countError: number

    /**  */
    status: EmailState

    /** ФИО получателя письма */
    recipientFullName: string

    /**  */
    lastDeliveryState: NotificationDeliveryStatus

    /** Идентификатор письма */
    emailId: number
}

export interface NotificationRecipientEmailInfoSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: NotificationRecipientEmailInfoSearchResultContract[]
}

export interface NotificationEmailInfoContentContract {
    /** Тема письма. */
    subject: string

    /** Текст письма. */
    message: string
}

export interface NotificationDeliveryStatusStatisticsItemContract {
    /**  */
    status: NotificationDeliveryStatus

    /** Число писем */
    emailsCount: number
}

export interface NotificationDeliveryStatusStatisticsContract {
    /** Дата получения статистики */
    statisticsDate: Date

    /** Статистика */
    statistics: NotificationDeliveryStatusStatisticsItemContract[]
}

export interface NotificationSendingOptionSelectContract {
    /** Идентификатор варианта отправки оповещения */
    id: number

    /** Адрес отправителя */
    senderAddress: string

    /** Признак, использовать по умолчанию */
    isDefault: boolean
}

export interface NotificationFiltrationConditionsValuesContract {
    /**  */
    condition: DictionaryContract

    /**  */
    parameter: DictionaryContract

    /**  */
    parameterValue: DictionaryContract

    /** Значение DateTime */
    dateTimeValue: string

    /** Значение Integer */
    integerValue: number
}

export interface NotificationMailingTaskContract {
    /** Идентификатор задания на рассылку */
    id: number

    /** Наименование задания на рассылку */
    name: string

    /** Описание задания на рассылку */
    description: string

    /**  */
    status: NotificationMailingTaskStatus

    /** Последняя рассылка по заданию */
    lastMailingOnAssignment: Date

    /** Дата изменения */
    changeDate: Date

    /**  */
    changeUser: DictionaryContract

    /** Тема сообщения */
    notificationTheme: string

    /** Тело сообщения */
    notificationBody: string

    /**  */
    type: NotificationMailingTaskType

    /** Дата начала рассылки */
    mailingStartDate: string

    /** Дата окончания рассылки */
    mailingEndDate: string

    /**  */
    event: DictionaryContract

    /**  */
    category: NotificationDestinationCategoryContract

    /** Назначения */
    appointments: DictionaryContract[]

    /**  */
    subCategory: NotificationDestinationSubCategoryContract

    /** Объекты назначения */
    appointmentObjects: DictionaryContract[]

    /** Прикрепленные вложения */
    attachments: AttachmentContract[]

    /**  */
    businessStatus: NotificationMailingTaskStatus

    /** Теги */
    tags: DictionaryContract[]

    /** Произвольные адресаты */
    randomRecipientEmails: string

    /**  */
    sendOption: NotificationSendingOptionSelectContract

    /** Флаг активности записи */
    active: boolean

    /** Условия */
    conditions: NotificationFiltrationConditionsValuesContract[]
}

export interface NotificationFilteringConditionValuesCreateOrUpdateContract {
    /** Идентификатор условия */
    conditionId: number

    /** Идентификатор параметра */
    parameterId: number

    /** Идентификатор значения параметра */
    parameterValueId: number

    /** Значение int */
    integerValue: number

    /** Значение DateTime */
    dateTimeValue: string
}

export interface NotificationMailingTaskCreateOrUpdateContract {
    /** Идентификатор обновляемой сущности */
    id: number

    /**  */
    status: NotificationMailingTaskStatus

    /**  */
    type: NotificationMailingTaskType

    /** Наименование задания на рассылку */
    name: string

    /** Описание задания на рассылку */
    description: string

    /** Тема сообщения */
    notificationTheme: string

    /** Тело сообщения */
    notificationBody: string

    /** Идентификатор события оповещения задания на рассылку */
    eventId: number

    /** Дата начала рассылки */
    mailingStartDate: string

    /** Дата окончания рассылки */
    mailingEndDate: string

    /** Идентификатор категории объекта назначения (вид) */
    categoryId: number

    /** Список идентификаторов назначений */
    appointmentIds: number[]

    /** Идентификатор подкатегории объекта назначения (тип) */
    subCategoryId: number

    /** Список идентификаторов объектов назначений */
    appointmentObjectIds: number[]

    /** Список идентификаторов вложений */
    attachmentIds: number[]

    /** Список идентификаторов тегов оповещения */
    tagIds: number[]

    /** Список произвольных адресатов */
    randomRecipientEmails: string

    /** Идентификатор варианта отправки */
    sendOptionId: number

    /** Условия фильтрации получателей */
    conditions: NotificationFilteringConditionValuesCreateOrUpdateContract[]
}

export interface NotificationMailingTaskSearchContract {
    /** Наименование задания на рассылку */
    name: string

    /** Дата изменения, начало диапазона */
    changeDateStart: Date

    /** Дата изменения, конец диапазона */
    changeDateEnd: Date

    /** Идентификаторы шаблонов оповещений */
    templateIds: number[]

    /** Статусы заданий */
    statuses: NotificationMailingTaskStatus[]

    /** Типы заданий */
    types: NotificationMailingTaskType[]

    /** Идентификаторы тегов */
    tagIds: number[]

    /** Категория объекта назначения */
    categoryId: number

    /** Список объектов назначений */
    objectIds: number[]

    /** Показывать архивные записи */
    showArchive: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface NotificationMailingTaskSearchResultContract {
    /** Идентификатор задания на рассылку */
    id: number

    /** Наименование задания на рассылку */
    name: string

    /** Описание задания на рассылку */
    description: string

    /** Список тегов */
    tags: DictionaryContract[]

    /**  */
    type: NotificationMailingTaskType

    /** Дата последнего изменения */
    changeDate: Date

    /**  */
    status: NotificationMailingTaskStatus

    /**  */
    businessStatus: NotificationMailingTaskStatus

    /** Список объектов назначения */
    appointmentObjects: DictionaryContract[]

    /** Список назначений */
    appointments: DictionaryContract[]

    /** Активна\/удалена запись */
    active: boolean
}

export interface NotificationMailingTaskSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: NotificationMailingTaskSearchResultContract[]
}

export interface NotificationMailingTaskAppointmentObjectSearchContract {
    /** Идентификатор категории объекта назначения */
    categoryId: number

    /** Наименование объекта назначения */
    name: string

    /** Список статусов объекта назначения */
    statuses: DocumentStatus[]

    /** Идентификатор подкатегории объекта назначения */
    subCategoryId: number

    /** Список организаций объектов назначений */
    organizationIds: number[]
}

export interface NotificationMailingTaskAppointmentObjectSearchResultContract {
    /** Идентификатор объекта назначения */
    id: number

    /** Наименование объекта назначения */
    name: string

    /**  */
    type: NotificationDestinationSubCategoryCode

    /** Список организаций для объекта назначения */
    organizations: DictionaryContract[]

    /**  */
    status: DocumentStatus
}

export interface NotificationMailingTaskAppointmentObjectSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: NotificationMailingTaskAppointmentObjectSearchResultContract[]
}

export interface NotificationMailingTaskAppointmentSearchContract {
    /** Идентификатор категории объекта назначения */
    categoryId: number

    /** Наименование назначения */
    name: string

    /** Список статусов назначения для треков */
    trackStatuses: AppointmentTrackStatus[]

    /** Список статусов назначения для мероприятий */
    activityStatuses: AppointmentTrackStatus[]

    /** Список статусов назначения для оценок, опросов и тестов */
    otherStatuses: AppointmentStatus[]

    /**  */
    type: NotificationDestinationSubCategoryCode

    /** Дата начала, начало диапазона */
    dateStart: Date

    /** Дата окончания, конец диапазона */
    dateEnd: Date
}

export interface NotificationMailingTaskAppointmentSearchResultContract {
    /** Идентификатор назначения */
    id: number

    /** Наименование назначения */
    name: string

    /**  */
    type: NotificationDestinationSubCategoryCode

    /**  */
    trackStatus: AppointmentTrackStatus

    /**  */
    activityStatus: AppointmentTrackStatus

    /**  */
    status: AppointmentStatus

    /** Дата начала назначения */
    dateStart: Date

    /** Дата окончания назначения */
    dateEnd: Date
}

export interface NotificationMailingTaskAppointmentSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: NotificationMailingTaskAppointmentSearchResultContract[]
}

export interface NotificationMailingTaskByEventSearchContract {
    /** Идентификатор события */
    eventId: number

    /** Дата и время начала расылки */
    startDate: Date

    /** Дата и время окончания рассылки */
    endDate: Date

    /** Идентификатор подкатегории */
    subCategoryId: number

    /** Идентификаторы объектов назначения */
    objectIds: number[]
}

export interface BooleanContract {
    /** Значение */
    value: boolean
}

export interface NotificationSimulateEventContract {
    /** Идентификатор сущности для которой имитируем событие */
    id: number

    /** Идентификатор назначения в рамках которого имитируем событие */
    appointmentId: number

    /**  */
    event: NotificationEventCode

    /**  */
    subCode: NotificationDestinationSubCategoryCode

    /** Словарь рассчитываемых значений для рендеринга */
    computedValues: object

    /** Массив удаленных \/ отчисленных пользователей */
    expulsionIds: number[]

    /** Массив конкретных пользователей назначения для которых случилось событие */
    participantIds: number[]
}

export interface PassingProgressDto {
    /**  */
    currentPoints: object

    /**  */
    ratingPlace: object
}

export interface NotificationNewsletterTemplateSearchContract {
    /** Наименование шаблона */
    name: string

    /** Статусы шаблона */
    statuses: NotificationTemplateStatus[]

    /** Дата изменения, начало диапазона */
    changeDateStart: Date

    /** Дата изменения, конец диапазона */
    changeDateEnd: Date

    /** Теги шаблона */
    tagIds: number[]

    /** Применен в активных заданиях на рассылку */
    appliedInMailingTasks: boolean

    /** Показывать архивные записи */
    showArchive: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface NotificationNewsletterTemplateSearchResultContract {
    /** Идентификатор шаблона оповещения */
    id: number

    /** Наименование шаблона оповещения */
    name: string

    /** Описание шаблона оповещения */
    description: string

    /** Список тегов шаблона */
    tags: DictionaryContract[]

    /** Применен в заданиях на рассылку (всего \/ активные) */
    appliedInMailingTasks: string

    /** Дата последнего изменения */
    changeDate: Date

    /**  */
    status: NotificationTemplateStatus

    /** Активна\/удалена запись */
    active: boolean
}

export interface NotificationNewsletterTemplateSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: NotificationNewsletterTemplateSearchResultContract[]
}

export interface UserFioContract {
    /** Идентификатор пользователя */
    id: number

    /** Имя */
    firstName: string

    /** Фамилия */
    lastName: string

    /** Отчество */
    patronymic: string
}

export interface NotificationNewsletterTemplateContract {
    /** Идентификатор шаблона оповещения */
    id: number

    /** Наименование шаблона оповещения */
    name: string

    /** Наименование описания оповещения */
    description: string

    /** Тема сообщения шаблона */
    notificationTheme: string

    /** Тело сообщения шаблона */
    notificationBody: string

    /**  */
    status: NotificationTemplateStatus

    /** Теги шаблона */
    tags: DictionaryContract[]

    /** Флаг активности записи */
    active: boolean

    /** Дата изменения */
    changeDate: Date

    /**  */
    changeUser: UserFioContract
}

export interface NotificationNewsletterTemplateCreateOrUpdateContract {
    /** Идентификатор обновляемой сущности */
    id: number

    /**  */
    status: NotificationTemplateStatus

    /** Наименование шаблона оповещения */
    name: string

    /** Описание шаблона оповещения */
    description: string

    /** Тема сообщения шаблона оповещения */
    notificationTheme: string

    /** Тело сообщения шаблона оповещения */
    notificationBody: string

    /** Список идентификаторов тегов оповещения */
    tagIds: number[]
}

export interface NotificationSearchContract {
    /**  */
    type: NotificationType

    /** Заголовок сообщения */
    text: string

    /** Статус прочтения */
    unread: boolean

    /** Заголовок */
    title: string

    /** Дата создания с */
    creationDateStart: Date

    /** Дата создания до */
    creationDateEnd: Date

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface NotificationContract {
    /** Идентификатор */
    id: string

    /** Получатель */
    userId: number

    /** Заголовок */
    title: string

    /** Сообщение */
    message: string

    /** Связанная ссылка */
    link: string

    /** Дата создания */
    creationDate: string

    /** Флаг непрочитанного оповещения */
    unread: boolean

    /**  */
    type: NotificationType
}

export interface NotificationContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: NotificationContract[]
}

export interface NotificationsMetadataContract {
    /** Количество не прочтенных сообщений */
    unreadCount: number
}

export interface NotificationBatchOperationContract {
    /** Идентификаторы оповещений */
    items: string[]
}

export interface TestRequest {
    /** ИД сущности. */
    id: number
}

export interface NotificationTagSearchContract {
    /** Наименование */
    name: string

    /** Показывать архивные записи */
    showArchive: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface NotificationTagSearchResultContract {
    /** Идентификатор тега оповещения */
    id: number

    /** Наименование тега оповещения */
    name: string

    /** Активна\/удалена запись */
    active: boolean
}

export interface NotificationTagSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: NotificationTagSearchResultContract[]
}

export interface NotificationTagCreateOrUpdateContract {
    /** Идентификатор обновляемой сущности */
    id: number

    /** Наименование тега оповещения */
    name: string
}

export interface NotificationTagContract {
    /** Идентификатор тега оповещения */
    id: number

    /** Наименование тега оповещения */
    name: string

    /** Флаг активности записи */
    active: boolean
}

export interface OrganizationInfoContract {
    /**  */
    type: StructuralUnitType

    /** Идентификатор */
    id: number

    /** Наименование */
    name: string
}

export interface StartPassingContract {
    /** Идентификатор назначения */
    appointmentId: number

    /** Идентификатор объекта оценки */
    candidateId: number
}

export interface PortfolioSimpleContract {
    /** Идентификатор */
    id: number

    /** Наименование портфеля */
    name: string

    /** Описание портфеля */
    description: string
}

export interface AppointmentSimpleContract {
    /** Идентификатор назначения */
    id: number

    /**  */
    status: AppointmentStatus

    /**  */
    type: QuestionnaireType

    /** Наименование назначения */
    name: string

    /** Период проведения с */
    evaluationPeriodFrom: Date

    /** Перод проведение до */
    evaluationPeriodTo: Date

    /**  */
    portfolio: PortfolioSimpleContract
}

export interface CompetencesPublicQuestionsContract {
    /**  */
    competence: DictionaryContract

    /** Коллекция вопросов */
    questions: QuestionPublicContract[]
}

export interface CompetenceGroupsPublicQuestionsContract {
    /**  */
    competenceGroup: DictionaryContract

    /** Иерархия компетенций с вложенными вопросами */
    competenceQuestions: CompetencesPublicQuestionsContract[]
}

export interface PassingFormQuestionAnswerContract {
    /** Вопрос */
    questionId: number

    /** Осталось времени (в секундах) */
    timeLeft: number

    /**  */
    chooseAnswerParameters: PassingFormQuestionAnswerChooseVariantContract

    /**  */
    scaleAnswerParameters: PassingFormQuestionAnswerScaleContract

    /**  */
    textAnswerParameters: PassingFormQuestionAnswerTextContract

    /**  */
    missingTextAnswerParameters: PassingFormQuestionAnswerMissingTextContract
}

export interface QuizPortfolioPresentationWelcomeWindowParametersContract {
    /** Показывать обращение к пользователю */
    showGreeting: boolean

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст приветствия */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /**  */
    picture: AttachmentContract
}

export interface QuizPortfolioPresentationFinalWindowParametersContract {
    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст финального обращения */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /**  */
    picture: AttachmentContract
}

export interface QuizPortfolioPresentationContract {
    /**  */
    welcomeWindow: QuizPortfolioPresentationWelcomeWindowParametersContract

    /**  */
    finalWindow: QuizPortfolioPresentationFinalWindowParametersContract

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Отображать прогресс */
    showPassingProgress: boolean

    /** Показывать вопросы по блокам */
    showQuestionsByBlock: boolean

    /**  */
    passingPicture: AttachmentContract

    /** Настройка отобразить итоговое окно */
    showFinalWindow: boolean
}

export interface TestPortfolioPresentationWelcomeWindowParametersContract {
    /** Показывать обращение к пользователю */
    showGreeting: boolean

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст приветствия */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /**  */
    picture: AttachmentContract
}

export interface TestPortfolioPresentationFinalWindowParametersContract {
    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст финального обращения */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /**  */
    picture: AttachmentContract
}

export interface TestPortfolioPresentationContract {
    /**  */
    welcomeWindow: TestPortfolioPresentationWelcomeWindowParametersContract

    /**  */
    finalWindow: TestPortfolioPresentationFinalWindowParametersContract

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Отображать прогресс */
    showPassingProgress: boolean

    /** Показывать вопросы по блокам */
    showQuestionsByBlock: boolean

    /**  */
    passingPicture: AttachmentContract
}

export interface PassingFormQuestionnaireResultContract {
    /** Идентификатор опросного листа */
    questionnaireId: number

    /** Наименование опросного листа */
    questionnaireName: string

    /**  */
    resultCalculatingType: ResultCalculatingType

    /** Пройден ли опросный лист */
    isPassed: boolean

    /** Проходной балл */
    requiredMinScore: number

    /** Номер уровня */
    levelNumber: number

    /** Наименование уровня результата теста */
    levelName: string

    /** Максимальное количество баллов за прохождение */
    maxScore: number

    /** Количество набранных польхователем баллов */
    overallScore: number
}

export interface PassingFormContract {
    /** Идентификатор формы */
    id: number

    /**  */
    passingStatus: AppointmentStatus

    /**  */
    respondentRole: AssessmentRole

    /**  */
    respondent: UserBaseContract

    /**  */
    appointment: AppointmentSimpleContract

    /** Время начала опроса */
    startTime: Date

    /** Время окончания опроса */
    endTime: Date

    /** Остаток времени в секундах */
    timeLeft: number

    /** Ограничивать время прохождения */
    timeIsLimited: boolean

    /** Время ограничения */
    timeLimit: number

    /** Коллекция вопросов сгруппированных  по группам компетенций и компетенциям */
    groupedQuestions: CompetenceGroupsPublicQuestionsContract[]

    /** Коллекция ответов на вопросы */
    answers: PassingFormQuestionAnswerContract[]

    /**  */
    candidate: UserBaseContract

    /**  */
    assessmentType: AssessmentType

    /**  */
    testType: TestTypeCode

    /**  */
    quizType: QuizType

    /**  */
    assessmentPortfolioPresentation: AssessmentPortfolioPresentationContract

    /**  */
    quizPortfolioPresentation: QuizPortfolioPresentationContract

    /**  */
    testPortfolioPresentation: TestPortfolioPresentationContract

    /** Ограничение количества попыток */
    attemptsLimit: number

    /** Ограничивать количество попыток */
    attemptsLimited: boolean

    /** Количество прохождений формы */
    finishedPassingCount: number

    /** Пользователь дал ответ на все обязательные вопросы */
    allRequiredUserAnswersExists: boolean

    /** Результаты прохождения опросных листов в тесте */
    overallTestScore: PassingFormQuestionnaireResultContract[]

    /** Показывать правильный ответ */
    showCorrectAnswer: boolean

    /** Давать ответы в произвольном порядке */
    randomAnswerOrder: boolean
}

export interface PassingFormQuestionAnswerCreateContract {
    /** Идентификатор вопроса */
    questionId: number

    /** Остаток времени прохождения */
    timeLeft: number

    /**  */
    chooseAnswerParameters: PassingFormQuestionAnswerChooseVariantContract

    /**  */
    scaleAnswerParameters: PassingFormQuestionAnswerScaleContract

    /**  */
    textAnswerParameters: PassingFormQuestionAnswerTextContract

    /**  */
    missingTextAnswerParameters: PassingFormQuestionAnswerMissingTextContract
}

export interface AbortPassingContract {
    /** Остаток времени */
    timeLeft: number
}

export interface StopPassingQuestionContract {
    /** Ид вопроса */
    questionId: number

    /** Времени осталось (в секундах) */
    timeLeft: number
}

export interface StopPassingContract {
    /** Остаток времени */
    timeLeft: number

    /** Вопросы для остановки */
    stoppingQuestions: StopPassingQuestionContract[]
}

export interface PassingFormFinalWindowInfoContract {
    /** Дата прохождения */
    endDate: Date

    /** Общее количество вопросов */
    totalQuestionsCount: number

    /** Отвеченное количество вопросов */
    passingQuestionsCount: number

    /**  */
    picture: AttachmentContract
}

export interface SavePassingFormCommentContract {
    /** Идентификатор формы */
    id: number

    /** Комантарий */
    comment: string
}

export interface MatchingObjectContract {
    /** ИД объекта согласования */
    id: number

    /** Название объекта согласования */
    name: string
}

export interface StageItemParticipantContract {
    /** ИД участника */
    id: number

    /** Ф.И.О. участника */
    fullName: string

    /** Должность участника */
    positionName: string

    /** Подразделение участника */
    departmentName: string
}

export interface StageItemContract {
    /** ИД элемента этапа */
    id: string

    /**  */
    participant: StageItemParticipantContract

    /** Срок россмотрения */
    considerationPeriod: Date

    /**  */
    status: AgreementStatus

    /** Можно ли согласовать данный элемент этапа */
    canAgree: boolean
}

export interface StageContract {
    /** ИД этапа */
    id: string

    /** Номер этапа */
    stageNumber: number

    /** Список элементов этапа */
    items: StageItemContract[]
}

export interface AgreementListDetailContract {
    /** ИД листа согласования */
    id: string

    /** Комментарий */
    comment: string

    /** Ф.И.О. создателя */
    creatorFullName: string

    /** Список объектов для согласования */
    matchingObjects: MatchingObjectContract[]

    /** Список этапов */
    stages: StageContract[]
}

export interface CreateStageItemContract {
    /** ИД участника */
    participantId: number

    /** Срок рассмотрения */
    considerationPeriod: Date
}

export interface CreateStageContract {
    /** Номер этапа */
    stageNumber: number

    /** Элементы этапа */
    stageItems: CreateStageItemContract[]
}

export interface CreateAgreementListContract {
    /** Список объектов для согласования */
    approvalObjects: MatchingObjectContract[]

    /** Комментарий */
    comment: string

    /** Этапы */
    stages: CreateStageContract[]
}

export interface CreateAgreementListResultContract {
    /** ИД объекта */
    objectId: number

    /** Результат */
    isSuccess: boolean

    /** ИД созданного листа согласования */
    agreementListId: string
}

export interface TemplateStageItemContract {
    /** ИД элемента этапа */
    id: string

    /** ИД участника */
    participantId: string
}

export interface TemplateStageContract {
    /** ИД этапа */
    id: string

    /** Номер этапа */
    stageNumber: number

    /** Список элементов этапа */
    items: TemplateStageItemContract[]
}

export interface TemplateDetailContract {
    /** ИД шаблона листа согласования */
    id: string

    /** Наименование */
    name: string

    /** Список этапов */
    stages: TemplateStageContract[]
}

export interface TemplateSearchContract {
    /** Наименование */
    name: string
}

export interface TemplateContract {
    /** ИД шаблона листа согласования */
    id: string

    /** Наименование */
    name: string
}

export interface TemplateContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TemplateContract[]
}

export interface CreateTemplateStageItemContract {
    /** ИД участника */
    participantId: string
}

export interface CreateTemplateStageContract {
    /** Номер этапа */
    stageNumber: number

    /** Элементы этапа */
    stageItems: CreateTemplateStageItemContract[]
}

export interface CreateTemplateContract {
    /** Название */
    name: string

    /** Этапы */
    stages: CreateTemplateStageContract[]
}

export interface CreateTemplateResultContract {
    /** ИД созданного листа согласования */
    id: string
}

export interface PositionProfileContract {
    /** Идентификатор профиля */
    id: number

    /** Отображать показатели эффективности должности */
    showPerfomanceIndicators: boolean

    /**  */
    status: DocumentStatus

    /**  */
    staffUnit: DictionaryContract

    /**  */
    structuralUnit: DictionaryContract

    /**  */
    organization: DictionaryContract

    /**  */
    position: DictionaryContract

    /**  */
    supervisingComplex: DictionaryContract

    /**  */
    oiv: DictionaryContract

    /** Наименование профиля */
    name: string

    /**  */
    accessForm: DictionaryContract

    /**  */
    corruptionRisk: DictionaryContract

    /**  */
    dismissalRisk: DictionaryContract

    /** Уровни образования */
    educationLevels: DictionaryContract[]

    /** Профли образования */
    educationProfiles: DictionaryContract[]

    /**  */
    requiredExperience: DictionaryContract

    /** Отрасли для требуемого опыта */
    requiredExperienceIndustries: DictionaryContract[]

    /** Примечание для требуемого опыта */
    requiredExperienceDescription: string

    /**  */
    governmentExperience: DictionaryContract

    /**  */
    manageExperience: DictionaryContract

    /**  */
    manageExperienceSubordinatesNumber: DictionaryContract

    /**  */
    manageExperienceIndustry: DictionaryContract

    /** Бюджет в управлении для управленческого опыта */
    manageExperienceBudget: string

    /**  */
    level: DictionaryContract

    /**  */
    subordination: DictionaryContract

    /**  */
    subordinationOther: DictionaryContract

    /** Список компетенций */
    competencies: PositionProfileCompetenceContract[]

    /** Список функций классов */
    functionClasses: DictionaryContract[]

    /** Связанные группы компетенций */
    competenceGroups: DictionaryContract[]

    /** Примечание для показателей эффективности должности */
    performanceIndicatorsDescription: string

    /**  */
    type: PositionProfileType

    /** Функциональные обязанности */
    functionalResponsibilities: string

    /** Номер заявки */
    requestNumber: string

    /** Дата заявки */
    requestDate: Date

    /** Дата заявки */
    requestCompletionDate: Date

    /** Дата изменения */
    changeDate: Date

    /** Версия записи */
    version: number

    /** Дополнительные требования. */
    additionalRequirements: string

    /** ИД листа согласования */
    agreementListId: string

    /** Имя пользователя */
    changeUserName: string

    /** Флаг активности мероприятия */
    active: boolean

    /**  */
    chief: DictionaryContract

    /** Идентификатор профиля должности для импорта */
    importId: string
}

export interface PositionProfileVersionContract {
    /** ИД профиля должности. */
    id: number

    /** Имя */
    firstName: string

    /** Фамилия */
    lastName: string

    /** Отчество */
    patronymic: string

    /** Дата изменения */
    modifiedDate: Date

    /**  */
    status: DocumentStatus

    /** Версия записи */
    version: number
}

export interface StaffUnitMultiFilterContract {
    /** Идентификаторы подразделений */
    departmentsIds: number[]

    /** Идентификаторы организаций */
    organizationsIds: number[]

    /** Идентификаторы ОИВ */
    oivIds: number[]

    /** Идентификаторы комплексов */
    complexIds: number[]

    /** Показывать архивные записи */
    showArchive: boolean
}

export interface PositionProfilesSearchContract {
    /** Наименование */
    name: string

    /** Номер заявки */
    requestNumber: string

    /** Статусы */
    statuses: DocumentStatus[]

    /** Исполнитель */
    changeUserName: string

    /** Идентификаторы подразделений\/организаций */
    structuralUnitsIds: number[]

    /** Идентификаторы должностей */
    positionsIds: number[]

    /**  */
    type: PositionProfileType

    /** Идентификаторы Оив */
    oivIds: number[]

    /** Идентификаторы курирующих комплексов */
    supervisingComplexIds: number[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]

    /** Идентификатор штатное единицы */
    staffUnitId: number

    /**  */
    staffUnitSearchParametres: StaffUnitMultiFilterContract

    /** Показать архивные записи */
    showArchive: boolean
}

export interface PositionProfileSearchResultContract {
    /** Идентификатор профиля */
    id: number

    /** Наименование профиля */
    name: string

    /** Наименование должности */
    positionName: string

    /** Наименование контролирующего комплекса */
    supervisingComplexName: string

    /** Наименование ОИВа */
    oivName: string

    /** Наименование организации */
    organizationName: string

    /** Наименование подразделения */
    structuralUnitName: string

    /**  */
    type: PositionProfileType

    /** Дата изменения */
    changeDate: Date

    /** Версия */
    version: number

    /** Номер заявки */
    requestNumber: string

    /** Дата заявки */
    requestDate: Date

    /**  */
    status: DocumentStatus

    /** ФИО пользователя, сделавшего изменения */
    changeUserName: string

    /** Флаг активности */
    active: boolean

    /** ИД листа согласования */
    agreementListId: string
}

export interface PositionProfileSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: PositionProfileSearchResultContract[]
}

export interface PositionProfileSearchForModalResultContract {
    /** Идентификатор профиля */
    id: number

    /** Наименование профиля */
    name: string

    /** Наименование должности */
    positionName: string

    /** Наименование организации */
    organizationName: string

    /** Наименование подразделения */
    structuralUnitName: string

    /** Список компетенций */
    competencies: PositionProfileCompetenceContract[]
}

export interface PositionProfileSearchForModalResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: PositionProfileSearchForModalResultContract[]
}

export interface PositionProfileForDropdownContract {
    /** Список компетенций */
    competencies: PositionProfileCompetenceContract[]
}

export interface PositionProfileCompetenceCreateContract {
    /** Идентификатор компетенции */
    id: number

    /** Уровень компетенции */
    competenceLevel: number

    /** Смежная компетенция */
    isAdjacent: boolean
}

export interface PositionProfileSaveContract {
    /** Ид */
    id: number

    /** Наименование профиля */
    name: string

    /** Отображать показатели эффективности должности */
    showPerfomanceIndicators: boolean

    /** Идентификатор подразделения */
    structuralUnitId: number

    /** Идентификатор должности */
    positionId: number

    /** Идентификатор формы допуска */
    accessFormId: number

    /** Идентификатор коррупционного риска */
    corruptionRiskId: number

    /** Идентификатор риска увольнения */
    dismissalRiskId: number

    /** Идентификаторы уровня образования */
    educationLevelIds: number[]

    /** Идентификаторы профиля образования */
    educationProfileIds: number[]

    /** Идентификатор стажа для требуемого опыта */
    requiredExperienceId: number

    /** Идентификаторы отрасли для требуемого опыта */
    requiredExperienceIndustryIds: number[]

    /** Примечание для требуемого опыта */
    requiredExperienceDescription: string

    /** Идентификатор стажа ГГС */
    governmentExperienceId: number

    /** Идентификатор стажа для управленческого опыта */
    manageExperienceId: number

    /** Идентификатор количества подчиненных для управленческого опыта */
    manageExperienceSubordinatesNumberId: number

    /** Идентификатор отрасли для управленческого опыта */
    manageExperienceIndustryId: number

    /** Бюджет в управлении для управленческого опыта */
    manageExperienceBudget: string

    /** Идентификатор уровня должности */
    levelId: number

    /** Идентификатор поля "Кому подчиняется" */
    subordinationId: number

    /** Идентификатор поля "Кому подчиняется - Другое" */
    subordinationOtherId: number

    /** Номер заявки */
    requestNumber: string

    /** Дата заявки */
    requestDate: Date

    /** Дата заявки */
    requestCompletionDate: Date

    /** Ид курирующего комплекса */
    supervisingComplexId: number

    /** Ид ОИВ */
    oivId: number

    /** Примечание для показателей эффективности должности */
    performanceIndicatorsDescription: string

    /**  */
    type: PositionProfileType

    /** Функциональные обязанности */
    functionalResponsibilities: string

    /** Список компетенций */
    competencies: PositionProfileCompetenceCreateContract[]

    /** Список функций классов */
    functionClassesIds: number[]

    /** Список групп компетенций */
    competenceGroupsIds: number[]

    /** Дополнительные требования. */
    additionalRequirements: string

    /** Идентификатор штатной единицы */
    staffUnitId: number

    /** Ид ответственного руководителя */
    chiefId: number

    /** Идентификатор профиля должности для импорта */
    importId: string
}

export interface PositionProfilePublishContract {
    /** Ид */
    id: number

    /** Наименование профиля */
    name: string

    /** Отображать показатели эффективности должности */
    showPerfomanceIndicators: boolean

    /** Идентификатор подразделения */
    structuralUnitId: number

    /** Идентификатор должности */
    positionId: number

    /** Идентификатор формы допуска */
    accessFormId: number

    /** Идентификатор коррупционного риска */
    corruptionRiskId: number

    /** Идентификатор риска увольнения */
    dismissalRiskId: number

    /** Идентификаторы уровня образования */
    educationLevelIds: number[]

    /** Идентификаторы профиля образования */
    educationProfileIds: number[]

    /** Идентификатор стажа для требуемого опыта */
    requiredExperienceId: number

    /** Идентификаторы отрасли для требуемого опыта */
    requiredExperienceIndustryIds: number[]

    /** Примечание для требуемого опыта */
    requiredExperienceDescription: string

    /** Идентификатор стажа ГГС */
    governmentExperienceId: number

    /** Идентификатор стажа для управленческого опыта */
    manageExperienceId: number

    /** Идентификатор количества подчиненных для управленческого опыта */
    manageExperienceSubordinatesNumberId: number

    /** Идентификатор отрасли для управленческого опыта */
    manageExperienceIndustryId: number

    /** Бюджет в управлении для управленческого опыта */
    manageExperienceBudget: string

    /** Идентификатор уровня должности */
    levelId: number

    /** Идентификатор поля "Кому подчиняется" */
    subordinationId: number

    /** Идентификатор поля "Кому подчиняется - Другое" */
    subordinationOtherId: number

    /** Номер заявки */
    requestNumber: string

    /** Дата заявки */
    requestDate: Date

    /** Дата заявки */
    requestCompletionDate: Date

    /** Ид курирующего комплекса */
    supervisingComplexId: number

    /** Ид ОИВ */
    oivId: number

    /** Примечание для показателей эффективности должности */
    performanceIndicatorsDescription: string

    /**  */
    type: PositionProfileType

    /** Функциональные обязанности */
    functionalResponsibilities: string

    /** Список компетенций */
    competencies: PositionProfileCompetenceCreateContract[]

    /** Список функций классов */
    functionClassesIds: number[]

    /** Список групп компетенций */
    competenceGroupsIds: number[]

    /** Дополнительные требования. */
    additionalRequirements: string

    /** Идентификатор штатной единицы */
    staffUnitId: number

    /** Ид ответственного руководителя */
    chiefId: number

    /** Идентификатор профиля должности для импорта */
    importId: string
}

export interface PositionProfileCompetenceFromFileContract {
    /** Идентификатор компетенции */
    competenceId: number

    /** Компетенция */
    competence: string

    /** Значение */
    competenceLevel: number

    /** Идентификатор группы компетенций */
    competenceGroupId: number
}

export interface PositionProfileFromFileContract {
    /** Идентификатор профиля должности */
    importId: string

    /** Ошибки валидации */
    validationErrors: string[]

    /** Компетенции */
    competences: PositionProfileCompetenceFromFileContract[]

    /** Типы деятельности */
    functionalClasses: DictionaryContract[]

    /** Уровни образования */
    educationLevels: DictionaryContract[]

    /** Профили образования */
    educationProfiles: DictionaryContract[]

    /**  */
    state: PositionProfileImportState

    /** Идентификатор должности */
    positionId: number

    /** Должность */
    position: string

    /** Идентификатор комплекса */
    supervisingComplexId: number

    /** Комплекс */
    supervisingComplex: string

    /** Идентификатор Оив */
    oivId: number

    /** ОИВ */
    oiv: string

    /** Идентифитатор организации */
    organizationId: number

    /** Организация */
    organization: string

    /** Идентификатор подразделения первого порядка */
    firstOrderUnitId: number

    /** Подразделение первого порядка */
    firstOrderUnit: string

    /** Идентификатор подразделения второго порядка */
    secondOrderUnitId: number

    /** Подразделение второго порядка */
    secondOrderUnit: string

    /** Идентификатор подразделения третьего порядка */
    thirdOrderUnitId: number

    /** Подразделение третьего порядка */
    thirdOrderUnit: string

    /**  */
    type: PositionProfileType

    /** Идентификатор поля "Кому подчиняется" */
    subordinationId: number

    /** Кому подчиняется */
    subordination: string

    /** Идентификатор уровня должности */
    levelId: number

    /** Уровень должности */
    level: string

    /** Идентификатор риска увольнения */
    dismissalRiskId: number

    /** Риск увольнения */
    dismissalRisk: string

    /** Идентификатор коррупционного риска */
    corruptionRiskId: number

    /** Коррупционный риск */
    corruptionRisk: string

    /** Идентификатор формы допуска */
    accessFormId: number

    /** Форма допуска */
    accessForm: string

    /** Функциональные обязанности */
    functionalResponsibilities: string

    /** Ключевые показатели эффективности */
    performanceIndicatorsDescription: string

    /** Идентификатор отрасли, в которой требуется опыт */
    requiredExperienceIndustryId: number

    /** Отрасль, в которой требуется опыт */
    requiredExperienceIndustry: string

    /** Идентификатор стажа для требуемого опыта */
    requiredExperienceId: number

    /** Стаж для требуемого опыта */
    requiredExperience: string

    /** Идентификатор стажа ГГС */
    governmentExperienceId: number

    /** Стаж ГГС */
    governmentExperience: string

    /** Примечание для требуемого опыта */
    requiredExperienceDescription: string

    /** Идентификатор стажа для управленческого опыта */
    manageExperienceId: number

    /** Стаж для управленческого опыта */
    manageExperience: string

    /** Идентификатор количества подчиненных для управленческого опыта */
    manageExperienceSubordinatesNumberId: number

    /** Количество подчиненных для управленческого опыта */
    manageExperienceSubordinatesNumber: string

    /** Бюджет в управлении для управленческого опыта */
    manageExperienceBudget: string

    /** Идентификатор отрасли для управленческого опыта */
    manageExperienceIndustryId: number

    /** Отрасль для управленческого опыта */
    manageExperienceIndustry: string

    /** Дополнительные требования. */
    additionalRequirements: string
}

export interface PositionSearchContract {
    /** Наименование должности */
    name: string
}

export interface ProductCardSearchContract {
    /** Наименование */
    name: string

    /** Тематики */
    themeIds: number[]

    /** Статус документа */
    statuses: DocumentStatus[]

    /** Дата начала */
    fromDate: string

    /** Формат обучения */
    studyingFormats: StudyingFormat[]

    /** Тип продукта */
    types: ProductType[]

    /** Год */
    years: number[]

    /** Дата окончания */
    toDate: string

    /** Показать архивные записи */
    showArchive: boolean

    /** Показать рекомендованные */
    showRecomended: boolean

    /** Отображать только прошедшие срок размещения */
    showPastPlacementPeriod: boolean

    /** Организационные структуры */
    structuralUnitIds: number[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface ProductCardSearchResultContract {
    /** Идентификатор мероприятия */
    id: number

    /**  */
    status: DocumentStatus

    /** Наименование */
    name: string

    /** Отображать в рекомендованных */
    showInRecommended: boolean

    /**  */
    studyingFormat: StudyingFormat

    /** Год */
    year: number

    /**  */
    type: ProductType

    /** Активна ли сущность */
    active: boolean

    /** Список тематик */
    themes: DictionaryContract[]

    /** Дата начала размещения в каталоге */
    startPlacementDate: Date

    /** Дата окончания размещения в каталоге */
    endPlacementDate: Date

    /** Дата изменения */
    changeDate: Date

    /** Организационные структуры */
    organizations: DictionaryContract[]

    /** Доступна запись на обучение */
    bookingAvailable: boolean
}

export interface ProductCardSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: ProductCardSearchResultContract[]
}

export interface ProductCardAutoAppointmentContract {
    /** Идентификатор назначения */
    appointmentId: number

    /**  */
    appointmentType: AppointmentType

    /** Идентификатор мероприятия, участвующего в автоназначение */
    activityId: number
}

export interface ProductCardContract {
    /** Идентификатор карточки продуктов */
    id: number

    /** Наименование */
    name: string

    /**  */
    status: DocumentStatus

    /**  */
    type: ProductType

    /** Дата начала размещения в каталоге */
    startPlacementDate: Date

    /** Дата окончания размещения в каталоге */
    endPlacementDate: Date

    /** Год */
    year: number

    /**  */
    graduationDocumentType: GraduationDocumentType

    /**  */
    studyingFormat: StudyingFormat

    /** Анонс */
    announcement: string

    /** Целевая аудитория */
    targetAudience: string

    /** Показывать в реккомендованных */
    showInRecommended: boolean

    /** Доступен запуск продукта из каталога */
    availableToStartFromCatalog: boolean

    /** Часов(очно) на прохождение */
    intramuralHourCount: number

    /** Часов(дистанционно) на прохождение */
    distantHourCount: number

    /** Дней на прохождение */
    daysCount: number

    /**  */
    programLevel: ProgramLevel

    /** Уровень должности */
    levels: DictionaryContract[]

    /** Должность */
    positions: DictionaryContract[]

    /** Доступна запись на обучение */
    bookingAvailable: boolean

    /** Карточка развивает компетенции */
    competences: DictionaryContract[]

    /** Организации */
    organizations: DictionaryContract[]

    /** Список тематик */
    themes: DictionaryContract[]

    /** Содержание */
    sections: ProductCardSectionContract[]

    /** Задачи, которые решает программа */
    challenges: DictionaryContract[]

    /** Результаты обучения */
    outcomes: DictionaryContract[]

    /** Преподователи */
    teachers: ProductCardTeacherContract[]

    /** Доступные периоды обучения */
    studyingPeriods: ProductCardStudyingPeriodContract[]

    /**  */
    picture: PictureContract

    /**  */
    track: DictionaryContract

    /**  */
    activity: DictionaryContract

    /**  */
    course: ProductCardCourseContract

    /** Книга */
    book: string

    /** Подкаст */
    podcast: string

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean

    /**  */
    autoAppointment: ProductCardAutoAppointmentContract
}

export interface ProductCardTeacherCreateContract {
    /** Идентификатор преподавателя из карточки продукта */
    id: number

    /** ФИО преподавателя */
    fullName: string

    /** Дожность преподавателя */
    position: string

    /** Идентификатор присоединенного файла с фото преподователя */
    attachmentId: number
}

export interface ProductCardStudyingPeriodPublishContract {
    /** Идентификатор периода обучения */
    id: number

    /** Порядок */
    order: number

    /** Дата начала проведения */
    startDate: Date

    /** Дата конца проведения */
    endDate: Date

    /** Количество участников */
    participantCount: number

    /** Идентификатор отвественного */
    responsiblePersonId: number
}

export interface ProductCardPublishContract {
    /** Дата начала размещения в каталоге */
    startPlacementDate: string

    /** Дата окончания размещения в каталоге */
    endPlacementDate: string

    /** Год */
    year: number

    /**  */
    studyingFormat: StudyingFormat

    /** Идентификаторы компетенций */
    competenceIds: number[]

    /** Идентификатор картинки */
    pictureId: number

    /**  */
    programLevel: ProgramLevel

    /** Уровень должности */
    levelIds: number[]

    /** Тематические разделы */
    themesIds: number[]

    /** Идентификатор. Default для создание. */
    id: number

    /** Наименование мероприятия */
    name: string

    /**  */
    type: ProductType

    /** Анонс */
    announcement: string

    /** Ключевые темы */
    keyTopics: string

    /** Цели */
    purposes: string

    /** Целевая аудитория */
    targetAudience: string

    /** Показывать в реккомендованных */
    showInRecommended: boolean

    /** Список организаций */
    organizationsIds: number[]

    /** Идентификатор трека развития */
    trackId: number

    /** Идентификатор мероприятия */
    activityId: number

    /** Идентификатор курса */
    courseId: number

    /**  */
    graduationDocumentType: GraduationDocumentType

    /** Доступен запуск продукта из каталога */
    availableToStartFromCatalog: boolean

    /** Разделы */
    sections: ProductCardSectionContract[]

    /** Задачи, которые решает программа */
    challenges: DictionaryContract[]

    /** Результаты обучения */
    outcomes: DictionaryContract[]

    /** Преподаватели */
    teachers: ProductCardTeacherCreateContract[]

    /** Часов(очно) на прохождение */
    intramuralHourCount: number

    /** Часов(дистанционно) на прохождение */
    distantHourCount: number

    /** Дней на прохождение */
    daysCount: number

    /** Идентификаторы должностей */
    positionIds: number[]

    /** Доступна запись на обучение */
    bookingAvailable: boolean

    /** Доступные периоды обучения */
    studyingPeriods: ProductCardStudyingPeriodPublishContract[]

    /** Книга */
    book: string

    /** Подкаст */
    podcast: string

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface ProductCardValidationResultContract {
    /** Результата валидации карточки продукта */
    result: boolean
}

export interface ProductCardSaveContract {
    /** Идентификатор. Default для создание. */
    id: number

    /** Наименование мероприятия */
    name: string

    /**  */
    type: ProductType

    /** Дата начала размещения в каталоге */
    startPlacementDate: string

    /** Дата окончания размещения в каталоге */
    endPlacementDate: string

    /** Анонс */
    announcement: string

    /** Ключевые темы */
    keyTopics: string

    /** Цели */
    purposes: string

    /** Целевая аудитория */
    targetAudience: string

    /** Показывать в реккомендованных */
    showInRecommended: boolean

    /** Список тематик */
    themesIds: number[]

    /** Список организаций */
    organizationsIds: number[]

    /** Идентификатор изображения */
    pictureId: number

    /** Идентификатор трека развития */
    trackId: number

    /** Идентификатор мероприятия */
    activityId: number

    /** Идентификатор курса */
    courseId: number

    /** Год */
    year: number

    /**  */
    graduationDocumentType: GraduationDocumentType

    /**  */
    studyingFormat: StudyingFormat

    /** Иднетификаторы компетенций */
    competenceIds: number[]

    /** Доступен запуск продукта из каталога */
    availableToStartFromCatalog: boolean

    /** Разделы */
    sections: ProductCardSectionContract[]

    /** Задачи, которые решает программа */
    challenges: DictionaryContract[]

    /** Результаты обучения */
    outcomes: DictionaryContract[]

    /** Преподаватели */
    teachers: ProductCardTeacherCreateContract[]

    /** Часов(очно) на прохождение */
    intramuralHourCount: number

    /** Часов(дистанционно) на прохождение */
    distantHourCount: number

    /** Дней на прохождение */
    daysCount: number

    /**  */
    programLevel: ProgramLevel

    /** Идентификаторы уровней должностей */
    levelIds: number[]

    /** Идентификаторы должностей */
    positionIds: number[]

    /** Доступна запись на обучение */
    bookingAvailable: boolean

    /** Доступные периоды обучения */
    studyingPeriods: ProductCardStudyingPeriodPublishContract[]

    /** Книга */
    book: string

    /** Подкаст */
    podcast: string

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface ProductCardStudyingPeriodListenerInfoContract {
    /** Идентификатор периода обучения */
    productCardStudyingPeriodId: number

    /** Количество слушателей записавшихся на курс */
    enrolledListenersCount: number

    /** Записывался ли слушатель ранее */
    isListenerEnrolled: boolean
}

export interface ProductCardReserveEnrollmentListenerContract {
    /**  */
    listener: UserBaseContract

    /**  */
    structuralUnit: DictionaryContract

    /**  */
    parentStructuralUnit: DictionaryContract

    /**  */
    position: DictionaryContract
}

export interface ProductCardListenerUpdateContract {
    /** Идентификатор */
    id: number

    /** Слушатель записан на курс */
    isEnrolled: boolean
}

export interface QuestionnaireSearchContract {
    /** Наименование опросного листа */
    name: string

    /** Тип опросного листа */
    types: QuestionnaireType[]

    /**  */
    quizType: QuizType

    /** Идентификатор типа тестирования */
    testTypeId: number

    /**  */
    assessmentType: AssessmentType

    /** Список идентификаторов должностей */
    positionIds: number[]

    /** Список идентификаторов компетенций */
    competenceIds: number[]

    /** Показывать архивные записи */
    showArchive: boolean

    /** Дата изменения, начало диапазона */
    changeDateStart: string

    /** Дата изменения, конец диапазона */
    changeDateEnd: string

    /** Включить статусы */
    includeStatuses: DocumentStatus[]

    /** Исключить статусы */
    excludeStatuses: DocumentStatus[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface QuestionnaireSearchResultContract {
    /** Идентификатор опросного листа */
    id: number

    /**  */
    quizType: QuizType

    /**  */
    assessmentType: AssessmentType

    /** Наименование опросного листа */
    name: string

    /** Флаг активности записи */
    active: boolean

    /**  */
    type: QuestionnaireType

    /**  */
    status: DocumentStatus

    /** Дата изменения */
    changeDate: Date

    /** Наименование компетенции */
    competenceName: string

    /** Версия опросного листа */
    version: number
}

export interface QuestionnaireSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: QuestionnaireSearchResultContract[]
}

export interface QuestionnaireSelectSearchContract {
    /**  */
    questionnaireType: QuestionnaireType

    /**  */
    assessmentType: AssessmentType

    /** Идентификатор компетенции */
    competenceIds: number[]

    /** Идентификатор типа теста опросного листа */
    testTypeId: number

    /**  */
    quizType: QuizType

    /** Статусы, которые необходимо исключить */
    excludeStatuses: DocumentStatus[]

    /** Идентификаторы должностей */
    positionIds: number[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface QuestionnaireForModalContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /**  */
    type: QuestionnaireType

    /** Наименование компетенции */
    competenceName: string
}

export interface QuestionnaireForModalContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: QuestionnaireForModalContract[]
}

export interface QuestionnaireShortInfoContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Количество вопросов */
    questionsCount: number

    /** Общее время ответов */
    totalAnswerLimit: number
}

export interface QuestionChooseVariantAnswerCreateContract {
    /** Порядковый номер ответа */
    number: number

    /** Текст ответа */
    text: string

    /**  */
    attachment: AttachmentContract

    /** Балы */
    points: number

    /** Правильный ответ */
    isRightAnswer: boolean
}

export interface QuestionChooseVariantCreateContract {
    /** Установить ограничение на количество вариантов ответа */
    isAnswersCountLimited: boolean

    /** Максимальное количество вариантов ответа */
    limitAnswersCount: number

    /** Доступное количество ответов (один\/несколько) */
    multiChoose: boolean

    /** Отображать варианты ответов в произвольном порядке */
    randomAnswerOrder: boolean

    /** Предоставить пользователю возможность добавить свой вариант ответа */
    customAnswer: boolean

    /** Список ответов */
    answers: QuestionChooseVariantAnswerCreateContract[]
}

export interface QuestionCriteriaScaleCreateContract {
    /** Ид шкалы ответов */
    scaleId: number

    /** Этот вопрос инверсионный */
    isInverseQuestion: boolean

    /** Номер парного прямого вопроса */
    linkedQuestionNumber: number
}

export interface QuestionCreateContract {
    /** Порядковый номер вопроса */
    number: number

    /** Номер блокирующего вопроса */
    blockedByQuestionNumber: number

    /**  */
    type: QuestionType

    /** Обязательный вопрос */
    mandatoryQuestion: boolean

    /** Установлено ограничение на ответ */
    limitedAnswerTime: boolean

    /** Время на ответ в минутах */
    answerLimit: number

    /** Вопрос неактивен */
    isInactive: boolean

    /** Заголовок вопроса */
    title: string

    /** Текст вопроса */
    text: string

    /** Идентификатор индикатора к которому относится вопрос */
    indicatorId: number

    /** Идентификаторы прикрепленных файлов */
    attachmentIds: number[]

    /**  */
    chooseVariant: QuestionChooseVariantCreateContract

    /**  */
    criteriaScale: QuestionCriteriaScaleCreateContract

    /**  */
    fillEmptyText: QuestionFillEmptyTextContract
}

export interface QuestionnaireAssessmentParametersCreateContract {
    /**  */
    assessmentType: AssessmentType

    /** Идентификатор типа респондента */
    respondentTypeId: number

    /** Идентификатор способа расчета результата */
    resultCalculatingMethodId: number

    /** Идентификатор типа шкалы оценки */
    scaleId: number
}

export interface QuestionnaireTestParametersCreateContract {
    /** Идентификатор типа теста */
    testTypeId: number
}

export interface QuestionnaireQuizParametersCreateContract {
    /**  */
    quizType: QuizType

    /** Идентификатор способа расчета результата */
    resultCalculatingMethodId: number

    /** Идентификатор типа шкалы оценки */
    scaleId: number
}

export interface QuestionnaireSaveContract {
    /**  */
    status: DocumentStatus

    /** Идентификатор обновляемой сущности */
    id: number

    /** Наименование опросного листа */
    name: string

    /**  */
    type: QuestionnaireType

    /** Список идентификаторов должностей */
    positionIds: number[]

    /** Список вопросов */
    questions: QuestionCreateContract[]

    /** Идентификатор параметра оценки */
    competenceId: number

    /** Описание шаблона */
    description: string

    /**  */
    sequence: TestSequence

    /**  */
    assessmentParameters: QuestionnaireAssessmentParametersCreateContract

    /**  */
    testParameters: QuestionnaireTestParametersCreateContract

    /**  */
    quizParameters: QuestionnaireQuizParametersCreateContract

    /**  */
    resultEstimationParameters: QuestionnaireResultEstimationParametersContract

    /** Отображать вопросы в произвольном порядке */
    randomQuestionsOrder: boolean

    /** Идентификаторы структурных подразделений */
    structuralUnitIds: number[]

    /** Идентификатор родительской сущности */
    parentId: number

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface QuestionnairePublishContract {
    /**  */
    status: DocumentStatus

    /** Наименование опросного листа */
    name: string

    /** Идентификатор параметра оценки */
    competenceId: number

    /** Описание шаблона */
    description: string

    /** Идентификатор обновляемой сущности */
    id: number

    /**  */
    type: QuestionnaireType

    /** Список идентификаторов должностей */
    positionIds: number[]

    /** Список вопросов */
    questions: QuestionCreateContract[]

    /**  */
    sequence: TestSequence

    /**  */
    assessmentParameters: QuestionnaireAssessmentParametersCreateContract

    /**  */
    testParameters: QuestionnaireTestParametersCreateContract

    /**  */
    quizParameters: QuestionnaireQuizParametersCreateContract

    /**  */
    resultEstimationParameters: QuestionnaireResultEstimationParametersContract

    /** Отображать вопросы в произвольном порядке */
    randomQuestionsOrder: boolean

    /** Идентификаторы структурных подразделений */
    structuralUnitIds: number[]

    /** Идентификатор родительской сущности */
    parentId: number

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface QuizCheckRegistrySearchContract {
    /** Название мероприятиия \/ трека */
    name: string

    /**  */
    type: AppointmentType

    /** Название назначения */
    appointmentName: string

    /** Проверяющий */
    tutor: string

    /** Статусы */
    statuses: QuizCheckStatus[]

    /** Показать просроченные */
    isOverdue: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface QuizCheckRegistryContract {
    /** Идентификатор записи */
    id: number

    /** Наименование трека\/мероприятия */
    objectName: string

    /**  */
    type: AppointmentType

    /** Наименование назначения */
    appointmentName: string

    /**  */
    tutor: UserBaseContract

    /**  */
    status: QuizCheckStatus

    /** Признак просроченного назначения */
    isOverdue: boolean
}

export interface QuizCheckRegistryContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: QuizCheckRegistryContract[]
}

export interface QuizCheckQuestionContract {
    /** Номер вопроса */
    number: number

    /** Вопрос */
    title: string

    /** Ответ */
    answer: string
}

export interface QuizCheckContract {
    /** Наименование опросного листа */
    name: string

    /** Вопросы и ответы */
    questions: QuizCheckQuestionContract[]

    /** Комментарий */
    comment: string
}

export interface QuizCheckSaveContract {
    /** Идентификатор проверки */
    id: number

    /** Комментарий */
    comment: string
}

export interface QuizCheckConfirmContract {
    /** Идентификаторы проверок */
    ids: number[]
}

export interface QuizPortfolioSearchContract {
    /** Наименование */
    name: string

    /** Статусы документа */
    statuses: DocumentStatus[]

    /**  */
    quizType: QuizType

    /** Показывать архивный записи */
    showArchive: boolean

    /** Организации\/подразделения */
    structuralUnitIds: number[]

    /** Дата изменения, начало диапазона */
    changeDateStart: string

    /** Дата изменения, конец диапазона */
    changeDateEnd: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface QuizPortfolioSearchResultContract {
    /** Идентификатор портфеля */
    id: number

    /** Наименование портфеля */
    name: string

    /** Организации портфеля */
    organizations: DictionaryContract[]

    /**  */
    type: QuizType

    /** Дата создания портфеля */
    creationDate: Date

    /** Дата изменения портфеля */
    changeDate: Date

    /**  */
    status: DocumentStatus

    /** Флаг активности */
    active: boolean

    /** Наименования активных назначений */
    appointmentNames: string[]
}

export interface QuizPortfolioSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: QuizPortfolioSearchResultContract[]
}

export interface QuizPortfolioContract {
    /** Флаг активности записи */
    active: boolean

    /** Идентификатор мероприятия */
    id: number

    /** Дата изменения */
    changeDate: Date

    /** Дата создания */
    creationDate: Date

    /**  */
    status: DocumentStatus

    /** Наименование портфеля */
    name: string

    /** Описание портфеля */
    description: string

    /**  */
    type: QuizType

    /** Список идентификаторов должностей */
    positions: DictionaryContract[]

    /** Список идентификаторов опросных листов */
    questionnaires: QuestionnaireContract[]

    /** Прикрепленные файлы */
    attachments: AttachmentContract[]

    /** Ограничивать время прохождения */
    timeIsLimited: boolean

    /** Время ограничения в секундах */
    timeLimit: number

    /** Обезличить результаты прохождения */
    depersonalizeRespondents: boolean

    /** Организации */
    organizations: DictionaryContract[]

    /**  */
    presentation: QuizPortfolioPresentationContract

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface QuizPortfolioSelectSearchContract {
    /** Организации */
    organizationsIds: number[]

    /**  */
    type: QuizType

    /** Правила сортировки */
    orderRules: OrderRuleContract[]

    /** Для всех организаций */
    forAllOrganizations: boolean
}

export interface QuizPortfolioPresentationWelcomeWindowParametersCreateContract {
    /** Показывать обращение к пользователю */
    showGreeting: boolean

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст приветствия */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /** Идентификатор приветственного окна */
    pictureId: number
}

export interface QuizPortfolioPresentationFinalWindowParametersCreateContract {
    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст финального обращения */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /** Идентификатор изображения финального окна */
    pictureId: number
}

export interface QuizPortfolioPresentationCreateContract {
    /**  */
    welcomeWindow: QuizPortfolioPresentationWelcomeWindowParametersCreateContract

    /**  */
    finalWindow: QuizPortfolioPresentationFinalWindowParametersCreateContract

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Отображать прогресс */
    showPassingProgress: boolean

    /** Показывать вопросы по блокам */
    showQuestionsByBlock: boolean

    /** Изображение прохождения */
    passingPictureId: number

    /** Настройка отобразить итоговое окно */
    showFinalWindow: boolean
}

export interface QuizPortfolioSaveContract {
    /** Идентификатор обновляемой сущности */
    id: number

    /**  */
    status: DocumentStatus

    /** Наименование портфеля */
    name: string

    /** Описание портфеля */
    description: string

    /**  */
    type: QuizType

    /** Список идентификаторов должностей */
    positionIds: number[]

    /** Идентификаторов опросного листа */
    questionnaireIds: number[]

    /** Ограничивать время прохождения */
    timeIsLimited: boolean

    /** Время ограничения в секундах */
    timeLimit: number

    /** Обезличить результаты прохождения */
    depersonalizeRespondents: boolean

    /** Организации */
    organizationsIds: number[]

    /** Идентификаторы прикрепленный файлов */
    attachmentIds: number[]

    /**  */
    presentation: QuizPortfolioPresentationCreateContract

    /** Идентификатор родительской сущности */
    parentId: number

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface QuizPortfolioPublishContract {
    /** Наименование портфеля */
    name: string

    /** Идентификатор обновляемой сущности */
    id: number

    /**  */
    status: DocumentStatus

    /** Описание портфеля */
    description: string

    /**  */
    type: QuizType

    /** Список идентификаторов должностей */
    positionIds: number[]

    /** Идентификаторов опросного листа */
    questionnaireIds: number[]

    /** Ограничивать время прохождения */
    timeIsLimited: boolean

    /** Время ограничения в секундах */
    timeLimit: number

    /** Обезличить результаты прохождения */
    depersonalizeRespondents: boolean

    /** Организации */
    organizationsIds: number[]

    /** Идентификаторы прикрепленный файлов */
    attachmentIds: number[]

    /**  */
    presentation: QuizPortfolioPresentationCreateContract

    /** Идентификатор родительской сущности */
    parentId: number

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface RecommendationContract {
    /** ИД */
    id: number

    /** Картинка */
    pictureUrl: string

    /** Название продукта */
    name: string

    /** Избранное */
    favorites: boolean

    /** Количество тематических блоков продукта */
    count: number

    /**  */
    studyingFormat: StudyingFormat

    /** Имеет индикатор, что запись на период обучения по карточке продукта закрыта */
    hasIndicatorBookingClosed: boolean
}

export interface SessionInfoContract {
    /** Идентификатор сессии */
    id: string

    /** Время начала сессии */
    sessionStartDateTime: Date

    /** Время последнего события сессии */
    lastEventDateTime: Date

    /**  */
    recordEntityType: RecordEntityType

    /** ФИО пользователя */
    userFIo: string

    /** Идентификатор пользователя */
    userId: number
}

export interface RrwebEventCreateContract {
    /** Часть события rrweb */
    eventPart: string
}

export interface SessionSearchContract {
    /** ФИО пользователя */
    userFio: string

    /** Время начала сессии */
    sessionStartDateTimeFrom: string

    /** Тип сущности для записи */
    recordEntityTypes: RecordEntityType[]

    /** Время начала сессии */
    sessionStartDateTimeTo: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface SessionInfoContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: SessionInfoContract[]
}

export interface ScaleSearchContract {
    /** Наименование */
    name: string

    /** Статусы */
    statuses: DocumentStatus[]

    /** Показать архивные */
    showArchive: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface ScaleContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: ScaleContract[]
}

export interface ScaleByIdsContract {
    /** Идентификатор шкалы */
    id: number

    /** Наименование шкалы */
    name: string

    /** Значения шкалы */
    scaleValues: ScaleValueContract[]
}

export interface ScaleCreateContract {
    /** Ид */
    id: number

    /** Имя */
    name: string

    /** Описание шкалы критериев */
    description: string

    /** Флаг Семантический */
    isSemantic: boolean

    /** Значения внутри шкалы */
    values: ScaleValueContract[]
}

export interface ScormOperationBaseContract {
    /** Идентификатор курса */
    courseId: number
}

export interface ScormSetValueContract {
    /** Наименование элемента SCORM */
    key: string

    /** Значение */
    value: string

    /** Идентификатор курса */
    courseId: number
}

export interface ScormGetValueContract {
    /** Ключ */
    key: string

    /** Идентификатор курса */
    courseId: number
}

export interface ScormValueContract {
    /** Ключ */
    key: string

    /** Значение */
    value: string
}

export interface OibV2SettingsContract {
    /** Дата последнего обновления организаций с ОИБ v2 */
    oibV2OrganizationLastUpdate: Date

    /** Дата последнего обновления подразделений с ОИБ v2 */
    oibV2DepartmentsLastUpdate: Date

    /** Дата последнего обновления должностей с ОИБ v2 */
    oibV2PositionLastUpdate: Date

    /** Дата последнего обновления штатных единиц с ОИБ v2 */
    oibV2StaffUnitsLastUpdate: Date

    /** Дата последнего обновления типов пользователей с ОИБ v2 */
    oibV2UserTypeLastUpdate: Date

    /** Дата последнего обновления пользователей с ОИБ v2 */
    oibV2UsersLastUpdate: Date

    /** Дата последнего обновления руководителей организаций с ОИБ v2 */
    oibV2ManagersLastUpdate: Date
}

export interface ApplicationSettings {
    /** Видимость переключателя тем */
    isThemeSwitcherVisible: boolean
}

export interface StaffUnitSearchContract {
    /** Идентификаторы организаций */
    organizationsIds: number[]

    /** Идентификаторы подразделений */
    departmentsIds: number[]

    /** Идентификаторы должностей */
    positionsIds: number[]

    /** Есть профиль должности */
    hasPositionProfile: boolean

    /** Показать архивные */
    showArchive: boolean
}

export interface StaffUnitInfoContract {
    /** Идентификатор штатной единицы */
    staffUnitId: number

    /** Идентификатор должности */
    positionId: number

    /** Наименование должности */
    positionName: string

    /** Активность записи */
    active: boolean

    /** Наличие профиля должности */
    hasPositionProfile: boolean
}

export interface StaffUnitSearchResultDepartmentItemContract {
    /**  */
    department: OrganizationInfoContract

    /** Информация о должностях */
    positions: StaffUnitInfoContract[]

    /** Дочерние подразделения */
    departments: StaffUnitSearchResultDepartmentItemContract[]
}

export interface StaffUnitSearchResultOrganizationItemContract {
    /**  */
    organization: OrganizationInfoContract

    /** Информация о должностях */
    positions: StaffUnitInfoContract[]

    /** Дочерние организации */
    organizations: StaffUnitSearchResultOrganizationItemContract[]

    /** Подразделения организации */
    departments: StaffUnitSearchResultDepartmentItemContract[]
}

export interface StaffUnitPageContract {
    /** Количество с профилем должности */
    withPositionProfileCount: number

    /** Количество без профиля должности */
    withoutPositionProfileCount: number

    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: StaffUnitSearchResultOrganizationItemContract[]
}

export interface StaffUnitFilterContract {
    /** Идентификатор комплекса */
    complexId: number

    /** Идентификатор ОИВ */
    oivId: number

    /** Идентификатор организации */
    organizationId: number

    /** Идентификатор подразделение */
    departmentId: number

    /** Показывать архивные записи */
    showArchive: boolean
}

export interface StaffUnitStructureContract {
    /**  */
    staffUnit: DictionaryContract

    /**  */
    department: DictionaryContract

    /** Организации */
    organizations: OrganizationInfoContract[]
}

export interface StructuralUnitPositionSearchContract {
    /** Идентификаторы структурных подразделений */
    structuralUnitsIds: number[]

    /** Идентификаторы должностей */
    positionsIds: number[]

    /** Есть привязанный профиль должности */
    hasLinkedPositionProfile: boolean

    /** Показать архивные */
    showArchive: boolean

    /** Дата изменения, начало периода */
    changeDateStart: string

    /** Дата изменения, конец периода */
    changeDateEnd: string
}

export interface StructuralUnitInfoContract {
    /** Дата изменения */
    changeDate: Date

    /**  */
    type: StructuralUnitType

    /** Флаг изменения сущности */
    isChanged: boolean

    /** Идентификатор */
    id: number

    /** Наименование */
    name: string
}

export interface UserInfoContract {
    /** Идентификатор пользователя */
    id: number

    /** Полное имя пользователя */
    fullName: string
}

export interface PositionInfoContract {
    /** Идентификатор связи структурная единица - должность */
    structuralUnitPositionId: number

    /** Идентификатор должности */
    positionId: number

    /** Наименование должности */
    positionName: string

    /** Дата модификации */
    changeDate: Date

    /** Активность записи */
    active: boolean

    /** Наличие профиля должности */
    hasPositionProfile: boolean

    /** Наличие привязанного профиля должности */
    hasLinkedPositionProfile: boolean

    /** Флаг изменения сущьности */
    isChanged: boolean

    /** Полное имя пользователя */
    userFullNames: UserInfoContract[]
}

export interface StructuralUnitPositionSearchResultItemContract {
    /**  */
    unit: StructuralUnitInfoContract

    /** Информация о должностях */
    positions: PositionInfoContract[]

    /** Дочерние элементы */
    items: StructuralUnitPositionSearchResultItemContract[]

    /** Флаг изменения сущьности */
    isChanged: boolean

    /** Количество с ПД */
    withPositionProfileCount: number

    /** Количество без ПД */
    withoutPositionProfileCount: number
}

export interface StructuralUnitPositionPageContract {
    /** Количество с профилем должности */
    withPositionProfileCount: number

    /** Количество без профиля должности */
    withoutPositionProfileCount: number

    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: StructuralUnitPositionSearchResultItemContract[]
}

export interface StructuralUnitsSearchContract {
    /** Название структурной единицы */
    name: string

    /** Дата изменения */
    modifiedDate: string

    /** Типы структурных единиц */
    types: StructuralUnitType[]
}

export interface StructuralUnitContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: StructuralUnitContract[]
}

export interface StructuralUnitPositionsSearchContract {
    /** Идентификаторы структурных единиц */
    structuralUnitsIds: number[]

    /** Идентификаторы должностей */
    positionsIds: number[]
}

export interface StructuralUnitPositionContract {
    /** Наименование должности */
    positionName: string

    /** Наименование организации */
    organizationName: string

    /** Наименование подразделения */
    unitName: string

    /** Флаг наличия профиля должности */
    hasProfile: boolean

    /** Идентификатор профиля должности */
    positionProfileId: number

    /** Идентификатор должности */
    positionId: number

    /** Идентификатор структурной единицы */
    structuralUnitId: number
}

export interface StructuralUnitPositionContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: StructuralUnitPositionContract[]
}

export interface DictionaryCreateContract {
    /** Наименование элемента справочника */
    name: string
}

export interface SupervisingComplexContract {
    /** Ид */
    id: number

    /** Имя */
    name: string

    /** Дата создания */
    changeDate: Date

    /** Куратор */
    curatorName: string
}

export interface SupervisingComplexSearchContract {
    /** Имя */
    name: string

    /** Дата изменения, начало периода */
    changeDateStart: string

    /** Дата изменения, конец периода */
    changeDateEnd: string

    /** ФИО куратора */
    curatorFio: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface SupervisingComplexContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: SupervisingComplexContract[]
}

export interface SystemInformationContract {
    /** Версия */
    version: string

    /** Номер телефона 1 */
    phoneNumber1: string

    /** Номер телефона 2 */
    phoneNumber2: string

    /** e-mail */
    email: string

    /** Хеш коммита из которого собрана сборка */
    gitCommit: string

    /** Ветка из которой собрана сборка */
    gitBranch: string

    /** Пайплайн в котором собрана и доставлена сборка */
    gitPipeline: string
}

export interface TestPortfolioSearchForSelectContract {
    /** Идентификаторы организаций */
    organizationIds: number[]

    /** Правила сортировки */
    orderRules: OrderRuleContract[]

    /** Для всех организаций */
    forAllOrganizations: boolean
}

export interface TestPortfolioQuestionnaireContract {
    /**  */
    questionnaire: QuestionnaireContract

    /** Количество случайных вопросов */
    countOfRandomQuestions: number

    /** Порядок опросного листа в портфеле */
    order: number
}

export interface TestPortfolioContract {
    /** Флаг активности записи */
    active: boolean

    /** Идентификатор мероприятия */
    id: number

    /** Дата изменения */
    changeDate: Date

    /** Дата создания */
    creationDate: Date

    /**  */
    purpose: DictionaryContract

    /**  */
    testType: DictionaryContract

    /**  */
    status: DocumentStatus

    /** Наименование портфеля */
    name: string

    /** Описание портфеля */
    description: string

    /** Ограничивать время прохождения */
    timeIsLimited: boolean

    /** Время ограничения в секундах */
    timeLimit: number

    /** Брать случайную выборку вопросов */
    takeRandomQuestions: boolean

    /** Список идентификаторов должностей */
    positions: DictionaryContract[]

    /** Список идентификаторов опросных листов */
    questionnaires: TestPortfolioQuestionnaireContract[]

    /** Организации */
    organizations: DictionaryContract[]

    /** Компетенции */
    competencies: DictionaryContract[]

    /** Прикрепленный файл */
    attachments: AttachmentContract[]

    /**  */
    presentation: TestPortfolioPresentationContract

    /** Показывать правильный ответ на вопрос */
    showCorrectAnswer: boolean

    /** Показывать общий результат прохождения теста */
    showOverallTestScore: boolean

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface TestPortfolioSearchContract {
    /** Наименование портфеля */
    name: string

    /** Идентификаторы компетенций */
    competenceIds: number[]

    /** Статусы документа */
    statuses: DocumentStatus[]

    /** Показать архивные записи */
    showArchive: boolean

    /** Организации\/подразделения */
    structuralUnitIds: number[]

    /** Дата создания теста, начало диапазона */
    creationDateStart: string

    /** Дата создания теста, конец диапазона */
    creationDateEnd: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface TestPortfolioSearchResultContract {
    /** Признак активности */
    active: boolean

    /** Идентификатор портфеля */
    id: number

    /** Наименование портфеля */
    name: string

    /** Наименования компетенций */
    competenceNames: string[]

    /**  */
    status: DocumentStatus

    /** Дата изменения */
    changeDate: Date

    /** Дата создания */
    creationDate: Date

    /** Организации */
    organizations: DictionaryContract[]

    /** Наименования активных назначений */
    appointmentNames: string[]
}

export interface TestPortfolioSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TestPortfolioSearchResultContract[]
}

export interface TestPortfolioQuestionnaireCreateContract {
    /** Идентификатор опросного листа */
    questionnaireId: number

    /** Количество вопросов для случайной выборки */
    countOfRandomQuestions: number

    /** Порядок опросного листа в портфеле */
    order: number
}

export interface TestPortfolioPresentationWelcomeWindowParametersCreateContract {
    /** Показывать обращение к пользователю */
    showGreeting: boolean

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст приветствия */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /** Изображение приветственного окна */
    pictureId: number
}

export interface TestPortfolioPresentationFinalWindowParametersCreateContract {
    /** Заголовок */
    title: string

    /**  */
    titleTextSettings: TextSettingsBaseContract

    /** Текст финального обращения */
    message: string

    /**  */
    messageTextSettings: TextSettingsBaseContract

    /** Изображение финального окна */
    pictureId: number
}

export interface TestPortfolioPresentationCreateContract {
    /**  */
    welcomeWindow: TestPortfolioPresentationWelcomeWindowParametersCreateContract

    /**  */
    finalWindow: TestPortfolioPresentationFinalWindowParametersCreateContract

    /** Показывать роль оценивающего */
    showCandidateRole: boolean

    /** Отображать прогресс */
    showPassingProgress: boolean

    /** Показывать вопросы по блокам */
    showQuestionsByBlock: boolean

    /** Изображение прохождения */
    passingPictureId: number
}

export interface TestPortfolioSaveContract {
    /** Идентификатор обновляемой сущности */
    id: number

    /** Наименование портфеля */
    name: string

    /** Описание портфеля */
    description: string

    /** Организации */
    organizationsIds: number[]

    /** Список идентификаторов должностей */
    positionIds: number[]

    /** Список идентификаторов компетенций */
    competenceIds: number[]

    /** Идентификатор цели тестирования */
    purposeId: number

    /** Идентификатор типа теста */
    testTypeId: number

    /** Идентификаторы опросных листов */
    questionnaires: TestPortfolioQuestionnaireCreateContract[]

    /** Идентификаторы прикрепленных файлов */
    attachmentIds: number[]

    /** Ограничивать время прохождения */
    timeIsLimited: boolean

    /** Время ограничения в секундах */
    timeLimit: number

    /** Взять случайные вопросы */
    takeRandomQuestions: boolean

    /**  */
    presentation: TestPortfolioPresentationCreateContract

    /** Показывать правильный ответ на вопрос */
    showCorrectAnswer: boolean

    /** Показывать общий результат прохождения теста */
    showOverallTestScore: boolean

    /** Идентификатор родительской сущности */
    parentId: number

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface TestPortfolioPublishContract {
    /** Идентификатор обновляемой сущности */
    id: number

    /** Наименование портфеля */
    name: string

    /** Описание портфеля */
    description: string

    /** Организации */
    organizationsIds: number[]

    /** Список идентификаторов должностей */
    positionIds: number[]

    /** Список идентификаторов компетенций */
    competenceIds: number[]

    /** Идентификатор цели тестирования */
    purposeId: number

    /** Идентификатор типа теста */
    testTypeId: number

    /** Идентификаторы опросных листов */
    questionnaires: TestPortfolioQuestionnaireCreateContract[]

    /** Идентификаторы прикрепленных файлов */
    attachmentIds: number[]

    /** Ограничивать время прохождения */
    timeIsLimited: boolean

    /** Время ограничения в секундах */
    timeLimit: number

    /** Взять случайные вопросы */
    takeRandomQuestions: boolean

    /**  */
    presentation: TestPortfolioPresentationCreateContract

    /** Показывать правильный ответ на вопрос */
    showCorrectAnswer: boolean

    /** Показывать общий результат прохождения теста */
    showOverallTestScore: boolean

    /** Идентификатор родительской сущности */
    parentId: number

    /** Для всех организаций */
    forAllOrganizations: boolean

    /** Для всех должностей */
    forAllPositions: boolean
}

export interface TextDocumentAddContract {
    /** Наименование документа */
    name: string
}

export interface TextDocumentContract {
    /** Идентификатор документа */
    id: number

    /** Идентификатор документа в LDE */
    ldeId: string

    /** Наименование документа */
    name: string

    /** Автор документа */
    author: string

    /** Дата создания */
    creationDate: Date
}

export interface DocumentsPageEntitiesCountContract {
    /** Количество документов */
    documentsCount: number

    /** Количество заданий */
    missionsCount: number

    /** Количество отчётов LDE */
    ldeReportsCount: number
}

export interface TextDocumentSearchContract {
    /** Наименование документа */
    name: string

    /** ФИО автора документа */
    author: string

    /** Дата создания, старт диапазона */
    creationDateStart: string

    /** Дата создания финиш, диапазона */
    creationDateEnd: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface TextDocumentContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TextDocumentContract[]
}

export interface TextDocumentForModalContract {
    /** Идентификатор документа */
    id: number

    /** Наименование документа */
    name: string
}

export interface TextDocumentForModalContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TextDocumentForModalContract[]
}

export interface TextDocumentHomeworkItemContract {
    /** Заголовок домашнего задания */
    title: string

    /** Текст домашнего задания */
    text: string
}

export interface TextDocumentHomeworkTemplateContract {
    /** Внутренний идентификатор задачи */
    id: string

    /** Наименование документа */
    name: string

    /** Описание документа */
    description: string

    /** Задания */
    tasks: TextDocumentHomeworkItemContract[]

    /** Признак, отключена ли проверка орфографии */
    isDisableSpellCheck: boolean
}

export interface ThemeContract {
    /** Идентификатор */
    id: number

    /** Наименование */
    name: string

    /** Дата изменения */
    changeDate: Date

    /**  */
    changeUser: DictionaryContract

    /** Количество карточек продуктов */
    productCardCount: number

    /** Карточки продуктов */
    productCards: DictionaryContract[]
}

export interface ThemeCreateContract {
    /** Наименование тематики */
    name: string
}

export interface ThemeContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: ThemeContract[]
}

export interface ThemesSearchContract {
    /** Наименование темы */
    name: string

    /** Дата изменения, начало диапазона */
    changeDateStart: string

    /** Дата изменения, конец диапазона */
    changeDateEnd: string

    /** Автор */
    changeUserName: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface TrackAssistantIllustrationSearchContract {
    /** Наименование */
    name: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface TrackAssistantIllustrationContact {
    /** ИД изображения. */
    id: number

    /** Наименование */
    name: string

    /** Url. */
    url: string
}

export interface TrackAssistantIllustrationContactPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TrackAssistantIllustrationContact[]
}

export interface TrackCanvasBackgroundContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TrackCanvasBackgroundContract[]
}

export interface TrackCanvasBackgroundDeleteContract {
    /**  */
    type: TrackType

    /** Идентификатор трека */
    trackId: number
}

export interface TrackCanvasIconSearchContract {
    /**  */
    trackStageStatus: TrackStageStatus
}

export interface TrackStageIconBackgroundAlbumContract {
    /** Идентификатор альбома */
    id: number

    /** Наименование */
    name: string

    /** Фоны холстов иконок */
    iconBackgrounds: AttachmentContract[]
}

export interface TrackStageIconBackgroundAlbumCreateContract {
    /** Наименование */
    name: string

    /** Идентификаторы фонов холста */
    trackIconBackgroundIds: number[]

    /**  */
    stageStatus: TrackStageStatus
}

export interface TrackCanvasIconDeleteContract {
    /**  */
    type: TrackType

    /** Идентификатор трека */
    trackId: number
}

export interface TrackCanvasTemplateSearchContract {
    /** Наименование */
    name: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface TrackCanvasTemplateCreateContract {
    /** Наименование */
    name: string

    /** Холсты */
    trackCanvases: TrackCanvasCreateContract[]
}

export interface TrackCanvasTemplateContract {
    /** Идентификатор шаблона холста для трека адаптации */
    id: number

    /** Наименование */
    name: string

    /** Холсты */
    trackCanvases: TrackCanvasContract[]
}

export interface TrackDisplayPictureContract {
    /** Идентификатор */
    id: number

    /** Url файла вложения */
    url: string
}

export interface TrackHeaderIconContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TrackHeaderIconContract[]
}

export interface ActivityLessonPassingContentContract {
    /** Идентификатор прохождения этапа */
    activityLessonPassingId: number

    /** Идентификатор урока из шаблона */
    lessonId: number

    /** Наименование мероприятия\/контента */
    name: string

    /**  */
    passingStatus: ActivityPassingStatus

    /** Доступность урока */
    isAvailable: boolean
}

export interface TrackStageContentContract {
    /** Идентификатор этапа прохождения */
    id: number

    /** Заголовок этапа */
    title: string

    /** Доступность этапа */
    isAvailable: boolean

    /** Номер этапа */
    stageNumber: number

    /**  */
    contentIcon: AttachmentContract

    /**  */
    stageStatus: AppointmentTrackStatus

    /** Идентификатор прохождения мероприятия */
    activityPassingId: number

    /**  */
    materialType: TrackStageMaterialType

    /** Информация о мероприятиях */
    lessons: ActivityLessonPassingContentContract[]
}

export interface TrackStageAvailabilityContract {
    /** Блокирован периодом */
    isBlockedByPeriod: boolean

    /** Блокирован предыдущим не пройденным этапом */
    isBlockedByPreviousStage: boolean

    /** Блокирован превышением количества попыток */
    isBlockedByAttemptsCountExceed: boolean

    /** Период С */
    periodFrom: Date

    /** Период По */
    periodTo: Date

    /**  */
    availableStatus: TrackStageAvailableStatus
}

export interface TrackStagePassingContract {
    /**  */
    materialType: TrackStageMaterialType

    /** Идентификатор прохождения этапа */
    id: number

    /** Заголовок этапа */
    title: string

    /** Показывать прогресс прохождения */
    showProgress: boolean

    /** Общее количество тематических блоков мероприятия */
    totalThematicBlocksCount: number

    /** Пройденное количество тематических блоков мероприятия */
    passingThematicBlocksCount: number

    /** Дата прохождения */
    passingDate: Date

    /** Номер этапа */
    stageNumber: number

    /**  */
    status: AppointmentTrackStatus

    /**  */
    availability: TrackStageAvailabilityContract

    /**  */
    activity: DictionaryContract

    /** Количество попыток прохождения */
    attemptsCount: number

    /** Ограничение количество попыток прохождения мероприятия */
    activityAttemptCount: number

    /**  */
    course: DictionaryContract
}

export interface TrackAdditionalInfoContract {
    /** Документы */
    documents: CourseContract[]

    /** Видеофайлы */
    videos: CourseContract[]

    /**  */
    questionnaire: DictionaryContract

    /**  */
    greetingVideo: CourseContract

    /** Этапы */
    stages: DictionaryContract[]
}

export interface TrackPassingContract {
    /** Идентификатор формы прохождения */
    id: number

    /**  */
    status: AppointmentTrackStatus

    /**  */
    respondent: UserBaseContract

    /** Этапы */
    stages: TrackStagePassingContract[]

    /** Опрос пройден */
    isQuestionnairePassed: boolean

    /** Холсты */
    trackCanvases: TrackCanvasContract[]

    /** Заголовок трека */
    trackTitle: string

    /** Количество попыток прохождения */
    attemptsCount: number

    /** Лимит попыток прохождения */
    attemptsCountLimit: number

    /** Текущий этап */
    currentStageNumber: number

    /**  */
    trackAdditionalInfo: TrackAdditionalInfoContract

    /** Использовать информационного помощника */
    useInformationAssistant: boolean

    /** Использует ли трек визуализацию */
    isTrackWithVisualisation: boolean

    /**  */
    trackProgressVisualization: TrackProgressVisualizationContract

    /**  */
    trackMenuVisualization: TrackMenuVisualizationContract

    /** Иконки заголовка трека */
    trackHeaderIcons: TrackHeaderIconContract[]

    /**  */
    passingType: PassingType

    /**  */
    trackBackgroundPicture: PictureContract
}

export interface TrackPassingCurrentStageContract {
    /** Идентификатор назначения */
    appointmentId: number

    /** Номер этапа */
    stageNumber: number
}

export interface TrackQuestionnaireContract {
    /** Вопросы */
    questions: QuestionPublicContract[]

    /** Текущий вопрос */
    currentQuestionNumber: number
}

export interface TrackStageActivityPassingContract {
    /**  */
    activityPassing: ActivityPassingContract

    /**  */
    course: CourseContract

    /** Номер этапа */
    stageNumber: number

    /** Название этапа */
    stageTitle: string
}

export interface TrackStageStartContract {
    /** Это перезапуск */
    isRestart: boolean
}

export interface TrackStageContentPassingContract {
    /** Идентификатор этапа прохождения трека */
    trackStagePassingId: number

    /**  */
    course: CourseContract

    /** Прогресс прохождения (доля от 0 до 1.0 для PDF, в секундах для видео) */
    progress: number
}

export interface TrackStageExitContract {
    /** Прогресс прохождения (доля от 0 до 1.0 для PDF, в секундах для видео) */
    progress: number
}

export interface TrackPassingAssistantEventContract {
    /**  */
    event: TrackEvent

    /** Текст сообщения */
    message: string

    /**  */
    illustration: TrackPictureContract

    /**  */
    greetingVideo: CourseContract
}

export interface TrackStageAssistantMessageSearchContract {
    /** Идентификатор прохождения трека */
    trackPassingId: number

    /**  */
    trackStageEvent: TrackStageEvent

    /** Номер этапа трека */
    trackStageNumber: number
}

export interface TrackStagePassingAssistantMessageContract {
    /**  */
    event: TrackStageEvent

    /** Текст сообщения */
    message: string

    /**  */
    illustration: TrackPictureContract
}

export interface TrackPassingProgressContract {
    /** Всего очков */
    totalPoints: number

    /** Набрано очков */
    currentPoints: number

    /** Количество пройденных вебинаров */
    webinarsPassed: number

    /** Количество пройденных домашних заданий */
    homeworksPassed: number

    /** Количество пройденных тематических блоков */
    lessonsPassed: number

    /** Количество пользователей в назначении */
    respondentsCount: number

    /** Место в рейтинге */
    ratingPlace: number
}

export interface MguuTrackPassingStartContract {
    /** Перезапуск */
    isRestart: boolean

    /** Идентификатор сущности */
    id: number

    /**  */
    type: StartType
}

export interface MguuTrackPassingSearchContract {
    /** Идентификатор сущности */
    id: number

    /**  */
    type: StartType
}

export interface MguuTrackPassingStatusDto {
    /**  */
    id: number

    /**  */
    children: MguuTrackPassingStatusDto[]

    /**  */
    order: number

    /**  */
    code: string

    /**  */
    attemptCount: number

    /**  */
    status: AppointmentTrackStatus
}

export interface MguuTrackPassingContract {
    /** Идентификатор записи */
    id: number

    /**  */
    respondent: UserBaseContract

    /**  */
    rootStatusNode: MguuTrackPassingStatusDto
}

export interface MguuTrackPassingAnswerWriteContract {
    /** Идентификатор блока */
    blockId: number

    /** Текстовый ответ */
    answer: string

    /** Идентификатор вложения */
    attachmentId: number
}

export interface MguuTrackPassingAnswerReadContract {
    /** Текстовый ответ */
    answer: string

    /**  */
    attachment: AttachmentContract
}

export interface TracksMguuSearchContract {
    /** Наименование трека */
    name: string

    /** Тэги */
    tags: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface TracksMguuSearchResultContract {
    /** Id записи */
    id: number

    /** Наименование трека */
    name: string

    /** Тэги */
    tags: string

    /** Автоназначение */
    isAutoAssignment: boolean

    /** Доп материалы */
    isHasAdditionals: boolean
}

export interface TracksMguuSearchResultContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: TracksMguuSearchResultContract[]
}

export interface UserStructuralUnitPositionForUserProfileContract {
    /** Идентификатор связи пользователя с подразделениями и должностями */
    id: number

    /**  */
    structuralUnit: DictionaryDto

    /**  */
    position: DictionaryDto

    /** Дата вступления в должность */
    hireDate: Date

    /** Дата увольнения */
    fireDate: Date

    /** Признак основного места работы */
    isMain: boolean

    /** Идентификатор профиля должности */
    positionProfileId: number
}

export interface UserAdditionalContactForUserProfileContract {
    /** Значение контакта */
    value: string

    /** Тип дополнительного контакта */
    kindName: string
}

export interface UserForUserProfileContract {
    /** Флаг, архивный ли пользователь */
    isArchived: boolean

    /** Роли пользователя */
    roles: DictionaryDto[]

    /** Должности пользователя в подразделениях */
    structuralUnitsPositions: UserStructuralUnitPositionForUserProfileContract[]

    /** Дата изменения */
    changeDate: Date

    /** Мобильный телефон */
    mobilePhone: string

    /** Дата рождения */
    birthday: Date

    /** СНИЛС */
    snils: string

    /** Права доступа пользователя */
    permissions: UserPermission[]

    /** Изменялась ли сущность */
    isChanged: boolean

    /** Дополнительные контакты пользователя в ОИБ */
    oibAdditionalContacts: UserAdditionalContactForUserProfileContract[]

    /** Идентификатор пользователя */
    id: number

    /** Имя */
    firstName: string

    /** Фамилия */
    lastName: string

    /** Отчество */
    patronymic: string

    /** Email */
    email: string
}

export interface UserProfileContract {
    /**  */
    user: UserForUserProfileContract

    /**  */
    positionProfile: PositionProfileContract

    /** Дата последних изменений в профиле */
    lastChangesDate: Date
}

export interface UserDevelopmentGoalContract {
    /** Идентификатор записи */
    id: number

    /** Идентификатор связи пользователя, должности и структурной единицы */
    userStructuralUnitPositionId: number

    /**  */
    developmentGoal: DevelopmentGoalContract

    /** Дата изменения */
    changeDate: Date

    /**  */
    changeUser: UserFioContract
}

export interface UserDevelopmentGoalSaveContract {
    /** Идентификатор связи пользователя, должности и структурной единицы */
    userStructuralUnitPositionId: number

    /** Идентификаторы целей развития */
    developmentGoalIds: number[]
}

export interface UserProfileRecommendationContract {
    /**  */
    type: UserProfileRecommendationType

    /** Наименование */
    name: string

    /** Идентификатор мероприятия */
    activityId: number

    /** Идентификатор трека развития */
    developmentTrackId: number
}

export interface UserProfileFavoriteRecommendationContract {
    /** Идентификатор */
    id: number

    /**  */
    type: UserProfileRecommendationType

    /** Наименование */
    name: string

    /** Идентификатор мероприятия */
    activityId: number

    /** Идентификатор трека развития */
    developmentTrackId: number
}

export interface ChosenUserProfileFavoriteRecommendationContract {
    /**  */
    type: UserProfileRecommendationType

    /** Идентификатор мероприятия */
    activityId: number

    /** Идентификатор трека развития */
    developmentTrackId: number
}

export interface UserProfileFavoriteRecommentaionUpdateContract {
    /** Идентификатор пользователя */
    userId: number

    /** Отмеченные рекомендации */
    recommendations: ChosenUserProfileFavoriteRecommendationContract[]
}

export interface UsersSearchContract {
    /** Идентификаторы структурных единиц */
    structuralUnitsIds: number[]

    /** Идентификаторы должностей */
    positionsIds: number[]

    /** Дата изменения, начало диапазона */
    modifiedDateStart: string

    /** Дата изменения, конец диапазона */
    modifiedDateEnd: string

    /** ФИО пользователя */
    fullName: string

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface UserStructuralUnitPositionRegistryContract {
    /** Организации в реестре пользователей */
    structuralUnits: DictionaryContract[]

    /**  */
    position: DictionaryContract

    /** Дата вступления в должность */
    hireDate: Date

    /** Дата увольнения */
    fireDate: Date

    /** Признак основного места работы */
    isMain: boolean
}

export interface UserAdditionalContactRegistryContract {
    /** Значение контакта */
    value: string

    /** Тип дополнительного контакта */
    kindName: string
}

export interface UserStaffStatusTypeRegistryContract {
    /** Наименование кадрового состояния */
    name: string

    /** Дата начала текущего кадрового состояния */
    staffStatusStartDate: Date

    /** Дата окончания текущего кадрового состояния */
    staffStatusEndDate: Date
}

export interface UserRegistryContract {
    /** Идентификатор пользователя */
    id: number

    /** ФИО */
    fullName: string

    /** Электронная почта */
    email: string

    /** Изменялась ли сущность */
    isChanged: boolean

    /** Дата изменения */
    changeDate: Date

    /** Флаг, архивный ли пользователь */
    active: boolean

    /**  */
    lastArchiveVersion: UserRegistryContract

    /** Связки Подразделение - Должность для пользователя для реестра сотрудников */
    userStructuralUnitPositions: UserStructuralUnitPositionRegistryContract[]

    /** Дополнительные контакты пользователя в ОИБ для реестра сотрудников */
    oibAdditionalContacts: UserAdditionalContactRegistryContract[]

    /**  */
    userStaffStatusType: UserStaffStatusTypeRegistryContract
}

export interface UserRegistryContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: UserRegistryContract[]
}

export interface UserModalSearchContract {
    /** Идентификаторы структурных подразделений */
    structuralUnitsIds: number[]

    /** Идентификаторы должностей */
    positionsIds: number[]

    /** ФИО пользователя */
    fullName: string
}

export interface UserModalContract {
    /** Идентификатор пользователя */
    id: number

    /** Имя */
    firstName: string

    /** Фамилия */
    lastName: string

    /** Отчество */
    patronymic: string

    /** Должности пользователя в подразделениях */
    structuralUnitsAndPositions: UserStructuralUnitPositionContract[]
}

export interface UserModalContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: UserModalContract[]
}

export interface UserStructuralUnitPositionForSelectContract {
    /**  */
    unit: DictionaryContract

    /**  */
    organization: DictionaryContract

    /**  */
    position: DictionaryContract
}

export interface UserSelectedInModalContract {
    /** Связки Подразделение - Должность для пользователя */
    userStructuralUnitPositions: UserStructuralUnitPositionForSelectContract[]

    /** Идентификатор пользователя */
    id: number

    /** Имя */
    firstName: string

    /** Фамилия */
    lastName: string

    /** Отчество */
    patronymic: string

    /** Email */
    email: string
}

export interface UserSettingsCreateContract {
    /** Ключ */
    key: string

    /** Значение */
    value: string
}

export interface UserSettingsContract {
    /** Значение */
    value: string
}

export interface YourDevelopmentSearchContract {
    /**  */
    appointmentType: AppointmentType

    /** Какие назначения показывать */
    showPassedOnly: boolean

    /** Правила сортировки */
    orderRules: OrderRuleContract[]
}

export interface LessonForUserContract {
    /** Идентификатор урока */
    id: number

    /** Наименование урока */
    name: string

    /**  */
    status: ActivityPassingStatus
}

export interface ActivityLessonsForUserContract {
    /** Идентификатор мероприятия */
    id: number

    /** Наименование мероприятия */
    name: string

    /** Тематические блоки */
    lessons: LessonForUserContract[]

    /** Описание мероприятия */
    description: string

    /**  */
    activityPicture: PictureContract
}

export interface AppointmentActivitiesForCurrentUserContract {
    /** Идентиикатор назначения */
    id: number

    /**  */
    activity: ActivityLessonsForUserContract

    /**  */
    status: ActivityPassingStatus

    /** Дата начала */
    startDate: Date

    /** Дата окончания */
    endDate: Date

    /** Дата назначения респондента */
    participantAppointmentDate: Date

    /** Количество тематических блоков */
    lessonsCount: number

    /** Количество пройденных тематических блоков */
    passedLessonsCount: number

    /** Процент пройденных тематических блоков */
    passedLessonsPercentage: number
}

export interface ActivityForUserContract {
    /** Идентификатор */
    id: number

    /** Наименование мероприятия */
    name: string

    /**  */
    type: ActivityType

    /** Доступно до */
    availableTo: Date

    /** Доступно с */
    availableFrom: Date
}

export interface TrackStageListenerContract {
    /** Идентификатор */
    id: number

    /** Наименование этапа */
    name: string

    /** Номер этапа */
    stageNumber: number

    /**  */
    materialType: TrackStageMaterialType

    /**  */
    activity: ActivityForUserContract

    /**  */
    course: DictionaryContract

    /**  */
    status: AppointmentTrackStatus

    /**  */
    availability: TrackStageAvailabilityContract
}

export interface AppointmentTrackListenerContract {
    /** Идентификатор */
    id: number

    /** Идентификатор формы прохождения, если она существует */
    trackPassingId: number

    /**  */
    passingType: PassingType

    /**  */
    trackType: TrackType

    /** Шаблон трека */
    templateTrackId: number

    /** Дата запуска трека */
    periodFrom: Date

    /** Дата завершения трека */
    periodTo: Date

    /**  */
    status: AppointmentTrackStatus

    /** Описание трека */
    description: string

    /** Наименование */
    name: string

    /** Настройки этапа трека */
    stageSettings: AppointmentTrackStageSettingsContract[]

    /** Этапы */
    stages: TrackStageListenerContract[]

    /** Дата назначения респондента */
    respondentAppointmentDate: Date

    /** Количество этапов */
    stagesCount: number

    /** Количество пройденных этапов */
    passedStagesCount: number

    /** Процент пройденных этапов */
    passedStagesPercentage: number

    /**  */
    trackYourDevelopmentDisplay: PictureContract
}

export interface YourDevelopmentPageItemContract {
    /**  */
    type: YourDevelopmentPageItemType

    /** Дата назначения для сортировки */
    appointmentDate: Date

    /**  */
    activity: AppointmentActivitiesForCurrentUserContract

    /**  */
    track: AppointmentTrackListenerContract
}

export interface YourDevelopmentPageItemContractPageContract {
    /** Количество заэлементов всего */
    total: number

    /** Элементы страницы */
    pageItems: YourDevelopmentPageItemContract[]
}

export enum ActivityType {
    'Intramural' = 'Intramural',
    'Distant' = 'Distant',
    'Mixed' = 'Mixed',
}

export enum ActivityKind {
    'Teaching' = 'Teaching',
    'Introductory' = 'Introductory',
}

export enum DocumentStatus {
    'Draft' = 'Draft',
    'ReadyForWork' = 'ReadyForWork',
    'InWork' = 'InWork',
}

export enum ActivityDecorationType {
    'Standard' = 'Standard',
    'Visualization' = 'Visualization',
}

export enum SortDirection {
    'Asc' = 'Asc',
    'Desc' = 'Desc',
}

export enum UserPermission {
    'Administration' = 'Administration',
    'Developer' = 'Developer',
    'PositionProfilesRead' = 'PositionProfilesRead',
    'PositionProfileRead' = 'PositionProfileRead',
    'PositionProfileSave' = 'PositionProfileSave',
    'PositionProfileDelete' = 'PositionProfileDelete',
    'PositionProfilePublish' = 'PositionProfilePublish',
    'ThemesRead' = 'ThemesRead',
    'ThemeRead' = 'ThemeRead',
    'ThemeCreate' = 'ThemeCreate',
    'ThemeUpdate' = 'ThemeUpdate',
    'ThemeDelete' = 'ThemeDelete',
    'TestPortfoliosRead' = 'TestPortfoliosRead',
    'TestPortfolioRead' = 'TestPortfolioRead',
    'TestPortfolioSave' = 'TestPortfolioSave',
    'TestPortfolioDelete' = 'TestPortfolioDelete',
    'TestPortfolioPublish' = 'TestPortfolioPublish',
    'TestAppointmentsRead' = 'TestAppointmentsRead',
    'TestAppointmentRead' = 'TestAppointmentRead',
    'TestAppointmentSave' = 'TestAppointmentSave',
    'TestAppointmentPublish' = 'TestAppointmentPublish',
    'TestAppointmentPause' = 'TestAppointmentPause',
    'TestAppointmentDelete' = 'TestAppointmentDelete',
    'TestAppointmentContinue' = 'TestAppointmentContinue',
    'TestAppointmentMonitoring' = 'TestAppointmentMonitoring',
    'TestAppointmentCancel' = 'TestAppointmentCancel',
    'TestAppointmentStop' = 'TestAppointmentStop',
    'AssessmentPortfoliosRead' = 'AssessmentPortfoliosRead',
    'AssessmentPortfolio180270360Read' = 'AssessmentPortfolio180270360Read',
    'AssessmentPortfolioExpertRead' = 'AssessmentPortfolioExpertRead',
    'AssessmentPortfolioManagerRead' = 'AssessmentPortfolioManagerRead',
    'AssessmentPortfolioSave' = 'AssessmentPortfolioSave',
    'AssessmentPortfolioDelete' = 'AssessmentPortfolioDelete',
    'AssessmentPortfolioPublish' = 'AssessmentPortfolioPublish',
    'QuestionnairesRead' = 'QuestionnairesRead',
    'QuestionnaireAssessment180270360Reads' = 'QuestionnaireAssessment180270360Reads',
    'QuestionnaireAssessmentExpertReads' = 'QuestionnaireAssessmentExpertReads',
    'QuestionnaireAssessmentManagerReads' = 'QuestionnaireAssessmentManagerReads',
    'QuestionnaireQuizFeedbackRead' = 'QuestionnaireQuizFeedbackRead',
    'QuestionnaireQuizInvolvementRead' = 'QuestionnaireQuizInvolvementRead',
    'QuestionnaireQuizServiceQualityRead' = 'QuestionnaireQuizServiceQualityRead',
    'QuestionnaireQuizRecommendRead' = 'QuestionnaireQuizRecommendRead',
    'QuestionnaireTestRead' = 'QuestionnaireTestRead',
    'QuestionnaireSave' = 'QuestionnaireSave',
    'QuestionnaireDelete' = 'QuestionnaireDelete',
    'QuestionnairePublish' = 'QuestionnairePublish',
    'AssessmentAppointmentsRead' = 'AssessmentAppointmentsRead',
    'AssessmentAppointment180270360Read' = 'AssessmentAppointment180270360Read',
    'AssessmentAppointmentExpertRead' = 'AssessmentAppointmentExpertRead',
    'AssessmentAppointmentManagerRead' = 'AssessmentAppointmentManagerRead',
    'AssessmentAppointmentSave' = 'AssessmentAppointmentSave',
    'AssessmentAppointmentDelete' = 'AssessmentAppointmentDelete',
    'AssessmentAppointmentPublish' = 'AssessmentAppointmentPublish',
    'AssessmentAppointmentPause' = 'AssessmentAppointmentPause',
    'AssessmentAppointmentContinue' = 'AssessmentAppointmentContinue',
    'AssessmentAppointmentMonitoring' = 'AssessmentAppointmentMonitoring',
    'AssessmentAppointmentCancel' = 'AssessmentAppointmentCancel',
    'AssessmentAppointmentStop' = 'AssessmentAppointmentStop',
    'DevelopmentTracksRead' = 'DevelopmentTracksRead',
    'DevelopmentTrackRead' = 'DevelopmentTrackRead',
    'DevelopmentTrackSave' = 'DevelopmentTrackSave',
    'DevelopmentTrackDelete' = 'DevelopmentTrackDelete',
    'DevelopmentTrackPublish' = 'DevelopmentTrackPublish',
    'AdaptationTracksRead' = 'AdaptationTracksRead',
    'AdaptationTrackRead' = 'AdaptationTrackRead',
    'AdaptationTrackSave' = 'AdaptationTrackSave',
    'AdaptationTrackDelete' = 'AdaptationTrackDelete',
    'AdaptationTrackPublish' = 'AdaptationTrackPublish',
    'EventsRead' = 'EventsRead',
    'EventRead' = 'EventRead',
    'EventSave' = 'EventSave',
    'EventDelete' = 'EventDelete',
    'EventPublish' = 'EventPublish',
    'EventPassing' = 'EventPassing',
    'QuizAppointmentsRead' = 'QuizAppointmentsRead',
    'QuizAppointmentFeedbackRead' = 'QuizAppointmentFeedbackRead',
    'QuizAppointmentInvolmentRead' = 'QuizAppointmentInvolmentRead',
    'QuizAppointmentRecommendRead' = 'QuizAppointmentRecommendRead',
    'QuizAppointmentServiceQualityRead' = 'QuizAppointmentServiceQualityRead',
    'QuizAppointmentSave' = 'QuizAppointmentSave',
    'QuizAppointmentDelete' = 'QuizAppointmentDelete',
    'QuizAppointmentPublish' = 'QuizAppointmentPublish',
    'QuizAppointmentPause' = 'QuizAppointmentPause',
    'QuizAppointmentContinue' = 'QuizAppointmentContinue',
    'QuizAppointmentMonitoring' = 'QuizAppointmentMonitoring',
    'QuizAppointmentCancel' = 'QuizAppointmentCancel',
    'QuizAppointmentStop' = 'QuizAppointmentStop',
    'QuizPortfoliosRead' = 'QuizPortfoliosRead',
    'QuizPortfoliosFeedbackRead' = 'QuizPortfoliosFeedbackRead',
    'QuizPortfoliosInvolmentRead' = 'QuizPortfoliosInvolmentRead',
    'QuizPortfoliosRecommendRead' = 'QuizPortfoliosRecommendRead',
    'QuizPortfoliosServiceQualityRead' = 'QuizPortfoliosServiceQualityRead',
    'QuizPortfoliosSave' = 'QuizPortfoliosSave',
    'QuizPortfoliosDelete' = 'QuizPortfoliosDelete',
    'QuizPortfoliosPublish' = 'QuizPortfoliosPublish',
    'ScalesRead' = 'ScalesRead',
    'ScaleRead' = 'ScaleRead',
    'ScaleSave' = 'ScaleSave',
    'ScalePublish' = 'ScalePublish',
    'ScaleDelete' = 'ScaleDelete',
    'ComplexesRead' = 'ComplexesRead',
    'UsersRead' = 'UsersRead',
    'UsersProfileRead' = 'UsersProfileRead',
    'UserAssessmentResultRead' = 'UserAssessmentResultRead',
    'UserQuizResultRead' = 'UserQuizResultRead',
    'UserTestResultRead' = 'UserTestResultRead',
    'UsersViewAll' = 'UsersViewAll',
    'UsersViewDown' = 'UsersViewDown',
    'UsersViewUp' = 'UsersViewUp',
    'StructuralUnitsRead' = 'StructuralUnitsRead',
    'StaffUnitsRead' = 'StaffUnitsRead',
    'CompetenciesRead' = 'CompetenciesRead',
    'CompetenceRead' = 'CompetenceRead',
    'CompetenceSave' = 'CompetenceSave',
    'CompetenceExtraSave' = 'CompetenceExtraSave',
    'CompetenceDelete' = 'CompetenceDelete',
    'CompetencePublish' = 'CompetencePublish',
    'CompetenceImport' = 'CompetenceImport',
    'CompetenceGroupRead' = 'CompetenceGroupRead',
    'CompetenceGroupSave' = 'CompetenceGroupSave',
    'CompetenceGroupDelete' = 'CompetenceGroupDelete',
    'CompetenceGroupPublish' = 'CompetenceGroupPublish',
    'IndicatorRead' = 'IndicatorRead',
    'IndicatorSave' = 'IndicatorSave',
    'IndicatorDelete' = 'IndicatorDelete',
    'IndicatorPublish' = 'IndicatorPublish',
    'ProductCardsRead' = 'ProductCardsRead',
    'ProductCardRead' = 'ProductCardRead',
    'ProductCardSave' = 'ProductCardSave',
    'ProductCardPublish' = 'ProductCardPublish',
    'ProductCardDelete' = 'ProductCardDelete',
    'TrackAppointmentsRead' = 'TrackAppointmentsRead',
    'TrackAppointmentRead' = 'TrackAppointmentRead',
    'TrackAppointmentSave' = 'TrackAppointmentSave',
    'TrackAppointmentDelete' = 'TrackAppointmentDelete',
    'TrackAppointmentGo' = 'TrackAppointmentGo',
    'AppointmentActivitiesRead' = 'AppointmentActivitiesRead',
    'AppointmentActivityRead' = 'AppointmentActivityRead',
    'AppointmentActivitySave' = 'AppointmentActivitySave',
    'AppointmentActivityDelete' = 'AppointmentActivityDelete',
    'AppointmentActivityPublish' = 'AppointmentActivityPublish',
    'CoursesRead' = 'CoursesRead',
    'CourseRead' = 'CourseRead',
    'CourseSave' = 'CourseSave',
    'CourseDelete' = 'CourseDelete',
    'CoursePlay' = 'CoursePlay',
    'TextDocumentsRead' = 'TextDocumentsRead',
    'TextDocumentRead' = 'TextDocumentRead',
    'TextDocumentSave' = 'TextDocumentSave',
    'TextDocumentDelete' = 'TextDocumentDelete',
    'PurposesRead' = 'PurposesRead',
    'PurposeRead' = 'PurposeRead',
    'PurposeSave' = 'PurposeSave',
    'PurposeDelete' = 'PurposeDelete',
    'AssessmentProjectsRead' = 'AssessmentProjectsRead',
    'AssessmentProject360Read' = 'AssessmentProject360Read',
    'AssessmentProjectManagerRead' = 'AssessmentProjectManagerRead',
    'AssessmentProjectSave' = 'AssessmentProjectSave',
    'AssessmentProjectDelete' = 'AssessmentProjectDelete',
    'AssessmentProjectPublish' = 'AssessmentProjectPublish',
    'AssessmentProjectMonitoring' = 'AssessmentProjectMonitoring',
    'Lk' = 'Lk',
    'LkHomeworkCheck' = 'LkHomeworkCheck',
    'LkQuizCheck' = 'LkQuizCheck',
    'LkMeetingResult' = 'LkMeetingResult',
    'LkMonitoring' = 'LkMonitoring',
    'LkMonitoringViewAll' = 'LkMonitoringViewAll',
    'MguuTracksRead' = 'MguuTracksRead',
    'MguuTrackRead' = 'MguuTrackRead',
    'MguuTrackSave' = 'MguuTrackSave',
    'NotificationNewsletterTemplatesRead' = 'NotificationNewsletterTemplatesRead',
    'NotificationNewsletterTemplateRead' = 'NotificationNewsletterTemplateRead',
    'NotificationNewsletterTemplateSave' = 'NotificationNewsletterTemplateSave',
    'NotificationNewsletterTemplateDelete' = 'NotificationNewsletterTemplateDelete',
    'NotificationNewsletterTemplatePublish' = 'NotificationNewsletterTemplatePublish',
    'NotificationMailingTasksRead' = 'NotificationMailingTasksRead',
    'NotificationMailingTaskRead' = 'NotificationMailingTaskRead',
    'NotificationMailingTaskSave' = 'NotificationMailingTaskSave',
    'NotificationMailingTaskDelete' = 'NotificationMailingTaskDelete',
    'NotificationMailingTaskPause' = 'NotificationMailingTaskPause',
    'NotificationMailingTaskContinue' = 'NotificationMailingTaskContinue',
    'NotificationTagsRead' = 'NotificationTagsRead',
    'NotificationTagRead' = 'NotificationTagRead',
    'NotificationTagSave' = 'NotificationTagSave',
    'NotificationTagDelete' = 'NotificationTagDelete',
    'CertificateTemplateSave' = 'CertificateTemplateSave',
    'AssessmentReportsRead' = 'AssessmentReportsRead',
    'AssessmentReportRead' = 'AssessmentReportRead',
    'AssessmentReportTemplate360Save' = 'AssessmentReportTemplate360Save',
    'AssessmentReportTemplateManagerSave' = 'AssessmentReportTemplateManagerSave',
    'AssessmentReportEmployeeAccess' = 'AssessmentReportEmployeeAccess',
    'KnowledgeBaseRead' = 'KnowledgeBaseRead',
    'KnowledgeBaseArticleRead' = 'KnowledgeBaseArticleRead',
    'KnowledgeBaseArticleSave' = 'KnowledgeBaseArticleSave',
    'KnowledgeBaseArticleDelete' = 'KnowledgeBaseArticleDelete',
}

export enum LessonType {
    'Course' = 'Course',
    'Information' = 'Information',
    'Activity' = 'Activity',
    'Homework' = 'Homework',
    'Questionnaire' = 'Questionnaire',
    'FaceToFaceMeeting' = 'FaceToFaceMeeting',
    'WebConference' = 'WebConference',
}

export enum DayOfWeek {
    'Sunday' = 'Sunday',
    'Monday' = 'Monday',
    'Tuesday' = 'Tuesday',
    'Wednesday' = 'Wednesday',
    'Thursday' = 'Thursday',
    'Friday' = 'Friday',
    'Saturday' = 'Saturday',
}

export enum LessonAccessType {
    'Random' = 'Random',
    'StepByStep' = 'StepByStep',
}

export enum ActivityCanvasDesignType {
    'FullScreen' = 'FullScreen',
    'ModalWindow' = 'ModalWindow',
    'List' = 'List',
}

export enum ActivityPassingStatus {
    'Waiting' = 'Waiting',
    'InProgress' = 'InProgress',
    'Pause' = 'Pause',
    'CompletedSuccessfully' = 'CompletedSuccessfully',
    'CompletedUnsuccessfully' = 'CompletedUnsuccessfully',
    'Canceled' = 'Canceled',
}

export enum QuizType {
    'Engagement' = 'Engagement',
    'Random' = 'Random',
    'Feedback' = 'Feedback',
    'Recommend' = 'Recommend',
    'ServiceQuality' = 'ServiceQuality',
}

export enum QuestionnaireType {
    'Test' = 'Test',
    'Assessment' = 'Assessment',
    'Quiz' = 'Quiz',
}

export enum CourseFormat {
    'Scorm12' = 'Scorm12',
    'Scorm13' = 'Scorm13',
    'TinCan' = 'TinCan',
    'Pdf' = 'Pdf',
    'Avi' = 'Avi',
    'Mp4' = 'Mp4',
}

export enum QuestionType {
    'TextQuestion' = 'TextQuestion',
    'CriteriaScale' = 'CriteriaScale',
    'ChooseVariant' = 'ChooseVariant',
    'FillEmptyText' = 'FillEmptyText',
}

export enum HomeworkStatusType {
    'OnChecking' = 'OnChecking',
    'WaitingForSolution' = 'WaitingForSolution',
    'Revision' = 'Revision',
    'Passed' = 'Passed',
}

export enum AnswerConclusion {
    'NotChecked' = 'NotChecked',
    'Correct' = 'Correct',
    'Incorrect' = 'Incorrect',
    'HasNotAllCorrect' = 'HasNotAllCorrect',
    'HasCorrectAndIncorrect' = 'HasCorrectAndIncorrect',
    'RequiresPersonalChecking' = 'RequiresPersonalChecking',
}

export enum TrackStageEvent {
    'Start' = 'Start',
    'End' = 'End',
}

export enum TrackStageMaterialType {
    'Activity' = 'Activity',
    'Content' = 'Content',
}

export enum PassingType {
    'Consistently' = 'Consistently',
    'Inconsistently' = 'Inconsistently',
}

export enum TrackEvent {
    'Greeting' = 'Greeting',
    'Start' = 'Start',
    'End' = 'End',
}

export enum AdditionalsType {
    'Document' = 'Document',
    'Video' = 'Video',
}

export enum AdditionalsAvailability {
    'Always' = 'Always',
    'AfterPassing' = 'AfterPassing',
}

export enum AppointmentTrackStatus {
    'Draft' = 'Draft',
    'InWork' = 'InWork',
    'AvailableToPassing' = 'AvailableToPassing',
    'Appointment' = 'Appointment',
    'Ended' = 'Ended',
    'Suspended' = 'Suspended',
    'Canceled' = 'Canceled',
    'Passed' = 'Passed',
    'NotPassed' = 'NotPassed',
}

export enum ActivityPassingDateType {
    'ByActivity' = 'ByActivity',
    'ByLessons' = 'ByLessons',
}

export enum AssessmentType {
    'Estimate_180' = 'Estimate_180',
    'Estimate_270' = 'Estimate_270',
    'Estimate_360' = 'Estimate_360',
    'EstimateByManager' = 'EstimateByManager',
    'EstimateByExpert' = 'EstimateByExpert',
}

export enum AppointmentStatus {
    'Draft' = 'Draft',
    'Expectation' = 'Expectation',
    'InProgress' = 'InProgress',
    'Done' = 'Done',
    'Checked' = 'Checked',
    'Suspended' = 'Suspended',
    'Canceled' = 'Canceled',
    'Ended' = 'Ended',
}

export enum AssessmentRole {
    'Manager' = 'Manager',
    'Colleague' = 'Colleague',
    'Subordinate' = 'Subordinate',
    'Expert' = 'Expert',
    'GroupExpert' = 'GroupExpert',
    'SelfAssessment' = 'SelfAssessment',
    'ColleagueFromRelatedDepartment' = 'ColleagueFromRelatedDepartment',
}

export enum StructuralUnitType {
    'Complex' = 'Complex',
    'Oiv' = 'Oiv',
    'Organization' = 'Organization',
    'Unit' = 'Unit',
}

export enum ColorResult {
    'Green' = 'Green',
    'Orange' = 'Orange',
    'Red' = 'Red',
    'White' = 'White',
}

export enum TrackType {
    'Adaptation' = 'Adaptation',
    'Development' = 'Development',
    'Mguu' = 'Mguu',
}

export enum TrackAppointmentType {
    'ByStages' = 'ByStages',
    'ByTrack' = 'ByTrack',
}

export enum TrackStageAvailableStatus {
    'Available' = 'Available',
    'NotAvailable' = 'NotAvailable',
}

export enum TestSequence {
    'Consistently' = 'Consistently',
    'Inconsistently' = 'Inconsistently',
}

export enum ResultCalculatingType {
    'TotalPoints' = 'TotalPoints',
    'PassingPoints' = 'PassingPoints',
}

export enum AssessmentKind {
    'Intramural' = 'Intramural',
    'Distant' = 'Distant',
    'Mixed' = 'Mixed',
}

export enum NotificationIntervals {
    'EveryDay' = 'EveryDay',
    'EveryTwoDays' = 'EveryTwoDays',
    'EveryThreeDays' = 'EveryThreeDays',
}

export enum CompliancePositionProfile {
    'LowCompliance' = 'LowCompliance',
    'PartiallyCompliance' = 'PartiallyCompliance',
    'Compliance' = 'Compliance',
    'Disbalance' = 'Disbalance',
    'PartiallyExceedingCompliance' = 'PartiallyExceedingCompliance',
    'AbsolutelyExceedingCompliance' = 'AbsolutelyExceedingCompliance',
}

export enum StrongSidesAndZonesOfDevelopmentPriority {
    'One' = 'One',
    'Two' = 'Two',
    'Three' = 'Three',
    'Four' = 'Four',
}

export enum ProductType {
    'Track' = 'Track',
    'Activity' = 'Activity',
    'Content' = 'Content',
    'Book' = 'Book',
    'Podcast' = 'Podcast',
}

export enum StudyingFormat {
    'Intramural' = 'Intramural',
    'Distant' = 'Distant',
    'Mixed' = 'Mixed',
    'Track' = 'Track',
}

export enum GraduationDocumentType {
    'CertificateOfPassing' = 'CertificateOfPassing',
    'CertificateOfProfessionalDevelopment' = 'CertificateOfProfessionalDevelopment',
    'ProfessionalRetrainingDiploma' = 'ProfessionalRetrainingDiploma',
}

export enum ProductCardEnrollmentStatus {
    'New' = 'New',
    'InProcess' = 'InProcess',
    'Completed' = 'Completed',
    'Confirmed' = 'Confirmed',
}

export enum CertificateType {
    'Internal' = 'Internal',
    'External' = 'External',
}

export enum CertificateKind {
    'Activity' = 'Activity',
    'DevelopmentTrack' = 'DevelopmentTrack',
}

export enum ConsistencyResultType {
    'LowConsistency' = 'LowConsistency',
    'MediumConsistency' = 'MediumConsistency',
    'HighConsistency' = 'HighConsistency',
}

export enum CircleReportType {
    'Consolidated' = 'Consolidated',
    'CompetenceResult' = 'CompetenceResult',
    'Sydes' = 'Sydes',
}

export enum CourseValidationCaseContract {
    'AlreadyExists' = 'AlreadyExists',
    'InProcess' = 'InProcess',
    'NameNotUnique' = 'NameNotUnique',
    'NameNotSameAsInPrevious' = 'NameNotSameAsInPrevious',
    'StructuralUnitNotSameAsInPrevious' = 'StructuralUnitNotSameAsInPrevious',
    'AlreadyExistsAndUsingAppointment' = 'AlreadyExistsAndUsingAppointment',
}

export enum NotificationType {
    'Other' = 'Other',
    'Assessment' = 'Assessment',
    'Quiz' = 'Quiz',
    'Test' = 'Test',
    'Track' = 'Track',
    'Activity' = 'Activity',
    'LdeCreated' = 'LdeCreated',
    'Homework' = 'Homework',
}

export enum NotificationThemeType {
    'Scheduled' = 'Scheduled',
    'DeadlineIsApproaching' = 'DeadlineIsApproaching',
    'DeadlineViolation' = 'DeadlineViolation',
    'CancellingScheduled' = 'CancellingScheduled',
    'StartingDateApproaching' = 'StartingDateApproaching',
    'CompletionDateApproaching' = 'CompletionDateApproaching',
    'AppoinmentSuspending' = 'AppoinmentSuspending',
    'AppointmentResuming' = 'AppointmentResuming',
    'AppointmentCompleting' = 'AppointmentCompleting',
}

export enum EntityType {
    'None' = 'None',
    'Questionnaire' = 'Questionnaire',
    'AdaptationTrack' = 'AdaptationTrack',
    'DevelopmentTrack' = 'DevelopmentTrack',
    'MguuTrack' = 'MguuTrack',
    'AssessmentPortfolio' = 'AssessmentPortfolio',
    'AssessmentAppointment' = 'AssessmentAppointment',
    'QuizAppointment' = 'QuizAppointment',
    'TestAppointment' = 'TestAppointment',
    'Competence' = 'Competence',
    'Indicator' = 'Indicator',
    'IndicatorType' = 'IndicatorType',
    'CompetenceGroup' = 'CompetenceGroup',
    'User' = 'User',
    'AdaptationTrackAppointment' = 'AdaptationTrackAppointment',
    'DevelopmentTrackAppointment' = 'DevelopmentTrackAppointment',
    'MguuTrackAppointment' = 'MguuTrackAppointment',
    'Activity' = 'Activity',
    'PositionProfile' = 'PositionProfile',
    'ProductCard' = 'ProductCard',
    'Course' = 'Course',
    'Attachment' = 'Attachment',
    'TestPortfolio' = 'TestPortfolio',
    'Mission' = 'Mission',
    'QuizPortfolio' = 'QuizPortfolio',
    'Certificate' = 'Certificate',
    'NotificationNewsletterTemplate' = 'NotificationNewsletterTemplate',
    'NotificationMailingTask' = 'NotificationMailingTask',
    'TextDocument' = 'TextDocument',
    'AdaptationTrackStage' = 'AdaptationTrackStage',
    'DevelopmentTrackStage' = 'DevelopmentTrackStage',
    'ActivityLessonPassing' = 'ActivityLessonPassing',
    'CourseFile' = 'CourseFile',
    'TrackStagePassing' = 'TrackStagePassing',
    'TrackPassing' = 'TrackPassing',
    'ActivityPassing' = 'ActivityPassing',
    'PassingForm' = 'PassingForm',
    'HomeworkPassing' = 'HomeworkPassing',
}

export enum AppointmentType {
    'Track' = 'Track',
    'Activity' = 'Activity',
}

export enum FaceToFaceMeetingStatus {
    'WaitForSolution' = 'WaitForSolution',
    'Confirmed' = 'Confirmed',
}

export enum FreeAnswerTabType {
    'Text' = 'Text',
    'Comments' = 'Comments',
    'FreeAnswer' = 'FreeAnswer',
}

export enum NotificationDestinationCategoryCode {
    'Activity' = 'Activity',
    'Quiz' = 'Quiz',
    'Assessment' = 'Assessment',
    'Track' = 'Track',
    'Test' = 'Test',
}

export enum NotificationDestinationSubCategoryCode {
    'Adaptation' = 'Adaptation',
    'Development' = 'Development',
}

export enum NotificationConditionParameterType {
    'None' = 'None',
    'DateTime' = 'DateTime',
    'Integer' = 'Integer',
    'Enumeration' = 'Enumeration',
}

export enum NotificationMailingInfoStatus {
    'Expectation' = 'Expectation',
    'InProgress' = 'InProgress',
    'Canceled' = 'Canceled',
    'Ended' = 'Ended',
}

export enum NotificationMailingTaskType {
    'ByEvent' = 'ByEvent',
    'AtSpecifiedTime' = 'AtSpecifiedTime',
}

export enum EmailState {
    'New' = 'New',
    'Success' = 'Success',
    'Fail' = 'Fail',
    'Error' = 'Error',
}

export enum NotificationDeliveryStatus {
    'Send' = 'Send',
    'Delivered' = 'Delivered',
    'View' = 'View',
    'Click' = 'Click',
    'Unsend' = 'Unsend',
    'Undelivered' = 'Undelivered',
    'Unsubscribe' = 'Unsubscribe',
}

export enum NotificationMailingTaskStatus {
    'Draft' = 'Draft',
    'Expectation' = 'Expectation',
    'InProgress' = 'InProgress',
    'Suspended' = 'Suspended',
    'Canceled' = 'Canceled',
    'Ended' = 'Ended',
}

export enum NotificationEventCode {
    'StartTrack' = 'StartTrack',
    'DeadlineIsApproachingAtTrack' = 'DeadlineIsApproachingAtTrack',
    'DeadlineViolationAtTrack' = 'DeadlineViolationAtTrack',
    'ScheduledAtTrack' = 'ScheduledAtTrack',
    'CancellingScheduledAtTrack' = 'CancellingScheduledAtTrack',
    'CompletionAtTrack' = 'CompletionAtTrack',
    'CompletedAtTrack' = 'CompletedAtTrack',
    'StartAtTest' = 'StartAtTest',
    'DeadlineIsApproachingAtTest' = 'DeadlineIsApproachingAtTest',
    'DeadlineViolationAtTest' = 'DeadlineViolationAtTest',
    'ScheduledAtTest' = 'ScheduledAtTest',
    'CancellingScheduledAtTest' = 'CancellingScheduledAtTest',
    'CompletionAtTest' = 'CompletionAtTest',
    'StartAtQuiz' = 'StartAtQuiz',
    'DeadlineIsApproachingAtQuiz' = 'DeadlineIsApproachingAtQuiz',
    'DeadlineViolationAtQuiz' = 'DeadlineViolationAtQuiz',
    'ScheduledAtQuiz' = 'ScheduledAtQuiz',
    'CancellingScheduledAtQuiz' = 'CancellingScheduledAtQuiz',
    'CompletionAtQuiz' = 'CompletionAtQuiz',
    'StartAtActivity' = 'StartAtActivity',
    'DeadlineIsApproachingAtActivity' = 'DeadlineIsApproachingAtActivity',
    'DeadlineViolationAtActivity' = 'DeadlineViolationAtActivity',
    'ScheduledAtActivity' = 'ScheduledAtActivity',
    'CancellingScheduledAtActivity' = 'CancellingScheduledAtActivity',
    'CompletionAtActivity' = 'CompletionAtActivity',
    'StartAtAssessment' = 'StartAtAssessment',
    'DeadlineIsApproachingAtAssessment' = 'DeadlineIsApproachingAtAssessment',
    'DeadlineViolationAtAssessment' = 'DeadlineViolationAtAssessment',
    'ScheduledAtAssessment' = 'ScheduledAtAssessment',
    'CancellingScheduledAtAssessment' = 'CancellingScheduledAtAssessment',
    'CompletionAtAssessment' = 'CompletionAtAssessment',
    'AppointmentSuspendingAtAssessment' = 'AppointmentSuspendingAtAssessment',
    'AppointmentResumingAtAssessment' = 'AppointmentResumingAtAssessment',
    'UserCreated' = 'UserCreated',
    'HomeworkPassedAtTrack' = 'HomeworkPassedAtTrack',
    'HomeworkRevisionAtTrack' = 'HomeworkRevisionAtTrack',
    'HomeworkPassedAtActivity' = 'HomeworkPassedAtActivity',
    'HomeworkRevisionAtActivity' = 'HomeworkRevisionAtActivity',
    'ProductCardListenersConfirmed' = 'ProductCardListenersConfirmed',
    'StartIsApproachingAtAssessment' = 'StartIsApproachingAtAssessment',
    'StartIsApproachingAtQuiz' = 'StartIsApproachingAtQuiz',
    'StartIsApproachingAtTest' = 'StartIsApproachingAtTest',
    'AppointmentSuspendingAtQuiz' = 'AppointmentSuspendingAtQuiz',
    'AppointmentSuspendingAtTest' = 'AppointmentSuspendingAtTest',
    'AccessAtBlockOpenedAtTrack' = 'AccessAtBlockOpenedAtTrack',
    'ExpulsionAtTrack' = 'ExpulsionAtTrack',
    'ExpulsionAtActivity' = 'ExpulsionAtActivity',
    'ExpulsionAtAssessment' = 'ExpulsionAtAssessment',
    'ExpulsionAtQuiz' = 'ExpulsionAtQuiz',
    'ExpulsionAtTest' = 'ExpulsionAtTest',
    'StartIsApproachingAtTrack' = 'StartIsApproachingAtTrack',
    'StartIsApproachingAtActivity' = 'StartIsApproachingAtActivity',
    'ProductCardListenersSubmitted' = 'ProductCardListenersSubmitted',
    'ProductCardListenersCancelledByResponsible' = 'ProductCardListenersCancelledByResponsible',
}

export enum NotificationTemplateStatus {
    'Draft' = 'Draft',
    'Published' = 'Published',
}

export enum TestTypeCode {
    'Primary' = 'Primary',
    'Secondary' = 'Secondary',
}

export enum AgreementStatus {
    'New' = 'New',
    'OnApproval' = 'OnApproval',
    'Approved' = 'Approved',
}

export enum PositionProfileType {
    'GGS' = 'GGS',
    'RBS' = 'RBS',
}

export enum PositionProfileImportState {
    'ExistsAndUpdatable' = 'ExistsAndUpdatable',
    'ExistsAndNotUpdatable' = 'ExistsAndNotUpdatable',
    'New' = 'New',
    'HasErrors' = 'HasErrors',
}

export enum ProgramLevel {
    'Beginner' = 'Beginner',
    'Middle' = 'Middle',
    'Specialist' = 'Specialist',
}

export enum QuizCheckStatus {
    'InProcess' = 'InProcess',
    'WaitForSolution' = 'WaitForSolution',
    'Confirmed' = 'Confirmed',
}

export enum RecordEntityType {
    'Certificate' = 'Certificate',
    'Article' = 'Article',
    'NotificationMailingTask' = 'NotificationMailingTask',
    'ProductCards' = 'ProductCards',
    'AssessmentPortfolio' = 'AssessmentPortfolio',
    'TestPortfolio' = 'TestPortfolio',
    'AdaptationTrack' = 'AdaptationTrack',
    'DevelopmentTrack' = 'DevelopmentTrack',
    'TrackAppointment' = 'TrackAppointment',
    'AssessmentAppointment' = 'AssessmentAppointment',
    'TestAppointment' = 'TestAppointment',
    'QuizAppointment' = 'QuizAppointment',
    'ActivityAppointment' = 'ActivityAppointment',
    'Activity' = 'Activity',
    'PositionProfile' = 'PositionProfile',
    'UserQuizAndTest' = 'UserQuizAndTest',
    'UserAssessment' = 'UserAssessment',
    'UserDevelopment' = 'UserDevelopment',
}

export enum TrackStageStatus {
    'BeforeStart' = 'BeforeStart',
    'InProcess' = 'InProcess',
    'Passing' = 'Passing',
}

export enum StartType {
    'ByTrack' = 'ByTrack',
    'ByAppointment' = 'ByAppointment',
}

export enum UserProfileRecommendationType {
    'Activity' = 'Activity',
    'DevelopmentTrack' = 'DevelopmentTrack',
}

export enum YourDevelopmentPageItemType {
    'AdaptationTrack' = 'AdaptationTrack',
    'DevelopmentTrack' = 'DevelopmentTrack',
    'Mguu' = 'Mguu',
    'Activity' = 'Activity',
}
