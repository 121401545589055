import React from 'react'
import { FORM_IDS } from 'core/configs'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { PopupWithForm } from 'components/shared/PopupWithForm'
import { withPermission } from 'HOCs'

import { TableActionDeleteProps } from './DeleteTableAction.types'

export const DeleteTableAction: React.FC<TableActionDeleteProps> = React.memo(
    withPermission(({ tableRow, messageText, modalStyles }) => (
        <PopupWithForm
            formId={FORM_IDS.CONFIRM_DELETE_FORM}
            buttonText=""
            havePopup={false}
            buttonOption={{
                title: LOCAL.ACTIONS.DELETE,
                type: 'link',
                icon: <IconsAdapter iconType="DeleteOutlined" />,
            }}
            formOptions={{
                initialValues: tableRow,
            }}
            modalOptions={{
                title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                okText: LOCAL.YES,
                style: { ...modalStyles },
            }}
            messageText={messageText}
        />
    ))
)
