import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button } from 'components/shared/Button'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import {
    NotificationDynamicFieldMarkerSelectDto,
    NotificationDynamicFieldMarkersService,
} from 'core/api'
import { SelectControl } from 'components/controls/SelectControl'
import { normalizeDataForSelectAndRadioWithoutUniqueId } from 'utils'

import { DynamicMarkerControlProps } from './DynamicMarkerControl.types'

/** Контрол для добавления маркеров */
export const DynamicMarkerControl: FC<DynamicMarkerControlProps> = React.memo(
    ({ form, onInsertMarker, categoryId, eventId }) => {
        const [selectedMarker, setSelectedMarker] = useState<LabeledValue>()
        const [markers, setMarkers] = useState<
            NotificationDynamicFieldMarkerSelectDto[]
        >()

        useEffect(() => {
            const getOptions = async () => {
                if (categoryId) {
                    const markersByCategory = await NotificationDynamicFieldMarkersService.getForSelectByCategory(
                        { categoryId }
                    )

                    const markersWithoutCategory = await NotificationDynamicFieldMarkersService.getForSelectWithoutCategory()

                    setMarkers([
                        ...markersWithoutCategory,
                        ...markersByCategory,
                    ])

                    return
                }
            }

            getOptions()
        }, [categoryId])

        useEffect(() => {
            const getOptions = async () => {
                if (!eventId && !categoryId) {
                    return await NotificationDynamicFieldMarkersService.getForSelectAll()
                }

                if (eventId && !categoryId) {
                    return await NotificationDynamicFieldMarkersService.getForSelectWithoutCategory()
                }
            }

            getOptions()?.then((res) => {
                setMarkers(res)
            })
        }, [eventId, categoryId])

        useEffect(() => {
            if (!markers?.some(({ name }) => name === selectedMarker?.value)) {
                form?.resetFields(['dynamicMarker'])
                setSelectedMarker(undefined)
            }
        }, [markers, selectedMarker, form])

        const handleInsertMarker = useCallback(() => {
            if (!selectedMarker) return

            onInsertMarker(selectedMarker)
        }, [selectedMarker, onInsertMarker])

        return (
            <>
                <FormItemAdapter
                    fieldName="dynamicMarker"
                    label={LOCAL.LABELS.DYNAMIC_FIELD_MARKER}
                >
                    <SelectControl
                        labelInValue
                        showSearch
                        values={
                            markers?.map(
                                normalizeDataForSelectAndRadioWithoutUniqueId
                            ) || []
                        }
                        onSelect={setSelectedMarker}
                    />
                </FormItemAdapter>

                <Button type="primary" onClick={handleInsertMarker}>
                    {LOCAL.ACTIONS.INSERT_MARKER}
                </Button>
            </>
        )
    }
)
