import React from 'react'
import { LOCAL } from 'core/local'

import styles from './TrackLogo.module.scss'
import { TrackLogoComponentProps } from './TrackLogo.types'

export const TrackLogo: React.FC<TrackLogoComponentProps> = React.memo(
    ({ logo }) => {
        const logotype = <img src={logo.url} alt={LOCAL.LABELS.TRACK_LOGO} />

        return logo.link ? (
            <a
                href={logo.link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.wrapper}
            >
                {logotype}
            </a>
        ) : (
            <div className={styles.wrapper}> {logotype}</div>
        )
    }
)
