import React from 'react'
import { AddonAfterDefault, DynamicFormRows } from 'components/shared'
import {
    ControlGroup,
    FormItemAdapter,
    NotEditableTextField,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { QUIZ_TYPES } from 'consts'
import { QuizType } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getValue, renderQuestionnairesDropdown } from 'utils'

import { PollOptionsProps } from './PollOptions.types'

/**
 * Опции для типа документа "Опрос"
 */
export const PollOptions: React.FC<PollOptionsProps> = ({
    formMode,
    dictionaries,
    disabled,
}) => (
    <>
        <NotEditableTextField
            fieldName="quizType"
            label={LOCAL.LABELS.QUIZ_TYPE}
            normalizeValueFn={(type: QuizType) => QUIZ_TYPES[type]}
        />

        <Form.Item noStyle dependencies={['quizPortfolioId']}>
            {(form) => {
                const quizPortfolioId = form.getFieldValue('quizPortfolioId')

                return (
                    <FormItemAdapter
                        fieldName="quizPortfolioId"
                        label={LOCAL.LABELS.QUIZ_PORTFOLIO}
                        required
                    >
                        <SelectControl
                            allowClear
                            values={dictionaries?.quizPortfolios || []}
                            addonAfter={
                                <AddonAfterDefault
                                    url={`${ROUTE_NAMES.QUIZ_PORTFOLIOS_VIEW}/${quizPortfolioId}`}
                                    disabled={!quizPortfolioId}
                                />
                            }
                            dropdownRender={renderQuestionnairesDropdown({
                                url: ROUTE_NAMES.QUIZ_PORTFOLIOS_CREATE,
                            })}
                            formMode={formMode}
                            notFoundContent={LOCAL.LABELS.EMPTY_DATA}
                            disabled={disabled}
                        />
                    </FormItemAdapter>
                )
            }}
        </Form.Item>

        <ControlGroup titleOutside={LOCAL.LABELS.REVIEWERS} type="bordered">
            <Form.Item noStyle name="reviewerIds">
                <DynamicFormRows
                    formMode={formMode}
                    addBtnText={LOCAL.ACTIONS.ADD_REVIEWER}
                    disabled={disabled}
                />
            </Form.Item>
        </ControlGroup>

        <ControlGroup
            titleOutside={LOCAL.LABELS.QUIZ_RESPONDENTS}
            type="bordered"
            required
        >
            <ShouldUpdateChecker fieldPath={['organizationsIds']}>
                {({ getFieldValue }) => {
                    const structuralUnitsIds = getFieldValue(
                        'organizationsIds'
                    )?.map(getValue)

                    return (
                        <Form.Item noStyle name="participantsIds">
                            <DynamicFormRows
                                addBtnText={LOCAL.ACTIONS.ADD_PARTICIPANTS}
                                formMode={formMode}
                                structuralUnitsIds={structuralUnitsIds}
                                disabled={disabled}
                            />
                        </Form.Item>
                    )
                }}
            </ShouldUpdateChecker>
        </ControlGroup>
    </>
)
