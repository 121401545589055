import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import { Button, IconsAdapter } from 'components/shared'
import { Collapse } from 'antd'
import { LOCAL } from 'core/local'
import { Store } from 'App.types'
import { useElementVisible } from 'hooks'

import styles from './PassingNavigation.module.scss'
import { NavigationList } from '../NavigationList'
import { PassingNavigationProps } from './PassingNavigation.types'
import { isListCompleted } from './PassingNavigation.utils'
import { renderListTItle } from './PassingNavigation.render'

/** Навигационная панель прохождения тестов */
export const PassingNavigation: React.FC<PassingNavigationProps> = React.memo(
    ({
        selectedListIndex,
        setSelectedListIndex,
        isQuestionsListCompleted,
        confirmedAnswers,
        questionnaireListQuestions,
    }) => {
        const { elementVisible, toggleElementVisible } = useElementVisible()
        const [expandedIndexes, setExpandedIndexes] = useState<Store<boolean>>(
            {}
        )

        const toggleExpanded = useCallback(
            (index: number) => () => {
                setExpandedIndexes((prev) => ({
                    ...prev,
                    [index]: !prev[index],
                }))
            },
            []
        )

        const handleOpenList = useCallback(
            (key: string | string[]) => {
                setSelectedListIndex(+key)
            },
            [setSelectedListIndex]
        )

        const disableNotAvailableLists = (listIndex: number) => {
            const isPrevListCompeted = isQuestionsListCompleted(listIndex - 1)

            if (!isPrevListCompeted) return 'disabled'
        }

        return (
            <div className={styles.wrapper}>
                <Button
                    onClick={toggleElementVisible}
                    className={styles.button}
                    type="text"
                >
                    <IconsAdapter
                        iconType={
                            elementVisible ? 'CloseOutlined' : 'MenuOutlined'
                        }
                    />
                </Button>

                {elementVisible && (
                    <div className={styles.menu}>
                        <Collapse
                            activeKey={selectedListIndex}
                            accordion
                            defaultActiveKey={0}
                            ghost
                            onChange={handleOpenList}
                        >
                            {questionnaireListQuestions.map((list, index) => (
                                <Collapse.Panel
                                    key={index}
                                    header={renderListTItle(
                                        list,
                                        confirmedAnswers
                                    )}
                                    collapsible={disableNotAvailableLists(
                                        index
                                    )}
                                    className={cn(
                                        isListCompleted(
                                            list,
                                            confirmedAnswers
                                        ) && styles.listCompleted
                                    )}
                                >
                                    <p className={styles.textSecondary}>
                                        {`${LOCAL.LABELS.GO_TO_QUESTION}:`}
                                    </p>

                                    <NavigationList
                                        list={list}
                                        listIndex={index}
                                        confirmedAnswers={confirmedAnswers}
                                        isExpanded={expandedIndexes[index]}
                                        toggleExpanded={toggleExpanded}
                                    />
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </div>
                )}
            </div>
        )
    }
)
