import React, { useCallback, useContext, useState } from 'react'
import {
    InfoAssistant,
    PageContent,
    VideoContentModal,
} from 'components/shared'
import { LessonType, TrackEvent, TrackPassingsService } from 'core/api'
import {
    isThemeBlockTypeVideoCourse,
    isTrainingMaterialFormatTinCan,
} from 'utils'

import styles from './ContentLayer.module.scss'
import { ContentLayerProps } from './ContentLayer.types'
import { InfoAssistantChecker } from '../InfoAssistantChecker'
import { LayerHeader } from './components'
import { ThematicBlockContent } from '../ThematicBlockLayer/components'
import { TrackPassingContext } from '../../TrackPassing.context'

/**
 * Слой контента, выбранного для определенно этапа трека адаптации
 */
export const ContentLayer: React.FC<ContentLayerProps> = React.memo(
    ({
        data,
        updateInfoAboutPassing,
        isTrackWithVisualisation,
        onThemeBlockByContentId,
        refetchTrackInfo,
        isShowInfoAssistant,
        updateTrackInfo,
        updateProgress,
    }) => {
        const { store } = useContext(TrackPassingContext)

        const [infoAssistantVisible, setInfoAssistantVisible] = useState(
            isShowInfoAssistant
        )

        /**
         * Закрыть прохождения контента этапа трека
         */
        const handleCloseContentLayer = useCallback(() => {
            updateInfoAboutPassing(undefined)
            refetchTrackInfo(true)
            onThemeBlockByContentId(undefined)
            setInfoAssistantVisible(false)

            if (!isTrackWithVisualisation) {
                updateTrackInfo?.()
            }
        }, [
            updateInfoAboutPassing,
            refetchTrackInfo,
            onThemeBlockByContentId,
            isTrackWithVisualisation,
            updateTrackInfo,
        ])

        /**
         * Обработчик ознакомления
         */
        const handleViewContent = useCallback(async () => {
            try {
                store.updateLoader(true)

                await TrackPassingsService.finishTrackStageWithContent({
                    trackStagePassingId: data.trackStagePassingId,
                })

                updateProgress?.()
                handleCloseContentLayer()
            } catch (error) {
                console.error(error)
            } finally {
                store.updateLoader(false)
            }
        }, [
            data.trackStagePassingId,
            handleCloseContentLayer,
            store,
            updateProgress,
        ])

        const handleExitContentLayer = useCallback(
            async (progress = 0) => {
                try {
                    store.updateLoader(true)

                    await TrackPassingsService.exitTrackStageWithContent({
                        trackStagePassingId: data.trackStagePassingId,
                        body: { progress },
                    })

                    handleCloseContentLayer()
                } catch (error) {
                    console.error(error)
                } finally {
                    store.updateLoader(false)
                }
            },
            [data.trackStagePassingId, store, handleCloseContentLayer]
        )

        if (isThemeBlockTypeVideoCourse(data)) {
            return (
                <VideoContentModal
                    fileUrl={data.course.launchUrl}
                    progress={data.progress}
                    onFinish={handleViewContent}
                    onExit={handleExitContentLayer}
                    popupProps={{
                        visible: true,
                    }}
                />
            )
        }

        return (
            <PageContent className={styles.wrapper}>
                {!isTrainingMaterialFormatTinCan(data.course.format) && (
                    <LayerHeader
                        {...data}
                        onStopContent={handleCloseContentLayer}
                        onViewContent={handleViewContent}
                        isTrackWithVisualisation={isTrackWithVisualisation}
                    />
                )}

                {data && (
                    <ThematicBlockContent
                        course={data.course}
                        type={LessonType.Course}
                        onViewMaterial={handleViewContent}
                    />
                )}

                <InfoAssistantChecker>
                    <InfoAssistant
                        mode="passing"
                        event={TrackEvent.Start}
                        visible={infoAssistantVisible}
                    />
                </InfoAssistantChecker>
            </PageContent>
        )
    }
)
