import React, { useEffect } from 'react'
import { Circle360ReportService } from 'core/api'
import {
    INDICATORS_HIGHER,
    INDICATORS_LOWER,
} from 'core/local/components/HiddenOpportunitiesProblems.local.config'
import { LOCAL } from 'core/local'
import { TableAdapter } from 'components/shared'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { safelySetInnerHtml } from 'utils'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './HiddenOpportunitiesProblems.module.scss'
import { getColumns } from './HiddenOpportunitiesProblems.utils'

/** Отчет по оценке 360. Скрытые возможности и проблемы */
export const HiddenOpportunitiesProblems: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getHiddenPossibilities, hiddenPossibilities] = useHttp(
        Circle360ReportService.getHiddenPossibilities
    )
    const [getHiddenProblems, hiddenProblems] = useHttp(
        Circle360ReportService.getHiddenProblems
    )

    useEffect(() => {
        if (reportId) {
            getHiddenPossibilities({ id: Number(reportId) })
            getHiddenProblems({ id: Number(reportId) })
        }
    }, [getHiddenPossibilities, getHiddenProblems, reportId])

    return (
        <>
            <p className={styles.description}>
                {
                    LOCAL.MESSAGES
                        .REPORT_HIDDEN_OPPORTUNITIES_AND_PROBLEMS_MESSAGE
                }
            </p>

            <h2 className={styles.header}>{LOCAL.LABELS.HIDDEN_FEATURES}</h2>

            <p
                className={styles.description}
                dangerouslySetInnerHTML={safelySetInnerHtml(INDICATORS_HIGHER)}
            />

            <div className={styles.tables}>
                {hiddenPossibilities?.map((el) => (
                    <TableAdapter
                        wrapperClassName={styles.table}
                        columns={getColumns(el.competence.name)}
                        dataSource={el.indicators}
                        rowKey={(row) => row.indicator?.id}
                        pagination={false}
                        key={el.competence.id}
                    />
                ))}
            </div>

            <h2 className={styles.header}>{LOCAL.LABELS.HIDDEN_PROBLEMS}</h2>

            <p
                className={styles.description}
                dangerouslySetInnerHTML={safelySetInnerHtml(INDICATORS_LOWER)}
            />

            <div className={styles.tables}>
                {hiddenProblems?.map((el) => (
                    <TableAdapter
                        wrapperClassName={styles.table}
                        columns={getColumns(el.competence.name)}
                        dataSource={el.indicators}
                        rowKey={(row) => row.indicator?.id}
                        pagination={false}
                        key={el.competence.id}
                    />
                ))}
            </div>
        </>
    )
})
