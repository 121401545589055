import React from 'react'
import { APPOINTMENT_TYPES, FACE_TO_FACE_MEETING_STATUSES } from 'consts'
import {
    AppointmentType,
    FaceToFaceMeetingJournalRegistryContract,
    FaceToFaceMeetingStatus,
} from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { LOCAL } from 'core/local'
import { formatDate, restrictBigText } from 'utils'

import { ResultsOfMeetingsActions } from './components'

export const getTableColumnsConfig = (
    props: DictionaryPageActionsProps
): ColumnsType<FaceToFaceMeetingJournalRegistryContract> => [
    {
        title: LOCAL.LABELS.ASSIGNMENT,
        dataIndex: 'appointmentName',
        key: 'appointmentName',
        sorter: defaultSorter,
        render: restrictBigText,
        fixed: 'left',
    },
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'appointmentType',
        key: 'appointmentType',
        render: (type: AppointmentType) => APPOINTMENT_TYPES[type],
    },
    {
        title: LOCAL.LABELS.MEETING,
        dataIndex: 'meetingName',
        key: 'meetingName',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.LISTENERS,
        dataIndex: 'listenersCount',
        key: 'listenersCount',
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'beginDate',
        key: 'beginDate',
        sorter: defaultSorter,
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status: FaceToFaceMeetingStatus) =>
            FACE_TO_FACE_MEETING_STATUSES[status],
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (row: FaceToFaceMeetingJournalRegistryContract) => (
            <ResultsOfMeetingsActions
                tableRow={row}
                reFetchDictionary={props.reFetchDictionary}
            />
        ),
        fixed: 'right',
    },
]
