import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import { DataNode } from 'rc-tree-select/lib/interface'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps, normalizeDataForTreeSelect } from 'utils'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import {
    StructuralUnitPositionSearchContract,
    StructuralUnitsService,
} from 'core/api'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'

import { POSITION_PROFILES } from './OrganizationsFilters.consts'

/**
 * Форма фильтрации организаций
 */
export const OrganizationsFilters: React.FC<FiltersProps<
    StructuralUnitPositionSearchContract
>> = React.memo((props) => {
    const [structuralUnits, setStructuralUnits] = useState<DataNode[]>()

    const mapFormDataToRequest = useCallback(
        (values: Partial<StructuralUnitPositionSearchContract>) => ({
            ...values,
            hasLinkedPositionProfile:
                values.hasLinkedPositionProfile !== undefined
                    ? Boolean(values.hasLinkedPositionProfile)
                    : undefined,
            structuralUnitsIds: values.structuralUnitsIds,
        }),
        []
    )

    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                const structuralUnitsData = await StructuralUnitsService.getAllStructuralUnits()
                setStructuralUnits(
                    structuralUnitsData.map(normalizeDataForTreeSelect)
                )
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                renderFooter={renderFooterWithShowArchiveField}
                mapFormDataToRequest={mapFormDataToRequest}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitsIds"
                            label={LOCAL.LABELS.STRUCTURAL_UNIT_NAME}
                        >
                            <TreeSelectControl
                                treeData={structuralUnits || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="hasLinkedPositionProfile"
                            label={LOCAL.LABELS.POSITION_PROFILE}
                        >
                            <SelectControl values={POSITION_PROFILES} />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changeDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changeDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
