import {
    CompetenciesService,
    DictionaryContract,
    PositionProfileArchivesService,
    PositionProfileCompetenceContract,
    PositionProfileDictionariesService,
    PositionProfilePublishContract,
    PositionProfileVersionContract,
    PositionsService,
    StaffUnitsService,
    StructuralUnitType,
    StructuralUnitsService,
} from 'core/api'
import { DATE_FORMAT, DOCUMENT_STATUSES } from 'consts'
import { FetchResultProps } from 'hooks'
import { FnActionProps, FnActionRequiredProps, Store } from 'App.types'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    createConfirmPopup,
    formatDate,
    getDateByFormat,
    getDayWithTime,
    getFormPopupTitle,
    getShortFio,
    getUniqueItemsArray,
    isFormModeCopy,
    isFormModeCreate,
    isFormModeEdit,
    isFormModeView,
    isObjectEmpty,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useCallback, useEffect, useState } from 'react'

import {
    CallConfirmPopupProps,
    FecthStructuralUnitsProps,
    GetInitialValueForExistingProfile,
    GetStuffUnitsStructure,
    GetVersionsProps,
    JobProfileDictionariesStateProps,
    StaffUnitChangeProps,
    StructuralUnitsProps,
    UpdateCompetenciesGroups,
    UpdateStructuralUnits,
} from './JobProfileForm.types'
import {
    PARENT_CHILD_DICTIONARY,
    PARENT_FORM_VALUES_DICTIONARY,
} from './JobProfileForm.consts'
import { mapResponseToFormData } from './JobProfileForm.utils.maps'

/**
 * Нормализуем данные для вывода в селекте значений версий профиля должности
 */
export const normalizeDataForSelectVersion = (
    el: PositionProfileVersionContract
): LabeledValue => ({
    value: el.version,
    label: `Версия ${el.version} ${getShortFio({
        firstName: el.firstName,
        lastName: el.lastName,
        patronymic: el.patronymic,
    })} ${formatDate(el.modifiedDate)} ${DOCUMENT_STATUSES[el.status]}`,
    key: `${el.version}-${el.modifiedDate}`,
})

/** Хук для получения ids групп компетенций по-умолчанию */
export const useDefaultCompetenceGroupsIds = () => {
    const [
        defaultCompetenceGroupsIds,
        setDefaultCompetenceGroupsIds,
    ] = useState<number[]>()

    const defaultCompetenceGroupsIdsFetch = useCallback(async () => {
        try {
            const defaultCompetenceItems = await PositionProfileDictionariesService.getDefaultCompetenceGroups()

            const uniqueGroupsIds = getUniqueItemsArray(
                defaultCompetenceItems.map(
                    (competence) => competence.competenceGroup.id
                )
            )

            setDefaultCompetenceGroupsIds(uniqueGroupsIds)
        } catch (error) {
            console.error(error)
        }
    }, [setDefaultCompetenceGroupsIds])

    /** Запрашиваем группы компетенций по-умолчанию при инициализации хука в компоненте*/
    useEffect(() => {
        defaultCompetenceGroupsIdsFetch()
    }, [defaultCompetenceGroupsIdsFetch])

    return { defaultCompetenceGroupsIds }
}

/**
 * Функция выполняет обход массива promises, получая из него ids групп компетенций
 * @param dependencyFieldsPromises - массив promises с результатами текущих компетенций для полей
 *     влияющих на рендер групп компетенций
 * @param defaultCompetenceGroupsIds - массив ids групп компетенций добавляемых по-умолчанию
 * @returns {number[]} - массив уникальных идентификаторов групп компетенций
 */
export const getCompetenceGroupsByDependenceFields = async (
    dependencyFieldsPromises: Array<
        Promise<PositionProfileCompetenceContract[]>
    >,
    defaultCompetenceGroupsIds?: number[]
) => {
    try {
        const competenciesFetchResult = await Promise.all(
            dependencyFieldsPromises
        )

        let groupIds = competenciesFetchResult.reduce<number[]>(
            (acc, el) => [
                ...acc,
                ...el.map((competence) => competence.competenceGroup.id),
            ],
            []
        )

        if (defaultCompetenceGroupsIds) {
            groupIds = groupIds.concat(defaultCompetenceGroupsIds)
        }

        return getUniqueItemsArray(groupIds)
    } catch (error) {
        console.error(error)
    }
}

/**
 * Добавить к начальным значениям для создания имя, если создание из реестра организаций
 * @param initialValuesToAdd - начальные значения
 * @param dictionaries - справочники компонента
 */
export const getInitialProfileName = (
    initialValuesToAdd?: Partial<PositionProfilePublishContract>,
    dictionaries?: Partial<JobProfileDictionariesStateProps>
) => {
    if (!initialValuesToAdd || !dictionaries) return

    const { positionId, oivId } = initialValuesToAdd

    let name = ''

    const positionName =
        dictionaries.positions?.find((el) => el.value === positionId)?.label ||
        ''

    const date = getDateByFormat(getDayWithTime(), DATE_FORMAT.WITH_TIME)

    if (positionId) {
        name = `${positionName} ${date}`
    }

    if (positionId && oivId) {
        const oivName =
            dictionaries.oiv?.find((el) => el.value === oivId)?.label || ''

        name = `${positionName} ${oivName} ${date}`
    }

    return { name }
}

/**
 * Обработчик для выбора опции селекта
 */
export const handleSelectVersion = (
    goTo: FnActionProps,
    positionProfileId?: number,
    positionProfileVersion?: number
) => (selectVersion: number) => {
    if (!positionProfileId) return

    if (selectVersion === positionProfileVersion)
        goTo(`${ROUTE_NAMES.POSITION_PROFILES_VIEW}/${positionProfileId}`)
    else {
        goTo(
            `${ROUTE_NAMES.POSITION_PROFILES_VIEW}/${positionProfileId}/archives/${selectVersion}`
        )
    }
}

/**
 * Запрос справочников
 */
export const dictionaryFetch = (
    fetchFn: FnActionRequiredProps<FetchResultProps>
) => {
    fetchFn({
        industries: [
            PositionProfileDictionariesService.getAllIndustries(),
            'treeSelect',
        ],
        accessForms: PositionProfileDictionariesService.getAllAccessForms(),
        corruptionRisks: PositionProfileDictionariesService.getAllCorruptionRisks(),
        dismissalRisks: PositionProfileDictionariesService.getAllDismissalRisks(),
        functionClasses: [
            PositionProfileDictionariesService.getAllFunctionClasses(),
            'treeSelect',
        ],
        levels: PositionProfileDictionariesService.getAllLevels(),
        positions: PositionsService.getAll(),
        subordinations: PositionProfileDictionariesService.getAllSubordinations(),
        experiences: PositionProfileDictionariesService.getAllExperiences(),
        governmentExperiences: PositionProfileDictionariesService.getAllGovernmentExperiences(),
        manageExperiences: PositionProfileDictionariesService.getAllManageExperiences(),
        subordinatesNumbers: PositionProfileDictionariesService.getAllSubordinatesNumbers(),
        educationLevels: [
            PositionProfileDictionariesService.getAllEducationLevels(),
            'treeSelect',
        ],
        educationProfiles: [
            PositionProfileDictionariesService.getAllEducationProfiles(),
            'treeSelect',
        ],
        supervisingComplexes: StructuralUnitsService.getOrganizationsByUnitType(
            { type: StructuralUnitType.Complex }
        ),
    })
}

export const callConfirmPopup = ({
    isSaveDocument,
    initialValuesForEdit,
    form,
    reFetchQuizPortfolio,
    goTo,
}: CallConfirmPopupProps) =>
    createConfirmPopup({
        title: getFormPopupTitle(getRouteMeta(ROUTE_NAMES.POSITION_PROFILES)),
        content: isSaveDocument
            ? LOCAL.MESSAGES.JOB_PROFILE_SAVE_SUCCESS_MESSAGE
            : LOCAL.MESSAGES.JOB_PROFILE_PUBLISH_SUCCESS_MESSAGE,
        onOk: () => goTo(ROUTE_NAMES.POSITION_PROFILES),
        onCancel: () => {
            if (!initialValuesForEdit?.id) form.resetFields()
            else reFetchQuizPortfolio?.(initialValuesForEdit?.id)
        },
    })

const getAllOfChildTypeElemnts = async (parent: DictionaryContract) =>
    parent
        ? await StructuralUnitsService.getAllOfChildTypeElemntsByParentId({
              parentId: parent.id,
          })
        : undefined

export const fetchStructuralUnits = async ({
    initialValuesForEdit,
    fetchStaffUnitStructure,
    handleChangeDictionaries,
}: FecthStructuralUnitsProps) => {
    try {
        if (!initialValuesForEdit) return

        const {
            supervisingComplex,
            oiv,
            organization,
            staffUnit: initialStaffUnit,
        } = initialValuesForEdit

        let staffUnit: DictionaryContract | undefined

        if (initialStaffUnit?.id) {
            staffUnit = await fetchStaffUnitStructure(initialStaffUnit.id)
        }

        const oivData = await getAllOfChildTypeElemnts(supervisingComplex)

        const organizationData = await getAllOfChildTypeElemnts(oiv)

        const structuralUnitsData = await getAllOfChildTypeElemnts(organization)

        handleChangeDictionaries({
            oiv: oivData?.map(normalizeDataForSelectAndRadio),
            organization: organizationData?.map(normalizeDataForSelectAndRadio),
            structuralUnits: structuralUnitsData?.map(
                normalizeDataForSelectAndRadio
            ),
            ...{
                ...(staffUnit
                    ? {
                          staffUnits: [
                              normalizeDataForSelectAndRadio(staffUnit),
                          ],
                      }
                    : {}),
            },
        })
    } catch (error) {
        console.error(error)
    }
}

export const fetchStructuralUnitParents = async ({
    initialValuesForAdd,
    fetchStaffUnitStructure,
    handleChangeDictionaries,
    setInitialOivId,
    form,
    handleFetchDictionaries,
}: StructuralUnitsProps) => {
    try {
        const unitId =
            initialValuesForAdd?.oivId ||
            initialValuesForAdd?.organization ||
            initialValuesForAdd?.structuralUnitId

        if (initialValuesForAdd?.staffUnitId) {
            const staffUnit = await fetchStaffUnitStructure(
                initialValuesForAdd?.staffUnitId
            )
            handleChangeDictionaries({
                staffUnits: [normalizeDataForSelectAndRadio(staffUnit)],
            })
        }

        if (!unitId) return

        const structuralUnitParents = await StructuralUnitsService.getAllParentElemntsByStructuralUnitId(
            {
                unitId,
            }
        )

        let structuralUnitParentsFormValues: {
            [unitType: string]: number
        } = {}

        /**
         * Пакет запросов справочников для oiv, organization, structuralUnits
         */
        const requestsBundle = structuralUnitParents.reduce<
            Store<Promise<DictionaryContract[]>>
        >((acc, unit) => {
            const parentFormValueDictionary =
                PARENT_FORM_VALUES_DICTIONARY[unit.type]

            /**
             * Если значение по ключу unit.type уже присутствует, то его не надо перетирать,
             * т.к. оно должно быть id самого младшего в цепочке подразделения
             */
            if (!structuralUnitParentsFormValues[parentFormValueDictionary]) {
                structuralUnitParentsFormValues = {
                    ...structuralUnitParentsFormValues,
                    [parentFormValueDictionary]: unit.id,
                }
            }

            return {
                ...acc,
                [PARENT_CHILD_DICTIONARY[
                    unit.type as Exclude<
                        StructuralUnitType,
                        StructuralUnitType.Unit
                    >
                ]]: StructuralUnitsService.getAllOfChildTypeElemntsByParentId({
                    parentId: unit.id,
                }),
            }
        }, {})

        if (structuralUnitParentsFormValues.oivId) {
            setInitialOivId(structuralUnitParentsFormValues.oivId)
        }

        form.setFieldsValue(structuralUnitParentsFormValues)
        handleFetchDictionaries(requestsBundle)
    } catch (error) {
        console.error(error)
    }
}

export const getVersions = async ({
    id,
    setVersions,
    initialValuesForEdit,
}: GetVersionsProps) => {
    try {
        const versionsData = await PositionProfileArchivesService.getVersions({
            id,
        })

        const changeUserNameArr = initialValuesForEdit?.changeUserName?.split(
            ' '
        )

        const currentVersion = {
            id: initialValuesForEdit?.version,
            modifiedDate: initialValuesForEdit?.changeDate,
            version: initialValuesForEdit?.version,
            firstName: changeUserNameArr?.[1],
            lastName: changeUserNameArr?.[0],
            patronymic: changeUserNameArr?.[2],
            status: initialValuesForEdit?.status,
        }

        const result = [
            ...versionsData,
            currentVersion as PositionProfileVersionContract,
        ]

        setVersions(result.map(normalizeDataForSelectVersion))
    } catch (error) {
        console.error(error)
    }
}

export const updateCompetenciesGroups = ({
    allValues,
    defaultCompetenceGroupsIds,
    form,
}: UpdateCompetenciesGroups) => {
    const dependenceFieldsPromises = [
        CompetenciesService.getByDepartmentIds({
            id: allValues?.oivId,
            body: allValues?.oivId ? [allValues?.oivId] : [],
        }),
        CompetenciesService.getByFunctionClassesIds({
            body: allValues?.functionClassesIds || [],
        }),
        CompetenciesService.getAllActiveByLevelId({
            levelId: allValues?.levelId,
        }),
    ]

    getCompetenceGroupsByDependenceFields(
        dependenceFieldsPromises,
        defaultCompetenceGroupsIds
    ).then((groupsIds) => {
        form.setFieldsValue({
            competenceGroupsIds: groupsIds,
        })
    })
}

export const updateStructuralUnits = async ({
    changedValueValue,
    changedValueKey,
    handleChangeDictionaries,
    form,
}: UpdateStructuralUnits) => {
    const structuralUnits = await StructuralUnitsService.getAllOfChildTypeElemntsByParentId(
        {
            parentId: Number(changedValueValue),
        }
    )

    const structuralUnitsNormalized = structuralUnits.map(
        normalizeDataForSelectAndRadio
    )

    switch (changedValueKey) {
        case 'supervisingComplexId':
            handleChangeDictionaries({
                oiv: structuralUnitsNormalized,
            })

            form.setFieldsValue({
                oivId: null,
                organization: null,
                structuralUnitId: null,
            })

            break
        case 'oivId':
            handleChangeDictionaries({
                organization: structuralUnitsNormalized,
            })
            form.setFieldsValue({
                organization: null,
                structuralUnitId: null,
            })

            break
        case 'organization':
            handleChangeDictionaries({
                structuralUnits: structuralUnitsNormalized,
            })

            form.setFieldsValue({
                structuralUnitId: null,
            })

            break
    }
}

export const getStaffUnitsStructure = async ({
    id,
    setStaffModalInitialValues,
    form,
}: GetStuffUnitsStructure) => {
    const staffModalValues = await StaffUnitsService.getStaffUnitStructure({
        id,
    })

    setStaffModalInitialValues(staffModalValues)
    form.setFieldsValue({
        staffUnitId: id,
    })

    return staffModalValues.staffUnit
}

export const updateStaffUnit = ({
    form,
    setStaffModalInitialValues,
    handleChangeDictionaries,
    staffUnitId,
    dictionary,
}: StaffUnitChangeProps) => {
    form.setFieldsValue({
        staffUnitId,
    })

    setStaffModalInitialValues({
        staffUnit: { id: staffUnitId } as DictionaryContract,
    })

    if (dictionary) {
        handleChangeDictionaries({
            staffUnits: dictionary,
        })
    }
}

export const getInitialValuesForExistingProfile = ({
    version,
    archivalJobProfile,
    initialValuesForEdit,
    defaultCompetenceGroupsIds,
    formMode,
    setTouchEvent,
    form,
}: GetInitialValueForExistingProfile) => {
    const formValues = mapResponseToFormData({
        initialValuesForEdit: version
            ? archivalJobProfile
            : initialValuesForEdit,
        defaultCompetenceGroupsIds,
        isCopy: isFormModeCopy(formMode),
    })

    setTouchEvent(false)
    form.setFieldsValue(formValues)

    if (!formValues?.levelId) return

    getCompetenceGroupsByDependenceFields(
        [
            CompetenciesService.getAllActiveByLevelId({
                levelId: formValues?.levelId,
            }),
        ],
        formValues.competenceGroupsIds
    ).then((groupsIds) => {
        setTouchEvent(false)
        form.setFieldsValue({
            competenceGroupsIds: groupsIds,
        })
    })
}

export const getStructualUnits = ({
    initialValuesForEdit,
    formMode,
    handleChangeDictionaries,
    fetchStaffUnitStructure,
    initialValuesForAdd,
    form,
    handleFetchDictionaries,
    setInitialOivId,
}: StructuralUnitsProps) => {
    if (
        !isObjectEmpty(initialValuesForEdit) &&
        (isFormModeEdit(formMode) || isFormModeView(formMode))
    )
        fetchStructuralUnits({
            handleChangeDictionaries,
            initialValuesForEdit,
            fetchStaffUnitStructure,
        })

    if (!isObjectEmpty(initialValuesForAdd) && isFormModeCreate(formMode))
        fetchStructuralUnitParents({
            form,
            handleFetchDictionaries,
            handleChangeDictionaries,
            initialValuesForAdd,
            fetchStaffUnitStructure,
            setInitialOivId,
        })
}
