import './ResetButton.scss'

import React from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import { ResetButtonProps } from './ResetButton.types'

/** Вкладки-табы на баннере */
export const ResetButton: React.FC<ResetButtonProps> = React.memo(
    ({ handleReset }) => (
        <Button
            icon={<IconsAdapter iconType="CustomIconEraser" />}
            onClick={handleReset}
            className="reset-button"
        />
    )
)
