import React, { useMemo } from 'react'
import { Form } from 'antd'
import { InputControl, InputNumberControl } from 'components/controls'

import { TableActionFieldProps } from './TableActionField.types'

/**
 * Компонент интерактивной ячейки таблицы
 */
export const TableActionField: React.FC<TableActionFieldProps> = React.memo(
    ({ name, type }) => {
        /** текущий контрол для рендера по типу */
        const currentControl = useMemo(() => {
            switch (type) {
                case 'comment':
                    return <InputControl />
                case 'answerRate':
                    return <InputNumberControl min={0} max={5} />
            }
        }, [type])

        return <Form.Item name={name}>{currentControl}</Form.Item>
    }
)
