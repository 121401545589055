import { LOCAL } from 'core/local'

export enum TabsKeys {
    CommonOptions = 'common-options',
    Notifications = 'notifications',
}

export const TABS_PANE_OPTIONS = {
    COMMON_OPTIONS: {
        tab: LOCAL.LABELS.COMMON_OPTIONS,
        key: TabsKeys.CommonOptions,
    },
    NOTIFICATIONS: {
        tab: LOCAL.LABELS.NOTIFICATIONS,
        key: TabsKeys.Notifications,
    },
}

export const DEFAULT_TAB_KEY = TABS_PANE_OPTIONS.COMMON_OPTIONS.key
