import React, { useCallback, useEffect, useState } from 'react'
import { LOCAL } from 'core/local'
import { Prompt, useHistory } from 'react-router-dom'

/** Интерфейс для оборачиваемого компонента */
export interface WithBlockUserRoutingComponentProps {
    /** callback вызывается в случае необходимости блокировки навигации пользователя */
    onBlockUserRouting?: (isBlocked?: boolean) => void
}

/**
 * HOC предназначен для блокировки навигации пользователя
 * @param WrappedComponent - оборачиваемый компонент
 * @param isAlwaysBlocked - флаг блокировки, по умолчанию true, если false - блокировка опирается
 *     на результат работы `handleBlockUserRouting`
 */
export function withBlockUserRouting<T = any>(
    WrappedComponent: React.FC<T & WithBlockUserRoutingComponentProps>,
    isAlwaysBlocked = true,
    beforeChange = (props: T) => {}
) {
    return function (props: T) {
        const history = useHistory()

        /**
         * Управление состоянием блокировки
         */
        const [shouldBlockUserRouting, setBlockUserRouting] = useState<
            boolean
        >()

        /**
         * Обработчик закрытия/перезагрузки окна браузера
         */
        const handleWindowBeforeUnload = useCallback(
            (e: BeforeUnloadEvent) => {
                e.preventDefault()

                /* Если колбэк не будет срабатывать - добавить синхронную паузу с помощью функции sleep (common.utils) */
                beforeChange(props)

                return (e.returnValue = true)
            },
            [props]
        )

        /**
         * Обработчик блокировки навигации пользователя
         * @param isBlocked - если false, то переход не блокируется
         */
        const handleBlockUserRouting = useCallback((isBlocked = true) => {
            setBlockUserRouting(isBlocked)
        }, [])

        useEffect(() => {
            if (!shouldBlockUserRouting) return

            window.addEventListener('beforeunload', handleWindowBeforeUnload)

            return () => {
                window.removeEventListener(
                    'beforeunload',
                    handleWindowBeforeUnload
                )
            }
        }, [handleWindowBeforeUnload, shouldBlockUserRouting])

        useEffect(() => {
            const unlisten = history.listen(() => {
                beforeChange(props)
            })

            return () => {
                setTimeout(unlisten, 0)
            }
        }, [history, props])

        useEffect(() => {
            setBlockUserRouting(isAlwaysBlocked)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isAlwaysBlocked])

        return (
            <>
                <Prompt
                    when={shouldBlockUserRouting}
                    message={LOCAL.MESSAGES.CHANGES_UNSAVED}
                />

                <WrappedComponent
                    {...props}
                    onBlockUserRouting={handleBlockUserRouting}
                />
            </>
        )
    }
}
