export const BLOCK_PICKER_COLORS = {
    silver: '#D9E3F0',
    pink: '#F47373',
    grey: '#697689',
    lightGreen: '#37D67A',
    cyan: '#2CCCE4',
    darkGrey: '#555555',
    yellow: '#dce775',
    orange: '#ff8a65',
    purple: '#ba68c8',
    blue: '#367AAF',
    green: '#34C759',
    black: '#1E1E1E',
    white: '#FFFFFF',
    transparent: 'transparent',
}
