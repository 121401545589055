import * as HttpStatus from 'http-status-codes'
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios'
import { API_BASE_URL, LOGIN_URL } from 'consts/env.consts'
import { LOCAL } from 'core/local'
import {
    createErrorMessages,
    createErrorNotification,
} from 'utils/notification.utils'

import { serviceOptions } from './api'

/**
 * Инициализация инстанса axios для работы с API
 * @param history - необходимо прокинуть историю из хука, чтоб работали редиректы
 */

const httpConfig: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
}

/**
 * Response interceptors
 * Перехват ответа от бэка перед возвратом в приложение
 * */

function successResponseInterceptor(
    response: AxiosResponse
): Promise<AxiosResponse> {
    return Promise.resolve(response)
}

function failureResponseInterceptor(error: AxiosError): Promise<AxiosError> {
    if (error.response) {
        const { response, stack } = error

        switch (response.status) {
            case HttpStatus.UNAUTHORIZED:
                const returnUrl = window.location.href
                window.location.href = `${LOGIN_URL}?returnUrl=${returnUrl}`
                break

            case HttpStatus.FORBIDDEN:
                createErrorNotification(
                    `${LOCAL.MESSAGES.ACCESS_DENIED} (${HttpStatus.FORBIDDEN})`,
                    response.data.messages[0]
                )
                break

            case HttpStatus.NOT_FOUND:
                response.data
                    ? createErrorMessages(response.data)
                    : createErrorNotification(
                          `${LOCAL.MESSAGES.NOT_FOUND} (${HttpStatus.NOT_FOUND})`,
                          stack
                      )
                break

            case HttpStatus.INTERNAL_SERVER_ERROR:
            case HttpStatus.NOT_IMPLEMENTED:
            case HttpStatus.BAD_GATEWAY:
            case HttpStatus.SERVICE_UNAVAILABLE:
            case HttpStatus.BAD_REQUEST:
                createErrorMessages(response.data)
                break
            case HttpStatus.GATEWAY_TIMEOUT:
                createErrorNotification(
                    `${LOCAL.MESSAGES.UNKNOWN_SERVER_ERROR} (${response.status})`,
                    stack
                )
                break

            default:
                break
        }
    }

    return Promise.reject(error.response)
}

/**
 * Request interceptors
 * Перехват запросов перед отправкой на сервер
 * */

function successRequestInterceptor(
    request: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
    request.headers = { 'X-Requested-With': 'XMLHttpRequest' }
    return Promise.resolve(request)
}

function failureRequestInterceptor(error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error)
}

const http: AxiosInstance = axios.create(httpConfig)

http.interceptors.response.use(
    successResponseInterceptor,
    failureResponseInterceptor
)
http.interceptors.request.use(
    successRequestInterceptor,
    failureRequestInterceptor
)

serviceOptions.axios = http
