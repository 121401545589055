import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import styles from './CloudTag.module.scss'
import { CloudTagProps } from './CloudTag.types'

/** Компонент тега типа "Cloud" */
export const CloudTag: React.FC<CloudTagProps> = React.memo(
    ({ disabled, showCloseIcon, closeIcon, onDelete, className, children }) => {
        /** обработчик удаления тега */
        const handleTagDelete = useCallback(() => {
            if (disabled) return

            onDelete?.()
        }, [onDelete, disabled])

        /** Рендер иконки закрытия тега */
        const closeIconRender = useMemo(() => {
            if (disabled || !showCloseIcon) return null

            return (
                closeIcon || (
                    <IconsAdapter
                        className={styles.defaultIcon}
                        iconType="CloseOutlined"
                    />
                )
            )
        }, [closeIcon, disabled, showCloseIcon])

        return (
            <span
                className={cn(styles.wrapper, className)}
                onClick={handleTagDelete}
            >
                {children}

                {closeIconRender}
            </span>
        )
    }
)
