import React, { CSSProperties, Fragment } from 'react'
import { API_BASE_URL } from 'consts/env.consts'
import { Divider } from 'antd'
import { IconType } from 'components/shared/IconsAdapter/IconsAdapter.types'
import { IconsAdapter, Spoiler } from 'components/shared'
import { LOCAL } from 'core/local'
import { SpoilerTitleRenderProps } from 'components/shared/Spoiler/Spoiler.types'
import { TrackAdditionalInfoContract } from 'core/api'
import { getMaterialLaunchUrl } from 'utils'

import styles from './TrackInfoMenu.module.scss'
import {
    GetItemForSpoilerProps,
    NormalizeMenuDataProps,
    RenderInfoMenuProps,
} from './TrackInfoMenu.types'
import { InfoMenuTypes } from './TrackInfoMenu.consts'
import { TrackInfoMenuVideo } from './TrackInfoMenuVideo'

/**
 * Текстовый элемент списка для содержимого спойлера
 * @param name название сущности
 * @param icon иконка
 */
const getItemForSpoilerContent = (name?: string, icon?: IconType) => (
    <>
        <span className={styles.itemName}>{name}</span>

        {icon && <IconsAdapter iconType={icon} />}
    </>
)

/**
 * Содержимое спойлера
 */
const getSpoilerContent = ({
    items,
    icon,
    key,
    quiz,
    onShowFeedbackForm,
}: GetItemForSpoilerProps) => {
    switch (key) {
        case InfoMenuTypes.Quiz:
            return (
                <div className={styles.item} onClick={onShowFeedbackForm}>
                    {getItemForSpoilerContent(quiz?.name, icon)}
                </div>
            )
        case InfoMenuTypes.Video:
            return items?.map((el) => (
                <TrackInfoMenuVideo launchUrl={el.launchUrl} key={el.id}>
                    {getItemForSpoilerContent(el.name, icon)}
                </TrackInfoMenuVideo>
            ))
        default:
            return items?.map((el) => (
                <a
                    className={styles.item}
                    href={getMaterialLaunchUrl(el.launchUrl)}
                    key={el.id}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {getItemForSpoilerContent(el.name, icon)}
                </a>
            ))
    }
}

/**
 * Подготовка данных для меню
 * @param data данные с вервера
 */
const normalizeMenuData = (
    data?: TrackAdditionalInfoContract
): NormalizeMenuDataProps[] => [
    {
        title: LOCAL.LABELS.MATERIALS,
        items: data?.documents,
        icon: 'CustomIconPdfFile',
        routeName: API_BASE_URL,
        key: InfoMenuTypes.File,
    },
    {
        title: LOCAL.LABELS.VIDEO,
        items: data?.videos,
        routeName: API_BASE_URL,
        key: InfoMenuTypes.Video,
    },
    {
        title: LOCAL.LABELS.QUIZ,
        quiz: data?.questionnaire,
        icon: 'BarsOutlined',
        key: InfoMenuTypes.Quiz,
    },
]

const getSpoilerTitle = (
    title: string,
    textStyle: CSSProperties,
    itemCount = 0
) => (
    <>
        <span style={textStyle}>{title}</span>

        <span className={styles.count}>{itemCount}</span>
    </>
)

const customSpoilerTitleRender = (textStyle: CSSProperties) => ({
    text,
    toggleSpoilerVisible,
    icon,
}: SpoilerTitleRenderProps) => (
    <a href="#s" onClick={toggleSpoilerVisible} className={styles.spoilerTitle}>
        <span style={textStyle}>{icon}</span>

        {text}
    </a>
)

/**
 * Проверка данных для типа Quiz
 * @param type тип для проверки
 */
const isInfoMenuQuizType = (type: InfoMenuTypes) => type === InfoMenuTypes.Quiz

/** Рендер меню с дополнительной информации о треке */
export const renderInfoMenu = ({
    spoilerVisibleIndex,
    onChangeSpoilerVisible,
    data,
    onShowFeedbackForm,
    textStyle,
    isHomeWorksVisible,
}: RenderInfoMenuProps) => (
    <>
        {normalizeMenuData(data).map(
            ({ title, items, icon, quiz, routeName, key }, index) => (
                <Fragment key={key}>
                    <Divider />

                    <Spoiler
                        title={getSpoilerTitle(
                            title,
                            textStyle,
                            isInfoMenuQuizType(key) && quiz ? 1 : items?.length
                        )}
                        className={styles.spoiler}
                        visible={spoilerVisibleIndex === index + 1}
                        onChangeVisible={onChangeSpoilerVisible(index + 1)}
                        spoilerTitleRender={customSpoilerTitleRender(textStyle)}
                        disabled={!(items?.length || quiz)}
                    >
                        {getSpoilerContent({
                            icon,
                            items,
                            quiz,
                            key,
                            onShowFeedbackForm,
                        })}
                    </Spoiler>
                </Fragment>
            )
        )}

        <Divider />

        {/* TODO: Будет реализовано в отдельной задаче*/}

        {isHomeWorksVisible && (
            <Spoiler
                title={getSpoilerTitle(LOCAL.LABELS.HOMEWORK, textStyle)}
                className={styles.spoiler}
                visible={spoilerVisibleIndex === Object.keys(data || {}).length}
                onChangeVisible={onChangeSpoilerVisible(
                    Object.keys(data || {}).length
                )}
                spoilerTitleRender={customSpoilerTitleRender(textStyle)}
                disabled={true}
            />
        )}
    </>
)
