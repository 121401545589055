import { AppointmentTestReviewParticipantsContract } from 'core/api'
import { ReactText } from 'react'

import { ReviewTableNameGroups } from '../TabReview/TabReview.types'
import { TABLE_REVIEW_FORM_ENTRY_POINT } from '../TabReview/TabReview.consts'

/**
 * Получить имя для динамического столбца
 * @param groupName - имя группы, к которой относится столбец
 * @param itemNum - номер столбца
 * @returns {string} - сгенерированное имя, которое можно использовать как dataIndex внутри таблицы
 */
export function getDynamicColName(
    groupName: ReviewTableNameGroups,
    itemNum: ReactText
) {
    return [groupName, itemNum].join('-')
}

/**
 * Получить имя для полей типа "ответ", которое используется для работы с формой
 * @param userId - идентификатор пользователя, давшего ответ
 * @param point - конечная точка в объекте результатов формы
 */
export function getActionCellFormName(
    userId: ReactText,
    point: Exclude<
        keyof AppointmentTestReviewParticipantsContract,
        'id' | 'user'
    >
) {
    return [
        TABLE_REVIEW_FORM_ENTRY_POINT,
        'participants',
        getDynamicColName(ReviewTableNameGroups.User, userId),
        point,
    ]
}
