import 'moment/locale/ru'

import './App.scss'

import moment from 'moment-timezone'
import ru_RU from 'antd/lib/locale/ru_RU'
import React, { FC, useEffect } from 'react'
import {
    AppSettingsContextProvider,
    ColorThemeContextProvider,
    Compose,
    ModalsContextProvider,
    NotificationCounterContextProvider,
    UserContextProvider,
} from 'contexts'
import { ConfigProvider, Modal } from 'antd'
import { DocumentHeadMeta, GlobalLoader, Header } from 'components/shared'
import { InitApiProvider } from 'core/api/InitApiProvider'
import { Router } from 'react-router-dom'
import { Routing } from 'routing'
import { customHistory } from 'core/configs'

moment.locale('ru')
moment.tz.setDefault('Europe/Moscow')

export const App: FC = () => {
    useEffect(() => {
        const handleHistoryPopstate = (e: Event) => {
            e.preventDefault()
            Modal.destroyAll()
        }

        window.addEventListener('popstate', handleHistoryPopstate)

        return () => {
            window.removeEventListener('popstate ', handleHistoryPopstate)
        }
    })

    return (
        <Router history={customHistory}>
            <div className="app">
                {/* Лоадер должен находиться выше остальных компонентов, т.к. внутри него
                используется eventEmitter, необходимо зарегистрировать все события до того,
                как они будут вызваны в остальных частях приложения/на глубоких уровнях */}
                <GlobalLoader />

                <ConfigProvider locale={ru_RU}>
                    <Compose
                        components={[
                            InitApiProvider,
                            UserContextProvider,
                            AppSettingsContextProvider,
                            ModalsContextProvider,
                            ColorThemeContextProvider,
                            NotificationCounterContextProvider,
                        ]}
                    >
                        <DocumentHeadMeta />

                        <Header />

                        <Routing />
                    </Compose>
                </ConfigProvider>
            </div>
        </Router>
    )
}
