import * as rrweb from 'rrweb'
import { RecordEntityType, RrwebStorageService } from 'core/api'
import { eventWithTime } from 'rrweb/typings/types'

class RrwebHelper {
    isMonitoringRun = false

    private _events: eventWithTime[] = []
    private readonly _saveInterval: number
    private _sessionId?: string
    private _stopFn?: Function

    constructor(interval: number) {
        this._saveInterval = interval
    }

    /** Запуск мониторинга */
    async run(entity: RecordEntityType) {
        try {
            if (this.isMonitoringRun) return

            this.isMonitoringRun = true

            const dataSource = await RrwebStorageService.initSession({
                recordEntityType: entity,
            })

            this._sessionId = dataSource.id

            this._rrwebRecord()

            console.log('Monitoring on')

            return dataSource.id
        } catch (err) {
            this.isMonitoringRun = false
            console.error(err)
        }
    }

    /** Стоп мониторинга */
    stop() {
        if (!this.isMonitoringRun) return

        this._stopFn?.()
        if (this._events.length) this._save()

        this.isMonitoringRun = false

        console.log('Monitoring off')
    }

    /** Старт записи */
    private _rrwebRecord() {
        this._stopFn = rrweb.record({
            emit: (event, isCheckout) => {
                if (isCheckout) this._save()
                this._events.push(event)
            },
            checkoutEveryNth: this._saveInterval,
        })
    }

    /** Сохранение данных на сервере */
    private async _save() {
        try {
            if (!this._sessionId) return

            const events = this._events
            this._events = this._events.slice(this._saveInterval)

            await RrwebStorageService.saveEvent({
                sessionId: this._sessionId,
                body: { eventPart: JSON.stringify(events) },
            })
        } catch (err) {
            console.error(err)
        }
    }
}

export const Rrweb = new RrwebHelper(100)
