import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    COL_xl8_lg12_xs24,
    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED,
    PROFILE_TYPES_MAPPED,
    ROW_GUTTER,
    TREE_SELECT_NORMALIZE_TYPE,
} from 'consts'
import { Col, Empty, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    MultiSelectControl,
    SelectControl,
} from 'components/controls'
import { DataNode } from 'rc-tree-select/lib/interface'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps, normalizeDataForTreeSelect, queryStrToObj } from 'utils'
import { LOCAL } from 'core/local'
import {
    PositionProfilesSearchContract,
    PositionsService,
    StructuralUnitType,
    StructuralUnitsService,
} from 'core/api'
import { Store } from 'App.types'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionaries } from 'hooks'

import { DictionariesStateProps } from './JobProfileOrgStructureFilters.types'
import { JobProfileQueryObjProps } from '../../JobProfiles.types'

export const JobProfileOrgStructureFilters: React.FC<FiltersProps<
    PositionProfilesSearchContract
>> = React.memo((props) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesStateProps
    >()

    const externalFilters = useMemo(
        () => queryStrToObj<JobProfileQueryObjProps>(window.location.search),
        []
    )

    const [unitsName, setUnitsName] = useState<DataNode[]>()

    const mapFormDataToRequest = useCallback((values: Store) => {
        const structuralUnits = [
            ...(values.structuralUnitsIds || []),
            ...(values.structuralOrganizationIds || []),
        ]

        return {
            ...values,
            structuralUnitsIds: structuralUnits,
        }
    }, [])

    const dictionaryFetch = useCallback(() => {
        handleFetchDictionaries({
            positions: [PositionsService.getAll(), TREE_SELECT_NORMALIZE_TYPE],
            organizationsName: [
                StructuralUnitsService.getOrganizationsByUnitType({
                    type: StructuralUnitType.Organization,
                }),
                TREE_SELECT_NORMALIZE_TYPE,
            ],
            oiv: [
                StructuralUnitsService.getOrganizationsByUnitType({
                    type: StructuralUnitType.Oiv,
                }),
                TREE_SELECT_NORMALIZE_TYPE,
            ],
            supervisingComplexes: [
                StructuralUnitsService.getOrganizationsByUnitType({
                    type: StructuralUnitType.Complex,
                }),
                TREE_SELECT_NORMALIZE_TYPE,
            ],
        })
    }, [handleFetchDictionaries])

    /**
     * Запрос справочника подразделений для выбранных организаций
     * @param ids массив id организаций
     */
    const unitsFetch = useCallback(
        async (ids?: number[]) => {
            try {
                const unitsData = await StructuralUnitsService.getOrganizationsUnitsFlat(
                    { body: ids }
                )
                setUnitsName(unitsData.map(normalizeDataForTreeSelect))
            } catch (error) {
                console.error(error)
            }
        },
        [setUnitsName]
    )

    useEffect(() => {
        const fetchStructuralUnitFilterData = async () => {
            if (
                externalFilters?.unit &&
                externalFilters.register === 'organizations'
            ) {
                const unitsData = await StructuralUnitsService.getByIds({
                    body: [+externalFilters.unit],
                })

                setUnitsName(unitsData.map(normalizeDataForTreeSelect))
            }
        }

        fetchStructuralUnitFilterData()
    }, [externalFilters])

    useEffect(() => {
        dictionaryFetch()
    }, [dictionaryFetch])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                mapFormDataToRequest={mapFormDataToRequest}
                renderFooter={renderFooterWithShowArchiveField}
                withoutSavedFilters={!!externalFilters?.register}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.PROFILE_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="positionsIds"
                            label={LOCAL.LABELS.POSITION}
                        >
                            <MultiSelectControl
                                treeData={dictionaries?.positions}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={4}>
                        <FormItemAdapter
                            fieldName="requestNumber"
                            label={LOCAL.LABELS.REQUEST_NUMBER}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={4}>
                        <FormItemAdapter
                            fieldName="type"
                            label={LOCAL.LABELS.PROFILE}
                        >
                            <SelectControl
                                values={PROFILE_TYPES_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>

                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="supervisingComplexIds"
                            label={LOCAL.LABELS.COMPLEX}
                        >
                            <MultiSelectControl
                                treeData={dictionaries?.supervisingComplexes}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="oivIds"
                            label={LOCAL.LABELS.OIV}
                        >
                            <MultiSelectControl
                                treeData={dictionaries?.oiv}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={4}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={4}>
                        <FormItemAdapter
                            fieldName="changeUserName"
                            label={LOCAL.LABELS.PERFORMER_FIO}
                        >
                            <InputControl placeholder="Введите текст" />
                        </FormItemAdapter>
                    </Col>
                </Row>

                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="structuralOrganizationIds"
                            label={LOCAL.LABELS.ORGANIZATION}
                        >
                            <MultiSelectControl
                                treeData={dictionaries?.organizationsName}
                                onChange={unitsFetch}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="structuralUnitsIds"
                            label={LOCAL.LABELS.STRUCTURAL_UNIT}
                        >
                            <MultiSelectControl
                                treeData={unitsName}
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={
                                            LOCAL.MESSAGES
                                                .CHOOSE_ORGANIZATION_BEFORE
                                        }
                                    />
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
