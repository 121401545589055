import React, { useCallback, useMemo, useState } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { MEDIA_DOCUMENT_TYPE } from 'consts'
import { Popup } from 'components/shared/Popup'
import { Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import { validateUploadImage } from 'utils'

import styles from './PicturesWallControl.module.scss'
import {
    PicturesWallControlProps,
    PicturesWallControlValueProps,
} from './PicturesWallControl.types'
import { emptyPictureWall } from './PicturesWallControl.const'
import { getBase64, setPictureFile } from './PicturesWallControl.utils'

/**
 * Компонент для загрузки и предпросмотра картинок
 */
export const PicturesWallControl: React.FC<PicturesWallControlProps> = React.memo(
    ({
        value,
        onChange,
        maxPicturesCount = 10,
        uploadText,
        customRequest,
        disabled,
        className,
    }) => {
        const [picturesWallData, setPicturesWallData] = useState<
            PicturesWallControlValueProps
        >(value || emptyPictureWall)

        /**
         * Обработчик закрытия модального окна предпросмотра
         */
        const handleCancelPreview = useCallback(() => {
            setPicturesWallData((prevState) => ({
                ...prevState,
                previewVisible: false,
            }))
        }, [])

        /**
         * Обработчик предпросмотра
         * @param file - объект файла для предпросмотра
         */
        const handlePreview = useCallback(async (file: UploadFile) => {
            if (!file.url && !file.preview && file.originFileObj) {
                file.preview = await getBase64(file.originFileObj)
            }

            const previewTitle =
                file.name ||
                (file.url &&
                    file.url.substring(file.url.lastIndexOf('/') + 1)) ||
                ''

            setPicturesWallData((prevState) => ({
                ...prevState,
                previewImage: file.url || file.preview || '',
                previewVisible: true,
                previewTitle,
            }))
        }, [])

        /**
         * Обработчик изменения состояния компонента
         * @param info - объект события
         */
        const handleChange = useCallback(
            ({ fileList }: UploadChangeParam) => {
                if (!fileList.length) {
                    setPictureFile({
                        setPicturesWallData,
                        onChange,
                        result: emptyPictureWall,
                    })

                    return
                }

                const result = { ...picturesWallData, fileList }

                if (validateUploadImage(fileList[0], fileList)) {
                    setPictureFile({
                        setPicturesWallData,
                        onChange,
                        result,
                    })
                }
            },
            [onChange, picturesWallData]
        )

        /**
         * Кнопка загрузки изображения
         */
        const uploadButton = useMemo(
            () => (
                <>
                    {uploadText ? (
                        uploadText
                    ) : (
                        <div>
                            <IconsAdapter iconType="PlusOutlined" />

                            <div className="ant-upload-text">{'Upload'}</div>
                        </div>
                    )}
                </>
            ),
            [uploadText]
        )

        return (
            <div className={className}>
                <Upload
                    listType="picture-card"
                    fileList={value?.fileList || picturesWallData.fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    accept={MEDIA_DOCUMENT_TYPE}
                    customRequest={customRequest}
                    disabled={disabled}
                >
                    {picturesWallData.fileList.length >= maxPicturesCount
                        ? null
                        : uploadButton}
                </Upload>

                <Popup
                    visible={picturesWallData.previewVisible}
                    title={picturesWallData.previewTitle}
                    footer={null}
                    onCancel={handleCancelPreview}
                    getContainer={getDropdownPopupContainerDefault}
                >
                    <img
                        className={styles.img}
                        alt={LOCAL.ACTIONS.PREVIEW}
                        src={picturesWallData.previewImage}
                    />
                </Popup>
            </div>
        )
    }
)
