import React, { useEffect } from 'react'
import {
    AssessmentPortfolioDictionariesService,
    AssessmentPortfolioSearchContract,
    StructuralUnitsService,
} from 'core/api'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import { DEPRECATED_ASSESSMENT_TYPES, ROW_GUTTER } from 'consts'
import { DictionariesCommonProps } from 'App.types'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import {
    FiltersProps,
    excludeSelectOptions,
    normalizeDictionaryToControl,
} from 'utils'
import { LOCAL } from 'core/local'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionaries, useDictionariesHelper } from 'hooks'

import { MANAGER_COMPETENCY_VALUES } from './AssessmentPortfoliosFilters.consts'

/**
 * Форма для работы с фильтрацией портфелей оценок
 */
export const AssessmentPortfoliosFilters: React.FC<FiltersProps<
    AssessmentPortfolioSearchContract
>> = React.memo((props) => {
    const { assessmentTypeDictionary } = useDictionariesHelper([
        'assessmentTypeDictionary',
    ])
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<
            'portfolioStatus' | 'portfolioPurpose' | 'organizations'
        >
    >()

    useEffect(() => {
        const dictionaryFetch = () => {
            handleFetchDictionaries({
                portfolioStatus: AssessmentPortfolioDictionariesService.getDocumentStatusTypes(),
                portfolioPurpose: AssessmentPortfolioDictionariesService.getAll(),
                organizations: [
                    StructuralUnitsService.getAllStructuralUnits(),
                    'treeSelect',
                ],
            })
        }

        dictionaryFetch()
    }, [handleFetchDictionaries])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl autoComplete="off" />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitIds"
                            label={LOCAL.LABELS.ORGANIZATIONS}
                        >
                            <TreeSelectControl
                                treeData={dictionaries?.organizations || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="type"
                            label={LOCAL.LABELS.TYPE}
                        >
                            <SelectControl
                                values={excludeSelectOptions(
                                    normalizeDictionaryToControl(
                                        assessmentTypeDictionary
                                    ),
                                    DEPRECATED_ASSESSMENT_TYPES
                                )}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="purposeId"
                            label={LOCAL.LABELS.GOAL}
                        >
                            <SelectControl
                                values={dictionaries?.portfolioPurpose || []}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                values={dictionaries?.portfolioStatus || []}
                                mode="multiple"
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changeDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changeDateEnd',
                            }}
                        />
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="managerCompetency"
                            label={LOCAL.LABELS.MANAGER_COMPETENCY}
                        >
                            <SelectControl values={MANAGER_COMPETENCY_VALUES} />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
