import React, { useCallback, useEffect } from 'react'
import {
    CheckboxControl,
    ControlGroup,
    FormItemAdapter,
    InputControl,
    MultiSelectControl,
    RangePickerControl,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import { Col, Form, Row, Space } from 'antd'
import {
    DOCUMENT_STATUSES_MAPPED,
    LEARNING_FORMATS_MAPPED,
    PRODUCT_TYPES_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { DictionariesCommonProps } from 'App.types'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps, excludeSelectOptions, getYearsPeriod } from 'utils'
import { LOCAL } from 'core/local'
import {
    ProductCardSearchContract,
    StructuralUnitsService,
    ThemesService,
} from 'core/api'
import { RenderFooterProps } from 'components/shared/FilterForm/FilterForm.types'
import { useDictionaries } from 'hooks'

import { ProductCardSearchProps } from './ProductCatalogFilters.types'

/** Форма фильтрации для карточек продуктов */
export const ProductCatalogFilters: React.FC<FiltersProps<
    ProductCardSearchContract
>> = React.memo((props) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<'themesData' | 'organizationsData'>
    >()

    const mapFormDataToRequest = useCallback(
        (values: Partial<ProductCardSearchProps>) => ({
            ...values,
            years: getYearsPeriod(values.years),
        }),
        []
    )

    const renderFooter = useCallback(
        ({
            cleanBtn,
            submitBtn,
            saveFiltersSwitch,
            isWidthLessLG,
        }: RenderFooterProps) => (
            <Row
                gutter={10}
                align="middle"
                justify={isWidthLessLG ? 'center' : 'end'}
            >
                <Col>
                    <Space>
                        <Form.Item name="showRecomended" noStyle>
                            <CheckboxControl>
                                {LOCAL.LABELS.DISPLAY_RECOMMENDED}
                            </CheckboxControl>
                        </Form.Item>

                        <Form.Item name="showPastPlacementPeriod" noStyle>
                            <CheckboxControl>
                                {LOCAL.LABELS.PAST_PLACEMENT_PERIOD}
                            </CheckboxControl>
                        </Form.Item>

                        <Form.Item name="showArchive" noStyle>
                            <CheckboxControl>
                                {LOCAL.LABELS.ARCHIVED_RECORDS}
                            </CheckboxControl>
                        </Form.Item>
                    </Space>
                </Col>

                {saveFiltersSwitch && <Col>{saveFiltersSwitch}</Col>}

                <Col>{submitBtn}</Col>

                <Col>{cleanBtn}</Col>
            </Row>
        ),
        []
    )

    useEffect(() => {
        handleFetchDictionaries({
            themesData: [ThemesService.getAll(), 'treeSelect'],
            organizationsData: [
                StructuralUnitsService.getAllStructuralUnits(),
                'treeSelect',
            ],
        })
    }, [handleFetchDictionaries])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                mapFormDataToRequest={mapFormDataToRequest}
                renderFooter={renderFooter}
            >
                <Row align="top" gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.PRODUCT_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="themeIds"
                            label={LOCAL.LABELS.THEMATIC_SECTION}
                        >
                            <MultiSelectControl
                                treeData={dictionaries?.themesData}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="types"
                            label={LOCAL.LABELS.PRODUCT_SHAPE}
                        >
                            <SelectControl
                                mode="multiple"
                                values={PRODUCT_TYPES_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="studyingFormats"
                            label={LOCAL.LABELS.LEARNING_FORMAT}
                        >
                            <SelectControl
                                mode="multiple"
                                values={LEARNING_FORMATS_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitIds"
                            label={LOCAL.LABELS.AVAILABLE_TO_ORGANIZATIONS}
                        >
                            <TreeSelectControl
                                treeData={dictionaries?.organizationsData || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={excludeSelectOptions(
                                    DOCUMENT_STATUSES_MAPPED,
                                    ['Archive', 'InWork']
                                )}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="years"
                            label={LOCAL.LABELS.YEAR}
                        >
                            <RangePickerControl picker="year" allowClear />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_START,
                                fieldName: 'fromDate',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_END,
                                fieldName: 'toDate',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
