import React, { FC } from 'react'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Switch } from 'react-router-dom'
import { TRACK_DEVELOPMENT_PERMISSIONS } from 'consts'
import { TrackDevelopmentPreset, TracksDevelopment } from 'components/pages'
import { UserPermission } from 'core/api'

export const TrackDevelopmentRouting: FC = React.memo(() => (
    <Switch>
        <ProtectedRoute
            requiredPermissions={[
                ...TRACK_DEVELOPMENT_PERMISSIONS,
                UserPermission.DevelopmentTrackSave,
            ]}
            path={`${ROUTE_NAMES.TRACKS_DEVELOPMENT_CREATE}/:id`}
        >
            <TrackDevelopmentPreset formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={TRACK_DEVELOPMENT_PERMISSIONS}
            path={`${ROUTE_NAMES.TRACKS_DEVELOPMENT_VIEW}/:id`}
        >
            <TrackDevelopmentPreset formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TRACK_DEVELOPMENT_PERMISSIONS,
                UserPermission.DevelopmentTrackSave,
            ]}
            path={`${ROUTE_NAMES.TRACKS_DEVELOPMENT_EDIT}/:id`}
        >
            <TrackDevelopmentPreset formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TRACK_DEVELOPMENT_PERMISSIONS,
                UserPermission.DevelopmentTrackSave,
            ]}
            path={`${ROUTE_NAMES.TRACKS_DEVELOPMENT_CREATE}`}
        >
            <TrackDevelopmentPreset formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.DevelopmentTracksRead,
            ]}
            path={ROUTE_NAMES.TRACKS_DEVELOPMENT}
        >
            <TracksDevelopment />
        </ProtectedRoute>
    </Switch>
))
