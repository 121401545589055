import React from 'react'
import Icon, {
    AlignCenterOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    AppstoreOutlined,
    ArrowLeftOutlined,
    ArrowUpOutlined,
    BarsOutlined,
    CalendarOutlined,
    CaretDownOutlined,
    CheckCircleFilled,
    CheckOutlined,
    CheckSquareFilled,
    ClockCircleOutlined,
    CloseCircleFilled,
    CloseOutlined,
    CloseSquareFilled,
    CopyOutlined,
    DeleteOutlined,
    DeliveredProcedureOutlined,
    DownCircleOutlined,
    DownOutlined,
    DownloadOutlined,
    EditOutlined,
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    EyeTwoTone,
    FileAddOutlined,
    FileOutlined,
    FileTextOutlined,
    FileZipOutlined,
    FilterOutlined,
    FormOutlined,
    HomeOutlined,
    InboxOutlined,
    InfoCircleFilled,
    InfoCircleOutlined,
    LockOutlined,
    LogoutOutlined,
    MailOutlined,
    MenuFoldOutlined,
    MenuOutlined,
    MenuUnfoldOutlined,
    MinusCircleOutlined,
    MinusOutlined,
    PaperClipOutlined,
    PauseCircleOutlined,
    PictureOutlined,
    PlaySquareOutlined,
    PlusCircleFilled,
    PlusCircleOutlined,
    PlusOutlined,
    QuestionCircleFilled,
    QuestionCircleOutlined,
    RetweetOutlined,
    SearchOutlined,
    SettingOutlined,
    StopFilled,
    StopOutlined,
    SwapOutlined,
    UnlockOutlined,
    UnorderedListOutlined,
    UpSquareOutlined,
    UserOutlined,
    VerticalAlignBottomOutlined,
    VerticalAlignTopOutlined,
    WarningOutlined,
} from '@ant-design/icons'
import { ReactSVG } from 'react-svg'

import CustomIconArrow from './img/icon-arrow.svg'
import CustomIconCheckHomework from './img/icon-check-homework.svg'
import CustomIconCheckQuizzes from './img/icon-check-quizzes.svg'
import CustomIconDevelopment from './img/icon-development.svg'
import CustomIconDistribution from './img/icon-distribution.svg'
import CustomIconEye from './img/icon-eye.svg'
import CustomIconHome from './img/icon-home.svg'
import CustomIconHomework from './img/icon-homework.svg'
import CustomIconLike from './img/icon-like.svg'
import CustomIconMainScreenFilter from './img/icon-filter-main-screen.svg'
import CustomIconPdfFile from './img/icon-pdf.svg'
import CustomIconProfileCircle from './img/icon-profile-circle.svg'
import CustomIconQuizzes from './img/icon-quizes.svg'
import CustomIconRecommended from './img/icon-recommended.svg'
import CustomIconReports from './img/icon-reports.svg'
import CustomIconResize from './img/icon-resize.svg'
import CustomIconResults from './img/icon-results.svg'
import CustomIconResultsWhite from './img/icon-results-white.svg'
import CustomIconRocket from './img/icon-rocket.svg'
import CustomIconStar from './img/icon-star.svg'
import CustomIconVideo from './img/icon-video.svg'
import { ReactComponent as CustomIconAdministration } from './img/icon-administration.svg'
import { ReactComponent as CustomIconAlarmClock } from './img/icon-alarm-clock.svg'
import { ReactComponent as CustomIconAlbum } from './img/icon-album.svg'
import { ReactComponent as CustomIconArrows } from './img/icon-arrows.svg'
import { ReactComponent as CustomIconAssessment } from './img/icon-assessment.svg'
import { ReactComponent as CustomIconChartBar } from './img/icon-chart-bar.svg'
import { ReactComponent as CustomIconChat } from './img/icon-chat.svg'
import { ReactComponent as CustomIconCheckFilled } from './img/icon-check-filled.svg'
import { ReactComponent as CustomIconChevron } from './img/icon-chevron.svg'
import { ReactComponent as CustomIconClose } from './img/icon-close.svg'
import { ReactComponent as CustomIconCloud } from './img/icon-cloud.svg'
import { ReactComponent as CustomIconColumnsOptions } from './img/icon-columns-options.svg'
import { ReactComponent as CustomIconCommercialAt } from './img/icon-commercial-at.svg'
import { ReactComponent as CustomIconConsistencyHigh } from './img/icon-consistency-high.svg'
import { ReactComponent as CustomIconConsistencyLow } from './img/icon-consistency-low.svg'
import { ReactComponent as CustomIconConsistencyMedium } from './img/icon-consistency-medium.svg'
import { ReactComponent as CustomIconDnD } from './img/icon-dnd.svg'
import { ReactComponent as CustomIconDnDFull } from './img/icon-dnd-full.svg'
import { ReactComponent as CustomIconEpaulet } from './img/icon-epaulet.svg'
import { ReactComponent as CustomIconEraser } from './img/icon-eraser.svg'
import { ReactComponent as CustomIconEvents } from './img/icon-events.svg'
import { ReactComponent as CustomIconFilter } from './img/icon-filters.svg'
import { ReactComponent as CustomIconInfoCircleSerif } from './img/icon-info-circle-serif.svg'
import { ReactComponent as CustomIconLayerPlus } from './img/icon-layer-plus.svg'
import { ReactComponent as CustomIconLineHeight } from './img/icon-line-height.svg'
import { ReactComponent as CustomIconLock } from './img/icon-lock.svg'
import { ReactComponent as CustomIconMarker } from './img/icon-marker.svg'
import { ReactComponent as CustomIconMaterials } from './img/icon-materials.svg'
import { ReactComponent as CustomIconNoImg } from './img/icon-noimg.svg'
import { ReactComponent as CustomIconNoImgDark } from './img/icon-noimg-dark.svg'
import { ReactComponent as CustomIconNotification } from './img/icon-notification.svg'
import { ReactComponent as CustomIconPaintBrush } from './img/icon-paint-brush.svg'
import { ReactComponent as CustomIconPencil } from './img/icon-pencil.svg'
import { ReactComponent as CustomIconPeople } from './img/icon-people.svg'
import { ReactComponent as CustomIconPlus } from './img/icon-plus.svg'
import { ReactComponent as CustomIconPointWithArrow } from './img/icon-point-with-arrow.svg'
import { ReactComponent as CustomIconProfiling } from './img/icon-profiling.svg'
import { ReactComponent as CustomIconQuiz } from './img/icon-quiz.svg'
import { ReactComponent as CustomIconSliderArrow } from './img/icon-slider-arrow.svg'
import { ReactComponent as CustomIconStatusNew } from './img/icon-status-new.svg'
import { ReactComponent as CustomIconStatusOnApproval } from './img/icon-status-on-approval.svg'
import { ReactComponent as CustomIconTests } from './img/icon-tests.svg'
import { ReactComponent as CustomIconTracks } from './img/icon-tracks.svg'
import { ReactComponent as CustomIconTrashAlt } from './img/icon-trash-alt.svg'
import { ReactComponent as CustomIconTrashAltBlue } from './img/icon-trash-alt-blue.svg'
import { ReactComponent as CustomIconTree } from './img/icon-tree.svg'
import { ReactComponent as CustomIconTriangle } from './img/icon-triangle.svg'
import { IconsAdapterProps } from './IconsAdapter.types'

/**
 * Адаптер для `Icons` от `antd`, принимает все теже `props`.
 * iconType влияет на тип выводимой иконки.
 */

export const IconsAdapter: React.FC<IconsAdapterProps> = React.memo(
    ({ iconType, ...restProps }) => {
        const iconsLibrary = {
            ArrowLeftOutlined: (
                <ArrowLeftOutlined {...restProps} translate={0} />
            ),
            BarsOutlined: <BarsOutlined {...restProps} translate={0} />,
            CheckOutlined: <CheckOutlined {...restProps} translate={0} />,
            ArrowUpOutlined: <ArrowUpOutlined {...restProps} translate={0} />,
            CaretDownOutlined: (
                <CaretDownOutlined {...restProps} translate={0} />
            ),
            CalendarOutlined: <CalendarOutlined {...restProps} translate={0} />,
            CloseOutlined: <CloseOutlined {...restProps} translate={0} />,
            CopyOutlined: <CopyOutlined {...restProps} translate={0} />,
            UnlockOutlined: <UnlockOutlined {...restProps} translate={0} />,
            VerticalAlignBottomOutlined: (
                <VerticalAlignBottomOutlined {...restProps} translate={0} />
            ),
            PictureOutlined: <PictureOutlined {...restProps} translate={0} />,
            DeleteOutlined: <DeleteOutlined {...restProps} translate={0} />,
            DeliveredProcedureOutlined: (
                <DeliveredProcedureOutlined {...restProps} translate={0} />
            ),
            DownOutlined: <DownOutlined {...restProps} translate={0} />,
            DownloadOutlined: <DownloadOutlined {...restProps} translate={0} />,
            EditOutlined: <EditOutlined {...restProps} translate={0} />,
            EyeOutlined: <EyeOutlined {...restProps} translate={0} />,
            FileAddOutlined: <FileAddOutlined {...restProps} translate={0} />,
            FileTextOutlined: <FileTextOutlined {...restProps} translate={0} />,
            FilterOutlined: <FilterOutlined {...restProps} translate={0} />,
            FileZipOutlined: <FileZipOutlined {...restProps} translate={0} />,
            FormOutlined: <FormOutlined {...restProps} translate={0} />,
            HomeOutlined: <HomeOutlined {...restProps} translate={0} />,
            LogoutOutlined: <LogoutOutlined {...restProps} translate={0} />,
            MenuFoldOutlined: <MenuFoldOutlined {...restProps} translate={0} />,
            MenuOutlined: <MenuOutlined {...restProps} translate={0} />,
            AppstoreOutlined: <AppstoreOutlined {...restProps} translate={0} />,
            MenuUnfoldOutlined: (
                <MenuUnfoldOutlined {...restProps} translate={0} />
            ),
            MinusOutlined: <MinusOutlined {...restProps} translate={0} />,
            MinusCircleOutlined: (
                <MinusCircleOutlined {...restProps} translate={0} />
            ),

            PlaySquareOutlined: (
                <PlaySquareOutlined {...restProps} translate={0} />
            ),
            PlusCircleOutlined: (
                <PlusCircleOutlined {...restProps} translate={0} />
            ),
            PlusOutlined: <PlusOutlined {...restProps} translate={0} />,
            UpSquareOutlined: <UpSquareOutlined {...restProps} translate={0} />,
            ExclamationCircleOutlined: (
                <ExclamationCircleOutlined {...restProps} translate={0} />
            ),
            ExclamationCircleFilled: (
                <ExclamationCircleFilled {...restProps} translate={0} />
            ),
            SearchOutlined: <SearchOutlined {...restProps} translate={0} />,
            SettingOutlined: <SettingOutlined {...restProps} translate={0} />,
            VerticalAlignTopOutlined: (
                <VerticalAlignTopOutlined {...restProps} translate={0} />
            ),
            UserOutlined: <UserOutlined {...restProps} translate={0} />,
            UnorderedListOutlined: (
                <UnorderedListOutlined {...restProps} translate={0} />
            ),
            InfoCircleOutlined: (
                <InfoCircleOutlined {...restProps} translate={0} />
            ),
            InfoCircleFilled: <InfoCircleFilled {...restProps} translate={0} />,
            EyeTwoTone: <EyeTwoTone {...restProps} translate={0} />,
            EyeInvisibleOutlined: (
                <EyeInvisibleOutlined {...restProps} translate={0} />
            ),
            StopFilled: <StopFilled {...restProps} translate={0} />,
            StopOutlined: <StopOutlined {...restProps} translate={0} />,
            SwapOutlined: <SwapOutlined {...restProps} translate={0} />,
            CheckSquareFilled: (
                <CheckSquareFilled {...restProps} translate={0} />
            ),
            CheckCircleFilled: (
                <CheckCircleFilled {...restProps} translate={0} />
            ),
            InboxOutlined: <InboxOutlined {...restProps} translate={0} />,
            MailOutlined: <MailOutlined {...restProps} translate={0} />,
            PauseCircleOutlined: (
                <PauseCircleOutlined {...restProps} translate={0} />
            ),
            ClockCircleOutlined: (
                <ClockCircleOutlined {...restProps} translate={0} />
            ),
            CustomIconCommercialAt: (
                <Icon
                    component={CustomIconCommercialAt}
                    translate={0}
                    {...restProps}
                />
            ),
            QuestionCircleOutlined: (
                <QuestionCircleOutlined {...restProps} translate={0} />
            ),
            QuestionCircleFilled: (
                <QuestionCircleFilled {...restProps} translate={0} />
            ),
            CloseSquareFilled: (
                <CloseSquareFilled {...restProps} translate={0} />
            ),
            CloseCircleFilled: (
                <CloseCircleFilled {...restProps} translate={0} />
            ),
            AlignCenterOutlined: (
                <AlignCenterOutlined {...restProps} translate={0} />
            ),
            AlignLeftOutlined: (
                <AlignLeftOutlined {...restProps} translate={0} />
            ),
            AlignRightOutlined: (
                <AlignRightOutlined {...restProps} translate={0} />
            ),
            LockOutlined: <LockOutlined {...restProps} translate={0} />,
            DownCircleOutlined: (
                <DownCircleOutlined {...restProps} translate={0} />
            ),
            WarnOutlined: <WarningOutlined {...restProps} translate={0} />,
            PaperClipOutlined: (
                <PaperClipOutlined {...restProps} translate={0} />
            ),
            RetweetOutlined: <RetweetOutlined {...restProps} translate={0} />,
            CustomIconFilter: (
                <Icon
                    component={CustomIconFilter}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconMainScreenFilter: (
                <ReactSVG
                    src={CustomIconMainScreenFilter}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconPlus: (
                <Icon component={CustomIconPlus} translate={0} {...restProps} />
            ),
            CustomIconTree: (
                <Icon component={CustomIconTree} translate={0} {...restProps} />
            ),
            CustomIconChevron: (
                <Icon
                    component={CustomIconChevron}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconColumnsOptions: (
                <Icon
                    component={CustomIconColumnsOptions}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconDnD: (
                <Icon component={CustomIconDnD} translate={0} {...restProps} />
            ),
            CustomIconClose: (
                <Icon
                    component={CustomIconClose}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconCloud: (
                <Icon
                    component={CustomIconCloud}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconAdministration: (
                <Icon
                    component={CustomIconAdministration}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconAssessment: (
                <Icon
                    component={CustomIconAssessment}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconEvents: (
                <Icon
                    component={CustomIconEvents}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconMaterials: (
                <Icon
                    component={CustomIconMaterials}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconProfiling: (
                <Icon
                    component={CustomIconProfiling}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconQuiz: (
                <Icon component={CustomIconQuiz} translate={0} {...restProps} />
            ),
            CustomIconTests: (
                <Icon
                    component={CustomIconTests}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconTracks: (
                <Icon
                    component={CustomIconTracks}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconDnDFull: (
                <Icon
                    component={CustomIconDnDFull}
                    translate={0}
                    {...restProps}
                />
            ),
            PlusCircleFilled: <PlusCircleFilled translate={0} {...restProps} />,
            CustomIconEpaulet: (
                <Icon
                    component={CustomIconEpaulet}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconAlbum: (
                <Icon
                    component={CustomIconAlbum}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconNoImg: (
                <Icon
                    component={CustomIconNoImg}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconNoImgDark: (
                <Icon
                    component={CustomIconNoImgDark}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconNotification: (
                <Icon
                    component={CustomIconNotification}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconLayerPlus: (
                <Icon
                    component={CustomIconLayerPlus}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconStatusNew: (
                <Icon
                    component={CustomIconStatusNew}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconStatusOnApproval: (
                <Icon
                    component={CustomIconStatusOnApproval}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconSliderArrow: (
                <Icon
                    component={CustomIconSliderArrow}
                    translate={0}
                    {...restProps}
                />
            ),
            CustomIconChartBar: (
                <Icon
                    component={CustomIconChartBar}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconPeople: (
                <Icon
                    component={CustomIconPeople}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconPointWithArrow: (
                <Icon
                    component={CustomIconPointWithArrow}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconCheckFilled: (
                <Icon
                    component={CustomIconCheckFilled}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconTriangle: (
                <Icon
                    component={CustomIconTriangle}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconChat: (
                <Icon component={CustomIconChat} {...restProps} translate={0} />
            ),
            CustomIconEraser: (
                <Icon
                    component={CustomIconEraser}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconArrows: (
                <Icon
                    component={CustomIconArrows}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconPaintBrush: (
                <Icon
                    component={CustomIconPaintBrush}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconTrashAlt: (
                <Icon
                    component={CustomIconTrashAlt}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconTrashAltBlue: (
                <Icon
                    component={CustomIconTrashAltBlue}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconPencil: (
                <Icon
                    component={CustomIconPencil}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconLineHeight: (
                <Icon
                    component={CustomIconLineHeight}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconLock: (
                <Icon component={CustomIconLock} {...restProps} translate={0} />
            ),
            CustomIconRecommended: (
                <ReactSVG
                    src={CustomIconRecommended}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconArrow: (
                <ReactSVG
                    src={CustomIconArrow}
                    className={restProps.className}
                />
            ),
            FileOutLined: <FileOutlined translate={0} {...restProps} />,
            CustomIconMarker: (
                <Icon
                    component={CustomIconMarker}
                    {...restProps}
                    translate={0}
                />
            ),
            InfoCircleSerif: (
                <Icon
                    component={CustomIconInfoCircleSerif}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconReports: (
                <ReactSVG
                    src={CustomIconReports}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconVideo: (
                <ReactSVG
                    src={CustomIconVideo}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconEye: (
                <ReactSVG
                    src={CustomIconEye}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconResults: (
                <ReactSVG
                    src={CustomIconResults}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconResultsWhite: (
                <ReactSVG
                    src={CustomIconResultsWhite}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconCheckHomework: (
                <ReactSVG
                    src={CustomIconCheckHomework}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconProfileCircle: (
                <ReactSVG
                    src={CustomIconProfileCircle}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconLike: (
                <ReactSVG
                    src={CustomIconLike}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconQuizzes: (
                <ReactSVG
                    src={CustomIconQuizzes}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconHomework: (
                <ReactSVG
                    src={CustomIconHomework}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconStar: (
                <ReactSVG
                    src={CustomIconStar}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconDevelopment: (
                <ReactSVG
                    src={CustomIconDevelopment}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconResize: (
                <ReactSVG
                    src={CustomIconResize}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconHome: (
                <ReactSVG
                    src={CustomIconHome}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconCheckQuizzes: (
                <ReactSVG
                    src={CustomIconCheckQuizzes}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconPdfFile: (
                <ReactSVG
                    src={CustomIconPdfFile}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconRocket: (
                <ReactSVG
                    src={CustomIconRocket}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconDistribution: (
                <ReactSVG
                    src={CustomIconDistribution}
                    wrapper="span"
                    className={restProps.className}
                />
            ),
            CustomIconConsistencyLow: (
                <Icon
                    component={CustomIconConsistencyLow}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconConsistencyMedium: (
                <Icon
                    component={CustomIconConsistencyMedium}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconConsistencyHigh: (
                <Icon
                    component={CustomIconConsistencyHigh}
                    {...restProps}
                    translate={0}
                />
            ),
            CustomIconAlarmClock: (
                <Icon
                    component={CustomIconAlarmClock}
                    {...restProps}
                    translate={0}
                />
            ),
        }

        return <>{iconType ? iconsLibrary[iconType] : ''}</>
    }
)
