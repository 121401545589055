import React from 'react'
import { AppointmentAssessmentExpertListContract } from 'core/api'
import { Button } from 'components/shared/Button'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    isAppointmentStatusChecked,
    isAppointmentStatusEnded,
    isAppointmentStatusExpectation,
    isAppointmentStatusInProgress,
} from 'utils'

/**
 * Рендер кнопок управления для записи таблицы
 */
export const renderExpertListActions = (
    row: AppointmentAssessmentExpertListContract
) => (
    <>
        {isAppointmentStatusChecked(row.status) ||
        isAppointmentStatusEnded(row.status) ? (
            <Button>{LOCAL.ACTIONS.VIEW}</Button>
        ) : (
            <Button
                href={`${ROUTE_NAMES.ASSESSMENT_ASSIGNED_EVALUATION}/${row.id}`}
                disabled={
                    isAppointmentStatusExpectation(row.status) ||
                    isAppointmentStatusInProgress(row.status)
                }
            >
                {LOCAL.ACTIONS.CHECK}
            </Button>
        )}
    </>
)
