import React from 'react'
import { ComponentCommonProps } from 'App.types'
import {
    ControlGroup,
    FormItemAdapter,
    TextAreaControl,
} from 'components/controls'
import { LOCAL } from 'core/local'

/** Компонент области "Функциональные обязанности" для формы профиля должности */
export const FunctionalResponsibilitiesGroup: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <ControlGroup
            type="dark"
            title={LOCAL.LABELS.FUNCTIONAL_RESPONSIBILITIES}
            tooltipText={
                LOCAL.TOOLTIPS.FUNCTIONAL_RESPONSIBILITIES_OF_JOB_PROFILE
            }
        >
            <FormItemAdapter fieldName="functionalResponsibilities">
                <TextAreaControl formMode={formMode} />
            </FormItemAdapter>
        </ControlGroup>
    )
)
