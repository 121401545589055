import React from 'react'
import {
    AssessmentPortfolioContract,
    AssessmentPortfolioSearchResultContract,
} from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { DictionariesStoreProps } from 'core/helpers/DictionariesHelper'
import { GetBtnOptionsType } from 'components/layouts/FormContainer/FormContainer.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WidthRestrictedElement } from 'components/shared/WidthRestrictedElement'
import { defaultSorter } from 'components/layouts'
import {
    formatDate,
    isDocumentStatusInWork,
    renderAppointmentNames,
    renderDocumentStatus,
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { AssessmentPortfoliosTableActions } from './components'

/** настройки отображения колонок таблицы */
export const getColumns = ({
    assessmentTypeDictionary,
}: DictionariesStoreProps): ColumnsType<
    AssessmentPortfolioSearchResultContract
> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizations',
        key: 'organizations',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ASSESSMENT_GOAL,
        dataIndex: 'purposeName',
        key: 'purposeName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.MANAGER_COMPETENCY,
        dataIndex: 'managerCompetencies',
        key: 'managerCompetencies',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    ...renderAppointmentNames(),
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (_, record) => (
            <WidthRestrictedElement
                title={assessmentTypeDictionary[record.type]}
            />
        ),
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'documentStatus',
        key: 'documentStatus',
        render: (status, row) =>
            renderDocumentStatus(status, {
                ...row,
                status: row.documentStatus,
            }),
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <AssessmentPortfoliosTableActions tableRow={tableRow} />
        ),
        align: 'right',
        fixed: 'right',
    },
]

export const CANCEL_BTN_OPTIONS = {
    href: ROUTE_NAMES.ASSESSMENT_PORTFOLIOS,
    visible: true,
}

export const getSaveBtnOptions: GetBtnOptionsType<AssessmentPortfolioContract> = ({
    editValues,
}) => ({
    visible: !isDocumentStatusInWork(editValues?.documentStatus),
})
