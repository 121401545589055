import React from 'react'
import { API_BASE_URL } from 'consts/env.consts'
import { Button } from 'components/shared/Button'
import { ColumnsType } from 'antd/lib/table'
import {
    CourseContract,
    StructuralUnitContract,
    StructuralUnitsSearchContract,
    StructuralUnitsService,
} from 'core/api'
import { CustomTableActionsProps } from 'components/shared/DynamicTableAdapter/DynamicTableAdapter.types'
import {
    EditableTextControl,
    OrganizationsFilters,
    SearchSelectControl,
} from 'components/controls'
import { FnActionRequiredProps, WithFormModeProps } from 'App.types'
import { IconsAdapter, getRowClassNameWithoutChild } from 'components/shared'
import { LOCAL } from 'core/local'
import { ORGANIZATIONS_TABLE_COLUMNS } from 'components/controls/AddOrganizationControl/AddOrganizationControl.consts'
import { STRUCTURAL_UNIT_SEARCH_BODY } from 'consts'
import { checkboxRenderForOIV } from 'components/controls/AddOrganizationControl/AddOrganizationControl.utils'
import { isFormModeView } from 'utils'

import styles from '../AdditionalMaterials.module.scss'
import { VideoFilesFormContract } from '../../../../TrackForm.types'

/** получить функцию рендера для схемы таблицы контрола */
export const getRenderColumnFn = (
    formMode: WithFormModeProps['formMode'],
    disabledForEdit?: boolean
) => (
    onChangeRow: FnActionRequiredProps<VideoFilesFormContract>
): ColumnsType<VideoFilesFormContract> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        width: '40%',
        render: (name, record) => (
            <EditableTextControl
                editable={{
                    onChange: (value) =>
                        onChangeRow({ ...record, name: value }),
                    tooltip: false,
                    autoSize: { maxRows: 1 },
                    editing: !isFormModeView(formMode),
                }}
                text={name}
                formMode={formMode}
                disabled={disabledForEdit}
            />
        ),
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'structuralUnit',
        key: 'structuralUnit',
        render: (value, record) => (
            <div className={styles.control}>
                <SearchSelectControl<
                    StructuralUnitsSearchContract,
                    StructuralUnitContract
                >
                    getByIdsMethod={StructuralUnitsService.getByIds}
                    formMode={formMode}
                    modalTitle={LOCAL.LABELS.ORGANIZATION_SELECTION}
                    value={value}
                    onChange={(structuralUnit) => {
                        onChangeRow({ ...record, structuralUnit })
                    }}
                    tableSearchOptions={{
                        searchMethod: StructuralUnitsService.search,
                        tableColumns: ORGANIZATIONS_TABLE_COLUMNS,
                        filterComponent: OrganizationsFilters,
                        tableFiltersOptions: {
                            initialValues: STRUCTURAL_UNIT_SEARCH_BODY,
                        },
                        childrenColumnName: 'units',
                        rowClassName: getRowClassNameWithoutChild,
                        renderSelectCell: checkboxRenderForOIV,
                    }}
                    disabled={disabledForEdit}
                />
            </div>
        ),
    },
]

/** нормализация данных, полученных через `getEntitiesByIds`, для `value` и внутреннего сейта компонента */
export const normalizeFn = (
    newData: CourseContract[],
    oldData?: VideoFilesFormContract[]
): VideoFilesFormContract[] =>
    newData.map((el) => {
        const oldItem = oldData?.find((oldEl) => oldEl.id === el.id)

        return {
            id: el.id,
            additionalMaterialId: oldItem?.additionalMaterialId,
            name: oldItem?.name || el.name,
            structuralUnit: oldItem?.structuralUnit,
            launchUrl: el.launchUrl,
        }
    })

/**
 * Рендер кнопок действия для таблицы выбора видео
 * @param rowInfo данные строки таблицы
 */
export const renderTableActions = ({
    record,
    formMode,
    disabled,
    onRemoveRow,
}: CustomTableActionsProps<VideoFilesFormContract>) => (
    <>
        <Button
            type="link"
            href={`${API_BASE_URL}${record.launchUrl}`}
            icon={<IconsAdapter iconType="EyeOutlined" />}
            targetBlank
        />

        {!isFormModeView(formMode) && (
            <Button
                type="link"
                icon={<IconsAdapter iconType="DeleteOutlined" />}
                onClick={onRemoveRow(record.id)}
                disabled={disabled}
            />
        )}
    </>
)
