import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    ActivityLessonPassingContract,
    ActivityLessonPassingService,
    ActivityPassingInfoContract,
    ActivityPassingService,
    HomeworkStatusType,
} from 'core/api'
import { BackTop, FinishActivityModal, PageContent } from 'components/shared'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsCommonProps } from 'App.types'
import {
    checkAllRequiredBlocksPassed,
    createWarningNotification,
    isActivityCompleted,
    isActivityLessonCompletedSuccessfully,
    isThemeBlockTypeActivity,
    isThemeBlockTypeCourse,
    isThemeBlockTypeHomeworkWithoutEditor,
    isThemeBlockTypeInformation,
    isThemeBlockTypeQuestionnaire,
    isThemeBlockTypeVideoCourse,
    isThemeBlockTypeWebConferenceOrFaceToFace,
} from 'utils'
import { useElementVisible } from 'hooks'
import { useHistory, useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import { BlockPassingLayer } from './components/BlockPassingLayer'
import { BlockPassingWithoutLayer } from './components/BlockPassingWithoutLayer'
import { EventPassingContextProvider } from './EventPassing.context'
import {
    NestedActivitiesProps,
    PassingUnitProps,
    TriggerStartThematicBlockPassingProps,
} from './EventPassing.types'
import { isViewPassedAssignment } from './EventPassing.utils'

/** Страница прохождения мероприятия */
export const EventPassing: React.FC = withLoader(({ updateLoader }) => {
    const { activityId, appointmentActivityId } = useParams<
        UrlParamsCommonProps<'activityId' | 'appointmentActivityId'>
    >()
    const history = useHistory()

    const [passingUnit, setPassingUnit] = useState<PassingUnitProps>()
    const [selectedThematicBlock, setSelectedThematicBlock] = useState<
        ActivityLessonPassingContract
    >()
    const [nestedActivities, setNestedActivities] = useState<
        NestedActivitiesProps
    >()
    const [currentLessonId, setCurrentLessonId] = useState<number>()

    const [progressForSend, setProgressForSend] = useState<number>()
    const [isHomeworkAutoCheck, setHomeworkAutoCheck] = useState(false)

    const {
        handleHideElement: hideFinishActivityModal,
        handleShowElement: showFinishActivityModal,
        elementVisible: isShowFinishActivityModal,
    } = useElementVisible()

    const isAllRequiredBlocksPassed = useMemo(
        () => checkAllRequiredBlocksPassed(passingUnit?.activityLessonPassings),
        [passingUnit]
    )

    const isNestedActivity = !!nestedActivities?.prevLessonsIds?.length

    const redirectToView = useCallback(() => {
        history.replace(
            `${ROUTE_NAMES.EVENTS_PASSING_VIEW}/${activityId}/${appointmentActivityId}`
        )
    }, [activityId, appointmentActivityId, history])

    /**
     * Старт прохождения мероприятия в целом
     */
    const startEventPassing = useCallback(async () => {
        try {
            updateLoader(true)

            const body = {
                activityId: Number(activityId),
                appointmentActivityId: appointmentActivityId
                    ? Number(appointmentActivityId)
                    : undefined,
            } as ActivityPassingInfoContract

            const response = await ActivityPassingService.checkForPassingFormExist(
                { body }
            )

            if (!response.isExist) {
                if (appointmentActivityId) {
                    await ActivityPassingService.startMain({ body })
                } else {
                    await ActivityPassingService.start({ body })
                }
            }

            const sourceData = await ActivityPassingService.getInfo({ body })

            setPassingUnit(sourceData)
            setHomeworkAutoCheck(sourceData.isHomeworkAutoCheck)
        } catch (error) {
            console.error(error)
        } finally {
            updateLoader(false)
        }
    }, [activityId, appointmentActivityId, updateLoader])

    /**
     * Старт прохождения тематического блока
     */
    const triggerStartThematicBlockPassing = useCallback(
        async ({
            lessonId,
            isReturnToPrevEvent,
            isThematicBlockClosed,
        }: TriggerStartThematicBlockPassingProps) => {
            try {
                updateLoader(true)

                const sourceData = await (isViewPassedAssignment()
                    ? ActivityLessonPassingService.info
                    : ActivityLessonPassingService.start)({
                    body: {
                        activityPassingId: (passingUnit?.id ||
                            nestedActivities?.mainActivityPassingId)!,
                        lessonId,
                    },
                })

                if (
                    isThemeBlockTypeHomeworkWithoutEditor(
                        sourceData.type,
                        sourceData?.homework?.isEditorUsed
                    ) &&
                    sourceData?.homework.status ===
                        HomeworkStatusType.OnChecking
                ) {
                    createWarningNotification(
                        LOCAL.MESSAGES.ATTENTION,
                        LOCAL.MESSAGES.HOMEWORK_ON_CHECKING
                    )

                    return
                }

                setCurrentLessonId(sourceData.id)

                if (
                    isThemeBlockTypeCourse(sourceData.type) &&
                    !isThemeBlockTypeVideoCourse(sourceData)
                ) {
                    history.push({
                        search: `?currentCourseId=${sourceData.id}`,
                    })
                }

                if (isThemeBlockTypeActivity(sourceData.type)) {
                    setPassingUnit({
                        ...sourceData.nestedActivityPassing,
                        isHomeworkAutoCheck,
                    })
                    !isThematicBlockClosed &&
                        setNestedActivities((prevState) => {
                            if (selectedThematicBlock) return prevState

                            return {
                                mainActivityPassingId:
                                    sourceData?.mainActivityPassingId,
                                prevLessonsIds: isReturnToPrevEvent
                                    ? prevState?.prevLessonsIds?.slice(0, -1)
                                    : [
                                          ...(prevState?.prevLessonsIds || []),
                                          lessonId,
                                      ],
                            }
                        })

                    return
                }

                setSelectedThematicBlock(sourceData)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        [
            updateLoader,
            passingUnit,
            nestedActivities,
            history,
            isHomeworkAutoCheck,
            selectedThematicBlock,
        ]
    )

    /**
     * Выход из прохождения с сохранением прогресса
     */
    const exitThematicBlockPassing = useCallback(
        async (progress?: number) => {
            try {
                updateLoader(true)

                if (!currentLessonId) return

                await ActivityLessonPassingService.exit({
                    activityLessonPassingId: currentLessonId,
                    body: {
                        progress: progress || progressForSend || 0,
                    },
                })
            } catch (e) {
                console.log(e)
            } finally {
                updateLoader(false)
            }
        },
        [updateLoader, currentLessonId, progressForSend]
    )

    const isShowEventInBlockPassingLayer =
        selectedThematicBlock &&
        !(
            isThemeBlockTypeHomeworkWithoutEditor(
                selectedThematicBlock.type,
                selectedThematicBlock.homework?.isEditorUsed
            ) ||
            isThemeBlockTypeWebConferenceOrFaceToFace(
                selectedThematicBlock.type
            ) ||
            isThemeBlockTypeVideoCourse(selectedThematicBlock) ||
            isThemeBlockTypeQuestionnaire(selectedThematicBlock.type) ||
            isThemeBlockTypeInformation(selectedThematicBlock.type)
        )

    /**
     * Обработчик сброса стейта тематического блока
     */
    const finishingThematicBlockPassage = useCallback(() => {
        history.replace({
            search: '',
        })
        const prevLessonsIds = nestedActivities?.prevLessonsIds

        progressForSend && exitThematicBlockPassing()

        if (prevLessonsIds?.length) {
            triggerStartThematicBlockPassing({
                lessonId: prevLessonsIds[prevLessonsIds.length - 1],
                isThematicBlockClosed: true,
            })
        } else {
            startEventPassing()
        }

        setSelectedThematicBlock(undefined)
    }, [
        history,
        nestedActivities,
        progressForSend,
        exitThematicBlockPassing,
        triggerStartThematicBlockPassing,
        startEventPassing,
    ])

    /**
     * Обработчик возврата на прохождение мероприятия уровнем вложенности выше
     */
    const handleReturnToPrevActivity = useCallback(() => {
        const prevLessonsIds = nestedActivities?.prevLessonsIds
        const lessonId = prevLessonsIds?.[prevLessonsIds.length - 2]

        if (prevLessonsIds?.length && lessonId) {
            triggerStartThematicBlockPassing({
                lessonId,
                isReturnToPrevEvent: true,
            })
        } else {
            setNestedActivities(undefined)
        }
    }, [nestedActivities, triggerStartThematicBlockPassing])

    /**
     * Обработчик завершения обуч.материала
     * @param isCompleted признак прохождения, для tincan может быть false
     */
    const handleViewMaterial = useCallback(
        async (isCompleted = true) => {
            if (selectedThematicBlock) {
                try {
                    updateLoader(true)

                    if (!isViewPassedAssignment() && isCompleted) {
                        await ActivityLessonPassingService.finish({
                            activityLessonPassingId: selectedThematicBlock.id,
                        })
                    }

                    finishingThematicBlockPassage()
                } catch (e) {
                    console.log(e)
                } finally {
                    updateLoader(false)
                }
            }
        },
        [selectedThematicBlock, updateLoader, finishingThematicBlockPassage]
    )

    /**
     * Обработчик выхода в процессе просмотра видео-контента
     */
    const handleExitVideoCourse = useCallback(
        async (progress = 0) => {
            try {
                updateLoader(true)

                await exitThematicBlockPassing(progress)

                startEventPassing()

                setSelectedThematicBlock(undefined)
            } catch (e) {
                console.log(e)
            } finally {
                updateLoader(false)
            }
        },
        [updateLoader, exitThematicBlockPassing, startEventPassing]
    )

    const finishLessonWithNestedActivity = useCallback(async () => {
        try {
            if (!(isAllRequiredBlocksPassed && currentLessonId)) return

            updateLoader(true)

            await ActivityLessonPassingService.finish({
                activityLessonPassingId: currentLessonId,
            })
        } catch (error) {
            console.error(error)
        } finally {
            updateLoader(false)
        }
    }, [currentLessonId, updateLoader, isAllRequiredBlocksPassed])

    /**
     * Обработчик возврата на прохождение мероприятия уровнем вложенности выше
     */
    const triggerReturnToPrevActivity = useCallback(async () => {
        await finishLessonWithNestedActivity()

        handleReturnToPrevActivity()
    }, [finishLessonWithNestedActivity, handleReturnToPrevActivity])

    useEffect(() => {
        if (!nestedActivities) {
            startEventPassing()
        }
    }, [nestedActivities, startEventPassing])

    useEffect(() => {
        if (
            !isViewPassedAssignment() &&
            isActivityCompleted(passingUnit?.status)
        ) {
            redirectToView()
        }
    }, [passingUnit, redirectToView])

    return (
        <EventPassingContextProvider updateLoader={updateLoader}>
            <PageContent>
                <BackTop />

                {passingUnit && (
                    <>
                        {isShowEventInBlockPassingLayer ? (
                            <BlockPassingLayer
                                activity={passingUnit}
                                nestedActivities={nestedActivities}
                                thematicBlock={selectedThematicBlock!}
                                onReturnToPrevActivity={
                                    handleReturnToPrevActivity
                                }
                                setProgressForSend={setProgressForSend}
                                onFinishThematicBlock={
                                    finishingThematicBlockPassage
                                }
                                onViewMaterial={handleViewMaterial}
                            />
                        ) : (
                            <BlockPassingWithoutLayer
                                passingUnit={passingUnit}
                                nestedActivities={nestedActivities}
                                currentLessonId={currentLessonId}
                                handleReturnToPrevActivity={
                                    handleReturnToPrevActivity
                                }
                                triggerStartThematicBlockPassing={
                                    triggerStartThematicBlockPassing
                                }
                                selectedThematicBlock={selectedThematicBlock}
                                finishingThematicBlockPassage={
                                    finishingThematicBlockPassage
                                }
                                isHomeworkAutoCheck={isHomeworkAutoCheck}
                                handleViewMaterial={handleViewMaterial}
                                handleExitVideoCourse={handleExitVideoCourse}
                            />
                        )}
                    </>
                )}
            </PageContent>

            {activityId &&
                !isActivityLessonCompletedSuccessfully(passingUnit?.status) && (
                    <FinishActivityModal
                        visible={isShowFinishActivityModal}
                        activityId={+activityId}
                        appointmentActivityId={appointmentActivityId}
                        closeModal={hideFinishActivityModal}
                        openModal={showFinishActivityModal}
                        finishActivity={
                            isNestedActivity
                                ? triggerReturnToPrevActivity
                                : startEventPassing
                        }
                        isNestedActivity={isNestedActivity}
                        eventName={passingUnit?.name}
                        activityLessons={passingUnit?.activityLessonPassings}
                    />
                )}
        </EventPassingContextProvider>
    )
})
