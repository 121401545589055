type MakeStyleObjectResultProps = {
    [k: string]: string
}

/**
 * Создать объект стилей из строки
 * @param str строка стилей
 */
export const makeStyleObject = (
    str?: string | null
): MakeStyleObjectResultProps | undefined => {
    if (!str) return

    const result = str.match(/(.+?;)/gi)?.map((el) => {
        const stylesArrWithoutSpace = el.replace(/[;\s]/g, '')
        const stylesArrUpperCase = stylesArrWithoutSpace.replace(
            /-(.{1,1})/g,
            (_, group) => group[0].toUpperCase() + group.slice(1)
        )

        return stylesArrUpperCase.split(':') as [string, string]
    })

    const stylesMap = new Map<string, string>(result)

    return Object.fromEntries(stylesMap)
}

/**
 * Вставить в тег `head` тег `style` вместе с заданными стилями css
 * @param style стили css
 * @param dataValue значение для атрибута `data-injected-style`
 * @returns {HTMLStyleElement} созданный html элемент стилей
 */
export const injectStyle = (
    style: string | string[],
    dataValue = ''
): HTMLStyleElement | undefined => {
    if (!style) return

    const styleElement = document.createElement('style')

    styleElement.setAttribute('data-injected-style', dataValue)
    styleElement.setAttribute('type', 'text/css')

    document.head.appendChild(styleElement)
    const styleSheet = styleElement.sheet as CSSStyleSheet

    if (!Array.isArray(style)) {
        styleSheet.insertRule(style, styleSheet.cssRules.length)
    } else {
        style.forEach((currentStyle) => {
            if (!currentStyle) return

            styleSheet.insertRule(currentStyle, styleSheet.cssRules.length)
        })
    }

    return styleElement
}

/**
 * Конвертировать opacity в hex-эквивалент
 */
export const convertOpacityToHex = (opacity: number) => {
    if (opacity >= 1) return 'FF'
    if (opacity <= 0) return '00'

    return Math.round(opacity * 255)
        .toString(16)
        .toUpperCase()
}

/**
 * Сделать hex-цвет с прозрачностью с помощью opacity
 * Hex-цвет должен быть указан в 6-значной форме с хештегом по типу #000000
 */
export const makeHexWithOpacity = (hex: string, opacity: number) =>
    hex.length === 7 ? `${hex}${convertOpacityToHex(opacity)}` : hex

/**
 * Формирует объект стиля с полем 'backgroundImage'
 * @param url ссылка на изображения для фона
 */
export const getBackgroundImageStyle = (url?: string) =>
    url ? { backgroundImage: `url(${url})` } : undefined
