import React from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { ComponentCommonProps } from 'App.types'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import { isFormModeView } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useDictionariesHelper } from 'hooks'
import { useParams } from 'react-router-dom'

import {
    ASSESSMENT_REPORTS_CREATE_PERMISSIONS,
    ASSESSMENT_REPORTS_FORM_ID,
} from './AssessmentReportsSettings.consts'
import { AssessmentReportsForm } from './components'
import { UrlParamsProps } from './AssessmentReportsSettings.types'
import { getPageTitle } from './AssessmentReportsSettings.utils'

const AssessmentReportsSettings: React.FC<
    ComponentCommonProps & WithBlockUserRoutingComponentProps
> = withLoader(({ formMode, onBlockUserRouting, updateLoader }) => {
    const { assessmentTypeDictionary } = useDictionariesHelper([
        'assessmentTypeDictionary',
    ])

    const { type } = useParams<UrlParamsProps>()

    return (
        <PageContent>
            <ContentTitleWithBackLink
                title={getPageTitle(assessmentTypeDictionary[type])}
            >
                <ButtonsToolbar>
                    <Button type="link" href={ROUTE_NAMES.ASSESSMENT_REPORTS}>
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    <HasPermissions
                        requiredPermissions={[
                            ASSESSMENT_REPORTS_CREATE_PERMISSIONS[
                                type as keyof typeof ASSESSMENT_REPORTS_CREATE_PERMISSIONS
                            ],
                        ]}
                        accessDeniedRender={renderAccessDeniedButton({
                            type: 'primary',
                            text: LOCAL.ACTIONS.CREATE,
                        })}
                    >
                        <Button
                            form={ASSESSMENT_REPORTS_FORM_ID}
                            htmlType="submit"
                            type="primary"
                            disabled={isFormModeView(formMode)}
                        >
                            {LOCAL.ACTIONS.SAVE}
                        </Button>
                    </HasPermissions>
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <AssessmentReportsForm
                updateLoader={updateLoader}
                onBlockUserRouting={onBlockUserRouting}
            />
        </PageContent>
    )
})

export default React.memo(
    withBlockUserRouting(AssessmentReportsSettings, false)
) as typeof AssessmentReportsSettings
