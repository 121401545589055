import { IRetryPolicy, RetryContext } from '@microsoft/signalr'

// 10, 20, 30, 60 second delays before reconnect attempts.
const DEFAULT_RETRY_DELAYS_IN_MILLISECONDS = [10000, 20000, 30000, 60000]

/**
 * Описание политики переподключения к хабу
 */
export class ReconnectPolicy implements IRetryPolicy {
    private readonly retryDelays: Array<number | null>

    constructor() {
        this.retryDelays = DEFAULT_RETRY_DELAYS_IN_MILLISECONDS
    }

    public nextRetryDelayInMilliseconds(
        retryContext: RetryContext
    ): number | null {
        const retryDelay = Math.min(
            this.retryDelays.length - 1,
            retryContext.previousRetryCount
        )

        return this.retryDelays[retryDelay]
    }
}
