import React, { FC, useCallback, useEffect, useState } from 'react'
import { DATE_FORMAT } from 'consts'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import {
    FormItemAdapter,
    HiddenField,
    NotEditableTextField,
    TextAreaControl,
} from 'components/controls'
import { HomeworksService } from 'core/api'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { getDateByFormat } from 'utils'
import { withLoader } from 'HOCs'

import { getHomeworkTime } from './CheckHomeworkInfoForm.utils'

export const CheckHomeworkInfoForm: FC<PopupAdapterFormProps> = withLoader(
    React.memo(({ initialValues, onRequestFinish, updateLoader }) => {
        const [form] = Form.useForm()

        const [isEditorUsed, setEditorUsed] = useState(true)

        const changeDateLabel = isEditorUsed
            ? LOCAL.LABELS.REVISION_NUMBER
            : LOCAL.LABELS.HOMEWORK_TIME

        const handleFormSubmit = useCallback(
            async ({ id, comment }) => {
                try {
                    updateLoader(true)

                    onRequestFinish?.(
                        await HomeworksService.updateComment({
                            id,
                            body: {
                                comment,
                            },
                        })
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [onRequestFinish, updateLoader]
        )

        useEffect(() => {
            const fetchHomeworkInfo = async () => {
                try {
                    updateLoader(true)

                    const info = await HomeworksService.info({
                        id: initialValues?.id,
                    })

                    setEditorUsed(info.isEditorUsed)

                    form.setFieldsValue({
                        ...info,
                        changeDate: info.isEditorUsed
                            ? getDateByFormat(
                                  info.changeDate,
                                  DATE_FORMAT.WITH_TIME
                              )
                            : getHomeworkTime({
                                  lessonStartDate: info.lessonStartDate,
                                  onCheckingDate: info.onCheckingDate,
                              }),
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }

            fetchHomeworkInfo()
        }, [form, initialValues, updateLoader])

        return (
            <div>
                <Form
                    id={FORM_IDS.CHECK_HOMEWORK_INFO_FORM}
                    onFinish={handleFormSubmit}
                    form={form}
                >
                    <HiddenField fieldName="id" />

                    <FormItemAdapter
                        fieldName="comment"
                        label={LOCAL.LABELS.COMMENT}
                    >
                        <TextAreaControl />
                    </FormItemAdapter>

                    <div>
                        {`${changeDateLabel}: `}

                        <NotEditableTextField fieldName="changeDate" />
                    </div>
                </Form>
            </div>
        )
    }),
    { onTopOfEverything: false }
)
