import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { DATE_FORMAT } from 'consts'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { getDateByFormat } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './AnswersHistory.module.scss'
import { AnswersHistoryProps } from './AnswersHistory.types'

export const AnswersHistory: React.FC<AnswersHistoryProps> = React.memo(
    ({ item, attempt }) => {
        const { updateElementVisible, elementVisible } = useElementVisible()
        const [showChevronBtn, setShowChevronBtn] = useState(false)

        const userHistoryRef = React.useRef<HTMLDivElement>(null)
        const tutorHistoryRef = React.useRef<HTMLDivElement>(null)

        const renderDate = useCallback(
            (date: Date) => (
                <span className={styles.dateWrapper}>
                    <span className={styles.historyDate}>
                        {getDateByFormat(date, DATE_FORMAT.DATE)}
                    </span>
                    <span>{getDateByFormat(date, DATE_FORMAT.HH_mm)}</span>
                </span>
            ),
            []
        )

        useEffect(() => {
            if (
                (userHistoryRef.current &&
                    userHistoryRef.current?.scrollWidth >
                        userHistoryRef.current?.offsetWidth) ||
                (tutorHistoryRef.current &&
                    tutorHistoryRef.current?.scrollWidth >
                        tutorHistoryRef.current?.offsetWidth)
            ) {
                setShowChevronBtn(true)
            }
        }, [])

        return (
            <div>
                <div>{`${attempt} ${LOCAL.LABELS.ATTEMPT.toLowerCase()}`}</div>

                <div className={styles.wrapper}>
                    <div className={styles.historyInfo}>
                        <div
                            className={cn(
                                styles.historyBlock,
                                styles.answerBlock
                            )}
                        >
                            {renderDate(item.answerDate)}

                            <span
                                className={styles.label}
                            >{`${LOCAL.LABELS.ANSWER}:`}</span>

                            <div
                                className={cn(
                                    styles.text,
                                    elementVisible && styles.fullText
                                )}
                                ref={userHistoryRef}
                            >
                                {item.answerText}
                            </div>
                        </div>

                        <div className={styles.historyBlock}>
                            {renderDate(item.commentDate)}

                            <span
                                className={styles.label}
                            >{`${LOCAL.LABELS.COMMENT}:`}</span>

                            <div
                                className={cn(
                                    styles.text,
                                    elementVisible && styles.fullText
                                )}
                                ref={tutorHistoryRef}
                            >
                                {item.comment}
                            </div>
                        </div>
                    </div>

                    {showChevronBtn && (
                        <div
                            className={cn(
                                styles.showAllBtn,
                                elementVisible && styles.showAllBtnOpened
                            )}
                            onClick={() =>
                                updateElementVisible(!elementVisible)
                            }
                        >
                            <IconsAdapter iconType="CustomIconChevron" />
                        </div>
                    )}
                </div>
            </div>
        )
    }
)
