import React, { useCallback } from 'react'
import { Form } from 'antd'
import { Spoiler } from 'components/shared'

import { AssessmentProjectPortfolioProps } from './AssessmentProjectPortfolio.types'
import { AssessmentProjectsPortfolioCompetencies } from '../AssessmentProjectsPortfolioCompetencies'
import { PortfolioAssessmentObjects } from '../PortfolioAssessmentObjects'
import { renderPortfolioTitle } from './AssessmentProjectPortfolio.utils'

/** Портфель оценивания формы оценочного проекта */
export const AssessmentProjectPortfolio: React.FC<AssessmentProjectPortfolioProps> = React.memo(
    ({ formMode, portfolio, field, remove }) => {
        /**
         * Обработчик удаления портфеля
         */
        const handleRemovePortfolio = useCallback(() => {
            remove?.(field.name)
        }, [field.name, remove])

        return (
            <Spoiler
                spoilerTitleRender={renderPortfolioTitle({
                    portfolio,
                    formMode,
                    handleRemovePortfolio,
                })}
            >
                <Form.Item name={[field.name, 'circleParameters']}>
                    <AssessmentProjectsPortfolioCompetencies />
                </Form.Item>

                <Form.Item name={[field.name, 'assessmentObjects']}>
                    <PortfolioAssessmentObjects
                        field={field}
                        formMode={formMode}
                    />
                </Form.Item>
            </Spoiler>
        )
    }
)
