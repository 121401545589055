import React, { useCallback } from 'react'
import { CourseFormat, CoursesService } from 'core/api'
import { LOCAL } from 'core/local'
import { Modal } from 'antd'
import { PlayerIframe, PlayerPdf, PlayerVideo } from 'components/shared'
import {
    RenderToolbar,
    Slot,
    ToolbarSlot,
    defaultLayout,
} from '@phuocng/react-pdf-viewer'
import { WithLoaderProps, withLoader } from 'HOCs'
import {
    createConfirmPopup,
    getMaterialLaunchUrl,
    isTrainingMaterialFormatAvi,
    isTrainingMaterialFormatMp4,
    isTrainingMaterialFormatPdf,
    isTrainingMaterialFormatScorm12,
    isTrainingMaterialFormatScorm13,
    isTrainingMaterialFormatTinCan,
    updateIframeNextButton,
} from 'utils'

import styles from './PlayerView.module.scss'
import { PlayerViewProps } from './PlayerView.types'

/**
 * Визуализация прохождения тематического блока, выполняемая в определенном плеере
 */
export const PlayerView: React.FC<
    PlayerViewProps & Partial<WithLoaderProps>
> = withLoader(
    React.memo(
        ({
            courseData,
            onViewMaterial,
            progress,
            setProgressForSend,
            updateLoader,
        }) => {
            const { format, launchUrl } = courseData || {}

            const onFinishTincanPassing = useCallback(async () => {
                try {
                    updateLoader(true)

                    const isPassed = await CoursesService.tincanIsPassed({
                        courseId: courseData.id,
                    })

                    if (isPassed) {
                        onViewMaterial?.()

                        return
                    }

                    createConfirmPopup({
                        className: styles.confirm,
                        title: '',
                        content:
                            LOCAL.MESSAGES.TRAINING_MATERIAL_IS_NOT_FINISHED,
                        okText: LOCAL.YES,
                        cancelText: LOCAL.NO,
                        onCancel: () => {
                            Modal.destroyAll()
                        },
                        onOk: () => {
                            onViewMaterial?.(false)
                        },
                    })
                } catch (err) {
                    console.error(err)
                } finally {
                    updateLoader(false)
                }
            }, [courseData.id, onViewMaterial, updateLoader])

            /** Обработка загрузки айфрейма */
            const handleIframeLoad = useCallback(
                (iframe: HTMLIFrameElement) => {
                    updateIframeNextButton(iframe, onFinishTincanPassing)
                },
                [onFinishTincanPassing]
            )

            /**
             * Панель управления для плеера PDF
             * @param toolbarSlot - объект компонентов панели
             */
            const renderToolbarPDF = (toolbarSlot: ToolbarSlot) => (
                <>
                    {toolbarSlot.previousPageButton}

                    <div>
                        {`${toolbarSlot.currentPage + 1} / ${
                            toolbarSlot.numPages
                        }`}
                    </div>

                    {toolbarSlot.nextPageButton}

                    {toolbarSlot.zoomOutButton}

                    {toolbarSlot.zoomInButton}

                    {toolbarSlot.fullScreenButton}
                </>
            )

            /**
             * Подбираем плеер, соответствующий формату курса
             * @param format - формат курса
             * @param fileUrl - ссылка для загрузки файла курса
             */
            const getPlayer = (format: CourseFormat, fileUrl?: string) => {
                if (isTrainingMaterialFormatPdf(format))
                    return (
                        <PlayerPdf
                            fileUrl={fileUrl}
                            viewerOptions={{
                                layout: (
                                    isSidebarOpened: boolean,
                                    container: Slot,
                                    main: Slot,
                                    toolbar: RenderToolbar,
                                    sidebar: Slot
                                ) =>
                                    defaultLayout(
                                        isSidebarOpened,
                                        container,
                                        main,
                                        toolbar(renderToolbarPDF),
                                        sidebar
                                    ),
                            }}
                            progress={progress}
                            setProgressForSend={setProgressForSend}
                        />
                    )

                if (
                    isTrainingMaterialFormatTinCan(format) ||
                    isTrainingMaterialFormatScorm12(format) ||
                    isTrainingMaterialFormatScorm13(format)
                )
                    return (
                        <PlayerIframe
                            fileUrl={fileUrl}
                            format={format}
                            onLoad={handleIframeLoad}
                            enableLoader
                            courseData={courseData}
                        />
                    )

                if (
                    isTrainingMaterialFormatMp4(format) ||
                    isTrainingMaterialFormatAvi(format)
                )
                    return (
                        <div className={styles.videoWrapper}>
                            <PlayerVideo
                                fileUrl={fileUrl}
                                progress={progress}
                                setProgressForSend={setProgressForSend}
                            />
                        </div>
                    )
            }

            return (
                <div className={styles.wrapper}>
                    {getPlayer(format, getMaterialLaunchUrl(launchUrl))}
                </div>
            )
        }
    )
)
