import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import {
    DatePickerControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { FilterForm } from 'components/shared/FilterForm'
import {
    FiltersProps,
    isNotificationDestinationCategoryTrack,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import {
    NotificationDestinationCategoriesService,
    NotificationDestinationCategoryContract,
    NotificationMailingTaskAppointmentSearchContract,
} from 'core/api'
import { ROW_GUTTER, TRACK_TYPES_MAPPED } from 'consts'
import { useHttp } from 'hooks'

import { getStatusByCategoryId } from './AppointmentSelectionFilter.utils'

/**
 * Форма фильтрации назначений
 */
export const AppointmentSelectionFilter: React.FC<FiltersProps<
    NotificationMailingTaskAppointmentSearchContract
>> = React.memo((props) => {
    const [fetchCategories, categories] = useHttp<
        NotificationDestinationCategoryContract[]
    >(NotificationDestinationCategoriesService.getForSelect)

    useEffect(() => {
        fetchCategories()
    }, [fetchCategories])

    return (
        <FilterForm {...props}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="categoryId"
                        label={LOCAL.LABELS.MAILING_CATEGORY_TYPE}
                    >
                        <SelectControl
                            values={categories?.map(
                                normalizeDataForSelectAndRadio
                            )}
                            disabled
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <ShouldUpdateChecker fieldPath={['categoryId']}>
                        {({ getFieldValue }) => {
                            const { fieldName, values } = getStatusByCategoryId(
                                categories?.find(
                                    (el) =>
                                        el.id === getFieldValue('categoryId')
                                )?.code
                            )

                            return (
                                <FormItemAdapter
                                    fieldName={fieldName}
                                    label={LOCAL.LABELS.STATUS}
                                >
                                    <SelectControl
                                        mode="multiple"
                                        values={values}
                                        allowClear
                                        showSearch
                                    />
                                </FormItemAdapter>
                            )
                        }}
                    </ShouldUpdateChecker>
                </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <ShouldUpdateChecker fieldPath={['categoryId']}>
                        {({ getFieldValue }) =>
                            isNotificationDestinationCategoryTrack(
                                categories?.find(
                                    (el) =>
                                        el.id === getFieldValue('categoryId')
                                )?.code
                            ) && (
                                <FormItemAdapter
                                    fieldName="type"
                                    label={LOCAL.LABELS.APPOINTMENT_TYPE}
                                >
                                    <SelectControl
                                        values={TRACK_TYPES_MAPPED}
                                        allowClear
                                        showSearch
                                    />
                                </FormItemAdapter>
                            )
                        }
                    </ShouldUpdateChecker>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="dateStart"
                        label={LOCAL.LABELS.DATE_START}
                    >
                        <DatePickerControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="dateEnd"
                        label={LOCAL.LABELS.DATE_END}
                    >
                        <DatePickerControl />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    )
})
