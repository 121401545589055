import React, { useCallback, useEffect, useState } from 'react'
import {
    AppointmentCard,
    Button,
    ContentTitle,
    FiltersToggleButton,
    PageBanner,
    PageContent,
} from 'components/shared'
import {
    AppointmentUserSearchResultContractPageContract,
    AppointmentsService,
} from 'core/api'
import { Col, Row } from 'antd'
import { DICTIONARY_KEYS } from 'core/configs'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    RequestQueryProps,
    useBreakpoint,
    useFilter,
    usePageSettings,
} from 'hooks'
import { createSuccessNotification, getShowMoreText, showElem } from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import styles from './QuestionnairesListenerPage.module.scss'
import {
    PAGINATION_SETTINGS,
    QUESTIONNAIRES_LISTENER_INITIAL_VALUES,
} from './QuestionnairesListenerPage.config'
import { QuestionnairesListenerFilters } from './components'

/** Реестр назначенных оценок на слушателя */
export const QuestionnairesListenerPage: React.FC = withLoader(
    React.memo(({ updateLoader, isLoading }) => {
        const { saveFilters } = usePageSettings(
            DICTIONARY_KEYS.QUESTIONNAIRES_LISTENER
        )

        const isWidthLessXXL = useBreakpoint('XXL')

        const [appointments, setAppointments] = useState<
            AppointmentUserSearchResultContractPageContract
        >()

        const {
            queryParams,
            handleSetFilters,
            handleResetFilters,
            handleShowMore,
            isShowMore,
        } = useSearchQuery({
            dictionaryKey: DICTIONARY_KEYS.QUESTIONNAIRES_LISTENER,
            queryOptions: {
                ...PAGINATION_SETTINGS,
                body: QUESTIONNAIRES_LISTENER_INITIAL_VALUES,
            },
        })

        const [visibleFilter, triggerFilterVisibility] = useFilter()

        const dictionaryFetch = useCallback(async () => {
            try {
                updateLoader(true)

                const dataSource = await AppointmentsService.searchAppointmentsForUser(
                    queryParams as Required<RequestQueryProps>
                )

                setAppointments((prev) =>
                    isShowMore
                        ? {
                              total: dataSource.total,
                              pageItems: [
                                  ...(prev?.pageItems || []),
                                  ...dataSource.pageItems,
                              ],
                          }
                        : dataSource
                )
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [isShowMore, queryParams, updateLoader])

        const showMoreEntities = useCallback(() => {
            handleShowMore(appointments?.pageItems.length)
        }, [appointments, handleShowMore])

        const showMoreText =
            appointments &&
            getShowMoreText({
                total: appointments.total,
                pageSize: PAGINATION_SETTINGS.pageSize,
                loadedEntitiesCount: appointments.pageItems.length,
            })

        useEffect(() => {
            dictionaryFetch()
        }, [dictionaryFetch])

        useEffect(() => {
            if (saveFilters) {
                createSuccessNotification(LOCAL.MESSAGES.SAVE_FILTERS)
            }
        }, [saveFilters])

        return (
            <PageContent filled className={styles.wrapper}>
                <PageBanner />

                <ContentTitle
                    title={getRouteMeta(ROUTE_NAMES.QUESTIONNAIRES_LISTENER)}
                    className={styles.contentTitle}
                >
                    <Row
                        gutter={15}
                        justify={isWidthLessXXL ? undefined : 'end'}
                        align="middle"
                    >
                        <Col>
                            <FiltersToggleButton
                                onToggleFilterVisibility={
                                    triggerFilterVisibility
                                }
                                isActive={saveFilters}
                            />
                        </Col>
                    </Row>
                </ContentTitle>

                <div className={showElem(visibleFilter)}>
                    <QuestionnairesListenerFilters
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                        dictionaryKey={DICTIONARY_KEYS.QUESTIONNAIRES_LISTENER}
                        tableFiltersOptions={{
                            initialValues: QUESTIONNAIRES_LISTENER_INITIAL_VALUES,
                        }}
                    />
                </div>

                {!!appointments?.pageItems.length && (
                    <div className={styles.cards}>
                        {appointments.pageItems.map((item, i) => (
                            <AppointmentCard {...item} key={i} />
                        ))}
                    </div>
                )}

                {showMoreText && (
                    <div className="mt-40">
                        <Button
                            type="dashed"
                            size="large"
                            onClick={showMoreEntities}
                            disabled={isLoading}
                            loading={isLoading}
                            block
                        >
                            {showMoreText}
                        </Button>
                    </div>
                )}
            </PageContent>
        )
    })
)
