import { AppError, CourseValidationContract } from 'core/api'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'

export enum ProgressPercents {
    NOT_UPLOADED = 0,
    START_UPLOAD = 1,
    UPLOAD_FILE = 30,
    UPLOAD_SUCCESS = 60,
    END_UPLOAD = 100,
}

export interface UploadCourseErrorProps extends Omit<AppError, 'data'> {
    data: AppError['data'] & CourseValidationContract
}

export interface UploadCourseFormProps extends PopupAdapterFormProps {
    newVersion?: boolean
    initialValues?: {
        structuralUnitId: number
        name: string
        rootCourseVersionId: number
        format?: string
    }
}
