import isNumber from 'lodash/isNumber'
import last from 'lodash/last'
import noImageBg from 'components/shared/IconsAdapter/img/icon-noimg.svg'
import noImageBgDark from 'components/shared/IconsAdapter/img/icon-noimg-dark.svg'
import { ColorThemes } from 'contexts'
import { injectStyle, renderStringArrayWithSeparator } from 'utils'
import { isThemeColorLight } from 'contexts/ColorTheme/ColorTheme.utils'

import {
    FormDataStagesStylesIconsProps,
    FormDataStagesStylesProps,
    IconStatusProps,
    IconWithMetaProps,
} from '../../../TrackForm.types'
import { ICON_BORDER_RADIUS_MAX_VALUE } from '../SingleStageVisualisationIconOption/SingleStageVisualisationIconOption.consts'
import {
    TRACK_STAGE_ICON_SIZE_DEFAULT,
    TRACK_STAGE_STATUSES,
} from './TrackStageDnD.consts'

function getBackgroundRulePart(
    { stageStyle, stageStatus }: ComposeStageIconStylesProps,
    theme: ColorThemes
) {
    const noImageBgSize = '22px'
    const bgImgPlaceholder = isThemeColorLight(theme)
        ? noImageBg
        : noImageBgDark
    const bgPlaceholder = `
        background-image: url(${bgImgPlaceholder});
        background-size: ${noImageBgSize};
        background-color: var(--track_stage_dnd_bg_color);
    `

    if (!stageStyle?.icons) return bgPlaceholder

    const icon = stageStyle.icons[stageStatus]

    if (!icon) return bgPlaceholder

    const resultBg = icon.meta?.currentIconUrl

    return `
        background-image: url(${resultBg ? resultBg : bgImgPlaceholder});
        background-size: ${resultBg ? 'cover' : noImageBgSize};
        ${
            resultBg
                ? 'background-color: transparent; border: 0;'
                : 'background-color: var(--track_stage_dnd_bg_color);'
        }
    `
}

export function getShadowRulePart({
    shadowColor,
    haveShadow,
}: IconWithMetaProps): string {
    if (!shadowColor) return 'box-shadow: none;'

    switch (haveShadow) {
        case 'right':
            return `box-shadow: 5px 5px 20px ${shadowColor};`
        case 'left':
            return `box-shadow: -5px 5px 20px ${shadowColor};`
        case 'center':
            return `box-shadow: 0 0 20px ${shadowColor};`
        default:
            return 'box-shadow: none;'
    }
}

/**
 * Вычислить скорость анимации
 * @param type тип скорости анимации
 */
export function getAnimationDuration(type: string): string {
    switch (type) {
        case 'slow':
            return '3000ms'
        case 'middle':
            return '2000ms'
        default:
            return '1000ms'
    }
}

function getAnimationOrShadowRulePart({
    stageStyle,
    stageIndex,
    stageStatus,
}: ComposeStageIconStylesProps) {
    if (!stageStyle?.icons) return ''

    const icon = stageStyle.icons[stageStatus]

    if (!icon) return ''

    const { effectTime, effectDuration, effectRepeat } = icon

    if (effectTime && effectDuration && effectRepeat) {
        return `
            animation: pulse-${stageIndex} ${getAnimationDuration(
            effectDuration
        )} ${effectTime}s infinite
        `
    }

    return getShadowRulePart(icon)
}

function getKeyframesAnimationRule({
    stageStyle,
    stageIndex,
    stageStatus,
}: ComposeStageIconStylesProps) {
    if (!stageStyle?.icons) return ''

    const icon = stageStyle.icons[stageStatus]

    if (!icon) return ''

    const iconShadow = getShadowRulePart(icon)

    return `
        @keyframes pulse-${stageIndex} {
            0% { box-shadow: 0 0 0 transparent; }
            50% { ${iconShadow} }
            100% { box-shadow: 0 0 0 transparent; }
        }
    `
}

function getIconSizeRulePart({
    stageStyle,
    stageStatus,
}: ComposeStageIconStylesProps) {
    if (!stageStyle) return ''

    const {
        width,
        height,
        iconRounding,
    }: FormDataStagesStylesIconsProps[keyof FormDataStagesStylesIconsProps] =
        stageStyle.icons?.[stageStatus] || {}

    if (!width && !height && !isNumber(iconRounding)) return ''

    return `
        width: ${width || TRACK_STAGE_ICON_SIZE_DEFAULT}px;
        height: ${height || TRACK_STAGE_ICON_SIZE_DEFAULT}px;
        border-radius: ${
            iconRounding
                ? (iconRounding || ICON_BORDER_RADIUS_MAX_VALUE) / 2
                : iconRounding
        }%;
    `
}

/**
 * Подготовить стили иконки этапа
 * @param styles объект стилей
 * @param stageIndex индекс этапа трека
 * @param isHover
 */
function prepareIconStylesInjection(
    styles: string[],
    stageIndex: string,
    isHover?: boolean
) {
    return `
        [data-id="${stageIndex}"] > [class*='img'] ${isHover ? ':hover' : ''} {
            ${renderStringArrayWithSeparator(styles, '')}
        }
    `
}

interface ComposeStageIconStylesProps {
    stageIndex: string
    stageStatus: IconStatusProps
    stageStyle?: FormDataStagesStylesProps
}

/**
 * Собрать стили для иконки
 */
export function composeStageIconStyles(
    settings: ComposeStageIconStylesProps,
    theme: ColorThemes
) {
    const { stageStyle, stageStatus } = settings

    const icon = stageStyle?.icons?.[stageStatus]

    const keyframesRule = getKeyframesAnimationRule(settings)
    const animationOrShadowRulePart = getAnimationOrShadowRulePart(settings)
    const iconSizeRulePart = getIconSizeRulePart(settings)
    const backgroundRulePart = getBackgroundRulePart(settings, theme)

    const preparedStageRule = prepareIconStylesInjection(
        [
            backgroundRulePart,
            iconSizeRulePart,
            icon?.effectStart !== 'onMouseOver'
                ? animationOrShadowRulePart
                : '',
        ],
        settings.stageIndex
    )

    const preparedStageHoverRule = prepareIconStylesInjection(
        [icon?.effectStart === 'onMouseOver' ? animationOrShadowRulePart : ''],
        settings.stageIndex,
        true
    )

    return injectStyle(
        [preparedStageRule, preparedStageHoverRule, keyframesRule],
        `stage-dnd-${settings.stageIndex}`
    )
}

export const getNextIconStatus = (iconStatus?: IconStatusProps) =>
    TRACK_STAGE_STATUSES[
        iconStatus === last(TRACK_STAGE_STATUSES)
            ? 0
            : TRACK_STAGE_STATUSES.findIndex(
                  (status) => status === iconStatus
              ) + 1
    ]
