import React from 'react'
import cn from 'classnames'
import { Button } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils/conditions.utils/form.utils'

import styles from './AddButton.module.scss'
import { AddButtonProps } from './AddButton.types'

/** Кнопка добавления */
export const AddButton: React.FC<AddButtonProps> = React.memo(
    ({
        buttonText = LOCAL.ACTIONS.ADD,
        onlyIcon,
        className,
        disabled,
        formMode,
        icon = <IconsAdapter iconType="PlusOutlined" />,
        ...restProps
    }) => (
        <Button
            className={cn(styles.wrapper, className)}
            icon={icon}
            type="default"
            disabled={disabled || isFormModeView(formMode)}
            {...restProps}
        >
            {!onlyIcon && buttonText}
        </Button>
    )
)
