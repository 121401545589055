import React from 'react'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker } from 'components/controls'
import { template } from 'lodash'

import styles from './CircleParameters.module.scss'
import { getRespondentsCount } from './CircleParameters.utils'

export const NUMBER_INPUT_MIN = 0

export const RESPONDENTS_MIN = 6

export const CIRCLE_PARAMETERS_FIELDS_NAMES = {
    managersCount: ['circleParameters', 'managersCount'],
    colleaguesCount: ['circleParameters', 'colleaguesCount'],
    colleagueFromRelatedDepartmentCount: [
        'circleParameters',
        'colleagueFromRelatedDepartmentCount',
    ],
    subordinatesCount: ['circleParameters', 'subordinatesCount'],
}

export const TITLE = (
    <ShouldUpdateChecker
        fieldPath={Object.values(CIRCLE_PARAMETERS_FIELDS_NAMES)}
    >
        {({ getFieldsValue }) => {
            const respondentsCount = getRespondentsCount(
                getFieldsValue(Object.values(CIRCLE_PARAMETERS_FIELDS_NAMES))
                    .circleParameters
            )

            return (
                <>
                    {LOCAL.LABELS.RESPONDENTS}

                    <span className={styles.counter}>
                        {` ${respondentsCount}/${
                            respondentsCount < RESPONDENTS_MIN
                                ? RESPONDENTS_MIN
                                : respondentsCount
                        }`}
                    </span>

                    <div className={styles.counter}>
                        {`* ${template(
                            LOCAL.TOOLTIPS.RESPONDENTS_MIN_REQUIRED
                        )({ count: RESPONDENTS_MIN }).toLowerCase()}`}
                    </div>

                    <div className={styles.counter}>
                        {template(LOCAL.TOOLTIPS.SET_RESPONDENTS)({
                            count: respondentsCount,
                        }).toLowerCase()}
                    </div>
                </>
            )
        }}
    </ShouldUpdateChecker>
)
