import { AppointmentContract, QuestionnaireType } from 'core/api'
import { getValue, isObjectEmpty, normalizeDataForSelectAndRadio } from 'utils'

import {
    AppointmentQuizFormValues,
    QuizAssignmentFormSubmitValues,
} from './QuizAssignmentForm.types'
import { MIN_DAYS_TO_NOTIFICATION } from './QuizAssignmentForm.consts'

/**
 * Получить параметры опросного листа
 * @param values - текущие значения формы
 */
const getQuestionnaireParameters = ({
    quizType,
    quizPortfolioId,
    ...restValues
}: Partial<AppointmentQuizFormValues>) => {
    const quiz = {
        type: quizType,
        portfolioId: quizPortfolioId,
        ...restValues,
    }

    return isObjectEmpty(quiz, true) ? {} : { quiz }
}

/**
 * Получить значения для отправки на бэк
 * @param values - текущие значения формы
 * @param id - идентификатор назначения
 */
export const mapFormDataToRequest = (
    {
        type,
        quizType,
        quizPortfolioId,
        reviewerIds,
        participantsIds,
        daysToStartNotification,
        daysToEndNotification,
        organizationsIds,
        attemptsLimit,
        attemptsLimited,
        ...restValues
    }: Partial<QuizAssignmentFormSubmitValues>,
    id?: number
) => ({
    ...restValues,
    id,
    type: QuestionnaireType.Quiz,
    organizationsIds: organizationsIds?.map(getValue),
    daysToStartNotification:
        daysToStartNotification || MIN_DAYS_TO_NOTIFICATION,
    daysToEndNotification: daysToEndNotification || MIN_DAYS_TO_NOTIFICATION,
    ...getQuestionnaireParameters({
        quizType,
        quizPortfolioId,
        participantsIds,
        reviewerIds,
        attemptsLimit,
        attemptsLimited,
    }),
})

/**
 * Получить начальные значения для формы назначения
 * @param assignment - опросный лист
 */
export const mapResponseToFormData = (assignment: AppointmentContract) => {
    const { organizations, quiz } = assignment

    return {
        ...assignment,
        organizationsIds: organizations?.map(normalizeDataForSelectAndRadio),
        attemptsLimit: quiz?.attemptsLimit,
        attemptsLimited: quiz?.attemptsLimited,
        quizType: quiz?.type,
        quizPortfolioId: quiz?.portfolio?.id,
        participantsIds: quiz.participants.map((el) => el.id),
        reviewerIds: quiz?.reviewers?.map((el) => el.id),
    }
}
