import React from 'react'
import cn from 'classnames'
import last from 'lodash/last'
import {
    UserRegistryContract,
    UserStructuralUnitPositionRegistryContract,
} from 'core/api'

import styles from './CoworkersStructuralUnitsColumn.module.scss'
import {
    isPositionChanged,
    isStructuralUnitChanged,
} from './CoworkersStructuralUnitsColumn.utils'

/**
 * Рендер для поля "Должность"
 * @params userStructuralUnitPositions - массив должностей пользователя в подразделениях
 */
export const CoworkersStructuralUnitsColumn = (
    userStructuralUnitPositions: UserStructuralUnitPositionRegistryContract[],
    record: UserRegistryContract
) => {
    if (!userStructuralUnitPositions?.length) return

    return userStructuralUnitPositions.map((el, index) => {
        const structuralUnits = el.structuralUnits
        const isMoreThanOneUnits = structuralUnits.length > 1

        return (
            <div key={index} className={styles.wrapper}>
                <div
                    className={cn(
                        styles.position &&
                            isPositionChanged(el.position, record) &&
                            styles.changedData
                    )}
                >
                    <span>{el.position?.name}</span>
                </div>

                <span
                    className={cn(
                        styles.units &&
                            isStructuralUnitChanged(structuralUnits, record) &&
                            styles.changedData
                    )}
                >
                    <span className={isMoreThanOneUnits ? styles.unit : ''}>
                        {last(structuralUnits)?.name}
                    </span>

                    {isMoreThanOneUnits && (
                        <span>{`${` / ${structuralUnits[0].name}`}`}</span>
                    )}
                </span>
            </div>
        )
    })
}
