import cn from 'classnames'
import React, { useCallback } from 'react'
import { CollapseSingle } from 'components/shared/CollapseSingle'
import { Form, Radio, Space } from 'antd'
import { Title } from 'components/shared/Title'

import styles from './RadioWithSpoilerControl.module.scss'
import { RadioWithSpoilerControlProp } from './RadioWithSpoilerControl.types'

/**
 * Контрол радио группы с функциональностью спойлера
 */
export const RadioWithSpoilerControl: React.FC<RadioWithSpoilerControlProp> = React.memo(
    ({ values, label, name }) => {
        const renderHeader = useCallback(
            (title) => <Title bottomIndent="0" type="h6" text={title} />,
            []
        )

        return (
            <div className={cn('form-control', styles.wrapper)}>
                <CollapseSingle
                    title={label}
                    className={styles.spoiler}
                    renderHeader={renderHeader}
                >
                    <Form.Item name={name} noStyle>
                        <Radio.Group>
                            <Space direction="vertical">
                                {values.map((el) => (
                                    <Radio
                                        key={String(el.value)}
                                        value={el.value}
                                    >
                                        {el.label}
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </CollapseSingle>
            </div>
        )
    }
)
