import React, { useCallback } from 'react'
import { Button, ButtonsToolbar, PopupWithForm } from 'components/shared'
import { ConfirmDeleteForm } from 'components/forms'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { KNOWLEDGE_BASE_REQUIRED_PERMISSIONS } from 'consts'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UserPermission } from 'core/api'

import { KnowledgeBaseSectionForm } from '../KnowledgeBaseSectionForm'
import { SectionActionsProps } from './SectionActions.types'

/** Кнопки действий для разделов базы знаний */
export const SectionActions: React.FC<SectionActionsProps> = React.memo(
    ({
        section: { id, name, articles },
        onSectionDelete,
        onDictionaryFetch,
    }) => {
        const handleClick = useCallback(
            (
                e:
                    | React.MouseEvent<HTMLDivElement, MouseEvent>
                    | React.KeyboardEvent<HTMLDivElement>
            ) => {
                e.stopPropagation()
            },
            []
        )

        return (
            <ButtonsToolbar
                bottomIndent="0"
                onClick={handleClick}
                onKeyPress={handleClick}
            >
                <HasPermissions
                    requiredPermissions={[
                        ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                        UserPermission.KnowledgeBaseArticleDelete,
                    ]}
                >
                    <PopupWithForm
                        modalOptions={{
                            title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                            okText: LOCAL.YES,
                        }}
                        formOptions={{
                            initialValues: { id },
                        }}
                        component={ConfirmDeleteForm}
                        formId={FORM_IDS.CONFIRM_DELETE_FORM}
                        buttonOption={{
                            ghost: true,
                        }}
                        buttonText={LOCAL.ACTIONS.DELETE_SECTION}
                        deleteFormAction={onSectionDelete}
                        messageText={`${
                            articles?.total
                                ? LOCAL.MESSAGES.SECTION_HAS_ACTIVE_ARTICLES
                                : ''
                        } ${LOCAL.MESSAGES.DELETE_CONFIRM}`}
                    />
                </HasPermissions>

                <HasPermissions
                    requiredPermissions={[
                        ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                        UserPermission.KnowledgeBaseArticleSave,
                    ]}
                >
                    <PopupWithForm
                        modalOptions={{
                            title: LOCAL.MESSAGES.EDITING_SECTION,
                            okText: LOCAL.ACTIONS.RENAME,
                        }}
                        formOptions={{
                            initialValues: { id, name },
                        }}
                        component={KnowledgeBaseSectionForm}
                        formId={`${FORM_IDS.KNOWLEDGE_ADD_SECTION_FORM}${id}`}
                        buttonOption={{
                            ghost: true,
                        }}
                        buttonText={LOCAL.ACTIONS.EDIT_SECTION}
                        onRequestFinish={onDictionaryFetch}
                    />
                </HasPermissions>

                <HasPermissions
                    requiredPermissions={[
                        ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                        UserPermission.KnowledgeBaseArticleSave,
                    ]}
                >
                    <Button
                        href={`${ROUTE_NAMES.KNOWLEDGE_BASE_CREATE}?section=${id}`}
                    >
                        {LOCAL.ACTIONS.ADD_ARTICLE}
                    </Button>
                </HasPermissions>
            </ButtonsToolbar>
        )
    }
)
