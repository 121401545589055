import { QuestionPublicContract } from 'core/api'

import { AnswerFormValuesContract } from '../../QuestionnairePassing.types'
import { IsAnswerFinishedProps } from './ButtonsControl.types'

export const isAnswerFinished = ({
    question,
    confirmedAnswers,
    currentAnswers,
}: IsAnswerFinishedProps) =>
    currentAnswers[question.id] &&
    !currentAnswers[question.id].unsaved &&
    confirmedAnswers.some((el) => el.questionId === question.id)

/** Определить есть ли ответы с ограничением времени*/
export const isThereAnswersWithTimeLimit = (
    answers: AnswerFormValuesContract[],
    questions: QuestionPublicContract[]
) =>
    !!answers.filter((el) =>
        questions.find(
            (question) =>
                question.limitedAnswerTime && question.id === el.questionId
        )
    ).length
