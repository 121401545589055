import React from 'react'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker, SwitchControl } from 'components/controls'

import { TrackHeaderIconsList } from './TrackHeaderIconsList'
import { UploadLogoProps } from './UploadLogo.types'

export const UploadLogo: React.FC<UploadLogoProps> = React.memo(
    ({ form, formMode, disabled }) => (
        <>
            <ShouldUpdateChecker fieldPath={['isTrackWithVisualisation']}>
                {({ getFieldValue }) => (
                    <Form.Item name="isTrackWithLogo">
                        <SwitchControl
                            addonAfter={LOCAL.LABELS.TRACK_LOGO}
                            formMode={formMode}
                            disabled={
                                !getFieldValue('isTrackWithVisualisation')
                            }
                        />
                    </Form.Item>
                )}
            </ShouldUpdateChecker>

            <ShouldUpdateChecker fieldPath={['isTrackWithLogo']}>
                {({ getFieldValue }) =>
                    getFieldValue('isTrackWithLogo') && (
                        <TrackHeaderIconsList
                            form={form}
                            disabled={disabled}
                            formMode={formMode}
                        />
                    )
                }
            </ShouldUpdateChecker>
        </>
    )
)
