import React from 'react'
import { useLocation } from 'react-router-dom'
/**
 * Хук для поиска query params
 *
 */
export function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}
