import React from 'react'
import cn from 'classnames'
import isNil from 'lodash/isNil'

import styles from './Title.module.scss'
import { TitleProps } from './Title.types'

export const Title: React.FC<TitleProps> = React.memo(
    ({ text, type: Element, className, bottomIndent, topIndent, flex }) => {
        const props = {
            className: cn(
                styles.wrapper,
                className,
                flex && styles.wrapperFlex,
                !isNil(bottomIndent) && `mb-${bottomIndent}`,
                !isNil(topIndent) && `mt-${topIndent}`
            ),
        }

        return <Element {...props}>{text}</Element>
    }
)
