import React from 'react'
import { Form, Input } from 'antd'

import { HiddenFieldProps } from './HiddenField.types'

/**
 * Скрытое поле, используется в тех случаях, когда поле показывать не нужно, но необходимо, чтобы на него маппились данные
 */
export const HiddenField: React.FC<HiddenFieldProps> = ({
    fieldName,
    initialValue,
}) => (
    <Form.Item noStyle name={fieldName} initialValue={initialValue} hidden>
        <Input disabled />
    </Form.Item>
)
