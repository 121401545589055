import React from 'react'
import {
    ControlGroup,
    FormItemAdapter,
    RadioButtonControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { DEPENDENCIES_MESSAGES } from 'core/local/messages.local.config'
import { DEPRECATED_ASSESSMENT_TYPES } from 'consts'
import { LOCAL } from 'core/local'
import {
    excludeSelectOptions,
    getOnBeforeChangeHandler,
    isAssessmentTypeExpert,
    isDocumentStatusReadyForWork,
    normalizeDictionaryToControl,
} from 'utils'
import { useDictionariesHelper } from 'hooks'

import { AssessmentQuestionnaireOptionsProps } from './AssessmentQuestionnaireOptions.types'
import {
    ResultCalculatingMethodIdDictionary,
    ResultEstimateScaleTypesDictionary,
} from '../QuestionnairesConstructorForm/QuestionnairesConstructorForm.consts'
import {
    isAssessmentEstimateScaleTypeIdDisabled,
    isAssessmentResultCalculatingMethodIdDisabled,
} from '../QuestionnairesConstructorForm/QuestionnairesConstructorForm.utils'

export const AssessmentQuestionnaireOptions: React.FC<AssessmentQuestionnaireOptionsProps> = React.memo(
    ({ resultCalculatingMethods, respondentTypes, status }) => {
        const {
            resultEstimateScaleTypesDictionary,
            assessmentTypeDictionary,
        } = useDictionariesHelper([
            'resultEstimateScaleTypesDictionary',
            'assessmentTypeDictionary',
        ])

        const resultEstimateScaleTypesFiltered = excludeSelectOptions(
            resultEstimateScaleTypesDictionary,
            [
                ResultEstimateScaleTypesDictionary.ConsentDisagreeScale13,
                ResultEstimateScaleTypesDictionary.ConsentDisagreeScale15,
            ]
        )
        const resultCalculatingMethodsFiltered = excludeSelectOptions(
            resultCalculatingMethods,
            [
                ResultCalculatingMethodIdDictionary.ImpactAssessment,
                ResultCalculatingMethodIdDictionary.Other,
            ]
        )

        return (
            <ShouldUpdateChecker
                fieldPath={[
                    ['formMode'],
                    ['assessmentType'],
                    ['respondentTypeId'],
                    ['assessmentResultCalculatingMethodId'],
                ]}
            >
                {(form) => {
                    const formValues = form.getFieldsValue()

                    const handleBeforeChange = getOnBeforeChangeHandler({
                        dependencies: [['questions']],
                        confirmTitle: DEPENDENCIES_MESSAGES.QUESTIONS,
                        form,
                    })

                    return (
                        <ControlGroup
                            title={LOCAL.LABELS.QUESTIONNAIRE_TEMPLATE_SETTINGS}
                            noPadding
                        >
                            <FormItemAdapter
                                fieldName="assessmentType"
                                label={LOCAL.LABELS.ASSESSMENT_TYPE}
                            >
                                <RadioButtonControl
                                    values={excludeSelectOptions(
                                        normalizeDictionaryToControl(
                                            assessmentTypeDictionary
                                        ),
                                        DEPRECATED_ASSESSMENT_TYPES
                                    )}
                                    buttonStyle="solid"
                                    onBeforeChange={handleBeforeChange}
                                    formMode={formValues.formMode}
                                    disabled={isDocumentStatusReadyForWork(
                                        status
                                    )}
                                />
                            </FormItemAdapter>

                            <FormItemAdapter
                                fieldName="respondentTypeId"
                                label={LOCAL.LABELS.RESPONDENT_TYPE}
                            >
                                <SelectControl
                                    values={respondentTypes}
                                    disabled={
                                        !isAssessmentTypeExpert(
                                            formValues?.assessmentType
                                        )
                                    }
                                    onBeforeChange={handleBeforeChange}
                                    formMode={formValues.formMode}
                                />
                            </FormItemAdapter>

                            <FormItemAdapter
                                fieldName="assessmentResultCalculatingMethodId"
                                label={LOCAL.LABELS.CALCULATION_RESULT}
                            >
                                <SelectControl
                                    values={resultCalculatingMethodsFiltered}
                                    disabled={isAssessmentResultCalculatingMethodIdDisabled(
                                        formValues
                                    )}
                                    formMode={formValues.formMode}
                                />
                            </FormItemAdapter>

                            <FormItemAdapter
                                fieldName="assessmentEstimateScaleTypeId"
                                label={LOCAL.LABELS.CRITERIA_SCALE_TYPE}
                            >
                                <SelectControl
                                    values={resultEstimateScaleTypesFiltered}
                                    disabled={isAssessmentEstimateScaleTypeIdDisabled(
                                        formValues
                                    )}
                                    formMode={formValues.formMode}
                                />
                            </FormItemAdapter>
                        </ControlGroup>
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
