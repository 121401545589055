import React, { useEffect, useMemo, useState } from 'react'
import {
    CircleReportsService,
    ConsolidatedCircleReportContract,
    UserProfilesService,
} from 'core/api'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { PageContent, ReportsTabs } from 'components/shared'
import { ROW_GUTTER } from 'consts'
import { ResultControl } from 'components/controls'
import { UrlParamsCommonProps } from 'App.types'
import { UserProfileHeader } from 'components/pages/UserProfile/components'
import { UserProfileStateProps } from 'components/pages/UserProfile/UserProfile.types'
import { getFullFio } from 'utils'
import { useParams } from 'react-router-dom'
import { useQuery } from 'hooks'
import { withLoader } from 'HOCs'

import styles from './CircleAssessmentReports.module.scss'
import { CircleReportProgress } from './components'

/**
 * Сводный отчет по оценке 360/270/180
 */
export const CircleAssessmentReports: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const { appointmentId } = useParams<
            UrlParamsCommonProps<'appointmentId'>
        >()

        const query = useQuery()
        const userId = query.get('userId')

        /**
         * Стейт для хранения данных профиля пользователя id: Number(userData?.id || reportsUrlParams.
         */
        const [userProfile, setUserProfile] = useState<UserProfileStateProps>()

        /**
         * Стейт для хранения данных отчетов
         */
        const [reportsData, setReportsData] = useState<
            ConsolidatedCircleReportContract
        >()

        /**
         * ФИО
         */
        const fullFio = useMemo(() => getFullFio(userProfile?.user), [
            userProfile,
        ])

        useEffect(() => {
            const fetch = async () => {
                try {
                    updateLoader(true)

                    setUserProfile(
                        userId
                            ? await UserProfilesService.getUserById({
                                  id: Number(userId),
                              })
                            : await UserProfilesService.getCurrentUserProfile()
                    )

                    const reportDataSource = await CircleReportsService.getConsolidatedReportByAppointmentId(
                        {
                            appointmentId: Number(appointmentId),
                        }
                    )

                    setReportsData(reportDataSource)
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }

            fetch()
        }, [appointmentId, updateLoader, userId])

        return (
            <PageContent>
                <UserProfileHeader
                    fullFio={fullFio}
                    userProfile={userProfile}
                    hideBackLink
                />

                <ReportsTabs userId={userId} appointmentId={appointmentId} />

                <div className={styles.body}>
                    {userId ? (
                        <Row gutter={ROW_GUTTER}>
                            <Col xs={8}>
                                <CircleReportProgress
                                    reportsData={reportsData}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <ResultControl
                            title={LOCAL.LABELS.REPORT_NOT_AVAILABLE}
                        />
                    )}
                </div>
            </PageContent>
        )
    })
)
