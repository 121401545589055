import './ReportsTabs.scss'

import React from 'react'
import { LOCAL } from 'core/local'
import { NavLink } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'

import { ReportsTabsProps } from './ReportsTabs.types'

/**
 * Навигация по вкладкам для отчетов
 */
export const ReportsTabs: React.FC<ReportsTabsProps> = React.memo(
    ({ userId, appointmentId }) => {
        const uriVariables = `${appointmentId}${
            userId ? `?userId=${userId}` : ''
        }`

        return (
            <div className="reports-tabs">
                <NavLink
                    className="reports-tabs__item"
                    activeClassName="reports-tabs__item_active"
                    to={`${ROUTE_NAMES.PROFILE_REPORTS}/${uriVariables}`}
                >
                    {LOCAL.LABELS.REPORT}
                </NavLink>

                {!!userId && (
                    <NavLink
                        className="reports-tabs__item"
                        activeClassName="reports-tabs__item_active"
                        to={`${ROUTE_NAMES.CONSOLIDATED_REPORTS}/${uriVariables}`}
                    >
                        {LOCAL.LABELS.CONSOLIDATED_REPORT}
                    </NavLink>
                )}
            </div>
        )
    }
)
