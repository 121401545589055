import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    TextAreaControl,
} from 'components/controls'
import {
    DictionaryContract,
    NotificationMailingTaskContract,
    NotificationTagsService,
} from 'core/api'
import { FormProps } from 'antd/lib/form'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts/layout.consts'
import { WithFormModeProps } from 'App.types'
import { normalizeDataForSelectAndRadio } from 'utils'
import { useHttp } from 'hooks'

/** Общие сведения задания на рассылку */
export const DistributionMailingGeneralnfo: React.FC<
    FormProps<NotificationMailingTaskContract> & WithFormModeProps
> = React.memo(({ formMode }) => {
    const [getTags, tags] = useHttp<DictionaryContract[]>(
        NotificationTagsService.getForSelect
    )

    useEffect(() => {
        getTags()
    }, [getTags])

    return (
        <ControlGroup title={LOCAL.LABELS.COMMON_INFORMATION} type="bordered">
            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.NAME}
                        required
                    >
                        <InputControl formMode={formMode} />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="tagIds"
                        label={LOCAL.CATALOGS.TAGS}
                    >
                        <SelectControl
                            mode="multiple"
                            showSearch
                            creatingOptions={{
                                create: NotificationTagsService.create,
                                refetch: getTags,
                                suffix: LOCAL.NEW_TAG,
                            }}
                            values={
                                tags?.map(normalizeDataForSelectAndRadio) || []
                            }
                            formMode={formMode}
                        />
                    </FormItemAdapter>
                </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="description"
                        label={LOCAL.LABELS.DESCRIPTION}
                        required
                    >
                        <TextAreaControl formMode={formMode} />
                    </FormItemAdapter>
                </Col>
            </Row>
        </ControlGroup>
    )
})
