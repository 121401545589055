import React from 'react'
import { getShoftFioFromString } from 'utils'

import avatarPlaceholder from './avatar-placeholder.svg'
import styles from './ProductCardTeacher.module.scss'
import { ProductCardTeacherProps } from './ProductCardTeacher.types'

/** Карточка продукта - преподователь */
export const ProductCardTeacher: React.FC<ProductCardTeacherProps> = React.memo(
    ({ url, position, fullName }) => (
        <div className={styles.wrapper}>
            <img
                src={url || avatarPlaceholder}
                alt="avatar"
                className={styles.avatar}
            />

            <div>
                <p className={styles.position}>{position}</p>

                <p className={styles.name}>{getShoftFioFromString(fullName)}</p>
            </div>
        </div>
    )
)
