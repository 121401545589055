import { Chart, ChartDataset } from 'chart.js'
import { DatasetItemConfigProps, getRadarChartDatasetItem } from 'utils'
import { ManagerReportSpiderWebContract } from 'core/api'

import { AcceptedKeysProps } from './CompetenceAssessmentResults.types'
import { DATASET_ITEM_CONFIGS_COLLECTION } from './CompetenceAssessmentResults.config'

export const isKeyPositionProfileLevel = (key?: AcceptedKeysProps) =>
    key === 'positionProfileLevel'

/** Набор данных для инициализации компонента диаграммы */
export const getRadarChartData = (values?: ManagerReportSpiderWebContract[]) =>
    values && {
        labels: values.map((el) => el.competenceName),
        datasets: DATASET_ITEM_CONFIGS_COLLECTION.reduce(
            (acc: Partial<ChartDataset>[], el) => {
                if (
                    isKeyPositionProfileLevel(el.dataKey) &&
                    values.every((el) => !el.positionProfileLevel)
                )
                    return acc

                return [...acc, getRadarChartDatasetItem(el, values)]
            },
            []
        ) as ChartDataset[],
    }

/**
 * Обработчик отображения данных диаграммы
 * @param key ключ набора данных, которые надо скрыть\показать
 * @param chart инстанс диаграммы
 */
export const handleChangeVisibleLabel = (
    key: AcceptedKeysProps,
    chart?: Chart,
    updateLabelChecked?: React.Dispatch<
        React.SetStateAction<{
            [key: string]: boolean
        }>
    >
) => (checked?: boolean) => {
    const datasets = chart?.config.data?.datasets as DatasetItemConfigProps<
        AcceptedKeysProps
    >[]

    datasets?.forEach((data) => {
        if (data.dataKey === key) {
            data.hidden = !checked

            updateLabelChecked?.((prev) => ({ ...prev, [key]: !!checked }))

            chart?.update()
        }
    })
}
