import React from 'react'
import cn from 'classnames'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { useElementVisible } from 'hooks'

import styles from '../TrackInfoMenu/TrackInfoMenu.module.scss'
import { MenuSpoilerProps } from './MenuSpoiler.types'

export const MenuItemWithSpoiler: React.FC<MenuSpoilerProps> = React.memo(
    ({ title, textStyle, itemCount = 0, children }) => {
        const { elementVisible, toggleElementVisible } = useElementVisible()

        return (
            <li className={styles.listItem}>
                <div
                    className={cn(styles.listItemTitle, itemCount && 'pointer')}
                    onClick={itemCount ? toggleElementVisible : undefined}
                >
                    <span style={textStyle}>{title}</span>

                    <span className={styles.count}>{itemCount}</span>

                    {!!itemCount && (
                        <IconsAdapter
                            iconType="CustomIconChevron"
                            className={cn(
                                styles.more,
                                elementVisible && 'rotate90'
                            )}
                        />
                    )}
                </div>

                {elementVisible && children}
            </li>
        )
    }
)
