import React from 'react'
import {
    CheckboxControl,
    FormItemAdapter,
    GetByIdsMethodParamProps,
    SearchSelectControl,
    ShouldUpdateChecker,
    TextDocumentsFilters,
} from 'components/controls'
import {
    DictionaryContract,
    MissionSearchForModalContract,
    MissionsService,
    TextDocumentForModalContract,
    TextDocumentSearchContract,
    TextDocumentsService,
} from 'core/api'
import { LOCAL } from 'core/local'
import { getTableColumnForName } from 'components/controls/SearchSelectControl/components/TableSearchForm/TableSearchForm.utils'

import { HomeworkFieldsProps } from './HomeworkFields.types'

export const HomeworkFields: React.FC<HomeworkFieldsProps> = React.memo(
    ({ field, formMode, resetFields, editorUsedFieldPath, disabled }) => (
        <>
            <FormItemAdapter fieldName={[field.name, 'isEditorUsed']}>
                <CheckboxControl
                    formMode={formMode}
                    onChange={() =>
                        resetFields([
                            ['lessons', field.name, 'textDocuments'],
                            ['lessons', field.name, 'missions'],
                        ])
                    }
                    disabled={disabled}
                >
                    {LOCAL.LABELS.USE_EDITOR}
                </CheckboxControl>
            </FormItemAdapter>

            <ShouldUpdateChecker fieldPath={editorUsedFieldPath}>
                {({ getFieldValue }) => {
                    const IsEditorUsed = getFieldValue(editorUsedFieldPath)

                    return IsEditorUsed ? (
                        <FormItemAdapter
                            fieldName={[field.name, 'textDocuments']}
                            label={LOCAL.LABELS.TASK_ADD}
                            required
                        >
                            <SearchSelectControl<
                                TextDocumentSearchContract,
                                TextDocumentForModalContract
                            >
                                modalTitle={LOCAL.LABELS.SELECTION_TASK}
                                getByIdsMethod={TextDocumentsService.getByIds}
                                formMode={formMode}
                                tableSearchOptions={{
                                    searchMethod:
                                        TextDocumentsService.getForModal,
                                    filterComponent: TextDocumentsFilters,
                                    tableColumns: getTableColumnForName<
                                        TextDocumentForModalContract
                                    >(LOCAL.LABELS.DOCUMENT_NAME),
                                }}
                                disabled={disabled}
                            />
                        </FormItemAdapter>
                    ) : (
                        <FormItemAdapter
                            fieldName={[field.name, 'missions']}
                            label={LOCAL.LABELS.TASK_ADD}
                            required
                        >
                            <SearchSelectControl<
                                MissionSearchForModalContract,
                                DictionaryContract
                            >
                                modalTitle={LOCAL.LABELS.SELECTION_TASK}
                                getByIdsMethod={({
                                    body,
                                }: GetByIdsMethodParamProps) => {
                                    if (!body) return

                                    return MissionsService.getByIdForModal({
                                        id: +body[0],
                                    })
                                }}
                                formMode={formMode}
                                tableSearchOptions={{
                                    tableColumns: getTableColumnForName<
                                        DictionaryContract
                                    >(LOCAL.LABELS.DOCUMENT_NAME),
                                    filterComponent: TextDocumentsFilters,
                                    searchMethod:
                                        MissionsService.searchForModal,
                                }}
                                disabled={disabled}
                            />
                        </FormItemAdapter>
                    )
                }}
            </ShouldUpdateChecker>
        </>
    )
)
