import React, { useCallback, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    IconsAdapter,
    TableActions,
    VideoContentModal,
} from 'components/shared'
import { CourseSearchResultContract, UserPermission } from 'core/api'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TRAINING_MATERIALS_REQUIRED_PERMISSIONS } from 'consts'
import { TableActionsCommonProps } from 'App.types'
import { isTrainingMaterialFormatVideo } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useElementVisible } from 'hooks'
import { useHistory } from 'react-router-dom'

/** Компонент для создания колонки действий внутри таблицы реестра обучающих материалов */
export const TrainingMaterialsTableActions: React.FC<TableActionsCommonProps<
    CourseSearchResultContract
>> = React.memo(({ tableRow }) => {
    const history = useHistory()

    const {
        elementVisible,
        handleShowElement,
        handleHideElement,
    } = useElementVisible()

    const videoPopupProps = useMemo(
        () => ({
            visible: elementVisible,
            onCancel: handleHideElement,
        }),
        [elementVisible, handleHideElement]
    )

    const handlePlay = useCallback(() => {
        if (isTrainingMaterialFormatVideo(tableRow.format)) {
            handleShowElement()

            return
        }

        history.push(`${ROUTE_NAMES.TRAINING_MATERIALS}/${tableRow.id}`)
    }, [tableRow, history, handleShowElement])

    return (
        <ButtonsToolbar bottomIndent="0">
            {tableRow.active && (
                <>
                    <HasPermissions
                        requiredPermissions={[
                            ...TRAINING_MATERIALS_REQUIRED_PERMISSIONS,
                            UserPermission.CoursePlay,
                        ]}
                        accessDeniedRender={renderAccessDeniedButton({
                            type: 'link',
                            icon: (
                                <IconsAdapter iconType="PlaySquareOutlined" />
                            ),
                        })}
                    >
                        <Button
                            type="link"
                            className="ant-btn-icon-only"
                            title={LOCAL.ACTIONS.PREVIEW}
                            onClick={handlePlay}
                            icon={
                                <IconsAdapter iconType="PlaySquareOutlined" />
                            }
                        />

                        <VideoContentModal
                            popupProps={videoPopupProps}
                            fileUrl={tableRow.launchUrl}
                            autoPlay
                        />
                    </HasPermissions>

                    <HasPermissions
                        requiredPermissions={
                            TRAINING_MATERIALS_REQUIRED_PERMISSIONS
                        }
                        accessDeniedRender={renderAccessDeniedButton({
                            type: 'link',
                            icon: (
                                <IconsAdapter iconType="InfoCircleOutlined" />
                            ),
                        })}
                    >
                        <TableActions.Info
                            href={`${ROUTE_NAMES.TRAINING_MATERIALS_INFO}/${tableRow.id}`}
                            title={LOCAL.CATALOGS.TRAINING_MATERIALS_INFO}
                        />
                    </HasPermissions>
                </>
            )}
        </ButtonsToolbar>
    )
})
