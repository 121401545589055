import { AppointmentStatus, AssessmentType, QuestionnaireType } from 'core/api'

export const USER_ASSIGNMENTS_INITIAL_VALUES = {
    types: [QuestionnaireType.Assessment, QuestionnaireType.Test],
    statuses: [AppointmentStatus.Expectation, AppointmentStatus.InProgress],
    assessmentTypes: [
        AssessmentType.Estimate_360,
        AssessmentType.EstimateByManager,
    ],
}

export const PAGINATION_SETTINGS = {
    pageSize: 9,
    pageNumber: 1,
}
