import React, { useCallback, useMemo } from 'react'
import { ControlGroup } from 'components/controls'
import { HasPermissions } from 'core/permissions'
import { JobProfileFromStateProps } from 'components/pages/JobProfiles'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import {
    PositionProfilesService,
    UserPermission,
    UserStructuralUnitPositionContract,
} from 'core/api'
import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'
import { withLoader } from 'HOCs'

import styles from './AllPositions.module.scss'
import { AllPositionsProps, GetByUnitPositionProps } from './AllPositions.types'
import { getWorkInfo } from './AllPositions.utils'

/**
 * Блок - занимаемые должности
 */
export const AllPositions: React.FC<AllPositionsProps> = withLoader(
    React.memo(({ user, onChangePositionProfile, updateLoader }) => {
        /**
         * Поиск профиля должности
         * @param body - параметры поиска
         */
        const positionProfileFetch = useCallback(
            async (
                userStructuralUnitPosition: UserStructuralUnitPositionContract,
                params: GetByUnitPositionProps
            ) => {
                try {
                    updateLoader(true)

                    const result = await PositionProfilesService.getByUnitPosition(
                        params
                    )

                    onChangePositionProfile?.(
                        userStructuralUnitPosition,
                        result
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [onChangePositionProfile, updateLoader]
        )

        /**
         * Обработчик изменения профиля должности
         * @param body - параметры для поиска нового профиля должности
         */
        const handleChangeProfile = useCallback(
            (
                userStructuralUnitPosition: UserStructuralUnitPositionContract,
                params: GetByUnitPositionProps
            ) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault()

                positionProfileFetch(userStructuralUnitPosition, params)
            },
            [positionProfileFetch]
        )

        /**
         * Список должностей
         */
        const allPositions = useMemo(
            () =>
                user?.structuralUnitsPositions.map((el) => (
                    <div key={el.structuralUnit.id} className={styles.item}>
                        <div className={styles.position}>
                            <a
                                href="#s"
                                onClick={handleChangeProfile(el, {
                                    unitId: el.structuralUnit.id,
                                    positionId: el.position.id,
                                })}
                                className={styles.viewPositionLink}
                            >
                                {el.position.name}
                            </a>

                            {el.positionProfileId && (
                                <span className={styles.viewProfileLink}>
                                    <HasPermissions
                                        requiredPermissions={[
                                            UserPermission.PositionProfilesRead,
                                        ]}
                                    >
                                        <Link
                                            to={{
                                                pathname: `${ROUTE_NAMES.POSITION_PROFILES}/${ROUTES_POSTFIXES.VIEW}/${el.positionProfileId}`,
                                                state: {
                                                    from:
                                                        JobProfileFromStateProps.UserProfile,
                                                },
                                            }}
                                        >
                                            {LOCAL.LINKS.POSITION_PROFILE_VIEW}
                                        </Link>
                                    </HasPermissions>
                                </span>
                            )}
                        </div>

                        <div className={styles.organization}>
                            {el.structuralUnit.name}
                        </div>

                        <div className={styles.workInfo}>{getWorkInfo(el)}</div>
                    </div>
                )),
            [handleChangeProfile, user]
        )

        return (
            <ControlGroup title={LOCAL.LABELS.ALL_POSITIONS} noPadding>
                {allPositions?.length
                    ? allPositions
                    : LOCAL.LABELS.POSITIONS_HISTORY_MISSING}
            </ControlGroup>
        )
    })
)
