import { LabeledValue } from 'antd/lib/select'
import { Store } from 'antd/lib/form/interface'

/**
 * Нормализуют данные справочника для использования в контроле
 * @param dictionary - справочник
 * @param valueToNumber приведение value к типу number
 */
export const normalizeDictionaryToControl = (
    dictionary: Store,
    valueToNumber?: boolean
): LabeledValue[] =>
    Object.keys(dictionary).reduce(
        (acc: LabeledValue[], key) =>
            key
                ? [
                      ...acc,
                      {
                          key,
                          value: valueToNumber ? Number(key) : key,
                          label: dictionary[key],
                      },
                  ]
                : acc,
        []
    )
