import React, { useCallback, useEffect, useState } from 'react'
import {
    ActivitiesService,
    ActivityContract,
    ActivityDecorationType,
} from 'core/api'
import { FORM_IDS } from 'core/configs'
import { Form, Modal, Tabs } from 'antd'
import { HiddenField } from 'components/controls'
import { LOCAL } from 'core/local'
import { MODAL_Z_INDEX } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    createConfirmPopup,
    getFormPopupTitle,
    isDecorationTypeVisualization,
    isDocumentStatusInWork,
    isDocumentStatusReadyForWork,
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'

import styles from './EventForm.module.scss'
import {
    EVENT_FORM_TABS_PANE_OPTIONS,
    INITIAL_FORM_VALUES,
} from './EventForm.consts'
import { EventFormDescription } from '../EventFormDescription'
import { EventFormOptions } from '../EventFormOptions'
import { EventFormProps, EventFormTabType } from './EventForm.types'
import { EventFormStructure } from '../EventFormStructure'
import { EventFormVisualization } from '../EventFormVisualization'
import { mapFormDataToRequest, mapResponseToFormData } from './EventForm.utils'

/** Форма создания мероприятия */
export const EventForm: React.FC<EventFormProps> = React.memo(
    ({
        onBlockUserRouting,
        initialValuesForEdit,
        isSaveDocument,
        formMode,
        updateLoader,
    }) => {
        const history = useHistory()
        const [form] = Form.useForm()
        const [eventDecorationType, setEventDecorationType] = useState<
            ActivityDecorationType
        >(ActivityDecorationType.Standard)
        const [tabKey, setTabKey] = useState<EventFormTabType>(
            EventFormTabType.MainOptions
        )

        const disabledByStatus =
            isFormModeEdit(formMode) &&
            isDocumentStatusInWork(initialValuesForEdit?.status)

        /**
         * Обработчик переключения табов
         * @param tabKey ключ переключаемого таба
         */
        const handleChangeTabs = useCallback(
            (tabKey) => {
                if (tabKey === EventFormTabType.Structure) {
                    const { type, kind } = form.getFieldsValue(['type', 'kind'])

                    if (!type || !kind) {
                        Modal.info({
                            content:
                                LOCAL.MESSAGES.EVENT_FORM_POPUP_INFO_MESSAGE,
                            getContainer: getDropdownPopupContainerDefault,
                            zIndex: MODAL_Z_INDEX.CONFIRM,
                        })

                        return
                    }
                }

                if (tabKey === EventFormTabType.EventVisualization) {
                    if (!form.getFieldValue('lessons')?.length) {
                        Modal.info({
                            content:
                                LOCAL.MESSAGES.ADD_MIN_LESSON_COUNT_MESSAGE,
                            getContainer: getDropdownPopupContainerDefault,
                            zIndex: MODAL_Z_INDEX.CONFIRM,
                        })

                        return
                    }
                }

                setTabKey(tabKey)
            },
            [form]
        )

        /**
         * Обработчик выбора типа оформления
         * @param decorationType тип оформления
         */
        const handleEventDecoration = useCallback(
            (decorationType) => setEventDecorationType(decorationType),
            [setEventDecorationType]
        )

        /**
         * Отправка формы на сервер
         * @param values значения формы
         */
        const handleFinish = useCallback(
            async (values) => {
                try {
                    updateLoader?.(true)

                    const { status, ...body } = mapFormDataToRequest({
                        ...values,
                        id: !isFormModeCopy(formMode) ? values.id : undefined,
                    })

                    let dataSource: ActivityContract
                    if (isSaveDocument) {
                        dataSource = await ActivitiesService.save({ body })
                    } else {
                        dataSource = await (isDocumentStatusReadyForWork(
                            status
                        ) && !isFormModeCopy(formMode)
                            ? ActivitiesService.createNewVersion
                            : ActivitiesService.publish)({ body })
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(ROUTE_NAMES.EVENTS)
                        ),
                        content: isSaveDocument
                            ? LOCAL.MESSAGES.EVENT_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES.EVENT_PUBLISH_SUCCESS_MESSAGE,
                        onOk: () => {
                            history.push(ROUTE_NAMES.EVENTS)
                        },
                        onCancel: () => {
                            if (!initialValuesForEdit?.id) {
                                form.resetFields()
                                form.setFieldsValue(INITIAL_FORM_VALUES)
                                setEventDecorationType(
                                    ActivityDecorationType.Standard
                                )
                            } else {
                                history.push(
                                    `${ROUTE_NAMES.EVENTS_EDIT}/${dataSource.id}`
                                )
                            }
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                updateLoader,
                formMode,
                isSaveDocument,
                onBlockUserRouting,
                history,
                initialValuesForEdit,
                form,
            ]
        )

        /**
         * Обработчик изменения значений полей формы
         * @param changedValue значение поля на котором сработало событие
         */
        const handleValuesChange = useCallback(
            (changedValue) => {
                const changedValueKey = Object.keys(changedValue)[0]

                if (
                    changedValueKey === 'isRating' &&
                    !changedValue[changedValueKey]
                ) {
                    form.setFieldsValue({
                        pointsForOpening: undefined,
                        pointsForPassing: undefined,
                    })
                }

                if (
                    changedValueKey === 'isCertification' &&
                    !changedValue[changedValueKey]
                ) {
                    form.setFieldsValue({ certificationType: undefined })
                }

                if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }
            },
            [form, formMode, onBlockUserRouting]
        )

        useEffect(() => {
            if (initialValuesForEdit?.id) {
                const initialValues = mapResponseToFormData(
                    initialValuesForEdit,
                    isFormModeCopy(formMode)
                )
                form.setFieldsValue(initialValues)
                setEventDecorationType(initialValues.decorationType)
            }
        }, [form, initialValuesForEdit, formMode])

        return (
            <Form
                id={FORM_IDS.EVENT_FORM}
                form={form}
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                initialValues={INITIAL_FORM_VALUES}
                autoComplete="off"
            >
                <HiddenField fieldName="id" />

                <HiddenField fieldName="status" />

                <Tabs
                    size="large"
                    type="card"
                    activeKey={tabKey}
                    onChange={handleChangeTabs}
                    className={styles.tabs}
                >
                    <Tabs.TabPane
                        {...EVENT_FORM_TABS_PANE_OPTIONS.MAIN_OPTIONS}
                        forceRender
                    >
                        <EventFormDescription
                            onEventDecoration={handleEventDecoration}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />

                        <EventFormOptions
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        {...EVENT_FORM_TABS_PANE_OPTIONS.STRUCTURE}
                        forceRender
                    >
                        <EventFormStructure
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </Tabs.TabPane>

                    {isDecorationTypeVisualization(eventDecorationType) &&
                        !isFormModeView(formMode) && (
                            <Tabs.TabPane
                                {...EVENT_FORM_TABS_PANE_OPTIONS.EVENT_VISUALIZATION}
                                forceRender
                            >
                                <EventFormVisualization
                                    form={form}
                                    disabled={disabledByStatus}
                                />
                            </Tabs.TabPane>
                        )}
                </Tabs>
            </Form>
        )
    }
)
