import isEqual from 'lodash/isEqual'
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    ActivityLessonPassingContentContract,
    ActivityLessonPassingService,
    ActivityPassingInfoContract,
    ActivityPassingService,
    AppointmentTrackStatus,
    HomeworkStatusType,
    HomeworksService,
    TrackPassingsService,
    TrackStageContentContract,
    TrackStageMaterialType,
} from 'core/api'
import { LOCAL } from 'core/local'
import { MODAL_Z_INDEX } from 'consts'
import { Modal } from 'antd'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    allBlocksAreRequired,
    checkAllRequiredBlocksPassed,
    createWarningNotification,
    isActivityCompletedSuccessfully,
    isActivityLessonCompletedSuccessfully,
    isActivityLessonInProgress,
    isActivityLessonWaiting,
    isAppointmentTrackPassedOrNotPassed,
    isAppointmentTrackStatusAvailableToPassing,
    isAppointmentTrackStatusDraft,
    isAppointmentTrackStatusInWork,
    isAppointmentTrackStatusPassed,
    isThemeBlockTypeHomeworkWithoutEditor,
} from 'utils'
import {
    eventEmitter,
    registerEvents,
    unRegisterEvents,
} from 'core/helpers/eventEmitter'
import { globalLoaderEventEmit } from 'components/shared/GlobalLoader/GlobalLoader.events'
import { useElementVisible, usePrevious } from 'hooks'
import { useHistory, useParams } from 'react-router-dom'

import { ContentOrActivityLayer } from './components/ContentOrActivityLayer'
import { FinishActivityModal } from './components/FinishActivityModal'
import { INFO_ASSISTANT_EVENT } from './components/InfoAssistant/InfoAssistant.events'
import { InfoAssistant } from './components/InfoAssistant'
import { LessonLayer } from './components/LessonLayer'
import { StartLessonProps, TrackPassingUrlProps } from './TrackPassing.types'
import { TRACK_PASSING_EVENTS } from './TrackPassing.events'
import { TrackInfoMenu, TrackLayerPassing, TrackProgress } from './components'
import { TrackPassingContext, storeNamePaths } from './TrackPassing.context'
import {
    getFirstNotPassedStage,
    getStageByNumber,
    isAppointmentPassedView,
    isTrackNeedToBeStarted,
    scrollToFirstAvailableStage,
} from './TrackPassing.utils'
import { isEnoughAttemptCount } from './components/TrackStagePassing/TrackStagePassing.utils'

export const TrackPassing: React.FC = () => {
    const history = useHistory()
    const urlParams = useParams<TrackPassingUrlProps>()
    const appointmentId = Number(urlParams.id)

    const {
        handleHideElement: hideFinishActivityModal,
        handleShowElement: showFinishActivityModal,
        elementVisible: finishActivityModalVisible,
    } = useElementVisible()

    const { store, updateStore, updateParticular } = useContext(
        TrackPassingContext
    )

    const currentStageNumber = useRef<number>()

    const { trackInfo, stageInfo, lessonInfo, lessonChildInfo } = store

    const [currentStageForViewIndex, setCurrentStageForViewIndex] = useState(0)

    const getProgress = useCallback(async () => {
        try {
            globalLoaderEventEmit('SHOW')

            const progress = await TrackPassingsService.getProgress({
                appointmentId,
            })

            updateStore({
                progress,
            })
        } catch (error) {
            console.error(error)
        } finally {
            globalLoaderEventEmit('HIDE')
        }
    }, [appointmentId, updateStore])

    const switchViewToNextStage = useCallback(() => {
        if (!trackInfo?.stages) return

        setCurrentStageForViewIndex((prev) => {
            if (prev < trackInfo.stages.length - 1) {
                return prev + 1
            }

            return 0
        })
    }, [trackInfo])

    const setCurrentStage = useCallback(
        async (stageNumber: number) => {
            try {
                await TrackPassingsService.setCurrentStage({
                    body: {
                        appointmentId,
                        stageNumber,
                    },
                })

                currentStageNumber.current = stageNumber
            } catch (error) {
                console.error(error)
            }
        },
        [appointmentId]
    )

    const cleanCurrentStage = useCallback(async () => {
        await setCurrentStage(0)
    }, [setCurrentStage])

    const getTrackInfo = useCallback(async () => {
        try {
            globalLoaderEventEmit('SHOW')

            if (!appointmentId) return

            if (isTrackNeedToBeStarted(urlParams.action)) {
                await TrackPassingsService.startPassing({
                    appointmentId,
                })

                history.replace(
                    `${ROUTE_NAMES.TRACKS_PASSING_v2}/${appointmentId}`
                )

                eventEmitter.emit(
                    INFO_ASSISTANT_EVENT.TRACK_GREETING,
                    appointmentId
                )

                return
            }

            const track = await TrackPassingsService.getInfo({
                appointmentId,
            })

            updateStore({
                trackInfo: track,
            })

            return track
        } catch (error) {
            console.error(error)
        } finally {
            globalLoaderEventEmit('HIDE')
        }
    }, [urlParams.action, appointmentId, history, updateStore])

    // TODO: на бэке будет один эндпоинт для получения инфы по этапу
    const getStageInfo = useCallback(
        async (stageNumber?: number) => {
            try {
                if (!stageNumber) return

                const stage = getStageByNumber(stageNumber, trackInfo?.stages)

                globalLoaderEventEmit('SHOW')

                const isActivity =
                    stage?.materialType === TrackStageMaterialType.Activity

                const payload = {
                    appointmentId,
                    stageNumber,
                }

                const stageInfo = {
                    activity: isActivity
                        ? await TrackPassingsService.getStagePassingInfo(
                              payload
                          )
                        : undefined,
                    // TODO: сейчас используется как инфо, т.к. на бэке метод для получения информации по этапу
                    // работает фактически только для мероприятий, возвращает не всю
                    // необходимую для контента информацию, необходимо допилить бэк
                    content: !isActivity
                        ? await TrackPassingsService.startStagePassingWithContent(
                              payload
                          )
                        : undefined,
                }

                updateStore({
                    stageInfo,
                })

                setCurrentStage(stageNumber)

                return stageInfo
            } catch (error) {
                console.error(error)
            } finally {
                globalLoaderEventEmit('HIDE')
            }
        },
        [appointmentId, setCurrentStage, trackInfo, updateStore]
    )

    // TODO: на бэке будет один эндпоинт для старта этапа
    const startStage = useCallback(
        async (stageNumber?: number, isRestart?: boolean) => {
            try {
                if (!stageNumber) return

                const stage = getStageByNumber(stageNumber, trackInfo?.stages)

                const isActivity =
                    stage?.materialType === TrackStageMaterialType.Activity

                const payload = {
                    appointmentId,
                    stageNumber,
                }

                const stageAvailable =
                    isAppointmentTrackStatusAvailableToPassing(stage?.status) ||
                    isAppointmentTrackStatusDraft(stage?.status)

                if (
                    isAppointmentPassedView(trackInfo?.status) ||
                    isAppointmentTrackStatusInWork(stage?.status) ||
                    (isAppointmentTrackPassedOrNotPassed(stage?.status) &&
                        isEnoughAttemptCount(stage))
                ) {
                    return getStageInfo(stageNumber)
                }

                if (isActivity) {
                    await TrackPassingsService.startStagePassingWithActivity({
                        ...payload,
                        body: {
                            isRestart: Boolean(isRestart),
                        },
                    })

                    if (stageAvailable) {
                        eventEmitter.emit(
                            INFO_ASSISTANT_EVENT.STAGE_ACTIVITY_START,
                            trackInfo?.id,
                            stageNumber,
                            appointmentId
                        )
                    }
                } else {
                    await TrackPassingsService.startStagePassingWithContent(
                        payload
                    )

                    eventEmitter.emit(
                        INFO_ASSISTANT_EVENT.STAGE_ACTIVITY_OR_CONTENT_START,
                        trackInfo?.id,
                        stageNumber,
                        appointmentId
                    )
                }

                return getStageInfo(stageNumber)
            } catch (error) {
                console.error(error)
            }
        },
        [trackInfo, appointmentId, getStageInfo]
    )

    const cleanStageInfo = useCallback(() => {
        updateStore({ stageInfo: undefined })
    }, [updateStore])

    const closeStage = useCallback(async () => {
        await cleanCurrentStage()

        cleanStageInfo()
        getProgress()

        const track = await getTrackInfo()

        if (isAppointmentTrackStatusPassed(track?.status)) {
            eventEmitter.emit(INFO_ASSISTANT_EVENT.TRACK_END, appointmentId)
        }
    }, [
        cleanCurrentStage,
        cleanStageInfo,
        getProgress,
        getTrackInfo,
        appointmentId,
    ])

    const finishStageContent = useCallback(
        async (trackStagePassingId?: number) => {
            try {
                if (!trackStagePassingId || !currentStageNumber.current) return

                await TrackPassingsService.finishTrackStageWithContent({
                    trackStagePassingId,
                })

                eventEmitter.emit(
                    INFO_ASSISTANT_EVENT.STAGE_END,
                    trackInfo?.id,
                    currentStageNumber.current
                )

                closeStage()
            } catch (error) {
                console.error(error)
            }
        },
        [trackInfo, closeStage]
    )

    const finishStageActivity = useCallback(
        async (trackStagePassingId?: number) => {
            try {
                if (urlParams.action === 'view') {
                    switchViewToNextStage()

                    return
                }

                if (!trackStagePassingId || !currentStageNumber.current) return

                const activityId = stageInfo?.activity?.activityPassing.id

                await ActivityPassingService.finish({
                    body: {
                        activityId,
                        trackStagePassingId,
                    } as ActivityPassingInfoContract,
                })

                eventEmitter.emit(
                    INFO_ASSISTANT_EVENT.STAGE_END,
                    trackInfo?.id,
                    currentStageNumber.current
                )

                closeStage()
            } catch (error) {
                console.error(error)
            }
        },
        [urlParams, stageInfo, trackInfo, closeStage, switchViewToNextStage]
    )

    const saveStageContentProgress = useCallback(
        async (stageContentInfoId: number, progress = 0) => {
            try {
                await TrackPassingsService.exitTrackStageWithContent({
                    trackStagePassingId: stageContentInfoId,
                    body: { progress },
                })

                closeStage()
            } catch (error) {
                console.error(error)
            }
        },
        [closeStage]
    )

    const getLessonInfo = useCallback(
        async ({
            lesson,
            isParentStage,
            isParentActivity,
            activityPassingId,
        }: StartLessonProps) => {
            try {
                globalLoaderEventEmit('SHOW')

                if (!activityPassingId) return

                const startedSesson = await ActivityLessonPassingService.info({
                    body: {
                        activityPassingId,
                        lessonId: lesson.lessonId,
                    },
                })

                if (
                    isThemeBlockTypeHomeworkWithoutEditor(
                        startedSesson.type,
                        startedSesson?.homework?.isEditorUsed
                    ) &&
                    startedSesson?.homework.status ===
                        HomeworkStatusType.OnChecking
                ) {
                    createWarningNotification(
                        LOCAL.MESSAGES.ATTENTION,
                        LOCAL.MESSAGES.HOMEWORK_ON_CHECKING
                    )

                    return
                }

                const isStrartedLessonPlain = !startedSesson.nestedActivityPassing

                updateStore(
                    isStrartedLessonPlain && isParentActivity
                        ? { lessonChildInfo: startedSesson }
                        : { lessonInfo: startedSesson }
                )

                if (startedSesson.nestedActivityPassing) {
                    const childLessonIds = startedSesson.nestedActivityPassing.activityLessonPassings.map(
                        (el) => el.lessonId
                    )

                    updateParticular([
                        {
                            namePath: [
                                storeNamePaths.lessonsParents,
                                startedSesson.lessonId,
                            ],
                            value: childLessonIds,
                        },
                    ])
                }

                return startedSesson
            } catch (error) {
                console.error(error)
            } finally {
                globalLoaderEventEmit('HIDE')
            }
        },
        [updateParticular, updateStore]
    )

    const startLesson = useCallback(
        async ({
            lesson,
            isParentStage,
            isParentActivity,
            activityPassingId,
        }: StartLessonProps) => {
            try {
                const status =
                    'status' in lesson ? lesson.status : lesson.passingStatus

                const isStart =
                    isActivityLessonWaiting(status) ||
                    isActivityLessonInProgress(status)

                if (!activityPassingId) return

                if (isAppointmentPassedView(trackInfo?.status) || !isStart) {
                    getLessonInfo({
                        lesson,
                        isParentStage,
                        isParentActivity,
                        activityPassingId,
                    })

                    return
                }

                await ActivityLessonPassingService.start({
                    body: {
                        activityPassingId,
                        lessonId: lesson.lessonId,
                    },
                })

                getLessonInfo({
                    lesson,
                    isParentStage,
                    isParentActivity,
                    activityPassingId,
                })
            } catch (error) {
                console.error(error)
            }
        },
        [trackInfo, getLessonInfo]
    )

    const startLessonFromContent = useCallback(
        async (
            stageFromContent: TrackStageContentContract,
            lessonFromContent: ActivityLessonPassingContentContract
        ) => {
            const stageInfo = await startStage(stageFromContent.stageNumber)

            startLesson({
                lesson: lessonFromContent,
                activityPassingId: stageInfo?.activity?.activityPassing.id,
            })
        },
        [startLesson, startStage]
    )

    const cleanLessonInfo = useCallback(() => {
        updateStore({
            lessonInfo: undefined,
        })
    }, [updateStore])

    const cleanLessonChildInfo = useCallback(() => {
        updateStore({
            lessonChildInfo: undefined,
        })
    }, [updateStore])

    const closeLesson = useCallback(
        async (isChildLesson?: boolean) => {
            if (isChildLesson && lessonInfo) {
                cleanLessonChildInfo()
                getProgress()

                const activityLessonInfo = await getLessonInfo({
                    lesson: lessonInfo,
                    activityPassingId: lessonInfo.mainActivityPassingId,
                })

                const childLessons =
                    activityLessonInfo?.nestedActivityPassing
                        .activityLessonPassings
                const isAllRequiredBlocksCompleted = checkAllRequiredBlocksPassed(
                    childLessons
                )

                if (
                    isAllRequiredBlocksCompleted &&
                    allBlocksAreRequired(childLessons)
                ) {
                    eventEmitter.emit(TRACK_PASSING_EVENTS.FINISH_LESSON)
                }

                if (
                    isAllRequiredBlocksCompleted &&
                    !isActivityLessonCompletedSuccessfully(
                        activityLessonInfo?.status
                    )
                ) {
                    eventEmitter.emit(
                        TRACK_PASSING_EVENTS.SHOW_FINISH_ACTIVITY_MODAL
                    )
                }
            } else {
                cleanLessonInfo()
                getProgress()

                const stageInfo = await getStageInfo(currentStageNumber.current)

                // TODO: возвращаемое значение stageInfo необходимо для треков без визуализации, для переключения этапа на следующий
                // необходимо получить актуальные статусы для текущего этапа трека типа мероприятия
                // (он завершается в фоне с последним блоком мероприятия)
                if (
                    !trackInfo?.isTrackWithVisualisation &&
                    isActivityCompletedSuccessfully(
                        stageInfo?.activity?.activityPassing.status
                    )
                ) {
                    const currentStageIndex =
                        trackInfo?.stages.findIndex(
                            (stage) =>
                                stage.stageNumber === currentStageNumber.current
                        ) || 0

                    const currentStageStatusNamePath = [
                        ...storeNamePaths.trackInfo.stages,
                        currentStageIndex,
                        'status',
                    ]

                    updateParticular([
                        {
                            namePath:
                                storeNamePaths.trackInfo.currentStageNumber,
                            value: 0,
                        },
                        {
                            namePath: currentStageStatusNamePath,
                            value: AppointmentTrackStatus.Passed,
                        },
                    ])
                    cleanCurrentStage()
                }
            }
        },
        [
            cleanLessonChildInfo,
            getProgress,
            lessonInfo,
            getLessonInfo,
            cleanLessonInfo,
            getStageInfo,
            trackInfo,
            updateParticular,
            cleanCurrentStage,
        ]
    )

    const sendToCheckHomework = useCallback(async () => {
        try {
            if (!lessonInfo?.homework?.id) return

            globalLoaderEventEmit('SHOW')

            await HomeworksService.sendToCheck({
                id: lessonInfo.homework.id,
                isHomeworkAutoCheck:
                    stageInfo?.activity?.activityPassing?.isHomeworkAutoCheck,
            })

            if (stageInfo?.activity?.activityPassing?.isHomeworkAutoCheck) {
                cleanLessonInfo()
            } else {
                Modal.success({
                    icon: '',
                    content: LOCAL.MESSAGES.HOMEWORK_ON_CHECK,
                    onOk: () => {
                        cleanLessonInfo()
                    },
                    okText: LOCAL.ACTIONS.CLOSE,
                    zIndex: MODAL_Z_INDEX.DEFAULT,
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            globalLoaderEventEmit('HIDE')
        }
    }, [lessonInfo, cleanLessonInfo, stageInfo])

    const finishLesson = useCallback(
        async (isChildLesson?: boolean) => {
            try {
                const lesson = isChildLesson ? lessonChildInfo : lessonInfo

                if (!lesson) return

                await ActivityLessonPassingService.finish({
                    activityLessonPassingId: lesson.id,
                })

                closeLesson(isChildLesson)
            } catch (e) {
                console.log(e)
            }
        },
        [closeLesson, lessonChildInfo, lessonInfo]
    )

    const exitLessonVideo = useCallback(
        async (progress?: number, isChildLesson?: boolean) => {
            try {
                const lesson = isChildLesson ? lessonChildInfo : lessonInfo

                if (!lesson || !progress) return

                globalLoaderEventEmit('SHOW')

                await ActivityLessonPassingService.exit({
                    activityLessonPassingId: lesson.id,
                    body: {
                        progress,
                    },
                })
            } catch (error) {
                console.error(error)
            } finally {
                closeLesson(isChildLesson)
                globalLoaderEventEmit('HIDE')
            }
        },
        [closeLesson, lessonChildInfo, lessonInfo]
    )

    const previousTrackInfo = usePrevious(trackInfo)

    useEffect(() => {
        if (!isEqual(previousTrackInfo?.stages, trackInfo?.stages)) {
            scrollToFirstAvailableStage(trackInfo?.stages)
        }
    }, [previousTrackInfo, trackInfo])

    useEffect(() => {
        getTrackInfo()
    }, [getTrackInfo])

    useEffect(() => {
        const events = {
            [TRACK_PASSING_EVENTS.GET_PROGRESS]: getProgress,
            [TRACK_PASSING_EVENTS.GET_TRACK_INFO]: getTrackInfo,
            [TRACK_PASSING_EVENTS.GET_STAGE_INFO]: getStageInfo,
            [TRACK_PASSING_EVENTS.GET_LESSON_INFO]: getLessonInfo,
            [TRACK_PASSING_EVENTS.CLEAN_STAGE_INFO]: cleanStageInfo,
            [TRACK_PASSING_EVENTS.CLEAN_LESSON_INFO]: cleanLessonInfo,
            [TRACK_PASSING_EVENTS.CLEAN_CURRENT_STAGE]: cleanCurrentStage,
            [TRACK_PASSING_EVENTS.CLOSE_STAGE]: closeStage,
            [TRACK_PASSING_EVENTS.CLOSE_LESSON]: closeLesson,
            [TRACK_PASSING_EVENTS.START_STAGE]: startStage,
            [TRACK_PASSING_EVENTS.START_LESSON_FROM_CONTENT]: startLessonFromContent,
            [TRACK_PASSING_EVENTS.START_LESSON]: startLesson,
            [TRACK_PASSING_EVENTS.FINISH_STAGE_CONTENT]: finishStageContent,
            [TRACK_PASSING_EVENTS.FINISH_STAGE_ACTIVITY]: finishStageActivity,
            [TRACK_PASSING_EVENTS.FINISH_LESSON]: finishLesson,
            [TRACK_PASSING_EVENTS.SAVE_STAGE_CONTENT_PROGRESS]: saveStageContentProgress,
            [TRACK_PASSING_EVENTS.SEND_TO_CHECK_HOMEWORK]: sendToCheckHomework,
            [TRACK_PASSING_EVENTS.EXIT_LESSON_VIDEO]: exitLessonVideo,
            [TRACK_PASSING_EVENTS.SHOW_FINISH_ACTIVITY_MODAL]: showFinishActivityModal,
            [TRACK_PASSING_EVENTS.SHOW_FINISH_ACTIVITY_MODAL]: hideFinishActivityModal,
        }

        registerEvents(events)

        return () => {
            unRegisterEvents(events)
        }
    }, [
        appointmentId,
        getProgress,
        startStage,
        getTrackInfo,
        getStageInfo,
        cleanStageInfo,
        finishStageContent,
        saveStageContentProgress,
        finishStageActivity,
        cleanLessonInfo,
        getLessonInfo,
        startLesson,
        cleanCurrentStage,
        closeStage,
        finishLesson,
        sendToCheckHomework,
        exitLessonVideo,
        closeLesson,
        startLessonFromContent,
        showFinishActivityModal,
        hideFinishActivityModal,
    ])

    useEffect(() => {
        if (!trackInfo) return

        const isTrackPassed =
            isAppointmentTrackStatusPassed(trackInfo.status) &&
            trackInfo.isQuestionnairePassed

        const firstNotPassedStageNumber = getFirstNotPassedStage(
            trackInfo.stages
        )?.stageNumber

        if (trackInfo.currentStageNumber) {
            startStage(trackInfo.currentStageNumber)

            return
        }

        if (
            !trackInfo.isTrackWithVisualisation &&
            isTrackPassed &&
            urlParams.action === 'view'
        ) {
            getStageInfo(trackInfo.stages[currentStageForViewIndex].stageNumber)

            return
        }

        if (
            !trackInfo.isTrackWithVisualisation &&
            isTrackPassed &&
            urlParams.action !== 'view'
        ) {
            history.push(ROUTE_NAMES.YOUR_DEVELOPMENT_v2)

            return
        }

        if (
            !trackInfo.isTrackWithVisualisation &&
            !isTrackPassed &&
            firstNotPassedStageNumber
        ) {
            startStage(firstNotPassedStageNumber)

            return
        }
    }, [
        getStageInfo,
        startStage,
        history,
        urlParams.action,
        trackInfo,
        currentStageForViewIndex,
    ])

    useEffect(() => {
        getProgress()
    }, [getProgress])

    return (
        <div>
            <InfoAssistant active={trackInfo?.useInformationAssistant} />

            {trackInfo?.isTrackWithVisualisation && (
                <TrackLayerPassing trackInfo={trackInfo} />
            )}

            <ContentOrActivityLayer />

            <LessonLayer />

            <LessonLayer isChildLesson />

            {trackInfo?.trackProgressVisualization?.isProgressVisible && (
                <TrackProgress />
            )}

            {trackInfo?.trackMenuVisualization?.isMenuVisible && (
                <TrackInfoMenu />
            )}

            <FinishActivityModal visible={finishActivityModalVisible} />
        </div>
    )
}
