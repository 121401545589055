import React from 'react'
import { COL_xl8_lg12_xs24, DATE_FORMAT, MODAL_WIDTH } from 'consts'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { NotEditableTextControl } from 'components/controls/NotEditableTextControl'
import { Popup, SpaceAdapter } from 'components/shared'
import {
    getDateByFormat,
    getFormattedDuration,
    isActivityLessonCompletedSuccessfully,
    isActivityLessonCompletedUnsuccessfully,
    isThemeBlockTypeFaceToFace,
} from 'utils'

import styles from './BlockPassingModal.module.scss'
import { BlockPassingModalProps } from './BlockPassingModal.types'
import { MODAL_TITLE } from './BlockPassingModal.consts'
import {
    getAdditionalScheduleFields,
    getFaceToFaceMeetingStatus,
    getScheduleData,
} from './BlockPassingModal.utils'

/** Модальное окно для прохождения тематических блоков с типом: "вебконференция" или "очная встреча"*/
export const BlockPassingModal: React.FC<BlockPassingModalProps> = React.memo(
    ({ popupProps, lesson }) => {
        const scheduleData = getScheduleData(lesson.schedule, lesson.type)

        const isActivityLessonCompleted =
            isActivityLessonCompletedSuccessfully(lesson.status) ||
            isActivityLessonCompletedUnsuccessfully(lesson.status)

        return (
            <Popup
                width={MODAL_WIDTH.MD}
                className={styles.modal}
                okText={LOCAL.ACTIONS.FURTHER}
                cancelText={LOCAL.ACTIONS.RETURN}
                title={`${MODAL_TITLE[lesson.type]}:`}
                okButtonProps={{
                    hidden: isThemeBlockTypeFaceToFace(lesson.type),
                }}
                footer={isActivityLessonCompleted ? null : undefined}
                {...popupProps}
            >
                <SpaceAdapter
                    fullWidth
                    justifyContent="space-between"
                    align="center"
                >
                    <p className="mb-10">{lesson.name}</p>

                    {isThemeBlockTypeFaceToFace(lesson.type) &&
                        getFaceToFaceMeetingStatus(lesson.status)}
                </SpaceAdapter>

                <pre className={styles.desc}>{lesson.lessonDescription}</pre>

                <Row className="mb-20">
                    <Col {...COL_xl8_lg12_xs24} className="mb-20">
                        <NotEditableTextControl
                            label={LOCAL.LABELS.DATE_AND_TIME}
                            value={getDateByFormat(
                                scheduleData.date,
                                DATE_FORMAT.WITH_TIME
                            )}
                            colon
                        />
                    </Col>

                    {(!!scheduleData.durationMinutes ||
                        !!scheduleData.durationHours) && (
                        <Col {...COL_xl8_lg12_xs24}>
                            <NotEditableTextControl
                                label={LOCAL.LABELS.DURATION}
                                colon
                                value={getFormattedDuration(
                                    scheduleData.durationHours,
                                    scheduleData.durationMinutes
                                )}
                            />
                        </Col>
                    )}

                    {getAdditionalScheduleFields(scheduleData)}
                </Row>
            </Popup>
        )
    }
)
