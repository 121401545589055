import React from 'react'
import {
    DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS,
    MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS,
} from 'consts'
import {
    DistributionMailing,
    DistributionMailingAssignment,
    DistributionMailingAssignmentFormContainer,
    DistributionMailingView,
    DistributionTags,
    DistributionTemplates,
    DistributionTemplatesFormContainer,
    ErrorPage,
} from 'components/pages'
import { ProtectedRoute } from 'core/permissions'
import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

export const DistributionRouting: React.FC = React.memo(() => (
    <Switch>
        <ProtectedRoute
            requiredPermissions={[
                ...DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS,
                UserPermission.NotificationNewsletterTemplateSave,
            ]}
            path={`${ROUTE_NAMES.DISTRIBUTION_TEMPLATES_COPY}/:id`}
        >
            <DistributionTemplatesFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS,
                UserPermission.NotificationNewsletterTemplateSave,
            ]}
            path={`${ROUTE_NAMES.DISTRIBUTION_TEMPLATES_EDIT}/:id`}
        >
            <DistributionTemplatesFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.DISTRIBUTION_TEMPLATES_VIEW}/:id`}
        >
            <DistributionTemplatesFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS,
                UserPermission.NotificationNewsletterTemplateSave,
            ]}
            path={ROUTE_NAMES.DISTRIBUTION_TEMPLATES_CREATE}
        >
            <DistributionTemplatesFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.NotificationNewsletterTemplatesRead,
            ]}
            path={ROUTE_NAMES.DISTRIBUTION_TEMPLATES}
        >
            <DistributionTemplates />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.NotificationTagsRead,
            ]}
            path={ROUTE_NAMES.DISTRIBUTION_TAGS}
        >
            <DistributionTags />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.Administration]}
            path={`${ROUTE_NAMES.DISTRIBUTION_MAILING}/${ROUTES_POSTFIXES.FILTER}/:taskId`}
        >
            <DistributionMailing />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.Administration]}
            path={`${ROUTE_NAMES.DISTRIBUTION_MAILING_VIEW}/:id`}
        >
            <DistributionMailingView formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.Administration]}
            path={ROUTE_NAMES.DISTRIBUTION_MAILING}
        >
            <DistributionMailing />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS,
                UserPermission.NotificationMailingTaskSave,
            ]}
            path={`${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_CREATE}/:templateId?`}
        >
            <DistributionMailingAssignmentFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS,
                UserPermission.NotificationMailingTaskSave,
            ]}
            path={`${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_COPY}/:id`}
        >
            <DistributionMailingAssignmentFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS,
                UserPermission.NotificationMailingTaskSave,
            ]}
            path={`${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_EDIT}/:id`}
        >
            <DistributionMailingAssignmentFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_VIEW}/:id`}
        >
            <DistributionMailingAssignmentFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.NotificationMailingTasksRead,
            ]}
            path={ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT}
        >
            <DistributionMailingAssignment />
        </ProtectedRoute>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
