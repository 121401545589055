import React from 'react'
import { CERTIFICATE_KIND_MAPPED, ROW_GUTTER } from 'consts'
import { CertificateSearchContract, CertificateType } from 'core/api'
import { CertificatesPageUrlParamsProps } from 'components/pages/Certificates/Certificates.types'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { useParams, useRouteMatch } from 'react-router'

/**
 * Форма фильтрации сертификатов
 */
export const CertificatesFilters: React.FC<FiltersProps<
    CertificateSearchContract
>> = React.memo((props) => {
    const { userId } = useParams<CertificatesPageUrlParamsProps>()

    const type = useRouteMatch(ROUTE_NAMES.INTERNAL_CERTIFICATES)
        ? CertificateType.Internal
        : CertificateType.External

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                tableFiltersOptions={{
                    initialValues: { type, userId: Number(userId) },
                }}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.CERTIFICATE_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="number"
                            label={LOCAL.LABELS.CERTIFICATE_NUMBER}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={4}>
                        <FormItemAdapter
                            fieldName="issueDate"
                            label={LOCAL.LABELS.CERTIFICATE_DATE}
                        >
                            <DatePickerControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={4}>
                        <FormItemAdapter
                            fieldName="kind"
                            label={LOCAL.LABELS.KIND}
                        >
                            <SelectControl values={CERTIFICATE_KIND_MAPPED} />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
