import React, { FC } from 'react'
import {
    ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
    ASSESSMENT_PORTFOLIOS_CONDITIONAL_PERMISSIONS,
    ASSESSMENT_PORTFOLIOS_REQUIRED_PERMISSIONS,
    ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS,
    ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS,
    ASSESSMENT_REPORTS_REQUIRED_PERMISSIONS,
} from 'consts'
import {
    AssessmentAssigned,
    AssessmentAssignmentFormContainer,
    AssessmentAssignments,
    AssessmentPortfolioFormContainer,
    AssessmentPortfolios,
    AssessmentReport,
    CircleAssessmentReports,
    ErrorPage,
    ProfileReports,
} from 'components/pages'
import { AssessmentEvaluation } from 'components/pages/AssessmentAssigned/AssessmentEvaluation'
import { AssessmentProjects } from 'components/pages/AssessmentProjects'
import { AssessmentProjectsFormContainer } from 'components/pages/AssessmentProjects/AssessmentProjectsFormContainer'
import { AssessmentReports } from 'components/pages/AssessmentReports'
import { AssessmentReportsSettings } from 'components/pages/AssessmentReportsSettings'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

export const AssessmentRouting: FC = React.memo(() => (
    <Switch>
        <Route path={`${ROUTE_NAMES.CONSOLIDATED_REPORTS}/:appointmentId`}>
            <CircleAssessmentReports />
        </Route>

        <Route
            path={`${ROUTE_NAMES.PROFILE_REPORTS}/:appointmentId/:reportId?`}
        >
            <ProfileReports />
        </Route>

        <ProtectedRoute
            requiredPermissions={ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_VIEW}/:id`}
        >
            <AssessmentAssignmentFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
                UserPermission.AssessmentAppointmentSave,
            ]}
            path={`${ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_EDIT}/:id`}
        >
            <AssessmentAssignmentFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
                UserPermission.AssessmentAppointmentSave,
            ]}
            path={ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_CREATE}
        >
            <AssessmentAssignmentFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS}
            path={ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS}
        >
            <AssessmentAssignments />
        </ProtectedRoute>

        <Route path={`${ROUTE_NAMES.ASSESSMENT_ASSIGNED_EVALUATION}/:id`}>
            <AssessmentEvaluation />
        </Route>

        <Route path={ROUTE_NAMES.ASSESSMENT_ASSIGNED}>
            <AssessmentAssigned />
        </Route>

        <ProtectedRoute
            requiredPermissions={[
                ...ASSESSMENT_PORTFOLIOS_REQUIRED_PERMISSIONS,
                UserPermission.AssessmentPortfolioSave,
            ]}
            conditionalPermissions={
                ASSESSMENT_PORTFOLIOS_CONDITIONAL_PERMISSIONS
            }
            path={`${ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_COPY}/:id`}
        >
            <AssessmentPortfolioFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={ASSESSMENT_PORTFOLIOS_REQUIRED_PERMISSIONS}
            conditionalPermissions={
                ASSESSMENT_PORTFOLIOS_CONDITIONAL_PERMISSIONS
            }
            path={`${ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_VIEW}/:id`}
        >
            <AssessmentPortfolioFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...ASSESSMENT_PORTFOLIOS_REQUIRED_PERMISSIONS,
                UserPermission.AssessmentPortfolioSave,
            ]}
            conditionalPermissions={
                ASSESSMENT_PORTFOLIOS_CONDITIONAL_PERMISSIONS
            }
            path={`${ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_EDIT}/:id`}
        >
            <AssessmentPortfolioFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...ASSESSMENT_PORTFOLIOS_REQUIRED_PERMISSIONS,
                UserPermission.AssessmentPortfolioSave,
            ]}
            conditionalPermissions={
                ASSESSMENT_PORTFOLIOS_CONDITIONAL_PERMISSIONS
            }
            path={`${ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_CREATE}/:assessmentType?`}
        >
            <AssessmentPortfolioFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={ASSESSMENT_PORTFOLIOS_REQUIRED_PERMISSIONS}
            path={ROUTE_NAMES.ASSESSMENT_PORTFOLIOS}
        >
            <AssessmentPortfolios />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.ASSESSMENT_PROJECTS}`}
            exact
        >
            <AssessmentProjects />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS,
                UserPermission.AssessmentProjectSave,
            ]}
            conditionalPermissions={ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS}
            path={`${ROUTE_NAMES.ASSESSMENT_PROJECTS_CREATE}`}
            exact
        >
            <AssessmentProjectsFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS,
                UserPermission.AssessmentProjectSave,
            ]}
            conditionalPermissions={ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS}
            path={`${ROUTE_NAMES.ASSESSMENT_PROJECTS_EDIT}/:id`}
        >
            <AssessmentProjectsFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS}
            conditionalPermissions={ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS}
            path={`${ROUTE_NAMES.ASSESSMENT_PROJECTS_VIEW}/:id`}
        >
            <AssessmentProjectsFormContainer formMode="view" />
        </ProtectedRoute>

        <Route path={`${ROUTE_NAMES.ASSESSMENT_REPORTS_SETTINGS}/:type`} exact>
            <AssessmentReportsSettings formMode="create" />
        </Route>

        <ProtectedRoute
            requiredPermissions={[
                ...ASSESSMENT_REPORTS_REQUIRED_PERMISSIONS,
                UserPermission.AssessmentReportRead,
            ]}
            path={`${ROUTE_NAMES.ASSESSMENT_REPORTS}/:type/:reportId`}
            exact
        >
            <AssessmentReport />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={ASSESSMENT_REPORTS_REQUIRED_PERMISSIONS}
            path={ROUTE_NAMES.ASSESSMENT_REPORTS}
            exact
        >
            <AssessmentReports />
        </ProtectedRoute>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
