import React from 'react'
import cn from 'classnames'
import { ColumnsType } from 'antd/lib/table'
import { DOCUMENT_STATUSES } from 'consts'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { SpaceAdapter, WidthRestrictedElement } from 'components/shared'
import { UserRegistryContract } from 'core/api'
import { defaultSorter } from 'components/layouts'
import { formatDate, restrictBigText } from 'utils'

import styles from './Coworkers.module.scss'
import { CoworkersStaffStatusTypeColumn } from './components/CoworkersStaffStatusTypeColumn/CoworkersStaffStatusTypeColumn'
import { CoworkersStructuralUnitsColumn } from './components/CoworkersStructuralUnitsColumn'

/** настройка отображения колонок для справочника сотрудников */
export const COWORKERS_TABLE_COLUMNS: ColumnsType<UserRegistryContract> = [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'fullName',
        key: 'fullName',
        render: (_, record) => (
            <SpaceAdapter>
                {record.isChanged && (
                    <IconsAdapter
                        iconType="ExclamationCircleOutlined"
                        className={styles.changedData}
                    />
                )}

                <span
                    className={cn(
                        styles.name,
                        record.lastArchiveVersion?.fullName &&
                            record.lastArchiveVersion.fullName !==
                                record.fullName &&
                            styles.changedData
                    )}
                >
                    {restrictBigText(record.fullName)}
                </span>
            </SpaceAdapter>
        ),
        fixed: 'left',
        sorter: defaultSorter,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'userStructuralUnitPositions',
        key: 'userStructuralUnitPositions',
        render: CoworkersStructuralUnitsColumn,
    },
    {
        title: LOCAL.LABELS.STAFF_STATUS_TYPE,
        dataIndex: 'userStaffStatusType',
        key: 'userStaffStatusType',
        render: CoworkersStaffStatusTypeColumn,
    },
    {
        title: LOCAL.LABELS.EMAIL,
        dataIndex: 'email',
        key: 'email',
        render: (_, record) => (
            <span
                className={cn(
                    record.lastArchiveVersion?.email &&
                        record.email !== record.lastArchiveVersion.email &&
                        styles.changedData
                )}
            >
                {record.email}
            </span>
        ),
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'active',
        key: 'active',
        render: (isArchived: boolean) => (
            <WidthRestrictedElement
                title={
                    isArchived
                        ? LOCAL.ACTIVE_EMPLOYEE
                        : DOCUMENT_STATUSES.Archive
                }
            />
        ),
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: defaultSorter,
        fixed: 'right',
    },
]
