import { LabeledValue } from 'antd/lib/select'
import { QuestionType } from 'core/api'
import { Store } from 'antd/lib/form/interface'
import {
    disableSelectOptions,
    isAssessmentTypeExpert,
    isAssessmentTypeManager,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeQuiz,
    isQuestionnaireTypeTest,
} from 'utils'

import { QuestionVariationValueProps } from '../QuestionVariation/QuestionVariation.types'
import { isRespondentTypeExpert } from '../QuestionnairesConstructorForm/QuestionnairesConstructorForm.utils'

/**
 * Получить список возможных значений для типов вопросов, в зависимости от значений полей формы
 * @param questionTypes - типы вопросов
 * @param formValues - поля формы
 */
export const getQuestionsListOptions = (
    questionTypes: LabeledValue[],
    formValues?: Store
) => {
    if (!formValues) return

    if (isQuestionnaireTypeTest(formValues.type))
        return disableSelectOptions(questionTypes, [QuestionType.CriteriaScale])

    if (isQuestionnaireTypeQuiz(formValues.type))
        return disableSelectOptions(questionTypes, [QuestionType.FillEmptyText])

    if (
        isQuestionnaireTypeAssessment(formValues.type) &&
        !isAssessmentTypeExpert(formValues.assessmentType) &&
        !isAssessmentTypeManager(formValues.assessmentType)
    )
        return disableSelectOptions(questionTypes, [
            QuestionType.ChooseVariant,
            QuestionType.FillEmptyText,
        ])

    if (
        isQuestionnaireTypeAssessment(formValues.type) &&
        isAssessmentTypeManager(formValues.assessmentType)
    )
        return disableSelectOptions(questionTypes, [QuestionType.FillEmptyText])

    if (
        isQuestionnaireTypeAssessment(formValues.type) &&
        isAssessmentTypeExpert(formValues.assessmentType) &&
        isRespondentTypeExpert(formValues.respondentTypeId)
    )
        return disableSelectOptions(questionTypes, [
            QuestionType.ChooseVariant,
            QuestionType.FillEmptyText,
        ])

    return questionTypes
}

/**
 * Обработчик изменения полей блока типа вопроса
 * @param fieldKey ключ поля, которое нужно обновить
 * @param onUpdateValue функция, обновляет значения для формы и для внутреннего стейта кастомного контрола
 * @param currentValues текущие значения кастомного контрола
 */
export function handleFieldChange(
    fieldKey: string,
    onUpdateValue: (value?: QuestionVariationValueProps) => void,
    currentValues?: QuestionVariationValueProps
) {
    function EventHandler(event?: boolean): void
    function EventHandler(event?: React.ChangeEvent<HTMLInputElement>): void
    function EventHandler(event?: React.ChangeEvent<HTMLTextAreaElement>): void
    function EventHandler(event?: number | string): void
    function EventHandler(event?: number): void
    function EventHandler(event?: any): void {
        const getValue = () => {
            if (typeof event === 'number') return event
            if (typeof event === 'boolean') return event

            return event?.target?.value
        }

        const calculatedValue = {
            ...currentValues,
            [fieldKey]: getValue(),
        } as QuestionVariationValueProps

        onUpdateValue(calculatedValue)
    }

    return EventHandler
}
