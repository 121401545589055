import { eventEmitter } from 'core/helpers/eventEmitter'

import { EventsProps } from './GlobalLoader.types'

export const GLOBAL_LOADER_EVENTS = {
    SHOW: 'SHOW',
    HIDE: 'HIDE',
}

export const registerEvents = (handlers: EventsProps) => {
    eventEmitter.on(GLOBAL_LOADER_EVENTS.SHOW, handlers.showLoader)
    eventEmitter.on(GLOBAL_LOADER_EVENTS.HIDE, handlers.hideLoader)
}

export const unRegisterEvents = (handlers: EventsProps) => {
    eventEmitter.off(GLOBAL_LOADER_EVENTS.SHOW, handlers.showLoader)
    eventEmitter.off(GLOBAL_LOADER_EVENTS.HIDE, handlers.hideLoader)
}

export const globalLoaderEventEmit = (
    event: keyof typeof GLOBAL_LOADER_EVENTS
) => {
    eventEmitter.emit(event)
}
