import React, { FC, Key, useCallback, useEffect, useState } from 'react'
import {
    AppointmentTrackRespondentProgressContractAppointmentProgressPageContract,
    AppointmentTracksService,
} from 'core/api'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    TableAdapter,
    getRowClassNameArchive,
} from 'components/shared'
import { ControlGroup } from 'components/controls'
import { LOCAL } from 'core/local'
import { RequestQueryProps } from 'hooks/useSearchQuery.types'
import {
    createConfirmPopup,
    getPageSize,
    showMessageWithDelayAfter,
} from 'utils'
import { isNumber } from 'lodash'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import styles from './TrackAssignmentsProgressTable.module.scss'
import { TRACK_ASSIGNMENTS_TABLE_COLUMNS } from './TrackAssignmentsProgressTable.consts'
import { TrackAssignmentsProgressFilters } from './TrackAssignmentsProgressFilters'
import { TrackAssignmentsProgressTableProps } from './TrackAssignmentsProgressTable.types'

/**
 * Форма удаления/редактирования/замены участников в запущенном назначении оценки
 */
export const TrackAssignmentsProgressTable: FC<TrackAssignmentsProgressTableProps> = withLoader(
    React.memo(
        ({
            initialValues,
            hideProgress,
            updateLoader,
            isLoading,
            reFetchDictionary,
        }) => {
            const [appointmentData, setAppointmentData] = useState<
                AppointmentTrackRespondentProgressContractAppointmentProgressPageContract
            >()
            const [selectedKeys, setSelectedKeys] = useState<Key[]>()

            const {
                paginationOptions,
                queryParams,
                handleSetFilters,
                handleResetFilters,
                handleSort,
            } = useSearchQuery({
                queryOptions: {
                    body: { appointmentTrackId: initialValues?.id },
                },
            })

            /**
             * Обработчик выбора записей для удаления в таблице формы
             */
            const handleSelectKeys = useCallback(setSelectedKeys, [
                setSelectedKeys,
            ])

            /**
             * Обработчик отправки формы
             */
            const handleFinish = useCallback(async () => {
                try {
                    updateLoader(true)

                    createConfirmPopup({
                        content: LOCAL.MESSAGES.EXCLUDE_USERS_CONFIRM_MESSAGE,
                        onOk: async () => {
                            await AppointmentTracksService.deactivateRespondents(
                                {
                                    appointmentTrackId: initialValues.id,
                                    body: selectedKeys,
                                }
                            )
                            reFetchDictionary?.()

                            showMessageWithDelayAfter({
                                messageText:
                                    LOCAL.MESSAGES
                                        .ASSIGNMENT_SAVE_SUCCESS_MESSAGE,
                            }).then(hideProgress)
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }, [
                initialValues,
                reFetchDictionary,
                hideProgress,
                selectedKeys,
                updateLoader,
            ])

            /**
             * Запрос назначения по id
             */
            useEffect(() => {
                const appointmentFetch = async () => {
                    try {
                        updateLoader(true)
                        setAppointmentData(
                            await AppointmentTracksService.getParticipantList(
                                queryParams as Required<RequestQueryProps>
                            )
                        )
                    } catch (error) {
                        console.error(error)
                    } finally {
                        updateLoader(false)
                    }
                }
                appointmentFetch()
            }, [queryParams, updateLoader])

            /**
             * Рендер checkbox для записей в таблице формы по условию
             */
            const checkboxRender = useCallback(
                (checked, record, index, originNode) => {
                    if (!record.active) return <></>

                    return originNode
                },
                []
            )

            return (
                <>
                    <TrackAssignmentsProgressFilters
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                    />

                    <ControlGroup
                        title={LOCAL.LABELS.LISTENERS}
                        noPadding
                        className={styles.controlGroup}
                    >
                        {isNumber(appointmentData?.commonProgress) && (
                            <p className={styles.overallProgress}>
                                {`${LOCAL.LABELS.OVERALL_PROGRESS_OF_PASSAGE}: ${appointmentData?.commonProgress}%`}
                            </p>
                        )}

                        <TableAdapter
                            columns={TRACK_ASSIGNMENTS_TABLE_COLUMNS}
                            rowKey="userId"
                            rowSelection={{
                                hideSelectAll: true,
                                preserveSelectedRowKeys: true,
                                onChange: handleSelectKeys,
                                renderCell: checkboxRender,
                            }}
                            dataSource={appointmentData?.pageItems}
                            pagination={{
                                ...paginationOptions,
                                total: appointmentData?.total,
                                current: queryParams.pageNumber,
                                pageSize: getPageSize(
                                    queryParams?.pageSize,
                                    appointmentData?.pageItems?.length
                                ),
                            }}
                            onChange={handleSort}
                            rowClassName={getRowClassNameArchive}
                        />
                    </ControlGroup>

                    <ButtonsToolbar align="space-between">
                        <Button
                            onClick={handleFinish}
                            disabled={isLoading || !selectedKeys?.length}
                            loading={isLoading}
                        >
                            {LOCAL.ACTIONS.DELETE}
                        </Button>

                        <Button type="link" onClick={hideProgress}>
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>
                    </ButtonsToolbar>
                </>
            )
        }
    )
)
