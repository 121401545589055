import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CheckboxControl, ShouldUpdateChecker } from 'components/controls'
import { Col, Form, Row } from 'antd'
import { CompetenciesService } from 'core/api'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { TooltipAdapter } from 'components/shared/TooltipAdapter'
import { getPositionProfileCompetenciesFromCompetenceContract } from 'utils'

import styles from './JobProfileCompetencies.module.scss'
import { AdjacentCompetenciesSelect } from '../AdjacentCompetenciesControl'
import { CompetenceGroupsIdsControl } from '../CompetenceGroupsIdsControl'
import { CompetenceItemsControl } from '../CompetenceItemsControl'
import { JobProfileCompetenciesProps } from './JobProfileCompetencies.types'
import { PositionProfileCompetenceOptionalContract } from '../CompetenceItemsControl/CompetenceItemsControl.types'

/** Компонент взаимодействия с областью компетенций внутри формы профиля должности */
export const JobProfileCompetencies: React.FC<JobProfileCompetenciesProps> = React.memo(
    ({ formMode, initialValuesForEdit }) => {
        /** Состояние для хранения смежных компетенций */
        const [adjacentCompetencies, setAdjacentCompetencies] = useState<
            PositionProfileCompetenceOptionalContract[]
        >([])

        /**
         * Запрос смежных компетенций по ids
         * @param competenceIds - ids компетенций для получения с сервера
         */
        const fetchAdjacentCompetencies = useCallback(
            async (competenceIds: number[]) => {
                try {
                    const fetchedCompetencies = await CompetenciesService.getByIds(
                        {
                            body: competenceIds,
                        }
                    )

                    setAdjacentCompetencies(
                        fetchedCompetencies.map(
                            getPositionProfileCompetenciesFromCompetenceContract
                        )
                    )
                } catch (error) {
                    console.error(error)
                }
            },
            []
        )
        /**
         * Обработчик изменений внутри компонента каскадного выбора смежных компетенций
         * @param selectedItems - выбранные пользователем элементы
         */
        const handleChangeAdjacentCompetencies = useCallback(
            (selectedItems: number[]) => {
                fetchAdjacentCompetencies(selectedItems)
            },
            [fetchAdjacentCompetencies]
        )

        /** ids текущих активных смежных компетенций */
        const currentAdjacentCompetenciesIds = useMemo(
            () => adjacentCompetencies.map((el) => el.id),
            [adjacentCompetencies]
        )

        /** Установка начальных значений для смежных компетенций, если такие имеются */
        useEffect(() => {
            setAdjacentCompetencies(
                initialValuesForEdit?.competencies?.filter(
                    (el) => el.isAdjacent
                ) || []
            )
        }, [initialValuesForEdit])

        return (
            <>
                <Row gutter={ROW_GUTTER}>
                    <Col>
                        <TooltipAdapter
                            title={LOCAL.TOOLTIPS.COMPETENCIES_OF_JOB_PROFILE}
                        >
                            <h2
                                className={cn(styles.title, 'required-control')}
                            >
                                {LOCAL.LABELS.COMPETENCIES}
                            </h2>
                        </TooltipAdapter>
                    </Col>

                    <Col>
                        <Form.Item name="competenceGroupsIds">
                            <CompetenceGroupsIdsControl formMode={formMode} />
                        </Form.Item>
                    </Col>

                    <Col>
                        <AdjacentCompetenciesSelect
                            value={currentAdjacentCompetenciesIds}
                            onChange={handleChangeAdjacentCompetencies}
                            formMode={formMode}
                        />
                    </Col>
                </Row>

                <Form.Item name="isCompetenciesIndicatorsShown">
                    <CheckboxControl className={styles.indicatorsCheckbox}>
                        {LOCAL.LABELS.COMPETENCIES_SHOW_INDICATORS}
                    </CheckboxControl>
                </Form.Item>

                <ShouldUpdateChecker fieldPath={['competenceGroupsIds']}>
                    {(form) => (
                        <Form.Item name="competencies">
                            <CompetenceItemsControl
                                competenceGroupsIds={form.getFieldValue(
                                    'competenceGroupsIds'
                                )}
                                initialCompetencies={
                                    initialValuesForEdit?.competencies
                                }
                                formMode={formMode}
                                adjacentCompetencies={adjacentCompetencies}
                                form={form}
                            />
                        </Form.Item>
                    )}
                </ShouldUpdateChecker>
            </>
        )
    }
)
