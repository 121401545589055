import {
    PassingFormContract,
    QuestionChooseVariantAnswerPublicContract,
    ScaleValueContract,
} from 'core/api'

import { getCommonSelectItemProps } from './form.utils'

/**
 * Меняем нумерацию вопросов в прохождении на сквозную
 * @param questionnaireData - объект прохождения
 */
export const changeQuestionEndToEndNumber = (
    questionnaireData: PassingFormContract
) => {
    let questionNumber = 0

    return {
        ...questionnaireData,
        groupedQuestions: questionnaireData.groupedQuestions.map((group) => ({
            ...group,
            competenceQuestions: group.competenceQuestions.map((el) => ({
                ...el,
                questions: el.questions.map((question) => {
                    questionNumber += 1

                    return {
                        ...question,
                        number: questionNumber,
                    }
                }),
            })),
        })),
    }
}

/**
 * Получаем вопросы плоским массивом
 * @param questionnaireData - объект прохождения
 */
export const getFlatQuestions = (questionnaireData: PassingFormContract) =>
    questionnaireData.groupedQuestions
        .map((group) => group.competenceQuestions.map((el) => el.questions))
        .flat(2)

/**
 * Получаем компетенции с вопросами плоским массивом
 * @param questionnaireData - объект прохождения
 */
export const getFlatCompetences = (questionnaireData: PassingFormContract) =>
    questionnaireData.groupedQuestions
        .map((group) => group.competenceQuestions)
        .flat()

/**
 * Нормализуем шкалы критериев для радио-инпута
 * @param scaleValues - шкалы критериев
 */
export const normalizeScaleValues = (scaleValues: ScaleValueContract[]) =>
    scaleValues.map((el) => ({
        label: el.description,
        ...getCommonSelectItemProps(el.id),
    }))

/**
 * Нормализуем список ответов вопроса
 * @param answers - список ответов
 */
export const normalizeAnswersValues = (
    answers: QuestionChooseVariantAnswerPublicContract[]
) =>
    answers.map((answer) => ({
        label: answer.text,
        ...getCommonSelectItemProps(answer.id),
    }))
