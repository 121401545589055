import { ColumnsType } from 'antd/lib/table'
import { DictionaryContract } from 'core/api'
import { LOCAL } from 'core/local'

/** настройка отображения колонок таблицы */
export const POSITIONS_TABLE_COLUMNS: ColumnsType<DictionaryContract> = [
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'name',
        key: 'name',
    },
]
