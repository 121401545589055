import React from 'react'
import { AppointmentQuizStructuralUnitProgressContract } from 'core/api'
import { generate } from 'shortid'

import styles from './QuizAssignmentsProgressTable.module.scss'
import { SurveyAssignmentProgressFormProps } from './QuizAssignmentsProgressTable.types'

/**
 * Рендер checkbox для записей в таблице формы по условию
 */
export const checkboxRender = (
    checked: boolean,
    record: SurveyAssignmentProgressFormProps,
    index: number,
    originNode: React.ReactNode
) => {
    if ('active' in record && !record.active)
        return <span className={styles.checkboxPlaceholder} />

    return originNode
}

export const renderRespondents = (
    userName: string,
    record: SurveyAssignmentProgressFormProps
): string | undefined => record.userName || record.structuralUnit?.name

/** маппинг данных для таблицы
 * @param item сущность для строки таблицы
 */
export const mapDataToResponse = (
    item: AppointmentQuizStructuralUnitProgressContract
): SurveyAssignmentProgressFormProps => ({
    ...item,
    key: generate(),
    children: [
        ...item.units.map(mapDataToResponse),
        ...item.users.map((el) => ({ ...el, key: generate() })),
    ],
})

/**
 * Рекурсивно вытаскиваем идентификаторы выбранных респондентов
 * @param record запись таблицы
 */
export const getUserIds = (
    record:
        | SurveyAssignmentProgressFormProps
        | AppointmentQuizStructuralUnitProgressContract
): number[] => {
    const userIds = record.users?.map((el) => el.userId) || []

    if (record.units?.length)
        userIds.concat(record.units.map(getUserIds).flat())

    return userIds
}
