import React, { FC, memo } from 'react'
import { CompetencesPublicQuestionsContract } from 'core/api'
import { LOCAL } from 'core/local'
import { Title } from 'components/shared'

import styles from './PassingCompetence.module.scss'
import { PassingCompetenceQuestion } from '../PassingCompetenceQuestion'
import { getCompetenceBlockId } from './PassingCompetence.utils'

/**
 * Компетенция с вопросами
 */
export const PassingCompetence: FC<CompetencesPublicQuestionsContract> = memo(
    ({ competence, questions }) => (
        <div className={styles.wrapper} id={getCompetenceBlockId(competence)}>
            <Title
                type="h4"
                text={
                    <>
                        {LOCAL.LABELS.COMPETENCE}

                        {' — '}

                        <span>{competence.name}</span>
                    </>
                }
                className={styles.header}
            />

            {questions.map((question) => (
                <PassingCompetenceQuestion {...question} key={question.id} />
            ))}
        </div>
    )
)
