import cn from 'classnames'
import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter, SpaceAdapter } from 'components/shared'
import { LEARNING_FORMATS } from 'consts'
import { RecommendationContract } from 'core/api'
import { handleCardView } from 'components/pages/Main/MainPage.utils'
import { toggleFavoriteStatus } from 'components/pages/Main/components/CatalogUnit'

import styles from './RecommendedUnit.module.scss'
import { CatalogItemWithUpdateProps } from '../../../MainPage.types'

/** "Рекомендованный" элемент каталога */
export const RecommendedUnit: React.FC<
    RecommendationContract & CatalogItemWithUpdateProps
> = React.memo(
    ({
        id,
        favorites,
        name,
        pictureUrl,
        onUpdateFavourites,
        studyingFormat,
        hasIndicatorBookingClosed,
    }) => {
        /** Добавить элемент в раздел "избранное" */
        const handlePickFavorite = useCallback(
            async (e) => {
                e.preventDefault()
                e.stopPropagation()

                await toggleFavoriteStatus({
                    itemId: id,
                    isFavorite: favorites,
                })
                await onUpdateFavourites(id)
            },
            [id, favorites, onUpdateFavourites]
        )

        const imageStyle = {
            backgroundImage: `linear-gradient(0deg, rgba(56, 56, 56, 0.8) 0%, rgba(38, 38, 38, 0) 50%), url(${pictureUrl})`,
        }

        return (
            <a
                onClick={handleCardView(id)}
                href="#s"
                className={styles.wrapper}
                key={id}
            >
                <div className={styles.body} style={imageStyle}>
                    <SpaceAdapter justifyContent="space-between" fullWidth>
                        <SpaceAdapter>
                            <Button
                                type="link"
                                className={cn(
                                    styles.favorite,
                                    favorites && styles.favoriteActive
                                )}
                                icon={
                                    <IconsAdapter iconType="CustomIconStar" />
                                }
                                onClick={handlePickFavorite}
                            />

                            <div className={styles.format}>
                                {LEARNING_FORMATS[studyingFormat]}
                            </div>
                        </SpaceAdapter>

                        {hasIndicatorBookingClosed && (
                            <div className={styles.filledGroups}>
                                <IconsAdapter iconType="StopOutlined" />
                            </div>
                        )}
                    </SpaceAdapter>

                    <h4 className={styles.title}>{name}</h4>
                </div>
            </a>
        )
    }
)
