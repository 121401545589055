export const SUB_TASK_TOOLBAR_OPTIONS = [
    { name: 'styles', items: ['TextColor'] },
    {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Strike', 'Underline'],
    },
    {
        name: 'tools',
        items: ['RemoveFormat', 'Undo'],
    },
    { name: 'links', items: ['Link', 'Unlink'] },
]
