import isEqual from 'lodash/isEqual'
import isNumber from 'lodash/isNumber'
import moment, { DurationInputArg1, Moment, MomentInput } from 'moment'
import { DATE_FORMAT, NULLABLE_DATE } from 'consts/date.consts'

/** Утилиты для работы с датами */

export type DateProps = string | Moment | Date | null

export type InclusivityType = '()' | '[)' | '(]' | '[]'

export enum InclusivityOptions {
    'NotIncluded' = '()',
    'IncludedStart' = '[)',
    'IncludedEnd' = '(]',
    'IncludedBoth' = '[]',
}

/**
 * Форматирование строки начала суток переданной даты
 * @param date дата
 */
export const getDayStartWithoutTime = (date?: DateProps) =>
    moment(date).startOf('d').utc().format()

/**
 * Форматирование строки с округлением до минуты переданной даты
 * @param date дата
 */
export const getDayWithTime = (date?: Moment) =>
    moment(date)?.startOf('m').utc().format()

/**
 * Проверка на корректность даты
 * @param date - дата для проверки
 * @returns {Moment | null} возвращаемое значение даты либо null
 */
export const validateDate = (date?: DateProps): DateProps => {
    if (!date) return null

    const isStringDateInvalid =
        typeof date === 'string' && date === NULLABLE_DATE

    if (isStringDateInvalid || isEqual(date, moment(NULLABLE_DATE))) return null

    return date
}

/**
 * Форматирование даты для вывода в UI
 * @param date - дата
 */
export function formatDate(date?: DateProps) {
    if (!validateDate(date)) return

    return moment(date).format(DATE_FORMAT.DATE)
}

/**
 * Форматирование даты для вывода в UI
 * @param date - дата
 * @param format - формат вывода даты
 */
export function getDateByFormat(date?: DateProps, format?: string) {
    if (!validateDate(date)) return

    return moment(date).format(format)
}

/**
 * Вычислить находится ли первая дата в прошлом относительно второй
 * @param firstDate - дата для сравнения
 * @param secondDate - дата для сравнения
 * @return {boolean} если true, значит первая дата находится в прошлом относительно второй
 */
export const isFirstDateEarlier = (
    firstDate: MomentInput,
    secondDate?: MomentInput
) => moment(secondDate).isAfter(firstDate, 'd')

/**
 * Вычислить первая дата раньше или тот же самый день относительно второй
 * @param firstDate - дата для сравнения
 * @param secondDate - дата для сравнения
 */
export const isFirstDateSameOrEarlier = (
    firstDate: MomentInput,
    secondDate: MomentInput
) => moment(secondDate).isSameOrAfter(firstDate, 'd')

/**
 * Вычислить находится ли дата в заданном промежутке c настройкой включения dateFrom и dateTo
 * @param date - дата для сравнения
 * @param dateFrom - дата для сравнения
 * @param dateTo - дата для сравнения
 * @param inclusivity (InclusivityOptions) - параметр включения dateFrom и dateTo в промежуток
 * @return {boolean} если true, значит дата находится в заданном промежутке
 */
export const isDateInBetween = (
    date: MomentInput,
    dateFrom: MomentInput,
    dateTo: MomentInput,
    inclusivity?: InclusivityType
) =>
    moment(date).isBetween(
        dateFrom,
        dateTo,
        undefined,
        inclusivity ?? InclusivityOptions.IncludedBoth
    )

/**
 * Форматирование периода для вывода в UI
 * @description 2021-01-20T00:00:00, 2021-01-25T00:00:00 => 20 — 25 января 2021
 * @description 2021-01-20T00:00:00, 2021-02-25T00:00:00 => 20 января — 25 февраля 2021
 * @description 2020-01-20T00:00:00, 2021-02-25T00:00:00 => 20 января 2020 — 25 февраля 2021
 * @param dateFrom - дата (от)
 * @param dateTo - дата (до)
 * @returns {string | undefined} - отформатированная строка, либо undefined (в случае не валидных
 *     значений)
 */
export const formatPeriod = (dateFrom: DateProps, dateTo: DateProps) => {
    if (!validateDate(dateFrom) || !validateDate(dateTo)) return
    const DAY_FORMAT = 'D'
    const MONTH_FORMAT = 'MMMM'
    const YEAR_FORMAT = 'YYYY'
    const DAY_MONTH_FORMAT = `${DAY_FORMAT} ${MONTH_FORMAT}`
    const FULL_FORMAT = `${DAY_MONTH_FORMAT} ${YEAR_FORMAT}`

    const momentFrom = moment(dateFrom)
    const momentTo = moment(dateTo)

    const isSameMonth = momentFrom.month() === momentTo.month()
    const isSameYear = momentFrom.year() === momentTo.year()
    const isSameMonthAndYear = isSameMonth && isSameYear
    const isSameMonthDate = momentFrom.date() === momentTo.date()
    const isSameDate = isSameMonthAndYear && isSameMonthDate

    const getFormattedDateFrom = () => {
        if (isSameMonthAndYear) {
            return momentFrom.format(DAY_FORMAT)
        }
        if (isSameYear) {
            return momentFrom.format(DAY_MONTH_FORMAT)
        }

        return momentFrom.format(FULL_FORMAT)
    }

    const getFormattedDateTo = () => momentTo.format(FULL_FORMAT)

    if (isSameDate) {
        return getFormattedDateTo()
    }

    return `${getFormattedDateFrom()} — ${getFormattedDateTo()}`
}

export const getYearsPeriod = (years?: Date[]) => {
    if (!years) return
    let yearStart = moment(years[0]).year()
    const yearEnd = moment(years[1]).year()

    const yearsPeriod = []

    while (yearStart <= yearEnd) {
        yearsPeriod.push(yearStart)

        yearStart += 1
    }

    return yearsPeriod
}

interface DisableDateProps {
    selectedDate?: DateProps
    isMore?: boolean
    withTime?: boolean
}

/**
 * Генерация недоступных для выбора дат в календаре
 * @param selectedDate выбранная дата, если параметр не передан, то используется сегодняшняя дата
 * @param isMore если true, то дизейблит даты после выбранной
 * @param withTime если true, то при сравнении дат учитывает текущее время
 */
export const disableDate = ({
    selectedDate,
    isMore,
    withTime,
}: DisableDateProps = {}) => (currentDate: Moment) => {
    const current = currentDate.startOf(withTime ? 's' : 'd')
    const selected = moment(selectedDate).startOf('d')

    return current && isMore ? current > selected : current < selected
}

/**
 * Генерация недоступных для выбора дат в календаре
 * Недоступные дни: ранее выбранной даты
 * @param selectedDate выбранная дата
 * @param isMore если true, то дизейблит даты после выбранной
 */
export const disableDateWhenSelectedDate = (
    selectedDate?: DateProps,
    isMore?: boolean
) => (currentDate?: Moment) => {
    if (!currentDate || !selectedDate) return false

    return disableDate({ selectedDate, isMore })(currentDate)
}

/**
 * Генерация недоступных для выбора дат в календаре
 * Недоступные дни: ранее выбранной даты и после даты, ограничивающей выбор
 * @param currentDate текущая дата
 * @param selectedDate выбранная дата
 * @param endDate
 */
export const disableGroupOfDate = (
    currentDate: Moment,
    selectedDate?: DateProps,
    endDate?: DateProps
) => {
    if (!currentDate) return false

    return (
        disableDate({ selectedDate })(currentDate) ||
        currentDate.startOf('s') >= moment(endDate).startOf('s')
    )
}

/**
 * Считает количество секунд в переданном времени
 * @param timeString строка времени в формате hh:mm:ss
 */
export const timeStringToSecondCount = (timeString: DurationInputArg1) =>
    moment.duration(timeString).asSeconds()

/**
 * Переводит кол-во секунд в формат времени
 * @param seconds количество секунд
 * @returns string возвращает время в формате hh:mm:ss
 */
export const secondCountToTimeString = (seconds?: number) =>
    isNumber(seconds)
        ? moment.utc(seconds * 1000).format(DATE_FORMAT.HH_mm_ss)
        : undefined
