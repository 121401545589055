import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DictionariesStoreProps } from 'core/helpers/DictionariesHelper'
import { LOCAL } from 'core/local'
import { QUESTIONNAIRE_TYPES, QUIZ_TYPES } from 'consts'
import { QuestionnaireSearchResultContract } from 'core/api'
import { WidthRestrictedElement } from 'components/shared'
import { defaultSorter } from 'components/layouts'
import {
    formatDate,
    renderDocumentStatus,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { QuestionnairesTableActions } from './components'

/**
 * настройка отображения колонок таблицы
 */
export const getColumns = ({
    assessmentTypeDictionary,
}: DictionariesStoreProps): ColumnsType<QuestionnaireSearchResultContract> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DOCUMENT_TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type: keyof typeof QUESTIONNAIRE_TYPES) => (
            <WidthRestrictedElement title={QUESTIONNAIRE_TYPES[type]} />
        ),
    },
    {
        title: LOCAL.LABELS.ASSESSMENT_QUIZ_KIND,
        dataIndex: 'assessmentPollType',
        key: 'assessmentPollType',
        render: (assessmentPollType, record) => (
            <WidthRestrictedElement
                title={
                    (record.assessmentType &&
                        assessmentTypeDictionary[record.assessmentType]) ||
                    (record.quizType && QUIZ_TYPES[record.quizType])
                }
            />
        ),
    },
    {
        title: LOCAL.LABELS.COMPETENCE,
        dataIndex: 'competenceName',
        key: 'competenceName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.VERSION,
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderDocumentStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <QuestionnairesTableActions tableRow={tableRow} />
        ),
        fixed: 'right',
    },
]
