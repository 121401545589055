import { TrackStagePassingContract } from 'core/api'

/**
 * Остались ли попытки для прохождения этапа
 * @param attemptsCount - текущее количество прохождений мероприятия
 * @param activityAttemptCount - максимально допустимое количество попыток мероприятия
 */
export const isEnoughAttemptCount = (stage?: TrackStagePassingContract) =>
    !stage?.activityAttemptCount ||
    stage?.activityAttemptCount - stage?.attemptsCount
