import React from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, IconsAdapter, PopupWithForm } from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { NotificationContract } from 'core/api'
import { TableActionsCommonProps } from 'App.types'

import { UserNotifictionsActionsProps } from '../../UserNotifications.types'

export const UserNotificationsTableActions: React.FC<
    TableActionsCommonProps<NotificationContract> & UserNotifictionsActionsProps
> = React.memo(({ tableRow, onSetAsRead }) => (
    <ButtonsToolbar bottomIndent="0">
        {tableRow.unread && (
            <Button
                title={LOCAL.MARK_AS_READ}
                type="link"
                icon={<IconsAdapter iconType="MailOutlined" />}
                onClick={onSetAsRead}
            />
        )}

        <PopupWithForm
            formId={FORM_IDS.CONFIRM_DELETE_FORM}
            buttonText=""
            havePopup={false}
            buttonOption={{
                title: LOCAL.ACTIONS.DELETE,
                type: 'link',
                icon: <IconsAdapter iconType="DeleteOutlined" />,
            }}
            formOptions={{
                initialValues: tableRow,
            }}
            modalOptions={{
                title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                okText: LOCAL.YES,
            }}
        />
    </ButtonsToolbar>
))
