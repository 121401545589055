import React, { FC, useContext } from 'react'
import { Form } from 'antd'
import { isQuestionnaireTypeAssessment } from 'utils'

import {
    EvaluationPassing,
    PortfolioFinalWindow,
    PortfolioReportWindow,
    PortfolioWelcomeWindow,
} from './components'
import { PRESENTATION_FORM_FIELD_NAME } from './PortfolioPresentation.consts'
import { PortfolioFormCommonTabsContext } from '../PortfolioFormCommonTabs.context'

/** Блок настроек оформления для портфелей (оценки, опроса, теста) */
export const PortfolioPresentation: FC = React.memo(() => {
    const { store } = useContext(PortfolioFormCommonTabsContext)

    const { portfolioFormType } = store

    return (
        <Form.Item name={PRESENTATION_FORM_FIELD_NAME} noStyle>
            <PortfolioWelcomeWindow />

            <EvaluationPassing />

            <PortfolioFinalWindow />

            {isQuestionnaireTypeAssessment(portfolioFormType) && (
                <PortfolioReportWindow />
            )}
        </Form.Item>
    )
})
