import React, { FC } from 'react'
import {
    AssessmentPortfolioSearchContract,
    AssessmentPortfolioSearchResultContract,
    AssessmentPortfoliosService,
    UserPermission,
} from 'core/api'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'
import { getRowHandlers } from 'utils'
import { useDictionariesHelper } from 'hooks'

import { AssessmentPortfoliosFilters } from './components'
import { getColumns } from './AssessmentPortfolios.config'

/** Страница реестра портфелей оценок */
export const AssessmentPortfolios: FC = React.memo(() => {
    const locales = useDictionariesHelper(['assessmentTypeDictionary'])

    return (
        <DictionaryPage<
            AssessmentPortfolioSearchContract,
            AssessmentPortfolioSearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.ASSESSMENT_PORTFOLIOS}
            searchMethod={AssessmentPortfoliosService.search}
            deleteMethod={AssessmentPortfoliosService.delete}
            contentTitle={getRouteMeta(
                ROUTE_NAMES.ASSESSMENT_PORTFOLIOS,
                'pageTitle'
            )}
            tableColumns={getColumns(locales)}
            createRecordLink={{
                path: ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_CREATE,
            }}
            filterComponent={AssessmentPortfoliosFilters}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES_WITH_CHANGE_DATE,
                },
            }}
            quickSearchMethod={AssessmentPortfoliosService.searchText}
            createPermissions={[
                UserPermission.AssessmentPortfoliosRead,
                UserPermission.AssessmentPortfolioSave,
            ]}
            rowClassName={getRowClassNameArchive}
            onRow={getRowHandlers({
                url: ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_VIEW,
            })}
        />
    )
})
