import Form from 'antd/lib/form'
import React from 'react'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { NamePath } from 'antd/lib/form/interface'

import { ScoreControl } from '../ScoreControl'
import { TestReviewTableRowChildrenProps } from '../TabReview/TabReview.types'
import { getActionCellFormName } from './ResultScore.utils'

/** Компонент для отображения итоговой оценки за ответы участника теста */
export const ResultScore: React.FC<{
    tableRow: TestReviewTableRowChildrenProps
    name: NamePath
}> = React.memo(({ tableRow, name }) => (
    <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
            const prev = get(
                prevValues,
                getActionCellFormName(tableRow.id, 'questionAnswer')
            )
            const current = get(
                curValues,
                getActionCellFormName(tableRow.id, 'questionAnswer')
            )

            return !isEqual(prev, current)
        }}
    >
        {(form) => {
            const answersStore = get(
                form.getFieldsValue(),
                getActionCellFormName(tableRow.id, 'questionAnswer')
            )

            const answerFieldKeys = Object.keys(answersStore || {})
            const score = answerFieldKeys.reduce(
                (acc, key) => acc + answersStore[key].reviewerEvaluation || 0,
                0
            )

            return (
                <Form.Item name={name}>
                    <ScoreControl score={score} />
                </Form.Item>
            )
        }}
    </Form.Item>
))
