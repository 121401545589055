import React from 'react'
import cn from 'classnames'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import {
    ManagerReportCompetenceResultContract,
    ManagerReportIndicatorResultContract,
} from 'core/api'
import { SpoilerTitleRenderProps } from 'components/shared/Spoiler/Spoiler.types'

import styles from './TablesCompetenciesIndicators.module.scss'
import stylesReportView from '../ReportView/ReportView.module.scss'
import { ReportViewProgress } from '../ReportView/ReportViewProgress'
import { defineColorByValue } from '../ReportView/ReportView.utils'

/**
 * Заголовок спойлера
 * @param value значение компетенции
 */
export const customSpoilerTitleRender = (value: number) => ({
    text,
    icon,
    toggleSpoilerVisible,
}: SpoilerTitleRenderProps) => (
    <>
        <a
            href="#s"
            className={stylesReportView.spoilerHeader}
            onClick={toggleSpoilerVisible}
        >
            {text}

            {icon}
        </a>

        <ReportViewProgress value={(100 * value) / 5} displayValue={value} />
    </>
)

/**
 * Рендер ячейки таблицы со значением индикатора
 * @param hasDataProfile наличие профиля должности
 */
const renderCellValue = (hasDataProfile?: boolean) => (
    value: number,
    record: ManagerReportIndicatorResultContract
) => (
    <span
        className={cn(
            !value && styles.empty,
            hasDataProfile &&
                record.positionProfileLevel > value &&
                styles.circle
        )}
        style={{
            color: value ? defineColorByValue(value) : undefined,
        }}
    >
        {value || 'X'}
    </span>
)

/** Настройка столбцов таблицы
 * @param data данные для таблицы
 * */
export const getTableColumnsOptions = (
    data: ManagerReportIndicatorResultContract[]
): ColumnsType<ManagerReportIndicatorResultContract> => {
    const hasDataProfile = data.some((el) => el.positionProfileLevel)

    const columnsSet: ColumnsType<ManagerReportIndicatorResultContract> = [
        {
            dataIndex: 'indicatorName',
            key: 'indicatorName',
            width: 400,
        },
        {
            title: LOCAL.LABELS.EMPLOYEE_ASSESSMENT_BY_MANAGER,
            dataIndex: 'managerResult',
            key: 'managerResult',
            align: 'center',
            render: renderCellValue(hasDataProfile),
            width: 'auto',
        },
        {
            title: LOCAL.LABELS.EMPLOYEE_ASSESSMENT_SELF,
            dataIndex: 'selfAssessmentResult',
            key: 'selfAssessmentResult',
            align: 'center',
            render: renderCellValue(hasDataProfile),
            width: 'auto',
        },
    ]

    if (!hasDataProfile) {
        return columnsSet
    }

    return [
        ...columnsSet,
        {
            title: LOCAL.LABELS.POSITION_PROFILE,
            dataIndex: 'positionProfileLevel',
            key: 'positionProfileLevel',
            align: 'center',
            render: renderCellValue(),
            width: 'auto',
        },
    ]
}

/** Настройка данных для таблицы
 * @param data данные для таблицы
 * */
export const createDataSource = ({
    managerResult,
    selfAssessmentResult,
    positionProfileLevel,
    indicatorResults,
}: ManagerReportCompetenceResultContract) => [
    {
        indicatorId: 0,
        indicatorName: LOCAL.LABELS.MIDDLE_COMPETENCE_SCORE,
        managerResult,
        selfAssessmentResult,
        positionProfileLevel,
    } as ManagerReportIndicatorResultContract,
    ...indicatorResults,
]
