import cn from 'classnames'
import shortid from 'shortid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { CoworkersFilters } from 'components/controls/SearchSelectControl/components/filters/CoworkersFilters'
import { FORM_IDS } from 'core/configs'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { SearchTableShowButton } from 'components/controls/SearchSelectControl/components/SearchTableShowButton/SearchTableShowButton'
import { TableAdapter } from 'components/shared/TableAdapter'
import {
    UserModalContract,
    UserModalSearchContract,
    UserSelectedInModalContract,
    UsersService,
} from 'core/api'
import { getCoworkersTableColumns } from 'components/controls/SearchSelectControl/components/TableSearchForm/TableSearchForm.consts'
import { getFullFio, isFormModeView } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './DynamicFormRows.module.scss'
import {
    DynamicFormRowsProps,
    MembersTableItemProps,
} from './DynamicFormRows.types'
import { getTableColumns } from './DynamicFormRows.utils'

/**
 * Компонент для создания и управления динамическими рядом пользователей внутри форм
 */
export const DynamicFormRows: React.FC<DynamicFormRowsProps> = React.memo(
    ({
        value,
        onChange,
        addBtnText,
        formMode,
        modalTitle = LOCAL.LABELS.EMPLOYEE_SELECTION,
        structuralUnitsIds,
        disabled,
    }) => {
        const [selectedMembers, setSelectedMembers] = useState<number[]>()
        const [membersRawData, setMembersRawData] = useState<
            UserSelectedInModalContract[]
        >()
        const {
            updateElementVisible: updateLoader,
            elementVisible: isLoading,
        } = useElementVisible()

        /**
         * Вычисленное значение для кастомного поля
         */
        const calculatedValue = useMemo(() => value || selectedMembers, [
            selectedMembers,
            value,
        ])

        /**
         * Обновить значение в стейте и внешней форме
         * @param {number[]} newValue новое значение
         * @returns {void}
         */
        const triggerChanges = useCallback(
            (newValue?: number[]) => {
                setSelectedMembers(newValue)
                onChange?.(newValue)
            },
            [onChange]
        )

        /**
         * Обработчик добавления выбранных лиц из таблицы сотрудников
         */
        const handleSelectRespondents = useCallback(
            (result?: number[]) => {
                if (result?.length) {
                    triggerChanges(result)
                }
            },
            [triggerChanges]
        )

        /**
         * Обработчик удаления сотрудника
         * @param {number} memberId идентификатор сотрудника
         * @returns {void}
         */
        const handleRemoveMember = useCallback(
            (memberId: number) => () => {
                triggerChanges(calculatedValue?.filter((id) => id !== memberId))
            },
            [calculatedValue, triggerChanges]
        )

        /**
         * Получить колонку действий для таблицы выбранных сотрудников
         * @param {MembersTableItemProps} tableRow - данные по текущему ряду в таблице
         * @returns {ReactNode} - колонка действий
         */
        const getTableActions = (tableRow: MembersTableItemProps) => (
            <ButtonsToolbar bottomIndent="0" align="center">
                <Button
                    title={LOCAL.ACTIONS.DELETE}
                    type="link"
                    icon={
                        <IconsAdapter
                            iconType="CustomIconTrashAlt"
                            className={styles.icon}
                            onClick={handleRemoveMember(tableRow.id)}
                        />
                    }
                    disabled={isFormModeView(formMode) || disabled}
                />
            </ButtonsToolbar>
        )

        /**
         * Данные для рендера списка выбранных сотрудников
         */
        const membersDataSource = useMemo(
            () =>
                membersRawData
                    ?.map(({ id, userStructuralUnitPositions, ...restProps }) =>
                        userStructuralUnitPositions.map((unit, i) => ({
                            id,
                            key: shortid.generate(),
                            fio: getFullFio(restProps),
                            rowSpan: i ? 0 : userStructuralUnitPositions.length,
                            position: unit.position.name,
                            structuralUnit: {
                                unit: unit.unit?.name,
                                organization: unit.organization?.name,
                            },
                        }))
                    )
                    .flat(),
            [membersRawData]
        )

        /**
         * Запрос дополнительной информации из справочника пользователей по идентификаторам
         */
        useEffect(() => {
            const dictionaryFetch = async (request: number[]) => {
                try {
                    updateLoader(true)

                    setMembersRawData(
                        await UsersService.getBySelectedInModalIds({
                            body: request,
                        })
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }

            if (calculatedValue) dictionaryFetch(calculatedValue)
        }, [calculatedValue, updateLoader])

        const showTable = !!(calculatedValue?.length && membersDataSource)

        return (
            <>
                <div className={cn(showTable && styles.table)}>
                    {showTable ? (
                        <TableAdapter
                            columns={getTableColumns(getTableActions)}
                            dataSource={membersDataSource}
                            pagination={false}
                            rowKey="key"
                        />
                    ) : null}
                </div>

                <div className={styles.actions}>
                    <SearchTableShowButton<
                        UserModalSearchContract,
                        UserModalContract
                    >
                        tableSearchOptions={{
                            formId: FORM_IDS.SEARCH_RESPONDENTS,
                            rowSelectionType: 'checkbox',
                            searchMethod: UsersService.getForModal,
                            tableColumns: getCoworkersTableColumns(),
                            filterComponent: CoworkersFilters,
                            onSelectTableItem: handleSelectRespondents,
                            initialValues: calculatedValue,
                            tableFiltersOptions: {
                                initialValues: {
                                    structuralUnitsIds,
                                },
                            },
                        }}
                        modalTitle={modalTitle}
                    >
                        <AddButton
                            buttonText={addBtnText}
                            disabled={
                                isFormModeView(formMode) ||
                                isLoading ||
                                disabled
                            }
                            loading={isLoading}
                        />
                    </SearchTableShowButton>
                </div>
            </>
        )
    }
)
