import React, { useCallback, useContext, useEffect } from 'react'
import { AddOrganizationControl } from 'components/controls/AddOrganizationControl'
import { AssessmentPortfolioDictionariesService } from 'core/api'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    HiddenField,
    InputControl,
    RadioButtonControl,
    SelectControl,
    ShouldUpdateChecker,
    TextAreaControl,
    UploadAttachments,
} from 'components/controls'
import { DEPRECATED_ASSESSMENT_TYPES, ROW_GUTTER } from 'consts'
import { DictionariesCommonProps } from 'App.types'
import { LOCAL } from 'core/local'
import { PortfolioFormCommonTabsContext } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import { TrackPassingContext } from 'components/pages/TrackPreviewPassing/TrackPassing.context'
import {
    excludeSelectOptions,
    isAssessmentTypeCircle,
    isAssessmentTypeManager,
    isFormModeEdit,
    normalizeDictionaryToControl,
} from 'utils'
import { useDictionaries, useDictionariesHelper } from 'hooks'

import { AssessmentPortfolioSettingsProps } from './AssessmentPortfolioSettings.types'
import { CircleParameters } from '../CircleParameters'
import { FORM_FIELD_PATHS } from '../AssessmentTemplateForm/AssessmentTemplateForm.config'
import { ManagerParameters } from '../ManagerParameters'

/** Вкладка настроек формы создания портфеля оценки */
export const AssessmentPortfolioSettings: React.FC<AssessmentPortfolioSettingsProps> = React.memo(
    ({ assessmentType, staffFieldsStatus, updateLoader }) => {
        const { assessmentTypeDictionary } = useDictionariesHelper([
            'assessmentTypeDictionary',
        ])

        const { store } = useContext(TrackPassingContext)

        const { store: portfolioFormCommonTabsStore } = useContext(
            PortfolioFormCommonTabsContext
        )

        const {
            formMode,
            form,
            disabledByStatus,
        } = portfolioFormCommonTabsStore

        const { dictionaries, handleFetchDictionaries } = useDictionaries<
            DictionariesCommonProps<'assessmentForms' | 'assessmentPurposes'>
        >({ updateLoader: store.updateLoader })

        /**
         * Запрос справочников
         */
        const dictionaryFetch = useCallback(() => {
            handleFetchDictionaries({
                assessmentForms: AssessmentPortfolioDictionariesService.getAssessmentForms(),
                assessmentPurposes: AssessmentPortfolioDictionariesService.getAll(),
            })
        }, [handleFetchDictionaries])

        useEffect(() => {
            dictionaryFetch()
        }, [dictionaryFetch])

        return (
            <>
                <HiddenField fieldName="id" />

                <ControlGroup
                    title={LOCAL.LABELS.ASSESSMENT_PORTFOLIO}
                    noPadding
                >
                    <Row gutter={ROW_GUTTER}>
                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName={FORM_FIELD_PATHS.type}
                                label={LOCAL.LABELS.ASSESSMENT_TYPE}
                                required
                            >
                                <RadioButtonControl
                                    values={excludeSelectOptions(
                                        normalizeDictionaryToControl(
                                            assessmentTypeDictionary
                                        ),
                                        DEPRECATED_ASSESSMENT_TYPES
                                    )}
                                    disabled={
                                        isFormModeEdit(formMode) ||
                                        !!assessmentType
                                    }
                                    formMode={formMode}
                                    buttonStyle="solid"
                                />
                            </FormItemAdapter>
                        </Col>
                    </Row>

                    <Row gutter={ROW_GUTTER}>
                        <Col xs={8}>
                            <Row>
                                <Col xs={24}>
                                    <FormItemAdapter
                                        fieldName="name"
                                        label={LOCAL.LABELS.NAME}
                                        required
                                    >
                                        <InputControl formMode={formMode} />
                                    </FormItemAdapter>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24}>
                                    <FormItemAdapter
                                        fieldName="purposeId"
                                        label={LOCAL.LABELS.ASSESSMENT_GOAL}
                                        required
                                    >
                                        <SelectControl
                                            values={
                                                dictionaries?.assessmentPurposes ||
                                                []
                                            }
                                            formMode={formMode}
                                            disabled={disabledByStatus}
                                        />
                                    </FormItemAdapter>
                                </Col>
                            </Row>

                            <ShouldUpdateChecker
                                fieldPath={FORM_FIELD_PATHS.type}
                            >
                                {({ getFieldValue }) =>
                                    isAssessmentTypeCircle(
                                        getFieldValue(FORM_FIELD_PATHS.type)
                                    ) && (
                                        <Row>
                                            <Col xs={24}>
                                                <FormItemAdapter
                                                    fieldName="structuralUnitIds"
                                                    label={
                                                        LOCAL.LABELS
                                                            .ORGANIZATIONS
                                                    }
                                                >
                                                    <AddOrganizationControl
                                                        formMode={formMode}
                                                        disabled={
                                                            form?.getFieldValue(
                                                                'assessmentPortfolioId'
                                                            ) ||
                                                            disabledByStatus
                                                        }
                                                        withSwitch
                                                    />
                                                </FormItemAdapter>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </ShouldUpdateChecker>
                        </Col>

                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="description"
                                label={LOCAL.LABELS.DESCRIPTION}
                            >
                                <TextAreaControl
                                    autoSize={{ minRows: 4 }}
                                    formMode={formMode}
                                />
                            </FormItemAdapter>

                            <UploadAttachments
                                name="attachmentIds"
                                form={form}
                                formMode={formMode}
                                disabled={disabledByStatus}
                                updateLoader={updateLoader}
                            />
                        </Col>
                    </Row>
                </ControlGroup>

                <ShouldUpdateChecker fieldPath={FORM_FIELD_PATHS.type}>
                    {(form) => {
                        const type = form.getFieldValue(FORM_FIELD_PATHS.type)

                        return (
                            <>
                                {isAssessmentTypeCircle(type) && (
                                    <CircleParameters
                                        formMode={formMode}
                                        staffFieldsStatus={staffFieldsStatus}
                                        disabled={disabledByStatus}
                                        form={form}
                                    />
                                )}

                                {isAssessmentTypeManager(type) && (
                                    <ManagerParameters
                                        formMode={formMode}
                                        disabled={disabledByStatus}
                                    />
                                )}
                            </>
                        )
                    }}
                </ShouldUpdateChecker>
            </>
        )
    }
)
