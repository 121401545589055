import last from 'lodash/last'
import { Resolutions } from 'core/configs'
import {
    getBackgroundImageStyle,
    getResolution,
    isAppointmentTrackStatusPassed,
} from 'utils'
import { useEffect, useState } from 'react'
import { useXScroll } from 'hooks'

import { UsePrepareLayerProps } from './TrackLayerPassing.types'
import { mapTrackVisualisation } from '../../../Track/components/TrackForm/TrackForm.utils'

export const usePrepareLayer = ({ trackInfo }: UsePrepareLayerProps) => {
    const [resolution, setResolution] = useState(Resolutions.XXL)
    const [resolutionForTrack, setResolutionForTrack] = useState(
        Resolutions.XXL
    )
    const { containerWithXScroll } = useXScroll()

    const trackCanvases = mapTrackVisualisation(trackInfo?.trackCanvases)

    const getCanvasBg = () => {
        const defaultCanvasBg =
            trackCanvases?.[resolution]?.meta?.currentBackgroundUrl

        const passedStages = trackInfo?.stages?.filter(({ status }) =>
            isAppointmentTrackStatusPassed(status)
        )

        if (passedStages?.length) {
            passedStages?.sort((prev, next) => {
                const prevDate = +new Date(prev.passingDate)
                const nextDate = +new Date(next.passingDate)

                return prevDate - nextDate
            })

            const lastOfPassedStages = last(passedStages)

            const stageStyle = trackCanvases?.[
                resolution
            ]?.trackStageStyles?.find(
                (el) => el?.stageNumber === lastOfPassedStages?.stageNumber
            )

            return (
                stageStyle?.icons.passing.iconBackground?.url || defaultCanvasBg
            )
        }

        return defaultCanvasBg
    }

    const trackCanvasStyles = {
        ...getBackgroundImageStyle(getCanvasBg()),
        width: trackCanvases?.[resolution]?.width || 'auto',
        height: trackCanvases?.[resolution]?.height || '100%',
    }

    const trackContainerStyles = {
        ...getBackgroundImageStyle(trackInfo?.trackBackgroundPicture?.url),
        backgroundSize: 'cover',
    }

    useEffect(() => {
        function handleWindowResize() {
            const resolutions = trackInfo?.trackCanvases?.map((canvas) =>
                String(canvas.screenResolution)
            )

            setResolution(
                getResolution(window.innerWidth, resolutions) as Resolutions
            )

            setResolutionForTrack(
                getResolution(window.innerWidth) as Resolutions
            )
        }

        handleWindowResize()

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [trackInfo])

    return {
        trackCanvases,
        trackCanvasStyles,
        trackContainerStyles,
        containerWithXScroll,
        resolution,
        resolutionForTrack,
    }
}
