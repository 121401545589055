import React from 'react'
import {
    ActivitiesSearchContract,
    ActivitiesService,
    ActivitySearchResultContract,
    UserPermission,
} from 'core/api'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import { EVENTS_REQUIRED_PERMISSIONS } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'
import { getRowHandlers } from 'utils'

import { EVENT_TABLE_COLUMNS } from './EventCatalog.consts'
import { EventsFilters } from './components'

/** Компонент отображения каталога (перечня) мероприятий */
export const EventCatalog: React.FC = React.memo(() => (
    <DictionaryPage<ActivitiesSearchContract, ActivitySearchResultContract>
        dictionaryKey={DICTIONARY_KEYS.EVENT_CATALOG}
        searchMethod={ActivitiesService.search}
        deleteMethod={ActivitiesService.setAsArchive}
        contentTitle={getRouteMeta(ROUTE_NAMES.EVENTS)}
        tableColumns={EVENT_TABLE_COLUMNS}
        createRecordLink={{
            path: ROUTE_NAMES.EVENTS_CREATE,
        }}
        filterComponent={EventsFilters}
        tableFiltersOptions={{
            initialValues: {
                orderRules: ORDER_RULES_WITH_CHANGE_DATE,
            },
        }}
        quickSearchMethod={ActivitiesService.searchText}
        createPermissions={[
            ...EVENTS_REQUIRED_PERMISSIONS,
            UserPermission.EventSave,
        ]}
        rowClassName={getRowClassNameArchive}
        onRow={getRowHandlers({ url: ROUTE_NAMES.EVENTS_VIEW })}
    />
))
