import React, { useEffect } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { MissionContract, MissionsService, UserPermission } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsForEditProps, useFormInitialValues } from 'hooks'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import { isFormModeEdit, isFormModeView } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useParams } from 'react-router-dom'

import { INITIAL_VALUES } from './MaterialTasks.consts'
import { MaterialsTaskForm } from './components'

/** Стр. задания */
const MaterialsTaskDetail: React.FC<
    WithBlockUserRoutingComponentProps & ComponentCommonProps
> = withLoader(({ onBlockUserRouting, formMode, isLoading, updateLoader }) => {
    const urlParams = useParams<UrlParamsForEditProps>()

    const {
        initialValuesForEdit,
        fetchInitialFormValuesById,
    } = useFormInitialValues<MissionContract>({
        requestMethod: MissionsService.getById,
        updateLoader,
    })

    /**
     * Запрашиваем initial values для формы при наличии id
     */
    useEffect(() => {
        if (urlParams.id) fetchInitialFormValuesById(Number(urlParams.id))
    }, [fetchInitialFormValuesById, urlParams.id])

    return (
        <PageContent>
            <ContentTitleWithBackLink>
                <ButtonsToolbar>
                    <Button type="link" href={ROUTE_NAMES.TASKS}>
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    <HasPermissions
                        requiredPermissions={[UserPermission.TextDocumentSave]}
                        accessDeniedRender={renderAccessDeniedButton({
                            text: LOCAL.ACTIONS.SAVE,
                            type: 'primary',
                        })}
                    >
                        <Button
                            form={FORM_IDS.MATERIALS_TASK_FORM}
                            type="primary"
                            htmlType="submit"
                            disabled={isFormModeView(formMode) || isLoading}
                        >
                            {LOCAL.ACTIONS.SAVE}
                        </Button>
                    </HasPermissions>
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <MaterialsTaskForm
                updateLoader={updateLoader}
                onBlockUserRouting={onBlockUserRouting}
                initialValuesForEdit={
                    isFormModeEdit(formMode)
                        ? initialValuesForEdit
                        : INITIAL_VALUES
                }
                reFetchInitialFormValues={fetchInitialFormValuesById}
                formMode={formMode}
            />
        </PageContent>
    )
})

export default React.memo(
    withBlockUserRouting(MaterialsTaskDetail, false)
) as typeof MaterialsTaskDetail
