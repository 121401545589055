import { eventEmitter } from 'core/helpers/eventEmitter'

import { InfoAssistantEventsProps } from './TrackPassing.types'

export const INFO_ASSISTANT_EVENT = {
    STAGE_START: 'STAGE_START',
    STAGE_END: 'STAGE_END',
    TRACK_START: 'TRACK_START',
    TRACK_OPEN: 'TRACK_OPEN',
    TRACK_END: 'TRACK_END',
}

// TODO: https://jira.it2g.ru/browse/SDO-5024
export const registerInfoAssistantEvents = ({
    onFinishStage,
    onFinishTrack,
}: InfoAssistantEventsProps) => {
    eventEmitter.on(INFO_ASSISTANT_EVENT.STAGE_END, onFinishStage)
    eventEmitter.on(INFO_ASSISTANT_EVENT.TRACK_END, onFinishTrack)
}

export const unRegisterInfoAssistantEvents = ({
    onFinishStage,
    onFinishTrack,
}: InfoAssistantEventsProps) => {
    eventEmitter.off(INFO_ASSISTANT_EVENT.STAGE_END, onFinishStage)
    eventEmitter.off(INFO_ASSISTANT_EVENT.TRACK_END, onFinishTrack)
}
