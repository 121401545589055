import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
    DictionaryContract,
    PositionProfileCompetenceFromFileContract,
    PositionProfileFromFileContract,
    PositionProfileImportState,
    PositionProfileType,
} from 'core/api'
import { LOCAL } from 'core/local'
import { MAKE_ORDER_UNIT_LABEL } from 'core/local/messages.local.config'
import { PROFILE_TYPES } from 'consts'
import {
    renderElementWithListPopover,
    renderEllipsisWithPopover,
    renderOrderNumber,
    renderStringArrayWithSeparator,
} from 'utils'

const STATE_LABELS = {
    [PositionProfileImportState.ExistsAndNotUpdatable]: {
        label: LOCAL.LABELS.POSITION_PROFILE_STATE_NOT_UPDATABLE,
        color: '--job_profiles_status_not_updatable',
    },
    [PositionProfileImportState.ExistsAndUpdatable]: {
        label: LOCAL.LABELS.POSITION_PROFILE_STATE_UPDATABLE,
        color: '--green1',
    },
    [PositionProfileImportState.HasErrors]: {
        label: LOCAL.LABELS.POSITION_PROFILE_STATE_HAS_ERRORS,
        color: '--red1',
    },
    [PositionProfileImportState.New]: {
        label: LOCAL.LABELS.POSITION_PROFILE_STATE_NEW,
        color: '--job_profiles_status_new',
    },
}

/**
 * Отображение колонок для страницы импорта ПД
 */
export const JOB_PROFILES_IMPORT_COLUMNS: ColumnsType<PositionProfileFromFileContract> = [
    {
        title: LOCAL.LABELS.NUMBER,
        render: renderOrderNumber,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'state',
        key: 'state',
        render: (state: PositionProfileImportState) => (
            <span style={{ color: `var(${STATE_LABELS[state].color})` }}>
                {STATE_LABELS[state].label}
            </span>
        ),
    },
    {
        title: LOCAL.LABELS.POSITION_PROFILE_ID,
        dataIndex: 'importId',
        key: 'importId',
    },
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'position',
        key: 'position',
    },
    {
        title: LOCAL.LABELS.COMPLEX,
        dataIndex: 'supervisingComplex',
        key: 'supervisingComplex',
    },
    {
        title: LOCAL.LABELS.OIV,
        dataIndex: 'oiv',
        key: 'oiv',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organization',
        key: 'organization',
    },
    {
        title: MAKE_ORDER_UNIT_LABEL(1),
        dataIndex: 'firstOrderUnit',
        key: 'firstOrderUnit',
    },
    {
        title: MAKE_ORDER_UNIT_LABEL(2),
        dataIndex: 'secondOrderUnit',
        key: 'secondOrderUnit',
    },
    {
        title: MAKE_ORDER_UNIT_LABEL(3),
        dataIndex: 'thirdOrderUnit',
        key: 'thirdOrderUnit',
    },
    {
        title: `${LOCAL.LABELS.GGS}/${LOCAL.LABELS.RBS}`,
        dataIndex: 'type',
        key: 'type',
        render: (type: PositionProfileType) => PROFILE_TYPES[type],
    },
    {
        title: LOCAL.LABELS.SUBORDINATED_TO,
        dataIndex: 'subordination',
        key: 'subordination',
    },
    {
        title: LOCAL.LABELS.LEVEL_OF_POSITIONS,
        dataIndex: 'level',
        key: 'level',
    },
    {
        title: LOCAL.LABELS.RISK_DISMISSAL,
        dataIndex: 'dismissalRisk',
        key: 'dismissalRisk',
    },
    {
        title: LOCAL.LABELS.CORRUPTION_RISK,
        dataIndex: 'corruptionRisk',
        key: 'corruptionRisk',
    },
    {
        title: LOCAL.LABELS.ADMISSION_FORM,
        dataIndex: 'accessForm',
        key: 'accessForm',
    },
    {
        title: LOCAL.LABELS.FUNCTIONAL_RESPONSIBILITIES,
        dataIndex: 'functionalResponsibilities',
        key: 'functionalResponsibilities',
        render: renderEllipsisWithPopover(),
    },
    {
        title: LOCAL.LABELS.KEY_PERFORMANCE_INDICATORS,
        dataIndex: 'performanceIndicatorsDescription',
        key: 'performanceIndicatorsDescription',
    },
    {
        title: `${LOCAL.LABELS.REQUIRED_EXPERIENCE}. ${LOCAL.LABELS.BRANCH}`,
        dataIndex: 'requiredExperienceIndustry',
        key: 'requiredExperienceIndustry',
    },
    {
        title: `${LOCAL.LABELS.REQUIRED_EXPERIENCE}. ${LOCAL.LABELS.EXPERIENCE}`,
        dataIndex: 'requiredExperience',
        key: 'requiredExperience',
    },
    {
        title: `${LOCAL.LABELS.REQUIRED_EXPERIENCE}. ${LOCAL.LABELS.EXPERIENCE_ON_GGS}`,
        dataIndex: 'governmentExperience',
        key: 'governmentExperience',
    },
    {
        title: `${LOCAL.LABELS.REQUIRED_EXPERIENCE}. ${LOCAL.LABELS.NOTE}`,
        dataIndex: 'requiredExperienceDescription',
        key: 'requiredExperienceDescription',
    },
    {
        title: `${LOCAL.LABELS.MANAGERIAL_EXPERIENCE}. ${LOCAL.LABELS.EXPERIENCE}`,
        dataIndex: 'manageExperience',
        key: 'manageExperience',
    },
    {
        title: `${LOCAL.LABELS.MANAGERIAL_EXPERIENCE}. ${LOCAL.LABELS.SUBORDINATES_COUNT}`,
        dataIndex: 'manageExperienceSubordinatesNumber',
        key: 'manageExperienceSubordinatesNumber',
    },
    {
        title: `${LOCAL.LABELS.MANAGERIAL_EXPERIENCE}. ${LOCAL.LABELS.BUDGET_IN_MANAGEMENT}`,
        dataIndex: 'manageExperienceBudget',
        key: 'manageExperienceBudget',
    },
    {
        title: `${LOCAL.LABELS.MANAGERIAL_EXPERIENCE}. ${LOCAL.LABELS.BRANCH}`,
        dataIndex: 'manageExperienceIndustry',
        key: 'manageExperienceIndustry',
    },
    {
        title: LOCAL.LABELS.ADDITIONAL_REQUIREMENTS,
        dataIndex: 'additionalRequirements',
        key: 'additionalRequirements',
    },
    {
        title: `${LOCAL.LABELS.ACTIVITY_TYPE} ${LOCAL.LABELS.POSITION_PROFILE_SHORT}`,
        dataIndex: 'functionalClasses',
        key: 'functionalClasses',
        render: (functionalClasses: DictionaryContract[]) =>
            renderElementWithListPopover(functionalClasses, { showDots: true }),
    },
    {
        title: `${LOCAL.LABELS.EDUCATION_LEVEL} ${LOCAL.LABELS.POSITION_PROFILE_SHORT}`,
        dataIndex: 'educationLevels',
        key: 'educationLevels',
        render: (educationLevels: DictionaryContract[]) =>
            renderElementWithListPopover(educationLevels, { showDots: true }),
    },
    {
        title: `${LOCAL.LABELS.EDUCATION_PROFILE} ${LOCAL.LABELS.POSITION_PROFILE_SHORT}`,
        dataIndex: 'educationProfiles',
        key: 'educationProfiles',
        render: (educationProfiles: DictionaryContract[]) =>
            renderElementWithListPopover(educationProfiles, { showDots: true }),
    },
    {
        title: LOCAL.LABELS.COMPETENCIES,
        dataIndex: 'competences',
        key: 'competences',
        render: (competences: PositionProfileCompetenceFromFileContract[]) =>
            renderElementWithListPopover(
                competences.map(
                    ({ competence }) =>
                        ({
                            name: competence,
                        } as DictionaryContract)
                ),
                { showDots: true }
            ),
    },
    {
        title: LOCAL.LABELS.ERRORS,
        dataIndex: 'validationErrors',
        key: 'validationErrors',
        render: (validationErrors) =>
            renderStringArrayWithSeparator(validationErrors),
    },
]
