import React, { FC } from 'react'
import {
    COMPETENCIES_REQUIRED_PERMISSIONS,
    JOB_PROFILES_REQUIRED_PERMISSIONS,
} from 'consts'
import {
    CompetenceFormContainer,
    Competencies,
    CompetenciesGroups,
    CompetenciesIndicators,
    CompetenciesOrderOptions,
    ErrorPage,
    JobProfileFormContainer,
    JobProfiles,
} from 'components/pages'
import { JobProfilesImport } from 'components/pages/JobProfiles/JobProfilesImport'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

export const ProfilingRouting: FC = React.memo(() => (
    <Switch>
        <ProtectedRoute
            requiredPermissions={JOB_PROFILES_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.POSITION_PROFILES_VIEW}/:profileId/:archives/:version`}
        >
            <JobProfileFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={JOB_PROFILES_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.POSITION_PROFILES_VIEW}/:profileId`}
        >
            <JobProfileFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...JOB_PROFILES_REQUIRED_PERMISSIONS,
                UserPermission.PositionProfileSave,
            ]}
            path={`${ROUTE_NAMES.POSITION_PROFILES_COPY}/:profileId`}
        >
            <JobProfileFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...JOB_PROFILES_REQUIRED_PERMISSIONS,
                UserPermission.PositionProfileSave,
            ]}
            path={`${ROUTE_NAMES.POSITION_PROFILES_EDIT}/:profileId`}
        >
            <JobProfileFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...JOB_PROFILES_REQUIRED_PERMISSIONS,
                UserPermission.PositionProfileSave,
            ]}
            path={`${ROUTE_NAMES.POSITION_PROFILES_CREATE}/:structuralUnitId/:structuralUnitType?/:positionId?`}
        >
            <JobProfileFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...JOB_PROFILES_REQUIRED_PERMISSIONS,
                UserPermission.PositionProfileSave,
            ]}
            path={ROUTE_NAMES.POSITION_PROFILES_CREATE}
        >
            <JobProfileFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...JOB_PROFILES_REQUIRED_PERMISSIONS,
                UserPermission.PositionProfileSave,
            ]}
            path={ROUTE_NAMES.POSITION_PROFILES_IMPORT}
        >
            <JobProfilesImport />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.PositionProfilesRead,
            ]}
            path={ROUTE_NAMES.POSITION_PROFILES}
        >
            <JobProfiles />
        </ProtectedRoute>

        <Route path={ROUTE_NAMES.COMPETENCIES_ORDER_OPTIONS}>
            <CompetenciesOrderOptions />
        </Route>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.IndicatorRead,
            ]}
            path={ROUTE_NAMES.COMPETENCIES_INDICATORS}
        >
            <CompetenciesIndicators />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.CompetenceGroupRead,
            ]}
            path={ROUTE_NAMES.COMPETENCIES_GROUPS}
        >
            <CompetenciesGroups />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={COMPETENCIES_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.COMPETENCIES_VIEW}/:id`}
        >
            <CompetenceFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...COMPETENCIES_REQUIRED_PERMISSIONS,
                UserPermission.CompetenceSave,
            ]}
            path={`${ROUTE_NAMES.COMPETENCIES_EDIT}/:id`}
        >
            <CompetenceFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...COMPETENCIES_REQUIRED_PERMISSIONS,
                UserPermission.CompetenceSave,
            ]}
            path={ROUTE_NAMES.COMPETENCIES_CREATE}
        >
            <CompetenceFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.CompetenciesRead,
            ]}
            path={ROUTE_NAMES.COMPETENCIES}
        >
            <Competencies />
        </ProtectedRoute>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
