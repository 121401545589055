import React, { useCallback, useEffect, useState } from 'react'
import { CatalogItemProductCardPreviewContract, CatalogService } from 'core/api'
import { PageContent, PlayerIframe } from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsForEditProps } from 'hooks'
import { getMaterialLaunchUrl, updateIframeNextButton } from 'utils'
import { useHistory, useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './ProductCard.module.scss'

/** Компонент страницы курса карточки продукта */
export const ProductCardCourse: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const urlParams = useParams<UrlParamsForEditProps>()
        const [pageInfo, setPageInfo] = useState<
            CatalogItemProductCardPreviewContract
        >()
        const { push } = useHistory()

        /** Запросить данные о карточке продукта */
        const fetchPageInfo = useCallback(async () => {
            try {
                updateLoader(true)
                const dataSource = await CatalogService.getCatalogProductCardPreviewAsync(
                    {
                        productCardId: Number(urlParams.id),
                    }
                )
                setPageInfo(dataSource)
            } catch (e) {
                console.error(e)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader, urlParams.id])

        /** Запрашиваем данные для отображения страницы */
        useEffect(() => {
            if (!urlParams.id && !Number(urlParams.id)) return

            fetchPageInfo()
        }, [fetchPageInfo, urlParams.id])

        /** Обработка кнопки "далее" на курсе */
        const handleNextButtonClick = useCallback(() => {
            if (pageInfo) {
                push(`${ROUTE_NAMES.PRODUCT_CARD_VIEW}/${pageInfo.id}`)
            }
        }, [pageInfo, push])

        /** Обработка загрузки айфрейма */
        const handleIframeLoad = useCallback(
            (iframe: HTMLIFrameElement) => {
                updateIframeNextButton(iframe, handleNextButtonClick)
            },
            [handleNextButtonClick]
        )

        return (
            <PageContent className={styles.wrapper} useHeightStyleForResize>
                {pageInfo && (
                    <PlayerIframe
                        fileUrl={getMaterialLaunchUrl(
                            pageInfo.course.launchUrl
                        )}
                        format={pageInfo.course.format}
                        onLoad={handleIframeLoad}
                        enableLoader
                    />
                )}
            </PageContent>
        )
    })
)
