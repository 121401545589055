import { UserPermission } from 'core/api'

/** Набор прав доступа обязательных для совершения любых действий внутри назначения оценки */
export const ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.AssessmentAppointmentsRead,
]

/** Набор дополнительных прав доступа для совершения любых действий внутри назначения оценки */
export const ASSESSMENT_ASSIGNMENT_CONDITIONAL_PERMISSIONS = [
    UserPermission.AssessmentAppointmentManagerRead,
    UserPermission.AssessmentAppointmentExpertRead,
    UserPermission.AssessmentAppointment180270360Read,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра портфелей оценки */
export const ASSESSMENT_PORTFOLIOS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.AssessmentPortfoliosRead,
]

/** Набор дополнительных прав доступа для совершения любых действий внутри реестра портфелей оценки */
export const ASSESSMENT_PORTFOLIOS_CONDITIONAL_PERMISSIONS = [
    UserPermission.AssessmentPortfolioManagerRead,
    UserPermission.AssessmentPortfolioExpertRead,
    UserPermission.AssessmentPortfolio180270360Read,
]

/** Набор прав доступа обязательных для совершения любых действий внутри оценочных проектов */
export const ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.AssessmentProjectsRead,
]

/** Набор дополнительных прав доступа для совершения любых действий внутри оценочных проектов */
export const ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS = [
    UserPermission.AssessmentProject360Read,
    UserPermission.AssessmentProjectManagerRead,
]

/** Набор прав доступа для реестра сертификатов */
export const CERTIFICATE_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.UsersRead,
    UserPermission.UsersProfileRead,
    UserPermission.CertificateTemplateSave,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра компетенций */
export const COMPETENCIES_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.CompetenciesRead,
    UserPermission.CompetenceRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра шкал */
export const CRITERIA_SCALES_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.ScalesRead,
    UserPermission.ScaleRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра целей развития */
export const DEVELOPMENT_GOALS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.PurposesRead,
    UserPermission.PurposeRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри тегов рассылки */
export const DISTRIBUTION_TAGS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.NotificationTagsRead,
    UserPermission.NotificationTagRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра шаблонов */
export const DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.NotificationNewsletterTemplatesRead,
    UserPermission.NotificationNewsletterTemplateRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра документов редактора */
export const DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.TextDocumentsRead,
    UserPermission.TextDocumentRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра мероприятий */
export const EVENTS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.EventsRead,
    UserPermission.EventRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра назначения мероприятия */
export const EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.AppointmentActivitiesRead,
    UserPermission.AppointmentActivityRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра профилей должности */
export const JOB_PROFILES_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.PositionProfilesRead,
    UserPermission.PositionProfileRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри задания на рассылку */
export const MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.NotificationMailingTasksRead,
    UserPermission.NotificationMailingTaskRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра карточек продуктов */
export const PRODUCT_CATALOG_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.ProductCardsRead,
    UserPermission.ProductCardRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри конструктора опросных листов */
export const QUESTIONNAIRES_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.QuestionnairesRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра назначений опроса */
export const QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.QuizAppointmentsRead,
]

/** Набор дополнительных прав доступа для совершения любых действий внутри реестра назначений опроса */
export const QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS = [
    UserPermission.QuizAppointmentFeedbackRead,
    UserPermission.QuizAppointmentInvolmentRead,
    UserPermission.QuizAppointmentRecommendRead,
    UserPermission.QuizAppointmentServiceQualityRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра портфелей опросов */
export const QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.QuizPortfoliosRead,
]

/** Набор дополнительных прав доступа для совершения любых действий внутри реестра портфелей опросов */
export const QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS = [
    UserPermission.QuizPortfoliosFeedbackRead,
    UserPermission.QuizPortfoliosInvolmentRead,
    UserPermission.QuizPortfoliosRecommendRead,
    UserPermission.QuizPortfoliosServiceQualityRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра назначений тестов */
export const TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.TestAppointmentsRead,
    UserPermission.TestAppointmentRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра портфелей тестов */
export const TEST_PORTFOLIOS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.TestPortfoliosRead,
    UserPermission.TestPortfolioRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра назначений треков */
export const TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.TrackAppointmentsRead,
    UserPermission.TrackAppointmentRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра треков адаптации */
export const TRACK_ADAPTATION_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.AdaptationTracksRead,
    UserPermission.AdaptationTrackRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра треков развития */
export const TRACK_DEVELOPMENT_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.DevelopmentTracksRead,
    UserPermission.DevelopmentTrackRead,
]

/** Набор прав доступа обязательных для совершения любых действий внутри реестра обучающих материалов */
export const TRAINING_MATERIALS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.CoursesRead,
    UserPermission.CourseRead,
]

/** Набор прав доступа обязательных для совершения действий с сущностью обучающих материалов */
export const TRAINING_MATERIALS_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.CoursesRead,
    UserPermission.CourseRead,
]

export const ASSESSMENT_REPORTS_REQUIRED_PERMISSIONS = [
    UserPermission.Administration,
    UserPermission.AssessmentReportsRead,
]

export const KNOWLEDGE_BASE_REQUIRED_PERMISSIONS = [
    UserPermission.KnowledgeBaseRead,
    UserPermission.KnowledgeBaseArticleRead,
]
