import React, { FC, useMemo } from 'react'
import { HasPermissions } from 'core/permissions'
import { Logo } from 'components/shared/Logo'
import { NavLink } from 'react-router-dom'

import styles from './MainMenu.module.scss'
import { MAIN_MENU_ITEMS } from './MainMenu.consts'

export const MainMenu: FC = React.memo(() => {
    const menuRender = useMemo(
        () =>
            MAIN_MENU_ITEMS.map((menuItem) => (
                <HasPermissions
                    key={menuItem.title}
                    requiredPermissions={menuItem.permissions}
                >
                    <NavLink
                        to={menuItem.link}
                        key={menuItem.title}
                        exact
                        className={styles.link}
                        activeClassName={styles.active}
                    >
                        {menuItem.title}
                    </NavLink>
                </HasPermissions>
            )),
        []
    )

    return (
        <div className={styles.wrapper}>
            <Logo className={styles.logo} />

            <nav className={styles.nav}>{menuRender}</nav>
        </div>
    )
})
