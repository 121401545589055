import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import {
    CompetenciesSearchContract,
    PositionProfileDictionariesService,
    StructuralUnitType,
    StructuralUnitsService,
} from 'core/api'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED, ROW_GUTTER } from 'consts'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps, normalizeDataForSelectAndRadio } from 'utils'
import { LOCAL } from 'core/local'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'

import { DictionariesStateProps } from './CompetenciesFilters.types'

/**
 * Форма фильтрации для справочника компетенций
 */
export const CompetenciesFilters: React.FC<FiltersProps<
    CompetenciesSearchContract
>> = React.memo((props) => {
    const [dictionaries, setDictionaries] = useState<DictionariesStateProps>({})

    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                const department = await StructuralUnitsService.getOrganizationsByUnitType(
                    { type: StructuralUnitType.Oiv }
                )
                const functionClasses = await PositionProfileDictionariesService.getAllFunctionClasses()

                setDictionaries({
                    department: department.map(normalizeDataForSelectAndRadio),
                    functionClasses: functionClasses.map(
                        normalizeDataForSelectAndRadio
                    ),
                })
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="competenceName"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="competenceGroupName"
                            label={LOCAL.LABELS.COMPETENCIES_GROUP_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="departmentIds"
                            label={LOCAL.LABELS.DEPARTMENT}
                        >
                            <SelectControl
                                values={dictionaries?.department || []}
                                mode="multiple"
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="functionClassIds"
                            label={LOCAL.LABELS.ACTIVITY_TYPE}
                        >
                            <SelectControl
                                values={dictionaries?.functionClasses || []}
                                mode="multiple"
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changingDateFrom',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changingDateTo',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
