import moment from 'moment-timezone'
import React, { useCallback, useEffect } from 'react'
import {
    AddOrganizationControl,
    CheckboxControl,
    ControlGroup,
    DatePickerControl,
    FormItemAdapter,
    HiddenField,
    InputControl,
    InputNumberControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import {
    AddonAfterDefault,
    DynamicFormRows,
    SpaceAdapter,
} from 'components/shared'
import {
    AppointmentContract,
    AppointmentPublishContract,
    AppointmentsService,
    EntityEditSessionService,
    EntityType,
    TestPortfolioSearchForSelectContract,
    TestPortfoliosService,
} from 'core/api'
import { Col, Form, Row, notification } from 'antd'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import { ORDER_RULES_WITH_CREATION_DATE } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ROW_GUTTER } from 'consts'
import { WithLoaderProps } from 'HOCs'
import { checkChangeEvaluationPeriodFrom } from 'utils/conditions.utils'
import {
    createConfirmPopup,
    disableDateWhenSelectedDate,
    getAssignmentFormPopupTitle,
    getInitialValuesForCopy,
    getValue,
    isAppointmentStatusDraft,
    isAppointmentStatusEnded,
    isAvailableToWatch,
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
    normalizeDataForSelectAndRadio,
    renderQuestionnairesDropdown,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { template } from 'lodash'
import { useDictionaries } from 'hooks'
import { useHistory } from 'react-router-dom'

import {
    DictionariesProps,
    TestAssignmentFormSubmitValues,
} from './TestAssignmentForm.types'
import {
    MIN_DAYS_TO_NOTIFICATION,
    SHOULD_UPDATE_PORTFOLIOS_FIELDS,
    TEST_ASSIGNMENTS_FORM_ID,
    TEST_ASSIGNMENT_FORM_INITIAL_VALUES,
} from './TestAssignmentForm.consts'
import {
    getValueFromEventNotificationField,
    mapFormDataToRequest,
    mapResponseToFormData,
} from './TestAssignmentForm.utils'

/**
 * Форма создания назначения теста
 */
export const TestAssignmentForm: React.FC<
    FormProps<AppointmentContract> & Partial<WithLoaderProps>
> = React.memo(
    ({
        onBlockUserRouting,
        isSaveDocument,
        initialValuesForEdit,
        reFetchInitialFormValues,
        formMode,
        updateLoader,
    }) => {
        const history = useHistory()
        const [form] = Form.useForm()

        const { dictionaries, handleChangeDictionaries } = useDictionaries<
            DictionariesProps
        >()

        const disabledByStatus =
            !isAppointmentStatusDraft(initialValuesForEdit?.status) &&
            isFormModeEdit(formMode)

        const disablePeriodFromByStatus =
            isAppointmentStatusEnded(initialValuesForEdit?.status) &&
            isFormModeEdit(formMode)

        const handleFinish = useCallback(
            async (values: Partial<TestAssignmentFormSubmitValues>) => {
                try {
                    updateLoader?.(true)

                    const body = mapFormDataToRequest(
                        values,
                        !isFormModeCopy(formMode)
                            ? initialValuesForEdit?.id
                            : undefined
                    ) as AppointmentPublishContract

                    if (isSaveDocument) {
                        await AppointmentsService.saveAppointment({ body })
                    } else {
                        await AppointmentsService.publishAppointment({
                            body,
                        })
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: isSaveDocument
                            ? LOCAL.MESSAGES.ASSIGNMENT_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES.ASSIGNMENT_PUBLISH_SUCCESS_MESSAGE,
                        content: getAssignmentFormPopupTitle(
                            getRouteMeta(
                                ROUTE_NAMES.TEST_ASSIGNMENTS,
                                'pageTitle'
                            )
                        ),
                        onOk: () => {
                            history.push(ROUTE_NAMES.TEST_ASSIGNMENTS)
                        },
                        onCancel: () => {
                            if (!initialValuesForEdit?.id) form.resetFields()
                            else
                                reFetchInitialFormValues?.(
                                    initialValuesForEdit?.id
                                )
                        },
                        okText: LOCAL.YES,
                        cancelText: LOCAL.NO,
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                updateLoader,
                formMode,
                initialValuesForEdit,
                isSaveDocument,
                onBlockUserRouting,
                history,
                form,
                reFetchInitialFormValues,
            ]
        )

        const testPortfoliosFetch = useCallback(async () => {
            try {
                const formValues: Partial<TestAssignmentFormSubmitValues> = form.getFieldsValue(
                    SHOULD_UPDATE_PORTFOLIOS_FIELDS
                )
                const organizationIds = formValues?.organizationsIds?.map(
                    getValue
                )
                const { forAllOrganizations } = formValues

                if (organizationIds?.length || forAllOrganizations) {
                    handleChangeDictionaries({
                        portfolios: await TestPortfoliosService.getAllForSelect(
                            {
                                body: {
                                    organizationIds,
                                    forAllOrganizations,
                                    orderRules: ORDER_RULES_WITH_CREATION_DATE,
                                } as TestPortfolioSearchForSelectContract,
                            }
                        ),
                    })

                    return
                }

                handleChangeDictionaries({
                    portfolios: undefined,
                })
            } catch (error) {
                console.error(error)
            }
        }, [form, handleChangeDictionaries])

        const handleValuesChange = useCallback(
            async (changedValue, allValues) => {
                const [changedValueKey, changedValueValue] = Object.entries(
                    changedValue
                )[0]

                if (SHOULD_UPDATE_PORTFOLIOS_FIELDS.includes(changedValueKey)) {
                    testPortfoliosFetch()
                }

                if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }

                if (changedValueKey === 'portfolioId' && changedValueValue) {
                    const dataSource = await EntityEditSessionService.checkAvailability(
                        {
                            id: changedValueValue as number,
                            entity: EntityType.TestPortfolio,
                        }
                    )

                    if (!dataSource.available) {
                        notification.error({
                            message: template(LOCAL.MESSAGES.PORTFOLIO_ON_EDIT)(
                                {
                                    portfolio: LOCAL.LABELS.TEST_PORTFOLIO.toLowerCase(),
                                }
                            ),
                        })

                        form.setFieldsValue({
                            [changedValueKey]: undefined,
                        })
                    }
                }

                if (changedValue.evaluationPeriodFrom) {
                    checkChangeEvaluationPeriodFrom({
                        evaluationPeriodFrom: changedValue.evaluationPeriodFrom,
                        evaluationPeriodTo: allValues.evaluationPeriodTo,
                        form,
                    })
                }
            },
            [form, formMode, onBlockUserRouting, testPortfoliosFetch]
        )

        useEffect(() => {
            if (initialValuesForEdit?.id) {
                form.setFieldsValue(
                    mapResponseToFormData(
                        getInitialValuesForCopy(initialValuesForEdit, formMode)
                    )
                )

                testPortfoliosFetch()
            }
        }, [testPortfoliosFetch, form, initialValuesForEdit, formMode])

        return (
            <Form
                id={TEST_ASSIGNMENTS_FORM_ID}
                onFinish={handleFinish}
                initialValues={TEST_ASSIGNMENT_FORM_INITIAL_VALUES}
                onValuesChange={handleValuesChange}
                form={form}
            >
                <HiddenField fieldName="type" />

                <FormItemAdapter
                    fieldName="name"
                    label={LOCAL.LABELS.NAME}
                    required
                >
                    <InputControl formMode={formMode} />
                </FormItemAdapter>

                <ShouldUpdateChecker fieldPath={['portfolioId']}>
                    {(form) => (
                        <>
                            <FormItemAdapter
                                fieldName="organizationsIds"
                                label={LOCAL.LABELS.ORGANIZATIONS}
                                required
                            >
                                <AddOrganizationControl
                                    formMode={formMode}
                                    disabled={
                                        form.getFieldValue('portfolioId') ||
                                        disabledByStatus
                                    }
                                    withSwitch
                                />
                            </FormItemAdapter>

                            <FormItemAdapter
                                fieldName="portfolioId"
                                label={LOCAL.LABELS.TEST_PORTFOLIO}
                                required
                            >
                                <SelectControl
                                    allowClear
                                    values={dictionaries?.portfolios?.map(
                                        normalizeDataForSelectAndRadio
                                    )}
                                    addonAfter={
                                        <AddonAfterDefault
                                            url={`${
                                                ROUTE_NAMES.TEST_PORTFOLIOS_VIEW
                                            }/${form?.getFieldValue(
                                                'portfolioId'
                                            )}`}
                                            disabled={
                                                !form?.getFieldValue(
                                                    'portfolioId'
                                                )
                                            }
                                        />
                                    }
                                    dropdownRender={renderQuestionnairesDropdown(
                                        {
                                            url:
                                                ROUTE_NAMES.TEST_PORTFOLIOS_CREATE,
                                        }
                                    )}
                                    notFoundContent={LOCAL.LABELS.EMPTY_DATA}
                                    disabled={
                                        initialValuesForEdit?.status &&
                                        isAvailableToWatch(
                                            initialValuesForEdit?.status
                                        )
                                    }
                                    formMode={formMode}
                                />
                            </FormItemAdapter>
                        </>
                    )}
                </ShouldUpdateChecker>

                <ControlGroup
                    type="bordered"
                    titleOutside={LOCAL.LABELS.REVIEWER}
                    required
                >
                    <Form.Item name="reviewerIds" noStyle>
                        <DynamicFormRows
                            addBtnText={LOCAL.ACTIONS.ADD_REVIEWER}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </Form.Item>
                </ControlGroup>

                <ControlGroup
                    type="bordered"
                    titleOutside={LOCAL.LABELS.TEST_PARTICIPANTS}
                    required
                >
                    <ShouldUpdateChecker fieldPath={['organizationsIds']}>
                        {({ getFieldValue }) => {
                            const structuralUnitsIds = getFieldValue(
                                'organizationsIds'
                            )?.map(getValue)

                            return (
                                <Form.Item name="participantsIds" noStyle>
                                    <DynamicFormRows
                                        addBtnText={
                                            LOCAL.ACTIONS.ADD_PARTICIPANTS
                                        }
                                        formMode={formMode}
                                        structuralUnitsIds={structuralUnitsIds}
                                        disabled={disabledByStatus}
                                    />
                                </Form.Item>
                            )
                        }}
                    </ShouldUpdateChecker>
                </ControlGroup>

                <SpaceAdapter className="mt-20">
                    <Form.Item name="attemptsLimited">
                        <CheckboxControl
                            formMode={formMode}
                            disabled={disabledByStatus}
                        >
                            {LOCAL.LABELS.PASSING_ATTEMPTS_LIMIT}
                        </CheckboxControl>
                    </Form.Item>

                    <Form.Item name="attemptsLimit">
                        <InputNumberControl
                            formMode={formMode}
                            min={1}
                            addonAfter={LOCAL.LABELS.TIMES}
                            disabled={disabledByStatus}
                        />
                    </Form.Item>
                </SpaceAdapter>

                <Row gutter={ROW_GUTTER}>
                    <Col md={24} xl={4}>
                        <FormItemAdapter
                            fieldName="evaluationPeriodFrom"
                            label={LOCAL.LABELS.DATE_START}
                            required
                        >
                            <DatePickerControl
                                formMode={formMode}
                                disabledDate={disableDateWhenSelectedDate(
                                    initialValuesForEdit?.evaluationPeriodFrom ||
                                        moment()
                                )}
                                disabled={disablePeriodFromByStatus}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col md={24} xl={4}>
                        <ShouldUpdateChecker fieldPath="evaluationPeriodFrom">
                            {({ getFieldsValue }) => {
                                const {
                                    evaluationPeriodFrom,
                                } = getFieldsValue(['evaluationPeriodFrom'])

                                return (
                                    <FormItemAdapter
                                        fieldName="evaluationPeriodTo"
                                        label={LOCAL.LABELS.DATE_END}
                                        required
                                    >
                                        <DatePickerControl
                                            formMode={formMode}
                                            disabledDate={disableDateWhenSelectedDate(
                                                evaluationPeriodFrom || moment()
                                            )}
                                            disabled={disablePeriodFromByStatus}
                                        />
                                    </FormItemAdapter>
                                )
                            }}
                        </ShouldUpdateChecker>
                    </Col>
                </Row>

                <FormItemAdapter
                    label={LOCAL.LABELS.NOTIFICATIONS_TIME_SET}
                    required
                >
                    <Form.Item
                        name="daysToStartNotification"
                        getValueFromEvent={getValueFromEventNotificationField}
                    >
                        <InputNumberControl
                            min={MIN_DAYS_TO_NOTIFICATION}
                            placeholder={String(MIN_DAYS_TO_NOTIFICATION)}
                            addonBefore={LOCAL.TIME_BEFORE}
                            addonAfter={LOCAL.LABELS.DAYS_TO_START}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </Form.Item>

                    <Form.Item
                        name="daysToEndNotification"
                        getValueFromEvent={getValueFromEventNotificationField}
                    >
                        <InputNumberControl
                            min={MIN_DAYS_TO_NOTIFICATION}
                            placeholder={String(MIN_DAYS_TO_NOTIFICATION)}
                            addonBefore={LOCAL.TIME_BEFORE}
                            addonAfter={LOCAL.LABELS.DAYS_TO_END}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </Form.Item>
                </FormItemAdapter>
            </Form>
        )
    }
)
