import React, { useCallback, useState } from 'react'
import { FreeAnswerTabType } from 'core/api'
import { Tabs } from 'antd'

import { getTabsOptions } from './QuizeReportFreeAnswer.utils.tabs'

/** Страница "Свободный ответ" в отчете опроса типа "Обратная связь" */
export const QuizeReportFreeAnswer: React.FC = React.memo(() => {
    const [activeKey, setActiveKey] = useState<string>(FreeAnswerTabType.Text)

    const handleChange = useCallback((activeKey: string) => {
        setActiveKey(activeKey)
    }, [])

    return (
        <Tabs
            defaultActiveKey={FreeAnswerTabType.Text}
            size="large"
            type="card"
            onChange={handleChange}
        >
            {getTabsOptions(activeKey).map(
                (tab) =>
                    tab.visible && (
                        <Tabs.TabPane forceRender tab={tab.tab} key={tab.key}>
                            {tab.content}
                        </Tabs.TabPane>
                    )
            )}
        </Tabs>
    )
})
