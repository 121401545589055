import React from 'react'
import { ColorPickerControl } from 'components/controls'
import { Form } from 'antd'

import styles from './VisualizationColorOptions.module.scss'
import { VISUALIZATION_COLOR_OPTIONS_META } from './VisualizationColorOptions.consts'
import { VisualizationColorOptionsProps } from './VisualizationColorOptions.types'

/** Цветовые настройки элементов холста */
export const VisualizationColorOptions: React.FC<VisualizationColorOptionsProps> = React.memo(
    ({ type, disabled }) => {
        /** Блок с колорпикерами */
        const colorPickersBlocks = Object.entries(
            VISUALIZATION_COLOR_OPTIONS_META[type].colorPickerData
        ).map(([key, value]) => (
            <Form.Item name={['canvas', key]} key={key}>
                <ColorPickerControl
                    label={value}
                    className={styles.colorPicker}
                    disabled={disabled}
                />
            </Form.Item>
        ))

        return <>{colorPickersBlocks}</>
    }
)
