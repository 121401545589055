import { ActivityLessonPassingContract } from 'core/api'
import { LOCAL } from 'core/local'
import { isHomeworkOnChecking } from 'utils'
import { renderHomeworkStatus } from 'components/pages/UserPersonalArea/components/CheckHomework/CheckHomework.utils'

export const getHomeworkStatus = (lesson?: ActivityLessonPassingContract) => {
    if (!lesson?.homework) return

    const { status, tutorId } = lesson.homework
    const tutor = tutorId ? `${LOCAL.LABELS.TUTOR} ${tutorId}: ` : ''

    const styledStatus = renderHomeworkStatus(
        status,
        isHomeworkOnChecking(status) ? `${tutor}${LOCAL.LABELS.DOCUMENT} ` : ''
    )

    return styledStatus
}
