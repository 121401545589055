import {
    APPOINTMENT_STATUS,
    DOCUMENT_STATUSES,
    MAILING_STATUSES,
    TEMPLATE_STATUSES,
    TRACKS_APPOINTMENT_STATUSES,
} from 'consts'
import {
    AppointmentStatus,
    AppointmentTrackStatus,
    DocumentStatus,
    NotificationMailingTaskStatus,
    NotificationTemplateStatus,
} from 'core/api'
import { LOCAL } from 'core/local'
import { isFormModeCopy } from 'utils'

import { GetTagProps } from './ContentTitle.types'

/**
 * Получить статус для вывода в CloudTag
 * @param status статус
 */
export const getStatus = (
    status:
        | DocumentStatus
        | AppointmentStatus
        | AppointmentTrackStatus
        | NotificationTemplateStatus
        | NotificationMailingTaskStatus
) => {
    if (
        (status as keyof typeof DocumentStatus) &&
        DOCUMENT_STATUSES[status as keyof typeof DocumentStatus]
    )
        return DOCUMENT_STATUSES[
            status as keyof typeof DocumentStatus
        ].toLowerCase()

    if (
        (status as keyof typeof AppointmentStatus) &&
        APPOINTMENT_STATUS[status as keyof typeof AppointmentStatus]
    )
        return APPOINTMENT_STATUS[
            status as keyof typeof AppointmentStatus
        ].toLowerCase()

    if (
        (status as keyof typeof AppointmentTrackStatus) &&
        TRACKS_APPOINTMENT_STATUSES[
            status as keyof typeof AppointmentTrackStatus
        ]
    )
        return TRACKS_APPOINTMENT_STATUSES[
            status as keyof typeof AppointmentTrackStatus
        ].toLowerCase()

    if (
        (status as keyof typeof NotificationTemplateStatus) &&
        TEMPLATE_STATUSES[status as keyof typeof NotificationTemplateStatus]
    )
        return TEMPLATE_STATUSES[
            status as keyof typeof NotificationTemplateStatus
        ].toLowerCase()

    if (
        (status as keyof typeof NotificationMailingTaskStatus) &&
        MAILING_STATUSES[status as keyof typeof NotificationMailingTaskStatus]
    )
        return MAILING_STATUSES[
            status as keyof typeof NotificationMailingTaskStatus
        ].toLowerCase()
}

export const getTag = ({ isArchive, status, formMode }: GetTagProps) => {
    if (isFormModeCopy(formMode)) return LOCAL.STATUSES.DRAFT.toLowerCase()

    if (isArchive) return LOCAL.STATUSES.ARCHIVE.toLowerCase()

    return getStatus(status)
}
