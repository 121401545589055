import React, { useEffect, useState } from 'react'
import { ActivityModalSearchContract, StructuralUnitsService } from 'core/api'
import { Col, Row } from 'antd'
import { DataNode } from 'rc-tree-select/lib/interface'
import { DictionariesCommonProps } from 'App.types'
import { EVENT_SPECIES_MAPPED, EVENT_TYPES_MAPPED, ROW_GUTTER } from 'consts'
import { FilterForm } from 'components/shared/FilterForm'
import {
    FiltersProps,
    getPopupContainer,
    normalizeDataForTreeSelect,
} from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { SelectControl } from 'components/controls/SelectControl'
import { TreeSelectControl } from 'components/controls/TreeSelectControl'

/**
 * Форма фильтрации мероприятий
 */
export const ActivitiesFilters: React.FC<FiltersProps<
    ActivityModalSearchContract
>> = React.memo((props) => {
    const [dictionaries, setDictionaries] = useState<
        DictionariesCommonProps<'structuralUnits', DataNode>
    >()

    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                const structuralUnitsData = await StructuralUnitsService.getAllStructuralUnits()

                setDictionaries({
                    structuralUnits: structuralUnitsData.map(
                        normalizeDataForTreeSelect
                    ),
                })
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [])

    return (
        <FilterForm {...props}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter fieldName="type" label={LOCAL.LABELS.TYPE}>
                        <SelectControl
                            values={EVENT_TYPES_MAPPED}
                            disabled={
                                props.tableFiltersOptions?.disabledFields?.type
                            }
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="structuralUnitIds"
                        label={LOCAL.LABELS.ORGANIZATION}
                    >
                        <TreeSelectControl
                            disabled={
                                props.tableFiltersOptions?.disabledFields
                                    ?.structuralUnitIds
                            }
                            treeData={dictionaries?.structuralUnits || []}
                            multiple
                            placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            getPopupContainer={getPopupContainer}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter fieldName="kind" label={LOCAL.LABELS.KIND}>
                        <SelectControl
                            values={EVENT_SPECIES_MAPPED}
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    )
})
