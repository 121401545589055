import map from 'lodash/map'
import {
    CompetencesPublicQuestionsContract,
    PassingFormQuestionAnswerContract,
    PassingFormQuestionAnswerCreateContract,
    QuestionType,
} from 'core/api'
import { isEmptyObject } from 'utils'

import styles from '../PassingCompetence/PassingCompetence.module.scss'
import {
    CompetencesStats,
    PassingStateProps,
    PassingSubmitValues,
} from './UserAssignmentPassing.types'
import { OFFSET } from './UserAssignmentPassing.consts'
import { getCompetenceIdByBlock } from '../PassingCompetence/PassingCompetence.utils'

/** Считаем количество вопросов и ответов для каждой компетенции */
export const getCompetencesStats = (
    competences: CompetencesPublicQuestionsContract[],
    questionsIds: number[]
) => {
    const stats: CompetencesStats = {}

    competences.forEach(({ competence, questions }) => {
        stats[competence.id] = {
            total: questions.length,
            answered: 0,
        }

        questions.forEach((question) => {
            if (questionsIds.includes(question.id)) {
                stats[competence.id].answered += 1
            }
        })
    })

    return stats
}

/** Фильтрация ответов, у которых нет значения, для прогресса прохождения */
export const filterAnswersForPassingState = (
    submitValues: PassingSubmitValues
) =>
    Object.entries(submitValues).reduce<PassingSubmitValues>(
        (acc, [key, value]) => {
            if (isEmptyObject(value[Object.keys(value)[0] as QuestionType])) {
                delete value.TextQuestion
            } else {
                acc[key] = value
            }

            return acc
        },
        {}
    )

/** Фильтрация ответов, у которых нет значения */
export const filterAnswers = (submitValues: PassingSubmitValues) =>
    Object.entries(submitValues).reduce<PassingSubmitValues>(
        (acc, [key, value]) => {
            if (value[Object.keys(value)[0] as QuestionType]) {
                acc[key] = value
            }

            return acc
        },
        {}
    )

/** Мапим ответы с бэка в значения формы */
export const mapResponseToFormData = (
    answers: PassingFormQuestionAnswerContract[]
) =>
    answers.reduce<PassingSubmitValues>(
        (acc, { questionId, scaleAnswerParameters, textAnswerParameters }) => {
            const questionType = scaleAnswerParameters
                ? QuestionType.CriteriaScale
                : QuestionType.TextQuestion
            const answerContent = scaleAnswerParameters || textAnswerParameters
            acc[questionId] = { [questionType]: answerContent }

            return acc
        },
        {}
    )

/** Мапим значения формы в контракт для бэка */
export const mapFormDataToRequest = (submitValues: PassingSubmitValues) =>
    map(submitValues, (value, key) => {
        const questionType = Object.keys(value)[0]
        const answerValue = value[questionType as QuestionType]
        const contract: Partial<PassingFormQuestionAnswerCreateContract> = {
            questionId: +key,
        }
        if (questionType === QuestionType.CriteriaScale) {
            contract.scaleAnswerParameters = answerValue
        }
        if (questionType === QuestionType.TextQuestion) {
            if (isEmptyObject(answerValue)) {
                delete contract.textAnswerParameters
            } else {
                contract.textAnswerParameters = answerValue
            }
        }

        return contract as PassingFormQuestionAnswerCreateContract
    })

/** Находим id компетенции, которую видит пользователь */
export const getActiveCompetenceId = () => {
    const competenceBlocks = document.querySelectorAll(`.${styles.wrapper}`)
    let activeId: undefined | number
    competenceBlocks.forEach((element) => {
        if (element.getBoundingClientRect().bottom >= OFFSET && !activeId) {
            activeId = getCompetenceIdByBlock(element.getAttribute('id') || '')
        }
    })

    return activeId
}

export const setCompetencesStats = (
    prevState: PassingStateProps,
    savedAnswers: PassingSubmitValues,
    competences?: CompetencesPublicQuestionsContract[]
) =>
    competences
        ? {
              ...prevState,
              competencesStats: getCompetencesStats(
                  competences,
                  Object.keys(savedAnswers).map(Number)
              ),
          }
        : prevState

export const changeUnsavedAnswers = (
    unsavedAnswers: PassingSubmitValues,
    questionId: string,
    questionType: QuestionType,
    questionValue: PassingSubmitValues,
    allValues: PassingSubmitValues
) => ({
    ...unsavedAnswers,
    [questionId]: {
        [questionType]: {
            ...(allValues[questionId]?.[questionType] || {}),
            ...questionValue[questionType],
        },
    },
})

export const getCheckAnswers = (answers: PassingSubmitValues) =>
    Object.keys(answers).reduce(
        (acc, key) => ({
            ...acc,
            [key]: { ...answers[key], questionId: Number(key) },
        }),
        {}
    )
