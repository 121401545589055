import {
    AssessmentPortfolioContract,
    QuestionnaireType,
    QuizPortfolioContract,
    TestPortfolioContract,
} from 'core/api'
import { WithFormInstanceProps, WithFormModeProps } from 'App.types'
import { createStoreContext } from 'contexts/Store'

export const [
    PortfolioFormCommonTabsContext,
    PortfolioFormCommonTabsContextProvider,
] = createStoreContext<{
    form: WithFormInstanceProps['form']
    initialValuesForEdit?:
        | QuizPortfolioContract
        | TestPortfolioContract
        | AssessmentPortfolioContract
    formMode: WithFormModeProps['formMode']
    portfolioFormType?: QuestionnaireType
    disabledByStatus?: boolean
}>()
