import NoImage from 'core/images/no-image.svg'
import React from 'react'
import cn from 'classnames'
import moment from 'moment'
import { Col, Typography } from 'antd'
import { LOCAL } from 'core/local'
import { ReactSVG } from 'react-svg'
import { Title } from 'components/shared'
import { getBackgroundImageStyle } from 'utils'

import styles from './ProcessCard.module.scss'
import { ProcessCardProps } from './ProcessCard.types'

const { Paragraph } = Typography

/**
 * Карточка с информацией о каком-то процессе, существующем в системе. Например, назначенные треки или мероприятия
 */
export const ProcessCard: React.FC<ProcessCardProps> = React.memo(
    ({
        header,
        title,
        description,
        dataView,
        imageUrl,
        onClick,
        passedPercentage,
        startPassingDate,
    }) => {
        const disabled = moment().isBefore(startPassingDate)

        return (
            <Col xs={24} xxl={12}>
                <div
                    className={cn(styles.wrapper, disabled && styles.disabled)}
                    onClick={disabled ? undefined : onClick}
                >
                    <div
                        style={getBackgroundImageStyle(imageUrl)}
                        className={styles.image}
                    >
                        {!imageUrl && (
                            <ReactSVG
                                src={NoImage}
                                className={styles.noImage}
                            />
                        )}
                    </div>

                    <div className={styles.content}>
                        <div>
                            {header}

                            <Title
                                type="h6"
                                text={title || LOCAL.LABELS.CONTENT_EMPTY}
                                className={styles.title}
                            />
                        </div>

                        <div>
                            <Paragraph
                                ellipsis={{ rows: 2, tooltip: description }}
                                className={styles.descr}
                            >
                                {description}
                            </Paragraph>

                            <div className={styles.progress}>
                                {dataView}

                                <div
                                    className={styles.percent}
                                >{`${passedPercentage}%`}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }
)
