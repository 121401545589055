import React, { useEffect, useMemo, useState } from 'react'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { HomeworksService } from 'core/api'
import { Store, UrlParamsCommonProps } from 'App.types'
import { useForm } from 'antd/lib/form/Form'
import { useHttp } from 'hooks'
import { useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './CheckHomeworkWithoutEditor.module.scss'
import { InfoBlock, Reference, SubtaskBlock } from './components'

export const CheckHomeworkWithoutEditor: React.FC<{}> = withLoader(
    React.memo(({ updateLoader }) => {
        const [form] = useForm()

        const [getData, data] = useHttp(
            HomeworksService.startCheck,
            updateLoader
        )

        const [getHomeworkHistory, homeworkHistory] = useHttp(
            HomeworksService.getHistoryTaskPassings,
            updateLoader
        )

        const [getHomeworkReferences, homeworkReferences] = useHttp(
            HomeworksService.getTaskPassingsReferences,
            updateLoader
        )

        const params = useParams<UrlParamsCommonProps<'id'>>()

        const [sendingTaskIds, setSendingTaskIds] = useState<number[]>([])

        const [subtaskBlocksHeight, setSubtaskBlocksHeight] = useState<
            Store<number>
        >({})

        const isDisabled = useMemo(() => !!sendingTaskIds.length, [
            sendingTaskIds,
        ])

        const initialValues = useMemo(
            () => ({
                taskPassings: data?.taskPassings,
            }),
            [data]
        )

        useEffect(() => {
            const id = Number(params.id)

            getData({ id })

            getHomeworkHistory({ id })

            getHomeworkReferences({ id })
        }, [getData, getHomeworkHistory, getHomeworkReferences, params])

        return data ? (
            <Form
                form={form}
                id={FORM_IDS.TASK_PASSING_FORM}
                initialValues={initialValues}
            >
                <InfoBlock
                    content={data.content}
                    id={data.userId}
                    disabled={isDisabled}
                />

                <Form.List name="taskPassings">
                    {(fields) =>
                        fields.map((field) => (
                            <div className={styles.wrapper} key={field.name}>
                                <div className={styles.homeworkWrapper}>
                                    <SubtaskBlock
                                        field={field}
                                        form={form}
                                        key={field.key}
                                        homeworkId={data.id}
                                        setSendingTaskIds={setSendingTaskIds}
                                        sendingTaskIds={sendingTaskIds}
                                        isOverdue={data.isOverdue}
                                        isTasksTitlesVisible={
                                            data.isTasksTitlesVisible
                                        }
                                        setSubtaskBlocksHeight={
                                            setSubtaskBlocksHeight
                                        }
                                        homeworkHistory={homeworkHistory}
                                    />
                                </div>
                                <div className={styles.referencesWrapper}>
                                    <Reference
                                        field={field}
                                        form={form}
                                        key={field.key}
                                        subtaskBlocksHeight={
                                            subtaskBlocksHeight
                                        }
                                        isTasksTitlesVisible={
                                            data.isTasksTitlesVisible
                                        }
                                        data={homeworkReferences}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </Form.List>

                <div className={styles.bg} />
            </Form>
        ) : null
    })
)
