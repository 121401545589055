import React from 'react'
import { NotImplementedError } from 'core/helpers/NotImplementedError'

import { UserProfileContextProps } from './UserProfileContext.types'

export const UserProfileContext = React.createContext<UserProfileContextProps>({
    setResultsComparingOptions: () => {
        throw new NotImplementedError()
    },
})
