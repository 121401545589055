import React, { useCallback } from 'react'
import { AppError, LdeReportsService } from 'core/api'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { FORM_IDS } from 'core/configs'
import { FormAdapter } from 'components/shared'
import { FormItemAdapter, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { useForm } from 'antd/lib/form/Form'
import { useHistory } from 'react-router-dom'
import { withLoader } from 'HOCs'

/** Форма создания отчета */
export const ReportCreateForm: React.FC<PopupAdapterFormProps> = withLoader(
    React.memo(({ onRequestFinish, onCancelSubmit, updateLoader }) => {
        const [form] = useForm()
        const history = useHistory()

        const handleFinish = useCallback(
            async (body) => {
                try {
                    updateLoader(true)

                    const { name } = body

                    const response = await LdeReportsService.createLdeReport({
                        body: {
                            name,
                        },
                    })

                    onRequestFinish?.()

                    history.push(
                        `${ROUTE_NAMES.DOCUMENTS_EDITOR_EDIT}/${response.ldeId}`
                    )
                } catch (error) {
                    onRequestFinish?.(error as AppError)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader, onRequestFinish, history]
        )

        return (
            <FormAdapter
                id={FORM_IDS.REPORT_CREATE_FORM}
                form={form}
                onFinish={handleFinish}
            >
                <FormItemAdapter
                    fieldName="name"
                    label={LOCAL.LABELS.DOCUMENT_NAME}
                >
                    <InputControl maxLength={50} />
                </FormItemAdapter>

                <ButtonsToolbar bottomIndent="0">
                    <Button type="link" onClick={onCancelSubmit}>
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    <Button type="primary" htmlType="submit">
                        {LOCAL.ACTIONS.SAVE}
                    </Button>
                </ButtonsToolbar>
            </FormAdapter>
        )
    })
)
