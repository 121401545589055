import React from 'react'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import { ControlGroup } from 'components/controls'
import { DynamicFormRows } from 'components/shared'
import { Form } from 'antd'
import { LOCAL } from 'core/local'

/** Область "тьюторы" для формы назначения мероприятия */
export const TutorsArea: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => (
    <ControlGroup type="bordered" titleOutside={LOCAL.LABELS.TUTORS}>
        <Form.Item noStyle name="tutorsIds">
            <DynamicFormRows
                formMode={formMode}
                addBtnText={LOCAL.ACTIONS.ADD_PARTICIPANTS}
                modalTitle={LOCAL.LABELS.EMPLOYEE_SELECTION}
                disabled={disabled}
            />
        </Form.Item>
    </ControlGroup>
))
