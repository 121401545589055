import {
    ActivityLessonPassingContract,
    TrackPassingContract,
    TrackPassingProgressContract,
    TrackStageActivityPassingContract,
    TrackStageContentPassingContract,
} from 'core/api'
import { Store } from 'App.types'
import { createStoreContext } from 'contexts/Store'

export interface TrackPassingContextProps {
    trackInfo?: TrackPassingContract
    stageInfo?: {
        activity?: TrackStageActivityPassingContract
        content?: TrackStageContentPassingContract
    }
    lessonInfo?: ActivityLessonPassingContract
    lessonChildInfo?: ActivityLessonPassingContract
    progress?: TrackPassingProgressContract
    activeLessonIndex?: number
    // TODO: не понадобится после доработки бэка, т.к. в текущем уроке будет храниться ссылка на родительский
    lessonsParents?: Store<number[]>
}

export const [
    TrackPassingContext,
    TrackPassingContextProvider,
] = createStoreContext<TrackPassingContextProps>()

export const storeNamePaths = {
    lessonsParents: 'lessonsParents',
    trackInfo: {
        currentStageNumber: ['trackInfo', 'currentStageNumber'],
        stages: ['trackInfo', 'stages'],
    },
}
