import { CertificateType, ProductType } from 'core/api'

export const isProductTypeContent = (type?: ProductType): boolean =>
    type === ProductType.Content
export const isProductTypeTrack = (type?: ProductType): boolean =>
    type === ProductType.Track
export const isProductTypeActivity = (type?: ProductType): boolean =>
    type === ProductType.Activity
export const isProductTypeBook = (type: ProductType) =>
    type === ProductType.Book
export const isProductTypePodcast = (type: ProductType) =>
    type === ProductType.Podcast

export const isCertificateTypeInternal = (type?: CertificateType) =>
    type === CertificateType.Internal
