import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import {
    AppError,
    QuestionnaireContract,
    QuestionnairesService,
    UserPermission,
} from 'core/api'
import {
    Button,
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsForEditProps, useSaveOrPublishState } from 'hooks'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import {
    editSessionCancel,
    isCompetitiveAccessException,
    isDocumentStatusReadyForWork,
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useHistory, useParams } from 'react-router-dom'

import { QuestionnairesConstructorForm } from './components'
import { StoreContext } from './Questionnaires.context'

/**
 * Конструктор опросных листов
 */
const QuestionnairesConstructor: React.FC<
    WithBlockUserRoutingComponentProps & ComponentCommonProps
> = withLoader(({ onBlockUserRouting, formMode, updateLoader, isLoading }) => {
    const urlParams = useParams<UrlParamsForEditProps>()
    const { store, updateStore } = useContext(StoreContext)
    const history = useHistory()

    const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()

    const fetchInitialFormValuesById = useCallback(async () => {
        try {
            if (!urlParams.id) return

            const response = await (!isFormModeEdit(formMode)
                ? QuestionnairesService.getActive
                : QuestionnairesService.getForEdit)({ id: +urlParams.id })

            updateStore({ currentQuestionnaire: response })
        } catch (error) {
            console.error(error)

            if (isCompetitiveAccessException(error as AppError))
                history.push(ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES)
        }
    }, [formMode, history, updateStore, urlParams.id])

    const initialValuesForEdit = useMemo(():
        | QuestionnaireContract
        | undefined => {
        if (!store.currentQuestionnaire) return

        const { status, ...initialValuesForEdit } = store.currentQuestionnaire

        if (isFormModeCopy(formMode))
            return initialValuesForEdit as QuestionnaireContract

        return { ...initialValuesForEdit, status }
    }, [formMode, store.currentQuestionnaire])

    const refuseEdit = useCallback(() => {
        editSessionCancel({
            service: QuestionnairesService,
            id: initialValuesForEdit?.id,
        })
    }, [initialValuesForEdit])

    useEffect(() => {
        if (!store.currentQuestionnaire) {
            fetchInitialFormValuesById()
        }

        return () => {
            store.currentQuestionnaire &&
                updateStore({ currentQuestionnaire: undefined })
        }
    }, [fetchInitialFormValuesById, store, updateStore])

    return (
        <PageContent>
            <ContentTitleWithBackLink
                status={initialValuesForEdit?.status}
                onBack={refuseEdit}
            >
                <ButtonsToolbar>
                    <Button
                        onClick={refuseEdit}
                        type="link"
                        href={ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES}
                    >
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    <Button
                        form={FORM_IDS.QUESTIONNAIRES_CONSTRUCTOR_FORM}
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmitForm(true)}
                        disabled={
                            isDocumentStatusReadyForWork(
                                initialValuesForEdit?.status
                            ) ||
                            isFormModeView(formMode) ||
                            isLoading
                        }
                    >
                        {LOCAL.ACTIONS.SAVE}
                    </Button>

                    <HasPermissions
                        requiredPermissions={[
                            UserPermission.QuestionnaireSave,
                            UserPermission.QuestionnairePublish,
                        ]}
                        accessDeniedRender={renderAccessDeniedButton({
                            text: LOCAL.ACTIONS.PUBLISH,
                            type: 'primary',
                        })}
                    >
                        <Button
                            form={FORM_IDS.QUESTIONNAIRES_CONSTRUCTOR_FORM}
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmitForm(false)}
                            disabled={isFormModeView(formMode) || isLoading}
                        >
                            {LOCAL.ACTIONS.PUBLISH}
                        </Button>
                    </HasPermissions>
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <QuestionnairesConstructorForm
                updateLoader={updateLoader}
                onBlockUserRouting={onBlockUserRouting}
                initialValuesForEdit={initialValuesForEdit}
                isSaveDocument={isSaveDocument}
                reFetchInitialFormValues={fetchInitialFormValuesById}
                formMode={formMode}
            />
        </PageContent>
    )
})

export default React.memo(
    withBlockUserRouting(QuestionnairesConstructor, false)
) as typeof QuestionnairesConstructor
