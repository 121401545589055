import React, { useEffect } from 'react'
import { LOCAL } from 'core/local'
import { SystemInformationContract, SystemInformationService } from 'core/api'
import { useHttp } from 'hooks'

import styles from './AboutSystem.module.scss'
import { getContactElement } from './AboutSystem.utils'

/** Информация о системе с контактами тех. поддержки */
export const AboutSystem: React.FC = React.memo(() => {
    const [getData, data] = useHttp<SystemInformationContract>(
        SystemInformationService.getVersion
    )

    useEffect(() => {
        getData()
    }, [getData])

    return (
        <div className={styles.wrapper}>
            <h3>{LOCAL.ABOUT_SYSTEM_TITLE}</h3>

            {data && (
                <div className={styles.body}>
                    <div>{`${LOCAL.LABELS.VERSION} ${data.version}`}</div>

                    <div
                        className={styles.contacts}
                    >{`${LOCAL.LABELS.STP_CONTACTS}:`}</div>

                    <ul>
                        {getContactElement(data.phoneNumber1, 'tel')}

                        {getContactElement(data.phoneNumber2, 'tel')}

                        {getContactElement(data.email, 'mailto')}
                    </ul>
                </div>
            )}
        </div>
    )
})
