import React from 'react'

import styles from './ModalBlocks.module.scss'
import { ActivityChildProps } from '../../ActivityLayer.types'
import { ModalSingleBlock } from '../ModalSingleBlock'
import { getBlocksStyle } from '../../ActivityLayer.utils'

/**
 * Отображение тематических блоков для мероприятия типа - "modal"
 */
export const ModalBlocks: React.FC<ActivityChildProps> = React.memo(
    ({ activity }) => {
        const lessons = activity?.activityLessonPassings
        const canvas = activity?.canvas

        return (
            <div className={styles.wrapper}>
                {lessons?.map((block, index) => (
                    <React.Fragment key={block.lessonId}>
                        <ModalSingleBlock
                            lessonIndex={index}
                            activity={activity}
                        />

                        {canvas?.showLines && (
                            <div
                                className={styles.line}
                                style={getBlocksStyle(canvas, block.status)}
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
        )
    }
)
