import React, { FC } from 'react'
import {
    ErrorPage,
    QuizAssignmentFormContainer,
    QuizAssignments,
    QuizPortfolioFormContainer,
    QuizPortfolios,
    QuizReport,
} from 'components/pages'
import { ProtectedRoute } from 'core/permissions'
import {
    QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS,
    QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
    QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS,
    QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
} from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

export const QuizRouting: FC = React.memo(() => (
    <Switch>
        <ProtectedRoute
            requiredPermissions={[
                ...QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
                UserPermission.QuizPortfoliosSave,
            ]}
            conditionalPermissions={QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS}
            path={`${ROUTE_NAMES.QUIZ_PORTFOLIOS_COPY}/:id`}
        >
            <QuizPortfolioFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS}
            conditionalPermissions={QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS}
            path={`${ROUTE_NAMES.QUIZ_PORTFOLIOS_VIEW}/:id`}
        >
            <QuizPortfolioFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
                UserPermission.QuizPortfoliosSave,
            ]}
            conditionalPermissions={QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS}
            path={`${ROUTE_NAMES.QUIZ_PORTFOLIOS_EDIT}/:id`}
        >
            <QuizPortfolioFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
                UserPermission.QuizPortfoliosSave,
            ]}
            conditionalPermissions={QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS}
            path={ROUTE_NAMES.QUIZ_PORTFOLIOS_CREATE}
        >
            <QuizPortfolioFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS}
            path={ROUTE_NAMES.QUIZ_PORTFOLIOS}
        >
            <QuizPortfolios />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS}
            conditionalPermissions={QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS}
            path={`${ROUTE_NAMES.QUIZ_ASSIGNMENTS_VIEW}/:id`}
        >
            <QuizAssignmentFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.QuizAppointmentSave,
            ]}
            conditionalPermissions={QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS}
            path={`${ROUTE_NAMES.QUIZ_ASSIGNMENTS_EDIT}/:id`}
        >
            <QuizAssignmentFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.QuizAppointmentSave,
            ]}
            conditionalPermissions={QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS}
            path={ROUTE_NAMES.QUIZ_ASSIGNMENTS_CREATE}
        >
            <QuizAssignmentFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS}
            path={ROUTE_NAMES.QUIZ_ASSIGNMENTS}
        >
            <QuizAssignments />
        </ProtectedRoute>

        <Route path={`${ROUTE_NAMES.QUIZ_REPORT}/:id`}>
            <QuizReport />
        </Route>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
