import { StructuralUnitContract } from 'core/api'
import { isStructuralUnitComplex, isStructuralUnitTypeOiv } from 'utils'

/**
 * Рендер checkbox для записей в таблице формы по условию
 */
export const checkboxRender = (
    checked: boolean,
    record: StructuralUnitContract,
    index: number,
    originNode: React.ReactNode
) => {
    if (isStructuralUnitComplex(record.type)) return ''

    return originNode
}

/**
 * Рендер checkbox для записей с типом ОИВ
 */
export const checkboxRenderForOIV = (
    checked: boolean,
    record: StructuralUnitContract,
    index: number,
    originNode: React.ReactNode
) => {
    if (isStructuralUnitTypeOiv(record.type)) return originNode

    return ''
}
