import cn from 'classnames'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { Affix } from 'antd'
import { DATE_FORMAT, MAIN_HEADER_HEIGHT, SEC_IN_HOUR } from 'consts'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { QUESTIONNAIRE_PASSING_EVENTS } from 'components/pages/QuestionnairesListener/QuestionnairePassing.events'
import { eventEmitter } from 'core/helpers/eventEmitter'

import styles from './ControlPanel.module.scss'
import { ControlPanelProps } from './ControlPanel.types'

/** Панель управления для формы прохождения опроса слушателем */
export const ControlPanel: React.FC<ControlPanelProps> = React.memo(
    ({
        passingData,
        checkAnswers,
        remainingTime,
        onRemainingTime,
        isAlreadyRestarted,
    }) => {
        const { questions, questionnaire } = passingData

        const showPassingProgress = useMemo(
            () =>
                questionnaire?.quizPortfolioPresentation?.showPassingProgress ||
                questionnaire?.testPortfolioPresentation?.showPassingProgress,
            [questionnaire]
        )

        /**
         * Время таймер для вывода в UI
         */
        const timeLimit = !!remainingTime && (
            <div className={styles.timer}>
                {`${LOCAL.LABELS.YOU_HAVE_LEFT}:`}

                <b>
                    {` ${moment()
                        .startOf('day')
                        .seconds(remainingTime)
                        .format(
                            remainingTime < SEC_IN_HOUR
                                ? DATE_FORMAT.mm_ss
                                : DATE_FORMAT.HH_mm_ss
                        )}`}
                </b>
            </div>
        )

        const progressPassing = (
            <ul className={styles.progressPassing}>
                {questions.map((el) => {
                    const isAnswered = checkAnswers[el.id]

                    return (
                        <li
                            key={el.id}
                            className={cn(
                                styles.division,
                                isAnswered && styles.divisionDone
                            )}
                        >
                            {el.blockedByQuestionNumber && !isAnswered && (
                                <IconsAdapter
                                    iconType="CustomIconLock"
                                    className={styles.iconLock}
                                />
                            )}
                        </li>
                    )
                })}
            </ul>
        )

        useEffect(() => {
            if (remainingTime === 0 && !isAlreadyRestarted) {
                if (!questionnaire) return

                eventEmitter.emit(QUESTIONNAIRE_PASSING_EVENTS.CONFIRM, true)
            }
        }, [remainingTime, checkAnswers, isAlreadyRestarted, questionnaire])

        /**
         * Запуск таймера, если опрос имеет ограничение по времени
         */
        useEffect(() => {
            if (!onRemainingTime) return

            const intervalId = setInterval(() => {
                onRemainingTime((currentValue) => {
                    if (!currentValue) {
                        clearInterval(intervalId)

                        return currentValue
                    }

                    return currentValue - 1
                })
            }, 1000)

            return () => clearInterval(intervalId)
        }, [onRemainingTime])

        return (
            <Affix offsetTop={MAIN_HEADER_HEIGHT}>
                <div className={styles.wrapper}>
                    <div className={styles.info}>
                        {showPassingProgress && (
                            <>
                                {progressPassing}

                                <span>{`${Object.keys(checkAnswers).length}/${
                                    questions.length
                                }`}</span>
                            </>
                        )}

                        {timeLimit}
                    </div>
                </div>
            </Affix>
        )
    }
)
