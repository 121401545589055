import React, { useCallback, useContext } from 'react'
import { PlayerView } from 'components/pages/TrackPreviewPassing/components/ThematicBlockLayer/components/ThematicBlockContent/components'
import { WithHeightResizeProps, withHeightResize } from 'HOCs'
import { eventEmitter } from 'core/helpers/eventEmitter'

import styles from './CourseLayerContent.module.scss'
import { TRACK_PASSING_EVENTS } from '../../TrackPassing.events'
import { TrackPassingContext } from '../../TrackPassing.context'

export const CourseLayerContent: React.FC<WithHeightResizeProps> = withHeightResize(
    ({ componentHeight, componentRef }) => {
        const { store } = useContext(TrackPassingContext)

        const { stageInfo, lessonInfo } = store

        const course = stageInfo?.content?.course
        const trackStagePassingId = stageInfo?.content?.trackStagePassingId

        const finishStage = useCallback(() => {
            eventEmitter.emit(
                TRACK_PASSING_EVENTS.FINISH_STAGE_CONTENT,
                trackStagePassingId
            )
        }, [trackStagePassingId])

        if (!course) return null

        return (
            <div
                className={styles.wrapper}
                style={{ height: componentHeight }}
                ref={componentRef}
            >
                <PlayerView
                    courseData={course}
                    onViewMaterial={finishStage}
                    progress={lessonInfo?.progress}
                />
            </div>
        )
    }
)
