import React from 'react'
import { LOCAL } from 'core/local'

import styles from './CommonStatsParticipansCount.module.scss'
import { CommonStatsParticipansCountProps } from './CommonStatsParticipansCount.types'

/** общая статистика отображение кол-ва участников */
export const CommonStatsParticipansCount: React.FC<CommonStatsParticipansCountProps> = React.memo(
    ({ maxCount, passedCount }) =>
        passedCount && maxCount ? (
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    {LOCAL.MESSAGES.NUMBER_PARTICIPANTS_FROM_APPOINTED}
                </div>

                {`${passedCount}/${maxCount}`}
            </div>
        ) : null
)
