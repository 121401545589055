import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { CompetenciesService, DevelopmentGoalsSearchContract } from 'core/api'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps, normalizeDataForSelectAndRadio } from 'utils'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { ROW_GUTTER } from 'consts'

/** Фильтры для реестра целей развития */
export const DevelopmentGoalsFilters: React.FC<FiltersProps<
    DevelopmentGoalsSearchContract
>> = React.memo((props) => {
    const [competenciesData, setCompetenciesData] = useState<LabeledValue[]>()

    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                const sourceData = await CompetenciesService.getAll()

                setCompetenciesData(
                    sourceData.map(normalizeDataForSelectAndRadio)
                )
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [])

    return (
        <ControlGroup type="light">
            <FilterForm {...props}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.DEVELOPMENT_GOAL_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="competenceIds"
                            label={LOCAL.LABELS.COMPETENCE}
                        >
                            <SelectControl
                                values={competenciesData}
                                mode="multiple"
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changeDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changeDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
