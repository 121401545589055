import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { Button, IconsAdapter, Title } from 'components/shared'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ShouldUpdateChecker } from 'components/controls'
import { Tag } from 'antd'
import { safelySetInnerHtml } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './InfoBlock.module.scss'
import stylesCheckHomeworkWithoutEditor from '../../CheckHomeworkWithoutEditor.module.scss'
import { DESCRIPTION_WIDTH_BORDER_VALUE } from './InfoBlock.consts'
import { InfoBlockProps } from './InfoBlock.types'
import { getTaskResult } from './InfoBlock.utils'
import { renderTaskCounter } from './InfoBlock.render'

export const InfoBlock: React.FC<InfoBlockProps> = React.memo(
    ({ disabled, id, content }) => {
        const { toggleElementVisible, elementVisible } = useElementVisible()

        const descriptionRef = React.useRef<HTMLDivElement>(null)

        const [isShowDescriptionBtn, setShowDescriptionBtn] = useState(false)

        useEffect(() => {
            if (
                descriptionRef.current &&
                descriptionRef.current?.offsetWidth >
                    DESCRIPTION_WIDTH_BORDER_VALUE
            ) {
                setShowDescriptionBtn(true)
            }
        }, [])

        return (
            <ShouldUpdateChecker fieldPath={['taskPassings']}>
                {({ getFieldValue }) => {
                    const tasks = getFieldValue(['taskPassings'])

                    const taskResults = getTaskResult(tasks)

                    return (
                        <div
                            className={stylesCheckHomeworkWithoutEditor.wrapper}
                        >
                            <div
                                className={
                                    stylesCheckHomeworkWithoutEditor.homeworkWrapper
                                }
                            >
                                <div className={styles.header}>
                                    <Link
                                        to={
                                            disabled
                                                ? '#'
                                                : ROUTE_NAMES.PA_CHECK_HOMEWORK
                                        }
                                    >
                                        <IconsAdapter iconType="ArrowLeftOutlined" />

                                        {LOCAL.ACTIONS.RETURN}
                                    </Link>

                                    <Tag color="#367AAF" className={styles.tag}>
                                        {`${LOCAL.LABELS.USER} ${id}`}
                                    </Tag>
                                </div>

                                <div
                                    className={cn(
                                        styles.description,
                                        elementVisible &&
                                            styles.descriptionOpened,
                                        isShowDescriptionBtn &&
                                            styles.descriptionCutted
                                    )}
                                    dangerouslySetInnerHTML={safelySetInnerHtml(
                                        content
                                    )}
                                    ref={descriptionRef}
                                />

                                {isShowDescriptionBtn && (
                                    <Button
                                        type="link"
                                        onClick={toggleElementVisible}
                                        className={styles.descriptionBtn}
                                    >
                                        {elementVisible
                                            ? LOCAL.ACTIONS.HIDE_TASK
                                            : LOCAL.ACTIONS.SHOW_ALL_TASK}
                                    </Button>
                                )}
                            </div>

                            <div
                                className={
                                    stylesCheckHomeworkWithoutEditor.referencesWrapper
                                }
                            >
                                <div className={styles.counterWrapper}>
                                    {renderTaskCounter(
                                        tasks.length,
                                        LOCAL.LABELS.TASKS_PARENTAL
                                    )}
                                    {renderTaskCounter(
                                        taskResults.passed +
                                            taskResults.revision,
                                        LOCAL.LABELS.VERIFIED
                                    )}
                                    {renderTaskCounter(
                                        taskResults.passed,
                                        LOCAL.LABELS.CREDITED
                                    )}
                                    {renderTaskCounter(
                                        taskResults.revision,
                                        LOCAL.LABELS.UNCREDITED
                                    )}
                                </div>

                                <Title
                                    type="h5"
                                    text={LOCAL.LABELS.ANSWERS_FROM_OTHER_USERS}
                                    bottomIndent="15"
                                    topIndent="35"
                                />

                                <div className={styles.answersTextInfo}>
                                    {
                                        LOCAL.MESSAGES
                                            .COMPARE_ANSWERS_WITH_CHECKED_TASK
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
