import React from 'react'
import { DatePickerControl, FormItemAdapter } from 'components/controls'
import { Form, Select } from 'antd'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { TRACK_STAGE_SETTINGS_STATUS_MAPPED } from 'consts'
import { WithFormModeProps } from 'App.types'
import { disableDate, getControlClass, isFormModeView } from 'utils'

export const renderStatusField = (
    row: FormListFieldData,
    formMode: WithFormModeProps['formMode']
) => (
    <Form.Item name={[row.name, 'status']} key={row.key}>
        <Select
            options={TRACK_STAGE_SETTINGS_STATUS_MAPPED}
            disabled={isFormModeView(formMode)}
            className={getControlClass({
                formMode,
            })}
        />
    </Form.Item>
)

export const renderFromDateField = (
    row: FormListFieldData,
    formMode: WithFormModeProps['formMode']
) => (
    <FormItemAdapter fieldName={[row.name, 'periodFrom']} key={row.key}>
        <DatePickerControl disabledDate={disableDate()} formMode={formMode} />
    </FormItemAdapter>
)

export const renderToDateField = (
    row: FormListFieldData,
    formMode: WithFormModeProps['formMode']
) => (
    <FormItemAdapter fieldName={[row.name, 'periodTo']} key={row.key}>
        <DatePickerControl disabledDate={disableDate()} formMode={formMode} />
    </FormItemAdapter>
)
