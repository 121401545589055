import React, { FC, memo } from 'react'
import { LOCAL } from 'core/local'
import { PassingStage } from 'components/shared/Passing/PassingStage'
import { getStyles } from 'utils'

import { PassingGreetingsStageProps } from './PassingGreetingsStage.types'
import { UserAssignmentPassingHeader } from '../UserAssignmentPassingHeader'
import { makeIconBlocksProps } from './PassingGreetingsStage.consts'

/** Стадия приветствия прохождения */
export const PassingGreetingsStage: FC<PassingGreetingsStageProps> = memo(
    ({ onStart, questionnaire, numberOfQuestions, numberOfCompetences }) => {
        const { assessmentPortfolioPresentation: presentation } = questionnaire

        return (
            <PassingStage
                header={
                    <UserAssignmentPassingHeader
                        title={presentation?.welcomeWindow?.title}
                        styles={getStyles(
                            presentation?.welcomeWindow?.titleTextSettings
                        )}
                        {...questionnaire}
                    />
                }
                presentationSettings={presentation?.welcomeWindow}
                showGreeting={presentation?.welcomeWindow?.showGreeting}
                onButtonClick={onStart}
                buttonText={LOCAL.ACTIONS.BEGIN}
                iconBlocksProps={makeIconBlocksProps(
                    numberOfCompetences,
                    numberOfQuestions
                )}
            />
        )
    }
)
