import { ColumnsType } from 'antd/lib/table'
import { DOCUMENT_STATUSES, TRACK_TYPES } from 'consts'
import {
    DocumentStatus,
    NotificationDestinationCategoryCode,
    NotificationDestinationSubCategoryCode,
    NotificationMailingTaskAppointmentObjectSearchResultContract,
} from 'core/api'
import { LOCAL } from 'core/local'
import { renderTableColumnWithPopover } from 'utils'

export const getObjectsColumnsByStatus = (
    categoryCode?: NotificationDestinationCategoryCode
) => {
    switch (categoryCode) {
        case NotificationDestinationCategoryCode.Track:
            return [
                {
                    title: LOCAL.LABELS.TYPE,
                    dataIndex: 'type',
                    key: 'type',
                    render: (val: NotificationDestinationSubCategoryCode) =>
                        val && TRACK_TYPES[val],
                },
            ]
        default:
            return []
    }
}

export const getTableObjectsColumnsByCategoryCode = (
    categoryCode?: NotificationDestinationCategoryCode
): ColumnsType<
    NotificationMailingTaskAppointmentObjectSearchResultContract
> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
    },
    ...getObjectsColumnsByStatus(categoryCode),
    {
        title: LOCAL.LABELS.ORGANIZATIONS,
        dataIndex: 'organizations',
        key: 'organizations',
        render: renderTableColumnWithPopover,
    },

    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status: DocumentStatus) => status && DOCUMENT_STATUSES[status],
    },
]
