import { NotificationNewsletterTemplateContract } from 'core/api'
import { getItemId } from 'utils'

/**
 * Функция для подготовки данных шаблона
 * @param template Шаблон рассылки
 */
export const mapResponseToFormData = (
    template: NotificationNewsletterTemplateContract
) => ({
    ...template,
    tagIds: template.tags.map(getItemId),
})
