import { ROUTES_META } from './routeNames.consts'
import { RouteMetaKeyProps, RoutesMetaChildProps } from './routeNames.types'

/**
 * Получить мета информацию по маршруту
 * @param routeName название маршрута
 * @param metaKey ключ мета
 */
export const getRouteMeta = (
    routeName: string,
    metaKey?: RouteMetaKeyProps
) => {
    if (typeof ROUTES_META[routeName] === 'object') {
        if (!metaKey) return

        const meta = ROUTES_META[routeName] as RoutesMetaChildProps

        return meta[metaKey]
    }

    return ROUTES_META[routeName] as string
}
