import React, { FC, useCallback } from 'react'
import {
    AppointmentSearchContract,
    AppointmentSearchResultContract,
    AppointmentSearchTextContract,
    AppointmentsService,
    UserPermission,
} from 'core/api'
import {
    AppointmentsPassingService,
    getPageTitleFromRoute,
    getRowHandlers,
} from 'utils'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage, DictionaryPageActionsProps } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRowClassNameArchive } from 'components/shared'
import { useDictionariesHelper } from 'hooks'
import { useLocation } from 'react-router-dom'

import {
    ASSESSMENT_FILTERS_INITIAL_VALUES,
    AssessmentAssignmentsFilters,
} from './components'
import { getAssessmentAssignmentsTableColumns } from './AssessmentAssignments.config'

/**
 * Список назначений оценки
 */
export const AssessmentAssignments: FC = React.memo(() => {
    const location = useLocation()
    const locales = useDictionariesHelper(['assessmentTypeDictionary'])
    const title = getPageTitleFromRoute(location.pathname)

    /**
     * Рендер колонок таблицы
     * @param actions - набор функций, берется из DictionaryPage
     */
    const renderTableColumns = useCallback(
        ({ reFetchDictionary, updateLoader }: DictionaryPageActionsProps) =>
            getAssessmentAssignmentsTableColumns(
                AppointmentsPassingService,
                {
                    reFetchDictionary,
                    updateLoader,
                },
                locales
            ),
        [locales]
    )

    return (
        <DictionaryPage<
            AppointmentSearchContract,
            AppointmentSearchResultContract,
            AppointmentSearchTextContract
        >
            dictionaryKey={DICTIONARY_KEYS.ASSESSMENT_ASSIGNMENTS}
            searchMethod={AppointmentsService.searchAppointment}
            deleteMethod={AppointmentsService.deleteAppointment}
            contentTitle={title}
            tableColumnsRender={renderTableColumns}
            createRecordLink={{
                path: ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_CREATE,
            }}
            filterComponent={AssessmentAssignmentsFilters}
            tableFiltersOptions={{
                initialValues: ASSESSMENT_FILTERS_INITIAL_VALUES,
            }}
            quickSearchMethod={AppointmentsService.searchAppointmentText}
            createPermissions={[
                UserPermission.AssessmentAppointmentsRead,
                UserPermission.AssessmentAppointmentSave,
            ]}
            rowClassName={getRowClassNameArchive}
            onRow={getRowHandlers({
                url: ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_VIEW,
            })}
        />
    )
})
