import cn from 'classnames'
import React, {
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useRef,
} from 'react'
import { ColorThemeContext } from 'contexts'
import { STAGE_STYLE_OPTIONS_INITIAL } from 'components/pages/Track/components/TrackForm/components/shared/StagesDndArea/StagesDndArea.consts'
import { StageProgress } from 'components/shared'
import { composeStageIconStyles } from 'components/pages/Track/components/TrackForm/components/shared/TrackStageDnD/TrackStageDnD.utils'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { getBlocksCountOfTrackStage } from 'utils'

import styles from './TrackStagePreview.module.scss'
import { StageActions } from '../StageActions'
import { TRACK_PREVIEW_EVENTS } from '../../TrackPreview.events'
import { TrackStageDnDProps } from './TrackStagePreview.types'

export const TrackStagePreview: React.FC<TrackStageDnDProps> = React.memo(
    ({ index, stageStyle, stage, canvasKey }) => {
        const { theme } = useContext(ColorThemeContext)
        const stageRef = useRef<HTMLDivElement>(null)

        const handleChangeStageState = useCallback(() => {
            if (!stage) return

            eventEmitter.emit(TRACK_PREVIEW_EVENTS.MOVE_TO_NEXT_STATUS, {
                currentStageStatus: stage?.iconStatus,
                currentStageIndex: index,
            })
        }, [index, stage])

        useLayoutEffect(() => {
            if (!stage?.iconStatus) return

            const injectedStyle = composeStageIconStyles(
                {
                    stageStyle,
                    stageStatus: stage?.iconStatus,
                    stageIndex: `${index}-${canvasKey}`,
                },
                theme
            )

            const removeInjectedStyle = () => {
                injectedStyle?.remove()
            }

            if (!stageRef.current) return removeInjectedStyle

            return removeInjectedStyle
        }, [index, stageStyle, theme, stage, canvasKey])

        const composeStagePositionStyles = () => {
            const styles = stageStyle?.positionStyles

            if (!styles) return

            const parseStyles = JSON.parse(styles)

            if (!parseStyles) return

            return {
                ...parseStyles,
                ...STAGE_STYLE_OPTIONS_INITIAL,
            }
        }

        const showProgress = useMemo(() => {
            if (stageStyle?.positionStyles) return stage?.showProgress

            return false
        }, [stageStyle, stage])

        return (
            <div
                className={cn(styles.wrapper, styles.wrapperOriginal)}
                data-id={`${index}-${canvasKey}`}
                style={composeStagePositionStyles()}
                ref={stageRef}
            >
                <div className={styles.img}>
                    <StageActions
                        onChangeStageState={handleChangeStageState}
                        className={styles.trackStageDndStageAction}
                    />
                </div>

                {showProgress && (
                    <StageProgress
                        stageCount={getBlocksCountOfTrackStage(
                            stage?.materialType,
                            stage?.totalThematicBlocksCount
                        )}
                        currentStage={0}
                    />
                )}
            </div>
        )
    }
)
