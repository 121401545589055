import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { NotificationTagContract } from 'core/api'
import { TableColumnsRenderOptions, defaultSorter } from 'components/layouts'

import { DistributionTagsRowControls } from './components'

export const getDistributionTagsColumns = ({
    reFetchDictionary,
}: TableColumnsRenderOptions): ColumnsType<NotificationTagContract> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
    },
    {
        render: (tableRow) => (
            <DistributionTagsRowControls
                tableRow={tableRow}
                onEdit={reFetchDictionary}
            />
        ),
    },
]
