import React, { useCallback, useEffect } from 'react'
import {
    AssessmentPortfolioDictionariesService,
    AssessmentProjectContract,
    AssessmentProjectPublishContract,
    AssessmentProjectService,
} from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { Form, Tabs } from 'antd'
import { FormProps } from 'components/forms/forms.types'
import { HiddenField } from 'components/controls'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WithBlockUserRoutingComponentProps, WithLoaderProps } from 'HOCs'
import {
    createConfirmPopup,
    getFormPopupTitle,
    isAssessmentTypeEstimate360,
    isFormModeView,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'
import { useHttp } from 'hooks'

import { AssessmentProjectFormValues } from './AssessmentProjectsForm.types'
import { AssessmentProjectsCommonSettings } from '../AssessmentProjectsCommonSettings'
import { AssessmentProjectsNotifications } from '../AssessmentProjectsNotifications'
import {
    DEFAULT_TAB_KEY,
    TABS_PANE_OPTIONS,
} from './AssessmentProjectsForm.consts'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
    validateCompetenciesForManagerEstimate,
    validateRespondentsRoleCount,
} from './AssessmentProjectsForm.utils'

/** Форма создания оценочного проекта*/
export const AssessmentProjectsForm: React.FC<
    ComponentCommonProps &
        WithBlockUserRoutingComponentProps &
        FormProps<AssessmentProjectContract> &
        Partial<WithLoaderProps>
> = React.memo(
    ({
        formMode,
        onBlockUserRouting,
        isSaveDocument,
        initialValuesForEdit,
        reFetchInitialFormValues,
        updateLoader,
    }) => {
        const [form] = Form.useForm()
        const history = useHistory()
        const [getGoals, goals] = useHttp(
            AssessmentPortfolioDictionariesService.getAll
        )

        /** Запрос на отправку формы
         * @param body - данные для отправки
         * @param isSaveDocument - сохранить или опубликовать данные
         */
        const onRequestSend = useCallback(
            async (
                body: AssessmentProjectPublishContract,
                isSaveDocument?: boolean
            ) => {
                try {
                    updateLoader?.(true)

                    if (isSaveDocument) {
                        await AssessmentProjectService.save({ body })
                    } else {
                        await AssessmentProjectService.publish({ body })
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(
                                ROUTE_NAMES.ASSESSMENT_PROJECTS,
                                'pageTitle'
                            )
                        ),
                        content: isSaveDocument
                            ? LOCAL.MESSAGES
                                  .ASSESSMENT_PROJECTS_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES
                                  .ASSESSMENT_PROJECTS_CREATE_SUCCESS_MESSAGE,
                        onOk: () => {
                            history.push(ROUTE_NAMES.ASSESSMENT_PROJECTS)
                        },
                        onCancel: () => {
                            if (initialValuesForEdit?.id)
                                reFetchInitialFormValues?.(
                                    initialValuesForEdit?.id
                                )
                            else {
                                form.resetFields()
                            }
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                form,
                history,
                initialValuesForEdit,
                onBlockUserRouting,
                reFetchInitialFormValues,
                updateLoader,
            ]
        )

        /**
         * Обработчик отправки формы
         * @param values - значения полей формы
         */
        const handleFinish = useCallback(
            async (values: AssessmentProjectFormValues) => {
                try {
                    const body = mapFormDataToRequest(values)

                    if (isSaveDocument) {
                        onRequestSend(body, true)
                    } else if (
                        !isAssessmentTypeEstimate360(values.assessmentType) ||
                        validateRespondentsRoleCount(
                            values.assessmentPortfolios
                        )
                    ) {
                        onRequestSend(body)

                        validateCompetenciesForManagerEstimate(values, form)
                    } else {
                        createConfirmPopup({
                            title:
                                LOCAL.MESSAGES
                                    .ASSESSMENTS_PROJECTS_RESPONDENTS_VALIDATION_ERROR,
                            okText: LOCAL.ACTIONS.SAVE_AS_DRAFT,
                            onOk: () => {
                                onRequestSend(body, true)
                            },
                        })
                    }
                } catch (error) {
                    console.error(error)
                }
            },
            [isSaveDocument, onRequestSend, form]
        )

        /**
         * Обработчик изменений формы
         */
        const handleValuesChange = useCallback(() => {
            if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                onBlockUserRouting?.()
            }
        }, [form, formMode, onBlockUserRouting])

        useEffect(() => {
            if (initialValuesForEdit?.id) {
                form.setFieldsValue(mapResponseToFormData(initialValuesForEdit))
            }
        }, [form, initialValuesForEdit])

        useEffect(() => {
            getGoals()
        }, [getGoals])

        return (
            <Form
                form={form}
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                id={FORM_IDS.ASSESSMENT_PROJECT_FORM}
            >
                <HiddenField
                    fieldName={['managerParameters', 'assessmentId']}
                />

                <Tabs
                    defaultActiveKey={DEFAULT_TAB_KEY}
                    size="large"
                    type="card"
                >
                    <Tabs.TabPane
                        forceRender
                        {...TABS_PANE_OPTIONS.COMMON_OPTIONS}
                    >
                        <AssessmentProjectsCommonSettings
                            formMode={formMode}
                            form={form}
                            goals={goals}
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        forceRender
                        {...TABS_PANE_OPTIONS.NOTIFICATIONS}
                    >
                        <AssessmentProjectsNotifications formMode={formMode} />
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        )
    }
)
