import React, { ReactNode } from 'react'
import { Button } from 'components/shared/Button'

import styles from './CatalogSection.module.scss'
import { MIN_ITEMS_COUNT } from './CatalogSection.consts'
import { RenderShowAllButtonProps } from './CatalogSection.types'

/** функция рендера кнопки "Показать все" */
export const renderShowAllButton = ({
    elemsCount,
    onClick,
    btnText,
}: RenderShowAllButtonProps) => {
    if (elemsCount && elemsCount > MIN_ITEMS_COUNT) {
        return (
            <Button type="link" onClick={onClick}>
                {btnText}
            </Button>
        )
    }
}

/**
 * Получить header по умолчанию для секции каталога
 * @param name - имя секции
 * @param showAllButton - рендер кнопки "показать все"
 */
export const getCatalogSectionHeader = (
    name: string,
    showAllButton?: ReactNode
) => (
    <>
        <h2 className={styles.title}>{name}</h2>

        {showAllButton}
    </>
)
