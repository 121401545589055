import React from 'react'
import lowerFirst from 'lodash/lowerFirst'
import { COL_xl8_lg12_xs24, DATE_FORMAT, ROW_GUTTER } from 'consts'
import { Col, Row, Space } from 'antd'
import {
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    InputNumberControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import {
    disableDate,
    isThemeBlockTypeFaceToFace,
    isThemeBlockTypeWebConference,
} from 'utils'

import { ThemeBlockProps } from '../ThemeBlock/ThemeBlock.types'
import { getThemeBlockType } from '../ThemeBlock/ThemeBlock.utils'

/** Расписание с информацией для очной встречи и вебинара */
export const ScheduleDates: React.FC<ThemeBlockProps> = React.memo(
    ({ field, disabled, formMode }) => (
        <ShouldUpdateChecker fieldPath={['lessons']}>
            {({ getFieldValue }) => {
                const themeBlockType = getThemeBlockType(getFieldValue, field)
                const isFaceToFace = isThemeBlockTypeFaceToFace(themeBlockType)
                const isWebConference = isThemeBlockTypeWebConference(
                    themeBlockType
                )

                if (!(isFaceToFace || isWebConference)) return

                const namePath = [field.name, lowerFirst(themeBlockType)]

                return (
                    <Col xs={24}>
                        <Row gutter={ROW_GUTTER}>
                            <Col {...COL_xl8_lg12_xs24}>
                                <Row gutter={ROW_GUTTER}>
                                    <Col xs={12}>
                                        <FormItemAdapter
                                            fieldName={namePath.concat('date')}
                                            label={
                                                LOCAL.LABELS.DATE_TIME_OF_EVENT
                                            }
                                            required
                                        >
                                            <DatePickerControl
                                                showTime={{
                                                    format: DATE_FORMAT.HH_mm,
                                                }}
                                                format={
                                                    DATE_FORMAT.LIST_WITH_TIME
                                                }
                                                allowClear={false}
                                                disabledDate={disableDate({
                                                    withTime: true,
                                                })}
                                                disabled={disabled}
                                                formMode={formMode}
                                            />
                                        </FormItemAdapter>
                                    </Col>

                                    <Col xs={12}>
                                        <FormItemAdapter
                                            label={LOCAL.LABELS.DURATION}
                                        >
                                            <Space>
                                                <FormItemAdapter
                                                    fieldName={namePath.concat(
                                                        'durationHours'
                                                    )}
                                                >
                                                    <InputNumberControl
                                                        min={0}
                                                        addonAfter={LOCAL.HOURS}
                                                        disabled={disabled}
                                                        formMode={formMode}
                                                    />
                                                </FormItemAdapter>

                                                <FormItemAdapter
                                                    fieldName={namePath.concat(
                                                        'durationMinutes'
                                                    )}
                                                >
                                                    <InputNumberControl
                                                        min={0}
                                                        addonAfter={
                                                            LOCAL.MINUTES
                                                        }
                                                        disabled={disabled}
                                                        formMode={formMode}
                                                    />
                                                </FormItemAdapter>
                                            </Space>
                                        </FormItemAdapter>
                                    </Col>
                                </Row>
                            </Col>

                            <Col {...COL_xl8_lg12_xs24}>
                                <FormItemAdapter
                                    fieldName={namePath.concat(
                                        isWebConference ? 'link' : 'place'
                                    )}
                                    label={
                                        isWebConference
                                            ? LOCAL.LABELS.LINK
                                            : LOCAL.LABELS.PLACE
                                    }
                                    required
                                >
                                    <InputControl
                                        disabled={disabled}
                                        formMode={formMode}
                                    />
                                </FormItemAdapter>
                            </Col>

                            {isFaceToFace && (
                                <Col {...COL_xl8_lg12_xs24}>
                                    <Row gutter={ROW_GUTTER}>
                                        <Col xs={16}>
                                            <FormItemAdapter
                                                fieldName={namePath.concat(
                                                    'additionalInformation'
                                                )}
                                                label={
                                                    LOCAL.LABELS.ADDITIONAL_CUT
                                                }
                                            >
                                                <InputControl
                                                    disabled={disabled}
                                                    formMode={formMode}
                                                />
                                            </FormItemAdapter>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Col>
                )
            }}
        </ShouldUpdateChecker>
    )
)
