import { FnActionProps } from 'App.types'
import {
    TrackEvent,
    TrackPassingAssistantEventContract,
    TrackStageEvent,
} from 'core/api'
import { TrackStageMode } from 'components/pages/Track/components/TrackForm/TrackForm.types'

export enum InfoAssistantType {
    'Track' = 'Track',
    'Stage' = 'Stage',
}
export interface InfoAssistantProps {
    type?: InfoAssistantType
    mode?: TrackStageMode
    event?: TrackEvent | TrackStageEvent
    visible?: boolean
    trackPassingId?: number
    stageNumber?: number
    onCloseAssistant?: FnActionProps
}

export interface InfoAssistantConfigProps
    extends Partial<Omit<TrackPassingAssistantEventContract, 'event'>> {
    event: TrackStageEvent | TrackEvent
}
