import React, { FC } from 'react'
import {
    CRITERIA_SCALES_REQUIRED_PERMISSIONS,
    DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS,
    TRAINING_MATERIALS_PERMISSIONS,
} from 'consts'
import {
    CriteriaScales,
    CriteriaScalesFormContainer,
    DocumentEditorEdit,
    DocumentsEditor,
    ErrorPage,
    Reports,
    TrainingMaterials,
} from 'components/pages'
import {
    MaterialTasks,
    MaterialsTaskDetail,
} from 'components/pages/MaterialsTasks'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { TrainingMaterialInfo } from 'components/pages/TrainingMaterialInfo/TrainingMaterialInfo'
import { TrainingMaterialView } from 'components/pages/TrainingMaterialView'
import { UserPermission } from 'core/api'

import { QuestionnairesRouting } from './QuestionnairesRouting'

export const MaterialsRouting: FC = React.memo(() => (
    <Switch>
        <Route path={ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES}>
            <QuestionnairesRouting />
        </Route>

        <ProtectedRoute
            requiredPermissions={[
                ...CRITERIA_SCALES_REQUIRED_PERMISSIONS,
                UserPermission.ScaleSave,
            ]}
            path={`${ROUTE_NAMES.CRITERIA_SCALES_CREATE}/:id`}
        >
            <CriteriaScalesFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...CRITERIA_SCALES_REQUIRED_PERMISSIONS,
                UserPermission.ScaleSave,
            ]}
            path={`${ROUTE_NAMES.CRITERIA_SCALES_EDIT}/:id`}
        >
            <CriteriaScalesFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...CRITERIA_SCALES_REQUIRED_PERMISSIONS,
                UserPermission.ScaleSave,
            ]}
            path={ROUTE_NAMES.CRITERIA_SCALES_CREATE}
        >
            <CriteriaScalesFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.ScalesRead,
            ]}
            path={ROUTE_NAMES.CRITERIA_SCALES}
        >
            <CriteriaScales />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={TRAINING_MATERIALS_PERMISSIONS}
            path={ROUTE_NAMES.TRAINING_MATERIALS}
            exact
        >
            <TrainingMaterials />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={TRAINING_MATERIALS_PERMISSIONS}
            path={`${ROUTE_NAMES.TRAINING_MATERIALS}/:id`}
            exact
        >
            <TrainingMaterialView />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={TRAINING_MATERIALS_PERMISSIONS}
            path={`${ROUTE_NAMES.TRAINING_MATERIALS_INFO}/:id`}
        >
            <TrainingMaterialInfo />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS,
                UserPermission.TextDocumentSave,
            ]}
            path={`${ROUTE_NAMES.DOCUMENTS_EDITOR_EDIT}/:id`}
        >
            <DocumentEditorEdit />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.TextDocumentsRead,
            ]}
            path={ROUTE_NAMES.DOCUMENTS_EDITOR}
        >
            <DocumentsEditor />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS,
                UserPermission.TextDocumentSave,
            ]}
            path={`${ROUTE_NAMES.TASKS_CREATE}`}
        >
            <MaterialsTaskDetail formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS,
                UserPermission.TextDocumentSave,
            ]}
            path={`${ROUTE_NAMES.TASKS_EDIT}/:id`}
        >
            <MaterialsTaskDetail formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.TextDocumentsRead,
            ]}
            path={ROUTE_NAMES.TASKS}
        >
            <MaterialTasks />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.TextDocumentsRead,
            ]}
            path={ROUTE_NAMES.REPORTS}
        >
            <Reports />
        </ProtectedRoute>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
