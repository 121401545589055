import React from 'react'
import Select from 'antd/lib/select'
import cn from 'classnames'
import styles from 'components/controls/SelectControl/SelectControl.module.scss'
import { AddonAfterDefault } from 'components/shared'
import { AssessmentType, UserPermission } from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { FnActionRequiredProps } from 'App.types'
import { LOCAL } from 'core/local'
import { PLACEHOLDERS } from 'core/local/placeholders.local.config'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    getCommonSelectItemProps,
    isFormModeView,
    renderQuestionnairesDropdown,
} from 'utils'

import { CompetenceFormContract } from './CircleParameters.types'
import { GetRenderColumnFnProps } from '../ManagerParametersRow/ManagerParametersRow.types'
import { questionnairesFetch } from '../ManagerParametersRow/ManagerParametersRow.utils'

/** получить функцию рендера для схемы таблицы контрола, с учетом ролей */
export const getRenderColumnFn = ({
    setQuestionnaires,
    questionnaires,
    formMode,
    disabled,
}: GetRenderColumnFnProps) => {
    const renderTableColumns = (
        onChangeRow: FnActionRequiredProps<CompetenceFormContract>
    ): ColumnsType<CompetenceFormContract> => [
        {
            title: LOCAL.LABELS.COMPETENCE,
            dataIndex: 'name',
            key: 'name',
            width: '50%',
        },
        {
            title: (
                <div className="required-control">
                    {LOCAL.LABELS.ASSESSMENT_SELF}
                </div>
            ),
            dataIndex: 'selfQuestionnaireId',
            key: 'selfQuestionnaireId',
            width: '25%',
            render: (_, record) => {
                const getInitialOptions = ({
                    selfQuestionnaireId,
                    selfQuestionnaireName,
                }: CompetenceFormContract) => {
                    if (!selfQuestionnaireName || !selfQuestionnaireId) return

                    return [
                        {
                            ...getCommonSelectItemProps(selfQuestionnaireId),
                            label: selfQuestionnaireName,
                        },
                    ]
                }

                return (
                    <div
                        className={cn(
                            styles.wrapper,
                            styles.wrapperAddonAfter,
                            'form-control'
                        )}
                    >
                        <Select<number>
                            onFocus={() =>
                                questionnairesFetch(
                                    AssessmentType.Estimate_360,
                                    record.id,
                                    setQuestionnaires,
                                    questionnaires
                                )
                            }
                            onChange={(value) => {
                                onChangeRow({
                                    ...record,
                                    selfQuestionnaireId: value,
                                })
                            }}
                            placeholder={PLACEHOLDERS.SELECT}
                            value={record.selfQuestionnaireId}
                            options={
                                questionnaires?.[record.id] ||
                                getInitialOptions(record)
                            }
                            disabled={isFormModeView(formMode) || disabled}
                            dropdownRender={renderQuestionnairesDropdown({
                                url: `${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_CREATE}?type=Assessment&competenceId=${record.id}`,
                                title: LOCAL.LABELS.QUESTIONNAIRE_TEMPLATE_ADD,
                                permissions: [UserPermission.QuestionnaireSave],
                            })}
                            notFoundContent={LOCAL.LABELS.EMPTY_DATA}
                        />

                        <div className={styles.addonAfterContent}>
                            <AddonAfterDefault
                                url={`${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW}/${record.selfQuestionnaireId}`}
                                disabled={!record.selfQuestionnaireId}
                            />
                        </div>
                    </div>
                )
            },
        },
        {
            title: (
                <div className="required-control">
                    {LOCAL.LABELS.RESPONDENTS}
                </div>
            ),
            dataIndex: 'respondentQuestionnaireId',
            key: 'respondentQuestionnaireId',
            width: '25%',
            render: (_, record) => {
                const getInitialOptions = ({
                    respondentQuestionnaireId,
                    respondentQuestionnaireName,
                }: CompetenceFormContract) => {
                    if (
                        !respondentQuestionnaireName ||
                        !respondentQuestionnaireId
                    )
                        return

                    return [
                        {
                            ...getCommonSelectItemProps(
                                respondentQuestionnaireId
                            ),
                            label: respondentQuestionnaireName,
                        },
                    ]
                }

                return (
                    <div
                        className={cn(
                            styles.wrapper,
                            styles.wrapperAddonAfter,
                            'form-control'
                        )}
                    >
                        <Select<number>
                            onFocus={() =>
                                questionnairesFetch(
                                    AssessmentType.Estimate_360,
                                    record.id,
                                    setQuestionnaires,
                                    questionnaires
                                )
                            }
                            onChange={(value) => {
                                onChangeRow({
                                    ...record,
                                    respondentQuestionnaireId: value,
                                })
                            }}
                            placeholder={PLACEHOLDERS.SELECT}
                            value={record.respondentQuestionnaireId}
                            options={
                                questionnaires?.[record.id] ||
                                getInitialOptions(record)
                            }
                            disabled={isFormModeView(formMode) || disabled}
                            dropdownRender={renderQuestionnairesDropdown({
                                url: `${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_CREATE}?type=Assessment&competenceId=${record.id}`,
                                title: LOCAL.LABELS.QUESTIONNAIRE_TEMPLATE_ADD,
                                permissions: [UserPermission.QuestionnaireSave],
                            })}
                            notFoundContent={LOCAL.LABELS.EMPTY_DATA}
                        />

                        <div className={styles.addonAfterContent}>
                            <AddonAfterDefault
                                url={`${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW}/${record.respondentQuestionnaireId}`}
                                disabled={!record.respondentQuestionnaireId}
                            />
                        </div>
                    </div>
                )
            },
        },
    ]

    return renderTableColumns
}
