import ReactDOM from 'react-dom'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import { Form, Tabs } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { SwitchControl } from 'components/controls'
import { usePortal } from 'hooks/usePortal'

import styles from './SingleStageVisualisationOptions.module.scss'
import stylesTrackStagesVisualization from '../TrackStagesVisualization/TrackStagesVisualization.module.scss'
import { SingleStageVisualisationOptionTab } from '../SingleStageVisualisationOptionTab'
import { SingleStageVisualisationOptionsProps } from './SingleStageVisualisationOptions.types'

/**
 * Компонент настройки визуализации этапа трека адаптации
 */
export const SingleStageVisualisationOptions: React.FC<SingleStageVisualisationOptionsProps> = React.memo(
    ({
        onChangeStageOptionsVisible,
        stageIndex,
        form,
        elementRef,
        canvasKey,
    }) => {
        const handleChangeTabs = useCallback(
            (activeKey: string) => {
                form?.setFields([
                    {
                        name: ['stages', stageIndex, 'iconStatus'],
                        value: activeKey,
                    },
                ])
            },
            [form, stageIndex]
        )

        const stageOptionsTmpl = useMemo(
            () => (
                <div ref={elementRef} className={styles.body}>
                    <header>
                        <h3>{LOCAL.LABELS.STAGE_VISUALIZATION_SET}</h3>

                        <Button
                            type="link"
                            icon={<IconsAdapter iconType="CustomIconClose" />}
                            onClick={onChangeStageOptionsVisible}
                        />
                    </header>

                    <Form.Item
                        name={['stages', stageIndex, 'showProgress']}
                        className={stylesTrackStagesVisualization.progress}
                    >
                        <SwitchControl
                            addonAfter={LOCAL.LABELS.SHOW_PROGRESS}
                        />
                    </Form.Item>

                    <Tabs
                        defaultActiveKey="beforeStart"
                        className={styles.tab}
                        tabBarGutter={ROW_GUTTER}
                        type="line"
                        onChange={handleChangeTabs}
                    >
                        <Tabs.TabPane
                            forceRender
                            tab={LOCAL.LABELS.PASSING_BEFORE}
                            key="beforeStart"
                        >
                            <SingleStageVisualisationOptionTab
                                stageIndex={stageIndex}
                                stageStatus="beforeStart"
                                form={form}
                                canvasKey={canvasKey}
                            />
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            forceRender
                            tab={LOCAL.LABELS.IN_PROCESS}
                            key="inProcess"
                        >
                            <SingleStageVisualisationOptionTab
                                hideBackgroundImagePicker
                                stageIndex={stageIndex}
                                stageStatus="inProcess"
                                form={form}
                                canvasKey={canvasKey}
                            />
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            forceRender
                            tab={LOCAL.LABELS.PASSING}
                            key="passing"
                        >
                            <SingleStageVisualisationOptionTab
                                stageIndex={stageIndex}
                                stageStatus="passing"
                                form={form}
                                canvasKey={canvasKey}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            ),
            [
                elementRef,
                onChangeStageOptionsVisible,
                stageIndex,
                handleChangeTabs,
                form,
                canvasKey,
            ]
        )

        const target = usePortal(
            `.${stylesTrackStagesVisualization.wrapper}.canvas-${canvasKey}`,
            styles.wrapper
        )

        useEffect(() => {
            handleChangeTabs('beforeStart')
        }, [handleChangeTabs])

        return ReactDOM.createPortal(stageOptionsTmpl, target)
    }
)
