import React, { useCallback, useMemo } from 'react'
import { AddButton, TableAdapter } from 'components/shared'
import { Col, Row } from 'antd'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { PositionProfilesService } from 'core/api'
import { SearchTableShowButton } from 'components/controls'
import { getCompetenciesIds } from 'components/pages/AssessmentProjects/components/AssessmentProjectsForm/AssessmentProjectsForm.utils'
import { isFormModeView } from 'utils'

import styles from './AssessmentProjectsCoworkerCompetencies.module.scss'
import { AddCompetenciesControl } from '../AddCompetenciesControl'
import { AssesmentProjectCompetenceFormContract } from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.types'
import { AssessmentProjectsCoworkerCompetenciesProps } from './AssessmentProjectsCoworkerCompetencies.types'
import { POSITION_PROFILE_SEARCH_TABLE_COLUMNS } from './AssessmentProjectsCoworkerCompetencies.consts'
import { PositionProfileFilters } from '../PositionProfileFilters'
import {
    getCompetenciesColumns,
    mapCompetenciesByGroup,
} from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.utils'

/** Компонент компетенций сотрудника оценки руководителем */
export const AssessmentProjectsCoworkerCompetencies: React.FC<AssessmentProjectsCoworkerCompetenciesProps> = React.memo(
    ({ onChange, value: coworker, formMode }) => {
        const selectedCompetenciesIds = useMemo(
            () => getCompetenciesIds(coworker?.competencies),
            [coworker]
        )

        const selectedPositionProfileId = useMemo(
            () => [Number(coworker?.positionProfileId)],
            [coworker]
        )

        /** Заголовок для контроллера добавления компетенций */
        const title = useMemo(
            () => (
                <span>
                    {`${LOCAL.LABELS.COMPETENCIES} ${LOCAL.LABELS.POSITION_PROFILE_SHORT} `}

                    {coworker?.positionName ? (
                        `"${coworker?.positionName}"`
                    ) : (
                        <b className={styles.noPositionProfile}>
                            {LOCAL.LABELS.POSITION_PROFILE_NOT_EXIST}
                        </b>
                    )}
                </span>
            ),
            [coworker]
        )

        /** Изменение компетенций сотрудника
         * @param competencies - компетенции
         * @param positionName - наименование должности профиля должности
         * @param profileId - id профиля должности по которому были добавлены компетенции
         */
        const onCompetenciesChange = useCallback(
            (
                competencies: AssesmentProjectCompetenceFormContract[],
                positionName?: string,
                positionProfileId?: number
            ) => {
                onChange?.({
                    ...coworker!,
                    competencies,
                    positionName: positionName || coworker?.positionName,
                    positionProfileId:
                        positionProfileId || coworker?.positionProfileId,
                })
            },
            [onChange, coworker]
        )

        /** Обработчик выбора компетенций по профилю должности */
        const handleSelectPositionProfile = useCallback(
            async (result?: number[]) => {
                try {
                    const positionProfile = await PositionProfilesService.get({
                        id: Number(result?.[0]),
                    })

                    onCompetenciesChange(
                        mapCompetenciesByGroup(
                            positionProfile.competencies,
                            positionProfile.id
                        ),
                        positionProfile.position.name,
                        positionProfile.id
                    )
                } catch (err) {
                    console.error(err)
                }
            },
            [onCompetenciesChange]
        )

        /** Обработчик удаления компетенции
         * @param competenceId - id компетенции, которую необходимо удалить
         */
        const handleRemoveCompetencies = useCallback(
            (competenceId: number) => () => {
                onChange?.({
                    ...coworker!,
                    competencies: coworker!.competencies?.filter(
                        ({ competence: { id } }) => id !== competenceId
                    ),
                })
            },
            [onChange, coworker]
        )

        return (
            <>
                <div className={styles.controlsWrapper}>
                    <AddCompetenciesControl
                        onCompetenciesChange={onCompetenciesChange}
                        title={title}
                        initialValues={selectedCompetenciesIds}
                        formMode={formMode}
                        selectedCompetencies={coworker?.competencies}
                        required
                    />

                    <SearchTableShowButton
                        tableSearchOptions={{
                            formId: FORM_IDS.COWORKERS_PROFILES_FORM,
                            searchMethod:
                                PositionProfilesService.searchForModal,
                            tableColumns: POSITION_PROFILE_SEARCH_TABLE_COLUMNS,
                            filterComponent: PositionProfileFilters,
                            rowSelectionType: 'radio',
                            onSelectTableItem: handleSelectPositionProfile,
                            initialValues: selectedPositionProfileId,
                        }}
                        modalTitle={LOCAL.ACTIONS.CHOOSE_POSITION_PROFILE}
                        modalWidth={MODAL_WIDTH.XL}
                        className={styles.positionProfile}
                    >
                        <AddButton
                            buttonText={`${LOCAL.ACTIONS.SELECT} ${LOCAL.LABELS.POSITION_PROFILE_SHORT}`}
                            disabled={isFormModeView(formMode)}
                        />
                    </SearchTableShowButton>
                </div>

                <Row>
                    <Col xs={12}>
                        {!!coworker?.competencies?.length && (
                            <TableAdapter
                                dataSource={coworker.competencies}
                                columns={getCompetenciesColumns(
                                    handleRemoveCompetencies,
                                    formMode
                                )}
                                rowKey={(record) => record.competence.id}
                                pagination={false}
                            />
                        )}
                    </Col>
                </Row>
            </>
        )
    }
)
