import { AssessmentType } from 'core/api'
import { ESTIMATE_360_SHORT_CODES, MANAGER_SHORT_CODES } from 'consts'
import { Store } from 'App.types'

export const SHORT_CODES: Store<Store> = {
    [AssessmentType.Estimate_360]: ESTIMATE_360_SHORT_CODES,
    [AssessmentType.EstimateByManager]: MANAGER_SHORT_CODES,
}

export const FIELD_NAMES = {
    CIRCLE: 'circle',
    MANAGER: 'manager',
    SELF_ASSESSMENT: 'selfAssessment',
}
