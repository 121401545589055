import React from 'react'
import cn from 'classnames'
import styles from 'components/controls/SelectControl/SelectControl.module.scss'
import Select, { LabeledValue } from 'antd/lib/select'
import { AddButton } from 'components/shared/AddButton'
import {
    AssessmentRole,
    UserContract,
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import {
    CoworkersFilters,
    SearchTableShowButton,
    TableFiltersOptionsProps,
    getCoworkersTableColumns,
} from 'components/controls'
import { FORM_IDS } from 'core/configs'
import {
    FnActionProps,
    FnActionRequiredProps,
    FormMode,
    WithDisabledProps,
} from 'App.types'
import { LOCAL } from 'core/local'
import { PLACEHOLDERS } from 'core/local/placeholders.local.config'
import {
    disableSelectOptions,
    excludeSelectOptions,
    getFullFio,
    isFormModeView,
    renderOrganizationName,
    renderPositionName,
} from 'utils'

import { RespondentFormContract } from './AddRespondentsTableControl.types'

/** нормализация данных, полученных через `getEntitiesByIds`, для `value` и внутреннего сейта компонента */
export const normalizeFetchDataForValue = (
    newData: UserContract[],
    oldData?: RespondentFormContract[]
): RespondentFormContract[] => {
    const normalize = (users: UserContract[]) =>
        users.map((el, i) => ({
            id: -i,
            userId: el.id,
            name: getFullFio(el),
            positions: renderPositionName(el),
            organizations: renderOrganizationName(el),
        }))

    if (!oldData) return normalize(newData)

    const oldIds = oldData.map((el) => el.userId || el.id)
    const newDataFiltered = newData.filter((el) => !oldIds.includes(el.id))

    return [...oldData, ...normalize(newDataFiltered)]
}

interface GetRespondentsColumnsProps extends WithDisabledProps {
    assessmentRoles?: LabeledValue[]
    onRespondentSelect?: FnActionRequiredProps<
        RespondentFormContract,
        FnActionProps
    >
    formHasManagerCompetencies?: boolean
    formMode?: FormMode
    commonTableFilterOptions?: TableFiltersOptionsProps<{
        structuralUnitsIds: number[]
    }>
}

/**
 * Получить список возможных значений для ролей респондентов, в зависимости от управленческой группы компетенции
 * портфеля
 * @param assessmentRoles - роли респондентов
 * @param formHasManagerCompetencies - есть ли компетенция из управленической группы
 */
export const getAssessmentRoles = (
    assessmentRoles: LabeledValue[],
    formHasManagerCompetencies?: boolean
) => {
    if (!formHasManagerCompetencies)
        return disableSelectOptions(assessmentRoles, [
            AssessmentRole.Subordinate,
        ])

    return assessmentRoles
}

/** получить функцию рендера для схемы таблицы контрола, с учетом ролей */
export const getRenderColumnFn = ({
    assessmentRoles,
    onRespondentSelect,
    formHasManagerCompetencies,
    formMode,
    commonTableFilterOptions,
}: GetRespondentsColumnsProps) => (
    onChangeRow: FnActionRequiredProps<RespondentFormContract>
): ColumnsType<RespondentFormContract> => [
    {
        title: <div className="required-control">{LOCAL.LABELS.ROLE}</div>,
        dataIndex: 'role',
        key: 'role',
        width: '200px',
        render: (_, record) => (
            <div className={cn(styles.wrapper, 'form-control')}>
                <Select<AssessmentRole>
                    onChange={(value) => {
                        onChangeRow({ ...record, role: value })
                    }}
                    placeholder={PLACEHOLDERS.SELECT}
                    value={record.role}
                    options={
                        assessmentRoles &&
                        getAssessmentRoles(
                            [
                                ...excludeSelectOptions(assessmentRoles, [
                                    AssessmentRole.Expert,
                                    AssessmentRole.GroupExpert,
                                    AssessmentRole.SelfAssessment,
                                ]),
                            ],
                            formHasManagerCompetencies
                        )
                    }
                    disabled={isFormModeView(formMode)}
                />
            </div>
        ),
    },
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'name',
        key: 'name',
        render: (_, record) =>
            record.userId ? (
                record.name
            ) : (
                <SearchTableShowButton<
                    UserModalSearchContract,
                    UserModalContract
                >
                    tableSearchOptions={{
                        formId: FORM_IDS.SELECT_RESPONDENT,
                        rowSelectionType: 'radio',
                        searchMethod: UsersService.getForModal,
                        tableColumns: getCoworkersTableColumns(),
                        filterComponent: CoworkersFilters,
                        onSelectTableItem: onRespondentSelect?.(record),
                        tableFiltersOptions: commonTableFilterOptions,
                    }}
                    modalTitle={LOCAL.LABELS.EMPLOYEE_SELECTION}
                >
                    <AddButton buttonText={LOCAL.ACTIONS.ADD_FROM_CATALOG} />
                </SearchTableShowButton>
            ),
    },
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'positions',
        key: 'positions',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizations',
        key: 'organizations',
    },
]

export const filterRespondentKeys = (id: number) => id > 0
