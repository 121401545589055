import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UserPermission } from 'core/api'

export const MAIN_MENU_ITEMS = [
    { title: LOCAL.LABELS.YOUR_PROGRESS, link: ROUTE_NAMES.YOUR_DEVELOPMENT },
    { title: LOCAL.LABELS.QUIZ, link: ROUTE_NAMES.QUESTIONNAIRES_LISTENER },
    { title: LOCAL.LABELS.ASSESSMENTS, link: ROUTE_NAMES.USER_ASSIGNMENTS },
    {
        title: LOCAL.CATALOGS.KNOWLEDGE_BASE,
        link: ROUTE_NAMES.KNOWLEDGE_BASE,
        permissions: [UserPermission.KnowledgeBaseRead],
    },
]
