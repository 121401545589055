import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DATE_FORMAT } from 'consts'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { HomeworkRegistryItemContract } from 'core/api'
import { LOCAL } from 'core/local'
import { WidthRestrictedElement } from 'components/shared'
import {
    formatDate,
    getDateByFormat,
    getFullFio,
    getShortFio,
    isAppointmentTypeActivity,
    renderTableCellTitle,
    restrictBigText,
} from 'utils'

import { CheckHomeworkActions } from './components/CheckHomeworkActions'
import { getStatusMeta } from './CheckHomework.utils'

export const getTableColumnsConfig = (
    props: DictionaryPageActionsProps
): ColumnsType<HomeworkRegistryItemContract> => [
    {
        title: LOCAL.LABELS.TRACK_OR_EVENT,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        render: restrictBigText,
        fixed: 'left',
    },
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type) =>
            isAppointmentTypeActivity(type)
                ? LOCAL.LABELS.EVENT_M
                : LOCAL.LABELS.TRACK,
    },
    {
        title: LOCAL.LABELS.ASSIGNMENT,
        dataIndex: 'appointmentName',
        key: 'appointmentName',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.TRAVEL_DIARY,
        dataIndex: 'lessonName',
        key: 'lessonName',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.USER_ID,
        dataIndex: 'userId',
        key: 'userId',
    },
    {
        title: LOCAL.LABELS.REVIEWER,
        dataIndex: 'tutor',
        key: 'tutor',
        render: (tutor) => (
            <WidthRestrictedElement
                title={getShortFio(tutor)}
                tooltip={{ title: getFullFio(tutor) }}
                showTooltipAlways
            />
        ),
    },
    {
        title: LOCAL.LABELS.COMPLETION_DATE,
        dataIndex: 'onCheckingDate',
        key: 'onCheckingDate',
        sorter: defaultSorter,
        render: (date) => renderTableCellTitle(formatDate(date)),
        width: '200px',
    },
    {
        title: LOCAL.LABELS.CHECK_START_DATE,
        dataIndex: 'checkingStartDate',
        key: 'checkingStartDate',
        sorter: defaultSorter,
        render: (date) =>
            renderTableCellTitle(getDateByFormat(date, DATE_FORMAT.WITH_TIME)),
        width: '200px',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
            const { title, styleModification } = getStatusMeta(status)

            return (
                <WidthRestrictedElement
                    title={title}
                    className={styleModification}
                />
            )
        },
        fixed: 'right',
    },
    {
        width: 150,
        render: (tableRow) => (
            <CheckHomeworkActions tableRow={tableRow} {...props} />
        ),
        align: 'right',
        fixed: 'right',
    },
]
