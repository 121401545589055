import React from 'react'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { TooltipAdapter } from 'components/shared'
import { mapDataNodeToLabeledValue } from 'utils'

import { ManageExperienceGroupProps } from './ManageExperienceGroup.types'

const NO_EXPIRIENCE_ID = 1

export const ManageExperienceGroup: React.FC<ManageExperienceGroupProps> = React.memo(
    ({ industries, manageExperiences, subordinatesNumbers, formMode }) => (
        <ControlGroup type="dark" title={LOCAL.LABELS.MANAGERIAL_EXPERIENCE}>
            <TooltipAdapter
                title={LOCAL.TOOLTIPS.MANAGERIAL_EXPERIENCE_OF_JOB_PROFILE}
            >
                <FormItemAdapter
                    fieldName="manageExperienceId"
                    label={LOCAL.LABELS.MANAGERIAL_SENIORITY}
                    required
                >
                    <SelectControl
                        values={manageExperiences}
                        formMode={formMode}
                    />
                </FormItemAdapter>
            </TooltipAdapter>

            <ShouldUpdateChecker fieldPath={['requiredExperienceId']}>
                {({ getFieldValue }) => (
                    <TooltipAdapter
                        title={LOCAL.TOOLTIPS.SUBORDINATES_COUNT_OF_JOB_PROFILE}
                    >
                        <FormItemAdapter
                            fieldName="manageExperienceSubordinatesNumberId"
                            label={LOCAL.LABELS.SUBORDINATES_COUNT}
                            required={
                                getFieldValue('requiredExperienceId') !==
                                NO_EXPIRIENCE_ID
                            }
                        >
                            <SelectControl
                                values={subordinatesNumbers}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    </TooltipAdapter>
                )}
            </ShouldUpdateChecker>

            <TooltipAdapter
                title={LOCAL.TOOLTIPS.BUDGET_IN_MANAGEMENT_OF_JOB_PROFILE}
            >
                <FormItemAdapter
                    fieldName="manageExperienceBudget"
                    label={LOCAL.LABELS.BUDGET_IN_MANAGEMENT}
                >
                    <InputControl formMode={formMode} />
                </FormItemAdapter>
            </TooltipAdapter>

            <ShouldUpdateChecker fieldPath={['requiredExperienceId']}>
                {({ getFieldValue }) => (
                    <TooltipAdapter
                        title={
                            LOCAL.TOOLTIPS
                                .INDUSTRY_FOR_WHICH_MANAGEMENT_EXPERIENCE_IS_REQUIRED
                        }
                    >
                        <FormItemAdapter
                            fieldName="manageExperienceIndustryId"
                            label={LOCAL.LABELS.BRANCH}
                            required={
                                getFieldValue('requiredExperienceId') !==
                                NO_EXPIRIENCE_ID
                            }
                        >
                            <SelectControl
                                values={mapDataNodeToLabeledValue(industries)}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    </TooltipAdapter>
                )}
            </ShouldUpdateChecker>
        </ControlGroup>
    )
)
