import {
    CatalogItemContract,
    CatalogItemProductCardContract,
    CatalogService,
} from 'core/api'
import { LOCAL } from 'core/local'
import { MouseEvent } from 'react'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Store } from 'App.types'
import { createErrorNotification } from 'utils'

/** Обновить флаг избранного у элемента*/
export const updateCardFavouritesFlag = <
    T extends { id: number; favorites: boolean }
>(
    card: T,
    id: number
) => ({
    ...card,
    favorites: card.id === id ? !card.favorites : card.favorites,
})

/** Обновить статус избранного у карточек продуктов */
export const updateCatalogItemsFavourites = (
    catalog: CatalogItemContract[],
    id: number
) =>
    catalog.map((item) => ({
        ...item,
        productCards: item.productCards.map((card) =>
            updateCardFavouritesFlag(card, id)
        ),
    }))

export const getCatalogFavouritesItems = (catalog?: CatalogItemContract[]) =>
    catalog?.reduce<CatalogItemProductCardContract[]>(
        (acc, el) => [
            ...acc,
            ...el.productCards.filter((card) => card.favorites),
        ],
        []
    )

export const validateCardView = async (productCardId: number) => {
    const response = await CatalogService.checkAvailability({ productCardId })

    return response.result
}

export const handleCardView = (productCardId?: number) => async (
    e: MouseEvent
) => {
    if (!productCardId) return

    e.preventDefault()

    const isAvailable = await validateCardView(productCardId)
    const route = ROUTE_NAMES.PRODUCT_CARD_VIEW

    if (isAvailable) {
        window.open(`${route}/${productCardId}`)

        return
    }

    createErrorNotification(LOCAL.MESSAGES.PRODUCT_CARD_ACCESS_DENIED)
}

export const getUniqueCardsFromCatalog = (catalog?: CatalogItemContract[]) =>
    Object.values(
        catalog?.reduce<Store<CatalogItemProductCardContract>>((acc, block) => {
            block.productCards.forEach((card) => {
                if (acc[card.id]) return

                acc[card.id] = card
            })

            return acc
        }, {}) || {}
    )
