/**
 * Минимальное количество дней, за которое срабатывает уведомление
 */
export const MIN_DAYS_TO_NOTIFICATION = 1

const circleParametersName = ['assessment', 'circleParameters']
const managerParametersName = ['assessment', 'managerParameters']

/** список полей для проверки на изменения */
export const CHECK_FIELDS = [
    ['assessment', 'portfolioId'],
    [...circleParametersName, 'candidateId'],
    [...circleParametersName, 'checkingPersons'],
    [...circleParametersName, 'depersonalizeRespondents'],
    [...circleParametersName, 'respondents'],
    [...managerParametersName, 'checkingPersons'],
    [...managerParametersName, 'supervisor'],
]
