import {
    ActivityCanvasContract,
    ActivityLessonPassingContract,
    ActivityPassingStatus,
    LessonType,
} from 'core/api'
import { LOCAL } from 'core/local'
import {
    isActivityLessonCompletedSuccessfully,
    isActivityLessonInProgress,
    isThematicBlockReadyForPassing,
    isThematicBlockStatusCompleted,
    isThemeBlockTypeQuestionnaire,
} from 'utils'

export const getInitialThematicBlockForPassing = (
    thematicBlocks?: ActivityLessonPassingContract[],
    thematicBlockId?: number
) => {
    if (thematicBlockId) {
        return (
            thematicBlocks?.findIndex(
                (el) => el.lessonId === thematicBlockId
            ) || 0
        )
    }

    const index = thematicBlocks?.findIndex(({ status }) =>
        isThematicBlockReadyForPassing(status)
    )

    return index && index > 0 ? index : 0
}

/**
 * Остались ли попытки для прохождения тематического блока мероприятия
 * @param attemptCount - текущее количество прохождений тематического блока
 * @param attemptNumber - максимально допустимое количество попыток прохождения тематического блока
 */
export const isEnoughAttemptCountForThematicBlock = (
    lesson?: ActivityLessonPassingContract
) =>
    lesson?.attemptNumber
        ? Boolean(lesson?.attemptNumber - lesson?.attemptCount)
        : true

/**
 * Является ли тематический блок доступным для перепрохождения
 */
export const isThematicBlockAvailableForRepassing = (
    lesson?: ActivityLessonPassingContract
) => {
    const validTypes = [LessonType.Course, LessonType.Homework]

    return (
        (!isThemeBlockTypeQuestionnaire(lesson?.type) &&
            isEnoughAttemptCountForThematicBlock(lesson)) ||
        validTypes.some((type) => type === lesson?.type)
    )
}

/**
 * Получить текст для перехода на страницу прохождения трека
 */
export const getPassButtonText = (
    thematicBlock?: ActivityLessonPassingContract,
    activityStatus?: ActivityPassingStatus
) => {
    if (
        isThematicBlockStatusCompleted(thematicBlock?.status) &&
        isThematicBlockAvailableForRepassing(thematicBlock)
    ) {
        return LOCAL.ACTIONS.REPEAT
    }

    if (isActivityLessonInProgress(thematicBlock?.status)) {
        return LOCAL.ACTIONS.CONTINUE
    }

    if (!isActivityLessonCompletedSuccessfully(activityStatus)) {
        return LOCAL.ACTIONS.PROCEED
    }
}

/** Получить объект стилей с backgroundColor для тематического блока */
export const getBlocksStyle = (
    {
        thematicBlockColorAfterPassing,
        thematicBlockColorBeforePassing,
        thematicBlockColorWhilePassing,
    }: ActivityCanvasContract,
    status: ActivityPassingStatus
) => {
    let backgroundColor: string

    switch (status) {
        case ActivityPassingStatus.InProgress:
            backgroundColor = thematicBlockColorWhilePassing
            break
        case ActivityPassingStatus.CompletedSuccessfully:
            backgroundColor = thematicBlockColorAfterPassing
            break
        default:
            backgroundColor = thematicBlockColorBeforePassing
    }

    return { backgroundColor }
}

export const getThematicBLockColor = (
    status?: ActivityPassingStatus,
    canvas?: ActivityCanvasContract
) => {
    switch (status) {
        case ActivityPassingStatus.Waiting:
            return canvas?.thematicBlockColorBeforePassing

        case ActivityPassingStatus.InProgress:
            return canvas?.thematicBlockColorWhilePassing

        case ActivityPassingStatus.CompletedSuccessfully:
            return canvas?.thematicBlockColorAfterPassing

        default:
            return canvas?.thematicBlockColorBeforePassing
    }
}
