import React from 'react'
import { CloseButton, UploadAvatar } from 'components/shared'
import { Col, Row } from 'antd'
import { FormItemAdapter, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { isFormModeView, normalizeFile } from 'utils'

import styles from './ProductTeacher.module.scss'
import { ProductTeacherProps } from './ProductTeacher.types'

/** Блок полей формы создания карточки продукта - преподаватель */
export const ProductTeacher: React.FC<ProductTeacherProps> = React.memo(
    ({ field, formMode, remove }) => (
        <div className={styles.wrapper}>
            <div className={styles.avatar}>
                <FormItemAdapter
                    {...field}
                    fieldName={[field.name, 'attachment']}
                    getValueFromEvent={normalizeFile}
                    label={LOCAL.LABELS.PHOTO}
                >
                    <UploadAvatar formMode={formMode} />
                </FormItemAdapter>
            </div>

            <Row gutter={ROW_GUTTER} align="middle" className={styles.row}>
                <Col xs={12}>
                    <FormItemAdapter
                        {...field}
                        fieldName={[field.name, 'fullName']}
                        label={LOCAL.LABELS.FIO}
                    >
                        <InputControl formMode={formMode} />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        {...field}
                        fieldName={[field.name, 'position']}
                        label={LOCAL.LABELS.POSITION}
                    >
                        <InputControl formMode={formMode} />
                    </FormItemAdapter>
                </Col>
            </Row>

            {!isFormModeView(formMode) && (
                <CloseButton
                    onClose={() => remove?.(field.name)}
                    className={styles.closeIcon}
                    formMode={formMode}
                />
            )}
        </div>
    )
)
