import React, { useMemo } from 'react'
import { DocumentEditorView } from 'components/shared'
import { LdeEditorInitialMode, LdeEditorRoles } from 'App.types'
import { WithHeightResizeProps, withHeightResize } from 'HOCs'
import {
    isHomeworkWaitingForSolution,
    isThemeBlockTypeCourse,
    isThemeBlockTypeHomework,
} from 'utils'

import styles from './ThematicBlockContent.module.scss'
import { PassingViewProps } from './ThematicBlockContent.types'
import { PlayerView } from './components'

/**
 * Контент тематического блока, входящего в состав мероприятия
 */
const ThematicBlockContentComponent: React.FC<
    PassingViewProps & WithHeightResizeProps
> = ({
    homework,
    course,
    type,
    onViewMaterial,
    componentHeight: height,
    componentRef,
    progress,
    setProgressForSend,
}) => {
    const editorConfig = useMemo(
        () => ({
            documentId: homework?.ldeId,
            initialMode: !isHomeworkWaitingForSolution(homework?.status)
                ? LdeEditorInitialMode.View
                : LdeEditorInitialMode.Edit,
            roles: [LdeEditorRoles.Listener],
            spelling: !homework?.isDisableSpellCheck,
        }),
        [homework]
    )

    /**
     * Рендер текущей вариации визуализации тематического блока в зависимости от его типа
     */
    const viewRender = useMemo(() => {
        if (isThemeBlockTypeHomework(type) && homework?.ldeId) {
            return <DocumentEditorView editorConfig={editorConfig} />
        }

        if (isThemeBlockTypeCourse(type) && course) {
            return (
                <PlayerView
                    courseData={course}
                    onViewMaterial={onViewMaterial}
                    progress={progress}
                    setProgressForSend={setProgressForSend}
                />
            )
        }
    }, [
        type,
        homework,
        course,
        editorConfig,
        onViewMaterial,
        progress,
        setProgressForSend,
    ])

    return (
        <div className={styles.wrapper} ref={componentRef} style={{ height }}>
            {viewRender}
        </div>
    )
}

export const ThematicBlockContent = withHeightResize(
    ThematicBlockContentComponent
)
