import cn from 'classnames'
import React, { useCallback } from 'react'
import { DocumentUploadForm } from 'components/forms'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { PopupWithForm, SpaceAdapter } from 'components/shared'
import { getFormattedDuration } from 'utils'

import styles from './ThematicBlock.module.scss'
import stylesEventPassingInfoPanel from '../EventPassingInfoPanel/EventPassingInfoPanel.module.scss'
import { ThematicBlockProps } from './ThematicBlock.types'
import { getActionsButton, getClasses, getStatus } from './ThematicBlock.utils'

/** Тематический блок для прохождения мероприятия */
export const ThematicBlock: React.FC<ThematicBlockProps> = React.memo(
    ({
        onStartThematicBlockPassing,
        name,
        type,
        lessonDescription,
        courseDuration,
        courseDurationMinutes,
        status,
        lessonId,
        orderNumber,
        isRequired,
        activityStatus,
        isContainsAttachments,
    }) => {
        const handleThematicBlockPassing = useCallback(() => {
            onStartThematicBlockPassing({ lessonId })
        }, [onStartThematicBlockPassing, lessonId])

        const duration = !!courseDuration || !!courseDurationMinutes

        return (
            <div className={cn(styles.wrapper, getClasses(status))}>
                <div className={styles.status}>
                    <span className={styles.icon}>
                        {getStatus(status, orderNumber)}
                    </span>
                </div>

                {isContainsAttachments && (
                    <PopupWithForm
                        component={DocumentUploadForm}
                        formId={FORM_IDS.DOCUMENT_UPLOAD_FORM}
                        buttonText={LOCAL.LABELS.ATTACHED_FILES}
                        buttonOption={{
                            type: 'link',
                            className: styles.attachments,
                        }}
                        modalOptions={{
                            title: LOCAL.LABELS.DOCUMENTS,
                            okText: LOCAL.OK,
                            width: MODAL_WIDTH.MD,
                            footer: null,
                        }}
                        formOptions={{
                            initialValues: {
                                lessonId,
                            },
                        }}
                    />
                )}

                <div className={styles.body}>
                    <h2 className={styles.title}>{name}</h2>

                    {(duration || isRequired) && (
                        <SpaceAdapter>
                            {duration && (
                                <span
                                    className={cn(
                                        stylesEventPassingInfoPanel.meta,
                                        styles.duration
                                    )}
                                >
                                    {`${LOCAL.LABELS.DURATION}: `}

                                    {getFormattedDuration(
                                        courseDuration,
                                        courseDurationMinutes
                                    )}
                                </span>
                            )}

                            {isRequired && (
                                <span className={styles.required}>
                                    {LOCAL.LABELS.REQUIRED}
                                </span>
                            )}
                        </SpaceAdapter>
                    )}

                    <pre className={styles.desc}>{lessonDescription}</pre>

                    {getActionsButton(
                        { status, type },
                        handleThematicBlockPassing,
                        activityStatus
                    )}
                </div>
            </div>
        )
    }
)
