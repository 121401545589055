import React from 'react'
import cn from 'classnames'
import moment from 'moment'
import {
    ACTIVITY_PASSING_STATUSES,
    APPOINTMENT_TYPES_YOUR_DEVELOPMENT,
    TRACKS_APPOINTMENT_STATUSES,
} from 'consts'
import {
    ActivityPassingStatus,
    AppointmentTrackStatus,
    YourDevelopmentPageItemType,
} from 'core/api'
import { IconsAdapter, SpaceAdapter } from 'components/shared'
import {
    formatDate,
    isAppointmentTrackStatusAvailableToPassing,
    isDateInBetween,
} from 'utils'

import styles from './ProcessCardHeader.module.scss'
import {
    ACTIVITY_STATUS_TITLE_CLASSNAMES,
    TRACK_STATUS_TITLE_CLASSNAMES,
} from './ProcessCardHeader.consts'
import { ProcessCardHeaderProps } from './ProcessCardHeader.types'
import { getClasses } from './ProcessCardHeader.utils'
import { getClassesPeriodFrom } from '../StagesIndicator/StagesIndicator.utils'

/**
 * Стандартная область заголовка для компонента ProcessCard
 */
export const ProcessCardHeader: React.FC<ProcessCardHeaderProps> = React.memo(
    ({ status, type, endDate, startDate }) => {
        const startDateMinusThreeDays = moment(startDate)
            .subtract(3, 'd')
            .toDate()

        const isTypeTrack = type !== YourDevelopmentPageItemType.Activity

        const isTrackAppointmentNeedToPass =
            isTypeTrack &&
            isAppointmentTrackStatusAvailableToPassing(
                status as AppointmentTrackStatus
            ) &&
            endDate &&
            startDate &&
            isDateInBetween(
                new Date(),
                startDateMinusThreeDays,
                moment(endDate).toDate()
            )

        const statusTitle = isTypeTrack
            ? TRACKS_APPOINTMENT_STATUSES[status as AppointmentTrackStatus]
            : ACTIVITY_PASSING_STATUSES[status as ActivityPassingStatus]

        const statusTitleClassName = isTypeTrack
            ? TRACK_STATUS_TITLE_CLASSNAMES[
                  status as keyof typeof TRACK_STATUS_TITLE_CLASSNAMES
              ]
            : ACTIVITY_STATUS_TITLE_CLASSNAMES[
                  status as keyof typeof ACTIVITY_STATUS_TITLE_CLASSNAMES
              ]

        const formattedStartDate = formatDate(startDate)
        const formattedEndDate = formatDate(endDate)

        return (
            <div className={styles.wrapper}>
                <SpaceAdapter>
                    <div className={styles.typeTag}>
                        {APPOINTMENT_TYPES_YOUR_DEVELOPMENT[type]}
                    </div>

                    {formattedStartDate && formattedEndDate && (
                        <div className={styles.tag}>
                            <span className={getClassesPeriodFrom(startDate)}>
                                {formattedStartDate}
                            </span>

                            <span className={getClasses(endDate)}>
                                {` - ${formattedEndDate}`}
                            </span>
                        </div>
                    )}
                </SpaceAdapter>

                <SpaceAdapter>
                    <div className={cn(styles.status, statusTitleClassName)}>
                        {statusTitle}
                    </div>

                    {isTrackAppointmentNeedToPass && (
                        <IconsAdapter
                            iconType="CustomIconAlarmClock"
                            className={styles.alarmIcon}
                        />
                    )}
                </SpaceAdapter>
            </div>
        )
    }
)
