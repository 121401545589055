import { EditorConfigProps } from '__global.d'
import { FnActionRequiredProps } from 'App.types'
import { useCallback, useEffect, useState } from 'react'

interface UseDocumentEditorProps {
    config?: EditorConfigProps
    editorContainerId?: string
    updateLoader?: FnActionRequiredProps<boolean>
    editorApiUrl?: string
    editorModulesUrl?: string
    loaderEditorTimeout?: number
}

/** Хук для инициализации редактора документов на странице */
export function useDocumentEditor({
    editorContainerId,
    config,
    updateLoader,
    editorApiUrl = '/lde-api/api',
    editorModulesUrl = '/lde-api/lde/lde',
    loaderEditorTimeout = 5000,
}: UseDocumentEditorProps = {}) {
    const [editorId, setEditorId] = useState<string>()
    const [isEditorReady, setEditorReady] = useState(false)

    /**
     * Обработчик закрытия редактора
     */
    const handleCloseLde = useCallback(() => {
        if (editorId) {
            window.ldeUnmount(editorId)
            setEditorId(undefined)
        }
    }, [editorId])

    useEffect(() => {
        if (!config?.documentId || !editorContainerId || !isEditorReady) return

        const documentEditorRender = async () => {
            try {
                const editorConfig = {
                    ...config,
                    baseUrl: editorApiUrl,
                }

                console.log('Объект настроек редактора:', editorConfig)

                setEditorId(
                    await window.ldeMount(
                        editorContainerId,
                        editorConfig,
                        editorModulesUrl
                    )
                )
            } catch (error) {
                console.error(error)
            }
        }

        documentEditorRender()
    }, [
        editorContainerId,
        isEditorReady,
        editorApiUrl,
        editorModulesUrl,
        config,
    ])

    useEffect(() => {
        if (!window.ldeLoad) {
            updateLoader?.(true)

            setTimeout(async () => {
                try {
                    await window.ldeLoad?.({
                        pathToModule: editorModulesUrl,
                    })
                    setEditorReady(true)
                } catch (err) {
                    console.error(err)
                } finally {
                    updateLoader?.(false)
                }
            }, loaderEditorTimeout)
        } else {
            setEditorReady(true)
        }
    }, [updateLoader, editorModulesUrl, loaderEditorTimeout])

    return { handleCloseLde }
}
