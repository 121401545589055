import React, { useCallback } from 'react'
import { LOCAL } from 'core/local'

import styles from './PlayerVideo.module.scss'
import { PlayerVideoProps } from './PlayerVideo.types'

/** Плеер для видео */
export const PlayerVideo: React.FC<PlayerVideoProps> = React.memo(
    ({
        fileUrl,
        progress,
        setProgressForSend,
        setDuration,
        autoPlay = false,
    }) => {
        const handleChangeProgress = (
            e: React.SyntheticEvent<HTMLVideoElement, Event>
        ) => {
            const video = e.target as HTMLMediaElement

            setProgressForSend?.(video.currentTime)
        }

        const handleLoadedMetadata = (
            e: React.SyntheticEvent<HTMLVideoElement, Event>
        ) => {
            const video = e.target as HTMLMediaElement
            // т.к. видео медиафайл загружается не полностью, мы загружаем его
            if (video.duration === Infinity) {
                video.currentTime = Number.MAX_SAFE_INTEGER
                video.ontimeupdate = function () {
                    this.ontimeupdate = () => null

                    return
                }
            }
        }

        const handleDurationChange = useCallback(
            (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
                const video = e.target as HTMLMediaElement

                if (video.duration === Infinity) return

                video.currentTime = progress || 0
                setDuration?.(video.duration)
            },
            [progress, setDuration]
        )

        return (
            <video
                src={fileUrl}
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleChangeProgress}
                controls
                preload="metadata"
                autoPlay={autoPlay}
                onDurationChange={handleDurationChange}
                className={styles.video}
            >
                {LOCAL.MESSAGES.BROWSER_DO_NOT_SUPPORT_VIDEO_FORMAT}
            </video>
        )
    }
)
