import React from 'react'
import {
    ControlGroup,
    FormItemAdapter,
    MultiSelectControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { TooltipAdapter } from 'components/shared/TooltipAdapter'

import { EducationGroupProps } from './EducationGroup.types'

/** Блок "Образование" для формы профиля должности */
export const EducationGroup: React.FC<EducationGroupProps> = React.memo(
    ({ educationLevels, educationProfiles, formMode }) => (
        <ControlGroup type="dark" title={LOCAL.LABELS.EDUCATION}>
            <TooltipAdapter
                title={LOCAL.TOOLTIPS.EDUCATION_LEVEL_OF_JOB_PROFILE}
            >
                <FormItemAdapter
                    fieldName="educationLevelIds"
                    label={LOCAL.LABELS.EDUCATION_LEVEL}
                    required
                >
                    <MultiSelectControl
                        treeData={educationLevels}
                        formMode={formMode}
                        selectAll
                        allowClear
                    />
                </FormItemAdapter>
            </TooltipAdapter>

            <TooltipAdapter
                title={LOCAL.TOOLTIPS.EDUCATION_PROFILE_OF_JOB_PROFILE}
            >
                <FormItemAdapter
                    fieldName="educationProfileIds"
                    label={LOCAL.LABELS.EDUCATION_PROFILE}
                    required
                >
                    <MultiSelectControl
                        treeData={educationProfiles}
                        formMode={formMode}
                        selectAll
                        allowClear
                    />
                </FormItemAdapter>
            </TooltipAdapter>
        </ControlGroup>
    )
)
