import { ASSESSMENT_ROLES } from 'consts'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { RespondentProgressContract } from 'core/api'
import { getShortFio, renderAppointmentStatus } from 'utils'

import { renderRow } from './AssignmentsLaunchedTable.render'

/** настройка отображения колонок таблицы */
export const APPOINTMENT_LAUNCHED_TABLE_COLUMNS: ColumnsType<RespondentProgressContract> = [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'fio',
        key: 'fio',
        render: (_, { respondent: { user, role, active } }) =>
            renderRow({
                value: `${getShortFio(user)}${
                    role ? ` - ${ASSESSMENT_ROLES[role].toLowerCase()}` : ''
                }`,
                isActive: active,
            }),
        width: '60%',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (_, { status, respondent: { active } }) =>
            renderRow({
                value: renderAppointmentStatus(status),
                isActive: active,
            }),
        width: '20%',
    },
    {
        title: LOCAL.LABELS.PROGRESS,
        dataIndex: 'passingPercent',
        key: 'passingPercent',
        render: (_, { passingPercent, respondent: { active } }) =>
            renderRow({
                value: `${passingPercent}%`,
                isActive: active,
            }),
        width: '20%',
    },
]
