import {
    AdaptationTrackPublishContract,
    AdaptationTrackSaveContract,
    AdaptationTracksService,
    DevelopmentTrackPublishContract,
    DevelopmentTrackSaveContract,
    DevelopmentTracksService,
    TrackType,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    TRACK_ADAPTATION_PERMISSIONS,
    TRACK_DEVELOPMENT_PERMISSIONS,
} from 'consts'

import { CustomTrackType } from './Track.types'
import {
    mapAdaptationTrackResponseToFormData,
    mapDevelopmentTrackResponseToFormData,
} from './components/TrackForm/TrackForm.utils'

export const TRACK_COMPONENT_PROPS = {
    ADAPTATION: {
        trackType: TrackType.Adaptation as CustomTrackType,
        getInitialValuesFn: AdaptationTracksService.getActive,
        saveFn: (body?: AdaptationTrackSaveContract) =>
            AdaptationTracksService.save({ body }),
        publishFn: (body?: AdaptationTrackPublishContract) =>
            AdaptationTracksService.publish({ body }),
        createNewVersionFn: (body?: AdaptationTrackPublishContract) =>
            AdaptationTracksService.createNewVersion({ body }),
        mapResponseToFormData: mapAdaptationTrackResponseToFormData,
    },
    DEVELOPMENT: {
        trackType: TrackType.Development as CustomTrackType,
        getInitialValuesFn: DevelopmentTracksService.getActive,
        saveFn: (body?: DevelopmentTrackSaveContract) =>
            DevelopmentTracksService.save({ body }),
        publishFn: (body?: DevelopmentTrackPublishContract) =>
            DevelopmentTracksService.publish({ body }),
        createNewVersionFn: (body?: DevelopmentTrackPublishContract) =>
            DevelopmentTracksService.createNewVersion({ body }),
        mapResponseToFormData: mapDevelopmentTrackResponseToFormData,
    },
}

const TRACK_ADAPTATION_CONFIG = {
    PARENT: ROUTE_NAMES.TRACKS_ADAPTATION,
    ROUTE: ROUTE_NAMES.TRACKS_ADAPTATION_EDIT,
    PERMISSIONS: {
        SAVE: [
            ...TRACK_ADAPTATION_PERMISSIONS,
            UserPermission.AdaptationTrackSave,
        ],
        PUBLISH: [
            ...TRACK_ADAPTATION_PERMISSIONS,
            UserPermission.AdaptationTrackPublish,
        ],
    },
}

const TRACK_DEVELOPMENT_CONFIG = {
    PARENT: ROUTE_NAMES.TRACKS_DEVELOPMENT,
    ROUTE: ROUTE_NAMES.TRACKS_DEVELOPMENT_EDIT,
    PERMISSIONS: {
        SAVE: [
            ...TRACK_DEVELOPMENT_PERMISSIONS,
            UserPermission.DevelopmentTrackSave,
        ],
        PUBLISH: [
            ...TRACK_DEVELOPMENT_PERMISSIONS,
            UserPermission.DevelopmentTrackPublish,
        ],
    },
}

type TrackComponentConfigProps = {
    [key in CustomTrackType]:
        | typeof TRACK_ADAPTATION_CONFIG
        | typeof TRACK_DEVELOPMENT_CONFIG
}

export const TRACK_COMPONENT_CONFIG: TrackComponentConfigProps = {
    [TrackType.Adaptation]: TRACK_ADAPTATION_CONFIG,
    [TrackType.Development]: TRACK_DEVELOPMENT_CONFIG,
}
