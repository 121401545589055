import shortid from 'shortid'
import {
    AssessmentPortfolioContract,
    AssessmentPortfolioPublishContract,
    AssessmentType,
    AttachmentContract,
    CircleAssessmentPortfolioQuestionnaireContract,
    ManagerAssessmentAssessedPositionQuestionnaireContract,
} from 'core/api'
import { DeepPartial } from 'App.types'
import {
    LOCAL_STORAGE_KEYS,
    LocalStorageHelper,
} from 'core/helpers/LocalStorage'
import { PORTFOLIO_PRESENTATION_INITIAL_FORM_VALUES } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.consts'
import { UploadFile } from 'antd/lib/upload/interface'
import {
    getValue,
    isAssessmentTypeCircle,
    normalizeAttachmentObject,
    normalizeDataForSelectAndRadio,
} from 'utils'

import { AssessmentPortfolioPublishFormContract } from './AssessmentTemplateForm.types'
import { CompetenceFormContract } from '../CircleParameters/CircleParameters.types'

/** нормализация данных, начальных значений формы (при редактировании, просмотре), для `value` и внутреннего сейта компонента */
export const normalizeInitialDataForCircleParametersValue = (
    data: CircleAssessmentPortfolioQuestionnaireContract[]
): CompetenceFormContract[] =>
    data.map((el) => ({
        id: el.competence?.id,
        name: el.competence?.name,
        selfQuestionnaireId: el.selfQuestionnaire?.id,
        selfQuestionnaireName: el.selfQuestionnaire?.name,
        respondentQuestionnaireId: el.respondentQuestionnaire?.id,
        respondentQuestionnaireName: el.respondentQuestionnaire?.name,
    }))

/** нормализация данных, начальных значений формы (при редактировании, просмотре), для `value` и внутреннего сейта компонента */
export const normalizeInitialDataForManagerParametersValue = (
    data?: ManagerAssessmentAssessedPositionQuestionnaireContract[]
): CompetenceFormContract[] | undefined =>
    data?.map((el) => ({
        id: el.competence?.id,
        name: el.competence?.name,
        managerQuestionnaireId: el.managerQuestionnaire?.id,
        managerQuestionnaireName: el.managerQuestionnaire?.name,
        selfQuestionnaireId: el.selfQuestionnaire?.id,
        selfQuestionnaireName: el.selfQuestionnaire?.name,
    }))

/**
 * Вытаскиваем id из объекта загруженной картинки
 * @param pictureData массив с объектом загруженной картинки
 */
const mapWindowPictureDataToRequest = (
    pictureData?: UploadFile<AttachmentContract>[]
) => pictureData?.[0].response?.id

/**
 * Получить начальные значения формы редактирования для определенного портфеля оценки
 * @param assessmentPortfolio портфель оценки
 */
export const mapResponseToFormData = ({
    attachments,
    purpose,
    structuralUnits,
    circleParameters,
    managerParameters,
    presentation,
    ...rest
}: AssessmentPortfolioContract) =>
    ({
        ...rest,
        purposeId: purpose?.id,
        presentation: {
            ...presentation,
            passingPictureId: presentation?.passingPicture && [
                normalizeAttachmentObject(presentation?.passingPicture),
            ],
            welcomeWindow: {
                ...presentation?.welcomeWindow,
                pictureId: presentation?.welcomeWindow.picture && [
                    normalizeAttachmentObject(
                        presentation?.welcomeWindow.picture
                    ),
                ],
            },
            finalWindow: {
                ...presentation?.finalWindow,
                pictureId: presentation?.finalWindow.picture && [
                    normalizeAttachmentObject(
                        presentation?.finalWindow.picture
                    ),
                ],
            },
            showQuestionsByBlock: Number(presentation?.showQuestionsByBlock),
        },
        attachmentIds: attachments?.map((attachment) =>
            normalizeAttachmentObject(attachment)
        ),
        structuralUnitIds: structuralUnits?.map(normalizeDataForSelectAndRadio),
        circleParameters: {
            ...(circleParameters || {}),
            questionnaires: circleParameters?.circlePortfolioQuestionnaires
                ?.length
                ? normalizeInitialDataForCircleParametersValue(
                      circleParameters.circlePortfolioQuestionnaires
                  )
                : undefined,
        },
        managerParameters: managerParameters.map((el) => ({
            ...el,
            positionId: [normalizeDataForSelectAndRadio(el.position)],
            structuralUnitId: [
                normalizeDataForSelectAndRadio(el.structuralUnit),
            ],
            questionnaires: normalizeInitialDataForManagerParametersValue(
                el.questionnaires
            ),
        })),
    } as DeepPartial<AssessmentPortfolioPublishFormContract>)

/**
 * Получить значения для отправки на бэк
 * @param values - текущие значения полей формы
 */
export const mapFormDataToRequest = (
    values: Partial<AssessmentPortfolioPublishFormContract>
) => {
    const {
        structuralUnitIds,
        attachmentIds,
        presentation,
        circleParameters,
        managerParameters,
        ...restValues
    } = values

    return {
        ...restValues,
        structuralUnitIds: structuralUnitIds?.map(getValue),
        attachmentIds: attachmentIds?.map(({ response }) => response?.id),
        presentation: {
            ...presentation,
            showQuestionsByBlock: !!Number(presentation?.showQuestionsByBlock),
            passingPictureId: mapWindowPictureDataToRequest(
                presentation?.passingPictureId
            ),
            finalWindow: {
                ...presentation?.finalWindow,
                pictureId: mapWindowPictureDataToRequest(
                    presentation?.finalWindow?.pictureId
                ),
            },
            welcomeWindow: {
                ...presentation?.welcomeWindow,
                pictureId: mapWindowPictureDataToRequest(
                    presentation?.welcomeWindow?.pictureId
                ),
            },
        },
        circleParameters: circleParameters && {
            ...circleParameters,
            questionnaires: circleParameters?.questionnaires?.map((el) => ({
                selfQuestionnaireId: el?.selfQuestionnaireId,
                respondentQuestionnaireId: el?.respondentQuestionnaireId,
                competenceId: el?.id,
            })),
        },
        managerParameters:
            (managerParameters?.length &&
                managerParameters?.map((el, index) => ({
                    positionId: el?.positionId?.[0].value,
                    structuralUnitId: el?.structuralUnitId?.[0].value,
                    assessedPositionNumber: index + 1,
                    hasSelfQuestionnaires: el?.hasSelfQuestionnaires,
                    questionnaires: el?.questionnaires?.map(
                        (questionnaire) => ({
                            selfQuestionnaireId:
                                questionnaire.selfQuestionnaireId,
                            managerQuestionnaireId:
                                questionnaire.managerQuestionnaireId,
                            competenceId: questionnaire.id,
                        })
                    ),
                }))) ||
            [],
    } as AssessmentPortfolioPublishContract
}

/**
 * Триггер обработчика события создания портфеля оценки на другой вкладке
 */
export const triggerLocalStoragePortfolioEvent = () => {
    LocalStorageHelper.setItem(
        LOCAL_STORAGE_KEYS.ASSESSMENT_PORTFOLIO_CREATED,
        shortid.generate()
    )
}

/** Получить начальные значения формы*/
export const getInitialFormValues = (type = AssessmentType.Estimate_360) => ({
    attachmentIds: [],
    presentation: PORTFOLIO_PRESENTATION_INITIAL_FORM_VALUES,
    type,
    circleParameters: isAssessmentTypeCircle(type)
        ? {
              managersCount: 1,
              colleaguesCount: 3,
              colleagueFromRelatedDepartmentCount: 2,
              subordinatesCount: 0,
          }
        : undefined,
})
