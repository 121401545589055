import { LOCAL } from 'core/local'

import { QuestionDisplayTypes } from '../../PortfolioPresentation.types'

export const SHOW_FINAL_RADIO_VALUE = {
    1: LOCAL.LABELS.DISPLAY_SUMMARY_WINDOW.toLowerCase(),
}

export const ASSESSMENT_QUESTIONS_DISPLAY_TYPES = {
    [QuestionDisplayTypes.ByBlock]: LOCAL.LABELS.BY_COMPETENCIES.toLowerCase(),
    [QuestionDisplayTypes.ByOne]: LOCAL.LABELS.ALL_QUESTIONS_ON_SHEET.toLowerCase(),
}

export const TEST_QUESTIONS_DISPLAY_TYPES = {
    [QuestionDisplayTypes.ByOne]: LOCAL.LABELS.BY_ONE.toLowerCase(),
    [QuestionDisplayTypes.ByBlock]: LOCAL.LABELS.BY_BLOCKS.toLowerCase(),
}

export const LAYOUT_OPTIONS = {
    xs: {
        span: 8,
    },
    xxl: {
        span: 7,
        offset: 1,
    },
}
