import React from 'react'
import { Col, Row } from 'antd'
import {
    DatePickerControl,
    FormItemAdapter,
    ShouldUpdateChecker,
} from 'components/controls'
import { ROW_GUTTER } from 'consts'
import { disableDateWhenSelectedDate } from 'utils'

import { FromToDatesFilterProps } from './FromToDatesFilter.types'

/** Фильтр по датам с/по */
export const FromToDatesFilter: React.FC<FromToDatesFilterProps> = React.memo(
    ({ from, to }) => (
        <ShouldUpdateChecker fieldPath={[[from.fieldName], [to.fieldName]]}>
            {({ getFieldsValue }) => {
                const {
                    [from.fieldName]: fromDate,
                    [to.fieldName]: toDate,
                } = getFieldsValue([from.fieldName, to.fieldName])

                return (
                    <Row gutter={ROW_GUTTER}>
                        <Col xs={12}>
                            <FormItemAdapter {...from}>
                                <DatePickerControl
                                    disabledDate={disableDateWhenSelectedDate(
                                        toDate,
                                        true
                                    )}
                                />
                            </FormItemAdapter>
                        </Col>

                        <Col xs={12}>
                            <FormItemAdapter {...to}>
                                <DatePickerControl
                                    disabledDate={disableDateWhenSelectedDate(
                                        fromDate
                                    )}
                                />
                            </FormItemAdapter>
                        </Col>
                    </Row>
                )
            }}
        </ShouldUpdateChecker>
    )
)
