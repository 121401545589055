import { UserContract } from 'core/api'

export function getFullFio(profile?: Partial<UserContract> | null) {
    if (!profile) {
        return ''
    }

    return [profile.lastName, profile.firstName, profile.patronymic]
        .filter((i) => !!i)
        .join(' ')
}

function getFirstLetter(val: string = ''): string {
    return val ? `${val[0].toUpperCase()}.` : ''
}

export function getShortFio(profile?: Partial<UserContract> | null) {
    if (!profile) {
        return ''
    }

    return [
        profile.lastName,
        getFirstLetter(profile.firstName),
        getFirstLetter(profile.patronymic),
    ]
        .filter((i) => !!i)
        .join(' ')
}

/** Получить фамилию и инициалы из строки */
export const getShoftFioFromString = (fio?: string) => {
    if (!fio) return ''

    return fio
        .split(' ')
        .map((name, index) => (!index ? name : getFirstLetter(name)))
        .join(' ')
}
