import React from 'react'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { Popup } from 'components/shared/Popup'
import { TableActions } from 'components/shared/TableActions'
import { useElementVisible } from 'hooks'

import { AssignmentsProgressProps } from './AssignmentsProgressAction.types'

function AssignmentsProgressAction<T extends { name: string }>({
    initialValues,
    reFetchDictionary,
    component: Component,
    requiredPermissions,
    conditionalPermissions,
}: AssignmentsProgressProps<T>) {
    const {
        elementVisible: progressVisible,
        handleHideElement: hideProgress,
        handleShowElement: showProgress,
    } = useElementVisible()

    return (
        <>
            <TableActions.Progress
                requiredPermissions={requiredPermissions}
                conditionalPermissions={conditionalPermissions}
                onClick={showProgress}
            />

            <Popup
                visible={progressVisible}
                footer={null}
                width={MODAL_WIDTH.XL}
                onCancel={hideProgress}
                title={`${LOCAL.LABELS.ASSIGNMENT}: ${initialValues?.name}`}
                destroyOnClose
            >
                <Component
                    initialValues={initialValues}
                    hideProgress={hideProgress}
                    reFetchDictionary={reFetchDictionary}
                />
            </Popup>
        </>
    )
}

export default React.memo(
    AssignmentsProgressAction
) as typeof AssignmentsProgressAction
