import cn from 'classnames'
import React, { useMemo } from 'react'
import { Popover } from 'antd'

import styles from './ElementWithListPopover.module.scss'
import { ElementWithListPopoverProps } from './ElementWithListPopover.types'
import { IconsAdapter } from '../IconsAdapter'
import { WidthRestrictedElement } from '../WidthRestrictedElement'

export const ElementWithListPopover: React.FC<ElementWithListPopoverProps> = ({
    className,
    title,
    items,
    options,
}) => {
    const showPopover = (items?.length || 0) > 1 || options?.showPopoverAlways

    const popoverContent = useMemo(
        () =>
            items?.map((item, index) => (
                <div key={index}>
                    <p>{item}</p>
                </div>
            )),
        [items]
    )

    const columnTitle = useMemo(
        () => (
            <WidthRestrictedElement
                title={title}
                tooltip={{ title }}
                width={options?.width}
                showDots
                getPopupContainer={null}
            />
        ),
        [options, title]
    )

    if (!title && !options?.showWithoutTitle)
        return <IconsAdapter iconType="MinusOutlined" />

    return (
        <div
            className={cn(
                styles.wrapper,
                className,
                options?.inline ? styles.wrapperInlineFlex : styles.wrapperFlex
            )}
        >
            {!options?.showWithoutTitle && columnTitle}

            {showPopover && (
                <Popover
                    content={popoverContent}
                    placement={options?.placement || 'rightTop'}
                    align={{ offset: [8, -13] }}
                >
                    <IconsAdapter
                        iconType="InfoCircleOutlined"
                        className={styles.info}
                    />
                </Popover>
            )}
        </div>
    )
}
