import axios, { CancelTokenSource } from 'axios'
import { RcFile } from 'antd/lib/upload'

interface CoursesServiceManualProps {
    file: RcFile
    putUrl: string
    cancelSource: CancelTokenSource
    contentType: string
}

/** Используется для загрузки курса */
export class CoursesServiceManual {
    /**
     * Загрузка курса
     */
    static upload({
        file,
        putUrl,
        cancelSource,
        contentType,
    }: CoursesServiceManualProps): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .put(putUrl, file, {
                    cancelToken: cancelSource.token,
                    headers: {
                        'content-type': contentType,
                    },
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
