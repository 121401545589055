import React from 'react'
import isNil from 'lodash/isNil'
import last from 'lodash/last'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { InputControl, InputNumberEditControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { WithFormModeProps } from 'App.types'
import { createFieldsRow } from 'utils'

import {
    ColumnsProps,
    ResultAssessmentTypeLevelsProps,
} from '../ResultAssessment/ResultAssessment.types'
import { POINTS_FROM, POINTS_TO } from './ResultAssessmentType.consts'

/**
 * Добавляет в конец таблицы новую строку
 * @param maxPoints - максимальное количество баллов
 * @param tableData - строка таблицы уровней результатов прохождения
 */
export const createNewRow = (
    maxPoints?: number,
    tableData?: ResultAssessmentTypeLevelsProps[]
): ResultAssessmentTypeLevelsProps => {
    if (tableData) {
        const prevRow = last(tableData)

        return {
            name: undefined,
            pointsFrom: prevRow!.pointsTo! + 1,
            pointsTo: maxPoints,
            ...createFieldsRow(),
        }
    }

    return {
        name: undefined,
        pointsFrom: 0,
        pointsTo: undefined,
        ...createFieldsRow(),
    }
}

export const getColumns = ({
    onDelete,
    tableData,
    onLevelNameEdit,
    onLevelScoreEdit,
    maxPoints,
    formMode,
}: ColumnsProps & WithFormModeProps) => {
    if (isNil(maxPoints)) return

    /**
     * Возвращает максимальное значение для поля - `баллы от`
     * @param record - запись таблицы уровней
     * @param index - индекс
     */
    const getMaxForScoreFrom = (
        record: ResultAssessmentTypeLevelsProps,
        index: number
    ) => {
        if (!index) return 0

        return record.pointsTo && record.pointsTo > maxPoints
            ? maxPoints
            : record.pointsTo || maxPoints
    }

    /**
     * Возвращает минимальное значение для поля - `баллы от`, начиная со второй строки таблицы
     * @param record - запись таблицы уровней
     * @param index - индекс
     */
    const getMinForScoreFrom = (
        record: ResultAssessmentTypeLevelsProps,
        index: number
    ) => (index ? Number(tableData[index - 1]?.pointsTo) + 1 : 0)

    /**
     * Возвращает максимальное значение для поля - `баллы до`
     * @param record - запись таблицы уровней
     * @param index - индекс
     */
    const getMaxForScoreTo = (
        record: ResultAssessmentTypeLevelsProps,
        index: number
    ) => {
        const nextRowScoreFrom = tableData[index + 1]?.pointsFrom - 1

        if (index !== tableData.length - 1 && nextRowScoreFrom) {
            return nextRowScoreFrom
        }

        return maxPoints
    }

    /**
     * Возвращает минимальное значение для поля - `баллы до`, начиная со второй строки таблицы
     * @param record - запись таблицы уровней
     * @param index - индекс
     */
    const getMinForScoreTo = (
        record: ResultAssessmentTypeLevelsProps,
        index: number
    ) => {
        if (index && tableData[index - 1]?.pointsTo) {
            return record.pointsFrom || tableData[index - 1].pointsTo! + 1
        }

        return record.pointsFrom
    }

    return [
        {
            title: LOCAL.LABELS.LEVEL,
            key: 'level',
            render: (
                text: string,
                record: ResultAssessmentTypeLevelsProps,
                index: number
            ) => index + 1,
        },
        {
            title: LOCAL.LABELS.NAME,
            dataIndex: 'name',
            key: 'name',
            render: (
                text: string,
                record: ResultAssessmentTypeLevelsProps,
                index: number
            ) => (
                <InputControl
                    value={text}
                    onChange={onLevelNameEdit('name', record)}
                    formMode={formMode}
                />
            ),
        },
        {
            title: LOCAL.LABELS.POINTS_FROM,
            dataIndex: POINTS_FROM,
            key: POINTS_FROM,
            render: (
                text: number,
                record: ResultAssessmentTypeLevelsProps,
                index: number
            ) => (
                <InputNumberEditControl
                    onChange={onLevelScoreEdit(POINTS_FROM, record)}
                    disabled={true}
                    initialValue={record.pointsFrom}
                    min={getMinForScoreFrom(record, index)}
                    max={getMaxForScoreFrom(record, index)}
                    value={record.pointsFrom}
                    formMode={formMode}
                />
            ),
        },
        {
            title: LOCAL.LABELS.POINTS_TO,
            dataIndex: POINTS_TO,
            key: POINTS_TO,
            render: (
                text: number,
                record: ResultAssessmentTypeLevelsProps,
                index: number
            ) => (
                <InputNumberEditControl
                    onChange={onLevelScoreEdit(POINTS_TO, record)}
                    disabled={index + 1 !== tableData.length}
                    initialValue={record.pointsTo}
                    min={getMinForScoreTo(record, index)}
                    max={getMaxForScoreTo(record, index)}
                    value={record.pointsTo}
                    formMode={formMode}
                />
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (
                text: string,
                record: ResultAssessmentTypeLevelsProps,
                index: number
            ) => {
                if (index) {
                    return (
                        <IconsAdapter
                            onClick={onDelete(record.id)}
                            iconType="CloseOutlined"
                        />
                    )
                }

                return ''
            },
        },
    ]
}
