import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { Form } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker, SwitchControl } from 'components/controls'
import { isFormModeView } from 'utils'

import styles from './TrackStage.module.scss'
import { TrackStageFields } from '../TrackStageFields'
import { TrackStageProps } from './TrackStage.types'

/**
 * Компонент отображения этапа трека
 */
export const TrackStage: React.FC<TrackStageProps> = React.memo(
    ({ stage, removeStage, provided, formMode, disabled }) => {
        /**
         * Обработчик удаления этапа трека
         */
        const handleRemoveTrackStage = useCallback(() => {
            removeStage(stage.name)
        }, [removeStage, stage.name])

        return (
            <div
                className={styles.wrapper}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div className={styles.body}>
                    <div className={styles.title}>
                        <h4>{`${LOCAL.LABELS.STAGE} ${stage.name + 1}`}</h4>

                        <ShouldUpdateChecker fieldPath={['passingType']}>
                            {({ getFieldValue }) => {
                                const passingType = getFieldValue('passingType')

                                const sequentialPassage =
                                    passingType === 'Consistently'

                                return (
                                    <Form.Item
                                        name={[stage.name, 'isPassingRequired']}
                                        noStyle
                                    >
                                        <SwitchControl
                                            addonAfter={LOCAL.LABELS.NECESSITY}
                                            disabled={
                                                disabled ||
                                                isFormModeView(formMode) ||
                                                sequentialPassage
                                            }
                                        />
                                    </Form.Item>
                                )
                            }}
                        </ShouldUpdateChecker>

                        {!isFormModeView(formMode) && (
                            <Button
                                onClick={handleRemoveTrackStage}
                                type="link"
                                icon={
                                    <IconsAdapter iconType="DeleteOutlined" />
                                }
                                className={styles.remove}
                                disabled={disabled}
                            />
                        )}
                    </div>

                    <TrackStageFields
                        stage={stage}
                        formMode={formMode}
                        disabled={disabled}
                    />
                </div>
            </div>
        )
    }
)
