import React from 'react'
import cn from 'classnames'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import { Draggable } from 'react-beautiful-dnd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { isFormModeView } from 'utils'

import styles from './IndicatorItem.module.scss'
import { IndicatorItemProps } from './IndicatorItem.types'

/** Компонент индикатора компетенции */
export const IndicatorItem: React.FC<IndicatorItemProps> = React.memo(
    ({ formMode, name, id, index, onDelete }) => (
        <Draggable
            key={id}
            draggableId={String(id)}
            index={index}
            isDragDisabled={isFormModeView(formMode)}
        >
            {(provided, snapshot) => (
                <div
                    className={cn(
                        styles.wrapper,
                        snapshot.isDragging && styles.wrapperDragging
                    )}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Row align="middle" justify="space-between">
                        <Col className={styles.icon} xs={1}>
                            <IconsAdapter iconType="CustomIconDnD" />
                        </Col>

                        <Col xs={22} className={styles.content}>
                            {name}
                        </Col>

                        <Col
                            className={cn(styles.icon, styles.iconClose)}
                            xs={1}
                        >
                            {!isFormModeView(formMode) && (
                                <Button
                                    onClick={onDelete}
                                    type="link"
                                    icon={
                                        <IconsAdapter
                                            iconType="CustomIconClose"
                                            size={10}
                                        />
                                    }
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            )}
        </Draggable>
    )
)
