import { ColorThemes } from 'contexts'
import { makeHexWithOpacity } from 'utils'
import { makeThemeDependentVar } from 'contexts/ColorTheme/ColorTheme.utils'

import { AnnotationType, DrawTimeType } from './Percentile.types'
import { DARK_THEME_COLOR, LIGHT_THEME_COLOR } from './Percentile.consts'

/**
 * Сделать hex-цвет со средней прозрачностью (opacity: 0.6)
 */
export const hexMediumOpacity = (hex: string) => makeHexWithOpacity(hex, 0.6)

/**
 * Сделать hex-цвет с высокой прозрачностью (opacity: 0.3)
 */
export const hexLowOpacity = (hex: string) => makeHexWithOpacity(hex, 0.3)

/**
 * Получить цвет для текста компетенций
 */
export const getPercentileCompetencesColor = (
    theme: ColorThemes,
    inverse?: boolean
) =>
    makeThemeDependentVar({
        theme,
        light: inverse ? DARK_THEME_COLOR : LIGHT_THEME_COLOR,
        dark: inverse ? LIGHT_THEME_COLOR : DARK_THEME_COLOR,
    })

/**
 * Получить цвет для текста осей
 */
export const getPercentileLabelsConfig = (theme: ColorThemes) => ({
    color: makeThemeDependentVar({
        theme,
        light: LIGHT_THEME_COLOR,
        dark: DARK_THEME_COLOR,
    }),
    font: { size: 14 },
})

export const getAnnotationConfig = (
    percentileCompetencesColorConfig: string
) => ({
    borderWidth: 1,
    borderColor: percentileCompetencesColorConfig,
    type: AnnotationType.box,
    drawTime: DrawTimeType.BeforeDraw,
})

/**
 * @param yMin
 * @param yMax
 * @param xMin
 * @param xMax
 */
export const getCoordinates = (
    yMin: number,
    yMax: number,
    xMin: number,
    xMax: number
) => ({
    yMin,
    yMax,
    xMin,
    xMax,
})
