import React, { useCallback } from 'react'
import { Form } from 'antd'
import { FormItemAdapter, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { SubordinationsOtherService } from 'core/api'

/** Форма создания организации для подчинения */
export const OtherSubordinationForm: React.FC<PopupAdapterFormProps> = React.memo(
    ({ onRequestFinish, id }) => {
        /**
         * Обработчик отправки данных на сервер
         * @param values значения формы
         */
        const handleFinish = useCallback(
            async (body) => {
                try {
                    await SubordinationsOtherService.create({
                        body,
                    })
                    onRequestFinish?.()
                } catch (e) {
                    console.error(e)
                } finally {
                }
            },
            [onRequestFinish]
        )

        return (
            <Form onFinish={handleFinish} id={id}>
                <FormItemAdapter
                    fieldName="name"
                    label={LOCAL.LABELS.TO_WHOM_IT_REPORTS}
                >
                    <InputControl />
                </FormItemAdapter>
            </Form>
        )
    }
)
