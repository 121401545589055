import { QuestionnaireType } from 'core/api'

/**
 * Начальные значения для формы
 */
export const TEST_ASSIGNMENT_FORM_INITIAL_VALUES = {
    type: QuestionnaireType.Test,
    daysToStartNotification: 3,
    daysToEndNotification: 3,
    attemptsLimit: 1,
}

export const MIN_DAYS_TO_NOTIFICATION = 1

export const TEST_ASSIGNMENTS_FORM_ID = 'testAssignmentsFormId'

export const SHOULD_UPDATE_PORTFOLIOS_FIELDS = [
    'organizationsIds',
    'forAllOrganizations',
]
