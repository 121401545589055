import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { isStatusActive } from 'utils'

import { NormalizeStructuralUnitPositionsResultProps } from '../Organizations/Organizations.types'
import { renderPositionProfile } from './StaffUnits.render'

export const STAFF_UNITS_TABLE_COLUMNS: ColumnsType<NormalizeStructuralUnitPositionsResultProps> = [
    {
        title: LOCAL.LABELS.ORG_STRUCTURE,
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: LOCAL.LABELS.POSITION_PROFILE,
        dataIndex: 'positionProfile',
        key: 'positionProfile',
        render: renderPositionProfile,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'active',
        key: 'active',
        render: (active: boolean) => isStatusActive(active),
        fixed: 'right',
    },
]
