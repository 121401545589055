import React, { FC, useCallback, useMemo } from 'react'
import { COL_xl8_lg12_xs24, ROW_GUTTER } from 'consts'
import { CatalogItemSearchContract, CatalogService } from 'core/api'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { useElementVisible } from 'hooks'

import { CatalogSection } from '../CatalogSection'
import { CatalogUnit } from '../CatalogUnit'
import { MIN_ITEMS_COUNT } from '../CatalogSection/CatalogSection.consts'
import { ThematicBlockProps } from './ThematicBlock.types'
import {
    getCatalogSectionHeader,
    renderShowAllButton,
} from '../CatalogSection/CatalogSection.utils'

/** Блок из единиц каталога, объединенных общей тематикой */
export const ThematicBlock: FC<ThematicBlockProps> = React.memo(
    ({
        name,
        countProductCards,
        productCards,
        onUpdateFavourites,
        themeId,
        onChangeCatalogItem,
    }) => {
        const {
            elementVisible: isShowAllCourses,
            toggleElementVisible,
        } = useElementVisible()

        /** Запросить все сущности каталога по текущей теме */
        const fetchAllCatalogItemsByThemeId = useCallback(async () => {
            if (productCards.length === countProductCards) return

            const {
                pageItems: allProductCards,
            } = await CatalogService.searchAsync({
                pageSize: countProductCards,
                pageNumber: 1,
                body: {
                    themeIds: [themeId],
                } as CatalogItemSearchContract,
            })

            const changedCatalogItem = {
                themeId,
                name,
                countProductCards,
                productCards: allProductCards,
            }

            onChangeCatalogItem(themeId, changedCatalogItem)
        }, [
            themeId,
            name,
            countProductCards,
            onChangeCatalogItem,
            productCards,
        ])

        /**
         * Показать/скрыть все мероприятия
         */
        const handleShowAllCourses = useCallback(() => {
            toggleElementVisible()
            fetchAllCatalogItemsByThemeId()
        }, [fetchAllCatalogItemsByThemeId, toggleElementVisible])

        /**
         * Создание контента для секции
         */
        const sectionContent = useMemo(() => {
            const items = productCards.map((card) => (
                <Col key={`${name}-${card.id}`} {...COL_xl8_lg12_xs24}>
                    <CatalogUnit
                        onUpdateFavourites={onUpdateFavourites}
                        {...card}
                    />
                </Col>
            ))
            if (!isShowAllCourses) {
                return items.filter((el, index) => index < MIN_ITEMS_COUNT)
            }

            return items
        }, [productCards, name, isShowAllCourses, onUpdateFavourites])

        /**
         * Рендер кнопки скрыть\показать
         */
        const showAllButton = useMemo(
            () =>
                renderShowAllButton({
                    elemsCount: countProductCards,
                    onClick: handleShowAllCourses,
                    btnText: isShowAllCourses
                        ? LOCAL.ACTIONS.HIDE
                        : `${LOCAL.ACTIONS.SHOW_ALL.toLowerCase()} ${countProductCards}`,
                }),
            [countProductCards, handleShowAllCourses, isShowAllCourses]
        )

        return (
            <CatalogSection
                header={getCatalogSectionHeader(name, showAllButton)}
                content={
                    <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
                        {sectionContent}
                    </Row>
                }
            />
        )
    }
)
