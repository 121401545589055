import React, { FC, Key, useCallback, useEffect, useState } from 'react'
import { AppointmentsService } from 'core/api'
import { Button, ButtonsToolbar } from 'components/shared'
import { ControlGroup } from 'components/controls'
import { LOCAL } from 'core/local'
import { MODAL_Z_INDEX } from 'consts'
import { Table } from 'antd'
import {
    createConfirmPopup,
    getUniqueItemsArray,
    isQuestionnaireTypeQuiz,
    showMessageWithDelayAfter,
} from 'utils'
import { isNumber } from 'lodash'
import { withLoader } from 'HOCs'

import styles from './QuizAssignmentsProgressTable.module.scss'
import {
    QuizAssignmentsProgressTableProps,
    SurveyAssignmentProgressFormProps,
} from './QuizAssignmentsProgressTable.types'
import { SURVEY_ASSIGNMENT_PROGRESS_TABLE_COLUMNS } from './QuizAssignmentsProgressTable.consts'
import {
    checkboxRender,
    getUserIds,
    mapDataToResponse,
} from './QuizAssignmentsProgressTable.utils'

/**
 * Форма удаления/редактирования/замены участников в запущенном назначении опроса
 */
export const QuizAssignmentsProgressTable: FC<QuizAssignmentsProgressTableProps> = withLoader(
    React.memo(
        ({
            initialValues,
            hideProgress,
            updateLoader,
            isLoading,
            reFetchDictionary,
        }) => {
            const [respondents, setRespondents] = useState<
                SurveyAssignmentProgressFormProps[]
            >()
            const [overallProgress, setOverallProgress] = useState<number>()
            const [selectedKeys, setSelectedKeys] = useState<number[]>()

            /**
             * Обработчик выбора записей для удаления в таблице формы
             */
            const handleSelectKeys = useCallback(
                (keys: Key[], record: SurveyAssignmentProgressFormProps[]) => {
                    setSelectedKeys(
                        getUniqueItemsArray(
                            record
                                .map((item) => item.userId ?? getUserIds(item))
                                .flat()
                        )
                    )
                },
                []
            )

            /**
             * Обработчик отправки формы
             */
            const handleFinish = useCallback(async () => {
                try {
                    updateLoader(true)

                    createConfirmPopup({
                        title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                        content: LOCAL.MESSAGES.EXCLUDE_USERS_CONFIRM_MESSAGE,
                        zIndex: MODAL_Z_INDEX.CONFIRM,
                        onOk: async () => {
                            await AppointmentsService.updateRespondentsAppointment(
                                {
                                    id: initialValues.id,
                                    body: selectedKeys,
                                }
                            )

                            reFetchDictionary?.()

                            showMessageWithDelayAfter({
                                messageText:
                                    LOCAL.MESSAGES
                                        .ASSIGNMENT_SAVE_SUCCESS_MESSAGE,
                            }).then(hideProgress)
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }, [
                initialValues,
                reFetchDictionary,
                hideProgress,
                selectedKeys,
                updateLoader,
            ])

            useEffect(() => {
                const appointmentFetch = async () => {
                    try {
                        updateLoader(true)
                        const quizResults = await AppointmentsService.getQuizProgress(
                            {
                                id: initialValues?.id,
                            }
                        )

                        setRespondents(quizResults.units.map(mapDataToResponse))
                        setOverallProgress(quizResults.passingPercent)
                    } catch (error) {
                        console.error(error)
                    } finally {
                        updateLoader(false)
                    }
                }

                appointmentFetch()
            }, [updateLoader, initialValues])

            return (
                <>
                    {isQuestionnaireTypeQuiz(initialValues?.type) && (
                        <ControlGroup
                            className={styles.controlGroup}
                            title={LOCAL.LABELS.RESPONDENTS}
                            noPadding
                        >
                            {isNumber(overallProgress) && (
                                <p className={styles.overallProgress}>
                                    {`${LOCAL.LABELS.OVERALL_PROGRESS_OF_PASSAGE}: ${overallProgress}%`}
                                </p>
                            )}

                            <Table<SurveyAssignmentProgressFormProps>
                                columns={
                                    SURVEY_ASSIGNMENT_PROGRESS_TABLE_COLUMNS
                                }
                                rowSelection={{
                                    hideSelectAll: true,
                                    checkStrictly: false,
                                    onChange: handleSelectKeys,
                                    renderCell: checkboxRender,
                                }}
                                dataSource={respondents || []}
                                pagination={false}
                            />
                        </ControlGroup>
                    )}

                    <ButtonsToolbar align="space-between">
                        <Button
                            onClick={handleFinish}
                            disabled={isLoading || !selectedKeys?.length}
                            loading={isLoading}
                        >
                            {LOCAL.ACTIONS.DELETE}
                        </Button>

                        <Button type="link" onClick={hideProgress}>
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>
                    </ButtonsToolbar>
                </>
            )
        }
    )
)
