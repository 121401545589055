import { CompetenceContract, DictionaryContract } from 'core/api'
import { Store } from 'antd/lib/form/interface'

/**
 * Готовим поле indicators для отправки на бек
 * @param indicators - коллекция "индикаторов" из формы
 */
export const mapIndicatorsFieldToRequest = (
    indicators?: DictionaryContract[]
) => {
    if (!indicators) return

    return {
        indicators: indicators?.map((el, index) => ({
            indicatorId: el.id,
            order: index + 1,
        })),
    }
}

/**
 * Готовим данные из формы для отправки на бек
 * @param values - значения формы
 */
export const mapFormDataToRequest = (values: Store) => {
    const { indicators, ...otherValues } = values

    return {
        ...mapIndicatorsFieldToRequest(indicators),
        ...otherValues,
    }
}

/**
 * Готовим данные с бека для применения их в форме
 * @param values - значения для формы с бека
 */
export const mapResponseToFormData = (values: Partial<CompetenceContract>) => ({
    name: values.name,
    competenceGroupId: values?.competenceGroup?.id,
    description: values.description,
    departmentId: values.department?.id,
    functionClassIds: values?.functionClass?.map((el) => el.id),
    indicators: values?.indicators?.map((el) => ({
        id: el.id,
        name: el.name,
    })),
})
