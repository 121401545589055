import React from 'react'
import cn from 'classnames'
import { Input } from 'antd'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import styles from './TextAreaControl.module.scss'
import { ControlViewMode } from '../ControlViewMode'
import { TextAreaControlProps } from './TextAreaControl.types'

/**
 * Декоратор для `TextArea` от `antd`, принимает все теже `props`
 */
export const TextAreaControl: React.FC<TextAreaControlProps> = React.memo(
    ({ disabled, formMode, form, ...props }) => {
        const classes = cn(styles.wrapper, 'form-control')

        return (
            <div className={classes}>
                {isFormModeView(formMode) ? (
                    <ControlViewMode value={props.value} />
                ) : (
                    <Input.TextArea
                        placeholder={LOCAL.PLACEHOLDERS.INPUT}
                        disabled={disabled}
                        {...props}
                    />
                )}
            </div>
        )
    }
)
