import cn from 'classnames'
import React, { MouseEvent, useCallback } from 'react'
import { Button as AntButton } from 'antd'
import { useHistory } from 'react-router-dom'

import styles from './Button.module.scss'
import { ButtonProps } from './Button.types'
import { TooltipAdapter } from '../TooltipAdapter'

export const Button = React.memo(
    React.forwardRef<HTMLElement, ButtonProps>(
        (
            {
                children,
                className,
                href,
                targetBlank,
                onClick,
                type = 'primary',
                title,
                disabled,
                tooltipOptions,
                ...restProps
            },
            ref
        ) => {
            const history = useHistory()

            const handleClick = useCallback(
                (e: MouseEvent<HTMLElement>) => {
                    if (href && !targetBlank) {
                        history.push(href)
                    }

                    if (href && targetBlank) {
                        window.open(href)
                    }

                    onClick?.(e)
                },
                [history, href, targetBlank, onClick]
            )

            const button = (
                <AntButton
                    ref={ref}
                    onClick={handleClick}
                    type={type}
                    className={cn(styles.wrapper, className)}
                    disabled={disabled}
                    {...restProps}
                >
                    {children}
                </AntButton>
            )

            return title ? (
                <TooltipAdapter {...tooltipOptions} title={title}>
                    {button}
                </TooltipAdapter>
            ) : (
                button
            )
        }
    )
)
