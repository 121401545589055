import React, { useEffect } from 'react'
import {
    AssessmentReportManagerService,
    ManagerReportCompetenceResultContract,
} from 'core/api'
import { ControlGroup } from 'components/controls'
import { Spoiler, TableAdapter } from 'components/shared'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { getRoundedOneCharNumber } from 'utils'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './TablesCompetenciesIndicators.module.scss'
import {
    createDataSource,
    customSpoilerTitleRender,
    getTableColumnsOptions,
} from './TablesCompetenciesIndicators.utils'

/** Отчёт по оценке руководителя. Оценка сотрудника по компетенциям и индикаторам */
export const TablesCompetenciesIndicators: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp<ManagerReportCompetenceResultContract[]>(
        AssessmentReportManagerService.getCompetenceIndicatorResults
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return (
        <div className={styles.wrapper}>
            {data?.map((el) => (
                <ControlGroup
                    type="dark"
                    key={el.competenceId}
                    className={styles.controlGroup}
                >
                    <Spoiler
                        title={el.competenceName}
                        spoilerTitleRender={customSpoilerTitleRender(
                            Number(getRoundedOneCharNumber(el.managerResult))
                        )}
                        className={styles.spoiler}
                    >
                        <TableAdapter
                            className={styles.table}
                            columns={getTableColumnsOptions(
                                el.indicatorResults
                            )}
                            dataSource={createDataSource(el)}
                            rowKey="indicatorId"
                            pagination={false}
                        />
                    </Spoiler>
                </ControlGroup>
            ))}
        </div>
    )
})
