import { LOCAL } from 'core/local'

export enum TabsKeys {
    MainInfo,
    ActiveAppointments,
}

export const TABS_PANE_OPTIONS = {
    MAIN_INFO: {
        tab: LOCAL.LABELS.MAIN_INFO,
        key: TabsKeys.MainInfo,
    },
    ACTIVE_APPOINTMENTS: {
        tab: LOCAL.LABELS.ACTIVE_APPOINTMENTS,
        key: TabsKeys.ActiveAppointments,
    },
}

export const DEFAULT_TAB_KEY = TABS_PANE_OPTIONS.MAIN_INFO.key
