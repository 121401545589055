import pick from 'lodash/pick'
import { CourseFullContract, CourseInfoSaveContract } from 'core/api'
import { formatDate, renderStringArrayWithSeparator } from 'utils'

export const mapFormDataToRequest = (
    course: CourseFullContract
): CourseInfoSaveContract => ({
    ...pick(course, [
        'id',
        'name',
        'comment',
        'organization',
        'email',
        'phoneNumber',
        'key',
    ]),
    structuralUnitId: course.structuralUnit.id,
})

export const mapResponseToFormData = ({
    uploadDate,
    fileSize,
    topics,
    relatedCourseVersions,
    ...restValues
}: CourseFullContract) => ({
    ...restValues,
    uploadDate: formatDate(uploadDate),
    fileSize: `${fileSize} Mb`,
    relatedCourseVersions: [...relatedCourseVersions].sort(
        (a, b) => a.courseVersion - b.courseVersion
    ),
    topics: renderStringArrayWithSeparator(topics.map((topic) => topic.name)),
})
