import React from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { DATE_FORMAT, ROW_GUTTER } from 'consts'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps, normalizeDictionaryToControl } from 'utils'
import { LOCAL } from 'core/local'
import { SessionSearchContract } from 'core/api'

import { MONITORING_ENTITIES_DICTIONAIRY } from '../Monitoring.consts'

/** Фильтры страницы мониторинга */
export const MonitoringFilters: React.FC<FiltersProps<
    SessionSearchContract
>> = React.memo((props) => (
    <ControlGroup type="light">
        <FilterForm {...props}>
            <Row gutter={ROW_GUTTER} align="middle">
                <Col xs={6}>
                    <FormItemAdapter
                        fieldName="userFio"
                        label={LOCAL.LABELS.USER_FIO}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={6}>
                    <FormItemAdapter
                        fieldName="recordEntityTypes"
                        label={LOCAL.LABELS.USER_FIO}
                    >
                        <SelectControl
                            values={normalizeDictionaryToControl(
                                MONITORING_ENTITIES_DICTIONAIRY
                            )}
                            mode="multiple"
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={6}>
                    <FormItemAdapter
                        fieldName="sessionStartDateTimeFrom"
                        label={LOCAL.LABELS.RECORD_DATE_FROM}
                    >
                        <DatePickerControl
                            showTime={{
                                format: DATE_FORMAT.HH_mm,
                            }}
                            format={DATE_FORMAT.LIST_WITH_TIME}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={6}>
                    <FormItemAdapter
                        fieldName="sessionStartDateTimeTo"
                        label={LOCAL.LABELS.RECORD_DATE_TO}
                    >
                        <DatePickerControl
                            showTime={{
                                format: DATE_FORMAT.HH_mm,
                            }}
                            format={DATE_FORMAT.LIST_WITH_TIME}
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    </ControlGroup>
))
