import { QuizType } from 'core/api'

/**
 * Проверка типа опроса
 * @param type - типа
 */
export const isQuizTypeEngagement = (type?: QuizType) =>
    type === QuizType.Engagement

export const isQuizTypeFeedback = (type?: QuizType) =>
    type === QuizType.Feedback

export const isQuizTypeRecommend = (type?: QuizType) =>
    type === QuizType.Recommend
