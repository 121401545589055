import { AssessmentType, UserPermission } from 'core/api'
import { checkRoleBasedPermissions } from 'core/permissions/ProtectedRoute/ProtectedRoute.utils'
import {
    isAssessmentTypeCircle,
    isAssessmentTypeExpert,
    isAssessmentTypeManager,
} from 'utils'

/**
 * Проверки наличия прав доступа для определен
 * @param assessmentType - тип оценки
 * @param userPermissions - права, которые есть у юзера
 */
const checkAssessmentManagerTypeAccess = (
    assessmentType?: AssessmentType,
    userPermissions?: UserPermission[]
): boolean | undefined =>
    isAssessmentTypeManager(assessmentType) &&
    checkRoleBasedPermissions(userPermissions)?.includes(
        UserPermission.AssessmentPortfolioManagerRead
    )

const checkAssessmentExpertTypeAccess = (
    assessmentType?: AssessmentType,
    userPermissions?: UserPermission[]
): boolean | undefined =>
    isAssessmentTypeExpert(assessmentType) &&
    checkRoleBasedPermissions(userPermissions)?.includes(
        UserPermission.AssessmentPortfolioExpertRead
    )

const checkAssessmentCircleTypeAccess = (
    assessmentType?: AssessmentType,
    userPermissions?: UserPermission[]
): boolean | undefined =>
    isAssessmentTypeCircle(assessmentType) &&
    userPermissions?.includes(UserPermission.AssessmentPortfolio180270360Read)

/**
 * Дизейблинг кнопки просмотра портфеля оценки в селекте
 * @param userPermissions - права у юзера
 * @param assessmentType - тип портфеля оценки
 */
export const isRenderAddonAfterAllowed = (
    userPermissions?: UserPermission[],
    assessmentType?: AssessmentType
): undefined | boolean =>
    checkAssessmentManagerTypeAccess(assessmentType, userPermissions) ||
    checkAssessmentExpertTypeAccess(assessmentType, userPermissions) ||
    checkAssessmentCircleTypeAccess(assessmentType, userPermissions)
