import { CertificateSearchContract, SortDirection } from 'core/api'

/** Получить квери параметры для запроса сертификатов */
export const getCertificatesSearchParams = (userId?: number) => ({
    pageNumber: 1,
    pageSize: 4,
    body: {
        userId,
        orderRules: [{ field: 'issueDate', direction: SortDirection.Desc }],
    } as CertificateSearchContract,
})

export const CAROUSEL_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                dots: true,
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 991,
            settings: {
                dots: true,
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 1199,
            settings: {
                dots: true,
                slidesToShow: 3,
            },
        },
    ],
}
