import React, { useCallback, useEffect, useState } from 'react'
import { Col, Empty, Row } from 'antd'
import { DataNode } from 'rc-tree-select/lib/interface'
import { DictionariesStateProps } from 'components/pages/JobProfiles/components/JobProfileOrgStructureFilters/JobProfileOrgStructureFilters.types'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps, normalizeDataForTreeSelect } from 'utils'
import {
    FormItemAdapter,
    InputControl,
    MultiSelectControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import {
    PositionProfilesSearchContract,
    PositionsService,
    StructuralUnitType,
    StructuralUnitsService,
} from 'core/api'
import { ROW_GUTTER, TREE_SELECT_NORMALIZE_TYPE } from 'consts'
import { useDictionaries } from 'hooks'

import { PositionProfileFiltersFormValuesProps } from './PositionProfileFilters.types'

/** Форма фильтрации модального окна выбора компетенции по профилю должности */
export const PositionProfileFilters: React.FC<FiltersProps<
    PositionProfilesSearchContract
>> = React.memo(({ onSetFilters, ...props }) => {
    const [unitsName, setUnitsName] = useState<DataNode[]>()

    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesStateProps
    >()

    /**
     * Запрос справочника подразделений для выбранных организаций
     * @param ids массив id организаций
     */
    const unitsFetch = useCallback(
        async (ids) => {
            try {
                const unitsData = await StructuralUnitsService.getOrganizationsUnitsFlat(
                    { body: ids }
                )
                setUnitsName(unitsData.map(normalizeDataForTreeSelect))
            } catch (error) {
                console.error(error)
            }
        },
        [setUnitsName]
    )

    const handleFinish = useCallback(
        ({
            structuralOrganizationIds,
            structuralUnitsIds,
            ...restValues
        }: Partial<PositionProfileFiltersFormValuesProps>) => {
            onSetFilters({
                ...restValues,
                structuralUnitsIds: [
                    ...(structuralUnitsIds || []),
                    ...(structuralOrganizationIds || []),
                ],
            })
        },
        [onSetFilters]
    )

    useEffect(() => {
        handleFetchDictionaries({
            positions: PositionsService.getAll(),
            organizationsName: [
                StructuralUnitsService.getOrganizationsByUnitType({
                    type: StructuralUnitType.Organization,
                }),
                TREE_SELECT_NORMALIZE_TYPE,
            ],
        })
    }, [handleFetchDictionaries])

    return (
        <FilterForm {...props} onSetFilters={handleFinish}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="positionsIds"
                        label={LOCAL.LABELS.POSITION}
                    >
                        <MultiSelectControl
                            treeData={dictionaries?.positions}
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="structuralOrganizationIds"
                        label={LOCAL.LABELS.ORGANIZATION}
                    >
                        <MultiSelectControl
                            treeData={dictionaries?.organizationsName}
                            onChange={unitsFetch}
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="structuralUnitsIds"
                        label={LOCAL.LABELS.STRUCTURAL_UNIT}
                    >
                        <MultiSelectControl
                            treeData={unitsName}
                            notFoundContent={
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        LOCAL.MESSAGES
                                            .CHOOSE_ORGANIZATION_BEFORE
                                    }
                                />
                            }
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    )
})
