import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { StructuralUnitContract } from 'core/api'

/** настройка отображения колонок таблицы организаций */
export const ORGANIZATIONS_TABLE_COLUMNS: ColumnsType<StructuralUnitContract> = [
    {
        title: LOCAL.LABELS.ORG_STRUCTURE,
        dataIndex: 'name',
        key: 'name',
    },
]
