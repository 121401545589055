import { LOCAL } from 'core/local'

export const PORTFOLIO_PRESENTATION_INITIAL_FORM_VALUES = {
    welcomeWindow: {
        message: LOCAL.MESSAGES.NEED_TO_PASS_ALL_ANSWERS,
    },
    finalWindow: {
        message: LOCAL.MESSAGES.EVALUATION_FINISHED,
    },
}

export enum TabsKeys {
    Settings,
    Presentation,
}

export const TABS_PANE_OPTIONS = {
    SETTINGS: {
        tab: LOCAL.LABELS.SETTINGS,
        key: TabsKeys.Settings,
    },
    VISUALIZATION: {
        tab: LOCAL.LABELS.DECORATION,
        key: TabsKeys.Presentation,
    },
}

export const DEFAULT_TAB_KEY = TABS_PANE_OPTIONS.SETTINGS.key
