import { APPOINTMENT_STATUS_MAPPED } from 'consts'
import { NotificationDestinationCategoryCode } from 'core/api'

import { TRACK_APPOINTMENT_STATUSES_WITHOUT_ARCHIVE } from './AppointmentSelectionFilter.consts'

export const getStatusByCategoryId = (
    categoryCode?: NotificationDestinationCategoryCode
) => {
    switch (categoryCode) {
        case NotificationDestinationCategoryCode.Track:
            return {
                fieldName: 'trackStatuses',
                values: TRACK_APPOINTMENT_STATUSES_WITHOUT_ARCHIVE,
            }
        case NotificationDestinationCategoryCode.Activity:
            return {
                fieldName: 'activityStatuses',
                values: TRACK_APPOINTMENT_STATUSES_WITHOUT_ARCHIVE,
            }
        default:
            return {
                fieldName: 'otherStatuses',
                values: APPOINTMENT_STATUS_MAPPED,
            }
    }
}
