import { LOCAL } from 'core/local'

export const TABS_PANE_OPTIONS = {
    EXPERT: {
        tab: LOCAL.LABELS.EXPERT,
        key: 'expert',
    },
    GROUP_EXPERT: {
        tab: LOCAL.LABELS.EXPERT_GROUP,
        key: 'groupExpert',
    },
}
