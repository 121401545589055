export const PERMISSIONS = {
    ADAPTATION_TRACKS_READ: 'доступ к ресстру треков адаптации',
    ADAPTATION_TRACK_DELETE: 'доступ к удалению трека адаптации',
    ADAPTATION_TRACK_PUBLISH: 'доступ к публикации трека адаптации',
    ADAPTATION_TRACK_READ: 'доступ к просмотру треков адаптации',
    ADAPTATION_TRACK_SAVE: 'доступ к сохранению трека адаптации',
    ADMINISTRATION: 'доступ к административной панели',
    APPOINTMENT_ACTIVITIES_READ: 'доступ к реестру назначений мероприятий',
    APPOINTMENT_ACTIVITY_DELETE: 'доступ на удаление назначений мероприятий',
    APPOINTMENT_ACTIVITY_PUBLISH: 'доступ на назначение мероприятия',
    APPOINTMENT_ACTIVITY_READ: 'доступ к работе с назначениями мероприятий',
    APPOINTMENT_ACTIVITY_SAVE:
        'доступ на создание, копирование и редактирование назначений мероприятий',
    ASSESSMENT_APPOINTMENTS_READ: 'доступ к реестру назначений оценки',
    ASSESSMENT_APPOINTMENT_180270360_READ:
        'доступ к реестру назначений оценки типов: 180, 270, 360',
    ASSESSMENT_APPOINTMENT_CANCEL: 'доступ к отмене назначения оценки',
    ASSESSMENT_APPOINTMENT_CONTINUE: 'доступ к возобновлению назначения оценки',
    ASSESSMENT_APPOINTMENT_DELETE: 'доступ к удалению назначения оценки',
    ASSESSMENT_APPOINTMENT_EXPERT_READ:
        'доступ к реестру назначений оценки типа экспертная оценка',
    ASSESSMENT_APPOINTMENT_MANAGER_READ:
        'доступ к просмотру назначений оценки типа оценка руководителем',
    ASSESSMENT_APPOINTMENT_MONITORING:
        'доступ к просмотру прогресса назначения оценки',
    ASSESSMENT_APPOINTMENT_PAUSE: 'доступ к приостановке назначения оценки',
    ASSESSMENT_APPOINTMENT_PUBLISH: 'доступ к публикации назначения оценки',
    ASSESSMENT_APPOINTMENT_SAVE: 'доступ к сохранению назначения оценки',
    ASSESSMENT_APPOINTMENT_STOP: 'доступ к завершению назначения оценки',
    ASSESSMENT_PORTFOLIOS_READ: 'доступ к реестру портфелей оценок',
    ASSESSMENT_PORTFOLIO_180270360_READ:
        'доступ к просмотру портфелей оценок типа 180/270/360',
    ASSESSMENT_PORTFOLIO_DELETE: 'доступ к удалению портфелей оценок',
    ASSESSMENT_PORTFOLIO_EXPERT_READ:
        'доступ к просмотру портфелей оценок типа экспертная оценки',
    ASSESSMENT_PORTFOLIO_MANAGER_READ:
        'доступ к просмотру портфелей оценок типа оценки руководителя',
    ASSESSMENT_PORTFOLIO_PUBLISH: 'доступ к публикации портфелей оценок',
    ASSESSMENT_PORTFOLIO_SAVE: 'доступ к сохранению портфелей оценок',
    ASSESSMENT_PROJECTS_READ: 'доступ к реестру проектов оценки',
    ASSESSMENT_PROJECT_360_READ: 'доступ на просмотр проекта оценки типа 360',
    ASSESSMENT_PROJECT_DELETE: 'доступ на уделние проектов оценки',
    ASSESSMENT_PROJECT_MANAGER_READ:
        'доступ на просмотр проекта оценки типа оценка руководителем',
    ASSESSMENT_PROJECT_MONITORING:
        'доступ на просмотр прогресса проектов оценки',
    ASSESSMENT_PROJECT_PUBLISH: 'доступ на публикацию проектов оценки',
    ASSESSMENT_PROJECT_SAVE:
        'доступ на создание и редактирование проектов оценки',
    ASSESSMENT_REPORTS_READ: 'доступ к реестру отчетов',
    ASSESSMENT_REPORT_EMPLOYEE_ACCESS: 'доступ к изменению состояния ',
    ASSESSMENT_REPORT_READ: 'доступ к просмотру отчета',
    ASSESSMENT_REPORT_TEMPLATE_360_SAVE:
        'доступ к сохранению шаблона отчета оценки 360',
    ASSESSMENT_REPORT_TEMPLATE_MANAGER_SAVE:
        'доступ к сохранению шаблона отчета оценки руководителя',
    CERTIFICATE_TEMPLATE_SAVE:
        'доступ пользователей к загрузке шаблонов сертификатов',
    COMPETENCE_DELETE: 'доступ к удалению и отправке в компетенций в архив',
    COMPETENCE_EXTRA_SAVE:
        'доступ к экстренному сохранению и редактированию компетенций',
    COMPETENCE_GROUP_DELETE:
        'доступ к удалению и отправке в групп компетенций в архив',
    COMPETENCE_GROUP_PUBLISH: 'доступ к публикации групп компетенций',
    COMPETENCE_GROUP_READ: 'доступ к просмотру групп компетенций',
    COMPETENCE_GROUP_SAVE:
        'доступ к сохранению и редактированию групп компетенций',
    COMPETENCE_IMPORT: 'доступ к импорту компетенций',
    COMPETENCE_PUBLISH: 'доступ к публикации компетенций',
    COMPETENCE_SAVE: 'доступ к сохранению и редактированию компетенций',
    COMPETENCIES_READ: 'доступ к реестру компетенций',
    COMPETENCIE_READ: 'доступ к просмотру компетенций',
    COMPLEXES_READ: 'доступ к ресстру комплексов',
    COURSES_READ: 'доступ к реестру обучающих материалов',
    COURSE_DELETE: 'доступ на удаление обучающих материалов',
    COURSE_PLAY: 'доступ на воппроизведение обучающих материалов',
    COURSE_READ: 'доступ на просмотр обучающих материалов',
    COURSE_SAVE: 'доступ на добавление и загрузку обучающих материалов',
    DEVELOPER: 'полные права на все для локальной разработки',
    DEVELOPMENT_TRACKS_READ: 'доступ к ресстру треков развития',
    DEVELOPMENT_TRACK_DELETE: 'доступ к удалению трека развития',
    DEVELOPMENT_TRACK_PUBLISH: 'доступ к публикации трека развития',
    DEVELOPMENT_TRACK_READ: 'доступ к просмотру треков развития',
    DEVELOPMENT_TRACK_SAVE: 'доступ к сохранению трека развития',
    EVENTS_READ: 'доступ к ресстру мероприятий',
    EVENT_DELETE: 'доступ к удалению мероприятия',
    EVENT_PASSING: 'доступ к прохождению мероприятия',
    EVENT_PUBLISH: 'доступ к публикации мероприятия',
    EVENT_READ: 'доступ к просмотру мероприятия',
    EVENT_SAVE: 'доступ к сохранению мероприятия',
    INDICATOR_DELETE:
        'доступ к удалению и отправке индикаторов компетенций в архив',
    INDICATOR_PUBLISH: 'доступ к публикации индикаторов компетенций',
    INDICATOR_READ: 'доступ к просмотру индикаторов компетенций',
    INDICATOR_SAVE:
        'доступ к сохранению и редактированию индикаторов компетенций',
    KNOWLEDGE_BASE_ARTICLE_DELETE:
        'доступ пользователей к удалению статей и разделов',
    KNOWLEDGE_BASE_ARTICLE_READ:
        'доступ пользователей к просмотру статей и разделов',
    KNOWLEDGE_BASE_ARTICLE_SAVE:
        'доступ пользователей к созданию, копированию и редактированию статей и разделов',
    KNOWLEDGE_BASE_READ: 'доступ пользователей к реестру Базы знаний',
    LK: 'доступ к разделу "Личный кабинет"',
    LK_HOMEWORK_CHECK: 'доступ к разделу "Проверка ДЗ"',
    LK_MEETING_RESULT: 'доступ к разделу "Результаты очных встреч"',
    LK_MONITORING: 'доступ к разделу "Мониторинг работы пользователей"',
    LK_QUIZ_CHECK: 'доступ к разделу "Проверка опросов"',
    MGUU_TRACKS_READ: 'доступ к реестру треков МГУУ',
    MGUU_TRACK_READ: 'доступ пользователей к просмотру треков МГУУ',
    MGUU_TRACK_SAVE:
        'доступ на создание, копирование и редактирование треков МГУУ',
    NOTIFICATION_MAILING_TASKS_READ: 'доступ к реестру заданий на рассылку',
    NOTIFICATION_MAILING_TASK_CONTINUE:
        'доступ на возобновление заданий на рассылку',
    NOTIFICATION_MAILING_TASK_DELETE: 'доступ на удаление заданий на рассылку',
    NOTIFICATION_MAILING_TASK_PAUSE:
        'доступ на приостановление заданий на рассылку',
    NOTIFICATION_MAILING_TASK_READ:
        'доступ пользователей к просмотру реестра заданий на рассылку',
    NOTIFICATION_MAILING_TASK_SAVE:
        'доступ на создание, копирование и редактирование заданий на рассылку',
    NOTIFICATION_NEWSLETTER_TEMPLATES_READ:
        'доступ к реестру шаблонов рассылки',
    NOTIFICATION_NEWSLETTER_TEMPLATE_DELETE:
        'доступ на удаление шаблонов рассылки',
    NOTIFICATION_NEWSLETTER_TEMPLATE_PUBLISH:
        'доступ на публикацию шаблонов рассылки',
    NOTIFICATION_NEWSLETTER_TEMPLATE_READ:
        'доступ пользователей к просмотру реестра шаблонов рассылки',
    NOTIFICATION_NEWSLETTER_TEMPLATE_SAVE:
        'доступ на создание, копирование и редактирование шаблонов рассылки',
    NOTIFICATION_TAGS_READ: 'доступ к реестру тегов оповещений',
    NOTIFICATION_TAG_DELETE: 'доступ на удаление тегов оповещений',
    NOTIFICATION_TAG_READ:
        'доступ пользователей к просмотру реестра тегов оповещений',
    NOTIFICATION_TAG_SAVE:
        'доступ на создание, копирование и редактирование тегов оповещений',
    POSITION_PROFILES_READ: 'доступ к реестру профилей должностей',
    POSITION_PROFILE_DELETE: 'доступ к удалению профиля должности',
    POSITION_PROFILE_PUBLISH: 'доступ к публикации профиля должности',
    POSITION_PROFILE_READ: 'доступ к профилю должности',
    POSITION_PROFILE_SAVE: 'доступ к созданию профиля должности',
    PRODUCT_CARDS_READ: 'доступ к реестру карточек продуктов',
    PRODUCT_CARD_DELETE: 'доступ на удаление карточки продукта',
    PRODUCT_CARD_PUBLISH: 'доступ на публикацию карточки продукта',
    PRODUCT_CARD_READ: 'доступ к просмотру и работе с карточкой продуктов',
    PRODUCT_CARD_SAVE:
        'доступ на cоздание, копирование и редактирование карточки продукта',
    PURPOSES_READ: 'доступ к справочнику целей развития',
    PURPOSE_DELETE: 'доступ на удаление цели развития',
    PURPOSE_READ: 'доступ пользователей к работе с целями развития',
    PURPOSE_SAVE: 'доступ на создание и редактирование цели развития',
    QUESTIONNAIRES_READ: 'доступ к реестру опросных листов',
    QUESTIONNAIRE_DELETE: 'доступ к удалению опросных листов',
    QUESTIONNAIRE_PUBLISH: 'доступ к публикации опросных листов',
    QUESTIONNAIRE_SAVE: 'доступ сохранению опросных листов',
    QUIZ_APPOINTMENTS_READ: 'доступ к реестру назначений опроса',
    QUIZ_APPOINTMENT_CANCEL: 'доступ на отмену назначений опроса',
    QUIZ_APPOINTMENT_CONTINUE: 'доступ на возобновление назначений опроса',
    QUIZ_APPOINTMENT_DELETE: 'доступ на удаление назначений опроса',
    QUIZ_APPOINTMENT_FEEDBACK_READ:
        'доступ к просмотру назначений опроса типа обратная связь',
    QUIZ_APPOINTMENT_INVOLMENT_READ:
        'доступ на просмотр назначений опроса типа вовлеченность',
    QUIZ_APPOINTMENT_MONITORING:
        'доступ на просмотр прогресса назначений опроса',
    QUIZ_APPOINTMENT_PAUSE: 'доступ на приостановку назначений опроса',
    QUIZ_APPOINTMENT_PUBLISH: 'доступ на назначение назначений опроса',
    QUIZ_APPOINTMENT_RECOMMEND_READ:
        'доступ на просмотр назначений опроса типа готовность порекомендовать',
    QUIZ_APPOINTMENT_SAVE:
        'доступ на создание и редактирование назначений опроса',
    QUIZ_APPOINTMENT_SERVICE_QUALITY_READ:
        'доступ на просмотр назначений опроса типа качество сервисов',
    QUIZ_APPOINTMENT_STOP: 'доступ на завершение назначений опроса',
    QUIZ_PORTFOLIOS_DELETE: 'доступ на удаление портфелей опросов',
    QUIZ_PORTFOLIOS_FEEDBACK_READ:
        'доступ на просмотр портфелей опросов типа обратная связь',
    QUIZ_PORTFOLIOS_INVOLMENT_READ:
        'доступ на просмотр портфелей опросов типа вовлеченность',
    QUIZ_PORTFOLIOS_PUBLISH: 'доступ на публикацию портфелей опросов',
    QUIZ_PORTFOLIOS_READ: 'доступ к ресстру портфелей опросов',
    QUIZ_PORTFOLIOS_RECOMMEND_READ:
        'доступ на просмотр портфелей опросов типа готовность порекомендовать',
    QUIZ_PORTFOLIOS_SAVE:
        'доступ на создание, копирование и редактирование портфелей опросов',
    QUIZ_PORTFOLIOS_SERVICE_QUALITY_READ:
        'доступ на просмотр портфелей опросов типа качество сервисов',
    SCALES_READ: 'доступ к ресстру шкал',
    SCALE_DELETE: 'доступ к удалению шкалы',
    SCALE_PUBLISH: 'доступ к публикации шкалы',
    SCALE_READ: 'доступ к просмотру шкалы',
    SCALE_SAVE: 'доступ к созданию, копированию и сохранению шкалы',
    STAFF_UNITS_READ: 'доступ к справочнику штатных единиц',
    STRUCTURAL_UNITS_READ: 'доступ к организационной структуре',
    TEST_APPOINTMENTS_READ: 'доступ к реестру назначений тестов',
    TEST_APPOINTMENT_CANCEL: 'доступ к отмене назначения тестов',
    TEST_APPOINTMENT_CONTINUE: 'доступ к возобновлению назначения тестов',
    TEST_APPOINTMENT_DELETE: 'доступ к удалению назначения тестов',
    TEST_APPOINTMENT_MONITORING:
        'доступ к мониторингу прогресса назначения тестов',
    TEST_APPOINTMENT_PAUSE: 'доступ к приостановке назначения тестов',
    TEST_APPOINTMENT_PUBLISH: 'доступ к публикации назначения тестов',
    TEST_APPOINTMENT_READ: 'доступ к назначению теста',
    TEST_APPOINTMENT_SAVE: 'доступ к сохранению назначения теста',
    TEST_APPOINTMENT_STOP: 'доступ к завершению назначения тестов',
    TEST_PORTFOLIOS_READ: 'доступ к реестру портфелей тестов',
    TEST_PORTFOLIO_DELETE: 'доступ к удалению портфеля тестов',
    TEST_PORTFOLIO_PUBLISH: 'доступ к публикации портфеля тестов',
    TEST_PORTFOLIO_READ: 'доступ к портфелю теста',
    TEST_PORTFOLIO_SAVE: 'доступ к сохранению портфеля теста',
    TEXT_DOCUMENTS_READ: 'доступ к реестру документов',
    TEXT_DOCUMENT_DELETE: 'доступ на удаление документов',
    TEXT_DOCUMENT_READ: 'доступ к работе с документами',
    TEXT_DOCUMENT_SAVE: 'доступ на создание и редактирование документов',
    THEMES_READ: 'доступ к реестру тематик',
    THEME_CREATE: 'доступ к созданию тематики',
    THEME_DELETE: 'доступ к удалению тематики',
    THEME_READ: 'доступ к тематике',
    THEME_UPDATE: 'доступ к редактированию тематики',
    TRACK_APPOINTMENTS_READ: 'доступ к реестру назначений треков',
    TRACK_APPOINTMENT_DELETE: 'доступ на удаление назначений треков',
    TRACK_APPOINTMENT_GO: 'доступ на назначение назначений треков',
    TRACK_APPOINTMENT_READ: 'доступ к просмотру назначений треков',
    TRACK_APPOINTMENT_SAVE:
        'доступ на создание, копирование и редактирование назначений треков',
    USERS_PROFILE_READ: 'доступ к просмотру профайла пользователя',
    USERS_READ: 'доступ к ресстру сотрудников',
}
