import { AssessmentRole } from 'core/api'

export const ROLES_TO_EXCLUDE = [
    AssessmentRole.GroupExpert,
    AssessmentRole.Expert,
    AssessmentRole.SelfAssessment,
]

/** Список ролей для типа оценки 360 в родительном падеже и множественном числе*/
export const ASSESSMENT_ROLES_GENITIVE_PLURAL = {
    [AssessmentRole.Colleague]: 'Коллег',
    [AssessmentRole.Subordinate]: 'Подчиненных',
    [AssessmentRole.Manager]: 'Руководителей',
    [AssessmentRole.ColleagueFromRelatedDepartment]:
        'Коллег из смежного отдела',
}
