import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DATE_FORMAT } from 'consts'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { HomeworkRegistryItemContract } from 'core/api'
import { LOCAL } from 'core/local'
import { WidthRestrictedElement } from 'components/shared'
import {
    formatDate,
    getDateByFormat,
    isAppointmentTypeActivity,
    renderTableCellTitle,
    restrictBigText,
} from 'utils'

import { HomeworksActions } from './components/HomeworksActions'
import { renderHomeworkStatus } from './Homeworks.utils'

export const getTableColumnsConfig = (
    props: DictionaryPageActionsProps
): ColumnsType<HomeworkRegistryItemContract> => [
    {
        title: LOCAL.LABELS.TRACK_OR_EVENT,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        fixed: 'left',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type) => (
            <WidthRestrictedElement
                title={
                    isAppointmentTypeActivity(type)
                        ? LOCAL.LABELS.EVENT_M
                        : LOCAL.LABELS.TRACK
                }
            />
        ),
    },
    {
        title: LOCAL.LABELS.ASSIGNMENT,
        dataIndex: 'appointmentName',
        key: 'appointmentName',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.TRAVEL_DIARY,
        dataIndex: 'lessonName',
        key: 'lessonName',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.REVIEWER,
        dataIndex: ['tutor', 'id'],
        key: 'tutorId',
    },
    {
        title: LOCAL.LABELS.COMPLETION_DATE,
        dataIndex: 'onCheckingDate',
        key: 'onCheckingDate',
        sorter: defaultSorter,
        render: (date) => renderTableCellTitle(formatDate(date)),
        width: '200px',
    },
    {
        title: LOCAL.LABELS.CHECK_START_DATE,
        dataIndex: 'checkingStartDate',
        key: 'checkingStartDate',
        sorter: defaultSorter,
        render: (date) =>
            renderTableCellTitle(getDateByFormat(date, DATE_FORMAT.WITH_TIME)),
        width: '200px',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status) => renderHomeworkStatus(status),
        fixed: 'right',
    },
    {
        render: (tableRow) => (
            <HomeworksActions tableRow={tableRow} {...props} />
        ),
        align: 'right',
        fixed: 'right',
    },
]
