import React from 'react'
import { Tabs } from 'antd'

import { ChartsBlock } from '../ChartsBlock'
import { ChartsProps } from './Charts.types'
import { TABS } from './Charts.config'

/** компонент отрисовки вкладок графика в назначениях опросов */
export const Charts: React.FC<ChartsProps> = React.memo(({ appointmentId }) => (
    <Tabs
        defaultActiveKey={TABS.histogram}
        size="large"
        type="card"
        destroyInactiveTabPane
    >
        <Tabs.TabPane tab={TABS.histogram} key={TABS.histogram}>
            <ChartsBlock appointmentId={appointmentId} />
        </Tabs.TabPane>

        <Tabs.TabPane tab={TABS.semantic} key={TABS.semantic}>
            <ChartsBlock appointmentId={appointmentId} isSemantic={true} />
        </Tabs.TabPane>
    </Tabs>
))
