import isEqual from 'lodash/isEqual'
import unionWith from 'lodash/unionWith'
import { DictionaryContract } from 'core/api'
import { normalizeDataForTreeSelect } from 'utils'

import { QuestionnaireSetRowValueProps } from '../QuestionnaireSetRow'

export const composeQuestionnairesTreeData = (
    questionnairesField?: QuestionnaireSetRowValueProps[],
    questionnairesState?: DictionaryContract[]
) => {
    const fetchedQuestionnaires = questionnairesState?.map(
        normalizeDataForTreeSelect
    )

    if (questionnairesField) {
        const selectedQuestionnaires = questionnairesField.map((el) =>
            normalizeDataForTreeSelect({
                name: el.name,
                id: el.questionnaireId,
            })
        )

        return unionWith(selectedQuestionnaires, fetchedQuestionnaires, isEqual)
    }

    return fetchedQuestionnaires
}
