import React, { useEffect, useMemo, useState } from 'react'
import {
    AssessmentReportManagerService,
    ManagerReportSpiderWebContract,
} from 'core/api'
import { Chart } from 'chart.js'
import { CheckboxControl, ControlGroup } from 'components/controls'
import { Diagram, NoChartData } from 'components/shared'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './CompetenceAssessmentResults.module.scss'
import {
    DATASET_ITEM_CONFIGS_COLLECTION,
    LABELS_CHECKED,
    RADAR_CHART_OPTIONS,
} from './CompetenceAssessmentResults.config'
import {
    getRadarChartData,
    handleChangeVisibleLabel,
    isKeyPositionProfileLevel,
} from './CompetenceAssessmentResults.utils'

/** Отчёт по оценке руководителя. Общие результаты оценки компетенций сотрудника */
export const CompetenceAssessmentResults: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp<ManagerReportSpiderWebContract[]>(
        AssessmentReportManagerService.getSpiderWebDiagram
    )
    const [chart, setChart] = useState<Chart>()

    const [labelsChecked, setLabelsChecked] = useState<{
        [key: string]: boolean
    }>(LABELS_CHECKED)

    const radarChartData = useMemo(() => getRadarChartData(data), [data])

    /**
     * Список лейблов для кастомной легенды
     */
    const labelsList = useMemo(
        () =>
            DATASET_ITEM_CONFIGS_COLLECTION.map((el) => {
                const isNotHavePositionProfile = data?.every(
                    (el) => !el.positionProfileLevel
                )
                if (
                    isKeyPositionProfileLevel(el.dataKey) &&
                    isNotHavePositionProfile
                )
                    return null

                return (
                    <CheckboxControl
                        key={el.dataKey}
                        className={el.className}
                        defaultChecked
                        onChange={handleChangeVisibleLabel(
                            el.dataKey,
                            chart,
                            setLabelsChecked
                        )}
                        checked={labelsChecked[el.dataKey]}
                    >
                        {el.label}
                    </CheckboxControl>
                )
            }),
        [data, chart, labelsChecked]
    )

    const displayDiagram = data && data.length >= 3

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return displayDiagram ? (
        <div className={styles.wrapper}>
            <ControlGroup type="dark" className={styles.legends}>
                {labelsList}
            </ControlGroup>

            <div className={styles.diagram}>
                <Diagram
                    type="radar"
                    data={radarChartData}
                    options={RADAR_CHART_OPTIONS}
                    getChartInstance={setChart}
                />
            </div>
        </div>
    ) : (
        <NoChartData />
    )
})
