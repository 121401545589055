import React from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { QuestionType, StructuralUnitType } from 'core/api'

export const TREE_SELECT_NORMALIZE_TYPE = 'treeSelect'

export const TEXT_DOCUMENT_TYPE = '.doc, .docx, .ppt, .pptx, .pdf, .xls, .xlsx'

export const THEME_BLOCKS_DOCUMENT_TYPE =
    '.doc, .docx, .pptx, .pdf, .xls, .xlsx'

export const TABLE_DOCUMENT_TYPE = '.xlsx'

export const VIDEO_DOCUMENT_TYPE = '.mpeg, .avi, .mp4'

export const TITLE_MAIN_FONT_SIZES = {
    54: 54,
    56: 56,
    58: 58,
}

export const TITLE_SECONDARY_FONT_SIZES = {
    22: 22,
    24: 24,
    26: 26,
}

export const MEDIA_DOCUMENT_TYPE = '.jpg, .png'

export const PICTURE_VALIDATE_FORMATS = ['image/jpeg', 'image/png']

export const LINE_HEIGHTS = {
    1: 1,
    1.5: 1.5,
}

export enum TextAlign {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export enum AlignSelf {
    Left = 'flex-start',
    Center = 'center',
    Right = 'flex-end',
}

/** Настройки выравнивания текста на холсте */
export const TEXT_ALIGN_OPTION = {
    [TextAlign.Left]: <IconsAdapter iconType="AlignLeftOutlined" />,
    [TextAlign.Center]: <IconsAdapter iconType="AlignCenterOutlined" />,
    [TextAlign.Right]: <IconsAdapter iconType="AlignRightOutlined" />,
}

export const ALIGN_SELF_OPTION = {
    [AlignSelf.Left]: <IconsAdapter iconType="AlignLeftOutlined" />,
    [AlignSelf.Center]: <IconsAdapter iconType="AlignCenterOutlined" />,
    [AlignSelf.Right]: <IconsAdapter iconType="AlignRightOutlined" />,
}

export const STRUCTURAL_UNIT_SEARCH_BODY = {
    types: [
        StructuralUnitType.Oiv,
        StructuralUnitType.Organization,
        StructuralUnitType.Unit,
    ],
}

export const ANSWER_FIELD_NAME_BY_QUESTION_TYPE = {
    [QuestionType.TextQuestion]: 'textAnswerParameters',
    [QuestionType.ChooseVariant]: 'chooseAnswerParameters',
    [QuestionType.CriteriaScale]: 'scaleAnswerParameters',
    [QuestionType.FillEmptyText]: 'missingTextAnswerParameters',
}
