import React from 'react'
import { Col, Form, Row } from 'antd'
import { HiddenField, RadioButtonControl } from 'components/controls'
import { normalizeDictionaryToControl } from 'utils'

import styles from './IndicatorsEvaluationList.module.scss'
import { IndicatorsEvaluationListProps } from './IndicatorsEvaluationList.types'
import { STRONG_WEAK_SIDE } from './IndicatorsEvaluationList.consts'

/** Оценка индикаторов */
export const IndicatorsEvaluationList: React.FC<IndicatorsEvaluationListProps> = React.memo(
    ({ field, fieldIndex, indicatorData }) => (
        <Row key={field.key}>
            <HiddenField fieldName={[field.name, 'indicatorId']} />

            <Col xs={8}>
                <Row gutter={5} className={styles.indicatorName}>
                    <Col>{`${fieldIndex + 1}.`}</Col>

                    <Col>{indicatorData?.indicatorName}</Col>
                </Row>
            </Col>

            <Col xs={8} className={styles.radio}>
                <Form.Item name={[field.name, 'isStrongSide']}>
                    <RadioButtonControl
                        values={normalizeDictionaryToControl(STRONG_WEAK_SIDE)}
                        buttonStyle="solid"
                    />
                </Form.Item>
            </Col>
        </Row>
    )
)
