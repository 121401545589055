import React, { useCallback, useState } from 'react'
import { Button } from 'components/shared'
import { Form, Upload } from 'antd'
import { FormListOperation } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'
import { TrackHeaderIconsService } from 'core/api'
import { beforeUploadAttachment, isFormModeView } from 'utils'

import styles from './TrackHeaderIconsList.module.scss'
import { ACCEPT_FORMATS, LOGO_MAX_COUNT } from '../UploadLogo.const'
import { TrackHeaderIconsListProps } from './TrackHeaderIconsList.types'
import { UploadLogoItem } from '../UploadLogoItem'

export const TrackHeaderIconsList: React.FC<TrackHeaderIconsListProps> = React.memo(
    ({ form, disabled, formMode }) => {
        const [isLoading, setLoading] = useState(false)

        const handleUploadLogo = useCallback(
            async ({ file }, add: FormListOperation['add']) => {
                try {
                    setLoading(true)

                    const result = await TrackHeaderIconsService.upload({
                        file,
                    })

                    add({
                        id: result.id,
                        url: result.url,
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    setLoading(false)
                }
            },
            []
        )

        return (
            <Form.List name="trackHeaderIcons">
                {(fields, { add, remove }) => (
                    <>
                        {!!fields.length &&
                            fields.map((field) => (
                                <UploadLogoItem
                                    field={field}
                                    remove={remove}
                                    key={field.key}
                                    form={form}
                                    disabled={disabled}
                                    formMode={formMode}
                                />
                            ))}

                        {fields.length < LOGO_MAX_COUNT && (
                            <Upload
                                beforeUpload={beforeUploadAttachment({
                                    documentType: ACCEPT_FORMATS,
                                    fileSize: 2,
                                })}
                                customRequest={(options) =>
                                    handleUploadLogo(options, add)
                                }
                                showUploadList={false}
                                accept={ACCEPT_FORMATS}
                                className={styles.downloadLogo}
                                disabled={disabled || isFormModeView(formMode)}
                            >
                                <Button
                                    type="primary"
                                    className={styles.downloadBtn}
                                    title={ACCEPT_FORMATS}
                                    disabled={
                                        disabled || isFormModeView(formMode)
                                    }
                                    loading={isLoading}
                                >
                                    {LOCAL.ACTIONS.DOWNLOAD_LOGO}
                                </Button>
                            </Upload>
                        )}
                    </>
                )}
            </Form.List>
        )
    }
)
