import React from 'react'
import {
    ACTIVITIES_TABLE_COLUMNS,
    ActivitiesFilters,
    FormItemAdapter,
    SearchSelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import {
    ActivitiesService,
    ActivityModalContract,
    ActivityModalSearchContract,
    DocumentStatus,
} from 'core/api'
import { AddonAfterDefault } from 'components/shared'
import { EVENTS_REQUIRED_PERMISSIONS } from 'consts'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'

import { ActivityFieldsProps } from './ActivityFields.types'

export const ActivityFields: React.FC<ActivityFieldsProps> = React.memo(
    ({ field, formMode, isTypeMixed, eventType, disabled }) => (
        <ShouldUpdateChecker
            fieldPath={[['lessons', field.name, 'nestedActivity']]}
        >
            {({ getFieldValue }) => {
                const nestedActivityId = getFieldValue([
                    'lessons',
                    field.name,
                    'nestedActivity',
                ])?.[0].value

                return (
                    <FormItemAdapter
                        label={LOCAL.LABELS.EVENT_M}
                        fieldName={[field.name, 'nestedActivity']}
                        required
                    >
                        <SearchSelectControl<
                            ActivityModalSearchContract,
                            ActivityModalContract
                        >
                            getByIdsMethod={ActivitiesService.getByIds}
                            allowClearSelect
                            formMode={formMode}
                            modalTitle={LOCAL.LABELS.EVENT_M_SELECTION}
                            addonAfter={
                                <AddonAfterDefault
                                    url={`${ROUTE_NAMES.EVENTS_VIEW}/${nestedActivityId}`}
                                    disabled={!nestedActivityId}
                                    permissionsRequired={
                                        EVENTS_REQUIRED_PERMISSIONS
                                    }
                                />
                            }
                            tableSearchOptions={{
                                searchMethod: ActivitiesService.getForModal,
                                filterComponent: ActivitiesFilters,
                                tableColumns: ACTIVITIES_TABLE_COLUMNS,
                                tableFiltersOptions: {
                                    initialValues: {
                                        type: isTypeMixed
                                            ? undefined
                                            : eventType,
                                        statuses: [DocumentStatus.ReadyForWork],
                                    },
                                    disabledFields: {
                                        type: !isTypeMixed,
                                    },
                                },
                            }}
                            disabled={disabled}
                        />
                    </FormItemAdapter>
                )
            }}
        </ShouldUpdateChecker>
    )
)
