import React, { useCallback, useEffect, useState } from 'react'
import {
    APPOINTMENT_STATUS_WITHOUT_CHECKED_MAPPED,
    DEPRECATED_QUIZ_TYPES,
    QUIZ_TYPES_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { AppointmentSearchContract, StructuralUnitsService } from 'core/api'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import { DataNode } from 'rc-tree-select/lib/interface'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import {
    FiltersProps,
    excludeSelectOptions,
    normalizeDataForTreeSelect,
} from 'utils'
import { LOCAL } from 'core/local'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'

import { AssignmentsFiltersSubmitValues } from './QuizAssignmentsFilters.types'
import { QUIZ_FILTERS_INITIAL_VALUES } from './QuizAssignmentsFilters.consts'

/**
 * Форма для работы с фильтрацией назначений опросов
 */
export const QuizAssignmentsFilters: React.FC<FiltersProps<
    AppointmentSearchContract
>> = React.memo((props) => {
    const [structuralUnits, setStructuralUnits] = useState<DataNode[]>()

    const mapFormDataToRequest = useCallback(
        (values: Partial<AssignmentsFiltersSubmitValues>) => ({
            ...values,
            appointmentQuizSearch: {
                type: values.appointmentQuizSearchType,
            },
        }),
        []
    )

    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                const structuralUnitsData = await StructuralUnitsService.getAllStructuralUnits()
                setStructuralUnits(
                    structuralUnitsData.map(normalizeDataForTreeSelect)
                )
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                mapFormDataToRequest={mapFormDataToRequest}
                tableFiltersOptions={{
                    initialValues: QUIZ_FILTERS_INITIAL_VALUES,
                }}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.APPOINTMENT_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitIds"
                            label={LOCAL.LABELS.ORGANIZATIONS}
                        >
                            <TreeSelectControl
                                treeData={structuralUnits || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="appointmentQuizSearchType"
                            label={LOCAL.LABELS.QUIZ_TYPE}
                        >
                            <SelectControl
                                values={excludeSelectOptions(
                                    QUIZ_TYPES_MAPPED,
                                    DEPRECATED_QUIZ_TYPES
                                )}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    APPOINTMENT_STATUS_WITHOUT_CHECKED_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_START,
                                fieldName: 'evaluationPeriodFrom',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_END,
                                fieldName: 'evaluationPeriodTo',
                            }}
                        />
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CREATE_FROM,
                                fieldName: 'creationDateFrom',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CREATE_TO,
                                fieldName: 'creationDateTo',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
