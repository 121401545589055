import { ColumnsType } from 'antd/lib/table'
import { DictionaryContract } from 'core/api'
import { LOCAL } from 'core/local'

/**
 * Настройка отображения таблицы только с колонкой "name"
 * @param title заголовок столбца
 */
export const getTableColumnForName = <T = DictionaryContract>(
    title: string = LOCAL.LABELS.NAME
): ColumnsType<T> => [
    {
        title,
        dataIndex: 'name',
        key: 'name',
    },
]
