import Carousel from 'react-slick'
import React from 'react'
import cn from 'classnames'

import styles from './ProductCardCarousel.module.scss'
import { ProductCardCarouselProps } from './ProductCardCarousel.types'
import { getCarouselSettings } from './ProductCardCarousel.utils'

export const ProductCardCarousel: React.FC<ProductCardCarouselProps> = React.memo(
    ({ slidesToShow, children, itemsCount }) => (
        <Carousel
            {...getCarouselSettings({
                slidesToShow,
            })}
            className={cn(
                styles.wrapper,
                itemsCount > slidesToShow.xxl && styles.wrapperWithSlide
            )}
        >
            {children}
        </Carousel>
    )
)
