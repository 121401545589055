import React from 'react'
import { ActivityContract, UserPermission } from 'core/api'
import { ButtonsToolbar, TableActions } from 'components/shared'
import { EVENTS_REQUIRED_PERMISSIONS } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TableActionsCommonProps } from 'App.types'
import { getConfirmEditMessage } from 'core/local/messages.local.config'
import { isDocumentStatusInWork } from 'utils'
import { useConfirmRedirect } from 'hooks'

/**
 * Компонент колонки действий для реестра мероприятий
 */
export const EventsTableActions: React.FC<TableActionsCommonProps<
    ActivityContract
>> = React.memo(({ tableRow }) => {
    const { handleRedirect } = useConfirmRedirect({
        to: `${ROUTE_NAMES.EVENTS_EDIT}/${tableRow.id}`,
        needConfirm: isDocumentStatusInWork(tableRow.status),
        confirmText: getConfirmEditMessage('event'),
    })

    return (
        <ButtonsToolbar bottomIndent="0">
            <TableActions.View
                requiredPermissions={EVENTS_REQUIRED_PERMISSIONS}
                href={`${ROUTE_NAMES.EVENTS_VIEW}/${tableRow.id}`}
                disabled={!tableRow.active}
            />

            {tableRow.active && (
                <>
                    <TableActions.Play
                        requiredPermissions={[
                            ...EVENTS_REQUIRED_PERMISSIONS,
                            UserPermission.EventPassing,
                        ]}
                        href={`${ROUTE_NAMES.EVENTS_PASSING}/${tableRow.id}`}
                    />

                    <TableActions.Edit
                        requiredPermissions={[
                            ...EVENTS_REQUIRED_PERMISSIONS,
                            UserPermission.EventSave,
                        ]}
                        onClick={handleRedirect}
                    />

                    <TableActions.Copy
                        requiredPermissions={[
                            ...EVENTS_REQUIRED_PERMISSIONS,
                            UserPermission.EventSave,
                        ]}
                        href={`${ROUTE_NAMES.EVENTS_COPY}/${tableRow.id}`}
                    />

                    {!isDocumentStatusInWork(tableRow.status) && (
                        <TableActions.Delete
                            tableRow={tableRow}
                            requiredPermissions={[
                                ...EVENTS_REQUIRED_PERMISSIONS,
                                UserPermission.EventDelete,
                            ]}
                        />
                    )}
                </>
            )}
        </ButtonsToolbar>
    )
})
