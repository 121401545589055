import React from 'react'
import { ContentTitle, PageContent } from 'components/shared'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'

/** Страница для информации пользователя о том, что текущий элемент находится в разработке */
export const PlaceholderPage: React.FC = React.memo(() => (
    <PageContent>
        <ContentTitle title={LOCAL.CATALOGS.WORK_IN_PROGRESS} />

        <main>
            <h2>{LOCAL.MESSAGES.CURRENT_PAGE_IN_DEVELOPMENT}</h2>

            <h3>
                {`${LOCAL.MESSAGES.YOU_CAN_GO_TO} `}

                <Link to="/">{LOCAL.LINKS.HOME_PAGE}</Link>
            </h3>
        </main>
    </PageContent>
))
