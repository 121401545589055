import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { idToUrlParam, isQuestionnaireTypeAssessment } from 'utils'

import { AppointmentCardWrapperProps } from './AppointmentCard.types'

export const AppointmentCardWrapper: React.FC<AppointmentCardWrapperProps> = React.memo(
    ({
        type,
        ended,
        expired,
        id,
        candidate,
        needToPass,
        children,
        className,
    }) => {
        const divWrapper = <div className={className}>{children}</div>

        if (isQuestionnaireTypeAssessment(type)) {
            const assignmentUrl =
                ended || expired
                    ? ROUTE_NAMES.USER_ASSIGNMENTS_VIEW
                    : ROUTE_NAMES.USER_ASSIGNMENTS_PASSING

            const isAvailableToView = needToPass || ended || expired

            return isAvailableToView ? (
                <Link
                    className={className}
                    to={`${assignmentUrl}/${id}${idToUrlParam(
                        candidate.id
                    )}/${type}`}
                >
                    {children}
                </Link>
            ) : (
                divWrapper
            )
        }

        if (needToPass)
            return (
                <Link
                    className={className}
                    to={`${ROUTE_NAMES.QUESTIONNAIRE_PASSING}/${id}/${type}`}
                >
                    {children}
                </Link>
            )

        return divWrapper
    }
)
