import React from 'react'
import cn from 'classnames'
import { Resolutions } from 'core/configs'
import { TrackHeaderIconContract } from 'core/api'

import styles from './TrackLogoList.module.scss'
import { TrackLogo } from './components'
import { TrackLogoListProps } from './TrackLogoList.types'
import { getTrackHeaderIconsByResolution } from './TrackLogoList.utils'

export const TrackLogoList: React.FC<TrackLogoListProps> = ({
    items,
    resolution = Resolutions.XXL,
    className,
}) => {
    const trackHeaderIcons = getTrackHeaderIconsByResolution(resolution, items)

    return (
        <div className={cn(styles.wrapper, className)}>
            {trackHeaderIcons.map(
                (item: TrackHeaderIconContract, index) =>
                    item.id && (
                        <TrackLogo key={`${item.id}-${index}`} logo={item} />
                    )
            )}
        </div>
    )
}
