import { CKEditorInstance, CKEditorStatus } from 'ckeditor4-react/dist/types'
import { ColorThemes } from 'contexts'
import { isThemeColorLight } from 'contexts/ColorTheme/ColorTheme.utils'

import { EDITOR_FULL_SCREEN_STATE } from './Editor.consts'

export const setEditorStyles = (className: string, theme: ColorThemes) => {
    const toolbarStyles = isThemeColorLight(theme) ? '#f8f8f8' : '#c8d1d4'

    const ckeBlock = document?.querySelector(className) as HTMLElement

    ckeBlock.style.backgroundColor = toolbarStyles
}

export const isEditorStatusReady = (status?: CKEditorStatus) =>
    status === 'ready'

/**
 * определяет развернут ли редактор на весь экран
 * @param editor
 */
export const isEditorFullScreenState = (editor: CKEditorInstance) =>
    editor?.getCommand('maximize').state === EDITOR_FULL_SCREEN_STATE
