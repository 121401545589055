import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'
import { WithFormModeProps } from 'App.types'

import {
    renderFromDateField,
    renderStatusField,
    renderToDateField,
} from './StagesSettingsTable.utils'

/** Настройки таблицы настроек доступности этапов трека */
export const getStageSettingsTableColumns = (
    formMode: WithFormModeProps['formMode']
): ColumnsType<FormListFieldData> => [
    {
        title: LOCAL.LABELS.STAGES,
        dataIndex: 'name',
        key: 'name',
        render: (_, row, index) => `${LOCAL.LABELS.STAGE} ${index + 1}`,
        width: '10%',
    },
    {
        title: <div className="required-control">{LOCAL.LABELS.STATUS}</div>,
        dataIndex: 'status',
        key: 'status',
        render: (_, row) => renderStatusField(row, formMode),
        width: '30%',
    },
    {
        title: (
            <div className="required-control">
                {LOCAL.LABELS.PERIOD} {LOCAL.SINCE}
            </div>
        ),
        dataIndex: 'periodFrom',
        key: 'periodFrom',
        render: (_, row) => renderFromDateField(row, formMode),
        width: '30%',
    },
    {
        title: `${LOCAL.LABELS.PERIOD} ${LOCAL.TO}`,
        dataIndex: 'periodTo',
        key: 'periodTo',
        render: (_, row) => renderToDateField(row, formMode),
        width: '30%',
    },
]
