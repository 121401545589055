import React from 'react'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { ModalProps } from 'antd'
import { PlayerPdf, Popup } from 'components/shared'
import { RenderToolbar, Slot, defaultLayout } from '@phuocng/react-pdf-viewer'

import styles from './MonitoringAgreement.module.scss'

export const MonitoringAgreement: React.FC<ModalProps> = (props) => {
    const renderToolbarPDF = () => <></>

    return (
        <Popup
            {...props}
            className={styles.modal}
            width={MODAL_WIDTH.XL}
            title={LOCAL.LABELS.USERS_AGREEMENT}
            cancelText={LOCAL.LABELS.AGREEMENT_DECLINE}
            okText={LOCAL.LABELS.AGREEMENT_ACCEPT}
        >
            <PlayerPdf
                fileUrl="/static-api/files/monitoringAgreement.pdf"
                viewerOptions={{
                    layout: (
                        isSidebarOpened: boolean,
                        container: Slot,
                        main: Slot,
                        toolbar: RenderToolbar,
                        sidebar: Slot
                    ) =>
                        defaultLayout(
                            isSidebarOpened,
                            container,
                            main,
                            toolbar(renderToolbarPDF),
                            sidebar
                        ),
                }}
            />
        </Popup>
    )
}
