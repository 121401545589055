import stylesContentTitle from 'components/shared/ContentTitle/ContentTitle.module.scss'
import React, { useCallback, useEffect } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Form, Row, Tooltip } from 'antd'
import {
    ColumnsOptions,
    FiltersToggleButton,
    IconsAdapter,
    PageContent,
    QuickSearch,
    TableAdapter,
} from 'components/shared'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { Link, useParams } from 'react-router-dom'
import {
    NotificationMailingInfoContract,
    NotificationMailingInformationsService,
    NotificationRecipientEmailInfoSearchResultContract,
    NotificationRecipientEmailInfoSearchResultContractPageContract,
    SearchTextContract,
} from 'core/api'
import { PAGE_NUMBER_DEFAULT, PAGE_SIZE_DEFAULT, ROW_GUTTER } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsCommonProps, WithFormModeProps } from 'App.types'
import {
    createSuccessNotification,
    getBackLinkTitleFromRoute,
    getPageSize,
    getTableColumnsWithClass,
    showElem,
} from 'utils'
import { useFilter, useHttp, usePageSettings } from 'hooks'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import styles from './DistributionMailingView.module.scss'
import { DISTRIBUTION_MAILING_VIEW_TABLE_COLUMNS } from './DistributionMailingView.config'
import {
    DistributionMailingViewFields,
    DistributionMailingViewFilters,
} from './components'
import { INITIAL_SEARCH_VALUES } from './DistributionMailingView.consts'
import { getRowClassName } from './DistributionMailingView.utils'

/** Страница просмотра рассылки */
export const DistributionMailingView: React.FC<WithFormModeProps> = withLoader(
    React.memo(({ formMode, updateLoader }) => {
        const [form] = Form.useForm()

        const urlParams = useParams<UrlParamsCommonProps<'id'>>()
        const { columnsVisible, saveFilters } = usePageSettings(
            DICTIONARY_KEYS.DISTRIBUTION_MAILING_VIEW
        )

        const [getMailingData, mailingData] = useHttp<
            NotificationMailingInfoContract
        >(NotificationMailingInformationsService.getActive, updateLoader)

        const [
            getMailingListData,
            mailingListData,
            ,
            updateMailingListData,
        ] = useHttp<
            NotificationRecipientEmailInfoSearchResultContractPageContract
        >(NotificationMailingInformationsService.recipientsSearch, updateLoader)

        const [visibleFilter, triggerFilterVisibility] = useFilter()

        const notificationMailingId = urlParams.id

        const {
            paginationOptions,
            queryParams,
            handleSetFilters,
            handleResetFilters,
            handleSort,
        } = useSearchQuery({
            dictionaryKey: DICTIONARY_KEYS.DISTRIBUTION_MAILING_VIEW,
            queryOptions: {
                body: INITIAL_SEARCH_VALUES,
            },
        })

        const quickSearch = useCallback(async () => {
            try {
                if (!notificationMailingId) return

                updateLoader(true)

                const data = await NotificationMailingInformationsService.recipientsSearchByText(
                    {
                        id: +notificationMailingId,
                        pageNumber:
                            queryParams.pageNumber || PAGE_NUMBER_DEFAULT,
                        pageSize: queryParams.pageSize || PAGE_SIZE_DEFAULT,
                        body: queryParams.body as SearchTextContract,
                    }
                )

                updateMailingListData(data)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [
            notificationMailingId,
            queryParams,
            updateLoader,
            updateMailingListData,
        ])

        const updateDistributionMailingView = useCallback(() => {
            notificationMailingId &&
                getMailingData({ id: notificationMailingId })

            getMailingListData({ ...queryParams, id: notificationMailingId })
        }, [
            notificationMailingId,
            getMailingListData,
            getMailingData,
            queryParams,
        ])

        useEffect(() => {
            notificationMailingId &&
                getMailingData({ id: notificationMailingId })
        }, [getMailingData, notificationMailingId])

        useEffect(() => {
            if (queryParams.body?.searchText) {
                quickSearch()

                return
            }

            getMailingListData({ ...queryParams, id: notificationMailingId })
        }, [
            queryParams,
            getMailingListData,
            notificationMailingId,
            quickSearch,
        ])

        useEffect(() => {
            form.setFieldsValue(mailingData)
        }, [form, mailingData])

        const row = useCallback(
            (props) =>
                props.className.includes(styles.disabled) ? (
                    <Tooltip title={LOCAL.MESSAGES.NOTIFICATIONS_DISABLED}>
                        <tr {...props} />
                    </Tooltip>
                ) : (
                    <tr {...props} />
                ),
            []
        )

        useEffect(() => {
            if (saveFilters) {
                createSuccessNotification(LOCAL.MESSAGES.SAVE_FILTERS)
            }
        }, [saveFilters])

        return (
            <PageContent filled className={styles.wrapper}>
                <div className="pt-20 pb-20">
                    <Row gutter={ROW_GUTTER}>
                        <Col xs={7}>
                            <h1>{LOCAL.CATALOGS.MAILING_VIEW}</h1>

                            <div className={stylesContentTitle.backLink}>
                                <Link to={ROUTE_NAMES.DISTRIBUTION_MAILING}>
                                    <IconsAdapter iconType="ArrowLeftOutlined" />

                                    {getBackLinkTitleFromRoute(
                                        ROUTE_NAMES.DISTRIBUTION_MAILING
                                    )}
                                </Link>
                            </div>

                            <Row align="middle" className={styles.filters}>
                                <Col>
                                    <QuickSearch
                                        onSetFilters={handleSetFilters}
                                        isReset={visibleFilter}
                                        className={styles.quickSearch}
                                    />
                                </Col>

                                <Col>
                                    <FiltersToggleButton
                                        onToggleFilterVisibility={
                                            triggerFilterVisibility
                                        }
                                        isActive={saveFilters}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={15}>
                            <Form
                                id={FORM_IDS.DISTRIBUTION_MAILING_VIEW_FORM}
                                form={form}
                            >
                                <DistributionMailingViewFields
                                    formMode={formMode}
                                />
                            </Form>
                        </Col>

                        <Col xs={2}>
                            <Row justify="end">
                                <Button
                                    type="primary"
                                    onClick={updateDistributionMailingView}
                                >
                                    {LOCAL.ACTIONS.UPDATE}
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className={showElem(visibleFilter)}>
                    <DistributionMailingViewFilters
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                        dictionaryKey={
                            DICTIONARY_KEYS.DISTRIBUTION_MAILING_VIEW
                        }
                    />
                </div>

                <ColumnsOptions<
                    NotificationRecipientEmailInfoSearchResultContract
                >
                    dictionaryKey={DICTIONARY_KEYS.DISTRIBUTION_MAILING_VIEW}
                    tableColumns={DISTRIBUTION_MAILING_VIEW_TABLE_COLUMNS}
                />

                <TableAdapter
                    columns={getTableColumnsWithClass<
                        NotificationRecipientEmailInfoSearchResultContract
                    >(columnsVisible, DISTRIBUTION_MAILING_VIEW_TABLE_COLUMNS)}
                    rowKey="emailId"
                    dataSource={mailingListData?.pageItems}
                    pagination={{
                        ...paginationOptions,
                        total: mailingListData?.total,
                        current: queryParams.pageNumber,
                        pageSize: getPageSize(
                            queryParams?.pageSize,
                            mailingListData?.pageItems?.length
                        ),
                    }}
                    onChange={handleSort}
                    showSorterTooltip={false}
                    rowClassName={getRowClassName}
                    components={{
                        body: {
                            row,
                        },
                    }}
                    className={styles.table}
                />
            </PageContent>
        )
    })
)
