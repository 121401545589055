import cn from 'classnames'
import React, { useCallback } from 'react'
import moment, { Moment } from 'moment'
import { DATE_FORMAT } from 'consts'
import { DatePicker } from 'antd'
import { LOCAL } from 'core/local'
import { RangeValue } from 'rc-picker/lib/interface'
import { isFormModeView } from 'utils'

import styles from './RangePickerControl.module.scss'
import { RangePickerControlProps } from './RangePickerControl.types'
import {
    isPickerYearType,
    mappingRangePickerDate,
} from './RangePickerControl.utils'

const { RangePicker } = DatePicker

/**
 * Декоратор для `RangePicker` от `antd`, принимает все теже `props`
 */
export const RangePickerControl: React.FC<RangePickerControlProps> = React.memo(
    ({ disabled, formMode, onChange, value, picker, ...props }) => {
        const triggerChanges = useCallback(
            (value: RangeValue<Moment>, formatString: [string, string]) => {
                onChange?.(mappingRangePickerDate(value), formatString)
            },
            [onChange]
        )

        const classes = cn(
            isFormModeView(formMode) && 'view-mode',
            'form-control',
            styles.wrapper
        )

        return (
            <div className={classes}>
                <RangePicker
                    format={
                        isPickerYearType(picker)
                            ? DATE_FORMAT.LIST_YEAR
                            : DATE_FORMAT.LIST
                    }
                    placeholder={[LOCAL.SINCE, LOCAL.TO]}
                    disabled={disabled || isFormModeView(formMode)}
                    value={
                        value ? [moment(value[0]), moment(value[1])] : undefined
                    }
                    onChange={triggerChanges}
                    picker={picker}
                    {...props}
                />
            </div>
        )
    }
)
