import React, { FC, useCallback } from 'react'
import {
    AppointmentSearchContract,
    AppointmentSearchResultContract,
    AppointmentSearchTextContract,
    AppointmentsService,
    UserPermission,
} from 'core/api'
import {
    AppointmentsPassingService,
    getPageTitleFromRoute,
    getRowHandlers,
} from 'utils'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage, DictionaryPageActionsProps } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRowClassNameArchive } from 'components/shared'
import { useLocation } from 'react-router-dom'

import {
    QUIZ_FILTERS_INITIAL_VALUES,
    QuizAssignmentsFilters,
} from './components'
import { getQuizAssignmentsTableColumns } from './QuizAssignments.config'

/**
 * Реестр назначений опросов
 */
export const QuizAssignments: FC = React.memo(() => {
    const location = useLocation()
    const title = getPageTitleFromRoute(location.pathname)

    /**
     * Рендер колонок таблицы
     * @param actions - набор функций, берется из DictionaryPage
     */
    const renderTableColumns = useCallback(
        ({ reFetchDictionary, updateLoader }: DictionaryPageActionsProps) =>
            getQuizAssignmentsTableColumns(AppointmentsPassingService, {
                reFetchDictionary,
                updateLoader,
            }),
        []
    )

    return (
        <DictionaryPage<
            AppointmentSearchContract,
            AppointmentSearchResultContract,
            AppointmentSearchTextContract
        >
            dictionaryKey={DICTIONARY_KEYS.QUIZ_ASSIGNMENTS}
            searchMethod={AppointmentsService.searchAppointment}
            deleteMethod={AppointmentsService.deleteAppointment}
            contentTitle={title}
            tableColumnsRender={renderTableColumns}
            createRecordLink={{
                path: ROUTE_NAMES.QUIZ_ASSIGNMENTS_CREATE,
            }}
            filterComponent={QuizAssignmentsFilters}
            tableFiltersOptions={{
                initialValues: QUIZ_FILTERS_INITIAL_VALUES,
            }}
            createPermissions={[UserPermission.QuizAppointmentSave]}
            quickSearchMethod={AppointmentsService.searchAppointmentText}
            rowClassName={getRowClassNameArchive}
            onRow={getRowHandlers({ url: ROUTE_NAMES.QUIZ_ASSIGNMENTS_VIEW })}
        />
    )
})
