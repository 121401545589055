import React, { useCallback } from 'react'
import {
    ButtonsToolbar,
    Popup,
    RrwebPlayer,
    TableActions,
} from 'components/shared'
import { MODAL_WIDTH } from 'consts'
import { RrwebStorageService, SessionInfoContract } from 'core/api'
import { TableActionsCommonProps } from 'App.types'
import { useElementVisible, useHttp } from 'hooks'
import { withLoader } from 'HOCs'

import styles from './MonitoringTableActions.module.scss'

/** Столбец действий для таблицы мониторинга */
export const MonitoringTableActions: React.FC<TableActionsCommonProps<
    SessionInfoContract
>> = withLoader(
    React.memo(({ tableRow, updateLoader }) => {
        const [getData, data] = useHttp(
            RrwebStorageService.getSessionEvents,
            updateLoader
        )
        const { elementVisible, toggleElementVisible } = useElementVisible()

        const handlePlayRecord = useCallback(async () => {
            await getData({ sessionId: tableRow.id })
            toggleElementVisible()
        }, [tableRow, getData, toggleElementVisible])

        return (
            <ButtonsToolbar bottomIndent="0">
                <TableActions.Play onClick={handlePlayRecord} />

                {!!data?.length && (
                    <Popup
                        visible={elementVisible}
                        width={MODAL_WIDTH.XL}
                        footer={null}
                        onCancel={toggleElementVisible}
                        className={styles.player}
                    >
                        <RrwebPlayer
                            events={data.map((el) => JSON.parse(el)).flat()}
                        />
                    </Popup>
                )}
            </ButtonsToolbar>
        )
    })
)
