import { ORDER_RULES_WITH_CREATION_DATE } from 'components/layouts'
import { QuestionnaireType } from 'core/api'

/**
 * Начальные значения для фильтра
 */
export const QUIZ_FILTERS_INITIAL_VALUES = {
    type: QuestionnaireType.Quiz,
    orderRules: ORDER_RULES_WITH_CREATION_DATE,
}
