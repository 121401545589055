import React from 'react'
import { Checkbox } from 'antd'
import { RESOLUTION_RELATIONS, Resolutions } from 'core/configs'
import { normalizeDictionaryToControl } from 'utils'

export const resolutions = normalizeDictionaryToControl(Resolutions)
    .filter((el) => el.label !== Resolutions.XXL)
    .map(({ key, label }) => (
        <Checkbox value={label} key={key}>
            {RESOLUTION_RELATIONS[label as string]}
        </Checkbox>
    ))
