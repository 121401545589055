import cn from 'classnames'
import React, { Fragment, useContext, useMemo } from 'react'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'

import styles from './UserProfileLegend.module.scss'
import stylesUserProfile from '../../UserProfile.module.scss'
import { UserProfileContext } from '../../context'
import { UserProfileLegendProps } from './UserProfileLegend.types'

/** Блок с легендой для компетенций */
export const UserProfileLegend: React.FC<UserProfileLegendProps> = React.memo(
    ({ assessmentResultsData }) => {
        const { showAssessments, showProfilePosition } = useContext(
            UserProfileContext
        )

        /**
         * Рендер легенды для выбранных результатов
         */
        const legendForAssessments = useMemo(
            () =>
                assessmentResultsData?.map((el, i) => (
                    <Fragment key={el.id}>
                        <Col>
                            <div
                                className={cn(
                                    styles.marker,
                                    stylesUserProfile[
                                        `assessmentResultsCompetence_${el.assessmentType}_${i}`
                                    ]
                                )}
                            />

                            {`${LOCAL.LABELS.COMPETENCIES} ${el.name}`}
                        </Col>

                        <Col>
                            <div
                                className={cn(
                                    styles.marker,
                                    stylesUserProfile[
                                        `assessmentResultsIndicator_${el.assessmentType}_${i}`
                                    ]
                                )}
                            />

                            {`${LOCAL.LABELS.INDICATORS} ${el.name}`}
                        </Col>
                    </Fragment>
                )),
            [assessmentResultsData]
        )

        return (
            <Row align="middle" gutter={ROW_GUTTER}>
                <Col xs={16}>
                    <Row align="middle" gutter={ROW_GUTTER}>
                        {showProfilePosition && (
                            <Col>
                                <div className={styles.marker} />

                                {LOCAL.LABELS.POSITION_PROFILE_SHORT}
                            </Col>
                        )}

                        {showAssessments && legendForAssessments}
                    </Row>
                </Col>
            </Row>
        )
    }
)
