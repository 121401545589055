import { createConfirmPopup } from 'utils'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

interface UseConfirmRedirectProps {
    to: string
    needConfirm: boolean
    confirmText: string
}

/**
 * Хук выводит окно подтверждения перед редиректом
 * @param to путь редиректа
 * @param needConfirm необходимость подтверждения
 * @param confirmText текст подтверждения
 */
export const useConfirmRedirect = ({
    to,
    needConfirm,
    confirmText,
}: UseConfirmRedirectProps) => {
    const history = useHistory()

    const handleRedirect = useCallback(() => {
        const redirect = () => {
            history.push(to)
        }

        if (needConfirm) {
            createConfirmPopup({
                content: confirmText,
                onOk: redirect,
            })
        } else {
            redirect()
        }
    }, [needConfirm, confirmText, to, history])

    return { handleRedirect }
}
