import React, { FC, useCallback } from 'react'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import { DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS } from 'consts'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CREATION_DATE,
} from 'components/layouts'
import {
    DocumentsPageEntitiesCountContract,
    IRequestOptions,
    TextDocumentContract,
    TextDocumentSearchContract,
    TextDocumentsService,
    UserPermission,
} from 'core/api'
import { LOCAL } from 'core/local'
import { PageTabs } from 'components/shared'
import { getPageTitleFromRoute } from 'utils'
import { useLocation } from 'react-router-dom'
import { useTabsTotalCount } from 'hooks'

import {
    DOCUMENTS_EDITOR_TABLE_COLUMNS,
    getTabsData,
} from './DocumentsEditor.config'
import { DocumentEditorCreateForm, DocumentsEditorFilters } from './components'

/** Редактор документов: реестр */
export const DocumentsEditor: FC = React.memo(() => {
    const location = useLocation()
    const title = getPageTitleFromRoute(location.pathname)

    const { tabsTotalCount } = useTabsTotalCount<
        IRequestOptions,
        DocumentsPageEntitiesCountContract
    >({
        tabsTotalCountMethod: TextDocumentsService.getDocumentPageEntitiesCount,
    })

    /** Колбэк рендера всех табов на странице */
    const renderTabs = useCallback(
        (recordsCount?: number) => (
            <PageTabs
                tabsData={getTabsData({
                    recordsCount,
                    tabsTotalCount,
                    activeTab: 'documents',
                })}
                defaultTabKey="documents"
            />
        ),
        [tabsTotalCount]
    )

    return (
        <DictionaryPage<TextDocumentSearchContract, TextDocumentContract>
            dictionaryKey={DICTIONARY_KEYS.DOCUMENT_EDITOR}
            searchMethod={TextDocumentsService.search}
            deleteMethod={TextDocumentsService.delete}
            contentTitle={title}
            tableColumns={DOCUMENTS_EDITOR_TABLE_COLUMNS}
            createRecordModal={{
                component: DocumentEditorCreateForm,
                formId: FORM_IDS.DOCUMENT_CREATE_FORM,
                modalOptions: {
                    title: LOCAL.LABELS.TASK_PREPARATION,
                    footer: null,
                },
            }}
            createPermissions={[
                ...DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS,
                UserPermission.TextDocumentSave,
            ]}
            filterComponent={DocumentsEditorFilters}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES_WITH_CREATION_DATE,
                },
            }}
            quickSearchMethod={TextDocumentsService.searchText}
            pageTabsRenderCustom={renderTabs}
        />
    )
})
