import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { BackTop, PageContent } from 'components/shared'
import {
    CatalogItemContract,
    CatalogItemProductCardContract,
    CatalogService,
    RecommendationsService,
} from 'core/api'
import { getUniqueObjectsArray } from 'utils'
import { useHttp } from 'hooks'
import { withLoader } from 'HOCs'

import {
    CatalogSearch,
    FavoriteItems,
    RecommendedItems,
    ThematicBlock,
} from './components'
import {
    getCatalogFavouritesItems,
    getUniqueCardsFromCatalog,
    updateCardFavouritesFlag,
    updateCatalogItemsFavourites,
} from './MainPage.utils'

/** Главная страница пользователя */
export const MainPage: FC = withLoader(
    React.memo(({ updateLoader }) => {
        const [getCatalog, catalog, , updateCatalog] = useHttp(
            CatalogService.getCatalogItemsAsync,
            updateLoader
        )
        const [getRecommended, recommended, , updateRecommended] = useHttp(
            RecommendationsService.getAsync,
            updateLoader
        )
        const [visibleSearchResults, setVisibleSearchResults] = useState(false)
        const [foundCatalogItems, setFoundCatalogItems] = useState<
            CatalogItemProductCardContract[]
        >()

        /**
         * Изменить единицу каталога для определнной темы
         * @param themeId - id темы в которой будут изменена единица каталога
         * @param catalogItem - измененная единица, которая заменит старую в состоянии
         */
        const handleChangeCertainCatalogItem = useCallback(
            (themeId: number, catalogItem: CatalogItemContract) => {
                if (!Array.isArray(catalog)) return [catalogItem]

                const changedCatalog = catalog.reduce<CatalogItemContract[]>(
                    (acc, item) => {
                        if (item.themeId !== themeId) return [...acc, item]

                        return [...acc, catalogItem]
                    },
                    []
                )

                updateCatalog(changedCatalog)
            },
            [catalog, updateCatalog]
        )

        /**
         * Карточки продуктов из раздела "Избранное"
         */
        const favorite = useMemo(() => {
            const resultArray = getCatalogFavouritesItems(catalog)

            return getUniqueObjectsArray(resultArray)
        }, [catalog])

        const fetchPage = useCallback(() => {
            getCatalog()
            getRecommended()
        }, [getCatalog, getRecommended])

        const onUpdateFavourites = useCallback(
            (id: number) => {
                if (catalog) {
                    updateCatalog(updateCatalogItemsFavourites(catalog, id))
                }

                if (recommended) {
                    updateRecommended(
                        recommended.map((card) =>
                            updateCardFavouritesFlag(card, id)
                        )
                    )
                }

                if (foundCatalogItems) {
                    setFoundCatalogItems(
                        foundCatalogItems.map((card) =>
                            updateCardFavouritesFlag(card, id)
                        )
                    )
                }
            },
            [
                catalog,
                recommended,
                updateCatalog,
                updateRecommended,
                foundCatalogItems,
            ]
        )

        /**
         * Тематические разделы
         */
        const thematicSections = useMemo(
            () =>
                catalog?.map((el) => (
                    <ThematicBlock
                        onUpdateFavourites={onUpdateFavourites}
                        key={el.themeId}
                        onChangeCatalogItem={handleChangeCertainCatalogItem}
                        {...el}
                    />
                )),
            [catalog, onUpdateFavourites, handleChangeCertainCatalogItem]
        )

        /**
         * Запрашиваем данные о карточках продуктов на главной странице
         */
        useEffect(() => {
            fetchPage()
        }, [fetchPage])

        return (
            <PageContent>
                <BackTop bottom={100} />

                {!!recommended?.length && (
                    <RecommendedItems
                        onUpdateFavourites={onUpdateFavourites}
                        recommended={recommended}
                    />
                )}

                {!!favorite?.length && (
                    <FavoriteItems
                        onUpdateFavourites={onUpdateFavourites}
                        favorites={favorite}
                    />
                )}

                <CatalogSearch
                    setVisibleSearchResults={setVisibleSearchResults}
                    visibleSearchResults={visibleSearchResults}
                    onUpdateFavourites={onUpdateFavourites}
                    foundCatalogItems={foundCatalogItems}
                    setFoundCatalogItems={setFoundCatalogItems}
                    catalogProductCards={getUniqueCardsFromCatalog(catalog)}
                />

                {!visibleSearchResults && thematicSections}
            </PageContent>
        )
    })
)
