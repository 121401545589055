import React, { Fragment, useCallback, useMemo } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import styles from './FullScreenBlocks.module.scss'
import {
    BlocksProps,
    ThematicBlockStatuses,
} from '../EventFormVisualization/EventFormVisualization.types'
import {
    getArrayForRenderThematicBlock,
    getConnectionLineBackgroundStyles,
} from '../EventFormVisualization/EventFormVisualization.utils'

/**
 * Отображение для тематических блоков мероприятия типа - "fullscreen"
 */
export const FullScreenBlocks: React.FC<BlocksProps> = React.memo((props) => {
    const {
        thematicBlockColorWhilePassing,
        thematicBlockColorAfterPassing,
        thematicBlockColorBeforePassing,
        thematicBlockIconColor,
        thematicBlocksCount,
    } = props

    const getConnectionLineStyles = useCallback(
        (type: ThematicBlockStatuses, currentElement?: number) =>
            getConnectionLineBackgroundStyles({
                ...props,
                type,
                currentElement,
            }),
        [props]
    )

    const blocksCompleteIcons = useMemo(
        () =>
            getArrayForRenderThematicBlock(thematicBlocksCount, true).map(
                (el) => (
                    <Fragment key={el}>
                        <div
                            style={{
                                background: thematicBlockColorAfterPassing,
                            }}
                            className={styles.completedStage}
                        >
                            <IconsAdapter
                                iconType="CheckOutlined"
                                style={{
                                    color: thematicBlockIconColor,
                                }}
                            />
                        </div>

                        <div
                            className={styles.line}
                            style={getConnectionLineStyles(
                                ThematicBlockStatuses.AfterPassing,
                                el
                            )}
                        />
                    </Fragment>
                )
            ),
        [
            thematicBlocksCount,
            thematicBlockColorAfterPassing,
            thematicBlockIconColor,
            getConnectionLineStyles,
        ]
    )

    /**
     * Иконки недоступных тематических блоков
     */
    const blocksLockIcons = useMemo(
        () =>
            getArrayForRenderThematicBlock(thematicBlocksCount).map((el) => (
                <Fragment key={el}>
                    <div
                        style={{
                            background: thematicBlockColorBeforePassing,
                        }}
                        className={styles.blockedStage}
                    >
                        <IconsAdapter
                            iconType="LockOutlined"
                            style={{ color: thematicBlockIconColor }}
                        />
                    </div>

                    <div
                        className={styles.line}
                        style={getConnectionLineStyles(
                            ThematicBlockStatuses.BeforePassing
                        )}
                    />
                </Fragment>
            )),
        [
            thematicBlocksCount,
            thematicBlockColorBeforePassing,
            thematicBlockIconColor,
            getConnectionLineStyles,
        ]
    )

    return (
        <div className={styles.wrapper}>
            {blocksCompleteIcons}

            <div
                className={styles.currentStage}
                style={{ background: thematicBlockColorWhilePassing }}
            >
                <div
                    style={{
                        background: thematicBlockIconColor,
                    }}
                />
            </div>

            <div
                className={styles.line}
                style={getConnectionLineStyles(ThematicBlockStatuses.Current)}
            />

            {blocksLockIcons}
        </div>
    )
})
