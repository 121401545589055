import React, { FC, useCallback, useState } from 'react'
import {
    ContentTitle,
    PageBanner,
    PageContent,
    PageTabs,
} from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Tabs } from 'antd'
import { getRouteMeta } from 'routing/routeNames.utils'
import { pageTabsRender } from 'components/shared/PageTabs/PageTabs.utils'

import {
    AssessmentAssignedExpert,
    AssessmentAssignedGroupExpert,
} from './components'
import { TABS_PANE_OPTIONS } from './AssessmentAssigned.consts'

export const AssessmentAssigned: FC = React.memo(() => {
    const { TabPane } = Tabs
    const [recordsCount, setRecordsCount] = useState(0)

    const handleUpdateRecordsCount = useCallback((count: number) => {
        setRecordsCount(count)
    }, [])

    return (
        <PageContent filled>
            <PageBanner>
                <PageTabs tabsData={pageTabsRender(recordsCount)} />
            </PageBanner>

            <ContentTitle
                title={getRouteMeta(ROUTE_NAMES.ASSESSMENT_ASSIGNED)}
            />

            <Tabs
                defaultActiveKey={TABS_PANE_OPTIONS.EXPERT.key}
                size="large"
                type="card"
                destroyInactiveTabPane
            >
                <TabPane {...TABS_PANE_OPTIONS.EXPERT}>
                    <AssessmentAssignedExpert
                        onUpdateRecordsCount={handleUpdateRecordsCount}
                    />
                </TabPane>

                <TabPane {...TABS_PANE_OPTIONS.GROUP_EXPERT}>
                    <AssessmentAssignedGroupExpert
                        onUpdateRecordsCount={handleUpdateRecordsCount}
                    />
                </TabPane>
            </Tabs>
        </PageContent>
    )
})
