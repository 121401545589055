import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import { FnActionRequiredProps } from 'App.types'
import { Spin } from 'antd'

import styles from './withLoader.module.scss'

export interface WithLoaderProps {
    updateLoader: FnActionRequiredProps<boolean>
    isLoading?: boolean
}

interface AdditionOptionsProps {
    className?: string
    fullWidthHeight?: boolean
    onTopOfEverything?: boolean
}

export function withLoader<T = {}>(
    Component: React.FC<T & WithLoaderProps>,
    {
        fullWidthHeight,
        onTopOfEverything = true,
        className,
    }: AdditionOptionsProps = {}
) {
    return function (props: T) {
        const [isLoading, setLoading] = useState(false)

        const updateLoader = useCallback((loading: boolean) => {
            setLoading(loading)
        }, [])

        return (
            <Spin
                spinning={isLoading}
                wrapperClassName={cn(
                    fullWidthHeight && styles.wrapperFullWidthHeight,
                    onTopOfEverything && styles.wrapperOnTopOfEverything,
                    className
                )}
            >
                <Component
                    {...props}
                    updateLoader={updateLoader}
                    isLoading={isLoading}
                />
            </Spin>
        )
    }
}
