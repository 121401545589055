import React from 'react'
import {
    AddUsersTableControl,
    CheckboxControl,
    FormItemAdapter,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { UserFormContract } from 'components/controls/AddUsersTableControl/AddUsersTableControl.types'
import { isAvailableToWatch } from 'utils'

import {
    AddRespondentsTableControl,
    getRenderColumnFn,
} from '../AddRespondentsTableControl'
import { AssessmentCircleSettingsProps } from './AssessmentCircleSettings.types'
import { RespondentFormContract } from '../AddRespondentsTableControl/AddRespondentsTableControl.types'
import {
    isDisabledPersons,
    renderTableActions,
} from './AssessmentCircleSettings.utils'
import { structuralUnitsIdsFieldNamePath } from '../AssessmentAssignmentForm/AssessmentAssignmentForm.consts'

/**
 * Опции назначения оценки 360
 */
export const AssessmentCircleSettings: React.FC<AssessmentCircleSettingsProps> = React.memo(
    ({
        formMode,
        initialValuesForEdit,
        assessmentRoles,
        onRespondentSelect,
        disabled,
        formHasManagerCompetencies,
    }) => (
        <ShouldUpdateChecker fieldPath={structuralUnitsIdsFieldNamePath}>
            {({ getFieldValue }) => {
                const commonTableFilterOptions = {
                    initialValues: {
                        structuralUnitsIds: getFieldValue(
                            structuralUnitsIdsFieldNamePath
                        ),
                    },
                }

                return (
                    <>
                        <FormItemAdapter
                            noStyle
                            fieldName={[
                                'assessment',
                                'circleParameters',
                                'candidateId',
                            ]}
                        >
                            <AddUsersTableControl
                                formMode={formMode}
                                disabled={
                                    !!initialValuesForEdit?.id &&
                                    isAvailableToWatch(
                                        initialValuesForEdit?.status
                                    )
                                }
                                title={LOCAL.LABELS.ASSESSMENT_OBJECT}
                                modalTitle={LOCAL.LABELS.USERS}
                                rowSelectionType="radio"
                                required
                                tableFiltersOptions={commonTableFilterOptions}
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            noStyle
                            fieldName={[
                                'assessment',
                                'circleParameters',
                                'respondents',
                            ]}
                        >
                            <AddRespondentsTableControl
                                renderTableColumns={getRenderColumnFn({
                                    assessmentRoles,
                                    onRespondentSelect,
                                    formHasManagerCompetencies,
                                    formMode,
                                    commonTableFilterOptions,
                                })}
                                tableOptions={{
                                    customTableActions: renderTableActions<
                                        RespondentFormContract
                                    >(!!initialValuesForEdit?.id),
                                }}
                                formMode={formMode}
                                disabled={isDisabledPersons(
                                    initialValuesForEdit?.status
                                )}
                                title={LOCAL.LABELS.RESPONDENTS}
                                modalTitle={LOCAL.LABELS.USERS}
                                required
                                tableFiltersOptions={commonTableFilterOptions}
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName={[
                                'assessment',
                                'circleParameters',
                                'depersonalizeRespondents',
                            ]}
                        >
                            <CheckboxControl
                                formMode={formMode}
                                disabled={disabled}
                            >
                                {LOCAL.LABELS.RESPONDENTS_DEPERSONALIZE}
                            </CheckboxControl>
                        </FormItemAdapter>

                        <FormItemAdapter
                            noStyle
                            fieldName={[
                                'assessment',
                                'circleParameters',
                                'checkingPersons',
                            ]}
                        >
                            <AddUsersTableControl
                                formMode={formMode}
                                disabled={disabled}
                                title={LOCAL.LABELS.REVIEWERS}
                                modalTitle={LOCAL.LABELS.USERS}
                                tableOptions={{
                                    customTableActions: renderTableActions<
                                        UserFormContract
                                    >(!!initialValuesForEdit?.id),
                                }}
                            />
                        </FormItemAdapter>
                    </>
                )
            }}
        </ShouldUpdateChecker>
    )
)
