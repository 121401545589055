import isNil from 'lodash/isNil'
import { LOCAL } from 'core/local'
import { PositionProfilePublishContract, PositionProfileType } from 'core/api'
import {
    getIdsArray,
    getUniqueItemsArray,
    normalizeDataForSelectAndRadio,
} from 'utils'

import {
    GetInitialValuesProps,
    JobProfileFormSubmitValuesProps,
} from './JobProfileForm.types'

export const mapFormDataToRequest = (
    values: JobProfileFormSubmitValuesProps,
    id?: number
) => {
    const {
        structuralUnitId,
        organization,
        oivId,
        supervisingComplexId,
        competencies,
        chiefId,
    } = values

    return {
        ...values,
        id,
        structuralUnitId:
            structuralUnitId || organization || oivId || supervisingComplexId,
        competencies: competencies?.filter(
            (el) => el.isAdjacent || !isNil(el.competenceLevel)
        ),
        chiefId: chiefId?.[0].value as number,
    }
}

/** Маппинг начальных значений в данные формы */
export const mapInitialValuesToFormValues = (
    initialValuesForAdd?: PositionProfilePublishContract,
    defaultCompetenceGroupsIds?: number[]
) => ({
    ...initialValuesForAdd,
    type: PositionProfileType.GGS,
    competenceGroupsIds: defaultCompetenceGroupsIds || [],
})

/** Маппинг данных с бэка при редактировании */
export const mapResponseToFormData = ({
    initialValuesForEdit,
    defaultCompetenceGroupsIds,
    isCopy,
}: GetInitialValuesProps) => {
    if (initialValuesForEdit?.id) {
        const {
            name,
            accessForm,
            corruptionRisk,
            dismissalRisk,
            competencies,
            competenceGroups,
            educationLevels,
            educationProfiles,
            governmentExperience,
            level,
            manageExperience,
            manageExperienceIndustry,
            manageExperienceSubordinatesNumber,
            performanceIndicatorsDescription,
            position,
            requiredExperience,
            requiredExperienceIndustries,
            subordination,
            subordinationOther,
            structuralUnit,
            functionClasses,
            organization,
            oiv,
            supervisingComplex,
            staffUnit,
            chief,
        } = initialValuesForEdit

        return {
            ...initialValuesForEdit,
            name: isCopy ? `${name} ${LOCAL.LABELS.COPY}` : name,
            accessFormId: accessForm?.id,
            corruptionRiskId: corruptionRisk?.id,
            dismissalRiskId: dismissalRisk?.id,
            competencies: competencies?.map((el) => ({
                id: el.id,
                competenceLevel: el.competenceLevel,
                isAdjacent: el.isAdjacent,
            })),
            competenceGroupsIds: getUniqueItemsArray([
                ...competenceGroups?.map((el) => el.id),
                ...(defaultCompetenceGroupsIds || []),
            ]),
            educationLevelIds: getIdsArray(educationLevels),
            educationProfileIds: getIdsArray(educationProfiles),
            governmentExperienceId: governmentExperience?.id,
            levelId: level?.id,
            manageExperienceId: manageExperience?.id,
            manageExperienceIndustryId: manageExperienceIndustry?.id,
            manageExperienceSubordinatesNumberId:
                manageExperienceSubordinatesNumber?.id,
            positionId: position?.id,
            requiredExperienceId: requiredExperience?.id,
            requiredExperienceIndustryIds: getIdsArray(
                requiredExperienceIndustries
            ),
            subordinationId: subordination?.id,
            subordinationOtherId: subordinationOther?.id,
            structuralUnitId: structuralUnit?.id,
            functionClassesIds: getIdsArray(functionClasses),
            organization: organization?.id,
            oivId: oiv?.id,
            supervisingComplexId: supervisingComplex?.id,
            staffUnitId: staffUnit?.id,
            chiefId: chief && [normalizeDataForSelectAndRadio(chief)],
            showPerfomanceIndicators: !!performanceIndicatorsDescription?.length,
        }
    }
}
