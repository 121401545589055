import React, { FC, useCallback } from 'react'
import { ButtonsToolbar, TableActions } from 'components/shared'
import { LOCAL } from 'core/local'
import { NotificationMailingInformationsService } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    createConfirmPopup,
    isNotificationMailingInfoTypeExpectation,
} from 'utils'

import { DistributionMailingTableActionsProps } from './MailingAssignmentTableActions.types'

export const DistributionMailingTableActions: FC<DistributionMailingTableActionsProps> = React.memo(
    ({ tableRow, reFetchDictionary }) => {
        /**
         * Функция для отмены рассылки
         */
        const cancelMailing = useCallback(async () => {
            try {
                const { id } = tableRow
                await NotificationMailingInformationsService.cancel({ id })
                await reFetchDictionary?.()
            } catch (error) {
                console.error(error)
            }
        }, [reFetchDictionary, tableRow])

        /**
         * Функция обработки нажатия по кнопке отмены рассылки
         */
        const handleClickStop = useCallback(() => {
            createConfirmPopup({
                title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                content: LOCAL.MESSAGES.CANCEL_MAILING,
                okText: LOCAL.ACTIONS.CONFIRM,
                onOk: cancelMailing,
            })
        }, [cancelMailing])

        return (
            <ButtonsToolbar bottomIndent="0" align="flex-end">
                <TableActions.View
                    href={`${ROUTE_NAMES.DISTRIBUTION_MAILING_VIEW}/${tableRow.id}`}
                    disabled={!tableRow.active}
                />

                {isNotificationMailingInfoTypeExpectation(tableRow.status) && (
                    <TableActions.Stop onClick={handleClickStop} />
                )}
            </ButtonsToolbar>
        )
    }
)
