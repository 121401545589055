import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { DataNode } from 'rc-tree-select/lib/interface'
import { FilterForm } from 'components/shared/FilterForm'
import {
    FiltersProps,
    getPopupContainer,
    normalizeDataForTreeSelect,
} from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { MultiSelectControl } from 'components/controls/MultiSelectControl'
import {
    PositionsService,
    StructuralUnitsService,
    UserModalSearchContract,
} from 'core/api'
import { ROW_GUTTER } from 'consts'
import { TreeSelectControl } from 'components/controls/TreeSelectControl'

interface DictionariesProps {
    positions: DataNode[]
    structuralUnits: DataNode[]
}

/**
 * Форма фильтрации сотрудников
 */
export const CoworkersFilters: React.FC<FiltersProps<
    UserModalSearchContract
>> = (props) => {
    const [dictionaries, setDictionaries] = useState<DictionariesProps>()

    const dictionaryFetch = useCallback(async () => {
        try {
            const positionsData = await PositionsService.getAll()
            const structuralUnitsData = await StructuralUnitsService.getAllStructuralUnits()

            setDictionaries({
                positions: positionsData.map(normalizeDataForTreeSelect),
                structuralUnits: structuralUnitsData.map(
                    normalizeDataForTreeSelect
                ),
            })
        } catch (error) {
            console.error(error)
        }
    }, [])

    useEffect(() => {
        dictionaryFetch()
    }, [dictionaryFetch])

    return (
        <FilterForm {...props}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="fullName"
                        label={LOCAL.LABELS.FIO}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="positionsIds"
                        label={LOCAL.LABELS.POSITION}
                    >
                        <MultiSelectControl
                            treeData={dictionaries?.positions || []}
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={24}>
                    <FormItemAdapter
                        fieldName="structuralUnitsIds"
                        label={LOCAL.LABELS.ORGANIZATION}
                    >
                        <TreeSelectControl
                            disabled={
                                props.tableFiltersOptions?.disabledFields
                                    ?.structuralUnitsIds
                            }
                            treeData={dictionaries?.structuralUnits || []}
                            multiple
                            placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            getPopupContainer={getPopupContainer}
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    )
}
