import cn from 'classnames'
import React, { useCallback, useContext, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, ContentTitle } from 'components/shared'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { eventEmitter } from 'core/helpers/eventEmitter'
import {
    isHomeworkWaitingForSolution,
    isThematicBlockStatusCompleted,
    isThemeBlockTypeHomework,
    isTrainingMaterialFormatMedia,
} from 'utils'

import styles from './LessonLayerHeader.module.scss'
import { LessonLayerChildProps } from '../LessonLayer/LessonLayer.types'
import { TRACK_PASSING_EVENTS } from '../../TrackPassing.events'
import { TrackPassingContext } from '../../TrackPassing.context'
import { getHomeworkStatus } from './LessonLayerHeader.utils'

export const LessonLayerHeader: React.FC<LessonLayerChildProps> = React.memo(
    ({ lessonInfo, isChildLesson }) => {
        const { store } = useContext(TrackPassingContext)

        const { stageInfo } = store

        const isThematicBlockCompleted = isThematicBlockStatusCompleted(
            lessonInfo?.status
        )

        const isHomeworkType = isThemeBlockTypeHomework(lessonInfo?.type)

        const closeLesson = useCallback(() => {
            eventEmitter.emit(TRACK_PASSING_EVENTS.CLOSE_LESSON, isChildLesson)
        }, [isChildLesson])

        const finishLesson = useCallback(() => {
            eventEmitter.emit(TRACK_PASSING_EVENTS.FINISH_LESSON, isChildLesson)
        }, [isChildLesson])

        const sendToCheckHomework = useCallback(() => {
            eventEmitter.emit(
                TRACK_PASSING_EVENTS.SEND_TO_CHECK_HOMEWORK,
                isChildLesson
            )
        }, [isChildLesson])

        const submitButtonProps = useMemo(
            () =>
                isHomeworkType
                    ? {
                          onClick: sendToCheckHomework,
                          disabled: !isHomeworkWaitingForSolution(
                              lessonInfo?.homework?.status
                          ),
                      }
                    : {
                          onClick: finishLesson,
                      },
            [finishLesson, isHomeworkType, lessonInfo, sendToCheckHomework]
        )

        return (
            <div className={cn(styles.wrapper)}>
                {!isTrainingMaterialFormatMedia(lessonInfo?.course?.format) && (
                    <ContentTitle
                        title={stageInfo?.activity?.activityPassing.name}
                    />
                )}

                <Row
                    justify="space-between"
                    align="middle"
                    className={styles.actions}
                >
                    <Col className={styles.name}>{lessonInfo?.name}</Col>

                    <Col>
                        <ButtonsToolbar className={styles.buttonsToolbar}>
                            {isHomeworkType && getHomeworkStatus(lessonInfo)}

                            <Button type="primary" onClick={closeLesson}>
                                {LOCAL.ACTIONS.RETURN}
                            </Button>

                            {!isThematicBlockCompleted && (
                                <Button type="primary" {...submitButtonProps}>
                                    {isHomeworkType
                                        ? LOCAL.LABELS.ON_CHECK
                                        : LOCAL.LABELS.FURTHER}
                                </Button>
                            )}
                        </ButtonsToolbar>
                    </Col>
                </Row>
            </div>
        )
    }
)
