import React from 'react'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { Modal } from 'antd'
import {
    PassingFormContract,
    PassingFormQuestionAnswerContract,
    PassingFormQuestionAnswerMissingTextBlockContract,
    PassingFormsService,
} from 'core/api'
import { isArrayEmpty, isEmptyObject } from 'utils'
import { withBlockUserRouting } from 'HOCs'

import {
    AnswerFormValuesContract,
    ConfirmRepassingProps,
    GetLeavePassingConfirmationProps,
} from './QuestionnairePassing.types'
import {
    getMessageForRepassingConfirm,
    renderConfirmTitle,
} from './QuestionnairePassing.render'

export const removeEmptyAnswers = (unsavedAnswers: any) => {
    const answers = unsavedAnswers

    Object.keys(answers).forEach((key) => {
        if (
            !answers[key] ||
            (typeof answers[key] === 'object' && isEmptyObject(answers[key]))
        ) {
            if (
                answers.chooseAnswerParameters &&
                !answers.chooseAnswerParameters.selectedAnswerIds
            ) {
                delete answers.chooseAnswerParameters
            }

            if (
                answers.missingTextAnswerParameters &&
                !answers.missingTextAnswerParameters.missingTextBlocks
            ) {
                delete answers.missingTextAnswerParameters
            }

            delete answers[key]
        } else if (typeof answers[key] === 'object') {
            removeEmptyAnswers(answers[key])
        }
    })

    return answers
}

export const mapRequestToFormData = (
    unsavedAnswers: AnswerFormValuesContract[],
    id: number
) => {
    const body = unsavedAnswers.reduce<AnswerFormValuesContract[]>(
        (acc, el) => {
            const textBlock = el.missingTextAnswerParameters?.missingTextBlocks

            if (textBlock) {
                const textBlockValues = textBlock.filter((el) => el?.text)

                if (isArrayEmpty(textBlockValues)) return acc

                return [
                    ...acc,
                    {
                        ...el,
                        missingTextAnswerParameters: {
                            ...el.missingTextAnswerParameters,
                            missingTextBlocks: textBlockValues,
                        },
                    },
                ]
            }

            return [...acc, el]
        },
        []
    )

    return {
        id,
        body,
    }
}

export const mapResponseToFormData = (
    response: PassingFormQuestionAnswerContract[]
) =>
    response.reduce((acc, val) => {
        if (isArrayEmpty(val.missingTextAnswerParameters?.missingTextBlocks))
            return {
                ...acc,
                [val.questionId]: { ...val, unsaved: false },
            }

        return {
            ...acc,
            [val.questionId]: {
                ...val,
                unsaved: false,
                missingTextAnswerParameters: {
                    ...val.missingTextAnswerParameters,
                    missingTextBlocks: val.missingTextAnswerParameters.missingTextBlocks.reduce<
                        PassingFormQuestionAnswerMissingTextBlockContract[]
                    >((acc, el) => {
                        acc[el.blockNumber] = el

                        return acc
                    }, []),
                },
            },
        }
    }, {})

export const getLeavePassingConfirmation = ({
    data,
    isRoutingBlocked,
}: GetLeavePassingConfirmationProps) => {
    const savePassingProgress = () => {
        if (!data.remainingTime) return

        PassingFormsService.abort({
            id: data.passingId,
            body: {
                timeLeft: data.remainingTime,
            },
        })
    }

    return withBlockUserRouting(
        () => <></>,
        isRoutingBlocked,
        isRoutingBlocked ? savePassingProgress : undefined
    )({})
}

const isCheckAnswer = (answer?: AnswerFormValuesContract) =>
    answer?.textAnswerParameters?.text ||
    answer?.chooseAnswerParameters?.selectedAnswerIds?.length ||
    answer?.chooseAnswerParameters?.text ||
    answer?.chooseAnswerParameters?.comment ||
    answer?.missingTextAnswerParameters?.missingTextBlocks?.filter(
        (el: { text?: string }) => el?.text
    )?.length ||
    answer?.scaleAnswerParameters?.scaleValueId ||
    answer?.scaleAnswerParameters?.comment

export const getCheckAnswers = (answers: AnswerFormValuesContract) =>
    Object.entries(answers).reduce(
        (acc, [key, value]) =>
            isCheckAnswer(value) ? { ...acc, [key]: value } : acc,
        {}
    )

/**
 * Получить количество оставшихся попыток прохождения
 * @param questionnaireData - данные о прохождении
 */
export const getRemainingAttemptsCount = ({
    attemptsLimit,
    attemptsLimited,
    finishedPassingCount,
}: PassingFormContract) =>
    attemptsLimited ? attemptsLimit - finishedPassingCount : Infinity

/** Подтверждение прохождения с учетом количества попыток и перепрохождения */
export const confirmRepassing = ({
    remainingAttempts,
    questionnaire,
    setAlreadyRestarted,
    completePass,
    onRemainingTime,
    timeIsUp,
}: ConfirmRepassingProps) => {
    const isRemainingAttemptsMoreThanOne = remainingAttempts > 1

    Modal.confirm({
        okText: LOCAL.ACTIONS.COMPLETE,
        cancelText: isRemainingAttemptsMoreThanOne
            ? LOCAL.ACTIONS.REPASS_ONCE_MORE
            : LOCAL.NO,
        title: renderConfirmTitle({
            timeLeft: questionnaire.timeLeft,
            timeLimit: questionnaire.timeLimit,
            startedTime: questionnaire.startTime,
            timeIsUp,
            type: questionnaire.appointment.type,
        }),
        content: getMessageForRepassingConfirm(
            remainingAttempts - 1,
            questionnaire.appointment.type,
            questionnaire.overallTestScore
        ),
        cancelButtonProps: {
            hidden:
                (!isRemainingAttemptsMoreThanOne && timeIsUp) ||
                !isRemainingAttemptsMoreThanOne,
            type: 'link',
        },
        onOk: () => {
            Modal.destroyAll()
            completePass()
        },
        onCancel: () => {
            Modal.destroyAll()

            if (isRemainingAttemptsMoreThanOne) {
                const body = {
                    appointmentId: questionnaire.appointment.id,
                    candidateId: questionnaire.candidate?.id,
                }

                onRemainingTime?.(0)

                setAlreadyRestarted?.(true)

                PassingFormsService.retry({
                    body,
                })
                    .then(() => {
                        window.location.reload()
                    })
                    .catch((err) => console.error(err))
            }
        },
        icon: null,
        width: MODAL_WIDTH.LG,
    })
}
