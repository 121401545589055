import React from 'react'
import { DictionaryContract } from 'core/api'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { Store } from 'App.types'

import { GetCarouselSettingsProps } from './ProductCardCarousel.types'

/**
 * Получить настройки для компонента карусели
 * @param sizes количество слайдов к разрешению экрана
 */
export const getCarouselSettings = ({
    slidesToShow,
}: GetCarouselSettingsProps) => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow.xxl,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: slidesToShow.xl || 3,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: slidesToShow.xs || 1,
            },
        },
    ],
})

/** Рендер блока с иконкой */
export const renderBlockWithCheckIcon = (styles: Store<string>) => (
    entity: DictionaryContract
) => (
    <div key={entity.id}>
        <div className={styles.item}>
            <IconsAdapter iconType="CheckOutlined" className={styles.icon} />

            <span className={styles.text}>{entity.name}</span>
        </div>
    </div>
)
