import { ASSESSMENT_ROLES } from 'consts'
import { AssessmentType } from 'core/api'
import { LOCAL } from 'core/local'
import {
    getFullFio,
    isAssessmentTypeEstimate360,
    isAssessmentTypeManager,
} from 'utils'

import {
    GetHeaderNameProps,
    GetHeaderSuffixProps,
} from './UserAssignmentPassingHeader.types'

/**
 * Получить нужное имя для хедера
 * @param userData
 * @param candidate
 * @param assessmentType
 */
export const getHeaderName = ({
    userData,
    candidate,
    assessmentType,
}: GetHeaderNameProps) =>
    getFullFio(
        isAssessmentTypeEstimate360(assessmentType) ||
            isAssessmentTypeManager(assessmentType)
            ? candidate
            : userData
    )

/**
 * Получить суффикс хедера прохождения оценки
 * @param assessmentType
 * @param respondentRole
 * @param candidate
 * @param userData
 * @param presentation
 */
export const getHeaderSuffix = ({
    assessmentType,
    respondentRole,
    candidate,
    userData,
    assessmentPortfolioPresentation: presentation,
}: GetHeaderSuffixProps) => {
    let suffix = ''

    if (
        assessmentType === AssessmentType.Estimate_360 &&
        presentation?.showCandidateRole
    ) {
        if (userData?.id === candidate?.id) {
            suffix = LOCAL.LABELS.ASSESSMENT_SELF
        } else {
            suffix = respondentRole ? ASSESSMENT_ROLES[respondentRole] : ''
        }
    }

    // Если в ходе обработке появился суффикс, то ставим перед ним запятую
    if (suffix) {
        suffix = `, ${suffix}`
    }

    return suffix.toLocaleLowerCase()
}
