import {
    PositionInfoContract,
    StructuralUnitInfoContract,
    StructuralUnitPositionSearchResultItemContract,
} from 'core/api'

import { NormalizeStructuralUnitPositionsResultProps } from './Organizations.types'

/**
 * Нормализация данных для справочника организаций
 * @param structuralUnit - объект структурной единицы (организации)
 */
export const normalizeStructuralUnitPositions = (
    structuralUnit: StructuralUnitPositionSearchResultItemContract,
    positionUnits?: StructuralUnitInfoContract[]
): NormalizeStructuralUnitPositionsResultProps => {
    const normalizePositions = (
        position: PositionInfoContract,
        positionUnits: StructuralUnitInfoContract[]
    ) => ({
        name: position.positionName,
        active: position.active,
        isChanged: position.isChanged,
        changeDate: position.changeDate,
        positionProfile: position.hasPositionProfile,
        linkedProfile: position.hasLinkedPositionProfile,
        id: position.structuralUnitPositionId,
        positionId: position.positionId,
        positionUnits,
        username: position.userFullNames
            .map((user) => user.fullName)
            .join(', '),
    })

    const parentUnits = positionUnits
        ? [...positionUnits, structuralUnit.unit]
        : [structuralUnit.unit]

    const positions = structuralUnit.positions.map((position) =>
        normalizePositions(position, parentUnits)
    )

    const subUnits = structuralUnit.items.map((subUnit) =>
        normalizeStructuralUnitPositions(subUnit, parentUnits)
    )

    const children = [...positions, ...subUnits]

    return {
        name: structuralUnit.unit.name,
        id: structuralUnit.unit.id,
        withPositionProfileCount: structuralUnit.withPositionProfileCount,
        withoutPositionProfileCount: structuralUnit.withoutPositionProfileCount,
        changeDate: structuralUnit.unit.changeDate,
        unitType: structuralUnit.unit.type,
        isChanged: structuralUnit.isChanged,
        isUnitChanged: structuralUnit.unit.isChanged,
        ...(children.length ? { children } : {}),
    }
}

/** Найти список айди родительских элементов, children которых содержит искомый id
 * @param row - нормализованный объект структурной единицы
 * @param id - айди элемента, который необходимо найти/раскрыть
 * @param ids - айдишники родительских элементов во вложенной структуре которых присутствует id
 */
const getIdsToExpand = (
    row: NormalizeStructuralUnitPositionsResultProps,
    id: number,
    ids: number[] = []
) => {
    if (id === row.id) {
        ids.push(id)

        return
    }

    row.children?.some((child) => getIdsToExpand(child, id, ids))

    if (ids.includes(id)) {
        ids.unshift(row.id)

        return ids
    }
}

/**
 * Получить массивы айдишек необходимых для того, чтобы
 * раскрыть вложенную структуры таблицы до искомых элементов
 * @param structuralUnitsIds - список запрашиваемых элементов, до которых следует раскрыть вложенную структуру
 * @param datasource - нормализованные данные
 */
export const getExpandedRowKeys = (
    structuralUnitsIds: number[],
    datasource: NormalizeStructuralUnitPositionsResultProps[]
) =>
    structuralUnitsIds?.map((id) =>
        datasource.map((data) => getIdsToExpand(data, id) || [])
    )
