import React from 'react'
import cn from 'classnames'
import { ActivityPassingStatus } from 'core/api'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { isThemeBlockTypeHomework } from 'utils'

import styles from './ModalSingleBlock.module.scss'
import { CreateModalBlockProps } from './ModalSingleBlock.types'
import { SingleBlockProps } from '../../ActivityLayer.types'
import { getChain } from './ModalSingleBlock.utils'

export const ModalSingleBlock: React.FC<SingleBlockProps> = React.memo(
    ({
        canvas: {
            thematicBlockIconColor,
            thematicBlockColorWhilePassing,
            thematicBlockColorBeforePassing,
            thematicBlockColorAfterPassing,
        },
        blockIndex,
        blocksList,
        activeBlock,
        onSetActiveBlock,
        lessonType,
    }) => {
        const block = blocksList[blockIndex]

        let modalOptions: CreateModalBlockProps

        switch (block.status) {
            case ActivityPassingStatus.CompletedSuccessfully:
                modalOptions = {
                    color: thematicBlockColorAfterPassing,
                    className: styles.completedStage,
                    iconType: 'CheckOutlined',
                    iconColor: thematicBlockIconColor,
                    backgroundColor: thematicBlockColorAfterPassing,
                }
                break

            case ActivityPassingStatus.Waiting:
                modalOptions = {
                    className: styles.currentStage,
                    color: thematicBlockColorBeforePassing,
                }
                break

            case ActivityPassingStatus.InProgress:
                modalOptions = {
                    className: styles.currentStage,
                    color: thematicBlockColorWhilePassing,
                }
                break

            default:
                modalOptions = {
                    className: styles.blockedStage,
                    color: thematicBlockColorBeforePassing,
                    iconType: 'LockOutlined',
                }
                break
        }

        return (
            <div
                style={{
                    borderColor: modalOptions.color,
                    backgroundColor: modalOptions.backgroundColor,
                }}
                className={cn(
                    modalOptions.className,
                    isThemeBlockTypeHomework(lessonType) && styles.squareStage,
                    activeBlock.index === blockIndex && styles.activeBlock
                )}
                onClick={onSetActiveBlock({
                    index: blockIndex,
                    borderColor: modalOptions.color,
                })}
            >
                {getChain(modalOptions.color)}

                <IconsAdapter
                    iconType={modalOptions.iconType}
                    style={{
                        color: modalOptions.iconColor || modalOptions.color,
                    }}
                />
            </div>
        )
    }
)
