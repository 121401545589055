import React, { FC, memo, useCallback, useEffect } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { FnActionAsyncRequiredProps } from 'App.types'
import { Form } from 'antd'
import { HiddenField, TextAreaControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { QuizCheckContract, QuizCheckingService } from 'core/api'
import { createSuccessNotification } from 'utils'
import { useForm } from 'antd/lib/form/Form'
import { useHttp } from 'hooks'
import { withLoader } from 'HOCs'

import styles from './QuizCheckingForm.module.scss'

/**
 * Форма проверки опросов
 */
export const QuizCheckingForm: FC<PopupAdapterFormProps> = withLoader(
    memo(({ initialValues, onCancelSubmit, onRefetch, updateLoader }) => {
        const { id } = initialValues || {}
        const [getQuiz, quizResult] = useHttp<QuizCheckContract>(
            QuizCheckingService.check,
            updateLoader
        )

        const [form] = useForm()

        const actionsForFetch = useCallback(
            async (
                message: string,
                fetchFn: FnActionAsyncRequiredProps<any>,
                body: object
            ) => {
                try {
                    updateLoader(true)

                    await fetchFn(body)

                    onCancelSubmit?.()
                    createSuccessNotification(message)
                    await onRefetch?.()
                } catch (err) {
                    console.error(err)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader, onCancelSubmit, onRefetch]
        )

        /** Подтвердить опрос */
        const handleConfirm = useCallback(() => {
            actionsForFetch(
                LOCAL.MESSAGES.QUIZ_COMPLETE,
                QuizCheckingService.confirm,
                { id }
            )
        }, [actionsForFetch, id])

        /** Сохранить комментарий */
        const handleFinish = useCallback(
            (body) => {
                actionsForFetch(
                    LOCAL.MESSAGES.CHANGES_SAVED,
                    QuizCheckingService.save,
                    { body }
                )
            },
            [actionsForFetch]
        )

        useEffect(() => {
            getQuiz({ id })
        }, [getQuiz, id])

        useEffect(() => {
            form.setFieldsValue({
                comment: quizResult?.comment,
                id,
            })
        }, [form, id, quizResult])

        return (
            <div className={styles.wrapper}>
                <div className={styles.quizzes}>
                    {quizResult?.questions.map((question) => (
                        <div className={styles.quiz} key={question.number}>
                            <div className={styles.question}>
                                <label>
                                    {`${LOCAL.LABELS.QUESTION} ${question.number}`}
                                </label>

                                <p>{question.title}</p>
                            </div>

                            <div className={styles.answer}>
                                <label>{LOCAL.LABELS.USER_ANSWER}</label>

                                <p>{question.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <Form
                    form={form}
                    className={styles.form}
                    layout="vertical"
                    onFinish={handleFinish}
                >
                    <HiddenField fieldName="id" />

                    <Form.Item name="comment" label={LOCAL.LABELS.COMMENT}>
                        <TextAreaControl />
                    </Form.Item>

                    <ButtonsToolbar>
                        <Button type="link" onClick={onCancelSubmit}>
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        <Button type="link" onClick={handleConfirm}>
                            {LOCAL.ACTIONS.CONFIRM}
                        </Button>

                        <Button htmlType="submit">{LOCAL.ACTIONS.SAVE}</Button>
                    </ButtonsToolbar>
                </Form>
            </div>
        )
    })
)
