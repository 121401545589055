import styles from './TableAdapter.module.scss'
import { GetRowClassNameWithoutChild, RecordProps } from './TableAdapter.types'

/** Ширина скролла таблицы */
export const DEFAULT_TABLE_SCROLL_WIDTH = 1760

export const getRowClassNameArchive = <T extends RecordProps>(
    record: T
): string => {
    if (record.active === false) {
        return 'bg-archive'
    }

    return ''
}

export function getRowClassNameExpired<T extends { isOverdue: boolean }>(
    record: T
) {
    if (record.isOverdue) {
        return 'bg-expired'
    }

    return ''
}

export const renderRowCheckbox = <T extends RecordProps>(
    checked: boolean,
    record: T,
    index: number,
    originNode: React.ReactNode
) => {
    if (record.active === false) return null

    return originNode
}

/**
 * Вычислить класс для конкретного `row` таблицы
 * @param record - запись таблицы
 */
export const getRowClassNameWithoutChild = (
    record: GetRowClassNameWithoutChild
): string => {
    if (!record.units?.length) return styles.withoutChildren

    return ''
}

/**
 * Получить значение отступов для дом элемента таблицы
 * @param elem - дом элемент таблицы
 */
export const getCoords = (elem: Element) => {
    const box = elem.getBoundingClientRect()

    return {
        top: box.top + window.pageYOffset,
        left: box.left + window.pageXOffset,
    }
}

/**
 * Вычислить значение для скролла внутри таблицы
 * @param elem - дом элемент таблицы
 */
export const getScrollSize = (elem: Element | null) => {
    if (!elem) return

    const PAGINATION_HEIGHT = 100
    const TABLE_TH_HEIGHT = 55

    const offsetTargetElement = Number(getCoords(elem)?.top)

    return (
        document.body.clientHeight -
        (offsetTargetElement || 0) -
        PAGINATION_HEIGHT -
        TABLE_TH_HEIGHT
    )
}

/**
 * Расчет минимальной высоты для ячеек таблицы, в которых есть кнопка расширения
 */
export function calcExpandRowMinHeight() {
    const expandTds: NodeListOf<HTMLElement> = document.querySelectorAll(
        '.ant-table-cell-with-append'
    )

    expandTds.forEach((el) => {
        const sibling = el.nextSibling as HTMLElement | null

        if (!sibling) return

        el.style.minHeight = sibling
            ? `${sibling.getBoundingClientRect().height}px`
            : 'auto'
    })
}
