import React from 'react'
import {
    APPOINTMENT_STATUS,
    DOCUMENT_STATUSES,
    QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS,
    QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
    QUIZ_TYPES,
} from 'consts'
import {
    AppointmentContract,
    AppointmentSearchResultContract,
    UserPermission,
} from 'core/api'
import {
    AppointmentsPassingServiceProps,
    formatDate,
    isAppointmentStatusDraft,
    isAppointmentStatusExpectation,
    isAppointmentStatusInProgress,
    isAppointmentStatusSuspended,
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'
import { ColumnsType } from 'antd/lib/table'
import { DictionaryPageActionsProps } from 'components/layouts/DictionaryPage/DictionaryPage.types'
import { GetBtnOptionsType } from 'components/layouts/FormContainer/FormContainer.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WidthRestrictedElement } from 'components/shared'
import { defaultSorter } from 'components/layouts/DictionaryPage/DictionaryPage.consts'

import { QuizAssignmentsTableActions } from './components'

/**
 * Получить настройки отображения колонок для назначений опроса
 * @param appointmentProcessService - сервис для контроля процесса прохождения назначения
 * @param actions - вспомогательные функции/экшены, берутся из DictionaryPage
 */
export const getQuizAssignmentsTableColumns = (
    appointmentProcessService: AppointmentsPassingServiceProps,
    { reFetchDictionary, updateLoader }: DictionaryPageActionsProps
): ColumnsType<AppointmentSearchResultContract> => [
    {
        title: LOCAL.LABELS.APPOINTMENT_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizations',
        key: 'organizations',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.QUIZ_TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (value, record) => (
            <WidthRestrictedElement title={QUIZ_TYPES[record.quizType]} />
        ),
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'evaluationPeriodFrom',
        key: 'evaluationPeriodFrom',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'evaluationPeriodTo',
        key: 'evaluationPeriodTo',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
            <WidthRestrictedElement
                title={
                    record.active
                        ? APPOINTMENT_STATUS[record.status]
                        : DOCUMENT_STATUSES.Archive
                }
            />
        ),
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CREATION,
        dataIndex: 'creationDate',
        key: 'creationDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        align: 'right',
        render: (tableRow) => (
            <QuizAssignmentsTableActions
                updateLoader={updateLoader}
                tableRow={tableRow}
                appointmentProcessService={appointmentProcessService}
                reFetchDictionary={reFetchDictionary}
            />
        ),
        fixed: 'right',
    },
]

export const CANCEL_BTN_OPTIONS = {
    visible: true,
    href: ROUTE_NAMES.QUIZ_ASSIGNMENTS,
}

export const getSaveBtnOptions: GetBtnOptionsType<AppointmentContract> = ({
    editValues,
}) => ({
    visible:
        !editValues?.status || isAppointmentStatusDraft(editValues?.status),
})

export const getPublishBtnOptions: GetBtnOptionsType<AppointmentContract> = ({
    editValues,
}) => ({
    visible:
        !editValues?.status ||
        isAppointmentStatusDraft(editValues?.status) ||
        isAppointmentStatusExpectation(editValues?.status) ||
        isAppointmentStatusInProgress(editValues?.status) ||
        isAppointmentStatusSuspended(editValues?.status),
    requiredPermissions: [
        ...QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
        UserPermission.QuizAppointmentPublish,
    ],
    conditionalPermissions: QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS,
    text: LOCAL.ACTIONS.ASSIGN,
})
