import React, { FC, useContext } from 'react'
import { ColorThemeContext } from 'contexts'
import { LOCAL } from 'core/local'

import moon from './moon.svg'
import styles from './ThemeSwitcher.module.scss'
import { THEME_SWITCHER_SIZE } from './ThemeSwitcher.consts'

export const ThemeSwitcher: FC = React.memo(() => {
    const { changeTheme } = useContext(ColorThemeContext)

    return (
        <span onClick={changeTheme} className={styles.wrapper}>
            <img
                src={moon}
                alt={LOCAL.ACTIONS.CHANGE_THEME}
                height={THEME_SWITCHER_SIZE}
            />
        </span>
    )
})
