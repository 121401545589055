import React from 'react'
import isEqual from 'lodash/isEqual'
import { Button } from 'components/shared/Button'
import { FnActionProps, Store, WithFormInstanceProps } from 'App.types'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import {
    PassingFormQuestionAnswerChooseVariantContract,
    PassingFormQuestionAnswerContract,
    PassingFormQuestionAnswerCreateContract,
    QuestionType,
} from 'core/api'
import { TooltipAdapter } from 'components/shared/TooltipAdapter'
import { VideoAttachment } from 'components/shared/VideoAttachment'
import { isArrayEmpty, isQuestionTypeFillEmptyText } from 'utils'
import { isNil } from 'lodash'

import styles from './QuestionnairePassingAnswer.module.scss'
import {
    AnswerComparisonParam,
    FillEmptyTextAnswer,
    GetAnswerFormTypeProps,
    GetQuestionTitleProps,
    GetUnblockedQuestionContentProps,
} from './QuestionnairePassingAnswer.types'
import { ChoosingAnswerPassing } from '../ChoosingAnswerPassing'
import { CriteriaScalePassing } from '../CriteriaScalePassing'
import { PasteTextPassing } from '../PasteTextPassing'
import { REQUIRED_FIELDS } from './QuestionnairePassingAnswer.consts'
import { TextAnswerPassing } from '../TextAnswerPassing'

export const isSubmitDisabled = (
    type?: QuestionType,
    form?: WithFormInstanceProps['form']
) => {
    if (!type) return

    let disabled = false

    const values: Store<string | FillEmptyTextAnswer> = form?.getFieldsValue()
    const required = REQUIRED_FIELDS[type]

    function defaultCheck() {
        if (
            required.some(
                (requiredKey) =>
                    !(Array.isArray(values[requiredKey])
                        ? values[requiredKey].length
                        : values[requiredKey])
            )
        ) {
            disabled = true
        }
    }

    switch (type) {
        case QuestionType.ChooseVariant:
            if (isNil(values.selectedAnswerIds) && values.text === '') {
                disabled = true
            } else if (isNil(values.selectedAnswerIds) && values.text) {
                disabled = false
            } else {
                defaultCheck()
            }

            break

        case QuestionType.FillEmptyText:
            disabled = !(values?.missingTextBlocks as FillEmptyTextAnswer)?.some(
                (answer) => answer?.text
            )

            break

        default:
            defaultCheck()

            break
    }

    return disabled
}

/**
 * Текст при блоке вопроса другим вопросом
 * @param questionNumber - номер блокирующего вопроса
 */
export const getBlockedByAnotherQuestionText = (questionNumber: number) =>
    `Вопрос будет доступен после того как вы ответите на Вопрос ${questionNumber}`

/**
 * Рендер при блоке вопроса инициализацией таймера
 * @param onClick - функция вызываемая при клике на кнопку "начать"
 * @param timeLimit - количество времени на прохождение вопроса в минутах
 */
export const getBlockedByTimerInitPlaceholder = (
    onClick: FnActionProps,
    timeLimit: number
) => (
    <>
        <p>
            {'Вопрос имеет ограничение ответа'}

            <br />

            {`На вопрос отводится ${timeLimit} мин.`}
        </p>

        <Button onClick={onClick}>{LOCAL.ACTIONS.BEGIN}</Button>
    </>
)

/**
 * Возвращает форму в зависимости от типа вопроса
 * @param question - объект вопроса
 * @param isPassingInTrack
 */
export const getAnswerFormType = ({
    question,
    ...restProps
}: GetAnswerFormTypeProps) => {
    const props = { ...question, ...restProps }

    return {
        CriteriaScale: <CriteriaScalePassing {...props} />,
        TextQuestion: <TextAnswerPassing {...props} />,
        ChooseVariant: <ChoosingAnswerPassing {...props} />,
        FillEmptyText: <PasteTextPassing {...props} />,
    }[question.type]
}

/**
 * Рендер при отсутствии блокеров
 * @param questionText - текст вопроса
 * @param questionType - тип вопроса
 * @param formFields - поля формы
 * @param questionAttachment - прикрепленное видео
 */
export const getUnblockedQuestionContent = ({
    questionText,
    questionType,
    formFields,
    questionAttachment,
}: GetUnblockedQuestionContentProps) => (
    <>
        {!isQuestionTypeFillEmptyText(questionType) && (
            <div className={styles.questionText}>{questionText}</div>
        )}

        {questionAttachment && (
            <VideoAttachment questionAttachment={questionAttachment} />
        )}

        {formFields}
    </>
)

/**
 * Текст заголовка вопроса
 */
export const getQuestionTitle = ({
    title,
    mandatoryQuestion,
    number,
}: GetQuestionTitleProps) => {
    const questionTitle = mandatoryQuestion ? title : ` - ${title}`

    return (
        <>
            {`${LOCAL.LABELS.QUESTION} ${number}`}

            {mandatoryQuestion && (
                <TooltipAdapter title={LOCAL.LABELS.MANDATORY_QUESTION}>
                    <IconsAdapter
                        iconType="ExclamationCircleOutlined"
                        className={styles.icon}
                    />
                </TooltipAdapter>
            )}

            {title && (
                <span className={styles.questionTitle}>{questionTitle}</span>
            )}
        </>
    )
}

/**
 * Функция для оптимизации объекта значений формы при типе вопроса "Выбор из предложенных вариантов"
 * @param parametersData - значения формы с бэка
 */
const getChooseAnswerParameters = (
    parametersData?: PassingFormQuestionAnswerChooseVariantContract
) => {
    if (!parametersData) return

    return {
        selectedAnswerIds: parametersData.selectedAnswerIds,
        customAnswer: parametersData.customAnswer,
        customAnswerText: parametersData.text,
    }
}

/**
 * Достаем объект с ранее сохраненным ответом
 * @param answerBody - тело ответа
 */
export const getAnswer = (
    answerBody?: Partial<
        | PassingFormQuestionAnswerContract
        | PassingFormQuestionAnswerCreateContract
    >
) =>
    answerBody?.textAnswerParameters ||
    answerBody?.scaleAnswerParameters ||
    getChooseAnswerParameters(answerBody?.chooseAnswerParameters)

/**
 * Сравнение ответов локальных и отправленных ранее на сервер
 * @param answer локальный ответ
 * @param confirmedAnswer сохраненный ранее
 */
export const compareAnswers = (
    answer: AnswerComparisonParam,
    confirmedAnswer?: AnswerComparisonParam
) => {
    const updatedAnswer = !isArrayEmpty(answer?.missingTextBlocks)
        ? { missingTextBlocks: answer?.missingTextBlocks?.filter(Boolean) }
        : answer

    return isEqual(updatedAnswer, confirmedAnswer)
}
