import React, { FC, useCallback, useEffect, useState } from 'react'
import {
    AppointmentAssessmentExpertListContractPageContract,
    AppointmentsService,
} from 'core/api'
import { AssessmentAssignedProps } from 'components/pages/AssessmentAssigned/AssessmentAssigned.types'
import {
    ButtonsToolbar,
    FiltersToggleButton,
    QuickSearch,
    TableAdapter,
} from 'components/shared'
import { DICTIONARY_KEYS } from 'core/configs'
import { RequestQueryProps, useDictionariesHelper, useFilter } from 'hooks'
import { getPageSize } from 'utils'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import { AssessmentAssignedGroupExpertFilters } from '../AssessmentAssignedGroupExpertFilters'
import { getColumns } from './AssessmentAssignedGroupExpert.config'

export const AssessmentAssignedGroupExpert: FC<AssessmentAssignedProps> = withLoader(
    React.memo(({ onUpdateRecordsCount, updateLoader }) => {
        const locales = useDictionariesHelper(['assessmentTypeDictionary'])

        const [registerData, setRegisterData] = useState<
            AppointmentAssessmentExpertListContractPageContract
        >()

        const [visibleFilter, triggerFilterVisibility] = useFilter()

        const {
            paginationOptions,
            queryParams,
            handleSetFilters,
            handleResetFilters,
            handleSort,
        } = useSearchQuery({
            dictionaryKey: DICTIONARY_KEYS.ASSESSMENT_ASSIGNED_GROUP_EXPERT,
        })

        const dictionaryFetch = useCallback(async () => {
            try {
                updateLoader(true)

                const dataSource = queryParams.body?.searchText
                    ? await AppointmentsService.searchTextForGroupExpert(
                          queryParams as Required<RequestQueryProps>
                      )
                    : await AppointmentsService.searchForGroupExpertChecking(
                          queryParams as Required<RequestQueryProps>
                      )

                onUpdateRecordsCount(dataSource.total)
                setRegisterData(dataSource)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader, queryParams, onUpdateRecordsCount])

        useEffect(() => {
            dictionaryFetch()
        }, [dictionaryFetch])

        return (
            <>
                <ButtonsToolbar>
                    <QuickSearch
                        onSetFilters={handleSetFilters}
                        isReset={visibleFilter}
                    />

                    <FiltersToggleButton
                        onToggleFilterVisibility={triggerFilterVisibility}
                    />
                </ButtonsToolbar>

                {visibleFilter && (
                    <AssessmentAssignedGroupExpertFilters
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                    />
                )}

                <TableAdapter
                    columns={getColumns(locales)}
                    dataSource={registerData?.pageItems}
                    pagination={{
                        ...paginationOptions,
                        total: registerData?.total,
                        current: queryParams.pageNumber,
                        pageSize: getPageSize(
                            queryParams?.pageSize,
                            registerData?.pageItems?.length
                        ),
                    }}
                    onChange={handleSort}
                    showSorterTooltip={false}
                />
            </>
        )
    })
)
