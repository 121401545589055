import unionWith from 'lodash/unionWith'
import React, { FC, useCallback, useMemo, useState } from 'react'
import {
    AddButton,
    CollapseSingle,
    DynamicTableAdapter,
    IconsAdapter,
} from 'components/shared'
import { AddOrganizationControl } from 'components/controls/AddOrganizationControl'
import { AddPositionControl } from 'components/controls/AddPositionControl'
import {
    COMPETENCIES_TABLE_COLUMNS,
    CheckboxControl,
    CompetenciesFilters,
    FormItemAdapter,
    ShouldUpdateChecker,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import {
    CompetenceContract,
    CompetenceForModalContract,
    CompetenciesSearchContract,
    CompetenciesService,
    PositionProfilesService,
} from 'core/api'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { createWarningNotification, isFormModeView } from 'utils'

import styles from './ManagerParametersRow.module.scss'
import {
    CompetenceFormContract,
    ManagerParametersRowProps,
    QuestionnairesState,
} from './ManagerParametersRow.types'
import { getRenderColumnFn } from './ManagerParametersRow.config'
import {
    normalizeFetchDataForValue,
    updatedManagerParameters,
} from './ManagerParametersRow.utils'

/** Группа полей - оценка руководителем: один ряд */
export const ManagerParametersRow: FC<ManagerParametersRowProps> = ({
    formMode,
    field,
    value: managerParameter,
    onChange,
    onRemoveRow,
    form,
    disabled,
}) => {
    const [questionnaires, setQuestionnaires] = useState<QuestionnairesState>()
    const isDisabled = isFormModeView(formMode) || disabled

    const spoilerTitleRender = (title: string): React.ReactNode => (
        <div>
            {title}

            <IconsAdapter
                iconType="DeleteOutlined"
                onClick={() => !isDisabled && onRemoveRow(field.name)}
                disabled={isDisabled}
            />
        </div>
    )

    /**
     * Запрос справочника опросных листов по id компетенции
     */
    const handleLoadCompetenciesFromPositionProfile = useCallback(async () => {
        try {
            if (
                !managerParameter?.structuralUnitId ||
                !managerParameter.positionId
            ) {
                createWarningNotification(
                    LOCAL.MESSAGES.ATTENTION,
                    LOCAL.MESSAGES.POSITION_PROFILE_MISSING
                )

                return
            }

            const questionnaires = await PositionProfilesService.getByUnitPositionNoPermissions(
                {
                    unitId: Number(managerParameter.structuralUnitId[0].value),
                    positionId: Number(managerParameter.positionId[0].value),
                }
            )

            if (!questionnaires)
                createWarningNotification(
                    LOCAL.MESSAGES.ATTENTION,
                    LOCAL.MESSAGES.POSITION_PROFILE_MISSING
                )

            const positionProfileCompetencies = questionnaires?.competencies?.map(
                ({ id, name }) => ({ id, name })
            )

            const mergedQuestionnaires = unionWith(
                managerParameter?.questionnaires,
                positionProfileCompetencies,
                (first, second) => first.id === second.id
            )

            onChange?.({
                ...managerParameter,
                questionnaires: mergedQuestionnaires,
            })
        } catch (error) {
            console.error(error)
        }
    }, [managerParameter, onChange])

    const headerAddonAfter = useMemo(
        () => (
            <AddButton
                onClick={handleLoadCompetenciesFromPositionProfile}
                buttonText={LOCAL.ACTIONS.ADD_COMPETENCIES_FROM_PP}
                disabled={isDisabled}
            />
        ),
        [handleLoadCompetenciesFromPositionProfile, isDisabled]
    )

    return (
        <CollapseSingle
            title={`${LOCAL.LABELS.ASSESSED_POSITION} ${field.name + 1}`}
            active
            className={styles.spoiler}
            renderHeader={spoilerTitleRender}
        >
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName={[field.name, 'positionId']}
                        label={LOCAL.LABELS.POSITION}
                        required
                    >
                        <AddPositionControl
                            form={form}
                            formMode={formMode}
                            rowSelectionType="radio"
                            disabled={disabled}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        label={LOCAL.LABELS.STRUCTURAL_UNIT_NAME}
                        fieldName={[field.name, 'structuralUnitId']}
                        required
                    >
                        <AddOrganizationControl
                            formMode={formMode}
                            rowSelectionType="radio"
                            disabled={isDisabled}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={6}>
                    <FormItemAdapter
                        label="&nbsp;"
                        fieldName={[field.name, 'hasSelfQuestionnaires']}
                    >
                        <CheckboxControl
                            formMode={formMode}
                            disabled={disabled}
                        >
                            {LOCAL.LABELS.ASSESSMENT_SELF}
                        </CheckboxControl>
                    </FormItemAdapter>
                </Col>
            </Row>

            <ShouldUpdateChecker
                fieldPath={[
                    'managerParameters',
                    field.name,
                    'hasSelfQuestionnaires',
                ]}
            >
                {({ getFieldValue }) => {
                    const hasSelfQuestionnaires = getFieldValue([
                        'managerParameters',
                        field.name,
                        'hasSelfQuestionnaires',
                    ])

                    updatedManagerParameters({
                        form,
                        fieldName: field.name,
                        hasSelfQuestionnaires,
                        getFieldValue,
                    })

                    return (
                        <Form.Item name={[field.name, 'questionnaires']}>
                            <DynamicTableAdapter<
                                CompetenceContract,
                                CompetenceFormContract,
                                CompetenciesSearchContract,
                                CompetenceForModalContract
                            >
                                getEntitiesByIds={CompetenciesService.getByIds}
                                searchMethod={CompetenciesService.getForModal}
                                modalFilterComponent={CompetenciesFilters}
                                columnsForSearchModal={
                                    COMPETENCIES_TABLE_COLUMNS
                                }
                                normalizeFn={normalizeFetchDataForValue}
                                renderTableColumns={getRenderColumnFn({
                                    setQuestionnaires,
                                    questionnaires,
                                    hasSelfQuestionnaires,
                                    formMode,
                                    disabled,
                                })}
                                title={LOCAL.LABELS.COMPETENCIES}
                                modalTitle={LOCAL.LABELS.COMPETENCES_SELECTION}
                                headerAddonAfter={headerAddonAfter}
                                formMode={formMode}
                                className={styles.dynamicTableAdapter}
                                required
                                disabled={disabled}
                            />
                        </Form.Item>
                    )
                }}
            </ShouldUpdateChecker>
        </CollapseSingle>
    )
}
