import { LOCAL } from 'core/local'
import { NOTIFICATION_DELIVERY_STATUSES } from 'consts'
import { NotificationDeliveryStatus } from 'core/api'

export const DELIVERY_MESSAGE_STATISTIC_COLUMNS = [
    {
        title: LOCAL.LABELS.STATUS_DELIVERY,
        dataIndex: 'status',
        key: 'status',
        render: (status: NotificationDeliveryStatus) =>
            NOTIFICATION_DELIVERY_STATUSES[status],
    },
    {
        title: LOCAL.LABELS.MESSAGE_COUNTS,
        dataIndex: 'emailsCount',
        key: 'emailsCount',
    },
]
