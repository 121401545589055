import { AppointmentTrackRespondentContract, UserContract } from 'core/api'
import { getFullFio, renderOrganizationName, renderPositionName } from 'utils'

import { UserFormContract } from './AddUsersTableControl.types'

const findFn = (compareId: number, arr?: UserFormContract[]) =>
    arr?.find((item) => item.id === compareId)

/** нормализация данных, полученных через `getEntitiesByIds`, для `value` и внутреннего сейта компонента */
export const normalizeFetchDataForValue = (
    newData: UserContract[],
    oldData?: UserFormContract[]
): UserFormContract[] =>
    newData.map((el) => {
        const oldVal = findFn(el.id, oldData)

        return {
            ...el,
            userId: el.id,
            role: oldVal?.role,
            name: getFullFio({
                firstName: el.firstName,
                lastName: el.lastName,
                patronymic: el.patronymic,
            }),

            // TODO в рамках задачи SDO-4895 поправить типизацию unitValues и привести к единообразию organizations/positions или organization/position
            positions: oldVal?.positions ?? renderPositionName(el),
            organizations: oldVal?.organizations ?? renderOrganizationName(el),
            score: oldVal?.score,
        }
    })

// TODO в рамках задачи SDO-4895 поправить типизацию unitValues и привести к единообразию organizations/positions или organization/position

export const normalizeFetchDataForImportedValue = (
    newData: AppointmentTrackRespondentContract[],
    oldData = [] as UserFormContract[]
): UserFormContract[] => {
    const concatedValues = oldData ? [...oldData, ...newData] : newData

    const unitValues = concatedValues.reduce(
        (acc: UserFormContract[], current: UserFormContract) => {
            if (acc.find((el) => el.id === current.id)) {
                return acc
            }

            return [
                ...acc,
                {
                    ...current,
                    userId: current.id,
                    role: findFn(current.id, oldData)?.role,
                    score: findFn(current.id, newData)?.score,
                    name:
                        current.name ??
                        getFullFio({
                            firstName: current.firstName,
                            lastName: current.lastName,
                            patronymic: current.patronymic,
                        }),
                    positions: current.positions ?? current.position?.name,
                    organizations:
                        current.organizations ?? current.organization?.name,
                },
            ]
        },
        []
    )

    return unitValues
}
