import cn from 'classnames'
import React, { useCallback } from 'react'
import { LOCAL } from 'core/local'
import { PositionProfileType } from 'core/api'
import { SwitchControl } from 'components/controls'
import { isFormModeView } from 'utils'

import styles from './ProfileTypeControl.module.scss'
import { ProfileTypeControlProps } from './ProfileTypeControl.types'
import { isProfileGGSType } from './ProfileTypeControl.utils'

/**
 * Тип профайла, кастомный контрол
 */
export const ProfileTypeControl: React.FC<ProfileTypeControlProps> = React.memo(
    ({ value, onChange, formMode }) => {
        const isProfileGGS = isProfileGGSType(value)
        /**
         * Изменить значение во внешней форме
         */
        const handleChange = useCallback(
            (checked?: boolean) => {
                if (onChange)
                    onChange(
                        checked
                            ? PositionProfileType.RBS
                            : PositionProfileType.GGS
                    )
            },
            [onChange]
        )

        return (
            <div className={styles.wrapper}>
                <div
                    className={cn(
                        styles.label,
                        isProfileGGS && styles.labelActive
                    )}
                >
                    {LOCAL.LABELS.GGS}
                </div>

                <SwitchControl
                    size="small"
                    checked={!isProfileGGS}
                    onChange={handleChange}
                    disabled={isFormModeView(formMode)}
                    className={styles.switchControl}
                />

                <div
                    className={cn(
                        styles.label,
                        !isProfileGGS && styles.labelActive
                    )}
                >
                    {LOCAL.LABELS.RBS}
                </div>
            </div>
        )
    }
)
