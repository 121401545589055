import React from 'react'
import { LOCAL } from 'core/local'
import { PassingIconBlockProps } from 'components/shared/Passing/PassingIconBlock'

/** Создаём массив блоков с иконками и текстом для стадии */
export const makeIconBlocksProps = (
    numberOfCompetences: number,
    numberOfQuestions: number
): PassingIconBlockProps[] => [
    {
        iconType: 'ClockCircleOutlined',
        content: (
            <>
                {`${LOCAL.LABELS.ALLOTTED_TIME}:`}

                <br />

                {LOCAL.LABELS.NOT_RESTRICTED}
            </>
        ),
    },
    {
        iconType: 'UnorderedListOutlined',
        content: (
            <>
                {`${LOCAL.LABELS.NUMBER_OF_COMPETENCIES_BLOCKS}: `}

                {`${numberOfCompetences},`}

                <br />

                {`${LOCAL.LABELS.NUMBER_OF_QUESTIONS}: ${numberOfQuestions}`}
            </>
        ),
    },
    {
        iconType: 'SwapOutlined',
        content: LOCAL.MESSAGES.YOU_CAN_ANSWER_QUESTIONS_IN_ANY_ORDER,
    },
]
