import React from 'react'
import cn from 'classnames'
import { WithHeightResizeProps, withHeightResize } from 'HOCs/withHeightResize'

import styles from './PageContent.module.scss'
import { PageContentProps } from './PageContent.types'

const PageContent: React.FC<PageContentProps & WithHeightResizeProps> = ({
    children,
    className,
    filled,
    componentHeight,
    componentRef,
    grid,
    style,
    useHeightStyleForResize,
}) => {
    const heightStyles = useHeightStyleForResize
        ? { height: componentHeight, overflow: 'auto' }
        : { minHeight: componentHeight }

    return (
        <section
            className={cn(
                styles.wrapper,
                filled && styles.filled,
                grid && styles.grid,
                className
            )}
            ref={componentRef}
            style={{
                ...style,
                ...heightStyles,
            }}
        >
            {children}
        </section>
    )
}

export default React.memo(withHeightResize(PageContent)) as typeof PageContent
