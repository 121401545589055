import React, { FC } from 'react'
import { CompetenciesIndicatorForm } from 'components/forms'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import {
    IndicatorContract,
    IndicatorsSearchContract,
    IndicatorsService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getBackLinkTitleFromRoute } from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'

import { COMPETENCIES_INDICATORS_TABLE_COLUMNS } from './consts/CompetenciesIndicators.consts'
import { CompetenciesIndicatorsFilters } from './components'

/** Справочник индикаторов компетенций */
export const CompetenciesIndicators: FC = React.memo(() => (
    <DictionaryPage<IndicatorsSearchContract, IndicatorContract>
        dictionaryKey={DICTIONARY_KEYS.COMPETENCIES_INDICATORS}
        searchMethod={IndicatorsService.search}
        deleteMethod={IndicatorsService.delete}
        filterComponent={CompetenciesIndicatorsFilters}
        tableFiltersOptions={{
            initialValues: {
                orderRules: ORDER_RULES_WITH_CHANGE_DATE,
            },
        }}
        contentTitle={getRouteMeta(ROUTE_NAMES.COMPETENCIES_INDICATORS)}
        quickSearchMethod={IndicatorsService.searchText}
        createPermissions={[
            UserPermission.IndicatorRead,
            UserPermission.IndicatorSave,
        ]}
        tableColumns={COMPETENCIES_INDICATORS_TABLE_COLUMNS}
        createRecordModal={{
            component: CompetenciesIndicatorForm,
            formId: FORM_IDS.COMPETENCIES_INDICATOR_FORM,
            modalOptions: {
                footer: null,
            },
            bodyWithoutScroll: true,
        }}
        rowClassName={getRowClassNameArchive}
        backLink={{
            path: ROUTE_NAMES.COMPETENCIES,
            text: getBackLinkTitleFromRoute(ROUTE_NAMES.COMPETENCIES),
        }}
    />
))
