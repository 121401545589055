import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Space } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { QuestionnaireModal } from 'components/shared/QuestionnaireModal'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ShouldUpdateChecker } from 'components/controls'
import { TrackPassingsService, TrackQuestionnaireContract } from 'core/api'
import { isAppointmentTrackStatusPassed } from 'utils'
import { useElementVisible } from 'hooks'
import { useHistory } from 'react-router-dom'

import styles from './TrackInfoMenu.module.scss'
import { QuizForm } from './QuizForm'
import { TrackInfoMenuProps } from './TrackInfoMenu.types'
import { renderInfoMenu } from './TrackInfoMenu.utils'

/** Компонент дополнительной информации о треке адаптации */
export const TrackInfoMenu: React.FC<TrackInfoMenuProps> = React.memo(
    ({ appointmentId, trackInfo, onToggleContent }) => {
        const history = useHistory()
        const [spoilerVisibleIndex, setSpoilerVisibleIndex] = useState(0)
        const [questions, setQuestions] = useState<TrackQuestionnaireContract>()

        const {
            elementVisible: hiddenInfo,
            toggleElementVisible: handleToggleInfo,
        } = useElementVisible(true)

        const {
            handleHideElement: hideFeedbackForm,
            handleShowElement: showFeedbackForm,
            elementVisible: feedbackFromVisible,
        } = useElementVisible()

        const hideFeedBack = useCallback(() => {
            if (!trackInfo.isTrackWithVisualisation) {
                history.push(ROUTE_NAMES.YOUR_DEVELOPMENT)
            }

            hideFeedbackForm()
        }, [hideFeedbackForm, history, trackInfo.isTrackWithVisualisation])

        /** Обработчик изменения номера текущего видимого элемента */
        const onChangeSpoilerVisibleIndex = useCallback(
            (index: number) => () => {
                setSpoilerVisibleIndex((prev) => (prev === index ? 0 : index))
            },
            []
        )

        const onShowFeedbackForm = useCallback(async () => {
            try {
                setQuestions(
                    await TrackPassingsService.getQuestions({
                        appointmentId,
                    })
                )

                showFeedbackForm()
            } catch (error) {
                console.error(error)
            }
        }, [appointmentId, showFeedbackForm])

        const trackMenuVisualization = trackInfo.trackMenuVisualization

        const textStyle = {
            color: trackMenuVisualization?.menuTextSettings?.textColor,
            fontName: trackMenuVisualization?.menuTextSettings?.fontName,
        }

        const trackMenuBackgroundStyle = {
            background: trackMenuVisualization?.backgroundColor,
        }

        useEffect(() => {
            const hasQuestionnaire =
                trackInfo?.trackAdditionalInfo?.questionnaire

            if (!hasQuestionnaire) return

            const isQuestionnairePassed = trackInfo?.isQuestionnairePassed
            const isAppointmentPassed = isAppointmentTrackStatusPassed(
                trackInfo.status
            )

            if (isAppointmentPassed && !isQuestionnairePassed)
                onShowFeedbackForm()
        }, [trackInfo, onShowFeedbackForm])

        return (
            <div
                className={cn(
                    styles.wrapper,
                    hiddenInfo && styles.wrapperHidden
                )}
            >
                <ShouldUpdateChecker fieldPath={['trackAdditionalInfo']}>
                    {({ getFieldValue }) => {
                        const menuData = getFieldValue('trackAdditionalInfo')

                        return (
                            <Space size={0} align="start">
                                <div
                                    onClick={handleToggleInfo}
                                    className={styles.toggle}
                                    style={{
                                        ...trackMenuBackgroundStyle,
                                        ...textStyle,
                                    }}
                                >
                                    <span hidden={!hiddenInfo}>
                                        {LOCAL.LABELS.MENU}
                                    </span>

                                    <IconsAdapter
                                        hidden={hiddenInfo}
                                        iconType="CloseOutlined"
                                    />
                                </div>

                                <div
                                    className={styles.list}
                                    style={trackMenuBackgroundStyle}
                                >
                                    <div
                                        className={styles.title}
                                        style={textStyle}
                                    >
                                        {LOCAL.LABELS.MENU}
                                    </div>

                                    {trackMenuVisualization.isContentsVisible && (
                                        <>
                                            <Divider />

                                            <div
                                                onClick={onToggleContent}
                                                className={cn(
                                                    styles.title,
                                                    styles.pointer
                                                )}
                                                style={textStyle}
                                            >
                                                {LOCAL.LABELS.SUBSTANCE}
                                            </div>
                                        </>
                                    )}

                                    {renderInfoMenu({
                                        spoilerVisibleIndex,
                                        onChangeSpoilerVisible: onChangeSpoilerVisibleIndex,
                                        data: menuData,
                                        onShowFeedbackForm,
                                        textStyle,
                                        isHomeWorksVisible:
                                            trackMenuVisualization.isHomeWorksVisible,
                                    })}
                                </div>

                                {feedbackFromVisible &&
                                    menuData?.questionnaire?.name && (
                                        <QuestionnaireModal
                                            initialValues={{
                                                id: appointmentId,
                                                name:
                                                    menuData.questionnaire.name,
                                                questions: questions?.questions,
                                                currentQuestionNumber:
                                                    questions?.currentQuestionNumber,
                                            }}
                                            onClose={hideFeedBack}
                                            componentWrapper={QuizForm}
                                        />
                                    )}
                            </Space>
                        )
                    }}
                </ShouldUpdateChecker>
            </div>
        )
    }
)
