import { AppointmentTrackStatus } from 'core/api'

export const BEFORE_START = [AppointmentTrackStatus.Draft]
export const IN_PROCESS = [
    AppointmentTrackStatus.InWork,
    AppointmentTrackStatus.AvailableToPassing,
    AppointmentTrackStatus.Appointment,
    AppointmentTrackStatus.Ended,
]
export const PASSING = [
    AppointmentTrackStatus.Suspended,
    AppointmentTrackStatus.Canceled,
    AppointmentTrackStatus.Passed,
    AppointmentTrackStatus.NotPassed,
]
