import { ActivityPassingStatus, AppointmentTrackStatus } from 'core/api'

import styles from './ProcessCardHeader.module.scss'

export const TRACK_STATUS_TITLE_CLASSNAMES = {
    [AppointmentTrackStatus.Appointment]: styles.success,
    [AppointmentTrackStatus.Ended]: styles.completed,
    [AppointmentTrackStatus.Suspended]: styles.refused,
    [AppointmentTrackStatus.Passed]: styles.success,
    [AppointmentTrackStatus.NotPassed]: styles.refused,
    [AppointmentTrackStatus.AvailableToPassing]: styles.success,
    [AppointmentTrackStatus.InWork]: styles.inWork,
}

export const ACTIVITY_STATUS_TITLE_CLASSNAMES = {
    [ActivityPassingStatus.CompletedUnsuccessfully]: styles.refused,
    [ActivityPassingStatus.InProgress]: styles.inWork,
    [ActivityPassingStatus.Waiting]: styles.success,
    [ActivityPassingStatus.CompletedSuccessfully]: styles.success,
    [ActivityPassingStatus.Pause]: styles.refused,
}
