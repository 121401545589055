import { CatalogItemProductCardContract } from 'core/api'

/**
 * Нормализуем данные для поиска по наименованию
 */
export const normalizeDataForSearch = ({
    id,
    name,
    type,
}: CatalogItemProductCardContract) => ({
    id,
    value: name,
    label: name,
    key: String(id),
    type,
})
