import React, {
    ReactText,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Col, Row } from 'antd'
import {
    ColumnsOptions,
    ContentTitle,
    FiltersToggleButton,
    PageBanner,
    PageContent,
    PageTabs,
    PopupWithForm,
    TableAdapter,
} from 'components/shared'
import { ConfirmDeleteForm } from 'components/forms'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import {
    NotificationContract,
    NotificationSearchContract,
    NotificationsService,
} from 'core/api'
import { NotificationCounterContext } from 'contexts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    RequestQueryProps,
    useBreakpoint,
    useFilter,
    usePageSettings,
} from 'hooks'
import {
    createSuccessNotification,
    getPageSize,
    getTableColumnsWithClass,
    showElem,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { pageTabsRender } from 'components/shared/PageTabs/PageTabs.utils'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import styles from './UserNotifications.module.scss'
import { UserNotificationsFilters } from './components'
import { getUserNotificationsTableColumns } from './UserNotifications.utils'

/** Уведомления пользователя */
export const UserNotifications: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const isWidthLessXXL = useBreakpoint('XXL')
        const [recordsCount, setRecordsCount] = useState(0)
        const [notificationsData, setNotificationsData] = useState<
            NotificationContract[]
        >()
        const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>()
        const [visibleFilter, triggerFilterVisibility] = useFilter()

        const { columnsVisible, saveFilters } = usePageSettings(
            DICTIONARY_KEYS.USER_NOTIFICATIONS
        )

        const { setNotificationUnreadCount } = useContext(
            NotificationCounterContext
        )

        const {
            paginationOptions,
            queryParams,
            handleSetFilters,
            handleResetFilters,
            handleSort,
        } = useSearchQuery({
            dictionaryKey: DICTIONARY_KEYS.USER_NOTIFICATIONS,
        })

        const dictionaryFetch = useCallback(async () => {
            try {
                updateLoader(true)

                const dataSource = await NotificationsService.search(
                    queryParams as Required<
                        RequestQueryProps<NotificationSearchContract>
                    >
                )

                setNotificationsData(dataSource.pageItems)
                setRecordsCount(dataSource.total)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [queryParams, updateLoader])

        const handleRowCheck = useCallback((selectedTableRowKeys) => {
            setSelectedRowKeys(selectedTableRowKeys)
        }, [])

        const handleDelete = useCallback(
            (id) =>
                NotificationsService.delete({
                    body: { items: (id ? [id] : selectedRowKeys) as string[] },
                }),
            [selectedRowKeys]
        )

        const handleRequestFinish = useCallback(dictionaryFetch, [
            dictionaryFetch,
        ])

        /**
         * Обработчик для отметки сообщения как прочитанного
         */
        const handleSetAsRead = useCallback(
            (items: string[]) => async () => {
                try {
                    const response = await NotificationsService.markAsRead({
                        body: {
                            items,
                        },
                    })
                    setNotificationUnreadCount(response.unreadCount)
                    dictionaryFetch()
                } catch (error) {
                    console.error(error)
                }
            },
            [dictionaryFetch, setNotificationUnreadCount]
        )

        /**
         * Итоговые настройки для отображения колонок
         */
        const tableColumnsCalculated = useMemo(
            () =>
                getTableColumnsWithClass<NotificationContract>(
                    columnsVisible,
                    getUserNotificationsTableColumns({
                        handleSetAsRead,
                        selectedRowKeys,
                    })
                ),
            [columnsVisible, handleSetAsRead, selectedRowKeys]
        )

        useEffect(() => {
            dictionaryFetch()
        }, [dictionaryFetch])

        useEffect(() => {
            if (saveFilters) {
                createSuccessNotification(LOCAL.MESSAGES.SAVE_FILTERS)
            }
        }, [saveFilters])

        return (
            <PageContent className={styles.wrapper} filled>
                <PageBanner>
                    <PageTabs tabsData={pageTabsRender(recordsCount)} />
                </PageBanner>

                <ContentTitle
                    title={getRouteMeta(ROUTE_NAMES.USER_NOTIFICATIONS)}
                >
                    <Row
                        gutter={15}
                        justify={isWidthLessXXL ? undefined : 'end'}
                        align="middle"
                    >
                        <Col>
                            <FiltersToggleButton
                                onToggleFilterVisibility={
                                    triggerFilterVisibility
                                }
                                isActive={saveFilters}
                            />
                        </Col>
                    </Row>
                </ContentTitle>

                <div className={showElem(visibleFilter)}>
                    <UserNotificationsFilters
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                        dictionaryKey={DICTIONARY_KEYS.USER_NOTIFICATIONS}
                    />
                </div>

                <ColumnsOptions<NotificationContract>
                    dictionaryKey={DICTIONARY_KEYS.USER_NOTIFICATIONS}
                    tableColumns={tableColumnsCalculated}
                />

                <TableAdapter
                    columns={tableColumnsCalculated}
                    dataSource={notificationsData}
                    pagination={{
                        ...paginationOptions,
                        total: recordsCount,
                        current: queryParams.pageNumber,
                        pageSize: getPageSize(
                            queryParams?.pageSize,
                            notificationsData?.length
                        ),
                    }}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys,
                        onChange: handleRowCheck,
                        preserveSelectedRowKeys: true,
                    }}
                    onChange={handleSort}
                    showSorterTooltip={false}
                />

                <PopupWithForm
                    component={ConfirmDeleteForm}
                    formId={FORM_IDS.CONFIRM_DELETE_FORM}
                    onRequestFinish={handleRequestFinish}
                    haveButton={false}
                    deleteFormAction={handleDelete}
                />
            </PageContent>
        )
    })
)
