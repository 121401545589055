import merge from 'lodash/merge'
import React, { useCallback, useContext, useEffect } from 'react'
import { Button } from 'components/shared/Button'
import { FnActionProps } from 'App.types'
import { LOCAL } from 'core/local'
import { ModalProps } from 'antd/lib/modal'
import { ModalsContext, ModalsOptionsProps } from 'contexts'
import { useElementVisible } from 'hooks'

import { Popup } from '../Popup'
import { PopupWithFormProps } from './PopupWithForm.types'

export const PopupWithForm: React.FC<PopupWithFormProps> = React.memo(
    ({
        component: Component,
        buttonText = LOCAL.ACTIONS.ADD,
        formId,
        modalOptions,
        formOptions,
        buttonOption,
        havePopup = true,
        haveButton = true,
        recordCopy,
        onRequestFinish,
        deleteFormAction,
        rowSelectionType,
        messageText,
        className,
        onRefetch,
        bodyWithoutScroll,
    }) => {
        const { popupWithFormOptions, setPopupWithFormOptions } = useContext(
            ModalsContext
        )

        const { elementVisible, updateElementVisible } = useElementVisible(
            false
        )

        const changeModalOptions = useCallback(
            (changedOptions?: ModalProps) => {
                setPopupWithFormOptions((prevState: ModalsOptionsProps) => ({
                    ...prevState,
                    [formId]: {
                        ...prevState[formId],
                        visible:
                            changedOptions?.visible ??
                            prevState[formId].visible,
                        modalOptions: merge(
                            prevState[formId].modalOptions,
                            changedOptions
                        ),
                    },
                }))
            },
            [setPopupWithFormOptions, formId]
        )

        const getRestPopupWithFormOptions = useCallback(
            (prevState: ModalsOptionsProps) => ({
                recordCopy,
                messageText,
                modalOptions: {
                    ...prevState[formId]?.modalOptions,
                    ...modalOptions,
                    title: modalOptions?.title || LOCAL.ACTIONS.RECORD_ADD,
                    okText: modalOptions?.okText || LOCAL.ACTIONS.SEND,
                },
            }),
            [formId, modalOptions, recordCopy, messageText]
        )

        const getFormInitialValues = useCallback(
            (prevState: ModalsOptionsProps) => ({
                formOptions: {
                    ...prevState[formId]?.formOptions,
                    initialValues: formOptions?.initialValues,
                },
            }),
            [formId, formOptions]
        )

        const showModal = useCallback(() => {
            setPopupWithFormOptions((prevState: ModalsOptionsProps) => ({
                ...prevState,
                [formId]: {
                    ...prevState[formId],
                    visible: true,
                    ...getFormInitialValues(prevState),
                    ...getRestPopupWithFormOptions(prevState),
                },
            }))
        }, [
            formId,
            getFormInitialValues,
            getRestPopupWithFormOptions,
            setPopupWithFormOptions,
        ])

        const closeModal = useCallback(() => {
            setPopupWithFormOptions((prevState) => ({
                ...prevState,
                [formId]: {
                    ...prevState[formId],
                    visible: false,
                    formOptions: undefined,
                },
            }))
        }, [formId, setPopupWithFormOptions])

        const handleRequestFinish = useCallback<FnActionProps>(
            (values) => {
                closeModal()

                onRequestFinish?.(values)
            },
            [closeModal, onRequestFinish]
        )

        useEffect(() => {
            setPopupWithFormOptions((prevState: ModalsOptionsProps) => ({
                ...prevState,
                [formId]: {
                    ...prevState[formId],
                    ...getRestPopupWithFormOptions(prevState),
                },
            }))
        }, [formId, getRestPopupWithFormOptions, setPopupWithFormOptions])

        return (
            <>
                {haveButton && (
                    <Button
                        {...buttonOption}
                        title={buttonOption?.title}
                        tooltipOptions={{
                            getPopupContainer: buttonOption?.getPopupContainer,
                        }}
                        onClick={showModal}
                    >
                        {buttonText}
                    </Button>
                )}

                {havePopup && Component && (
                    <Popup
                        formId={formId}
                        {...popupWithFormOptions[formId]?.modalOptions}
                        visible={popupWithFormOptions[formId]?.visible}
                        onCancel={closeModal}
                        className={className}
                        localLoaderState={elementVisible}
                        bodyWithoutScroll={bodyWithoutScroll}
                    >
                        <Component
                            onChangeModalOptions={changeModalOptions}
                            onRequestFinish={handleRequestFinish}
                            updateButtonVisibility={updateElementVisible}
                            deleteFormAction={deleteFormAction}
                            id={formId}
                            {...formOptions}
                            recordCopy={
                                popupWithFormOptions[formId]?.recordCopy
                            }
                            initialValues={
                                popupWithFormOptions[formId]?.formOptions
                                    ?.initialValues
                            }
                            rowSelectionType={rowSelectionType}
                            onCancelSubmit={closeModal}
                            messageText={
                                popupWithFormOptions[formId]?.messageText
                            }
                            onRefetch={onRefetch}
                        />
                    </Popup>
                )}
            </>
        )
    }
)
