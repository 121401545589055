import React from 'react'
import { Helmet } from 'react-helmet'
import { LOCAL } from 'core/local'

import { MetaProps } from './DocumentHeadMeta.types'

export const DocumentHeadMeta: React.FC<MetaProps> = React.memo((props) => (
    <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />

        <title>{props.title || LOCAL.DOCUMENT_META.DEFAULT_TITLE}</title>

        {props.description && (
            <meta name="description" content={props.description} />
        )}

        {props['og:title'] && (
            <meta property="og:title" content={props['og:title']} />
        )}

        {props['og:description'] && (
            <meta property="og:description" content={props['og:description']} />
        )}

        <link rel="stylesheet" type="text/css" href="/lde-api/lde/lde.css" />

        <link
            rel="stylesheet"
            type="text/css"
            href="/static-api/fonts/stylesheet.css"
        />

        <script type="module" src="/lde-api/lde/lde.js" />

        {props.children}
    </Helmet>
))
