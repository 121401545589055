import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { IndicatorResultsReport360Contract } from 'core/api'
import { LOCAL } from 'core/local'
import { getItemName } from 'utils'

import styles from './HiddenOpportunitiesProblems.module.scss'
import { renderScore } from '../ReportView/ReportView.utils'

export const getColumns = (
    competenceName: string
): ColumnsType<IndicatorResultsReport360Contract> => [
    {
        title: (
            <span className={styles.competenceHeader}>{competenceName}</span>
        ),
        dataIndex: 'indicator',
        key: 'indicator',
        render: getItemName,
    },
    {
        title: LOCAL.LABELS.ASSESSMENT_SELF,
        dataIndex: 'averageScoreSelf',
        key: 'averageScoreSelf',
        align: 'center',
        width: '172px',
        render: renderScore,
    },
    {
        title: LOCAL.LABELS.ALL_RESPONDENTS,
        dataIndex: 'averageScoreWithoutSelfEstim',
        key: 'averageScoreWithoutSelfEstim',
        align: 'center',
        width: '172px',
        render: renderScore,
    },
]
