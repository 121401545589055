import React from 'react'
import cn from 'classnames'
import { LOCAL } from 'core/local'
import { formatDate } from 'utils'

import styles from './ProductListenersGroup.module.scss'
import { ProductListenersGroupProps } from './ProductListenersGroup.types'
import {
    isProductPeriodCompleted,
    isProductPeriodConfirmed,
} from '../ProductListenersForm/ProductListenersForm.utils'

/** Форма слушателей записанных на продукт - группа обучения */
export const ProductListenersGroup: React.FC<ProductListenersGroupProps> = React.memo(
    ({ group, index, onClick, currentGroupIndex }) => (
        <div
            className={cn(styles.wrapper, {
                [styles.wrapperSelected]: currentGroupIndex === index,
            })}
            onClick={onClick}
        >
            <h3 className={styles.title}>
                {group.isReserved
                    ? LOCAL.LABELS.RESERVE
                    : `${index + 1} ${LOCAL.LABELS.FLOW.toLowerCase()}`}
            </h3>

            {isProductPeriodCompleted(group.status) && (
                <span className={styles.completed}>{LOCAL.LABELS.STAFFED}</span>
            )}

            {isProductPeriodConfirmed(group.status) && (
                <span className={styles.completed}>
                    {LOCAL.LABELS.CONFIRMED}
                </span>
            )}

            <span>
                {group.isReserved
                    ? LOCAL.LABELS.LISTENERS_LIST
                    : `${formatDate(group.startDate)} - ${formatDate(
                          group.endDate
                      )}`}
            </span>
        </div>
    )
)
