import React, { FC } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS } from 'consts'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import {
    NotificationNewsletterTemplateContract,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TableActionsCommonProps } from 'App.types'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

import styles from './DistributionTemplatesTableActions.module.scss'
import { isNotificationTemplateStatusPublished } from '../../DistributionTemplates.utils'

export const DistributionTemplatesTableActions: FC<TableActionsCommonProps<
    NotificationNewsletterTemplateContract
>> = React.memo(({ tableRow }) => (
    <ButtonsToolbar bottomIndent="0">
        <TableActions.View
            requiredPermissions={DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS}
            href={`${ROUTE_NAMES.DISTRIBUTION_TEMPLATES_VIEW}/${tableRow.id}`}
        />

        {tableRow.active && (
            <TableActions.Edit
                requiredPermissions={[
                    ...DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS,
                    UserPermission.NotificationNewsletterTemplateSave,
                ]}
                href={`${ROUTE_NAMES.DISTRIBUTION_TEMPLATES_EDIT}/${tableRow.id}`}
            />
        )}

        <TableActions.Copy
            requiredPermissions={[
                ...DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS,
                UserPermission.NotificationNewsletterTemplateSave,
            ]}
            href={`${ROUTE_NAMES.DISTRIBUTION_TEMPLATES_COPY}/${tableRow.id}`}
        />

        {tableRow.active && (
            <>
                <HasPermissions
                    requiredPermissions={[
                        ...DISTRIBUTION_TEMPLATES_REQUIRED_PERMISSIONS,
                        UserPermission.NotificationNewsletterTemplateDelete,
                    ]}
                    accessDeniedRender={renderAccessDeniedButton({
                        type: 'link',
                        icon: <IconsAdapter iconType="DeleteOutlined" />,
                    })}
                >
                    <PopupWithForm
                        formId={FORM_IDS.CONFIRM_DELETE_FORM}
                        buttonText=""
                        buttonOption={{
                            type: 'link',
                            icon: <IconsAdapter iconType="DeleteOutlined" />,
                            title: LOCAL.ACTIONS.DELETE,
                        }}
                        formOptions={{
                            initialValues: tableRow,
                        }}
                        modalOptions={{
                            title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                            okText: LOCAL.YES,
                        }}
                    />
                </HasPermissions>

                {isNotificationTemplateStatusPublished(tableRow?.status) && (
                    <Button
                        type="link"
                        className={styles.noPadding}
                        href={`${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_CREATE}/${tableRow.id}`}
                        title={LOCAL.ACTIONS.CREATE_TASK_FOR_MAILING}
                    >
                        {'+'}

                        <IconsAdapter iconType="MailOutlined" />
                    </Button>
                )}
            </>
        )}
    </ButtonsToolbar>
))
