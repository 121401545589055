import { FnActionProps } from 'App.types'

export enum ColorThemes {
    Light = 'light',
    Dark = 'dark',
}

export interface ColorThemeContextProps {
    theme: ColorThemes
    updateTheme: (theme: ColorThemes) => void
    changeTheme: FnActionProps
}
