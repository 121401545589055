import noop from 'lodash/noop'
import { AppError } from 'core/api/api.types'
import { FnActionProps } from 'App.types'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH, MODAL_Z_INDEX } from 'consts/layout.consts'
import { Modal, notification } from 'antd'
import { ModalFuncProps } from 'antd/lib/modal'

import { getDropdownPopupContainerDefault } from './layout.utils'

interface CreateNotificationCallbacks {
    onClick?: FnActionProps
    onClose?: FnActionProps
}

export function createSuccessNotification(
    message: string,
    description: string = '',
    callbacks: CreateNotificationCallbacks = {}
): void {
    notification.success({
        message,
        description,
        ...callbacks,
    })
}

export function createErrorNotification(
    message: string,
    description: string = '',
    callbacks: CreateNotificationCallbacks = {}
): void {
    notification.error({
        message,
        description,
        ...callbacks,
    })
}

export function createWarningNotification(
    message: string,
    description: string = '',
    callbacks: CreateNotificationCallbacks = {}
): void {
    notification.warning({
        message,
        description,
        ...callbacks,
    })
}

interface CreateConfirmPopupProps extends ModalFuncProps {}

export function createConfirmPopup({
    title = LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
    content = '',
    okText = LOCAL.ACTIONS.CONFIRM,
    cancelText = LOCAL.ACTIONS.CANCEL,
    zIndex = MODAL_Z_INDEX.CONFIRM,
    onOk = noop,
    onCancel = noop,
    width = MODAL_WIDTH.SM,
    className,
    icon,
    getContainer = getDropdownPopupContainerDefault,
}: CreateConfirmPopupProps = {}): void {
    Modal.confirm({
        okText,
        cancelText,
        title,
        content,
        icon,
        zIndex,
        onOk,
        onCancel,
        width,
        className,
        getContainer,
    })
}

export function createConfirmDeletePopup(
    props: CreateConfirmPopupProps = {}
): void {
    createConfirmPopup({
        ...props,
        content: LOCAL.MESSAGES.ATTENTION_CONFIRM_DELETE,
    })
}

export function createErrorMessages(data: AppError['data']) {
    data.messages?.forEach((el: string) => {
        notification.error({
            message: el,
        })
    })
}
