import React, { useCallback, useEffect } from 'react'
import {
    ActivitiesService,
    AppError,
    StructuralUnitContract,
    StructuralUnitsService,
} from 'core/api'
import {
    COL_xl8_lg12_xs24,
    EVENT_DECORATION_TYPE_MAPPED,
    EVENT_SPECIES_MAPPED,
    EVENT_TYPES_MAPPED,
    MEDIA_DOCUMENT_TYPE,
    PICTURE_VALIDATE_FORMATS,
    ROW_GUTTER,
} from 'consts'
import { Col, Form, Row } from 'antd'
import {
    ControlGroup,
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    RadioButtonControl,
    SelectControl,
    TextAreaControl,
    TreeSelectControl,
    UploadDraggerImageControl,
    getLastFileFromList,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { RcCustomRequestOptions } from 'types'
import { TooltipAdapter } from 'components/shared/TooltipAdapter'
import {
    disableDate,
    getStringWithoutDots,
    isFormModeView,
    normalizeStructuralUnitsWithoutComplexes,
    validateUploadImage,
} from 'utils'
import { useHttp } from 'hooks'

import styles from './EventFormDescription.module.scss'
import { EventFormDescriptionProps } from './EventFormDescription.types'

/** Блок описания формы мероприятия */
export const EventFormDescription: React.FC<EventFormDescriptionProps> = React.memo(
    ({ onEventDecoration, formMode, disabled }) => {
        const [getOrganizations, organizations] = useHttp<
            StructuralUnitContract[]
        >(StructuralUnitsService.getAllStructuralUnits)

        /**
         * Отправка файла на сервер
         * @param request - тело запроса
         */
        const uploadImage = useCallback(
            async (request: RcCustomRequestOptions) => {
                try {
                    const result = await ActivitiesService.upload({
                        file: request.file,
                    })

                    request.onSuccess?.(result, {} as XMLHttpRequest)
                } catch (error) {
                    request.onError?.(error as AppError)
                }
            },
            []
        )

        useEffect(() => {
            getOrganizations()
        }, [getOrganizations])

        return (
            <ControlGroup title={LOCAL.LABELS.DESCRIPTION} type="bordered">
                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.EVENT_M_NAME}
                            required
                        >
                            <InputControl formMode={formMode} />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="announcement"
                            label={LOCAL.LABELS.EVENT_M_DESCRIPTION}
                        >
                            <TextAreaControl
                                autoSize={{ minRows: 4 }}
                                formMode={formMode}
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="decorationType"
                            label={LOCAL.LABELS.DECORATION}
                        >
                            <RadioButtonControl
                                values={EVENT_DECORATION_TYPE_MAPPED}
                                onChange={onEventDecoration}
                                buttonStyle="solid"
                                formMode={formMode}
                                disabled={disabled}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <Form.Item noStyle shouldUpdate>
                            {(form) => (
                                <Row gutter={ROW_GUTTER}>
                                    <Col xs={12}>
                                        <FormItemAdapter
                                            fieldName="type"
                                            label={LOCAL.LABELS.EVENT_M_TYPE}
                                            required
                                        >
                                            <SelectControl
                                                values={EVENT_TYPES_MAPPED}
                                                form={form}
                                                formMode={formMode}
                                                disabled={disabled}
                                            />
                                        </FormItemAdapter>
                                    </Col>

                                    <Col xs={12}>
                                        <FormItemAdapter
                                            fieldName="kind"
                                            label={LOCAL.LABELS.EVENT_M_KIND}
                                            required
                                        >
                                            <SelectControl
                                                values={EVENT_SPECIES_MAPPED}
                                                form={form}
                                                formMode={formMode}
                                                disabled={disabled}
                                            />
                                        </FormItemAdapter>
                                    </Col>
                                </Row>
                            )}
                        </Form.Item>

                        <FormItemAdapter
                            fieldName="ownerOrganizationId"
                            label={LOCAL.LABELS.OWNER}
                            required
                        >
                            <TreeSelectControl
                                treeData={normalizeStructuralUnitsWithoutComplexes(
                                    organizations
                                )}
                                showSearch
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                                disabled={isFormModeView(formMode) || disabled}
                            />
                        </FormItemAdapter>

                        <Row gutter={ROW_GUTTER}>
                            <Col xs={12}>
                                <FormItemAdapter
                                    fieldName="availableFrom"
                                    label={LOCAL.LABELS.EVENTS_HOLDING_DATES}
                                    required
                                >
                                    <DatePickerControl
                                        disabledDate={disableDate()}
                                        formMode={formMode}
                                        disabled={disabled}
                                    />
                                </FormItemAdapter>
                            </Col>

                            <Col xs={12}>
                                <FormItemAdapter
                                    fieldName="availableTo"
                                    withEmptyLabel
                                >
                                    <DatePickerControl
                                        disabledDate={disableDate()}
                                        formMode={formMode}
                                        disabled={disabled}
                                    />
                                </FormItemAdapter>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <FormItemAdapter
                                    fieldName="code"
                                    label={LOCAL.LABELS.EVENT_M_CODE}
                                    getValueFromEvent={(value) =>
                                        value.target.value.replace(
                                            /[^A-Za-zа-яА-Я0-9]/g,
                                            ''
                                        )
                                    }
                                >
                                    <InputControl
                                        formMode={formMode}
                                        maxLength={4}
                                        disabled={disabled}
                                    />
                                </FormItemAdapter>
                            </Col>
                        </Row>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <TooltipAdapter
                            title={getStringWithoutDots(MEDIA_DOCUMENT_TYPE)}
                        >
                            <FormItemAdapter
                                fieldName="activityPictureId"
                                label={LOCAL.LABELS.EVENT_M_PICTURE}
                                valuePropName="fileList"
                                getValueFromEvent={getLastFileFromList}
                                required
                            >
                                <UploadDraggerImageControl
                                    accept={MEDIA_DOCUMENT_TYPE}
                                    customRequest={uploadImage}
                                    beforeUpload={(file, fileList) =>
                                        validateUploadImage(
                                            file,
                                            fileList,
                                            PICTURE_VALIDATE_FORMATS,
                                            1
                                        )
                                    }
                                    disabled={
                                        isFormModeView(formMode) || disabled
                                    }
                                    className={styles.uploadImgArea}
                                />
                            </FormItemAdapter>
                        </TooltipAdapter>
                    </Col>
                </Row>
            </ControlGroup>
        )
    }
)
