import React from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { ControlGroup } from 'components/controls'
import { Form, Steps } from 'antd'
import { LOCAL } from 'core/local'
import {
    getAnswerFormType,
    isSubmitDisabled,
} from 'components/pages/QuestionnairesListener/components/QuestionnairePassingAnswer/QuestionnairePassingAnswer.utils'
import { isQuestionTypeFillEmptyText } from 'utils'

import styles from './QuestionnaireModalForm.module.scss'
import { QuestionnaireModalFormProps } from '../QuestionnaireModal.types'

const { Step } = Steps

/** Форма для опросного листа и теста в модалке */
export const QuestionnaireModalForm: React.FC<QuestionnaireModalFormProps> = React.memo(
    ({ form, handleQuestionAnswer, initialValues, currentIndex }) => {
        /**
         * Блоки с вопросами для их последовательного отображения
         */
        const questionSteps = initialValues?.questions?.map((question) => (
            <div key={question.id}>
                <p className={styles.questionTitle}>{question.title}</p>

                {!isQuestionTypeFillEmptyText(question.type) && (
                    <p className={styles.questionText}>{`${question.text}:`}</p>
                )}

                {question &&
                    getAnswerFormType({ question, isPassingInTrack: true })}
            </div>
        ))

        return (
            <Form
                form={form}
                onFinish={handleQuestionAnswer}
                id={styles.wrapper}
                className={styles.wrapper}
            >
                <ControlGroup
                    title={initialValues?.name}
                    className={styles.controlGroup}
                    noPadding
                >
                    <span className={styles.questionNumber}>
                        {`${currentIndex + 1}/${
                            initialValues?.questions?.length
                        }`}
                    </span>

                    {questionSteps?.[currentIndex]}
                </ControlGroup>

                <ButtonsToolbar align="space-between" bottomIndent="0">
                    <Steps
                        current={currentIndex}
                        className={styles.questionsPagination}
                    >
                        {questionSteps?.map((_, index) => (
                            <Step key={index} />
                        ))}
                    </Steps>

                    <Form.Item shouldUpdate>
                        {(form) => {
                            const currentQuestion =
                                initialValues?.questions?.[currentIndex]
                            const disabled = isSubmitDisabled(
                                currentQuestion?.type,
                                form
                            )

                            return (
                                !disabled && (
                                    <Button
                                        htmlType="submit"
                                        form={styles.wrapper}
                                    >
                                        {LOCAL.ACTIONS.ANSWER}
                                    </Button>
                                )
                            )
                        }}
                    </Form.Item>
                </ButtonsToolbar>
            </Form>
        )
    }
)
