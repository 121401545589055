import React, { useCallback } from 'react'
import {
    AssessmentProjectPositionProfileContract,
    AssessmentProjectService,
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import { CoworkersFilters, getCoworkersTableColumns } from 'components/controls'
import { DynamicTableAdapter } from 'components/shared'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { WithFormModeProps } from 'App.types'
import { renderExpandIcon } from 'utils'

import styles from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.module.scss'
import { AssessmentProjectsCoworkerCompetencies } from '../AssessmentProjectsCoworkerCompetencies'
import { UserFormContract } from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.types'
import { getCoworkersColumunsManagerEstimate } from './AssessmentProjectsCoworkers.utils'
import { normalizeFetchDataForValue } from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.utils'

/** Компонент добавления сотрудников для оценки руководителем */
export const AssessmentProjectsCoworkers: React.FC<WithFormModeProps> = React.memo(
    ({ formMode }) => {
        /** Рендер вложенной структуры компетенций сотрудника */
        const renderExpandedRow = useCallback(
            (record: UserFormContract, index: number) => (
                <Form.Item name={['managerParameters', 'coworkers', index]}>
                    <AssessmentProjectsCoworkerCompetencies
                        formMode={formMode}
                    />
                </Form.Item>
            ),
            [formMode]
        )

        return (
            <Form.Item name={['managerParameters', 'coworkers']}>
                <DynamicTableAdapter<
                    AssessmentProjectPositionProfileContract,
                    UserFormContract,
                    UserModalSearchContract,
                    UserModalContract
                >
                    className={styles.control}
                    withSpoiler
                    getEntitiesByIds={
                        AssessmentProjectService.getPositionProfileCompetences
                    }
                    searchMethod={UsersService.getForModal}
                    modalFilterComponent={CoworkersFilters}
                    columnsForSearchModal={getCoworkersTableColumns()}
                    renderTableColumns={getCoworkersColumunsManagerEstimate}
                    title={LOCAL.LABELS.EMPLOYEES}
                    normalizeFn={normalizeFetchDataForValue}
                    modalTitle={LOCAL.LABELS.EMPLOYEE_SELECTION}
                    formMode={formMode}
                    tableOptions={{
                        expandable: {
                            expandIcon: renderExpandIcon,
                            expandedRowRender: renderExpandedRow,
                        },
                    }}
                    required
                />
            </Form.Item>
        )
    }
)
