import { ChartOptions, InteractionMode } from 'chart.js'
import { LOCAL } from 'core/local'

import styles from './SpiderWebDiagramAssessment360.module.scss'
import { DatasetItemConfigProps } from './SpiderWebDiagramAssessment360.types'

/** Набор визуальных настроек диаграммы "паутинка"/"радар" */
export const radarChartOptions: ChartOptions = {
    hover: {
        mode: 'point' as InteractionMode,
    },
    elements: {
        line: {
            borderWidth: 1,
        },
        point: {
            radius: 1,
        },
    },
    scales: {
        r: {
            suggestedMin: 0,
            suggestedMax: 5,
            ticks: {
                stepSize: 0.5,
            },
        },
        ticks: {
            display: false,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
    maintainAspectRatio: false,
}

/** Набор объектов с настройками для создания dataset'а диаграммы типа "паутинка"/"радар" */
export const DATASET_CONFIG: DatasetItemConfigProps[] = [
    {
        dataKey: 'averageScoreSelf',
        label: LOCAL.LABELS.ASSESSMENT_SELF,
        borderColor: '#67B5EB',
        backgroundColor: '#67B5EB19',
        className: styles.averageScoreSelf,
    },
    {
        dataKey: 'averageScoreCollegue',
        label: LOCAL.LABELS.COLLEAGUES,
        borderColor: '#E2976D',
        backgroundColor: '#E2976D19',
        className: styles.averageScoreCollegue,
    },
    {
        dataKey: 'averageScoreSubordinate',
        label: LOCAL.LABELS.SUBORDINATES,
        borderColor: '#4FA84D',
        backgroundColor: '#4FA84D19',
        className: styles.averageScoreSubordinate,
    },
    {
        dataKey: 'averageScoreManager',
        label: LOCAL.LABELS.MANAGER,
        borderColor: '#CE30B5',
        backgroundColor: '#CE30B519',
        className: styles.averageScoreManager,
    },
    {
        dataKey: 'averageScoreWithoutSelfEstim',
        label: LOCAL.LABELS.ALL_RESPONDENTS,
        borderColor: '#FFE600',
        backgroundColor: '#FFE60019',
        className: styles.averageScoreWithoutSelfEstim,
    },
]

export const LABELS_CHECKED = {
    averageScoreSelf: true,
    averageScoreCollegue: true,
    averageScoreSubordinate: true,
    averageScoreManager: true,
    averageScoreWithoutSelfEstim: true,
}
