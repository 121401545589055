import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import { DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED, ROW_GUTTER } from 'consts'
import { DictionariesCommonProps } from 'App.types'
import { FilterForm } from 'components/shared/FilterForm'
import {
    FiltersProps,
    getPopupContainer,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import {
    NotificationDestinationCategoriesService,
    NotificationDestinationSubCategoriesService,
    NotificationDestinationSubCategoryContract,
    NotificationMailingTaskAppointmentObjectSearchContract,
    StructuralUnitsService,
} from 'core/api'
import { SelectControl, TreeSelectControl } from 'components/controls'
import { useDictionaries, useHttp } from 'hooks'

/**
 * Форма фильтрации назначений
 */
export const AppointmentObjectsSelectionFilter: React.FC<FiltersProps<
    NotificationMailingTaskAppointmentObjectSearchContract
>> = React.memo(({ onSetFilters, tableFiltersOptions, ...props }) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<'organizations' | 'categories' | 'departments'>
    >()

    const [fetchSubCategoriesByCategoryId, subCategories] = useHttp<
        NotificationDestinationSubCategoryContract[]
    >(NotificationDestinationSubCategoriesService.getForSelectByCategory)

    useEffect(() => {
        handleFetchDictionaries({
            organizations: [
                StructuralUnitsService.getAllStructuralUnits(),
                'treeSelect',
            ],
            categories: NotificationDestinationCategoriesService.getForSelect(),
        })
    }, [handleFetchDictionaries])

    useEffect(() => {
        fetchSubCategoriesByCategoryId({
            categoryId: tableFiltersOptions?.initialValues?.categoryId,
        })
    }, [fetchSubCategoriesByCategoryId, tableFiltersOptions])

    return (
        <FilterForm
            {...props}
            onSetFilters={onSetFilters}
            tableFiltersOptions={tableFiltersOptions}
        >
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="categoryId"
                        label={LOCAL.LABELS.MAILING_CATEGORY_TYPE}
                    >
                        <SelectControl
                            values={dictionaries.categories}
                            disabled
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="statuses"
                        label={LOCAL.LABELS.STATUS}
                    >
                        <SelectControl
                            mode="multiple"
                            values={DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED}
                            allowClear
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    {!!subCategories?.length && (
                        <FormItemAdapter
                            fieldName="subCategoryId"
                            label={LOCAL.LABELS.MAILING_CATEGORY_SUBTYPE}
                        >
                            <SelectControl
                                values={subCategories.map(
                                    normalizeDataForSelectAndRadio
                                )}
                                allowClear
                                showSearch
                                disabled={
                                    !!tableFiltersOptions?.initialValues
                                        ?.subCategoryId
                                }
                            />
                        </FormItemAdapter>
                    )}
                </Col>

                <Col xs={16}>
                    <FormItemAdapter
                        fieldName="organizationIds"
                        label={LOCAL.LABELS.ORGANIZATIONS}
                    >
                        <TreeSelectControl
                            treeData={dictionaries.organizations}
                            placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            showSearch
                            multiple
                            getPopupContainer={getPopupContainer}
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    )
})
