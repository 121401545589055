import { DocumentStatus } from 'core/api'

/**
 * Проверка статуса документа/записи
 */
export const isDocumentStatusDraft = (status?: DocumentStatus) =>
    status === DocumentStatus.Draft
export const isDocumentStatusReadyForWork = (status?: DocumentStatus) =>
    status === DocumentStatus.ReadyForWork
export const isDocumentStatusInWork = (status?: DocumentStatus) =>
    status === DocumentStatus.InWork
