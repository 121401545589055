import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    MultiSelectControl,
    TreeSelectControl,
} from 'components/controls'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import {
    PositionsService,
    StructuralUnitsService,
    UsersSearchContract,
} from 'core/api'
import { ROW_GUTTER } from 'consts'
import { useDictionaries } from 'hooks'

import { DictionariesProps } from './CoworkersFilters.types'

/**
 * Форма фильтрации сотрудников
 */
export const CoworkersFilters: React.FC<FiltersProps<
    UsersSearchContract
>> = React.memo((props) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesProps
    >()

    useEffect(() => {
        handleFetchDictionaries({
            positions: PositionsService.getAll(),
            structuralUnits: StructuralUnitsService.getAllStructuralUnits(),
        })
    }, [handleFetchDictionaries])

    return (
        <ControlGroup type="light">
            <FilterForm {...props}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="fullName"
                            label={LOCAL.LABELS.FIO}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitsIds"
                            label={LOCAL.LABELS.ORGANIZATION}
                        >
                            <TreeSelectControl
                                treeData={dictionaries?.structuralUnits || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="positionsIds"
                            label={LOCAL.LABELS.POSITION}
                        >
                            <MultiSelectControl
                                treeData={dictionaries?.positions}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'modifiedDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'modifiedDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
