import cn from 'classnames'
import shortid from 'shortid'
import React, { useCallback, useMemo, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts/layout.consts'
import { Popup } from 'components/shared/Popup'
import { RowSelectionType } from 'antd/lib/table/interface'
import { Select } from 'antd'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import { getValue } from 'utils/common.utils'
import { isFormModeView } from 'utils/conditions.utils'
import { useElementVisible } from 'hooks/useElementVisible'

import styles from './SearchSelectControl.module.scss'
import { ControlViewMode } from '../ControlViewMode'
import { SearchSelectControlProps } from './SearchSelectControl.types'
import { TableSearchForm } from './components'
import { normalizeResponse } from './SearchSelectControl.utils'

/**
 * Универсальный компонент поиска: селект + модальное окно
 */
function SearchSelectControl<
    Request extends object,
    Response extends { id: number }
>({
    value,
    onChange,
    tableSearchOptions,
    getByIdsMethod,
    okButtonText = LOCAL.ACTIONS.ADD,
    modalTitle = LOCAL.LABELS.SEARCH,
    selectMode,
    allowClearSelect = true,
    disabled,
    addonAfter,
    addonAfterColumn,
    showArrow = false,
    className,
    formMode,
    onBeforeChange,
}: SearchSelectControlProps<Request, Response>) {
    const [formId] = useState(shortid.generate())

    const [modalVisibility, setModalVisibility] = useState(false)
    const {
        updateElementVisible: updateLoader,
        elementVisible: isLoading,
    } = useElementVisible()

    /**
     * Обработчик закрытия модального окна
     */
    const handleCloseModal = useCallback(() => setModalVisibility(false), [])

    /**
     * Обработчик открытия модального окна
     */
    const handleShowModal = useCallback(() => {
        const startTrigger = () => {
            setModalVisibility(true)
        }

        if (onBeforeChange) {
            onBeforeChange({ onChange: startTrigger })
        } else {
            setModalVisibility(true)
        }
    }, [onBeforeChange])

    /**
     * Инициировать обновление состояние компонента, а также значения внешней формы
     */
    const triggerChanges = useCallback(
        (value) => {
            onChange?.(value)
        },
        [onChange]
    )

    /**
     * Запрос дополнительной информации из справочника по идентификаторам сущностей
     */
    const dictionaryFetch = useCallback(
        async (request?: number[]) => {
            try {
                updateLoader(true)

                const result = await getByIdsMethod({
                    body: request,
                })

                if (!result) return []

                triggerChanges(
                    normalizeResponse(Array.isArray(result) ? result : [result])
                )
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        [getByIdsMethod, triggerChanges, updateLoader]
    )

    /**
     * Обработчик добавления значений из таблицы в `Select`
     */
    const handleSelectAddOptions = useCallback(
        (result?: number[]) => {
            handleCloseModal()
            dictionaryFetch(result)
        },
        [dictionaryFetch, handleCloseModal]
    )

    /**
     * Обработчик изменения `Select`
     */
    const handleSelectChange = useCallback(
        (value) => {
            const startTrigger = () => {
                triggerChanges(value)
            }

            if (onBeforeChange) {
                onBeforeChange({
                    onChange: startTrigger,
                })
            } else {
                startTrigger()
            }
        },
        [onBeforeChange, triggerChanges]
    )

    /** Объект с пропсами-настройками для TableSearchForm */
    const mergedTableSearchOptions = useMemo(
        () => ({
            id: formId,
            rowSelectionType:
                selectMode === 'multiple'
                    ? ('checkbox' as RowSelectionType)
                    : ('radio' as RowSelectionType),
            initialValues: value?.map(getValue) || [],
            onRequestFinish: handleSelectAddOptions,
            ...tableSearchOptions,
        }),
        [tableSearchOptions, selectMode, value, handleSelectAddOptions, formId]
    )

    const calculatedValue = useMemo(
        () => (value === null ? undefined : value),
        [value]
    )

    /**
     * Классы для компонента
     */
    const searchSelectClasses = cn(
        isFormModeView(formMode) && 'view-mode',
        Boolean(addonAfter) && styles.wrapperAddonAfter,
        allowClearSelect && styles.wrapperAllowClear,
        'form-control',
        styles.wrapper
    )

    if (!selectMode && isFormModeView(formMode))
        return <ControlViewMode value={value} addonAfter={addonAfter} />

    return (
        <>
            <div className={searchSelectClasses}>
                <div className={styles.container}>
                    <Select
                        open={false}
                        notFoundContent={LOCAL.LABELS.SELECT_FROM_DIRECTORY}
                        value={calculatedValue}
                        labelInValue
                        placeholder={
                            isFormModeView(formMode)
                                ? LOCAL.PLACEHOLDERS.INPUT_EMPTY
                                : LOCAL.PLACEHOLDERS.SELECT
                        }
                        onChange={handleSelectChange}
                        allowClear={allowClearSelect}
                        mode={selectMode}
                        disabled={disabled || isFormModeView(formMode)}
                        showArrow={showArrow}
                        className={className}
                    />

                    {!isFormModeView(formMode) && (
                        <AddButton
                            onlyIcon
                            onClick={handleShowModal}
                            disabled={disabled || isLoading}
                            loading={isLoading}
                            className={styles.addButton}
                        />
                    )}
                </div>

                {addonAfter && (
                    <div className={styles.addonAfterContent}>{addonAfter}</div>
                )}

                {addonAfterColumn && <div>{addonAfterColumn}</div>}
            </div>

            <Popup
                formId={formId}
                visible={modalVisibility}
                onCancel={handleCloseModal}
                destroyOnClose
                title={modalTitle}
                okText={okButtonText}
                width={MODAL_WIDTH.LG}
                centered
                getContainer={getDropdownPopupContainerDefault}
            >
                <TableSearchForm<Request, Response>
                    {...mergedTableSearchOptions}
                />
            </Popup>
        </>
    )
}

export default React.memo(SearchSelectControl) as typeof SearchSelectControl
