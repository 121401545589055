import React from 'react'
import template from 'lodash/template'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import {
    checkAllRequiredBlocksPassed,
    isActivityLessonCompletedSuccessfully,
} from 'utils'

import styles from './ProgressEventPassing.module.scss'
import { ProgressEventPassingProps } from './ProgressEventPassing.types'
import { ProgressLine } from './components'

/** Прогресс прохождения мероприятия */
export const ProgressEventPassing: React.FC<ProgressEventPassingProps> = React.memo(
    ({ activityLessonPassings, activityStatus, ratingInfo }) => {
        /**
         * Кол-во успешно пройденных тематических блоков
         */
        const lessonCompletedCount = activityLessonPassings?.filter((el) =>
            isActivityLessonCompletedSuccessfully(el.status)
        ).length

        const isRequiredLessonCompleted = checkAllRequiredBlocksPassed(
            activityLessonPassings
        )

        return (
            <div className={styles.wrapper}>
                {isRequiredLessonCompleted &&
                    isActivityLessonCompletedSuccessfully(activityStatus) && (
                        <div className={styles.completedSuccessful}>
                            <IconsAdapter iconType="CustomIconCheckFilled" />

                            {`${LOCAL.MESSAGES.EVENT_SUCCESSFUL_COMPLETE}${
                                ratingInfo
                                    ? `: ${template(
                                          LOCAL.MESSAGES.YOU_SCORED_POINTS
                                      )({
                                          accrued: ratingInfo.accuredPoints,
                                          total: ratingInfo.totalPoints,
                                      })}`
                                    : ''
                            }`}
                        </div>
                    )}

                <ProgressLine
                    activityLessonPassings={activityLessonPassings}
                    lessonCompletedCount={lessonCompletedCount}
                />
            </div>
        )
    }
)
