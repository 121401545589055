import React, { FC, useCallback } from 'react'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import { DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS } from 'consts'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CREATION_DATE,
} from 'components/layouts'
import {
    DocumentsPageEntitiesCountContract,
    IRequestOptions,
    LdeReportContract,
    LdeReportSearchContract,
    LdeReportsService,
    TextDocumentsService,
    UserPermission,
} from 'core/api'
import { LOCAL } from 'core/local/local.config'
import { PageTabs } from 'components/shared'
import { getPageTitleFromRoute } from 'utils'
import { getTabsData } from 'components/pages/DocumentsEditor/DocumentsEditor.config'
import { useLocation } from 'react-router-dom'
import { useTabsTotalCount } from 'hooks'

import { REPORTS_TABLE_COLUMNS } from './Reports.consts'
import { ReportCreateForm, ReportsFilters } from './components'

/** Реестр отчетов */
export const Reports: FC = React.memo(() => {
    const location = useLocation()
    const title = getPageTitleFromRoute(location.pathname)

    const { tabsTotalCount } = useTabsTotalCount<
        IRequestOptions,
        DocumentsPageEntitiesCountContract
    >({
        tabsTotalCountMethod: TextDocumentsService.getDocumentPageEntitiesCount,
    })

    /** Колбэк рендера всех табов на странице */
    const renderTabs = useCallback(
        (recordsCount?: number) => (
            <PageTabs
                tabsData={getTabsData({
                    recordsCount,
                    tabsTotalCount,
                    activeTab: 'reports',
                })}
                defaultTabKey="reports"
            />
        ),
        [tabsTotalCount]
    )

    return (
        <DictionaryPage<LdeReportSearchContract, LdeReportContract>
            dictionaryKey={DICTIONARY_KEYS.REPORTS}
            searchMethod={LdeReportsService.search}
            deleteMethod={LdeReportsService.delete}
            contentTitle={title}
            tableColumns={REPORTS_TABLE_COLUMNS}
            createRecordModal={{
                component: ReportCreateForm,
                formId: FORM_IDS.REPORT_CREATE_FORM,
                modalOptions: {
                    title: LOCAL.LABELS.ADDING_REPORT,
                    footer: null,
                },
            }}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES_WITH_CREATION_DATE,
                },
            }}
            createPermissions={[
                ...DOCUMENTS_EDITOR_REQUIRED_PERMISSIONS,
                UserPermission.TextDocumentSave,
            ]}
            filterComponent={ReportsFilters}
            quickSearchMethod={LdeReportsService.searchText}
            pageTabsRenderCustom={renderTabs}
        />
    )
})
