import React, { SyntheticEvent } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { SpoilerTitleRenderProps } from 'components/shared/Spoiler/Spoiler.types'
import { isFormModeView } from 'utils'

import styles from './CompetenciesPanel.module.scss'
import { CompetenceGroupHeaderProps } from './CompetenciesPanel.types'

/**
 * Получить заголовок для группы компетенций
 * @param groupId id группы компетенций
 * @param groupName наименование группы компетенций
 * @param competenceGroupsIds массив из id групп компетенций
 * @param formMode режим формы
 * @param form
 * @return заголовок для группы компетенций
 */
export const renderSpoilerTitle = ({
    groupId,
    groupName,
    competenceGroupsIds,
    formMode,
    form,
}: CompetenceGroupHeaderProps) => ({
    icon,
    toggleSpoilerVisible,
}: SpoilerTitleRenderProps) => {
    const removeFunction = (e: SyntheticEvent) => {
        e.stopPropagation()
        form?.setFieldsValue({
            competenceGroupsIds: competenceGroupsIds?.filter(
                (id) => id !== groupId
            ),
        })
    }

    return (
        <>
            <a
                href="/#"
                onClick={toggleSpoilerVisible}
                className={styles.title}
            >
                <span className={styles.groupName}>{groupName}</span>

                {icon}
            </a>

            {!isFormModeView(formMode) &&
                competenceGroupsIds?.includes(groupId) && (
                    <IconsAdapter
                        iconType="DeleteOutlined"
                        className={styles.deleteButton}
                        onClick={removeFunction}
                        title={LOCAL.ACTIONS.DELETE}
                    />
                )}
        </>
    )
}
