import cn from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
    Button,
    ContentTitle,
    FiltersToggleButton,
    PageBanner,
    PageContent,
    QuickSearch,
    SpaceAdapter,
} from 'components/shared'
import { Col, Collapse, Divider, Row } from 'antd'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    GlossariesService,
    GlossarySectionContract,
    UserPermission,
} from 'core/api'
import { HasPermissions } from 'core/permissions'
import {
    KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
    PAGE_SIZE_ALL,
    ROW_GUTTER,
} from 'consts'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    RequestQueryProps,
    useFilter,
    usePageSettings,
    useSearchQuery,
} from 'hooks'
import {
    createSuccessNotification,
    getItemId,
    getPageTitleFromRoute,
    showElem,
} from 'utils'
import { useLocation } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './KnowledgeBaseFormContainer.module.scss'
import {
    KnowledgeBaseCard,
    KnowledgeBaseFilters,
    SectionActions,
} from './components'

const { Panel } = Collapse

/** Реестр База знаний */
export const KnowledgeBase: FC = withLoader(
    React.memo(({ updateLoader }) => {
        const { saveFilters } = usePageSettings(DICTIONARY_KEYS.KNOWLEDGE_BASE)
        const [knowledgeBaseData, setKnowledgeBaseData] = useState<
            GlossarySectionContract[]
        >()
        const location = useLocation()
        const title = getPageTitleFromRoute(location.pathname)

        const {
            queryParams,
            handleSetFilters,
            handleResetFilters,
        } = useSearchQuery({
            dictionaryKey: DICTIONARY_KEYS.KNOWLEDGE_BASE,
            queryOptions: {
                body: {
                    // TODO: поменяется в рамках SDO-5862
                    pageSize: PAGE_SIZE_ALL,
                },
            },
        })

        const [visibleFilter, triggerFilterVisibility] = useFilter()

        /**
         * Запрос данных справочника
         */
        const dictionaryFetch = useCallback(async () => {
            try {
                updateLoader(true)

                const dataSource = queryParams.body?.searchText
                    ? await GlossariesService.searchText(
                          queryParams as Required<RequestQueryProps>
                      )
                    : await GlossariesService.search(
                          queryParams as Required<RequestQueryProps>
                      )

                setKnowledgeBaseData(dataSource)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader, queryParams])

        const handleSectionDelete = useCallback(
            async (sectionId: number) => {
                try {
                    updateLoader(true)

                    await GlossariesService.deleteSection({ sectionId })

                    dictionaryFetch()
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [dictionaryFetch, updateLoader]
        )

        const getSection = (section: GlossarySectionContract) => (
            <Panel
                header={<h1>{section.name}</h1>}
                key={section.id}
                extra={
                    <SectionActions
                        section={section}
                        onSectionDelete={handleSectionDelete}
                        onDictionaryFetch={dictionaryFetch}
                    />
                }
            >
                <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
                    {section.articles?.pageItems.map((article) => (
                        <Col key={article.id} xs={24} xl={12} xxl={8}>
                            <KnowledgeBaseCard
                                article={article}
                                onDictionaryFetch={dictionaryFetch}
                                updateLoader={updateLoader}
                            />
                        </Col>
                    ))}
                </Row>

                <Divider />
            </Panel>
        )

        /**
         * Создание контента для секции
         */
        const sections = !!knowledgeBaseData && (
            <Collapse
                ghost
                defaultActiveKey={knowledgeBaseData?.map(getItemId)}
                className={styles.section}
            >
                {knowledgeBaseData?.map(getSection)}
            </Collapse>
        )

        useEffect(() => {
            dictionaryFetch()
        }, [dictionaryFetch])

        useEffect(() => {
            if (saveFilters) {
                createSuccessNotification(LOCAL.MESSAGES.SAVE_FILTERS)
            }
        }, [saveFilters])

        return (
            <PageContent filled>
                <PageBanner />

                <ContentTitle title={title} className={styles.title}>
                    <SpaceAdapter size={30}>
                        <QuickSearch
                            onSetFilters={handleSetFilters}
                            isReset={visibleFilter}
                        />

                        <FiltersToggleButton
                            onToggleFilterVisibility={triggerFilterVisibility}
                            isActive={saveFilters}
                        />

                        {!knowledgeBaseData?.some(
                            (el) => el.articles?.total
                        ) && (
                            <HasPermissions
                                requiredPermissions={[
                                    ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                                    UserPermission.KnowledgeBaseArticleSave,
                                ]}
                            >
                                <Button
                                    href={ROUTE_NAMES.KNOWLEDGE_BASE_CREATE}
                                >
                                    {LOCAL.ACTIONS.CREATE_ARTICLE}
                                </Button>
                            </HasPermissions>
                        )}
                    </SpaceAdapter>
                </ContentTitle>

                <div className={cn(showElem(visibleFilter), styles.filter)}>
                    <KnowledgeBaseFilters
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                        dictionaryKey={DICTIONARY_KEYS.KNOWLEDGE_BASE}
                    />
                </div>

                {sections}
            </PageContent>
        )
    })
)
