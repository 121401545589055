import React, { FC, memo } from 'react'

import styles from './UserAssignmentPassingForm.module.scss'
import { PassingButtons } from '../PassingButtons'
import { PassingCompetence } from '../PassingCompetence'
import { UserAssignmentPassingFormProps } from './UserAssignmentPassingForm.types'

/** Форма прохождения с кнопками */
export const UserAssignmentPassingForm: FC<UserAssignmentPassingFormProps> = memo(
    ({
        isFormTouched,
        canComplete,
        setActiveCompetenceContract,
        activeCompetenceContract,
        competences,
        onComplete,
        questionnaire,
    }) => {
        const isByBlock =
            questionnaire?.assessmentPortfolioPresentation?.showQuestionsByBlock

        return (
            <div className={styles.wrapper}>
                {isByBlock ? (
                    <PassingCompetence {...activeCompetenceContract} />
                ) : (
                    competences.map((competence) => (
                        <PassingCompetence
                            {...competence}
                            key={competence.competence.id}
                        />
                    ))
                )}

                <PassingButtons
                    competenceContracts={competences}
                    activeCompetenceContract={activeCompetenceContract}
                    setActiveCompetenceContract={setActiveCompetenceContract}
                    isByBlock={isByBlock}
                    isFormTouched={isFormTouched}
                    canComplete={canComplete}
                    onComplete={onComplete}
                />
            </div>
        )
    }
)
