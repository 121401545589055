import React, { useMemo } from 'react'
import { VideoContentModal } from 'components/shared'
import { useElementVisible } from 'hooks'

import styles from './TrackInfoMenu.module.scss'
import { TrackInfoMenuVideoProps } from './TrackInfoMenu.types'

/** Видео контент в спойлере дополнительной информации о треке */
export const TrackInfoMenuVideo: React.FC<TrackInfoMenuVideoProps> = React.memo(
    ({ launchUrl, children }) => {
        const {
            elementVisible,
            handleHideElement,
            handleShowElement,
        } = useElementVisible()

        const popupProps = useMemo(
            () => ({
                visible: elementVisible,
                onCancel: handleHideElement,
            }),
            [elementVisible, handleHideElement]
        )

        return (
            <>
                <div className={styles.item} onClick={handleShowElement}>
                    {children}
                </div>

                <VideoContentModal
                    popupProps={popupProps}
                    fileUrl={launchUrl}
                    autoPlay
                />
            </>
        )
    }
)
