import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ANIMATION_TIME_OUT_DEFAULT } from 'consts'
import { CSSTransition } from 'react-transition-group'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'

import styles from './Spoiler.module.scss'
import { SpoilerProps } from './Spoiler.types'
import { spoilerTitleRenderDefault } from './Spoiler.utils'

/**
 * Спойлер
 */
export const Spoiler: React.FC<SpoilerProps> = React.memo(
    ({
        children,
        className,
        title,
        defaultVisible,
        visible,
        onChangeVisible,
        spoilerTitleRender = spoilerTitleRenderDefault,
        iconPosition = 'right',
        bordered,
        disabled,
    }) => {
        /**
         * Управление состоянием видимости спойлера
         */
        const [spoilerVisible, setSpoilerVisible] = useState(defaultVisible)

        /**
         * Переключатель видимости содержимого спойлера
         */
        const toggleSpoilerVisible = useCallback(
            (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault()

                if (disabled) return

                setSpoilerVisible((prevValue) => {
                    const nextValue = !prevValue
                    onChangeVisible?.(nextValue)

                    return nextValue
                })
            },
            [onChangeVisible, disabled]
        )

        /**
         * Заголовок спойлера
         */
        const spoilerTitle = useMemo(() => {
            if (title) return title

            return spoilerVisible ? LOCAL.ACTIONS.HIDE : LOCAL.ACTIONS.SHOW
        }, [spoilerVisible, title])

        useEffect(() => {
            if (visible !== undefined) {
                setSpoilerVisible(visible)
            }
        }, [visible])

        return (
            <div className={cn(styles.wrapper, className)}>
                <h3
                    className={cn(
                        styles.header,
                        spoilerVisible && styles.headerActive
                    )}
                >
                    {spoilerTitleRender({
                        text: <span>{spoilerTitle}</span>,
                        icon: (
                            <IconsAdapter
                                iconType="DownOutlined"
                                className={cn(
                                    styles.icon,
                                    iconPosition === 'left' && styles.iconLeft
                                )}
                            />
                        ),
                        iconPosition,
                        toggleSpoilerVisible,
                        visible: spoilerVisible,
                    })}
                </h3>

                <CSSTransition
                    in={spoilerVisible}
                    timeout={ANIMATION_TIME_OUT_DEFAULT}
                    classNames="animation-fade-unmount"
                    unmountOnExit
                >
                    <div
                        className={cn(
                            styles.body,
                            bordered && styles.bodyBordered
                        )}
                    >
                        {children}
                    </div>
                </CSSTransition>
            </div>
        )
    }
)
