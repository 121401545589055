import React, { FC, useEffect, useState } from 'react'
import { FeedbackQuizReportsService } from 'core/api'
import { TableAdapter } from 'components/shared'
import { UrlParamsCommonProps } from 'App.types'
import { useParams } from 'react-router-dom'
import { useSearchQuery } from 'hooks/useSearchQuery'

import styles from './TabContent.module.scss'
import {
    FreeAnswerTableRowProps,
    QuestionsColumnsProps,
    TabContentProps,
} from './TabContent.types'
import { freeAnswerTableColsUtils, getDataSource } from './TabContent.utils'

export const TabContent: FC<TabContentProps> = React.memo(
    ({ tabType, activeKey }) => {
        const urlParams = useParams<UrlParamsCommonProps<'id'>>()
        const [dataSource, setDataSource] = useState<FreeAnswerTableRowProps>()
        const [questionsColumns, setQuestionsColumns] = useState<
            QuestionsColumnsProps[]
        >()
        const { paginationOptions, queryParams } = useSearchQuery({})

        useEffect(() => {
            if (tabType !== activeKey) return

            const fetchDataSource = async (id: number) => {
                const data = await FeedbackQuizReportsService.getFreeAnswersTab(
                    {
                        appointmentId: id,
                        pageSize: queryParams.pageSize,
                        pageNumber: queryParams.pageNumber,
                        feeAnswerTabType: tabType,
                    }
                )

                setQuestionsColumns(
                    data?.columns
                        .map((el, index) => ({
                            number: index,
                            data: el,
                        }))
                        .filter((el) => el.number !== 0)
                )
                setDataSource(getDataSource(data.questionsTable, styles.answer))
            }

            fetchDataSource(Number(urlParams.id))
        }, [
            urlParams.id,
            tabType,
            activeKey,
            queryParams.pageNumber,
            queryParams.pageSize,
        ])

        return (
            <TableAdapter
                wrapperClassName={styles.wrapper}
                columns={freeAnswerTableColsUtils.getTableColumns(
                    questionsColumns
                )}
                dataSource={dataSource?.normalizeData}
                expandable={{ defaultExpandAllRows: true }}
                scroll={{
                    y: 425,
                }}
                pagination={{
                    ...paginationOptions,
                    total: dataSource?.total,
                    current: queryParams.pageNumber,
                    pageSize: queryParams?.pageSize,
                }}
            />
        )
    }
)
