import React, { ReactText, useCallback, useState } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { isFormModeView } from 'utils'

import styles from './QuestionnaireSetRow.module.scss'
import { QuestionnaireQuestionsCount } from '../QuestionnaireQuestionsCount'
import {
    QuestionnaireSetRowProps,
    QuestionnaireSetRowValueProps,
} from './QuestionnaireSetRow.types'

/**
 * Список опросных листов для формы портфеля теста: один ряд
 */
export const QuestionnaireSetRow: React.FC<QuestionnaireSetRowProps> = React.memo(
    ({ removeRow, rowIndex, value, onChange, formMode, disabled }) => {
        const [rowState, setRowState] = useState<
            QuestionnaireSetRowValueProps | undefined
        >(
            value && {
                name: value.name,
                totalQuestionCount: value.totalQuestionCount,
                questionnaireId: value.questionnaireId,
            }
        )

        /**
         * Обработчик удаления опросного листа из списка
         */
        const handleRemoveRow = useCallback(() => {
            removeRow(rowIndex)
        }, [removeRow, rowIndex])

        /**
         * Триггер для изменения внешней формы
         * @param changedValue новые значения
         */
        const triggerChanges = useCallback(
            (changedValue) => {
                if (!value) return

                onChange?.({
                    ...rowState,
                    ...value,
                    ...changedValue,
                })
                setRowState((prevState) => ({
                    ...prevState,
                    ...changedValue,
                }))
            },
            [onChange, rowState, value]
        )

        /**
         * Обработчик изменения кол-ва случайных вопросов из опросного листа
         * @param value кол-во выбранных случайных вопросов
         */
        const handleRowChange = useCallback(
            (value?: ReactText) => {
                if (value === undefined) return

                triggerChanges({
                    countOfRandomQuestions: Number(value),
                })
            },
            [triggerChanges]
        )

        return (
            <div className={styles.wrapper}>
                <FormItemAdapter
                    label={!rowIndex ? LOCAL.LABELS.NAME : ''}
                    className={styles.name}
                >
                    {value?.name || LOCAL.NO}
                </FormItemAdapter>

                <FormItemAdapter
                    label={!rowIndex ? LOCAL.LABELS.QUESTIONS_FROM_SHEET : ''}
                    className={styles.count}
                    shouldUpdate
                >
                    {(form) => (
                        <QuestionnaireQuestionsCount
                            value={
                                value?.countOfRandomQuestions ||
                                rowState?.countOfRandomQuestions
                            }
                            onChange={handleRowChange}
                            isRandomQuestions={form.getFieldValue(
                                'takeRandomQuestions'
                            )}
                            totalQuestionCount={value?.totalQuestionCount}
                            formMode={formMode}
                            disabled={disabled}
                        />
                    )}
                </FormItemAdapter>

                <FormItemAdapter
                    label={!rowIndex ? LOCAL.LABELS.ACTIONS : ''}
                    className={styles.actions}
                >
                    <ButtonsToolbar className={styles.buttonsToolbar}>
                        <a
                            href={`${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW}/${value?.questionnaireId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconsAdapter iconType="EyeOutlined" />
                        </a>

                        <Button
                            type="link"
                            icon={<IconsAdapter iconType="DeleteOutlined" />}
                            onClick={handleRemoveRow}
                            disabled={isFormModeView(formMode) || disabled}
                        />
                    </ButtonsToolbar>
                </FormItemAdapter>
            </div>
        )
    }
)
