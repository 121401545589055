import cn from 'classnames'
import React, { FC, useCallback } from 'react'
import { ControlGroup } from 'components/controls'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { Form } from 'antd'
import { FormListOperation } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'

import stylesThemeBlockDraggable from '../ThemeBlockDraggable/ThemeBlockDraggable.module.scss'
import { EventFormStructureProps } from './EventFormStructure.types'
import { ThemeBlockDraggable } from '../ThemeBlockDraggable'
import { getStructureActionButtons } from './EventFormStructure.utils'

/** Вкладка структура формы мероприятий */
export const EventFormStructure: FC<EventFormStructureProps> = React.memo(
    ({ formMode, disabled }) => {
        /**
         * Обработчик изменения последовательного маршрута
         * @param move - коллбек для перемещения этапа внутри Form.List
         * @param result - объект события перетаскивания
         */
        const handleDragEnd = useCallback(
            (move: FormListOperation['move']) => (result: DropResult) => {
                if (!result.destination) {
                    return
                }

                move(result.source.index, result.destination.index)
            },
            []
        )

        return (
            <Form.List name="lessons">
                {(fields, { add, remove, move }) => (
                    <ControlGroup
                        title={LOCAL.LABELS.THEMATIC_BLOCKS_SETTING}
                        titleClassName="mb-25"
                        required
                    >
                        <DragDropContext onDragEnd={handleDragEnd(move)}>
                            <Droppable droppableId="droppable" type="group">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        className={cn(
                                            snapshot.isDraggingOver &&
                                                stylesThemeBlockDraggable.dragging
                                        )}
                                    >
                                        {fields.map((field, index) => (
                                            <ThemeBlockDraggable
                                                index={index}
                                                field={field}
                                                remove={remove}
                                                key={field.key}
                                                dndKey={`themeBlock-${field.key}`}
                                                formMode={formMode}
                                                disabled={disabled}
                                            />
                                        ))}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        {getStructureActionButtons({
                            addFn: add,
                            formMode,
                            disabled,
                        })}
                    </ControlGroup>
                )}
            </Form.List>
        )
    }
)
