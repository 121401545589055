import React from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared'
import { DictionaryPageActionsProps } from 'components/layouts'
import { LOCAL } from 'core/local'

import { RenderPageContentProps } from './QuizChecking.types'

export const renderPageContent = ({
    selectedRows,
    confirmQuiz,
}: RenderPageContentProps) => ({
    reFetchDictionary,
}: DictionaryPageActionsProps) => {
    const handleChangeStatus = async () => {
        await confirmQuiz({ body: { ids: selectedRows } })
        reFetchDictionary?.()
    }

    return (
        <ButtonsToolbar>
            <Button
                disabled={!selectedRows.length}
                onClick={handleChangeStatus}
            >
                {LOCAL.ACTIONS.CONFIRM}
            </Button>
        </ButtonsToolbar>
    )
}
