import contentTitleStyles from 'components/shared/ContentTitle/ContentTitle.module.scss'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    DocumentEditorView,
    IconsAdapter,
    SpaceAdapter,
} from 'components/shared'
import { HomeworkStatusType, HomeworksService } from 'core/api'
import { LOCAL } from 'core/local'
import {
    LdeEditorInitialMode,
    LdeEditorRoles,
    UrlParamsCommonProps,
} from 'App.types'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { isHomeworkOnChecking } from 'utils'
import { useHistory, useParams } from 'react-router'
import { withHeightResize, withLoader } from 'HOCs'

import styles from './CheckHomeworkProcess.module.scss'

/**
 * Компонент для отображения домашнего задания для проверяющего
 */
export const CheckHomeworkProcess: FC = withHeightResize(
    withLoader(({ updateLoader, componentHeight: height, componentRef }) => {
        const [ldeId, setLdeId] = useState<string>()
        const [homeworkStatus, setHomeworkStatus] = useState<
            HomeworkStatusType
        >()
        const { id } = useParams<UrlParamsCommonProps<'id'>>()
        const history = useHistory()

        const editorConfig = useMemo(
            () => ({
                documentId: ldeId,
                initialMode: !isHomeworkOnChecking(homeworkStatus)
                    ? LdeEditorInitialMode.View
                    : LdeEditorInitialMode.Edit,
                roles: [LdeEditorRoles.Tutor],
                spelling: true,
            }),
            [homeworkStatus, ldeId]
        )

        const handleFinishCheckHomework = useCallback(
            (isPassed: boolean) => async () => {
                try {
                    if (!id) return

                    updateLoader(true)

                    await HomeworksService.finishCheck({
                        id: +id,
                        body: {
                            isPassed,
                        },
                    })

                    Modal.success({
                        icon: '',
                        content: isPassed
                            ? LOCAL.MESSAGES.HOMEWORK_PASSED
                            : LOCAL.MESSAGES.HOMEWORK_SENT_FOR_REVISION,
                        onOk: () => history.push(ROUTE_NAMES.PA_CHECK_HOMEWORK),
                        okText: LOCAL.ACTIONS.CLOSE,
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [history, id, updateLoader]
        )

        useEffect(() => {
            if (!id) return

            const fetchHomeworkInfo = async () => {
                const { status } = await HomeworksService.info({
                    id: +id,
                })

                if (!isHomeworkOnChecking(status)) return

                const { ldeId } = await HomeworksService.startCheck({
                    id: +id,
                })

                setLdeId(ldeId)
                setHomeworkStatus(status)
            }

            fetchHomeworkInfo()
        }, [homeworkStatus, id])

        return (
            <section
                className={styles.wrapper}
                ref={componentRef}
                style={{ height }}
            >
                <SpaceAdapter
                    align="center"
                    justifyContent="space-between"
                    fullWidth
                    className="mb-20"
                >
                    <div className={contentTitleStyles.backLink}>
                        <Link to={ROUTE_NAMES.PA_CHECK_HOMEWORK}>
                            <IconsAdapter iconType="ArrowLeftOutlined" />

                            {LOCAL.ACTIONS.RETURN}
                        </Link>
                    </div>

                    <ButtonsToolbar bottomIndent="0">
                        <Button
                            type="primary"
                            onClick={handleFinishCheckHomework(false)}
                            disabled={!isHomeworkOnChecking(homeworkStatus)}
                        >
                            {LOCAL.ACTIONS.FOR_REVISION}
                        </Button>

                        <Button
                            type="primary"
                            onClick={handleFinishCheckHomework(true)}
                            disabled={!isHomeworkOnChecking(homeworkStatus)}
                        >
                            {LOCAL.STATUSES.CREDIT_RECEIVED}
                        </Button>
                    </ButtonsToolbar>
                </SpaceAdapter>

                {ldeId && <DocumentEditorView editorConfig={editorConfig} />}
            </section>
        )
    })
)
