import React from 'react'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'

import styles from './TabReviewHeader.module.scss'

/** Компонент хедер для вкладки "проверка" страницы отчета по тестам */
export const TabReviewHeader: React.FC = React.memo(() => (
    <header className={styles.wrapper}>
        <Row className={styles.row}>
            <Col className={styles.icon} flex="1 1 50px">
                <IconsAdapter iconType="InfoCircleSerif" />
            </Col>

            <Col flex="35 1 500px">
                {LOCAL.MESSAGES.TAB_REVIEW_INSTRUCTION}

                <br />

                {LOCAL.MESSAGES.TAB_REVIEW_AFTER_INSTRUCTION}
            </Col>

            <Col className={styles.actions} flex="2 1 100px">
                <Button htmlType="submit" type="primary">
                    {LOCAL.ACTIONS.CALCULATE}
                </Button>
            </Col>
        </Row>
    </header>
))
