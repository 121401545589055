import React from 'react'
import { Form } from 'antd'
import { FormItemAdapter, HiddenField, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { VALIDATE_MESSAGE_DEFAULT } from 'core/configs'

/** Форма создания/редактирования заголовка */
export const TitleForm: React.FC<PopupAdapterFormProps> = React.memo(
    ({ id, onRequestFinish, initialValues }) => (
        <Form
            onFinish={onRequestFinish}
            id={id}
            initialValues={initialValues}
            validateMessages={{ required: VALIDATE_MESSAGE_DEFAULT }}
        >
            <HiddenField fieldName="index" />

            <FormItemAdapter
                fieldName="name"
                label={LOCAL.LABELS.NAME}
                required
                rules={[{ required: true }]}
            >
                <InputControl />
            </FormItemAdapter>
        </Form>
    )
)
