export enum ComparingOptions {
    ProfilePosition,
    Assessments,
}

export interface UserProfileContextProps {
    resultsComparingOptions?: ComparingOptions[]
    setResultsComparingOptions: (comparingOptions: ComparingOptions[]) => void
    showAssessments?: boolean
    showProfilePosition?: boolean
}
