export const LABELS = {
    ABOUT_SYSTEM: 'О системе',
    ABSENT: 'Отсутствует',
    ACCESSES: 'Доступы',
    ACCESSORY_TYPE: 'Принадлежность (тип)',
    ACCESS_GROUPS: 'Просмотреть оценку',
    ACCESS_TO_TEMPLATE: 'Доступ к шаблону',
    ACCESS_TO_TRACK: 'Предоставление доступов к треку',
    ACTIONS: 'Действия',
    ACTIVE_APPOINTMENTS: 'Активные назначения',
    ACTIVE_APPOINTMENTS_INFO: 'Информация об активных назначениях',
    ACTIVITY: 'Активити',
    ACTIVITY_DESCRIPTION_TEXT_SETTINGS: 'Настройка описания мероприятия',
    ACTIVITY_NAME_SETTINGS: 'Настройка наименования мероприятия',
    ACTIVITY_OR_TRACK_NAME: 'Наименование мероприятия/трека',
    ACTIVITY_TYPE: 'Тип деятельности',
    ACTUAL_QUANTITY: 'Фактическое количество',
    ADAPTATION: 'Адаптация',
    ADAPTATION_TRACK_TEMPLATE: 'Шаблон трека адаптации',
    ADDING_REPORT: 'Добавление отчёта',
    ADDITIONAL_CUT: 'Доп. информация',
    ADDITIONAL_INFO: 'Дополнительная информация',
    ADDITIONAL_MATERIALS: 'Дополнительные материалы',
    ADDITIONAL_MATERIALS_CUT: 'Доп. материалы',
    ADDITIONAL_REQUIREMENTS: 'Дополнительные требования',
    ADDITIONAL_SETTINGS: 'Дополнительные настройки',
    ADDRESS: 'Адрес',
    ADMINISTRATION: 'Администрирование',
    ADMIN_MANAGEMENT: 'Управление администратора',
    ADMISSION_FORM: 'Форма допуска',
    ADVANCE_ASSESSMENT_RESULTS: 'Предварительные результаты оценки',
    ADVANCE_QUIZ_RESULTS: 'Предварительные результаты опроса',
    ADVANCE_TEST_RESULTS: 'Предварительные результаты теста',
    AFTER_PASSING: 'После прохождения',
    AGE: 'Возраст',
    AGREEMENT_ACCEPT: 'Принять соглашение',
    AGREEMENT_DECLINE: 'Отказаться от соглашения',
    AGREEMENT_OBJECTS: 'Согласуемые объекты',
    ALL: 'Все',
    ALLOTTED_TIME: 'Отведенное время',
    ALLOW_MULTIPLE_ANSWER_CHOOSE: 'Доступен множественный выбор ответов',
    ALLOW_USER_ADD_CUSTOM_ANSWER:
        'Предоставить пользователю возможность добавить свой вариант ответа',
    ALL_POSITIONS: 'Все должности',
    ALL_QUESTIONS_ON_SHEET: 'Все вопросы на листе',
    ALL_RESPONDENTS: 'Все респонденты',
    ALL_WITHOUT_SELF_ESTEEM: 'Все без самооценки',
    ALWAYS: 'Всегда',
    ANSWER: 'Ответ',
    ANSWERS_FROM_OTHER_USERS: 'Ответы других пользователей',
    ANSWERS_SEQUENCE: 'Последовательность ответа на задание',
    ANSWER_LIMIT_AVAILABLE_FOR_SELECTION:
        'Ограничить количество доступных для выбора вариантов ответов',
    ANSWER_VARIANTS: 'Варианты ответов',
    APPEARANCE: 'Явка',
    APPOINTED_ACTIVITIES: 'Назначено мероприятий',
    APPOINTED_ASSESSMENT: 'Назначенные оценки',
    APPOINTED_TESTS_AND_QUIZES: 'Назначенные опросы и тесты',
    APPOINTED_TRACKS: 'Назначено треков',
    APPOINTMENTS: 'Назначения',
    APPOINTMENT_DATE_END: 'Дата окончания назначения',
    APPOINTMENT_NAME: 'Наименование назначения',
    APPOINTMENT_OBJECTS: 'Объекты назначения',
    APPOINTMENT_OBJECTS_SELECTION: 'Выбор объектов назначений',
    APPOINTMENT_OBJECT_KIND: 'Вид объекта назначения',
    APPOINTMENT_SELECTION: 'Выбор назначений',
    APPOINTMENT_START_DATE: 'Дата начала назначения',
    APPOINTMENT_TITLE: 'Название назначения',
    APPOINTMENT_TYPE: 'Тип назначения',
    APPROVAL_ADD_PARALLEL: 'Добавить согласующего параллельно',
    APPROVAL_ADD_SEQUENTLY: 'Добавить согласующего последовательно',
    APPROVAL_RESPONDENT: 'Участник согласования',
    APPROVAL_SELECTION: 'Выбор согласующего',
    APPROVAL_TIME: 'Срок рассмотрения',
    APPROVE_LIST: 'Лист согласования',
    ARBITRARILY: 'Произвольно',
    ARCHIVE: 'Архивная',
    ARCHIVED_RECORDS: 'Архивные записи',
    ARCHIVE_TAG: 'Архив',
    AREAS_STRENGTHS_DEVELOPMENT: 'Сильные стороны и зоны развития',
    ARTICLE_CARD_IMAGE: 'Изображение карточки статьи',
    ARTICLE_IN_DEVELOPMENT: 'Статья в разработке',
    ARTICLE_NAME: 'Название статьи',
    ASSESSED_COMPETENCIES: 'Оцениваемые компетенции',
    ASSESSED_POSITION: 'Оцениваемая должность',
    ASSESSED_POSITIONS: 'Оцениваемые должности',
    ASSESSMENT: 'Оценка',
    ASSESSMENTS: 'Оценки',
    ASSESSMENTS_360: 'Оценки 360',
    ASSESSMENTS_BY_EXPERT: 'Экспертные оценки',
    ASSESSMENTS_PARAMETER: 'Параметр оценки',
    ASSESSMENTS_RESPONDENTS_INDICATORS:
        'Оценки групп респондентов по индикаторам компетенций',
    ASSESSMENT_360: '360',
    ASSESSMENT_360_TEXT: 'Оценка 360',
    ASSESSMENT_BY_EXPERT: 'Экспертная оценка',
    ASSESSMENT_BY_MANAGER: 'Оценка руководителем',
    ASSESSMENT_COWORKER_BY_COMPETENCIES_AND_INDICATORS:
        'Оценка сотрудника по компетенциям и индикаторам',
    ASSESSMENT_GOAL: 'Цель оценки',
    ASSESSMENT_KIND: 'Вид оценки',
    ASSESSMENT_METHOD: 'Метод оценки',
    ASSESSMENT_NAME: 'Наименование оценки',
    ASSESSMENT_OBJECT: 'Объект оценки',
    ASSESSMENT_OBJECTS: 'Объекты оценки',
    ASSESSMENT_OBJECT_NAME: 'ФИО объекта оценки',
    ASSESSMENT_OBJECT_SELECTION: 'Выбор объекта оценки',
    ASSESSMENT_PASSING: 'Прохождение оценки',
    ASSESSMENT_PORTFOLIO: 'Портфель оценки',
    ASSESSMENT_PORTFOLIOS: 'Портфели оценки',
    ASSESSMENT_QUIZ_KIND: 'Вид оценки/опроса',
    ASSESSMENT_RESULT: 'Результат оценки',
    ASSESSMENT_SELF: 'Самооценка',
    ASSESSMENT_TITLE: 'Название оценки',
    ASSESSMENT_TYPE: 'Тип оценки',
    ASSESSMENT_VIEW: 'Просмотреть оценку',
    ASSESSMENT_WITHOUT_SELF_ESTEEM: 'Оценка руководителя, коллег и подчинённых',
    ASSIGNMENT: 'Назначение',
    ASSIGNMENT_PERIOD: 'Период назначения',
    ASSIGNMENT_SETTINGS: 'Параметры назначения',
    ATTACHED_FILES: 'Прикрепленные файлы',
    ATTACHMENT_TITLE: 'Заголовок',
    ATTACH_ICON_IN_CONTENT: 'Прикрепить иконку в содержании',
    ATTEMPT: 'Попытка',
    ATTEMPT_NUMBER: 'Количество попыток',
    AT_SPECIFIED_TIME: 'В указанное время',
    AUTHOR: 'Автор',
    AUTHOR_FIO: 'ФИО автора',
    AUTHOR_LAST_NAME: 'Фамилия автора',
    AUTHOR_NAME: 'Имя автора',
    AUTHOR_PATRONYMIC: 'Отчество автора',
    AUTO: 'Автоматически',
    AUTOASSIGNMENT: 'Автоназначение',
    AUTOMATIC: 'Автоматическое',
    AUTOMATIC_TRACK_ASSIGNMENT: 'Автоматическое назначение трека',
    AVAILABILITY: 'Доступность',
    AVAILABILITY_FROM_CATALOG: 'Доступность запуска продукта из каталога',
    AVAILABLE_FOR_ALL_ORGANIZATIONS: 'Доступно для всех организаций',
    AVAILABLE_FOR_ALL_POSITIONS: 'Доступно для всех должностей',
    AVAILABLE_FROM: 'Доступно от',
    AVAILABLE_TO_EMPLOYEE: 'Доступен сотруднику',
    AVAILABLE_TO_ORGANIZATIONS: 'Доступно организациям',
    AVAILABLE_UNTIL: 'Доступно до',
    AVERAGE_VALUE: 'Среднее значение',
    BACKGROUND_COLOR: 'Цвет фона',
    BACKGROUND_IMAGE: 'Фоновое изображение',
    BALL: 'Балл',
    BIRTHDAY: 'Дата рождения',
    BLOCK: 'Блок',
    BLOCK_COLOR_AFTER_PASSING: 'Цвет блока после прохождения',
    BLOCK_COLOR_BEFORE_PASSING: 'Цвет блока до прохождения',
    BLOCK_ICON_COLOR: 'Цвет иконки блока',
    BLOCK_START_BUTTON: 'Кнопка запуска блока',
    BOLD: 'Жирный',
    BOOK: 'Книга',
    BRANCH: 'Отрасль',
    BUDGET_IN_MANAGEMENT: 'Бюджет в управлении',
    BUTTON_COLOR: 'Цвет кнопки',
    BUTTON_TEXT_COLOR: 'Цвет текста кнопки',
    BY_BLOCKS: 'Блоками',
    BY_COMMON_POINT: 'По общему баллу',
    BY_COMPETENCIES: 'По компетенциям',
    BY_EVENT: 'По событию',
    BY_FIO: 'По ФИО',
    BY_GOAL: 'По цели',
    BY_NAME: 'По наименованию',
    BY_ONE: 'По одному',
    BY_ORGANIZATION: 'По организации',
    BY_PASSING_POINT: 'По проходному баллу',
    BY_POSITION: 'По должности',
    BY_POST: 'По почте',
    BY_STAGE: 'По этапам',
    BY_STATUS: 'По статусу',
    BY_TRACK: 'По треку',
    CALCULATION_RESULT: 'Расчет результата',
    CANVAS_BG: 'Фон холста',
    CANVAS_SIZES: 'Размеры холста',
    CATALOG: 'Каталог',
    CENTER: 'По центру',
    CERTIFICATES: 'Сертификаты',
    CERTIFICATES_MISSING: 'Сертификаты отсутствуют',
    CERTIFICATE_DATE: 'Дата выдачи сертификата',
    CERTIFICATE_NAME: 'Наименование сертификата',
    CERTIFICATE_NUMBER: 'Номер сертификата',
    CERTIFICATION: 'Сертификация',
    CHANGED: 'Изменен',
    CHANGED_BY_YOU: 'Назначено вами',
    CHANGE_DATE_TIME: 'Дата, время изменения',
    CHARTS: 'Графики',
    CHECK: 'Проверка',
    CHECKING_DATE_START: 'Дата начала проверки',
    CHECKING_TEST_RESULT: 'Проверка результатов теста',
    CHECK_START_DATE: 'Дата начала проверки',
    CHOOSE_THE_COMPETENCIES_FOR_THE_REQUIRED_OIV:
        'Выберите компетенции по необходимым ОИВ',
    CHOOSE_VARIANT: 'Выбор ответа из предложенных вариантов',
    CHOOSING_DEVELOPMENT_COALS: 'Выбор целей развития',
    CLEAR: 'Очистить',
    COLLEAGUE: 'Коллега',
    COLLEAGUES: 'Коллеги',
    COLLEAGUES_RELATED: 'Коллеги-смежники',
    COLLEAGUE_FROM_RELATED_DEPARTMENT: 'Коллега из смежного отдела',
    COLOR: 'Цвет',
    COMMENT: 'Комментарий',
    COMMENTS_MISSING: 'Комментарии отсутствуют',
    COMMENTS_OF_RESPONDENTS: 'Комментарии респондентов',
    COMMENTS_ON_TEXT: 'Комментарии к тексту',
    COMMENT_TO_CHECK_TEST_RESULT: 'Комментарий к проверке результатов теста',
    COMMENT_TO_USER: 'Комментарий пользователю',
    COMMON_INFORMATION: 'Общие сведения',
    COMMON_OPTIONS: 'Основные настройки',
    COMMON_STATISTIC: 'Общая статистика',
    COMPARING_RESULTS_360:
        'Сравнение Ваших индивидуальных результатов с результатами других участников данного опроса «360 градусов»',
    COMPETENCE: 'Компетенция',
    COMPETENCES_SELECTION: 'Выбор компетенций',
    COMPETENCE_ADD: 'Добавить компетенции',
    COMPETENCE_ASSESSMENT: 'Оценка компетенции',
    COMPETENCE_FINAL_SCORE: 'Итоговый балл по компетенции',
    COMPETENCE_LEVEL_BASIC: 'Базовый уровень',
    COMPETENCE_LEVEL_BASIC_HINT:
        'Эффективно решает отдельные типовые задачи, нуждается в поддержке и помощи опытных коллег',
    COMPETENCE_LEVEL_BEGINNER: 'Начальный уровень',
    COMPETENCE_LEVEL_BEGINNER_HINT: 'Не способен решать типовые задачи',
    COMPETENCE_LEVEL_EXPERIENCE: 'Уровень опыта',
    COMPETENCE_LEVEL_EXPERIENCE_HINT:
        'Эффективно решает большую часть типовых задач',
    COMPETENCE_LEVEL_LEADER: 'Уровень лидерства',
    COMPETENCE_LEVEL_LEADER_HINT:
        'Способен решать сложные, в том числе, нестандартные задачи',
    COMPETENCE_LEVEL_MASTER: 'Уровень мастерства',
    COMPETENCE_LEVEL_MASTER_HINT: 'Способен решать задачи повышенной сложности',
    COMPETENCE_LEVEL_NOT_IMPORTANT: 'Компетенция не важна',
    COMPETENCE_LEVEL_NOT_IMPORTANT_HINT: 'Компетенция отсутствует',
    COMPETENCE_NAME: 'Наименование компетенции',
    COMPETENCE_PERCENTILE: 'Процентиль компетенции',
    COMPETENCE_PRIORITY_WITHOUT_PP_FOUR:
        'Компетенции с высоким уровнем развития, которые можно отнести к сильным сторонам сотрудника по сравнению со шкалой развития и оценками других компетенций',
    COMPETENCE_PRIORITY_WITHOUT_PP_ONE:
        'Компетенции, уровень развития которых, по сравнению со шкалой развития и оценками других компетенций является низким',
    COMPETENCE_PRIORITY_WITHOUT_PP_THREE:
        'Компетенции с уровнем развития выше среднего по сравнению со шкалой развития и оценками других компетенций',
    COMPETENCE_PRIORITY_WITHOUT_PP_TWO:
        'Компетенции, развитие которых по сравнению со шкалой развития и оценками других компетенций находится на среднем уровне',
    COMPETENCE_PRIORITY_WITH_PP_FOUR: 'Компетенции с высоким уровнем развития',
    COMPETENCE_PRIORITY_WITH_PP_ONE:
        'Компетенции, уровень развития которых значимо ниже требований профиля должности',
    COMPETENCE_PRIORITY_WITH_PP_THREE:
        'Компетенции со средним уровнем развития',
    COMPETENCE_PRIORITY_WITH_PP_TWO: 'Компетенции с низким уровнем развития',
    COMPETENCE_RATING: 'Рейтинг оценок по компетенциям',
    COMPETENCE_SELECTION: 'Выбор компетенции',
    COMPETENCIES: 'Компетенции',
    COMPETENCIES_ASSESSMENTS_BY_GROUPS:
        'Оценки компетенций с разбивкой по группам респондентов',
    COMPETENCIES_COUNT: 'Кол-во компетенций',
    COMPETENCIES_DEVELOPS: 'Развивает компетенции',
    COMPETENCIES_FOR_DEVELOPMENT: 'Компетенции, рекомендованные для развития',
    COMPETENCIES_GROUP: 'Группа компетенций',
    COMPETENCIES_GROUP_NAME: 'Наименование группы компетенций',
    COMPETENCIES_LIST_EMPTY: 'Список компетенций пуст',
    COMPETENCIES_LOW_HIGH_LEVEL:
        'Компетенции низкого и высокого уровня развития',
    COMPETENCIES_ORDER_SETTINGS:
        'Настройка порядка отображения компетенций и индикаторов',
    COMPETENCIES_RECOMMENDED_FOR_DEVELOPMENT:
        'Компетенции, рекомендованные для развития',
    COMPETENCIES_RECOMMENDED_FOR_DEVELOPMENT_MISSING:
        'Компетенции, рекомендованные к развитию, отсутствуют',
    COMPETENCIES_SHOW_INDICATORS: 'Отобразить индикаторы',
    COMPLETED: 'Завершенные',
    COMPLETED_SUCCESSFUL: 'Завершено успешно',
    COMPLETE_QUESTIONNAIRE: 'Завершение опроса',
    COMPLETING_TASKS: 'Прохождение заданий',
    COMPLETION_DATE: 'Дата выполнения',
    COMPLEX: 'Комплекс',
    COMPLIANCE_PROFILE_POSITION:
        'Анализ соответствия результатов сотрудника профилю должности',
    COMPLIANCE_PROFILE_POSITION_ABSOLUTELY_EXCEEDING:
        'Абсолютно превышает профиль',
    COMPLIANCE_PROFILE_POSITION_DISBALANCE: 'Дисбаланс оценок',
    COMPLIANCE_PROFILE_POSITION_FULL: 'Соответствует профилю',
    COMPLIANCE_PROFILE_POSITION_LOW: 'Не соответствует профилю',
    COMPLIANCE_PROFILE_POSITION_PARTIALLY: 'Частично соответствует профилю',
    COMPLIANCE_PROFILE_POSITION_PARTIALLY_EXCEEDING:
        'Частично превышает профиль',
    CONDITION_SENDING: 'Условия отправки',
    CONFIRMED: 'Подтвержден',
    CONSISTENCY_OPINIONS: 'Согласованность мнений',
    CONSOLIDATED_REPORT: 'Сводный отчет',
    CONTACT_INFO: 'Контактные данные',
    CONTACT_THE_RESPONSIBLE_PEOPLE: 'Обратитесь к ответственным',
    CONTAINER_BACKGROUND: 'Фон контейнера',
    CONTAINS_ATTACHMENTS: 'Содержит документы для скачивания',
    CONTENT: 'Контент',
    CONTENT_EMPTY: 'Контент отсутствует',
    CONTENT_SELECTION: 'Выбор контента',
    CONTENT_VERSION: 'Версия контента',
    COPY: 'копия',
    CORRUPTION_RISK: 'Коррупционный риск',
    COURSE_DEVELOPER: 'Разработчик курса',
    COURSE_INFORMATION: 'Информация о курсе',
    CREATION_DATE: 'Дата создания',
    CREDITED: 'Зачтены',
    CRITERIA_SCALE: 'Шкала критериев',
    CRITERIA_SCALE_TYPE: 'Тип шкалы оценки',
    CURATOR: 'Куратор',
    CURRENT_BLOCK_COLOR: 'Цвет блока в момент прохождения',
    DATE: 'Дата',
    DATE_AND_TIME: 'Дата и время',
    DATE_CHANGE: 'Дата изменения',
    DATE_CHANGE_FROM: 'Дата изменения (с)',
    DATE_CHANGE_TO: 'Дата изменения (по)',
    DATE_CREATE_FROM: 'Дата создания (с)',
    DATE_CREATE_TO: 'Дата создания (по)',
    DATE_CREATION: 'Дата создания',
    DATE_DOWNLOAD: 'Дата загрузки',
    DATE_EMPTY: 'Без даты',
    DATE_END: 'Дата окончания',
    DATE_END_TO: 'Дата окончания (по)',
    DATE_FINISH: 'Дата завершения',
    DATE_FROM: 'Дата (с)',
    DATE_HOMEWORK_AUTOCHECK_STARTED: 'Дата запуска автозачета',
    DATE_MAILING_FROM: 'Дата отправки планируемая (с)',
    DATE_MAILING_TO: 'Дата отправки планируемая (по)',
    DATE_OF_LEARNING_HOLDING: 'Дата проведения обучения',
    DATE_START: 'Дата начала',
    DATE_START_FROM: 'Дата начала (с)',
    DATE_START_TO: 'Дата начала (по)',
    DATE_TIME_OF_EVENT: 'Дата и время проведения',
    DATE_TO: 'Дата (по)',
    DATE_TRACK_STARTED: 'Дата запуска трека',
    DATE_UPLOAD_VERSION: 'Дата загрузки версии',
    DAYS: 'Дней',
    DAYS_AFTER_TRACK_FINISHING_DATE: 'Дней с даты прохождения трека',
    DAYS_BEFORE_QUIZ_END: 'Дней до окончания опроса',
    DAYS_FROM_ASSIGNMENT_DATE: 'Дней с даты назначения',
    DAYS_TO_END: 'Дней до окончания',
    DAYS_TO_START: 'Дней до начала',
    DECORATION: 'Оформление',
    DELIVERY_MESSAGE_STATISTIC: 'Статистика доставки сообщений',
    DEPARTMENT: 'Департамент',
    DEPERSONALIZE_RESULT_FOR_QUIZ: 'Обезличить результат прохождения опроса',
    DESCRIPTION: 'Описание',
    DESCRIPTION_TEXT_COLOR: 'Цвет текста описания',
    DESCRIPTION_TEXT_SETTINGS: 'Настройка текста описания',
    DESTINATION: 'Назначения',
    DESTINATION_OBJECT: 'Объект назначения',
    DEVELOPMENT: 'Развитие',
    DEVELOPMENT_GOAL_CREATION: 'Создание новой цели',
    DEVELOPMENT_GOAL_EDITING: 'Редактирование цели',
    DEVELOPMENT_GOAL_NAME: 'Наименование цели развития',
    DEVELOPMENT_GOAL_NEW: 'Новая цель',
    DEVELOPMENT_TRACK_TEMPLATE: 'Шаблон трека развития',
    DEVELOPMENT_ZONES: 'Зоны развития',
    DISPATCH_TIME: 'Дата, время отправки',
    DISPATCH_TIME_PLANNED: 'Дата, время отправки планируемые',
    DISPLAYING_COLUMNS: 'Отображение колонок',
    DISPLAY_INFO_IN_CERTIFICATES: 'Отображать информацию о сертификате',
    DISPLAY_IN_RECOMMENDED: 'Отображать в рекомендованных',
    DISPLAY_IN_THEMATICS: 'Отображать в тематиках',
    DISPLAY_IN_THEMATICS_SECTIONS: 'Отображать в тематических разделах',
    DISPLAY_QUESTIONS: 'Отображение вопросов',
    DISPLAY_RECOMMENDED: 'Отобразить рекомендованные',
    DISPLAY_SUMMARY_WINDOW: 'Отобразить итоговое окно',
    DISTANT: 'Дистанционный',
    DISTRIBUTION: 'Рассылки',
    DISTRIBUTIONS: 'Рассылки',
    DISTRIBUTION_MAILING_VIEW_TITLE: 'Сообщение для <%= email %>',
    DISTRIBUTION_TAG_CREATE: 'Создание тега',
    DISTRIBUTION_TAG_EDIT: 'Редактирование тега',
    DOCUMENT: 'Документ',
    DOCUMENTS: 'Документы',
    DOCUMENTS_SELECTION: 'Выбор документов',
    DOCUMENT_CREATION: 'Создание документа',
    DOCUMENT_DESCRIPTION: 'Описание документа',
    DOCUMENT_NAME: 'Наименование документа',
    DOCUMENT_TYPE: 'Тип документа',
    DOWNLOAD_DATE: 'Дата загрузки',
    DOWNLOAD_IMAGE: 'Загрузить изображение',
    DRAW_LINES: 'Отображать линии',
    DURATION: 'Продолжительность',
    DYNAMIC_FIELD_MARKER: 'Маркер динамического поля',
    EDUCATION: 'Образование',
    EDUCATION_LEVEL: 'Уровень образования',
    EDUCATION_MATERIAL: 'Учебный материал',
    EDUCATION_MATERIALS: 'Учебные материалы',
    EDUCATION_MATERIALS_SELECTION: 'Выбор учебных материалов',
    EDUCATION_MATERIAL_ADD: 'Добавление обучающего материала',
    EDUCATION_PROFILE: 'Профиль образования',
    EDUCATION_TIME: 'Учебное время',
    EMAIL: 'Email',
    EMAIL_ADDRESS: 'Электронный адрес',
    EMPLOYEE: 'Сотрудник',
    EMPLOYEES: 'Сотрудники',
    EMPLOYEE_ASSESSMENT_BY_MANAGER: 'Оценка сотрудника руководителем',
    EMPLOYEE_ASSESSMENT_SELF: 'Самооценка сотрудника',
    EMPLOYEE_SELECTION: 'Выбор сотрудника',
    EMPTY_DATA: 'Нет данных',
    EMPTY_LIST: 'Список пуст',
    ENDING_DOCUMENT: 'Документ об окончании',
    END_PLACEMENT_DATE: 'Дата окончания размещения',
    END_TIME: 'Время окончания',
    ENGAGEMENT: 'Вовлеченность',
    ERRORS: 'Ошибки',
    EVENTS_HOLDING_DATES: 'Даты проведения мероприятия',
    EVENTS_M: 'Мероприятия',
    EVENTS_M_KIND: 'Вид мероприятий',
    EVENTS_M_TYPE: 'Тип мероприятий',
    EVENT_C: 'Событие',
    EVENT_C_ADD: 'Добавить событие',
    EVENT_M: 'Мероприятие',
    EVENT_M_CODE: 'Код мероприятия',
    EVENT_M_DATES: 'Даты прохождения мероприятия',
    EVENT_M_DESCRIPTION: 'Описание мероприятия',
    EVENT_M_KIND: 'Вид мероприятия',
    EVENT_M_NAME: 'Наименование мероприятия',
    EVENT_M_PARAMETERS: 'Параметры мероприятия',
    EVENT_M_PICTURE: 'Обложка мероприятия',
    EVENT_M_RATING: 'Рейтинговое мероприятие',
    EVENT_M_SELECTION: 'Выбор мероприятия',
    EVENT_M_TYPE: 'Тип мероприятия',
    EVENT_M_VISUALIZATION: 'Визуализация мероприятия',
    EVENT_VIEW_TYPE: 'Вид отображения мероприятия',
    EVERY_DAY: 'Каждый день',
    EVERY_THREE_DAYS: 'Каждые три дня',
    EVERY_TWO_DAYS: 'Каждые два дня',
    EXCEEDING_PROFILE_POSITION: 'Превышение профиля',
    EXECUTION_DATE: 'Дата исполнения',
    EXPECTATION: 'Ожидание',
    EXPERIENCE: 'Стаж',
    EXPERIENCE_ON_GGS: 'Стаж на ГГС',
    EXPERT: 'Эксперт',
    EXPERTS: 'Эксперты',
    EXPERT_ADD: 'Добавить эксперта',
    EXPERT_COMMENT: 'Комментарий эксперта',
    EXPERT_GROUP: 'Эксперт группы',
    EXPERT_SELECTION: 'Выбор эксперта',
    EXPIRE: 'Истекло',
    EXPIRED: 'Просрочено',
    EXTERNAL: 'Внешний',
    EXTERNAL_SHADOW: 'Внешняя тень',
    FACE_TO_FACE: 'Очный',
    FACE_TO_FACE_MEETING: 'Очная встреча',
    FACE_TO_FACE_MEETING_G: 'Очную встречу',
    FAVORITE: 'Избранное',
    FEEDBACK: 'Обратная связь',
    FIELD_NUMBER: 'Номер поля',
    FILE_HASH: 'Hash файла',
    FILE_ID: 'Id файла',
    FILE_NAME: 'Наименование файла',
    FILE_SIZE: 'Размер файла',
    FILE_STRUCTURE: 'Структура файла',
    FILL_EMPTY_TEXT: 'Вставить пропущенный текст',
    FILTERS: 'Фильтры',
    FINAL_APPEAL_TEXT: 'Текст финального обращения',
    FINAl_WINDOW: 'Финальное окно',
    FIO: 'ФИО',
    FIRST_NAME: 'Имя',
    FLOW: 'Поток',
    FONT: 'Шрифт',
    FONT_BOLD_SYMBOL: 'Ж',
    FONT_ITALIC_SYMBOL: 'К',
    FONT_UNDERLINE_SYMBOL: 'Ч',
    FORMAT: 'Формат',
    FOR_APPOINTMENT: 'Для назначения',
    FOR_OPENING: 'За открытие',
    FOR_PASSING: 'За прохождение',
    FOR_WHO: 'Для кого',
    FREE_ANSWER: 'Свободный ответ',
    FREE_ANSWERS: 'Свободные ответы',
    FROM_GROUP: 'Из группы',
    FULL_SCREEN: 'Полноэкранный',
    FUNCTIONAL_RESPONSIBILITIES: 'Функциональные обязанности',
    FURTHER: 'Далее',
    GENERAL_ASSESSMENT_COMPETENCE_RESULT:
        'Общие результаты оценки компетенций сотрудника',
    GENERAL_INFO: 'Общие сведения',
    GENERAL_INFORMATION: 'Общая информация',
    GGS: 'ГГС',
    GOAL: 'Цель',
    GOALS: 'Цели',
    GOALS_FOR_DEVELOPMENT: 'Цели для развития',
    GO_TO_QUESTION: 'Перейти к вопросу',
    GREETING: 'Приветствие',
    GREETINGS_TEXT: 'Текст приветствия',
    GROUPS: 'Группы',
    GUEST_ACCESS: 'Гостевой доступ',
    HAND_OPERATED: 'Ручное',
    HAS_UNSAVED_CHANGES: 'Есть несохраненные изменения',
    HEIGHT: 'Высота',
    HIDDEN_FEATURES: 'Скрытые возможности',
    HIDDEN_OPPORTUNITIES_PROBLEMS: 'Скрытые возможности и проблемы',
    HIDDEN_PROBLEMS: 'Скрытые проблемы',
    HIGHER: 'выше',
    HOMEWORK: 'Домашнее задание',
    HOMEWORK_AUTOCHECK: 'Автоматическое проставление зачета за задания',
    HOMEWORK_TIME: 'Время выполнения домашнего задания',
    HOME_PHONE: 'Домашний телефон',
    HOUR: 'Час',
    HOURS: 'Часов',
    HOURS_DISTANT: 'Часов (дистанционно)',
    HOURS_FACE_TO_FACE: 'Часов (очно)',
    HOUR_G: 'Часа',
    HUMANS_TEMPLATE: '<%= count %> человек',
    ICONS_SET: 'Наборы иконок',
    ICONS_SIZES: 'Размеры иконки',
    ICON_CANVAS_BACKGROUND: 'Фон холста иконки',
    ICON_DOWNLOAD: 'Загрузить новую',
    ICON_ROUNDING: 'Скругление иконки',
    INDICATOR: 'Индикатор',
    INDICATORS: 'Индикаторы',
    INDICATOR_CREATE: 'Создать индикатор',
    INDICATOR_SELECTION: 'Выбор индикатора',
    INDICATOR_TYPE: 'Тип индикатора',
    INFORMATION: 'Информация',
    INFORMATION_ASSISTANT: 'Информационный помощник',
    INFORMATION_ASSISTANT_ILLUSTRATION: 'Отображение помощника',
    INFORMATION_ASSISTANT_NAME: 'Имя помощника',
    INFORMATION_ASSISTANT_SET: 'Настройка помощника',
    INFORMATION_BLOCK: 'Информационный блок',
    INFORMATION_DATE_UPDATE: 'Дата актуализации сведений',
    INNER: 'Внутренние',
    INSTRUCTION: 'Инструкция',
    INSUFFICIENT_DATA: 'Недостаточно данных',
    INSUFFICIENT_DATA_TO_CALCULATE: 'Недостаточно данных для расчета',
    INSUFFICIENT_DATA_TO_DISPLAY_DIAGRAM:
        'Недостаточно данных для отображения графика',
    INTERNAL: 'Внутренний',
    INTRAMURAL: 'Очное',
    INTRODUCTORY: 'Ознакомительное',
    INVERSION_QUESTION:
        'Это инверсионный вопрос. Укажите номер прямого вопроса для установления связи',
    IN_DEVELOPMENT: 'В разработке',
    IN_PERSONAL_ACCOUNT: 'В личном кабинете',
    IN_PROCESS: 'В процессе',
    ITALICS: 'Курсив',
    KEY: 'Ключ',
    KEY_PERFORMANCE_INDICATORS: 'Ключевые показатели эффективности',
    KEY_THEMES: 'Ключевые темы',
    KIND: 'Вид',
    KNOWLEDGE_IN_RELATED_INDUSTRY: 'Знания в смежных областях',
    LAST_CHANGES: 'Последние изменения',
    LAST_NAME: 'Фамилия',
    LAST_UPDATE: 'Последнее обновление',
    LEARNING: 'Обучение',
    LEARNING_FORMAT: 'Формат обучения',
    LEFT: 'Слева',
    LEVEL: 'Уровень',
    LEVEL_COMPETENCE_DEVELOPMENT_WITH:
        'Уровни развития компетенций в соответствии с профилем должности',
    LEVEL_COMPETENCE_DEVELOPMENT_WITHOUT:
        'Уровни развития компетенций отсутствующих в профиле должности',
    LEVEL_OF_POSITIONS: 'Уровни должности',
    LINE_HEIGHT: 'Интервал',
    LINK: 'Ссылка',
    LIST: 'Список',
    LISTENERS: 'Слушатели',
    LISTENERS_COUNT: 'Всего слушателей',
    LISTENERS_LIST: 'Список слушателей',
    LIST_SETTINGS: 'Настройка списка',
    LOWER: 'ниже',
    MAIL: 'Письмо',
    MAILING: 'Задания на рассылку',
    MAILING_ASSIGNMENT: 'Задание на рассылку',
    MAILING_CATEGORY_SUBTYPE: 'Тип объекта назначения',
    MAILING_CATEGORY_TYPE: 'Вид назначения',
    MAILING_CHANGED: 'изменено',
    MAILING_EMAILS: 'Персональные адресаты',
    MAILING_END: 'Завершить',
    MAILING_INFO: 'Информация о задании на рассылку',
    MAILING_INFORMATION:
        'Адресатов в рассылке / создано сообщений / отправлено сообщений',
    MAILING_LAST: 'последняя рассылка по заданию',
    MAILING_PARAMS: 'Параметры отправки сообщения',
    MAILING_START: 'Начать',
    MAILING_STATISTICS:
        'Адресатов в рассылке / \n создано сообщений / \n отправлено сообщений',
    MAILING_STATUS: 'Статус рассылки',
    MAILING_THEME: 'Тема сообщения',
    MAILING_TYPE: 'Тип рассылки',
    MAIN_INFO: 'Основная информация',
    MAIN_PLACE_OF_WORK: 'Основное место работы',
    MAIN_TAG: 'Основная',
    MANAGER: 'Руководитель',
    MANAGERIAL_EXPERIENCE: 'Управленческий опыт',
    MANAGERIAL_GROUP: 'Управленческая',
    MANAGERIAL_SENIORITY: 'Управленческий стаж',
    MANAGER_ADD: 'Добавить руководителя',
    MANAGER_COMPETENCIES: 'Управленческие компетенции',
    MANAGER_COMPETENCY: 'Управленческая компетенция',
    MANAGER_SELECTION: 'Выбор руководителя',
    MANDATORY_QUESTION: 'Обязательный вопрос',
    MANUAL: 'Вручную',
    MATERIALS: 'Материалы',
    MATERIAL_VIEWED: 'Ознакомлен с материалом',
    MAXIMUM_POINTS: 'Максимальное количество баллов',
    MAX_PARTICIPANTS_COUNT: 'Максимальное количество участников',
    MEETING: 'Встреча',
    MEETING_A: 'Встречу',
    MEETING_G: 'Встречи',
    MENU: 'Меню',
    MESSAGE_BODY: 'Тело сообщения',
    MESSAGE_COUNTS: 'Количество писем',
    MESSAGE_TEXT: 'Текст сообщения',
    METRICS_BY_INDICATORS: 'Показатели по индикаторам',
    MIDDLE: 'Средний',
    MIDDLE_COMPETENCE_SCORE: 'Средний балл по компетенции',
    MINUTE: 'Минута',
    MINUTES: 'Минут',
    MINUTE_G: 'Минуты',
    MIXED: 'Смешанный',
    MODAL_WINDOW: 'Модальное окно',
    MODAL_WINDOW_POSITION: 'Размещение окна',
    MODAL_WINDOW_SETTINGS: 'Настройка окна',
    MONITORING: 'Мониторинг',
    NAME: 'Наименование',
    NAME_MAILING_INFORMATION: 'Наименование задания на рассылку',
    NAME_MUST_BE_UNIQUE: 'Наименование должно быть уникальным',
    NAME_OF_MAILING_TASK: 'Наименование задания на рассылку',
    NECESSITY: 'Обязательность',
    NEUTRAL: 'Нейтрально',
    NEW_VERSION: 'Новая версия',
    NONAPPEARANCE: 'Неявка',
    NORMAL: 'Обычный',
    NOTE: 'Примечание',
    NOTIFICATIONS: 'Уведомления',
    NOTIFICATIONS_DISABLED_BY_USER: 'Пользователем отключены уведомления',
    NOTIFICATIONS_PERIOD: 'Срок уведомлений',
    NOTIFICATIONS_PERIODICITY: 'Периодичность напоминаний',
    NOTIFICATIONS_THEME: 'Тема уведомлений',
    NOTIFICATIONS_TIME_SET: 'Настройка срока уведомлений',
    NOTIFICATIONS_TIME_SET_BEFORE: 'За',
    NOTIFICATIONS_TIME_SET_DAYS_TO_END: 'дней до окончания',
    NOTIFICATIONS_TIME_SET_DAYS_TO_START: 'дней до начала',
    NOT_ASSIGNED: 'Не назначено',
    NOT_RESTRICTED: 'Не ограничено',
    NOVICE: 'Новичок',
    NO_ACTIVE_APPOINTMENTS_YET: 'Активных назначений пока что нет',
    NO_CERTIFICATES: 'Сертификаты отсутствуют',
    NO_DATA_SHORT: 'н/д',
    NO_POSITION_PROFILE: 'Нет ПД',
    NO_RESULTS: 'Результаты отсутствуют',
    NUMBER: 'Номер',
    NUMBER_OF_ATTEMPTS_TO_PASS: 'Количество попыток прохождения',
    NUMBER_OF_ATTEMPTS_TO_SEND: 'Количество попыток отправки',
    NUMBER_OF_COMPETENCIES_BLOCKS: 'Блоков компетенций',
    NUMBER_OF_PARTICIPANTS: 'Количество участников',
    NUMBER_OF_QUESTIONS: 'Вопросов',
    NUMBER_SHORT: '№',
    OBJECTIVE: 'Задача',
    OFFSET: 'Зачет',
    OIV: 'ОИВ',
    ONLINE: 'Онлайн',
    ONLY_WITH_ERRORS: 'Только с ошибками',
    ON_CHECK: 'На проверку',
    ON_MOUSE_OVER: 'При наведении мышкой',
    ON_OPENING: 'При открытии трека',
    OPEN_DOCUMENT_AFTER_SAVE: 'Открыть документ после сохранения',
    OPINION: 'Мнение',
    ORGANIZATION: 'Организация',
    ORGANIZATIONS: 'Организации',
    ORGANIZATION_SELECTION: 'Выбор организации',
    ORG_STRUCTURE: 'Организационная структура',
    OTHER: 'Другое',
    OUTER: 'Внешние',
    OVERALL_PROGRESS_OF_PASSAGE: 'Общий прогресс прохождения',
    OWNER: 'Владелец',
    OWNER_NAME: 'ФИО владельца',
    PAIR_QUESTIONS_NUMBER: 'Номер парного прямого вопроса',
    PARTICIPANTS: 'Участники',
    PARTICIPANTS_LIMIT: 'Ограничить количество участников',
    PART_TIME: 'По совместительству',
    PASSING: 'Прохождение',
    PASSING_ATTEMPTS_LIMIT: 'Ограничить количество попыток прохождения',
    PASSING_BEFORE: 'До прохождения',
    PASSING_BLOCKS: 'Прохождение блоков',
    PASSING_CERTIFICATE: 'Сертификат о прохождении',
    PASSING_ORDER: 'Порядок прохождения',
    PASSING_RESULT_LEVELS: 'Уровни результатов прохождения',
    PASSING_TIME: 'Время, отведенное на прохождение',
    PASSING_TIME_LIMIT: 'Ограничить время прохождения',
    PASSING_TIME_LIMIT_FOR_QUIZ: 'Ограничить время для прохождения опроса',
    PASTE_CHART: 'Вставить график',
    PAST_PLACEMENT_PERIOD: 'Прошедший срок размещения',
    PATRONYMIC: 'Отчество',
    PERCENTILE: 'Процентиль',
    PERCENTILE_IN_ASSESSMENT_PROJECT: 'Процентиль в оценочном проекте',
    PERFORMER_FIO: 'ФИО исполнителя',
    PERIOD: 'Период',
    PHONE: 'Телефон',
    PHONE_NUMBER: 'Номер телефона',
    PHOTO: 'Фото',
    PLACE: 'Место',
    PLACEMENT_PERIOD_IN_CATALOG: 'Срок размещения в каталоге',
    PLACE_IN_RATING: 'Место в рейтинге',
    PLANNED_QUANTITY: 'Планируемое количество',
    PODCAST: 'Подкаст',
    POINTS_COUNT: 'Кол-во баллов',
    POINTS_FOR_ANSWER: 'Начисление баллов за задание',
    POINTS_FOR_EACH_CORRECT_ANSWER:
        'Начисление баллов за каждый правильный ответ',
    POINTS_FROM: 'Баллы (от)',
    POINTS_OF: 'Баллов',
    POINTS_TO: 'Баллы (до)',
    PORTFOLIO_NAME: 'Наименование портфеля',
    POSITION: 'Должность',
    POSITIONS: 'Должности',
    POSITIONS_AND_UNIT_NO_DATA:
        'Должность и подразделение - данные отсутствуют',
    POSITIONS_HISTORY_MISSING: 'История должностей отсутствует',
    POSITION_CLASSIFIER: 'Классификатор должности',
    POSITION_LEVEL: 'Уровень должности',
    POSITION_NAME: 'Наименование должности',
    POSITION_PROFILE: 'Профиль должности',
    POSITION_PROFILE_ATTACHMENT: 'Привязан',
    POSITION_PROFILE_AUTO_ADD_COMPETENCE: 'Автоматически добавлять в ПД',
    POSITION_PROFILE_ID: 'ID ПД',
    POSITION_PROFILE_MATCH: 'Совпадение с профилем',
    POSITION_PROFILE_NAME: 'Наименование профиля должности',
    POSITION_PROFILE_NOT_ATTACHMENT: 'Не привязан',
    POSITION_PROFILE_NOT_DEFINED: 'Профиль должности не определен',
    POSITION_PROFILE_NOT_EXIST: 'ПД отсутствует',
    POSITION_PROFILE_SHORT: 'ПД',
    POSITION_PROFILE_STATE_HAS_ERRORS: 'С ошибками',
    POSITION_PROFILE_STATE_NEW: 'Новый',
    POSITION_PROFILE_STATE_NOT_UPDATABLE: 'Есть в БД, обновить нельзя',
    POSITION_PROFILE_STATE_UPDATABLE: 'Есть в БД, можно обновить',
    POSITION_SELECTION: 'Выбор должностей',
    POST_PERFORMANCE_INDICATORS: 'Показатели эффективности должности',
    PREDEFINED: 'Предустановленный',
    PRESET_TYPE_OF_SCALE: 'Заданный тип шкалы',
    PRIMARY: 'Первичный',
    PRODUCT_ANNOUNCEMENT: 'Анонс продукта',
    PRODUCT_CONTENT: 'Содержание продукта',
    PRODUCT_COUNT: 'Кол-во продуктов',
    PRODUCT_IMAGE_IN_CATALOG: 'Изображение продукта в каталоге',
    PRODUCT_NAME: 'Наименование продукта',
    PRODUCT_SHAPE: 'Форма продукта',
    PRODUCT_TYPE: 'Тип продукта',
    PROFESSIONAL_RETRAINING_DIPLOMA: 'Диплом о профессиональной переподготовке',
    PROFILE: 'Профиль',
    PROFILE_DISPLAY_DATA_TYPE_ASSESSMENT: 'Оценок',
    PROFILE_DISPLAY_DATA_TYPE_POSITION: 'Профиля должности',
    PROFILE_NAME: 'Наименование профиля',
    PROFILING: 'Профилирование',
    PROGRAMME_LEVEL: 'Уровень программы',
    PROGRAM_LISTENERS_LISTS: 'Списки записавшихся на программу',
    PROGRESS: 'Прогресс',
    PROJECT_HOLDING_PERIOD_SETTINGS: 'Настройка периода проведения проекта',
    PROJECT_NAME: 'Наименование проекта',
    PROMOTION_CERTIFICATE: 'Удостоверение о повышении квалификации',
    PULSE: 'Пульсация',
    PULSE_TIME: 'Время пульсации',
    QUESTION: 'Вопрос',
    QUESTIONNAIRE_ADD: 'Добавить опрос',
    QUESTIONNAIRE_FINAL_WINDOW: 'Итоговое окно',
    QUESTIONNAIRE_NAME: 'Наименование опросного листа',
    QUESTIONNAIRE_TEMPLATE: 'Опросный лист',
    QUESTIONNAIRE_TEMPLATES_ADD: 'Добавить опросные листы',
    QUESTIONNAIRE_TEMPLATE_ADD: 'Добавить опросный лист',
    QUESTIONNAIRE_TEMPLATE_SETTINGS: 'Настройка опросного листа',
    QUESTIONS_FROM_SHEET: 'Вопросы из листа',
    QUESTIONS_LIST: 'Список вопросов',
    QUESTION_DESCRIPTION: 'Текст вопроса',
    QUESTION_TITLE: 'Заголовок вопроса',
    QUESTION_TYPE: 'Тип вопроса',
    QUIZ: 'Опрос',
    QUIZZES: 'Опросы',
    QUIZ_AVAILABILITY: 'Доступность опроса',
    QUIZ_PASSING: 'Прохождение опроса',
    QUIZ_PORTFOLIO: 'Портфель опроса',
    QUIZ_REPORT_CHARTS_HISTOGRAM_TAB: 'Гистограмма со средними значениями',
    QUIZ_REPORT_CHARTS_SEMANTIC_TAB: 'Семантический график',
    QUIZ_REPORT_NO_DATA: 'Информация отсутствует по',
    QUIZ_RESPONDENTS: 'Участники опроса',
    QUIZ_TYPE: 'Тип опроса',
    RANDOM_ANSWER_ORDER: 'Отображать варианты ответов в произвольном порядке',
    RANDOM_QUESTIONS_ORDER: 'Отображать вопросы в произвольном порядке',
    RBS: 'РБС',
    READY_TO_RECOMMEND: 'Готовность порекомендовать',
    RECEIVER_NAME: 'Имя получателя',
    RECIPIENT: 'Получатель',
    RECOMMENDATIONS: 'Рекомендации',
    RECOMMENDED: 'Рекомендованное',
    RECORD_DATE_FROM: 'Дата записи (с)',
    RECORD_DATE_TO: 'Дата записи (по)',
    RECORD_NAME: 'Наименование записи',
    REGISTRATION_FOR_LEARNING_AVAILABLE: 'Доступна запись на обучение',
    REPASSING_COUNT: 'Количество попыток',
    REPEAT: 'Повторение',
    REPLAY: 'Повторное прохождение',
    REPORT: 'Отчет',
    REPORTS: 'Отчёты',
    REPORTS_LEGEND_HIGH: 'Высокая',
    REPORTS_LEGEND_HIGH_TO: 'высокому',
    REPORTS_LEGEND_LOW: 'Низкая',
    REPORTS_LEGEND_LOW_TO: 'низкому',
    REPORTS_LEGEND_MEDIUM: 'Средняя',
    REPORTS_LEGEND_MEDIUM_TO: 'среднему',
    REPORTS_LEGEND_NOT_FORMED:
        'Не сформировано (50% и более затруднились ответить)',
    REPORT_360: 'Отчет 360',
    REPORT_MANAGER_ASSESSMENT_RESULTS:
        'Отчет по результатам оценки руководителя',
    REPORT_NOT_AVAILABLE: 'Отчет недоступен. Обратитесь к администратору',
    REPORT_TEMPLATE: 'Шаблон отчета',
    REPORT_TEXT: 'Текст отчета',
    REPRESENTATION: 'Представление',
    REQUEST: 'Заявка',
    REQUEST_DATE: 'Дата заявки',
    REQUEST_NUMBER: '№ заявки',
    REQUIRED: 'Обязательный',
    REQUIRED_EXPERIENCE: 'Требуемый опыт',
    REQUIRED_MIN_SCORE: 'Требуемый проходной балл',
    RESERVE: 'Резерв',
    RESPONDENTS: 'Респонденты',
    RESPONDENTS_ADD: 'Добавить респондента',
    RESPONDENTS_COMMENTS: 'Комментарии респондентов',
    RESPONDENTS_DEPERSONALIZE: 'Обезличить респондентов',
    RESPONDENTS_EVALUATED_GROUPS:
        'Группы респондентов, принимавших участие в оценке Ваших компетенций',
    RESPONDENTS_QUANTITY: 'Количество респондентов',
    RESPONDENT_ROLES: 'Роли респондентов',
    RESPONDENT_TYPE: 'Тип респондента',
    RESPONSIBLE: 'Ответственный',
    RESPONSIBLE_MANAGER: 'Ответственный руководитель',
    RESPONSIBLE_PEOPLE: 'Ответственные',
    RESPONSIBLE_PERSON: 'Ответственное лицо',
    RESULT: 'Результат',
    RESULTS_AVAILABLE: 'Результаты доступны пользователю',
    RESULTS_OF_MEETINGS: 'Результаты очных встреч',
    RESULT_ASSESSMENT: 'Оценка результатов',
    RESULT_AVAILABLE_TO_USER: 'Результаты доступны пользователю',
    RESULT_DISPLAY: 'Отображение результата',
    RESULT_OF_THE_EDUCATION: 'Результат обучения',
    REVIEWER: 'Проверяющий',
    REVIEWERS: 'Проверяющие',
    REVISION_NUMBER: 'Номер версии ревизии',
    RIGHT: 'Справа',
    RIGHT_ANSWER: 'Правильный ответ',
    RISK_DISMISSAL: 'Риск увольнения',
    ROLE: 'Роль',
    ROUTE: 'Маршрут',
    ROUTE_TEMPLATE: 'Шаблон маршрута',
    SAVE_FILTERS: 'Сохранить настройки фильтрации',
    SCALE_NAME: 'Название шкалы',
    SCALE_SIGN: 'Признак шкалы',
    SCALE_TYPE: 'Тип шкалы',
    SEARCH: 'Поиск',
    SEARCH_BY_FULL_NAME: 'Поиск по ФИО',
    SEARCH_RESULTS: 'Результаты поиска',
    SECONDARY: 'Повторный',
    SECTION: 'Раздел',
    SELECTION_QUIZ: 'Выбор опроса',
    SELECTION_QUIZ_TEST: 'Выбор теста/опроса',
    SELECTION_TASK: 'Выбор задания',
    SELECT_COMPETENCE_GROUP: 'Выберите группу компетенций',
    SELECT_FROM_DIRECTORY: 'Выбрать из справочника',
    SELECT_LOGO: 'Выберите логотип',
    SELECT_RESOLUTION: 'Выберите разрешение',
    SELF_RECORDING: 'Самостоятельная запись',
    SEMANTIC: 'Семантический',
    SEND: 'Отправить',
    SENDER_ADDRESS: 'Адрес отправителя',
    SEND_TO_CHECK: 'Отправлено на проверку',
    SERVICE: 'Сервис',
    SERVICE_QUALITY: 'Качество сервисов',
    SETTINGS: 'Настройки',
    SET_OFF: 'Зачет',
    SHADOW_LIGHT: 'Тень/подсветка',
    SHAPE: 'Форма',
    SHIPPING_METHOD: 'Способ отправки',
    SHOW_APPEAL: 'Показывать обращение',
    SHOW_ARCHIVED: 'Показать архивные',
    SHOW_AUTO_ASSIGNMENT: 'Отобразить автоназначение',
    SHOW_COMPLETED: 'Показать завершенные',
    SHOW_EVALUATOR_ROLE: 'Показывать роли оценивающего',
    SHOW_EXPIRED: 'Показать просроченные',
    SHOW_NUM_AND_TASK_TITLE: 'Показывать заголовок и № заданий',
    SHOW_PASSING_PROGRESS: 'Отображать прогресс прохождения',
    SHOW_POST_PERFORMANCE_INDICATORS:
        'Отобразить показатели эффективности должности',
    SHOW_PROGRESS: 'Отображать прогресс',
    SHOW_TASK_TITLE_ON_ROAD_MAP:
        'Скрывать или показывать заголовок "Задание №" на форме Дорожного дневника',
    SIZE: 'Размер',
    SNILS: 'СНИЛС',
    SPECIALIST: 'Специалист',
    SPECIFIED_DATE_TIME: 'Дата, время отправки планируемые',
    SPENT_TIME: 'Затраченное время',
    STAFFED: 'Набран',
    STAFF_STATUS_TYPE: 'Кадровое состояние',
    STAFF_UNIT: 'Штатная единица',
    STAGE: 'Этап',
    STAGES: 'Этапы',
    STAGES_COUNT: 'Кол-во этапов',
    STAGES_PASSING_ORDER: 'Порядок прохождения этапов',
    STAGES_VISUALIZATION: 'Визуализация этапов',
    STAGE_ADD: 'Добавление этапа',
    STAGE_EFFECTS: 'Эффекты этапа',
    STAGE_EMPTY: 'Нет этапов',
    STAGE_END: 'Завершение этапа',
    STAGE_NAME: 'Наименование этапа',
    STAGE_START: 'Запуск этапа',
    STAGE_VISUALIZATION_SET: 'Настройка отображения этапа',
    START: 'Запуск',
    START_PLACEMENT_DATE: 'Дата начала размещения',
    START_TIME: 'Время начала',
    STATISTIC: 'Статистика',
    STATUS: 'Статус',
    STATUS_AND_BALL: 'Статус и балл',
    STATUS_CHANGE_TIME: 'Дата, время изменения статуса',
    STATUS_DELIVERY: 'Статус доставки',
    STATUS_SENDING: 'Статус отправки',
    STEP_BY_STEP: 'Последовательно',
    STP_CONTACTS: 'Контакты СТП',
    STRONG_SIDES: 'Сильные стороны',
    STRUCTURAL_UNIT: 'Подразделение',
    STRUCTURAL_UNIT_NAME: 'Наименование подразделения/организации',
    STRUCTURE: 'Структура',
    STUDYING_ADJECTIVE: 'Учебных',
    STUDYING_DAYS_PLURAL: 'Учебных дней',
    SUBORDINATE: 'Подчиненный',
    SUBORDINATED_TO: 'Подчинен',
    SUBORDINATES: 'Подчиненные',
    SUBORDINATES_COUNT: 'Количество подчиненных',
    SUBSECTION: 'Подраздел',
    SUBSTANCE: 'Содержание',
    SUBUNIT: 'Подразделения',
    SUM_DURATION: 'Общая длительность',
    SYNC_DATE: 'Дата синхронизации',
    TAGS: 'Теги',
    TARGET: 'Целевая',
    TARGET_AUDIENCE: 'Целевая аудитория',
    TASK: 'Задание',
    TASKS: 'Задания',
    TASKS_PARENTAL: 'Заданий',
    TASKS_THAT_PROGRAM_SOLVES: 'Задачи, которые решает программа',
    TASK_ADD: 'Добавить задание',
    TASK_DESCRIPTION: 'Описание задания',
    TASK_NAME: 'Наименование задания',
    TASK_NUMBER_AND_SCORE: 'Номер задания/балл',
    TASK_PASSING_ORDER: 'Доступно для выполнения после задания',
    TASK_PREPARATION: 'Подготовка задания',
    TASK_STATUS: 'Статус задания',
    TASK_TEXT: 'Текст задания',
    TASK_TITLE: 'Заголовок задания',
    TEACHERS: 'Преподаватели',
    TEACHING: 'Обучающее',
    TEMPLATES: 'Шаблоны',
    TEMPLATE_CATEGORY_TYPE: 'Вид объекта назначения',
    TEMPLATE_DESCRIPTION: 'Описание шаблона',
    TEMPLATE_DOWNLOAD: 'Загрузить готовый шаблон',
    TEMPLATE_INFO: 'Информация о шаблоне',
    TEMPLATE_NAME: 'Наименование шаблона',
    TEMPLATE_NOTIFICATION: 'Шаблон уведомления',
    TEMPLATE_REGISTRY: 'Реестр шаблонов',
    TEMPLATE_SAVE: 'Сохранить как шаблон',
    TEMPLATE_STATUS: 'Статус шаблона',
    TEMPLATE_TITLE: 'Заголовок шаблона',
    TERMS_SCORING: 'Условия начисления баллов',
    TEST: 'Тест',
    TESTS: 'Тесты',
    TEST_KIND: 'Вид теста',
    TEST_PARTICIPANTS: 'Участники тестирования',
    TEST_PASSING: 'Прохождение теста',
    TEST_POINT: 'Цель теста',
    TEST_PORTFOLIO: 'Портфель теста',
    TEST_QUIZ: 'Тест/опрос',
    TEXT: 'Текст',
    TEXT_ANSWER: 'Текстовый ответ',
    TEXT_COLOR: 'Цвет текста',
    THANKS: 'Спасибо',
    THEMATIC: 'Тематика',
    THEMATIC_BLOCK: 'Тематический блок',
    THEMATIC_BLOCKS: 'Тематические блоки',
    THEMATIC_BLOCKS_COUNT: 'Тематических блоков',
    THEMATIC_BLOCKS_PASSING_ORDER: 'Порядок прохождения тематических блоков',
    THEMATIC_BLOCKS_SETTING: 'Настройка тематических блоков',
    THEMATIC_BLOCK_DESCRIPTION_SETTINGS: 'Настройка описания блока',
    THEMATIC_BLOCK_DURATION: 'Тематический блок проводится несколько дней',
    THEMATIC_BLOCK_NAME_SETTINGS: 'Настройка наименования блока',
    THEMATIC_SECTION: 'Тематический раздел',
    THEMATIC_SECTIONS_CREATION: 'Создание нового тематического раздела',
    THEMATIC_SECTIONS_EDITING: 'Редактирование тематического раздела',
    THEME: 'Тема',
    TIME: 'Время',
    TIMES: 'Раз',
    TIME_AND_MINUTES: 'Время, мин',
    TIME_SPENT: 'Времени затрачено',
    TITLE: 'Название',
    TITLE_EDIT: 'Редактирование заголовка',
    TITLE_TEXT: 'Текст заголовка',
    TITLE_TEXT_COLOR: 'Цвет текста наименования',
    TOPIC_MESSAGE: 'Тема сообщения',
    TOTAL: 'Всего',
    TOTAL_SCORE: 'Итоговый балл',
    TO_WHOM_IT_REPORTS: 'Кому подчиняется',
    TO_WORK: 'В работу',
    TRACK: 'Трек',
    TRACKS: 'Треки',
    TRACK_ACCESSIBILITY: 'Доступность трека',
    TRACK_DISPLAY: 'Отображение трека в вашем развитии',
    TRACK_DURATION: 'Продолжительность трека',
    TRACK_END: 'Завершение трека',
    TRACK_LOGO: 'Логотип трека',
    TRACK_NAME: 'Название трека',
    TRACK_NAME_LONG: 'Наименование трека',
    TRACK_OPTIONS: 'Настройка трека',
    TRACK_OR_EVENT: 'Трек/мероприятие',
    TRACK_SELECTION: 'Выбор трека',
    TRACK_STAGES_SETTINGS: 'Настройка этапов трека',
    TRACK_START: 'Запуск трека',
    TRACK_START_PERIOD: 'Период запуска трека',
    TRACK_TEMPLATE: 'Шаблон трека',
    TRACK_TEMPLATES_TITLE: 'Заголовок шаблона трека',
    TRACK_TEMPLATE_SELECTION: 'Выбор шаблона трека',
    TRACK_TITLE: 'Заголовок трека',
    TRACK_TYPE: 'Тип трека',
    TRACK_VISUALIZATION: 'Визуализация трека',
    TRAVEL_DIARY: 'Дорожный дневник',
    TURN_OFF_SPELLING_CHECK: 'Отключить проверку орфографии',
    TUTOR: 'Тьютор',
    TUTORS: 'Тьюторы',
    TUTOR_COMMENT: 'Комментарий проверяющего',
    TYPE: 'Тип',
    UNCREDITED: 'Не зачтены',
    UNDERLINED: 'Подчеркнутый',
    UNIVERSITY: 'Университет',
    UNKNOWN: 'Неизвестно',
    UNLIMITED_NUMBER: 'Неограниченное количество',
    UNPIN_FILE: 'Открепить файл',
    UNTIL_NOW: 'По настоящее время',
    UPLOAD_AUTHOR: 'Автор загрузки',
    UPLOAD_DATE: 'Дата загрузки',
    UPLOAD_FILE_ALLOWED_FORMATS: 'Доступна загрузка контента в формате',
    USER: 'Пользователь',
    USERS: 'Пользователи',
    USERS_AGREEMENT: 'Пользовательское соглашение',
    USERS_COMMENTS_TO_ANSWERS: 'Комментарии пользователей к вопросам',
    USER_ANSWER: 'Ответ пользователя',
    USER_AVATAR: 'Аватар пользователя',
    USER_FIO: 'ФИО пользователя',
    USER_ID: 'ID пользователя',
    USE_EDITOR: 'Использовать редактор',
    USE_FOR_POSITION_LEVELS: 'Использовать для уровней должности',
    VACANCY: 'Вакансия',
    VALUE: 'Значение',
    VALUES: 'Значения',
    VERIFIED: 'Проверены',
    VERSION: 'Версия',
    VERSION_FROM_DATE_TEMPLATE: 'Версия <%= version %> от <%= date %>',
    VIDEO: 'Видео',
    VIDEO_FILES: 'Видеофайлы',
    VISUALIZATION: 'Визуализация',
    VISUALIZATION_FOR_TRACK: 'Визуализация для трека',
    WEAK_SIDES: 'Слабые стороны',
    WEBINAR: 'Вебинар',
    WEBINARS: 'Вебинары',
    WEBINAR_G: 'Вебинара',
    WELCOME_MODAL: 'Приветственное окно',
    WELCOME_VIDEO: 'Приветственное видео',
    WHAT_YOU_WILL_LEARN: 'Чему вы научитесь',
    WIDTH: 'Ширина',
    WITHOUT_SHADOW: 'Без тени',
    WORK_EMAIL: 'Рабочий email',
    YEAR: 'Год',
    YOUR_APPLICATION_HAS_SEND: 'Ваша заявка отправлена',
    YOUR_PROGRESS: 'Ваше развитие',
    YOUR_SCORE: 'Ваш балл',
    YOUR_VARIANT: 'Ваш вариант',
    YOU_EVALUATE: 'Вы оцениваете',
    YOU_HAVE_CHOSEN: 'Вы выбрали',
    YOU_HAVE_LEFT: 'У вас осталось',
    YOU_RATING: 'Ваш рейтинг',
    YOU_WILL_LEARN: 'Чему вы научитесь',
}
