import React from 'react'
import { LOCAL } from 'core/local'
import { PassingFormContract } from 'core/api'
import { PassingIconBlockProps } from 'components/shared/Passing/PassingIconBlock'
import { secondCountToTimeString } from 'utils'

import { MakeIconBlockProps } from './QuestionnairesPassingGreetingsStage.types'
import { getRemainingAttemptsCount } from '../../QuestionnairePassing.utils'

/** Создаём массив блоков с иконками и текстом для стадии */
export const makeIconBlocksProps = ({
    numberOfQuestions,
    timeLimit,
    finishedPassingCount,
    attemptsLimited,
    attemptsLimit,
    randomAnswerOrder,
}: MakeIconBlockProps): PassingIconBlockProps[] => [
    {
        iconType: 'ClockCircleOutlined',
        content: (
            <>
                {`${LOCAL.LABELS.ALLOTTED_TIME}:`}

                <br />

                {timeLimit
                    ? secondCountToTimeString(timeLimit)
                    : LOCAL.LABELS.NOT_RESTRICTED}
            </>
        ),
    },
    {
        iconType: 'UnorderedListOutlined',
        content: `${LOCAL.LABELS.NUMBER_OF_QUESTIONS}: ${numberOfQuestions}`,
    },
    {
        iconType: 'SwapOutlined',
        content: randomAnswerOrder
            ? LOCAL.MESSAGES.YOU_CAN_ANSWER_QUESTIONS_IN_ANY_ORDER
            : LOCAL.MESSAGES.YOU_CAN_ANSWER_QUESTIONS_IN_SEQUENTIAL_ORDER,
    },
    {
        iconType: 'RetweetOutlined',
        content: (
            <>
                {`${LOCAL.LABELS.REPASSING_COUNT}:`}

                <br />

                {attemptsLimited
                    ? getRemainingAttemptsCount({
                          attemptsLimit,
                          finishedPassingCount,
                          attemptsLimited,
                      } as PassingFormContract)
                    : LOCAL.LABELS.NOT_RESTRICTED}
            </>
        ),
    },
]
