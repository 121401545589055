import React from 'react'
import { Form } from 'antd'
import { shouldFormFieldUpdate } from 'utils'

import { ShouldUpdateCheckerProps } from './ShouldUpdateChecker.types'

export const ShouldUpdateChecker: React.FC<ShouldUpdateCheckerProps> = React.memo(
    ({ fieldPath, children }) => (
        <Form.Item noStyle shouldUpdate={shouldFormFieldUpdate(fieldPath)}>
            {children}
        </Form.Item>
    )
)
