import React from 'react'
import {
    AppointmentActivitiesPassingServiceProps,
    formatDate,
    renderAppointmentTrackStatus,
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'
import { AppointmentActivitySearchResultContract } from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { LOCAL } from 'core/local'

import { EventsAssignmentsTableActions } from './components'

/**
 * Получить настройки отображения колонок таблицы назначения мероприятий
 * @param appointmentEventsProcessService сервис для контроля процесса прохождения назначения
 * мероприятия
 * @param actions вспомогательные функции/экшены, берутся из DictionaryPage
 */
export const getEventsAssignmentsTableColumns = (
    appointmentEventsProcessService: AppointmentActivitiesPassingServiceProps,
    { reFetchDictionary, updateLoader }: DictionaryPageActionsProps
): ColumnsType<AppointmentActivitySearchResultContract> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.EVENT_M_NAME,
        dataIndex: 'activityName',
        key: 'activityName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizations',
        key: 'organizations',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'startDate',
        key: 'startDate',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'endDate',
        key: 'endDate',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderAppointmentTrackStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <EventsAssignmentsTableActions
                updateLoader={updateLoader}
                tableRow={tableRow}
                appointmentEventsProcessService={
                    appointmentEventsProcessService
                }
                reFetchDictionary={reFetchDictionary}
            />
        ),
        fixed: 'right',
    },
]
