import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { TooltipAdapter } from 'components/shared'
import { restrictBigText, secondCountToTimeString } from 'utils'

export const SPENT_TIME_TABLE_CONFIG: ColumnsType<any> = [
    {
        title: LOCAL.LABELS.EMPLOYEE,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.SPENT_TIME,
        dataIndex: 'time',
        key: 'time',
        render: (time) => {
            if (!time) return

            const normalizeTime = secondCountToTimeString(time)

            return (
                <TooltipAdapter
                    title={`${normalizeTime} ${LOCAL.TOOLTIPS.TIME_DECODING}`}
                >
                    {normalizeTime}
                </TooltipAdapter>
            )
        },
    },
]
