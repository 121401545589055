import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, SpaceAdapter } from 'components/shared'
import { CoursesService } from 'core/api'
import { FormItemAdapter, SelectControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    createSuccessNotification,
    isDocumentStatusInWork,
    launchCourse,
} from 'utils'
import { useHistory } from 'react-router'
import { useHttp } from 'hooks'

import styles from './CourseVersions.module.scss'
import { AddCourseVersion } from '../AddCourseVersion'
import { CourseVersionsProps } from './CourseVersions.types'
import { normalizeRelatedCourseVersions } from './CourseVersions.utils'

/** Контроллер выбора версии курса*/
export const CourseVersions: React.FC<CourseVersionsProps> = React.memo(
    ({
        id,
        relatedCourseVersions,
        launchUrl,
        status,
        uniqKey,
        updateLoader,
        onFetchCourse,
    }) => {
        const history = useHistory()
        const [setAsArchive] = useHttp<{ id: number }>(
            CoursesService.setAsArchive,
            updateLoader
        )
        const [setCourseAsMainScopeVersion] = useHttp<{ id: number }>(
            CoursesService.setCourseAsMainScopeVersion,
            updateLoader
        )

        const mainVersion = relatedCourseVersions?.find((el) => el.isMain)
        const currentVersion = relatedCourseVersions?.find((el) => el.id === id)
        const isCurrentCourseMainVersion =
            mainVersion?.id === id || !currentVersion?.active
        const hasKey = !!uniqKey?.length

        const redirectToSelectVersion = useCallback(
            (value?: number) => {
                if (!value) return

                history.push(`${ROUTE_NAMES.TRAINING_MATERIALS_INFO}/${value}`)
            },
            [history]
        )

        const handleSetAsArchive = useCallback(async () => {
            await setAsArchive({
                id,
            })

            redirectToSelectVersion(mainVersion?.id)

            createSuccessNotification(LOCAL.MESSAGES.VERSION_DELETED)
        }, [redirectToSelectVersion, id, mainVersion, setAsArchive])

        const handleSetCourseAsMain = useCallback(async () => {
            await setCourseAsMainScopeVersion({
                id,
            })

            createSuccessNotification(LOCAL.MESSAGES.VERSION_CHOOSE_AS_MAIN)
            onFetchCourse?.(id)
        }, [id, setCourseAsMainScopeVersion, onFetchCourse])

        return (
            <SpaceAdapter
                align="start"
                fullWidth
                size={20}
                className={styles.wrapper}
            >
                <div>
                    <FormItemAdapter
                        fieldName="id"
                        label={LOCAL.LABELS.CONTENT_VERSION}
                        fullControlWidth
                    >
                        <SelectControl
                            onChange={redirectToSelectVersion}
                            values={normalizeRelatedCourseVersions(
                                relatedCourseVersions
                            )}
                            addonAfter={!hasKey && <AddCourseVersion />}
                        />
                    </FormItemAdapter>

                    <ButtonsToolbar align="space-between">
                        <Button onClick={() => launchCourse(launchUrl)}>
                            {LOCAL.ACTIONS.PLAY}
                        </Button>

                        <SpaceAdapter>
                            <Button
                                onClick={handleSetCourseAsMain}
                                disabled={isCurrentCourseMainVersion}
                            >
                                {LOCAL.ACTIONS.MAKE_MAIN}
                            </Button>

                            <Button
                                onClick={handleSetAsArchive}
                                disabled={
                                    isCurrentCourseMainVersion ||
                                    isDocumentStatusInWork(status) ||
                                    hasKey
                                }
                            >
                                {LOCAL.ACTIONS.TO_ARCHIVE}
                            </Button>
                        </SpaceAdapter>
                    </ButtonsToolbar>
                </div>
            </SpaceAdapter>
        )
    }
)
