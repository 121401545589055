import React from 'react'
import cn from 'classnames'
import { Form } from 'antd'

import styles from './FormItemAdapter.module.scss'
import { FormItemAdapterProps } from './FormItemAdapter.types'

/**
 * Адаптер для Form.Item, используется в тех случаях, когда нужна стилизация поля формы по стандарту для нашего проекта
 */
export const FormItemAdapter: React.FC<FormItemAdapterProps> = ({
    fieldName,
    children,
    className,
    labelAlign = 'left',
    fullControlWidth = true,
    withEmptyLabel = false,
    label,
    ...restProps
}) => {
    const classes = cn(
        styles.wrapper,
        className,
        fullControlWidth && styles.wrapperFullControlWidth,
        labelAlign === 'left' && styles.wrapperLabelAlignLeft,
        labelAlign === 'right' && styles.wrapperLabelAlignRight
    )

    return (
        <Form.Item
            {...restProps}
            name={fieldName}
            className={classes}
            label={withEmptyLabel ? ' ' : label}
        >
            {children}
        </Form.Item>
    )
}
