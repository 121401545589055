import React, { FC } from 'react'
import {
    CheckHomework,
    CheckHomeworkProcess,
    CheckHomeworkWithoutEditor,
    Homeworks,
    Monitoring,
    QuizChecking,
    ResultsOfMeetings,
} from 'components/pages/UserPersonalArea/components'
import { LOCAL } from 'core/local'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Redirect, Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

export const UserPersonalAreaRouting: FC = React.memo(() => (
    <Switch>
        <ProtectedRoute
            requiredPermissions={[UserPermission.LkHomeworkCheck]}
            path={`${ROUTE_NAMES.PA_CHECK_HOMEWORK}/lde/:id`}
        >
            <CheckHomeworkProcess />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.LkHomeworkCheck]}
            path={`${ROUTE_NAMES.PA_CHECK_HOMEWORK}/:id`}
        >
            <CheckHomeworkWithoutEditor />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.Lk]}
            path={ROUTE_NAMES.PA_HOMEWORK}
        >
            <Homeworks />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.LkHomeworkCheck]}
            path={ROUTE_NAMES.PA_CHECK_HOMEWORK}
        >
            <CheckHomework />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.LkQuizCheck]}
            path={ROUTE_NAMES.PA_CHECK_QUIZZES}
        >
            <QuizChecking />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.LkMeetingResult]}
            path={ROUTE_NAMES.PA_MEETINGS_RESULTS}
        >
            <ResultsOfMeetings />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.LkMonitoring]}
            path={ROUTE_NAMES.PA_MONITORING}
        >
            <Monitoring />
        </ProtectedRoute>

        <Route path={ROUTE_NAMES.USER_PERSONAL_AREA} exact>
            <Redirect push to={ROUTE_NAMES.PA_HOMEWORK} />
        </Route>

        <Route>{`${LOCAL.CATALOGS.WORK_IN_PROGRESS}...`}</Route>
    </Switch>
))
