import React, { FC, useCallback, useState } from 'react'
import {
    AppError,
    PositionProfileDictionariesService,
    PositionProfileFromFileContract,
    PositionProfileSaveContract,
    PositionProfilesService,
} from 'core/api'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitle,
    PageBanner,
    PageContent,
    TableAdapter,
} from 'components/shared'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { RcCustomRequestOptions } from 'types'
import { TABLE_DOCUMENT_TYPE } from 'consts'
import { Upload } from 'antd'
import { createSuccessNotification, getStringWithoutDots } from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'
import { useHttp } from 'hooks'
import { withLoader } from 'HOCs'

import { JOB_PROFILES_IMPORT_COLUMNS } from './JobProfilesImport.config'
import { mapParsedPositionProfilesForSave } from './JobProfiles.utils'

/** Компонент отображения импорта профилей должности */
export const JobProfilesImport: FC = withLoader(
    React.memo(({ updateLoader }) => {
        const [positionProfiles, setPositionProfiles] = useState<
            PositionProfileFromFileContract[]
        >()
        const [importingPP, setImportingPP] = useState(false)
        const [importingDictionaries, setImportingDictionaries] = useState(
            false
        )
        const { push } = useHistory()
        const [save] = useHttp(PositionProfilesService.saveMany, updateLoader)

        const onCancel = useCallback(() => {
            push(ROUTE_NAMES.POSITION_PROFILES)
        }, [push])

        /**
         * Обработчик импорта ПД
         */
        const handleImportPositionProfiles = useCallback(
            async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
                try {
                    setImportingPP(true)
                    const upload = await PositionProfilesService.parseFile({
                        file,
                    })

                    setPositionProfiles(upload)
                    onSuccess?.(upload, {} as XMLHttpRequest)
                } catch (error) {
                    onError?.(error as AppError)
                } finally {
                    setImportingPP(false)
                }
            },
            [setImportingPP]
        )

        /**
         * Обработчик импорта справочников
         */
        const handleImportDictionaries = useCallback(
            async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
                try {
                    setImportingDictionaries(true)
                    const upload = await PositionProfileDictionariesService.parseFile(
                        { file }
                    )

                    createSuccessNotification(
                        LOCAL.MESSAGES.DICTIONARIES_UPDATED
                    )
                    onSuccess?.(upload, {} as XMLHttpRequest)
                } catch (error) {
                    onError?.(error as AppError)
                } finally {
                    setImportingDictionaries(false)
                }
            },
            [setImportingDictionaries]
        )

        const onPublish = useCallback(() => {
            if (positionProfiles)
                save({
                    body: mapParsedPositionProfilesForSave(
                        positionProfiles
                    ) as PositionProfileSaveContract[],
                })
        }, [positionProfiles, save])

        return (
            <PageContent>
                <PageBanner />

                <ContentTitle
                    title={getRouteMeta(ROUTE_NAMES.POSITION_PROFILES)}
                >
                    <ButtonsToolbar>
                        <Button type="link" onClick={onCancel}>
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        <Upload
                            customRequest={handleImportPositionProfiles}
                            showUploadList={false}
                            accept={TABLE_DOCUMENT_TYPE}
                            disabled={importingPP}
                        >
                            <Button
                                type="primary"
                                loading={importingPP}
                                title={getStringWithoutDots(
                                    TABLE_DOCUMENT_TYPE
                                )}
                            >
                                {LOCAL.ACTIONS.IMPORT_POSITION_PROFILES}
                            </Button>
                        </Upload>

                        <Upload
                            customRequest={handleImportDictionaries}
                            showUploadList={false}
                            accept={TABLE_DOCUMENT_TYPE}
                            disabled={importingDictionaries}
                        >
                            <Button
                                type="primary"
                                loading={importingDictionaries}
                                title={getStringWithoutDots(
                                    TABLE_DOCUMENT_TYPE
                                )}
                            >
                                {LOCAL.ACTIONS.IMPORT_DICTIONARIES}
                            </Button>
                        </Upload>

                        <Button
                            type="primary"
                            disabled={!positionProfiles}
                            onClick={onPublish}
                        >
                            {LOCAL.ACTIONS.ADD}
                        </Button>
                    </ButtonsToolbar>
                </ContentTitle>

                <TableAdapter
                    columns={JOB_PROFILES_IMPORT_COLUMNS}
                    dataSource={positionProfiles}
                    pagination={false}
                />
            </PageContent>
        )
    })
)
