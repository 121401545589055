import {
    ComponentCommonProps,
    FnActionProps,
    FnActionRequiredProps,
} from 'App.types'
import { DataNode } from 'rc-tree-select/lib/interface'
import { OnBeforeChangeProps } from 'utils'
import { ReactNode } from 'react'
import { TreeSelectProps } from 'antd/lib/tree-select'

export enum TagsType {
    'Cloud' = 'Cloud',
    'List' = 'List',
    'ListView' = 'ListView',
}

/**
 * Интерфейс для MultiSelectControl
 */
export interface MultiSelectControlProps<T = number[]>
    extends Omit<TreeSelectProps<T>, 'value'>,
        ComponentCommonProps {
    /** значение контрола */
    value?: T | null

    /** callback при изменении контрола */
    onChange?: FnActionProps<T>

    /** тип вывода выбранных пунктов меню */
    tagsType?: TagsType

    /** отображение пункта "Выбрать все" */
    selectAll?: boolean

    /** контент после контрола */
    addonAfter?: ReactNode

    onBeforeChange?: FnActionRequiredProps<OnBeforeChangeProps>
}

/** интерфейс функции getMultiSelectTags */
export interface GetMultiSelectTagsProps {
    /** Тип ожидаемых тегов */
    tagsType: TagsType

    /** Коллекция данных для рендера options в dropdown меню */
    treeDataCollection: DataNode[]

    /** Функция выполняемая при удалении тега */
    onDeleteTag?: FnActionRequiredProps<number, FnActionProps>

    /** Коллекция идентификаторов текущих выбранных в мультиселекте элементов */
    selectedItemsIds?: number[]

    /** Если true, то теги блокируются и не реагируют на действия пользователя */
    isTagsDisabled?: boolean
}

/** Общий интерфейс для служебных функций подготовки тегов для рендера */
export interface GettingTagsUtilsProps {
    itemId: number
    onDeleteTag?: FnActionRequiredProps<number, FnActionProps>
    isTagsDisabled?: boolean
    label: DataNode['label']
}

export interface GetTagLabelProps
    extends Pick<GettingTagsUtilsProps, 'itemId'> {
    treeDataCollection: DataNode[]
}
