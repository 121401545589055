export const MLSEC_IN_SECOND = 1000
export const SEC_IN_MINUTE = 60
export const SEC_IN_HOUR = 3600

export const NULLABLE_DATE = '0001-01-01T00:00:00'

export const DATE_FORMAT = {
    DATE: 'DD.MM.YYYY',
    WITH_TIME: 'DD.MM.YYYY HH:mm',
    LIST: ['DD.MM.YYYY', 'DD.MM.YY'],
    LIST_WITH_TIME: ['DD.MM.YYYY HH:mm', 'DD.MM.YY HH:mm'],
    LIST_YEAR: ['YYYY', 'YYYY'],
    YEAR: ['YYYY'],
    mm_ss: 'mm:ss',
    HH_mm_ss: 'HH:mm:ss',
    HH_mm: 'HH:mm',
}
