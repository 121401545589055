import React, { useCallback } from 'react'
import { CheckboxControl } from 'components/controls'
import { RECOMMENDATIONS_TYPES } from 'consts'

import styles from './UserProfileRecommendationsItem.module.scss'
import { ControlGroupItem } from '../ControlGroupItem'
import { UserProfileRecommendationsItemProps } from './UserProfileRecommendationsItemProps.types'

/** Рекомендации на странице профиля пользователя */
export const UserProfileRecommendationsItem: React.FC<UserProfileRecommendationsItemProps> = React.memo(
    ({ recommendation, onChosenRecommendation, checked }) => {
        /**
         * Обработчик изменения чекбокса
         */
        const handleChangeRecommendation = useCallback(
            (checked?: boolean) => {
                onChosenRecommendation({
                    item: recommendation,
                    isAdd: checked,
                })
            },
            [recommendation, onChosenRecommendation]
        )

        return (
            <ControlGroupItem type="ghost" className={styles.wrapper}>
                <CheckboxControl
                    onChange={handleChangeRecommendation}
                    checked={checked}
                    className={styles.checkboxControl}
                >
                    {recommendation.name}
                </CheckboxControl>

                <span className={styles.type}>
                    {RECOMMENDATIONS_TYPES[recommendation.type]}
                </span>
            </ControlGroupItem>
        )
    }
)
