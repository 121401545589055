import React from 'react'

import styles from './CatalogSection.module.scss'
import { CatalogSectionProps } from './CatalogSection.types'

/** Компонент для получения шаблонной секции каталога */
export const CatalogSection: React.FC<CatalogSectionProps> = React.memo(
    ({ header, content }) => (
        <section className={styles.wrapper}>
            <div className={styles.header}>{header}</div>

            <div>{content}</div>
        </section>
    )
)
