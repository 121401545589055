import React from 'react'
import { Anchor, Divider } from 'antd'
import { AnchorDecorator, IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { MAIN_HEADER_HEIGHT } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'

import styles from './ArticleContents.module.scss'
import { ArticleContentsProps } from './ArticleContents.types'

/** Содержание статьи */
export const ArticleContents: React.FC<ArticleContentsProps> = React.memo(
    ({ article }) => (
        <AnchorDecorator
            offsetTop={MAIN_HEADER_HEIGHT}
            className={styles.wrapper}
        >
            <Link to={ROUTE_NAMES.KNOWLEDGE_BASE} className={styles.back}>
                <IconsAdapter iconType="ArrowLeftOutlined" />

                <span
                    className={styles.linkText}
                >{`${LOCAL.LINKS.PREFIX} "${LOCAL.CATALOGS.KNOWLEDGE_BASE}"`}</span>
            </Link>

            <Divider />

            <h3>{LOCAL.LABELS.SUBSTANCE}</h3>

            <ul className={styles.list}>
                {article?.chapters?.map((el) => (
                    <li className={styles.title} key={el.id}>
                        <Anchor.Link href={`#${el.id}`} title={el.title} />
                    </li>
                ))}
            </ul>
        </AnchorDecorator>
    )
)
