import cn from 'classnames'
import React, { useContext, useMemo } from 'react'
import { ActivityPassingStatus } from 'core/api'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { TrackPassingContext } from 'components/pages/TrackPassing/TrackPassing.context'
import { isThemeBlockTypeHomework } from 'utils'

import styles from './ModalSingleBlock.module.scss'
import { ActivityChildProps } from '../../ActivityLayer.types'
import { CreateModalBlockProps } from './ModalSingleBlock.types'
import { getChain } from './ModalSingleBlock.render'

export const ModalSingleBlock: React.FC<
    {
        lessonIndex: number
    } & ActivityChildProps
> = React.memo(({ lessonIndex, activity }) => {
    const { store, updateStore } = useContext(TrackPassingContext)

    const { activeLessonIndex } = store

    const lessons = activity?.activityLessonPassings
    const canvas = activity?.canvas

    const lesson = lessons?.[lessonIndex]

    const modalOptions = useMemo<CreateModalBlockProps>(() => {
        switch (lesson?.status) {
            case ActivityPassingStatus.CompletedSuccessfully:
                return {
                    color: canvas?.thematicBlockColorAfterPassing,
                    className: styles.completedStage,
                    iconType: 'CheckOutlined',
                    iconColor: canvas?.thematicBlockIconColor,
                    backgroundColor: canvas?.thematicBlockColorAfterPassing,
                }

            case ActivityPassingStatus.Waiting:
                return {
                    className: styles.currentStage,
                    color: canvas?.thematicBlockColorBeforePassing,
                }

            case ActivityPassingStatus.InProgress:
                return {
                    className: styles.currentStage,
                    color: canvas?.thematicBlockColorWhilePassing,
                }

            default:
                return {
                    className: styles.blockedStage,
                    color: canvas?.thematicBlockColorBeforePassing,
                    iconType: 'LockOutlined',
                }
        }
    }, [canvas, lesson])

    return (
        <div
            style={{
                borderColor: modalOptions.color,
                backgroundColor: modalOptions.backgroundColor,
            }}
            className={cn(
                modalOptions.className,
                isThemeBlockTypeHomework(lesson?.type) && styles.squareStage,
                activeLessonIndex === lessonIndex && styles.activeBlock
            )}
            onClick={() => {
                updateStore({ activeLessonIndex: lessonIndex })
            }}
        >
            {getChain(modalOptions.color)}

            <IconsAdapter
                iconType={modalOptions.iconType}
                style={{
                    color: modalOptions.iconColor || modalOptions.color,
                }}
            />
        </div>
    )
})
