import { ACTIONS } from './actions.local.config'
import { CATALOGS } from './catalogs.local.config'
import { DOCUMENT_META } from './documentMeta.local.config'
import { ERRORS } from './errors.local.config'
import { LABELS } from './labels.local.config'
import { LINKS } from './links.local.config'
import { MESSAGES } from './messages.local.config'
import { PERMISSIONS } from './permissions.local.config'
import { PLACEHOLDERS } from './placeholders.local.config'
import { STATUSES } from './statuses.local.config'
import { TOOLTIPS } from './tooltips.local.config'

export const LOCAL = {
    ACTIONS,
    LABELS,
    CATALOGS,
    STATUSES,
    PLACEHOLDERS,
    LINKS,
    MESSAGES,
    ERRORS,
    PERMISSIONS,
    DOCUMENT_META,
    TOOLTIPS,

    ACTIVE_EMPLOYEE: 'Активен',
    ACTIVE_POSITION: 'Активна',
    ADDED: 'Добавлено',
    BY_THEMATIC_BLOCKS: 'По тематическим блокам',
    DELETE_SELECTED: 'Удалить выбранные',
    EXIT: 'Выход',
    FAST: 'Быстро',
    FROM: 'из',
    HHMMSS: 'ЧЧ:ММ:СС',
    HOURS: 'ч',
    MARK_AS_READ: 'Отметить прочитанным',
    MARK_AS_READ_SELECTED: 'Отметить прочитанными выбранные',
    MIDDLE: 'Средне',
    MIN: 'Мин',
    MINUTES: 'минут',
    NEXT: 'Следующий',
    NO: 'Нет',
    NOT_ACTIVE_EMPLOYEE: 'Не активен',
    OCCASIONS: 'раз',
    OK: 'ОК',
    PREV: 'Предыдущий',
    RESPECTED: 'Уважаемый(ая)',
    SINCE: 'с',
    SLOW: 'Медленно',
    STANDARD: 'Стандартное',
    STRONG_SIDE: 'Сильная',
    THROUGHOUT_THE_EVENT: 'По всему мероприятию',
    TIME_BEFORE: 'За',
    TO: 'по',
    TO_PROFILES_LIST: 'к списку профайлов',
    TO_REGISTER: 'в реестр',
    WEAK_SIDE: 'Слабая',
    YES: 'Да',
    ABOUT_SYSTEM_TITLE: 'Онлайн-университет Правительства Москвы',
    NEW_VALUE: 'новое значение',
    NEW_TAG: 'новый тег',
}
