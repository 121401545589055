import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import styles from './ButtonsToolbar.module.scss'
import { ButtonsToolbarProps } from './ButtonsToolbar.types'

/**
 * Панель инструментов, группировка для кнопок
 */
export const ButtonsToolbar: React.FC<ButtonsToolbarProps> = React.memo(
    ({
        children,
        align = 'flex-end',
        className,
        flexGrow,
        bottomIndent,
        topIndent,
        ...restValues
    }) => (
        <div
            className={classNames(
                styles.wrapper,
                flexGrow && styles.wrapperFlexGrow,
                className,
                !isNil(bottomIndent) && `mb-${bottomIndent}`,
                !isNil(topIndent) && `mt-${topIndent}`
            )}
            style={{ justifyContent: align }}
            {...restValues}
        >
            {children}
        </div>
    )
)
