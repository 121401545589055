import { ChartDataset, ChartOptions, TooltipItem } from 'chart.js'
import { ColorThemes } from 'contexts'
import { LOCAL } from 'core/local'
import { PercentileReport360Contract } from 'core/api'
import { REPORT_LEVELS_OPTIONS, ReportLevels } from 'consts'
import { getRoundedOneCharNumber } from 'utils'

import {
    getAnnotationConfig,
    getCoordinates,
    getPercentileCompetencesColor,
    getPercentileLabelsConfig,
    hexLowOpacity,
    hexMediumOpacity,
} from './Percentile.utils'

export const makePercentileDiagramOptions = (
    theme: ColorThemes
): ChartOptions => {
    const percentileLabelsConfig = getPercentileLabelsConfig(theme)
    const percentileCompetencesColorConfig = getPercentileCompetencesColor(
        theme,
        true
    )

    return {
        animation: {
            duration: 0,
        },
        scales: {
            y: {
                title: {
                    text: LOCAL.LABELS.PERCENTILE_IN_ASSESSMENT_PROJECT,
                    display: true,
                    ...percentileLabelsConfig,
                },
                type: 'linear',
                grid: { display: false },
                ticks: {
                    stepSize: 25,
                    callback: (val) => `${val}%`,
                    ...percentileLabelsConfig,
                },
                min: 0,
                max: 100,
                beginAtZero: true,
            },

            x: {
                title: {
                    text: LOCAL.LABELS.ASSESSMENT_WITHOUT_SELF_ESTEEM,
                    display: true,
                    ...percentileLabelsConfig,
                },
                type: 'linear',
                grid: { display: false },
                ticks: {
                    stepSize: 1,
                    ...percentileLabelsConfig,
                },
                beginAtZero: true,
                min: 0,
                max: 5,
            },
        },
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                titleFont: { weight: 'normal' },
                callbacks: {
                    title: (data: TooltipItem<'line'>[]) => {
                        const raw = data[0].raw as { x: number }

                        return `${raw.x} - ${LOCAL.LABELS.COMPETENCE_ASSESSMENT}`
                    },
                    label: (data: TooltipItem<'line'>) => {
                        const raw = data.raw as { y: number }

                        return `${raw.y}% - ${LOCAL.LABELS.COMPETENCE_PERCENTILE}`
                    },
                },
            },

            /**
             * настройка фона графика
             */

            annotation: {
                annotations: {
                    box1: {
                        backgroundColor:
                            REPORT_LEVELS_OPTIONS[ReportLevels.Low].COLOR,
                        ...getCoordinates(0, 25, 0, 2.5),
                        ...getAnnotationConfig(
                            percentileCompetencesColorConfig
                        ),
                    },
                    box2: {
                        backgroundColor: hexMediumOpacity(
                            REPORT_LEVELS_OPTIONS[ReportLevels.Low].COLOR
                        ),
                        ...getCoordinates(25, 75, 0, 2.5),
                        ...getAnnotationConfig(
                            percentileCompetencesColorConfig
                        ),
                    },
                    box3: {
                        backgroundColor: hexLowOpacity(
                            REPORT_LEVELS_OPTIONS[ReportLevels.Low].COLOR
                        ),
                        ...getCoordinates(75, 100, 0, 2.5),
                        ...getAnnotationConfig(
                            percentileCompetencesColorConfig
                        ),
                    },
                    box4: {
                        backgroundColor:
                            REPORT_LEVELS_OPTIONS[ReportLevels.Medium].COLOR,
                        ...getCoordinates(0, 25, 2.5, 3.5),
                        ...getAnnotationConfig(
                            percentileCompetencesColorConfig
                        ),
                    },
                    box5: {
                        backgroundColor:
                            REPORT_LEVELS_OPTIONS[ReportLevels.Medium].COLOR,
                        ...getCoordinates(25, 75, 2.5, 3.5),
                        ...getAnnotationConfig(
                            percentileCompetencesColorConfig
                        ),
                    },
                    box6: {
                        backgroundColor:
                            REPORT_LEVELS_OPTIONS[ReportLevels.Medium].COLOR,
                        ...getCoordinates(75, 100, 2.5, 3.5),
                        ...getAnnotationConfig(
                            percentileCompetencesColorConfig
                        ),
                    },
                    box7: {
                        backgroundColor: hexLowOpacity(
                            REPORT_LEVELS_OPTIONS[ReportLevels.High].COLOR
                        ),
                        ...getCoordinates(0, 25, 3.5, 5),
                        ...getAnnotationConfig(
                            percentileCompetencesColorConfig
                        ),
                    },
                    box8: {
                        backgroundColor: hexMediumOpacity(
                            REPORT_LEVELS_OPTIONS[ReportLevels.High].COLOR
                        ),
                        ...getCoordinates(25, 75, 3.5, 5),
                        ...getAnnotationConfig(
                            percentileCompetencesColorConfig
                        ),
                    },
                    box9: {
                        backgroundColor:
                            REPORT_LEVELS_OPTIONS[ReportLevels.High].COLOR,
                        ...getCoordinates(75, 100, 3.5, 5),
                        ...getAnnotationConfig(
                            percentileCompetencesColorConfig
                        ),
                    },
                },
            },
        },
    }
}

/**
 * Маппинг данных с бека для графика
 */
export const preparePercentilesForDiagram = (
    theme: ColorThemes,
    percentiles: PercentileReport360Contract[]
): ChartDataset => {
    const percentileCompetencesColorConfig = getPercentileCompetencesColor(
        theme
    )
    const percentileCompetencesColorConfigInverse = getPercentileCompetencesColor(
        theme,
        true
    )

    return {
        showLine: false,
        backgroundColor: percentileCompetencesColorConfig,
        borderColor: percentileCompetencesColorConfig,
        pointBorderWidth: 4,
        datalabels: {
            display: true,
            anchor: 'center',
            align: 'right',
            offset: 10,
            color: percentileCompetencesColorConfig,
            font: {
                size: 16,
            },
            textShadowColor: percentileCompetencesColorConfigInverse,
            textShadowBlur: 3,
            formatter: (value, context) =>
                percentiles[context.dataIndex].competence.name,
        },
        data: percentiles.map((item) => ({
            x: getRoundedOneCharNumber(
                item.averageScoreWithoutSelfEstim
            ) as number,
            y: item.percentile,
        })),
    }
}
