import React, { useCallback, useEffect } from 'react'
import { Divider } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { PopupWithForm } from 'components/shared/PopupWithForm'
import { SelectControl } from 'components/controls/SelectControl'
import { normalizeDataForSelectAndRadio } from 'utils'
import { useHttp } from 'hooks'

import styles from './SelectWithAddButtonControl.module.scss'
import { SelectWithAddButtonControlProps } from './SelectWithAddButtonControl.types'

/**
 * Селект с кнопкой "Добавить"
 */
export const SelectWithAddButtonControl: React.FC<SelectWithAddButtonControlProps> = React.memo(
    ({
        formMode,
        onChange,
        value,
        request,
        formId,
        formComponent,
        addButtonText,
        modalOptions = {},
    }) => {
        const [getData, data] = useHttp(request)

        const handleRequestFinish = useCallback(getData, [getData])

        const renderSearchComponentShowButton = useCallback(
            (menu: React.ReactElement) => (
                <>
                    {menu}

                    <Divider className={styles.divider} />

                    <PopupWithForm
                        formId={formId}
                        buttonOption={{
                            icon: (
                                <IconsAdapter iconType="PlusCircleOutlined" />
                            ),
                            className: 'add-button',
                        }}
                        buttonText={addButtonText || LOCAL.ACTIONS.ADD}
                        modalOptions={{
                            title: LOCAL.ACTIONS.ADD,
                            okText: LOCAL.ACTIONS.ADD,
                            ...modalOptions,
                        }}
                        onRequestFinish={handleRequestFinish}
                        onRefetch={getData}
                        component={formComponent}
                    />
                </>
            ),
            [
                addButtonText,
                formComponent,
                formId,
                modalOptions,
                handleRequestFinish,
                getData,
            ]
        )

        useEffect(() => {
            getData()
        }, [getData])

        return (
            <SelectControl
                notFoundContent={LOCAL.LABELS.SELECT_FROM_DIRECTORY}
                values={data?.map(normalizeDataForSelectAndRadio)}
                formMode={formMode}
                value={value}
                onChange={onChange}
                dropdownRender={renderSearchComponentShowButton}
            />
        )
    }
)
