import React from 'react'
import { Col, Row } from 'antd'
import { ComponentCommonProps } from 'App.types'
import {
    ControlGroup,
    DatePickerControl,
    FormItemAdapter,
    InputControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { TooltipAdapter } from 'components/shared'

export const RequestGroup: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <ControlGroup type="dark" title={LOCAL.LABELS.REQUEST}>
            <TooltipAdapter
                title={LOCAL.TOOLTIPS.REQUEST_NUMBER_OF_JOB_PROFILE}
            >
                <FormItemAdapter
                    fieldName="requestNumber"
                    label={LOCAL.LABELS.REQUEST_NUMBER}
                >
                    <InputControl formMode={formMode} />
                </FormItemAdapter>
            </TooltipAdapter>

            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <TooltipAdapter
                        title={LOCAL.TOOLTIPS.REQUEST_DATE_OF_JOB_PROFILE}
                    >
                        <FormItemAdapter
                            fieldName="requestDate"
                            label={LOCAL.LABELS.REQUEST_DATE}
                        >
                            <DatePickerControl formMode={formMode} />
                        </FormItemAdapter>
                    </TooltipAdapter>
                </Col>

                <Col xs={12}>
                    <TooltipAdapter
                        title={LOCAL.TOOLTIPS.EXECUTION_DATE_OF_JOB_PROFILE}
                    >
                        <FormItemAdapter
                            fieldName="requestCompletionDate"
                            label={LOCAL.LABELS.EXECUTION_DATE}
                        >
                            <DatePickerControl formMode={formMode} />
                        </FormItemAdapter>
                    </TooltipAdapter>
                </Col>
            </Row>
        </ControlGroup>
    )
)
