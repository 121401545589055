import { Dispatch, SetStateAction } from 'react'
import { FnActionProps } from 'App.types'

export enum TimerStatuses {
    Pause = 'pause',
    Running = 'running',
    Stopped = 'stopped',
}

/** Интерфейс компонента таймера */
export interface QuestionTimerProps {
    /** Текущее значение таймера */
    timerValue: number
    /** Функция установки состояния при тике таймера */
    onTick: Dispatch<SetStateAction<number>>
    /** Функция вызываемая при старте таймера */
    onStart?: FnActionProps
    /** Функция вызываемая при остановке таймера */
    onEnd?: FnActionProps
    /** Текущий статус работы таймера */
    timerStatus?: TimerStatuses
}
