import React, { useEffect } from 'react'

import styles from './ScoreControl.module.scss'
import { ScoreControlProps } from './ScoreControl.types'

/**
 * Кастомный контрол для управления значением итогового счета по оценкам за прохождение теста
 */
export const ScoreControl: React.FC<ScoreControlProps> = React.memo(
    ({ onChange, score }) => {
        useEffect(() => {
            onChange?.(score)
        }, [score, onChange])

        return <span className={styles.wrapper}>{score}</span>
    }
)
