import React, { FC, useContext } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { AssessmentType } from 'core/api'
import { CollapseSingle } from 'components/shared/CollapseSingle'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ShouldUpdateChecker } from 'components/controls'
import { isFormModeView } from 'utils'

import styles from './PortfolioReportWindow.module.scss'
import { PortfolioFormCommonTabsContext } from '../../../PortfolioFormCommonTabs.context'

/** Блок настроек оформления для портфелей - блок отчет */
export const PortfolioReportWindow: FC = React.memo(() => {
    const { store } = useContext(PortfolioFormCommonTabsContext)
    const { disabledByStatus, formMode } = store

    return (
        <CollapseSingle title={LOCAL.LABELS.REPORT}>
            <div className={styles.wrapper}>
                <span className={styles.wrapperText}>
                    {LOCAL.LABELS.REPORT_TEXT}
                </span>

                <ShouldUpdateChecker fieldPath={['type']}>
                    {({ getFieldValue }) => {
                        const type: AssessmentType = getFieldValue('type')

                        return (
                            <Link
                                to={`${ROUTE_NAMES.ASSESSMENT_REPORTS_SETTINGS}/${type}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <AddButton
                                    disabled={
                                        isFormModeView(formMode) ||
                                        disabledByStatus
                                    }
                                    buttonText={LOCAL.ACTIONS.SET}
                                    icon={
                                        <IconsAdapter iconType="FileAddOutlined" />
                                    }
                                />
                            </Link>
                        )
                    }}
                </ShouldUpdateChecker>
            </div>
        </CollapseSingle>
    )
})
