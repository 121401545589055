import React from 'react'
import { LOCAL } from 'core/local'

import styles from './WindowTextSettings.module.scss'

/** Настройки выравнивания текста */
export const FONT_STYLE_OPTIONS = {
    bold: <span className={styles.bold}>{LOCAL.LABELS.FONT_BOLD_SYMBOL}</span>,
    italic: (
        <span className={styles.italic}>{LOCAL.LABELS.FONT_ITALIC_SYMBOL}</span>
    ),
    underline: (
        <span className={styles.underline}>
            {LOCAL.LABELS.FONT_UNDERLINE_SYMBOL}
        </span>
    ),
}
