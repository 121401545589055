import React from 'react'
import { APPOINTMENT_TYPES, QUIZ_CHECK_STATUSES } from 'consts'
import {
    AppointmentType,
    QuizCheckRegistryContract,
    QuizCheckStatus,
} from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { LOCAL } from 'core/local'
import { WidthRestrictedElement } from 'components/shared'
import { getFullFio, getShortFio, restrictBigText } from 'utils'

import { QuizCheckingActions } from './components'

export const getTableColumns = ({
    reFetchDictionary,
}: DictionaryPageActionsProps): ColumnsType<QuizCheckRegistryContract> => [
    {
        title: LOCAL.LABELS.TRACK_OR_EVENT,
        dataIndex: 'objectName',
        key: 'objectName',
        sorter: defaultSorter,
        render: restrictBigText,
        fixed: 'left',
    },
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type: AppointmentType) => APPOINTMENT_TYPES[type],
    },
    {
        title: LOCAL.LABELS.ASSIGNMENT,
        dataIndex: 'appointmentName',
        key: 'appointmentName',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.REVIEWER,
        dataIndex: 'tutor',
        key: 'tutor',
        render: (tutor) => (
            <WidthRestrictedElement
                title={getShortFio(tutor)}
                tooltip={{ title: getFullFio(tutor) }}
                showTooltipAlways
            />
        ),
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status: QuizCheckStatus) => QUIZ_CHECK_STATUSES[status],
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (row: QuizCheckRegistryContract) => (
            <QuizCheckingActions tableRow={row} onRefetch={reFetchDictionary} />
        ),
        fixed: 'right',
    },
]
