import ChartDataLabels from 'chartjs-plugin-datalabels'
import annotationPlugin from 'chartjs-plugin-annotation'
import React, { useEffect, useRef } from 'react'
import { Chart, registerables } from 'chart.js'

import { DiagramProps } from './Diagram.types'

/** Общий компонент диаграмм */
export const Diagram: React.FC<DiagramProps> = React.memo(
    ({ type, data, options, chartDataLabelsPlugin, getChartInstance }) => {
        Chart.register(...registerables, annotationPlugin)

        const canvasRef = useRef<HTMLCanvasElement>(null)
        const chartRef = useRef<Chart>()

        useEffect(() => {
            const ctx = canvasRef.current?.getContext('2d')

            if (!ctx) return

            chartRef.current = new Chart(ctx, {
                type,
                plugins: chartDataLabelsPlugin ? [ChartDataLabels] : [],
                data: [] as any,
            })

            getChartInstance?.(chartRef.current)
        }, [type, chartDataLabelsPlugin, getChartInstance])

        useEffect(() => {
            const chart = chartRef.current

            if (chart && data) {
                chart.data = data
                chart.update('resize')
            }
        }, [data])

        useEffect(() => {
            const chart = chartRef.current

            if (chart && options) {
                chart.options = options
                chart.update('resize')
            }
        }, [options])

        return <canvas ref={canvasRef} id={type} />
    }
)
