import React from 'react'
import {
    AddUsersTableControl,
    FormItemAdapter,
    HiddenField,
    ShouldUpdateChecker,
} from 'components/controls'
import { CollapseSingle } from 'components/shared'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { isAvailableToWatch } from 'utils'

import styles from './AssessmentManagerSettings.module.scss'
import { AssessmentManagerOptionsProps } from './AssessmentManagerSettings.types'
import { AssessmentPosition } from '../AssessmentPosition'
import {
    assessedPositionFieldName,
    candidatesFieldNamePath,
} from './AssessmentManagerSettings.consts'
import { portfolioIdFieldNamePath } from '../AssessmentAssignmentForm/AssessmentAssignmentForm.consts'

/**
 * Опции назначения оценки руководителем
 */
export const AssessmentManagerSettings: React.FC<AssessmentManagerOptionsProps> = React.memo(
    ({
        formMode,
        initialValuesForEdit,
        visibleAssessmentObjects,
        disabled,
    }) => (
        <div>
            {visibleAssessmentObjects && (
                <CollapseSingle title={LOCAL.LABELS.ASSESSMENT_OBJECTS} active>
                    <Form.List name={candidatesFieldNamePath}>
                        {(fields) =>
                            fields.map((field) => (
                                <React.Fragment key={field.key}>
                                    <ShouldUpdateChecker
                                        fieldPath={portfolioIdFieldNamePath}
                                    >
                                        <HiddenField
                                            fieldName={[
                                                field.name,
                                                assessedPositionFieldName,
                                            ]}
                                        />

                                        <ShouldUpdateChecker
                                            fieldPath={[
                                                ...candidatesFieldNamePath,
                                                field.name,
                                                assessedPositionFieldName,
                                            ]}
                                        >
                                            {({ getFieldValue }) => (
                                                <FormItemAdapter
                                                    fieldName={[
                                                        field.name,
                                                        'candidateIds',
                                                    ]}
                                                >
                                                    <AssessmentPosition
                                                        formMode={formMode}
                                                        positionData={getFieldValue(
                                                            [
                                                                ...candidatesFieldNamePath,
                                                                field.name,
                                                                assessedPositionFieldName,
                                                            ]
                                                        )}
                                                        disabled={disabled}
                                                    />
                                                </FormItemAdapter>
                                            )}
                                        </ShouldUpdateChecker>
                                    </ShouldUpdateChecker>
                                </React.Fragment>
                            ))
                        }
                    </Form.List>
                </CollapseSingle>
            )}

            <FormItemAdapter
                noStyle
                fieldName={['assessment', 'managerParameters', 'supervisor']}
            >
                <AddUsersTableControl
                    formMode={formMode}
                    disabled={
                        !!initialValuesForEdit?.id &&
                        isAvailableToWatch(initialValuesForEdit?.status)
                    }
                    rowSelectionType="radio"
                    title={LOCAL.LABELS.MANAGER}
                    modalTitle={LOCAL.LABELS.USERS}
                    required
                    className={styles.table}
                />
            </FormItemAdapter>

            <FormItemAdapter
                noStyle
                fieldName={[
                    'assessment',
                    'managerParameters',
                    'checkingPersons',
                ]}
            >
                <AddUsersTableControl
                    formMode={formMode}
                    disabled={
                        !!initialValuesForEdit?.id &&
                        isAvailableToWatch(initialValuesForEdit?.status)
                    }
                    title={LOCAL.LABELS.REVIEWERS}
                    modalTitle={LOCAL.LABELS.USERS}
                    className={styles.table}
                />
            </FormItemAdapter>
        </div>
    )
)
