import { useCallback, useState } from 'react'

interface RecordProps<T> {
    id: number
    children?: RecordProps<T>[]
}

/**
 * Хук скрытия/раскрытия вложенной структуры
 */
export const useExpandKeys = <T>() => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

    /** Найти ids, которые необходимо скрыть с учетом вложенной структуры */
    const searchForIdsToHide = useCallback(
        (
            selectedIds: number[],
            record: RecordProps<T>,
            ids: number[] = []
        ): number[] => {
            if (selectedIds.includes(record.id)) {
                ids.push(record.id)
            }

            record.children?.forEach((child) =>
                searchForIdsToHide(selectedIds, child, ids)
            )

            return ids
        },
        []
    )
    /**
     * Отфильтровать все id с учетом вложенной структуры
     */
    const filterNestedUnits = useCallback(
        (selectedIds: number[], record: RecordProps<T>): number[] => {
            const idsToHide = searchForIdsToHide(selectedIds, record)

            return selectedIds.filter((id) => !idsToHide.includes(id))
        },
        [searchForIdsToHide]
    )

    /** Обработчик раскрытия/скрытия строки таблицы */
    const handleExpandRow = useCallback(
        (expanded: boolean, record: RecordProps<T>) => {
            setExpandedRowKeys((prev) =>
                expanded
                    ? [...prev, record.id]
                    : filterNestedUnits(prev, record)
            )
        },
        [filterNestedUnits]
    )

    return { expandedRowKeys, setExpandedRowKeys, handleExpandRow }
}
