import cn from 'classnames'
import React, { useCallback, useEffect, useRef } from 'react'
import { ScormAdapterHelper } from 'core/helpers/scorm'
import { useRrweb } from 'hooks'
import { withLoader } from 'HOCs'

import styles from './PlayerIframe.module.scss'
import { PlayerIframeProps } from './PlayerIframe.types'

/** Плеер для открытия файлов в iframe */
export const PlayerIframe: React.FC<PlayerIframeProps> = withLoader(
    React.memo(
        ({
            fileUrl,
            className,
            iframeOptions,
            format,
            onLoad,
            enableLoader,
            updateLoader,
            courseData,
        }) => {
            const iframeRef = useRef<HTMLIFrameElement>(null)
            const { stopMonitoring } = useRrweb()

            useEffect(() => {
                if (!courseData) return

                ScormAdapterHelper.mount(format, courseData?.id)

                return () => {
                    ScormAdapterHelper.unmount()
                }
            }, [courseData, format])

            useEffect(() => {
                if (enableLoader) {
                    updateLoader(true)
                }
            }, [enableLoader, updateLoader])

            useEffect(() => {
                stopMonitoring()
            }, [stopMonitoring])

            /** Обработчик загрузки айфрейма */
            const handleLoad = useCallback(() => {
                if (iframeRef.current && onLoad) {
                    onLoad(iframeRef.current)
                }
                if (enableLoader) {
                    updateLoader(false)
                }
            }, [enableLoader, updateLoader, onLoad])

            return (
                <iframe
                    onLoad={handleLoad}
                    ref={iframeRef}
                    src={fileUrl}
                    title={fileUrl}
                    className={cn(styles.wrapper, className)}
                    {...iframeOptions}
                />
            )
        }
    ),
    {
        fullWidthHeight: true,
    }
)
