import React from 'react'
import {
    AssessmentProjectSearchContract,
    AssessmentProjectSearchResultContract,
    AssessmentProjectService,
    UserPermission,
} from 'core/api'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getPageTitleFromRoute, getRowHandlers } from 'utils'
import { getRowClassNameArchive } from 'components/shared'
import { useDictionariesHelper } from 'hooks'
import { useLocation } from 'react-router-dom'

import { AssessmentProjectsFilters } from './components/AssessmentProjectsFilters'
import { ORDER_RULES } from './AssessmentProjects.const'
import { getColumns } from './AssessmentProjects.config'

/** Страница оценочных проектов */
export const AssessmentProjects = React.memo(() => {
    const locales = useDictionariesHelper(['assessmentTypeDictionary'])
    const location = useLocation()
    const title = getPageTitleFromRoute(location.pathname)

    return (
        <DictionaryPage<
            AssessmentProjectSearchContract,
            AssessmentProjectSearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.ASSESSMENT_PROJECTS}
            contentTitle={title}
            tableColumns={getColumns(locales)}
            searchMethod={AssessmentProjectService.search}
            quickSearchMethod={AssessmentProjectService.searchText}
            deleteMethod={AssessmentProjectService.delete}
            createRecordLink={{
                path: ROUTE_NAMES.ASSESSMENT_PROJECTS_CREATE,
            }}
            rowClassName={getRowClassNameArchive}
            onRow={getRowHandlers({
                url: ROUTE_NAMES.ASSESSMENT_PROJECTS_VIEW,
            })}
            createPermissions={[UserPermission.AssessmentProjectSave]}
            filterComponent={AssessmentProjectsFilters}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES,
                },
            }}
        />
    )
})
