import React, { useEffect } from 'react'
import { Circle360ReportService } from 'core/api'
import { ReportLevels } from 'consts'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import { makeCompetenceLevelsBlock } from './CompetenciesLowHighLevel.utils'

/** Отчет по оценке 360. Общая информация */
export const CompetenciesLowHighLevel: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(
        Circle360ReportService.getCompetenciesLowHighLevel
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return (
        <>
            {makeCompetenceLevelsBlock(
                ReportLevels.Low,
                data?.lowLevelCompetences
            )}

            {makeCompetenceLevelsBlock(
                ReportLevels.High,
                data?.highLevelCompetences
            )}
        </>
    )
})
