import React from 'react'
import cn from 'classnames'
import { Draggable } from 'react-beautiful-dnd'
import { Spoiler } from 'components/shared/Spoiler'

import styles from './ThemeBlockDraggable.module.scss'
import { ThemeBlock } from '../ThemeBlock'
import { ThemeBlockDraggableProps } from './ThemeBlockDraggable.types'
import { ThemeBlockHeader } from '../ThemeBlockHeader'

/** Тематический блок с возможностью перетаскивания */
export const ThemeBlockDraggable: React.FC<ThemeBlockDraggableProps> = React.memo(
    ({ dndKey, index, formMode, field, remove, disabled }) => (
        <Draggable draggableId={dndKey} index={index} isDragDisabled={disabled}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={provided.draggableProps.style}
                    className={cn(snapshot.isDragging && styles.dragging)}
                >
                    <Spoiler
                        className={styles.spoiler}
                        spoilerTitleRender={(spoilerProps) => (
                            <ThemeBlockHeader
                                field={field}
                                spoilerProps={spoilerProps}
                                removeFn={remove}
                                formMode={formMode}
                                themeBlockNumber={index}
                                dragHandleProps={provided.dragHandleProps}
                                disabled={disabled}
                            />
                        )}
                    >
                        <ThemeBlock
                            field={field}
                            formMode={formMode}
                            disabled={disabled}
                            className={styles.themeBlock}
                        />
                    </Spoiler>
                </div>
            )}
        </Draggable>
    )
)
