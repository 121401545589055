import React, { useCallback } from 'react'
import { Form } from 'antd'
import { FormInstance } from 'rc-field-form'
import {
    FormItemAdapter,
    InputControl,
    ShouldUpdateChecker,
    TextAreaControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { getFieldsPath } from 'utils'

import { ANSWER_COLUMNS_COUNT } from './ChoosingAnswerPassing.consts'
import { AnswerFieldProps } from '../QuestionnairePassingAnswer/QuestionnairePassingAnswer.types'
import { ChoosingTypeAnswer } from '../ChoosingTypeAnswer'

/**
 * Секция вопроса типа "Выбор ответа из предложенных вариантов"
 */
export const ChoosingAnswerPassing: React.FC<AnswerFieldProps> = React.memo(
    ({ chooseVariant, isPassingInTrack, fieldName }) => {
        const customAnswerFieldName = getFieldsPath('text', fieldName)
        const selectedIdsFieldName = getFieldsPath(
            'selectedAnswerIds',
            fieldName
        )

        /** Сбросить выбранные значения, если вводим кастомный ответ */
        const normalizeCustomAnswerChange = useCallback(
            (form: FormInstance) => (value: any) => {
                if (
                    form.getFieldValue(selectedIdsFieldName) &&
                    !chooseVariant.multiChoose
                ) {
                    form.setFields([
                        {
                            name: selectedIdsFieldName,
                            value: undefined,
                        },
                    ])
                }

                return value
            },
            [selectedIdsFieldName, chooseVariant]
        )

        return (
            <>
                <ShouldUpdateChecker
                    fieldPath={[
                        Array.isArray(customAnswerFieldName)
                            ? customAnswerFieldName
                            : [customAnswerFieldName],
                        Array.isArray(selectedIdsFieldName)
                            ? selectedIdsFieldName
                            : [selectedIdsFieldName],
                    ]}
                >
                    {(form) => (
                        <>
                            <Form.Item name={selectedIdsFieldName}>
                                <ChoosingTypeAnswer
                                    chooseVariant={chooseVariant}
                                    disabled={form.getFieldValue(
                                        customAnswerFieldName
                                    )}
                                    columnsCount={
                                        isPassingInTrack
                                            ? ANSWER_COLUMNS_COUNT.TRACK
                                            : ANSWER_COLUMNS_COUNT.EVENT
                                    }
                                    answerVerify={{
                                        correctAnswerIds: form.getFieldValue(
                                            getFieldsPath(
                                                'correctAnswerIds',
                                                fieldName
                                            )
                                        ),
                                        result: form.getFieldValue(
                                            getFieldsPath('result', fieldName)
                                        ),
                                    }}
                                />
                            </Form.Item>

                            {chooseVariant.customAnswer && (
                                <FormItemAdapter
                                    fieldName={customAnswerFieldName}
                                    label={LOCAL.LABELS.YOUR_VARIANT}
                                    normalize={normalizeCustomAnswerChange(
                                        form
                                    )}
                                >
                                    <InputControl />
                                </FormItemAdapter>
                            )}
                        </>
                    )}
                </ShouldUpdateChecker>

                <FormItemAdapter
                    fieldName={getFieldsPath('comment', fieldName)}
                    label={LOCAL.LABELS.COMMENT}
                >
                    <TextAreaControl />
                </FormItemAdapter>
            </>
        )
    }
)
