import React, { useCallback } from 'react'
import {
    CheckboxControl,
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { Col, Row } from 'antd'
import {
    EMAIL_SEND_STATUSES_MAPPED,
    NOTIFICATION_DELIVERY_STATUSES_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import { NotificationRecipientEmailInfoSearchContract } from 'core/api'
import { RenderFooterProps } from 'components/shared/FilterForm/FilterForm.types'

import { INITIAL_SEARCH_VALUES } from '../../DistributionMailingView.consts'

/**
 * Форма фильтрации списка получателей сообщений
 */
export const DistributionMailingViewFilters: React.FC<FiltersProps<
    NotificationRecipientEmailInfoSearchContract
>> = React.memo((props) => {
    const renderFooter = useCallback(
        ({
            cleanBtn,
            submitBtn,
            saveFiltersSwitch,
            isWidthLessLG,
        }: RenderFooterProps) => (
            <Row
                gutter={10}
                align="middle"
                justify={isWidthLessLG ? 'center' : 'end'}
            >
                <Col>
                    <FormItemAdapter
                        fieldName="recipientNotificationsDisabled"
                        noStyle
                    >
                        <CheckboxControl>
                            {LOCAL.LABELS.NOTIFICATIONS_DISABLED_BY_USER}
                        </CheckboxControl>
                    </FormItemAdapter>
                </Col>

                {saveFiltersSwitch && <Col>{saveFiltersSwitch}</Col>}

                <Col>{submitBtn}</Col>

                <Col>{cleanBtn}</Col>
            </Row>
        ),
        []
    )

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                tableFiltersOptions={{ initialValues: INITIAL_SEARCH_VALUES }}
                renderFooter={renderFooter}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={6}>
                        <FormItemAdapter
                            fieldName="recipientFio"
                            label={LOCAL.LABELS.RECEIVER_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={6}>
                        <FormItemAdapter
                            fieldName="recipientMail"
                            label={LOCAL.LABELS.ADDRESS}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={6}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS_SENDING}
                        >
                            <SelectControl
                                mode="multiple"
                                values={EMAIL_SEND_STATUSES_MAPPED}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={6}>
                        <FormItemAdapter
                            fieldName="deliveryStatuses"
                            label={LOCAL.LABELS.STATUS_DELIVERY}
                        >
                            <SelectControl
                                mode="multiple"
                                values={NOTIFICATION_DELIVERY_STATUSES_MAPPED}
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
