import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { renderAppointmentStatus } from 'utils'

import { SurveyAssignmentProgressFormProps } from './QuizAssignmentsProgressTable.types'
import { renderRespondents } from './QuizAssignmentsProgressTable.utils'

/** настройка отображения колонок таблицы прогресса прохождения опроса */
export const SURVEY_ASSIGNMENT_PROGRESS_TABLE_COLUMNS: ColumnsType<SurveyAssignmentProgressFormProps> = [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'userName',
        key: 'userName',
        render: renderRespondents,
        width: '60%',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderAppointmentStatus,
        width: '20%',
    },
    {
        title: LOCAL.LABELS.PROGRESS,
        dataIndex: 'passingPercent',
        key: 'passingPercent',
        render: (passingPercent) => `${passingPercent}%`,
        width: '20%',
    },
]
