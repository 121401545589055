import React from 'react'
import { COWORKERS_CONTROL_TABLE_COLUMNS } from 'components/controls/AddUsersTableControl/AddUsersTableControl.consts'
import { CheckboxControl } from 'components/controls'
import { ColumnsType } from 'antd/lib/table'
import { FnActionRequiredProps } from 'App.types'
import { LOCAL } from 'core/local'

import { UserFormContract } from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.types'

/** Получить колонки таблицы сотрудников для оценки руководителем */
export const getCoworkersColumunsManagerEstimate = (
    handleUpdateState: FnActionRequiredProps<UserFormContract>
): ColumnsType<UserFormContract> => [
    ...COWORKERS_CONTROL_TABLE_COLUMNS,
    {
        key: 'selfAssessment',
        dataIndex: 'selfAssessment',
        render: (_, row) => (
            <CheckboxControl
                checked={row.selfAssessment}
                onChange={(checked) =>
                    handleUpdateState({
                        ...row,
                        selfAssessment: checked,
                    })
                }
            >
                {LOCAL.LABELS.ASSESSMENT_SELF.toLowerCase()}
            </CheckboxControl>
        ),
    },
]
