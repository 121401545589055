import { ColumnsType } from 'antd/lib/table/interface'
import { LOCAL } from 'core/local'

import { RespondentFormContract } from './AddRespondentsTableControl.types'

export const RESPONDENTS_CONTROL_TABLE_COLUMNS: ColumnsType<RespondentFormContract> = [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'positions',
        key: 'positions',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizations',
        key: 'organizations',
    },
]
