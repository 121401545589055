import {
    AppointmentTestReviewCreateContract,
    AppointmentTestReviewParticipantsCreateContract,
    AppointmentTestReviewQuestionAnswerContract,
    AppointmentTestReviewQuestionAnswerCreateContract,
    CommentContract,
} from 'core/api'
import { Store } from 'App.types'

import { TABLE_REVIEW_FORM_ENTRY_POINT } from './TabReview.consts'

/**
 * Перечисление имен для возможных группировок в контексте формы
 */
export enum ReviewTableNameGroups {
    Answer = 'answer',
    User = 'user',
}

/**
 * Интерфейс объекта Информации по вопросам тестов
 */
export interface QuestionsInfoProps
    extends Pick<
        AppointmentTestReviewQuestionAnswerContract,
        'questionNumber' | 'question'
    > {}

/**
 * Часть "участники тестов" для значений формы
 */
export interface TestReviewParticipantsProps {
    [user: string]: Omit<
        AppointmentTestReviewParticipantsCreateContract,
        'questionAnswer'
    > & {
        questionAnswer?: {
            [answer: string]: AppointmentTestReviewQuestionAnswerCreateContract
        }
    }
}

/**
 * Интерфейс итоговых значений формы
 */
export interface TestReviewFormValuesProps {
    [TABLE_REVIEW_FORM_ENTRY_POINT]: Omit<
        AppointmentTestReviewCreateContract,
        'participants'
    > & {
        participants: TestReviewParticipantsProps
    }
}

/**
 * Базовый интерфейс для создания ряда внутри таблицы
 */
interface TestReviewTableRowBaseProps {
    id: string
    key: string
    main: string
    comment: CommentContract
}

/**
 * Интерфейс родительского ряда
 */
export interface TestReviewTableRowParentProps
    extends TestReviewTableRowBaseProps {
    children: TestReviewTableRowChildrenProps[]
}

export interface AnswerProps {
    reviewerFullname: string
    revieweredDate: Date
    name: string
}

/**
 * Интерфейс дочернего ряда
 */
export type TestReviewTableRowChildrenProps = TestReviewTableRowBaseProps &
    Store<AnswerProps>
