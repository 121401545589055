import isNil from 'lodash/isNil'
import React, { FC, memo, useCallback, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { ShouldUpdateChecker } from 'components/controls'
import { isFormModeView } from 'utils'
import { useBreakpoint } from 'hooks'

import { PassingButtonsProps } from './PassingButtons.types'
import { scrollTo } from '../PassingMenu/PassingMenu.utils'

/** Вывод кнопок прохождения */
export const PassingButtons: FC<PassingButtonsProps> = memo(
    ({
        competenceContracts,
        activeCompetenceContract,
        setActiveCompetenceContract,
        isByBlock,
        isFormTouched,
        canComplete,
        onComplete,
    }) => {
        const isWidthLessMD = useBreakpoint('MD')

        const activeIndex = useMemo(() => {
            if (isByBlock) {
                return competenceContracts.findIndex(
                    (item) =>
                        item.competence.id ===
                        activeCompetenceContract.competence.id
                )
            }
        }, [
            activeCompetenceContract.competence.id,
            competenceContracts,
            isByBlock,
        ])

        const nextCompetence = useMemo(() => {
            if (!isNil(activeIndex) && ~activeIndex) {
                return competenceContracts[activeIndex + 1] || null
            }
        }, [activeIndex, competenceContracts])

        /** Перейти к следующей компетенции */
        const handleNextCompetenceClick = useCallback(() => {
            if (nextCompetence) {
                scrollTo(0)
                setActiveCompetenceContract(nextCompetence)
            }
        }, [nextCompetence, setActiveCompetenceContract])

        const buttonBottomIndent = isWidthLessMD ? 'mb-20' : undefined

        return (
            <ShouldUpdateChecker fieldPath={['formMode']}>
                {({ getFieldValue }) => {
                    const formMode = getFieldValue('formMode')

                    return (
                        <Row
                            gutter={ROW_GUTTER}
                            justify={isWidthLessMD ? 'center' : 'end'}
                        >
                            {!isFormModeView(formMode) && (
                                <Col className={buttonBottomIndent}>
                                    <Button
                                        htmlType="submit"
                                        disabled={!isFormTouched}
                                    >
                                        {LOCAL.ACTIONS.CONFIRM}
                                    </Button>
                                </Col>
                            )}

                            {isByBlock && nextCompetence && (
                                <Col className={buttonBottomIndent}>
                                    <Button onClick={handleNextCompetenceClick}>
                                        {LOCAL.ACTIONS.GO_TO_NEXT_COMPETENCE}
                                    </Button>
                                </Col>
                            )}

                            {!isFormModeView(formMode) &&
                                ((isByBlock && !nextCompetence) ||
                                    !isByBlock) && (
                                    <Col className={buttonBottomIndent}>
                                        <Button
                                            disabled={!canComplete}
                                            onClick={onComplete}
                                        >
                                            {LOCAL.ACTIONS.COMPLETE}
                                        </Button>
                                    </Col>
                                )}
                        </Row>
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
