import React from 'react'
import { ButtonType } from 'antd/lib/button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

import { BaseTableAction } from '../BaseTableAction'
import { FunctionalTableActionProps } from '../../TableActionBtn.types'

/** Универсальный компонент для создания триггера внутри колонки действий для реестров  */
export const TableActionTrigger: React.FC<FunctionalTableActionProps> = React.memo(
    ({ iconType, title, iconClassName, ...restProps }) => {
        const BUTTON_TYPE: ButtonType = 'link'
        const BUTTON_ICON = (
            <IconsAdapter iconType={iconType} className={iconClassName} />
        )

        return (
            <BaseTableAction
                icon={BUTTON_ICON}
                type={BUTTON_TYPE}
                accessDeniedRender={renderAccessDeniedButton({
                    type: BUTTON_TYPE,
                    icon: BUTTON_ICON,
                })}
                title={title}
                tooltipOptions={{
                    getPopupContainer: null,
                }}
                {...restProps}
            />
        )
    }
)
