import React, { useContext } from 'react'
import { ColorThemeContext } from 'contexts'
import { FeedbackQuizReportGraficTabContract } from 'core/api'
import { NoChartData, Title } from 'components/shared'

import { renderCharts } from './SemanticCharts.utils'

/** Вкладка "Семантический график" в назначениях опросов  */
export const SemanticCharts: React.FC<FeedbackQuizReportGraficTabContract> = React.memo(
    ({ questions, scaleFrom, scaleTo, head }) => {
        const { theme } = useContext(ColorThemeContext)

        return (
            <>
                <Title type="h5" text={head} />

                {questions?.length ? (
                    questions.map((el) =>
                        renderCharts({
                            grafics: el.grafics,
                            scaleFrom,
                            scaleTo,
                            theme,
                        })
                    )
                ) : (
                    <NoChartData />
                )}
            </>
        )
    }
)
