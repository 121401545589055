import React from 'react'
import { AssessmentPortfolioCircleParametersContract } from 'core/api'
import { CustomControlCommonProps } from 'App.types'
import { LOCAL } from 'core/local'
import { Spoiler, TableAdapter } from 'components/shared'

import styles from './AssessmentProjectsPortfolioCompetencies.module.scss'
import { ASSESSMENT_PORTFOLIO_COMPETENCIES_COLUMNS } from './AssessmentProjectsPortfolioCompetencies.consts'

/** Компонент компетенций портфеля оценки*/
export const AssessmentProjectsPortfolioCompetencies: React.FC<CustomControlCommonProps<
    AssessmentPortfolioCircleParametersContract
>> = React.memo(({ value: circleParameters }) => (
    <Spoiler
        title={LOCAL.LABELS.COMPETENCIES}
        iconPosition="left"
        className={styles.spoiler}
    >
        <TableAdapter
            pagination={false}
            columns={ASSESSMENT_PORTFOLIO_COMPETENCIES_COLUMNS}
            dataSource={circleParameters?.circlePortfolioQuestionnaires}
            rowKey={(record) => record.competence.id}
        />
    </Spoiler>
))
