import React from 'react'
import cn from 'classnames'
import {
    AppointmentTestStatisticByOrganizationContract,
    AppointmentTestStatisticScoreContract,
    ColorResult,
} from 'core/api'
import { formatDate } from 'utils'
import { uniqueId } from 'lodash'

import styles from './TabCommonStats.module.scss'
import { CHILDREN_KEY, MAIN_KEY } from './TabCommonStats.consts'
import {
    GetResultQuestionnaireProps,
    QuestionsColumns,
    TableDataProps,
} from './TabCommonStats.types'

/** рендер результата проверки вопроса */
const renderResult = (result: string, colorResult: ColorResult) => (
    <div className={styles[`result${colorResult}`]}>{result}</div>
)

/** рендер ответов на вопросы по баллам */
const renderScores = (scores: AppointmentTestStatisticScoreContract[]) => (
    <div className={styles.scoreWrapper}>
        {scores.map((el, index) => (
            <div
                className={cn(styles.scoreBlock, styles[`score${el.color}`])}
                key={`${index}${el.score}`}
            >
                <div className={styles.scoreNumber}>{index + 1}</div>

                <div className={styles.score}>{el.score}</div>
            </div>
        ))}
    </div>
)

const getResultQuestionnaire = ({
    questionColumns,
    resultQuestionnaire,
}: GetResultQuestionnaireProps) =>
    resultQuestionnaire?.reduce(
        (acc, current, index) => ({
            ...acc,
            [`${questionColumns?.[index].data}-scores-${
                index + 1
            }`]: renderScores(current.scores),
            [`${questionColumns?.[index].data}-result-${
                index + 1
            }`]: renderResult(current.result, current.colorResult),
        }),
        {}
    )

/** формирование participiantsStatistic для таблицы */
const getParticipations = (
    reviewData: AppointmentTestStatisticByOrganizationContract,
    questionColumns?: QuestionsColumns[]
) =>
    reviewData.participantsStatistic.map((el) => {
        const childrenKey = uniqueId(CHILDREN_KEY)

        return {
            id: childrenKey,
            key: childrenKey,
            main: el.fio,
            ...el,
            dateEnd: formatDate(el.dateEnd),
            ...getResultQuestionnaire({
                resultQuestionnaire: el.resultQuestionnaire,
                questionColumns,
            }),
        }
    })

/** формирование children для таблицы*/
const getRowChildren = (
    reviewData: AppointmentTestStatisticByOrganizationContract,
    questionColumns?: QuestionsColumns[]
): TableDataProps[] => [
    ...reviewData.children.map((el) => {
        const childrenKey = uniqueId(CHILDREN_KEY)

        return {
            id: childrenKey,
            key: childrenKey,
            main: el.name,
            children: getRowChildren(el, questionColumns),
        }
    }),
    ...getParticipations(reviewData, questionColumns),
]

/**
 * Получить итоговый контент таблицы в виде dataSource
 * @param commonData
 * @param questionColumns
 */
export const getDataTable = (
    commonData?: AppointmentTestStatisticByOrganizationContract[],
    questionColumns?: QuestionsColumns[]
) => {
    if (!commonData) return []

    return commonData.map((reviewGroup) => {
        const mainKey = uniqueId(MAIN_KEY)

        return {
            id: mainKey,
            key: mainKey,
            main: reviewGroup.name,
            children: getRowChildren(reviewGroup, questionColumns),
        }
    })
}
