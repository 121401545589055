import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CircleReportSmallContract, CircleReportsService } from 'core/api'
import { Col, Form, Row, message } from 'antd'
import { FORM_IDS } from 'core/configs'
import {
    FormItemAdapter,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { TreeNode } from 'antd/lib/tree-select'
import { getItemId, normalizeDataForSelectAndRadio } from 'utils'
import { useDictionariesHelper } from 'hooks'
import { useForm } from 'antd/lib/form/Form'
import { withLoader } from 'HOCs'

import styles from './UserProfileReportForm.module.scss'
import stylesUserProfile from '../../UserProfile.module.scss'
import {
    DictionariesStateProps,
    UserProfileReportFormProps,
} from './UserProfileReportForm.types'
import { UserProfileCompetenciesOptions } from '../UserProfileCompetenciesOptions'
import { UserProfileLegend } from '../UserProfileLegend'
import { dictionariesInitialState } from './UserProfileReportForm.consts'
import {
    normalizedAssessmentResultsData,
    renderReportLink,
} from './UserProfileReportForm.utils'

/** Форма на странице профиля пользователя */
export const UserProfileReportForm: React.FC<UserProfileReportFormProps> = withLoader(
    React.memo(
        ({
            userId,
            setComparisonData,
            onChangeChosenReports,
            onLoadReports,
            updateLoader,
            isOwnProfile = false,
        }) => {
            const { assessmentTypeDictionary } = useDictionariesHelper([
                'assessmentTypeDictionary',
            ])
            const [form] = useForm()
            const [dictionaries, setDictionaries] = useState<
                DictionariesStateProps
            >(dictionariesInitialState)
            const [assessmentResultsData, setAssessmentResultsData] = useState<
                CircleReportSmallContract[]
            >()

            /**
             * Обработчик выбора результатов оценок для сравнения
             * @param ids - id результатов оценки
             */
            const handleChooseComparisonData = useCallback(
                (ids?) => {
                    if (!ids) return

                    onChangeChosenReports(ids)

                    if (ids.length > 3) {
                        form.setFieldsValue({
                            assessmentResult: assessmentResultsData?.map(
                                getItemId
                            ),
                        })
                        message.warning(
                            LOCAL.MESSAGES
                                .COMPARISON_MESSAGE_MAX_ASSESSMENT_RESULTS
                        )

                        return
                    }

                    setAssessmentResultsData(
                        ids.map((id: number) =>
                            dictionaries.assessmentResults.find(
                                (el) => el.id === id
                            )
                        )
                    )
                },
                [
                    onChangeChosenReports,
                    form,
                    assessmentResultsData,
                    dictionaries.assessmentResults,
                ]
            )

            /**
             * Дропдаун для селекта "результаты оценки"
             */
            const assessmentResultsDropdown = useMemo(
                () =>
                    dictionaries.assessmentResults
                        .reduce(
                            normalizedAssessmentResultsData(
                                assessmentTypeDictionary
                            ),
                            []
                        )
                        .map((el) => (
                            <TreeNode
                                key={el.key}
                                value={el.value!}
                                disabled={el.disabled}
                                title={el.title}
                                className={cn(
                                    styles.text,
                                    stylesUserProfile[
                                        `assessmentResultsCompetence_${el.value}`
                                    ]
                                )}
                            >
                                {el.children?.map((child) => (
                                    <TreeNode
                                        key={child.key}
                                        value={child.value!}
                                        title={child.title}
                                        className={cn(
                                            styles.text,
                                            stylesUserProfile[
                                                `assessmentResultsCompetence_${el.value}`
                                            ]
                                        )}
                                    />
                                ))}
                            </TreeNode>
                        )),
                [assessmentTypeDictionary, dictionaries.assessmentResults]
            )

            useEffect(() => {
                const fetch = async () => {
                    try {
                        if (!userId) return
                        updateLoader(true)

                        const requestPayload = {
                            body: { userId },
                        }

                        const dataSource = isOwnProfile
                            ? await CircleReportsService.getByOwnerId(
                                  requestPayload
                              )
                            : await CircleReportsService.getByUserId(
                                  requestPayload
                              )

                        onLoadReports(dataSource)

                        setDictionaries({
                            assessmentTypes: dataSource
                                .filter(
                                    (el) =>
                                        el.assessmentReportId &&
                                        (isOwnProfile
                                            ? el.isAvailableForCandidate
                                            : true)
                                )
                                .map(normalizeDataForSelectAndRadio),
                            assessmentResults: dataSource.filter((el) =>
                                isOwnProfile
                                    ? el.competenciesReportAvailableForCandidate
                                    : true
                            ),
                        })

                        if (!assessmentResultsData) return

                        const reportsIds = assessmentResultsData.map(getItemId)
                        setComparisonData({
                            data: await CircleReportsService.getCompetenceResultsForComparison(
                                { body: reportsIds }
                            ),
                            reportsIds,
                        })
                    } catch (err) {
                        console.error(err)
                    } finally {
                        updateLoader(false)
                    }
                }

                fetch()
            }, [
                onLoadReports,
                userId,
                isOwnProfile,
                assessmentResultsData,
                setComparisonData,
                updateLoader,
            ])

            return (
                <section className={styles.wrapper}>
                    <Form form={form} id={FORM_IDS.USER_PROFILE_REPORT_FORM}>
                        <Row gutter={ROW_GUTTER}>
                            <Col className={styles.field}>
                                <FormItemAdapter
                                    fieldName="assessmentResult"
                                    label={LOCAL.LABELS.ASSESSMENT_RESULT}
                                >
                                    <TreeSelectControl
                                        onChange={handleChooseComparisonData}
                                        multiple
                                        treeCheckable
                                    >
                                        {assessmentResultsDropdown}
                                    </TreeSelectControl>
                                </FormItemAdapter>
                            </Col>

                            <Col className={styles.field}>
                                <FormItemAdapter
                                    fieldName="report"
                                    label={LOCAL.LABELS.REPORT}
                                >
                                    <SelectControl
                                        values={renderReportLink({
                                            selectItems:
                                                dictionaries.assessmentTypes,
                                            userId,
                                            isOwnProfile,
                                        })}
                                    />
                                </FormItemAdapter>
                            </Col>
                        </Row>
                    </Form>

                    <UserProfileCompetenciesOptions />

                    <UserProfileLegend
                        assessmentResultsData={assessmentResultsData}
                    />
                </section>
            )
        }
    )
)
