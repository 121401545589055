import cn from 'classnames'
import React, { useCallback } from 'react'
import {
    Button,
    CanvasImagesWithInfiniteScroll,
    IconsAdapter,
    Popup,
} from 'components/shared'
import {
    FormItemAdapter,
    InputControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { ImgsUploadFnResponseProps } from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.types'
import { LOCAL } from 'core/local'
import { TrackHeaderIconsService } from 'core/api'
import { canvasBgImgMapFn } from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.utils'
import { isFormModeView } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './UploadLogoItem.module.scss'
import { UploadLogoItemProps } from './UploadLogoItem.types'
import {
    canvasLogoImgsFetchFn,
    canvasLogoImgsUploadFn,
} from './UploadLogoItem.utils'

export const UploadLogoItem: React.FC<UploadLogoItemProps> = React.memo(
    ({ field, remove, form, disabled, formMode }) => {
        const { elementVisible, toggleElementVisible } = useElementVisible()

        const canvasImagesName = [field.name, 'selectedLogo']

        const handleChangeLogo = useCallback(
            async (value?: ImgsUploadFnResponseProps) => {
                try {
                    const trackHeaderIcons = form?.getFieldsValue()
                        .trackHeaderIcons

                    trackHeaderIcons.splice(field.name, 1, {
                        ...trackHeaderIcons[field.name],
                        url: value?.url,
                        id: value?.id,
                    })

                    form?.setFieldsValue({
                        trackHeaderIcons,
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            [field, form]
        )

        return (
            <ShouldUpdateChecker fieldPath={['trackHeaderIcons', field.name]}>
                {({ getFieldValue }) => {
                    const logoItem = getFieldValue([
                        'trackHeaderIcons',
                        field.name,
                    ])

                    return (
                        <div className={styles.wrapper}>
                            <div
                                className={styles.logoWrapper}
                                onClick={
                                    disabled || isFormModeView(formMode)
                                        ? undefined
                                        : toggleElementVisible
                                }
                            >
                                <img
                                    src={logoItem.url}
                                    className={cn(
                                        styles.logoIcon,
                                        (disabled ||
                                            isFormModeView(formMode)) &&
                                            styles.logoIconDisabled
                                    )}
                                    alt={LOCAL.LABELS.TRACK_LOGO}
                                />
                            </div>

                            <FormItemAdapter
                                fieldName={[field.name, 'link']}
                                label={LOCAL.LABELS.LINK}
                                className={styles.linkWrapper}
                            >
                                <InputControl
                                    className={styles.link}
                                    disabled={disabled}
                                    formMode={formMode}
                                />
                            </FormItemAdapter>

                            {!isFormModeView(formMode) && (
                                <Button
                                    type="link"
                                    title={LOCAL.ACTIONS.DELETE}
                                    icon={
                                        <IconsAdapter iconType="DeleteOutlined" />
                                    }
                                    onClick={() => remove(field.name)}
                                    disabled={disabled}
                                    className={styles.removeBtn}
                                />
                            )}

                            <Popup
                                title={LOCAL.LABELS.SELECT_LOGO}
                                visible={elementVisible}
                                onCancel={toggleElementVisible}
                                footer={null}
                                destroyOnClose
                            >
                                <CanvasImagesWithInfiniteScroll
                                    className={styles.logoList}
                                    imgMapFn={canvasBgImgMapFn}
                                    imgsFetchFn={canvasLogoImgsFetchFn}
                                    imgsUploadFn={canvasLogoImgsUploadFn}
                                    controlName={canvasImagesName}
                                    controlMetaName={canvasImagesName}
                                    onImgChoose={handleChangeLogo}
                                    deleteMethod={
                                        TrackHeaderIconsService.delete
                                    }
                                    showLoadBtn={false}
                                />
                            </Popup>
                        </div>
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
