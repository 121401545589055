import {
    EmailState,
    NotificationRecipientEmailInfoSearchResultContract,
} from 'core/api'

import styles from './DistributionMailingView.module.scss'

export const renderNumberOfAttemptsToSend = (
    record: NotificationRecipientEmailInfoSearchResultContract
) => {
    if (record.status === EmailState.Success) {
        return record.countError + 1
    }

    return record.countError
}

export const getRowClassName = <T extends { status?: EmailState }>(
    record: T
): string => (record.status === null ? styles.disabled : '')
