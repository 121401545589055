import React, { useCallback } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { RECOMMENDATIONS_TYPES } from 'consts'

import styles from './YourChosenItem.module.scss'
import { ControlGroupItem } from '../ControlGroupItem'
import { YourChosenItemProps } from './YourChosenItem.types'

/** Блок Ваш выбор на странице профиля пользователя*/
export const YourChosenItem: React.FC<YourChosenItemProps> = React.memo(
    ({ chosen, onRemoveChosen }) => {
        /**
         * Обработчик удаления записи
         */
        const handleDeleteChosen = useCallback(() => {
            onRemoveChosen({ item: chosen })
        }, [chosen, onRemoveChosen])

        return (
            <ControlGroupItem className={styles.wrapper} type="light">
                <div>
                    <div className={styles.type}>
                        {RECOMMENDATIONS_TYPES[chosen.type]}
                    </div>

                    <div className={styles.name}>{chosen.name}</div>
                </div>

                <IconsAdapter
                    iconType="CloseOutlined"
                    className={styles.delete}
                    onClick={handleDeleteChosen}
                />
            </ControlGroupItem>
        )
    }
)
