import React, { useCallback } from 'react'
import {
    AppointmentActivitiesForCurrentUserContract,
    AppointmentTrackListenerContract,
    AppointmentTrackStatus,
    StartType,
    TrackType,
    YourDevelopmentPageItemContract,
    YourDevelopmentPageItemType,
} from 'core/api'
import { Button } from 'components/shared'
import { Col, Row } from 'antd'
import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'
import { ROW_GUTTER } from 'consts'
import { TracksUniqueType } from 'components/pages/TracksUnique/TracksUnique.consts'
import {
    getShowMoreText,
    isActivityLessonCompletedSuccessfully,
    isActivityLessonCompletedUnsuccessfully,
    isActivityLessonInProgress,
    isActivityLessonWaiting,
    isFirstDateSameOrEarlier,
} from 'utils'

import { PAGINATION_SETTINGS } from '../../YourDevelopment.consts'
import { PanelType } from '../../YourDevelopment.types'
import { ProcessCard } from '../ProcessCard'
import { ProcessCardHeader } from '../ProcessCardHeader'
import { StagesIndicator } from '../StagesIndicator'
import { TrackAppointmentsCardsProps } from './AppointmentsCards.types'
import { getDataSource } from '../StagesIndicator/StagesIndicator.utils'
import { getLessonsData } from './AppointmentsCards.utils'

/** Компонент карточек назначения треков на странице вашего развития */
export const AppointmentsCards: React.FC<TrackAppointmentsCardsProps> = React.memo(
    ({
        appointments,
        fetchAppointments,
        isPassed,
        showMoreEntities,
        isLoading,
    }) => {
        const handleTrackStart = useCallback(
            ({
                id,
                status,
                trackType,
            }: AppointmentTrackListenerContract) => () => {
                const disableStatuses = [
                    AppointmentTrackStatus.NotPassed,
                    AppointmentTrackStatus.Canceled,
                ]

                if (disableStatuses.includes(status)) return

                const mode = isPassed
                    ? ROUTES_POSTFIXES.VIEW
                    : ROUTES_POSTFIXES.START

                switch (trackType) {
                    case TrackType.Mguu:
                        window.open(
                            `${ROUTE_NAMES.TRACKS_PASSING_UNIQUE}/${TracksUniqueType.Crossword}/${id}/${StartType.ByAppointment}/${mode}`
                        )
                        break
                    default:
                        window.open(
                            `${ROUTE_NAMES.TRACKS_PASSING_v2}/${id}/${mode}`
                        )

                        break
                }

                if (!isPassed) {
                    fetchAppointments({
                        panelType: PanelType.Tracks,
                        isInit: true,
                    })
                }
            },
            [fetchAppointments, isPassed]
        )

        const handleActivityStart = useCallback(
            ({
                id,
                status,
                startDate,
                endDate,
                activity,
            }: AppointmentActivitiesForCurrentUserContract) => () => {
                if (!id || !activity.id) return

                fetchAppointments({
                    panelType: PanelType.Activities,
                    isInit: true,
                })

                const currentDate = new Date()

                const isNotOver = startDate
                    ? isFirstDateSameOrEarlier(new Date(startDate), currentDate)
                    : true
                const isNotStart = endDate
                    ? isFirstDateSameOrEarlier(currentDate, new Date(endDate))
                    : true
                const isAvailabilityPeriod = isNotOver && isNotStart

                if (
                    isActivityLessonWaiting(status) ||
                    isActivityLessonInProgress(status) ||
                    !isAvailabilityPeriod
                ) {
                    window.open(
                        `${ROUTE_NAMES.EVENTS_PASSING}/${activity.id}/${id}`
                    )

                    return
                }

                if (
                    isActivityLessonCompletedUnsuccessfully(status) ||
                    isActivityLessonCompletedSuccessfully(status)
                ) {
                    window.open(
                        `${ROUTE_NAMES.EVENTS_PASSING_VIEW}/${activity.id}/${id}`
                    )
                }
            },
            [fetchAppointments]
        )

        const renderTrack = ({
            track: appointment,
            type,
        }: YourDevelopmentPageItemContract) => (
            <ProcessCard
                key={appointment?.id}
                onClick={handleTrackStart(appointment)}
                header={
                    <ProcessCardHeader
                        endDate={appointment?.periodTo}
                        startDate={appointment?.periodFrom}
                        status={appointment?.status}
                        type={type}
                    />
                }
                title={appointment?.name}
                description={appointment?.description}
                passedPercentage={appointment?.passedStagesPercentage}
                dataView={
                    <StagesIndicator dataSource={getDataSource(appointment)} />
                }
                imageUrl={appointment?.trackYourDevelopmentDisplay?.url}
                startPassingDate={appointment.periodFrom}
            />
        )

        const renderActivity = ({
            activity: appointment,
            type,
        }: YourDevelopmentPageItemContract) => (
            <ProcessCard
                key={appointment.id}
                onClick={handleActivityStart(appointment)}
                header={
                    appointment.status && (
                        <ProcessCardHeader
                            endDate={appointment.endDate}
                            startDate={appointment.startDate}
                            status={appointment.status}
                            type={type}
                        />
                    )
                }
                title={appointment.activity?.name}
                description={appointment.activity?.description}
                passedPercentage={appointment.passedLessonsPercentage}
                dataView={
                    <StagesIndicator
                        dataSource={getLessonsData(
                            appointment.activity?.lessons
                        )}
                    />
                }
                imageUrl={appointment.activity?.activityPicture?.url}
                startPassingDate={appointment.startDate}
            />
        )

        const showMoreText =
            appointments &&
            getShowMoreText({
                total: appointments.total,
                pageSize: PAGINATION_SETTINGS.pageSize,
                loadedEntitiesCount: appointments.pageItems.length,
            })

        const renderAppointments = () =>
            appointments?.pageItems.map((appointment) => {
                switch (appointment.type) {
                    case YourDevelopmentPageItemType.Activity:
                        return renderActivity(appointment)
                    default:
                        return renderTrack(appointment)
                }
            })

        return (
            <Row gutter={ROW_GUTTER}>
                {renderAppointments()}

                {showMoreText && (
                    <Col span={24}>
                        <Button
                            type="dashed"
                            size="large"
                            onClick={showMoreEntities}
                            disabled={isLoading}
                            loading={isLoading}
                            block
                        >
                            {showMoreText}
                        </Button>
                    </Col>
                )}
            </Row>
        )
    }
)
