import React from 'react'
import cn from 'classnames'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import styles from './StageActions.module.scss'
import { StageActionsProps } from './StageActions.types'

export const StageActions: React.FC<StageActionsProps> = React.memo(
    ({ onChangeStageState, className }) => (
        <div className={cn(styles.wrapper, className)}>
            <IconsAdapter
                onClick={onChangeStageState}
                iconType="CustomIconPencil"
                className={cn(styles.option, styles.optionChangeState)}
            />
        </div>
    )
)
