import React, { useCallback } from 'react'
import { ActivitiesService, AppError } from 'core/api'
import { AddButton, Title, TooltipAdapter } from 'components/shared'
import { Form, Upload } from 'antd'
import { FormListOperation } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'
import { RcCustomRequestOptions } from 'types'
import { RcFile } from 'antd/es/upload/interface'
import { THEME_BLOCKS_DOCUMENT_TYPE } from 'consts'
import { beforeUploadAttachment, getStringWithoutDots } from 'utils'
import { withLoader } from 'HOCs'

import styles from './ThemeBlockAttachments.module.scss'
import { ThemeBlockAttachmentsHeader } from '../ThemeBlockAttachmentsHeader'
import { ThemeBlockAttachmentsProps } from './ThemeBlockAttachments.types'
import { ThemeBlockAttachmentsRow } from '../ThemeBlockAttachmentsRow'

export const ThemeBlockAttachments: React.FC<ThemeBlockAttachmentsProps> = withLoader(
    React.memo(({ fieldName, updateLoader, disabled, formMode }) => {
        const handleUploadAttachment = useCallback(
            async (
                { file, onSuccess, onError }: RcCustomRequestOptions,
                add: FormListOperation['add']
            ) => {
                try {
                    updateLoader(true)

                    const upload = await ActivitiesService.uploadLessonAttachment(
                        {
                            file,
                        }
                    )

                    const rcFile = file as RcFile

                    onSuccess?.(upload, {} as XMLHttpRequest)

                    add({ id: upload.id, fileName: rcFile.name, name: '' })
                } catch (error) {
                    onError?.(error as AppError)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader]
        )

        return (
            <Form.List name={[fieldName, 'attachments']}>
                {(fields, { add, remove }) => (
                    <>
                        {!!fields.length && (
                            <>
                                <Title
                                    text={LOCAL.LABELS.DOCUMENTS}
                                    type="h6"
                                    bottomIndent="20"
                                    className={styles.title}
                                />

                                <ThemeBlockAttachmentsHeader />

                                {fields.map((field) => (
                                    <ThemeBlockAttachmentsRow
                                        field={field}
                                        remove={remove}
                                        key={field.key}
                                        disabled={disabled}
                                        formMode={formMode}
                                    />
                                ))}
                            </>
                        )}

                        <Upload
                            beforeUpload={beforeUploadAttachment({
                                documentType: THEME_BLOCKS_DOCUMENT_TYPE,
                                fileSize: 15,
                            })}
                            customRequest={(options) =>
                                handleUploadAttachment(options, add)
                            }
                            showUploadList={false}
                            accept={THEME_BLOCKS_DOCUMENT_TYPE}
                        >
                            <TooltipAdapter
                                title={getStringWithoutDots(
                                    THEME_BLOCKS_DOCUMENT_TYPE
                                )}
                            >
                                <AddButton
                                    className={styles.addButton}
                                    disabled={disabled}
                                />
                            </TooltipAdapter>
                        </Upload>
                    </>
                )}
            </Form.List>
        )
    })
)
