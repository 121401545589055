export const TRACK_PASSING_EVENTS = {
    GET_PROGRESS: 'TRACK_PASSING_EVENTS_GET_PROGRESS',
    GET_TRACK_INFO: 'TRACK_PASSING_EVENTS_GET_TRACK_INFO',
    GET_STAGE_INFO: 'TRACK_PASSING_EVENTS_GET_STAGE_INFO',
    GET_LESSON_INFO: 'TRACK_PASSING_EVENTS_GET_LESSON_INFO',
    START_STAGE: 'TRACK_PASSING_EVENTS_START_STAGE',
    START_LESSON_FROM_CONTENT: 'TRACK_PASSING_EVENTS_START_LESSON_FROM_CONTENT',
    START_LESSON: 'TRACK_PASSING_EVENTS_START_LESSON',
    SAVE_STAGE_CONTENT_PROGRESS:
        'TRACK_PASSING_EVENTS_SAVE_STAGE_CONTENT_PROGRESS',
    FINISH_STAGE_CONTENT: 'TRACK_PASSING_EVENTS_FINISH_STAGE_CONTENT',
    FINISH_STAGE_ACTIVITY: 'TRACK_PASSING_EVENTS_FINISH_STAGE_ACTIVITY',
    FINISH_LESSON: 'TRACK_PASSING_EVENTS_FINISH_LESSON',
    CLEAN_STAGE_INFO: 'TRACK_PASSING_EVENTS_CLEAN_STAGE_INFO',
    CLEAN_LESSON_INFO: 'TRACK_PASSING_EVENTS_CLEAN_LESSON_INFO',
    CLEAN_CURRENT_STAGE: 'TRACK_PASSING_EVENTS_CLEAN_CURRENT_STAGE',
    CLOSE_STAGE: 'TRACK_PASSING_EVENTS_CLOSE_STAGE',
    CLOSE_LESSON: 'TRACK_PASSING_EVENTS_CLOSE_LESSON',
    SEND_TO_CHECK_HOMEWORK: 'TRACK_PASSING_EVENTS_SEND_TO_CHECK_HOMEWORK',
    EXIT_LESSON_VIDEO: 'TRACK_PASSING_EVENTS_EXIT_LESSON_VIDEO',
    SHOW_FINISH_ACTIVITY_MODAL: 'SHOW_FINISH_ACTIVITY_MODAL',
    HIDE_FINISH_ACTIVITY_MODAL: 'HIDE_FINISH_ACTIVITY_MODAL',
}
