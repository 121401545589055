import React from 'react'
import cn from 'classnames'
import { Button } from 'components/shared/Button'
import { Drawer } from 'antd'
import { HasPermissions } from 'core/permissions/HasPermissions'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { useElementVisible } from 'hooks/useElementVisible'

import styles from './AdminMenu.module.scss'
import { ADMIN_MENU_ITEMS } from './AdminMenu.consts'
import { AdminGroupMenuProps, AdminMenuChildProps } from './AdminMenu.types'
import { AdminMenuGroup } from './AdminMenuGroup'
import { AdminMenuItem } from './AdminMenuItem'

/** Меню администратора */
export const AdminMenu: React.FC = React.memo(() => {
    const {
        elementVisible,
        handleHideElement,
        toggleElementVisible,
        toggleElementVisibleRef,
        elementRef,
    } = useElementVisible()

    const getToggleStyles = (reverse = false) => {
        // eslint-disable-next-line no-nested-ternary
        const display = reverse
            ? elementVisible
                ? 'none'
                : 'inline-block'
            : elementVisible
            ? 'inline-block'
            : 'none'

        return {
            display,
            margin: 0,
        }
    }

    return (
        <div className={styles.wrapper}>
            <Button
                ref={toggleElementVisibleRef}
                onClick={toggleElementVisible}
                className={styles.toggle}
                type="text"
            >
                <IconsAdapter
                    iconType="CloseOutlined"
                    style={getToggleStyles()}
                />

                <IconsAdapter
                    iconType="MenuOutlined"
                    style={getToggleStyles(true)}
                />
            </Button>

            <Drawer
                placement="top"
                height="75%"
                className={cn(styles.content, elementVisible && styles.open)}
                closable={false}
                forceRender
                visible={elementVisible}
                onClose={handleHideElement}
            >
                <section ref={elementRef} className={styles.grid}>
                    {ADMIN_MENU_ITEMS.map((item: AdminGroupMenuProps) => (
                        <HasPermissions
                            key={item.name}
                            conditionalPermissions={item.children
                                ?.map((el) => el.permissions)
                                .flat()}
                        >
                            <AdminMenuGroup
                                name={item.name}
                                title={item.title}
                                icon={item.icon}
                            >
                                {item.children?.map(
                                    (menuItem: AdminMenuChildProps) => (
                                        <HasPermissions
                                            key={menuItem.key}
                                            requiredPermissions={
                                                menuItem.permissions
                                            }
                                        >
                                            <AdminMenuItem
                                                itemKey={menuItem.key}
                                                link={menuItem.link}
                                                title={menuItem.title}
                                                onClick={handleHideElement}
                                            />
                                        </HasPermissions>
                                    )
                                )}
                            </AdminMenuGroup>
                        </HasPermissions>
                    ))}
                </section>
            </Drawer>
        </div>
    )
})
