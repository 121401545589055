import React, { useMemo } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Progress, Space } from 'antd'
import { ROUTE_NAMES } from 'routing/routeNames.consts'

import styles from './UploadCourseValidationConfirm.module.scss'
import { ProgressPercents } from '../UploadCourseForm/UploadCourseForm.types'
import { SPACE_SIZE } from './UploadCourseValidationConfirm.consts'
import { UploadCourseValidationConfirmProps } from './UploadCourseValidationConfirm.types'
import {
    getValidationMessage,
    normalizeFileNameToView,
} from './UploadCourseValidationConfirm.utils'
import {
    isCourseValidationTypeAlreadyExists,
    isCourseValidationTypeAlreadyExistsAndUsingAppointment,
} from '../UploadCourseForm/UploadCourseForm.utils'

/** Блок с информацией о валидации в форме загрузки курса */
export const UploadCourseValidationConfirm: React.FC<UploadCourseValidationConfirmProps> = React.memo(
    ({
        validationData,
        uploadFile,
        progressPercents,
        onRemoveFile,
        onCancelSubmit,
    }) => {
        const isAlreadyExists = isCourseValidationTypeAlreadyExists(
            validationData?.case
        )

        const isAlreadyExistsAndUsingAppointment = isCourseValidationTypeAlreadyExistsAndUsingAppointment(
            validationData?.case
        )

        const isShowGoToFileBtn = useMemo(
            () => isAlreadyExists || isAlreadyExistsAndUsingAppointment,
            [isAlreadyExistsAndUsingAppointment, isAlreadyExists]
        )

        return (
            <div className={styles.wrapper}>
                {validationData ? (
                    <Space
                        size={SPACE_SIZE}
                        direction="vertical"
                        align="center"
                    >
                        <div className={styles.message}>
                            {getValidationMessage(
                                isAlreadyExists,
                                isAlreadyExistsAndUsingAppointment,
                                validationData.processingUser
                            )}
                        </div>

                        <ButtonsToolbar>
                            <Button type="primary" onClick={onRemoveFile()}>
                                {LOCAL.ACTIONS.SELECT_ANOTHER_FILE}
                            </Button>

                            {isShowGoToFileBtn && (
                                <Button
                                    type="primary"
                                    href={`${ROUTE_NAMES.TRAINING_MATERIALS_INFO}/${validationData.courseId}`}
                                    onClick={onCancelSubmit}
                                >
                                    {LOCAL.ACTIONS.GO_TO_FILE_CARD}
                                </Button>
                            )}
                        </ButtonsToolbar>
                    </Space>
                ) : (
                    <>
                        <Space>
                            {normalizeFileNameToView(uploadFile)}

                            <Button
                                type="link"
                                icon={<IconsAdapter iconType="StopOutlined" />}
                                onClick={onRemoveFile(true)}
                                disabled={
                                    !!progressPercents &&
                                    progressPercents ===
                                        ProgressPercents.START_UPLOAD
                                }
                            />
                        </Space>

                        {!!progressPercents && (
                            <Progress percent={progressPercents} />
                        )}
                    </>
                )}
            </div>
        )
    }
)
