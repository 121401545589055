import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import { DictionariesCommonProps } from 'App.types'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { IndicatorTypesService, IndicatorsSearchContract } from 'core/api'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { SelectControl } from 'components/controls/SelectControl'
import { useDictionaries } from 'hooks'

/**
 * Форма фильтрации индикаторов компетенций
 */
export const IndicatorsFilters: React.FC<FiltersProps<
    IndicatorsSearchContract
>> = React.memo((props) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<'indicatorsTypes'>
    >()

    useEffect(() => {
        handleFetchDictionaries({
            indicatorsTypes: IndicatorTypesService.getAllIndicatorsType(),
        })
    }, [handleFetchDictionaries])

    return (
        <FilterForm {...props}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={24} md={12}>
                    <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={24} md={12}>
                    <FormItemAdapter
                        fieldName="indicatorTypeId"
                        label={LOCAL.LABELS.TYPE}
                    >
                        <SelectControl values={dictionaries.indicatorsTypes} />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    )
})
