import cn from 'classnames'
import isNil from 'lodash/isNil'
import React, { useCallback } from 'react'
import { ActivityLessonPassingContract, ActivityPassingStatus } from 'core/api'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import {
    getBackgroundImageStyle,
    getStyles,
    isActivityLessonCompletedSuccessfully,
    isEventPassingTypeStepByStep,
    isThemeBlockTypeQuestionnaire,
} from 'utils'

import styles from './ListCanvas.module.scss'
import { CanvasProps } from '../../ActivityLayer.types'
import { useCanvas } from '../../ActivityLayer.hooks'

/**
 * Отображение для мероприятия типа - "List"
 */
export const ListCanvas: React.FC<CanvasProps> = React.memo((props) => {
    const {
        activity,
        parentLessonIdForPassing,
        onStartThematicBlock,
        goBackToTrackLayer,
        onCloseNestedActivity,
    } = props

    const {
        questionnaireForm,
        showQuestionnaireForm,
        meetingModal,
        videoModal,
        showFurtherButton,
        finishEventModal,
        handleFurtherBtnClick,
        showReturnButton,
    } = useCanvas(props)

    const { name, announcement, canvas, activityLessonPassings } = activity

    const isActivityStepByStep = isEventPassingTypeStepByStep(
        activity.lessonAccessType
    )

    const getActivityStepByStepBlockColor = (status: ActivityPassingStatus) => {
        switch (status) {
            case ActivityPassingStatus.CompletedSuccessfully:
                return canvas?.thematicBlockColorAfterPassing
            case ActivityPassingStatus.Waiting:
            case ActivityPassingStatus.InProgress:
                return canvas?.thematicBlockColorWhilePassing
            default:
                return canvas?.thematicBlockColorBeforePassing
        }
    }

    const getActivityRandomBlockColor = (status: ActivityPassingStatus) => {
        switch (status) {
            case ActivityPassingStatus.CompletedSuccessfully:
                return canvas?.thematicBlockColorAfterPassing
            default:
                return canvas?.thematicBlockColorBeforePassing
        }
    }

    const isStageDidNotTouched = (status: ActivityPassingStatus) =>
        isNil(status)

    const isStageLock = useCallback(
        (
            currentStageStatus: ActivityPassingStatus,
            prevStageStatus?: ActivityPassingStatus
        ) =>
            !isActivityLessonCompletedSuccessfully(prevStageStatus) &&
            isStageDidNotTouched(currentStageStatus) &&
            isActivityStepByStep,
        [isActivityStepByStep]
    )

    const handleStartThematicBlock = useCallback(
        (
            currentLesson: ActivityLessonPassingContract,
            prevLesson?: ActivityLessonPassingContract
        ) => () => {
            if (isStageLock(currentLesson.status, prevLesson?.status)) return

            onStartThematicBlock(currentLesson.lessonId)

            if (isThemeBlockTypeQuestionnaire(currentLesson.type)) {
                showQuestionnaireForm()
            }
        },
        [showQuestionnaireForm, isStageLock, onStartThematicBlock]
    )

    const isNeedsIconPlaceholder = (lesson: ActivityLessonPassingContract) =>
        !isActivityLessonCompletedSuccessfully(lesson.status) &&
        activityLessonPassings.some((el) =>
            isActivityLessonCompletedSuccessfully(el.status)
        )

    return (
        <div className={styles.wrapper}>
            <div
                style={getBackgroundImageStyle(canvas?.canvasBackground?.url)}
                className={styles.body}
            >
                {showReturnButton && (
                    <Button
                        type="link"
                        onClick={
                            parentLessonIdForPassing
                                ? onCloseNestedActivity
                                : goBackToTrackLayer
                        }
                        className={styles.returnButton}
                    >
                        <IconsAdapter iconType="CustomIconArrow" />
                    </Button>
                )}

                <h1
                    className={styles.eventTitle}
                    style={getStyles(canvas?.titleSettings)}
                >
                    {name}
                </h1>

                <pre
                    className={styles.eventDescription}
                    style={getStyles(canvas?.descriptionSettings)}
                >
                    {announcement}
                </pre>

                <div
                    className={styles.blocks}
                    style={getStyles(canvas?.listSettings)}
                >
                    {activityLessonPassings.map((lesson, index) => (
                        <a
                            className={cn(
                                styles.blockTitle,
                                isStageLock(
                                    lesson.status,
                                    activityLessonPassings[index - 1]?.status
                                ) && styles.inactive
                            )}
                            style={{
                                color: isActivityStepByStep
                                    ? getActivityStepByStepBlockColor(
                                          lesson.status
                                      )
                                    : getActivityRandomBlockColor(
                                          lesson.status
                                      ),
                            }}
                            key={index}
                            href="#s"
                            onClick={handleStartThematicBlock(
                                lesson,
                                activityLessonPassings[index - 1]
                            )}
                        >
                            {isActivityLessonCompletedSuccessfully(
                                lesson.status
                            ) && (
                                <IconsAdapter
                                    className={styles.icon}
                                    iconType="CheckOutlined"
                                    style={{
                                        color: getActivityStepByStepBlockColor(
                                            lesson.status
                                        ),
                                    }}
                                />
                            )}

                            {isNeedsIconPlaceholder(lesson) && (
                                <span className={styles.iconPlaceholder} />
                            )}

                            <span className={styles.lessonName}>
                                {lesson.name}
                            </span>
                        </a>
                    ))}
                </div>

                {showFurtherButton && (
                    <Button
                        onClick={handleFurtherBtnClick}
                        className={styles.nextButton}
                    >
                        {LOCAL.ACTIONS.FURTHER}
                    </Button>
                )}
            </div>

            {questionnaireForm}

            {meetingModal}

            {videoModal}

            {finishEventModal}
        </div>
    )
})
