import { LOCAL } from 'core/local'
import { Store } from 'App.types'
import { UserPermission } from 'core/api'

/** Основа для role-based модели доступа внутри приложения */
export const ROLE_BASED_PERMISSIONS: Store<string[]> = {
    Developer: Object.values(UserPermission),
}

/**
 * Права доступа
 */
export const PERMISSIONS: Store<string> = {
    [UserPermission.Lk]: LOCAL.PERMISSIONS.LK,
    [UserPermission.Administration]: LOCAL.PERMISSIONS.ADMINISTRATION,
    [UserPermission.AssessmentReportRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_REPORT_READ,
    [UserPermission.AssessmentReportsRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_REPORTS_READ,
    [UserPermission.AssessmentReportTemplate360Save]:
        LOCAL.PERMISSIONS.ASSESSMENT_REPORT_TEMPLATE_360_SAVE,
    [UserPermission.AssessmentReportTemplateManagerSave]:
        LOCAL.PERMISSIONS.ASSESSMENT_REPORT_TEMPLATE_MANAGER_SAVE,
    [UserPermission.AssessmentReportEmployeeAccess]:
        LOCAL.PERMISSIONS.ASSESSMENT_REPORT_EMPLOYEE_ACCESS,
    [UserPermission.Developer]: LOCAL.PERMISSIONS.DEVELOPER,
    [UserPermission.Lk]: LOCAL.PERMISSIONS.LK,
    [UserPermission.PositionProfilesRead]:
        LOCAL.PERMISSIONS.POSITION_PROFILES_READ,
    [UserPermission.PositionProfileRead]:
        LOCAL.PERMISSIONS.POSITION_PROFILE_READ,
    [UserPermission.PositionProfileSave]:
        LOCAL.PERMISSIONS.POSITION_PROFILE_SAVE,
    [UserPermission.PositionProfileDelete]:
        LOCAL.PERMISSIONS.POSITION_PROFILE_DELETE,
    [UserPermission.PositionProfilePublish]:
        LOCAL.PERMISSIONS.POSITION_PROFILE_PUBLISH,
    [UserPermission.ThemesRead]: LOCAL.PERMISSIONS.THEMES_READ,
    [UserPermission.ThemeRead]: LOCAL.PERMISSIONS.THEME_READ,
    [UserPermission.ThemeCreate]: LOCAL.PERMISSIONS.THEME_CREATE,
    [UserPermission.ThemeUpdate]: LOCAL.PERMISSIONS.THEME_UPDATE,
    [UserPermission.ThemeDelete]: LOCAL.PERMISSIONS.THEME_DELETE,
    [UserPermission.TestPortfoliosRead]: LOCAL.PERMISSIONS.TEST_PORTFOLIOS_READ,
    [UserPermission.TestPortfolioRead]: LOCAL.PERMISSIONS.TEST_PORTFOLIO_READ,
    [UserPermission.TestPortfolioSave]: LOCAL.PERMISSIONS.TEST_PORTFOLIO_SAVE,
    [UserPermission.TestPortfolioDelete]:
        LOCAL.PERMISSIONS.TEST_PORTFOLIO_DELETE,
    [UserPermission.TestPortfolioPublish]:
        LOCAL.PERMISSIONS.TEST_PORTFOLIO_PUBLISH,
    [UserPermission.TestAppointmentsRead]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENTS_READ,
    [UserPermission.TestAppointmentRead]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENT_READ,
    [UserPermission.TestAppointmentSave]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENT_SAVE,
    [UserPermission.TestAppointmentPublish]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENT_PUBLISH,
    [UserPermission.TestAppointmentPause]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENT_PAUSE,
    [UserPermission.TestAppointmentDelete]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENT_DELETE,
    [UserPermission.TestAppointmentContinue]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENT_CONTINUE,
    [UserPermission.TestAppointmentMonitoring]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENT_MONITORING,
    [UserPermission.TestAppointmentCancel]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENT_CANCEL,
    [UserPermission.TestAppointmentStop]:
        LOCAL.PERMISSIONS.TEST_APPOINTMENT_STOP,
    [UserPermission.AssessmentPortfoliosRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_PORTFOLIOS_READ,
    [UserPermission.AssessmentPortfolio180270360Read]:
        LOCAL.PERMISSIONS.ASSESSMENT_PORTFOLIO_180270360_READ,
    [UserPermission.AssessmentPortfolioExpertRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_PORTFOLIO_EXPERT_READ,
    [UserPermission.AssessmentPortfolioManagerRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_PORTFOLIO_MANAGER_READ,
    [UserPermission.AssessmentPortfolioSave]:
        LOCAL.PERMISSIONS.ASSESSMENT_PORTFOLIO_SAVE,
    [UserPermission.AssessmentPortfolioDelete]:
        LOCAL.PERMISSIONS.ASSESSMENT_PORTFOLIO_DELETE,
    [UserPermission.AssessmentPortfolioPublish]:
        LOCAL.PERMISSIONS.ASSESSMENT_PORTFOLIO_PUBLISH,
    [UserPermission.QuestionnairesRead]: LOCAL.PERMISSIONS.QUESTIONNAIRES_READ,
    [UserPermission.QuestionnaireAssessment180270360Reads]: '',
    [UserPermission.QuestionnaireAssessmentExpertReads]: '',
    [UserPermission.QuestionnaireAssessmentManagerReads]: '',
    [UserPermission.QuestionnaireQuizFeedbackRead]: '',
    [UserPermission.QuestionnaireQuizInvolvementRead]: '',
    [UserPermission.QuestionnaireQuizServiceQualityRead]: '',
    [UserPermission.QuestionnaireQuizRecommendRead]: '',
    [UserPermission.QuestionnaireTestRead]: '',
    [UserPermission.QuestionnaireSave]: LOCAL.PERMISSIONS.QUESTIONNAIRE_SAVE,
    [UserPermission.QuestionnaireDelete]:
        LOCAL.PERMISSIONS.QUESTIONNAIRE_DELETE,
    [UserPermission.QuestionnairePublish]:
        LOCAL.PERMISSIONS.QUESTIONNAIRE_PUBLISH,
    [UserPermission.AssessmentAppointmentsRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENTS_READ,
    [UserPermission.AssessmentAppointment180270360Read]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_180270360_READ,
    [UserPermission.AssessmentAppointmentExpertRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_EXPERT_READ,
    [UserPermission.AssessmentAppointmentManagerRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_MANAGER_READ,
    [UserPermission.AssessmentAppointmentSave]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_SAVE,
    [UserPermission.AssessmentAppointmentDelete]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_DELETE,
    [UserPermission.AssessmentAppointmentPublish]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_PUBLISH,
    [UserPermission.AssessmentAppointmentPause]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_PAUSE,
    [UserPermission.AssessmentAppointmentContinue]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_CONTINUE,
    [UserPermission.AssessmentAppointmentMonitoring]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_MONITORING,
    [UserPermission.AssessmentAppointmentCancel]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_CANCEL,
    [UserPermission.AssessmentAppointmentStop]:
        LOCAL.PERMISSIONS.ASSESSMENT_APPOINTMENT_STOP,
    [UserPermission.DevelopmentTracksRead]:
        LOCAL.PERMISSIONS.DEVELOPMENT_TRACKS_READ,
    [UserPermission.DevelopmentTrackRead]:
        LOCAL.PERMISSIONS.DEVELOPMENT_TRACK_READ,
    [UserPermission.DevelopmentTrackSave]:
        LOCAL.PERMISSIONS.DEVELOPMENT_TRACK_SAVE,
    [UserPermission.DevelopmentTrackDelete]:
        LOCAL.PERMISSIONS.DEVELOPMENT_TRACK_DELETE,
    [UserPermission.DevelopmentTrackPublish]:
        LOCAL.PERMISSIONS.DEVELOPMENT_TRACK_PUBLISH,
    [UserPermission.AdaptationTracksRead]:
        LOCAL.PERMISSIONS.ADAPTATION_TRACKS_READ,
    [UserPermission.AdaptationTrackRead]:
        LOCAL.PERMISSIONS.ADAPTATION_TRACK_READ,
    [UserPermission.AdaptationTrackSave]:
        LOCAL.PERMISSIONS.ADAPTATION_TRACK_SAVE,
    [UserPermission.AdaptationTrackDelete]:
        LOCAL.PERMISSIONS.ADAPTATION_TRACK_DELETE,
    [UserPermission.AdaptationTrackPublish]:
        LOCAL.PERMISSIONS.ADAPTATION_TRACK_PUBLISH,
    [UserPermission.EventsRead]: LOCAL.PERMISSIONS.EVENTS_READ,
    [UserPermission.EventRead]: LOCAL.PERMISSIONS.EVENT_READ,
    [UserPermission.EventSave]: LOCAL.PERMISSIONS.EVENT_SAVE,
    [UserPermission.EventDelete]: LOCAL.PERMISSIONS.EVENT_DELETE,
    [UserPermission.EventPublish]: LOCAL.PERMISSIONS.EVENT_PUBLISH,
    [UserPermission.EventPassing]: LOCAL.PERMISSIONS.EVENT_PASSING,
    [UserPermission.QuizAppointmentsRead]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENTS_READ,
    [UserPermission.QuizAppointmentFeedbackRead]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_FEEDBACK_READ,
    [UserPermission.QuizAppointmentInvolmentRead]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_INVOLMENT_READ,
    [UserPermission.QuizAppointmentRecommendRead]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_RECOMMEND_READ,
    [UserPermission.QuizAppointmentServiceQualityRead]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_SERVICE_QUALITY_READ,
    [UserPermission.QuizAppointmentSave]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_SAVE,
    [UserPermission.QuizAppointmentDelete]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_DELETE,
    [UserPermission.QuizAppointmentPublish]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_PUBLISH,
    [UserPermission.QuizAppointmentPause]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_PAUSE,
    [UserPermission.QuizAppointmentContinue]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_CONTINUE,
    [UserPermission.QuizAppointmentMonitoring]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_MONITORING,
    [UserPermission.QuizAppointmentCancel]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_CANCEL,
    [UserPermission.QuizAppointmentStop]:
        LOCAL.PERMISSIONS.QUIZ_APPOINTMENT_STOP,
    [UserPermission.QuizPortfoliosRead]: LOCAL.PERMISSIONS.QUIZ_PORTFOLIOS_READ,
    [UserPermission.QuizPortfoliosFeedbackRead]:
        LOCAL.PERMISSIONS.QUIZ_PORTFOLIOS_FEEDBACK_READ,
    [UserPermission.QuizPortfoliosInvolmentRead]:
        LOCAL.PERMISSIONS.QUIZ_PORTFOLIOS_INVOLMENT_READ,
    [UserPermission.QuizPortfoliosRecommendRead]:
        LOCAL.PERMISSIONS.QUIZ_PORTFOLIOS_RECOMMEND_READ,
    [UserPermission.QuizPortfoliosServiceQualityRead]:
        LOCAL.PERMISSIONS.QUIZ_PORTFOLIOS_SERVICE_QUALITY_READ,
    [UserPermission.QuizPortfoliosSave]: LOCAL.PERMISSIONS.QUIZ_PORTFOLIOS_SAVE,
    [UserPermission.QuizPortfoliosDelete]:
        LOCAL.PERMISSIONS.QUIZ_PORTFOLIOS_DELETE,
    [UserPermission.QuizPortfoliosPublish]:
        LOCAL.PERMISSIONS.QUIZ_PORTFOLIOS_PUBLISH,
    [UserPermission.ScalesRead]: LOCAL.PERMISSIONS.SCALES_READ,
    [UserPermission.ScaleRead]: LOCAL.PERMISSIONS.SCALE_READ,
    [UserPermission.ScaleSave]: LOCAL.PERMISSIONS.SCALE_SAVE,
    [UserPermission.ScalePublish]: LOCAL.PERMISSIONS.SCALE_PUBLISH,
    [UserPermission.ScaleDelete]: LOCAL.PERMISSIONS.SCALE_DELETE,
    [UserPermission.ComplexesRead]: LOCAL.PERMISSIONS.COMPLEXES_READ,
    [UserPermission.UsersRead]: LOCAL.PERMISSIONS.USERS_READ,
    [UserPermission.UsersProfileRead]: LOCAL.PERMISSIONS.USERS_PROFILE_READ,
    [UserPermission.UserAssessmentResultRead]: '',
    [UserPermission.UserQuizResultRead]: '',
    [UserPermission.UserTestResultRead]: '',
    [UserPermission.StructuralUnitsRead]:
        LOCAL.PERMISSIONS.STRUCTURAL_UNITS_READ,
    [UserPermission.StaffUnitsRead]: LOCAL.PERMISSIONS.STAFF_UNITS_READ,
    [UserPermission.CompetenciesRead]: LOCAL.PERMISSIONS.COMPETENCIES_READ,
    [UserPermission.CompetenceRead]: LOCAL.PERMISSIONS.COMPETENCIE_READ,
    [UserPermission.CompetenceSave]: LOCAL.PERMISSIONS.COMPETENCE_SAVE,
    [UserPermission.CompetenceExtraSave]:
        LOCAL.PERMISSIONS.COMPETENCE_EXTRA_SAVE,
    [UserPermission.CompetenceDelete]: LOCAL.PERMISSIONS.COMPETENCE_DELETE,
    [UserPermission.CompetencePublish]: LOCAL.PERMISSIONS.COMPETENCE_PUBLISH,
    [UserPermission.CompetenceImport]: LOCAL.PERMISSIONS.COMPETENCE_IMPORT,
    [UserPermission.CompetenceGroupRead]:
        LOCAL.PERMISSIONS.COMPETENCE_GROUP_READ,
    [UserPermission.CompetenceGroupSave]:
        LOCAL.PERMISSIONS.COMPETENCE_GROUP_SAVE,
    [UserPermission.CompetenceGroupDelete]:
        LOCAL.PERMISSIONS.COMPETENCE_GROUP_DELETE,
    [UserPermission.CompetenceGroupPublish]:
        LOCAL.PERMISSIONS.COMPETENCE_GROUP_PUBLISH,
    [UserPermission.IndicatorRead]: LOCAL.PERMISSIONS.INDICATOR_READ,
    [UserPermission.IndicatorSave]: LOCAL.PERMISSIONS.INDICATOR_SAVE,
    [UserPermission.IndicatorDelete]: LOCAL.PERMISSIONS.INDICATOR_DELETE,
    [UserPermission.IndicatorPublish]: LOCAL.PERMISSIONS.INDICATOR_PUBLISH,
    [UserPermission.ProductCardsRead]: LOCAL.PERMISSIONS.PRODUCT_CARDS_READ,
    [UserPermission.ProductCardRead]: LOCAL.PERMISSIONS.PRODUCT_CARD_READ,
    [UserPermission.ProductCardSave]: LOCAL.PERMISSIONS.PRODUCT_CARD_SAVE,
    [UserPermission.ProductCardPublish]: LOCAL.PERMISSIONS.PRODUCT_CARD_PUBLISH,
    [UserPermission.ProductCardDelete]: LOCAL.PERMISSIONS.PRODUCT_CARD_DELETE,
    [UserPermission.TrackAppointmentsRead]:
        LOCAL.PERMISSIONS.TRACK_APPOINTMENTS_READ,
    [UserPermission.TrackAppointmentRead]:
        LOCAL.PERMISSIONS.TRACK_APPOINTMENT_READ,
    [UserPermission.TrackAppointmentSave]:
        LOCAL.PERMISSIONS.TRACK_APPOINTMENT_SAVE,
    [UserPermission.TrackAppointmentDelete]:
        LOCAL.PERMISSIONS.TRACK_APPOINTMENT_DELETE,
    [UserPermission.TrackAppointmentGo]: LOCAL.PERMISSIONS.TRACK_APPOINTMENT_GO,
    [UserPermission.AppointmentActivitiesRead]:
        LOCAL.PERMISSIONS.APPOINTMENT_ACTIVITIES_READ,
    [UserPermission.AppointmentActivityRead]:
        LOCAL.PERMISSIONS.APPOINTMENT_ACTIVITY_READ,
    [UserPermission.AppointmentActivitySave]:
        LOCAL.PERMISSIONS.APPOINTMENT_ACTIVITY_SAVE,
    [UserPermission.AppointmentActivityDelete]:
        LOCAL.PERMISSIONS.APPOINTMENT_ACTIVITY_DELETE,
    [UserPermission.AppointmentActivityPublish]:
        LOCAL.PERMISSIONS.APPOINTMENT_ACTIVITY_PUBLISH,
    [UserPermission.CoursesRead]: LOCAL.PERMISSIONS.COURSES_READ,
    [UserPermission.CourseRead]: LOCAL.PERMISSIONS.COURSE_READ,
    [UserPermission.CourseSave]: LOCAL.PERMISSIONS.COURSE_SAVE,
    [UserPermission.CourseDelete]: LOCAL.PERMISSIONS.COURSE_DELETE,
    [UserPermission.CoursePlay]: LOCAL.PERMISSIONS.COURSE_PLAY,
    [UserPermission.TextDocumentsRead]: LOCAL.PERMISSIONS.TEXT_DOCUMENTS_READ,
    [UserPermission.TextDocumentRead]: LOCAL.PERMISSIONS.TEXT_DOCUMENT_READ,
    [UserPermission.TextDocumentSave]: LOCAL.PERMISSIONS.TEXT_DOCUMENT_SAVE,
    [UserPermission.TextDocumentDelete]: LOCAL.PERMISSIONS.TEXT_DOCUMENT_DELETE,
    [UserPermission.PurposesRead]: LOCAL.PERMISSIONS.PURPOSES_READ,
    [UserPermission.PurposeRead]: LOCAL.PERMISSIONS.PURPOSE_READ,
    [UserPermission.PurposeSave]: LOCAL.PERMISSIONS.PURPOSE_SAVE,
    [UserPermission.PurposeDelete]: LOCAL.PERMISSIONS.PURPOSE_DELETE,
    [UserPermission.AssessmentProjectsRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_PROJECTS_READ,
    [UserPermission.AssessmentProject360Read]:
        LOCAL.PERMISSIONS.ASSESSMENT_PROJECT_360_READ,
    [UserPermission.AssessmentProjectManagerRead]:
        LOCAL.PERMISSIONS.ASSESSMENT_PROJECT_MANAGER_READ,
    [UserPermission.AssessmentProjectSave]:
        LOCAL.PERMISSIONS.ASSESSMENT_PROJECT_SAVE,
    [UserPermission.AssessmentProjectDelete]:
        LOCAL.PERMISSIONS.ASSESSMENT_PROJECT_DELETE,
    [UserPermission.AssessmentProjectPublish]:
        LOCAL.PERMISSIONS.ASSESSMENT_PROJECT_PUBLISH,
    [UserPermission.AssessmentProjectMonitoring]:
        LOCAL.PERMISSIONS.ASSESSMENT_PROJECT_MONITORING,
    [UserPermission.Lk]: LOCAL.PERMISSIONS.LK,
    [UserPermission.LkHomeworkCheck]: LOCAL.PERMISSIONS.LK_HOMEWORK_CHECK,
    [UserPermission.LkQuizCheck]: LOCAL.PERMISSIONS.LK_QUIZ_CHECK,
    [UserPermission.LkMeetingResult]: LOCAL.PERMISSIONS.LK_MEETING_RESULT,
    [UserPermission.LkMonitoring]: LOCAL.PERMISSIONS.LK_MONITORING,
    [UserPermission.MguuTracksRead]: LOCAL.PERMISSIONS.MGUU_TRACKS_READ,
    [UserPermission.MguuTrackRead]: LOCAL.PERMISSIONS.MGUU_TRACK_READ,
    [UserPermission.MguuTrackSave]: LOCAL.PERMISSIONS.MGUU_TRACK_SAVE,
    [UserPermission.NotificationNewsletterTemplatesRead]:
        LOCAL.PERMISSIONS.NOTIFICATION_NEWSLETTER_TEMPLATES_READ,
    [UserPermission.NotificationNewsletterTemplateRead]:
        LOCAL.PERMISSIONS.NOTIFICATION_NEWSLETTER_TEMPLATE_READ,
    [UserPermission.NotificationNewsletterTemplateSave]:
        LOCAL.PERMISSIONS.NOTIFICATION_NEWSLETTER_TEMPLATE_SAVE,
    [UserPermission.NotificationNewsletterTemplateDelete]:
        LOCAL.PERMISSIONS.NOTIFICATION_NEWSLETTER_TEMPLATE_DELETE,
    [UserPermission.NotificationNewsletterTemplatePublish]:
        LOCAL.PERMISSIONS.NOTIFICATION_NEWSLETTER_TEMPLATE_PUBLISH,
    [UserPermission.NotificationMailingTasksRead]:
        LOCAL.PERMISSIONS.NOTIFICATION_MAILING_TASKS_READ,
    [UserPermission.NotificationMailingTaskRead]:
        LOCAL.PERMISSIONS.NOTIFICATION_MAILING_TASK_READ,
    [UserPermission.NotificationMailingTaskSave]:
        LOCAL.PERMISSIONS.NOTIFICATION_MAILING_TASK_SAVE,
    [UserPermission.NotificationMailingTaskDelete]:
        LOCAL.PERMISSIONS.NOTIFICATION_MAILING_TASK_DELETE,
    [UserPermission.NotificationMailingTaskPause]:
        LOCAL.PERMISSIONS.NOTIFICATION_MAILING_TASK_PAUSE,
    [UserPermission.NotificationMailingTaskContinue]:
        LOCAL.PERMISSIONS.NOTIFICATION_MAILING_TASK_CONTINUE,
    [UserPermission.NotificationTagsRead]:
        LOCAL.PERMISSIONS.NOTIFICATION_TAGS_READ,
    [UserPermission.NotificationTagRead]:
        LOCAL.PERMISSIONS.NOTIFICATION_TAG_READ,
    [UserPermission.NotificationTagSave]:
        LOCAL.PERMISSIONS.NOTIFICATION_TAG_SAVE,
    [UserPermission.NotificationTagDelete]:
        LOCAL.PERMISSIONS.NOTIFICATION_TAG_DELETE,
    [UserPermission.CertificateTemplateSave]:
        LOCAL.PERMISSIONS.CERTIFICATE_TEMPLATE_SAVE,
    [UserPermission.UsersViewAll]: '',
    [UserPermission.UsersViewDown]: '',
    [UserPermission.UsersViewUp]: '',
    [UserPermission.KnowledgeBaseRead]: LOCAL.PERMISSIONS.KNOWLEDGE_BASE_READ,
    [UserPermission.KnowledgeBaseArticleRead]:
        LOCAL.PERMISSIONS.KNOWLEDGE_BASE_ARTICLE_READ,
    [UserPermission.KnowledgeBaseArticleSave]:
        LOCAL.PERMISSIONS.KNOWLEDGE_BASE_ARTICLE_SAVE,
    [UserPermission.KnowledgeBaseArticleDelete]:
        LOCAL.PERMISSIONS.KNOWLEDGE_BASE_ARTICLE_DELETE,
}
