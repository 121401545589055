import cn from 'classnames'
import isNil from 'lodash/isNil'
import React, { useCallback, useContext } from 'react'
import { ActivityLessonPassingContract, ActivityPassingStatus } from 'core/api'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { TRACK_PASSING_EVENTS } from 'components/pages/TrackPassing/TrackPassing.events'
import { TrackPassingContext } from 'components/pages/TrackPassing/TrackPassing.context'
import { eventEmitter } from 'core/helpers/eventEmitter'
import {
    getBackgroundImageStyle,
    getStyles,
    isActivityLessonCompletedSuccessfully,
    isEventPassingTypeStepByStep,
} from 'utils'

import styles from './ListCanvas.module.scss'
import { ActivityChildProps } from '../../ActivityLayer.types'
import { useCanvas } from '../../ActivityLayer.hooks'

export const ListCanvas: React.FC<ActivityChildProps> = React.memo(
    ({ activity }) => {
        const { store } = useContext(TrackPassingContext)

        const { lessonInfo } = store

        const {
            showFurtherButton,
            showReturnButton,
            showFinishActivityModal,
            returnToPrevLayer,
        } = useCanvas({ activity })

        const activityLessonPassings = activity?.activityLessonPassings
        const canvas = activity?.canvas

        const isActivityStepByStep = isEventPassingTypeStepByStep(
            activity?.lessonAccessType
        )

        const getActivityStepByStepBlockColor = (
            status: ActivityPassingStatus
        ) => {
            switch (status) {
                case ActivityPassingStatus.CompletedSuccessfully:
                    return canvas?.thematicBlockColorAfterPassing
                case ActivityPassingStatus.Waiting:
                case ActivityPassingStatus.InProgress:
                    return canvas?.thematicBlockColorWhilePassing
                default:
                    return canvas?.thematicBlockColorBeforePassing
            }
        }

        const getActivityRandomBlockColor = (status: ActivityPassingStatus) => {
            switch (status) {
                case ActivityPassingStatus.CompletedSuccessfully:
                    return canvas?.thematicBlockColorAfterPassing
                default:
                    return canvas?.thematicBlockColorBeforePassing
            }
        }

        const lessonWasNotTouched = (status: ActivityPassingStatus) =>
            isNil(status)

        const isLessonLock = useCallback(
            (
                currentStageStatus: ActivityPassingStatus,
                prevStageStatus?: ActivityPassingStatus
            ) =>
                !isActivityLessonCompletedSuccessfully(prevStageStatus) &&
                lessonWasNotTouched(currentStageStatus) &&
                isActivityStepByStep,
            [isActivityStepByStep]
        )

        const startLesson = useCallback(
            (
                currentLesson: ActivityLessonPassingContract,
                prevLesson?: ActivityLessonPassingContract
            ) => () => {
                if (isLessonLock(currentLesson.status, prevLesson?.status))
                    return

                const isParentStage = !lessonInfo
                const isParentActivity = !!lessonInfo?.nestedActivityPassing

                eventEmitter.emit(TRACK_PASSING_EVENTS.START_LESSON, {
                    lesson: currentLesson,
                    activityPassingId: activity?.id,
                    isParentStage,
                    isParentActivity,
                })
            },
            [activity, isLessonLock, lessonInfo]
        )

        const isNeedsIconPlaceholder = (
            lesson: ActivityLessonPassingContract
        ) =>
            !isActivityLessonCompletedSuccessfully(lesson.status) &&
            activityLessonPassings?.some((el) =>
                isActivityLessonCompletedSuccessfully(el.status)
            )

        return (
            <div className={styles.wrapper}>
                <div
                    style={getBackgroundImageStyle(
                        canvas?.canvasBackground?.url
                    )}
                    className={styles.body}
                >
                    {showReturnButton && (
                        <Button
                            type="link"
                            onClick={returnToPrevLayer}
                            className={styles.returnButton}
                        >
                            <IconsAdapter iconType="CustomIconArrow" />
                        </Button>
                    )}

                    <h1
                        className={styles.eventTitle}
                        style={getStyles(canvas?.titleSettings)}
                    >
                        {activity?.name}
                    </h1>

                    <pre
                        className={styles.eventDescription}
                        style={getStyles(canvas?.descriptionSettings)}
                    >
                        {activity?.announcement}
                    </pre>

                    <div
                        className={styles.blocks}
                        style={getStyles(canvas?.listSettings)}
                    >
                        {activityLessonPassings?.map((lesson, index) => (
                            <a
                                className={cn(
                                    styles.blockTitle,
                                    isLessonLock(
                                        lesson.status,
                                        activityLessonPassings?.[index - 1]
                                            ?.status
                                    ) && styles.inactive
                                )}
                                style={{
                                    color: isActivityStepByStep
                                        ? getActivityStepByStepBlockColor(
                                              lesson.status
                                          )
                                        : getActivityRandomBlockColor(
                                              lesson.status
                                          ),
                                }}
                                key={index}
                                href="#s"
                                onClick={startLesson(
                                    lesson,
                                    activityLessonPassings?.[index - 1]
                                )}
                            >
                                {isActivityLessonCompletedSuccessfully(
                                    lesson.status
                                ) && (
                                    <IconsAdapter
                                        className={styles.icon}
                                        iconType="CheckOutlined"
                                        style={{
                                            color: getActivityStepByStepBlockColor(
                                                lesson.status
                                            ),
                                        }}
                                    />
                                )}

                                {isNeedsIconPlaceholder(lesson) && (
                                    <span className={styles.iconPlaceholder} />
                                )}

                                <span className={styles.lessonName}>
                                    {lesson.name}
                                </span>
                            </a>
                        ))}
                    </div>

                    {showFurtherButton && (
                        <Button
                            onClick={showFinishActivityModal}
                            className={styles.nextButton}
                        >
                            {LOCAL.ACTIONS.FURTHER}
                        </Button>
                    )}
                </div>
            </div>
        )
    }
)
