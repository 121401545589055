import cn from 'classnames'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppSettingsContext, UserContext } from 'contexts'
import { HasPermissions } from 'core/permissions/HasPermissions'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { UserPermission } from 'core/api'
import { backwardFn } from 'utils'
import { useQuery } from 'hooks'
import { useRouteMatch } from 'react-router-dom'

import styles from './Header.module.scss'
import {
    AdminMenu,
    MainMenu,
    MobileNavigation,
    MonitoringBtn,
    NotificationIndicator,
    ProfileMenu,
    ThemeSwitcher,
} from './components'
import {
    MATCH_CONFIG_FOR_HEADER_NONE,
    MATCH_CONFIG_FOR_HEADER_SLIDE,
} from './Header.consts'
import { useShowMonitoring } from './Header.hooks'

/** Основной хеадер приложения */
export const Header: React.FC = React.memo(() => {
    const { userData, userSettingsData } = useContext(UserContext)
    const { appSettings } = useContext(AppSettingsContext)

    const query = useQuery()

    const routeMatchForHidden = useRouteMatch(MATCH_CONFIG_FOR_HEADER_SLIDE)
    const routeMatchForDisplayNone = useRouteMatch(MATCH_CONFIG_FOR_HEADER_NONE)

    const { isShowMonitoring } = useShowMonitoring(
        userSettingsData?.monitoringEnable
    )

    const [isToggle, setToggle] = useState(false)

    const [isLesson, setIsLesson] = useState(true)

    const isHeaderHidden = routeMatchForHidden?.isExact && !isToggle

    const handleToggleClick = useCallback(() => {
        setToggle(backwardFn)
    }, [])

    useEffect(() => {
        if (query.get('currentCourseId')) {
            setIsLesson(true)

            return
        }

        setIsLesson(false)
    }, [query])

    return userData && !routeMatchForDisplayNone ? (
        <>
            <div className={styles.target} />

            <header
                className={cn(
                    styles.wrapper,
                    isHeaderHidden && styles.wrapperHidden,
                    routeMatchForHidden?.isExact && styles.wrapperFixed,
                    isLesson && isToggle && styles.contentMargin
                )}
            >
                <div className={styles.body}>
                    <MainMenu />

                    <div className={styles.extraNavigation}>
                        {isShowMonitoring && <MonitoringBtn />}

                        {appSettings?.isThemeSwitcherVisible && (
                            <ThemeSwitcher />
                        )}

                        <HasPermissions
                            requiredPermissions={[
                                UserPermission.Administration,
                            ]}
                        >
                            <AdminMenu />
                        </HasPermissions>

                        <NotificationIndicator />

                        <ProfileMenu />

                        <MobileNavigation />
                    </div>
                </div>

                {routeMatchForHidden?.isExact && (
                    <IconsAdapter
                        className={cn(
                            styles.toggle,
                            isHeaderHidden && styles.toggleReverted
                        )}
                        onClick={handleToggleClick}
                        iconType="ArrowLeftOutlined"
                    />
                )}
            </header>
        </>
    ) : null
})
