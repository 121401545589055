/**
 * Статусы прохождения треков
 */
export const TRACKS_PROGRESS_STATUSES_FOR_EXCLUDE = [
    'AvailableToPassing',
    'InWork',
    'Passed',
    'NotPassed',
    'Archive',
]
