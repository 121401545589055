import React, { useContext } from 'react'
import { AddOrganizationControl } from 'components/controls/AddOrganizationControl'
import { AddPositionControl } from 'components/controls/AddPositionControl'
import {
    COL_lg12_xs24,
    COL_xl8_lg12_xs24,
    EXCLUDE_DRAFT_STATUS_SEARCH_BODY,
    ROW_GUTTER,
} from 'consts'
import {
    COMPETENCIES_TABLE_COLUMNS,
    CheckboxControl,
    CompetenciesFilters,
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SearchSelectControl,
    SelectControl,
    TextAreaControl,
    TimePickerControl,
    UploadAttachments,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import {
    CompetenceForModalContract,
    CompetenciesSearchContract,
    CompetenciesService,
} from 'core/api'
import { DEPENDENCIES_MESSAGES } from 'core/local/messages.local.config'
import { FormInstance } from 'antd/lib/form'
import { LOCAL } from 'core/local'
import { PortfolioFormCommonTabsContext } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import { getOnBeforeChangeHandler } from 'utils'

import {
    COMPETENCIES_FIELD,
    POSITION_FIELD,
    TEST_TYPE_FIELD,
} from '../TestPortfolioForm/TestPortfolioForm.consts'
import { QuestionnaireSet } from '../QuestionnaireSet'
import { TestPortfolioCommonSettingsProps } from './TestPortfolioCommonSettings.types'

/** Вкладка основных настроек для формы создания портфелей тестов */
export const TestPortfolioCommonSettings: React.FC<TestPortfolioCommonSettingsProps> = React.memo(
    ({ dictionaries }) => {
        const { store } = useContext(PortfolioFormCommonTabsContext)

        const { formMode, form, disabledByStatus } = store

        const handleBeforeChange = getOnBeforeChangeHandler({
            dependencies: [['questionnaires']],
            confirmTitle: DEPENDENCIES_MESSAGES.QUESTIONNAIRES,
            form,
        })

        return (
            <>
                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                            required
                        >
                            <InputControl formMode={formMode} />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="organizationsIds"
                            label={LOCAL.LABELS.ORGANIZATIONS}
                            required
                        >
                            <AddOrganizationControl
                                formMode={formMode}
                                disabled={disabledByStatus}
                                withSwitch
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName={POSITION_FIELD}
                            label={LOCAL.LABELS.POSITION}
                        >
                            <AddPositionControl
                                form={form}
                                onBeforeChange={handleBeforeChange}
                                formMode={formMode}
                                disabled={disabledByStatus}
                                withSwitch
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="purposeId"
                            label={LOCAL.LABELS.TEST_POINT}
                        >
                            <SelectControl
                                values={dictionaries?.testPurposes || []}
                                formMode={formMode}
                                disabled={disabledByStatus}
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName={TEST_TYPE_FIELD}
                            label={LOCAL.LABELS.TEST_KIND}
                            required
                        >
                            <SelectControl
                                values={dictionaries?.testTypes || []}
                                formMode={formMode}
                                disabled={disabledByStatus}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="description"
                            label={LOCAL.LABELS.DESCRIPTION}
                        >
                            <TextAreaControl
                                autoSize={{ minRows: 7, maxRows: 7 }}
                                formMode={formMode}
                            />
                        </FormItemAdapter>

                        <UploadAttachments
                            form={form}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </Col>
                </Row>

                <ControlGroup title={LOCAL.LABELS.COMPETENCE_ADD} noPadding>
                    <FormItemAdapter
                        fieldName={COMPETENCIES_FIELD}
                        label={LOCAL.LABELS.ASSESSED_COMPETENCIES}
                        required
                    >
                        <SearchSelectControl<
                            CompetenciesSearchContract,
                            CompetenceForModalContract
                        >
                            getByIdsMethod={CompetenciesService.getByIds}
                            selectMode="multiple"
                            form={form as FormInstance}
                            onBeforeChange={handleBeforeChange}
                            formMode={formMode}
                            modalTitle={LOCAL.LABELS.COMPETENCES_SELECTION}
                            tableSearchOptions={{
                                tableFiltersOptions: {
                                    initialValues: EXCLUDE_DRAFT_STATUS_SEARCH_BODY,
                                },
                                searchMethod: CompetenciesService.getForModal,
                                filterComponent: CompetenciesFilters,
                                tableColumns: COMPETENCIES_TABLE_COLUMNS,
                            }}
                            disabled={disabledByStatus}
                        />
                    </FormItemAdapter>
                </ControlGroup>

                <ControlGroup
                    title={LOCAL.LABELS.QUESTIONNAIRE_TEMPLATES_ADD}
                    noPadding
                >
                    <QuestionnaireSet
                        questionnairesTreeData={dictionaries?.questionnaires}
                        form={form}
                        formMode={formMode}
                        disabled={disabledByStatus}
                    />
                </ControlGroup>

                <Row>
                    <Form.Item name="timeIsLimited">
                        <CheckboxControl
                            formMode={formMode}
                            disabled={disabledByStatus}
                        >
                            {LOCAL.ACTIONS.LIMIT_TIME}
                        </CheckboxControl>
                    </Form.Item>

                    <Form.Item name="timeLimit">
                        <TimePickerControl
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </Form.Item>
                </Row>

                <ControlGroup title={LOCAL.LABELS.RESULT_DISPLAY} noPadding>
                    <Form.Item name="showCorrectAnswer">
                        <CheckboxControl
                            formMode={formMode}
                            disabled={disabledByStatus}
                        >
                            {LOCAL.MESSAGES.SHOW_CORRECT_ANSWER_TO_QUESTION}
                        </CheckboxControl>
                    </Form.Item>

                    <Form.Item name="showOverallTestScore">
                        <CheckboxControl
                            formMode={formMode}
                            disabled={disabledByStatus}
                        >
                            {LOCAL.MESSAGES.SHOW_OVERALL_TEST_SCORE}
                        </CheckboxControl>
                    </Form.Item>
                </ControlGroup>
            </>
        )
    }
)
