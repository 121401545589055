import React, { useContext, useMemo } from 'react'
import { ActivityCanvasDesignType } from 'core/api'

import styles from './ActivityLayer.module.scss'
import { FullScreenCanvas, ListCanvas, ModalCanvas } from './components'
import { TrackPassingContext } from '../../TrackPassing.context'

export const ActivityLayer: React.FC = React.memo(() => {
    const { store } = useContext(TrackPassingContext)

    const { stageInfo, lessonInfo } = store

    const activity = useMemo(
        () =>
            lessonInfo?.mainActivityPassingId &&
            lessonInfo?.nestedActivityPassing
                ? {
                      ...lessonInfo?.nestedActivityPassing,
                      id: lessonInfo.mainActivityPassingId,
                      isHomeworkAutoCheck: !!stageInfo?.activity
                          ?.activityPassing.isHomeworkAutoCheck,
                  }
                : stageInfo?.activity?.activityPassing,
        [lessonInfo, stageInfo]
    )

    if (!activity?.canvas?.canvasDesignType) return null

    const getActivityCanvas = () => {
        switch (activity?.canvas?.canvasDesignType) {
            case ActivityCanvasDesignType.FullScreen:
                return <FullScreenCanvas activity={activity} />
            case ActivityCanvasDesignType.ModalWindow:
                return <ModalCanvas activity={activity} />
            default:
                return <ListCanvas activity={activity} />
        }
    }

    return <div className={styles.wrapper}>{getActivityCanvas()}</div>
})
