import React, { useMemo } from 'react'
import {
    FormItemAdapter,
    RadioControl,
    TextAreaControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { getFieldsPath, normalizeScaleValues } from 'utils'

import styles from './CriteriaScalePassing.module.scss'
import { AnswerFieldProps } from '../QuestionnairePassingAnswer/QuestionnairePassingAnswer.types'

/** Секция ответа типа "Шкала критериев" */
export const CriteriaScalePassing: React.FC<AnswerFieldProps> = React.memo(
    ({ criteriaScale, fieldName }) => {
        /**
         * Варианты ответа
         */
        const scaleList = useMemo(
            () => normalizeScaleValues(criteriaScale.scale.scaleValues),
            [criteriaScale]
        )

        return (
            <>
                <FormItemAdapter
                    fieldName={getFieldsPath('scaleValueId', fieldName)}
                    className={styles.radio}
                >
                    <RadioControl values={scaleList} />
                </FormItemAdapter>

                <FormItemAdapter
                    fieldName={getFieldsPath('comment', fieldName)}
                    label={LOCAL.LABELS.COMMENT}
                >
                    <TextAreaControl />
                </FormItemAdapter>
            </>
        )
    }
)
