import {
    DictionaryContract,
    EnumContract,
    StructuralUnitContract,
    SupervisingComplexContract,
} from 'core/api'
import { FnActionProps, FnActionRequiredProps } from 'App.types'
import {
    normalizeDataForSelectAndRadio,
    normalizeDataForTreeSelect,
} from 'utils'
import { useCallback, useState } from 'react'

import { normalizeDataForOrganizationsTreeSelect } from '../components/pages/TracksAdaptation/components/TracksAdaptationFilters/TracksAdaptationFilters.utils'

/**
 * Возможные типы ответа при запросе справочников с сервера
 */
type DictionariesFetchResultProps =
    | StructuralUnitContract
    | DictionaryContract
    | SupervisingComplexContract
    | EnumContract

/**
 * Тип функции нормализации, если не указан по умолчанию берется `normalizeDataForSelectAndRadio`
 */
type NormalizeFnTypeProps =
    | 'treeSelect'
    | 'treeSelectForStructuralUnit'
    | undefined

/**
 * Интерфейс результата запроса справочников
 */
export interface FetchResultProps {
    [key: string]:
        | Promise<DictionariesFetchResultProps[]>
        | [Promise<DictionariesFetchResultProps[]>, NormalizeFnTypeProps]
}

/**
 * Интерфейс для возвращаемых параметров хука `useDictionaries`
 */
interface UseDictionariesOutputProps<DictionariesProps> {
    /** текущее состояние справочников */
    dictionaries: Partial<DictionariesProps>

    /** обработчик обновления справочников */
    handleChangeDictionaries: FnActionProps<Partial<DictionariesProps>>

    /** обработчик запроса справочников с сервера */
    handleFetchDictionaries: FnActionRequiredProps<FetchResultProps>
}

/**
 * Хук для работы со справочниками
 * * @param params параметры инициализации хука
 */
export function useDictionaries<DictionariesProps>(params?: {
    updateLoader?: FnActionRequiredProps<boolean>
}): UseDictionariesOutputProps<DictionariesProps> {
    const { updateLoader } = params || {}
    const [dictionaries, setDictionaries] = useState<
        Partial<DictionariesProps>
    >({})

    /**
     * Нормализация справочников перед записью в стейт `dictionaries`
     * @param dictionary данные справочника
     * @param normalizeFnType тип функции нормализации
     */
    const normalizeDictionary = useCallback(
        (
            dictionaryData?: DictionariesFetchResultProps[],
            normalizeFnType?: NormalizeFnTypeProps
        ) => {
            if (!dictionaryData) return []

            switch (normalizeFnType) {
                case 'treeSelect':
                    return (dictionaryData as Exclude<
                        DictionariesFetchResultProps,
                        EnumContract
                    >[]).map(normalizeDataForTreeSelect)
                case 'treeSelectForStructuralUnit':
                    return (dictionaryData as StructuralUnitContract[]).map(
                        normalizeDataForOrganizationsTreeSelect
                    )
                default:
                    return dictionaryData.map(normalizeDataForSelectAndRadio)
            }
        },
        []
    )

    /**
     * Обработчик обновления справочников
     * @param values новые значения
     */
    const handleChangeDictionaries = useCallback(
        (values?: Partial<DictionariesProps>) => {
            setDictionaries((prevState) => ({
                ...prevState,
                ...values,
            }))
        },
        []
    )

    /**
     * Обработчик запроса справочников с сервера
     * @param fetchResult массив результатов (promise) работы методов АПИ
     * @param normalizeRules правила нормализации
     */
    const handleFetchDictionaries = useCallback(
        (fetchResult: FetchResultProps) => {
            updateLoader?.(true)
            const dictionariesKeys = Object.keys(fetchResult)
            const dictionariesData = Object.values(fetchResult)
            const promises = dictionariesData.map((el) =>
                Array.isArray(el) ? el[0] : el
            )

            Promise.all(promises)
                .then((response) => {
                    const tempDictionaries = response.reduce<
                        Partial<DictionariesProps>
                    >((acc, dictionary, index) => {
                        const currentDictionary = dictionariesData[index]
                        const normalizeFnType = Array.isArray(currentDictionary)
                            ? currentDictionary[1]
                            : undefined

                        return {
                            ...acc,
                            [dictionariesKeys[index]]: normalizeDictionary(
                                dictionary,
                                normalizeFnType
                            ),
                        }
                    }, {})

                    handleChangeDictionaries(tempDictionaries)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    updateLoader?.(false)
                })
        },
        [handleChangeDictionaries, normalizeDictionary, updateLoader]
    )

    return {
        dictionaries,
        handleChangeDictionaries,
        handleFetchDictionaries,
    }
}
