import React from 'react'
import { NotImplementedError } from 'core/helpers/NotImplementedError'

import { NotificationCounterContextProps } from './NotificationCounterContext.types'

export const NotificationCounterContext = React.createContext<
    NotificationCounterContextProps
>({
    notificationUnreadCount: 0,
    setNotificationUnreadCount: () => {
        throw new NotImplementedError()
    },
})
