import React from 'react'
import { CompetenceContract, CompetenciesService } from 'core/api'
import { CompetenceForm } from 'components/forms'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { FormContainer } from 'components/layouts'

import {
    CANCEL_BTN_OPTIONS,
    PUBLISH_BTN_OPTIONS,
    getSaveBtnOptions,
} from './consts/Competencies.config'

/** Стр. отдельной компетенции */
export const CompetenceFormContainer: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <FormContainer<CompetenceContract>
            formId={FORM_IDS.COMPETENCE_FORM}
            formMode={formMode}
            formComponent={CompetenceForm}
            requestMethod={CompetenciesService.getById}
            cancelBtnOptions={CANCEL_BTN_OPTIONS}
            saveBtnOptions={getSaveBtnOptions}
            publishBtnOptions={PUBLISH_BTN_OPTIONS}
        />
    )
)
