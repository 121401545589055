import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form, Row } from 'antd'
import {
    ExpertEvaluationListCompetenceContract,
    ExpertEvaluationListService,
} from 'core/api'
import { FORM_IDS } from 'core/configs'
import {
    FormItemAdapter,
    HiddenField,
    InputNumberControl,
    TextAreaControl,
} from 'components/controls'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ROW_GUTTER } from 'consts'
import { createConfirmPopup, getFormPopupTitle, isFormModeView } from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useForm } from 'antd/lib/form/Form'
import { useHistory } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './AssessmentEvaluationForm.module.scss'
import {
    AssessmentEvaluationFormProps,
    MapFormDataToRequestProps,
} from './AssessmentEvaluationForm.types'
import {
    COMPETENCE_EVALUATION_SAVE,
    INPUT_NUMBER_MAX_VALUE,
    INPUT_NUMBER_MIN_VALUE,
    INPUT_NUMBER_PLACEHOLDER,
} from './AssessmentEvaluation.consts'
import {
    IndicatorsEvaluationList,
    QuestionnairesEvaluationList,
} from './components'
import {
    isActionFOrFormTypeFinish,
    isActionFOrFormTypeNext,
    isActionFOrFormTypePrev,
    mapFormDataToRequest,
    mapResponseToFormData,
} from './AssessmentEvaluationForm.utils'

/** Форма подтверждение результатов оценки экспертом и экспертом группы */
export const AssessmentEvaluationForm: React.FC<AssessmentEvaluationFormProps> = withLoader(
    React.memo(
        ({
            onBlockUserRouting,
            evaluationListData,
            actionForForm,
            currentQuestionnaireIndex,
            setCurrentQuestionnaireIndex,
            formMode,
            updateLoader,
        }) => {
            const [form] = useForm()
            const history = useHistory()
            const [isFormTouched, setFormTouched] = useState(false)
            const [
                evaluationListPageData,
                setEvaluationListPageData,
            ] = useState<ExpertEvaluationListCompetenceContract>()

            /**
             * Запрос страницы с компетенцией
             */
            const fetchEvaluationPage = useCallback(async () => {
                try {
                    updateLoader(true)

                    if (!evaluationListData) return

                    let questionnaireIndex = currentQuestionnaireIndex
                    if (isActionFOrFormTypeNext(actionForForm))
                        questionnaireIndex += 1
                    if (isActionFOrFormTypePrev(actionForForm))
                        questionnaireIndex -= 1

                    const sourceData = await ExpertEvaluationListService.getEvaluationListPage(
                        {
                            appointmentId: evaluationListData.appointmentId,
                            competenceId:
                                evaluationListData.competenceIds[
                                    questionnaireIndex
                                ],
                        }
                    )

                    setEvaluationListPageData(sourceData)

                    form.setFieldsValue(mapResponseToFormData(sourceData))

                    setCurrentQuestionnaireIndex(questionnaireIndex)
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }, [
                form,
                evaluationListData,
                currentQuestionnaireIndex,
                actionForForm,
                setCurrentQuestionnaireIndex,
                updateLoader,
            ])

            /**
             * Отправка оценочного листа по компетенции
             * @param values - данные формы
             * @param appointmentId - id назначения
             */
            const sendEvaluationListPageData = useCallback(
                async (values, appointmentId) => {
                    await ExpertEvaluationListService.saveEvaluationListPage({
                        body: mapFormDataToRequest(values, appointmentId),
                    })
                },
                []
            )

            /** Отправка формы
             *  @param values - данные формы
             */
            const handleFinish = useCallback(
                async (values: MapFormDataToRequestProps) => {
                    try {
                        updateLoader(true)

                        if (!evaluationListData) return

                        const appointmentId = evaluationListData.appointmentId

                        if (isActionFOrFormTypeFinish(actionForForm)) {
                            await sendEvaluationListPageData(
                                values,
                                appointmentId
                            )

                            await ExpertEvaluationListService.finish({
                                appointmentId,
                            })

                            onBlockUserRouting?.(false)

                            createConfirmPopup({
                                title: getFormPopupTitle(
                                    getRouteMeta(
                                        ROUTE_NAMES.ASSESSMENT_ASSIGNED
                                    )
                                ),
                                content:
                                    LOCAL.MESSAGES
                                        .ASSESSMENT_EVALUATION_SAVE_SUCCESS_MESSAGE,
                                onOk: () =>
                                    history.push(
                                        ROUTE_NAMES.ASSESSMENT_ASSIGNED
                                    ),
                                onCancel: () => fetchEvaluationPage(),
                            })
                        } else {
                            isFormTouched
                                ? createConfirmPopup({
                                      content: COMPETENCE_EVALUATION_SAVE,
                                      onOk: () =>
                                          sendEvaluationListPageData(
                                              values,
                                              appointmentId
                                          ),
                                  })
                                : await sendEvaluationListPageData(
                                      values,
                                      appointmentId
                                  )

                            await fetchEvaluationPage()
                        }
                    } catch (error) {
                        console.error(error)
                    } finally {
                        updateLoader(false)
                    }
                },
                [
                    history,
                    sendEvaluationListPageData,
                    isFormTouched,
                    onBlockUserRouting,
                    evaluationListData,
                    fetchEvaluationPage,
                    updateLoader,
                    actionForForm,
                ]
            )

            /** Блок со списком опросников с баллами
             * @param fields - массив объектов Form.List
             */
            const questionnairesList = useCallback(
                (fields: FormListFieldData[]) =>
                    fields.map((field, index) => (
                        <QuestionnairesEvaluationList
                            field={field}
                            fieldIndex={index}
                            evaluationListData={evaluationListPageData}
                        />
                    )),
                [evaluationListPageData]
            )

            /** Блок со списком индикаторов с оценкой
             * @param fields - массив объектов Form.List
             */
            const indicatorsEvaluationList = useCallback(
                (fields: FormListFieldData[]) =>
                    fields.map((field, index) => (
                        <IndicatorsEvaluationList
                            field={field}
                            fieldIndex={index}
                            indicatorData={
                                evaluationListPageData?.indicatorResults[index]
                            }
                        />
                    )),
                [evaluationListPageData]
            )

            /**
             * Обработчик изменений формы
             */
            const handleValuesChange = useCallback(
                () => setFormTouched(true),
                []
            )

            useEffect(() => {
                if (evaluationListData?.competenceIds.length && !actionForForm)
                    fetchEvaluationPage()
            }, [evaluationListData, fetchEvaluationPage, actionForForm])

            useEffect(() => {
                if (isFormTouched && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }
            }, [formMode, isFormTouched, onBlockUserRouting])

            return (
                <Form
                    id={FORM_IDS.ASSESSMENT_EVALUATION}
                    form={form}
                    onFinish={handleFinish}
                    className={styles.wrapper}
                    onValuesChange={handleValuesChange}
                >
                    <HiddenField fieldName="competenceId" />

                    {evaluationListData?.candidateName && (
                        <div className={styles.candidateInfo}>
                            <div>
                                {`${LOCAL.LABELS.ASSESSMENT_OBJECT}: ${evaluationListData.candidateName}, ${evaluationListData.positionName}`}
                            </div>

                            <div>
                                {`${LOCAL.LABELS.EXPERT}: ${evaluationListData.expertName}`}
                            </div>
                        </div>
                    )}

                    <h2>
                        {`${LOCAL.LABELS.COMPETENCE}: `}

                        {evaluationListPageData?.competenceName}
                    </h2>

                    <Form.List name="questionnaireResults">
                        {(fields) => questionnairesList(fields)}
                    </Form.List>

                    <Row
                        align="middle"
                        className={styles.summary}
                        gutter={ROW_GUTTER}
                    >
                        <Col xs={8} className={styles.text}>
                            {`${LOCAL.LABELS.COMPETENCE_FINAL_SCORE}:`}
                        </Col>

                        <Col xs={8} className={styles.input}>
                            <Form.Item name="score">
                                <InputNumberControl
                                    min={INPUT_NUMBER_MIN_VALUE}
                                    max={INPUT_NUMBER_MAX_VALUE}
                                    placeholder={INPUT_NUMBER_PLACEHOLDER}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.List name="indicatorResults">
                        {(fields) => indicatorsEvaluationList(fields)}
                    </Form.List>

                    <Row>
                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="comment"
                                label={LOCAL.LABELS.EXPERT_COMMENT}
                            >
                                <TextAreaControl autoSize={{ minRows: 5 }} />
                            </FormItemAdapter>
                        </Col>
                    </Row>
                </Form>
            )
        }
    )
)
