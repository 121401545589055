import React, { useCallback, useContext } from 'react'
import { ErrorPage } from 'components/pages/utilityPages/ErrorPage'
import { Route, RouteComponentProps } from 'react-router-dom'
import { UserContext } from 'contexts/User'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

import { ProtectedRouteProps } from './ProtectedRoute.types'
import {
    checkRoleBasedPermissions,
    getPermissionsMessages,
} from './ProtectedRoute.utils'

/** Компонент для осуществления защиты определенных роутов приложения */
export const ProtectedRoute: React.FC<ProtectedRouteProps> = React.memo(
    ({
        component: Component,
        children,
        conditionalPermissions,
        requiredPermissions,
        ...rest
    }) => {
        const { userData } = useContext(UserContext)
        const { hasPermissions } = useCheckPermissions()

        /**
         * Возвращаем компонент для рендера
         * @param props пропсы Route для рендера
         */
        const getRouteRender = useCallback(
            (props: RouteComponentProps): React.ReactNode => {
                const missingAccessRights = getPermissionsMessages(
                    requiredPermissions,
                    checkRoleBasedPermissions(userData?.permissions || []),
                    conditionalPermissions
                )

                if (
                    !hasPermissions({
                        requiredPermissions,
                        conditionalPermissions,
                    })
                )
                    return (
                        <ErrorPage
                            errorCode="403"
                            missingAccessRights={missingAccessRights}
                        />
                    )

                return Component ? <Component {...props} /> : children
            },
            [
                conditionalPermissions,
                requiredPermissions,
                userData,
                hasPermissions,
                Component,
                children,
            ]
        )

        return <Route {...rest} render={getRouteRender} />
    }
)
