import { CourseFormat } from 'core/api'

import { Scorm12Adapter } from './Scorm12Adapter'
import { Scorm2004Adapter } from './Scorm2004Adapter'

/**
 * Вспомогательный класс для назначения объекту window API адаптера SCORM, для версий 1.2 и 2004
 */
export class ScormAdapterHelper {
    static mount(format: CourseFormat, courseId: number) {
        switch (format) {
            case CourseFormat.Scorm12:
                window.API = new Scorm12Adapter(courseId)
                break

            case CourseFormat.Scorm13:
                window.API_1484_11 = new Scorm2004Adapter(courseId)
                break
        }
    }

    static unmount() {
        window.API = null
        window.API_1484_11 = null
    }
}
