import React, { ReactText } from 'react'
import { Button } from 'components/shared/Button'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import { GetDynamicTableAdapterColumnsWithActionsProps } from './DynamicTableAdapter.types'
import { IconsAdapter } from '../IconsAdapter'

/** Функция создания колонок для таблицы с возможностью удаления */
export const getDynamicTableAdapterColumnsWithActions = <
    T extends { id: ReactText }
>({
    onRemoveRow,
    formMode,
    disabled,
    columns = [],
    customTableActions,
}: GetDynamicTableAdapterColumnsWithActionsProps<T>): ColumnsType<T> => [
    ...columns,
    {
        render: (_, row) => (
            <>
                {customTableActions?.({
                    record: row,
                    formMode,
                    disabled,
                    onRemoveRow,
                }) || (
                    <Button
                        type="link"
                        title={LOCAL.ACTIONS.DELETE}
                        icon={<IconsAdapter iconType="DeleteOutlined" />}
                        onClick={onRemoveRow(row.id)}
                        disabled={disabled || isFormModeView(formMode)}
                    />
                )}
            </>
        ),
        align: 'right',
    },
]
