import React, { useCallback, useState } from 'react'
import {
    AppError,
    DictionaryContract,
    TrackCanvasTemplateSearchContract,
    TrackCanvasTemplateService,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { Popup, VisualizationOptionsGroup } from 'components/shared'
import {
    SearchTableShowButton,
    TrackTemplateFilters,
} from 'components/controls'

import styles from './DownloadSaveTemplate.module.scss'
import { DownloadSaveTemplateProps } from './DownloadSaveTemplate.types'
import { MapFormDataToRequestProps } from '../../../TrackForm.types'
import { SaveStageVisualisationTemplateForm } from '../SaveStageVisualisationTemplateForm'
import { getTrackTemplateColumns } from './DownloadSaveTemplate.consts'
import {
    mapTrackVisualisation,
    openTrackPreview,
    synchronizeStagesAndCanvases,
} from '../../../TrackForm.utils'

/**
 * Компонент загрузки/сохранения шаблона
 */
export const DownloadSaveTemplate: React.FC<DownloadSaveTemplateProps> = React.memo(
    ({ form, disabled }) => {
        const [initialValues, setInitialValues] = useState<
            MapFormDataToRequestProps
        >()

        const [isModalVisible, setIsModalVisible] = useState(false)

        const handleShowModal = useCallback(() => {
            setIsModalVisible(true)
            setInitialValues(form?.getFieldsValue())
        }, [form])

        const handleCancel = useCallback(() => {
            setIsModalVisible(false)
        }, [])

        const handleRequestFinish = useCallback((err?: AppError) => {
            if (!err) {
                setIsModalVisible(false)
            }
        }, [])

        /**
         * Запрос выбранного шаблона трека
         * @param id - id шаблона трека
         */
        const fetchSelectedTemplate = useCallback(
            async (id: number) => {
                try {
                    const template = await TrackCanvasTemplateService.getById({
                        id,
                    })

                    const trackCanvases = mapTrackVisualisation(
                        template.trackCanvases
                    )

                    form?.setFieldsValue({
                        formTempStore: {
                            trackCanvases,
                        },
                    })

                    synchronizeStagesAndCanvases(form, trackCanvases)
                } catch (error) {
                    console.error(error)
                }
            },
            [form]
        )

        /**
         * Обработчик добавления выбранного шаблона трека
         */
        const handleSelectTrackTemplate = useCallback(
            async (result?: number[]) => {
                if (!result?.[0]) return

                await fetchSelectedTemplate(result[0])
            },
            [fetchSelectedTemplate]
        )

        /**
         * Обработчик открытия предпоказа шаблона визуализации
         */
        const handlePreviewTemplate = useCallback(
            (row: DictionaryContract) => async () => {
                const trackCanvases = await fetchSelectedTemplate(row.id)

                openTrackPreview({
                    ...form?.getFieldsValue(),
                    trackCanvases,
                })
            },
            [fetchSelectedTemplate, form]
        )

        return (
            <VisualizationOptionsGroup className={styles.wrapper}>
                <Button
                    type="primary"
                    onClick={handleShowModal}
                    disabled={disabled}
                >
                    {LOCAL.LABELS.TEMPLATE_SAVE}
                </Button>

                <SearchTableShowButton<
                    TrackCanvasTemplateSearchContract,
                    DictionaryContract
                >
                    tableSearchOptions={{
                        formId: FORM_IDS.SEARCH_TRACK_TEMPLATE,
                        rowSelectionType: 'radio',
                        onSelectTableItem: handleSelectTrackTemplate,
                        searchMethod: TrackCanvasTemplateService.search,
                        tableColumns: getTrackTemplateColumns(
                            handlePreviewTemplate
                        ),
                        filterComponent: TrackTemplateFilters,
                    }}
                    modalTitle={LOCAL.LABELS.TEMPLATE_DOWNLOAD}
                    okButtonText={LOCAL.ACTIONS.UPLOAD}
                >
                    <Button disabled={disabled}>
                        {LOCAL.LABELS.TEMPLATE_DOWNLOAD}
                    </Button>
                </SearchTableShowButton>

                <Popup
                    title={LOCAL.LABELS.TEMPLATE_SAVE}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    destroyOnClose
                >
                    <SaveStageVisualisationTemplateForm
                        onRequestFinish={handleRequestFinish}
                        id={FORM_IDS.SAVE_STAGE_VISUALISATION_TEMPLATE_FORM}
                        onCancelSubmit={handleCancel}
                        initialValues={initialValues}
                    />
                </Popup>
            </VisualizationOptionsGroup>
        )
    }
)
