import React, { useCallback, useContext, useState } from 'react'
import {
    ButtonsToolbar,
    IconsAdapter,
    Popup,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { FormMode, TableActionsCommonProps } from 'App.types'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH, PRODUCT_CATALOG_REQUIRED_PERMISSIONS } from 'consts'
import {
    ProductCardSearchResultContract,
    ProductCardsService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
    stopEventPropagation,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useElementVisible } from 'hooks'
import { useHistory } from 'react-router-dom'

import styles from './ProductCatalogTableActions.module.scss'
import { ProductCatalogContext } from '../../ProductCatalog.context'
import { ProductListenersForm } from '../ProductListenersForm'

/** Компонент для создания колонки действий внутри таблицы реестра карточек продуктов */
export const ProductCatalogTableActions: React.FC<TableActionsCommonProps<
    ProductCardSearchResultContract
>> = React.memo(({ tableRow }) => {
    const history = useHistory()
    const { updateStore } = useContext(ProductCatalogContext)
    const [isLoading, setLoading] = useState(false)
    const [lastActionType, setLastActionType] = useState<FormMode>()

    const getRouteForAction = useCallback(
        (type: FormMode) => {
            const id = tableRow.id

            switch (type) {
                case 'edit':
                    return `${ROUTE_NAMES.PRODUCT_CATALOG_EDIT}/${id}`
                case 'view':
                    return `${ROUTE_NAMES.PRODUCT_CATALOG_VIEW}/${id}`
                case 'copy':
                    return `${ROUTE_NAMES.PRODUCT_CATALOG_COPY}/${id}`
            }
        },
        [tableRow.id]
    )

    const {
        elementVisible,
        toggleElementVisible,
        handleHideElement,
    } = useElementVisible()

    const entityActionFn = useCallback(
        (id: number, type: FormMode) => async () => {
            try {
                setLoading(true)
                setLastActionType(type)

                updateStore({
                    currentProductCard: isFormModeEdit(type)
                        ? await ProductCardsService.getForEdit({ id })
                        : await ProductCardsService.getActive({ id }),
                })

                const route = getRouteForAction(type)

                if (route) history.push(route)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
                setLastActionType(undefined)
            }
        },
        [getRouteForAction, history, updateStore]
    )

    return (
        <ButtonsToolbar bottomIndent="0" onDoubleClick={stopEventPropagation}>
            {tableRow.bookingAvailable && (
                <TableActions.Progress
                    requiredPermissions={[
                        ...PRODUCT_CATALOG_REQUIRED_PERMISSIONS,
                        UserPermission.ProductCardPublish,
                        UserPermission.ProductCardSave,
                    ]}
                    onClick={toggleElementVisible}
                />
            )}

            <TableActions.View
                requiredPermissions={PRODUCT_CATALOG_REQUIRED_PERMISSIONS}
                onClick={entityActionFn(tableRow.id, 'view')}
                disabled={isLoading || !tableRow.active}
                loading={isLoading && isFormModeView(lastActionType)}
            />

            {tableRow.active && (
                <>
                    <TableActions.Edit
                        requiredPermissions={[
                            ...PRODUCT_CATALOG_REQUIRED_PERMISSIONS,
                            UserPermission.ProductCardSave,
                        ]}
                        loading={isLoading && isFormModeEdit(lastActionType)}
                        disabled={isLoading}
                        onClick={entityActionFn(tableRow.id, 'edit')}
                    />

                    <TableActions.Copy
                        requiredPermissions={[
                            ...PRODUCT_CATALOG_REQUIRED_PERMISSIONS,
                            UserPermission.ProductCardSave,
                        ]}
                        onClick={entityActionFn(tableRow.id, 'copy')}
                        disabled={isLoading}
                        loading={isLoading && isFormModeCopy(lastActionType)}
                    />

                    <HasPermissions
                        requiredPermissions={[
                            ...PRODUCT_CATALOG_REQUIRED_PERMISSIONS,
                            UserPermission.ProductCardDelete,
                        ]}
                        accessDeniedRender={renderAccessDeniedButton({
                            type: 'link',
                            icon: <IconsAdapter iconType="DeleteOutlined" />,
                        })}
                    >
                        <PopupWithForm
                            formId={FORM_IDS.CONFIRM_DELETE_FORM}
                            buttonText=""
                            havePopup={false}
                            buttonOption={{
                                type: 'link',
                                icon: (
                                    <IconsAdapter iconType="DeleteOutlined" />
                                ),
                                title: LOCAL.ACTIONS.DELETE,
                            }}
                            formOptions={{
                                initialValues: tableRow,
                            }}
                            modalOptions={{
                                title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                                okText: LOCAL.YES,
                            }}
                        />
                    </HasPermissions>
                </>
            )}

            <Popup
                visible={elementVisible}
                formId={FORM_IDS.PRODUCT_LISTENERS_FORM}
                footer={null}
                width={MODAL_WIDTH.XL}
                title={`${LOCAL.LABELS.PROGRAM_LISTENERS_LISTS}:`}
                onCancel={handleHideElement}
                className={styles.listenerModal}
            >
                <ProductListenersForm
                    initialValues={tableRow}
                    onRequestFinish={handleHideElement}
                />
            </Popup>
        </ButtonsToolbar>
    )
})
