import React from 'react'

import { ReportViewContent } from './ReportViewContent'
import { ReportViewProps } from './ReportView.types'

/** Отчет по оценке. Просмотр */
export const ReportView: React.FC<ReportViewProps> = React.memo(
    ({ template }) => (
        <>
            {template?.map((section) => (
                <ReportViewContent {...section} key={section.id} />
            ))}
        </>
    )
)
