import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { isFormModeView } from 'utils'

import styles from './CheckboxControl.module.scss'
import { CheckboxControlProps } from './CheckboxControlProps.types'

/**
 * Декоратор для `Checkbox` от `antd`, принимает все теже `props`
 */
export const CheckboxControl: React.FC<CheckboxControlProps> = React.memo(
    ({
        disabled,
        formMode,
        className,
        onChange,
        value,
        onBeforeChange,
        ...props
    }) => {
        const [valueState, setValueState] = useState<boolean>()

        const classes = cn(
            isFormModeView(formMode) && 'view-mode',
            'form-control',
            styles.wrapper,
            styles.checkbox,
            className
        )

        const triggerChanges = useCallback(
            (e: CheckboxChangeEvent) => {
                setValueState(e.target.checked)

                onChange?.(e.target.checked)
            },
            [onChange]
        )

        const onChangeCheckbox = useCallback(
            (e: CheckboxChangeEvent) => {
                if (onBeforeChange) {
                    onBeforeChange({
                        onChange: () => triggerChanges(e),
                        value: e.target.checked,
                    })
                } else {
                    triggerChanges(e)
                }
            },
            [onBeforeChange, triggerChanges]
        )

        return (
            <div className={classes}>
                <Checkbox
                    disabled={disabled || isFormModeView(formMode)}
                    onChange={onChangeCheckbox}
                    checked={value || valueState}
                    {...props}
                >
                    {props.children}
                </Checkbox>
            </div>
        )
    }
)
