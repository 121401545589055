import { AnswerConclusion } from 'core/api'

import styles from './ChoosingTypeAnswer.module.scss'

export const ANSWER_RESULT_CLASSNAME = {
    [AnswerConclusion.Correct]: styles.correct,
    [AnswerConclusion.Incorrect]: styles.wrong,
    [AnswerConclusion.HasCorrectAndIncorrect]: styles.notAll,
    [AnswerConclusion.HasNotAllCorrect]: styles.notAll,
    [AnswerConclusion.NotChecked]: styles.notAll,
    [AnswerConclusion.RequiresPersonalChecking]: styles.notAll,
}
