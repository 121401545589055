import React, { useCallback, useMemo } from 'react'
import { AddUsersTableControl } from 'components/controls'
import { Col, Form, Row } from 'antd'
import { CustomControlCommonProps, WithFormModeProps } from 'App.types'
import { LOCAL } from 'core/local'
import { TableAdapter } from 'components/shared'
import { getCompetenciesIds } from 'components/pages/AssessmentProjects/components/AssessmentProjectsForm/AssessmentProjectsForm.utils'
import { renderExpandIcon } from 'utils'

import styles from './AssessmentProjectsManager.module.scss'
import stylesAssessmentProjectsManagerEstimate from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.module.scss'
import { AddCompetenciesControl } from '../AddCompetenciesControl'
import {
    AssesmentProjectCompetenceFormContract,
    UserFormContract,
} from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.types'
import { getCompetenciesColumns } from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.utils'

/** Руководитель оценки руководителем  - форма оценочного проекта */
export const AssessmentProjectsManager: React.FC<
    CustomControlCommonProps<UserFormContract> & WithFormModeProps
> = React.memo(({ value: manager, onChange, formMode }) => {
    const selectedCompetenciesIds = useMemo(
        () => getCompetenciesIds(manager?.competencies),
        [manager]
    )

    /** Изменение компетенций руководителя
     * @param competencies - компетенции
     */
    const onCompetenciesChange = useCallback(
        (competencies: AssesmentProjectCompetenceFormContract[]) => {
            onChange?.({
                ...manager!,
                competencies,
            })
        },
        [onChange, manager]
    )

    /** Обработчик удаления компетенции
     * @param competenceId - id компетенции, которую необходимо удалить
     */
    const handleRemoveCompetecies = useCallback(
        (competenceId: number) => () => {
            onChange?.({
                ...manager!,
                competencies: manager!.competencies?.filter(
                    ({ competence: { id } }) => id !== competenceId
                ),
            })
        },
        [onChange, manager]
    )

    /**
     *  Рендер вложенной структуры компетенций руководителя
     */
    const renderExpandedRow = useCallback(
        () => (
            <>
                <AddCompetenciesControl
                    className={styles.controlsWrapper}
                    onCompetenciesChange={onCompetenciesChange}
                    title={LOCAL.LABELS.COMPETENCIES}
                    initialValues={selectedCompetenciesIds}
                    formMode={formMode}
                    selectedCompetencies={manager?.competencies}
                />

                <Row>
                    <Col xs={12}>
                        {!!manager?.competencies?.length && (
                            <TableAdapter
                                dataSource={manager.competencies}
                                columns={getCompetenciesColumns(
                                    handleRemoveCompetecies
                                )}
                                rowKey={(record) => record.competence.id}
                                pagination={false}
                            />
                        )}
                    </Col>
                </Row>
            </>
        ),
        [
            formMode,
            handleRemoveCompetecies,
            manager,
            onCompetenciesChange,
            selectedCompetenciesIds,
        ]
    )

    return (
        <Form.Item name={['managerParameters', 'managers']} noStyle>
            <AddUsersTableControl
                withSpoiler
                title={LOCAL.LABELS.MANAGER}
                rowSelectionType="radio"
                className={stylesAssessmentProjectsManagerEstimate.control}
                formMode={formMode}
                tableOptions={{
                    expandable: {
                        expandIcon: renderExpandIcon,
                        expandedRowRender: renderExpandedRow,
                    },
                }}
                required
            />
        </Form.Item>
    )
})
