import React from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED, ROW_GUTTER } from 'consts'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import { ScaleSearchContract } from 'core/api'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'

/**
 * Форма фильтрации для реестра
 */
export const CriteriaScalesFilters: React.FC<FiltersProps<
    ScaleSearchContract
>> = React.memo((props) => (
    <ControlGroup type="light">
        <FilterForm {...props} renderFooter={renderFooterWithShowArchiveField}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="statuses"
                        label={LOCAL.LABELS.STATUS}
                    >
                        <SelectControl
                            mode="multiple"
                            values={DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED}
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    </ControlGroup>
))
