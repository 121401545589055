import React from 'react'
import cn from 'classnames'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { TextAlignProperty } from 'App.types'
import { getBackgroundImageStyle, getStyles } from 'utils'

import styles from './ModalCanvas.module.scss'
import { CanvasProps } from '../../ActivityLayer.types'
import { ModalBlocks } from '../ModalBlocks'
import { getPassButtonText } from '../../ActivityLayer.utils'
import { useCanvas } from '../../ActivityLayer.hooks'

/**
 * Отображение для мероприятия типа - "modal"
 */
export const ModalCanvas: React.FC<CanvasProps> = React.memo((props) => {
    const {
        activity,
        activeBlock,
        onSetActiveBlock,
        onCloseNestedActivity,
        parentLessonIdForPassing,
    } = props

    const {
        questionnaireForm,
        meetingModal,
        activeThematicBlock,
        isThematicBlockLock,
        isThematicBlockCompletedAndNotAvailableForRepassing,
        handleStartThematicBlock,
        videoModal,
        showFurtherButton,
        finishEventModal,
        handleFurtherBtnClick,
        showReturnButton,
        goBackToTrackLayer,
    } = useCanvas(props)

    const { canvas, activityLessonPassings } = activity

    const buttonStyles = {
        background: canvas.buttonColor,
        color: canvas.buttonTextColor,
    }

    return (
        <div
            className={styles.wrapper}
            style={{
                justifyContent: canvas.windowAlign,
            }}
        >
            <div className={styles.body}>
                {showReturnButton && (
                    <IconsAdapter
                        iconType="CloseOutlined"
                        className={styles.closeButton}
                        onClick={
                            parentLessonIdForPassing
                                ? onCloseNestedActivity
                                : goBackToTrackLayer
                        }
                        style={{ color: buttonStyles.background }}
                    />
                )}

                <div
                    className={styles.image}
                    style={getBackgroundImageStyle(
                        canvas?.canvasBackground?.url
                    )}
                />

                <h1
                    className={styles.blockTitle}
                    style={getStyles(canvas.blockNameSettings)}
                >
                    {activeThematicBlock?.name}
                </h1>

                <pre
                    className={styles.blockDescription}
                    style={{
                        ...getStyles(canvas.blockDescriptionSettings),
                        borderColor: activeBlock.borderColor,
                    }}
                >
                    {activeThematicBlock?.lessonDescription}
                </pre>

                <div className={styles.blocks}>
                    <ModalBlocks
                        canvas={canvas}
                        blocksList={activityLessonPassings}
                        activeBlock={activeBlock}
                        onSetActiveBlock={onSetActiveBlock}
                    />
                </div>

                <div
                    style={{
                        textAlign: canvas.buttonAlign as TextAlignProperty,
                    }}
                    className={styles.nav}
                >
                    {!isThematicBlockCompletedAndNotAvailableForRepassing &&
                        !isThematicBlockLock && (
                            <Button
                                className={cn(
                                    styles.startButton,
                                    'capitalize-text'
                                )}
                                style={{
                                    background: canvas.buttonColor,
                                    color: canvas.buttonTextColor,
                                }}
                                onClick={handleStartThematicBlock}
                            >
                                {getPassButtonText(
                                    activeThematicBlock,
                                    activity.status
                                )}
                            </Button>
                        )}

                    {showFurtherButton && (
                        <Button
                            style={buttonStyles}
                            onClick={handleFurtherBtnClick}
                            className={cn(styles.nextButton, 'capitalize-text')}
                        >
                            {LOCAL.ACTIONS.FURTHER}
                        </Button>
                    )}
                </div>
            </div>

            {questionnaireForm}

            {meetingModal}

            {videoModal}

            {finishEventModal}
        </div>
    )
})
