import React from 'react'
import { LOCAL } from 'core/local'

import { ErrorBoundaryProps } from './ErrorBoundary.types'

export class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    { hasError: boolean }
> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallbackComponent ? (
                this.props.fallbackComponent
            ) : (
                <h3>{LOCAL.MESSAGES.DEFAULT_ERROR}</h3>
            )
        }

        return this.props.children
    }
}
