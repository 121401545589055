import { HomeworkStatusType } from 'core/api'

/**
 * Проверка состояния домашней работы
 */
export const isHomeworkWaitingForSolution = (status?: HomeworkStatusType) =>
    status === HomeworkStatusType.WaitingForSolution
export const isHomeworkOnChecking = (status?: HomeworkStatusType) =>
    status === HomeworkStatusType.OnChecking
export const isHomeworkPassed = (status?: HomeworkStatusType) =>
    status === HomeworkStatusType.Passed
export const isHomeworkOnRevision = (status?: HomeworkStatusType) =>
    status === HomeworkStatusType.Revision
