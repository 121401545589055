import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    AppError,
    ProductCardContract,
    ProductCardsService,
    UserPermission,
} from 'core/api'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { ComponentCommonProps } from 'App.types'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsForEditProps, useSaveOrPublishState } from 'hooks'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import {
    editSessionCancel,
    isCompetitiveAccessException,
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useHistory, useParams } from 'react-router-dom'

import { PRODUCT_CATALOG_FORM_ID } from './components/ProductCatalogForm/ProductCatalogForm.consts'
import { ProductCatalogContext } from './ProductCatalog.context'
import { ProductCatalogForm } from './components'

/** Страница создания/редактирования карточки продукта */
const ProductCatalogFormContainer: React.FC<
    WithBlockUserRoutingComponentProps & ComponentCommonProps
> = withLoader(
    React.memo(({ onBlockUserRouting, formMode, updateLoader }) => {
        const urlParams = useParams<UrlParamsForEditProps>()
        const { store, updateStore } = useContext(ProductCatalogContext)
        const [productCardInitialData] = useState(store.currentProductCard)
        const history = useHistory()

        const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()

        const fetchInitialFormValuesById = useCallback(async () => {
            try {
                if (!urlParams.id) return

                const requestMethod = !isFormModeEdit(formMode)
                    ? ProductCardsService.getActive
                    : ProductCardsService.getForEdit

                const response = await requestMethod({ id: +urlParams.id })

                updateStore({ currentProductCard: response })
            } catch (error) {
                console.error(error)

                if (isCompetitiveAccessException(error as AppError))
                    history.push(ROUTE_NAMES.PRODUCT_CATALOG)
            }
        }, [formMode, history, updateStore, urlParams.id])

        const initialValuesForEdit = useMemo(() => {
            if (!store.currentProductCard) return

            const { status, ...initialValuesForEdit } = store.currentProductCard

            if (isFormModeCopy(formMode))
                return initialValuesForEdit as ProductCardContract

            return { ...initialValuesForEdit, status }
        }, [formMode, store.currentProductCard])

        const refuseEdit = useCallback(
            () =>
                editSessionCancel({
                    service: ProductCardsService,
                    id: initialValuesForEdit?.id,
                }),
            [initialValuesForEdit]
        )

        useEffect(() => {
            if (
                !productCardInitialData ||
                productCardInitialData.id !== Number(urlParams.id)
            ) {
                fetchInitialFormValuesById()
            }

            return () => {
                productCardInitialData &&
                    updateStore({ currentProductCard: undefined })
            }
        }, [
            fetchInitialFormValuesById,
            productCardInitialData,
            updateStore,
            urlParams.id,
        ])

        return (
            <PageContent>
                <ContentTitleWithBackLink
                    status={initialValuesForEdit?.status}
                    onBack={refuseEdit}
                >
                    <ButtonsToolbar>
                        <Button
                            type="link"
                            href={ROUTE_NAMES.PRODUCT_CATALOG}
                            onClick={refuseEdit}
                        >
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        <HasPermissions
                            requiredPermissions={[
                                UserPermission.ProductCardRead,
                                UserPermission.ProductCardSave,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                text: LOCAL.ACTIONS.SAVE,
                                type: 'primary',
                            })}
                        >
                            <Button
                                form={PRODUCT_CATALOG_FORM_ID}
                                htmlType="submit"
                                type="primary"
                                onClick={handleSubmitForm(true)}
                                disabled={isFormModeView(formMode)}
                            >
                                {LOCAL.ACTIONS.SAVE}
                            </Button>
                        </HasPermissions>

                        <HasPermissions
                            requiredPermissions={[
                                UserPermission.ProductCardRead,
                                UserPermission.ProductCardPublish,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                text: LOCAL.ACTIONS.PUBLISH,
                                type: 'primary',
                            })}
                        >
                            <Button
                                form={PRODUCT_CATALOG_FORM_ID}
                                htmlType="submit"
                                type="primary"
                                onClick={handleSubmitForm(false)}
                                disabled={isFormModeView(formMode)}
                            >
                                {LOCAL.ACTIONS.PUBLISH}
                            </Button>
                        </HasPermissions>
                    </ButtonsToolbar>
                </ContentTitleWithBackLink>

                <ProductCatalogForm
                    updateLoader={updateLoader}
                    onBlockUserRouting={onBlockUserRouting}
                    initialValuesForEdit={initialValuesForEdit}
                    isSaveDocument={isSaveDocument}
                    reFetchInitialFormValues={fetchInitialFormValuesById}
                    formMode={formMode}
                />
            </PageContent>
        )
    })
)

export default React.memo(
    withBlockUserRouting(ProductCatalogFormContainer, false)
) as typeof ProductCatalogFormContainer
