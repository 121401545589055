import React, { useCallback } from 'react'
import { ACTIVITY_CANVAS_DESIGN_TYPE_MAPPED } from 'consts'
import {
    CanvasImagesWithInfiniteScroll,
    SpaceAdapter,
    VisualizationOptionsGroup,
} from 'components/shared'
import {
    CheckboxControl,
    RadioButtonControl,
    TextAlignControl,
} from 'components/controls'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import { Form } from 'antd'
import { ImgsUploadFnResponseProps } from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.types'
import { LOCAL } from 'core/local'
import { canvasBgImgMapFn } from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.utils'

import styles from './EventFormVisualization.module.scss'
import { FullScreenCanvas } from '../FullScreenCanvas'
import { FullScreenType, ListType, ModalType } from '../ActivitiyTypeChecker'
import { ListCanvas } from '../ListCanvas'
import { ModalCanvas } from '../ModalCanvas'
import { VISUALIZATION_COLOR_OPTIONS_META } from '../VisualizationColorOptions/VisualizationColorOptions.consts'
import { VisualizationColorOptions } from '../VisualizationColorOptions'
import { VisualizationTextOptions } from '../VisualizationTextOptions'
import {
    canvasBgImgsFetchFn,
    canvasBgImgsUploadFn,
} from './EventFormVisualization.utils'

/** Вкладка визуализации формы мероприятия */
export const EventFormVisualization: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ form, disabled }) => {
    /**
     * Обработчик выбора фона полотна
     */
    const handleCanvasBgChoose = useCallback(
        (value?: ImgsUploadFnResponseProps) => {
            form?.setFields([
                {
                    name: ['canvas', 'canvasBackgroundUrl'],
                    value: value?.url,
                },
            ])
        },
        [form]
    )

    return (
        <div className={styles.wrapper}>
            <aside>
                <div className={styles.settings}>
                    <VisualizationOptionsGroup
                        title={LOCAL.LABELS.EVENT_VIEW_TYPE}
                        className={styles.design}
                    >
                        <Form.Item name={['canvas', 'canvasDesignType']}>
                            <RadioButtonControl
                                values={ACTIVITY_CANVAS_DESIGN_TYPE_MAPPED}
                                className={styles.radioButtonControl}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </VisualizationOptionsGroup>

                    <CanvasImagesWithInfiniteScroll
                        fileListTitle={LOCAL.LABELS.CANVAS_BG}
                        imgMapFn={canvasBgImgMapFn}
                        imgsUploadFn={canvasBgImgsUploadFn}
                        imgsFetchFn={canvasBgImgsFetchFn}
                        onImgChoose={handleCanvasBgChoose}
                        controlName={['canvas', 'canvasBackgroundId']}
                        controlMetaName={['canvas', 'canvasBackgroundUrl']}
                        disabled={disabled}
                    />

                    <ModalType inverse>
                        <VisualizationTextOptions
                            type="titleSettings"
                            disabled={disabled}
                        />

                        <VisualizationTextOptions
                            type="descriptionSettings"
                            disabled={disabled}
                        />
                    </ModalType>

                    <ListType>
                        <VisualizationTextOptions
                            type="listSettings"
                            disabled={disabled}
                        />
                    </ListType>

                    <ModalType>
                        <VisualizationOptionsGroup
                            title={LOCAL.LABELS.MODAL_WINDOW_SETTINGS}
                        >
                            <SpaceAdapter
                                justifyContent="space-between"
                                fullWidth
                            >
                                <Form.Item>
                                    {LOCAL.LABELS.MODAL_WINDOW_POSITION}
                                </Form.Item>

                                <Form.Item name={['canvas', 'windowAlign']}>
                                    <TextAlignControl disabled={disabled} />
                                </Form.Item>
                            </SpaceAdapter>
                        </VisualizationOptionsGroup>
                    </ModalType>

                    <ListType inverse>
                        <VisualizationTextOptions
                            type="blockNameSettings"
                            disabled={disabled}
                        />
                    </ListType>

                    <ModalType>
                        <VisualizationTextOptions
                            type="blockDescriptionSettings"
                            disabled={disabled}
                        />
                    </ModalType>

                    <VisualizationOptionsGroup
                        title={
                            VISUALIZATION_COLOR_OPTIONS_META
                                .thematicBlocksOptions.title
                        }
                    >
                        <VisualizationColorOptions
                            type="thematicBlocksOptions"
                            disabled={disabled}
                        />

                        <ListType inverse>
                            <Form.Item name={['canvas', 'showLines']} noStyle>
                                <CheckboxControl disabled={disabled}>
                                    {LOCAL.LABELS.DRAW_LINES}
                                </CheckboxControl>
                            </Form.Item>
                        </ListType>

                        <ListType>
                            <Form.Item
                                name={['canvas', 'showProgress']}
                                noStyle
                            >
                                <CheckboxControl disabled={disabled}>
                                    {LOCAL.LABELS.SHOW_PROGRESS}
                                </CheckboxControl>
                            </Form.Item>
                        </ListType>
                    </VisualizationOptionsGroup>

                    <ListType inverse>
                        <VisualizationOptionsGroup
                            title={
                                VISUALIZATION_COLOR_OPTIONS_META.buttonsOptions
                                    .title
                            }
                            className={styles.buttons}
                        >
                            <Form.Item name={['canvas', 'buttonAlign']}>
                                <TextAlignControl disabled={disabled} />
                            </Form.Item>

                            <VisualizationColorOptions
                                type="buttonsOptions"
                                disabled={disabled}
                            />
                        </VisualizationOptionsGroup>
                    </ListType>
                </div>
            </aside>

            <FullScreenType>
                <FullScreenCanvas />
            </FullScreenType>

            <ModalType>
                <ModalCanvas />
            </ModalType>

            <ListType>
                <ListCanvas />
            </ListType>
        </div>
    )
})
