import classNames from 'classnames'
import React, { useCallback, useEffect } from 'react'
import { ANIMATION_TIME_OUT_DEFAULT } from 'consts'
import { CSSTransition } from 'react-transition-group'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker } from 'components/controls'
import { Space } from 'antd'
import { WithFormInstanceProps } from 'App.types'
import { isFormModeView } from 'utils'

import styles from './QuestionVariation.module.scss'
import { QuestionForm } from '../QuestionForm'
import { QuestionVariationProps } from './QuestionVariation.types'

export const QuestionVariation: React.FC<QuestionVariationProps> = React.memo(
    (props) => {
        const {
            index,
            field,
            questionsVisibility,
            toggleVisibilityParticularQuestion,
            onDeleteQuestion,
        } = props

        /**
         * Переключатель видимости содержимого спойлера
         */
        const toggleSpoilerVisible = useCallback(() => {
            const questionKey = String(field.name)

            if (questionsVisibility?.[questionKey]) {
                toggleVisibilityParticularQuestion(false, questionKey)
            } else {
                toggleVisibilityParticularQuestion(true, questionKey)
            }
        }, [
            field.name,
            questionsVisibility,
            toggleVisibilityParticularQuestion,
        ])

        /**
         * Получить заголовок для спойлера вопроса
         */
        const getQuestionTitle = useCallback(
            (form: WithFormInstanceProps['form']) => {
                const questionNumber = index + 1
                const questionTitle =
                    form?.getFieldValue(['questions', field.name, 'title']) ||
                    LOCAL.LABELS.QUESTION

                return (
                    <>
                        <span>{`${questionNumber}.`}</span>

                        {questionTitle}
                    </>
                )
            },
            [field.name, index]
        )

        useEffect(() => {
            const questionName = String(field.name)

            if (questionsVisibility?.[questionName] == null) {
                toggleVisibilityParticularQuestion(true, questionName)
            }
        }, [
            field.name,
            questionsVisibility,
            toggleVisibilityParticularQuestion,
        ])

        return (
            <>
                <CSSTransition
                    in={!questionsVisibility?.[field.name]}
                    timeout={ANIMATION_TIME_OUT_DEFAULT}
                    classNames="animation-fade-unmount"
                    unmountOnExit
                >
                    <div className={styles.spoilerHeader}>
                        <ShouldUpdateChecker
                            fieldPath={[['questions', field.name, 'title']]}
                        >
                            {(form) => (
                                <div className={styles.spoilerTitle}>
                                    {getQuestionTitle(form)}
                                </div>
                            )}
                        </ShouldUpdateChecker>

                        <ShouldUpdateChecker fieldPath="formMode">
                            {({ getFieldValue }) => {
                                const formMode = getFieldValue('formMode')

                                return (
                                    <Space className={styles.questionActions}>
                                        <IconsAdapter
                                            className={classNames(
                                                styles.spoilerToggle,
                                                {
                                                    [styles.spoilerVisible]:
                                                        questionsVisibility?.[
                                                            field.name
                                                        ],
                                                }
                                            )}
                                            iconType="CustomIconChevron"
                                            onClick={toggleSpoilerVisible}
                                        />

                                        {!isFormModeView(formMode) && (
                                            <IconsAdapter
                                                iconType="CustomIconTrashAlt"
                                                onClick={onDeleteQuestion}
                                            />
                                        )}
                                    </Space>
                                )
                            }}
                        </ShouldUpdateChecker>
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={questionsVisibility?.[field.name]}
                    timeout={ANIMATION_TIME_OUT_DEFAULT}
                    classNames="animation-fade-unmount"
                    unmountOnExit
                >
                    <div className={styles.spoilerBody}>
                        <QuestionForm
                            {...props}
                            toggleSpoilerVisible={toggleSpoilerVisible}
                        />
                    </div>
                </CSSTransition>
            </>
        )
    }
)
