import React from 'react'
import { AddonAfterDefault } from 'components/shared'
import { FormItemAdapter, ShouldUpdateChecker } from 'components/controls'
import { ProductType, TrackModalSearchContract } from 'core/api'
import { WithFormModeProps } from 'App.types'

import { PRODUCT_FORM_ITEM_BY_TYPE } from './ProductTypeControl.consts'

export const getProductFieldByType = (
    productType: ProductType,
    formMode?: WithFormModeProps['formMode'],
    initialValues?: Partial<TrackModalSearchContract>
) => {
    if (!productType) return

    const {
        name,
        label,
        children,
        link,
        permissions,
    } = PRODUCT_FORM_ITEM_BY_TYPE[productType]

    const getProps = (itemId?: number) => ({
        formMode,
        ...(link
            ? {
                  addonAfter: (
                      <AddonAfterDefault
                          url={`${link}/${itemId}`}
                          disabled={!itemId}
                          permissionsRequired={permissions}
                      />
                  ),
              }
            : {}),
        ...(initialValues
            ? {
                  tableSearchOptions: {
                      ...children.props.tableSearchOptions,
                      tableFiltersOptions: {
                          initialValues,
                      },
                  },
              }
            : {}),
    })

    return (
        <ShouldUpdateChecker fieldPath={name}>
            {({ getFieldValue }) => (
                <FormItemAdapter fieldName={name} label={label}>
                    {React.cloneElement(
                        children,
                        getProps(getFieldValue(name)?.[0].value)
                    )}
                </FormItemAdapter>
            )}
        </ShouldUpdateChecker>
    )
}
