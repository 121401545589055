import React from 'react'
import { AddUsersTableControl } from 'components/controls'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { WithFormModeProps } from 'App.types'

import styles from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.module.scss'

/** Компонент проверяющих оценку руководителем */
export const AssessmentProjectsReviewers: React.FC<WithFormModeProps> = React.memo(
    ({ formMode }) => (
        <Form.Item name={['managerParameters', 'reviewers']}>
            <AddUsersTableControl
                withSpoiler
                title={LOCAL.LABELS.REVIEWERS}
                className={styles.control}
                formMode={formMode}
            />
        </Form.Item>
    )
)
