import React from 'react'
import { FormInstance } from 'rc-field-form'
import { FormMode } from 'App.types'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker } from 'components/controls'
import { isFormModeView } from 'utils'

import {
    AccessArea,
    AdditionalMaterials,
    InformationAssistantSettings,
    OverallSettings,
    TrackStages,
} from './components/shared'
import {
    MapFormDataToRequestProps,
    TabPaneOptionsProps,
} from './TrackForm.types'
import { ResponsiveTabs } from './components/shared/ResponsiveTabs'

/**
 * Список вкладок с их настройками
 * @param form инстанс формы
 * @param formMode режим отображения формы
 * @param disabled доступность редактирования
 */
export const getTabPaneOptions = (
    form: FormInstance,
    formMode?: FormMode,
    disabled?: boolean
): TabPaneOptionsProps[] => [
    {
        tab: LOCAL.LABELS.TRACK_OPTIONS,
        key: LOCAL.LABELS.TRACK_OPTIONS,
        content: <OverallSettings formMode={formMode} disabled={disabled} />,
        visible: true,
    },
    {
        tab: LOCAL.LABELS.ACCESSES,
        key: LOCAL.LABELS.ACCESSES,
        content: <AccessArea formMode={formMode} disabled={disabled} />,
        visible: true,
    },
    {
        tab: LOCAL.LABELS.ADDITIONAL_MATERIALS,
        key: LOCAL.LABELS.ADDITIONAL_MATERIALS,
        content: (
            <AdditionalMaterials formMode={formMode} disabled={disabled} />
        ),
        visible: form.getFieldValue([
            'trackMenuVisualization',
            'isAdditionalMaterialsVisible',
        ]),
    },
    {
        tab: LOCAL.LABELS.INFORMATION_ASSISTANT,
        key: LOCAL.LABELS.INFORMATION_ASSISTANT,
        content: (
            <InformationAssistantSettings
                formMode={formMode}
                disabled={disabled}
            />
        ),
        visible: true,
    },
    {
        tab: LOCAL.LABELS.STAGES,
        key: LOCAL.LABELS.STAGES,
        content: (
            <TrackStages form={form} formMode={formMode} disabled={disabled} />
        ),
        visible: true,
    },
    {
        tab: LOCAL.LABELS.STAGES_VISUALIZATION,
        key: LOCAL.LABELS.STAGES_VISUALIZATION,
        content: (
            <ShouldUpdateChecker fieldPath={['formTempStore']}>
                {({ getFieldValue }) => {
                    const formTempStore:
                        | MapFormDataToRequestProps
                        | undefined = getFieldValue('formTempStore')

                    return (
                        <ResponsiveTabs
                            form={form}
                            formMode={formMode}
                            externalCanvases={formTempStore?.trackCanvases}
                        />
                    )
                }}
            </ShouldUpdateChecker>
        ),
        visible:
            !isFormModeView(formMode) &&
            form.getFieldValue('isTrackWithVisualisation'),
    },
]
