import cn from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Select } from 'antd'
import { getPopupContainer as getPopupContainerDefault } from 'utils/common.utils'
import { isFormModeView } from 'utils/conditions.utils'

import styles from './SelectControl.module.scss'
import { ControlViewMode } from '../ControlViewMode'
import {
    FnCreateAndFetchProps,
    SelectControlProps,
    SelectControlValuesProps,
} from './SelectControl.types'

/**
 * Декоратор для `Select` от `antd`
 */
export const SelectControl: React.FC<SelectControlProps> = React.memo(
    (props) => {
        const {
            values = [],
            dropdownClassName,
            disabled,
            value,
            onChange,
            onBeforeChange,
            addonBefore,
            addonAfter,
            addonAfterColumn,
            getPopupContainer = getPopupContainerDefault,
            formMode,
            creatingOptions,
            ...restProps
        } = props

        const [customValue, setCustomValue] = useState<
            SelectControlValuesProps
        >()

        const selectClasses = cn(
            isFormModeView(formMode) && 'view-mode',
            Boolean(addonAfterColumn) && styles.wrapperAddonAfterColumn,
            Boolean(addonAfter) && styles.wrapperAddonAfter,
            Boolean(addonBefore) && styles.wrapperAddonBefore,
            'form-control',
            styles.wrapper
        )

        const dropdownClasses = cn(dropdownClassName, styles.dropdown)

        /**
         * Обновить значение в стейте и внешней форме
         */
        const triggerChanges = useCallback(
            (value: number) => {
                onChange?.(value)
            },
            [onChange]
        )

        const customValueLabelSuffix = `(${
            creatingOptions?.suffix ?? LOCAL.NEW_VALUE
        })`

        /**
         * Функция подготовки данных для добавления в options select-компоненты
         */
        const preparedValues = useMemo(() => {
            if (!customValue) return values

            return [customValue, ...values!]
        }, [customValue, values])

        /**
         * Функция для создания элемента и запроса обновленного списка элементов
         */
        const createAndRefetch: FnCreateAndFetchProps = useCallback(
            async (value, values, onSelectChange) => {
                try {
                    setCustomValue(undefined)

                    const { id } = await creatingOptions?.create({
                        body: { name: value },
                    })
                    await creatingOptions?.refetch()

                    values.pop()
                    onSelectChange([...values, id])
                } catch (error) {
                    console.error(error)
                }
            },
            [creatingOptions]
        )

        /**
         * Обработчик изменения значения Select
         */
        const handleSelectChange = useCallback(
            (value) => {
                const lastIndex = value?.length - 1

                if (
                    customValue?.value &&
                    customValue.value === value[lastIndex]
                )
                    createAndRefetch(
                        value[lastIndex],
                        value,
                        handleSelectChange
                    )

                const startTrigger = () => {
                    triggerChanges(value)
                }

                if (onBeforeChange) {
                    onBeforeChange({
                        onChange: startTrigger,
                    })
                } else {
                    startTrigger()
                }
            },
            [onBeforeChange, triggerChanges, customValue, createAndRefetch]
        )

        /**
         * Вычисленное значение для `Select`
         */
        const calculatedValue = useMemo(() => {
            if (value === null) return undefined

            return value
        }, [value])

        const calculatedLabel = useMemo(() => {
            if (value === null) return undefined

            return values?.find((el) => el.value === value)
                ?.label as React.ReactText
        }, [value, values])

        /**
         * Обработка пойска элемента в  select-компоненте
         */
        const handleSearch = useCallback(
            (searchValue) => {
                if (!creatingOptions) return

                const foundValue = values?.find(
                    (item) => item.label === searchValue
                )

                if (searchValue === '' || foundValue)
                    return setCustomValue(undefined)

                return setCustomValue({
                    label: `${searchValue} ${customValueLabelSuffix}`,
                    value: searchValue,
                })
            },
            [values, customValueLabelSuffix, creatingOptions]
        )

        if (!props.mode && isFormModeView(formMode))
            return (
                <ControlViewMode
                    value={calculatedLabel ?? props.value}
                    addonAfter={addonAfter}
                />
            )

        return (
            <div className={selectClasses}>
                {addonBefore && (
                    <div className={styles.addonBeforeContent}>
                        {addonBefore}
                    </div>
                )}

                <Select
                    value={calculatedValue}
                    onChange={handleSelectChange}
                    onSearch={handleSearch}
                    showSearch={!creatingOptions}
                    placeholder={
                        isFormModeView(formMode)
                            ? LOCAL.PLACEHOLDERS.INPUT_EMPTY
                            : LOCAL.PLACEHOLDERS.SELECT
                    }
                    disabled={disabled || isFormModeView(formMode)}
                    {...restProps}
                    dropdownClassName={dropdownClasses}
                    showArrow
                    options={preparedValues || undefined}
                    suffixIcon={<IconsAdapter iconType="CustomIconTriangle" />}
                    getPopupContainer={getPopupContainer}
                    filterOption
                    optionFilterProp="label"
                />

                {addonAfter && (
                    <div className={styles.addonAfterContent}>{addonAfter}</div>
                )}

                {addonAfterColumn && (
                    <div className={styles.addonAfterContent}>
                        {addonAfterColumn}
                    </div>
                )}
            </div>
        )
    }
)
