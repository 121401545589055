import React from 'react'
import { Col } from 'antd'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import {
    ControlGroup,
    FormItemAdapter,
    OrganizationsFilters,
    SearchSelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import {
    DictionaryContract,
    PositionSearchContract,
    PositionsService,
    StructuralUnitContract,
    StructuralUnitsSearchContract,
    StructuralUnitsService,
} from 'core/api'
import { LOCAL } from 'core/local'
import { LabelWithSwitch } from 'components/shared/LabelWithSwitch'
import { ORGANIZATIONS_TABLE_COLUMNS } from 'components/controls/AddOrganizationControl/AddOrganizationControl.consts'
import { POSITIONS_TABLE_COLUMNS } from 'components/controls/AddPositionControl/AddPositionControl.consts'
import { PositionsFilters } from 'components/controls/AddPositionControl/components'
import { STRUCTURAL_UNIT_SEARCH_BODY } from 'consts'
import { checkboxRender } from 'components/controls/AddOrganizationControl/AddOrganizationControl.utils'
import { convertToShouldUpdateDep } from 'utils'
import { getRowClassNameWithoutChild } from 'components/shared'
import { isEmpty } from 'lodash'

import {
    ORGANIZATIONS_DEPENDENCIES,
    POSITIONS_DEPENDENCIES,
} from './AccessArea.consts'

/** Компонент области "Доступ и назначение" для формы трека */
export const AccessArea: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => (
    <ControlGroup title={LOCAL.LABELS.ACCESS_TO_TRACK}>
        <Col xs={8}>
            <ShouldUpdateChecker
                fieldPath={convertToShouldUpdateDep(POSITIONS_DEPENDENCIES)}
            >
                {({ getFieldsValue }) => {
                    const { positionIds, forAllPositions } = getFieldsValue(
                        POSITIONS_DEPENDENCIES
                    )

                    const positionsLabel = (
                        <LabelWithSwitch
                            label={LOCAL.LABELS.POSITION}
                            fieldName="forAllPositions"
                            addonAfter={
                                LOCAL.LABELS.AVAILABLE_FOR_ALL_POSITIONS
                            }
                            formMode={formMode}
                            disabled={!isEmpty(positionIds) || disabled}
                        />
                    )

                    return (
                        <FormItemAdapter
                            fieldName="positionIds"
                            label={positionsLabel}
                            required
                        >
                            <SearchSelectControl<
                                PositionSearchContract,
                                DictionaryContract
                            >
                                getByIdsMethod={PositionsService.getByIds}
                                selectMode="multiple"
                                formMode={formMode}
                                disabled={disabled || forAllPositions}
                                modalTitle={LOCAL.LABELS.POSITION_SELECTION}
                                tableSearchOptions={{
                                    searchMethod: PositionsService.search,
                                    tableColumns: POSITIONS_TABLE_COLUMNS,
                                    filterComponent: PositionsFilters,
                                }}
                            />
                        </FormItemAdapter>
                    )
                }}
            </ShouldUpdateChecker>
        </Col>

        <Col xs={8}>
            <ShouldUpdateChecker
                fieldPath={convertToShouldUpdateDep(ORGANIZATIONS_DEPENDENCIES)}
            >
                {({ getFieldsValue }) => {
                    const {
                        organizationIds,
                        forAllOrganizations,
                    } = getFieldsValue(ORGANIZATIONS_DEPENDENCIES)

                    const organizationsLabel = (
                        <LabelWithSwitch
                            label={LOCAL.LABELS.ORGANIZATIONS}
                            fieldName="forAllOrganizations"
                            addonAfter={
                                LOCAL.LABELS.AVAILABLE_FOR_ALL_ORGANIZATIONS
                            }
                            formMode={formMode}
                            disabled={!isEmpty(organizationIds) || disabled}
                        />
                    )

                    return (
                        <FormItemAdapter
                            fieldName="organizationIds"
                            label={organizationsLabel}
                            required
                        >
                            <SearchSelectControl<
                                StructuralUnitsSearchContract,
                                StructuralUnitContract
                            >
                                getByIdsMethod={StructuralUnitsService.getByIds}
                                selectMode="multiple"
                                formMode={formMode}
                                disabled={disabled || forAllOrganizations}
                                modalTitle={LOCAL.LABELS.ORGANIZATION_SELECTION}
                                tableSearchOptions={{
                                    searchMethod: StructuralUnitsService.search,
                                    tableColumns: ORGANIZATIONS_TABLE_COLUMNS,
                                    tableFiltersOptions: {
                                        initialValues: STRUCTURAL_UNIT_SEARCH_BODY,
                                    },
                                    childrenColumnName: 'units',
                                    rowClassName: getRowClassNameWithoutChild,
                                    renderSelectCell: checkboxRender,
                                    filterComponent: OrganizationsFilters,
                                    isExpandRowBySearch: true,
                                }}
                            />
                        </FormItemAdapter>
                    )
                }}
            </ShouldUpdateChecker>
        </Col>
    </ControlGroup>
))
