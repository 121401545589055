import React from 'react'
import { AppointmentAssessmentExpertListContract } from 'core/api'
import { Button } from 'components/shared/Button'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { isAppointmentStatusChecked, isAppointmentStatusEnded } from 'utils'

/**
 * Рендер кнопок управления для записи таблицы
 */
export const renderGroupExpertListActions = (
    row: AppointmentAssessmentExpertListContract
) => (
    <>
        {isAppointmentStatusEnded(row.status) && (
            <Button>{LOCAL.ACTIONS.VIEW}</Button>
        )}

        {isAppointmentStatusChecked(row.status) && (
            <Button
                href={`${ROUTE_NAMES.ASSESSMENT_ASSIGNED_EVALUATION}/${row.id}`}
            >
                {LOCAL.ACTIONS.CHECK}
            </Button>
        )}
    </>
)
