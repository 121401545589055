import { QuestionPublicContract, QuestionType } from 'core/api'

import { CriteriaScale } from '../CriteriaScale'
import { TextQuestion } from '../TextQuestion'

/**
 * Получаем name для вопроса
 * @param question - контракт вопроса
 */
export const getQuestionName = ({ id, type }: QuestionPublicContract) => [
    String(id),
    type,
]

/**
 * Получаем соответствующий компонент для вопроса
 * @param type - тип вопроса
 */
export const getQuestionComponent = (type: QuestionType) =>
    ({
        CriteriaScale,
        TextQuestion,
        // Для оценки следующие типы вопроса не реализуются
        ChooseVariant: undefined,
        FillEmptyText: undefined,
    }[type])
