import React, { useCallback, useEffect } from 'react'
import {
    AssessmentPortfolioDictionariesService,
    DocumentStatus,
    QuestionnaireDictionariesService,
    QuestionnaireSelectSearchContract,
    QuestionnaireType,
    QuestionnairesService,
    TestPortfolioContract,
    TestPortfoliosService,
} from 'core/api'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import { ORDER_RULES_WITH_CREATION_DATE } from 'components/layouts'
import { PRESENTATION_FORM_FIELD_NAME } from 'components/shared/PortfolioFormCommonTabs/PortfolioPresentation/PortfolioPresentation.consts'
import { PortfolioFormCommonTabs } from 'components/shared'
import { PortfolioFormCommonTabsContextProvider } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import { QuestionDisplayTypes } from 'components/shared/PortfolioFormCommonTabs/PortfolioPresentation/PortfolioPresentation.types'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WithLoaderProps } from 'HOCs'
import {
    getInitialValuesForCopy,
    getValue,
    isDocumentStatusInWork,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { useDictionaries, usePublish } from 'hooks'

import {
    BORDER_NUMBER_OF_QUESTIONNAIRES,
    FORM_INITIAL_VALUES,
    questionnaireDependencies,
} from './TestPortfolioForm.consts'
import {
    DictionariesProps,
    TestPortfolioFormSubmitValues,
} from './TestPortfolioForm.types'
import { TestPortfolioCommonSettings } from '../TestPortfolioCommonSettings'
import {
    getTimeLimit,
    mapFormDataToRequest,
    mapResponseToFormData,
} from './TestPortfolioForm.utils'

/**
 * Форма создания портфеля теста
 */
export const TestPortfolioForm: React.FC<
    FormProps<TestPortfolioContract> & Partial<WithLoaderProps>
> = React.memo(
    ({
        onBlockUserRouting,
        isSaveDocument,
        initialValuesForEdit,
        formMode,
        updateLoader,
    }) => {
        const [form] = Form.useForm()
        const { createEntityFn } = usePublish({
            updateLoader,
            formMode,
            mapFormDataToRequest,
            initialValuesForEdit,
            saveFn: TestPortfoliosService.save,
            publishFn: TestPortfoliosService.publish,
            redirectRoute: ROUTE_NAMES.TEST_PORTFOLIOS,
            saveMessage: LOCAL.MESSAGES.TEST_PORTFOLIO_SAVE_SUCCESS_MESSAGE,
            publishMessage:
                LOCAL.MESSAGES.TEST_PORTFOLIO_PUBLISH_SUCCESS_MESSAGE,
            onBlockUserRouting,
            isSaveDocument,
            form,
            formInitialValues: FORM_INITIAL_VALUES,
        })

        const {
            dictionaries,
            handleFetchDictionaries,
            handleChangeDictionaries,
        } = useDictionaries<DictionariesProps>({ updateLoader })

        /**
         * Отправка формы
         */
        const handleFinish = useCallback(
            async (values: Partial<TestPortfolioFormSubmitValues>) => {
                await createEntityFn(values)
            },
            [createEntityFn]
        )

        /**
         * Запрос опросных листов
         */
        const questionnairesFetch = useCallback(async () => {
            try {
                const formValues: Partial<TestPortfolioFormSubmitValues> = form.getFieldsValue(
                    questionnaireDependencies
                )

                if (!formValues) return

                const positionIds = formValues?.positionIds?.map(getValue)
                const competenceIds = formValues?.competenceIds?.map(getValue)

                if (
                    positionIds?.length ||
                    competenceIds?.length ||
                    formValues?.forAllPositions ||
                    formValues?.forAllOrganizations
                ) {
                    handleChangeDictionaries({
                        questionnaires: await QuestionnairesService.getForSelect(
                            {
                                body: {
                                    questionnaireType: QuestionnaireType.Test,
                                    positionIds,
                                    competenceIds,
                                    excludeStatuses: [DocumentStatus.Draft],
                                    testTypeId: formValues?.testTypeId,
                                    orderRules: ORDER_RULES_WITH_CREATION_DATE,
                                    forAllPositions:
                                        formValues?.forAllPositions,
                                    forAllOrganizations:
                                        formValues?.forAllOrganizations,
                                } as QuestionnaireSelectSearchContract,
                            }
                        ),
                    })

                    return
                }

                handleChangeDictionaries({
                    questionnaires: undefined,
                })
            } catch (error) {
                console.error(error)
            }
        }, [form, handleChangeDictionaries])

        /**
         * Обработчик изменения полей формы
         */
        const handleValuesChange = useCallback(
            (changedValue, allValues) => {
                const changedValueKey = Object.keys(changedValue)[0]

                if (questionnaireDependencies.includes(changedValueKey)) {
                    form.setFieldsValue({ questionnaires: undefined })
                    questionnairesFetch()
                }

                if (changedValueKey === 'questionnaires') {
                    const timeLimit = getTimeLimit(
                        changedValue[changedValueKey]
                    )

                    if (changedValue[changedValueKey].length && timeLimit) {
                        form.setFieldsValue({
                            timeLimit,
                        })
                    } else if (allValues.timeLimit !== undefined) {
                        form.setFieldsValue({ timeLimit: undefined })
                    }
                }

                if (changedValueKey === 'questionnaires') {
                    const questionnairesLength =
                        changedValue[changedValueKey]?.length || 0

                    if (questionnairesLength > BORDER_NUMBER_OF_QUESTIONNAIRES)
                        return

                    form.setFields([
                        {
                            name: [
                                PRESENTATION_FORM_FIELD_NAME,
                                'showQuestionsByBlock',
                            ],
                            value: QuestionDisplayTypes.ByOne,
                        },
                    ])
                }

                if (changedValueKey === 'timeLimit') {
                    const timeLimit = getTimeLimit(allValues.questionnaires)
                    form.setFieldsValue({
                        timeLimit:
                            timeLimit &&
                            changedValue[changedValueKey] < timeLimit
                                ? timeLimit
                                : changedValue[changedValueKey],
                    })
                }

                if (form.isFieldsTouched() && !isFormModeView(formMode))
                    onBlockUserRouting?.()
            },
            [form, formMode, onBlockUserRouting, questionnairesFetch]
        )

        useEffect(() => {
            handleFetchDictionaries({
                testTypes: QuestionnaireDictionariesService.getTestTypes(),
                testPurposes: AssessmentPortfolioDictionariesService.getAll(),
            })
        }, [handleFetchDictionaries])

        useEffect(() => {
            if (initialValuesForEdit?.id) {
                form.setFieldsValue(
                    mapResponseToFormData(
                        getInitialValuesForCopy(initialValuesForEdit, formMode)
                    )
                )
                questionnairesFetch()
            }
        }, [form, formMode, initialValuesForEdit, questionnairesFetch])

        if (
            (isFormModeEdit(formMode) || isFormModeView(formMode)) &&
            !initialValuesForEdit?.id
        )
            return null

        return (
            <Form
                id={FORM_IDS.TEST_PORTFOLIO_FORM}
                form={form}
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                autoComplete="off"
                initialValues={FORM_INITIAL_VALUES}
            >
                <PortfolioFormCommonTabsContextProvider
                    form={form}
                    initialValuesForEdit={initialValuesForEdit}
                    formMode={formMode}
                    portfolioFormType={QuestionnaireType.Test}
                    disabledByStatus={
                        isFormModeEdit(formMode) &&
                        isDocumentStatusInWork(initialValuesForEdit?.status)
                    }
                >
                    <PortfolioFormCommonTabs>
                        <TestPortfolioCommonSettings
                            dictionaries={dictionaries}
                        />
                    </PortfolioFormCommonTabs>
                </PortfolioFormCommonTabsContextProvider>
            </Form>
        )
    }
)
