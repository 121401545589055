import { AssessmentPortfolioForModalContract } from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'

export const ASSESSMENT_PORTFOLIOS_MODAL_TABLE_COLUMNS: ColumnsType<AssessmentPortfolioForModalContract> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
    },
]
