import set from 'lodash/set'
import React, { useCallback, useState } from 'react'
import { FnActionRequiredProps } from 'App.types'
import { NamePath } from 'antd/lib/form/interface'
import { NotImplementedError } from 'core/helpers/NotImplementedError'
import { cloneDeepViaJson } from 'utils/common.utils'
import { isNil } from 'lodash'

interface UpdateParticularProps {
    namePath?: NamePath
    value?: any
}

export interface StoreContextProps<T> {
    store: T
    updateStore: FnActionRequiredProps<T>
    updateParticular: FnActionRequiredProps<UpdateParticularProps[]>
}

export function createStoreContext<T extends object>(): [
    React.Context<StoreContextProps<T>>,
    React.FC<T>
] {
    const StoreContext = React.createContext<StoreContextProps<T>>({
        store: {} as T,
        updateStore: () => {
            throw new NotImplementedError()
        },
        updateParticular: () => {
            throw new NotImplementedError()
        },
    })

    const StoreContextProvider: React.FC<T> = ({ children, ...rest }) => {
        const [store, setStore] = useState<T>(rest as T)

        const updateStore = useCallback((newStore: T) => {
            setStore((prevStore) => ({ ...prevStore, ...newStore }))
        }, [])

        const updateParticular = useCallback(
            (namePaths: UpdateParticularProps[]) => {
                const paths = namePaths.filter(
                    ({ namePath, value }) => !isNil(namePath) && !isNil(value)
                ) as Required<UpdateParticularProps>[]

                if (!paths.length) return

                setStore((prevStore) => {
                    const newStore = cloneDeepViaJson(prevStore)

                    paths.forEach(({ namePath, value }) => {
                        set(newStore, namePath, value)
                    })

                    return newStore
                })
            },
            []
        )

        return (
            <StoreContext.Provider
                value={{ store, updateStore, updateParticular }}
            >
                {children}
            </StoreContext.Provider>
        )
    }

    return [StoreContext, StoreContextProvider]
}
