import React from 'react'
import {
    ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS,
    ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS,
} from 'consts'
import { AssessmentProjectSearchResultContract, UserPermission } from 'core/api'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    isAppointmentStatusDraft,
    isAppointmentStatusExpectation,
    isAppointmentStatusInProgress,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

export const AssessmentProjectsTableActions: React.FC<AssessmentProjectSearchResultContract> = React.memo(
    ({ status, id, active }) => {
        const canBeChanged =
            (isAppointmentStatusInProgress(status) ||
                isAppointmentStatusDraft(status) ||
                isAppointmentStatusExpectation(status)) &&
            active

        return (
            <ButtonsToolbar bottomIndent="0">
                <TableActions.View
                    requiredPermissions={
                        ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS
                    }
                    conditionalPermissions={
                        ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS
                    }
                    href={`${ROUTE_NAMES.ASSESSMENT_PROJECTS_VIEW}/${id}`}
                    disabled={!active}
                />

                {active && (
                    <>
                        <HasPermissions
                            requiredPermissions={[
                                ...ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS,
                                UserPermission.AssessmentProjectMonitoring,
                            ]}
                            conditionalPermissions={
                                ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS
                            }
                            accessDeniedRender={renderAccessDeniedButton({
                                type: 'link',
                                icon: (
                                    <IconsAdapter iconType="InfoCircleOutlined" />
                                ),
                            })}
                        >
                            <Button
                                type="link"
                                icon={
                                    <IconsAdapter iconType="InfoCircleOutlined" />
                                }
                                title={LOCAL.LABELS.PROGRESS}
                            />
                        </HasPermissions>

                        <TableActions.Edit
                            requiredPermissions={[
                                ...ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS,
                                UserPermission.AssessmentProjectSave,
                            ]}
                            conditionalPermissions={
                                ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS
                            }
                            href={`${ROUTE_NAMES.ASSESSMENT_PROJECTS_EDIT}/${id}`}
                            disabled={!canBeChanged}
                        />

                        {canBeChanged && (
                            <HasPermissions
                                requiredPermissions={[
                                    ...ASSESSMENT_PROJECTS_REQUIRED_PERMISSIONS,
                                    UserPermission.AssessmentProjectDelete,
                                ]}
                                conditionalPermissions={
                                    ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS
                                }
                                accessDeniedRender={renderAccessDeniedButton({
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="DeleteOutlined" />
                                    ),
                                })}
                            >
                                <PopupWithForm
                                    formId={FORM_IDS.CONFIRM_DELETE_FORM}
                                    buttonText=""
                                    havePopup={false}
                                    buttonOption={{
                                        type: 'link',
                                        icon: (
                                            <IconsAdapter iconType="DeleteOutlined" />
                                        ),
                                        title: LOCAL.ACTIONS.DELETE,
                                    }}
                                    formOptions={{
                                        initialValues: { id },
                                    }}
                                    modalOptions={{
                                        title:
                                            LOCAL.MESSAGES
                                                .CONFIRM_TITLE_DEFAULT,
                                        okText: LOCAL.YES,
                                    }}
                                />
                            </HasPermissions>
                        )}
                    </>
                )}
            </ButtonsToolbar>
        )
    }
)
