import cn from 'classnames'
import React, { ReactNode } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import { HomeworkStatusType } from 'core/api'
import { LOCAL } from 'core/local'
import {
    TOOLBAR_CONTENT_SETTINGS,
    ToolbarContentRenderChildrenProps,
} from 'components/layouts'

import styles from './CheckHomework.module.scss'
import { RenderToolbarContentProps } from './CheckHomework.types'

/**
 * Получить мета информацию для рендера статуса
 * @param row ряд таблицы
 * @returns {[string, string]} наименование статуса, модификатор стиля статуса
 */
export const getStatusMeta = (status?: HomeworkStatusType) => {
    if (!status) return {}

    switch (status) {
        case HomeworkStatusType.WaitingForSolution:
            return {
                title: LOCAL.STATUSES.AWAIT_DECISION,
                styleModification:
                    styles[`status${HomeworkStatusType.WaitingForSolution}`],
            }
        case HomeworkStatusType.OnChecking:
            return {
                title: LOCAL.STATUSES.ON_CHECK,
                styleModification:
                    styles[`status${HomeworkStatusType.OnChecking}`],
            }
        case HomeworkStatusType.Revision:
            return {
                title: LOCAL.STATUSES.CREDIT_LOST,
                styleModification:
                    styles[`status${HomeworkStatusType.Revision}`],
            }
        default:
            return {
                title: LOCAL.STATUSES.CREDIT_RECEIVED,
                styleModification: styles[`status${HomeworkStatusType.Passed}`],
            }
    }
}

/**
 * Рендер статуса домашнего задания
 * @param status статус домашнег задания
 * @returns {React.JSX}
 */
export const renderHomeworkStatus = (
    status?: HomeworkStatusType,
    appendBefore?: ReactNode
) => {
    const { title, styleModification } = getStatusMeta(status)

    return (
        <span className={cn(styleModification)}>
            {appendBefore}

            {title}
        </span>
    )
}

export const renderToolbarContent = ({
    gutterResponsive,
    handleOpenHomework,
}: RenderToolbarContentProps) => ({
    titleComponent,
    quickSearchComponent,
    filterSwitch,
}: ToolbarContentRenderChildrenProps) => (
    <Row {...TOOLBAR_CONTENT_SETTINGS.PROPS} className="pt-20 pb-20">
        <Col>{titleComponent}</Col>

        <Col>
            <Row gutter={gutterResponsive} align="middle">
                <Col>
                    <Row gutter={gutterResponsive} align="middle">
                        <Col>{quickSearchComponent}</Col>

                        <Col className={styles.toolbarFilters}>
                            {filterSwitch}
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <Button onClick={handleOpenHomework}>
                        {LOCAL.ACTIONS.TAKE_TO_WORK}
                    </Button>
                </Col>
            </Row>
        </Col>
    </Row>
)
