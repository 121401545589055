import React from 'react'
import { Form } from 'antd'
import { ShouldUpdateChecker } from 'components/controls'

import { AssessmentProjectPortfolio } from '../AssessmentProjectPortfolio'
import { AssessmentProjectsPortfoliosListProps } from './AssessmentProjectsPortfoliosList.types'

/** Список добавленных портфелей оценивания */
export const AssessmentProjectsPortfoliosList: React.FC<AssessmentProjectsPortfoliosListProps> = React.memo(
    ({ formMode }) => (
        <Form.List name="assessmentPortfolios">
            {(fields, { remove }) => (
                <ShouldUpdateChecker fieldPath={['assessmentPortfolios']}>
                    {({ getFieldValue }) => {
                        const portfolios = getFieldValue('assessmentPortfolios')

                        return fields?.map((field, index) => (
                            <AssessmentProjectPortfolio
                                portfolio={portfolios[index]}
                                key={field.key}
                                formMode={formMode}
                                field={field}
                                remove={remove}
                            />
                        ))
                    }}
                </ShouldUpdateChecker>
            )}
        </Form.List>
    )
)
