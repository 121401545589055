export enum AnnotationType {
    box = 'box',
    line = 'line',
}

export enum DrawTimeType {
    AfterDraw = 'afterDraw',
    AfterDatasetsDraw = 'afterDatasetsDraw',
    BeforeDraw = 'beforeDraw',
    BeforeDatasetsDraw = 'beforeDatasetsDraw',
}
