import React from 'react'
import cn from 'classnames'
import { InputNumber } from 'antd'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import styles from './InputNumberControl.module.scss'
import { InputNumberControlProps } from './InputControl.types'

/**
 * Декоратор для `InputNumber` от `antd`, принимает все теже `props`
 */
export const InputNumberControl: React.FC<InputNumberControlProps> = React.memo(
    ({
        addonAfter,
        addonBefore,
        disabled,
        fullWidth,
        withMargin,
        justifyContent,
        formMode,
        min = 0,
        form,
        className,
        ...restProps
    }) => {
        const classes = cn(
            styles.wrapper,
            withMargin && styles.wrapperWithMargin,
            fullWidth && styles.wrapperFullWidth,
            (Boolean(addonAfter) || Boolean(addonBefore)) &&
                styles.wrapperAddonContent,
            isFormModeView(formMode) && 'view-mode',
            'form-control',
            className
        )

        return (
            <div className={classes} style={{ justifyContent }}>
                {addonBefore && (
                    <span className={styles.addonBefore}>{addonBefore}</span>
                )}

                <div>
                    <InputNumber
                        placeholder={LOCAL.PLACEHOLDERS.INPUT_NUMBER}
                        disabled={disabled || isFormModeView(formMode)}
                        type="number"
                        min={min}
                        {...restProps}
                    />
                </div>

                {addonAfter && (
                    <span className={styles.addonAfter}>{addonAfter}</span>
                )}
            </div>
        )
    }
)
