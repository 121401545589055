import React, { FC, useCallback, useEffect } from 'react'
import {
    COL_xl8_lg12_xs24,
    ROW_GUTTER,
    TREE_SELECT_NORMALIZE_TYPE,
} from 'consts'
import { Col, Form, Row } from 'antd'
import {
    CompetenceCreateContract,
    CompetenceGroupsService,
    CompetenciesService,
    PositionProfileDictionariesService,
    StructuralUnitType,
    StructuralUnitsService,
} from 'core/api'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    ShouldUpdateChecker,
    TextAreaControl,
    TreeSelectControl,
} from 'components/controls'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { createConfirmPopup, getFormPopupTitle, isFormModeView } from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useDictionaries } from 'hooks'
import { useHistory } from 'react-router-dom'

import styles from './CompetenceForm.module.scss'
import {
    CompetenceFormProps,
    DictionariesStateProps,
} from './CompetenceForm.types'
import { IndicatorsControl } from './components'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
} from './CompetenceForm.utils'

/**
 * Форма добавления/редактирования компетенции
 */
export const CompetenceForm: FC<CompetenceFormProps> = React.memo(
    ({
        initialValuesForEdit,
        isSaveDocument,
        onBlockUserRouting,
        reFetchInitialFormValues,
        formMode,
        updateLoader,
    }) => {
        const { dictionaries, handleFetchDictionaries } = useDictionaries<
            DictionariesStateProps
        >({ updateLoader })
        const history = useHistory()
        const [form] = Form.useForm()

        /**
         * Обработчик отправки формы
         * @param values - значения полей формы
         */
        const handleFinish = useCallback(
            async (values: Partial<CompetenceCreateContract>) => {
                try {
                    updateLoader?.(true)

                    const body = {
                        id: initialValuesForEdit?.id,
                        ...mapFormDataToRequest(values),
                    } as CompetenceCreateContract

                    if (isSaveDocument) {
                        await CompetenciesService.save({ body })
                    } else {
                        await CompetenciesService.publish({ body })
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(ROUTE_NAMES.COMPETENCIES)
                        ),
                        content: isSaveDocument
                            ? LOCAL.MESSAGES.COMPETENCE_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES.COMPETENCE_PUBLISH_SUCCESS_MESSAGE,
                        onOk: () => {
                            history.push(ROUTE_NAMES.COMPETENCIES)
                        },
                        onCancel: () => {
                            if (initialValuesForEdit?.id)
                                reFetchInitialFormValues?.(
                                    initialValuesForEdit?.id
                                )
                            else {
                                form.resetFields()
                            }
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                form,
                updateLoader,
                isSaveDocument,
                history,
                reFetchInitialFormValues,
                initialValuesForEdit,
                onBlockUserRouting,
            ]
        )

        /** Обработчик изменения формы */
        const handleValuesChange = useCallback(() => {
            if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                onBlockUserRouting?.()
            }
        }, [form, formMode, onBlockUserRouting])

        useEffect(() => {
            handleFetchDictionaries({
                competenceGroups: CompetenceGroupsService.getForModal(),
                functionClasses: PositionProfileDictionariesService.getAllFunctionClasses(),
                organizations: [
                    StructuralUnitsService.getStructuralUnitsByTypes({
                        body: [
                            StructuralUnitType.Oiv,
                            StructuralUnitType.Organization,
                        ],
                    }),
                    TREE_SELECT_NORMALIZE_TYPE,
                ],
            })
        }, [handleFetchDictionaries])

        /** Установка начальных значений полей формы из данных полученных с сервера, если они имеются */
        useEffect(() => {
            if (!initialValuesForEdit?.id) {
                return
            }

            form.setFieldsValue(mapResponseToFormData(initialValuesForEdit))
        }, [form, initialValuesForEdit])

        return (
            <Form
                id={FORM_IDS.COMPETENCE_FORM}
                form={form}
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                            required
                        >
                            <InputControl formMode={formMode} />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="competenceGroupId"
                            label={LOCAL.LABELS.COMPETENCIES_GROUP}
                            required
                        >
                            <SelectControl
                                values={dictionaries?.competenceGroups || []}
                                formMode={formMode}
                            />
                        </FormItemAdapter>

                        <ShouldUpdateChecker fieldPath={['functionClassIds']}>
                            {(form) => (
                                <FormItemAdapter
                                    fieldName="departmentId"
                                    label={LOCAL.LABELS.ORGANIZATION}
                                >
                                    <TreeSelectControl
                                        treeData={dictionaries?.organizations}
                                        showSearch
                                        placeholder={
                                            LOCAL.PLACEHOLDERS.SEARCH_SELECT
                                        }
                                        disabled={
                                            !!form.getFieldValue(
                                                'functionClassIds'
                                            )?.length ||
                                            isFormModeView(formMode)
                                        }
                                        allowClear
                                    />
                                </FormItemAdapter>
                            )}
                        </ShouldUpdateChecker>

                        <ShouldUpdateChecker fieldPath={['departmentId']}>
                            {(form) => (
                                <FormItemAdapter
                                    fieldName="functionClassIds"
                                    label={LOCAL.LABELS.ACTIVITY_TYPE}
                                >
                                    <SelectControl
                                        values={
                                            dictionaries?.functionClasses || []
                                        }
                                        mode="multiple"
                                        disabled={
                                            !!form.getFieldValue('departmentId')
                                        }
                                        formMode={formMode}
                                        allowClear
                                    />
                                </FormItemAdapter>
                            )}
                        </ShouldUpdateChecker>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="description"
                            label={LOCAL.LABELS.DESCRIPTION}
                        >
                            <TextAreaControl
                                autoSize={{ minRows: 3.65 }}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <ControlGroup className={styles.indicators}>
                            <Form.Item name="indicators" noStyle>
                                <IndicatorsControl formMode={formMode} />
                            </Form.Item>
                        </ControlGroup>
                    </Col>
                </Row>
            </Form>
        )
    }
)
