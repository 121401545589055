import {
    AdaptationTrackContract,
    AdaptationTrackStageContract,
    AdaptationTrackStageCreateContract,
    AdditionalsAvailability,
    AdditionalsType,
    DevelopmentTrackContract,
    DevelopmentTrackStageContract,
    TrackAdditionalContract,
    TrackAdditionalsCreateContract,
    TrackCanvasContract,
    TrackPassingContract,
} from 'core/api'
import { LOCAL } from 'core/local'
import {
    LOCAL_STORAGE_KEYS,
    LocalStorageHelper,
} from 'core/helpers/LocalStorage'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Resolutions } from 'core/configs'
import { Store, WithFormInstanceProps } from 'App.types'
import {
    getNotFalsyArray,
    getValue,
    isAdditionalAvailabilityAlways,
    isObjectEmpty,
    isObjectEmptyDeep,
    mapPictureContractToFormData,
    normalizeDataForSelectAndRadio,
} from 'utils'

import {
    FormDataAdditionalsProps,
    FormDataDocumentsProps,
    FormDataStagesStylesIconsProps,
    FormDataStagesStylesProps,
    IconStatusProps,
    MapFormDataToRequestProps,
    MapFormDataToRequestTrackCanvasProps,
    StageOptionsProps,
    StagesStylesProps,
    UpdateStateOfPreviewBtnProps,
    VideoFilesFormContract,
} from './TrackForm.types'
import {
    MAX_PULSE_TIME,
    MIN_PULSE_TIME,
    TRACK_STAGE_ICON_SIZE_DEFAULT,
    TRACK_STAGE_STATUSES,
} from './components/shared/TrackStageDnD/TrackStageDnD.consts'

/**
 * Маппинг для визуализации трека
 * @param trackCanvases - объект данных по визуализации трека
 */
export const mapTrackVisualisation = (trackCanvases?: TrackCanvasContract[]) =>
    trackCanvases?.reduce<Store<MapFormDataToRequestTrackCanvasProps>>(
        (acc, canvas) => ({
            ...acc,
            [canvas.screenResolution]: {
                ...canvas,
                meta: {
                    currentBackgroundUrl: canvas.trackCanvasBackground?.url,
                },
                trackCanvasBackgroundId: canvas.trackCanvasBackground?.id,
                trackStageStyles: canvas.trackStageStyles?.map(
                    ({ icons, ...restStyles }) => ({
                        ...restStyles,
                        icons: {
                            ...icons,
                            beforeStart: {
                                ...icons.beforeStart,
                                meta: {
                                    currentIconUrl: icons.beforeStart?.url,
                                },
                                iconBackgroundId:
                                    icons.beforeStart?.iconBackground?.id,
                            },
                            inProcess: {
                                ...icons.inProcess,
                                meta: {
                                    currentIconUrl: icons.inProcess?.url,
                                },
                                iconBackgroundId:
                                    icons.inProcess?.iconBackground?.id,
                            },
                            passing: {
                                ...icons.passing,
                                meta: {
                                    currentIconUrl: icons.passing?.url,
                                },
                                iconBackgroundId:
                                    icons.passing?.iconBackground?.id,
                            },
                        },
                    })
                ),
            },
        }),
        {}
    )

/** нормализация данных, начальных значений формы (при редактировании, просмотре), для `value` и внутреннего стейта*/
export const normalizeInitialDataForVideoValue = (
    data: TrackAdditionalContract[]
): VideoFilesFormContract[] | undefined =>
    data?.map((el) => ({
        id: el.course.id,
        additionalMaterialId: el.id,
        name: el.name,
        structuralUnit: el.structuralUnit && [
            normalizeDataForSelectAndRadio(el.structuralUnit),
        ],
        launchUrl: el.course.launchUrl,
    }))

/** нормализация данных, начальных значений формы (при редактировании, просмотре), для `value` и внутреннего стейта*/
const normalizeInitialDataForDocumentValue = (
    data: TrackAdditionalContract[]
): FormDataDocumentsProps[] | undefined =>
    data?.map((el) => ({
        id: el.course.id,
        additionalMaterialId: el.id,
        name: el.name,
        format: el.course.format,
        availability: isAdditionalAvailabilityAlways(el.availability),
        launchUrl: el.course.launchUrl,
    }))

/** Маппинг дополнительных материалов для формы трека
 * @param additionals дополнительные материалы трека адаптации
 */
const mapAdditionalsToFormData = (additionals?: TrackAdditionalContract[]) => {
    const video: TrackAdditionalContract[] = [],
        documents: TrackAdditionalContract[] = []

    additionals?.forEach((element) => {
        if (element.additionalsType === AdditionalsType.Document) {
            documents.push(element)
        } else {
            video.push(element)
        }
    })

    const documentMapped = normalizeInitialDataForDocumentValue(documents)
    const videoMapped = normalizeInitialDataForVideoValue(video)

    return {
        video: videoMapped,
        documents: documentMapped,
    }
}

/** Маппинг этапов трека для формы трека
 * @param stages - этапы трека адаптации
 */
const mapStagesToFormData = (
    stages?: (AdaptationTrackStageContract | DevelopmentTrackStageContract)[]
) =>
    stages?.map(
        ({
            activity,
            course,
            contentIcon,
            assistantEvents,
            isActivityAttemptCountLimited,
            ...restValues
        }) => ({
            ...restValues,
            isActivityAttemptCountLimited: !isActivityAttemptCountLimited,
            activityId: activity
                ? [
                      {
                          label: activity?.name,
                          key: String(activity?.id),
                          value: activity?.id,
                      },
                  ]
                : null,
            courseId: course
                ? [
                      {
                          label: course?.name,
                          key: String(course?.id),
                          value: course?.id,
                      },
                  ]
                : null,
            assistantEvents: assistantEvents.length
                ? assistantEvents
                : [undefined],
            contentIcon: [
                { response: { url: contentIcon?.url, id: contentIcon?.id } },
            ],
        })
    )

/**
 * Функция генерации изначальных значений для формы трека
 * @param values объект данных по треку
 * @param isCopy если true, значит осуществляется создание копии из текущей версии
 */
export const mapResponseToFormData = (
    {
        informationAssistant,
        questionnaire,
        trackBackgroundPicture,
        trackYourDevelopmentDisplay,
        ...trackData
    }: Partial<AdaptationTrackContract> | Partial<DevelopmentTrackContract>,
    isCopy: boolean
) => ({
    ...trackData,
    questionnaire: questionnaire
        ? [normalizeDataForSelectAndRadio(questionnaire)]
        : undefined,
    status: !isCopy ? trackData.status : undefined,
    name: isCopy ? `${trackData.name} ${LOCAL.LABELS.COPY}` : trackData.name,
    additionals: mapAdditionalsToFormData(trackData.additionals),
    informationAssistant: {
        events: informationAssistant?.events?.length
            ? informationAssistant?.events
            : [undefined],
        illustrationId: informationAssistant?.illustration?.id,
        name: informationAssistant?.name,
    },
    organizationIds: trackData.organizations?.map(
        normalizeDataForSelectAndRadio
    ),
    positionIds: trackData.positions?.map(normalizeDataForSelectAndRadio),
    stages: mapStagesToFormData(trackData.stages),
    trackCanvases: mapTrackVisualisation(trackData.trackCanvases),
    isTrackWithLogo: !!trackData.trackHeaderIcons?.length,
    trackBackgroundPictureId: mapPictureContractToFormData(
        trackBackgroundPicture
    ),
    trackYourDevelopmentDisplayId: mapPictureContractToFormData(
        trackYourDevelopmentDisplay
    ),
})

export const mapAdaptationTrackResponseToFormData = (
    values: Partial<AdaptationTrackContract>,
    isCopy: boolean
) =>
    (({
        ...mapResponseToFormData(values, isCopy),
        automaticAppointmentPositions: values.automaticAppointmentPositions?.map(
            normalizeDataForSelectAndRadio
        ),
    } as unknown) as MapFormDataToRequestProps)

export const mapDevelopmentTrackResponseToFormData = (
    values: Partial<DevelopmentTrackContract>,
    isCopy: boolean
) =>
    (({
        ...mapResponseToFormData(values, isCopy),
        goalIds: values.goals?.map((el) => el.id),
        isDevelopmentGoals: !!values.goals?.length,
    } as unknown) as MapFormDataToRequestProps)

/** Маппинг этапов трека для отправки на бэк
 * @param stages этапы трека адаптации
 * @param useInformationAssistant флаг использования информационного помощника
 */
const mapStagesOptionsToRequest = (
    stages?: StageOptionsProps,
    useInformationAssistant?: boolean
) => {
    const resultStages = stages?.reduce<
        Partial<AdaptationTrackStageCreateContract>[]
    >(
        (acc, stage, index) =>
            stage
                ? [
                      ...acc,
                      {
                          ...stage,
                          contentIconId: stage?.contentIcon?.[0]?.response?.id,
                          activityAttemptCount: !stage.isActivityAttemptCountLimited
                              ? stage.activityAttemptCount
                              : undefined,
                          isActivityAttemptCountLimited: !stage.isActivityAttemptCountLimited,
                          activityId: Number(stage.activityId?.[0].value),
                          courseId: Number(stage.courseId?.[0].value),
                          stageNumber: index + 1,
                          assistantEvents: useInformationAssistant
                              ? getNotFalsyArray(stage.assistantEvents)
                              : [],
                      },
                  ]
                : acc,
        []
    )

    return resultStages?.length ? resultStages : undefined
}

const normalizeEffectTime = (effectTime: number) => {
    if (effectTime > MAX_PULSE_TIME) return MAX_PULSE_TIME
    if (effectTime < MIN_PULSE_TIME) return MIN_PULSE_TIME

    return Math.round(effectTime)
}

/**
 * Маппинг значений для отправки: иконки этапа
 * @param icons объект иконок
 * @returns {FormDataStagesStylesIconsProps} без `meta`, пустой объект заменяется на `undefined`
 */
export const getStageIconsStyles = (icons?: FormDataStagesStylesIconsProps) => {
    if (!icons || isObjectEmptyDeep(icons)) return

    return Object.entries(icons).reduce<FormDataStagesStylesIconsProps>(
        (acc, [key, value]) => {
            if (!value) return acc

            const { meta, width, height, iconRounding, ...restProps } = value

            return {
                ...acc,
                [key]: !isObjectEmpty(restProps)
                    ? {
                          ...restProps,
                          width: width || TRACK_STAGE_ICON_SIZE_DEFAULT,
                          height: height || TRACK_STAGE_ICON_SIZE_DEFAULT,
                          iconRounding:
                              iconRounding == null ? 100 : iconRounding,
                          effectTime:
                              normalizeEffectTime(restProps.effectTime) ||
                              MIN_PULSE_TIME,
                      }
                    : undefined,
            }
        },
        {} as FormDataStagesStylesIconsProps
    )
}

/** Маппинг дополнительных материалов для отправки на бэк
 * @param additional дополнительные материалы трека адаптации
 */
const mapAdditionalToRequest = (additional?: FormDataAdditionalsProps) => [
    ...(additional?.video?.map((video) => ({
        id: video.additionalMaterialId,
        courseId: video.id,
        additionalsType: AdditionalsType.Video,
        structuralUnitId: video.structuralUnit?.[0].value,
        name: video.name,
    })) || []),
    ...(additional?.documents?.map(
        (document): Partial<TrackAdditionalsCreateContract> => ({
            id: document.additionalMaterialId,
            courseId: document.id,
            additionalsType: AdditionalsType.Document,
            availability: document.availability
                ? AdditionalsAvailability.Always
                : AdditionalsAvailability.AfterPassing,
            name: document.name,
        })
    ) || []),
]

/**
 * Маппинг отправляемых значений
 * @param requestValues значения формы
 */
export const mapFormDataToRequest = ({
    additionals,
    stages,
    trackCanvases,
    informationAssistant,
    organizationIds,
    positionIds,
    automaticAppointmentPositions,
    questionnaire,
    trackBackgroundPictureId,
    trackYourDevelopmentDisplayId,
    ...values
}: Partial<MapFormDataToRequestProps>): Store => ({
    ...values,
    questionnaireId: questionnaire?.[0].value,
    positionIds: positionIds?.map(getValue),
    automaticAppointmentPositionIds: automaticAppointmentPositions?.map(
        getValue
    ),
    organizationIds: organizationIds?.map(getValue),
    additionals: mapAdditionalToRequest(additionals),
    trackCanvases:
        trackCanvases &&
        Object.entries(trackCanvases).map(([resolution, canvas]) => ({
            ...canvas,
            screenResolution: Number(resolution),
            trackStageStyles: canvas.trackStageStyles?.map((stage, index) => ({
                positionStyles: stage?.positionStyles,
                icons: getStageIconsStyles(stage?.icons),
                stageNumber: stage?.stageNumber || index + 1,
            })),
        })),
    informationAssistant: values.useInformationAssistant
        ? {
              ...informationAssistant,
              events: getNotFalsyArray(informationAssistant?.events),
          }
        : {},
    stages: mapStagesOptionsToRequest(stages, values.useInformationAssistant),
    trackBackgroundPictureId: trackBackgroundPictureId?.[0].response?.id,
    trackYourDevelopmentDisplayId:
        trackYourDevelopmentDisplayId?.[0].response?.id,
})

/**
 * Выбраны ли иконки для двух состояний (до прохождения и во время прохождения)
 * @param stageStyles - стили этапа
 */
const isStageStylesApplied = (stageStyles?: FormDataStagesStylesProps) => {
    const selectedIconsCount =
        (stageStyles?.icons &&
            Object.entries(stageStyles?.icons).filter(
                ([key, value]) => value.iconId
            )?.length) ??
        0

    return selectedIconsCount > 1
}

/** Маппинг данных для прохождения формы*/
export const mapTrackPassingData = (track: TrackPassingContract) => ({
    trackPassingId: track.id,
    title: track.trackTitle,
    stages: track.stages,
    trackCanvases: mapTrackVisualisation(track.trackCanvases),
    trackAdditionalInfo: track.trackAdditionalInfo,
    useInformationAssistant: track.useInformationAssistant,
    trackBackgroundPicture: track.trackBackgroundPicture,
})

// TODO: будет отдельная задача на предпросмотр
/**
 * Обновить состояние кнопки "предпросмотр"
 * @param values выбранные значения формы
 * @param setPreviewReady колбэк для управления кнопкой "предпросмотр"
 */
export const updateStateOfPreviewBtn = ({
    dataToRequest,
    setPreviewReady,
}: UpdateStateOfPreviewBtnProps) => {
    const { title, stages, trackCanvases } = dataToRequest

    if (
        title &&
        stages?.length &&
        trackCanvases?.[Resolutions.XXL].trackCanvasBackgroundId &&
        trackCanvases?.[Resolutions.XXL].width &&
        trackCanvases?.[Resolutions.XXL].height &&
        trackCanvases?.[Resolutions.XXL].trackStageStyles?.some(
            (stageStyles) =>
                isStageStylesApplied(stageStyles) && stageStyles?.positionStyles
        )
    ) {
        setPreviewReady(true)
    } else {
        setPreviewReady(false)
    }
}

export const addAssistantsEventsToStage = (
    form?: WithFormInstanceProps['form'],
    externalStages?: StageOptionsProps
) => {
    const {
        stages: trackStages,
    }: MapFormDataToRequestProps = form?.getFieldsValue()

    const stages = externalStages || trackStages

    const updatedStages = stages?.map((option) => ({
        ...option,
        assistantEvents: !!option?.assistantEvents.length
            ? option?.assistantEvents
            : [undefined],
    }))

    form?.setFieldsValue({
        stages: updatedStages,
    })
}

export const setStagesPassingRequired = (stages?: StageOptionsProps) =>
    stages?.map((stage) => ({
        ...stage,
        isPassingRequired: true,
    }))

/**
 * Возвращает высоту контейнера для таба с учетом высоты таб-вкладок и падингов
 * @param componentHeight высота компонента
 * @return возвращает объект для style компонента
 */
export const getTabMinHeight = (componentHeight?: number) => {
    if (!componentHeight) return

    const deviationHeight = 83

    return {
        minHeight: componentHeight - deviationHeight,
    }
}

/**
 * Получить этапы со стилизацией двух состояний
 * @param stagesStyles - стили этапов
 */
const getAppliedStageStyles = (stagesStyles: StagesStylesProps) =>
    stagesStyles.filter(isStageStylesApplied)

/**
 * Устанавливает значения формы в локалсторейдж и открывает страницу предпросмотра трека
 * @param values - значения формы
 */
export const openTrackPreview = (
    values: Partial<MapFormDataToRequestProps>
) => {
    LocalStorageHelper.setItem(LOCAL_STORAGE_KEYS.TRACK_PREVIEW, {
        ...values,
        trackCanvases: {
            ...values.trackCanvases,
            trackStageStyles: getAppliedStageStyles(
                values.trackCanvases?.[Resolutions.XXL].trackStageStyles || []
            ),
        },
        trackBackgroundPicture: values.trackBackgroundPictureId?.[0].response,
    })

    window.open(ROUTE_NAMES.TRACKS_ADAPTATION_PREVIEW, '_blank')
}

export type FormValuesProps = ReturnType<typeof mapTrackPassingData>

export const updateStageOptionsPreviewStatus = (
    stages?: StageOptionsProps,
    iconStatus?: IconStatusProps
) =>
    stages?.map((stageOption) => ({
        ...stageOption,
        iconStatus,
    }))

/** Маппинг данных для предпросмотра трека */
export const mapTrackPreviewData = (
    track: Partial<MapFormDataToRequestProps>
) => ({
    ...track,
    stages: updateStageOptionsPreviewStatus(
        track.stages,
        TRACK_STAGE_STATUSES[0]
    ),
})

/** Маппинг холстов для предпросмотра трека */
export const mapTrackCanvasesPreview = (
    trackCanvases: Store<MapFormDataToRequestTrackCanvasProps>
): TrackCanvasContract[] =>
    Object.keys(trackCanvases)
        .filter((key) => key === Resolutions.XXL)
        .map(
            (key) =>
                (({
                    ...(trackCanvases?.[key] || {}),
                    screenResolution: +key,
                } as unknown) as TrackCanvasContract)
        )

export const synchronizeStagesAndCanvases = (
    form?: WithFormInstanceProps['form'],
    externalCanvases?: Store<MapFormDataToRequestTrackCanvasProps>,
    externalStages?: StageOptionsProps
) => {
    const {
        stages: trackStages,
        trackCanvases,
    }: MapFormDataToRequestProps = form?.getFieldsValue()

    const canvases = externalCanvases || trackCanvases
    const stages = externalStages || trackStages

    if (!stages || !canvases) return

    const updatedCanvases = Object.entries(canvases).map(
        ([resolution, canvas]) => {
            if (!canvas.trackStageStyles) {
                canvas.trackStageStyles = []
            }

            const lengthDiff = stages.length - canvas.trackStageStyles.length

            if (lengthDiff === 0) return [resolution, canvas]

            if (lengthDiff > 0) {
                canvas.trackStageStyles = canvas.trackStageStyles.concat(
                    new Array(lengthDiff).fill(undefined)
                )
            } else {
                canvas.trackStageStyles = canvas.trackStageStyles.slice(
                    0,
                    lengthDiff
                )
            }

            return [
                resolution,
                {
                    ...canvas,
                    trackStageStyles: canvas.trackStageStyles,
                },
            ]
        }
    )

    form?.setFieldsValue({
        trackCanvases: Object.fromEntries(updatedCanvases),
    })
}
