import React from 'react'
import {
    APPOINTMENT_STATUS,
    ASSESSMENT_ASSIGNMENT_CONDITIONAL_PERMISSIONS,
    ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
    DOCUMENT_STATUSES,
} from 'consts'
import {
    AppointmentContract,
    AppointmentSearchResultContract,
    UserPermission,
} from 'core/api'
import {
    AppointmentsPassingServiceProps,
    formatDate,
    isAppointmentStatusCanceled,
    isAppointmentStatusDraft,
    isAppointmentStatusEnded,
    mapToDictionaryFromStrArr,
    renderElementWithListPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'
import { ColumnsType } from 'antd/lib/table/interface'
import { DictionariesStoreProps } from 'core/helpers/DictionariesHelper'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { GetBtnOptionsType } from 'components/layouts/FormContainer/FormContainer.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WidthRestrictedElement } from 'components/shared/WidthRestrictedElement'

import { AssessmentAssignmentsTableActions } from './components'

/**
 * Получить настройки отображения колонок для реестра назначений оценок
 * @param appointmentProcessService - сервис для контроля процесса прохождения назначения
 * @param actions - вспомогательные функции/экшены, берутся из DictionaryPage
 * @param locales - локали с бэкенда
 */
export const getAssessmentAssignmentsTableColumns = (
    appointmentProcessService: AppointmentsPassingServiceProps,
    { reFetchDictionary, updateLoader }: DictionaryPageActionsProps,
    { assessmentTypeDictionary }: DictionariesStoreProps
): ColumnsType<AppointmentSearchResultContract> => [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.ASSESSMENT_OBJECT,
        dataIndex: 'candidateFullNames',
        key: 'candidateFullNames',
        render: (candidateFullNames: string[]) =>
            renderElementWithListPopover(
                mapToDictionaryFromStrArr(candidateFullNames),
                { width: 350 }
            ),
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'assessmentType',
        key: 'assessmentType',
        render: (value, record) => (
            <WidthRestrictedElement
                title={assessmentTypeDictionary[record.assessmentType]}
            />
        ),
    },
    {
        title: LOCAL.LABELS.REQUEST_NUMBER,
        dataIndex: 'requestNumber',
        key: 'requestNumber',
        sorter: defaultSorter,
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'evaluationPeriodFrom',
        key: 'evaluationPeriodFrom',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'evaluationPeriodTo',
        key: 'evaluationPeriodTo',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
            <WidthRestrictedElement
                title={
                    record.active
                        ? APPOINTMENT_STATUS[record.status]
                        : DOCUMENT_STATUSES.Archive
                }
            />
        ),
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <AssessmentAssignmentsTableActions
                updateLoader={updateLoader}
                tableRow={tableRow}
                appointmentProcessService={appointmentProcessService}
                reFetchDictionary={reFetchDictionary}
            />
        ),
        align: 'right',
        fixed: 'right',
    },
]

export const CANCEL_BTN_OPTIONS = {
    href: ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS,
    visible: true,
}

export const getSaveBtnOptions: GetBtnOptionsType<AppointmentContract> = ({
    urlParams,
    editValues,
}) => ({
    visible:
        !urlParams?.id ||
        (!!urlParams?.id && isAppointmentStatusDraft(editValues?.status)),
})

export const getPublishBtnOptions: GetBtnOptionsType<AppointmentContract> = ({
    editValues,
}) => ({
    text: LOCAL.ACTIONS.ASSIGN,
    visible:
        !isAppointmentStatusCanceled(editValues?.status) &&
        !isAppointmentStatusEnded(editValues?.status),
    requiredPermissions: [
        ...ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
        UserPermission.AssessmentAppointmentPublish,
    ],
    conditionalPermissions: ASSESSMENT_ASSIGNMENT_CONDITIONAL_PERMISSIONS,
})
