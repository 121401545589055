import React from 'react'
import { ControlGroup } from 'components/controls'
import { DATE_FORMAT, DOCUMENT_STATUSES, MAILING_STATUSES } from 'consts'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { NotificationMailingTaskContract } from 'core/api'
import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'
import { getDateByFormat } from 'utils'

import styles from './DistributionMailingInfo.module.scss'

/** Отображение информации о задании */
export const DistributionMailingInfo: React.FC<{
    task: NotificationMailingTaskContract
}> = React.memo(({ task }) => (
    <ControlGroup type="bordered" title={LOCAL.LABELS.MAILING_INFO}>
        {task.lastMailingOnAssignment && (
            <Link
                to={`${ROUTE_NAMES.DISTRIBUTION_MAILING}/${ROUTES_POSTFIXES.FILTER}/${task.id}`}
                className={styles.link}
                target="_blank"
            >
                <IconsAdapter iconType="UnorderedListOutlined" />

                <span className={styles.distributions}>
                    {LOCAL.LABELS.DISTRIBUTIONS}
                </span>
            </Link>
        )}

        <p className={styles.title}>
            {`${LOCAL.LABELS.TASK_STATUS}: `}

            <span className={styles.text}>
                {task.active
                    ? MAILING_STATUSES[task.businessStatus]
                    : DOCUMENT_STATUSES.Archive}
            </span>
        </p>

        <p className={styles.title}>
            {`${LOCAL.LABELS.MAILING_LAST}: `}

            {task.lastMailingOnAssignment && (
                <span className={styles.text}>
                    {getDateByFormat(
                        task.lastMailingOnAssignment,
                        DATE_FORMAT.WITH_TIME
                    )}
                </span>
            )}
        </p>

        <p className={styles.title}>
            {`${LOCAL.LABELS.MAILING_CHANGED}: `}

            <span className={styles.text}>
                {`${getDateByFormat(task.changeDate, DATE_FORMAT.WITH_TIME)} ${
                    task.changeUser?.name
                }`}
            </span>
        </p>
    </ControlGroup>
))
