import React from 'react'
import cn from 'classnames'
import { Divider, Space } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { useElementVisible } from 'hooks'

import styles from './TrackProgress.module.scss'
import { TrackProgressProps } from './TrackProgress.types'
import { getProgressBlock, getStatisticRow } from './TrackProgress.utils'

/** Прогресс трека адаптации */
export const TrackProgress: React.FC<TrackProgressProps> = React.memo(
    ({
        data,
        showProgress,
        onShowProgress,
        trackProgressVisualization,
        toggleRef,
    }) => {
        const {
            elementVisible: openRating,
            toggleElementVisible: handleOpenRating,
        } = useElementVisible()

        const textStyle = {
            color: trackProgressVisualization.progressTextSettings.textColor,
            fontFamily:
                trackProgressVisualization.progressTextSettings.fontName,
        }

        return (
            <div
                className={cn(
                    styles.wrapper,
                    !showProgress && styles.wrapperHidden
                )}
            >
                <Space
                    className={styles.progress}
                    size={0}
                    style={{
                        background: trackProgressVisualization.backgroundColor,
                    }}
                >
                    <div
                        className={cn(
                            styles.info,
                            !showProgress && styles.pointer
                        )}
                        ref={toggleRef}
                        onClick={onShowProgress}
                    >
                        <IconsAdapter iconType="CustomIconRocket" />

                        <div
                            className={cn(
                                styles.title,
                                styles.titleProgressText
                            )}
                            style={textStyle}
                        >
                            {LOCAL.LABELS.PROGRESS}
                        </div>
                    </div>

                    {trackProgressVisualization.isLessonsVisible && (
                        <>
                            <Divider type="vertical" />

                            {getProgressBlock(
                                LOCAL.LABELS.PASSING_BLOCKS,
                                textStyle,
                                data?.lessonsPassed
                            )}
                        </>
                    )}

                    {trackProgressVisualization.isHomeworksVisible && (
                        <>
                            <Divider type="vertical" />

                            {getProgressBlock(
                                LOCAL.LABELS.COMPLETING_TASKS,
                                textStyle,
                                data?.homeworksPassed
                            )}
                        </>
                    )}

                    {trackProgressVisualization.isWebinarsVisible && (
                        <>
                            <Divider type="vertical" />

                            {getProgressBlock(
                                LOCAL.LABELS.WEBINARS,
                                textStyle,
                                data?.webinarsPassed
                            )}
                        </>
                    )}

                    {trackProgressVisualization.isRatingVisible && (
                        <>
                            <Divider type="vertical" />

                            <div
                                className={cn(
                                    styles.info,
                                    styles.pointer,
                                    openRating && styles.infoOpen
                                )}
                                style={textStyle}
                                onClick={handleOpenRating}
                            >
                                <div className={styles.count}>
                                    {data?.ratingPlace}
                                </div>

                                <div className={styles.title}>
                                    {LOCAL.LABELS.YOU_RATING}
                                </div>
                            </div>
                        </>
                    )}
                </Space>

                {openRating && (
                    <div className={styles.rating}>
                        {getStatisticRow(
                            LOCAL.LABELS.PLACE_IN_RATING,
                            data?.ratingPlace,
                            data?.respondentsCount
                        )}

                        {getStatisticRow(
                            LOCAL.LABELS.YOUR_SCORE,
                            data?.currentPoints,
                            data?.totalPoints
                        )}
                    </div>
                )}
            </div>
        )
    }
)
