import React from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export const useSize = (target: any) => {
    const [size, setSize] = React.useState()

    React.useLayoutEffect(() => {
        setSize(target.current?.getBoundingClientRect())
    }, [target])

    useResizeObserver(target, (entry: any) => setSize(entry.contentRect))

    return size as any
}
