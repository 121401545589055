import React, { useCallback } from 'react'
import { AppError, AttachmentContract, AttachmentsService } from 'core/api'
import { CustomControlCommonProps } from 'App.types'
import { MEDIA_DOCUMENT_TYPE } from 'consts'
import { RcCustomRequestOptions } from 'types'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import { Upload } from 'antd'
import { isFormModeView, validateUploadImage } from 'utils'

import styles from './UploadAvatar.module.scss'
import { AddButton } from '../AddButton'
import { CloseButton } from '../CloseButton'

/** Контрол добавления и удаления файла для формы, принимает инстанс формы */
export const UploadAvatar: React.FC<CustomControlCommonProps<
    UploadFile<AttachmentContract>[]
>> = React.memo(({ formMode, disabled, value, onChange }) => {
    const imgUrl = value?.[0]?.response?.url
    const isDisabled = disabled || isFormModeView(formMode)

    /**
     * Обработчик удаления файла
     */
    const handleRemoveFile = useCallback(() => {
        if (isDisabled) return

        onChange?.([])
    }, [isDisabled, onChange])

    /**
     * Обновление значения во внешней форме
     * @param value - значение для формы
     */
    const triggerChange = useCallback(
        (value) => {
            if (onChange) onChange(value)
        },
        [onChange]
    )

    /**
     * Обработчик добавления файла
     */
    const handleUploadAttachment = useCallback(
        async ({ file, onError }: RcCustomRequestOptions) => {
            try {
                const upload = await AttachmentsService.upload({
                    file,
                })

                const rcFile = file as RcFile

                onChange?.([
                    {
                        ...rcFile,
                        originFileObj: rcFile,
                        response: upload,
                    },
                ])
            } catch (error) {
                onError?.(error as AppError)
            }
        },
        [onChange]
    )

    return imgUrl ? (
        <div className={styles.picture}>
            <img className={styles.body} src={imgUrl} alt="avatar" />

            {!isDisabled && (
                <CloseButton
                    className={styles.remove}
                    onClose={handleRemoveFile}
                    disabled={isDisabled}
                />
            )}
        </div>
    ) : (
        <Upload
            listType="picture"
            fileList={value}
            onChange={triggerChange}
            customRequest={handleUploadAttachment}
            showUploadList={false}
            disabled={isDisabled}
            accept={MEDIA_DOCUMENT_TYPE}
            beforeUpload={validateUploadImage}
        >
            <AddButton className={styles.body} buttonText="" />
        </Upload>
    )
})
