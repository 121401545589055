import React from 'react'
import { NotImplementedError } from 'core/helpers/NotImplementedError'

import { AppSettingsContextProps } from './AppSettingsContext.types'

export const AppSettingsContext = React.createContext<AppSettingsContextProps>({
    appSettings: null,
    setAppSettings: () => {
        throw new NotImplementedError()
    },
})
