export const STATUSES = {
    APPROVED: 'Согласовано',
    ARCHIVE: 'Архив',
    ASSIGNED: 'Назначено',
    AVAILABLE: 'Доступно',
    AVAILABLE_FOR_PASSING: 'Доступен к прохождению',
    AWAIT_DECISION: 'Ожидает решения',
    CANCELED: 'Отменено',
    CHECKED: 'Проверено',
    CLICKED: 'Получателем выполнен переход по ссылке',
    CONFIRMED: 'Подтверждено',
    CORRECT_AND_WRONG_ANSWER: 'Указаны правильные и неправильные ответы',
    CORRECT_ANSWER: 'Ответ верный',
    CREDIT_LOST: 'Незачет',
    CREDIT_RECEIVED: 'Зачет',
    DELIVERED: 'Доставлено получателю',
    DELIVERED_EPS: 'Получено ЕПС',
    DONE: 'Выполнено',
    DRAFT: 'Черновик',
    ENDED: 'Завершено',
    ERROR: 'Ошибка',
    EXPECTATION: 'Ожидание',
    IN_PROGRESS: 'В процессе',
    IN_WORK: 'В работе',
    NOT_ALL_ANSWERS: 'Указаны не все верные ответы',
    NOT_PASSED: 'Не пройдено',
    NOT_PASSED_M: 'Не пройден',
    NOT_SENT: 'Не отправлено',
    NO_ASSIGNED: 'Не назначено',
    NO_AVAILABLE: 'Недоступно',
    NO_RESTRICTED: 'Не ограничено',
    ON_APPROVAL: 'На согласовании',
    ON_CHECK: 'На проверке',
    ON_REVISION: 'На доработке',
    PASSED: 'Пройдено',
    PASSED_M: 'Пройден',
    PENDING: 'В ожидании',
    PUBLISHED: 'Опубликован',
    READED: 'Прочитано',
    READY_FOR_WORK: 'Готов к работе',
    REVISE: 'Доработать',
    SENT: 'Отправлено',
    SENT_FOR_REVISION: 'Отправлено на доработку',
    SENT_FOR_VERIFICATON: 'Отправлено на проверку',
    SUSPENDED: 'Приостановлено',
    UNDELIVERED: 'Не доставлено получателю',
    UNREADED: 'Не прочитано',
    UNSEND: 'Не отправлено получателю',
    UNSUBSCRIBED: 'Получатель отписался от рассылки',
    VIEWED: 'Просмотрено получателем',
    WRONG_ANSWER: 'Ответ неверный',
}
