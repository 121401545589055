import React from 'react'
import cn from 'classnames'
import { Form } from 'antd'

import styles from './LabelWithSwitch.module.scss'
import { LabelWithSwitchProps } from './LabelWithSwitch.types'
import { SwitchControl } from '../../controls'

/**
 * Лейбл с переключателем
 */
export const LabelWithSwitch: React.FC<LabelWithSwitchProps> = React.memo(
    (props) => {
        const { label, fieldName, className, ...restProps } = props

        return (
            <div className={cn(styles.wrapper, className)}>
                <span>{label}</span>
                <Form.Item noStyle name={fieldName}>
                    <SwitchControl
                        className={styles.switch}
                        size="small"
                        {...restProps}
                    />
                </Form.Item>
            </div>
        )
    }
)
