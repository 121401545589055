import { AppError } from 'core/api'
import { FnActionAsyncProps, FnActionRequiredProps } from 'App.types'
import { useCallback, useState } from 'react'

/** тип метода api с полученными параметрами, где Request - тип параметров, Response - тип ответа */
export type HttpMethodType<Response, Request> = FnActionAsyncProps<
    Request,
    Response
>

/** тип результата работы хука useHttp, где Request - тип параметров, Response - тип ответа */
export type UseHttpResultType<Response, Request> = [
    FnActionAsyncProps<Request>,
    Response | undefined,
    AppError | undefined,
    FnActionRequiredProps<Response>
]

/**
 * Хук useHttp используется для запроса метода api, возвращает результат или ошибки
 * @param httpMethod - метод запроса данных, который предоставляет api
 * @param updateLoader
 */
export const useHttp = <Response = any, Request = any>(
    httpMethod: HttpMethodType<Response, Request>,
    updateLoader?: FnActionRequiredProps<boolean>
): UseHttpResultType<Response, Request> => {
    const [response, setResponse] = useState<Response>()
    const [error, setError] = useState<AppError>()

    /**
     * Получение данных
     */
    const getData = useCallback(
        async (params?: Request) => {
            try {
                updateLoader?.(true)

                const response: Response = await httpMethod(params)

                setResponse(response)
            } catch (error) {
                setError(error as AppError)
            } finally {
                updateLoader?.(false)
            }
        },
        [updateLoader, httpMethod]
    )

    /**
     * Обновление данных
     */
    const updateData = useCallback((data: Response) => {
        setResponse(data)
    }, [])

    return [getData, response, error, updateData]
}
