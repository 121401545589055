import React, { useEffect } from 'react'
import { Circle360ReportService } from 'core/api'
import { DESCRIPTION } from 'core/local/components/AreasStrengthsDevelopment.local.config'
import { LOCAL } from 'core/local'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { safelySetInnerHtml } from 'utils'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import stylesReportView from '../ReportView/ReportView.module.scss'
import { makeList } from '../ReportView/ReportView.utils'

/** Отчет по оценке 360. Сильные стороны и зоны развития */
export const AreasStrengthsDevelopment: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(
        Circle360ReportService.getAreasStrengthsDevelopment
    )

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return (
        <>
            <p
                className={stylesReportView.text}
                dangerouslySetInnerHTML={safelySetInnerHtml(DESCRIPTION)}
            />

            <h2>{LOCAL.LABELS.STRONG_SIDES}</h2>

            {makeList(data?.strongSides)}

            <h2>{LOCAL.LABELS.DEVELOPMENT_ZONES}</h2>

            {makeList(data?.zonesOfDevelopment)}
        </>
    )
})
