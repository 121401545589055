import React from 'react'
import { AddButton } from 'components/shared/AddButton'
import { ComponentCommonProps } from 'App.types'
import { Form } from 'antd'
import { LOCAL } from 'core/local'

import styles from './ProductContent.module.scss'
import { mapProductContentSections } from './ProductContent.utilts'

/** Блок полей формы создания карточки продукта - содержание продукта */
export const ProductContent: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <div className={styles.wrapper}>
            <h3 className={styles.title}>{LOCAL.LABELS.PRODUCT_CONTENT}</h3>

            <Form.List name="sections">
                {(fields, { add, remove }) => (
                    <div>
                        {mapProductContentSections({
                            fields,
                            remove,
                            formMode,
                        })}

                        <AddButton
                            className={styles.addSection}
                            buttonText={LOCAL.ACTIONS.ADD_SECTION}
                            onClick={() => add()}
                            formMode={formMode}
                        />
                    </div>
                )}
            </Form.List>
        </div>
    )
)
