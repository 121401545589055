import React, { FC, useEffect, useState } from 'react'
import { LOCAL } from 'core/local'
import { ScaleByIdsContract, ScalesService } from 'core/api'

import styles from './CriteriaScaleGrid.module.scss'
import { CriteriaScaleGridProps } from './CriteriaScaleGrid.types'

/**
 * Шкала критериев оценки
 */
export const CriteriaScaleGrid: FC<CriteriaScaleGridProps> = React.memo(
    ({ pollEstimateScaleTypeId, assessmentEstimateScaleTypeId }) => {
        /**
         * Стейт для хранения данных всех шкал
         */
        const [scaleData, setScaleData] = useState<ScaleByIdsContract[]>()

        useEffect(() => {
            let cleanupFunction = false

            /**
             * Запрос шкалы критериев
             */
            const dictionaryFetch = async () => {
                try {
                    const chosenScaleId =
                        pollEstimateScaleTypeId || assessmentEstimateScaleTypeId

                    const dataSource = await ScalesService.getByIds({
                        body: [chosenScaleId],
                    })

                    if (!cleanupFunction) setScaleData(dataSource)
                } catch (error) {
                    console.error(error)
                }
            }

            dictionaryFetch()

            return () => {
                cleanupFunction = true
            }
        }, [assessmentEstimateScaleTypeId, pollEstimateScaleTypeId])

        return (
            <div>
                <p className={styles.name}>
                    {`${LOCAL.LABELS.PRESET_TYPE_OF_SCALE}: ${scaleData?.map(
                        (scale) => scale.name
                    )}`}
                </p>

                <ol className={styles.scalesList}>
                    {scaleData?.[0]?.scaleValues?.map((el) => (
                        <li key={el.id}>{el.description}</li>
                    ))}
                </ol>
            </div>
        )
    }
)
