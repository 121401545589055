import React from 'react'
import { AlignType } from 'rc-table/lib/interface'
import { ColumnsType } from 'antd/lib/table'
import { FaceToFaceMeetingListenerContract, UserBaseContract } from 'core/api'
import { LOCAL } from 'core/local'
import { Switch } from 'antd'
import { getShortFio } from 'utils'

import styles from './ResultsOfMeetingsModal.module.scss'

export const getColumns = (
    onChange: (userId: number) => (checked: boolean) => void
): ColumnsType<FaceToFaceMeetingListenerContract> => [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'listener',
        width: '33%',
        render: (listener: UserBaseContract) => (
            <span> {getShortFio(listener)}</span>
        ),
    },

    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'position',
        width: '50%',
    },

    {
        title: LOCAL.LABELS.STATUS,
        width: '17%',
        align: 'right' as AlignType,
        render: ({ isPassed, id }) => (
            <div className={styles.switch}>
                <Switch defaultChecked={isPassed} onChange={onChange(id)} />

                <span>{LOCAL.LABELS.APPEARANCE}</span>
            </div>
        ),
    },
]
