import React, { useEffect } from 'react'
import { FeedbackQuizReportsService } from 'core/api'
import { TableAdapter } from 'components/shared'
import { useHttp, useSearchQuery } from 'hooks'

import { SPENT_TIME_TABLE_CONFIG } from './SpentTime.config'
import { SpentTimeProps } from './SpentTime.types'
import { normalizeData } from './SpentTime.utils'

/** Страница "Затраченное время" в отчете опроса типа "Обратная связь" */
export const SpentTime: React.FC<SpentTimeProps> = React.memo(
    ({ appointmentId }) => {
        const { paginationOptions, queryParams } = useSearchQuery({})

        const [getData, data] = useHttp(
            FeedbackQuizReportsService.getElapsedTimeTab
        )

        useEffect(() => {
            getData({
                appointmentId,
                pageNumber: queryParams.pageNumber,
                pageSize: queryParams?.pageSize,
            })
        }, [getData, appointmentId, queryParams])

        return (
            <TableAdapter
                columns={SPENT_TIME_TABLE_CONFIG}
                dataSource={normalizeData(data?.elapsedTimeTable)}
                childrenColumnName="children"
                pagination={{
                    ...paginationOptions,
                    total: data?.totalUsers,
                    current: queryParams.pageNumber,
                    pageSize: queryParams?.pageSize,
                }}
            />
        )
    }
)
