import React from 'react'
import { Col, Row } from 'antd'
import { CourseSearchContract } from 'core/api'
import { DatePickerControl } from 'components/controls/DatePickerControl'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'

export const DocumentsFilters: React.FC<FiltersProps<
    CourseSearchContract
>> = React.memo((props) => (
    <FilterForm {...props}>
        <Row gutter={ROW_GUTTER}>
            <Col xs={12}>
                <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                    <InputControl />
                </FormItemAdapter>
            </Col>

            <Col xs={12}>
                <FormItemAdapter
                    fieldName="uploadDate"
                    label={LOCAL.LABELS.UPLOAD_DATE}
                >
                    <DatePickerControl />
                </FormItemAdapter>
            </Col>
        </Row>
    </FilterForm>
))
