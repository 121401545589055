import { QuestionnairesConstructorFormSubmitValues } from 'components/pages/Questionnaires/components/QuestionnairesConstructorForm/QuestionnairesConstructorForm.types'

/**
 * Заблокировать поле кнопку добавления нового ряда
 * @param values - текущие значения формы
 * @param questionIndex - индекс блока типа вопроса
 */
export const disableAddButton = (
    values: Partial<QuestionnairesConstructorFormSubmitValues>,
    questionIndex: number
) => {
    if (!values.maxPoints || !values.questions) return
    const sumRowsPoints = values.questions[questionIndex].answers?.reduce(
        (acc, el) => acc + (el.points || 0),
        0
    )

    return { disabled: values.maxPoints === sumRowsPoints }
}
