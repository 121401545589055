import React from 'react'
import {
    ErrorPage,
    ProductCatalog,
    ProductCatalogFormContainer,
    Themes,
} from 'components/pages'
import { PRODUCT_CATALOG_REQUIRED_PERMISSIONS } from 'consts'
import { ProductCatalogContextProvider } from 'components/pages/ProductCatalog/ProductCatalog.context'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

export const ProductCatalogRouting = React.memo(() => (
    <ProductCatalogContextProvider>
        <Switch>
            <ProtectedRoute
                requiredPermissions={[
                    UserPermission.Administration,
                    UserPermission.ThemesRead,
                ]}
                path={ROUTE_NAMES.THEMES}
            >
                <Themes />
            </ProtectedRoute>

            <ProtectedRoute
                requiredPermissions={[
                    ...PRODUCT_CATALOG_REQUIRED_PERMISSIONS,
                    UserPermission.ProductCardSave,
                ]}
                path={`${ROUTE_NAMES.PRODUCT_CATALOG_COPY}/:id`}
            >
                <ProductCatalogFormContainer formMode="copy" />
            </ProtectedRoute>

            <ProtectedRoute
                requiredPermissions={PRODUCT_CATALOG_REQUIRED_PERMISSIONS}
                path={`${ROUTE_NAMES.PRODUCT_CATALOG_VIEW}/:id`}
            >
                <ProductCatalogFormContainer formMode="view" />
            </ProtectedRoute>

            <ProtectedRoute
                requiredPermissions={[
                    ...PRODUCT_CATALOG_REQUIRED_PERMISSIONS,
                    UserPermission.ProductCardSave,
                ]}
                path={`${ROUTE_NAMES.PRODUCT_CATALOG_EDIT}/:id`}
            >
                <ProductCatalogFormContainer formMode="edit" />
            </ProtectedRoute>

            <ProtectedRoute
                requiredPermissions={[
                    ...PRODUCT_CATALOG_REQUIRED_PERMISSIONS,
                    UserPermission.ProductCardSave,
                ]}
                path={ROUTE_NAMES.PRODUCT_CATALOG_CREATE}
            >
                <ProductCatalogFormContainer formMode="create" />
            </ProtectedRoute>

            <ProtectedRoute
                requiredPermissions={[
                    UserPermission.Administration,
                    UserPermission.ProductCardsRead,
                ]}
                path={ROUTE_NAMES.PRODUCT_CATALOG}
            >
                <ProductCatalog />
            </ProtectedRoute>

            <Route>
                <ErrorPage errorCode="404" />
            </Route>
        </Switch>
    </ProductCatalogContextProvider>
))
