import React from 'react'

import styles from './DownloadLink.module.scss'
import { DownloadLinkProps } from './DownloadLink.types'
import { IconsAdapter } from '../IconsAdapter'
import { WidthRestrictedElement } from '../WidthRestrictedElement'

export const DownloadLink: React.FC<DownloadLinkProps> = React.memo(
    ({ link, fileName, getTooltipContainer, maxWidth = 300 }) => (
        <a href={link} className={styles.wrapper}>
            <IconsAdapter
                iconType="DownloadOutlined"
                className={styles.uploadFileIcon}
            />
            <WidthRestrictedElement
                className={styles.title}
                title={fileName}
                tooltip={{
                    title: fileName,
                }}
                maxWidth={maxWidth}
                getPopupContainer={getTooltipContainer}
                showDots
            />
        </a>
    )
)
