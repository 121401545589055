import React, { useCallback } from 'react'
import {
    Button,
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
} from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { isHomeworkPassed } from 'utils/conditions.utils/homework.utils'
import { useHistory } from 'react-router-dom'

import { CheckHomeworkActionsProps } from './CheckHomeworkActions.types'
import { CheckHomeworkInfoForm } from '../CheckHomeworkInfoForm'

export const CheckHomeworkActions: React.FC<CheckHomeworkActionsProps> = React.memo(
    ({ tableRow }) => {
        const history = useHistory()

        const handleClick = useCallback(() => {
            if (tableRow.isEditorUsed) {
                history.push(
                    `${ROUTE_NAMES.PA_CHECK_HOMEWORK}/lde/${tableRow.id}`
                )

                return
            }

            history.push(`${ROUTE_NAMES.PA_CHECK_HOMEWORK}/${tableRow.id}`)
        }, [tableRow, history])

        return (
            <ButtonsToolbar bottomIndent="0">
                {!(tableRow.isEditorUsed && tableRow.isOverdue) && (
                    <Button onClick={handleClick}>
                        {isHomeworkPassed(tableRow.status) || tableRow.isOverdue
                            ? LOCAL.ACTIONS.VIEW
                            : LOCAL.ACTIONS.CHECK}
                    </Button>
                )}

                <PopupWithForm
                    component={CheckHomeworkInfoForm}
                    formId={FORM_IDS.CHECK_HOMEWORK_INFO_FORM}
                    buttonText=""
                    buttonOption={{
                        type: 'link',
                        icon: <IconsAdapter iconType="InfoCircleOutlined" />,
                        title: LOCAL.LABELS.INFORMATION,
                    }}
                    formOptions={{
                        initialValues: tableRow,
                    }}
                    modalOptions={{
                        title: tableRow.lessonName,
                        okText: LOCAL.ACTIONS.SAVE,
                    }}
                />
            </ButtonsToolbar>
        )
    }
)
