import Slider from 'react-slick'
import React, { useMemo } from 'react'
import { LOCAL } from 'core/local'
import { RecommendationContract } from 'core/api'

import styles from './RecommendedItems.module.scss'
import { CAROUSEL_SETTINGS } from './RecommendedItems.consts'
import { CatalogSection } from '../CatalogSection'
import { RecommendedItemsProps } from './RecommendedItems.types'
import { RecommendedUnit } from './RecomendedUnit'
import { getCatalogSectionHeader } from '../CatalogSection/CatalogSection.utils'

/** Компонент для отображения коллекции "рекомендованных" элементов каталога */
export const RecommendedItems: React.FC<RecommendedItemsProps> = React.memo(
    ({ recommended, onUpdateFavourites }) => {
        const carouselContent = useMemo(
            () =>
                recommended.map((el: RecommendationContract) => (
                    <RecommendedUnit
                        onUpdateFavourites={onUpdateFavourites}
                        key={el.id}
                        {...el}
                    />
                )),
            [recommended, onUpdateFavourites]
        )

        return (
            <CatalogSection
                header={getCatalogSectionHeader(LOCAL.LABELS.RECOMMENDED)}
                content={
                    <div className={styles.wrapper}>
                        <Slider
                            infinite={recommended.length > 2}
                            {...CAROUSEL_SETTINGS}
                        >
                            {carouselContent}
                        </Slider>
                    </div>
                }
            />
        )
    }
)
