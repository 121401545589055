import React, { useCallback, useContext } from 'react'
import { BlockPassingModal } from 'components/pages/Events/EventPassing/components'
import { InformationPassingModal } from 'components/forms/InformationPassingModal'
import {
    PageContent,
    QuestionnaireForm,
    QuestionnaireModal,
    VideoContentModal,
} from 'components/shared'
import { TaskPassingForm } from 'components/forms/TaskPassingForm'
import { eventEmitter } from 'core/helpers/eventEmitter'
import {
    isThemeBlockTypeActivity,
    isThemeBlockTypeCourse,
    isThemeBlockTypeHomework,
    isThemeBlockTypeHomeworkWithoutEditor,
    isThemeBlockTypeInformation,
    isThemeBlockTypeQuestionnaire,
    isThemeBlockTypeVideoCourse,
    isThemeBlockTypeWebConferenceOrFaceToFace,
    isTrainingMaterialFormatTinCan,
} from 'utils'

import styles from './LessonLayer.module.scss'
import { ActivityLayer } from '../ActivityLayer'
import { LessonLayerContent } from '../LessonLayerContent'
import { LessonLayerHeader } from '../LessonLayerHeader'
import { TRACK_PASSING_EVENTS } from '../../TrackPassing.events'
import { TrackPassingContext } from '../../TrackPassing.context'
import { isEnoughAttemptCountForThematicBlock } from '../ActivityLayer/ActivityLayer.utils'

export const LessonLayer: React.FC<{ isChildLesson?: boolean }> = React.memo(
    ({ isChildLesson }) => {
        const { store } = useContext(TrackPassingContext)

        const { stageInfo, lessonInfo, lessonChildInfo } = store

        const lesson = isChildLesson ? lessonChildInfo : lessonInfo

        const closeLesson = useCallback(() => {
            eventEmitter.emit(TRACK_PASSING_EVENTS.CLOSE_LESSON, isChildLesson)
        }, [isChildLesson])

        const finishLesson = useCallback(() => {
            eventEmitter.emit(TRACK_PASSING_EVENTS.FINISH_LESSON, isChildLesson)
        }, [isChildLesson])

        const exitVideoLesson = useCallback(
            (progress) => {
                eventEmitter.emit(
                    TRACK_PASSING_EVENTS.EXIT_LESSON_VIDEO,
                    progress,
                    isChildLesson
                )
            },
            [isChildLesson]
        )

        if (isThemeBlockTypeVideoCourse(lesson)) {
            return (
                <VideoContentModal
                    popupProps={{
                        visible: !!lesson,
                    }}
                    fileUrl={lesson?.course.launchUrl}
                    progress={lesson?.progress}
                    onFinish={finishLesson}
                    onExit={exitVideoLesson}
                />
            )
        }

        if (isThemeBlockTypeWebConferenceOrFaceToFace(lesson?.type)) {
            return (
                <BlockPassingModal
                    popupProps={{
                        visible: !!lesson,
                        onCancel: closeLesson,
                        onOk: finishLesson,
                    }}
                    lesson={lesson!}
                />
            )
        }

        if (isThemeBlockTypeInformation(lesson?.type)) {
            return (
                <InformationPassingModal
                    popupProps={{
                        visible: !!lesson,
                        onCancel: closeLesson,
                        onOk: finishLesson,
                    }}
                    selectedThematicBlock={lesson}
                />
            )
        }

        // TODO: необходима доработка по бэку, чтобы вложенное мероприятие содержало ссылку на родителький lesson
        if (isThemeBlockTypeActivity(lesson?.type) && !isChildLesson) {
            return <ActivityLayer />
        }

        if (
            isThemeBlockTypeHomeworkWithoutEditor(
                lesson?.type,
                lesson?.homework?.isEditorUsed
            )
        ) {
            return (
                <TaskPassingForm
                    homework={lesson?.homework!}
                    popupProps={{
                        visible: !!lesson,
                        onCancel: closeLesson,
                    }}
                    isHomeworkAutoCheck={
                        stageInfo?.activity?.activityPassing.isHomeworkAutoCheck
                    }
                />
            )
        }

        if (isThemeBlockTypeQuestionnaire(lesson?.type)) {
            const initialValues =
                !!lesson?.questions?.length &&
                isEnoughAttemptCountForThematicBlock(lesson)
                    ? {
                          questions: lesson.questions,
                          id: lesson.id,
                          name: lesson.questionnaireName,
                          currentQuestionNumber: lesson.currentQuestionNumber,
                          type: lesson.questionnaireType,
                      }
                    : undefined

            return (
                <QuestionnaireModal
                    initialValues={initialValues}
                    onClose={closeLesson}
                    componentWrapper={QuestionnaireForm}
                />
            )
        }

        if (
            isThemeBlockTypeHomework(lesson?.type) ||
            isThemeBlockTypeCourse(lesson?.type)
        ) {
            return (
                <PageContent className={styles.wrapper}>
                    {!isTrainingMaterialFormatTinCan(
                        lesson?.course?.format
                    ) && (
                        <LessonLayerHeader
                            lessonInfo={lesson}
                            isChildLesson={isChildLesson}
                        />
                    )}

                    <LessonLayerContent
                        lessonInfo={lesson}
                        isChildLesson={isChildLesson}
                    />
                </PageContent>
            )
        }

        return null
    }
)
