import React, { CSSProperties } from 'react'
import { LOCAL } from 'core/local'

import styles from './TrackProgress.module.scss'

/**
 * Формирование строки для статистики
 * @param passed первое значение
 * @param total второе значение
 * @param title заголовок
 */
export const getStatisticRow = (
    title: string,
    passed?: number,
    total?: number
) => (
    <div className={styles.statistic}>
        <div>{`${title}:`}</div>

        <div>{`${passed || 0} ${LOCAL.FROM} ${total || 0}`}</div>
    </div>
)

/**
 * Формирование строки для статистики
 * @param title заголовок
 * @param textStyle стили текста
 * @param value первое значение
 */
export const getProgressBlock = (
    title: string,
    textStyle: CSSProperties,
    value?: number
) => (
    <div className={styles.info} style={textStyle}>
        <div className={styles.count}>{value}</div>

        <div className={styles.title}>{title}</div>
    </div>
)
