const CATALOGS_TO_DELETE = {
    PA_ASSESSMENTS: 'Оценки',
    PA_ASSIGNED_ASSESSMENTS: 'Назначенные оценки',
    PA_CHECK_HOMEWORK: 'Проверка домашних заданий',
    PA_CHECK_QUIZZES: 'Проверка опросов',
    PA_FAVORITES: 'Избранное',
    PA_HOMEWORK: 'Мои домашние задания',
    PA_MEETINGS_RESULTS: 'Результаты очных встреч',
    PA_MONITORING: 'Мониторинг работы пользователей',
    PA_MY_DEVELOPMENT: 'Моё развитие',
    PA_PROFILE: 'Профайл',
    PA_QUIZZES: 'Опросы',
    USER_PERSONAL_AREA: 'Личный кабинет',
}

export const CATALOGS = {
    // TODO: исходя из аналитики в будущем данных роутов не будет (временная необходимость),
    // вместо них будут подставлены существующие
    ...CATALOGS_TO_DELETE,
    APPROVE_CREATE: 'Редактирование листа согласования',
    APPROVE_VIEW: 'Лист согласования',
    ASSESSMENTS: 'Оценки',
    ASSESSMENT_ASSIGNED: 'Экспертные оценки',
    ASSESSMENT_ASSIGNED_EVALUATION: 'Оценочный лист',
    ASSESSMENT_ASSIGNMENTS: {
        menuTitle: 'Назначения',
        pageTitle: 'Назначения оценки',
    },
    ASSESSMENT_ASSIGNMENTS_CREATE: 'Создание назначения оценки',
    ASSESSMENT_ASSIGNMENTS_EDIT: 'Редактирование назначения оценки',
    ASSESSMENT_ASSIGNMENTS_VIEW: 'Просмотр назначения оценки',
    ASSESSMENT_PORTFOLIOS: {
        menuTitle: 'Портфели',
        pageTitle: 'Портфели оценок',
    },
    ASSESSMENT_PORTFOLIOS_CREATE: 'Создание портфеля оценки',
    ASSESSMENT_PORTFOLIOS_EDIT: 'Редактирование портфеля оценки',
    ASSESSMENT_PORTFOLIOS_VIEW: 'Просмотр портфеля оценки',
    ASSESSMENT_PROJECTS: {
        menuTitle: 'Проекты',
        pageTitle: 'Оценочные проекты',
    },
    ASSESSMENT_PROJECTS_CREATE: 'Создание оценочного проекта',
    ASSESSMENT_PROJECTS_EDIT: 'Редактирование оценочного проекта',
    ASSESSMENT_PROJECTS_VIEW: 'Просмотр оценочного проекта',
    CERTIFICATES: 'Сертификаты',
    CIRCLE_ASSESSMENT_REPORTS: 'Сводный отчет по оценке 360/270/180',
    COMPETENCIES: 'Компетенции и индикаторы',
    COMPETENCIES_CREATE: 'Создание компетенции',
    COMPETENCIES_EDIT: 'Редактирование компетенции',
    COMPETENCIES_GROUPS: 'Группы компетенций',
    COMPETENCIES_INDICATORS: 'Индикаторы компетенций',
    COMPETENCIES_ORDER_OPTIONS:
        'Настройка порядка отображения компетенций и индикаторов',
    COMPETENCIES_VIEW: 'Просмотр компетенции',
    COWORKERS: 'Сотрудники',
    CRITERIA_SCALES: 'Шкалы',
    CRITERIA_SCALES_CREATE: 'Создание шкалы опроса',
    CRITERIA_SCALES_EDIT: 'Редактирование шкалы опроса',
    DEVELOPMENT_GOALS: 'Цели развития',
    DISTRIBUTIONS: 'Рассылки',
    DOCUMENTS_EDITOR: {
        menuTitle: 'Документы и задания',
        pageTitle: 'Реестр документов редактора',
    },
    ERROR_OCCURRED: 'Произошла ошибка',
    EVENTS: 'Мероприятия',
    EVENTS_ASSIGNMENTS: {
        menuTitle: 'Назначения',
        pageTitle: 'Назначения мероприятия',
    },
    EVENTS_ASSIGNMENTS_CREATE: 'Назначение мероприятия',
    EVENTS_ASSIGNMENTS_EDIT: 'Редактирование назначения мероприятия',
    EVENTS_ASSIGNMENTS_VIEW: 'Просмотр назначения мероприятия',
    EVENTS_CREATE: 'Создание мероприятия',
    EVENTS_EDIT: 'Редактирование мероприятия',
    EVENTS_PASSING: 'Прохождение мероприятия',
    EVENTS_VIEW: 'Просмотр мероприятия',
    EXTERNAL_CERTIFICATES: {
        pageTitle: 'Внешних сертификатов',
    },
    EXTERNAL_CERTIFICATES_CREATE: 'Создание внешнего сертификата',
    EXTERNAL_CERTIFICATES_EDIT: 'Редактирование внешнего сертификата',
    EXTERNAL_CERTIFICATES_VIEW: 'Просмотр внешнего сертификата',
    KNOWLEDGE_BASE: 'База знаний',
    KNOWLEDGE_BASE_CREATE: 'Создание статьи',
    KNOWLEDGE_BASE_EDIT: 'Редактирование статьи',
    KNOWLEDGE_BASE_VIEW: 'Просмотр статьи',
    MAILING: 'Реестр рассылок',
    MAILING_ASSIGNMENT: 'Задания на рассылку',
    MAILING_ASSIGNMENT_CREATE: 'Создание задания на рассылку',
    MAILING_ASSIGNMENT_EDIT: 'Редактирование задания на рассылку',
    MAILING_ASSIGNMENT_VIEW: 'Просмотр задания на рассылку',
    MAILING_VIEW: 'Просмотр рассылки',
    MGUU_TRACKS: 'Треки МГУУ',
    NOTIFICATIONS_SETTINGS: 'Настройка уведомлений',
    ORGANIZATIONS: 'Справочник организаций и должностей',
    POSITION_PROFILES: 'Профили должностей',
    POSITION_PROFILES_CREATE: 'Создание профиля должности',
    POSITION_PROFILES_EDIT: 'Редактирование профиля должности',
    POSITION_PROFILES_VIEW: 'Просмотр профиля должности',
    PRODUCT_CARD_VIEW: 'Информация о событии',
    PRODUCT_CATALOG: 'Карточки продуктов',
    PRODUCT_CATALOG_CREATE: 'Создание карточки продукта',
    PRODUCT_CATALOG_EDIT: 'Редактирование карточки продукта',
    PRODUCT_CATALOG_VIEW: 'Просмотр карточки продукта',
    QUESTIONNAIRES_LISTENER: 'Назначенные опросы',
    QUESTIONNAIRE_PASSING: 'Прохождение опроса',
    QUESTIONNAIRE_TEMPLATES: 'Опросные листы',
    QUESTIONNAIRE_TEMPLATES_CREATE: 'Создание опросного листа',
    QUESTIONNAIRE_TEMPLATES_EDIT: 'Редактирование опросного листа',
    QUESTIONNAIRE_TEMPLATES_VIEW: 'Просмотр опросного листа',
    QUIZ_ASSIGNMENTS: {
        menuTitle: 'Назначения',
        pageTitle: 'Назначения опросов',
    },
    QUIZ_ASSIGNMENTS_CREATE: 'Создание назначения опроса',
    QUIZ_ASSIGNMENTS_EDIT: 'Редактирование назначения опроса',
    QUIZ_ASSIGNMENTS_VIEW: 'Просмотр назначения опроса',
    QUIZ_PORTFOLIOS: {
        menuTitle: 'Портфели',
        pageTitle: 'Портфели опросов',
    },
    QUIZ_PORTFOLIOS_CREATE: 'Создание портфеля опроса',
    QUIZ_PORTFOLIOS_EDIT: 'Редактирование портфеля опроса',
    QUIZ_PORTFOLIOS_VIEW: 'Просмотр портфеля опроса',
    QUIZ_REPORT: 'Графики быстрой статистики',
    QUIZ_REPORT_FREE_ANSWERS: 'Сводная таблица свободных ответов',
    RECIPIENTS_MAIL: 'Адрес электронной почты получателя',
    REPORTS: 'Отчёты',
    REPORTS_SETTINGS: 'Настройка шаблона отчёта',
    SPENT_TIME_FOR_QUIZ_PASSING: 'Затраченное время на прохождение опроса',
    STAFF_UNITS: 'Штатные единицы',
    STRONG_SIDES_REPORTS: 'Сильные стороны и зоны развития',
    SUPERVISING_COMPLEXES: 'Комплексы',
    TAGS: 'Теги',
    TASKS: {
        menuTitle: 'Документы и задания',
        pageTitle: 'Реестр заданий',
    },
    TASKS_CREATE: 'Создание задания',
    TASKS_EDIT: 'Редактирование задания',
    TEMPLATES: 'Шаблоны',
    TEMPLATES_CREATE: 'Создание шаблона',
    TEMPLATES_EDIT: 'Редактирование шаблона',
    TEMPLATES_VIEW: 'Просмотр шаблона',
    TEST_ASSIGNMENTS: {
        menuTitle: 'Назначения',
        pageTitle: 'Назначения тестов',
    },
    TEST_ASSIGNMENTS_CREATE: 'Создание назначения теста',
    TEST_ASSIGNMENTS_EDIT: 'Редактирование назначения теста',
    TEST_ASSIGNMENTS_VIEW: 'Просмотр назначения теста',
    TEST_PORTFOLIOS: {
        menuTitle: 'Портфели',
        pageTitle: 'Портфели тестов',
    },
    TEST_PORTFOLIOS_CREATE: 'Создание портфеля теста',
    TEST_PORTFOLIOS_EDIT: 'Редактирование портфеля теста',
    TEST_PORTFOLIOS_VIEW: 'Просмотр портфеля теста',
    TEST_REPORT: 'Отчет по тесту',
    THEMATIC_SECTIONS: 'Тематические разделы',
    THEMATIC_SECTIONS_ORDER_OPTIONS:
        'Настройка порядка отображения тематических разделов',
    TRACKS_ADAPTATION: 'Треки адаптации',
    TRACKS_ADAPTATION_CREATE: 'Создание трека адаптации',
    TRACKS_ADAPTATION_EDIT: 'Редактирование трека адаптации',
    TRACKS_ADAPTATION_VIEW: 'Просмотр трека адаптации',
    TRACKS_ASSIGNMENTS: 'Назначения треков',
    TRACKS_ASSIGNMENTS_CREATE: 'Создание назначения трека',
    TRACKS_ASSIGNMENTS_EDIT: 'Редактирование назначения трека',
    TRACKS_ASSIGNMENTS_VIEW: 'Просмотр назначения трека',
    TRACKS_DEVELOPMENT: 'Треки развития',
    TRACKS_DEVELOPMENT_CREATE: 'Создание трека развития',
    TRACKS_DEVELOPMENT_EDIT: 'Редактирование трека развития',
    TRACKS_DEVELOPMENT_VIEW: 'Просмотр трека развития',
    TRAINING_MATERIALS: 'Обучающие материалы',
    TRAINING_MATERIALS_INFO: 'Информация о файле',
    USER_NOTIFICATIONS: 'Уведомления',
    USER_PROFILE: 'Профиль пользователя',
    WORK_IN_PROGRESS: 'Страница в разработке',
}
