import React, { useCallback, useEffect } from 'react'
import { ASSESSMENT_REPORTS_FORM_ID } from 'components/pages/AssessmentReportsSettings/AssessmentReportsSettings.consts'
import { AssessmentReportTemplatesService } from 'core/api'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { createConfirmPopup, getFormPopupTitle } from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import {
    AssessmentReportsFormProps,
    FormValuesProps,
} from './AssessmentReportsForm.types'
import { UrlParamsProps } from '../../AssessmentReportsSettings.types'
import {
    getAssessmentReportByType,
    getReportsTemplatesByAssessmentType,
} from './AssessmentReportsForm.utils'

export const AssessmentReportsForm: React.FC<AssessmentReportsFormProps> = React.memo(
    ({ onBlockUserRouting, updateLoader }) => {
        const history = useHistory()
        const [form] = Form.useForm()
        const { type } = useParams<UrlParamsProps>()

        const handleFinish = useCallback(
            async (values: FormValuesProps) => {
                try {
                    updateLoader(true)

                    await AssessmentReportTemplatesService.save({
                        body: Object.values(values).flat(),
                    })

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(ROUTE_NAMES.ASSESSMENT_REPORTS)
                        ),
                        content:
                            LOCAL.MESSAGES
                                .ASSESSMENT_REPORT_UPDATE_SUCCEESS_MESSAGE,
                        onOk: () => {
                            history.push(ROUTE_NAMES.ASSESSMENT_REPORTS)
                        },
                    })
                } catch (err) {
                    console.error(err)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader, history, onBlockUserRouting]
        )

        useEffect(() => {
            const fetchInitialValues = async () => {
                try {
                    updateLoader(true)

                    const templates = await AssessmentReportTemplatesService.getByType(
                        {
                            type,
                        }
                    )

                    form.setFieldsValue(
                        getReportsTemplatesByAssessmentType(templates, type)
                    )
                } catch (err) {
                    console.error(err)
                } finally {
                    updateLoader(false)
                }
            }

            fetchInitialValues()
        }, [form, updateLoader, type])

        return (
            <Form
                form={form}
                onFinish={handleFinish}
                id={ASSESSMENT_REPORTS_FORM_ID}
            >
                {getAssessmentReportByType(type)}
            </Form>
        )
    }
)
