import cn from 'classnames'
import React, { useCallback } from 'react'
import moment, { Moment } from 'moment'
import { DATE_FORMAT } from 'consts'
import { DatePicker } from 'antd'
import { LOCAL } from 'core/local'
import { getDayStartWithoutTime, getDayWithTime } from 'utils/date.utils'
import { getPopupContainer as getPopupContainerDefault } from 'utils/common.utils'
import { isFormModeView } from 'utils/conditions.utils'

import styles from './DatePickerControl.module.scss'
import { DatePickerControlProps } from './DatePickerControl.types'

/**
 * Декоратор для `DatePicker` от `antd`
 */
export const DatePickerControl: React.FC<DatePickerControlProps> = React.memo(
    ({
        value,
        format,
        showTime,
        onChange,
        className,
        placeholder,
        disabled,
        getPopupContainer = getPopupContainerDefault,
        formMode,
        picker,
        ...restProps
    }) => {
        const triggerChange = useCallback(
            (value: Moment | null) => {
                let dateString
                if (value) {
                    dateString = showTime
                        ? getDayWithTime(value)
                        : getDayStartWithoutTime(value)
                }

                onChange?.(value ? dateString : value)
            },
            [showTime, onChange]
        )

        const classes = cn(
            styles.wrapper,
            isFormModeView(formMode) && 'view-mode',
            'form-control'
        )

        return (
            <div className={classes}>
                <DatePicker
                    format={format || DATE_FORMAT.LIST}
                    onChange={triggerChange}
                    value={value ? moment(value) : undefined}
                    showTime={showTime}
                    placeholder={
                        isFormModeView(formMode)
                            ? LOCAL.PLACEHOLDERS.INPUT_EMPTY
                            : placeholder
                    }
                    disabled={disabled || isFormModeView(formMode)}
                    getPopupContainer={getPopupContainer}
                    picker={picker}
                    {...restProps}
                />
            </div>
        )
    }
)
