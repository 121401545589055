import React, { useCallback } from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import {
    HomeworkCheckSearchContract,
    HomeworkRegistryItemContract,
    HomeworkStatusType,
    HomeworksService,
    SortDirection,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getPageTitleFromRoute } from 'utils'
import { getRowClassNameExpired } from 'components/shared'
import { useHistory, useLocation } from 'react-router-dom'
import { useToolbarGutterResponsive } from 'components/layouts/DictionaryPage/DictionaryPage.hooks'

import { CheckHomeworkFilters } from './components/CheckHomeworkFilters'
import { getTableColumnsConfig } from './CheckHomework.config'
import { renderToolbarContent } from './CheckHomework.utils'

/**
 * Личный кабинет пользователя: проверка домашних заданий
 */
export const CheckHomework: React.FC = React.memo(() => {
    const gutterResponsive = useToolbarGutterResponsive()

    const history = useHistory()
    const location = useLocation()

    const handleOpenHomework = useCallback(async () => {
        const result = await HomeworksService.startCheckLast()

        if (result.ldeId) {
            history.push(`${ROUTE_NAMES.PA_CHECK_HOMEWORK}/lde/${result.id}`)
        } else {
            history.push(`${ROUTE_NAMES.PA_CHECK_HOMEWORK}/${result.id}`)
        }
    }, [history])

    return (
        <DictionaryPage<
            HomeworkCheckSearchContract,
            HomeworkRegistryItemContract
        >
            dictionaryKey={DICTIONARY_KEYS.CHECK_HOMEWORK}
            searchMethod={HomeworksService.homeworksSearch}
            contentTitle={getPageTitleFromRoute(location.pathname)}
            contentTitleType="h5"
            tableColumnsRender={getTableColumnsConfig}
            quickSearchMethod={HomeworksService.homeworksSearchText}
            toolbarContentRender={renderToolbarContent({
                gutterResponsive,
                handleOpenHomework,
            })}
            filterComponent={CheckHomeworkFilters}
            rowClassName={getRowClassNameExpired}
            tableFiltersOptions={{
                initialValues: {
                    statuses: [HomeworkStatusType.OnChecking],
                    orderRules: [
                        {
                            field: 'creationDate',
                            direction: SortDirection.Desc,
                        },
                    ],
                },
            }}
            withPageBanner={false}
        />
    )
})
