import React from 'react'
import { LOCAL } from 'core/local'
import { Title } from 'components/shared'

import styles from './ProductCardTasks.module.scss'
import { ProductCardCarousel } from '../ProductCardCarousel'
import { ProductCardTasksProps } from './ProductCardTasks.types'
import { SLIDES_TO_SHOW } from './ProductCardTasks.consts'
import { renderBlockWithCheckIcon } from '../ProductCardCarousel/ProductCardCarousel.utils'

/** Карточка продукта - блок "задачи, которые решает программа" */
export const ProductCardTasks: React.FC<ProductCardTasksProps> = React.memo(
    ({ tasks }) => (
        <div className={styles.wrapper}>
            <Title
                type="h1"
                text={LOCAL.LABELS.TASKS_THAT_PROGRAM_SOLVES}
                className={styles.title}
            />

            <ProductCardCarousel
                slidesToShow={SLIDES_TO_SHOW}
                itemsCount={tasks.length}
            >
                {tasks.map(renderBlockWithCheckIcon(styles))}
            </ProductCardCarousel>
        </div>
    )
)
