import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import { API_BASE_URL } from 'consts/env.consts'
import { ColorThemes } from 'contexts'
import { DataNode } from 'rc-tree-select/lib/interface'
import { FnActionProps, Store } from 'App.types'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { MouseEvent, ReactText } from 'react'
import { ROUTES_POSTFIXES } from 'routing/routeNames.consts'
import { Resolutions } from 'core/configs/resolutions.config'
import { getRouteMeta } from 'routing/routeNames.utils'
import { matchPath } from 'react-router-dom'
import { message } from 'antd'
import { sanitize } from 'dompurify'

import {
    AppointmentUserContract,
    UserContract,
    UserModalContract,
} from '../core/api'
import { getFullFio } from './formattingUserName.utils'

/**
 * Интерфейс для `showMessageWithDelayAfter`
 * @param messageText - текс сообщения
 * @param messageType - тип сообщения
 * @param delay - задержка в миллисекундах
 * @param onRequestFinish - функция, которую нужно выполнить перед выводом сообщения
 */
interface ShowMessageWithDelayAfterProps {
    messageText?: string
    messageType?: 'info' | 'success' | 'error'
    delay?: number
    onRequestFinish?: FnActionProps
}

/**
 * Показать сообщение с задержкой после сообщения
 * @param onRequestFinish - callback функция
 * @param messageText - текст сообщения
 * @param messageType - тип сообещния
 * @param delay - время задержки
 */
export const showMessageWithDelayAfter = ({
    onRequestFinish,
    messageText = '',
    messageType = 'success',
    delay = 2000,
}: ShowMessageWithDelayAfterProps = {}) =>
    new Promise<void>((resolve) => {
        if (onRequestFinish) onRequestFinish()
        message[messageType](messageText)

        setTimeout(() => {
            resolve()
        }, delay)
    })

/**
 * Проверить объект на пустоту, возвращает `true` пустой
 * @param obj - объект
 * @param strictMode - строгий режим, если значение `true` и все свойства объекта `undefined`
 *     вернет `true`
 * @param filterFn - функция фильтрации, для `strictMode`, если не передается, берется `Boolean`
 */
export const isObjectEmpty = (
    obj?: Store,
    strictMode?: boolean,
    filterFn?: (el: ReactText) => boolean
) => {
    if (!obj) return true
    if (strictMode)
        return !Object.values(obj).filter(filterFn || Boolean).length

    return !Object.keys(obj).length
}

/**
 * Проверить объект на пустоту, возвращает `true` пустой
 * @param object - объект
 */
export const isEmptyObject = (object: any) =>
    Object.values(object).filter((item) => !!item).length === 0

/**
 * Проверить рекурсивно объект на пустоту с учетом вложенности
 * @param value - объект или значение свойства объекта
 */
export const isObjectEmptyDeep = (value?: Store): boolean => {
    if (typeof value !== 'object' || value === null) return !value

    return Object.values(value).every(isObjectEmptyDeep)
}

/**
 * Проверить массив объектов на пустые объекты, возвращает `true` если все элементы массива -
 * пустые объекты
 * @param arr - массив объектов
 */
export const isObjectArrayEmpty = <T extends object>(arr?: T[]) =>
    arr?.every((el) => isObjectEmpty(el, true))

/**
 * Проверить массив на пустые значения
 * @param arr - массив
 */
export const isArrayEmpty = <T>(arr?: T[]) => {
    const tempArr = arr?.filter(Boolean)

    return !tempArr?.length
}

/**
 * Возращает пустой массив, если массив состоит из ложных значений либо входящий массив
 * @param arr - массив
 */
export const getNotFalsyArray = <T>(arr?: T[]) =>
    isArrayEmpty(arr) ? [] : (arr as T[])

/**
 * Функция для получения массива из уникальных примитивов
 * @param items - массив примитивов
 * @returns {ReactText[]} - массив из уникальных примитивов
 */
export const getUniqueItemsArray = <T extends ReactText>(items: T[]) =>
    Array.from(new Set(items))

/**
 * Функция для удаления всех дублирующихся объектов в массиве
 * @param arr - массив объектов
 */
export const getUniqueObjectsArray = (arr?: Array<Store>) => {
    if (!arr) return

    const set = new Set(arr.map((el) => JSON.stringify(el)))

    return Array.from(set).map((el) => JSON.parse(el))
}

/**
 * Получить округленное число
 * @param num - число
 */
export const getRoundedNumber = (num?: number) => {
    if (!num) return

    return Math.round(num)
}

/**
 * Получить число, округленное до одного знака после запятой
 * @param num - число
 * @param withNull - флаг отображения нуля после запятой, если true - число будет вида: "1.0"
 */
export const getRoundedOneCharNumber = (num?: number, withNull?: boolean) => {
    if (isNil(num)) return 0
    if (withNull) return num.toFixed(1)

    return Number(num.toFixed(1).replace('.0', ''))
}

/**
 * Составить route без параметров
 * @param path - текущий путь
 * @param params - объект параметров url
 * @param excludeRoutePrefixes - исключить префиксы, необходим в случае, если мы хотим получить информацию по основному
 *     маршруту, без префикса (create/edit и т.д.)
 * @returns строка маршрут
 */
export function composeRouteWithoutParams(
    path: string,
    params?: Store,
    excludeRoutePrefixes?: boolean
): string {
    const urlParams = Object.values(params || {})
    let key = path.split('/').filter((el) => !urlParams.includes(el))

    if (excludeRoutePrefixes) {
        key = key.filter((el) => !Object.values(ROUTES_POSTFIXES).includes(el))
    }

    return key.join('/')
}

/**
 * Получить заголовок страницы из роута
 * @param path - текущий путь
 * @param params - объект параметров url
 * @param excludeRoutePrefixes - исключить префиксы, необходим в случае, если мы хотим получить информацию по основному
 *     маршруту, без префикса (create/edit и т.д.)
 * @returns строка/заголовок стр.
 */
export function getPageTitleFromRoute(
    path: string,
    params?: Store,
    excludeRoutePrefixes?: boolean
): string {
    const route = composeRouteWithoutParams(path, params, excludeRoutePrefixes)

    return getRouteMeta(route, 'pageTitle') || ''
}

/**
 * Получить заголовок для backLink
 * @param path - текущий путь
 * @param params - объект параметров url
 * @returns строка/заголовок стр.
 */
export function getBackLinkTitleFromRoute(
    path: string,
    params?: Store
): string {
    return `${LOCAL.LINKS.PREFIX} "${getPageTitleFromRoute(
        path,
        params,
        true
    )}"`
}

/**
 * Получить строку без точек
 * @param str - строка форматов документов
 */
export const getStringWithoutDots = (str: string) => trim(str, '.')

/**
 * Получить строку из массива строк
 * @param {string[]} arr - массив строк
 * @param {string} separator - разделитель
 * @return {string} строка с разделителем
 */
export function renderStringArrayWithSeparator(
    arr?: string[],
    separator: string = ', '
): string | undefined {
    return arr?.join(separator)
}

/**
 * Получить число из строки
 * @param {string} str - строка
 * @return {number} число
 */
export function getNumFromStr(str: string): number {
    return Number(str.replace(/\D/g, ''))
}

/**
 * Получить подготовленное для вывода в UI значение из числа
 * @param num - число для форматирования
 * @example 10965 => "10 965"
 * @return {string} - подготовленное значение
 */
export const getNumFormattedToView = (num?: number): string => {
    const roundedNum = getRoundedNumber(num)

    if (!roundedNum) return '0'

    const charsArr = String(roundedNum).split('')

    return charsArr
        .reverse()
        .reduce<string[]>(
            (acc, el, index) =>
                index % 3 === 0 ? [...acc, ' ', el] : [...acc, el],
            []
        )
        .reverse()
        .join('')
        .trim()
}

/**
 * Получить единицы измерения в формате: 40B, 3.3KB, 22MB и т.д.
 * @param bytes - значение в байтах
 * @param decimals - количество знаков после запятой
 */
export const getFormattedBytes = (bytes?: number, decimals = 2) => {
    if (!bytes) return '0 B'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const exponent = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, exponent)).toFixed(dm))} ${
        sizes[exponent]
    }`
}

/**
 * Рендер поля `Должность` для пользователя
 * @param user - пользователь
 */
export const renderPositionName = (user?: UserContract | UserModalContract) =>
    renderStringArrayWithSeparator(
        user?.structuralUnitsAndPositions?.map((el) => el.position?.name)
    )

/**
 * Рендер поля `Организация` для пользователя
 * @param user - пользователь
 */
export const renderOrganizationName = (
    user?: UserContract | UserModalContract
) =>
    renderStringArrayWithSeparator(
        user?.structuralUnitsAndPositions?.map((el) => el.structuralUnit?.name)
    )

/**
 * Получить элемент из списка
 * @param arr массив файлов
 * @param searchedId идентификатор выбранного элемента
 */
export const getElemById = <T extends { id: number }>(
    arr: T[],
    searchedId: number
): T | undefined => arr.find((el) => el.id === searchedId)

/**
 * Функция, которая возвращает противоположное значение состояния
 * @param state - состояние
 */
export const backwardFn = (state?: boolean) => !state

/** Открыть пдф-файл в новой вкладке браузера
 * @param pdf - файл
 */
export const openPdfFileInNewTab = (pdf: Blob) => {
    const file = new Blob([pdf], {
        type: 'application/pdf',
    })
    const fileURL = URL.createObjectURL(file)

    window.open(fileURL)
}

/** Получить url-параметр id либо пустую строку */
export const idToUrlParam = (id?: string | number) => (id ? `/${id}` : '')

/** Функция для генерации uuid */
export const uuidv4 = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8

        return v.toString(16)
    })

/**
 * Получить идентификатор элемента
 * @param item объект с полем id
 */
export const getItemId = <T extends { id: T['id'] }>(item: T) => item.id

/**
 * Получить наименование элемента
 * @param item объект с полем name
 */
export const getItemName = <T extends { name: T['name'] }>(item: T) =>
    item?.name ?? ''

/**
 * Получить значение поля формы
 * @param field объект с полем value
 */
export const getValue = (field?: LabeledValue | DataNode) => field?.value

/**
 * Получить значения краткой информации о пользователе
 * @param user объект пользователя
 */
export const getUserShortInfo = (user: AppointmentUserContract) => ({
    id: user.id,
    name: getFullFio(user),
    positions: user.position?.name,
    organizations: user.organization?.name,
})

/**
 * Открыть страницу запуска курса
 */
export const launchCourse = (launchUrl: string) => {
    window.open(`${API_BASE_URL}${launchUrl}`, '_blank')
}

/**
 * Если условие выполняется, возвращаем массив с переданным элементом, в ином случае — пустой массив
 * @param condition условие
 * @param element элемент
 */
export const getConditionalArray = <T>(condition: any, element: T) =>
    condition ? [element] : []

/**
 * Получить массив с типом DictionaryContract из массива строк
 */
export const mapToDictionaryFromStrArr = (arr?: string[]) =>
    arr?.map((str, index) => ({
        id: index,
        name: str,
    }))
/**
 * Безопасно вставить html
 */
export const safelySetInnerHtml = (html: string): { __html: string } => ({
    __html: sanitize(html),
})

/** Получить продолжительность в формате:
 * @example 1 мин.
 * @example 2 ч.
 * */
export const getDurationWithLabel = (unit: string, num?: number) => {
    if (!num) return ''

    return `${num} ${getStringWithoutDots(unit.toLowerCase())}.`
}

/** Получить продолжительность в формате:
 * @example 2ч. 30мин.
 */
export const getFormattedDuration = (hours?: number, minutes?: number) =>
    `${getDurationWithLabel(LOCAL.HOURS, hours)}${
        hours && minutes ? ' ' : ''
    }${getDurationWithLabel(LOCAL.MIN, minutes)}`

/** Прекратить всплытие события */
export const stopEventPropagation = <T>(e: MouseEvent<T>) => {
    e.stopPropagation()
}

interface GetBannerBackgroundCssProps {
    theme: ColorThemes
    url?: string
    gradient?: {
        light: string
        dark: string
    }
}

const LINEAR_GRADIENT_THEMES = {
    light:
        'linear-gradient(10.68deg, #FAFAFA 14.91%, rgba(250, 250, 250, 0) 100%), linear-gradient(90deg, #FAFAFA 14.97%, rgba(250, 250, 250, 0.9) 37.6%, rgba(250, 250, 250, 0.7) 60.3%, rgba(250, 250, 250, 0) 85.96%)',
    dark:
        'linear-gradient(10.68deg, #192A3E 14.91%, rgba(25, 42, 62, 0) 100%), linear-gradient(90deg, #192A3E 14.97%, rgba(25, 42, 62, 0.9) 37.6%, rgba(25, 42, 62, 0.7) 60.3%, rgba(25, 42, 62, 0) 85.96%)',
}

/** Получить ксс свойство для фона страницы с градиентом
 * @param theme текущая тема
 * @param url ссылка на картинку для фона
 * @param gradient градиент для цветовых схем приложения
 */
export const getBannerBackgroundCssProp = ({
    url,
    theme,
    gradient = LINEAR_GRADIENT_THEMES,
}: GetBannerBackgroundCssProps): { background?: string } => {
    if (!url) return {}

    return {
        background: `${gradient[theme]}, url(${url}) no-repeat center / cover`,
    }
}
/** Скачать файл с помощью url, задав имя файла
 * @param url - url
 * @param name - имя файла
 */
export const downloadFileViaUrl = (url: string, name: string) => {
    const a = document.createElement('a')

    a.style.display = 'none'
    a.href = url
    a.download = name

    document.body.appendChild(a)

    a.click()
    a.remove()
}

/** Скачать файл с помощью blob, задав имя и тип файла
 * @param data - blob данные
 * @param name - имя файла
 * @param mime - MIME тип файла
 */
export const downloadFileViaBlob = (
    data: BlobPart[],
    mime: string,
    name: string
) => {
    const url = window.URL.createObjectURL(new Blob(data, { type: mime }))

    downloadFileViaUrl(url, name)

    window.URL.revokeObjectURL(url)
}

export const calcResolution = (width: number, resolutions: string[]) =>
    resolutions.reduce<number>((acc, res, index) => {
        const currentPoint = +res
        const nextPoint: string | undefined = resolutions[index + 1]

        const isWidthLessThanNextPoint = nextPoint && width < +nextPoint

        if (
            width >= currentPoint &&
            (isWidthLessThanNextPoint || nextPoint === undefined)
        ) {
            return currentPoint
        }

        return acc
    }, 0)

export const getResolution = (width: number, restrictions?: string[]) => {
    const values = Object.values(Resolutions)

    const resolution = calcResolution(width, values)

    restrictions?.sort((prev, next) => Number(prev) - Number(next))

    if (restrictions?.every((res) => width < +res)) {
        return restrictions[0]
    }

    if (restrictions) {
        return String(calcResolution(resolution, restrictions))
    }

    return String(resolution)
}

export const cloneDeepViaJson = <T extends object>(obj?: T) =>
    JSON.parse(JSON.stringify(obj))

export const getPopupContainer = (triggerNode?: HTMLElement) =>
    triggerNode?.parentElement || document.body

export const insertCharBeforeWord = (
    str: string,
    word: number | string,
    char: string
) => {
    const arr = str.split(' ')
    const index =
        typeof word === 'number'
            ? Number(word)
            : arr.findIndex((el) => el === word)

    if (index < 0 || index > arr.length) return str

    arr.splice(index, 0, char)

    return arr.join(' ')
}

export const getIdsArray = (value?: { id: number }[]) =>
    value?.map(({ id }) => id) || []

export const matchCurrentPath = <UrlParams = any>(
    path: string,
    withMatchObj = false
) => {
    const match = matchPath<UrlParams>(window.location.pathname, {
        path,
        exact: false,
        strict: false,
    })

    if (withMatchObj) return match

    return match?.isExact
}

/** Синхронный таймаут*/
export const sleep = (milliseconds: number) => {
    const date = Date.now()
    let currentDate = null

    do {
        currentDate = Date.now()
    } while (currentDate - date < milliseconds)
}

export const showElem = (
    visible?: boolean,
    visibleDisplayClass:
        | 'd-inline'
        | 'd-block'
        | 'd-iblock'
        | 'd-flex'
        | 'd-iflex'
        | 'd-grid'
        | 'd-table' = 'd-block'
) => (visible ? visibleDisplayClass : 'd-none')

interface GetShowMoreTextProps {
    total: number
    pageSize: number
    loadedEntitiesCount: number
}

export const getShowMoreText = (state: GetShowMoreTextProps) => {
    if (!state) return

    const remain = state.total - state.loadedEntitiesCount

    if (!remain) return

    const size = state.pageSize < remain ? state.pageSize : remain

    return `${LOCAL.ACTIONS.SHOW_MORE} ${size} ${LOCAL.FROM} ${remain}`
}

export const getUrlWithoutPostfixes = (str?: string) =>
    str?.replace(/(\/(edit|view|create|copy))?(\/(\d+))*$/, '')

export const objToQueryStr = (obj?: Store) => {
    if (!obj || isEmpty(obj)) return ''

    return `?${new URLSearchParams(Object.entries(obj)).toString()}`
}

export const queryStrToObj = <T>(search?: string): T | undefined => {
    if (!search) return

    const obj = search
        .substring(1)
        .split('&')
        .map((el) => el.split('='))

    return Object.fromEntries(obj)
}
