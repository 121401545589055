import { eventEmitter } from 'core/helpers/eventEmitter'
import { makeStyleObject } from 'utils'

import stylesTrackStageDnd from '../TrackStageDnD/TrackStageDnD.module.scss'
import { DnDConfigProps } from './StagesDndArea.types'
import { STAGE_STYLE_OPTIONS_IN_MOTION } from './StagesDndArea.consts'
import {
    TRACK_STAGE_CLASS_DRAGGING_NOW,
    TRACK_STAGE_CLASS_NOT_DROPPED,
} from '../TrackStageDnD/TrackStageDnD.consts'

export const TRACK_STAGE_DND_EVENTS = {
    DRAG_START: 'DRAG_START',
    DRAG_FINISH: 'DRAG_FINISH',
    REMOVE_STAGE: 'REMOVE_STAGE',
}

export class TrackStageDndHelper {
    private static _config: DnDConfigProps

    static init(config: DnDConfigProps) {
        this._config = config

        this.handleMouseDown = this.handleMouseDown.bind(this)
        this.handleMouseMove = this.handleMouseMove.bind(this)
        this.handleMouseUp = this.handleMouseUp.bind(this)
    }

    /**
     * Собрать JSON стилей для этапа
     * @param styles стили
     */
    static composeStageStyleJSON(styles?: string | null) {
        if (!styles) return ''

        const stylesObj = makeStyleObject(styles)

        if (!stylesObj) return ''

        return JSON.stringify({
            left: stylesObj.left,
            top: stylesObj.top,
        })
    }

    /**
     * Переместить выбранный элемент в новые координаты
     * @param clientX координаты по оси X
     * @param clientY координаты по оси Y
     */
    static moveAt(clientX: number, clientY: number) {
        const { dragElementRef, shiftCoordinates } = this._config

        const dragElement = dragElementRef.current

        if (!dragElement) {
            return
        }

        const [shiftX, shiftY] = shiftCoordinates.current

        const newX = clientX - shiftX
        const newY = clientY - shiftY
        dragElement.style.left = `${newX}px`
        dragElement.style.top = `${newY}px`
    }

    /**
     * Проверить позицию перетаскиваемого элемента
     * @param event объект события мыши
     */
    static checkDraggableElementPosition(event: MouseEvent) {
        const { dragElementRef, isDroppable } = this._config

        const dragElement = dragElementRef.current

        if (!dragElement) {
            return
        }

        dragElement.hidden = true
        const elemBelow = document.elementFromPoint(
            event.clientX,
            event.clientY
        )
        dragElement.hidden = false

        if (!elemBelow) return

        const droppable = elemBelow.closest(
            `.droppable-${this._config.currentCanvasKey}`
        )

        isDroppable.current = !!droppable
    }

    /**
     * Обработчик, срабатывает при отпускании зажатой КМ
     */
    static handleMouseUp(event: MouseEvent) {
        this.checkDraggableElementPosition(event)
        this.finishDrag()
    }

    /**
     * Обработчик движения мыши
     * @param event объект события
     */
    static handleMouseMove(event: MouseEvent) {
        this.moveAt(event.clientX, event.clientY)
        this.checkDraggableElementPosition(event)
    }

    /**
     * Отпустить перетаскиваемый элемент, закрепить его относительно документа (абсолютные
     * координаты)
     */
    static finishDrag() {
        const {
            isDragging,
            isDroppable,
            dragElementRef,
            droppableElem,
            form,
            onChangeStagesStyles,
        } = this._config

        if (!this._config.currentCanvasKey) return

        const droppableElemPosition = droppableElem?.getBoundingClientRect()
        const dragElement = dragElementRef.current

        if (!isDragging.current || !dragElement || !droppableElemPosition) {
            return
        }

        setTimeout(() => {
            dragElement.classList.remove(TRACK_STAGE_CLASS_DRAGGING_NOW)
        }, 0)

        isDragging.current = false

        const dropTopPositionRelativeToWindow = parseInt(dragElement.style.top)
        const dropLeftPositionRelativeToWindow = parseInt(
            dragElement.style.left
        )
        const dropTopPositionRelativeToDroppableElem =
            dropTopPositionRelativeToWindow - droppableElemPosition.top
        const dropLeftPositionRelativeToDroppableElem =
            dropLeftPositionRelativeToWindow - droppableElemPosition.left

        document.removeEventListener('mousemove', this.handleMouseMove)
        dragElement.removeEventListener('mouseup', this.handleMouseUp)

        const droppable = document.querySelector(
            `.droppable-${this._config.currentCanvasKey}`
        )
        const dragElementId = dragElement.dataset.id

        if (!droppable) return
        if (!dragElementId) return

        const { trackStageStyles } = form?.getFieldValue('trackCanvases')[
            this._config.currentCanvasKey
        ]

        if (!isDroppable.current) {
            const parentNode = document.querySelector(
                `[data-id="${dragElementId}"]`
            )

            parentNode?.append(dragElement)
            dragElement.removeAttribute('style')
            dragElement.classList.add(TRACK_STAGE_CLASS_NOT_DROPPED)
        } else {
            dragElement.style.top = `${dropTopPositionRelativeToDroppableElem}px`
            dragElement.style.left = `${dropLeftPositionRelativeToDroppableElem}px`
            dragElement.style.position = 'absolute'
            dragElement.classList.remove(TRACK_STAGE_CLASS_NOT_DROPPED)

            droppable.appendChild(dragElement)
        }

        const [elemId] = dragElementId.split('-')

        trackStageStyles[elemId] = {
            ...trackStageStyles[elemId],
            positionStyles: this.composeStageStyleJSON(
                dragElement.getAttribute('style')
            ),
        }

        onChangeStagesStyles?.(trackStageStyles)
        eventEmitter.emit(
            TRACK_STAGE_DND_EVENTS.DRAG_FINISH,
            form?.getFieldsValue()
        )
    }

    /**
     * Захватить элемент, поменять позиционирование на `position:fixed`
     * @param clientX координаты по оси X
     * @param clientY координаты по оси Y
     */
    static startDrag(clientX: number, clientY: number) {
        const { isDragging, dragElementRef, shiftCoordinates } = this._config

        const dragElement = dragElementRef.current

        if (isDragging.current || !dragElement) {
            return
        }

        isDragging.current = true

        document.addEventListener('mousemove', this.handleMouseMove)
        dragElement.addEventListener('mouseup', this.handleMouseUp)

        shiftCoordinates.current = [
            clientX - dragElement.getBoundingClientRect().left,
            clientY - dragElement.getBoundingClientRect().top,
        ]

        dragElement.style.position = STAGE_STYLE_OPTIONS_IN_MOTION.position
        dragElement.style.zIndex = STAGE_STYLE_OPTIONS_IN_MOTION.zIndex

        this.moveAt(clientX, clientY)
    }

    /**
     * Обработчик зажатия кнопки мыши
     */
    static handleMouseDown(event: MouseEvent) {
        const { isDroppable, dragElementRef } = this._config

        const targetElement = event.target as HTMLElement
        const draggableIcon = targetElement.closest('.draggable')

        if (!draggableIcon) return

        const draggableIconStage = draggableIcon.closest(
            `.${stylesTrackStageDnd.wrapperOriginal}`
        ) as HTMLElement

        dragElementRef.current = draggableIconStage

        const dragElement = dragElementRef.current

        dragElement.classList.add(TRACK_STAGE_CLASS_DRAGGING_NOW)

        isDroppable.current = false

        if (!dragElement) return

        event.preventDefault()

        dragElement.ondragstart = function () {
            return false
        }

        this.startDrag(event.clientX, event.clientY)
        eventEmitter.emit(TRACK_STAGE_DND_EVENTS.DRAG_START)
    }
}
