import {
    OrganizationInfoContract,
    StaffUnitInfoContract,
    StaffUnitSearchResultDepartmentItemContract,
    StaffUnitSearchResultOrganizationItemContract,
} from 'core/api'

import { NormalizeStructuralUnitPositionsResultProps } from '../Organizations/Organizations.types'

/**
 * Нормализация данных для `positions`
 * @param unit структурное подразделение
 */
const normalizePositions = (unit: OrganizationInfoContract) => (
    position: StaffUnitInfoContract
) => ({
    name: position.positionName,
    active: position.active,
    positionProfile: position.hasPositionProfile,
    staffUnitId: position.staffUnitId,
    // Делаем уникальный id для должности, чтобы не возникало багов с одинаковыми ключами
    id: unit.id + position.positionId + position.staffUnitId,
    positionId: position.positionId,
    positionUnitType: unit.type,
    positionUnitId: unit.id,
})

/**
 * Нормализация данных `departments`
 * @param el объект структурной единицы
 */
export const normalizeDepartments = (
    el: StaffUnitSearchResultDepartmentItemContract
): NormalizeStructuralUnitPositionsResultProps => {
    const positions = el.positions.map(normalizePositions(el.department))

    const subUnits = el.departments.map((subUnit) => ({
        name: subUnit.department.name,
        id: subUnit.department.id,
        unitType: subUnit.department.type,
        ...(subUnit.departments.length || subUnit.positions.length
            ? {
                  children: [
                      ...subUnit.positions.map(
                          normalizePositions(subUnit.department)
                      ),
                      ...subUnit.departments.map((el) =>
                          normalizeDepartments(el)
                      ),
                  ],
              }
            : {}),
    }))

    const children = [...positions, ...subUnits]

    return {
        name: el.department.name,
        id: el.department.id,
        unitType: el.department.type,
        ...(children.length ? { children } : {}),
    }
}

/**
 * Нормализация данных `staff unit`
 * @param el объект структурной единицы
 */
export const normalizeStaffUnits = (
    el: StaffUnitSearchResultOrganizationItemContract
): NormalizeStructuralUnitPositionsResultProps => {
    const positions = el.positions.map(normalizePositions(el.organization))

    const departments = el.departments.map(normalizeDepartments)

    const subUnits = el.organizations.map((subUnit) => ({
        name: subUnit.organization.name,
        id: subUnit.organization.id,
        unitType: subUnit.organization.type,
        ...(subUnit.organizations.length ||
        subUnit.positions.length ||
        subUnit.departments.length
            ? {
                  children: [
                      ...subUnit.positions.map(
                          normalizePositions(subUnit.organization)
                      ),
                      ...subUnit.organizations.map(normalizeStaffUnits),
                      ...subUnit.departments.map(normalizeDepartments),
                  ],
              }
            : {}),
    }))

    const children = [...positions, ...subUnits, ...departments]

    return {
        name: el.organization.name,
        id: el.organization.id,
        unitType: el.organization.type,
        ...(children.length ? { children } : {}),
    }
}
