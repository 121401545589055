import unescape from 'lodash/unescape'

import { TAG_VALUE_ATTRIBUTE } from './TextRedactor.consts'

/**
 * Получить теги из поля редактора
 * @param editor dom узел редактора
 */
export const getTagsFromEditor = (editor?: HTMLDivElement) => {
    if (!editor) return []

    const allTagsNodes = editor.querySelectorAll('input[id]')
    const result: [number, string][] = []

    allTagsNodes.forEach((el) => {
        const value = (el as HTMLInputElement).value
        const id = el.getAttribute('id')

        if (value && id) result.push([Number(value), id])
    })

    result.sort(
        ([firstArrAnswerNumber], [secondArrAnswerNumber]) =>
            firstArrAnswerNumber - secondArrAnswerNumber
    )

    return result.map(([_, answerId]) => answerId)
}

/**
 * Пересчитать номера ответов, вызывается при удалении из текста тега ответа
 * @param editor node узел редактора
 * @param deletedElemIndex индекс удаляемого тега
 */
export const recalculateAnswersNumbers = (
    editor?: HTMLDivElement,
    deletedElemIndex = 0
) => {
    if (!editor) return []

    const allTagsNodes = editor.querySelectorAll('input[id]')

    allTagsNodes.forEach((el) => {
        const tag = el as HTMLInputElement
        const counter = String(Number(tag.value) - 1)

        if (Number(tag.value) > deletedElemIndex) {
            tag.value = counter
            tag.setAttribute(TAG_VALUE_ATTRIBUTE, counter)
        }
    })
}

/**
 * Реплейс для текста с бэка при редактировании
 * @param text - текст
 */
export const getQuestionTextForEdit = (text?: string) => {
    const tempHTML = unescape(text)
    const regexp = new RegExp(
        `id="(.{9,12})".+?${TAG_VALUE_ATTRIBUTE}="(\\d*)"`,
        'g'
    )

    const result = tempHTML?.replace(
        regexp,
        (tagAttributesStr, ...[_, value]) =>
            `${tagAttributesStr} value="${value}"`
    )

    return result || ''
}

/**
 * Проверка, является ли элемент dom узлом или содержит их
 * @param ancestor родительский dom узел
 * @param descendant дочерний dom узел
 */
export const isOrContainsNode = (
    ancestor: HTMLDivElement,
    descendant: Node
) => {
    let node = descendant

    while (node) {
        if (node === ancestor) return true
        if (node.parentNode) {
            node = node.parentNode
        }
    }

    return false
}

/**
 * Вставить dom узел (тег) в редактируемую область, в место курсора
 * @param nodeForInster dom узел для вставки
 * @param containerNode dom узел редактируемой области
 */
export const insertNodeOverSelection = (
    nodeForInster: HTMLInputElement,
    containerNode: HTMLDivElement
) => {
    if (!window.getSelection) return

    const sel = window.getSelection()

    if (!sel?.getRangeAt || !sel?.rangeCount) return

    const range = sel.getRangeAt(0)

    if (isOrContainsNode(containerNode, range.commonAncestorContainer)) {
        range.deleteContents()
        range.insertNode(nodeForInster)
    } else {
        containerNode.appendChild(nodeForInster)
    }

    sel.removeAllRanges()
}
