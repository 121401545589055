import cn from 'classnames'
import difference from 'lodash/difference'
import get from 'lodash/get'
import intersectionWith from 'lodash/intersectionWith'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import shortid from 'shortid'
import template from 'lodash/template'
import React, { ReactText } from 'react'
import { AddButton } from 'components/shared/AddButton'
import {
    AttachmentContract,
    CourseSearchResultContract,
    DictionaryContract,
    EntityEditSessionService,
    EntityType,
    EnumContract,
    PictureContract,
    StructuralUnitContract,
    StructuralUnitType,
    UserPermission,
} from 'core/api'
import { DataNode } from 'rc-tree-select/lib/interface'
import { Divider, message, notification } from 'antd'
import {
    FnActionProps,
    FormMode,
    Store,
    WithFormInstanceProps,
    WithFormModeProps,
} from 'App.types'
import { HasPermissions } from 'core/permissions/HasPermissions'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { InternalNamePath, NamePath } from 'antd/lib/form/interface'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { MAKE_UPLOAD_FILE_LIMIT_MESSAGE } from 'core/local/messages.local.config'
import { PICTURE_VALIDATE_FORMATS } from 'consts/form.consts'
import { RcFile } from 'antd/lib/upload'
import { SelectControlValuesProps } from 'components/controls/SelectControl'
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

import { createConfirmPopup } from './notification.utils'
import { formatDate } from './date.utils'
import {
    isFormModeCopy,
    isFormModeView,
    isSizeLess,
    isSizeLessOrEqual,
    isValidTypeFile,
} from './conditions.utils'

export interface NestedStructureCommonContract extends DictionaryContract {
    units?: NestedStructureCommonContract[]
}

/**
 * Получить общие пропсы для нормализатора
 * @param value значение, которое будет использоваться для key, а также value контрола
 * @returns объект с общими пропсами
 */
export const getCommonSelectItemProps = (value: React.ReactText) => ({
    value,
    key: String(value),
})
/**
 * Нормализация вложенной структуры данных, с полем units содержащим дочерние элементы, для вывода в TreeSelectControl
 * с возможностью сделать некоторые поля недоступными для выбора
 * @param el - очередная запись итерации
 * @param disable - булевое значение или колбэк возвращающий булевое значение
 */
export const normalizeTreeDataWithDisabledValues = <
    T extends NestedStructureCommonContract
>(
    el: T,
    disable?: boolean | FnActionProps<T, boolean>
): DataNode => ({
    title: el.name,
    disabled: typeof disable === 'function' ? disable(el) : disable,
    ...getCommonSelectItemProps(el.id),
    children: el.units?.map((value) =>
        normalizeTreeDataWithDisabledValues(value as T, disable)
    ),
})

/**
 * Нормализация данных, для вывода в TreeSelectControl
 * @param {NestedStructureCommonContract} el  - очередная запись итерации
 */
export const normalizeDataForTreeSelect = (
    el: NestedStructureCommonContract
): DataNode => ({
    title: el.name,
    ...getCommonSelectItemProps(el.id),
    children: el.units?.map(normalizeDataForTreeSelect),
})

/**
 * Нормализация данных, для вывода в SelectControl\MultiSelectControl\Radio
 * @param el очередная запись итерации
 */
export const normalizeDataForSelectAndRadio = (
    el: EnumContract | DictionaryContract
): LabeledValue => ({
    label: el.name,
    ...getCommonSelectItemProps('value' in el ? el.value : el.id),
})

/**
 * Нормализация данных, для вывода в SelectControl\MultiSelectControl\Radio,
 * у которых value и key будут name
 * @param el очередная запись итерации
 */
export const normalizeDataForSelectAndRadioWithoutUniqueId = (
    el: EnumContract | DictionaryContract
): LabeledValue => ({
    label: el.name,
    ...getCommonSelectItemProps(el.name),
})

export const mapDataNodeToLabeledValue = (
    values?: DataNode[]
): LabeledValue[] => {
    if (!values) return []

    return values.reduce<LabeledValue[]>((acc, curr) => {
        if (!curr.value) return acc

        return [
            ...acc,
            {
                key: String(curr.value),
                value: curr.value,
                label: curr.title,
            },
        ]
    }, [])
}

/**
 * Устанавливает значение параметра `disabled` в `true` для выбранных опций
 * @param options - массив значений для опций `select`
 * @param keys - массив ключей, по которым осуществляется сопоставление с массивом `options`
 */
export const disableSelectOptions = <T extends SelectControlValuesProps>(
    options: T[],
    keys: any[]
) =>
    options.map((el) => {
        if (
            !!keys.find((key) =>
                typeof key === 'object' && 'value' in key
                    ? key.value === el.value
                    : key === el.value
            )
        ) {
            return {
                ...el,
                disabled: true,
            }
        }

        return el
    })

/**
 * Исключает выбранные опции
 * @param options - массив значений для опций `select`
 * @param values - массив ключей, по которым осуществляется сопоставление с массивом `options`
 */
export const excludeSelectOptions = (
    options?: SelectControlValuesProps[],
    values?: (string | number)[]
) => {
    if (!values) return options || []

    return options?.filter((el) => !values.includes(el.value)) || []
}

/**
 * Выбирает необходимые опции
 * @param options - массив значений для опций `select`
 * @param keys - массив ключей, по которым осуществляется сопоставление с массивом `options`
 */
export const pickSelectOptions = (
    options: SelectControlValuesProps[],
    keys: (string | number)[]
) => options.filter((el) => keys.includes(el.value))

export const isDependencyFieldsTouched = (
    dependencies: InternalNamePath[],
    form: Required<WithFormInstanceProps>['form']
) => {
    let isFieldsTouched = false

    dependencies.forEach((fieldPath) => {
        const value = form.getFieldValue(fieldPath)

        if (typeof value === 'object' && !isEmpty(value)) isFieldsTouched = true

        if (value) isFieldsTouched = true
    })

    return isFieldsTouched
}

export interface GetOnBeforeChangeHandlerProps {
    dependencies: InternalNamePath[]
    confirmTitle: string
    form?: WithFormInstanceProps['form']
}

export interface OnBeforeChangeProps {
    onChange: FnActionProps
}

export const getOnBeforeChangeHandler = ({
    dependencies,
    confirmTitle,
    form,
}: GetOnBeforeChangeHandlerProps) => ({
    onChange: startTrigger,
}: OnBeforeChangeProps) => {
    if (!form) return

    if (isDependencyFieldsTouched(dependencies, form)) {
        createConfirmPopup({
            content: confirmTitle,
            onOk: startTrigger,
        })
    } else {
        startTrigger()
    }
}

/**
 * Создать новый ряд полей
 */
export function createFieldsRow() {
    return {
        id: shortid.generate(),
    }
}

/**
 * Интерфейс функции `renderQuestionnairesDropdown`
 * @param url - адрес для ссылки
 * @param title - надпись на кнопке
 * @param permissions - роли для проверки доступности кнопки
 */
export interface RenderQuestionnairesDropdownProps {
    url: string
    title?: string
    permissions?: UserPermission[]
}

/**
 * Рендер кнопки-ссылки для выпадающего меню селекта
 * @param url - адрес для ссылки
 * @param title - надпись на кнопке
 * @param permissions - роли для проверки доступности кнопки
 */
export const renderQuestionnairesDropdown = ({
    url,
    title,
    permissions,
}: RenderQuestionnairesDropdownProps) => (menu: React.ReactElement) => (
    <div>
        {menu}

        <Divider />

        <HasPermissions
            requiredPermissions={permissions || []}
            accessDeniedRender={renderAccessDeniedButton({
                text: title || LOCAL.ACTIONS.CREATE_TEMPLATE,
                icon: <IconsAdapter iconType="PlusOutlined" />,
            })}
        >
            <a href={url} target="_blank" rel="noopener noreferrer">
                <AddButton
                    buttonText={title || LOCAL.ACTIONS.CREATE_TEMPLATE}
                />
            </a>
        </HasPermissions>
    </div>
)

export const isPlainArray = (arr: NamePath[]) =>
    !arr.some((el) => Array.isArray(el) || typeof el === 'object')

/**
 * Проверка, должно ли поле быть обновлено
 * @param fieldPath - ключ/массив ключей полей
 */
export const shouldFormFieldUpdate = (fieldPath: NamePath | NamePath[]) => (
    prevValues: Store,
    curValues: Store
) => {
    if (!Array.isArray(fieldPath)) {
        return !isEqual(prevValues[fieldPath], curValues[fieldPath])
    }

    let prevValue: any[] = []
    let curValue: any[] = []

    if (isPlainArray(fieldPath)) {
        prevValue = get(prevValues, fieldPath as ReactText[])
        curValue = get(curValues, fieldPath as ReactText[])
    } else {
        fieldPath.forEach((field) => {
            prevValue?.push(get(prevValues, field))
            curValue?.push(get(curValues, field))
        })
    }

    return !isEqual(prevValue, curValue)
}

/**
 * Заголовок для поп-апа закрытия страницы формы
 * @param pageName название страницы, куда будет переход
 * @param pageTypeName
 */
export const getFormPopupTitle = (pageName?: string, pageTypeName?: string) =>
    `${pageTypeName || LOCAL.MESSAGES.GO_TO_PAGE} \n"${pageName}"?`

export const getAssignmentFormPopupTitle = (pageName?: string) =>
    getFormPopupTitle(pageName, LOCAL.MESSAGES.GO_TO_REGISTER)

interface GetControlClassProps {
    /** режим работы формы */
    formMode?: WithFormModeProps['formMode']

    /** произвольный класс */
    className?: string
}

/**
 * Получить класс для контрола
 * @param {GetControlClassProps} - объект параметров (режим работы, произвольный класс)
 * @returns {string} - классы
 */
export const getControlClass = ({
    formMode,
    className,
}: GetControlClassProps): string => {
    const viewClass = isFormModeView(formMode) && 'view-field'

    return cn(viewClass, className)
}

/**
 * Получить готовые для использования в форме данные об изображении
 * @param picture - данные об изображении, которые отдал сервер
 */
export const mapPictureContractToFormData = (picture?: PictureContract) => {
    if (!picture) return

    return [
        {
            response: {
                url: picture.url,
                id: picture.id,
            },
        },
    ]
}

/**
 * Функция нормализации для Upload, указывает как получить значение файла из события типа onChange
 * @param e событие, при работе с Upload
 */
export const normalizeFile = (e: any) => {
    if (Array.isArray(e)) {
        return e
    }

    return e?.fileList
}

interface FetchSelectedItemsByIdsProps<T extends { id: any }> {
    getByIdsMethod: ({ body }: { body: number[] }) => Promise<T[]>
    result: number[]
    selectedItems?: T[]
    selectedItemsIds?: number[]
}

/**
 * Функция для запроса выбранных сущностей по id, без повторного запроса уже выбранных элементов
 * @param selectedItems массив выбранных элементов
 * @param selectedItemsIds массив выбранных идентификаторов
 * @param result новый массив выбранных идентификаторов элементов
 * @param getByIdsMethod метод для запроса элементов по массиву идентификаторов
 */
export const fetchSelectedItemsByIds = async <T extends { id: any }>({
    getByIdsMethod,
    result,
    selectedItems,
    selectedItemsIds = [],
}: FetchSelectedItemsByIdsProps<T>) => {
    // TODO: Допилить с учетом того, что данные с бэка могут стать не актуальными
    const body = difference(result, selectedItemsIds)

    const chosenItems = await getByIdsMethod({
        body,
    })

    return intersectionWith(selectedItems, result, (a, b) => a.id === b).concat(
        chosenItems as T[]
    )
}

/**
 * Валидация загружаемого файла по умолчанию
 * @param file добавляемый файл
 * @param fileList список загруженных файлов
 * @param fileFormats
 * @param fileSizeLimit ограничения по размеру загружаемого файла (в МБ)
 * @return {boolean} если true, значит валидация прошла успешно
 */
export const validateUploadImage = (
    file: UploadFile,
    fileList: UploadFile[],
    fileFormats = PICTURE_VALIDATE_FORMATS,
    fileSizeLimit = 2
): boolean => {
    const showedDocumentTypes = fileFormats
        .map((format) => format.split(/[+/\s,]+/)[1].toUpperCase())
        .join('/')

    if (!isValidTypeFile(file.type, fileFormats)) {
        message.warning(
            template(LOCAL.MESSAGES.YOU_CAN_ONLY_UPLOAD_FORMATS_TEMPLATE)({
                documentType: showedDocumentTypes,
            })
        )

        return false
    }

    if (file.size && !isSizeLess(file.size, fileSizeLimit)) {
        message.warning(
            template(LOCAL.MESSAGES.IMAGE_FILE_SIZE_VALIDATION_ERROR_MESSAGE)({
                fileSize: fileSizeLimit,
            })
        )

        return false
    }

    return true
}

/**
 * Валидация файла по типу перед загрузкой
 * @param documentType строка содержащая допустимые типы файлов
 * @param fileName имя файла
 */
export const validateUploadFileType = (
    documentType: string,
    fileName: RcFile['name']
) => {
    const fileType = fileName.split('.').pop()?.toLowerCase() || ''
    const documentTypes = documentType.replace(/[\s.]/g, '').split(',')

    return isValidTypeFile(fileType, documentTypes)
}

export interface BeforeUploadAttachmentProps {
    documentType: string
    maxCount?: number | boolean
    fileList?: UploadFile<AttachmentContract>[]
    fileSize?: number
    errors?: {
        size?: string
        type?: string
    }
    overallFilesMaxSize?: number
}

export const beforeUploadAttachment = ({
    documentType,
    maxCount,
    fileList,
    fileSize = 50,
    errors,
    overallFilesMaxSize,
}: BeforeUploadAttachmentProps) => (file: RcFile) => {
    if (!isSizeLessOrEqual(file.size, fileSize)) {
        notification.error({
            message:
                errors?.size ||
                template(LOCAL.MESSAGES.UPLOAD_FILE_SIZE_LIMIT_MESSAGE)({
                    maxSize: fileSize,
                }),
        })

        return false
    }

    if (!validateUploadFileType(documentType, file.name)) {
        notification.error({
            message:
                errors?.type ||
                template(LOCAL.MESSAGES.YOU_CAN_ONLY_UPLOAD_FORMATS_TEMPLATE)({
                    documentType,
                }),
        })

        return false
    }

    if (maxCount && fileList?.length === maxCount) {
        notification.error({
            message: MAKE_UPLOAD_FILE_LIMIT_MESSAGE(maxCount),
        })

        return false
    }

    const overallFileSize =
        fileList?.reduce((size, el) => size + (el.size || 0), 0) || 0

    if (
        overallFilesMaxSize &&
        !isSizeLess(file.size + overallFileSize, overallFilesMaxSize)
    ) {
        notification.error({
            message: template(LOCAL.MESSAGES.OVERALL_FILE_SIZE_ARE_BIGGER_THAN)(
                {
                    fileSize: overallFilesMaxSize,
                }
            ),
        })

        return false
    }

    return true
}
/** Получить наименование курса с версией и датой загрузки */
export const getCourseNameWithVersion = (
    props?: CourseSearchResultContract
) => {
    if (!props) return

    const { name, courseVersion, uploadDate } = props

    return `${name} (${template(LOCAL.LABELS.VERSION_FROM_DATE_TEMPLATE)({
        version: courseVersion,
        date: formatDate(uploadDate),
    })})`
}

/** Добавить необходимое наименование к NamePath элемента формы
 * @param name - изначальный путь к элементу формы
 * @param fieldNameToAdd - наименование, которые необходимо добавить
 */
export const addNameToNamePath = (name: NamePath, fieldNameToAdd: string) => {
    if (Array.isArray(name)) return name.concat(fieldNameToAdd)

    return [name, fieldNameToAdd]
}

export const isUnitTypeComplex = (unit?: StructuralUnitContract) =>
    unit?.type === StructuralUnitType.Complex

/** Нормализация дерева структурных единиц без возможности выбора структурной единицы с типом Complex*/
export const normalizeStructuralUnitsWithoutComplexes = (
    units?: StructuralUnitContract[]
) => {
    if (!units) return []

    return units.map((unit) =>
        normalizeTreeDataWithDisabledValues(unit, isUnitTypeComplex)
    )
}

/**
 * Нормалайзер данных файла от бэка для форм
 * @param attachment объект с данными файла
 * @param status для совместимости с Upload
 * @param type для совместимости с Upload
 */
export const normalizeAttachmentObject = (
    attachment: AttachmentContract,
    status = 'done' as UploadFileStatus,
    type = ''
) => ({
    response: attachment,
    size: attachment.fileSize,
    name: attachment.fileName,
    uid: String(attachment.id),
    status,
    type,
})

/** Сконкатенировать два NamePath'a в один */
export const getFieldsPath = (name: NamePath, fieldName?: NamePath) => {
    if (!fieldName) return name

    if (Array.isArray(fieldName)) {
        return fieldName.concat(name)
    }

    if (Array.isArray(name)) {
        return [...name, fieldName]
    }

    return [fieldName, name]
}

export const wrapToArrayIfTruthy = <T,>(value?: T) =>
    value ? [value] : undefined

export const wrapToArray = <T,>(value: T) => [value]

export const convertToShouldUpdateDep = <T,>(arr: T[]) => arr.map(wrapToArray)

/**
 * Функция проверяет режим формы. Eсли formMode == 'copy', то в поле name шаблона добавляется 'копия'
 * @param data начальные данные для подстановки в форму
 * @param formMode режим формы
 * @returns Подготовленные данные для создания копии шаблона
 */
export const getInitialValuesForCopy = <T extends { name: string }>(
    data: T,
    formMode?: FormMode
) => {
    if (isFormModeCopy(formMode))
        return { ...data, name: `${data.name} ${LOCAL.LABELS.COPY}` }

    return data
}

/**
 * Функция расширения объекта request при копировании сущности
 * @param data объект request
 * @param formMode режим формы
 */
export const setParentIdForCopy = <T extends { id?: number }>(
    data: T,
    formMode?: FormMode
) => {
    if (isFormModeCopy(formMode))
        return { ...data, parentId: data.id, id: undefined }

    return data
}

interface EditSessionCancelProps<T> {
    service?: T
    id?: number
    type?: EntityType
}

export const editSessionCancel = <T extends { editSessionCancel: Function }>({
    service,
    id,
    type,
}: EditSessionCancelProps<T>) => {
    if (!id) return

    if (service)
        service.editSessionCancel({
            id,
        })

    if (type)
        EntityEditSessionService.editSessionCancel({
            id,
            entity: type,
        })
}
