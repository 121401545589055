import React, { useCallback, useEffect, useState } from 'react'
import { FORM_IDS, Resolutions } from 'core/configs'
import { Form } from 'antd'
import { HiddenField } from 'components/controls'
import { InfoAssistant } from 'components/shared'
import { InfoAssistantProps } from 'components/shared/InfoAssistant/InfoAssistant.types'
import {
    LOCAL_STORAGE_KEYS,
    LocalStorageHelper,
} from 'core/helpers/LocalStorage'
import {
    TrackEvent,
    TrackHeaderIconContract,
    TrackPassingContract,
} from 'core/api'

import { InfoAssistantChecker, TrackLayer } from './components'
import {
    mapTrackCanvasesPreview,
    mapTrackPreviewData,
} from '../Track/components/TrackForm/TrackForm.utils'

export const TrackPreview: React.FC = () => {
    const mode = 'preview'

    const [form] = Form.useForm()

    const [infoAssistantConfig, setInfoAssistantConfig] = useState<
        InfoAssistantProps
    >()
    const [trackInfo, setTrackInfo] = useState<Partial<TrackPassingContract>>()

    const handleUpdateInfoAssistantConfig = useCallback(
        (config: InfoAssistantProps) => {
            setInfoAssistantConfig((prev) => ({ ...prev, ...config }))
        },
        []
    )

    useEffect(() => {
        const setTrackInfoForPreview = () => {
            const trackStageCreatePreview = LocalStorageHelper.getItem(
                LOCAL_STORAGE_KEYS.TRACK_PREVIEW
            )

            if (!trackStageCreatePreview) return

            const trackPreviewData = mapTrackPreviewData(
                trackStageCreatePreview
            )

            form.setFieldsValue(trackPreviewData)

            setTrackInfo({
                trackCanvases: mapTrackCanvasesPreview(
                    trackPreviewData.trackCanvases || {}
                ),
                trackHeaderIcons: trackPreviewData.trackHeaderIcons as TrackHeaderIconContract[],
            })
        }

        handleUpdateInfoAssistantConfig({
            mode,
            visible: true,
            event: TrackEvent.Greeting,
        })

        setTrackInfoForPreview()
    }, [form, mode, handleUpdateInfoAssistantConfig])

    return (
        <Form id={FORM_IDS.TRACK_FORM} form={form}>
            <HiddenField fieldName="title" />

            <HiddenField fieldName="useInformationAssistant" />

            <HiddenField
                fieldName={['trackCanvases', Resolutions.XXL, 'width']}
            />

            <HiddenField
                fieldName={['trackCanvases', Resolutions.XXL, 'height']}
            />

            <HiddenField
                fieldName={[
                    'trackCanvases',
                    Resolutions.XXL,
                    'trackCanvasBackgroundId',
                ]}
            />

            <HiddenField
                fieldName={[
                    'trackCanvases',
                    Resolutions.XXL,
                    'meta',
                    'currentBackgroundUrl',
                ]}
            />

            <HiddenField
                fieldName={[
                    'trackCanvases',
                    Resolutions.XXL,
                    'trackStageStyles',
                ]}
            />

            <HiddenField fieldName="stages" />

            <HiddenField fieldName="trackPassingId" />

            <HiddenField fieldName="trackBackgroundPicture" />

            <TrackLayer mode={mode} trackInfo={trackInfo} />

            <InfoAssistantChecker>
                <InfoAssistant {...infoAssistantConfig} />
            </InfoAssistantChecker>
        </Form>
    )
}
