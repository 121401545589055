import React, { useCallback, useEffect, useMemo } from 'react'
import { COL_xl8_lg12_xs24, PAGE_SIZE_FIFTY, ROW_GUTTER } from 'consts'
import { CatalogItemSearchContract, CatalogService } from 'core/api'
import { Col, Row } from 'antd'
import { ControlGroup } from 'components/controls'
import { FiltersToggleButton, IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { RequestQueryProps, useBreakpoint, useElementVisible } from 'hooks'
import { useSearchQuery } from 'hooks/useSearchQuery'

import styles from './CatalogSearch.module.scss'
import { CatalogSearchFilterByName } from './CatalogSearchFilterByName'
import { CatalogSearchFilterExtended } from './CatalogSearchFilterExtended'
import { CatalogSearchProps } from './CatalogSearch.types'
import { CatalogSection } from '../CatalogSection'
import { CatalogUnit } from '../CatalogUnit'
import { getCatalogSectionHeader } from '../CatalogSection/CatalogSection.utils'

/** Компонент поиска для каталога */
export const CatalogSearch: React.FC<CatalogSearchProps> = React.memo(
    ({
        setVisibleSearchResults,
        visibleSearchResults,
        onUpdateFavourites,
        setFoundCatalogItems,
        foundCatalogItems,
        catalogProductCards,
    }) => {
        const {
            elementVisible: visibleFilter,
            toggleElementVisible: triggerFilterVisibility,
        } = useElementVisible()

        const {
            queryParams,
            handleSetFilters,
            handleResetFilters,
        } = useSearchQuery({
            queryOptions: {
                pageSize: PAGE_SIZE_FIFTY,
            },
        })

        const catalogItems = visibleSearchResults
            ? foundCatalogItems
            : catalogProductCards

        const isWidthLessLG = useBreakpoint('LG')

        const quikSearchResponsive = useMemo(
            () =>
                isWidthLessLG
                    ? { search: { flex: 1 } }
                    : {
                          search: {
                              xs: 18,
                              lg: 12,
                              xl: 8,
                          },
                          filter: {
                              xs: 6,
                              lg: 12,
                              xl: 8,
                          },
                      },
            [isWidthLessLG]
        )

        const fetchCatalogItems = useCallback(async () => {
            try {
                const dataSource = await CatalogService.searchAsync(
                    queryParams as Required<
                        RequestQueryProps<CatalogItemSearchContract>
                    >
                )

                setFoundCatalogItems(dataSource.pageItems)
            } catch (error) {
                console.error(error)
            }
        }, [queryParams, setFoundCatalogItems])

        const sectionContent = useMemo(
            () =>
                catalogItems?.map((el) => (
                    <Col {...COL_xl8_lg12_xs24} key={el.id}>
                        <CatalogUnit
                            onUpdateFavourites={onUpdateFavourites}
                            {...el}
                        />
                    </Col>
                )),
            [catalogItems, onUpdateFavourites]
        )

        useEffect(() => {
            fetchCatalogItems()
        }, [fetchCatalogItems])

        return (
            <div className={styles.wrapper}>
                <div className={styles.quikSearch}>
                    <Row gutter={ROW_GUTTER} wrap={false}>
                        <Col {...quikSearchResponsive.search}>
                            <CatalogSearchFilterByName
                                onSetFilters={handleSetFilters}
                                setVisibleSearchResults={
                                    setVisibleSearchResults
                                }
                                catalogItems={catalogItems}
                            />
                        </Col>

                        <Col {...quikSearchResponsive.filter}>
                            <FiltersToggleButton
                                icon={
                                    <IconsAdapter iconType="CustomIconMainScreenFilter" />
                                }
                                onToggleFilterVisibility={
                                    triggerFilterVisibility
                                }
                                hideText
                                className={styles.filterButton}
                            />
                        </Col>
                    </Row>
                </div>

                {visibleFilter && (
                    <ControlGroup type="dark" className={styles.filters}>
                        <CatalogSearchFilterExtended
                            onSetFilters={handleSetFilters}
                            onResetFilters={handleResetFilters}
                            setVisibleSearchResults={setVisibleSearchResults}
                        />
                    </ControlGroup>
                )}

                {visibleSearchResults && (
                    <CatalogSection
                        header={getCatalogSectionHeader(
                            LOCAL.LABELS.SEARCH_RESULTS
                        )}
                        content={
                            <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
                                {sectionContent?.length ? (
                                    sectionContent
                                ) : (
                                    <Col>{LOCAL.LABELS.NO_RESULTS}</Col>
                                )}
                            </Row>
                        }
                    />
                )}
            </div>
        )
    }
)
