import React, { FC, memo, useContext, useMemo } from 'react'
import { PassingHeader } from 'components/shared/Passing/PassingHeader'
import { UserContext } from 'contexts'
import { useDictionariesHelper } from 'hooks'

import passingHeaderStyles from './UserAssignmentPassingHeader.module.scss'
import { UserAssignmentPassingHeaderProps } from './UserAssignmentPassingHeader.types'
import {
    getHeaderName,
    getHeaderSuffix,
} from './UserAssignmentPassingHeader.utils'

/** Заголовок прохождения */
export const UserAssignmentPassingHeader: FC<UserAssignmentPassingHeaderProps> = memo(
    ({
        respondentRole,
        assessmentType,
        candidate,
        numberOfAnswers,
        numberOfQuestions,
        assessmentPortfolioPresentation,
        hasUnsavedChanges,
        styles,
        title: titleProp,
    }) => {
        const { assessmentTypeDictionary } = useDictionariesHelper([
            'assessmentTypeDictionary',
        ])

        const { userData } = useContext(UserContext)

        const headerName = getHeaderName({
            assessmentType,
            candidate,
            userData,
        })
        const suffixText = getHeaderSuffix({
            userData,
            assessmentType,
            candidate,
            respondentRole,
            assessmentPortfolioPresentation,
        })

        const title = useMemo(
            () => (
                <div style={styles}>
                    <div className={passingHeaderStyles.assessmentType}>
                        {`Тип оценки - ${assessmentTypeDictionary[assessmentType]}`}
                    </div>
                    {titleProp && <span>{`${titleProp} — `}</span>}

                    {headerName}

                    {suffixText}
                </div>
            ),
            [
                assessmentType,
                assessmentTypeDictionary,
                headerName,
                styles,
                suffixText,
                titleProp,
            ]
        )

        return (
            <PassingHeader
                styles={styles}
                title={title}
                numberOfAnswers={numberOfAnswers}
                numberOfQuestions={numberOfQuestions}
                hasUnsavedChanges={hasUnsavedChanges}
            />
        )
    }
)
