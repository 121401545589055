import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { ColumnsType } from 'antd/lib/table'
import { EyeBlock } from 'components/shared'
import { FeedbackQuizReportQuestionsTableContractPageContract } from 'core/api'
import { LOCAL } from 'core/local'
import { Popover } from 'antd'
import { getDynamicColName } from 'utils'

import {
    FreeAnswerTableRowParentProps,
    FreeAnswerTableRowProps,
    GetAnswersProps,
    QuestionsColumnsProps,
} from './TabContent.types'
import { MAX_CONTENT_LENGTH } from './TabContent.consts'
import { ReviewTableNameGroups } from '../QuizeReportFreeAnswer/QuizeReportFreeAnswer.consts'

export const freeAnswerTableColsUtils = {
    /**
     * Создать колонки таблицы, которые зависят от кол-ва вопросов для проверки
     * @param questions - вопросы для колонок таблиц свободного ответа
     * @returns {ColumnsType} - возвращает объекты для создания динамических столбцов таблицы
     */
    makeDynamicColumns(questions: QuestionsColumnsProps[]): ColumnsType {
        return questions.map((el) => ({
            title: (
                <EyeBlock
                    key={uniqueId('KEY_')}
                    title={LOCAL.LABELS.QUESTION}
                    modalContent={el.data}
                />
            ),
            dataIndex: getDynamicColName<ReviewTableNameGroups>(
                ReviewTableNameGroups.Answer,
                el.number
            ),
        }))
    },

    /**
     * Создание итогового набора столбцов для таблицы свободных ответов
     * @param questions - вопросы для колонок таблицы свободных ответов
     * @returns {ColumnsType} - возвращает объекты для создания итоговых столбцов таблицы
     */
    getTableColumns(
        questions: QuestionsColumnsProps[] | undefined
    ): ColumnsType<object> | undefined {
        if (!questions) return

        return [
            { dataIndex: 'main', title: LOCAL.LABELS.EMPLOYEES },
            ...this.makeDynamicColumns(questions),
        ] as ColumnsType<object>
    },
}

/**
 * Создать часть контента таблицы, содержащую ответ пользователя
 * @param content - контент ячейки
 * @param answerClassName - стиль для блока ответа
 */
const makeAnswerDataItem = (content: string, answerClassName: string) =>
    content.length > MAX_CONTENT_LENGTH ? (
        <Popover content={content}>
            <div className={answerClassName}>{content}</div>
        </Popover>
    ) : (
        <div className={answerClassName}>{content}</div>
    )

const getAnswers = ({ answers, answerClassName }: GetAnswersProps) =>
    answers.reduce(
        (acc, current, index) =>
            index === 0
                ? {
                      main: current,
                  }
                : {
                      ...acc,
                      [`answer-${index}`]: makeAnswerDataItem(
                          current,
                          answerClassName
                      ),
                  },
        {}
    )

/**
 * Получить итоговый контент таблицы в виде dataSource
 * @param reviewCommonData - коллекция данных для таблиц свободных ответов
 */
export const getDataSource = (
    commonData: FeedbackQuizReportQuestionsTableContractPageContract,
    answerClassName: string
): FreeAnswerTableRowProps => {
    const normalizeData = commonData.pageItems.map((reviewGroup) => {
        const mainKey = uniqueId('KEY_')

        return {
            id: mainKey,
            key: mainKey,
            main: reviewGroup.organization,
            children: reviewGroup.rows.map((el) => {
                const answers = (el as unknown) as string[]
                const childrenKey = uniqueId('KEY_CHILDREN_')

                return {
                    id: childrenKey,
                    key: childrenKey,
                    ...getAnswers({ answers, answerClassName }),
                }
            }, {}),
        } as FreeAnswerTableRowParentProps
    })

    return { total: commonData.total, normalizeData }
}
