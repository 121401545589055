import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { NOTIFICATION_STATUSES, NOTIFICATION_TYPES } from 'consts'
import { NotificationContract, NotificationType } from 'core/api'
import { defaultSorter } from 'components/layouts'

import styles from './UserNotifications.module.scss'
import { GetUserNotificationsTableColumnsProps } from './UserNotifications.types'
import {
    UserNotificationsHeaderActions,
    UserNotificationsTableActions,
} from './components'

/**
 * Текст ячейки для столбца "Текст"
 * @param message - основной текст сообщения
 * @param type - тип уведомления
 * @param link - ссылка
 */
export const getMessageText = (
    message: string,
    { type, link }: NotificationContract
) => {
    if (!link) return message

    const getLinkText = (type: NotificationType) =>
        type === NotificationType.Homework
            ? LOCAL.ACTIONS.GO_TO
            : NOTIFICATION_TYPES[type]

    return (
        <div>
            {message}

            {link && (
                <a href={link} className={styles.link}>
                    {getLinkText(type)}
                </a>
            )}
        </div>
    )
}

/** Получить настройки колонок таблицы реестра уведомлений */
export const getUserNotificationsTableColumns = ({
    handleSetAsRead,
    selectedRowKeys,
}: GetUserNotificationsTableColumnsProps): ColumnsType<
    NotificationContract
> => [
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type: NotificationType) => NOTIFICATION_TYPES[type],
    },
    {
        title: LOCAL.LABELS.THEME,
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: LOCAL.LABELS.TEXT,
        dataIndex: 'message',
        key: 'message',
        align: 'left',
        width: '40%',
        render: getMessageText,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'unread',
        key: 'unread',
        width: 250,
        render: (isUnread: boolean) =>
            isUnread
                ? NOTIFICATION_STATUSES.unread
                : NOTIFICATION_STATUSES.read,
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.DATE,
        dataIndex: 'creationDate',
        key: 'creationDate',
        width: 200,
    },
    {
        key: 'actions',
        title: (tableRow) => (
            <UserNotificationsHeaderActions
                tableRow={tableRow}
                onSetAsRead={handleSetAsRead(selectedRowKeys as string[])}
            />
        ),
        render: (tableRow) => (
            <UserNotificationsTableActions
                tableRow={tableRow}
                onSetAsRead={handleSetAsRead([tableRow.id])}
            />
        ),
        width: 100,
        align: 'right',
    },
]
