import React from 'react'
import cn from 'classnames'
import { SpoilerTitleRenderProps } from 'components/shared/Spoiler/Spoiler.types'

import styles from './ProductCardContent.module.scss'

export const renderSpoilerTitle = (name: string, isHaveChildren: boolean) => ({
    toggleSpoilerVisible,
    visible,
}: SpoilerTitleRenderProps) => (
    <div className={styles.header}>
        {isHaveChildren ? (
            <a
                href="#s"
                onClick={toggleSpoilerVisible}
                className={styles.toggle}
            >
                <button
                    className={
                        visible
                            ? 'ant-table-row-expand-icon ant-table-row-expand-icon-expanded'
                            : 'ant-table-row-expand-icon ant-table-row-expand-icon-collapsed'
                    }
                />

                <p className={styles.name}>{name}</p>
            </a>
        ) : (
            <p className={cn(styles.name, styles.nameWithoutBtn)}>{name}</p>
        )}
    </div>
)
