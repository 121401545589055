import { LOCAL } from 'core/local'

export enum TabsKeys {
    Coworker,
    Manager,
}

export const TABS_PANE_OPTIONS = {
    COWORKER: {
        tab: LOCAL.LABELS.EMPLOYEE,
        key: TabsKeys.Coworker,
    },
    MANAGER: {
        tab: LOCAL.LABELS.MANAGER,
        key: TabsKeys.Manager,
    },
}

export const DEFAULT_TAB_KEY = TABS_PANE_OPTIONS.MANAGER.key
