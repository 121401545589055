import orderBy from 'lodash/orderBy'
import React, { useContext, useMemo } from 'react'
import { LOCAL } from 'core/local'

import styles from './UserProfileCompetencies.module.scss'
import { UserProfileCompetenciesGroup } from '../UserProfileCompetenciesGroup'
import { UserProfileCompetenciesProps } from './UserProfileCompetencies.types'
import { UserProfileContext } from '../../context'
import {
    getComparison,
    isDisplayGroupedCompetencies,
} from './UserProfileCompetencies.utils'

/**
 * Компетенции профиля должности
 */
export const UserProfileCompetencies: React.FC<UserProfileCompetenciesProps> = React.memo(
    ({ competenciesData, comparisonData }) => {
        const { showAssessments, showProfilePosition } = useContext(
            UserProfileContext
        )

        /**
         * Уровни компетенций
         */
        const competenciesLevels = useMemo(() => {
            const groups = getComparison(
                competenciesData,
                comparisonData,
                showAssessments,
                showProfilePosition
            )

            const sortedGroups = orderBy(groups, ['order', 'groupName']).map(
                (group) => ({
                    ...group,
                    children: orderBy(group.children, ['order', 'name']),
                })
            )

            return sortedGroups?.map((group) => (
                <UserProfileCompetenciesGroup
                    key={group.groupId}
                    groupId={group.groupId}
                    groupName={group.groupName}
                    competencies={group.children}
                />
            ))
        }, [
            showAssessments,
            showProfilePosition,
            competenciesData,
            comparisonData,
        ])

        return (
            <div className={styles.wrapper}>
                {isDisplayGroupedCompetencies(
                    comparisonData?.data,
                    competenciesData
                ) ? (
                    competenciesLevels
                ) : (
                    <div>{`${LOCAL.LABELS.COMPETENCIES_LIST_EMPTY}...`}</div>
                )}
            </div>
        )
    }
)
