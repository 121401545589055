import React, { FC } from 'react'
import {
    EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS,
    EVENTS_REQUIRED_PERMISSIONS,
} from 'consts'
import { EVENTS_PASSING_VIEW_ROUTE } from 'components/pages/Events/EventPassing/EventPassing.consts'
import {
    EventCatalog,
    EventFormContainer,
    EventPassing,
} from 'components/pages'
import {
    EventsAssignments,
    EventsAssignmentsFormContainer,
} from 'components/pages/EventsAssignments'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Redirect, Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

export const EventRouting: FC = React.memo(() => (
    <Switch>
        <Route
            path={[
                EVENTS_PASSING_VIEW_ROUTE,
                `${ROUTE_NAMES.EVENTS_PASSING}/:activityId/:appointmentActivityId`,
            ]}
        >
            <EventPassing />
        </Route>

        <ProtectedRoute
            requiredPermissions={[
                ...EVENTS_REQUIRED_PERMISSIONS,
                UserPermission.EventPassing,
            ]}
            path={`${ROUTE_NAMES.EVENTS_PASSING}/:activityId`}
            exact
        >
            <EventPassing />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.AppointmentActivitySave,
            ]}
            path={`${ROUTE_NAMES.EVENTS_ASSIGNMENTS_EDIT}/:id`}
        >
            <EventsAssignmentsFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.EVENTS_ASSIGNMENTS_VIEW}/:id`}
        >
            <EventsAssignmentsFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.AppointmentActivitySave,
            ]}
            path={`${ROUTE_NAMES.EVENTS_ASSIGNMENTS_CREATE}`}
        >
            <EventsAssignmentsFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.AppointmentActivitiesRead,
            ]}
            path={`${ROUTE_NAMES.EVENTS_ASSIGNMENTS}`}
        >
            <EventsAssignments />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...EVENTS_REQUIRED_PERMISSIONS,
                UserPermission.EventSave,
            ]}
            path={`${ROUTE_NAMES.EVENTS_COPY}/:id`}
        >
            <EventFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={EVENTS_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.EVENTS_VIEW}/:id`}
        >
            <EventFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...EVENTS_REQUIRED_PERMISSIONS,
                UserPermission.EventSave,
            ]}
            path={`${ROUTE_NAMES.EVENTS_EDIT}/:id`}
        >
            <EventFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...EVENTS_REQUIRED_PERMISSIONS,
                UserPermission.EventSave,
            ]}
            path={ROUTE_NAMES.EVENTS_CREATE}
        >
            <EventFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.EventsRead,
            ]}
            path={ROUTE_NAMES.EVENTS}
        >
            <EventCatalog />
        </ProtectedRoute>

        <Redirect to={ROUTE_NAMES.EVENTS} />
    </Switch>
))
