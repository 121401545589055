import React, { useCallback } from 'react'
import { AssessmentType, QuestionnaireType } from 'core/api'
import { Col, Form, Row } from 'antd'
import { FormItemAdapter, SwitchControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { useDictionariesHelper } from 'hooks'

import styles from './UserAssignmentsFilters.module.scss'
import {
    UserAssignmentsFiltersProps,
    UserAssignmentsFiltersSubmitValues,
} from './UserAssignmentsFilters.types'
import {
    mapFormDataToRequest,
    mapRequestToFormData,
} from './UserAssignmentsFilters.utils'

/**
 * Форма фильтрации назначений пользователя
 */
export const UserAssignmentsFilters: React.FC<UserAssignmentsFiltersProps> = React.memo(
    ({ onSetFilters, filterValue = {} }) => {
        const [form] = Form.useForm()

        const { assessmentTypeDictionary } = useDictionariesHelper([
            'assessmentTypeDictionary',
        ])

        /**
         * Обработчик изменения значений полей формы
         */
        const handleValuesChange = useCallback(
            (_, values: Partial<UserAssignmentsFiltersSubmitValues>) => {
                onSetFilters(mapFormDataToRequest(values))
            },
            [onSetFilters]
        )

        return (
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                initialValues={mapRequestToFormData(filterValue)}
            >
                <Row className={styles.wrapper} gutter={ROW_GUTTER}>
                    <Col className={styles.col}>
                        <FormItemAdapter
                            fieldName={AssessmentType.Estimate_360}
                        >
                            <SwitchControl
                                addonAfter={
                                    assessmentTypeDictionary[
                                        AssessmentType.Estimate_360
                                    ]
                                }
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col className={styles.col}>
                        <FormItemAdapter
                            fieldName={AssessmentType.EstimateByManager}
                        >
                            <SwitchControl
                                addonAfter={
                                    assessmentTypeDictionary[
                                        AssessmentType.EstimateByManager
                                    ]
                                }
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col className={styles.col}>
                        <FormItemAdapter fieldName={QuestionnaireType.Test}>
                            <SwitchControl addonAfter={LOCAL.LABELS.TESTS} />
                        </FormItemAdapter>
                    </Col>

                    <Col className={styles.col}>
                        <FormItemAdapter fieldName="showArchived">
                            <SwitchControl
                                addonAfter={LOCAL.LABELS.SHOW_COMPLETED}
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </Form>
        )
    }
)
