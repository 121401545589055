import React from 'react'
import { Button } from 'components/shared/Button'
import { LOCAL } from 'core/local'

import { CommonStatsControlsProps } from './CommonStatsControls.types'

/** общая статистика контролы */
export const CommonStatsControls: React.FC<CommonStatsControlsProps> = React.memo(
    ({ handleUpdate, handleExport }) => (
        <div>
            <Button type="link" onClick={handleUpdate} className="mr-20">
                {LOCAL.ACTIONS.UPDATE}
            </Button>

            <Button onClick={handleExport}>{LOCAL.ACTIONS.EXPORT}</Button>
        </div>
    )
)
