/**
 * преобразование текста вопроса при смене типа вопроса
 * удаляет из текста html-теги
 * @param text
 */
export const cleanQuestionText = (text?: string) => {
    if (!text) return

    const tagRegex = new RegExp('<[^>]+>', 'g')
    const nbspRegex = new RegExp('&nbsp;', 'g')

    return text.replace(tagRegex, '').replace(nbspRegex, '\u00A0')
}
