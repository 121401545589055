import React from 'react'
import { ErrorPage } from 'components/pages'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

import { TracksUnique } from './TracksUnique'

const TracksUniquePassing = React.lazy(() =>
    import('components/pages/TracksUnique/TrackUniquePassing')
)

export const TracksUniqueRouting = React.memo(() => (
    <div>
        <Switch>
            <Route path={ROUTE_NAMES.TRACKS_PASSING_UNIQUE}>
                <React.Suspense fallback={null}>
                    <TracksUniquePassing />
                </React.Suspense>
            </Route>

            <ProtectedRoute
                requiredPermissions={[
                    UserPermission.Administration,
                    UserPermission.MguuTracksRead,
                ]}
                path={ROUTE_NAMES.TRACKS_UNIQUE}
            >
                <TracksUnique />
            </ProtectedRoute>

            <Route>
                <ErrorPage errorCode="404" />
            </Route>
        </Switch>
    </div>
))
