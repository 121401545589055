import React, { useCallback } from 'react'
import { Col, Form, Row } from 'antd'
import { ComponentCommonProps } from 'App.types'
import { INPUT_NUMBER_MIN_VALUE } from 'components/forms'
import {
    InputNumberControl,
    SelectControl,
    ShouldUpdateChecker,
    SwitchControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { NOTIFICATION_DICTIONARY_MAPPED } from 'consts'
import { isFormModeView } from 'utils'

import styles from './AssessmentProjectsNotifications.module.scss'

/** Вкладка настроек оповещения оценочного проекта*/
export const AssessmentProjectsNotifications: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => {
        /**
         * Cменить на противоположное значение переодичности нотификаций
         */
        const handlePeridiodicalNotificationUnCheck = useCallback(
            (setFieldsValue: (value: any) => void, value: boolean) => () => {
                setFieldsValue({
                    periodicalNotification: !value,
                })
            },
            []
        )

        return (
            <div className={styles.wrapper}>
                <h3 className={styles.title}>
                    {LOCAL.CATALOGS.NOTIFICATIONS_SETTINGS}
                </h3>

                <div className={styles.row}>
                    <ShouldUpdateChecker fieldPath={['periodicalNotification']}>
                        {({ getFieldValue, setFieldsValue }) => {
                            const periodicalNotification = getFieldValue(
                                'periodicalNotification'
                            )

                            return (
                                <SwitchControl
                                    checked={!periodicalNotification}
                                    onChange={handlePeridiodicalNotificationUnCheck(
                                        setFieldsValue,
                                        periodicalNotification
                                    )}
                                    addonAfter={LOCAL.ACTIONS.REMIND}
                                    disabled={isFormModeView(formMode)}
                                    className={styles.switchControl}
                                />
                            )
                        }}
                    </ShouldUpdateChecker>

                    <Form.Item name="daysToStartNotification">
                        <InputNumberControl
                            addonBefore={LOCAL.TIME_BEFORE}
                            addonAfter={LOCAL.LABELS.DAYS_TO_START}
                            min={INPUT_NUMBER_MIN_VALUE}
                            formMode={formMode}
                            className={styles.inputNumberControl}
                        />
                    </Form.Item>

                    <Form.Item name="daysToEndNotification">
                        <InputNumberControl
                            addonBefore={LOCAL.TIME_BEFORE}
                            addonAfter={LOCAL.LABELS.DAYS_TO_END}
                            min={INPUT_NUMBER_MIN_VALUE}
                            formMode={formMode}
                            className={styles.inputNumberControl}
                        />
                    </Form.Item>
                </div>

                <Row className={styles.row}>
                    <Col xs={10} lg={8} xl={7} xxl={6}>
                        <Form.Item name="periodicalNotification">
                            <SwitchControl
                                addonAfter={
                                    LOCAL.LABELS.NOTIFICATIONS_PERIODICITY
                                }
                                disabled={isFormModeView(formMode)}
                                className={styles.switchControl}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={6} xl={3} pull={1}>
                        <Form.Item name="notificationIntervals">
                            <SelectControl
                                className={styles.periodicity}
                                values={NOTIFICATION_DICTIONARY_MAPPED}
                                formMode={formMode}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        )
    }
)
