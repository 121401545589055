import {
    ASSESSMENT_PORTFOLIOS_REQUIRED_PERMISSIONS,
    EVENTS_REQUIRED_PERMISSIONS,
    QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
    TEST_PORTFOLIOS_REQUIRED_PERMISSIONS,
    TRACK_ADAPTATION_PERMISSIONS,
    TRACK_DEVELOPMENT_PERMISSIONS,
} from 'consts'
import {
    NotificationDestinationCategoryCode,
    NotificationDestinationSubCategoryCode,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { isNotificationDestinationCategoryTrack } from 'utils'

const createViewOptionsObj = (
    url: string,
    permissionsRequired: UserPermission[]
) => ({
    url,
    permissionsRequired,
})

export const getViewOptions = (
    categoryCode: NotificationDestinationCategoryCode,
    subCategoryCode?: NotificationDestinationSubCategoryCode
) => {
    if (isNotificationDestinationCategoryTrack(categoryCode)) {
        switch (subCategoryCode) {
            case NotificationDestinationSubCategoryCode.Adaptation:
                return createViewOptionsObj(
                    ROUTE_NAMES.TRACKS_ADAPTATION_VIEW,
                    TRACK_ADAPTATION_PERMISSIONS
                )
            case NotificationDestinationSubCategoryCode.Development:
                return createViewOptionsObj(
                    ROUTE_NAMES.TRACKS_DEVELOPMENT_VIEW,
                    TRACK_DEVELOPMENT_PERMISSIONS
                )
        }
    }

    switch (categoryCode) {
        case NotificationDestinationCategoryCode.Activity:
            return createViewOptionsObj(
                ROUTE_NAMES.EVENTS_VIEW,
                EVENTS_REQUIRED_PERMISSIONS
            )
        case NotificationDestinationCategoryCode.Quiz:
            return createViewOptionsObj(
                ROUTE_NAMES.QUIZ_PORTFOLIOS_VIEW,
                QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS
            )
        case NotificationDestinationCategoryCode.Assessment:
            return createViewOptionsObj(
                ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_VIEW,
                ASSESSMENT_PORTFOLIOS_REQUIRED_PERMISSIONS
            )
        case NotificationDestinationCategoryCode.Test:
            return createViewOptionsObj(
                ROUTE_NAMES.TEST_PORTFOLIOS_VIEW,
                TEST_PORTFOLIOS_REQUIRED_PERMISSIONS
            )
    }
}
