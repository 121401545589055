import cn from 'classnames'
import React, { FC, useContext, useState } from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { LOGOUT_URL } from 'consts/env.consts'
import { Menu } from 'antd'
import { NavLink } from 'react-router-dom'
import { UserContext } from 'contexts'
import { UserPermission } from 'core/api'
import { backwardFn, getShortFio } from 'utils'
import { checkRoleBasedPermissions } from 'core/permissions/ProtectedRoute/ProtectedRoute.utils'

import styles from './MobileNavigation.module.scss'
import {
    getAdminMenu,
    getMainMenu,
    getProfileMenu,
} from './MobileNavigation.utils'

const { SubMenu } = Menu

export const MobileNavigation: FC = React.memo(() => {
    const { userData } = useContext(UserContext)
    const [visible, setMenuVisible] = useState(false)

    const toggleVisible = () => {
        setMenuVisible(backwardFn)
    }

    const handleLogout = () => (window.location.href = LOGOUT_URL)

    const hasAdminPermission = checkRoleBasedPermissions(
        userData?.permissions
    ).includes(UserPermission.Administration)

    return (
        <div className={styles.wrapper}>
            <Button type="primary" onClick={toggleVisible}>
                <IconsAdapter
                    iconType={visible ? 'CloseOutlined' : 'MenuOutlined'}
                />
            </Button>

            <Menu
                mode="inline"
                className={cn(styles.menu, visible && styles.active)}
            >
                {getMainMenu(toggleVisible)}

                <SubMenu
                    title={userData && getShortFio(userData)}
                    key="profileMenu"
                >
                    {getProfileMenu(toggleVisible)}

                    <Menu.Item>
                        <NavLink to="#" onClick={handleLogout}>
                            {LOCAL.EXIT}
                        </NavLink>
                    </Menu.Item>
                </SubMenu>

                {hasAdminPermission && (
                    <SubMenu
                        title={LOCAL.LABELS.ADMIN_MANAGEMENT}
                        key="adminMenu"
                    >
                        {getAdminMenu(toggleVisible)}
                    </SubMenu>
                )}
            </Menu>
        </div>
    )
})
