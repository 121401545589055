import React from 'react'
import { AssessmentReportSearchResultContract, AssessmentType } from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { DictionariesStoreProps } from 'core/helpers/DictionariesHelper'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { LOCAL } from 'core/local'
import { formatDate, getItemName } from 'utils'

import { ReportsTableActions } from './components/ReportsTableActions'

export const getAssessmentReportsColumns = (
    { reFetchDictionary, updateLoader }: DictionaryPageActionsProps,
    dictionary: DictionariesStoreProps
): ColumnsType<AssessmentReportSearchResultContract> => [
    {
        title: LOCAL.LABELS.ASSESSMENT_NAME,
        dataIndex: 'appointmentAssessment',
        key: 'appointmentAssessment',
        render: getItemName,
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizationName',
        key: 'organizationName',
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.STRUCTURAL_UNIT,
        dataIndex: 'unitName',
        key: 'unitName',
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'positionName',
        key: 'positionName',
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.EMPLOYEE,
        dataIndex: 'candidateFio',
        key: 'candidateFio',
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type: AssessmentType) =>
            dictionary.assessmentTypeDictionary[type],
    },
    {
        title: LOCAL.LABELS.DATE_FINISH,
        dataIndex: 'endDate',
        key: 'endDate',
        sorter: defaultSorter,
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.AVAILABLE_TO_EMPLOYEE,
        dataIndex: 'availableToEmployee',
        key: 'availableToEmployee',
        width: 150,
        render: (_, record) => (
            <ReportsTableActions
                tableRow={record}
                reFetchDictionary={reFetchDictionary}
                updateLoader={updateLoader}
            />
        ),
    },
]
