import React from 'react'
import { PageBanner, PageContent, PageTabs } from 'components/shared'
import { useTabsState } from 'hooks'

import { DEFAULT_TAB_KEY, TABS_DATA, TabsKeys } from './TestReport.consts'
import { TabCommonStats, TabReview } from './components'

/** Страница отчета по тесту */
export const TestReport: React.FC = React.memo(() => {
    const TABS_CONFIG = {
        [TabsKeys.Checking]: <TabReview />,
        [TabsKeys.CommonStats]: <TabCommonStats />,
    }

    const { currentTabRender, changeActiveTab } = useTabsState(
        TABS_CONFIG,
        DEFAULT_TAB_KEY
    )

    return (
        <PageContent>
            <PageBanner>
                <PageTabs
                    tabsData={TABS_DATA}
                    defaultTabKey={DEFAULT_TAB_KEY}
                    onChange={changeActiveTab}
                />
            </PageBanner>

            {currentTabRender}
        </PageContent>
    )
})
