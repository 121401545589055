import React, { useCallback, useEffect } from 'react'
import { Col, Form, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    SelectControl,
} from 'components/controls'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps, normalizeDataForSelectAndRadio } from 'utils'
import { LOCAL } from 'core/local'
import {
    OrganizationsService,
    PositionProfilesSearchContract,
    StaffUnitsService,
    StructuralUnitType,
} from 'core/api'
import { ROW_GUTTER } from 'consts'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionaries } from 'hooks'

import {
    JobProfileStaffFiltersDictionaryProps,
    JobProfileStaffUnitsFiltersValuesProps,
} from './JobProfileStaffUnitsFilters.types'
import { getNormalizedUnits } from '../StaffUnitModal/StaffUnitModal.utils'
import { mapFormDataToRequest } from './JobProfileStaffUnitsFilters.utils'
import { staffUnitsShouldFetch } from './JobProfileStaffUnitsFilters.consts'

export const JobProfileStaffUnitsFilters: React.FC<FiltersProps<
    PositionProfilesSearchContract
>> = React.memo(({ onSetFilters, ...props }) => {
    const {
        dictionaries,
        handleChangeDictionaries,
        handleFetchDictionaries,
    } = useDictionaries<JobProfileStaffFiltersDictionaryProps>()
    const [form] = Form.useForm()

    const handleFinish = useCallback(
        (values: Partial<JobProfileStaffUnitsFiltersValuesProps>) => {
            const { staffUnitId } = values

            onSetFilters({
                ...(staffUnitId
                    ? { staffUnitId, showArchive: values.showArchive }
                    : {
                          staffUnitSearchParametres: mapFormDataToRequest(
                              values
                          ),
                      }),
            })
        },
        [onSetFilters]
    )

    const handleValuesChange = useCallback(
        async (
            changedValue: JobProfileStaffUnitsFiltersValuesProps,
            allValues: JobProfileStaffUnitsFiltersValuesProps
        ) => {
            const [changedFieldKey, changedFieldValue] = Object.entries(
                changedValue
            )[0]

            if (changedFieldKey !== 'staffUnitId') {
                const {
                    complexIds,
                    oivIds,
                    organizationsIds,
                    departmentsIds,
                    showArchive,
                } = allValues

                const staffUnits = await StaffUnitsService.getByFilter({
                    body: {
                        complexId: complexIds,
                        oivId: oivIds,
                        organizationId: organizationsIds,
                        departmentId: departmentsIds,
                        showArchive,
                    },
                })

                const normalizedStaffUnits = staffUnits.map(
                    normalizeDataForSelectAndRadio
                )

                if (staffUnitsShouldFetch.includes(changedFieldKey)) {
                    const structuralUnitsNormalized = changedFieldValue
                        ? await getNormalizedUnits(
                              changedFieldKey,
                              changedFieldValue as number
                          )
                        : []

                    switch (changedFieldKey) {
                        case 'complexIds':
                            handleChangeDictionaries({
                                oiv: structuralUnitsNormalized,
                                staffUnits: normalizedStaffUnits,
                            })

                            form.setFieldsValue({
                                oivIds: null,
                                organizationsIds: null,
                                departmentsIds: null,
                                staffUnitId: null,
                            })

                            break
                        case 'oivIds':
                            handleChangeDictionaries({
                                organization: structuralUnitsNormalized,
                                staffUnits: normalizedStaffUnits,
                            })

                            form.setFieldsValue({
                                organizationsIds: null,
                                departmentsIds: null,
                                staffUnitId: null,
                            })

                            break
                        case 'organizationsIds':
                            handleChangeDictionaries({
                                department: structuralUnitsNormalized,
                                staffUnits: normalizedStaffUnits,
                            })

                            form.setFieldsValue({
                                departmentsIds: null,
                                staffUnitId: null,
                            })

                            break
                        case 'departmentsIds':
                            handleChangeDictionaries({
                                staffUnits: normalizedStaffUnits,
                            })

                            form.setFieldsValue({
                                staffUnitId: null,
                            })

                            break
                    }
                } else {
                    handleChangeDictionaries({
                        staffUnits: normalizedStaffUnits,
                    })
                }
            }
        },
        [form, handleChangeDictionaries]
    )

    useEffect(() => {
        handleFetchDictionaries({
            complex: OrganizationsService.getAllByUnitType({
                type: StructuralUnitType.Complex,
            }),
        })
    }, [handleFetchDictionaries])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                onSetFilters={handleFinish}
                form={form}
                onValuesChange={handleValuesChange}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="complexIds"
                            label={LOCAL.LABELS.COMPLEX}
                        >
                            <SelectControl
                                values={dictionaries?.complex || []}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="oivIds"
                            label={LOCAL.LABELS.OIV}
                        >
                            <SelectControl
                                values={dictionaries?.oiv || []}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="organizationsIds"
                            label={LOCAL.LABELS.ORGANIZATION}
                        >
                            <SelectControl
                                values={dictionaries?.organization || []}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="departmentsIds"
                            label={LOCAL.LABELS.STRUCTURAL_UNIT}
                        >
                            <SelectControl
                                values={dictionaries?.department || []}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="staffUnitId"
                            label={LOCAL.LABELS.POSITION}
                        >
                            <SelectControl
                                values={dictionaries?.staffUnits || []}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
