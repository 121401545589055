import { LabeledValue } from 'antd/lib/select'
import { RowSelectionType } from 'antd/lib/table/interface'
import {
    StructuralUnitPositionContract,
    UserModalContract,
    UsersService,
} from 'core/api'
import { getFullFio } from 'utils'

/**
 * Тип входных данных для функции нормализатора
 */
export type NormalizeResponseDefaultProps = {
    name: string
    id: number
}

/**
 * Нормализует данные для `options` контрола, по дефолту
 * @param response - ответ от сервера
 */
export function normalizeResponse<T extends NormalizeResponseDefaultProps>(
    response?: T[] | T
): LabeledValue[] {
    if (!response) return []

    if (!Array.isArray(response))
        return [
            {
                label: response.name,
                value: response.id,
            },
        ]

    return response.map((value) => ({
        label: value.name,
        value: value.id,
    }))
}

/**
 * Нормализует данные для `options` контрола, для должностей
 * @param response - ответ от сервера
 */
export function normalizePositionSearchResponse(
    response?: StructuralUnitPositionContract[]
): LabeledValue[] {
    if (!response) return []

    return response.map((value) => ({
        label: value.positionName,
        value: value.positionId,
        key: String(value.positionId),
    }))
}

/**
 * Нормализует данные для `options` контрола, для юзеров
 * @param response - ответ от сервера
 */
export function normalizeUsersSearchResponse(
    response?: UserModalContract[]
): LabeledValue[] {
    if (!response) return []

    return response?.map((value) => ({
        label: getFullFio({
            firstName: value.firstName,
            lastName: value.lastName,
            patronymic: value.patronymic,
        }),
        value: value.id,
    }))
}

export const isRowSelectionTypeCheckbox = (mode: RowSelectionType) =>
    mode === 'checkbox'

export const getUserByIdsMethod = async (params: { body?: any | null[] }) => {
    const response = await UsersService.getByIds(params)

    return response.map((el) => ({
        ...el,
        name: getFullFio({
            firstName: el.firstName,
            lastName: el.lastName,
            patronymic: el.patronymic,
        }),
    }))
}
