import React, { FC, useCallback, useEffect, useState } from 'react'
import {
    AppError,
    CompetenceGroupContract,
    CompetenceGroupsService,
    DictionaryContract,
    PositionProfileDictionariesService,
    UserPermission,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import {
    CheckboxControl,
    FormItemAdapter,
    HiddenField,
    InputControl,
    MultiSelectControl,
    TextAreaControl,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { isDocumentStatusReadyForWork, normalizeDataForTreeSelect } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { withLoader } from 'HOCs'

import { mapResponseToFormData } from './CompetenciesGroupForm.utils'

/** Форма добавления/редактирования группы компетенций */
export const CompetenciesGroupForm: FC<PopupAdapterFormProps> = withLoader(
    React.memo(
        ({
            onRequestFinish,
            deleteFormAction,
            initialValues,
            recordCopy,
            rowSelectionType,
            onCancelSubmit,
            messageText,
            updateLoader,
            updateButtonVisibility,
            ...props
        }) => {
            const [levels, setLevels] = useState<DictionaryContract[]>()

            /**
             * Стейт для управления типом отправки формы (сохранение/публикация)
             */
            const [isSaveDocument, setSaveDocument] = useState(false)

            /**
             * Обработчик сохранения/публикации документа
             */
            const handleSubmitForm = useCallback(
                (type: boolean) => () => {
                    setSaveDocument(type)
                },
                []
            )

            /**
             * Обработчик отправки формы
             */
            const handleFinish = useCallback(
                async (body) => {
                    try {
                        updateLoader(true)
                        updateButtonVisibility?.(true)
                        if (isSaveDocument) {
                            onRequestFinish?.(
                                await CompetenceGroupsService.save({ body })
                            )

                            return
                        }

                        onRequestFinish?.(
                            await CompetenceGroupsService.publish({ body })
                        )
                    } catch (error) {
                        onRequestFinish?.(error as AppError)
                    } finally {
                        updateLoader(false)
                        updateButtonVisibility?.(false)
                    }
                },
                [
                    isSaveDocument,
                    onRequestFinish,
                    updateButtonVisibility,
                    updateLoader,
                ]
            )

            useEffect(() => {
                const fetch = async () => {
                    try {
                        setLevels(
                            await PositionProfileDictionariesService.getAllLevels()
                        )
                    } catch (error) {
                        console.error(error)
                    }
                }
                fetch()
            }, [])

            return (
                <Form
                    onFinish={handleFinish}
                    {...props}
                    initialValues={mapResponseToFormData(
                        initialValues as CompetenceGroupContract
                    )}
                >
                    <HiddenField fieldName="id" />

                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.NAME}
                        required
                    >
                        <InputControl />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName="description"
                        label={LOCAL.LABELS.DESCRIPTION}
                    >
                        <TextAreaControl />
                    </FormItemAdapter>

                    <Form.Item noStyle dependencies={['defaultGroup']}>
                        {({ getFieldValue }) => (
                            <FormItemAdapter
                                fieldName="levelIds"
                                label={LOCAL.LABELS.USE_FOR_POSITION_LEVELS}
                            >
                                <MultiSelectControl
                                    treeData={levels?.map(
                                        normalizeDataForTreeSelect
                                    )}
                                    disabled={getFieldValue('defaultGroup')}
                                />
                            </FormItemAdapter>
                        )}
                    </Form.Item>

                    <Row>
                        <Col xs={12}>
                            <Form.Item noStyle dependencies={['levelIds']}>
                                {({ getFieldValue }) => (
                                    <FormItemAdapter fieldName="defaultGroup">
                                        <CheckboxControl
                                            disabled={
                                                getFieldValue('levelIds')
                                                    ?.length
                                            }
                                        >
                                            {
                                                LOCAL.LABELS
                                                    .POSITION_PROFILE_AUTO_ADD_COMPETENCE
                                            }
                                        </CheckboxControl>
                                    </FormItemAdapter>
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={12}>
                            <Form.Item noStyle dependencies={['levelIds']}>
                                {({ getFieldValue }) => (
                                    <FormItemAdapter fieldName="isManager">
                                        <CheckboxControl
                                            disabled={
                                                !getFieldValue('levelIds')
                                                    ?.length
                                            }
                                        >
                                            {LOCAL.LABELS.MANAGERIAL_GROUP}
                                        </CheckboxControl>
                                    </FormItemAdapter>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <ButtonsToolbar bottomIndent="0">
                        <Button type="link" onClick={onCancelSubmit}>
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        {!isDocumentStatusReadyForWork(
                            initialValues?.status
                        ) && (
                            <HasPermissions
                                requiredPermissions={[
                                    UserPermission.CompetenceGroupRead,
                                    UserPermission.CompetenceGroupSave,
                                ]}
                                accessDeniedRender={renderAccessDeniedButton({
                                    type: 'primary',
                                    text: LOCAL.ACTIONS.SAVE,
                                })}
                            >
                                <Button
                                    form={FORM_IDS.COMPETENCIES_GROUP_FORM}
                                    type="primary"
                                    htmlType="submit"
                                    onClick={handleSubmitForm(true)}
                                >
                                    {LOCAL.ACTIONS.SAVE}
                                </Button>
                            </HasPermissions>
                        )}

                        <HasPermissions
                            requiredPermissions={[
                                UserPermission.CompetenceGroupRead,
                                UserPermission.CompetenceGroupPublish,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                type: 'primary',
                                text: LOCAL.ACTIONS.PUBLISH,
                            })}
                        >
                            <Button
                                form={FORM_IDS.COMPETENCIES_GROUP_FORM}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmitForm(false)}
                            >
                                {LOCAL.ACTIONS.PUBLISH}
                            </Button>
                        </HasPermissions>
                    </ButtonsToolbar>
                </Form>
            )
        }
    )
)
