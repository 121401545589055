import stylesDictionaryPage from 'components/layouts/DictionaryPage/DictionaryPage.module.scss'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Row } from 'antd'
import {
    ColumnsOptions,
    ContentTitle,
    FiltersToggleButton,
    PageBanner,
    PageContent,
    PageTabs,
    Popup,
    QuickSearch,
    TableAdapter,
    getRowClassNameArchive,
} from 'components/shared'
import { CourseSearchResultContract, CoursesService } from 'core/api'
import { DICTIONARY_KEYS } from 'core/configs'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    RequestQueryProps,
    useBreakpoint,
    useElementVisible,
    useFilter,
    usePageSettings,
} from 'hooks'
import {
    createSuccessNotification,
    getPageSize,
    getTableColumnsWithClass,
    showElem,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { pageTabsRender } from 'components/shared/PageTabs/PageTabs.utils'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import styles from './TrainingMaterials.module.scss'
import { ORDER_RULES } from './TrainingMaterials.consts'
import { TRAINING_MATERIALS_TABLE_COLUMNS } from './TrainingMaterials.config'
import { TrainingMaterialsFilters, UploadCourseForm } from './components'

/** Справочник обучающих материалов */
export const TrainingMaterials: FC = withLoader(
    React.memo(({ updateLoader }) => {
        const isWidthLessXXL = useBreakpoint('XXL')
        const { columnsVisible, saveFilters } = usePageSettings(
            DICTIONARY_KEYS.TRAINING_MATERIALS
        )
        const [tableData, setTableData] = useState<
            CourseSearchResultContract[]
        >()
        const [recordsCount, setRecordsCount] = useState(0)
        const {
            elementVisible: uploadFormVisible,
            handleHideElement: hideUploadForm,
            handleShowElement: showUploadForm,
        } = useElementVisible()

        const [visibleFilter, toggleFilterVisibility] = useFilter()

        const {
            paginationOptions,
            queryParams,
            handleSetFilters,
            handleResetFilters,
            handleSort,
        } = useSearchQuery({
            dictionaryKey: DICTIONARY_KEYS.TRAINING_MATERIALS,
            queryOptions: {
                body: {
                    orderRules: ORDER_RULES,
                },
            },
        })

        /**
         * Запрос данных справочника
         */
        const dictionaryFetch = useCallback(async () => {
            try {
                updateLoader(true)

                const dataSource = queryParams.body?.searchText
                    ? await CoursesService.searchText(
                          queryParams as Required<RequestQueryProps>
                      )
                    : await CoursesService.search(
                          queryParams as Required<RequestQueryProps>
                      )
                setTableData(dataSource.pageItems)
                setRecordsCount(dataSource.total)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [queryParams, updateLoader])

        /**
         * Итоговые настройки для отображения колонок
         */
        const tableColumnsCalculated = useMemo(
            () =>
                getTableColumnsWithClass<CourseSearchResultContract>(
                    columnsVisible,
                    TRAINING_MATERIALS_TABLE_COLUMNS
                ),
            [columnsVisible]
        )

        useEffect(() => {
            dictionaryFetch()
        }, [dictionaryFetch])

        const handleUploadMaterial = useCallback(() => {
            hideUploadForm()
            dictionaryFetch()
        }, [hideUploadForm, dictionaryFetch])

        useEffect(() => {
            if (saveFilters) {
                createSuccessNotification(LOCAL.MESSAGES.SAVE_FILTERS)
            }
        }, [saveFilters])

        return (
            <PageContent filled className={stylesDictionaryPage.wrapper}>
                <PageBanner>
                    <PageTabs tabsData={pageTabsRender(recordsCount)} />
                </PageBanner>

                <ContentTitle
                    title={getRouteMeta(ROUTE_NAMES.TRAINING_MATERIALS)}
                >
                    <Row
                        gutter={15}
                        justify={isWidthLessXXL ? undefined : 'end'}
                        align="middle"
                    >
                        <Col>
                            <QuickSearch
                                onSetFilters={handleSetFilters}
                                isReset={visibleFilter}
                            />
                        </Col>

                        <Col>
                            <FiltersToggleButton
                                onToggleFilterVisibility={
                                    toggleFilterVisibility
                                }
                                isActive={saveFilters}
                            />
                        </Col>

                        <Col>
                            <Button onClick={showUploadForm}>
                                {LOCAL.ACTIONS.UPLOAD}
                            </Button>

                            <Popup
                                visible={uploadFormVisible}
                                footer={null}
                                width={MODAL_WIDTH.MD}
                                onCancel={hideUploadForm}
                                title={LOCAL.ACTIONS.ADD_MATERIAL}
                                className={styles.modal}
                                destroyOnClose
                            >
                                <UploadCourseForm
                                    onRequestFinish={handleUploadMaterial}
                                    onCancelSubmit={hideUploadForm}
                                />
                            </Popup>
                        </Col>
                    </Row>
                </ContentTitle>

                <div className={showElem(visibleFilter)}>
                    <TrainingMaterialsFilters
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                        dictionaryKey={DICTIONARY_KEYS.TRAINING_MATERIALS}
                    />
                </div>

                <ColumnsOptions<CourseSearchResultContract>
                    dictionaryKey={DICTIONARY_KEYS.TRAINING_MATERIALS}
                    tableColumns={tableColumnsCalculated}
                />

                <TableAdapter
                    columns={tableColumnsCalculated}
                    dataSource={tableData}
                    locale={{ emptyText: LOCAL.LABELS.EMPTY_DATA }}
                    pagination={{
                        ...paginationOptions,
                        total: recordsCount,
                        current: queryParams.pageNumber,
                        pageSize: getPageSize(
                            queryParams?.pageSize,
                            tableData?.length
                        ),
                    }}
                    rowClassName={getRowClassNameArchive}
                    onChange={handleSort}
                    showSorterTooltip={false}
                />
            </PageContent>
        )
    })
)
