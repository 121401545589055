import { ChartOptions, InteractionMode } from 'chart.js'
import { DatasetItemConfigProps } from 'utils'
import { LOCAL } from 'core/local'

import styles from './CompetenceAssessmentResults.module.scss'
import { AcceptedKeysProps } from './CompetenceAssessmentResults.types'

/** Набор визуальных настроек диаграммы "паутинка"/"радар" */
export const RADAR_CHART_OPTIONS: ChartOptions = {
    plugins: {
        legend: {
            display: false,
        },
    },
    hover: {
        mode: 'point' as InteractionMode,
    },
    elements: {
        line: {
            borderWidth: 1,
        },
        point: {
            radius: 1,
        },
    },
    scales: {
        r: {
            suggestedMin: 0,
            suggestedMax: 5,
            ticks: {
                stepSize: 0.5,
            },
        },
        ticks: {
            display: false,
        },
    },
    maintainAspectRatio: false,
}

/** Набор объектов с настройками для создания dataset'а диаграммы типа "паутинка"/"радар" */
export const DATASET_ITEM_CONFIGS_COLLECTION: DatasetItemConfigProps<
    AcceptedKeysProps
>[] = [
    {
        dataKey: 'managerResult',
        label: LOCAL.LABELS.EMPLOYEE_ASSESSMENT_BY_MANAGER,
        borderColor: '#67B5EB',
        backgroundColor: '#67B5EB19',
        className: styles.manager,
    },
    {
        dataKey: 'selfAssessmentResult',
        label: LOCAL.LABELS.EMPLOYEE_ASSESSMENT_SELF,
        borderColor: '#E2976D',
        backgroundColor: '#E2976D19',
        className: styles.self,
    },
    {
        dataKey: 'positionProfileLevel',
        label: LOCAL.LABELS.POSITION_PROFILE,
        borderColor: '#4FA84D',
        backgroundColor: '#4FA84D19',
        className: styles.profile,
    },
]

export const LABELS_CHECKED = {
    managerResult: true,
    selfAssessmentResult: true,
    positionProfileLevel: true,
}
