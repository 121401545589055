import React, { FC } from 'react'
import {
    AdaptationTracksService,
    TrackSearchContract,
    TrackSearchResultContract,
    UserPermission,
} from 'core/api'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CREATION_DATE,
} from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TRACK_ADAPTATION_PERMISSIONS } from 'consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'
import { getRowHandlers } from 'utils'

import { ADAPTATION_TRACKS_TABLE_COLUMNS } from './TracksAdaptation.consts'
import { TrackPageFilters } from './components'

export const TracksAdaptation: FC = React.memo(() => (
    <DictionaryPage<TrackSearchContract, TrackSearchResultContract>
        dictionaryKey={DICTIONARY_KEYS.ADAPTATION_TRACKS}
        searchMethod={AdaptationTracksService.search}
        deleteMethod={AdaptationTracksService.setAsArchive}
        contentTitle={getRouteMeta(ROUTE_NAMES.TRACKS_ADAPTATION)}
        tableColumns={ADAPTATION_TRACKS_TABLE_COLUMNS}
        createRecordLink={{
            path: ROUTE_NAMES.TRACKS_ADAPTATION_CREATE,
        }}
        filterComponent={TrackPageFilters}
        tableFiltersOptions={{
            initialValues: {
                orderRules: ORDER_RULES_WITH_CREATION_DATE,
            },
        }}
        quickSearchMethod={AdaptationTracksService.searchText}
        createPermissions={[
            ...TRACK_ADAPTATION_PERMISSIONS,
            UserPermission.AdaptationTrackSave,
        ]}
        rowClassName={getRowClassNameArchive}
        onRow={getRowHandlers({ url: ROUTE_NAMES.TRACKS_ADAPTATION_VIEW })}
    />
))
