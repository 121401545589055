import React from 'react'
import unescape from 'lodash/unescape'
import { Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { TAG_VALUE_ATTRIBUTE } from 'components/controls/TextRedactor/TextRedactor.consts'
import { getFieldsPath } from 'utils'

import { PasteTextInput } from '../PasteTextInput'

/**
 * Конвентируем строку в массив с текстом и порядковыми номерами блоков (инпутов)
 * @param block блок с инпута для вставки пропущенного текста
 * @param inputRegExp RegExp для инпута
 */
const getFormattedArray = (block: string, inputRegExp: RegExp) =>
    block.split(inputRegExp).map((el, i) => {
        if (el.includes(TAG_VALUE_ATTRIBUTE)) {
            const valueRegExp = new RegExp('value-for-backend="([^"]+)"', 'g')

            return +(valueRegExp.exec(el)?.[1] || 0)
        }

        return el === '<br>' ? <br key={i} /> : el.replace('<br>', '')
    })

/**
 * Рендер контента с инпутом для вставки пропущенного ответа
 * @param formattedArr - Отформатированный массив
 * @param fieldName
 */
const renderFillEmptyTextContent = (
    formattedArr: (string | number | JSX.Element)[],
    fieldName?: NamePath
) =>
    formattedArr.map((el) => {
        if (typeof el === 'number')
            return (
                <Form.Item
                    noStyle
                    key={el}
                    name={getFieldsPath(['missingTextBlocks', el], fieldName)}
                >
                    <PasteTextInput blockNumber={el} />
                </Form.Item>
            )

        return el
    })

/**
 * Реплейс для текста с бэка при прохождении
 * @param text - текст
 * @param fieldName
 */
export const getQuestionTextForPassing = (
    text?: string,
    fieldName?: NamePath
) => {
    const nbspRegExp = new RegExp('&nbsp;', 'g')
    const inputRegExp = new RegExp('(<[^>]*value-for-backend[^>]*>)', 'g')

    const tempHTML = unescape(text)
        .replace(nbspRegExp, '\u00A0')
        .replace(/<\/div>/g, '')

    // Разбираем строку по дивам, каждый див - новая строчка, согласно текущей реализации редактора
    return tempHTML.split('<div>').map((block, index) => (
        <div className="mb-10" key={index}>
            {renderFillEmptyTextContent(
                getFormattedArray(block, inputRegExp),
                fieldName
            )}
        </div>
    ))
}
