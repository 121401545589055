import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import { Form, Radio, Space, Spin, Upload } from 'antd'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { MEDIA_DOCUMENT_TYPE } from 'consts'
import { RadioChangeEvent } from 'antd/lib/radio'
import { RcCustomRequestOptions } from 'types'
import { ShouldUpdateChecker } from 'components/controls'
import { WithFormInstanceProps } from 'App.types'
import {
    createConfirmPopup,
    getElemById,
    getStringWithoutDots,
    validateUploadImage,
} from 'utils'
import { useElementVisible } from 'hooks'
import { useLocation } from 'react-router'

import styles from './CanvasImagesWithInfiniteScroll.module.scss'
import {
    CanvasImagesWithInfiniteScrollProps,
    ImgsFetchFnResponseProps,
} from './CanvasImagesWithInfiniteScroll.types'
import {
    IMGS_FETCH_COUNT,
    INFINITE_SCROLL_OPTIONS,
} from './CanvasImagesWithInfiniteScroll.consts'
import { TooltipAdapter } from '../TooltipAdapter'
import { VisualizationOptionsGroup } from '../VisualizationOptionsGroup'

/**
 * Компонент для отображения изображений и иконок полотна,
 * с функциональностью подгрузки при скролле (infinite scroll)
 */
export const CanvasImagesWithInfiniteScroll: React.FC<CanvasImagesWithInfiniteScrollProps> = ({
    fileListTitle,
    imgMapFn,
    imgsFetchFn,
    imgsUploadFn,
    controlName,
    controlMetaName,
    downloadBtnText = LOCAL.ACTIONS.UPLOAD,
    downloadAcceptTypes = MEDIA_DOCUMENT_TYPE,
    fileListEmptyText = LOCAL.LABELS.EMPTY_LIST,
    className,
    onImgChoose,
    imgsFetchCount = IMGS_FETCH_COUNT,
    disabled,
    getContainerForPopup,
    deleteMethod,
    required,
    validateFileFormats,
    showLoadBtn = true,
}) => {
    const {
        updateElementVisible: updateLoader,
        elementVisible: isLoading,
    } = useElementVisible()

    const [fileList, setFileList] = useState<
        ImgsFetchFnResponseProps['pageItems']
    >([])

    const [hasMore, setHasMore] = useState<boolean>(true)

    const location = useLocation()

    /**
     * Запрос фоновых изображений
     */
    const imgsFetchMore = useCallback(async () => {
        try {
            updateLoader(true)

            const dataSource = await imgsFetchFn(
                imgsFetchCount,
                Math.ceil(fileList.length / imgsFetchCount) + 1
            )

            if (!dataSource.pageItems.length) return

            const images = [...fileList, ...dataSource.pageItems]

            setFileList(images)

            if (images.length < dataSource.total) {
                setHasMore(true)
            } else {
                setHasMore(false)
            }
        } catch (error) {
            console.error(error)
        } finally {
            updateLoader(false)
        }
    }, [fileList, imgsFetchCount, imgsFetchFn, updateLoader])

    /**
     * Запрос фоновых изображений: после загрузки
     */
    const imgsFetchAfterUpload = useCallback(async () => {
        try {
            document.querySelector(`.${styles.fields}`)?.scrollTo(0, 0)

            const dataSource = await imgsFetchFn(imgsFetchCount, 1)

            setFileList(dataSource.pageItems)

            if (dataSource.pageItems.length < dataSource.total) {
                setHasMore(true)
            } else {
                setHasMore(false)
            }
        } catch (error) {
            console.error(error)
        }
    }, [imgsFetchCount, imgsFetchFn, setFileList])

    /**
     * Отправка файла на сервер
     * @param request - тело запроса
     */
    const uploadImage = useCallback(
        async (request: RcCustomRequestOptions) => {
            try {
                const result = await imgsUploadFn(request)

                request.onSuccess?.(result, {} as XMLHttpRequest)
                imgsFetchAfterUpload()
            } catch (error) {
                request.onError?.(error as Error)
            }
        },
        [imgsFetchAfterUpload, imgsUploadFn]
    )

    /** Колбэк подтверждения удаления изображения
     * @param fileId - id изображения
     * @param form - form instance
     */
    const onConfirmDelete = useCallback(
        (fileId: number, form: WithFormInstanceProps['form']) => async () => {
            try {
                const isEditMode = location.pathname.indexOf('edit') !== -1

                const { trackType: type, id: trackId } = form?.getFieldsValue()

                await deleteMethod?.({
                    id: fileId,
                    body: isEditMode
                        ? {
                              type,
                              trackId,
                          }
                        : {
                              trackId: undefined,
                              type: undefined,
                          },
                })

                const chosenImageId = form?.getFieldValue(controlName)

                if (chosenImageId === fileId) {
                    form?.setFields([
                        { name: controlMetaName, value: undefined },
                        { name: controlName, value: undefined },
                    ])
                }

                setFileList((prev) => prev.filter(({ id }) => id !== fileId))
            } catch (error) {
                console.error(error)
            }
        },
        [controlMetaName, controlName, deleteMethod, location.pathname]
    )

    /**
     * Обработчик удаления изображения
     */
    const handleRemoveImage = useCallback(
        (fileId: number, form?: WithFormInstanceProps['form']) => () => {
            createConfirmPopup({
                content: LOCAL.MESSAGES.ATTENTION_CONFIRM_DELETE,
                className: styles.modal,
                getContainer: getContainerForPopup,
                onOk: onConfirmDelete(fileId, form),
            })
        },
        [getContainerForPopup, onConfirmDelete]
    )

    /**
     * Маппинг для элементов формы (радио)
     */
    const imgsMapped = useMemo(
        () => (
            <ShouldUpdateChecker fieldPath={controlName}>
                {(form) =>
                    fileList.map((file) =>
                        imgMapFn(
                            file,
                            disabled
                                ? undefined
                                : deleteMethod &&
                                      handleRemoveImage(file.id, form)
                        )
                    )
                }
            </ShouldUpdateChecker>
        ),
        [
            controlName,
            fileList,
            imgMapFn,
            deleteMethod,
            handleRemoveImage,
            disabled,
        ]
    )

    const handleImageChoose = useCallback(
        (e: RadioChangeEvent) => {
            const chosenBg = getElemById(fileList, e.target.value)

            onImgChoose?.({ url: chosenBg?.url, id: chosenBg?.id })
        },
        [fileList, onImgChoose]
    )

    useEffect(() => {
        if (!fileList.length) imgsFetchMore()
    }, [fileList.length, imgsFetchMore])

    return (
        <VisualizationOptionsGroup
            title={fileListTitle}
            className={cn(styles.wrapper, className)}
            required={required}
        >
            <div>
                <Spin spinning={isLoading}>
                    <Form.Item name={controlMetaName} noStyle>
                        <InputControl type="hidden" />
                    </Form.Item>

                    {fileList.length ? (
                        <Form.Item name={controlName}>
                            <Radio.Group
                                onChange={handleImageChoose}
                                className={styles.radioGroup}
                                disabled={disabled}
                            >
                                <InfiniteScroll
                                    dataLength={fileList.length}
                                    next={imgsFetchMore}
                                    hasMore={hasMore}
                                    className={cn(
                                        styles.fields,
                                        disabled && styles.fieldsDisabled
                                    )}
                                    {...INFINITE_SCROLL_OPTIONS}
                                >
                                    {imgsMapped}
                                </InfiniteScroll>
                            </Radio.Group>
                        </Form.Item>
                    ) : (
                        <Space
                            align="center"
                            className={styles.emptyText}
                        >{`${fileListEmptyText}...`}</Space>
                    )}
                </Spin>
            </div>

            {showLoadBtn && (
                <div className={styles.download}>
                    <Upload
                        beforeUpload={(file, fileList) =>
                            validateUploadImage(
                                file,
                                fileList,
                                validateFileFormats
                            )
                        }
                        accept={downloadAcceptTypes}
                        showUploadList={false}
                        customRequest={uploadImage}
                    >
                        <TooltipAdapter
                            title={getStringWithoutDots(downloadAcceptTypes)}
                            placement="top"
                        >
                            <Button disabled={disabled}>
                                {downloadBtnText}
                            </Button>
                        </TooltipAdapter>
                    </Upload>
                </div>
            )}
        </VisualizationOptionsGroup>
    )
}
