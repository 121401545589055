import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import {
    AdaptationTracksService,
    AppointmentTrackContract,
    AppointmentTrackPublishContract,
    AppointmentTracksService,
    DevelopmentTracksService,
    TrackAppointmentType,
    TrackModalContract,
    TrackModalSearchContract,
    TrackShortInfoContract,
    TrackType,
    TracksMguuSearchContract,
    TracksMguuService,
} from 'core/api'
import {
    AddUsersTableControl,
    CheckboxControl,
    DatePickerControl,
    FormItemAdapter,
    GetByIdsMethodParamProps,
    InputControl,
    SearchSelectControl,
    SelectControl,
    ShouldUpdateChecker,
    TRACK_COLUMNS,
    TracksFilters,
} from 'components/controls'
import { AddonAfterDefault } from 'components/shared'
import { Col, Form, Row } from 'antd'
import { FORM_IDS } from 'core/configs'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    ROW_GUTTER,
    TRACKS_APPOINTMENT_TYPES_MAPPED,
    TRACK_TYPES_MAPPED,
} from 'consts'
import { RcFile } from 'antd/lib/upload/interface'
import { WithLoaderProps, withBlockUserRouting } from 'HOCs'
import {
    createConfirmPopup,
    disableGroupOfDate,
    getAssignmentFormPopupTitle,
    getInitialValuesForCopy,
    isFormModeCopy,
    isFormModeView,
    isObjectEmpty,
    isTrackTypeAdaptation,
    isTrackTypeDevelopment,
    isTrackTypeUnique,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'
import { useHttp } from 'hooks'

import styles from './TrackAssignmentForm.module.scss'
import {
    INITIAL_FORM_VALUES,
    MIN_DAYS_TO_NOTIFICATION,
    TRACK_SEARCH_METHOD_BODY,
} from './TrackAssignmentForm.consts'
import { TrackAssignmentFormSubmitValues } from './TrackAssignmentForm.types'
import { TrackAssignmentSettings } from './components'
import {
    getTracksSearchOptions,
    mapFormDataToRequest,
    mapResponseToFormData,
    shouldFetchTrackAdaptation,
    shouldRemoveTrackCheckingPersons,
    shouldRemoveTrackStagesSettings,
} from './TrackAssignmentForm.utils'

/**
 * Форма создания назначения трека
 */
const TrackAssignmentForm: React.FC<
    FormProps<AppointmentTrackContract> & Partial<WithLoaderProps>
> = ({
    onBlockUserRouting,
    isSaveDocument,
    initialValuesForEdit,
    reFetchInitialFormValues,
    formMode,
    updateLoader,
}) => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [getUniqueTracks, uniqueTracks] = useHttp(TracksMguuService.search)

    /**
     * Обработчик отправки формы
     */
    const handleFinish = useCallback(
        async (values: Partial<TrackAssignmentFormSubmitValues>) => {
            try {
                updateLoader?.(true)

                const body = mapFormDataToRequest(
                    values,
                    !isFormModeCopy(formMode)
                        ? initialValuesForEdit?.id
                        : undefined
                ) as AppointmentTrackPublishContract

                if (isSaveDocument) {
                    await AppointmentTracksService.save({ body })
                } else {
                    await AppointmentTracksService.publish({ body })
                }

                onBlockUserRouting?.(false)

                createConfirmPopup({
                    title: isSaveDocument
                        ? LOCAL.MESSAGES.ASSIGNMENT_SAVE_SUCCESS_MESSAGE
                        : LOCAL.MESSAGES.ASSIGNMENT_PUBLISH_SUCCESS_MESSAGE,
                    content: getAssignmentFormPopupTitle(
                        getRouteMeta(ROUTE_NAMES.TRACKS_ASSIGNMENTS)
                    ),
                    onOk: () => {
                        history.push(ROUTE_NAMES.TRACKS_ASSIGNMENTS)
                    },
                    onCancel: () => {
                        if (!initialValuesForEdit?.id) form.resetFields()
                        else
                            reFetchInitialFormValues?.(initialValuesForEdit?.id)
                    },
                    okText: LOCAL.YES,
                    cancelText: LOCAL.NO,
                })
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader?.(false)
            }
        },
        [
            form,
            formMode,
            history,
            initialValuesForEdit,
            isSaveDocument,
            onBlockUserRouting,
            reFetchInitialFormValues,
            updateLoader,
        ]
    )

    /**
     * Обертка для метода запроса по id
     * @param body - тело запроса
     */
    const getByIdsMethod = useCallback(
        ({ body }: GetByIdsMethodParamProps) => {
            if (!body?.length) return

            return (isTrackTypeAdaptation(form.getFieldValue('trackType'))
                ? AdaptationTracksService.getActiveNoPermissions
                : DevelopmentTracksService.getActiveNoPermissions)({
                id: body[0],
            })
        },
        [form]
    )

    const getSelectTrackControl = (trackType: TrackType) => {
        switch (trackType) {
            case TrackType.Mguu:
                return (
                    <FormItemAdapter
                        fieldName="mguuTrackId"
                        required
                        label={LOCAL.LABELS.TRACK_TEMPLATE}
                    >
                        <SelectControl
                            values={
                                uniqueTracks?.pageItems?.map(
                                    normalizeDataForSelectAndRadio
                                ) || []
                            }
                        />
                    </FormItemAdapter>
                )

            default:
                if (!trackType) return

                const {
                    link,
                    permissions,
                    ...modalOptions
                } = getTracksSearchOptions(trackType)!

                return (
                    <ShouldUpdateChecker fieldPath={modalOptions?.name}>
                        {({ getFieldValue }) => {
                            const trackId = getFieldValue(
                                modalOptions?.name
                            )?.[0].value

                            return (
                                <FormItemAdapter
                                    fieldName={modalOptions?.name}
                                    label={modalOptions?.label}
                                    required
                                >
                                    <SearchSelectControl<
                                        TrackModalSearchContract,
                                        | TrackModalContract
                                        | TrackShortInfoContract
                                    >
                                        getByIdsMethod={getByIdsMethod}
                                        formMode={formMode}
                                        modalTitle={
                                            LOCAL.LABELS
                                                .TRACK_TEMPLATE_SELECTION
                                        }
                                        addonAfter={
                                            <AddonAfterDefault
                                                url={`${link}/${trackId}`}
                                                disabled={!trackId}
                                                permissionsRequired={
                                                    permissions
                                                }
                                            />
                                        }
                                        tableSearchOptions={{
                                            searchMethod:
                                                modalOptions?.searchMethod,
                                            tableFiltersOptions: {
                                                initialValues: TRACK_SEARCH_METHOD_BODY,
                                            },
                                            tableColumns: TRACK_COLUMNS,
                                            filterComponent: TracksFilters,
                                        }}
                                    />
                                </FormItemAdapter>
                            )
                        }}
                    </ShouldUpdateChecker>
                )
        }
    }

    /**
     * Обработчик изменения значений полей формы
     * @param changedValue значение поля на котором сработало событие
     * @param allValues все значения, включая `changedValue`
     */
    const handleValuesChange = useCallback(
        async (
            changedValue: TrackAssignmentFormSubmitValues,
            allValues: TrackAssignmentFormSubmitValues
        ) => {
            const currentAllValues = allValues as Partial<
                TrackAssignmentFormSubmitValues
            >

            const changedValueKey = Object.keys(changedValue)[0]

            if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                onBlockUserRouting?.()
            }

            if (changedValueKey === 'isDuration') {
                form.setFieldsValue({
                    duration: changedValue[changedValueKey] ? 1 : undefined,
                })
            }

            if (changedValueKey === 'period') {
                form.setFieldsValue({
                    stageSettings: allValues.stageSettings?.map(
                        ({ periodFrom, periodTo, ...restStageSettings }) => ({
                            ...restStageSettings,
                            periodFrom: undefined,
                            periodTo: undefined,
                        })
                    ),
                })
            }

            if (changedValueKey === 'isNotificationEnable') {
                form.setFieldsValue({
                    daysToEndNotification: allValues.isNotificationEnable
                        ? MIN_DAYS_TO_NOTIFICATION
                        : undefined,
                    daysToStartNotification: allValues.isNotificationEnable
                        ? MIN_DAYS_TO_NOTIFICATION
                        : undefined,
                })
            }

            if (changedValueKey === 'trackType') {
                form.setFieldsValue({
                    appointmentType: TrackAppointmentType.ByTrack,
                    adaptationTrackId: null,
                    developmentTrackId: null,
                    mguuTrackId: null,
                })

                currentAllValues.adaptationTrackId = undefined
                currentAllValues.developmentTrackId = undefined
                currentAllValues.mguuTrackId = undefined
            }

            if (
                changedValueKey === 'periodFrom' ||
                changedValueKey === 'duration'
            ) {
                form.setFieldsValue({
                    homeworkAutoCheckDate: null,
                })
            }

            if (shouldFetchTrackAdaptation(currentAllValues, changedValueKey)) {
                try {
                    updateLoader?.(true)

                    const trackId = Number(
                        currentAllValues.adaptationTrackId?.[0]?.value ||
                            currentAllValues.developmentTrackId?.[0]?.value
                    )

                    const track = await getByIdsMethod({
                        body: [trackId],
                    })

                    form.setFieldsValue({
                        stageSettings: Array(track?.countStages || 0).fill(
                            undefined
                        ),
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            }

            if (shouldRemoveTrackStagesSettings(allValues)) {
                form.setFieldsValue({ stageSettings: [] })
            }

            if (shouldRemoveTrackCheckingPersons(changedValueKey, allValues)) {
                form.setFieldsValue({ checkingPersons: undefined })
            }
        },
        [form, formMode, onBlockUserRouting, getByIdsMethod, updateLoader]
    )

    const handleImportValues = useCallback(
        (file?: string | Blob | RcFile) => {
            const trackType = form.getFieldValue('trackType')

            return AppointmentTracksService.importRespondents({
                file,
                trackType,
            })
        },
        [form]
    )

    useEffect(() => {
        form.setFieldsValue(
            !isObjectEmpty(initialValuesForEdit) && initialValuesForEdit
                ? mapResponseToFormData(
                      getInitialValuesForCopy(initialValuesForEdit, formMode)
                  )
                : INITIAL_FORM_VALUES
        )
    }, [form, initialValuesForEdit, formMode])

    useEffect(() => {
        getUniqueTracks({
            pageSize: 999,
            pageNumber: 1,
            body: {} as TracksMguuSearchContract,
        })
    }, [getUniqueTracks])

    return (
        <Form
            id={FORM_IDS.TRACK_ASSIGNMENT_FORM}
            onFinish={handleFinish}
            onValuesChange={handleValuesChange}
            form={form}
            className={styles.form}
        >
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.APPOINTMENT_TITLE}
                        required
                    >
                        <InputControl formMode={formMode} />
                    </FormItemAdapter>
                </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="trackType"
                        label={LOCAL.LABELS.TRACK_TYPE}
                    >
                        <SelectControl
                            values={TRACK_TYPES_MAPPED}
                            formMode={formMode}
                        />
                    </FormItemAdapter>
                </Col>

                <ShouldUpdateChecker fieldPath={['trackType']}>
                    {(form) => {
                        const trackType = form.getFieldValue('trackType')

                        return (
                            <>
                                <Col xs={8}>
                                    {getSelectTrackControl(trackType)}
                                </Col>

                                <Col xs={8}>
                                    <FormItemAdapter
                                        fieldName="appointmentType"
                                        label={LOCAL.LABELS.APPOINTMENT_TYPE}
                                    >
                                        <SelectControl
                                            disabled={isTrackTypeUnique(
                                                trackType
                                            )}
                                            values={
                                                TRACKS_APPOINTMENT_TYPES_MAPPED
                                            }
                                            formMode={formMode}
                                        />
                                    </FormItemAdapter>
                                </Col>
                            </>
                        )
                    }}
                </ShouldUpdateChecker>
            </Row>

            <TrackAssignmentSettings
                formMode={formMode}
                initialValuesForEdit={initialValuesForEdit}
            />

            <Row gutter={ROW_GUTTER}>
                <FormItemAdapter
                    fieldName="isHomeworkAutoCheck"
                    className={styles.autoCheckCheckbox}
                >
                    <CheckboxControl formMode={formMode}>
                        {LOCAL.LABELS.HOMEWORK_AUTOCHECK}
                    </CheckboxControl>
                </FormItemAdapter>
            </Row>

            <Row gutter={ROW_GUTTER}>
                <ShouldUpdateChecker
                    fieldPath={[
                        ['isHomeworkAutoCheck'],
                        ['periodFrom'],
                        ['duration'],
                        ['period'],
                    ]}
                >
                    {({ getFieldsValue }) => {
                        const {
                            isHomeworkAutoCheck,
                            periodFrom,
                            duration,
                            period,
                        } = getFieldsValue([
                            'isHomeworkAutoCheck',
                            'periodFrom',
                            'duration',
                            'period',
                        ])

                        const periodStart = periodFrom || period?.[0]

                        const periodEnd = duration
                            ? moment(periodFrom).add(duration, 'd')
                            : moment(period?.[1]).add(1, 'd')

                        return (
                            isHomeworkAutoCheck && (
                                <Col xs={8}>
                                    <FormItemAdapter
                                        fieldName="homeworkAutoCheckDate"
                                        label={
                                            LOCAL.LABELS
                                                .DATE_HOMEWORK_AUTOCHECK_STARTED
                                        }
                                    >
                                        <DatePickerControl
                                            formMode={formMode}
                                            disabledDate={(current) =>
                                                disableGroupOfDate(
                                                    current,
                                                    periodStart,
                                                    periodEnd
                                                )
                                            }
                                        />
                                    </FormItemAdapter>
                                </Col>
                            )
                        )
                    }}
                </ShouldUpdateChecker>
            </Row>

            <ShouldUpdateChecker fieldPath={['trackType']}>
                {({ getFieldValue }) => {
                    const trackType = getFieldValue('trackType')

                    return (
                        (isTrackTypeDevelopment(trackType) ||
                            isTrackTypeUnique(trackType)) && (
                            <Form.Item name="checkingPersons" noStyle>
                                <AddUsersTableControl
                                    title={LOCAL.LABELS.REVIEWER}
                                    modalTitle={LOCAL.ACTIONS.ADD_REVIEWER}
                                    formMode={formMode}
                                />
                            </Form.Item>
                        )
                    )
                }}
            </ShouldUpdateChecker>

            <Form.Item name="tutors" noStyle>
                <AddUsersTableControl
                    title={LOCAL.LABELS.TUTOR}
                    modalTitle={LOCAL.ACTIONS.ADD_TUTOR}
                    formMode={formMode}
                />
            </Form.Item>

            <Form.Item name="respondents" noStyle>
                <AddUsersTableControl
                    title={LOCAL.LABELS.USERS}
                    modalTitle={LOCAL.LABELS.EMPLOYEE_SELECTION}
                    required
                    importValues={handleImportValues}
                    formMode={formMode}
                />
            </Form.Item>
        </Form>
    )
}

export default React.memo(
    withBlockUserRouting(TrackAssignmentForm, false)
) as typeof TrackAssignmentForm
