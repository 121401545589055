import React, { FC } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    RangePickerControl,
    ShouldUpdateChecker,
    SwitchControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import {
    disableDateWhenSelectedDate,
    getDisabledDate,
    isFormModeCreate,
} from 'utils'

import styles from '../../TrackAssignmentForm.module.scss'
import { NotificationsSettings } from './components/NotificationsSettings'
import { PeriodSettings, StagesSettingsTable } from './components'
import { TrackAssignmentSettingsProps } from './TrackAssignmentSettings.types'
import { isTrackAppointmentTypeByStages } from '../../TrackAssignmentForm.utils'

/**
 * Настройки периода и этапов, оповещений трека
 */
export const TrackAssignmentSettings: FC<TrackAssignmentSettingsProps> = React.memo(
    ({ formMode, initialValuesForEdit }) => (
        <Row gutter={ROW_GUTTER}>
            <ShouldUpdateChecker fieldPath={['appointmentType']}>
                {({ getFieldValue }) => {
                    const status = getFieldValue('status')

                    return isTrackAppointmentTypeByStages(
                        getFieldValue('appointmentType')
                    ) ? (
                        <>
                            <ControlGroup
                                title={LOCAL.LABELS.TRACK_DURATION}
                                className={styles.fullWidth}
                                noPadding
                            >
                                <Col xs={6}>
                                    <FormItemAdapter
                                        fieldName="period"
                                        label={LOCAL.LABELS.TRACK_START_PERIOD}
                                        required
                                    >
                                        <RangePickerControl
                                            formMode={formMode}
                                            disabledDate={disableDateWhenSelectedDate(
                                                getDisabledDate(
                                                    status,
                                                    initialValuesForEdit?.periodFrom
                                                )
                                            )}
                                        />
                                    </FormItemAdapter>
                                </Col>
                            </ControlGroup>

                            <Col xs={12}>
                                <StagesSettingsTable formMode={formMode} />
                            </Col>
                        </>
                    ) : (
                        <Col xs={16}>
                            <Row gutter={ROW_GUTTER}>
                                <Col>
                                    <FormItemAdapter
                                        fieldName="isDuration"
                                        withEmptyLabel
                                    >
                                        <SwitchControl
                                            addonAfter={LOCAL.LABELS.DURATION}
                                            addonBefore={LOCAL.LABELS.PERIOD}
                                            disabled={
                                                !isFormModeCreate(formMode)
                                            }
                                        />
                                    </FormItemAdapter>
                                </Col>

                                <PeriodSettings
                                    formMode={formMode}
                                    initialValuesForEdit={initialValuesForEdit}
                                />
                            </Row>
                        </Col>
                    )
                }}
            </ShouldUpdateChecker>

            <Col xs={8}>
                <NotificationsSettings formMode={formMode} />
            </Col>
        </Row>
    )
)
