import React from 'react'
import moment, { MomentInput } from 'moment'
import {
    ActivityPassingStatus,
    AppointmentTrackListenerContract,
    AppointmentTrackStatus,
    TrackStageListenerContract,
} from 'core/api'
import { EVENT_TYPES, TRACKS_APPOINTMENT_STATUSES } from 'consts'
import { LOCAL } from 'core/local'
import {
    formatDate,
    isAppointmentTrackStatusSuspended,
    isFirstDateEarlier,
    isFirstDateSameOrEarlier,
    isMaterialTypeActivity,
    isMaterialTypeContent,
    isNotAvailableStatus,
    isTrackTypeUnique,
} from 'utils'

import styles from './StagesIndicator.module.scss'
import { GetStageTitleProps, SIDataSourceProps } from './StagesIndicator.types'

export const getClassesPeriodFrom = (date?: MomentInput) =>
    date && isFirstDateSameOrEarlier(moment(date), moment())
        ? styles.expired
        : ''

export const getClassesPeriodTo = (date?: MomentInput) =>
    date && isFirstDateEarlier(moment(date), moment()) ? styles.expired : ''

/**
 * Рендер заголовка для этапа трека в прогрессе карточки назначения
 */
const renderStageTitle = ({
    stage: { materialType, name, activity, course, status },
    stageSettings,
    type,
}: GetStageTitleProps) => {
    const stageActivityTitle = `${
        activity?.name || LOCAL.LABELS.CONTENT_EMPTY
    }, ${EVENT_TYPES[activity?.type] || LOCAL.LABELS.CONTENT_EMPTY}`

    const stageContentTitle = `${course?.name || LOCAL.LABELS.CONTENT_EMPTY}`

    const formattedStartDate = formatDate(stageSettings?.periodFrom)
    const formattedEndDate = formatDate(stageSettings?.periodTo || '')

    const stageStatus = `, ${
        status === AppointmentTrackStatus.Draft
            ? LOCAL.STATUSES.PENDING
            : TRACKS_APPOINTMENT_STATUSES[status]
    }`

    return (
        <>
            {isTrackTypeUnique(type) && name}
            {isMaterialTypeActivity(materialType) && stageActivityTitle}
            {isMaterialTypeContent(materialType) && stageContentTitle}

            {(stageSettings?.periodFrom || stageSettings?.periodTo) &&
                !isNotAvailableStatus(stageSettings?.status) && (
                    <>
                        {', '}
                        <span
                            className={getClassesPeriodFrom(
                                stageSettings?.periodFrom
                            )}
                        >
                            {formattedStartDate}
                        </span>
                        <span
                            className={getClassesPeriodTo(
                                stageSettings?.periodTo
                            )}
                        >
                            {` - ${formattedEndDate}`}
                        </span>
                    </>
                )}

            {!isMaterialTypeContent(materialType) && status && stageStatus}
        </>
    )
}

const getStatusStageIndicator = ({
    status,
    availability,
}: TrackStageListenerContract) => {
    if (
        availability.isBlockedByPeriod ||
        availability.isBlockedByPreviousStage ||
        availability.isBlockedByAttemptsCountExceed
    ) {
        return styles.stageLocked
    }

    switch (status) {
        case AppointmentTrackStatus.Passed:
            return styles.stagePassed
        case AppointmentTrackStatus.NotPassed:
            return styles.stageNotPassed
        default:
            return styles.stagePending
    }
}

/** Маппинг данных для компонента индикатора этапа */
export const getDataSource = (
    appointment: AppointmentTrackListenerContract
): SIDataSourceProps[] =>
    appointment.stages?.map((stage) => ({
        key: String(stage.id),
        title: renderStageTitle({
            stage,
            stageSettings: appointment.stageSettings.find(
                ({ stageNumber }) => stageNumber === stage.stageNumber
            ),
            type: appointment.trackType,
        }),
        statusClassName: isAppointmentTrackStatusSuspended(appointment.status)
            ? styles.stageLocked
            : getStatusStageIndicator(stage),
    })) || []

/**
 * Определяем статус для индикатора тематического блока
 * @param status статус тематического блока
 */
export const getStatusLessonIndicator = (status?: ActivityPassingStatus) => {
    switch (status) {
        case ActivityPassingStatus.CompletedUnsuccessfully:
            return styles.stageNotPassed
        case ActivityPassingStatus.CompletedSuccessfully:
            return styles.stagePassed
        default:
            return styles.stagePending
    }
}
