import { isDocumentStatusInWork } from 'utils'

import { UserProfileStateProps } from '../../UserProfile.types'

/**
 * Возвращает флаг для отображения блока с компетенциями
 * @param displayPositionProfile флаг наличия профайла пользователя
 * @param haveComparisonData флаг наличия компетенция для сравнения
 */
export const isDisplayUserProfileCompetencies = (
    haveComparisonData: boolean,
    displayPositionProfile?: boolean
) => displayPositionProfile || haveComparisonData

/**
 * Проверка на отображение информации о профиле должности
 * @param userProfile данные профиля
 */
export const checkDisplayPositionProfile = (
    userProfile?: UserProfileStateProps
) =>
    userProfile?.positionProfile?.active &&
    isDocumentStatusInWork(userProfile?.positionProfile?.status)
