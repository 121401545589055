import template from 'lodash/template'
import React, { useCallback } from 'react'
import {
    Button,
    IconsAdapter,
    TooltipAdapter,
    UploadFileWrapper,
} from 'components/shared'
import { HomeworksService } from 'core/api'
import { LOCAL } from 'core/local'
import { THEME_BLOCKS_DOCUMENT_TYPE } from 'consts'
import {
    beforeUploadAttachment,
    isHomeworkOnChecking,
    isHomeworkPassed,
} from 'utils'

import styles from './UserUploadFile.module.scss'
import {
    UserUploadFileProps,
    ViewFilesComponentProps,
} from './UserUploadFile.types'

export const UserUploadFile: React.FC<UserUploadFileProps> = React.memo(
    ({ fieldName, status, updateLoader, isLoading, uploadFile, form }) => {
        const viewFilesComponent = useCallback(
            ({
                disabled,
                fileName,
                handleDeleteAttachment,
            }: ViewFilesComponentProps) => (
                <div className={styles.uploadFile}>
                    {disabled ? (
                        <IconsAdapter
                            iconType="CheckOutlined"
                            className={styles.successUpload}
                        />
                    ) : (
                        <IconsAdapter
                            iconType="PaperClipOutlined"
                            className={styles.paperClipIcon}
                        />
                    )}

                    <TooltipAdapter title={fileName}>
                        <div className={styles.fileName}>{fileName}</div>
                    </TooltipAdapter>

                    {!disabled && (
                        <TooltipAdapter title={LOCAL.LABELS.UNPIN_FILE}>
                            <IconsAdapter
                                iconType="CustomIconTrashAltBlue"
                                className={styles.trashIcon}
                                onClick={handleDeleteAttachment}
                            />
                        </TooltipAdapter>
                    )}
                </div>
            ),
            []
        )

        const handleFileRemove = () => {
            form?.setFields([
                {
                    name: ['taskPassings', fieldName, 'attachment'],
                    value: undefined,
                },
            ])
        }

        return (
            <UploadFileWrapper
                name={[fieldName, 'attachments']}
                acceptedTypes={THEME_BLOCKS_DOCUMENT_TYPE}
                updateLoader={updateLoader}
                isLoading={isLoading}
                uploadMethod={HomeworksService.uploadTaskAttachment}
                validateFiles={beforeUploadAttachment({
                    documentType: THEME_BLOCKS_DOCUMENT_TYPE,
                    fileSize: 20,
                    errors: {
                        type: LOCAL.MESSAGES.WRONG_FORMAT,
                        size: LOCAL.MESSAGES.WRONG_SIZE_LIMIT,
                    },
                })}
                initialFile={{
                    name: uploadFile?.fileName,
                    id: uploadFile?.id,
                }}
                className={styles.uploadFile}
                hideDownloadBtn
                disabled={
                    isHomeworkOnChecking(status) || isHomeworkPassed(status)
                }
                viewFilesComponent={viewFilesComponent}
                handleFileRemove={handleFileRemove}
            >
                <Button
                    type="link"
                    title={template(LOCAL.MESSAGES.ATTACH_FILE)({
                        attachFormats: THEME_BLOCKS_DOCUMENT_TYPE,
                    })}
                    tooltipOptions={{ className: styles.uploadBtnTooltip }}
                    disabled={
                        isHomeworkOnChecking(status) || isHomeworkPassed(status)
                    }
                    className={styles.uploadBtn}
                >
                    <IconsAdapter iconType="FileTextOutlined" />

                    {LOCAL.ACTIONS.ATTACH_FILE}
                </Button>
            </UploadFileWrapper>
        )
    }
)
