import React, { useCallback } from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import {
    ProductCardSearchContract,
    ProductCardSearchResultContract,
    ProductCardsService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    getPageTitleFromRoute,
    getRowHandlers,
    isFirstDateEarlier,
} from 'utils'
import { useLocation } from 'react-router-dom'

import styles from './ProductCatalog.module.scss'
import { PRODUCT_CATALOG_TABLE_COLUMNS } from './ProductCatalog.consts'
import { ProductCatalogFilters } from './components'

/** Реестр карточек продуктов */
export const ProductCatalog = React.memo(() => {
    const location = useLocation()
    const title = getPageTitleFromRoute(location.pathname)

    /**
     * Вычислить класс для конкретного `row` таблицы
     * @param record - запись таблицы
     */
    const getRowClassName = useCallback(
        (record: ProductCardSearchResultContract) => {
            if (record.active === false) return styles.archive

            if (
                isFirstDateEarlier(
                    new Date(record.endPlacementDate),
                    new Date()
                )
            )
                return 'bg-expired'

            return ''
        },
        []
    )

    return (
        <DictionaryPage<
            ProductCardSearchContract,
            ProductCardSearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.PRODUCT_CATALOG}
            searchMethod={ProductCardsService.search}
            deleteMethod={ProductCardsService.setAsArchive}
            contentTitle={title}
            tableColumns={PRODUCT_CATALOG_TABLE_COLUMNS}
            createRecordLink={{
                path: ROUTE_NAMES.PRODUCT_CATALOG_CREATE,
            }}
            filterComponent={ProductCatalogFilters}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES_WITH_CHANGE_DATE,
                },
            }}
            quickSearchMethod={ProductCardsService.searchText}
            createPermissions={[
                UserPermission.ProductCardRead,
                UserPermission.ProductCardSave,
            ]}
            className={styles.wrapper}
            rowClassName={getRowClassName}
            onRow={getRowHandlers({
                url: ROUTE_NAMES.PRODUCT_CATALOG_VIEW,
            })}
        />
    )
})
