import React from 'react'
import { Form } from 'antd'

import { FormAdapterProps } from './FormAdapter.types'

/** Обертка Form от antd */
export const FormAdapter: React.FC<FormAdapterProps> = React.memo(
    ({ children, ...props }) => (
        <Form {...props} autoComplete="off">
            {children}
        </Form>
    )
)
