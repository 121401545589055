import React, { useCallback, useMemo } from 'react'
import { AddButton, Spoiler, TableAdapter } from 'components/shared'
import {
    CoworkersFilters,
    SearchTableShowButton,
    ShouldUpdateChecker,
    getCoworkersTableColumns,
} from 'components/controls'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { SpoilerTitleRenderProps } from 'components/shared/Spoiler/Spoiler.types'
import {
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import {
    fetchSelectedItemsByIds,
    getItemId,
    getPortfolioObjectsColumnsWithActions,
    isFormModeView,
    renderExpandIcon,
} from 'utils'
import { useElementVisible } from 'hooks'

import styles from './PortfolioAssessmentObjects.module.scss'
import { PortfolioAssessmentObjectsProps } from './PortfolioAssessmentObjects.types'
import { PortfolioAssessmentObjectsRespondents } from '../PortfolioAssessmentObjectsRespondents'

/** Компонент объектов оценки портфеля оценки */
export const PortfolioAssessmentObjects: React.FC<PortfolioAssessmentObjectsProps> = React.memo(
    ({ formMode, field, onChange, value: assessmentObjects }) => {
        const {
            updateElementVisible: updateLoader,
            elementVisible: isLoading,
        } = useElementVisible()

        /** Id выбранных объектов оценки */
        const initialValues = useMemo(() => assessmentObjects?.map(getItemId), [
            assessmentObjects,
        ])

        /**
         * Обработчик выбора объектов оценки в модальном окне
         */
        const handleSelectAssessmentObjects = useCallback(
            async (result?: number[]) => {
                try {
                    if (!result) return

                    updateLoader(true)

                    const items = await fetchSelectedItemsByIds({
                        result,
                        selectedItemsIds: initialValues,
                        getByIdsMethod: UsersService.getByIds,
                        selectedItems: assessmentObjects,
                    })

                    onChange?.(items)
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [initialValues, onChange, assessmentObjects, updateLoader]
        )

        /**
         * Обработчик удаления объекта оценки
         */
        const handleRemoveAssessmentObject = useCallback(
            (id) => () => {
                onChange?.(
                    assessmentObjects?.filter(
                        (assessmentObject) => assessmentObject.id !== id
                    )
                )
            },
            [assessmentObjects, onChange]
        )

        /**
         * Функция для кастомного рендера заголовка спойлера объектов оценки
         */
        const renderSpoilerTitle = useCallback(
            ({ icon, toggleSpoilerVisible }: SpoilerTitleRenderProps) => (
                <div className={styles.control}>
                    <a
                        href="#s"
                        onClick={toggleSpoilerVisible}
                        className={styles.toggleBtn}
                    >
                        {icon}

                        <h4 className="required-control">
                            {LOCAL.LABELS.ASSESSMENT_OBJECTS}
                        </h4>
                    </a>

                    <ShouldUpdateChecker fieldPath={['structuralUnitIds']}>
                        {({ getFieldValue }) => {
                            const structuralUnitIds =
                                getFieldValue('structuralUnitIds')?.map(
                                    ({ value }: LabeledValue) => value
                                ) || []

                            return (
                                <SearchTableShowButton<
                                    UserModalSearchContract,
                                    UserModalContract
                                >
                                    tableSearchOptions={{
                                        formId: FORM_IDS.SEARCH_RESPONDENTS,
                                        rowSelectionType: 'checkbox',
                                        searchMethod: UsersService.getForModal,
                                        tableColumns: getCoworkersTableColumns(),
                                        filterComponent: CoworkersFilters,
                                        tableFiltersOptions: {
                                            initialValues: {
                                                structuralUnitsIds: structuralUnitIds,
                                            },
                                            disabledFields: {
                                                structuralUnitsIds: !!structuralUnitIds?.length,
                                            },
                                        },
                                        onSelectTableItem: handleSelectAssessmentObjects,
                                        initialValues,
                                    }}
                                    modalTitle={LOCAL.LABELS.EMPLOYEE_SELECTION}
                                >
                                    <AddButton
                                        disabled={
                                            isFormModeView(formMode) ||
                                            isLoading
                                        }
                                        buttonText={LOCAL.ACTIONS.ADD}
                                        loading={isLoading}
                                    />
                                </SearchTableShowButton>
                            )
                        }}
                    </ShouldUpdateChecker>
                </div>
            ),

            [formMode, handleSelectAssessmentObjects, initialValues, isLoading]
        )

        /**
         * Рендер вложенной структуры респондентов объекта оценки
         */
        const renderRespondentsTableControl = useCallback(
            (fields: FormListFieldData[]) => (
                record: UserModalContract,
                index: number
            ) => (
                <PortfolioAssessmentObjectsRespondents
                    field={fields[index]}
                    formMode={formMode}
                />
            ),
            [formMode]
        )

        return (
            <Spoiler spoilerTitleRender={renderSpoilerTitle}>
                <Form.List name={[field.name, 'assessmentObjects']}>
                    {(fields) => (
                        <TableAdapter
                            columns={getPortfolioObjectsColumnsWithActions<
                                UserModalContract
                            >({
                                tableColumnsObject: getCoworkersTableColumns(),
                                handleRemoveRow: handleRemoveAssessmentObject,
                                formMode,
                            })}
                            dataSource={assessmentObjects || []}
                            className={styles.table}
                            expandable={{
                                expandedRowRender: renderRespondentsTableControl(
                                    fields
                                ),
                                expandIcon: renderExpandIcon,
                            }}
                        />
                    )}
                </Form.List>
            </Spoiler>
        )
    }
)
