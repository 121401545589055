import React, { ReactNode } from 'react'
import { HasPermissions } from 'core/permissions'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { NavLink } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UserPermission } from 'core/api'

import styles from './UserPersonalAreaNavigation.module.scss'
import { UserPersonalAreaNavigationGroup } from './UserPersonalAreaNavigation.types'

const PERSONAL_AREA_GROUPS: UserPersonalAreaNavigationGroup[] = [
    {
        items: [
            {
                link: ROUTE_NAMES.USER_PERSONAL_AREA,
                title: LOCAL.CATALOGS.USER_PERSONAL_AREA,
                icon: 'CustomIconHome',
                hidden: true,
            },
        ],
    },
    {
        items: [
            {
                link: ROUTE_NAMES.PA_MY_DEVELOPMENT,
                title: LOCAL.CATALOGS.PA_MY_DEVELOPMENT,
                icon: 'CustomIconDevelopment',
                hidden: true,
            },
            {
                link: ROUTE_NAMES.PA_FAVORITES,
                title: LOCAL.CATALOGS.PA_FAVORITES,
                icon: 'CustomIconStar',
                hidden: true,
            },
            {
                link: ROUTE_NAMES.PA_HOMEWORK,
                title: LOCAL.CATALOGS.PA_HOMEWORK,
                icon: 'CustomIconHomework',
            },
        ],
    },
    {
        items: [
            {
                link: ROUTE_NAMES.PA_QUIZZES,
                title: LOCAL.CATALOGS.PA_QUIZZES,
                icon: 'CustomIconQuizzes',
                hidden: true,
            },
            {
                link: ROUTE_NAMES.PA_ASSESSMENTS,
                title: LOCAL.CATALOGS.PA_ASSESSMENTS,
                icon: 'CustomIconLike',
                hidden: true,
            },
        ],
    },
    {
        items: [
            {
                link: ROUTE_NAMES.PA_PROFILE,
                title: LOCAL.CATALOGS.PA_PROFILE,
                icon: 'CustomIconProfileCircle',
                hidden: true,
            },
        ],
    },
    {
        conditionalPermissions: [
            UserPermission.LkHomeworkCheck,
            UserPermission.LkQuizCheck,
            UserPermission.LkMeetingResult,
        ],
        items: [
            {
                link: ROUTE_NAMES.PA_CHECK_HOMEWORK,
                title: LOCAL.CATALOGS.PA_CHECK_HOMEWORK,
                icon: 'CustomIconCheckHomework',
                requiredPermissions: [UserPermission.LkHomeworkCheck],
            },
            {
                link: ROUTE_NAMES.PA_CHECK_QUIZZES,
                title: LOCAL.CATALOGS.PA_CHECK_QUIZZES,
                icon: 'CustomIconCheckQuizzes',
                requiredPermissions: [UserPermission.LkQuizCheck],
            },
            {
                link: ROUTE_NAMES.PA_MEETINGS_RESULTS,
                title: LOCAL.CATALOGS.PA_MEETINGS_RESULTS,
                icon: 'CustomIconResults',
                requiredPermissions: [UserPermission.LkMeetingResult],
            },
        ],
    },
    {
        items: [
            {
                link: ROUTE_NAMES.PA_ASSIGNED_ASSESSMENTS,
                title: LOCAL.CATALOGS.PA_ASSIGNED_ASSESSMENTS,
                icon: 'CustomIconVideo',
                hidden: true,
            },
            {
                link: ROUTE_NAMES.PA_REPORTS,
                title: LOCAL.CATALOGS.REPORTS,
                icon: 'CustomIconReports',
                hidden: true,
            },
        ],
    },
    {
        items: [
            {
                link: ROUTE_NAMES.PA_MONITORING,
                title: LOCAL.CATALOGS.PA_MONITORING,
                icon: 'CustomIconEye',
                requiredPermissions: [UserPermission.LkMonitoring],
            },
        ],
    },
]

export const PERSONAL_AREA_NAVIGATION = PERSONAL_AREA_GROUPS.map(
    ({ items, conditionalPermissions }, groupIndex) => {
        const renderedItems = items.reduce<ReactNode[]>(
            (acc, navigationItem, itemIndex) => {
                if (navigationItem.hidden) return acc

                return [
                    ...acc,
                    <HasPermissions
                        requiredPermissions={navigationItem.requiredPermissions}
                        key={itemIndex}
                    >
                        <li>
                            <NavLink
                                exact
                                to={navigationItem.link}
                                className={styles.link}
                                activeClassName={styles.activeLink}
                            >
                                <IconsAdapter iconType={navigationItem.icon} />

                                <i>{navigationItem.title}</i>
                            </NavLink>
                        </li>
                    </HasPermissions>,
                ]
            },
            []
        )

        return (
            <HasPermissions
                conditionalPermissions={conditionalPermissions}
                key={groupIndex}
            >
                <ul>{renderedItems}</ul>
            </HasPermissions>
        )
    }
)
