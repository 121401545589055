import React, { useCallback } from 'react'
import { RadioButtonControl } from 'components/controls'
import { eventEmitter } from 'core/helpers/eventEmitter'

import styles from './ToggleIcons.module.scss'
import { ICON_STATE_NAMES } from './ToggleIcons.config'
import { TRACK_PREVIEW_EVENTS } from '../../TrackPreview.events'

export const ToggleIcons: React.FC = React.memo(() => {
    const handleChange = useCallback((value?: React.ReactText) => {
        eventEmitter.emit(TRACK_PREVIEW_EVENTS.MOVE_STAGE_STATUS, value)
    }, [])

    return (
        <div className={styles.wrapper}>
            <RadioButtonControl
                defaultValue={ICON_STATE_NAMES[0].value}
                values={ICON_STATE_NAMES}
                buttonStyle="solid"
                onChange={handleChange}
            />
        </div>
    )
})
