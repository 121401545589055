import React from 'react'
import { useRrweb } from 'hooks'

import styles from './MonitoringBtn.module.scss'

export const MonitoringBtn: React.FC = React.memo(() => {
    const { stopMonitoring } = useRrweb()

    return (
        <div className={styles.circle} onClick={stopMonitoring}>
            <div className={styles.square}></div>
        </div>
    )
})
