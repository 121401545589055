import {
    ActivityContract,
    ActivityPublishContract,
    ActivitySaveContract,
    DocumentStatus,
    LessonCreateContract,
    LessonScheduleContract,
} from 'core/api'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import {
    getValue,
    mapPictureContractToFormData,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { normalizeUsersSearchResponse } from 'components/controls'

import {
    LessonValueFormProps,
    MapFormDataToRequestProps,
} from './EventForm.types'

/** Нормалайзер для отправки данных формы на сервер
 * @param value значения формы
 * @return объект для отправки на сервер
 */
export const mapFormDataToRequest = ({
    lessons,
    activityPictureId,
    competencesIds,
    ...values
}: MapFormDataToRequestProps) =>
    ({
        ...values,
        competencesIds: competencesIds?.map((el: LabeledValue) => el.value),
        activityPictureId: activityPictureId?.[0]?.response?.id,
        lessons: lessons?.map(
            (lesson: LessonValueFormProps, index: number) =>
                ({
                    id: lesson.id,
                    name: lesson.name,
                    pointForPassing: lesson.pointForPassing,
                    isRequired: lesson.isRequired,
                    isEditorUsed: !!lesson.isEditorUsed,
                    orderNumber: index + 1,
                    courseId: lesson.course?.map(getValue)?.[0],
                    lessonDescription: lesson.lessonDescription,
                    questionnaireIds: lesson.questionnaires?.map(getValue),
                    tutorIds: lesson.tutors?.map(getValue),
                    textDocumentId: Number(
                        lesson.textDocuments?.map(getValue)[0]
                    ),
                    missionId: Number(lesson.missions?.map(getValue)[0]),
                    type: lesson.type,
                    courseDuration: lesson.courseDuration,
                    courseDurationMinutes: lesson.courseDurationMinutes,
                    attemptNumber: lesson.attemptNumber,
                    nestedActivityId: lesson.nestedActivity?.map(getValue)?.[0],
                    schedule:
                        lesson.faceToFaceMeeting || lesson.webConference
                            ? ({
                                  faceToFaceMeeting: lesson.faceToFaceMeeting,
                                  webConference: lesson.webConference,
                              } as LessonScheduleContract)
                            : undefined,
                    attachments: lesson.attachments,
                    isContainsAttachments: lesson.isContainsAttachments,
                    informationBlockText: lesson.informationBlockText,
                } as LessonCreateContract)
        ),
    } as (ActivityPublishContract | ActivitySaveContract) & {
        status?: DocumentStatus
    })

/** Нормализация значений мероприятия для инициализации основной формы
 * @param values для формы
 * @param isCopy флаг копирования
 * @return объект значений формы
 */
export const mapResponseToFormData = (
    {
        canvas,
        lessons,
        activityPicture,
        ownerOrganization,
        competences,
        name,
        ...eventValues
    }: ActivityContract,
    isCopy?: boolean
) => ({
    ...eventValues,
    name: isCopy ? `${name} ${LOCAL.LABELS.COPY}` : name,
    competencesIds: competences?.map(normalizeDataForSelectAndRadio),
    ownerOrganizationId: ownerOrganization?.id,
    activityPictureId: mapPictureContractToFormData(activityPicture),
    lessons: lessons
        ?.map((el) => ({
            ...el,
            course: el.course && [normalizeDataForSelectAndRadio(el.course)],
            nestedActivity: el.nestedActivity && [
                normalizeDataForSelectAndRadio(el.nestedActivity),
            ],
            tutors: normalizeUsersSearchResponse(el.tutors),
            textDocuments: el.textDocument && [
                normalizeDataForSelectAndRadio(el.textDocument),
            ],
            missions: el.mission && [
                normalizeDataForSelectAndRadio(el.mission),
            ],
            questionnaires: el.questionnaires?.map(
                normalizeDataForSelectAndRadio
            ),
            faceToFaceMeeting: el.schedule?.faceToFaceMeeting,
            webConference: el.schedule?.webConference,
            isContainsAttachments: !!el.attachments.length,
        }))
        .sort((a, b) => a.orderNumber - b.orderNumber),
    canvas: {
        ...(canvas || {}),
        canvasBackgroundId: canvas?.canvasBackground?.id,
        canvasBackgroundUrl: canvas?.canvasBackground?.url,
    },
})
