import React from 'react'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
} from 'components/controls'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { IndicatorsSearchContract } from 'core/api'
import { LOCAL } from 'core/local'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'

/**
 * Форма фильтрации индикаторов компетенций
 */
export const CompetenciesIndicatorsFilters: React.FC<FiltersProps<
    IndicatorsSearchContract
>> = React.memo((props) => (
    <ControlGroup type="light">
        <FilterForm {...props} renderFooter={renderFooterWithShowArchiveField}>
            <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                <InputControl />
            </FormItemAdapter>
        </FilterForm>
    </ControlGroup>
))
