import isNil from 'lodash/isNil'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import {
    DepartmentsService,
    OrganizationsService,
    PositionsService,
    StaffUnitSearchContract,
} from 'core/api'
import { DictionariesCommonProps, Store } from 'App.types'
import { FilterForm } from 'components/shared/FilterForm'
import {
    FiltersProps,
    getValue,
    normalizeDataForSelectAndRadio,
    queryStrToObj,
} from 'utils'
import { JobProfileQueryObjProps } from 'components/pages/JobProfiles'
import { LOCAL } from 'core/local'
import { POSITION_PROFILES } from 'components/pages/Organizations/components/OrganizationsFilters/OrganizationsFilters.consts'
import { ROW_GUTTER } from 'consts'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionaries } from 'hooks'

import { DEPARTMENTS_FETCH_DEPENDENCIES } from './StaffUnitsFilters.consts'

/**
 * Форма фильтрации
 */
export const StaffUnitsFilters: React.FC<FiltersProps<
    StaffUnitSearchContract
>> = React.memo((props) => {
    const {
        dictionaries,
        handleFetchDictionaries,
        handleChangeDictionaries,
    } = useDictionaries<
        DictionariesCommonProps<'departments' | 'organizations' | 'positions'>
    >()

    const externalFilters = useMemo(
        () => queryStrToObj<JobProfileQueryObjProps>(window.location.search),
        []
    )

    const mapFormDataToRequest = useCallback(
        (values: Store) => ({
            ...values,
            departmentsIds: values.departmentsIds?.map(getValue),
            hasPositionProfile: isNil(values.hasPositionProfile)
                ? undefined
                : Boolean(values.hasPositionProfile),
        }),
        []
    )

    const handleValuesChange = useCallback(
        async (changedValue) => {
            const changedValueKey = Object.keys(changedValue)[0]

            if (DEPARTMENTS_FETCH_DEPENDENCIES.includes(changedValueKey)) {
                const departments = await DepartmentsService.getByOrganizationsIds(
                    {
                        body: changedValue.organizationsIds,
                    }
                )

                handleChangeDictionaries({
                    departments: departments.map(
                        normalizeDataForSelectAndRadio
                    ),
                })
            }
        },
        [handleChangeDictionaries]
    )

    useEffect(() => {
        const dictionaryFetch = () => {
            handleFetchDictionaries({
                organizations: [
                    OrganizationsService.getTreeOrganizations(),
                    'treeSelect',
                ],
                positions: PositionsService.getAll(),
            })
        }

        dictionaryFetch()
    }, [handleFetchDictionaries])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                mapFormDataToRequest={mapFormDataToRequest}
                onValuesChange={handleValuesChange}
                renderFooter={renderFooterWithShowArchiveField}
                withoutSavedFilters={!!externalFilters?.register}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={6}>
                        <FormItemAdapter
                            fieldName="organizationsIds"
                            label={LOCAL.LABELS.ORGANIZATION}
                        >
                            <TreeSelectControl
                                treeData={dictionaries?.organizations || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={6}>
                        <FormItemAdapter
                            fieldName="departmentsIds"
                            label={LOCAL.LABELS.SUBUNIT}
                        >
                            <SelectControl
                                values={dictionaries?.departments || []}
                                mode="multiple"
                                showSearch
                                labelInValue
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={6}>
                        <FormItemAdapter
                            fieldName="positionsIds"
                            label={LOCAL.LABELS.POSITIONS}
                        >
                            <SelectControl
                                values={dictionaries?.positions}
                                mode="multiple"
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={6}>
                        <FormItemAdapter
                            fieldName="hasPositionProfile"
                            label={LOCAL.LABELS.POSITION_PROFILE}
                        >
                            <SelectControl values={POSITION_PROFILES} />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
