import { FnActionProps } from 'App.types'
import { ReactNode, useCallback, useState } from 'react'

interface TabsConfigProps {
    [tabKey: string]: ReactNode
}

interface UseTabsResultProps {
    changeActiveTab: FnActionProps
    currentTabRender: ReactNode
}

/**
 * Хук для осуществления контроля за переключением вкладок на странице
 * @param tabsConfig - объект конфигурации вкладок типа: (уникальный ключ вкладки => ее компонент)
 * @param defaultTabKey - ключ вкладки открываемой по умолчанию
 */
export function useTabsState(
    tabsConfig: TabsConfigProps,
    defaultTabKey: keyof TabsConfigProps
): UseTabsResultProps {
    const [activeTab, setActiveTab] = useState<keyof TabsConfigProps>(
        defaultTabKey
    )

    /**
     * Обработчик переключения вкладки
     * @param activeTabKey - ключ новой активной вкладки
     */
    const changeActiveTab = useCallback(
        (activeTabKey: keyof TabsConfigProps) => {
            setActiveTab(activeTabKey)
        },
        []
    )

    /** активный компонент для рендера */
    const currentTabRender = tabsConfig[activeTab]

    return { changeActiveTab, currentTabRender }
}
