import React, { useEffect, useMemo } from 'react'
import { Col, Row } from 'antd'
import { CoursesService } from 'core/api'
import { DatePickerControl } from 'components/controls/DatePickerControl'
import { DictionariesCommonProps } from 'App.types'
import { FilterForm } from 'components/shared/FilterForm'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { SelectControl } from 'components/controls/SelectControl'
import { useDictionaries } from 'hooks'

import { TrainingMaterialsFiltersProps } from './TrainingMaterialsFilters.types'

export const TrainingMaterialsFilters: React.FC<TrainingMaterialsFiltersProps> = React.memo(
    ({ excludeCourseFormats, ...props }) => {
        const { dictionaries, handleFetchDictionaries } = useDictionaries<
            DictionariesCommonProps<'courseTypes'>
        >()

        const courseTypes = useMemo(() => {
            if (!dictionaries.courseTypes) return []

            return (
                dictionaries.courseTypes.filter(
                    (el) => !excludeCourseFormats?.includes(el.value as string)
                ) || []
            )
        }, [dictionaries.courseTypes, excludeCourseFormats])

        useEffect(() => {
            handleFetchDictionaries({
                courseTypes: CoursesService.getCourseTypes(),
            })
        }, [handleFetchDictionaries])

        return (
            <FilterForm {...props}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="types"
                            label={LOCAL.LABELS.TYPE}
                        >
                            <SelectControl
                                values={courseTypes}
                                mode="multiple"
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="uploadDate"
                            label={LOCAL.LABELS.UPLOAD_DATE}
                        >
                            <DatePickerControl />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        )
    }
)
