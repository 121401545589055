import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { CompetenciesService, DevelopmentGoalsSearchContract } from 'core/api'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps, normalizeDataForSelectAndRadio } from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { ROW_GUTTER } from 'consts'
import { SelectControl } from 'components/controls/SelectControl'

/**
 * Фильтры целей развития для таблицы во всплывающем окне
 */
export const DevelopmentGoalsFilters: React.FC<FiltersProps<
    DevelopmentGoalsSearchContract
>> = React.memo((props) => {
    const [competenciesData, setCompetenciesData] = useState<LabeledValue[]>()

    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                const sourceData = await CompetenciesService.getAll()

                setCompetenciesData(
                    sourceData.map(normalizeDataForSelectAndRadio)
                )
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [])

    return (
        <FilterForm {...props}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.DEVELOPMENT_GOAL_NAME}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="competenceIds"
                        label={LOCAL.LABELS.COMPETENCE}
                    >
                        <SelectControl
                            values={competenciesData}
                            mode="multiple"
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    )
})
