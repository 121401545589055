import React from 'react'
import {
    APPOINTMENT_TYPES_MAPPED,
    HOMEWORK_STATUS_TYPE_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    InputNumberControl,
    SelectControl,
} from 'components/controls'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps } from 'utils'
import { HomeworkGetContract } from 'core/api'
import { LOCAL } from 'core/local'
import { renderFooterWithShowExpiredField } from 'components/shared/FilterForm/FilterForm.render'

export const HomeworksFilters: React.FC<FiltersProps<
    HomeworkGetContract
>> = React.memo((props) => (
    <ControlGroup type="light">
        <FilterForm {...props} renderFooter={renderFooterWithShowExpiredField}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.TRACK_OR_EVENT}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter fieldName="type" label={LOCAL.LABELS.TYPE}>
                        <SelectControl
                            values={APPOINTMENT_TYPES_MAPPED}
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="appointmentName"
                        label={LOCAL.LABELS.ASSIGNMENT}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="lessonName"
                        label={LOCAL.LABELS.TRAVEL_DIARY}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="tutorId"
                        label={LOCAL.LABELS.REVIEWER}
                    >
                        <InputNumberControl
                            placeholder={LOCAL.PLACEHOLDERS.INPUT}
                            controls={false}
                            fullWidth
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="statuses"
                        label={LOCAL.LABELS.STATUS}
                    >
                        <SelectControl
                            values={HOMEWORK_STATUS_TYPE_MAPPED}
                            mode="multiple"
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FromToDatesFilter
                        from={{
                            label: `${LOCAL.LABELS.COMPLETION_DATE} (${LOCAL.SINCE})`,
                            fieldName: 'onCheckingDateStart',
                        }}
                        to={{
                            label: `${LOCAL.LABELS.COMPLETION_DATE} (${LOCAL.TO})`,
                            fieldName: 'onCheckingDateEnd',
                        }}
                    />
                </Col>

                <Col xs={8}>
                    <FromToDatesFilter
                        from={{
                            label: `${LOCAL.LABELS.CHECKING_DATE_START} (${LOCAL.SINCE})`,
                            fieldName: 'checkingStartDateStart',
                        }}
                        to={{
                            label: `${LOCAL.LABELS.CHECKING_DATE_START} (${LOCAL.TO})`,
                            fieldName: 'checkingStartDateEnd',
                        }}
                    />
                </Col>
            </Row>
        </FilterForm>
    </ControlGroup>
))
