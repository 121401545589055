import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { ANIMATION_TIME_OUT_DEFAULT } from 'consts/layout.consts'
import { Button } from 'components/shared/Button'
import { CSSTransition } from 'react-transition-group'
import { CheckboxControl } from 'components/controls/CheckboxControl'
import { ColumnType } from 'antd/lib/table'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { Store } from 'App.types'
import { useElementVisible } from 'hooks/useElementVisible'
import { usePageSettings } from 'hooks'

import styles from './ColumnsOptions.module.scss'
import { ColumnsOptionsProps } from './ColumnsOptions.types'
import { IconsAdapter } from '../IconsAdapter'

/**
 * Меню настроек видимости колонок таблицы
 */
function ColumnsOptions<ColumnsProps extends object>({
    dictionaryKey,
    className,
    tableColumns,
    keysOfAlwaysVisibleColumns = [],
}: ColumnsOptionsProps<ColumnsProps>) {
    const [form] = Form.useForm()
    const { columnsVisible, updatePageSettings } = usePageSettings(
        dictionaryKey
    )

    const {
        elementVisible,
        toggleElementVisible,
        toggleElementVisibleRef,
        elementRef,
    } = useElementVisible()

    /**
     * Опции видимости рядов таблицы.
     * Первый и последний ряд, соответственно (наименование и кнопки управления скрыть нельзя).
     */
    const tableColumnsOptions = useMemo(
        () =>
            tableColumns?.reduce<JSX.Element[] | undefined>(
                (acc, { key, title }: ColumnType<ColumnsProps>, index) => {
                    if (
                        !title ||
                        !index ||
                        keysOfAlwaysVisibleColumns.includes(String(key)) ||
                        String(key) === 'actions'
                    )
                        return acc

                    return [
                        ...(acc || []),
                        <Form.Item name={key} key={index}>
                            <CheckboxControl className={styles.checkboxControl}>
                                {title}
                            </CheckboxControl>
                        </Form.Item>,
                    ]
                },
                []
            ),
        [keysOfAlwaysVisibleColumns, tableColumns]
    )

    /**
     * Обработчик изменения видимости столбцов
     * @param changedValue - поле формы, соответствующее столбцу, видимость которого была изменена
     */
    const handleValuesChange = useCallback(
        (changedColumn) => {
            const [changedColumnKey] = Object.keys(changedColumn)

            updatePageSettings(dictionaryKey, {
                columnsVisible: {
                    ...(columnsVisible || {}),
                    [changedColumnKey]: changedColumn[changedColumnKey],
                },
            })
        },
        [columnsVisible, dictionaryKey, updatePageSettings]
    )

    /**
     * Настройки отображения видимости столбцов, по умолчанию
     */
    const defaultColumnsVisibility = useMemo(
        () =>
            tableColumns?.reduce<Store<boolean>>(
                (acc, curr: ColumnType<ColumnsProps>) => ({
                    ...acc,
                    [curr.key as string]: true,
                }),
                {}
            ),
        [tableColumns]
    )

    /**
     * Настройки отображения видимости столбцов, вычисленные на основе контекста.
     * Столбец скрывается только в том случае, если значение false (если true или undefined - не
     * скрывается). Если настроек для данного справочника нет, то берутся настройки по умолчанию
     * (defaultColumnsVisibility)
     */
    const calculatedColumnsVisibility: Store<boolean> = useMemo(() => {
        if (!columnsVisible) return

        const resultColumnsVisibility = Object.entries(
            columnsVisible
        ).map(([key, value]) => [key, value !== false ? true : value])

        return Object.fromEntries(resultColumnsVisibility)
    }, [columnsVisible])

    return !!tableColumnsOptions?.length ? (
        <div className={cn(className, styles.wrapper)}>
            <Button
                ref={toggleElementVisibleRef}
                onClick={toggleElementVisible}
                type="link"
                icon={<IconsAdapter iconType="CustomIconColumnsOptions" />}
                className={styles.toggleMenu}
            />

            <CSSTransition
                in={elementVisible}
                timeout={ANIMATION_TIME_OUT_DEFAULT}
                classNames="animation-fade-unmount"
                unmountOnExit
            >
                <div className={styles.menu} ref={elementRef}>
                    <section>
                        <Form
                            id={FORM_IDS.COLUMNS_OPTIONS_FORM}
                            initialValues={{
                                ...(defaultColumnsVisibility || {}),
                                ...calculatedColumnsVisibility,
                            }}
                            onValuesChange={handleValuesChange}
                            form={form}
                        >
                            <h3>{LOCAL.LABELS.DISPLAYING_COLUMNS}</h3>

                            {tableColumnsOptions}
                        </Form>
                    </section>
                </div>
            </CSSTransition>
        </div>
    ) : (
        <></>
    )
}

export default React.memo(ColumnsOptions) as typeof ColumnsOptions
