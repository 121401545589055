import React, { useCallback, useEffect, useMemo } from 'react'
import {
    ActivitiesService,
    ActivityContract,
    EntityType,
    UserPermission,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, PageContent } from 'components/shared'
import { ComponentCommonProps } from 'App.types'
import { ContentTitleWithBackLink } from 'components/shared/ContentTitleWithBackLink'
import { EVENTS_REQUIRED_PERMISSIONS } from 'consts'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    UrlParamsForEditProps,
    useFormInitialValues,
    useSaveOrPublishState,
} from 'hooks'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import {
    editSessionCancel,
    isDocumentStatusInWork,
    isDocumentStatusReadyForWork,
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useParams } from 'react-router-dom'

import { EventForm } from './components'

/** Создание/редактирование мероприятия */
const EventFormContainer: React.FC<
    WithBlockUserRoutingComponentProps & ComponentCommonProps
> = withLoader(({ onBlockUserRouting, formMode, updateLoader, isLoading }) => {
    const urlParams = useParams<UrlParamsForEditProps>()

    const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()

    const {
        initialValuesForEdit,
        fetchInitialFormValuesById,
    } = useFormInitialValues<ActivityContract>({
        requestMethod: isFormModeEdit(formMode)
            ? ActivitiesService.getForEdit
            : ActivitiesService.getActive,
        updateLoader,
    })

    const refuseEdit = useCallback(() => {
        editSessionCancel({
            type: EntityType.Activity,
            id: initialValuesForEdit?.id,
        })
    }, [initialValuesForEdit])

    useEffect(() => {
        if (urlParams.id) fetchInitialFormValuesById(Number(urlParams.id))
    }, [urlParams.id, fetchInitialFormValuesById])

    const isSavable = useMemo(
        () => !isDocumentStatusInWork(initialValuesForEdit?.status),
        [initialValuesForEdit]
    )

    return (
        <PageContent grid className="pb-0">
            <ContentTitleWithBackLink
                status={initialValuesForEdit?.status}
                formMode={formMode}
                onBack={refuseEdit}
            >
                <ButtonsToolbar>
                    <Button
                        type="link"
                        href={ROUTE_NAMES.EVENTS}
                        onClick={refuseEdit}
                    >
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    {isSavable && (
                        <HasPermissions
                            requiredPermissions={[
                                ...EVENTS_REQUIRED_PERMISSIONS,
                                UserPermission.EventSave,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                text: LOCAL.ACTIONS.SAVE,
                                type: 'primary',
                            })}
                        >
                            <Button
                                htmlType="submit"
                                type="primary"
                                form={FORM_IDS.EVENT_FORM}
                                disabled={
                                    (isDocumentStatusReadyForWork(
                                        initialValuesForEdit?.status
                                    ) &&
                                        !isFormModeCopy(formMode)) ||
                                    isFormModeView(formMode) ||
                                    isLoading
                                }
                                onClick={handleSubmitForm(true)}
                            >
                                {LOCAL.ACTIONS.SAVE}
                            </Button>
                        </HasPermissions>
                    )}

                    <HasPermissions
                        requiredPermissions={[
                            ...EVENTS_REQUIRED_PERMISSIONS,
                            UserPermission.EventPublish,
                        ]}
                        accessDeniedRender={renderAccessDeniedButton({
                            text: LOCAL.ACTIONS.PUBLISH,
                            type: 'primary',
                        })}
                    >
                        <Button
                            htmlType="submit"
                            type="primary"
                            form={FORM_IDS.EVENT_FORM}
                            onClick={handleSubmitForm(false)}
                            disabled={isFormModeView(formMode) || isLoading}
                        >
                            {LOCAL.ACTIONS.PUBLISH}
                        </Button>
                    </HasPermissions>
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <EventForm
                onBlockUserRouting={onBlockUserRouting}
                initialValuesForEdit={initialValuesForEdit}
                isSaveDocument={isSaveDocument}
                formMode={formMode}
                updateLoader={updateLoader}
            />
        </PageContent>
    )
})

export default React.memo(
    withBlockUserRouting(EventFormContainer, false)
) as typeof EventFormContainer
