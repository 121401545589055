import React from 'react'
import { Col, Row } from 'antd'
import { ComponentCommonProps } from 'App.types'
import {
    FormItemAdapter,
    InputNumberControl,
    ShouldUpdateChecker,
    SwitchControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'

import styles from './NotificationsSettings.module.scss'
import { MIN_DAYS_TO_NOTIFICATION } from '../../../../TrackAssignmentForm.consts'

export const NotificationsSettings: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <>
            <Row gutter={ROW_GUTTER}>
                <Col>
                    <FormItemAdapter
                        fieldName="isNotificationEnable"
                        className={styles.notifications}
                    >
                        <SwitchControl
                            addonBefore={LOCAL.LABELS.NOTIFICATIONS}
                            formMode={formMode}
                        />
                    </FormItemAdapter>
                </Col>
            </Row>

            <ShouldUpdateChecker fieldPath={['isNotificationEnable']}>
                {({ getFieldValue }) => {
                    const isNotificationEnable = getFieldValue(
                        'isNotificationEnable'
                    )

                    return (
                        isNotificationEnable && (
                            <Row gutter={ROW_GUTTER} align="bottom">
                                <Col>
                                    <FormItemAdapter
                                        fieldName="daysToStartNotification"
                                        label={
                                            LOCAL.LABELS.NOTIFICATIONS_TIME_SET
                                        }
                                    >
                                        <InputNumberControl
                                            min={MIN_DAYS_TO_NOTIFICATION}
                                            placeholder={String(
                                                MIN_DAYS_TO_NOTIFICATION
                                            )}
                                            formMode={formMode}
                                            addonBefore={
                                                LOCAL.LABELS
                                                    .NOTIFICATIONS_TIME_SET_BEFORE
                                            }
                                            addonAfter={
                                                LOCAL.LABELS
                                                    .NOTIFICATIONS_TIME_SET_DAYS_TO_START
                                            }
                                        />
                                    </FormItemAdapter>
                                </Col>

                                <Col>
                                    <FormItemAdapter fieldName="daysToEndNotification">
                                        <InputNumberControl
                                            min={MIN_DAYS_TO_NOTIFICATION}
                                            placeholder={String(
                                                MIN_DAYS_TO_NOTIFICATION
                                            )}
                                            formMode={formMode}
                                            addonBefore={
                                                LOCAL.LABELS
                                                    .NOTIFICATIONS_TIME_SET_BEFORE
                                            }
                                            addonAfter={
                                                LOCAL.LABELS
                                                    .NOTIFICATIONS_TIME_SET_DAYS_TO_END
                                            }
                                        />
                                    </FormItemAdapter>
                                </Col>
                            </Row>
                        )
                    )
                }}
            </ShouldUpdateChecker>
        </>
    )
)
