import React from 'react'
import { ActivitySearchResultContract } from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { EVENT_DECORATION_TYPE, EVENT_SPECIES, EVENT_TYPES } from 'consts'
import { LOCAL } from 'core/local'
import { WidthRestrictedElement } from 'components/shared'
import { defaultSorter } from 'components/layouts'
import {
    formatDate,
    renderDocumentStatus,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { EventsTableActions } from './components'

/** настройка отображения колонок для каталога (перечня) мероприятий */
export const EVENT_TABLE_COLUMNS: ColumnsType<ActivitySearchResultContract> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.EVENTS_M_TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type: keyof typeof EVENT_TYPES) => EVENT_TYPES[type],
    },
    {
        title: LOCAL.LABELS.EVENTS_M_KIND,
        dataIndex: 'kind',
        key: 'kind',
        render: (species: keyof typeof EVENT_SPECIES) => EVENT_SPECIES[species],
    },
    {
        title: LOCAL.LABELS.DECORATION,
        dataIndex: 'designType',
        key: 'designType',
        render: (_, record) => (
            <WidthRestrictedElement
                title={EVENT_DECORATION_TYPE[record.decorationType]}
            />
        ),
    },
    {
        title: LOCAL.LABELS.OWNER,
        dataIndex: ['ownerOrganization', 'name'],
        key: 'ownerOrganizationName',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.VERSION,
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderDocumentStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => <EventsTableActions tableRow={tableRow} />,
        align: 'right',
        fixed: 'right',
    },
]
