import React, { useCallback, useEffect, useState } from 'react'
import {
    ContentTitle,
    PageBanner,
    PageContent,
    TableAdapter,
} from 'components/shared'
import {
    CurrentUserSettingsService,
    UserNotificationSettingSaveContract,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { withLoader } from 'HOCs'

import {
    FetchNotificationsStatusProps,
    NotificationsSettingsTableDataProps,
} from './NotificationsSettings.types'
import {
    getNotificationsSettingsTableColumns,
    mapFormDataToRequest,
    normalizedNotificationsSettingsData,
} from './NotificationsSettings.utils'

/** Настройки уведомлений */
export const NotificationsSettings: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const [settingsData, setSettingsData] = useState<
            NotificationsSettingsTableDataProps[]
        >()

        /**
         * Запрос текущих настроек оповещений
         */
        const fetchNotificationsSettings = useCallback(async () => {
            try {
                updateLoader(true)
                const dataSource = await CurrentUserSettingsService.getNotificationsSettings()
                setSettingsData(normalizedNotificationsSettingsData(dataSource))
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader])

        /**
         * Запрос для изменения статуса настроек оповещения
         */
        const handleFetchNotificationsStatus = useCallback(
            (values: FetchNotificationsStatusProps) => async (
                checked?: boolean
            ) => {
                try {
                    if (!settingsData) return
                    updateLoader(true)

                    await CurrentUserSettingsService.saveNotificationSettings({
                        body: mapFormDataToRequest({
                            ...values,
                            settingsData,
                            isChecked: checked,
                        }) as UserNotificationSettingSaveContract[],
                    })

                    fetchNotificationsSettings()
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader, fetchNotificationsSettings, settingsData]
        )

        useEffect(() => {
            fetchNotificationsSettings()
        }, [fetchNotificationsSettings])

        return (
            <PageContent>
                <PageBanner />

                <ContentTitle
                    title={getRouteMeta(ROUTE_NAMES.NOTIFICATIONS_SETTINGS)}
                />

                <TableAdapter
                    columns={getNotificationsSettingsTableColumns(
                        handleFetchNotificationsStatus,
                        settingsData
                    )}
                    dataSource={settingsData}
                    pagination={false}
                />
            </PageContent>
        )
    })
)
