import { TrackStageIconBackgroundAlbumCreateContract } from 'core/api'

import { UploadAlbumFormValuesProps } from './UploadAlbumForm.types'

/** Маппинг данных формы для отправки запроса */
export const mapFormDataToRequest = ({
    files,
    ...rest
}: UploadAlbumFormValuesProps): TrackStageIconBackgroundAlbumCreateContract => ({
    trackIconBackgroundIds: files?.map((file) => file.response.id),
    ...rest,
})
