import cn from 'classnames'
import React, { useEffect } from 'react'
import { showElem } from 'utils/common.utils'
import { useElementVisible } from 'hooks'

import loader from './GlobalLoader.svg'
import styles from './GlobalLoader.module.scss'
import { registerEvents, unRegisterEvents } from './GlobalLoader.events'

export const GlobalLoader: React.FC = React.memo(() => {
    const {
        handleHideElement,
        handleShowElement,
        elementVisible,
    } = useElementVisible()

    useEffect(() => {
        const handlers = {
            hideLoader: handleHideElement,
            showLoader: handleShowElement,
        }

        registerEvents(handlers)

        return () => unRegisterEvents(handlers)
    }, [handleHideElement, handleShowElement])

    return (
        <div className={cn(styles.wrapper, showElem(elementVisible, 'd-flex'))}>
            <img src={loader} alt="" width="48" height="48" />
        </div>
    )
})
