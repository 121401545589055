import React, { useContext } from 'react'
import { AttachmentsService } from 'core/api'
import { LOCAL } from 'core/local'
import { PortfolioFormCommonTabsContext } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import { UploadFileWrapper } from 'components/shared/UploadFileWrapper'
import { isFormModeView, validateUploadImage } from 'utils'

import { PresentationUploadImageControlProps } from './PresentationUploadImageControl.types'

/** Компонент для рендера контрола добавления фонового изображения
 */
export const PresentationUploadImageControl: React.FC<PresentationUploadImageControlProps> = React.memo(
    ({ name, initialPictureName }) => {
        const { store } = useContext(PortfolioFormCommonTabsContext)

        const { formMode, form, disabledByStatus } = store

        const disabled = isFormModeView(formMode) || disabledByStatus

        const handleFileRemove = () => {
            form?.setFields([
                {
                    name,
                    value: undefined,
                },
            ])
        }

        return (
            <>
                <h3>{LOCAL.LABELS.BACKGROUND_IMAGE}</h3>

                <UploadFileWrapper
                    name={name}
                    disabled={disabled}
                    handleFileRemove={handleFileRemove}
                    uploadMethod={AttachmentsService.upload}
                    validateFiles={validateUploadImage}
                    initialFile={initialPictureName}
                />
            </>
        )
    }
)
