import React from 'react'
import { Form } from 'antd'
import {
    InputNumberEditControl,
    SliderSingleControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { VisualizationOptionsGroup } from 'components/shared'

import styles from '../SingleStageVisualisationOptions/SingleStageVisualisationOptions.module.scss'
import {
    ICON_BORDER_RADIUS_MAX_VALUE,
    ICON_BORDER_RADIUS_MIN_VALUE,
} from './SingleStageVisualisationIconOption.consts'
import { SingleStageVisualisationIconOptionProps } from './SingleStageVisualisationIconOption.types'
import { TRACK_STAGE_ICON_SIZE_DEFAULT } from '../TrackStageDnD/TrackStageDnD.consts'

/** Настройка размеров иконки визуализации этапа */
export const SingleStageVisualisationIconOption: React.FC<SingleStageVisualisationIconOptionProps> = React.memo(
    ({ stageIndex, canvasKey, stageStatus }) => (
        <>
            <VisualizationOptionsGroup
                title={LOCAL.LABELS.ICONS_SIZES}
                className={styles.icons}
            >
                <Form.Item
                    label={LOCAL.LABELS.WIDTH}
                    labelAlign="left"
                    labelCol={{ xs: 6 }}
                    name={[
                        'trackCanvases',
                        canvasKey,
                        'trackStageStyles',
                        stageIndex,
                        'icons',
                        stageStatus,
                        'width',
                    ]}
                >
                    <InputNumberEditControl
                        min={1}
                        initialValue={TRACK_STAGE_ICON_SIZE_DEFAULT}
                    />
                </Form.Item>

                <Form.Item
                    label={LOCAL.LABELS.HEIGHT}
                    labelAlign="left"
                    labelCol={{ xs: 6 }}
                    name={[
                        'trackCanvases',
                        canvasKey,
                        'trackStageStyles',
                        stageIndex,
                        'icons',
                        stageStatus,
                        'height',
                    ]}
                >
                    <InputNumberEditControl
                        min={1}
                        initialValue={TRACK_STAGE_ICON_SIZE_DEFAULT}
                    />
                </Form.Item>
            </VisualizationOptionsGroup>

            <VisualizationOptionsGroup title={LOCAL.LABELS.ICON_ROUNDING}>
                <Form.Item
                    name={[
                        'trackCanvases',
                        canvasKey,
                        'trackStageStyles',
                        stageIndex,
                        'icons',
                        stageStatus,
                        'iconRounding',
                    ]}
                >
                    <SliderSingleControl
                        min={ICON_BORDER_RADIUS_MIN_VALUE}
                        max={ICON_BORDER_RADIUS_MAX_VALUE}
                        initialValue={ICON_BORDER_RADIUS_MAX_VALUE}
                        addonNumberAfter
                        marks={{
                            [ICON_BORDER_RADIUS_MIN_VALUE]: ICON_BORDER_RADIUS_MIN_VALUE,
                            [ICON_BORDER_RADIUS_MAX_VALUE]: ICON_BORDER_RADIUS_MAX_VALUE,
                        }}
                    />
                </Form.Item>
            </VisualizationOptionsGroup>
        </>
    )
)
