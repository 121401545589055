import React from 'react'
import cn from 'classnames'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { Radio } from 'antd'
import { useImgLoader } from 'hooks'

import styles from './CanvasBackgroundRadio.module.scss'
import { CanvasBackgroundRadioProps } from './CanvasBackgroundRadio.types'

/** Компонент картинки выбора фонового полотна трека */
export const CanvasBackgroundRadio: React.FC<CanvasBackgroundRadioProps> = React.memo(
    ({ src, id, onRemoveImg, className }) => {
        const [bg] = useImgLoader({ src })

        return (
            <div className={cn(styles.wrapper, className)}>
                <Radio
                    value={id}
                    className={styles.control}
                    style={{
                        backgroundImage: bg,
                    }}
                />

                {!!onRemoveImg && (
                    <div className={styles.areaRemove}>
                        <IconsAdapter
                            iconType="CustomIconTrashAlt"
                            className={styles.iconRemove}
                            onClick={onRemoveImg}
                        />
                    </div>
                )}
            </div>
        )
    }
)
