import React from 'react'
import cn from 'classnames'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import {
    CourseContract,
    CourseForModalContract,
    CourseSearchContract,
    CoursesService,
} from 'core/api'
import {
    DocumentsFilters,
    TRAINING_MATERIALS_TABLE_COLUMNS,
} from 'components/controls'
import { DynamicTableAdapter } from 'components/shared'
import { Form } from 'antd'
import { LOCAL } from 'core/local'

import styles from '../AdditionalMaterials.module.scss'
import stylesTrackForm from '../../../../TrackForm.module.scss'
import { COURSES_FORMATS_TO_EXCLUDE } from './VideoFilesArea.consts'
import { VideoFilesFormContract } from '../../../../TrackForm.types'
import {
    getRenderColumnFn,
    normalizeFn,
    renderTableActions,
} from './VideoFilesArea.utils'

/**
 * Компонент отображения блока видеофайлов
 */
export const VideoFilesArea: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => (
    <Form.Item name={['additionals', 'video']} noStyle>
        <DynamicTableAdapter<
            CourseContract,
            VideoFilesFormContract,
            CourseSearchContract,
            CourseForModalContract
        >
            getEntitiesByIds={CoursesService.getByIds}
            searchMethod={CoursesService.getForModal}
            className={cn(styles.wrapper, stylesTrackForm.dynamicTableAdapter)}
            tableFiltersOptions={{
                initialValues: {
                    excludeFormats: COURSES_FORMATS_TO_EXCLUDE,
                },
            }}
            modalFilterComponent={DocumentsFilters}
            columnsForSearchModal={TRAINING_MATERIALS_TABLE_COLUMNS}
            normalizeFn={normalizeFn}
            title={LOCAL.LABELS.VIDEO_FILES}
            modalTitle={LOCAL.LABELS.CONTENT_SELECTION}
            formMode={formMode}
            renderTableColumns={getRenderColumnFn(formMode, disabled)}
            tableOptions={{
                customTableActions: renderTableActions,
            }}
            disabled={disabled}
        />
    </Form.Item>
))
