import { ActivitiesService } from 'core/api'
import { RcCustomRequestOptions } from 'types'

import {
    GetConnectionLineBackgroundStylesProps,
    ThematicBlockStatuses,
} from './EventFormVisualization.types'

/**
 * Загрузка нового фонового изображеня
 * @param request объект запроса, содержащего изображение
 */
export const canvasBgImgsUploadFn = (request: RcCustomRequestOptions) =>
    ActivitiesService.uploadCanvasPicture({
        file: request.file,
    })

/**
 * Запрос фоновых изображений
 * @param pageSize количество записей на стр.
 * @param pageNumber номер стр.
 */
export const canvasBgImgsFetchFn = (pageSize: number, pageNumber: number) =>
    ActivitiesService.getActivityPictureWithPagination({
        pageSize,
        pageNumber,
    })

/**
 * Создаем массив по количеству пройденных блоков или заблокированных
 * @param thematicBlocksCount общее количество тематических блоков
 * @param completedBlocks флаг, определяющий тип тематического блока (пройденный или недоступный
 * для прохождения)
 * @return массив для итерации при рендере визуализации блоков
 */
export const getArrayForRenderThematicBlock = (
    thematicBlocksCount: number,
    completedBlocks?: boolean
): number[] => {
    const thematicBlocksCountHalf = thematicBlocksCount / 2

    return Array.from(
        Array(
            Math.floor(
                // eslint-disable-next-line no-nested-ternary
                completedBlocks
                    ? thematicBlocksCountHalf
                    : thematicBlocksCount % 2
                    ? thematicBlocksCountHalf
                    : thematicBlocksCountHalf - 1
            )
        ).keys()
    )
}

/** Получить стили для линии соединяющей тематические блоки */
export const getConnectionLineBackgroundStyles = ({
    showLines,
    thematicBlockColorAfterPassing,
    thematicBlocksCount,
    thematicBlockColorBeforePassing,
    thematicBlockColorWhilePassing,
    type,
    currentElement,
}: GetConnectionLineBackgroundStylesProps) => {
    if (!showLines) return {}

    switch (type) {
        case ThematicBlockStatuses.BeforePassing:
            return { background: thematicBlockColorBeforePassing }

        case ThematicBlockStatuses.AfterPassing: {
            if (currentElement !== undefined)
                return {
                    background:
                        currentElement + 1 ===
                        Math.floor(thematicBlocksCount / 2)
                            ? `linear-gradient(90deg, ${thematicBlockColorAfterPassing}, ${thematicBlockColorWhilePassing})`
                            : thematicBlockColorAfterPassing,
                }
            break
        }

        case ThematicBlockStatuses.Current:
            return {
                background: `linear-gradient(to right, ${thematicBlockColorWhilePassing}, ${thematicBlockColorBeforePassing})`,
            }
    }
}
