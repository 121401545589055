import cn from 'classnames'
import React, { FC, useContext } from 'react'
import {
    CheckboxControl,
    FormItemAdapter,
    SwitchControl,
    TextAreaControl,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import { CollapseSingle } from 'components/shared/CollapseSingle'
import { LOCAL } from 'core/local'
import { PortfolioFormCommonTabsContext } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import { ROW_GUTTER } from 'consts'
import {
    isFormModeCopy,
    isFormModeView,
    isQuestionnaireTypeAssessment,
} from 'utils'

import styles from './PortfolioWelcomeWindow.module.scss'
import { PRESENTATION_FORM_FIELD_NAME } from '../../PortfolioPresentation.consts'
import { PresentationUploadImageControl } from '../PresentationUploadImageControl'
import { WindowTextSettings } from '../WindowTextSettings'

/** Блок настроек оформления для портфелей - приветственное окно */
export const PortfolioWelcomeWindow: FC = React.memo(() => {
    const { store } = useContext(PortfolioFormCommonTabsContext)

    const {
        formMode,
        portfolioFormType,
        initialValuesForEdit,
        disabledByStatus,
        form,
    } = store

    const initialPictureName = {
        name: isFormModeCopy(formMode)
            ? form?.getFieldValue('presentation')?.welcomeWindow?.picture
                  ?.fileName
            : initialValuesForEdit?.presentation?.welcomeWindow?.picture
                  ?.fileName,
    }

    return (
        <CollapseSingle title={LOCAL.LABELS.WELCOME_MODAL} active>
            <Form.Item name={['presentation', 'welcomeWindow', 'showGreeting']}>
                <SwitchControl
                    disabled={isFormModeView(formMode) || disabledByStatus}
                    addonAfter={LOCAL.LABELS.SHOW_APPEAL}
                    className={styles.showGreeting}
                />
            </Form.Item>

            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName={[
                            PRESENTATION_FORM_FIELD_NAME,
                            'welcomeWindow',
                            'title',
                        ]}
                        label={LOCAL.LABELS.TITLE_TEXT}
                    >
                        <TextAreaControl
                            autoSize={{ minRows: 4 }}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName={[
                            PRESENTATION_FORM_FIELD_NAME,
                            'welcomeWindow',
                            'message',
                        ]}
                        label={LOCAL.LABELS.GREETINGS_TEXT}
                        required
                    >
                        <TextAreaControl
                            autoSize={{ minRows: 4 }}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </FormItemAdapter>
                </Col>

                <Col
                    xs={{
                        span: 8,
                    }}
                    xxl={{
                        span: 7,
                        offset: 1,
                    }}
                >
                    <WindowTextSettings
                        formMode={formMode}
                        type="titleTextSettings"
                        windowFormFieldName="welcomeWindow"
                        disabled={disabledByStatus}
                    />

                    <WindowTextSettings
                        formMode={formMode}
                        type="messageTextSettings"
                        windowFormFieldName="welcomeWindow"
                        disabled={disabledByStatus}
                    />
                </Col>

                <Col
                    xs={{
                        span: 8,
                    }}
                    xxl={{
                        span: 7,
                        offset: 1,
                    }}
                >
                    <PresentationUploadImageControl
                        name={[
                            PRESENTATION_FORM_FIELD_NAME,
                            'welcomeWindow',
                            'pictureId',
                        ]}
                        initialPictureName={initialPictureName}
                    />

                    {isQuestionnaireTypeAssessment(portfolioFormType) && (
                        <>
                            <h3 className={cn(styles.title, 'pt-20')}>
                                {LOCAL.LABELS.ADDITIONAL_SETTINGS}
                            </h3>

                            <Form.Item
                                name={[
                                    PRESENTATION_FORM_FIELD_NAME,
                                    'welcomeWindow',
                                    'showCandidateRole',
                                ]}
                            >
                                <CheckboxControl
                                    formMode={formMode}
                                    disabled={disabledByStatus}
                                >
                                    {LOCAL.LABELS.SHOW_EVALUATOR_ROLE.toLowerCase()}
                                </CheckboxControl>
                            </Form.Item>
                        </>
                    )}
                </Col>
            </Row>
        </CollapseSingle>
    )
})
