import isNil from 'lodash/isNil'

import { PositionProfileCompetenceCustomContract } from '../JobProfileForm/JobProfileForm.types'

/**
 * Функция подготавливает коллекцию компетенций для кэширования в состоянии
 * @param prevCompetencies - коллекция уже хранящихся в  состоянии компетенций
 * @param newCompetencies - коллекция новых компетенций, которые возможно должны быть добавлены в состояние
 * @param isReset флг сброса уровня компетенции
 * @returns {PositionProfileCompetenceContract[] | undefined} данные, которые заменят собой старые
 *     в состоянии
 */
export const checkCompetenceItemsToCache = (
    prevCompetencies?: PositionProfileCompetenceCustomContract[],
    newCompetencies?: PositionProfileCompetenceCustomContract[],
    isReset?: boolean
): PositionProfileCompetenceCustomContract[] => {
    if (prevCompetencies) {
        if (!newCompetencies) return prevCompetencies

        return newCompetencies.reduce<
            PositionProfileCompetenceCustomContract[]
        >(
            (prevCompetencies, currentElem) => {
                const commonItem = prevCompetencies.find(
                    (elem) => elem.id === currentElem.id
                )

                if (!commonItem) {
                    return [...prevCompetencies, currentElem]
                }

                if (!isNil(currentElem.competenceLevel) || isReset) {
                    commonItem.competenceLevel = currentElem.competenceLevel
                }

                return prevCompetencies
            },
            [...prevCompetencies]
        )
    }

    return newCompetencies || []
}
