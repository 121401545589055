import React from 'react'
import {
    AddOrganizationControl,
    FormItemAdapter,
    HiddenField,
    InputControl,
    RangePickerControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { AssessmentType } from 'core/api'
import { Col, Form, Row } from 'antd'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import {
    disableDate,
    isAssessmentTypeEstimate360,
    isAssessmentTypeManager,
    normalizeDataForSelectAndRadio,
    normalizeDictionaryToControl,
    pickSelectOptions,
} from 'utils'
import { useDictionariesHelper } from 'hooks'

import styles from './AssessmentProjectsCommonSettings.module.scss'
import { AssessmentProjectsCommonSettingsProps } from './AssessmentProjectsCommonSettings.types'
import { AssessmentProjectsEstimate360 } from '../AssessmentProjectsEstimate360'
import { AssessmentProjectsManagerEstimate } from '../AssessmentProjectsManagerEstimate'

/** Вкладка основных настроек оценочного проекта */
export const AssessmentProjectsCommonSettings: React.FC<AssessmentProjectsCommonSettingsProps> = React.memo(
    ({ formMode, form, goals }) => {
        const { assessmentTypeDictionary } = useDictionariesHelper([
            'assessmentTypeDictionary',
        ])

        return (
            <>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="requestNumber"
                            label={LOCAL.LABELS.REQUEST_NUMBER}
                            required
                        >
                            <InputControl formMode={formMode} />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.PROJECT_NAME}
                            required
                        >
                            <InputControl formMode={formMode} />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="purposeId"
                            label={LOCAL.LABELS.GOAL}
                            required
                        >
                            <SelectControl
                                values={goals?.map(
                                    normalizeDataForSelectAndRadio
                                )}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={24}>
                        <Form.Item name="structuralUnitIds" noStyle>
                            <AddOrganizationControl
                                formMode={formMode}
                                className={styles.organizationsControl}
                                title={LOCAL.LABELS.ORGANIZATION}
                                required
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={ROW_GUTTER} className={styles.row}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="assessmentType"
                            label={LOCAL.LABELS.ASSESSMENT_METHOD}
                            required
                        >
                            <SelectControl
                                values={pickSelectOptions(
                                    normalizeDictionaryToControl(
                                        assessmentTypeDictionary
                                    ),
                                    [
                                        AssessmentType.Estimate_360,
                                        AssessmentType.EstimateByManager,
                                    ]
                                )}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="holdingDate"
                            label={LOCAL.LABELS.PROJECT_HOLDING_PERIOD_SETTINGS}
                            required
                        >
                            <RangePickerControl
                                formMode={formMode}
                                disabledDate={disableDate()}
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>

                <Row gutter={ROW_GUTTER}>
                    <Col xs={24}>
                        <ShouldUpdateChecker fieldPath={['assessmentType']}>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('assessmentType')

                                if (isAssessmentTypeEstimate360(type))
                                    return (
                                        <AssessmentProjectsEstimate360
                                            formMode={formMode}
                                            form={form}
                                        />
                                    )

                                if (isAssessmentTypeManager(type))
                                    return (
                                        <AssessmentProjectsManagerEstimate
                                            formMode={formMode}
                                        />
                                    )
                            }}
                        </ShouldUpdateChecker>
                    </Col>
                </Row>

                <HiddenField fieldName="id" />
            </>
        )
    }
)
