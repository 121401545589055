import React, { useEffect, useMemo, useState } from 'react'
import { AssessmentReportContent } from 'components/pages/AssessmentReport'
import {
    AssessmentReportContract,
    AssessmentReportsService,
    UserProfilesService,
} from 'core/api'
import { LOCAL } from 'core/local'
import { PageContent, ReportsTabs } from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ResultControl } from 'components/controls'
import { UrlParamsCommonProps } from 'App.types'
import { UserProfileHeader } from 'components/pages/UserProfile/components'
import { UserProfileStateProps } from 'components/pages/UserProfile/UserProfile.types'
import { getFullFio } from 'utils'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'hooks'
import { withLoader } from 'HOCs'

import styles from './ProfileReports.module.scss'

/**
 * Отчёт в профиле
 */
export const ProfileReports: React.FC = withLoader(
    React.memo(({ updateLoader, isLoading }) => {
        const { push } = useHistory()

        const { appointmentId, reportId } = useParams<
            UrlParamsCommonProps<'reportId' | 'appointmentId'>
        >()

        const query = useQuery()
        const userId = query.get('userId')

        /**
         * Стейт для хранения данных профиля пользователя id: Number(userData?.id ||
         */
        const [userProfile, setUserProfile] = useState<UserProfileStateProps>()

        /**
         * Стейт для хранения данных отчетов
         */
        const [reportsData, setReportsData] = useState<
            AssessmentReportContract
        >()

        /**
         * ФИО
         */
        const fullFio = useMemo(() => getFullFio(userProfile?.user), [
            userProfile,
        ])

        useEffect(() => {
            const fetch = async () => {
                try {
                    updateLoader(true)

                    setUserProfile(
                        userId
                            ? await UserProfilesService.getUserById({
                                  id: Number(userId),
                              })
                            : await UserProfilesService.getCurrentUserProfile()
                    )

                    const reportDataSource = await AssessmentReportsService.getAssessmentReportByAppointmentId(
                        {
                            appointmentId: Number(appointmentId),
                        }
                    )

                    setReportsData(reportDataSource)

                    push(
                        `${ROUTE_NAMES.PROFILE_REPORTS}/${appointmentId}/${
                            reportDataSource.id
                        }${userId ? `?userId=${userId}` : ''}`
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            }

            fetch()
        }, [appointmentId, push, reportId, updateLoader, userId])

        const beforeLoadAndNoResultTemplate = isLoading ? null : (
            <ResultControl title={LOCAL.LABELS.REPORT_NOT_AVAILABLE} />
        )

        return (
            <PageContent>
                <UserProfileHeader
                    fullFio={fullFio}
                    userProfile={userProfile}
                    hideBackLink
                />

                <ReportsTabs userId={userId} appointmentId={appointmentId} />

                <div>
                    {reportsData?.type &&
                    reportsData?.availableForCandidate &&
                    reportId ? (
                        <div className={styles.wrapper}>
                            <AssessmentReportContent
                                updateLoader={updateLoader}
                                assessmentType={reportsData.type}
                            />
                        </div>
                    ) : (
                        beforeLoadAndNoResultTemplate
                    )}
                </div>
            </PageContent>
        )
    })
)
