import React from 'react'
import cn from 'classnames'

import styles from './ControlGroupItem.module.scss'
import { ControlGroupItemProps } from './ControlGroupItem.types'

/** Композитный элемент для вывода в списке */
export const ControlGroupItem: React.FC<ControlGroupItemProps> = React.memo(
    ({ children, className, type = 'border' }) => (
        <div className={cn(styles.component, styles[type], className)}>
            {children}
        </div>
    )
)
