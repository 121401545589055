import cn from 'classnames'
import React, { useCallback } from 'react'
import { Col, Form, Row } from 'antd'
import { CoursesService } from 'core/api'
import { FORM_IDS } from 'core/configs'
import {
    FormItemAdapter,
    HiddenField,
    InputControl,
    NotEditableTextField,
    ShouldUpdateChecker,
    TextAreaControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ROW_GUTTER } from 'consts'
import { SpaceAdapter } from 'components/shared'
import {
    convertToShouldUpdateDep,
    createConfirmPopup,
    getFormPopupTitle,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router'
import { withLoader } from 'HOCs'

import styles from './CourseMainInfo.module.scss'
import { CourseMainInfoProps } from './CourseMainInfo.types'
import { CourseVersions } from '../CourseVersions'
import { DEPENDENT_FIELDS } from './CourseMainInfo.consts'
import { mapFormDataToRequest } from '../../TrainingMaterialInfo.utils'

/** Вкладка основной информации о курсе */
export const CourseMainInfo: React.FC<CourseMainInfoProps> = withLoader(
    React.memo(({ onFetchCourse, form, updateLoader }) => {
        const history = useHistory()

        const handleFinish = useCallback(
            async (values) => {
                try {
                    updateLoader(true)

                    await CoursesService.updateInfo({
                        body: mapFormDataToRequest(values),
                    })

                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(ROUTE_NAMES.TRAINING_MATERIALS)
                        ),
                        content: LOCAL.MESSAGES.CHANGES_SAVED,
                        onOk: () => {
                            history.push(ROUTE_NAMES.TRAINING_MATERIALS)
                        },
                        onCancel: () => {
                            onFetchCourse?.(values.id)
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [history, onFetchCourse, updateLoader]
        )

        return (
            <Form
                id={FORM_IDS.COURSE_INFO_FORM}
                onFinish={handleFinish}
                form={form}
            >
                <Row
                    gutter={ROW_GUTTER}
                    justify="space-between"
                    className="mt-30"
                >
                    <Col xs={12}>
                        <ShouldUpdateChecker
                            fieldPath={convertToShouldUpdateDep(
                                DEPENDENT_FIELDS
                            )}
                        >
                            {({ getFieldsValue }) => {
                                const props = getFieldsValue(DEPENDENT_FIELDS)

                                return (
                                    <CourseVersions
                                        {...props}
                                        uniqKey={props.key}
                                        updateLoader={updateLoader}
                                        onFetchCourse={onFetchCourse}
                                    />
                                )
                            }}
                        </ShouldUpdateChecker>

                        <NotEditableTextField
                            label={`${LOCAL.LABELS.OWNER} (${LOCAL.LABELS.ORGANIZATION})`}
                            fieldName={['structuralUnit', 'name']}
                        />

                        <HiddenField fieldName={['structuralUnit', 'id']} />

                        <HiddenField fieldName={['launchUrl']} />

                        <HiddenField fieldName={['rootCourseVersion']} />

                        <NotEditableTextField
                            label={LOCAL.LABELS.RECORD_NAME}
                            fieldName="name"
                        />

                        <SpaceAdapter
                            fullWidth
                            justifyContent="space-between"
                            className="mb-25"
                        >
                            <NotEditableTextField
                                label={LOCAL.LABELS.FORMAT}
                                fieldName="format"
                            />

                            <NotEditableTextField
                                label={LOCAL.LABELS.SIZE}
                                fieldName="fileSize"
                            />

                            <NotEditableTextField
                                label={LOCAL.LABELS.UPLOAD_AUTHOR}
                                fieldName={['author', 'name']}
                            />

                            <NotEditableTextField
                                label={LOCAL.LABELS.DATE_UPLOAD_VERSION}
                                fieldName="uploadDate"
                            />
                        </SpaceAdapter>

                        <FormItemAdapter
                            label={LOCAL.LABELS.COMMENT}
                            fieldName="comment"
                        >
                            <TextAreaControl autoSize={{ minRows: 3 }} />
                        </FormItemAdapter>

                        <h2 className={styles.titleSecondary}>
                            {LOCAL.LABELS.COURSE_DEVELOPER}
                        </h2>

                        <FormItemAdapter
                            label={LOCAL.LABELS.ORGANIZATION}
                            fieldName="organization"
                        >
                            <InputControl />
                        </FormItemAdapter>

                        <FormItemAdapter
                            label={LOCAL.LABELS.EMAIL}
                            fieldName="email"
                        >
                            <InputControl />
                        </FormItemAdapter>

                        <FormItemAdapter
                            label={LOCAL.LABELS.PHONE_NUMBER}
                            fieldName="phoneNumber"
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <h2>{LOCAL.LABELS.ADDITIONAL_INFO}</h2>

                        <NotEditableTextField
                            label={LOCAL.LABELS.FILE_NAME}
                            fieldName="fileName"
                            className="mb-30"
                        />

                        <NotEditableTextField
                            label={LOCAL.LABELS.FILE_HASH}
                            fieldName="fileHash"
                            className="mb-30"
                        />

                        <NotEditableTextField
                            label={LOCAL.LABELS.FILE_ID}
                            fieldName="identifier"
                            className={cn(styles.id, 'mb-30')}
                        />

                        <NotEditableTextField
                            label={LOCAL.LABELS.FILE_STRUCTURE}
                            className="mb-30"
                            fieldName="topics"
                        />

                        <FormItemAdapter
                            label={LOCAL.LABELS.KEY}
                            fieldName="key"
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <HiddenField fieldName="structuralUnit" />
                </Row>
            </Form>
        )
    })
)
