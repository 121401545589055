import React from 'react'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { FormContainer } from 'components/layouts'
import { ScaleContract, ScalesService } from 'core/api'

import {
    CANCEL_BTN_OPTIONS,
    PUBLISH_BTN_OPTIONS,
    SAVE_BTN_OPTIONS,
} from './CriteriaScales.config'
import { CriteriaScalesForm } from './components'

/**
 * Страница создания/редактирования шкалы опроса
 */
export const CriteriaScalesFormContainer: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <FormContainer<ScaleContract>
            formId={FORM_IDS.POLL_SCALE_FORM}
            formMode={formMode}
            formComponent={CriteriaScalesForm}
            requestMethod={ScalesService.getActive}
            cancelBtnOptions={CANCEL_BTN_OPTIONS}
            saveBtnOptions={SAVE_BTN_OPTIONS}
            publishBtnOptions={PUBLISH_BTN_OPTIONS}
        />
    )
)
