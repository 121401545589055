import React, { useCallback } from 'react'
import { AppError, ProductCardsService } from 'core/api'
import { ComponentCommonProps } from 'App.types'
import {
    FormItemAdapter,
    UploadDraggerImageControl,
    getLastFileFromList,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { MEDIA_DOCUMENT_TYPE } from 'consts'
import { RcCustomRequestOptions } from 'types'
import { TooltipAdapter } from 'components/shared'
import {
    getStringWithoutDots,
    isFormModeView,
    validateUploadImage,
} from 'utils'

import styles from '../ProductCatalogForm/ProductCatalogForm.module.scss'

/** Блок полей формы создания карточки продукта - изображение продукта в каталоге */
export const ProductImage: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => {
        /**
         * Отправка файла на сервер
         * @param request - тело запроса
         */
        const uploadImage = useCallback(
            async (request: RcCustomRequestOptions) => {
                try {
                    const result = await ProductCardsService.upload({
                        file: request.file,
                    })

                    request.onSuccess?.(result, {} as XMLHttpRequest)
                } catch (error) {
                    request.onError?.(error as AppError)
                }
            },
            []
        )

        return (
            <TooltipAdapter title={getStringWithoutDots(MEDIA_DOCUMENT_TYPE)}>
                <FormItemAdapter
                    fieldName="picture"
                    label={LOCAL.LABELS.PRODUCT_IMAGE_IN_CATALOG}
                    valuePropName="fileList"
                    getValueFromEvent={getLastFileFromList}
                    required
                >
                    <UploadDraggerImageControl
                        accept={MEDIA_DOCUMENT_TYPE}
                        customRequest={uploadImage}
                        beforeUpload={validateUploadImage}
                        className={styles.productImgArea}
                        disabled={isFormModeView(formMode)}
                    />
                </FormItemAdapter>
            </TooltipAdapter>
        )
    }
)
