import React from 'react'
import isNil from 'lodash/isNil'
import { LOCAL } from 'core/local'

import stylesReportView from './ReportView.module.scss'
import { ReportViewProgressProps } from './ReportView.types'

/** Компонент для отображения линии прогресса в отчетах */
export const ReportViewProgress: React.FC<ReportViewProgressProps> = React.memo(
    ({ value, displayValue, color, alwaysFull }) => {
        const width = !isNil(value) && `${alwaysFull ? 100 : value}%`

        return (
            <div className={stylesReportView.lineContainer}>
                {width ? (
                    <div
                        style={{ width, backgroundColor: color }}
                        className={stylesReportView.line}
                    >
                        <span>{displayValue || value}</span>
                    </div>
                ) : (
                    <div className={stylesReportView.lineNoData}>
                        {LOCAL.LABELS.NO_DATA_SHORT}
                    </div>
                )}
            </div>
        )
    }
)
