import React from 'react'
import { Col } from 'antd'
import {
    DatePickerControl,
    FormItemAdapter,
    InputNumberControl,
    RangePickerControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { disableDateWhenSelectedDate, getDisabledDate } from 'utils'

import { MIN_DAYS_FOR_TRACK_ACCESSIBILITY } from './PeriodSettings.consts'
import { PeriodSettingsProps } from './PeriodSettings.types'

/**
 * Настройки периода трека
 */
export const PeriodSettings: React.FC<PeriodSettingsProps> = React.memo(
    ({ formMode, initialValuesForEdit }) => (
        <ShouldUpdateChecker fieldPath={['isDuration']}>
            {({ getFieldValue }) => {
                const status = getFieldValue('status')

                const disabledDate = getDisabledDate(
                    status,
                    initialValuesForEdit?.periodFrom
                )

                return getFieldValue('isDuration') ? (
                    <>
                        <Col>
                            <FormItemAdapter
                                fieldName="periodFrom"
                                label={LOCAL.LABELS.DATE_TRACK_STARTED}
                                required
                            >
                                <DatePickerControl
                                    disabledDate={disableDateWhenSelectedDate(
                                        disabledDate
                                    )}
                                    formMode={formMode}
                                />
                            </FormItemAdapter>
                        </Col>

                        <Col>
                            <FormItemAdapter
                                fieldName="duration"
                                label={LOCAL.LABELS.TRACK_ACCESSIBILITY}
                                required
                            >
                                <InputNumberControl
                                    min={MIN_DAYS_FOR_TRACK_ACCESSIBILITY}
                                    placeholder={String(
                                        MIN_DAYS_FOR_TRACK_ACCESSIBILITY
                                    )}
                                    addonAfter={
                                        LOCAL.LABELS.DAYS_FROM_ASSIGNMENT_DATE
                                    }
                                    formMode={formMode}
                                />
                            </FormItemAdapter>
                        </Col>
                    </>
                ) : (
                    <Col>
                        <FormItemAdapter
                            fieldName="period"
                            label={LOCAL.LABELS.TRACK_START_PERIOD}
                            required
                        >
                            <RangePickerControl
                                disabledDate={disableDateWhenSelectedDate(
                                    disabledDate
                                )}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    </Col>
                )
            }}
        </ShouldUpdateChecker>
    )
)
