import { TrackModalSearchContract } from 'core/api'
import { getValue } from 'utils'

import { ProductCardPublishFormContract } from '../ProductCatalogForm/ProductCatalogForm.types'

export const prepareSearchOptions = ({
    organizations,
    ...restValues
}: ProductCardPublishFormContract): Partial<TrackModalSearchContract> =>
    ({
        ...restValues,
        structuralUnitIds: organizations?.map(getValue),
    } as Partial<TrackModalSearchContract>)
