import cn from 'classnames'
import React, { useContext } from 'react'
import { TrackPassingContext } from 'components/pages/TrackPassing/TrackPassing.context'

import styles from './FullScreenSingleBlock.module.scss'
import { ActivityChildProps } from '../../ActivityLayer.types'
import { getBlockBody } from '../../ActivityLayer.render'
import { getBlocksStyle } from '../../ActivityLayer.utils'

/**
 * Отображение для конретного блока, для мероприятия типа - "fullscreen"
 */
export const FullScreenSingleBlock: React.FC<
    {
        lessonIndex: number
    } & ActivityChildProps
> = React.memo(({ lessonIndex, activity }) => {
    const { store, updateStore } = useContext(TrackPassingContext)

    const { activeLessonIndex } = store

    const lessons = activity?.activityLessonPassings
    const canvas = activity?.canvas

    const lesson = lessons?.[lessonIndex]

    const isActiveBlock = activeLessonIndex === lessonIndex

    if (!lesson || !canvas) return null

    return (
        <div
            style={getBlocksStyle(canvas, lesson.status)}
            className={cn(styles.wrapper, isActiveBlock && styles.active)}
            onClick={() => {
                updateStore({ activeLessonIndex: lessonIndex })
            }}
        >
            {getBlockBody(canvas, lesson.status)}
        </div>
    )
})
