import { LabeledValue } from 'antd/lib/select'
import { StructuralUnitType } from 'core/api'

import { StructuralUnitParentsDictionaryProps } from './JobProfileForm.types'

export const COMPETENCIES_LEVELS: LabeledValue[] = []

for (let i = 0; i < 6; i += 1) {
    COMPETENCIES_LEVELS.push({
        key: String(i),
        value: i,
        label: i,
    })
}

export const COMPETENCE_GROUPS_SHOULD_UPDATE = [
    'oivId',
    'functionClassesIds',
    'levelId',
]

export const STRUCTURAL_UNITS_SHOULD_FETCH = [
    'supervisingComplexId',
    'oivId',
    'organization',
]

export const JOB_PROFILE_FORM_ID = 'jobProfileForm'

export const MANAGE_EXPERIENCE_MISSING_ID = 1

/**
 * Справочник для сопоставления связей типа `StructuralUnitType - child
 * dictionary`, где StructuralUnitType - тип унита из structuralUnitParents, по
 * id которого запрашивается справочник для child, child dictionary -
 * справочник из `dictionaries` useDictionaries
 */
export const PARENT_CHILD_DICTIONARY: StructuralUnitParentsDictionaryProps = {
    [StructuralUnitType.Complex]: 'oiv',
    [StructuralUnitType.Oiv]: 'organization',
    [StructuralUnitType.Organization]: 'structuralUnits',
}

/**
 * Справочник для сопоставления связей `StructuralUnitType - form field value`
 */
export const PARENT_FORM_VALUES_DICTIONARY = {
    [StructuralUnitType.Complex]: 'supervisingComplexId',
    [StructuralUnitType.Oiv]: 'oivId',
    [StructuralUnitType.Organization]: 'organization',
    [StructuralUnitType.Unit]: 'structuralUnitId',
}
