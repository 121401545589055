import { FormInstance } from 'rc-field-form'
import { LabeledValue } from 'antd/lib/select'
import { ReactText } from 'react'

export interface ComponentCommonProps
    extends WithFormInstanceProps,
        WithFormModeProps {
    className?: string
    topIndent?: IndentType
    bottomIndent?: IndentType
}

/** Альтернатива Store node_modules\rc-field-form\lib\interface.d.ts, с более строгой типизаций */
export type Store<T = any> = {
    [key: string]: T
}

/** Тип функции - экшена */
export type FnActionProps<T = any, R = void> = (args?: T) => R

/** Тип функции - экшена, с обязательными аргументами */
export type FnActionRequiredProps<T, R = void> = (args: T) => R

/** Тип функции - экшена, возвращающего промис */
export type FnActionAsyncProps<T = any, D = void> = FnActionProps<T, Promise<D>>

/** Тип функции - экшена, возвращающего промис, с обязательными аргументами */
export type FnActionAsyncRequiredProps<T, D = void> = FnActionRequiredProps<
    T,
    Promise<D>
>

/** Общий тип для стейта справочников форм */
export type DictionariesCommonProps<
    DictionariesKeys extends string | number | symbol,
    ResultType = LabeledValue
> = Partial<Record<DictionariesKeys, ResultType[]>>

/** Общий тип для urlParams при использовании хука useParams */
export type UrlParamsCommonProps<
    Params extends string | number | symbol
> = Partial<Record<Params, string>>

export type FormMode = 'view' | 'copy' | 'edit' | 'create' | 'passing'

/** Общий интерфейс для управления типом формы */
export interface WithFormModeProps {
    formMode?: FormMode
}

/** Общий интерфейс для компонентов содержащих экземпляр form */
export interface WithFormInstanceProps<T = any> {
    /** экземпляр формы antd */
    form?: FormInstance<T>
}

export interface WithDisabledProps {
    disabled?: boolean
}

/** Общий интерфейс для кастомных контролов полей форм */
export interface CustomControlCommonProps<T> extends WithFormModeProps {
    value?: T
    onChange?: FnActionProps<T>
    disabled?: boolean
    className?: string
}

/** Общий интерфейс для создания колонки действий у реестров */
export interface TableActionsCommonProps<T> {
    tableRow: T
}

/** Partial с учетом вложенности */
export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>
}

/** Сделать одно или несколько свойств необязательными */
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

/** Тип колбэка функции Array.filter */
export type ArrayFilterFn = (el: any) => boolean

export type TextAlignProperty =
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start'

export interface FocusedElementProps {
    name: string
    currentTarget: HTMLInputElement | HTMLTextAreaElement
}

export enum LdeEditorInitialMode {
    View = 'VIEW',
    Clear = 'CLEAR',
    Review = 'REVIEW',
    Edit = 'EDIT',
}

export enum LdeEditorRoles {
    Tutor = 'OU_TUTOR',
    Listener = 'OU_LISTENER',
}

export type ReactTextNullable = ReactText | null

export type IndentType =
    | '0'
    | '10'
    | '15'
    | '20'
    | '25'
    | '30'
    | '35'
    | '40'
    | '45'
    | '50'
    | '75'
    | '100'
    | '125'
    | '150'
