import cn from 'classnames'
import React, { useCallback, useEffect } from 'react'
import { useElementVisible } from 'hooks'

import styles from './UserPersonalAreaNavigation.module.scss'
import { PERSONAL_AREA_NAVIGATION } from './UserPersonalAreaNavigation.config'

/**
 * Личный кабинет пользователя: навигация
 */
export const UserPersonalAreaNavigation: React.FC = React.memo(() => {
    const {
        elementVisible,
        updateElementVisible,
        elementRef,
    } = useElementVisible()

    const handleCollapseMenu = useCallback(
        (event: Event) => {
            if (!elementRef.current) return

            const isElementContainsTarget = elementRef.current.contains(
                event.target as Node
            )

            if (isElementContainsTarget) {
                updateElementVisible(true)
            } else {
                updateElementVisible(false)
            }
        },
        [elementRef, updateElementVisible]
    )

    useEffect(() => {
        document.addEventListener('mousemove', handleCollapseMenu)

        return () => {
            document.removeEventListener('mousemove', handleCollapseMenu)
        }
    }, [updateElementVisible, handleCollapseMenu])

    return (
        <aside
            className={cn(styles.wrapper, elementVisible && styles.collapsed)}
            ref={elementRef}
        >
            {PERSONAL_AREA_NAVIGATION}
        </aside>
    )
})
