import moment from 'moment-timezone'
import {
    AppointmentTrackContract,
    AppointmentUserContract,
    TrackAppointmentType,
    TrackType,
} from 'core/api'
import {
    getDayWithTime,
    getFullFio,
    getItemId,
    isArrayEmpty,
    isTrackTypeAdaptation,
} from 'utils'

import {
    ADAPTATION_TRACK_DEPENDENCY_FIELDS,
    MIN_DAYS_TO_NOTIFICATION,
    TRACKS_SEARCH_OPTIONS,
} from './TrackAssignmentForm.consts'
import { TrackAssignmentFormSubmitValues } from './TrackAssignmentForm.types'

/**
 * Получить значения для отправки на бэк
 * @param values - текущие значения формы
 * @param id - идентификатор назначения
 */
export const mapFormDataToRequest = (
    {
        adaptationTrackId,
        developmentTrackId,
        respondents,
        period,
        stageSettings,
        checkingPersons,
        tutors,
        daysToStartNotification,
        daysToEndNotification,
        homeworkAutoCheckDate,
        ...restValues
    }: Partial<TrackAssignmentFormSubmitValues>,
    id?: number
) => ({
    adaptationTrackId: adaptationTrackId?.[0].value,
    developmentTrackId: developmentTrackId?.[0].value,
    respondents,
    tutorIds: tutors?.map(getItemId),
    checkingPersonsIds: checkingPersons?.map(getItemId),
    periodFrom: period?.[0],
    periodTo: period?.[1],
    stageSettings: isArrayEmpty(stageSettings)
        ? []
        : stageSettings?.map(({ stageNumber, ...restSetting }, index) => ({
              ...restSetting,
              stageNumber: stageNumber || index + 1,
          })),
    isTrackAttemptCountNotLimited:
        !isTrackTypeAdaptation(restValues.trackType) || undefined,
    ...restValues,
    id,
    daysToEndNotification: daysToEndNotification || MIN_DAYS_TO_NOTIFICATION,
    daysToStartNotification:
        daysToStartNotification || MIN_DAYS_TO_NOTIFICATION,
    homeworkAutoCheckDate: homeworkAutoCheckDate ?? getDayWithTime(moment()),
})

/** нормализация данных для AddUsersTableControl*/
const normalizeValuesForTableControl = ({
    id,
    position,
    organization,
    ...restValue
}: AppointmentUserContract) => ({
    id,
    userId: id,
    name: getFullFio(restValue),
    positions: position?.name,
    organizations: organization?.name,
})

/**
 * Получить начальные значения для формы назначения
 * @param initialValues - начальные значения
 */
export const mapResponseToFormData = ({
    adaptationTrack,
    stageSettings,
    developmentTrack,
    mguuTrack,
    respondents,
    checkingPersons,
    tutors,
    ...rest
}: AppointmentTrackContract) => ({
    ...rest,
    period: [rest.periodFrom, rest.periodTo],
    adaptationTrackId: adaptationTrack && [
        {
            label: adaptationTrack.name,
            key: String(adaptationTrack.id),
            value: adaptationTrack.id,
        },
    ],
    developmentTrackId: developmentTrack && [
        {
            label: developmentTrack.name,
            key: String(developmentTrack.id),
            value: developmentTrack.id,
        },
    ],
    mguuTrackId: mguuTrack?.id,
    stageSettings,
    respondents: respondents?.map(normalizeValuesForTableControl),
    tutors: tutors?.map(normalizeValuesForTableControl),
    checkingPersons: checkingPersons?.map(normalizeValuesForTableControl),
})

export const isTrackAppointmentTypeByStages = (type?: TrackAppointmentType) =>
    type === TrackAppointmentType.ByStages

export const shouldFetchTrackAdaptation = (
    values: Partial<TrackAssignmentFormSubmitValues>,
    changedValueKey: string
) =>
    ADAPTATION_TRACK_DEPENDENCY_FIELDS.includes(changedValueKey) &&
    isTrackAppointmentTypeByStages(values.appointmentType) &&
    (values.adaptationTrackId || values.developmentTrackId)

export const shouldRemoveTrackStagesSettings = (
    values: TrackAssignmentFormSubmitValues
) =>
    !isTrackAppointmentTypeByStages(values.appointmentType) &&
    values.stageSettings?.length

export const shouldRemoveTrackCheckingPersons = (
    changedValueKey: string,
    values: TrackAssignmentFormSubmitValues
) => changedValueKey === 'trackType' && isTrackTypeAdaptation(values.trackType)

/**
 * Возвращает объект с настройками формы поиска треков
 * @param trackType тип треков
 */
export const getTracksSearchOptions = (trackType: TrackType) =>
    TRACKS_SEARCH_OPTIONS[trackType as keyof typeof TRACKS_SEARCH_OPTIONS]
