import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import { Switch } from 'antd'
import { isFormModeView } from 'utils'

import styles from './SwitchControl.module.scss'
import { SwitchControlProps } from './SwitchControl.types'

/**
 * Декоратор для `Switch` от `antd`, принимает все теже `props`
 */
export const SwitchControl: React.FC<SwitchControlProps> = React.memo(
    ({
        disabled,
        formMode,
        className,
        onChange,
        value,
        onBeforeChange,
        addonAfter,
        addonBefore,
        ...props
    }) => {
        const [valueState, setValueState] = useState<boolean>()

        const triggerChanges = useCallback(
            (checked: boolean) => {
                setValueState(checked)

                onChange?.(checked)
            },
            [onChange]
        )

        const onChangeCheckbox = useCallback(
            (checked: boolean) => {
                if (onBeforeChange) {
                    onBeforeChange({
                        onChange: () => triggerChanges(checked),
                        value: checked,
                    })
                } else {
                    triggerChanges(checked)
                }
            },
            [onBeforeChange, triggerChanges]
        )

        const classes = cn(styles.wrapper, 'form-control', className)

        return (
            <div className={classes}>
                {addonBefore && <span>{addonBefore}</span>}

                <Switch
                    onChange={onChangeCheckbox}
                    checked={value || valueState}
                    disabled={disabled || isFormModeView(formMode)}
                    {...props}
                />

                {addonAfter && <span>{addonAfter}</span>}
            </div>
        )
    }
)
