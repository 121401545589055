import React, { useCallback } from 'react'
import { Form } from 'antd'
import { FormItemAdapter, HiddenField, InputControl } from 'components/controls'
import { GlossariesService } from 'core/api'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { withLoader } from 'HOCs'

/** Форма создания нового раздела */
export const KnowledgeBaseSectionForm: React.FC<PopupAdapterFormProps> = withLoader(
    React.memo(({ onRequestFinish, id, updateLoader, initialValues }) => {
        const handleFinish = useCallback(
            async (body) => {
                try {
                    updateLoader(true)

                    await GlossariesService[
                        body.id ? 'updateSection' : 'createSection'
                    ]({
                        body,
                    })
                    onRequestFinish?.()
                } catch (e) {
                    console.error(e)
                } finally {
                    updateLoader(false)
                }
            },
            [onRequestFinish, updateLoader]
        )

        return (
            <Form onFinish={handleFinish} id={id} initialValues={initialValues}>
                <HiddenField fieldName="id" />

                <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                    <InputControl />
                </FormItemAdapter>
            </Form>
        )
    })
)
