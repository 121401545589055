import React from 'react'
import { Col, Row } from 'antd'
import { ComponentCommonProps } from 'App.types'
import { ROW_GUTTER } from 'consts'

import { AddAssessmentProjectsPortfolios } from '../AddAssessmentProjectsPortfolios'
import { AssessmentProjectsPortfoliosList } from '../AssessmentProjectsPortfoliosList'

/** Компонент формы оценочного проекта для типа оценки 360*/
export const AssessmentProjectsEstimate360: React.FC<ComponentCommonProps> = React.memo(
    ({ form, formMode }) => (
        <Row gutter={ROW_GUTTER}>
            <Col xs={24}>
                <AddAssessmentProjectsPortfolios
                    form={form}
                    formMode={formMode}
                />

                <AssessmentProjectsPortfoliosList formMode={formMode} />
            </Col>
        </Row>
    )
)
