import React from 'react'
import cn from 'classnames'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { TextAlignProperty } from 'App.types'
import { getBackgroundImageStyle, getStyles } from 'utils'

import styles from './FullScreenCanvas.module.scss'
import { ActivityChildProps } from '../../ActivityLayer.types'
import { FullScreenBlocks } from '../FullScreenBlocks'
import { getPassButtonText } from '../../ActivityLayer.utils'
import { useCanvas } from '../../ActivityLayer.hooks'

export const FullScreenCanvas: React.FC<ActivityChildProps> = React.memo(
    ({ activity }) => {
        const {
            activeLesson,
            isThematicBlockLock,
            isThematicBlockCompletedAndNotAvailableForRepassing,
            showFurtherButton,
            showReturnButton,
            returnToPrevLayer,
            showFinishActivityModal,
            startLesson,
        } = useCanvas({ activity })

        const buttonStyles = {
            background: activity?.canvas?.buttonColor,
            color: activity?.canvas?.buttonTextColor,
            borderColor: activity?.canvas?.buttonColor,
        }

        return (
            <div className={styles.wrapper}>
                <div
                    style={getBackgroundImageStyle(
                        activity?.canvas?.canvasBackground?.url
                    )}
                    className={styles.body}
                >
                    <div>
                        {showReturnButton && (
                            <Button
                                type="link"
                                onClick={returnToPrevLayer}
                                className={styles.returnButton}
                            >
                                <IconsAdapter iconType="CustomIconArrow" />
                            </Button>
                        )}

                        <h1
                            className={styles.eventTitle}
                            style={getStyles(activity?.canvas?.titleSettings)}
                        >
                            {activity?.name}
                        </h1>

                        <pre
                            className={styles.eventDescription}
                            style={getStyles(
                                activity?.canvas?.descriptionSettings
                            )}
                        >
                            {activity?.announcement}
                        </pre>
                    </div>

                    <h3
                        className={styles.blockName}
                        style={getStyles(activity?.canvas?.blockNameSettings)}
                    >
                        {activeLesson?.name}
                    </h3>

                    <div
                        style={{
                            textAlign: activity?.canvas
                                ?.buttonAlign as TextAlignProperty,
                        }}
                        className={styles.nav}
                    >
                        {!isThematicBlockCompletedAndNotAvailableForRepassing &&
                            !isThematicBlockLock && (
                                <Button
                                    style={buttonStyles}
                                    onClick={startLesson}
                                    className={cn(
                                        styles.startButton,
                                        'capitalize-text'
                                    )}
                                >
                                    {getPassButtonText(
                                        activeLesson,
                                        activity?.status
                                    )}
                                </Button>
                            )}

                        {showFurtherButton && (
                            <Button
                                style={buttonStyles}
                                onClick={showFinishActivityModal}
                                className={cn(
                                    styles.nextButton,
                                    'capitalize-text'
                                )}
                            >
                                {LOCAL.ACTIONS.FURTHER}
                            </Button>
                        )}
                    </div>

                    <div className={styles.blocks}>
                        <FullScreenBlocks activity={activity} />
                    </div>
                </div>
            </div>
        )
    }
)
