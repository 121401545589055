import React, { useCallback } from 'react'
import {
    APPOINTMENT_STATUS_WITHOUT_CHECKED_MAPPED,
    DEPRECATED_ASSESSMENT_TYPES,
    ROW_GUTTER,
} from 'consts'
import {
    AppointmentSearchContract,
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    CoworkersFilters,
    FormItemAdapter,
    InputControl,
    SearchSelectControl,
    SelectControl,
    getCoworkersTableColumns,
    getUserByIdsMethod,
} from 'components/controls'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import {
    FiltersProps,
    excludeSelectOptions,
    getValue,
    normalizeDictionaryToControl,
} from 'utils'
import { LOCAL } from 'core/local'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionariesHelper } from 'hooks'

import { ASSESSMENT_FILTERS_INITIAL_VALUES } from './AssessmentAssignmentsFilters.consts'
import { AssignmentsFiltersSubmitValues } from './AssessmentAssignmentsFilters.types'

/**
 * Форма фильтрации назначений
 */
export const AssessmentAssignmentsFilters: React.FC<FiltersProps<
    AppointmentSearchContract
>> = React.memo((props) => {
    const { assessmentTypeDictionary } = useDictionariesHelper([
        'assessmentTypeDictionary',
    ])

    const mapFormDataToRequest = useCallback(
        (values: Partial<AssignmentsFiltersSubmitValues>) => ({
            ...values,
            appointmentAssessmentSearch: {
                type: values.assessmentType,
                requestNumber: values.requestNumber,
                candidates: values.candidates?.map(getValue),
            },
        }),
        []
    )

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                tableFiltersOptions={{
                    initialValues: ASSESSMENT_FILTERS_INITIAL_VALUES,
                }}
                renderFooter={renderFooterWithShowArchiveField}
                mapFormDataToRequest={mapFormDataToRequest}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changeDateFrom',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changeDateTo',
                            }}
                        />
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="assessmentType"
                            label={LOCAL.LABELS.ASSESSMENT_TYPE}
                        >
                            <SelectControl
                                values={excludeSelectOptions(
                                    normalizeDictionaryToControl(
                                        assessmentTypeDictionary
                                    ),
                                    DEPRECATED_ASSESSMENT_TYPES
                                )}
                                showSearch
                                allowClear
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="requestNumber"
                            label={LOCAL.LABELS.REQUEST_NUMBER}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_START,
                                fieldName: 'evaluationPeriodFrom',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_END,
                                fieldName: 'evaluationPeriodTo',
                            }}
                        />
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    APPOINTMENT_STATUS_WITHOUT_CHECKED_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="candidates"
                            label={LOCAL.LABELS.ASSESSMENT_OBJECT}
                        >
                            <SearchSelectControl<
                                UserModalSearchContract,
                                UserModalContract
                            >
                                getByIdsMethod={getUserByIdsMethod}
                                selectMode="multiple"
                                modalTitle={
                                    LOCAL.LABELS.ASSESSMENT_OBJECT_SELECTION
                                }
                                tableSearchOptions={{
                                    searchMethod: UsersService.getForModal,
                                    tableColumns: getCoworkersTableColumns(),
                                    filterComponent: CoworkersFilters,
                                }}
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
