import { LOCAL } from 'core/local'
import { NotificationNewsletterTemplatesService } from 'core/api'
import { createConfirmPopup } from 'utils'

import {
    ChangeNotificationThemeProps,
    SetMailFieldsProps,
} from './DistributionMailingMail.types'

export const setMailFields = ({
    id,
    notificationTheme,
    notificationBody,
    setTemplateId,
    form,
}: SetMailFieldsProps) => {
    setTemplateId(id)

    form?.setFieldsValue({
        notificationTheme,
        notificationMailBody: notificationBody,
    })
}

export const onChangeNotificationTheme = async ({
    id,
    setTemplateId,
    form,
    prevId,
    initialValuesForEdit,
}: ChangeNotificationThemeProps) => {
    const response = await NotificationNewsletterTemplatesService.getActive({
        id,
    })

    const { notificationTheme, notificationBody } = form?.getFieldsValue([
        'notificationTheme',
        'notificationBody',
    ])

    const checkInitialTemplate = () => {
        if (initialValuesForEdit) {
            return (
                initialValuesForEdit.notificationTheme !== notificationTheme &&
                initialValuesForEdit.notificationBody !== notificationBody
            )
        }

        return true
    }

    if (!!notificationTheme && !!notificationBody && checkInitialTemplate())
        return createConfirmPopup({
            title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
            content: `${LOCAL.MESSAGES.TEMPLATE_CHANGED} "${response.name}"?`,
            okText: LOCAL.ACTIONS.CONFIRM,
            onCancel: () => form?.setFieldsValue({ templateId: prevId }),
            onOk: () =>
                setMailFields({
                    id,
                    notificationTheme: response.notificationTheme,
                    notificationBody: response.notificationBody,
                    setTemplateId,
                    form,
                }),
        })

    setMailFields({
        id,
        notificationTheme: response.notificationTheme,
        notificationBody: response.notificationBody,
        setTemplateId,
        form,
    })
}
