import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { LdeReportContract } from 'core/api'
import { defaultSorter } from 'components/layouts'
import { formatDate, restrictBigText } from 'utils'

import { ReportsTableActions } from './components/ReportsTableActions'

export const REPORTS_TABLE_COLUMNS: ColumnsType<LdeReportContract> = [
    {
        title: LOCAL.LABELS.DOCUMENT_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.OWNER_NAME,
        dataIndex: 'author',
        key: 'author',
        sorter: defaultSorter,
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.DATE_CREATION,
        dataIndex: 'creationDate',
        key: 'creationDate',
        render: formatDate,
        sorter: defaultSorter,
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => <ReportsTableActions tableRow={tableRow} />,
        fixed: 'right',
    },
]
