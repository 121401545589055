import React from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, IconsAdapter, Popup } from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { MODAL_WIDTH } from 'consts'
import { useElementVisible } from 'hooks'

import { QuizCheckingActionsProps } from './QuizCheckingActions.types'
import { QuizCheckingForm } from '../QuizCheckingForm'

/**
 * Содержимое столбца "Actions" в таблице проверки опросов
 */
export const QuizCheckingActions: React.FC<QuizCheckingActionsProps> = React.memo(
    ({ tableRow, onRefetch }) => {
        const {
            elementVisible,
            toggleElementVisible,
            handleHideElement,
        } = useElementVisible()

        return (
            <>
                <ButtonsToolbar bottomIndent="0">
                    <Button
                        type="link"
                        icon={<IconsAdapter iconType="EyeOutlined" />}
                        onClick={toggleElementVisible}
                    />
                </ButtonsToolbar>

                <Popup
                    visible={elementVisible}
                    formId={FORM_IDS.UPLOAD_COURSE_FORM}
                    title={tableRow.objectName}
                    width={MODAL_WIDTH.MD}
                    footer={null}
                    onCancel={handleHideElement}
                >
                    <QuizCheckingForm
                        initialValues={tableRow}
                        onRefetch={onRefetch}
                        onCancelSubmit={handleHideElement}
                    />
                </Popup>
            </>
        )
    }
)
