import React, { FC, useEffect } from 'react'
import {
    AssessmentReportTemplateSectionContract,
    AssessmentReportTemplatesService,
    AssessmentType,
} from 'core/api'
import { useHttp } from 'hooks'

import { AssessmentReportContentProps } from './AssessmentReport.types'
import { AssessmentReportGrid, ManagerAssessmentReport } from './components'

/** Отчет по оценке: содержимое */
export const AssessmentReportContent: FC<AssessmentReportContentProps> = React.memo(
    ({ updateLoader, assessmentType }) => {
        const [getTemplate, template] = useHttp<
            AssessmentReportTemplateSectionContract[]
        >(AssessmentReportTemplatesService.getByType, updateLoader)

        useEffect(() => {
            if (assessmentType) {
                getTemplate({ type: assessmentType })
            }
        }, [getTemplate, assessmentType])

        switch (assessmentType) {
            case AssessmentType.Estimate_360:
                return <AssessmentReportGrid template={template} />
            case AssessmentType.EstimateByManager:
                return <ManagerAssessmentReport template={template} />
            default:
                return null
        }
    }
)
