import React from 'react'
import lowerFirst from 'lodash/lowerFirst'
import moment from 'moment'
import template from 'lodash/template'
import {
    CONFIRM_REPASSING_TITLE,
    MODAL_WIDTH,
    QUESTIONNAIRE_TYPES,
} from 'consts'
import { LOCAL } from 'core/local'
import { Modal } from 'antd'
import {
    PassingFormQuestionnaireResultContract,
    QuestionType,
    QuestionnaireType,
} from 'core/api'
import { TableAdapter, TooltipAdapter } from 'components/shared'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { secondCountToTimeString, timeStringToSecondCount } from 'utils'

import styles from './QuestionnairePassing.module.scss'
import {
    AnsweredQuestionsProps,
    RenderConfirmFinalWindowProps,
    RenderConfirmTitleProps,
} from './QuestionnairePassing.types'
import { QUESTIONNAIRE_PASSING_EVENTS } from './QuestionnairePassing.events'
import { QuestionnaireFinalWindowContent } from './components/QuestionnaireFinalWindowContent'
import { repassingColumns } from './QuestionnairePassing.config'

/**
 * Получить сообщение для окончательного завершения прохождение теста
 * @param remainingAttemptsCount количество оставшихся попыток прохождения
 * @param questionnaireResults результаты тестирования
 */
export const getMessageForRepassingConfirm = (
    remainingAttemptsCount: number,
    type: QuestionnaireType,
    questionnaireResults?: PassingFormQuestionnaireResultContract[]
) => {
    let modalText = LOCAL.MESSAGES.YOU_HAVE_USED_LAST_PASSING_ATTEMPT

    if (remainingAttemptsCount > 0) {
        const attemptsCount = template(
            LOCAL.MESSAGES.YOU_HAVE_LEFT_AMOUNT_OF_PASSING_ATTEMPTS
        )({
            amount:
                remainingAttemptsCount === Infinity
                    ? lowerFirst(LOCAL.LABELS.UNLIMITED_NUMBER)
                    : remainingAttemptsCount,
        })

        const wantedPassAgain = template(
            LOCAL.MESSAGES.IS_WANTED_TO_PASS_AGAIN
        )({
            type: lowerFirst(QUESTIONNAIRE_TYPES[type]),
        })

        modalText = `${attemptsCount}. ${wantedPassAgain}`
    }

    return (
        <>
            {questionnaireResults?.length ? (
                <TableAdapter
                    className={styles.table}
                    dataSource={questionnaireResults}
                    columns={repassingColumns}
                    pagination={false}
                    rowKey="questionnaireId"
                />
            ) : null}

            <div className={styles.message}>{modalText}</div>
        </>
    )
}

export const renderConfirmTitle = ({
    startedTime,
    timeLimit,
    timeIsUp,
    type,
}: RenderConfirmTitleProps) => (
    <>
        <div className={styles.title}>{CONFIRM_REPASSING_TITLE[type]}</div>

        {timeIsUp ? (
            <div className={styles.time}>
                {`${LOCAL.MESSAGES.TIME_IS_UP_TEST_PASSING_MESSAGE} `}
                <TooltipAdapter title={LOCAL.HHMMSS} className={styles.timeTip}>
                    <span className={styles.timeCount}>
                        {secondCountToTimeString(timeLimit)}
                    </span>
                </TooltipAdapter>
                {` ${LOCAL.LABELS.EXPIRE.toLowerCase()}`}
            </div>
        ) : (
            <div className={styles.time}>
                {`${LOCAL.LABELS.TIME_SPENT}: `}

                <span>
                    {secondCountToTimeString(
                        timeStringToSecondCount(moment().diff(startedTime))
                    )}
                </span>
            </div>
        )}
    </>
)

const getAnsweredQuestions = ({
    answers,
    questions,
}: Partial<RenderConfirmFinalWindowProps>) =>
    answers?.map((answer) => {
        const question = questions?.find((el) => el.id === answer.questionId)

        const answeredQuestion: AnsweredQuestionsProps = {
            name: question?.text,
            number: question?.number,
        }

        switch (question?.type) {
            case QuestionType.ChooseVariant:
                const selectedAnswersIds =
                    answer?.chooseAnswerParameters?.selectedAnswerIds

                const answersInfo = question?.chooseVariant.answers
                const answerText = answer?.chooseAnswerParameters?.text
                const selectedAnswers = selectedAnswersIds?.map(
                    (item) =>
                        answersInfo?.find(
                            (answerInfo) => answerInfo.id === item
                        )?.text || ''
                )
                answeredQuestion.answer = answerText
                    ? [...selectedAnswers, answerText]
                    : selectedAnswers

                break

            case QuestionType.CriteriaScale:
                const scaleAnswer = question?.criteriaScale?.scale?.scaleValues.find(
                    (item) =>
                        item.id === answer.scaleAnswerParameters.scaleValueId
                )
                answeredQuestion.answer = scaleAnswer?.description
                break

            case QuestionType.TextQuestion:
                answeredQuestion.answer = answer.textAnswerParameters.text
                break
        }

        return answeredQuestion
    })

export const renderConfirmFinalWindow = ({
    answers,
    questions,
}: RenderConfirmFinalWindowProps) => {
    const answeredQuestions = getAnsweredQuestions({ answers, questions })

    Modal.confirm({
        okText: LOCAL.ACTIONS.COMPLETE,
        cancelText: LOCAL.ACTIONS.RETURN,
        title: LOCAL.LABELS.QUESTIONNAIRE_FINAL_WINDOW,
        content: (
            <QuestionnaireFinalWindowContent
                answeredQuestions={answeredQuestions}
            />
        ),
        onOk: () => {
            Modal.destroyAll()
            eventEmitter.emit(QUESTIONNAIRE_PASSING_EVENTS.CONFIRM)
        },
        onCancel: () => {
            Modal.destroyAll()
        },
        icon: undefined,
        width: MODAL_WIDTH.MD,
    })
}
