import {
    AppointmentStatus,
    AppointmentTrackStatus,
    AppointmentType,
} from 'core/api'

type AppointmentStatusesProps = AppointmentStatus | AppointmentTrackStatus

/**
 * Проверка статуса назначения
 * @param status - статус назначения
 */
export const isAppointmentStatusExpectation = (
    status?: AppointmentStatusesProps
) => status === AppointmentStatus.Expectation

export const isAppointmentStatusInProgress = (
    status?: AppointmentStatusesProps
) => status === AppointmentStatus.InProgress

export const isAppointmentStatusDone = (status?: AppointmentStatusesProps) =>
    status === AppointmentStatus.Done

export const isAppointmentStatusChecked = (status?: AppointmentStatusesProps) =>
    status === AppointmentStatus.Checked

export const isAppointmentStatusDraft = (status?: AppointmentStatusesProps) =>
    status === AppointmentStatus.Draft

export const isAppointmentStatusEnded = (status?: AppointmentStatusesProps) =>
    status === AppointmentStatus.Ended

export const isAppointmentStatusCanceled = (
    status?: AppointmentStatusesProps
) => status === AppointmentStatus.Canceled

export const isAppointmentStatusSuspended = (
    status?: AppointmentStatusesProps
) => status === AppointmentStatus.Suspended

/**
 * Проверка на доступность для показа
 * @param status - статус назначения
 */
export const isAvailableToWatch = (status?: AppointmentStatusesProps) =>
    !isAppointmentStatusDraft(status) &&
    !isAppointmentStatusEnded(status) &&
    !isAppointmentStatusCanceled(status)

/**
 * Проверка типа назначения
 * @param type - тип назначения
 */
export const isAppointmentTypeActivity = (type?: AppointmentType) =>
    type === AppointmentType.Activity

export const isAppointmentStatusEditable = (status?: AppointmentStatus) =>
    isAppointmentStatusDraft(status) ||
    isAppointmentStatusExpectation(status) ||
    isAppointmentStatusInProgress(status) ||
    isAppointmentStatusSuspended(status)
