import React, { useCallback, useState } from 'react'
import { AppError, AttachmentContract, AttachmentsService } from 'core/api'
import { Button } from 'components/shared/Button'
import { Col, Form, Row, Upload } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Popup } from 'components/shared/Popup'
import { RcCustomRequestOptions } from 'types'
import { TEXT_DOCUMENT_TYPE } from 'consts'
import { UploadFile } from 'antd/lib/upload/interface'
import {
    beforeUploadAttachment,
    getFormattedBytes,
    getStringWithoutDots,
    isFormModeView,
} from 'utils'
import { useElementVisible } from 'hooks'

import styles from './UploadAttachments.module.scss'
import { ShouldUpdateChecker } from '../ShouldUpdateChecker'
import { UploadAttachmentsProps } from './UploadAttachments.types'
import { validateUploadFile } from './UploadAttachments.utils'

/** Контрол добавления и удаления файла для формы, принимает инстанс формы */
export const UploadAttachments: React.FC<UploadAttachmentsProps> = ({
    form,
    formMode,
    name = 'attachments',
    documentType = TEXT_DOCUMENT_TYPE,
    maxCount = 3,
    deleteButtonProps = { title: LOCAL.ACTIONS.DELETE_FILE },
    overallFilesMaxSize,
    customUploadHandler,
    disabled,
    updateLoader,
}) => {
    const [selectedAttachment, setSelectedAttachment] = useState<
        AttachmentContract
    >()
    const {
        elementVisible,
        handleHideElement,
        handleShowElement,
    } = useElementVisible()

    /**
     * Обработчик добавления файла
     */
    const handleUploadAttachment = useCallback(
        async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
            try {
                updateLoader?.(true)
                const upload = await AttachmentsService.upload({
                    file,
                })

                onSuccess?.(upload, {} as XMLHttpRequest)
            } catch (error) {
                onError?.(error as AppError)
            } finally {
                updateLoader?.(false)
            }
        },
        [updateLoader]
    )

    /**
     * Обработчик удаления файла
     */
    const handleDeleteAttachment = useCallback(
        (
            attachment: UploadFile,
            attachments: UploadFile<AttachmentContract>[]
        ) => () => {
            form?.setFieldsValue({
                [name]: attachments.filter(
                    (item) => item.uid !== attachment.uid
                ),
            })
        },
        [form, name]
    )

    const handleOpenConfirmDownload = useCallback(
        (attachment: UploadFile<AttachmentContract>) => () => {
            if (!attachment?.response?.id) return

            setSelectedAttachment(attachment.response)
            handleShowElement()
        },
        [setSelectedAttachment, handleShowElement]
    )

    return (
        <>
            <div className={styles.wrapper}>
                <ShouldUpdateChecker fieldPath={[name]}>
                    {({ getFieldValue }) => (
                        <Form.Item
                            noStyle
                            name={name}
                            valuePropName="fileList"
                            getValueFromEvent={validateUploadFile}
                        >
                            <Upload
                                beforeUpload={beforeUploadAttachment({
                                    documentType,
                                    maxCount,
                                    overallFilesMaxSize,
                                    fileList: getFieldValue([name]),
                                })}
                                customRequest={
                                    customUploadHandler ||
                                    handleUploadAttachment
                                }
                                showUploadList={false}
                                className={styles.upload}
                                accept={documentType}
                                disabled={isFormModeView(formMode) || disabled}
                            >
                                <Button
                                    type="link"
                                    className={styles.img}
                                    disabled={isFormModeView(formMode)}
                                    title={getStringWithoutDots(documentType)}
                                >
                                    <IconsAdapter iconType="FileTextOutlined" />

                                    {LOCAL.ACTIONS.DOWNLOAD_FILE}
                                </Button>
                            </Upload>
                        </Form.Item>
                    )}
                </ShouldUpdateChecker>

                <ShouldUpdateChecker fieldPath={[name]}>
                    {({ getFieldValue }) => {
                        const attachments: UploadFile<
                            AttachmentContract
                        >[] = getFieldValue(name)

                        return (
                            <div className={styles.uploadList}>
                                {attachments?.map((attachment) => (
                                    <Row align="top" key={attachment.uid}>
                                        <Col xs={20}>
                                            <IconsAdapter
                                                iconType="FileOutLined"
                                                className={
                                                    styles.uploadListItemIcon
                                                }
                                                onClick={handleOpenConfirmDownload(
                                                    attachment
                                                )}
                                            />

                                            <p>
                                                <span
                                                    className={
                                                        styles.uploadListItemName
                                                    }
                                                >
                                                    {attachment.name}
                                                </span>

                                                <span
                                                    className={
                                                        styles.uploadListItemSize
                                                    }
                                                >{` ( ${getFormattedBytes(
                                                    attachment.size
                                                )} )`}</span>
                                            </p>
                                        </Col>

                                        <Col xs={4}>
                                            <Button
                                                icon={
                                                    <IconsAdapter iconType="CustomIconClose" />
                                                }
                                                className={
                                                    styles.uploadListItemDelete
                                                }
                                                onClick={handleDeleteAttachment(
                                                    attachment,
                                                    attachments
                                                )}
                                                disabled={
                                                    isFormModeView(formMode) ||
                                                    disabled
                                                }
                                                type="text"
                                                title={deleteButtonProps.title}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        )
                    }}
                </ShouldUpdateChecker>
            </div>

            <Popup
                title={selectedAttachment?.fileName}
                visible={elementVisible}
                onCancel={handleHideElement}
                okText={
                    <a
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        href={selectedAttachment?.url}
                        onClick={handleHideElement}
                    >
                        {LOCAL.YES}
                    </a>
                }
            >
                <p className={styles.message}>
                    {LOCAL.MESSAGES.VIEW_UPLOADED_ATTACHMENT}
                </p>
            </Popup>
        </>
    )
}
