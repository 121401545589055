import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { ScaleValueContract } from 'core/api'

/** настройка отображения колонок таблицы шкалы */
export const CRITERIA_SCALE_TABLE_COLUMNS: ColumnsType<ScaleValueContract> = [
    {
        title: LOCAL.LABELS.VALUES,
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: LOCAL.LABELS.BALL,
        dataIndex: 'displayValue',
        key: 'displayValue',
    },
]
