import React from 'react'
import {
    COMPLIANCE_POSITION_PROFILE,
    REPORT_COMPLIANCE_COLORS,
    REPORT_EXCEEDING_POSITION_PROFILE_COLORS,
} from 'consts'
import { LOCAL } from 'core/local'

import styles from './ComplianceProfilePositionBlock.module.scss'
import { ComplianceProfilePositionBlockProps } from './ComplianceProfilePositionBlock.types'
import { ReportViewProgress } from '../ReportView/ReportViewProgress'
import { SIGNIFICANT_PERCENT } from './ComplianceProfilePositionBlock.config'

/** Отчёт по оценке руководителя. Блок соответствия результатов сотрудника профилю должности */
export const ComplianceProfilePositionBlock: React.FC<ComplianceProfilePositionBlockProps> = React.memo(
    ({ title, compliance, exceedingPositionProfile, result }) => (
        <div className={styles.wrapper}>
            <h2>{title}</h2>

            <div className={styles.grid}>
                <div>{`${LOCAL.LABELS.POSITION_PROFILE_MATCH} (%)`}</div>

                <div>{`${LOCAL.LABELS.EXCEEDING_PROFILE_POSITION} (%)`}</div>

                <ReportViewProgress
                    value={compliance}
                    displayValue={`${compliance}%`}
                    color={REPORT_COMPLIANCE_COLORS[result]}
                />

                <ReportViewProgress
                    value={exceedingPositionProfile}
                    displayValue={`${exceedingPositionProfile}%`}
                    color={
                        exceedingPositionProfile >= SIGNIFICANT_PERCENT
                            ? REPORT_EXCEEDING_POSITION_PROFILE_COLORS.SIGNIFICANT
                            : REPORT_EXCEEDING_POSITION_PROFILE_COLORS.INSIGNIFICANT
                    }
                    alwaysFull
                />
            </div>

            {COMPLIANCE_POSITION_PROFILE[result] ||
                LOCAL.LABELS.INSUFFICIENT_DATA}
        </div>
    )
)
