import { Store } from 'App.types'

export enum Resolutions {
    XS = '0',
    SM = '576',
    MD = '768',
    LG = '992',
    XL = '1200',
    XXL = '1600',
}

export const RESOLUTION_RELATIONS: Store<string> = {
    [Resolutions.XS]: '< 576px',
    [Resolutions.SM]: '≥ 576px',
    [Resolutions.MD]: '≥ 768px',
    [Resolutions.LG]: '≥ 992px',
    [Resolutions.XL]: '≥ 1200px',
    [Resolutions.XXL]: '≥ 1600px',
}
