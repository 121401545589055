import cn from 'classnames'
import React, { useContext } from 'react'
import { Divider, Space } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { useElementVisible } from 'hooks'

import styles from './TrackProgress.module.scss'
import { TrackPassingContext } from '../../TrackPassing.context'
import { getProgressBlock, getStatisticRow } from './TrackProgress.render'

export const TrackProgress: React.FC = React.memo(() => {
    const { store } = useContext(TrackPassingContext)

    const { trackInfo, progress } = store
    const options = trackInfo?.trackProgressVisualization

    const {
        elementVisible: progressVisible,
        toggleElementVisible: toggleProgressVisible,
    } = useElementVisible()

    const {
        elementVisible: openRating,
        toggleElementVisible: handleOpenRating,
    } = useElementVisible()

    const textStyle = {
        color: options?.progressTextSettings.textColor,
        fontFamily: options?.progressTextSettings.fontName,
    }

    return (
        <div
            className={cn(
                styles.wrapper,
                !progressVisible && styles.wrapperHidden
            )}
        >
            <Space
                className={styles.progress}
                size={0}
                style={{
                    background: options?.backgroundColor,
                }}
            >
                <div
                    className={cn(
                        styles.info,
                        !progressVisible && styles.pointer
                    )}
                    onClick={toggleProgressVisible}
                >
                    <IconsAdapter iconType="CustomIconRocket" />

                    <div
                        className={cn(styles.title, styles.titleProgressText)}
                        style={textStyle}
                    >
                        {LOCAL.LABELS.PROGRESS}
                    </div>
                </div>

                {options?.isLessonsVisible && (
                    <>
                        <Divider type="vertical" />

                        {getProgressBlock(
                            LOCAL.LABELS.PASSING_BLOCKS,
                            textStyle,
                            progress?.lessonsPassed
                        )}
                    </>
                )}

                {options?.isHomeworksVisible && (
                    <>
                        <Divider type="vertical" />

                        {getProgressBlock(
                            LOCAL.LABELS.COMPLETING_TASKS,
                            textStyle,
                            progress?.homeworksPassed
                        )}
                    </>
                )}

                {options?.isWebinarsVisible && (
                    <>
                        <Divider type="vertical" />

                        {getProgressBlock(
                            LOCAL.LABELS.WEBINARS,
                            textStyle,
                            progress?.webinarsPassed
                        )}
                    </>
                )}

                {options?.isRatingVisible && (
                    <>
                        <Divider type="vertical" />

                        <div
                            className={cn(
                                styles.info,
                                styles.pointer,
                                openRating && styles.infoOpen
                            )}
                            style={textStyle}
                            onClick={handleOpenRating}
                        >
                            <div className={styles.count}>
                                {progress?.ratingPlace}
                            </div>

                            <div className={styles.title}>
                                {LOCAL.LABELS.YOU_RATING}
                            </div>
                        </div>
                    </>
                )}
            </Space>

            {openRating && (
                <div className={styles.rating}>
                    {getStatisticRow(
                        LOCAL.LABELS.PLACE_IN_RATING,
                        progress?.ratingPlace,
                        progress?.respondentsCount
                    )}

                    {getStatisticRow(
                        LOCAL.LABELS.YOUR_SCORE,
                        progress?.currentPoints,
                        progress?.totalPoints
                    )}
                </div>
            )}
        </div>
    )
})
