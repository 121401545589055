import React, { FC, memo } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import styles from './PassingIconBlock.module.scss'
import { PassingIconBlockProps } from './PassingIconBlock.types'

/** Блок с иконкой и текстом для прохождения */
export const PassingIconBlock: FC<PassingIconBlockProps> = memo(
    ({ iconType, content }) => (
        <div className={styles.wrapper}>
            <IconsAdapter iconType={iconType} />

            <div className={styles.content}>{content}</div>
        </div>
    )
)
