import shortid from 'shortid'
import React, {
    ClipboardEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { AddButton } from 'components/shared/AddButton'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import styles from './TextRedactor.module.scss'
import { TAG_VALUE_ATTRIBUTE } from './TextRedactor.consts'
import { TextRedactorProps } from './TextRedactor.types'
import {
    getQuestionTextForEdit,
    getTagsFromEditor,
    insertNodeOverSelection,
    recalculateAnswersNumbers,
} from './TextRedactor.utils'

/**
 * Текстовый редактор для блока вопросов - "Вставить пропущенный текст", форма создания опросного
 * листа
 */
export const TextRedactor: React.FC<TextRedactorProps> = React.memo(
    ({ value, onChange, onChangeCustom, formMode, disabled }) => {
        /**
         * Ссылка на элемент div - текстовое поле
         */
        const redactor = useRef<HTMLDivElement>(null)

        /**
         * Состояние текстового поля
         */
        const [btnAddDisabled, setBtnAddDisabled] = useState(false)

        /**
         * Обработчик изменения текстового поля
         */
        const handleEditorChange = useCallback(() => {
            const redactorField = redactor.current

            if (!redactorField) return

            onChange?.(redactorField.innerHTML)
            onChangeCustom?.(redactorField)

            setBtnAddDisabled(false)
        }, [onChange, onChangeCustom])

        /**
         * Добавить тег в текстовое поле
         */
        const addLabel = () => {
            if (!redactor.current) return

            setBtnAddDisabled(true)
            redactor.current.focus()

            const currentLabelsIds = getTagsFromEditor(redactor.current)
            const counter = String(currentLabelsIds.length + 1)

            const tag = document.createElement('input')
            tag.classList.add(styles.tag)
            tag.setAttribute('id', shortid.generate())
            tag.setAttribute('readonly', 'readonly')
            tag.setAttribute(TAG_VALUE_ATTRIBUTE, counter)
            tag.value = counter

            insertNodeOverSelection(tag, redactor.current)
            handleEditorChange()
        }

        /**
         * Удалить тег из текстового поля
         */
        const deleteLabel = useCallback((id: string) => {
            const el = document.getElementById(id)
            el?.parentNode?.removeChild(el)
        }, [])

        /**
         * Обработчик удаления тега из текстового поля
         */
        const handleDeleteLabel = useCallback(
            (e) => {
                if (disabled || isFormModeView(formMode)) return

                const id = e?.target?.id
                const redactorField = redactor.current

                if (!id || !redactorField) return

                const currentLabelsIds = getTagsFromEditor(redactorField)
                const indexOfDeletedElem = currentLabelsIds.findIndex(
                    (el) => el === id
                )

                deleteLabel(id)

                recalculateAnswersNumbers(redactorField, indexOfDeletedElem + 1)

                handleEditorChange()
            },
            [deleteLabel, disabled, formMode, handleEditorChange]
        )

        /**
         * Обработчик вставки текста из буфера
         */
        const handlePasteText: ClipboardEventHandler<HTMLDivElement> = (e) => {
            e.preventDefault()
            const text = e.clipboardData.getData('text/plain')
            document.execCommand('insertText', false, text)
        }

        /**
         * Подписка/отписка на события клика по тегу текстового поля
         */
        useEffect(() => {
            const redactorField = redactor.current

            if (!redactorField) return

            redactorField.addEventListener('click', handleDeleteLabel)

            return () =>
                redactorField.removeEventListener('click', handleDeleteLabel)
        }, [handleDeleteLabel])

        /**
         * Инициализация значения текстового поля
         */
        useEffect(() => {
            const redactorField = redactor.current

            if (!redactorField) return
            redactorField.innerHTML = getQuestionTextForEdit(value)
            handleEditorChange()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return (
            <div className="form-control">
                <Form.Item>
                    <div
                        className={styles.textarea}
                        ref={redactor}
                        contentEditable={!disabled && !isFormModeView(formMode)}
                        onInput={handleEditorChange}
                        onPaste={handlePasteText}
                    />
                </Form.Item>

                <ButtonsToolbar className={styles.toolbar}>
                    <AddButton
                        disabled={
                            disabled ||
                            isFormModeView(formMode) ||
                            btnAddDisabled
                        }
                        onClick={addLabel}
                        buttonText={LOCAL.ACTIONS.ADD_FIELD}
                    />
                </ButtonsToolbar>
            </div>
        )
    }
)
