import React from 'react'
import { formatDate } from 'utils'

import styles from './CommonStatsDate.module.scss'
import { CommonStatsDateProps } from './CommonStatsDate.types'

/** общая статистика даты проведения теста */
export const CommonStatsDate: React.FC<CommonStatsDateProps> = React.memo(
    ({ dates }) => (
        <div className={styles.wrapper}>
            {dates?.map((el) => (
                <div className={styles.row} key={el.label}>
                    <div className={styles.label}>{el.label}</div>

                    <div className={styles.value}>{formatDate(el.value)}</div>
                </div>
            ))}
        </div>
    )
)
