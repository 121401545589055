import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { TracksMguuSearchResultContract } from 'core/api'
import { defaultSorter } from 'components/layouts'

import { TracksUniqueTableActions } from './components/TracksUniqueTableActions'

/** Настройки отображения колонок для таблицы реестра треков МГУУ */
export const TRACKS_TABLE_COLUMNS: ColumnsType<TracksMguuSearchResultContract> = [
    {
        title: LOCAL.LABELS.TRACK_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
    },
    {
        title: LOCAL.CATALOGS.TAGS,
        dataIndex: 'tags',
        key: 'tags',
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.AUTOASSIGNMENT,
        dataIndex: 'isAutoAssignment',
        key: 'isAutoAssignment',
        render: (isAutoAssignment) => (isAutoAssignment ? LOCAL.YES : LOCAL.NO),
    },
    {
        title: LOCAL.LABELS.ADDITIONAL_MATERIALS_CUT,
        dataIndex: 'isHasAdditionals',
        key: 'isHasAdditionals',
        render: (isHasAdditionals) => (isHasAdditionals ? LOCAL.YES : LOCAL.NO),
    },
    {
        key: 'actions',
        width: 150,
        render: (tableRow) => <TracksUniqueTableActions tableRow={tableRow} />,
        align: 'right',
    },
]

export enum TracksUniqueType {
    Crossword = 'crossword',
}
