import { HomeworkStatusType, MissionTaskPassingContract } from 'core/api'

import { TaskReduceAccProps } from './InfoBlock.types'

export const getTaskResult = (tasks: MissionTaskPassingContract[]) =>
    tasks.reduce<TaskReduceAccProps>(
        (acc, current) => {
            switch (current.status) {
                case HomeworkStatusType.Passed:
                    return {
                        ...acc,
                        passed: acc.passed + 1,
                    }
                case HomeworkStatusType.Revision:
                    return {
                        ...acc,
                        revision: acc.revision + 1,
                    }
                default:
                    return acc
            }
        },
        { passed: 0, revision: 0 }
    )
