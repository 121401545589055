import React, { useEffect, useRef } from 'react'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { TrackStagePassing } from 'components/pages/TrackPreviewPassing/components/TrackLayer/components'
import { VisualizationOptionsGroup } from 'components/shared'
import { WithFormInstanceProps } from 'App.types'
import { isDocumentStatusInWork, isStageModePassing } from 'utils'

import styles from './StagesDndArea.module.scss'
import { FormDataStagesStylesProps } from '../../../TrackForm.types'
import { StagesDndAreaProps } from './StagesDndArea.types'
import { TrackStageDnD } from '../TrackStageDnD'
import { TrackStageDndHelper } from './TrackStageDndHelper'

/**
 * Компонент добавления созданных этапов на полотно трека
 */
export const StagesDndArea: React.FC<StagesDndAreaProps> = React.memo(
    ({
        droppableElem,
        form,
        status,
        appointmentStatus,
        onChangeStagesStyles,
        mode,
        onUpdatePassageProcessInfo,
        startStagePassing,
        canvasKey,
        currentCanvasKey,
        passingType,
    }) => {
        const isDragging = useRef(false)
        const isDroppable = useRef(false)
        const dragElementRef = useRef<HTMLElement | null>(null)
        const shiftCoordinates = useRef<[number, number]>([0, 0])

        /**
         * Маппинг для массива этапов
         */
        const getStagesMapped = (form: WithFormInstanceProps['form']) => {
            const { trackCanvases, stages } = form?.getFieldsValue()

            if (
                !stages?.length ||
                !trackCanvases?.[canvasKey]?.trackStageStyles?.length ||
                !droppableElem
            )
                return

            return trackCanvases[
                canvasKey
            ].trackStageStyles.map(
                (stageStyle: FormDataStagesStylesProps, index: number) =>
                    isStageModePassing(mode) ? (
                        <TrackStagePassing
                            key={index}
                            index={index}
                            stageStyle={stageStyle}
                            stageOptions={stages[index]}
                            droppableElem={droppableElem}
                            startStagePassing={startStagePassing}
                            onUpdatePassageProcessInfo={
                                onUpdatePassageProcessInfo
                            }
                            passingType={passingType}
                            appointmentStatus={appointmentStatus}
                        />
                    ) : (
                        <TrackStageDnD
                            key={index}
                            index={index}
                            stageStyle={stageStyle}
                            stageOptions={stages[index]}
                            droppableElem={droppableElem}
                            mode={mode}
                            form={form}
                            canvasKey={canvasKey}
                        />
                    )
            )
        }

        useEffect(() => {
            if (isDocumentStatusInWork(status)) return

            /**
             * Для DnD используются статические методы класса TrackStageDndHelper, класс понимает с каким
             * разрешением (у канваса может быть их несколько Resolutions.XXL или Resolutions.XL и т.д.)
             * ему работать благодаря currentCanvasKey, который соответствует текущему активному табу разрешений.
             * Но поскольку у нас все вкладки рендерятся принудительно, даже неактивные (это необходимо для того, чтобы
             * контекст формы antd верно проставлял значения полям формы), у нас TrackStageDndHelper.init может быть
             * вызван несколько раз, перепоределяя нужные нам значения. Чтобы этого не происходило мы делаем проверку
             * и выходим, если значение currentCanvasKey (текущая вкладка) не равно очередной в рендеринге вкладке.
             */
            if (canvasKey !== currentCanvasKey) return

            TrackStageDndHelper.init({
                isDragging,
                isDroppable,
                dragElementRef,
                shiftCoordinates,
                droppableElem,
                form,
                onChangeStagesStyles,
                currentCanvasKey,
            })

            document.addEventListener(
                'mousedown',
                TrackStageDndHelper.handleMouseDown
            )

            return () => {
                document.removeEventListener(
                    'mousedown',
                    TrackStageDndHelper.handleMouseDown
                )
            }
        }, [
            droppableElem,
            form,
            status,
            onChangeStagesStyles,
            canvasKey,
            currentCanvasKey,
        ])

        return (
            <VisualizationOptionsGroup
                title={LOCAL.LABELS.STAGE_ADD}
                className={styles.wrapper}
                required
            >
                <Form.Item noStyle shouldUpdate>
                    {(form) =>
                        getStagesMapped(form) || (
                            <span className={styles.emptyText}>
                                {LOCAL.LABELS.STAGE_EMPTY}
                            </span>
                        )
                    }
                </Form.Item>
            </VisualizationOptionsGroup>
        )
    }
)
