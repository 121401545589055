import React, { useCallback, useMemo } from 'react'
import { DocumentEditorView } from 'components/shared'
import { LdeEditorInitialMode, LdeEditorRoles } from 'App.types'
import { PlayerView } from 'components/pages/TrackPreviewPassing/components/ThematicBlockLayer/components/ThematicBlockContent/components'
import { WithHeightResizeProps, withHeightResize } from 'HOCs'
import { eventEmitter } from 'core/helpers/eventEmitter'
import {
    isHomeworkWaitingForSolution,
    isThemeBlockTypeCourse,
    isThemeBlockTypeHomework,
} from 'utils'

import styles from './LessonLayerContent.module.scss'
import { LessonLayerChildProps } from '../LessonLayer/LessonLayer.types'
import { TRACK_PASSING_EVENTS } from '../../TrackPassing.events'

export const LessonLayerContent: React.FC<
    WithHeightResizeProps & LessonLayerChildProps
> = withHeightResize(
    ({ componentHeight, componentRef, lessonInfo, isChildLesson }) => {
        const finishLesson = useCallback(() => {
            eventEmitter.emit(TRACK_PASSING_EVENTS.FINISH_LESSON, isChildLesson)
        }, [isChildLesson])

        const editorConfig = useMemo(
            () => ({
                documentId: lessonInfo?.homework?.ldeId,
                initialMode: !isHomeworkWaitingForSolution(
                    lessonInfo?.homework?.status
                )
                    ? LdeEditorInitialMode.View
                    : LdeEditorInitialMode.Edit,
                roles: [LdeEditorRoles.Listener],
                spelling: !lessonInfo?.homework?.isDisableSpellCheck,
            }),
            [lessonInfo]
        )

        const getLesson = () => {
            if (
                isThemeBlockTypeHomework(lessonInfo?.type) &&
                lessonInfo?.homework?.ldeId
            ) {
                return <DocumentEditorView editorConfig={editorConfig} />
            }

            if (
                isThemeBlockTypeCourse(lessonInfo?.type) &&
                lessonInfo?.course
            ) {
                return (
                    <PlayerView
                        courseData={lessonInfo?.course}
                        onViewMaterial={finishLesson}
                        progress={lessonInfo?.progress}
                    />
                )
            }
        }

        return (
            <div
                className={styles.wrapper}
                style={{ height: componentHeight }}
                ref={componentRef}
            >
                {getLesson()}
            </div>
        )
    }
)
