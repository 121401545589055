import React from 'react'
import { Button } from 'components/shared/Button'
import { FnActionProps } from 'App.types'

import { NotificationProps } from './Notification.types'

/**
 * Кнопка закрытия оповещения
 */
export const renderCloseButton = (callback: FnActionProps) => (
    <Button type="primary" size="small" onClick={callback}>
        {'Прочитано'}
    </Button>
)

/**
 * Тело оповещения
 */
export const renderNotificationBody = (notification: NotificationProps) =>
    notification.link ? (
        <a href={notification.link}>{notification.text}</a>
    ) : (
        notification.text
    )
