import React, { FC } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { CKEditorAdapter } from 'components/shared'
import { FormItemAdapter } from 'components/controls'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'

import styles from './MaterialsTaskFields.module.scss'
import { MaterialsTaskFieldProps } from './MaterialTaskFIelds.types'
import { SUB_TASK_TOOLBAR_OPTIONS } from './MaterialsTaskFields.config'
import { TASK_DESCRIPTION_EXTRA_PLUGINS } from '../MaterialsTaskForm/MaterialsTaskForm.config'

export const MaterialsTaskFields: FC<MaterialsTaskFieldProps> = React.memo(
    ({ fields, add, remove, formMode, dispatch }) => (
        <>
            {fields.map((field, index) => (
                <div className={styles.flex} key={field.key}>
                    <FormItemAdapter
                        fieldName={[field.name, 'taskText']}
                        label={`${LOCAL.LABELS.TASK} ${index + 1}`}
                        className={styles.taskField}
                    >
                        <CKEditorAdapter
                            dispatchEvent={dispatch}
                            height="20vh"
                            toolbar={SUB_TASK_TOOLBAR_OPTIONS}
                            extraPlugins={TASK_DESCRIPTION_EXTRA_PLUGINS}
                        />
                    </FormItemAdapter>

                    {!!index && (
                        <IconsAdapter
                            iconType="CloseOutlined"
                            onClick={() => remove(field.name)}
                            className={styles.closeIcon}
                        />
                    )}
                </div>
            ))}

            <AddButton
                buttonText={LOCAL.ACTIONS.ADD_TASK}
                onClick={() => add({ taskNumber: fields.length + 1 })}
                formMode={formMode}
                className={styles.addButton}
            />
        </>
    )
)
