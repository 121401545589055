import cn from 'classnames'
import React, { useCallback } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { ShouldUpdateChecker } from 'components/controls'
import { THEME_BLOCK_TYPE } from 'consts'
import { isFormModeView } from 'utils'

import styles from './ThemeBlockHeader.module.scss'
import { LessonValueFormProps } from '../EventForm/EventForm.types'
import { ThemeBlockHeaderProps } from './ThemeBlockHeader.types'

export const ThemeBlockHeader: React.FC<ThemeBlockHeaderProps> = React.memo(
    ({
        spoilerProps: { toggleSpoilerVisible, icon },
        field,
        formMode,
        removeFn,
        dragHandleProps,
        themeBlockNumber,
        disabled,
    }) => {
        const isRemovable = !isFormModeView(formMode) && !disabled
        const handleRemove = useCallback(() => removeFn(themeBlockNumber), [
            removeFn,
            themeBlockNumber,
        ])

        return (
            <div className={styles.wrapper}>
                <div className={styles.body}>
                    <IconsAdapter
                        iconType="CustomIconDnD"
                        {...dragHandleProps}
                    />

                    <div className={styles.order}>{themeBlockNumber + 1}</div>

                    <a href="#s" onClick={toggleSpoilerVisible}>
                        <ShouldUpdateChecker fieldPath={['lessons']}>
                            {({ getFieldValue }) => {
                                const {
                                    name,
                                    type,
                                }: Partial<LessonValueFormProps> = getFieldValue(
                                    'lessons'
                                )[field.name]

                                return (
                                    <div className={styles.body}>
                                        {icon}

                                        <div className={styles.blockType}>
                                            {THEME_BLOCK_TYPE[type!]}
                                        </div>

                                        {name && (
                                            <>
                                                <span
                                                    className={
                                                        styles.headerText
                                                    }
                                                >
                                                    {' -'}
                                                </span>

                                                <span
                                                    className={cn(
                                                        styles.headerText,
                                                        styles.name
                                                    )}
                                                >
                                                    {`${name}`}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                )
                            }}
                        </ShouldUpdateChecker>
                    </a>
                </div>

                {isRemovable && (
                    <IconsAdapter
                        iconType="CloseOutlined"
                        className={styles.delete}
                        onClick={handleRemove}
                    />
                )}
            </div>
        )
    }
)
