import React, { useCallback, useEffect, useMemo } from 'react'
import {
    AppError,
    CompetenciesService,
    DevelopmentGoalCreateContract,
    DevelopmentGoalsService,
} from 'core/api'
import { DictionariesCommonProps } from 'App.types'
import { Form } from 'antd'
import {
    FormItemAdapter,
    HiddenField,
    InputControl,
    SelectControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import { getItemId } from 'utils'
import { useDictionaries } from 'hooks'
import { withLoader } from 'HOCs'

/** Форма создания\редактирования цели развития */
export const DevelopmentGoalsForm: React.FC<PopupAdapterFormProps> = withLoader(
    React.memo(
        ({
            onRequestFinish,
            deleteFormAction,
            initialValues,
            recordCopy,
            rowSelectionType,
            messageText,
            onCancelSubmit,
            updateLoader,
            updateButtonVisibility,
            onChangeModalOptions,
            onRefetch,
            isLoading,
            ...props
        }) => {
            const { dictionaries, handleFetchDictionaries } = useDictionaries<
                DictionariesCommonProps<'competencies'>
            >({ updateLoader })

            /**
             * Обработчик отправки формы
             */
            const handleFinish = useCallback(
                async ({ id, ...values }) => {
                    try {
                        updateLoader(true)
                        updateButtonVisibility?.(true)
                        const body = {
                            ...values,
                            competenceIds: values.competenceIds?.length
                                ? values.competenceIds
                                : undefined,
                        } as DevelopmentGoalCreateContract

                        if (id) {
                            await DevelopmentGoalsService.update({
                                id,
                                body,
                            })
                        } else {
                            await DevelopmentGoalsService.create({
                                body,
                            })
                        }

                        onRequestFinish?.()
                    } catch (error) {
                        onRequestFinish?.(error as AppError)
                    } finally {
                        updateLoader(false)
                        updateButtonVisibility?.(false)
                    }
                },
                [onRequestFinish, updateButtonVisibility, updateLoader]
            )

            /**
             * Запрос данных справочника
             */
            useEffect(() => {
                const dictionaryFetch = async () => {
                    try {
                        handleFetchDictionaries({
                            competencies: CompetenciesService.getAll(),
                        })
                    } catch (error) {
                        console.error(error)
                    }
                }

                dictionaryFetch()
            }, [handleFetchDictionaries])

            /**
             * Начальные значения формы
             */
            const initialValue = useMemo(
                () =>
                    initialValues && {
                        id: initialValues.id,
                        name: initialValues.name,
                        competenceIds: initialValues.competences.map(getItemId),
                    },
                [initialValues]
            )

            return (
                <Form
                    onFinish={handleFinish}
                    {...props}
                    initialValues={initialValue}
                >
                    <HiddenField fieldName="id" />

                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.DEVELOPMENT_GOAL_NAME}
                        required
                    >
                        <InputControl />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName="competenceIds"
                        label={LOCAL.LABELS.COMPETENCE}
                        required
                    >
                        <SelectControl
                            values={dictionaries?.competencies}
                            mode="multiple"
                            getPopupContainer={() =>
                                getDropdownPopupContainerDefault(
                                    'ant-modal-body'
                                )
                            }
                        />
                    </FormItemAdapter>
                </Form>
            )
        }
    )
)
