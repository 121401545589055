import React, { useCallback, useContext } from 'react'
import { PageContent, VideoContentModal } from 'components/shared'
import { eventEmitter } from 'core/helpers/eventEmitter'
import {
    isTrainingMaterialFormatTinCan,
    isTrainingMaterialFormatVideo,
} from 'utils'

import styles from './CourseLayer.module.scss'
import { CourseLayerContent } from '../CourseLayerContent'
import { CourseLayerHeader } from '../CourseLayerHeader'
import { TRACK_PASSING_EVENTS } from '../../TrackPassing.events'
import { TrackPassingContext } from '../../TrackPassing.context'

export const CourseLayer: React.FC = React.memo(() => {
    const { store } = useContext(TrackPassingContext)

    const { stageInfo } = store

    const course = stageInfo?.content?.course
    const trackStagePassingId = stageInfo?.content?.trackStagePassingId

    const finishStage = useCallback(() => {
        eventEmitter.emit(
            TRACK_PASSING_EVENTS.FINISH_STAGE_CONTENT,
            trackStagePassingId
        )
    }, [trackStagePassingId])

    const exitStage = useCallback(
        (progress) => {
            eventEmitter.emit(
                TRACK_PASSING_EVENTS.SAVE_STAGE_CONTENT_PROGRESS,
                trackStagePassingId,
                progress || 0
            )
        },
        [trackStagePassingId]
    )

    if (isTrainingMaterialFormatVideo(course?.format)) {
        return (
            <VideoContentModal
                fileUrl={course?.launchUrl}
                progress={stageInfo?.content?.progress}
                onFinish={finishStage}
                onExit={exitStage}
                popupProps={{
                    visible: true,
                }}
            />
        )
    }

    return (
        <PageContent className={styles.wrapper}>
            {!isTrainingMaterialFormatTinCan(course?.format) && (
                <CourseLayerHeader />
            )}

            {stageInfo?.content && <CourseLayerContent />}
        </PageContent>
    )
})
