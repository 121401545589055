import React from 'react'
import { ShouldUpdateChecker } from 'components/controls'
import {
    isActivityTypeMixed,
    isThemeBlockTypeActivity,
    isThemeBlockTypeCourse,
    isThemeBlockTypeHomework,
    isThemeBlockTypeQuestionnaire,
} from 'utils'

import { ActivityFields } from '../ActivityFields'
import { CourseFields } from '../CourseFields'
import { FieldsByTypeEventProps } from './FieldsByTypeEvent.types'
import { HomeworkFields } from '../HomeworkFields'
import { QuestionnaireFields } from '../QuestionnaireFields'
import { getThemeBlockType } from '../ThemeBlock/ThemeBlock.utils'

export const FieldsByTypeEvent: React.FC<FieldsByTypeEventProps> = React.memo(
    ({ field, formMode, disabled }) => (
        <ShouldUpdateChecker fieldPath={['lessons']}>
            {({ getFieldValue, resetFields }) => {
                const themeBlockType = getThemeBlockType(getFieldValue, field)
                const eventType = getFieldValue('type')
                const isTypeMixed = isActivityTypeMixed(eventType)
                const isCourse = isThemeBlockTypeCourse(themeBlockType)
                const isQuestionnaire = isThemeBlockTypeQuestionnaire(
                    themeBlockType
                )
                const isHomework = isThemeBlockTypeHomework(themeBlockType)
                const isActivity = isThemeBlockTypeActivity(themeBlockType)
                const editorUsedFieldPath = [
                    'lessons',
                    field.name,
                    'isEditorUsed',
                ]

                return (
                    <>
                        {isCourse && (
                            <CourseFields
                                formMode={formMode}
                                field={field}
                                disabled={disabled}
                            />
                        )}

                        {isQuestionnaire && (
                            <QuestionnaireFields
                                formMode={formMode}
                                field={field}
                                disabled={disabled}
                            />
                        )}

                        {isHomework && (
                            <HomeworkFields
                                formMode={formMode}
                                field={field}
                                resetFields={resetFields}
                                editorUsedFieldPath={editorUsedFieldPath}
                                disabled={disabled}
                            />
                        )}

                        {isActivity && (
                            <ActivityFields
                                formMode={formMode}
                                field={field}
                                isTypeMixed={isTypeMixed}
                                eventType={eventType}
                                disabled={disabled}
                            />
                        )}
                    </>
                )
            }}
        </ShouldUpdateChecker>
    )
)
