import React, { useMemo } from 'react'

import styles from './UserProfileCompetenciesGroup.module.scss'
import {
    ResultCompetenceProps,
    UserProfileCompetenciesGroupProps,
} from './UserProfileCompetenciesGroup.types'
import { UserProfileCompetenceLevelComparisonRow } from '../UserProfileCompetenceLevelComparisonRow'

/**
 * Профиль пользователя: группа компетенций
 */
export const UserProfileCompetenciesGroup: React.FC<UserProfileCompetenciesGroupProps> = React.memo(
    ({ competencies, groupName }) => {
        /**
         * Компетенции группы
         */
        const competenciesRendered = useMemo(
            () =>
                competencies?.map((el: ResultCompetenceProps) => (
                    <div className={styles.row} key={el.id}>
                        <UserProfileCompetenceLevelComparisonRow {...el} />
                    </div>
                )),
            [competencies]
        )

        return (
            <div className={styles.wrapper}>
                <h4 className={styles.name}>{groupName}</h4>

                {competenciesRendered}
            </div>
        )
    }
)
