import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Affix, Form, Space } from 'antd'
import { CONTENT_TITLE_HEIGHT } from 'consts'
import { CheckboxControl, ShouldUpdateChecker } from 'components/controls'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { IndicatorsService } from 'core/api'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { OnBeforeChangeProps } from 'components/controls/CheckboxControl/CheckboxControlProps.types'
import { Store, WithFormInstanceProps } from 'App.types'
import {
    createConfirmPopup,
    isAssessmentTypeEstimate360,
    isFormModeView,
    isQuestionnaireTypeAssessment,
    normalizeDataForSelectAndRadio,
} from 'utils'

import styles from './QuestionsList.module.scss'
import { INITIAL_FORM_VALUES } from '../QuestionnairesConstructorForm/QuestionnairesConstructorForm.consts'
import { QuestionVariation } from '../QuestionVariation'
import { QuestionVariationValueProps } from '../QuestionVariation/QuestionVariation.types'
import { QuestionsListProps } from './QuestionsList.types.'
import { QuestionsNavigation } from '../QuestionsNavigation'

export const QuestionsList: React.FC<QuestionsListProps> = React.memo(
    ({ updateLoader, isLoading, competenceId }) => {
        const [showAllQuestion, setShowAllQuestions] = useState(true)

        const [questionsVisibility, setQuestionsVisibility] = useState<
            Store<boolean>
        >()

        const [indicators, setIndicators] = useState<LabeledValue[]>([])

        const fetchIndicatorsData = useCallback(async () => {
            try {
                if (!competenceId) return

                updateLoader(true)

                const indicators = await IndicatorsService.getIndicatorsByCompetenceId(
                    {
                        competenceId,
                    }
                )

                const normalizedIndicators = indicators.map(
                    normalizeDataForSelectAndRadio
                )

                setIndicators(normalizedIndicators)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [competenceId, updateLoader])

        const toggleVisibilityAllQuestions = useCallback(
            (isVisible: boolean) => {
                setQuestionsVisibility((prevState) => {
                    const tempQuestion = { ...prevState }

                    Object.keys(tempQuestion).forEach((questionKey) => {
                        tempQuestion[questionKey] = isVisible
                    })

                    return tempQuestion
                })
            },
            []
        )

        const toggleVisibilityParticularQuestion = useCallback(
            (isVisible: boolean, questionKey: string) => {
                setQuestionsVisibility((prevState) => ({
                    ...prevState,
                    [questionKey]: isVisible,
                }))
            },
            []
        )

        const handleDeleteField = useCallback(
            (field, onDelete) => () => {
                onDelete(field)
            },
            []
        )

        const handleChangeVisibilityAllQuestion = useCallback(() => {
            setShowAllQuestions(!showAllQuestion)
            toggleVisibilityAllQuestions(!showAllQuestion)
        }, [showAllQuestion, toggleVisibilityAllQuestions])

        const onBeforeChange = useCallback(
            (form: WithFormInstanceProps['form']) => ({
                onChange,
                value,
            }: OnBeforeChangeProps) => {
                if (value) {
                    createConfirmPopup({
                        content: LOCAL.MESSAGES.BEFORE_CHANGE_QUESTIONS_ORDER,
                        onOk: () => {
                            const questions: QuestionVariationValueProps[] = form?.getFieldValue(
                                'questions'
                            )

                            form?.setFieldsValue({
                                questions: questions?.map(
                                    ({ blockedByQuestionNumber, ...el }) => el
                                ),
                            })
                            onChange()
                        },
                    })
                } else {
                    onChange()
                }
            },
            []
        )

        /**
         * Запрос индикаторов на основе компетенции
         */
        useEffect(() => {
            fetchIndicatorsData()
        }, [fetchIndicatorsData])

        /**
         * Установка состояния флага скрытия/раскрытия всех вопросов
         */
        useEffect(() => {
            if (!questionsVisibility) return

            if (Object.values(questionsVisibility).some(Boolean)) {
                setShowAllQuestions(true)
            }

            if (
                Object.values(questionsVisibility).every(
                    (isVisible) => !isVisible
                )
            ) {
                setShowAllQuestions(false)
            }
        }, [questionsVisibility])

        return (
            <div className={styles.wrapper}>
                <ShouldUpdateChecker
                    fieldPath={[['type'], ['assessmentType'], ['formMode']]}
                >
                    {(form) => {
                        const {
                            type,
                            assessmentType,
                            formMode,
                        } = form.getFieldsValue()

                        return (
                            !(
                                isQuestionnaireTypeAssessment(type) &&
                                isAssessmentTypeEstimate360(
                                    assessmentType ||
                                        INITIAL_FORM_VALUES.assessmentType
                                )
                            ) && (
                                <div className={styles.randomQuestion}>
                                    <Form.Item name="randomQuestionsOrder">
                                        <CheckboxControl
                                            formMode={formMode}
                                            onBeforeChange={onBeforeChange(
                                                form
                                            )}
                                        >
                                            {
                                                LOCAL.LABELS
                                                    .RANDOM_QUESTIONS_ORDER
                                            }
                                        </CheckboxControl>
                                    </Form.Item>
                                </div>
                            )
                        )
                    }}
                </ShouldUpdateChecker>

                <Form.List name="questions">
                    {(fields, { add, remove }) => (
                        <>
                            <Affix offsetTop={CONTENT_TITLE_HEIGHT}>
                                <div className={styles.header}>
                                    <Space>
                                        <button
                                            type="button"
                                            disabled={!fields.length}
                                            onClick={
                                                handleChangeVisibilityAllQuestion
                                            }
                                            className={classNames(
                                                styles.toggleVisibility,
                                                {
                                                    [styles.visible]: showAllQuestion,
                                                }
                                            )}
                                        >
                                            <IconsAdapter iconType="UpSquareOutlined" />
                                        </button>

                                        <h2>{LOCAL.LABELS.QUESTIONS_LIST}</h2>

                                        <ShouldUpdateChecker fieldPath="formMode">
                                            {({ getFieldValue }) => {
                                                const formMode = getFieldValue(
                                                    'formMode'
                                                )

                                                return (
                                                    <AddButton
                                                        onClick={() => add()}
                                                        buttonText={
                                                            LOCAL.ACTIONS
                                                                .ADD_QUESTION
                                                        }
                                                        disabled={isFormModeView(
                                                            formMode
                                                        )}
                                                    />
                                                )
                                            }}
                                        </ShouldUpdateChecker>
                                    </Space>

                                    {!!fields.length && (
                                        <QuestionsNavigation
                                            questions={fields}
                                        />
                                    )}
                                </div>
                            </Affix>

                            <div className={styles.body}>
                                {fields.map((field, index) => (
                                    <div
                                        className={styles.item}
                                        key={field.key}
                                    >
                                        <div
                                            id={`question-${field.name + 1}`}
                                        />

                                        <QuestionVariation
                                            index={index}
                                            field={field}
                                            indicators={indicators}
                                            toggleVisibilityParticularQuestion={
                                                toggleVisibilityParticularQuestion
                                            }
                                            questionsVisibility={
                                                questionsVisibility
                                            }
                                            onDeleteQuestion={handleDeleteField(
                                                field.name,
                                                remove
                                            )}
                                            updateLoader={updateLoader}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </Form.List>
            </div>
        )
    }
)
