import cn from 'classnames'
import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { CloudTag, IconsAdapter, Title } from 'components/shared'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { toggleFavoriteStatus } from 'components/pages/Main/components'

import styles from './ProductCardHeader.module.scss'
import { ProductCardHeaderProps } from './ProductCardHeader.types'
import { ProductCardLauncher } from '../ProductCardLauncher'

/** Карточка продукта - шапка */
export const ProductCardHeader: React.FC<ProductCardHeaderProps> = React.memo(
    ({ pageInfo, fetchPageInfo, isAppointed, isListenerEnrolled }) => {
        /**
         * Добавить элемент в раздел "избранное"
         */
        const handlePickFavorite = useCallback(async () => {
            await toggleFavoriteStatus({
                itemId: Number(pageInfo?.id),
                isFavorite: !!pageInfo?.favorites,
            })

            fetchPageInfo()
        }, [fetchPageInfo, pageInfo])

        /** Перечисление тематик, к которым прикреплена карточка */
        const cardThematics = pageInfo?.themes.map((theme) => (
            <CloudTag className={styles.cloudTag} key={theme.id}>
                {theme.name}
            </CloudTag>
        ))

        return (
            <div className={styles.wrapper}>
                <div className={styles.toolbar}>
                    <Link to={ROUTE_NAMES.MAIN} className={styles.backlink}>
                        <IconsAdapter iconType="CustomIconSliderArrow" />

                        <span>{LOCAL.ACTIONS.RETURN.toLowerCase()}</span>
                    </Link>

                    <Button
                        type="link"
                        icon={<IconsAdapter iconType="CustomIconStar" />}
                        onClick={handlePickFavorite}
                        className={cn(
                            styles.icon,
                            pageInfo?.favorites && styles.iconActive
                        )}
                    />

                    <div className={styles.themes}>{cardThematics}</div>
                </div>

                <Title type="h1" text={pageInfo?.name} />

                <Title
                    type="h4"
                    text={pageInfo?.announcement}
                    className={styles.announcement}
                />

                <ProductCardLauncher
                    card={pageInfo}
                    isListenerEnrolled={isListenerEnrolled}
                    fetchPageInfo={fetchPageInfo}
                    showMessage
                    isAppointed={isAppointed}
                />
            </div>
        )
    }
)
