import cn from 'classnames'
import isNumber from 'lodash/isNumber'
import noop from 'lodash/noop'
import React, { useMemo } from 'react'
import { ReactTextNullable } from 'App.types'
import { Slider } from 'antd'

import styles from './SliderSingleControl.module.scss'
import { InputNumberControl } from '../InputControl'
import { SliderSingleControlProps } from './SliderSingleControl.types'

/** Декоратор для `Slider` от `antd` */
export const SliderSingleControl: React.FC<SliderSingleControlProps> = React.memo(
    ({
        value,
        onChange = noop,
        min,
        max,
        addonNumberAfter,
        addonNumberOptions,
        defaultValue,
        initialValue,
        className,
        ...restProps
    }) => {
        /**
         * Обработчик внешней формы
         * @param value выбранное значение
         */
        const triggerChange = (value?: ReactTextNullable) => {
            onChange?.(Number(value))
        }

        /**
         * Определяем текущее значение для компонента
         */
        const currentValue = useMemo(
            () => (isNumber(value) ? value : initialValue),
            [value, initialValue]
        )

        return (
            <div className={cn(styles.wrapper, className)}>
                <Slider
                    onChange={triggerChange}
                    value={currentValue}
                    min={min}
                    max={max}
                    defaultValue={defaultValue}
                    {...restProps}
                />

                {addonNumberAfter && (
                    <InputNumberControl
                        value={currentValue}
                        onChange={triggerChange}
                        className={styles.inputNumberControl}
                        min={min}
                        max={max}
                        defaultValue={defaultValue}
                        {...addonNumberOptions}
                    />
                )}
            </div>
        )
    }
)
