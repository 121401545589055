import React, { FC, useCallback, useEffect, useState } from 'react'
import {
    COMPETENCIES_TABLE_COLUMNS,
    CompetenciesFilters,
    ControlGroup,
    FormItemAdapter,
    InputNumberControl,
    ShouldUpdateChecker,
} from 'components/controls'
import {
    CompetenceContract,
    CompetenceForModalContract,
    CompetenciesSearchContract,
    CompetenciesService,
} from 'core/api'
import { DynamicTableAdapter } from 'components/shared'
import { Form } from 'antd'
import { LOCAL } from 'core/local'

import {
    CIRCLE_PARAMETERS_FIELDS_NAMES,
    NUMBER_INPUT_MIN,
    TITLE,
} from './CircleParameters.consts'
import {
    CircleParametersProps,
    CompetenceFormContract,
} from './CircleParameters.types'
import { QuestionnairesState } from '../ManagerParametersRow/ManagerParametersRow.types'
import {
    checkCompetenciesFromManagerGroup,
    normalizeFetchDataForValue,
} from './CircleParameters.utils'
import { getRenderColumnFn } from './CircleParameters.config'

/** Группа полей - оценка 360 */
export const CircleParameters: FC<CircleParametersProps> = React.memo(
    ({ formMode, staffFieldsStatus: { staff, managers }, disabled, form }) => {
        const [questionnaires, setQuestionnaires] = useState<
            QuestionnairesState
        >()

        const [
            formHasManagerCompetencies,
            setFormHasManagerCompetencies,
        ] = useState<boolean>()

        const handleChangeCompetence = useCallback(
            async (competenciesIds: number[]) => {
                if (!competenciesIds) return

                try {
                    const competencies = await CompetenciesService.getByIds({
                        body: competenciesIds,
                    })

                    setFormHasManagerCompetencies(
                        checkCompetenciesFromManagerGroup(competencies)
                    )
                } catch (error) {
                    console.error(error)
                }
            },
            []
        )

        useEffect(() => {
            if (!formHasManagerCompetencies) {
                const allValues = form?.getFieldsValue()

                form?.setFieldsValue({
                    circleParameters: {
                        ...allValues.circleParameters,
                        subordinatesCount: NUMBER_INPUT_MIN,
                    },
                })
            }
        }, [formHasManagerCompetencies, form])

        return (
            <>
                <ControlGroup title={TITLE} noPadding>
                    <FormItemAdapter
                        fieldName={CIRCLE_PARAMETERS_FIELDS_NAMES.managersCount}
                        validateStatus={managers}
                    >
                        <InputNumberControl
                            formMode={formMode}
                            min={NUMBER_INPUT_MIN}
                            addonBefore={LOCAL.LABELS.MANAGER}
                            disabled={disabled}
                        />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName={
                            CIRCLE_PARAMETERS_FIELDS_NAMES.colleaguesCount
                        }
                        validateStatus={staff}
                    >
                        <InputNumberControl
                            formMode={formMode}
                            min={NUMBER_INPUT_MIN}
                            addonBefore={LOCAL.LABELS.COLLEAGUE}
                            disabled={disabled}
                        />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName={
                            CIRCLE_PARAMETERS_FIELDS_NAMES.colleagueFromRelatedDepartmentCount
                        }
                        validateStatus={staff}
                    >
                        <InputNumberControl
                            formMode={formMode}
                            min={NUMBER_INPUT_MIN}
                            addonBefore={
                                LOCAL.LABELS.COLLEAGUE_FROM_RELATED_DEPARTMENT
                            }
                            disabled={disabled}
                        />
                    </FormItemAdapter>

                    <ShouldUpdateChecker
                        fieldPath={['circleParameters', 'questionnaires']}
                    >
                        {({ getFieldValue }) => {
                            const competenciesIds = getFieldValue(
                                'circleParameters'
                            ).questionnaires?.map(
                                (questionnairy: CompetenceFormContract) =>
                                    questionnairy.id
                            )

                            handleChangeCompetence(competenciesIds)

                            const isDisabled =
                                !formHasManagerCompetencies || disabled

                            return (
                                <FormItemAdapter
                                    fieldName={
                                        CIRCLE_PARAMETERS_FIELDS_NAMES.subordinatesCount
                                    }
                                    validateStatus={staff}
                                >
                                    <InputNumberControl
                                        formMode={formMode}
                                        min={NUMBER_INPUT_MIN}
                                        addonBefore={LOCAL.LABELS.SUBORDINATE}
                                        disabled={isDisabled}
                                    />
                                </FormItemAdapter>
                            )
                        }}
                    </ShouldUpdateChecker>
                </ControlGroup>

                <Form.Item name={['circleParameters', 'questionnaires']}>
                    <DynamicTableAdapter<
                        CompetenceContract,
                        CompetenceFormContract,
                        CompetenciesSearchContract,
                        CompetenceForModalContract
                    >
                        getEntitiesByIds={CompetenciesService.getByIds}
                        searchMethod={CompetenciesService.getForModal}
                        modalFilterComponent={CompetenciesFilters}
                        columnsForSearchModal={COMPETENCIES_TABLE_COLUMNS}
                        normalizeFn={normalizeFetchDataForValue}
                        renderTableColumns={getRenderColumnFn({
                            setQuestionnaires,
                            questionnaires,
                            formMode,
                            disabled,
                        })}
                        title={LOCAL.LABELS.COMPETENCIES}
                        modalTitle={LOCAL.LABELS.COMPETENCES_SELECTION}
                        formMode={formMode}
                        required
                        disabled={disabled}
                    />
                </Form.Item>
            </>
        )
    }
)
