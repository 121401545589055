import React from 'react'
import { ErrorPage } from 'components/pages/utilityPages/ErrorPage'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'

import { QuestionnairePassing } from './QuestionnairePassing'
import { QuestionnairesListenerPage } from './QuestionnairesListenerPage'

/** Страница назначенных оценок на слушателя */
export const QuestionnairesListener: React.FC = React.memo(() => (
    <Switch>
        <Route
            path={`${ROUTE_NAMES.QUESTIONNAIRE_PASSING}/:questionnairePassingId/:questionnaireType`}
        >
            <QuestionnairePassing />
        </Route>

        <Route path={ROUTE_NAMES.QUESTIONNAIRES_LISTENER}>
            <QuestionnairesListenerPage />
        </Route>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
