import {
    ActivityCanvasDesignType,
    ActivityDecorationType,
    ActivityKind,
    ActivityLessonPassingContract,
    ActivityPassingStatus,
    ActivityType,
    LessonAccessType,
    LessonType,
} from 'core/api'

import { ContentInfoProps } from '../../components/pages/TrackPreviewPassing/TrackPreviewPassing.types'
import { isTrainingMaterialFormatVideo } from './trainingMaterials.utils'

/**
 * Проверка типа мероприятия
 * @param type - тип
 */
export const isActivityTypeDistant = (type?: ActivityType) =>
    type === ActivityType.Distant

export const isActivityTypeIntramural = (type?: ActivityType) =>
    type === ActivityType.Intramural

export const isActivityTypeMixed = (type?: ActivityType) =>
    type === ActivityType.Mixed

/**
 * Проверка вида мероприятия
 * @param kind - вид
 */
export const isActivityKindTeaching = (kind?: ActivityKind) =>
    kind === ActivityKind.Teaching

export const isActivityKindIntroductory = (kind?: ActivityKind) =>
    kind === ActivityKind.Introductory

/**
 * Для статуса прохождения мероприятия
 * @param status
 */
export const isActivitynWaiting = (status?: ActivityPassingStatus) =>
    status === ActivityPassingStatus.Waiting

export const isActivityInProgress = (status?: ActivityPassingStatus) =>
    status === ActivityPassingStatus.InProgress

export const isActivityCompletedSuccessfully = (
    status?: ActivityPassingStatus
) => status === ActivityPassingStatus.CompletedSuccessfully

export const isActivityCompletedUnsuccessfully = (
    status?: ActivityPassingStatus
) => status === ActivityPassingStatus.CompletedUnsuccessfully

export const isActivityCompleted = (status?: ActivityPassingStatus) =>
    isActivityCompletedSuccessfully(status) ||
    isActivityCompletedUnsuccessfully(status)

/**
 * Для статуса прохождения тематического блока
 * @param status
 */
export const isActivityLessonWaiting = (status?: ActivityPassingStatus) =>
    status === ActivityPassingStatus.Waiting

export const isActivityLessonInProgress = (status?: ActivityPassingStatus) =>
    status === ActivityPassingStatus.InProgress

export const isActivityLessonCompletedSuccessfully = (
    status?: ActivityPassingStatus
) => status === ActivityPassingStatus.CompletedSuccessfully

export const isActivityLessonCompletedUnsuccessfully = (
    status?: ActivityPassingStatus
) => status === ActivityPassingStatus.CompletedUnsuccessfully

/**
 * Проверка типа тематического блока
 * @param themeBlockType - тип тематического блока
 * @return true, если тип совпадает
 */

/** Учебный материал */
export const isThemeBlockTypeCourse = (themeBlockType?: LessonType): boolean =>
    themeBlockType === LessonType.Course

export const isThemeBlockTypeVideoCourse = (
    themeBlock?: ActivityLessonPassingContract | ContentInfoProps
): boolean => isTrainingMaterialFormatVideo(themeBlock?.course?.format)

/** Очная встреча */
export const isThemeBlockTypeFaceToFace = (
    themeBlockType?: LessonType
): boolean => themeBlockType === LessonType.FaceToFaceMeeting

/** Задание */
export const isThemeBlockTypeHomework = (
    themeBlockType?: LessonType
): boolean => themeBlockType === LessonType.Homework

/** Задание без использования редактора */
export const isThemeBlockTypeHomeworkWithoutEditor = (
    themeBlockType?: LessonType,
    isEditorUsed?: boolean
): boolean => themeBlockType === LessonType.Homework && !isEditorUsed

/** Вебинар */
export const isThemeBlockTypeWebConference = (
    themeBlockType?: LessonType
): boolean => themeBlockType === LessonType.WebConference

/** Опрос/Тест */
export const isThemeBlockTypeQuestionnaire = (
    themeBlockType?: LessonType
): boolean => themeBlockType === LessonType.Questionnaire

/** Мероприятие */
export const isThemeBlockTypeActivity = (
    themeBlockType?: LessonType
): boolean => themeBlockType === LessonType.Activity

/** Информационный блок */
export const isThemeBlockTypeInformation = (
    themeBlockType?: LessonType
): boolean => themeBlockType === LessonType.Information

export const isThemeBlockTypeWebConferenceOrFaceToFace = (
    themeBlockType?: LessonType
) =>
    isThemeBlockTypeWebConference(themeBlockType) ||
    isThemeBlockTypeFaceToFace(themeBlockType)

/**
 * Проверка типа оформления
 * @param decorationType тип оформления
 * @return true, если тип совпадает
 */
export const isDecorationTypeVisualization = (
    decorationType?: ActivityDecorationType
): boolean => decorationType === ActivityDecorationType.Visualization

export const isDecorationTypeStandard = (
    decorationType?: ActivityDecorationType
): boolean => decorationType === ActivityDecorationType.Standard

/**
 * Узнать произвольное ли прохождение мероприятия
 * @param passingType тип прохождения мероприятия
 */
export const isEventPassingTypeRandom = (passingType?: LessonAccessType) =>
    passingType === LessonAccessType.Random
export const isEventPassingTypeStepByStep = (passingType?: LessonAccessType) =>
    passingType === LessonAccessType.StepByStep

/**
 * Узнать завершен ли тематический блок
 */
export const isThematicBlockStatusCompleted = (
    status?: ActivityPassingStatus
) =>
    isActivityLessonCompletedSuccessfully(status) ||
    isActivityLessonCompletedUnsuccessfully(status)

/**
 * Узнать готов ли тематический блок к прохождению
 */
export const isThematicBlockReadyForPassing = (
    status?: ActivityPassingStatus
) => isActivityLessonWaiting(status) || isActivityLessonInProgress(status)

export const isActivityCanvasTypeFullScreen = (
    type?: ActivityCanvasDesignType
) => type === ActivityCanvasDesignType.FullScreen
export const isActivityCanvasTypeList = (type?: ActivityCanvasDesignType) =>
    type === ActivityCanvasDesignType.List
export const isActivityCanvasTypeModalWindow = (
    type?: ActivityCanvasDesignType
) => type === ActivityCanvasDesignType.ModalWindow
