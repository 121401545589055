import { useEffect, useRef } from 'react'

/**
 * Хук для контроля за предыдущим состоянием сущности
 * @param value - текущее значение состояния
 */
export const usePrevious = <T extends unknown>(value: T) => {
    const ref = useRef<T>()

    useEffect(() => {
        ref.current = value
    })

    return ref.current
}
