import { AssessmentType, UserPermission } from 'core/api'

export const ASSESSMENT_REPORTS_FORM_ID = 'assessment-reports-form-id'

export const ASSESSMENT_REPORTS_CREATE_PERMISSIONS = {
    [AssessmentType.Estimate_360]:
        UserPermission.AssessmentReportTemplate360Save,
    [AssessmentType.EstimateByManager]:
        UserPermission.AssessmentReportTemplateManagerSave,
}
