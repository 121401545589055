import React, { useCallback, useEffect, useMemo } from 'react'
import {
    AppointmentTestReviewContract,
    AppointmentTestReviewParticipantsContract,
    AppointmentTestReviewsService,
} from 'core/api'
import { Form, Modal } from 'antd'
import { HiddenField } from 'components/controls'
import { LOCAL } from 'core/local'
import { TableAdapter } from 'components/shared'
import { UrlParamsCommonProps } from 'App.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './TabReview.module.scss'
import {
    QuestionsInfoProps,
    TestReviewFormValuesProps,
} from './TabReview.types'
import { TABLE_REVIEW_FORM_ENTRY_POINT } from './TabReview.consts'
import { TabReviewHeader } from '../TabReviewHeader'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
    reviewTableColsUtils,
    reviewTableDataSourceUtils,
} from './TabReview.utils'

/** Вкладка "Проверка" для страницы отчета по тесту */
export const TabReview: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const urlParams = useParams<UrlParamsCommonProps<'id'>>()
        const [getData, data] = useHttp<AppointmentTestReviewContract[]>(
            AppointmentTestReviewsService.getAppointmentTestForReview
        )
        const [form] = Form.useForm()
        /**
         * Выборка информации по вопросам для участников тестов
         */
        const questionsInfo: QuestionsInfoProps[] = useMemo(
            () =>
                data?.[0]?.participants?.[0]?.questionAnswer?.map((el) => ({
                    question: el.question,
                    questionNumber: el.questionNumber,
                })) || [],
            [data]
        )

        /**
         * Получаем данные для отрисовки таблицы
         * @param id - идентификатор назначения тестов
         */
        const fetchTestReview = useCallback(
            async (appointmentId: number) => {
                getData({ appointmentId })
            },
            [getData]
        )

        const handleFinish = useCallback(
            async (formValues: TestReviewFormValuesProps) => {
                try {
                    updateLoader(true)

                    await AppointmentTestReviewsService.saveAppointmentTestReview(
                        {
                            body: mapFormDataToRequest(formValues),
                        }
                    )

                    /** TODO: Переработать на обычный confirmPopup в рамках SDO-1414 */
                    Modal.info({
                        title: LOCAL.LABELS.CHECKING_TEST_RESULT,
                        content:
                            LOCAL.MESSAGES.TEST_REVIEW_SAVE_SUCCESS_MESSAGE,
                        okText: LOCAL.ACTIONS.CLOSE,
                        onOk: () => {
                            fetchTestReview(Number(urlParams.id))
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader, urlParams.id, fetchTestReview]
        )

        useEffect(() => {
            fetchTestReview(Number(urlParams.id))
        }, [urlParams.id, fetchTestReview])

        /**
         * Устанавливаем значения в форму ИСКЛЮЧИТЕЛЬНО после рендера таблицы
         */
        useEffect(() => {
            if (!data) return

            form.setFieldsValue({
                [TABLE_REVIEW_FORM_ENTRY_POINT]: mapResponseToFormData(
                    data,
                    Number(urlParams.id)
                ),
            })
        }, [data, form, urlParams.id])

        return (
            <Form form={form} onFinish={handleFinish}>
                <HiddenField fieldName={TABLE_REVIEW_FORM_ENTRY_POINT} />

                <TabReviewHeader />

                {!!data && (
                    <TableAdapter
                        wrapperClassName={styles.table}
                        columns={reviewTableColsUtils.getTableColumns<
                            AppointmentTestReviewParticipantsContract
                        >(questionsInfo)}
                        dataSource={reviewTableDataSourceUtils.getDataSource(
                            data
                        )}
                        expandable={{ defaultExpandAllRows: true }}
                        pagination={false}
                    />
                )}
            </Form>
        )
    })
)
