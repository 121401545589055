import React, { useCallback, useState } from 'react'
import { AddButton, Spoiler, TableAdapter } from 'components/shared'
import {
    CoworkersFilters,
    SearchTableShowButton,
    getCoworkersTableColumns,
} from 'components/controls'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { SpoilerTitleRenderProps } from 'components/shared/Spoiler/Spoiler.types'
import {
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import {
    fetchSelectedItemsByIds,
    getItemId,
    getPortfolioObjectsColumnsWithActions,
    isFormModeView,
} from 'utils'

import styles from './AssessmentPosition.module.scss'
import { AssessmentPositionProps } from './AssessmentPosition.types'
import { getTitle, getTitleText } from './AssessmentPosition.utils'

/** Объекты оценки для назначения оценки с типом "оценка руководителем" */
export const AssessmentPosition: React.FC<AssessmentPositionProps> = React.memo(
    ({ formMode, value, onChange, positionData, disabled }) => {
        /** Id выбранных объектов оценки */
        const initialValues = value?.map(getItemId)

        const { position, structuralUnit } = positionData
        const [visible, setVisible] = useState<boolean>(true)

        /**
         * Обработчик выбора объектов оценки в модальном окне
         */
        const handleSelectAssessmentObjects = useCallback(
            async (result?: number[]) => {
                try {
                    if (result) {
                        onChange?.(
                            await fetchSelectedItemsByIds({
                                result,
                                selectedItemsIds: initialValues,
                                getByIdsMethod: UsersService.getByIds,
                                selectedItems: value,
                            })
                        )
                    }
                } catch (err) {
                    console.error(err)
                }
            },
            [initialValues, onChange, value]
        )

        /**
         * Обработчик удаления объекта оценки
         */
        const handleRemoveAssessmentObject = (id: number) => () => {
            const changedValue = value?.filter((el) => el.id !== id)
            onChange?.(changedValue)
            if (!changedValue?.length) {
                setVisible(false)
            }
        }

        /**
         * Функция для кастомного рендера заголовка спойлера объектов оценки
         */
        const renderSpoilerTitle = useCallback(
            ({ icon, toggleSpoilerVisible }: SpoilerTitleRenderProps) => (
                <div className={styles.name}>
                    {getTitle({
                        toggleSpoilerVisible,
                        icon,
                        titleText: getTitleText(positionData),
                        initialValues,
                    })}

                    <SearchTableShowButton<
                        UserModalSearchContract,
                        UserModalContract
                    >
                        tableSearchOptions={{
                            formId: FORM_IDS.SEARCH_RESPONDENTS,
                            searchMethod: UsersService.getForModal,
                            tableColumns: getCoworkersTableColumns(),
                            filterComponent: CoworkersFilters,
                            tableFiltersOptions: {
                                initialValues: {
                                    positionsIds: [position.id],
                                    structuralUnitsIds: [structuralUnit.id],
                                },
                            },
                            onSelectTableItem: handleSelectAssessmentObjects,
                            initialValues,
                        }}
                        modalTitle={LOCAL.LABELS.EMPLOYEE_SELECTION}
                    >
                        <AddButton
                            disabled={isFormModeView(formMode) || disabled}
                            buttonText={LOCAL.ACTIONS.ADD}
                        />
                    </SearchTableShowButton>
                </div>
            ),
            [
                formMode,
                initialValues,
                handleSelectAssessmentObjects,
                positionData,
                position.id,
                structuralUnit.id,
                disabled,
            ]
        )

        return (
            <Spoiler spoilerTitleRender={renderSpoilerTitle} visible={visible}>
                {!!value && (
                    <TableAdapter
                        columns={getPortfolioObjectsColumnsWithActions({
                            tableColumnsObject: getCoworkersTableColumns(),
                            handleRemoveRow: handleRemoveAssessmentObject,
                            formMode,
                            disabled,
                        })}
                        pagination={false}
                        dataSource={value || []}
                        className={styles.table}
                    />
                )}
            </Spoiler>
        )
    }
)
