import React from 'react'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { SwitchControl } from 'components/controls'
import {
    TOOLBAR_CONTENT_SETTINGS,
    ToolbarContentRenderChildrenProps,
} from 'components/layouts'

import styles from './Monitoring.module.scss'
import { RenderToolbarContentProps } from './Monitoring.types'

export const renderToolbarContent = ({
    gutterResponsive,
    toggleMonitoring,
    monitoringEnable,
}: RenderToolbarContentProps) => ({
    titleComponent,
    filterSwitch,
}: ToolbarContentRenderChildrenProps) => (
    <Row {...TOOLBAR_CONTENT_SETTINGS.PROPS} className="pt-20 pb-20">
        <Col>{titleComponent}</Col>

        <Col>
            <Row gutter={gutterResponsive} align="middle">
                <Col>{filterSwitch}</Col>
                <Col>
                    <SwitchControl
                        onClick={toggleMonitoring}
                        addonAfter={LOCAL.LABELS.MONITORING}
                        checked={monitoringEnable}
                        className={styles.toggleMonitoring}
                    />
                </Col>
            </Row>
        </Col>
    </Row>
)
