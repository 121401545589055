import React from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import {
    IndicatorResultsReport360Contract,
    RespondentResultsReport360Contract,
} from 'core/api'
import { LOCAL } from 'core/local'
import { REPORT_LEVELS_MAX } from 'consts'
import { SpoilerTitleRenderProps } from 'components/shared/Spoiler/Spoiler.types'
import { getRoundedOneCharNumber } from 'utils'

import styles from './AssessmentsRespondentsIndicators.module.scss'
import stylesReportView from '../ReportView/ReportView.module.scss'
import { ReportViewProgress } from '../ReportView/ReportViewProgress'
import { getConsistencyIcon } from '../ReportView/ReportView.utils'

// TODO https://jira.it2g.ru/browse/SDO-3563
/**
 * Визуализация уровня компетенции на основе ее значения
 * @param value значение компетенции
 */
const getIndicatorScale = (value: RespondentResultsReport360Contract) => {
    const roundedValue = getRoundedOneCharNumber(
        value.averageScoreWithoutSelfEstim
    )

    return (
        <div className={stylesReportView.spoilerProgress}>
            <ReportViewProgress
                value={(+roundedValue / REPORT_LEVELS_MAX) * 100}
                displayValue={roundedValue}
            />

            <div className={styles.iconContainer}>
                {getConsistencyIcon(value.consistency)}
            </div>

            <div className={styles.iconContainer}>
                {!value.opinionFormed && (
                    <IconsAdapter iconType="ExclamationCircleFilled" />
                )}
            </div>
        </div>
    )
}

/**
 * Заголовок спойлера
 * @param value значение компетенции
 */
export const customSpoilerTitleRender = (
    value: RespondentResultsReport360Contract
) => ({ text, icon, toggleSpoilerVisible }: SpoilerTitleRenderProps) => (
    <>
        <a
            href="#s"
            className={stylesReportView.spoilerHeader}
            onClick={toggleSpoilerVisible}
        >
            {text}

            {icon}
        </a>

        {getIndicatorScale(value)}
    </>
)

export const createDataSource = (
    respondentResults: RespondentResultsReport360Contract
) => [
    {
        indicator: {
            id: 0,
            name: LOCAL.LABELS.MIDDLE_COMPETENCE_SCORE,
        },

        averageScoreCollegue: respondentResults.averageScoreCollegue,
        averageScoreSubordinate: respondentResults.averageScoreSubordinate,
        averageScoreManager: respondentResults.averageScoreManager,
        averageScoreWithoutSelfEstim:
            respondentResults.averageScoreWithoutSelfEstim,
        averageScoreSelf: respondentResults.averageScoreSelf,

        opinionFormedCollegue: respondentResults.opinionFormed,
        opinionFormedSubordinate: respondentResults.opinionFormed,
        opinionFormedManager: respondentResults.opinionFormed,
        opinionFormedAllGroups: respondentResults.opinionFormed,
    } as IndicatorResultsReport360Contract,
    ...respondentResults.indicatorResults,
]
