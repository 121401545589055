import React, { useCallback, useMemo } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import {
    LOCAL_STORAGE_KEYS,
    LocalStorageHelper,
} from 'core/helpers/LocalStorage'
import { LOGOUT_URL } from 'consts/env.consts'
import { MODAL_WIDTH } from 'consts'
import { Menu, Modal } from 'antd'
import { NavLink } from 'react-router-dom'
import { useCheckPermissions } from 'hooks'

import styles from './ProfileMenuList.module.scss'
import { AboutSystem } from './AboutSystem'
import { PROFILE_MENU_ITEMS } from './ProfileMenuList.consts'

const handleLogout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    LocalStorageHelper.removeItem(LOCAL_STORAGE_KEYS.USER_PAGE_SETTINGS)
    window.location.href = LOGOUT_URL
}

/** Список меню */
export const ProfileMenuList: React.FC = React.memo((props) => {
    const { hasPermissionsRequired } = useCheckPermissions()

    /** Обработчик открытия модалки с информацией о системе */
    const handleShowModal = useCallback((e) => {
        e.preventDefault()

        Modal.info({
            icon: '',
            content: <AboutSystem />,
            width: MODAL_WIDTH.MD,
            className: styles.aboutSystem,
            okText: LOCAL.ACTIONS.CLOSE,
        })
    }, [])

    /** Список пунктов меню */
    const profileMenu = useMemo(
        () =>
            PROFILE_MENU_ITEMS.map((menuItem) => (
                <Menu.Item
                    key={menuItem.key}
                    hidden={!hasPermissionsRequired(menuItem.permissions)}
                >
                    {menuItem.key === 'system' ? (
                        <a href="#s" onClick={handleShowModal}>
                            <IconsAdapter iconType={menuItem.icon} />

                            {menuItem.title}
                        </a>
                    ) : (
                        <NavLink to={menuItem.link}>
                            <IconsAdapter iconType={menuItem.icon} />

                            {menuItem.title}
                        </NavLink>
                    )}
                </Menu.Item>
            )),
        [handleShowModal, hasPermissionsRequired]
    )

    return (
        <Menu className={styles.wrapper} {...props}>
            {profileMenu}

            <Menu.Item key="exitItem">
                <a href="#s" onClick={handleLogout}>
                    <IconsAdapter iconType="LogoutOutlined" />

                    {LOCAL.EXIT}
                </a>
            </Menu.Item>
        </Menu>
    )
})
