import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UserPermission } from 'core/api'

import { ProfileMenuItemProps } from './ProfileMenuList.types'

export const PROFILE_MENU_ITEMS: ProfileMenuItemProps[] = [
    {
        title: LOCAL.LABELS.PROFILE,
        link: ROUTE_NAMES.USER_PROFILE,
        icon: 'UserOutlined',
        key: 'profile',
    },
    {
        title: LOCAL.CATALOGS.USER_PERSONAL_AREA,
        link: ROUTE_NAMES.USER_PERSONAL_AREA,
        icon: 'AppstoreOutlined',
        key: 'personal-area',
        permissions: [UserPermission.Lk],
    },
    {
        title: LOCAL.LABELS.SETTINGS,
        link: ROUTE_NAMES.NOTIFICATIONS_SETTINGS,
        icon: 'SettingOutlined',
        key: 'settings',
    },
    {
        title: LOCAL.LABELS.ABOUT_SYSTEM,
        link: '#',
        icon: 'InfoCircleOutlined',
        key: 'system',
    },
]
