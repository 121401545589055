import {
    ActivityCanvasDesignType,
    ActivityDecorationType,
    LessonAccessType,
} from 'core/api'
import { BLOCK_PICKER_COLORS } from 'components/controls/ColorPickerControl/ColorPickerControl.consts'
import { FONT_NAMES, FONT_SIZES, LINE_HEIGHTS, TextAlign } from 'consts'
import { LOCAL } from 'core/local'

import { EventFormTabType } from './EventForm.types'

export const EVENT_FORM_TABS_PANE_OPTIONS = {
    MAIN_OPTIONS: {
        tab: LOCAL.LABELS.COMMON_OPTIONS,
        key: EventFormTabType.MainOptions,
    },
    STRUCTURE: {
        tab: LOCAL.LABELS.STRUCTURE,
        key: EventFormTabType.Structure,
    },
    EVENT_VISUALIZATION: {
        tab: LOCAL.LABELS.EVENT_M_VISUALIZATION,
        key: EventFormTabType.EventVisualization,
    },
}

const initialTextSettings = {
    textAlign: TextAlign.Left,
    textColor: BLOCK_PICKER_COLORS.black,
    fontName: FONT_NAMES.Roboto,
    fontSize: FONT_SIZES['24'],
    fontStyle: 'normal',
    lineHeight: LINE_HEIGHTS['1'],
}

export const INITIAL_FORM_VALUES = {
    lessonAccessType: LessonAccessType.StepByStep,
    decorationType: ActivityDecorationType.Standard,
    canvas: {
        windowAlign: TextAlign.Left,
        showLines: true,
        canvasDesignType: ActivityCanvasDesignType.FullScreen,
        thematicBlockColorWhilePassing: BLOCK_PICKER_COLORS.blue,
        thematicBlockColorBeforePassing: BLOCK_PICKER_COLORS.blue,
        thematicBlockColorAfterPassing: BLOCK_PICKER_COLORS.green,
        thematicBlockIconColor: BLOCK_PICKER_COLORS.black,
        buttonAlign: TextAlign.Left,
        buttonColor: BLOCK_PICKER_COLORS.blue,
        buttonTextColor: BLOCK_PICKER_COLORS.white,
        titleSettings: {
            ...initialTextSettings,
            fontSize: FONT_SIZES['56'],
        },
        descriptionSettings: initialTextSettings,
        blockNameSettings: initialTextSettings,
        blockDescriptionSettings: initialTextSettings,
        listSettings: initialTextSettings,
    },
}
