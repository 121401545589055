import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DevelopmentGoalsSearchResultContract } from 'core/api'
import { LOCAL } from 'core/local'
import { defaultSorter } from 'components/layouts'
import {
    formatDate,
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { DevelopmentGoalsTableActions } from './components'

/** Настройки отображения колонок для таблицы реестра тематик */
export const DEVELOPMENT_GOALS_TABLE_COLUMNS: ColumnsType<DevelopmentGoalsSearchResultContract> = [
    {
        title: LOCAL.LABELS.DEVELOPMENT_GOAL_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.COMPETENCE,
        dataIndex: 'competences',
        key: 'competences',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <DevelopmentGoalsTableActions tableRow={tableRow} />
        ),
        align: 'right',
        fixed: 'right',
    },
]
