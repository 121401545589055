import isNil from 'lodash/isNil'
import {
    checkAllRequiredBlocksPassed,
    isThematicBlockStatusCompleted,
} from 'utils'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { useCallback, useContext, useEffect } from 'react'

import { ActivityChildProps } from './ActivityLayer.types'
import { TRACK_PASSING_EVENTS } from '../../TrackPassing.events'
import { TrackPassingContext } from '../../TrackPassing.context'
import {
    getInitialThematicBlockForPassing,
    isThematicBlockAvailableForRepassing,
} from './ActivityLayer.utils'

export const useCanvas = ({ activity }: ActivityChildProps) => {
    const { store, updateStore } = useContext(TrackPassingContext)

    const { trackInfo, activeLessonIndex, lessonsParents, lessonInfo } = store

    useEffect(() => {
        const index = getInitialThematicBlockForPassing(
            activity?.activityLessonPassings
        )

        updateStore({
            activeLessonIndex: index,
        })
    }, [activity, updateStore])

    const activeLesson =
        activity?.activityLessonPassings?.[activeLessonIndex || 0]

    const isThematicBlockCompletedAndNotAvailableForRepassing =
        isThematicBlockStatusCompleted(activeLesson?.status) &&
        !isThematicBlockAvailableForRepassing(activeLesson)

    const isThematicBlockLock = isNil(activeLesson?.status)

    const isAllRequiredBlocksCompleted = checkAllRequiredBlocksPassed(
        activity?.activityLessonPassings
    )

    const showFurtherButton =
        !trackInfo?.isTrackWithVisualisation && isAllRequiredBlocksCompleted

    const showReturnButton =
        trackInfo?.isTrackWithVisualisation ||
        (!isAllRequiredBlocksCompleted && lessonInfo?.nestedActivityPassing)

    const returnToPrevLayer = useCallback(() => {
        const parentLessonId =
            lessonsParents && lessonInfo
                ? Object.keys(lessonsParents).find((key) =>
                      lessonsParents[key].includes(lessonInfo.lessonId)
                  )
                : undefined

        if (lessonInfo?.nestedActivityPassing && parentLessonId) {
            eventEmitter.emit(
                TRACK_PASSING_EVENTS.GET_LESSON_INFO,
                parentLessonId
            )
        } else if (lessonInfo?.nestedActivityPassing && !parentLessonId) {
            eventEmitter.emit(TRACK_PASSING_EVENTS.CLOSE_LESSON)
        } else {
            eventEmitter.emit(TRACK_PASSING_EVENTS.CLOSE_STAGE)
        }
    }, [lessonInfo, lessonsParents])

    const showFinishActivityModal = useCallback(() => {
        eventEmitter.emit(TRACK_PASSING_EVENTS.SHOW_FINISH_ACTIVITY_MODAL)
    }, [])

    const startLesson = useCallback(() => {
        const isParentStage = !lessonInfo
        const isParentActivity = !!lessonInfo?.nestedActivityPassing

        eventEmitter.emit(TRACK_PASSING_EVENTS.START_LESSON, {
            lesson: activeLesson,
            activityPassingId: activity?.id,
            isParentStage,
            isParentActivity,
        })
    }, [activeLesson, activity, lessonInfo])

    return {
        activeLesson,
        isThematicBlockLock,
        isAllRequiredBlocksCompleted,
        isThematicBlockCompletedAndNotAvailableForRepassing,
        showFurtherButton,
        showReturnButton,
        activeLessonIndex,
        returnToPrevLayer,
        showFinishActivityModal,
        startLesson,
    }
}
