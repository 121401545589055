import React from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DevelopmentTracksService,
    TrackSearchContract,
    TrackSearchResultContract,
    UserPermission,
} from 'core/api'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CREATION_DATE,
} from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TRACK_DEVELOPMENT_PERMISSIONS } from 'consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'
import { getRowHandlers } from 'utils'

import { DEVELOPMENT_TRACKS_TABLE_COLUMNS } from './TracksDevelopment.consts'
import { TrackPageFilters } from '../TracksAdaptation/components'

export const TracksDevelopment: React.FC = React.memo(() => (
    <DictionaryPage<TrackSearchContract, TrackSearchResultContract>
        dictionaryKey={DICTIONARY_KEYS.DEVELOPMENT_TRACKS}
        searchMethod={DevelopmentTracksService.search}
        deleteMethod={DevelopmentTracksService.setAsArchive}
        contentTitle={getRouteMeta(ROUTE_NAMES.TRACKS_DEVELOPMENT)}
        tableColumns={DEVELOPMENT_TRACKS_TABLE_COLUMNS}
        createRecordLink={{
            path: ROUTE_NAMES.TRACKS_DEVELOPMENT_CREATE,
        }}
        filterComponent={TrackPageFilters}
        tableFiltersOptions={{
            initialValues: {
                orderRules: ORDER_RULES_WITH_CREATION_DATE,
            },
        }}
        quickSearchMethod={DevelopmentTracksService.searchText}
        createPermissions={[
            ...TRACK_DEVELOPMENT_PERMISSIONS,
            UserPermission.DevelopmentTrackSave,
        ]}
        rowClassName={getRowClassNameArchive}
        onRow={getRowHandlers({ url: ROUTE_NAMES.TRACKS_DEVELOPMENT_VIEW })}
    />
))
