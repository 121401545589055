import React, { useCallback } from 'react'
import {
    AppointmentTracksProcessTypes,
    downloadXlsxReport,
    isAppointmentTrackStatusAppointment,
    isAppointmentTrackStatusSuspended,
} from 'utils'
import {
    AssignmentsProgressAction,
    ButtonsToolbar,
    TableActions,
} from 'components/shared'
import { QuizReportsService, UserPermission } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS } from 'consts'

import { TrackAssignmentsProgressTable } from '../TrackAssignmentsProgressTable'
import { TrackAssignmentsTableActionsProps } from './TrackAssignmentsTableActions.types'

/** Компонент для создания колонки действий внутри таблицы реестра назначений треков */
export const TrackAssignmentsTableActions: React.FC<TrackAssignmentsTableActionsProps> = React.memo(
    ({
        tableRow,
        reFetchDictionary,
        appointmentTracksProcessService,
        updateLoader,
    }) => {
        const isAvailableToAction =
            isAppointmentTrackStatusAppointment(tableRow.status) ||
            isAppointmentTrackStatusSuspended(tableRow.status)

        const isDeletable =
            !isAppointmentTrackStatusSuspended(tableRow.status) &&
            !isAppointmentTrackStatusAppointment(tableRow.status)

        /**
         * Обработчик выбора экшена процесса прохождения назначения треков
         * @param action - выбранный экшен
         */
        const handleChooseAction = useCallback(
            (action: AppointmentTracksProcessTypes) => async () => {
                try {
                    updateLoader?.(true)

                    appointmentTracksProcessService(tableRow.id, action).then(
                        reFetchDictionary
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                appointmentTracksProcessService,
                reFetchDictionary,
                tableRow.id,
                updateLoader,
            ]
        )

        /**
         * Скачать отчет
         */
        const downloadReport = useCallback(async () => {
            try {
                updateLoader?.(true)

                const file = await QuizReportsService.getXlsxReportByTrack(
                    { appointmentTrackId: tableRow.id },
                    { responseType: 'blob' }
                )

                downloadXlsxReport(file)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader?.(false)
            }
        }, [updateLoader, tableRow.id])

        return (
            <ButtonsToolbar bottomIndent="0">
                <TableActions.View
                    requiredPermissions={TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS}
                    href={`${ROUTE_NAMES.TRACKS_ASSIGNMENTS_VIEW}/${tableRow.id}`}
                    disabled={!tableRow.active}
                />

                {tableRow.active && (
                    <>
                        {isAvailableToAction && (
                            <AssignmentsProgressAction
                                initialValues={tableRow}
                                reFetchDictionary={reFetchDictionary}
                                component={TrackAssignmentsProgressTable}
                            />
                        )}

                        <TableActions.Edit
                            requiredPermissions={[
                                ...TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                UserPermission.TrackAppointmentSave,
                            ]}
                            href={`${ROUTE_NAMES.TRACKS_ASSIGNMENTS_EDIT}/${tableRow.id}`}
                        />

                        {isAppointmentTrackStatusSuspended(tableRow.status) && (
                            <TableActions.Play
                                requiredPermissions={
                                    TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS
                                }
                                onClick={handleChooseAction(
                                    AppointmentTracksProcessTypes.Resume
                                )}
                            />
                        )}

                        {isAppointmentTrackStatusAppointment(
                            tableRow.status
                        ) && (
                            <TableActions.Pause
                                requiredPermissions={
                                    TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS
                                }
                                onClick={handleChooseAction(
                                    AppointmentTracksProcessTypes.Suspend
                                )}
                            />
                        )}

                        {isAvailableToAction && (
                            <TableActions.Stop
                                requiredPermissions={
                                    TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS
                                }
                                onClick={handleChooseAction(
                                    AppointmentTracksProcessTypes.Cancel
                                )}
                            />
                        )}

                        {tableRow.hasFeedbackQuizPassed && (
                            <TableActions.Export onClick={downloadReport} />
                        )}

                        <TableActions.Copy
                            requiredPermissions={[
                                ...TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                UserPermission.TrackAppointmentSave,
                            ]}
                            href={`${ROUTE_NAMES.TRACKS_ASSIGNMENTS_COPY}/${tableRow.id}`}
                        />

                        {isDeletable && (
                            <TableActions.Delete
                                tableRow={tableRow}
                                requiredPermissions={[
                                    ...TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.TrackAppointmentDelete,
                                ]}
                            />
                        )}
                    </>
                )}
            </ButtonsToolbar>
        )
    }
)
