import React from 'react'
import { Col, Row } from 'antd'
import { ProductCardChildrenProps } from 'components/pages/ProductCard/ProductCard.types'
import { ROW_GUTTER } from 'consts'

import styles from './ProductCardFooter.module.scss'
import { ProductCardCertificate } from '../ProductCardCertificate'
import { ProductCardContent } from '../ProductCardContent'

/** Карточка продукта - блок "чему вы научитесь" */
export const ProductCardFooter: React.FC<ProductCardChildrenProps> = React.memo(
    ({ pageInfo }) => (
        <Row
            className={styles.wrapper}
            gutter={ROW_GUTTER}
            justify="space-between"
        >
            <Col flex={1}>
                {!!pageInfo?.sections.length && (
                    <ProductCardContent sections={pageInfo.sections} />
                )}
            </Col>

            <Col>
                {pageInfo && (
                    <ProductCardCertificate
                        graduationDocumentType={pageInfo.graduationDocumentType}
                    />
                )}
            </Col>
        </Row>
    )
)
