import React from 'react'
import { AppointmentContract, AppointmentsService } from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { FormContainer } from 'components/layouts'

import {
    CANCEL_BTN_OPTIONS,
    getPublishBtnOptions,
    getSaveBtnOptions,
} from './QuizAssignments.config'
import { QuizAssignmentForm } from './components'

/**
 * Создание назначения опроса
 */
export const QuizAssignmentFormContainer: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <FormContainer<AppointmentContract>
            formId={FORM_IDS.QUIZ_ASSIGNMENTS_FORM}
            formMode={formMode}
            formComponent={QuizAssignmentForm}
            requestMethod={AppointmentsService.getAppointment}
            cancelBtnOptions={CANCEL_BTN_OPTIONS}
            saveBtnOptions={getSaveBtnOptions}
            publishBtnOptions={getPublishBtnOptions}
        />
    )
)
