import React from 'react'
import isNil from 'lodash/isNil'
import {
    AssessmentType,
    CompetenceContract,
    DocumentStatus,
    QuestionnaireSelectSearchContract,
    QuestionnaireType,
    QuestionnairesService,
} from 'core/api'
import { ORDER_RULES_WITH_CREATION_DATE } from 'components/layouts'
import { normalizeDataForSelectAndRadio } from 'utils'

import {
    CompetenceFormContract,
    QuestionnairesState,
    UpdatedManagerParametersProps,
} from './ManagerParametersRow.types'

/** нормализация данных, полученных через `getEntitiesByIds`, для `value` и внутреннего сейта компонента */
export const normalizeFetchDataForValue = (
    newData: CompetenceContract[],
    oldData?: CompetenceFormContract[]
): CompetenceFormContract[] =>
    newData.map((el) => ({
        id: el.id,
        name: el.name,
        managerQuestionnaireId: oldData?.find((oldEl) => oldEl.id === el.id)
            ?.managerQuestionnaireId,
        selfQuestionnaireId: oldData?.find((oldEl) => oldEl.id === el.id)
            ?.selfQuestionnaireId,
    }))

/**
 * Запрос справочника опросных листов по id компетенции
 */
export const questionnairesFetch = async (
    assessmentType: AssessmentType,
    competenceId: number,
    setQuestionnaires: React.Dispatch<
        React.SetStateAction<QuestionnairesState | undefined>
    >,
    currentQuestionnaires?: QuestionnairesState
) => {
    try {
        if (!competenceId || currentQuestionnaires?.[competenceId]?.length)
            return

        const questionnaires = await QuestionnairesService.getForSelect({
            body: {
                questionnaireType: QuestionnaireType.Assessment,
                assessmentType,
                competenceIds: [competenceId],
                orderRules: ORDER_RULES_WITH_CREATION_DATE,
                excludeStatuses: [DocumentStatus.Draft],
            } as QuestionnaireSelectSearchContract,
        })

        const selectOptions = questionnaires.map(normalizeDataForSelectAndRadio)

        setQuestionnaires((prevState) => ({
            ...prevState,
            [competenceId]: selectOptions,
        }))
    } catch (error) {
        console.error(error)
    }
}

export const updatedManagerParameters = ({
    getFieldValue,
    hasSelfQuestionnaires,
    fieldName,
    form,
}: UpdatedManagerParametersProps) => {
    const managerParameters = getFieldValue('managerParameters')

    if (!isNil(hasSelfQuestionnaires)) {
        const changedEl = managerParameters[fieldName]

        managerParameters[fieldName] = {
            ...changedEl,
            questionnaires: changedEl.questionnaires?.map((el: any) => ({
                ...el,
                selfQuestionnaireId: changedEl.hasSelfQuestionnaires
                    ? el.selfQuestionnaireId
                    : undefined,
            })),
        }

        form?.setFieldsValue({ managerParameters })
    }
}
