import React from 'react'
import { CircleAssessmentPortfolioQuestionnaireContract } from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'

import styles from './AssessmentProjectsPortfolioCompetencies.module.scss'

export const ASSESSMENT_PORTFOLIO_COMPETENCIES_COLUMNS: ColumnsType<CircleAssessmentPortfolioQuestionnaireContract> = [
    {
        title: LOCAL.LABELS.COMPETENCIES,
        dataIndex: ['competence', 'name'],
    },
    {
        title: LOCAL.LABELS.ASSESSMENT_SELF,
        dataIndex: 'selfQuestionnaire',
        render: (_, row) => (
            <>
                <span>{row.selfQuestionnaire.name}</span>

                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW}/${row.selfQuestionnaire.id}`}
                >
                    <IconsAdapter
                        iconType="EyeOutlined"
                        className={styles.link}
                    />
                </a>
            </>
        ),
    },
    {
        title: LOCAL.LABELS.RESPONDENTS,
        dataIndex: 'respondentQuestionnaire',
        render: (_, row) => (
            <>
                <span>{row.respondentQuestionnaire?.name}</span>

                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW}/${row.respondentQuestionnaire.id}`}
                >
                    <IconsAdapter
                        iconType="EyeOutlined"
                        className={styles.link}
                    />
                </a>
            </>
        ),
    },
]
