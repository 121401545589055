import cloneDeep from 'lodash/cloneDeep'
import template from 'lodash/template'
import React, { useCallback, useEffect } from 'react'
import { FORM_IDS } from 'core/configs'
import { Form, Tabs } from 'antd'
import { HiddenField, ShouldUpdateChecker } from 'components/controls'
import { InitialValuesBaseType } from 'components/pages/Track/Track.types'
import { LOCAL } from 'core/local'
import { TRACK_COMPONENT_CONFIG } from 'components/pages/Track/Track.consts'
import { TestSequence } from 'core/api'
import {
    createConfirmPopup,
    getFormPopupTitle,
    isDocumentStatusDraft,
    isDocumentStatusInWork,
    isDocumentStatusReadyForWork,
    isFormModeEdit,
    isFormModeView,
    isObjectEmpty,
    isPassingTypeConsistently,
    setParentIdForCopy,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'
import { withHeightResize, withLoader } from 'HOCs'

import styles from './TrackForm.module.scss'
import { INITIAL_FORM_VALUES_DEFAULT } from './TrackForm.consts'
import { MapFormDataToRequestProps, TrackFormProps } from './TrackForm.types'
import {
    addAssistantsEventsToStage,
    getTabMinHeight,
    mapFormDataToRequest,
    setStagesPassingRequired,
    synchronizeStagesAndCanvases,
    updateStateOfPreviewBtn,
} from './TrackForm.utils'
import { getTabPaneOptions } from './TrackForm.utils.tabs'
import {
    registerUpdateStateOfPreviewBtn,
    unRegisterUpdateStateOfPreviewBtn,
} from './TrackForm.events'

/** Компонент-шаблон для формы треков */
function TrackFormPlain<Response extends InitialValuesBaseType, Request>({
    setPreviewReady,
    isSaveDocument,
    initialValuesForEdit,
    onBlockUserRouting,
    formMode,
    form,
    componentRef,
    componentHeight,
    trackType,
    saveFn,
    publishFn,
    createNewVersionFn,
    updateLoader,
}: TrackFormProps<Response, Request>) {
    const history = useHistory()

    const disabledByStatus =
        isFormModeEdit(formMode) &&
        isDocumentStatusInWork(initialValuesForEdit?.status)
    /**
     * Функция отправки данных формы
     */
    const handleFinish = useCallback(
        async (values) => {
            try {
                updateLoader?.(true)

                const afterSubmitFn = (newVersionId: number) => {
                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(
                                TRACK_COMPONENT_CONFIG[trackType].PARENT
                            )
                        ),
                        content: isSaveDocument
                            ? template(
                                  LOCAL.MESSAGES.SAVE_SUCCESS_MESSAGE_MALE
                              )({ entity: LOCAL.LABELS.TRACK })
                            : template(
                                  LOCAL.MESSAGES.PUBLISH_SUCCESS_MESSAGE_MALE
                              )({ entity: LOCAL.LABELS.TRACK }),
                        onOk: () => {
                            history.push(
                                TRACK_COMPONENT_CONFIG[trackType].PARENT
                            )
                        },
                        onCancel: () => {
                            if (values.id) {
                                history.push(
                                    `${TRACK_COMPONENT_CONFIG[trackType].ROUTE}/${newVersionId}`
                                )
                            } else {
                                form.resetFields()
                                form.setFieldsValue(INITIAL_FORM_VALUES_DEFAULT)
                            }
                        },
                    })
                }

                const { status, ...body } = setParentIdForCopy(
                    mapFormDataToRequest(values),
                    formMode
                )

                const isCanPublish =
                    isDocumentStatusInWork(status) ||
                    isDocumentStatusDraft(status) ||
                    status == null
                const isCanCreateNewVersion = isDocumentStatusReadyForWork(
                    status
                )

                if (isSaveDocument) {
                    const { id: newVersionId } = await saveFn(body as Request)
                    afterSubmitFn(newVersionId)
                }

                if (isCanPublish && !isSaveDocument) {
                    const { id: newVersionId } = await publishFn(
                        body as Request
                    )
                    afterSubmitFn(newVersionId)
                }

                if (isCanCreateNewVersion && !isSaveDocument) {
                    createConfirmPopup({
                        title:
                            LOCAL.MESSAGES.CREATE_NEW_VERSION_CONFIRM_MESSAGE,
                        content: '',
                        onOk: async () => {
                            const {
                                id: newVersionId,
                            } = await createNewVersionFn(body as Request)
                            afterSubmitFn(newVersionId)
                        },
                        cancelText: LOCAL.ACTIONS.CANCEL,
                    })
                }

                onBlockUserRouting?.(false)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader?.(false)
            }
        },
        [
            updateLoader,
            isSaveDocument,
            onBlockUserRouting,
            trackType,
            history,
            form,
            saveFn,
            publishFn,
            createNewVersionFn,
            formMode,
        ]
    )

    const handleUpdateStateOfPreviewBtn = useCallback(
        (dataToRequest: MapFormDataToRequestProps) => {
            updateStateOfPreviewBtn({
                dataToRequest,
                setPreviewReady,
            })
        },
        [setPreviewReady]
    )

    /**
     * Обработчик изменений формы
     */
    const handleValuesChange = useCallback(
        (
            changedValues: MapFormDataToRequestProps,
            allValues: MapFormDataToRequestProps
        ) => {
            const [changedValueKey] = Object.keys(changedValues)

            if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                onBlockUserRouting?.()
            }

            if (changedValueKey === 'useInformationAssistant') {
                changedValues.useInformationAssistant &&
                    addAssistantsEventsToStage(form, allValues.stages)
            }

            if (
                changedValueKey === 'isTrackWithVisualisation' &&
                !changedValues.isTrackWithVisualisation
            ) {
                form.setFieldsValue({
                    passingType: TestSequence.Consistently,
                    isTrackWithLogo: false,
                    stages: setStagesPassingRequired(allValues.stages),
                })
            }

            if (
                changedValueKey === 'passingType' &&
                isPassingTypeConsistently(changedValues.passingType)
            ) {
                form.setFieldsValue({
                    stages: setStagesPassingRequired(allValues.stages),
                })
            }

            if (
                changedValueKey === 'isTrackWithLogo' &&
                !changedValues.isTrackWithLogo
            ) {
                form.setFieldsValue({
                    trackHeaderIcons: null,
                })
            }

            if (changedValueKey === 'stages') {
                const stages = allValues.stages?.filter(
                    (el) => !isObjectEmpty(el, true)
                )

                synchronizeStagesAndCanvases(form, undefined, stages)
            }

            handleUpdateStateOfPreviewBtn(allValues)
        },
        [form, formMode, onBlockUserRouting, handleUpdateStateOfPreviewBtn]
    )

    /**
     * Установка начальных значений формы
     */
    useEffect(() => {
        const result = !isObjectEmpty(initialValuesForEdit, true)
            ? initialValuesForEdit
            : cloneDeep(INITIAL_FORM_VALUES_DEFAULT)

        form.setFieldsValue({
            ...result,
            trackType,
            formTempStore: {
                trackCanvases: initialValuesForEdit?.trackCanvases,
            },
        })

        if (!result) return

        handleUpdateStateOfPreviewBtn(result as MapFormDataToRequestProps)
    }, [form, initialValuesForEdit, trackType, handleUpdateStateOfPreviewBtn])

    useEffect(() => {
        registerUpdateStateOfPreviewBtn(handleUpdateStateOfPreviewBtn)

        return () => {
            unRegisterUpdateStateOfPreviewBtn(handleUpdateStateOfPreviewBtn)
        }
    }, [handleUpdateStateOfPreviewBtn])

    return (
        <Form
            id={FORM_IDS.TRACK_FORM}
            onFinish={handleFinish}
            onValuesChange={handleValuesChange}
            form={form}
        >
            {/* В контрактах не фигурирует, поле необходимо для временного хранения начальных значений
            формы полученных при редактировании, либо загрузке готового шаблона для формы. Это дает возможность
            получить к нему доступ через контекст формы, мануально обновлять значение поля из любых дочерних
            компонентов, прослушивать изменения значений поля, не затрагивая основные поля формы, что позволяет
            избежать нежелательных ререндеров. */}

            <HiddenField fieldName="formTempStore" />

            <HiddenField fieldName="id" />

            <HiddenField fieldName="status" />

            <HiddenField fieldName="trackCanvases" />

            <HiddenField fieldName="trackBackgroundPictureId" />

            {/* В контрактах не фигурирует, поле необходимо для технических
            целей, т.к. форма используется для двух типов треков: адаптация и развитие,
            набор полей будет немного отличаться */}

            <HiddenField fieldName="trackType" />

            <div ref={componentRef}>
                <ShouldUpdateChecker
                    fieldPath={[
                        'isTrackWithVisualisation',
                        [
                            'trackMenuVisualization',
                            'isAdditionalMaterialsVisible',
                        ],
                    ]}
                >
                    {(form) => (
                        <Tabs size="large" type="card" className={styles.tabs}>
                            {getTabPaneOptions(
                                form,
                                formMode,
                                disabledByStatus
                            ).map(
                                (tab) =>
                                    tab.visible && (
                                        <Tabs.TabPane
                                            forceRender
                                            style={getTabMinHeight(
                                                componentHeight
                                            )}
                                            tab={tab.tab}
                                            key={tab.key}
                                        >
                                            {tab.content}
                                        </Tabs.TabPane>
                                    )
                            )}
                        </Tabs>
                    )}
                </ShouldUpdateChecker>
            </div>
        </Form>
    )
}

type TrackFormPlainType = typeof TrackFormPlain

export const TrackForm = React.memo(
    withHeightResize(withLoader(TrackFormPlain) as TrackFormPlainType)
) as TrackFormPlainType
