/**
 * Поменять порядок рядов в списке
 * @param startIndex стартовая позиция
 * @param endIndex конечная позиция
 * @param list массив для перестановки
 */
export const reorderDnDRow = <T>(
    startIndex: number,
    endIndex?: number,
    list?: T[]
) => {
    if (!list || endIndex == null) return

    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}
