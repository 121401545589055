import React from 'react'
import { Title } from 'components/shared'

import styles from './AppointmentsCardsTitle.module.scss'
import { AppointmentsCardsTitleProps } from './AppointmentsCardsTitle.types'

/**
 * Компонент заголовка для группы процессов на странице "Ваше развитие"
 */
export const AppointmentsCardsTitle: React.FC<AppointmentsCardsTitleProps> = React.memo(
    ({ text, count }) => (
        <Title
            type="h4"
            bottomIndent="0"
            text={
                <>
                    {text}

                    <span className={styles.count}>{`(${count})`}</span>
                </>
            }
            className={styles.wrapper}
        />
    )
)
