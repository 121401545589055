import React, { ReactNode } from 'react'
import { ColumnsType } from 'antd/lib/table/interface'
import { FnActionRequiredProps } from 'App.types'
import { LOCAL } from 'core/local'
import { restrictBigText } from 'utils'

import { MembersTableItemProps } from './DynamicFormRows.types'

export const getTableColumns = (
    getTableActions: FnActionRequiredProps<MembersTableItemProps, ReactNode>
): ColumnsType<MembersTableItemProps> => [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'fio',
        key: 'fio',
        render: (value, { rowSpan }) => ({
            children: value,
            props: { rowSpan },
        }),
    },
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'position',
        key: 'position',
        width: '25%',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'structuralUnit',
        key: 'structuralUnit',
        width: '25%',
        render: (structuralUnit) => (
            <>
                {structuralUnit.organization &&
                    restrictBigText(structuralUnit.organization)}

                {structuralUnit.unit && restrictBigText(structuralUnit.unit)}
            </>
        ),
    },
    {
        title: '',
        dataIndex: '',
        width: 100,
        render: (tableRow: MembersTableItemProps) => ({
            children: getTableActions(tableRow),
            props: { rowSpan: tableRow.rowSpan },
        }),
    },
]
