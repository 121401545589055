import 'rrweb-player/dist/style.css'

import cn from 'classnames'
import rrwebPlayer from 'rrweb-player'
import React, { useEffect, useRef } from 'react'

import styles from './RrwebPlayer.module.scss'
import { RrwebPlayerProps } from './RrwebPlayer.types'

/** Плеер для пакета RRWEB */
export const RrwebPlayer: React.FC<RrwebPlayerProps> = React.memo(
    ({ events, className }) => {
        const playerRef = useRef<HTMLDivElement>(null)

        useEffect(() => {
            if (!playerRef.current) return

            new rrwebPlayer({
                target: playerRef.current,
                props: {
                    events,
                },
            })
        }, [events])

        return (
            <div className={cn(styles.wrapper, className)}>
                <div ref={playerRef} className="rr-block" />
            </div>
        )
    }
)
