import TableActions from 'components/shared/TableActions/TableActions'
import React, { useCallback } from 'react'
import {
    AppointmentProcessTypes,
    downloadXlsxReport,
    isAppointmentStatusDraft,
    isAppointmentStatusEditable,
    isAppointmentStatusExpectation,
    isAppointmentStatusInProgress,
    isAppointmentStatusSuspended,
    isAvailableToWatch,
} from 'utils'
import { AssignmentsProgressAction, ButtonsToolbar } from 'components/shared'
import { LOCAL } from 'core/local'
import {
    QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS,
    QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
} from 'consts'
import { QuizReportsService, UserPermission } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getConfirmEditMessage } from 'core/local/messages.local.config'
import { useConfirmRedirect } from 'hooks'

import { QuizAssignmentsProgressTable } from '../QuizAssignmentsProgressTable'
import { QuizAssignmentsTableActionsProps } from './QuizAssignmentsTableActions.types'

/** Компонент для создания колонки действий внутри таблицы реестра назначений опроса */
export const QuizAssignmentsTableActions: React.FC<QuizAssignmentsTableActionsProps> = React.memo(
    ({
        tableRow,
        reFetchDictionary,
        appointmentProcessService,
        updateLoader,
    }) => {
        const isDeletable =
            !isAppointmentStatusSuspended(tableRow.status) &&
            !isAppointmentStatusExpectation(tableRow.status) &&
            !isAppointmentStatusInProgress(tableRow.status)

        const isCanStop =
            isAvailableToWatch(tableRow.status) &&
            !isAppointmentStatusSuspended(tableRow.status)

        const isEditable = isAppointmentStatusEditable(tableRow.status)

        const { handleRedirect } = useConfirmRedirect({
            to: `${ROUTE_NAMES.QUIZ_ASSIGNMENTS_EDIT}/${tableRow.id}`,
            needConfirm: !isAppointmentStatusDraft(tableRow.status),
            confirmText: getConfirmEditMessage('quiz', true),
        })

        /**
         * Обработчик выбора экшена процесса прохождения назначения
         * @param action - выбранный экшен
         */
        const handleChooseAction = useCallback(
            (action: AppointmentProcessTypes) => async () => {
                try {
                    updateLoader?.(true)

                    appointmentProcessService(tableRow.id, action).then(
                        reFetchDictionary
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                appointmentProcessService,
                reFetchDictionary,
                tableRow.id,
                updateLoader,
            ]
        )

        /**
         * Скачать отчет
         */
        const downloadReport = useCallback(async () => {
            try {
                updateLoader?.(true)

                const file = await QuizReportsService.getXlsxReport(
                    { appointmentId: tableRow.id },
                    { responseType: 'blob' }
                )

                downloadXlsxReport(file)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader?.(false)
            }
        }, [updateLoader, tableRow.id])

        return (
            <ButtonsToolbar bottomIndent="0">
                <TableActions.View
                    requiredPermissions={QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS}
                    conditionalPermissions={
                        QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS
                    }
                    href={`${ROUTE_NAMES.QUIZ_ASSIGNMENTS_VIEW}/${tableRow.id}`}
                    disabled={!tableRow.active}
                />

                {tableRow.active && (
                    <>
                        {isAvailableToWatch(tableRow.status) && (
                            <AssignmentsProgressAction
                                requiredPermissions={[
                                    ...QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.QuizAppointmentMonitoring,
                                ]}
                                conditionalPermissions={
                                    QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS
                                }
                                initialValues={tableRow}
                                reFetchDictionary={reFetchDictionary}
                                component={QuizAssignmentsProgressTable}
                            />
                        )}

                        {isEditable && (
                            <TableActions.Edit
                                requiredPermissions={[
                                    ...QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.QuizAppointmentSave,
                                ]}
                                conditionalPermissions={
                                    QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS
                                }
                                onClick={handleRedirect}
                            />
                        )}

                        {isAppointmentStatusSuspended(tableRow.status) && (
                            <TableActions.Play
                                requiredPermissions={[
                                    ...QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.QuizAppointmentContinue,
                                ]}
                                conditionalPermissions={
                                    QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS
                                }
                                onClick={handleChooseAction(
                                    AppointmentProcessTypes.CancelSuspension
                                )}
                            />
                        )}

                        {isCanStop &&
                            !isAppointmentStatusSuspended(tableRow.status) && (
                                <TableActions.Pause
                                    requiredPermissions={[
                                        ...QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                        UserPermission.QuizAppointmentPause,
                                    ]}
                                    conditionalPermissions={
                                        QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS
                                    }
                                    onClick={handleChooseAction(
                                        AppointmentProcessTypes.Suspend
                                    )}
                                />
                            )}

                        {isAvailableToWatch(tableRow.status) && (
                            <TableActions.Stop
                                requiredPermissions={[
                                    ...QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.QuizAppointmentStop,
                                ]}
                                conditionalPermissions={
                                    QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS
                                }
                                onClick={handleChooseAction(
                                    AppointmentProcessTypes.Cancel
                                )}
                            />
                        )}

                        {tableRow.isShowReport && (
                            <>
                                <TableActions.Report
                                    requiredPermissions={
                                        QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS
                                    }
                                    conditionalPermissions={
                                        QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS
                                    }
                                    href={`${ROUTE_NAMES.QUIZ_REPORT}/${tableRow.id}`}
                                    title={LOCAL.ACTIONS.GENERATE_REPORT}
                                />

                                <TableActions.Export
                                    requiredPermissions={
                                        QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS
                                    }
                                    onClick={downloadReport}
                                />
                            </>
                        )}

                        {isDeletable && (
                            <TableActions.Delete
                                tableRow={tableRow}
                                requiredPermissions={[
                                    ...QUIZ_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.QuizAppointmentDelete,
                                ]}
                                conditionalPermissions={
                                    QUIZ_ASSIGNMENTS_CONDITIONAL_PERMISSIONS
                                }
                            />
                        )}
                    </>
                )}
            </ButtonsToolbar>
        )
    }
)
