import React from 'react'
import { Tabs } from 'antd'

import {
    DEFAULT_TAB_KEY,
    TABS_PANE_OPTIONS,
} from './PortfolioFormCommonTabs.consts'
import { PortfolioPresentation } from './PortfolioPresentation'

/** Табы для форм портфелей оценки/теста/опроса */
export const PortfolioFormCommonTabs: React.FC = React.memo(({ children }) => (
    <Tabs defaultActiveKey={String(DEFAULT_TAB_KEY)} size="large" type="card">
        <Tabs.TabPane forceRender {...TABS_PANE_OPTIONS.SETTINGS}>
            {children}
        </Tabs.TabPane>

        <Tabs.TabPane forceRender {...TABS_PANE_OPTIONS.VISUALIZATION}>
            <PortfolioPresentation />
        </Tabs.TabPane>
    </Tabs>
))
