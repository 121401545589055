import React, { FC } from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    ThemeContract,
    ThemesSearchContract,
    ThemesService,
    UserPermission,
} from 'core/api'
import { getRouteMeta } from 'routing/routeNames.utils'

import {
    CREATE_RECORD_MODAL_PROPS,
    TABLE_FILTERS_OPTIONS_PROPS,
    THEMES_TABLE_COLUMNS,
} from './Themes.config'
import { ThemesFilters } from './components'

/** Справочник тематик */
export const Themes: FC = React.memo(() => (
    <DictionaryPage<ThemesSearchContract, ThemeContract>
        dictionaryKey={DICTIONARY_KEYS.THEMES}
        searchMethod={ThemesService.search}
        deleteMethod={ThemesService.delete}
        contentTitle={getRouteMeta(ROUTE_NAMES.THEMES)}
        tableColumns={THEMES_TABLE_COLUMNS}
        createRecordModal={CREATE_RECORD_MODAL_PROPS}
        tableFiltersOptions={TABLE_FILTERS_OPTIONS_PROPS}
        filterComponent={ThemesFilters}
        quickSearchMethod={ThemesService.searchText}
        createPermissions={[UserPermission.ThemeCreate]}
    />
))
