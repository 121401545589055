import React, { useCallback, useEffect, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { ComponentCommonProps } from 'App.types'
import {
    EntityType,
    QuizPortfolioContract,
    QuizPortfoliosService,
} from 'core/api'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import {
    QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS,
    QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS,
} from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    UrlParamsForEditProps,
    useFormInitialValues,
    useSaveOrPublishState,
} from 'hooks'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import {
    editSessionCancel,
    isDocumentStatusInWork,
    isDocumentStatusReadyForWork,
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useParams } from 'react-router-dom'

import { QuizPortfolioForm } from './components'

/** Страница создания и редактирования портфеля опроса */
const QuizPortfolioFormContainer: React.FC<
    WithBlockUserRoutingComponentProps & ComponentCommonProps
> = withLoader(({ onBlockUserRouting, formMode, updateLoader, isLoading }) => {
    const urlParams = useParams<UrlParamsForEditProps>()

    const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()

    const {
        initialValuesForEdit: initialValuesForEditState,
        fetchInitialFormValuesById,
    } = useFormInitialValues<QuizPortfolioContract>({
        requestMethod: isFormModeEdit(formMode)
            ? QuizPortfoliosService.getForEdit
            : QuizPortfoliosService.getActive,
        updateLoader,
    })

    const initialValuesForEdit = useMemo(():
        | QuizPortfolioContract
        | undefined => {
        if (!initialValuesForEditState) return

        const { status, ...initialValuesForEdit } = initialValuesForEditState

        if (isFormModeCopy(formMode))
            return initialValuesForEdit as QuizPortfolioContract

        return { ...initialValuesForEdit, status }
    }, [formMode, initialValuesForEditState])

    const refuseEdit = useCallback(() => {
        editSessionCancel({
            type: EntityType.QuizPortfolio,
            id: initialValuesForEdit?.id,
        })
    }, [initialValuesForEdit])

    /**
     * Запрашиваем initial values для формы при наличии id
     */
    useEffect(() => {
        if (urlParams.id) fetchInitialFormValuesById(Number(urlParams.id))
    }, [fetchInitialFormValuesById, urlParams.id])

    const isSavable = useMemo(
        () =>
            !isDocumentStatusReadyForWork(initialValuesForEdit?.status) &&
            !isDocumentStatusInWork(initialValuesForEdit?.status),
        [initialValuesForEdit]
    )

    return (
        <PageContent>
            <ContentTitleWithBackLink
                status={initialValuesForEdit?.status}
                onBack={refuseEdit}
            >
                <ButtonsToolbar>
                    <Button
                        type="link"
                        href={ROUTE_NAMES.QUIZ_PORTFOLIOS}
                        onClick={refuseEdit}
                    >
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    {isSavable && (
                        <Button
                            htmlType="submit"
                            type="primary"
                            form={FORM_IDS.QUIZ_PORTFOLIO_FORM}
                            onClick={handleSubmitForm(true)}
                            disabled={isFormModeView(formMode) || isLoading}
                        >
                            {LOCAL.ACTIONS.SAVE}
                        </Button>
                    )}

                    <HasPermissions
                        requiredPermissions={
                            QUIZ_PORTFOLIOS_REQUIRED_PERMISSIONS
                        }
                        conditionalPermissions={
                            QUIZ_PORTFOLIOS_CONDITIONAL_PERMISSIONS
                        }
                        accessDeniedRender={renderAccessDeniedButton({
                            text: LOCAL.ACTIONS.PUBLISH,
                            type: 'primary',
                        })}
                    >
                        <Button
                            htmlType="submit"
                            type="primary"
                            form={FORM_IDS.QUIZ_PORTFOLIO_FORM}
                            onClick={handleSubmitForm(false)}
                            disabled={isFormModeView(formMode) || isLoading}
                        >
                            {LOCAL.ACTIONS.PUBLISH}
                        </Button>
                    </HasPermissions>
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <QuizPortfolioForm
                updateLoader={updateLoader}
                onBlockUserRouting={onBlockUserRouting}
                initialValuesForEdit={initialValuesForEdit}
                isSaveDocument={isSaveDocument}
                reFetchInitialFormValues={fetchInitialFormValuesById}
                formMode={formMode}
            />
        </PageContent>
    )
})

export default React.memo(
    withBlockUserRouting(QuizPortfolioFormContainer, false)
) as typeof QuizPortfolioFormContainer
