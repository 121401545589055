import { CascadeSelectDataUnitProps } from './AdjacentCompetenciesSelect.types'

/**
 * Нормализация данных для использования в компонента каскадного выбора
 * @param item - элемент подлежащий нормализации
 */
export const normalizeDataToAdjacentCompetenciesTable = <
    T extends { id: number; name: string }
>(
    item: T
): CascadeSelectDataUnitProps => ({
    key: item.id,
    label: item.name,
})
