import React from 'react'
import { ControlGroup, EditableTextControl } from 'components/controls'
import { Form } from 'antd'
import { LOCAL } from 'core/local'

import styles from './ResultAssessment.module.scss'
import { ResultAssessmentType } from '../ResultAssessmentType'

export const ResultAssessment: React.FC = React.memo(() => (
    <div className={styles.wrapper}>
        <ControlGroup
            title={LOCAL.LABELS.RESULT_ASSESSMENT}
            type="bordered"
            required
        >
            <Form.Item
                name="maxPoints"
                label={LOCAL.LABELS.MAXIMUM_POINTS}
                labelAlign="left"
                valuePropName="text"
            >
                <EditableTextControl className="ml-10" />
            </Form.Item>

            <Form.Item name="resultAssessmentType" labelAlign="left">
                <ResultAssessmentType />
            </Form.Item>
        </ControlGroup>
    </div>
))
