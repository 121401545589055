import React from 'react'
import { Button } from 'components/shared/Button'
import { withPermission } from 'HOCs'

import { BaseTableActionProps } from '../../TableActionBtn.types'

/** Основной компонент для создания компонента-триггера внутри реестров */
const BaseTableAction: React.FC<BaseTableActionProps> = ({
    accessDeniedRender,
    requiredPermissions,
    conditionalPermissions,
    ...restProps
}) => <Button {...restProps} />

export default React.memo(
    withPermission(BaseTableAction)
) as typeof BaseTableAction
