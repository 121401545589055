import React, { ChangeEvent, useCallback, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Form } from 'antd'
import { FormListOperation } from 'antd/lib/form/FormList'

import styles from './DynamicListControl.module.scss'
import { DynamicListControlItem } from './components/DynamicListControlItem'
import { DynamicListControlProps } from './DynamicListControl.types'
import { FormItemAdapter } from '..'
import { InputControl } from '../InputControl'

/** Компонент списка с возможностью удаления и добавления элементов c помощью текстового инпута */
export const DynamicListControl: React.FC<DynamicListControlProps> = React.memo(
    ({ listFieldName, label, formMode }) => {
        const [inputValue, setInputValue] = useState('')

        /** Обработчик изменения ввода текстового поля */
        const handleValueChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value)
            },
            []
        )

        /** Обработчик добавления элемента списка */
        const handleAddListItem = useCallback(
            (add: FormListOperation['add']) => () => {
                if (!inputValue) return

                add?.({ name: inputValue })
                setInputValue('')
            },
            [inputValue]
        )

        return (
            <div>
                <Form.List name={listFieldName}>
                    {(fields, { add, remove }) => (
                        <div>
                            {fields.map((field) => (
                                <div key={field.key}>
                                    <Form.Item {...field} noStyle>
                                        <DynamicListControlItem
                                            onRemove={() => {
                                                remove(field.name)
                                            }}
                                            formMode={formMode}
                                            className={styles.addon}
                                        />
                                    </Form.Item>
                                </div>
                            ))}

                            <FormItemAdapter label={label}>
                                <InputControl
                                    value={inputValue}
                                    onChange={handleValueChange}
                                    formMode={formMode}
                                />
                            </FormItemAdapter>

                            <AddButton
                                onClick={handleAddListItem(add)}
                                className={styles.add}
                                formMode={formMode}
                            />
                        </div>
                    )}
                </Form.List>
            </div>
        )
    }
)
