import { LabeledValue } from 'antd/lib/select'

interface FilterConditionsProps {
    conditions: LabeledValue[]
    selected: ({ id: number } | undefined)[]
    currentId: number
}

/** формирует список доступных для выбора условий отправки - исключает уже добавленные в список условия */
export const filterConditionsValues = ({
    conditions,
    selected,
    currentId,
}: FilterConditionsProps) =>
    conditions.filter(
        (condition) =>
            condition.value === currentId ||
            !selected.find((el) => el?.id === condition.value)
    )
