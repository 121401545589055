import React from 'react'
import {
    ControlGroup,
    FormItemAdapter,
    MultiSelectControl,
} from 'components/controls'
import { LOCAL } from 'core/local'

import { FunctionClassesGroupProps } from './FunctionClassesGroup.types'

/** Компонент области "Тип деятельности" для формы профиля должности */
export const FunctionClassesGroup: React.FC<FunctionClassesGroupProps> = React.memo(
    ({ formMode, treeData }) => (
        <ControlGroup type="dark" title={LOCAL.LABELS.ACTIVITY_TYPE} required>
            <FormItemAdapter fieldName="functionClassesIds" required>
                <MultiSelectControl
                    treeData={treeData}
                    formMode={formMode}
                    selectAll
                    allowClear
                />
            </FormItemAdapter>
        </ControlGroup>
    )
)
