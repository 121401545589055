import React from 'react'
import {
    APPOINTMENT_STATUS,
    ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS,
    DOCUMENT_STATUSES,
} from 'consts'
import {
    AssessmentProjectContract,
    AssessmentProjectSearchResultContract,
    AssessmentType,
    UserPermission,
} from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { DictionariesStoreProps } from 'core/helpers/DictionariesHelper'
import { GetBtnOptionsType } from 'components/layouts/FormContainer/FormContainer.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { defaultSorter } from 'components/layouts'
import {
    formatDate,
    isAppointmentStatusDraft,
    renderTableColumnWithPopover,
} from 'utils'

import { AssessmentProjectsTableActions } from './components'

export const getColumns = ({
    assessmentTypeDictionary,
}: DictionariesStoreProps): ColumnsType<
    AssessmentProjectSearchResultContract
> => [
    {
        title: LOCAL.LABELS.PROJECT_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'structuralUnits',
        key: 'structuralUnits',
        render: renderTableColumnWithPopover,
    },
    {
        title: LOCAL.LABELS.RESPONSIBLE_PERSON,
        dataIndex: 'changeUser',
        key: 'changeUser',
    },
    {
        title: LOCAL.LABELS.ASSESSMENT_METHOD,
        dataIndex: 'assessmentType',
        key: 'assessmentType',
        render: (type: AssessmentType) => assessmentTypeDictionary[type],
    },
    {
        title: LOCAL.LABELS.REQUEST_NUMBER,
        dataIndex: 'requestNumber',
        key: 'requestNumber',
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'dateFrom',
        key: 'dateFrom',
        render: formatDate,
        sorter: defaultSorter,
        defaultSortOrder: 'descend',
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'dateTo',
        key: 'dateTo',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        sorter: defaultSorter,
        render: (status, record) =>
            record.active
                ? APPOINTMENT_STATUS[record.status]
                : DOCUMENT_STATUSES.Archive,
    },
    {
        key: 'actions',
        align: 'right',
        render: (_, record) => <AssessmentProjectsTableActions {...record} />,
    },
]

export const CANCEL_BTN_OPTIONS = {
    href: ROUTE_NAMES.ASSESSMENT_PROJECTS,
    visible: true,
}

export const getSaveBtnOptions: GetBtnOptionsType<AssessmentProjectContract> = ({
    editValues,
}) => ({
    visible: true,
    disabled: !!editValues?.id && !isAppointmentStatusDraft(editValues?.status),
})

export const PUBLISH_BTN_OPTIONS = {
    visible: true,
    requiredPermissions: [
        UserPermission.AssessmentProjectsRead,
        UserPermission.AssessmentProjectPublish,
    ],
    conditionalPermissions: ASSESSMENT_PROJECTS_CONDITIONAL_PERMISSIONS,
}
