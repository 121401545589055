import * as path from 'path'
import shortid from 'shortid'
import { FnActionProps } from 'App.types'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { NotificationsService } from 'core/api'
import { SIGNALR_URL } from 'consts/env.consts'
import { createErrorNotification } from 'utils/notification.utils'
import { notification } from 'antd'

import {
    BaseNotificationProps,
    CourseUploadStatusChangedProps,
    NotificationModalTypes,
    NotificationProps,
    ReportConvertedNotificationProps,
    TextDocumentTaskNotificationProps,
} from './Notification.types'
import { ReconnectPolicy } from './ReconnectPolicy'
import { renderCloseButton, renderNotificationBody } from './Notification'

const withErrorNotification = <T extends BaseNotificationProps>(message: T) => {
    if (message?.errorMessage) {
        createErrorNotification(message.errorMessage)
    }

    return message
}

const connection = new HubConnectionBuilder()
    .withUrl(path.join(SIGNALR_URL, 'hub/notifications'))
    .withAutomaticReconnect(new ReconnectPolicy())
    .build()

connection.on('onNotifyReceived', (notify: NotificationProps) => {
    const key = `notification${shortid.generate()}`

    notification[NotificationModalTypes[notify.modalType]]({
        key,
        message: notify.title,
        description: renderNotificationBody(notify),
        btn: renderCloseButton(() => {
            NotificationsService.markAsRead({
                body: {
                    items: [notify.id],
                },
            })
            notification.close(key)
        }),
    })
})

/** Функция для создания слушателя на событие получения количества непросмотренных уведомлений
 * @param onReceiveMessage - колбэк для обработки события
 */
export const onReceiveUnreadMessageCount = (
    onReceiveMessage: (unreadCount: number) => void
) => {
    connection.on('onNotifyReceived', (message: NotificationProps) => {
        const unreadCount = message?.meta?.unreadCount
        if (unreadCount) {
            onReceiveMessage(unreadCount)
        }
    })
}

let isTextDocumentConnectionEstablished: boolean

/** Функция для создания слушателя на событие завершения текстовым редактором ассинхронного задания
 * @param onReceiveMessage - колбэк для обработки события
 */
export const onTextDocumentStateUpdated = (
    onReceiveMessage: FnActionProps<TextDocumentTaskNotificationProps>
) => {
    if (isTextDocumentConnectionEstablished) return

    connection.on(
        'onTextDocumentStateUpdated',
        (message: TextDocumentTaskNotificationProps) => {
            onReceiveMessage(message)
        }
    )

    isTextDocumentConnectionEstablished = true
}

/** Функция для создания слушателя на событие завершения обработки файла обучающего курса после
 *  загрузки
 * @param onReceiveMessage - колбэк для обработки события
 */
export const onCourseUploadStatusChanged = (
    onReceiveMessage: FnActionProps<CourseUploadStatusChangedProps>
) => {
    connection.on(
        'onCourseUploadStatusChanged',
        (message: CourseUploadStatusChangedProps) => {
            onReceiveMessage(message)
        }
    )
}

export const courseUploadStatusChangedOff = () => {
    connection.off('onCourseUploadStatusChanged')
}

export const textDocumentStateUpdatedOff = () => {
    connection.off('onTextDocumentStateUpdated')
    isTextDocumentConnectionEstablished = false
}

// TODO: преобразовать в класс, со всеми обработчиками
/**
 * Подключиться к хабу оповещений
 */
export function Connect() {
    connection.start().catch((error) => {
        console.error(error)
    })
}

let isReportConvertedConnectionEstablished: boolean

/**
 * Функция для создания слушателя на событие получения отчета по назначению 360
 */
export const onReportConverted = (
    onReceiveMessage: FnActionProps<ReportConvertedNotificationProps>
) => {
    if (isReportConvertedConnectionEstablished) return

    connection.on(
        'onReportConverted',
        (message: ReportConvertedNotificationProps) => {
            onReceiveMessage(withErrorNotification(message))
        }
    )

    isReportConvertedConnectionEstablished = true
}
