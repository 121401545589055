import stylesUserProfile from '../../UserProfile.module.scss'
import { GetUserProfileClassNameProps } from './UserProfileCompetenceLevelComparison.types'

/**
 * Получить часть наименования класса в соответствии типу, индексу и ПД
 */
export const getUserProfileClassName = ({
    isIndicatorLevel,
    assessmentType,
    index,
    isLowScore,
}: GetUserProfileClassNameProps) => {
    const competenceClass = isLowScore ? 'CompetenceLowScore' : 'Competence'
    const levelType = assessmentType
        ? `${assessmentType}_${index}`
        : competenceClass

    return stylesUserProfile[
        `assessmentResults${
            isIndicatorLevel ? 'Indicator' : 'Competence'
        }_${levelType}`
    ]
}
