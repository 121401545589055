import React from 'react'
import { ContentTitle } from 'components/shared/ContentTitle'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { PageContent } from 'components/shared/PageContent'

import { ErrorPageProps } from './ErrorPage.types'
import { getErrorPageContent } from './ErrorPage.utils'

/** Страница для отображения ошибки пользователю */
export const ErrorPage: React.FC<ErrorPageProps> = React.memo(
    ({ errorCode, missingAccessRights }) => {
        const currentError = getErrorPageContent(errorCode, missingAccessRights)

        return (
            <PageContent>
                <ContentTitle
                    title={currentError.title || LOCAL.CATALOGS.ERROR_OCCURRED}
                />

                <main>
                    {currentError?.description && (
                        <h2>{currentError.description}</h2>
                    )}

                    <h3>
                        {`${LOCAL.MESSAGES.YOU_CAN_GO_TO} `}

                        <Link to="/">{LOCAL.LINKS.HOME_PAGE}</Link>
                    </h3>
                </main>
            </PageContent>
        )
    }
)
