import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Select } from 'antd'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { JobProfilesUrlParamsProps } from 'components/pages'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import {
    PositionProfilePublishContract,
    PositionProfilesService,
    StaffUnitStructureContract,
} from 'core/api'
import { isFormModeCopy, isFormModeView } from 'utils'
import { useDictionaries, useElementVisible } from 'hooks'
import { useHistory, useParams } from 'react-router-dom'
import { withLoader } from 'HOCs/withLoader'

import {
    COMPETENCE_GROUPS_SHOULD_UPDATE,
    JOB_PROFILE_FORM_ID,
    MANAGE_EXPERIENCE_MISSING_ID,
    STRUCTURAL_UNITS_SHOULD_FETCH,
} from './JobProfileForm.consts'
import {
    JobProfileDictionariesStateProps,
    JobProfileFormProps,
    JobProfileFormSubmitValuesProps,
} from './JobProfileForm.types'
import { JobProfileFormFields } from '../JobProfileFormFields'
import {
    callConfirmPopup,
    dictionaryFetch,
    fetchStructuralUnits,
    getInitialProfileName,
    getInitialValuesForExistingProfile,
    getStaffUnitsStructure,
    getStructualUnits,
    getVersions,
    handleSelectVersion,
    updateCompetenciesGroups,
    updateStaffUnit,
    updateStructuralUnits,
    useDefaultCompetenceGroupsIds,
} from './JobProfileForm.utils'
import {
    mapFormDataToRequest,
    mapInitialValuesToFormValues,
} from './JobProfileForm.utils.maps'

/**
 * Форма создание профиля должности
 */
export const JobProfileForm: React.FC<JobProfileFormProps> = withLoader(
    React.memo(
        ({
            onBlockUserRouting,
            archivalJobProfile,
            initialValuesForEdit,
            initialValuesForAdd,
            isSaveDocument,
            reFetchQuizPortfolio,
            formMode,
            updateLoader,
        }) => {
            const [isFormTouched, setFormTouched] = useState(false)
            const [touchEvent, setTouchEvent] = useState(true)
            const {
                dictionaries,
                handleFetchDictionaries,
                handleChangeDictionaries,
            } = useDictionaries<JobProfileDictionariesStateProps>({
                updateLoader,
            })

            const [form] = Form.useForm()
            const {
                defaultCompetenceGroupsIds,
            } = useDefaultCompetenceGroupsIds()
            const urlParams = useParams<JobProfilesUrlParamsProps>()

            const history = useHistory()

            const [versions, setVersions] = useState<LabeledValue[]>()
            const [currentVersion, setCurrentVersion] = useState<number>()

            /** Начальные значения для id ОИВ'а, необходимо для случая, когда изначальный ОИВ был получен
             * по организации/подразделению/комплексу пользователя при создании из реестра организаций
             * */
            const [initialOivId, setInitialOivId] = useState<number>()

            const {
                elementVisible,
                handleHideElement,
                handleShowElement,
            } = useElementVisible()
            const [
                staffModalInitialValues,
                setStaffModalInitialValues,
            ] = useState<Partial<StaffUnitStructureContract>>()

            const calculatedVersion = useMemo(
                () =>
                    urlParams.version
                        ? archivalJobProfile?.version
                        : initialValuesForEdit?.version,
                [archivalJobProfile, initialValuesForEdit, urlParams.version]
            )

            const goTo = useCallback((url: string) => history.push(url), [
                history,
            ])

            /**
             * Отправка формы
             */
            const handleFinish = useCallback(
                async (values: JobProfileFormSubmitValuesProps) => {
                    try {
                        updateLoader(true)

                        const body = mapFormDataToRequest(
                            values,
                            !isFormModeCopy(formMode)
                                ? initialValuesForEdit?.id
                                : undefined
                        ) as PositionProfilePublishContract

                        if (isSaveDocument) {
                            await PositionProfilesService.save({ body })
                        } else {
                            await PositionProfilesService.publish({ body })
                        }

                        onBlockUserRouting?.(false)

                        callConfirmPopup({
                            isSaveDocument,
                            form,
                            initialValuesForEdit,
                            reFetchQuizPortfolio,
                            goTo,
                        })

                        if (!initialValuesForEdit) {
                            form.resetFields()
                        }
                    } catch (error) {
                        console.error(error)
                    } finally {
                        updateLoader(false)
                    }
                },
                [
                    updateLoader,
                    formMode,
                    initialValuesForEdit,
                    isSaveDocument,
                    onBlockUserRouting,
                    form,
                    reFetchQuizPortfolio,
                    goTo,
                ]
            )

            const fetchStaffUnitStructure = useCallback(
                (id: number) =>
                    getStaffUnitsStructure({
                        form,
                        setStaffModalInitialValues,
                        id,
                    }),
                [form]
            )

            const onShowStaffUnitModal = useCallback(async () => {
                if (staffModalInitialValues?.staffUnit?.id) {
                    await fetchStaffUnitStructure(
                        staffModalInitialValues.staffUnit.id
                    )
                }

                handleShowElement()
            }, [
                fetchStaffUnitStructure,
                handleShowElement,
                staffModalInitialValues,
            ])

            /**
             * Запрос версий по `id` профиля должности
             */
            const versionsFetch = useCallback(
                (id) => getVersions({ id, setVersions, initialValuesForEdit }),
                [initialValuesForEdit]
            )

            /** Обработчик изменения значений формы */
            const handleValuesChange = useCallback(
                async (
                    changedValue: PositionProfilePublishContract,
                    allValues
                ) => {
                    const [changedValueKey, changedValueValue] = Object.entries(
                        changedValue
                    )[0]

                    if (!touchEvent) {
                        setTouchEvent(true)
                    } else {
                        setFormTouched(true)
                    }

                    if (
                        COMPETENCE_GROUPS_SHOULD_UPDATE.includes(
                            changedValueKey
                        )
                    ) {
                        updateCompetenciesGroups({
                            form,
                            defaultCompetenceGroupsIds,
                            allValues,
                        })
                    }

                    if (
                        STRUCTURAL_UNITS_SHOULD_FETCH.includes(changedValueKey)
                    ) {
                        await updateStructuralUnits({
                            changedValueValue,
                            changedValueKey,
                            form,
                            handleChangeDictionaries,
                        })
                    }

                    if (
                        changedValueKey === 'manageExperienceId' &&
                        changedValueValue === MANAGE_EXPERIENCE_MISSING_ID
                    ) {
                        form.setFieldsValue({
                            manageExperienceSubordinatesNumberId: null,
                            manageExperienceIndustryId: null,
                        })
                    }

                    if (changedValueKey === 'showPerfomanceIndicators') {
                        form.setFieldsValue({
                            performanceIndicatorsDescription: null,
                        })
                    }
                },
                [
                    defaultCompetenceGroupsIds,
                    form,
                    handleChangeDictionaries,
                    touchEvent,
                ]
            )

            const handleStuffUnitChange = useCallback(
                (staffUnitId: number, dictionary?: LabeledValue[]) =>
                    updateStaffUnit({
                        form,
                        staffUnitId,
                        dictionary,
                        setStaffModalInitialValues,
                        handleChangeDictionaries,
                    }),
                [form, handleChangeDictionaries, setStaffModalInitialValues]
            )

            useEffect(() => {
                if (isFormTouched && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }
            }, [formMode, isFormTouched, onBlockUserRouting])

            useEffect(() => {
                setCurrentVersion(calculatedVersion)
            }, [calculatedVersion])

            useEffect(() => {
                dictionaryFetch(handleFetchDictionaries)
                fetchStructuralUnits({
                    handleChangeDictionaries,
                    initialValuesForEdit,
                    fetchStaffUnitStructure,
                })
            }, [
                handleFetchDictionaries,
                fetchStaffUnitStructure,
                initialValuesForEdit,
                handleChangeDictionaries,
            ])

            useEffect(() => {
                getStructualUnits({
                    initialValuesForEdit,
                    formMode,
                    handleChangeDictionaries,
                    fetchStaffUnitStructure,
                    initialValuesForAdd,
                    form,
                    handleFetchDictionaries,
                    setInitialOivId,
                })
            }, [
                formMode,
                initialValuesForAdd,
                initialValuesForEdit,
                handleChangeDictionaries,
                fetchStaffUnitStructure,
                form,
                handleFetchDictionaries,
                setInitialOivId,
            ])

            useEffect(() => {
                if (urlParams.profileId) {
                    versionsFetch(Number(urlParams.profileId))
                }
            }, [versionsFetch, urlParams.profileId])

            useEffect(() => {
                if (
                    initialValuesForAdd?.positionId ||
                    initialValuesForAdd?.oivId
                ) {
                    form.setFieldsValue(
                        getInitialProfileName(
                            { ...initialValuesForAdd, oivId: initialOivId },
                            {
                                oiv: dictionaries.oiv,
                                positions: dictionaries.positions,
                            }
                        )
                    )
                }
            }, [
                dictionaries.oiv,
                dictionaries.positions,
                form,
                initialOivId,
                initialValuesForAdd,
            ])

            useEffect(() => {
                if (!initialValuesForEdit?.id) {
                    setTouchEvent(false)
                    form.setFieldsValue(
                        mapInitialValuesToFormValues(
                            initialValuesForAdd,
                            defaultCompetenceGroupsIds
                        )
                    )
                }
            }, [
                defaultCompetenceGroupsIds,
                form,
                initialValuesForAdd,
                initialValuesForEdit,
            ])

            useEffect(() => {
                getInitialValuesForExistingProfile({
                    version: urlParams.version,
                    form,
                    archivalJobProfile,
                    initialValuesForEdit,
                    formMode,
                    setTouchEvent,
                    defaultCompetenceGroupsIds,
                })
            }, [
                archivalJobProfile,
                form,
                initialValuesForEdit,
                urlParams.version,
                defaultCompetenceGroupsIds,
                formMode,
                setTouchEvent,
            ])

            return (
                <>
                    {isFormModeView(formMode) && (
                        <FormItemAdapter label={LOCAL.LABELS.VERSION}>
                            <Select
                                options={versions || []}
                                value={currentVersion}
                                onChange={handleSelectVersion(
                                    goTo,
                                    initialValuesForEdit?.id,
                                    initialValuesForEdit?.version
                                )}
                            />
                        </FormItemAdapter>
                    )}

                    <Form
                        form={form}
                        id={JOB_PROFILE_FORM_ID}
                        onFinish={handleFinish}
                        onValuesChange={handleValuesChange}
                    >
                        <JobProfileFormFields
                            formMode={formMode}
                            dictionaries={dictionaries}
                            initialValuesForEdit={initialValuesForEdit}
                            onShowStaffUnitModal={onShowStaffUnitModal}
                            elementVisible={elementVisible}
                            handleHideElement={handleHideElement}
                            handleStuffUnitChange={handleStuffUnitChange}
                            staffModalInitialValues={staffModalInitialValues}
                        />
                    </Form>
                </>
            )
        }
    )
)
