import capitalize from 'lodash/capitalize'
import cn from 'classnames'
import last from 'lodash/last'
import stylesTrackStagesVisualization from 'components/pages/Track/components/TrackForm/components/shared/TrackStagesVisualization/TrackStagesVisualization.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'antd'
import { IconStatusProps } from 'components/pages/Track/components/TrackForm/TrackForm.types'
import { RadioButtonControl } from 'components/controls'
import { Resolutions } from 'core/configs'
import { StagesDndArea } from 'components/pages/Track/components/TrackForm/components/shared/StagesDndArea'
import { TrackLogoList } from 'components/shared/TrackLogoList'
import {
    getBackgroundImageStyle,
    getResolution,
    isAppointmentTrackStatusPassed,
    isStageModePreview,
} from 'utils'
import { useXScroll } from 'hooks'

import styles from './TrackLayer.module.scss'
import {
    FormValuesProps,
    updateStageOptionsPreviewStatus,
} from '../../../Track/components/TrackForm/TrackForm.utils'
import { ICON_STATE_NAMES, RESOLUTIONS_FOR_LOGO } from './TrackLayer.consts'
import { TrackLayerProps } from './TrackLayer.types'

/**
 * Слой трека, прохождение трека начинается с этого места
 */
export const TrackLayer: React.FC<TrackLayerProps> = React.memo(
    ({
        mode,
        onUpdatePassageProcessInfo,
        startStagePassing,
        passingType,
        trackInfo,
    }) => {
        const [resolution, setResolution] = useState(Resolutions.XXL)
        const [resolutionForTrack, setResolutionForTrack] = useState(
            Resolutions.XXL
        )
        /**
         * Ссылка на элемент `main`
         */
        const droppableElem = useRef<HTMLDivElement>(null)

        const { containerWithXScroll } = useXScroll()

        useEffect(() => {
            function handleWindowResize() {
                const resolutions = trackInfo?.trackCanvases?.map((canvas) =>
                    String(canvas.screenResolution)
                )

                setResolution(
                    getResolution(window.innerWidth, resolutions) as Resolutions
                )

                setResolutionForTrack(
                    getResolution(
                        window.innerWidth,
                        RESOLUTIONS_FOR_LOGO
                    ) as Resolutions
                )
            }

            handleWindowResize()

            window.addEventListener('resize', handleWindowResize)

            return () => {
                window.removeEventListener('resize', handleWindowResize)
            }
        }, [trackInfo])

        return (
            <>
                <Form.Item noStyle shouldUpdate>
                    {({ getFieldsValue, setFieldsValue }) => {
                        const {
                            trackCanvases,
                            stages,
                            trackBackgroundPicture,
                        }: FormValuesProps = getFieldsValue()

                        const defaultCanvasBg =
                            trackCanvases?.[resolution]?.meta
                                ?.currentBackgroundUrl
                        let canvasBg = defaultCanvasBg

                        const passedStages = stages?.filter(({ status }) =>
                            isAppointmentTrackStatusPassed(status)
                        )

                        if (passedStages?.length) {
                            passedStages?.sort((prev, next) => {
                                const prevDate = +new Date(prev.passingDate)
                                const nextDate = +new Date(next.passingDate)

                                return prevDate - nextDate
                            })

                            const lastOfPassedStages = last(passedStages)
                            const stageStyle = trackCanvases?.[
                                resolution
                            ]?.trackStageStyles?.find(
                                (el) =>
                                    el?.stageNumber ===
                                    lastOfPassedStages?.stageNumber
                            )

                            canvasBg =
                                stageStyle?.icons.passing.iconBackground?.url ||
                                defaultCanvasBg
                        }

                        // TODO: декомпозировать логику прохождения и предпросмотра на более низком уровне (в дочерних компонентах)
                        const stagesStylesForPreview = trackCanvases?.[
                            resolution
                        ]?.trackStageStyles as any

                        return (
                            <div
                                className={cn(
                                    stylesTrackStagesVisualization.wrapper,
                                    stylesTrackStagesVisualization[
                                        `wrapper${capitalize(mode)}`
                                    ]
                                )}
                            >
                                <aside
                                    className={
                                        stylesTrackStagesVisualization.aside
                                    }
                                >
                                    <StagesDndArea
                                        droppableElem={droppableElem.current}
                                        mode={mode}
                                        onUpdatePassageProcessInfo={
                                            onUpdatePassageProcessInfo
                                        }
                                        startStagePassing={startStagePassing}
                                        canvasKey={resolution}
                                        passingType={passingType}
                                        appointmentStatus={trackInfo?.status}
                                    />
                                </aside>

                                <main
                                    ref={containerWithXScroll}
                                    className={
                                        stylesTrackStagesVisualization.main
                                    }
                                    style={{
                                        ...getBackgroundImageStyle(
                                            trackBackgroundPicture?.url
                                        ),
                                        backgroundSize: 'cover',
                                    }}
                                >
                                    {trackInfo?.trackHeaderIcons && (
                                        <TrackLogoList
                                            resolution={resolutionForTrack}
                                            items={trackInfo.trackHeaderIcons}
                                            className={styles.logoWrapper}
                                        />
                                    )}

                                    <div
                                        ref={droppableElem}
                                        className={cn(
                                            stylesTrackStagesVisualization.canvas,
                                            'droppable'
                                        )}
                                        style={{
                                            ...getBackgroundImageStyle(
                                                canvasBg
                                            ),
                                            width:
                                                trackCanvases?.[resolution]
                                                    ?.width || 'auto',
                                            height:
                                                trackCanvases?.[resolution]
                                                    ?.height || '100%',
                                        }}
                                    >
                                        {isStageModePreview(mode) && (
                                            <div
                                                className={
                                                    stylesTrackStagesVisualization.toggleIcons
                                                }
                                            >
                                                <RadioButtonControl
                                                    defaultValue={
                                                        ICON_STATE_NAMES[0]
                                                            .value
                                                    }
                                                    values={ICON_STATE_NAMES}
                                                    buttonStyle="solid"
                                                    onChange={(item) =>
                                                        setFieldsValue({
                                                            stages: updateStageOptionsPreviewStatus(
                                                                stagesStylesForPreview,
                                                                item as IconStatusProps
                                                            ),
                                                        })
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </main>
                            </div>
                        )
                    }}
                </Form.Item>
            </>
        )
    }
)
