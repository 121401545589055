import React from 'react'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { QuestionnaireSearchContract } from 'core/api'

/**
 * Форма фильтрации опросных листов для формы трека адаптации
 */
export const TrackQuestionnairesFilters: React.FC<FiltersProps<
    QuestionnaireSearchContract
>> = React.memo((props) => (
    <FilterForm {...props}>
        <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
            <InputControl />
        </FormItemAdapter>
    </FilterForm>
))
