import React from 'react'
import { ErrorPage } from 'components/pages/utilityPages/ErrorPage'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'

import { UserAssignmentPassing } from './components'
import { UserAssignmentsPage } from './UserAssignmentsPage'

export const UserAssignments: React.FC = React.memo(() => (
    <Switch>
        <Route
            path={`${ROUTE_NAMES.USER_ASSIGNMENTS_PASSING}/:assignmentId/:candidateId?`}
        >
            <UserAssignmentPassing formMode="passing" />
        </Route>

        <Route
            path={`${ROUTE_NAMES.USER_ASSIGNMENTS_VIEW}/:assignmentId/:candidateId?`}
        >
            <UserAssignmentPassing formMode="view" />
        </Route>

        <Route path={ROUTE_NAMES.USER_ASSIGNMENTS}>
            <UserAssignmentsPage />
        </Route>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
