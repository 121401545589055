import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    AppointmentTestReviewsService,
    AppointmentTestStatisticCommonContract,
    TestReportsService,
} from 'core/api'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { Title } from 'components/shared'
import { UrlParamsCommonProps } from 'App.types'
import { downloadXlsxReport } from 'utils'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './TabCommonStats.module.scss'
import { COMMENT_FORM_EVENTS } from '../CommonStatsCommentModal/CommonStatsCommentModal.events'
import { CommonStatsAvgTime } from '../CommonStatsAvgTime'
import { CommonStatsControls } from '../CommonStatsControls'
import { CommonStatsDate } from '../CommonStatsDate'
import { CommonStatsParticipansCount } from '../CommonStatsParticipantsCount'
import { CommonStatsTable } from '../CommonStatsTable'
import { QuestionsColumns, TableDataProps } from './TabCommonStats.types'
import { getDataTable } from './TabCommonStats.utils'

/** Вкладка "общая статистика" для страницы отчета по тесту */
export const TabCommonStats: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const { id } = useParams<UrlParamsCommonProps<'id'>>()
        const [questionsColumns, setQuestionsColumns] = useState<
            QuestionsColumns[]
        >()
        const [data, setData] = useState<
            AppointmentTestStatisticCommonContract
        >()
        const [tableData, setTableData] = useState<TableDataProps[]>([])

        const dates = useMemo(
            () => [
                {
                    value: data?.dateStart,
                    label: LOCAL.LABELS.DATE_START,
                },
                {
                    value: data?.dateEnd,
                    label: LOCAL.LABELS.DATE_END,
                },
            ],
            [data]
        )

        const getData = useCallback(async () => {
            try {
                updateLoader(true)

                const response = await AppointmentTestReviewsService.getAppointmentTestStatistic(
                    { appointmentId: Number(id) }
                )

                const normalizedQuestions = response?.questionnaireNames.map(
                    (el, index) => ({
                        number: index + 1,
                        data: el,
                    })
                )

                setQuestionsColumns(normalizedQuestions)

                setTableData(
                    getDataTable(response?.statistic, normalizedQuestions)
                )

                setData(response)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader, id])

        useEffect(() => {
            getData()
        }, [getData])

        /**
         * Скачать отчет
         */
        const downloadReport = useCallback(async () => {
            try {
                updateLoader?.(true)

                const file = await TestReportsService.getXlsxReport(
                    { appointmentId: Number(id) },
                    { responseType: 'blob' }
                )

                downloadXlsxReport(file)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader?.(false)
            }
        }, [updateLoader, id])

        useEffect(() => {
            eventEmitter.on(COMMENT_FORM_EVENTS.SAVE_COMMENT, getData)

            return () => {
                eventEmitter.off(COMMENT_FORM_EVENTS.SAVE_COMMENT, getData)
            }
        }, [getData])

        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <Title type="h1" text={data?.name} bottomIndent="10" />

                    <CommonStatsControls
                        handleUpdate={getData}
                        handleExport={downloadReport}
                    />
                </div>

                <Row gutter={ROW_GUTTER}>
                    <Col xs={6}>
                        <CommonStatsDate dates={dates} />
                    </Col>

                    <Col xs={6}>
                        <CommonStatsParticipansCount
                            maxCount={data?.numberAppointedParticipants}
                            passedCount={data?.numberCompletedParticipants}
                        />
                    </Col>

                    <Col xs={6}>
                        <CommonStatsAvgTime
                            avgTime={data?.averagePassingTime}
                        />
                    </Col>
                </Row>

                {!!tableData.length && (
                    <CommonStatsTable
                        data={tableData}
                        questionsColumns={questionsColumns}
                        key={tableData[0].id}
                    />
                )}
            </div>
        )
    })
)
