import React, { useContext, useMemo } from 'react'
import { ActivityPassingContract } from 'core/api'
import { Col, Row } from 'antd'
import { ColorThemeContext } from 'contexts'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { getBannerBackgroundCssProp } from 'utils'

import styles from './EventPassingInfoPanel.module.scss'
import { EventPassingInfoPanelProps } from './EventPassingInfoPanel.types'
import { ProgressEventPassing } from '../ProgressEventPassing'
import { renderEventMetaInfo } from './EventPassingInfoPanel.utils'

/** Информация о мероприятии при прохождении */
export const EventPassingInfoPanel: React.FC<EventPassingInfoPanelProps> = React.memo(
    ({ onReturnToPrevActivity, nestedActivities, activity }) => {
        const { theme } = useContext(ColorThemeContext)

        const prevLessonsIdsLength = nestedActivities?.prevLessonsIds?.length

        /**
         * Формируем объект для ссылки возврата
         */
        const titleBackLink = useMemo(
            () =>
                prevLessonsIdsLength
                    ? {
                          text: LOCAL.ACTIONS.RETURN,
                          onClick: onReturnToPrevActivity,
                      }
                    : undefined,
            [prevLessonsIdsLength, onReturnToPrevActivity]
        )

        return (
            <div
                className={styles.wrapper}
                style={getBannerBackgroundCssProp({
                    url: activity?.activityPicture?.url,
                    theme,
                })}
            >
                <Row>
                    <Col xs={14}>
                        {titleBackLink && (
                            <Link to="#s" onClick={titleBackLink.onClick}>
                                <IconsAdapter iconType="ArrowLeftOutlined" />

                                {titleBackLink.text}
                            </Link>
                        )}

                        <div className={styles.header}>
                            <h1 className={styles.title}>{activity.name}</h1>

                            <div className={styles.meta}>
                                {renderEventMetaInfo(
                                    activity as ActivityPassingContract
                                )}
                            </div>
                        </div>

                        <pre className={styles.desc}>
                            {activity.announcement}
                        </pre>

                        <ProgressEventPassing
                            activityLessonPassings={
                                activity.activityLessonPassings
                            }
                            ratingInfo={activity.ratingInfo}
                            activityStatus={activity.status}
                        />

                        <div className={styles.meta}>
                            {`${LOCAL.LABELS.THEMATIC_BLOCKS_COUNT}: ${activity.activityLessonPassings.length}`}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
)
