import {
    ActivityCanvasDesignType,
    ActivityDecorationType,
    ActivityKind,
    ActivityPassingDateType,
    ActivityPassingStatus,
    ActivityType,
    AgreementStatus,
    AnswerConclusion,
    AppointmentStatus,
    AppointmentTrackStatus,
    AppointmentType,
    AssessmentRole,
    AssessmentType,
    CertificateKind,
    CertificateType,
    CompliancePositionProfile,
    DocumentStatus,
    EmailState,
    FaceToFaceMeetingStatus,
    GraduationDocumentType,
    HomeworkStatusType,
    LessonAccessType,
    LessonType,
    NotificationDeliveryStatus,
    NotificationIntervals,
    NotificationMailingTaskStatus,
    NotificationMailingTaskType,
    NotificationTemplateStatus,
    NotificationType,
    PositionProfileType,
    ProductType,
    ProgramLevel,
    QuestionType,
    QuestionnaireType,
    QuizCheckStatus,
    QuizType,
    StrongSidesAndZonesOfDevelopmentPriority,
    StudyingFormat,
    TestSequence,
    TestTypeCode,
    TrackAppointmentType,
    TrackEvent,
    TrackStageAvailableStatus,
    TrackStageEvent,
    TrackStageMaterialType,
    TrackType,
    UserProfileRecommendationType,
    YourDevelopmentPageItemType,
} from 'core/api/api'
import { LOCAL } from 'core/local'

import { ReportLevels } from './reports.consts'
import { ReportShortCodes360, ReportShortCodesManager } from './enums.consts'

/**
 * Тип материала для этапа трека адаптации
 */
export const TRACK_STAGES_MATERIAL_TYPE = {
    [TrackStageMaterialType.Activity]: LOCAL.LABELS.EVENT_M,
    [TrackStageMaterialType.Content]: LOCAL.LABELS.CONTENT,
}

/**
 * Статусы документа
 */
export const DOCUMENT_STATUSES = {
    [DocumentStatus.ReadyForWork]: LOCAL.STATUSES.READY_FOR_WORK,
    [DocumentStatus.InWork]: LOCAL.STATUSES.IN_WORK,
    [DocumentStatus.Draft]: LOCAL.STATUSES.DRAFT,
    Archive: LOCAL.STATUSES.ARCHIVE,
}

/**
 * Статусы назначений
 */
export const APPOINTMENT_STATUS = {
    [AppointmentStatus.Draft]: LOCAL.STATUSES.DRAFT,
    [AppointmentStatus.Expectation]: LOCAL.LABELS.EXPECTATION,
    [AppointmentStatus.InProgress]: LOCAL.STATUSES.IN_PROGRESS,
    [AppointmentStatus.Done]: LOCAL.STATUSES.DONE,
    [AppointmentStatus.Checked]: LOCAL.STATUSES.CHECKED,
    [AppointmentStatus.Suspended]: LOCAL.STATUSES.SUSPENDED,
    [AppointmentStatus.Canceled]: LOCAL.STATUSES.CANCELED,
    [AppointmentStatus.Ended]: LOCAL.STATUSES.ENDED,
}

/**
 * Статусы назначений треков
 */
export const TRACKS_APPOINTMENT_STATUSES = {
    [AppointmentTrackStatus.Draft]: LOCAL.STATUSES.DRAFT,
    [AppointmentTrackStatus.Appointment]: LOCAL.STATUSES.ASSIGNED,
    [AppointmentTrackStatus.Ended]: LOCAL.STATUSES.ENDED,
    [AppointmentTrackStatus.Suspended]: LOCAL.STATUSES.SUSPENDED,
    [AppointmentTrackStatus.Canceled]: LOCAL.STATUSES.CANCELED,
    [AppointmentTrackStatus.Passed]: LOCAL.STATUSES.PASSED,
    [AppointmentTrackStatus.NotPassed]: LOCAL.STATUSES.NOT_PASSED,
    [AppointmentTrackStatus.AvailableToPassing]:
        LOCAL.STATUSES.AVAILABLE_FOR_PASSING,
    [AppointmentTrackStatus.InWork]: LOCAL.STATUSES.IN_WORK,
    Archive: LOCAL.STATUSES.ARCHIVE,
}

/**
 * Статусы листов согласования
 */
export const APPROVE_STATUSES = {
    [AgreementStatus.New]: '-',
    [AgreementStatus.OnApproval]: LOCAL.STATUSES.ON_APPROVAL,
    [AgreementStatus.Approved]: LOCAL.STATUSES.APPROVED,
}

/**
 * Более не используемые виды оценки
 */
export const DEPRECATED_ASSESSMENT_TYPES = [
    AssessmentType.Estimate_180,
    AssessmentType.Estimate_270,
    AssessmentType.EstimateByExpert,
]

/**
 * Типы опроса
 */
export const QUIZ_TYPES = {
    [QuizType.Engagement]: LOCAL.LABELS.ENGAGEMENT,

    // TODO: нет ошибки, по просьбе аналитики (QuizType.Random - пока не используется)
    [QuizType.Random]: LOCAL.LABELS.FEEDBACK,
    [QuizType.Feedback]: LOCAL.LABELS.FEEDBACK,
    [QuizType.Recommend]: LOCAL.LABELS.READY_TO_RECOMMEND,
    [QuizType.ServiceQuality]: LOCAL.LABELS.SERVICE_QUALITY,
}

/**
 * Более ее используемые типы опроса
 */
export const DEPRECATED_QUIZ_TYPES = [
    QuizType.Engagement,
    QuizType.Random,
    QuizType.ServiceQuality,
    QuizType.Recommend,
]

/**
 * Типы тестов
 */
export const TEST_TYPES = {
    [TestTypeCode.Primary]: LOCAL.LABELS.PRIMARY,
    [TestTypeCode.Secondary]: LOCAL.LABELS.SECONDARY,
}

/**
 * Типы анкеты/документа
 */
export const QUESTIONNAIRE_TYPES = {
    [QuestionnaireType.Test]: LOCAL.LABELS.TEST,
    [QuestionnaireType.Assessment]: LOCAL.LABELS.ASSESSMENT,
    [QuestionnaireType.Quiz]: LOCAL.LABELS.QUIZ,
}

/**
 * Типы вопросов
 */
export const QUESTION_TYPES = {
    [QuestionType.TextQuestion]: LOCAL.LABELS.TEXT_ANSWER,
    [QuestionType.CriteriaScale]: LOCAL.LABELS.CRITERIA_SCALE,
    [QuestionType.ChooseVariant]: LOCAL.LABELS.CHOOSE_VARIANT,
    [QuestionType.FillEmptyText]: LOCAL.LABELS.FILL_EMPTY_TEXT,
}

/**
 * Последовательность ответов на вопросы для типа документа - Тест
 */
export const TEST_SEQUENCE = {
    [TestSequence.Consistently]: LOCAL.LABELS.STEP_BY_STEP,
    [TestSequence.Inconsistently]: LOCAL.LABELS.ARBITRARILY,
}

/**
 * Тип мероприятия
 */
export const EVENT_TYPES = {
    [ActivityType.Intramural]: LOCAL.LABELS.INTRAMURAL,
    [ActivityType.Distant]: LOCAL.LABELS.ONLINE,
    [ActivityType.Mixed]: LOCAL.LABELS.MIXED,
}

/**
 * Вид мероприятия
 */
export const EVENT_SPECIES = {
    [ActivityKind.Teaching]: LOCAL.LABELS.TEACHING,
    [ActivityKind.Introductory]: LOCAL.LABELS.INTRODUCTORY,
}

/**
 * Роли оценки
 */
export const ASSESSMENT_ROLES = {
    [AssessmentRole.Manager]: LOCAL.LABELS.MANAGER,
    [AssessmentRole.Colleague]: LOCAL.LABELS.COLLEAGUE,
    [AssessmentRole.Subordinate]: LOCAL.LABELS.SUBORDINATE,
    [AssessmentRole.Expert]: LOCAL.LABELS.EXPERT,
    [AssessmentRole.GroupExpert]: LOCAL.LABELS.EXPERT_GROUP,
    [AssessmentRole.SelfAssessment]: LOCAL.LABELS.ASSESSMENT_SELF,
    [AssessmentRole.ColleagueFromRelatedDepartment]:
        LOCAL.LABELS.COLLEAGUE_FROM_RELATED_DEPARTMENT,
}

/**
 * Тип уведомлений
 */
export const NOTIFICATION_TYPES = {
    [NotificationType.Activity]: LOCAL.LABELS.EVENT_M,
    [NotificationType.LdeCreated]: LOCAL.LABELS.DOCUMENT,
    [NotificationType.Assessment]: LOCAL.LABELS.ASSESSMENT,
    [NotificationType.Quiz]: LOCAL.LABELS.QUIZ,
    [NotificationType.Test]: LOCAL.LABELS.TEST,
    [NotificationType.Track]: LOCAL.LABELS.TRACK,
    [NotificationType.Homework]: LOCAL.LABELS.HOMEWORK,
    [NotificationType.Other]: LOCAL.LABELS.OTHER,
}

/**
 * Статусы уведомлений
 */
export const NOTIFICATION_STATUSES = {
    read: LOCAL.STATUSES.READED,
    unread: LOCAL.STATUSES.UNREADED,
}

/**
 * Типы трека
 */
export const TRACK_TYPES = {
    [TrackType.Adaptation]: LOCAL.LABELS.ADAPTATION,
    [TrackType.Development]: LOCAL.LABELS.DEVELOPMENT,
    [TrackType.Mguu]: LOCAL.LABELS.PREDEFINED,
}

/** Тип назначения трека */
export const TRACKS_APPOINTMENT_TYPES = {
    [TrackAppointmentType.ByStages]: LOCAL.LABELS.BY_STAGE,
    [TrackAppointmentType.ByTrack]: LOCAL.LABELS.BY_TRACK,
}

/** Тип назначения */
export const APPOINTMENT_TYPES = {
    [AppointmentType.Activity]: LOCAL.LABELS.EVENT_M,
    [AppointmentType.Track]: LOCAL.LABELS.TRACK,
}

/** Тип назначения (для стр. "Ваше развитие") */
export const APPOINTMENT_TYPES_YOUR_DEVELOPMENT = {
    [YourDevelopmentPageItemType.AdaptationTrack]: LOCAL.LABELS.ADAPTATION,
    [YourDevelopmentPageItemType.DevelopmentTrack]: LOCAL.LABELS.DEVELOPMENT,
    [YourDevelopmentPageItemType.Mguu]: LOCAL.LABELS.DEVELOPMENT,
    [YourDevelopmentPageItemType.Activity]: LOCAL.LABELS.EVENT_M,
}

/** Статус настройки этапа трека  */
export const TRACK_STAGE_SETTINGS_STATUS = {
    [TrackStageAvailableStatus.Available]: LOCAL.STATUSES.AVAILABLE,
    [TrackStageAvailableStatus.NotAvailable]: LOCAL.STATUSES.NO_AVAILABLE,
}

/** Справочник событий трэка */
export const TRACK_EVENTS = {
    [TrackEvent.Greeting]: LOCAL.LABELS.GREETING,
    [TrackEvent.Start]: LOCAL.LABELS.TRACK_START,
    [TrackEvent.End]: LOCAL.LABELS.TRACK_END,
}

/** Справочник событий стадий трэка */
export const TRACK_STAGE_EVENTS = {
    [TrackStageEvent.Start]: LOCAL.LABELS.STAGE_START,
    [TrackStageEvent.End]: LOCAL.LABELS.STAGE_END,
}

/**
 * Типы профилей должностей
 */
export const PROFILE_TYPES = {
    [PositionProfileType.RBS]: LOCAL.LABELS.RBS,
    [PositionProfileType.GGS]: LOCAL.LABELS.GGS,
}

/**
 * Типы тематических блоков мероприятия
 */
export const THEME_BLOCK_TYPE = {
    [LessonType.Course]: LOCAL.LABELS.EDUCATION_MATERIAL,
    [LessonType.Activity]: LOCAL.LABELS.EVENT_M,
    [LessonType.Questionnaire]: LOCAL.LABELS.TEST_QUIZ,
    [LessonType.WebConference]: LOCAL.LABELS.WEBINAR,
    [LessonType.Homework]: LOCAL.LABELS.TASK,
    [LessonType.FaceToFaceMeeting]: LOCAL.LABELS.FACE_TO_FACE_MEETING,
    [LessonType.Information]: LOCAL.LABELS.INFORMATION_BLOCK,
}

/**
 * Типы тематических блоков мероприятия в родительном падеже
 */
export const THEME_BLOCK_TYPE_GENETIVE = {
    ...THEME_BLOCK_TYPE,
    [LessonType.FaceToFaceMeeting]: LOCAL.LABELS.FACE_TO_FACE_MEETING_G,
}

/**
 * Типы дат для прохождения мероприятий
 */
export const ACTIVITY_PASSING_DATE_TYPE = {
    [ActivityPassingDateType.ByActivity]: LOCAL.THROUGHOUT_THE_EVENT,
    [ActivityPassingDateType.ByLessons]: LOCAL.BY_THEMATIC_BLOCKS,
}

/**
 * Типы стилей оформления визуализации мероприятий
 */
export const ACTIVITY_CANVAS_DESIGN_TYPE = {
    [ActivityCanvasDesignType.FullScreen]: LOCAL.LABELS.FULL_SCREEN,
    [ActivityCanvasDesignType.ModalWindow]: LOCAL.LABELS.MODAL_WINDOW,
    [ActivityCanvasDesignType.List]: LOCAL.LABELS.LIST,
}

/**
 * Типы оформления мероприятия
 */
export const EVENT_DECORATION_TYPE = {
    [ActivityDecorationType.Standard]: LOCAL.LABELS.FOR_APPOINTMENT,
    [ActivityDecorationType.Visualization]:
        LOCAL.LABELS.VISUALIZATION_FOR_TRACK,
}

/**
 * Есть ли визуализация у мероприятия
 */
export const EVENT_HAVE_VISUALIZATION = {
    [ActivityDecorationType.Standard]: LOCAL.NO,
    [ActivityDecorationType.Visualization]: LOCAL.YES,
}

/**
 * Уровни программы
 */
export const PROGRAMME_LEVELS = {
    [ProgramLevel.Beginner]: LOCAL.LABELS.NOVICE,
    [ProgramLevel.Middle]: LOCAL.LABELS.MIDDLE,
    [ProgramLevel.Specialist]: LOCAL.LABELS.SPECIALIST,
}

/**
 * Форматы обучения
 */
export const LEARNING_FORMATS = {
    [StudyingFormat.Track]: LOCAL.LABELS.TRACK,
    [StudyingFormat.Distant]: LOCAL.LABELS.DISTANT,
    [StudyingFormat.Intramural]: LOCAL.LABELS.FACE_TO_FACE,
    [StudyingFormat.Mixed]: LOCAL.LABELS.MIXED,
}

/**
 * Статусы прохождения мероприятия
 */
export const ACTIVITY_PASSING_STATUSES = {
    [ActivityPassingStatus.CompletedUnsuccessfully]: LOCAL.STATUSES.NOT_PASSED,
    [ActivityPassingStatus.InProgress]: LOCAL.STATUSES.IN_WORK,
    [ActivityPassingStatus.Waiting]: LOCAL.STATUSES.AVAILABLE_FOR_PASSING,
    [ActivityPassingStatus.CompletedSuccessfully]: LOCAL.STATUSES.PASSED,
    [ActivityPassingStatus.Canceled]: LOCAL.STATUSES.CANCELED,
    [ActivityPassingStatus.Pause]: LOCAL.STATUSES.SUSPENDED,
}

/**
 * Формы продукта
 */
export const PRODUCT_TYPES = {
    [ProductType.Activity]: LOCAL.LABELS.EVENT_M,
    [ProductType.Book]: LOCAL.LABELS.BOOK,
    [ProductType.Content]: LOCAL.LABELS.CONTENT,
    [ProductType.Podcast]: LOCAL.LABELS.PODCAST,
    [ProductType.Track]: LOCAL.LABELS.TRACK,
}

/**
 * Статусы результатов опроса
 */
export const QUIZ_CHECK_STATUSES = {
    [QuizCheckStatus.Confirmed]: LOCAL.STATUSES.CONFIRMED,
    [QuizCheckStatus.InProcess]: LOCAL.STATUSES.IN_PROGRESS,
    [QuizCheckStatus.WaitForSolution]: LOCAL.STATUSES.AWAIT_DECISION,
}

/**
 * Статусы результатов очных встреч
 */
export const FACE_TO_FACE_MEETING_STATUSES = {
    [FaceToFaceMeetingStatus.Confirmed]: LOCAL.STATUSES.CONFIRMED,
    [FaceToFaceMeetingStatus.WaitForSolution]: LOCAL.STATUSES.AWAIT_DECISION,
}

/**
 * Типы рекомендаций в профиле пользователя
 */
export const RECOMMENDATIONS_TYPES = {
    [UserProfileRecommendationType.Activity]: LOCAL.LABELS.EVENT_M,
    [UserProfileRecommendationType.DevelopmentTrack]: LOCAL.LABELS.TRACK,
}

/**
 * Шорткоды для отчёта оценки 360
 */
export const ESTIMATE_360_SHORT_CODES = {
    [ReportShortCodes360.ObjectInformationAssessment_360]:
        LOCAL.LABELS.GENERAL_INFORMATION,
    [ReportShortCodes360.GroupsOfRespondents]:
        LOCAL.LABELS.RESPONDENTS_EVALUATED_GROUPS,
    [ReportShortCodes360.SpiderWebDiagramAssessment360]:
        LOCAL.LABELS.COMPETENCIES_ASSESSMENTS_BY_GROUPS,
    [ReportShortCodes360.CompetenceRating]: LOCAL.LABELS.COMPETENCE_RATING,
    [ReportShortCodes360.CompetenciesLowHighLevel]:
        LOCAL.LABELS.COMPETENCIES_LOW_HIGH_LEVEL,
    [ReportShortCodes360.AssessmentsRespondentsIndicators]:
        LOCAL.LABELS.ASSESSMENTS_RESPONDENTS_INDICATORS,
    [ReportShortCodes360.AreasStrengthsDevelopment]:
        LOCAL.LABELS.AREAS_STRENGTHS_DEVELOPMENT,
    [ReportShortCodes360.HiddenOpportunitiesProblems]:
        LOCAL.LABELS.HIDDEN_OPPORTUNITIES_PROBLEMS,
    [ReportShortCodes360.CommentsOfRespondents]:
        LOCAL.LABELS.COMMENTS_OF_RESPONDENTS,
    [ReportShortCodes360.Percentile]: LOCAL.LABELS.COMPARING_RESULTS_360,
}

/**
 * Шорткоды для отчёта руководителя
 */
export const MANAGER_SHORT_CODES = {
    [ReportShortCodesManager.ObjectInformationManagerAssessment]:
        LOCAL.LABELS.GENERAL_INFORMATION,
    [ReportShortCodesManager.SpiderWebDiagramManagerAssessment]:
        LOCAL.LABELS.GENERAL_ASSESSMENT_COMPETENCE_RESULT,
    [ReportShortCodesManager.TablesCompetenciesIndicators]:
        LOCAL.LABELS.ASSESSMENT_COWORKER_BY_COMPETENCIES_AND_INDICATORS,
    [ReportShortCodesManager.ComplianceProfilePosition]:
        LOCAL.LABELS.COMPLIANCE_PROFILE_POSITION,
    [ReportShortCodesManager.TableOfDevelopmentWithProfilePosition]:
        LOCAL.LABELS.LEVEL_COMPETENCE_DEVELOPMENT_WITH,
    [ReportShortCodesManager.TableOfDevelopmentWithoutProfilePosition]:
        LOCAL.LABELS.LEVEL_COMPETENCE_DEVELOPMENT_WITHOUT,
}

/**
 * Отчёты
 */
export const REPORTS = {
    [AssessmentType.Estimate_360]: LOCAL.LABELS.REPORT_360,
    [AssessmentType.EstimateByManager]:
        LOCAL.LABELS.REPORT_MANAGER_ASSESSMENT_RESULTS,
}

/**
 * Роли оценки для отчета
 */
export const ASSESSMENT_ROLES_SHORT = {
    ...ASSESSMENT_ROLES,
    [AssessmentRole.ColleagueFromRelatedDepartment]:
        LOCAL.LABELS.COLLEAGUES_RELATED,
    [AssessmentRole.Colleague]: LOCAL.LABELS.COLLEAGUES,
    [AssessmentRole.Subordinate]: LOCAL.LABELS.SUBORDINATES,
}

/**
 * Уровни оценки в отчетах
 */
export const REPORT_LEVELS_MESSAGES_TO = {
    [ReportLevels.Low]: LOCAL.LABELS.REPORTS_LEGEND_LOW_TO,
    [ReportLevels.Medium]: LOCAL.LABELS.REPORTS_LEGEND_MEDIUM_TO,
    [ReportLevels.High]: LOCAL.LABELS.REPORTS_LEGEND_HIGH_TO,
}

/**
 * Уровни оценок индикаторов относительно самооценки
 */
export const CERTIFICATE_KIND = {
    [CertificateKind.DevelopmentTrack]: LOCAL.LABELS.TRACK,
    [CertificateKind.Activity]: LOCAL.LABELS.EVENT_M,
}

/**
 * Соответствие профилю должности
 */
export const COMPLIANCE_POSITION_PROFILE = {
    [CompliancePositionProfile.Disbalance]:
        LOCAL.LABELS.COMPLIANCE_PROFILE_POSITION_DISBALANCE,
    [CompliancePositionProfile.LowCompliance]:
        LOCAL.LABELS.COMPLIANCE_PROFILE_POSITION_LOW,
    [CompliancePositionProfile.PartiallyCompliance]:
        LOCAL.LABELS.COMPLIANCE_PROFILE_POSITION_PARTIALLY,
    [CompliancePositionProfile.Compliance]:
        LOCAL.LABELS.COMPLIANCE_PROFILE_POSITION_FULL,
    [CompliancePositionProfile.PartiallyExceedingCompliance]:
        LOCAL.LABELS.COMPLIANCE_PROFILE_POSITION_PARTIALLY_EXCEEDING,
    [CompliancePositionProfile.AbsolutelyExceedingCompliance]:
        LOCAL.LABELS.COMPLIANCE_PROFILE_POSITION_ABSOLUTELY_EXCEEDING,
}

/**
 * Приоритет компетенций в соответствии с ПД
 */
export const COMPETENCIES_PRIORITIES_WITH_PP = {
    [StrongSidesAndZonesOfDevelopmentPriority.One]:
        LOCAL.LABELS.COMPETENCE_PRIORITY_WITH_PP_ONE,
    [StrongSidesAndZonesOfDevelopmentPriority.Two]:
        LOCAL.LABELS.COMPETENCE_PRIORITY_WITH_PP_TWO,
    [StrongSidesAndZonesOfDevelopmentPriority.Three]:
        LOCAL.LABELS.COMPETENCE_PRIORITY_WITH_PP_THREE,
    [StrongSidesAndZonesOfDevelopmentPriority.Four]:
        LOCAL.LABELS.COMPETENCE_PRIORITY_WITH_PP_FOUR,
}

/**
 * Приоритет компетенций отсутствующих в ПД
 */
export const COMPETENCIES_PRIORITIES_WITHOUT_PP = {
    [StrongSidesAndZonesOfDevelopmentPriority.One]:
        LOCAL.LABELS.COMPETENCE_PRIORITY_WITHOUT_PP_ONE,
    [StrongSidesAndZonesOfDevelopmentPriority.Two]:
        LOCAL.LABELS.COMPETENCE_PRIORITY_WITHOUT_PP_TWO,
    [StrongSidesAndZonesOfDevelopmentPriority.Three]:
        LOCAL.LABELS.COMPETENCE_PRIORITY_WITHOUT_PP_THREE,
    [StrongSidesAndZonesOfDevelopmentPriority.Four]:
        LOCAL.LABELS.COMPETENCE_PRIORITY_WITHOUT_PP_FOUR,
}

/**
 * Статусы задания на рассылку
 */
export const MAILING_STATUSES = {
    [NotificationMailingTaskStatus.Draft]: LOCAL.STATUSES.DRAFT,
    [NotificationMailingTaskStatus.Canceled]: LOCAL.STATUSES.CANCELED,
    [NotificationMailingTaskStatus.Expectation]: LOCAL.STATUSES.EXPECTATION,
    [NotificationMailingTaskStatus.InProgress]: LOCAL.STATUSES.IN_PROGRESS,
    [NotificationMailingTaskStatus.Suspended]: LOCAL.STATUSES.SUSPENDED,
    [NotificationMailingTaskStatus.Ended]: LOCAL.STATUSES.ENDED,
}

/**
 * Способ отправки задания на рассылку
 */
export const MAILING_TYPES = {
    [NotificationMailingTaskType.ByEvent]: LOCAL.LABELS.BY_EVENT,
    [NotificationMailingTaskType.AtSpecifiedTime]:
        LOCAL.LABELS.AT_SPECIFIED_TIME,
}

/**
 * Результат ответа на вопрос при прохождении тестов
 */
export const ANSWER_RESULT_TYPES = {
    [AnswerConclusion.Correct]: LOCAL.STATUSES.CORRECT_ANSWER,
    [AnswerConclusion.Incorrect]: LOCAL.STATUSES.WRONG_ANSWER,
    [AnswerConclusion.HasCorrectAndIncorrect]:
        LOCAL.STATUSES.CORRECT_AND_WRONG_ANSWER,
    [AnswerConclusion.HasNotAllCorrect]: LOCAL.STATUSES.NOT_ALL_ANSWERS,
    [AnswerConclusion.NotChecked]: LOCAL.STATUSES.NOT_ALL_ANSWERS,
    [AnswerConclusion.RequiresPersonalChecking]: LOCAL.STATUSES.NOT_ALL_ANSWERS,
}

/**
 * Статусы шаблона рассылки
 */
export const TEMPLATE_STATUSES = {
    [NotificationTemplateStatus.Draft]: LOCAL.STATUSES.DRAFT,
    [NotificationTemplateStatus.Published]: LOCAL.STATUSES.PUBLISHED,
}

/**
 * Статусы доставки сообщения электронной почты
 */
export const EMAIL_SEND_STATUSES = {
    [EmailState.Error]: LOCAL.STATUSES.ERROR,
    [EmailState.Fail]: LOCAL.STATUSES.NOT_SENT,
    [EmailState.New]: LOCAL.STATUSES.EXPECTATION,
    [EmailState.Success]: LOCAL.STATUSES.SENT,
}

/**
 * Статусы доставки сообщения электронной почты
 */
export const NOTIFICATION_DELIVERY_STATUSES = {
    [NotificationDeliveryStatus.Send]: LOCAL.STATUSES.DELIVERED_EPS,
    [NotificationDeliveryStatus.View]: LOCAL.STATUSES.VIEWED,
    [NotificationDeliveryStatus.Click]: LOCAL.STATUSES.CLICKED,
    [NotificationDeliveryStatus.Unsend]: LOCAL.STATUSES.UNSEND,
    [NotificationDeliveryStatus.Undelivered]: LOCAL.STATUSES.UNDELIVERED,
    [NotificationDeliveryStatus.Delivered]: LOCAL.STATUSES.DELIVERED,
    [NotificationDeliveryStatus.Unsubscribe]: LOCAL.STATUSES.UNSUBSCRIBED,
}

/**
 * Статусы сообщения электронной почты
 */
export const CERTIFICATE_TYPE = {
    [CertificateType.Internal]: LOCAL.LABELS.INTERNAL,
    [CertificateType.External]: LOCAL.LABELS.EXTERNAL,
}

export const ASSESSMENT_PROJECTS_EVALUATION_TYPES = {
    [AssessmentType.Estimate_360]: LOCAL.LABELS.ASSESSMENT_360,
    [AssessmentType.EstimateByManager]: LOCAL.LABELS.ASSESSMENT_BY_MANAGER,
}

export const NOTIFICATION_DICTIONARY = {
    [NotificationIntervals.EveryDay]: LOCAL.LABELS.EVERY_DAY,
    [NotificationIntervals.EveryTwoDays]: LOCAL.LABELS.EVERY_TWO_DAYS,
    [NotificationIntervals.EveryThreeDays]: LOCAL.LABELS.EVERY_THREE_DAYS,
}

export const ADDITIONAL_OPTIONS = {
    [LessonAccessType.StepByStep]: LOCAL.LABELS.STEP_BY_STEP,
    [LessonAccessType.Random]: LOCAL.LABELS.ARBITRARILY,
}

export const FONT_NAMES = {
    Arial: 'Arial',
    Calibri: 'Calibri',
    Helvetica: 'Helvetica',
    'Liberation Sans': 'Liberation Sans',
    'Liberation Serif': 'Liberation Serif',
    Roboto: 'Roboto',
    'Times New Roman': 'Times New Roman',
}

export const FONT_STYLES = {
    normal: LOCAL.LABELS.NORMAL.toLowerCase(),
    bold: LOCAL.LABELS.BOLD.toLowerCase(),
    italic: LOCAL.LABELS.ITALICS.toLowerCase(),
    underline: LOCAL.LABELS.UNDERLINED.toLowerCase(),
}

export const HOMEWORK_STATUS_TYPE = {
    [HomeworkStatusType.OnChecking]: 'На проверке',
    [HomeworkStatusType.WaitingForSolution]: 'Ожидает решения',
    [HomeworkStatusType.Revision]: 'Незачет',
    [HomeworkStatusType.Passed]: 'Зачет',
}

export const CONFIRM_REPASSING_TITLE = {
    [QuestionnaireType.Test]: LOCAL.LABELS.ADVANCE_TEST_RESULTS,
    [QuestionnaireType.Quiz]: LOCAL.LABELS.ADVANCE_QUIZ_RESULTS,
    [QuestionnaireType.Assessment]: LOCAL.LABELS.ADVANCE_QUIZ_RESULTS,
}

export const ENDING_DOCUMENT_TYPES = {
    [GraduationDocumentType.CertificateOfPassing]:
        LOCAL.LABELS.PASSING_CERTIFICATE,
    [GraduationDocumentType.CertificateOfProfessionalDevelopment]:
        LOCAL.LABELS.PROMOTION_CERTIFICATE,
    [GraduationDocumentType.ProfessionalRetrainingDiploma]:
        LOCAL.LABELS.PROFESSIONAL_RETRAINING_DIPLOMA,
}
