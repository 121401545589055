// TODO: в последствии, когда бэк будет подготовлен,
// все консоль логи будут заменены на реальную логику взаимодействия с LMS

import { ScormService } from 'core/api'

/**
 * Адаптер API для SCORM версии 2004
 */
export class Scorm2004Adapter {
    _courseId: number

    constructor(courseId: number) {
        this._courseId = courseId
    }

    async Initialize(): Promise<boolean> {
        console.info('Scorm 2004 - Initialize')
        console.log('Инициализация подключения к LMS')

        await ScormService.initialize({
            body: { courseId: this._courseId },
        })

        return true
    }

    async Terminate(): Promise<boolean> {
        console.info('Scorm 2004 - Terminate')
        console.log('Закрытие подключения к LMS')

        await ScormService.terminate({
            body: { courseId: this._courseId },
        })

        return true
    }

    async GetValue(key: string): Promise<string> {
        console.info('Scorm 2004 - GetValue')
        console.log(`Получение значения для элемента данных: ${key}`)

        await ScormService.getValue({
            body: {
                courseId: this._courseId,
                key,
            },
        })

        return key
    }

    async SetValue(key: string, value: string): Promise<string> {
        console.info('Scorm 2004 - SetValue')
        console.log(`Установка значения (${value}) для элемента: ${key}`)

        await ScormService.setValue({
            body: {
                courseId: this._courseId,
                key,
                value,
            },
        })

        return key
    }

    Commit(): boolean {
        console.info('Scorm 2004 - Commit')
        console.log('Отправка данных в LMS')

        return true
    }

    GetLastError(): number | undefined {
        console.info('Scorm 2004 - GetLastError')

        return
    }

    GetErrorString(errorCode: number): string {
        console.info('Scorm 2004 - GetErrorString', errorCode)

        return ''
    }

    GetDiagnostic(errorCode: number): string {
        console.info('Scorm 2004 - GetDiagnostic', errorCode)

        return ''
    }
}
