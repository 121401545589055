import React, { useCallback, useState } from 'react'
import { MODAL_WIDTH } from 'consts'
import { getMaterialLaunchUrl } from 'utils'

import styles from './VideoContentModal.module.scss'
import { PlayerVideo } from '../PlayerVideo'
import { Popup } from '../Popup'
import { VideoModalProps } from './VideoContentModal.types'

export const VideoContentModal: React.FC<VideoModalProps> = React.memo(
    ({ fileUrl, autoPlay, progress, onFinish, onExit, popupProps }) => {
        const [progressForSend, setProgressForSend] = useState<
            number | undefined
        >(progress)
        const [duration, setDuration] = useState<number>()

        const handleCancel = useCallback(() => {
            if (progressForSend === duration) {
                onFinish?.()
            } else {
                onExit?.(progressForSend)
            }
        }, [progressForSend, duration, onFinish, onExit])

        return (
            <Popup
                className={styles.modal}
                footer={null}
                width={MODAL_WIDTH.MD}
                onCancel={handleCancel}
                centered
                {...popupProps}
            >
                <PlayerVideo
                    fileUrl={getMaterialLaunchUrl(fileUrl)}
                    progress={progress}
                    setProgressForSend={setProgressForSend}
                    setDuration={setDuration}
                    autoPlay={autoPlay}
                />
            </Popup>
        )
    }
)
