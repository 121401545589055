import cn from 'classnames'
import React, { useMemo } from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import styles from './ListTag.module.scss'
import { ListTagProps } from './ListTag.types'

/** Компонент тега типа "List" */
export const ListTag: React.FC<ListTagProps> = React.memo(
    ({ children, onDelete, link, disabled, className }) => {
        /**
         * Рендер кнопки удаления тега
         */
        const tagDeleteBtnRender = useMemo(
            () => (
                <Button
                    type="link"
                    icon={<IconsAdapter iconType="CloseOutlined" />}
                    onClick={() => !disabled && onDelete?.()}
                />
            ),
            [onDelete, disabled]
        )

        /**
         * Рендер ссылки тега
         */
        const tagLinkRender = useMemo(
            () => (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <IconsAdapter iconType={'EyeOutlined'} />
                </a>
            ),
            [link]
        )

        return (
            <div className={cn(styles.wrapper, className)}>
                <div>{children}</div>

                <div className={styles.actions}>
                    {onDelete && tagDeleteBtnRender}

                    {link && tagLinkRender}
                </div>
            </div>
        )
    }
)
