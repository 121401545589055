import React from 'react'
import { ComponentCommonProps } from 'App.types'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
} from 'components/controls'
import { LOCAL } from 'core/local'

/** Компонент области "дополнительные требования" для формы профиля должности */
export const AdditionalRequirementsGroup: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <ControlGroup
            type="dark"
            title={LOCAL.LABELS.ADDITIONAL_REQUIREMENTS}
            tooltipText={LOCAL.TOOLTIPS.ADDITIONAL_REQUIREMENTS_OF_JOB_PROFILE}
        >
            <FormItemAdapter fieldName="additionalRequirements">
                <InputControl formMode={formMode} />
            </FormItemAdapter>
        </ControlGroup>
    )
)
