import React from 'react'
import { ButtonsToolbar, TableActions } from 'components/shared'
import { HomeworkRegistryItemContract } from 'core/api'
import { LOCAL } from 'core/local'
import { TableActionsCommonProps } from 'App.types'

export const HomeworksActions: React.FC<TableActionsCommonProps<
    HomeworkRegistryItemContract
>> = React.memo(() => (
    <ButtonsToolbar bottomIndent="0">
        <TableActions.Play title={LOCAL.ACTIONS.START} href="#s" />

        <TableActions.Info title={LOCAL.LABELS.INFORMATION} href="#s" />
    </ButtonsToolbar>
))
