import find from 'lodash/find'
import {
    CompetenceGroupsPublicQuestionsContract,
    PassingFormQuestionAnswerContract,
    QuestionPublicContract,
} from 'core/api'
import { Store } from 'App.types'

import { PassingDataProps } from '../../QuestionnairePassing.types'

export const getQuestionsGroupedByQuestionList = (
    groupedQuestions: CompetenceGroupsPublicQuestionsContract[]
) =>
    Object.values(
        groupedQuestions.reduce<Store<QuestionPublicContract[]>>(
            (acc, competenceGroup) => {
                competenceGroup.competenceQuestions.forEach((competence) => {
                    competence.questions.forEach((question) => {
                        const questionnaireId = question.questionnaire.id

                        if (!acc[questionnaireId]) {
                            acc[questionnaireId] = [question]

                            return
                        }

                        acc[questionnaireId].push(question)
                    })
                })

                return acc
            },
            {}
        )
    ).sort(
        (a, b) =>
            a[0].testPortfolioQuestionnaireOrder -
            b[0].testPortfolioQuestionnaireOrder
    )

/** Обновить номер вопроса в соответствии с порядковым номером в опросном листе */
export const updateQuestionNumDueQuestionnaireList = (
    groupedQuestions: QuestionPublicContract[][]
) => {
    groupedQuestions.forEach((list) => list.sort((a, b) => a.number - b.number))

    return groupedQuestions.map((list) =>
        list.map((question, index) => ({
            ...question,
            number: index + 1,
        }))
    )
}

export const getSortedQuestionsLists = (
    groupedQuestions: CompetenceGroupsPublicQuestionsContract[]
) =>
    updateQuestionNumDueQuestionnaireList(
        getQuestionsGroupedByQuestionList(groupedQuestions)
    )

export const getBlockedByQuestionEndToEndNumber = (
    questionsList: QuestionPublicContract[],
    passingData: PassingDataProps
) => (blockingQuestionId?: number) => {
    if (!blockingQuestionId) return

    const blockingQuestionNumber = questionsList.find(
        (el) => el.id === blockingQuestionId
    )?.number

    if (
        passingData.questionnaire.answers.every(
            (el) => el.questionId !== blockingQuestionId
        )
    )
        return blockingQuestionNumber
}

export const isAllRequiredQuestionsConfirmed = (
    list: QuestionPublicContract[],
    confirmedAnswers: PassingFormQuestionAnswerContract[]
) =>
    list.every((question) =>
        question.mandatoryQuestion
            ? find(confirmedAnswers, ['questionId', question.id])
            : true
    )

export const getQuestionsListsState = (
    lists: QuestionPublicContract[][],
    confirmedAnswers: PassingFormQuestionAnswerContract[]
) =>
    lists.reduce<Store<boolean>>(
        (store, list, index) => ({
            ...store,
            [index]: isAllRequiredQuestionsConfirmed(list, confirmedAnswers),
        }),
        {}
    )

export const isQuestionsListCompletedFn = (
    listIndex: number,
    listState?: Store<boolean>
) => {
    if (!listState) return false

    if (listIndex < 0) return true

    return listState[listIndex]
}
