import cn from 'classnames'
import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter, SpaceAdapter, Title } from 'components/shared'
import { LEARNING_FORMATS } from 'consts'

import styles from './CatalogUnit.module.scss'
import { CatalogUnitProps } from './CatalogUnit.types'
import { handleCardView } from '../../MainPage.utils'
import { toggleFavoriteStatus } from './CatalogUnit.utils'

/** Элемент для включения в шаблонную секцию каталога */
export const CatalogUnit: React.FC<CatalogUnitProps> = React.memo(
    ({
        id,
        name,
        favorites,
        onUpdateFavourites,
        studyingFormat,
        hasIndicatorBookingClosed,
    }) => {
        /** Добавить элемент в раздел "избранное" */
        const handlePickFavorite = useCallback(
            async (e) => {
                e.preventDefault()
                e.stopPropagation()

                await toggleFavoriteStatus({
                    itemId: id,
                    isFavorite: favorites,
                })
                await onUpdateFavourites(id)
            },
            [onUpdateFavourites, id, favorites]
        )

        return (
            <a
                className={styles.wrapper}
                href="#s"
                onClick={handleCardView(id)}
            >
                <Title type="h6" text={name} className={styles.title} />

                <SpaceAdapter align="center">
                    <div className={styles.learningFormats}>
                        {LEARNING_FORMATS[studyingFormat]}
                    </div>

                    {hasIndicatorBookingClosed && (
                        <IconsAdapter
                            className={styles.filledGroups}
                            iconType="StopOutlined"
                        />
                    )}

                    <Button
                        type="link"
                        className={cn(
                            styles.favorite,
                            favorites && styles.favoriteActive
                        )}
                        icon={<IconsAdapter iconType="CustomIconStar" />}
                        onClick={handlePickFavorite}
                    />
                </SpaceAdapter>
            </a>
        )
    }
)
