import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { PositionProfileSearchForModalResultContract } from 'core/api'

/** Колонки для модального окна поиска профиля должности */
export const POSITION_PROFILE_SEARCH_TABLE_COLUMNS: ColumnsType<PositionProfileSearchForModalResultContract> = [
    {
        title: LOCAL.LABELS.NAME,
        key: 'name',
        dataIndex: 'name',
    },
    {
        title: LOCAL.LABELS.POSITION,
        key: 'positionName',
        dataIndex: 'positionName',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        key: 'organizationName',
        dataIndex: 'organizationName',
    },
    {
        title: LOCAL.LABELS.STRUCTURAL_UNIT,
        key: 'structuralUnitName',
        dataIndex: 'structuralUnitName',
    },
]
