import React, { FC, useCallback, useMemo } from 'react'
import {
    CompetenceGroupContract,
    CompetenceGroupsService,
    UserPermission,
} from 'core/api'
import { DICTIONARY_KEYS, FORM_IDS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import { MODAL_WIDTH } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getBackLinkTitleFromRoute } from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'

import { COMPETENCIES_GROUPS_TABLE_COLUMNS } from './consts/CompetenciesGroups.consts'
import { CompetenciesGroupForm } from './components'

/** Справочник группы компетенций */
export const CompetenciesGroups: FC = React.memo(() => {
    const searchMethod = useCallback(async (params) => {
        const response = await CompetenceGroupsService.getAll(params?.body)

        return {
            total: response.length,
            pageItems: response,
        }
    }, [])

    const quickSearchMethod = useCallback(async (params) => {
        const response = await CompetenceGroupsService.searchText(params)

        return {
            total: 0,
            pageItems: response,
        }
    }, [])

    const createRecordModal = useMemo(
        () => ({
            component: CompetenciesGroupForm,
            formId: FORM_IDS.COMPETENCIES_GROUP_FORM,
            modalOptions: {
                width: MODAL_WIDTH.MD,
                footer: null,
            },
            bodyWithoutScroll: true,
        }),
        []
    )

    const createPermissions = useMemo(
        () => [
            UserPermission.CompetenceGroupRead,
            UserPermission.CompetenceGroupSave,
        ],
        []
    )

    const backLink = useMemo(
        () => ({
            path: ROUTE_NAMES.COMPETENCIES,
            text: getBackLinkTitleFromRoute(ROUTE_NAMES.COMPETENCIES),
        }),
        []
    )

    return (
        <DictionaryPage<{}, CompetenceGroupContract>
            dictionaryKey={DICTIONARY_KEYS.COMPETENCIES_GROUPS}
            deleteMethod={CompetenceGroupsService.delete}
            searchMethod={searchMethod}
            contentTitle={getRouteMeta(ROUTE_NAMES.COMPETENCIES_GROUPS)}
            quickSearchMethod={quickSearchMethod}
            createPermissions={createPermissions}
            tableColumns={COMPETENCIES_GROUPS_TABLE_COLUMNS}
            createRecordModal={createRecordModal}
            backLink={backLink}
            withPagination={false}
        />
    )
})
