import React, { FC, useCallback, useMemo, useState } from 'react'
import { COL_xl8_lg12_xs24, ROW_GUTTER } from 'consts'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { backwardFn } from 'utils'

import { CatalogSection } from '../CatalogSection'
import { CatalogUnit } from '../CatalogUnit'
import { FavoriteItemsProps } from './FavoriteItems.types'
import { MIN_ITEMS_COUNT } from '../CatalogSection/CatalogSection.consts'
import {
    getCatalogSectionHeader,
    renderShowAllButton,
} from '../CatalogSection/CatalogSection.utils'

/** Раздел "избранное" для каталога */
export const FavoriteItems: FC<FavoriteItemsProps> = React.memo(
    ({ favorites, onUpdateFavourites }) => {
        const [isShowAllCourses, setShowAllCourses] = useState(false)

        const handleShowAllCourses = useCallback(() => {
            setShowAllCourses(backwardFn)
        }, [])

        const showAllButton = useMemo(
            () =>
                renderShowAllButton({
                    elemsCount: favorites.length,
                    onClick: handleShowAllCourses,
                    btnText: isShowAllCourses
                        ? LOCAL.ACTIONS.HIDE
                        : `${LOCAL.ACTIONS.SHOW_ALL} ${favorites.length}`,
                }),
            [favorites, handleShowAllCourses, isShowAllCourses]
        )

        const favoriteCoursesItems = useMemo(() => {
            const items = favorites.map((el) => (
                <Col key={el.id} {...COL_xl8_lg12_xs24}>
                    <CatalogUnit
                        onUpdateFavourites={onUpdateFavourites}
                        {...el}
                    />
                </Col>
            ))

            if (items?.length && !isShowAllCourses) {
                return items.filter((el, index) => index < MIN_ITEMS_COUNT)
            }

            return items
        }, [favorites, isShowAllCourses, onUpdateFavourites])

        return (
            <CatalogSection
                header={getCatalogSectionHeader(
                    LOCAL.LABELS.FAVORITE,
                    showAllButton
                )}
                content={
                    <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
                        {favoriteCoursesItems}
                    </Row>
                }
            />
        )
    }
)
