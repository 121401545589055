import React, { useCallback } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import {
    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED,
    QUESTIONNAIRE_TYPES_MAPPED,
    QUIZ_TYPES_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import {
    FiltersProps,
    excludeSelectOptions,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeQuiz,
    isQuestionnaireTypeTest,
    normalizeDictionaryToControl,
} from 'utils'
import { LOCAL } from 'core/local'
import {
    QuestionnaireSearchContract,
    QuestionnaireType,
    QuizType,
} from 'core/api'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionariesHelper } from 'hooks'

/**
 * Форма фильтрации опросных листов
 */
export const QuestionnairesFilters: React.FC<FiltersProps<
    QuestionnaireSearchContract
>> = React.memo((props) => {
    const { assessmentTypeDictionary } = useDictionariesHelper([
        'assessmentTypeDictionary',
    ])
    const mapFormDataToRequest = useCallback(
        (values: Partial<QuestionnaireSearchContract>) => ({
            ...values,
            types: values?.types ? [values.types] : undefined,
        }),
        []
    )

    /**
     * Определяем селект для фильтра по виду опросного листа
     * @param documentType тип опросного листа
     */
    const getQuestionnaireKindControl = useCallback(
        (documentType: QuestionnaireType) => {
            if (isQuestionnaireTypeQuiz(documentType))
                return (
                    <FormItemAdapter fieldName="quizType" label="Вид опроса">
                        <SelectControl
                            values={excludeSelectOptions(QUIZ_TYPES_MAPPED, [
                                QuizType.Random,
                            ])}
                            showSearch
                        />
                    </FormItemAdapter>
                )

            if (isQuestionnaireTypeAssessment(documentType))
                return (
                    <FormItemAdapter
                        fieldName="assessmentType"
                        label={LOCAL.LABELS.ASSESSMENT_KIND}
                    >
                        <SelectControl
                            values={normalizeDictionaryToControl(
                                assessmentTypeDictionary
                            )}
                            showSearch
                        />
                    </FormItemAdapter>
                )
        },
        [assessmentTypeDictionary]
    )

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                mapFormDataToRequest={mapFormDataToRequest}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="types"
                            label={LOCAL.LABELS.DOCUMENT_TYPE}
                        >
                            <SelectControl
                                values={QUESTIONNAIRE_TYPES_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <ShouldUpdateChecker fieldPath={['types']}>
                        {(form) => {
                            const documentType = form.getFieldValue('types')

                            return (
                                documentType &&
                                !isQuestionnaireTypeTest(documentType) && (
                                    <Col xs={8}>
                                        {getQuestionnaireKindControl(
                                            documentType
                                        )}
                                    </Col>
                                )
                            )
                        }}
                    </ShouldUpdateChecker>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="includeStatuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changeDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changeDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
