import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { normalizeDataForSelectAndRadio } from 'utils'

import { SelectControl } from '../SelectControl'
import { SelectPictureControlProps } from './SelectPictureControl.types'
import {
    getPictureData,
    selectAddonAfterPicture,
} from './SelectPictureControl.utils'

/**
 * Компонент реализует логику комбинирования select поля и рендера картинки в зависимости от выбранного результата
 */
export const SelectPictureControl: React.FC<SelectPictureControlProps> = React.memo(
    ({ selectValues, onChange, value, formMode, ...props }) => {
        /**
         * id текущего выбранного элемента селекта
         */
        const [currentPictureId, setCurrentPictureId] = useState<number>()

        /**
         * Инициировать обновление состояние компонента, а также значения внешней формы
         */
        const triggerChanges = useCallback(
            (value) => {
                setCurrentPictureId(value)

                if (onChange) onChange(value)
            },
            [onChange]
        )

        /**
         * Объект данных по текущей выбранной картинке в селект
         */
        const currentPictureItem = useMemo(
            () =>
                getPictureData({
                    picturesCollection: selectValues,
                    selectedPictureId: currentPictureId,
                }),
            [selectValues, currentPictureId]
        )

        /**
         * Устанавливка дефолтных значений
         */
        useEffect(() => {
            if (currentPictureId !== value) setCurrentPictureId(value)
        }, [value, setCurrentPictureId, currentPictureId])

        return (
            <SelectControl
                values={selectValues?.map(normalizeDataForSelectAndRadio) || []}
                onChange={triggerChanges}
                addonAfterColumn={selectAddonAfterPicture({
                    selectedPictureItem: currentPictureItem,
                })}
                value={value}
                formMode={formMode}
                {...props}
            />
        )
    }
)
