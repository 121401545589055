import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import {
    ControlGroup,
    DEVELOPMENT_COALS_TABLE_COLUMNS,
    DevelopmentGoalsFilters,
    SearchTableShowButton,
} from 'components/controls'
import {
    DevelopmentGoalsSearchContract,
    DevelopmentGoalsSearchResultContract,
    DevelopmentGoalsService,
    UserDevelopmentGoalContract,
    UserProfileFavoriteRecommendationContract,
    UserProfileRecommendationContract,
    UserProfilesService,
} from 'core/api'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { withLoader } from 'HOCs'

import styles from './UserProfileGoals.module.scss'
import {
    HandleChosenRecommendationProps,
    UserProfileGoalsProps,
} from './UserProfileGoals.types'
import { UserGoalsItem } from '../UserGoalsItem'
import { UserProfileRecommendationsItem } from '../UserProfileRecommendationsItem'
import { YourChosenItem } from '../YourChosenItem'
import { getChosen } from './UserProfileGoals.utils'

/** Список целей пользователя */
export const UserProfileGoals: React.FC<UserProfileGoalsProps> = withLoader(
    React.memo(({ userStructuralUnitPosition, userId, updateLoader }) => {
        const [userGoals, setUserGoals] = useState<
            UserDevelopmentGoalContract[]
        >()
        const [userRecommendations, setUserRecommendations] = useState<
            UserProfileRecommendationContract[]
        >()
        const [favorite, setFavorite] = useState<
            UserProfileFavoriteRecommendationContract[]
        >()
        const isDisabledElement = !!userStructuralUnitPosition?.fireDate

        /**
         * Запрос для получения выбранных рекомендаций
         */
        const fetchFavoriteRecommendations = useCallback(
            async (userId: number) => {
                try {
                    setFavorite(
                        await UserProfilesService.getFavoriteRecommendations({
                            userId,
                        })
                    )
                } catch (e) {
                    console.log(e)
                }
            },
            []
        )

        /**
         * Запрос для получения целей пользователя
         */
        const fetchItems = useCallback(async () => {
            try {
                if (!userId || !userStructuralUnitPosition?.id) return

                updateLoader(true)

                setUserGoals(
                    await UserProfilesService.getUserDevelopmentGoals({
                        userStructuralUnitPositionId:
                            userStructuralUnitPosition.id,
                    })
                )

                setUserRecommendations(
                    await UserProfilesService.getRecommendations({
                        userId,
                    })
                )
                await fetchFavoriteRecommendations(userId)
            } catch (e) {
                console.log(e)
            } finally {
                updateLoader(false)
            }
        }, [
            userId,
            userStructuralUnitPosition,
            updateLoader,
            fetchFavoriteRecommendations,
        ])

        /**
         * Запрос сохранения выбранных целей пользователя
         * @param ids id целей
         */
        const saveSelectedItem = useCallback(
            async (ids: number[]) => {
                try {
                    updateLoader(true)

                    if (userStructuralUnitPosition?.id) {
                        await UserProfilesService.saveUserDevelopmentGoals({
                            body: {
                                userStructuralUnitPositionId:
                                    userStructuralUnitPosition.id,
                                developmentGoalIds: ids,
                            },
                        })
                        fetchItems()
                    }
                } catch (err) {
                    console.error(err)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader, userStructuralUnitPosition, fetchItems]
        )

        /**
         * Обработчик, вызываемый после закрытия всплывающего окна выбора целей
         */
        const handleSelectGoals = useCallback(
            (ids?: number[]) => {
                if (ids?.length) {
                    saveSelectedItem(ids)
                }
            },
            [saveSelectedItem]
        )

        /**
         * Маппинг списка целей пользователя
         */
        const userGoalsList = useMemo(
            () =>
                userGoals?.map((el) => (
                    <UserGoalsItem
                        userDevelopmentGoal={el}
                        key={el.id}
                        onFetchGoals={fetchItems}
                        disabled={isDisabledElement}
                        userId={userId}
                    />
                )),
            [userGoals, fetchItems, isDisabledElement, userId]
        )

        /**
         * Массив id целей пользователя
         */
        const userGoalsIds = useMemo(
            () => userGoals?.map((el) => el.developmentGoal.id),
            [userGoals]
        )

        /**
         * Обработчик добавления\удаления из раздела "Вы выбрали"
         * @param item сущность, с которой работаем
         * @param isAdd true, если добавляем
         */
        const handleChosenRecommendation = useCallback(
            async ({
                item: { name, ...item },
                isAdd,
            }: HandleChosenRecommendationProps) => {
                try {
                    if (!userId) return
                    updateLoader(true)

                    if (isAdd) {
                        await UserProfilesService.saveFavoriteRecommendations({
                            body: {
                                userId,
                                recommendations: [item],
                            },
                        })
                    } else {
                        await UserProfilesService.deleteFavoriteRecommendations(
                            {
                                body: [item.id],
                            }
                        )
                    }
                    await fetchFavoriteRecommendations(userId)
                } catch (e) {
                    console.log(e)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader, userId, fetchFavoriteRecommendations]
        )

        /**
         * Маппинг списка рекомендаций пользователя
         */
        const userRecommendationsList = useMemo(
            () =>
                userRecommendations?.map((el, i) => {
                    const chosen = getChosen(el, favorite)

                    return (
                        <UserProfileRecommendationsItem
                            key={i}
                            recommendation={{ id: chosen?.id, ...el }}
                            onChosenRecommendation={handleChosenRecommendation}
                            checked={!!chosen}
                        />
                    )
                }),
            [userRecommendations, favorite, handleChosenRecommendation]
        )

        /**
         * Маппинг списка выбранных пользователем событий
         */
        const favoriteList = useMemo(
            () =>
                favorite?.map((el) => (
                    <YourChosenItem
                        key={el.id}
                        chosen={el}
                        onRemoveChosen={handleChosenRecommendation}
                    />
                )),
            [favorite, handleChosenRecommendation]
        )

        useEffect(() => {
            fetchItems()
        }, [fetchItems])

        return (
            <>
                <ControlGroup
                    title={LOCAL.CATALOGS.DEVELOPMENT_GOALS}
                    noPadding
                    className={styles.wrapper}
                >
                    {userGoalsList}

                    <SearchTableShowButton<
                        DevelopmentGoalsSearchContract,
                        DevelopmentGoalsSearchResultContract
                    >
                        tableSearchOptions={{
                            rowSelectionType: 'checkbox',
                            formId: FORM_IDS.GOALS_FORM,
                            onSelectTableItem: handleSelectGoals,
                            searchMethod: DevelopmentGoalsService.search,
                            initialValues: userGoalsIds,
                            tableColumns: DEVELOPMENT_COALS_TABLE_COLUMNS,
                            filterComponent: DevelopmentGoalsFilters,
                            disabledRows: userGoalsIds,
                        }}
                        modalTitle={LOCAL.LABELS.CHOOSING_DEVELOPMENT_COALS}
                        className={styles.searchTableShowButton}
                    >
                        <AddButton disabled={isDisabledElement} />
                    </SearchTableShowButton>
                </ControlGroup>

                {userStructuralUnitPosition?.isMain && (
                    <>
                        {!!userRecommendations?.length && (
                            <ControlGroup
                                title={LOCAL.LABELS.RECOMMENDATIONS}
                                noPadding
                            >
                                <div className={styles.scroll}>
                                    {userRecommendationsList}
                                </div>
                            </ControlGroup>
                        )}

                        {!!favorite?.length && (
                            <ControlGroup
                                title={LOCAL.LABELS.YOU_HAVE_CHOSEN}
                                type="bordered"
                            >
                                <div className={styles.scroll}>
                                    {favoriteList}
                                </div>
                            </ControlGroup>
                        )}
                    </>
                )}
            </>
        )
    })
)
