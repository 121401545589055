import React from 'react'
import { CheckboxControl, ShouldUpdateChecker } from 'components/controls'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { isFormModeView, isThemeBlockTypeActivity } from 'utils'

import { AttachmentFilesProps } from './AttachmentFiles.types'
import { FIELDS_NAME } from './AttachmentFiles.conts'
import { ThemeBlockAttachments } from '../ThemeBlockAttachments'
import { getThemeBlockType } from '../ThemeBlock/ThemeBlock.utils'

export const AttachmentFiles: React.FC<AttachmentFilesProps> = React.memo(
    ({ field, form, formMode, disabled }) => (
        <ShouldUpdateChecker fieldPath={['lessons', field.name, 'type']}>
            {({ getFieldValue }) => {
                const themeBlockType = getThemeBlockType(getFieldValue, field)

                return (
                    !isThemeBlockTypeActivity(themeBlockType) && (
                        <>
                            <Form.Item
                                name={[field.name, 'isContainsAttachments']}
                                noStyle
                            >
                                <CheckboxControl
                                    formMode={formMode}
                                    disabled={disabled}
                                >
                                    {LOCAL.LABELS.CONTAINS_ATTACHMENTS}
                                </CheckboxControl>
                            </Form.Item>

                            <ShouldUpdateChecker
                                fieldPath={[
                                    FIELDS_NAME,
                                    field.name,
                                    'isContainsAttachments',
                                ]}
                            >
                                {({ getFieldValue }) =>
                                    getFieldValue([
                                        FIELDS_NAME,
                                        field.name,
                                        'isContainsAttachments',
                                    ]) && (
                                        <ThemeBlockAttachments
                                            fieldName={field.name}
                                            form={form}
                                            disabled={
                                                isFormModeView(formMode) ||
                                                disabled
                                            }
                                            formMode={formMode}
                                        />
                                    )
                                }
                            </ShouldUpdateChecker>
                        </>
                    )
                )
            }}
        </ShouldUpdateChecker>
    )
)
