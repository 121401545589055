import { HomeworksService } from 'core/api'

import { FinishHomeworkProps } from './SubtaskBlock.types'

export const finishHomework = async ({
    body,
    form,
    fieldName,
    status,
}: FinishHomeworkProps) => {
    await HomeworksService.tutorCheck({
        body: {
            ...body,
            homeworkStatusType: status,
        },
    })

    form?.setFields([
        {
            name: ['taskPassings', fieldName, 'status'],
            value: status,
        },
    ])
}
