import {
    AdditionalsAvailability,
    AppointmentTrackStatus,
    PassingType,
    TrackStageAvailableStatus,
    TrackStageMaterialType,
    TrackType,
} from 'core/api'
import {
    BEFORE_START,
    IN_PROCESS,
    PASSING,
} from 'consts/trackStagesStatuses.consts'
import {
    IconStatusProps,
    TrackStageMode,
} from 'components/pages/Track/components/TrackForm/TrackForm.types'
import { InfoAssistantType } from 'components/shared/InfoAssistant/InfoAssistant.types'

/**
 * Проверка типа трека
 * @param type - типа
 */
export const isTrackTypeAdaptation = (type?: TrackType) =>
    type === TrackType.Adaptation
export const isTrackTypeDevelopment = (type?: TrackType) =>
    type === TrackType.Development
export const isTrackTypeUnique = (type?: TrackType) => type === TrackType.Mguu

/**
 * Проверка статуса назначения трека
 * @param status - статус назначения трека
 */
export const isAppointmentTrackStatusDraft = (
    status?: AppointmentTrackStatus
) => status === AppointmentTrackStatus.Draft

export const isAppointmentTrackStatusInWork = (
    status?: AppointmentTrackStatus
) => status === AppointmentTrackStatus.InWork

export const isAppointmentTrackStatusAvailableToPassing = (
    status?: AppointmentTrackStatus
) => status === AppointmentTrackStatus.AvailableToPassing

export const isAppointmentTrackStatusAppointment = (
    status?: AppointmentTrackStatus
) => status === AppointmentTrackStatus.Appointment

export const isAppointmentTrackStatusEnded = (
    status?: AppointmentTrackStatus
) => status === AppointmentTrackStatus.Ended

export const isAppointmentTrackStatusSuspended = (
    status?: AppointmentTrackStatus
) => status === AppointmentTrackStatus.Suspended

export const isAppointmentTrackStatusCanceled = (
    status?: AppointmentTrackStatus
) => status === AppointmentTrackStatus.Canceled

export const isAppointmentTrackStatusPassed = (
    status?: AppointmentTrackStatus
) => status === AppointmentTrackStatus.Passed

export const isAppointmentTrackStatusNotPassed = (
    status?: AppointmentTrackStatus
) => status === AppointmentTrackStatus.NotPassed

export const isAppointmentTrackPassedOrNotPassed = (
    status?: AppointmentTrackStatus
) =>
    status === AppointmentTrackStatus.NotPassed ||
    status === AppointmentTrackStatus.Passed

/**
 * Проверка типа этапа у трека
 * @param type тип материала в этапе
 * @return true, если тип совпадает
 */
export const isMaterialTypeActivity = (
    type?: TrackStageMaterialType
): boolean => type === TrackStageMaterialType.Activity

export const isMaterialTypeContent = (type?: TrackStageMaterialType): boolean =>
    type === TrackStageMaterialType.Content

/**
 * Проверка статуса этапа у трека
 * @param status статус этапа
 * @return true, если статус совпадает
 */
export const isStageStatusAvailable = (status?: TrackStageAvailableStatus) =>
    status === TrackStageAvailableStatus.Available

/** Проверка порядка прохождения трека */
export const isPassingTypeConsistently = (type?: PassingType) =>
    type === PassingType.Consistently

/**
 * Проверка просрочен ли трек на 1 день
 */
export const isExpiredTrackDate = (
    date: Date,
    status: AppointmentTrackStatus
) => {
    const validateDate = new Date(date)
    validateDate.setDate(validateDate.getDate() + 1)

    return validateDate < new Date() && !isAppointmentTrackStatusInWork(status)
}

/**
 * Получить количество тематических блоков для этапа трека, для контента всегда 1
 */
export const getBlocksCountOfTrackStage = (
    type?: TrackStageMaterialType,
    totalThematicBlocksCount?: number
) => {
    if (type === TrackStageMaterialType.Content) return 1

    return totalThematicBlocksCount || 0
}

export const isStageModePassing = (mode?: TrackStageMode) => mode === 'passing'

export const isStageModePreview = (mode?: TrackStageMode) => mode === 'preview'

export const isStageModeCreate = (mode?: TrackStageMode) => mode === 'create'

/**
 * Проверка доступности дополнительного материала в треке
 */
export const isAdditionalAvailabilityAlways = (
    type?: AdditionalsAvailability
) => type === AdditionalsAvailability.Always

/**
 * Получить текущую стадию трека по статусу
 * @param status статус этапа
 * @param isInProcessForPassingConsistently флаг для статуса иконки при последовательном прохождении
 */
export const currentTrackStageByStatus = (
    status: AppointmentTrackStatus,
    isInProcessForPassingConsistently: boolean
): IconStatusProps => {
    if (BEFORE_START.includes(status)) {
        return isInProcessForPassingConsistently ? 'inProcess' : 'beforeStart'
    }

    if (IN_PROCESS.includes(status)) {
        return 'inProcess'
    }

    if (PASSING.includes(status)) {
        return 'passing'
    }

    return 'beforeStart'
}

export const isTrackAssistantTypeTrack = (type: InfoAssistantType) =>
    type === InfoAssistantType.Track

export const isNotAvailableStatus = (status?: TrackStageAvailableStatus) =>
    status === TrackStageAvailableStatus.NotAvailable
