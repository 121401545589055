import React from 'react'
import { ButtonsToolbar, IconsAdapter, PopupWithForm } from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { IndicatorContract, UserPermission } from 'core/api'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { TableActionsCommonProps } from 'App.types'
import { isDocumentStatusInWork, isDocumentStatusReadyForWork } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

/** Компонент для создания колонки действий внутри таблицы реестра индикаторов */
export const IndicatorsActions: React.FC<TableActionsCommonProps<
    IndicatorContract
>> = React.memo(({ tableRow }) => {
    const isEditableCompetenciesIndicators = !isDocumentStatusInWork(
        tableRow.status
    )

    const isDeletable =
        !isDocumentStatusReadyForWork(tableRow.status) &&
        !isDocumentStatusInWork(tableRow.status)

    return (
        <ButtonsToolbar bottomIndent="0">
            {tableRow.active && (
                <>
                    {isEditableCompetenciesIndicators && (
                        <>
                            <HasPermissions
                                requiredPermissions={[
                                    UserPermission.IndicatorRead,
                                    UserPermission.IndicatorSave,
                                ]}
                                accessDeniedRender={renderAccessDeniedButton({
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="EditOutlined" />
                                    ),
                                })}
                            >
                                <PopupWithForm
                                    formId={
                                        FORM_IDS.COMPETENCIES_INDICATOR_FORM
                                    }
                                    buttonText=""
                                    havePopup={false}
                                    recordCopy={false}
                                    buttonOption={{
                                        title: LOCAL.ACTIONS.EDIT,
                                        type: 'link',
                                        icon: (
                                            <IconsAdapter iconType="EditOutlined" />
                                        ),
                                    }}
                                    formOptions={{
                                        initialValues: tableRow,
                                    }}
                                    modalOptions={{
                                        title: LOCAL.ACTIONS.RECORD_EDIT,
                                        width: MODAL_WIDTH.MD,
                                        footer: null,
                                    }}
                                />
                            </HasPermissions>

                            <HasPermissions
                                requiredPermissions={[
                                    UserPermission.IndicatorRead,
                                    UserPermission.IndicatorSave,
                                ]}
                                accessDeniedRender={renderAccessDeniedButton({
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="CopyOutlined" />
                                    ),
                                })}
                            >
                                <PopupWithForm
                                    formId={
                                        FORM_IDS.COMPETENCIES_INDICATOR_FORM
                                    }
                                    buttonText=""
                                    havePopup={false}
                                    recordCopy={true}
                                    buttonOption={{
                                        title: LOCAL.ACTIONS.COPY,
                                        type: 'link',
                                        icon: (
                                            <IconsAdapter iconType="CopyOutlined" />
                                        ),
                                    }}
                                    formOptions={{
                                        initialValues: tableRow,
                                    }}
                                    modalOptions={{
                                        title: LOCAL.ACTIONS.RECORD_COPY,
                                        footer: null,
                                    }}
                                />
                            </HasPermissions>
                        </>
                    )}

                    {isDocumentStatusReadyForWork(tableRow.status) && (
                        <HasPermissions
                            requiredPermissions={[
                                UserPermission.IndicatorRead,
                                UserPermission.IndicatorDelete,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                type: 'link',
                                icon: (
                                    <IconsAdapter iconType="FileZipOutlined" />
                                ),
                            })}
                        >
                            <PopupWithForm
                                formId={FORM_IDS.CONFIRM_DELETE_FORM}
                                buttonText=""
                                havePopup={false}
                                buttonOption={{
                                    title: LOCAL.ACTIONS.RECORD_TO_ARCHIVE,
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="FileZipOutlined" />
                                    ),
                                }}
                                formOptions={{
                                    initialValues: tableRow,
                                }}
                                modalOptions={{
                                    title: LOCAL.ACTIONS.RECORD_TO_ARCHIVE,
                                    okText: LOCAL.YES,
                                }}
                                messageText={
                                    LOCAL.MESSAGES.RECORD_TO_ARCHIVE_CONFIRM
                                }
                            />
                        </HasPermissions>
                    )}

                    {isDeletable && (
                        <HasPermissions
                            requiredPermissions={[
                                UserPermission.IndicatorRead,
                                UserPermission.IndicatorDelete,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                type: 'link',
                                icon: (
                                    <IconsAdapter iconType="DeleteOutlined" />
                                ),
                            })}
                        >
                            <PopupWithForm
                                formId={FORM_IDS.CONFIRM_DELETE_FORM}
                                buttonText=""
                                havePopup={false}
                                buttonOption={{
                                    title: LOCAL.ACTIONS.DELETE,
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="DeleteOutlined" />
                                    ),
                                }}
                                formOptions={{
                                    initialValues: tableRow,
                                }}
                                modalOptions={{
                                    title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                                    okText: LOCAL.YES,
                                }}
                            />
                        </HasPermissions>
                    )}
                </>
            )}
        </ButtonsToolbar>
    )
})
