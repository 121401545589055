import { LOCAL } from 'core/local'
import { QuestionnairesConstructorFormSubmitValues } from 'components/pages/Questionnaires/components/QuestionnairesConstructorForm/QuestionnairesConstructorForm.types'
import {
    isAssessmentTypeExpert,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeTest,
} from 'utils'
import { isRespondentTypeEstimated } from 'components/pages/Questionnaires/components/QuestionnairesConstructorForm/QuestionnairesConstructorForm.utils'

import { QuestionVariationValueProps } from '../QuestionVariation/QuestionVariation.types'

/**
 * Заблокировать поле "Баллы" для редактирования
 * @param values - текущие значения формы
 */
export const disablePoints = (
    values: Partial<QuestionnairesConstructorFormSubmitValues>
) => {
    const isShouldDisabled = !(
        isQuestionnaireTypeTest(values.type) ||
        (isQuestionnaireTypeAssessment(values.type) &&
            isRespondentTypeEstimated(values.respondentTypeId) &&
            isAssessmentTypeExpert(values.assessmentType))
    )

    if (isShouldDisabled)
        return { disabled: true, placeholderDisabled: LOCAL.NO }

    return {}
}

const isAboveAnswerCountLimit = (limit: number, answerCount: number) =>
    answerCount > limit

export const calculateScoreChooseAnswer = (
    question: QuestionVariationValueProps
) =>
    [...question.answers]
        .filter((answer) => answer.isRightAnswer)
        .sort((a, b) => b.points - a.points)
        .reduce((acc, answer, index) => {
            if (question.multiChoose) {
                if (
                    question.isAnswersCountLimited &&
                    isAboveAnswerCountLimit(
                        question.limitAnswersCount,
                        index + 1
                    )
                )
                    return acc

                return acc + answer.points
            }

            return (answer.points > acc ? answer.points : acc) || 0
        }, 0)

export const calculateScoreFillEmptyText = (
    question: QuestionVariationValueProps
) => {
    if (question.calcAndScore.calcForAnswer) {
        return question.fillEmptyTextAnswers?.reduce(
            (acc, answer) => acc + (answer?.score || 0),
            0
        )
    }

    return question.calcAndScore.questionScore || 0
}

export const calculateQuestionMaxScore = (
    question?: QuestionVariationValueProps
) => {
    if (question?.answers) return calculateScoreChooseAnswer(question)

    if (question?.calcAndScore) return calculateScoreFillEmptyText(question)

    return 0
}

export const calculateMaxScore = (questions: QuestionVariationValueProps[]) =>
    questions.reduce(
        (acc, question) => acc + calculateQuestionMaxScore(question),
        0
    )
