import React, { useEffect, useMemo } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { DocumentStatus } from 'core/api'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import {
    UrlParamsForEditProps,
    useFormInitialValues,
    useSaveOrPublishState,
} from 'hooks'
import { isFormModeCopy, isFormModeView } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useParams } from 'react-router-dom'
import { withBlockUserRouting, withLoader } from 'HOCs'

import { FormContainerProps } from './FormContainer.types'

function FormContainer<
    InitialValuesEdit extends { status?: any; documentStatus?: any },
    InitialValuesCreate = any
>({
    onBlockUserRouting,
    formMode,
    updateLoader,
    isLoading,
    formId,
    formComponent: Form,
    requestMethod,
    publishBtnOptions,
    saveBtnOptions,
    cancelBtnOptions,
    title,
    className,
    grid,
}: FormContainerProps<InitialValuesEdit, InitialValuesCreate>) {
    const urlParams = useParams<UrlParamsForEditProps>()

    const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()

    const {
        initialValuesForEdit: editValues,
        fetchInitialFormValuesById,
    } = useFormInitialValues<InitialValuesEdit>({
        requestMethod,
        updateLoader,
    })

    /**
     * Начальные значения для формы со/без свойством `status`, в зависимости от назначения формы
     * (копирование - без свойства `status`, редактирование со свойством `status`)
     */
    const initialValuesForEdit = useMemo<InitialValuesEdit | undefined>(() => {
        if (!editValues) return

        const { status, documentStatus, ...restValues } = editValues

        if (isFormModeCopy(formMode)) return restValues as InitialValuesEdit

        return editValues
    }, [formMode, editValues])

    useEffect(() => {
        if (urlParams.id) fetchInitialFormValuesById(Number(urlParams.id))
    }, [fetchInitialFormValuesById, urlParams.id, formMode])

    const saveBtn =
        typeof saveBtnOptions === 'function'
            ? saveBtnOptions({
                  formMode,
                  urlParams,
                  editValues: initialValuesForEdit,
              })
            : saveBtnOptions

    const publishBtn =
        typeof publishBtnOptions === 'function'
            ? publishBtnOptions({
                  formMode,
                  urlParams,
                  editValues: initialValuesForEdit,
              })
            : publishBtnOptions

    const saveBtnText = saveBtn?.text || LOCAL.ACTIONS.SAVE
    const publishBtnText = publishBtn?.text || LOCAL.ACTIONS.PUBLISH

    const saveBtnDisabled =
        saveBtn?.disabled || isFormModeView(formMode) || isLoading
    const publishBtnDisabled =
        publishBtn?.disabled || isFormModeView(formMode) || isLoading

    return (
        <PageContent className={className} grid={grid}>
            <ContentTitleWithBackLink
                title={title}
                status={
                    initialValuesForEdit?.status ||
                    initialValuesForEdit?.documentStatus ||
                    (isFormModeCopy(formMode) && DocumentStatus.Draft)
                }
                formMode={formMode}
            >
                <ButtonsToolbar>
                    {cancelBtnOptions?.visible && (
                        <Button
                            onClick={cancelBtnOptions.onClick}
                            type="link"
                            href={cancelBtnOptions?.href}
                            disabled={cancelBtnOptions.disabled}
                        >
                            {cancelBtnOptions?.text || LOCAL.ACTIONS.CANCEL}
                        </Button>
                    )}

                    {saveBtn?.visible && (
                        <HasPermissions
                            requiredPermissions={saveBtn?.requiredPermissions}
                            conditionalPermissions={
                                saveBtn?.conditionalPermissions
                            }
                            accessDeniedRender={renderAccessDeniedButton({
                                text: saveBtnText,
                                type: 'primary',
                            })}
                        >
                            <Button
                                form={formId}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmitForm(true)}
                                disabled={saveBtnDisabled}
                            >
                                {saveBtnText}
                            </Button>
                        </HasPermissions>
                    )}

                    {publishBtn?.visible && (
                        <HasPermissions
                            requiredPermissions={
                                publishBtn?.requiredPermissions
                            }
                            conditionalPermissions={
                                publishBtn?.conditionalPermissions
                            }
                            accessDeniedRender={renderAccessDeniedButton({
                                text: publishBtnText,
                                type: 'primary',
                            })}
                        >
                            <Button
                                form={formId}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmitForm(false)}
                                disabled={publishBtnDisabled}
                            >
                                {publishBtnText}
                            </Button>
                        </HasPermissions>
                    )}
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <Form
                onBlockUserRouting={onBlockUserRouting}
                initialValuesForEdit={initialValuesForEdit}
                isSaveDocument={isSaveDocument}
                reFetchInitialFormValues={fetchInitialFormValuesById}
                formMode={formMode}
                updateLoader={updateLoader}
            />
        </PageContent>
    )
}

export default React.memo(
    withBlockUserRouting(withLoader(FormContainer), false)
) as typeof FormContainer
