import React, { useCallback } from 'react'
import {
    AppointmentProcessTypes,
    isAppointmentStatusCanceled,
    isAppointmentStatusDraft,
    isAppointmentStatusEditable,
    isAppointmentStatusEnded,
    isAppointmentStatusExpectation,
    isAppointmentStatusInProgress,
    isAppointmentStatusSuspended,
    isAvailableToWatch,
} from 'utils'
import {
    AssignmentsLaunchedTable,
    AssignmentsProgressAction,
    ButtonsToolbar,
    TableActions,
} from 'components/shared'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS } from 'consts'
import { UserPermission } from 'core/api'
import { getConfirmEditMessage } from 'core/local/messages.local.config'
import { useConfirmRedirect } from 'hooks'

import { TestAssignmentsTableActionsProps } from './TestAssignmentsTableActions.types'

/** Компонент для создания колонки действий внутри таблицы реестра назначений тестов */
export const TestAssignmentsTableActions: React.FC<TestAssignmentsTableActionsProps> = React.memo(
    ({
        tableRow,
        appointmentProcessService,
        reFetchDictionary,
        updateLoader,
    }) => {
        const isEditable = isAppointmentStatusEditable(tableRow.status)

        const isDeletable =
            !isAppointmentStatusSuspended(tableRow.status) &&
            !isAppointmentStatusExpectation(tableRow.status) &&
            !isAppointmentStatusInProgress(tableRow.status)

        const isCanStop =
            isAppointmentStatusExpectation(tableRow.status) ||
            isAppointmentStatusInProgress(tableRow.status)

        const isCanLaunch =
            isAppointmentStatusCanceled(tableRow.status) ||
            isAppointmentStatusSuspended(tableRow.status)

        const { handleRedirect } = useConfirmRedirect({
            to: `${ROUTE_NAMES.TEST_ASSIGNMENTS_EDIT}/${tableRow.id}`,
            needConfirm: !isAppointmentStatusDraft(tableRow.status),
            confirmText: getConfirmEditMessage('test', true),
        })

        /**
         * Обработчик выбора экшена процесса прохождения назначения
         * @param action - выбранный экшен
         */
        const handleChooseAction = useCallback(
            (action: AppointmentProcessTypes) => () => {
                try {
                    updateLoader?.(true)

                    appointmentProcessService(tableRow.id, action).then(
                        reFetchDictionary
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                appointmentProcessService,
                reFetchDictionary,
                tableRow.id,
                updateLoader,
            ]
        )

        return (
            <ButtonsToolbar bottomIndent="0">
                <TableActions.View
                    requiredPermissions={TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS}
                    href={`${ROUTE_NAMES.TEST_ASSIGNMENTS_VIEW}/${tableRow.id}`}
                    disabled={!tableRow.active}
                />

                {tableRow.active && (
                    <>
                        {isAvailableToWatch(tableRow.status) && (
                            <AssignmentsProgressAction
                                requiredPermissions={[
                                    ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.TestAppointmentMonitoring,
                                ]}
                                initialValues={tableRow}
                                reFetchDictionary={reFetchDictionary}
                                component={AssignmentsLaunchedTable}
                            />
                        )}

                        {isAppointmentStatusEnded(tableRow.status) && (
                            <TableActions.Report
                                requiredPermissions={
                                    TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS
                                }
                                href={`${ROUTE_NAMES.TEST_REPORT}/${tableRow.id}`}
                                title={LOCAL.ACTIONS.UPLOAD_REPORT}
                            />
                        )}

                        {isEditable && (
                            <TableActions.Edit
                                requiredPermissions={[
                                    ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.TestAppointmentSave,
                                ]}
                                onClick={handleRedirect}
                            />
                        )}

                        {isCanLaunch && (
                            <TableActions.Play
                                requiredPermissions={[
                                    ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.TestAppointmentContinue,
                                ]}
                                onClick={handleChooseAction(
                                    AppointmentProcessTypes.CancelSuspension
                                )}
                            />
                        )}

                        {isCanStop && (
                            <TableActions.Pause
                                requiredPermissions={[
                                    ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.TestAppointmentPause,
                                ]}
                                onClick={handleChooseAction(
                                    AppointmentProcessTypes.Suspend
                                )}
                            />
                        )}

                        {(isCanStop ||
                            isAppointmentStatusSuspended(tableRow.status)) && (
                            <TableActions.Stop
                                requiredPermissions={[
                                    ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.TestAppointmentCancel,
                                ]}
                                onClick={handleChooseAction(
                                    AppointmentProcessTypes.Cancel
                                )}
                            />
                        )}

                        <TableActions.Copy
                            requiredPermissions={[
                                ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                UserPermission.TestAppointmentSave,
                            ]}
                            href={`${ROUTE_NAMES.TEST_ASSIGNMENTS_COPY}/${tableRow.id}`}
                        />

                        {isDeletable && (
                            <TableActions.Delete
                                tableRow={tableRow}
                                requiredPermissions={[
                                    ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.TestAppointmentDelete,
                                ]}
                            />
                        )}
                    </>
                )}
            </ButtonsToolbar>
        )
    }
)
