import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { Col, Row } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { ROW_GUTTER } from 'consts'
import { getRoundedOneCharNumber } from 'utils'

import styles from './UserProfileCompetenceLevelComparison.module.scss'
import {
    GetLevelProps,
    UserProfileCompetenceLevelComparisonProps,
} from './UserProfileCompetenceLevelComparison.types'
import {
    LOW_SCORE,
    LOW_SCORE_NUMBER_OF_COMPETENCIES,
} from './UserProfileCompetenceLevelComparison.consts'
import { getUserProfileClassName } from './UserProfileCompetenceLevelComparison.utils'

/**
 * Сравнение компетенций и индикаторов
 */
export const UserProfileCompetenceLevelComparison: React.FC<UserProfileCompetenceLevelComparisonProps> = React.memo(
    ({
        values = [],
        label,
        defaultValues,
        haveIndicators,
        isIndicatorLevel,
        indicatorsVisible,
        toggleIndicatorsVisible,
    }) => {
        /**
         * Уровни компетенций
         * @param value - значение уровня
         * @param type - тип оценки, к которой относится значение уровня
         */
        const getLevels = useCallback(
            ({ score, assessmentType, index, showLowScore }: GetLevelProps) =>
                values.map((el, i) => {
                    const roundedValue = getRoundedOneCharNumber(score)
                    const isLowScore = showLowScore && roundedValue <= LOW_SCORE
                    const levelClasses =
                        el.value <= roundedValue
                            ? getUserProfileClassName({
                                  isIndicatorLevel,
                                  isLowScore,
                                  index,
                                  assessmentType,
                              })
                            : undefined

                    return (
                        <span
                            key={i}
                            className={cn(styles.level, levelClasses)}
                        />
                    )
                }),
            [values, isIndicatorLevel]
        )

        /**
         * Строка с уровнями
         */
        const levels = useMemo(
            () =>
                defaultValues?.map((el) => (
                    <Row key={el.appointmentId || el.index} align="middle">
                        <Col
                            className={cn(
                                styles.grid,
                                isIndicatorLevel && styles.gridIndicator
                            )}
                        >
                            {isIndicatorLevel && <span />}

                            {getLevels({
                                ...el,
                                /**
                                 * Показывать низкую оценку у ПД можно только если идет
                                 * сравнение не с более чем LOW_SCORE_NUMBER_OF_COMPETENCIES компетенций
                                 */
                                showLowScore:
                                    defaultValues.length <=
                                    LOW_SCORE_NUMBER_OF_COMPETENCIES + 1,
                            })}
                        </Col>

                        <span className={styles.number}>
                            {getRoundedOneCharNumber(el.score)}
                        </span>
                    </Row>
                )),
            [defaultValues, isIndicatorLevel, getLevels]
        )

        return (
            <div className={styles.wrapper}>
                <Row gutter={ROW_GUTTER} align="middle">
                    <Col xs={12}>{levels}</Col>

                    <Col
                        xs={12}
                        className={cn(
                            styles.groupLabel,
                            indicatorsVisible &&
                                styles.groupLabelHaveIndicators,
                            isIndicatorLevel && styles.groupLabelIndicatorLabel
                        )}
                    >
                        {label}

                        {haveIndicators && (
                            <IconsAdapter
                                iconType="DownOutlined"
                                onClick={toggleIndicatorsVisible}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
)
