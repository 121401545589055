import { AppointmentActivityContract, UserPermission } from 'core/api'
import { EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS } from 'consts/permissionSets.consts'
import { GetBtnOptionsType } from 'components/layouts/FormContainer/FormContainer.types'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    isAppointmentTrackStatusAppointment,
    isAppointmentTrackStatusInWork,
    isAppointmentTrackStatusSuspended,
} from 'utils'

export const CANCEL_BTN_OPTIONS = {
    href: ROUTE_NAMES.EVENTS_ASSIGNMENTS,
    visible: true,
}

export const getSaveBtnOptions: GetBtnOptionsType<AppointmentActivityContract> = ({
    editValues,
}) => ({
    visible:
        !isAppointmentTrackStatusAppointment(editValues?.status) &&
        !isAppointmentTrackStatusSuspended(editValues?.status) &&
        !isAppointmentTrackStatusInWork(editValues?.status),
})

export const PUBLISH_BTN_OPTIONS = {
    visible: true,
    text: LOCAL.ACTIONS.ASSIGN,
    requiredPermissions: [
        ...EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS,
        UserPermission.AppointmentActivityPublish,
    ],
}
