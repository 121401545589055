import { ActivityPassingStatus, AppointmentTrackStatus } from 'core/api'
import { LOCAL } from 'core/local'
import { isAppointmentTrackPassedOrNotPassed } from 'utils'

import styles from './TrackContentMenu.module.scss'

export const getActivityTitle = (name: string, index: number) =>
    `${LOCAL.LABELS.ACTIVITY} ${index + 1}. ${name}`

export const getBlockClassName = (
    status: AppointmentTrackStatus,
    isAvailable: boolean
) => {
    if (!isAvailable) return styles.inaccessible

    if (isAppointmentTrackPassedOrNotPassed(status)) return styles.done
}

export const getActivityClassName = (status: ActivityPassingStatus) => {
    switch (status) {
        case ActivityPassingStatus.Canceled:
        case ActivityPassingStatus.Pause:
            return styles.inaccessible
        case ActivityPassingStatus.CompletedSuccessfully:
        case ActivityPassingStatus.CompletedUnsuccessfully:
            return styles.done
    }
}
