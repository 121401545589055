import moment from 'moment'

interface HomeworkTimeProps {
    lessonStartDate: Date
    onCheckingDate: Date
}

export const getHomeworkTime = ({
    lessonStartDate,
    onCheckingDate,
}: HomeworkTimeProps) => {
    const startDate = moment(lessonStartDate)
    const endDate = moment(onCheckingDate)

    const days = moment.duration(endDate.diff(startDate)).days()
    const hours = moment.duration(endDate.diff(startDate)).hours()
    const minutes = moment.duration(endDate.diff(startDate)).minutes()
    const seconds = moment.duration(endDate.diff(startDate)).seconds()

    const date: string[] = []

    if (days) date.push(`${days} д`)
    if (hours) date.push(`${hours} ч`)
    if (minutes || seconds) date.push(`${seconds ? minutes + 1 : minutes} м`)

    return date.join(' ')
}
