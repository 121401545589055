import { useEffect } from 'react'

export function usePreventPast(selector: string) {
    useEffect(() => {
        const elements = document.querySelectorAll(selector) as NodeListOf<
            HTMLElement
        >

        if (!elements) return

        const preventPast = (e: ClipboardEvent) => {
            e.preventDefault()

            return false
        }

        elements.forEach((el) => {
            el.addEventListener('paste', preventPast)
        })

        return () => {
            elements.forEach((el) => {
                el.removeEventListener('paste', preventPast)
            })
        }
    }, [selector])
}
