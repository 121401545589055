import { LOCAL_STORAGE_KEYS } from 'core/helpers/LocalStorage'
import { UserSettingsCreateContract, UsersService } from 'core/api'

import { UserSettingsParsedProps, UserSettingsType } from './UserContext.types'

/**
 * Распарсить настройки пользователя
 * @param settings - настройки
 */
export const parseUserSettings = (
    settings: UserSettingsType
): UserSettingsParsedProps | null => settings && JSON.parse(settings)

/**
 * Обновить настройки пользователя на сервере
 * @param body - тело запроса
 */
export const updateUserSettings = async (body: UserSettingsCreateContract) => {
    try {
        await UsersService.createOrUpdateCurrentUserSettings({ body })
    } catch (error) {
        console.error(error)
    }
}

export const updateMonitoringSettings = async (
    state: boolean,
    settings: UserSettingsParsedProps | null
) => {
    try {
        const newSettings = JSON.stringify({
            ...(settings || {}),
            monitoringEnable: state,
        })

        await updateUserSettings({
            key: LOCAL_STORAGE_KEYS.USER_PAGE_SETTINGS,
            value: newSettings,
        })

        return newSettings
    } catch (error) {
        console.error(error)
    }
}
