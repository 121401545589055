import loaderDefault from 'core/images/img-loader.gif'
import { useEffect, useState } from 'react'

interface UseImgLoaderProps {
    src?: string
}

export const useImgLoader = ({ src }: UseImgLoaderProps) => {
    const [bg, setBg] = useState(`url(${loaderDefault})`)

    useEffect(() => {
        if (!src) return

        const preloaderImg = document.createElement('img')

        preloaderImg.src = src

        const handleImgLoad = () => {
            setBg(`url(${src})`)

            preloaderImg.remove()
        }

        preloaderImg.addEventListener('load', handleImgLoad)
    }, [src])

    return [bg]
}
