import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import {
    ACTIVITY_PASSING_DATE_TYPE_MAPPED,
    COL_xl8_lg12_xs24,
    ROW_GUTTER,
} from 'consts'
import {
    ActivitiesService,
    ActivityPassingDateType,
    DictionaryContract,
} from 'core/api'
import {
    CheckboxControl,
    ControlGroup,
    DatePickerControl,
    FormItemAdapter,
    RadioButtonControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import {
    disableDate,
    disableDateWhenSelectedDate,
    getDisabledDate,
} from 'utils'

import styles from './AssignmentParametersArea.module.scss'
import { AssignmentParametersAreaProps } from './AssignmentParametersArea.types'
import { PASSING_DATE_TYPE_FIELD } from '../../EventsAssignmentsForm.consts'
import { ThematicBlocksView } from './components'

/** Область "параметры назначения" для формы назначения мероприятия */
export const AssignmentParametersArea: React.FC<AssignmentParametersAreaProps> = React.memo(
    ({
        currentActivityId,
        formMode,
        disabled,
        disablePeriodFromByStatus,
        initialValuesForEdit,
    }) => {
        const [thematicBlocks, setThematicBlocks] = useState<
            DictionaryContract[]
        >([])
        /**
         * Получаем информацию о тематических блоках через текущее выбранное в форме мероприятие
         */
        useEffect(() => {
            if (!currentActivityId) return

            const fetchActivity = async (id: number) => {
                try {
                    const activity = await ActivitiesService.getActive({ id })
                    setThematicBlocks(activity.lessons)
                } catch (error) {
                    console.error(error)
                }
            }

            fetchActivity(currentActivityId)
        }, [currentActivityId])

        return (
            <ControlGroup
                type="bordered"
                title={LOCAL.LABELS.ASSIGNMENT_SETTINGS}
            >
                <Row gutter={ROW_GUTTER} className={styles.wrapper}>
                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName={PASSING_DATE_TYPE_FIELD}
                            label={LOCAL.LABELS.EVENT_M_DATES}
                            className={styles.dateTypeField}
                        >
                            <RadioButtonControl
                                values={ACTIVITY_PASSING_DATE_TYPE_MAPPED}
                                buttonStyle="solid"
                                formMode={formMode}
                                disabled={disabled}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <ShouldUpdateChecker
                            fieldPath={PASSING_DATE_TYPE_FIELD}
                        >
                            {(form) => {
                                const status = form.getFieldValue('status')

                                return form.getFieldValue([
                                    PASSING_DATE_TYPE_FIELD,
                                ]) === ActivityPassingDateType.ByActivity ? (
                                    <Row gutter={ROW_GUTTER}>
                                        <Col md={24} xl={12}>
                                            <FormItemAdapter
                                                fieldName="startDate"
                                                label={
                                                    LOCAL.LABELS
                                                        .APPOINTMENT_START_DATE
                                                }
                                                required
                                            >
                                                <DatePickerControl
                                                    disabledDate={disableDateWhenSelectedDate(
                                                        getDisabledDate(
                                                            status,
                                                            initialValuesForEdit?.startDate
                                                        )
                                                    )}
                                                    formMode={formMode}
                                                    disabled={
                                                        disablePeriodFromByStatus
                                                    }
                                                />
                                            </FormItemAdapter>
                                        </Col>

                                        <Col md={24} xl={12}>
                                            <ShouldUpdateChecker fieldPath="startDate">
                                                {({ getFieldsValue }) => {
                                                    const {
                                                        startDate,
                                                    } = getFieldsValue([
                                                        'startDate',
                                                    ])

                                                    return (
                                                        <FormItemAdapter
                                                            fieldName="endDate"
                                                            label={
                                                                LOCAL.LABELS
                                                                    .APPOINTMENT_DATE_END
                                                            }
                                                            required
                                                        >
                                                            <DatePickerControl
                                                                disabledDate={disableDateWhenSelectedDate(
                                                                    startDate ||
                                                                        moment()
                                                                )}
                                                                formMode={
                                                                    formMode
                                                                }
                                                                disabled={
                                                                    disablePeriodFromByStatus
                                                                }
                                                            />
                                                        </FormItemAdapter>
                                                    )
                                                }}
                                            </ShouldUpdateChecker>
                                        </Col>
                                    </Row>
                                ) : (
                                    <ThematicBlocksView
                                        thematicBlocks={thematicBlocks}
                                    />
                                )
                            }}
                        </ShouldUpdateChecker>
                    </Col>
                </Row>

                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="isHomeworkAutoCheck"
                            className={styles.autoCheckCheckbox}
                        >
                            <CheckboxControl
                                disabled={disabled}
                                formMode={formMode}
                            >
                                {LOCAL.LABELS.HOMEWORK_AUTOCHECK}
                            </CheckboxControl>
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <ShouldUpdateChecker
                            fieldPath={[['isHomeworkAutoCheck'], ['startDate']]}
                        >
                            {({ getFieldValue }) => {
                                const isHomeworkAutoCheck = getFieldValue(
                                    'isHomeworkAutoCheck'
                                )

                                return (
                                    isHomeworkAutoCheck && (
                                        <FormItemAdapter
                                            fieldName="homeworkAutoCheckDate"
                                            label={
                                                LOCAL.LABELS
                                                    .DATE_HOMEWORK_AUTOCHECK_STARTED
                                            }
                                        >
                                            <DatePickerControl
                                                formMode={formMode}
                                                disabledDate={disableDate({
                                                    selectedDate: getFieldValue(
                                                        'startDate'
                                                    ),
                                                })}
                                            />
                                        </FormItemAdapter>
                                    )
                                )
                            }}
                        </ShouldUpdateChecker>
                    </Col>
                </Row>
            </ControlGroup>
        )
    }
)
