import React, { useCallback } from 'react'
import {
    AppointmentActivitiesProcessTypes,
    isAppointmentTrackStatusAppointment,
    isAppointmentTrackStatusCanceled,
    isAppointmentTrackStatusDraft,
    isAppointmentTrackStatusEnded,
    isAppointmentTrackStatusInWork,
    isAppointmentTrackStatusSuspended,
    stopEventPropagation,
} from 'utils'
import {
    AssignmentsProgressAction,
    ButtonsToolbar,
    TableActions,
} from 'components/shared'
import { EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UserPermission } from 'core/api'
import { getConfirmEditMessage } from 'core/local/messages.local.config'
import { useConfirmRedirect } from 'hooks'

import { ActivityAssignmentsProgressTable } from '../ActivityAssignmentsProgressTable'
import { EventsAssignmentsTableActionsProps } from './EventsAssignmentsTableActions.types'

/** Компонент колонки действий внутри реестра назначения мероприятия */
export const EventsAssignmentsTableActions: React.FC<EventsAssignmentsTableActionsProps> = React.memo(
    ({
        tableRow,
        reFetchDictionary,
        appointmentEventsProcessService,
        updateLoader,
    }) => {
        const isAppointment = isAppointmentTrackStatusAppointment(
            tableRow.status
        )
        const isDraft = isAppointmentTrackStatusDraft(tableRow.status)
        const isSuspended = isAppointmentTrackStatusSuspended(tableRow.status)
        const isEditAvailable =
            isSuspended ||
            isDraft ||
            isAppointment ||
            isAppointmentTrackStatusInWork(tableRow.status)
        const isDeleteAvailable =
            isAppointmentTrackStatusEnded(tableRow.status) ||
            isAppointmentTrackStatusCanceled(tableRow.status) ||
            isDraft

        const { handleRedirect } = useConfirmRedirect({
            to: `${ROUTE_NAMES.EVENTS_ASSIGNMENTS_EDIT}/${tableRow.id}`,
            needConfirm: !isDraft,
            confirmText: getConfirmEditMessage('event', true),
        })

        /**
         * Обработчик выбора экшена процесса прохождения назначения треков
         * @param action выбранный экшен
         */
        const handleChooseAction = useCallback(
            (action: AppointmentActivitiesProcessTypes) => async () => {
                try {
                    updateLoader?.(true)

                    appointmentEventsProcessService(tableRow.id, action).then(
                        reFetchDictionary
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                appointmentEventsProcessService,
                reFetchDictionary,
                tableRow.id,
                updateLoader,
            ]
        )

        return (
            <ButtonsToolbar
                bottomIndent="0"
                align="flex-end"
                onDoubleClick={stopEventPropagation}
            >
                <TableActions.View
                    requiredPermissions={
                        EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS
                    }
                    href={`${ROUTE_NAMES.EVENTS_ASSIGNMENTS_VIEW}/${tableRow.id}`}
                    disabled={!tableRow.active}
                />

                {tableRow.active && (
                    <>
                        {isAppointment && (
                            <AssignmentsProgressAction
                                initialValues={tableRow}
                                reFetchDictionary={reFetchDictionary}
                                component={ActivityAssignmentsProgressTable}
                            />
                        )}

                        {isEditAvailable && (
                            <TableActions.Edit
                                requiredPermissions={[
                                    ...EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.AppointmentActivitySave,
                                ]}
                                onClick={handleRedirect}
                            />
                        )}

                        {isSuspended && (
                            <TableActions.Play
                                requiredPermissions={[
                                    ...EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.AssessmentAppointmentContinue,
                                ]}
                                onClick={handleChooseAction(
                                    AppointmentActivitiesProcessTypes.CancelSuspension
                                )}
                            />
                        )}

                        {isAppointment && (
                            <TableActions.Pause
                                onClick={handleChooseAction(
                                    AppointmentActivitiesProcessTypes.Suspend
                                )}
                            />
                        )}

                        {isAppointment && (
                            <TableActions.Stop
                                onClick={handleChooseAction(
                                    AppointmentActivitiesProcessTypes.Cancel
                                )}
                            />
                        )}

                        {isDeleteAvailable && (
                            <TableActions.Delete
                                tableRow={tableRow}
                                requiredPermissions={[
                                    ...EVENTS_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                                    UserPermission.AppointmentActivityDelete,
                                ]}
                            />
                        )}
                    </>
                )}
            </ButtonsToolbar>
        )
    }
)
