import { ACTIVITY_PASSING_STATUSES } from 'consts'
import { AppointmentActivityRespondentProgressContract } from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'

/** настройка отображения колонок таблицы */
export const APPOINTMENT_LAUNCHED_TABLE_COLUMNS: ColumnsType<AppointmentActivityRespondentProgressContract> = [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'fullName',
        key: 'fullName',
        width: '60%',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        width: '20%',
        render: (_, record) => ACTIVITY_PASSING_STATUSES[record.status],
    },
    {
        title: LOCAL.LABELS.PROGRESS,
        dataIndex: 'progress',
        key: 'progress',
        render: (progress) => `${progress}%`,
        width: '20%',
    },
]
