import template from 'lodash/template'
import { LOCAL } from 'core/local'

export const POSITION_FIELD = 'positionIds'
export const COMPETENCIES_FIELD = 'competenceIds'
export const TEST_TYPE_FIELD = 'testTypeId'
export const FOR_ALL_POSITIONS_FIELD = 'forAllPositions'
export const FOR_ALL_ORGANIZATIONS_FIELD = 'forAllOrganizations'

/**
 * Массив зависимых полей, при изменении которых происходит обновление поля `questionnaires`
 */
export const questionnaireDependencies = [
    POSITION_FIELD,
    COMPETENCIES_FIELD,
    TEST_TYPE_FIELD,
    FOR_ALL_POSITIONS_FIELD,
    FOR_ALL_ORGANIZATIONS_FIELD,
]

export const BORDER_NUMBER_OF_QUESTIONNAIRES = 1

export const FORM_INITIAL_VALUES = {
    presentation: {
        welcomeWindow: {
            message: LOCAL.MESSAGES.NEED_TO_PASS_ALL_ANSWERS,
        },
        finalWindow: {
            message: template(LOCAL.MESSAGES.PORTFOLIO_FINAL_MESSAGE)({
                portfolioType: LOCAL.LABELS.TEST,
            }),
        },
    },
}
