import React from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    TracksMguuSearchContract,
    TracksMguuSearchResultContract,
    TracksMguuService,
} from 'core/api'
import { getRouteMeta } from 'routing/routeNames.utils'

import { TRACKS_TABLE_COLUMNS } from './TracksUnique.consts'

/** Реестр уникальных треков */
export const TracksUnique: React.FC = React.memo(() => (
    <DictionaryPage<TracksMguuSearchContract, TracksMguuSearchResultContract>
        dictionaryKey={DICTIONARY_KEYS.TRACKS_UNIQUE}
        searchMethod={TracksMguuService.search}
        contentTitle={getRouteMeta(ROUTE_NAMES.TRACKS_UNIQUE)}
        tableColumns={TRACKS_TABLE_COLUMNS}
    />
))
