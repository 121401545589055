import React from 'react'
import { LOCAL } from 'core/local'

import styles from './NoChartData.module.scss'

export const NoChartData = () => (
    <p className={styles.text}>
        {`${LOCAL.LABELS.INSUFFICIENT_DATA_TO_CALCULATE}.`}
    </p>
)
