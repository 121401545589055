import React, { useCallback } from 'react'
import {
    CertificateSearchContract,
    CertificateSearchResultContract,
    CertificateType,
    CertificatesService,
} from 'core/api'
import { DICTIONARY_KEYS } from 'core/configs'
import { DictionaryPage, DictionaryPageActionsProps } from 'components/layouts'
import { PageTabs } from 'components/shared'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { idToUrlParam } from 'utils'
import { useParams } from 'react-router'

import { CertificatesFilters } from './components'
import { CertificatesPageUrlParamsProps } from './Certificates.types'
import { TabsKeys } from './Certificates.consts'
import {
    getCertificatesPageTableColumns,
    getTabsData,
} from './Certificates.utils'

/** Реестр внешних сертификатов */
export const ExternalCertificatesPage = React.memo(() => {
    const { userId } = useParams<CertificatesPageUrlParamsProps>()

    const renderTabs = useCallback(
        () => <PageTabs tabsData={getTabsData(userId)} />,
        [userId]
    )

    const renderTableColumns = useCallback(
        ({ updateLoader }: DictionaryPageActionsProps) =>
            getCertificatesPageTableColumns(TabsKeys.EXTERNAL_CERTIFICATES, {
                updateLoader,
            }),
        []
    )

    return (
        <DictionaryPage<
            CertificateSearchContract,
            CertificateSearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.EXTERNAL_CERTIFICATES}
            searchMethod={CertificatesService.search}
            deleteMethod={CertificatesService.delete}
            contentTitle={getRouteMeta(ROUTE_NAMES.CERTIFICATES)}
            tableColumnsRender={renderTableColumns}
            createRecordLink={{
                path: `${
                    ROUTE_NAMES.EXTERNAL_CERTIFICATES_CREATE
                }${idToUrlParam(userId)}`,
            }}
            tableFiltersOptions={{
                initialValues: {
                    type: CertificateType.External,
                    userId: Number(userId),
                },
            }}
            filterComponent={CertificatesFilters}
            pageTabsRenderCustom={renderTabs}
        />
    )
})
