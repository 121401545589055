import React, { useCallback, useContext } from 'react'
import { CheckboxGroupControl } from 'components/controls'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'

import styles from './UserProfileCompetenciesOptions.module.scss'
import { COMPARING_OPTIONS, UserProfileContext } from '../../context'

/** Опции для управления разделом компетенций на странице профайла юзера */
export const UserProfileCompetenciesOptions: React.FC = React.memo(() => {
    const { setResultsComparingOptions, resultsComparingOptions } = useContext(
        UserProfileContext
    )

    /**
     * Обработчик выбора отображаемых данных
     * @param values - выбранные значения
     */
    const handleSelectDisplayData = useCallback(
        (values) => {
            setResultsComparingOptions(values)
        },
        [setResultsComparingOptions]
    )

    return (
        <Row gutter={ROW_GUTTER} className={styles.wrapper}>
            <Col>
                <b>{LOCAL.ACTIONS.DISPLAY_DATA}</b>
            </Col>

            <Col>
                <CheckboxGroupControl
                    options={COMPARING_OPTIONS}
                    value={resultsComparingOptions}
                    onChange={handleSelectDisplayData}
                />
            </Col>
        </Row>
    )
})
