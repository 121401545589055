import React from 'react'
import upperFirst from 'lodash/upperFirst'
import { Button } from 'components/shared/Button'
import { DevelopmentGoalsForm } from 'components/pages/DevelopmentGoals/components'
import { FORM_IDS } from 'core/configs'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { PopupWithForm } from 'components/shared'
import { Space } from 'antd'
import { UserProfilesService } from 'core/api'
import { formatDate } from 'utils'

import styles from './UserGoalsItem.module.scss'
import { ControlGroupItem } from '../ControlGroupItem'
import { UserGoalsItemProps } from './UserGoalsItem.types'

/**
 * Элемент списка целей пользователя
 */
export const UserGoalsItem: React.FC<UserGoalsItemProps> = React.memo(
    ({
        disabled,
        onFetchGoals,
        userDevelopmentGoal: { developmentGoal, id, changeDate },
        userId,
    }) => {
        /**
         * Обработчик удаления элемента
         */
        const handleDeleteItem = async () => {
            try {
                if (!userId) return

                await UserProfilesService.deleteUserDevelopmentGoal({
                    userId,
                    goalId: id,
                })
                onFetchGoals()
            } catch (e) {
                console.log(e)
            }
        }

        return (
            <ControlGroupItem className={styles.wrapper}>
                <div className={styles.name}>
                    {upperFirst(developmentGoal.name)}
                </div>

                <Space>
                    <div className={styles.date}>
                        <div>{LOCAL.ADDED}</div>

                        <div>{formatDate(changeDate)}</div>
                    </div>

                    <PopupWithForm
                        component={DevelopmentGoalsForm}
                        formId={`${FORM_IDS.DEVELOPMENT_GOALS_FORM}${id}`}
                        buttonText=""
                        buttonOption={{
                            type: 'link',
                            icon: <IconsAdapter iconType="EditOutlined" />,
                        }}
                        formOptions={{
                            initialValues: developmentGoal,
                        }}
                        modalOptions={{
                            title: LOCAL.LABELS.DEVELOPMENT_GOAL_EDITING,
                            okText: LOCAL.ACTIONS.SAVE,
                            width: MODAL_WIDTH.MD,
                        }}
                        onRequestFinish={onFetchGoals}
                    />

                    <Button
                        className={styles.deleteButton}
                        icon={<IconsAdapter iconType="CloseOutlined" />}
                        type="link"
                        onClick={handleDeleteItem}
                        disabled={disabled}
                    />
                </Space>
            </ControlGroupItem>
        )
    }
)
