import React from 'react'
import { Col, Row } from 'antd'
import {
    FormItemAdapter,
    InputNumberControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import {
    isThemeBlockTypeCourse,
    isThemeBlockTypeHomework,
    isThemeBlockTypeInformation,
    isThemeBlockTypeQuestionnaire,
} from 'utils'

import { ThemeBlockProps } from '../ThemeBlock/ThemeBlock.types'
import { getThemeBlockType } from '../ThemeBlock/ThemeBlock.utils'

/** Полями ввода для цифр */
export const ThemeBlockSettings: React.FC<ThemeBlockProps> = React.memo(
    ({ field, formMode, disabled }) => (
        <>
            <ShouldUpdateChecker fieldPath={['lessons']}>
                {({ getFieldValue }) => {
                    const themeBlockType = getThemeBlockType(
                        getFieldValue,
                        field
                    )

                    const isCourse = isThemeBlockTypeCourse(themeBlockType)

                    const isAttemptNumberAvailable =
                        isCourse ||
                        isThemeBlockTypeQuestionnaire(themeBlockType) ||
                        isThemeBlockTypeHomework(themeBlockType)

                    return (
                        <>
                            {isCourse && (
                                <Row gutter={5}>
                                    <Col>
                                        <FormItemAdapter
                                            fieldName={[
                                                field.name,
                                                'courseDuration',
                                            ]}
                                            withEmptyLabel
                                        >
                                            <InputNumberControl
                                                min={0}
                                                addonBefore={
                                                    LOCAL.LABELS.DURATION
                                                }
                                                addonAfter={LOCAL.HOURS}
                                                formMode={formMode}
                                                disabled={disabled}
                                            />
                                        </FormItemAdapter>
                                    </Col>

                                    <Col>
                                        <FormItemAdapter
                                            fieldName={[
                                                field.name,
                                                'courseDurationMinutes',
                                            ]}
                                            withEmptyLabel
                                        >
                                            <InputNumberControl
                                                min={0}
                                                addonAfter={LOCAL.MINUTES}
                                                formMode={formMode}
                                                disabled={disabled}
                                            />
                                        </FormItemAdapter>
                                    </Col>
                                </Row>
                            )}

                            {isAttemptNumberAvailable && (
                                <FormItemAdapter
                                    fieldName={[field.name, 'attemptNumber']}
                                    withEmptyLabel
                                >
                                    <InputNumberControl
                                        min={0}
                                        addonBefore={
                                            LOCAL.LABELS.ATTEMPT_NUMBER
                                        }
                                        formMode={formMode}
                                        disabled={disabled}
                                    />
                                </FormItemAdapter>
                            )}
                        </>
                    )
                }}
            </ShouldUpdateChecker>

            <ShouldUpdateChecker fieldPath={['isRating']}>
                {({ getFieldValue }) => {
                    const themeBlockType = getThemeBlockType(
                        getFieldValue,
                        field
                    )

                    return getFieldValue('isRating') ||
                        isThemeBlockTypeInformation(themeBlockType) ? (
                        <FormItemAdapter
                            label={LOCAL.LABELS.TERMS_SCORING}
                            fieldName={[field.name, 'pointForPassing']}
                        >
                            <InputNumberControl
                                min={0}
                                addonBefore={LOCAL.LABELS.FOR_PASSING}
                                disabled={disabled}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    ) : null
                }}
            </ShouldUpdateChecker>
        </>
    )
)
