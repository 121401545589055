import { FnActionProps, FnActionRequiredProps } from 'App.types'
import { UserContext } from 'contexts/User/UserContext'
import { UserPermission } from 'core/api'
import { checkRoleBasedPermissions } from 'core/permissions/ProtectedRoute/ProtectedRoute.utils'
import { useCallback, useContext } from 'react'

export interface UseCheckPermissionsProps {
    hasPermissions: FnActionRequiredProps<CheckPermissionsProps, boolean>
    hasPermissionsRequired: FnActionProps<UserPermission[], boolean>
    hasPermissionsConditional: FnActionProps<UserPermission[], boolean>
}

interface CheckPermissionsProps {
    requiredPermissions?: UserPermission[]
    conditionalPermissions?: UserPermission[]
}

export const useCheckPermissions = (): UseCheckPermissionsProps => {
    const { userData } = useContext(UserContext)

    /** если true, значит у пользователя есть все права, которые требуют точного соответствия */
    const hasPermissionsRequired = useCallback(
        (requiredPermissions?: UserPermission[]) =>
            requiredPermissions
                ? requiredPermissions.every((item) =>
                      checkRoleBasedPermissions(userData?.permissions).includes(
                          item
                      )
                  )
                : true,
        [userData]
    )

    /** если true, значит у пользователя есть все права, которые требуют лишь частичного соответствия */
    const hasPermissionsConditional = useCallback(
        (conditionalPermissions?: UserPermission[]) =>
            conditionalPermissions
                ? conditionalPermissions.some((item) =>
                      checkRoleBasedPermissions(userData?.permissions).includes(
                          item
                      )
                  )
                : true,
        [userData]
    )

    const hasPermissions = useCallback(
        ({
            requiredPermissions,
            conditionalPermissions,
        }: CheckPermissionsProps) =>
            hasPermissionsRequired(requiredPermissions) &&
            hasPermissionsConditional(conditionalPermissions),
        [hasPermissionsConditional, hasPermissionsRequired]
    )

    return {
        hasPermissions,
        hasPermissionsRequired,
        hasPermissionsConditional,
    }
}
