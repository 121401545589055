import React from 'react'
import { AppointmentTrackSearchResultContract, UserPermission } from 'core/api'
import {
    AppointmentTracksPassingServiceProps,
    formatDate,
    renderAppointmentTrackStatus,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'
import { ColumnsType } from 'antd/lib/table'
import { DictionaryPageActionsProps, defaultSorter } from 'components/layouts'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS, TRACK_TYPES } from 'consts'

import { TrackAssignmentsTableActions } from './components'

/**
 * Получить настройки отображения колонок таблицы назначения треков
 * @param appointmentTracksProcessService - сервис для контроля процесса прохождения назначения треков
 * @param actions - вспомогательные функции/экшены, берутся из DictionaryPage
 */
export const getTrackAssignmentsTableColumns = (
    appointmentTracksProcessService: AppointmentTracksPassingServiceProps,
    { reFetchDictionary, updateLoader }: DictionaryPageActionsProps
): ColumnsType<AppointmentTrackSearchResultContract> => [
    {
        title: LOCAL.LABELS.APPOINTMENT_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.TRACK_NAME_LONG,
        dataIndex: 'trackName',
        key: 'trackName',
        sorter: defaultSorter,
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.TRACK_TYPE,
        dataIndex: 'trackType',
        key: 'trackType',
        render: (trackType, record) => TRACK_TYPES[record.trackType],
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'periodFrom',
        key: 'periodFrom',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'periodTo',
        key: 'periodTo',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderAppointmentTrackStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CREATION,
        dataIndex: 'creationDate',
        key: 'creationDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <TrackAssignmentsTableActions
                updateLoader={updateLoader}
                tableRow={tableRow}
                appointmentTracksProcessService={
                    appointmentTracksProcessService
                }
                reFetchDictionary={reFetchDictionary}
            />
        ),
        fixed: 'right',
    },
]

export const CANCEL_BTN_OPTIONS = {
    visible: true,
    href: ROUTE_NAMES.TRACKS_ASSIGNMENTS,
}

export const SAVE_BTN_OPTIONS = {
    visible: true,
}

export const PUBLISH_BTN_OPTIONS = {
    visible: true,
    requiredPermissions: [
        ...TRACK_ASSIGNMENTS_REQUIRED_PERMISSIONS,
        UserPermission.TrackAppointmentGo,
    ],
    text: LOCAL.ACTIONS.ASSIGN,
}
