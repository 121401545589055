import React, { useMemo } from 'react'
import { Button } from 'components/shared'
import { LOCAL } from 'core/local'
import { useElementVisible } from 'hooks'

import styles from './History.module.scss'
import { AnswersHistory } from '../AnswersHistory'
import { HistoryProps } from './History.types'

export const History: React.FC<HistoryProps> = React.memo(
    ({ homeworkHistory }) => {
        const { toggleElementVisible, elementVisible } = useElementVisible()

        const attemptHistory = useMemo(() => {
            const dataLength = homeworkHistory.length

            return homeworkHistory.map((el, index) => (
                <AnswersHistory
                    item={el}
                    key={el.id}
                    attempt={dataLength - index}
                />
            ))
        }, [homeworkHistory])

        return (
            <div className={styles.history}>
                {elementVisible && attemptHistory}

                <Button type="link" onClick={toggleElementVisible}>
                    {elementVisible
                        ? LOCAL.ACTIONS.HIDE_ANSWERS_HISTORY
                        : LOCAL.ACTIONS.SHOW_ANSWERS_HISTORY}
                </Button>
            </div>
        )
    }
)
