import React, { FC } from 'react'
import {
    ErrorPage,
    TestAssignmentFormContainer,
    TestAssignments,
    TestPortfolios,
    TestPortfoliosFormContainer,
    TestReport,
} from 'components/pages'
import { ProtectedRoute } from 'core/permissions'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'
import {
    TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
    TEST_PORTFOLIOS_REQUIRED_PERMISSIONS,
} from 'consts'
import { UserPermission } from 'core/api'

export const TestRouting: FC = React.memo(() => (
    <Switch>
        <ProtectedRoute
            requiredPermissions={[
                ...TEST_PORTFOLIOS_REQUIRED_PERMISSIONS,
                UserPermission.TestPortfolioSave,
            ]}
            path={`${ROUTE_NAMES.TEST_PORTFOLIOS_COPY}/:id`}
        >
            <TestPortfoliosFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={TEST_PORTFOLIOS_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.TEST_PORTFOLIOS_VIEW}/:id`}
        >
            <TestPortfoliosFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TEST_PORTFOLIOS_REQUIRED_PERMISSIONS,
                UserPermission.TestPortfolioSave,
            ]}
            path={`${ROUTE_NAMES.TEST_PORTFOLIOS_EDIT}/:id`}
        >
            <TestPortfoliosFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TEST_PORTFOLIOS_REQUIRED_PERMISSIONS,
                UserPermission.TestPortfolioSave,
            ]}
            path={ROUTE_NAMES.TEST_PORTFOLIOS_CREATE}
        >
            <TestPortfoliosFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.TestPortfoliosRead,
            ]}
            path={ROUTE_NAMES.TEST_PORTFOLIOS}
        >
            <TestPortfolios />
        </ProtectedRoute>

        <Route path={`${ROUTE_NAMES.TEST_REPORT}/:id`}>
            <TestReport />
        </Route>

        <ProtectedRoute
            requiredPermissions={[
                ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.TestAppointmentSave,
            ]}
            path={`${ROUTE_NAMES.TEST_ASSIGNMENTS_COPY}/:id`}
        >
            <TestAssignmentFormContainer formMode="copy" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.TEST_ASSIGNMENTS_VIEW}/:id`}
        >
            <TestAssignmentFormContainer formMode="view" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.TestAppointmentSave,
            ]}
            path={`${ROUTE_NAMES.TEST_ASSIGNMENTS_EDIT}/:id`}
        >
            <TestAssignmentFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...TEST_ASSIGNMENTS_REQUIRED_PERMISSIONS,
                UserPermission.TestAppointmentSave,
            ]}
            path={ROUTE_NAMES.TEST_ASSIGNMENTS_CREATE}
        >
            <TestAssignmentFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                UserPermission.Administration,
                UserPermission.TestAppointmentsRead,
            ]}
            path={ROUTE_NAMES.TEST_ASSIGNMENTS}
        >
            <TestAssignments />
        </ProtectedRoute>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
