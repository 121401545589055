import cn from 'classnames'
import React, { useMemo } from 'react'
import { HomeworkTaskNumberTitle } from 'components/shared'
import { LOCAL } from 'core/local'
import { getShortFio } from 'utils'

import styles from './Reference.module.scss'
import { ReferenceItem } from '../ReferenceItem'
import { ReferenceProps } from './Reference.types'

export const Reference: React.FC<ReferenceProps> = React.memo(
    ({ field, form, subtaskBlocksHeight, isTasksTitlesVisible, data }) => {
        const taskNumber = form?.getFieldValue([
            'taskPassings',
            field.name,
            'taskNumber',
        ])

        const randomizeReferences = useMemo(() => {
            const randomCoef = 0.5
            const currentData = data?.find((el) => el.taskNumber === taskNumber)

            return currentData?.missionTaskPassingReferences?.sort(
                () => Math.random() - randomCoef
            )
        }, [data, taskNumber])

        return (
            <div
                className={styles.wrapper}
                style={{ height: `${subtaskBlocksHeight?.[field.name]}px` }}
            >
                <HomeworkTaskNumberTitle
                    isTasksTitlesVisible={isTasksTitlesVisible}
                    taskNumber={taskNumber}
                    className={styles.titleTaskNumber}
                />

                {randomizeReferences ? (
                    randomizeReferences.map((el, index) => (
                        <div
                            className={cn(
                                styles.reference,
                                styles[`reference${el.status}`]
                            )}
                            key={index}
                        >
                            <ReferenceItem
                                label={el.userId}
                                text={el.answerText}
                                isTutorAnswer={false}
                            />

                            <ReferenceItem
                                label={getShortFio(el.tutor)}
                                text={el.comment ?? LOCAL.MESSAGES.NO_COMMENT}
                                fieldName={field.name}
                                form={form}
                            />
                        </div>
                    ))
                ) : (
                    <div
                        className={cn(
                            styles.emptyData,
                            isTasksTitlesVisible && styles.emptyDataWithTitle
                        )}
                    >
                        {LOCAL.LABELS.EMPTY_DATA}
                    </div>
                )}
            </div>
        )
    }
)
