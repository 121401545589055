import { DocumentStatus, QuestionnaireType } from 'core/api'
import { LOCAL } from 'core/local'

export const PROGRESS_VISUALIZATION = [
    {
        label: LOCAL.LABELS.PASSING_BLOCKS,
        fieldName: 'isLessonsVisible',
    },
    {
        label: LOCAL.LABELS.COMPLETING_TASKS,
        fieldName: 'isHomeworksVisible',
    },
    {
        label: LOCAL.LABELS.WEBINARS,
        fieldName: 'isWebinarsVisible',
    },
    {
        label: LOCAL.LABELS.YOU_RATING,
        fieldName: 'isRatingVisible',
    },
]

export const MENU_VISUALIZATION = [
    {
        label: LOCAL.LABELS.SUBSTANCE,
        fieldName: 'isContentsVisible',
    },
    {
        label: LOCAL.LABELS.ADDITIONAL_MATERIALS,
        fieldName: 'isAdditionalMaterialsVisible',
    },
    {
        label: LOCAL.LABELS.HOMEWORK,
        fieldName: 'isHomeWorksVisible',
    },
]

export const PROGRESS_VISIBLE_FIELD = [
    'trackProgressVisualization',
    'isProgressVisible',
]

export const MENU_VISIBLE_FIELD = ['trackMenuVisualization', 'isMenuVisible']

export const QUESTIONNAIRES_TABLE_FILTER_OPTIONS = {
    initialValues: {
        types: [QuestionnaireType.Quiz],
        excludeStatuses: [DocumentStatus.Draft, DocumentStatus.InWork],
    },
}
