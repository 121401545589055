import React, { useCallback, useEffect, useReducer, useState } from 'react'
import {
    AppError,
    AttachmentContract,
    DictionaryContract,
    NotificationMailingTasksService,
    NotificationNewsletterTemplatesService,
} from 'core/api'
import { CKEditorAdapter, IconsAdapter, PopupWithForm } from 'components/shared'
import { CKEditorEventAction, stripPrefix } from 'ckeditor4-react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    DynamicMarkerControl,
    FormItemAdapter,
    InputControl,
    SelectControl,
    ShouldUpdateChecker,
    UploadAttachments,
} from 'components/controls'
import { DEFAULT_STYLES } from 'components/shared/CKEditorAdapter/CKEditorAdapter.config'
import {
    EDITOR_TEMPLATE_EXTRA_PLUGINS_LIST,
    EDITOR_TEMPLATE_TOOLBAR_OPTIONS,
    MODAL_WIDTH,
    ROW_GUTTER,
} from 'consts'
import { EmailPreviewForm } from 'components/forms'
import { FORM_IDS } from 'core/configs'
import { FocusedElementProps } from 'App.types'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { RcCustomRequestOptions } from 'types'
import { UploadFile } from 'antd/lib/upload/interface'
import {
    addMarker,
    convertToShouldUpdateDep,
    isFormModeView,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { editorReducer } from 'components/shared/CKEditorAdapter/CKEditorAdapter.utils'
import { useHttp } from 'hooks'

import styles from './DistributionMailingMail.module.scss'
import { DYNAMIC_MARKER_SHOULD_UPDATE_DEP } from './DistributionMailingMail.consts'
import { DistributionMailingMailProps } from './DistributionMailingMail.types'
import { onChangeNotificationTheme } from './DistributionMailingMail.utils'

/** Письмо задания на рассылку */
export const DistributionMailingMail: React.FC<DistributionMailingMailProps> = React.memo(
    ({ form, formMode, initialValuesForEdit }) => {
        const [state, dispatch] = useReducer(editorReducer, {
            styles: DEFAULT_STYLES,
        })

        const [focusedField, setFocusedField] = useState<FocusedElementProps>()
        const [templateId, setTemplateId] = useState<number>()
        const [newNotificationBody, setNewNotificationBody] = useState('')

        const [fetchTemplates, templates] = useHttp<DictionaryContract[]>(
            NotificationNewsletterTemplatesService.getForSelect
        )

        useEffect(() => {
            fetchTemplates()
        }, [fetchTemplates])

        /**
         * Обработчик добавления файла
         */
        const handleUploadAttachment = useCallback(
            async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
                const attachments: UploadFile<
                    AttachmentContract
                >[] = form?.getFieldValue('attachmentIds')

                try {
                    const upload = await NotificationMailingTasksService.upload(
                        {
                            file,
                            ids: attachments.map(
                                (item) => item.response?.id
                            ) as [],
                        }
                    )

                    onSuccess?.(upload, {} as XMLHttpRequest)
                } catch (error) {
                    onError?.(error as AppError)
                }
            },
            [form]
        )

        const handleAddMarker = useCallback(
            (marker: LabeledValue) => {
                if (focusedField) {
                    addMarker(form, focusedField)(marker)

                    return
                }

                dispatch({
                    type: stripPrefix(CKEditorEventAction.insertHtml),
                    payload: { html: `{${marker.label}}` },
                })
            },
            [focusedField, form]
        )

        const handleTemplateChange = useCallback(
            (_, { value }) =>
                onChangeNotificationTheme({
                    id: value,
                    setTemplateId,
                    form,
                    prevId: templateId,
                }),
            [form, templateId]
        )

        useEffect(() => {
            const notificationMailBody = form?.getFieldValue(
                'notificationMailBody'
            )

            setNewNotificationBody(
                notificationMailBody
                    ? notificationMailBody
                    : initialValuesForEdit?.notificationBody ?? ''
            )
        }, [state, form, dispatch, templateId, initialValuesForEdit])

        useEffect(() => {
            const initialTemplateId = form?.getFieldValue('templateId')

            if (!initialTemplateId) return

            onChangeNotificationTheme({
                id: initialTemplateId,
                setTemplateId,
                form,
                initialValuesForEdit,
            })
        }, [form, initialValuesForEdit])

        return (
            <ControlGroup
                title={LOCAL.LABELS.MAIL}
                type="bordered"
                className={styles.wrapper}
            >
                <ShouldUpdateChecker
                    fieldPath={[['notificationTheme'], ['notificationBody']]}
                >
                    {({ getFieldsValue }) => (
                        <PopupWithForm
                            formId={FORM_IDS.EMAIL_PREVIEW_FORM}
                            buttonText={LOCAL.ACTIONS.PREVIEW}
                            buttonOption={{
                                className: 'preview-button',
                            }}
                            modalOptions={{
                                footer: null,
                                width: MODAL_WIDTH.XL,
                                title: LOCAL.ACTIONS.PREVIEW,
                            }}
                            component={EmailPreviewForm}
                            formOptions={{
                                initialValues: getFieldsValue([
                                    'notificationTheme',
                                    'notificationBody',
                                ]),
                            }}
                        />
                    )}
                </ShouldUpdateChecker>

                {!isFormModeView(formMode) && (
                    <Row gutter={ROW_GUTTER}>
                        <Col xs={12}>
                            <FormItemAdapter
                                fieldName="templateId"
                                label={LOCAL.LABELS.TEMPLATE_NOTIFICATION}
                            >
                                <SelectControl
                                    formMode={formMode}
                                    showSearch
                                    allowClear
                                    placeholder={
                                        LOCAL.PLACEHOLDERS.SEARCH_SELECT
                                    }
                                    onSelect={handleTemplateChange}
                                    onClear={() =>
                                        form?.resetFields([
                                            'notificationTheme',
                                            'notificationBody',
                                        ])
                                    }
                                    values={
                                        templates?.map(
                                            normalizeDataForSelectAndRadio
                                        ) || []
                                    }
                                />
                            </FormItemAdapter>

                            <Link
                                to={`${ROUTE_NAMES.DISTRIBUTION_TEMPLATES}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.link}
                            >
                                <IconsAdapter iconType="UnorderedListOutlined" />

                                <span>{LOCAL.LABELS.TEMPLATE_REGISTRY}</span>
                            </Link>
                        </Col>

                        <Col xs={12}>
                            <ShouldUpdateChecker
                                fieldPath={convertToShouldUpdateDep(
                                    DYNAMIC_MARKER_SHOULD_UPDATE_DEP
                                )}
                            >
                                {({ getFieldsValue }) => {
                                    const {
                                        category,
                                        eventId,
                                    } = getFieldsValue(
                                        DYNAMIC_MARKER_SHOULD_UPDATE_DEP
                                    )

                                    return (
                                        <DynamicMarkerControl
                                            form={form}
                                            onInsertMarker={handleAddMarker}
                                            categoryId={category?.value}
                                            eventId={eventId}
                                        />
                                    )
                                }}
                            </ShouldUpdateChecker>
                        </Col>
                    </Row>
                )}

                <FormItemAdapter
                    fieldName="notificationTheme"
                    label={LOCAL.LABELS.MAILING_THEME}
                    required
                >
                    <InputControl
                        formMode={formMode}
                        onFocus={({ currentTarget }) =>
                            setFocusedField({
                                name: 'notificationTheme',
                                currentTarget,
                            })
                        }
                    />
                </FormItemAdapter>

                <FormItemAdapter
                    fieldName="notificationBody"
                    label={LOCAL.LABELS.MESSAGE_BODY}
                    required
                >
                    <CKEditorAdapter
                        dispatchEvent={dispatch}
                        extraPlugins={EDITOR_TEMPLATE_EXTRA_PLUGINS_LIST}
                        toolbar={EDITOR_TEMPLATE_TOOLBAR_OPTIONS}
                        onFocus={() => setFocusedField(undefined)}
                        newValue={newNotificationBody}
                        readOnly={isFormModeView(formMode)}
                    />
                </FormItemAdapter>

                <ShouldUpdateChecker fieldPath={['attachmentIds']}>
                    <UploadAttachments
                        name="attachmentIds"
                        form={form}
                        formMode={formMode}
                        customUploadHandler={handleUploadAttachment}
                        maxCount={false}
                        overallFilesMaxSize={20}
                    />
                </ShouldUpdateChecker>
            </ControlGroup>
        )
    }
)
