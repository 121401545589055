import React, { useMemo } from 'react'
import { CompetenceGroupWithCompetenciesContract } from 'core/api'
import { Droppable } from 'react-beautiful-dnd'

import styles from '../CompetenciesOrderOptions.module.scss'
import { ChildrenNodesWithSpoiler } from './ChildrenNodesWithSpoiler'
import { CompetenciesOrderOptionsChildProps } from '../CompetenciesOrderOptions.types'
import { Indicators } from './Indicators'

export const Competencies: React.FC<CompetenciesOrderOptionsChildProps<
    CompetenceGroupWithCompetenciesContract
>> = React.memo(({ groupData, groupIndex, className }) => {
    const competenciesData = useMemo(
        () =>
            groupData.competencies?.map((competence, index) => {
                const key = `competence-${competence.id}`

                return (
                    <ChildrenNodesWithSpoiler
                        key={key}
                        dndKey={key}
                        draggableId={key}
                        index={index}
                        name={`${competence.name}`}
                    >
                        {!!competence.indicators?.length && (
                            <Indicators
                                groupIndex={index}
                                competenceGroupIndex={groupIndex}
                                groupData={competence}
                                className={styles.indicators}
                            />
                        )}
                    </ChildrenNodesWithSpoiler>
                )
            }),
        [groupData.competencies, groupIndex]
    )

    return (
        <Droppable
            droppableId={`group-${groupData.id}`}
            type={`competence-${groupIndex}`}
        >
            {(provided) => (
                <div ref={provided.innerRef} className={className}>
                    {competenciesData}

                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
})
