import React, { useContext, useEffect } from 'react'
import { Circle360ReportService } from 'core/api'
import { ColorThemeContext } from 'contexts'
import { Diagram, NoChartData } from 'components/shared'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import {
    makePercentileDiagramOptions,
    preparePercentilesForDiagram,
} from './Percentile.config'

/** Отчет по оценке 360. Процентиль */
export const Percentile: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp(Circle360ReportService.getPercentiles)
    const { theme } = useContext(ColorThemeContext)

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return data?.length ? (
        <Diagram
            type="line"
            options={makePercentileDiagramOptions(theme)}
            data={{
                datasets: [preparePercentilesForDiagram(theme, data)],
            }}
            chartDataLabelsPlugin
        />
    ) : (
        <NoChartData />
    )
})
