import { LOCAL } from 'core/local'

import { RoutesMetaProps } from './routeNames.types'

/** постфиксы маршрутов */
export const ROUTES_POSTFIXES = {
    CREATE: 'create',
    EDIT: 'edit',
    VIEW: 'view',
    COPY: 'copy',
    PASSING: 'passing',
    FILTER: 'filter',
    REPORT: 'report',
    ORDER_OPTIONS: 'order-options',
    PREVIEW: 'preview',
    COURSE: 'course',
    SETTINGS: 'settings',
    IMPORT: 'import',
    UNIQUE: 'unique',
    START: 'start',
}

const ADMINISTRATION = 'administration'
const ASSESSMENT = 'assessment'
const USER_ASSIGNMENTS = 'user-assignments'
const ASSESSMENT_ASSIGNED = 'assessment-assigned'
const ASSESSMENT_ASSIGNED_EVALUATION = 'assessment-assigned-evaluation'
const ASSESSMENT_ASSIGNMENTS = 'assessment-assignments'
const ASSESSMENT_PORTFOLIOS = 'assessment-portfolios'
const ASSIGNMENTS = 'assignments'
const APPROVE = 'approve'
const CONSOLIDATED_REPORTS = 'consolidated-reports'
const PROFILE_REPORTS = 'profile-reports'
const CONTENT = 'content'
const COMPETENCIES = 'competencies'
const COMPETENCIES_GROUPS = 'groups'
const COMPETENCIES_INDICATORS = 'indicators'
const COWORKERS = 'coworkers'
const CRITERIA_SCALES = 'criteria-scales'
const DOCUMENTS_EDITOR = 'documents-editor'
const DEVELOPMENT_GOALS = 'development-goals'
const EVENTS = 'events'
const EVENT_ASSIGNMENTS = 'events-assignments'
const KNOWLEDGE_BASE = 'knowledge-base'
const INFO = 'info'
const MATERIALS = 'materials'
const NOTIFICATIONS_SETTINGS = 'notifications-settings'
const ORGANIZATIONS = 'organizations'
const POSITION_PROFILES = 'position-profiles'
const PRODUCT_CATALOG = 'product-catalog'
const PRODUCT_CARDS = 'product-cards'
const PROFILING = 'profiling'
const QUESTIONNAIRES_LISTENER = 'questionnaires-listener'
const QUESTIONNAIRE_PASSING = 'questionnaire-passing'
const QUESTIONNAIRE_TEMPLATES = 'questionnaire-templates'
const QUIZ = 'quiz'
const QUIZ_ASSIGNMENTS = 'quiz-assignments'
const QUIZ_PORTFOLIOS = 'quiz-portfolios'
const STAFF_UNITS = 'staff-units'
const SUPERVISING_COMPLEXES = 'supervising-complexes'
const TEST = 'test'
const TEST_ASSIGNMENTS = 'test-assignments'
const TEST_PORTFOLIOS = 'test-portfolios'
const THEMES = 'themes'
const TASKS = 'tasks'
const TRACKS = 'tracks'
const TRACKS_ADAPTATION = 'tracks-adaptation'
const TRACKS_DEVELOPMENT = 'tracks-development'
const TRAINING_MATERIALS = 'training-materials'
const USER_NOTIFICATIONS = 'user-notifications'
const USER_PROFILE = 'user-profile'
const CERTIFICATES = 'certificates'
const INTERNAL_CERTIFICATES = 'internal-certificates'
const EXTERNAL_CERTIFICATES = 'external-certificates'
const THEMATIC_BLOCK = 'thematic-block'
const YOUR_DEVELOPMENT = 'your-development'
const WORK_IN_PROGRESS = 'work-in-progress'
const ASSESSMENT_PROJECTS = 'assessment-projects'
const REPORTS = 'reports'
const DISTRIBUTION = 'distribution'
const TAGS = 'tags'
const TEMPLATES = 'templates'
const MAILING = 'mailing'
const MAILING_ASSIGNMENT = 'mailing-assignment'

// TODO: исходя из аналитики в будущем данных роутов не будет (временная необходимость),
// вместо них будут подставлены существующие
const USER_PERSONAL_AREA = 'user-personal-area'
const PA_MY_DEVELOPMENT = 'my-development'
const PA_FAVORITES = 'favorites'
const PA_HOMEWORK = 'homework'
const PA_QUIZZES = 'quizzes'
const PA_ASSESSMENTS = 'assessments'
const PA_PROFILE = 'profile'
const PA_CHECK_HOMEWORK = 'check-homework'
const PA_CHECK_QUIZZES = 'check-quizzes'
const PA_MEETINGS_RESULTS = 'meetings-results'
const PA_MONITORING = 'monitoring'
const PA_ASSIGNED_ASSESSMENTS = 'assigned-assessments'
const PA_REPORTS = 'reports'

export const ROUTE_NAMES = {
    ADMINISTRATION: `/${ADMINISTRATION}`,
    ASSESSMENT: `/${ASSESSMENT}`,
    ASSESSMENT_ASSIGNED: `/${ASSESSMENT}/${ASSESSMENT_ASSIGNED}`,
    ASSESSMENT_ASSIGNED_EVALUATION: `/${ASSESSMENT}/${ASSESSMENT_ASSIGNED}/${ASSESSMENT_ASSIGNED_EVALUATION}`,
    ASSESSMENT_ASSIGNMENTS: `/${ASSESSMENT}/${ASSESSMENT_ASSIGNMENTS}`,
    ASSESSMENT_ASSIGNMENTS_CREATE: `/${ASSESSMENT}/${ASSESSMENT_ASSIGNMENTS}/${ROUTES_POSTFIXES.CREATE}`,
    ASSESSMENT_ASSIGNMENTS_EDIT: `/${ASSESSMENT}/${ASSESSMENT_ASSIGNMENTS}/${ROUTES_POSTFIXES.EDIT}`,
    ASSESSMENT_ASSIGNMENTS_VIEW: `/${ASSESSMENT}/${ASSESSMENT_ASSIGNMENTS}/${ROUTES_POSTFIXES.VIEW}`,
    ASSESSMENT_REPORTS: `/${ASSESSMENT}/${REPORTS}`,
    ASSESSMENT_REPORTS_SETTINGS: `/${ASSESSMENT}/${REPORTS}/${ROUTES_POSTFIXES.SETTINGS}`,
    ASSESSMENT_PORTFOLIOS: `/${ASSESSMENT}/${ASSESSMENT_PORTFOLIOS}`,
    ASSESSMENT_PORTFOLIOS_CREATE: `/${ASSESSMENT}/${ASSESSMENT_PORTFOLIOS}/${ROUTES_POSTFIXES.CREATE}`,
    ASSESSMENT_PORTFOLIOS_COPY: `/${ASSESSMENT}/${ASSESSMENT_PORTFOLIOS}/${ROUTES_POSTFIXES.COPY}`,
    ASSESSMENT_PORTFOLIOS_EDIT: `/${ASSESSMENT}/${ASSESSMENT_PORTFOLIOS}/${ROUTES_POSTFIXES.EDIT}`,
    ASSESSMENT_PORTFOLIOS_VIEW: `/${ASSESSMENT}/${ASSESSMENT_PORTFOLIOS}/${ROUTES_POSTFIXES.VIEW}`,
    ASSESSMENT_PROJECTS: `/${ASSESSMENT}/${ASSESSMENT_PROJECTS}`,
    ASSESSMENT_PROJECTS_CREATE: `/${ASSESSMENT}/${ASSESSMENT_PROJECTS}/${ROUTES_POSTFIXES.CREATE}`,
    ASSESSMENT_PROJECTS_COPY: `/${ASSESSMENT}/${ASSESSMENT_PROJECTS}/${ROUTES_POSTFIXES.COPY}`,
    ASSESSMENT_PROJECTS_EDIT: `/${ASSESSMENT}/${ASSESSMENT_PROJECTS}/${ROUTES_POSTFIXES.EDIT}`,
    ASSESSMENT_PROJECTS_VIEW: `/${ASSESSMENT}/${ASSESSMENT_PROJECTS}/${ROUTES_POSTFIXES.VIEW}`,
    APPROVE: `/${APPROVE}`,
    APPROVE_CREATE: `/${APPROVE}/${ROUTES_POSTFIXES.CREATE}`,
    APPROVE_VIEW: `/${APPROVE}/${ROUTES_POSTFIXES.VIEW}`,
    CONSOLIDATED_REPORTS: `/${ASSESSMENT}/${CONSOLIDATED_REPORTS}`,
    PROFILE_REPORTS: `/${ASSESSMENT}/${PROFILE_REPORTS}`,
    COMPETENCIES: `/${PROFILING}/${COMPETENCIES}`,
    COMPETENCIES_CREATE: `/${PROFILING}/${COMPETENCIES}/${ROUTES_POSTFIXES.CREATE}`,
    COMPETENCIES_EDIT: `/${PROFILING}/${COMPETENCIES}/${ROUTES_POSTFIXES.EDIT}`,
    COMPETENCIES_VIEW: `/${PROFILING}/${COMPETENCIES}/${ROUTES_POSTFIXES.VIEW}`,
    COMPETENCIES_GROUPS: `/${PROFILING}/${COMPETENCIES}/${COMPETENCIES_GROUPS}`,
    COMPETENCIES_INDICATORS: `/${PROFILING}/${COMPETENCIES}/${COMPETENCIES_INDICATORS}`,
    COMPETENCIES_ORDER_OPTIONS: `/${PROFILING}/${COMPETENCIES}/${ROUTES_POSTFIXES.ORDER_OPTIONS}`,
    CONTENT: `/${CONTENT}`,
    COWORKERS: `/${ADMINISTRATION}/${COWORKERS}`,
    CRITERIA_SCALES: `/${MATERIALS}/${CRITERIA_SCALES}`,
    CRITERIA_SCALES_CREATE: `/${MATERIALS}/${CRITERIA_SCALES}/${ROUTES_POSTFIXES.CREATE}`,
    CRITERIA_SCALES_EDIT: `/${MATERIALS}/${CRITERIA_SCALES}/${ROUTES_POSTFIXES.EDIT}`,
    DOCUMENTS_EDITOR: `/${MATERIALS}/${DOCUMENTS_EDITOR}`,
    DOCUMENTS_EDITOR_EDIT: `/${MATERIALS}/${DOCUMENTS_EDITOR}/${ROUTES_POSTFIXES.EDIT}`,
    DEVELOPMENT_GOALS: `/${TRACKS}/${DEVELOPMENT_GOALS}`,
    EVENTS: `/${EVENTS}`,
    EVENTS_CREATE: `/${EVENTS}/${ROUTES_POSTFIXES.CREATE}`,
    EVENTS_COPY: `/${EVENTS}/${ROUTES_POSTFIXES.COPY}`,
    EVENTS_EDIT: `/${EVENTS}/${ROUTES_POSTFIXES.EDIT}`,
    EVENTS_VIEW: `/${EVENTS}/${ROUTES_POSTFIXES.VIEW}`,
    EVENTS_ASSIGNMENTS: `/${EVENTS}/${EVENT_ASSIGNMENTS}`,
    EVENTS_ASSIGNMENTS_CREATE: `/${EVENTS}/${EVENT_ASSIGNMENTS}/${ROUTES_POSTFIXES.CREATE}`,
    EVENTS_ASSIGNMENTS_EDIT: `/${EVENTS}/${EVENT_ASSIGNMENTS}/${ROUTES_POSTFIXES.EDIT}`,
    EVENTS_ASSIGNMENTS_VIEW: `/${EVENTS}/${EVENT_ASSIGNMENTS}/${ROUTES_POSTFIXES.VIEW}`,
    EVENTS_PASSING: `/${EVENTS}/${ROUTES_POSTFIXES.PASSING}`,
    EVENTS_PASSING_VIEW: `/${EVENTS}/${ROUTES_POSTFIXES.PASSING}/${ROUTES_POSTFIXES.VIEW}`,
    KNOWLEDGE_BASE: `/${KNOWLEDGE_BASE}`,
    KNOWLEDGE_BASE_CREATE: `/${KNOWLEDGE_BASE}/${ROUTES_POSTFIXES.CREATE}`,
    KNOWLEDGE_BASE_EDIT: `/${KNOWLEDGE_BASE}/${ROUTES_POSTFIXES.EDIT}`,
    KNOWLEDGE_BASE_VIEW: `/${KNOWLEDGE_BASE}/${ROUTES_POSTFIXES.VIEW}`,
    MAIN: '/',
    MATERIALS: `/${MATERIALS}`,
    NOTIFICATIONS_SETTINGS: `/${NOTIFICATIONS_SETTINGS}`,
    ORGANIZATIONS: `/${ADMINISTRATION}/${ORGANIZATIONS}`,
    POSITION_PROFILES: `/${PROFILING}/${POSITION_PROFILES}`,
    POSITION_PROFILES_IMPORT: `/${PROFILING}/${POSITION_PROFILES}/${ROUTES_POSTFIXES.IMPORT}`,
    POSITION_PROFILES_CREATE: `/${PROFILING}/${POSITION_PROFILES}/${ROUTES_POSTFIXES.CREATE}`,
    POSITION_PROFILES_EDIT: `/${PROFILING}/${POSITION_PROFILES}/${ROUTES_POSTFIXES.EDIT}`,
    POSITION_PROFILES_VIEW: `/${PROFILING}/${POSITION_PROFILES}/${ROUTES_POSTFIXES.VIEW}`,
    POSITION_PROFILES_COPY: `/${PROFILING}/${POSITION_PROFILES}/${ROUTES_POSTFIXES.COPY}`,
    PRODUCT_CARDS: `/${PRODUCT_CARDS}`,
    PRODUCT_CARD_VIEW: `/${PRODUCT_CARDS}/${ROUTES_POSTFIXES.VIEW}`,
    PRODUCT_CATALOG: `/${PRODUCT_CATALOG}`,
    PRODUCT_CATALOG_CREATE: `/${PRODUCT_CATALOG}/${ROUTES_POSTFIXES.CREATE}`,
    PRODUCT_CATALOG_COPY: `/${PRODUCT_CATALOG}/${ROUTES_POSTFIXES.COPY}`,
    PRODUCT_CATALOG_EDIT: `/${PRODUCT_CATALOG}/${ROUTES_POSTFIXES.EDIT}`,
    PRODUCT_CATALOG_VIEW: `/${PRODUCT_CATALOG}/${ROUTES_POSTFIXES.VIEW}`,
    PROFILING: `/${PROFILING}`,
    QUESTIONNAIRES_LISTENER: `/${QUESTIONNAIRES_LISTENER}`,
    QUESTIONNAIRE_PASSING: `/${QUESTIONNAIRES_LISTENER}/${QUESTIONNAIRE_PASSING}`,
    QUESTIONNAIRE_TEMPLATES: `/${MATERIALS}/${QUESTIONNAIRE_TEMPLATES}`,
    QUESTIONNAIRE_TEMPLATES_CREATE: `/${MATERIALS}/${QUESTIONNAIRE_TEMPLATES}/${ROUTES_POSTFIXES.CREATE}`,
    QUESTIONNAIRE_TEMPLATES_EDIT: `/${MATERIALS}/${QUESTIONNAIRE_TEMPLATES}/${ROUTES_POSTFIXES.EDIT}`,
    QUESTIONNAIRE_TEMPLATES_VIEW: `/${MATERIALS}/${QUESTIONNAIRE_TEMPLATES}/${ROUTES_POSTFIXES.VIEW}`,
    QUESTIONNAIRE_TEMPLATES_COPY: `/${MATERIALS}/${QUESTIONNAIRE_TEMPLATES}/${ROUTES_POSTFIXES.COPY}`,
    QUIZ: `/${QUIZ}`,
    QUIZ_ASSIGNMENTS: `/${QUIZ}/${QUIZ_ASSIGNMENTS}`,
    QUIZ_ASSIGNMENTS_CREATE: `/${QUIZ}/${QUIZ_ASSIGNMENTS}/${ROUTES_POSTFIXES.CREATE}`,
    QUIZ_ASSIGNMENTS_EDIT: `/${QUIZ}/${QUIZ_ASSIGNMENTS}/${ROUTES_POSTFIXES.EDIT}`,
    QUIZ_ASSIGNMENTS_VIEW: `/${QUIZ}/${QUIZ_ASSIGNMENTS}/${ROUTES_POSTFIXES.VIEW}`,
    QUIZ_PORTFOLIOS: `/${QUIZ}/${QUIZ_PORTFOLIOS}`,
    QUIZ_PORTFOLIOS_CREATE: `/${QUIZ}/${QUIZ_PORTFOLIOS}/${ROUTES_POSTFIXES.CREATE}`,
    QUIZ_PORTFOLIOS_COPY: `/${QUIZ}/${QUIZ_PORTFOLIOS}/${ROUTES_POSTFIXES.COPY}`,
    QUIZ_PORTFOLIOS_EDIT: `/${QUIZ}/${QUIZ_PORTFOLIOS}/${ROUTES_POSTFIXES.EDIT}`,
    QUIZ_PORTFOLIOS_VIEW: `/${QUIZ}/${QUIZ_PORTFOLIOS}/${ROUTES_POSTFIXES.VIEW}`,
    QUIZ_REPORT: `/${QUIZ}/${ROUTES_POSTFIXES.REPORT}`,
    REPORTS: `/${MATERIALS}/${REPORTS}`,
    STAFF_UNITS: `/${ADMINISTRATION}/${STAFF_UNITS}`,
    SUPERVISING_COMPLEXES: `/${ADMINISTRATION}/${SUPERVISING_COMPLEXES}`,
    TASKS: `/${MATERIALS}/${TASKS}`,
    TASKS_CREATE: `/${MATERIALS}/${TASKS}/${ROUTES_POSTFIXES.CREATE}`,
    TASKS_EDIT: `/${MATERIALS}/${TASKS}/${ROUTES_POSTFIXES.EDIT}`,
    TEST: `/${TEST}`,
    TEST_REPORT: `/${TEST}/${ROUTES_POSTFIXES.REPORT}`,
    TEST_ASSIGNMENTS: `/${TEST}/${TEST_ASSIGNMENTS}`,
    TEST_ASSIGNMENTS_CREATE: `/${TEST}/${TEST_ASSIGNMENTS}/${ROUTES_POSTFIXES.CREATE}`,
    TEST_ASSIGNMENTS_COPY: `/${TEST}/${TEST_ASSIGNMENTS}/${ROUTES_POSTFIXES.COPY}`,
    TEST_ASSIGNMENTS_EDIT: `/${TEST}/${TEST_ASSIGNMENTS}/${ROUTES_POSTFIXES.EDIT}`,
    TEST_ASSIGNMENTS_VIEW: `/${TEST}/${TEST_ASSIGNMENTS}/${ROUTES_POSTFIXES.VIEW}`,
    TEST_PORTFOLIOS: `/${TEST}/${TEST_PORTFOLIOS}`,
    TEST_PORTFOLIOS_CREATE: `/${TEST}/${TEST_PORTFOLIOS}/${ROUTES_POSTFIXES.CREATE}`,
    TEST_PORTFOLIOS_COPY: `/${TEST}/${TEST_PORTFOLIOS}/${ROUTES_POSTFIXES.COPY}`,
    TEST_PORTFOLIOS_EDIT: `/${TEST}/${TEST_PORTFOLIOS}/${ROUTES_POSTFIXES.EDIT}`,
    TEST_PORTFOLIOS_VIEW: `/${TEST}/${TEST_PORTFOLIOS}/${ROUTES_POSTFIXES.VIEW}`,
    THEMES: `/${PRODUCT_CATALOG}/${THEMES}`,
    THEMES_ORDER_OPTIONS: `/${PRODUCT_CATALOG}/${THEMES}/${ROUTES_POSTFIXES.ORDER_OPTIONS}`,
    TRACKS: `/${TRACKS}`,
    TRACKS_UNIQUE: `/${TRACKS}/${ROUTES_POSTFIXES.UNIQUE}`,
    TRACKS_PASSING: `/${TRACKS}/${ROUTES_POSTFIXES.PASSING}`,
    TRACKS_PASSING_v2: `/${TRACKS}/${ROUTES_POSTFIXES.PASSING}_v2`,
    TRACKS_PASSING_UNIQUE: `/${TRACKS}/${ROUTES_POSTFIXES.UNIQUE}/${ROUTES_POSTFIXES.PASSING}`,
    TRACKS_ADAPTATION: `/${TRACKS}/${TRACKS_ADAPTATION}`,
    TRACKS_ADAPTATION_CREATE: `/${TRACKS}/${TRACKS_ADAPTATION}/${ROUTES_POSTFIXES.CREATE}`,
    TRACKS_ADAPTATION_EDIT: `/${TRACKS}/${TRACKS_ADAPTATION}/${ROUTES_POSTFIXES.EDIT}`,
    TRACKS_ADAPTATION_VIEW: `/${TRACKS}/${TRACKS_ADAPTATION}/${ROUTES_POSTFIXES.VIEW}`,
    TRACKS_ADAPTATION_PREVIEW: `/${TRACKS}/${TRACKS_ADAPTATION}/${ROUTES_POSTFIXES.PREVIEW}`,
    TRACKS_ADAPTATION_PREVIEW_v2: `/${TRACKS}/${TRACKS_ADAPTATION}/${ROUTES_POSTFIXES.PREVIEW}_v2`,
    TRACKS_ASSIGNMENTS: `/${TRACKS}/${ASSIGNMENTS}`,
    TRACKS_ASSIGNMENTS_CREATE: `/${TRACKS}/${ASSIGNMENTS}/${ROUTES_POSTFIXES.CREATE}`,
    TRACKS_ASSIGNMENTS_EDIT: `/${TRACKS}/${ASSIGNMENTS}/${ROUTES_POSTFIXES.EDIT}`,
    TRACKS_ASSIGNMENTS_COPY: `/${TRACKS}/${ASSIGNMENTS}/${ROUTES_POSTFIXES.COPY}`,
    TRACKS_ASSIGNMENTS_VIEW: `/${TRACKS}/${ASSIGNMENTS}/${ROUTES_POSTFIXES.VIEW}`,
    TRACKS_DEVELOPMENT: `/${TRACKS}/${TRACKS_DEVELOPMENT}`,
    TRACKS_DEVELOPMENT_CREATE: `/${TRACKS}/${TRACKS_DEVELOPMENT}/${ROUTES_POSTFIXES.CREATE}`,
    TRACKS_DEVELOPMENT_EDIT: `/${TRACKS}/${TRACKS_DEVELOPMENT}/${ROUTES_POSTFIXES.EDIT}`,
    TRACKS_DEVELOPMENT_VIEW: `/${TRACKS}/${TRACKS_DEVELOPMENT}/${ROUTES_POSTFIXES.VIEW}`,
    THEMATIC_BLOCK: `/${THEMATIC_BLOCK}`,
    TRAINING_MATERIALS: `/${MATERIALS}/${TRAINING_MATERIALS}`,
    TRAINING_MATERIALS_INFO: `/${MATERIALS}/${TRAINING_MATERIALS}/${INFO}`,
    USER_ASSIGNMENTS: `/${USER_ASSIGNMENTS}`,
    USER_ASSIGNMENTS_PASSING: `/${USER_ASSIGNMENTS}/${ROUTES_POSTFIXES.PASSING}`,
    USER_ASSIGNMENTS_VIEW: `/${USER_ASSIGNMENTS}/${ROUTES_POSTFIXES.VIEW}`,
    USER_NOTIFICATIONS: `/${USER_NOTIFICATIONS}`,
    USER_PROFILE: `/${USER_PROFILE}`,
    CERTIFICATES: `/${CERTIFICATES}`,
    INTERNAL_CERTIFICATES: `/${CERTIFICATES}/${INTERNAL_CERTIFICATES}`,
    EXTERNAL_CERTIFICATES: `/${CERTIFICATES}/${EXTERNAL_CERTIFICATES}`,
    EXTERNAL_CERTIFICATES_CREATE: `/${CERTIFICATES}/${EXTERNAL_CERTIFICATES}/${ROUTES_POSTFIXES.CREATE}`,
    EXTERNAL_CERTIFICATES_EDIT: `/${CERTIFICATES}/${EXTERNAL_CERTIFICATES}/${ROUTES_POSTFIXES.EDIT}`,
    EXTERNAL_CERTIFICATES_VIEW: `/${CERTIFICATES}/${EXTERNAL_CERTIFICATES}/${ROUTES_POSTFIXES.VIEW}`,
    YOUR_DEVELOPMENT: `/${YOUR_DEVELOPMENT}`,
    YOUR_DEVELOPMENT_v2: `/${YOUR_DEVELOPMENT}_v2`,
    WORK_IN_PROGRESS: `/${WORK_IN_PROGRESS}`,
    DISTRIBUTION: `/${DISTRIBUTION}`,
    DISTRIBUTION_MAILING_ASSIGNMENT: `/${DISTRIBUTION}/${MAILING_ASSIGNMENT}`,
    DISTRIBUTION_MAILING_ASSIGNMENT_CREATE: `/${DISTRIBUTION}/${MAILING_ASSIGNMENT}/${ROUTES_POSTFIXES.CREATE}`,
    DISTRIBUTION_MAILING_ASSIGNMENT_COPY: `/${DISTRIBUTION}/${MAILING_ASSIGNMENT}/${ROUTES_POSTFIXES.COPY}`,
    DISTRIBUTION_MAILING_ASSIGNMENT_EDIT: `/${DISTRIBUTION}/${MAILING_ASSIGNMENT}/${ROUTES_POSTFIXES.EDIT}`,
    DISTRIBUTION_MAILING_ASSIGNMENT_VIEW: `/${DISTRIBUTION}/${MAILING_ASSIGNMENT}/${ROUTES_POSTFIXES.VIEW}`,
    DISTRIBUTION_MAILING: `/${DISTRIBUTION}/${MAILING}`,
    DISTRIBUTION_MAILING_VIEW: `/${DISTRIBUTION}/${MAILING}/${ROUTES_POSTFIXES.VIEW}`,
    DISTRIBUTION_TAGS: `/${DISTRIBUTION}/${TAGS}`,
    DISTRIBUTION_TEMPLATES: `/${DISTRIBUTION}/${TEMPLATES}`,
    DISTRIBUTION_TEMPLATES_CREATE: `/${DISTRIBUTION}/${TEMPLATES}/${ROUTES_POSTFIXES.CREATE}`,
    DISTRIBUTION_TEMPLATES_EDIT: `/${DISTRIBUTION}/${TEMPLATES}/${ROUTES_POSTFIXES.EDIT}`,
    DISTRIBUTION_TEMPLATES_VIEW: `/${DISTRIBUTION}/${TEMPLATES}/${ROUTES_POSTFIXES.VIEW}`,
    DISTRIBUTION_TEMPLATES_COPY: `/${DISTRIBUTION}/${TEMPLATES}/${ROUTES_POSTFIXES.COPY}`,

    // TODO: исходя из аналитики в будущем данных роутов не будет (временная необходимость),
    // вместо них будут подставлены существующие
    USER_PERSONAL_AREA: `/${USER_PERSONAL_AREA}`,
    PA_MY_DEVELOPMENT: `/${USER_PERSONAL_AREA}/${PA_MY_DEVELOPMENT}`,
    PA_FAVORITES: `/${USER_PERSONAL_AREA}/${PA_FAVORITES}`,
    PA_HOMEWORK: `/${USER_PERSONAL_AREA}/${PA_HOMEWORK}`,
    PA_QUIZZES: `/${USER_PERSONAL_AREA}/${PA_QUIZZES}`,
    PA_ASSESSMENTS: `/${USER_PERSONAL_AREA}/${PA_ASSESSMENTS}`,
    PA_PROFILE: `/${USER_PERSONAL_AREA}/${PA_PROFILE}`,
    PA_CHECK_HOMEWORK: `/${USER_PERSONAL_AREA}/${PA_CHECK_HOMEWORK}`,
    PA_CHECK_QUIZZES: `/${USER_PERSONAL_AREA}/${PA_CHECK_QUIZZES}`,
    PA_MEETINGS_RESULTS: `/${USER_PERSONAL_AREA}/${PA_MEETINGS_RESULTS}`,
    PA_MONITORING: `/${USER_PERSONAL_AREA}/${PA_MONITORING}`,
    PA_ASSIGNED_ASSESSMENTS: `/${USER_PERSONAL_AREA}/${PA_ASSIGNED_ASSESSMENTS}`,
    PA_REPORTS: `/${USER_PERSONAL_AREA}/${PA_REPORTS}`,
}

/**
 * Мета информация для routes
 */
export const ROUTES_META: RoutesMetaProps = {
    [ROUTE_NAMES.USER_ASSIGNMENTS]: LOCAL.CATALOGS.ASSESSMENTS,
    [ROUTE_NAMES.ASSESSMENT_ASSIGNED]: LOCAL.CATALOGS.ASSESSMENT_ASSIGNED,
    [ROUTE_NAMES.ASSESSMENT_ASSIGNED_EVALUATION]:
        LOCAL.CATALOGS.ASSESSMENT_ASSIGNED_EVALUATION,
    [ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS]: {
        menuTitle: LOCAL.CATALOGS.ASSESSMENT_ASSIGNMENTS.menuTitle,
        pageTitle: LOCAL.CATALOGS.ASSESSMENT_ASSIGNMENTS.pageTitle,
    },
    [ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_CREATE]:
        LOCAL.CATALOGS.ASSESSMENT_ASSIGNMENTS_CREATE,
    [ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_EDIT]:
        LOCAL.CATALOGS.ASSESSMENT_ASSIGNMENTS_EDIT,
    [ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_VIEW]:
        LOCAL.CATALOGS.ASSESSMENT_ASSIGNMENTS_VIEW,
    [ROUTE_NAMES.ASSESSMENT_PORTFOLIOS]: {
        menuTitle: LOCAL.CATALOGS.ASSESSMENT_PORTFOLIOS.menuTitle,
        pageTitle: LOCAL.CATALOGS.ASSESSMENT_PORTFOLIOS.pageTitle,
    },
    [ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_CREATE]:
        LOCAL.CATALOGS.ASSESSMENT_PORTFOLIOS_CREATE,
    [ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_EDIT]:
        LOCAL.CATALOGS.ASSESSMENT_PORTFOLIOS_EDIT,
    [ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_VIEW]:
        LOCAL.CATALOGS.ASSESSMENT_PORTFOLIOS_VIEW,
    [ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_COPY]:
        LOCAL.CATALOGS.ASSESSMENT_PORTFOLIOS_CREATE,
    [ROUTE_NAMES.ASSESSMENT_PROJECTS]: {
        menuTitle: LOCAL.CATALOGS.ASSESSMENT_PROJECTS.menuTitle,
        pageTitle: LOCAL.CATALOGS.ASSESSMENT_PROJECTS.pageTitle,
    },
    [ROUTE_NAMES.ASSESSMENT_PROJECTS_CREATE]:
        LOCAL.CATALOGS.ASSESSMENT_PROJECTS_CREATE,
    [ROUTE_NAMES.ASSESSMENT_PROJECTS_EDIT]:
        LOCAL.CATALOGS.ASSESSMENT_PROJECTS_EDIT,
    [ROUTE_NAMES.ASSESSMENT_PROJECTS_VIEW]:
        LOCAL.CATALOGS.ASSESSMENT_PROJECTS_VIEW,
    [ROUTE_NAMES.ASSESSMENT_PROJECTS_COPY]:
        LOCAL.CATALOGS.ASSESSMENT_PROJECTS_CREATE,
    [ROUTE_NAMES.CONSOLIDATED_REPORTS]:
        LOCAL.CATALOGS.CIRCLE_ASSESSMENT_REPORTS,
    [ROUTE_NAMES.PROFILE_REPORTS]: LOCAL.CATALOGS.REPORTS,
    [ROUTE_NAMES.ASSESSMENT_REPORTS]: LOCAL.CATALOGS.REPORTS,
    [ROUTE_NAMES.ASSESSMENT_REPORTS_SETTINGS]: LOCAL.CATALOGS.REPORTS_SETTINGS,
    [ROUTE_NAMES.COMPETENCIES]: LOCAL.CATALOGS.COMPETENCIES,
    [ROUTE_NAMES.COMPETENCIES_CREATE]: LOCAL.CATALOGS.COMPETENCIES_CREATE,
    [ROUTE_NAMES.COMPETENCIES_EDIT]: LOCAL.CATALOGS.COMPETENCIES_EDIT,
    [ROUTE_NAMES.COMPETENCIES_VIEW]: LOCAL.CATALOGS.COMPETENCIES_VIEW,
    [ROUTE_NAMES.COMPETENCIES_GROUPS]: LOCAL.CATALOGS.COMPETENCIES_GROUPS,
    [ROUTE_NAMES.COMPETENCIES_INDICATORS]:
        LOCAL.CATALOGS.COMPETENCIES_INDICATORS,
    [ROUTE_NAMES.COMPETENCIES_ORDER_OPTIONS]:
        LOCAL.CATALOGS.COMPETENCIES_ORDER_OPTIONS,
    [ROUTE_NAMES.PRODUCT_CARD_VIEW]: LOCAL.CATALOGS.PRODUCT_CARD_VIEW,
    [ROUTE_NAMES.COWORKERS]: LOCAL.CATALOGS.COWORKERS,
    [ROUTE_NAMES.CRITERIA_SCALES]: LOCAL.CATALOGS.CRITERIA_SCALES,
    [ROUTE_NAMES.CRITERIA_SCALES_CREATE]: LOCAL.CATALOGS.CRITERIA_SCALES_CREATE,
    [ROUTE_NAMES.CRITERIA_SCALES_EDIT]: LOCAL.CATALOGS.CRITERIA_SCALES_EDIT,
    [ROUTE_NAMES.DOCUMENTS_EDITOR]: {
        menuTitle: LOCAL.CATALOGS.DOCUMENTS_EDITOR.menuTitle,
        pageTitle: LOCAL.CATALOGS.DOCUMENTS_EDITOR.pageTitle,
    },
    [ROUTE_NAMES.DEVELOPMENT_GOALS]: LOCAL.CATALOGS.DEVELOPMENT_GOALS,
    [ROUTE_NAMES.EVENTS]: LOCAL.CATALOGS.EVENTS,
    [ROUTE_NAMES.EVENTS_CREATE]: LOCAL.CATALOGS.EVENTS_CREATE,
    [ROUTE_NAMES.EVENTS_COPY]: LOCAL.CATALOGS.EVENTS_CREATE,
    [ROUTE_NAMES.EVENTS_EDIT]: LOCAL.CATALOGS.EVENTS_EDIT,
    [ROUTE_NAMES.EVENTS_VIEW]: LOCAL.CATALOGS.EVENTS_VIEW,
    [ROUTE_NAMES.EVENTS_ASSIGNMENTS]: {
        menuTitle: LOCAL.CATALOGS.EVENTS_ASSIGNMENTS.menuTitle,
        pageTitle: LOCAL.CATALOGS.EVENTS_ASSIGNMENTS.pageTitle,
    },
    [ROUTE_NAMES.EVENTS_ASSIGNMENTS_CREATE]:
        LOCAL.CATALOGS.EVENTS_ASSIGNMENTS_CREATE,
    [ROUTE_NAMES.EVENTS_ASSIGNMENTS_EDIT]:
        LOCAL.CATALOGS.EVENTS_ASSIGNMENTS_EDIT,
    [ROUTE_NAMES.EVENTS_ASSIGNMENTS_VIEW]:
        LOCAL.CATALOGS.EVENTS_ASSIGNMENTS_VIEW,
    [ROUTE_NAMES.EVENTS_PASSING]: LOCAL.CATALOGS.EVENTS_PASSING,
    [ROUTE_NAMES.KNOWLEDGE_BASE]: LOCAL.CATALOGS.KNOWLEDGE_BASE,
    [ROUTE_NAMES.KNOWLEDGE_BASE_CREATE]: LOCAL.CATALOGS.KNOWLEDGE_BASE_CREATE,
    [ROUTE_NAMES.KNOWLEDGE_BASE_EDIT]: LOCAL.CATALOGS.KNOWLEDGE_BASE_EDIT,
    [ROUTE_NAMES.KNOWLEDGE_BASE_VIEW]: LOCAL.CATALOGS.KNOWLEDGE_BASE_VIEW,
    [ROUTE_NAMES.ASSESSMENT_REPORTS]: LOCAL.CATALOGS.REPORTS,
    [ROUTE_NAMES.ASSESSMENT_REPORTS_SETTINGS]: LOCAL.CATALOGS.REPORTS_SETTINGS,
    [ROUTE_NAMES.TRACKS_UNIQUE]: LOCAL.CATALOGS.MGUU_TRACKS,
    [ROUTE_NAMES.NOTIFICATIONS_SETTINGS]: LOCAL.CATALOGS.NOTIFICATIONS_SETTINGS,
    [ROUTE_NAMES.ORGANIZATIONS]: LOCAL.CATALOGS.ORGANIZATIONS,
    [ROUTE_NAMES.POSITION_PROFILES]: LOCAL.CATALOGS.POSITION_PROFILES,
    [ROUTE_NAMES.POSITION_PROFILES_CREATE]:
        LOCAL.CATALOGS.POSITION_PROFILES_CREATE,
    [ROUTE_NAMES.POSITION_PROFILES_EDIT]: LOCAL.CATALOGS.POSITION_PROFILES_EDIT,
    [ROUTE_NAMES.POSITION_PROFILES_VIEW]: LOCAL.CATALOGS.POSITION_PROFILES_VIEW,
    [ROUTE_NAMES.POSITION_PROFILES_COPY]:
        LOCAL.CATALOGS.POSITION_PROFILES_CREATE,
    [ROUTE_NAMES.PRODUCT_CATALOG]: LOCAL.CATALOGS.PRODUCT_CATALOG,
    [ROUTE_NAMES.PRODUCT_CATALOG_CREATE]: LOCAL.CATALOGS.PRODUCT_CATALOG_CREATE,
    [ROUTE_NAMES.PRODUCT_CATALOG_COPY]: LOCAL.CATALOGS.PRODUCT_CATALOG_CREATE,
    [ROUTE_NAMES.PRODUCT_CATALOG_EDIT]: LOCAL.CATALOGS.PRODUCT_CATALOG_EDIT,
    [ROUTE_NAMES.PRODUCT_CATALOG_VIEW]: LOCAL.CATALOGS.PRODUCT_CATALOG_VIEW,
    [ROUTE_NAMES.QUESTIONNAIRES_LISTENER]:
        LOCAL.CATALOGS.QUESTIONNAIRES_LISTENER,
    [ROUTE_NAMES.QUESTIONNAIRE_PASSING]: LOCAL.CATALOGS.QUESTIONNAIRE_PASSING,
    [ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES]:
        LOCAL.CATALOGS.QUESTIONNAIRE_TEMPLATES,
    [ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_CREATE]:
        LOCAL.CATALOGS.QUESTIONNAIRE_TEMPLATES_CREATE,
    [ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_EDIT]:
        LOCAL.CATALOGS.QUESTIONNAIRE_TEMPLATES_EDIT,
    [ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW]:
        LOCAL.CATALOGS.QUESTIONNAIRE_TEMPLATES_VIEW,
    [ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_COPY]:
        LOCAL.CATALOGS.QUESTIONNAIRE_TEMPLATES_CREATE,
    [ROUTE_NAMES.QUIZ_ASSIGNMENTS]: {
        menuTitle: LOCAL.CATALOGS.QUIZ_ASSIGNMENTS.menuTitle,
        pageTitle: LOCAL.CATALOGS.QUIZ_ASSIGNMENTS.pageTitle,
    },
    [ROUTE_NAMES.QUIZ_ASSIGNMENTS_CREATE]:
        LOCAL.CATALOGS.QUIZ_ASSIGNMENTS_CREATE,
    [ROUTE_NAMES.QUIZ_ASSIGNMENTS_EDIT]: LOCAL.CATALOGS.QUIZ_ASSIGNMENTS_EDIT,
    [ROUTE_NAMES.QUIZ_ASSIGNMENTS_VIEW]: LOCAL.CATALOGS.QUIZ_ASSIGNMENTS_VIEW,
    [ROUTE_NAMES.QUIZ_PORTFOLIOS]: {
        menuTitle: LOCAL.CATALOGS.QUIZ_PORTFOLIOS.menuTitle,
        pageTitle: LOCAL.CATALOGS.QUIZ_PORTFOLIOS.pageTitle,
    },
    [ROUTE_NAMES.QUIZ_PORTFOLIOS_CREATE]: LOCAL.CATALOGS.QUIZ_PORTFOLIOS_CREATE,
    [ROUTE_NAMES.QUIZ_PORTFOLIOS_EDIT]: LOCAL.CATALOGS.QUIZ_PORTFOLIOS_EDIT,
    [ROUTE_NAMES.QUIZ_PORTFOLIOS_VIEW]: LOCAL.CATALOGS.QUIZ_PORTFOLIOS_VIEW,
    [ROUTE_NAMES.QUIZ_PORTFOLIOS_COPY]: LOCAL.CATALOGS.QUIZ_PORTFOLIOS_CREATE,
    [ROUTE_NAMES.QUIZ_REPORT]: LOCAL.CATALOGS.QUIZ_REPORT,
    [ROUTE_NAMES.REPORTS]: LOCAL.CATALOGS.REPORTS,
    [ROUTE_NAMES.STAFF_UNITS]: LOCAL.CATALOGS.STAFF_UNITS,
    [ROUTE_NAMES.SUPERVISING_COMPLEXES]: LOCAL.CATALOGS.SUPERVISING_COMPLEXES,
    [ROUTE_NAMES.TASKS]: LOCAL.CATALOGS.TASKS,
    [ROUTE_NAMES.TASKS_CREATE]: LOCAL.CATALOGS.TASKS_CREATE,
    [ROUTE_NAMES.TASKS_EDIT]: LOCAL.CATALOGS.TASKS_EDIT,
    [ROUTE_NAMES.TEST_REPORT]: LOCAL.CATALOGS.TEST_REPORT,
    [ROUTE_NAMES.TEST_ASSIGNMENTS]: {
        menuTitle: LOCAL.CATALOGS.TEST_ASSIGNMENTS.menuTitle,
        pageTitle: LOCAL.CATALOGS.TEST_ASSIGNMENTS.pageTitle,
    },
    [ROUTE_NAMES.TEST_ASSIGNMENTS_CREATE]:
        LOCAL.CATALOGS.TEST_ASSIGNMENTS_CREATE,
    [ROUTE_NAMES.TEST_ASSIGNMENTS_EDIT]: LOCAL.CATALOGS.TEST_ASSIGNMENTS_EDIT,
    [ROUTE_NAMES.TEST_ASSIGNMENTS_VIEW]: LOCAL.CATALOGS.TEST_ASSIGNMENTS_VIEW,
    [ROUTE_NAMES.TEST_ASSIGNMENTS_COPY]: LOCAL.CATALOGS.TEST_ASSIGNMENTS_CREATE,
    [ROUTE_NAMES.TEST_PORTFOLIOS]: {
        menuTitle: LOCAL.CATALOGS.TEST_PORTFOLIOS.menuTitle,
        pageTitle: LOCAL.CATALOGS.TEST_PORTFOLIOS.pageTitle,
    },
    [ROUTE_NAMES.TEST_PORTFOLIOS_CREATE]: LOCAL.CATALOGS.TEST_PORTFOLIOS_CREATE,
    [ROUTE_NAMES.TEST_PORTFOLIOS_EDIT]: LOCAL.CATALOGS.TEST_PORTFOLIOS_EDIT,
    [ROUTE_NAMES.TEST_PORTFOLIOS_VIEW]: LOCAL.CATALOGS.TEST_PORTFOLIOS_VIEW,
    [ROUTE_NAMES.TEST_PORTFOLIOS_COPY]: LOCAL.CATALOGS.TEST_PORTFOLIOS_CREATE,
    [ROUTE_NAMES.THEMES]: LOCAL.CATALOGS.THEMATIC_SECTIONS,
    [ROUTE_NAMES.THEMES_ORDER_OPTIONS]:
        LOCAL.CATALOGS.THEMATIC_SECTIONS_ORDER_OPTIONS,
    [ROUTE_NAMES.TRACKS_ADAPTATION]: LOCAL.CATALOGS.TRACKS_ADAPTATION,
    [ROUTE_NAMES.TRACKS_ADAPTATION_CREATE]:
        LOCAL.CATALOGS.TRACKS_ADAPTATION_CREATE,
    [ROUTE_NAMES.TRACKS_ADAPTATION_EDIT]: LOCAL.CATALOGS.TRACKS_ADAPTATION_EDIT,
    [ROUTE_NAMES.TRACKS_ADAPTATION_VIEW]: LOCAL.CATALOGS.TRACKS_ADAPTATION_VIEW,
    [ROUTE_NAMES.TRACKS_ASSIGNMENTS]: LOCAL.CATALOGS.TRACKS_ASSIGNMENTS,
    [ROUTE_NAMES.TRACKS_ASSIGNMENTS_COPY]:
        LOCAL.CATALOGS.TRACKS_ASSIGNMENTS_CREATE,
    [ROUTE_NAMES.TRACKS_ASSIGNMENTS_CREATE]:
        LOCAL.CATALOGS.TRACKS_ASSIGNMENTS_CREATE,
    [ROUTE_NAMES.TRACKS_ASSIGNMENTS_EDIT]:
        LOCAL.CATALOGS.TRACKS_ASSIGNMENTS_EDIT,
    [ROUTE_NAMES.TRACKS_ASSIGNMENTS_VIEW]:
        LOCAL.CATALOGS.TRACKS_ASSIGNMENTS_VIEW,
    [ROUTE_NAMES.TRACKS_DEVELOPMENT]: LOCAL.CATALOGS.TRACKS_DEVELOPMENT,
    [ROUTE_NAMES.TRACKS_DEVELOPMENT_CREATE]:
        LOCAL.CATALOGS.TRACKS_DEVELOPMENT_CREATE,
    [ROUTE_NAMES.TRACKS_DEVELOPMENT_EDIT]:
        LOCAL.CATALOGS.TRACKS_DEVELOPMENT_EDIT,
    [ROUTE_NAMES.TRACKS_DEVELOPMENT_VIEW]:
        LOCAL.CATALOGS.TRACKS_DEVELOPMENT_VIEW,
    [ROUTE_NAMES.TRAINING_MATERIALS]: LOCAL.CATALOGS.TRAINING_MATERIALS,
    [ROUTE_NAMES.TRAINING_MATERIALS_INFO]:
        LOCAL.CATALOGS.TRAINING_MATERIALS_INFO,
    [ROUTE_NAMES.USER_NOTIFICATIONS]: LOCAL.CATALOGS.USER_NOTIFICATIONS,
    [ROUTE_NAMES.USER_PROFILE]: LOCAL.CATALOGS.USER_PROFILE,
    [ROUTE_NAMES.APPROVE_CREATE]: LOCAL.CATALOGS.APPROVE_CREATE,
    [ROUTE_NAMES.APPROVE_VIEW]: LOCAL.CATALOGS.APPROVE_VIEW,
    [ROUTE_NAMES.CERTIFICATES]: LOCAL.CATALOGS.CERTIFICATES,
    [ROUTE_NAMES.EXTERNAL_CERTIFICATES]: LOCAL.CATALOGS.EXTERNAL_CERTIFICATES,
    [ROUTE_NAMES.EXTERNAL_CERTIFICATES_CREATE]:
        LOCAL.CATALOGS.EXTERNAL_CERTIFICATES_CREATE,
    [ROUTE_NAMES.EXTERNAL_CERTIFICATES_EDIT]:
        LOCAL.CATALOGS.EXTERNAL_CERTIFICATES_EDIT,
    [ROUTE_NAMES.EXTERNAL_CERTIFICATES_VIEW]:
        LOCAL.CATALOGS.EXTERNAL_CERTIFICATES_VIEW,
    [ROUTE_NAMES.WORK_IN_PROGRESS]: LOCAL.CATALOGS.WORK_IN_PROGRESS,
    [ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT]:
        LOCAL.CATALOGS.MAILING_ASSIGNMENT,
    [ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_CREATE]:
        LOCAL.CATALOGS.MAILING_ASSIGNMENT_CREATE,
    [ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_COPY]:
        LOCAL.CATALOGS.MAILING_ASSIGNMENT_CREATE,
    [ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_EDIT]:
        LOCAL.CATALOGS.MAILING_ASSIGNMENT_EDIT,
    [ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_VIEW]:
        LOCAL.CATALOGS.MAILING_ASSIGNMENT_VIEW,
    [ROUTE_NAMES.DISTRIBUTION_MAILING]: LOCAL.CATALOGS.MAILING,
    [ROUTE_NAMES.DISTRIBUTION_MAILING_VIEW]: LOCAL.CATALOGS.MAILING_VIEW,
    [ROUTE_NAMES.DISTRIBUTION_TAGS]: LOCAL.CATALOGS.TAGS,
    [ROUTE_NAMES.DISTRIBUTION_TEMPLATES]: LOCAL.CATALOGS.TEMPLATES,
    [ROUTE_NAMES.DISTRIBUTION_TEMPLATES_CREATE]:
        LOCAL.CATALOGS.TEMPLATES_CREATE,
    [ROUTE_NAMES.DISTRIBUTION_TEMPLATES_EDIT]: LOCAL.CATALOGS.TEMPLATES_EDIT,
    [ROUTE_NAMES.DISTRIBUTION_TEMPLATES_VIEW]: LOCAL.CATALOGS.TEMPLATES_VIEW,
    [ROUTE_NAMES.DISTRIBUTION_TEMPLATES_COPY]: LOCAL.CATALOGS.TEMPLATES_CREATE,

    // TODO: исходя из аналитики в будущем данных роутов не будет (временная необходимость),
    // вместо них будут подставлены существующие
    [ROUTE_NAMES.PA_MY_DEVELOPMENT]: LOCAL.CATALOGS.PA_MY_DEVELOPMENT,
    [ROUTE_NAMES.PA_FAVORITES]: LOCAL.CATALOGS.PA_FAVORITES,
    [ROUTE_NAMES.PA_HOMEWORK]: LOCAL.CATALOGS.PA_HOMEWORK,
    [ROUTE_NAMES.PA_QUIZZES]: LOCAL.CATALOGS.PA_QUIZZES,
    [ROUTE_NAMES.PA_ASSESSMENTS]: LOCAL.CATALOGS.PA_ASSESSMENTS,
    [ROUTE_NAMES.PA_PROFILE]: LOCAL.CATALOGS.PA_PROFILE,
    [ROUTE_NAMES.PA_CHECK_HOMEWORK]: LOCAL.CATALOGS.PA_CHECK_HOMEWORK,
    [ROUTE_NAMES.PA_CHECK_QUIZZES]: LOCAL.CATALOGS.PA_CHECK_QUIZZES,
    [ROUTE_NAMES.PA_MEETINGS_RESULTS]: LOCAL.CATALOGS.PA_MEETINGS_RESULTS,
    [ROUTE_NAMES.PA_MONITORING]: LOCAL.CATALOGS.PA_MONITORING,
    [ROUTE_NAMES.PA_ASSIGNED_ASSESSMENTS]:
        LOCAL.CATALOGS.PA_ASSIGNED_ASSESSMENTS,
    [ROUTE_NAMES.PA_REPORTS]: LOCAL.CATALOGS.REPORTS,
}
