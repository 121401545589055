import React from 'react'
import cn from 'classnames'
import { LOCAL } from 'core/local'

import styles from './HomeworkTaskNumberTitle.module.scss'
import { HomeworkTaskNumberTitleProps } from './HomeworkTaskNumberTitle.types'

export const HomeworkTaskNumberTitle: React.FC<HomeworkTaskNumberTitleProps> = React.memo(
    ({ isTasksTitlesVisible, taskNumber, className }) =>
        isTasksTitlesVisible ? (
            <div
                className={cn(styles.title, className)}
            >{`${LOCAL.LABELS.TASK} ${taskNumber}`}</div>
        ) : null
)
