import { FnActionProps, FnActionRequiredProps } from 'App.types'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

export interface UseElementVisibleResultProps {
    /** Флаг отображения элемента */
    elementVisible: boolean
    /** Обработчик переключения отображения элемента */
    toggleElementVisible: FnActionProps
    /** Обработчик скрытия элемента */
    handleHideElement: FnActionProps
    /** Обработчик показа элемента */
    handleShowElement: FnActionProps
    updateElementVisible: FnActionRequiredProps<boolean>
    /** Ссылка на компонент намерения пользователя*/
    toggleElementVisibleRef: RefObject<HTMLDivElement>
    /** Ссылка на отображаемый\скрываемый компонента */
    elementRef: RefObject<HTMLDivElement>
}

/**
 * Хук для переключения видимости элемента, с учетом клика за пределами
 * переключателя, а также самого элемента
 * @initialValue - начальное положение элемента
 */
export function useElementVisible(
    initialValue: boolean = false
): UseElementVisibleResultProps {
    const [elementVisible, setElementVisible] = useState(initialValue)
    const toggleElementVisibleRef = useRef<HTMLDivElement>(null)
    const elementRef = useRef<HTMLDivElement>(null)

    const toggleElementVisible = useCallback(() => {
        setElementVisible((prevState) => !prevState)
    }, [])

    const handleHideElement = useCallback(() => {
        setElementVisible(false)
    }, [])

    const handleShowElement = useCallback(() => {
        setElementVisible(true)
    }, [])

    const updateElementVisible = useCallback((visible: boolean) => {
        setElementVisible(visible)
    }, [])

    /**
     * Обработчик клика за пределами переключателя видимости элемента
     * @param event - объект события
     */
    const handleClickOutsideElementToggle = useCallback(
        (event: MouseEvent | TouchEvent) => {
            if (!toggleElementVisibleRef.current || !elementRef.current) return

            const isToggleContainsTarget = toggleElementVisibleRef.current.contains(
                event.target as Node
            )
            const isElementContainsTarget = elementRef.current.contains(
                event.target as Node
            )

            if (!isToggleContainsTarget && !isElementContainsTarget) {
                setElementVisible(false)
            }
        },
        []
    )

    /**
     * Подписка/отписка на события клика по документу
     */
    useEffect(() => {
        document.addEventListener('click', handleClickOutsideElementToggle)
        document.addEventListener('touchstart', handleClickOutsideElementToggle)

        return () => {
            document.removeEventListener(
                'click',
                handleClickOutsideElementToggle
            )
            document.removeEventListener(
                'touchstart',
                handleClickOutsideElementToggle
            )
        }
    }, [handleClickOutsideElementToggle])

    return {
        elementVisible,
        toggleElementVisible,
        handleHideElement,
        handleShowElement,
        updateElementVisible,
        toggleElementVisibleRef,
        elementRef,
    }
}
