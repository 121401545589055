import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
    ColumnsOptions,
    DictionarySyncDate,
    PageBanner,
    PageContent,
    PageTabs,
    ResetButton,
    TableAdapter,
    getRowClassNameArchive,
} from 'components/shared'
import { DICTIONARY_KEYS } from 'core/configs'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    RequestQueryProps,
    useBreakpoint,
    useExpandKeys,
    useFilter,
    usePageSettings,
} from 'hooks'
import { SettingsService, StructuralUnitPositionsService } from 'core/api'
import {
    createSuccessNotification,
    getPageSize,
    getTableColumnsWithClass,
    showElem,
} from 'utils'
import { pageTabsRender } from 'components/shared/PageTabs/PageTabs.utils'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import styles from './Organizations.module.scss'
import { NormalizeStructuralUnitPositionsResultProps } from './Organizations.types'
import { ORGANIZATIONS_TABLE_COLUMNS } from './Organizations.config'
import { OrganizationsFilters } from './components'
import { contentTitleRender } from './Organizations.render'
import {
    getExpandedRowKeys,
    normalizeStructuralUnitPositions,
} from './Organizations.utils'

/** Компонент отображения справочника организаций */
export const Organizations: FC = withLoader(
    React.memo(({ updateLoader }) => {
        const isWidthLessXXL = useBreakpoint('XXL')
        const { columnsVisible, saveFilters } = usePageSettings(
            DICTIONARY_KEYS.ORGANIZATIONS
        )

        const {
            expandedRowKeys,
            setExpandedRowKeys,
            handleExpandRow,
        } = useExpandKeys<NormalizeStructuralUnitPositionsResultProps>()

        const {
            paginationOptions,
            queryParams,
            handleSetFilters,
            handleResetFilters,
        } = useSearchQuery({
            dictionaryKey: DICTIONARY_KEYS.ORGANIZATIONS,
        })

        const [recordsCount, setRecordsCount] = useState<
            Record<
                | 'total'
                | 'withPositionProfileCount'
                | 'withoutPositionProfileCount',
                number
            >
        >()
        const [organizationsData, setOrganizationsData] = useState<
            NormalizeStructuralUnitPositionsResultProps[]
        >()

        const [integrationSyncDate, setIntegrationSyncDate] = useState<Date>()

        const [visibleFilter, triggerFilterVisibility] = useFilter()

        const dictionaryFetch = useCallback(async () => {
            try {
                updateLoader(true)

                const dataSource = await StructuralUnitPositionsService.search(
                    queryParams as Required<RequestQueryProps>
                )

                const normalizedDataSource = dataSource.pageItems.map(
                    (structuralUnit) =>
                        normalizeStructuralUnitPositions(structuralUnit)
                )

                setOrganizationsData(normalizedDataSource)

                setRecordsCount({
                    total: dataSource.total,
                    withPositionProfileCount:
                        dataSource.withPositionProfileCount,
                    withoutPositionProfileCount:
                        dataSource.withoutPositionProfileCount,
                })

                setIntegrationSyncDate(
                    (await SettingsService.getOibV2IntegrationSyncDate())
                        .oibV2OrganizationLastUpdate
                )

                if (!queryParams.body?.structuralUnitsIds) {
                    setExpandedRowKeys([])

                    return
                }

                const rowsToExpand = getExpandedRowKeys(
                    queryParams.body.structuralUnitsIds,
                    normalizedDataSource
                )

                if (rowsToExpand) {
                    setExpandedRowKeys(rowsToExpand.flat(2))
                }
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader, queryParams, setExpandedRowKeys])

        useEffect(() => {
            dictionaryFetch()
        }, [dictionaryFetch])

        /**
         * Изменение флага "Сущность изменена" у всех организационных структур
         */
        const updateChangeStatus = useCallback(
            (
                organizationsData?: NormalizeStructuralUnitPositionsResultProps[]
            ) => {
                if (!organizationsData) return

                const updated = organizationsData
                    .filter((item) => item.isChanged)
                    .map((item) => Object.assign(item, { isChanged: false }))

                return updated && [...organizationsData, ...updated]
            },
            []
        )

        /**
         * Обработчик сброса флага "Сущность изменена"
         */
        const handleReset = useCallback(async () => {
            updateChangeStatus(organizationsData)
            await StructuralUnitPositionsService.resetIsChanged()
            dictionaryFetch()
        }, [organizationsData, updateChangeStatus, dictionaryFetch])

        const contentTitle = useMemo(
            () =>
                contentTitleRender({
                    triggerFilterVisibility,
                    route: ROUTE_NAMES.ORGANIZATIONS,
                    justify: isWidthLessXXL ? undefined : 'end',
                    withPositionProfileCount:
                        recordsCount?.withPositionProfileCount,
                    withoutPositionProfileCount:
                        recordsCount?.withoutPositionProfileCount,
                    isActive: saveFilters,
                }),
            [isWidthLessXXL, recordsCount, saveFilters, triggerFilterVisibility]
        )

        useEffect(() => {
            if (saveFilters) {
                createSuccessNotification(LOCAL.MESSAGES.SAVE_FILTERS)
            }
        }, [saveFilters])

        return (
            <PageContent className={styles.wrapper} filled>
                <PageBanner>
                    <PageTabs tabsData={pageTabsRender(recordsCount?.total)} />

                    <DictionarySyncDate
                        integrationSyncDate={integrationSyncDate}
                    />

                    <ResetButton handleReset={handleReset} />
                </PageBanner>

                {contentTitle}

                <div className={showElem(visibleFilter)}>
                    <OrganizationsFilters
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                        dictionaryKey={DICTIONARY_KEYS.ORGANIZATIONS}
                    />
                </div>

                <ColumnsOptions<NormalizeStructuralUnitPositionsResultProps>
                    dictionaryKey={DICTIONARY_KEYS.ORGANIZATIONS}
                    tableColumns={ORGANIZATIONS_TABLE_COLUMNS}
                    keysOfAlwaysVisibleColumns={['username']}
                />

                <TableAdapter<NormalizeStructuralUnitPositionsResultProps>
                    rowKey="id"
                    columns={getTableColumnsWithClass<
                        NormalizeStructuralUnitPositionsResultProps
                    >(columnsVisible, ORGANIZATIONS_TABLE_COLUMNS)}
                    dataSource={organizationsData}
                    pagination={{
                        ...paginationOptions,
                        total: recordsCount?.total,
                        current: queryParams.pageNumber,
                        pageSize: getPageSize(
                            queryParams?.pageSize,
                            organizationsData?.length
                        ),
                    }}
                    rowClassName={getRowClassNameArchive}
                    expandable={{ expandedRowKeys }}
                    onExpand={handleExpandRow}
                />
            </PageContent>
        )
    })
)
