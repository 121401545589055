import {
    NotificationFilteringConditionContract,
    NotificationFilteringConditionValuesCreateOrUpdateContract,
    NotificationFiltrationConditionsValuesContract,
} from 'core/api'
import { getUniqueItemsArray } from 'utils'

import { NotificationFilteringConditionCustomContract } from './ConditionForm/ConditionForm.types'
import { NotificationFilteringParameterCustomContract } from '../ConditionParamsControl/ConditionParamsControl.types'
import {
    isDateTime,
    isEnumeration,
    isInteger,
} from '../ConditionParamsControl/ConditionParamsControl.utils'

export const mapConditionParameterToRequest = (
    parameter: NotificationFilteringParameterCustomContract,
    condition: NotificationFilteringConditionCustomContract
) =>
    ({
        conditionId: condition.id,
        parameterId: parameter.id,
        ...(isEnumeration(parameter.parameterType) && {
            parameterValueId: parameter.value,
        }),
        ...(isDateTime(parameter.parameterType) && {
            dateTimeValue: parameter.value,
        }),
        ...(isInteger(parameter.parameterType) && {
            integerValue: parameter.value,
        }),
    } as NotificationFilteringConditionValuesCreateOrUpdateContract)

export const mapConditionsToRequest = (
    conditions: NotificationFilteringConditionCustomContract[] = []
) =>
    conditions.reduce(
        (
            acc: NotificationFilteringConditionValuesCreateOrUpdateContract[],
            el
        ) => [
            ...acc,
            ...(el.parameters || []).map((parameter) =>
                mapConditionParameterToRequest(parameter, el)
            ),
        ],
        []
    )

export const mapConditionsToFormData = (
    parameters: NotificationFiltrationConditionsValuesContract[],
    conditions: NotificationFilteringConditionContract[] = []
) => {
    const conditionIds = getUniqueItemsArray(
        parameters?.map((parameter) => parameter.condition.id)
    )

    return conditionIds.map((conditionId) => ({
        id: conditionId,
        parameters: parameters
            .filter((parameter) => parameter.condition.id === conditionId)
            .map((parameter) => {
                const parameterDictionary = conditions
                    .find((el) => el.id === conditionId)
                    ?.parameters.find((p) => p.id === parameter.parameter.id)

                return {
                    ...parameterDictionary,
                    value:
                        parameter.parameterValue?.id ||
                        parameter.integerValue ||
                        parameter.dateTimeValue,
                }
            }),
    }))
}
