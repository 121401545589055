import React from 'react'

import styles from './NotEditableTextControl.module.scss'
import { NotEditableTextControlProps } from './NotEditableTextControl.types'

/**
 * Не редактируемое текстовое поле (контрол), используется в тех случаях, когда данные из формы необходимо отобразить в виде простого текста
 */
export const NotEditableTextControl: React.FC<NotEditableTextControlProps> = React.memo(
    ({ value, label, colon, normalizeValueFn }) => (
        <div>
            {label && (
                <p className={styles.label}>
                    {label}

                    {colon && ':'}
                </p>
            )}

            <span className={styles.body}>
                {normalizeValueFn?.(value) || value}
            </span>
        </div>
    )
)
