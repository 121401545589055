export const ROW_GUTTER = 20

export const COL_xl8_lg12_xs24 = {
    xs: 24,
    lg: 12,
    xl: 8,
}

export const COL_xl6_lg12_xs24 = {
    xs: 24,
    lg: 12,
    xl: 6,
}

export const COL_xl16_lg12_xs24 = {
    xs: 24,
    lg: 12,
    xl: 16,
}

export const COL_lg12_xs24 = {
    xs: 24,
    lg: 12,
}

export const MODAL_WIDTH = {
    XL: 1050,
    LG: 850,
    MD: 740,
    SM: 500,
}

export const MODAL_Z_INDEX = {
    DEFAULT: 8001,
    CONFIRM: 8002,
}

export const MAIN_HEADER_HEIGHT = 80
export const CONTENT_TITLE_HEIGHT = 110

export const ANIMATION_TIME_OUT_DEFAULT = 300

export const DROPDOWN_POPUPS_TARGET = 'dropdown-popups-target'

export const FONT_SIZES = {
    54: 54,
    56: 56,
    58: 58,
    22: 22,
    24: 24,
    26: 26,
}
