import { QuizPortfolioContract } from 'core/api'
import {
    generatePictureFromResponse,
    getValue,
    normalizeAttachmentObject,
    normalizeDataForSelectAndRadio,
    secondCountToTimeString,
    timeStringToSecondCount,
} from 'utils'

import { QuizPortfolioFormSubmitValues } from './QuizPortfolioForm.types'

export const mapResponseToFormData = (
    quizPortfolio: QuizPortfolioContract
) => ({
    id: quizPortfolio.id,
    name: quizPortfolio.name,
    description: quizPortfolio.description,
    organizationsIds: quizPortfolio.organizations?.map(
        normalizeDataForSelectAndRadio
    ),
    forAllOrganizations: quizPortfolio.forAllOrganizations,
    positionIds: quizPortfolio.positions?.map(normalizeDataForSelectAndRadio),
    forAllPositions: quizPortfolio.forAllPositions,
    quizType: quizPortfolio.type,
    questionnaireIds: quizPortfolio.questionnaires?.map((el) => el.id),
    timeIsLimited: quizPortfolio.timeIsLimited,
    timeLimit: secondCountToTimeString(quizPortfolio.timeLimit),
    depersonalizeRespondents: quizPortfolio.depersonalizeRespondents,
    attachmentIds: quizPortfolio.attachments?.map((attachment) =>
        normalizeAttachmentObject(attachment)
    ),
    presentation: {
        ...quizPortfolio.presentation,
        showQuestionsByBlock: Number(
            quizPortfolio.presentation?.showQuestionsByBlock
        ),
        showFinalWindow: Number(quizPortfolio.presentation?.showFinalWindow),
        finalWindow: {
            ...quizPortfolio.presentation?.finalWindow,
            pictureId: generatePictureFromResponse(
                quizPortfolio.presentation?.finalWindow?.picture
            ),
        },
        welcomeWindow: {
            ...quizPortfolio.presentation?.welcomeWindow,
            pictureId: generatePictureFromResponse(
                quizPortfolio.presentation?.welcomeWindow?.picture
            ),
        },
        passingPictureId: generatePictureFromResponse(
            quizPortfolio.presentation?.passingPicture
        ),
    },
})

export const mapFormDataToRequest = ({
    positionIds,
    organizationsIds,
    quizType,
    presentation,
    attachmentIds,
    timeLimit,
    ...restValues
}: Partial<QuizPortfolioFormSubmitValues>) => ({
    ...restValues,
    timeLimit: timeLimit ? timeStringToSecondCount(timeLimit) : undefined,
    type: quizType,
    organizationsIds: organizationsIds?.map(getValue),
    positionIds: positionIds?.map(getValue),
    presentation: {
        ...presentation,
        showQuestionsByBlock: !!Number(presentation?.showQuestionsByBlock),
        showFinalWindow: !!presentation?.showFinalWindow,
        finalWindow: {
            ...presentation?.finalWindow,
            pictureId: presentation?.finalWindow?.pictureId?.[0]?.response?.id,
        },
        welcomeWindow: {
            ...presentation?.welcomeWindow,
            pictureId:
                presentation?.welcomeWindow?.pictureId?.[0]?.response?.id,
        },
        passingPictureId: presentation?.passingPictureId?.[0]?.response?.id,
    },
    attachmentIds: attachmentIds?.map(({ response }) => response?.id),
})
