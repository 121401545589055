import React, { ReactNode } from 'react'
import { Button } from 'components/shared/Button'
import { FnActionProps } from 'App.types'
import { LOCAL } from 'core/local'
import { SpoilerTitleRenderProps } from 'components/shared/Spoiler/Spoiler.types'

import styles from './TrackContentMenu.module.scss'

/**
 * Рендер для заголовка спойлера
 * @param onStartStagePassing колбэк для старта этапа
 */
export const customSpoilerTitleRender = (
    onStartStagePassing: FnActionProps
) => ({
    text,
    icon,
    toggleSpoilerVisible,
}: SpoilerTitleRenderProps): ReactNode => (
    <>
        <Button type="link" onClick={onStartStagePassing}>
            {text}
        </Button>

        <Button icon={icon} onClick={toggleSpoilerVisible} type="link" />
    </>
)

export const getBlockTitle = (title: string, stageNumber: number) => (
    <>
        <span>{`${LOCAL.LABELS.BLOCK} ${stageNumber}.`}</span>

        <span className={styles.name}>{title}</span>
    </>
)
