import React from 'react'
import { Col, Row } from 'antd'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps, excludeSelectOptions } from 'utils'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { QUESTIONNAIRE_TYPES_MAPPED, ROW_GUTTER } from 'consts'
import { QuestionnaireSearchContract, QuestionnaireType } from 'core/api'
import { SelectControl } from 'components/controls/SelectControl'

/**
 * Форма фильтрации опросных листов для тематических блоков
 */
export const ThemeBlockQuestionnairesFilters: React.FC<FiltersProps<
    QuestionnaireSearchContract
>> = React.memo((props) => (
    <FilterForm {...props}>
        <Row gutter={ROW_GUTTER}>
            <Col xs={12}>
                <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                    <InputControl />
                </FormItemAdapter>
            </Col>

            <Col xs={12}>
                <FormItemAdapter
                    fieldName="types"
                    label={LOCAL.LABELS.DOCUMENT_TYPE}
                >
                    <SelectControl
                        values={excludeSelectOptions(
                            QUESTIONNAIRE_TYPES_MAPPED,
                            [QuestionnaireType.Assessment]
                        )}
                        mode="multiple"
                    />
                </FormItemAdapter>
            </Col>
        </Row>
    </FilterForm>
))
