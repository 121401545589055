import { StructuralUnitType } from 'core/api'

export interface JobProfilesUrlParamsProps {
    profileId?: string
    positionId?: string
    structuralUnitId?: string
    structuralUnitType?: StructuralUnitType
    version?: string
}

export enum JobProfileFromStateProps {
    UserProfile = 'USER_PROFILE',
}

export interface JobProfileHistoryStateProps {
    from?: JobProfileFromStateProps
}

export interface JobProfileQueryObjProps {
    complex?: number
    oiv?: number
    organization?: number
    unit?: number
    position?: number
    register?: 'organizations' | 'staffUnits'
}
