import cn from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { excludeSelectOptions } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './ShadowPickerControl.module.scss'
import { OPTIONS_SHADOW_ANGLE } from './ShadowPickerControl.consts'
import { ShadowPickerControlProp } from './ShadowPickerControl.types'
import { ShadowPickerRadio } from './components'

/**
 * Контрол для выбора типа тени
 */
export const ShadowPickerControl: React.FC<ShadowPickerControlProp> = React.memo(
    ({ label, value, onChange }) => {
        const [shadow, setShadow] = useState<string>()

        const {
            elementVisible,
            toggleElementVisible,
            toggleElementVisibleRef,
            elementRef,
        } = useElementVisible()

        const handleChangeShadow = useCallback(
            (e: RadioChangeEvent) => {
                const shadow = e.target.value

                setShadow(shadow)
                onChange?.(shadow)
            },
            [onChange]
        )

        const externalShadowOptions = useMemo(
            () =>
                excludeSelectOptions(OPTIONS_SHADOW_ANGLE, [
                    'none',
                ]).map((option) => (
                    <ShadowPickerRadio
                        key={option.value}
                        value={option.value as string}
                        className={styles[option.value]}
                    />
                )),
            []
        )

        const iconMode = value || shadow

        return (
            <div className={cn('form-control', styles.wrapper)}>
                <Button
                    className={styles.toggle}
                    type="link"
                    onClick={toggleElementVisible}
                    ref={toggleElementVisibleRef}
                >
                    <div
                        className={cn(
                            styles.icon,
                            iconMode && styles[iconMode]
                        )}
                    >
                        <i />

                        <IconsAdapter iconType="CaretDownOutlined" />
                    </div>

                    <div className={styles.text}>{label}</div>
                </Button>

                {elementVisible && (
                    <div className={styles.dropdown} ref={elementRef}>
                        <Radio.Group
                            onChange={handleChangeShadow}
                            value={iconMode}
                        >
                            <h4>{LOCAL.LABELS.WITHOUT_SHADOW}</h4>

                            <section>
                                <ShadowPickerRadio
                                    value={
                                        styles[OPTIONS_SHADOW_ANGLE[0].value]
                                    }
                                />
                            </section>

                            <h4>{LOCAL.LABELS.EXTERNAL_SHADOW}</h4>

                            <section>{externalShadowOptions}</section>
                        </Radio.Group>
                    </div>
                )}
            </div>
        )
    }
)
