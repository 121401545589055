import React, { useCallback, useEffect } from 'react'
import {
    ACTIVITIES_TABLE_COLUMNS,
    ActivitiesFilters,
    FormItemAdapter,
    InputControl,
    SearchSelectControl,
} from 'components/controls'
import {
    ActivitiesService,
    ActivityModalContract,
    ActivityModalSearchContract,
    AppointmentActivityContract,
    AppointmentActivityService,
    EntityEditSessionService,
    EntityType,
} from 'core/api'
import { AddonAfterDefault } from 'components/shared'
import {
    COL_xl8_lg12_xs24,
    EVENTS_REQUIRED_PERMISSIONS,
    ROW_GUTTER,
} from 'consts'
import { Col, Form, Row, notification } from 'antd'
import { FORM_IDS } from 'core/configs'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { ORDER_RULES_WITH_CREATION_DATE } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WithLoaderProps } from 'HOCs'
import {
    checkChangeEvaluationPeriodFrom,
    createConfirmPopup,
    getAssignmentFormPopupTitle,
    isAppointmentStatusEnded,
    isAppointmentTrackStatusDraft,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'

import {
    ACTIVITY_FIELD,
    EVENTS_ASSIGNMENT_FORM_INITIAL_VALUES,
    ORGANIZATIONS_FIELD,
} from './EventsAssignmentsForm.consts'
import {
    AssignmentParametersArea,
    ParticipantsArea,
    TutorsArea,
} from './components'
import { EventsAssignmentsFormValues } from './EventsAssignmentsForm.types'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
} from './EventsAssignmentsForm.utils'

/** Форма создания/редактирования назначения мероприятий */
export const EventsAssignmentsForm: React.FC<
    FormProps<AppointmentActivityContract> & Partial<WithLoaderProps>
> = React.memo(
    ({
        onBlockUserRouting,
        reFetchInitialFormValues,
        initialValuesForEdit,
        isSaveDocument,
        formMode,
        updateLoader,
    }) => {
        const history = useHistory()
        const [form] = Form.useForm()

        const disabledByStatus =
            isFormModeEdit(formMode) &&
            !isAppointmentTrackStatusDraft(initialValuesForEdit?.status)

        const disablePeriodFromByStatus =
            isAppointmentStatusEnded(initialValuesForEdit?.status) &&
            isFormModeEdit(formMode)

        /**
         * Обработчик отправки значений формы на бэк
         * @param values объект значений формы
         */
        const handleFinish = useCallback(
            async (values: Partial<EventsAssignmentsFormValues>) => {
                try {
                    updateLoader?.(true)

                    const body = mapFormDataToRequest(
                        values,
                        initialValuesForEdit?.id
                    )

                    if (isSaveDocument) {
                        await AppointmentActivityService.save({ body })
                    } else {
                        await AppointmentActivityService.publish({
                            body,
                        })
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: isSaveDocument
                            ? LOCAL.MESSAGES.ASSIGNMENT_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES.ASSIGNMENT_PUBLISH_SUCCESS_MESSAGE,
                        content: getAssignmentFormPopupTitle(
                            getRouteMeta(
                                ROUTE_NAMES.EVENTS_ASSIGNMENTS,
                                'pageTitle'
                            )
                        ),
                        onOk: () => {
                            history.push(ROUTE_NAMES.EVENTS_ASSIGNMENTS)
                        },
                        onCancel: () => {
                            if (!initialValuesForEdit?.id) form.resetFields()
                            else
                                reFetchInitialFormValues?.(
                                    initialValuesForEdit?.id
                                )
                        },
                        okText: LOCAL.YES,
                        cancelText: LOCAL.NO,
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                form,
                history,
                initialValuesForEdit,
                isSaveDocument,
                onBlockUserRouting,
                reFetchInitialFormValues,
                updateLoader,
            ]
        )

        const handleValuesChange = useCallback(
            async (changedValue: Partial<EventsAssignmentsFormValues>) => {
                if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }

                const changedValueKey = Object.keys(changedValue)[0]

                if (changedValueKey === 'startDate') {
                    form.setFieldsValue({
                        homeworkAutoCheckDate: null,
                    })

                    checkChangeEvaluationPeriodFrom({
                        evaluationPeriodFrom: changedValue.startDate,
                        evaluationPeriodTo: changedValue.endDate,
                        form,
                        changeValueKey: 'endDate',
                    })
                }

                if (
                    changedValueKey === ACTIVITY_FIELD &&
                    changedValue[changedValueKey]
                ) {
                    const dataSource = await EntityEditSessionService.checkAvailability(
                        {
                            id: changedValue[changedValueKey]?.[0]
                                .value as number,
                            entity: EntityType.Activity,
                        }
                    )

                    if (!dataSource.available) {
                        notification.error({
                            message: LOCAL.MESSAGES.EVENT_ON_EDIT,
                        })

                        form.setFieldsValue({
                            [changedValueKey]: undefined,
                        })
                    }
                }
            },
            [form, formMode, onBlockUserRouting]
        )

        /**
         * Установка начальный значений формы
         */
        useEffect(() => {
            const fieldsValues = initialValuesForEdit?.id
                ? mapResponseToFormData(initialValuesForEdit)
                : EVENTS_ASSIGNMENT_FORM_INITIAL_VALUES
            form.setFieldsValue(fieldsValues)
        }, [form, initialValuesForEdit])

        return (
            <Form
                id={FORM_IDS.EVENTS_ASSIGNMENTS_FORM}
                form={form}
                onValuesChange={handleValuesChange}
                onFinish={handleFinish}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col {...COL_xl8_lg12_xs24}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                            required
                        >
                            <InputControl formMode={formMode} />
                        </FormItemAdapter>
                    </Col>

                    <Col {...COL_xl8_lg12_xs24}>
                        <Form.Item
                            noStyle
                            dependencies={[ACTIVITY_FIELD, ORGANIZATIONS_FIELD]}
                        >
                            {(form) => {
                                const activityId = form?.getFieldValue(
                                    ACTIVITY_FIELD
                                )?.[0]?.value

                                return (
                                    <FormItemAdapter
                                        fieldName={ACTIVITY_FIELD}
                                        label={LOCAL.LABELS.EVENT_M}
                                        required
                                    >
                                        <SearchSelectControl<
                                            ActivityModalSearchContract,
                                            ActivityModalContract
                                        >
                                            getByIdsMethod={
                                                ActivitiesService.getByIds
                                            }
                                            modalTitle={
                                                LOCAL.LABELS.EVENT_M_SELECTION
                                            }
                                            addonAfter={
                                                <AddonAfterDefault
                                                    url={`${ROUTE_NAMES.EVENTS_VIEW}/${activityId}`}
                                                    disabled={!activityId}
                                                    permissionsRequired={
                                                        EVENTS_REQUIRED_PERMISSIONS
                                                    }
                                                />
                                            }
                                            formMode={formMode}
                                            tableSearchOptions={{
                                                searchMethod:
                                                    ActivitiesService.getForModal,
                                                filterComponent: ActivitiesFilters,
                                                tableColumns: ACTIVITIES_TABLE_COLUMNS,
                                                tableFiltersOptions: {
                                                    initialValues: {
                                                        structuralUnitIds: form
                                                            .getFieldValue(
                                                                ORGANIZATIONS_FIELD
                                                            )
                                                            ?.map(
                                                                (
                                                                    el: LabeledValue
                                                                ) => el.value
                                                            ),
                                                        orderRules: ORDER_RULES_WITH_CREATION_DATE,
                                                    },
                                                },
                                            }}
                                            disabled={disabledByStatus}
                                        />
                                    </FormItemAdapter>
                                )
                            }}
                        </Form.Item>
                    </Col>
                </Row>

                <TutorsArea formMode={formMode} disabled={disabledByStatus} />

                <ParticipantsArea
                    formMode={formMode}
                    disabled={disabledByStatus}
                />

                <Form.Item noStyle dependencies={[ACTIVITY_FIELD]}>
                    {(form) => (
                        <AssignmentParametersArea
                            currentActivityId={
                                form.getFieldValue([ACTIVITY_FIELD])?.[0]?.value
                            }
                            formMode={formMode}
                            disabled={disabledByStatus}
                            disablePeriodFromByStatus={
                                disablePeriodFromByStatus
                            }
                            initialValuesForEdit={initialValuesForEdit}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    }
)
