import { FnActionAsyncProps } from 'App.types'
import { useEffect } from 'react'

import { useHttp } from './useHttp'

interface TabsRenderProps<Response, Request> {
    tabsTotalCountMethod: FnActionAsyncProps<Response, Request>
}

/** Хук используется для получения count всех табов на странице */
export const useTabsTotalCount = <Response, Request>({
    tabsTotalCountMethod,
}: TabsRenderProps<Response, Request>) => {
    const [getTabsTotalCount, tabsTotalCount] = useHttp<Request>(
        tabsTotalCountMethod
    )

    useEffect(() => {
        getTabsTotalCount()
    }, [getTabsTotalCount])

    return { tabsTotalCount }
}
