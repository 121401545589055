import React, { FC, useContext } from 'react'
import { BORDER_NUMBER_OF_QUESTIONNAIRES } from 'components/pages/TestPortfolios/components/TestPortfolioForm/TestPortfolioForm.consts'
import {
    CheckboxControl,
    RadioControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import { CollapseSingle } from 'components/shared/CollapseSingle'
import { LOCAL } from 'core/local'
import { PortfolioFormCommonTabsContext } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import {
    excludeSelectOptions,
    isFormModeCopy,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeQuiz,
    isQuestionnaireTypeTest,
    normalizeDictionaryToControl,
} from 'utils'

import styles from './EvaluationPassing.module.scss'
import {
    ASSESSMENT_QUESTIONS_DISPLAY_TYPES,
    LAYOUT_OPTIONS,
    SHOW_FINAL_RADIO_VALUE,
    TEST_QUESTIONS_DISPLAY_TYPES,
} from './EvaluationPassing.consts'
import { PRESENTATION_FORM_FIELD_NAME } from '../../PortfolioPresentation.consts'
import { PresentationUploadImageControl } from '../PresentationUploadImageControl'
import { QuestionDisplayTypes } from '../../PortfolioPresentation.types'

/** Вкладка оформления создания портфеля оценки - блок прохождение оценки*/
export const EvaluationPassing: FC = React.memo(() => {
    const { store } = useContext(PortfolioFormCommonTabsContext)

    const {
        formMode,
        portfolioFormType,
        initialValuesForEdit,
        disabledByStatus,
        form,
    } = store

    const initialPictureName = {
        name: isFormModeCopy(formMode)
            ? form?.getFieldValue('presentation')?.passingPicture?.fileName
            : initialValuesForEdit?.presentation?.passingPicture?.fileName,
    }

    return (
        <CollapseSingle title={LOCAL.LABELS.PASSING}>
            <Row>
                <Col xs={8}>
                    <h3>{LOCAL.LABELS.DISPLAY_QUESTIONS}</h3>

                    {isQuestionnaireTypeQuiz(portfolioFormType) && (
                        <Form.Item
                            name={[
                                PRESENTATION_FORM_FIELD_NAME,
                                'showFinalWindow',
                            ]}
                        >
                            <RadioControl
                                formMode={formMode}
                                values={normalizeDictionaryToControl(
                                    SHOW_FINAL_RADIO_VALUE,
                                    true
                                )}
                                disabled={disabledByStatus}
                            />
                        </Form.Item>
                    )}

                    {isQuestionnaireTypeAssessment(portfolioFormType) && (
                        <Form.Item
                            name={[
                                PRESENTATION_FORM_FIELD_NAME,
                                'showQuestionsByBlock',
                            ]}
                        >
                            <RadioControl
                                formMode={formMode}
                                values={normalizeDictionaryToControl(
                                    ASSESSMENT_QUESTIONS_DISPLAY_TYPES,
                                    true
                                )}
                                direction="vertical"
                                disabled={disabledByStatus}
                            />
                        </Form.Item>
                    )}

                    {isQuestionnaireTypeTest(portfolioFormType) && (
                        <ShouldUpdateChecker fieldPath={['questionnaires']}>
                            {({ getFieldValue }) => {
                                const questionnairesLength =
                                    getFieldValue('questionnaires')?.length || 0

                                return (
                                    <Form.Item
                                        name={[
                                            PRESENTATION_FORM_FIELD_NAME,
                                            'showQuestionsByBlock',
                                        ]}
                                        initialValue={
                                            QuestionDisplayTypes.ByOne
                                        }
                                    >
                                        <RadioControl
                                            formMode={formMode}
                                            values={excludeSelectOptions(
                                                normalizeDictionaryToControl(
                                                    TEST_QUESTIONS_DISPLAY_TYPES,
                                                    true
                                                ),
                                                questionnairesLength >
                                                    BORDER_NUMBER_OF_QUESTIONNAIRES
                                                    ? undefined
                                                    : [
                                                          QuestionDisplayTypes.ByBlock,
                                                      ]
                                            )}
                                            direction="vertical"
                                            disabled={disabledByStatus}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </ShouldUpdateChecker>
                    )}
                </Col>

                <Col {...LAYOUT_OPTIONS}>
                    <PresentationUploadImageControl
                        name={[
                            PRESENTATION_FORM_FIELD_NAME,
                            'passingPictureId',
                        ]}
                        initialPictureName={initialPictureName}
                    />
                </Col>

                <Col {...LAYOUT_OPTIONS}>
                    <h3>{LOCAL.LABELS.ADDITIONAL_SETTINGS}</h3>

                    {isQuestionnaireTypeAssessment(portfolioFormType) && (
                        <Form.Item
                            name={[
                                PRESENTATION_FORM_FIELD_NAME,
                                'showCandidateRole',
                            ]}
                            noStyle
                        >
                            <CheckboxControl
                                formMode={formMode}
                                className={styles.checkboxControl}
                                disabled={disabledByStatus}
                            >
                                {LOCAL.LABELS.SHOW_EVALUATOR_ROLE.toLowerCase()}
                            </CheckboxControl>
                        </Form.Item>
                    )}

                    <Form.Item
                        name={[
                            PRESENTATION_FORM_FIELD_NAME,
                            'showPassingProgress',
                        ]}
                        noStyle
                    >
                        <CheckboxControl
                            formMode={formMode}
                            className={styles.checkboxControl}
                            disabled={disabledByStatus}
                        >
                            {LOCAL.LABELS.SHOW_PASSING_PROGRESS.toLowerCase()}
                        </CheckboxControl>
                    </Form.Item>
                </Col>
            </Row>
        </CollapseSingle>
    )
})
