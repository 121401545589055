import React, { useCallback } from 'react'
import { DynamicListControlItem } from 'components/controls/DynamicListControl/components/DynamicListControlItem'
import { Form } from 'antd'
import { MODAL_WIDTH } from 'consts'
import { Popup } from 'components/shared'
import { TableSearchForm } from 'components/controls'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import { getIdsArray } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './Appointments.module.scss'
import { AppointmentsProps } from './Appointments.types'
import { DynamicListCardControl } from '../DynamicListCardControl'
import { DynamicListItemRenderProps } from '../DynamicListCardControl/DynamicListCardControl.types'
import { getViewOptions } from './Appointments.utils'

function AppointmentsPlain<
    Request extends object,
    Response extends object & { id: number }
>({
    formMode,
    initialValues,
    tableSearchOptions,
    form,
    listFieldName,
    controlTitle,
    modalTitle,
    disabled,
    getByIdsMethod,
}: AppointmentsProps<Request, Response>) {
    const {
        elementVisible,
        handleHideElement,
        handleShowElement,
    } = useElementVisible()

    const handleClear = useCallback(() => {
        form?.setFieldsValue({
            [listFieldName]: [],
        })
    }, [form, listFieldName])

    const dictionaryFetch = useCallback(
        async (request?: number[]) => {
            try {
                form?.setFieldsValue({
                    [listFieldName]: await getByIdsMethod({
                        ...initialValues,
                        categoryId: initialValues.category.value,
                        body: request,
                    }),
                })
            } catch (error) {
                console.error(error)
            }
        },
        [form, listFieldName, getByIdsMethod, initialValues]
    )

    const handleSelectAddOptions = useCallback(
        (result?: number[]) => {
            handleHideElement()
            dictionaryFetch(result)
        },
        [dictionaryFetch, handleHideElement]
    )

    const renderListItem = useCallback(
        ({ field, onRemove }: DynamicListItemRenderProps) => (
            <Form.Item {...field} key={field.key} name={field.name} noStyle>
                <DynamicListControlItem
                    onRemove={onRemove}
                    formMode={formMode}
                    viewOptions={getViewOptions(
                        initialValues.category.key,
                        initialValues.subCategory?.key
                    )}
                    className={styles.addon}
                />
            </Form.Item>
        ),
        [formMode, initialValues]
    )

    return (
        <>
            <DynamicListCardControl
                listFieldName={listFieldName}
                title={controlTitle}
                formMode={formMode}
                onAdd={handleShowElement}
                onClear={handleClear}
                disabled={disabled}
                renderListItem={renderListItem}
            />

            <Popup
                formId={listFieldName}
                visible={elementVisible}
                onCancel={handleHideElement}
                destroyOnClose
                title={modalTitle}
                width={MODAL_WIDTH.LG}
                centered
                getContainer={getDropdownPopupContainerDefault}
            >
                <TableSearchForm<Request, Response>
                    id={listFieldName}
                    initialValues={getIdsArray(initialValues?.[listFieldName])}
                    onRequestFinish={handleSelectAddOptions}
                    {...tableSearchOptions}
                />
            </Popup>
        </>
    )
}

export const Appointments = React.memo(
    AppointmentsPlain
) as typeof AppointmentsPlain
