import { CKEditorEventAction, stripPrefix } from 'ckeditor4-react'
import { TITLE_MAIN_FONT_SIZES, TITLE_SECONDARY_FONT_SIZES } from 'consts'

import {
    EditorReducerAction,
    EditorReducerState,
    FontProps,
    TagSettings,
} from './CKEditorAdapter.types'

/** Получить настройки для тэгов редактора */
export const getTagsSettings = (tagsSettings: TagSettings[]) =>
    Object.fromEntries(
        tagsSettings.map((tag) => [`format_${tag.tagName}`, tag])
    )

/** Получить стили для заголовков текстового редактора от большего к меньшему */
export const formatTittleSettings = () =>
    Object.values({
        ...TITLE_SECONDARY_FONT_SIZES,
        ...TITLE_MAIN_FONT_SIZES,
    })
        .sort((a, b) => b - a)
        .map((size, index) => ({
            tagName: `h${index + 1}`,
            element: `h${index + 1}`,
            styles: { 'font-size': `${size}px` },
        }))

export function editorReducer(
    state: EditorReducerState,
    action: EditorReducerAction
) {
    switch (action.type) {
        case CKEditorEventAction.instanceReady:
            return {
                editor: action.payload.editor,
            }
        case CKEditorEventAction.beforeLoad:
            action.payload.addCss(state.styles)

            return state

        case stripPrefix(CKEditorEventAction.insertHtml):
            state.editor.insertHtml(action.payload.html)

            return state

        default:
            return state
    }
}

/**
 * Возвращает строку стилей @font-face для инъекции в ckeditor4
 * @param font объект данных шрифта
 */
export const getFontFace = (font: FontProps) => `
    @font-face {
        font-family: '${font.name}';
        src: url('/static-api/fonts/${font.normal}.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
      
    @font-face {
        font-family: '${font.name}';
        src: url('/static-api/fonts/${font.bold}.woff') format('woff');
        font-weight: bold;
        font-style: normal;
    }
    
    @font-face {
        font-family: '${font.name}';
        src: url('/static-api/fonts/${font.italic}.woff') format('woff');
        font-weight: normal;
        font-style: italic;
    }
    
    @font-face {
        font-family: '${font.name}';
        src: url('/static-api/fonts/${font.boldItalic}.woff') format('woff');
        font-weight: bold;
        font-style: italic;
    }
`
