import React, { useMemo } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { isFormModeView } from 'utils'

import styles from './StagesGroup.module.scss'
import { GroupFieldsProps } from './StagesGroup.types'
import { TrackStage } from '../TrackStage'

/**
 * Компонент отображения группы\горизонтального списка этапов трека
 */
export const StagesGroup: React.FC<GroupFieldsProps> = React.memo(
    ({ groupStages, removeStage, groupKey, isDisabled, formMode }) => {
        const groupFieldsMapped = useMemo(
            () =>
                groupStages.map((stage) => (
                    <Draggable
                        key={stage.name}
                        draggableId={String(stage.name)}
                        isDragDisabled={isDisabled || isFormModeView(formMode)}
                        index={stage.name}
                    >
                        {(dragProvided) => (
                            <TrackStage
                                stage={stage}
                                key={stage.name}
                                removeStage={removeStage}
                                provided={dragProvided}
                                formMode={formMode}
                                disabled={isDisabled}
                            />
                        )}
                    </Draggable>
                )),
            [groupStages, removeStage, isDisabled, formMode]
        )

        return (
            <Droppable
                droppableId={groupKey}
                type="CARD"
                direction="horizontal"
                isCombineEnabled
            >
                {(dropProvided) => (
                    <div
                        className={styles.group}
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}
                    >
                        {groupFieldsMapped}

                        {dropProvided.placeholder}
                    </div>
                )}
            </Droppable>
        )
    }
)
