import React from 'react'
import { AppointmentTrackSearchContract } from 'core/api'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps, excludeSelectOptions } from 'utils'
import { LOCAL } from 'core/local'
import {
    ROW_GUTTER,
    TRACKS_APPOINTMENT_STATUSES_MAPPED,
    TRACK_TYPES_MAPPED,
} from 'consts'
import { TRACKS_PROGRESS_STATUSES_FOR_EXCLUDE } from 'components/pages/TrackAssignments/TrackAssignments.consts'
import { renderFooterWithArchiveAndAutoAppointment } from 'components/shared/FilterForm/FilterForm.render'

/**
 * Форма фильтрации назначений треков
 */
export const TrackAssignmentsFilters: React.FC<FiltersProps<
    AppointmentTrackSearchContract
>> = React.memo((props) => (
    <ControlGroup type="light">
        <FilterForm
            {...props}
            renderFooter={renderFooterWithArchiveAndAutoAppointment}
        >
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.APPOINTMENT_TITLE}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="trackType"
                        label={LOCAL.LABELS.TRACK_TYPE}
                    >
                        <SelectControl values={TRACK_TYPES_MAPPED} showSearch />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="statuses"
                        label={LOCAL.LABELS.STATUS}
                    >
                        <SelectControl
                            values={excludeSelectOptions(
                                TRACKS_APPOINTMENT_STATUSES_MAPPED,
                                TRACKS_PROGRESS_STATUSES_FOR_EXCLUDE
                            )}
                            mode="multiple"
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FromToDatesFilter
                        from={{
                            label: LOCAL.LABELS.DATE_START,
                            fieldName: 'periodFrom',
                        }}
                        to={{
                            label: LOCAL.LABELS.DATE_END,
                            fieldName: 'periodTo',
                        }}
                    />
                </Col>
            </Row>
        </FilterForm>
    </ControlGroup>
))
