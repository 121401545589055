import { LOCAL } from 'core/local'
import { RecordEntityType } from 'core/api'

export const MONITORING_ENTITIES_DICTIONAIRY = {
    [RecordEntityType.AdaptationTrack]: LOCAL.CATALOGS.TRACKS_ADAPTATION,
    [RecordEntityType.AssessmentPortfolio]:
        LOCAL.CATALOGS.ASSESSMENT_PORTFOLIOS.pageTitle,
    [RecordEntityType.TestPortfolio]: LOCAL.CATALOGS.TEST_PORTFOLIOS.pageTitle,
    [RecordEntityType.DevelopmentTrack]: LOCAL.CATALOGS.TRACKS_DEVELOPMENT,
    [RecordEntityType.AssessmentAppointment]:
        LOCAL.CATALOGS.ASSESSMENT_ASSIGNMENTS.pageTitle,
    [RecordEntityType.TestAppointment]:
        LOCAL.CATALOGS.TEST_ASSIGNMENTS.pageTitle,
    [RecordEntityType.TrackAppointment]: LOCAL.CATALOGS.TRACKS_ASSIGNMENTS,
    [RecordEntityType.QuizAppointment]:
        LOCAL.CATALOGS.QUIZ_ASSIGNMENTS.pageTitle,
    [RecordEntityType.ActivityAppointment]:
        LOCAL.CATALOGS.EVENTS_ASSIGNMENTS.pageTitle,
    [RecordEntityType.Certificate]: LOCAL.CATALOGS.CERTIFICATES,
    [RecordEntityType.Certificate]: LOCAL.CATALOGS.CERTIFICATES,
    [RecordEntityType.ProductCards]: LOCAL.CATALOGS.PRODUCT_CATALOG,
    [RecordEntityType.Activity]: LOCAL.CATALOGS.EVENTS,
    [RecordEntityType.PositionProfile]: LOCAL.CATALOGS.POSITION_PROFILES,
    [RecordEntityType.QuizAppointment]:
        LOCAL.CATALOGS.QUIZ_ASSIGNMENTS.pageTitle,
    [RecordEntityType.UserDevelopment]: LOCAL.LABELS.YOUR_PROGRESS,
    [RecordEntityType.Article]: LOCAL.CATALOGS.KNOWLEDGE_BASE,
    [RecordEntityType.UserQuizAndTest]: LOCAL.LABELS.APPOINTED_TESTS_AND_QUIZES,
    [RecordEntityType.UserAssessment]: LOCAL.LABELS.APPOINTED_ASSESSMENT,
    [RecordEntityType.NotificationMailingTask]:
        LOCAL.CATALOGS.MAILING_ASSIGNMENT,
    [RecordEntityType.ProductCards]: LOCAL.CATALOGS.PRODUCT_CATALOG,
}
