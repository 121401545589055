import React from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
} from 'components/controls'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { ThemesSearchContract } from 'core/api'

/**
 * Форма фильтрации тематик
 */
export const ThemesFilters: React.FC<FiltersProps<
    ThemesSearchContract
>> = React.memo((props) => (
    <ControlGroup type="light">
        <FilterForm {...props}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="changeUserName"
                        label={LOCAL.LABELS.AUTHOR_FIO}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FromToDatesFilter
                        from={{
                            label: LOCAL.LABELS.DATE_CHANGE_FROM,
                            fieldName: 'changeDateStart',
                        }}
                        to={{
                            label: LOCAL.LABELS.DATE_CHANGE_TO,
                            fieldName: 'changeDateEnd',
                        }}
                    />
                </Col>
            </Row>
        </FilterForm>
    </ControlGroup>
))
