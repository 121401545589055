import React, { useEffect } from 'react'
import { ArticleContract, GlossariesService, UserPermission } from 'core/api'
import {
    Button,
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { KNOWLEDGE_BASE_REQUIRED_PERMISSIONS } from 'consts'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsForEditProps, useFormInitialValues } from 'hooks'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import { useParams } from 'react-router-dom'

import styles from './KnowledgeBaseFormContainer.module.scss'
import { KnowledgeBaseForm } from './components'

/** Создание/редактирование статьи Базы знаний */
const Component: React.FC<
    WithBlockUserRoutingComponentProps & ComponentCommonProps
> = withLoader(({ onBlockUserRouting, formMode, updateLoader, isLoading }) => {
    const { id } = useParams<UrlParamsForEditProps>()

    const {
        initialValuesForEdit,
        fetchInitialFormValuesById,
    } = useFormInitialValues<ArticleContract>({
        requestMethod: GlossariesService.getArticle,
        updateLoader,
    })

    useEffect(() => {
        if (id) fetchInitialFormValuesById(Number(id))
    }, [id, fetchInitialFormValuesById])

    return (
        <PageContent className={styles.wrapper} grid>
            <ContentTitleWithBackLink>
                <ButtonsToolbar>
                    <Button href={ROUTE_NAMES.KNOWLEDGE_BASE} type="link">
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    <HasPermissions
                        requiredPermissions={[
                            ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                            UserPermission.KnowledgeBaseArticleSave,
                        ]}
                    >
                        <Button
                            htmlType="submit"
                            type="primary"
                            form={FORM_IDS.KNOWLEDGE_BASE_FORM}
                        >
                            {LOCAL.ACTIONS.SAVE}
                        </Button>
                    </HasPermissions>
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <KnowledgeBaseForm
                onBlockUserRouting={onBlockUserRouting}
                initialValuesForEdit={initialValuesForEdit}
                formMode={formMode}
                updateLoader={updateLoader}
            />
        </PageContent>
    )
})

export const KnowledgeBaseFormContainer = React.memo(
    withBlockUserRouting(Component, false)
) as typeof Component
