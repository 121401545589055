import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { Link, useParams } from 'react-router-dom'
import {
    NotificationMailingTaskContract,
    NotificationMailingTaskType,
    NotificationMailingTasksService,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    UrlParamsForEditProps,
    useFormInitialValues,
    useRrweb,
    useSaveOrPublishState,
} from 'hooks'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import { WithFormModeProps } from 'App.types'
import {
    isFormModeCopy,
    isFormModeCreate,
    isFormModeEdit,
    isFormModeView,
    isMailingStatusDraft,
    isMailingStatusExpectation,
    isNotificationMailingTaskTypeAtSpecifiedTime,
} from 'utils'

import styles from './DistributionMailingAssignment.module.scss'
import { DistributionMailingAssignmentForm } from './components'

/** Страница для создания, редактирования и просмотра задания на рассылку */
export const DistributionMailingAssignmentFormContainer: React.FC<
    WithBlockUserRoutingComponentProps & WithFormModeProps
> = withLoader(
    React.memo(({ formMode, updateLoader, onBlockUserRouting }) => {
        const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()
        const [form] = Form.useForm()

        const urlParams = useParams<UrlParamsForEditProps>()

        const {
            initialValuesForEdit,
            fetchInitialFormValuesById,
        } = useFormInitialValues<NotificationMailingTaskContract>({
            requestMethod: NotificationMailingTasksService.getActive,
            updateLoader,
        })
        const [eventType, setEventType] = useState(
            NotificationMailingTaskType.ByEvent
        )

        const { pauseMonitoring } = useRrweb()

        useEffect(() => {
            pauseMonitoring()
        }, [pauseMonitoring])

        useEffect(() => {
            if (urlParams.id) fetchInitialFormValuesById(Number(urlParams.id))
        }, [fetchInitialFormValuesById, urlParams.id, formMode])

        useEffect(() => {
            setEventType(
                initialValuesForEdit?.type ||
                    NotificationMailingTaskType.ByEvent
            )
        }, [initialValuesForEdit])

        const handleEventTypeChange = useCallback(() => {
            setEventType(form.getFieldValue('type'))
        }, [setEventType, form])

        const isSaved =
            isFormModeCreate(formMode) ||
            isFormModeCopy(formMode) ||
            (isFormModeEdit(formMode) &&
                (isMailingStatusDraft(initialValuesForEdit?.businessStatus) ||
                    isMailingStatusExpectation(
                        initialValuesForEdit?.businessStatus
                    )))

        return (
            <PageContent filled>
                <Form.Provider onFormChange={handleEventTypeChange}>
                    <ContentTitleWithBackLink
                        status={
                            initialValuesForEdit?.businessStatus ||
                            initialValuesForEdit?.status
                        }
                        formMode={formMode}
                    >
                        <ButtonsToolbar>
                            <Link
                                to={ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT}
                                className={styles.link}
                            >
                                {LOCAL.ACTIONS.CANCEL}
                            </Link>

                            {isSaved && (
                                <Button
                                    form={FORM_IDS.DISTRIBUTION_MAILING_FORM}
                                    type="primary"
                                    htmlType="submit"
                                    onClick={handleSubmitForm(true)}
                                >
                                    {LOCAL.ACTIONS.SAVE}
                                </Button>
                            )}

                            <Button
                                form={FORM_IDS.DISTRIBUTION_MAILING_FORM}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmitForm(false)}
                                disabled={isFormModeView(formMode)}
                            >
                                {!isNotificationMailingTaskTypeAtSpecifiedTime(
                                    eventType
                                )
                                    ? LOCAL.ACTIONS.SCHEDULE
                                    : LOCAL.ACTIONS.SEND}
                            </Button>
                        </ButtonsToolbar>
                    </ContentTitleWithBackLink>

                    <DistributionMailingAssignmentForm
                        form={form}
                        formMode={formMode}
                        updateLoader={updateLoader}
                        initialValuesForEdit={initialValuesForEdit}
                        isSaveDocument={isSaveDocument}
                        onBlockUserRouting={onBlockUserRouting}
                        eventType={eventType}
                    />
                </Form.Provider>
            </PageContent>
        )
    })
)
export default withBlockUserRouting(
    DistributionMailingAssignmentFormContainer,
    false
)
