import React from 'react'
import cn from 'classnames'
import lowerFirst from 'lodash/lowerFirst'
import {
    ActivityPassingStatus,
    FaceToFaceSchedule,
    LessonScheduleContract,
    LessonType,
    WebConferenceSchedule,
} from 'core/api'
import { Col } from 'antd'
import { LOCAL } from 'core/local'
import { NotEditableTextControl } from 'components/controls/NotEditableTextControl'

import styles from './BlockPassingModal.module.scss'

export const getScheduleData = (
    schedule: LessonScheduleContract,
    type: LessonType
) =>
    schedule[lowerFirst(type) as keyof LessonScheduleContract] as
        | WebConferenceSchedule
        | FaceToFaceSchedule

/** Получить поля специфичные только для вебинара или для очной встречи */
export const getAdditionalScheduleFields = (
    data: WebConferenceSchedule | FaceToFaceSchedule
) => {
    if ('link' in data) {
        return (
            data.link && (
                <Col xs={24}>
                    <>
                        <NotEditableTextControl label={LOCAL.LABELS.LINK} />

                        <a
                            href={data.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {data.link}
                        </a>
                    </>
                </Col>
            )
        )
    }

    return (
        <>
            {data.place && (
                <Col xs={24} className="mb-20">
                    <NotEditableTextControl
                        label={LOCAL.LABELS.ADDRESS}
                        value={data.place}
                        colon
                    />
                </Col>
            )}

            {data.additionalInformation && (
                <Col xs={24}>
                    <NotEditableTextControl
                        label={LOCAL.LABELS.ADDITIONAL_INFO}
                        value={data.additionalInformation}
                        colon
                    />
                </Col>
            )}
        </>
    )
}

export const getFaceToFaceMeetingStatus = (status: ActivityPassingStatus) => {
    switch (status) {
        case ActivityPassingStatus.CompletedSuccessfully:
            return (
                <p className={cn(styles.status, styles.statusPassed)}>
                    {LOCAL.MESSAGES.YOU_HAVE_COMPLETED_MEETING}
                </p>
            )

        case ActivityPassingStatus.CompletedUnsuccessfully:
            return (
                <p className={cn(styles.status, styles.statusFailed)}>
                    {LOCAL.MESSAGES.YOU_HAVE_MISSED_MEETING}
                </p>
            )

        default:
            return null
    }
}
