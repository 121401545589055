import React, { useEffect } from 'react'
import { ArticleContract, GlossariesService } from 'core/api'
import { Col, Row } from 'antd'
import { MAIN_HEADER_HEIGHT } from 'consts'
import { PageContent } from 'components/shared'
import { UrlParamsCommonProps } from 'App.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'
import { withHeightResize, withLoader } from 'HOCs'

import styles from './Article.module.scss'
import { ArticleContents } from './ArticleContents'
import { ArticleView } from './ArticleView'

/** Статья из базы знаний */
export const Article: React.FC = withHeightResize(
    withLoader(
        React.memo(({ updateLoader, componentHeight }) => {
            const { id } = useParams<UrlParamsCommonProps<'id'>>()
            const [getArticle, article] = useHttp<ArticleContract>(
                GlossariesService.getArticle,
                updateLoader
            )

            useEffect(() => {
                if (id) getArticle({ id })
            }, [id, getArticle])

            return (
                <PageContent className={styles.wrapper}>
                    <Row>
                        <Col xs={6}>
                            <ArticleContents article={article} />
                        </Col>

                        <Col
                            xs={18}
                            className={styles.view}
                            style={
                                componentHeight
                                    ? {
                                          minHeight:
                                              componentHeight -
                                              MAIN_HEADER_HEIGHT,
                                      }
                                    : undefined
                            }
                        >
                            <ArticleView article={article} />
                        </Col>
                    </Row>
                </PageContent>
            )
        })
    )
)
