import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
    ColumnsOptions,
    PageBanner,
    PageContent,
    PageTabs,
    TableAdapter,
    getRowClassNameArchive,
} from 'components/shared'
import { DICTIONARY_KEYS } from 'core/configs'
import { LOCAL } from 'core/local'
import { PAGE_SIZE_ALL } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    RequestQueryProps,
    useBreakpoint,
    useExpandKeys,
    useFilter,
    usePageSettings,
} from 'hooks'
import { StaffUnitsService } from 'core/api'
import {
    createSuccessNotification,
    getTableColumnsWithClass,
    showElem,
} from 'utils'
import { pageTabsRender } from 'components/shared/PageTabs/PageTabs.utils'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import styles from './StaffUnits.module.scss'
import { NormalizeStructuralUnitPositionsResultProps } from '../Organizations/Organizations.types'
import { STAFF_UNITS_TABLE_COLUMNS } from './StaffUnits.config'
import { StaffUnitsFilters } from './components'
import { contentTitleRender } from '../Organizations/Organizations.render'
import { getExpandedRowKeys } from '../Organizations/Organizations.utils'
import { normalizeStaffUnits } from './StaffUnits.utils'

/** Компонент отображения штатных единиц */
export const StaffUnits: FC = withLoader(
    React.memo(({ updateLoader }) => {
        const isWidthLessXXL = useBreakpoint('XXL')
        const { columnsVisible, saveFilters } = usePageSettings(
            DICTIONARY_KEYS.STAFF_UNITS
        )
        const [visibleFilter, triggerFilterVisibility] = useFilter()
        const {
            expandedRowKeys,
            setExpandedRowKeys,
            handleExpandRow,
        } = useExpandKeys<NormalizeStructuralUnitPositionsResultProps>()

        const [recordsCount, setRecordsCount] = useState<
            Record<
                | 'total'
                | 'withPositionProfileCount'
                | 'withoutPositionProfileCount',
                number
            >
        >()
        const [staffUnits, setStaffUnits] = useState<
            NormalizeStructuralUnitPositionsResultProps[]
        >()

        const {
            queryParams,
            handleSetFilters,
            handleResetFilters,
        } = useSearchQuery({
            dictionaryKey: DICTIONARY_KEYS.STAFF_UNITS,
            queryOptions: {
                pageSize: PAGE_SIZE_ALL,
                body: {},
            },
        })

        const dictionaryFetch = useCallback(async () => {
            try {
                updateLoader(true)

                const dataSource = await StaffUnitsService.search(
                    queryParams as Required<RequestQueryProps>
                )

                const normalizedDataSource = dataSource.pageItems.map(
                    normalizeStaffUnits
                )

                setStaffUnits(normalizedDataSource)
                setRecordsCount({
                    total: dataSource.total,
                    withPositionProfileCount:
                        dataSource.withPositionProfileCount,
                    withoutPositionProfileCount:
                        dataSource.withoutPositionProfileCount,
                })

                if (!queryParams.body?.organizationsIds) {
                    setExpandedRowKeys([])

                    return
                }

                const rowsToExpand = getExpandedRowKeys(
                    queryParams.body.organizationsIds,
                    normalizedDataSource
                )

                if (rowsToExpand) {
                    setExpandedRowKeys(rowsToExpand.flat(2))
                }
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader, queryParams, setExpandedRowKeys])

        useEffect(() => {
            dictionaryFetch()
        }, [dictionaryFetch])

        const contentTitle = useMemo(
            () =>
                contentTitleRender({
                    triggerFilterVisibility,
                    route: ROUTE_NAMES.STAFF_UNITS,
                    justify: isWidthLessXXL ? undefined : 'end',
                    withPositionProfileCount:
                        recordsCount?.withPositionProfileCount,
                    withoutPositionProfileCount:
                        recordsCount?.withoutPositionProfileCount,
                    isActive: saveFilters,
                }),
            [isWidthLessXXL, recordsCount, saveFilters, triggerFilterVisibility]
        )

        useEffect(() => {
            if (saveFilters) {
                createSuccessNotification(LOCAL.MESSAGES.SAVE_FILTERS)
            }
        }, [saveFilters])

        return (
            <PageContent className={styles.wrapper} filled>
                <PageBanner>
                    <PageTabs tabsData={pageTabsRender(recordsCount?.total)} />
                </PageBanner>

                {contentTitle}

                <div className={showElem(visibleFilter)}>
                    <StaffUnitsFilters
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                        dictionaryKey={DICTIONARY_KEYS.STAFF_UNITS}
                    />
                </div>

                <ColumnsOptions<NormalizeStructuralUnitPositionsResultProps>
                    dictionaryKey={DICTIONARY_KEYS.STAFF_UNITS}
                    tableColumns={STAFF_UNITS_TABLE_COLUMNS}
                />

                <TableAdapter<NormalizeStructuralUnitPositionsResultProps>
                    columns={getTableColumnsWithClass<
                        NormalizeStructuralUnitPositionsResultProps
                    >(columnsVisible, STAFF_UNITS_TABLE_COLUMNS)}
                    dataSource={staffUnits}
                    pagination={false}
                    rowClassName={getRowClassNameArchive}
                    expandable={{ expandedRowKeys }}
                    onExpand={handleExpandRow}
                />
            </PageContent>
        )
    })
)
