import React from 'react'
import { AddButton } from 'components/shared/AddButton'
import { ComponentCommonProps } from 'App.types'
import { Form } from 'antd'
import { LOCAL } from 'core/local'

import styles from './ProductTeachers.module.scss'
import { ProductTeacher } from '../ProductTeacher'

/** Блок полей формы создания карточки продукта - преподаватели */
export const ProductTeachers: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <div className={styles.wrapper}>
            <h3>{LOCAL.LABELS.TEACHERS}</h3>

            <Form.List name="teachers">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <ProductTeacher
                                key={field.key}
                                field={field}
                                remove={remove}
                                formMode={formMode}
                            />
                        ))}

                        <AddButton
                            buttonText={LOCAL.ACTIONS.ADD_TEACHER}
                            onClick={() => add()}
                            formMode={formMode}
                        />
                    </>
                )}
            </Form.List>
        </div>
    )
)
