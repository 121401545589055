import React from 'react'
import cn from 'classnames'
import { Input } from 'antd'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'

import styles from './InputControl.module.scss'
import { ControlViewMode } from '../ControlViewMode'
import { InputControlProps } from './InputControl.types'

/**
 * Декоратор для `Input` от `antd`, принимает все теже `props`
 */
export const InputControl: React.FC<InputControlProps> = React.memo(
    ({ disabled, formMode, form, ...props }) => {
        const classes = cn(styles.wrapper, 'form-control')

        return (
            <div className={classes}>
                {isFormModeView(formMode) ? (
                    <ControlViewMode
                        value={props.value}
                        addonAfter={props.addonAfter}
                        addonBefore={props.addonBefore}
                    />
                ) : (
                    <Input
                        placeholder={LOCAL.PLACEHOLDERS.INPUT}
                        disabled={disabled}
                        {...props}
                    />
                )}
            </div>
        )
    }
)
