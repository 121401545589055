import React from 'react'
import { ControlGroup } from 'components/controls'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { TableAdapter } from 'components/shared'

import styles from './CriteriaScaleView.module.scss'
import { CRITERIA_SCALE_TABLE_COLUMNS } from './CriteriaScaleView.consts'

/** Окно просмотра шкалы */
export const CriteriaScaleView: React.FC<PopupAdapterFormProps> = React.memo(
    ({ initialValues }) => (
        <ControlGroup
            title={initialValues?.name}
            noPadding
            className={styles.wrapper}
        >
            <TableAdapter
                columns={CRITERIA_SCALE_TABLE_COLUMNS}
                dataSource={initialValues?.scaleValues}
                pagination={false}
            />

            {initialValues?.description && (
                <div className={styles.description}>
                    {`${LOCAL.LABELS.DESCRIPTION}`}

                    <p className={styles.descriptionText}>
                        {initialValues?.description}
                    </p>
                </div>
            )}
        </ControlGroup>
    )
)
