import React, { useCallback, useEffect, useState } from 'react'
import {
    AssessmentReportSearchContract,
    PositionsService,
    StructuralUnitsService,
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    CoworkersFilters,
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    MultiSelectControl,
    SearchSelectControl,
    SelectControl,
    TreeSelectControl,
    getCoworkersTableColumns,
    getUserByIdsMethod,
} from 'components/controls'
import { DEPRECATED_ASSESSMENT_TYPES, ROW_GUTTER } from 'consts'
import { FilterForm } from 'components/shared/FilterForm'
import {
    FiltersProps,
    excludeSelectOptions,
    normalizeDataForTreeSelect,
    normalizeDictionaryToControl,
} from 'utils'
import { LOCAL } from 'core/local'
import { useDictionariesHelper } from 'hooks'

import {
    AssessmentReportsFilterContract,
    DictionariesProps,
} from './AssessmentReportsFilters.types'

export const AssessmentReportsFilters: React.FC<FiltersProps<
    AssessmentReportSearchContract
>> = React.memo((props) => {
    const { assessmentTypeDictionary } = useDictionariesHelper([
        'assessmentTypeDictionary',
    ])
    const [dictionaries, setDictionaries] = useState<DictionariesProps>()

    const mapFormDataToRequest = useCallback(
        ({
            candidates,
            ...restValues
        }: Partial<AssessmentReportsFilterContract>) => ({
            ...restValues,
            candidateIds: candidates?.map((candidate) => +candidate.value),
        }),
        []
    )

    const dictionaryFetch = useCallback(async () => {
        try {
            const positionsData = await PositionsService.getAll()
            const structuralUnitsData = await StructuralUnitsService.getAllStructuralUnits()

            setDictionaries({
                positions: positionsData.map(normalizeDataForTreeSelect),
                structuralUnits: structuralUnitsData.map(
                    normalizeDataForTreeSelect
                ),
            })
        } catch (err) {
            console.error(err)
        }
    }, [])

    useEffect(() => {
        dictionaryFetch()
    }, [dictionaryFetch])

    return (
        <ControlGroup type="light">
            <FilterForm {...props} mapFormDataToRequest={mapFormDataToRequest}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitIds"
                            label={LOCAL.LABELS.ORGANIZATION}
                        >
                            <TreeSelectControl
                                treeData={dictionaries?.structuralUnits || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="candidates"
                            label={LOCAL.LABELS.EMPLOYEE}
                        >
                            <SearchSelectControl<
                                UserModalSearchContract,
                                UserModalContract
                            >
                                getByIdsMethod={getUserByIdsMethod}
                                selectMode="multiple"
                                modalTitle={
                                    LOCAL.LABELS.ASSESSMENT_OBJECT_SELECTION
                                }
                                tableSearchOptions={{
                                    searchMethod: UsersService.getForModal,
                                    tableColumns: getCoworkersTableColumns(),
                                    filterComponent: CoworkersFilters,
                                }}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="type"
                            label={LOCAL.LABELS.TYPE}
                        >
                            <SelectControl
                                values={excludeSelectOptions(
                                    normalizeDictionaryToControl(
                                        assessmentTypeDictionary
                                    ),
                                    DEPRECATED_ASSESSMENT_TYPES
                                )}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="positionIds"
                            label={LOCAL.LABELS.POSITION}
                        >
                            <MultiSelectControl
                                treeData={dictionaries?.positions}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="appointmentName"
                            label={LOCAL.LABELS.ASSESSMENT_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="endDate"
                            label={LOCAL.LABELS.DATE_FINISH}
                        >
                            <DatePickerControl />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
