import cn from 'classnames'
import React, { ReactText, useCallback, useState } from 'react'
import { Radio } from 'antd'
import { isFormModeView } from 'utils'

import styles from './RadioButtonControl.module.scss'
import { RadioControlCommonProps } from './RadioControl.types'

/**
 * Декоратор для `RadioGroup` от `antd`
 */
export const RadioButtonControl: React.FC<RadioControlCommonProps> = React.memo(
    (props) => {
        const {
            values = [],
            disabled,
            value,
            onChange,
            onBeforeChange,
            formMode,
            className,
            buttonStyles,
            ...restProps
        } = props

        const [valueState, setValueState] = useState<ReactText>()

        /**
         * Обновить значение в стейте и внешней форме
         */
        const triggerChanges = useCallback(
            (value: ReactText) => {
                setValueState(value)
                if (onChange) onChange(value)
            },
            [onChange]
        )

        /**
         * Обработчик изменения значения RadioGroup
         */
        const handleRadioGroupChange = useCallback(
            (event) => {
                const startTrigger = () => {
                    if (event?.target?.value !== undefined) {
                        triggerChanges(event.target.value)
                    }
                }

                if (onBeforeChange) {
                    onBeforeChange({
                        onChange: startTrigger,
                    })
                } else {
                    startTrigger()
                }
            },
            [onBeforeChange, triggerChanges]
        )

        const classes = cn(
            isFormModeView(formMode) && 'view-mode',
            'form-control',
            className,
            styles.wrapper
        )

        return (
            <div className={classes}>
                <Radio.Group
                    onChange={handleRadioGroupChange}
                    value={value || valueState}
                    disabled={disabled || isFormModeView(formMode)}
                    {...restProps}
                >
                    {values.map((el) => (
                        <Radio.Button
                            key={String(el.key || el.label)}
                            value={el.value}
                            style={buttonStyles}
                        >
                            {el.label}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </div>
        )
    }
)
