import { ActivityLessonPassingContract } from 'core/api'
import { CSSProperties } from 'react'
import { TextAlignProperty } from 'App.types'

import { isThematicBlockStatusCompleted } from './conditions.utils'

interface GetStylesProps {
    textColor?: string
    fontName?: string
    fontSize?: string | number
    lineHeight?: string | number
    fontStyle?: string
    textAlign?: string
}

/**
 * Преобразуем объект стилей в стили компонента
 * @param options
 */
export const getStyles = (
    options?: GetStylesProps
): Partial<CSSProperties | undefined> => {
    if (!options) return

    return {
        color: options?.textColor,
        fontFamily: options?.fontName,
        fontSize: Number(options?.fontSize) || undefined,
        lineHeight: options?.lineHeight,
        fontWeight:
            options?.fontStyle === 'bold' ? options?.fontStyle : 'normal',
        fontStyle:
            options?.fontStyle === 'italic' ? options?.fontStyle : 'normal',
        textDecoration:
            options?.fontStyle === 'underline' ? options?.fontStyle : 'none',
        textAlign: options?.textAlign as TextAlignProperty,
    }
}

export const checkAllRequiredBlocksPassed = (
    activityLessonPassings?: ActivityLessonPassingContract[]
) =>
    activityLessonPassings?.every((el) =>
        el.isRequired ? isThematicBlockStatusCompleted(el.status) : true
    )

export const allBlocksAreRequired = (
    activityLessonPassings?: ActivityLessonPassingContract[]
) => activityLessonPassings?.every((el) => el.isRequired)
