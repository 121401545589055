import React from 'react'
import cn from 'classnames'
import { Checkbox } from 'antd'

import styles from './CheckboxControl.module.scss'
import { CheckboxGroupControlProps } from './CheckboxControlProps.types'

/**
 * Декоратор для `Checkbox.Group` от `antd`
 */
export const CheckboxGroupControl: React.FC<CheckboxGroupControlProps> = React.memo(
    ({ labelList, ...restProps }) => (
        <div className={cn(styles.wrapper, labelList && styles.labelList)}>
            <Checkbox.Group options={labelList} {...restProps} />
        </div>
    )
)
