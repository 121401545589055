import React from 'react'
import { PageContent } from 'components/shared'
import { UserPersonalAreaRouting } from 'routing/components/UserPersonalAreaRouting'

import styles from './UserPersonalArea.module.scss'
import { UserPersonalAreaNavigation } from './components'

/**
 * Личный кабинет пользователя
 */
export const UserPersonalArea: React.FC = React.memo(() => (
    <PageContent className={styles.wrapper}>
        <div className={styles.body}>
            <UserPersonalAreaNavigation />

            <main className={styles.routing}>
                <UserPersonalAreaRouting />
            </main>
        </div>
    </PageContent>
))
