import React, { FC } from 'react'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import {
    QuestionnaireSearchContract,
    QuestionnaireSearchResultContract,
    QuestionnairesService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRouteMeta } from 'routing/routeNames.utils'
import { getRowClassNameArchive } from 'components/shared'
import { getRowHandlers } from 'utils'
import { useDictionariesHelper } from 'hooks'

import { QuestionnairesFilters } from './components'
import { getColumns } from './Questionnaires.config'

/**
 * Реестр шаблонов опросных листов
 */
export const QuestionnaireTemplates: FC = React.memo(() => {
    const locales = useDictionariesHelper(['assessmentTypeDictionary'])

    return (
        <DictionaryPage<
            QuestionnaireSearchContract,
            QuestionnaireSearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.QUESTIONNAIRE_TEMPLATES}
            searchMethod={QuestionnairesService.search}
            deleteMethod={QuestionnairesService.delete}
            contentTitle={getRouteMeta(ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES)}
            tableColumns={getColumns(locales)}
            rowClassName={getRowClassNameArchive}
            createRecordLink={{
                path: ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_CREATE,
            }}
            filterComponent={QuestionnairesFilters}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES_WITH_CHANGE_DATE,
                },
            }}
            quickSearchMethod={QuestionnairesService.searchText}
            createPermissions={[
                UserPermission.QuestionnairesRead,
                UserPermission.QuestionnaireSave,
            ]}
            onRow={getRowHandlers({
                url: ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES_VIEW,
            })}
        />
    )
})
