import { FONT_NAMES } from 'consts'

import { FontProps, TagSettings } from './CKEditorAdapter.types'
import { formatTittleSettings, getFontFace } from './CKEditorAdapter.utils'

export const TEXT_FORMAT_TAGS = 'p;h1;h2;h3;h4;h5;h6'

export const DEFAULT_FONT_SIZE = 18

export const EDITOR_HEIGHT = '65vh'

const FONTS_LIST: FontProps[] = [
    {
        name: 'Arial',
        normal: 'Arial/Arial-Regular',
        italic: 'Arial/Arial-Italic',
        bold: 'Arial/Arial-Bold',
        boldItalic: 'Arial/Arial-BoldItalic',
    },
    {
        name: 'Calibri',
        normal: 'Calibri/Calibri-Regular',
        italic: 'Calibri/Calibri-Italic',
        bold: 'Calibri/Calibri-Bold',
        boldItalic: 'Calibri/Calibri-BoldItalic',
    },
    {
        name: 'Helvetica',
        normal: 'Helvetica/Helvetica-Regular',
        italic: 'Helvetica/Helvetica-Italic',
        bold: 'Helvetica/Helvetica-Bold',
        boldItalic: 'Helvetica/Helvetica-BoldItalic',
    },
    {
        name: 'Liberation Sans',
        normal: 'LiberationSans/liberationsans',
        italic: 'LiberationSans/liberationsansitalic',
        bold: 'LiberationSans/liberationsansbold',
        boldItalic: 'LiberationSans/liberationsansbolditalic',
    },
    {
        name: 'Liberation Serif',
        normal: 'LiberationSerif/LiberationSerif-Regular',
        italic: 'LiberationSerif/LiberationSerif-Italic',
        bold: 'LiberationSerif/LiberationSerif-Bold',
        boldItalic: 'LiberationSerif/LiberationSerif-BoldItalic',
    },
    {
        name: 'Roboto',
        normal: 'Roboto/Roboto-Regular',
        italic: 'Roboto/Roboto-Italic',
        bold: 'Roboto/Roboto-Bold',
        boldItalic: 'Roboto/Roboto-BoldItalic',
    },
    {
        name: 'Times New Roman',
        normal: 'TimesNewRoman/TimesNewRomanPSMT',
        italic: 'TimesNewRoman/TimesNewRomanPS-ItalicMT',
        bold: 'TimesNewRoman/TimesNewRomanPS-BoldMT',
        boldItalic: 'TimesNewRoman/TimesNewRomanPS-BoldItalicMT',
    },
]

export const DEFAULT_STYLES = `
    ${FONTS_LIST.map(getFontFace).join('')}

    .cke_editable {
        font-size: ${DEFAULT_FONT_SIZE}px;
        font-family: Roboto;
    }
        
    [contenteditable=false] {
        cursor: not-allowed
    }

    .ckeditor__title {
        font-size: 48px;
        line-height: 64px;
        font-weight: 500;
        margin: 10px 0;
    }

    .ckeditor__subtitle {
        font-size: 21px;
        line-height: 28px;
        margin: 10px 0;
    }

    .ckeditor__text {
        font-style: cursive;
        margin: 20px 0;
    }
    `

export const FONT_NAMES_STRING = Object.keys(FONT_NAMES).join(';')

export const TOOLBAR_OPTIONS = [
    { name: 'styles', items: ['Format', 'Font'] },
    {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat', 'Undo'],
    },
    {
        name: 'paragraph',
        items: ['NumberedList', 'BulletedList', 'Blockquote'],
    },
    { name: 'links', items: ['Link', 'Unlink'] },
    {
        name: 'insert',
        items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'],
    },
    { name: 'tools', items: ['Maximize', 'Source'] },
]

export const TAGS_SETTINGS: TagSettings[] = [
    {
        tagName: 'p',
        element: 'p',
        styles: { 'font-size': `${DEFAULT_FONT_SIZE}px` },
    },
    ...formatTittleSettings(),
]

export const EXTRA_PLUGINS_LIST = ['font']

export const EXTRA_PLUGINS_LIST_EXTENDED = [
    'font',
    'colorbutton',
    'copyformatting',
]

export const TOOLBAR_OPTIONS_EXTENDED = [
    {
        name: 'basicstyles',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'Strike',
            '-',
            'CopyFormatting',
            'RemoveFormat',
        ],
    },
    {
        name: 'paragraph',
        items: ['NumberedList', 'BulletedList', '-', 'Blockquote'],
    },
    {
        name: 'insert',
        items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'],
    },
    '/',
    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
    { name: 'colors', items: ['TextColor', 'BGColor'] },
    { name: 'tools', items: ['Maximize'] },
]
