import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ScaleContract, UserPermission } from 'core/api'
import { defaultSorter } from 'components/layouts'
import {
    formatDate,
    renderDocumentStatus,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { CriteriaScalesTableActions } from './components'

/** настройка отображения колонок для справочника шкал критериев */
export const SCALES_TABLE_COLUMNS: ColumnsType<ScaleContract> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderDocumentStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <CriteriaScalesTableActions tableRow={tableRow} />
        ),
        align: 'right',
        fixed: 'right',
    },
]

export const CANCEL_BTN_OPTIONS = {
    visible: true,
    href: ROUTE_NAMES.CRITERIA_SCALES,
}

export const SAVE_BTN_OPTIONS = {
    visible: true,
}

export const PUBLISH_BTN_OPTIONS = {
    visible: true,
    requiredPermissions: [UserPermission.ScalePublish],
}
