import React from 'react'
import cn from 'classnames'
import { ComponentCommonProps } from 'App.types'

import styles from './PageBanner.module.scss'

/** Компонент баннера для страниц реестров */
export const PageBanner: React.FC<ComponentCommonProps> = React.memo(
    ({ children, className }) => (
        <section className={cn(styles.wrapper, className)}>{children}</section>
    )
)
