/** Проверки соответствия нажимаемых клавиш  */

const ENTER_KEYCODE = 13
const BACKSPACE_KEYCODE = 8
const ESCAPE_KEYCODE = 27
const ALT_KEYCODE = 18
const CTRL_KEYCODE = 17

export const isKeyEnter = (keycode: number) => keycode === ENTER_KEYCODE
export const isKeyBackspace = (keycode: number) => keycode === BACKSPACE_KEYCODE
export const isKeyEscape = (keycode: number) => keycode === ESCAPE_KEYCODE
export const isKeyAlt = (keycode: number) => keycode === ALT_KEYCODE
export const isKeyCtrl = (keycode: number) => keycode === CTRL_KEYCODE
