import React from 'react'

import styles from './FullScreenBlocks.module.scss'
import { ActivityChildProps } from '../../ActivityLayer.types'
import { FullScreenSingleBlock } from '../FullScreenSingleBlock'
import { getBlocksStyle } from '../../ActivityLayer.utils'

/**
 * Отображение для тематических блоков мероприятия типа - "fullscreen"
 */
export const FullScreenBlocks: React.FC<ActivityChildProps> = React.memo(
    ({ activity }) => (
        <div className={styles.wrapper}>
            {activity?.activityLessonPassings?.map((lesson, index) => (
                <React.Fragment key={lesson.lessonId}>
                    <FullScreenSingleBlock
                        lessonIndex={index}
                        activity={activity}
                    />

                    {activity?.canvas?.showLines && (
                        <div
                            className={styles.line}
                            style={getBlocksStyle(
                                activity?.canvas,
                                lesson.status
                            )}
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
    )
)
