import cn from 'classnames'
import React, { FC, memo, useCallback, useMemo } from 'react'
import { Button } from 'components/shared/Button'

import styles from './PassingMenuButton.module.scss'
import { PassingMenuButtonProps } from './PassingMenuButton.types'
import { getIsActive } from '../PassingMenu/PassingMenu.utils'

/** Кнопка с компетенцией и счетчиком для меню прохождения*/
export const PassingMenuButton: FC<PassingMenuButtonProps> = memo(
    ({
        onCompetenceClick,
        competencesStats,
        activeCompetenceContract,
        competenceContract,
    }) => {
        const active = getIsActive(
            competenceContract.competence,
            activeCompetenceContract?.competence
        )
        const stats = competencesStats?.[competenceContract.competence.id]

        const { statsString, completed } = useMemo(() => {
            let statsString = ''
            let completed = false
            if (stats) {
                const { total, answered } = stats
                statsString = `${answered} / ${total}`
                completed = total === answered
            }

            return {
                statsString,
                completed,
            }
        }, [stats])

        const handleCompetenceClick = useCallback(
            () => onCompetenceClick(competenceContract),
            [competenceContract, onCompetenceClick]
        )

        return (
            <Button
                key={competenceContract.competence.id}
                type="link"
                className={cn(
                    styles.wrapper,
                    active && !completed && styles.wrapperActive,
                    completed && styles.wrapperCompleted
                )}
                onClick={handleCompetenceClick}
            >
                <span className={styles.competence}>
                    {competenceContract.competence.name}
                </span>

                <span className={styles.counter}>{statsString}</span>
            </Button>
        )
    }
)
