import React from 'react'
import { COL_xl8_lg12_xs24, ROW_GUTTER } from 'consts'
import {
    CheckboxControl,
    ControlGroup,
    FormItemAdapter,
    HiddenField,
    InputControl,
    ShouldUpdateChecker,
    TextAreaControl,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import { LOCAL } from 'core/local'
import { isThemeBlockTypeInformation } from 'utils'

import { AttachmentFiles } from '../AttachmentFiles'
import { FieldsByTypeEvent } from '../FieldsByTypeEvent'
import { InformationBlock } from '../InformationBlock'
import { ScheduleDates } from '../ScheduleDates'
import { ThemeBlockProps } from './ThemeBlock.types'
import { ThemeBlockSettings } from '../ThemeBlockSettings'
import { getThemeBlockType } from './ThemeBlock.utils'

/** Тематический блок */
export const ThemeBlock: React.FC<ThemeBlockProps> = React.memo(
    ({ field, formMode, form, disabled, className }) => (
        <ControlGroup type="bordered" className={className}>
            <HiddenField fieldName={[field.name, 'type']} />

            <Form.Item name={[field.name, 'isRequired']}>
                <CheckboxControl formMode={formMode} disabled={disabled}>
                    {LOCAL.LABELS.NECESSITY}
                </CheckboxControl>
            </Form.Item>

            <Row gutter={ROW_GUTTER}>
                <Col {...COL_xl8_lg12_xs24}>
                    <FormItemAdapter
                        label={LOCAL.LABELS.NAME}
                        fieldName={[field.name, 'name']}
                        required
                    >
                        <InputControl formMode={formMode} disabled={disabled} />
                    </FormItemAdapter>

                    <FieldsByTypeEvent
                        formMode={formMode}
                        field={field}
                        disabled={disabled}
                    />
                </Col>

                <Col {...COL_xl8_lg12_xs24}>
                    <ShouldUpdateChecker fieldPath={['lessons']}>
                        {({ getFieldValue }) => {
                            const themeBlockType = getThemeBlockType(
                                getFieldValue,
                                field
                            )

                            return (
                                <FormItemAdapter
                                    label={LOCAL.LABELS.DESCRIPTION}
                                    fieldName={[
                                        field.name,
                                        'lessonDescription',
                                    ]}
                                >
                                    <TextAreaControl
                                        autoSize={{
                                            minRows: isThemeBlockTypeInformation(
                                                themeBlockType
                                            )
                                                ? 1
                                                : 4,
                                        }}
                                        formMode={formMode}
                                        disabled={disabled}
                                    />
                                </FormItemAdapter>
                            )
                        }}
                    </ShouldUpdateChecker>
                </Col>

                <Col {...COL_xl8_lg12_xs24}>
                    <ThemeBlockSettings
                        field={field}
                        formMode={formMode}
                        disabled={disabled}
                    />
                </Col>

                <ScheduleDates
                    field={field}
                    disabled={disabled}
                    formMode={formMode}
                />
            </Row>

            <ShouldUpdateChecker fieldPath={['lessons']}>
                {({ getFieldValue }) => {
                    const themeBlockType = getThemeBlockType(
                        getFieldValue,
                        field
                    )

                    return (
                        isThemeBlockTypeInformation(themeBlockType) && (
                            <InformationBlock
                                fieldName={field.name}
                                disabled={disabled}
                                formMode={formMode}
                            />
                        )
                    )
                }}
            </ShouldUpdateChecker>

            <AttachmentFiles
                form={form}
                field={field}
                formMode={formMode}
                disabled={disabled}
            />
        </ControlGroup>
    )
)
