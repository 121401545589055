import cn from 'classnames'
import React, { useCallback, useEffect } from 'react'
import { Form } from 'antd'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import { isKeyEnter } from 'utils'

import styles from './QuickSearch.module.scss'
import { IconsAdapter } from '../IconsAdapter'
import { QuickSearchProps } from './QuickSearch.types'

/**
 * Быстрый поиск
 */
export const QuickSearch: React.FC<QuickSearchProps> = React.memo(
    ({ onSetFilters, isReset, className }) => {
        const [form] = Form.useForm()

        /**
         * Вывод результата поиска по нажатию кнопки enter
         */
        const handleKeyDown = useCallback(
            (e) => {
                if (!isKeyEnter(e.keyCode)) {
                    return
                }

                onSetFilters({ searchText: e.target.value })
            },
            [onSetFilters]
        )

        useEffect(() => {
            isReset && form.resetFields()
        }, [isReset, form])

        return (
            <Form form={form} className={cn(styles.wrapper, className)}>
                <Form.Item name="searchText">
                    <InputControl
                        onKeyDown={handleKeyDown}
                        placeholder={LOCAL.PLACEHOLDERS.QUICK_SEARCH}
                        suffix={<IconsAdapter iconType="SearchOutlined" />}
                        autoComplete="off"
                    />
                </Form.Item>
            </Form>
        )
    }
)
