import React from 'react'
import { Space } from 'antd'

import { SpaceAdapterProps } from './SpaceAdapter.types'

/**
 * Обертка для `Space` от `antd`
 */
export const SpaceAdapter: React.FC<SpaceAdapterProps> = React.memo(
    ({
        children,
        className,
        justifyContent,
        fullHeight,
        fullWidth,
        ...restProps
    }) => {
        const height = fullHeight ? '100%' : undefined
        const width = fullWidth ? '100%' : undefined

        return (
            <Space
                className={className}
                style={{
                    justifyContent,
                    width,
                    height,
                }}
                {...restProps}
            >
                {children}
            </Space>
        )
    }
)
