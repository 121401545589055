import React, { useCallback, useState } from 'react'
import { Button } from 'components/shared/Button'
import { CommentContract, PassingFormsService } from 'core/api'
import { Form } from 'antd'
import { FormItemAdapter, InputControl } from 'components/controls'
import { IconsAdapter, Popup } from 'components/shared'
import { LOCAL } from 'core/local'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { formatDate } from 'utils'

import styles from './CommonStatsCommentModal.module.scss'
import { COMMENT_FORM_EVENTS } from './CommonStatsCommentModal.events'
import { CommonStatsCommentModalProps } from './CommonStatsCommentModal.types'

/** общая статистика модальное окно сохранения комментария */
export const CommonStatsCommentModal: React.FC<CommonStatsCommentModalProps> = React.memo(
    ({ passingFormId, comment }) => {
        const [form] = Form.useForm()

        const [isOpenModal, setOpenModal] = useState(false)

        const handleOpenModal = useCallback(() => {
            setOpenModal(true)
        }, [])

        const handleCloseModal = useCallback(() => {
            setOpenModal(false)
        }, [])

        const handleFinish = async (values: CommentContract) => {
            try {
                await PassingFormsService.saveComment({
                    body: { comment: values.text, id: passingFormId },
                })

                eventEmitter.emit(COMMENT_FORM_EVENTS.SAVE_COMMENT)

                handleCloseModal()
            } catch (error) {
                console.error(error)
            }
        }

        return (
            <>
                <Button className={styles.commentBtn} onClick={handleOpenModal}>
                    <IconsAdapter iconType="CustomIconResultsWhite" />
                </Button>

                <Popup
                    visible={isOpenModal}
                    footer={null}
                    onCancel={handleCloseModal}
                    title={LOCAL.LABELS.COMMENT_TO_CHECK_TEST_RESULT}
                >
                    <Form
                        form={form}
                        onFinish={handleFinish}
                        initialValues={comment}
                    >
                        <FormItemAdapter fieldName="comment">
                            <InputControl />
                        </FormItemAdapter>

                        <span>
                            {comment.userFullName}{' '}
                            {formatDate(comment.commentedDate)}
                        </span>

                        <div className={styles.modalFooter}>
                            <Button
                                type="link"
                                onClick={handleCloseModal}
                                className="mr-20"
                            >
                                {LOCAL.ACTIONS.CANCEL}
                            </Button>

                            <Button htmlType="submit">
                                {LOCAL.ACTIONS.SAVE}
                            </Button>
                        </div>
                    </Form>
                </Popup>
            </>
        )
    }
)
