import cn from 'classnames'
import React, { useMemo } from 'react'
import { Col } from 'antd'
import { Spoiler } from 'components/shared'
import { isFormModeView } from 'utils'

import styles from './CompetenciesPanel.module.scss'
import { COMPETENCE_GROUP_LEGEND_ITEMS } from './CompetenciesPanel.consts'
import { COMPETENCIES_LEVELS } from '../JobProfileForm/JobProfileForm.consts'
import { CompetenciesPanelProps } from './CompetenciesPanel.types'
import { JobProfileCompetence } from '../JobProfileCompetence'
import { renderSpoilerTitle } from './CompetenciesPanel.utils'

/** Группа компетенций */
export const CompetenciesPanel: React.FC<CompetenciesPanelProps> = React.memo(
    ({
        groupData,
        onCompetenciesLevelChange: handleCompetenciesLevelChange,
        competenceGroupsIds,
        formMode,
        form,
    }) => {
        /**
         * Компетенции группы
         */
        const competencies = useMemo(
            () =>
                groupData.children?.map((el) => (
                    <div key={el.id} className={styles.row}>
                        <JobProfileCompetence
                            name={String(el.id)}
                            values={COMPETENCIES_LEVELS}
                            value={el.competenceLevel}
                            onChange={handleCompetenciesLevelChange}
                            label={el.name}
                            labelCol={{
                                xs: 12,
                            }}
                            valueCol={{
                                xs: 12,
                            }}
                            description={el.description}
                            disabled={isFormModeView(formMode)}
                        />
                    </div>
                )),
            [formMode, groupData.children, handleCompetenciesLevelChange]
        )

        return (
            <div className={styles.wrapper}>
                <Spoiler
                    spoilerTitleRender={renderSpoilerTitle({
                        groupId: groupData.groupId,
                        groupName: groupData.groupName,
                        competenceGroupsIds,
                        formMode,
                        form,
                    })}
                    defaultVisible
                    className={styles.spoiler}
                >
                    <div className={styles.row}>
                        <Col xs={12}>
                            <div className={styles.competenceGroup}>
                                {COMPETENCE_GROUP_LEGEND_ITEMS.map((el) => (
                                    <div
                                        key={el.label}
                                        className={cn(
                                            styles.level,
                                            el.modifierCn
                                        )}
                                        title={el.title}
                                        style={{
                                            width: `calc(100% / ${COMPETENCE_GROUP_LEGEND_ITEMS.length}`,
                                        }}
                                    >
                                        {el.label}
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </div>

                    {competencies}
                </Spoiler>
            </div>
        )
    }
)
