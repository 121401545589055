import { QuizType } from 'core/api'

/**
 * Начальные значения для формы
 */
export const INITIAL_FORM_VALUES = {
    daysToStartNotification: 3,
    daysToEndNotification: 3,
    quizType: QuizType.Feedback,
    attemptsLimit: 1,
}

/**
 * Минимальное количество дней, за которое срабатывает уведомелние
 */
export const MIN_DAYS_TO_NOTIFICATION = 1

export const SHOULD_UPDATE_PORTFOLIOS_FIELDS = ['forAllOrganizations']
