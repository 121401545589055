import React, { useEffect } from 'react'
import { AddOrganizationControl } from 'components/controls/AddOrganizationControl'
import { Col, Row } from 'antd'
import { ComponentCommonProps, DictionariesCommonProps } from 'App.types'
import {
    FormItemAdapter,
    MultiSelectControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { LabelWithSwitch } from 'components/shared'
import { PROGRAMME_LEVELS_MAPPED, ROW_GUTTER } from 'consts'
import {
    PositionProfileDictionariesService,
    PositionsService,
    ThemesService,
} from 'core/api'
import { WithLoaderProps } from 'HOCs'
import { isEmpty } from 'lodash'
import { useDictionaries } from 'hooks'

import styles from '../ProductCatalogForm/ProductCatalogForm.module.scss'

/** Блок полей формы создания карточки продукта - доступность продукта */
export const ProductAvailability: React.FC<
    ComponentCommonProps & WithLoaderProps
> = React.memo(({ formMode, updateLoader }) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<'levels' | 'positions' | 'themesData'>
    >({ updateLoader })

    useEffect(() => {
        handleFetchDictionaries({
            levels: PositionProfileDictionariesService.getAllLevels(),
            positions: PositionsService.getAll(),
            themesData: [ThemesService.getAll(), 'treeSelect'],
        })
    }, [handleFetchDictionaries])

    return (
        <div className={styles.section}>
            <h3>{LOCAL.LABELS.AVAILABILITY}</h3>

            <Row gutter={ROW_GUTTER}>
                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="programLevel"
                        label={LOCAL.LABELS.PROGRAMME_LEVEL}
                        required
                    >
                        <SelectControl
                            values={PROGRAMME_LEVELS_MAPPED}
                            formMode={formMode}
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="levelIds"
                        label={LOCAL.LABELS.POSITION_LEVEL}
                        required
                    >
                        <SelectControl
                            values={dictionaries?.levels || []}
                            formMode={formMode}
                            mode="multiple"
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={12}>
                    <ShouldUpdateChecker
                        fieldPath={[['positionIds'], ['forAllPositions']]}
                    >
                        {({ getFieldValue }) => {
                            const positions = getFieldValue('positionIds')
                            const forAllPositions = getFieldValue(
                                'forAllPositions'
                            )

                            const positionsLabel = (
                                <LabelWithSwitch
                                    label={LOCAL.LABELS.POSITION}
                                    fieldName="forAllPositions"
                                    addonAfter={
                                        LOCAL.LABELS.AVAILABLE_FOR_ALL_POSITIONS
                                    }
                                    formMode={formMode}
                                    disabled={!isEmpty(positions)}
                                />
                            )

                            return (
                                <FormItemAdapter
                                    fieldName="positionIds"
                                    label={positionsLabel}
                                    required
                                >
                                    <SelectControl
                                        values={dictionaries?.positions || []}
                                        formMode={formMode}
                                        mode="multiple"
                                        disabled={forAllPositions}
                                    />
                                </FormItemAdapter>
                            )
                        }}
                    </ShouldUpdateChecker>
                </Col>

                <Col xs={12}>
                    <FormItemAdapter
                        fieldName="themesIds"
                        label={LOCAL.LABELS.DISPLAY_IN_THEMATICS_SECTIONS}
                        required
                    >
                        <MultiSelectControl
                            formMode={formMode}
                            treeData={dictionaries?.themesData || []}
                            selectAll
                            allowClear
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={24}>
                    <FormItemAdapter
                        fieldName="organizations"
                        label={LOCAL.LABELS.AVAILABLE_TO_ORGANIZATIONS}
                        required
                    >
                        <AddOrganizationControl
                            formMode={formMode}
                            withSwitch
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </div>
    )
})
