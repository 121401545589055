import React, { useCallback, useMemo } from 'react'
import { CheckboxControl, ControlGroup } from 'components/controls'
import { CircleReportType, CircleReportsService } from 'core/api'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'

import styles from './UserProfileBody.module.scss'
import { AllPositions } from '../AllPositions'
import { UserProfileBodyProps } from './UserProfileBody.types'
import { UserProfileCompetencies } from '../UserProfileCompetencies'
import { UserProfileGoals } from '../UserProfileGoals'
import {
    checkDisplayPositionProfile,
    isDisplayUserProfileCompetencies,
} from './UserProfileBody.utils'

/** Основной контент страницы профайла пользователя */
export const UserProfileBody: React.FC<UserProfileBodyProps> = React.memo(
    ({
        comparisonData,
        userProfile,
        currentUserId,
        handleChangePositionProfile,
        isOwnProfile,
        chosenReports,
        isAvailableForCandidate,
        setAvailableForCandidate,
        currentUserStructuralUnitPosition,
    }) => {
        const isOneAppointment = useMemo(() => chosenReports.length === 1, [
            chosenReports.length,
        ])

        /**
         * Обработчик изменения доступа к отчету для выбранного назначения
         */
        const handleChangeReportAccess = useCallback(
            async (checked?: boolean) => {
                try {
                    if (isOneAppointment) {
                        const [appointmentId] = chosenReports

                        await CircleReportsService.toggleReportAccess({
                            body: {
                                appointmentId,
                                type: CircleReportType.CompetenceResult,
                            },
                        })

                        setAvailableForCandidate(checked)
                    }
                } catch (error) {
                    console.error(error)
                }
            },
            [chosenReports, isOneAppointment, setAvailableForCandidate]
        )

        return (
            <div className={styles.wrapper}>
                <Row gutter={ROW_GUTTER}>
                    <Col xs={24} xl={16}>
                        <ControlGroup className={styles.controlGroup} noPadding>
                            {isDisplayUserProfileCompetencies(
                                !!comparisonData?.data.length,
                                checkDisplayPositionProfile(userProfile)
                            ) ? (
                                <>
                                    <UserProfileCompetencies
                                        competenciesData={
                                            userProfile?.positionProfile
                                                ?.competencies
                                        }
                                        comparisonData={comparisonData}
                                    />

                                    {!isOwnProfile && isOneAppointment && (
                                        <CheckboxControl
                                            checked={isAvailableForCandidate}
                                            onChange={handleChangeReportAccess}
                                        >
                                            {LOCAL.LABELS.RESULTS_AVAILABLE}
                                        </CheckboxControl>
                                    )}
                                </>
                            ) : (
                                <>
                                    <h4>{LOCAL.LABELS.COMPETENCIES}</h4>

                                    <div>
                                        {
                                            LOCAL.LABELS
                                                .POSITION_PROFILE_NOT_DEFINED
                                        }
                                    </div>
                                </>
                            )}
                        </ControlGroup>

                        <UserProfileGoals
                            userStructuralUnitPosition={
                                currentUserStructuralUnitPosition
                            }
                            userId={currentUserId}
                        />
                    </Col>

                    <Col xs={24} xl={8}>
                        <AllPositions
                            user={userProfile?.user}
                            onChangePositionProfile={
                                handleChangePositionProfile
                            }
                        />
                    </Col>
                </Row>
            </div>
        )
    }
)
