import React from 'react'
import cn from 'classnames'
import { Tooltip } from 'antd'

import styles from './TooltipLight.module.scss'
import { TooltipLightProps } from './TooltipLight.types'

/**
 * Вариант tooltip'а со скругленными краями и отсуствием хвоста
 */
export const TooltipLight: React.FC<TooltipLightProps> = React.memo(
    ({ className, children, ...restProps }) => (
        <Tooltip
            overlayClassName={cn(
                styles.wrapper,
                styles.wrapperWithoutArrow,
                className
            )}
            {...restProps}
        >
            {children}
        </Tooltip>
    )
)
