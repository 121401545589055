import {
    AdaptationTracksService,
    DevelopmentTracksService,
    DocumentStatus,
    TrackAppointmentType,
    TrackType,
} from 'core/api'
import { LOCAL } from 'core/local'
import { ORDER_RULES_WITH_CREATION_DATE } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    TRACK_ADAPTATION_PERMISSIONS,
    TRACK_DEVELOPMENT_PERMISSIONS,
} from 'consts'

/**
 * Начальные значения для формы
 */
export const INITIAL_FORM_VALUES = {
    isDuration: true,
    trackType: TrackType.Adaptation,
    appointmentType: TrackAppointmentType.ByTrack,
    attemptCount: 1,
    duration: 1,
}

export const TRACK_SEARCH_METHOD_BODY = {
    statuses: [DocumentStatus.ReadyForWork, DocumentStatus.InWork],
    orderRules: ORDER_RULES_WITH_CREATION_DATE,
}

export const TRACKS_SEARCH_OPTIONS = {
    Adaptation: {
        name: 'adaptationTrackId',
        label: LOCAL.LABELS.ADAPTATION_TRACK_TEMPLATE,
        searchMethod: AdaptationTracksService.searchForModal,
        link: ROUTE_NAMES.TRACKS_ADAPTATION_VIEW,
        permissions: TRACK_ADAPTATION_PERMISSIONS,
    },
    Development: {
        name: 'developmentTrackId',
        label: LOCAL.LABELS.DEVELOPMENT_TRACK_TEMPLATE,
        searchMethod: DevelopmentTracksService.searchForModal,
        link: ROUTE_NAMES.TRACKS_DEVELOPMENT_VIEW,
        permissions: TRACK_DEVELOPMENT_PERMISSIONS,
    },
}

export const ADAPTATION_TRACK_DEPENDENCY_FIELDS = [
    'appointmentType',
    'adaptationTrackId',
    'developmentTrackId',
    'trackType',
]

export const MIN_DAYS_TO_NOTIFICATION = 1
