import cn from 'classnames'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import React, { useCallback, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import { Col, Radio, Row } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { IndicatorInfoContract, IndicatorsService } from 'core/api'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { RadioChangeEvent } from 'antd/lib/radio'
import { ShouldUpdateChecker } from 'components/controls'
import { TooltipAdapter } from 'components/shared'
import { backwardFn, getPopupContainer } from 'utils'

import styles from './JobProfileCompetence.module.scss'
import { CompetenceIndicators } from '../CompetenceIndicators'
import { JobProfileCompetenceProps } from './JobProfileCompetence.types'

/**
 * Компонент расширяющий поведение `RadioGroup` от `antd`, принимает те же `props`
 */
export const JobProfileCompetence: React.FC<JobProfileCompetenceProps> = React.memo(
    (props) => {
        const {
            values = [],
            label,
            labelCol = { xs: 8 },
            valueCol = { xs: 16 },
            rowOptions = { gutter: ROW_GUTTER, align: 'middle' },
            description,
            onChange,
            name: competenceId,
            value,
            ...restProps
        } = props

        const [indicators, setIndicators] = useState<IndicatorInfoContract[]>()
        const [isIndicatorsOpen, setIndicatorsOpen] = useState(false)

        /**
         * Обработчик выбора уровня
         * @param e объект события
         */
        const triggerChangeLevel = useCallback(
            (e: RadioChangeEvent) => {
                onChange?.(e.target.value, competenceId)
            },
            [competenceId, onChange]
        )

        const handleToggleIndicators = (
            isCompetenciesIndicatorsShown: boolean
        ) => async () => {
            if (!isCompetenciesIndicatorsShown) return

            if (!indicators) {
                const indicators = await IndicatorsService.getIndicatorsByCompetenceId(
                    { competenceId: Number(competenceId) }
                )

                setIndicators(orderBy(indicators, ['order', 'name']))
            }

            setIndicatorsOpen(backwardFn)
        }

        /**
         * Обработчик сброса уровня
         */
        const triggerResetLevel = () => {
            onChange?.(undefined, competenceId, true)
        }

        const resetTooltipVisibleProp = () => {
            if (isNil(value)) return { visible: false }
        }

        /** Грид уровней компетенции
         * @param count всего уровней
         */
        const renderLevel = useCallback(
            (count: number) =>
                new Array(count + 1).fill('').map((_, i) => <span key={i} />),
            []
        )

        /** Радио с уровнями компетенции */
        const radios = useMemo(
            () =>
                values
                    .map((el) => (
                        <Radio
                            key={String(el.key)}
                            value={el.value}
                            className={styles.level}
                            style={{
                                width: `calc(100% / ${values.length} * ${
                                    Number(el.value) + 1
                                })`,
                            }}
                        >
                            <div className={styles.grid}>
                                {renderLevel(Number(el.value))}
                            </div>

                            <div
                                className={styles.label}
                                style={{
                                    width: `calc(100% / ${
                                        Number(el.value) + 1
                                    })`,
                                }}
                            >
                                {el.label}
                            </div>
                        </Radio>
                    ))
                    .reverse(),
            [renderLevel, values]
        )

        const radioGroup = useMemo(
            () => (
                <Radio.Group
                    {...restProps}
                    value={value}
                    name={competenceId}
                    onChange={triggerChangeLevel}
                >
                    {radios}

                    {isNil(value) && <span className={styles.zeroLine} />}
                </Radio.Group>
            ),
            [competenceId, triggerChangeLevel, value, restProps, radios]
        )

        return (
            <div className={cn(styles.wrapper, 'form-control')}>
                <ShouldUpdateChecker
                    fieldPath={['isCompetenciesIndicatorsShown']}
                >
                    {({ getFieldValue }) => {
                        const isCompetenciesIndicatorsShown = getFieldValue(
                            'isCompetenciesIndicatorsShown'
                        )

                        return (
                            <>
                                <Row {...rowOptions}>
                                    <Col {...valueCol}>
                                        {restProps.disabled ? (
                                            radioGroup
                                        ) : (
                                            <TooltipAdapter
                                                title={
                                                    <Button
                                                        type="link"
                                                        onClick={
                                                            triggerResetLevel
                                                        }
                                                    >
                                                        {LOCAL.LABELS.CLEAR}
                                                    </Button>
                                                }
                                                overlayClassName="level-reset-tooltip"
                                                placement="right"
                                                getPopupContainer={
                                                    getPopupContainer
                                                }
                                                {...resetTooltipVisibleProp()}
                                            >
                                                {radioGroup}
                                            </TooltipAdapter>
                                        )}
                                    </Col>

                                    <Col {...labelCol}>
                                        <Row className={styles.info}>
                                            <Col
                                                className={cn(
                                                    styles.infoLabel,
                                                    isCompetenciesIndicatorsShown &&
                                                        styles.infoLabelPointer
                                                )}
                                                xs={22}
                                                onClick={handleToggleIndicators(
                                                    isCompetenciesIndicatorsShown
                                                )}
                                            >
                                                <div>{label}</div>

                                                {isCompetenciesIndicatorsShown && (
                                                    <IconsAdapter
                                                        iconType="DownOutlined"
                                                        className={cn(
                                                            styles.arrowIcon,
                                                            isIndicatorsOpen &&
                                                                styles.arrowIconUp
                                                        )}
                                                    />
                                                )}
                                            </Col>

                                            <Col
                                                className={
                                                    styles.infoDescription
                                                }
                                                xs={2}
                                            >
                                                {description && (
                                                    <TooltipAdapter
                                                        placement="top"
                                                        trigger="click"
                                                        title={description}
                                                    >
                                                        <IconsAdapter iconType="InfoCircleFilled" />
                                                    </TooltipAdapter>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {isIndicatorsOpen &&
                                    isCompetenciesIndicatorsShown && (
                                        <CompetenceIndicators
                                            indicators={indicators}
                                            competenceLevel={value}
                                        />
                                    )}
                            </>
                        )
                    }}
                </ShouldUpdateChecker>
            </div>
        )
    }
)
