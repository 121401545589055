import React from 'react'
import { ComponentCommonProps } from 'App.types'
import {
    DevelopmentTrackContract,
    DevelopmentTrackPublishContract,
} from 'core/api'
import { withLoader } from 'HOCs'

import Track from './Track'
import { TRACK_COMPONENT_PROPS } from './Track.consts'

export const TrackDevelopmentPreset: React.FC<ComponentCommonProps> = withLoader(
    ({ formMode, updateLoader }) => (
        <Track<DevelopmentTrackContract, DevelopmentTrackPublishContract>
            updateLoader={updateLoader}
            formMode={formMode}
            {...TRACK_COMPONENT_PROPS.DEVELOPMENT}
        />
    )
)
