import React from 'react'
import { AssessmentPortfolioAssessedPositionContract } from 'core/api'

import styles from './AssessmentPosition.module.scss'
import { GetTitleProps } from './AssessmentPosition.types'

/**
 * Текст заголовка для объекта должности
 */
export const getTitleText = ({
    position,
    structuralUnit,
}: AssessmentPortfolioAssessedPositionContract) => (
    <div>
        <h4 className={styles.positionName}>{position.name}</h4>

        <div className={styles.organization}>{structuralUnit.name}</div>
    </div>
)

/**
 * Заголовок для объекта должности
 * @param toggleSpoilerVisible функция переключения состояния
 * @param icon иконка
 * @param titleText компонент текста заголовка
 * @param initialValues начальные значения
 */
export const getTitle = ({
    toggleSpoilerVisible,
    icon,
    titleText,
    initialValues,
}: GetTitleProps) =>
    initialValues?.length ? (
        <a href="#s" onClick={toggleSpoilerVisible} className={styles.toggle}>
            {icon}

            {titleText}
        </a>
    ) : (
        titleText
    )
