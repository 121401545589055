import moment from 'moment-timezone'
import React, { useCallback, useEffect, useState } from 'react'
import {
    AddOrganizationControl,
    CheckboxControl,
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    InputNumberControl,
    ShouldUpdateChecker,
} from 'components/controls'
import {
    AppointmentContract,
    AppointmentPublishContract,
    AppointmentsService,
    EntityEditSessionService,
    EntityType,
    QuizPortfolioSelectSearchContract,
    QuizPortfoliosService,
    QuizType,
} from 'core/api'
import { Col, Form, Row, notification } from 'antd'
import { FORM_IDS } from 'core/configs'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import { ORDER_RULES_WITH_CREATION_DATE } from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ROW_GUTTER } from 'consts'
import { SpaceAdapter } from 'components/shared'
import { WithLoaderProps } from 'HOCs'
import { checkChangeEvaluationPeriodFrom } from 'utils/conditions.utils'
import {
    createConfirmPopup,
    disableDateWhenSelectedDate,
    getAssignmentFormPopupTitle,
    isAppointmentStatusDraft,
    isAppointmentStatusEnded,
    isFormModeEdit,
    isFormModeView,
    normalizeDataForSelectAndRadio,
} from 'utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { template } from 'lodash'
import { useHistory } from 'react-router-dom'

import {
    INITIAL_FORM_VALUES,
    MIN_DAYS_TO_NOTIFICATION,
    SHOULD_UPDATE_PORTFOLIOS_FIELDS,
} from './QuizAssignmentForm.consts'
import { PollOptions } from '../PollOptions'
import {
    QuizAssignmentFormSubmitValues,
    QuizDictionariesStateProps,
} from './QuizAssignmentForm.types'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
} from './QuizAssignmentForm.utils'

/**
 * Форма создания назначения опроса
 */
export const QuizAssignmentForm: React.FC<
    FormProps<AppointmentContract> & Partial<WithLoaderProps>
> = React.memo(
    ({
        onBlockUserRouting,
        isSaveDocument,
        initialValuesForEdit,
        reFetchInitialFormValues,
        formMode,
        updateLoader,
    }) => {
        const history = useHistory()
        const [form] = Form.useForm()

        const [dictionaries, setDictionaries] = useState<
            QuizDictionariesStateProps
        >()

        const disabledByStatus =
            isFormModeEdit(formMode) &&
            !isAppointmentStatusDraft(initialValuesForEdit?.status)

        const disablePeriodFromByStatus =
            isAppointmentStatusEnded(initialValuesForEdit?.status) &&
            isFormModeEdit(formMode)

        /**
         * Обработчик отправки формы
         */
        const handleFinish = useCallback(
            async (values: Partial<QuizAssignmentFormSubmitValues>) => {
                try {
                    updateLoader?.(true)

                    const body = mapFormDataToRequest(
                        values,
                        initialValuesForEdit?.id
                    ) as AppointmentPublishContract

                    if (isSaveDocument) {
                        await AppointmentsService.saveAppointment({ body })
                    } else {
                        await AppointmentsService.publishAppointment({
                            body,
                        })
                    }

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: isSaveDocument
                            ? LOCAL.MESSAGES.ASSIGNMENT_SAVE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES.ASSIGNMENT_PUBLISH_SUCCESS_MESSAGE,
                        content: getAssignmentFormPopupTitle(
                            getRouteMeta(
                                ROUTE_NAMES.QUIZ_ASSIGNMENTS,
                                'pageTitle'
                            )
                        ),
                        onOk: () => {
                            history.push(ROUTE_NAMES.QUIZ_ASSIGNMENTS)
                        },
                        onCancel: () => {
                            if (!initialValuesForEdit?.id) form.resetFields()
                            else
                                reFetchInitialFormValues?.(
                                    initialValuesForEdit?.id
                                )
                        },
                        okText: LOCAL.YES,
                        cancelText: LOCAL.NO,
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                updateLoader,
                initialValuesForEdit,
                isSaveDocument,
                onBlockUserRouting,
                history,
                form,
                reFetchInitialFormValues,
            ]
        )

        const updateDictionaries = useCallback(
            (newValue: QuizDictionariesStateProps) => {
                setDictionaries((prevState) => ({
                    ...prevState,
                    ...newValue,
                }))
            },
            []
        )

        /**
         * Запрос портфелей оценки
         */
        const portfoliosFetch = useCallback(
            async ({ forAllOrganizations }) => {
                try {
                    updateLoader?.(true)

                    const quizPortfolios = await QuizPortfoliosService.getForSelect(
                        {
                            body: {
                                type: QuizType.Feedback,
                                forAllOrganizations,
                                orderRules: ORDER_RULES_WITH_CREATION_DATE,
                            } as QuizPortfolioSelectSearchContract,
                        }
                    )

                    updateDictionaries({
                        quizPortfolios: quizPortfolios.map(
                            normalizeDataForSelectAndRadio
                        ),
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [updateDictionaries, updateLoader]
        )

        /**
         * Запрос справочников
         */
        const dictionariesFetch = useCallback(async () => {
            try {
                portfoliosFetch({
                    forAllOrganizations:
                        initialValuesForEdit?.forAllOrganizations,
                })
            } catch (error) {
                console.error(error)
            }
        }, [portfoliosFetch, initialValuesForEdit])

        /**
         * Обработчик изменения значений полей формы
         * @param changedValue значение поля на котором сработало событие
         * @param allValues все значения, включая `changedValue`
         */
        const handleValuesChange = useCallback(
            async (changedValue, allValues) => {
                const [changedValueKey, changedValueValue] = Object.entries(
                    changedValue
                )[0]

                if (SHOULD_UPDATE_PORTFOLIOS_FIELDS.includes(changedValueKey)) {
                    portfoliosFetch(allValues)
                }
                if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                    onBlockUserRouting?.()
                }
                if (
                    changedValueKey === 'quizPortfolioId' &&
                    changedValueValue
                ) {
                    const dataSource = await EntityEditSessionService.checkAvailability(
                        {
                            id: changedValueValue as number,
                            entity: EntityType.QuizPortfolio,
                        }
                    )

                    if (!dataSource.available) {
                        notification.error({
                            message: template(LOCAL.MESSAGES.PORTFOLIO_ON_EDIT)(
                                {
                                    portfolio: LOCAL.LABELS.QUIZ_PORTFOLIO.toLowerCase(),
                                }
                            ),
                        })

                        form.setFieldsValue({
                            [changedValueKey]: undefined,
                        })
                    }
                }

                if (changedValue.evaluationPeriodFrom) {
                    checkChangeEvaluationPeriodFrom({
                        evaluationPeriodFrom: changedValue.evaluationPeriodFrom,
                        evaluationPeriodTo: allValues.evaluationPeriodTo,
                        form,
                    })
                }
            },
            [form, formMode, onBlockUserRouting, portfoliosFetch]
        )

        useEffect(() => {
            dictionariesFetch()
        }, [dictionariesFetch])

        useEffect(() => {
            if (initialValuesForEdit?.id)
                form.setFieldsValue(mapResponseToFormData(initialValuesForEdit))
        }, [form, initialValuesForEdit])

        return (
            <Form
                id={FORM_IDS.QUIZ_ASSIGNMENTS_FORM}
                onFinish={handleFinish}
                initialValues={INITIAL_FORM_VALUES}
                onValuesChange={handleValuesChange}
                form={form}
            >
                <FormItemAdapter
                    fieldName="name"
                    label={LOCAL.LABELS.NAME}
                    required
                >
                    <InputControl formMode={formMode} />
                </FormItemAdapter>

                <Form.Item noStyle dependencies={['quizPortfolioId']}>
                    {({ getFieldValue }) => (
                        <FormItemAdapter
                            fieldName="organizationsIds"
                            label={LOCAL.LABELS.ORGANIZATIONS}
                            required
                        >
                            <AddOrganizationControl
                                formMode={formMode}
                                disabled={
                                    getFieldValue('quizPortfolioId') ||
                                    disabledByStatus
                                }
                                withSwitch
                            />
                        </FormItemAdapter>
                    )}
                </Form.Item>

                <PollOptions
                    formMode={formMode}
                    dictionaries={dictionaries}
                    disabled={disabledByStatus}
                />

                <SpaceAdapter className="mt-20">
                    <Form.Item name="attemptsLimited">
                        <CheckboxControl
                            formMode={formMode}
                            disabled={disabledByStatus}
                        >
                            {LOCAL.LABELS.PASSING_ATTEMPTS_LIMIT}
                        </CheckboxControl>
                    </Form.Item>

                    <Form.Item noStyle dependencies={['attemptsLimited']}>
                        {({ getFieldValue }) => (
                            <Form.Item name="attemptsLimit">
                                <InputNumberControl
                                    formMode={formMode}
                                    min={1}
                                    addonAfter={LOCAL.LABELS.TIMES}
                                    disabled={
                                        !getFieldValue('attemptsLimited') ||
                                        disabledByStatus
                                    }
                                />
                            </Form.Item>
                        )}
                    </Form.Item>
                </SpaceAdapter>

                <Row gutter={ROW_GUTTER}>
                    <Col md={24} xl={4}>
                        <FormItemAdapter
                            fieldName="evaluationPeriodFrom"
                            label={LOCAL.LABELS.DATE_START}
                            required
                        >
                            <DatePickerControl
                                formMode={formMode}
                                disabledDate={disableDateWhenSelectedDate(
                                    initialValuesForEdit?.evaluationPeriodFrom ||
                                        moment()
                                )}
                                disabled={disablePeriodFromByStatus}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col md={24} xl={4}>
                        <ShouldUpdateChecker fieldPath="evaluationPeriodFrom">
                            {({ getFieldsValue }) => {
                                const {
                                    evaluationPeriodFrom,
                                } = getFieldsValue(['evaluationPeriodFrom'])

                                return (
                                    <FormItemAdapter
                                        fieldName="evaluationPeriodTo"
                                        label={LOCAL.LABELS.DATE_END}
                                        required
                                    >
                                        <DatePickerControl
                                            formMode={formMode}
                                            disabledDate={disableDateWhenSelectedDate(
                                                evaluationPeriodFrom || moment()
                                            )}
                                            disabled={disablePeriodFromByStatus}
                                        />
                                    </FormItemAdapter>
                                )
                            }}
                        </ShouldUpdateChecker>
                    </Col>
                </Row>

                <FormItemAdapter
                    label={LOCAL.LABELS.NOTIFICATIONS_TIME_SET}
                    required
                >
                    <Form.Item name="daysToStartNotification">
                        <InputNumberControl
                            min={MIN_DAYS_TO_NOTIFICATION}
                            placeholder={String(MIN_DAYS_TO_NOTIFICATION)}
                            addonBefore={LOCAL.TIME_BEFORE}
                            addonAfter={LOCAL.LABELS.DAYS_TO_START}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </Form.Item>

                    <Form.Item name="daysToEndNotification">
                        <InputNumberControl
                            min={MIN_DAYS_TO_NOTIFICATION}
                            placeholder={String(MIN_DAYS_TO_NOTIFICATION)}
                            addonBefore={LOCAL.TIME_BEFORE}
                            addonAfter={LOCAL.LABELS.DAYS_TO_END}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </Form.Item>
                </FormItemAdapter>
            </Form>
        )
    }
)
