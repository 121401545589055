import React from 'react'
import { useDocumentEditor } from 'hooks'
import { withLoader } from 'HOCs'

import styles from './DocumentEditorView.module.scss'
import { DOCUMENT_EDITOR_VIEW_CONTAINER_ID } from './DocumentEditorView.consts'
import { DocumentEditorViewProps } from './DocumentEditorView.types'

/**
 * Визуализация прохождения тематического блока, выполняемая в текстовом редакторе
 */
export const DocumentEditorView: React.FC<DocumentEditorViewProps> = withLoader(
    React.memo(({ updateLoader, editorConfig }) => {
        useDocumentEditor({
            editorContainerId: DOCUMENT_EDITOR_VIEW_CONTAINER_ID,
            config: editorConfig,
            updateLoader,
        })

        return (
            <div
                id={DOCUMENT_EDITOR_VIEW_CONTAINER_ID}
                className={styles.wrapper}
            />
        )
    }),
    { fullWidthHeight: true }
)
