import React from 'react'
import classNames from 'classnames'
import { DATE_FORMAT } from 'consts'
import { LOCAL } from 'core/local'
import { getDateByFormat } from 'utils'

import styles from './DictionarySyncDate.module.scss'
import { DictionarySyncDateProps } from './DictionarySyncDate.types'

/** Дата последней синхронизации справочника с ОИБ на баннере */
export const DictionarySyncDate: React.FC<DictionarySyncDateProps> = React.memo(
    ({ className, integrationSyncDate }) => (
        <div className={classNames(styles.wrapper, className)}>
            <p>{LOCAL.LABELS.SYNC_DATE.toLocaleLowerCase()}</p>

            <span>
                {getDateByFormat(integrationSyncDate, DATE_FORMAT.WITH_TIME)}
            </span>
        </div>
    )
)
