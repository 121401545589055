import React from 'react'
import stylesTableAdapter from 'components/shared/TableAdapter/TableAdapter.module.scss'
import { LOCAL } from 'core/local'
import { RespondentProgressContract } from 'core/api'
import { Store } from 'antd/lib/form/interface'
import {
    isAssessmentTypeExpert,
    isAssessmentTypeManager,
    isQuestionnaireTypeTest,
} from 'utils'

import styles from './AssignmentsLaunchedTable.module.scss'

/**
 * Рендер плейсхолдера checkbox для записей в таблице формы
 */
export const checkboxPlaceholderRender = () => (
    <span className={styles.checkboxPlaceholder} />
)

/**
 * Рендер checkbox для записей в таблице формы по условию
 */
export const checkboxRender = (
    checked: boolean,
    record: RespondentProgressContract,
    index: number,
    originNode: React.ReactNode
) => {
    if (!record.respondent.active) return checkboxPlaceholderRender()

    return originNode
}

/**
 * Вычислить класс для ряда талицы
 */
export const calculateRowClass = (record: RespondentProgressContract) => {
    if (record.respondent.active === false) return stylesTableAdapter.archive

    return ''
}

/**
 * Вычислить ключ для ряда талицы
 */
export const calculateRowKey = (record: RespondentProgressContract) =>
    record.respondent.user.id

/**
 * Получить заголовок для модального окна прогресса
 */
export const getModalTitle = (initialValues?: Store) => {
    if (isQuestionnaireTypeTest(initialValues?.type))
        return LOCAL.LABELS.PARTICIPANTS

    if (
        isAssessmentTypeExpert(initialValues?.assessment?.type) ||
        isAssessmentTypeManager(initialValues?.assessment?.type)
    )
        return LOCAL.LABELS.EXPERTS

    return LOCAL.LABELS.RESPONDENTS
}
