import { eventEmitter } from 'core/helpers/eventEmitter'

import { ProductCardEventProps } from './ProductCard.types'

export const PRODUCT_CARD_EVENTS = {
    SET_APPOINTED: 'SET_APPOINTED',
}

export const registerProductCardEvents = ({
    onSetAppointed,
}: ProductCardEventProps) => {
    eventEmitter.on(PRODUCT_CARD_EVENTS.SET_APPOINTED, onSetAppointed)
}

export const unregisterProductCardEvents = ({
    onSetAppointed,
}: ProductCardEventProps) => {
    eventEmitter.off(PRODUCT_CARD_EVENTS.SET_APPOINTED, onSetAppointed)
}
