import { getUrlWithoutPostfixes } from 'utils'
import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useRrweb } from 'hooks'

import {
    MATCH_CONFIG_FOR_MONITORING,
    URL_PATH_TO_ENTITY_TYPE,
} from './Header.consts'

export const useShowMonitoring = (monitoringEnable?: boolean) => {
    const { resumeMonitoring, pauseMonitoring } = useRrweb()

    const history = useHistory()
    const routeMatchForMonitoring = useRouteMatch(MATCH_CONFIG_FOR_MONITORING)

    const [prevPath, setPrevPath] = useState<string>()

    const isShowMonitoring =
        routeMatchForMonitoring?.isExact && monitoringEnable

    useEffect(() => {
        if (
            prevPath &&
            getUrlWithoutPostfixes(history.location.pathname) !==
                getUrlWithoutPostfixes(prevPath)
        ) {
            pauseMonitoring()
        }

        setPrevPath(history.location.pathname)
    }, [history.location.pathname, pauseMonitoring, prevPath])

    useEffect(() => {
        const appElement = document.querySelector('.app')

        if (isShowMonitoring) {
            appElement?.classList.add('monitoringEnabled')

            resumeMonitoring(
                URL_PATH_TO_ENTITY_TYPE[
                    getUrlWithoutPostfixes(history.location.pathname)!
                ]
            )

            return
        }

        if (appElement?.classList.contains('monitoringEnabled')) {
            appElement?.classList.remove('monitoringEnabled')
        }
    }, [history.location.pathname, isShowMonitoring, resumeMonitoring])

    return { isShowMonitoring }
}
