import React, {
    ReactText,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { AppError } from 'core/api'
import { Form } from 'antd'
import { RequestQueryProps } from 'hooks/useSearchQuery.types'
import { TableAdapter } from 'components/shared/TableAdapter'
import { getPageSize } from 'utils'
import { useSearchQuery } from 'hooks/useSearchQuery'
import { withLoader } from 'HOCs'

import styles from './TableSearchForm.module.scss'
import {
    ExpandedRowKeysProps,
    RowWithChildren,
    TableSearchFormProps,
} from './TableSearchForm.types'

function TableSearchFormPlain<
    Request extends object,
    Response extends { id: number }
>({
    onRequestFinish,
    initialValues,
    rowSelectionType,
    disableSearch,
    disabledRows,
    searchMethod,
    childrenColumnName,
    tableFiltersOptions,
    tableColumns,
    rowClassName,
    renderSelectCell,
    filterKeys,
    expandable,
    filterComponent: FilterComponent,
    isExpandRowBySearch,
    updateLoader,
    isLoading,
    onRowCheck,
    ...props
}: TableSearchFormProps<Request, Response>) {
    const [recordsCount, setRecordsCount] = useState(0)
    const [tableData, setTableData] = useState<Response[]>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>(
        initialValues as ReactText[]
    )

    const selectedRowKeysFiltered = useMemo(
        () =>
            filterKeys ? selectedRowKeys?.filter(filterKeys) : selectedRowKeys,
        [filterKeys, selectedRowKeys]
    )

    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

    const {
        paginationOptions,
        queryParams,
        handleSetFilters,
        handleResetFilters,
    } = useSearchQuery({
        queryOptions: {
            body: tableFiltersOptions?.initialValues,
        },
    })

    const getExpandedRowKeys = useCallback(
        ({
            data,
            setExpandedRowKeys,
        }: ExpandedRowKeysProps<RowWithChildren<Response>>) => {
            let tempArr: RowWithChildren<Response>[] = [...data]
            const ids: number[] = []

            while (tempArr.length) {
                const current = tempArr.pop()

                if (!current) return

                ids.push(current.id)

                if (current.units) {
                    tempArr = tempArr.concat(current.units)
                }
            }

            setExpandedRowKeys(ids)
        },
        []
    )

    /**
     * Запрос основного справочника
     */
    const dictionaryFetch = useCallback(async () => {
        try {
            if (disableSearch) return

            updateLoader?.(true)

            const dataSource = await searchMethod(
                queryParams as Required<RequestQueryProps>
            )

            const body = queryParams.body as { name: string }

            if (body.name) {
                getExpandedRowKeys?.({
                    data: dataSource.pageItems,
                    setExpandedRowKeys,
                })
            } else setExpandedRowKeys([])

            setTableData(dataSource.pageItems)
            setRecordsCount(dataSource.total)
        } catch (error) {
            console.error(error)
        } finally {
            updateLoader?.(false)
        }
    }, [
        disableSearch,
        updateLoader,
        searchMethod,
        queryParams,
        getExpandedRowKeys,
    ])

    useEffect(() => {
        dictionaryFetch()
    }, [dictionaryFetch])

    /**
     * Обработчик отправки формы фильтра
     */
    const handleFinish = useCallback(async () => {
        try {
            updateLoader?.(true)
            onRequestFinish?.(selectedRowKeysFiltered)
        } catch (error) {
            console.error(error)
            onRequestFinish?.(error as AppError)
        } finally {
            updateLoader?.(false)
        }
    }, [onRequestFinish, selectedRowKeysFiltered, updateLoader])

    /**
     * Обработчики для `row` таблицы
     * @param selectedTableRowKeys - выбранные ключи
     */
    const handleRowCheck = useCallback((selectedTableRowKeys: ReactText[]) => {
        setSelectedRowKeys(selectedTableRowKeys)
    }, [])

    /**
     * Вычислить класс для конкретного `row` таблицы
     * @param record - запись таблицы
     */
    const getRowClassName = useCallback(
        (record) => {
            if (disabledRows?.includes(record.id)) return styles.rowDisabled

            return ''
        },
        [disabledRows]
    )

    const expandableOptions = useMemo(
        () => (isExpandRowBySearch ? { expandedRowKeys } : expandable),
        [expandable, isExpandRowBySearch, expandedRowKeys]
    )

    const handleExpand = useCallback((expanded: boolean, record: Response) => {
        expanded
            ? setExpandedRowKeys((prev) => [...prev, record.id])
            : setExpandedRowKeys((prev) =>
                  prev.filter((el) => el !== record.id)
              )
    }, [])

    return (
        <div>
            <div className={styles.filters}>
                {FilterComponent && (
                    <FilterComponent
                        tableFiltersOptions={tableFiltersOptions}
                        onSetFilters={handleSetFilters}
                        onResetFilters={handleResetFilters}
                    />
                )}
            </div>

            <Form
                onFinish={handleFinish}
                {...props}
                initialValues={initialValues}
            >
                {tableData && (
                    <TableAdapter<Response>
                        columns={tableColumns}
                        childrenColumnName={childrenColumnName}
                        dataSource={tableData}
                        pagination={{
                            ...paginationOptions,
                            total: recordsCount,
                            current: queryParams.pageNumber,
                            pageSize: getPageSize(
                                queryParams?.pageSize,
                                tableData?.length
                            ),
                        }}
                        rowClassName={rowClassName || getRowClassName}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeysFiltered,
                            renderCell: renderSelectCell,
                            preserveSelectedRowKeys: true,
                            onChange: handleRowCheck,
                            checkStrictly: true,
                            type: rowSelectionType,
                        }}
                        expandable={expandableOptions}
                        onExpand={
                            isExpandRowBySearch ? handleExpand : undefined
                        }
                    />
                )}
            </Form>
        </div>
    )
}

type TableSearchFormPlainType = typeof TableSearchFormPlain

export const TableSearchForm = withLoader(
    React.memo(TableSearchFormPlain) as TableSearchFormPlainType
) as TableSearchFormPlainType
