import React from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Col, Form, Row } from 'antd'
import { ControlGroup } from 'components/controls/ControlGroup'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { isFormModeView } from 'utils'

import styles from './DynamicListCardControl.module.scss'
import { DynamicListCardControlProps } from './DynamicListCardControl.types'

/** Компонент списка с возможностью удаления и добавления элементов */
export const DynamicListCardControl: React.FC<DynamicListCardControlProps> = React.memo(
    ({
        listFieldName,
        title,
        formMode,
        disabled,
        disabledAddBtn,
        disabledClearBtn,
        onAdd,
        onClear,
        renderListItem,
    }) => (
        <ControlGroup type="bordered">
            <Form.List name={listFieldName}>
                {(fields, { add, remove }) => (
                    <>
                        <Row gutter={ROW_GUTTER} className={styles.header}>
                            <Col flex="auto">
                                <span className={styles.title}>{title}</span>
                            </Col>

                            <Col>
                                <AddButton
                                    onClick={() => {
                                        ;(onAdd || add)()
                                    }}
                                    formMode={formMode}
                                    disabled={disabled || disabledAddBtn}
                                />
                            </Col>

                            <Col>
                                <AddButton
                                    icon={
                                        <IconsAdapter iconType="CloseOutlined" />
                                    }
                                    onClick={onClear}
                                    disabled={
                                        disabled ||
                                        disabledClearBtn ||
                                        isFormModeView(formMode) ||
                                        !fields.length
                                    }
                                    buttonText={LOCAL.LABELS.CLEAR}
                                />
                            </Col>
                        </Row>

                        {fields.map((field) =>
                            renderListItem({
                                field,
                                onRemove: () => {
                                    remove(field.name)
                                },
                            })
                        )}
                    </>
                )}
            </Form.List>
        </ControlGroup>
    )
)
