import moment from 'moment'
import { AppointmentTrackStatus } from 'core/api'
import { WithFormInstanceProps } from 'App.types'

import { isAppointmentStatusSuspended } from './appointment.utils'
import { isAppointmentTrackStatusAppointment } from './track.utils'

export interface CheckChangeEvaluationPeriodFromProps
    extends WithFormInstanceProps {
    evaluationPeriodFrom?: string
    evaluationPeriodTo?: string
    changeValueKey?: string
}

export const checkChangeEvaluationPeriodFrom = ({
    evaluationPeriodFrom,
    evaluationPeriodTo,
    form,
    changeValueKey = 'evaluationPeriodTo',
}: CheckChangeEvaluationPeriodFromProps) => {
    if (moment(evaluationPeriodFrom).isAfter(evaluationPeriodTo)) {
        form?.setFieldsValue({ [changeValueKey]: null })
    }
}

export const getDisabledDate = (
    status: AppointmentTrackStatus,
    initialDate?: Date
) =>
    isAppointmentTrackStatusAppointment(status) ||
    isAppointmentStatusSuspended(status)
        ? initialDate
        : moment()
