import {
    SortDirection,
    TrackAssistantIllustrationSearchContract,
} from 'core/api'

/* TODO Временное решение, так как на данный момент пагинация для картинок
информационного помощника не актуальна (в задаче указано что изображений будет всего 3) */
export const SEARCH_ASSISTANTS_PICTURES_PARAMS = {
    pageNumber: 1,
    pageSize: 10,
    body: {
        orderRules: [
            {
                field: 'name',
                direction: SortDirection.Asc,
            },
        ],
    } as TrackAssistantIllustrationSearchContract,
}
