import React from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, ContentTitle } from 'components/shared'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { isTrainingMaterialFormatMedia } from 'utils'

import styles from './LayerHeader.module.scss'
import { LayerHeaderProps } from './LayerHeader.types'

export const LayerHeader: React.FC<LayerHeaderProps> = React.memo(
    ({
        course,
        stageTitle,
        onStopContent,
        onViewContent,
        isTrackWithVisualisation,
    }) => (
        <div className={styles.wrapper}>
            {!isTrainingMaterialFormatMedia(course.format) && (
                <ContentTitle title={stageTitle} />
            )}

            <Row
                justify="space-between"
                align="middle"
                className={styles.actions}
            >
                <Col className={styles.name}>{course.name}</Col>

                <Col>
                    <ButtonsToolbar className={styles.buttonsToolbar}>
                        {isTrackWithVisualisation && (
                            <Button type="primary" onClick={onStopContent}>
                                {LOCAL.ACTIONS.CLOSE}
                            </Button>
                        )}

                        <Button type="primary" onClick={onViewContent}>
                            {LOCAL.LABELS.MATERIAL_VIEWED}
                        </Button>
                    </ButtonsToolbar>
                </Col>
            </Row>
        </div>
    )
)
