import React, { useCallback, useEffect } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { Form } from 'antd'
import { FormItemAdapter, SelectControl } from 'components/controls'
import { LOCAL } from 'core/local'
import {
    OrganizationsService,
    StaffUnitFilterContract,
    StaffUnitsService,
    StructuralUnitType,
} from 'core/api'
import { Popup } from 'components/shared/Popup'
import { normalizeDataForSelectAndRadio } from 'utils'
import { useDictionaries } from 'hooks'
import { withLoader } from 'HOCs'

import styles from '../JobProfileCompetencies/JobProfileCompetencies.module.scss'
import {
    STAFF_MODAL_INITIAL_FORM_VALUES,
    staffUnitsShouldFetch,
} from './StaffUnitModal.consts'
import {
    StaffUnitModalDictionariesProps,
    StaffUnitModalFormValues,
    StaffUnitModalProps,
} from './StaffUnitModal.types'
import {
    getDictionaryName,
    getNormalizedUnits,
    mapResponseToFormData,
} from './StaffUnitModal.utils'

export const StaffUnitModal: React.FC<StaffUnitModalProps> = withLoader(
    React.memo(
        ({
            initialValuesForAdd,
            formMode,
            isOpen,
            handleClose,
            handleStuffUnitChange,
            updateLoader,
        }) => {
            const {
                dictionaries,
                handleChangeDictionaries,
                handleFetchDictionaries,
            } = useDictionaries<StaffUnitModalDictionariesProps>({
                updateLoader,
            })
            const [form] = Form.useForm()

            const handleValuesChange = useCallback(
                async (
                    changedValue: StaffUnitModalFormValues,
                    allValues: StaffUnitModalFormValues
                ) => {
                    const [changedValueKey, changedValueValue] = Object.entries(
                        changedValue
                    )[0]

                    if (staffUnitsShouldFetch.includes(changedValueKey)) {
                        const { staffUnitId, ...restValues } = allValues

                        const staffUnits = await StaffUnitsService.getByFilter({
                            body: restValues,
                        })
                        const normalizedStaffUnits = staffUnits.map(
                            normalizeDataForSelectAndRadio
                        )

                        const structuralUnitsNormalized = await getNormalizedUnits(
                            changedValueKey,
                            changedValueValue
                        )

                        switch (changedValueKey) {
                            case 'complexId':
                                handleChangeDictionaries({
                                    oiv: structuralUnitsNormalized,
                                    staffUnits: normalizedStaffUnits,
                                })

                                form.setFieldsValue(
                                    STAFF_MODAL_INITIAL_FORM_VALUES
                                )

                                break
                            case 'oivId':
                                handleChangeDictionaries({
                                    organization: structuralUnitsNormalized,
                                    staffUnits: normalizedStaffUnits,
                                })

                                form.setFieldsValue({
                                    organizationId: null,
                                    departmentId: null,
                                    staffUnitId: null,
                                })

                                break
                            case 'organizationId':
                                handleChangeDictionaries({
                                    department: structuralUnitsNormalized,
                                    staffUnits: normalizedStaffUnits,
                                })

                                form.setFieldsValue({
                                    departmentId: null,
                                    staffUnitId: null,
                                })

                                break
                            case 'departmentId':
                                handleChangeDictionaries({
                                    staffUnits: normalizedStaffUnits,
                                })

                                form.setFieldsValue({
                                    staffUnitId: null,
                                })

                                break
                        }
                    }
                },
                [form, handleChangeDictionaries]
            )

            const handleFinish = useCallback(
                (values: StaffUnitModalFormValues) => {
                    if (values.oivId) {
                        handleStuffUnitChange(
                            values.staffUnitId,
                            dictionaries?.staffUnits
                        )
                        form.resetFields()

                        handleClose()
                    }
                },
                [handleClose, form, handleStuffUnitChange, dictionaries]
            )

            useEffect(() => {
                const initialValues = mapResponseToFormData(initialValuesForAdd)

                if (initialValuesForAdd) {
                    const { staffUnitId, ...restValues } = initialValues

                    StaffUnitsService.getByFilter({
                        body: restValues as StaffUnitFilterContract,
                    }).then((staffUnits) => {
                        handleChangeDictionaries({
                            staffUnits: staffUnits.map(
                                normalizeDataForSelectAndRadio
                            ),
                        })
                    })
                }
                form.setFieldsValue(initialValues)
            }, [initialValuesForAdd, handleChangeDictionaries, form])

            useEffect(() => {
                const { department, organizations } = initialValuesForAdd || {}

                department &&
                    handleChangeDictionaries({
                        department: [
                            normalizeDataForSelectAndRadio(department),
                        ],
                    })

                if (organizations) {
                    organizations.forEach(async ({ type, id }) => {
                        const normalizedStructuralUnits = await getNormalizedUnits(
                            type,
                            id
                        )

                        const dictionaryName = getDictionaryName(type)

                        if (dictionaryName) {
                            handleChangeDictionaries({
                                [dictionaryName]: normalizedStructuralUnits,
                            })
                        }
                    })
                }
            }, [initialValuesForAdd, handleChangeDictionaries])

            useEffect(() => {
                handleFetchDictionaries({
                    complex: OrganizationsService.getAllByUnitType({
                        type: StructuralUnitType.Complex,
                    }),
                })
            }, [handleFetchDictionaries])

            return (
                <Popup
                    visible={isOpen}
                    footer={null}
                    onCancel={handleClose}
                    className={styles.staffUnitPopup}
                    bodyWithoutScroll
                >
                    <h3>{LOCAL.ACTIONS.SELECT_STAFF_UNIT}</h3>

                    <Form
                        form={form}
                        onValuesChange={handleValuesChange}
                        onFinish={handleFinish}
                    >
                        <FormItemAdapter
                            fieldName="complexId"
                            label={LOCAL.LABELS.COMPLEX}
                        >
                            <SelectControl
                                values={dictionaries?.complex || []}
                                formMode={formMode}
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="oivId"
                            label={LOCAL.LABELS.OIV}
                        >
                            <SelectControl
                                values={dictionaries?.oiv || []}
                                formMode={formMode}
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="organizationId"
                            label={LOCAL.LABELS.ORGANIZATION}
                        >
                            <SelectControl
                                values={dictionaries?.organization || []}
                                formMode={formMode}
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="departmentId"
                            label={LOCAL.LABELS.STRUCTURAL_UNIT}
                        >
                            <SelectControl
                                values={dictionaries?.department || []}
                                formMode={formMode}
                            />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="staffUnitId"
                            label={LOCAL.LABELS.POSITION}
                        >
                            <SelectControl
                                values={dictionaries?.staffUnits || []}
                            />
                        </FormItemAdapter>

                        <ButtonsToolbar align="flex-start">
                            <Button type="primary" htmlType="submit">
                                {LOCAL.ACTIONS.ACCEPT}
                            </Button>

                            <Button onClick={handleClose}>
                                {LOCAL.ACTIONS.CANCEL}
                            </Button>
                        </ButtonsToolbar>
                    </Form>
                </Popup>
            )
        }
    )
)
