import React, { useEffect } from 'react'
import { FeedbackQuizReportsService } from 'core/api'
import { NoChartData } from 'components/shared'
import { useHttp } from 'hooks'

import { ChartsBlockProps } from './ChartsBlock.types'
import { ChartsHistogramGroup } from '../ChartsHistogramGroup'
import { SemanticCharts } from '../SemanticCharts'

/** Компонент отрисовки графиков в назначениях опросов */
export const ChartsBlock: React.FC<ChartsBlockProps> = React.memo(
    ({ appointmentId, isSemantic = false }) => {
        const [getData, data] = useHttp(
            FeedbackQuizReportsService.getGraficsTab
        )

        useEffect(() => {
            getData({
                appointmentId,
                isSemantic,
            })
        }, [appointmentId, isSemantic, getData])

        const getChart = () => (
            <>
                {isSemantic
                    ? data?.map((chartData) => (
                          <SemanticCharts key={chartData.head} {...chartData} />
                      ))
                    : data?.map((chartData) => (
                          <ChartsHistogramGroup
                              key={chartData.head}
                              {...chartData}
                          />
                      ))}
            </>
        )

        return data?.length ? getChart() : <NoChartData />
    }
)
