import { isThemeColorLight } from 'contexts/ColorTheme/ColorTheme.utils'

import { AlignType, AnchorType, IndexAxis } from './SemanticCharts.conts.'
import { ChartSemanticOptionsProps } from './SemanticCharts.types'

export const getSemanticChartOptions = ({
    chartTitle,
    min,
    max,
    count,
    theme,
}: ChartSemanticOptionsProps) => ({
    scales: {
        xAxes: {
            max,
            min,
            grid: {
                drawBorder: false,
            },
        },

        yAxes: {
            display: false,
        },
    },

    plugins: {
        datalabels: {
            color: '#fff',
            anchor: count < 0 ? AnchorType.end : AnchorType.start,
            align: count < 0 ? AlignType.left : AlignType.right,
            padding: {
                left: 15,
            },
        },
        legend: {
            display: false,
        },
        title: {
            display: true,
            text: chartTitle,
            font: {
                size: 21,
                weight: 'bold',
            },
            color: isThemeColorLight(theme) ? '#000' : '#FFF',
        },
        tooltip: {
            enabled: count === 0,
            intersect: false,
        },
    },
    indexAxis: IndexAxis.y,
    maintainAspectRatio: false,
})

export const SEMANTIC_BACKGROUNDS = {
    Red: '#FF3B30',
    Green: '#3CC339',
}
