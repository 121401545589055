import cn from 'classnames'
import React, {
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useRef,
} from 'react'
import { ColorThemeContext } from 'contexts'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker } from 'components/controls'
import { StageProgress } from 'components/shared'
import { TooltipAdapter } from 'components/shared/TooltipAdapter'
import { eventEmitter } from 'core/helpers/eventEmitter'
import {
    getBlocksCountOfTrackStage,
    getFieldsPath,
    isDocumentStatusInWork,
} from 'utils'
import { useElementVisible } from 'hooks'

import styles from './TrackStageDnD.module.scss'
import { STAGE_STYLE_OPTIONS_INITIAL } from '../StagesDndArea/StagesDndArea.consts'
import { SingleStageVisualisationOptions } from '../SingleStageVisualisationOptions'
import { StageActions } from '../StageActions'
import {
    TRACK_STAGE_CLASS_NOT_DROPPED,
    TRACK_STAGE_STATUSES,
} from './TrackStageDnD.consts'
import { TRACK_STAGE_DND_EVENTS } from '../StagesDndArea/TrackStageDndHelper'
import { TrackStageDnDProps } from './TrackStageDnD.types'
import {
    composeStageIconStyles,
    getNextIconStatus,
} from './TrackStageDnD.utils'

/**
 * Компонент этапа трека, расположенного в настройках визуализации трека
 */
export const TrackStageDnD: React.FC<TrackStageDnDProps> = React.memo(
    ({
        index,
        droppableElem,
        stageStyle,
        stageOptions,
        form,
        mode,
        canvasKey,
    }) => {
        const { theme } = useContext(ColorThemeContext)

        const stageDraggablePart = useRef<HTMLDivElement>(null)

        const stageStatus = stageOptions?.iconStatus || TRACK_STAGE_STATUSES[0]

        const handleChangeStageState = useCallback(() => {
            form?.setFields([
                {
                    name: ['stages', index, 'iconStatus'],
                    value: getNextIconStatus(stageOptions?.iconStatus),
                },
            ])
        }, [form, index, stageOptions])

        /**
         * Обработчик удаления этапа с полотна
         */
        const handleRemoveStageFromCanvas = useCallback(() => {
            const updatedValue = {
                ...form?.getFieldValue(
                    getFieldsPath([
                        'trackCanvases',
                        canvasKey,
                        'trackStageStyles',
                        index,
                    ])
                ),
                positionStyles: null,
            }

            form?.setFields([
                {
                    name: [
                        'trackCanvases',
                        canvasKey,
                        'trackStageStyles',
                        index,
                    ],
                    value: updatedValue,
                },
            ])

            const dragElement = stageDraggablePart.current

            if (!dragElement) return

            const parentId = stageDraggablePart.current?.dataset.id
            const parentNode = document.querySelector(`[data-id="${parentId}"]`)

            parentNode?.append(dragElement)
            dragElement.removeAttribute('style')
            dragElement.classList.add(TRACK_STAGE_CLASS_NOT_DROPPED)

            eventEmitter.emit(
                TRACK_STAGE_DND_EVENTS.REMOVE_STAGE,
                form?.getFieldsValue()
            )
        }, [canvasKey, form, index])

        useLayoutEffect(() => {
            const injectedStyle = composeStageIconStyles(
                {
                    stageStyle,
                    stageStatus,
                    stageIndex: `${index}-${canvasKey}`,
                },
                theme
            )

            const removeInjectedStyle = () => {
                injectedStyle?.remove()
            }

            if (!stageDraggablePart.current) return removeInjectedStyle

            if (!stageStyle?.positionStyles) {
                stageDraggablePart.current.classList.add(
                    TRACK_STAGE_CLASS_NOT_DROPPED
                )

                return removeInjectedStyle
            } else if (
                stageDraggablePart.current.classList.contains(
                    TRACK_STAGE_CLASS_NOT_DROPPED
                )
            ) {
                stageDraggablePart.current.classList.remove(
                    TRACK_STAGE_CLASS_NOT_DROPPED
                )
            }

            droppableElem?.appendChild(stageDraggablePart.current)

            return removeInjectedStyle
        }, [
            droppableElem,
            index,
            stageStyle,
            theme,
            stageOptions,
            canvasKey,
            stageStatus,
            form,
        ])

        useLayoutEffect(() => {
            const draggablePart = stageDraggablePart.current

            return () => {
                draggablePart?.remove()
            }
        }, [])

        const {
            elementVisible,
            toggleElementVisible,
            toggleElementVisibleRef,
            elementRef,
        } = useElementVisible()

        const composeStagePositionStyles = () => {
            const styles = stageStyle?.positionStyles

            if (!styles) return

            const parseStyles = JSON.parse(styles)

            if (!parseStyles) return

            return {
                ...parseStyles,
                ...STAGE_STYLE_OPTIONS_INITIAL,
            }
        }

        /**
         * Показываваем прогресс только когда этап позиционирован
         * и включена опция "Отображать прогресс"
         */
        const showProgress = useMemo(() => {
            if (stageStyle?.positionStyles) return stageOptions?.showProgress

            return false
        }, [stageStyle, stageOptions])

        return (
            <div
                className={styles.wrapperTuple}
                data-id={`${index}-${canvasKey}`}
            >
                <div className={cn(styles.wrapper, styles.wrapperPlaceholder)}>
                    <div
                        className={cn(
                            styles.img,
                            elementVisible && styles.imgActive
                        )}
                    >
                        <IconsAdapter iconType="CustomIconNoImg" />
                    </div>

                    <span>{`${LOCAL.LABELS.STAGE} ${index + 1}`}</span>
                </div>

                <ShouldUpdateChecker fieldPath={['status']}>
                    {({ getFieldValue }) => (
                        <div
                            className={cn(
                                styles.wrapper,
                                styles.wrapperOriginal
                            )}
                            data-id={`${index}-${canvasKey}`}
                            style={composeStagePositionStyles()}
                            ref={stageDraggablePart}
                        >
                            <TooltipAdapter
                                title={
                                    stageOptions?.title ||
                                    `${LOCAL.LABELS.STAGE} ${index + 1}`
                                }
                            >
                                <div className={styles.img}>
                                    <StageActions
                                        ref={toggleElementVisibleRef}
                                        onToggleMenu={toggleElementVisible}
                                        onRemoveStageFromCanvas={
                                            handleRemoveStageFromCanvas
                                        }
                                        onChangeStageState={
                                            handleChangeStageState
                                        }
                                        mode={mode}
                                        isInWorkStatus={isDocumentStatusInWork(
                                            getFieldValue('status')
                                        )}
                                        className={
                                            styles.trackStageDndStageAction
                                        }
                                    />
                                </div>
                            </TooltipAdapter>

                            {showProgress && (
                                <StageProgress
                                    stageCount={getBlocksCountOfTrackStage(
                                        stageOptions?.materialType,
                                        stageOptions?.totalThematicBlocksCount
                                    )}
                                    currentStage={0}
                                />
                            )}
                        </div>
                    )}
                </ShouldUpdateChecker>

                {elementVisible && (
                    <SingleStageVisualisationOptions
                        onChangeStageOptionsVisible={toggleElementVisible}
                        stageIndex={index}
                        form={form}
                        elementRef={elementRef}
                        canvasKey={canvasKey}
                        stageStatus={stageStatus}
                    />
                )}
            </div>
        )
    }
)
