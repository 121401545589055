// TODO: в последствии, когда бэк будет подготовлен,
// все консоль логи будут заменены на реальную логику взаимодействия с LMS

import { ScormService } from 'core/api'

/**
 * Адаптер API для SCORM версии 1.2
 */
export class Scorm12Adapter {
    _courseId: number

    constructor(courseId: number) {
        this._courseId = courseId
    }

    async LMSInitialize(): Promise<boolean> {
        console.info('Scorm 1.2 - LMSInitialize')
        console.log('Инициализация подключения к LMS')

        await ScormService.initialize({
            body: { courseId: this._courseId },
        })

        return true
    }

    async LMSFinish(): Promise<boolean> {
        console.info('Scorm 1.2 - LMSInitialize')
        console.log('Закрытие подключения к LMS')

        await ScormService.terminate({
            body: { courseId: this._courseId },
        })

        return true
    }

    async LMSGetValue(key: string): Promise<string> {
        console.info('Scorm 1.2 - LMSGetValue')
        console.log(`Получение значения для элемента данных: ${key}`)

        await ScormService.getValue({
            body: {
                courseId: this._courseId,
                key,
            },
        })

        return key
    }

    async LMSSetValue(key: string, value: string): Promise<string> {
        console.info('Scorm 1.2 - LMSSetValue')
        console.log(`Установка значения (${value}) для элемента: ${key}`)

        await ScormService.setValue({
            body: {
                courseId: this._courseId,
                key,
                value,
            },
        })

        return key
    }

    LMSCommit(): boolean {
        console.info('Scorm 1.2 - LMSCommit')
        console.log('Отправка данных в LMS')

        return true
    }

    LMSGetLastError(): number | undefined {
        console.info('Scorm 1.2 - LMSGetLastError')

        return
    }

    LMSGetErrorString(errorCode: number): string {
        console.info('Scorm 1.2 - LMSGetErrorString', errorCode)

        return ''
    }

    LMSGetDiagnostic(errorCode: number): string {
        console.info('Scorm 1.2 - LMSGetDiagnostic', errorCode)

        return ''
    }
}
