import React from 'react'
import groupBy from 'lodash/groupBy'
import {
    AssessmentProjectCompetenceContract,
    AssessmentProjectManagerAppointmentAssessmentContract,
    AssessmentProjectPositionProfileContract,
    CompetenceContract,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { ColumnsType } from 'antd/lib/table'
import { FnActionProps, WithFormModeProps } from 'App.types'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { getItemName, getUserShortInfo, isFormModeView } from 'utils'

import styles from './AssessmentProjectsManagerEstimate.module.scss'
import {
    AssesmentProjectCompetenceFormContract,
    CompetenciesCommonContract,
    UserFormContract,
} from './AssessmentProjectsManagerEstimate.types'

/** нормализация данных, полученных через `getEntitiesByIds`, для `value` и внутреннего стейта компонента
 * @param newData - новые выбранные данные
 * @param oldData - данные, которые были выбраны в прошлый раз
 */
export const normalizeFetchDataForValue = (
    newData: AssessmentProjectPositionProfileContract[],
    oldData?: UserFormContract[]
): UserFormContract[] =>
    newData.map((el) => {
        const oldEl = oldData?.find((oldEl) => oldEl.id === el.candidate.id)

        return {
            ...getUserShortInfo(el.candidate),
            selfAssessment: oldEl?.selfAssessment,
            competencies: oldEl?.competencies || el.candidateCompetencies,
            positionProfileId:
                oldEl?.positionProfileId ||
                el.candidateCompetencies?.[0]?.positionProfileId,
            positionName: oldEl?.positionName || el.positionName,
        }
    })

export function mapCompetenciesByGroup(
    competencies: CompetenciesCommonContract[],
    profileId?: number
): AssesmentProjectCompetenceFormContract[]
export function mapCompetenciesByGroup(
    competencies: AssessmentProjectCompetenceContract[]
): AssesmentProjectCompetenceFormContract[]

/** Получить компетенции по группам
 * @param competencies - список компетенций
 * @param profileId - id профиля должности по которому были добавлены компетенции
 */
export function mapCompetenciesByGroup(
    competencies: any[] = [],
    profileId?: number
) {
    const collectionByGroupName = groupBy(
        competencies,
        (competence) => competence.competenceGroup.name
    )

    return Object.entries(collectionByGroupName)
        .map(([, competencies]) =>
            competencies.map(
                (
                    {
                        competence,
                        id,
                        name,
                        competenceGroup,
                        positionProfileId,
                        hasQuestionnaire,
                    },
                    index
                ) => ({
                    competence: competence || { id, name },
                    competenceGroup: !index ? competenceGroup : undefined,
                    positionProfileId: profileId || positionProfileId,
                    hasQuestionnaire,
                })
            )
        )
        .flat()
}

/** нормализация данных, начальных значений формы (при редактировании, просмотре), для `value` и внутреннего стейта*/
export const normalizeInitialDataForValue = (
    data: AssessmentProjectManagerAppointmentAssessmentContract[]
): UserFormContract[] =>
    data?.map(({ candidate, candidateCompetencies, ...restValues }) => ({
        ...getUserShortInfo(candidate),
        competencies: mapCompetenciesByGroup(candidateCompetencies),
        ...restValues,
    }))

/** Получить колонки компетенций с возможностью удаления */
export const getCompetenciesColumns = (
    handleRemoveRow: (id: number) => FnActionProps,
    formMode?: WithFormModeProps['formMode']
): ColumnsType<AssesmentProjectCompetenceFormContract> => [
    {
        title: LOCAL.LABELS.COMPETENCIES_GROUP,
        dataIndex: 'competenceGroup',
        render: getItemName,
    },
    {
        title: LOCAL.LABELS.COMPETENCIES,
        dataIndex: 'competence',
        key: 'competence',
        render: (competence, row) => (
            <span
                className={
                    // TODO: Пересмотреть реализацию подсветки компетенций по которым не были сформированы опросные листы
                    row.hasQuestionnaire === false
                        ? styles.competenceWithoutQuestionnarie
                        : undefined
                }
            >
                {competence.name}
            </span>
        ),
    },
    {
        width: 50,
        align: 'right',
        render: (_, row) => (
            <Button
                type="link"
                icon={<IconsAdapter iconType="DeleteOutlined" />}
                onClick={handleRemoveRow(row.competence.id)}
                disabled={isFormModeView(formMode)}
            />
        ),
    },
]

/** Маппинг добавленных компетенций учитывая выбранные значения
 * @param competencies - добавленные компетенции
 * @param oldCompetencies - компетенции которые были выбраны в прошлый раз
 */
export const normalizeFetchDataCompetencies = (
    competencies: CompetenceContract[],
    oldCompetencies?: AssesmentProjectCompetenceFormContract[]
) =>
    competencies.map((competence) => ({
        ...(oldCompetencies?.find(
            (oldCompetence) => oldCompetence.competence.id === competence.id
        ) || {}),
        ...competence,
    }))
