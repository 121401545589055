import styles from 'components/pages/Track/components/TrackForm/components/shared/TrackStageDnD/TrackStageDnD.module.scss'
import { AppointmentTrackStatus, TrackStagePassingContract } from 'core/api'
import { InfoAssistantProps } from 'components/shared/InfoAssistant/InfoAssistant.types'
import {
    isAppointmentTrackStatusCanceled,
    isAppointmentTrackStatusDraft,
    isAppointmentTrackStatusInWork,
    isAppointmentTrackStatusNotPassed,
    isAppointmentTrackStatusPassed,
} from 'utils'

/** Необходимо ли запустить старт трека
 * @startParam - url параметр прохождения трека
 */
export const isTrackNeedToBeStarted = (startParam?: string) =>
    startParam === 'start'

export const getFirstNotPassedStage = (stages: TrackStagePassingContract[]) =>
    stages?.find(
        ({ status }) =>
            isAppointmentTrackStatusDraft(status) ||
            isAppointmentTrackStatusInWork(status)
    )

export function getFirstAvailableStage(
    stages?: TrackStagePassingContract[]
): TrackStagePassingContract | undefined
export function getFirstAvailableStage(
    stages?: TrackStagePassingContract[],
    returnIndex?: true
): number | undefined
export function getFirstAvailableStage(
    stages?: TrackStagePassingContract[],
    returnIndex?: true
) {
    function findFn({ status }: TrackStagePassingContract) {
        return (
            isAppointmentTrackStatusInWork(status) ||
            isAppointmentTrackStatusDraft(status)
        )
    }

    return returnIndex ? stages?.findIndex(findFn) : stages?.find(findFn)
}

export function scrollToFirstAvailableStage(
    stages?: TrackStagePassingContract[]
) {
    setTimeout(() => {
        const firstAvailableNotStartedStage = getFirstAvailableStage(
            stages,
            true
        )
        const stageToScroll = document.querySelector(
            `.${styles.wrapper}[data-id^="${firstAvailableNotStartedStage}"]`
        )

        stageToScroll?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        })
    })
}

export const getFirstPassedStage = (stages: TrackStagePassingContract[]) =>
    stages?.find(({ status }) => isAppointmentTrackStatusPassed(status))

export const getStageByNumber = (
    num?: number,
    stages?: TrackStagePassingContract[]
) => stages?.find(({ stageNumber }) => stageNumber === num)

export const isAppointmentPassedView = (status?: AppointmentTrackStatus) =>
    isAppointmentTrackStatusCanceled(status) ||
    isAppointmentTrackStatusNotPassed(status) ||
    isAppointmentTrackStatusPassed(status)

export const onSetInfoAssitantVisible = (
    assitantData: InfoAssistantProps | undefined,
    visible = false
) => ({ ...assitantData, visible })
