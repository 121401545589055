import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { MissionSearchResultContract } from 'core/api'
import { defaultSorter } from 'components/layouts'
import { formatDate, restrictBigText } from 'utils'

import { MaterialTasksTableActions } from './components/MaterialTasksTableActions'

export const INITIAL_VALUES = {
    missionTasks: [{ taskNumber: 1 }],
    content: '<h2>Заголовок</h2>\n <p>Введите текст задания....</p>',
}

export const MATERIAL_TASKS_TABLE_COLUMNS: ColumnsType<MissionSearchResultContract> = [
    {
        title: LOCAL.LABELS.TASK_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.OWNER_NAME,
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: defaultSorter,
        render: restrictBigText,
    },
    {
        title: LOCAL.LABELS.DATE_CREATION,
        dataIndex: 'creationDate',
        key: 'creationDate',
        render: formatDate,
        sorter: defaultSorter,
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => <MaterialTasksTableActions tableRow={tableRow} />,
        fixed: 'right',
    },
]
