import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { ElementWithListPopover } from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { ORDER_RULES_WITH_CHANGE_DATE, defaultSorter } from 'components/layouts'
import { ThemeContract } from 'core/api'
import {
    extractNames,
    formatDate,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { ThemeForm, ThemesTableActions } from './components'

/** Настройки отображения колонок для таблицы реестра тематик */
export const THEMES_TABLE_COLUMNS: ColumnsType<ThemeContract> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.PRODUCT_COUNT,
        dataIndex: 'productCardCount',
        key: 'productCardCount',
        render: (productCardCount, record) => (
            <ElementWithListPopover
                title={productCardCount}
                items={extractNames(record.productCards)}
                options={{
                    showPopoverAlways: true,
                }}
            />
        ),
        width: '250px',
    },
    {
        title: LOCAL.LABELS.AUTHOR_FIO,
        dataIndex: ['changeUser', 'name'],
        key: 'changeUser',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => <ThemesTableActions tableRow={tableRow} />,
        align: 'right',
        fixed: 'right',
    },
]

export const CREATE_RECORD_MODAL_PROPS = {
    component: ThemeForm,
    formId: FORM_IDS.THEME_FORM,
    modalOptions: {
        title: LOCAL.LABELS.THEMATIC_SECTIONS_CREATION,
        okText: LOCAL.ACTIONS.SAVE,
        width: MODAL_WIDTH.MD,
    },
}

export const TABLE_FILTERS_OPTIONS_PROPS = {
    initialValues: {
        orderRules: ORDER_RULES_WITH_CHANGE_DATE,
    },
}
