import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { ComponentCommonProps } from 'App.types'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import {
    PositionProfileArchivesService,
    PositionProfileContract,
    PositionProfilePublishContract,
    PositionProfilesService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import {
    isDocumentStatusInWork,
    isDocumentStatusReadyForWork,
    isFormModeCopy,
    isFormModeView,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useFormInitialValues, useSaveOrPublishState } from 'hooks'
import { useHistory, useParams } from 'react-router-dom'

import { JOB_PROFILE_FORM_ID } from './components/JobProfileForm/JobProfileForm.consts'
import { JobProfileForm } from './components'
import {
    JobProfileHistoryStateProps,
    JobProfilesUrlParamsProps,
} from './JobProfiles.types'
import { MapFormDataToRequestProps } from './components/JobProfileForm/JobProfileForm.types'
import {
    getStructuralUnitIdDependingOnType,
    isFromUserProfile,
} from './JobProfiles.utils'

/**
 * Создание профиля должности
 */
const JobProfileFormContainer: React.FC<
    WithBlockUserRoutingComponentProps & ComponentCommonProps
> = withLoader(({ onBlockUserRouting, formMode, updateLoader, isLoading }) => {
    const urlParams = useParams<JobProfilesUrlParamsProps>()
    const history = useHistory<JobProfileHistoryStateProps>()

    const [archivalJobProfile, setArchivalJobProfile] = useState<
        PositionProfileContract
    >()

    const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()

    const {
        initialValuesForEdit,
        fetchInitialFormValuesById,
    } = useFormInitialValues<PositionProfileContract>({
        requestMethod: PositionProfilesService.get,
        updateLoader,
    })

    /** Начальные значения для формы создания профиля*/
    const [initialValuesForAdd, setInitialValuesForAdd] = useState<
        MapFormDataToRequestProps
    >()

    const handleCancel = useCallback(() => {
        if (isFromUserProfile(history)) {
            history.goBack()
        }

        history.push(ROUTE_NAMES.POSITION_PROFILES)
    }, [history])

    const backLinkText = useMemo(() => {
        if (!isFromUserProfile(history)) return

        return `${LOCAL.LINKS.PREFIX} "${LOCAL.CATALOGS.USER_PROFILE}"`
    }, [history])

    /**
     * Запрос архивного профиля должности
     */
    const fetchArchivalJobProfile = useCallback(
        async (id: number, version: number) => {
            try {
                updateLoader(true)
                setArchivalJobProfile(
                    await PositionProfileArchivesService.get({
                        id,
                        version,
                    })
                )
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        [updateLoader]
    )

    /**
     * Флаг - создание профиля должности из справочника организаций и должностей
     */
    const isFromOrganizationsCatalog = useMemo(
        () =>
            urlParams.positionId &&
            urlParams.structuralUnitId &&
            urlParams.structuralUnitType,
        [
            urlParams.positionId,
            urlParams.structuralUnitId,
            urlParams.structuralUnitType,
        ]
    )

    const isStaffUnitIdParam =
        urlParams.structuralUnitId &&
        !urlParams.positionId &&
        !urlParams.structuralUnitType

    useEffect(() => {
        if (urlParams.profileId && urlParams.version) {
            fetchArchivalJobProfile(
                Number(urlParams.profileId),
                Number(urlParams.version)
            )
        }

        if (urlParams.profileId)
            fetchInitialFormValuesById(Number(urlParams.profileId))

        if (isFromOrganizationsCatalog) {
            setInitialValuesForAdd({
                positionId: Number(urlParams.positionId),
                ...getStructuralUnitIdDependingOnType(
                    Number(urlParams.structuralUnitId),
                    urlParams.structuralUnitType
                ),
            } as PositionProfilePublishContract)
        }

        if (isStaffUnitIdParam) {
            setInitialValuesForAdd({
                staffUnitId: Number(urlParams.structuralUnitId),
            } as PositionProfilePublishContract)
        }
    }, [
        fetchArchivalJobProfile,
        fetchInitialFormValuesById,
        isFromOrganizationsCatalog,
        isStaffUnitIdParam,
        urlParams.positionId,
        urlParams.profileId,
        urlParams.structuralUnitId,
        urlParams.structuralUnitType,
        urlParams.version,
    ])

    const isDisabledActionsButtons = isFormModeView(formMode) || isLoading
    const isArchive = initialValuesForEdit?.active === false
    const isShowsEditButton =
        isFormModeView(formMode) &&
        !isDocumentStatusInWork(initialValuesForEdit?.status) &&
        !isArchive

    return (
        <PageContent>
            <ContentTitleWithBackLink
                status={initialValuesForEdit?.status}
                isArchive={isArchive}
                backLinkText={backLinkText}
                onBack={handleCancel}
            >
                <ButtonsToolbar>
                    <Button type="link" onClick={handleCancel}>
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    <HasPermissions
                        requiredPermissions={[
                            UserPermission.PositionProfileSave,
                        ]}
                        accessDeniedRender={renderAccessDeniedButton({
                            text: LOCAL.ACTIONS.SAVE,
                            type: 'primary',
                        })}
                    >
                        <Button
                            form={JOB_PROFILE_FORM_ID}
                            htmlType="submit"
                            type="primary"
                            onClick={handleSubmitForm(true)}
                            disabled={
                                (isDocumentStatusReadyForWork(
                                    initialValuesForEdit?.status
                                ) &&
                                    !isFormModeCopy(formMode)) ||
                                isDisabledActionsButtons
                            }
                        >
                            {LOCAL.ACTIONS.SAVE}
                        </Button>
                    </HasPermissions>

                    <HasPermissions
                        requiredPermissions={[
                            UserPermission.PositionProfilePublish,
                        ]}
                        accessDeniedRender={renderAccessDeniedButton({
                            text: LOCAL.ACTIONS.PUBLISH,
                            type: 'primary',
                        })}
                    >
                        <Button
                            form={JOB_PROFILE_FORM_ID}
                            htmlType="submit"
                            type="primary"
                            onClick={handleSubmitForm(false)}
                            disabled={isDisabledActionsButtons}
                        >
                            {LOCAL.ACTIONS.PUBLISH}
                        </Button>
                    </HasPermissions>

                    {isShowsEditButton && (
                        <HasPermissions
                            requiredPermissions={[
                                UserPermission.PositionProfileSave,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                text: LOCAL.ACTIONS.EDIT,
                                type: 'primary',
                            })}
                        >
                            <Button
                                type="link"
                                href={`${ROUTE_NAMES.POSITION_PROFILES_EDIT}/${initialValuesForEdit?.id}`}
                            >
                                {LOCAL.ACTIONS.EDIT}
                            </Button>
                        </HasPermissions>
                    )}
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <JobProfileForm
                updateLoader={updateLoader}
                onBlockUserRouting={onBlockUserRouting}
                archivalJobProfile={archivalJobProfile}
                initialValuesForEdit={initialValuesForEdit}
                initialValuesForAdd={initialValuesForAdd}
                isSaveDocument={isSaveDocument}
                reFetchQuizPortfolio={fetchInitialFormValuesById}
                formMode={formMode}
            />
        </PageContent>
    )
})

export default React.memo(
    withBlockUserRouting(JobProfileFormContainer, false)
) as typeof JobProfileFormContainer
