import React from 'react'
import {
    AppointmentTrackRespondentContract,
    UserContract,
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import { DynamicTableAdapter } from 'components/shared/DynamicTableAdapter'

import {
    AddUsersTableControlProps,
    UserFormContract,
} from './AddUsersTableControl.types'
import { COWORKERS_CONTROL_TABLE_COLUMNS } from './AddUsersTableControl.consts'
import {
    CoworkersFilters,
    getCoworkersTableColumns,
} from '../SearchSelectControl/components'
import {
    normalizeFetchDataForImportedValue,
    normalizeFetchDataForValue,
} from './AddUsersTableControl.utils'

/** Контрол для добавления удаления пользователей, в виде таблицы */
export const AddUsersTableControl: React.FC<AddUsersTableControlProps> = ({
    tableOptions,
    ...restProps
}) => (
    <DynamicTableAdapter<
        UserContract,
        UserFormContract,
        UserModalSearchContract,
        UserModalContract,
        AppointmentTrackRespondentContract
    >
        getEntitiesByIds={UsersService.getByIds}
        searchMethod={UsersService.getForModal}
        modalFilterComponent={CoworkersFilters}
        columnsForSearchModal={getCoworkersTableColumns()}
        normalizeFn={normalizeFetchDataForValue}
        noralizeImportFn={normalizeFetchDataForImportedValue}
        tableOptions={{
            ...tableOptions,
            columns: COWORKERS_CONTROL_TABLE_COLUMNS,
        }}
        {...restProps}
    />
)
