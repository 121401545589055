import React, { useCallback, useEffect, useState } from 'react'
import { Store } from 'App.types'

import { ButtonsListControl } from '../ButtonsListControl'
import { QuestionnaireListGrouped } from '../QuestionnaireListGrouped'
import { TestPassingByBlockProps } from './TestPassingByBlock.types'
import {
    getBlockedByQuestionEndToEndNumber,
    getQuestionsListsState,
    getSortedQuestionsLists,
    isQuestionsListCompletedFn,
} from './TestPassingByBlock.utils'

export const TestPassingByBlock: React.FC<TestPassingByBlockProps> = React.memo(
    ({ form, passingData, answers, ...restProps }) => {
        const [selectedListIndex, setSelectedListIndex] = useState(0)

        /** Коллекция, где ключ - это индекс опросного листа, а значение - признак ответа на все обязательные вопросы */
        const [listState, setListate] = useState<Store<boolean>>()

        const questionnaireListQuestions = getSortedQuestionsLists(
            passingData.questionnaire.groupedQuestions
        )

        const isQuestionsListCompleted = useCallback(
            (listIndex: number) =>
                isQuestionsListCompletedFn(listIndex, listState),

            [listState]
        )

        useEffect(() => {
            setListate(
                getQuestionsListsState(
                    getSortedQuestionsLists(
                        passingData.questionnaire.groupedQuestions
                    ),
                    restProps.confirmedAnswers
                )
            )
        }, [
            passingData.questionnaire.groupedQuestions,
            restProps.confirmedAnswers,
        ])

        return (
            <>
                <QuestionnaireListGrouped
                    {...restProps}
                    selectedListIndex={selectedListIndex}
                    setSelectedListIndex={setSelectedListIndex}
                    questionnaireListQuestions={questionnaireListQuestions}
                    getBlockedByQuestionEndToEndNumber={getBlockedByQuestionEndToEndNumber(
                        questionnaireListQuestions[selectedListIndex],
                        passingData
                    )}
                    isQuestionsListCompleted={isQuestionsListCompleted}
                />

                <ButtonsListControl
                    selectedListIndex={selectedListIndex}
                    setSelectedListIndex={setSelectedListIndex}
                    isQuestionsListCompleted={isQuestionsListCompleted}
                    passingData={passingData}
                    answers={answers}
                    form={form}
                    onConfirmAnswers={restProps.onConfirmAnswers}
                    isLastQuestionsList={
                        selectedListIndex ===
                        questionnaireListQuestions.length - 1
                    }
                />
            </>
        )
    }
)
