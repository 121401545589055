import React, { useCallback, useEffect } from 'react'
import { AssessmentPortfolioSearchContract } from 'core/api'
import { Button } from 'components/shared/Button'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps } from 'utils'
import { Form } from 'antd'
import { FormItemAdapter } from 'components/controls'
import { InputControl } from 'components/controls/InputControl'
import { LOCAL } from 'core/local'
import {
    LOCAL_STORAGE_KEYS,
    LocalStorageHelper,
} from 'core/helpers/LocalStorage'
import { ROUTE_NAMES } from 'routing/routeNames.consts'

/** Форма фильтрации модального окна выбора портфелей оценки */
export const AssessmentPortfoliosFilter: React.FC<FiltersProps<
    AssessmentPortfolioSearchContract
>> = React.memo(({ onSetFilters, ...props }) => {
    const [form] = Form.useForm()

    useEffect(() => {
        const onAssessmentPortfolioCreated = (event: StorageEvent) => {
            if (event.key === LOCAL_STORAGE_KEYS.ASSESSMENT_PORTFOLIO_CREATED) {
                form.setFieldsValue({ name: '' })
                onSetFilters({})
            }
        }

        LocalStorageHelper.registerHandler(onAssessmentPortfolioCreated)

        return () =>
            LocalStorageHelper.unRegisterHandler(onAssessmentPortfolioCreated)
    }, [form, onSetFilters])

    /**
     * Открыть страницую создания портфеля оценки
     */
    const handleOpenCreatePortfolioPage = useCallback(() => {
        window.open(ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_CREATE, '_blank')
    }, [])

    return (
        <FilterForm {...props} onSetFilters={onSetFilters} form={form}>
            <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                <InputControl />
            </FormItemAdapter>

            <Button onClick={handleOpenCreatePortfolioPage}>
                {LOCAL.ACTIONS.CREATE}
            </Button>
        </FilterForm>
    )
})
