import React, { FC, useCallback, useEffect } from 'react'
import {
    CheckboxControl,
    ControlGroup,
    CoworkersFilters,
    FormItemAdapter,
    InputControl,
    SearchSelectControl,
    SelectControl,
    getCoworkersTableColumns,
    getUserByIdsMethod,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import {
    DictionaryContract,
    NotificationMailingInformationsService,
    NotificationMailingTaskStatus,
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { LOCAL } from 'core/local'
import { MAILING_STATUSES_MAPPED, MAILING_TYPES_MAPPED } from 'consts'
import { ROW_GUTTER } from 'consts/layout.consts'
import { Store } from 'App.types'
import {
    excludeSelectOptions,
    getValue,
    normalizeDataForSelectAndRadioWithoutUniqueId,
} from 'utils'
import { useHttp } from 'hooks'
import { withLoader } from 'HOCs'

import { DistributionMailingFiltersProps } from './DistributionMailingFilters.types'

export const DistributionMailingFilter: FC<DistributionMailingFiltersProps> = withLoader(
    React.memo(({ updateLoader, isLoading, ...props }) => {
        const [getMails, mails] = useHttp<DictionaryContract[]>(
            NotificationMailingInformationsService.getMailsForSelect,
            updateLoader
        )

        useEffect(() => {
            getMails()
        }, [getMails])

        const mapFormDataToRequest = useCallback(
            (values: Store) => ({
                ...values,
                recipientMails: values.recipientMails?.map(getValue),
                recipientIds: values.recipientIds?.map(getValue),
            }),
            []
        )

        return (
            <ControlGroup type="light">
                <FilterForm
                    {...props}
                    mapFormDataToRequest={mapFormDataToRequest}
                >
                    <Row gutter={ROW_GUTTER}>
                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="name"
                                label={LOCAL.LABELS.NAME_MAILING_INFORMATION}
                            >
                                <InputControl />
                            </FormItemAdapter>
                        </Col>

                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="statuses"
                                label={LOCAL.LABELS.STATUS_SENDING}
                            >
                                <SelectControl
                                    mode="multiple"
                                    values={excludeSelectOptions(
                                        MAILING_STATUSES_MAPPED,
                                        [
                                            NotificationMailingTaskStatus.Suspended,
                                            NotificationMailingTaskStatus.Draft,
                                        ]
                                    )}
                                    showSearch
                                />
                            </FormItemAdapter>
                        </Col>

                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="types"
                                label={LOCAL.LABELS.MAILING_TYPE}
                            >
                                <SelectControl
                                    mode="multiple"
                                    values={MAILING_TYPES_MAPPED}
                                    showSearch
                                />
                            </FormItemAdapter>
                        </Col>
                    </Row>

                    <Row gutter={ROW_GUTTER}>
                        <Col xs={8}>
                            <FromToDatesFilter
                                from={{
                                    label: LOCAL.LABELS.DATE_MAILING_FROM,
                                    fieldName: 'mailingDateStart',
                                }}
                                to={{
                                    label: LOCAL.LABELS.DATE_MAILING_TO,
                                    fieldName: 'mailingDateEnd',
                                }}
                            />
                        </Col>

                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="recipientIds"
                                label={LOCAL.LABELS.RECIPIENT}
                            >
                                <SearchSelectControl<
                                    UserModalSearchContract,
                                    UserModalContract
                                >
                                    getByIdsMethod={getUserByIdsMethod}
                                    selectMode="multiple"
                                    tableSearchOptions={{
                                        searchMethod: UsersService.getForModal,
                                        tableColumns: getCoworkersTableColumns(),
                                        filterComponent: CoworkersFilters,
                                    }}
                                />
                            </FormItemAdapter>
                        </Col>

                        <Col xs={8}>
                            <FormItemAdapter
                                fieldName="recipientMails"
                                label={LOCAL.CATALOGS.RECIPIENTS_MAIL}
                            >
                                <SelectControl
                                    mode="multiple"
                                    showSearch
                                    allowClear
                                    labelInValue
                                    values={
                                        mails?.map(
                                            normalizeDataForSelectAndRadioWithoutUniqueId
                                        ) || []
                                    }
                                />
                            </FormItemAdapter>
                        </Col>

                        <Col xs={8}>
                            <FromToDatesFilter
                                from={{
                                    label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                    fieldName: 'changeDateStart',
                                }}
                                to={{
                                    label: LOCAL.LABELS.DATE_CHANGE_TO,
                                    fieldName: 'changeDateEnd',
                                }}
                            />
                        </Col>
                    </Row>

                    <Form.Item name="onlyWithErrors" noStyle>
                        <CheckboxControl>
                            {LOCAL.LABELS.ONLY_WITH_ERRORS}
                        </CheckboxControl>
                    </Form.Item>
                </FilterForm>
            </ControlGroup>
        )
    })
)
