import React, { useCallback } from 'react'
import { AppointmentStatus, AppointmentUserSearchContract } from 'core/api'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    DatePickerControl,
    FormItemAdapter,
    InputControl,
} from 'components/controls'
import { FilterForm } from 'components/shared/FilterForm'
import { FiltersProps, getConditionalArray } from 'utils'
import { LOCAL } from 'core/local'
import { ROW_GUTTER } from 'consts'
import { Store } from 'App.types'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'

import styles from './QuestionnairesListenerFilters.module.scss'
import { QUESTIONNAIRES_LISTENER_INITIAL_VALUES } from '../../QuestionnairesListenerPage.config'

/**
 * Форма фильтрации назначенных оценок на слушателя
 */
export const QuestionnairesListenerFilters: React.FC<FiltersProps<
    AppointmentUserSearchContract
>> = React.memo((props) => {
    const mapFormDataToRequest = useCallback(
        (values: Store) => ({
            ...values,
            statuses: [
                ...QUESTIONNAIRES_LISTENER_INITIAL_VALUES.statuses,
                ...getConditionalArray(
                    values.showArchive,
                    AppointmentStatus.Ended
                ),
            ],
            name: values.name,
            periodFrom: values.evaluationPeriodFrom,
            periodTo: values.evaluationPeriodTo,
        }),
        []
    )

    return (
        <ControlGroup type="light" className={styles.wrapper}>
            <FilterForm
                {...props}
                mapFormDataToRequest={mapFormDataToRequest}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={24} md={12}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.BY_NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={24} md={6}>
                        <FormItemAdapter
                            fieldName="evaluationPeriodFrom"
                            label={LOCAL.LABELS.DATE_START_FROM}
                        >
                            <DatePickerControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={24} md={6}>
                        <FormItemAdapter
                            fieldName="evaluationPeriodTo"
                            label={LOCAL.LABELS.DATE_END_TO}
                        >
                            <DatePickerControl />
                        </FormItemAdapter>
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
