import React, { FC, useCallback } from 'react'
import { AppError, PositionProfilesService } from 'core/api'
import { Form } from 'antd'
import { HiddenField } from 'components/controls'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { withLoader } from 'HOCs'

/** Форма отправки записи в работу */
export const JobProfileInWorkForm: FC<PopupAdapterFormProps> = withLoader(
    React.memo(({ initialValues, id, onRequestFinish, updateLoader }) => {
        /**
         * Отправка формы
         */
        const handleFinish = useCallback(
            async (values) => {
                try {
                    updateLoader(true)

                    await PositionProfilesService.inWork({
                        id: values.id as number,
                    })

                    onRequestFinish?.()
                } catch (error) {
                    onRequestFinish?.(error as AppError)
                } finally {
                    updateLoader(false)
                }
            },
            [onRequestFinish, updateLoader]
        )

        return (
            <Form id={id} initialValues={initialValues} onFinish={handleFinish}>
                {'Профиль должности нельзя изменить после отправки в работу.'}

                {'Уверены, что хотите продолжить?'}

                <HiddenField fieldName="id" />
            </Form>
        )
    })
)
