import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Form } from 'antd'
import {
    ProductCardEnrollmentStatus,
    ProductCardListenerContract,
    ProductCardReserveEnrollmentListenerContract,
    ProductCardStudyingPeriodContract,
} from 'core/api'
import { SwitchControl } from 'components/controls'
import { getFullFio } from 'utils'

import styles from '../ProductListenersForm/ProductListenersForm.module.scss'

export const getProductListenersColumns = (
    selectedGroupIndex: number,
    disabled: boolean
): ColumnsType<Partial<ProductCardListenerContract>> => [
    {
        dataIndex: 'listener',
        key: 'listener',
        render: (_, row) => (
            <div>
                <p className={styles.listener}>{getFullFio(row.listener)}</p>
                <p>{row.position?.name}</p>
            </div>
        ),
        width: '50%',
    },
    {
        dataIndex: 'organization',
        key: 'organization',
        render: (_, row) => (
            <div className={styles.structuralUnits}>
                <p>{row.parentStructuralUnit?.name}</p>
                <p>{row.structuralUnit?.name}</p>
            </div>
        ),
        width: '50%',
    },
    {
        title: '',
        dataIndex: '',
        key: '',
        width: 50,
        render: (_, value, index) =>
            value.productCardStudyingPeriodId && (
                <Form.Item
                    noStyle
                    name={[
                        'groups',
                        selectedGroupIndex,
                        'listeners',
                        index,
                        'isEnrolled',
                    ]}
                >
                    <SwitchControl disabled={disabled} />
                </Form.Item>
            ),
    },
]

/** Добавить резерв в группу обучения продукта */
export const addReserveToStudyingGroups = (
    periods: ProductCardStudyingPeriodContract[],
    reservedListeners: ProductCardReserveEnrollmentListenerContract[]
) => [
    ...periods,
    {
        isReserved: true,
        listeners: reservedListeners,
    },
]

/** Маппинг слушателей продукта для запроса по обновлению записи */
export const mapListenersToUpdate = (
    listeners: ProductCardListenerContract[]
) =>
    listeners.map((listener) => ({
        id: listener.id,
        isEnrolled: listener.isEnrolled,
    }))

export const isProductPeriodConfirmed = (
    status?: ProductCardEnrollmentStatus
) => status === ProductCardEnrollmentStatus.Confirmed
export const isProductPeriodCompleted = (
    status?: ProductCardEnrollmentStatus
) => status === ProductCardEnrollmentStatus.Completed
