import React from 'react'
import { Tabs } from 'antd'

import styles from './ManagerAssessmentReport.module.scss'
import { AssessmentReportGrid } from '../AssessmentReportGrid'
import {
    DEFAULT_TAB_KEY,
    TABS_PANE_OPTIONS,
} from './ManagerAssessmentReport.consts'
import { ManagerAssessmentReportProps } from './ManagerAssessmentReport.types'

/** Отчет оценки руководителя  */
export const ManagerAssessmentReport: React.FC<ManagerAssessmentReportProps> = React.memo(
    ({ template }) => (
        <Tabs
            defaultActiveKey={DEFAULT_TAB_KEY}
            size="large"
            type="card"
            className={styles.tabs}
        >
            {TABS_PANE_OPTIONS.map((tabProps) => (
                <Tabs.TabPane {...tabProps}>
                    <AssessmentReportGrid
                        template={template?.filter(
                            (el) => el.assessmentRole === tabProps.key
                        )}
                    />
                </Tabs.TabPane>
            ))}
        </Tabs>
    )
)
