import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    BackTop,
    ContentTitle,
    ControlPanel,
    IconsAdapter,
    PageContent,
    TooltipAdapter,
} from 'components/shared'
import { ColorThemeContext } from 'contexts'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import {
    PassingFormFinalWindowInfoContract,
    PassingFormsService,
    StartPassingContract,
} from 'core/api'
import { PassingStage } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ShouldUpdateChecker } from 'components/controls'
import { Store } from 'App.types'
import {
    changeQuestionEndToEndNumber,
    getBannerBackgroundCssProp,
    getFlatQuestions,
    getUniqueItemsArray,
    isAppointmentStatusEnded,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeQuiz,
    isQuestionnaireTypeTest,
    secondCountToTimeString,
} from 'utils'
import {
    isPassingStageDone,
    isPassingStageGreeting,
    isPassingStageInProgress,
} from 'utils/conditions.utils/passingStage.utils'
import { useHistory, useParams } from 'react-router-dom'
import { useHttp, usePreventCopyPaste } from 'hooks'
import { withLoader } from 'HOCs'

import styles from './QuestionnairePassing.module.scss'
import {
    AnswerFormValuesContract,
    PassingDataProps,
    PassingStateProps,
    QuestionnairePassingParamsProps,
} from './QuestionnairePassing.types'
import { CommonPassing } from './components/CommonPassing/CommonPassing'
import {
    QuestionnairesPassingDoneStage,
    QuestionnairesPassingGreetingsStage,
    TestPassingByBlock,
} from './components'
import {
    confirmRepassing,
    getCheckAnswers,
    getLeavePassingConfirmation,
    getRemainingAttemptsCount,
    mapRequestToFormData,
    mapResponseToFormData,
    removeEmptyAnswers,
} from './QuestionnairePassing.utils'
import {
    registerQuestionnairePassingEvents,
    unregisterQuestionnairePassingEvents,
} from './QuestionnairePassing.events'
import { renderConfirmFinalWindow } from './QuestionnairePassing.render'

/** Страница прохождения опроса слушателем */
export const QuestionnairePassing: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const { push } = useHistory()
        const { questionnairePassingId, questionnaireType } = useParams<
            QuestionnairePassingParamsProps
        >()
        const appointmentId = Number(questionnairePassingId)
        const [form] = Form.useForm()
        const [remainingTime, setRemainingTime] = useState<number>()
        const [completePassing, passingResult] = useHttp(
            PassingFormsService.done,
            updateLoader
        )

        const [picture, setPicture] = useState<string>()

        /** Стейт прохождения формы, содержит подтвержденные ответы на вопросы passingData.questionnaire.answers */
        const [passingData, setPassingData] = useState<PassingDataProps>()

        const [isLastCompetencesPage, setIsLastCompetencesPage] = useState(
            false
        )
        const [{ stage, passingResults }, setPassingState] = useState<
            PassingStateProps
        >({
            stage: PassingStage.GREETINGS,
        })

        const [isAlreadyRestarted, setAlreadyRestarted] = useState(false)

        const { theme } = useContext(ColorThemeContext)

        const presentation = useMemo(
            () =>
                passingData?.questionnaire?.quizPortfolioPresentation ||
                passingData?.questionnaire?.testPortfolioPresentation,
            [passingData]
        )

        useEffect(() => {
            switch (stage) {
                case PassingStage.GREETINGS:
                    setPicture(presentation?.welcomeWindow?.picture?.url)
                    break
                case PassingStage.PASSING:
                    setPicture(presentation?.passingPicture?.url)
                    break
                default:
                    setPicture(passingResults?.picture?.url)
                    break
            }
        }, [presentation, stage, passingResults])

        /**
         * Запрос информации по опросу
         */
        const fetchQuestionnaire = useCallback(async () => {
            try {
                updateLoader(true)

                const data = await PassingFormsService.startPassing({
                    body: {
                        appointmentId,
                    } as StartPassingContract,
                })

                const questionnaireData = data.testPortfolioPresentation
                    ?.showQuestionsByBlock
                    ? data
                    : changeQuestionEndToEndNumber(data)

                setPassingData({
                    questionnaire: questionnaireData,
                    questions: getFlatQuestions(questionnaireData),
                    id: questionnaireData.id,
                })

                form.setFieldsValue({
                    answers: mapResponseToFormData(questionnaireData.answers),
                })

                setRemainingTime(questionnaireData.timeLeft)

                // Если прохождение закончено, сразу кидаем на финальный экран
                if (
                    isAppointmentStatusEnded(questionnaireData?.passingStatus)
                ) {
                    const newAnswersIds = getUniqueItemsArray(
                        questionnaireData.answers.map((el) => el.questionId)
                    )
                    const newQuestions = getFlatQuestions(questionnaireData)

                    const newPassingResults = {
                        endDate: questionnaireData?.endTime,
                        passingQuestionsCount: newAnswersIds.length,
                        totalQuestionsCount: newQuestions?.length || 0,
                    }

                    setPassingState((prev) => ({
                        ...prev,
                        stage: PassingStage.DONE,
                        passingResults: newPassingResults,
                    }))

                    return
                }
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [updateLoader, appointmentId, form])

        /**
         * Возвращает номер блокирующего вопроса
         * @param numberOfBlockingQuestion - номер блокирующего вопроса
         * @return undefined если не заблокирован
         */
        const getBlockedByQuestionEndToEndNumber = useCallback(
            (blockingQuestionId?: number) => {
                if (!passingData || !blockingQuestionId) return

                const blockingQuestionNumber = passingData.questions.find(
                    (el) => el.id === blockingQuestionId
                )?.number

                if (
                    passingData.questionnaire.answers.every(
                        (el) => el.questionId !== blockingQuestionId
                    )
                )
                    return blockingQuestionNumber
            },
            [passingData]
        )

        const setIsLast = useCallback(() => {
            setIsLastCompetencesPage(true)
        }, [])

        /** Обработчик перехода обратно */
        const backToRegistry = useCallback(() => {
            const isTypeQuiz = isQuestionnaireTypeQuiz(
                passingData?.questionnaire.appointment.type
            )

            push(
                isTypeQuiz
                    ? ROUTE_NAMES.QUESTIONNAIRES_LISTENER
                    : ROUTE_NAMES.USER_ASSIGNMENTS
            )
        }, [push, passingData])

        /** Поменять стадию на "завершено" */
        const changeStageToDone = useCallback(
            (passingResults: PassingFormFinalWindowInfoContract) => {
                setPassingState((prev) => ({
                    ...prev,
                    stage: PassingStage.DONE,
                    passingResults,
                }))
            },
            []
        )

        /** Поменять стадию на "прохождение" */
        const changeStageToPassing = useCallback(async () => {
            try {
                if (
                    isQuestionnaireTypeTest(
                        passingData?.questionnaire.appointment.type
                    )
                )
                    await PassingFormsService.setPassingStartTime({
                        appointmentId,
                    })

                setPassingState((prev) => ({
                    ...prev,
                    stage: PassingStage.PASSING,
                }))

                setPassingData((prev) => {
                    if (!prev?.questionnaire) return prev

                    return {
                        ...prev,
                        questionnaire: {
                            ...prev.questionnaire,
                            startTime: new Date(),
                        },
                    }
                })
            } catch (error) {
                console.error(error)
            }
        }, [appointmentId, passingData])

        /** Набор запросов при сохранении ответов
         * @param unsavedAnswers массив несохраненных ответов
         * @return актуальный объект прохождения
         */
        const fetchSetAnswers = useCallback(
            async (unsavedAnswers) => {
                try {
                    updateLoader(true)

                    await PassingFormsService.setAnswers(
                        mapRequestToFormData(
                            removeEmptyAnswers(unsavedAnswers),
                            Number(passingData?.questionnaire.id)
                        )
                    )

                    return await PassingFormsService.getPassingInfo({
                        appointmentId,
                    })
                } catch (err) {
                    console.error(err)
                } finally {
                    updateLoader(false)
                }
            },
            [appointmentId, passingData, updateLoader]
        )

        /** Сохранение ответов при нажатии на кнопку "подтвердить" */
        const handleConfirmQuestions = useCallback(
            (unsavedAnswers) => {
                fetchSetAnswers(unsavedAnswers).then((questionnaireData) => {
                    if (!questionnaireData) return

                    form.setFieldsValue({
                        answers: mapResponseToFormData(
                            questionnaireData.answers
                        ),
                    })

                    setPassingData((prevState) => {
                        if (!prevState) return

                        return {
                            ...prevState,
                            questionnaire: {
                                ...questionnaireData,
                                groupedQuestions:
                                    prevState.questionnaire.groupedQuestions,
                            },
                        }
                    })
                })
            },
            [form, fetchSetAnswers]
        )

        /**
         * Отправка на сервер информации о завершении прохождения опроса
         */
        const completePass = useCallback(async () => {
            if (passingData) {
                const data = {
                    id: passingData.questionnaire.id,
                    body: {
                        timeLeft: remainingTime as number,
                        stoppingQuestions: passingData.questionnaire.answers.map(
                            (el) => ({
                                questionId: el.questionId,
                                timeLeft: el.timeLeft,
                            })
                        ),
                    },
                }

                await completePassing(data)
            }
        }, [passingData, completePassing, remainingTime])

        const handleConfirmRepassing = useCallback(
            (timeIsUp?: boolean) => {
                if (!passingData) return

                confirmRepassing({
                    remainingAttempts: getRemainingAttemptsCount(
                        passingData.questionnaire
                    ),
                    questionnaire: passingData.questionnaire,
                    completePass,
                    onRemainingTime: setRemainingTime,
                    setAlreadyRestarted,
                    timeIsUp,
                })
            },
            [completePass, passingData]
        )

        /**
         * Завершение опроса
         * @param message - сообщение для модального окна
         */
        const handleFinishPassing = useCallback(() => {
            if (!passingData) return

            const showConfirmFinalModal =
                passingData.questionnaire.answers.length &&
                passingData.questionnaire.quizPortfolioPresentation
                    ?.showFinalWindow

            showConfirmFinalModal
                ? renderConfirmFinalWindow({
                      questions: passingData.questions,
                      answers: passingData.questionnaire.answers,
                  })
                : handleConfirmRepassing()
        }, [passingData, handleConfirmRepassing])

        /** Стадия окончания */
        const doneStageContent = useMemo(() => {
            if (
                passingData?.questionnaire &&
                passingData?.questions &&
                passingResults &&
                isPassingStageDone(stage)
            ) {
                return (
                    <QuestionnairesPassingDoneStage
                        questionnaire={passingData.questionnaire}
                        onOk={backToRegistry}
                        numberOfAnswers={passingResults.passingQuestionsCount}
                        numberOfQuestions={passingResults.totalQuestionsCount}
                        date={passingResults.endDate}
                    />
                )
            }
        }, [passingData, passingResults, stage, backToRegistry])

        /** Стадия приветствия */
        const greetingsStageContent = useMemo(() => {
            if (
                passingData?.questionnaire &&
                passingData?.questions &&
                isPassingStageGreeting(stage)
            ) {
                return (
                    <QuestionnairesPassingGreetingsStage
                        questionnaire={passingData.questionnaire}
                        onStart={changeStageToPassing}
                        numberOfQuestions={passingData.questions.length}
                        attemptsLimit={passingData.questionnaire.attemptsLimit}
                        attemptsLimited={
                            passingData.questionnaire.attemptsLimited
                        }
                    />
                )
            }
        }, [stage, changeStageToPassing, passingData])

        /**
         * Получить компонент прохождения по типу прохождения
         */
        const getPassingComponentByType = useCallback(
            (answers: Store<AnswerFormValuesContract>) => {
                if (!passingData) return

                const showQuestionsByBlock = presentation?.showQuestionsByBlock
                const { questionnaire } = passingData

                const commonProps = {
                    confirmedAnswers: questionnaire.answers,
                    isAssessment: isQuestionnaireTypeAssessment(
                        questionnaire.appointment.type
                    ),
                    disabledQuestionsTimer:
                        isQuestionnaireTypeTest(
                            questionnaire.appointment.type
                        ) && questionnaire.timeIsLimited,
                    questionnaireId: questionnaire.id,
                    getBlockedByQuestionEndToEndNumber,
                    showQuestionsByBlock,
                    setIsLast,
                    onConfirmAnswers: handleConfirmQuestions,
                    answers,
                }

                if (
                    showQuestionsByBlock &&
                    isQuestionnaireTypeTest(questionnaireType)
                ) {
                    return (
                        <TestPassingByBlock
                            {...commonProps}
                            form={form}
                            passingData={passingData}
                        />
                    )
                }

                return (
                    <CommonPassing
                        {...commonProps}
                        isLastCompetencesPage={isLastCompetencesPage}
                        form={form}
                        passingData={passingData}
                    />
                )
            },
            [
                passingData,
                presentation,
                getBlockedByQuestionEndToEndNumber,
                setIsLast,
                handleConfirmQuestions,
                questionnaireType,
                isLastCompetencesPage,
                form,
            ]
        )

        const affixIsVisible = useMemo(
            () =>
                presentation?.showPassingProgress ||
                passingData?.questionnaire?.timeLimit,
            [passingData, presentation]
        )

        /** Запрашиваем данные по опросу во время инициализации */
        useEffect(() => {
            fetchQuestionnaire()
        }, [fetchQuestionnaire])

        useEffect(() => {
            passingResult && changeStageToDone(passingResult)
        }, [passingResult, changeStageToDone])

        const {
            subscribeOnActions,
            unSubscribeOnActions,
        } = usePreventCopyPaste({ manual: true })

        useEffect(() => {
            if (!isQuestionnaireTypeTest(questionnaireType)) return

            subscribeOnActions()

            return unSubscribeOnActions
        }, [subscribeOnActions, questionnaireType, unSubscribeOnActions])

        useEffect(() => {
            registerQuestionnairePassingEvents({
                onFinish: handleFinishPassing,
                onConfirmRepassing: handleConfirmRepassing,
            })

            return () =>
                unregisterQuestionnairePassingEvents({
                    onFinish: handleFinishPassing,
                    onConfirmRepassing: handleConfirmRepassing,
                })
        }, [handleConfirmRepassing, handleFinishPassing])

        return (
            <section
                style={getBannerBackgroundCssProp({
                    url: picture,
                    theme,
                })}
            >
                {getLeavePassingConfirmation({
                    data: {
                        remainingTime,
                        passingId: Number(passingData?.id),
                    },
                    isRoutingBlocked: !!remainingTime && !passingResult,
                })}

                <BackTop bottom={130} />

                <PageContent>
                    {greetingsStageContent}

                    {isPassingStageInProgress(stage) && (
                        <Form
                            id={FORM_IDS.QUESTIONNAIRE_PASSING_FORM}
                            form={form}
                        >
                            <ContentTitle
                                title={
                                    passingData?.questionnaire?.appointment
                                        ?.portfolio.name || ''
                                }
                                className={styles.header}
                            >
                                {passingData?.questionnaire?.timeIsLimited && (
                                    <div className={styles.duration}>
                                        <IconsAdapter iconType="ExclamationCircleOutlined" />

                                        <span
                                            className={styles.durationMessage}
                                        >
                                            {`${LOCAL.MESSAGES.ESTABLISHED_PASSING_TIME_RESTRICTION}: `}

                                            <TooltipAdapter
                                                title={LOCAL.HHMMSS}
                                                className={styles.time}
                                            >
                                                <b>
                                                    {secondCountToTimeString(
                                                        passingData
                                                            .questionnaire
                                                            .timeLimit
                                                    )}
                                                </b>
                                            </TooltipAdapter>
                                        </span>
                                    </div>
                                )}
                            </ContentTitle>

                            {passingData && (
                                <ShouldUpdateChecker fieldPath={['answers']}>
                                    {({ getFieldValue }) => {
                                        const answers = getFieldValue('answers')

                                        return (
                                            <>
                                                {affixIsVisible && (
                                                    <ControlPanel
                                                        passingData={
                                                            passingData
                                                        }
                                                        checkAnswers={getCheckAnswers(
                                                            answers
                                                        )}
                                                        remainingTime={
                                                            remainingTime
                                                        }
                                                        onRemainingTime={
                                                            setRemainingTime
                                                        }
                                                        isAlreadyRestarted={
                                                            isAlreadyRestarted
                                                        }
                                                    />
                                                )}

                                                <div
                                                    className={
                                                        styles.description
                                                    }
                                                >
                                                    {
                                                        passingData
                                                            .questionnaire
                                                            .appointment
                                                            .portfolio
                                                            .description
                                                    }
                                                </div>

                                                {getPassingComponentByType(
                                                    answers
                                                )}
                                            </>
                                        )
                                    }}
                                </ShouldUpdateChecker>
                            )}
                        </Form>
                    )}

                    {doneStageContent}
                </PageContent>
            </section>
        )
    })
)
