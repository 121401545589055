import React from 'react'
import {
    APPOINTMENT_TYPES_MAPPED,
    QUIZ_CHECK_TYPE_WITHOUT_IN_PROCESS_FOR_SOLUTION_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { FilterForm } from 'components/shared'
import { FiltersProps } from 'utils'
import { LOCAL } from 'core/local'
import { QuizCheckRegistrySearchContract } from 'core/api'
import { renderFooterWithShowExpiredField } from 'components/shared/FilterForm/FilterForm.render'

export const QuizCheckingFilters: React.FC<FiltersProps<
    QuizCheckRegistrySearchContract
>> = React.memo((props) => (
    <ControlGroup type="light">
        <FilterForm {...props} renderFooter={renderFooterWithShowExpiredField}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.TRACK_OR_EVENT}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter fieldName="type" label={LOCAL.LABELS.TYPE}>
                        <SelectControl
                            values={APPOINTMENT_TYPES_MAPPED}
                            showSearch
                        />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="appointmentName"
                        label={LOCAL.LABELS.ASSIGNMENT}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="tutor"
                        label={LOCAL.LABELS.REVIEWER}
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Col>

                <Col xs={8}>
                    <FormItemAdapter
                        fieldName="statuses"
                        label={LOCAL.LABELS.STATUS}
                    >
                        <SelectControl
                            values={
                                QUIZ_CHECK_TYPE_WITHOUT_IN_PROCESS_FOR_SOLUTION_MAPPED
                            }
                            mode="multiple"
                        />
                    </FormItemAdapter>
                </Col>
            </Row>
        </FilterForm>
    </ControlGroup>
))
