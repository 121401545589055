import React, { FC, useCallback } from 'react'
import { AppError, ThemeCreateContract, ThemesService } from 'core/api'
import { Form } from 'antd'
import { FormItemAdapter, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { withLoader } from 'HOCs'

/** Форма добавления/редактирования тематического раздела */
export const ThemeForm: FC<PopupAdapterFormProps> = withLoader(
    React.memo(
        ({
            onRequestFinish,
            deleteFormAction,
            initialValues,
            recordCopy,
            rowSelectionType,
            updateLoader,
            updateButtonVisibility,
            onRefetch,
            messageText,
            onChangeModalOptions,
            onCancelSubmit,
            isLoading,
            ...props
        }) => {
            /**
             * Обработчик отправки формы
             */
            const handleFinish = useCallback(
                async (values: Partial<ThemeCreateContract>) => {
                    try {
                        updateLoader(true)
                        updateButtonVisibility?.(true)

                        if (initialValues) {
                            await ThemesService.update({
                                id: initialValues.id,
                                body: values as ThemeCreateContract,
                            })
                        } else {
                            await ThemesService.create({
                                body: values as ThemeCreateContract,
                            })
                        }

                        onRequestFinish?.()
                    } catch (error) {
                        onRequestFinish?.(error as AppError)
                    } finally {
                        updateLoader(false)
                        updateButtonVisibility?.(false)
                    }
                },
                [
                    initialValues,
                    onRequestFinish,
                    updateButtonVisibility,
                    updateLoader,
                ]
            )

            return (
                <Form
                    onFinish={handleFinish}
                    {...props}
                    initialValues={initialValues}
                >
                    <FormItemAdapter
                        fieldName="name"
                        label={LOCAL.LABELS.NAME}
                        required
                    >
                        <InputControl />
                    </FormItemAdapter>
                </Form>
            )
        }
    )
)
