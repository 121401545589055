import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { ComponentCommonProps } from 'App.types'
import { DictionaryContract } from 'core/api'
import { Empty } from 'antd'
import { FormItemAdapter, InputControl } from 'components/controls'

import styles from './ThematicBlocksView.module.scss'

/**
 * Визуализация тематических блоков выбранного мероприятия для формы назначения мероприятия
 */
export const ThematicBlocksView: React.FC<
    {
        thematicBlocks?: DictionaryContract[]
    } & ComponentCommonProps
> = React.memo(({ thematicBlocks }) => {
    /**
     * Генерация шаблона тематических блоков для отображения
     * @param thematicBlock - информация о текущем тематическом блоке
     * @return {ReactNode}
     */
    const makeThematicBlock = (thematicBlock: DictionaryContract) => (
        <FormItemAdapter
            key={uniqueId()}
            label="Наименование тематического блока"
        >
            <InputControl disabled value={thematicBlock.name} />
        </FormItemAdapter>
    )

    return (
        <>
            {thematicBlocks?.length ? (
                thematicBlocks?.map(makeThematicBlock)
            ) : (
                <Empty
                    className={styles.empty}
                    description="Тематические блоки отсутствуют либо мероприятие не выбрано"
                />
            )}
        </>
    )
})
