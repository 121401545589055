import React from 'react'
import cn from 'classnames'
import { CheckboxControl, SwitchControl } from 'components/controls'
import { Col, Form, Row } from 'antd'
import { LOCAL } from 'core/local'

import styles from './FilterForm.module.scss'
import { RenderFooterProps } from './FilterForm.types'

export const renderFooterWithShowArchiveField = ({
    cleanBtn,
    submitBtn,
    saveFiltersSwitch,
    isWidthLessLG,
}: RenderFooterProps) => (
    <Row gutter={10} align="middle" justify={isWidthLessLG ? 'center' : 'end'}>
        <Col>
            <Form.Item name="showArchive" noStyle>
                <CheckboxControl>
                    {LOCAL.LABELS.ARCHIVED_RECORDS}
                </CheckboxControl>
            </Form.Item>
        </Col>

        {saveFiltersSwitch && <Col>{saveFiltersSwitch}</Col>}

        <Col>{submitBtn}</Col>

        <Col>{cleanBtn}</Col>
    </Row>
)

export const renderFooterWithShowExpiredField = ({
    cleanBtn,
    submitBtn,
    saveFiltersSwitch,
    isWidthLessLG,
}: RenderFooterProps) => (
    <Row gutter={10} align="middle" justify={isWidthLessLG ? 'center' : 'end'}>
        <Col>
            <Form.Item name="isOverdue" noStyle>
                <CheckboxControl>{LOCAL.LABELS.SHOW_EXPIRED}</CheckboxControl>
            </Form.Item>
        </Col>

        {saveFiltersSwitch && <Col>{saveFiltersSwitch}</Col>}

        <Col>{cleanBtn}</Col>

        <Col>{submitBtn}</Col>
    </Row>
)

export const renderFooterWithArchiveAndAutoAppointment = ({
    cleanBtn,
    submitBtn,
    saveFiltersSwitch,
    isWidthLessLG,
}: RenderFooterProps) => (
    <Row gutter={10} align="middle" justify={isWidthLessLG ? 'center' : 'end'}>
        <Col>
            <Form.Item name="showArchive" noStyle>
                <CheckboxControl>
                    {LOCAL.LABELS.ARCHIVED_RECORDS}
                </CheckboxControl>
            </Form.Item>
        </Col>

        <Col>
            <Form.Item name="isAutomaticallyCreated" noStyle>
                <SwitchControl
                    className={cn(styles.saveFilterSwitch, 'mr-5', 'ml-5')}
                    addonBefore={LOCAL.LABELS.HAND_OPERATED}
                    addonAfter={LOCAL.LABELS.AUTOMATIC}
                />
            </Form.Item>
        </Col>

        {saveFiltersSwitch && <Col>{saveFiltersSwitch}</Col>}

        <Col>{submitBtn}</Col>

        <Col>{cleanBtn}</Col>
    </Row>
)
