import React from 'react'
import { Form, Tabs } from 'antd'

import { AssessmentReportsContent } from '../AssessmentReportsContent'
import {
    DEFAULT_TAB_KEY,
    TABS_PANE_OPTIONS,
} from './AssessmentReportsManager.consts'
import { FIELD_NAMES } from '../AssessmentReportsForm/AssessmentReportsForm.consts'

export const AssessmentReportsManager = React.memo(() => (
    <Tabs defaultActiveKey={String(DEFAULT_TAB_KEY)} size="large" type="card">
        <Tabs.TabPane {...TABS_PANE_OPTIONS.MANAGER}>
            <Form.Item name={FIELD_NAMES.MANAGER}>
                <AssessmentReportsContent namePath={FIELD_NAMES.MANAGER} />
            </Form.Item>
        </Tabs.TabPane>

        <Tabs.TabPane {...TABS_PANE_OPTIONS.COWORKER}>
            <Form.Item name={FIELD_NAMES.SELF_ASSESSMENT}>
                <AssessmentReportsContent
                    namePath={FIELD_NAMES.SELF_ASSESSMENT}
                />
            </Form.Item>
        </Tabs.TabPane>
    </Tabs>
))
