import React, { useCallback, useMemo } from 'react'
import { HomeworksService } from 'core/api'
import { InformationPassingModal } from 'components/forms/InformationPassingModal/InformationPassingModal'
import { QuestionnaireForm, VideoContentModal } from 'components/shared'
import { QuestionnaireModal } from 'components/shared/QuestionnaireModal'
import { TaskPassingForm } from 'components/forms'
import { isEnoughAttemptCountForThematicBlock } from 'components/pages/TrackPreviewPassing/components/ActivityLayer/ActivityLayer.utils'
import {
    isThemeBlockTypeHomeworkWithoutEditor,
    isThemeBlockTypeInformation,
    isThemeBlockTypeQuestionnaire,
    isThemeBlockTypeVideoCourse,
    isThemeBlockTypeWebConferenceOrFaceToFace,
} from 'utils'

import { BlockPassingModal } from '../BlockPassingModal'
import { BlockPassingWithoutLayerProps } from './BlockPassingWithoutLayer.types'
import { EventPassingInfoPanel } from '../EventPassingInfoPanel'
import { ThematicBlock } from '../ThematicBlock'
import {
    getDurationHours,
    getDurationMinutes,
} from './BlockPassingWithoutLayer.utils'
import { isViewPassedAssignment } from '../../EventPassing.utils'

export const BlockPassingWithoutLayer: React.FC<BlockPassingWithoutLayerProps> = React.memo(
    ({
        passingUnit,
        nestedActivities,
        currentLessonId,
        handleReturnToPrevActivity,
        triggerStartThematicBlockPassing,
        selectedThematicBlock,
        finishingThematicBlockPassage,
        isHomeworkAutoCheck,
        handleViewMaterial,
        handleExitVideoCourse,
    }) => {
        const { homework } = selectedThematicBlock || {}

        /**
         * Раздел со списком тематических блоков для прохождения
         */
        const thematicBlocksList = useMemo(
            () =>
                passingUnit?.activityLessonPassings.map((el) => (
                    <ThematicBlock
                        {...el}
                        courseDuration={getDurationHours(el)}
                        courseDurationMinutes={getDurationMinutes(el)}
                        key={el.lessonId}
                        onStartThematicBlockPassing={
                            triggerStartThematicBlockPassing
                        }
                        activityStatus={passingUnit.status}
                    />
                )),
            [passingUnit, triggerStartThematicBlockPassing]
        )

        const onCloseHomeworkModal = useCallback(async () => {
            try {
                finishingThematicBlockPassage()
                await HomeworksService.onExit({ id: Number(homework?.id) })
            } catch (err) {
                console.error(err)
            }
        }, [finishingThematicBlockPassage, homework])

        /**
         * Тематический блок типа Видео отображается в модальном окне
         */
        const isShowVideoCourseModal =
            selectedThematicBlock &&
            isThemeBlockTypeVideoCourse(selectedThematicBlock)

        const isShowInformationModal =
            selectedThematicBlock &&
            isThemeBlockTypeInformation(selectedThematicBlock.type)

        const isShowEventInBlockPassingModal =
            selectedThematicBlock &&
            isThemeBlockTypeWebConferenceOrFaceToFace(
                selectedThematicBlock.type
            )

        const initialValues = useMemo(
            () =>
                !!selectedThematicBlock?.questions?.length &&
                isEnoughAttemptCountForThematicBlock(selectedThematicBlock) && {
                    questions: selectedThematicBlock.questions,
                    id: selectedThematicBlock.id,
                    name: selectedThematicBlock.questionnaireName,
                    currentQuestionNumber:
                        selectedThematicBlock.currentQuestionNumber,
                    type: selectedThematicBlock.questionnaireType,
                },
            [selectedThematicBlock]
        )

        const isShowQuestionnaireModal =
            isThemeBlockTypeQuestionnaire(selectedThematicBlock?.type) &&
            !!selectedThematicBlock

        const informationPassingModalOkButtonOptions = useMemo(
            () =>
                isViewPassedAssignment()
                    ? {
                          props: {
                              style: { display: 'none' },
                          },
                          onOk: undefined,
                      }
                    : {
                          props: {
                              style: undefined,
                          },
                          onOk: handleViewMaterial,
                      },
            [handleViewMaterial]
        )

        return (
            <>
                <EventPassingInfoPanel
                    activity={passingUnit}
                    nestedActivities={nestedActivities}
                    currentLessonId={currentLessonId}
                    onReturnToPrevActivity={handleReturnToPrevActivity}
                />

                <div>{thematicBlocksList}</div>

                {isShowVideoCourseModal && (
                    <VideoContentModal
                        popupProps={{
                            visible: !!selectedThematicBlock,
                        }}
                        fileUrl={selectedThematicBlock?.course?.launchUrl!}
                        progress={selectedThematicBlock?.progress}
                        onFinish={handleViewMaterial}
                        onExit={handleExitVideoCourse}
                    />
                )}

                {isShowEventInBlockPassingModal && (
                    <BlockPassingModal
                        popupProps={{
                            visible: !!selectedThematicBlock,
                            onCancel: finishingThematicBlockPassage,
                            onOk: handleViewMaterial,
                        }}
                        lesson={selectedThematicBlock!}
                    />
                )}

                {isThemeBlockTypeHomeworkWithoutEditor(
                    selectedThematicBlock?.type,
                    selectedThematicBlock?.homework?.isEditorUsed
                ) && (
                    <TaskPassingForm
                        homework={homework!}
                        popupProps={{
                            visible: !!selectedThematicBlock,
                            onCancel: onCloseHomeworkModal,
                        }}
                        isHomeworkAutoCheck={isHomeworkAutoCheck}
                    />
                )}

                {initialValues && isShowQuestionnaireModal && (
                    <QuestionnaireModal
                        initialValues={initialValues}
                        onClose={finishingThematicBlockPassage}
                        componentWrapper={QuestionnaireForm}
                        useStoreLoader={false}
                    />
                )}

                {isShowInformationModal && (
                    <InformationPassingModal
                        popupProps={{
                            visible: !!selectedThematicBlock,
                            onCancel: finishingThematicBlockPassage,
                            onOk: informationPassingModalOkButtonOptions.onOk,
                            okButtonProps:
                                informationPassingModalOkButtonOptions.props,
                        }}
                        selectedThematicBlock={selectedThematicBlock}
                    />
                )}
            </>
        )
    }
)
