export enum AnchorType {
    start = 'start',
    center = 'center',
    end = 'end',
}

export enum AlignType {
    right = 'right',
    left = 'left',
    top = 'top',
    bottom = 'bottom',
    center = 'center',
    end = 'end',
    start = 'start',
}

export enum IndexAxis {
    y = 'y',
    x = 'x',
}
