import React from 'react'
import template from 'lodash/template'
import { ColumnsType } from 'antd/lib/table'
import { DATE_FORMAT, MODAL_WIDTH } from 'consts'
import {
    DateProps,
    getDateByFormat,
    getShortFio,
    renderDeliveredNotificationStatus,
    renderSendEmailStatus,
} from 'utils'
import { EmailPreviewForm } from 'components/forms'
import { FORM_IDS } from 'core/configs'
import { IconsAdapter, PopupWithForm } from 'components/shared'
import { LOCAL } from 'core/local'
import {
    NotificationRecipientEmailInfoSearchResultContract,
    UserBaseContract,
} from 'core/api'
import { defaultSorter } from 'components/layouts'

import { renderNumberOfAttemptsToSend } from './DistributionMailingView.utils'

export const DISTRIBUTION_MAILING_VIEW_TABLE_COLUMNS: ColumnsType<NotificationRecipientEmailInfoSearchResultContract> = [
    {
        title: LOCAL.LABELS.RECEIVER_NAME,
        dataIndex: 'recipientFullName',
        key: 'recipientFullName',
        render: (_, record) => getShortFio(record.recipient),
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.ADDRESS,
        dataIndex: 'recipient',
        key: 'email',
        render: (recipient: UserBaseContract) => recipient.email,
    },
    {
        title: LOCAL.LABELS.DISPATCH_TIME,
        dataIndex: 'sendDate',
        key: 'sendDate',
        render: (date: DateProps) =>
            getDateByFormat(date, DATE_FORMAT.WITH_TIME),
    },
    {
        title: LOCAL.LABELS.NUMBER_OF_ATTEMPTS_TO_SEND,
        dataIndex: 'countError',
        key: 'countError',
        render: (_, record) => renderNumberOfAttemptsToSend(record),
    },
    {
        title: LOCAL.LABELS.STATUS_SENDING,
        dataIndex: 'status',
        key: 'status',
        render: renderSendEmailStatus,
        sorter: defaultSorter,
    },
    {
        title: LOCAL.LABELS.STATUS_DELIVERY,
        dataIndex: 'lastDeliveryState',
        key: 'lastDeliveryState',
        render: renderDeliveredNotificationStatus,
        sorter: defaultSorter,
    },
    {
        title: '',
        render: (tableRow) =>
            tableRow.status !== null && (
                <PopupWithForm
                    formId={`${FORM_IDS.EMAIL_PREVIEW_FORM}${tableRow.emailId}`}
                    component={EmailPreviewForm}
                    buttonText=""
                    buttonOption={{
                        type: 'link',
                        icon: <IconsAdapter iconType="EyeOutlined" />,
                        title: LOCAL.ACTIONS.VIEW,
                        getPopupContainer: () => document.body,
                        disabled: !tableRow.active && !tableRow.status,
                    }}
                    formOptions={{
                        initialValues: tableRow,
                    }}
                    modalOptions={{
                        footer: null,
                        width: MODAL_WIDTH.XL,
                        title: ` ${template(
                            LOCAL.LABELS.DISTRIBUTION_MAILING_VIEW_TITLE
                        )({
                            email: tableRow.recipient.email,
                        })}`,
                    }}
                />
            ),
        align: 'right',
    },
]
