import React, { useCallback } from 'react'
import {
    CompetenceGroupsPublicQuestionsContract,
    CompetencesPublicQuestionsContract,
} from 'core/api'

import { ButtonsControl } from '../ButtonsControl'
import { CommonPassingProps } from './CommonPassing.types'
import { CompetenceGroupQuestions } from '../CompetenceGroupQuestions'

/** Общий компонент для прохождения тестов по одному и опросов*/
export const CommonPassing: React.FC<CommonPassingProps> = ({
    answers,
    form,
    showQuestionsByBlock,
    passingData,
    isLastCompetencesPage,
    ...restProps
}) => {
    const renderQuestions = useCallback(() => {
        if (showQuestionsByBlock) {
            const competenceQuestions = passingData.questionnaire.groupedQuestions.reduce<
                CompetencesPublicQuestionsContract[]
            >((acc, curr) => [...acc, ...curr.competenceQuestions], [])

            return (
                <CompetenceGroupQuestions
                    competenceQuestions={competenceQuestions}
                    {...restProps}
                />
            )
        }

        return passingData.questionnaire.groupedQuestions.map(
            (el: CompetenceGroupsPublicQuestionsContract) => (
                <CompetenceGroupQuestions
                    {...el}
                    key={el.competenceGroup.id}
                    {...restProps}
                />
            )
        )
    }, [
        passingData.questionnaire.groupedQuestions,
        restProps,
        showQuestionsByBlock,
    ])

    return (
        <>
            {renderQuestions()}

            {isLastCompetencesPage && (
                <ButtonsControl
                    passingData={passingData}
                    answers={answers}
                    form={form}
                    onConfirmAnswers={restProps.onConfirmAnswers}
                />
            )}
        </>
    )
}
