import {
    ActivityModalContract,
    CompetenceForModalContract,
    CourseForModalContract,
    DevelopmentGoalsSearchResultContract,
    DictionaryContract,
    IndicatorContract,
    QuestionnaireContract,
    QuestionnaireForModalContract,
    UserModalContract,
} from 'core/api'
import { ColumnsType } from 'antd/lib/table/interface'
import { EVENT_TYPES, QUESTIONNAIRE_TYPES } from 'consts'
import { LOCAL } from 'core/local'
import {
    formatDate,
    getFullFio,
    getItemName,
    isDecorationTypeVisualization,
    renderOrganizationName,
    renderPositionName,
    renderStringArrayWithSeparator,
} from 'utils'

import { getTableColumnForName } from './TableSearchForm.utils'

/**
 * Рендер поля `ФИО`
 * @param value - значение конкретного поля ряда
 * @param record - ряд полей
 */
function renderFullName(value: string, record: UserModalContract) {
    return getFullFio({
        firstName: record.firstName,
        lastName: record.lastName,
        patronymic: record.patronymic,
    })
}

/** настройка отображения колонок для справочника индикаторов */
export const INDICATORS_TABLE_COLUMNS: ColumnsType<IndicatorContract> = [
    ...getTableColumnForName<IndicatorContract>(),
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'indicatorType',
        key: 'indicatorType',
        render: (type: DictionaryContract) => type.name,
    },
]

/** настройка отображения колонок для справочника компетенций */
export const COMPETENCIES_TABLE_COLUMNS: ColumnsType<CompetenceForModalContract> = [
    {
        title: LOCAL.LABELS.COMPETENCIES_GROUP,
        dataIndex: 'competenceGroup',
        key: 'competenceGroup',
        render: (group: DictionaryContract) => group.name,
    },
    ...getTableColumnForName<CompetenceForModalContract>(),
]

/** Настройка отображения колонок для справочника обучающих материалов*/
export const TRAINING_MATERIALS_TABLE_COLUMNS: ColumnsType<CourseForModalContract> = [
    ...getTableColumnForName<CourseForModalContract>(),
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'formatDescription',
        key: 'formatDescription',
    },
    {
        title: LOCAL.LABELS.DATE_DOWNLOAD,
        dataIndex: 'uploadDate',
        key: 'uploadDate',
        render: formatDate,
    },
]

/** настройка отображения колонок для справочника пользователей */
export const getCoworkersTableColumns = <
    T extends UserModalContract
>(): ColumnsType<T> => [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'fullName',
        key: 'fullName',
        render: renderFullName,
    },
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'positionName',
        key: 'positionName',
        render: (_, record) => renderPositionName(record),
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizationName',
        key: 'organizationName',
        render: (_, record) => renderOrganizationName(record),
    },
]

/** настройка отображения колонок для сокращенного справочника треков адаптации */
export const TRACK_COLUMNS: ColumnsType<Record<
    'name' | 'title' | 'ownerFullName',
    string
>> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
    },
    {
        title: LOCAL.LABELS.TRACK_TEMPLATES_TITLE,
        dataIndex: 'title',
    },
    {
        title: LOCAL.LABELS.AUTHOR_FIO,
        dataIndex: 'ownerFullName',
    },
]

/** настройка отображения колонок для справочника мероприятий */
export const ACTIVITIES_TABLE_COLUMNS: ColumnsType<ActivityModalContract> = [
    ...getTableColumnForName<ActivityModalContract>(),
    {
        title: LOCAL.LABELS.VISUALIZATION,
        dataIndex: 'decorationType',
        key: 'decorationType',
        render: (decorationType, record) =>
            isDecorationTypeVisualization(record.decorationType)
                ? LOCAL.YES
                : LOCAL.NO,
    },
    {
        title: LOCAL.LABELS.EVENTS_M_TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type, record) => EVENT_TYPES[record.type],
        align: 'center',
    },
]

/** настройка отображения колонок для справочника опросов */
export const QUESTIONNAIRES_TABLE_COLUMNS: ColumnsType<
    QuestionnaireContract | QuestionnaireForModalContract
> = [
    ...getTableColumnForName<
        QuestionnaireContract | QuestionnaireForModalContract
    >(LOCAL.LABELS.APPOINTMENT_NAME),
    {
        title: LOCAL.LABELS.DOCUMENT_TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type, record) => QUESTIONNAIRE_TYPES[record.type],
    },
]

/** настройка отображения колонок таблицы для целей развития */
export const DEVELOPMENT_COALS_TABLE_COLUMNS: ColumnsType<DevelopmentGoalsSearchResultContract> = [
    ...getTableColumnForName<DevelopmentGoalsSearchResultContract>(
        LOCAL.LABELS.DEVELOPMENT_GOAL_NAME
    ),
    {
        title: LOCAL.LABELS.COMPETENCE,
        dataIndex: 'competences',
        key: 'competences',
        render: (_, record) =>
            renderStringArrayWithSeparator(
                record.competences?.map(getItemName)
            ),
    },
]
