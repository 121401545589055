import React from 'react'
import { ComponentCommonProps } from 'App.types'
import { ControlGroup } from 'components/controls'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { TableAdapter } from 'components/shared'

import styles from './StagesSettingsTable.module.scss'
import { getStageSettingsTableColumns } from './StagesSettingsTable.config.table'

/** Таблица с этапами для настройки их доступности при прохождении трека */
export const StagesSettingsTable: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <Form.List name="stageSettings">
            {(stages) => (
                <ControlGroup
                    title={LOCAL.LABELS.TRACK_STAGES_SETTINGS}
                    className={styles.wrapper}
                    noPadding
                >
                    <TableAdapter
                        columns={getStageSettingsTableColumns(formMode)}
                        dataSource={stages}
                        pagination={false}
                        rowKey="fieldKey"
                    />
                </ControlGroup>
            )}
        </Form.List>
    )
)
