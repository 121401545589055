import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'

import { UserFormContract } from './AddUsersTableControl.types'

export const COWORKERS_CONTROL_TABLE_COLUMNS: ColumnsType<UserFormContract> = [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: LOCAL.LABELS.POSITION,
        dataIndex: 'positions',
        key: 'positions',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizations',
        key: 'organizations',
    },
]
