import React from 'react'
import {
    CheckboxControl,
    ColorPickerControl,
    ControlGroup,
    FormItemAdapter,
    SearchSelectControl,
    SelectControl,
    ShouldUpdateChecker,
    SwitchControl,
    TrackQuestionnairesFilters,
} from 'components/controls'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import { FONT_NAMES_MAPPED } from 'consts'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import {
    QuestionnaireContract,
    QuestionnaireForModalContract,
    QuestionnaireSearchContract,
    QuestionnairesService,
} from 'core/api'
import { SpaceAdapter } from 'components/shared'
import { getTableColumnForName } from 'components/controls/SearchSelectControl/components/TableSearchForm/TableSearchForm.utils'

import styles from './TrackVisualizationOptions.module.scss'
import stylesOverallSettings from '../../shared/OverallSettings/OverallSettings.module.scss'
import {
    MENU_VISIBLE_FIELD,
    MENU_VISUALIZATION,
    PROGRESS_VISIBLE_FIELD,
    PROGRESS_VISUALIZATION,
    QUESTIONNAIRES_TABLE_FILTER_OPTIONS,
} from './TrackVisualizationOptions.consts'

/** Опции визуализации трека на вкладке "Настройка трека" */
export const TrackVisualizationOptions: React.FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => (
    <ControlGroup type="light" className={stylesOverallSettings.card}>
        <Form.Item name="isTrackWithVisualisation">
            <SwitchControl
                formMode={formMode}
                addonAfter={LOCAL.LABELS.TRACK_VISUALIZATION}
                disabled={disabled}
            />
        </Form.Item>

        <Form.Item name={PROGRESS_VISIBLE_FIELD}>
            <SwitchControl
                formMode={formMode}
                addonAfter={LOCAL.LABELS.PROGRESS}
                disabled={disabled}
            />
        </Form.Item>

        <ShouldUpdateChecker fieldPath={PROGRESS_VISIBLE_FIELD}>
            {({ getFieldValue }) =>
                getFieldValue(PROGRESS_VISIBLE_FIELD) && (
                    <div className={styles.setup}>
                        {PROGRESS_VISUALIZATION.map((el) => (
                            <Form.Item
                                noStyle
                                name={[
                                    'trackProgressVisualization',
                                    el.fieldName,
                                ]}
                                key={el.label}
                            >
                                <CheckboxControl
                                    formMode={formMode}
                                    disabled={disabled}
                                >
                                    {el.label}
                                </CheckboxControl>
                            </Form.Item>
                        ))}

                        <SpaceAdapter>
                            <Form.Item
                                name={[
                                    'trackProgressVisualization',
                                    'progressTextSettings',
                                    'textColor',
                                ]}
                            >
                                <ColorPickerControl
                                    label={LOCAL.LABELS.TEXT_COLOR}
                                    className={styles.colorPicker}
                                    formMode={formMode}
                                />
                            </Form.Item>

                            <Form.Item
                                name={[
                                    'trackProgressVisualization',
                                    'backgroundColor',
                                ]}
                            >
                                <ColorPickerControl
                                    label={LOCAL.LABELS.BACKGROUND_COLOR}
                                    formMode={formMode}
                                />
                            </Form.Item>
                        </SpaceAdapter>

                        <FormItemAdapter
                            fieldName={[
                                'trackProgressVisualization',
                                'progressTextSettings',
                                'fontName',
                            ]}
                        >
                            <SelectControl
                                className={styles.font}
                                values={FONT_NAMES_MAPPED}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    </div>
                )
            }
        </ShouldUpdateChecker>

        <Form.Item name={MENU_VISIBLE_FIELD}>
            <SwitchControl
                formMode={formMode}
                addonAfter={LOCAL.LABELS.MENU}
                disabled={disabled}
            />
        </Form.Item>

        <ShouldUpdateChecker fieldPath={MENU_VISIBLE_FIELD}>
            {({ getFieldValue }) =>
                getFieldValue(MENU_VISIBLE_FIELD) && (
                    <div className={styles.setup}>
                        {MENU_VISUALIZATION.map((el) => (
                            <FormItemAdapter
                                fieldName={[
                                    'trackMenuVisualization',
                                    el.fieldName,
                                ]}
                                key={el.label}
                            >
                                <CheckboxControl formMode={formMode}>
                                    {el.label}
                                </CheckboxControl>
                            </FormItemAdapter>
                        ))}

                        <FormItemAdapter
                            fieldName="questionnaire"
                            label={LOCAL.LABELS.QUESTIONNAIRE_ADD}
                        >
                            <SearchSelectControl<
                                QuestionnaireSearchContract,
                                | QuestionnaireContract
                                | QuestionnaireForModalContract
                            >
                                getByIdsMethod={QuestionnairesService.getByIds}
                                allowClearSelect
                                modalTitle={LOCAL.LABELS.SELECTION_QUIZ}
                                disabled={disabled}
                                formMode={formMode}
                                tableSearchOptions={{
                                    filterComponent: TrackQuestionnairesFilters,
                                    tableColumns: getTableColumnForName(
                                        LOCAL.LABELS.APPOINTMENT_NAME
                                    ),
                                    tableFiltersOptions: QUESTIONNAIRES_TABLE_FILTER_OPTIONS,
                                    searchMethod:
                                        QuestionnairesService.getForModal,
                                }}
                            />
                        </FormItemAdapter>

                        <SpaceAdapter className={styles.colorSetup}>
                            <Form.Item
                                name={[
                                    'trackMenuVisualization',
                                    'menuTextSettings',
                                    'textColor',
                                ]}
                            >
                                <ColorPickerControl
                                    label={LOCAL.LABELS.TEXT_COLOR}
                                    formMode={formMode}
                                />
                            </Form.Item>

                            <Form.Item
                                name={[
                                    'trackMenuVisualization',
                                    'backgroundColor',
                                ]}
                            >
                                <ColorPickerControl
                                    label={LOCAL.LABELS.BACKGROUND_COLOR}
                                    formMode={formMode}
                                />
                            </Form.Item>
                        </SpaceAdapter>

                        <FormItemAdapter
                            fieldName={[
                                'trackMenuVisualization',
                                'menuTextSettings',
                                'fontName',
                            ]}
                        >
                            <SelectControl
                                className={styles.font}
                                values={FONT_NAMES_MAPPED}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    </div>
                )
            }
        </ShouldUpdateChecker>
    </ControlGroup>
))
