import React from 'react'
import { LOCAL } from 'core/local'
import { ProductCardChildrenProps } from 'components/pages/ProductCard/ProductCard.types'
import { Title } from 'components/shared'

import styles from './ProductCardSecondary.module.scss'
import { ProductCardTeachers } from '../ProductCardTeachers'

/** Карточка продукта - блок "Для кого" */
export const ProductCardSecondary: React.FC<ProductCardChildrenProps> = React.memo(
    ({ pageInfo }) => (
        <div className={styles.wrapper}>
            {pageInfo?.targetAudience && (
                <>
                    <Title type="h6" text={`${LOCAL.LABELS.FOR_WHO}:`} />

                    <p>{pageInfo?.targetAudience}</p>
                </>
            )}

            {!!pageInfo?.teachers.length && (
                <ProductCardTeachers teachers={pageInfo.teachers} />
            )}
        </div>
    )
)
