import React from 'react'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { Popup } from 'components/shared'
import { safelySetInnerHtml } from 'utils'

import styles from './InformationPassingModal.module.scss'
import { InformationPassingModalProps } from './InformationPassingModal.types'

export const InformationPassingModal: React.FC<InformationPassingModalProps> = React.memo(
    ({ popupProps, selectedThematicBlock }) => (
        <Popup
            className={styles.wrapper}
            width={MODAL_WIDTH.XL}
            centered
            okText={LOCAL.LABELS.FURTHER}
            cancelText={LOCAL.ACTIONS.RETURN}
            title={selectedThematicBlock?.name}
            {...popupProps}
        >
            <div
                dangerouslySetInnerHTML={safelySetInnerHtml(
                    selectedThematicBlock?.informationBlockText ?? ''
                )}
            />
        </Popup>
    )
)
