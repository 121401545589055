import { LOCAL } from 'core/local'

export enum TabsKeys {
    Checking = 'checking',
    CommonStats = 'common-stats',
}

export interface TabsDataProps extends Record<'key', TabsKeys> {
    text: string
}

export const TABS_DATA: TabsDataProps[] = [
    {
        text: LOCAL.LABELS.CHECK,
        key: TabsKeys.Checking,
    },
    {
        text: LOCAL.LABELS.COMMON_STATISTIC,
        key: TabsKeys.CommonStats,
    },
]

export const DEFAULT_TAB_KEY = TABS_DATA[0].key
