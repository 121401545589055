import React, { useCallback } from 'react'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker } from 'components/controls'

import styles from './Conditions.module.scss'
import { ConditionForm } from './ConditionForm'
import { ConditionsProps } from './Conditions.types'
import { DynamicListCardControl } from '../DynamicListCardControl'
import { DynamicListItemRenderProps } from '../DynamicListCardControl/DynamicListCardControl.types'

/** Компонент списка условий отправки на форме Задания на рассылку */
export const Conditions: React.FC<ConditionsProps> = React.memo((props) => {
    const { form, formMode, conditions } = props

    const handleClear = useCallback(() => {
        form?.setFieldsValue({
            conditions: [],
        })
    }, [form])

    const renderConditionsItem = useCallback(
        ({ field, onRemove }: DynamicListItemRenderProps) => (
            <ConditionForm
                className={styles.item}
                form={form}
                formMode={formMode}
                key={field.key}
                field={field}
                conditions={conditions}
                onRemove={onRemove}
            />
        ),
        [form, formMode, conditions]
    )

    return (
        <ShouldUpdateChecker fieldPath={['conditions']}>
            {({ getFieldValue }) => (
                <DynamicListCardControl
                    listFieldName="conditions"
                    title={LOCAL.LABELS.CONDITION_SENDING}
                    onClear={handleClear}
                    renderListItem={renderConditionsItem}
                    formMode={formMode}
                    disabledAddBtn={
                        getFieldValue('conditions').length === conditions.length
                    }
                />
            )}
        </ShouldUpdateChecker>
    )
})
