import React, { FC } from 'react'
import { ButtonsToolbar, IconsAdapter, PopupWithForm } from 'components/shared'
import { DISTRIBUTION_TAGS_REQUIRED_PERMISSIONS, MODAL_WIDTH } from 'consts'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { UserPermission } from 'core/api'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

import { DistributionTagForm } from '../DistributionTagForm'
import { DistributionTagsRowControlsProps } from './DistributionTagsRowControl.types'

export const DistributionTagsRowControls: FC<DistributionTagsRowControlsProps> = React.memo(
    ({ tableRow, onEdit }) => (
        <ButtonsToolbar bottomIndent="0">
            <HasPermissions
                requiredPermissions={[
                    ...DISTRIBUTION_TAGS_REQUIRED_PERMISSIONS,
                    UserPermission.NotificationTagSave,
                ]}
                accessDeniedRender={renderAccessDeniedButton({
                    type: 'link',
                    icon: <IconsAdapter iconType="EditOutlined" />,
                })}
            >
                <PopupWithForm
                    component={(props) => (
                        <DistributionTagForm {...props} formMode="edit" />
                    )}
                    formId={`${FORM_IDS.EDIT_TAG_FORM}${tableRow.id}`}
                    onRequestFinish={onEdit}
                    buttonText=""
                    buttonOption={{
                        type: 'link',
                        icon: <IconsAdapter iconType="EditOutlined" />,
                        title: LOCAL.ACTIONS.EDIT,
                    }}
                    formOptions={{
                        initialValues: tableRow,
                    }}
                    modalOptions={{
                        title: LOCAL.MESSAGES.EDIT_DISTRIBUTION_TAG,
                        okText: LOCAL.ACTIONS.SAVE,
                        width: MODAL_WIDTH.MD,
                    }}
                />
            </HasPermissions>

            <HasPermissions
                requiredPermissions={[
                    ...DISTRIBUTION_TAGS_REQUIRED_PERMISSIONS,
                    UserPermission.NotificationTagDelete,
                ]}
                accessDeniedRender={renderAccessDeniedButton({
                    type: 'link',
                    icon: <IconsAdapter iconType="DeleteOutlined" />,
                })}
            >
                <PopupWithForm
                    formId={FORM_IDS.CONFIRM_DELETE_FORM}
                    buttonText=""
                    buttonOption={{
                        type: 'link',
                        icon: <IconsAdapter iconType="DeleteOutlined" />,
                        title: LOCAL.ACTIONS.DELETE,
                    }}
                    formOptions={{
                        initialValues: tableRow,
                    }}
                    modalOptions={{
                        title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                        okText: LOCAL.YES,
                    }}
                />
            </HasPermissions>
        </ButtonsToolbar>
    )
)
