import { LOCAL } from 'core/local'

import { downloadFileViaBlob } from './common.utils'

export const downloadXlsxReport = (file: BlobPart) =>
    downloadFileViaBlob(
        [file],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        LOCAL.LABELS.REPORT
    )
