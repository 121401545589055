import { useEffect, useRef } from 'react'

/**
 * Хук для использования ReactDOM.createPortal
 * @param selector селектор узла DOM, куда необходимо отрендерить целевой элемент
 * @param classes классы css в виде строки
 * @returns {Element} current useRef - обертка над элементом, который мы передаем в портал
 */
export function usePortal(selector: string, classes = ''): Element {
    const portalElemWrapper = useRef<Element | null>()

    useEffect(() => {
        if (!portalElemWrapper?.current) return

        const parentElem = document.querySelector(selector)
        parentElem?.appendChild(portalElemWrapper.current)

        return () => {
            portalElemWrapper?.current?.remove()
        }
    }, [selector])

    const getPortaElemWrapper = () => {
        if (portalElemWrapper.current) return portalElemWrapper.current

        portalElemWrapper.current = document.createElement('div')

        if (classes) {
            classes.split(' ').forEach((className) => {
                portalElemWrapper.current?.classList.add(className)
            })
        }

        return portalElemWrapper.current
    }

    return getPortaElemWrapper()
}
