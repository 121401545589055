import React, { useCallback, useEffect } from 'react'
import {
    ACTIVITIES_TABLE_COLUMNS,
    ActivitiesFilters,
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SearchSelectControl,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import {
    ActivitiesService,
    ActivityModalContract,
    ActivityModalSearchContract,
    AppointmentActivitySearchContract,
    StructuralUnitsService,
} from 'core/api'
import { Col, Row } from 'antd'
import { DictionariesCommonProps } from 'App.types'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import { FiltersProps, excludeSelectOptions } from 'utils'
import { LOCAL } from 'core/local'
import {
    ROW_GUTTER,
    TRACKS_APPOINTMENT_STATUSES_MAPPED,
    TREE_SELECT_NORMALIZE_TYPE,
} from 'consts'
import { renderFooterWithArchiveAndAutoAppointment } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionaries } from 'hooks'

import { EventsAssignmentsFiltersSubmitValuesProps } from './EventsAssignmentsFilters.types'
import { STATUSES_TO_EXCLUDE } from './EventsAssignmentsFilters.consts'

/** Компонент фильтров для формы назначения мероприятия */
export const EventsAssignmentsFilters: React.FC<FiltersProps<
    AppointmentActivitySearchContract
>> = React.memo((props) => {
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<'organizations'>
    >()

    const mapFormDataToRequest = useCallback(
        (values: Partial<EventsAssignmentsFiltersSubmitValuesProps>) => ({
            ...values,
            activityName: values.activity?.[0]?.label,
        }),
        []
    )

    useEffect(() => {
        handleFetchDictionaries({
            organizations: [
                StructuralUnitsService.getAllStructuralUnits(),
                TREE_SELECT_NORMALIZE_TYPE,
            ],
        })
    }, [handleFetchDictionaries])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                renderFooter={renderFooterWithArchiveAndAutoAppointment}
                mapFormDataToRequest={mapFormDataToRequest}
            >
                <Row align="bottom" gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="activity"
                            label={LOCAL.LABELS.EVENT_M_NAME}
                        >
                            <SearchSelectControl<
                                ActivityModalSearchContract,
                                ActivityModalContract
                            >
                                getByIdsMethod={ActivitiesService.getByIds}
                                tableSearchOptions={{
                                    searchMethod: ActivitiesService.getForModal,
                                    filterComponent: ActivitiesFilters,
                                    tableColumns: ACTIVITIES_TABLE_COLUMNS,
                                }}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={excludeSelectOptions(
                                    TRACKS_APPOINTMENT_STATUSES_MAPPED,
                                    STATUSES_TO_EXCLUDE
                                )}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitIds"
                            label={LOCAL.LABELS.ORGANIZATION}
                        >
                            <TreeSelectControl
                                treeData={dictionaries?.organizations || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: `${LOCAL.LABELS.DATE_START} (${LOCAL.SINCE})`,
                                fieldName: 'startDate',
                            }}
                            to={{
                                label: `${LOCAL.LABELS.DATE_END} (${LOCAL.TO})`,
                                fieldName: 'endDate',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
