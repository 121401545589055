import React, { FC } from 'react'
import { LOCAL } from 'core/local'
import { isHomeworkPassed } from 'utils'

import styles from './UserFields.module.scss'
import { UserFieldsProps } from './UserFields.types'

export const UserFields: FC<UserFieldsProps> = ({
    comment,
    currentStatus,
    isHomeworkAutoCheck,
}) => (
    <>
        {comment && (
            <pre className={styles.comment}>
                {`${LOCAL.LABELS.TUTOR_COMMENT}: \n${comment}`}
            </pre>
        )}

        {isHomeworkAutoCheck && isHomeworkPassed(currentStatus) && (
            <div className={styles.passedMessage}>
                {LOCAL.MESSAGES.HOMEWORK_AUTOCHECK_PASSED_MESSAGE}
            </div>
        )}
    </>
)
