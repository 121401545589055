import React, { useMemo } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import { ChildrenNodesWithSpoiler } from './ChildrenNodesWithSpoiler'
import { IndicatorsOrderOptionsProps } from './Indicators.types'

export const Indicators: React.FC<IndicatorsOrderOptionsProps> = React.memo(
    ({ groupData, groupIndex, competenceGroupIndex, className }) => {
        const indicatorsData = useMemo(
            () =>
                groupData.indicators?.map((indicator, index) => {
                    const key = `indicator-${indicator.id}`

                    return (
                        <ChildrenNodesWithSpoiler
                            key={key}
                            dndKey={key}
                            draggableId={key}
                            index={index}
                            name={indicator.name}
                        />
                    )
                }),
            [groupData.indicators]
        )

        return (
            <Droppable
                droppableId={`competence-${groupData.id}`}
                type={`${competenceGroupIndex}-${String(groupIndex)}`}
            >
                {(provided) => (
                    <div ref={provided.innerRef} className={className}>
                        {indicatorsData}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        )
    }
)
