import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SelectControl,
    TreeSelectControl,
} from 'components/controls'
import {
    DEPRECATED_QUIZ_TYPES,
    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED,
    QUIZ_TYPES_MAPPED,
    ROW_GUTTER,
} from 'consts'
import { DataNode } from 'rc-tree-select/lib/interface'
import { FilterForm, FromToDatesFilter } from 'components/shared'
import {
    FiltersProps,
    excludeSelectOptions,
    normalizeDataForTreeSelect,
} from 'utils'
import { LOCAL } from 'core/local'
import { QuizPortfolioSearchContract, StructuralUnitsService } from 'core/api'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'

/** Форма фильтрации портфелей опросов */
export const QuizPortfoliosFilters: React.FC<FiltersProps<
    QuizPortfolioSearchContract
>> = React.memo((props) => {
    const [structuralUnits, setStructuralUnits] = useState<DataNode[]>()

    useEffect(() => {
        const dictionaryFetch = async () => {
            try {
                const structuralUnitsData = await StructuralUnitsService.getAllStructuralUnits()
                setStructuralUnits(
                    structuralUnitsData.map(normalizeDataForTreeSelect)
                )
            } catch (error) {
                console.error(error)
            }
        }

        dictionaryFetch()
    }, [])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                renderFooter={renderFooterWithShowArchiveField}
            >
                <Row gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="structuralUnitIds"
                            label={LOCAL.LABELS.ORGANIZATIONS}
                        >
                            <TreeSelectControl
                                treeData={structuralUnits || []}
                                multiple
                                placeholder={LOCAL.PLACEHOLDERS.SEARCH_SELECT}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="quizType"
                            label={LOCAL.LABELS.TYPE}
                        >
                            <SelectControl
                                values={excludeSelectOptions(
                                    QUIZ_TYPES_MAPPED,
                                    DEPRECATED_QUIZ_TYPES
                                )}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={
                                    DOCUMENT_STATUSES_WITHOUT_ARCHIVE_MAPPED
                                }
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changeDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changeDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
