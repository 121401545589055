import React from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { SpoilerTitleRenderProps } from 'components/shared/Spoiler/Spoiler.types'
import { isFormModeView } from 'utils'

import styles from './AssessmentProjectPortfolio.module.scss'
import { RenderPortfolioTitleProps } from './AssessmentProjectPortfolio.types'

/**
 * Рендер заголовка портфеля
 */
export const renderPortfolioTitle = ({
    portfolio,
    formMode,
    handleRemovePortfolio,
}: RenderPortfolioTitleProps) => ({
    icon,
    toggleSpoilerVisible,
}: SpoilerTitleRenderProps) => (
    <div className={styles.title}>
        <a onClick={toggleSpoilerVisible} href="#s">
            {icon}

            <span className={styles.titleSpoilerName}>{portfolio?.name}</span>
        </a>

        <Link
            to={`${ROUTE_NAMES.ASSESSMENT_PORTFOLIOS_VIEW}/${portfolio?.id}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Button
                type="link"
                icon={<IconsAdapter iconType="EyeOutlined" />}
            />
        </Link>

        <Button
            type="link"
            disabled={isFormModeView(formMode)}
            icon={
                <IconsAdapter
                    iconType="DeleteOutlined"
                    onClick={handleRemovePortfolio}
                />
            }
        />
    </div>
)
