import React, { FC } from 'react'
import { ControlGroup } from 'components/controls'
import { DATE_FORMAT, TEMPLATE_STATUSES } from 'consts'
import { LOCAL } from 'core/local'
import { getDateByFormat, getFullFio } from 'utils'

import { DistributionTemplateInfoProps } from './DistributionTemplateInfo.types'

/** Блок для отображения информации о шаблоне */
export const DistributionTemplateInfo: FC<DistributionTemplateInfoProps> = React.memo(
    ({ template }) => (
        <ControlGroup type="bordered" title={LOCAL.LABELS.TEMPLATE_INFO}>
            <p>
                {`${LOCAL.LABELS.TEMPLATE_STATUS}: `}

                <b>{TEMPLATE_STATUSES[template.status]}</b>
            </p>

            <p>
                {`${LOCAL.LABELS.CHANGED}: ${getDateByFormat(
                    template.changeDate,
                    DATE_FORMAT.WITH_TIME
                )} ${getFullFio(template.changeUser)}`}
            </p>
        </ControlGroup>
    )
)
