import cn from 'classnames'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { Affix, Collapse } from 'antd'
import { Button } from 'components/shared/Button'
import {
    CompetencesPublicQuestionsContract,
    DictionaryContract,
} from 'core/api'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { backwardFn } from 'utils'
import { useBreakpoint } from 'hooks'

import styles from './PassingMenu.module.scss'
import { OFFSET } from '../UserAssignmentPassing/UserAssignmentPassing.consts'
import { PassingMenuButton } from '../PassingMenuButton'
import { PassingMenuProps } from './PassingMenu.types'
import { getAllIds, scrollTo } from './PassingMenu.utils'
import { getCompetenceBlockId } from '../PassingCompetence/PassingCompetence.utils'

/** Навигационное меню прохождения назначения пользователя */
export const PassingMenu: FC<PassingMenuProps> = memo(
    ({
        groupedQuestions,
        activeCompetenceContract,
        assessmentPortfolioPresentation,
        setActiveCompetenceContract,
        competencesStats,
    }) => {
        const [isOpen, setIsOpen] = useState(true)
        const isWidthLessLG = useBreakpoint('LG')

        /** Переключаем видимость меню */
        const toggleMenuVisibility = useCallback(() => {
            setIsOpen(backwardFn)
        }, [])

        /** Скроллим до нужной компетенции по клику */
        const goToCompetence = useCallback((competence: DictionaryContract) => {
            const elementPosition = document
                .getElementById(getCompetenceBlockId(competence))
                ?.getBoundingClientRect().top
            if (elementPosition) {
                const neededPosition =
                    elementPosition + window.pageYOffset - OFFSET
                scrollTo(neededPosition)
            }
        }, [])

        /** Обрабатываем клик по компетенции */
        const handleCompetenceClick = useCallback(
            (competenceContract: CompetencesPublicQuestionsContract) => {
                if (assessmentPortfolioPresentation?.showQuestionsByBlock) {
                    setActiveCompetenceContract(competenceContract)
                    scrollTo(0)
                } else {
                    goToCompetence(competenceContract.competence)
                }
            },
            [
                assessmentPortfolioPresentation,
                goToCompetence,
                setActiveCompetenceContract,
            ]
        )

        useEffect(() => {
            if (isWidthLessLG) setIsOpen(false)
        }, [isWidthLessLG])

        return (
            <Affix
                offsetTop={90}
                className={cn(styles.affix, isOpen && styles.affixOpen)}
            >
                <div
                    className={cn(styles.wrapper, isOpen && styles.wrapperOpen)}
                >
                    <Button
                        onClick={toggleMenuVisibility}
                        type="text"
                        className={styles.button}
                    >
                        <IconsAdapter
                            iconType={isOpen ? 'CloseOutlined' : 'MenuOutlined'}
                        />
                    </Button>

                    <div
                        className={cn(
                            styles.content,
                            isOpen && styles.contentOpen
                        )}
                    >
                        <Collapse
                            ghost
                            defaultActiveKey={getAllIds(groupedQuestions)}
                        >
                            {groupedQuestions.map((questionGroup) => (
                                <Collapse.Panel
                                    header={questionGroup.competenceGroup.name}
                                    key={questionGroup.competenceGroup.id}
                                    collapsible="header"
                                >
                                    {questionGroup.competenceQuestions.map(
                                        (competenceContract) => (
                                            <PassingMenuButton
                                                onCompetenceClick={
                                                    handleCompetenceClick
                                                }
                                                competenceContract={
                                                    competenceContract
                                                }
                                                activeCompetenceContract={
                                                    activeCompetenceContract
                                                }
                                                competencesStats={
                                                    competencesStats
                                                }
                                                key={
                                                    competenceContract
                                                        .competence.id
                                                }
                                            />
                                        )
                                    )}
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </div>
                </div>
            </Affix>
        )
    }
)
