import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { CompetenceGroupContract } from 'core/api'
import { DOCUMENT_STATUSES } from 'consts'
import { ElementWithListPopover } from 'components/shared'
import { LOCAL } from 'core/local'
import { extractNames, restrictBigText } from 'utils'

import { CompetenciesGroupsActions } from '../components'

/** настройка отображения колонок для справочника групп компетенций */
export const COMPETENCIES_GROUPS_TABLE_COLUMNS: ColumnsType<CompetenceGroupContract> = [
    {
        title: LOCAL.LABELS.COMPETENCIES_GROUP_NAME,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.COMPETENCIES_COUNT,
        dataIndex: 'competenceCount',
        key: 'competenceCount',
        render: (competenceCount, record) => (
            <ElementWithListPopover
                title={competenceCount}
                items={extractNames(record.competencies)}
                options={{
                    showPopoverAlways: true,
                }}
            />
        ),
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => DOCUMENT_STATUSES[record.status],
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => <CompetenciesGroupsActions tableRow={tableRow} />,
        align: 'right',
        fixed: 'right',
    },
]
