import React from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'

import styles from './StaffUnits.module.scss'
import { NormalizeStructuralUnitPositionsResultProps } from '../Organizations/Organizations.types'

export const renderPositionProfile = (
    positionProfile: boolean,
    record: NormalizeStructuralUnitPositionsResultProps
) => {
    if (record.unitType) return

    const registrLink = `${ROUTE_NAMES.POSITION_PROFILES}?unit=${record.staffUnitId}&register=staffUnits`
    const createLink = `${ROUTE_NAMES.POSITION_PROFILES}/${ROUTES_POSTFIXES.CREATE}/${record.staffUnitId}`

    return positionProfile ? (
        <Link to={registrLink} className={styles.showProfiles}>
            <IconsAdapter iconType="CustomIconTree" />

            {LOCAL.TO_REGISTER}
        </Link>
    ) : (
        <Link to={createLink} className={styles.createProfile}>
            <IconsAdapter iconType="CustomIconPlus" />

            {LOCAL.ACTIONS.ADD_PROFILE}
        </Link>
    )
}
