import React from 'react'
import { ControlGroup } from 'components/controls'
import { Form } from 'antd'

import styles from './QuestionnaireListGrouped.module.scss'
import { PassingNavigation } from '../PassingNavigation'
import { QuestionnaireListGroupedProps } from './QuestionnaireListGrouped.types'
import { QuestionnairePassingAnswer } from '../QuestionnairePassingAnswer'

/** Прохождение вопросы сгруппированных по опросным листам */
export const QuestionnaireListGrouped: React.FC<QuestionnaireListGroupedProps> = React.memo(
    ({
        selectedListIndex,
        setSelectedListIndex,
        questionnaireListQuestions,
        confirmedAnswers,
        disabledQuestionsTimer,
        getBlockedByQuestionEndToEndNumber,
        onConfirmAnswers,
        isQuestionsListCompleted,
    }) => (
        <ControlGroup className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.questions}>
                    {questionnaireListQuestions[selectedListIndex || 0].map(
                        (question) => (
                            <Form.Item
                                name={['answers', String(question.id)]}
                                key={question.id}
                            >
                                <QuestionnairePassingAnswer
                                    question={question}
                                    confirmedAnswer={confirmedAnswers.find(
                                        (el) => el.questionId === question.id
                                    )}
                                    getBlockedByQuestionEndToEndNumber={
                                        getBlockedByQuestionEndToEndNumber
                                    }
                                    onConfirmAnswers={onConfirmAnswers}
                                    disabledQuestionsTimer={
                                        disabledQuestionsTimer
                                    }
                                />
                            </Form.Item>
                        )
                    )}
                </div>

                <PassingNavigation
                    confirmedAnswers={confirmedAnswers}
                    questionnaireListQuestions={questionnaireListQuestions}
                    selectedListIndex={selectedListIndex}
                    setSelectedListIndex={setSelectedListIndex}
                    isQuestionsListCompleted={isQuestionsListCompleted}
                />
            </div>
        </ControlGroup>
    )
)
