import React from 'react'
import capitalize from 'lodash/capitalize'
import cn from 'classnames'
import { TooltipAdapter } from 'components/shared/TooltipAdapter'

import styles from './ControlGroup.module.scss'
import { ControlGroupProps } from './ControlGroup.types'

/**
 * Группировка для контролов
 */
export const ControlGroup: React.FC<ControlGroupProps> = React.memo(
    ({
        children,
        type,
        className,
        title,
        noPadding,
        titleOutside,
        titleClassName,
        tooltipText,
        required,
    }) => {
        const titleInside = (
            <h4
                className={cn(styles.title, titleClassName, {
                    'required-control': required,
                })}
            >
                {title}
            </h4>
        )

        return (
            <div className={className}>
                {titleOutside && (
                    <h3
                        className={cn(styles.titleOutside, {
                            'required-control': required,
                        })}
                    >
                        {titleOutside}
                    </h3>
                )}

                <div
                    className={cn(
                        styles.content,
                        type && styles[`content${capitalize(type)}`],
                        noPadding && styles.contentNoPadding
                    )}
                >
                    {title &&
                        (tooltipText ? (
                            <TooltipAdapter title={tooltipText}>
                                {titleInside}
                            </TooltipAdapter>
                        ) : (
                            titleInside
                        ))}

                    {children}
                </div>
            </div>
        )
    }
)
