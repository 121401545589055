import React from 'react'
import {
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { CertificateSearchResultContract } from 'core/api'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TableActionsCommonProps } from 'App.types'

/** Компонент для создания колонки действий внутри таблицы реестра внешних сертификатов */
export const ExternalCertificatesTableActions: React.FC<TableActionsCommonProps<
    CertificateSearchResultContract
>> = React.memo(({ tableRow }) => (
    <ButtonsToolbar bottomIndent="0">
        <TableActions.View
            href={`${ROUTE_NAMES.EXTERNAL_CERTIFICATES_VIEW}/${tableRow.userId}/${tableRow.id}`}
        />

        <TableActions.Edit
            href={`${ROUTE_NAMES.EXTERNAL_CERTIFICATES_EDIT}/${tableRow.userId}/${tableRow.id}`}
        />

        <PopupWithForm
            formId={FORM_IDS.CONFIRM_DELETE_FORM}
            buttonText=""
            havePopup={false}
            buttonOption={{
                type: 'link',
                icon: <IconsAdapter iconType="DeleteOutlined" />,
            }}
            formOptions={{
                initialValues: tableRow,
            }}
            modalOptions={{
                title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                okText: LOCAL.YES,
            }}
        />
    </ButtonsToolbar>
))
