import React from 'react'
import { LOCAL } from 'core/local'
import { UploadFile } from 'antd/lib/upload/interface'
import { UserBaseContract } from 'core/api'
import { getNumFormattedToView, getShortFio } from 'utils'

import styles from './UploadCourseValidationConfirm.module.scss'

/**
 * Привести значение размера файла к подходящему для вывода в UI
 * @param file объект файла
 */
export const normalizeFileNameToView = ({ size, name }: UploadFile) => {
    const formattedFileSize = `(${getNumFormattedToView(
        Number(size) / 1024
    )} КБ)`

    return (
        <div className={styles.info}>
            <div className={styles.name} title={name}>
                {name}
            </div>

            <div className={styles.size}>{formattedFileSize}</div>
        </div>
    )
}

/**
 * Формируем сообщение при валидации
 * @param isAlreadyExists флаг, определяющий тип валидации
 * @param isAlreadyExistsAndUsingAppointment
 * @param processingUser данные юзера, который быстрее поставил файл на обработку
 */
export const getValidationMessage = (
    isAlreadyExists: boolean,
    isAlreadyExistsAndUsingAppointment: boolean,
    processingUser?: UserBaseContract
) => {
    if (isAlreadyExists)
        return LOCAL.MESSAGES.SELECTED_FILE_FOR_OWNER_ALREADY_EXISTS

    if (isAlreadyExistsAndUsingAppointment) {
        return (
            <>
                <div>
                    {LOCAL.MESSAGES.SELECTED_FILE_FOR_OWNER_ALREADY_EXISTS}
                </div>

                <div>{LOCAL.MESSAGES.FILE_USED_IN_ACTIVE_IN_APPOINTMENT}</div>
            </>
        )
    }

    return `${
        LOCAL.MESSAGES.SELECTED_FILE_FOR_OWNER_IS_PROGRESS
    }: ${getShortFio(processingUser)}`
}
