import React from 'react'
import { Title } from 'components/shared'

import styles from './ChartsHistogramGroup.module.scss'
import { ChartRows } from '../ChartRows'
import { RenderChartsProps } from './ChartsHistogramGroup.types'

export const renderChart = ({
    scaleDifference,
    minScore,
    question,
}: RenderChartsProps) =>
    question.grafics.length ? (
        <div key={question.subHead}>
            <Title type="h6" className={styles.title} text={question.subHead} />

            <ChartRows
                grafics={question.grafics}
                scaleDifference={scaleDifference}
                scaleFrom={minScore}
                isScale={!question.subHead}
            />
        </div>
    ) : null
