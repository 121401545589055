import React, { useCallback } from 'react'
import { AppError, TrackDisplayPicturesService } from 'core/api'
import { ComponentCommonProps } from 'App.types'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    TextAreaControl,
    UploadDraggerImageControl,
    getLastFileFromList,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { RcCustomRequestOptions } from 'types'
import { TooltipAdapter } from 'components/shared'
import {
    getStringWithoutDots,
    isFormModeView,
    validateUploadImage,
} from 'utils'

import styles from './DescriptionArea.module.scss'
import stylesOverallSettings from '../../shared/OverallSettings/OverallSettings.module.scss'
import { CoursometerRegistration } from '../CoursometerRegistration'
import {
    IMAGES_DOCUMENT_TYPE,
    VALIDATE_FILE_FORMATS,
} from './DescriptionArea.consts'

/**
 * Компонент с описанием трека
 */
export const DescriptionArea: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => {
        /**
         * Обработчик добавления файла
         * @param request - тело запроса
         */
        const uploadImage = useCallback(
            async (request: RcCustomRequestOptions) => {
                try {
                    const result = await TrackDisplayPicturesService.upload({
                        file: request.file,
                    })

                    request.onSuccess?.(result, {} as XMLHttpRequest)
                } catch (error) {
                    request.onError?.(error as AppError)
                }
            },
            []
        )

        return (
            <ControlGroup
                title={LOCAL.LABELS.DESCRIPTION}
                className={stylesOverallSettings.cardBorderless}
            >
                <FormItemAdapter
                    fieldName="name"
                    label={LOCAL.LABELS.TRACK_NAME_LONG}
                    required
                >
                    <InputControl formMode={formMode} />
                </FormItemAdapter>

                <FormItemAdapter
                    fieldName="title"
                    label={LOCAL.LABELS.TRACK_TITLE}
                    required
                >
                    <InputControl formMode={formMode} />
                </FormItemAdapter>

                <FormItemAdapter
                    fieldName="description"
                    label={LOCAL.LABELS.DESCRIPTION}
                >
                    <TextAreaControl
                        autoSize={{ minRows: 4, maxRows: 4 }}
                        formMode={formMode}
                    />
                </FormItemAdapter>

                <CoursometerRegistration formMode={formMode} />

                <TooltipAdapter
                    title={getStringWithoutDots(IMAGES_DOCUMENT_TYPE)}
                >
                    <FormItemAdapter
                        fieldName="trackYourDevelopmentDisplayId"
                        label={LOCAL.LABELS.TRACK_DISPLAY}
                        valuePropName="fileList"
                        getValueFromEvent={getLastFileFromList}
                        className="mt-15"
                    >
                        <UploadDraggerImageControl
                            accept={IMAGES_DOCUMENT_TYPE}
                            customRequest={uploadImage}
                            beforeUpload={(file, fileList) =>
                                validateUploadImage(
                                    file,
                                    fileList,
                                    VALIDATE_FILE_FORMATS
                                )
                            }
                            disabled={isFormModeView(formMode)}
                            className={styles.uploadImgArea}
                        />
                    </FormItemAdapter>
                </TooltipAdapter>
            </ControlGroup>
        )
    }
)
