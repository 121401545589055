import React, { MouseEvent, ReactNode, useCallback } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { useElementVisible } from 'hooks'

import styles from './QuestionsNavigation.module.scss'
import { QuestionsNavigationProps } from './QuestionsNavigation.types'

export const QuestionsNavigation: React.FC<QuestionsNavigationProps> = React.memo(
    ({ questions }) => {
        const {
            elementVisible,
            toggleElementVisible,
            toggleElementVisibleRef,
            elementRef,
        } = useElementVisible()

        /**
         * Обработчик скролла к спойлеру вопроса
         */
        const handleScrollToQuestion = useCallback(
            (index: number) => (e: MouseEvent) => {
                e.preventDefault()

                const anchor = document.getElementById(`question-${index + 1}`)

                anchor?.scrollIntoView()
            },
            []
        )

        /**
         * Обработчик скролла к началу формы
         */
        const handleScrollToFormStart = useCallback((e: MouseEvent) => {
            e.preventDefault()

            const anchor = document.getElementById('root')

            anchor?.scrollIntoView()
        }, [])

        /**
         * Маппинг для вопросов
         */
        const getQuestionsMapped = useCallback(
            (count?: number) => {
                if (!questions) return

                const getNode = (name: number) => (
                    <a
                        className={styles.catapult}
                        onClick={handleScrollToQuestion(name)}
                        href="#s"
                        key={name}
                    >
                        {name + 1}
                    </a>
                )

                if (count) {
                    const questionsNodes: ReactNode[] = []

                    for (let i = 0; i < questions.length; i += 1) {
                        if (i === count) {
                            questionsNodes.push(
                                <span
                                    className={styles.catapultAll}
                                    onClick={toggleElementVisible}
                                    ref={toggleElementVisibleRef}
                                    key="all"
                                >
                                    {'...'}
                                </span>
                            )

                            break
                        }

                        questionsNodes.push(getNode(questions[i]?.name))
                    }

                    return questionsNodes
                }

                return questions.map((question) => getNode(question.name))
            },
            [
                toggleElementVisible,
                handleScrollToQuestion,
                questions,
                toggleElementVisibleRef,
            ]
        )

        return (
            <div className={styles.wrapper}>
                <IconsAdapter
                    className={styles.scrollUp}
                    iconType="VerticalAlignTopOutlined"
                    onClick={handleScrollToFormStart}
                />

                <span className={styles.title}>
                    {`${LOCAL.LABELS.GO_TO_QUESTION}:`}
                </span>

                <div className={styles.shortList}>{getQuestionsMapped(10)}</div>

                {elementVisible && (
                    <div className={styles.allQuestions} ref={elementRef}>
                        {getQuestionsMapped()}
                    </div>
                )}
            </div>
        )
    }
)
