import React, { useCallback } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Button } from 'components/shared/Button'
import { Form, Space } from 'antd'
import {
    FormItemAdapter,
    InputControl,
    InputNumberControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'

import styles from './ScaleValuesList.module.scss'
import { getMinValue } from './ScaleValuesList.utils'

/** Список значений шкалы */
export const ScaleValuesList: React.FC = React.memo(() => {
    /**
     * Обработчик добавления значения
     * @param {FormListOperation} объект с возможными вариантами операций (добавление, удаление)
     */
    const handleAddValue = useCallback(
        ({ add }: Partial<FormListOperation>) => () => {
            add?.()
        },
        []
    )

    /**
     * Обработчик удаления значения
     * @param {FormListOperation} объект с возможными вариантами операций (добавление, удаление)
     */
    const handleRemoveValue = useCallback(
        (
            { remove }: Partial<FormListOperation>,
            value: FormListFieldData
        ) => () => {
            remove?.(value.name)
        },
        []
    )

    return (
        <Form.List name="scaleValues">
            {(values, { add, remove }) => (
                <>
                    {values.map((value, index) => (
                        <Space
                            key={value.key}
                            className={styles.wrapper}
                            align="baseline"
                            size={30}
                        >
                            <FormItemAdapter
                                fieldName={[value.name, 'description']}
                                label={LOCAL.LABELS.VALUE}
                                required
                            >
                                <InputControl />
                            </FormItemAdapter>

                            <ShouldUpdateChecker fieldPath={['isSemantic']}>
                                {({ getFieldValue }) => {
                                    const minValue = getFieldValue('isSemantic')

                                    return (
                                        <FormItemAdapter
                                            fieldName={[
                                                value.name,
                                                'displayValue',
                                            ]}
                                            label={LOCAL.LABELS.BALL}
                                        >
                                            <InputNumberControl
                                                placeholder={
                                                    LOCAL.PLACEHOLDERS.INPUT
                                                }
                                                min={getMinValue(minValue)}
                                            />
                                        </FormItemAdapter>
                                    )
                                }}
                            </ShouldUpdateChecker>

                            {index > 1 && (
                                <Button
                                    onClick={handleRemoveValue(
                                        { remove },
                                        value
                                    )}
                                    type="link"
                                    icon={
                                        <IconsAdapter iconType="CloseOutlined" />
                                    }
                                    className={styles.button}
                                />
                            )}
                        </Space>
                    ))}

                    <AddButton
                        onClick={handleAddValue({ add })}
                        buttonText={LOCAL.ACTIONS.ADD_VALUE}
                    />
                </>
            )}
        </Form.List>
    )
})
