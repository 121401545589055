import { ReactNode } from 'react'
import { ReportShortCodes } from 'consts'

import { AreasStrengthsDevelopment } from '../AreasStrengthsDevelopment'
import { AssessmentsRespondentsIndicators } from '../AssessmentsRespondentsIndicators'
import { CommentsOfRespondents } from '../CommentsOfRespondents'
import { CompetenceAssessmentResults } from '../CompetenceAssessmentResults'
import { CompetenceRating } from '../CompetenceRating'
import { CompetenciesLowHighLevel } from '../CompetenciesLowHighLevel'
import { ComplianceProfilePosition } from '../ComplianceProfilePosition'
import { GroupsOfRespondents } from '../GroupsOfRespondents'
import { HiddenOpportunitiesProblems } from '../HiddenOpportunitiesProblems'
import { ObjectInformation } from '../ObjectInformation'
import { ObjectInformationAssessment360 } from '../ObjectInformationAssessment360'
import { Percentile } from '../Percentile'
import { SpiderWebDiagramAssessment360 } from '../SpiderWebDiagramAssessment360'
import {
    TableOfDevelopment,
    TableOfDevelopmentWithProfilePosition,
} from '../TableOfDevelopment'
import { TablesCompetenciesIndicators } from '../TablesCompetenciesIndicators'

export const SHORT_CODES_COMPONENTS: {
    [key in ReportShortCodes]: ReactNode
} = {
    // Шорткоды для отчета 360
    [ReportShortCodes.ObjectInformationAssessment_360]: ObjectInformationAssessment360,
    [ReportShortCodes.GroupsOfRespondents]: GroupsOfRespondents,
    [ReportShortCodes.SpiderWebDiagramAssessment360]: SpiderWebDiagramAssessment360,
    [ReportShortCodes.CompetenceRating]: CompetenceRating,
    [ReportShortCodes.CompetenciesLowHighLevel]: CompetenciesLowHighLevel,
    [ReportShortCodes.AssessmentsRespondentsIndicators]: AssessmentsRespondentsIndicators,
    [ReportShortCodes.AreasStrengthsDevelopment]: AreasStrengthsDevelopment,
    [ReportShortCodes.HiddenOpportunitiesProblems]: HiddenOpportunitiesProblems,
    [ReportShortCodes.CommentsOfRespondents]: CommentsOfRespondents,
    [ReportShortCodes.Percentile]: Percentile,

    // Шорткоды для отчета по результатам оценки руководителя
    [ReportShortCodes.ObjectInformationManagerAssessment]: ObjectInformation,
    [ReportShortCodes.SpiderWebDiagramManagerAssessment]: CompetenceAssessmentResults,
    [ReportShortCodes.TablesCompetenciesIndicators]: TablesCompetenciesIndicators,
    [ReportShortCodes.ComplianceProfilePosition]: ComplianceProfilePosition,
    [ReportShortCodes.TableOfDevelopmentWithProfilePosition]: TableOfDevelopmentWithProfilePosition,
    [ReportShortCodes.TableOfDevelopmentWithoutProfilePosition]: TableOfDevelopment,
}
