import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
    AppError,
    IndicatorCreateContract,
    IndicatorTypesService,
    IndicatorsService,
    UserPermission,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import {
    FormItemAdapter,
    InputControl,
    SelectControl,
    TextAreaControl,
} from 'components/controls'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { isDocumentStatusDraft, normalizeDataForSelectAndRadio } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { withLoader } from 'HOCs'

/**
 * Форма добавления/редактирования индикатора компетенции
 */
export const CompetenciesIndicatorForm: FC<PopupAdapterFormProps> = withLoader(
    React.memo(
        ({
            onRequestFinish,
            deleteFormAction,
            initialValues,
            recordCopy,
            rowSelectionType,
            onCancelSubmit,
            messageText,
            updateLoader,
            updateButtonVisibility,
            ...props
        }) => {
            const [indicatorsTypes, setIndicatorsTypes] = useState<
                LabeledValue[]
            >([])

            /**
             * Стейт для управления типом отправки формы (сохранение/публикация)
             */
            const [isSaveDocument, setSaveDocument] = useState(false)

            /**
             * Обработчик сохранения/публикации документа
             */
            const handleSubmitForm = useCallback(
                (type: boolean) => () => {
                    setSaveDocument(type)
                },
                []
            )

            /**
             * Запрос справочника группы компетенций
             */
            const dictionaryFetch = useCallback(async () => {
                try {
                    const dataSource = await IndicatorTypesService.getAllIndicatorsType()

                    setIndicatorsTypes(
                        dataSource.map(normalizeDataForSelectAndRadio)
                    )
                } catch (error) {
                    console.error(error)
                }
            }, [])

            useEffect(() => {
                dictionaryFetch()
            }, [dictionaryFetch])

            /**
             * Параметры отправки формы
             */
            const getRequestBody = useCallback(
                (values: Partial<IndicatorCreateContract>) => ({
                    ...values,
                    id: !recordCopy ? initialValues?.id : undefined,
                }),
                [initialValues, recordCopy]
            )

            /**
             * Обработчик отправки формы
             */
            const handleFinish = useCallback(
                async (values: Partial<IndicatorCreateContract>) => {
                    try {
                        updateLoader(true)
                        updateButtonVisibility?.(true)
                        const body = {
                            body: getRequestBody(
                                values
                            ) as IndicatorCreateContract,
                        }

                        if (isSaveDocument) {
                            onRequestFinish?.(
                                await IndicatorsService.save(body)
                            )

                            return
                        }

                        onRequestFinish?.(await IndicatorsService.publish(body))
                    } catch (error) {
                        console.error(error as AppError)
                    } finally {
                        updateLoader(false)
                        updateButtonVisibility?.(false)
                    }
                },
                [
                    getRequestBody,
                    isSaveDocument,
                    onRequestFinish,
                    updateButtonVisibility,
                    updateLoader,
                ]
            )

            const initialValuesNormilized = useMemo(
                () => ({
                    ...initialValues,
                    name: !recordCopy
                        ? initialValues?.name
                        : `${initialValues?.name} ${LOCAL.LABELS.COPY}`,
                    indicatorTypeId: initialValues?.indicatorType?.id,
                }),
                [initialValues, recordCopy]
            )

            const isSavable = useMemo(
                () =>
                    isDocumentStatusDraft(initialValues?.status) ||
                    (!initialValues?.status && !initialValues?.fromCompetence),
                [initialValues]
            )

            return (
                <>
                    <Form
                        onFinish={handleFinish}
                        {...props}
                        initialValues={initialValuesNormilized}
                    >
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                            required
                        >
                            <InputControl />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="indicatorTypeId"
                            label={LOCAL.LABELS.INDICATOR_TYPE}
                            required
                        >
                            <SelectControl values={indicatorsTypes} />
                        </FormItemAdapter>

                        <FormItemAdapter
                            fieldName="description"
                            label={LOCAL.LABELS.DESCRIPTION}
                        >
                            <TextAreaControl />
                        </FormItemAdapter>
                    </Form>

                    <ButtonsToolbar bottomIndent="0">
                        <Button type="link" onClick={onCancelSubmit}>
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        {isSavable && (
                            <HasPermissions
                                requiredPermissions={[
                                    UserPermission.IndicatorRead,
                                    UserPermission.IndicatorSave,
                                ]}
                                accessDeniedRender={renderAccessDeniedButton({
                                    type: 'primary',
                                    text: LOCAL.ACTIONS.SAVE,
                                })}
                            >
                                <Button
                                    form={FORM_IDS.COMPETENCIES_INDICATOR_FORM}
                                    type="primary"
                                    htmlType="submit"
                                    onClick={handleSubmitForm(true)}
                                >
                                    {LOCAL.ACTIONS.SAVE}
                                </Button>
                            </HasPermissions>
                        )}

                        <HasPermissions
                            requiredPermissions={[
                                UserPermission.IndicatorRead,
                                UserPermission.IndicatorPublish,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                type: 'primary',
                                text: LOCAL.ACTIONS.PUBLISH,
                            })}
                        >
                            <Button
                                form={FORM_IDS.COMPETENCIES_INDICATOR_FORM}
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmitForm(false)}
                            >
                                {LOCAL.ACTIONS.PUBLISH}
                            </Button>
                        </HasPermissions>
                    </ButtonsToolbar>
                </>
            )
        }
    )
)
