import { ChartDataset } from 'chart.js'
import {
    CompetenceContract,
    PositionProfileCompetenceGroupContract,
} from 'core/api'
import { PositionProfileCompetenceOptionalContract } from 'components/pages/JobProfiles/components/CompetenceItemsControl/CompetenceItemsControl.types'
import { Store } from 'App.types'

import { getRoundedOneCharNumber } from './common.utils'

/** УТИЛИТЫ ДЛЯ РАБОТЫ С КОМПЕТЕНЦИЯМИ */

export interface GroupedCompetenciesGroupValueProps<T> {
    groupId: number
    groupName: string
    order: number
    children: T[]
}

export interface GroupedCompetenciesGroupProps<T>
    extends Store<GroupedCompetenciesGroupValueProps<T>> {}

type GroupedCompetenciesCompetence = {
    competenceGroup: PositionProfileCompetenceGroupContract
    competenceLevel?: number
    defaultValue?: number
    averageScoreWithoutSelfEstim?: number
}

/**
 * Получить компетенции в сгруппированном виде
 * @param competencies - массив компетенций
 */
export const getGroupedCompetencies = <T extends GroupedCompetenciesCompetence>(
    competencies?: T[]
): GroupedCompetenciesGroupValueProps<T>[] | undefined => {
    const competenciesGroups = competencies?.reduce(
        (group: GroupedCompetenciesGroupProps<T>, competence: T) => ({
            ...group,
            [competence.competenceGroup.id]: {
                groupId: competence.competenceGroup.id,
                groupName: competence.competenceGroup.name,
                order: competence.competenceGroup.order,
                children: [
                    ...(group[competence.competenceGroup.id]?.children || []),
                    {
                        ...competence,
                        defaultValue:
                            competence.competenceLevel ||
                            competence.averageScoreWithoutSelfEstim,
                    },
                ],
            },
        }),
        {}
    )

    return competenciesGroups && Object.values(competenciesGroups)
}

/**
 * Получить из компетенции формата CompetenceContract компетенцию PositionProfileCompetenceContract
 * @param competence - единица компетенции для преобразования
 */
export const getPositionProfileCompetenciesFromCompetenceContract = (
    competence: CompetenceContract
): PositionProfileCompetenceOptionalContract => ({
    id: competence.id,
    name: competence.name,
    competenceGroup: competence.competenceGroup,
    description: competence.description,
    departmentId: competence.department.id,
    isAdjacent: true,
    order: competence.order,
})

/**
 * Получить значение полей объекта компетенции, которые содержат одну из оценок уровня данной
 * компетенции
 * @param scoreProperty поле объекта компетенции, содержащее оценку
 * @param competenciesCollection коллекция компетенций
 * @returns {number[]} массив оценок из всех компетенций в коллекции для текущего свойства
 */
export const getCompetenceCertainPropertyScore = <
    T extends Partial<keyof D>,
    D extends { [key in T]: number }
>(
    scoreProperty: T,
    competenciesCollection: D[]
): number[] =>
    competenciesCollection.map((competence) =>
        Number(getRoundedOneCharNumber(competence[scoreProperty]))
    )

/** Интерфейс объекта с настройками для генерации dataset'а компетенций диаграммы типа "радар" */
export type DatasetItemConfigProps<T> = Partial<ChartDataset> & {
    dataKey: T
    className?: string
}

/**
 * Функция создает элемент dataset'а диаграммы "радар" на основе объекта настроек и коллекции компетенций
 * @param datasetItemConfig объект с настройками для создания элемента dataset'a
 * @param competenciesCollection коллекция компетенций для построения диаграммы
 * @returns {ChartData} элемент dataset'a диаграммы
 */
export const getRadarChartDatasetItem = <
    T extends Partial<keyof D>,
    D extends { [key in T]: number },
    R extends DatasetItemConfigProps<T>
>(
    datasetItemConfig: R,
    competenciesCollection: D[]
): Partial<ChartDataset> => ({
    ...datasetItemConfig,
    data: getCompetenceCertainPropertyScore<T, D>(
        datasetItemConfig.dataKey,
        competenciesCollection
    ),
})
