import { CompetenceGroupContract } from 'core/api'

/**
 * Маппинг начальных значений для формы
 */
export const mapResponseToFormData = (values?: CompetenceGroupContract) => {
    if (!values) return

    return {
        ...values,
        levelIds: values?.levels?.map((el) => el.id),
    }
}
