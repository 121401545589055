import React, { useEffect } from 'react'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { FormContainer } from 'components/layouts'
import {
    NotificationNewsletterTemplateContract,
    NotificationNewsletterTemplatesService,
} from 'core/api'
import { useRrweb } from 'hooks'

import {
    CANCEL_BTN_OPTIONS,
    PUBLISH_BTN_OPTIONS,
    getSaveBtnOptions,
} from './DistributionTemplates.config'
import { DistributionTemplateForm } from './components'

/** Страница для создания, редактирования и просмотра шаблона рассылки */
export const DistributionTemplatesFormContainer: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => {
        const { stopMonitoring } = useRrweb()

        useEffect(() => {
            stopMonitoring()
        }, [stopMonitoring])

        return (
            <FormContainer<NotificationNewsletterTemplateContract>
                formId={FORM_IDS.TEMPLATES_FORM}
                formMode={formMode}
                formComponent={DistributionTemplateForm}
                requestMethod={NotificationNewsletterTemplatesService.getActive}
                cancelBtnOptions={CANCEL_BTN_OPTIONS}
                saveBtnOptions={getSaveBtnOptions}
                publishBtnOptions={PUBLISH_BTN_OPTIONS}
            />
        )
    }
)
