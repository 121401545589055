import { CompliancePositionProfile } from 'core/api'

// Балл не может быть меньше 1, но начало оси идет с 0
export const REPORT_LEVELS_MIN = 0
export const REPORT_LEVELS_MAX = 5

type ReportLevelOptionProps = {
    [key in ReportLevels]: {
        SCORE: [number, number]
        COLOR: string
    }
}

export enum ReportLevels {
    Low,
    Medium,
    High,
}

export const REPORT_LEVELS_OPTIONS: ReportLevelOptionProps = {
    [ReportLevels.Low]: {
        SCORE: [REPORT_LEVELS_MIN, 2.5],
        COLOR: '#FF3B30',
    },
    [ReportLevels.Medium]: {
        SCORE: [2.6, 3.5],
        COLOR: '#ECCE2E',
    },
    [ReportLevels.High]: {
        SCORE: [3.6, REPORT_LEVELS_MAX],
        COLOR: '#05FF00',
    },
}

export const REPORT_LEVELS_TABLES_OPTIONS: ReportLevelOptionProps = {
    [ReportLevels.Low]: {
        SCORE: [REPORT_LEVELS_MIN, 2.5],
        COLOR: '#FF0000',
    },
    [ReportLevels.Medium]: {
        SCORE: [2.6, 3.5],
        COLOR: '#FF9D09',
    },
    [ReportLevels.High]: {
        SCORE: [3.6, REPORT_LEVELS_MAX],
        COLOR: '#41B619',
    },
}

export const REPORT_COMPLIANCE_COLORS = {
    [CompliancePositionProfile.Disbalance]: '#99A4AE',
    [CompliancePositionProfile.LowCompliance]: '#B42253',
    [CompliancePositionProfile.PartiallyCompliance]: '#FF9D09',
    [CompliancePositionProfile.Compliance]: '#00CF91',
    [CompliancePositionProfile.PartiallyExceedingCompliance]: '#33CCFF',
    [CompliancePositionProfile.AbsolutelyExceedingCompliance]: '#41B619',
}

export const REPORT_EXCEEDING_POSITION_PROFILE_COLORS = {
    INSIGNIFICANT: '#32CCFF',
    SIGNIFICANT: '#F1AE21',
}
