import React, { useCallback, useEffect } from 'react'
import { Col, Row } from 'antd'
import { DATE_FORMAT } from 'consts'
import {
    DatePickerControl,
    FormItemAdapter,
    InputControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import {
    NotificationDestinationCategoriesService,
    NotificationDestinationCategoryContract,
    NotificationFilteringConditionContract,
    NotificationFilteringConditionsService,
    NotificationMailingTaskAppointmentSearchContract,
    NotificationMailingTaskAppointmentSearchResultContract,
    NotificationMailingTasksService,
} from 'core/api'
import { TooltipAdapter } from 'components/shared'
import { disableDate } from 'utils'
import { useHttp } from 'hooks'

import { AppointmentSelectionFilter } from '../AppointmentSelectionFilter'
import { Appointments } from '../Appointments'
import { Conditions } from '../Conditions'
import { DistributionMailingParamsProps } from '../DistributionMailingParams/DistributionMailingParams.types'
import {
    checkMailFieldsAndAlert,
    normalizeRatioByCode,
} from '../DistributionMailingAssignmentForm/DistributionMailingAssignmentForm.utils'
import { getTableColumnsByCategoryCode } from './DistributionMailingParamsByTime.utils'

export const DistributionMailingParamsByTime: React.FC<DistributionMailingParamsProps> = React.memo(
    ({ formMode, form }) => {
        const [fetchCategories, categories] = useHttp<
            NotificationDestinationCategoryContract[]
        >(NotificationDestinationCategoriesService.getForSelect)

        const [fetchConditions, conditions] = useHttp<
            NotificationFilteringConditionContract[]
        >(NotificationFilteringConditionsService.getForSelect)

        const handleValueChange = useCallback(
            (value?: LabeledValue) => {
                checkMailFieldsAndAlert(form, value?.label)
            },
            [form]
        )

        useEffect(() => {
            fetchCategories()
            fetchConditions()
        }, [fetchCategories, fetchConditions])

        return (
            <>
                <Row>
                    <Col xs={12}>
                        <FormItemAdapter
                            fieldName="mailingStartDate"
                            label={LOCAL.LABELS.SEND}
                            required
                        >
                            <DatePickerControl
                                showTime={{
                                    format: DATE_FORMAT.HH_mm,
                                }}
                                format={DATE_FORMAT.LIST_WITH_TIME}
                                disabledDate={disableDate({ withTime: true })}
                                formMode={formMode}
                            />
                        </FormItemAdapter>
                    </Col>
                </Row>

                <TooltipAdapter title={LOCAL.TOOLTIPS.EMAILS_LIST}>
                    <FormItemAdapter
                        fieldName="randomRecipientEmails"
                        label={LOCAL.LABELS.MAILING_EMAILS}
                    >
                        <InputControl
                            formMode={formMode}
                            placeholder={LOCAL.PLACEHOLDERS.INPUT_EMAIL}
                        />
                    </FormItemAdapter>
                </TooltipAdapter>

                <FormItemAdapter
                    fieldName="category"
                    label={LOCAL.LABELS.MAILING_CATEGORY_TYPE}
                    required
                >
                    <SelectControl
                        formMode={formMode}
                        showSearch
                        onChange={handleValueChange}
                        values={categories?.map(normalizeRatioByCode)}
                        labelInValue
                    />
                </FormItemAdapter>

                <ShouldUpdateChecker
                    fieldPath={[['category'], ['appointments']]}
                >
                    {({ getFieldValue }) => {
                        const appointments = getFieldValue('appointments')
                        const category = getFieldValue('category')

                        return (
                            !!category && (
                                <>
                                    <Appointments<
                                        NotificationMailingTaskAppointmentSearchContract,
                                        NotificationMailingTaskAppointmentSearchResultContract
                                    >
                                        form={form}
                                        formMode={formMode}
                                        listFieldName="appointments"
                                        controlTitle={LOCAL.LABELS.APPOINTMENTS}
                                        modalTitle={
                                            LOCAL.LABELS.APPOINTMENT_SELECTION
                                        }
                                        initialValues={{
                                            appointments,
                                            category,
                                        }}
                                        getByIdsMethod={
                                            NotificationMailingTasksService.getAppointmentsByIds
                                        }
                                        tableSearchOptions={{
                                            filterComponent: AppointmentSelectionFilter,
                                            searchMethod:
                                                NotificationMailingTasksService.searchAppointments,
                                            tableColumns: getTableColumnsByCategoryCode(
                                                category.key
                                            ),
                                            tableFiltersOptions: {
                                                initialValues: {
                                                    categoryId: category.value,
                                                },
                                            },
                                        }}
                                    />
                                </>
                            )
                        )
                    }}
                </ShouldUpdateChecker>

                <ShouldUpdateChecker fieldPath={['categoryId']}>
                    {({ getFieldValue }) => {
                        const categoryId = getFieldValue('categoryId')?.value

                        return (
                            categoryId &&
                            !!conditions?.length && (
                                <Conditions
                                    conditions={conditions}
                                    formMode={formMode}
                                    form={form}
                                />
                            )
                        )
                    }}
                </ShouldUpdateChecker>
            </>
        )
    }
)
