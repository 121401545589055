import React from 'react'
import { Form } from 'antd'
import { InputNumberEditControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { VisualizationOptionsGroup } from 'components/shared'

import styles from './CanvasSizes.module.scss'
import { CanvasSizesProps } from './CanvasSizes.types'
import { HEIGHT_DEFAULT } from './CanvasSizes.consts'
import { getInitialWidth } from './CanvasSizes.utils'

/**
 * Компонент настройки размеров полотна трека
 */
export const CanvasSizes: React.FC<CanvasSizesProps> = React.memo(
    ({ disabled, canvasKey }) => (
        <VisualizationOptionsGroup
            title={LOCAL.LABELS.CANVAS_SIZES}
            className={styles.wrapper}
        >
            <Form.Item
                name={['trackCanvases', canvasKey, 'width']}
                label={LOCAL.LABELS.WIDTH}
                labelAlign="left"
                labelCol={{
                    xs: 5,
                }}
                initialValue={getInitialWidth(canvasKey)}
            >
                <InputNumberEditControl min={0} disabled={disabled} />
            </Form.Item>

            <Form.Item
                name={['trackCanvases', canvasKey, 'height']}
                label={LOCAL.LABELS.HEIGHT}
                labelAlign="left"
                labelCol={{
                    xs: 5,
                }}
                initialValue={HEIGHT_DEFAULT}
            >
                <InputNumberEditControl min={0} disabled={disabled} />
            </Form.Item>
        </VisualizationOptionsGroup>
    )
)
