import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { CompetenciesService, StructuralUnitsService } from 'core/api'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { Popup } from 'components/shared/Popup'
import { Table } from 'antd'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import { isFormModeView } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './AdjacentCompetenciesSelect.module.scss'
import { ADJACENT_COMPETENCIES_TABLE_COLUMNS } from './AdjacentCompetenciesSelect.consts'
import {
    AdjacentCompetenciesSelectProps,
    CascadeSelectDataUnitProps,
} from './AdjacentCompetenciesSelect.types'
import { normalizeDataToAdjacentCompetenciesTable } from './AdjacentCompetenciesSelect.utils'

/** Компонент для управления смежными компетенциями  */
export const AdjacentCompetenciesSelect: React.FC<AdjacentCompetenciesSelectProps> = React.memo(
    ({ value, onChange, formMode }) => {
        const [dataSource, setDataSource] = useState<
            CascadeSelectDataUnitProps[]
        >([])

        const {
            elementVisible,
            handleHideElement,
            handleShowElement,
        } = useElementVisible()

        /**
         * Запросить смежные компетенции по id организации
         * @param departmentsData - нормализованные данные по организациям/оивам
         */
        const fetchAdjacentCompetenciesByDepartmentId = useCallback(
            async (departmentsData: CascadeSelectDataUnitProps[]) => {
                const departmentIds = departmentsData.map((el) => el.key)

                try {
                    const fetchedCompetencies = await CompetenciesService.getByDepartmentIds(
                        {
                            id: String(departmentIds[0] || [0]),
                            body: departmentIds,
                        }
                    )

                    setDataSource(
                        departmentsData.reduce<CascadeSelectDataUnitProps[]>(
                            (acc, el) => {
                                const currentDepartmentCompetencies = fetchedCompetencies.filter(
                                    (competence) =>
                                        competence.departmentId === el.key
                                )

                                if (!currentDepartmentCompetencies.length)
                                    return [...acc, el]

                                const copy = { ...el }
                                copy.children = currentDepartmentCompetencies.map(
                                    normalizeDataToAdjacentCompetenciesTable
                                )

                                return [...acc, copy]
                            },
                            []
                        )
                    )
                } catch (error) {
                    console.error(error)
                }
            },
            []
        )

        const rowSelection = useMemo(
            () => ({
                onChange: async (
                    selectedRowKeys: React.Key[],
                    selectedRows: CascadeSelectDataUnitProps[]
                ) => {
                    const competenceIds = selectedRows
                        .filter((row) => !row?.children)
                        .map((competenceRow) => Number(competenceRow.key))

                    onChange?.(competenceIds)
                },
                checkStrictly: false,
                hideSelectAll: true,
                selectedRowKeys: value,
            }),
            [onChange, value]
        )

        /** Запрос ОИВов и компетенций, а также нормализация данных для первичного отображения в модальном окне */
        useEffect(() => {
            const fetchOivs = async () => {
                try {
                    const filledOivItemsIds = await StructuralUnitsService.getOivIdsWithExistingCompetence()

                    const normalizedDepartmentData = filledOivItemsIds.map(
                        normalizeDataToAdjacentCompetenciesTable
                    )

                    fetchAdjacentCompetenciesByDepartmentId(
                        normalizedDepartmentData
                    )
                } catch (error) {
                    console.error(error)
                }
            }

            fetchOivs()
        }, [fetchAdjacentCompetenciesByDepartmentId])

        return (
            <>
                <AddButton
                    onClick={handleShowElement}
                    disabled={isFormModeView(formMode)}
                    buttonText={LOCAL.ACTIONS.ADD_KNOWLEDGE_IN_RELATED_INDUSTRY}
                />

                <Popup
                    visible={elementVisible}
                    onCancel={handleHideElement}
                    cancelText={LOCAL.ACTIONS.CLOSE}
                    destroyOnClose
                    centered
                    title={LOCAL.LABELS.KNOWLEDGE_IN_RELATED_INDUSTRY}
                    width={MODAL_WIDTH.LG}
                    okButtonProps={{ hidden: true }}
                    getContainer={getDropdownPopupContainerDefault}
                >
                    <span className={styles.hint}>
                        {
                            LOCAL.LABELS
                                .CHOOSE_THE_COMPETENCIES_FOR_THE_REQUIRED_OIV
                        }
                    </span>

                    <section className={styles.table}>
                        <Table
                            columns={ADJACENT_COMPETENCIES_TABLE_COLUMNS}
                            dataSource={dataSource || []}
                            rowSelection={rowSelection}
                            pagination={false}
                        />
                    </section>
                </Popup>
            </>
        )
    }
)
