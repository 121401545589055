import React from 'react'
import cn from 'classnames'
import stylesContentTitle from 'components/shared/ContentTitle/ContentTitle.module.scss'
import { Col, Row } from 'antd'
import { HasPermissions } from 'core/permissions'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { ROW_GUTTER } from 'consts'
import { Title } from 'components/shared'
import { UserPermission } from 'core/api'
import { getBackLinkTitleFromRoute } from 'utils'

import styles from './UserProfileHeader.module.scss'
import { UserProfileHeaderProps } from './UserProfileHeader.types'
import { UserProfileInfo } from '../UserProfileInfo'
import {
    getLastWorkPlace,
    getStructuralUnitAndOrganizationText,
} from './UserProfileHeader.utils'

/** Хеадер страницы профайла пользователя */
export const UserProfileHeader: React.FC<UserProfileHeaderProps> = React.memo(
    ({ userProfile, fullFio, hideBackLink }) => {
        /**
         * Должность и подразделение
         */
        const structuralUnitsAndPositions = () => {
            const mainStructuralUnitAndPosition = userProfile?.user?.structuralUnitsPositions.find(
                (el) => el.isMain
            )

            const currentStructuralUnitAndPosition =
                mainStructuralUnitAndPosition ||
                getLastWorkPlace(userProfile?.user?.structuralUnitsPositions)

            const userOrganization = userProfile?.userOrganization?.name

            if (currentStructuralUnitAndPosition || userOrganization) {
                return (
                    <>
                        <div className={styles.position}>
                            {currentStructuralUnitAndPosition?.position?.name}
                        </div>

                        <div className={styles.organization}>
                            {getStructuralUnitAndOrganizationText(
                                currentStructuralUnitAndPosition?.structuralUnit
                                    ?.name,
                                userOrganization
                            )}
                        </div>
                    </>
                )
            }

            return <div>{LOCAL.LABELS.POSITIONS_AND_UNIT_NO_DATA}</div>
        }

        return (
            <div className={styles.wrapper}>
                <Row gutter={ROW_GUTTER} justify="space-between" align="middle">
                    <Col xs={24} xl={14}>
                        <Title
                            text={
                                <>
                                    {LOCAL.CATALOGS.PA_PROFILE}

                                    {fullFio && (
                                        <span className={styles.fio}>
                                            {` - ${fullFio}`}
                                        </span>
                                    )}
                                </>
                            }
                            type="h3"
                        />

                        {structuralUnitsAndPositions()}

                        {!hideBackLink && (
                            <HasPermissions
                                requiredPermissions={[
                                    UserPermission.Administration,
                                ]}
                            >
                                <div
                                    className={cn(
                                        stylesContentTitle.backLink,
                                        styles.back
                                    )}
                                >
                                    <Link to={ROUTE_NAMES.COWORKERS}>
                                        <IconsAdapter iconType="ArrowLeftOutlined" />

                                        <span>
                                            {getBackLinkTitleFromRoute(
                                                ROUTE_NAMES.COWORKERS
                                            )}
                                        </span>
                                    </Link>
                                </div>
                            </HasPermissions>
                        )}
                    </Col>

                    <Col>
                        <UserProfileInfo user={userProfile?.user} />
                    </Col>
                </Row>
            </div>
        )
    }
)
