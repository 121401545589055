import React from 'react'
import { Col, Row } from 'antd'
import { ROW_GUTTER } from 'consts'

import styles from './ProductCardSchedule.module.scss'
import { ProductCardLauncher } from '../ProductCardLauncher'
import { ProductCardScheduleProps } from './ProductCardSchedule.types'
import { mapLearningScheduleInfo } from './ProductCardSchedule.utils'

/** Карточка продукта - блок "расписание" */
export const ProductCardSchedule: React.FC<ProductCardScheduleProps> = React.memo(
    ({ pageInfo, isListenerEnrolled, isAppointed, fetchPageInfo }) => (
        <Row
            className={styles.wrapper}
            justify="space-between"
            gutter={ROW_GUTTER}
        >
            <Col flex={1}>
                <Row gutter={ROW_GUTTER}>
                    {mapLearningScheduleInfo(pageInfo)}
                </Row>
            </Col>

            <Col>
                <ProductCardLauncher
                    fetchPageInfo={fetchPageInfo}
                    isAppointed={isAppointed}
                    card={pageInfo}
                    isListenerEnrolled={isListenerEnrolled}
                    long
                />
            </Col>
        </Row>
    )
)
