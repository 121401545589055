import React, { useCallback } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Form } from 'antd'
import {
    FormItemAdapter,
    InputControl,
    SelectControl,
} from 'components/controls'
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { isFormModeView, normalizeDictionaryToControl } from 'utils'

import styles from './InfoAssistantOptions.module.scss'
import { InfoAssistantOptionsProps } from './InfoAssistantOptions.types'

/** Настройки событий помощника */
export const InfoAssistantOptions: React.FC<InfoAssistantOptionsProps> = React.memo(
    ({ formListName, eventTypes, disabled, formMode }) => {
        /**
         * Обработчик добавления событий
         * @param {FormListOperation} объект с возможными вариантами операций (добавление, удаление)
         */
        const handleAddEvent = useCallback(
            ({ add }: Partial<FormListOperation>) => () => {
                add?.()
            },
            []
        )

        /**
         * Обработчик удаления событий
         * @param {FormListOperation} объект с возможными вариантами операций (добавление, удаление)
         */
        const handleRemoveEvent = useCallback(
            (
                { remove }: Partial<FormListOperation>,
                event: FormListFieldData
            ) => () => {
                remove?.(event.name)
            },
            []
        )

        return (
            <Form.List name={formListName}>
                {(events, { add, remove }) => (
                    <div className={styles.wrapper}>
                        {events.map((event, index) => (
                            <React.Fragment key={event.key}>
                                <div className={styles.select}>
                                    <FormItemAdapter
                                        fieldName={[event.name, 'event']}
                                        label={LOCAL.LABELS.EVENT_C}
                                    >
                                        <SelectControl
                                            values={normalizeDictionaryToControl(
                                                eventTypes
                                            )}
                                            disabled={disabled}
                                            formMode={formMode}
                                        />
                                    </FormItemAdapter>

                                    {!!index && !isFormModeView(formMode) && (
                                        <IconsAdapter
                                            iconType="DeleteOutlined"
                                            onClick={handleRemoveEvent(
                                                { remove },
                                                event
                                            )}
                                            disabled={disabled}
                                        />
                                    )}
                                </div>

                                <FormItemAdapter
                                    fieldName={[event.name, 'message']}
                                    label={LOCAL.LABELS.MESSAGE_TEXT}
                                >
                                    <InputControl
                                        disabled={disabled}
                                        formMode={formMode}
                                    />
                                </FormItemAdapter>
                            </React.Fragment>
                        ))}

                        <AddButton
                            onClick={handleAddEvent({ add })}
                            disabled={disabled || isFormModeView(formMode)}
                            buttonText={LOCAL.LABELS.EVENT_C_ADD}
                            className={styles.addButton}
                        />
                    </div>
                )}
            </Form.List>
        )
    }
)
