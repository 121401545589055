import React from 'react'
import toLower from 'lodash/toLower'
import { CourseVersionContract } from 'core/api'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { formatDate } from 'utils'

import styles from './CourseVersions.module.scss'

const getRelatedCourseVersionLabel = (
    relatedCourseVersion: CourseVersionContract
) => {
    const message = `${LOCAL.LABELS.VERSION} ${
        relatedCourseVersion.courseVersion
    } от ${formatDate(relatedCourseVersion.uploadDate)}`

    if (relatedCourseVersion.isMain) {
        return (
            <div className={styles.mainVersion}>{`${message} [${toLower(
                LOCAL.LABELS.MAIN_TAG
            )}]`}</div>
        )
    }

    if (!relatedCourseVersion.active) {
        return (
            <div className={styles.archiveVersion}>{`${message} [${toLower(
                LOCAL.LABELS.ARCHIVE
            )}]`}</div>
        )
    }

    return message
}

export const normalizeRelatedCourseVersions = (
    relatedCourseVersions?: CourseVersionContract[]
): LabeledValue[] | undefined => {
    if (!relatedCourseVersions) return

    return relatedCourseVersions.map((el) => ({
        value: el.id,
        key: String(el.id),
        label: getRelatedCourseVersionLabel(el),
    }))
}
