import React, { useState } from 'react'

import { NotificationCounterContext } from './NotificationCounterContext'

/** Компонент контекста с сущностями для отправки на согласование*/
export const NotificationCounterContextProvider: React.FC = React.memo(
    ({ children }) => {
        const [notificationUnreadCount, setNotificationUnreadCount] = useState<
            number
        >(0)

        return (
            <NotificationCounterContext.Provider
                value={{ notificationUnreadCount, setNotificationUnreadCount }}
            >
                {children}
            </NotificationCounterContext.Provider>
        )
    }
)
