import React, { FC, memo } from 'react'
import { LOCAL } from 'core/local'
import { PassingStage } from 'components/shared/Passing/PassingStage'
import { getStyles } from 'utils'
import { makeIconBlocksProps } from 'components/pages/UserAssignments/components/PassingDoneStage/PassingDoneStage.consts'

import { QuestionnairesPassingDoneStageProps } from './QuestionnairesPassingDoneStage.types'
import { QuestionnairesPassingHeader } from '../QuestionnairesPassingHeader'

/** Стадия окончания прохождения */
export const QuestionnairesPassingDoneStage: FC<QuestionnairesPassingDoneStageProps> = memo(
    ({ onOk, questionnaire, numberOfQuestions, numberOfAnswers, date }) => {
        const {
            quizPortfolioPresentation,
            testPortfolioPresentation,
        } = questionnaire

        const presentation =
            quizPortfolioPresentation || testPortfolioPresentation

        return (
            <PassingStage
                header={
                    <QuestionnairesPassingHeader
                        styles={getStyles(
                            presentation?.finalWindow?.titleTextSettings
                        )}
                        presentationSettings={presentation?.finalWindow}
                        {...questionnaire}
                    />
                }
                presentationSettings={presentation?.finalWindow}
                showGreeting
                onButtonClick={onOk}
                buttonText={LOCAL.OK}
                iconBlocksProps={makeIconBlocksProps(
                    date,
                    numberOfAnswers,
                    numberOfQuestions
                )}
            />
        )
    }
)
