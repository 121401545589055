import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DOCUMENT_STATUSES, TEMPLATE_STATUSES } from 'consts'
import { GetBtnOptionsType } from 'components/layouts/FormContainer/FormContainer.types'
import { LOCAL } from 'core/local'
import {
    NotificationNewsletterTemplateContract,
    NotificationNewsletterTemplateSearchResultContract,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { defaultSorter } from 'components/layouts'
import { formatDate } from 'utils/date.utils'
import {
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { DistributionTemplatesTableActions } from './components'
import { isNotificationTemplateStatusPublished } from './DistributionTemplates.utils'

export const DISTRIBUTION_TEMPLATES_COLUMNS: ColumnsType<NotificationNewsletterTemplateSearchResultContract> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DESCRIPTION,
        dataIndex: 'description',
        key: 'description',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.CATALOGS.TAGS,
        dataIndex: 'tags',
        key: 'tags',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status, record) =>
            record.active
                ? TEMPLATE_STATUSES[record.status]
                : DOCUMENT_STATUSES.Archive,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        render: formatDate,
        fixed: 'right',
    },
    {
        render: (tableRow) => (
            <DistributionTemplatesTableActions tableRow={tableRow} />
        ),
        fixed: 'right',
    },
]

export const CANCEL_BTN_OPTIONS = {
    visible: true,
    href: ROUTE_NAMES.DISTRIBUTION_TEMPLATES,
}

export const getSaveBtnOptions: GetBtnOptionsType<NotificationNewsletterTemplateContract> = ({
    editValues,
}) => ({
    visible: !isNotificationTemplateStatusPublished(editValues?.status),
    requiredPermissions: [
        UserPermission.NotificationNewsletterTemplateRead,
        UserPermission.NotificationNewsletterTemplateSave,
    ],
})

export const PUBLISH_BTN_OPTIONS = {
    visible: true,
    requiredPermissions: [
        UserPermission.NotificationNewsletterTemplateRead,
        UserPermission.NotificationNewsletterTemplatePublish,
    ],
}
