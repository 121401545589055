import { DocumentStatus } from 'core/api'

export const PAGE_SIZE_DEFAULT = 10
export const PAGE_SIZE_ALL = 999
export const PAGE_NUMBER_DEFAULT = 1
export const PAGE_SIZE_FIFTY = 50

export const EXCLUDE_DRAFT_STATUS_SEARCH_BODY = {
    excludeStatuses: [DocumentStatus.Draft],
}
