import React from 'react'
import { FormItemAdapter } from 'components/controls/FormItemAdapter'

import { NotEditableTextControl } from '../NotEditableTextControl'
import { NotEditableTextFieldProps } from './NotEditableTextField.types'

/**
 * Не редактируемое текстовое поле, используется в тех случаях, когда данные из формы необходимо отобразить в виде простого текста
 */
export const NotEditableTextField: React.FC<NotEditableTextFieldProps> = React.memo(
    ({ className, normalizeValueFn, ...restProps }) => (
        <FormItemAdapter {...restProps} className={className}>
            <NotEditableTextControl normalizeValueFn={normalizeValueFn} />
        </FormItemAdapter>
    )
)
