import React from 'react'
import { Col, Form, Row } from 'antd'
import {
    ColorPickerControl,
    SelectControl,
    TextAlignControl,
} from 'components/controls'
import {
    FONT_NAMES_MAPPED,
    FONT_SIZES,
    FONT_STYLES_MAPPED,
    LINE_HEIGHTS,
    ROW_GUTTER,
} from 'consts'
import { IconsAdapter, VisualizationOptionsGroup } from 'components/shared'
import { normalizeDictionaryToControl } from 'utils'

import styles from './ViisualizationTextOptions.module.scss'
import {
    VISUALIZATION_TEXT_COLOR_SETTINGS_META,
    VISUALIZATION_TEXT_SETTINGS_META,
} from './VisualizationTextOptions.consts'
import { VisualizationTextOptionsProps } from './VisualizationTextOptions.types'

/** Настройки текстовых элементов холста */
export const VisualizationTextOptions: React.FC<VisualizationTextOptionsProps> = React.memo(
    ({ type, disabled }) => (
        <VisualizationOptionsGroup
            title={VISUALIZATION_TEXT_SETTINGS_META[type]}
            className={styles.wrapper}
        >
            <Form.Item name={['canvas', type, 'fontName']}>
                <SelectControl values={FONT_NAMES_MAPPED} disabled={disabled} />
            </Form.Item>

            <Row gutter={ROW_GUTTER}>
                <Col xs={16}>
                    <Form.Item name={['canvas', type, 'fontStyle']}>
                        <SelectControl
                            values={FONT_STYLES_MAPPED}
                            disabled={disabled}
                        />
                    </Form.Item>

                    <Row align="middle" gutter={ROW_GUTTER}>
                        <Col xs={16}>
                            <Form.Item name={['canvas', type, 'textAlign']}>
                                <TextAlignControl disabled={disabled} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col xs={8}>
                    <Form.Item name={['canvas', type, 'fontSize']}>
                        <SelectControl
                            values={normalizeDictionaryToControl(
                                FONT_SIZES,
                                true
                            )}
                            disabled={disabled}
                        />
                    </Form.Item>

                    <Form.Item
                        name={['canvas', type, 'lineHeight']}
                        className={styles.lineHeight}
                    >
                        <SelectControl
                            values={normalizeDictionaryToControl(
                                LINE_HEIGHTS,
                                true
                            )}
                            addonBefore={
                                <IconsAdapter iconType="CustomIconLineHeight" />
                            }
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {VISUALIZATION_TEXT_COLOR_SETTINGS_META[type] && (
                <Form.Item name={['canvas', type, 'textColor']}>
                    <ColorPickerControl
                        label={VISUALIZATION_TEXT_COLOR_SETTINGS_META[type]}
                        disabled={disabled}
                    />
                </Form.Item>
            )}
        </VisualizationOptionsGroup>
    )
)
