import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { DictionaryPageActionsProps } from 'components/layouts'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { JOB_PROFILES_REQUIRED_PERMISSIONS } from 'consts'
import { LOCAL } from 'core/local'
import {
    PositionProfileContract,
    PositionProfilePublishContract,
    PositionProfilesService,
    UserPermission,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TableActionsCommonProps } from 'App.types'
import { isDocumentStatusInWork, isDocumentStatusReadyForWork } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

import { JobProfileFormSubmitValuesProps } from '../JobProfileForm/JobProfileForm.types'
import {
    mapFormDataToRequest,
    mapResponseToFormData,
} from '../JobProfileForm/JobProfileForm.utils.maps'

/** Компонент для создания колонки действий внутри таблицы реестра профилей должности */
export const JobProfilesTableActions: React.FC<
    TableActionsCommonProps<PositionProfileContract> &
        Pick<DictionaryPageActionsProps, 'reFetchDictionary' | 'updateLoader'>
> = React.memo(({ tableRow, reFetchDictionary, updateLoader }) => {
    const handleCopyProfile = useCallback(async () => {
        try {
            updateLoader?.(true)
            const initialValuesForEdit = await PositionProfilesService.get({
                id: tableRow.id,
            })

            const body = mapFormDataToRequest(
                mapResponseToFormData({
                    initialValuesForEdit,
                    isCopy: true,
                }) as JobProfileFormSubmitValuesProps
            )

            await PositionProfilesService.save({
                body: body as PositionProfilePublishContract,
            })

            reFetchDictionary?.()
        } catch (err) {
            console.error(err)
        } finally {
            updateLoader?.(false)
        }
    }, [reFetchDictionary, tableRow.id, updateLoader])

    return (
        <>
            <ButtonsToolbar bottomIndent="0">
                <TableActions.View
                    requiredPermissions={JOB_PROFILES_REQUIRED_PERMISSIONS}
                    href={`${ROUTE_NAMES.POSITION_PROFILES_VIEW}/${tableRow.id}`}
                />

                {tableRow.active && (
                    <>
                        {isDocumentStatusReadyForWork(tableRow.status) && (
                            <HasPermissions
                                requiredPermissions={[
                                    ...JOB_PROFILES_REQUIRED_PERMISSIONS,
                                    UserPermission.PositionProfilePublish,
                                ]}
                                accessDeniedRender={renderAccessDeniedButton({
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="InboxOutlined" />
                                    ),
                                })}
                            >
                                <PopupWithForm
                                    formId={FORM_IDS.JOB_PROFILE_IN_WORK_FORM}
                                    buttonText=""
                                    havePopup={false}
                                    buttonOption={{
                                        title: LOCAL.LABELS.TO_WORK,
                                        type: 'link',
                                        icon: (
                                            <IconsAdapter iconType="InboxOutlined" />
                                        ),
                                    }}
                                    formOptions={{
                                        initialValues: { id: tableRow.id },
                                    }}
                                    modalOptions={{
                                        title: LOCAL.ACTIONS.SEND_TO_WORK,
                                        okText: LOCAL.YES,
                                    }}
                                />
                            </HasPermissions>
                        )}

                        {!isDocumentStatusInWork(tableRow.status) && (
                            <TableActions.Edit
                                requiredPermissions={[
                                    ...JOB_PROFILES_REQUIRED_PERMISSIONS,
                                    UserPermission.PositionProfileSave,
                                ]}
                                href={`${ROUTE_NAMES.POSITION_PROFILES_EDIT}/${tableRow.id}`}
                            />
                        )}

                        <TableActions.Copy
                            requiredPermissions={[
                                ...JOB_PROFILES_REQUIRED_PERMISSIONS,
                                UserPermission.PositionProfileSave,
                            ]}
                            onClick={handleCopyProfile}
                        />

                        <HasPermissions
                            requiredPermissions={[
                                ...JOB_PROFILES_REQUIRED_PERMISSIONS,
                                UserPermission.PositionProfileDelete,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                type: 'link',
                                icon: (
                                    <IconsAdapter iconType="DeleteOutlined" />
                                ),
                            })}
                        >
                            <PopupWithForm
                                formId={FORM_IDS.CONFIRM_DELETE_FORM}
                                buttonText=""
                                havePopup={false}
                                buttonOption={{
                                    title: LOCAL.ACTIONS.DELETE,
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="DeleteOutlined" />
                                    ),
                                }}
                                formOptions={{
                                    initialValues: { id: tableRow.id },
                                }}
                                modalOptions={{
                                    title: isDocumentStatusInWork(
                                        tableRow.status
                                    )
                                        ? LOCAL.ACTIONS.SEND_TO_ARCHIVE
                                        : LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                                    okText: LOCAL.YES,
                                }}
                                messageText={
                                    isDocumentStatusInWork(tableRow.status)
                                        ? LOCAL.MESSAGES
                                              .PROFILE_CANNOT_BE_DELETED
                                        : ''
                                }
                            />
                        </HasPermissions>

                        {tableRow.agreementListId && (
                            <HasPermissions
                                requiredPermissions={
                                    JOB_PROFILES_REQUIRED_PERMISSIONS
                                }
                                accessDeniedRender={renderAccessDeniedButton({
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="FormOutlined" />
                                    ),
                                })}
                            >
                                <Button
                                    icon={
                                        <IconsAdapter iconType="FormOutlined" />
                                    }
                                    title={LOCAL.LABELS.APPROVE_LIST}
                                    type="link"
                                    href={`${ROUTE_NAMES.APPROVE_VIEW}/${tableRow.agreementListId}`}
                                >
                                    {LOCAL.ACTIONS.CANCEL}
                                </Button>
                            </HasPermissions>
                        )}
                    </>
                )}
            </ButtonsToolbar>
        </>
    )
})
