import React from 'react'
import { CircleReportSmallContract, QuizType } from 'core/api'
import { DataNode } from 'rc-tree-select/lib/interface'
import { DictionariesStoreProps } from 'core/helpers/DictionariesHelper'
import { LabeledValue } from 'antd/lib/select'
import { NavLink } from 'react-router-dom'
import { QUIZ_TYPES } from 'consts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getCommonSelectItemProps } from 'utils'

import { RenderReportLinkProps } from './UserProfileReportForm.types'

/**
 * Формируем ссылки из item'ов селекта
 * @param selectItems - массив данных для селекта
 * @param userId
 * @param isOwnProfile
 */
export const renderReportLink = ({
    selectItems,
    userId,
    isOwnProfile,
}: RenderReportLinkProps): LabeledValue[] =>
    selectItems.map((el) => ({
        ...el,
        label: (
            <NavLink
                to={`${ROUTE_NAMES.PROFILE_REPORTS}/${el.value}${
                    isOwnProfile ? '' : `?userId=${userId}`
                }`}
            >
                {el.label}
            </NavLink>
        ),
    }))

/**
 * Формируем данные для селекта результатов отчетов
 * @param assessmentType
 */
export const normalizedAssessmentResultsData = (
    assessmentType: DictionariesStoreProps['assessmentTypeDictionary']
) => (acc: DataNode[], resultData: CircleReportSmallContract) => {
    if (!acc.find((el) => el.key === resultData.assessmentType)) {
        return [
            ...acc,
            {
                title:
                    assessmentType[resultData.assessmentType] ||
                    QUIZ_TYPES[
                        (resultData.assessmentType as unknown) as QuizType
                    ],
                disabled: true,
                ...getCommonSelectItemProps(resultData.assessmentType),
                children: [
                    {
                        title: resultData.name,
                        ...getCommonSelectItemProps(resultData.id),
                    },
                ],
            },
        ]
    }

    return acc.map((el) => {
        if (el.key === resultData.assessmentType && el.children) {
            return {
                ...el,
                children: [
                    ...el.children,
                    {
                        title: resultData.name,
                        ...getCommonSelectItemProps(resultData.id),
                    },
                ],
            }
        }

        return el
    })
}
