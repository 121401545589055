import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import {
    CertificateSearchResultContract,
    CertificatesFilesService,
    CertificatesService,
} from 'core/api'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { TableActionsCommonProps } from 'App.types'
import { WithLoaderProps } from 'HOCs'
import { notification } from 'antd'
import { openPdfFileInNewTab } from 'utils'

/** Компонент для создания колонки действий внутри таблицы реестра внутренних сертификатов */
export const InternalCertificatesTableActions: React.FC<
    TableActionsCommonProps<CertificateSearchResultContract> &
        Partial<WithLoaderProps>
> = React.memo(({ tableRow, updateLoader }) => {
    const handleSendToMail = useCallback(async () => {
        try {
            updateLoader?.(true)

            await CertificatesFilesService.sendCertificateToEmail({
                id: tableRow.id,
            })

            notification.success({
                message:
                    LOCAL.MESSAGES.INTERNAL_CERTIFICATE_SEND_TO_MAIL_SUCCESS,
            })
        } catch (error) {
            notification.error({
                message: LOCAL.MESSAGES.ERROR_SEND_CERTIFICATES_TO_EMAIL,
            })
        } finally {
            updateLoader?.(false)
        }
    }, [tableRow.id, updateLoader])

    const handleViewCertificate = useCallback(async () => {
        try {
            updateLoader?.(true)

            const pdf = await CertificatesService.getFile(
                {
                    id: tableRow.id,
                },
                { responseType: 'blob' }
            )

            openPdfFileInNewTab(pdf)
        } catch (error) {
            notification.error({
                message: LOCAL.MESSAGES.ERROR_VIEW_CERTIFICATE,
            })
        } finally {
            updateLoader?.(false)
        }
    }, [tableRow.id, updateLoader])

    return (
        <ButtonsToolbar bottomIndent="0">
            <Button
                icon={<IconsAdapter iconType={'EyeOutlined'} />}
                type="link"
                onClick={handleViewCertificate}
            />

            <Button
                icon={<IconsAdapter iconType={'CustomIconCommercialAt'} />}
                type="link"
                onClick={handleSendToMail}
            />
        </ButtonsToolbar>
    )
})
