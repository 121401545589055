import { LOCAL } from 'core/local'
import { getRightsRestrictionsMessage } from 'core/local/messages.local.config'

/**
 * Функция для отображения контента при ошибке
 * @param errorCode - код ошибки
 * @param missingAccessRights - недостающие права доступа
 */
export const getErrorPageContent = (
    errorCode: string,
    missingAccessRights?: string
) => {
    switch (errorCode) {
        case '403':
            return {
                title: LOCAL.MESSAGES.ACCESS_ERROR,
                description: getRightsRestrictionsMessage(missingAccessRights),
            }
        case '404':
            return {
                title: LOCAL.MESSAGES.NO_RESULTS_FOUND_FOR_SEARCH,
                description: '',
            }
        default:
            return {
                title: '',
                description: '',
            }
    }
}
