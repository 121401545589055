import React, { FC } from 'react'
import {
    ADDITIONAL_OPTIONS_MAPPED,
    COL_xl8_lg12_xs24,
    EXCLUDE_DRAFT_STATUS_SEARCH_BODY,
    ROW_GUTTER,
} from 'consts'
import {
    COMPETENCIES_TABLE_COLUMNS,
    CompetenciesFilters,
    ControlGroup,
    FormItemAdapter,
    RadioButtonControl,
    SearchSelectControl,
    ShouldUpdateChecker,
    SwitchControl,
} from 'components/controls'
import { Col, Form, Row } from 'antd'
import {
    CompetenceForModalContract,
    CompetenciesSearchContract,
    CompetenciesService,
} from 'core/api'
import { ComponentCommonProps, WithDisabledProps } from 'App.types'
import { LOCAL } from 'core/local'
import { isDecorationTypeStandard, isFormModeView } from 'utils'

/** Блок настроек формы мероприятия */
export const EventFormOptions: FC<
    ComponentCommonProps & WithDisabledProps
> = React.memo(({ formMode, disabled }) => (
    <ControlGroup title={LOCAL.LABELS.EVENT_M_PARAMETERS} type="bordered">
        <Row gutter={ROW_GUTTER}>
            <Col {...COL_xl8_lg12_xs24}>
                <FormItemAdapter
                    fieldName="competencesIds"
                    label={LOCAL.LABELS.COMPETENCIES_DEVELOPS}
                    required
                >
                    <SearchSelectControl<
                        CompetenciesSearchContract,
                        CompetenceForModalContract
                    >
                        getByIdsMethod={CompetenciesService.getByIds}
                        selectMode="multiple"
                        formMode={formMode}
                        modalTitle={LOCAL.LABELS.COMPETENCES_SELECTION}
                        tableSearchOptions={{
                            tableFiltersOptions: {
                                initialValues: EXCLUDE_DRAFT_STATUS_SEARCH_BODY,
                            },
                            filterComponent: CompetenciesFilters,
                            searchMethod: CompetenciesService.getForModal,
                            tableColumns: COMPETENCIES_TABLE_COLUMNS,
                        }}
                        disabled={disabled}
                    />
                </FormItemAdapter>
            </Col>

            <Col {...COL_xl8_lg12_xs24}>
                <Form.Item name="isRating">
                    <SwitchControl
                        addonAfter={LOCAL.LABELS.EVENT_M_RATING}
                        disabled={isFormModeView(formMode) || disabled}
                    />
                </Form.Item>

                <Form.Item name="isCertification">
                    <SwitchControl
                        addonAfter={LOCAL.LABELS.CERTIFICATION}
                        disabled={isFormModeView(formMode) || disabled}
                    />
                </Form.Item>
            </Col>

            <Col {...COL_xl8_lg12_xs24}>
                <FormItemAdapter
                    fieldName="lessonAccessType"
                    label={LOCAL.LABELS.THEMATIC_BLOCKS_PASSING_ORDER}
                >
                    <RadioButtonControl
                        values={ADDITIONAL_OPTIONS_MAPPED}
                        buttonStyle="solid"
                        formMode={formMode}
                        disabled={disabled}
                    />
                </FormItemAdapter>

                <ShouldUpdateChecker fieldPath={['decorationType']}>
                    {(form) =>
                        isDecorationTypeStandard(
                            form.getFieldValue('decorationType')
                        ) ? (
                            <FormItemAdapter
                                fieldName="isRepeatPassingPossible"
                                withEmptyLabel
                            >
                                <SwitchControl
                                    addonAfter={LOCAL.LABELS.REPLAY}
                                    disabled={disabled}
                                    formMode={formMode}
                                />
                            </FormItemAdapter>
                        ) : null
                    }
                </ShouldUpdateChecker>
            </Col>
        </Row>
    </ControlGroup>
))
