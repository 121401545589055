import React, { FC, memo } from 'react'
import { LOCAL } from 'core/local'
import { PassingStage } from 'components/shared/Passing/PassingStage'
import { getStyles } from 'utils'

import { PassingDoneStageProps } from './PassingDoneStage.types'
import { UserAssignmentPassingHeader } from '../UserAssignmentPassingHeader'
import { makeIconBlocksProps } from './PassingDoneStage.consts'

/** Стадия окончания прохождения */
export const PassingDoneStage: FC<PassingDoneStageProps> = memo(
    ({ onOk, questionnaire, numberOfQuestions, numberOfAnswers, date }) => {
        const { assessmentPortfolioPresentation: presentation } = questionnaire

        return (
            <PassingStage
                header={
                    <UserAssignmentPassingHeader
                        title={presentation?.finalWindow?.title}
                        styles={getStyles(
                            presentation?.finalWindow?.titleTextSettings
                        )}
                        {...questionnaire}
                    />
                }
                presentationSettings={presentation?.finalWindow}
                showGreeting
                onButtonClick={onOk}
                buttonText={LOCAL.OK}
                iconBlocksProps={makeIconBlocksProps(
                    date,
                    numberOfAnswers,
                    numberOfQuestions
                )}
            />
        )
    }
)
