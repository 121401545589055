import cn from 'classnames'
import React, { ChangeEvent, useCallback } from 'react'
import { InputControl } from 'components/controls'
import { isBoolean } from 'lodash'
import { usePreventPast } from 'hooks'

import styles from './PasteTextInput.module.scss'
import { PasteTextInputProps } from './PasteTextInput.types'
import { VerifyIcon } from '../VerifyIcon/VerifyIcon'

/** Инпут для вопроса с типом "вставить текст" у прохождения опросного листа */
export const PasteTextInput: React.FC<PasteTextInputProps> = React.memo(
    ({ value, onChange, blockNumber }) => {
        usePreventPast(`.${styles.input}`)

        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                onChange?.({
                    blockNumber,
                    text: e.target.value,
                    isCorrect: value?.isCorrect,
                })
            },
            [onChange, blockNumber, value]
        )

        return (
            <div
                className={cn(
                    styles.wrapper,
                    isBoolean(value?.isCorrect) && styles.wrapperVerified
                )}
            >
                <InputControl
                    className={styles.input}
                    suffix={
                        isBoolean(value?.isCorrect) ? (
                            <VerifyIcon isCorrect={value!.isCorrect} />
                        ) : undefined
                    }
                    value={value?.text}
                    onChange={handleChange}
                    placeholder={undefined}
                />
            </div>
        )
    }
)
