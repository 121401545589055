import React from 'react'
import {
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { COMPETENCIES_REQUIRED_PERMISSIONS } from 'consts'
import { CompetenceContract, UserPermission } from 'core/api'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { TableActionsCommonProps } from 'App.types'
import { isDocumentStatusInWork, isDocumentStatusReadyForWork } from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'

/** Компонент для создания колонки действий внутри таблицы реестра компетенций */
export const CompetenciesActions: React.FC<TableActionsCommonProps<
    CompetenceContract
>> = React.memo(({ tableRow }) => {
    const isDeletable =
        !isDocumentStatusReadyForWork(tableRow.status) &&
        !isDocumentStatusInWork(tableRow.status)

    return (
        <ButtonsToolbar bottomIndent="0">
            <TableActions.View
                requiredPermissions={COMPETENCIES_REQUIRED_PERMISSIONS}
                disabled={!tableRow.active}
                href={`${ROUTE_NAMES.COMPETENCIES_VIEW}/${tableRow.id}`}
            />

            {tableRow.active && (
                <>
                    <TableActions.Edit
                        requiredPermissions={[
                            ...COMPETENCIES_REQUIRED_PERMISSIONS,
                            !isDocumentStatusInWork(tableRow.status)
                                ? UserPermission.CompetenceSave
                                : UserPermission.CompetenceExtraSave,
                        ]}
                        href={`${ROUTE_NAMES.COMPETENCIES_EDIT}/${tableRow.id}`}
                    />

                    {isDocumentStatusReadyForWork(tableRow.status) && (
                        <HasPermissions
                            requiredPermissions={[
                                ...COMPETENCIES_REQUIRED_PERMISSIONS,
                                UserPermission.CompetenceDelete,
                            ]}
                            accessDeniedRender={renderAccessDeniedButton({
                                type: 'link',
                                icon: (
                                    <IconsAdapter iconType="FileZipOutlined" />
                                ),
                            })}
                        >
                            <PopupWithForm
                                formId={FORM_IDS.CONFIRM_DELETE_FORM}
                                buttonText=""
                                havePopup={false}
                                buttonOption={{
                                    title: LOCAL.ACTIONS.RECORD_TO_ARCHIVE,
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="FileZipOutlined" />
                                    ),
                                }}
                                formOptions={{
                                    initialValues: tableRow,
                                }}
                                modalOptions={{
                                    title: LOCAL.ACTIONS.RECORD_TO_ARCHIVE,
                                    okText: LOCAL.YES,
                                }}
                                messageText={
                                    LOCAL.MESSAGES.RECORD_TO_ARCHIVE_CONFIRM
                                }
                            />
                        </HasPermissions>
                    )}

                    {isDeletable && (
                        <TableActions.Delete
                            tableRow={tableRow}
                            requiredPermissions={[
                                ...COMPETENCIES_REQUIRED_PERMISSIONS,
                                UserPermission.CompetenceDelete,
                            ]}
                        />
                    )}
                </>
            )}
        </ButtonsToolbar>
    )
})
