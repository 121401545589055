import { CourseFormat } from 'core/api'

/**
 * Проверка формата учебного материала
 * @param format - формат курса
 */
export const isTrainingMaterialFormatPdf = (format?: CourseFormat) =>
    format === CourseFormat.Pdf
export const isTrainingMaterialFormatScorm12 = (format?: CourseFormat) =>
    format === CourseFormat.Scorm12
export const isTrainingMaterialFormatScorm13 = (format?: CourseFormat) =>
    format === CourseFormat.Scorm13
export const isTrainingMaterialFormatTinCan = (format?: CourseFormat) =>
    format === CourseFormat.TinCan
export const isTrainingMaterialFormatAvi = (format?: CourseFormat) =>
    format === CourseFormat.Avi
export const isTrainingMaterialFormatMp4 = (format?: CourseFormat) =>
    format === CourseFormat.Mp4
export const isTrainingMaterialFormatMedia = (format?: CourseFormat) =>
    isTrainingMaterialFormatMp4(format) ||
    isTrainingMaterialFormatAvi(format) ||
    isTrainingMaterialFormatPdf(format)
export const isTrainingMaterialFormatVideo = (format?: CourseFormat) =>
    isTrainingMaterialFormatMp4(format) || isTrainingMaterialFormatAvi(format)
