import React, { useEffect, useRef } from 'react'

export interface UseXScrollResultProps {
    containerWithXScroll: React.RefObject<HTMLDivElement>
}

export interface UseXScrollOptionsProps {
    scrollSpeed?: number
}

/**
 * Хук помогает реализовать функциональность горизонтальной прокрутки при помощи
 * колесика мыши для конкретного контейнера (для этого нужно дать ему ссылку `ref={containerWithYScroll}`)
 */
export function useXScroll(
    { scrollSpeed = 40 } = {} as UseXScrollOptionsProps
): UseXScrollResultProps {
    const containerWithXScroll = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const container = containerWithXScroll.current

        if (!container) return

        const scrollHorizontally = (e: any) => {
            e.preventDefault()

            const delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail))
            container.scrollLeft -= delta * scrollSpeed
        }

        container.addEventListener('mousewheel', scrollHorizontally) // IE9, Chrome, Safari, Opera
        container.addEventListener('DOMMouseScroll', scrollHorizontally) // Firefox

        return () => {
            container.removeEventListener('mousewheel', scrollHorizontally)
            container.removeEventListener('DOMMouseScroll', scrollHorizontally)
        }
    }, [scrollSpeed])

    return {
        containerWithXScroll,
    }
}
