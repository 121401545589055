import { RESOLUTION_RELATIONS, Resolutions } from 'core/configs'
import { Store } from 'App.types'

import { INITIAL_TABS } from './ResponsiveTabs.config'
import { MapFormDataToRequestTrackCanvasProps } from '../../../TrackForm.types'
import { TabeProps } from './ResponsiveTabs.types'
import { TrackStagesVisualization } from '../TrackStagesVisualization'

export const composeTabOptions = (key: string) => ({
    title: RESOLUTION_RELATIONS[key],
    content: TrackStagesVisualization,
    key,
})

export const updateTabsDependingOnExternalCanvases = (
    externalCanvases?: Store<MapFormDataToRequestTrackCanvasProps>
): TabeProps[] => {
    if (!externalCanvases) return INITIAL_TABS

    const resolutions = Object.keys(externalCanvases).filter(
        (resolution) => resolution !== Resolutions.XXL
    )

    return INITIAL_TABS.concat(resolutions.map(composeTabOptions)).sort(
        (prev, next) => Number(prev.key) - Number(next.key)
    )
}
