export const DICTIONARY_KEYS = {
    ADAPTATION_TRACKS: 'ADAPTATION_TRACKS',
    ASSESSMENT_ASSIGNED_EXPERT: 'ASSESSMENT_ASSIGNED_EXPERT',
    ASSESSMENT_ASSIGNED_GROUP_EXPERT: 'ASSESSMENT_ASSIGNED_GROUP_EXPERT',
    ASSESSMENT_ASSIGNMENTS: 'ASSESSMENT_ASSIGNMENTS',
    ASSESSMENT_PORTFOLIOS: 'ASSESSMENT_PORTFOLIOS',
    ASSESSMENT_PROJECTS: 'ASSESSMENT_PROJECTS',
    ASSESSMENT_REPORTS: 'ASSESSMENT_REPORTS',
    CHECK_HOMEWORK: 'CHECK_HOMEWORK',
    COMPETENCIES: 'COMPETENCIES',
    COMPETENCIES_GROUPS: 'COMPETENCIES_GROUPS',
    COMPETENCIES_INDICATORS: 'COMPETENCIES_INDICATORS',
    COWORKERS: 'COWORKERS',
    CRITERIA_SCALES: 'CRITERIA_SCALES',
    DEVELOPMENT_GOALS: 'DEVELOPMENT_GOALS',
    DEVELOPMENT_TRACKS: 'DEVELOPMENT_TRACKS',
    DISTRIBUTION_MAILING: 'DISTRIBUTION_MAILING',
    DISTRIBUTION_MAILING_ASSIGNMENT: 'DISTRIBUTION_MAILING_ASSIGNMENT',
    DISTRIBUTION_MAILING_VIEW: 'DISTRIBUTION_MAILING_VIEW',
    DISTRIBUTION_TAGS: 'DISTRIBUTION_TAGS',
    DISTRIBUTION_TEMPLATES: 'DISTRIBUTION_TEMPLATES',
    DOCUMENT_EDITOR: 'DOCUMENT_EDITOR',
    EVENTS_ASSIGNMENTS: 'EVENTS_ASSIGNMENTS',
    EVENT_CATALOG: 'EVENT_CATALOG',
    EXTERNAL_CERTIFICATES: 'EXTERNAL_CERTIFICATES',
    HOMEWORKS: 'HOMEWORKS',
    INTERNAL_CERTIFICATES: 'INTERNAL_CERTIFICATES',
    JOB_PROFILES: 'JOB_PROFILES',
    KNOWLEDGE_BASE: 'KNOWLEDGE_BASE',
    MATERIAL_TASKS: 'MATERIAL_TASKS',
    MONITORING: 'MONITORING',
    ORGANIZATIONS: 'ORGANIZATIONS',
    PRODUCT_CATALOG: 'PRODUCT_CATALOG',
    QUESTIONNAIRES_LISTENER: 'QUESTIONNAIRES_LISTENER',
    QUESTIONNAIRE_TEMPLATES: 'QUESTIONNAIRE_TEMPLATES',
    QUIZ_ASSIGNMENTS: 'QUIZ_ASSIGNMENTS',
    QUIZ_CHECKING: 'QUIZ_CHECKING',
    QUIZ_PORTFOLIOS: 'QUIZ_PORTFOLIOS',
    REPORTS: 'REPORTS',
    RESULTS_OF_MEETINGS: 'RESULTS_OF_MEETINGS',
    STAFF_UNITS: 'STAFF_UNITS',
    SUPERVISING_COMPLEXES: 'SUPERVISING_COMPLEXES',
    TEST_ASSIGNMENTS: 'TEST_ASSIGNMENTS',
    TEST_PORTFOLIOS: 'TEST_PORTFOLIOS',
    THEMES: 'THEMES',
    TRACKS_UNIQUE: 'TRACKS_UNIQUE',
    TRACK_ASSIGNMENTS: 'TRACK_ASSIGNMENTS',
    TRAINING_MATERIALS: 'TRAINING_MATERIALS',
    USER_ASSIGNMENTS: 'USER_ASSIGNMENTS',
    USER_NOTIFICATIONS: 'USER_NOTIFICATIONS',
}
