import { AgreementStatus } from 'core/api'

/**
 * Рендер типа иконки ряда в этапе листа согласования
 * @param status - статус согласования
 */
export const renderStatusIcon = (status: AgreementStatus) => {
    switch (status) {
        case AgreementStatus.Approved:
            return 'CustomIconCheckFilled'
        case AgreementStatus.OnApproval:
            return 'CustomIconStatusOnApproval'
        case AgreementStatus.New:
            return 'CustomIconStatusNew'
    }
}
