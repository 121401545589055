import React, { useCallback } from 'react'
import { AttachmentsService } from 'core/api'
import { Button, IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker } from 'components/controls'
import { UploadFileWrapper } from 'components/shared/UploadFileWrapper'
import { beforeUploadAttachment, isFormModeView } from 'utils'

import styles from './UploadFileForQuestion.module.scss'
import { ACCEPT_FORMATS } from '../QuestionForm/QuestionForm.consts'
import { UploadFileForQuestionProps } from './UploadFileForQuestion.types'
import { getFormatedQuestions } from './UploadFileForQuestion.utils'

export const UploadFileForQuestion: React.FC<UploadFileForQuestionProps> = React.memo(
    ({ fieldName, formMode, isInactive, updateLoader, isLoading }) => {
        const handleFileRemove = useCallback(
            (form) => async () => {
                if (isLoading) return

                const values = form?.getFieldsValue()

                const newQuestions = getFormatedQuestions(
                    values.questions,
                    fieldName
                )

                form?.setFieldsValue({
                    ...values,
                    questions: newQuestions,
                })
            },
            [isLoading, fieldName]
        )

        return (
            <ShouldUpdateChecker
                fieldPath={['questions', fieldName, 'attachments']}
            >
                {(form) => {
                    const uploadFile = form?.getFieldValue([
                        'questions',
                        fieldName,
                        'attachments',
                    ])?.[0]

                    return (
                        <UploadFileWrapper
                            name={[fieldName, 'attachments']}
                            acceptedTypes={ACCEPT_FORMATS}
                            updateLoader={updateLoader}
                            isLoading={isLoading}
                            handleFileRemove={handleFileRemove(form)}
                            uploadMethod={AttachmentsService.upload}
                            label={
                                !isFormModeView(formMode) &&
                                `${LOCAL.LABELS.UPLOAD_FILE_ALLOWED_FORMATS} ${ACCEPT_FORMATS}`
                            }
                            validateFiles={beforeUploadAttachment({
                                documentType: ACCEPT_FORMATS,
                                errors: {
                                    type: LOCAL.MESSAGES.WRONG_FORMAT,
                                    size: LOCAL.MESSAGES.WRONG_SIZE_LIMIT,
                                },
                            })}
                            initialFile={{
                                name: uploadFile?.name,
                                id: uploadFile?.response?.id,
                            }}
                            disabled={isFormModeView(formMode) || isInactive}
                            className={styles.uploadFile}
                        >
                            <Button
                                type="link"
                                disabled={
                                    isFormModeView(formMode) ||
                                    isInactive ||
                                    isLoading
                                }
                            >
                                <IconsAdapter iconType="FileTextOutlined" />

                                {LOCAL.ACTIONS.DOWNLOAD_FILE}
                            </Button>
                        </UploadFileWrapper>
                    )
                }}
            </ShouldUpdateChecker>
        )
    }
)
