import React, { useEffect, useState } from 'react'
import { InfoAssistant } from 'components/shared'
import {
    LOCAL_STORAGE_KEYS,
    LocalStorageHelper,
} from 'core/helpers/LocalStorage'
import { Resolutions } from 'core/configs'
import {
    TrackEvent,
    TrackHeaderIconContract,
    TrackPassingContract,
    TrackStagePassingContract,
} from 'core/api'
import { registerEvents, unRegisterEvents } from 'core/helpers/eventEmitter'

import {
    FormDataStagesStylesProps,
    IconStatusProps,
} from '../Track/components/TrackForm/TrackForm.types'
import { OnMoveToNextStatusProps } from './TrackPreview.types'
import { TRACK_PREVIEW_EVENTS } from './TrackPreview.events'
import { TRACK_STAGE_STATUSES } from '../Track/components/TrackForm/components/shared/TrackStageDnD/TrackStageDnD.consts'
import { ToggleIcons } from './components/ToggleIcons'
import { TrackLayerPreview } from './components/TrackLayerPreview'
import { getNextIconStatus } from '../Track/components/TrackForm/components/shared/TrackStageDnD/TrackStageDnD.utils'

export const TrackPreview: React.FC = () => {
    const [trackInfo, setTrackInfo] = useState<Partial<TrackPassingContract>>()

    useEffect(() => {
        const handleMoveStagesStatus = (status?: IconStatusProps) => {
            setTrackInfo((prev) => ({
                ...prev,
                stages: prev?.stages?.map((stage) => ({
                    ...stage,
                    iconStatus: status,
                })),
            }))
        }

        const handleMoveToNextStatus = ({
            currentStageStatus,
            currentStageIndex,
        }: OnMoveToNextStatusProps) => {
            const nextStatus = getNextIconStatus(currentStageStatus)

            setTrackInfo((prev) => ({
                ...prev,
                stages: prev?.stages?.map((stage, index) => ({
                    ...stage,
                    ...(currentStageIndex === index
                        ? { iconStatus: nextStatus }
                        : {}),
                })),
            }))
        }

        const events = {
            [TRACK_PREVIEW_EVENTS.MOVE_STAGE_STATUS]: handleMoveStagesStatus,
            [TRACK_PREVIEW_EVENTS.MOVE_TO_NEXT_STATUS]: handleMoveToNextStatus,
        }

        registerEvents(events)

        return () => {
            unRegisterEvents(events)
        }
    }, [])

    useEffect(() => {
        const setTrackInfoForPreview = () => {
            const trackStageCreatePreview = LocalStorageHelper.getItem(
                LOCAL_STORAGE_KEYS.TRACK_PREVIEW
            )

            if (!trackStageCreatePreview) return

            setTrackInfo({
                useInformationAssistant:
                    trackStageCreatePreview.useInformationAssistant,
                trackBackgroundPicture:
                    trackStageCreatePreview.trackBackgroundPicture,
                stages: trackStageCreatePreview.stages?.map(
                    (stage: TrackStagePassingContract) => ({
                        ...stage,
                        iconStatus: TRACK_STAGE_STATUSES[0],
                    })
                ),
                trackCanvases: Object.keys(
                    trackStageCreatePreview.trackCanvases
                )
                    .filter((key) => key === Resolutions.XXL)
                    .map((key) => ({
                        ...(trackStageCreatePreview.trackCanvases?.[key] || {}),
                        screenResolution: +key,
                        trackCanvasBackground: {
                            url:
                                trackStageCreatePreview.trackCanvases?.[key]
                                    ?.meta.currentBackgroundUrl,
                        },
                        trackStageStyles: trackStageCreatePreview.trackCanvases?.[
                            key
                        ]?.trackStageStyles.map(
                            (el: FormDataStagesStylesProps) => ({
                                ...el,
                                icons: {
                                    beforeStart: {
                                        ...el.icons.beforeStart,
                                        url:
                                            el.icons.beforeStart?.meta
                                                .currentIconUrl,
                                    },
                                    inProcess: {
                                        ...el.icons.inProcess,
                                        url:
                                            el.icons.inProcess?.meta
                                                .currentIconUrl,
                                    },
                                    passing: {
                                        ...el.icons.passing,
                                        url:
                                            el.icons.passing?.meta
                                                .currentIconUrl,
                                    },
                                },
                            })
                        ),
                    })),
                trackHeaderIcons: trackStageCreatePreview.trackHeaderIcons as TrackHeaderIconContract[],
            } as TrackPassingContract)
        }

        setTrackInfoForPreview()
    }, [])

    return (
        <div>
            <TrackLayerPreview trackInfo={trackInfo} />

            {trackInfo?.useInformationAssistant && (
                <InfoAssistant
                    mode="preview"
                    event={TrackEvent.Greeting}
                    visible
                />
            )}

            <ToggleIcons />
        </div>
    )
}
