import logo from 'core/images/logo.svg'
import React, { FC } from 'react'
import { LOCAL } from 'core/local'
import { Link } from 'react-router-dom'
import { ROUTE_NAMES } from 'routing/routeNames.consts'

import styles from './Logo.module.scss'
import { LogoProps } from './Logo.types'

export const Logo: FC<LogoProps> = React.memo(
    ({ className, isLink = true }) => {
        const logoBody = (
            <>
                <img src={logo} alt="Онлайн университет" />

                <span>
                    {LOCAL.LABELS.ONLINE}

                    <br />

                    {LOCAL.LABELS.UNIVERSITY}
                </span>
            </>
        )

        return (
            <div className={className}>
                {isLink ? (
                    <Link className={styles.logo} to={ROUTE_NAMES.MAIN}>
                        {logoBody}
                    </Link>
                ) : (
                    <div className={styles.logo}>{logoBody}</div>
                )}
            </div>
        )
    }
)
