import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DOCUMENT_STATUSES } from 'consts'
import { IndicatorContract } from 'core/api'
import { LOCAL } from 'core/local'
import { WidthRestrictedElement } from 'components/shared/WidthRestrictedElement'
import { defaultSorter } from 'components/layouts'
import { formatDate } from 'utils/date.utils'
import { restrictBigText } from 'utils/table.utils'
import { sortByDefaultRule } from 'utils'

import { IndicatorsActions } from '../components'

/** настройка отображения колонок таблицы */
export const COMPETENCIES_INDICATORS_TABLE_COLUMNS: ColumnsType<IndicatorContract> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'indicatorType',
        key: 'indicatorType',
        render: (indicatorType) => restrictBigText(indicatorType?.name),
        width: '350px',
    },
    {
        title: LOCAL.LABELS.VERSION,
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
            <WidthRestrictedElement title={DOCUMENT_STATUSES[record.status]} />
        ),
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => <IndicatorsActions tableRow={tableRow} />,
        align: 'right',
        fixed: 'right',
    },
]
