import React, { FC } from 'react'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

import { HasPermissionsProps } from './HasPermissions.types'

/** Компонент для ограничения доступа к отдельным элементам внутри страницы приложения */
export const HasPermissions: FC<HasPermissionsProps> = React.memo(
    ({
        requiredPermissions = [],
        conditionalPermissions,
        children,
        accessDeniedRender,
    }) => {
        const {
            hasPermissionsConditional,
            hasPermissionsRequired,
        } = useCheckPermissions()

        return (
            <>
                {hasPermissionsRequired(requiredPermissions) &&
                hasPermissionsConditional(conditionalPermissions)
                    ? children
                    : accessDeniedRender}
            </>
        )
    }
)
