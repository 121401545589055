import React from 'react'
import { LOCAL } from 'core/local'
import { Title } from 'components/shared'

import styles from './ProductCardOutcomes.module.scss'
import { ProductCardCarousel } from '../ProductCardCarousel'
import { ProductCardOutcomesProps } from './ProductCardOutcomes.types'
import { SLIDES_TO_SHOW } from './ProductCardOutcomes.consts'
import { renderBlockWithCheckIcon } from '../ProductCardCarousel/ProductCardCarousel.utils'

/** Карточка продукта - блок "чему вы научитесь" */
export const ProductCardOutcomes: React.FC<ProductCardOutcomesProps> = React.memo(
    ({ outcomes }) => (
        <div className={styles.wrapper}>
            <Title
                type="h6"
                text={`${LOCAL.LABELS.WHAT_YOU_WILL_LEARN}:`}
                bottomIndent="0"
            />

            <ProductCardCarousel
                slidesToShow={SLIDES_TO_SHOW}
                itemsCount={outcomes.length}
            >
                {outcomes.map(renderBlockWithCheckIcon(styles))}
            </ProductCardCarousel>
        </div>
    )
)
