import React, { ReactNode, useCallback, useState } from 'react'
import { FnActionProps } from 'App.types'
import { LOCAL } from 'core/local'
import { MODAL_WIDTH } from 'consts'
import { Popup } from 'components/shared/Popup'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'

import { SearchTableShowButtonProps } from './SearchTableShowButton.types'
import { TableSearchForm } from '../TableSearchForm/TableSearchForm'

export function SearchTableShowButton<
    Request extends object,
    Response extends { id: number }
>({
    children,
    tableSearchOptions,
    okButtonText = LOCAL.ACTIONS.ADD,
    modalTitle = LOCAL.LABELS.SEARCH,
    modalWidth = MODAL_WIDTH.LG,
    className,
    onBeforeChange,
}: SearchTableShowButtonProps<Request, Response> & {
    children?: ReactNode
}) {
    const [modalVisibility, setModalVisibility] = useState(false)

    /**
     * Обработчик закрытия модального окна
     */
    const handleCloseModal = useCallback(() => {
        setModalVisibility(false)
    }, [])

    /**
     * Обработчик открытия модального окна с формой выбора значения для селекта
     */
    const handleShowModal = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.preventDefault()

            const startTrigger = () => {
                setModalVisibility(true)
            }

            if (onBeforeChange) {
                onBeforeChange({ onChange: startTrigger })
            } else {
                setModalVisibility(true)
            }
        },
        [onBeforeChange]
    )

    const {
        onSelectTableItem,
        formId,
        ...restTableSearchOptions
    } = tableSearchOptions

    const onRequestFinish = useCallback<FnActionProps>(
        (values) => {
            handleCloseModal()

            onSelectTableItem?.(values)
        },
        [handleCloseModal, onSelectTableItem]
    )

    return (
        <div className={className}>
            <a href="#s" onClick={handleShowModal}>
                {children}
            </a>

            <Popup
                formId={formId}
                visible={modalVisibility}
                onCancel={handleCloseModal}
                destroyOnClose
                title={modalTitle}
                okText={okButtonText}
                width={modalWidth}
                centered={true}
                getContainer={getDropdownPopupContainerDefault}
            >
                <TableSearchForm<Request, Response>
                    id={formId}
                    onRequestFinish={onRequestFinish}
                    {...restTableSearchOptions}
                />
            </Popup>
        </div>
    )
}

export default React.memo(SearchTableShowButton) as typeof SearchTableShowButton
