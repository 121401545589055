import { AppointmentTrackRespondentProgressContract } from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { renderAppointmentTrackStatus } from 'utils'

/** настройка отображения колонок таблицы прогресса назначения трека */
export const TRACK_ASSIGNMENTS_TABLE_COLUMNS: ColumnsType<AppointmentTrackRespondentProgressContract> = [
    {
        title: LOCAL.LABELS.FIO,
        dataIndex: 'fullName',
        key: 'fullName',
    },
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderAppointmentTrackStatus,
    },
    {
        title: LOCAL.LABELS.PROGRESS,
        dataIndex: 'progress',
        key: 'progress',
        render: (_, record) => `${record.progress}%`,
    },
]
