import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ActionForFormType } from 'components/forms/AssessmentEvaluationForm/AssessmentEvaluationForm.types'
import { AssessmentEvaluationForm } from 'components/forms/AssessmentEvaluationForm'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { ContentTitle } from 'components/shared/ContentTitle'
import {
    ExpertEvaluationListContract,
    ExpertEvaluationListService,
} from 'core/api'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { PageContent } from 'components/shared/PageContent'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsForEditProps } from 'hooks'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import { getBackLinkTitleFromRoute, getPageTitleFromRoute } from 'utils'
import { useLocation, useParams } from 'react-router-dom'

const CURRENT_QUESTIONNAIRE_INDEX_DEFAULT = 0

/**
 * Подтверждение результатов оценки экспертом и экспертом группы
 */
export const AssessmentEvaluation: React.FC<WithBlockUserRoutingComponentProps> = withLoader(
    ({ onBlockUserRouting, updateLoader, isLoading }) => {
        const urlParams = useParams<UrlParamsForEditProps>()
        const location = useLocation()
        const [expertEvaluationList, setExpertEvaluationList] = useState<
            ExpertEvaluationListContract
        >()
        const [
            currentQuestionnaireIndex,
            setCurrentQuestionnaireIndex,
        ] = useState(CURRENT_QUESTIONNAIRE_INDEX_DEFAULT)
        const [actionForForm, setActionForForm] = useState<ActionForFormType>()

        /**
         * Запрос сущности назначения
         */
        const fetchExpertEvaluation = useCallback(
            async (appointmentId: number) => {
                try {
                    updateLoader(true)

                    setExpertEvaluationList(
                        await ExpertEvaluationListService.getExpertEvaluationList(
                            {
                                appointmentId,
                            }
                        )
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [updateLoader]
        )

        useEffect(() => {
            if (urlParams.id && !expertEvaluationList)
                fetchExpertEvaluation(Number(urlParams.id))
        }, [fetchExpertEvaluation, expertEvaluationList, urlParams.id])

        /**
         * Заголовок страницы
         */
        const pageTitle = useMemo(
            () => getPageTitleFromRoute(location.pathname, urlParams),
            [location.pathname, urlParams]
        )

        return (
            <PageContent>
                <ContentTitle
                    title={pageTitle}
                    backLink={{
                        path: ROUTE_NAMES.ASSESSMENT_ASSIGNED,
                        text: getBackLinkTitleFromRoute(
                            ROUTE_NAMES.ASSESSMENT_ASSIGNED
                        ),
                    }}
                >
                    <ButtonsToolbar>
                        <Button
                            form={FORM_IDS.ASSESSMENT_EVALUATION}
                            type="primary"
                            htmlType="submit"
                            onClick={() =>
                                setActionForForm(ActionForFormType.Prev)
                            }
                            disabled={isLoading || !currentQuestionnaireIndex}
                        >
                            {LOCAL.PREV}
                        </Button>

                        <Button
                            form={FORM_IDS.ASSESSMENT_EVALUATION}
                            type="primary"
                            htmlType="submit"
                            onClick={() =>
                                setActionForForm(ActionForFormType.Finish)
                            }
                            disabled={isLoading}
                        >
                            {LOCAL.ACTIONS.COMPLETE}
                        </Button>

                        <Button
                            form={FORM_IDS.ASSESSMENT_EVALUATION}
                            type="primary"
                            htmlType="submit"
                            onClick={() =>
                                setActionForForm(ActionForFormType.Next)
                            }
                            disabled={
                                isLoading ||
                                !expertEvaluationList?.competenceIds[
                                    currentQuestionnaireIndex + 1
                                ]
                            }
                        >
                            {LOCAL.NEXT}
                        </Button>
                    </ButtonsToolbar>
                </ContentTitle>

                <AssessmentEvaluationForm
                    onBlockUserRouting={onBlockUserRouting}
                    actionForForm={actionForForm}
                    currentQuestionnaireIndex={currentQuestionnaireIndex}
                    setCurrentQuestionnaireIndex={setCurrentQuestionnaireIndex}
                    evaluationListData={expertEvaluationList}
                />
            </PageContent>
        )
    }
)

export default React.memo(
    withBlockUserRouting(AssessmentEvaluation, false)
) as typeof AssessmentEvaluation
