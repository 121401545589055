import { API_BASE_URL, APP_URL, IS_DEV } from 'consts/env.consts'
import { FnActionProps } from 'App.types'

/** Согласованный айди нужной кнопки на курсе */
export const NEXT_BUTTON_ID = 'nextBtn'

/** Получить нужную кнопку из айфрейма по айди */
export const getNextButton = (iframe: HTMLIFrameElement) =>
    iframe.contentWindow?.document.getElementById(NEXT_BUTTON_ID)

/** Обновить кнопку "далее" в айфрейме */
export const updateIframeNextButton = (
    iframe: HTMLIFrameElement,
    onButtonClick?: FnActionProps
) => {
    const button = getNextButton(iframe)

    if (button) {
        // Заменяем элемент на клон себя самого, чтобы сбросить обработчики событий
        button.replaceWith(button.cloneNode(true))

        const clonedButton = getNextButton(iframe)

        if (clonedButton) {
            // Если это ссылка - убираем href
            if (clonedButton.getAttribute('href')) {
                clonedButton.removeAttribute('href')
            }

            if (onButtonClick) {
                clonedButton.addEventListener('click', onButtonClick)
            }
        }
    }
}

export const getMaterialLaunchUrl = (url?: string) => {
    if (!url) return

    const launchUrl = `${API_BASE_URL}${url}`

    return IS_DEV
        ? `${APP_URL}${API_BASE_URL}${launchUrl.replace(/^\/+/, '')}`
        : launchUrl
}
