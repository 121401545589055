import React from 'react'
import cn from 'classnames'
import { Radio } from 'antd'

import styles from './ShadowPickerRadio.module.scss'
import { ShadowPickerRadioProps } from './ShadowPickerRadio.types'

/**
 * Компонент типа радио для ShadowPickerControl
 */
export const ShadowPickerRadio: React.FC<ShadowPickerRadioProps> = React.memo(
    ({ value, className }) => (
        <Radio value={value} className={cn(styles.wrapper, className)} />
    )
)
