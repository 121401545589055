import React, { useCallback, useMemo, useState } from 'react'
import { COMPETENCIES_LEVELS } from 'components/pages/JobProfiles/components/JobProfileForm/JobProfileForm.consts'
import { backwardFn } from 'utils'

import { UserProfileCompetenceLevelComparison } from '../UserProfileCompetenceLevelComparison'
import { UserProfileCompetenceLevelComparisonRowProps } from './UserProfileCompetenceLevelComparisonRow.types'

/** Уровень компетенции с индикаторами для сравнения */
export const UserProfileCompetenceLevelComparisonRow: React.FC<UserProfileCompetenceLevelComparisonRowProps> = React.memo(
    ({ defaultValues, indicators, name }) => {
        const [indicatorsVisible, setIndicatorsVisible] = useState(false)

        /**
         * Обработчик показа индикаторов
         */
        const handleIndicatorsVisible = useCallback(
            () => setIndicatorsVisible(backwardFn),
            []
        )

        /**
         * Индикаторы компетенции
         */
        const indicatorsLevels = useMemo(
            () =>
                indicators?.map((indicator) => (
                    <UserProfileCompetenceLevelComparison
                        key={indicator.id}
                        values={COMPETENCIES_LEVELS}
                        defaultValues={indicator.defaultValues}
                        label={indicator.name}
                        isIndicatorLevel
                    />
                )),
            [indicators]
        )

        return (
            <>
                <UserProfileCompetenceLevelComparison
                    values={COMPETENCIES_LEVELS}
                    defaultValues={defaultValues}
                    label={name}
                    haveIndicators={!!indicators?.length}
                    toggleIndicatorsVisible={handleIndicatorsVisible}
                    indicatorsVisible={indicatorsVisible}
                />

                {indicatorsVisible && indicatorsLevels}
            </>
        )
    }
)
