import moment from 'moment'
import {
    ProductCardContract,
    ProductCardPublishContract,
    ProductCardSectionContract,
    ProductCardStudyingPeriodContract,
    ProductCardStudyingPeriodPublishContract,
    ProductCardTeacherContract,
    ProductCardTeacherCreateContract,
    ProductType,
} from 'core/api'
import {
    getItemId,
    getValue,
    mapPictureContractToFormData,
    normalizeDataForSelectAndRadio,
} from 'utils'
import {
    isProductTypeActivity,
    isProductTypeTrack,
} from 'utils/conditions.utils/product.utils'

import {
    ProductCardPublishFormContract,
    ProductCardSectionPublishFormContract,
    ProductCardTeachersFormContract,
    StudiyingPeriodsPublishFormContract,
} from './ProductCatalogForm.types'

/**
 * Маппинг разделов содержания продукта для запроса*
 */
export const mapSectionDataToRequest = (
    sections?: ProductCardSectionPublishFormContract[]
): Partial<ProductCardSectionContract>[] | undefined =>
    sections?.map(
        ({ subSections, order, ...rest }, index) =>
            ({
                ...rest,
                order: order || index + 1,
                subSections: mapSectionDataToRequest(subSections),
            } as ProductCardSectionContract)
    )

/**
 * Маппинг периодов обучения продукта для запроса
 */
export const mapStydingPeriodsToRequest = (
    periods: StudiyingPeriodsPublishFormContract[]
): Partial<ProductCardStudyingPeriodPublishContract>[] | undefined =>
    periods?.map(({ date, responsiblePerson, order, ...rest }, index) => ({
        ...rest,
        order: order || index + 1,
        responsiblePersonId: Number(responsiblePerson?.[0].value),
        startDate: date?.[0],
        endDate: date?.[1],
    }))

/**
 * Маппинг разделов содержания продукта в данные формы
 */
export const mapStudyingPeriodsToFormValues = (
    periods?: ProductCardStudyingPeriodContract[]
): StudiyingPeriodsPublishFormContract[] | undefined =>
    periods?.map(({ startDate, endDate, responsiblePerson, ...rest }) => ({
        ...rest,
        date: [startDate, endDate],
        responsiblePerson: [normalizeDataForSelectAndRadio(responsiblePerson)],
    }))

/**
 * Маппинг данных о преподавателях продукта для запроса
 */
export const mapTeachersToRequest = (
    teachers: ProductCardTeachersFormContract[]
): Partial<ProductCardTeacherCreateContract>[] | undefined =>
    teachers?.map(({ attachment, ...rest }) => ({
        ...rest,
        attachmentId: attachment?.[0]?.response?.id,
    }))

/**
 * Маппинг данных о преподавателях продукта в данные формы
 */
export const mapTeachersToFormValues = (
    teachers?: ProductCardTeacherContract[]
) =>
    teachers?.map(({ url, attachmentId, ...rest }) => ({
        ...rest,
        attachment: [{ response: { url, id: attachmentId } }],
    }))

/**
 * Готовим данные формы для отправки на бек
 */
export const mapFormDataToRequest = ({
    picture,
    organizations,
    placementDate,
    course,
    track,
    activity,
    competences,
    sections,
    teachers,
    studyingPeriods,
    year,
    ...otherFields
}: ProductCardPublishFormContract) =>
    ({
        ...otherFields,
        competenceIds: competences?.map(getValue),
        trackId: track?.[0].value,
        activityId: activity?.[0].value,
        courseId: course?.[0].value,
        pictureId: picture?.[0].response?.id,
        organizationsIds: organizations?.map(getValue),
        startPlacementDate: placementDate?.[0],
        endPlacementDate: placementDate?.[1],
        sections: mapSectionDataToRequest(sections),
        studyingPeriods: mapStydingPeriodsToRequest(studyingPeriods),
        teachers: mapTeachersToRequest(teachers),
        year: year ? Number(moment(year).format('YYYY')) : undefined,
    } as ProductCardPublishContract)

/**
 * Готовим данные с бека для работы с формой
 * @param values - объект значений формы полученный с бека
 */
export const mapResponseToFormData = (values: Partial<ProductCardContract>) => {
    const {
        picture,
        startPlacementDate,
        endPlacementDate,
        themes,
        organizations,
        track,
        course,
        activity,
        levels,
        positions,
        year,
        teachers,
        studyingPeriods,
        competences,
        ...otherFields
    } = values

    return {
        ...otherFields,
        track: track && [normalizeDataForSelectAndRadio(track)],
        activity: activity && [normalizeDataForSelectAndRadio(activity)],
        course: course && [normalizeDataForSelectAndRadio(course)],
        placementDate: startPlacementDate &&
            endPlacementDate && [startPlacementDate, endPlacementDate],
        themesIds: themes?.map(getItemId),
        organizations: organizations?.map(normalizeDataForSelectAndRadio),
        picture: mapPictureContractToFormData(picture),
        positionIds: positions?.map(getItemId),
        levelIds: levels?.map(getItemId),
        year: year ? String(year) : undefined,
        teachers: mapTeachersToFormValues(teachers),
        studyingPeriods: mapStudyingPeriodsToFormValues(studyingPeriods),
        competences: competences?.map(normalizeDataForSelectAndRadio),
    }
}

/**
 *  Доступна ли кнопка добавления периода обучения
 */
export const enableStudyingPeriods = (
    type: ProductType,
    isAvailableRecordForLearning: boolean
) => {
    if (!isProductTypeActivity(type) && !isProductTypeTrack(type)) return true

    return isAvailableRecordForLearning
}
