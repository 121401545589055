import React, { useCallback, useEffect } from 'react'
import { ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS } from 'consts'
import {
    AppointmentProcessTypes,
    downloadFileViaUrl,
    isAppointmentStatusCanceled,
    isAppointmentStatusDraft,
    isAppointmentStatusEditable,
    isAppointmentStatusEnded,
    isAppointmentStatusSuspended,
    isAssessmentTypeEstimate360,
    isAssessmentTypeManager,
    isAvailableToWatch,
} from 'utils'
import {
    AssignmentsLaunchedTable,
    AssignmentsProgressAction,
    ButtonsToolbar,
    TableActions,
} from 'components/shared'
import { Circle360ReportService, UserPermission } from 'core/api'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getConfirmEditMessage } from 'core/local/messages.local.config'
import { onReportConverted } from 'core/helpers/signalr'
import { useConfirmRedirect } from 'hooks'

import { AssessmentAssignmentsTableActionsProps } from './AssessmentAssignmentsTableActions.types'

/** Компонент для создания колонки действий внутри таблицы реестра назначений оценки */
export const AssessmentAssignmentsTableActions: React.FC<AssessmentAssignmentsTableActionsProps> = React.memo(
    ({
        tableRow,
        reFetchDictionary,
        appointmentProcessService,
        updateLoader,
    }) => {
        const isEditable = isAppointmentStatusEditable(tableRow.status)

        const isDeletable =
            isAppointmentStatusDraft(tableRow.status) ||
            isAppointmentStatusCanceled(tableRow.status)

        const isAllowUsage =
            isAvailableToWatch(tableRow.status) &&
            !isAppointmentStatusSuspended(tableRow.status)

        const showReportExport =
            isAppointmentStatusEnded(tableRow.status) &&
            isAssessmentTypeEstimate360(tableRow.assessmentType)

        const { handleRedirect } = useConfirmRedirect({
            to: `${ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_EDIT}/${tableRow.id}`,
            needConfirm: !isAppointmentStatusDraft(tableRow.status),
            confirmText: getConfirmEditMessage('assessment', true),
        })
        /**
         * Обработчик выбора экшена процесса прохождения назначения
         * @param action - выбранный экшен
         */
        const handleChooseAction = useCallback(
            (action: AppointmentProcessTypes) => () => {
                try {
                    updateLoader?.(true)

                    appointmentProcessService(tableRow.id, action).then(
                        reFetchDictionary
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [
                appointmentProcessService,
                reFetchDictionary,
                tableRow.id,
                updateLoader,
            ]
        )

        const buildReport = useCallback(async () => {
            try {
                updateLoader?.(true)
                await Circle360ReportService.startBuildReport({
                    id: tableRow.assessmentReportId,
                })
            } catch (error) {
                console.error(error)
                updateLoader?.(false)
            }
        }, [tableRow.assessmentReportId, updateLoader])

        useEffect(() => {
            onReportConverted((message) => {
                if (message?.url) {
                    downloadFileViaUrl(message.url, LOCAL.LABELS.REPORT)
                }

                updateLoader?.(false)
            })
        }, [updateLoader])

        return (
            <ButtonsToolbar bottomIndent="0">
                {isAppointmentStatusEnded(tableRow.status) &&
                    !isAssessmentTypeManager(tableRow.assessmentType) && (
                        <TableActions.Report
                            requiredPermissions={
                                ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS
                            }
                            href={`${ROUTE_NAMES.ASSESSMENT_REPORTS}/${tableRow.assessmentType}/${tableRow.assessmentReportId}`}
                        />
                    )}

                {showReportExport && (
                    <TableActions.Export
                        requiredPermissions={
                            ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS
                        }
                        onClick={buildReport}
                    />
                )}

                <TableActions.View
                    requiredPermissions={
                        ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS
                    }
                    href={`${ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS_VIEW}/${tableRow.id}`}
                    disabled={!tableRow.active}
                />

                {tableRow.active && (
                    <>
                        {isAllowUsage && (
                            <AssignmentsProgressAction
                                requiredPermissions={[
                                    ...ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.AssessmentAppointmentMonitoring,
                                ]}
                                initialValues={tableRow}
                                reFetchDictionary={reFetchDictionary}
                                component={AssignmentsLaunchedTable}
                            />
                        )}

                        {isEditable && (
                            <TableActions.Edit
                                requiredPermissions={[
                                    ...ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.AssessmentAppointmentSave,
                                ]}
                                onClick={handleRedirect}
                            />
                        )}

                        {isAppointmentStatusSuspended(tableRow.status) && (
                            <TableActions.Play
                                requiredPermissions={[
                                    ...ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.AssessmentAppointmentContinue,
                                ]}
                                onClick={handleChooseAction(
                                    AppointmentProcessTypes.CancelSuspension
                                )}
                            />
                        )}

                        {isAllowUsage && (
                            <TableActions.Pause
                                requiredPermissions={[
                                    ...ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.AssessmentAppointmentPause,
                                ]}
                                onClick={handleChooseAction(
                                    AppointmentProcessTypes.Suspend
                                )}
                            />
                        )}

                        {isAvailableToWatch(tableRow.status) && (
                            <TableActions.Stop
                                requiredPermissions={[
                                    ...ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.AssessmentAppointmentCancel,
                                ]}
                                onClick={handleChooseAction(
                                    AppointmentProcessTypes.Cancel
                                )}
                            />
                        )}

                        {isDeletable && (
                            <TableActions.Delete
                                tableRow={tableRow}
                                requiredPermissions={[
                                    ...ASSESSMENT_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.AssessmentAppointmentDelete,
                                ]}
                            />
                        )}
                    </>
                )}
            </ButtonsToolbar>
        )
    }
)
