import cn from 'classnames'
import React, { useContext } from 'react'
import { Button } from 'components/shared/Button'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { TextAlignProperty } from 'App.types'
import { TrackPassingContext } from 'components/pages/TrackPassing/TrackPassing.context'
import { getBackgroundImageStyle, getStyles } from 'utils'

import styles from './ModalCanvas.module.scss'
import { ActivityChildProps } from '../../ActivityLayer.types'
import { ModalBlocks } from '../ModalBlocks'
import {
    getPassButtonText,
    getThematicBLockColor,
} from '../../ActivityLayer.utils'
import { useCanvas } from '../../ActivityLayer.hooks'

export const ModalCanvas: React.FC<ActivityChildProps> = React.memo(
    ({ activity }) => {
        const { store } = useContext(TrackPassingContext)

        const { activeLessonIndex } = store

        const {
            activeLesson,
            isThematicBlockLock,
            isThematicBlockCompletedAndNotAvailableForRepassing,
            showFurtherButton,
            showReturnButton,
            returnToPrevLayer,
            showFinishActivityModal,
            startLesson,
        } = useCanvas({ activity })

        const lessons = activity?.activityLessonPassings
        const canvas = activity?.canvas

        const buttonStyles = {
            background: canvas?.buttonColor,
            color: canvas?.buttonTextColor,
        }

        return (
            <div
                className={styles.wrapper}
                style={{
                    justifyContent: canvas?.windowAlign,
                }}
            >
                <div className={styles.body}>
                    {showReturnButton && (
                        <IconsAdapter
                            iconType="CloseOutlined"
                            className={styles.closeButton}
                            onClick={returnToPrevLayer}
                            style={{ color: buttonStyles.background }}
                        />
                    )}

                    <div
                        className={styles.image}
                        style={getBackgroundImageStyle(
                            canvas?.canvasBackground?.url
                        )}
                    />

                    <h1
                        className={styles.blockTitle}
                        style={getStyles(canvas?.blockNameSettings)}
                    >
                        {activeLesson?.name}
                    </h1>

                    <pre
                        className={styles.blockDescription}
                        style={{
                            ...getStyles(canvas?.blockDescriptionSettings),
                            borderColor: getThematicBLockColor(
                                lessons?.[activeLessonIndex || 0].status,
                                canvas
                            ),
                        }}
                    >
                        {activeLesson?.lessonDescription}
                    </pre>

                    <div className={styles.blocks}>
                        <ModalBlocks activity={activity} />
                    </div>

                    <div
                        style={{
                            textAlign: canvas?.buttonAlign as TextAlignProperty,
                        }}
                        className={styles.nav}
                    >
                        {!isThematicBlockCompletedAndNotAvailableForRepassing &&
                            !isThematicBlockLock && (
                                <Button
                                    className={cn(
                                        styles.startButton,
                                        'capitalize-text'
                                    )}
                                    style={{
                                        background: canvas?.buttonColor,
                                        color: canvas?.buttonTextColor,
                                    }}
                                    onClick={startLesson}
                                >
                                    {getPassButtonText(
                                        activeLesson,
                                        activity?.status
                                    )}
                                </Button>
                            )}

                        {showFurtherButton && (
                            <Button
                                style={buttonStyles}
                                onClick={showFinishActivityModal}
                                className={cn(
                                    styles.nextButton,
                                    'capitalize-text'
                                )}
                            >
                                {LOCAL.ACTIONS.FURTHER}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        )
    }
)
