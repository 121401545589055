import { ActivityLessonPassingContract, LessonType } from 'core/api'

export const getDurationHours = (el: ActivityLessonPassingContract): number => {
    switch (el.type) {
        case LessonType.WebConference:
            return el.schedule?.webConference?.durationHours

        case LessonType.FaceToFaceMeeting:
            return el.schedule?.faceToFaceMeeting?.durationHours
        default:
            return el.courseDuration
    }
}

export const getDurationMinutes = (
    el: ActivityLessonPassingContract
): number => {
    switch (el.type) {
        case LessonType.WebConference:
            return el.schedule?.webConference?.durationMinutes

        case LessonType.FaceToFaceMeeting:
            return el.schedule?.faceToFaceMeeting?.durationMinutes
        default:
            return el.courseDuration
    }
}
