import React, { ReactText } from 'react'
import {
    AppointmentTestReviewContract,
    AppointmentTestReviewCreateContract,
    AppointmentTestReviewParticipantsContract,
    DictionaryContract,
} from 'core/api'
import { ColumnsType } from 'antd/lib/table'
import {
    ElementWithListPopover,
    EyeBlock,
    SpaceAdapter,
} from 'components/shared'
import { LOCAL } from 'core/local'
import { formatDate, getDynamicColName } from 'utils'

import {
    AnswerProps,
    QuestionsInfoProps,
    ReviewTableNameGroups,
    TestReviewFormValuesProps,
    TestReviewTableRowChildrenProps,
    TestReviewTableRowParentProps,
} from './TabReview.types'
import { ResultScore } from '../ResultScore'
import { TABLE_REVIEW_FORM_ENTRY_POINT } from './TabReview.consts'
import { TableActionField } from '../TableActionField'
import { getActionCellFormName } from '../ResultScore/ResultScore.utils'

/** Маппинг отправляемых данных */
export function mapFormDataToRequest(formValues: TestReviewFormValuesProps) {
    const { id, appointmentId, participants } = formValues[
        TABLE_REVIEW_FORM_ENTRY_POINT
    ]
    const participantKeys = Object.keys(participants)

    return {
        id: Number(id),
        appointmentId: Number(appointmentId),
        participants: participantKeys.map((userKey) => {
            const {
                id,
                comment,
                finalEvaluation,
                participantId,
                questionAnswer,
            } = participants[userKey]

            const answerKeys = Object.keys(questionAnswer || {})

            return {
                id: Number(id),
                comment,
                finalEvaluation: Number(finalEvaluation),
                participantId: Number(participantId),
                questionAnswer: answerKeys.map((key) => {
                    if (!questionAnswer?.[key]) return {}

                    return {
                        ...questionAnswer?.[key],
                    }
                }),
            }
        }),
    } as AppointmentTestReviewCreateContract
}

/** Маппинг получаемых данных*/
export function mapResponseToFormData(
    response: AppointmentTestReviewContract[],
    appointmentId: number
) {
    const commonParticipants = response.reduce<
        AppointmentTestReviewParticipantsContract[]
    >((acc, reviewGroup) => [...acc, ...reviewGroup.participants], [])

    return response.reduce(
        (acc, reviewGroup) => ({
            ...acc,
            id: reviewGroup.id,
            appointmentId,
            participants: {
                ...commonParticipants.reduce(
                    (acc, userData) => ({
                        ...acc,
                        [getDynamicColName<ReviewTableNameGroups>(
                            ReviewTableNameGroups.User,
                            userData.user.id
                        )]: {
                            id: userData.id,
                            comment: userData.comment?.text,
                            finalEvaluation: userData.finalEvaluation,
                            participantId: userData.user.id,
                            questionAnswer: {
                                ...userData.questionAnswer.reduce(
                                    (acc, answerData) => ({
                                        ...acc,
                                        [getDynamicColName<
                                            ReviewTableNameGroups
                                        >(
                                            ReviewTableNameGroups.Answer,
                                            answerData.question.id
                                        )]: {
                                            id: answerData.id,
                                            questionAnswerId:
                                                answerData.answer.id,
                                            reviewerEvaluation:
                                                answerData.reviewerEvaluation,
                                        },
                                    }),
                                    {}
                                ),
                            },
                        },
                    }),
                    {}
                ),
            },
        }),
        {}
    )
}

/**
 * typeGuard для объектов dataSource таблицы
 * @param tableRow - объект с данными ряда таблицы
 */
export const isChildDataItem = (
    tableRow: TestReviewTableRowChildrenProps | TestReviewTableRowParentProps
): tableRow is TestReviewTableRowChildrenProps => !tableRow.children

export function getReviewerEvaluationCellFormName(
    userId: ReactText,
    questionNum: number
) {
    return [
        TABLE_REVIEW_FORM_ENTRY_POINT,
        'participants',
        getDynamicColName<ReviewTableNameGroups>(
            ReviewTableNameGroups.User,
            userId
        ),
        'questionAnswer',
        getDynamicColName<ReviewTableNameGroups>(
            ReviewTableNameGroups.Answer,
            questionNum
        ),
        'reviewerEvaluation',
    ]
}

/** Группы утилит для генерации колонок таблицы-оценки */
export const reviewTableColsUtils = {
    /**
     * Создать колонку таблицы типа "Вопрос"
     * @param num - номер вопроса
     * @param question - сущность вопроса
     */
    makeQuestionCol(num: number, question: DictionaryContract) {
        return {
            title: (
                <EyeBlock
                    title={`${LOCAL.LABELS.QUESTION} ${num}`}
                    modalContent={question.name}
                />
            ),
            dataIndex: getDynamicColName<ReviewTableNameGroups>(
                ReviewTableNameGroups.Answer,
                question.id
            ),

            render: (
                cellContent: AnswerProps,
                tableRow: TestReviewTableRowChildrenProps
            ) =>
                !!cellContent && (
                    <SpaceAdapter size={30}>
                        <EyeBlock
                            title={`${LOCAL.LABELS.ANSWER} ${num}`}
                            modalContent={cellContent.name}
                        />

                        <SpaceAdapter>
                            <TableActionField
                                name={getReviewerEvaluationCellFormName(
                                    tableRow.id,
                                    question.id
                                )}
                                type="answerRate"
                            />

                            <ElementWithListPopover
                                items={[
                                    `${cellContent?.reviewerFullname || ''} ${
                                        formatDate(
                                            cellContent?.revieweredDate
                                        ) || ''
                                    }`,
                                ]}
                                options={{
                                    showPopoverAlways: true,
                                    showWithoutTitle: true,
                                }}
                            />
                        </SpaceAdapter>
                    </SpaceAdapter>
                ),
        }
    },

    /**
     * Создать колонку таблицы типа "Оценка ответа на вопрос"
     * @param num - номер вопроса
     * @param questionId - идентификатор вопроса
     */
    makeAnswerRateCol(num: number, questionId: number) {
        return {
            title: '',
            dataIndex: getDynamicColName<ReviewTableNameGroups>(
                ReviewTableNameGroups.Answer,
                questionId
            ),
            width: 155,
            render: (
                cellContent: string,
                tableRow: TestReviewTableRowChildrenProps
            ) =>
                cellContent && (
                    <TableActionField
                        name={getReviewerEvaluationCellFormName(
                            tableRow.id,
                            questionId
                        )}
                        type="answerRate"
                    />
                ),
        }
    },

    /**
     * Создать колонки таблицы, которые зависят от кол-ва вопросов для проверки
     * @param questionsInfo - данные по вопросам, содержащимся в тестах
     * @returns {ColumnsType} - возвращает объекты для создания динамических столбцов таблицы
     */
    makeDynamicColumns(questionsInfo: QuestionsInfoProps[]): ColumnsType {
        return questionsInfo.reduce<ColumnsType>((acc, info) => {
            const { question, questionNumber } = info

            return [
                ...acc,
                this.makeQuestionCol(questionNumber, question),
            ] as ColumnsType
        }, [])
    },

    /**
     * Создание итогового набора столбцов для таблицы-оценки
     * @param questionsInfo - данные по вопросам для проверки
     * @returns {ColumnsType} - возвращает объекты для создания итоговых столбцов таблицы
     */
    getTableColumns<RecordType>(
        questionsInfo: QuestionsInfoProps[]
    ): ColumnsType<object> {
        return [
            { title: LOCAL.LABELS.EMPLOYEES, dataIndex: 'main' },
            ...this.makeDynamicColumns(questionsInfo),
            {
                title: LOCAL.LABELS.TOTAL_SCORE,
                dataIndex: 'resultScore',
                align: 'center',
                render: (
                    cellContent,
                    tableRow: TestReviewTableRowChildrenProps
                ) =>
                    isChildDataItem(tableRow) && (
                        <ResultScore
                            name={getActionCellFormName(
                                tableRow.id,
                                'finalEvaluation'
                            )}
                            tableRow={tableRow}
                        />
                    ),
            },
            {
                dataIndex: 'comment',
                title: LOCAL.LABELS.COMMENTS_ON_TEXT,
                render: (
                    cellContent,
                    tableRow:
                        | TestReviewTableRowChildrenProps
                        | TestReviewTableRowParentProps
                ) =>
                    isChildDataItem(tableRow) && (
                        <SpaceAdapter>
                            <TableActionField
                                name={getActionCellFormName(
                                    tableRow.id,
                                    'comment'
                                )}
                                type="comment"
                            />

                            <ElementWithListPopover
                                items={[
                                    `${tableRow.comment?.userFullName || ''} ${
                                        formatDate(
                                            tableRow.comment?.commentedDate
                                        ) || ''
                                    }`,
                                ]}
                                options={{
                                    showPopoverAlways: true,
                                    showWithoutTitle: true,
                                }}
                            />
                        </SpaceAdapter>
                    ),
            },
        ] as ColumnsType<object>
    },
}

/** Группа утилит по генерации контента таблицы оценки */
export const reviewTableDataSourceUtils = {
    /**
     * Создать часть контента таблицы, содержащую ответ пользователя
     * @param num - номер вопроса
     * @param colType - тип столбца, к которой будет относиться ячейка
     * @param content - контент ячейки
     */
    makeAnswerDataItem(
        num: number,
        colType: ReviewTableNameGroups,
        content: AnswerProps
    ) {
        return {
            [getDynamicColName<ReviewTableNameGroups>(colType, num)]: content,
        }
    },

    /**
     * Получить итоговый контент таблицы в виде dataSource
     * @param reviewCommonData - коллекция данных по всем тестам для проверки
     */
    getDataSource(reviewCommonData?: AppointmentTestReviewContract[]) {
        return reviewCommonData?.map((reviewGroup, index) => {
            const { participants, structuralUnit } = reviewGroup

            return {
                id: String(index),
                key: String(index),
                main: structuralUnit.name,
                children: participants.map((worker) => {
                    const { user, comment, questionAnswer } = worker

                    return {
                        id: String(user.id),
                        key: String(user.id),
                        main: user.name,
                        comment,
                        ...questionAnswer.reduce(
                            (acc, questionUnit) => ({
                                ...acc,
                                ...this.makeAnswerDataItem(
                                    questionUnit.question.id,
                                    ReviewTableNameGroups.Answer,
                                    {
                                        name: questionUnit.answer.name,
                                        reviewerFullname:
                                            questionUnit.reviewerFullname,
                                        revieweredDate:
                                            questionUnit.revieweredDate,
                                    }
                                ),
                            }),
                            {}
                        ),
                    }
                }),
            }
        })
    },
}
