import React from 'react'
import { LOCAL } from 'core/local'
import { formatDate } from 'utils'

import styles from '../../UserProfile.module.scss'
import { UserProfileFooterProps } from './UserProfileFooter.types'

export const UserProfileFooter: React.FC<UserProfileFooterProps> = React.memo(
    ({ userProfile }) => {
        /**
         * Дата актуализации сведений о профиле
         */
        const changeDate = (
            <div className={styles.changeDate}>
                {`${LOCAL.LABELS.INFORMATION_DATE_UPDATE} ${formatDate(
                    userProfile?.lastChangesDate
                )}`}
            </div>
        )

        return userProfile?.lastChangesDate ? changeDate : <div />
    }
)
