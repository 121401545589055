import React from 'react'
import {
    Article,
    ErrorPage,
    KnowledgeBase,
    KnowledgeBaseFormContainer,
} from 'components/pages'
import { KNOWLEDGE_BASE_REQUIRED_PERMISSIONS } from 'consts'
import { ProtectedRoute } from 'core/permissions'
import { Route, Switch } from 'react-router-dom'
import { UserPermission } from 'core/api'

import { ROUTE_NAMES } from '../routeNames.consts'

export const KnowledgeBaseRouting: React.FC = React.memo((props) => (
    <Switch>
        <ProtectedRoute
            requiredPermissions={[
                ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                UserPermission.KnowledgeBaseArticleSave,
            ]}
            path={ROUTE_NAMES.KNOWLEDGE_BASE_CREATE}
        >
            <KnowledgeBaseFormContainer formMode="create" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[
                ...KNOWLEDGE_BASE_REQUIRED_PERMISSIONS,
                UserPermission.KnowledgeBaseArticleSave,
            ]}
            path={`${ROUTE_NAMES.KNOWLEDGE_BASE_EDIT}/:id`}
        >
            <KnowledgeBaseFormContainer formMode="edit" />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={KNOWLEDGE_BASE_REQUIRED_PERMISSIONS}
            path={`${ROUTE_NAMES.KNOWLEDGE_BASE_VIEW}/:id`}
        >
            <Article />
        </ProtectedRoute>

        <ProtectedRoute
            requiredPermissions={[UserPermission.KnowledgeBaseRead]}
            path={ROUTE_NAMES.KNOWLEDGE_BASE}
        >
            <KnowledgeBase />
        </ProtectedRoute>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
