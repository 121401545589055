import template from 'lodash/template'
import React, {
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, TableAdapter } from 'components/shared'
import { Col, Divider, Row } from 'antd'
import {
    FaceToFaceMeetingListenerContract,
    FaceToFaceMeetingsJournalsService,
} from 'core/api'
import { LOCAL } from 'core/local'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { ROW_GUTTER } from 'consts'
import { createSuccessNotification } from 'utils'
import { useHttp } from 'hooks'
import { withLoader } from 'HOCs'

import styles from './ResultsOfMeetingsModal.module.scss'
import { getColumns } from './ResultsOfMeetingsModal.config.table'

/**
 * Результаты очных встреч - содержимое модального окна
 */
export const ResultsOfMeetingsModal: FC<PopupAdapterFormProps> = withLoader(
    memo(({ initialValues, onCancelSubmit, onRefetch, updateLoader }) => {
        const { id } = initialValues || {}
        const [listeners, setListeners] = useState<
            FaceToFaceMeetingListenerContract[]
        >([])
        const [getJournal, journalResult] = useHttp(
            FaceToFaceMeetingsJournalsService.get,
            updateLoader
        )
        const [confirm] = useHttp(FaceToFaceMeetingsJournalsService.publish)
        const [save] = useHttp(FaceToFaceMeetingsJournalsService.save)

        /**
         * Сохранение результатов
         */
        const handleSave = useCallback(async () => {
            try {
                updateLoader(true)

                await save({ body: { id, listeners } })

                onCancelSubmit?.()
                createSuccessNotification(LOCAL.MESSAGES.CHANGES_SAVED)
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [id, listeners, onCancelSubmit, save, updateLoader])

        /**
         * Подтверждение результатов
         */
        const handleConfirm = useCallback(async () => {
            await confirm({ body: { id, listeners } })

            onCancelSubmit?.()
            onRefetch?.()
            createSuccessNotification(LOCAL.MESSAGES.MEETING_CONFIRMED)
        }, [confirm, id, listeners, onCancelSubmit, onRefetch])

        /**
         * Изменение свитчера на зачет/незачет
         */
        const handleChangePassing = useCallback(
            (userId: number) => (checked: boolean) => {
                setListeners((prev) =>
                    prev.map((user) =>
                        user.id === userId
                            ? { ...user, isPassed: checked }
                            : user
                    )
                )
            },
            [setListeners]
        )

        /**
         * Количество зачетов
         */
        const passedCount = useMemo(
            () => listeners.filter(({ isPassed }) => isPassed).length,
            [listeners]
        )

        /**
         * Количество незачетов
         */
        const notPassedCount = useMemo(
            () => listeners.filter(({ isPassed }) => !isPassed).length,
            [listeners]
        )

        useEffect(() => {
            getJournal({ id })
        }, [getJournal, id])

        useEffect(() => {
            if (journalResult) {
                setListeners(journalResult.listeners)
            }
        }, [setListeners, journalResult])

        return (
            <div className={styles.wrapper}>
                <Row className={styles.header} gutter={ROW_GUTTER}>
                    <Col xs={16}>
                        <label>{`${LOCAL.LABELS.LISTENERS_COUNT}:`}</label>

                        <p>
                            {template(LOCAL.LABELS.HUMANS_TEMPLATE)({
                                count: listeners.length,
                            })}
                        </p>
                    </Col>

                    <Col xs={4}>
                        <label>{`${LOCAL.LABELS.APPEARANCE}:`}</label>

                        <p>{passedCount}</p>
                    </Col>

                    <Col xs={4}>
                        <label>{`${LOCAL.LABELS.NONAPPEARANCE}:`}</label>

                        <p>{notPassedCount}</p>
                    </Col>
                </Row>

                <Divider />

                <div className={styles.table_wrapper}>
                    <h3 className={styles.title}>{LOCAL.LABELS.LISTENERS}</h3>

                    <TableAdapter
                        columns={getColumns(handleChangePassing)}
                        className={styles.table}
                        dataSource={listeners}
                    />
                </div>

                <ButtonsToolbar align="flex-end">
                    <Button key="cancel" type="link" onClick={onCancelSubmit}>
                        {LOCAL.ACTIONS.RETURN}
                    </Button>

                    <Button key="save" onClick={handleSave}>
                        {LOCAL.ACTIONS.SAVE}
                    </Button>

                    <Button key="confirm" onClick={handleConfirm}>
                        {LOCAL.ACTIONS.CONFIRM}
                    </Button>
                </ButtonsToolbar>
            </div>
        )
    })
)
