import { AppointmentStatus, QuestionnaireType } from 'core/api'

export const QUESTIONNAIRES_LISTENER_INITIAL_VALUES = {
    types: [QuestionnaireType.Quiz],
    statuses: [AppointmentStatus.Expectation, AppointmentStatus.InProgress],
}

export const PAGINATION_SETTINGS = {
    pageSize: 9,
    pageNumber: 1,
}
