import {
    FeedbackQuizReportElapsedTimeNodeContract,
    FeedbackQuizReportUserElapsedTimeContract,
} from 'core/api'

import { NormalizedFeedbackQuizReportElapsedTimeNode } from './SpentTime.types'

export const normalizeData = (
    data?: (
        | FeedbackQuizReportElapsedTimeNodeContract
        | FeedbackQuizReportUserElapsedTimeContract
    )[]
): NormalizedFeedbackQuizReportElapsedTimeNode[] => {
    if (!data) return []

    return data.map((item) => {
        if ('userId' in item) {
            return {
                id: item.userId,
                name: item.userName,
                time: item.elapsedTime,
            }
        }

        return {
            id: item.organization?.id,
            name: item.organization?.name,
            children:
                item.children?.length || item.userResults?.length
                    ? [
                          ...normalizeData(item.children),
                          ...normalizeData(item.userResults),
                      ]
                    : null,
        }
    })
}
