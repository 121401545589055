import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { GetBtnOptionsType } from 'components/layouts/FormContainer/FormContainer.types'
import { LOCAL } from 'core/local'
import { QUIZ_TYPES } from 'consts'
import {
    QuizPortfolioContract,
    QuizPortfolioSearchResultContract,
} from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WidthRestrictedElement } from 'components/shared'
import { defaultSorter } from 'components/layouts'
import {
    formatDate,
    isDocumentStatusInWork,
    isDocumentStatusReadyForWork,
    renderAppointmentNames,
    renderDocumentStatus,
    renderTableColumnWithPopover,
    restrictBigText,
    sortByDefaultRule,
} from 'utils'

import { QuizPortfoliosTableActions } from './components'

/** настройка отображения колонок таблицы портфелей опросов */
export const QUIZ_PORTFOLIOS_TABLE_COLUMNS: ColumnsType<QuizPortfolioSearchResultContract> = [
    {
        title: LOCAL.LABELS.NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ORGANIZATION,
        dataIndex: 'organizations',
        key: 'organizations',
        render: renderTableColumnWithPopover,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.TYPE,
        dataIndex: 'type',
        key: 'type',
        render: (type, record) => (
            <WidthRestrictedElement title={QUIZ_TYPES[record.type]} />
        ),
    },
    ...renderAppointmentNames(),
    {
        title: LOCAL.LABELS.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: renderDocumentStatus,
        sorter: defaultSorter,
        fixed: 'right',
    },
    {
        title: LOCAL.LABELS.DATE_CHANGE,
        dataIndex: 'changeDate',
        key: 'changeDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <QuizPortfoliosTableActions tableRow={tableRow} />
        ),
        align: 'right',
        fixed: 'right',
    },
]

export const CANCEL_BTN_OPTIONS = {
    visible: true,
    href: ROUTE_NAMES.QUIZ_PORTFOLIOS,
}

export const getSaveBtnOptions: GetBtnOptionsType<QuizPortfolioContract> = ({
    editValues,
}) => ({
    visible:
        !isDocumentStatusReadyForWork(editValues?.status) &&
        !isDocumentStatusInWork(editValues?.status),
})
