import cn from 'classnames'
import React, { useCallback } from 'react'
import { Button } from 'components/shared'
import { DownloadLink } from 'components/shared/DownloadLink'
import { HomeworkStatusType } from 'core/api'
import { LOCAL } from 'core/local'
import { isHomeworkOnChecking } from 'utils'

import styles from './Footer.module.scss'
import { FooterProps } from './Footer.types'
import { getTooltipContainer } from './Footer.utils'

export const Footer: React.FC<FooterProps> = React.memo(
    ({
        attachment,
        currentStatus,
        loading,
        isOverdue,
        finishSubtaskReview,
    }) => {
        const sendToRevision = useCallback(
            () => finishSubtaskReview(HomeworkStatusType.Revision),
            [finishSubtaskReview]
        )

        const markTaskAsPassed = useCallback(
            () => finishSubtaskReview(HomeworkStatusType.Passed),
            [finishSubtaskReview]
        )

        return (
            <div
                className={cn(
                    styles.wrapper,
                    attachment && styles.spaceBetween
                )}
            >
                {attachment && (
                    <DownloadLink
                        link={attachment.url}
                        fileName={attachment.fileName}
                        getTooltipContainer={getTooltipContainer}
                        maxWidth={500}
                    />
                )}

                {isHomeworkOnChecking(currentStatus) && (
                    <div>
                        <Button
                            onClick={sendToRevision}
                            disabled={loading || isOverdue}
                            type="link"
                            className={styles.failBtn}
                        >
                            {LOCAL.ACTIONS.FAIL}
                        </Button>

                        <Button
                            onClick={markTaskAsPassed}
                            loading={loading}
                            disabled={isOverdue}
                        >
                            {LOCAL.ACTIONS.PASSED}
                        </Button>
                    </div>
                )}
            </div>
        )
    }
)
