import React from 'react'
import { AppointmentContract, AppointmentsService } from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { FormContainer } from 'components/layouts'

import {
    CANCEL_BTN_OPTIONS,
    PUBLISH_BTN_OPTIONS,
    getSaveBtnOptions,
} from './TestAssignments.config'
import { TEST_ASSIGNMENTS_FORM_ID } from './components/TestAssignmentForm/TestAssignmentForm.consts'
import { TestAssignmentForm } from './components'

/**
 * Создание назначения теста
 */
export const TestAssignmentFormContainer: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <FormContainer<AppointmentContract>
            formId={TEST_ASSIGNMENTS_FORM_ID}
            formMode={formMode}
            formComponent={TestAssignmentForm}
            requestMethod={AppointmentsService.getAppointment}
            cancelBtnOptions={CANCEL_BTN_OPTIONS}
            saveBtnOptions={getSaveBtnOptions}
            publishBtnOptions={PUBLISH_BTN_OPTIONS}
        />
    )
)
