import React from 'react'
import { ActivityCanvasContract, ActivityPassingStatus } from 'core/api'
import { IconsAdapter } from 'components/shared/IconsAdapter'

/** Получить содержимое(тело) тематического блока */
export const getBlockBody = (
    { thematicBlockIconColor }: ActivityCanvasContract,
    status: ActivityPassingStatus
) => {
    switch (status) {
        case ActivityPassingStatus.CompletedSuccessfully:
            return (
                <IconsAdapter
                    iconType="CheckOutlined"
                    style={{ color: thematicBlockIconColor }}
                />
            )
        case ActivityPassingStatus.Waiting:
        case ActivityPassingStatus.InProgress:
            return (
                <div
                    style={{
                        background: thematicBlockIconColor,
                    }}
                />
            )
        default:
            return (
                <IconsAdapter
                    iconType="LockOutlined"
                    style={{ color: thematicBlockIconColor }}
                />
            )
    }
}
