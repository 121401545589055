import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import {
    CompareScoreContract,
    CompetenceComparingContract,
    CompetenceGroupComparingContract,
    IndicatorComparingContract,
    PositionProfileCompetenceContract,
} from 'core/api'
import {
    GroupedCompetenciesGroupProps,
    GroupedCompetenciesGroupValueProps,
    getConditionalArray,
} from 'utils'

import {
    ComparisonData,
    ExtendedCompareScoreContract,
} from '../../UserProfile.types'
import { ResultCompetenceProps } from '../UserProfileCompetenciesGroup/UserProfileCompetenciesGroup.types'

/**
 * Добавляем индекс к результату
 * @param scores - результаты
 * @param reportsIds - массив айди выбранных отчетов
 */
const mapScores = (
    scores: CompareScoreContract[],
    reportsIds: number[]
): ExtendedCompareScoreContract[] =>
    sortBy(
        scores.map((score: CompareScoreContract) => ({
            ...score,
            index: reportsIds.findIndex((id) => id === score.appointmentId),
        })),
        (score) => score.index
    )

/**
 * Конвертируем индикатор для отображения
 * @param indicator - индикатор
 * @param reportsIds - массив айди выбранных отчетов
 * @param profilePositionLevel - уровень ПД
 */
const convertIndicatorToChild = (
    indicator: IndicatorComparingContract,
    reportsIds: number[],
    profilePositionLevel?: number
) => ({
    id: indicator.id,
    name: indicator.name,
    defaultValues: [
        ...getConditionalArray(!isNil(profilePositionLevel), {
            score: profilePositionLevel,
        } as CompareScoreContract),
        ...mapScores(indicator.scores, reportsIds),
    ],
})

/**
 * Конвертируем компетенцию для отображения
 * @param competence - компетенция
 * @param reportsIds - массив айди выбранных отчетов
 */
const convertCompetenceToChild = (
    competence: CompetenceComparingContract,
    reportsIds: number[]
) => ({
    id: competence.id,
    name: competence.name,
    defaultValues: mapScores(competence.scores, reportsIds),
    indicators: competence.indicators.map((el) =>
        convertIndicatorToChild(el, reportsIds)
    ),
})

/**
 * Формируем массив для отображения шкал компетенций и индикаторов
 * @param comparisonData - данные сравнения по компетенциям
 */
export const getGroupedCompetenciesForComparison = (
    comparisonData?: ComparisonData
): GroupedCompetenciesGroupValueProps<ResultCompetenceProps>[] | undefined =>
    comparisonData?.data.map((groupCompetencies) => ({
        groupId: groupCompetencies.id,
        groupName: groupCompetencies.name,
        children: groupCompetencies.competences.map((el) =>
            convertCompetenceToChild(el, comparisonData?.reportsIds)
        ),
        order: groupCompetencies.order,
    }))

/**
 * Получить компетенции в сгруппированном виде
 * @param competencies - массив компетенций
 */
export const getGroupedCompetencies = (
    competencies: PositionProfileCompetenceContract[]
): GroupedCompetenciesGroupProps<ResultCompetenceProps> =>
    competencies?.reduce(
        (
            group: GroupedCompetenciesGroupProps<ResultCompetenceProps>,
            competence
        ) => ({
            ...group,
            [competence.competenceGroup.id]: {
                groupId: competence.competenceGroup.id,
                groupName: competence.competenceGroup.name,
                order: competence.competenceGroup.order,
                children: [
                    ...(group[competence.competenceGroup.id]?.children || []),
                    {
                        ...competence,
                        defaultValues: [
                            {
                                score: competence.competenceLevel,
                                index: competence.id,
                            },
                        ],
                        indicators: [],
                    },
                ],
            },
        }),
        {}
    )

/**
 * Склеиваем ПД и отчет оценки
 * @param competencies - массив компетенций
 * @param comparisonData - данные сравнения по компетенциям
 * @param showAssessments - показывать ли оценки
 * @param showProfilePosition - показывать ли ПД
 */
export const getComparison = (
    competencies?: PositionProfileCompetenceContract[],
    comparisonData?: ComparisonData,
    showAssessments?: boolean,
    showProfilePosition?: boolean
): GroupedCompetenciesGroupValueProps<ResultCompetenceProps>[] | undefined => {
    if (
        competencies &&
        comparisonData &&
        showAssessments &&
        showProfilePosition
    ) {
        const { reportsIds, data: comparison } = comparisonData
        const result = getGroupedCompetencies(competencies)

        comparison.forEach((group) => {
            if (!result[group.id]) {
                result[group.id] = {
                    groupId: group.id,
                    groupName: group.name,
                    children: group.competences.map((el) =>
                        convertCompetenceToChild(el, reportsIds)
                    ),
                    order: group.order,
                }

                return
            }

            const resultGroup = result[group.id]

            group.competences.forEach((competence) => {
                const resultCompetence = resultGroup.children.find(
                    (el) => el.id === competence.id
                )

                if (resultCompetence) {
                    resultCompetence.defaultValues = [
                        ...(resultCompetence.defaultValues || []),
                        ...mapScores(competence.scores, reportsIds),
                    ]
                    resultCompetence.indicators = [
                        ...(resultCompetence.indicators || []),
                        ...competence.indicators.map((el) =>
                            convertIndicatorToChild(
                                el,
                                reportsIds,
                                resultCompetence.defaultValues?.[0].score
                            )
                        ),
                    ]

                    return
                }

                resultGroup.children.push(
                    convertCompetenceToChild(competence, reportsIds)
                )
            })
        })

        return Object.values(result)
    }

    if (competencies && showProfilePosition) {
        return Object.values(getGroupedCompetencies(competencies))
    }

    if (comparisonData && showAssessments) {
        return getGroupedCompetenciesForComparison(comparisonData)
    }
}

/**
 * Флаг для отображения уровней компетенции
 * @param comparisonData - массив компетенция для сравнения
 * @param competenciesData - массив компетенций профиля должности
 */
export const isDisplayGroupedCompetencies = (
    comparisonData?: CompetenceGroupComparingContract[],
    competenciesData?: PositionProfileCompetenceContract[]
) => !!(comparisonData?.length || competenciesData?.length)
