import React from 'react'
import { ASSESSMENT_ROLES_MAPPED } from 'consts'
import { AssessmentRole } from 'core/api'
import { Button } from 'components/shared/Button'
import { FnActionRequiredProps, Store, WithFormModeProps } from 'App.types'
import { IconsAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { Popover } from 'antd'
import { SelectControl, getCoworkersTableColumns } from 'components/controls'
import { excludeSelectOptions } from 'utils'

import styles from '../PortfolioAssessmentObjects/PortfolioAssessmentObjects.module.scss'
import {
    ASSESSMENT_ROLES_GENITIVE_PLURAL,
    ROLES_TO_EXCLUDE,
} from './PortfolioAssessmentObjectsRespondents.consts'
import { UserContractWithAssessmentRole } from './PortfolioAssessmentObjectsRespondents.types'

/**
 * Получить количество респондентов по ролям
 * @param respondents - слушатели
 */
export const mapRespondentsRoleCount = (
    respondents?: UserContractWithAssessmentRole[]
) => {
    const countByRoles = respondents?.reduce<Store<number>>((acc, item) => {
        if (!item.role) return acc

        return {
            ...acc,
            [item.role]: acc[item.role] ? acc[item.role] + 1 : 1,
        }
    }, {})

    if (countByRoles) {
        return Object.entries(countByRoles).map(([role, count], index, arr) => {
            const label =
                ASSESSMENT_ROLES_GENITIVE_PLURAL[
                    role as keyof typeof ASSESSMENT_ROLES_GENITIVE_PLURAL
                ]

            return `${label.toLowerCase()}: ${count}${
                index !== arr.length - 1 ? ', ' : ''
            }`
        })
    }
}

/**
 * Рендер колонок таблицы респондентов объекта оценки
 * @param formMode - мод формы
 * @param handleUpdateState - колбэк для того чтобы обновить внешний стейт таблицы
 */
export const renderAssessmentObjectRespondentsColumns = ({
    formMode,
}: WithFormModeProps) => (
    handleUpdateState: FnActionRequiredProps<UserContractWithAssessmentRole>
) => {
    const handleChangeRole = (row: UserContractWithAssessmentRole) => (
        value: AssessmentRole
    ) => {
        handleUpdateState({
            ...row,
            role: value,
        })
    }

    return [
        {
            title: LOCAL.LABELS.ROLE,
            name: 'role',
            key: 'role',
            width: '30%',
            render: (
                value: Pick<UserContractWithAssessmentRole, 'role'>,
                row: UserContractWithAssessmentRole
            ) => (
                <SelectControl
                    value={row.role as any}
                    onChange={handleChangeRole(row)}
                    values={excludeSelectOptions(
                        ASSESSMENT_ROLES_MAPPED,
                        ROLES_TO_EXCLUDE
                    )}
                    formMode={formMode}
                />
            ),
        },
        ...getCoworkersTableColumns<UserContractWithAssessmentRole>(),
    ]
}

/**
 * Рендер заголовка для добавления респондентов
 */
export const renderRespondentsControlTitle = (
    respondents?: UserContractWithAssessmentRole[]
) => (
    <div className={styles.respondentsHeader}>
        <h3>{LOCAL.LABELS.RESPONDENTS}</h3>

        <Popover
            trigger="click"
            title={LOCAL.LABELS.RESPONDENTS_QUANTITY}
            content={mapRespondentsRoleCount(respondents)}
            overlayClassName={styles.respondentsHeaderPopover}
        >
            <Button
                className={styles.respondentsInfoBtn}
                icon={<IconsAdapter iconType="InfoCircleOutlined" />}
            />
        </Popover>
    </div>
)

/**
 * Нормализация данных для компонента DynamicTableAdapter
 */
export const normalizeRespondentsData = (
    respondents: UserContractWithAssessmentRole[],
    oldData?: UserContractWithAssessmentRole[]
) =>
    respondents.map((el) => ({
        ...el,
        role: oldData?.find((oldEl) => oldEl.id === el.id)?.role,
    }))
