import React from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import styles from './AdminMenuGroup.module.scss'
import { AdminMenuGroupProps } from './AdminMenuGroup.types'

/** Компонент группы ссылок для меню администратора */
export const AdminMenuGroup: React.FC<AdminMenuGroupProps> = React.memo(
    ({ name, icon, title, children }) => (
        <div key={name} className={styles.wrapper}>
            <IconsAdapter iconType={icon} />

            <h4>{title}</h4>

            <ul>{children}</ul>
        </div>
    )
)
