import React from 'react'
import { AttachmentContract } from 'core/api'

import styles from './QuestionnairePictureAttachment.module.scss'

export const QuestionnairePictureAttachment = ({
    file,
}: {
    file?: AttachmentContract
}) => {
    if (!file) return null

    return (
        <div className={styles.wrapper}>
            <img src={file.url} alt={file.fileName} />
        </div>
    )
}
