import React, { useCallback, useContext } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, ContentTitle } from 'components/shared'
import { Col, Row } from 'antd'
import { LOCAL } from 'core/local'
import { TRACK_PASSING_EVENTS } from 'components/pages/TrackPassing/TrackPassing.events'
import { TrackPassingContext } from 'components/pages/TrackPassing/TrackPassing.context'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { isTrainingMaterialFormatMedia } from 'utils'

import styles from './CourseLayerHeader.module.scss'

export const CourseLayerHeader: React.FC = React.memo(() => {
    const { store } = useContext(TrackPassingContext)

    const { trackInfo, stageInfo } = store

    // TODO: не понадобится после расширения контракта на бэке
    const stageTitle = trackInfo?.stages.find(
        (el) => el.id === stageInfo?.content?.trackStagePassingId
    )?.title

    const trackStagePassingId = stageInfo?.content?.trackStagePassingId

    const closeStage = useCallback(() => {
        eventEmitter.emit(TRACK_PASSING_EVENTS.CLOSE_STAGE)
    }, [])

    const finishStage = useCallback(() => {
        eventEmitter.emit(
            TRACK_PASSING_EVENTS.FINISH_STAGE_CONTENT,
            trackStagePassingId
        )
    }, [trackStagePassingId])

    return (
        <div className={styles.wrapper}>
            {!isTrainingMaterialFormatMedia(
                stageInfo?.content?.course.format
            ) && <ContentTitle title={stageTitle} />}

            <Row
                justify="space-between"
                align="middle"
                className={styles.actions}
            >
                <Col className={styles.name}>
                    {stageInfo?.content?.course.name}
                </Col>

                <Col>
                    <ButtonsToolbar className={styles.buttonsToolbar}>
                        {trackInfo?.isTrackWithVisualisation && (
                            <Button type="primary" onClick={closeStage}>
                                {LOCAL.ACTIONS.CLOSE}
                            </Button>
                        )}

                        <Button type="primary" onClick={finishStage}>
                            {LOCAL.LABELS.MATERIAL_VIEWED}
                        </Button>
                    </ButtonsToolbar>
                </Col>
            </Row>
        </div>
    )
})
