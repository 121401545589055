import React, { useCallback, useState } from 'react'
import { AppError, TrackCanvasIconsService } from 'core/api'
import { Col, Form, Row, Upload } from 'antd'
import { FormItemAdapter, HiddenField, InputControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { MEDIA_DOCUMENT_TYPE } from 'consts'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { RcCustomRequestOptions } from 'types'
import { TrackCanvasIconsService as TrackCanvasIconsServiceManual } from 'core/api/TrackCanvasIconsService.api'
import { UploadFile } from 'antd/lib/upload/interface'
import { normalizeFile, validateUploadImage } from 'utils'
import { withLoader } from 'HOCs'

import styles from './UploadAlbumForm.module.scss'
import { UploadAlbumFormValuesProps } from './UploadAlbumForm.types'
import { mapFormDataToRequest } from './UploadAlbumForm.utils'

const { Dragger } = Upload

/** Форма загрузки альбома фоновых изображений холста этапа трека */
export const UploadAlbumForm: React.FC<PopupAdapterFormProps> = withLoader(
    React.memo(({ onRequestFinish, id, initialValues, updateLoader }) => {
        const [fileList, setFileList] = useState<UploadFile[]>([])
        /** Обработчик отправки формы */
        const handleFinish = useCallback(
            async (values: UploadAlbumFormValuesProps) => {
                try {
                    updateLoader(true)

                    await TrackCanvasIconsService.createIconBackgroundAlbum({
                        body: mapFormDataToRequest(values),
                    })

                    onRequestFinish?.()
                } catch (error) {
                    onRequestFinish?.(error as AppError)
                }
            },
            [onRequestFinish, updateLoader]
        )

        const onChange = useCallback((info: any) => {
            setFileList(info.fileList)
        }, [])

        /** Обработчик загрузки файла */
        const handleUploadAttachment = useCallback(
            async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
                try {
                    const upload = await TrackCanvasIconsServiceManual.uploadBackground(
                        {
                            trackStageStatus: initialValues?.stageStatus,
                            file,
                        }
                    )

                    onSuccess?.(upload, {} as XMLHttpRequest)
                } catch (error) {
                    onError?.(error as AppError)
                }
            },
            [initialValues]
        )

        return (
            <Form onFinish={handleFinish} id={id} initialValues={initialValues}>
                <Row>
                    <Col xs={24}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={24}>
                        <Form.Item
                            name="files"
                            valuePropName="fileList"
                            getValueFromEvent={normalizeFile}
                        >
                            <Dragger
                                beforeUpload={validateUploadImage}
                                className={styles.upload}
                                accept={MEDIA_DOCUMENT_TYPE}
                                multiple
                                customRequest={handleUploadAttachment}
                                onChange={onChange}
                                fileList={fileList}
                            >
                                <p className={styles.uploadText}>
                                    {
                                        LOCAL.MESSAGES
                                            .DRAG_OR_CLICK_TO_UPLOAD_FILE
                                    }
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>

                    <HiddenField fieldName="stageStatus" />
                </Row>
            </Form>
        )
    })
)
