import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UserPermission } from 'core/api'

import { AdminGroupMenuProps } from './AdminMenu.types'
import { getMenuLink } from './AdminMenu.utils'

export const ADMIN_MENU_ITEMS: AdminGroupMenuProps[] = [
    {
        name: ROUTE_NAMES.PROFILING,
        title: LOCAL.LABELS.PROFILING,
        icon: 'CustomIconProfiling',
        children: [
            {
                name: ROUTE_NAMES.POSITION_PROFILES,
                permissions: [UserPermission.PositionProfilesRead],
            },
            {
                name: ROUTE_NAMES.COMPETENCIES,
                permissions: [UserPermission.CompetenciesRead],
            },
        ].map(getMenuLink),
    },
    {
        name: ROUTE_NAMES.TEST,
        title: LOCAL.LABELS.TESTS,
        icon: 'CustomIconTests',
        children: [
            {
                name: ROUTE_NAMES.TEST_PORTFOLIOS,
                permissions: [UserPermission.TestPortfoliosRead],
            },
            {
                name: ROUTE_NAMES.TEST_ASSIGNMENTS,
                permissions: [UserPermission.TestAppointmentsRead],
            },
        ].map(getMenuLink),
    },
    {
        name: ROUTE_NAMES.EVENTS,
        title: LOCAL.LABELS.EVENTS_M,
        icon: 'CustomIconEvents',
        children: [
            {
                name: ROUTE_NAMES.EVENTS,
                permissions: [UserPermission.EventsRead],
            },
            {
                name: ROUTE_NAMES.EVENTS_ASSIGNMENTS,
                permissions: [UserPermission.AppointmentActivitiesRead],
            },
        ].map(getMenuLink),
    },
    {
        name: ROUTE_NAMES.ASSESSMENT,
        title: LOCAL.LABELS.ASSESSMENTS,
        icon: 'CustomIconAssessment',
        children: [
            {
                name: ROUTE_NAMES.ASSESSMENT_PORTFOLIOS,
                permissions: [UserPermission.AssessmentPortfoliosRead],
            },
            {
                name: ROUTE_NAMES.ASSESSMENT_ASSIGNMENTS,
                permissions: [UserPermission.AssessmentAppointmentsRead],
            },
            {
                name: ROUTE_NAMES.ASSESSMENT_ASSIGNED,
                permissions: [UserPermission.Developer],
            },
            {
                name: ROUTE_NAMES.ASSESSMENT_PROJECTS,
                permissions: [UserPermission.AssessmentProjectsRead],
            },
            {
                name: ROUTE_NAMES.ASSESSMENT_REPORTS,
                permissions: [UserPermission.AssessmentReportsRead],
            },
        ].map(getMenuLink),
    },
    {
        name: ROUTE_NAMES.QUIZ,
        title: LOCAL.LABELS.QUIZZES,
        icon: 'CustomIconQuiz',
        children: [
            {
                name: ROUTE_NAMES.QUIZ_PORTFOLIOS,
                permissions: [UserPermission.QuizPortfoliosRead],
            },
            {
                name: ROUTE_NAMES.QUIZ_ASSIGNMENTS,
                permissions: [UserPermission.QuizAppointmentsRead],
            },
        ].map(getMenuLink),
    },
    {
        name: ROUTE_NAMES.TRACKS,
        title: LOCAL.LABELS.TRACKS,
        icon: 'CustomIconTracks',
        children: [
            {
                name: ROUTE_NAMES.TRACKS_ADAPTATION,
                permissions: [UserPermission.AdaptationTracksRead],
            },
            {
                name: ROUTE_NAMES.TRACKS_DEVELOPMENT,
                permissions: [UserPermission.DevelopmentTracksRead],
            },
            {
                name: ROUTE_NAMES.TRACKS_ASSIGNMENTS,
                permissions: [UserPermission.TrackAppointmentsRead],
            },
            {
                name: ROUTE_NAMES.DEVELOPMENT_GOALS,
                permissions: [UserPermission.PurposesRead],
            },
            {
                name: ROUTE_NAMES.TRACKS_UNIQUE,
                permissions: [UserPermission.MguuTracksRead],
            },
        ].map(getMenuLink),
    },
    {
        name: ROUTE_NAMES.ADMINISTRATION,
        title: LOCAL.LABELS.ADMINISTRATION,
        icon: 'CustomIconAdministration',
        children: [
            {
                name: ROUTE_NAMES.ORGANIZATIONS,
                permissions: [UserPermission.StructuralUnitsRead],
            },
            {
                name: ROUTE_NAMES.STAFF_UNITS,
                permissions: [UserPermission.StaffUnitsRead],
            },
            {
                name: ROUTE_NAMES.COWORKERS,
                permissions: [UserPermission.UsersRead],
            },
            {
                name: ROUTE_NAMES.SUPERVISING_COMPLEXES,
                permissions: [UserPermission.ComplexesRead],
            },
        ].map(getMenuLink),
    },
    {
        name: ROUTE_NAMES.MATERIALS,
        title: LOCAL.LABELS.MATERIALS,
        icon: 'CustomIconMaterials',
        children: [
            {
                name: ROUTE_NAMES.QUESTIONNAIRE_TEMPLATES,
                permissions: [UserPermission.QuestionnairesRead],
            },
            {
                name: ROUTE_NAMES.CRITERIA_SCALES,
                permissions: [UserPermission.ScalesRead],
            },
            {
                name: ROUTE_NAMES.TRAINING_MATERIALS,
                permissions: [UserPermission.CoursesRead],
            },
            {
                name: ROUTE_NAMES.TASKS,
                permissions: [UserPermission.TextDocumentsRead],
            },
        ].map(getMenuLink),
    },
    {
        name: ROUTE_NAMES.PRODUCT_CATALOG,
        title: LOCAL.LABELS.CATALOG,
        icon: 'CustomIconAlbum',
        children: [
            {
                name: ROUTE_NAMES.PRODUCT_CATALOG,
                permissions: [UserPermission.ProductCardsRead],
            },
            {
                name: ROUTE_NAMES.THEMES,
                permissions: [UserPermission.ThemesRead],
            },
        ].map(getMenuLink),
    },
    {
        name: ROUTE_NAMES.DISTRIBUTION_TAGS,
        title: LOCAL.LABELS.DISTRIBUTIONS,
        icon: 'CustomIconDistribution',
        children: [
            {
                name: ROUTE_NAMES.DISTRIBUTION_TEMPLATES,
                permissions: [
                    UserPermission.NotificationNewsletterTemplatesRead,
                ],
            },
            {
                name: ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT,
                permissions: [UserPermission.NotificationMailingTasksRead],
            },
            {
                name: ROUTE_NAMES.DISTRIBUTION_MAILING,
                permissions: [UserPermission.Developer],
            },
            {
                name: ROUTE_NAMES.DISTRIBUTION_TAGS,
                permissions: [UserPermission.NotificationTagsRead],
            },
        ].map(getMenuLink),
    },
]
