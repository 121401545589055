import React from 'react'
import cn from 'classnames'

import styles from './VisualizationOptionsGroup.module.scss'
import { VisualizationOptionsGroupProps } from './VisualizationOptionsGroup.types'

/**
 * Группировка опций визуализации трека
 */
export const VisualizationOptionsGroup: React.FC<VisualizationOptionsGroupProps> = React.memo(
    ({ title, children, className, required }) => (
        <div
            className={cn(
                styles.wrapper,
                !title && styles.wrapperNoTopPadding,
                className
            )}
        >
            {title && (
                <h4 className={cn({ 'required-control': required })}>
                    {title}
                </h4>
            )}

            <div className={styles.body}>{children}</div>
        </div>
    )
)
