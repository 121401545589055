import React, { useCallback, useEffect } from 'react'
import { Col, Form, Row } from 'antd'
import {
    ControlGroup,
    FormItemAdapter,
    InputControl,
    SearchSelectControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { DictionariesCommonProps } from 'App.types'
import {
    FilterForm,
    FromToDatesFilter,
    TooltipAdapter,
} from 'components/shared'
import { FiltersProps, getValue, normalizeDataForSelectAndRadio } from 'utils'
import { LOCAL } from 'core/local'
import {
    MAILING_STATUSES_MAPPED,
    MAILING_TYPES_MAPPED,
    ROW_GUTTER,
} from 'consts'
import {
    NotificationDestinationCategoriesService,
    NotificationDestinationCategoryContract,
    NotificationMailingTaskAppointmentObjectSearchContract,
    NotificationMailingTaskAppointmentObjectSearchResultContract,
    NotificationMailingTaskSearchContract,
    NotificationMailingTasksService,
    NotificationTagsService,
} from 'core/api'
import { renderFooterWithShowArchiveField } from 'components/shared/FilterForm/FilterForm.render'
import { useDictionaries, useHttp } from 'hooks'

import { AppointmentObjectsSelectionFilter } from '../AppointmentObjectsSelectionFilter'
import { MailingAssignmentFiltersSubmitValues } from './MailingAssignmentFilters.types'
import { getTableObjectsColumnsByCategoryCode } from '../DistributionMailingParams/DistributionMailingParams.utils'

/** Форма фильтрации для заданий на рассылку */
export const MailingAssignmentFilters: React.FC<FiltersProps<
    NotificationMailingTaskSearchContract
>> = React.memo((props) => {
    const [form] = Form.useForm()
    const [fetchCategories, categories] = useHttp<
        NotificationDestinationCategoryContract[]
    >(NotificationDestinationCategoriesService.getForSelect)
    const { dictionaries, handleFetchDictionaries } = useDictionaries<
        DictionariesCommonProps<'markers'>
    >()

    const mapFormDataToRequest = useCallback(
        (values: Partial<MailingAssignmentFiltersSubmitValues>) => ({
            ...values,
            objectIds: values.appointmentObjects?.map(getValue),
        }),
        []
    )

    const resetAppointmentObjects = useCallback(() => {
        form?.setFieldsValue({
            appointmentObjects: [],
        })
    }, [form])

    useEffect(() => {
        handleFetchDictionaries({
            markers: NotificationTagsService.getForSelect(),
        })
        fetchCategories()
    }, [handleFetchDictionaries, fetchCategories])

    return (
        <ControlGroup type="light">
            <FilterForm
                {...props}
                mapFormDataToRequest={mapFormDataToRequest}
                renderFooter={renderFooterWithShowArchiveField}
                form={form}
            >
                <Row align="top" gutter={ROW_GUTTER}>
                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="name"
                            label={LOCAL.LABELS.NAME_OF_MAILING_TASK}
                        >
                            <InputControl />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="tagIds"
                            label={LOCAL.LABELS.TAGS}
                        >
                            <SelectControl
                                mode="multiple"
                                showSearch
                                allowClear
                                values={dictionaries.markers}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="types"
                            label={LOCAL.LABELS.MAILING_TYPE}
                        >
                            <SelectControl
                                mode="multiple"
                                values={MAILING_TYPES_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="categoryId"
                            label={LOCAL.LABELS.APPOINTMENT_OBJECT_KIND}
                        >
                            <SelectControl
                                values={categories?.map(
                                    normalizeDataForSelectAndRadio
                                )}
                                onChange={resetAppointmentObjects}
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <ShouldUpdateChecker fieldPath={['categoryId']}>
                            {({ getFieldValue }) => {
                                const category = categories?.find(
                                    (el) =>
                                        el.id === getFieldValue('categoryId')
                                )

                                return (
                                    <TooltipAdapter
                                        title={
                                            !category &&
                                            LOCAL.TOOLTIPS
                                                .SELECT_CATEGORY_DESTINATION_OBJECT
                                        }
                                    >
                                        <FormItemAdapter
                                            fieldName="appointmentObjects"
                                            label={
                                                LOCAL.LABELS.DESTINATION_OBJECT
                                            }
                                        >
                                            <SearchSelectControl<
                                                NotificationMailingTaskAppointmentObjectSearchContract,
                                                NotificationMailingTaskAppointmentObjectSearchResultContract
                                            >
                                                getByIdsMethod={async (ids) =>
                                                    await NotificationMailingTasksService.getAppointmentObjectsByCategoryAndIds(
                                                        {
                                                            ...ids,
                                                            categoryId: Number(
                                                                category?.id
                                                            ),
                                                        }
                                                    )
                                                }
                                                tableSearchOptions={{
                                                    searchMethod:
                                                        NotificationMailingTasksService.searchAppointmentObjects,
                                                    tableColumns: getTableObjectsColumnsByCategoryCode(
                                                        category?.code
                                                    ),
                                                    filterComponent: AppointmentObjectsSelectionFilter,
                                                    tableFiltersOptions: {
                                                        initialValues: {
                                                            categoryId: Number(
                                                                category?.id
                                                            ),
                                                        },
                                                    },
                                                }}
                                                selectMode="multiple"
                                                disabled={!category}
                                            />
                                        </FormItemAdapter>
                                    </TooltipAdapter>
                                )
                            }}
                        </ShouldUpdateChecker>
                    </Col>

                    <Col xs={8}>
                        <FormItemAdapter
                            fieldName="statuses"
                            label={LOCAL.LABELS.STATUS}
                        >
                            <SelectControl
                                mode="multiple"
                                values={MAILING_STATUSES_MAPPED}
                                showSearch
                            />
                        </FormItemAdapter>
                    </Col>

                    <Col xs={8}>
                        <FromToDatesFilter
                            from={{
                                label: LOCAL.LABELS.DATE_CHANGE_FROM,
                                fieldName: 'changeDateStart',
                            }}
                            to={{
                                label: LOCAL.LABELS.DATE_CHANGE_TO,
                                fieldName: 'changeDateEnd',
                            }}
                        />
                    </Col>
                </Row>
            </FilterForm>
        </ControlGroup>
    )
})
