import {
    ImgsFetchFnResponseProps,
    ImgsUploadFnResponseProps,
} from 'components/shared/CanvasImagesWithInfiniteScroll/CanvasImagesWithInfiniteScroll.types'
import { RcCustomRequestOptions } from 'types'
import { TrackCanvasIconsService as TrackCanvasIconsCustomService } from 'core/api/TrackCanvasIconsService.api'
import { TrackCanvasIconsService, TrackStageStatus } from 'core/api'

import styles from './SingleStageVisualisationOptions.module.scss'
import { IconStatusProps } from '../../../TrackForm.types'

/**
 * Запрос иконок этапа трека
 */
export const canvasIconsFetchFn = (stageStatus: IconStatusProps) => (
    pageSize: number,
    pageNumber: number
): Promise<ImgsFetchFnResponseProps> =>
    TrackCanvasIconsService.search({
        pageSize,
        pageNumber,
        body: {
            trackStageStatus: stageStatus as TrackStageStatus,
        },
    })

/**
 * Загрузка иконок этапа трека
 */
export const canvasIconsUploadFn = (stageStatus: IconStatusProps) => (
    request: RcCustomRequestOptions
): Promise<ImgsUploadFnResponseProps> =>
    TrackCanvasIconsCustomService.upload({
        file: request.file,
        trackStageStatus: stageStatus as TrackStageStatus,
    })

/** Получить контейнер для модального окна подтверждения удаления иконки этапа */
export const getPopupContainerForIconRemove = (): HTMLElement =>
    document.querySelector(`.${styles.body}`) || document.body
