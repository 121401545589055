import React from 'react'
import { LOCAL } from 'core/local'
import {
    getFullFio,
    getShortFio,
    isQuestionnaireTypeAssessment,
    renderElementWithListPopover,
} from 'utils'

import { AppointmentCardResponsiblePeopleProps } from './AppointmentCard.types'

export const AppointmentCardResponsiblePeople: React.FC<AppointmentCardResponsiblePeopleProps> = React.memo(
    ({ owner, expired, type, reviewers }) =>
        isQuestionnaireTypeAssessment(type) ? (
            <>
                {`${
                    expired
                        ? LOCAL.LABELS.CONTACT_THE_RESPONSIBLE_PEOPLE
                        : LOCAL.LABELS.RESPONSIBLE_PEOPLE
                }: `}

                <span>{getShortFio(owner)}</span>
            </>
        ) : (
            <>
                {`${LOCAL.LABELS.REVIEWERS}: `}

                {renderElementWithListPopover(
                    reviewers.map((reviewer) => ({
                        name: getFullFio(reviewer),
                        id: reviewer.id,
                    })),
                    { showDots: true, inline: true }
                )}
            </>
        )
)
