import { OrderRuleContract, SortDirection } from 'core/api'
import { sortByDefaultRule } from 'utils'

/**
 * Дефолтные настройки сортировки
 */
export const defaultSorter = {
    compare: sortByDefaultRule,
    multiple: 1,
}

export const ORDER_RULES_WITH_CHANGE_DATE: OrderRuleContract[] = [
    { field: 'changeDate', direction: SortDirection.Desc },
    { field: 'name', direction: SortDirection.Asc },
]

export const ORDER_RULES_WITH_CREATION_DATE: OrderRuleContract[] = [
    { field: 'creationDate', direction: SortDirection.Desc },
    { field: 'name', direction: SortDirection.Asc },
]

export const TOOLBAR_CONTENT_SETTINGS = {
    GUTTER: {
        SM: 15,
        XL: 45,
    },
    PROPS: {
        justify: 'space-between' as 'space-between',
        align: 'middle' as 'middle',
        gutter: 15,
    },
}
