import React, { useCallback } from 'react'
import {
    ButtonsToolbar,
    IconsAdapter,
    PopupWithForm,
    TableActions,
} from 'components/shared'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS } from 'consts'
import {
    MailingAssignmentProcessTypes,
    MailingAssignmentServiceService,
    createConfirmPopup,
    isMailingStatusCanceled,
    isMailingStatusDraft,
    isMailingStatusEnded,
    isMailingStatusExpectation,
    isMailingStatusInProgress,
    isMailingStatusSuspended,
} from 'utils'
import { ROUTES_POSTFIXES, ROUTE_NAMES } from 'routing/routeNames.consts'
import { UserPermission } from 'core/api'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useHistory } from 'react-router-dom'

import styles from './MailingAssignmentTableActions.module.scss'
import { MailingAssignmentTableActionsProps } from './MailingAssignmentTableActions.types'

export const MailingAssignmentTableActions: React.FC<MailingAssignmentTableActionsProps> = React.memo(
    ({ tableRow, reFetchDictionary, updateLoader }) => {
        const history = useHistory()

        const isEditable =
            !isMailingStatusCanceled(tableRow?.businessStatus) &&
            !isMailingStatusEnded(tableRow?.businessStatus)

        const isStopped =
            isMailingStatusInProgress(tableRow?.businessStatus) ||
            isMailingStatusExpectation(tableRow?.businessStatus) ||
            isMailingStatusSuspended(tableRow?.businessStatus)

        const isPossibilityOfTransition =
            !isMailingStatusDraft(tableRow?.businessStatus) &&
            !isMailingStatusExpectation(tableRow?.businessStatus)

        /**
         * Обработчик выбора экшена процесса прохождения назначения треков
         * @param action выбранный экшен
         */
        const handleChooseAction = useCallback(
            (action: MailingAssignmentProcessTypes) => async () => {
                try {
                    updateLoader?.(true)

                    MailingAssignmentServiceService(tableRow.id, action).then(
                        reFetchDictionary
                    )
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader?.(false)
                }
            },
            [reFetchDictionary, tableRow.id, updateLoader]
        )

        const handleClickStop = useCallback(() => {
            createConfirmPopup({
                title: LOCAL.MESSAGES.CONFIRM_TITLE_DEFAULT,
                content: LOCAL.MESSAGES.CANCEL_MAILING_ASSIGNMENT,
                okText: LOCAL.ACTIONS.CONFIRM,
                onOk: handleChooseAction(MailingAssignmentProcessTypes.Cancel),
            })
        }, [handleChooseAction])

        const handleClickGoToListByRow = useCallback(
            () =>
                history.push(
                    `${ROUTE_NAMES.DISTRIBUTION_MAILING}/${ROUTES_POSTFIXES.FILTER}/${tableRow.id}`
                ),
            [tableRow, history]
        )

        return (
            <ButtonsToolbar bottomIndent="0" className={styles.wrapper}>
                <TableActions.View
                    requiredPermissions={
                        MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS
                    }
                    href={`${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_VIEW}/${tableRow.id}`}
                    disabled={!tableRow.active}
                />

                {tableRow.active && (
                    <>
                        {isEditable && (
                            <TableActions.Edit
                                requiredPermissions={[
                                    ...MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.NotificationMailingTaskSave,
                                ]}
                                href={`${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_EDIT}/${tableRow.id}`}
                                disabled={isMailingStatusInProgress(
                                    tableRow?.businessStatus
                                )}
                                title={
                                    isMailingStatusInProgress(
                                        tableRow?.businessStatus
                                    )
                                        ? LOCAL.TOOLTIPS.PAUSE_JOB_TO_EDIT
                                        : LOCAL.ACTIONS.EDIT
                                }
                            />
                        )}

                        <TableActions.Copy
                            requiredPermissions={[
                                ...MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                UserPermission.NotificationMailingTaskSave,
                            ]}
                            href={`${ROUTE_NAMES.DISTRIBUTION_MAILING_ASSIGNMENT_COPY}/${tableRow.id}`}
                        />

                        {isStopped && (
                            <TableActions.Stop
                                requiredPermissions={
                                    MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS
                                }
                                onClick={handleClickStop}
                            />
                        )}

                        {isMailingStatusInProgress(
                            tableRow?.businessStatus
                        ) && (
                            <TableActions.Pause
                                requiredPermissions={[
                                    ...MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.AssessmentAppointmentPause,
                                ]}
                                onClick={handleChooseAction(
                                    MailingAssignmentProcessTypes.Suspend
                                )}
                            />
                        )}

                        {isMailingStatusSuspended(tableRow?.businessStatus) && (
                            <TableActions.Play
                                requiredPermissions={[
                                    ...MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.NotificationMailingTaskContinue,
                                ]}
                                onClick={handleChooseAction(
                                    MailingAssignmentProcessTypes.Resume
                                )}
                            />
                        )}

                        {isPossibilityOfTransition && (
                            <TableActions.GoToListByRow
                                requiredPermissions={
                                    MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS
                                }
                                onClick={handleClickGoToListByRow}
                            />
                        )}

                        {isMailingStatusDraft(tableRow?.businessStatus) && (
                            <HasPermissions
                                requiredPermissions={[
                                    ...MAILING_ASSIGNMENT_REQUIRED_PERMISSIONS,
                                    UserPermission.NotificationMailingTaskDelete,
                                ]}
                                accessDeniedRender={renderAccessDeniedButton({
                                    type: 'link',
                                    icon: (
                                        <IconsAdapter iconType="DeleteOutlined" />
                                    ),
                                })}
                            >
                                <PopupWithForm
                                    formId={FORM_IDS.CONFIRM_DELETE_FORM}
                                    buttonText=""
                                    buttonOption={{
                                        type: 'link',
                                        icon: (
                                            <IconsAdapter iconType="DeleteOutlined" />
                                        ),
                                        title: LOCAL.ACTIONS.DELETE,
                                    }}
                                    formOptions={{
                                        initialValues: tableRow,
                                    }}
                                    modalOptions={{
                                        title:
                                            LOCAL.MESSAGES
                                                .CONFIRM_TITLE_DEFAULT,
                                        okText: LOCAL.YES,
                                    }}
                                />
                            </HasPermissions>
                        )}
                    </>
                )}
            </ButtonsToolbar>
        )
    }
)
