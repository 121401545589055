import React from 'react'
import {
    AppointmentActivityContract,
    AppointmentActivityService,
} from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { FormContainer } from 'components/layouts'

import {
    CANCEL_BTN_OPTIONS,
    PUBLISH_BTN_OPTIONS,
    getSaveBtnOptions,
} from './EventAssignments.config'
import { EventsAssignmentsForm } from './components'

/** Страница создания/редактирования назначения мероприятий */
export const EventsAssignmentsFormContainer: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <FormContainer<AppointmentActivityContract>
            formId={FORM_IDS.EVENTS_ASSIGNMENTS_FORM}
            formMode={formMode}
            formComponent={EventsAssignmentsForm}
            requestMethod={AppointmentActivityService.getActive}
            cancelBtnOptions={CANCEL_BTN_OPTIONS}
            saveBtnOptions={getSaveBtnOptions}
            publishBtnOptions={PUBLISH_BTN_OPTIONS}
        />
    )
)
