import React from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { useCheckPermissions } from 'hooks'

import { AddonAfterDefaultProps } from './AddonAfterDefault.types'

export const AddonAfterDefault: React.FC<AddonAfterDefaultProps> = React.memo(
    ({ url, permissionsRequired, disabled }) => {
        const { hasPermissionsRequired } = useCheckPermissions()

        if (disabled || !hasPermissionsRequired(permissionsRequired))
            return (
                <IconsAdapter
                    iconType="EyeInvisibleOutlined"
                    data-testid="EyeInvisibleOutlined"
                />
            )

        return (
            <a href={url} target="_blank" rel="noopener noreferrer">
                <IconsAdapter
                    title={LOCAL.ACTIONS.BROWSE}
                    iconType="EyeOutlined"
                    data-testid="EyeOutlined"
                />
            </a>
        )
    }
)
