import React, { useCallback } from 'react'
import {
    ActiveBlockStateProps,
    BlocksProps,
} from 'components/pages/TrackPreviewPassing/components/ActivityLayer/ActivityLayer.types'

import styles from './ModalBlocks.module.scss'
import { ModalSingleBlock } from '../ModalSingleBlock'
import { getBlocksStyle } from '../../ActivityLayer.utils'

/**
 * Отображение тематических блоков для мероприятия типа - "modal"
 */
export const ModalBlocks: React.FC<BlocksProps> = React.memo(
    ({ canvas, blocksList, activeBlock, onSetActiveBlock }) => {
        const handleSetActiveBlock = useCallback(
            (state: ActiveBlockStateProps) => () => {
                onSetActiveBlock(state)
            },
            [onSetActiveBlock]
        )

        return (
            <div className={styles.wrapper}>
                {blocksList.map((block, index) => (
                    <React.Fragment key={block.lessonId}>
                        <ModalSingleBlock
                            canvas={canvas}
                            blockIndex={index}
                            blocksList={blocksList}
                            activeBlock={activeBlock}
                            onSetActiveBlock={handleSetActiveBlock}
                            lessonType={block.type}
                        />

                        {canvas.showLines && (
                            <div
                                className={styles.line}
                                style={getBlocksStyle(canvas, block.status)}
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
        )
    }
)
