import cn from 'classnames'
import React, { useCallback } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar, IconsAdapter } from 'components/shared'
import { Col, Form, Row } from 'antd'
import {
    FormItemAdapter,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { isFormModeView, normalizeDataForSelectAndRadio } from 'utils'

import styles from './ConditionForm.module.scss'
import { ConditionFormProps } from './ConditionForm.types'
import { ConditionParamsControl } from '../../ConditionParamsControl'
import { filterConditionsValues } from './ConditionForm.utils'
import { getRenderParametersData } from '../../ConditionParamsControl/ConditionParamsControl.utils'

/** Компонент выбора и настройки параметров условия отправки на форме Задание на рассылку */
export const ConditionForm: React.FC<ConditionFormProps> = React.memo(
    (props) => {
        const { form, formMode, field, conditions, onRemove, className } = props

        const handleSelectCondition = useCallback(
            (value: number) => {
                form?.setFields([
                    {
                        name: ['conditions', field.name, 'parameters'],
                        value: getRenderParametersData(
                            conditions?.find((el) => el.id === value)
                                ?.parameters
                        ),
                    },
                ])
            },
            [conditions, field.name, form]
        )

        return (
            <Row className={cn(styles.wrapper, className)}>
                <Col xs={22}>
                    <ShouldUpdateChecker fieldPath={['conditions', field.name]}>
                        {({ getFieldValue }) => {
                            const selected = getFieldValue('conditions')
                            const currentId = getFieldValue([
                                'conditions',
                                field.name,
                                'id',
                            ])

                            return (
                                <>
                                    <FormItemAdapter
                                        fieldName={[field.name, 'id']}
                                    >
                                        <SelectControl
                                            formMode={formMode}
                                            values={filterConditionsValues({
                                                conditions:
                                                    conditions?.map(
                                                        normalizeDataForSelectAndRadio
                                                    ) || [],
                                                selected,
                                                currentId,
                                            })}
                                            onChange={handleSelectCondition}
                                        />
                                    </FormItemAdapter>

                                    <Form.Item
                                        noStyle
                                        name={[field.name, 'parameters']}
                                    >
                                        <ConditionParamsControl
                                            formMode={formMode}
                                            fieldName={field.name}
                                        />
                                    </Form.Item>
                                </>
                            )
                        }}
                    </ShouldUpdateChecker>
                </Col>

                <Col xs={2} className={styles.icon}>
                    {!isFormModeView(formMode) && (
                        <ButtonsToolbar flexGrow>
                            <Button
                                type="link"
                                icon={
                                    <IconsAdapter iconType="DeleteOutlined" />
                                }
                                onClick={onRemove}
                            />
                        </ButtonsToolbar>
                    )}
                </Col>
            </Row>
        )
    }
)
