import React, { useCallback, useEffect, useState } from 'react'
import { Collapse } from 'antd'
import { FnActionAsyncRequiredProps } from 'App.types'
import { LOCAL } from 'core/local'
import { PageBanner, PageContent } from 'components/shared'
import {
    SortDirection,
    YourDevelopmentPageItemContractPageContract,
    YourDevelopmentSearchContract,
    YourDevelopmentService,
} from 'core/api'
import { withLoader } from 'HOCs'

import styles from './YourDevelopment.module.scss'
import { AppointmentsCards } from './components/AppointmentsCards'
import { AppointmentsCardsTitle } from './components/AppointmentsCardsTitle'
import {
    AppointmentsStateType,
    FetchAppointmentsProps,
    PaginationType,
    PanelType,
} from './YourDevelopment.types'
import { PAGINATION_SETTINGS } from './YourDevelopment.consts'
import { getRequestFilters } from './YourDevelopment.utils'

const { Panel } = Collapse

/** Страница "Ваше развитие" */
export const YourDevelopmentV2: React.FC = withLoader(
    React.memo(({ updateLoader, isLoading }) => {
        const [appointments, setAppointments] = useState<
            AppointmentsStateType
        >()

        const [paginationInitiator, setPaginationInitiator] = useState<
            PanelType
        >()

        const [pagination, setPagination] = useState<PaginationType>({
            [PanelType.Tracks]: PAGINATION_SETTINGS,
            [PanelType.Activities]: PAGINATION_SETTINGS,
            [PanelType.PassedTracksAndActivities]: PAGINATION_SETTINGS,
        })

        const showMoreEntities = useCallback(
            (key: PanelType) => () => {
                setPaginationInitiator(key)
                setPagination((prev) => ({
                    ...prev,
                    [key]: {
                        ...prev[key],
                        pageNumber:
                            prev[key].pageNumber +
                            PAGINATION_SETTINGS.pageNumber,
                    },
                }))
            },
            []
        )

        const addAppointmentsToCurrentState = (
            panelType: PanelType,
            appointments: YourDevelopmentPageItemContractPageContract,
            prevState?: AppointmentsStateType
        ) => ({
            ...prevState?.[panelType],
            pageItems: [
                ...(prevState?.[panelType]?.pageItems || []),
                ...appointments?.pageItems,
            ],
        })

        const fetchAppointments = useCallback<
            FnActionAsyncRequiredProps<FetchAppointmentsProps>
        >(
            async ({ panelType, isInit }) => {
                try {
                    updateLoader(true)

                    const appointments = await YourDevelopmentService.search({
                        ...pagination[panelType],
                        body: {
                            orderRules: [
                                {
                                    field: 'appointmentDate',
                                    direction: SortDirection.Desc,
                                },
                            ],
                            ...getRequestFilters(panelType),
                        } as YourDevelopmentSearchContract,
                    })

                    setAppointments((prev) => ({
                        ...prev,
                        [panelType]: isInit
                            ? appointments
                            : addAppointmentsToCurrentState(
                                  panelType,
                                  appointments,
                                  prev
                              ),
                    }))

                    updateLoader(false)
                } catch (error) {
                    console.error(error)
                }
            },
            [pagination, updateLoader]
        )

        useEffect(() => {
            if (!paginationInitiator) {
                ;[
                    PanelType.Tracks,
                    PanelType.Activities,
                    PanelType.PassedTracksAndActivities,
                ].forEach((panelType) => {
                    fetchAppointments({ panelType, isInit: true })
                })
            } else {
                fetchAppointments({ panelType: paginationInitiator })
            }
        }, [fetchAppointments, paginationInitiator])

        return (
            <PageContent className={styles.wrapper}>
                <PageBanner />

                <Collapse
                    ghost
                    className={styles.groups}
                    defaultActiveKey={Object.values(PanelType)}
                >
                    <Panel
                        key={PanelType.Tracks}
                        header={
                            <AppointmentsCardsTitle
                                text={LOCAL.LABELS.APPOINTED_TRACKS}
                                count={
                                    appointments?.[PanelType.Tracks]?.total || 0
                                }
                            />
                        }
                        collapsible="header"
                    >
                        <AppointmentsCards
                            appointments={appointments?.[PanelType.Tracks]}
                            fetchAppointments={fetchAppointments}
                            showMoreEntities={showMoreEntities(
                                PanelType.Tracks
                            )}
                            isLoading={isLoading}
                        />
                    </Panel>

                    <Panel
                        key={PanelType.Activities}
                        header={
                            <AppointmentsCardsTitle
                                text={LOCAL.LABELS.APPOINTED_ACTIVITIES}
                                count={
                                    appointments?.[PanelType.Activities]
                                        ?.total || 0
                                }
                            />
                        }
                        collapsible="header"
                    >
                        <AppointmentsCards
                            appointments={appointments?.[PanelType.Activities]}
                            fetchAppointments={fetchAppointments}
                            showMoreEntities={showMoreEntities(
                                PanelType.Activities
                            )}
                            isLoading={isLoading}
                        />
                    </Panel>

                    <Panel
                        key={PanelType.PassedTracksAndActivities}
                        header={
                            <AppointmentsCardsTitle
                                text={LOCAL.LABELS.COMPLETED}
                                count={
                                    appointments?.[
                                        PanelType.PassedTracksAndActivities
                                    ]?.total || 0
                                }
                            />
                        }
                        collapsible="header"
                    >
                        <AppointmentsCards
                            appointments={
                                appointments?.[
                                    PanelType.PassedTracksAndActivities
                                ]
                            }
                            fetchAppointments={fetchAppointments}
                            showMoreEntities={showMoreEntities(
                                PanelType.PassedTracksAndActivities
                            )}
                            isLoading={isLoading}
                            isPassed
                        />
                    </Panel>
                </Collapse>
            </PageContent>
        )
    })
)
