import cn from 'classnames'
import styles from 'components/pages/Track/components/TrackForm/components/shared/TrackStageDnD/TrackStageDnD.module.scss'
import React, { useCallback, useContext, useLayoutEffect, useRef } from 'react'
import { ColorThemeContext } from 'contexts'
import { IconsAdapter, StageProgress, TooltipAdapter } from 'components/shared'
import { LOCAL } from 'core/local'
import { STAGE_STYLE_OPTIONS_INITIAL } from 'components/pages/Track/components/TrackForm/components/shared/StagesDndArea/StagesDndArea.consts'
import { composeStageIconStyles } from 'components/pages/Track/components/TrackForm/components/shared/TrackStageDnD/TrackStageDnD.utils'
import {
    currentTrackStageByStatus,
    formatDate,
    isAppointmentTrackStatusNotPassed,
    isAppointmentTrackStatusPassed,
    isNotAvailableStatus,
    isPassingTypeConsistently,
} from 'utils'
import { eventEmitter } from 'core/helpers/eventEmitter'

import { TRACK_PASSING_EVENTS } from '../../TrackPassing.events'
import { TrackStagePassingProps } from './TrackStagePassing.types'

export const TrackStagePassing: React.FC<TrackStagePassingProps> = React.memo(
    ({ stage, stageStyle, index, passingType }) => {
        const { theme } = useContext(ColorThemeContext)
        const isPassingConsistently = isPassingTypeConsistently(passingType)
        const stageRef = useRef<HTMLDivElement>(null)

        const stageTooltip = `${
            LOCAL.MESSAGES.AVAILABILITY_STAGE_FOR_PASSING
        } ${
            isNotAvailableStatus(stage?.availability?.availableStatus)
                ? formatDate(stage?.availability?.periodTo)
                : formatDate(stage?.availability?.periodFrom)
        }`

        const handleStartStage = useCallback(() => {
            eventEmitter.emit(
                TRACK_PASSING_EVENTS.START_STAGE,
                stage.stageNumber
            )
        }, [stage])

        const composeStagePositionStyles = () => {
            const styles = stageStyle?.positionStyles

            if (!styles) return

            const parseStyles = JSON.parse(styles)

            if (!parseStyles) return

            return {
                ...parseStyles,
                ...STAGE_STYLE_OPTIONS_INITIAL,
            }
        }

        const isShowProgress =
            stage?.showProgress &&
            stage?.passingThematicBlocksCount !==
                stage?.totalThematicBlocksCount

        useLayoutEffect(() => {
            const injectedStyle = composeStageIconStyles(
                {
                    stageStyle,
                    stageStatus: currentTrackStageByStatus(
                        stage.status,
                        stage.availability && isPassingConsistently
                    ),
                    stageIndex: String(index),
                },
                theme
            )

            const removeInjectedStyle = () => {
                injectedStyle?.remove()
            }

            if (!stageRef.current) return removeInjectedStyle

            return removeInjectedStyle
        }, [index, stageStyle, theme, stageRef, stage, isPassingConsistently])

        const stageIcon = (
            <div
                className={cn(styles.wrapper, styles.wrapperOriginal)}
                data-id={index}
                style={composeStagePositionStyles()}
                ref={stageRef}
                onClick={handleStartStage}
            >
                <div className={styles.img}>
                    {isAppointmentTrackStatusNotPassed(stage.status) && (
                        <IconsAdapter
                            iconType="CustomIconClose"
                            className={styles.notPassed}
                        />
                    )}

                    {!stage.availability &&
                        !isAppointmentTrackStatusPassed(stage.status) &&
                        !isPassingConsistently && (
                            <IconsAdapter
                                iconType="CustomIconLock"
                                className={styles.notAvailable}
                            />
                        )}
                </div>

                {isShowProgress && (
                    <StageProgress
                        stageCount={stage.totalThematicBlocksCount}
                        currentStage={stage.passingThematicBlocksCount}
                    />
                )}
            </div>
        )

        return stage?.availability?.periodFrom ? (
            <TooltipAdapter title={stageTooltip}>{stageIcon}</TooltipAdapter>
        ) : (
            stageIcon
        )
    }
)
