import React from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import { VerifyIconProps } from './VerifyIcon.types'
import { getIconProps } from './VerifyIcon.utils'

/** Иконка для визуализации правильности ответа */
export const VerifyIcon: React.FC<VerifyIconProps> = React.memo(
    ({ isCorrect }) => <IconsAdapter {...getIconProps(isCorrect)} />
)
