import SearchTableShowButton from 'components/controls/SearchSelectControl/components/SearchTableShowButton/SearchTableShowButton'
import cn from 'classnames'
import React, { ReactText, useCallback, useMemo, useState } from 'react'
import { ANIMATION_TIME_OUT_DEFAULT } from 'consts'
import { Button } from 'components/shared/Button'
import { CSSTransition } from 'react-transition-group'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { RcCustomRequestOptions } from 'types'
import { Upload } from 'antd'
import { backwardFn, getItemId, isFormModeView } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './DynamicTableAdapter.module.scss'
import { AddButton } from '../AddButton'
import { DynamicTableAdapterProps } from './DynamicTableAdapter.types'
import { IconsAdapter } from '../IconsAdapter'
import { TableAdapter } from '../TableAdapter'
import { getDynamicTableAdapterColumnsWithActions } from './DynamicTableAdapter.utils'

/** Компонент таблицы с возможностью добавления и удаления новых элементов */
function DynamicTableAdapter<
    GetEntitiesByIdsResponse,
    NormalizedDataForValueProps extends { id: ReactText },
    SearchMethodRequest extends object = any,
    SearchMethodResponse extends { id: number } = any,
    ImportRespondentsResponse extends object = any
>({
    className,
    value: valueProp,
    onChange,
    title,
    modalTitle,
    modalWidth,
    disabled,
    rowSelectionType = 'checkbox',
    formMode,
    columnsForSearchModal,
    modalFilterComponent,
    tableFiltersOptions,
    tableOptions,
    renderTableColumns,
    getEntitiesByIds,
    searchMethod,
    normalizeFn,
    customAddButtonRender,
    renderTitle,
    withSpoiler,
    headerAddonAfter,
    childrenColumnName,
    rowClassName,
    renderSelectCell,
    filterKeys,
    onRowCheck,
    required,
    importValues,
    noralizeImportFn,
}: DynamicTableAdapterProps<
    GetEntitiesByIdsResponse,
    NormalizedDataForValueProps,
    SearchMethodRequest,
    SearchMethodResponse,
    ImportRespondentsResponse
>) {
    const {
        updateElementVisible: updateLoader,
        elementVisible: isLoading,
    } = useElementVisible()

    const [selectedEntitiesState, setSelectedEntitiesState] = useState<
        NormalizedDataForValueProps[]
    >()

    const value = valueProp || selectedEntitiesState

    const initialValuesForTableInModal = useMemo(() => value?.map(getItemId), [
        value,
    ])

    const [visible, setVisible] = useState(true)

    const isSelectedItems = !!value?.length

    /**
     * Обработчик добавления выбранных записей
     */
    const handleSelectItems = useCallback(
        async (result?: number[]) => {
            try {
                updateLoader(true)

                if (result?.length) {
                    const newItems = await getEntitiesByIds({
                        body: result,
                    })

                    const newValue = normalizeFn(newItems, value)

                    setSelectedEntitiesState(newValue)
                    onChange?.(newValue)
                }
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        },
        [getEntitiesByIds, normalizeFn, onChange, updateLoader, value]
    )

    /**
     * Обработчик обновления выбранных записей
     */
    const handleUpdateState = useCallback(
        (changedRow: NormalizedDataForValueProps) => {
            const newValue = value?.map((row) =>
                row.id === changedRow.id ? changedRow : row
            )

            setSelectedEntitiesState(newValue)
            onChange?.(newValue)
        },
        [onChange, value]
    )

    /**
     * Обработчик удаления выбранной сущности
     */
    const handleRemoveRow = useCallback(
        (idToRemove: React.ReactText) => () => {
            const newValue = value?.filter((row) => row.id !== idToRemove)

            setSelectedEntitiesState(newValue)
            onChange?.(newValue)
        },
        [onChange, value]
    )

    /** Обработчик раскрытия/скрытия таблицы элементов*/
    const handleToggleVisible = useCallback(
        (e) => {
            e.preventDefault()

            if (!withSpoiler) return

            setVisible(backwardFn)
        },
        [withSpoiler]
    )

    const handleImportValues = useCallback(
        async (attachment: RcCustomRequestOptions) => {
            if (!importValues) return

            const newItems = await importValues(attachment.file)

            const normalizedValues = noralizeImportFn?.(newItems, value) || []

            setSelectedEntitiesState(normalizedValues)

            onChange?.(normalizedValues)
        },
        [importValues, value, onChange, noralizeImportFn]
    )

    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={styles.header}>
                <a
                    href="#s"
                    onClick={handleToggleVisible}
                    className={cn(
                        styles.headerLink,
                        visible && styles.headerLinkActive,
                        withSpoiler && isSelectedItems && 'with-spoiler'
                    )}
                >
                    {withSpoiler && isSelectedItems && (
                        <IconsAdapter
                            iconType="DownOutlined"
                            className={'dynamic-table-adapter__spoiler-icon'}
                        />
                    )}

                    <span
                        className={cn(styles.title, {
                            'required-control': required,
                        })}
                    >
                        {renderTitle?.(value) || title}

                        {importValues &&
                            !!value?.length &&
                            ` (${value?.length})`}
                    </span>
                </a>

                <SearchTableShowButton<
                    SearchMethodRequest,
                    SearchMethodResponse
                >
                    tableSearchOptions={{
                        searchMethod,
                        rowSelectionType,
                        tableColumns: columnsForSearchModal,
                        formId: FORM_IDS.ASSESSMENT_CANDIDATE,
                        filterComponent: modalFilterComponent,
                        onSelectTableItem: handleSelectItems,
                        initialValues: initialValuesForTableInModal,
                        tableFiltersOptions,
                        childrenColumnName,
                        rowClassName,
                        renderSelectCell,
                        filterKeys,
                        onRowCheck,
                    }}
                    modalTitle={modalTitle}
                    modalWidth={modalWidth}
                >
                    {customAddButtonRender?.({
                        disabled,
                        isModeView: isFormModeView(formMode),
                        isLoading,
                    }) || (
                        <AddButton
                            className={styles.addButton}
                            disabled={
                                disabled ||
                                isFormModeView(formMode) ||
                                isLoading
                            }
                            buttonText={LOCAL.ACTIONS.ADD}
                            loading={isLoading}
                        />
                    )}
                </SearchTableShowButton>

                {importValues && (
                    <Upload
                        showUploadList={false}
                        customRequest={handleImportValues}
                        className={cn(styles.importBtn)}
                    >
                        <Button
                            disabled={isLoading || isFormModeView(formMode)}
                            loading={isLoading}
                        >
                            {LOCAL.ACTIONS.IMPORT}
                        </Button>
                    </Upload>
                )}

                {headerAddonAfter && (
                    <div className={styles.headerAddonAfter}>
                        {headerAddonAfter}
                    </div>
                )}
            </div>

            <CSSTransition
                in={visible}
                timeout={ANIMATION_TIME_OUT_DEFAULT}
                classNames="animation-fade-unmount"
                unmountOnExit
            >
                <div>
                    {isSelectedItems && (
                        <TableAdapter
                            {...(tableOptions || {})}
                            dataSource={value}
                            columns={getDynamicTableAdapterColumnsWithActions<
                                NormalizedDataForValueProps
                            >({
                                onRemoveRow: handleRemoveRow,
                                formMode,
                                disabled,
                                columns:
                                    renderTableColumns?.(handleUpdateState) ||
                                    tableOptions?.columns,
                                customTableActions:
                                    tableOptions?.customTableActions,
                            })}
                            pagination={false}
                        />
                    )}
                </div>
            </CSSTransition>
        </div>
    )
}

export default React.memo(DynamicTableAdapter) as typeof DynamicTableAdapter
