import React, { useEffect } from 'react'
import {
    ControlGroup,
    FormItemAdapter,
    RadioButtonControl,
    SelectControl,
    ShouldUpdateChecker,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { MAILING_TYPES_MAPPED } from 'consts'
import {
    NotificationMailingTaskType,
    NotificationSendingOptionSelectContract,
    NotificationSendingOptionsService,
} from 'core/api'
import {
    getCommonSelectItemProps,
    isFormModeEdit,
    isMailingStatusSuspended,
    isNotificationMailingTaskTypeAtSpecifiedTime,
} from 'utils'
import { useHttp } from 'hooks'

import { DistributionMailingParamsByEvent } from '../DistributionMailingParamsByEvent'
import { DistributionMailingParamsByTime } from '../DistributionMailingParamsByTime'
import { DistributionMailingParamsProps } from './DistributionMailingParams.types'

/** Параметры отправки задания на рассылку */
export const DistributionMailingParams: React.FC<DistributionMailingParamsProps> = React.memo(
    ({ form, formMode, initialValues }) => {
        const [getSendingOptions, sendingOptions] = useHttp<
            NotificationSendingOptionSelectContract[]
        >(NotificationSendingOptionsService.getForSelect)

        useEffect(() => {
            getSendingOptions()
        }, [getSendingOptions])

        useEffect(() => {
            if (sendingOptions) {
                form?.setFieldsValue({
                    sendOptionId: sendingOptions?.find((el) => el.isDefault)
                        ?.id,
                })
            }
        }, [form, sendingOptions])

        return (
            <ControlGroup title={LOCAL.LABELS.MAILING_PARAMS} type="bordered">
                <FormItemAdapter
                    fieldName="sendOptionId"
                    label={LOCAL.LABELS.SENDER_ADDRESS}
                >
                    <SelectControl
                        values={sendingOptions?.map((el) => ({
                            ...getCommonSelectItemProps(el.id),
                            label: el.senderAddress,
                        }))}
                        formMode={formMode}
                    />
                </FormItemAdapter>

                <FormItemAdapter
                    fieldName="type"
                    label={LOCAL.LABELS.SHIPPING_METHOD}
                    initialValue={NotificationMailingTaskType.ByEvent}
                >
                    <RadioButtonControl
                        values={MAILING_TYPES_MAPPED}
                        buttonStyle="solid"
                        form={form}
                        formMode={formMode}
                        disabled={
                            isFormModeEdit(formMode) &&
                            isMailingStatusSuspended(initialValues?.status)
                        }
                    />
                </FormItemAdapter>

                <ShouldUpdateChecker fieldPath={['type']}>
                    {({ getFieldValue }) =>
                        !isNotificationMailingTaskTypeAtSpecifiedTime(
                            getFieldValue('type')
                        ) ? (
                            <DistributionMailingParamsByEvent
                                formMode={formMode}
                                form={form}
                                initialValues={initialValues}
                            />
                        ) : (
                            <DistributionMailingParamsByTime
                                formMode={formMode}
                                form={form}
                            />
                        )
                    }
                </ShouldUpdateChecker>
            </ControlGroup>
        )
    }
)
