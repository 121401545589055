import React, { useCallback, useContext } from 'react'
import { ActivityLessonPassingService } from 'core/api'
import { PageContent } from 'components/shared'
import { isTrainingMaterialFormatTinCan } from 'utils'

import styles from './ThematicBlockLayer.module.scss'
import { ThematicBlockContent } from './components/ThematicBlockContent'
import { ThematicBlockHeader } from './components'
import { ThematicBlockLayerProps } from './ThematicBlockLayer.types'
import { TrackPassingContext } from '../../TrackPassing.context'

/**
 * Слой тематического блока, входящего в состав мероприятия
 */
export const ThematicBlockLayer: React.FC<ThematicBlockLayerProps> = React.memo(
    ({
        activity,
        thematicBlock,
        onFinishThematicBlock,
        setProgressForSend,
    }) => {
        const { store } = useContext(TrackPassingContext)

        /**
         * Обработчик ознакомления
         * @param isCompleted признак прохождения, для tincan может быть false
         */
        const handleViewMaterial = useCallback(
            async (isCompleted = true) => {
                if (thematicBlock) {
                    try {
                        store.updateLoader(true)

                        if (isCompleted) {
                            await ActivityLessonPassingService.finish({
                                activityLessonPassingId: thematicBlock.id,
                            })
                        }

                        onFinishThematicBlock()
                    } catch (e) {
                        console.log(e)
                    } finally {
                        store.updateLoader(false)
                    }
                }
            },
            [thematicBlock, store, onFinishThematicBlock]
        )

        return (
            <PageContent className={styles.wrapper}>
                {!isTrainingMaterialFormatTinCan(
                    thematicBlock.course?.format
                ) && (
                    <ThematicBlockHeader
                        activity={activity}
                        thematicBlock={thematicBlock}
                        onFinishThematicBlock={onFinishThematicBlock}
                        onViewMaterial={handleViewMaterial}
                    />
                )}

                <ThematicBlockContent
                    {...thematicBlock}
                    onViewMaterial={handleViewMaterial}
                    setProgressForSend={setProgressForSend}
                />
            </PageContent>
        )
    }
)
