import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { ContentTitle } from 'components/shared/ContentTitle'
import { CoursesService, DictionaryContract } from 'core/api'
import { FORM_IDS } from 'core/configs'
import { Form, Tabs } from 'antd'
import { LOCAL } from 'core/local'
import { PageContent } from 'components/shared/PageContent'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { UrlParamsCommonProps } from 'App.types'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useParams } from 'react-router-dom'
import { withLoader } from 'HOCs'

import styles from './TrainingMaterialInfo.module.scss'
import { CourseActiveAppointments, CourseMainInfo } from './components'
import { TABS_PANE_OPTIONS } from './TrainingMaterialInfo.consts'
import { mapResponseToFormData } from './TrainingMaterialInfo.utils'

/** Страница курса с информацией о версиях */
export const TrainingMaterialInfo: React.FC = withLoader(
    React.memo(({ updateLoader }) => {
        const params = useParams<UrlParamsCommonProps<'id'>>()
        const [form] = Form.useForm()

        const [course, setCourse] = useState<DictionaryContract>()

        const fetchCourse = useCallback(async () => {
            try {
                updateLoader(true)

                const values = await CoursesService.getActive({
                    id: Number(params.id),
                })

                const mappedCourseValues = mapResponseToFormData(values)

                setCourse(mappedCourseValues)

                form.setFieldsValue({ ...mappedCourseValues })
            } catch (error) {
                console.error(error)
            } finally {
                updateLoader(false)
            }
        }, [form, params.id, updateLoader])

        const pageTitle = useMemo(
            () => (
                <>
                    <span className="b500">
                        {getRouteMeta(ROUTE_NAMES.TRAINING_MATERIALS_INFO)}
                    </span>

                    <span className="b400">{` - ${course?.name}`}</span>
                </>
            ),
            [course]
        )

        useEffect(() => {
            fetchCourse()
        }, [fetchCourse])

        return (
            <PageContent filled className={styles.wrapper}>
                <ContentTitle
                    className={styles.title}
                    title={pageTitle}
                    disableBoxShadow
                >
                    <ButtonsToolbar>
                        <Button
                            type="link"
                            href={ROUTE_NAMES.TRAINING_MATERIALS}
                        >
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        <Button
                            form={FORM_IDS.COURSE_INFO_FORM}
                            htmlType="submit"
                            type="primary"
                        >
                            {LOCAL.ACTIONS.SAVE}
                        </Button>
                    </ButtonsToolbar>
                </ContentTitle>

                <Tabs type="card">
                    <Tabs.TabPane forceRender {...TABS_PANE_OPTIONS.MAIN_INFO}>
                        <CourseMainInfo
                            onFetchCourse={fetchCourse}
                            form={form}
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        forceRender
                        {...TABS_PANE_OPTIONS.ACTIVE_APPOINTMENTS}
                    >
                        <CourseActiveAppointments id={Number(params.id)} />
                    </Tabs.TabPane>
                </Tabs>
            </PageContent>
        )
    })
)
