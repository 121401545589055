import isEmpty from 'lodash/isEmpty'
import {
    AssessmentType,
    DictionaryContract,
    EnumTableContract,
    EnumTableDictionariesService,
    ScalesService,
} from 'core/api'
import { LabeledValue } from 'antd/lib/select'
import { Store } from 'App.types'
import { eventEmitter } from 'core/helpers/eventEmitter'
import { normalizeDataForSelectAndRadio } from 'utils/form.utils'

const convertEnumTableContractToDictionary = (
    dictionary: EnumTableContract[]
) => dictionary.reduce((acc, curr) => ({ ...acc, [curr.code]: curr.name }), {})

const convertDictionaryContractToLabeledValue = (
    dictionary: DictionaryContract[]
) => dictionary.map(normalizeDataForSelectAndRadio)

export const DICTIONARIES_HELPER_EVENTS = {
    ON_DICTIONARIES_LOADED: 'ON_DICTIONARIES_LOADED',
}

/**
 * Типизация для DictionariesHelper.Store, при добавлении новых справочников, типизацию также необходимо расширять
 */
export interface DictionariesStoreProps {
    assessmentTypeDictionary: { [key in AssessmentType]?: string }
    resultEstimateScaleTypesDictionary: LabeledValue[]
}

/**
 * Маппинг для словарей, по сути не обязателен, если соответствующей ключу словаря функции нет, значение с бэка в словарь записывается в исходном виде
 */
const MAPPING_CONFIG: Store<Function> = {
    assessmentTypeDictionary: convertEnumTableContractToDictionary,
    resultEstimateScaleTypesDictionary: convertDictionaryContractToLabeledValue,
}

export type DictionaryKeys = keyof DictionariesStoreProps

export type PromiseQueueProps = {
    [Propery in DictionaryKeys]: boolean
}

/**
 * Конфигурация промисов
 * @param dictionaryKeys ключи словарей, для которых необходимо выполнить запрос данных
 * @param queue текущая очередь промисов, берется из DictionariesHelper.queue
 * @returns конфиг\объект (ключ словаря\промис) только необходимых промисов, в соответсвии с dictionaryKeys и queue
 */
const getPromiseConfig = (
    dictionaryKeys: string[],
    queue: PromiseQueueProps
) => {
    /**
     * Хранилище методов для запроса справочников
     */
    const methods = {
        assessmentTypeDictionary:
            EnumTableDictionariesService.getEntityAssessmentTypes,
        resultEstimateScaleTypesDictionary: ScalesService.getForSelect,
    }

    const filteredMethods = Object.entries(methods).filter(([key]) =>
        dictionaryKeys.includes(key)
    )

    const promises = filteredMethods.reduce<Store>((acc, [key, method]) => {
        if (!queue[key as DictionaryKeys]) {
            acc[key] = method()
            queue[key as DictionaryKeys] = true
        }

        return acc
    }, {})

    return promises
}

// TODO: после обновления typescript до 4.5 перейти на Awaited для упрощения работы с типизацией Store на основе PROMISE_CONFIG
export class DictionariesHelper {
    private static _store: DictionariesStoreProps = {
        assessmentTypeDictionary: {},
        resultEstimateScaleTypesDictionary: [],
    }

    public static get store() {
        return this._store
    }

    private static _fetchDictionaries(promises: Store) {
        const dictionariesKeys = Object.keys(promises)
        const dictionariesPromises = Object.values(promises)

        Promise.all(dictionariesPromises)
            .then((responses) => {
                responses.forEach((dictionary, index) => {
                    const currentDictionary = dictionariesKeys[
                        index
                    ] as DictionaryKeys

                    this._store[currentDictionary] = MAPPING_CONFIG[
                        currentDictionary
                    ]
                        ? MAPPING_CONFIG[currentDictionary]?.(dictionary)
                        : dictionary
                })

                eventEmitter.emit(
                    DICTIONARIES_HELPER_EVENTS.ON_DICTIONARIES_LOADED
                )
            })
            .catch((e) => {
                console.error('Unable to initialize dictionaires', e)
            })
    }

    public static update(dictionaryKeys: DictionaryKeys[]) {
        const queue = dictionaryKeys.reduce<PromiseQueueProps>((acc, key) => {
            acc[key] = false

            return acc
        }, {} as PromiseQueueProps)

        const promises = getPromiseConfig(dictionaryKeys, queue)

        this._fetchDictionaries(promises)
    }

    /**
     * Состояние очереди прописов, справочники могут запрашиваться из нескольких компонентов сразу.
     * Поэтому, если ранее уже был инициализирован запрос для конкретного справочника,
     * то значение соответствующего ему ключа равняется true, тем самым мы понимаем, что больше
     * промис резолвить не нужно.
     */
    public static promisesQueue = {} as PromiseQueueProps

    /**
     * Ициализировать справочники, с учетом указанных ключей
     * @param dictionaryKeys ключи запрашиваемых справочников
     */
    public static init(dictionaryKeys: DictionaryKeys[]) {
        const requestedDictionaryEntries = Object.entries(
            this.store
        ).filter(([key]) => dictionaryKeys.includes(key as DictionaryKeys))

        const emptyAmongRequestedDictionaryKeys = requestedDictionaryEntries.reduce<
            string[]
        >((acc, [key, value]) => {
            if (isEmpty(value) && !this.promisesQueue[key as DictionaryKeys]) {
                acc.push(key)
            }

            return acc
        }, [])

        if (emptyAmongRequestedDictionaryKeys.length) {
            const promises = getPromiseConfig(
                emptyAmongRequestedDictionaryKeys,
                this.promisesQueue
            )

            this._fetchDictionaries(promises)
        }
    }
}
