import { eventEmitter } from 'core/helpers/eventEmitter'
import { useCallback, useMemo } from 'react'

import { ButtonsControlProps } from './ButtonsControl.types'
import { QUESTIONNAIRE_PASSING_EVENTS } from '../../QuestionnairePassing.events'
import {
    isAnswerFinished,
    isThereAnswersWithTimeLimit,
} from './ButtonsControl.utils'

export const useButtonsControl = ({
    passingData,
    answers,
    onConfirmAnswers,
}: ButtonsControlProps) => {
    const { questions, questionnaire } = passingData

    const unsavedAnswers = useMemo(
        () => Object.values(answers).filter((el) => el.unsaved),
        [answers]
    )

    /**
     * Активность/не активность кнопки завершения прохождения
     * @return boolean - является false, когда есть ответы на все обязательные вопросы
     */
    const isBtnFinishDisabled = useMemo(
        () =>
            questions.some(
                (el) =>
                    el.mandatoryQuestion &&
                    !isAnswerFinished({
                        question: el,
                        confirmedAnswers: questionnaire.answers,
                        currentAnswers: answers,
                    })
            ),
        [answers, questionnaire.answers, questions]
    )

    /**
     * Завершение опроса
     * @param message - сообщение для модального окна
     */
    const handleSubmit = useCallback(() => {
        eventEmitter.emit(QUESTIONNAIRE_PASSING_EVENTS.FINISH)
    }, [])

    /**
     * Колбэк для подтверждения ответов
     * @param withSetAnswersTimeLeft если true, то обновляем значения таймеров несохраненных ответов, в последствии
     *     подтверждая на бэке
     */
    const handleConfirmAnswers = useCallback(() => {
        if (!unsavedAnswers.length) return

        if (isThereAnswersWithTimeLimit(unsavedAnswers, questions)) {
            eventEmitter.emit(
                QUESTIONNAIRE_PASSING_EVENTS.UPDATE_ANSWER,
                unsavedAnswers
            )
        }

        onConfirmAnswers(unsavedAnswers)
    }, [onConfirmAnswers, questions, unsavedAnswers])

    return {
        handleSubmit,
        handleConfirmAnswers,
        isBtnFinishDisabled,
        unsavedAnswers,
    }
}
