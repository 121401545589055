import axios from 'axios'
import moment from 'moment'
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Button } from 'components/shared/Button'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { Col, Form, Row, Upload } from 'antd'
import { CourseUploadStateTypes } from 'core/helpers/signalr/Notification.types'
import { CoursesService } from 'core/api'
import { CoursesServiceManual } from 'core/api/CourseUploadManual'
import { DATE_FORMAT } from 'consts'
import { FORM_IDS } from 'core/configs'
import {
    FormItemAdapter,
    HiddenField,
    InputControl,
    NotEditableTextField,
    SelectControl,
    TextAreaControl,
} from 'components/controls'
import { LOCAL } from 'core/local'
import { LabeledValue } from 'antd/lib/select'
import { RcFile } from 'antd/lib/upload/interface'
import { UserContext } from 'contexts'
import {
    courseUploadStatusChangedOff,
    onCourseUploadStatusChanged,
} from 'core/helpers/signalr'
import {
    createConfirmPopup,
    createErrorNotification,
    createSuccessNotification,
    getShortFio,
} from 'utils'
import { useElementVisible } from 'hooks'
import { useForm } from 'antd/lib/form/Form'
import { withLoader } from 'HOCs'

import styles from './UploadCourseForm.module.scss'
import {
    ACCEPT_FORMATS,
    EDITIONAL_INFO_ROW_GUTTER,
} from './UploadCourseForm.consts'
import {
    ProgressPercents,
    UploadCourseErrorProps,
    UploadCourseFormProps,
} from './UploadCourseForm.types'
import { UploadCourseValidationConfirm } from '../UploadCourseValidationConfirm'
import {
    composeAcceptFormatString,
    getFileHash,
    isCourseValidationTypeNameNotUnique,
    ownerFetch,
} from './UploadCourseForm.utils'

/** Форма загрузки курса */
export const UploadCourseForm: React.FC<UploadCourseFormProps> = withLoader(
    React.memo(
        ({
            onRequestFinish,
            onCancelSubmit,
            newVersion,
            initialValues,
            updateLoader,
        }) => {
            const {
                elementVisible: isLoading,
                updateElementVisible,
            } = useElementVisible()
            const { userData } = useContext(UserContext)
            const [form] = useForm()
            const [uploadFile, setUploadFile] = useState<RcFile>()
            const [owners, setOwners] = useState<LabeledValue[]>()
            const [validationData, setValidationData] = useState<
                UploadCourseErrorProps['data']
            >()
            const [progressPercents, setProgressPercents] = useState<
                ProgressPercents
            >(ProgressPercents.NOT_UPLOADED)
            const uploadCancelSourceRef = useRef(axios.CancelToken.source())
            const [fileObjectKey, setFileObjectKey] = useState<string>()

            /**
             * Обработчик отмены обработки файла на сервере
             */
            const handleCancelUpload = useCallback(async () => {
                try {
                    if (fileObjectKey) {
                        uploadCancelSourceRef.current.cancel()

                        await CoursesService.cancelUpload({
                            body: { fileObjectKey },
                        })

                        uploadCancelSourceRef.current = axios.CancelToken.source()
                    }
                } catch (error) {
                    console.error(error)
                }
            }, [fileObjectKey])

            /**
             * Обработчик закрытия формы
             */
            const handleCancelForm = useCallback(() => {
                if (progressPercents >= ProgressPercents.UPLOAD_FILE) {
                    createConfirmPopup({
                        content: LOCAL.MESSAGES.ABORT_FILE_DOWNLOAD,
                        onOk: () => {
                            handleCancelUpload()
                            onCancelSubmit?.()
                        },
                    })
                } else {
                    onCancelSubmit?.()
                }
            }, [onCancelSubmit, handleCancelUpload, progressPercents])

            /**
             * Обработчик отправки формы
             */
            const handleFinish = useCallback(
                async (body) => {
                    try {
                        updateLoader(true)

                        updateElementVisible(true)

                        setProgressPercents(ProgressPercents.START_UPLOAD)

                        const {
                            objectKey,
                            putUrl,
                            contentType,
                        } = await CoursesService.registerUpload({
                            body: {
                                ...body,
                                rootCourseVersionId:
                                    initialValues?.rootCourseVersionId,
                                fileName: uploadFile?.name,
                            },
                        })

                        if (objectKey && uploadFile) {
                            setFileObjectKey(objectKey)
                            setProgressPercents(ProgressPercents.UPLOAD_FILE)

                            await CoursesServiceManual.upload({
                                putUrl,
                                file: uploadFile,
                                cancelSource: uploadCancelSourceRef.current,
                                contentType,
                            })

                            setProgressPercents(ProgressPercents.UPLOAD_SUCCESS)

                            await CoursesService.processUpload({
                                body: {
                                    fileObjectKey: objectKey,
                                },
                            })

                            setProgressPercents(ProgressPercents.END_UPLOAD)
                        }
                    } catch (err) {
                        updateLoader(false)

                        const error = err as UploadCourseErrorProps

                        setProgressPercents(ProgressPercents.NOT_UPLOADED)

                        if (error.data?.case) {
                            const validationData = error.data
                            if (
                                isCourseValidationTypeNameNotUnique(
                                    validationData.case
                                )
                            ) {
                                createErrorNotification(
                                    LOCAL.LABELS.NAME_MUST_BE_UNIQUE
                                )
                            } else {
                                setValidationData(validationData)
                            }
                        } else if (axios.isCancel(error)) {
                            createErrorNotification(
                                LOCAL.MESSAGES.DOWNLOAD_CANCELED
                            )
                            console.log('Request canceled')
                        } else {
                            console.error(error)
                        }
                    } finally {
                        updateElementVisible(false)
                    }
                },
                [updateLoader, updateElementVisible, initialValues, uploadFile]
            )

            /**
             * Обработчик добавления файла
             * @param options объект события добавления файла
             */
            const handleCustomRequest = useCallback(
                (options) => {
                    updateLoader(true)

                    setUploadFile(options.file)
                    getFileHash(options.file)
                        .then((fileHash) => {
                            form.setFieldsValue({ fileHash })
                        })
                        .finally(() => {
                            updateLoader(false)
                        })
                },
                [updateLoader, form]
            )

            /**
             * Обработчик остановки загрузки файла
             * @param isPopup флаг для запуска попапа с предупреждением
             */
            const handleRemoveFile = useCallback(
                (isPopup?: boolean) => () => {
                    const cleanUploadData = function () {
                        setUploadFile(undefined)
                        setValidationData(undefined)
                        form.setFieldsValue({ fileHash: undefined })
                        setFileObjectKey(undefined)
                    }

                    if (
                        progressPercents >= ProgressPercents.UPLOAD_FILE &&
                        isPopup
                    ) {
                        createConfirmPopup({
                            content: LOCAL.MESSAGES.ABORT_FILE_DOWNLOAD,
                            onOk: () => {
                                cleanUploadData()
                                handleCancelUpload()
                            },
                        })
                    } else {
                        cleanUploadData()
                    }
                },
                [handleCancelUpload, progressPercents, form]
            )

            useEffect(() => {
                ownerFetch({ setOwners, updateElementVisible })
            }, [updateElementVisible])

            useEffect(() => {
                onCourseUploadStatusChanged((response) => {
                    switch (response?.state) {
                        case CourseUploadStateTypes.Finished:
                            createSuccessNotification(
                                LOCAL.MESSAGES.FILE_UPLOADED_SUCCESSFULLY
                            )
                            updateLoader(false)
                            onRequestFinish?.()
                            break
                        case CourseUploadStateTypes.Failed:
                            createErrorNotification(
                                LOCAL.MESSAGES.COURSE_UPLOAD_ERROR
                            )
                            updateLoader(false)
                            onCancelSubmit?.()
                    }
                })

                return () => courseUploadStatusChangedOff()
            }, [onRequestFinish, onCancelSubmit, updateLoader])

            return (
                <Form
                    id={FORM_IDS.UPLOAD_COURSE_FORM}
                    form={form}
                    onFinish={handleFinish}
                    initialValues={initialValues}
                >
                    <HiddenField fieldName="fileHash" />

                    <FormItemAdapter
                        fieldName="structuralUnitId"
                        label={LOCAL.LABELS.OWNER}
                    >
                        <SelectControl
                            values={owners}
                            showSearch
                            disabled={!!uploadFile || newVersion}
                        />
                    </FormItemAdapter>

                    <div className={styles.upload}>
                        {uploadFile ? (
                            <UploadCourseValidationConfirm
                                progressPercents={progressPercents}
                                onRemoveFile={handleRemoveFile}
                                validationData={validationData}
                                uploadFile={uploadFile}
                                onCancelSubmit={onCancelSubmit}
                            />
                        ) : (
                            <Form.Item
                                noStyle
                                dependencies={['structuralUnitId']}
                            >
                                {({ getFieldValue }) => {
                                    const isDisableUpload = !getFieldValue(
                                        'structuralUnitId'
                                    )

                                    return (
                                        <>
                                            <Upload
                                                accept={
                                                    composeAcceptFormatString(
                                                        initialValues?.format
                                                    ) || ACCEPT_FORMATS
                                                }
                                                customRequest={
                                                    handleCustomRequest
                                                }
                                                disabled={isDisableUpload}
                                            >
                                                <AddButton
                                                    buttonText={
                                                        LOCAL.ACTIONS
                                                            .SELECT_FILE
                                                    }
                                                    disabled={
                                                        isLoading ||
                                                        isDisableUpload
                                                    }
                                                    loading={isLoading}
                                                />
                                            </Upload>

                                            <div className={styles.formats}>
                                                {`${LOCAL.LABELS.FORMAT}: ${
                                                    composeAcceptFormatString(
                                                        initialValues?.format
                                                    ) || ACCEPT_FORMATS
                                                }`}
                                            </div>
                                        </>
                                    )
                                }}
                            </Form.Item>
                        )}
                    </div>

                    <FormItemAdapter fieldName="name" label={LOCAL.LABELS.NAME}>
                        <InputControl disabled={newVersion} />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName="comment"
                        label={LOCAL.LABELS.COMMENT}
                    >
                        <TextAreaControl
                            autoSize={{ minRows: 4, maxRows: 4 }}
                        />
                    </FormItemAdapter>

                    <FormItemAdapter fieldName="key" label={LOCAL.LABELS.KEY}>
                        <InputControl />
                    </FormItemAdapter>

                    <Row gutter={EDITIONAL_INFO_ROW_GUTTER}>
                        <Col>
                            <NotEditableTextField
                                label={LOCAL.LABELS.UPLOAD_AUTHOR}
                                fieldName="author"
                                initialValue={getShortFio(userData)}
                            />
                        </Col>

                        <Col>
                            <NotEditableTextField
                                label={LOCAL.LABELS.DOWNLOAD_DATE}
                                fieldName="downloadDate"
                                initialValue={moment().format(DATE_FORMAT.DATE)}
                            />
                        </Col>
                    </Row>

                    <ButtonsToolbar>
                        <Button
                            type="link"
                            onClick={handleCancelForm}
                            disabled={
                                progressPercents ===
                                ProgressPercents.START_UPLOAD
                            }
                        >
                            {LOCAL.ACTIONS.CANCEL}
                        </Button>

                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                progressPercents >=
                                ProgressPercents.START_UPLOAD
                            }
                        >
                            {LOCAL.ACTIONS.ADD}
                        </Button>
                    </ButtonsToolbar>
                </Form>
            )
        }
    )
)
