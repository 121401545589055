import cn from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { AddButton } from 'components/shared/AddButton'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { QuestionnaireType } from 'core/api'
import { ShouldUpdateChecker } from 'components/controls'
import { createFieldsRow, isFormModeView } from 'utils'

import styles from './AnswersField.module.scss'
import { AnswerRow, AnswerRowPropsValue } from '../AnswerRow'
import { AnswersFieldProps } from './AnswersField.types'
import { disableAddButton } from './AnswersField.utils'

export const AnswersField: FC<AnswersFieldProps> = React.memo(
    ({ value, onChange, questionIndex, disabled }) => {
        /**
         * Основной стейт, для управления состоянием компонента
         */
        const [answersFieldData, setAnswersFieldData] = useState<
            AnswerRowPropsValue[]
        >(value || [createFieldsRow()])

        /**
         * Обновить значение во внешней форме
         * @param changedValue новое значение для поля
         */
        const triggerChange = useCallback(
            (changedValue: AnswerRowPropsValue[]) => {
                if (onChange) {
                    onChange(changedValue)
                }
            },
            [onChange]
        )

        /**
         * Обработчик добавления нового вопроса
         */
        const handleAnswerAdd = useCallback(() => {
            const result = [...answersFieldData, createFieldsRow()]

            setAnswersFieldData(result)
            triggerChange(result)
        }, [answersFieldData, triggerChange])

        /**
         * Обработчик удаления вопроса
         */
        const handleAnswerRemove = useCallback(
            (id) => () => {
                const result = answersFieldData.filter((el) => el.id !== id)

                setAnswersFieldData(result)
                triggerChange(result)
            },
            [answersFieldData, triggerChange]
        )

        /**
         * Обработчик изменения вопроса
         */
        const handleAnswerEdit = useCallback(
            (id) => (value: AnswerRowPropsValue) => {
                const result = answersFieldData.map((el) => {
                    if (el.id === id) return { ...el, ...value }

                    return el
                })

                setAnswersFieldData(result)
                triggerChange(result)
            },
            [answersFieldData, triggerChange]
        )

        /**
         * Рендер ряда ответа
         */
        const answerRow = useCallback(
            (type: QuestionnaireType) =>
                answersFieldData.map((el, index) => (
                    <AnswerRow
                        key={el.id}
                        answerRowIndex={index}
                        questionIndex={questionIndex}
                        onChange={handleAnswerEdit(el.id)}
                        onRemove={handleAnswerRemove(el.id)}
                        value={value?.[index] || answersFieldData[index]}
                        disabled={disabled}
                        type={type}
                    />
                )),
            [
                answersFieldData,
                handleAnswerEdit,
                handleAnswerRemove,
                questionIndex,
                value,
                disabled,
            ]
        )

        return (
            <div className={styles.wrapper}>
                <h4 className={cn({ 'required-control': !disabled })}>
                    {LOCAL.LABELS.ANSWER_VARIANTS}
                </h4>

                <ShouldUpdateChecker fieldPath={['type']}>
                    {({ getFieldValue }) => answerRow(getFieldValue('type'))}
                </ShouldUpdateChecker>

                <Form.Item shouldUpdate noStyle>
                    {(form) => (
                        <AddButton
                            onClick={handleAnswerAdd}
                            {...disableAddButton(
                                form.getFieldsValue(),
                                questionIndex
                            )}
                            buttonText={LOCAL.ACTIONS.ADD_VARIANT}
                            disabled={
                                isFormModeView(
                                    form.getFieldsValue()?.formMode
                                ) || disabled
                            }
                        />
                    )}
                </Form.Item>
            </div>
        )
    }
)
