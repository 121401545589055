import React, { useCallback } from 'react'
import { AssessmentType } from 'core/api'
import { Form } from 'antd'
import { PopupAdapterFormProps } from 'components/shared/PopupWithForm/PopupWithForm.types'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { SelectControl } from 'components/controls'
import { normalizeDictionaryToControl, pickSelectOptions } from 'utils'
import { useDictionariesHelper } from 'hooks'
import { useHistory } from 'react-router'

export const SelectReportForm: React.FC<PopupAdapterFormProps> = React.memo(
    ({ onRequestFinish, id }) => {
        const { assessmentTypeDictionary } = useDictionariesHelper([
            'assessmentTypeDictionary',
        ])

        const history = useHistory()

        const handleFinish = useCallback(
            (values) => {
                history.push(
                    `${ROUTE_NAMES.ASSESSMENT_REPORTS_SETTINGS}/${values.type}`
                )

                onRequestFinish?.()
            },
            [history, onRequestFinish]
        )

        return (
            <Form id={id} onFinish={handleFinish}>
                <Form.Item name="type">
                    <SelectControl
                        values={pickSelectOptions(
                            normalizeDictionaryToControl(
                                assessmentTypeDictionary
                            ),
                            [
                                AssessmentType.Estimate_360,
                                AssessmentType.EstimateByManager,
                            ]
                        )}
                    />
                </Form.Item>
            </Form>
        )
    }
)
