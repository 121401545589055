import { FormListFieldData } from 'antd/lib/form/FormList'
import { WithFormInstanceProps } from 'App.types'

/** Обработчик изменения сущности в поле "Материал" */
export const handleChangeMaterial = (
    stage: FormListFieldData,
    form: WithFormInstanceProps['form']
) => () => {
    form?.setFields([
        {
            name: ['stages', stage.name, 'activityId'],
            value: null,
        },
    ])
}
