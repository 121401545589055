import classNames from 'classnames'
import differenceWith from 'lodash/differenceWith'
import sortBy from 'lodash/sortBy'
import React, { FC, useCallback } from 'react'
import {
    CheckboxControl,
    ControlGroup,
    FormItemAdapter,
    InputControl,
    InputNumberControl,
    SelectControl,
    ShouldUpdateChecker,
    SwitchControl,
    TextAreaControl,
    TextRedactor,
} from 'components/controls'
import { Col, Form, Row, Select, Space } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { QUESTION_TYPES_MAPPED } from 'consts'
import { QuestionType } from 'core/api'
import { Store } from 'antd/lib/form/interface'
import { TooltipAdapter } from 'components/shared'
import {
    disableSelectOptions,
    isFormModeView,
    isQuestionTypeChooseVariant,
    isQuestionTypeCriteriaScale,
    isQuestionTypeFillEmptyText,
    isQuestionnaireTypeAssessment,
    isQuestionnaireTypeQuiz,
    isQuestionnaireTypeTest,
    isQuizTypeEngagement,
    isQuizTypeFeedback,
    isQuizTypeRecommend,
} from 'utils'
import { getTagsFromEditor } from 'components/controls/TextRedactor/TextRedactor.utils'
import { useDictionariesHelper } from 'hooks'

import styles from './QuestionForm.module.scss'
import { AnswersField } from '../AnswersField'
import { CalcForAnswer } from '../CalcForAnswer'
import { CriteriaScaleGrid } from '../CriteriaScaleGrid'
import { MIN_LIMIT_ANSWERS_COUNT, ROW_GUTTER } from './QuestionForm.consts'
import { PastTextAnswersTable } from '../PastTextAnswersTable'
import { PastTextAnswersTableValueProps } from '../PastTextAnswersTable/PastTextAnswersTable.types'
import { QuestionFormProps } from './QuestionForm.types'
import { QuestionVariationValueProps } from '../QuestionVariation/QuestionVariation.types'
import { TEST_TASK_SCORING_DEFAULT } from '../AnswerRow/AnswerRow.const'
import { UploadFileForQuestion } from '../UploadFileForQuestion'
import { cleanQuestionText } from './QuestionForm.utils'
import { getQuestionsListOptions } from '../QuestionsList/QuestionsList.utils'

/**
 * Общий компонент для вопросов конструктора опросного листа
 */
export const QuestionForm: FC<QuestionFormProps> = React.memo(
    ({
        indicators,
        field,
        questionsVisibility,
        toggleSpoilerVisible,
        onDeleteQuestion,
        updateLoader,
        isLoading,
    }) => {
        const { resultEstimateScaleTypesDictionary } = useDictionariesHelper([
            'resultEstimateScaleTypesDictionary',
        ])

        /**
         * Получить доступные для выбора индикаторы
         */
        const getIndicators = useCallback(
            (formSate: Store) => {
                const { quizType, type, questions } = formSate
                const nonAvailableIndicators = questions
                    ?.map((el: QuestionVariationValueProps) => el?.indicatorId)
                    .filter(Boolean)

                const isActiveIndicators =
                    isQuestionnaireTypeTest(type) ||
                    (isQuizTypeEngagement(quizType) &&
                        isQuestionnaireTypeQuiz(type))

                return isActiveIndicators
                    ? indicators
                    : disableSelectOptions(indicators, nonAvailableIndicators)
            },
            [indicators]
        )

        /**
         * Составить список ответов на основе тегов для типа вопроса `FillEmptyText`
         */
        const composeAnswers = useCallback(
            (form) => (editor: HTMLDivElement) => {
                if (!editor) return

                const tags = getTagsFromEditor(editor as HTMLDivElement)
                const answers:
                    | PastTextAnswersTableValueProps[]
                    | undefined = form.getFieldsValue()?.questions[field.name]
                    ?.fillEmptyTextAnswers

                let fillEmptyTextAnswersValue:
                    | PastTextAnswersTableValueProps[]
                    | undefined

                if (!tags?.length) {
                    fillEmptyTextAnswersValue = undefined
                } else if (!answers?.length) {
                    fillEmptyTextAnswersValue = tags?.map((el) => ({
                        id: el,
                        correctAnswers: [''],
                    }))
                } else if (answers.length === tags.length) return
                else if (tags.length > answers.length) {
                    const [newTag] = differenceWith(
                        tags,
                        answers,
                        (firstArrEl, secondArrEl) =>
                            firstArrEl === secondArrEl.id
                    )

                    fillEmptyTextAnswersValue = [...answers]

                    fillEmptyTextAnswersValue.push({
                        id: newTag,
                        correctAnswers: [''],
                    })
                } else {
                    fillEmptyTextAnswersValue = answers.filter((el) =>
                        tags?.includes(el.id!)
                    )
                }

                form?.setFields([
                    {
                        name: ['questions', field.name, 'fillEmptyTextAnswers'],
                        value: fillEmptyTextAnswersValue,
                    },
                ])
            },
            [field.name]
        )

        return (
            <div className={styles.wrapper}>
                <ShouldUpdateChecker
                    fieldPath={[
                        ['questions', field.name],
                        ['randomQuestionsOrder'],
                        ['formMode'],
                        ['type'],
                        ['quizType'],
                        ['assessmentEstimateScaleTypeId'],
                    ]}
                >
                    {(form) => {
                        const { getFieldValue, getFieldsValue } = form

                        const {
                            formMode,
                            type,
                            quizType,
                            assessmentEstimateScaleTypeId,
                            questions,
                            randomQuestionsOrder,
                        } = getFieldsValue()

                        const isMandatoryQuestionDisabled =
                            isQuestionnaireTypeQuiz(type) &&
                            (isQuizTypeFeedback(quizType) ||
                                isQuizTypeRecommend(quizType))

                        const isInactive =
                            getFieldValue([
                                'questions',
                                field.name,
                                'isActive',
                            ]) === false

                        const questionType = getFieldValue([
                            'questions',
                            field.name,
                            'type',
                        ])

                        const pollEstimateScaleTypeId = getFieldValue([
                            'questions',
                            field.name,
                            'pollEstimateScaleTypeId',
                        ])

                        return (
                            <Row gutter={ROW_GUTTER}>
                                <Col xs={16}>
                                    <div className={styles.leftSide}>
                                        <span className={styles.questionNumber}>
                                            {`${field.name + 1}.`}
                                        </span>

                                        <Row gutter={ROW_GUTTER / 2}>
                                            <Col xs={8}>
                                                <ShouldUpdateChecker
                                                    fieldPath={[
                                                        ['assessmentType'],
                                                        ['respondentTypeId'],
                                                    ]}
                                                >
                                                    {({
                                                        getFieldsValue,
                                                        setFieldsValue,
                                                    }) => {
                                                        const isMandatoryQuestion = !isQuestionnaireTypeQuiz(
                                                            type
                                                        )

                                                        const handleChangeType = () => {
                                                            const {
                                                                questions,
                                                            } = getFieldsValue()

                                                            const type =
                                                                questions[
                                                                    field.name
                                                                ]?.type

                                                            const indicatorId =
                                                                questions[
                                                                    field.name
                                                                ]?.indicatorId

                                                            const text = cleanQuestionText(
                                                                questions[
                                                                    field.name
                                                                ]?.text
                                                            )

                                                            const title =
                                                                questions[
                                                                    field.name
                                                                ]?.title

                                                            questions[
                                                                field.name
                                                            ] = {
                                                                type,
                                                                indicatorId,
                                                                text,
                                                                title,
                                                                mandatoryQuestion: isMandatoryQuestion,
                                                                limitAnswersCount: 2,
                                                                isActive: true,
                                                            }

                                                            setFieldsValue({
                                                                questions,
                                                            })
                                                        }

                                                        return (
                                                            <FormItemAdapter
                                                                fieldName={[
                                                                    field.name,
                                                                    'type',
                                                                ]}
                                                                label={
                                                                    LOCAL.LABELS
                                                                        .QUESTION_TYPE
                                                                }
                                                                initialValue={
                                                                    QuestionType.TextQuestion
                                                                }
                                                            >
                                                                <Select
                                                                    options={getQuestionsListOptions(
                                                                        sortBy(
                                                                            QUESTION_TYPES_MAPPED,
                                                                            'label'
                                                                        ),
                                                                        getFieldsValue()
                                                                    )}
                                                                    placeholder={
                                                                        LOCAL
                                                                            .PLACEHOLDERS
                                                                            .SELECT
                                                                    }
                                                                    disabled={
                                                                        isFormModeView(
                                                                            formMode
                                                                        ) ||
                                                                        isInactive
                                                                    }
                                                                    onChange={
                                                                        handleChangeType
                                                                    }
                                                                />
                                                            </FormItemAdapter>
                                                        )
                                                    }}
                                                </ShouldUpdateChecker>

                                                <UploadFileForQuestion
                                                    fieldName={field.name}
                                                    isInactive={isInactive}
                                                    formMode={formMode}
                                                    isLoading={isLoading}
                                                    updateLoader={updateLoader}
                                                />
                                            </Col>

                                            <Col xs={16}>
                                                <FormItemAdapter
                                                    fieldName={[
                                                        field.name,
                                                        'title',
                                                    ]}
                                                    label={
                                                        LOCAL.LABELS
                                                            .QUESTION_TITLE
                                                    }
                                                >
                                                    <InputControl
                                                        formMode={formMode}
                                                        disabled={isInactive}
                                                    />
                                                </FormItemAdapter>

                                                <FormItemAdapter
                                                    fieldName={[
                                                        field.name,
                                                        'text',
                                                    ]}
                                                    label={
                                                        LOCAL.LABELS
                                                            .QUESTION_DESCRIPTION
                                                    }
                                                    required={!isInactive}
                                                >
                                                    {isQuestionTypeFillEmptyText(
                                                        questionType
                                                    ) ? (
                                                        <TextRedactor
                                                            field={field}
                                                            formMode={formMode}
                                                            disabled={
                                                                isInactive
                                                            }
                                                            onChangeCustom={composeAnswers(
                                                                form
                                                            )}
                                                        />
                                                    ) : (
                                                        <TextAreaControl
                                                            formMode={formMode}
                                                            autoSize={{
                                                                minRows: 3,
                                                                maxRows: 6,
                                                            }}
                                                            disabled={
                                                                isInactive
                                                            }
                                                        />
                                                    )}
                                                </FormItemAdapter>
                                            </Col>

                                            {isQuestionTypeCriteriaScale(
                                                questionType
                                            ) &&
                                                isQuestionnaireTypeQuiz(
                                                    type
                                                ) && (
                                                    <Col xs={8}>
                                                        <FormItemAdapter
                                                            fieldName={[
                                                                field.name,
                                                                'pollEstimateScaleTypeId',
                                                            ]}
                                                            label={
                                                                LOCAL.LABELS
                                                                    .SCALE_TYPE
                                                            }
                                                        >
                                                            <SelectControl
                                                                values={
                                                                    resultEstimateScaleTypesDictionary
                                                                }
                                                                disabled={isQuizTypeEngagement(
                                                                    quizType
                                                                )}
                                                                formMode={
                                                                    formMode
                                                                }
                                                            />
                                                        </FormItemAdapter>
                                                    </Col>
                                                )}
                                        </Row>

                                        {isQuestionTypeChooseVariant(
                                            questionType
                                        ) && (
                                            <Form.Item
                                                name={[field.name, 'answers']}
                                            >
                                                <AnswersField
                                                    questionIndex={field.name}
                                                    disabled={isInactive}
                                                />
                                            </Form.Item>
                                        )}

                                        {isQuestionTypeCriteriaScale(
                                            questionType
                                        ) && (
                                            <ShouldUpdateChecker
                                                fieldPath={[
                                                    [
                                                        field.name,
                                                        'pollEstimateScaleTypeId',
                                                    ],
                                                ]}
                                            >
                                                {({ getFieldValue }) =>
                                                    (getFieldValue([
                                                        'questions',
                                                        field.name,
                                                        'pollEstimateScaleTypeId',
                                                    ]) ||
                                                        !isQuestionnaireTypeQuiz(
                                                            type
                                                        )) && (
                                                        <ControlGroup
                                                            noPadding
                                                            title={
                                                                LOCAL.LABELS
                                                                    .ANSWER_VARIANTS
                                                            }
                                                        >
                                                            <CriteriaScaleGrid
                                                                pollEstimateScaleTypeId={
                                                                    pollEstimateScaleTypeId
                                                                }
                                                                assessmentEstimateScaleTypeId={
                                                                    assessmentEstimateScaleTypeId
                                                                }
                                                            />
                                                        </ControlGroup>
                                                    )
                                                }
                                            </ShouldUpdateChecker>
                                        )}

                                        {isQuestionTypeFillEmptyText(
                                            questionType
                                        ) && (
                                            <>
                                                <Form.Item
                                                    noStyle
                                                    name={[
                                                        field.name,
                                                        'calcAndScore',
                                                    ]}
                                                    initialValue={{
                                                        questionScore: isQuestionnaireTypeTest(
                                                            type
                                                        )
                                                            ? TEST_TASK_SCORING_DEFAULT
                                                            : undefined,
                                                    }}
                                                >
                                                    <CalcForAnswer
                                                        field={field}
                                                        disabled={isInactive}
                                                    />
                                                </Form.Item>

                                                <ShouldUpdateChecker
                                                    fieldPath={[
                                                        [
                                                            'questions',
                                                            field.name,
                                                            'text',
                                                        ],
                                                        [
                                                            'questions',
                                                            field.name,
                                                            'calcAndScore',
                                                        ],
                                                    ]}
                                                >
                                                    {({ getFieldsValue }) => (
                                                        <Form.Item
                                                            noStyle
                                                            name={[
                                                                field.name,
                                                                'fillEmptyTextAnswers',
                                                            ]}
                                                        >
                                                            <PastTextAnswersTable
                                                                field={field}
                                                                formState={getFieldsValue()}
                                                                disabled={
                                                                    isInactive
                                                                }
                                                            />
                                                        </Form.Item>
                                                    )}
                                                </ShouldUpdateChecker>
                                            </>
                                        )}
                                    </div>
                                </Col>

                                <Col xs={8} className={styles.rightSide}>
                                    <Row>
                                        <Col xs={16}>
                                            {isQuestionnaireTypeTest(type) && (
                                                <FormItemAdapter
                                                    fieldName={[
                                                        field.name,
                                                        'blockedByQuestionNumber',
                                                    ]}
                                                    label={
                                                        LOCAL.LABELS
                                                            .TASK_PASSING_ORDER
                                                    }
                                                >
                                                    <InputNumberControl
                                                        formMode={formMode}
                                                        fullWidth
                                                        max={questions.length}
                                                        disabled={
                                                            isInactive ||
                                                            randomQuestionsOrder
                                                        }
                                                    />
                                                </FormItemAdapter>
                                            )}
                                        </Col>

                                        <Col xs={8}>
                                            <Space
                                                className={
                                                    styles.questionActions
                                                }
                                            >
                                                <IconsAdapter
                                                    className={classNames(
                                                        styles.spoilerToggle,
                                                        {
                                                            [styles.questionVisible]:
                                                                questionsVisibility?.[
                                                                    field.key
                                                                ],
                                                        }
                                                    )}
                                                    iconType="CustomIconChevron"
                                                    onClick={
                                                        toggleSpoilerVisible
                                                    }
                                                />

                                                {!isFormModeView(formMode) && (
                                                    <IconsAdapter
                                                        iconType="CustomIconTrashAlt"
                                                        onClick={
                                                            onDeleteQuestion
                                                        }
                                                    />
                                                )}
                                            </Space>

                                            <TooltipAdapter
                                                title={
                                                    LOCAL.MESSAGES
                                                        .SWITCH_CONTROLS_QUESTION_ACTIVITY
                                                }
                                            >
                                                <Form.Item
                                                    name={[
                                                        field.name,
                                                        'isActive',
                                                    ]}
                                                    className={
                                                        styles.activeToggle
                                                    }
                                                    initialValue={true}
                                                >
                                                    <SwitchControl
                                                        formMode={formMode}
                                                        className={
                                                            styles.switchControl
                                                        }
                                                    />
                                                </Form.Item>
                                            </TooltipAdapter>
                                        </Col>
                                    </Row>

                                    <ShouldUpdateChecker
                                        fieldPath={['questions']}
                                    >
                                        {({ getFieldsValue }) => {
                                            const formValues = getFieldsValue()

                                            const isHidden =
                                                isQuestionnaireTypeQuiz(type) &&
                                                isQuizTypeFeedback(quizType)

                                            const isIndicatorRequired =
                                                isQuestionnaireTypeAssessment(
                                                    type
                                                ) && !isInactive

                                            return (
                                                !isHidden && (
                                                    <FormItemAdapter
                                                        fieldName={[
                                                            field.name,
                                                            'indicatorId',
                                                        ]}
                                                        label={
                                                            LOCAL.LABELS
                                                                .INDICATOR
                                                        }
                                                        required={
                                                            isIndicatorRequired
                                                        }
                                                    >
                                                        <SelectControl
                                                            values={getIndicators(
                                                                formValues
                                                            )}
                                                            labelInValue
                                                            disabled={
                                                                !indicators.length ||
                                                                isInactive
                                                            }
                                                            formMode={formMode}
                                                        />
                                                    </FormItemAdapter>
                                                )
                                            )
                                        }}
                                    </ShouldUpdateChecker>

                                    <Form.Item
                                        name={[field.name, 'mandatoryQuestion']}
                                        initialValue={
                                            !isMandatoryQuestionDisabled
                                        }
                                    >
                                        <CheckboxControl
                                            formMode={formMode}
                                            disabled={
                                                isInactive ||
                                                isMandatoryQuestionDisabled
                                            }
                                        >
                                            {LOCAL.LABELS.MANDATORY_QUESTION}
                                        </CheckboxControl>
                                    </Form.Item>

                                    {!isQuestionnaireTypeAssessment(type) && (
                                        <Space>
                                            <Form.Item
                                                name={[
                                                    field.name,
                                                    'limitedAnswerTime',
                                                ]}
                                            >
                                                <CheckboxControl
                                                    formMode={formMode}
                                                    disabled={isInactive}
                                                >
                                                    {
                                                        LOCAL.LABELS
                                                            .PASSING_TIME_LIMIT
                                                    }
                                                </CheckboxControl>
                                            </Form.Item>

                                            <Form.Item
                                                name={[
                                                    field.name,
                                                    'answerLimit',
                                                ]}
                                            >
                                                <InputNumberControl
                                                    formMode={formMode}
                                                    min={0}
                                                    withMargin
                                                    disabled={isInactive}
                                                />
                                            </Form.Item>
                                        </Space>
                                    )}

                                    {isQuestionTypeChooseVariant(
                                        questionType
                                    ) && (
                                        <>
                                            <Form.Item
                                                name={[
                                                    field.name,
                                                    'multiChoose',
                                                ]}
                                            >
                                                <CheckboxControl
                                                    formMode={formMode}
                                                    disabled={isInactive}
                                                >
                                                    {
                                                        LOCAL.LABELS
                                                            .ALLOW_MULTIPLE_ANSWER_CHOOSE
                                                    }
                                                </CheckboxControl>
                                            </Form.Item>

                                            <ShouldUpdateChecker
                                                fieldPath={[
                                                    [
                                                        'questions',
                                                        field.name,
                                                        'multiChoose',
                                                    ],
                                                    [
                                                        'questions',
                                                        field.name,
                                                        'answers',
                                                    ],
                                                    [
                                                        'questions',
                                                        field.name,
                                                        'isAnswersCountLimited',
                                                    ],
                                                ]}
                                            >
                                                {({ getFieldsValue }) => {
                                                    const {
                                                        multiChoose,
                                                        answers,
                                                        isAnswersCountLimited,
                                                    } = getFieldsValue().questions[
                                                        field.name
                                                    ]

                                                    const MAX_LIMIT_ANSWERS_COUNT =
                                                        (answers?.length ||
                                                            MIN_LIMIT_ANSWERS_COUNT) <=
                                                        MIN_LIMIT_ANSWERS_COUNT
                                                            ? MIN_LIMIT_ANSWERS_COUNT
                                                            : answers.length

                                                    return (
                                                        <Space>
                                                            <Form.Item
                                                                name={[
                                                                    field.name,
                                                                    'isAnswersCountLimited',
                                                                ]}
                                                            >
                                                                <CheckboxControl
                                                                    formMode={
                                                                        formMode
                                                                    }
                                                                    disabled={
                                                                        !multiChoose ||
                                                                        isInactive
                                                                    }
                                                                >
                                                                    {
                                                                        LOCAL
                                                                            .LABELS
                                                                            .ANSWER_LIMIT_AVAILABLE_FOR_SELECTION
                                                                    }
                                                                </CheckboxControl>
                                                            </Form.Item>

                                                            <Form.Item
                                                                name={[
                                                                    field.name,
                                                                    'limitAnswersCount',
                                                                ]}
                                                            >
                                                                <InputNumberControl
                                                                    disabled={
                                                                        !multiChoose ||
                                                                        !isAnswersCountLimited ||
                                                                        isInactive
                                                                    }
                                                                    min={
                                                                        MIN_LIMIT_ANSWERS_COUNT
                                                                    }
                                                                    max={
                                                                        MAX_LIMIT_ANSWERS_COUNT
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Space>
                                                    )
                                                }}
                                            </ShouldUpdateChecker>

                                            <Form.Item
                                                name={[
                                                    field.name,
                                                    'randomAnswerOrder',
                                                ]}
                                            >
                                                <CheckboxControl
                                                    formMode={formMode}
                                                    disabled={isInactive}
                                                >
                                                    {
                                                        LOCAL.LABELS
                                                            .RANDOM_ANSWER_ORDER
                                                    }
                                                </CheckboxControl>
                                            </Form.Item>

                                            <Form.Item
                                                name={[
                                                    field.name,
                                                    'customAnswer',
                                                ]}
                                            >
                                                <CheckboxControl
                                                    formMode={formMode}
                                                    disabled={isInactive}
                                                >
                                                    {
                                                        LOCAL.LABELS
                                                            .ALLOW_USER_ADD_CUSTOM_ANSWER
                                                    }
                                                </CheckboxControl>
                                            </Form.Item>
                                        </>
                                    )}

                                    {isQuestionTypeCriteriaScale(
                                        questionType
                                    ) &&
                                        !isQuestionnaireTypeQuiz(type) && (
                                            <>
                                                <FormItemAdapter
                                                    fieldName={[
                                                        field.name,
                                                        'isInverseQuestion',
                                                    ]}
                                                >
                                                    <CheckboxControl
                                                        formMode={formMode}
                                                        disabled={isInactive}
                                                    >
                                                        {
                                                            LOCAL.LABELS
                                                                .INVERSION_QUESTION
                                                        }
                                                    </CheckboxControl>
                                                </FormItemAdapter>

                                                <FormItemAdapter
                                                    fieldName={[
                                                        field.name,
                                                        'linkedQuestionNumber',
                                                    ]}
                                                    label={
                                                        LOCAL.LABELS
                                                            .PAIR_QUESTIONS_NUMBER
                                                    }
                                                >
                                                    <InputNumberControl
                                                        formMode={formMode}
                                                        disabled={isInactive}
                                                        fullWidth
                                                        min={0}
                                                    />
                                                </FormItemAdapter>
                                            </>
                                        )}
                                </Col>
                            </Row>
                        )
                    }}
                </ShouldUpdateChecker>
            </div>
        )
    }
)
