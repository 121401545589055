import { UserContext } from 'contexts'
import { useContext } from 'react'

export const usePageSettings = (dictionaryKey?: string) => {
    const { userSettingsData, updatePageSettings } = useContext(UserContext)

    const settings = dictionaryKey
        ? userSettingsData?.pages?.[dictionaryKey]
        : undefined

    return {
        settings,
        filters: settings?.filters,
        saveFilters: settings?.filters?.formValues?.saveFilters,
        columnsVisible: settings?.columnsVisible,
        pagination: settings?.pagination,
        updatePageSettings,
    }
}
