import React, { ReactNode } from 'react'
import { FeedbackQuizReportTitleContract } from 'core/api'
import { LOCAL } from 'core/local'
import { Store } from 'antd/lib/form/interface'
import { Title } from 'components/shared'
import { getPageTitleFromRoute, secondCountToTimeString } from 'utils'
import { template } from 'lodash'

import { QuizReportType } from './QuizReport.types'

/**
 * Формируем общую информацию по отчету
 * @param commonStatistics - сырые данные
 * @return string - текст с общей статистикой отчета
 */
export const getCommonStatisticsInfo = (
    commonStatistics?: FeedbackQuizReportTitleContract
): ReactNode | undefined => {
    if (!commonStatistics) return

    return (
        <div>
            <Title type="h5" text={commonStatistics.title} bottomIndent="10" />

            <div>
                {`${template(LOCAL.MESSAGES.QUIZ_PASSED_EMPLOYEES_COUNT)({
                    finishedCount: commonStatistics.respondentsFinishedCount,
                    respondentsCount: commonStatistics.respondentsCount,
                })} ${template(LOCAL.MESSAGES.EMPLOYEES_TOOK_PART)({
                    count: commonStatistics.respondentsFinishedPercentage,
                })}`}
            </div>

            <div>
                {`${
                    LOCAL.MESSAGES.QUIZ_PASSING_AVG_TIME
                } ${secondCountToTimeString(
                    commonStatistics.averagePassingTime
                )}.`}
            </div>
        </div>
    )
}

export const getPageTitle = (
    currentTab: QuizReportType,
    urlParams: Store,
    pathName: string
) => {
    switch (currentTab) {
        case 'freeAnswers':
            return LOCAL.CATALOGS.QUIZ_REPORT_FREE_ANSWERS

        case 'charts':
            return getPageTitleFromRoute(pathName, urlParams)

        case 'spentTime':
            return LOCAL.CATALOGS.SPENT_TIME_FOR_QUIZ_PASSING

        default:
            return null
    }
}
