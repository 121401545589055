import { PICTURE_VALIDATE_FORMATS } from 'consts/form.consts'

/**
 * Для размера файла
 * @param size - размер файла в байтах
 * @param limit - ограничение размера в МегаБайтах, по умолчанию 1
 */
export const isSizeLess = (size: number, limit: number = 1): boolean =>
    size / 1024 / 1024 < limit

/**
 * Для размера файла с допуском файла равного размера
 * @param size - размер файла в байтах
 * @param limit - ограничение размера в МегаБайтах, по умолчанию 1
 */
export const isSizeLessOrEqual = (size: number, limit: number = 1): boolean =>
    size / 1024 / 1024 <= limit

/**
 * Для формата файла
 * @param type - тип проверяемого файла
 * @param validationTypes - массив типов для сравнения, по умолчанию ['image/jpeg', 'image/png']
 */
export const isValidTypeFile = (
    type?: string,
    validationTypes: string[] = PICTURE_VALIDATE_FORMATS
): boolean => !!validationTypes.filter((el) => el === type).length
