import isNil from 'lodash/isNil'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { ActivityLessonPassingService } from 'core/api'
import { BlockPassingModal } from 'components/pages/Events/EventPassing/components'
import {
    FinishActivityModal,
    QuestionnaireForm,
    QuestionnaireModal,
    VideoContentModal,
} from 'components/shared'
import {
    checkAllRequiredBlocksPassed,
    isActivityLessonCompletedSuccessfully,
    isActivityLessonInProgress,
    isActivityLessonWaiting,
    isThematicBlockStatusCompleted,
    isThemeBlockTypeQuestionnaire,
    isThemeBlockTypeVideoCourse,
    isThemeBlockTypeWebConferenceOrFaceToFace,
} from 'utils'
import { useElementVisible } from 'hooks'
import { useParams } from 'react-router-dom'

import { AdaptationTrackReadyParams } from '../../TrackPreviewPassing.types'
import { CanvasProps } from './ActivityLayer.types'
import { TrackPassingContext } from '../../TrackPassing.context'
import {
    isEnoughAttemptCountForThematicBlock,
    isThematicBlockAvailableForRepassing,
} from './ActivityLayer.utils'

export const useCanvas = ({
    activity,
    activeBlock,
    thematicBlockInProgress,
    parentLessonIdForPassing,
    isThematicBlockStarted,
    onStartThematicBlock,
    onFinishThematicBlock,
    onCloseNestedActivity,
    goBackToTrackLayer,
    themeBlockByContentId,
    onExitThematicBlock,
    isTrackWithVisualisation,
    switchViewToNextStage,
    currentStagesId,
    currentStageNumber,
    trackPassingId,
}: CanvasProps) => {
    const { store } = useContext(TrackPassingContext)
    const urlParams = useParams<AdaptationTrackReadyParams>()
    const {
        handleHideElement: hideQuestionnaireForm,
        handleShowElement: showQuestionnaireForm,
        elementVisible: questionnaireFormVisible,
    } = useElementVisible()

    const {
        handleHideElement: hideFinishActivityModal,
        handleShowElement: showFinishActivityModal,
        elementVisible: isShowFinishActivityModal,
    } = useElementVisible()

    const { activityLessonPassings, activityId } = activity
    const activeThematicBlock = activityLessonPassings[activeBlock.index]

    const isThematicBlockCompletedAndNotAvailableForRepassing =
        isThematicBlockStatusCompleted(activeThematicBlock?.status) &&
        !isThematicBlockAvailableForRepassing(activeThematicBlock)

    const isThematicBlockLock = isNil(activeThematicBlock?.status)

    const isAllRequiredBlocksCompleted = checkAllRequiredBlocksPassed(
        activityLessonPassings
    )

    const showFurtherButton =
        !isTrackWithVisualisation && isAllRequiredBlocksCompleted

    const showReturnButton =
        isTrackWithVisualisation ||
        (!isAllRequiredBlocksCompleted && parentLessonIdForPassing)

    const handleStartThematicBlock = useCallback(() => {
        if (!activeThematicBlock) return

        const isStart =
            isActivityLessonWaiting(activeThematicBlock.status) ||
            isActivityLessonInProgress(activeThematicBlock.status)

        onStartThematicBlock(
            themeBlockByContentId || activeThematicBlock.lessonId,
            !isStart
        ).then((lesson) => {
            if (isThemeBlockTypeQuestionnaire(lesson?.type)) {
                showQuestionnaireForm()
            }
        })
    }, [
        activeThematicBlock,
        onStartThematicBlock,
        showQuestionnaireForm,
        themeBlockByContentId,
    ])

    const handleViewMaterial = useCallback(async () => {
        if (thematicBlockInProgress) {
            try {
                store.updateLoader(true)

                await ActivityLessonPassingService.finish({
                    activityLessonPassingId: thematicBlockInProgress.id,
                })

                onFinishThematicBlock()
            } catch (e) {
                console.log(e)
            } finally {
                store.updateLoader(false)
            }
        }
    }, [thematicBlockInProgress, store, onFinishThematicBlock])

    /**
     * Обработчик завершения мероприятия (необходим только для вложенных мероприятий, т.к. при завершении последнего
     * тематического блока корневого мероприятия весь этап трека переходит в состояние `passed`)
     */
    const finishActivity = useCallback(async () => {
        try {
            store.updateLoader(true)

            if (
                !isActivityLessonCompletedSuccessfully(activity.status) &&
                isAllRequiredBlocksCompleted &&
                parentLessonIdForPassing
            ) {
                await ActivityLessonPassingService.finish({
                    activityLessonPassingId: parentLessonIdForPassing,
                })
            }

            if (parentLessonIdForPassing) {
                onCloseNestedActivity?.()
            } else {
                goBackToTrackLayer()
            }

            if (urlParams.action === 'view') {
                switchViewToNextStage?.()
            }
        } catch (error) {
            console.error(error)
        } finally {
            store.updateLoader(false)
        }
    }, [
        activity.status,
        goBackToTrackLayer,
        isAllRequiredBlocksCompleted,
        onCloseNestedActivity,
        parentLessonIdForPassing,
        store,
        switchViewToNextStage,
        urlParams.action,
    ])

    const initialValues = useMemo(
        () =>
            !!thematicBlockInProgress?.questions?.length &&
            isEnoughAttemptCountForThematicBlock(thematicBlockInProgress) && {
                questions: thematicBlockInProgress.questions,
                id: thematicBlockInProgress.id,
                name: thematicBlockInProgress.questionnaireName,
                currentQuestionNumber:
                    thematicBlockInProgress.currentQuestionNumber,
                type: thematicBlockInProgress.questionnaireType,
            },
        [thematicBlockInProgress]
    )

    /* TODO: объединить логику условного рендеринга для meetingModal и questionnaireForm */
    const questionnaireForm =
        questionnaireFormVisible && initialValues ? (
            <QuestionnaireModal
                initialValues={initialValues}
                onFinishThematicBlock={onFinishThematicBlock}
                onClose={hideQuestionnaireForm}
                componentWrapper={QuestionnaireForm}
            />
        ) : null

    const meetingModal = thematicBlockInProgress &&
        isThematicBlockStarted &&
        isThemeBlockTypeWebConferenceOrFaceToFace(
            thematicBlockInProgress.type
        ) && (
            <BlockPassingModal
                popupProps={{
                    visible: isThematicBlockStarted,
                    onCancel: onFinishThematicBlock,
                    onOk: handleViewMaterial,
                }}
                lesson={thematicBlockInProgress}
            />
        )

    const videoModal = thematicBlockInProgress &&
        isThematicBlockStarted &&
        isThemeBlockTypeVideoCourse(thematicBlockInProgress) && (
            <VideoContentModal
                popupProps={{
                    visible: isThematicBlockStarted,
                }}
                fileUrl={thematicBlockInProgress.course.launchUrl}
                progress={thematicBlockInProgress.progress}
                onFinish={handleViewMaterial}
                onExit={onExitThematicBlock}
            />
        )

    const finishEventModal = activityId &&
        isAllRequiredBlocksCompleted &&
        !isActivityLessonCompletedSuccessfully(activity.status) && (
            <FinishActivityModal
                visible={isShowFinishActivityModal}
                closeModal={hideFinishActivityModal}
                openModal={showFinishActivityModal}
                activityId={activityId}
                trackStagePassingId={currentStagesId}
                currentStageNumber={currentStageNumber}
                finishActivity={
                    !!parentLessonIdForPassing
                        ? finishActivity
                        : goBackToTrackLayer
                }
                isNestedActivity={!!parentLessonIdForPassing}
                trackPassingId={trackPassingId}
                eventName={activity?.name}
                activityLessons={activityLessonPassings}
            />
        )

    useEffect(() => {
        if (themeBlockByContentId) {
            handleStartThematicBlock()
        }
    }, [themeBlockByContentId, handleStartThematicBlock])

    return {
        questionnaireForm,
        activeThematicBlock,
        isThematicBlockLock,
        isAllRequiredBlocksCompleted,
        isThematicBlockCompletedAndNotAvailableForRepassing,
        finishActivity,
        showQuestionnaireForm,
        handleStartThematicBlock,
        meetingModal,
        videoModal,
        showFurtherButton,
        finishEventModal,
        handleFurtherBtnClick: showFinishActivityModal,
        showReturnButton,
        goBackToTrackLayer,
    }
}
