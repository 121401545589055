import { DATE_FORMAT } from 'consts'
import { LOCAL } from 'core/local'
import { UserStaffStatusTypeRegistryContract } from 'core/api'
import { getDateByFormat } from 'utils'

export const getStaffStatusDateRange = (
    staffStatusType: UserStaffStatusTypeRegistryContract
) => {
    const startDate = getDateByFormat(
        staffStatusType.staffStatusStartDate,
        DATE_FORMAT.DATE
    )

    const endDate = getDateByFormat(
        staffStatusType.staffStatusEndDate,
        DATE_FORMAT.DATE
    )

    const dateArr = [
        startDate ? `${LOCAL.SINCE} ${startDate}` : '',
        endDate ? ` ${LOCAL.TO} ${endDate}` : '',
    ]

    return dateArr.join('')
}
