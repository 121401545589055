import React from 'react'
import { Form } from 'antd'
import { ShouldUpdateChecker, TextAreaControl } from 'components/controls'
import { addNameToNamePath } from 'utils'
import { usePreventPast } from 'hooks'

import { CLASS_NAME, COUNT_ROWS, MAX_LENGTH } from './TextQuestion.consts'
import { QuestionComponentProps } from '../PassingCompetenceQuestion/PassingCompetenceQuestion.types'

/** Секция ответа типа "Текстовый ответ" */
export const TextQuestion: React.FC<QuestionComponentProps> = React.memo(
    ({ name }) => {
        usePreventPast(`.${CLASS_NAME}`)

        return (
            <ShouldUpdateChecker fieldPath={['formMode']}>
                {({ getFieldValue }) => (
                    <Form.Item name={addNameToNamePath(name, 'text')}>
                        <TextAreaControl
                            className={CLASS_NAME}
                            autoSize={{
                                minRows: COUNT_ROWS,
                                maxRows: COUNT_ROWS,
                            }}
                            maxLength={MAX_LENGTH}
                            formMode={getFieldValue('formMode')}
                        />
                    </Form.Item>
                )}
            </ShouldUpdateChecker>
        )
    }
)
