import { FnActionRequiredProps } from 'App.types'

export const LOCAL_STORAGE_KEYS = {
    ASSESSMENT_PORTFOLIO_CREATED: 'ASSESSMENT_PORTFOLIO_CREATED',
    TRACK_PREVIEW: 'TRACK_PREVIEW',
    USER_PAGE_SETTINGS: 'USER_PAGE_SETTINGS',
    COLOR_THEME: 'COLOR_THEME',
}

export class LocalStorageHelper {
    static setItem(key?: string, value?: any) {
        if (!key || !value) return

        if (typeof value === 'object') {
            localStorage.setItem(key, JSON.stringify(value))

            return
        }

        localStorage.setItem(key, value)
    }

    static getItem(key?: string, withoutParse?: boolean) {
        if (!key) return

        const str = localStorage.getItem(key)

        if (!str) return

        if (withoutParse) return str

        try {
            return JSON.parse(str)
        } catch {
            return str
        }
    }

    static removeItem(key?: string) {
        if (!key) return

        localStorage.removeItem(key)
    }

    static registerHandler(handler: FnActionRequiredProps<StorageEvent>) {
        window.addEventListener('storage', handler)
    }

    static unRegisterHandler(handler: FnActionRequiredProps<StorageEvent>) {
        window.removeEventListener('storage', handler)
    }
}
