import React, { FC, useContext } from 'react'
import { Col, Row } from 'antd'
import { CollapseSingle } from 'components/shared/CollapseSingle'
import { ComponentCommonProps } from 'App.types'
import { FormItemAdapter, TextAreaControl } from 'components/controls'
import { LOCAL } from 'core/local'
import { PortfolioFormCommonTabsContext } from 'components/shared/PortfolioFormCommonTabs/PortfolioFormCommonTabs.context'
import { ROW_GUTTER } from 'consts'
import { isFormModeCopy } from 'utils'

import { PRESENTATION_FORM_FIELD_NAME } from '../../PortfolioPresentation.consts'
import { PresentationUploadImageControl } from '../PresentationUploadImageControl'
import { WindowTextSettings } from '../WindowTextSettings'

/** Блок настроек оформления для портфелей - блок финальное окно */
export const PortfolioFinalWindow: FC<ComponentCommonProps> = React.memo(() => {
    const { store } = useContext(PortfolioFormCommonTabsContext)

    const { formMode, initialValuesForEdit, disabledByStatus, form } = store

    const initialPictureName = {
        name: isFormModeCopy(formMode)
            ? form?.getFieldValue('presentation')?.finalWindow?.picture
                  ?.fileName
            : initialValuesForEdit?.presentation?.finalWindow?.picture
                  ?.fileName,
    }

    return (
        <CollapseSingle title={LOCAL.LABELS.FINAl_WINDOW}>
            <Row gutter={ROW_GUTTER}>
                <Col xs={8}>
                    <FormItemAdapter
                        fieldName={[
                            PRESENTATION_FORM_FIELD_NAME,
                            'finalWindow',
                            'title',
                        ]}
                        label={LOCAL.LABELS.TITLE_TEXT}
                    >
                        <TextAreaControl
                            autoSize={{ minRows: 4 }}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </FormItemAdapter>

                    <FormItemAdapter
                        fieldName={[
                            PRESENTATION_FORM_FIELD_NAME,
                            'finalWindow',
                            'message',
                        ]}
                        label={LOCAL.LABELS.FINAL_APPEAL_TEXT}
                        required
                    >
                        <TextAreaControl
                            autoSize={{ minRows: 4 }}
                            formMode={formMode}
                            disabled={disabledByStatus}
                        />
                    </FormItemAdapter>
                </Col>

                <Col
                    xs={{
                        span: 8,
                    }}
                    xxl={{
                        span: 7,
                        offset: 1,
                    }}
                >
                    <WindowTextSettings
                        type="titleTextSettings"
                        windowFormFieldName="finalWindow"
                        formMode={formMode}
                        disabled={disabledByStatus}
                    />

                    <WindowTextSettings
                        type="messageTextSettings"
                        windowFormFieldName="finalWindow"
                        formMode={formMode}
                        disabled={disabledByStatus}
                    />
                </Col>

                <Col
                    xs={{
                        span: 8,
                    }}
                    xxl={{
                        span: 7,
                        offset: 1,
                    }}
                >
                    <PresentationUploadImageControl
                        name={[
                            PRESENTATION_FORM_FIELD_NAME,
                            'finalWindow',
                            'pictureId',
                        ]}
                        initialPictureName={initialPictureName}
                    />
                </Col>
            </Row>
        </CollapseSingle>
    )
})
