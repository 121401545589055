import React from 'react'
import cn from 'classnames'

import styles from './FullScreenSingleBlock.module.scss'
import { SingleBlockProps } from '../../ActivityLayer.types'
import { getBlockBody, getBlocksStyle } from '../../ActivityLayer.utils'

/**
 * Отображение для конретного блока, для мероприятия типа - "fullscreen"
 */
export const FullScreenSingleBlock: React.FC<SingleBlockProps> = React.memo(
    ({ canvas, blockIndex, blocksList, activeBlock, onSetActiveBlock }) => {
        const block = blocksList[blockIndex]

        const isActiveBlock = activeBlock.index === blockIndex

        return (
            <div
                style={getBlocksStyle(canvas, block.status)}
                className={cn(styles.wrapper, isActiveBlock && styles.active)}
                onClick={onSetActiveBlock({ index: blockIndex })}
            >
                {getBlockBody(canvas, block.status)}
            </div>
        )
    }
)
