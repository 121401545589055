import React, { useCallback, useEffect, useMemo } from 'react'
import { ASSESSMENT_PORTFOLIOS_CONDITIONAL_PERMISSIONS } from 'consts'
import {
    AssessmentPortfolioContract,
    AssessmentPortfoliosService,
    EntityType,
    UserPermission,
} from 'core/api'
import { Button } from 'components/shared/Button'
import {
    ButtonsToolbar,
    ContentTitleWithBackLink,
    PageContent,
} from 'components/shared'
import { ComponentCommonProps } from 'App.types'
import { ErrorPage } from 'components/pages/utilityPages'
import { FORM_IDS } from 'core/configs'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import {
    WithBlockUserRoutingComponentProps,
    withBlockUserRouting,
    withLoader,
} from 'HOCs'
import {
    editSessionCancel,
    isDocumentStatusInWork,
    isDocumentStatusReadyForWork,
    isFormModeCopy,
    isFormModeEdit,
    isFormModeView,
} from 'utils'
import { renderAccessDeniedButton } from 'core/permissions/HasPermissions/HasPermissions.utils'
import { useFormInitialValues, useSaveOrPublishState } from 'hooks'
import { useParams } from 'react-router-dom'

import { AssessmentTemplateForm } from './components'
import { UrlProps } from './AssessmentPortfolioFormContainer.types'
import { isAssessmentTypeValid } from './AssessmentPortfolioFormContainer.utils'

/**
 * Реестр шаблонов портфеля оценки: создание портфеля оценки
 */
const AssessmentPortfolioFormContainer: React.FC<
    WithBlockUserRoutingComponentProps & ComponentCommonProps
> = withLoader(({ onBlockUserRouting, formMode, updateLoader, isLoading }) => {
    const { id, assessmentType } = useParams<UrlProps>()

    const { isSaveDocument, handleSubmitForm } = useSaveOrPublishState()

    const {
        initialValuesForEdit,
        fetchInitialFormValuesById,
    } = useFormInitialValues<AssessmentPortfolioContract>({
        requestMethod: isFormModeEdit(formMode)
            ? AssessmentPortfoliosService.getForEdit
            : AssessmentPortfoliosService.getActive,
        updateLoader,
    })

    const isDisabledActionsButtons = isFormModeView(formMode) || isLoading

    const refuseEdit = useCallback(() => {
        editSessionCancel({
            type: EntityType.AssessmentPortfolio,
            id: initialValuesForEdit?.id,
        })
    }, [initialValuesForEdit])

    const isSavable = useMemo(
        () => !isDocumentStatusInWork(initialValuesForEdit?.documentStatus),
        [initialValuesForEdit]
    )

    useEffect(() => {
        if (id) fetchInitialFormValuesById(Number(id))
    }, [fetchInitialFormValuesById, id])

    return assessmentType && !isAssessmentTypeValid(assessmentType) ? (
        <ErrorPage errorCode="404" />
    ) : (
        <PageContent>
            <ContentTitleWithBackLink
                status={initialValuesForEdit?.documentStatus}
                onBack={refuseEdit}
            >
                <ButtonsToolbar>
                    <Button
                        type="link"
                        href={ROUTE_NAMES.ASSESSMENT_PORTFOLIOS}
                        onClick={refuseEdit}
                    >
                        {LOCAL.ACTIONS.CANCEL}
                    </Button>

                    {isSavable && (
                        <Button
                            form={FORM_IDS.ASSESSMENT_TEMPLATE_FORM}
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmitForm(true)}
                            disabled={
                                (isDocumentStatusReadyForWork(
                                    initialValuesForEdit?.documentStatus
                                ) &&
                                    !isFormModeCopy(formMode)) ||
                                isDisabledActionsButtons
                            }
                        >
                            {LOCAL.ACTIONS.SAVE}
                        </Button>
                    )}

                    <HasPermissions
                        requiredPermissions={[
                            UserPermission.AssessmentPortfoliosRead,
                            UserPermission.AssessmentPortfolioPublish,
                        ]}
                        conditionalPermissions={
                            ASSESSMENT_PORTFOLIOS_CONDITIONAL_PERMISSIONS
                        }
                        accessDeniedRender={renderAccessDeniedButton({
                            text: LOCAL.ACTIONS.PUBLISH,
                            type: 'primary',
                        })}
                    >
                        <Button
                            form={FORM_IDS.ASSESSMENT_TEMPLATE_FORM}
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmitForm(false)}
                            disabled={isDisabledActionsButtons}
                        >
                            {LOCAL.ACTIONS.PUBLISH}
                        </Button>
                    </HasPermissions>
                </ButtonsToolbar>
            </ContentTitleWithBackLink>

            <AssessmentTemplateForm
                updateLoader={updateLoader}
                onBlockUserRouting={onBlockUserRouting}
                initialValuesForEdit={initialValuesForEdit}
                isSaveDocument={isSaveDocument}
                reFetchInitialFormValues={fetchInitialFormValuesById}
                formMode={formMode}
            />
        </PageContent>
    )
})

export default React.memo(
    withBlockUserRouting(AssessmentPortfolioFormContainer, false)
) as typeof AssessmentPortfolioFormContainer
