import React from 'react'
import { AddButton } from 'components/shared/AddButton'
import { ButtonsToolbar } from 'components/shared/ButtonsToolbar'
import { FormListOperation } from 'antd/lib/form/FormList'
import { LOCAL } from 'core/local'
import { LessonType } from 'core/api'
import { THEME_BLOCK_TYPE_GENETIVE } from 'consts'
import { WithFormModeProps } from 'App.types'
import { isFormModeView } from 'utils'

import styles from './EventFormStructure.module.scss'

/**
 * Формируем текст кнопки в зависимости от типа тематического блока
 * @param type тип тематического блока
 * @return текст кнопки
 */
export const getThemeBlockButtonText = (type: LessonType): string =>
    `${LOCAL.ACTIONS.ADD} ${THEME_BLOCK_TYPE_GENETIVE[type]}`

/**
 * Получить кнопку для добавления тематического блока
 * @param themeBlockType тип тематического блока
 * @param addFn колбек добавления тематического блока
 * @param formMode режим формы
 * @param disabled доступность кнопки
 * @return кнопку для добавления тематического блока определенного типа
 */
const getStructureButton = (
    themeBlockType: LessonType,
    { addFn, formMode, disabled }: GetStructureActionButtonsProps
): React.ReactNode => (
    <AddButton
        buttonText={getThemeBlockButtonText(themeBlockType)}
        onClick={() => addFn({ type: themeBlockType, isRequired: true })}
        disabled={isFormModeView(formMode) || disabled}
    />
)

interface GetStructureActionButtonsProps {
    addFn: FormListOperation['add']
    formMode?: WithFormModeProps['formMode']
    disabled?: boolean
}

/**
 * Получить кнопки для добавления тематического блока
 * @return группу кнопок для добавления тематических блоков
 * @param params общие параметры кнопок
 */
export const getStructureActionButtons = (
    params: GetStructureActionButtonsProps
): React.ReactNode => (
    <ButtonsToolbar align="flex-start" className={styles.structureButtons}>
        {getStructureButton(LessonType.Course, params)}
        {getStructureButton(LessonType.Activity, params)}
        {getStructureButton(LessonType.Questionnaire, params)}
        {getStructureButton(LessonType.FaceToFaceMeeting, params)}
        {getStructureButton(LessonType.WebConference, params)}
        {getStructureButton(LessonType.Homework, params)}
        {getStructureButton(LessonType.Information, params)}{' '}
    </ButtonsToolbar>
)
