import React, { FC, useCallback, useReducer } from 'react'
import { CKEditorAdapter, TooltipAdapter } from 'components/shared'
import {
    CheckboxControl,
    FormItemAdapter,
    HiddenField,
    InputControl,
} from 'components/controls'
import { DEFAULT_STYLES } from 'components/shared/CKEditorAdapter/CKEditorAdapter.config'
import { FORM_IDS } from 'core/configs'
import { Form } from 'antd'
import { FormProps } from 'components/forms/forms.types'
import { LOCAL } from 'core/local'
import { MissionContract, MissionSaveContract, MissionsService } from 'core/api'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { WithLoaderProps } from 'HOCs'
import {
    createConfirmPopup,
    getFormPopupTitle,
    isFormModeEdit,
    isFormModeView,
    isObjectEmpty,
} from 'utils'
import { editorReducer } from 'components/shared/CKEditorAdapter/CKEditorAdapter.utils'
import { getRouteMeta } from 'routing/routeNames.utils'
import { useHistory } from 'react-router-dom'

import styles from './MaterialsTaskForm.module.scss'
import { MaterialsTaskFields } from '../MaterialsTaskFields'
import { MaterialsTaskFormProps } from './MaterialsTaskForm.types'
import {
    TASK_DESCRIPTION_EXTRA_PLUGINS,
    TASK_DESCRIPTION_TOOLBAR_OPTION,
} from './MaterialsTaskForm.config'

/**
 * Форма добавления/редактирования задания
 */
export const MaterialsTaskForm: FC<
    FormProps<MaterialsTaskFormProps> & WithLoaderProps
> = React.memo(
    ({
        initialValuesForEdit,
        onBlockUserRouting,
        reFetchInitialFormValues,
        formMode,
        updateLoader,
    }) => {
        const history = useHistory()
        const [form] = Form.useForm<MissionContract>()

        const [, dispatch] = useReducer(editorReducer, {
            styles: DEFAULT_STYLES,
        })

        const isEditMode = isFormModeEdit(formMode)

        const handleFinish = useCallback(
            async (values: MissionSaveContract) => {
                try {
                    updateLoader(true)

                    await MissionsService.saveMission({ body: values })

                    onBlockUserRouting?.(false)

                    createConfirmPopup({
                        title: getFormPopupTitle(
                            getRouteMeta(ROUTE_NAMES.TASKS, 'pageTitle')
                        ),
                        content: isEditMode
                            ? LOCAL.MESSAGES.TASKS_UPDATE_SUCCESS_MESSAGE
                            : LOCAL.MESSAGES.TASKS_CREATE_SUCCESS_MESSAGE,
                        onOk: () => {
                            history.push(ROUTE_NAMES.TASKS)
                        },
                        onCancel: () => {
                            if (initialValuesForEdit?.id)
                                reFetchInitialFormValues?.(
                                    initialValuesForEdit?.id
                                )
                            else {
                                form.resetFields()
                            }
                        },
                    })
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [
                updateLoader,
                onBlockUserRouting,
                isEditMode,
                history,
                initialValuesForEdit,
                reFetchInitialFormValues,
                form,
            ]
        )

        /** Обработчик изменения формы */
        const handleValuesChange = useCallback(() => {
            if (form.isFieldsTouched() && !isFormModeView(formMode)) {
                onBlockUserRouting?.()
            }
        }, [form, formMode, onBlockUserRouting])

        if (isObjectEmpty(initialValuesForEdit)) return null

        return (
            <Form
                id={FORM_IDS.MATERIALS_TASK_FORM}
                form={form}
                onFinish={handleFinish}
                onValuesChange={handleValuesChange}
                initialValues={initialValuesForEdit}
            >
                <FormItemAdapter
                    fieldName="name"
                    label={LOCAL.LABELS.TASK_NAME}
                    className={styles.taskField}
                >
                    <InputControl formMode={formMode} />
                </FormItemAdapter>

                <Form.Item
                    name="content"
                    label={LOCAL.LABELS.TASK_DESCRIPTION}
                    className={styles.contentEditor}
                >
                    <CKEditorAdapter
                        dispatchEvent={dispatch}
                        height="30vh"
                        toolbar={TASK_DESCRIPTION_TOOLBAR_OPTION}
                        extraPlugins={TASK_DESCRIPTION_EXTRA_PLUGINS}
                    />
                </Form.Item>

                <TooltipAdapter
                    title={LOCAL.LABELS.SHOW_TASK_TITLE_ON_ROAD_MAP}
                >
                    <Form.Item name="isTasksTitlesVisible" className="mt-15">
                        <CheckboxControl formMode={formMode}>
                            {LOCAL.LABELS.SHOW_NUM_AND_TASK_TITLE}
                        </CheckboxControl>
                    </Form.Item>
                </TooltipAdapter>

                <Form.List name="missionTasks">
                    {(fields, { add, remove }) => (
                        <MaterialsTaskFields
                            dispatch={dispatch}
                            add={add}
                            remove={remove}
                            formMode={formMode}
                            fields={fields}
                        />
                    )}
                </Form.List>

                <HiddenField fieldName="id" />
            </Form>
        )
    }
)
