export const API_BASE_URL = process.env.REACT_APP_API_URL || ''

export const APP_URL = process.env.REACT_APP_URL

export const AUTH_BASE_URL =
    process.env.REACT_APP_AUTH_URL || process.env.REACT_APP_API_URL || ''

export const LOGIN_URL = `${AUTH_BASE_URL}/auth/login`
export const LOGOUT_URL = `${AUTH_BASE_URL}/auth/logout`
export const DISABLE_AUTH =
    process.env.REACT_APP_DISABLE_AUTH === 'true' || false

export const USER_ID = process.env.REACT_APP_USER_ID || ''
export const USER_PERMISSIONS = process.env.REACT_APP_USER_PERMISSIONS || ''
export const SIGNALR_URL = process.env.REACT_APP_SIGNALR_URL || ''

export const IS_DEV = process.env.NODE_ENV === 'development'
