import React, { useEffect } from 'react'
import {
    AssessmentReportManagerService,
    ManagerReportObjectInformationContract,
} from 'core/api'
import { UrlParamsProps } from 'components/pages/AssessmentReport/AssessmentReport.types'
import { useHttp } from 'hooks'
import { useParams } from 'react-router'

import styles from './ObjectInformation.module.scss'

/** Отчёт по оценке руководителя. Общая информация */
export const ObjectInformation: React.FC = React.memo(() => {
    const { reportId } = useParams<UrlParamsProps>()
    const [getData, data] = useHttp<
        ManagerReportObjectInformationContract,
        { id: number }
    >(AssessmentReportManagerService.getObjectInformation)

    useEffect(() => {
        if (reportId) {
            getData({ id: Number(reportId) })
        }
    }, [getData, reportId])

    return (
        <>
            <div className={styles.position}>{data?.position?.name}</div>

            <div className={styles.candidate}>{data?.candidate?.name}</div>

            <div className={styles.unit}>{data?.unit?.name}</div>

            <div className={styles.organization}>
                {data?.organization?.name}
            </div>
        </>
    )
})
