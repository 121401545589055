import { TestPortfolioContract } from 'core/api'
import {
    generatePictureFromResponse,
    getValue,
    normalizeAttachmentObject,
    normalizeDataForSelectAndRadio,
    secondCountToTimeString,
    timeStringToSecondCount,
} from 'utils'

import {
    GetTimeLimitProps,
    TestPortfolioFormSubmitValues,
} from './TestPortfolioForm.types'

export const mapResponseToFormData = (response: TestPortfolioContract) => ({
    name: response.name,
    attachments: response.attachments?.map((attachment) =>
        normalizeAttachmentObject(attachment)
    ),
    description: response.description,
    organizationsIds: response.organizations?.map(
        normalizeDataForSelectAndRadio
    ),
    forAllOrganizations: response.forAllOrganizations,
    positionIds: response.positions?.map(normalizeDataForSelectAndRadio),
    forAllPositions: response.forAllPositions,
    competenceIds: response.competencies?.map(normalizeDataForSelectAndRadio),
    questionnaires: response.questionnaires?.map((el) => ({
        name: el.questionnaire.name,
        questionnaireId: el.questionnaire.id,
        totalQuestionCount: el.questionnaire.questions?.length,
        countOfRandomQuestions: el.countOfRandomQuestions,
    })),
    timeIsLimited: response.timeIsLimited,
    timeLimit: secondCountToTimeString(response.timeLimit),
    takeRandomQuestions: response.takeRandomQuestions,
    showCorrectAnswer: response.showCorrectAnswer,
    showOverallTestScore: response.showOverallTestScore,
    purposeId: response.purpose?.id,
    testTypeId: response.testType?.id,
    presentation: {
        ...response.presentation,
        showQuestionsByBlock: Number(
            response.presentation?.showQuestionsByBlock
        ),
        finalWindow: {
            ...response.presentation?.finalWindow,
            pictureId: generatePictureFromResponse(
                response.presentation?.finalWindow?.picture
            ),
        },
        welcomeWindow: {
            ...response.presentation?.welcomeWindow,
            pictureId: generatePictureFromResponse(
                response.presentation?.welcomeWindow?.picture
            ),
        },
        passingPictureId: generatePictureFromResponse(
            response.presentation?.passingPicture
        ),
    },
})

export const mapFormDataToRequest = ({
    attachments,
    competenceIds,
    organizationsIds,
    positionIds,
    questionnaires,
    presentation,
    timeLimit,
    ...restValues
}: Partial<TestPortfolioFormSubmitValues>) => ({
    ...restValues,
    timeLimit: timeLimit ? timeStringToSecondCount(timeLimit) : undefined,
    competenceIds: competenceIds?.map(getValue),
    positionIds: positionIds?.map(getValue),
    organizationsIds: organizationsIds?.map(getValue),
    questionnaires: questionnaires?.map((el, index) => ({
        questionnaireId: el.questionnaireId,
        countOfRandomQuestions: el.countOfRandomQuestions,
        order: index + 1,
    })),
    attachmentIds: attachments?.map(({ response }) => response?.id),
    presentation: {
        ...presentation,
        showQuestionsByBlock: !!Number(presentation?.showQuestionsByBlock),
        finalWindow: {
            ...presentation?.finalWindow,
            pictureId: presentation?.finalWindow?.pictureId?.[0]?.response?.id,
        },
        welcomeWindow: {
            ...presentation?.welcomeWindow,
            pictureId:
                presentation?.welcomeWindow?.pictureId?.[0]?.response?.id,
        },
        passingPictureId: presentation?.passingPictureId?.[0]?.response?.id,
    },
})

/**
 * Считаем минимальное допустимое значение времени
 * @param questionnaires опросные листы
 */
export const getTimeLimit = (questionnaires?: GetTimeLimitProps[]) => {
    const totalLimit = questionnaires?.reduce(
        (acc, el) => acc + el.totalAnswerLimit,
        0
    )

    if (!totalLimit) return

    return secondCountToTimeString(totalLimit)
}
