import React from 'react'
import { AddButton } from 'components/shared/AddButton'
import { ComponentCommonProps } from 'App.types'
import { Form } from 'antd'
import { LOCAL } from 'core/local'
import { ShouldUpdateChecker, SwitchControl } from 'components/controls'
import { convertToShouldUpdateDep } from 'utils'
import {
    isProductTypeActivity,
    isProductTypeTrack,
} from 'utils/conditions.utils/product.utils'

import styles from './ProductLearning.module.scss'
import { ProductPeriod } from '../ProductPeriod'
import { SHOULD_UPDATE_DEP } from './ProductLearning.consts'
import { enableStudyingPeriods } from '../ProductCatalogForm/ProductCatalogForm.utils'

/** Блок полей формы создания карточки продукта - обучение */
export const ProductLearning: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <ShouldUpdateChecker
            fieldPath={convertToShouldUpdateDep(SHOULD_UPDATE_DEP)}
        >
            {({ getFieldsValue, resetFields }) => {
                const { type, bookingAvailable } = getFieldsValue(
                    SHOULD_UPDATE_DEP
                )

                return (
                    (isProductTypeActivity(type) ||
                        isProductTypeTrack(type)) && (
                        <div className={styles.wrapper}>
                            <div className={styles.header}>
                                <h3>{LOCAL.LABELS.LEARNING}</h3>

                                <Form.Item name="bookingAvailable">
                                    <SwitchControl
                                        addonAfter={
                                            LOCAL.LABELS
                                                .REGISTRATION_FOR_LEARNING_AVAILABLE
                                        }
                                        formMode={formMode}
                                        onChange={() =>
                                            resetFields(['studyingPeriods'])
                                        }
                                    />
                                </Form.Item>
                            </div>

                            {enableStudyingPeriods(type, bookingAvailable) && (
                                <Form.List name="studyingPeriods">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <ProductPeriod
                                                    key={field.key}
                                                    field={field}
                                                    index={index}
                                                    remove={remove}
                                                    formMode={formMode}
                                                />
                                            ))}

                                            <AddButton
                                                onClick={() => add()}
                                                buttonText={
                                                    LOCAL.ACTIONS.ADD_PERIOD
                                                }
                                                formMode={formMode}
                                            />
                                        </>
                                    )}
                                </Form.List>
                            )}
                        </div>
                    )
                )
            }}
        </ShouldUpdateChecker>
    )
)
