import React, { FC } from 'react'
import { ApproveView, ErrorPage } from 'components/pages'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { Route, Switch } from 'react-router-dom'

export const ApproveRouting: FC = React.memo(() => (
    <Switch>
        <Route path={`${ROUTE_NAMES.APPROVE_VIEW}/:id`}>
            <ApproveView />
        </Route>

        <Route>
            <ErrorPage errorCode="404" />
        </Route>
    </Switch>
))
