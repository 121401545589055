import React from 'react'
import {
    AppointmentActivitiesPassingService,
    getPageTitleFromRoute,
    getRowHandlers,
} from 'utils'
import {
    AppointmentActivitySearchContract,
    AppointmentActivitySearchResultContract,
    AppointmentActivityService,
    UserPermission,
} from 'core/api'
import { DICTIONARY_KEYS } from 'core/configs'
import {
    DictionaryPage,
    DictionaryPageActionsProps,
    ORDER_RULES_WITH_CHANGE_DATE,
} from 'components/layouts'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { getRowClassNameArchive } from 'components/shared'
import { useLocation } from 'react-router-dom'

import { EventsAssignmentsFilters } from './components'
import { getEventsAssignmentsTableColumns } from './EventsAssignments.utils'

/** Реестр назначений мероприятия */
export const EventsAssignments: React.FC = React.memo(() => {
    const location = useLocation()
    const title = getPageTitleFromRoute(location.pathname)

    /**
     * Рендер колонок таблицы
     * @param actions набор функций, берется из DictionaryPage
     */
    const renderTableColumns = ({
        reFetchDictionary,
        updateLoader,
    }: DictionaryPageActionsProps) =>
        getEventsAssignmentsTableColumns(AppointmentActivitiesPassingService, {
            reFetchDictionary,
            updateLoader,
        })

    return (
        <DictionaryPage<
            AppointmentActivitySearchContract,
            AppointmentActivitySearchResultContract
        >
            dictionaryKey={DICTIONARY_KEYS.EVENTS_ASSIGNMENTS}
            searchMethod={AppointmentActivityService.search}
            deleteMethod={AppointmentActivityService.setAsArchive}
            contentTitle={title}
            tableColumnsRender={renderTableColumns}
            createRecordLink={{
                path: ROUTE_NAMES.EVENTS_ASSIGNMENTS_CREATE,
            }}
            filterComponent={EventsAssignmentsFilters}
            tableFiltersOptions={{
                initialValues: {
                    orderRules: ORDER_RULES_WITH_CHANGE_DATE,
                },
            }}
            createPermissions={[UserPermission.AppointmentActivitySave]}
            quickSearchMethod={AppointmentActivityService.searchText}
            rowClassName={getRowClassNameArchive}
            onRow={getRowHandlers({
                url: ROUTE_NAMES.EVENTS_ASSIGNMENTS_VIEW,
            })}
        />
    )
})
