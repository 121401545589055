import cn from 'classnames'
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { LOCAL } from 'core/local'
import { Table } from 'antd'
import { backwardFn } from 'utils'
import { useBreakpoint } from 'hooks'

import styles from './TableAdapter.module.scss'
import {
    DEFAULT_TABLE_SCROLL_WIDTH,
    calcExpandRowMinHeight,
    getScrollSize,
} from './TableAdapter.utils'
import { TableAdapterProps } from './TableAdapter.types'

/**
 * Адаптер для `Table` от `antd`, принимает все теже `props`
 */
function TableAdapter<T extends object = any>({
    columns,
    wrapperClassName,
    rowKey,
    scroll,
    expandable,
    ...props
}: TableAdapterProps<T>) {
    const tableRef = useRef<HTMLDivElement>(null)
    const [tableHeight, setTableHeight] = useState<number>()
    const isWidthLessXL = useBreakpoint('XL')
    const [expand, setExpand] = useState(false)

    const onExpandedRowsChange = useCallback(() => {
        setExpand(backwardFn)
    }, [])

    /**
     * Схема столбцов для отображения с вычисленной шириной
     */
    const calculatedColumns = useMemo(() => {
        const displayedColumns = columns?.filter(
            (el) => el.className !== 'column-hide'
        )

        if (!displayedColumns) return

        const columnsWidth = displayedColumns.reduce((acc, el) => {
            if (el.title) return acc + Number(el.width || 0)

            return acc
        }, 0)

        if (columnsWidth < DEFAULT_TABLE_SCROLL_WIDTH) {
            return displayedColumns.map((el) => ({
                ...el,
                width: (
                    DEFAULT_TABLE_SCROLL_WIDTH / displayedColumns.length
                ).toFixed(0),
            }))
        }

        return displayedColumns
    }, [columns])

    useLayoutEffect(() => {
        const handleWindowResize = () =>
            setTableHeight(getScrollSize(tableRef.current))

        setTableHeight(getScrollSize(tableRef.current))
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    useEffect(() => {
        calcExpandRowMinHeight()

        window.addEventListener('resize', calcExpandRowMinHeight)

        return () => {
            window.addEventListener('resize', calcExpandRowMinHeight)
        }
    }, [props.dataSource, expand])

    return (
        <div ref={tableRef} className={cn(styles.wrapper, wrapperClassName)}>
            <Table
                {...props}
                rowKey={rowKey || 'id'}
                locale={{ emptyText: LOCAL.LABELS.EMPTY_DATA }}
                expandable={{
                    ...expandable,
                    onExpandedRowsChange,
                }}
                columns={
                    isWidthLessXL
                        ? calculatedColumns?.map(({ fixed, ...el }) => el)
                        : calculatedColumns
                }
                scroll={
                    scroll
                        ? {
                              y: scroll?.y ?? tableHeight,
                              x: scroll?.x ?? DEFAULT_TABLE_SCROLL_WIDTH,
                          }
                        : { x: DEFAULT_TABLE_SCROLL_WIDTH }
                }
            />
        </div>
    )
}

export default React.memo(TableAdapter) as typeof TableAdapter
