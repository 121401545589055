import React, { FC, Fragment } from 'react'
import { AssessmentReportTemplateSectionContract } from 'core/api'
import {
    ESTIMATE_360_SHORT_CODES,
    MANAGER_SHORT_CODES,
    ReportShortCodes,
} from 'consts'
import { safelySetInnerHtml } from 'utils'
import { sanitize } from 'dompurify'

import styles from './ReportView.module.scss'
import { SHORT_CODES_COMPONENTS } from './ReportView.config'

/**
 * Компонент контент секции отчета
 */
export const ReportViewContent = (
    sectionTemplate: AssessmentReportTemplateSectionContract
) => {
    const shortCodesArr = Object.keys(SHORT_CODES_COMPONENTS)
    const shortCodesRegex = new RegExp(
        shortCodesArr.map((el) => `(?=\\[${el}\\])|(?<=\\[${el}\\])`).join('|'),
        'gi'
    )

    const cleanHtml = sanitize(sectionTemplate.content)
    const htmlArr = cleanHtml.split(shortCodesRegex)

    return (
        <div
            id={`${sectionTemplate.id}`}
            className={styles.section}
            key={sectionTemplate.id}
        >
            <h1>{sectionTemplate.name}</h1>

            {htmlArr.map((el, i) => {
                const shortcode = el.slice(1, -1) as ReportShortCodes
                if (shortCodesArr.includes(shortcode)) {
                    const Component = SHORT_CODES_COMPONENTS[shortcode] as FC
                    const title = {
                        ...ESTIMATE_360_SHORT_CODES,
                        ...MANAGER_SHORT_CODES,
                    }[shortcode]

                    return (
                        <Fragment key={i}>
                            {title && <h2>{title}</h2>}

                            {Component && <Component />}
                        </Fragment>
                    )
                }

                return (
                    <div
                        dangerouslySetInnerHTML={safelySetInnerHtml(el)}
                        key={i}
                    />
                )
            })}
        </div>
    )
}
