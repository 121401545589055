import React, { useMemo } from 'react'
import { CoworkersFilters, getCoworkersTableColumns } from 'components/controls'
import { DynamicTableAdapter } from 'components/shared'
import {
    UserContract,
    UserModalContract,
    UserModalSearchContract,
    UsersService,
} from 'core/api'

import {
    AddRespondentsTableControlProps,
    RespondentFormContract,
} from './AddRespondentsTableControl.types'
import { RESPONDENTS_CONTROL_TABLE_COLUMNS } from './AddRespondentsTableControl.consts'
import {
    filterRespondentKeys,
    normalizeFetchDataForValue,
} from './AddRespondentsTableControl.utils'

/** Контрол для добавления удаления пользователей, в виде таблицы */
export const AddRespondentsTableControl: React.FC<AddRespondentsTableControlProps> = ({
    tableOptions,
    value: valueProp,
    ...restProps
}) => {
    const value = useMemo(
        () => valueProp?.map((el) => ({ ...el, id: el.userId || el.id })),
        [valueProp]
    )

    return (
        <DynamicTableAdapter<
            UserContract,
            RespondentFormContract,
            UserModalSearchContract,
            UserModalContract
        >
            getEntitiesByIds={UsersService.getByIds}
            searchMethod={UsersService.getForModal}
            modalFilterComponent={CoworkersFilters}
            columnsForSearchModal={getCoworkersTableColumns()}
            normalizeFn={normalizeFetchDataForValue}
            value={value}
            filterKeys={filterRespondentKeys}
            tableOptions={{
                ...tableOptions,
                columns: RESPONDENTS_CONTROL_TABLE_COLUMNS,
            }}
            {...restProps}
        />
    )
}
