import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { LOCAL } from 'core/local'
import { getDynamicColName, secondCountToTimeString } from 'utils'

import styles from './CommonStatsTable.module.scss'
import { CommonStatsCommentModal } from '../CommonStatsCommentModal'
import { QuestionsColumns } from '../TabCommonStats/TabCommonStats.types'

/**
 * Создать колонки таблицы, которые зависят от кол-ва вопросов для проверки
 * @param questions - вопросы для колонок таблиц общей статистики
 * @returns {ColumnsType} - возвращает объекты для создания динамических столбцов таблицы
 */

const makeDynamicColumns = (questions: QuestionsColumns[]): ColumnsType =>
    questions.map((el) => ({
        title: el.data,
        children: [
            {
                title: LOCAL.LABELS.TASK_NUMBER_AND_SCORE,
                dataIndex: getDynamicColName(`${el.data}-scores`, el.number),
                width: '200px',
            },
            {
                title: (
                    <div className={styles.title}>
                        {LOCAL.MESSAGES.APPOINTMENT_RESULTS}
                    </div>
                ),
                dataIndex: getDynamicColName(`${el.data}-result`, el.number),
                align: 'center',
                width: '300px',
            },
        ],
    }))

/**
 * Создание итогового набора столбцов для таблицы общей статистики
 * @param questions - вопросы для колонок таблицы общей статистики
 * @returns {ColumnsType} - возвращает объекты для создания итоговых столбцов таблицы
 */
export const getTableColumns = (questions: QuestionsColumns[] | undefined) => {
    if (!questions) return []

    return [
        {
            dataIndex: 'main',
            title: `${LOCAL.LABELS.ORGANIZATION}/${LOCAL.LABELS.FIO}`,
            width: '400px',
        },
        ...makeDynamicColumns(questions),
        {
            dataIndex: 'timePassing',
            title: LOCAL.LABELS.TIME,
            width: '130px',
            render: secondCountToTimeString,
        },
        {
            dataIndex: 'dateEnd',
            title: LOCAL.LABELS.DATE_FINISH,
            width: '170px',
        },
        {
            dataIndex: 'comment',
            title: LOCAL.LABELS.COMMENT,
            render: (_, record: any) =>
                record.passingFormId && (
                    <CommonStatsCommentModal
                        passingFormId={record.passingFormId}
                        comment={record.comment}
                    />
                ),
            align: 'right',
            width: '130px',
        },
    ] as ColumnsType<object>
}
