import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { DATE_FORMAT } from 'consts'
import { LOCAL } from 'core/local'
import { RecordEntityType, SessionInfoContract } from 'core/api'
import { formatDate, getDateByFormat } from 'utils'

import { MONITORING_ENTITIES_DICTIONAIRY } from './Monitoring.consts'
import { MonitoringTableActions } from './MonitoringTableActions'

export const getTableColumnsConfig = (): ColumnsType<SessionInfoContract> => [
    {
        title: LOCAL.LABELS.USER_FIO,
        dataIndex: 'userFIo',
        key: 'userFIo',
    },
    {
        title: LOCAL.LABELS.SERVICE,
        dataIndex: 'recordEntityType',
        key: 'recordEntityType',
        render: (row: RecordEntityType) => MONITORING_ENTITIES_DICTIONAIRY[row],
    },
    {
        title: LOCAL.LABELS.DATE_START,
        dataIndex: 'sessionStartDateTime',
        key: 'sessionStartDateTime',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.DATE_END,
        dataIndex: 'lastEventDateTime',
        key: 'lastEventDateTime',
        render: formatDate,
    },
    {
        title: LOCAL.LABELS.START_TIME,
        dataIndex: 'sessionStartDateTime',
        key: 'sessionStartDateTime',
        render: (date) => getDateByFormat(date, DATE_FORMAT.HH_mm),
    },
    {
        title: LOCAL.LABELS.END_TIME,
        dataIndex: 'lastEventDateTime',
        key: 'lastEventDateTime',
        render: (date) => getDateByFormat(date, DATE_FORMAT.HH_mm),
    },
    {
        key: 'actions',
        render: (tableRow) => <MonitoringTableActions tableRow={tableRow} />,
    },
]
