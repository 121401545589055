import cn from 'classnames'
import iconsStyles from 'core/styles/icons.module.scss'
import React, { ReactNode, ReactText } from 'react'
import {
    AppointmentStatus,
    AppointmentTrackStatus,
    DictionaryContract,
    DocumentStatus,
    EmailState,
    NotificationDeliveryStatus,
} from 'core/api'
import { Button } from 'components/shared/Button'
import { ColumnsType } from 'antd/lib/table'
import { ElementWithListPopover } from 'components/shared/ElementWithListPopover'
import {
    FnActionProps,
    FnActionRequiredProps,
    WithFormModeProps,
} from 'App.types'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { LOCAL } from 'core/local'
import { Popover } from 'antd'
import { WidthRestrictedElement } from 'components/shared/WidthRestrictedElement'

import {
    APPOINTMENT_STATUS,
    DOCUMENT_STATUSES,
    EMAIL_SEND_STATUSES,
    NOTIFICATION_DELIVERY_STATUSES,
    TRACKS_APPOINTMENT_STATUSES,
} from '../consts'
import { getItemName, renderStringArrayWithSeparator } from './common.utils'
import { isFormModeView } from './conditions.utils'

/** УТИЛИТЫ ДЛЯ РАБОТЫ С ТАБЛИЦАМИ */

/**
 * Интерфейс для параметра tableRow функции `renderDocumentStatus`
 * @param active - признак архивности сущности
 */
interface TableRowProps<S> {
    active: boolean
    status: S
}

/**
 * Рендер статуса для сущностей использующих базовую статусную модель
 * @param _ - статус документа
 * @param row - запись таблицы
 */
export function renderDocumentStatus<T extends TableRowProps<DocumentStatus>>(
    _: DocumentStatus,
    row: T
) {
    return (
        <WidthRestrictedElement
            title={
                row.active
                    ? DOCUMENT_STATUSES[row.status]
                    : DOCUMENT_STATUSES.Archive
            }
        />
    )
}

/**
 * Рендер статуса для сущностей использующих статусную модель треков
 * @param status - статус документа
 * @param tableRow - ряд таблицы
 */
export function renderAppointmentTrackStatus<
    T extends TableRowProps<AppointmentTrackStatus>
>(status: AppointmentTrackStatus, tableRow: T) {
    return (
        <WidthRestrictedElement
            title={
                tableRow.active
                    ? TRACKS_APPOINTMENT_STATUSES[status]
                    : TRACKS_APPOINTMENT_STATUSES.Archive
            }
        />
    )
}

/**
 * Рендер статуса для сущностей использующих статусную модель назначений
 * @param status статус сущности
 */
export const renderAppointmentStatus = (status: AppointmentStatus) =>
    APPOINTMENT_STATUS[status]

/**
 * Рендер статуса сообщения электронной почты
 * @param status статус отправки сообщения
 */
export const renderSendEmailStatus = (status: EmailState) =>
    EMAIL_SEND_STATUSES[status]

/**
 * Рендер статуса сообщения электронной почты
 * @param status статус доставки сообщения
 */
export const renderDeliveredNotificationStatus = (
    status: NotificationDeliveryStatus
) => NOTIFICATION_DELIVERY_STATUSES[status]

/**
 * Проверка статуса должности
 * @param isActive - если false значит элемент архивный
 * @returns {string | null} вернет значение для колонки статуса в таблице
 */
export const isStatusActive = (isActive?: boolean): string | null => {
    if (isActive === undefined) return null

    if (isActive) return LOCAL.ACTIVE_POSITION

    return LOCAL.STATUSES.ARCHIVE
}

interface RenderFirstWithTooltipOptionsProps {
    showDots?: boolean
    width?: ReactText
    inline?: boolean
}

export const extractNames = <T extends { name: string }>(items?: T[]) =>
    items?.map((el) => el.name)

export const renderElementWithListPopover = <T extends { name: string }>(
    items?: T[],
    options?: RenderFirstWithTooltipOptionsProps
) => {
    if (!items) return

    const names = extractNames(items)

    return (
        <ElementWithListPopover
            title={names?.[0]}
            items={names}
            options={options}
        />
    )
}

export const renderTableColumnWithPopover = <T extends { name: string }>(
    entities: T[]
) => renderElementWithListPopover(entities, { width: 300, showDots: true })

/**
 * Рендер колонки, содержащей множественные значения через запятую
 * @params list - значение поля
 */
export const renderListWithComma = <T extends DictionaryContract[]>(
    list: T
) => {
    const firstItem = list?.[0]?.name

    if (!firstItem) return
    if (list.length === 1) return firstItem

    return renderStringArrayWithSeparator(list.map(getItemName))
}

/**
 * Рендер строки с троеточием и подсказкой
 * @params maxWidth - ширина
 */
export const renderEllipsisWithPopover = (maxWidth: string | number = 300) => (
    content: ReactNode | string
) => (
    <Popover content={content}>
        <div
            style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                maxWidth,
            }}
            data-testid="elipsisWithPopover"
        >
            {content}
        </div>
    </Popover>
)

/**  Пропсы функция рендера кастомной иконки раскрытия строки таблицы */
export interface RenderExpandIconProps<T> {
    /** раскрыта ли строка таблицы*/
    expanded: boolean

    /** обработчик раскрытия строки таблицы*/
    onExpand: (
        record: T,
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void

    /** запись строки таблицы*/
    record: T
}

/** Рендер иконки стрелочки для раскрытия вложенности таблицы */
export const renderExpandIcon = <T,>({
    expanded,
    onExpand,
    record,
}: RenderExpandIconProps<T>) => (
    <IconsAdapter
        iconType="DownOutlined"
        className={cn(
            iconsStyles.expand,
            expanded && iconsStyles.expandExpanded
        )}
        data-testid="expandIcon"
        onClick={(e) => onExpand(record, e)}
    />
)

export interface GetPortfolioObjectsColumnsWithActionsProps<T>
    extends WithFormModeProps {
    tableColumnsObject: ColumnsType<T>
    handleRemoveRow: FnActionRequiredProps<number, FnActionProps>
    disabled?: boolean
}

/** Получить колонки для объектов оценки с возможностью удаления */
export function getPortfolioObjectsColumnsWithActions<
    T extends { id: number }
>({
    handleRemoveRow,
    formMode,
    tableColumnsObject,
    disabled,
}: GetPortfolioObjectsColumnsWithActionsProps<T>): ColumnsType<T> {
    return [
        ...tableColumnsObject,
        {
            render: (_, row) => (
                <Button
                    type="link"
                    icon={<IconsAdapter iconType="DeleteOutlined" />}
                    data-testid="removeColumnButton"
                    onClick={handleRemoveRow(row.id)}
                    disabled={isFormModeView(formMode) || disabled}
                />
            ),
        },
    ]
}

/**
 * Получить имя для динамического столбца
 * @param groupName - имя группы, к которой относится столбец
 * @param itemNum - номер столбца
 * @returns {string} - сгенерированное имя, которое можно использовать как dataIndex внутри таблицы
 */
export function getDynamicColName<T>(groupName: T, itemNum: ReactText) {
    return [groupName, itemNum].join('-')
}

export const restrictBigText = (title?: ReactText) => (
    <WidthRestrictedElement
        title={title}
        width={350}
        tooltip={{ title, placement: 'top' }}
        showDots
        getPopupContainer={null}
    />
)

export const renderTableCellTitle = (title?: ReactNode) =>
    title || (
        <IconsAdapter
            iconType="MinusOutlined"
            data-testid="missingTableCellTitleIcon"
        />
    )

export const renderAppointmentNames = () => [
    {
        title: LOCAL.LABELS.APPOINTMENTS,
        dataIndex: 'appointmentNames',
        key: 'appointmentNames',
        render: (appointmentNames: string[]) => (
            <ElementWithListPopover
                title={appointmentNames?.length}
                items={appointmentNames}
                options={{ showPopoverAlways: true }}
            />
        ),
        width: '100px',
    },
]

export const renderOrderNumber = (item: any, row: any, i: number) => i + 1
