import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './AdminMenuItem.module.scss'
import { AdminMenuItemProps } from './AdminMenuItem.types'

/** Компонент ссылки для меню администратора */
export const AdminMenuItem: React.FC<AdminMenuItemProps> = React.memo(
    ({ itemKey, link, title, onClick }) => (
        <li key={itemKey}>
            <NavLink
                to={link}
                onClick={onClick}
                exact
                className={styles.link}
                activeClassName={styles.active}
            >
                {title}
            </NavLink>
        </li>
    )
)
