import cn from 'classnames'
import React, { useCallback } from 'react'
import { AddButton } from 'components/shared'
import {
    COMPETENCIES_TABLE_COLUMNS,
    CompetenciesFilters,
    SearchTableShowButton,
} from 'components/controls'
import {
    CompetenceForModalContract,
    CompetenciesSearchContract,
    CompetenciesService,
} from 'core/api'
import { EXCLUDE_DRAFT_STATUS_SEARCH_BODY } from 'consts'
import { FORM_IDS } from 'core/configs'
import { LOCAL } from 'core/local'
import { isFormModeView } from 'utils'
import { useElementVisible } from 'hooks'

import styles from './AddCompetenciesControl.module.scss'
import { AddCompetenciesControlProps } from './AddCompetenciesControl.types'
import {
    mapCompetenciesByGroup,
    normalizeFetchDataCompetencies,
} from '../AssessmentProjectsManagerEstimate/AssessmentProjectsManagerEstimate.utils'

/** Компонент добавления компетенций */
export const AddCompetenciesControl: React.FC<AddCompetenciesControlProps> = React.memo(
    ({
        initialValues,
        formMode,
        title,
        onCompetenciesChange,
        className,
        selectedCompetencies,
        required,
    }) => {
        const {
            updateElementVisible: updateLoader,
            elementVisible: isLoading,
        } = useElementVisible()

        /** Обработчик выбора компетенций */
        const handleSelectCompetencies = useCallback(
            async (result?: number[]) => {
                try {
                    updateLoader(true)

                    if (result?.length) {
                        const fetchedCompetencies = await CompetenciesService.getByIds(
                            {
                                body: result,
                            }
                        )

                        const competencies = normalizeFetchDataCompetencies(
                            fetchedCompetencies,
                            selectedCompetencies
                        )

                        onCompetenciesChange(
                            mapCompetenciesByGroup(competencies)
                        )
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    updateLoader(false)
                }
            },
            [onCompetenciesChange, selectedCompetencies, updateLoader]
        )

        return (
            <SearchTableShowButton<
                CompetenciesSearchContract,
                CompetenceForModalContract
            >
                tableSearchOptions={{
                    formId: FORM_IDS.SEARCH_COMPETENCE,
                    onSelectTableItem: handleSelectCompetencies,
                    rowSelectionType: 'checkbox',
                    filterComponent: CompetenciesFilters,
                    tableColumns: COMPETENCIES_TABLE_COLUMNS,
                    tableFiltersOptions: {
                        initialValues: EXCLUDE_DRAFT_STATUS_SEARCH_BODY,
                    },
                    searchMethod: CompetenciesService.getForModal,
                    initialValues,
                }}
                modalTitle={LOCAL.LABELS.COMPETENCES_SELECTION}
                className={className}
            >
                <div className={cn({ 'required-control': required })}>
                    <span className={styles.title}>{title}</span>

                    <AddButton
                        buttonText={LOCAL.ACTIONS.ADD}
                        disabled={isFormModeView(formMode) || isLoading}
                        loading={isLoading}
                    />
                </div>
            </SearchTableShowButton>
        )
    }
)
