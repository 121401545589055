import React, { useCallback, useState } from 'react'
import { Button } from 'components/shared/Button'
import { CKEditorEventAction, stripPrefix } from 'ckeditor4-react'
import { LOCAL } from 'core/local'
import { SelectControl } from 'components/controls'
import { Title } from 'components/shared'
import { normalizeDictionaryToControl } from 'utils'
import { useParams } from 'react-router-dom'

import styles from './ShortCodesForm.module.scss'
import { ChartType, ShortCodesFormProps } from './ShortCodesForm.types'
import { SHORT_CODES } from '../AssessmentReportsForm/AssessmentReportsForm.consts'
import { UrlParamsProps } from '../../AssessmentReportsSettings.types'

/** Форма добавления шорткодов в текстовый редактор отчета */
export const ShortCodesForm: React.FC<ShortCodesFormProps> = React.memo(
    ({ dispatch }) => {
        const { type } = useParams<UrlParamsProps>()
        const [chartType, setChartType] = useState<ChartType>()

        const handleFinish = useCallback(() => {
            if (!chartType) return

            dispatch({
                type: stripPrefix(CKEditorEventAction.insertHtml),
                payload: { html: `[${chartType}]` },
            })
        }, [dispatch, chartType])

        const changeChart = useCallback((e: ChartType) => {
            setChartType(e)
        }, [])

        return (
            <div className={styles.wrapper}>
                <Title
                    type="h5"
                    text={LOCAL.LABELS.PASTE_CHART}
                    bottomIndent="0"
                />

                <div className={styles.tip}>
                    {LOCAL.MESSAGES.PUT_CURSOR_TO_PASTE_CHART}
                </div>

                <SelectControl
                    placeholder={LOCAL.PLACEHOLDERS.CHOOSE_CHART}
                    values={normalizeDictionaryToControl(SHORT_CODES[type])}
                    onChange={changeChart}
                />

                <Button className={styles.button} onClick={handleFinish}>
                    {LOCAL.LABELS.PASTE_CHART}
                </Button>
            </div>
        )
    }
)
