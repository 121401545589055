import { ArticleContract } from 'core/api'
import { getShortFio, normalizeAttachmentObject } from 'utils'

import { KnowledgeBaseFormSubmitValues } from './KnowledgeBaseForm.types'

/**
 * Маппинг статьи для запроса
 **/
export const mapFormDataToRequest = ({
    selectedIndex,
    picture,
    ...values
}: KnowledgeBaseFormSubmitValues) => ({
    ...values,
    pictureId: picture?.map(({ response }) => response?.id)[0],
})

/**
 * Маппинг запроса для формы статей
 **/
export const mapResponseToFormData = ({
    picture,
    changeUser,
    section,
    ...values
}: ArticleContract) => ({
    ...values,
    picture: [normalizeAttachmentObject(picture)],
    changeUser: getShortFio(changeUser),
    sectionId: section.id,
})
