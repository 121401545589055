import React from 'react'
import cn from 'classnames'
import { CustomArrowProps } from 'react-slick'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import styles from './RecommendedItems.module.scss'

const SliderArrow = ({
    onClick,
    type,
}: CustomArrowProps & { type: string }) => (
    <IconsAdapter
        iconType="CustomIconSliderArrow"
        onClick={onClick}
        className={cn(styles.arrow, type)}
    />
)

export const CAROUSEL_SETTINGS = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type={styles.left} />,
    nextArrow: <SliderArrow type={styles.right} />,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2,
            },
        },
    ],
}
