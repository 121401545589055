import {
    AppointmentActivityContract,
    AppointmentActivityCreateContract,
} from 'core/api'
import { getIdsArray, getValue, normalizeDataForSelectAndRadio } from 'utils'

import { EventsAssignmentsFormValues } from './EventsAssignmentsForm.types'

/**
 * Получить значения для отправки на бэк
 * @param values текущие значения формы
 * @param id идентификатор назначения
 */
export const mapFormDataToRequest = (
    {
        activity,
        organizationIds,
        ...restValues
    }: Partial<EventsAssignmentsFormValues>,
    id?: number
) =>
    ({
        id,
        ...restValues,
        activityId: activity?.[0]?.value,
        organizationIds: organizationIds?.map(getValue),
    } as AppointmentActivityCreateContract)

/**
 * Получить начальные значения для формы назначения
 * @param {} объект значений назначения мероприятия
 */
export function mapResponseToFormData({
    activity,
    participants,
    organizations,
    tutors,
    ...restValues
}: Partial<AppointmentActivityContract>) {
    return {
        ...restValues,
        organizationIds: organizations?.map(normalizeDataForSelectAndRadio),
        activity: activity && [normalizeDataForSelectAndRadio(activity)],
        participantIds: getIdsArray(participants),
        tutorsIds: getIdsArray(tutors),
    }
}
