import { ColumnType, ColumnsType, SorterResult } from 'antd/lib/table/interface'
import { Store } from 'App.types'
import { calculateColumnVisibility } from 'components/shared/ColumnsOptions/ColumnsOptions.utils'
import { useHistory } from 'react-router-dom'

export function getTableColumnsWithClass<T>(
    columnsVisible?: Store<boolean>,
    tableColumns?: ColumnsType<T>
) {
    return tableColumns?.map((el: ColumnType<T>) => ({
        ...el,
        className: calculateColumnVisibility(columnsVisible, el.key),
    }))
}

/**
 * Функция сортировки по умолчанию
 */
export const sortByDefaultRule = <T>(a: T, b: T) => {
    if (a < b) return -1
    if (a > b) return 1

    return 0
}

/**
 * Собрать опции сортировки в массив
 * @param {SorterResult<T> | SorterResult<T>[]} sorter объект или массив объектов опций сортировки
 * @returns {SorterResult<T>[]} массив опций сортировки
 */
export const composeSortOptionsToArray = <T>(
    sorter: SorterResult<T> | SorterResult<T>[]
) =>
    Object.prototype.hasOwnProperty.call(sorter, 'length')
        ? (sorter as SorterResult<T>[])
        : [sorter as SorterResult<T>]

/**
 * Считаем кол-во сущностей на странице в таблице
 * @param pageSize - установленные размер страницы
 * @param tableDataLength - длина массива данных для таблицы
 */
export const getPageSize = (pageSize?: number, tableDataLength?: number) =>
    (Number(tableDataLength) || 0) < (Number(pageSize) || 0)
        ? pageSize
        : tableDataLength

/** id записи таблицы, на которой произошел двойной клик */
type RecordWithId = {
    id: number
    active?: boolean
}

/** URL страницы просмотра */
export interface GetRowHandlersProps {
    url: string
    allowArchive?: boolean
}

/**
 * Обработчик двойного нажатия на строку таблицы (открывает страницу просмотра записи)
 */
export const getRowHandlers = ({ url, allowArchive }: GetRowHandlersProps) => <
    T extends RecordWithId
>(
    record: T
) => {
    if (!record.active && !allowArchive) return {}

    const history = useHistory()

    return {
        onDoubleClick: () => {
            history.push(`${url}/${record.id}`)
        },
    }
}
