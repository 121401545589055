import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ANSWER_FIELD_NAME_BY_QUESTION_TYPE, SEC_IN_MINUTE } from 'consts'
import { IconsAdapter, QuestionnairePictureAttachment } from 'components/shared'
import { LOCAL } from 'core/local'
import { PassingFormQuestionAnswerContract } from 'core/api'
import { checkVideoAttachment, isObjectEmptyDeep } from 'utils'

import styles from './QuestionnairePassingAnswer.module.scss'
import { AnswerFormValuesContract } from '../../QuestionnairePassing.types'
import { QuestionTimer, TimerStatuses } from '../QuestionTimer'
import { QuestionnairePassingAnswerProps } from './QuestionnairePassingAnswer.types'
import {
    compareAnswers,
    getAnswerFormType,
    getBlockedByAnotherQuestionText,
    getBlockedByTimerInitPlaceholder,
    getQuestionTitle,
    getUnblockedQuestionContent,
} from './QuestionnairePassingAnswer.utils'
import {
    isTimerStatusPause,
    isTimerStatusStopped,
} from '../QuestionTimer/QuestionTimer.utils'
import {
    registerUpdateAnswerTimeLeft,
    unregisterUpdateAnswerTimeLeft,
} from '../../QuestionnairePassing.events'

/** Компонент ответа на вопрос при прохождении опроса */
export const QuestionnairePassingAnswer: React.FC<QuestionnairePassingAnswerProps> = React.memo(
    ({
        question,
        value: answer,
        confirmedAnswer,
        getBlockedByQuestionEndToEndNumber,
        onChange,
        onConfirmAnswers,
        disabledQuestionsTimer,
    }) => {
        const isTimerActive =
            !disabledQuestionsTimer && question.limitedAnswerTime
        /**
         * Статус таймера
         */
        const [timerStatus, setTimerStatus] = useState<TimerStatuses>(
            TimerStatuses.Pause
        )

        /**
         * Текущее значение таймера в секундах
         */
        const [timerValue, setTimerValue] = useState(
            question.answerLimit * SEC_IN_MINUTE
        )

        const hasVideoAttachment = checkVideoAttachment(
            question.attachments[0]?.fileName
        )

        const attachment = question.attachments[0]

        /**
         * Обработчик старта начала отсчета таймера
         */
        const handleStartTimer = useCallback(
            () => setTimerStatus(TimerStatuses.Running),
            [setTimerStatus]
        )

        /**
         * Обработчик остановки таймера
         */
        const handleStopTimer = useCallback(() => {
            setTimerStatus(TimerStatuses.Stopped)
        }, [setTimerStatus])

        /** Колбэк вызываемый на подтверждение ответов, для оптимизации формы ответов, чтобы передавать данные непосредственно перед отправкой на бэк*/
        const onSetAnswerTimeLeft = useCallback(
            async (unsavedAnswers: AnswerFormValuesContract[]) => {
                if (!timerValue || !answer) return

                onChange?.({
                    ...answer,
                    timeLeft: timerValue,
                })

                const searchedAnswerIndex = unsavedAnswers.findIndex(
                    (el) => el.questionId === answer.questionId
                )

                if (~searchedAnswerIndex) {
                    unsavedAnswers[searchedAnswerIndex].timeLeft = timerValue
                }
            },
            [onChange, timerValue, answer]
        )

        const onTimerExpired = useCallback(() => {
            handleStopTimer()

            if (answer?.unsaved) {
                onConfirmAnswers([answer])
            }
        }, [handleStopTimer, answer, onConfirmAnswers])

        /**
         * Генерация контента формы в зависимости от условий
         */
        const formContent = useMemo(() => {
            const blockedByQuestionEndToEndNumber = getBlockedByQuestionEndToEndNumber(
                question.blockedByQuestionId
            )

            if (blockedByQuestionEndToEndNumber) {
                return (
                    <div className={styles.blocker}>
                        {getBlockedByAnotherQuestionText(
                            blockedByQuestionEndToEndNumber
                        )}
                    </div>
                )
            }

            if (isTimerActive && isTimerStatusStopped(timerStatus)) {
                return (
                    <div className={styles.blocker}>
                        {LOCAL.MESSAGES.QUESTION_TIME_LIMIT_ENDED}
                    </div>
                )
            }

            if (isTimerActive && isTimerStatusPause(timerStatus)) {
                return (
                    <div className={styles.blocker}>
                        {getBlockedByTimerInitPlaceholder(
                            handleStartTimer,
                            question.answerLimit
                        )}
                    </div>
                )
            }

            return getUnblockedQuestionContent({
                questionText: question.text,
                questionType: question.type,
                formFields: getAnswerFormType({
                    question,
                    fieldName: [
                        'answers',
                        String(question.id),
                        ANSWER_FIELD_NAME_BY_QUESTION_TYPE[question.type],
                    ],
                }),
                questionAttachment: hasVideoAttachment ? attachment : null,
            })
        }, [
            getBlockedByQuestionEndToEndNumber,
            question,
            timerStatus,
            handleStartTimer,
            hasVideoAttachment,
            attachment,
            isTimerActive,
        ])

        useEffect(() => {
            if (!answer) return

            const valueKey = ANSWER_FIELD_NAME_BY_QUESTION_TYPE[
                question.type
            ] as keyof Omit<
                PassingFormQuestionAnswerContract,
                'timeLeft' | 'questionId'
            >

            if (
                !isObjectEmptyDeep(answer[valueKey]) &&
                compareAnswers(answer[valueKey], confirmedAnswer?.[valueKey])
            ) {
                if (answer.unsaved) {
                    onChange?.({ ...answer, unsaved: false })
                }

                return
            }

            if (!answer.unsaved) {
                onChange?.({
                    ...answer,
                    unsaved: true,
                    questionId: question.id,
                })
            }
        }, [confirmedAnswer, onChange, question.id, question.type, answer])

        useEffect(() => {
            if (confirmedAnswer && isTimerActive) {
                handleStopTimer()
                setTimerValue(0)
            }
        }, [confirmedAnswer, handleStopTimer, isTimerActive])

        useEffect(() => {
            registerUpdateAnswerTimeLeft({
                onUpdateAnswerTimeLeftValues: onSetAnswerTimeLeft,
            })

            return () => {
                unregisterUpdateAnswerTimeLeft({
                    onUpdateAnswerTimeLeftValues: onSetAnswerTimeLeft,
                })
            }
        }, [onSetAnswerTimeLeft])

        return (
            <div className={styles.wrapper} data-id={question.id}>
                <header className={styles.header}>
                    <h4 className={styles.title}>
                        {getQuestionTitle(question)}
                    </h4>

                    <span className={styles.title}>
                        {isTimerActive && (
                            <QuestionTimer
                                onEnd={onTimerExpired}
                                onTick={setTimerValue}
                                timerValue={timerValue}
                                timerStatus={timerStatus}
                            />
                        )}

                        {answer && !answer.unsaved && (
                            <IconsAdapter
                                iconType="CheckOutlined"
                                className={styles.icon}
                            />
                        )}
                    </span>
                </header>

                {formContent}

                {!hasVideoAttachment && (
                    <QuestionnairePictureAttachment file={attachment} />
                )}
            </div>
        )
    }
)
