import React, { useCallback, useEffect } from 'react'
import { ASSESSMENT_REPORTS_REQUIRED_PERMISSIONS } from 'consts'
import {
    AssessmentReportsService,
    Circle360ReportService,
    UserPermission,
} from 'core/api'
import { CheckboxControl } from 'components/controls'
import { HasPermissions } from 'core/permissions'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { SpaceAdapter, TableActions, TooltipAdapter } from 'components/shared'
import { downloadFileViaUrl, isAssessmentTypeEstimate360 } from 'utils'
import { onReportConverted } from 'core/helpers/signalr'

import { ReportsTableActionsProps } from './ReportsTableActions.types'

export const ReportsTableActions: React.FC<ReportsTableActionsProps> = React.memo(
    ({ tableRow, reFetchDictionary, updateLoader }) => {
        const handleToggleAvailability = useCallback(async () => {
            await AssessmentReportsService.toggleAvailability({
                id: tableRow.id,
            })

            reFetchDictionary?.()
        }, [reFetchDictionary, tableRow.id])

        const buildReport = useCallback(async () => {
            try {
                updateLoader?.(true)
                await Circle360ReportService.startBuildReport({
                    id: tableRow.id,
                })
            } catch (error) {
                console.error(error)
                updateLoader?.(false)
            }
        }, [tableRow.id, updateLoader])

        useEffect(() => {
            onReportConverted((message) => {
                if (message?.url) {
                    downloadFileViaUrl(message.url, LOCAL.LABELS.REPORT)
                }

                updateLoader?.(false)
            })
        }, [updateLoader])

        return (
            <SpaceAdapter fullWidth>
                <HasPermissions
                    requiredPermissions={[
                        UserPermission.AssessmentReportEmployeeAccess,
                    ]}
                >
                    <TooltipAdapter title={LOCAL.TOOLTIPS.SHOW_TO_EMPLOYEE}>
                        <CheckboxControl
                            onClick={handleToggleAvailability}
                            checked={tableRow.availableForCandidate}
                        />
                    </TooltipAdapter>
                </HasPermissions>

                <HasPermissions
                    requiredPermissions={[
                        ...ASSESSMENT_REPORTS_REQUIRED_PERMISSIONS,
                        UserPermission.AssessmentReportRead,
                    ]}
                >
                    <TableActions.Report
                        href={`${ROUTE_NAMES.ASSESSMENT_REPORTS}/${tableRow.type}/${tableRow.id}`}
                    />

                    {isAssessmentTypeEstimate360(tableRow.type) && (
                        <TableActions.Export onClick={buildReport} />
                    )}
                </HasPermissions>

                <TableActions.Progress />
            </SpaceAdapter>
        )
    }
)
