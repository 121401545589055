import { LOCAL } from 'core/local'

export const VISUALIZATION_TEXT_SETTINGS_META = {
    titleSettings: LOCAL.LABELS.ACTIVITY_NAME_SETTINGS,
    descriptionSettings: LOCAL.LABELS.ACTIVITY_DESCRIPTION_TEXT_SETTINGS,
    blockNameSettings: LOCAL.LABELS.THEMATIC_BLOCK_NAME_SETTINGS,
    blockDescriptionSettings: LOCAL.LABELS.THEMATIC_BLOCK_DESCRIPTION_SETTINGS,
    listSettings: LOCAL.LABELS.LIST_SETTINGS,
}

export const VISUALIZATION_TEXT_COLOR_SETTINGS_META = {
    titleSettings: LOCAL.LABELS.TITLE_TEXT_COLOR,
    descriptionSettings: LOCAL.LABELS.DESCRIPTION_TEXT_COLOR,
    blockNameSettings: LOCAL.LABELS.TITLE_TEXT_COLOR,
    blockDescriptionSettings: LOCAL.LABELS.DESCRIPTION_TEXT_COLOR,
    listSettings: '',
}
