import React from 'react'
import { AppointmentTrackContract, AppointmentTracksService } from 'core/api'
import { ComponentCommonProps } from 'App.types'
import { FORM_IDS } from 'core/configs'
import { FormContainer } from 'components/layouts'

import {
    CANCEL_BTN_OPTIONS,
    PUBLISH_BTN_OPTIONS,
    SAVE_BTN_OPTIONS,
} from './TrackAssignments.config'
import { TrackAssignmentForm } from './components/TrackAssignmentForm'

/**
 * Создание назначения трека
 */
export const TrackAssignmentFormContainer: React.FC<ComponentCommonProps> = React.memo(
    ({ formMode }) => (
        <FormContainer<AppointmentTrackContract>
            formId={FORM_IDS.TRACK_ASSIGNMENT_FORM}
            formMode={formMode}
            formComponent={TrackAssignmentForm}
            requestMethod={AppointmentTracksService.getActive}
            cancelBtnOptions={CANCEL_BTN_OPTIONS}
            saveBtnOptions={SAVE_BTN_OPTIONS}
            publishBtnOptions={PUBLISH_BTN_OPTIONS}
        />
    )
)
