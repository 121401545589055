import { StaffUnitMultiFilterContract } from 'core/api'

import { JobProfileStaffUnitsFiltersValuesProps } from './JobProfileStaffUnitsFilters.types'

export const mapFormDataToRequest = (
    values: Partial<JobProfileStaffUnitsFiltersValuesProps>
): StaffUnitMultiFilterContract => {
    const { staffUnitId, ...restValues } = values

    return Object.fromEntries(
        Object.entries(restValues)
            .filter(([key, value]) => value)
            .map(([key, value]) =>
                key === 'showArchive' ? [key, value] : [key, [value]]
            )
    ) as StaffUnitMultiFilterContract
}
