import React, { useContext } from 'react'
import { Dropdown } from 'antd'
import { IconsAdapter } from 'components/shared/IconsAdapter'
import { UserContext } from 'contexts'
import { getDropdownPopupContainerDefault } from 'utils/layout.utils'
import { getShortFio } from 'utils/formattingUserName.utils'

import styles from './ProfileMenu.module.scss'
import stylesHeader from '../../Header.module.scss'
import { ProfileMenuList } from './ProfileMenuList'

/** Меню пользователя */
export const ProfileMenu: React.FC = React.memo(() => {
    const { userData } = useContext(UserContext)

    return (
        <Dropdown
            overlay={<ProfileMenuList />}
            trigger={['click']}
            overlayStyle={{ position: 'fixed' }}
            getPopupContainer={() =>
                getDropdownPopupContainerDefault(stylesHeader.target)
            }
        >
            <div className={styles.toggle}>
                <div className={styles.userName}>
                    {userData && getShortFio(userData)}
                </div>

                <IconsAdapter iconType="DownOutlined" />
            </div>
        </Dropdown>
    )
})
