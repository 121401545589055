import cn from 'classnames'
import React, { useEffect } from 'react'
import { IconsAdapter } from 'components/shared/IconsAdapter'

import styles from './QuestionTimer.module.scss'
import { QuestionTimerProps } from './QuestionTimer.types'
import { TIMES_STATUSES } from './QuestionTimer.consts'
import {
    isTimerStatusRunning,
    isTimerStatusStopped,
    normalizeTime,
} from './QuestionTimer.utils'

export const QuestionTimer: React.FC<QuestionTimerProps> = React.memo(
    ({ onEnd, onStart, onTick, timerStatus, timerValue }) => {
        /**
         * Запуск таймера при переключении статуса таймера
         */
        useEffect(() => {
            if (isTimerStatusRunning(timerStatus)) {
                onStart?.()
                const intervalId = setInterval(() => {
                    onTick((currentValue: number) => {
                        if (!currentValue) {
                            clearInterval(intervalId)

                            return currentValue
                        }

                        return currentValue - 1
                    })
                }, 1000)

                return () => clearInterval(intervalId)
            }
        }, [timerStatus, onStart, onTick])

        /**
         * Выполнение калбека при завершении работы таймера
         */
        useEffect(() => {
            if (!timerValue && !isTimerStatusStopped(timerStatus)) {
                onEnd?.()
            }
        }, [timerValue, onEnd, timerStatus])

        return (
            <figure className={styles.wrapper}>
                <figcaption>{normalizeTime(timerValue)}</figcaption>

                <IconsAdapter
                    iconType="ClockCircleOutlined"
                    className={cn(
                        styles.timerIcon,
                        timerStatus && TIMES_STATUSES[timerStatus]
                    )}
                />
            </figure>
        )
    }
)
