import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
    DocumentsPageEntitiesCountContract,
    TextDocumentContract,
} from 'core/api'
import { LOCAL } from 'core/local'
import { ROUTE_NAMES } from 'routing/routeNames.consts'
import { defaultSorter } from 'components/layouts'
import { formatDate, restrictBigText, sortByDefaultRule } from 'utils'

import { DocumentsEditorTableActions } from './components'

export const DOCUMENTS_EDITOR_TABLE_COLUMNS: ColumnsType<TextDocumentContract> = [
    {
        title: LOCAL.LABELS.DOCUMENT_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: defaultSorter,
        defaultSortOrder: 'ascend',
        fixed: 'left',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.ACCESSORY_TYPE,
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: LOCAL.LABELS.OWNER_NAME,
        dataIndex: 'author',
        key: 'author',
        render: restrictBigText,
        width: '350px',
    },
    {
        title: LOCAL.LABELS.DATE_CREATION,
        dataIndex: 'creationDate',
        key: 'creationDate',
        render: formatDate,
        sorter: {
            compare: sortByDefaultRule,
            multiple: 2,
        },
        defaultSortOrder: 'descend',
        fixed: 'right',
    },
    {
        key: 'actions',
        render: (tableRow) => (
            <DocumentsEditorTableActions tableRow={tableRow} />
        ),
        fixed: 'right',
    },
]

interface TabsDataProps {
    recordsCount?: number
    tabsTotalCount?: DocumentsPageEntitiesCountContract
    activeTab?: string
}

interface RecordsCountProps {
    tabKey: string
    tabsTotalCountKey: 'documentsCount' | 'missionsCount' | 'ldeReportsCount'
}

export const getTabsData = ({
    recordsCount,
    tabsTotalCount,
    activeTab,
}: TabsDataProps) => {
    const getRecordsCount = ({
        tabKey,
        tabsTotalCountKey,
    }: RecordsCountProps) =>
        activeTab === tabKey
            ? recordsCount
            : tabsTotalCount?.[tabsTotalCountKey]

    return [
        {
            text: LOCAL.LABELS.DOCUMENTS,
            key: 'documents',
            path: ROUTE_NAMES.DOCUMENTS_EDITOR,
            recordsCount: getRecordsCount({
                tabKey: 'documents',
                tabsTotalCountKey: 'documentsCount',
            }),
        },
        {
            text: LOCAL.LABELS.TASKS,
            key: 'tasks',
            path: ROUTE_NAMES.TASKS,
            recordsCount: getRecordsCount({
                tabKey: 'tasks',
                tabsTotalCountKey: 'missionsCount',
            }),
        },
        {
            text: LOCAL.LABELS.REPORTS,
            key: 'reports',
            path: ROUTE_NAMES.REPORTS,
            recordsCount: getRecordsCount({
                tabKey: 'reports',
                tabsTotalCountKey: 'ldeReportsCount',
            }),
        },
    ]
}
