import React, { FC, useCallback, useEffect, useState } from 'react'
import {
    LOCAL_STORAGE_KEYS,
    LocalStorageHelper,
} from 'core/helpers/LocalStorage'

import { ColorThemeContext } from './ColorThemeContext'
import { ColorThemes } from './ColorThemeProps'
import { isThemeColorLight } from './ColorTheme.utils'

const body = document.querySelector('body')

export const ColorThemeContextProvider: FC = React.memo(({ children }) => {
    const [theme, updateTheme] = useState<ColorThemes>(
        (LocalStorageHelper.getItem(
            LOCAL_STORAGE_KEYS.COLOR_THEME
        ) as ColorThemes) || ColorThemes.Light
    )

    const changeTheme = useCallback(
        () =>
            updateTheme((prev) =>
                isThemeColorLight(prev) ? ColorThemes.Dark : ColorThemes.Light
            ),
        [updateTheme]
    )

    useEffect(() => {
        body?.removeAttribute('class')
        body?.classList.add(theme)
        LocalStorageHelper.setItem(LOCAL_STORAGE_KEYS.COLOR_THEME, theme)
    }, [theme])

    return (
        <ColorThemeContext.Provider value={{ theme, updateTheme, changeTheme }}>
            {children}
        </ColorThemeContext.Provider>
    )
})
