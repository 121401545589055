import { TOOLBAR_OPTIONS } from 'components/shared/CKEditorAdapter/CKEditorAdapter.config'

export const TASK_DESCRIPTION_TOOLBAR_OPTION = TOOLBAR_OPTIONS.map((el) =>
    el.name === 'basicstyles'
        ? {
              name: el.name,
              items: [
                  'TextColor',
                  'Bold',
                  'Italic',
                  'Underline',
                  'Strike',
                  '-',
                  'RemoveFormat',
                  'Undo',
              ],
          }
        : el
)

export const TASK_DESCRIPTION_EXTRA_PLUGINS = ['colorbutton']
