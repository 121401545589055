import React, { FC, useState } from 'react'

import { ModalsContext } from './ModalsContext'
import { ModalsOptionsProps } from './ModalsContextProps'

/**
 * Контекст для управления модальными окнами.
 * Данный контекст нужен для связывания нескольких компонентов Popup, отображающих одно содержимое, но в разных частях системы
 */
export const ModalsContextProvider: FC = React.memo(({ children }) => {
    const [popupWithFormOptions, setPopupWithFormOptions] = useState<
        ModalsOptionsProps
    >({})

    return (
        <ModalsContext.Provider
            value={{
                popupWithFormOptions,
                setPopupWithFormOptions,
            }}
        >
            {children}
        </ModalsContext.Provider>
    )
})
